<template>
	<div class="bg-white">
				<!--<div class="d-flex w-100">
						<a class="ml-auto" @click="collapseAll()">Close All</a>
				</div>-->
				<b-list-group flush>
					<b-list-group-item class=" shadow-none " v-for="resource in permission_resources" v-if="resource.isTitle == 0" no-body>
						<div v-if="resource.isTitle == 0">
						<b-button variant="white" v-b-toggle="'permissions-'+resource.id" class="btn-collapse-toggle btn btn-white m-0 px-4 py-0 w-100 btn-white text-left align-items-start justify-content-start d-flex" ><span class="my-auto">{{$t(resource.label)}}</span> <a class="btn btn-primary rounded-circle ml-auto my-auto"><i class="mdi mdi-plus"></i></a></b-button>
						<b-collapse :id="'permissions-'+resource.id" v-if="resource.submenu.length == 0">
						<b-card v-if="resource.submenu.length == 0" class="shadow-none mb-0">
							<b-row class="px-3">
											<b-col md="3" variant="white" class="">{{$t(resource.label)}}</b-col>
											<b-col md="9" :id="'permissions-'+resource.id">
											<b-form-group class="text-right">
												<b-form-checkbox v-for="(action, key) in permissions[0]" v-if="!excluded_keys.includes(key)" v-model="permissions[0][key]" :value="resource.id" :name="'resource-'+resource.id+''+key+''" inline> {{key}} </b-form-checkbox>
											</b-form-group>
										</b-col>
									</b-row>
										</b-card>
									</b-collapse>
						</div>
						<b-collapse :id="'permissions-'+resource.id" v-if="resource.submenu.length > 0">
							<b-card class="shadow-none">
								<!--<h4>{{ $t(resource.label) }}</h4>-->
								<b-list-group flush>
									<b-list-group-item v-if="resource.submenu" v-for="subsubmenu in resource.submenu">
										<b-row>
											<b-col md="3" variant="white" class=" d-flex align-items-center">{{$t(subsubmenu.label)}}</b-col>
											<b-col md="9" :id="'permissions-'+subsubmenu.id">
												<b-card class="shadow-none mb-0" no-body>
													<b-form-group class="text-right">
														<b-form-checkbox v-for="(action, key) in permissions[0]" v-if="!excluded_keys.includes(key)" v-model="permissions[0][key]" :value="subsubmenu.id" inline> {{key}} </b-form-checkbox>
													</b-form-group>
												</b-card>
											</b-col>
										</b-row>
									</b-list-group-item>
								</b-list-group>
							</b-card>
						</b-collapse>
					</b-list-group-item>
				</b-list-group>
				</div>
</template>

<script>

export default {
	data() {
		return {
			excluded_keys: ['id', 'created_at', 'updated_at', 'user_role_id', 'company_user_id'],
		}
	},
	props: {
		permission_resources: {
			type: Array,
		},
		permissions: {
			type: Array,
			default: [{
   				view: [],
   				add: [],
   				edit: [],
   				read: [],
   				delete: [],
   			}],
		}
	},
	methods:{
		collapseAll(){
			this.permission_resources.forEach(collapse => {
			this.$root.$emit('bv::toggle::collapse', 'permissions-'+collapse.id);
				});
		}
	}
}

</script>