<script type="text/javascript">
	export default {
		props: {
	  	   	 user: {
	  	   	 	type: Object,
	  	   	 },
	  	   	 company: {
	  	   	 	type: Object,
	  	   	 	default: () => '',
	  	   	 },
	  	   	 order: {
	  	   	 	type: Object,
	  	   	 },
	  	   	 paid: {
	  	   	 	type: Boolean,
	  	   	 	default: () => false,
	  	   	 }

	  	   },
	}

</script>

<template>
<b-container fluid>
	<b-row>
		<b-col md="8">
			<b-card class="border-radius-1em">
		
				<b-row>
					<b-col class="border-right">
						<h4 class="card-title mb-3">Delivery Address</h4>
						{{order.delivery_address.alias}}
						{{order.delivery_address.address1}}
						{{order.delivery_address.address2}}
						{{order.delivery_address.address3}}
						{{order.delivery_address.city}}
						<span v-if="order.delivery_address.county.name">{{order.delivery_address.county.name}}</span>
						{{order.delivery_address.postcode}}
						<span v-if="order.delivery_address.country.name">{{order.delivery_address.country.name}}</span>
					</b-col>
					<b-col>
						<h4 class="card-title mb-3">Invoice Address</h4>
						{{order.invoice_address.alias}}
						{{order.invoice_address.address1}}
						{{order.invoice_address.address2}}
						{{order.invoice_address.address3}}
						{{order.invoice_address.city}}
						<span v-if="order.invoice_address.county.name">{{order.invoice_address.county.name}}</span>
						{{order.invoice_address.postcode}}
						<span v-if="order.invoice_address.country.name">{{order.invoice_address.country.name}}</span>
					</b-col>
				</b-row>
			</b-card>
			<b-card class=" border-radius-1em">
				<h4 class="card-title mb-3">Cart Summary</h4>
			<b-list-group v-for="(company, cindex) in cart_companies">
			<div>
				<b-card class="shadow-none d-flex border border-radius-1em mb-2 mt-4"><h3>You're ordering {{company.length}} item(s) from:</h3><img v-if="company.products[0].company !== null" :src="storageurl+company.products[0].company.logo" class="avatar-sm rounded-circle border border-light">
			<span v-if="company.products[0].company !== null">{{company.products[0].company.name}}</span></b-card></div>
            	<b-list-group-item  v-for="(productitem, pindex) in company.products" href="javascript: void(0);" class="text-reset notification-item px-0 py-3 m-0">
	              <div class="media align-items-center">
	                <div class="avatar-sm me-3">
	                  <img v-if="productitem.product.cover_image" :src="productitem.product.cover_image" class="avatar-title rounded-circle">
	                  <span v-else class="avatar-title bg-primary rounded-circle font-size-16" >
	                    <i class="bx bx-cart"></i>
	                  </span>
	                </div>
	                <div class="media-body my-auto flex-grow-10">
	                  <h6 class="mt-0 mb-1">{{productitem.product.name}}</h6>
	                </div>
	                <div class="my-auto flex-grow-2 text-right d-flex">
	                  <!--<i class="bx bx-chevron-up mb-auto mx-auto"></i>-->
	                  <b-form-input type="number"  v-model="productitem.quantity" :max="productitem.product.total_qty" class="visible mx-auto my-auto" style="width: 25%;">
	                  </b-form-input>
	                  <span class="mr-auto my-auto"><strong class="text-primary">{{productitem.product.total_qty}}</strong> available</span>
	                  <!--<span class="mx-auto my-auto">{{productitem.quantity}}</span>
	                  <i class="bx bx-chevron-down mt-auto mx-auto"></i>-->
	                </div>
	                <div class="my-auto flex-grow-2 text-center">
	                  <strong class="text-primary mr-2">£</strong>{{productitem.price_exc}}
	                </div>
	                <div class="my-auto flex-grow-2 text-center">
	                  <strong class="text-primary mr-2">£</strong>{{(productitem.price_exc * productitem.quantity).toFixed(2)}}
	                </div>
	                <div class="my-auto flex-grow-1 text-center">
	                 <a class="btn btn-outline-light btn-rounded" @click="removeProduct(productitem.id, pindex)"><i class="bx bx-trash-alt"></i></a>
	                </div>
	              </div>
            	</b-list-group-item>
            	<b-list-group-item v-if="cart.products.length == 0">
	              <div class="p-5 text-center border">
	                <i class="bx bx-shopping-bag font-size-42 text-secondary mb-2"></i>
	                <h6>No Products in Cart</h6>
	              </div>
	            </b-list-group-item>
            	<b-card class="shadow-none d-flex border border-radius-1em mb-2 mt-2">
            		<h6 class="mb-4" v-if="company.products[0].company !== null">Shipping Options for {{company.products[0].company.name}}:</h6>
            		<b-row>
            			<b-col md="12" v-if="delivery_address == null">
            				<b-card class="shadow-none text-center border border-radius-1em">
            					<i class="bx bxs-truck font-size-24 text-primary"></i>
            					<h6>Please Select a Delivery Address To See Shipping Options</h6>
            				</b-card>
            			</b-col>
            			<b-col md="3"  v-else v-for="shippingoption in company.shippingoptions">
	            			<b-card class="shadow-sm text-center border border-radius-1em py-3 bg-primary text-white">
	            				<img v-if="shippingoption.icon !== (null || '')" :src="storageurl+shippingoption.icon"></img>
	            				<i v-else class="bx bxs-truck d-block font-size-24" :class="{'text-white': cart_companies[cindex].company_shipping_option_id == shippingoption.id, 'text-primary': cart_companies[cindex].company_shipping_option_id !== shippingoption.id}"></i>
	            				{{shippingoption.name}}
	            				<small v-if="shippingoption.free_shipping == 1" class="d-block shipping-price font-italic" :class="{'text-white': cart_companies[cindex].company_shipping_option_id == shippingoption.id, 'text-muted': cart_companies[cindex].company_shipping_option_id !== shippingoption.id}">
	            					+£0.00
	            				</small>
	            				<small v-else class="d-block font-italic text-center" >
	            					+£{{calculateShippingPrice(shippingoption.ranges, cart_companies[cindex].productstotal, shippingoption.free_shipping)}}
	            				</small>
	            			</b-card>
            			</b-col>
            		</b-row>
            	</b-card>
            	<b-card class="shadow-none d-flex border border-radius-1em mb-2 mt-2">
            		<b-row>
            			<b-col md="4">
            				<h6 class="mb-4" v-if="company.products[0].company !== null">Add Discount Coupon</h6>
            			</b-col>
            			<b-col md="8">
            				 <div class="mt-sm-0 form-inline w-100" v-if="company.coupon == ''" >
				              <div class="search-box mr-2 ml-auto mt-3">
				                <div class="position-relative">
				                  <input
				                    type="text"
				                    class="form-control w-100 border-0 bg-light"
				                    placeholder="Enter coupon code"
				                    v-model="couponsearch" @keydown.enter="getDiscountCoupon(company.uid, cindex)" 
				                  />
				                  <i class="bx bx-search-alt search-icon"></i>
				                </div>
				              </div>
				            </div>
				            <div class="d-flex border border-radius-1em p-3" v-if="company.coupon !== ''">
				            	<div class="avatar-sm my-auto">
				            		<span class="avatar-title rounded-circle">
				            			{{company.coupon.code.slice(0,1)}}
				            		</span>
				            	</div>
				            	<div class="media-body my-auto ml-3">
				            		<h5 class="mb-0">{{company.coupon.code}}</h5>
				            		<p class="mb-0">{{company.coupon.description}}</p>
				            		
				            	</div>
				            	<div class="ml-auto my-auto">
				            		<h5 class="mb-0 text-primary font-size-24">
				            		<span v-if="company.coupon.discount_type == 2">£</span>{{company.coupon.discount_amount}}<span v-if="company.coupon.discount_type == 1">%</span>
				            		</h5>
				            	</div>
				            	<div class="ml-3 my-auto" >
				            		<a href="javascript:;" class="btn btn-rounded btn-danger" @click="company.coupon = '', couponDiscountsTotal()">
				            			<i class="bx bx-trash-alt"></i>
				            		</a>
				            	</div>
				            </div>
            			</b-col>
            		</b-row>
            	</b-card>
          </b-list-group>
            </b-card>
		</b-col>
		<b-col md="4">
			<b-card class="border-radius-1em">
				<b-list-group flush>
				<b-list-group-item class="d-flex w-100">
				<h4 class="card-title mb-3">Order Reference</h4>
				<span>Order Reference</span>
			</b-list-group-item>

				<b-list-group-item class="d-flex w-100"><h4 class="card-title mb-3">Transaction Reference</h4>
				<span>Order Reference</span></b-list-group-item>

				<b-list-group-item class="d-flex w-100"><h4 class="card-title mb-3">Order Status</h4>
				<span>Order Reference</span></b-list-group-item>

				<b-list-group-item class="d-flex w-100"><h4 class="card-title mb-3">Estimated Delivery Time</h4>
				<span>Order Reference</span></b-list-group-item>

				<b-list-group-item class="d-flex w-100"><h4 class="card-title mb-3">Payment Summary</h4>
				</b-list-group-item>
         	</b-list-group>
				<b-list-group flush>
					<b-list-group-item class="d-flex w-100">
					</b-list-group-item>
				</b-list-group>
			</b-card>
		</b-col>
	</b-row>
</b-container>
</template>