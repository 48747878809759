<script>
	import Multiselect from "vue-multiselect";
	import AddNewSupplierAddressForm from '../../components/widgets/AddNewSupplierAddressForm';
	import SideSlidePanel from "../../components/widgets/SideSlidePanel"
	export default{
		components: { Multiselect, AddNewSupplierAddressForm, SideSlidePanel },
		data(){
			return{
				id: '',
				options: [
					{id: 1, address_alias: 'text'},
					{id: 2, address_alias: 'text2'}
				],
				search: '',
				currentPage: 1,
				order: 'desc',
      			sortby: 'id',
      			limit: 0,
				limits: [
		        {value: 25, text: "25"},
		        {value: 50, text: "50"},
		        {value: 100, text: "100"},
		    	],
		    	noresults: false,
				response_error: [], 
				addNewPanel: false,
			}
		},
		props: {
			isDisabled: {
				type: Boolean,
				default: () => false,
				},
			address_data: {
				type: Array,
				default: () => []
			},
			supplier_id: {
				type: Number,
				default: () => 0
			}
		},
		created(){
		
			this.id = this.$attrs.id;
			
		},
		methods:{
			updateDepartment: function(selectedOption, id){
				this.$emit('supplier-address-selected', selectedOption);
			},
			customLabel ({ title, desc }) {
		      return `${title} – ${desc}`
		    },
		    addressAdded(address){
		    	this.address_data.push(address);
		    	this.$emit('supplier-address-added');
		    	this.$bvModal.hide('modal-addnewsupplieraddress');
		    }
		}
	}
</script>
<template>
	<div>
		<div class="d-flex">
	<multiselect v-model="id" track-by="name" label="name" :options="address_data" @select="updateDepartment" :option-height="104" :disabled="isDisabled" >
                     <!--<template slot="singleLabel" slot-scope="{ option }">{{ option.address_alias }} - {{ option.jobtitle }}</template>-->
                     <template slot="singleLabel" slot-scope="{ option }"><div class="d-flex w-100 h-100"><div class="avatar-sm mr-4 my-auto">
                        <span class="avatar-title rounded-circle">{{(option.address_alias).slice(0,1)}}</span>
                      </div>
      <div class="option__desc d-flex flex-row w-75"><span class="option__title ml-2 my-auto w-100">{{ option.address_alias }}<br><p class="m-0 lh-1 w-100 text-truncate"><small>{{ option.address1 }} {{ option.address2 }} <span v-if="county">{{ option.county }}</span> <span v-if="country">{{ option.country }}</span> {{ option.postcode }}</small></p></span>
      </div>
  		</div></template>
    <template slot="option" slot-scope="{ option }">
    	<div class="d-flex"><div class="avatar-sm mr-4">
                        <span class="avatar-title rounded-circle">{{(option.address_alias).slice(0,1)}}</span>
                      </div>
      <div class="option__desc d-flex flex-row w-50"><span class="option__title ml-2 my-auto w-100">{{ option.address_alias }}<br><p class="m-0 lh-1 w-100 text-truncate"><small>{{ option.address1 }} {{ option.address2 }} <span v-if="county">{{ option.county }}</span> <span v-if="country">{{ option.country }}</span> {{ option.postcode }}</small></p></span>
      </div>
  		</div>
    </template>
                  </multiselect>
                   <a v-if="supplier_id !== 0" variant="light" class="my-auto btn btn-outline-light ml-2 flex-grow-1 h-100"  @click="addNewPanel = !addNewPanel" ><i class="mdi mdi-plus"></i></a>
               </div>


                  <!--<b-modal 
                    id="modal-addnewsupplieraddress"
                    centered
                    title="Add New Supplier Address"
                    title-class="font-18"
                    dialog-class="edit-sidepanel top-0"
                    hide-footer
                  >-->
                  <SideSlidePanel :editPanel="addNewPanel" :panelClass="'edit-micropanel'" @close-panel="addNewPanel = !addNewPanel">
                  	<b-row class="px-4">
                  		<b-col>
                  			<h4>Add New Supplier Address</h4>
                    		<AddNewSupplierAddressForm :supplier_id="supplier_id" :api_token="$attrs.api_token" @close="$bvModal.hide('modal-addnewsupplieraddress')" @supplier-address-added="addressAdded" ></AddNewSupplierAddressForm>
                    	</b-col>
                    </b-row>
                  </SideSlidePanel>
   </div>
</template>