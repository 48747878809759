<script>
	export default{
		data(){
			return{
				searchquery: '',
				search_results: [],
				all_brands: '',
        storageurl: 'https://'+this.$attrs.company.nickname+'.s3.eu-west-2.amazonaws.com',
				results_start: 0,
      			results_end: 5,
			}
		},
    props: {
      product_brand_id: {
        default: () => null
      }
    },
		methods:{
		  searchResource(){
		  	if(this.searchquery){
          		 this.$emit('search-query', this.searchquery);
      		 }
          },
          selectResource(resource){
          	this.$emit('search-resource-selected', resource);
          },
          activeBrand(id){
             return this.all_brands.filter(item => item.id === id);
          },
          brandsDefault(){
            if(this.searchquery.length < 2 || this.searchquery === undefined){
            this.$attrs.search_results = this.all_brands;
            }
          },
          brandsOnBlur(){
            if(this.searchquery.length < 1){
                //this.$attrs.search_results = [];
            }
            this.results_end = 5;
            this.results_start = 0;
          },
          priceIncVat(price, tax_percentage){
		      var tax = 1 + (tax_percentage / 100);
		      return price * tax;
		    }
		}
	}
</script>
<template>
  <div class="row">
          <div class="col-md-12">
                            <div class="form-control-group">
                              <div class="input-group">
                                <div class="search-box" style="flex-grow: 3">
                                  <div class="position-relative">
                                    <input class="form-control" type="text" v-model="searchquery" v-on:keyup.enter="searchResource" v-on:keydown="brandsDefault">
                                    <i class="bx bx-search-alt search-icon"></i>
                                    <a v-b-tooltip.hover title="Reset" placement="top" href="javascript:;" @click="[$attrs.search_results = [], searchquery = undefined]">
                                      <i class="bx bx-reset search-icon reset"></i>
                                    </a>
                                  </div>
                                </div>
                                <div class="pl-2">
                                    <a href="javascript:;" class="btn btn-rounded btn-primary btn-block" @click="searchResource">Search</a>
                                </div>
                             </div>
                            <ul class="list-group searchbar-searchlist shadow" v-if="$attrs.search_results.length">
                              <li v-for="searchresult in $attrs.search_results" class="list-group-item d-flex">
                              	<div v-if="$attrs.search_resource == 'products'" ><img v-if="searchresult.cover_image !== null" class="avatar-md border rounded-circle" :src="storageurl+searchresult.cover_image.src.replace('//', '/')">
                                  <img v-else class="avatar-md border rounded-circle" :src="'/images/product-placeholder.png'"></div>
                                <img v-else-if="$attrs.search_resource == 'brands'" class="avatar-md rounded-circle border" :src="'/public/storage/'+searchresult.logo">
                                <div class="mr-auto ml-2 my-auto w-50" v-if="$attrs.search_resource == 'products'">
                                	<strong class="mr-auto my-auto">{{searchresult.name}}</strong>
                                	<span class="badge badge-light">{{searchresult.reference}}</span>
						            <small class="text-muted italic no-margin" v-html="searchresult.short_description"></small>
						            <strong class="text-muted mb-auto">
                          <span>£{{searchresult.sales_price}} <small class="mr-3">exc. VAT</small></span>
                          <span v-if="searchresult.tax_rule !== null">£{{priceIncVat(searchresult.sales_price, searchresult.tax_rule.percentage)}} <small class="mr-3">inc. VAT</small></span>
                          <span>£{{searchresult.trade_price}} <small class="mr-3">Trade Price</small></span>
                        </strong>
                                </div>
                                <div class="mr-auto ml-2 my-auto w-50" v-else>
                                	<span >{{searchresult.name}}</span>
                                </div>
                                <a v-if="$attrs.search_resource == 'products'" class="btn btn-outline-light btn-rounded ml-auto my-auto" href="javascript:;" @click="selectResource(searchresult), [$attrs.search_results = [], searchquery = undefined] "><i class="bx bx-plus"></i></a>
                                <a v-if="$attrs.search_resource == 'brands'" class="btn btn-outline-light btn-rounded ml-auto my-auto" href="javascript:;" @click="toggleBrand(false, searchresult.id)">Select Brand <i class="bx bx-plus"></i></a>
                              </li>
                              <li v-if="search_results.length && (results_end <= search_results.length)" class="list-group-item py-4 text-center" v-on:click="results_end += 3">Load More
                              </li>
                            </ul>
                          </div>
                        
                      <div class="col-md-12" v-if="product_brand_id !== null">
                        <h5 class="mt-4 mb-4 pb-2 form-row-title" >Active Brand</h5>
                          <ul class="list-group">
                            <li v-for="searchresult in activeBrand(product_brand_id)" class="list-group-item d-flex">
                            <img class="avatar-md rounded-circle border" :src="'/public/storage/'+searchresult.logo">
                            <span class="my-auto ml-2">{{searchresult.name}}</span>
                              <a class="btn btn-remove ml-auto font-size-20 my-auto" href="javascript:;" @click="toggleBrand(true, searchresult.id)">
                                <i class="bx bx-trash-alt"></i>
                              </a>
                            </li>
                          </ul>
                      </div>
                  </div>
                </div>
</template>