<script type="text/javascript">
	import { EventBus } from '../../app'

	export default{
		name: 'PublicMarketplaceCollectionList',
		props: {
			resource: {
		        type: String,
		        default: () => '',
		      },
		      resource_id: {
		        type: Number,
		        default: () => '',
		      },
		      api_token: {
		        type: String,
		        default: () => '',
		      },
		      resource_route: {
		        type: String,
		        default: () => '',
		      },
		      marketplace: {
		        type: String,
		        default: () => '',
		      },
		      product_list: {
		        type: Array,
		        default: () => '',
		      },
		      expanded:{
		      	type: Number,
		      	default: () => 0
		      },
		      view: {
		      	type: String,
		      	default: () => 'grid'
		      },
		      layouttoggle: {
		      	type: Boolean,
		      	default: () => true
		      },
		      showtools: {
		      	type: Boolean,
		      	default: () => false
		      },
		      showcategories: {
		      	type: Boolean,
		      	default: () => true
		      },
		      minilist: {
		      	type: Boolean,
		      	default: () => false
		      }
		  },
		  data(){
			return{
		        quickviewproduct: '',
		        quantity: 1,
		        showQuantitySelector: null,
		        hoverIndex: -1,
		        storageurl: '/public-image',
			}
		  },
		  methods: {
		  	removeResource(){

		  	},
		  	priceIncVat(price, tax_percentage){
		      var tax = 1 + (tax_percentage / 100);
		      return price * tax;
		    },
		    addToCart(product, quantity){
		    	EventBus.$emit('add-to-cart', product, quantity)
		    }
		  }
	}
</script>
<template>
	 <div class="row">
          <div class="col-md-12">
          	<ul class="nav nav-pills product-view-nav mb-2 float-right" v-if="layouttoggle == true">
          	<li class="nav-item">
                  <a class="nav-link" :class="{'active': view == 'grid' }" href="javascript: void(0);" @click="view = 'grid'">
                    <i class="bx bx-grid-alt"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': view == 'list' }" href="javascript: void(0);" @click="view = 'list'">
                    <i class="bx bx-list-ul"></i>
                  </a>
                </li>
               </ul>
          	<ul id="my-table" class="list-group product-list w-100 no-shadow" v-if="(product_list.length !== 0) && (view == 'list')">
                              <li v-for="product in product_list" class="list-group-item d-flex px-0 py-0 border product-list-item mb-3 shadow-sm">
                              	<div class="d-flex product-list-item-container position-relative">
                              		<span class="badge bg-primary position-absolute mt-2 ml-2 product-list-reference-badge" v-if="minilist == false">{{product.reference}}</span>
                              	<b-img-lazy v-if="product.cover_image" class="avatar-lg mr-3  product-list-img" :src="product.cover_image" :class="{'w-15 h-100': minilist == false}" /></b-img-lazy>
                              	<b-img-lazy v-else class="avatar-lg mr-3 product-list-img" :class="{'w-15 h-100': minilist == false}" src="/images/product-placeholder.png" />
                                <div class="mr-auto ml-2 my-auto flex-grow-3 mr-3" >
                                	<span class="badge bg-primary mt-2 product-list-reference-badge" v-if="minilist == true">{{product.reference}}</span>
                                	<strong class="mr-auto my-auto text-primary" :class="{'d-block': minilist == false}">{{product.name}}</strong>
                                	
						            <small v-if="minilist == false" class="text-muted italic no-margin" v-html="product.short_description"></small>
						            <strong class="text-muted mb-auto">£{{product.trade_price}} <small class="mr-3">Trade Price</small></strong>
						            <div class="d-flex">
						            <a 
                          href="javascript: void(0);"
                          class="badge bg-light bg-soft rounded-pill font-size-11 m-1"
                         v-if="(product.trade_marketplace_defaultcategory !== null) && (showcategories == true)"><b-img-lazy class="rounded-circle avatar-xs" v-if="product.trade_marketplace_defaultcategory.image" :src="`${product.trade_marketplace_defaultcategory.image}`" alt /> <b-img-lazy v-else  src="/images/banner-placeholder.png" alt="" class="rounded-circle avatar-xs" /> <span class="text-dark">{{product.trade_marketplace_defaultcategory.name}}</span></a>

                         <a 
                         v-if="(product.trade_marketplace_categories.length >0) && (showcategories == true)"
                          href="javascript: void(0);"
                          class="badge bg-light bg-soft rounded-pill font-size-11 m-1"
                          v-for="category in product.trade_marketplace_categories"><b-img-lazy v-if="category.category.image" class="rounded-circle avatar-xs" :src="`${category.category.image}`" alt /> <b-img-lazy v-else src="/images/banner-placeholder.png" alt="" class="rounded-circle avatar-xs" />  <span class="text-dark">{{category.category.name}}</span></a>
                        </div>
						            
                                </div>
                                <div class="mr-auto ml-2 my-auto flex-grow-1 mr-3 d-flex" v-if="showtools == true">
                                	<router-link class="btn btn-outline-light btn-rounded ml-auto my-auto flex-grow-3 mr-2" to="javascript:;" @click="quickviewproduct = product"><i class="py-3 px-2 mdi mdi-eye-outline"></i></router-link>
                                <a class="btn btn-outline-light btn-rounded ml-auto my-auto" href="javascript:;" @click="removeResource(product)"><i class="py-1 px-2 bx bx-trash-alt"></i></a>
                            	</div>
              
                              </div>
                              </li>
                              

                              <!--<li class="list-group-item py-4 text-center" ><a class="btn btn-outline-light btn-rounded">Load More</a>
                              </li>-->
                            </ul>
                <ul class="list-group product-list w-100 no-shadow" v-if="(product_list.length == 0) && (view == 'list')">
                	  <li class="list-group-item d-flex">
                	  	<i class="bx bx-warning"></i> No Sellers Match Your Search Criteria <span v-if="resource !== ''">assigned to this {{resource}}</span>
                	  </li>
                </ul>
          </div>
          <div class="col-md-12">
          	<div  id="my-table" class="row product-grid px-2 row-eq-height" v-if="(product_list.length !== 0) && (view == 'grid')">
                 <div class="d-flex flex-column product-grid-item mb-2" :class="{'col-md-6' : expanded == 0,'col-md-4' : expanded == 1}" v-for="(product, index) in product_list">
                 
		    <b-card class="card overflow-hidden border-radius-1em shadow-sm seller-profile-card" no-body @mouseenter="hoverIndex = index" @mouseleave="hoverIndex = -1">
          <div class="bg-soft bg-light">
            <div class="row">
            	<transition name="fade-left">
		                    	<div class="col-md-12 position-absolute z-index-2" v-if="hoverIndex == index"> 
                              	<router-link :to="'/seller/'+product.nickname+'/profile'" class="mt-3 ml-3 btn btn-primary font-size-16 btn-rounded ml-auto float-left" v-b-tooltip.hover.right title="View Company" ><i class="mdi mdi-eye-outline"></i></router-link>
                              </div>
                          </transition>
                           
                       <div class="col-12">
                       	<router-link :to="'/seller/'+product.nickname+'/profile'">
                					<div v-if="product.cover_image !== null">	
                						<b-img-lazy v-if="'gallery' in product.cover_image" :src="storageurl + product.cover_image.gallery+'?s='+product.nickname" alt class="img-fluid" />
                						<b-img-lazy v-else-if="'thumb' in product.cover_image" :src="storageurl + product.cover_image.thumb+'?s='+product.nickname" alt class="img-fluid" />
                						<b-img-lazy v-else-if="'src' in product.cover_image" :src="storageurl + product.cover_image.src+'?s='+product.nickname" alt class="img-fluid" />
                						<div v-else style="min-height: 240px;"></div>
                					</div>
                					<div v-else style="min-height: 240px;"></div>
                				</router-link>
                 <transition name="fade-right">
		                        <div class="mt-auto mb-3 mx-2 d-flex position-absolute bottom-0 right-0 left-0" v-if="hoverIndex == index">
                              	<b-form-rating style="width: 30%;" id="rating-inline" readonly variant="warning" class="bg-transparent pr-4 ml-auto" inline no-border size="sm" :value="product.reviews_avg_rating"></b-form-rating> 
              					</div>
              				</transition>
              </div>
            </div>
          </div>
          <b-card-body class="card-body pt-0">
            <div class="row">
              <div class="col-sm-12 pb-4 text-center">
                <div class="avatar-md profile-user-wid mb-2 mx-auto" style="margin-top: -45px !important;">
                  <img
                  	v-if="product.logo" :src="product.logo.includes(storageurl) == false ? (storageurl + product.logo+'?s='+product.nickname) : product.logo+'?s='+product.nickname"
                    alt
                    class="img-thumbnail rounded-circle"
                  />
                   <img
                  	v-else 
                    src="/images/product-placeholder.png"
                    alt
                    class="img-thumbnail rounded-circle"
                  />
                </div>
                <h5 class="font-size-15 text-truncate">{{product.name}}</h5>
                <p class="text-muted mb-0 text-truncate"><span v-if="product.county !== null">{{product.county.name}},</span> {{product.country.name}}</p>
              </div>
              <div class="col-md-6 d-flex">
              	<span class="badge badge-light border-radius-1em bg-light text-dark font-size-11 lh-16 my-auto p-2">
              		{{product.type.name}}
              	</span>
              </div>
              <div class="col-md-6 d-flex">
              	<!--<span class="badge badge-light border-radius-1em bg-light text-dark font-size-11 lh-16 my-auto p-2 ml-auto">
              		100 <i class="bx bx-tag"></i>
              	</span>-->
              	<span class="badge badge-light border-radius-1em bg-light text-dark font-size-11 lh-16 my-auto p-2 ml-auto">
              		{{product.listings.length}} Stores and Locations <i class="bx bx-buildings"></i>
              	</span>
              </div>

            </div>
          </b-card-body>
        </b-card>
                    	<!--<div class="marketplace-pg border shadow-sm product-grid-item-container bg-white flex-column d-flex">
                         <div class="row">
                 						<div class="col-md-12 position-absolute"> 
                              	<a id="tooltip-add"  @click="quickviewproduct = product" class="mt-3 ml-3 btn btn-primary font-size-16 btn-rounded ml-auto float-left"><i class="mdi mdi-eye-outline"></i></a>
                              </div>
                            </div>
                           	<img v-if="product.cover_image" class="w-100 product-grid-img" :src="product.cover_image">
                           	<img v-else class="w-100 product-grid-img" src="/images/product-placeholder.png">
                          <div class="mt-3 px-3">
                           	<strong class="mr-auto my-auto text-primary">{{product.name}}</strong>
                           	<span class="badge mt-3 bg-primary position-absolute product-grid-reference-badge">{{product.reference}}</span>
								            <small class="text-muted italic no-margin" v-html="(product.short_description).slice(0,150)+'...'"></small>
                    			</div>
                                <div class="my-1 px-3">
                                	<strong class="text-muted mb-auto">£{{product.trade_price}} <small class="mr-3">Trade Price</small></strong>
                                </div>
                        <b-row class="px-1 mt-1">
                        <b-col md="4" v-if="product.trade_marketplace_defaultcategory !== null">  
	                         <a 
	                          href="javascript: void(0);"
	                          class="badge bg-light bg-soft rounded-pill font-size-11 m-1"
	                         v-if="product.trade_marketplace_defaultcategory !== null"><img class="rounded-circle avatar-xs" v-if="product.trade_marketplace_defaultcategory.image" :src="`${product.trade_marketplace_defaultcategory.image}`" alt /> <img v-else  src="/images/banner-placeholder.png" alt="" class="rounded-circle avatar-xs"> <span class="text-dark">{{product.trade_marketplace_defaultcategory.name}}</span></a>
                       </b-col>

                           <b-col md="4" v-if="product.brand !== null">
	                         	<a 
	                          href="javascript: void(0);"
	                          class="badge bg-light bg-soft rounded-pill font-size-11 m-1"
	                         v-if="product.brand != null"><img v-if="product.brand.logo" class="rounded-circle avatar-xs" :src="`${product.brand.logo}`" alt />  <img v-else  src="/images/banner-placeholder.png" alt="" class="rounded-circle avatar-xs"> <span class="text-dark">{{product.brand.name}}</span></a>
                         </b-col>
                       </b-row>

                        <div class="mt-auto mb-3 d-flex">
                              	<a id="tooltip-add"  @click="selectedCategory = list, showStoneDetails = 1"class="ml-3 mr-auto btn btn-primary font-size-16 btn-rounded"><i class="mdi mdi-eye-outline"></i></a>
                              	<a id="tooltip-add"  @click="selectedCategory = list, showStoneDetails = 1"class="mr-2 ml-auto btn btn-primary font-size-16 btn-rounded"><i class="mdi mdi-heart-outline"></i></a>
                              	<a id="tooltip-add"  @click="showQuantitySelector = index"class="mr-3 ml-0 btn btn-primary font-size-16 btn-rounded"><i class="mdi mdi-shopping-outline"></i></a>
                              	<b-card class="quantity-card bg-light" v-if="showQuantitySelector == index">
                              		<b-row>
                              			<b-col md="12">
                              		<a class="float-right btn btn-outline-light btn-rounded" @click="showQuantitySelector = null" ><i class="bx bx-x font-size-14"></i></a>
                              			</b-col>
                              		</b-row>
                              		<b-row>
                              			<b-col md="12">
		                              		<b-form-group label="Add Quantity">
		                              			<b-form-input type="number" v-model="quantity">
		                              			</b-form-input>
		                              			<a lass="mr-3 ml-0 btn btn-primary mt-2 btn-rounded ml-auto btn-block">Add to Cart <i class="mdi mdi-shopping-outline"></i></a>
		                              		</b-form-group>
		                              	</b-col>
		                              </b-row>
                              		
                              	</b-card>
              					</div>
                              	</div>-->
                              </div>
                              

                              <!--<li class="list-group-item py-4 text-center" ><a class="btn btn-outline-light btn-rounded">Load More</a>
                              </li>-->
                            </div>
                <ul class="list-group product-list w-100 no-shadow"  v-if="(product_list.length == 0) && (view == 'grid')">
                	  <li class="list-group-item d-flex">
                	  	<i class="bx bx-warning"></i> No Products <span v-if="resource !== ''">assigned to this {{resource}}</span>
                	  </li>
                </ul>
          </div>
          <div class="product-modal d-flex flex-column justify-content-center" v-if="quickviewproduct !== ''">
          	<div class=" d-flex flex-column px-2 product-grid-item mb-2 mx-auto col-md-8 col-sm-10">
                              	<div class="border shadow-sm product-grid-item-container bg-white row">
                              		<div class="col-md-6 m-0 p-0">
                              	<img v-if="quickviewproduct.cover_image" class="w-100 quickviewproduct-grid-img" :src="quickviewproduct.cover_image">
                              	<img v-else class="w-100 product-list-img" src="/images/product-placeholder.png"></div>
                              	<div class="col-md-6 d-flex flex-column justify-content-center">
                              		<a href="javascript:;" @click="quickviewproduct = ''" class="btn btn-rounded btn-outline-light float-right mr-2 mt-2 position-absolute close-modal"><i class="mdi mdi-close noti-icon font-size-20"></i></a>
                              	                                <div class="my-3 px-3">
                                	<h2 class="mr-auto my-auto text-primary">{{quickviewproduct.name}}</h2>
                                	<span class="badge badge-primary">{{quickviewproduct.reference}}</span>
						            <p class="text-muted italic no-margin" v-html="quickviewproduct.short_description"></p>
                                </div>
                                <div class="my-1 px-3"  v-if="marketplace == ('company' || 'public')">
                                	<strong class="text-muted mb-auto">£{{quickviewproduct.sales_price}} <small class="mr-3">exc. VAT</small></strong>
                                </div>
                                <div class="my-1 px-3"  v-if="marketplace == ('company' || 'public')">
                                	<strong class="text-muted mb-auto"> £{{priceIncVat(quickviewproduct.sales_price, quickviewproduct.tax_rule.percentage)}} <small class="mr-3">inc. VAT</small></strong>
                                </div>
                                <div class="my-1 px-3" v-if="marketplace == 'trade'">
                                	<strong class="text-muted mb-auto">£{{quickviewproduct.trade_price}} <small class="mr-3">Trade Price</small></strong>
                                </div>
                                <div class="d-flex grab-row">
											            <a 
					                          href="javascript: void(0);"
					                          class="badge bg-light bg-soft rounded-pill font-size-11 m-1"
					                         v-if="quickviewproduct.trade_marketplace_defaultcategory !== null"><img class="rounded-circle avatar-xs" v-if="quickviewproduct.trade_marketplace_defaultcategory.image" :src="`${quickviewproduct.trade_marketplace_defaultcategory.image}`" alt /> <img v-else  src="/images/banner-placeholder.png" alt="" class="rounded-circle avatar-xs"> <span class="text-dark">{{quickviewproduct.trade_marketplace_defaultcategory.name}}</span></a>

					                         <a 
					                         v-if="quickviewproduct.trade_marketplace_categories.length >0"
					                          href="javascript: void(0);"
					                          class="badge bg-light bg-soft rounded-pill font-size-11 m-1"
					                          v-for="category in quickviewproduct.trade_marketplace_categories"><img v-if="category.category.image" class="rounded-circle avatar-xs" :src="`${category.category.image}`" alt /> <img v-else src="/images/banner-placeholder.png" alt="" class="rounded-circle avatar-xs">  <span class="text-dark">{{category.category.name}}</span></a>
					                        </div>
                                <div class="my-3 px-3">
                                	<a class="btn btn-outline-light btn-rounded ml-auto my-auto flex-grow-3" href="javascript:;"><i class="bx bx-pencil"></i></a>
                                <a class="btn btn-outline-light btn-rounded ml-auto my-auto flex-grow-3" href="javascript:;" @click="removeResource(quickviewproduct)"><i class="bx bx-trash-alt"></i></a>
              					</div>
              				</div>
                              	</div>
                              </div>
          </div>
     </div>
</template>