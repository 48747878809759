<template>
	<div>
		<transition name="slide-success" mode="out-in">
                  <b-alert id="success-message" variant="success" :show="success_message"  dismissible @dismissed="success_message = !success_message">
                      <p class="font-size-14">{{success_text}}</p>
                    </b-alert>
                  </transition>
                  </b-col>
               
                    <transition name="slide-error" mode="out-in">
                  <b-alert id="errors" variant="danger" :show="error_message"  dismissible @dismissed="error_message = !error_message">
                      <p class="font-size-14">Oops! Please correct the following errors in your submission</p>
                      <ul>
                        <li v-for="(error, key) in error_reponse">
                          {{error[0]}}
                        </li>
                      </ul>
                      <small>Please Try Again. If this error persists, please contact support.</small>
                    </b-alert>
                  </transition>
		<div v-if="$attrs.content[1].layout.toLowerCase() == 'plain'">
			
		<form v-if="form !== null">
			 <transition name="slide-success" mode="out-in">
                  <b-alert id="success-message" variant="success" :show="success_message"  dismissible @dismissed="success_message = !success_message">
                      <p class="font-size-14">{{success_text}}</p>
                    </b-alert>
                  </transition>
                  </b-col>
               
                    <transition name="slide-error" mode="out-in">
                  <b-alert id="errors" variant="danger" :show="error_message"  dismissible @dismissed="error_message = !error_message">
                      <p class="font-size-14">Oops! Please correct the following errors in your submission</p>
                      <ul>
                        <li v-for="(error, key) in error_reponse">
                          {{error[0]}}
                        </li>
                      </ul>
                      <small>Please Try Again. If this error persists, please contact support.</small>
                    </b-alert>
                  </transition>
			<SubscriptionWidgetForm :form="form" />
			 <a class="btn btn-primary btn-block" v-if="$attrs.liveMode == true" @click="submitForm()">
                	Submit
                </a>
        <a class="btn btn-primary btn-block" v-else>
                	Submit
                </a>    
		</form>
		</div>
		<div v-else-if="$attrs.content[1].layout.toLowerCase() == 'modal'">	
				<a class="btn btn-primary btn-block" @click="$bvModal.show('subscriptionFormModal')">
					Subscribe
				</a>

				<b-modal id="subscriptionFormModal" hide-footer hide-header centered>
					<form v-if="form !== null">

						<a :id="'formTrigger-'+$attrs.id" class="position-absolute top-0 right-0 m-2 btn" @click="$bvModal.hide('subscriptionFormModal')">
							<i class="bx bx-x font-size-20"></i>
						</a>
							<SubscriptionWidgetForm :form="form" />
					 <a class="btn btn-primary btn-block" v-if="$attrs.liveMode == true" @click="submitForm()">
                	Submit
                </a>
        <a class="btn btn-primary btn-block" v-else>
                	Submit
                </a>  
                </a>

						</form>
				</b-modal>
		</div>
		<div v-else-if="$attrs.content[1].layout.toLowerCase() == 'popup'">	
				<b-modal hide-footer hide-header centered v-model="modalPopout" >
					<form v-if="form !== null">

						<a :id="'formTrigger-'+$attrs.id" class="position-absolute top-0 right-0 m-2 btn" @click="modalPopout = false">
							<i class="bx bx-x font-size-20"></i>
						</a>
							<SubscriptionWidgetForm :form="form" />
					 <a class="btn btn-primary btn-block" v-if="$attrs.liveMode == true" @click="submitForm()">
                	Submit
                </a>
        <a class="btn btn-primary btn-block" v-else>
                	Submit
                </a>    

						</form>
				</b-modal>
		</div>
	</div>
</template>

<script type="text/javascript">
	import SubscriptionWidgetForm from './SubscriptionWidgetForm';
	export default{
		components: {
			SubscriptionWidgetForm
		},
		data(){
			return{
				modalPopped: false,
				modalPopout: false,
				form: null,
				compileStyles: '',
				error_reponse: [],
				error_message: false,
				success_message: false,
				success_text: '',
			}
		},
		mounted(){
			var myEle = document.getElementById("formCss-"+this.$attrs.id);

			if(!myEle) {
			var h = document.getElementsByTagName('head').item(0);
			var f = document.createElement("style");
			f.type = "text/css"; 
			f.id = "formCss-"+this.$attrs.id;
			h.appendChild(f);
			}
			this.getFormStyles;
		},
		watch: {
			'$attrs.configs'(){
				this.getFormStyles;
			}
		},
		created(){
			this.getFormDetails(this.$attrs.content[0].form);
			if(this.$attrs.content[1].layout.toLowerCase() == 'popup'){
					this.popUp(this.$attrs.content[1].popup_action);
			}
			//this.getFormStyles(this.$attrs.configs);
		},
		computed: {
			getFormStyles(){
				var compiledstyle = '';
				compiledstyle += '#'+this.$attrs.id+ ' form input{ will-change: auto; ';
				compiledstyle += 'border-style: ' +this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'field_border_style')].field_border_style.value + ' !important; ';
				compiledstyle += 'border-color: ' +this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'field_border_colour')].field_border_colour.value + ' !important; ';
				compiledstyle += 'border-top-width: ' +this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'field_border')].field_border.top + 'px !important; ';
				compiledstyle += 'border-bottom-width: ' +this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'field_border')].field_border.bottom + 'px !important; ';
				compiledstyle += 'border-left-width: ' +this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'field_border')].field_border.left + 'px !important; ';
				compiledstyle += 'border-right-width: ' +this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'field_border')].field_border.right + 'px !important; ';
				compiledstyle += 'border-top-right-radius: ' + ( this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'field_border_radius')].field_border_radius.top_right !== null ? this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'field_border_radius')].field_border_radius.top_right : '' ) + this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'field_border_radius')].field_border_radius.units +' !important; ';
				compiledstyle += 'border-bottom-right-radius: ' + (this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'field_border_radius')].field_border_radius.bottom_right !== null ? this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'field_border_radius')].field_border_radius.bottom_right : '' ) + this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'field_border_radius')].field_border_radius.units +' !important; ';
				compiledstyle += 'border-top-left-radius: ' + (this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'field_border_radius')].field_border_radius.top_left !== null ? this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'field_border_radius')].field_border_radius.top_left : 0 ) + this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'field_border_radius')].field_border_radius.units +' !important; ';
				compiledstyle += 'border-bottom-left-radius: ' + (this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'field_border_radius')].field_border_radius.bottom_left !== null ? this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'field_border_radius')].field_border_radius.bottom_left : '') + this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'field_border_radius')].field_border_radius.units +' !important; '
					compiledstyle += 'background: ' + (this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'field_background_colour')].field_background_colour.value !== null ? this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'field_background_colour')].field_background_colour.value : '') +' !important; ';
				compiledstyle += '} ';

				compiledstyle += '#'+this.$attrs.id+ ' form .btn{ will-change: auto; ';
				compiledstyle += 'background: ' +this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'button_colour')].button_colour.value + ' !important; ';
				compiledstyle += 'color: ' +this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'button_text_colour')].button_text_colour.value + ' !important; ';
				compiledstyle += 'border-style: ' +this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'button_border_style')].button_border_style.value + ' !important; ';
				compiledstyle += 'border-color: ' +this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'button_border_colour')].button_border_colour.value + ' !important; ';
				compiledstyle += 'border-top-width: ' +this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'button_border')].button_border.top + 'px !important; ';
				compiledstyle += 'border-bottom-width: ' +this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'button_border')].button_border.bottom + 'px !important; ';
				compiledstyle += 'border-left-width: ' +this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'button_border')].button_border.left + 'px !important; ';
				compiledstyle += 'border-right-width: ' +this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'button_border')].button_border.right + 'px !important; ';
				compiledstyle += 'border-top-right-radius: ' + ( this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'button_border_radius')].button_border_radius.top_right !== null ? this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'button_border_radius')].button_border_radius.top_right : '' ) + this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'button_border_radius')].button_border_radius.units +' !important; ';
				compiledstyle += 'border-bottom-right-radius: ' + (this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'button_border_radius')].button_border_radius.bottom_right !== null ? this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'button_border_radius')].button_border_radius.bottom_right : '' ) + this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'button_border_radius')].button_border_radius.units +' !important; ';
				compiledstyle += 'border-top-left-radius: ' + (this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'button_border_radius')].button_border_radius.top_left !== null ? this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'button_border_radius')].button_border_radius.top_left : 0 ) + this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'button_border_radius')].button_border_radius.units +' !important; ';
				compiledstyle += 'border-bottom-left-radius: ' + (this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'button_border_radius')].button_border_radius.bottom_left !== null ? this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'button_border_radius')].button_border_radius.bottom_left : '') + this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'button_border_radius')].button_border_radius.units +' !important; ';

				compiledstyle += '} ';
				this.compileStyles = compiledstyle;
				document.getElementById("formCss-"+this.$attrs.id).innerHTML = compiledstyle;		
				return compiledstyle;
				
			},
		},
		methods: {
			popUp(state){
					if(state == 'Page Load'){
							this.modalPopout = true;
					}else if(state == 'Page Exit'){
							let self = this;
							document.addEventListener('mousemove', function(event) {
		    				// Check if the mouse is near the top of the viewport (50 pixels from the top)

										if(self.modalPopped == true){
											window.localStorage.setItem('form-'+self.form.uid);
										}

		    						if ((event.clientY < 50) && (self.modalPopped == false)) {
		    							console.log(event.clientY);
		        						
		        						self.modalPopout = true;
		        						self.modalPopped = true;
		    						}
								});
					}
			},
			filterStylesKey(styles, key){
                for(var i = 0; i < styles.length; i++){
                   var keys = Object.keys(styles[i])
                   if(keys.includes(key)){
                     return i;
                   }
                }
      },
			submitForm(){
        this.success_message = false;
      axios.post('/website-api/widget/subscription-list/form/'+this.form.company_customer_list_id+'/subscribe-user', {list: this.listData, form: this.form, name: this.form.fields[this.form.fields.findIndex(item => item.name == 'Name')].value, email: this.form.fields[this.form.fields.findIndex(item => item.name == 'Email')].value, company_customer_id: this.$attrs.user !== '' ? this.$attrs.user['id'] : null}).then(response => {
          
          this.success_text = 'Subscriber added to list';
          this.success_message = true;
          
          this.$bvModal.hide('addSubscriber');
          this.form = null;
      }).catch(error => {
        this.error_reponse = error.response.data.errors;
        this.error_message = true;

        let self = this;
          setTimeout(function(){
            self.loadingForm = false;
          }, 500);
      });
      },
			getFormDetails(uid){

				if(this.$attrs.liveMode == true){
					axios.get('/website-api/widget/subscription-list/form/'+uid).then(response => {
						this.form = response.data;
					}).then(error => {

					});
				}else{
					axios.get('/api/company/customer/list/'+uid+'/form', {headers:  {'Authorization' : 'Bearer ' + this.$attrs.user.api_token}}).then(response => {
						this.form = response.data;
					}).then(error => {

					});
				}
			}
		}

	}
</script>

