<script type="text/javascript">
	  export default{
    data(){
      return{
        csrf:  document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
      	newsupplieraddress: {
	      	id: null, 
	        name: '',
	        alias: '',
	        address1: '',
	        address2: '',
	        address3: '',
	        county: '',
	        country: '',
	        city: '',
	        postcode: '',
	        default: 0,
	        invoice: 0,
	        delivery: 0,
	        type: '',
    	},
      countries: [],
      counties: [],
      loadingForm: false,
      error_reponse: [],
      error_message: false,
      }
    },
    props: {
    	supplier_id: {
    		type: Number,
    		default: () => 0
    	},
    	api_token: {
    		type: String,
    		default: () => ''
    	},

    },
    methods: {
      addSupplierAddress(){
          this.loadingForm = true;
          var postForm = new FormData(document.getElementById("addsupplieraddressform"));
          axios.post('/api/company/product/supplier/'+this.supplier_id+'/address/create', postForm, {headers: {
                  'Authorization': 'Bearer ' + this.api_token
              }}).then(response => {
            console.log(response);
            this.$emit('supplier-address-added', response.data);
            //this.resetProjectData();
            //this.addPanel = false;
            let self = this;
              setTimeout(function(){
                self.loadingForm = false;
              }, 1500);
          }).catch( error => {
            
            this.error_reponse = error.response.data.errors;
            this.error_message = true;
            //this.resetProjectData();
            let self = this;
              setTimeout(function(){
                self.loadingForm = false;
              }, 1500);
          });
      },
      closeModal(){
        this.$emit('close');
      },
      getCountries(){
        if(this.countries.length == 0){
          axios.get('/api/countries', {headers: {
                  'Authorization': 'Bearer ' + this.api_token
              }}).then(response => {
            this.countries = response.data;
          }).catch(error => {
            this.load_error = error.data;
          })
        }
      },
      getCounties(country_id){
        // get counties as country selected
        axios.get('/api/country/'+country_id+'/counties', {headers: {
                  'Authorization': 'Bearer ' + this.api_token
              }}).then(response => {
          this.counties = response.data;
        }).catch(error => {
          this.load_error = error.data;
        })
      },
    },
    mounted(){
    	this.getCountries();
    }
  }
</script>

<template>
  <b-overlay
                        id="overlay-background"
                        :show="loadingForm"
                        variant="white"
                        opacity="0.85"
                        blur="1px"
                        rounded="md"
                      >
	<b-form id="addsupplieraddressform" method="POST" enctype="multipart/form-data">
                   <input type="hidden" name="_token" :value="csrf">
           <b-row>
                     <b-col md="12">
                    <transition name="slide-error" mode="out-in">
                    <b-alert id="errors" variant="danger" :show="error_message"  dismissible @dismissed="error_message = !error_message">
                      <p class="font-size-14">Oops! Please correct the following errors in your submission</p>
                      <ul>
                        <li v-for="(error, key) in error_reponse">
                          {{error[0]}}
                        </li>
                      </ul>
                    </b-alert>
                    
                  </transition>
                  </b-col>
          </b-row>
       
           <b-col md="12">
                <b-form-group  label="Address Alias/Name">
                  <b-form-input type="text" name="alias" v-model="newsupplieraddress.alias" ></b-form-input>
                </b-form-group>
                <b-form-group  label="Address 1">
                  <b-form-input type="text" name="address1" v-model="newsupplieraddress.address1" ></b-form-input>
                </b-form-group>
                <b-form-group class="mt-2" label="Address 2">
                  <b-form-input type="text" name="address2" v-model="newsupplieraddress.address2" ></b-form-input>
                </b-form-group>
                <b-form-group class="mt-2" label="Address 3">
                  <b-form-input type="text" name="address3" v-model="newsupplieraddress.address3" ></b-form-input>
                </b-form-group>
                
                <b-form-group class="mt-2" label="Country">
                  <b-form-select class="form-control" v-model="newsupplieraddress.country_id" name="country_id"  @input="getCounties(newsupplieraddress.country_id)">
                    <b-form-select-option v-for="country in countries" :value="country.id">
                      {{country.name}}
                    </b-form-select-option>
                  </b-form-select>
                </b-form-group>
                <b-form-group class="mt-2" label="County/State">
                  <b-form-select class="form-control" name="county_id"  v-model="newsupplieraddress.county_id"  :disabled="(counties.length == 0)">
                    <b-form-select-option v-for="county in counties" :value="county.id" >
                      {{county.name}}
                    </b-form-select-option>
                  </b-form-select>
                </b-form-group>
                <b-form-group class="mt-2" label="City">
                  <b-form-input type="text" v-model="newsupplieraddress.city" name="city"></b-form-input>
                </b-form-group>
                <b-form-group class="mt-2" label="Postcode/Zip">
                  <b-form-input type="text" v-model="newsupplieraddress.postcode" name="postcode"></b-form-input>
                </b-form-group>
                  <div
                        class="form-check form-switch form-switch-sm mt-3 mb-2 ml-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          :id="`SwitchCheckBilling`"
                          v-model="newsupplieraddress.invoice"
                          value="1"
                          unchecked-value="0"
                          name="active"
                        />
                        <label class="form-check-label" :for="`SwitchCheckBilling`" 
                          >Billing Address</label
                        >
                    </div>
                    <div
                        class="form-check form-switch form-switch-sm my-2 ml-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          :id="`SwitchCheckDelivery`"
                          v-model="newsupplieraddress.delivery"
                          value="1"
                          unchecked-value="0"
                          name="active"
                        />
                        <label class="form-check-label" :for="`SwitchCheckDelivery`" 
                          >Delivery Address</label
                        >
                    </div>
                    <div
                        class="form-check form-switch form-switch-sm my-2 ml-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          :id="`SwitchCheckDefault`"
                          v-model="newsupplieraddress.default"
                          value="1"
                          unchecked-value="0"
                          name="active"
                        />
                        <label class="form-check-label" :for="`SwitchCheckDefault`" 
                          >Default Address</label
                        >
                    </div>
               
              </b-col>
          <div class="d-flex flex-row mt-3">
          <b-button variant="success" class="w-md w-50 d-inline" @click="addSupplierAddress()"> <i class=" bx bx-save"></i> Save</b-button>
          <b-button variant="danger" class="w-md w-50 d-inline" @click="closeModal"> <i class=" bx bx-save"></i> Cancel</b-button>
          </div>
        </b-form>
      </b-overlay>
</template>