<template>
	<simplebar  v-if="$attrs.content[filterStylesKey($attrs.content, 'view')].view.value == 'list'">
		<b-list-group v-if="$attrs.listings">
			<b-list-group-item v-for="listing in $attrs.listings.data">
				<div class="media">
                                    <div class="avatar-sm me-3" v-if="listing.logo">
                                        <span
                                            class="avatar-title rounded-circle bg-light"
                                        >
                                            <!--<img
                                            		
                                                :src="listing.logo"
                                                alt=""
                                                height="18"
                                            />-->
                                            
                                        </span>
                                      </div>
                                       <div class="avatar-sm me-3" v-else>
                                        <span class="avatar-title rounded-circle" >{{(listing.name).slice(0,1)}}</span>
                                      </div>
                                   
                                    <div class="media-body">
                                        <h5 class="font-size-14">
                                            {{listing.name}}
                                        </h5>
                                        <p class="text-muted">
                                            <span v-if="listing.address_1">{{listing.address_1}},</span>
				          	<span v-if="listing.address_2">{{listing.address_2}},</span>
				          	<span v-if="listing.address_3">{{listing.address_3}},</span>
				          	<span v-if="listing.county">{{listing.county.name}},</span>
				          	<span v-if="listing.country">{{listing.country.name}},</span>
				          	<span v-if="listing.postcode">{{listing.postcode}}</span>
                                        </p>

                                        <div class="float-end">
                                            <p class="text-muted mb-0" v-if="listing.listing_type">
                                                <i 
                                                    class="bx bx-buildings me-1"
                                                ></i>
                                                {{listing.listing_type.name}}
                                            </p>
                                        </div>
                                        <p class="text-muted mb-0">
                                            <a class="text-primary" >See Location</a>
                                        </p>
                                    </div>
                                </div>
			</b-list-group-item>
		</b-list-group>
	</simplebar>
	<b-row v-else-if="$attrs.content[filterStylesKey($attrs.content, 'view')].view.value == 'grid'">
		<b-col  :class="'col-xl-'+$attrs.content[filterStylesKey($attrs.content, 'columns')].columns.value.xl+' col-lg-'+$attrs.content[filterStylesKey($attrs.content, 'columns')].columns.value.lg+' col-md-'+$attrs.content[filterStylesKey($attrs.content, 'columns')].columns.value.md+' col-sm-'+$attrs.content[filterStylesKey($attrs.content, 'columns')].columns.value.sm+' col-xs-'+$attrs.content[filterStylesKey($attrs.content, 'columns')].columns.value.xs" v-for="(listing, index) in $attrs.listings.data">
			<ListingCardWidget :listing="listing" :index="index" :liveMode="$attrs.liveMode"></ListingCardWidget>
		</b-col>
	</b-row>
    <swiper class="swiper overflow-hidden" v-else-if="($attrs.content[filterStylesKey($attrs.content, 'view')].view.value == 'carousel') && (contentReloading == false)" :ref="'listingCarousel-'+$attrs.id" :options="swiperOptions" >
        <swiper-slide v-if="contentReloading == false" v-for="(listing, index) in $attrs.listings.data" class="h-100" >
              <ListingCardWidget :listing="listing" :index="index" :liveMode="$attrs.liveMode"></ListingCardWidget>
        </swiper-slide>
            <div v-if="$attrs.content[filterStylesKey($attrs.content, 'carousel')]['carousel'].subfields[filterStylesKey($attrs.content[filterStylesKey($attrs.content, 'carousel')]['carousel'].subfields, 'arrows_navigation')].arrows_navigation.value == true" class="swiper-button-prev" slot="button-prev"></div>
            <div v-if="$attrs.content[filterStylesKey($attrs.content, 'carousel')]['carousel'].subfields[filterStylesKey($attrs.content[filterStylesKey($attrs.content, 'carousel')]['carousel'].subfields, 'arrows_navigation')].arrows_navigation.value == true" class="swiper-button-next" slot="button-next"></div>
              
    </swiper>
    <b-overlay v-else-if="contentReloading == true" :show="contentReloading">
    </b-overlay> 
</template>

<script type="text/javascript">
    import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
	import ListingCardWidget from './ListingCardWidget'
    import SwiperClass, { Pagination } from 'swiper'
    import 'swiper/swiper.min.css'
    import { EventBus } from "../../../../../app";

	export default {
		components: {
			ListingCardWidget,
		    Swiper,
            SwiperSlide
        },
        directives: {
            swiper: directive
        },
        computed: {
            swiper() {
                return this.$refs['listingCarousel-'+this.$attrs.id].$swiper
            },  
        },
        data() {
          return {
            contentReloading: false,
            hoverIndex: -1,
            selected_index: -1,
            storageurl: 'https://'+this.$attrs.company.nickname+'.s3.eu-west-2.amazonaws.com',
            swiperOptions: {
                  direction: 'horizontal',
                  loop: true,
                  slidesPerView: 3,
                  spaceBetween: 10,
                  pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                  },
                  navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                  },
                  breakpoints: {
                  0: {
                    slidesPerView: 1,
                    spaceBetween: 20
                  },
                  // when window width is >= 320px
                  350: {
                    slidesPerView: 1,
                    spaceBetween: 20
                  },
                  // when window width is >= 640px
                  768: {
                    slidesPerView: 2,
                    spaceBetween: 40
                  },
                  991: {
                    slidesPerView: 3,
                    spaceBetween: 40
                  },
                  1200: {
                    slidesPerView: 3,
                    spaceBetween: 40
                  },
                  1440: {
                    slidesPerView: 4,
                    spaceBetween: 40
                  }
                }
            },
          }
        },
        methods: {
            compileSlides(){
                var sizes = Object.keys(this.$attrs.page);
                for(var i = 0; i < sizes.length; i++){
                    console.log('Slide '+i+ ':'+sizes[i]);
                    var slide = sizes[i];
                    if(slide == 'xs'){
                                        this.swiperOptions.breakpoints[0].slidesPerView = parseFloat(this.$attrs.page[slide].sections[this.$attrs.block_array[0]].rows[this.$attrs.block_array[1]].cols[this.$attrs.block_array[2]].blocks[this.$attrs.block_array[3]].widgetcontent[this.filterStylesKey(this.$attrs.page[slide].sections[this.$attrs.block_array[0]].rows[this.$attrs.block_array[1]].cols[this.$attrs.block_array[2]].blocks[this.$attrs.block_array[3]].widgetcontent, 'carousel')].carousel.subfields[this.filterStylesKey(this.$attrs.page[slide].sections[this.$attrs.block_array[0]].rows[this.$attrs.block_array[1]].cols[this.$attrs.block_array[2]].blocks[this.$attrs.block_array[3]].widgetcontent[this.filterStylesKey(this.$attrs.page[slide].sections[this.$attrs.block_array[0]].rows[this.$attrs.block_array[1]].cols[this.$attrs.block_array[2]].blocks[this.$attrs.block_array[3]].widgetcontent, 'carousel')].carousel.subfields, 'show')].show.value).toFixed(0);
                                    }else if(slide == 'sm'){
                                        this.swiperOptions.breakpoints[350].slidesPerView = parseFloat(this.$attrs.page[slide].sections[this.$attrs.block_array[0]].rows[this.$attrs.block_array[1]].cols[this.$attrs.block_array[2]].blocks[this.$attrs.block_array[3]].widgetcontent[this.filterStylesKey(this.$attrs.page[slide].sections[this.$attrs.block_array[0]].rows[this.$attrs.block_array[1]].cols[this.$attrs.block_array[2]].blocks[this.$attrs.block_array[3]].widgetcontent, 'carousel')].carousel.subfields[this.filterStylesKey(this.$attrs.page[slide].sections[this.$attrs.block_array[0]].rows[this.$attrs.block_array[1]].cols[this.$attrs.block_array[2]].blocks[this.$attrs.block_array[3]].widgetcontent[this.filterStylesKey(this.$attrs.page[slide].sections[this.$attrs.block_array[0]].rows[this.$attrs.block_array[1]].cols[this.$attrs.block_array[2]].blocks[this.$attrs.block_array[3]].widgetcontent, 'carousel')].carousel.subfields, 'show')].show.value).toFixed(0);
                                    }else if(slide == 'md'){
                                        this.swiperOptions.breakpoints[768].slidesPerView = parseFloat(this.$attrs.page[slide].sections[this.$attrs.block_array[0]].rows[this.$attrs.block_array[1]].cols[this.$attrs.block_array[2]].blocks[this.$attrs.block_array[3]].widgetcontent[this.filterStylesKey(this.$attrs.page[slide].sections[this.$attrs.block_array[0]].rows[this.$attrs.block_array[1]].cols[this.$attrs.block_array[2]].blocks[this.$attrs.block_array[3]].widgetcontent, 'carousel')].carousel.subfields[this.filterStylesKey(this.$attrs.page[slide].sections[this.$attrs.block_array[0]].rows[this.$attrs.block_array[1]].cols[this.$attrs.block_array[2]].blocks[this.$attrs.block_array[3]].widgetcontent[this.filterStylesKey(this.$attrs.page[slide].sections[this.$attrs.block_array[0]].rows[this.$attrs.block_array[1]].cols[this.$attrs.block_array[2]].blocks[this.$attrs.block_array[3]].widgetcontent, 'carousel')].carousel.subfields, 'show')].show.value).toFixed(0);
                                    }else if(slide == 'lg'){
                                        this.swiperOptions.breakpoints[991].slidesPerView = parseFloat(this.$attrs.page[slide].sections[this.$attrs.block_array[0]].rows[this.$attrs.block_array[1]].cols[this.$attrs.block_array[2]].blocks[this.$attrs.block_array[3]].widgetcontent[this.filterStylesKey(this.$attrs.page[slide].sections[this.$attrs.block_array[0]].rows[this.$attrs.block_array[1]].cols[this.$attrs.block_array[2]].blocks[this.$attrs.block_array[3]].widgetcontent, 'carousel')].carousel.subfields[this.filterStylesKey(this.$attrs.page[slide].sections[this.$attrs.block_array[0]].rows[this.$attrs.block_array[1]].cols[this.$attrs.block_array[2]].blocks[this.$attrs.block_array[3]].widgetcontent[this.filterStylesKey(this.$attrs.page[slide].sections[this.$attrs.block_array[0]].rows[this.$attrs.block_array[1]].cols[this.$attrs.block_array[2]].blocks[this.$attrs.block_array[3]].widgetcontent, 'carousel')].carousel.subfields, 'show')].show.value).toFixed(0);               
                                    }else if(slide =  'xl'){
                                        this.swiperOptions.breakpoints[1200].slidesPerView = parseFloat(this.$attrs.page[slide].sections[this.$attrs.block_array[0]].rows[this.$attrs.block_array[1]].cols[this.$attrs.block_array[2]].blocks[this.$attrs.block_array[3]].widgetcontent[this.filterStylesKey(this.$attrs.page[slide].sections[this.$attrs.block_array[0]].rows[this.$attrs.block_array[1]].cols[this.$attrs.block_array[2]].blocks[this.$attrs.block_array[3]].widgetcontent, 'carousel')].carousel.subfields[this.filterStylesKey(this.$attrs.page[slide].sections[this.$attrs.block_array[0]].rows[this.$attrs.block_array[1]].cols[this.$attrs.block_array[2]].blocks[this.$attrs.block_array[3]].widgetcontent[this.filterStylesKey(this.$attrs.page[slide].sections[this.$attrs.block_array[0]].rows[this.$attrs.block_array[1]].cols[this.$attrs.block_array[2]].blocks[this.$attrs.block_array[3]].widgetcontent, 'carousel')].carousel.subfields, 'show')].show.value).toFixed(0);              
                                        this.swiperOptions.slidesPerView = parseFloat(this.$attrs.page[slide].sections[this.$attrs.block_array[0]].rows[this.$attrs.block_array[1]].cols[this.$attrs.block_array[2]].blocks[this.$attrs.block_array[3]].widgetcontent[this.filterStylesKey(this.$attrs.page[slide].sections[this.$attrs.block_array[0]].rows[this.$attrs.block_array[1]].cols[this.$attrs.block_array[2]].blocks[this.$attrs.block_array[3]].widgetcontent, 'carousel')].carousel.subfields[this.filterStylesKey(this.$attrs.page[slide].sections[this.$attrs.block_array[0]].rows[this.$attrs.block_array[1]].cols[this.$attrs.block_array[2]].blocks[this.$attrs.block_array[3]].widgetcontent[this.filterStylesKey(this.$attrs.page[slide].sections[this.$attrs.block_array[0]].rows[this.$attrs.block_array[1]].cols[this.$attrs.block_array[2]].blocks[this.$attrs.block_array[3]].widgetcontent, 'carousel')].carousel.subfields, 'show')].show.value).toFixed(0);
                                    }
                }
            },
            filterStylesKey(styles, key){
                for(var i = 0; i < styles.length; i++){
                   var keys = Object.keys(styles[i])
                   if(keys.includes(key)){
                     return i;
                   }
                }
            },
        },
        watch: {
            '$attrs.content'(){
                 if(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields, 'show')].show.value !== ''){
                    this.swiperOptions.slidesPerView = parseFloat(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields, 'show')].show.value).toFixed(0);
                }else{
                    this.swiperOptions.slidesPerView = 3;
                }
                if(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields, 'loop')].loop.value !== ''){
                    this.swiperOptions.loop = this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields, 'loop')].loop.value;
                }else{
                    this.swiperOptions.loop = false;
                }
                if(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields, 'margin')].margin.value !== ''){
                    this.swiperOptions.spaceBetween = this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields, 'margin')].margin.value;
                }else{
                    this.swiperOptions.spaceBetween = 10;
                }
                if(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields, 'direction')].direction.value !== ''){
                    this.swiperOptions.direction = this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields, 'direction')].direction.value;
                }else{
                    this.swiperOptions.direction = 'horizontal';
                }
            },
            '$attrs.content[filterStylesKey($attrs.content, \'carousel\')].carousel.subfields[filterStylesKey($attrs.content[filterStylesKey($attrs.content, \'carousel\')].carousel.subfields, \'show\')].show.value'(){
                if(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields, 'show')].show.value !== ''){
                    this.swiperOptions.slidesPerView = parseFloat(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields, 'show')].show.value).toFixed(0);
                    this.swiper.loopedSlide = parseFloat(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields, 'show')].show.value).toFixed(0);
                }else{
                    this.swiperOptions.slidesPerView = 3;
                }
              
            },
            '$attrs.content[filterStylesKey($attrs.content, \'carousel\')].carousel.subfields[filterStylesKey($attrs.content[filterStylesKey($attrs.content, \'carousel\')].carousel.subfields, \'margin\')].margin.value;'(){
                if(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields, 'margin')].margin.value !== ''){
                    this.swiperOptions.loop = this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields, 'margin')].margin.value;
                }else{
                    this.swiperOptions.loop = 10;
                }
                
            },
            '$attrs.content[filterStylesKey($attrs.content, \'carousel\')].carousel.subfields[filterStylesKey($attrs.content[filterStylesKey($attrs.content, \'carousel\')].carousel.subfields, \'loop\')].loop.value'(){
                if(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields, 'loop')].loop.value !== ''){
                    this.swiperOptions.loop = this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields, 'loop')].loop.value;
                }else{
                    this.swiperOptions.loop = false;
                }
               
            },
            '$attrs.content[filterStylesKey($attrs.content, \'carousel\')].carousel.subfields[filterStylesKey($attrs.content[filterStylesKey($attrs.content, \'carousel\')].carousel.subfields, \'direction\')].direction.value'(){
                if(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields, 'direction')].direction.value !== ''){
                    this.swiperOptions.direction = this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields, 'direction')].direction.value;
                }else{
                    this.swiperOptions.direction = 'horizontal';
                }
                
            }
        },
        created(){
                if(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields, 'show')].show.value !== ''){
                    this.swiperOptions.slidesPerView = parseFloat(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields, 'show')].show.value).toFixed(0);
                }else{
                    this.swiperOptions.slidesPerView = 3;
                }
                if(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields, 'loop')].loop.value !== ''){
                    this.swiperOptions.loop = this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields, 'loop')].loop.value;
                }else{
                    this.swiperOptions.loop = false;
                }
                if(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields, 'margin')].margin.value !== ''){
                    this.swiperOptions.spaceBetween = this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields, 'margin')].margin.value;
                }else{
                    this.swiperOptions.spaceBetween = 10;
                }
                if(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields, 'direction')].direction.value !== ''){
                    this.swiperOptions.direction = this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields, 'direction')].direction.value;
                }else{
                    this.swiperOptions.direction = 'horizontal';
                }
        },
        mounted() {
            this.compileSlides();
            console.log('Current Swiper instance object', this.swiper);
            this.swiper.slideTo(this.startSlide, 1000, false);
                if(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields, 'show')].show.value !== ''){
                    this.swiperOptions.slidesPerView = parseFloat(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields, 'show')].show.value).toFixed(0);
                }else{
                    this.swiperOptions.slidesPerView = 3;
                }
                if(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields, 'loop')].loop.value !== ''){
                    this.swiperOptions.loop = this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields, 'loop')].loop.value;
                }else{
                    this.swiperOptions.loop = false;
                }
                if(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields, 'margin')].margin.value !== ''){
                    this.swiperOptions.spaceBetween = this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields, 'margin')].margin.value;
                }else{
                    this.swiperOptions.spaceBetween = 10;
                }
                if(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields, 'direction')].direction.value !== ''){
                    this.swiperOptions.direction = this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields, 'direction')].direction.value;
                }else{
                    this.swiperOptions.direction = 'horizontal';
                }
                let self = this;
                EventBus.$on('update-widget', function(){
                    self.contentReloading = true;
                    setTimeout(function(){
                        self.contentReloading = false;
                    }, 1000);
                });
            },
	}

</script>