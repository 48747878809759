<script>
/**
 * Login component
 */
export default {
  data() {
    return {
      username: 'joebloggs88',
      email: "jobblogs@example.com",
      password: "123456789",
      tryingToLogIn: false,
      isAuthError: false,
    };
  },
  props: {
    submitUrl: {
      type: String,
      required: true,
    },
    authError: {
      type: String,
      required: false,
      default: () => null,
    },
  },
  mounted() {
    this.isAuthError = !!this.authError;
  },
  methods: {
    login(){
      var postForm = new FormData(document.getElementById("login"));
      axios.post('/account/login', postForm).then(response => {
        console.log(response.data);
      }).catch(error => {
        console.log(response.data);
      });
    }
  }
};
</script>

<template>
  <div class="row justify-content-center">
    <div class="col-md-12">
      <div class="card overflow-hidden border border-radius-1em shadow-none mb-0">
        <!--<div class="bg-soft-primary">
          <div class="row" style="background-image: url('/images/diamond-bg.webp'); background-size: cover; min-height: 250px">
            <div class="col-12">
              <div class="text-primary p-4 text-center d-flex flex-column justify-content-center" style="background: rgba(94, 59, 176, 0.75); height: 100%">
                <h2 class="text-light mb-0">Welcome Back</h2>
                <p class="text-light">Sign in to your Gemesys account</p>
              </div>
            </div>
          </div>
        </div>-->
        <div class="card-body pt-0">
         <!--<div>
            <a href="/">
              <div class="avatar-md mb-0 position-relative mx-auto mt-3">
                <span class="avatar-title rounded-circle bg-light">
                   <div class="cube-wrapper m-auto"><div class="cube-folding"><span class="leaf1"></span><span class="leaf2"></span><span class="leaf3"></span><span class="leaf4"></span></div></div>
                </span>
              </div>
            </a>
          </div>
          -->
          <b-alert
            v-model="isAuthError"
            variant="danger"
            class="mt-3"
            dismissible
            >{{ authError }}</b-alert
          >

          <b-form id="login" class="p-2" :action="submitUrl" method="POST">
            <slot />
            <b-form-group
              id="input-group-1"
              label="Username"
              label-for="input-1"
              class="my-3"
            >
              <b-form-input
                id="input-1"
                name="username"
                v-model="username"
                type="text"
                placeholder="Enter username"
              ></b-form-input>
            </b-form-group>

            <!--<b-form-group
              id="input-group-1"
              label="Email"
              label-for="input-1"
              class="my-3"
            >
              <b-form-input
                id="input-1"
                name="email"
                v-model="email"
                type="text"
                placeholder="Enter email"
              ></b-form-input>
            </b-form-group>
            -->

            <b-form-group
              id="input-group-2"
              label="Password"
              label-for="input-2"
              class="mb-3"
            >
              <b-form-input
                id="input-2"
                v-model="password"
                name="password"
                type="password"
                placeholder="Enter password"
              ></b-form-input>
            </b-form-group>
            <b-form-checkbox
              class="form-check"
              id="customControlInline"
              name="checkbox-1"
              value="accepted"
              unchecked-value="not_accepted"
            >
              Remember me
            </b-form-checkbox>
            <div class="mt-3 d-grid">
              <b-button type="submit" variant="primary" class="btn-primary btn-rounded btn-block mb-2"
                >Log In</b-button
              >
              <a class="btn btn-light btn-rounded btn-block" @click="$emit('register')">Create Account</a>
            </div>
            <!--<div class="mt-4 text-center">
              <h5 class="font-size-14 mb-3">Sign in with</h5>

              <ul class="list-inline">
                <li class="list-inline-item">
                  <a
                    href="javascript: void(0);"
                    class="social-list-item bg-primary text-white border-primary"
                  >
                    <i class="mdi mdi-facebook"></i>
                  </a>
                </li>
                <li class="list-inline-item">
                  <a
                    href="javascript: void(0);"
                    class="social-list-item bg-info text-white border-info"
                  >
                    <i class="mdi mdi-twitter"></i>
                  </a>
                </li>
                <li class="list-inline-item">
                  <a
                    href="javascript: void(0);"
                    class="social-list-item bg-danger text-white border-danger"
                  >
                    <i class="mdi mdi-google"></i>
                  </a>
                </li>
              </ul>
            </div>-->
            <div class="mt-4 text-center">
              <a href="/reset/password" class="text-muted">
                <i class="mdi mdi-lock mr-1"></i> Forgot your password?
              </a>
            </div>
          </b-form>
        </div>
        <!-- end card-body -->
      </div>
      <!-- end card -->
      <!-- end row -->
    </div>
    <!-- end col -->
  </div>
  <!-- end row -->
</template>

