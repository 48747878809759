<script type="text/javascript">
   export default{
    data: function(){
      return{
        weightInAir: '',
        weightInWater: '',
        specificGravity: 0.00, 
        marginError: 0,
      }
    },
    methods:{
      calculateSpecificGravity(){
        this.specificGravity = (this.weightInAir / (this.weightInAir - this.weightInWater)).toFixed(2);
      }
    }
  }
</script>
  <template>
    <b-card><div class="row"><div class="col-md-8 p-4"><h3 class="pb-3">Specific Gravity Calculator</h3><label>Weight in Air</label><input v-model="weightInAir" class="form-control"  placeholder="0.00" type="number" @keyup="calculateSpecificGravity"></input><label>Weight in Water</label><input v-model="weightInWater" placeholder="0.00" class="form-control" type="number" @keyup="calculateSpecificGravity"></input><div class="row"><div class="col-md-6 mt-4 text-center py-4"><label>Estimated Specific Gravity</label><p class="result-value font-size-24 text-primary">{{specificGravity}}</p></div><div class="col-md-6 mt-4 text-center py-4"><label>Margin of Error</label><p class="result-value font-size-24 text-primary">+/- {{marginError}}</p></div></div></div></div></b-card>
  </template>