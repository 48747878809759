<script type="text/javascript">

	import VueSlider from 'vue-slider-component'
	import ImageBlocks from "../components/widgets/blocks/ImageBlocks";
	import MiniCardCarousel from "../components/widgets/carousel/MiniCardCarousel";
	import Swiper from "../components/widgets/carousel/Swiper";
	import PublicMarketplaceProductList from "../components/widgets/PublicMarketplaceProductList";
	import FilterPublicProductBrands from "../components/widgets/filters/public/FilterPublicProductBrands";
	import FilterPublicProductCategories from "../components/widgets/filters/public/FilterPublicProductCategories";
	import FilterPublicProductCollections from "../components/widgets/filters/public/FilterPublicProductCollections";
		import CustomerSellerEnquiry from "../components/widgets/forms/public/CustomerSellerEnquiry";
	import Login from "../public-views/account/onpageLogin";
	import { EventBus } from '../public-app';
	import SocialShareBlock from "../components/widgets/page-builder/widgets/web/SocialShareBlock"
	import ReviewForm from "../components/widgets/forms/ReviewForm"
	import ReviewCard from "../components/widgets/forms/ReviewCard"
	import ReviewCarousel from "../components/widgets/forms/ReviewCarousel"
	export default{
		components: { Swiper, ImageBlocks, MiniCardCarousel, PublicMarketplaceProductList, VueSlider, FilterPublicProductBrands, FilterPublicProductCategories, FilterPublicProductCollections, Login, CustomerSellerEnquiry, SocialShareBlock, ReviewForm, ReviewCard, ReviewCarousel },
		data(){
			return{
				title: '',
				breadcrumbs: [],
				bread: {
					title: '',
					link: '',
					id: 1,
				},
				csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
				categorydata: '',
				categoriesdata: '',
				data: '',
				form: {
					action: '',
					name: '',
					lastname: '',
					email: '',
					customer_uid: '',
					share_details: '',
					review: '',
					message: '',
					review_score: 0,
				},
				meta: {
					title: '',
					description: '',
					image: '', 
					og: {
						title: '',
						description: '',
						image: '',
						url: '',
					}
				},
				reviewOptions: {
		          slidesPerView: 3,
		          spaceBetween: 30,
		          autoplay: {
				      delay: 2500,
				      disableOnInteraction: false
				    },
		          loop: false,
		          loopFillGroupWithBlank: false,
		          pagination: {
		            el: '.swiper-pagination',
		            clickable: true,
		          },
		          breakpoints: {
			          0: {
			            slidesPerView: 1,
			            spaceBetween: 20
			          },
			          // when window width is >= 320px
			          420: {
			            slidesPerView: 1,
			            spaceBetween: 20
			          },
			          // when window width is >= 480px
			          567: {
			            slidesPerView: 2,
			            spaceBetween: 30
			          },
			          // when window width is >= 640px
			          767: {
			            slidesPerView: 2,
			            spaceBetween: 40
			          },
			          990: {
			            slidesPerView: 2,
			            spaceBetween: 40
			          },
			          1240: {
			            slidesPerView: 3,
			            spaceBetween: 40
			          }

			        }
		          // Some Swiper option/callback...
		        },
				infomodalaction: 'reviews',
				storageurl: '.s3.eu-west-2.amazonaws.com/',
				productsLoading: true,
				showFilters: false,
		        sliderPrice: 800,
		        priceRange: [0,250000],
		        brands: [],
		        collections: [],
		        categories: [],
		        sortby: 'created_at',
		        order: 'desc',
		        search: '',
		        view: 'grid',
		        public_active: 1,
		        trade_active: '',
		        active: '',
		        expanded: 1,
		        currentPage: 1,
		        query: '',
		        limit: 25,
				limits: [
				        {value: 25, text: "25"},
				        {value: 50, text: "50"},
				        {value: 100, text: "100"},
				      ],
			}
		},
		props: {
			user: {
				type: Object
			}
		},
		mounted() {
			this.createQueryString();
			this.loadSellerInfo();
			this.loadSellerProducts();
			this.loadSellerCollections();
			this.checkUserLoggedIn();
			//this.loadSellerCollections();

		},
		created(){
			window.scrollTo(0,0);
			let self = this;
			EventBus.$on('category-select', function(id){
				self.filterSelectedCategories(id);
		    });
			EventBus.$on('categories-cleared', function(id){
				self.categories = [];
				self.loadSellerProducts();
		    });
		},
		methods: {
			injectMeta(product){
									// Create a script element for JSON-LD schema

					this.meta['title'] = product.name;
					this.meta['og']['title'] = product.name;
					if(product.description !== null){
						this.meta['description'] = product.description.replaceAll("<[^>]*>", "");
						this.meta['og']['description'] = product.description.replaceAll("<[^>]*>", "");
					}
					this.meta['og']['url'] = 'https://gltrbox.co.uk'+this.$route.path;
					this.meta['image'] = 'https://gltrbox.co.uk/public-image'+product.image+'?s='+product.company.nickname;
					this.meta['og']['image'] = 'https://gltrbox.co.uk/public-image'+product.image+'?s='+product.company.nickname;

					EventBus.$emit('inject-meta', this.$route, this.meta)
			},
			checkUserLoggedIn(){
				axios.get('/customer-api/user').then(response => {
					console.log(response);
				}).catch( error => {
					console.log(error);
				})
			},
			loadSellerProducts(){
				this.createQueryString();
				this.productsLoading = true;
				//console.log(this.$route.path);
				var routepath = this.$route.path;
				var path = routepath.replace('/profile', '');
				console.log(path);
				axios.get('/customer-api'+path+'/products'+this.query).then(response => {
					this.data = response.data;
				}).catch(error => {
					console.log(error);
				});
				var self = this;
                setTimeout(function(){
	                self.productsLoading = false;                
                }, 500);
			},
			loadSellerInfo(){
				this.createQueryString();
				axios.get('/customer-api'+this.$route.path+this.query).then(response => {
					this.categorydata = response.data;
					return this.injectMeta(response.data);
				}).catch(error => {
					console.log(error);
				});
			},
			loadSellerCollections(){
				axios.get('/customer-api/seller/'+this.$route.params.id+'/collections').then(response => {
					this.collections = response.data;
				}).catch(error => {
					console.log(error);
				});
			},
			filterBrands(value){
		      this.brands = value;
		      //this.createQueryString();
		      this.loadSellerProducts();
		    },
		    filterCollections(id){
		        
		          var exists = this.collections.findIndex(item => item == id);
		          console.log(exists);
		          if(exists == -1){
		            this.collections.push(id);
		          }else{
								this.collections.splice(exists, 1);
		          }
		          //this.createQueryString();
		      		this.loadSellerProducts();
		      },
			createQueryString(){
				this.query = '?page='+this.currentPage+'&limit='+this.limit+'&sortby='+this.sortby+'&order='+this.order+'&search='+this.search+'&public_active='+this.public_active+'&active='+this.active+'&brands='+this.brands+'&collections='+this.collections+'&categories='+this.categories+'&pricerange='+this.priceRange+'';
			},
			filterSelectedCategories(id){
		        
		          var exists = this.categories.findIndex(item => item == id);
		          console.log(exists);
		          if(exists == -1){
		            this.categories.push(id);
		          }else{
								this.categories.splice(exists, 1);
		          }
		          //this.createQueryString();
		      		this.loadSellerProducts();
		      },
		},
		watch: {
        $route() {
        	this.$nextTick(() => {
        		this.loadSellerProducts();
        		this.loadSellerInfo();
        		
        	 });
            //this.$nextTick();
            let self = this;
			
         }  
    	},
	}

</script>

<template>
<div>
		<section >
		 <SocialShareBlock class="mt-4 pt-4" />
		  <div class="container-fullwidth" style="background: #fff">
		    <!--<PageHeader :title="title" :items="items" />-->

		    <b-row 	v-if="categorydata.image !== null" class="mb-4 bg-primary bg-extra-soft" :style="'background-image: url( https://' + (categorydata.company.nickname + storageurl + categorydata.cover_image).replaceAll('//', '/') +'); min-height: 400px; background-position:  center center; background-size: cover; background-repeat: no-repeat'">

		      <b-col md="10" class="d-flex flex-column justify-content-center align-items-start mx-auto">
		      	
		      	<!--<div class="d-flex flex-row mt-4 pt-4">
		      	<img class="avatar-xl bg-white border p-2 rounded-circle my-auto mr-3" v-if="categorydata.cover_image == null" src="/images/product-placeholder.png">
		      	<img class="avatar-xl bg-white border p-2 rounded-circle my-auto mr-3" v-else :src="categorydata.cover_image">
		      	<div class="d-flex flex-column my-auto">
		      		<b-row class="">
				 	<b-col md="12" class="d-flex flex-row align-items-start mx-auto">
				 	<div  v-for="(bread, index) in breadcrumbs" class="d-flex flex-row">
		    		<span class="text-muted font-size-12 text-capitalize my-auto"><i class="bx bx-home my-auto font-size-10 mr-1" v-if="index == 0"></i>{{bread}}</span>
		    		 <i class="bx bx-chevron-right my-auto" v-if="index !== (breadcrumbs.length -1)"></i>
		    		</div>

				    	</b-col>
				    </b-row>
		        <h1 class="font-size-36 text-primary  text-capitalize">{{categorydata.name}}</h1>
		        <p class="w-75 mb-0" v-if="categorydata.description !== (null || '')">
		            {{categorydata.description}}
		          </p>
		      	</div>
		      </div>-->

		      </b-col>
		    </b-row>
		    <b-row v-else class="mb-4 bg-primary bg-extra-soft" style="min-height: 400px; background-position:  right bottom; background-size: 50%; background-repeat: no-repeat">

		      <b-col md="10" class="d-flex flex-column justify-content-center align-items-start mx-auto">
		      	
		      	<!--<div class="d-flex flex-row mt-4 pt-4">
		      	<img class="avatar-xl bg-white border p-2 rounded-circle my-auto mr-3" v-if="categorydata.cover_image == null" src="/images/product-placeholder.png">
		      	<img class="avatar-xl bg-white border p-2 rounded-circle my-auto mr-3" v-else :src="categorydata.cover_image">
		      	<div class="d-flex flex-column my-auto">
		      		<b-row class="">
				 	<b-col md="12" class="d-flex flex-row align-items-start mx-auto">
				 	<div  v-for="(bread, index) in breadcrumbs" class="d-flex flex-row">
		    		<span class="text-muted font-size-12 text-capitalize my-auto"><i class="bx bx-home my-auto font-size-10 mr-1" v-if="index == 0"></i>{{bread}}</span>
		    		 <i class="bx bx-chevron-right my-auto" v-if="index !== (breadcrumbs.length -1)"></i>
		    		</div>

				    	</b-col>
				    </b-row>
		        <h1 class="font-size-36 text-primary  text-capitalize">{{categorydata.name}}</h1>
		        <p class="w-75 mb-0" v-if="categorydata.description !== (null || '')">
		            {{categorydata.description}}
		          </p>
		      	</div>
		      </div>-->

		      </b-col>
		    </b-row>
		    <b-row class="seller-top-row mb-4 pb-4">
		    	<b-row class="border-bottom pb-5 mx-1">
		    		<b-col md="7" class="ml-auto d-flex flex-column d-none d-lg-flex">
		    			<div class="avatar-xl profile-user-wid mb-2 mr-auto">
                  <img
                  	v-if="categorydata.image" :src="'https://' + (categorydata.company.nickname + storageurl + categorydata.image).replaceAll('//', '/')"
                    alt
                    class="img-thumbnail rounded-circle"
                  />
                   <img
                  	v-else 
                    src="/images/product-placeholder.png"
                    alt
                    class="img-thumbnail rounded-circle"
                  />
                </div>
		   
		        <h1 class="section-info-header position-relative mr-auto">{{categorydata.name}}</h1>
		        <p class="w-100 mb-0" v-if="categorydata.description !== (null || '')">
		            {{categorydata.description}}
		          </p>
		        </b-col>
		        <b-col lg="3" md="12" class="mr-auto">

		        	<div class="profile-user-wid mb-2 mr-auto w-100">
		        		<b-card class="border-radius-1em">

                <div class="avatar-xl profile-user-wid mb-2 mx-auto bg-white rounded-circle" style="margin-top: -65px !important;">
                	<router-link class="text-center" :to="'/seller/'+categorydata.company.nickname+'/profile'">
                  <img
                  	v-if="categorydata.company.logo" :src="categorydata.company.logo.includes(storageurl) == false ?  'https://' + ( categorydata.company.nickname + storageurl + categorydata.company.logo).replaceAll('//', '/') : categorydata.company.logo"
                    alt
                    class="rounded-circle avatar-xl"
                  />
                   <img
                  	v-else 
                    src="/images/product-placeholder.png"
                    alt
                    class="rounded-circle avatar-xl"
                  />
                </router-link>
                </div>

                <div class="d-lg-none d-md-flex flex-column pb-3">
			                	<h1 class="text-center section-info-header position-relative mx-auto">{{categorydata.name}}</h1>
					        		<p class="text-center w-100 mb-0" v-if="categorydata.description !== (null || '')">
					            {{categorydata.description}}
					          </p>
                </div>
                	<h5 class="text-center"><span class="cursive-title font-size-20">Collection By:</span><br><router-link class="text-center font-size-20" :to="'/seller/'+categorydata.company.nickname+'/profile'">{{categorydata.company.name}}</router-link></h5>
                	<hr class="bg-light">
		        			<h5 class="text-center pt-3">Seller Rating</h5>
		        			<p  class="text-muted my-3 text-center" @click="infomodalaction = 'reviews'" v-b-modal.info-modal>
			                    <span class="bx bx-star font-size-18 text-warning"></span>
			                    <span class="bx bx-star font-size-18 text-warning ml-1"></span>
			                    <span class="bx bx-star font-size-18 text-warning ml-1"></span>
			                    <span class="bx bx-star font-size-18 text-warning ml-1"></span>
			                    <span class="bx bx-star font-size-18 ml-1"></span>
			                    <br><small>
			             	 / 5 (1,000 reviews)
			             </small>
			                  </p>
			            
		        			<!--<a class="btn btn-outline-light btn-rounded btn-block mb-2" v-b-modal.modal-1 @click="form.action = 'message'">Message Contact <i class="bx bx-mail-send text-primary"></i></a>
		        			<a class="btn btn-outline-light btn-rounded btn-block mb-2" @click="form.action = 'favourites'">Add To Favourites <i class="mdi mdi-heart-outline text-danger"></i></a>
		        			<a class="btn btn-outline-light btn-rounded btn-block mb-2"  v-b-modal.modal-1 @click="form.action = 'review'">Submit Review <i class="bx bx-star text-warning"></i></a>-->
		        			<!--<h6 v-if="categorydata.county !== null">{{categorydata.county.name}}</h6>
		        			<h6 v-if="categorydata.country !== null"><i class="bx bx-globe text-primary font-size-12"></i> {{categorydata.country.name}}</h6>-->
		        		</b-card>
		        	</div>
		        </b-col>
		    	</b-row>
		  	</b-row>
		    <!-- end row -->
		  </div>
		</section>
		<section >
			<b-container fluid>
				
				 <b-row class="pb-4">
				 	<!--<b-col md="12" class="d-flex flex-row align-items-start mx-auto">
				 	<div  v-for="(bread, index) in breadcrumbs" class="d-flex flex-row">
		    		<span class="badge badge-light text-dark font-size-12 text-capitalize my-auto">{{bread}}</span>
		    		 <i class="bx bx-chevron-right my-auto" v-if="index !== (breadcrumbs.length -1)"></i>
		    		</div>

		    	</b-col>-->
		    <transition name="fade-filtersleft">
		     	<b-col md="3" v-if="showFilters">
          <div class="col-lg-12 pt-1 mt-4">
        <div>
          <div class="card p-4 rounded-4 shadow-sm border-radius-1em mt-4">
            <!--<h4 class="card-title mb-4">Filter</h4>-->
            <div>
               <div class="mb-3">
              <h5 class="mb-3">Price</h5>
              <vue-slider v-model="priceRange" :min="0" :max="100 + priceRange[1]"  @drag-end="loadSellerProducts()" ></vue-slider>
              <b-form-group label="Min Price" class="w-50 d-inline float-left mt-2 mb-3 px-2">
              <b-form-input
                type="number"
                v-model="priceRange[0]"
                @input="loadSellerProducts"></b-form-input>
              </b-form-group>
              <b-form-group label="Max Price" class="w-50 d-inline float-left mt-2 mb-3 px-2">
                 <b-form-input
                 label="Max Price"
                 type="number"
                 v-model="priceRange[1]"
                 @input="loadSellerProducts"></b-form-input>
              </b-form-group>
            </div>
            		<div v-if="collections.length > 0">
            		<h5 class="my-3">Collections</h5>
            			<FilterPublicProductCollections :brands="collections" v-on:collections-filtered="filterCollections"></FilterPublicProductCollections>
            		</div>
                <h5 class="my-3">Categories</h5>
                <!--<FilterListTree></FilterListTree>-->
                <!--<FilterPublicProductCategories  :category_data="categoriesdata"></FilterPublicProductCategories>
                <h5 class="font-size-14 mb-3">Brands</h5>
               <FilterPublicProductBrands v-on:brands-filtered="filterBrands"></FilterPublicProductBrands>
               -->
            </div>
           

          <!-- <div class="mt-4 pt-3">
              <h5 class="font-size-14 mb-3">Customer Rating</h5>
              <div>
                <b-form-checkbox
                  id="checkbox-1"
                  name="checkbox-1"
                  value="accepted"
                  unchecked-value="not_accepted"
                >
                  4
                  <i class="bx bx-star text-warning"></i> & Above
                </b-form-checkbox>

                <b-form-checkbox
                  id="checkbox-2"
                  class="mt-2"
                  name="checkbox-2"
                  value="accepted"
                  unchecked-value="not_accepted"
                >
                  3
                  <i class="bx bx-star text-warning"></i> & Above
                </b-form-checkbox>

                <b-form-checkbox
                  id="checkbox-3"
                  class="mt-2"
                  name="checkbox-3"
                  value="accepted"
                  unchecked-value="not_accepted"
                >
                  2
                  <i class="bx bx-star text-warning"></i> & Above
                </b-form-checkbox>
                <b-form-checkbox
                  id="checkbox-4"
                  class="mt-2"
                  name="checkbox-4"
                  value="accepted"
                  unchecked-value="not_accepted"
                >
                  1
                  <i class="bx bx-star text-warning"></i>
                </b-form-checkbox>
              </div>
            </div>-->
          </div>
          <h6>Advertisement</h6>
          <div class="card shadow-sm border-radius-1em">
              <img src="/images/banner-placeholder.png" class="w-100">
            </div>
        </div>
      </div>
          </b-col>
      </transition>
      <transition name="fade-right">
					<b-col class="" :class="{'col-md-9 fade-right-in' : showFilters == true, 'col-md-12 fade-right-exit' : showFilters == false, }">
						<div class="row mb-3">
          
        <div class="col-lg-7 col-sm-6 d-flex">
            <div class="mt-sm-0 form-inline w-100" >
              <div class="search-box mr-2 ml-auto">
                <div class="position-relative">
                  <input
                    type="text"
                    class="form-control w-100 border"
                    placeholder="Search name, reference or description..."
                    v-model="search"
                    @keyup.enter="searchFilter($event)"
                  />
                  <i class="bx bx-search-alt search-icon"></i>
                </div>
              </div>
            </div>
          </div>

         
          <div class="col-lg-2 col-sm-6 d-flex">
          <form class="my-auto">
            <small class="mx-auto my-2 text-center d-block">Results {{data.from}} - {{data.to}} of {{data.total}}</small>
          </form>
        </div>
       
          <div class="col-lg-2 col-sm-6 d-flex">
            <div class="d-flex ml-auto">
              <ul class="nav nav-pills product-view-nav">
                <!--<li class="nav-item">
                  <a class="nav-link" :class="{'active': showFilters == true }" href="javascript: void(0);" @click="loadSellerCollections(), showFilters = !showFilters, expanded = !expanded">
                    <i class="bx bx-filter-alt"></i>
                  </a>
                </li>-->
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': view == 'grid' }" href="javascript: void(0);" @click="view = 'grid'">
                    <i class="bx bx-grid-alt"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': view == 'list' }" href="javascript: void(0);" @click="view = 'list'">
                    <i class="bx bx-list-ul"></i>
                  </a>
                </li>
              </ul>
              <div class="text-sm-right">
                  
                </div>
          </div>
        </div>
         <div class="col-xl-1 col-sm-6">
            <div class="my-auto">
              <b-form-select class="form-control border btn-rounded" v-model="limit" :options="limits" @input="loadSellerProducts()"></b-form-select>
            </div>
          </div>
      </div>
						<b-overlay
			        :show="productsLoading"
			        variant="white"
			        opacity="0.85"
			        blur="1px"
			        rounded="xl">
			          <PublicMarketplaceProductList :product_list="data.data" :expanded="expanded" :layouttoggle="false" :view="'grid'" :marketplace="'public'"  :exchange_rates="$attrs.exchange_rates" :selected_currency="$attrs.selected_currency" :selected_currency_symbol="$attrs.selected_currency_symbol"  ></PublicMarketplaceProductList>
			      </b-overlay>
					</b-col>
				</transition>
				<b-col md="12">
					<b-pagination v-if="(data.data !== null)" v-model="currentPage" :total-rows="data.total" :per-page="data.per_page" align="center"  @input="loadSellerProducts()"></b-pagination>
					</b-col>
			</b-row>
			<b-row>
				<b-col md="12" class="d-flex mt-4">
				<h2 class="product-info-header">Seller Reviews</h2>
				<hr class="mb-4 flex-grow-1">
			</b-col>
			<b-col md="12">
				<b-row class="mb-4" v-if="categorydata.company.reviews.length > 0"  >
					<b-col>
						<ReviewCarousel :items="categorydata.company.reviews" :swiperOptions="reviewOptions"></ReviewCarousel>
					</b-col>
					<b-col md="12" class="d-flex">
						<a class="ml-auto btn btn-light btn-rounded" href="javascript:;" v-b-modal.modal-1 @click="form.action = 'review'">Write Review</a>
					</b-col>
				</b-row>
				<b-row class="mb-4" v-else>
					<b-col md="12" class="text-center py-4">
						<i class="bx bx-star text-warning d-inline-block font-size-14"></i>
						<i class="bx bx-star text-warning d-inline-block font-size-20"></i>
						<i class="bx bx-star text-warning d-inline-block font-size-28"></i>
						<i class="bx bx-star text-warning d-inline-block font-size-20"></i>
						<i class="bx bx-star text-warning d-inline-block font-size-14"></i>
						<h4 class="text-muted">
							No Reviews
						</h4>
						<!--<a class="text-primary" href="javascript:;">
							Be the first to review this product
						</a>-->
						<a class="btn btn-light btn-rounded mb-2"  v-b-modal.modal-1 @click="form.action = 'review'">Be the first to review this seller <i class="bx bx-star text-warning"></i></a>
					</b-col>
				</b-row>
			</b-col>
			</b-row>
			</b-container>
		</section>
		<b-modal id="info-modal" centered content-class="border-radius-1em" header-class="border-none" size="lg" hide-footer>
			<b-card-body class="card-body pt-0">
				 <div class="row">
            	<div class="col-sm-12 pb-4 text-center mb-4">
            		<h3 class="text-capitalize">{{infomodalaction}} for:</h3>
            	</div>
              <div class="col-sm-12 pb-4 text-center border border-radius-1em">
                <div class="avatar-md profile-user-wid mb-2 mx-auto">
                  <img
                  	v-if="categorydata.logo" :src="storageurl + categorydata.logo"
                    alt
                    class="img-thumbnail rounded-circle"
                  />
                   <img
                  	v-else 
                    src="/images/product-placeholder.png"
                    alt
                    class="img-thumbnail rounded-circle"
                  />
                </div>
                <h5 class="font-size-15 text-truncate">{{categorydata.name}}</h5>
              </div>
           

            </div>
             <div class="row">
		       <b-card class="border-radius-1em border shadow-none mt-3 widget-scrollbox" body-class="pt-4 px-2 pb-1">
							<b-row v-if="infomodalaction == 'reviews'" >
								<b-col v-for="item in categorydata.reviews" md="6">
									<ReviewCard :review="item"></ReviewCard>
								</b-col>
							</b-row>
					</b-card>
			</div>
			<a class="btn btn-outline-light btn-rounded btn-block mb-2"  v-b-modal.modal-1 @click="form.action = 'review'">Submit Review <i class="bx bx-star text-warning"></i></a>
			</b-card-body>
		</b-modal>
		<b-modal id="modal-1" centered content-class="border-radius-1em" header-class="border-none" hide-footer>
			<b-card-body class="card-body pt-0">
            <div class="row">
            	<div class="col-sm-12 pb-4 text-center mb-4">
            		<h3>You are about to {{form.action}}</h3>
            	</div>
              <div class="col-sm-12 pb-4 text-center border border-radius-1em">
                <div class="avatar-md profile-user-wid mb-2 mx-auto">
                  <img
                  	v-if="categorydata.company.logo" :src="storageurl + categorydata.company.logo"
                    alt
                    class="img-thumbnail rounded-circle"
                  />
                   <img
                  	v-else 
                    src="/images/product-placeholder.png"
                    alt
                    class="img-thumbnail rounded-circle"
                  />
                </div>
                <h5 class="font-size-15 text-truncate">{{categorydata.company.name}}</h5>
              </div>
           

            </div>
          </b-card-body>
    <div v-if="form.action == 'message'">
    	<CustomerSellerEnquiry :token="$attrs.recaptcha_token" :route="'/customer-api/seller/'+this.$route.params.id+'/enquiry'"></CustomerSellerEnquiry>
    </div>

    <b-form v-if="(form.action == 'review')  && (user)" class="px-2">
    	<ReviewForm v-if="(form.action == 'review')  && (user)" class="px-2" :company_id="categorydata.company.id" :product_id="''" :service_id="''" :source="'public'" :api_token="user.api_token" :user="user" :review_type="'company'">
    </ReviewForm>
    
    	
    </b-form>
    <b-form v-if="(form.action == 'favourites')  && (user !== null)" class="px-2">
    </b-form>

    <b-form v-if="((form.action == 'favourites') || (form.action == 'review')) && (!user)" class="px-2">
    	<!--<a class="btn btn-outline-light btn-rounded btn-block mb-2" target="_blank">Sign In</a>-->
    	<Login submit-url="https://gemesys.co.uk/my-account/login" auth-error="">
             <input type="hidden" name="_token" :value="csrf">
        </Login>
        
    </b-form>
  </b-modal>
</div>
</template>