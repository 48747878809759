<script>
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import VueSlideBar from "vue-slide-bar";
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import CompanyProductSearch from '../../components/widgets/CompanyProductSearch';
import CompanyProductList from '../../components/widgets/CompanyProductList';
import SelectValuationSignature from '../../components/widgets/SelectValuationSignature';
import SelectValuationStamp from '../../components/widgets/SelectValuationStamp';
import SelectValuationNote from '../../components/widgets/SelectValuationNote';
import SideSlidePanel from "../../components/widgets/SideSlidePanel";
import ModalWindow from "../../components/widgets/ModalWindow";
import StorageWindow from "../../components/widgets/file-manager/StorageWindow";
import CompanyCustomerSearch from "../../components/widgets/CompanyCustomerSearch";
import SearchCompanyCustomerAddress from "../../components/widgets/SearchCompanyCustomerAddress";
import PageBuilder from "../../components/widgets/page-builder/PageBuilder";
import PageBuilderPreview from "../../components/widgets/page-builder/PageBuilderPreview";
import draggable from 'vuedraggable';

export default{
	components:{VueSlideBar, Layout, PageHeader, SideSlidePanel, ckeditor: CKEditor.component, CompanyProductSearch, CompanyProductList, ModalWindow, StorageWindow, SelectValuationSignature, SelectValuationStamp, SelectValuationNote, CompanyCustomerSearch, SearchCompanyCustomerAddress, draggable, PageBuilder, PageBuilderPreview},
	data() {
    	return {
          csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
          editor: ClassicEditor,
          showStampDetails: 0,
          previewMode: false,
          previewData: {
            rows: [],
          },
          activeAnchor: '',
          hoverElement: '',
          fileResource: '',
          fileRowIndex: '',
          fileColIndex: '',
          storageurl: 'https://'+this.$attrs.company.nickname+'.s3.eu-west-2.amazonaws.com',
          showColOptions: false,
          widgetBox: 0,
          widgetBoxEditMode: false,
          widgetBoxEditContent: [],
          widgetEditBlockIndex: null, 
          fileBlockIndex: '',
          widgets: [
            {
              widgetname: 'Image',
              widgettype: 'image',
              widgeticon: 'bx bx-image',
              widgetcontent: {
                url: '',
                width: 300,
                height: 300,
                alignment: 'text-left',
                fullwidth: false,
              },
              editable: 0,
              id: '',
            },
            {
              widgetname: 'Text',
              widgettype: 'text',
              widgeticon: 'bx bx-text',
              widgetcontent: {
                title: '',
                text: '',
              },
              editable: 0,
              id: '',
            },
            {
              widgetname: 'Spacer',
              widgettype: 'spacer',
              widgeticon: 'mdi mdi-arrow-split-horizontal',
              widgetcontent: {
                height: ''
              },
              editable: 0,
              id: '',
            },
            {
              widgetname: 'Divider',
              widgettype: 'divider',
              widgeticon: 'mdi mdi-border-horizontal',
              widgetcontent: {
                bordertype: 'solid',
                width: 1,
                color: '#000',
                opacity: '',
              },
              widgetoptions:{
                bordertypes: ['dotted','dashed','solid','double','groove','ridge','inset','outset','none','hidden']
              },
              editable: 0,
              id: '',
            },
            {
              widgetname: 'Store Address',
              widgettype: 'address',
              widgeticon: 'bx bx-home-circle',
              widgetcontent: {
                url: '',
                width: 300,
                height: 300,
              },
              editable: 0,
              id: '',
            },
            {
              widgetname: 'Customer Details',
              widgettype: 'customer-address',
              widgeticon: 'bx bx-user-circle',
              widgetcontent: {
                selectcustomer: false, 
                selectedcustomer: null,
                selectedaddress: null,
              },
              editable: 0,
              id: '',
            },
            {
              widgetname: 'Signature',
              widgettype: 'signature',
              widgeticon: 'bx bx-pen',
              widgetcontent: {
                url: '',
                width: 300,
                height: 300,
              },
              editable: 0,
              id: '',
            },
            {
              widgetname: 'Note',
              widgettype: 'note',
              widgeticon: 'bx bx-note',
              widgetcontent: {
                url: '',
                width: 300,
                height: 300,
              },
              editable: 0,
              id: '',
            },
            {
              widgetname: 'Stamp',
              widgettype: 'stamp',
              widgeticon: 'mdi mdi-stamper',
              widgetcontent: {
                url: '',
                width: 300,
                height: 300,
              },
              editable: 0,
              id: '',
            },
            {
              widgetname: 'Dynamic Fields',
              widgettype: 'dynamic-field',
              widgeticon: 'mdi-form-textbox',
              widgetcontent: {
                url: '',
                width: 300,
                height: 300,
              },
              editable: 0,
              id: '',
            },
          ],
          showFileManager: false,
          fileUrl: '',
    		  brandsData: [],
    		  categoryProducts: [],
    		  selectedStamp: [],
    		  brands_error: [],
    		  products_error: [],
          addProductToggle: false,
          error_reponse: [],
          error_message: false,
          success_message: false,
          success_text: '',
          loadingForm: false, 
          loadingList: false,
    		  view: 'grid',
    		  search: '',
    		  limit: '25',
    		  sortby: 'id',
    		  order: 'desc',
    		  selectedids: [],
          newCoverLetter: {
            name: '',
            description: '',
            companyname: '',
            firstname: '',
            lastname: '',
            image: '',
            default: 0,
            active: 0,
            header: {
              rows: []
            },
            body: {
              rows: []
            },
            footer: {
              rows: []
            }
          },
           rowsTemplate: [{
                cols: [{
                  blocks: [{
                    widgetname: 'Widget 1',
                    widgettype: 'Widget Type 1',
                    widgetcontent: 'Widget Content 1',
                    editable: 0,
                  }],
                },
                {
                  blocks: [{
                    widgetname: 'Widget 2',
                    widgettype: 'Widget Type 2',
                    widgetcontent: 'Widget Content 2',
                    editable: 0,
                  }],
                }]
              }],
          editcollection: {
            name: '',
            companyname: '',
            firstname: '',
            lastname: '',
            image: '',
            default: 0,
            active: 0,
          },
          isCheckAll: false,
          addNewPanel: false,
          editPanel: false,
          addPanelClass: 'edit-sidepanel-large',
          expandable: 0,
    		  getProductsPanel: 0,
    		  selected_parent: {id: null},
    		  limits: [
		        {value: 25, text: "25"},
		        {value: 50, text: "50"},
		        {value: 100, text: "100"},
		      ],
		      title: "Cover Letters",
		      items: [
		        {
		          text: "Appraisal",
		          href: "javascript:;",
		        },
		        {
		          text: "Cover Letters",
		          active: true,
		        },
		      ],
  				}	
	},
	methods:{
    printPreview(){

    },
    preparePreview(){
      var head = this.newCoverLetter.header.rows;
      var body = this.newCoverLetter.body.rows;
      var footer = this.newCoverLetter.footer.rows;
      var array = [];
      array = head.concat(body).concat(footer);
      console.log(array);
      this.previewData.rows = array;
      this.previewMode = true;
    },
		brandQuery(){
			axios.get('/api/company/appraisal/templates/cover-letters?page='+this.currentPage+'&limit='+this.limit+'&sortby='+this.sortby+'&order='+this.order+'&search='+this.search+'&parent_id='+this.selected_parent.id, {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
              }}).then(response => {
				this.brandsData = response.data;
			}).catch(error => {
				this.brands_error = error.data;
			});
		},
		getProducts(category_id){
      this.categoryProducts = [];
      this.loadingForm = false;
			axios.get('/api/company/appraisal/templates/cover-letter/'+category_id+'/products', {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
              }}).then(response => {
        this.categoryProducts = response.data;
         let self = this;
          setTimeout(function(){
            self.loadingForm = false;
          }, 500);
      }).catch(error => {
        this.products_error = error.data;
      });
		},
    addCollection(){
      this.loadingForm = true;
      var postForm = new FormData(document.getElementById("addcollectionform"));
      axios.post('/api/company/appraisal/templates/cover-letter/create', postForm, {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
              }}).then(response => {
        console.log(response);
        this.success_text = 'Congratulations, your new category has been added.';
        this.success_message = true;
        //this.resetProjectData();
        //this.addPanel = false;
        let self = this;
          setTimeout(function(){
            self.loadingForm = false;
            self.addNewPanel = false;
            self.brandQuery();
          }, 1500);
      }).catch( error => {
        
        this.error_reponse = error.response.data.errors;
        this.error_message = true;
        //this.resetProjectData();
        let self = this;
          setTimeout(function(){
            self.loadingForm = false;
          }, 1500);
      });
    },
    selectValSig(payload){
      this.widgetBoxEditContent.widgetcontent = payload;
    },
    selectValStamp(payload){
      this.widgetBoxEditContent.widgetcontent = payload;
    },
    selectValNote(payload){
      this.widgetBoxEditContent.widgetcontent = payload;
    },
    selectCustomer(payload){
      this.widgetBoxEditContent.widgetcontent.selectedcustomer = payload;
    },
    selectAddress(payload){
      this.widgetBoxEditContent.widgetcontent.selectedaddress = payload;
    },
    enforceFullwidthImage(){
      this.widgetBoxEditContent.widgetcontent.width = '100%';
      this.widgetBoxEditContent.widgetcontent.height = 'auto';
      this.widgetBoxEditContent.widgetcontent.fullwidth = true;
    },
    fileSelected(payload){
      this.widgetBoxEditContent.widgetcontent.url = ''+this.storageurl+''+payload.file+'';
    },
    addNewWidget(widget, resource, rindex, cindex){
      this.newCoverLetter[resource].rows[rindex].cols[cindex].blocks.push(widget);
    },
    editWidget(resource, rindex, cindex, bindex){
      this.widgetBoxEditContent = this.newCoverLetter[resource].rows[rindex].cols[cindex].blocks[bindex];
      this.widgetBoxEditMode = true;
      this.widgetBox = ''+rindex+''+cindex+'';
      this.widgetEditBlockIndex = bindex;
    },
    updateEditedWidget(){

    },
    deleteWidget(resource, rindex, cindex, bindex){
      this.newCoverLetter[resource].rows[rindex].cols[cindex].blocks.splice(bindex, 1);
    },
    deleteColumn(resource, rindex, cindex){
      this.newCoverLetter[resource].rows[rindex].cols.splice(cindex, 1);
    },
    deleteRow(resource, rindex){
      this.newCoverLetter[resource].rows.splice(rindex, 1);
    },
    updateCollection(){
      this.success_message = false;
      this.error_message = false;
      this.loadingForm = true;
      var postForm = new FormData(document.getElementById("updatecollectionform"));
      axios.post('/api/company/appraisal/templates/cover-letter/'+this.editcollection.id+'/update', postForm, {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
              }}).then(response => {
        console.log(response);
        this.success_text = 'Congratulations, your category has been updated.';
        this.success_message = true;
        //this.resetProjectData();
        this.addPanel = false;
        let self = this;
          setTimeout(function(){
            self.loadingForm = false;
            self.editPanel = false;
            self.brandQuery();
          }, 500);
      }).catch( error => {
        
        this.error_reponse = error.response.data.errors;
        this.error_message = true;
        //this.resetProjectData();
        let self = this;
          setTimeout(function(){
            self.loadingForm = false;
          }, 500);
      });

    },
    showStatusMessage(result){
      if(result == false){
        this.error_reponse = {error: ["Product Already Exists in Category"]};
        this.error_message = true;
      }else{
        this.success_message = true;
        this.success_text = 'Congratulations, your action has been saved.';
        this.getProducts(this.selectedStamp.id)
      }
    },
		priceIncVat(price, tax_percentage){
			var tax = 1 + (tax_percentage / 100);
			return price * tax;
		},
    filterSelected(id){
        if(this.selectedids.length > 0){
          var exists = this.selectedids.filter(item => item == id);
          if(exists.length > 0){
            return true;
          }else{
            return false
          }
        }else{
          return false;
        }
      },
      checkUrl(hash, search_query){
      if(hash == search_query){
        return true;
      }else{
        return false;
      }
    },
      checkAll: function(){ 
                 this.isCheckAll = !this.isCheckAll; 
                 this.selectedids = []; 
                 if(this.isCheckAll){ 
                     // Check all 
                     for (var key in this.brandsData.data) {          
                        this.selectedids.push(this.brandsData.data[key].id); 
                     } 
                 } 
              }, 
      updateCheckall: function(){ 
                 if(this.selectedids.length == this.brandsData.data.length){
                    this.isCheckAll = true; 
                 }else{ 
                    this.isCheckAll = false; 
                 } 
      },
      sanitizeTitle: function(title) {
            var nickname = "";
            // Change to lower case
            var titleLower = title.toLowerCase();
            // Letter "e"
            nickname = titleLower.replace(/e|é|è|ẽ|ẻ|ẹ|ê|ế|ề|ễ|ể|ệ/gi, 'e');
            // Letter "a"
            nickname = nickname.replace(/a|á|à|ã|ả|ạ|ă|ắ|ằ|ẵ|ẳ|ặ|â|ấ|ầ|ẫ|ẩ|ậ/gi, 'a');
            // Letter "o"
            nickname = nickname.replace(/o|ó|ò|õ|ỏ|ọ|ô|ố|ồ|ỗ|ổ|ộ|ơ|ớ|ờ|ỡ|ở|ợ/gi, 'o');
            // Letter "u"
            nickname = nickname.replace(/u|ú|ù|ũ|ủ|ụ|ư|ứ|ừ|ữ|ử|ự/gi, 'u');
            // Letter "d"
            nickname = nickname.replace(/đ/gi, 'd');
            // Trim the last whitespace
            nickname = nickname.replace(/\s*$/g, '');
            // Change whitespace to "-"
            nickname = nickname.replace(/\s+/g, '-');
            // Remove quotations
            nickname = nickname.replace(/["']/g, "");
            // Remove symbols
            nickname = nickname.replace(/@|#|!/g, '');
            return nickname;
        }
	},
	created(){
		this.brandQuery();
  }
}
</script>

<template>
	<Layout>
		<PageHeader :title="title" :items="items" />
		    <div class="row">
          <b-col md="12">
                    <transition name="slide-success" mode="out-in">
                  <b-alert id="success-message" variant="success" :show="success_message"  dismissible @dismissed="success_message = !success_message">
                      <p class="font-size-14">{{success_text}}</p>
                    </b-alert>
                  </transition>
                  </b-col>
      <div class="col-md-12">
 <div class="row mb-3">
          <div class="col-xl-1 col-sm-6">
            <div class="mt-2">
              <b-form-select class="form-control" v-model="limit" :options="limits" @input="brandQuery()"></b-form-select>
            </div>
          </div>
         
          <div class="col-lg-2 col-sm-6 d-flex">
          <form class="my-auto">
            <small class="mx-auto my-2 text-center d-block">Results {{brandsData.from}} - {{brandsData.to}} of {{brandsData.total}}</small>
          </form>
        </div>
        <div class="col-lg-3">
          <b-form-group class="my-auto ml-auto mr-3">
            <b-form-radio-group id="btn-radios-1" button-variant="outline-light"
        size="md" v-model="marketplace_brands" :options="quickfilter_options" buttons @input="brandQuery()"></b-form-radio-group>
      
          
          </b-form-group>
        </div>
        <div class="col-lg-3 col-sm-6 d-flex">
          
            <div class="mt-sm-0 form-inline w-100" >
              <div class="search-box mr-2 ml-auto">
                <div class="position-relative">
                  <input
                    type="text"
                    class="form-control w-100 border-0"
                    placeholder="Search name, reference or description..."
                    v-model="search"
                    @keyup.enter="brandQuery()"
                  />
                  <i class="bx bx-search-alt search-icon"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-sm-6 d-flex">
            <div class="d-flex ml-auto">
              <ul class="nav nav-pills product-view-nav">
                
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': view == 'grid' }" href="javascript: void(0);" @click="view = 'grid'">
                    <i class="bx bx-grid-alt"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': view == 'list' }" href="javascript: void(0);" @click="view = 'list'">
                    <i class="bx bx-list-ul"></i>
                  </a>
                </li>
                <li class="nav-item">
                 <b-dropdown text="Bulk Actions" variant="primary" class="rounded">
                  <b-dropdown-item href="javascript: void(0);">Action</b-dropdown-item>
                  <b-dropdown-item href="javascript: void(0);">Another action</b-dropdown-item>
                  <b-dropdown-item href="javascript: void(0);">Something else here</b-dropdown-item>
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-item href="javascript: void(0);">Separated link</b-dropdown-item>
                </b-dropdown>
                </li>
                <li class="nav-item">
                 <a
                    class="btn btn-success btn-rounded mb-2 mr-2 text-white"
                    href="javascript:;"
                    @click="addNewPanel = !addNewPanel"
                  >
                    <i class="mdi mdi-plus mr-1"></i> New
                  </a>
                </li>
              </ul>
              <div class="text-sm-right">
                  
                </div>
          </div>
        </div>
      </div>
        <div class="card" v-if="view == 'list'" >
          <div class="card-body p-0">
            <div class="table-responsive">
              <table class="table table-centered table-hover">

                <thead class="thead-light">
                  <tr>
                    <th scope="col"  style="width: 100px" class="text-center"><div
                        class="form-check form-switch form-switch-sm mb-0 mx-auto p-0 mt-auto d-flex"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input mx-auto my-auto" 
                          type="checkbox"
                          id="SwitchCheckSizesm"
                         
                          v-model="isCheckAll"
                          @click='checkAll()'
                          name="active"
                        />
                    </div></th>
                    <th scope="col"></th>
                    <th scope="col"><a :class="{'text-dark': sortby != 'name'}" href="javascript: void(0);" @click="orderDesc = !orderDesc,  sortby = 'name', brandQuery()">Name <i v-if="orderDesc == true" class="bx bx-caret-up"></i><i v-if="orderDesc == false" class="bx bx-caret-down"></i></a></th>
                    
                    <th scope="col" class="w-75">Note</th>
                    <th scope="col">Status</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
               
                <tbody>
                  
                  <tr v-for="list in brandsData.data" :key="list.id" valign="middle">
                    <td>
                       <div class="text-center" v-if="list.image">
                      <b-form-checkbox class="select-row-btn" v-model="selectedids" :value="list.id" button-variant="primary" @input="updateSelected" button>
                        <i v-if="filterSelected(list.id)" class="bx bx-check"></i>
                        <img v-else :src="`${list.image}`" alt class="avatar-sm rounded-circle" />
                      </b-form-checkbox>
                    </div>
                    <div class="text-center" v-else >
                        <b-form-checkbox class="select-row-btn" v-model="selectedids" :value="list.id" button-variant="primary" @input="updateSelected" button>

                          <i v-if="filterSelected(list.id)" class="bx bx-check"></i><span class="avatar-title rounded-circle" v-else>{{(list.name).slice(0,1)}}</span></b-form-checkbox>
                    </div> 
                    </td>
                    <td>
                      <div v-if="list.font" class="avatar-xs">
                        <span class="avatar-title rounded-circle">{{list.font}}</span>
                      </div>
                      <div v-if="list.logo">
                        <img class="rounded-circle avatar-lg" :src="`/public/storage/${list.logo}`" alt />
                      </div>
                    </td>
                    <td>
                        <h5 class="m-0">{{list.name}}</h5>
                    </td>
                    <td  class="w-75">
                        <p class="m-0" v-html="list.description"></p>
                    </td>
                    
                    <td>
                      <div
                        class="form-check form-switch form-switch-sm my-auto ml-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          :id="`SwitchCheckSize-${list.id}`"
                          v-model="list.active"
                          value="1"
                          unchecked-value="0"
                          name="active"
                        />
                        <label class="form-check-label" :for="`SwitchCheckSize-${list.id}`" 
                          >Active</label
                        >
                    </div>
                    </td>
                    <td>
                      <ul class="list-inline font-size-20 contact-links mb-0">
                        <li class="list-inline-item px-2"><a  @click="editcollection = list, editPanel = true" v-b-tooltip.hover title="Edit" placement="left"><i class="bx bx-edit-alt"></i>
                          </a>
                        </li>
                        <li class="list-inline-item px-2">
                           <b-dropdown v-b-tooltip.hover title="More Options" placement="left"dropleft class="more-options-dropdown">
			                  <template v-slot:button-content>
			                     <i class="bx bx-dots-horizontal-rounded"></i>
			                  </template>
			                  <b-dropdown-item>Action</b-dropdown-item>
			                  <b-dropdown-item>Another Action</b-dropdown-item>
			                  <b-dropdown-item>Something else here</b-dropdown-item>
			                </b-dropdown>
                        </li>
                      </ul>
                    </td>
                  </tr>
              	</tbody>
              </table>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <b-pagination
              v-if="brandsData.data.length > 0"
              class="justify-content-center"
              pills
              v-model="currentPage"
              :total-rows="brandsData.total"
              :per-page="brandsData.per_page"
              aria-controls="my-table"
              @input="brandQuery"
            ></b-pagination>
             <small class="mx-auto my-2 text-center d-block">Records {{brandsData.from}} - {{brandsData.to}} of {{brandsData.total}} // Page {{brandsData.current_page}} of {{brandsData.last_page}}</small>
              </div>
            </div>
          </div>
      </div>
    <div class="row" v-if="view == 'grid'" >
      <div class="col-xl-4"  v-for="list in brandsData.data" :key="list.id">
        <div class="card overflow-hidden">
          <div class="bg-soft-primary min-h-100">
            <div class="row">
              
              <div class="col-12 align-self-end bg-light">
                <div class="row">
                 <div class="col-md-12 position-absolute"> 
                <a id="tooltip-add" @click="editcollection = list, editPanel = true" class="mt-3 mr-3 btn btn-primary font-size-16 btn-rounded ml-3"><i class="bx bx-pencil"></i></a>
                <a id="tooltip-add"  @click="selectedStamp = list, showStampDetails = 1"class="mt-3 mr-3 btn btn-primary font-size-16 btn-rounded ml-auto float-right"><i class="mdi mdi-eye-outline"></i></a>
              </div>
              </div>
                <div class="w-75 bg-white mx-auto mt-5 p-4">
                  <small  class="micro-text" v-html="list.description"></small>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body pt-0">
            <div class="row">
              <div class="col-sm-12 text-center">
               <!-- <div class="avatar-xl profile-user-wid mb-4">
                  <img class="img-thumbnail rounded-circle h-100" :src="`/public/storage/${list.image}`" alt />
                </div>-->
                <h4 class="font-size-18 text-truncate mt-3">{{list.name}}</h4>
              </div>

            </div>
          </div>
          <div class="card-footer bg-soft bg-light border-top">
                  <div class="d-flex py-2">
                     <div
                        class="form-check form-switch form-switch-sm my-auto mr-auto"
                        dir="ltr"
                      >
                      <label class="form-check-label" :for="`SwitchCheckDefaultSize-${list.id}`" 
                          >Default</label
                        >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          :id="`SwitchCheckDefaultSize-${list.id}`"
                          v-model="list.default"
                          value="1"
                          unchecked-value="0"
                          name="default"
                        />
                        
                    </div>
                      <div
                        class="form-check form-switch form-switch-sm my-auto ml-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          :id="`SwitchCheckSize-${list.id}`"
                          v-model="list.active"
                          value="1"
                          unchecked-value="0"
                          name="active"
                        />
                        <label class="form-check-label" :for="`SwitchCheckSize-${list.id}`" 
                          >Active</label
                        >
                    </div>
                  </div>
          </div>
        </div>
    </div>
    	</div>
    </div>
</div>
<div>
 <ModalWindow :editModal="showFileManager" :panelClass="'product-modal d-flex flex-column justify-content-center'" @close-modal="showFileManager = !showFileManager" :formReponse="''">
        <StorageWindow :user="$attrs.user" :company="$attrs.company" @close-window="showFileManager = false" @file-selected="fileSelected" ></StorageWindow>
      </ModalWindow>
</div>
  <SideSlidePanel :editPanel="showStampDetails" :panelClass="'edit-sidepanel'" @close-panel="showStampDetails = 0">
    <div class="row">
        <div class="col-md-12 bg-light" style="background-size: 100%; min-height: 300px; background-position: center;" >
          <div class="w-75 bg-white mx-auto mt-5 p-4 note-preview-inner">
                  <p  v-html="selectedStamp.description"></p>
          </div>
        </div>
        <div class="col-md-12 pt-3">
        <b-card class="shadow-none">
        <div class="d-flex flex-column mb-3 mx-3">
          <h3 class="my-auto mr-auto px-3 pb-3">{{selectedStamp.name}}</h3>
           <p class="px-3" v-html="selectedStamp.description"></p>

        </div>
        
        </b-card>
        </div>
    </div>
  </SideSlidePanel>
   <SideSlidePanel :editPanel="addNewPanel" :panelClass="addPanelClass" @close-panel="addNewPanel = !addNewPanel" :expandable="1"  @expand="addPanelClass = 'edit-sidepanel-fullwidth'" @contract="addPanelClass = 'edit-sidepanel-large'">
       <b-row>
              <b-col md="3" class="border-right d-flex flex-column">
                <div class="sticky">
                <b-list-group flush class="">
                  <b-list-group-item href="#header" @click="activeAnchor = '#header'" :class="{'active': checkUrl(activeAnchor, '#header') == true}">
                    Header
                  </b-list-group-item>
                  <b-list-group-item href="#body" @click="activeAnchor = '#body'"  :class="{'active': checkUrl(activeAnchor, '#body') == true}">
                    Body
                  </b-list-group-item>
                  <b-list-group-item href="#footer" @click="activeAnchor = '#footer'"  :class="{'active': checkUrl(activeAnchor, '#footer') == true}">
                    Footer
                  </b-list-group-item>
                 
                </b-list-group>
                </div>
                <a class="btn-info btn btn-block mb-2" @click="preparePreview()" v-if="previewMode == false"><i class="mdi mdi-eye"></i> Preview</a>
                <a v-else class="btn-info btn btn-block mb-2" @click="previewMode = false"><i class="mdi mdi-eye"></i> Close Preview</a>
                <a href="javascript:;" class="btn btn-success btn-block">Save</a>
              </div>
              </b-col>
              <b-col md="9" >
                <div v-if="previewMode == false">
                <h5 class="mt-5" >Basic Information</h5>
                  <hr>
                  <b-form-group label="Cover Letter Name">
                    <b-form-input type="text" name="name" v-model="newCoverLetter.name" ></b-form-input>
                  </b-form-group>
                  <b-form-group label="Description" :description="newCoverLetter.description.length+' of 200'">
                     <b-form-textarea name="name" v-model="newCoverLetter.description" rows="5" placeholder="Enter short company description..."></b-form-textarea>
                  </b-form-group>
                </div>
                  <div v-if="previewMode == false">
                    <h5 id="header" class="mt-3">Header</h5>
                    <hr>
                    <PageBuilder :user="$attrs.user" :data="newCoverLetter.header" :widgets="widgets" :row_limit="4" :preview="previewMode" :allow_preview="false" :company="$attrs.company"></PageBuilder>
                    <h5 id="body" class="mt-3">Body</h5>
                      <hr>
                    <PageBuilder :user="$attrs.user" :data="newCoverLetter.body" :widgets="widgets" :row_limit="12" :preview="previewMode" :allow_preview="false" :company="$attrs.company"></PageBuilder>
                    <h5 id="footer" class="mt-3">Footer</h5>
                      <hr>
                    <PageBuilder :user="$attrs.user" :data="newCoverLetter.footer" :widgets="widgets" :row_limit="4" :preview="previewMode" :allow_preview="false" :company="$attrs.company"></PageBuilder>
                </div>
                <div v-else>
                  <h5 id="header" class="mt-3">Preview</h5>
                    <hr>
                  <PageBuilderPreview v-if="previewMode == true" :data="previewData" :preview_type="'document'" @close-preview="previewMode = false"  ></PageBuilderPreview>
                </div>
                <!--<div class="border p-4">
                  <draggable
                      v-model="newCoverLetter.header.rows"
                      handle=".handle"
                      ghost-class="ghost" >
                  <b-row v-for="(row, rindex) in newCoverLetter.header.rows" style="min-height: 100px; position: relative;" class="page-builder-element-outline mt-2">
                    <span class="page-builder-tab text-white bg-primary row-tabs">Row</span>
                    <span class="page-builder-tools text-white bg-primary row-tabs d-flex"><a @click="deleteRow('header', rindex)" class="flex-grow-1" href="javascript:;"><i class="bx bx-x"></i></a><a class="flex-grow-1 handle" href="javascript:;"><i class="bx bx-move"></i></a></span>
                    <draggable
                      v-model="newCoverLetter.header.rows[rindex].cols"
                      handle=".handle2"
                      ghost-class="ghost"
                      group="nested"
                       tag="b-col"
                       v-if="row.cols" v-for="(col, cindex) in row.cols" style="min-height: 100px;" class="page-builder-element-outline mt-4 pt-2"
                      >
                      <span class="page-builder-tab text-white bg-info">Column</span>
                      <span class="page-builder-tools text-white bg-info d-flex"><a @click="deleteColumn('header', rindex, cindex)" class="flex-grow-1" href="javascript:;"><i class="bx bx-x"></i></a><a class="flex-grow-1 handle2" href="javascript:;"><i class="bx bx-move"></i></a></span>
                      <draggable
                      v-model="newCoverLetter.header.rows[rindex].cols[cindex].blocks"
                      handle=".handle3"
                      ghost-class="ghost"
                      group="nested"
                      >
                      <div v-if="col.blocks" class="widget-block position-relative  border" v-for="(block, bindex) in col.blocks" :class="{'page-builder-element-outline': block.editable == 1, 'page-builder-element-outline': hoverElement == ''+rindex+''+cindex+''+bindex+'', 'border-white': hoverElement !== ''+rindex+''+cindex+''+bindex+''}" @mouseover="hoverElement = ''+rindex+''+cindex+''+bindex+''" @mouseout="hoverElement = ''">
                        <span class="page-builder-tab text-white bg-secondary bg-soft" :class="{'invisible': hoverElement !== ''+rindex+''+cindex+''+bindex+''}">{{block.widgetname}}</span><span class="page-builder-tools text-white bg-secondary bg-soft" :class="{'invisible': hoverElement !== ''+rindex+''+cindex+''+bindex+''}"><a class="" href="javascript:;" @click="deleteWidget('header', rindex, cindex, bindex)"><i class="bx bx-x"></i></a><a class="" href="javascript:;" @click="editWidget('header', rindex, cindex, bindex)"><i class="bx bx-pencil"></i><a class="flex-grow-1 handle3" href="javascript:;"><i class="bx bx-move"></i></a></a></span>
                        
                        <div v-if="block.widgettype == 'text'" v-html="block.widgetcontent.text"></div>
                        <div v-if="block.widgettype == 'image'" :class="block.widgetcontent.alignment">
                          <img  :src="block.widgetcontent.url" :width="block.widgetcontent.width" :height="block.widgetcontent.height">
                        </div>
                        <div v-if="block.widgettype == 'address'">
                          [Store Address]
                        </div>
                        <div v-if="block.widgettype == 'customer-address'">
                          [Customer Address]
                        </div>
                        <div v-if="block.widgettype == 'spacer'" :style="{'height': block.widgetcontent.height+'px'}">
                        </div>
                        <hr v-if="block.widgettype == 'divider'" class="text-white" :style="{'border-style': block.widgetcontent.bordertype, 'border-width': block.widgetcontent.width+'px', 'border-color': block.widgetcontent.color}">
                        <div v-if="block.widgettype == 'signature'">
                          <img :src="storageurl+block.widgetcontent.image">
                          <p class="mb-0">{{block.widgetcontent.firstname}} {{block.widgetcontent.lastname}}</p>
                          <strong>{{block.widgetcontent.companyname}}</strong>
                        </div>
                        <img v-if="block.widgettype == 'stamp'" :src="block.widgetcontent.url">
                        <div v-if="block.widgettype == 'note'" v-html="block.widgetcontent.content">
                        </div>
                       
                      </div>
                    </draggable>
                      <div class="position-relative">
                        <div class="w-100 d-flex h-100" style="min-height: 100px">
                        <a v-if="widgetBox !== ''+rindex + '' + cindex + ''" v-b-tooltip.hover title="Add Widget" class="btn btn-secondary btn-rounded m-auto" href="javascript:;" @click="widgetBox = ''+rindex + '' + cindex + ''"><i class="mdi mdi-plus"></i></a>
                        <a v-else class="btn btn-secondary btn-rounded m-auto" href="javascript:;" @click="widgetBox = '', widgetBoxEditMode = false, widgetEditBlockIndex = null, widgetBoxEditContent =''"><i class="mdi mdi-close noti-icon font-size-14"></i></a>
                        </div>
                       <div class="widget-box border shadow-sm bg-white " v-if="widgetBox === ''+rindex + '' + cindex + ''" :class="{'widget-box-lg': widgetBoxEditContent.widgettype == 'text', 'widget-box-lg': widgetBoxEditContent.widgettype == 'customer-address'}">
                        
                        <div class="d-flex w-100">
                          <strong class="w-75 d-block mb-2">Add Widget</strong>
                          
                        </div>
                   <b-button v-for="widget in widgets" v-if="widgetBoxEditMode == false"
                      class=" flex-grow-1 w-lg py-4 m-2"
                      value="0"
                      variant="light"
                      @click="widgetBoxEditContent = JSON.parse(JSON.stringify(widget)), widgetBoxEditMode = true"
                    ><i class="font-size-24 d-block text-primary my-2" :class="widget.widgeticon"></i> 
                     {{widget.widgetname}}
                    </b-button>
                    <div v-if="widgetBoxEditMode">
                        <div v-if="widgetBoxEditContent.widgettype == 'text'">
                          <b-form-group label="Text" >
                             <ckeditor id="issue" v-model="widgetBoxEditContent.widgetcontent.text" :editor="editor" rows="6" ></ckeditor>
                          </b-form-group>

                         </div>
                         <div v-if="widgetBoxEditContent.widgettype == 'divider'">
                          <b-form-group class="mb-2" label="Width">
                            <b-form-input class="w-100" type="range" v-model="widgetBoxEditContent.widgetcontent.width"></b-form-input>
                            <small>{{widgetBoxEditContent.widgetcontent.width}}px</small>
                          </b-form-group>
                          <b-form-group class="mb-2" label="Colour">
                            <b-form-input type="color" v-model="widgetBoxEditContent.widgetcontent.color"></b-form-input>
                          </b-form-group>
                          <b-form-group class="mb-2" label="Divider Style">
                             <b-form-select v-model="widgetBoxEditContent.widgetcontent.bordertype" :options="widgetBoxEditContent.widgetoptions.bordertypes"></b-form-select>
                          </b-form-group>
                          </div>
                          <div v-if="widgetBoxEditContent.widgettype == 'spacer'">
                          <b-form-group class="mb-2" label="Height">
                            <b-form-input type="range" class="w-100" v-model="widgetBoxEditContent.widgetcontent.height"></b-form-input>
                            <small>{{widgetBoxEditContent.widgetcontent.height}}px</small>
                          </b-form-group>
                          </div>
                         <div v-if="widgetBoxEditContent.widgettype == 'image'">
                          <div class="bg-light my-4 d-flex" style="min-height: 150px">
                          <a v-if="widgetBoxEditContent.widgetcontent.url == ''" class="btn btn-primary w-75 m-auto" href="javascript:;"  @click="fileResource = 'header', fileRowIndex = rindex, fileColIndex = cindex, showFileManager = !showFileManager">Select File</a>
                          <img v-else :src="widgetBoxEditContent.widgetcontent.url" class="w-75 m-auto">
                          </div>
                          <b-form-group class="mb-2" label="Width">
                            <b-form-input type="number" v-model="widgetBoxEditContent.widgetcontent.width" :disabled="widgetBoxEditContent.widgetcontent.fullwidth"></b-form-input>
                          </b-form-group>
                          <b-form-group class="mb-2" label="Height">
                            <b-form-input type="number" v-model="widgetBoxEditContent.widgetcontent.height" :disabled="widgetBoxEditContent.widgetcontent.fullwidth"></b-form-input>
                          </b-form-group> 
                          <b-form-group label="Alignment" class="mb-2">
                            <a class="btn btn-light" :class="{'btn-primary': widgetBoxEditContent.widgetcontent.alignment == 'text-left', 'btn-light': widgetBoxEditContent.widgetcontent.alignment !== 'text-left'}" @click="widgetBoxEditContent.widgetcontent.alignment = 'text-left'">
                              <i class="bx bx-align-left font-size-20"></i>
                            </a>
                            <a class="btn btn-light" :class="{'btn-primary': widgetBoxEditContent.widgetcontent.alignment == 'text-center', 'btn-light': widgetBoxEditContent.widgetcontent.alignment !== 'text-center'}" @click="widgetBoxEditContent.widgetcontent.alignment = 'text-center'">
                              <i class="bx bx-align-middle font-size-20"></i>
                            </a>
                            <a class="btn btn-light" :class="{'btn-primary': widgetBoxEditContent.widgetcontent.alignment == 'text-right', 'btn-light': widgetBoxEditContent.widgetcontent.alignment !== 'text-right'}" @click="widgetBoxEditContent.widgetcontent.alignment = 'text-right'">
                              <i class="bx bx-align-right font-size-20"></i>
                            </a>
                          </b-form-group>
                          <b-form-group class="mb-2" label="">
                            <b-form-checkbox v-model="widgetBoxEditContent.widgetcontent.fullwidth" @change="enforceFullwidthImage()" name="check-button" switch>
                                  Enforce Fullwith
                                </b-form-checkbox>
                          </b-form-group>                          
                         </div>
                         <div v-if="widgetBoxEditContent.widgettype == 'signature'">
                          <SelectValuationSignature :user="$attrs.user" :isDisabled="false" :id="''" @brand-selected="selectValSig"></SelectValuationSignature>
                         </div>
                         <div v-if="widgetBoxEditContent.widgettype == 'stamp'">
                          <SelectValuationStamp :user="$attrs.user" :isDisabled="false" :id="''" @brand-selected="selectValStamp"></SelectValuationStamp>
                         </div>
                         <div v-if="widgetBoxEditContent.widgettype == 'note'">
                          <SelectValuationNote :user="$attrs.user" :isDisabled="false" :id="''" @brand-selected="selectValNote"></SelectValuationNote>
                         </div>
                         <div v-if="widgetBoxEditContent.widgettype == 'customer-address'">
                          <b-form-group class="mb-2" label="Use Individual Customer" description="If you want to select a particular customer for this template, check this box. Otherwise customer address will be added from your appraisal customer.">
                            <b-form-checkbox v-model="widgetBoxEditContent.widgetcontent.selectcustomer"  name="check-button" switch>
                                  Select Customer
                                </b-form-checkbox>
                          </b-form-group>    
                          <b-form-group class="mt-2" label="Customer"  v-if="(widgetBoxEditContent.widgetcontent.selectcustomer == true)">
                            <CompanyCustomerSearch v-if="(widgetBoxEditContent.widgetcontent.selectcustomer == true) && (widgetBoxEditContent.widgetcontent.selectedcustomer == null)" :id="widgetBoxEditContent.widgetcontent.selectedcustomer"  :api_token="$attrs.user.api_token" @search-resource-selected="selectCustomer"></CompanyCustomerSearch>
                            <div class="d-flex w-100 h-100 border rounded-3 p-2 mt-3" v-if="widgetBoxEditContent.widgetcontent.selectedcustomer !== null">
                                <div class="avatar-sm mr-4 my-auto" v-if="widgetBoxEditContent.widgetcontent.selectedcustomer.user_details.avatar == null">
                                  <span class="avatar-title rounded-circle">{{widgetBoxEditContent.widgetcontent.selectedcustomer.firstname.slice(0, 1)}}</span>
                                </div>
                                 <img v-else :src="`${widgetBoxEditContent.widgetcontent.selectedcustomer.user_details.avatar}`" alt class="avatar-sm rounded-circle" />
                                <div class="option__desc d-flex flex-row w-75">
                                  <span class="option__title ml-2 my-auto w-100">{{widgetBoxEditContent.widgetcontent.selectedcustomer.firstname}} {{widgetBoxEditContent.widgetcontent.selectedcustomer.lastname}}<br><p class="m-0 lh-1 w-100 text-truncate"><small>{{widgetBoxEditContent.widgetcontent.selectedcustomer.user_details.email}}</small></p></span></div>
                                  <div class="d-flex flex-column flex-grow-1"><a class="btn btn-outline-light btn-rounded ml-auto my-auto mr-0"><i class="mdi mdi-close noti-icon font-size-16" href="javascript:;" @click="widgetBoxEditContent.widgetcontent.selectedcustomer = null" ></i></a></div></div>
                          </b-form-group>
                          <b-form-group class="mt-2" label="Address" v-if="(widgetBoxEditContent.widgetcontent.selectcustomer == true)">
                              <SearchCompanyCustomerAddress v-if="widgetBoxEditContent.widgetcontent.selectedcustomer !== null" :id="widgetBoxEditContent.widgetcontent.selectedaddress" :api_token="$attrs.user.api_token" :options="widgetBoxEditContent.widgetcontent.selectedcustomer.addresses" @status-selected="selectAddress" :data_provided="true" ></SearchCompanyCustomerAddress>
                              
                         </b-form-group>
                         </div>
                         <a class="btn btn-success my-2" v-if="widgetEditBlockIndex == null" @click="addNewWidget(widgetBoxEditContent, 'header', rindex, cindex), widgetBoxEditMode = false, widgetBoxEditContent = ''"> Save</a>
                          <a v-else class="btn btn-success my-2" @click="widgetBoxEditMode = false, widgetBoxEditContent = '', widgetBox = '', widgetEditBlockIndex = null">Update</a>
                    </div>

                </div>
                      </div>
                  </draggable>
                    <b-col md="12" class="text-center d-flex"  v-if="row.cols.length == 0" style="min-height: 100px;">
                    <a v-b-tooltip.hover title="Add Columns" class="btn btn-info btn-rounded m-auto" href="javascript:;" @click="showColOptions = !showColOptions"><i v-if="showColOptions == false" class="mdi mdi-plus"></i><i v-else class="mdi mdi-close noti-icon font-size-14"></i></a>
                    <b-row class="page-builder-column-select rounded-3">
                      <b-col md="6" class="text-center mx-auto bg-light" v-if="(row.cols.length == 0) && (showColOptions == true)">
                      <a class="btn btn-info mx-auto my-3" @click="newCoverLetter.header.rows[rindex].cols.push({blocks: []})">Add 1 Column</a>
                      <a class="btn btn-info mx-auto my-3" @click="newCoverLetter.header.rows[rindex].cols.push({blocks: []}), newCoverLetter.header.rows[rindex].cols.push({blocks: []})">Add 2 Columns</a>
                      <a class="btn btn-info mx-auto my-3" @click="newCoverLetter.header.rows[rindex].cols.push({blocks: []}), newCoverLetter.header.rows[rindex].cols.push({blocks: []}), newCoverLetter.header.rows[rindex].cols.push({blocks: []})">Add 3 Columns</a>
                    </b-col>
                    </b-row>
                    </b-col>
                  </b-row>
                  </draggable>
                  <b-row>
                    <b-col md="12" class="d-flex text-center" style="min-height: 75px;">
                  <a class="btn btn-primary btn-rounded m-auto" @click="newCoverLetter.header.rows.push({cols: []})"><i class="mdi mdi-plus"></i></a>
                    </b-col>
                  </b-row>
                </div>-->
                
              </b-col>
            </b-row>

    </SideSlidePanel> 
      <SideSlidePanel :editPanel="editPanel" :panelClass="addPanelClass" @close-panel="editPanel = !editPanel"  :expandable="1"  @expand="addPanelClass = 'edit-sidepanel-large'" @contract="addPanelClass = 'edit-sidepanel'">
      <div class="row">
        
        <div class="col-md-12">
        <b-card class="shadow-none">
          <h4 class="my-3">Edit Stamp: {{editcollection.name}}</h4>
           <b-overlay
                        id="overlay-background"
                        :show="loadingForm"
                        variant="white"
                        opacity="0.85"
                        blur="1px"
                        rounded="md"
                      >
         <b-form id="updatecollectionform" method="POST" enctype="multipart/form-data">
                   <input type="hidden" name="_token" :value="csrf">
           <b-row>
                     <b-col md="12">
                    <transition name="slide-error" mode="out-in">
                    <b-alert id="errors" variant="danger" :show="error_message"  dismissible @dismissed="error_message = !error_message">
                      <p class="font-size-14">Oops! Please correct the following errors in your submission</p>
                      <ul>
                        <li v-for="(error, key) in error_reponse">
                          {{error[0]}}
                        </li>
                      </ul>
                    </b-alert>
                    
                  </transition>
                  </b-col>
          </b-row>
         <b-form-group label="Reference Name">
            <b-form-input type="text" name="name" v-model="editcollection.name"></b-form-input>
          </b-form-group>
          <b-form-group label="Company Name">
            <b-form-input type="text" name="name" v-model="editcollection.companyname"></b-form-input>
          </b-form-group>
          <b-form-group label="Valuers First Name">
            <b-form-input type="text" name="name" v-model="editcollection.firstname"></b-form-input>
          </b-form-group>
          <b-form-group label="Valuers Last Name">
            <b-form-input type="text" name="name" v-model="editcollection.lastname"></b-form-input>
          </b-form-group>
          <b-form-group class="mt-2" label="Image">
            <input class="form-control mb-2" name="image" type="file">
          </b-form-group>
          <b-form-group class="my-3">
                     <div
                        class="form-check form-switch form-switch-sm mb-0 mt-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          id="editstamp-default"
                          v-model="editcollection.default" value="true"
                          unchecked-value="false"
                          name="default"
                        />
                        <label class="form-check-label" for="editstamp-default" 
                          > Default</label
                        >
                    </div>
                    <input type="hidden" :value="editcollection.default" name="default">
          </b-form-group>
          <b-form-group class="my-3">
                     <div
                        class="form-check form-switch form-switch-sm mb-0 mt-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          id="editstamp-active"
                          v-model="editcollection.active" value="true"
                          unchecked-value="false"
                          name="active"
                        />
                        <label class="form-check-label" for="editstamp-active" 
                          > Active</label
                        >
                    </div>
                    <input type="hidden" :value="editcollection.active" name="active">
          </b-form-group>
          <div class="d-flex flex-row mt-3">
          <b-button variant="success" class="w-md w-100" @click="updateCollection()"> <i class=" bx bx-save"></i> Save</b-button>
          </div>
        </b-form>
      </b-overlay>
        </b-card>
        </div>
      </div>
    </SideSlidePanel> 
      
</Layout>
</template>