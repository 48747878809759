<script type="text/javascript">
	import Swal from "sweetalert2";
	import Layout from '../../layouts/main'
	import PageHeader from '../../components/page-header'
	import DateTime from 'luxon/src/datetime.js';
	import Interval from 'luxon/src/interval.js';
	import OrderInvoice from '../../components/widgets/ecommerce/OrderInvoice'
	import OrderPayment from "../../components/widgets/ecommerce/OrderPayment";
	import SelectCompanyOrderStatus from "../../components/widgets/SelectCompanyOrderStatus";
	import { shippingStatuses } from "./shippingStatuses";
	import CompanyCustomerSupportTwilioThreads from "../../components/widgets/admin/support/CompanyCustomerSupportTwilioThreads";
	import CompanySupportForm from '../../components/widgets/forms/company/CompanySupportForm';
	import AddressLabel from "../../components/widgets/admin/print/AddressLabel";
	import CartPayment from "../../components/widgets/ecommerce/public/CartPayment";
	export default {
		components: { Layout, PageHeader, DateTime, Interval, OrderInvoice, OrderPayment, SelectCompanyOrderStatus, CompanyCustomerSupportTwilioThreads, AddressLabel, Swal, CartPayment, CompanySupportForm},
		data() {
			return {
				payment_type: '',
				support_type: '',
				support_order: '',
				support_order_company_id: '',
				support_company_order: '',
				support_company_order_product: '',
				support_product: '',
				support_proudct_company: '',
				support_contact: '',
				support_types: [
					{
						text: 'Order Issue', value: 'order'
					},
					{
						text: 'Order Cancellation', value: 'cancellation'
					},
					{
						text: 'Delivery Issue', value: 'delivery'
					},
					{
						text: 'Product Issue', value: 'product'
					}
				],
				support_contact_options: [
					{
						text: 'Email', value: 'email', disabled: false,
					},
					{
						text: 'Phone', value: 'phone', disabled: false,
					},
					{
						text: 'Chat', value: 'chat', disabled: true,
					},
					{
						text: 'SMS', value: 'sms', disabled: true,
					},
					{
						text: 'WhatsApp', value: 'whatsapp', disabled: true,
					},
				],
				order_cancelled: false,
				refund_products: [],
				refund_amount: 0.00,
				loadingShippingIntegration: false,
				courierapi_response: '',
				mark_fully_paid: false,
				amount_payable: 0.00,
				refund_amount: 0.00,
				custom_payment_amount: false,
				printPreview: false,
				bookingInfo: false,
				packingInfo: false,
				selected_carrier_preset: '',
				selected_county_id: '',
				selected_country_id: '',
				selected_shipping: '',
				actionModalSize: 'lg',
				addressLabel: '',
				new_order_status: '',
				loadingNewStatus: false,
				shippingStatuses: shippingStatuses,
				loadingTracking: false,
				selectedShippingStatus: '',
				order_statuses: [],
				orderMessages: [],
				loadingDeliveryBooking: false,
				showInvoiceLayout: false,
				customisationdropdown: -2,
				storageurl: 'https://'+this.$attrs.company.nickname+'.s3.eu-west-2.amazonaws.com',
				create_label_response: '',
				parcel_products: [],
				showOrderItems: -1,
				countries: [],
				counties: [],
				action: '',
				order: '',
				orderLoading: true,
				shipping_apikeys: '',
				shipping_integrations: '',
				active_carrier_integrations: '',
				all_carrier_integrations: '',
				create_label_step: 0,
				selected_carrier: '',
				selected_carrier_specifics: '',
				selected_carrier_presets: '',
				create_delivery_label: {
				  testing: true,
				  auth_company: "company",
				  shipment: {
				    label_size: "6x4",
				    label_format: "pdf",
				    generate_invoice: false,
				    generate_packing_slip: false,
				    courier: {
				      global_product_code: "",
				      local_product_code: "",
				      friendly_service_name: ""
				    },
				    collection_date: "",
				    reference: "my reference",
				    reference_2: "my second reference",
				    delivery_instructions: "Leave on the porch",
				    ship_from: {
				      name: "MN",
				      phone: "01377337164",
				      email: "foo@foo.com",
				      company_name: "Fake Company",
				      address_1: "unit 76",
				      address_2: "warfield road",
				      address_3: "",
				      city: "Driffield",
				      postcode: "YO25 9DJ",
				      county: "",
				      country_iso: "GB",
				      company_id: "911-70-1234",
				      tax_id: "911-70-1234",
				      eori_id: "911-70-1234-000",
				      ioss_number: "IM2760000711"
				    },
				    ship_to: {
				      name: "Laura Maura",
				      phone: "466546346546",
				      email: "",
				      company_name: "",
				      address_1: "Ekeredsvaegen 132",
				      address_2: "",
				      address_3: "",
				      city: "Lerum",
				      county: "",
				      postcode: "443 50",
				      country_iso: "SE"
				    },
				    parcels: [
				      
				    ]
				  },
				  "format_address_default": true,
				  "request_id": 123456789
				},
				parcel: {
				        dim_width: 50,
				        dim_height: 80,
				        dim_length: 40,
				        dim_unit: "cm",
				        weight: 8.2,
				        weight_unit: "kg",
				        packaging_weight: 0.2,
				        items: [
				        
				        ]
				      },
				parcel_item: {
				            description: "Test Item One",
				            origin_country: "GB",
				            quantity: 1,
				            value: 20,
				            value_currency: "GBP",
				            sku: "TEST0001",
				            product_id: 0,
				            hs_code: ""
				          },
				          collection:{
			      courier:{
			         pickup_container_code:"01"
			      },
			      collection_window_start:"11:00",
			      collection_window_end:"15:00",
			      collection_date:"2021-11-23",
			      collection_address:{
			         name:"John Doe",
			         phone:"01377455180",
			         email:"hello@despatchcloud.com",
			         company_name:"Despatch Cloud Ltd",
			         address_1:"Unit 76",
			         address_2:"Warfield Road",
			         address_3:"",
			         city:"Driffield",
			         county:"East Yorkshire",
			         postcode:"YO259FQ",
			         country_iso:"GB"
			      },
			      allow_weekend_collection:false,
			      shipment_count:12,
			      total_weight:140,
			      tracking_codes:[
			         "1Z44VF806834604984",
			         "1Z44VF806892824477",
			         "1Z44VF806894433083"
			      ]
			   },
				weight_units: ['g', 'kg'],
				dim_units: ['mm', 'cm', 'in', 'meter'],
				create_label_errors: [],

				title: 'Order',
				items: [
			        {
			          text: 'Order',
			          active: true,
			        },
			      ],
			}
		},
		props: {
			id: {
				type: String,
				default: () => '',
			}
		},
		computed: {
			orderSource(){
				if(this.order.source == null){
					if(this.order.trade_orders.length > 0){
						this.transfer_group_id = this.order.trade_orders[0].order.reference;
						return 'trade';
					}
					if(this.order.customer_orders.length > 0){
						this.transfer_group_id = this.order.customer_orders[0].order.reference;
						return 'public';
					}
		          if(this.order.website_orders.length > 0){
		          	this.transfer_group_id = this.order.website_orders[0].order.reference;
		          	return 'website';
		          }
		          if(this.order.pos_orders.length > 0){
		          	this.transfer_group_id = this.order.pos_orders[0].order.reference;
		          	return 'pos';
		          }
				}else{
					return this.order.source;
				}
			},
			validateCourierStep(){
				this.create_label_errors = [];
			 	if(this.create_label_step == 0){
			 		if(this.selected_carrier_preset == '' || this.selected_carrier_preset == undefined){
			 			this.create_label_errors.push("You must select a service for your parcel");
			 		}
			 		if(this.create_delivery_label.shipment.collection_date == ''){
			 			this.create_label_errors.push("You must add a collection date for your parcel");
			 		}
			 		if(this.selected_carrier_specifics !== ''){
			 			var keys = Object.keys(this.create_delivery_label.shipment.courier);
			 			
			 			for(var i = 0; i.length < this.selected_carrier_specifics["required"]; i++){
			 				var exists = keys.includes(this.selected_carrier_specifics["required"][i].name);
			 				if(exists == false){
			 					this.create_label_errors.push("You must complete the "+this.selected_carrier_specifics['required'][i].name +" field for your parcel");
			 				}
			 			}
			 		}
			 	}else if(this.create_label_step == 1){
					if(this.create_delivery_label.shipment.ship_from.address_1 == ''){
			 			this.create_label_errors.push("You must the first line of the ship from address");
			 		}
			 		if(this.create_delivery_label.shipment.ship_from.country_iso == ''){
			 			this.create_label_errors.push("You must add the country you are shipping from.");
			 		}
			 		if(this.create_delivery_label.shipment.ship_from.county == ''){
			 			this.create_label_errors.push("You must add the county of your shipping address");
			 		}
			 		if(this.create_delivery_label.shipment.ship_from.postcode == ''){
			 			this.create_label_errors.push("You must add athe postcode of your shipping address");
			 		}
			 		
			 		
				}else if(this.create_label_step == 2){
			 			if(this.create_delivery_label.shipment.ship_to.address_1 == ''){
			 			this.create_label_errors.push("You must the first line of the ship to address");
			 		}
			 		if(this.create_delivery_label.shipment.ship_to.country_iso == ''){
			 			this.create_label_errors.push("You must add the country you are shipping to.");
			 		}
			 		if(this.create_delivery_label.shipment.ship_to.county == ''){
			 			this.create_label_errors.push("You must add the county of your shipping address");
			 		}
			 		if(this.create_delivery_label.shipment.ship_to.postcode == ''){
			 			this.create_label_errors.push("You must add the postcode of your shipping address");
			 		}
			 		if(this.create_delivery_label.shipment.ship_to.phone == '' || this.create_delivery_label.shipment.ship_to.phone == undefined){
			 			this.create_label_errors.push("You must add the phone of your shipping address");
			 		}
			 		if(this.create_delivery_label.shipment.ship_to.email == '' || this.create_delivery_label.shipment.ship_to.email == undefined){
			 			this.create_label_errors.push("You must add the email of your shipping address");
			 		}
			 		
				}
				if(this.create_label_errors.length > 0){
					return false;
				}else{
					return true;
				}
			}
		},
		methods:{
			sendEnquiry(){
				
				//var notspam = this.recaptcha();
				this.loadingForm = true;
				var postForm = new FormData(document.getElementById("contact-form"));
				postForm.append('support_type', this.support_type);
				if(this.support_order !== ''){
					postForm.append('support_order', this.support_order.id);
				}
				if(this.support_company_order !== ''){
					postForm.append('support_company_order', this.support_company_order.id);
					postForm.append('support_order_company_id', this.support_company_order.company_id);
				}
				if(this.support_company_order_product !== ''){
					postForm.append('support_company_order_product', this.support_company_order_product.id);
				}
				if(this.support_product !== ''){
					postForm.append('support_product', this.support_product.id);
				}
				if(this.support_product_order !== ''){
					postForm.append('support_proudct_company', this.support_proudct_company.company_id);
				}
				postForm.append('status', this.status);
				postForm.append('support_contact', this.support_contact);
				axios.post('/customer-api/user/message/create', postForm, {
					headers: {
						'Authorization' : 'Bearer '+this.user.api_token
					}
				}).then(response => {
					this.success_message = true; 
					this.success_messagetext = 'Your support message has been sent';
					let self = this
					setTimeout(function() {
						self.loadingForm = false;
					}, 300);
				}).catch(error => {
                	this.error_reponse = error.response.data.errors;
					this.error_message = true;
					let self = this
					setTimeout(function() {
						self.loadingForm = false;
					}, 300);
				});
			},
				processRefund(chargeid, transactionid, payment){
					var refundedinfull = false;
					if(this.refund_amount == payment.amount){
						refundedinfull = true;
					}
					this.loadingDeliveryBooking = true;
					if(chargeid !== 'multiple'){
						axios.post('/api/company/order/'+this.id+'/process-refund', {amount_payable: this.refund_amount, charge_id: chargeid, order_id: this.order.id, currency: this.order.currency, transaction_id: transactionid , source: this.orderSource, transfer_group: this.transfer_group, transfer_id: transactionid, refunded_all: refundedinfull, products_refunded: this.refund_products, payment_id: payment.id, cancelled: this.order_cancelled}, {headers : { 'Authorization' : 'Bearer '+this.$attrs.user.api_token }}).then(response => {
							this.order.payments.push(response.data[2]);
							this.success_message = true;
							this.loadingDeliveryBooking = false;

						}).catch(error => {
							this.error_message = true;
							this.loadingDeliveryBooking = false;
						});
					}else{
						for(var i = 0; i > this.order.payments.length; i++){
							axios.post('/api/company/order/'+this.id+'/process-refund', {amount_payable: this.refund_amount, charge_id: this.order.payments[i].charge_id, order_id: this.order.id, currency: this.order.currency, transaction_id: this.order.payments[i].transaction_id , source: this.orderSource, transfer_group: this.transfer_group, transfer_id: transactionid, refunded_all: refundedinfull, products_refunded: this.refund_products, payment_id: payment.id, cancelled: this.order_cancelled}, {headers : { 'Authorization' : 'Bearer '+this.$attrs.user.api_token }}).then(response => {
								this.order.payments.push(response.data[2]);
								this.success_message = true;
								this.loadingDeliveryBooking = false;
							}).catch(error => {
								this.error_message = true;
								this.loadingDeliveryBooking = false;
							});
						}
					}
				},
				addPayment(method){
					axios.post('/api/company/order/'+this.id+'/payment/create', {amount_payable: this.amount_payable, payment_method: method, order_id: this.order.id, currency: this.order.currency, source: this.orderSource}, {headers : { 'Authorization' : 'Bearer '+this.$attrs.user.api_token }}).then(response => {
						this.order.payments.push(response.data);
					}).catch(error => {

					});
				},
				setUpCourierApiKey(){
   			var header = {'Authorization' : 'Bearer '+this.$attrs.user.api_token};
   			let self = this;
   			var pass = self.generatePassword();
   			axios.post('https://production.courierapi.co.uk/api/apiuser/create', { 
					    'name': this.$attrs.company.nickname, 
					    'key' : pass
	  			}, { headers: { 
					    'api-user': 'getCarriers', 
					    'api-token': 'griokptfhmzvbaqy', 
					    'Content-Type': 'application/json'
	  			}}).then(response => {
			    //this.setState({ courierapi_response: response.data });
			    this.courierapi_response = response.data;
			    return axios.post('/api/company/delivery-link-setup', response.data, {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}});
			  }).then(response => {
			  	console.log(response);
			  	this.shipping_apikeys = response.data;
			  	return this.getShippingIntegrations();
			  }).catch(error => {
			  	console.log(error);
			  });
		    	/**axios.all(createApiAccount(self.$attrs.company.nickname, pass)).then(axios.spread(function (response) {
								//companyList[ia].push(response.data)
								self.courierapi_response = response.data;
								console.log(response.data);
								return axios.post('/api/company/order/delivery-options/integration/setup', response.data, {headers: {'Authorization' : 'Bearer '+self.$attrs.user.api_token}}).then(setupresponse => {
									self.shipping_apikeys = setupresponse.data;
								}).catch(setuperror => {
									console.log(setuperror);
								});
							}));

		    	
	    	
   			async function createApiAccount(api_user, api_token){
			  		return await axios.post('https://production.courierapi.co.uk/api/apiuser/create', { 
					    'name': api_user, 
					    'key' : api_token
	  			}, { headers: { 
					    'api-user': 'getCarriers', 
					    'api-token': 'griokptfhmzvbaqy', 
					    'Content-Type': 'application/json'
	  			}});**/
			  	
   		},
			checkFullyPaid(){
					if(this.amount_payable == this.order.total_order_inc){
						this.order_paid = 1;
					}else{
						this.order_paid = 0;
					}
			},
			updateOrderStatus(){
				this.loadingNewStatus = true;
				axios.post('/api/company/order/'+this.id+'/update-status', this.new_order_status, {headers : { 'Authorization' : 'Bearer '+this.$attrs.user.api_token }}).then(response => {
					this.order.status = response.data;
					this.loadingNewStatus = false;
				}).catch(error => {
					console.log(error);
				});
			},
			getOrderStatuses(){
				if(this.order_statuses.length == 0){
					axios.get('/api/company/order/statuses?limit=0&sortby=id&order=desc&search=', {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
              }}).then(response => {
							this.order_statuses = response.data;
						}).catch(error => {
							this.response_error = error.data;
						});
				}
			},
			loadOrderMessages(){
				if(this.order.trade_orders.length > 0){
					this.orderMessages = this.order.trade_orders[0].order.support_tickets;
				}
				if(this.order.website_orders.length > 0){
					this.orderMessages = this.order.website_orders[0].order.support_tickets;
				}
				if(this.order.customer_orders.length > 0){
					this.orderMessages = this.order.customer_orders[0].order.support_tickets;
				}
				if(this.order.pos_orders.length > 0){
					this.orderMessages = this.order.pos_orders[0].order.support_tickets;
				}
			},
			bookCollection(){
				
			},
			setCourierSpecifics(courier){
				this.create_delivery_label.shipment.courier["courier_service"] = courier.dc_service_id;
			},
			collectionSupported(courier_name){
				var list = this.all_carrier_integrations.filter(item => item.name == courier_name);
				if(list.length > 0){
						if('collection_supported' in list[0]){
							return list[0]['collection_supported'];	
						}else{
							return false;
						}
				}else{
					return false;
				}
			},
			createLabel(){
				this.loadingDeliveryBooking = true;
				this.create_delivery_label.auth_company = this.$attrs.company.nickname;
				this.create_delivery_label.shipment.courier.friendly_service_name = this.selected_carrier.courier;
				var labeldata = JSON.stringify(this.create_delivery_label);
				axios.post('https://production.courierapi.co.uk/api/couriers/v1/'+this.selected_carrier.courier+'/create-label',  labeldata , { headers: { 
					    'api-user': this.$attrs.company.nickname, 
					    'api-token': this.shipping_apikeys.token,
					    'Content-Type': 'application/json'
	  			}}).then(response => {
	  				console.log(response.data);
	  				this.create_label_response = response.data;
	  				return this.createDelivery(response.data);
	  			}).catch(error => {
	  				console.log(error.data);
	  			});
			},
			markPackedTracked(reference){
				var data = JSON.stringify({"reference": reference, "testing" : true});
				axios.post('https://production.courierapi.co.uk/api/couriers/v1/mark-shipment-packed', {data : data}, { maxBodyLength: Infinity, headers: { 
					    'api-user': this.$attrs.company.nickname, 
					    'api-token': this.shipping_apikeys.token,
					    'Content-Type': 'application/json'
	  			}}).then(response => {
	  				console.log(response.data);
					}).catch(error => {

				});
			},	
			renewLabelRequest(label_key){
				axios.get('https://production.courierapi.co.uk/api/pdfs/renew?key='+label_key, { headers: { 
					    'api-user': this.$attrs.company.nickname, 
					    'api-token': this.shipping_apikeys.token,
					    'Content-Type': 'application/json'
	  			}}).then(response => {
	  				console.log(response.data);
					return this.openFile(response.data.uri);
				}).catch(error => {

				});
			},
			getShippingStatusText(status_id){
				var status = this.shippingStatuses.filter(item => item.id == status_id);
				return status[0].text;
			},
			getDeliveryStatus(shipping){
				this.loadingTracking = true;
				this.selected_shipping = shipping;
				axios.get('https://production.courierapi.co.uk/api/shipments.json?dc_request_id='+shipping.dc_request_id, { headers: { 
					    'api-user': this.$attrs.company.nickname, 
					    'api-token': this.shipping_apikeys.token,
					    'Content-Type': 'application/json'
	  			}}).then(response => {
					this.selectedShippingStatus = response.data;
					return this.loadingTracking = false;
				}).catch(error => {

				});
			},
			getDeliveryTracking(shipping){
				this.loadingTracking = true;
				axios.get('https://production.courierapi.co.uk/api/shipments.json?dc_request_id='+shipping.dc_request_id, { headers: { 
					    'api-user': this.$attrs.company.nickname, 
					    'api-token': this.shipping_apikeys.token,
					    'Content-Type': 'application/json'
	  			}}).then(response => {
					this.selectedShippingStatus = response.data;
					return this.loadingTracking = false;
				}).catch(error => {

				});
			},
			presaveDelivery(){
				axios.post('/api/company/order/'+this.order.id+'/presave-delivery', {label: this.create_delivery_label, courier: this.selected_carrier.courier}, {headers: {
					'Authorization' : 'Bearer '+this.$attrs.user.api_token
				}}).then(response => {
					this.create_delivery_label.shipment.reference = response.data.uid;
        	return	this.createLabel();
				}).catch(error => {

				});
			},
			deleteDelivery(uid, isPreSave){
				axios.post('/api/company/order/'+this.order.id+'/delete-delivery/'+uid+'', {presave: isPreSave}, {headers: {
					'Authorization' : 'Bearer '+this.$attrs.user.api_token
				}}).then(response => {
						var index = this.order.shippings.findIndex(item => item.uid == uid);
						if(index > -1){
							this.order.shippings.splice(index, 1);
						}
				}).catch(error => {

				});
			},
			createDelivery(response_data){
				response_data["create_delivery_label"] = this.create_delivery_label;
				axios.post('/api/company/order/'+this.order.id+'/book-delivery', response_data, {headers: {
					'Authorization' : 'Bearer '+this.$attrs.user.api_token
				}}).then(response => {
					this.order["shippings"].push(response.data);
					this.loadingDeliveryBooking = false;
					this.create_label_step = 5;
        	return	this.openFile(response_data.data.uri);
				}).catch(error => {

				});
			},
			reloadDeliveries(){
				axios.post('/api/company/order/'+this.order.id+'/deliveries', {presave: isPreSave}, {headers: {
					'Authorization' : 'Bearer '+this.$attrs.user.api_token
				}}).then(response => {
					
				}).catch(error => {

				});
			},
			openFile(filepath){
				var fileURL = window.open(filepath);
        //fileURL.click();
			},
			deleteProductFromParcel(parcelindex, itemindex, pref, pname){
				var productindex = this.parcel_products.findIndex(item => ((item.product.reference == pref) && (item.product.name == pname) ));
				var quantityRed = this.create_delivery_label.shipment.parcels[parcelindex].items[itemindex].quantity;
				this.create_delivery_label.shipment.parcels[parcelindex].items.splice(itemindex, 1);
				this.parcel_products[productindex].quantity = quantityRed;
			},
			addProductToParcel(index, product){
				var item = JSON.parse(JSON.stringify(this.parcel_item));
				var itemIndex = this.create_delivery_label.shipment.parcels[index].items.findIndex(item => (item.sku == product.product.reference) && (item.description == product.product.name));
				if(itemIndex > -1){
					this.create_delivery_label.shipment.parcels[index].items[itemIndex].quantity += 1;
					this.create_delivery_label.shipment.parcels[index].items[itemIndex].value = parseFloat(this.create_delivery_label.shipment.parcels[index].items[itemIndex].value) + parseFloat(product.product.sales_price);
				}else{
					item.sku = product.product.reference;
					item.product_id = product.product.id;
					item.description = product.product.name;
					item.quantity = 1;
					item.value = product.product.sales_price;
					this.create_delivery_label.shipment.parcels[index].items.push(item);
				}
			},
			getCountries(){
        if(this.countries.length == 0){
          axios.get('/api/countries', {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
              }}).then(response => {
            this.countries = response.data;
          }).catch(error => {
            this.load_error = error.data;
          })
        }
      },
			getCounties(country_id){
			        // get counties as country selected
			        axios.get('/api/country/'+country_id+'/counties', {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
              }}).then(response => {
			          this.counties = response.data;
			        }).catch(error => {
			          this.load_error = error.data;
			        })
			      },
			getShippingImage(name){
      	var integrations = this.all_carrier_integrations.filter(item => item.key == name);
      	//console.log(integrations);
      	return integrations[0].logo;
      },
      getMessages(source){
      	axios.get('/api/company/order/'+this.order.uid+'/messages?source='+source, {headers: {
      		'Authorization' : 'Bearer '+this.$attrs.user.api_token
      	}}).then(response => {
      		if(response.data.trade_orders.length > 0){
				this.orderMessages = response.data.trade_orders[0].order.support_tickets;
			}
			if(response.data.website_orders.length > 0){
				this.orderMessages = response.data.website_orders[0].order.support_tickets;
			}
			if(response.data.customer_orders.length > 0){
				this.orderMessages = response.data.customer_orders[0].order.support_tickets;
			}
			if(response.data.pos_orders.length > 0){
				this.orderMessages = response.data.pos_orders[0].order.support_tickets;
			}
      	}).catch(error => {

      	});
      },
			getOrder(){
				this.orderLoading = true;
				axios.get('/api/company/order/'+this.id, {headers : { 'Authorization' : 'Bearer '+this.$attrs.user.api_token }}).then(response => {
					this.order = response.data;
					this.parcel_products = response.data.products;
					this.orderLoading = false;
				}).catch(error => {
					console.log(error);
				});
			},
			getShippingIntegrations(){
				this.loadingDeliveryBooking = true;
   			if(this.all_carrier_integrations.length == 0){
	   			axios.get('https://production.courierapi.co.uk/api/couriers/v1/list-couriers', { headers: { 
					    'api-user': this.$attrs.company.nickname, 
					    'api-token': this.shipping_apikeys.token,
					    'Content-Type': 'application/json'
	  			}}).then(response => {
	  				console.log(response.data);
	  				this.all_carrier_integrations = response.data.couriers;
	  				this.loadingDeliveryBooking = false;
	  			}).catch(error => {
	  				console.log(error.data);
	  				this.loadingDeliveryBooking = false;
	  			});
  			}
   		},
   		getActiveShippingIntegrations(){
   			this.loadingDeliveryBooking = true;
   			if(this.all_carrier_integrations.length == 0){
	   			axios.get('https://production.courierapi.co.uk/api/couriers/v1/list-registered-couriers', { headers: { 
					    'api-user': this.$attrs.company.nickname, 
					    'api-token': this.shipping_apikeys.token,
					    'Content-Type': 'application/json'
	  			}}).then(response => {
	  				console.log(response.data);
	  				this.active_carrier_integrations = response.data;
	  				this.loadingDeliveryBooking = false;
	  			}).catch(error => {
	  				console.log(error.data);
	  				this.loadingDeliveryBooking = false;
	  			});
  			}
   		},
   		getCourierApiKey(){
	   			if((this.shipping_apikeys == '')){
		   			axios.get('/api/company/delivery-links', {headers: {
			    		'Authorization' : 'Bearer '+this.$attrs.user.api_token
			    	}}).then(response => {
											this.shipping_apikeys = response.data;
												//console.log(this.shipping_apikeys.token);
												return this.getAllShippingIntegrations();
										}).then(error => {
											console.log(error);
										});
					}
   		},
   		getAllShippingIntegrations(){
   			this.getShippingIntegrations();
   			this.getActiveShippingIntegrations();
   		},
   		getCourierPresets(courier){
   				axios.get('https://production.courierapi.co.uk/api/couriers/v1/'+courier+'/presets', { headers: { 
					    'api-user': this.$attrs.company.nickname, 
					    'api-token': this.shipping_apikeys.token,
					    'Content-Type': 'application/json'
	  			}}).then(response => {
	  				console.log(response.data);
	  				this.selected_carrier_presets = response.data;
	  			}).catch(error => {
	  				console.log(error.data);
	  			});
   		},
   		getCourierSpecifics(courier){
   				axios.get('https://production.courierapi.co.uk/api/couriers/v1/'+courier+'/courier-specifics', { headers: { 
					    'api-user': this.$attrs.company.nickname, 
					    'api-token': this.shipping_apikeys.token,
					    'Content-Type': 'application/json'
	  			}}).then(response => {
	  				console.log(response.data);
	  				this.selected_carrier_specifics = response.data;
	  			}).catch(error => {
	  				console.log(error.data);
	  			});
   		},
   		getShippingAddress(){
   			this.getCountries();
   			this.getCounties(this.$attrs.company.country_id);
   			this.create_delivery_label.shipment.ship_from.name = this.$attrs.user.name+' '+this.$attrs.user.lastname;
				
				this.create_delivery_label.shipment.ship_from.email = this.$attrs.company.email;
				
				this.create_delivery_label.shipment.ship_from.company_id = this.$attrs.company.name;
				this.create_delivery_label.shipment.ship_from.company_name = this.$attrs.company.name;
				this.create_delivery_label.shipment.ship_from.address_1 = this.$attrs.company.address1;
				this.create_delivery_label.shipment.ship_from.address_2 = this.$attrs.company.address2;
				this.create_delivery_label.shipment.ship_from.address_3 = this.$attrs.company.address3;
				this.create_delivery_label.shipment.ship_from.city = '';
			
				if(this.$attrs.company.country !== null){
					this.create_delivery_label.shipment.ship_from.country_iso = this.$attrs.company.country.iso_code;
					this.selected_country_id = this.$attrs.company.country;
					this.create_delivery_label.shipment.ship_from.phone = '++'+this.$attrs.company.country.call_prefix + this.$attrs.company.telephone;

				}
				if(this.$attrs.company.county !== null){
						this.create_delivery_label.shipment.ship_from.county = this.$attrs.company.county.name;
						this.selected_county_id = this.$attrs.company.county;
				}
				this.create_delivery_label.shipment.ship_from.postcode = this.$attrs.company.postcode;
				var addresskey = '';
				if(this.order.website_orders.length > 0){
					addresskey = 'website_orders';
				}
				if(this.order.customer_orders.length > 0){
					addresskey = 'customer_orders';
				}
				if(this.order.pos_orders.length > 0){
					addresskey = 'pos_orders';
				}
				if(this.order.trade_orders.length > 0){
					addresskey = 'trade_orders';
				}
				if(addresskey !== 0){
					if(addresskey == 'trade_orders'){
						this.create_delivery_label.shipment.ship_to.name = this.order[addresskey][0].order.customer.name;
					}else{
						this.create_delivery_label.shipment.ship_to.name = this.order[addresskey][0].order.customer.name+' '+this.order[addresskey][0].order.customer.lastname;
					}
					if(this.create_delivery_label.shipment.ship_to.phone !== '' || this.create_delivery_label.shipment.ship_to.phone !== undefined){
						this.create_delivery_label.shipment.ship_to.phone = this.order[addresskey][0].order.customer_delivery_address.phone;
					}else{
						this.create_delivery_label.shipment.ship_to.phone = '';
					}
					if(this.create_delivery_label.shipment.ship_to.email !== '' || this.create_delivery_label.shipment.ship_to.email !== undefined){
						this.create_delivery_label.shipment.ship_to.email = this.order[addresskey][0].order.customer_delivery_address.email;
					}else{
						this.create_delivery_label.shipment.ship_to.email = '';
					}
					this.create_delivery_label.shipment.ship_to.company_name = this.order[addresskey][0].order.customer_delivery_address.name;
					this.create_delivery_label.shipment.ship_to.address_1 = this.order[addresskey][0].order.customer_delivery_address.address_1;
					this.create_delivery_label.shipment.ship_to.address_2 = this.order[addresskey][0].order.customer_delivery_address.address_2;
					this.create_delivery_label.shipment.ship_to.address_3 = this.order[addresskey][0].order.customer_delivery_address.address_3;
					this.create_delivery_label.shipment.ship_to.city = '';
					if(this.order[addresskey][0].order.customer_delivery_address.county !== null){
							this.create_delivery_label.shipment.ship_to.county = this.order[addresskey][0].order.customer_delivery_address.county.name;
					}
					if(this.order[addresskey][0].order.customer_delivery_address.country !== null){
						this.create_delivery_label.shipment.ship_to.country_iso = this.order[addresskey][0].order.customer_delivery_address.country.iso_code;
						this.getCounties(this.order[addresskey][0].order.customer_delivery_address.country.country_id);
					}
					this.create_delivery_label.shipment.ship_to.postcode = this.order[addresskey][0].order.customer_delivery_address.postcode;
				}


   		},
   		duplicateParcel(parcel){
   			var parcelItem = JSON.parse(JSON.stringify(parcel));
   			parcelItem.items = [];
   			this.create_delivery_label.shipment.parcels.push(parcelItem);
   		},
   		removeParcel(index){
	        	Swal.fire({
		        title: "Are you sure?",
		        text: "You are about to remove a parcel",
		        icon: "warning",
		        allowOutsideClick: false,
		        showCancelButton: true,
		        confirmButtonColor: "#34c38f",
		        cancelButtonColor: "#f46a6a",
		        confirmButtonText: "Confirm Remove Parcel",
		        cancelButtonText: "Cancel Remove Parcel"
		      }).then(result => {
		        if (result.value) {
		        	this.create_delivery_label.shipment.parcels.splice(index, 1);

		          //await async function()
		        }else{
		        	let timerInterval;
		        	Swal.fire({
				        title: "Redirecting you now",
				        html: "Your page has not been saved.",
				        timer: 500,
				        timerProgressBar: true,
				        onBeforeOpen: () => {
				          //Swal.showLoading();
				          timerInterval = setInterval(() => {
				            Swal.getContent().querySelector(
				              "b"
				            ).textContent = Swal.getTimerLeft();
				          }, 100);
				        },
				        onClose: () => {
				          
				        }
      				})
		        }
		      });
   			

   		},
			parseDate(date){
				this.timestamp = DateTime.local().toISO();
				console.log(DateTime.fromISO(date));
				var formattedDate = DateTime.fromISO(date);
				return formattedDate.day+' '+formattedDate.monthLong+' '+formattedDate.weekYear+'';
			},
			generateReport () {
				axios.post('/api/company/order/'+this.order.id+'/generate-invoice', {order_invoice_ref: this.order.uid},  {headers: {'Authorization': 'Bearer '+this.$attrs.user.api_token}}).then(response => {
					this.order.invoice_created = 1;
					this.order.order_invoice = response.data;
					return this.$refs.html2Pdf.generatePdf();
				}).catch(error => {

				});
            
        },
        saveGeneratedInvoice(pdfevent){
        	 		console.log(pdfevent);
        		var postForm = new FormData(document.getElementById("invoiceUpload"));
        		postForm.append('file', pdfevent);
        		axios.post('/api/company/order/'+this.order.id+'/save-invoice', postForm, {headers: {'Authorization': 'Bearer '+this.$attrs.user.api_token}}).then(response => {
        		}).catch(error => {

        		});
        },
        printInvoice(){
        	this.$bvModal.hide('invoice-modal');
        	this.printPreview = true;
        	this.showInvoiceLayout = true;
        	
        	//var printContents = document.getElementById('invoiceArea').innerHTML;
			     //var originalContents = document.getElementById('print-window').innerHTML;

			     //document.getElementById('print-window').innerHTML = printContents;
        	let self = this;
        	setTimeout(function(){
        		window.print();

        		self.$bvModal.show('invoice-modal');
	        	self.printPreview = false;
	        	self.showInvoiceLayout = false;
        	}, 1000);
			     
        	
			     //document.getElementById('print-window').innerHTML = originalContents;
			     //this.showInvoiceLayout = false;
			     //this.$bvModal.hide('invoice-modal');
        }
		},
		created(){
			this.getOrder();
			this.getCourierApiKey();
			this.getOrderStatuses();
		}
	}

</script>

<template>
<Layout  :nickname="$attrs.nickname" :user="$attrs.user" :menuItems="$attrs.menuItems">
	 <PageHeader :title="title" :items="items" />
	 	<b-row v-if="orderLoading">
	 		<b-overlay
	 		_:show="true">
	 		</b-overlay>
	 	</b-row>
		<b-row v-else>
			<b-col xl="3" lg="12" md="12">
					<b-card class="card overflow-hidden border-radius-1em shadow-sm seller-profile-card" no-body >
          <div class="bg-light border-radius-top-right-1em border-radius-top-left-1em" style="height: 75px;">
            <div class="row">
                           
                       <div class="col-12">
              </div>
            </div>
          </div>
				<b-card-body class="card-body pt-0">
		            <div class="row">
		              <div class="col-sm-12 pb-4 text-center">
		                <div class="avatar-md profile-user-wid mb-2 mx-auto" style="margin-top: -45px !important;">
		                  <span class="avatar-title rounded-circle">
		                  	O
		                  </span>
		                </div>
		                 <div v-if="order.trade_orders.length !== 0">
                        <a :href="'/seller/profile/'+order.trade_orders[0].order.customer.nickname+''"><h5 class="font-size-15 text-truncate mb-0">{{order.trade_orders[0].order.customer.name}}</h5>
                        {{order.trade_orders[0].order.customer.email}}</a>
                      </div>
                      <div v-if="order.customer_orders.length !== 0">
                         <a :href="order.customer_orders[0].order.customer.company_customer !== null ? '/customer/details/'+order.customer_orders[0].order.customer.company_customer.uid+'' : 'javascript:;'"><h5 class="font-size-15 text-truncate mb-0">{{order.customer_orders[0].order.customer.name}} {{order.customer_orders[0].order.customer.lastname}}</h5>
                        {{order.customer_orders[0].order.customer.email}}</a>
                      </div>
                      <div v-if="order.website_orders.length !== 0">
                         <a :href="order.website_orders[0].order.customer.company_customer !== null ? '/customer/details/'+order.website_orders[0].order.customer.company_customer.uid+'' : 'javascript:;'"><h5 class="font-size-15 text-truncate mb-0">{{order.website_orders[0].order.customer.name}} {{order.website_orders[0].order.customer.lastname}}</h5>
                        {{order.website_orders[0].order.customer.email}}</a>
                      </div>
                      <div v-if="order.pos_orders.length !== 0">
                         <a v-if="order.pos_orders[0].order.customer !== null" :href="'/customer/details/'+order.pos_orders[0].order.customer.uid+''"><h5 class="font-size-15 text-truncate mb-0">{{order.pos_orders[0].order.customer.name}} {{order.pos_orders[0].order.customer.lastname}}</h5>
                        {{order.pos_orders[0].order.customer.email}}</a>
                        <a v-else href="javascript:;">No customer data provided</a>
                      </div>
		                
		                <!--<p class="text-muted mb-0 text-truncate"><span v-if="shopData.county !== null">{{shopData.county.name}},</span> {{shopData.country.name}}</p>-->
		              </div>
		              <div class="col-md-12 d-flex">
		              	<span class="mx-auto badge badge-light border-radius-1em bg-light text-dark font-size-11 lh-16 my-auto p-2">
		              		<i class="bx bx-buildings"></i> {{order.reference}}
		              	</span>
		              </div>
		
		            

		            </div>
		          </b-card-body>
		        </b-card>	
		          <b-dropdown id="dropdown-left" text="Left align" variant="primary" class="btn-block rounded-dropdown mb-3 d-sm-flex d-md-none" button-class="btn-rounded" menu-class="w-100">

		         	 <template #button-content>
		         	 	Actions <i class="bx bx-chevron-down"></i>
		         	 </template>
		         		<b-dropdown-item  @click="action = 'message-customer'">
								Message Customer <i class="bx bx-message-alt"></i>
								</b-dropdown-item>
				        <b-dropdown-item v-if="(order.order_paid == false) || (order.order_paid == 0) || (order.order_paid == null)" v-b-modal.action-modal @click="action = 'setup-payment'">
									Make Payment <i class="bx bx-credit-card-alt"></i>
								</b-dropdown-item>
								<b-dropdown-item  v-b-modal.action-modal @click="action = 'setup-refund'">
									Process Refund <i class="bx bx-credit-card-alt"></i>
								</b-dropdown-item>
								<b-dropdown-item v-b-modal.action-modal @click="actionModalSize = 'xl', action = 'arrange-delivery'">
									Arrange Delivery <i class="bx bx-printer"></i>
								</b-dropdown-item>
								<b-dropdown-item v-b-modal.invoice-modal>
									Process Invoice <i class="bx bxs-file-pdf"></i>
								</b-dropdown-item>
		         </b-dropdown>
		       <b-row>
		       	<b-col sm="12" md="6" lg="12">
		        <b-card class="shadow-sm border-radius-1em" >
		        	<div class="media">
		        		<div class="avatar-sm mr-3 d-none d-xl-none d-xxl-block">
		        			<span class="avatar-title rounded-circle">
		        				<i class="bx bxs-truck"></i>
		        			</span>
		        		</div>
		        		<div class="media-body my-auto">
		        	<h6 class="mb-0">Invoice Address</h6>
		        		<div v-if="order.trade_orders.length !== 0">
		        		<p class="text-muted mb-0" v-if="order.trade_orders[0].order.customer_invoice_address !== null">
                     <span v-if="order.trade_orders[0].order.customer_invoice_address.address_1">{{order.trade_orders[0].order.customer_invoice_address.address_1}},</span>
                      <span v-if="order.trade_orders[0].order.customer_invoice_address.address_2">{{order.trade_orders[0].order.customer_invoice_address.address_2}},</span>
                      <span v-if="order.trade_orders[0].order.customer_invoice_address.address_3">{{order.trade_orders[0].order.customer_invoice_address.address_3}},</span>
                      <span v-if="order.trade_orders[0].order.customer_invoice_address.county">{{order.trade_orders[0].order.customer_invoice_address.county.name}},</span>
                      <span v-if="order.trade_orders[0].order.customer_invoice_address.country">{{order.trade_orders[0].order.customer_invoice_address.country.name}},</span>
                      <span v-if="order.trade_orders[0].order.customer_invoice_address.postcode">{{order.trade_orders[0].order.customer_invoice_address.postcode}}</span>
                                          </p>
                     <p class="text-muted mb-0" v-else>
                     	-- No Address Added --
                     </p>
                     <a class="ml-auto btn btn-sm btn-light float-right btn-rounded"  @click="addressLabel = order.trade_orders[0].order.customer_invoice_address, actionModalSize = 'xl', action = 'print-address-label', $bvModal.show('action-modal')">
                  	<i class="bx bx-printer"></i>
                  	Print Label
                  </a>
                  </div>
                  <div v-if="order.customer_orders.length !== 0">
		        		<p class="text-muted mb-0" v-if="order.customer_orders[0].order.customer_invoice_address !== null">
                     <span v-if="order.customer_orders[0].order.customer_invoice_address.address_1">{{order.customer_orders[0].order.customer_invoice_address.address_1}},</span>
                      <span v-if="order.customer_orders[0].order.customer_invoice_address.address_2">{{order.customer_orders[0].order.customer_invoice_address.address_2}},</span>
                      <span v-if="order.customer_orders[0].order.customer_invoice_address.address_3">{{order.customer_orders[0].order.customer_invoice_address.address_3}},</span>
                      <span v-if="order.customer_orders[0].order.customer_invoice_address.county">{{order.customer_orders[0].order.customer_invoice_address.county.name}},</span>
                      <span v-if="order.customer_orders[0].order.customer_invoice_address.country">{{order.customer_orders[0].order.customer_invoice_address.country.name}},</span>
                      <span v-if="order.customer_orders[0].order.customer_invoice_address.postcode">{{order.customer_orders[0].order.customer_invoice_address.postcode}}</span>
                                          </p>
                     <p class="text-muted mb-0" v-else>
                     	-- No Address Added --
                     </p>
                     <a class="ml-auto btn btn-sm btn-light float-right btn-rounded"  @click="addressLabel = order.customer_orders[0].order.customer_invoice_address, actionModalSize = 'xl', action = 'print-address-label', $bvModal.show('action-modal')">
                  	<i class="bx bx-printer"></i>
                  	Print Label
                  </a>
                  </div>
                   <div v-if="order.website_orders.length !== 0">
		        		<p class="text-muted mb-0" v-if="order.website_orders[0].order.customer_invoice_address !== null">
                     <span v-if="order.website_orders[0].order.customer_invoice_address.address_1">{{order.website_orders[0].order.customer_invoice_address.address_1}},</span>
                      <span v-if="order.website_orders[0].order.customer_invoice_address.address_2">{{order.website_orders[0].order.customer_invoice_address.address_2}},</span>
                      <span v-if="order.website_orders[0].order.customer_invoice_address.address_3">{{order.website_orders[0].order.customer_invoice_address.address_3}},</span>
                      <span v-if="order.website_orders[0].order.customer_invoice_address.county">{{order.website_orders[0].order.customer_invoice_address.county.name}},</span>
                      <span v-if="order.website_orders[0].order.customer_invoice_address.country">{{order.website_orders[0].order.customer_invoice_address.country.name}},</span>
                      <span v-if="order.website_orders[0].order.customer_invoice_address.postcode">{{order.website_orders[0].order.customer_invoice_address.postcode}}</span>
                                          </p>
                     <p class="text-muted mb-0" v-else>
                     	-- No Address Added --
                     </p>
                     <a class="ml-auto btn btn-sm btn-light float-right btn-rounded"  @click="addressLabel = order.website_orders[0].order.customer_invoice_address, actionModalSize = 'xl', action = 'print-address-label', $bvModal.show('action-modal')">
                  	<i class="bx bx-printer"></i>
                  	Print Label
                  </a>
                  </div>
                  <div v-if="order.pos_orders.length !== 0">
		        		<p class="text-muted mb-0" v-if="order.pos_orders[0].order.customer_invoice_address !== null">
                     <span v-if="order.pos_orders[0].order.customer_invoice_address.address_1">{{order.pos_orders[0].order.customer_invoice_address.address_1}},</span>
                      <span v-if="order.pos_orders[0].order.customer_invoice_address.address_2">{{order.pos_orders[0].order.customer_invoice_address.address_2}},</span>
                      <span v-if="order.pos_orders[0].order.customer_invoice_address.address_3">{{order.pos_orders[0].order.customer_invoice_address.address_3}},</span>
                      <span v-if="order.pos_orders[0].order.customer_invoice_address.county">{{order.pos_orders[0].order.customer_invoice_address.county.name}},</span>
                      <span v-if="order.pos_orders[0].order.customer_invoice_address.country">{{order.pos_orders[0].order.customer_invoice_address.country.name}},</span>
                      <span v-if="order.pos_orders[0].order.customer_invoice_address.postcode">{{order.pos_orders[0].order.customer_invoice_address.postcode}}</span>
                                          </p>
                     <p class="text-muted mb-0" v-else>
                     	-- No Address Added --
                     </p>
                     <a class="ml-auto btn btn-sm btn-light float-right btn-rounded"  @click="addressLabel = order.website_orders[0].order.customer_invoice_address, actionModalSize = 'xl', action = 'print-address-label', $bvModal.show('action-modal')">
                  	<i class="bx bx-printer"></i>
                  	Print Label
                  </a>
                  </div>
                  
                </div>
              </div>
		        </b-card>
		    </b-col>
		    <b-col sm="12" md="6" lg="12">
		        <b-card class="shadow-sm border-radius-1em">
		        	<div class="media mb-2">
		        		<div class="avatar-sm mr-3 d-none d-xl-none d-xxl-block">
		        			<span class="avatar-title rounded-circle">
		        				<i class="bx bxs-truck"></i>
		        			</span>
		        		</div>
		        		<div class="media-body my-auto">
		        	<h6 class="mb-0">Delivery Address</h6>
		        	<div v-if="order.trade_orders.length !== 0">
		        		<p class="text-muted mb-0" v-if="order.trade_orders[0].order.customer_delivery_address !== null">
                     <span v-if="order.trade_orders[0].order.customer_delivery_address.address_1">{{order.trade_orders[0].order.customer_delivery_address.address_1}},</span>
                      <span v-if="order.trade_orders[0].order.customer_delivery_address.address_2">{{order.trade_orders[0].order.customer_delivery_address.address_2}},</span>
                      <span v-if="order.trade_orders[0].order.customer_delivery_address.address_3">{{order.trade_orders[0].order.customer_delivery_address.address_3}},</span>
                      <span v-if="order.trade_orders[0].order.customer_delivery_address.county">{{order.trade_orders[0].order.customer_delivery_address.county.name}},</span>
                      <span v-if="order.trade_orders[0].order.customer_delivery_address.country">{{order.trade_orders[0].order.customer_delivery_address.country.name}},</span>
                      <span v-if="order.trade_orders[0].order.customer_delivery_address.postcode">{{order.trade_orders[0].order.customer_delivery_address.postcode}}</span>
                                          </p>
                     <p class="text-muted mb-0" v-else>
                     	-- No Address Added --
                     </p>
                     <a class="ml-auto btn btn-sm btn-light float-right btn-rounded"  @click="addressLabel = order.trade_orders[0].order.customer_delivery_address, actionModalSize = 'xl', action = 'print-address-label', $bvModal.show('action-modal')">
                     	<i class="bx bx-printer"></i>
                     	Print Label
                  </a>
                  </div>
                  	<div v-if="order.customer_orders.length !== 0">
		        		<p class="text-muted mb-0" v-if="order.customer_orders[0].order.customer_delivery_address !== null">
                     <span v-if="order.customer_orders[0].order.customer_delivery_address.address_1">{{order.customer_orders[0].order.customer_delivery_address.address_1}},</span>
                      <span v-if="order.customer_orders[0].order.customer_delivery_address.address_2">{{order.customer_orders[0].order.customer_delivery_address.address_2}},</span>
                      <span v-if="order.customer_orders[0].order.customer_delivery_address.address_3">{{order.customer_orders[0].order.customer_delivery_address.address_3}},</span>
                      <span v-if="order.customer_orders[0].order.customer_delivery_address.county">{{order.customer_orders[0].order.customer_delivery_address.county.name}},</span>
                      <span v-if="order.customer_orders[0].order.customer_delivery_address.country">{{order.customer_orders[0].order.customer_delivery_address.country.name}},</span>
                      <span v-if="order.customer_orders[0].order.customer_delivery_address.postcode">{{order.customer_orders[0].order.customer_delivery_address.postcode}}</span>
                                          </p>
                     <p class="text-muted mb-0" v-else>
                     	-- No Address Added --
                     </p>
                      <a class="ml-auto btn btn-sm btn-light float-right btn-rounded"  @click="addressLabel = order.customer_orders[0].order.customer_delivery_address, actionModalSize = 'xl', action = 'print-address-label', $bvModal.show('action-modal')">
                      	<i class="bx bx-printer"></i>
                      	Print Label
                  </a>
                  </div>
                  <div v-if="order.website_orders.length !== 0">
		        		<p class="text-muted mb-0" v-if="order.website_orders[0].order.customer_delivery_address !== null">
                     <span v-if="order.website_orders[0].order.customer_delivery_address.address_1">{{order.website_orders[0].order.customer_delivery_address.address_1}},</span>
                      <span v-if="order.website_orders[0].order.customer_delivery_address.address_2">{{order.website_orders[0].order.customer_delivery_address.address_2}},</span>
                      <span v-if="order.website_orders[0].order.customer_delivery_address.address_3">{{order.website_orders[0].order.customer_delivery_address.address_3}},</span>
                      <span v-if="order.website_orders[0].order.customer_delivery_address.county">{{order.website_orders[0].order.customer_delivery_address.county.name}},</span>
                      <span v-if="order.website_orders[0].order.customer_delivery_address.country">{{order.website_orders[0].order.customer_delivery_address.country.name}},</span>
                      <span v-if="order.website_orders[0].order.customer_delivery_address.postcode">{{order.website_orders[0].order.customer_delivery_address.postcode}}</span>
                                          </p>
                     <p class="text-muted mb-0" v-else>
                     	-- No Address Added --
                     </p>
                      <a class="ml-auto btn btn-sm btn-light float-right btn-rounded"  @click="addressLabel = order.website_orders[0].order.customer_delivery_address, actionModalSize = 'xl', action = 'print-address-label', $bvModal.show('action-modal')">
                      	<i class="bx bx-printer"></i>
                      	Print Label
                  </a>
                  </div>
                  <div v-if="order.pos_orders.length !== 0">
		        		<p class="text-muted mb-0" v-if="order.pos_orders[0].order.customer_delivery_address !== null">
                     <span v-if="order.pos_orders[0].order.customer_delivery_address.address_1">{{order.pos_orders[0].order.customer_delivery_address.address_1}},</span>
                      <span v-if="order.pos_orders[0].order.customer_delivery_address.address_2">{{order.pos_orders[0].order.customer_delivery_address.address_2}},</span>
                      <span v-if="order.pos_orders[0].order.customer_delivery_address.address_3">{{order.pos_orders[0].order.customer_delivery_address.address_3}},</span>
                      <span v-if="order.pos_orders[0].order.customer_delivery_address.county">{{order.pos_orders[0].order.customer_delivery_address.county.name}},</span>
                      <span v-if="order.pos_orders[0].order.customer_delivery_address.country">{{order.pos_orders[0].order.customer_delivery_address.country.name}},</span>
                      <span v-if="order.pos_orders[0].order.customer_delivery_address.postcode">{{order.pos_orders[0].order.customer_delivery_address.postcode}}</span>
                                          </p>
                     <p class="text-muted mb-0" v-else>
                     	-- No Address Added --
                     </p>
                      <a class="ml-auto btn btn-sm btn-light float-right btn-rounded"  @click="addressLabel = order.pos_orders[0].order.customer_delivery_address, actionModalSize = 'xl', action = 'print-address-label', $bvModal.show('action-modal')">
                      	<i class="bx bx-printer"></i>
                      	Print Label
                  </a>
                  </div>

                </div>
                </div>
		        </b-card>
		    </b-col>
		</b-row>
		        <b-row class="d-none d-lg-block">
		        	<b-col md="12" class="mb-3">
		       			<button class="btn btn-primary btn-soft btn-rounded btn-block" v-b-modal.action-modal @click="action = 'message-customer'">
		       				<div class="d-flex m-2">
								 Message Customer <i class="bx bx-message-alt font-size-20 ml-auto icon-label-end"></i>
									</div>
								</button>
							</b-col>
							<b-col md="12" class="mb-3" v-if="(order.order_paid == false) || (order.order_paid == 0) || (order.order_paid == null)">
		       			<button class="btn btn-primary btn-soft btn-rounded btn-block"  v-b-modal.action-modal  @click="action = 'setup-payment'" >
		       				<div class="d-flex m-2">
								 Make Payment  <i class="bx bx-credit-card-alt font-size-20 ml-auto icon-label-end"></i>
									</div>
								</button>
							</b-col>
							<b-col md="12" class="mb-3" v-else-if="order.order_paid == 1 || order.order_paid == true">
								<button class="btn btn-primary btn-soft btn-rounded btn-block"  v-b-modal.action-modal @click="action = 'setup-refund'" v-if="order.order_refunded !== 1" >
								<div class="d-flex m-2">
									
									Process Refund <i class="bx bx-credit-card-alt font-size-20 ml-auto icon-label-end"></i>
								</div>
							</button>
							<button class="btn btn-primary btn-soft btn-rounded btn-block"  v-b-modal.action-modal @click="action = 'reverse-refund'" v-else >
								<div class="d-flex m-2">
									
									Reverse Refund <i class="bx bx-credit-card-alt font-size-20 ml-auto icon-label-end"></i>
								</div>
							</button>
							</b-col>
							<b-col md="12" class="mb-3">
		       			<button class="btn btn-primary btn-soft btn-rounded btn-block" v-b-modal.action-modal @click="actionModalSize = 'xl', action = 'arrange-delivery'">
		       				<div class="d-flex m-2">
								 Arrange Delivery  <i class="bx bx-box font-size-20 ml-auto icon-label-end"></i>
									</div>
								</button>
							</b-col>
							<b-col md="12" class="mb-3">
		       			<button class="btn btn-primary btn-soft btn-rounded btn-block" v-b-modal.invoice-modal v-if="order.invoice_created == 0">
		       				<div class="d-flex m-2">
								 Process Invoice  <i class="bx bxs-file-pdf font-size-20 ml-auto icon-label-end"></i>
									</div>
								</button>
								<button class="btn btn-success btn-soft btn-rounded btn-block" v-b-modal.invoice-modal v-else-if="order.invoice_created == 1">
		       				<div class="d-flex m-2">
								 Print & Send Invoice  <i class="bx bxs-check-circle font-size-20 ml-auto icon-label-end"></i>
									</div>
								</button>
							</b-col>
							<b-col md="12" class="mb-3">
								<button class="btn btn-soft btn-rounded btn-block" :style="{'background-color' : order.status.color}" :class="order.status !== null ? order.status.text_color : 'text-dark'" v-b-modal.action-modal @click="action = 'update-status'">
								<div class="d-flex m-2">
									Update Status <i class="bx bx-history font-size-20 ml-auto icon-label-end"></i>
								</div>
								</button>
									</b-col>
							<b-col md="12" class="mb-3">
								<button class="btn btn-success btn-rounded btn-block" v-b-modal.invoice-modal>
									<div class="d-flex m-2">
									 Mark Complete <i class="bx bxs-check-circle font-size-20 ml-auto icon-label-end"></i>
									</div>
								</button>
							</b-col>
		       </b-row>
			
						
			</b-col>

			<b-col sm="12" md="12" lg="12" xl="9">
				<b-row>
					<b-col md="4">
						<b-card class="shadow-sm border-radius-1em">
							<div class="d-flex align-items-center mb-3"><div class="avatar-xs mr-3"><span class="avatar-title rounded-circle bg-soft-primary text-white font-size-18"><i class="bx bx-calendar"></i></span></div> <h5 class="font-size-14 mb-0">Order Date</h5></div> <div class="text-muted mt-4"><h4>{{parseDate(order.created_at)}} <i class="mdi mdi-chevron-up ml-1 text-success"></i></h4> <div class="d-flex"><span class="mr-2 text-truncate">Order From</span>
											<div v-if="order.trade_orders.length > 0">
                       <span class="font-size-12 badge badge-light bg-light text-dark"><i class="bx bx-transfer-alt text-primary"></i> Trade Marketplace</span>
                      </div>
                      <div v-if="order.customer_orders.length > 0">
                        <span class="font-size-12 badge bg-primary bg-soft text-dark"><i class="bx bxs-user text-dark"></i> Public Marketplace</span>
                      </div>
                      <div v-if="order.website_orders.length > 0">
                        <span class="font-size-12 badge bg-peach-soft text-primary"><i class="bx bx-globe text-primary"></i> Website</span>
                      </div> 
                      <div v-if="order.pos_orders.length > 0">
                        <span class="font-size-12 badge bg-warning"><i class="bx bx-store"></i> In Store</span>
                      </div> 
                    </div>
                  </div>
						</b-card>
					</b-col>
					<b-col md="4">
						<b-card class="shadow-sm border-radius-1em">
							<div class="d-flex align-items-center mb-3"><div class="avatar-xs mr-3"><span v-if="order.order_cancelled == (null || 0)" class="avatar-title rounded-circle bg-soft-primary text-white font-size-18" :style="{'background' : order.status.color}"><i class="bx bx-history"></i></span>
							<span v-else class="avatar-title rounded-circle bg-danger text-white font-size-18" ><i class="bx bx-x"></i></span></div> <h5 class="font-size-14 mb-0">Order Status</h5></div> <div class="text-muted mt-4"><h4 v-if="order.order_cancelled !== 1">{{order.status.name}}</h4> <h4 v-else-if="(order.order_cancelled == 1) && (order.order_refunded == 0 || null)">Cancelled</h4>
								<h4 v-else-if="(order.order_cancelled == 1) && (order.order_refunded == 1)">Cancelled & Refunded</h4>
								<div class="d-flex flex-wrap"><span class="mr-2 d-none d-lg-block"></span><span v-if="(order.order_paid == 1) && (order.order_refunded == (null || 0))" class="badge badge-soft-success font-size-12"><i class="bx bx-check-circle text-success"></i> Paid</span><span v-else-if="order.order_refunded == 1" class="badge badge-light bg-light font-size-12"><i class="bx bx-transfer-alt text-primary"></i> Refunded</span><span v-else class="badge badge-soft-danger font-size-12"><i class="bx bx-x-circle text-danger"></i> Not Paid</span> 

								<div v-if="order.payments.length > 0">
								<span v-if="order.payments[0].payment_type == 'card'" class="ml-2 font-size-12 badge badge-soft-primary text-primary">
                          <i class="bx bx-credit-card"></i>
                          Card
                        </span>
                        	<span v-if="order.payments[0].payment_type == 'card_terminal'" class="ml-2 font-size-12 badge badge-soft-primary text-primary">
                          <i class="bx bx-credit-card"></i>
                          Card in Store
                        </span>
                         	<span v-if="order.payments[0].payment_type == 'cash'" class="ml-2 font-size-12 badge badge-soft-primary text-primary">
                          <i class="bx bx-cashs"></i>
                        	 Cash
                        </span>
                         	<span v-if="order.payments[0].payment_type == 'cheque'" class="ml-2 font-size-12 badge badge-soft-primary text-primary">
                          <i class="bx bx-cheque"></i>
                          Cheque
                        </span>
								</div>
								<div v-if="order.currency !== null">
									<span class="ml-2 font-size-12 badge badge-soft-info text-info">
										<i :class="order.currency.icon"></i>
										{{order.currency.name}}
										
									</span>
								</div>
							</div>
						</div>
						</b-card>
					</b-col>
					<b-col md="4">
						<b-card class="shadow-sm border-radius-1em">
							<div class="d-flex align-items-center mb-3"><div class="avatar-xs mr-3"><span class="avatar-title rounded-circle bg-soft-primary text-white font-size-18"><i class="bx bx-pound"></i></span></div> <h5 class="font-size-14 mb-0">Order Total</h5></div> <div class="text-muted mt-4"><h4>{{order.currency == null ? '£' : order.currency.symbol}}{{parseFloat(order.total_order_inc).toFixed(2)}} inc. VAT<i class="mdi mdi-chevron-up ml-1 text-success"></i></h4> <div class="d-flex"><span class="font-size-12 badge badge-light bg-light text-dark">{{order.currency == null ? '£' : order.currency.symbol}} {{parseFloat(order.total_order_exc).toFixed(2)}} exc VAT</span> <span class="ml-2 text-truncate">inc. {{order.currency == null ? '£' : order.currency.symbol}}{{parseFloat(order.total_shipping_inc).toFixed(2)}} Delivery Fee</span></div></div>
						</b-card>
					</b-col>
				</b-row>
				<b-tabs pills justified>
					<b-tab>
						<template v-slot:title>
                              <span class="d-inline-block d-none">
                               <!--<i class="bx bx-history font-size-16"></i>-->
                              </span>
                              <span class="d-sm-inline-block"> Products</span>
                            </template>

				
						<b-card class="shadow-none border-radius-1em border mt-3">
							<div class="d-flex w-100 mb-3">
								<h5 class="my-auto"><i class="bx bx-purchase-tag-alt text-primary"></i> Products</h5>
								<a class="btn btn-primary btn-rounded ml-auto my-auto">
									Add Prouct to Order <i class="mdi mdi-plus"></i>
								</a>
								<hr>
							</div>
								<b-list-group flush>
									<b-list-group-item>
										<b-row>
											<b-col md="6">
												<h6 class="mb-0">Product Details</h6>
											</b-col>
											<b-col md="1" class="text-center">
												<h6 class="mb-0">Quantity</h6>
											</b-col>
											<b-col md="2" class="text-center">
												<h6 class="mb-0">Customisations</h6>
											</b-col>
											<b-col md="2">
												<h6 class="mb-0">Sub Totals</h6>
											</b-col>
										</b-row>
									</b-list-group-item>
									<b-list-group-item v-for="(product, pindex) in order.products">
										<b-row>
											<b-col md="1">
												<div v-if="product.product.cover_image !== null" class="text-center">
													<img v-if="'thumb' in product.product.cover_image" :src="storageurl + product.product.cover_image.thumb.replaceAll('//', '/')" class="avatar-sm rounded-circle">
													<img v-else-if="'gallery' in product.product.cover_image" :src="storageurl + product.product.cover_image.gallery.replaceAll('//', '/')" class="avatar-sm rounded-circle">
													<img v-else-if="'src' in product.product.cover_image" :src="storageurl + product.product.cover_image.src.replaceAll('//', '/')" class="avatar-sm rounded-circle">
													</div>
												<img v-else src="/images/product-placeholder.png" class="avatar-sm rounded-circle">
											</b-col>
											<b-col md="5" class="d-flex"> 
												<h6 class="my-auto">{{product.product.name}}</h6>
											</b-col>
											<b-col md="1" class="text-center d-flex">
												<div class="my-auto">{{product.quantity}}</div>
											</b-col>
											<b-col md="2" class="text-center">
												<a v-b-tooltip.hover title="View Customisations" v-if="(product.combinations.length > 0) || (product.variants.length > 0)" class="btn btn-light btn-rounded btn-sm" @click="customisationdropdown = pindex"><i class="mdi mdi-plus"></i></a>
											</b-col>
											<b-col md="3" >
												<b-row>
													<b-col>
												<span class="currency">{{order.currency == null ? '£' : order.currency.symbol}}</span>{{parseFloat(product.price_exc * product.quantity).toFixed(2)}} <small>Exc VAT.</small>
													</b-col>
													<b-col>
												<span class="currency">{{order.currency == null ? '£' : order.currency.symbol}}</span>{{parseFloat(product.price_inc * product.quantity).toFixed(2)}} <small>Inc VAT.</small>
											</b-col>
												</b-row>
											</b-col>
										</b-row>
										<transition name="fade-top">
											<b-row v-if="customisationdropdown == pindex">
											<b-col>
												<b-row  v-if="(product.combinations.length == 0) && (product.variants.length > 0)" class="mt-4">
											<b-col md="12">
											<b-card class="border-radius-1em border shadow-none">
												<label class="text-left mb-4">Customisation Options</label>
											<div class="w-100 mb-3" v-for="(variants, key) in product.grouped_variants">
											
												<h5 class="text-uppercase font-size-12 spaced-title my-2">{{variants[0].variant.attribute.name}}</h5>
											
											
												<b-row >
													<b-col md="12" v-for="value in variants">
													<b-overlay :show="loadingVariants">
														<a href="javascript:;">
														<b-card class="border-top border-bottom bg-transparent shadow-none text-dark mb-0" body-class="d-flex">
															<span class="my-auto" v-if="value.variant.value !== null"><img v-if="value.variant.value.image !== null" :src="value.variant.value.image" class="avatar-xs rounded-circle mr-2 border"> 
															</span>
															<span class="my-auto mr-2" v-if="value.variant.value !== null">{{value.variant.value.name}}</span> <span class="my-auto">{{value.variant.measurement_value}}</span><span class="my-auto mr-2" v-if="value.variant.units !== null">{{value.variant.units.suffix}}</span>
															<span class="ml-auto my-auto">
																{{value.quantity}} X
															</span>
															<span class="badge badge-light bg-light my-auto ml-3 font-size-14">
															<span class="ml-auto my-auto" v-if="value.variant.price_difference > 0">+</span><span class="ml-auto my-auto" v-else>-</span><span>{{order.currency == null ? '£' : order.currency.symbol}}</span>{{value.variant.price_difference}}
															</span>
														</b-card>
														</a>
													</b-overlay>
													</b-col>
												</b-row>
												</div>
											</b-card>
											</b-col>
											
									</b-row>
									<b-row v-else-if="product.combinations.length > 0">
										<b-col>
											<b-card class="border-radius-1em border shadow-none mt-3">
													<h3 class="font-size-18 text-center mb-4">Customisation Options</h3>
											<div class="w-100" v-for="(combination, index) in product.combinations">
												<a href="javascript:;" >
													<b-card  class="border-top border-bottom bg-transparent shadow-none text-dark mb-0">
												<b-row>
													<b-col md="3" v-for="value in combination.combination_variants">
														
														
															<span v-if="value.variant.attribute !== null">{{value.variant.attribute.name}}</span> 
															<span v-if="value.variant.value !== null">{{value.variant.value.name}}</span> {{value.variant.measurement_value}}<span v-if="value.variant.units !== null">{{value.variant.units.suffix}}</span>
															
															
														
														
													</b-col>
												</b-row>
												<b-row>
													<b-col md="2" class="d-flex offset-md-8">
														<span class="ml-auto my-auto">Quantity: {{combination.quantity}}</span>
													</b-col>
													<b-col md="2" class="d-flex">
														<span class="badge badge-light bg-light ml-auto mt-2 font-size-14">
														<span v-if="combination.price_difference > 0">+</span><span v-else>-</span><span>{{order.currency == null ? '£' : order.currency.symbol}}</span>{{combination.price_difference}}
															</span>
													</b-col>
												</b-row>
												</b-card>
												</a>
											</div>
											</b-card>
										</b-col>
								       </b-row>

								     </b-col>
								   </b-row>
								 </transition>
									</b-list-group-item>
									<b-list-group-item>
										<b-row>
										<b-col md="9" class="d-flex">
											<strong class="ml-auto">
											Sub Totals</strong>
										</b-col>
										<b-col md="3" >
												<b-row>
													<b-col>
												<span class="currency">{{order.currency == null ? '£' : order.currency.symbol}}</span>{{parseFloat(order.total_products_exc).toFixed(2)}} <small>Exc VAT.</small>
													</b-col>
													<b-col>
												<span class="currency">{{order.currency == null ? '£' : order.currency.symbol}}</span>{{parseFloat(order.total_products_inc).toFixed(2)}} <small>Inc VAT.</small>
											</b-col>
												</b-row>
											</b-col>
										</b-row>
									</b-list-group-item>
									<b-list-group-item>
									   <b-row>
													<b-col md="9" class="d-flex">
														<strong class="ml-auto">
											Customisations</strong>
										</b-col>
										<b-col md="3" >
												<b-row>
													<b-col>
												<span class="currency">{{order.currency == null ? '£' : order.currency.symbol}}</span>{{(parseFloat((order.total_combinations_exc == null ? 0.00 : order.total_combinations_exc)) + parseFloat((order.total_variants_exc == null ? 0.00 : order.total_variants_exc))).toFixed(2)}} <small>Exc VAT.</small>
													</b-col>
													<b-col>
												<span class="currency">{{order.currency == null ? '£' : order.currency.symbol}}</span>{{(parseFloat((order.total_combinations_inc == null ? 0.00 : order.total_combinations_inc)) + parseFloat((order.total_variants_inc == null ? 0.00 : order.total_variants_inc))).toFixed(2)}} <small>Exc VAT.</small>
											</b-col>
												</b-row>
											</b-col>
											</b-row>
									</b-list-group-item>
									<b-list-group-item>
									   <b-row>
														<b-col md="9" class="d-flex">
															<strong class="ml-auto">
											Postage</strong>
										</b-col>
										<b-col md="3" >
												<b-row>
													<b-col>
												<span class="currency">{{order.currency == null ? '£' : order.currency.symbol}}</span>{{parseFloat(order.total_shipping_exc)}} <small>Exc VAT.</small>
													</b-col>
													<b-col>
												<span class="currency">{{order.currency == null ? '£' : order.currency.symbol}}</span>{{parseFloat(order.total_shipping_inc)}} <small>Inc VAT.</small>
											</b-col>
												</b-row>
											</b-col>
											</b-row>
									</b-list-group-item>
									<b-list-group-item>
									   <b-row>
														<b-col md="9" class="d-flex">
															<strong class="ml-auto">
											Taxes</strong>
										</b-col>
										<b-col md="3" >
												<b-row>
													<b-col>
													<span class="currency">{{order.currency == null ? '£' : order.currency.symbol}}</span>{{((parseFloat(order.total_order_inc) - parseFloat(order.total_order_exc)) + (parseFloat(order.total_combinations_inc == null ? 0.00 : order.total_combinations_inc) - parseFloat(order.total_combinations_exc == null ? 0.00 : order.total_combinations_exc)) + (parseFloat(order.total_variants_inc == null ? 0.00 : order.total_variants_inc) - parseFloat(order.total_variants_exc == null ? 0.00 : order.total_variants_exc))).toFixed(2)}}
													</b-col>
													<b-col>
												
											</b-col>
												</b-row>
											</b-col>
											</b-row>
									</b-list-group-item>
									<b-list-group-item>
									   <b-row>
														<b-col md="9" class="d-flex">
															<strong class="ml-auto">
											Totals</strong>
										</b-col>
										<b-col md="3" >
												<b-row>
													<b-col>
												<span class="currency">{{order.currency == null ? '£' : order.currency.symbol}}</span>{{parseFloat(order.total_order_exc).toFixed(2)}} <small>Exc VAT.</small>
													</b-col>
													<b-col>
												<span class="currency">{{order.currency == null ? '£' : order.currency.symbol}}</span>{{parseFloat(order.total_order_inc).toFixed(2)}} <small>Inc VAT.</small>
											</b-col>
												</b-row>
											</b-col>
										</b-row>
									</b-list-group-item>
								</b-list-group>
								
									<!--<b-list-group-item class="border-top d-flex">
										<div class="font-italic">Item(s) Total</div>
										<div class="my-auto text-bold ml-auto"><strong>£</strong>{{(company.company_order.total_order_exc - company.company_order.total_shipping_exc).toFixed(2)}}</div>
									</b-list-group-item>
									<b-list-group-item class="border-top d-flex">
										<div class="font-italic">Customisations Total</div>
										<div class="my-auto text-bold ml-auto"><strong>£</strong>{{((company.company_order.total_combinations_exc) + (company.company_order.total_variants_exc)).toFixed(2)}}</div>
									</b-list-group-item>
									<b-list-group-item class="border-top d-flex">
										<div class="font-italic">Shipping</div>
										<div class="my-auto text-bold ml-auto"><strong class="text-primary">+£</strong>{{parseFloat(company.company_order.total_shipping_inc).toFixed(2)}}</div>
									</b-list-group-item>
									<b-list-group-item class="border-top d-flex">
										<div class="font-italic">Taxes</div>
										<div class="my-auto text-bold ml-auto"><strong class="text-primary">+£</strong>{{(company.company_order.total_order_inc - company.company_order.total_order_exc).toFixed(2)}}</div>
									</b-list-group-item>
									<b-list-group-item class="border-top d-flex">
										<div class="my-auto text-bold ml-auto"><p class="font-size-18"><strong class="text-primary">£</strong>{{parseFloat(company.company_order.total_order_inc).toFixed(2)}}
											</p>
										</div>
									</b-list-group-item>-->
								</b-list-group>
						</b-card>
					</b-tab>
					<b-tab>
						<template v-slot:title>
                              <span class="d-inline-block d-none">
                               <!--<i class="bx bx-history font-size-16"></i>-->
                              </span>
                              <span class="d-sm-inline-block"> Payments</span>
                            </template>

					
						<b-card class="shadow-none border-radius-1em border mt-3">
							<div class="d-flex w-100 mb-3">
								<h5 class="my-auto"><i class="bx bx-purchase-tag-alt text-primary"></i> Payment History</h5>
								<a class="btn btn-primary btn-rounded ml-auto my-auto" v-if="order.order_paid == false" v-b-modal.action-modal @click="action = 'setup-payment'">
									Make Payment <i class="mdi mdi-plus"></i>
								</a>
								<hr>
							</div>
									<b-list-group flush>
									<b-list-group-item>
										<b-row>
											<b-col md="4">
												<h6 class="mb-0">Type & Reference</h6>
											</b-col>
											<b-col md="2">
												<h6 class="mb-0">Date</h6>
											</b-col>
											<b-col md="2">
												<h6 class="mb-0">Order Total</h6>
											</b-col>
											<b-col md="2">
																			Fee
											</b-col>
											<b-col md="2">
																			Total Received
											</b-col>
										</b-row>
									</b-list-group-item>
									<b-list-group-item v-for="payment in order.payments">
										<b-row>
											<b-col md="4">
												<h5 class="text-capitalize mb-0">{{payment.payment_type}}</h5>
												<small>{{payment.transaction_reference}}</small>
											</b-col>
											<b-col md="2" class="d-flex flex-column">
												<div class="my-auto">
												{{parseDate(payment.created_at)}}
												</div>
											</b-col>
											<b-col md="2" v-if="payment.payment_type !== 'refund'"  class="d-flex flex-column">
												<div class="my-auto">
												<span class="currency">{{payment.currency == null ? '£' : payment.currency.symbol}}</span>{{(parseFloat(payment.amount) + parseFloat(payment.fee)).toFixed(2)}}
												</div>
											</b-col>
											<b-col v-else md="2"  class="d-flex flex-column">
												<div class="my-auto">
												<span class="" v-if="payment.amount < 0">-</span>
												<span class="currency">{{payment.currency == null ? '£' : payment.currency.symbol}}</span>{{parseFloat(Math.abs(payment.amount)).toFixed(2)}} 
												</div>
											</b-col>
											<b-col md="2"  class="d-flex flex-column">
												<div class="my-auto"  v-if="payment.payment_type !== 'refund'">
												-<span class="currency">{{payment.currency == null ? '£' : payment.currency.symbol}}</span>{{payment.fee}} Fee<br>
												</div>
											</b-col>
											<b-col md="2"  class="d-flex flex-column">
												<div class="my-auto">
												= <span class="currency">{{payment.currency == null ? '£' : payment.currency.symbol}}</span>{{payment.amount}}
												</div>
											</b-col>
										</b-row>
									</b-list-group-item>
									<b-list-group-item>
											<b-row>
											<b-col md="4">
												<strong>
													Payment Totals
												</strong>
											</b-col>
											<b-col md="2">
											
											</b-col>
											<b-col md="2">
												<span class="currency">{{order.currency == null ? '£' : order.currency.symbol}}</span>{{(parseFloat(order.payments_sum_amount) + parseFloat(order.payments_sum_fee)).toFixed(2)}} 
											</b-col>
											<b-col md="2">
												-<span class="currency">{{order.currency == null ? '£' : order.currency.symbol}}</span>{{(parseFloat(order.payments_sum_fee)).toFixed(2)}} Fee
											</b-col>
											<b-col md="2">
												<span class="currency">{{order.currency == null ? '£' : order.currency.symbol}}</span>{{(parseFloat(order.payments_sum_amount)).toFixed(2)}} 
												<!--<span class="currency">£</span>{{(parseFloat(payment.amount) + parseFloat(payment.fee)).toFixed(2)}}<br>
												-<span class="currency">£</span>{{payment.fee}} Fee<br>
												={{payment.amount}}-->
											</b-col>
										</b-row>
									</b-list-group-item>
								</b-list-group>
						</b-card>
					</b-tab>
					<b-tab>
						<template v-slot:title>
                              <span class="d-inline-block d-none">
                               <!--<i class="bx bx-history font-size-16"></i>-->
                              </span>
                              <span class="d-sm-inline-block"> Delivery</span>
                            </template>

				
						<b-card class="shadow-none border-radius-1em border mt-3">
						<div class="d-flex w-100 mb-3"> 
								<h5 class="my-auto"><i class="bx bx-purchase-tag-alt text-primary"></i> Deliveries</h5>
								<a class="btn btn-primary btn-rounded ml-auto my-auto" v-b-modal.action-modal @click="actionModalSize = 'xl', action = 'arrange-delivery'">
									Book New Delivery <i class="mdi mdi-plus"></i>
								</a>
								<hr>
							</div>
							<b-card class="shadow-none border border-radius-1em mt-3" v-if="order.shippings.length > 0">
							<b-row>
								<b-col md="12">
									<b-list-group>
										<b-list-group-item v-for="shipping in order.shippings" class="d-flex">
											<div class="my-auto">
												<span>{{shipping.dc_request_id}}</span>
											</div>
											<div class="my-auto ml-auto mr-3">
												<span class="spaced-title">Tracking Code:</span>
												<span class="badge badge-light bg-light font-size-12"># {{shipping.delivery_reference}}</span>
											</div>
											<div class="mr-2 ml-auto">
												<a class="btn btn-outline-light btn-rounded" >Download Label <i class="mdi mdi-download"></i></a>
											</div>
											<div class="mr-2 ml-2">
												<a class="btn btn-outline-light btn-rounded" >Open & Print Label <i class="bx bx-printer"></i></a>
											</div>
											<div class="mr-2 ml-2">
												<a class="btn btn-primary btn-rounded" v-b-modal.action-modal  @click=" action = 'delivery-tracking', actionModalSize = 'xl', getDeliveryStatus(shipping)" >Tracking Progress <i class="bx bx-print"></i></a>
											</div>
										</b-list-group-item>
									</b-list-group>
								</b-col>
							</b-row>
						</b-card>
								<b-card class="shadow-none border border-radius-1em mt-3" body-class="d-flex flex-column justify-content-center align-items-center" v-else>
								<i class="bx bxs-truck font-size-42 text-muted"></i>
								<p>
									No Deliveries Booked
								</p>
								<a class="btn btn-primary btn-rounded" v-b-modal.action-modal @click="actionModalSize = 'xl', action = 'arrange-delivery'">
									Book New Delivery
								</a>
							</b-card>
						</b-card>
					</b-tab>
					<b-tab @click="loadOrderMessages()">
						<template v-slot:title>
                              <span class="d-inline-block d-none">
                               <!--<i class="bx bx-history font-size-16"></i>-->
                              </span>
                              <span class="d-sm-inline-block"> Messages</span>
                            </template>

				
						<b-card class="shadow-none border-radius-1em border mt-3">
								<div class="d-flex w-100 mb-3"> 
								<h5 class="my-auto"><i class="bx bx-message-alt text-primary"></i> Messages</h5>
								<a class="btn btn-primary btn-rounded ml-auto my-auto" v-b-modal.action-modal @click="action = 'message-customer'">
									Start New Thread <i class="mdi mdi-plus"></i>
								</a>
								<hr>
							</div>
							<b-card class="shadow-none border border-radius-1em mt-3" v-if="orderMessages.length > 0">
								 <CompanyCustomerSupportTwilioThreads v-if="(orderMessages.length > 0)" :thread_list="orderMessages" :expanded="true" :view="'list'" :user="$attrs.user" :thread_uid="''" :userview="'company'" :order_source="orderSource" :company_order="order"></CompanyCustomerSupportTwilioThreads>
								</b-card>
								<b-card class="shadow-none border border-radius-1em mt-3" body-class="d-flex flex-column justify-content-center align-items-center" v-else>
									<i class="bx bx-mail-send font-size-42 text-muted"></i>
									<p>
										No Support Messages Sent
									</p>
									<a class="btn btn-primary btn-rounded" v-b-modal.action-modal @click="action = 'message-customer'">
										Create Support Thread
									</a>
								</b-card>
						</b-card>
					</b-tab>
				</b-tabs>
			</b-col>
		</b-row>

		        <b-row class="d-lg-none d-block">
		        	<b-col md="12" class="mb-3">
		       			<button class="btn btn-primary btn-soft btn-rounded btn-block" v-b-modal.action-modal @click="action = 'message-customer'">
		       				<div class="d-flex m-2">
								 Message Customer <i class="bx bx-message-alt font-size-20 ml-auto icon-label-end"></i>
									</div>
								</button>
							</b-col>
							<b-col md="12" class="mb-3" v-if="(order.order_paid == false) || (order.order_paid == 0) || (order.order_paid == null)">
		       			<button class="btn btn-primary btn-soft btn-rounded btn-block"  v-b-modal.action-modal  @click="action = 'setup-payment'" >
		       				<div class="d-flex m-2">
								 Make Payment  <i class="bx bx-credit-card-alt font-size-20 ml-auto icon-label-end"></i>
									</div>
								</button>
							</b-col>
							<b-col md="12" class="mb-3" v-else-if="order.order_paid == 1 || order.order_paid == true">
								<button class="btn btn-primary btn-soft btn-rounded btn-block"  v-b-modal.action-modal @click="action = 'setup-refund'" v-if="order.order_refunded !== 1" >
								<div class="d-flex m-2">
									
									Process Refund <i class="bx bx-credit-card-alt font-size-20 ml-auto icon-label-end"></i>
								</div>
							</button>
							<button class="btn btn-primary btn-soft btn-rounded btn-block"  v-b-modal.action-modal @click="action = 'reverse-refund'" v-else >
								<div class="d-flex m-2">
									
									Reverse Refund <i class="bx bx-credit-card-alt font-size-20 ml-auto icon-label-end"></i>
								</div>
							</button>
							</b-col>
							<b-col md="12" class="mb-3">
		       			<button class="btn btn-primary btn-soft btn-rounded btn-block" v-b-modal.action-modal @click="actionModalSize = 'xl', action = 'arrange-delivery'">
		       				<div class="d-flex m-2">
								 Arrange Delivery  <i class="bx bx-box font-size-20 ml-auto icon-label-end"></i>
									</div>
								</button>
							</b-col>
							<b-col md="12" class="mb-3">
		       			<button class="btn btn-primary btn-soft btn-rounded btn-block" v-b-modal.invoice-modal v-if="order.invoice_created == 0">
		       				<div class="d-flex m-2">
								 Process Invoice  <i class="bx bxs-file-pdf font-size-20 ml-auto icon-label-end"></i>
									</div>
								</button>
								<button class="btn btn-success btn-soft btn-rounded btn-block" v-b-modal.invoice-modal v-else-if="order.invoice_created == 1">
		       				<div class="d-flex m-2">
								 Print & Send Invoice  <i class="bx bxs-check-circle font-size-20 ml-auto icon-label-end"></i>
									</div>
								</button>
							</b-col>
							<b-col md="12" class="mb-3">
								<button class="btn btn-soft btn-rounded btn-block" :style="{'background-color' : order.status.color}" :class="order.status !== null ? order.status.text_color : 'text-dark'" v-b-modal.action-modal @click="action = 'update-status'">
								<div class="d-flex m-2">
									Update Status <i class="bx bx-history font-size-20 ml-auto icon-label-end"></i>
								</div>
								</button>
									</b-col>
							<b-col md="12" class="mb-3">
								<button class="btn btn-success btn-rounded btn-block" v-b-modal.invoice-modal>
									<div class="d-flex m-2">
									 Mark Complete <i class="bx bxs-check-circle font-size-20 ml-auto icon-label-end"></i>
									</div>
								</button>
							</b-col>
		       </b-row>



		<OrderPayment v-if="(action == 'make-payment')" :user="$attrs.user" :company="$attrs.company" :order="order" :amount_payable="amount_payable" @close-window="action = ''" :id="id" :source="orderSource" :order_paid="mark_fully_paid" :selected_currency="order.currency !== null ? order.currency.code : $attrs.company.currency.code" :selected_currency_symbol="order.currency !== null ? order.currency.symbol : $attrs.company.currency.symbol" ></OrderPayment>

		<b-modal id="action-modal" centered :size="actionModalSize" centered content-class="border-radius-1em" header-class="border-none" hide-footer scrollable >
			<div v-if="action == 'message-customer'" >
				
				  			<div v-if="order.trade_orders.length !== 0">
		                        <CompanySupportForm :user="$attrs.user" :customer="order.trade_orders[0].order.customer" :support_order="order.trade_orders[0].order" :support_company_order="order"  :type="'trade'" :orders="orders" :company="$attrs.company" @ticket-created="getMessages('trade'), $bvModal.hide('action-modal')" ></CompanySupportForm>
		                      </div>
		                      <div v-if="order.customer_orders.length !== 0">
		                        <CompanySupportForm :user="$attrs.user" :customer="order.customer_orders[0].order.customer" :support_order="order.customer_orders[0].order" :support_company_order="order" :type="'public'" :orders="orders" :company="$attrs.company" @ticket-created="getMessages('public'), $bvModal.hide('action-modal')" ></CompanySupportForm>
		                      </div>
		                      <div v-if="order.website_orders.length !== 0">
		                       <CompanySupportForm :user="$attrs.user" :customer="order.website_orders[0].order.customer" :support_order="order.website_orders[0].order" :support_company_order="order" :type="'website'" :orders="orders" :company="$attrs.company" @ticket-created="getMessages('website'), $bvModal.hide('action-modal')" ></CompanySupportForm>
		                      </div>
		                      <div v-if="order.pos_orders.length !== 0">
		                         <CompanySupportForm :user="$attrs.user" :customer="order.pos_orders[0].order.customer" :support_order="order.pos_orders[0].order" :support_company_order="order" :type="'pos'" :orders="orders" :company="$attrs.company" @ticket-created="getMessages('pos'), $bvModal.hide('action-modal')" ></CompanySupportForm>
		                      </div>
			</div>
				
			
			<b-form v-if="action == 'setup-refund'">
					<b-card class="border-radius-1em border shadow-none "  body-class="d-flex justify-content-center align-items-center flex-column p-4">
						<h3>Process Refund</h3>
						<!--<b-row class="w-100 mt-3">
 						<b-col md="6">
						<a class="btn btn-light btn-rounded btn-block" @click="refund_amount = parseFloat(order.total_order_inc).toFixed(2)">
							Refund Order Total: {{parseFloat(order.total_order_inc).toFixed(2)}}
						</a>
						</b-col>
						<b-col md="6">
					<a class="btn btn-light btn-rounded btn-block" @click="custom_payment_amount = true">
						Refund Custom Amount
					</a>
						</b-col>
				
					<b-col md="12" v-if="custom_payment_amount == true" class="mt-3">
					<b-form-group  label="Payment Amount">
						<b-form-input type="number" v-model="refund_amount">

						</b-form-input>
					</b-form-group>

				
						</b-col>
						<b-col md="12" v-if="refund_amount > 0" class="d-flex">
							<a class="btn-rounded btn btn-primary mt-3 ml-auto" @click="processRefund()">
								Process Refund
							</a>
						</b-col>
						</b-row>-->
									<b-list-group flush class="w-100 pt-3">
									<b-list-group-item class="border bg-light bg-soft">
										<b-row>
											<b-col md="4" class="d-flex flex-column justify-content-center">
												<h6 class="mb-0">Payment Type & Reference</h6>
											</b-col>
											<b-col md="3" class="d-flex flex-column justify-content-center">
												<h6 class="mb-0">Date</h6>
											</b-col>
											<b-col md="2" class="d-flex flex-column justify-content-center">
												<h6 class="mb-0">Amount</h6>
											</b-col>
											<b-col md="3" class="d-flex flex-column justify-content-center">
												<a class="btn btn-light btn-rounded my-auto ml-auto" @click="refund_amount = payment.amount, processRefund('multiple', null, null)">
													Refund All
												</a>
											</b-col>
										</b-row>
									</b-list-group-item>
									<b-list-group-item v-for="payment in order.payments">
										<b-row>
											<b-col md="4">
												<h6 class="text-capitalize mb-0">{{payment.payment_type}}</h6>
												
												<small>{{payment.transaction_reference}}</small>
											</b-col>
											<b-col md="3" class="d-flex flex-column">
												<div class="my-auto">
												{{payment.created_at  | luxon({input: 'server'})}}
												</div>
											</b-col>
											
											<b-col md="2" class="d-flex flex-column">
												<div class="my-auto">
											<span class="currency">{{payment.currency == null ? '£' : payment.currency.symbol}}</span>{{payment.amount}}
												</div>
											</b-col>
											<b-col md="3"  class="d-flex flex-column" v-if="(payment.payment_type !== 'refund') && (payment.refunded == null || 0)">
													<a class="btn btn-light btn-rounded my-auto ml-auto" @click="refund_amount = payment.amount, processRefund(payment.charge_id, payment.transfer_id, payment)">
													Refund Payment
												</a>
											</b-col>
										</b-row>
									</b-list-group-item>
									<!--<b-list-group-item>
										<b-row>
											<b-col md="7">
												<h6 class="my-auto">Custom Amount</h6>
											</b-col>
											<b-col md="2">
												<b-form-input type="number" v-model="refund_amount" min="0" >
												</b-form-input>
											</b-col>
											<b-col md="3"  class="d-flex flex-column">
													<a class="btn btn-light btn-rounded my-auto ml-auto" @click="processRefund(null, null)">
													Refund Payment
												</a>
											</b-col>
										</b-row>
									</b-list-group-item>-->
								</b-list-group>
								<b-form-group label="Mark Order Cancelled">
									<b-button class="btn btn-outline-light" variant="outline-light" @click="order_cancelled = true">
										<i class="bx bx-check text-success" v-if="order_cancelled == true"></i>
										<span>
											Yes
										</span>
									</b-button>
									<b-button class="btn btn-outline-light" variant="outline-light" @click="order_cancelled = false">
										<i class="bx bx-x text-danger" v-if="order_cancelled == false"></i>
										<span>
											No
										</span>
									</b-button>
								</b-form-group>
					</b-card>
			</b-form>
			<b-form v-if="action == 'setup-payment'">
				<b-card class="border-radius-1em border shadow-none "  body-class="d-flex justify-content-center align-items-center flex-column p-4">
					<h3>Set-up Payment</h3>
 					<b-row class="w-100 mt-3">
 						<!--<b-col md="4">
						<a class="btn btn-light btn-rounded btn-block" @click="mark_fully_paid = true, amount_payable = parseFloat(order.total_order_inc).toFixed(2)">
							Order Total: {{order.currency == null ? '£' : order.currency.symbol}}{{parseFloat(order.total_order_inc).toFixed(2)}} 
						</a>
						</b-col>-->
						<b-col md="6">
					<a class="btn btn-light btn-rounded btn-block" @click="mark_fully_paid = true, amount_payable = parseFloat(order.total_order_inc).toFixed(2)">
						Remaining Balance: {{order.currency == null ? '£' : order.currency.symbol}}{{parseFloat(order.total_order_inc).toFixed(2)}} 
					</a>
						</b-col>
						<b-col md="6">
					<a class="btn btn-light btn-rounded btn-block" @click="custom_payment_amount = true">
						Custom Amount
					</a>
						</b-col>
				
					<b-col md="12" v-if="custom_payment_amount == true">
					<b-form-group  label="Payment Amount">
						<b-form-input type="number" v-model="amount_payable">

						</b-form-input>
					</b-form-group>

				
					</b-col>

					<b-col md="12" v-if="amount_payable > 0" class="mt-3 d-flex">
						<a class="btn btn-light border-radius-1em btn-md mx-2 btn-block w-25 d-flex flex-column justify-content-center" @click="payment_type = 'card'" >
							<i class="bx bx-credit-card font-size-36 text-primary d-block my-2"></i>
							Card Payment
						</a>
						<a class="btn btn-light border-radius-1em btn-md mx-2  btn-block w-25 d-flex flex-column justify-content-center" @click="payment_type = 'terminal'" >
							<i class="mdi mdi-cash-register font-size-36 text-primary d-block"></i>
							Reader Payment
						</a>
						<a class="btn btn-light border-radius-1em btn-md mx-2  btn-block w-25 d-flex flex-column justify-content-center" @click="payment_type = 'cash'" >
							<i class="mdi mdi-cash font-size-36 text-primary d-block"></i>
							Cash Payment
						</a>

						<a class="btn btn-light border-radius-1em btn-md mx-2  btn-block w-25 d-flex flex-column justify-content-center"  @click="payment_type = 'cheque'">
							<i class="mdi mdi-pen font-size-36 text-primary d-block"></i>
							Cheque Payment
						</a>
					</b-col>
					<b-col md="12" class="mt-3">
						<a v-if="payment_type == 'card'" class="btn btn-primary btn-rounded btn-block" @click="checkFullyPaid(), action = 'make-payment', $bvModal.hide('action-modal')">
							Process Payment
						</a>

						<a v-if="payment_type == 'terminal'" class="btn btn-primary btn-rounded btn-block" @click="action = 'make-terminal-payment', $bvModal.hide('action-modal')">
							Process Payment
						</a>

						<a v-if="payment_type == 'cash'" class="btn btn-primary btn-rounded btn-block" @click="addPayment('cash'), $bvModal.hide('action-modal')">
							Process Payment
						</a>

						<a v-if="payment_type == 'cheque'" class="btn btn-primary btn-rounded btn-block" @click="addPayment('cheque'), $bvModal.hide('action-modal')">
							Process Payment
						</a>
					</b-col>
						</b-row>
				</b-card>
			</b-form>
			<b-overlay :show="loadingDeliveryBooking">
			<b-form v-if="action == 'arrange-delivery'">
				<b-card class="border-radius-1em border shadow-none "  body-class="d-flex justify-content-center align-items-center flex-column p-4"  v-if="selected_carrier_specifics == ''" >
									<div class="m-3 p-3 text-center" v-if="active_carrier_integrations.length == 0">
										<i class="bx bxs-truck font-size-24 text-primary"></i>
										<h5>No Shipping Integrations Found</h5>
										<a class="btn btn-primary btn-rounded mt-3" v-if="shipping_apikeys == ''" @click="setUpCourierApiKey()">Create API Key <i class="bx bx-key"></i></a>
										<a class="btn btn-primary btn-rounded mt-3" v-else @click="getAllShippingIntegrations()">Refresh Shipping Integrations <i class="bx bx-refresh"></i></a>
									</div>
									<div v-else class="w-100">
										<b-overlay 
								:show="loadingShippingIntegration"
								>
										<b-list-group flush>
											<b-list-group-item v-for="courier in active_carrier_integrations" class="d-flex">
												<img :src="getShippingImage(courier.courier)" class="my-auto mr-3" width="75px">
												<h6 class="my-auto mr-auto">
												Courier name: {{courier.courier}}
												</h6>
											<a class="my-auto w-25 btn-rounded btn btn-primary" @click="selected_carrier = courier, getCourierSpecifics(courier.courier), getCourierPresets(courier.courier), getShippingAddress(), create_delivery_label.shipment.reference_2 = order.uid">Book Shipping</a>
											</b-list-group-item>
										</b-list-group>
										</b-overlay>
									</div>
								</b-card>
								<transition name="fade-top">
										<div  v-if="selected_carrier_specifics !== ''">
											<b-card class="shadow-none border-radius-1em border" body-class="d-flex">
												
												<img :src="getShippingImage(selected_carrier.courier)" class="my-auto mr-3" width="75px">
												<h6 class="my-auto mr-auto">
												Selected courier: {{selected_carrier.courier}}
												</h6>
											
													<a class="btn btn-light ml-auto my-auto btn-rounded" @click="selected_carrier_specifics = '', selected_carrier = '', create_label_step = -1">
														Change Courier
													</a>
												
											</b-card>
											<b-tabs v-model="create_label_step" pills justified content-class="pt-3">
												<b-tab >
													<template #title>
														<span v-if="create_label_step == 0" class="bg-white text-primary border-primary rounded-circle px-2 mr-2">1</span>
														<span v-else-if="create_label_step > 0" class="bg-white text-primary border-primary rounded-circle px-2 mr-2"> <i class="bx bx-check text-success"></i></span>
														 Label Options
													</template>
											<b-card class="shadow-none border-radius-1em border" v-if="create_label_step == 0">
												<h5>Courier Service</h5>
												<b-form-select class="form-control" v-model="selected_carrier_preset" @input="setCourierSpecifics($event)">
													<b-form-select-option v-for="preset in selected_carrier_presets.system_presets" :value="preset">
														{{preset.name}}
													</b-form-select-option>
												</b-form-select>
											</b-card>
											<b-card class="shadow-none border-radius-1em border" v-if="create_label_step == 0">
												<h5>Label Options</h5>
												<b-form-group class="mb-2" label="Label size">
													<b-form-select v-model="create_delivery_label.shipment.label_size" class="form-control" :options="['6x4']">

													</b-form-select>
												</b-form-group>
												<b-form-group class="mb-2" label="Label Format">
													<b-form-select v-model="create_delivery_label.shipment.label_format" class="form-control" :options="['pdf', 'png']">

													</b-form-select>
												</b-form-group>
												<b-form-group class="mb-2" label="Generate Shipping Invoice">
													<b-form-checkbox v-model="create_delivery_label.shipment.generate_invoice" >

													</b-form-checkbox>
												</b-form-group>
												<b-form-group class="mb-2" label="Generate Packing Slip">
													<b-form-checkbox v-model="create_delivery_label.shipment.generate_packing_slip">

													</b-form-checkbox>
												</b-form-group>
											
											</b-card>
											<b-card class="shadow-none border-radius-1em border" v-if="create_label_step == 0 && selected_carrier_specifics !== ''">

												<h5>Courier Information</h5>
												<b-form-group class="mb-2 text-capitalize" v-for="specific in selected_carrier_specifics['required']" :label="specific.name.replaceAll('_', ' ')" :description="specific.description" required>
											 		<b-form-input required v-if="specific.type == 'string'" :type="specific.type" v-model="create_delivery_label.shipment.courier[specific.name]">
											 		</b-form-input>

											 		<b-form-radio-group required v-else-if="specific.type == 'boolean'" v-model="create_delivery_label.shipment.courier[specific.name]" :options="[{value: false, text: 'No'},{value: true, text: 'Yes'}]">
											 		</b-form-radio-group>
											 	</b-form-group>
											 	<b-form-group class="mb-2 text-capitalize" v-for="specific in selected_carrier_specifics['optional']" :label="specific.name.replaceAll('_', ' ')" :description="specific.description">
											 		<b-form-input v-if="specific.type == 'string'" :type="specific.type" v-model="create_delivery_label.shipment.courier[specific.name]">
											 		</b-form-input>

											 		<b-form-radio-group v-else-if="specific.type == 'boolean'" v-model="create_delivery_label.shipment.courier[specific.name]" :options="[{value: false, text: 'No'},{value: true, text: 'Yes'}]">
											 		</b-form-radio-group>
											 	</b-form-group>

											 	<b-card v-if="selected_carrier_specifics == '' || (selected_carrier_specifics['optional'].length == 0 &&selected_carrier_specifics['required'] == 0)" class="bg-info bg-soft text-info border-radius-1em mb-0">
											 			No courier specifics for this shipping method.
											 	</b-card>
											</b-card>
											<b-card class="shadow-none border-radius-1em border">
												<h5>Collection Information</h5>
													<b-form-group class="mb-2" label="Collection Date">
													<b-form-input type="datetime-local" v-model="create_delivery_label.shipment.collection_date">
													</b-form-input>
												</b-form-group>
												<div v-if="collectionSupported(create_delivery_label.shipment.courier.name)">
												<b-form-group>
												</b-form-group>
											</div>
											</b-card>
										</b-tab>
											
										<b-tab :title-link-class="{'disabled' : create_label_step < 1}">
													<template #title>
														<span v-if="create_label_step == 1" class="bg-white text-primary border-primary rounded-circle px-2 mr-2">2</span>
														<span v-else-if="create_label_step > 1" class="bg-white text-primary border-primary rounded-circle px-2 mr-2"> <i class="bx bx-check text-success"></i></span> Ship From
													</template>
											<b-card class="border-radius-1em border shadow-none mt-3" v-if="create_label_step == 1">
													<h5>Ship From:</h5>
								<b-form-group class="mt-2"  label="Address 1">
                  <b-form-input type="text" name="address1" v-model="create_delivery_label.shipment.ship_from.address_1"></b-form-input>
                </b-form-group>
                <b-form-group class="mt-2" label="Address 2">
                  <b-form-input type="text" name="address2" v-model="create_delivery_label.shipment.ship_from.address_2"></b-form-input>
                </b-form-group>
                <b-form-group class="mt-2" label="Address 3">
                  <b-form-input type="text" name="address3" v-model="create_delivery_label.shipment.ship_from.address_3"></b-form-input>
                </b-form-group>
                
                <b-form-group class="mt-2" label="Country">
                  <b-form-select class="form-control" v-model="selected_country_id" name="country_id"  @input="getCounties(selected_country_id.id)" @change="create_delivery_label.shipment.ship_from.country = $event.iso_code">
                    <b-form-select-option v-for="country in countries" :value="country">
                      {{country.name}}
                    </b-form-select-option>
                  </b-form-select>
                </b-form-group>
                <b-form-group class="mt-2" label="County/State">
                  <b-form-select class="form-control" name="county_id" v-model="selected_county_id" :disabled="(counties.length == 0)" @change="create_delivery_label.shipment.ship_from.county = $event.name">
                    <b-form-select-option v-for="county in counties" :value="county" >
                      {{county.name}}
                    </b-form-select-option>
                  </b-form-select>
                </b-form-group>
                <b-form-group class="mt-2" label="City">
                  <b-form-input type="text" name="city" v-model="create_delivery_label.shipment.ship_from.city"></b-form-input>
                </b-form-group>
                <b-form-group class="mt-2" label="Postcode/Zip">
                  <b-form-input type="text" name="postcode" v-model="create_delivery_label.shipment.ship_from.postcode"></b-form-input>
                </b-form-group>
                 <h6>Contact Information</h6>
                <b-form-group class="mt-2" label="Phone">
                  <b-form-input type="telephone" name="phone" v-model="create_delivery_label.shipment.ship_from.phone"></b-form-input>
                </b-form-group>
                <b-form-group class="mt-2" label="Email">
                  <b-form-input type="email" name="email" v-model="create_delivery_label.shipment.ship_from.email"></b-form-input>
                </b-form-group>
              </b-card>
            </b-tab>
            	<b-tab :title-link-class="{'disabled' : create_label_step < 2}">
													<template #title>
														<span v-if="create_label_step == 2" class="bg-white text-primary border-primary rounded-circle px-2 mr-2">3</span>
														<span v-else-if="create_label_step > 2" class="bg-white text-primary border-primary rounded-circle px-2 mr-2"> <i class="bx bx-check text-success"></i></span> Ship To:
													</template>
											<b-card class="border-radius-1em border shadow-none mt-3" v-if="create_label_step == 2">
													<h5>Ship To: </h5>
								<b-form-group class="mt-2"  label="Address 1">
                  <b-form-input type="text" name="address1" v-model="create_delivery_label.shipment.ship_to.address_1"></b-form-input>
                </b-form-group>
                <b-form-group class="mt-2" label="Address 2">
                  <b-form-input type="text" name="address2" v-model="create_delivery_label.shipment.ship_to.address_2"></b-form-input>
                </b-form-group>
                <b-form-group class="mt-2" label="Address 3">
                  <b-form-input type="text" name="address3" v-model="create_delivery_label.shipment.ship_to.address_3"></b-form-input>
                </b-form-group>
                
                <b-form-group class="mt-2" label="Country">
                  <b-form-select class="form-control" v-model="selected_country_id" name="country_id"  @input="getCounties(selected_country_id.id)" @change="create_delivery_label.shipment.ship_to.country = $event.iso_code">
                    <b-form-select-option v-for="country in countries" :value="country">
                      {{country.name}}
                    </b-form-select-option>
                  </b-form-select>
                </b-form-group>
                <b-form-group class="mt-2" label="County/State">
                  <b-form-select class="form-control" name="county_id"  v-model="create_delivery_label.shipment.ship_to.county" :disabled="(counties.length == 0)">
                    <b-form-select-option v-for="county in counties" :value="county.name" >
                      {{county.name}}
                    </b-form-select-option>
                  </b-form-select>
                </b-form-group>
                <b-form-group class="mt-2" label="City">
                  <b-form-input type="text" name="city" v-model="create_delivery_label.shipment.ship_to.city"></b-form-input>
                </b-form-group>
                <b-form-group class="mt-2" label="Postcode/Zip">
                  <b-form-input type="text" name="postcode" v-model="create_delivery_label.shipment.ship_to.postcode"></b-form-input>
                </b-form-group>
                <h6>Contact Information</h6>
                <b-form-group class="mt-2" label="Phone">
                  <b-form-input type="telephone" name="phone" v-model="create_delivery_label.shipment.ship_to.phone"></b-form-input>
                </b-form-group>
                <b-form-group class="mt-2" label="Email">
                  <b-form-input type="email" name="email" v-model="create_delivery_label.shipment.ship_to.email"></b-form-input>
                </b-form-group>
                
              </b-card>
            </b-tab>
            	<b-tab :title-link-class="{'disabled' : create_label_step < 3}">
													<template #title>
														<span v-if="create_label_step == 3" class="bg-white text-primary border-primary rounded-circle px-2 mr-2">4</span>
														<span v-else-if="create_label_step > 3" class="bg-white text-primary border-primary rounded-circle px-2 mr-2"> <i class="bx bx-check text-success"></i></span> Parcels
													</template>
              <b-card class="shadow-none border-radius-1em border" v-if="create_label_step == 3">
              	<h5>Parcels</h5>
              	<p>{{create_delivery_label.shipment.parcels.length}} Parcel(s) in Your Shipment</p>
              	<a class="btn btn-light btn-rounded btn-block" @click="create_delivery_label.shipment.parcels.push(JSON.parse(JSON.stringify(parcel)))">Add Parcels <i class="mdi mdi-plus"></i></a>
              	 <div class="accordion" role="tablist">
              	<b-card class="shadow-none border-radius-1em bg-light bg-soft mt-3" body-class="position-relative" v-for="(parcel, pindex) in create_delivery_label.shipment.parcels">
              	<b-card-header v-b-toggle="'accordion-'+pindex" header-tag="header" class="p-1 bg-light bg-soft d-flex" role="tab">
              		<h6>Parcel {{pindex + 1}} Weight & Dimensions</h6>
              		<i class="mdi mdi-plus ml-auto mr-1 font-size-18 hide-on-collapse"></i>
              		<i class="mdi mdi-minus ml-auto mr-1 font-size-18 show-on-collapse"></i>
              	</b-card-header>
              	<b-collapse :id="'accordion-'+pindex" visible accordion="my-accordion" role="tabpanel">
        					<b-card-body>
              		<div class="d-flex position-absolute right-0 mr-4 mb-3">
              			<a v-b-tooltip.hover title="Duplicate" class="btn btn-light btn-rounded" @click="duplicateParcel(parcel)">
              				<i class="bx bx-copy-alt text-info"></i>
              			</a>
              			<a v-b-tooltip.hover title="Delete" class="btn btn-light btn-rounded" @click="removeParcel(pindex)">
              				<i class="bx bx-trash-alt text-danger"></i>
              			</a>
              		</div>
              		<b-row class="mt-4">
              			<b-col>
              		<b-form-group class="mb-2" label="Parcel dim width">
              		<b-form-input type="number" step="0.1" v-model="create_delivery_label.shipment.parcels[pindex].dim_width"></b-form-input>
              	</b-form-group>
              </b-col>

              			<b-col>
				        	<b-form-group class="mb-2" label="Parcel dim height">
				        	<b-form-input type="number" step="0.1" v-model="create_delivery_label.shipment.parcels[pindex].dim_height"></b-form-input>
				        </b-form-group>
				      </b-col>
				      		</b-row>
				      		<b-row>
								<b-col>
				        	<b-form-group class="mb-2" label="Parcel dim length">
				        	<b-form-input type="number" step="0.1" v-model="create_delivery_label.shipment.parcels[pindex].dim_length"></b-form-input>
				        </b-form-group>
				      </b-col>
							<b-col>
				        	<b-form-group class="mb-2" label="Parcel dim unit">
				        		<b-form-select class="form-control" :options="dim_units" step="0.1" v-model="create_delivery_label.shipment.parcels[pindex].dim_unit"></b-form-select>
				        </b-form-group>
				      </b-col>
				      </b-row>
				      <b-row>
								<b-col>
				        	<b-form-group class="mb-2" label="Parcel weight">
				        	<b-form-input type="number" step="0.1" v-model="create_delivery_label.shipment.parcels[pindex].weight"></b-form-input>
				        </b-form-group>
				      </b-col>
              			<b-col>
				       		<b-form-group class="mb-2" label="Parcel weight unit">
				       		<b-form-select class="form-control" :options="weight_units" step="0.1" v-model="create_delivery_label.shipment.parcels[pindex].weight_unit"></b-form-select>
				       	</b-form-group>
				       </b-col>

              			<b-col>
				        	<b-form-group class="mb-2" label="Parcel packaging weight">
				        	<b-form-input type="number" step="0.1" v-model="create_delivery_label.shipment.parcels[pindex].packaging_weight"></b-form-input>
				        </b-form-group>
              		</b-col>
              	</b-row>
              		<h6>Items In Parcel {{pindex + 1}}</h6>
              		<b-list-group class="my-3">
              				<b-list-group-item v-for="(product, iindex) in parcel.items">
              					<b-row>
              						<b-col>
              							#{{product.sku}}
              						</b-col>
              						<b-col>
              							{{product.description}}
              						</b-col>
              						<b-col>
              							{{product.quantity}}
              						</b-col>
              						<b-col>
              							{{product.value}}
              						</b-col>
              						<b-col>
              							<a class="btn btn-primary btn-rounded" @click="deleteProductFromParcel(pindex, iindex, product.sku, product.description)">
              								<i class="mdi mdi-minus"></i>
              							</a>
              						</b-col>
              					</b-row>
              				</b-list-group-item>
              				<b-list-group-item v-if="parcel.items.length == 0">
              					<b-card class="border-radius-1em border shadow-none" body-class="d-flex flex-column justify-content-center align-items-center">
              						<i class="bx bx-cube-alt text-muted font-size-42"></i>
              						<h4>No items currently in this parcel</h4>
              					</b-card>
              				</b-list-group-item>
              		</b-list-group>
              		<a class="btn btn-primary btn-rounded btn-block" @click="showOrderItems = pindex">
              			Add Order Item <i class="mdi mdi-plus"></i>
              		
              		</a>
              		<transition name="fade-top">
              			<b-list-group class="mt-3" v-if="showOrderItems == pindex">
              				<b-list-group-item v-for="product in parcel_products">
              					<b-row v-if="product.quantity > 0">
              						<b-col>
              							#{{product.product.reference}}
              						</b-col>
              						<b-col>
              							{{product.product.name}}
              						</b-col>
              						<b-col>
              							{{product.product.sales_price}}
              						</b-col>
              						<b-col>
              							<a class="btn btn-primary btn-rounded" @click="addProductToParcel(pindex, product), product.quantity--, showOrderItems = -1">
              								<i class="mdi mdi-plus"></i>
              							</a>
              						</b-col>
              					</b-row>
              				</b-list-group-item>
              			</b-list-group>
              		</transition>
              	
              </b-card-body>
            </b-collapse>
            </b-card>
           
          </div>
              </b-card>
            </b-tab>
           <b-tab :title-link-class="{'disabled' : create_label_step < 4}">
													<template #title>
														<span v-if="create_label_step == 4" class="bg-white text-primary border-primary rounded-circle px-2 mr-2">5</span>
														<span v-else-if="create_label_step > 4" class="bg-white text-primary border-primary rounded-circle px-2 mr-2"> <i class="bx bx-check text-success"></i></span> Summary
													</template>
               <b-card class="shadow-none border-none" v-if="create_label_step == 4">
            	<h4>Shipment Summary</h4>
            	<p>You are arranging shipment of {{create_delivery_label.shipment.parcels.length}} parcel(s):</p>
            	<b-card class="shadow-none border-radius-1em border">
            		<h4><i class="bx bx-store text-primary mr-2"></i> From:</h4>
            		{{create_delivery_label.shipment.ship_from.address_1}}
            		{{create_delivery_label.shipment.ship_from.address_2}}
            		{{create_delivery_label.shipment.ship_from.address_3}}
            		{{create_delivery_label.shipment.ship_from.county}}
            		{{create_delivery_label.shipment.ship_from.postcode}}
            		{{create_delivery_label.shipment.ship_from.country}}

            	</b-card>
            	<b-card class="shadow-none border-radius-1em border">
            		<h4><i class="bx bx-home-alt text-primary mr-2"></i> To:</h4>
            		{{create_delivery_label.shipment.ship_to.address_1}}
            		{{create_delivery_label.shipment.ship_to.address_2}}
            		{{create_delivery_label.shipment.ship_to.address_3}}
            		{{create_delivery_label.shipment.ship_to.county}}
            		{{create_delivery_label.shipment.ship_to.postcode}}
            		{{create_delivery_label.shipment.ship_to.country}}
            	</b-card>
            	<b-card class="shadow-none border-radius-1em border">
            		<h4><i class="bx bxs-truck text-primary mr-2"></i>Courier:</h4>
            		{{selected_carrier.courier}}
            	</b-card>
            	<b-card class="shadow-none border-radius-1em border">
            		<h4><i class="bx bx-calendar-alt text-primary mr-2"></i> Date:</h4>
            		{{create_delivery_label.shipment.collection_date}}
            	</b-card>
            
            		<h4><i class="bx bx-cube-alt text-primary mr-2"></i> Parcels</h4>
            		<b-list-group>
            			<b-list-group-item v-for="(parcel, pindex) in create_delivery_label.shipment.parcels">
            				<h5 class="mt-2"><i class="bx bx-cube-alt text-primary mr-2"></i> Parcel {{pindex + 1}}</h5>
            			 	Dimensions: W {{parcel.dim_width}}{{parcel.dim_unit}} x H {{parcel.dim_height}}{{parcel.dim_unit}} x L	{{parcel.dim_length}}{{parcel.dim_unit}}<br>
				        		Weight: {{parcel.weight}}	{{parcel.weight_unit}}

				        		<h5 class="mt-3">{{parcel.items.length}} product(s) in this parcel: </h5>
				        		<b-list-group flush class="mt-3">
				        			<b-list-group-item v-for="item in parcel.items" class="px-0">
				        				<b-row>
              						<b-col md="3" class="d-flex flex-column justify-content-center">
              							#{{item.sku}}
              						</b-col>
              						<b-col class="d-flex flex-column justify-content-center" md="6">
              							<p class="text-truncate my-0">{{item.description}}</p>
              						</b-col>
              						<b-col class="d-flex flex-column justify-content-center" md="1">
              							{{item.quantity}}
              						</b-col>
              						<b-col class="d-flex justify-content-center" md="2">
              							<span class="currency">{{order.currency == null ? '£' : order.currency.symbol}}</span>{{parseFloat(item.value).toFixed(2)}}
              						</b-col>
              					</b-row>
				        			</b-list-group-item>
				        		</b-list-group>
				        	</b-list-group-item>
            		</b-list-group>
            	
            </b-card>
          </b-tab>
          <b-tab :title-link-class="{'disabled' : create_label_step < 5}">
          		<template #title>
													
														<span v-if="create_label_step == 5" class="bg-white text-primary border-primary rounded-circle px-2 mr-2"> <i class="bx bx-check text-success"></i></span> 
														Print
													</template>
          	<b-card class="shadow-none border-none" v-if="create_label_step == 5">
          		 <b-row>
								<b-col md="12">
          		 <b-card class="shadow-none border-radius-1em border">
              	<h4 class="text-center">Shipping Successfully Booked!</h4>
								<svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
								  <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
								  <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
								</svg>
              	<h4 class="text-center">Shipping Reference: #{{order.shippings[order.shippings.length - 1].uid}}</h4>
              </b-card>
             	</b-col>
              	<b-col md="6">
              <b-card class="shadow-none border-radius-1em border">
            		<h4><i class="bx bxs-truck text-primary mr-2"></i>Courier:</h4>
            		{{selected_carrier.courier}}
            	</b-card>
            		</b-col>

              	<b-col md="6">
	            	<b-card class="shadow-none border-radius-1em border">
	            		<h4><i class="bx bx-calendar-alt text-primary mr-2"></i> Date:</h4>
	            		{{create_delivery_label.shipment.collection_date}}
	            	</b-card>
	            </b-col>

              	<b-col md="6">
	            	<b-card class="shadow-none border-radius-1em border">
            		<h4><i class="bx bx-store text-primary mr-2"></i> Shipped From:</h4>
            		{{create_delivery_label.shipment.ship_from.address_1}}
            		{{create_delivery_label.shipment.ship_from.address_2}}
            		{{create_delivery_label.shipment.ship_from.address_3}}
            		{{create_delivery_label.shipment.ship_from.county}}
            		{{create_delivery_label.shipment.ship_from.postcode}}
            		{{create_delivery_label.shipment.ship_from.country}}

            	</b-card>
            </b-col>

              	<b-col md="6">
            	<b-card class="shadow-none border-radius-1em border">
            		<h4><i class="bx bx-home-alt text-primary mr-2"></i> Shipped To:</h4>
            		{{create_delivery_label.shipment.ship_to.address_1}}
            		{{create_delivery_label.shipment.ship_to.address_2}}
            		{{create_delivery_label.shipment.ship_to.address_3}}
            		{{create_delivery_label.shipment.ship_to.county}}
            		{{create_delivery_label.shipment.ship_to.postcode}}
            		{{create_delivery_label.shipment.ship_to.country}}
            	</b-card>
            </b-col>
            <b-col md="12">

            		<b-card class="shadow-none border-radius-1em border" body-class="d-flex">
            			<div>
            			<h4><i class="bx bx-note text-primary mr-2"></i> Label</h4>
            				<p v-if="bookingInfo" class="bg-info bg-soft p-2 text-info mb-2 border-3">
				        			Scan this code once your parcel is packed to mark the parcel packed and ready to be collected by your courier.
				        		</p>
            		</div>
            		<div class="w-25 ml-auto">
            			<qrcode-vue v-if="order.shippings[order.shippings.length - 1].uid" :value="JSON.stringify({type: 'delivery-packed', shipping_reference: create_label_response.create_delivery_label.shipment.reference, company_id: $attrs.company.id, parcel_index: pindex})" :size="150" level="L" foreground="#000000" background="#ffffff" margin="0" class="ml-auto px-1"/>
				        			<small @click="packingInfo = !packingInfo">Packed Code <i class="bx bx-info-circle text-info"></i></small>
            			<a href="javascript" @click="openFile(create_label_response.uri)" >
            				 Download & Print Label
            			</a>

            			</div>
            			<div class="w-25">
	            			<a class="btn btn-primary btn-rounded btn-block mb-2"  @click="markPackedTracked(create_label_response.create_delivery_label.shipment.reference)">Book Collection</a>
					        	<a href="javascript:;" class="btn btn-info btn-rounded btn-block mb-2" @click="openFile(selected_shipping.delivery_label_url)" >
	            			   Mark Packed
	            			</a>
	            			<a href="javascript:;" class="btn btn-primary btn-rounded btn-block mb-2" @click="openFile(selected_shipping.delivery_label_url)" >
	            				 Download Label
	            			</a>
	            			<a class="btn btn-success btn-rounded btn-block" @click="renewLabelRequest(selectedShippingStatus.shipments[0].label_key)">Refetch Label</a>
            			</div>
            		</b-card>
            </b-col>
              	<b-col md="12">
            		<b-card class="shadow-none border-radius-1em border">

            			<h4><i class="bx bx-cube-alt text-primary mr-2"></i> Parcels</h4>

            			<p v-if="bookingInfo" class="bg-info bg-soft p-2 text-info mb-2 border-3">
				        			Add this code to your parcel to enable booking in of stock at another store.
				        		</p>
            		<b-list-group>
            			<b-list-group-item v-for="(parcel, pindex) in create_delivery_label.shipment.parcels" class="d-flex flex-column">
            				<div class="d-flex">
            				<h5 class="mt-2 mr-4"><i class="bx bx-cube-alt text-primary mr-2"></i> Parcel {{pindex + 1}}</h5>
            			 	<p class="mt-2">Dimensions: W {{parcel.dim_width}}{{parcel.dim_unit}} x H {{parcel.dim_height}}{{parcel.dim_unit}} x L	{{parcel.dim_length}}{{parcel.dim_unit}}<br>
				        		Weight: {{parcel.weight}}	{{parcel.weight_unit}}

				        		
				        	</p>
				        		<div class="d-flex flex-column ml-auto">
				        		<qrcode-vue v-if="order.shippings[order.shippings.length - 1].uid" :value="JSON.stringify({type: 'delivery-booking-in', shipping_reference_2: order.uid, shipping_reference: order.shippings[order.shippings.length - 1].uid, company_id: $attrs.company.id, parcel_index: pindex})" :size="150" level="L" foreground="#000000" background="#ffffff" margin="0" class="ml-auto px-1"/>
				        			<small @click="bookingInfo = !bookingInfo">Booking in Code <i class="bx bx-info-circle text-info"></i></small>
														        		
				        		</div>
				        	</div>
				        	<div class="d-flex flex-column">
				        		<h5 class="mt-3">{{parcel.items.length}} product(s) in this parcel: </h5>
				        		<b-list-group flush class="mt-3">
				        			<b-list-group-item v-for="item in parcel.items" class="px-0">
				        				<b-row>
              						<b-col md="3" class="d-flex flex-column justify-content-center">
              							#{{item.sku}}
              						</b-col>
              						<b-col class="d-flex flex-column justify-content-center" md="6">
              							<p class="text-truncate my-0">{{item.description}}</p>
              						</b-col>
              						<b-col class="d-flex flex-column justify-content-center" md="1">
              							{{item.quantity}}
              						</b-col>
              						<b-col class="d-flex justify-content-center" md="2">
              							<span class="currency">{{order.currency == null ? '£' : order.currency.symbol}}</span>{{parseFloat(item.value).toFixed(2)}}
              						</b-col>
              					</b-row>
				        			</b-list-group-item>
				        		</b-list-group>
				        	</div>
				        	</b-list-group-item>
            		</b-list-group>
            		</b-card>
            		</b-col>
              	</b-row>
              </b-card>
            </b-tab>
        </b-tabs>
              
               <b-button variant="primary" class="btn-rounded btn btn-primary float-left" @click="create_label_step--" v-if="create_label_step > 0">
													Previous Step
												</b-button>
              <b-button variant="primary" class="btn-rounded btn btn-primary float-right" @click="create_label_step++" v-if="create_label_step < 4" :disabled="!validateCourierStep">
													Next Step
												</b-button>
												<a v-else-if="create_label_step == 4" class="btn-rounded float-right btn btn-success" @click="presaveDelivery()">
													Book Shipping
												</a>
									</div>
							</transition>
			</b-form>
			<b-card v-if="action == 'print-address-label'" class="shadow-none border-none mb-0" no-body>
				<AddressLabel :address="addressLabel" :addresses="[]"></AddressLabel>
			</b-card>
			<b-card v-if="action == 'update-status'">
				<h4>Update Status</h4>
				<b-form-group label="Current Status:">
					<SelectCompanyOrderStatus :id="order.status" :selectdata="order_statuses" :isDisabled="true" :allowAdd="false"></SelectCompanyOrderStatus>
				</b-form-group>
				<b-form-group label="Update To:">
					<SelectCompanyOrderStatus :selectdata="order_statuses" @order-status-selected="new_order_status = $event" :allowAdd="false" ></SelectCompanyOrderStatus>
				</b-form-group>
				<a class="btn btn-primary btn-rounded btn-block mt-3" @click="updateOrderStatus()">
					Update Order Status
				</a>
			</b-card>
			<b-card v-if="action == 'delivery-tracking'">
				<b-overlay :show="loadingTracking">
					 
              <b-row v-if="selected_shipping !== ''">
              		<b-col md="12">
              			 <b-card class="shadow-none border-radius-1em border">
              						<h4 class="text-left">Shipping Reference: #{{selected_shipping.uid}}</h4>
              			</b-card>
              	</b-col>
              	<b-col md="6">
              <b-card class="shadow-none border-radius-1em border">
            		<h4><i class="bx bxs-truck text-primary mr-2"></i>Courier:</h4>
            		{{selectedShippingStatus.shipments[0].courier}}
            	</b-card>
            		</b-col>

              	<b-col md="6">
	            	<b-card class="shadow-none border-radius-1em border">
	            		<h4><i class="bx bx-calendar-alt text-primary mr-2"></i> Collection Date:</h4>
	            		{{selected_shipping.collection_date}}
	            	</b-card>
	            </b-col>
	            <b-col md="12">

            		<b-card class="shadow-none border-radius-1em border" body-class="d-flex">
            			<div class="w-50">
            			<h4><i class="bx bx-note text-primary mr-2"></i> Label</h4>
            				<p v-if="packingInfo" class="bg-info bg-soft p-2 text-info mb-2 border-3">
				        			Scan this code once your parcel is packed to mark the parcel packed and ready to be collected by your courier.
				        		</p>
            		</div>
            		<div class="w-25 ml-auto">
            			<qrcode-vue :value="JSON.stringify({type: 'delivery-packed', shipping_reference: selected_shipping.reference, company_id: $attrs.company.id, parcel_index: pindex})" :size="120" level="L" foreground="#000000" background="#ffffff" margin="0" class="ml-auto px-1"/>
				        			<small @click="packingInfo = !packingInfo">Packed Code <i class="bx bx-info-circle text-info"></i></small>
            			</div>
            			<div class="w-25">
	            			<a class="btn btn-primary btn-rounded btn-block mb-2"  @click="markPackedTracked(selectedShippingStatus.shipments[0].reference)">Book Collection</a>
					        	<a href="javascript:;" class="btn btn-info btn-rounded btn-block mb-2" @click="openFile(selected_shipping.delivery_label_url)" >
	            			   Mark Packed
	            			</a>
	            			<a href="javascript:;" class="btn btn-primary btn-rounded btn-block mb-2" @click="openFile(selected_shipping.delivery_label_url)" >
	            				 Download Label
	            			</a>
	            			<a class="btn btn-success btn-rounded btn-block" @click="renewLabelRequest(selectedShippingStatus.shipments[0].label_key)">Refetch Label</a>
            			</div>
            		</b-card>
            </b-col>
              	<b-col md="6">
	            	<b-card class="shadow-none border-radius-1em border">
            		<h4><i class="bx bx-store text-primary mr-2"></i> Shipped From:</h4>
            		{{selected_shipping.shipping_details.ship_from_address_1}}
            		{{selected_shipping.shipping_details.ship_from_address_2}}
            		{{selected_shipping.shipping_details.ship_from_address_3}}
            		{{selected_shipping.shipping_details.ship_from_county}}
            		{{selected_shipping.shipping_details.ship_from_postcode}}
            		{{selected_shipping.shipping_details.ship_from_country}}

            	</b-card>
            </b-col>

              	<b-col md="6">
            	<b-card class="shadow-none border-radius-1em border">
            		<h4><i class="bx bx-home-alt text-primary mr-2"></i> Shipped To:</h4>
            		{{selected_shipping.shipping_details.ship_to_address_1}}
            		{{selected_shipping.shipping_details.ship_to_address_2}}
            		{{selected_shipping.shipping_details.ship_to_address_3}}
            		{{selected_shipping.shipping_details.ship_to_county}}
            		{{selected_shipping.shipping_details.ship_to_postcode}}
            		{{selected_shipping.shipping_details.ship_to_country}}
            	</b-card>
            </b-col>
            <b-col md="12">
            	
            </b-col>
              	<b-col md="12">
            		<b-card class="shadow-none border-radius-1em border">

            			<h4><i class="bx bx-cube-alt text-primary mr-2"></i> Parcels</h4>

            			<p v-if="bookingInfo" class="bg-info bg-soft p-2 text-info mb-2 border-3">
				        			Add this code to your parcel to enable booking in of stock at another store.
				        		</p>
            		<b-list-group>
            			<b-list-group-item v-for="(parcel, pindex) in selected_shipping.parcels" class="d-flex flex-column">
            				<div class="d-flex">
            				<h5 class="mt-2 mr-4"><i class="bx bx-cube-alt text-primary mr-2"></i> Parcel {{pindex + 1}}</h5>
            			 	<!--<p class="mt-2">Dimensions: W {{parcel.dim_width}}{{parcel.dim_unit}} x H {{parcel.dim_height}}{{parcel.dim_unit}} x L	{{parcel.dim_length}}{{parcel.dim_unit}}<br>
				        		Weight: {{parcel.weight}}	{{parcel.weight_unit}}-->

				        		
				        	</p>
				        		<div class="d-flex flex-column ml-auto">
				        		<qrcode-vue :value="JSON.stringify({type: 'delivery-booking-in', shipping_reference_2: order.uid, shipping_reference: selected_shipping.uid, company_id: $attrs.company.id, parcel_index: pindex})" :size="150" level="L" foreground="#000000" background="#ffffff" margin="0" class="ml-auto px-1"/>
				        			<small @click="bookingInfo = !bookingInfo">Booking in Code <i class="bx bx-info-circle text-info"></i></small>
														        		
				        		</div>
				        	</div>
				        	<div class="d-flex flex-column">
				        		<h5 class="mt-3">{{parcel.items.length}} product(s) in this parcel: </h5>
				        		<b-list-group flush class="mt-3">
				        			<b-list-group-item v-for="item in parcel.items" class="px-0">
				        				<b-row>
              						<b-col md="3" class="d-flex flex-column justify-content-center">
              							#{{item.product.reference}}
              						</b-col>
              						<b-col class="d-flex flex-column justify-content-center" md="6">
              							<p class="text-truncate my-0">{{item.product.name}}</p>
              						</b-col>
              						<b-col class="d-flex flex-column justify-content-center" md="1">
              							{{item.quantity}}
              						</b-col>
              						<b-col class="d-flex justify-content-center" md="2">
              							<span class="currency">{{order.currency == null ? '£' : order.currency.symbol}}</span>{{parseFloat(item.value).toFixed(2)}}
              						</b-col>
              					</b-row>
				        			</b-list-group-item>
				        		</b-list-group>
				        	</div>
				        	</b-list-group-item>
            		</b-list-group>
            		</b-card>
            		</b-col>
            			<b-col md="12">
										<b-card class="border-radius-1em border shadow-none">
												<h5 class="font-size-16">Status:</h5>
												<hr>
												<span class="">{{getShippingStatusText(selectedShippingStatus.shipments[0].tracking_status_code)}}</span>
										</b-card>
									</b-col>
              	</b-row>
			
					
					
				</b-overlay>
			</b-card>
			</b-overlay>
		</b-modal>
		<b-modal id="invoice-modal" size="xl" centered content-class="border-radius-1em" header-class="border-none" hide-footer>
			<OrderInvoice v-if="printPreview == false" :company="$attrs.company" :order="order" :user="$attrs.user" @process-pdf="generateReport()" @print-pdf="printInvoice()" ></OrderInvoice>
			<b-form id="invoiceUpload" v-if="order.order_invoice !== null">
				<input type="hidden" v-model="order.order_invoice.filename" name="filename">
				<input type="hidden" v-model="order.order_invoice.filepath" name="filepath">
				
    	</b-form>
		</b-modal>
		<vue-html2pdf
		 		id="invoiceArea"
        :show-layout="showInvoiceLayout"
        :float-layout="true"
        :enable-download="false"
        :preview-modal="true"
       
        :filename="order.uid"
        :pdf-quality="2"
        :manual-pagination="false"
        pdf-format="a4"
        pdf-orientation="portrait"
        :margin="2"
        ref="html2Pdf"
       
        @hasDownloaded="saveGeneratedInvoice($event)"
    >
        <section slot="pdf-content">
           <OrderInvoice :company="$attrs.company" :order="order" :printable="true" :user="$attrs.user"></OrderInvoice>
        </section>
    </vue-html2pdf>

</Layout>
</template>

<style scoped>

	.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #7ac142;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}
.checkmark {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  margin: 2% auto;
  background: #7ac142;
  box-shadow: inset 0px 0px 0px #7ac142;
  animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
}
.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}
@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%, 100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #7ac142;
  }
}
</style>