<script type="text/javascript">
	import VueEasyLightbox from "vue-easy-lightbox";
	import { EventBus } from "../../../../../website-app";
	import DefaultContentRelatedProductsSwiper from "./DefaultContent-RelatedProductsSwiper"
	import DefaultContentRecentlyViewed from "./DefaultContent-RecentlyViewed"
	import Login from "../../../../../website-views/account/onpageLogin";
	import SocialShareBlock from "../../../page-builder/widgets/web/SocialShareBlock";
	import ReviewForm from "../../../../../components/widgets/forms/ReviewForm";
	import ReviewCarousel from "../../../../../components/widgets/forms/ReviewCarousel";
	import ProductImageGallery from "../../../../../components/widgets/page-builder/widgets/template/product-image-gallery";
	import ProductTitleBlock from "../../../../../components/widgets/page-builder/widgets/template/product-title-block";
	import ProductPriceBlock from "../../../../../components/widgets/page-builder/widgets/template/product-price-block";
	import ProductCartButton from "../../../../../components/widgets/page-builder/widgets/template/product-cart-button";
	import ProductFeatures from "../../../../../components/widgets/page-builder/widgets/template/product-features";
	import ProductDesc from "../../../../../components/widgets/page-builder/widgets/template/product-desc";
	export default{
		data(){
			return {
				recentlyviewedobject: {
					id: null,
					uid: null,
					name: null,
					cover_image: null,
					short_description: null,
					company: {
						nickname: null,
					},
					url_rewrite: null,
					trade_price: null,
					tax_rule: null,
				},
				recentlyviewed: [],
				quantity: 1,
				previewImgBtns: false,
				showLightbox: false,
				imgView: null,
				imgViewIndex: 0,
				hoverIndex: -1,
				hoverLink: -1,
				loadingVariants: false,
				lightboxImages: [],
				selectedvariantstotal: 0.00,
				selectedvariants: {},
				selectedcombination: null,
				product: [],
				routearray: [],
				storageurl: 'https://'+this.company.nickname+'.s3.eu-west-2.amazonaws.com',
				form: {
					action: '',
					name: '',
					lastname: '',
					email: '',
					customer_uid: '',
					share_details: '',
					review: '',
					message: '',
					review_score: 0,
				},
				imgswiperOptions: {
		          slidesPerView: 5,
		          spaceBetween: 10,
		          pagination: {
		            el: '.swiper-pagination',
		            clickable: true
		          },
		          navigation: {
		            nextEl: '.swiper-button-next',
		            prevEl: '.swiper-button-prev'
		          }

		        },
		        swiperOptions: {
		          slidesPerView: 4,
		          spaceBetween: 30,
		          autoplay: {
				      delay: 2500,
				      disableOnInteraction: false
				    },
		          loop: false,
		          loopFillGroupWithBlank: false,
		          pagination: {
		            el: '.swiper-pagination',
		            clickable: true,
		          },
		          // Some Swiper option/callback...
		        },
		        reviewOptions: {
		          slidesPerView: 3,
		          spaceBetween: 30,
		          autoplay: {
				      delay: 2500,
				      disableOnInteraction: false
				    },
		          loop: false,
		          loopFillGroupWithBlank: false,
		          pagination: {
		            el: '.swiper-pagination',
		            clickable: true,
		          },
		          // Some Swiper option/callback...
		        },
			}
		},
		props: {
			company: {
				type: Object
			},
			user: {
				type: Object
			},
			resource: {
				type: String,
				default: () => 'product'
			}
		},
		components: {
			VueEasyLightbox,
			DefaultContentRelatedProductsSwiper,
			DefaultContentRecentlyViewed,
			SocialShareBlock,
			Login,
			ReviewForm,
			ReviewCarousel,
			ProductImageGallery,
			ProductTitleBlock,
			ProductPriceBlock,
			ProductCartButton,
			ProductFeatures,
			ProductDesc
		},
		created(){
			this.getResource(this.resource);
		},
		methods: {
			isKeyValueActive(key, value){
				if(this.selectedvariants[key] == value){
					return true;
				}else{
					return false;
				}
			},
			addToCart(product, quantity, variant, combination){
		    	EventBus.$emit('add-to-cart', product, quantity, variant, combination);
		    },
			getResource(resource){
				this.routearray = this.$route.path.substring(1).split('/');
				axios.get('/website-api/widget/'+resource+'/'+this.routearray[this.routearray.length-1]).then(response => {
					this.product = response.data;
					
				}).catch(error => {
					this.product = [];
					console.log(error);
				});
			},
			imageSelected(img){
		    	if(this.imgView == null){
		    		this.product.images.unshift(this.product.cover_image);
		    	}
		    	this.imgView = img;
		    },
		    calculateVariantsTotal(){
        		var total = 0.00;
        		for(let key in this.selectedvariants){
        			console.log(key);
        			total += parseFloat(this.selectedvariants[key].price_difference);
        		}
        		this.selectedvariantstotal = this.addTax(total);
        	},
        	/*addTax(price){
        		if(this.product.tax_rule !== null){
        			var percentage = (parseFloat(this.product.tax_rule.percentage) / 100) + 1;
        			return price * percentage;
         		}else{
         			return price;
         		}
        	},*/
        	addTax(product, price){
        		var tax_rule = this.product.tax_rule;
        		console.log(tax_rule);
		    		var country_id = this.company.country_id;
		    	if(tax_rule !== null && tax_rule.country_rules.length > 0){
		    		var selected_rule = null;
		    		var selected_ruleIndex = -1;
			    	for(var ti = 0; ti < tax_rule.country_rules.length; ti++){
			    		selected_ruleIndex = tax_rule.country_rules[ti].countries.findIndex(item => item == country_id);
			    		if(selected_ruleIndex !== -1){
			    			selected_rule = tax_rule.country_rules[ti];
			    			ti = tax_rule.country_rules.length;
			    		}
			    	}
			    	console.log(selected_rule);
			    	if(tax_rule.type == 1){
		        		if(selected_rule !== null){
		        			var percentage = (parseFloat(selected_rule.value) / 100) + 1;
		        			return price * percentage;
		         		}else{
		         			return price;
		         		}
	         		}else{
	         			if(selected_rule !== null){
		        			return price + selected_rule.value;
		         		}else{
		         			return price;
		         		}
	         		}
         		}else if(tax_rule !== null && tax_rule.country_rules.length > 0){
         			var percentage = (parseFloat(tax_rule.percentage) / 100) + 1;
		        	return price * percentage;
         		}else {
		        	return price;
         		}
        	},
		    setUpLightbox(){
		    	//this.product.images = JSON.parse(JSON.stringify(this.product.images).replaceAll('caption', 'title'));
		    		var image = {};
		    		image["src"] = ''+this.storageurl+''+this.product.cover_image.src.replaceAll('//', '/')+'';
		    		image["title"] = this.product.cover_image.caption;
		    		this.lightboxImages.push(image);
		    	for(var i = 0; i < this.product.images.length; i++){
		    		var image = {};
		    		image["src"] = ''+this.storageurl+''+this.product.images[i].src.replaceAll('//', '/')+'';
		    		image["title"] = this.product.images[i].caption;
		    		console.log(image);
		    		this.lightboxImages.push(image);
		    		if(this.imgView == this.product.images[i]){
		    			this.imgViewIndex = (this.lightboxImages.length - 1);
		    		}
		    	}
		    	

		    },
		},
		  watch: {
        $route() {
        	this.$nextTick(() => {
        		 //this.loadingPage = true;
        		 this.getResource(this.resource);

        		});
        	}
        },
        computed: {
        	recentlyViewed(){
        		if(this.product !== ''){
							this.recentlyviewedobject.id = this.product.id;
							this.recentlyviewedobject.uid = this.product.uid;
							this.recentlyviewedobject.name = this.product.name;
							this.recentlyviewedobject.cover_image = this.product.cover_image;
							this.recentlyviewedobject.short_description = this.product.short_description;
							this.recentlyviewedobject.company["nickname"] = this.product.company.nickname;
							this.recentlyviewedobject.url_rewrite = this.product.url_rewrite;
							this.recentlyviewedobject.trade_price = this.product.trade_price;
							this.recentlyviewedobject.tax_rule = this.product.tax_rule;
						}
						var viewed = window.sessionStorage.getItem("website_rv");
						
						if(!viewed){
							console.log('no viewed');
							//var vieweditems = JSON.parse(viewed);
							if(this.product !== ''){
								this.recentlyviewed.push(this.recentlyviewedobject);
							}
							window.sessionStorage.setItem("website_rv", JSON.stringify(this.recentlyviewed));
						}else{
							var vieweditems = JSON.parse(viewed);
								if(this.product !== ''){
										if(vieweditems.findIndex(item => item.id == this.product.id) == -1){
											vieweditems.push(this.recentlyviewedobject);
										}
								window.sessionStorage.setItem("website_rv", JSON.stringify(vieweditems));
								return vieweditems.reverse();
							}
							
						}
        	},
        	variantsTotal(){
        		var total = 0.00;
        		for(let key in this.selectedvariants){
        			console.log(key);
        			total =+ parseFloat(this.selectedvariants[key].price_difference);
        		}
        		return total;
        	},
        	priceIncVat(){
        		if(this.product.tax_rule !== null){
        			return this.addTax(this.product, this.product.sales_price);
         		}else{
         			return this.product.sales_price;
         		}
        	},
        	
        	combinationPrice(){
        		if(this.selectedcombination !== null){
        		return parseFloat(this.selectedcombination.price_difference);
        		}else{
        			return parseFloat(0.00);
        		}
        	}
        }
	}

</script>
<template>
	<b-container fluid class="py-4 my-4 default-content">
		<vue-easy-lightbox
                :visible="showLightbox"
                :imgs="lightboxImages"
                :index="imgViewIndex"
                @hide="showLightbox = !showLightbox"
              ></vue-easy-lightbox>
		<b-row class="mb-4">
			<b-col md="6" class="px-3 overflow-hidden">
				<!--<a class="position-relative d-block" @mouseenter="previewImgBtns = true" @mouseleave="previewImgBtns = false" @click="setUpLightbox(), showLightbox = !showLightbox">
				<img v-if="(product.cover_image !== null) && (imgView == null)" :src="storageurl + product.cover_image.src.replaceAll('//', '/')" class="img-fluid w-100 border mb-3" >
				<img v-else-if="imgView !== null" :src="storageurl + imgView.src.replaceAll('//', '/')" class="img-fluid w-100 border mb-3"> 
				<img v-else src="/images/product-placeholder.png" class="img-fluid w-100 border mb-3">
				<transition name="fade-right">
				   <div class="position-absolute top-0 right-0 mt-3 mr-3 rounded" v-if="previewImgBtns == true">
	                          	<a class="btn btn-primary rounded-circle bg-peach-soft" href="javascript:;">
	                          		<i class="bx bx-search"></i>
	                          	</a>
	                </div>
	            </transition>
				</a>
				<ImageSwiper :swiperOptions="imgswiperOptions" :items="product.images" @image-selected="imageSelected" :imgClass="'border'" ></ImageSwiper>-->
				<ProductImageGallery :company="company" :product="product"></ProductImageGallery>
			</b-col>
			<b-col md="6" class="px-3">
				<SocialShareBlock />
				<small>SKU: {{product.reference}}</small>
				<h1 class="product-title my-1">{{product.name}}</h1>
				<div class="d-flex">
					<b-form-rating id="rating-inline" variant="warning" v-b-modal.modal-1 @click="form.action = 'review'" inline no-border size="lg" class="bg-transparent" :readonly="true" v-model="product.reviews_avg_rating"></b-form-rating><span class="my-auto ml-2" v-if="product.reviews.length > 0"><small v-if="product.reviews_avg_rating !== null" >{{parseFloat(product.reviews_avg_rating).toFixed(2)}} / 5 from {{product.reviews.length}} reviews</small><small v-else >0 / 5 from {{product.reviews.length}} reviews</small></span>
					<a class="my-auto" href="javascript:;" v-b-modal.modal-1  @click="form.action = 'review'">
						<i class="bx bx-pencil-alt"></i>
						<small>Write Review</small>
					</a>
				
				</div>
				<p class="mt-3" v-html="product.short_description"></p>
				<ProductDesc :description="'product-short-desc'" :product="product" ref="shortDescription"></ProductDesc>
				<!--<ProductPriceBlock :product="product" :selectedvariants="selectedvariants" :selectedcombination="selectedcombination" :company="company"></ProductPriceBlock>-->
				<ProductCartButton :product="product" :selectedvariants="selectedvariants" :selectedcombination="selectedcombination" :company="company"  :exchange_rates="$attrs.exchange_rates"  :currencies="$attrs.currencies" :selected_currency="$attrs.selected_currency" :selected_currency_symbol="$attrs.selected_currency_symbol"></ProductCartButton>
				<!--<h4 class="font-size-28 mb-4" v-if="product.combinations.length == 0">£{{(parseFloat(priceIncVat) + parseFloat(selectedvariantstotal)).toFixed(2)}}</h4>
				<h4 v-else class="font-size-28 mb-4">£{{(parseFloat(priceIncVat) + parseFloat(combinationPrice)).toFixed(2)}}</h4>-->

				<!--<h5 class="text-uppercase font-size-12 text-underline spaced-title mt-2 pt-3">Product Features</h5>
				<b-row>
				     <div class="col-md-12" v-if="product.attributes.length == 0">
                    <b-card class="shadow-none text-center border mb-2 py-4">
                      <i class="bx bxs-cube font-size-24 text-muted"></i>
                      <h4>No Active Features</h4>
                  </b-card>
                  </div>
                 <div class="col-md-12 mb-2" v-if="product.attributes.length !== 0">
                <div class="row">
               <div class="col-md-12">
                <div class="form-control-group">
                <b-list-group class="list-group" flush>
                <li v-for="(active_attributes, index) in product.attributes" class="list-group-item p-0 d-flex py-3">
                <span class="my-auto pl-4 w-25">
                  <img v-if="active_attributes.attribute.image" :src="active_attributes.attribute.image" class="avatar-xs rounded-circle mr-2">
                  <img v-else src="/images/product-placeholder.png" class="avatar-xs rounded-circle mr-2">
                {{active_attributes.attribute.name}}
              </span>
              <span class="w-25">
                <img v-if="active_attributes.value.image" :src="active_attributes.value.image" class="avatar-xs rounded-circle mr-3">
                <img v-else src="/images/product-placeholder.png" class="avatar-xs rounded-circle ml-2 mr-2"> {{active_attributes.value.name}}
              </span>
              <span class="my-auto pl-4 w-50 d-flex">
              	<span class="ml-auto">{{active_attributes.measurement_value}}{{active_attributes.units.suffix}}
              	</span>
              </span>
              
			          </li>
			        </b-list-group>
			      </div>
			    </div>
			  </div>
			</div> 
			</b-row>
			<div class="border-top pt-3" v-if="product.combinations.length == 0" >
					<h5 class="text-uppercase font-size-12 text-underline spaced-title mb-4">Select Customisation Options</h5>
				<div class="w-100" v-for="(variants, key) in product.grouped_variants">
					<h5 class="text-uppercase font-size-12 spaced-title">{{variants[0].attribute.name}}</h5>
					<b-row >
						<b-col md="4" v-for="value in variants">
						<b-overlay :show="loadingVariants">
							<a href="javascript:;" @click="selectedvariants[key] = value, calculateVariantsTotal(), loadingVariants = true, loadingVariants = false" >
							<b-card class="border-radius-1em border shadow-none text-dark mb-3" body-class="d-flex" :class="{'border-primary' : selectedvariants[key] == value}">
								<span class="my-auto" v-if="value.value !== null"><img v-if="value.value.image !== null" :src="value.value.image" class="avatar-xs rounded-circle mr-2 border"> 
								</span>
								<span class="my-auto mr-2" v-if="value.value !== null">{{value.value.name}}</span> <span class="my-auto">{{value.measurement_value}}</span><span class="my-auto mr-2" v-if="value.units !== null">{{value.units.suffix}}</span>
								<span class="badge badge-light bg-light my-auto ml-auto font-size-14">
								<span class="ml-auto my-auto" v-if="value.price_difference > 0">+</span><span class="ml-auto my-auto" v-else>-</span><span>£</span>{{value.price_difference}}
								</span>
							</b-card>
							</a>
						</b-overlay>
						</b-col>
					</b-row>
				</div>
			</div>
				<div v-else >
					<h5 class="text-uppercase font-size-12 text-underline spaced-title mt-2 pt-3">Select Customisation Options</h5>
				<div class="w-100" v-for="(combination, index) in product.combinations">
					<a href="javascript:;" @click="selectedcombination = combination" >
						<b-card class="border-radius-1em border shadow-none text-dark" :class="{'border-primary' : selectedcombination == combination}">
					<b-row>
						<b-col md="3" v-for="value in combination.variants">
							
							
								<span v-if="value.variant.attribute !== null">{{value.variant.attribute.name}}</span> 
								<span v-if="value.variant.value !== null">{{value.variant.value.name}}</span> {{value.variant.measurement_value}}<span v-if="value.variant.units !== null">{{value.variant.units.suffix}}</span>
								
								
							
							
						</b-col>
						<b-col md="12" class="d-flex">
							<span class="badge badge-light bg-light ml-auto mt-2 font-size-14">
							<span v-if="combination.price_difference > 0">+</span><span v-else>-</span><span>£</span>{{combination.price_difference}}
								</span>
						</b-col>
					</b-row>
					</b-card>
					</a>
				</div>
				</div>
				<div class="d-flex mb-2">
					 <b-button variant="-light" :disabled="quantity < 2" id="tooltip-add" @click="quantity--" class="btn btn-light font-size-16 btn-rounded w-25 mr-1 my-auto"><i class="mdi mdi-minus"></i></b-button>
					  <b-form-group label="" class="w-50 my-auto mx-1">
						<b-form-input type="number" v-model="quantity" size="lg" /></b-form-group>
					  <b-button variant="-light" :disabled="quantity >= product.total_qty " @click="quantity++" class="btn btn-light font-size-16 btn-rounded w-25 my-auto m1-1"><i class="mdi mdi-plus"></i></b-button>
				</div>
				<div class="d-flex">
					<b-button variant="primary" class="btn-rounded btn-block w-100 text-white btn-lg" @click="addToCart(product, quantity, selectedvariants, selectedcombination)">
					Add to Cart
					</b-button>	
					
				</div>-->
			</b-col>
		</b-row>
		<b-row>
			<b-col md="12" class="d-flex mt-4" v-if="product.long_description !== null || ''">
				<h2 class="product-info-header">More Information</h2>
				<hr class="mb-4 flex-grow-1">
			</b-col>
			<b-col md="6" class="offset-md-6" v-if="product.long_description !== null || ''">
				<div v-html="product.long_description"></div>
			</b-col>
			<b-col md="12" class="d-flex mt-4" v-if="product.attributes !== null && product.attributes.length > 0">
				<h2 class="product-info-header">Features</h2>
				<hr class="mb-4 flex-grow-1">
			</b-col>
			<b-col md="6" class="offset-md-6" v-if="product.attributes !== null && product.attributes.length > 0">
				<ProductFeatures :product="product"></ProductFeatures>
			</b-col>
			<b-col md="12" class="d-flex mt-4">
				<h2 class="product-info-header">Reviews</h2>
				<hr class="mb-4 flex-grow-1">
			</b-col>
			<b-col md="12" class="pt-3">
				<b-row v-if="product.reviews.length > 0"  >
					<b-col>
						<ReviewCarousel :items="product.reviews" :swiperOptions="reviewOptions"></ReviewCarousel>
					</b-col>
					<b-col md="12" class="d-flex">
						<a class="ml-auto btn btn-light" href="javascript:;" v-b-modal.modal-1 @click="form.action = 'review'">Write Review</a>
					</b-col>
				</b-row>
				<!--<b-row v-if="product.reviews.length > 0">
					<b-col v-for="review in product.reviews">
					<b-card class="border border-radius-1em shadow-none">
						<blockquote class="p-3">{{review.message}}</blockquote>
						<b-form-rating readonly v-model="review.rating">
						</b-form-rating>
						<div class="media my-3">
							<div class="avatar-sm me-2" v-if="review.customer !== null">
								<span v-if="review.customer.avatar == null" class="avatar-title rounded-circle">{{review.name.slice(0,1)}}</span>
								<img v-else class="avatar-sm rounded-circle" :src="storageurl+review.customer.avatar.replaceAll('//', '/')">
							</div>
							<div v-else class="avatar-sm me-2">
								<span class="avatar-title rounded-circle">{{review.name.slice(0,1)}}</span>
							</div>
							<div class="media-body my-auto">
								<h5 class="mb-0 mt-auto">
									<strong>{{review.name}} 
									{{review.lastname}}</strong>
									
								</h5>
								<small class="mb-auto mt-0">
										{{review.created_at | luxon({input: 'server'})}}
									</small>
						</div>
					</div>
						<p class="font-size-14" v-html="review.description"></p>
						<small>
							xx Found This Review Helpful. 
						</small>
						<small class="float-right">
							 Did this help you? <a class="text-success" href="javascript"><i class="mdi mdi-thumb-up-outline"></i></a> 
						</small>
					</b-card>
					</b-col >
					<b-col md="12" class="d-flex">
						<a class="text-primary ml-auto" href="javascript:;" v-b-modal.modal-1 @click="form.action = 'review'">
							Review this product
						</a>
					</b-col>
				</b-row>-->
				<b-row v-else>
					<b-col md="12" class="text-center py-4">
						<i class="bx bx-star text-primary d-inline-block font-size-14"></i>
						<i class="bx bx-star text-primary d-inline-block font-size-20"></i>
						<i class="bx bx-star text-primary d-inline-block font-size-28"></i>
						<i class="bx bx-star text-primary d-inline-block font-size-20"></i>
						<i class="bx bx-star text-primary d-inline-block font-size-14"></i>
						<h4 class="text-muted">
							No Reviews
						</h4>
						<a class="text-primary" href="javascript:;" v-b-modal.modal-1 @click="form.action = 'review'">
							Be the first to review this product
						</a>
					</b-col>
				</b-row>
			</b-col>
		</b-row>
		<!--<b-row>
			<b-col md="12" class="d-flex mt-4">
				<h2 class="product-info-header">Related Products</h2>
				<hr class="mb-4 flex-grow-1">
			</b-col>
			<b-col md="8" class="offset-md-4 overflow-hidden">
				<DefaultContentRelatedProductsSwiper :swiperOptions="swiperOptions" :items="product.related_products">
				</DefaultContentRelatedProductsSwiper>

			</b-col>
		</b-row>-->
		<b-row class="mt-4 py-4">
			<b-col md="12" class="d-flex mt-4">
				<h2 class="product-info-header">Related Products</h2>
				<hr class="mb-4 flex-grow-1">
			</b-col>
			<b-col md="12" class="overflow-hidden">
				<div class="pt-5">
				<DefaultContentRelatedProductsSwiper :swiperOptions="swiperOptions" :items="product.related_products" :startSlide="0" :company="company">
				</DefaultContentRelatedProductsSwiper>
				</div>
			</b-col>
		</b-row>
		<b-row class="">
			<b-col md="12" class="d-flex mt-4">
				<h2 class="product-info-header">You Recently Viewed</h2>
				<hr class="mb-4 flex-grow-1">
			</b-col>
			<b-col md="12" class="overflow-hidden">
				<div class="pt-5">
				<DefaultContentRecentlyViewed v-if="recentlyViewed && recentlyViewed.length > 0"  :swiperOptions="swiperOptions" :company="company" :items="recentlyViewed" :startSlide="0">
				</DefaultContentRecentlyViewed>
				</div>
			</b-col>
		</b-row>
			<b-modal id="modal-1" centered content-class="border-radius-1em" header-class="border-none" hide-footer>
			<b-card-body class="card-body pt-0">
            <div class="row">
            	<div class="col-sm-12 pb-4 text-center mb-4">
            		<h3>You are about to {{form.action}}</h3>
            	</div>
              <div class="col-sm-12 pb-4 text-center border border-radius-1em">
                <div class="avatar-md profile-user-wid mb-2 mx-auto">
                  <img
                  	v-if="product.cover_image" :src="storageurl + product.cover_image.thumb"
                    alt
                    class="img-thumbnail rounded-circle"
                  />
                   <img
                  	v-else 
                    src="/images/product-placeholder.png"
                    alt
                    class="img-thumbnail rounded-circle"
                  />
                </div>
                <h5 class="font-size-15 text-truncate">{{product.name}}</h5>
              </div>
           

            </div>
          </b-card-body>
    <b-form v-if="form.action == 'message'">
    	<b-form-group>
    		<b-form-input class="underline-input my-2" placeholder="First Name" type="text"></b-form-input>
    	</b-form-group>
    	<b-form-group>
    		<b-form-input class="underline-input my-2" placeholder="Last Name" type="text"></b-form-input>
    	</b-form-group>
    	<b-form-group>
    		<b-form-input class="underline-input my-2" placeholder="Email" type="text"></b-form-input>
    	</b-form-group>
    	<b-form-group>
    		<b-form-input class="underline-input my-2" placeholder="Telephone" type="text"></b-form-input>
    	</b-form-group>
    	<b-form-group>
    		<b-form-textarea
    		class="underline-input my-2"
		      id="textarea"
		      placeholder="Enter something..."
		      rows="4"
		    ></b-form-textarea>
    	</b-form-group>
    	<a class="btn btn-outline-light btn-rounded btn-block mt-3" href="javascript:;">Send Message</a>
    </b-form>

    <b-form v-if="(form.action == 'review') && (user)" class="px-2">
    <b-form-group class="text-center">
    	<label class="mt-3 text-center">Your Details</label>
    </b-form-group>
    <ReviewForm :source="'website'" :api_token="user.api_token" :product_id="product.id" :company_id="product.company_id" :user="user" :review_type="'product'"></ReviewForm>
    
    </b-form>
    <b-form v-if="(form.action == 'favourites')  && (user !== null)" class="px-2">
    </b-form>

    <b-form v-if="((form.action == 'favourites') || (form.action == 'review')) && (!user)" class="px-2">
    	<!--<a class="btn btn-outline-light btn-rounded btn-block mb-2" target="_blank">Sign In</a>-->
    	<Login submit-url="http://gemesys.co.uk/my-account/login" auth-error="">
             <input type="hidden" name="_token" :value="csrf">
        </Login>
        
    </b-form>
  </b-modal>
	</b-container>
</template>


<style scoped >
#rating-inline{
	display: inline !important;
    padding: 0;
    width: auto;
}
#rating-inline .b-rating-star{
	padding: 0 5px 0 0 !important;
}
.spaced-title{
	letter-spacing: 0.9px;
}
</style>