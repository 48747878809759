<script type="text/javascript">
	export default{
		props: {
			recaptcha: {
				type: String
			},
			list_id: {
				type: Number
			},
			user: {
				type: Object
			},
			styleClasses: {
				type: String
			}
		},
		data(){
			return {
				formLoading: false,
				response_text: '',
				response_status: -1,
				response_data: '',
				subscription: {
		          name: '',
		          lastname: '',
		          email: '',
		          phone: '',
		          customer_id: '',
		          list_id: 1,
		        }
			}
		},
		created(){
        this.checkUser();
	    },
	    mounted(){
	        this.checkUser();
	    },
	    watch: {
	      $route() {
	      this.$nextTick(() => {
	        this.checkUser();
	        this.response_data = '';
	        this.response_status = -1;
	      })
	      }
	    },
		methods: {
			submitSubscription(){
				this.formLoading = true;
				this.response_data = '';
				axios.post('/customer-api/list/subscribe', {subscription: this.subscription, recaptcha: this.recaptcha, list_id: this.list_id }).then(response => {

								
								this.response_text = "Thank you for your subscription!";
								this.response_status = 1;
								let self = this;
								setTimeout(function() {
								self.formLoading = false;
									}, 500);
							}).catch(error => {
								this.response_text = "Sorry, you could not be subscribed for the following reasons:";
								if(error.response.data !== false){
								this.response_data = error.response.data.errors;
								}else{
									this.response_data = [['Your request did not pass verification challenge.']];
								}
								this.response_status = 0;
								let self = this;
								setTimeout(function() {
								self.formLoading = false;
									}, 500);
							});
			},
			checkUser(){
		        if(this.user !== ''){
		          this.subscription.email = this.$attrs.user.email;
		          this.subscription.id = this.$attrs.user.id;
		        }
		      }
		}
	}

</script>

<template>
	<div class="d-flex flex-column align-items-center w-100">
		<!--<b-overlay :show="formLoading" no-wrap></b-overlay>-->
		
		  <b-form-group label="" class="mt-2 w-75" v-if="response_status !== 1">
           <b-form-input type="email" placeholder="Enter your email address..." :disabled="formLoading" :class="styleClasses ? styleClasses : ''" v-model="subscription.email">
           </b-form-input>
          </b-form-group>
          
          <a v-if="response_status !== 1" class="border-bottom border-primary text-dark mt-3 py-2 d-inline-flex mx-auto text-center" href="javascript:;" @click="submitSubscription()">
          <h4>Subscribe</h4> <i class="bx bx-chevron-right text-primary font-size-20 ml-2" v-if="!formLoading"></i><b-spinner v-else small  type="grow" variant="primary" class="ml-2"></b-spinner>
        </a>
                  <p class="mb-0 mt-3" v-if="response_status !== -1">
          	{{response_text}}

          				<ul>
                        <li v-for="(error, key) in response_data">
                          {{error[0]}}
                        </li>
                      </ul>
          </p>
	</div>

</template>