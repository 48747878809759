<script type="text/javascript">
	import Swal from "sweetalert2";
	import PageBuilder from "../../components/widgets/page-builder/PageBuilder";
	import PageBuilderPreview from "../../components/widgets/page-builder/PageBuilderPreview";
	import vue2Dropzone from 'vue2-dropzone';
	import { webPageWidgets, templateWidgets } from "./page-widgets"
	import stylesTab from "../../components/widgets/page-builder/widgets/stylesTab";
	import { containerStyles, widgetStyles } from "../../components/widgets/page-builder/page-styles";
	import draggable from 'vuedraggable';
	import simplebar from "simplebar-vue";
	import { EventBus } from "../../app";
	import CodeEditor from 'simple-code-editor';
	import PageBuilderProcessor from "../../components/widgets/page-builder/PageBuilderProcessor";
	import BuilderLayerNav from "../../components/widgets/page-builder/BuilderLayerNav"
	import widgetEditBox from "../../components/widgets/page-builder/widgetEditBox"
	import widgetViewportSwitcher from "../../components/widgets/page-builder/widgetViewportSwitcher";

	export default{
		components: {PageBuilder, PageBuilderPreview, vueDropzone: vue2Dropzone, draggable, simplebar, CodeEditor, stylesTab, PageBuilderProcessor, widgetEditBox, BuilderLayerNav, widgetViewportSwitcher},
		data() {
		    return {
		    	moveSectionIndex: -1,
		    	selectedFonts: [],
		    	layerNav: false,
		    	action_type: '',
		    	stylesEditArea: 'section',
		    	activateViewPortSettings: false,
		    	loadingInnerWindow: true,
		    	loadingWidgetSettings: false,
		    	showWidgetBox: true,
		    	widgetBoxEditContent: [],
		    	widgetEditBlockIndex: null,
					widgetBox: '',
					rindex: -1,
					cindex: -1,
					bindex: -1,
					sindex: -1,
					stylesrindex: -1,
					stylescindex: -1,
					stylesbindex: -1,
					stylessindex: -1,
					widgetBoxLoading: '',
					widgetBoxEditMode: '',
					widgetBoxSubForm: '',
					//filteredWidgets: '',
					fileResource: '',
					fileRowIndex: '',
					fileColIndex: '',
					showFileManager: '',
					data: {},
					selectedFiles: [],
					widgets: [],
					template_areas: [],
					sidePanelMaxHeight: '',
					widgetStyles: [],
					widgetSearch: '',
		    	previewUrl: false,
		    	mobilePreview: false,
		    	startX: 0,
    			contentWidth: 600,
		    	resizingLeft: false,
    			resizingRight: false,
		    	windowInnerWidth: 1240,
		    	viewportSize: 'xl',
		    	viewportSet: 'xl',
		    	showPreview: false,
		    	previewContent: '',
		    	showSettingsBar: true,
		    	closingPage: false,
		    	activeSection: false,
		    	showSectionTools: -1,
		      containerStyles: containerStyles,
		      title: "Template",
		      showModal: false,
		      edit_area: '',
		      sidePanelMaxHeight: 420,
		      sectionHover: -1,
		      sectionSettings: null,
		      sectionStyles_raw: '',
		      sectionStyles_editing: '',
		      pageSettings: false,
		      pageStylesheets: [],
		      pageTemplates: [
		      	{name: 'Template 1', thumbnail: '', description: ''},
		       	{name: 'Template 2', thumbnail: '', description: ''},
		      ],
		      page: {
		      	name: '',
		      	url_rewrite: '',
		      	description: '',
		      	thumbnail: '',
		      	company_web_page_template_id: '',
		      	company_web_page_theme_id: '',
		      	header: {sections: []},
		      	footer: {sections: []},
		      	fonts: [],
		      	active: 0, 
		      	custom_classes: '',
		      	custom_css: '',
		      	custom_js: '',
		      	content: {
		      		xs: {
					 		sections: [
					 					{styles: containerStyles, rows: []}
					 						],
					 		},
					 		sm: {
					 		sections: [
					 					{styles: containerStyles, rows: []}
					 						],
					 		},
					 		md: {
					 		sections: [
					 					{styles: containerStyles, rows: []}
					 						],
					 		},
					 		lg: {
					 		sections: [
					 					{styles: containerStyles, rows: []}
					 						],
					 		},
					 		xl: {
					 		sections: [
					 					{styles: containerStyles, rows: []}
					 						],
					 		}
		     	 }
		  	 },
		  	 page_setup:  {
		      		xs: {
					 		sections: [
					 					{styles: containerStyles, rows: []}
					 						],
					 		},
					 		sm: {
					 		sections: [
					 					{styles: containerStyles, rows: []}
					 						],
					 		},
					 		md: {
					 		sections: [
					 					{styles: containerStyles, rows: []}
					 						],
					 		},
					 		lg: {
					 		sections: [
					 					{styles: containerStyles, rows: []}
					 						],
					 		},
					 		xl: {
					 		sections: [
					 					{styles: containerStyles, rows: []}
					 						],
					 		}
		     	 },
		  	 showPageTemplates: false,
		      error_reponse: '',
		      error_message: false,
		      templateWidgets: templateWidgets,
		      webPageWidgets: webPageWidgets,
		      dropzoneOptions: {
		        url: 'https://httpbin.org/post',
		        thumbnailHeight: 150,
		        thumbnailWidth: 150,
		        autoProcessQueue: false,
		      },
		    };
		  },
		created(){
			//window.resizeTo(500, 500);
			//document.documentElement.classList.add("bg-white");
			//document.documentElement.style.backgroundColor = 'rgba(75, 47, 141, 0.1)';
			document.body.classList.add("bg-white");

			
			window.addEventListener("resize", this.windowResize);	
			window.addEventListener("message", this.runQuery);	
			var url = window.location.href;
			if(url.includes('section=header')){
				this.edit_area = 'header';
			}
			if(url.includes('section=body')){
				this.edit_area = 'content';
			}
			if(url.includes('section=footer')){
				this.edit_area = 'footer';
			}
			if(url.includes('action=edit')){
				this.action_type = 'edit';
			}else if(url.includes('action=add')){
				this.action_type = 'add';
			}
			if(url.includes('preview=true')){
				this.mobilePreview = true;
			}else{
				this.showPreview = true;
				document.body.style.overflow = 'hidden';
				this.loadMobilePreview();
			}
			if(this.$attrs.id !== ''){
				this.getPage();
			}else{
				this.addSection();
			//	this.pageSettings = true;
			}
		},
		
		destroyed() {
            window.removeEventListener("resize", this.windowResize);

    },
    mounted(){
        	this.sidePanelMaxHeight = window.innerHeight - 80;
        	this.windowInnerWidth = window.innerWidth;
        	this.contentWidth = this.windowInnerWidth;
        	document.body.style.position = 'relative';
        	document.body.style.margin = 'auto';
        		let self = this;

        	EventBus.$on('send-to-parent', function(){
        			self.sendPagetoParent();
        		});
        	EventBus.$on('change-viewport-size', function(payload){
        			self.changeViewSize(payload);
        			//self.sendPagetoIframe();
        			
        		});
        			
        	EventBus.$on('add-font', function(payload){
        		self.selectedFonts.push(payload);
        		self.addFonttoIframe(payload);
        	});

        	EventBus.$on('remove-font', function(payload){
        		var fontIndex = self.selectedFonts.findIndex(item => item == payload);
        		self.selectedFonts.splice(fontIndex, 1);
        		self.removeFontfromIframe(fontIndex);
        	});

        		EventBus.$on('copy-widget-across-viewport', function(to_viewport, from_viewport, sindex, rindex, cindex, bindex){
        		console.log('to:' + to_viewport + ' from:' + from_viewport + ' ' +sindex + '' + rindex + '' + cindex + '' + bindex);
        			var copiedarea = self.page[self.edit_area][from_viewport].sections[sindex].rows[rindex].cols[cindex].blocks[bindex];
        				self.page[self.edit_area][to_viewport].sections[sindex].rows[rindex].cols[cindex].blocks[bindex] = JSON.parse(JSON.stringify(copiedarea));
        			self.sendPagetoIframe();

        	});

        	EventBus.$on('widget-added', function(widget, sindex, rindex, cindex){
        		console.log(sindex);
        		const iframe = self.$refs.previewIframe;
			      iframe.contentWindow.postMessage({
			        action: 'widgetAdded',
			        widget: widget,
					sindex: sindex,
					rindex: rindex,
					cindex: cindex,
			      }, '*');
			      self.widgetBoxEditMode = false;
        		self.showWidgetBox = false;
        		self.widgetBoxEditContent = [];
        		self.widgetBox = '';
        		self.closeWidgetBox(false);
        	});

        	EventBus.$on('widget-updated', function(){
        		self.widgetBoxEditMode = false;
        		self.showWidgetBox = false;
        		self.widgetBoxEditContent = [];
        		self.widgetBox = '';
        		self.sendPagetoIframe();
        		self.closeWidgetBox(false);
        		
        	});

        	EventBus.$on('update-widget', function(){
        		const iframe = self.$refs.previewIframe;
			      iframe.contentWindow.postMessage({
			        action: 'reloadWidget',
			        sindex: this.sindex,
							rindex: this.rindex,
							cindex: this.cindex,
							bindex: this.bindex,
			      });
        	});

        	EventBus.$on('widget-auto-update', function(){
        		self.sendPagetoIframe();
        	});
        	
        	EventBus.$on('element-moved', function(element, sindex, rindex, cindex, oldindex, newindex, newrindex, newcindex, oldbindex){
        		//alert('moved:' + sindex);
        		//self.sendPagetoParent();
        		parent.postMessage({
									element: element,
									sindex: sindex,
									rindex: rindex,
									cindex: cindex,
									oldindex: oldindex,
									newindex: newindex,
									newrindex: newrindex,
									newcindex: newcindex,
									oldbindex: oldbindex,
									moveSectionIndex: self.moveSectionIndex,
									action: 'moveElement'
								}, '*');
        			
        				//self.sendPagetoParent();
        				//self.sendPagetoIframe();
        	});

        	window.addEventListener('message', function(event) {
        		//console.log('childmessage:' +event);
	  				if (event.data.action == 'updateData') {
	  					if(self.contentWidth == self.windowInnerWidth){
	   						 Object.keys(self.page[self.edit_area]).forEach(val => {
	   						 self.page[self.edit_area][val].sections[event.data.sindex] = event.data.data_update;
	   						});
	   					}else{
	   						self.page[self.edit_area][self.viewportSize].sections[event.data.sindex] = event.data.data_update;
	   					}
	    				// Perform actions with the data received from the parent
	  				}


	  				if (event.data.action == 'widgetAdded') {
	  						
	  						Object.keys(self.page[self.edit_area]).forEach(val => {
	  							console.log(self.page);
	  							console.log(event.data.sindex);
		        			self.page[self.edit_area][val].sections[event.data.sindex].rows[event.data.rindex].cols[event.data.cindex].blocks.push(JSON.parse(JSON.stringify(event.data.widget)));

		        			//self.sendDataToIframe(self.data);
		        			
		        			});
	  						self.widgetBoxEditMode = false;
		        		self.showWidgetBox = false;
		        		self.widgetBoxEditContent = [];
		        		self.widgetBox = '';
		        		self.sendPagetoParent();
	  				}


	  				if (event.data.action == 'reloadWidget') {
	  					EventBus.$emit('update-widget');
	  				}

	  				if(event.data.action == 'addFont'){
	  					self.selectedFonts.push(event.data.data_update);
	  				}

	  				if(event.data.action == 'removeFont'){
	  					self.selectedFonts.splice(event.data.data_update, 1);
	  				}

	  				if (event.data.action == 'updatePageData') {
	  					self.page = event.data.data_update;
	  				}
	  				if (event.data.action == 'closeWidgetBox') {
	  						self.closeWidgetBox(event.data.value);
	  				}

	  				if (event.data.action == 'savePage') {
	  						self.savePage();
	  				}

	  				if (event.data.action == 'updatePage') {
	  						self.updatePage();
	  				}

	  				if (event.data.action == 'resizeBody') {
	  						self.changeViewSize(event.data.width);
	  				}

	  				if (event.data.action == 'updateStyles') {
	  					if(event.data.section == "section"){
	  						self.sectionSettings = event.data.sectionIndex;
	  						self.stylesEditArea = event.data.section;
	  						self.stylessindex = event.data.sectionIndex;

	  						self.sectionStyles_editing = JSON.parse(JSON.stringify(self.page[self.edit_area][self.viewportSize].sections[self.sectionSettings].styles));
	  						//self.sindex = self.sectionSettings;
	  						}
	  						if(event.data.section == "row"){
	  						self.sectionSettings = event.data.sectionIndex;
	  						self.stylesEditArea = event.data.section;
	  						self.stylessindex = event.data.sectionIndex.split('-')[0];
	  						self.stylesrindex = event.data.sectionIndex.split('-')[1];

	  						
	  						self.sectionStyles_editing = JSON.parse(JSON.stringify(self.page[self.edit_area][self.viewportSize].sections[self.stylessindex].rows[self.stylesrindex].styles));
	  						
	  						}

	  						if(event.data.section == "col"){
	  						self.sectionSettings = event.data.sectionIndex;
	  						self.stylesEditArea = event.data.section;
	  						self.stylessindex = event.data.sectionIndex.split('-')[0];
	  						self.stylesrindex = event.data.sectionIndex.split('-')[1];
	  						self.stylescindex = event.data.sectionIndex.split('-')[2];
	  						
	  						self.sectionStyles_editing = JSON.parse(JSON.stringify(self.page[self.edit_area][self.viewportSize].sections[self.stylessindex].rows[self.stylesrindex].cols[self.stylescindex].styles));
	  						//self.sindex = self.sectionSettings;
	  						}
	  				}

	  				if (event.data.action == 'showTools') {
	  						console.log('tools showing');
	  						if(event.data.section == "section"){
	  						self.sectionSettings = event.data.sectionIndex;
	  						self.stylesEditArea = event.data.section;
	  						self.stylessindex = event.data.sectionIndex;

	  						self.sectionStyles_editing = self.page[self.edit_area][self.viewportSize].sections[self.sectionSettings].styles;
	  						//self.sindex = self.sectionSettings;
	  						}
	  						if(event.data.section == "row"){
	  						self.sectionSettings = event.data.sectionIndex;
	  						self.stylesEditArea = event.data.section;
	  						self.stylessindex = event.data.sectionIndex.split('-')[0];
	  						self.stylesrindex = event.data.sectionIndex.split('-')[1];

	  						
	  						self.sectionStyles_editing = self.page[self.edit_area][self.viewportSize].sections[self.stylessindex].rows[self.stylesrindex].styles;
	  						
	  						}

	  						if(event.data.section == "col"){
	  						self.sectionSettings = event.data.sectionIndex;
	  						self.stylesEditArea = event.data.section;
	  						self.stylessindex = event.data.sectionIndex.split('-')[0];
	  						self.stylesrindex = event.data.sectionIndex.split('-')[1];
	  						self.stylescindex = event.data.sectionIndex.split('-')[2];
	  						
	  						self.sectionStyles_editing = self.page[self.edit_area][self.viewportSize].sections[self.stylessindex].rows[self.stylesrindex].cols[self.stylescindex].styles;
	  						//self.sindex = self.sectionSettings;
	  						}
	  				}

	  				if (event.data.action == 'moveElement') {
	  					self.blockMoved(event.data.element, event.data.sindex, event.data.rindex, event.data.cindex, event.data.oldindex, event.data.newindex, event.data.newrindex, event.data.newcindex, event.data.oldbindex, event.data.moveSectionIndex);
	  				}
	  				if (event.data.action == 'addSection') {
	  					self.addSection();
	  				}

	  				if (event.data.action == 'deleteSection') {
	  					self.deleteSection(event.data.sindex);
	  				}

	  				if (event.data.action == 'duplicateSection') {
	  					self.duplicateSection(event.data.sindex, event.data.section);
	  				}

	  				if (event.data.action == 'duplicateRow') {
	  					self.duplicateRow(event.data.sindex, event.data.rindex, event.data.section);
	  				}

	  				/*if (event.data.action == 'deleteRow') {
	  					self.deleteRow(event.data.sindex, event.data.rindex);
	  				}*/
	  				if (event.data.action == 'addCol') {
	  					console.log('add col event ' + self.mobilePreview);
	  					self.addColumn(event.data.sindex, event.data.rindex, event.data.data);
	  				}
	  				if (event.data.action == 'deleteCol') {
							self.deleteColumn(event.data.sindex, event.data.rindex);
	  				}
	  				if (event.data.action == 'duplicateCol') {
	  					self.duplicateCol(event.data.sindex, event.data.rindex, event.data.cindex, event.data.data);
	  				}

	  				if (event.data.action == 'duplicateWidget') {
	  					self.duplicateWidget(event.data.sindex, event.data.rindex, event.data.cindex, event.data.bindex, event.data.data);
	  				}

	  				if (event.data.action == 'deleteWidget') {
	  					console.log('del widget')
	  					self.deleteWidget(event.data.sindex, event.data.rindex, event.data.cindex, event.data.bindex, event.data.data);
	  				}
	  				if (event.data.action == 'updatePageData') {
	  					self.page = event.data.data_update;
	  				}

	  				if (event.data.action == 'pageToParent') {
	  					self.page = event.data.data_update;
	  				}
					});

    },
    computed: {
    	setViewportSize(){
    		
			},
    },
		methods: {
			copyAcrossViewport(to_viewport, from_viewport){
    	if(this.stylesEditArea == 'section'){
    		var copiedarea = this.page[this.edit_area][from_viewport].sections[this.stylessindex].styles;
    		this.page[this.edit_area][to_viewport].sections[this.stylessindex].styles = JSON.parse(JSON.stringify(copiedarea));
    	}else if(this.stylesEditArea == 'row'){
    		var copiedarea = this.page[this.edit_area][from_viewport].sections[this.stylessindex].rows[this.stylesrindex].styles;
    		this.page[this.edit_area][to_viewport].sections[this.stylessindex].rows[this.stylesrindex].styles = JSON.parse(JSON.stringify(copiedarea));
    	}else if(this.stylesEditArea == 'column'){
    		var copiedarea = this.page[this.edit_area][from_viewport].sections[this.stylessindex].rows[this.stylesrindex].cols[this.stylescindex].styles;
    		this.page[this.edit_area][to_viewport].sections[this.stylessindex].rows[this.stylesrindex].cols[this.stylescindex].styles = JSON.parse(JSON.stringify(copiedarea));
    	}
    	this.sendStylesToIframe();
    },
			blockMoved(element, sindex, rindex, cindex, oldindex, newindex, newrindex, newcindex, oldbindex, moveSectionIndex){
				console.log(this.mobilePreview + ' is block moved '+ moveSectionIndex);
				Object.keys(this.page[this.edit_area]).forEach(val => {
        				
										if(element == 'row'){
        					
        						var area = JSON.parse(JSON.stringify(this.page[this.edit_area][val].sections[sindex].rows[oldindex]));
        						this.page[this.edit_area][val].sections[sindex].rows.splice(oldindex, 1);
	   						 		 if(this.page[this.edit_area][val].sections[moveSectionIndex].rows.length > 0){
	   						 		 this.page[this.edit_area][val].sections[moveSectionIndex].rows.splice(newindex, 0, area);
	   						 			}else{
	   						 				this.page[this.edit_area][val].sections[moveSectionIndex].rows.push(area);
	   						 			}
	   						 }else if(element == 'col'){
	   						 	var area = JSON.parse(JSON.stringify(this.page[this.edit_area][val].sections[sindex].rows[rindex].cols[oldindex]));
        					this.page[this.edit_area][val].sections[sindex].rows[rindex].cols.splice(oldindex, 1);
	   						 	this.page[this.edit_area][val].sections[moveSectionIndex].rows[rindex].cols.splice(newindex, 0, area);
	   						 }else if(element == 'block'){
	   						 	if(this.viewportSize == val){
	   						 	console.log('sold: ' + sindex + ' snew: ' + moveSectionIndex + ' rnew: ' + newrindex + ' rold: ' + rindex + ' cnew:' + newcindex + ' cold:' + cindex + ' bold:' + oldindex + ' bnew:' + newindex);
	   						 	var area = this.page[this.edit_area][val].sections[sindex].rows[rindex].cols[((cindex == -1) ? 0 : cindex)].blocks[oldindex];
        					this.page[this.edit_area][val].sections[sindex].rows[rindex].cols[((cindex == -1) ? 0 : cindex)].blocks.splice(oldindex, 1);
        					if(this.page[this.edit_area][val].sections[moveSectionIndex].rows[((newrindex == -1) ? 0 : newrindex)].cols[((newcindex == -1) ? 0 : newcindex)].blocks.length > 0){
	   						 	this.page[this.edit_area][val].sections[moveSectionIndex].rows[((newrindex == -1) ? 0 : newrindex)].cols[((newcindex == -1) ? 0 : newcindex)].blocks.splice(newindex, 0, JSON.parse(JSON.stringify(area)));
	   						 	}else{
	   						 		this.page[this.edit_area][val].sections[moveSectionIndex].rows[((newrindex == -1) ? 0 : newrindex)].cols[((newcindex == -1) ? 0 : newcindex)].blocks.push(JSON.parse(JSON.stringify(area)));
	   						 	}
	   						 	
	   						 }
	   						 }
	   						});
        				//
        				//self.sendPagetoParent();
        				this.sendPagetoIframe();
				//this.sendPagetoParent();
				
			},
			elementMoved(payload){
				if(this.mobilePreview == true){
				console.log(payload.oldIndex + ' to:' + payload.newIndex);
				Object.keys(this.page[this.edit_area]).forEach(val => {
				
					if(this.viewportSize !== val){
						var area = JSON.parse(JSON.stringify(this.page[this.edit_area][val].sections[payload.oldIndex]));
					
						
						console.log(val);
						this.page[this.edit_area][val].sections.splice(payload.oldIndex, 1);
						console.log('after: ' + val);
						this.page[this.edit_area][val].sections.splice(payload.newIndex, 0, area);
						
							console.log(area);

					}
				});
				this.sendPagetoParent();
				}
			},
			openSidepanel(area, index, style){
				this.showTools(area, index, style);
			},
			showTools(area, index, section){
					if(this.mobilePreview == true){
								parent.postMessage({
									action: 'showTools',
									section: area,
									sectionIndex: index,
									styles: section
								}, '*');
					}else{
						if(area == 'section'){
							this.sectionSettings = index;
						}
					}
			},
			reloadInnerWindow(){
					this.loadingInnerWindow = true;
					let self = this;
        setTimeout(function(){
				         		//self.getActiveShippingIntegrations();
				         		self.loadingInnerWindow = false;
								  }, 1000);
			},
			changeViewSize(size){
					this.contentWidth = size;				
					document.body.style.width = size + 'px';
					this.activateViewPortSettings = true;
					if(this.contentWidth <= 576){
								this.viewportSize = 'xs';
								
							}else if(this.contentWidth <= 769){
								this.viewportSize = 'sm';
								
							}else if(this.contentWidth <= 992){
								this.viewportSize = 'md';
								
							}else if(this.contentWidth <= 1200){
								this.viewportSize = 'lg';					
								
							}else if(this.contentWidth > 1200){
								this.viewportSize = 'xl';
								
							}
							this.widgetBoxEditContent = this.page[this.edit_area][this.viewportSize].sections[this.sindex].rows[this.rindex].cols[this.cindex].blocks[this.bindex];
						if(this.widgetBoxEditContent.widgetvisibility){
				var visibility = this.widgetBoxEditContent.widgetvisibility;		
					
					if(this.page[this.edit_area][this.viewportSize].sections[this.sindex].rows[this.rindex].cols[this.cindex].blocks[this.bindex].widgetvisibility !== visibility){
					Object.keys(this.page[this.edit_area]).forEach(val => {
						this.page[this.edit_area][val].sections[this.sindex].rows[this.rindex].cols[this.cindex].blocks[this.bindex].widgetvisibility = JSON.parse(JSON.stringify(visibility));
					});
					}
				}
			},
			resizeBody(size){
					const iframe = this.$refs.previewIframe;
	      iframe.contentWindow.postMessage({
	        action: 'resizeBody',
	        width: size
	      }, '*');
			},
			sendStylesToIframe(area, data) {
	      const iframe = this.$refs.previewIframe;
	      iframe.contentWindow.postMessage({
	        action: 'updateStyles',
	        section: area,
	        stylessindex: this.stylessindex,
	        stylesrindex: this.stylesrindex,
	        stylescindex: this.stylescindex,
	        data_update: data,
	      }, '*');
	    },
			sendDataToIframe(data) {
	      const iframe = this.$refs.previewIframe;
	      iframe.contentWindow.postMessage({
	        action: 'updateData',
	        data_update: this.data,
	        sindex: this.sindex
	      }, '*');
	    },
	    addFonttoIframe(font){
	    	if(this.mobilePreview == false){
	    	  const iframe = this.$refs.previewIframe;
	      iframe.contentWindow.postMessage({
	        action: 'addFont',
	        data_update: font,
	      }, '*');
	    	}
	    },
	    removeFontfromIframe(index){
	    	if(this.mobilePreview == false){
	    	  const iframe = this.$refs.previewIframe;
	      iframe.contentWindow.postMessage({
	        action: 'removeFont',
	        data_update: index,
	      }, '*');
	    	}
	    },
	    sendPagetoIframe(){
	    		if(this.mobilePreview == false){
	    	  const iframe = this.$refs.previewIframe;
	      iframe.contentWindow.postMessage({
	        action: 'updatePageData',
	        data_update: this.page,
	      }, '*');
	    	}
	    },
	    sendPagetoParent(){
	    		if(this.mobilePreview == true){
	    	parent.postMessage({
									action: 'pageToParent',
									message: 'pageToParent',
									data_update: this.page,
								}, '*');
	    		}
	    },
	    updateLayers(){
	    },
			runQuery(e){
				if(e.data.message == 'addNewWidget'){
					console.log(e.data);
					//alert('new widget added');
						this.widgetBoxEditContent = e.data.widgetBoxEditContent;
						this.widgetBox = e.data.widgetBox;
						this.sindex = e.data.sindex;
						this.rindex = e.data.rindex;
						this.cindex = e.data.cindex;
						this.widgetEditBlockIndex = null;
						this.widgetBoxLoading = e.data.widgetBoxLoading;
						this.widgetBoxEditMode = e.data.widgetBoxEditMode;
						this.widgetBoxSubForm = e.data.widgetBoxSubForm;
						//this.filteredWidgets = e.data.filteredWidgets;
						this.fileResource = e.data.fileResource;
						this.fileRowIndex = e.data.fileRowIndex;
						this.fileColIndex = e.data.fileColIndex;
						this.showFileManager = e.data.showFileManager;
						this.user = this.$attrs.user;
						this.company = this.$attrs.company;
						this.data = e.data.data;
						this.selectedFiles = e.data.selectedFiles;
						this.widgets = e.data.widgets;
						this.template_areas = e.data.template_areas;
						this.sidePanelMaxHeight = e.data.sidePanelMaxHeight;
						this.widgetStyles = e.data.widgetStyles;
						this.widgetSearch = e.data.widgetSearch;

						this.showWidgetBox = true;
				}
				if(e.data.message == 'editWidget'){
					console.log(e.data);
					//alert('new widget added');

						this.widgetBoxEditContent = e.data.widgetBoxEditContent;
						this.widgetBox = e.data.widgetBox;
						this.sindex = e.data.sindex;
						this.rindex = e.data.rindex;
						this.cindex = e.data.cindex;
						this.bindex = e.data.bindex;
						this.widgetEditBlockIndex = e.data.bindex;
						this.widgetBoxLoading = e.data.widgetBoxLoading;
						this.widgetBoxEditMode = e.data.widgetBoxEditMode;
						this.widgetBoxSubForm = e.data.widgetBoxSubForm;
						//this.filteredWidgets = e.data.filteredWidgets;
						this.fileResource = e.data.fileResource;
						this.fileRowIndex = e.data.fileRowIndex;
						this.fileColIndex = e.data.fileColIndex;
						this.showFileManager = e.data.showFileManager;
						this.user = this.$attrs.user;
						this.company = this.$attrs.company;
						this.data = e.data.data;
						this.selectedFiles = e.data.selectedFiles;
						this.widgets = e.data.widgets;
						this.template_areas = e.data.template_areas;
						this.sidePanelMaxHeight = e.data.sidePanelMaxHeight;
						this.widgetStyles = e.data.widgetStyles;
						this.widgetSearch = e.data.widgetSearch;
						this.widgetBoxEditContent = this.page[this.edit_area][this.viewportSize].sections[this.sindex].rows[this.rindex].cols[this.cindex].blocks[this.bindex];
						this.showWidgetBox = true;
				}
				
				if(e.data.message == 'addSection'){
						Object.keys(this.page[this.edit_area]).forEach(val => {
							this.page[this.edit_area][val].sections.push({styles: JSON.parse(JSON.stringify(this.containerStyles)), rows: []});
						});
				}

				if(e.data.message == 'addRow'){
					//console.log('add row');

						Object.keys(this.page[this.edit_area]).forEach(val => {
						this.page[this.edit_area][val].sections[e.data.sindex].rows.push(e.data.data);
							
						});
						this.sendPagetoIframe();
				}

				if (e.data.message == 'addCol') {
	  					console.log('add col e ' + this.mobilePreview);
	  					this.addColumn(e.data.sindex, e.data.rindex, e.data.data);
	  				}
				if (e.data.message == 'deleteCol') {
							this.deleteColumn(e.data.sindex, e.data.rindex);
	  				}
				if (e.data.message == 'deleteRow') {
					console.log('del row');
	  					this.deleteRow(e.data.sindex, e.data.rindex);
	  				}
				if (e.data.message == 'duplicateRow') {
	  					this.duplicateRow(e.data.sindex, e.data.rindex, e.data.data);
	  				}

	  				if (e.data.message == 'duplicateCol') {
	  					this.duplicateCol(e.data.sindex, e.data.rindex, e.data.cindex, e.data.data);
	  				}

	  				if (e.data.message == 'duplicateWidget') {
	  					this.duplicateWidget(e.data.sindex, e.data.rindex, e.data.cindex, e.data.bindex, e.data.data);
	  				}

	  				if (e.data.message == 'deleteWidget') {
	  					this.deleteWidget(e.data.sindex, e.data.rindex, e.data.cindex, e.data.bindex, e.data.data);
	  				}
			},
			loadMobilePreview() {
        const pageUrl = window.location.href.replace('/template/', '/template/') + '&preview=true';
        //const iframe = document.getElementById("preview-iframe");
        //const pagePreview = document.getElementById("page-preview");

        this.previewUrl = pageUrl;
        let self = this;
        setTimeout(function(){
				         		//self.getActiveShippingIntegrations();
				         		self.loadingInnerWindow = false;
								  }, 2000);
    },
      async newWidgetOptions(widget){
		    	this.widgetBoxEditContent = JSON.parse(JSON.stringify(widget));
		    	this.widgetBoxEditContent['widgetstyles'] = JSON.parse(JSON.stringify(this.widgetStyles));
		    	if(widget.widgettype == 'listings'){
		    			//const listing_types = await this.getListingTypes();
		        	this.widgetBoxEditContent.widgetfilters.filter.listing_type.options = await this.getListingTypes();
		      }
		      if(widget.widgettype == 'listingsmap'){
		    			//const listing_types = await this.getListingTypes();
		        	this.widgetBoxEditContent.widgetfilters.filter.listing_type.options = await this.getListingTypes();
		      }
		      if(widget.widgettype == 'menu'){
		      		this.widgetBoxEditContent.widgetfilters.filter.menus.options = this.menus.data;
		      		if(this.$attrs.company.logo !== ''){
		      			this.widgetBoxEditContent.widgetcontent[this.filterStylesKey(this.widgetBoxEditContent.widgetcontent, 'logo')].logo.value = ''+this.storageurl+''+this.$attrs.company.logo+'';
		      		}
		      }
		      this.widgetBoxEditMode = true;
		      let self = this;
				         setTimeout(function(){
				         		//self.getActiveShippingIntegrations();
				         		self.widgetBoxLoading = false;
								  }, 1000);
		    },
		startResizeLeft(e) {
      this.resizingLeft = true;
      this.startX = e.clientX;
      document.addEventListener('mousemove', this.resizeLeft);
      document.addEventListener('mouseup', this.stopResizeLeft);
    },
    resizeLeft(e) {
      if (this.resizingLeft) {
        const diffX = e.clientX - this.startX;
        this.contentWidth -= diffX;
        // Boundary check for left sidebar
        this.contentWidth = Math.max(this.contentWidth, 0);
        document.body.style.width = this.contentWidth + 'px';
        this.startX = e.clientX;
      }
    },
    stopResizeLeft() {
      this.resizingLeft = false;
      document.removeEventListener('mousemove', this.resizeLeft);
      document.removeEventListener('mouseup', this.stopResizeLeft);
    },
    startResizeRight(e) {
      this.resizingRight = true;
      this.startX = e.clientX;
      document.addEventListener('mousemove', this.resizeRight);
      document.addEventListener('mouseup', this.stopResizeRight);
    },
    resizeRight(e) {
      if (this.resizingRight) {
        const diffX = e.clientX - this.startX;
        this.contentWidth += diffX;
        this.contentWidth = Math.min(this.contentWidth, window.innerWidth);
        document.body.style.width = this.contentWidth + 'px';
        this.startX = e.clientX;
      }
    },
    stopResizeRight() {
      this.resizingRight = false;
      document.removeEventListener('mousemove', this.resizeRight);
      document.removeEventListener('mouseup', this.stopResizeRight);
    },
		getDefaultDomain(){
				axios.get('/api/company/web/domains', {headers: {
				'Authorization' : 'Bearer '+this.$attrs.user.api_token
				}}).then(response => {

				}).catch(error => {

				});
			},
			activatePreviewMode(){
				this.previewContent = this.page[this.edit_area];
				this.showPreview = true;
			},
						addColumn(sindex, rindex, data){
				if(this.mobilePreview == true){
					/*console.log('add col 2');
				
						Object.keys(this.page[this.edit_area]).forEach(val => {
						if(this.viewportSize !== val){
							
							this.page[this.edit_area][val].sections[sindex].rows[rindex].cols.push(JSON.parse(JSON.stringify(data)));
							}
							console.log(val);
						});*/
						this.sendPagetoParent();
				}else{
					const iframe = this.$refs.previewIframe;
			      iframe.contentWindow.postMessage({
			        action: 'addCol',
			        data: data,
			        sindex: sindex,
			        rindex: rindex
			      }, '*');
				}
			},
			deleteColumn(index, rindex, cindex){
				/*Object.keys(this.page[this.edit_area]).forEach(val => {
					this.page[this.edit_area][val].sections[index].rows[rindex].cols.splice(cindex, 1);
					});*/
					
					if(this.mobilePreview == true){
					
						this.sendPagetoParent();
					}else{
						parent.postMessage({
									action: 'deleteCol',
									message: 'deleteCol',
									sindex: index,
									rindex: rindex,
								}, '*');
					}
			},
			addSection(){
			 	Object.keys(this.page[this.edit_area]).forEach(val => {
					this.page[this.edit_area][val].sections.push({styles: JSON.parse(JSON.stringify(this.containerStyles)), rows: []});
				});
					if(this.mobilePreview == true){
					parent.postMessage({
									action: 'addSection',
									message: 'addSection',
									data_update: {styles: JSON.parse(JSON.stringify(this.containerStyles)), rows: []},
								}, '*');
					}
				
			},
			deleteRow(index, rindex){
				Object.keys(this.page[this.edit_area]).forEach(val => {
					this.page[this.edit_area][val].sections[index].rows.splice(rindex, 1);
					});
					this.sendPagetoIframe();
					if(this.mobilePreview == true){
					parent.postMessage({
									action: 'deleteRow',
									message: 'deleteRow',
									sindex: index,
									rindex: rindex,
								}, '*');
					}			
			},
			deleteWidget(index, rindex, cindex, bindex, section){
				Object.keys(this.page[this.edit_area]).forEach(val => {
					this.page[this.edit_area][val].sections[index].rows[rindex].cols[cindex].blocks.splice(bindex, 1);
					});
				this.sendPagetoIframe();
					if(this.mobilePreview == true){
					parent.postMessage({
									action: 'deleteWidget',
									message: 'deleteWidget',
									section: JSON.parse(JSON.stringify(section)),
									sindex: index,
									rindex: rindex,
									cindex: cindex,
									bindex: bindex,
								}, '*');
					}		
			},
			duplicateCol(index, rindex, cindex, section){
				Object.keys(this.page[this.edit_area]).forEach(val => {
				this.page[this.edit_area][val].sections[index].rows[rindex].cols.splice(cindex, 0, JSON.parse(JSON.stringify(section)));
					});
					this.sendPagetoIframe();
					if(this.mobilePreview == true){
					parent.postMessage({
									action: 'duplicateCol',
									message: 'duplicateCol',
									section: JSON.parse(JSON.stringify(section)),
									sindex: index,
									rindex: rindex,
									cindex: cindex,
								}, '*');
					}			
			},
			duplicateWidget(index, rindex, cindex, bindex, section){
				Object.keys(this.page[this.edit_area]).forEach(val => {
					this.page[this.edit_area][val].sections[index].rows[rindex].cols[cindex].blocks.splice(bindex, 0, JSON.parse(JSON.stringify(section)));
					});
					this.sendPagetoIframe();
					if(this.mobilePreview == true){
					parent.postMessage({
									action: 'duplicateWidget',
									message: 'duplicateWidget',
									section: JSON.parse(JSON.stringify(section)),
									sindex: index,
									rindex: rindex,
									cindex: cindex,
									bindex: bindex,
								}, '*');
					}			
			},
			duplicateRow(index, rindex, section){
				Object.keys(this.page[this.edit_area]).forEach(val => {
				this.page[this.edit_area][val].sections[index].rows.splice(rindex, 0, JSON.parse(JSON.stringify(section)));
					});
				this.sendPagetoIframe();
					if(this.mobilePreview == true){
					parent.postMessage({
									action: 'duplicateRow',
									message: 'duplicateRow',
									section: JSON.parse(JSON.stringify(section)),
									sindex: index,
									rindex: rindex,
								}, '*');
					}			
			},
			duplicateSection(index, section){
				Object.keys(this.page[this.edit_area]).forEach(val => {
				this.page[this.edit_area][val].sections.splice(index, 0, JSON.parse(JSON.stringify(this.page[this.edit_area][val].sections[index])));
					});
					if(this.mobilePreview == true){
					parent.postMessage({
									action: 'duplicateSection',
									message: 'duplicateSection',
									section: JSON.parse(JSON.stringify(section)),
									sindex: index,
								}, '*');
					}			
			},
			deleteSection(index){
				if((index == 0) && (this.page[this.edit_area][this.viewportSize].sections.length == 1)){
					Swal.fire("You can't delete this", "Every page needs at least one section!", "error");
				}else{
					Object.keys(this.page[this.edit_area]).forEach(val => {
						this.page[this.edit_area][val].sections.splice(index, 1);
					});
					if(this.mobilePreview == true){
					parent.postMessage({
									action: 'deleteSection',
									message: 'deleteSection',
									sindex: index,
								}, '*');
					}				
				}
			},
			selectPageTemplate(){
				this.getPageTemplates();
				this.showPageTemplates = true;
			},
			getPageTemplates(){

			},
			closeWidgetBox(state){
				if(this.mobilePreview == true){
					const iframe = this.$refs.previewIframe;
					iframe.contentWindow.postMessage({
	        action: 'closeWidgetBox',
	        value: state
	      		}, '*');
				}else{	
						this.showWidgetBox = false;
						this.widgetBoxEditContent = [];
						this.widgetBoxEditMode = false;
						this.loadingWidgetSettings = state;
					
				}
			},
			getPage(){
				axios.get('/api/company/web/template/'+this.$attrs.id, {headers: {
				'Authorization' : 'Bearer '+this.$attrs.user.api_token
				}}).then(response => {
					this.page = response.data;
					if(this.page[this.edit_area] == null){
						this.page[this.edit_area] = JSON.parse(JSON.stringify(this.page_setup));
					};
					this.selectedFonts = this.page.fonts;
				}).catch(error => {
					this.error_reponse = error.response.error;
					this.error_message = true;
				});
			},
			savePage(){
				//var postForm = new FormData(document.getElementById("page-form"));
				//this.page.append('thumbnail', this.page.thumbnail);
				//postForm.append('header', JSON.stringify(this.page.header));
				//postForm.append('footer', JSON.stringify(this.page.footer));
				//postForm.append('content', JSON.stringify(this.page.content));

				if(this.mobilePreview == false){
					const iframe = this.$refs.previewIframe;
					iframe.contentWindow.postMessage({
	        action: 'savePage',
	        data_update: this.data,
	        sindex: this.sindex
	      		}, '*');
				}else{
					this.page.fonts = this.selectedFonts;
					axios.post('/api/company/web/template/create', this.page, {headers: {
						'Authorization' : 'Bearer '+this.$attrs.user.api_token
					}}).then(response => {
						this.page = response.data;
						
						var self = this;
		                setTimeout(function(){
		                 //Vue.swal("Avatar Updated");
		                 	Swal.fire("Saved", "Your page has been saved.", "success").then(result => {
		                 		if(self.closingPage == true){
		                 		window.location.replace('https://'+self.$attrs.company.nickname+'.gemesys.co.uk/web/templates')
		                 		}
		                 	});
		                 	//this.success_text = "Your page has been saved";
							//this.success_message = true;
		                }, 500);
					}).catch(error => {

					});
					}
			},
			updatePage(){
				if(this.mobilePreview == false){
					const iframe = this.$refs.previewIframe;
					iframe.contentWindow.postMessage({
	        action: 'updatePage',
	        data_update: this.data,
	        sindex: this.sindex
	      		}, '*');
				}else{
					this.page.fonts = this.selectedFonts;
				axios.post('/api/company/web/template/'+this.page.uid+'/update', {area: this.edit_area, template: this.page}, {headers: {
					'Authorization' : 'Bearer '+this.$attrs.user.api_token
				}}).then(response => {
					//this.page = response.data;
					var self = this;
	                setTimeout(function(){
	                 //Vue.swal("Avatar Updated");
	                 	Swal.fire("Saved", "Your page has been saved.", "success").then(result => {
	                 		if(self.closingPage == true){
	                 		window.location.replace('https://'+self.$attrs.company.nickname+'.gemesys.co.uk/web/templates')
	                 		}
	                 	});
	                 	
	                 	//this.success_text = "Your page has been saved";
						//this.success_message = true;
	                }, 500);
				}).catch(error => {

				});
					}
			},
			changeView(){
				document.body.classList.add("bg-white");
			},
			triggerEvent(event){
				EventBus.$emit(event, true);
			},
			deletePage(){

			},
			imageAdded(file){
	            this.page.thumbnail = file;
	        },
	        areYouSure(){
	        	this.closingPage = true;
	        	Swal.fire({
		        title: "Are you sure?",
		        text: "Make sure you have saved your work",
		        icon: "warning",
		        allowOutsideClick: false,
		        showCancelButton: true,
		        confirmButtonColor: "#34c38f",
		        cancelButtonColor: "#f46a6a",
		        confirmButtonText: "Save and Leave",
		        cancelButtonText: "Leave without Saving"
		      }).then(result => {
		        if (result.value) {
		        	if(this.$attrs.id !== ''){
		          	this.updatePage();
		        	}else{
		        		this.savePage();
		        	}

		          //await async function()
		        }else{
		        	let timerInterval;
		        	Swal.fire({
				        title: "Redirecting you now",
				        html: "Your page has not been saved.",
				        timer: 500,
				        timerProgressBar: true,
				        onBeforeOpen: () => {
				          //Swal.showLoading();
				          timerInterval = setInterval(() => {
				            Swal.getContent().querySelector(
				              "b"
				            ).textContent = Swal.getTimerLeft();
				          }, 100);
				        },
				        onClose: () => {
				          window.location.replace('https://'+this.$attrs.company.nickname+'.gemesys.co.uk/web/templates')
				        }
      				})
		        }
		      });
	        },
	        compiledStyles(styles){
	        	if(styles){
	        	var style = 'will-change: auto; ';
	        	for(var i = 0; i < (styles.length - 1); i++ ){
	        		for(var key in styles[i] ){
	        			
	        		if(styles[i][key].active == true){
		        		if(key.includes('_label') == false){

		        			if(key == 'background'){
		        				//var stylename = key.replace('_', '-');
		        				if(styles[i][key].value[0] == 'background-image'){
		        					style += ''+styles[i][key].value[0]+': url('+styles[i][key].value[1].replaceAll(' ', '%20')+'); '
		        				}else{
		        					style += ''+styles[i][key].value[0]+': '+styles[i][key].value[1]+'; '
		        				}
		        				style += 'background-size: '+styles[i][key].size+'; '
		        				style += 'background-position: '+styles[i][key].position+'; '
		        				style += 'background-repeat: '+styles[i][key].repeat+'; '
		        			}
		        			else if(styles[i][key].type == 'numbergroup'){
		        				//var stylename = key.replace('_', '-');
		        				for(var numgroupkey in styles[i][key]){
		        					if((numgroupkey !== 'parentlabel') || (numgroupkey !== 'type')){
		        						if((key.includes('_radius') == false) && (key.includes('border') == true)){

		        							style += ''+key+'-'+numgroupkey.replace('_','-')+': '+styles[i][key][numgroupkey]+'px '+styles[this.filterStylesKey(styles, 'border_style')]["border_style"].value+' '+styles[this.filterStylesKey(styles, 'border_colour')]["border_colour"].value+' !important; '

		        						}else	if(key.includes('_radius') == false){

		        							style += ''+key+'-'+numgroupkey.replace('_','-')+': '+styles[i][key][numgroupkey]+'px !important; '
		        						
		        						}else{
		        						
		        							style += ''+key.replace('_radius', '')+'-'+numgroupkey.replace('_','-')+'-radius: '+styles[i][key][numgroupkey]+'px !important; '
		        						
		        						}
		        					}
		        				}
		        			}
		        			else if(styles[i][key].type == 'range'){
		        					if('units' in styles[i][key]){
		        						//console.log(styles[i][key].value);
		        						if(styles[i][key].value !== null){
			        						var stylename = key.replace('_', '-');
					        				style += ''+stylename+': '+parseFloat(styles[i][key].value)+''+styles[i][key].units+'; '; 
				        					}
		        					 }else{
		        					 		style += ''+stylename+': '+styles[i][key].value+'; '
		        					 }

		        			}else{
		        				var stylename = key.replace('_', '-');
		        				stylename = stylename.replace('colour', 'color');
		        				if(styles[i][key].value.length !== 0){
		        					style += ''+stylename+': '+styles[i][key].value+' !important; '
		        				}else{
		        					style += ''+stylename+': '+styles[i][key].value+' !important; '
		        				}
		        			}
		        		}
	        		}
	        	}
	        }
	        	return style;
	        }else{
	        	return '';
	        }
	        },
	        filterStylesKey(styles, key){
       		for(var i = 0; i < styles.length; i++){
       			 var keys = Object.keys(styles[i])
       			 if(keys.includes(key)){
       			 	 return i;
       			 }
       		}
       },
	         windowResize(e) {
            console.log(window.innerHeight);
    				this.contentWidth = window.innerWidth;
            this.sidePanelMaxHeight = window.innerHeight - 80;
   					 	if(this.contentWidth <= 576){
								this.viewportSize = 'xs';
							}else if(this.contentWidth <= 769){
								this.viewportSize = 'sm';
							}else if(this.contentWidth <= 992){
								this.viewportSize = 'md';
							}else if(this.contentWidth <= 1200){
								this.viewportSize = 'lg';					
							}else if(this.contentWidth > 1200){
								this.viewportSize = 'xl';
							}
           
          },
          sanitizeTitle: function(title) {
            var nickname = "";
            // Change to lower case
            var titleLower = title.toLowerCase();
            // Letter "e"
            nickname = titleLower.replace(/e|é|è|ẽ|ẻ|ẹ|ê|ế|ề|ễ|ể|ệ/gi, 'e');
            // Letter "a"
            nickname = nickname.replace(/a|á|à|ã|ả|ạ|ă|ắ|ằ|ẵ|ẳ|ặ|â|ấ|ầ|ẫ|ẩ|ậ/gi, 'a');
            // Letter "o"
            nickname = nickname.replace(/o|ó|ò|õ|ỏ|ọ|ô|ố|ồ|ỗ|ổ|ộ|ơ|ớ|ờ|ỡ|ở|ợ/gi, 'o');
            // Letter "u"
            nickname = nickname.replace(/u|ú|ù|ũ|ủ|ụ|ư|ứ|ừ|ữ|ử|ự/gi, 'u');
            // Letter "d"
            nickname = nickname.replace(/đ/gi, 'd');
            // Trim the last whitespace
            nickname = nickname.replace(/\s*$/g, '');
            // Change whitespace to "-"
            nickname = nickname.replace(/\s+/g, '-');
            // Remove quotations
            nickname = nickname.replace(/["']/g, "");
            
            return nickname;
        },
		}
	}
</script>

<!--<template>
	<form id="page-form" method="post" enctype="multipart/form-data" @submit.prevent="">
	<draggable handle=".sechandle"
				tag="div"
				class="page-builder-element-outline position-relative"
  				:list="page[edit_area].sections"
                      ghost-class="ghost" v-for="(section, sindex) in page[edit_area].sections" :class="section.styles.containerStyle.value" >
                      <span class="page-builder-tab text-white bg-secondary bg-soft top-0 sechandle z-index-1">Section <i class="bx bx-move"></i></span>
                    <span class="page-builder-tools text-white bg-secondary bg-soft top-0 d-flex px-2">
                    	<a @click="deleteSection(sindex)" :disabled="page[this.edit_area].sections.length < 2" class="mr-auto" href="javascript:;"><i class="mdi mdi-close"></i></a>
                    	<a @click="sectionSettings = sindex, sectionStyles_raw = JSON.parse(JSON.stringify(section.styles))" class="mx-auto" href="javascript:;"><i class="bx bx-pencil"></i></a>

                    	
                    </span>
                     <b-card class="page-builder-section-settings border border-radius-1em shadow-none position-absolute right-0 px-0" body-class="px-0" v-if="sectionSettings == sindex">
                    	<simplebar style="max-height: 450px; overflow-x: hidden;">
                    		<b-row>
                    			<b-col md="12">
	                    				<b-tabs justified nav-class="nav-tabs-custom" content-class="pb-3 pt-0 text-muted">
	                         			<b-tab >
	                         			 <template v-slot:title>
										                  <span class="d-inline-block d-sm-none">
										                    <i class="bx bx-notepad"></i>
										                  </span>
										                  <span class="d-none d-sm-inline-block">Design</span>
										                </template>
										                <b-card class="page-builder-section-settings border-radius-1em w-100" body-class="px-0 pt-0">
										                	<b-list-group>
											               <b-list-group-item v-for="(value, key) in section.styles" :id="'settings-'+key" :key="key">
											               	<b-form-group class="text-uppercase" :label="key.replace(/_/g, ' ')">
											               
    																		 
    																	<div class="d-flex" v-if="section.styles[key].type == 'range'">
											               		<b-form-input type="number"  v-model="section.styles[key].value" :max="2400"></b-form-input>
											               		 <b-form-radio-group v-model="section.styles[key].units" buttons button-variant="outline-primary" size="sm" >
    																		  	<b-form-radio value="px">px</b-form-radio>
    																		  	<b-form-radio value="%">%</b-form-radio>
    																		  </b-form-radio-group>
    																	</div>
    																	<div class="d-flex" v-else-if="section.styles[key].type == 'numbergroup'">
    																		<b-form-group label="" description="LEFT" class="mr-1">
											               			<b-form-input type="number"  v-model="section.styles[key].left" :min="0"></b-form-input>
											               		</b-form-group>
											               		<b-form-group description="RIGHT" class="mx-1">
																				<b-form-input type="number"  v-model="section.styles[key].right" :min="0"></b-form-input>
																				</b-form-group>
																				<b-form-group description="TOP" class="mx-1">
																				<b-form-input type="number"  v-model="section.styles[key].top" :min="0"></b-form-input>
																			</b-form-group>
																			<b-form-group description="BOTTOM" class="ml-1">
																				<b-form-input type="number"  v-model="section.styles[key].bottom" :min="0"></b-form-input>
																				</b-form-group>								               		
    																	</div>
    																	<div class="d-flex" v-else-if="section.styles[key].type == 'select'">
    																		<b-form-group class="w-100">
    																			<b-form-select class="form-control text-capitalize" v-model="section.styles[key].value" :options="section.styles[key].options">
    																			</b-form-select>
    																		</b-form-group>
    																	</div>

											               		<b-form-input v-else class="w-100 mt-2"  :type="section.styles[key].type" v-model="section.styles[key].value"></b-form-input>
											               	</b-form-group>
											               	
											               </b-list-group-item>
										             	 </b-list-group>
										                </b-card>
										               </b-tab>

										            </b-tabs>
                    			</b-col>
                    		</b-row>
                    	</simplebar>
                    	<b-row class="row position-absolute bottom-0 right-0 left-0 bg-white py-3 z-index-1">
                    			<b-col md="6">
                    				<a class="btn btn-danger btn-rounded btn-block" @click="sectionSettings = null, section.styles = JSON.parse(JSON.stringify(sectionStyles_raw))">
                    					Cancel
                    				</a>
                    			</b-col>
                    			<b-col md="6">
                    				<a class="btn btn-success btn-rounded btn-block" @click="sectionSettings = null">
                    					Save
                    				</a>
                    			</b-col>
                    		</b-row>

                    </b-card>
	 <PageBuilder :user="$attrs.user" :data="section" :widgets="webPageWidgets" :row_limit="50" :col_limit="50" :preview="false" :allow_preview="false" :company="$attrs.company" @add-section="addSection" :sidePanelMaxHeight="sidePanelMaxHeight"></PageBuilder>
	</draggable>
	<b-container fluid class="position-fixed bottom-0 left-0 right-0 mb-3">
		<b-row>
			<b-col class="d-flex"> 
				<a class="mr-auto my-auto btn btn-primary btn-rounded" @click="pageSettings = !pageSettings">
					<i class="bx bx-cog">
					</i>
					<span>
						Page Settings
					</span>
				</a>
			</b-col>
			<b-col>
				
			</b-col>
			<b-col class="d-flex">
				<a class="ml-auto mr-2 my-auto btn btn-outline-danger btn-rounded" @click="areYouSure()">
					<i class="bx bx-chevron-left">
					</i>
					<span>
						Back to Pages
					</span>
					
				</a>
				<a class="mr-2 my-auto btn btn-info btn-rounded" @click="activatePreviewMode()">
					<i class="mdi mdi-eye-outline">
					</i>
					<span>
						Preview
					</span>
					
				</a>
				<a class="my-auto btn btn-success btn-rounded" v-if="$attrs.id == ''" @click="savePage()">	
					<span>
						Save
					</span>
					<i class="bx bx-save">
					</i>
				</a>
				<a class="my-auto btn btn-success btn-rounded" v-else @click="updatePage()">
					
					<span>
						Update
					</span>
					<i class="bx bx-save">
					</i>
				</a>
			</b-col>
		</b-row>
	</b-container>
	<transition name="fade-left">
		<div v-if="pageSettings == true" class="z-index-2 page-options position-fixed top-0 left-0 bottom-0 bg-white shadow" style="width: 460px;">
			<div class="w-100 top-0 d-flex mt-2 mb-4">
			<a class="mr-3 ml-auto my-auto btn btn-outline-light btn-rounded float-right" @click="pageSettings = !pageSettings">
					<i class="mdi mdi-close">
					</i>
					
				</a>
			</div>
			
				<b-tabs pills justified class="px-3">
					<b-tab title="General">
						<b-card class="border-radius-1em border shadow-none h-100 mt-3">
							<b-form-group label="Name" class="mt-2">
								<b-form-input type="text" v-model="page.name"></b-form-input>
							</b-form-group>
							<b-form-group label="Url" class="mt-2">
								<b-form-input id="metatitle" name="url_rewrite" type="text" class="form-control" v-model="page.url_rewrite" :value="sanitizeTitle(page.name)" ></b-form-input></b-form-group>
							</b-form-group>
							<b-form-group label="Description" class="mt-2">
								<b-form-textarea type="text" v-model="page.description"></b-form-textarea>
							</b-form-group>
							<b-form-group label="Page Thumbnail" class="mt-2">
								<vue-dropzone id="dropzone" ref="myVueDropzone" :use-custom-slot="true" :options="dropzoneOptions" @vdropzone-file-added="imageAdded"  @vdropzone-drop="imageAdded">
			                        <div class="dropzone-custom-content">
			                            <i class="display-4 text-muted bx bxs-cloud-upload"></i>
			                            <h4>Drop files here or click to upload.</h4>
			                        </div>
			                        </vue-dropzone>
							</b-form-group>
							<input type="hidden" name="thumbnail" v-model="page.thumbnail" />
							<b-form-group label="Active" class="mt-2">
												 <div
				                        class="form-check form-switch form-switch-lg w-75 d-flex"
				                        dir="ltr"
				                      >
				                 
				                      <input
				                          class="form-check-input ml-auto my-auto" 
				                          type="checkbox"
				                          id="SwitchCheckSizesm"
				                         
				                          v-model="page.active"
				                        
				                          name="active"
				                        />
				                    </div>
							</b-form-group>
							
						</b-card>
					</b-tab>
					
					<b-tab title="Template">
						<b-card class="border-radius-1em border shadow-none h-100 mt-3">
							<b-row>
								<b-col>
									<h6>Current Template</h6>
									<b-card v-if="page.company_web_page_template_id !== ''" class="border-radius-1em border shadow-none">
									</b-card>
									<b-card v-else class="border-radius-1em border shadow-none" body-class="d-flex">
										<div>
										<h6 class="mb-0">No Template Selected</h6>
										<span class="text-muted font-italic">Using Default Template</span>
										</div>
										<div class="d-flex ml-auto">
											<a class="btn btn-primary ml-auto btn-rounded my-auto" @click="selectPageTemplate()">Select New <i class="bx bx-chevron-down"></i></a>
										</div>
									</b-card>
								</b-col>
							</b-row>
							<transition name="fade-top">
								<b-row v-if="showPageTemplates == true">
									<b-col md="12">
										<h6>Select New Template</h6>
									</b-col>
									<b-col md="6" v-for="template in pageTemplates">
										<b-card class="border-radius-1em border shadow-none" body-class="p-0">
										<b-card-img v-if="template.thumbnail" :src="template.thumbnail"></b-card-img>
										<b-card-img v-else :src="'/images/product-placeholder.png'"></b-card-img>
										<b-card-title class="p-2">{{template.name}}</b-card-title>
										<b-card-text class="p-2">{{template.description}}</b-card-text>
										</b-card>
									</b-col>
								</b-row>
							</transition>
						</b-card>
					</b-tab>
				
					<b-tab title="Advanced">
						<b-card class="border-radius-1em border shadow-none h-100 mt-3">
							<b-form-group label="Custom CSS Classes" >
								<b-form-input type="text" ></b-form-input>
							</b-form-group>
							<b-form-group label="Custom CSS" class="mt-2">
								<b-form-textarea type="text" rows="6"></b-form-textarea>
							</b-form-group>
							<b-form-group label="Custom JS" class="mt-2">
								<b-form-textarea type="text" rows="6"></b-form-textarea>
							</b-form-group>
						</b-card>
					</b-tab>
				</b-tabs>
		</div>
	</transition>
	</form>
</template>-->


<template>
	<form id="page-form" method="post" enctype="multipart/form-data" @submit.prevent="">
		<transition name="fade-left">
			<div class="widget-box shadow-sm bg-white px-0 py-0" v-if="showPreview == true && layerNav == true">
				<div class="d-flex w-100  border-primary border-bottom text-center bg-primary py-3">
                          <h5 class="text-white my-auto ml-4">Page Layers</h5>
                          <a class="btn btn-primary btn-rounded ml-auto my-auto mr-4" href="javascript:;" @click="layerNav = false"><i class="mdi mdi-close noti-icon font-size-14"></i></a>
                        </div>
                        
							<BuilderLayerNav  :sections="page[edit_area][viewportSize].sections" @layers-updated="page[edit_area][viewportSize].sections = $event, sendPagetoIframe()" >
							</BuilderLayerNav>
				
		</div>
	</transition>
	<transition name="fade-left">
		<widgetEditBox v-if="showPreview == true && showWidgetBox == true" :widgetBoxEditContent="widgetBoxEditContent" :widgetBox="widgetBox" :sindex="sindex" :rindex="rindex" :cindex="cindex" :bindex="bindex" :widgetBoxLoading="widgetBoxLoading" :widgetBoxEditMode="widgetBoxEditMode" :widgetBoxSubForm="widgetBoxSubForm"  :fileResource="fileResource" :fileRowIndex="fileRowIndex" :fileColIndex="fileColIndex" :showFileManager="showFileManager" :user="$attrs.user" :company="$attrs.company" :data="data" :selectedFiles="selectedFiles" :widgets="widgets" :template_areas="template_areas" :sidePanelMaxHeight="sidePanelMaxHeight" :widgetStyles="widgetStyles" :widgetSearch="widgetSearch" :widgetEditBlockIndex="widgetEditBlockIndex" @close-widget-editbox="widgetBox = '', widgetBoxEditMode = false,	widgetEditBlockIndex = null,	widgetBoxEditContent = []" :viewPortSize="viewportSize" :windowContentWidth="contentWidth"></widgetEditBox>
	</transition>
		<div class="sidebar left-hand" v-show="(showPreview == true) && (activateViewPortSettings == true)">
    <a class="sidebar-handle left-hand" @mousedown="startResizeLeft" @mouseup="stopResizeLeft" v-b-tooltip.hover.left :title="contentWidth"></a>
  </div>
  <div v-if="previewUrl !== false">
  	<b-overlay :show="loadingInnerWindow"  variant="white" opacity="0">
                    		<template #overlay>
					<b-row>
	      			<b-col md="12" class="offset-md-2" >
	      			<b-card class="border-radius-1em border shadow-none mb-0">	
			            <div class="cube-wrapper mx-auto"><div class="cube-folding"><span class="leaf1"></span><span class="leaf2"></span><span class="leaf3"></span><span class="leaf4"></span></div></div>
			  
			            <h6 class="text-center">Loading Template</h6>
			             <b-progress class="w-50 mx-auto" :value="100" variant="primary"></b-progress>
	         		</b-card>
	      			</b-col>
	      			</b-row>
	      		</template>
                    
  	    <iframe seamless ref="previewIframe" id="preview-iframe" :src="'https://'+$attrs.company.nickname+'.gemesys.co.uk/web/template/'+$attrs.id+'?action='+action_type+'&section=' + (edit_area !== 'content' ? edit_area : 'body') + '&preview=true'" frameborder="0" width="100%" style="height: 100vh;"></iframe>
  	    	</b-overlay>
  </div>
	
                    
	 <PageBuilder ref="PageBuilder" :user="$attrs.user" :data="section" :widgets="webPageWidgets" :template_areas="templateWidgets.filter(item => item.widgetresource == page.resource)" :row_limit="50" :col_limit="50" :preview="false" :allow_preview="false" :company="$attrs.company" @add-section="addSection" :sidePanelMaxHeight="sidePanelMaxHeight" @show-section-tools="showSectionTools = sindex" @hide-section-tools="showSectionTools = -1"  @sidepanel-opened="openSidepanel" :sindex="sindex" :exchange_rates="$attrs.exchange_rates"  :currencies="$attrs.currencies" :selected_currency="$attrs.selected_currency" :selected_currency_symbol="$attrs.selected_currency_symbol" :widgetBox="widgetBox" :loadingWidgetSettings="loadingWidgetSettings" :viewPortSize="viewportSize" :page="page[edit_area]" ></PageBuilder>
	

	<transition name="fade-right">
                     <b-card class="page-builder-section-settings border-none shadow-none position-fixed right-0 top-0 bottom-0 px-0 mb-0 shadow-none" body-class="px-0 pt-0" v-if="(sectionSettings == stylessindex)  && (stylesEditArea == 'section')">
                     	<widgetViewportSwitcher :viewPortSize="viewportSize" :windowWidth="contentWidth" :area="stylesEditArea" @copy-to-viewport="copyAcrossViewport" ></widgetViewportSwitcher>
                    	<simplebar style="overflow-x: hidden;" :style="{'max-height' : sidePanelMaxHeight+'px'}">
                    		<b-row>
                    			<b-col md="12" class="d-flex py-3 bg-success">
                    				<h5 class="text-white my-auto ml-4">Section Options</h5>
                    				<a class="btn btn-success btn-rounded ml-auto mr-3"  @click="sectionSettings = null, sectionStyles_editing = JSON.parse(JSON.stringify(sectionStyles_raw))"><i class="mdi mdi-close"></i></a>
                    			</b-col>
                    			<b-col md="12">
	                    				<b-tabs justified nav-class="nav-tabs-custom success" content-class="pb-3 pt-0 text-muted">
	                         
										               <stylesTab @styles-updated="sendStylesToIframe('section', $event)" :user="$attrs.user" :company="$attrs.company" :styles="sectionStyles_editing" :widgetbox="false" @showFileManager="" @change="alert('change component')"  :element="'section'" />
										            </b-tabs>
                    			</b-col>
                    		</b-row>
                    	</simplebar>
                    	<b-row class="row position-absolute bottom-0 right-0 left-0 bg-white py-3 z-index-1">
                    			<b-col md="6">
                    				<a class="btn btn-danger btn-rounded btn-block" @click="sectionSettings = null, sectionStyles_editing = JSON.parse(JSON.stringify(sectionStyles_raw))">
                    					Cancel
                    				</a>
                    			</b-col>
                    			<b-col md="6">
                    				<a class="btn btn-success btn-rounded btn-block" @click="sendDataToIframe(data), sectionSettings = null">
                    					Save
                    				</a>
                    			</b-col>
                    		</b-row>

                    </b-card>
                  </transition>
                  <transition name="fade-right">
                    <b-card class="page-builder-section-settings border-none shadow-none position-fixed right-0 top-0 bottom-0 px-0 mb-0 shadow-none" body-class="px-0 pt-0" v-if="(sectionSettings == stylessindex + '-' + stylesrindex)  && (stylesEditArea == 'row')">
                    	<widgetViewportSwitcher :viewPortSize="viewportSize" :windowWidth="contentWidth" :area="stylesEditArea" @copy-to-viewport="copyAcrossViewport" ></widgetViewportSwitcher>
                    	<simplebar style="overflow-x: hidden;" :style="{'max-height' : sidePanelMaxHeight+'px'}">
                    		<b-row>
                    			<b-col md="12" class="d-flex py-3 bg-primary">
                    				<h5 class="text-white my-auto ml-4">Row Options</h5>
                    				<a class="btn btn-primary btn-rounded ml-auto mr-3"  @click="sectionSettings = null, sectionStyles_editing = JSON.parse(JSON.stringify(sectionStyles_raw))"><i class="mdi mdi-close"></i></a>
                    			</b-col>
                    			<b-col md="12">
	                    				<b-tabs justified nav-class="nav-tabs-custom primary" content-class="pb-3 pt-0 text-muted">
	                         
										               <stylesTab @styles-updated="sendStylesToIframe('row', $event)" :user="$attrs.user" :company="$attrs.company" :styles="sectionStyles_editing" :widgetbox="false" @showFileManager=""  :element="'row'" />
										            </b-tabs>
                    			</b-col>
                    		</b-row>
                    	</simplebar>
                    	<b-row class="row position-absolute bottom-0 right-0 left-0 bg-white py-3 z-index-1">
                    			<b-col md="6">
                    				<a class="btn btn-danger btn-rounded btn-block" @click="sectionSettings = null, sectionStyles_editing = JSON.parse(JSON.stringify(sectionStyles_raw))">
                    					Cancel
                    				</a>
                    			</b-col>
                    			<b-col md="6">
                    				<a class="btn btn-success btn-rounded btn-block" @click="sendDataToIframe(data), sectionSettings = null">
                    					Save
                    				</a>
                    			</b-col>
                    		</b-row>

                    </b-card>
                  </transition>
                  <transition name="fade-right">
                    <b-card class="page-builder-section-settings border-none shadow-none position-fixed right-0 top-0 bottom-0 px-0 mb-0 shadow-none" body-class="px-0 pt-0" v-if="(sectionSettings == stylessindex + '-' + stylesrindex + '-' + stylescindex) && (stylesEditArea == 'col')">
                    	<widgetViewportSwitcher :viewPortSize="viewportSize" :windowWidth="contentWidth" :area="stylesEditArea" @copy-to-viewport="copyAcrossViewport" ></widgetViewportSwitcher>
                    	<simplebar style="overflow-x: hidden;" :style="{'max-height' : sidePanelMaxHeight+'px'}">
                    		<b-row>
                    			<b-col md="12" class="d-flex py-3 bg-info">
                    				<h5 class="text-white my-auto ml-4">Column Options</h5>
                    				<a class="btn btn-info btn-rounded ml-auto mr-3"  @click="sectionSettings = null, sectionStyles_editing = JSON.parse(JSON.stringify(sectionStyles_raw))"><i class="mdi mdi-close"></i></a>
                    			</b-col>
                    			<b-col md="12">
	                    				<b-tabs justified nav-class="nav-tabs-custom info" content-class="pb-3 pt-0 text-muted">
	                         
										               <stylesTab @styles-updated="sendStylesToIframe('col', $event)" :user="$attrs.user" :company="$attrs.company" :styles="sectionStyles_editing" :widgetbox="false" @showFileManager="" :element="'column'" />
										            </b-tabs>
                    			</b-col>
                    		</b-row>
                    	</simplebar>
                    	<b-row class="row position-absolute bottom-0 right-0 left-0 bg-white py-3 z-index-1">
                    			<b-col md="6">
                    				<a class="btn btn-danger btn-rounded btn-block" @click="sectionSettings = null, sectionStyles_editing = JSON.parse(JSON.stringify(sectionStyles_raw))">
                    					Cancel
                    				</a>
                    			</b-col>
                    			<b-col md="6">
                    				<a class="btn btn-success btn-rounded btn-block" @click="sendDataToIframe(data), sectionSettings = null">
                    					Save
                    				</a>
                    			</b-col>
                    		</b-row>

                    </b-card>
                  </transition>
	<b-container fluid class="position-fixed bottom-0 left-0 right-0 mb-3 d-flex z-index-3" v-if="showPreview == true">
		<a class="btn btn-primary mr-2 rounded-circle my-auto" @click="showSettingsBar = !showSettingsBar">
					<i class="bx bx-dots-horizontal-rounded text-white font-size-16 my-1"></i>
				</a>
		<b-row class="w-100">
			
				
			<transition name="fade-left">
			<b-col md="4" sm="4" class="d-flex" v-if="showSettingsBar == true"> 
				<a class="mr-2 my-auto btn btn-primary btn-rounded font-size-14 d-flex my-auto" v-b-tooltip.hover.top title="Settings" @click="pageSettings = !pageSettings">
					<i class="bx bx-cog font-size-16 my-1">
					</i>
				</a>
				<div class="d-flex">
					<a v-b-tooltip.hover.top title="Page Layout" class="btn btn-primary mr-1 rounded-circle my-auto" @click="layerNav = !layerNav" >
					<i class="bx bx-layer text-white font-size-16 my-1"></i>
				</a>
					<a v-b-tooltip.hover.top title="Fullwidth" class="btn btn-primary mr-1 rounded-circle my-auto" @click="changeViewSize(windowInnerWidth), activateViewPortSettings = false" :class="[contentWidth == windowInnerWidth ? 'bg-info border-info' : '']">
					<i class="bx bx-fullscreen text-white font-size-16 my-1"></i>
				</a>
				<a v-b-tooltip.hover.top title="Desktop" class="btn btn-primary mr-1 rounded-circle my-auto" @click="changeViewSize(1300), activateViewPortSettings = true" :class="[windowInnerWidth > 1200 ? 'bg-success border-success' : '', contentWidth > 1200 ? 'bg-info border-info' : '']">
					<i class="bx bx-desktop text-white font-size-16 my-1"></i>
				</a>
					<a v-b-tooltip.hover.top title="Laptop" class="btn btn-primary mr-1 rounded-circle my-auto" @click="changeViewSize(1199), activateViewPortSettings = true" :class="[(windowInnerWidth > 991) && (windowInnerWidth < 1200) ? 'bg-success border-success' : '', (contentWidth > 991) && (contentWidth < 1200) ? 'bg-info border-info' : '']">
					<i class="bx bx-laptop text-white font-size-16 my-1"></i>
				</a>
				<a v-b-tooltip.hover.top title="Tablet" class="btn btn-primary mr-1 rounded-circle my-auto" @click="changeViewSize(990), activateViewPortSettings = true" :class="[(windowInnerWidth > 769) && (windowInnerWidth < 991) ? 'bg-success border-success' : '', (contentWidth > 769) && (contentWidth < 991) ? 'bg-info border-info' : '']">
					<i class="bx bx bx-tab text-white font-size-16 my-1"></i>
				</a>
				<a v-b-tooltip.hover.top title="Large Mobile" class="btn btn-primary mr-1 rounded-circle my-auto" @click="changeViewSize(768), activateViewPortSettings = true" :class="[(windowInnerWidth > 575) && (windowInnerWidth < 769) ? 'bg-success border-success' : '', (contentWidth > 575) && (contentWidth < 769) ? 'bg-info border-info' : '']">
					<i class="bx bx bx-mobile-alt text-white font-size-16 my-1"></i>
				</a>
				<a v-b-tooltip.hover.top title="Small Mobile" class="btn btn-primary mr-1 rounded-circle my-auto" @click="changeViewSize(350), activateViewPortSettings = true" :class="[windowInnerWidth < 576 ? 'bg-success border-success' : '', contentWidth < 576 ? 'bg-info border-info' : '']">
					<i class="bx bx-mobile text-white font-size-16 my-1"></i>
				</a>
				</div>
				
			
				
			</b-col>
		</transition>
			<transition name="fade-right">
			<b-col md="8" sm="8" class="d-flex" v-if="showSettingsBar == true">
				<a class="ml-auto mr-2 my-auto btn btn-info btn-rounded d-flex" @click="areYouSure()">
					<i class="bx bx-chevron-left font-size-16 my-1 mr-2">
					</i>
					<span class="my-auto">
						Back
					</span>
					
				</a>
				<!--<a v-if="showPreview == true" class="mr-2 my-auto btn btn-info btn-rounded" @click="activatePreviewMode()">
					<i class="mdi mdi-eye-outline">
					</i>
					<span>
						Preview
					</span>
					
				</a>
				<a class="mr-2 my-auto btn btn-info btn-rounded" @click="showPreview = false, previewContent = ''" v-else-if="showPreview == false">
					<i class="mdi mdi-close">
					</i>
					<span>
						Close Preview
					</span>
										
				</a>-->
				<a class="my-auto btn btn-success btn-rounded d-flex" v-if="$attrs.id == ''" @click="savePage()">	
					<span class="my-auto">
						Save
					</span>
					<i class="bx bx-save font-size-16 my-1 ml-2">
					</i>
				</a>
				<a class="my-auto btn btn-success btn-rounded d-flex" v-else @click="updatePage()">
					
					<span class="my-auto">
						Update
					</span>
					<i class="bx bx-save font-size-16 my-1 ml-2">
					</i>
				</a>
			</b-col>
		</transition>
		</b-row>
	</b-container>
	<transition name="fade-left">
		<div v-if="pageSettings == true" class="z-index-10 page-options position-fixed top-0 left-0 bottom-0 bg-white shadow" style="width: 460px;">
			<!--<div class="w-100 top-0 d-flex mt-2 mb-4">
			<a class="mr-3 ml-auto my-auto btn btn-outline-light btn-rounded float-right" @click="pageSettings = !pageSettings">
					<i class="mdi mdi-close">
					</i>
					
				</a>
			</div>-->
			<b-row class="bg-white">
			<b-col md="12" class="d-flex py-3 bg-primary">
                    				<h5 class="text-white my-auto ml-4">Template Options</h5>
                    				<a class="btn btn-primary btn-rounded ml-auto mr-3"  @click="pageSettings = !pageSettings"><i class="mdi mdi-close"></i></a>
                    			</b-col>
                   
        <b-col md="12" class="pt-2">
				<b-tabs justified content-class="px-3" nav-class="nav-tabs-custom">
					<b-tab title="General">
						<b-card class="border-radius-1em border shadow-none h-100 mt-3">
							<b-form-group label="Template Name" class="mt-2">
								<b-form-input type="text" v-model="page.name"></b-form-input>
							</b-form-group>
							<b-form-group label="Description" class="mt-2">
								<b-form-textarea type="text" v-model="page.description"></b-form-textarea>
							</b-form-group>
							<b-form-group label="Template Thumbnail" class="mt-2">
								<vue-dropzone id="dropzone" ref="myVueDropzone" :use-custom-slot="true" :options="dropzoneOptions" @vdropzone-file-added="imageAdded"  @vdropzone-drop="imageAdded">
			                        <div class="dropzone-custom-content">
			                            <i class="display-4 text-muted bx bxs-cloud-upload"></i>
			                            <h4>Drop files here or click to upload.</h4>
			                        </div>
			                        </vue-dropzone>
							</b-form-group>
							<input type="hidden" name="thumbnail" v-model="page.thumbnail" />
							<b-form-group label="Active" class="mt-2">
												 <div
				                        class="form-check form-switch form-switch-lg w-75 d-flex"
				                        dir="ltr"
				                      >
				                 
				                      <input
				                          class="form-check-input ml-auto my-auto" 
				                          type="checkbox"
				                          id="SwitchCheckSizesm"
				                         
				                          v-model="page.active"
				                        
				                          name="active"
				                        />
				                    </div>
							</b-form-group>
							
						</b-card>
					</b-tab>
					
					<!--<b-tab title="Template">
						<b-card class="border-radius-1em border shadow-none h-100 mt-3">
							<b-row>
								<b-col>
									<h6>Current Template</h6>
									<b-card v-if="page.company_web_page_template_id !== ''" class="border-radius-1em border shadow-none">
									</b-card>
									<b-card v-else class="border-radius-1em border shadow-none" body-class="d-flex">
										<div>
										<h6 class="mb-0">No Template Selected</h6>
										<span class="text-muted font-italic">Using Default Template</span>
										</div>
										<div class="d-flex ml-auto">
											<a class="btn btn-primary ml-auto btn-rounded my-auto" @click="selectPageTemplate()">Select New <i class="bx bx-chevron-down"></i></a>
										</div>
									</b-card>
								</b-col>
							</b-row>
							<transition name="fade-top">
								<b-row v-if="showPageTemplates == true">
									<b-col md="12">
										<h6>Select New Template</h6>
									</b-col>
									<b-col md="6" v-for="template in pageTemplates">
										<b-card class="border-radius-1em border shadow-none" body-class="p-0">
										<b-card-img v-if="template.thumbnail" :src="template.thumbnail"></b-card-img>
										<b-card-img v-else :src="'/images/product-placeholder.png'"></b-card-img>
										<b-card-title class="p-2">{{template.name}}</b-card-title>
										<b-card-text class="p-2">{{template.description}}</b-card-text>
										</b-card>
									</b-col>
								</b-row>
							</transition>
						</b-card>
					</b-tab>-->
				
					<b-tab title="Advanced">
						<b-card class="border-radius-1em border shadow-none h-100 mt-3">
							<b-form-group label="Custom CSS Classes" >
								<b-form-input type="text" ></b-form-input>
							</b-form-group>
							<label class="mt-2 text-muted">Custom CSS</label>
								<CodeEditor selector_height="200px" min_height="200px" max_height="400px"  :language_selector="false" :languages="[['css', 'CSS']]" theme="light" class="light bg-white w-100"></CodeEditor>
						
							
						</b-card>
					</b-tab>
				</b-tabs>
				</b-col>
       </b-row>
		</div>
	</transition>
		<!--<PageBuilderProcessor :content="previewContent" :company="$attrs.company" :user="$attrs.user" :domain="$attrs.company.default_domain" :exchange_rates="$attrs.exchange_rates"  :currencies="$attrs.currencies" :selected_currency="$attrs.selected_currency" :selected_currency_symbol="$attrs.selected_currency_symbol"></PageBuilderProcessor>-->
		<div class="sidebar right-hand" v-show="(showPreview == true) && (activateViewPortSettings == true)">
    <a class="sidebar-handle right-hand" @mousedown="startResizeRight" @mouseup="stopResizeRight" v-b-tooltip.hover.right :title="contentWidth"></a>
  </div>
	</form>
</template>

<style>
	
	.grecaptcha-badge{
		display: none !important;
	}
	.sidebar {
  width: 200px;
  height: 100%;
  background-color: #ccc;
  display: inline-block;
}

.sidebar-handle {
  width: 15px;
  height: 100%;
  background-color: rgba(75, 47, 141, 0.1) !important;
  cursor: ew-resize;
  display: inline-block;
}
	.left-hand {
  width: 0px;
  height: 100%;
  background-color: #ccc;
  display: inline-block;
  position: absolute;
  top: 0;
  left:-8px;
  z-index: 1;
}

.right-hand {
  width: 0px;
  height: 100%;
  background-color: #ccc;
  display: inline-block;
  position: absolute;
  top: 0;
  right:-8px;
  z-index: 1;
}

.sidebar-handle {
  width: 15px;
  height: 100%;
  background-color: rgba(75, 47, 141, 0.1) !important;
  cursor: ew-resize;
  display: inline-block;
}

.sidebar-handle.left-hand {
  cursor: w-resize;
}

.sidebar-handle.right-hand {
  cursor: e-resize;
}

</style>