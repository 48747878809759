<script type="text/javascript">
	import { EventBus } from '../../../../public-app'
	export default{
		data: function(){
			return{
				stripeAPIToken: process.env.MIX_STRIPE_KEY,
				cartLoading: false,
				orderCreated: false,
		      	cartPanelData: [],
		      	cartPanel: false,
		      	cart: {
			        products: [],
			        uid: '',
			        id: '',
			     },
			     stripe: '',
			     elements: '',
			     card: '',
			     cardholder_name: '',
			     cardholder_line1: '',
			     cardholder_line2: '',
			     cardholder_line3: '',
			     cardholder_city: '',
			     cardholder_country: '',
			     listingslimit: 25,
			     listingssortby: 'id',
			     listingsData: [],
				 listingsearch: '',
				 listingtrade_active: 1,
	      		 listingpublic_active: '',
	      		 listing_active: '',
	      		 order: 'desc',
	      		 type_id: '',
	      		 awaiting_payment_response: false,
	      		 delivery_address: null,
	      		 invoice_address: null,
	      		 closeHover: false,
	      		 storageurl: 'https://gemesys-dev.s3.eu-west-2.amazonaws.com/',
	      		 intentToken: '',
	      		 postPaymentIntent: '',
	      		 payment_process_msg: '',
	      		 payment_progress: '',
			}
		},
		  props: {
		    user: {
		      type: Object,
		      default: () => {api_token: ''}
		    },
		    company: {
		      type: Object,
		      default: () => {}
		    },
		    amount_payable: {
		    	type: Number,
		    	default: () => 0.00
		    }
		  },
		created() {
		      //this.checkAndGetorder();
		     
	            axios.post('/customer-api/cart/setup-intent', {amount: parseFloat(this.amount_payable), currency: this.$attrs.selected_currency.toLowerCase()}, {headers: {'Authorization' : 'Bearer '+this.user.api_token}})
	                .then( function( response ){
	                    this.intentToken = response.data;
	                    return this.includeStripe('js.stripe.com/v3/', function(){
		                this.configureStripe();
			            }.bind(this) );

	                }.bind(this));

		  },
		mounted() {    
			let self = this;
		    EventBus.$on('process-payment', function(ref, uid){
		      self.submitPaymentMethod(ref, uid);
		    });
		  },
		computed: {
			
			cartCompanies(){
				var id = 0;
				var companies = [];
				var companyList = [];
				if(this.cart.products.length > 0){
					for(var i = 0; i < this.cart.products.length; i++){
						companies.push(this.cart.products[i].company_id);
					}
					for(var ia = 0; ia < companies.length; ia++){
						companyList.push(this.cart.products.filter(item => item.product.company_id == companies[ia]));
					}
					return companyList;
				}


			},
		    cartTotal: function(){
		      var score = 0;
		      if(this.cart.products.length > 1){
		        for(var i = 0; i < this.cart.products.length; i++){
		          var itemscore = (parseFloat(this.cart.products[i].price_exc) * parseFloat(this.cart.products[i].quantity));
		          score = score + itemscore;
		        }
		      }else{
		      	score = this.cart.products[0].price_exc * this.cart.products[0].quantity;
		      }
		      return (score).toFixed(2);
		    }
		},
		methods: {
		    refetchPaymentIntent(){
		    	
		    	axios.post('/customer-api/cart/retreive-intent', {token_id: this.intentToken.id}, {headers: {'Authorization' : 'Bearer '+this.user.api_token}})
	                .then( function( response ){
	                    //this.intentToken = response.data;
	                    this.postPaymentIntent = response.data;
	                    this.confirmOrderPaid(response.data);
	                    let self = this;
				          setTimeout(function(){
				            self.cartLoading = false;
				            self.orderCreated = true;
							//self.emit('order-updated');
							EventBus.$emit('order-updated', self.$attrs.order);
				            }, 1500);
	                }.bind(this));
		    },
		    createCompanyCharges(){
		    	
		    },
		    confirmOrderPaid(){
		    	axios.post('/customer-api/order/confirm-payment',  {token_id: this.intentToken.id, order: this.$attrs.order, currency_code: this.$attrs.selected_currency, currency_code_low: this.$attrs.selected_currency.toLowerCase(), exchange_rates: this.$attrs.exchange_rates}, {headers: {'Authorization' : 'Bearer '+this.user.api_token}}).then(response => {

		    			  let self = this;
				          setTimeout(function(){
				          	
				            self.cartLoading = false;
				            self.orderCreated = true;
				           	self.payment_progress = 100;
				            self.payment_process_msg = 'Order Complete';
				            }, 1000);

				          setTimeout(function(){
				           	self.awaiting_payment_response = false;
				            
							//self.emit('order-updated');
							EventBus.$emit('order-updated', self.$attrs.order);
				            }, 1000);

		    	}).catch(error => {

		    	});
		    },
		    includeStripe( URL, callback ){
                        let documentTag = document, tag = 'script',
                            object = documentTag.createElement(tag),
                            scriptTag = documentTag.getElementsByTagName(tag)[0];
                        object.src = '//' + URL;
                        if (callback) { object.addEventListener('load', function (e) { callback(null, e); }, false); }
                        scriptTag.parentNode.insertBefore(object, scriptTag);
            },
            configureStripe(){
                this.stripe = Stripe( this.stripeAPIToken );

                this.elements = this.stripe.elements({clientSecret: this.intentToken.client_secret});
                this.card = this.elements.create('payment', {clientSecret: this.intentToken.client_secret});
                this.card.mount('#payment-element');
            },
            cancelPayment(){
            	EventBus.$emit('cancel-payment', this.$attrs.order);
            },
            processCart(){
				EventBus.$emit('process-cart');
				//this.$attrs.orderCreated = true;
				//this.$emit('update-cart', orderdetails);
				//this.show_payment_form = true;
			},
            submitPaymentMethod(orderref, uid){
            		this.payment_process_msg = 'Submitting Your Payment';
                    this.addPaymentStatus = 1;
                    this.awaiting_payment_response = true;
                    this.payment_progress = 15;
                    this.stripe.confirmPayment({
							  elements: this.elements,
							  transfer_group: orderref,
							  confirmParams: {
							    return_url: 'https://gemesys.co.uk/order/'+uid+'?confirm='+this.intentToken.id,
							    payment_method_data: {
							    	billing_details: {
	                                    name: this.cardholder_name,
	                                    email: this.company.email,
								        //address: {
								         //line1: this.cardholder_line1,
									   	 //line2: this.cardholder_line2,
									     //line3: this.cardholder_line3,
								         // city: this.cardholder_city,
								        //}
	                                }
							    }
							  },
							  // Uncomment below if you only want redirect for redirect-based payments
							   redirect: 'if_required',

							}
                    ).then(function(result) {
                    	
                    	//console.log(result.data);
                    	console.log(result.paymentIntent.status);
                    	//console.log(result.status);
                        if (result.paymentIntent.status == "succeeded") {
                            this.confirmOrderPaid();
                            
                            EventBus.$emit('order-paid', result);
                            let self = this;
                            setTimeout(function(){
				          		self.payment_progress = 60;
				            	self.payment_process_msg = 'Your Payment Has Succeeded'
				            }, 500);

                        } else {
                            //this.savePaymentMethod( result.setupIntent.payment_method );
                            
                            this.addPaymentStatus = 2;
                            this.card.clear();
                            this.cardholder_name = '';
                            
                        }
                    }.bind(this));
                    /*this.stripe.confirmPayment(
                        this.intentToken.client_secret, {
                            payment_method: {
                                card: this.card,
                                billing_details: {
                                    name: this.cardholder_name,
                                    email: this.company.email,
							        //address: {
							         //line1: this.cardholder_line1,
								   	 //line2: this.cardholder_line2,
								     //line3: this.cardholder_line3,
							         // city: this.cardholder_city,
							        //}
                                }
                            }
                        }
                    ).then(function(result) {
                    	
                    	//console.log(result.data);
                    	console.log(result.paymentIntent.status);
                    	//console.log(result.status);
                        if (result.paymentIntent.status == "succeeded") {
                            this.confirmOrderPaid();
                            
                            EventBus.$emit('order-paid', result);
                            let self = this;
                            setTimeout(function(){
				          		self.payment_progress = 60;
				            	self.payment_process_msg = 'Your Payment Has Succeeded'
				            }, 500);

                        } else {
                            //this.savePaymentMethod( result.setupIntent.payment_method );
                            
                            this.addPaymentStatus = 2;
                            this.card.clear();
                            this.cardholder_name = '';
                            
                        }
                    }.bind(this));**/
                },
	    }
	}
</script>
<template>
		<div class="product-modal d-flex flex-column justify-content-center">
			<div class=" d-flex flex-column px-2 product-grid-item mb-2 mx-auto col-md-4 col-sm-10 card border border-radius-1em shadow-none mb-0 px-4 mx-4">
			<b-row >
				<div class="col-sm-12 text-center"><div class="avatar-lg profile-user-wid mb-4 mx-auto bg-light rounded-circle d-flex shadow" style="margin-top: -45px;">
					 <div class="cube-wrapper m-auto"><div class="cube-folding"><span class="leaf1"></span><span class="leaf2"></span><span class="leaf3"></span><span class="leaf4"></span></div></div>
				</div> 
				<div v-if="orderCreated == false">
				<h4>Pay for Your Order</h4>
				<h1 class="text-primary">
				 {{$attrs.selected_currency_symbol}}{{amount_payable}}
					<small class="text-dark text-muted font-size-12">inc. VAT</small>
				</h1>
				<h6>Amount Payable</h6>
				</div>
				<div v-else>
					<h4>Payment Complete</h4>
				</div>
			</div>
			</b-row>
			<b-overlay
				:show="awaiting_payment_response"
				 rounded="sm"
				 variant="white"
			>
			<form id="payment-form">
			<b-card class="border border-radius-1em shadow-none mb-0" v-if="orderCreated == false">
				<b-form-group label="Cardholder Name" class="mb-2"><b-form-input type="text" v-model="cardholder_name" name="name" class="mb-2" placeholder="Name on card"></b-form-input></b-form-group>
				 <!--<div id="card-element" class="form-control-group stripe-card-element">

                </div>-->
               <div id="payment-element" class="form-control-group stripe-card-element">

                </div>
            </b-card>
            <div class="d-flex mt-0 mb-5 mx-4"  v-if="orderCreated == false">
                <a class="btn btn-block btn-success mt-3 ml-auto mr-1" id="add-card-button" v-on:click="processCart()">
                    Pay Now
                </a>
                <a class="btn btn-block btn-danger close-btn mt-3 mr-auto ml-1" href="javascript:;" @click="show_payment_form = false, selected_plan = '', $emit('close-window')">Cancel</a>
                </div>
            </form>
                <template #overlay>
            <div class="cube-wrapper mx-auto"><div class="cube-folding"><span class="leaf1"></span><span class="leaf2"></span><span class="leaf3"></span><span class="leaf4"></span></div></div>
            <h6>{{payment_process_msg}}</h6>
            <p v-if="payment_progress == 60">Marking order complete and notifying sellers...</p>
            <b-progress v-model="payment_progress" variant="primary"></b-progress>
          </template>
            </b-overlay>
            </div>
		</div>
	
</template>