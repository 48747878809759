<template>
  <div>
<transition name="slide-fadepanel">
        <div v-if="addNew == true"  class="bg-white position-fixed top-0 bottom-0 right-0 left-0 edit-sidepanel-fullwidth" style="z-index: 1006">
         <b-overlay :show="loadingTheme">
            <template #overlay>
                    <b-row>
                        <b-col md="12" class="offset-md-2" >
                        <b-card class="border-radius-1em border shadow-none mb-0">  
                            <div class="cube-wrapper mx-auto"><div class="cube-folding"><span class="leaf1"></span><span class="leaf2"></span><span class="leaf3"></span><span class="leaf4"></span></div></div>
                            <h6 class="text-center">Compiling your theme...</h6>
                             <b-progress class="w-50 mx-auto" v-model="loadingThemeProgress" variant="primary"></b-progress>
                        </b-card>
                        </b-col>
                        </b-row>
                      </template>
          <b-row class="justify-content-start h-100">
          <b-col md="3" class="bg-light position-relative pb-0 px-0" v-if="addNew == true" style="z-index: 1007;" >
            <h5 class="pt-4 pb-3 px-3 bg-primary text-white d-flex mb-0">
              <div class="my-auto mr-auto d-flex flex-column justify-content-center pl-2 ml-2">
                <span>Theme Options</span>
                <small>Theme: {{theme.name}}</small>
              </div>
               <a class="btn btn-primary btn-rounded  mr-2 my-auto" @click="addNew = !addNew">
            <i class="mdi mdi-close"></i>
          </a>
            </h5>
            <simplebar style="overflow-y: auto;" :style="{'max-height' : sidePanelMaxHeight - 35 +'px'}">
              
                    
             <div class="accordion mt-0">
              <b-card no-body class="mb-0" >
                <b-card-header header-tag="header" class="p-0" role="tab">
                  <b-button block variant="dark" class="text-left text-capitalize">
                  <h5 class="my-auto d-flex py-2">
                  <span class="my-auto mr-auto ml-3 text-white">Available Elements</span>
              
                </h5></b-button>
                </b-card-header>
              </b-card>
            <div :id="'accordion-available-els'">
              <b-card no-body class="mb-0" >
                <b-card-header header-tag="header" class="p-0" role="tab">
                   <b-button :disabled="activeElementKeys.includes('images')" block variant="light" class="text-left text-capitalize d-flex" @click="theme.styles.themeconfigs = availableStyles.themeconfigs, theme.styles.themecontent = availableStyles.themecontent, activeElementKeys.push('images')" >
                  <h5 class="my-auto"><i class="bx bx-chevron-right text-primary my-auto"></i>
                  Images</h5>  <i class="ml-auto my-auto bx bx-plus text-primary bg-light p-2 rounded-circle mr-2"></i></b-button>
              
                </b-card-header>
              </b-card>
          
            <div class="accordion" role="tablist" v-for="(element, index, key) in availableStyles.elements">
              <b-card no-body class="mb-0" v-for="fields, fieldkey in element" >
                <b-card-header header-tag="header" class="p-0" role="tab">
                  <b-button :disabled="activeElementKeys.includes(fieldkey)" block @click="theme.styles.elements.push(JSON.parse(JSON.stringify(element))), activeElementKeys.push(fieldkey)" variant="light" class="text-left text-capitalize">
                  <h5 class="my-auto d-flex"><i class="my-auto bx bx-chevron-right text-primary"></i>
                  <span class="my-auto">{{fieldkey.replaceAll('_1_','').replaceAll('_2_','').replaceAll('_3_','').replaceAll('_4_','').replaceAll('_', ' ')}}</span>  <i class="ml-auto my-auto bx bx-plus text-primary bg-light p-2 rounded-circle mr-2"></i></h5>
                </b-button>
                </b-card-header>
              </b-card>
              </div>
            </div>
            </div>
             <div class="accordion">
              <b-card no-body class="mb-0" >
                <b-card-header header-tag="header" class="p-0" role="tab">
                  <b-button block variant="success" class="text-left text-capitalize rounded-0">
                  <h5 class="mb-0 d-flex py-1">
                  <span class="my-auto mr-auto ml-3 text-white">Active Elements</span>
                  <i class="bx bx-check-circle text-success bg-success bg-soft p-2 rounded-circle mr-2"></i>
                </h5></b-button>
                </b-card-header>
              </b-card>
            </div>
            <div v-if="activeElementKeys.includes('images')" class="accordion" role="tablist" >
              <b-card no-body class="mb-0">
                  <b-card-header header-tag="header" class="p-0" role="tab">
                    <b-button block v-b-toggle="'accordion-configs'" variant="light" class="text-left text-capitalize d-flex">
                    <h5 class="my-auto"><i class="bx bx-chevron-right text-primary"></i>
                    Images</h5><a @click="theme.styles.themecontent = [], theme.styles.themeconfigs = [], activeElementKeys.splice(activeElementKeys.findIndex(item => item == 'images'), 1)" class="ml-auto my-auto">
                  <i class="bx bx-minus text-primary bg-light p-2 rounded-circle mr-2"></i>
                  </a></b-button>
                  </b-card-header>
                  <b-collapse :id="'accordion-configs'" accordion="my-accordion" role="tabpanel">
                  <widgetConfigsTab class="pl-2" :user="$attrs.user" :company="$attrs.company" :widgetbox="false" :configs="theme.styles.themeconfigs" :content="theme.styles.themecontent" @storage-closed="hideDesign = true" @storage-toggled="hideDesign = $event"  style="height: 100%; max-height: 100%; margin-bottom: -60px;"  />
                </b-collapse>
              </b-card>
            </div>
            <div class="accordion" role="tablist" v-for="(element, index, key) in theme.styles.elements">
              <b-card no-body class="mb-0" v-for="fields, fieldkey in element" >
                <b-card-header header-tag="header" class="p-0" role="tab">
                  <b-button block v-b-toggle="'accordion-'+fieldkey" variant="light" class="text-left text-capitalize d-flex">
                  <h5 class="my-auto"><i class="bx bx-chevron-right text-primary"></i>
                  {{fieldkey.replaceAll('_1_','').replaceAll('_2_','').replaceAll('_3_','').replaceAll('_4_','').replaceAll('_', ' ')}}</h5>
                   <a @click="theme.styles.elements.splice(index, 1), activeElementKeys.splice(activeElementKeys.findIndex(item => item == fieldkey), 1)" class="ml-auto my-auto">
                  <i class="bx bx-minus text-primary bg-light p-2 rounded-circle mr-2"></i>
                  </a>
                </b-button>
                 
                </b-card-header>
                <b-collapse :id="'accordion-'+fieldkey"  accordion="my-accordion" role="tabpanel" style="height: 100%; max-height: 100%;">
                  <b-card no-body class="mb-0 pl-2" v-for="(type, typek) in element[fieldkey]">
                <b-card-header header-tag="header" class="p-0" role="tab">
                  <b-button block v-b-toggle="'subaccordion-'+typek" variant="outline-light" class="text-left text-capitalize">
                    <h5 class="mb-0"> <i class="bx bx-chevron-right text-primary"></i>
                  {{(typek).replaceAll('_1_','').replaceAll('_2_','').replaceAll('_3_','').replaceAll('_4_','').replaceAll('_', ' ')}}</h5></b-button>
                </b-card-header>
                  <b-collapse :id="'subaccordion-'+typek" :visible="index == 0" accordion="sub-accordion" role="tabpanel"  style="height: 100%; max-height: 100%;">
                    <stylesBlock :styles="type" :user="$attrs.user" :company="$attrs.company" 
                          :widgetbox="false" @showFileManager="" :element="'row'"  style="height: 100%; max-height: 100%;"></stylesBlock>
                      </b-collapse>
                  </b-card>
                </b-collapse>
              </b-card>
            </div>
            <!--<div class="accordion" role="tablist" v-for="(element, index, key) in theme.styles.custom_classes">
              <b-card no-body class="mb-0" v-for="fields, fieldkey in element" >
                <b-card-header header-tag="header" class="p-0" role="tab">
                  <b-button block v-b-toggle="'accordion-'+fieldkey" variant="light" class="text-left text-capitalize">
                  <h5 class="mb-0"><i class="bx bx-chevron-right text-primary"></i>
                  {{fieldkey.replaceAll('_', ' ')}}</h5></b-button>
                </b-card-header>
                <b-collapse :id="'accordion-'+fieldkey" :visible="index == 0" accordion="my-accordion" role="tabpanel" style="height: 100%; max-height: 100%;">
                  <b-card no-body class="mb-0 pl-2" v-for="(type, typek) in element[fieldkey]">
                <b-card-header header-tag="header" class="p-0" role="tab">
                  <b-button block v-b-toggle="'subaccordion-'+typek" variant="outline-light" class="text-left text-capitalize">
                    <h5 class="mb-0"> <i class="bx bx-chevron-right text-primary"></i>
                  {{(typek).replaceAll('_', ' ')}}</h5></b-button>
                </b-card-header>
                  <b-collapse :id="'subaccordion-'+typek" :visible="index == 0" accordion="sub-accordion" role="tabpanel"  style="height: 100%; max-height: 100%;">
                    <stylesBlock :styles="type" :user="$attrs.user" :company="$attrs.company" 
                          :widgetbox="false" @showFileManager="" :element="'row'"  style="height: 100%; max-height: 100%;"></stylesBlock>
                      </b-collapse>
                  </b-card>
                </b-collapse>
              </b-card>
            </div>-->
            <div class="accordion">
              <b-card no-body class="mb-0" >
                <b-card-header header-tag="header" class="p-0" role="tab">
                  <b-button block variant="info" class="text-left text-capitalize" @click="addCustomClass()">
                  <h5 class="mb-0 d-flex py-1">
                  <span class="my-auto mr-auto ml-3 text-white">Add Class</span>
                  <i class="bx bx-plus text-primary bg-light p-2 rounded-circle mr-2"></i>
                </h5></b-button>
                </b-card-header>
              </b-card>
            </div>
             <div class="accordion" v-if="theme.styles.custom_classes.length > 0" v-for="(customclass, class_key, class_index) in theme.styles.custom_classes" style="height: 100%; max-height: 100%;">
                  <b-card no-body class="mb-0 pl-2" >
                <b-card-header header-tag="header" class="p-0" role="tab">
                  <b-button block v-b-toggle="'custom-class-accordion-'+customclass.class_name" variant="outline-light" class="text-left d-flex">
                    <p class="my-auto mx-3">
                  {{(customclass.class_name).replaceAll('[0-9]','').replaceAll('_', ' ')}}</p>
                  <a class="ml-auto mr-1 my-auto font-size-16 btn btn-light rounded-circle btn-sm" @click="theme.styles.custom_classes.splice(class_key, 1)"><i class="bx bx-trash-alt text-danger"></i></a>
                  <a class="mr-1 my-auto font-size-16 btn btn-light rounded-circle btn-sm" @click="theme.styles.custom_classes.push(JSON.parse(JSON.stringify(customclass))), theme.styles.custom_classes[theme.styles.custom_classes.length - 1].class_name = customclass.class_name+'-copy'"><i class="bx bx-copy-alt text-info"></i></a>
                  <a class="mr-1 my-auto font-size-16 btn btn-light rounded-circle btn-sm" @click="selectedcustomclass_index = class_key, selectedcustomclass = customclass, customClassForm = true"><i class="bx bx-edit text-primary"></i></a></b-button>
                </b-card-header>
                  <b-collapse :id="'custom-class-accordion-'+customclass.class_name" :visible="class_index == 0" accordion="custom-class-accordion" role="tabpanel"  style="height: 100%; max-height: 100%;">
                    <stylesBlock :styles="customclass.styles" :user="$attrs.user" :company="$attrs.company" 
                          :widgetbox="false" @showFileManager="" :element="'row'"  style="height: 100%; max-height: 100%;"></stylesBlock>
                      </b-collapse>
                  </b-card>
                </div>
        </simplebar>
        <transition name="fade-bottom">
          <simplebar v-if="customClassForm"  class="position-absolute bottom-0 right-0 left-0 d-flex flex-column bg-light px-3 pb-3 shadow-lg" style="height: 100%; z-index: 1">
            <div class="pl-2">
            <div class="row d-flex mb-3 mt-1">
              <b-col md="12" class="mb-3 d-flex bg-primary mx-0 py-3">
                <h5 class="my-auto card-title text-white">Class Settings</h5>
                <a class="ml-auto my-auto" @click="customClassForm = false"><i class="bx bx-x font-size-20 ml-auto my-auto bg-light text-primary rounded-circle p-2" ></i></a>
              </b-col>
              <b-col md="12" class="mb-2">
                <b-form-group label="Class Name" description="Enter your class name eg. .card h1">
                  <b-input type="text" name="class_name" v-model="selectedcustomclass.class_name">
                  </b-input>
                </b-form-group>
              </b-col>
              <b-col md="12" class="mb-2">
                <h6>Class Elements</h6>
                <p v-if="customclasserror !== ''" class="text-danger bg-danger bg-soft rounded p-2">{{customclasserror}}</p>
                <b-form-group>
                  <b-form-select v-model="selectedclasselement" class="form-control text-capitalize">
                  <b-form-select-option v-for="element, key, index in customStyleElements['styles']" :value="element">
                    {{Object.keys(element)[0].replaceAll('_', ' ')}}
                  </b-form-select-option>
                  
                </b-form-select>
                <b-button variant="primary" class="btn-rounded btn-block mt-2" :disabled="selectedclasselement == ''" @click="addClassElement(selectedclasselement)">
                    Add Element
                  </b-button>
                </b-form-group>
              </b-col>
              <b-col md="12" class="mb-2">
                <h6>Make Important</h6>
                <b-form-group>
                  <b-form-checkbox-group button-variant="primary" class="text-capitalize" buttons :options="[true, false]" v-model="selectedcustomclass.important">
                  </b-form-checkbox-group>
                </b-form-group>
              </b-col>
              <b-col md="12" class="mb-2">

                <h6>Class Styles</h6>
                <stylesBlock :styles="selectedcustomclass.styles" :user="$attrs.user" :company="$attrs.company" 
                          :widgetbox="false" @showFileManager="" :element="'row'"  style="height: 100%; max-height: 100%;"></stylesBlock>

              </b-col>
             
              <b-col md="12" class="mb-4">
                <b-button variant="primary" class="btn-rounded btn-block mb-2" :disabled="selectedcustomclass.styles.length == 0" v-if="selectedcustomclass_index == -1" @click="addClass(selectedcustomclass)">
                    Add Class
                  </b-button>
                  <b-button variant="primary" class="btn-rounded btn-block mb-2" :disabled="selectedcustomclass.styles.length == 0" v-else @click="updateClass(selectedcustomclass, index)">
                    Update Class
                  </b-button>
              </b-col>
            </div>
          </div>
          </simplebar>
        </transition>
          <transition name="fade-bottom">
          <div v-if="themeSettings"  class="position-absolute bottom-0 right-0 left-0 d-flex flex-column bg-light px-3 pb-3 shadow-lg" style="height: 50%; z-index: 1">
            <div class="pl-2">
            <div class="row d-flex mb-3 mt-1">
              <b-col md="12" class="mb-3 d-flex bg-primary mx-0 py-3">
                <h5 class="my-auto card-title text-white">Settings</h5>
                <i class="bx bx-cog font-size-20 ml-auto my-auto bg-light text-primary rounded-circle p-2"></i>
              </b-col>
              <b-col md="12" class="mb-2">
              <button class="btn btn-primary btn-soft btn-rounded btn-block position-relative overflow-hidden"   @click="themeDetails = !themeDetails, showCompiledStylesheet = false" >
                  <div class="d-flex m-2">
                    Theme Settings <i class="bx bx-cog font-size-20 ml-auto icon-label-end right-0"></i>
                  </div>
              </button>
              </b-col>
              <b-col md="12" class="mb-2">
              <button class="btn btn-info btn-soft btn-rounded btn-block position-relative overflow-hidden"   @click="showCompiledStylesheet = !showCompiledStylesheet, themeDetails = false" >
                  <div class="d-flex m-2">
                    View Stylesheets <i class="bx bx-file font-size-20 ml-auto icon-label-end right-0"></i>
                  </div>
              </button>
              </b-col>
            </div>
          </div>
          </div>
        </transition>
          <div class="position-absolute bottom-0 right-0 left-0 d-flex flex-column bg-white p-3" style="z-index: 1">
            <div class="w-100 d-flex justify-content-around">
              <a class="btn btn-light btn-rounded rounded-circle mr-1 ml-1" @click="themeSettings = !themeSettings">
                <i v-if="themeSettings == false" class="bx bx-cog font-size-20"></i>
                <i v-else class="bx bx-x font-size-20"></i>
              </a>

            <a class="btn btn-danger btn-rounded w-50 mr-1" @click="addNew = !addNew">
                Cancel
              </a>
              <a v-if="theme.id !== ''" class="btn btn-success btn-rounded w-50 mr-1" @click="updateTheme()">
                Save
              </a>
              <a v-else class="btn btn-success btn-rounded w-50 mr-1" @click="createTheme()">
                Save
              </a>
            </div>
          </div>
          </b-col>
          <b-col md="9" class="p-0" v-if="!hideDesign || !showCompiledStylesheet">
            <simplebar style="overflow-y: auto; overflow-x: hidden;" :style="{'max-height' : (sidePanelMaxHeight + 120 ) +'px'}" id="theme">
            <b-row>
              <b-col md="6">
                <img src="/images/product-placeholder.png" class="avatar-md">
              </b-col>
              <b-col md="6" class="d-flex">
                <div class="dropdown d-none d-lg-inline-block ml-auto">
                <button  
                  type="button"
                  class="btn header-item noti-icon"
                               >
                  <i class="bx bx-search"></i>
                </button>
                <button  
                  type="button"
                  class="btn header-item noti-icon"
                               >
                  <i class="bx bx-pound"></i>
                </button>
                <button  
                  type="button"
                  class="btn header-item noti-icon"
                               >
                  <i class="bx bx-shopping-bag"></i>
                </button>
                <b-dropdown
           
                right
                variant="black"
                toggle-class="header-item"
                menu-class="dropdown-menu-end"
               
              >
                <template v-slot:button-content>
                 
                  <div>
                    <img
                    class="rounded-circle header-profile-user"
                    src="/images/product-placeholder.png"
                    alt="Sign in to Gemesys"
                  />
                  <span class="d-none d-xl-inline-block ml-1">
                    My Account
                  </span>
                  <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
                  </div>

                  
                </template>
              </b-dropdown>
              </div>
              </b-col>
              <b-col md="12" class="py-3 border-bottom border-top">
                <b-nav align="center">
                  <b-nav-item active>Active</b-nav-item>
                  <b-nav-item>Link</b-nav-item>
                  <b-nav-item>Link with a long name </b-nav-item>
                  <b-nav-item disabled>Disabled</b-nav-item>
                </b-nav>
              </b-col>
            </b-row>
            <b-row class="bg-primary bg-soft d-flex flex-column justify-content-center" style="height: 500px;">
              <b-col md="12" class="text-center">
                <h1>Heading 1 Text</h1>
                <p>This is an example of some paragraph text</p>
              </b-col>
            </b-row>
            <b-row class="px-5 pt-5">
            <b-col md="8">
              <span class="font-size-20 mb-3 border-bottom d-block">Fonts</span>
              <b-card >
                 <span class="font-size-20 mb-3 border-bottom d-block">Headers</span>
              <h1>Heading 1</h1>
              <h2>Heading 2</h2>
              <h3>Heading 3</h3>
              <h4>Heading 4</h4>
              <h5>Heading 5</h5>
              <span class="font-size-20 mb-3 mt-4  border-bottom d-block">Paragraph</span>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum
              </p>
              <span class="font-size-20 mb-3 mt-4  border-bottom d-block">Links</span>
              <a>
               Link Text
              </a>
              <span class="font-size-20 mb-3 mt-4  border-bottom d-block">Blockquote</span>
              <blockquote>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum
              </blockquote>
            </b-card>
            <span class="font-size-20 mb-3 border-bottom d-block">Horizontal Card</span>
             <b-card  no-body>
               <b-row no-gutters>
              <b-col md="4">
                <b-card-img src="/images/placeholders/placeholder-earring1.jpg" alt="Image" class="rounded-0"></b-card-img>
              </b-col>
              <b-col md="8">
                 <b-card-body title="Sample Card Styles">
                    <b-card-text>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    </b-card-text>
                    <a class="btn btn-primary mb-2">
                      Primary Button
                    </a>
                    <a class="btn btn-secondary mb-2">
                      Secondary Button
                    </a>
                  </b-card-body>
                </b-col>
              </b-row>
              </b-card>
            </b-col>
            <b-col md="4">
              <span class="font-size-20 mb-3 border-bottom d-block">Buttons</span>
              <b-card >
              <a class="btn btn-primary btn-block mb-2">
                Primary Button
              </a>
              <a class="btn btn-secondary btn-block mb-2">
                Secondary Button
              </a>
            </b-card>
            <span class="font-size-20 mb-3 border-bottom d-block">Vertical Card</span>
             <b-card  title="Sample Card Styles" img-src="/images/placeholders/placeholder-ring1.jpg" img-top>
                <b-card-text>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                </b-card-text>
              <a class="btn btn-primary mb-2">
                Primary Button
              </a>
              <a class="btn btn-secondary mb-2">
                Secondary Button
              </a>
              </b-card>
              <span class="font-size-20 mb-3 border-bottom d-block">Pagination</span>
              <b-pagination :total-rows="10" :per-page="5"  ></b-pagination>
              <span class="font-size-20 mb-3 border-bottom d-block">Filters</span>
              <b-pagination :total-rows="10" :per-page="5"  ></b-pagination>
               <span class="font-size-20 mb-3 border-bottom d-block">Default Searchbar</span>
              <div class="mt-sm-0 form-inline w-100"><div class="app-search search-box mr-2 ml-auto"><div class="position-relative"><input type="text" placeholder="Search name, reference or description..." class="form-control w-100 border"> <span class="bx bx-search-alt search-icon"></span></div></div></div>
            </b-col>
          </b-row>
           <b-row class="px-5 pt-3">
            <span class="font-size-20 mb-3 border-bottom d-block">Card Variations</span>
            <b-col md="4">
             <b-card id="product-card" no-body class="border shadow-none border-radius-1em" @mouseenter="producthoverIndex = 1" @mouseleave="producthoverIndex = -1" >
            
           
            <b-card-img :src="'/images/placeholders/placeholder-ring2.jpg'" />
            <b-card-body>
              <h3 class="card-title">Product Name</h3>
            <b-card-text class="d-flex flex-column">
              
              <p class="card-text-paragraph" v-html="'Here is a product description'"></p>
              <div class="d-flex">
              <span class="badge badge-light text-dark bg-light font-size-14 my-2">
                £1,000
              </span>
            <transition name="fade-right">
              <a v-if="producthoverIndex == 1" class="btn" href="javascript:;">
                See Product <i class="bx bx-chevron-right"></i>
              </a>
            </transition>
              </div>
            </b-card-text>
          </b-card-body>
          </b-card>
            </b-col>
            <b-col md="4">
             <b-card id="category-card" no-body @mouseenter="hoverIndex = 1" @mouseleave="hoverIndex = -1" >
                
                <b-card-img  :src="'/images/placeholders/placeholder-ring3.jpg'" />
                <b-card-body>
                  <h3 class="card-title">Category Name</h3>
                <b-card-text>
                  <p class="card-text-paragraph" v-html="'Here is a category description'"></p>
                  <div>
                    <a href="javascript:;">
                          See Products <i class="bx bx-chevron-right"></i>
                        </a>
                    <transition name="fade-right">
                      <a v-if="(hoverIndex == 1)" href="javascript:;">
                          See Products <i class="bx bx-chevron-right"></i>
                        </a>
                    </transition>
                  </div>
                  </b-card-text>
                </b-card-body>
                </b-card>
            </b-col>
            <b-col md="4">
            <b-card class="card overflow-hidden border-radius-1em shadow-sm seller-profile-card" no-body @mouseenter="hoverIndex = 2" @mouseleave="hoverIndex = -1">
          <div class="bg-soft bg-primary">
            <div class="row">
              <transition name="fade-left">
                          <div class="col-md-12 position-absolute z-index-2" v-if="hoverIndex == 2"> 
                                <a class="mt-3 ml-3 btn btn-primary font-size-16 btn-rounded ml-auto float-left"><i class="mdi mdi-eye-outline"></i></a>
                              </div>
                          </transition>
                           
                       <div class="col-12">

                <a :href="'javascript:;'">
                        <b-card-img-lazy src="/images/banner-placeholder.png" alt class="img-fluid" /></b-card-img-lazy>
                        </a>
              
              </div>
            </div>
          </div>
          <b-card-body class="card-body pt-0">
            <div class="row">
              <div class="col-sm-12 pb-4 text-center">
                <div class="avatar-md profile-user-wid mb-2 mx-auto" style="margin-top: -45px !important;">
                  
                  <span class="avatar-title avatar-md bg-light text-primary rounded-circle listing-pin">
                    <i class="bx bx-map-pin font-size-20 listing-pin-icon"></i>
                  </span>
                </div>
                <h5 class="card-title">Listing Name</h5>
                <p class="card-text mb-0">
                  <span>50 Kings Street</span>
                  <span>Aldgate</span>
                  <span>Oxford</span>
                  <span>Oxfordshire</span>
                  <span>OX12 122</span>
                </p>
              </div>
              <div class="col-md-12 d-flex">
                <span class="badge badge-light listing-badge border-radius-1em bg-light text-dark font-size-11 lh-16 my-auto p-2 mx-auto">
                  Listing Type
                </span>
              </div>
             

            </div>
          </b-card-body>
        </b-card>
            </b-col>
           </b-row>
           <b-row>
           <DefaultFooter :company="$attrs.company" :user="$attrs.user" />
         </b-row>
        </simplebar>
        <transition name="slide-fadepanel">
        <div class="position-fixed top-0 bottom-0 right-0 left-0 bg-white" style="margin-left: 24%;" v-if="themeDetails == true">
           <a class="btn btn-light btn-rounded rounded-circle ml-auto mb-auto mt-3 float-right mr-3" @click="themeDetails = false">
          <i class="mdi mdi-close font-size-20"></i>
        </a>
                <b-row class="h-100 d-flex justify-content-center">
                 
       <b-col md="12" class="my-auto">
             <b-card class="card border border-radius-1em shadow-none ml-4">
              <p>Please remember to give your theme a name and description</p>
            <b-form-group label="Theme Name" class="mt-2">
                <b-form-input type="text" v-model="theme.name"></b-form-input>
              </b-form-group>
              <b-form-group label="Theme Description" class="mt-2">
                <b-form-textarea type="text" v-model="theme.description"></b-form-textarea>
              </b-form-group>
             <!-- <b-form-group label="theme Thumbnail" class="mt-2">
                <vue-dropzone id="dropzone" ref="myVueDropzone" :use-custom-slot="true" :options="dropzoneOptions" @vdropzone-file-added="imageAdded"  @vdropzone-drop="imageAdded">
                              <div class="dropzone-custom-content">
                                  <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                                  <h4>Drop files here or click to upload.</h4>
                              </div>
                              </vue-dropzone>
              </b-form-group>
              <input type="hidden" name="thumbnail" v-model="theme.thumbnail" />-->
              <b-form-group label="Active" class="mt-2">
                         <div
                                class="form-check form-switch form-switch-lg w-75 d-flex"
                                dir="ltr"
                              >
                         
                              <input
                                  class="form-check-input ml-auto my-auto" 
                                  type="checkbox"
                                  id="SwitchCheckSizesm"
                                 
                                  v-model="theme.active"
                                
                                  name="active"
                                />
                            </div>
              </b-form-group>
              </b-card>
            </b-col>
          </b-row>
              </div>
            </transition>
              <transition name="slide-fadepanel">
                   <ThemeCompiler ref="styleCompiler" v-if="showCompiledStylesheet" :elements="theme.styles.elements" :windowInnerHeight="sidePanelMaxHeight - 35" @close-compiler="showCompiledStylesheet = false" />
               </transition>
          </b-col>
          </b-row>
          </b-overlay>
        </div>
      </transition></div>
    </template>
    <!--<style id="customCss" v-model="loadStyles"></style>-->
    <script type="text/javascript">
        
        import Swal from "sweetalert2";
import VueSlideBar from "vue-slide-bar";
//import FilterListTree from "../../views/stock/product/category/_partials/FilterListTree";
import ThemeCompiler from "./ThemeCompiler";
import FilterListTree from "../FilterListTree";
import FilterProductBrands from "../FilterProductBrands";
import FilterProductCollections from "../FilterProductCollections";
import SideSlidePanel from "../SideSlidePanel";

import stylesBlock from "./widgets/stylesBlock";
import widgetConfigsTab from "./widgets/widgetConfigsTab";
import simplebar from "simplebar-vue";
import DefaultFooter from "./templates/actinolite/DefaultFooter";
import { EventBus } from '../../../app';
import { themeClassElements } from "./page-styles";

/**
 * Products component
 */
export default {
  components: { VueSlideBar, FilterListTree, FilterProductBrands, FilterProductCollections, SideSlidePanel, stylesBlock, simplebar, Swal, DefaultFooter, widgetConfigsTab, ThemeCompiler },
  data() {
     return {
      customClassForm: false,
      loadingTheme: true,
      loadingThemeProgress: 0,
      compiledStyle: '',
      themeSettings: false,
      themeDetails: false,
      showCompiledStylesheet: false,
      hideDesign: false,
      loadingTheme: false,
      loadingThemeProgress: 0,
      loadingForm: false,
      deleteMode: 'single',
      hoverIndex: -1,
      producthoverIndex: -1,
      showPageTools: -1,
      showTemplateTools: -1,
      pages: '',
      templates: '',
      pagesData: '',
      title: "Themes",
      items: [
        {
          text: "Web",
          href: "/",
        },
        {
          text: "Themes",
          active: true,
        },
      ],
      search: '',
      customStyleElements: themeClassElements,
      selectedclasselement: '',
      selectedcustomclass: '',
      selectedcustomclass_index: -1,
      customclasserror: '',
      custom_classes: [],
      custom_class: {
        class_name: '',
        styles: [],
        important: false,
        media_size: 'xl',
      },
      media_sizes: [{variant: 'xl', width: ''}, {variant: 'lg', width: ''}, {variant: 'md', width: ''}, {variant: 'sm', width: ''},{variant: 'custom', width: ''}],
      pageslimit: 25,
      pagessearch: '',
      storageurl: 'https://'+this.$attrs.company.nickname+'.s3.eu-west-2.amazonaws.com',
      showFilters: false,
      addNew: false,
      addNewModal: false,
      sliderPrice: 800,
      priceRange: [0,999999],
      currentPage: 1,
      limit: 25,
      selectedids: [],
      sidePanelMaxHeight: 420,
      orderDesc: true,
      order: 'desc',
      sortby: 'id',
      error_reponse: '',
      error_message: false,
      success_message: false, 
      success_text: 'Your action was successful',
      trade_active: '',
      public_active: '',
      brands: '',
      collections: '',
      selectedids: [],
      isCheckAll: false,
      classswitches: [
        {oldclass: '-1-', newclass: ''},
        {oldclass: '-2-', newclass: ''},
        {oldclass: '-3-', newclass: ''},
        {oldclass: '-4-', newclass: ''},
        {oldclass: '.field', newclass: 'input'},
        {oldclass: 'button', newclass: 'btn'},
        {oldclass: '#headers .', newclass: ''},
        {oldclass: '#body-text .paragraph', newclass: 'p'},
        {oldclass: '#body-text .links', newclass: 'a'},
        {oldclass: '#body-text .blockquote', newclass: 'blockquote'},
        {oldclass: '#cards .default', newclass: '.card'},
        {oldclass: '#cards .default .title', newclass: '.card .card-title'},
        {oldclass: '#cards .default .body', newclass: '.card .card-body p, .card .card-body, .card .card-body .card-text'},
        {oldclass: '#cards .default .btn', newclass: '.card .btn'},
        {oldclass: '#cards .product', newclass: '#product-card.card'},
        {oldclass: '#cards .product .title', newclass: '#product-card.card .card-title'},
        {oldclass: '#cards .product .body', newclass: '#product-card.card .card-body, .card .card-body .card-text p, #product-card.card .card-body, #product-card.card .card-body .card-text'},
        {oldclass: '#cards .product .btn', newclass: '#product-card.card .btn'},
        {oldclass: '#cards .product .pricing-badge', newclass: '#product-card.card .badge'},
        {oldclass: '#cards .listing', newclass: '#listing-card.card'},
        {oldclass: '#cards .listing .title', newclass: '#listing-card.card .card-title'},
        {oldclass: '#cards .listing .body', newclass: '#listing-card.card .card-body, .card .card-body .card-text p, #listing-card.card .card-body, #listing-card.card .card-body .card-text'},
        {oldclass: '#cards .listing .btn', newclass: '#listing-card.card .btn'},
        {oldclass: '#cards .listing .listing-badge', newclass: '#listing-card.card .badge'},
        {oldclass: '#btns .btn-primary', newclass: '.btn .btn-primary'},
        {oldclass: '#btns .btn-secondary', newclass: '.btn .btn-secondary'},
        {oldclass: '.card .title', newclass: '.card .card-title'},
        {oldclass: '\n', newclass: ''},
        
      ],
      theme: {
        id: '',
        uid: '',
        name: '',
        description: '',
        active: '',
        styles: '',
      },
      theme_raw: {
        id: '',
        uid: '',
        name: '',
        description: '',
        active: '',
        styles: '',
      },
      active: '',
      view: 'grid',
      fields: [
        { key: "id", sortable: true },
        { key: "name", sortable: true },
        ],
      filter: null,
      filterOn: [],
      limits: [
        {value: 25, text: "25"},
        {value: 50, text: "50"},
        {value: 100, text: "100"},
      ],
      discountRates: [],
      activeElementKeys: [],
    }
  },
  props: {
    theme: {
      type: Object,
      default: {},
    },
    availableStyles: {
      type: Object, 
      default: {},
    },
    availableStylesRaw: {
      type: Object, 
      default: {},
    }
  },
  mounted(){
     //this.loadStyles;
     this.createHead();
     this.sidePanelMaxHeight = window.innerHeight - 120;
     //this.compileStyleElements();
    //this.theme.styles.custom_classes = this.theme.styles.custom_classes;
     this.loadingTheme = true;
    let self = this;
    EventBus.$on('loadTheme', function(){
       self.loadTheme();
       self.loadStyles;
    });
  },
  created(){
      window.addEventListener("resize", this.windowResize);
      //this.theme.styles.custom_classes = this.theme.styles.custom_classes;
      for(var i = 0; i < this.theme.styles.elements.length; i++){
        this.activeElementKeys.push(Object.keys(this.styles.elements[i])[0]);
      }
      
    },
    destroyed() {
            window.removeEventListener("resize", this.windowResize);
        },
    watch: {
          'theme.styles[*]':function(){
            //alert('update');
          }
    },
  methods: {
      windowResize(e) {
            console.log(window.innerHeight);
    
            this.sidePanelMaxHeight = window.innerHeight - 120;
   
           
      }, 
      addCustomClass(){
        this.selectedcustomclass_index = -1;
        this.selectedcustomclass = JSON.parse(JSON.stringify(this.custom_class));
        this.customClassForm = true;
      },
      addClass(sclass){
        this.theme.styles.custom_classes.push(sclass);
        this.customClassForm = false;
      },
      updateClass(sclass, index){
        this.theme.styles.custom_classes[index] = sclass;
        this.customClassForm = false;
      },
      addClassElement(element){
        var key = Object.keys(element)[0];
        var index = this.selectedcustomclass.styles.findIndex(item => Object.keys(item)[0] == key);
        if(index == -1){
          this.selectedcustomclass.styles.push(JSON.parse(JSON.stringify(this.selectedclasselement)));
          this.selectedclasselement = '';
        }else{
          this.customclasserror = 'Style element already exists';
          let self = this;
          setTimeout(function (){
            self.customclasserror = '';
          }, 5000);
        }
      },
      loadTheme(){
        this.loadingThemeProgress = 49;
        this.addNew = true
        let self = this;
          setTimeout(function(){
            //self.theme = item;
            
            self.loadingThemeProgress = 100;
            self.loadingTheme = false;
          }, 300);
      },
      compileStyleElement(element){

      },
      
      polishStyle(style){
        for(var stari = 0; stari < this.classswitches.length; stari++){
          style = style.replaceAll(this.classswitches[stari].oldclass, this.classswitches[stari].newclass);
        }
       // this.compiledStyle = style;
        return style;

      },
      
      hexToRgb(hex, opacity) {
          return 'rgba(' + (hex = hex.replace('#', '')).match(new RegExp('(.{' + hex.length/3 + '})', 'g')).map(function(l) { return parseInt(hex.length%2 ? l+l : l, 16) }).concat(isFinite(opacity) ? opacity : 1).join(',') + ')';
      },
      createTheme(){
        if(this.theme.name == '' || theme.description == ''){

        }else{
          this.loadingThemeProgress = 10;
          this.loadingTheme = false;
          axios.post('/api/company/web/theme/create', {data: this.theme, raw_css: this.compiledStyle}, {headers: {'Authorization' : 'Bearer '+ this.$attrs.user.api_token}}).then(response => {
            this.theme = response.data;
            let self = this;
            setTimeout(function(){
              //self.theme = item;
              
              self.loadingThemeProgress = 100;
              self.loadingTheme = false;
            }, 300);
          }).catch(error => {

          });
        }
      },
      createHead(){
        var h = document.getElementsByTagName('head').item(0);
        /*var l = document.createElement("link");
      l.rel = "stylesheet"; 
      l.id = "headingfontStylesheets";
      h.appendChild(l);*/
      var b = document.createElement("link");
      b.rel = "stylesheet"; 
      b.id = "bodyfontStylesheets";
      h.appendChild(b);
      var s = document.createElement("style");
      s.type = "text/css"; 
      s.id = "customCss";
      h.appendChild(s);
      var f = document.createElement("style");
      f.type = "text/css"; 
      f.id = "loadFonts";
      h.appendChild(f);
      },
      updateTheme(){
        this.loadingThemeProgress = 10;
        this.loadingTheme = false;
        this.theme.styles.custom_classes = this.theme.styles.custom_classes;
        this.loadStyles;
        axios.post('/api/company/web/theme/'+this.theme.id+'/update', {data: this.theme, raw_css: this.compiledStyle, raw_json: ''}, {headers: {'Authorization' : 'Bearer '+ this.$attrs.user.api_token}}).then(response => {
          let self = this;
          setTimeout(function(){
            //self.theme = item;
            
            self.loadingThemeProgress = 100;
            self.loadingTheme = false;
            Swal.fire("Saved", "Your template has been updated.", "success");
          }, 300);
        }).catch(error => {

        });
      }
   },
   computed: {
        loadStyles: function(){
            this.compiledStyle = '';
            if(this.compileStyleElements !== ''){
              this.compiledStyle += this.compileStyleElements;
            }
            this.compiledStyle += this.compileCustomElements;
            document.getElementById('customCss').innerHTML = this.compiledStyle;
            return this.compiledStyle;
          },
          compileStyleElements: function(){
          var style = '';
                 for(var i = 0; i < this.theme.styles.elements.length; i++){
                  var key1 = Object.keys(this.theme.styles.elements[i]);
                  
                 
                    var subkeys = Object.keys(this.theme.styles.elements[i][key1[0]]);
                    for(var subki = 0; subki < subkeys.length; subki++){
                      var key2 = [];
                      key2.push(subkeys[subki]);
                      style += '#theme #'+key1[0].replaceAll('_', '-')+'';
                    if(key2[0]){
                 style += ' .'+key2[0].replaceAll('_', '-')+'';
              
              //console.log( this.theme.styles.elements[i][key1[0]][key2[0]]);
                var subkey = '';
                for(var ei = 0; ei < this.theme.styles.elements[i][key1[0]][key2[0]].length; ei++ ){
                  
                  var key3 = Object.keys(this.theme.styles.elements[i][key1[0]][key2[0]][ei]);
                  
                  if(ei == 0){
                    style += '{\n';
                  }
                  if((key3[0].includes('__')) && (this.theme.styles.elements[i][key1[0]][key2[0]][ei][key3[0]].value && this.theme.styles.elements[i][key1[0]][key2[0]][ei][key3[0]].value !== '')){
                      if((subkey == '') || (subkey !== key3[0].split('__')[0])){
                        style += '} \n';
                        style += '#theme #'+key1[0].replaceAll('_', '-')+' .'+key2[0].replaceAll('_', '-')+' .'+key3[0].split('__')[0].replaceAll('_', '-')+'{\n';
                      }
                      subkey = key3[0].split('__')[0];
                  }
                  if(!key3[0].includes('_label')){
                  if(key3[0].includes('font_family')){
                    style += ''+(key3[0]).replaceAll(''+subkey+'__', '').replaceAll('_', '-').replaceAll('colour', 'color')+': "'+this.theme.styles.elements[i][key1[0]][key2[0]][ei][key3[0]].value.family+'"';
                    style += '; \n'

                  }else if(key3[0] == 'border' || key3[0].split('_')[key3[0].split('_').length - 1] == 'border'){
                    style += ''+(key3[0]).replaceAll(''+subkey+'__', '').replaceAll('_', '-').replaceAll('colour', 'color')+'-top: '+this.theme.styles.elements[i][key1[0]][key2[0]][ei][key3[0]].top+'px';
                    style += '; \n'
                    style += ''+(key3[0]).replaceAll(''+subkey+'__', '').replaceAll('_', '-').replaceAll('colour', 'color')+'-bottom: '+this.theme.styles.elements[i][key1[0]][key2[0]][ei][key3[0]].bottom+'px';
                    style += '; \n'
                    style += ''+(key3[0]).replaceAll(''+subkey+'__', '').replaceAll('_', '-').replaceAll('colour', 'color')+'-left: '+this.theme.styles.elements[i][key1[0]][key2[0]][ei][key3[0]].left+'px';
                    style += '; \n'
                    style += ''+(key3[0]).replaceAll(''+subkey+'__', '').replaceAll('_', '-').replaceAll('colour', 'color')+'-right: '+this.theme.styles.elements[i][key1[0]][key2[0]][ei][key3[0]].right+'px';
                    style += '; \n'

                  }else if(key3[0].includes('border_radius') || key3[0].split('_')[key3[0].split('_').length - 1].includes('border_radius')){
                    style += ''+(key3[0]).replaceAll(''+subkey+'__', '').replaceAll('_', '-').replaceAll('colour', 'color')+'-top-left: '+this.theme.styles.elements[i][key1[0]][key2[0]][ei][key3[0]].top_left+'px';
                    style += '; \n'
                    style += ''+(key3[0]).replaceAll(''+subkey+'__', '').replaceAll('_', '-').replaceAll('colour', 'color')+'-bottom-left: '+this.theme.styles.elements[i][key1[0]][key2[0]][ei][key3[0]].bottom_left+'px';
                    style += '; \n'
                    style += ''+(key3[0]).replaceAll(''+subkey+'__', '').replaceAll('_', '-').replaceAll('colour', 'color')+'-top-left: '+this.theme.styles.elements[i][key1[0]][key2[0]][ei][key3[0]].top_left+'px';
                    style += '; \n'
                    style += ''+(key3[0]).replaceAll(''+subkey+'__', '').replaceAll('_', '-').replaceAll('colour', 'color')+'-bottom-right: '+this.theme.styles.elements[i][key1[0]][key2[0]][ei][key3[0]].bottom_right+'px';
                    style += '; \n'

                  }else if(this.theme.styles.elements[i][key1[0]][key2[0]][ei][key3[0]].value && this.theme.styles.elements[i][key1[0]][key2[0]][ei][key3[0]].value !== ''){
                   style += ''+(key3[0]).replaceAll(''+subkey+'__', '').replaceAll('_', '-').replaceAll('colour', 'color')+': '+this.theme.styles.elements[i][key1[0]][key2[0]][ei][key3[0]].value;
                   if(this.theme.styles.elements[i][key1[0]][key2[0]][ei][key3[0]].units){
                    style += this.theme.styles.elements[i][key1[0]][key2[0]][ei][key3[0]].units;
                   }
                   style += '; \n'
                  }
                  }
                  if((key3[0].includes('__')) && (this.theme.styles.elements[i][key1[0]][key2[0]][ei][key3[0]].value && this.theme.styles.elements[i][key1[0]][key2[0]][ei][key3[0]].value !== '')){
                      
                  }
                  if(ei == (this.theme.styles.elements[i][key1[0]][key2[0]].length - 1)){
                  style += '}\n';
                  }
                }
                
              }
             }
              //var key3 = Object.keys(this.elements[i][key1[0]][key2[0]]);
              //style += ' .'+key3[0]+'';
                    /*for(var sei = 0; sei < this.elements[i][key1[0]][key2[0]].length; sei++){
                      style += ' .test;'
                
                
              }*/
             
            }
            //this.compiledStyle = ''+style.replaceAll('.field', 'input').replaceAll('button', 'btn').replaceAll('#headers .', '').replaceAll('\n', '')+'';
            //return this.polishStyle(style);
            for(var stari = 0; stari < this.classswitches.length; stari++){
              style = style.replaceAll(this.classswitches[stari].oldclass, this.classswitches[stari].newclass);
            }
           // this.compiledStyle = style;
            return style;
          },
          compileCustomElements: function(){
            var style = '';
            if(this.theme.styles.custom_classes.length > 0){
            for(var cci = 0; cci < this.theme.styles.custom_classes.length; cci++){
              style += '#theme '+this.theme.styles.custom_classes[cci].class_name+'{\n';
            if(this.theme.styles.custom_classes[cci] !== ''){
            for(var i = 0; i < this.theme.styles.custom_classes[cci].styles.length; i++){
              var key3 = Object.keys(this.theme.styles.custom_classes[cci].styles[i]);
              if(key3[0].includes('font_family')){
                    style += ''+(key3[0]).replaceAll('_', '-').replaceAll('colour', 'color')+': "'+this.theme.styles.custom_classes[cci].styles[i][key3[0]].value.family+'"';
                    style += ''+ (this.theme.styles.custom_classes[cci].important == true ? ' !important' : '') +';\n'

                  }else if(key3[0] == 'border' || key3[0].split('_')[key3[0].split('_').length - 1] == 'border'){
                    style += ''+(key3[0]).replaceAll('_', '-').replaceAll('colour', 'color')+'-top: '+this.theme.styles.custom_classes[cci].styles[i][key3[0]].top+'px';
                    style += ''+ (this.theme.styles.custom_classes[cci].important == true ? ' !important' : '') +';\n'
                    style += ''+(key3[0]).replaceAll('_', '-').replaceAll('colour', 'color')+'-bottom: '+this.theme.styles.custom_classes[cci].styles[i][key3[0]].bottom+'px';
                    style += ''+ (this.theme.styles.custom_classes[cci].important == true ? ' !important' : '') +';\n'
                    style += ''+(key3[0]).replaceAll('_', '-').replaceAll('colour', 'color')+'-left: '+this.theme.styles.custom_classes[cci].styles[i][key3[0]].left+'px';
                    style += ''+ (this.theme.styles.custom_classes[cci].important == true ? ' !important' : '') +';\n'
                    style += ''+(key3[0]).replaceAll('_', '-').replaceAll('colour', 'color')+'-right: '+this.theme.styles.custom_classes[cci].styles[i][key3[0]].right+'px';
                    style += ''+ (this.theme.styles.custom_classes[cci].important == true ? ' !important' : '') +';\n'

                  }else if(key3[0].includes('border_radius') || key3[0].split('_')[key3[0].split('_').length - 1].includes('border_radius')){
                    style += ''+(key3[0]).replaceAll('_', '-').replaceAll('colour', 'color')+'-top-right: '+this.theme.styles.custom_classes[cci].styles[i][key3[0]].top_right+'px';
                    style += ''+ (this.theme.styles.custom_classes[cci].important == true ? ' !important' : '') +';\n'
                    style += ''+(key3[0]).replaceAll('_', '-').replaceAll('colour', 'color')+'-bottom-left: '+this.theme.styles.custom_classes[cci].styles[i][key3[0]].bottom_left+'px';
                    style += ''+ (this.theme.styles.custom_classes[cci].important == true ? ' !important' : '') +';\n'
                    style += ''+(key3[0]).replaceAll('_', '-').replaceAll('colour', 'color')+'-top-left: '+this.theme.styles.custom_classes[cci].styles[i][key3[0]].top_left+'px';
                    style += ''+ (this.theme.styles.custom_classes[cci].important == true ? ' !important' : '') +';\n'
                    style += ''+(key3[0]).replaceAll('_', '-').replaceAll('colour', 'color')+'-bottom-right: '+this.theme.styles.custom_classes[cci].styles[i][key3[0]].bottom_right+'px';
                    style += ''+ (this.theme.styles.custom_classes[cci].important == true ? ' !important' : '') +';\n'

                  }else if(key3[0].includes('box_shadow') || key3[0].split('_')[key3[0].split('_').length - 1].includes('box_shadow')){
                    style += ''+(key3[0]).replaceAll('_', '-').replaceAll('colour', 'color')+':'; 
                    style += ' '+this.theme.styles.custom_classes[cci].styles[i][key3[0]].v_offset+'px ';
                    style += ' '+this.theme.styles.custom_classes[cci].styles[i][key3[0]].h_offset+'px ';
                    style += ''+this.theme.styles.custom_classes[cci].styles[i][key3[0]].blur+'px ';
                    style += ''+this.theme.styles.custom_classes[cci].styles[i][key3[0]].spread+'px ';
                    style += ''+this.hexToRgb(this.theme.styles.custom_classes[cci].styles[i][key3[0]].value)+'';
                    style += ''+ (this.theme.styles.custom_classes[cci].important == true ? ' !important' : '') +';\n'

                  }else if((key3[0].includes('text_color')) || (key3[0].includes('text_colour'))){
                    style += ''+(key3[0]).replaceAll('text_', '').replaceAll('colour', 'color')+': '+this.theme.styles.custom_classes[cci].styles[i][key3[0]].value;
                  }else if(this.theme.styles.custom_classes[cci].styles[i][key3[0]].value && this.theme.styles.custom_classes[cci].styles[i][key3[0]].value !== ''){
                   style += ''+(key3[0]).replaceAll('_', '-').replaceAll('colour', 'color')+': '+this.theme.styles.custom_classes[cci].styles[i][key3[0]].value;
                   if(this.theme.styles.custom_classes[cci].styles[i][key3[0]].units){
                    style += this.theme.styles.custom_classes[cci].styles[i][key3[0]].units;
                   }
                   style += ''+ (this.theme.styles.custom_classes[cci].important == true ? ' !important' : '') +';\n'
                  }
            }
             style += '} \n'
            }
            //this.compiledStyle = ''+style.replaceAll('.field', 'input').replaceAll('button', 'btn').replaceAll('#headers .', '').replaceAll('\n', '')+'';
            //console.log(style);
            }
            return style.replaceAll('\n', '');
            }else{
              return '';
            }
          },
          compileCustomElement: function(){
            var style = this.selectedcustomclass.class_name+'{\n';
            if(this.selectedcustomclass !== ''){
            for(var i = 0; i < this.selectedcustomclass.styles.length; i++){
              var key3 = Object.keys(this.selectedcustomclass.styles[i]);
              if(key3[0].includes('font_family')){
                    style += ''+(key3[0]).replaceAll('_', '-').replaceAll('colour', 'color')+': "'+this.selectedcustomclass.styles[i][key3[0]].value.family+'"';
                    style += ';\n'

                  }else if(key3[0] == 'border' || key3[0].split('_')[key3[0].split('_').length - 1] == 'border'){
                    style += ''+(key3[0]).replaceAll('_', '-').replaceAll('colour', 'color')+'-top: '+this.selectedcustomclass.styles[i][key3[0]].top+'px';
                    style += ';\n'
                    style += ''+(key3[0]).replaceAll('_', '-').replaceAll('colour', 'color')+'-bottom: '+this.selectedcustomclass.styles[i][key3[0]].bottom+'px';
                    style += ';\n'
                    style += ''+(key3[0]).replaceAll('_', '-').replaceAll('colour', 'color')+'-left: '+this.selectedcustomclass.styles[i][key3[0]].left+'px';
                    style += ';\n'
                    style += ''+(key3[0]).replaceAll('_', '-').replaceAll('colour', 'color')+'-right: '+this.selectedcustomclass.styles[i][key3[0]].right+'px';
                    style += ';\n'

                  }else if(key3[0].includes('border_radius') || key3[0].split('_')[key3[0].split('_').length - 1].includes('border_radius')){
                    style += ''+(key3[0]).replaceAll('_', '-').replaceAll('colour', 'color')+'-top-right: '+this.selectedcustomclass.styles[i][key3[0]].top_right+'px';
                    style += ';\n'
                    style += ''+(key3[0]).replaceAll('_', '-').replaceAll('colour', 'color')+'-bottom-left: '+this.selectedcustomclass.styles[i][key3[0]].bottom_left+'px';
                    style += ';\n'
                    style += ''+(key3[0]).replaceAll('_', '-').replaceAll('colour', 'color')+'-top-left: '+this.selectedcustomclass.styles[i][key3[0]].top_left+'px';
                    style += ';\n'
                    style += ''+(key3[0]).replaceAll('_', '-').replaceAll('colour', 'color')+'-bottom-right: '+this.selectedcustomclass.styles[i][key3[0]].bottom_right+'px';
                    style += ';\n'

                  }else if(key3[0].includes('box_shadow') || key3[0].split('_')[key3[0].split('_').length - 1].includes('box_shadow')){
                    var opacity = 1;
                    if('opacity' in this.selectedcustomclass.styles[i][key3[0]]){
                      this.selectedcustomclass.styles[i][key3[0]].opacity;
                    }
                    style += ''+(key3[0]).replaceAll('_', '-').replaceAll('colour', 'color')+':'; 
                    style += ' '+this.selectedcustomclass.styles[i][key3[0]].v_offset+'px ';
                    style += ' '+this.selectedcustomclass.styles[i][key3[0]].h_offset+'px ';
                    style += ''+this.selectedcustomclass.styles[i][key3[0]].blur+'px ';
                    style += ''+this.selectedcustomclass.styles[i][key3[0]].spread+'px ';
                    style += ''+this.hexToRgb(this.selectedcustomclass.styles[i][key3[0]].value, opacity)+'';
                    style += ';\n'

                  }else if((key3[0].includes('text_color')) || (key3[0].includes('text_colour'))){
                    style += ''+(key3[0]).replaceAll('text_', '').replaceAll('colour', 'color')+': '+this.selectedcustomclass.styles[i][key3[0]].value;
                  }else if(this.selectedcustomclass.styles[i][key3[0]].value && this.selectedcustomclass.styles[i][key3[0]].value !== ''){
                   style += ''+(key3[0]).replaceAll('_', '-').replaceAll('colour', 'color')+': '+this.selectedcustomclass.styles[i][key3[0]].value;
                   if(this.selectedcustomclass.styles[i][key3[0]].units){
                    style += this.selectedcustomclass.styles[i][key3[0]].units;
                   }
                   style += ';\n'
                  }
            }
             style += '} \n'
            }
            //this.compiledStyle = ''+style.replaceAll('.field', 'input').replaceAll('button', 'btn').replaceAll('#headers .', '').replaceAll('\n', '')+'';
            //console.log(style);
            return style;
          },
   }
}
    </script>

