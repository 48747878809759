<script>
	import Multiselect from "vue-multiselect";
	export default{
		components: { Multiselect },
		data(){
			return{
				id: '',
				options: [
					{id: 1, name: 'text'},
					{id: 2, name: 'text2'}
				],
				search: '',
				currentPage: 1,
				order: 'desc',
      			sortby: 'id',
      			limit: 0,
				limits: [
		        {value: 25, text: "25"},
		        {value: 50, text: "50"},
		        {value: 100, text: "100"},
		    	],
				response_error: [],
				noresults: false,
			}
		},
		created(){
			this.id = this.$attrs.id;
			this.getResource();
		},
		mounted(){
			//this.getResource();
		},
		methods:{
			updateUser: function(selectedOption, id){
				this.$emit('status-selected', selectedOption);
			},
			getResource(){
				axios.get('/api/company/service/statuses?limit=0&sortby='+this.sortby+'&order='+this.order+'&search='+this.search+'', {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.api_token
              }}).then(response => {
				this.options = response.data;
				if(response.data.length == 0){
					this.noresults = true;
				}
			}).catch(error => {
				this.response_error = error.data;
			});
			}
		}
	}
</script>
<template>
	<div>
	<multiselect v-model="id" track-by="name" label="name" :options="options" @select="updateUser" :option-height="104" >
                     <template slot="singleLabel" slot-scope="{ option }" class="w-100 h-100">
                     	<div class="d-flex w-100 h-100">
                     			<div class="avatar-xs mr-4 my-auto mx-1">
                     				<span class="avatar-title rounded-circle" :style="{'background-color': option.colour}"></span>
                      			</div>
      						<div class="option__desc d-flex flex-row w-75"><span class="option__title ml-2 my-auto">{{ option.name }} </span><span class="option__small ml-auto my-auto">{{ option.description }}</span>
      						</div>
  						</div></template>
    <template slot="option" slot-scope="{ option }">
    	<div class="d-flex">
    		<div class="avatar-xs mr-4">
                        <span class="avatar-title rounded-circle" :style="{'background-color': option.colour}"></span>
                      </div>
      <div class="option__desc d-flex flex-row w-50"><span class="option__title ml-2 my-auto">{{ option.name }}</span><span class="option__small ml-auto my-auto">{{ option.description }}</span>
      </div>
  		</div>
    </template>
                  </multiselect>
   </div>
</template>