<template>
	<div>
		<SocialShareWidget v-if="$attrs.action == 'share'" :company="$attrs.company" :user="$attrs.user" :liveMode="false" :content="$attrs.content" :configs="$attrs.configs" :filters="$attrs.active_filters" ></SocialShareWidget>
		<SocialFollowWidget v-if="$attrs.action == 'follow'" :company="$attrs.company" :user="$attrs.user" :liveMode="false" :content="$attrs.content" :configs="$attrs.configs" :filters="$attrs.active_filters" ></SocialFollowWidget>
	</div>
</template>

<script type="text/javascript">
	import SocialShareWidget from './SocialShareWidget';
	import SocialFollowWidget from './SocialFollowWidget';

	export default {
		components: {
			SocialShareWidget,
			SocialFollowWidget
		}
	}

</script>