<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import Swal from "sweetalert2";
import SideSlidePanel from "../../components/widgets/SideSlidePanel";
import SearchCompanyUser from "../../components/widgets/SearchCompanyUser";
import UserList from "../../components/widgets/UserList";
import SearchCompanyUsers from "../../components/widgets/SearchCompanyUsers";
import SearchCompanyDepartment from "../../components/widgets/SearchCompanyDepartment";
import Subscription from '../../components/widgets/subscription'

/**
 * Contacts-list component
 */
export default {
  components: { Layout, PageHeader, SideSlidePanel, SearchCompanyUser, UserList, SearchCompanyUsers, SearchCompanyDepartment, Subscription },
  data() {
    return {
      title: "Locations",
      view: 'list',
      userAddToDepartment: false,
      locations: [],
      load_error: [],
      success_message: false,
      error_reponse: [],
      error_message: false,
      delete_confirm: false,
      delete_action: false, 
      deleted_message: false,
      delete_form_load: false,
      currentPage: 1,
      limit: 25,
      selectedids: [],
      orderDesc: true,
      order: 'desc',
      sortby: 'id',
      search: '',
      timeout: null,
      formResponse: '',
      errorResponse: [],
      addFormSubmitted: false,
      updateFormSubmitted: false,
      updateManagerBtn: false,
      isCheckAll: false,
      newLocation:{
        id: '',
        name: '',
        address1: '',
        address2: '',
        address3: '',
        county_id: '',
        country_id: '',
        postcode: '',
        telephone: '',
        company_id: this.$attrs.company.id,
      },
      updateLocation: {
        id: '',
        name: '',
        address1: '',
        address2: '',
        address3: '',
        county_id: '',
        country_id: '',
        postcode: '',
        telephone: '',
        company_id: this.$attrs.company.id,
      },
      counties: [],
      countries: [],
      updatePanel: false,
      addPanel: false,
      viewPanel: false,
      selectedids: [],
      limits: [
        {value: 25, text: "25"},
        {value: 50, text: "50"},
        {value: 100, text: "100"},
      ],
      items: [
        {
          text: "Company",
          href: "/dashboard"
        },
        {
          text: "Locations",
          active: true
        }
      ]
    };
  },
  created(){
    if(this.$attrs.validsubscription == 0){
      this.title = 'Subscription';
      this.items[1].text = 'Subscription';
    }
    this.getCountries();
    this.searchResource();
  },
  methods: {
    getCountries(){
      axios.get('/api/countries', {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
              }}).then(response => {
        this.countries = response.data;
      }).catch(error => {
        this.countries_error = error.data;
      });
    },
    getCounties(id){
      axios.get('/api/country/'+id+'/counties', {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
              }}).then(response => {
        this.counties = response.data;
      }).catch(error => {
        this.counties_error = error.data;
      })
    },
    deleteLocationEvent(action){
      this.delete_action = action;
      this.delete_confirm = true;
    },
    deleteLocation(action){
      this.delete_form_load = true;
      axios.post('/api/company/location/'+action.id+'/destroy', {}, {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
              }} ).then(response => {
         if(response.data == true){
                var self = this;
                setTimeout(function(){
                 //Vue.swal("Avatar Updated");
                 self.delete_confirm = false;
                 self.deleted_message = true;
                 self.delete_form_load =false;
                 self.searchResource();
                }, 1500);
            }
          }).catch(error => {
        this.error_message = true;
      });
    },
    searchResource(){
      axios.get('//'+this.$attrs.nickname+'.compliancecube.com/api/company/locations?page='+this.currentPage+'&limit='+this.limit+'&sortby='+this.sortby+'&order='+this.order+'&search='+this.search+'', {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
              }}).then(response => {
            this.locations = response.data;
      }).catch(error => {
            this.load_error = response.error;
      });
    },
    searchFilter(e) {
      const searchStr = e.target.value;
      this.searchResource();
    },
    setTimeout(callback) {
        this.clearTimeout()
        this.timeout = setTimeout(() => {
          this.clearTimeout()
          callback()
        }, 3000)
    },
    clearTimeout() {
        if (this.timeout) {
          clearTimeout(this.timeout)
          this.timeout = null;
        }
    },
    validateNewLocation(){
      this.addFormSubmitted = true;
      if(this.newLocation.name.length < 2){
        this.errorResponse.push({error: "Please Enter Location Name 2 Characters or Longer"});
      }
      if(this.newLocation.address1 == ''){
          this.errorResponse.push({error: "Please Enter Location Address line 1"})
      }
       if(this.newLocation.address2 == ''){
          this.errorResponse.push({error: "Please Enter Location Address line 2"})
      }
      if(this.newLocation.country == ''){
          this.errorResponse.push({error: "Please Enter Location Country"})
      }
      if(this.newLocation.county == ''){
          this.errorResponse.push({error: "Please Enter Location County"})
      }
       if(this.newLocation.postcode == ''){
          this.errorResponse.push({error: "Please Enter Location Postcode"})
      }
      if(this.errorResponse == ''){
        this.addNewLocation();
      }else{
        this.setTimeout(() => {
          this.addFormSubmitted = false;
        });
      }
      
    },
    validateUpdatedLocation(){
      this.updateFormSubmitted = true;
      if(this.updateLocation.name.length < 2){
        this.errorResponse.push({error: "Please Enter Location Name 2 Characters or Longer"});
      }
      if(this.updateLocation.address1 == ''){
          this.errorResponse.push({error: "Please Enter Location Address line 1"})
      }
       if(this.updateLocation.address2 == ''){
          this.errorResponse.push({error: "Please Enter Location Address line 2"})
      }
      if(this.updateLocation.country == ''){
          this.errorResponse.push({error: "Please Enter Location Country"})
      }
      if(this.updateLocation.county == ''){
          this.errorResponse.push({error: "Please Enter Location County"})
      }
       if(this.updateLocation.postcode == ''){
          this.errorResponse.push({error: "Please Enter Location Postcode"})
      }
      if(this.errorResponse == ''){
        this.updateExistingLocation();
      }else{
        this.setTimeout(() => {
          this.updateFormSubmitted = false;
        });
      }
      
    },
    addNewLocation(){
      this.addFormSubmitted = true;
      axios.post('//'+this.$attrs.nickname+'.compliancecube.com/api/company/location/store?api_token='+this.$attrs.user.api_token, this.newLocation).then(response => {
            this.formResponse = response.data;
          //this.addFormSubmitted = false;
          this.success_message = true;
          this.newLocation = {
              id: '',
              name: '',
              address1: '',
              address2: '',
              address3: '',
              county_id: '',
              country_id: '',
              postcode: '',
              telephone: '',
              company_id: this.$attrs.company.id,
            };
            let self = this;
            setTimeout(function(){
              self.addFormSubmitted = false;
              self.addPanel = false;
              self.searchResource();
            }, 1500);
      }).catch(error => {
            this.error_reponse = error.response.data.errors;
            this.error_message = true;
            this.setTimeout(() => {
            this.addFormSubmitted = false;
          });
      });
    },
    updateExistingLocation(){
      this.addFormSubmitted = true;
        axios.post('//'+this.$attrs.nickname+'.compliancecube.com/api/company/location/'+this.updateLocation.id+'/update?api_token='+this.$attrs.user.api_token, this.updateLocation).then(response => {
          this.formResponse = response.data;
          this.success_message = true;
          let self = this;
          setTimeout(function(){
                self.addFormSubmitted = false;
                 //self.updatePanel = false;
                }, 1500);
        }).catch(error => {
            this.error_reponse = error.response.data.errors;
            this.error_message = true;
            this.setTimeout(() => {
            this.addFormSubmitted = false;
          });
        });
      },
      getManager(selectedOption){
        this.newLocation.manager_id = selectedOption.companies[0].id;
        this.updateLocation.manager_id = selectedOption.companies[0].id;
      },
      getDepartment(selectedOption){
        this.newLocation.company_department_id = selectedOption.id;
        this.updateLocation.company_department_id = selectedOption.id;
      },
      filterSelected(id){
        if(this.selectedids.length > 0){
          var exists = this.selectedids.filter(item => item == id);
          if(exists.length > 0){
            return true;
          }else{
            return false
          }
        }else{
          return false;
        }
      },
      checkAll: function(){ 
                 this.isCheckAll = !this.isCheckAll; 
                 this.selectedids = []; 
                 if(this.isCheckAll){ 
                     // Check all 
                     for (var key in this.locations.data) {          
                        this.selectedids.push(this.locations.data[key].id); 
                     } 
                 } 
              }, 
      updateCheckall: function(){ 
                 if(this.selectedids.length == this.locations.data.length){
                    this.isCheckAll = true; 
                 }else{ 
                    this.isCheckAll = false; 
                 } 
      }, 

  }
};
</script>

<template>
  <Layout v-if="$attrs.validsubscription == 1" :nickname="$attrs.nickname" :user="$attrs.user" >
    <PageHeader :title="title" :items="items" />
    <div class="row mb-3">
        <b-col md="12">
                    <transition name="slide-success" mode="out-in">
                  <b-alert id="success-message" variant="success" :show="success_message"  dismissible @dismissed="success_message = !success_message">
                      <p class="font-size-14">Congratulations, your location has been saved.</p>
                    </b-alert>
                  </transition>
                  </b-col>
          <div class="col-xl-2 col-sm-6">
            <div class="mt-2">
              <b-form-select v-model="limit" :options="limits" @input="productQuery()"></b-form-select>
            </div>
          </div>
         
          <div class="col-lg-10 col-sm-6 d-flex">
          <form class="my-auto">
            <small class="mx-auto my-2 text-center d-block">Results {{locations.from}} - {{locations.to}} of {{locations.total}}</small>
          </form>
            <div class="mt-4 mt-sm-0 form-inline ml-auto" >
              <div class="search-box mr-2 ml-auto">
                <div class="position-relative">
                  <input
                    type="text"
                    class="form-control w-100 border-0"
                    placeholder="Search name, reference or description..."
                    v-model="search"
                    @keyup.enter="searchFilter($event)"
                  />
                  <i class="bx bx-search-alt search-icon"></i>
                </div>
              </div>
              <ul class="nav nav-pills product-view-nav pt-2">
                <!--<li class="nav-item">
                  <a class="nav-link" :class="{'active': showFilters == true }" href="javascript: void(0);" @click="showFilters = !showFilters">
                    <i class="bx bx-filter-alt"></i>
                  </a>
                </li>-->
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': view == 'grid' }" href="javascript: void(0);" @click="view = 'grid'">
                    <i class="bx bx-grid-alt"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': view == 'list' }" href="javascript: void(0);" @click="view = 'list'">
                    <i class="bx bx-list-ul"></i>
                  </a>
                </li>
                <li class="nav-item">
                 <b-dropdown text="Bulk Actions" variant="primary" class="rounded-pill">
                  <b-dropdown-item href="javascript: void(0);">Action</b-dropdown-item>
                  <b-dropdown-item href="javascript: void(0);">Another action</b-dropdown-item>
                  <b-dropdown-item href="javascript: void(0);">Something else here</b-dropdown-item>
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-item href="javascript: void(0);">Separated link</b-dropdown-item>
                </b-dropdown>
                </li>
                <li class="nav-item">
                 <a
                    class="btn btn-success btn-rounded mb-2 mr-2 text-white"
                    @click="addPanel = !addPanel"
                  >
                    <i class="mdi mdi-plus mr-1"></i> New
                  </a>
                </li>
              </ul>
              <div class="text-sm-right">
                  
                </div>
            </div>
          </div>
        </div>
        <div class="row">
       <div class="col-md-12">
          <SideSlidePanel id="filter-project-panel" :editPanel="delete_confirm || deleted_message" :panelClass="'search-sidepanel mb-4'" @close-panel="delete_confirm = false">
             <b-overlay
                        id="report-generating-overlay"
                        :show="delete_form_load"
                        variant="white"
                        opacity="0.85"
                        blur="1px"
                        rounded="md"
                        class="w-100"
                      >
          <div class="col-md-12 confirm-delete d-flex h-100" v-if="delete_confirm == true">
            <div class="card my-auto mx-auto pb-3 text-center">
              <div class="card-body">
              <h4>Are You Sure?</h4>
              <p>You Are About to Delete Location <strong>{{delete_action.name}}</strong>.<br>All projects, tasks and reviews under this location will not be effected.</p>
             <!--<b-row>
              <b-col md="4">
              <b-form-group title="Delete Projects" description="Delete all projects assigned to this team">
                <b-form-checkbox v-model="delete_assprojects" switch></b-form-checkbox>
              </b-form-group>
            </b-col>
             <b-col md="4">
              <b-form-group title="Delete All Actions" description="Delete all actions assigned to this team">
                <b-form-checkbox v-model="delete_assactions" switch></b-form-checkbox>
              </b-form-group>
            </b-col>
             <b-col md="4">
              <b-form-group title="Delete All Reviews" description="Delete all reviews assigned to this team">
                <b-form-checkbox v-model="delete_assreviews" switch></b-form-checkbox>
              </b-form-group>
            </b-col>
            </b-row>-->
              <a class="btn btn-success float-left" @click="deleteLocation(delete_action)">Confirm</a>
              <a class="btn btn-danger float-right" @click="delete_confirm = false, delete_action = []">Cancel!</a>
            </div>
            </div>
          </div>
          <div class="col-md-12 confirm-delete d-flex h-100" v-if="deleted_message == true">
            <div class="card my-auto mx-auto pb-3 text-center">
              <div class="card-body">
              <h4>Location Deleted</h4>
              <p>Your location has been deleted.</p>
              <a class="btn btn-success mx-auto text-center" @click="delete_confirm = false, deleted_message = false">Close</a>
            </div>
            </div>
          </div>
        </b-overlay>
        </SideSlidePanel>
      </div>
    </div>
    <div class="row" v-if="view == 'list'">
      <div class="col-lg-12">
        <div class="card">
          <div class="">
            <div class="table-responsive">
              <table class="table table-centered table-nowrap table-hover">
                <thead>
                  <tr>
                    <th scope="col" style="width: 100px; text-align: center;"><input type="checkbox" class="align-middle" @click='checkAll()' v-model="isCheckAll" /></th>
                    <th scope="col">Name</th>
                    <th scope="col">Address</th>
                    <th scope="col">County</th>
                    <th scope="col">Postcode</th>
                    <th scope="col">Country</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="location in locations.data" :key="location.id">
                    <td>
                      <div class="text-center">
                        <b-form-checkbox class="select-row-btn" v-model="selectedids" :value="location.id" button-variant="primary" button>

                          <i v-if="filterSelected(location.id)" class="bx bx-check"></i><span class="avatar-title rounded-circle" v-else>{{(location.name).slice(0,1)}}</span></b-form-checkbox>
                      </div>                      
                    </td>
                    <td>
                      <h5 class="font-size-14 mb-1">
                        <a href="#" class="text-dark">{{location.name}}</a>
                      </h5>
                    </td>
                    <td>
                     {{location.address1}}<br>
                     {{location.address2}}<br>
                     {{location.address3}}
                     </td>
                     
                    <td><span v-if="location.county !== null">{{location.county.name}}</span></td>
                    <td>{{location.postcode}}</td>
                    <td>{{location.country.name}}</td>
                    <td>
                      <ul class="list-inline font-size-20 contact-links mb-0">
                        <li class="list-inline-item px-1">
                          <a v-b-tooltip.hover title="Edit" @click="updateLocation = location, updatePanel = true">
                            <i class="bx bx-edit"></i>
                          </a>
                        </li>
                        <li class="list-inline-item px-1">
                          <a v-b-tooltip.hover title="View" @click="updateLocation = location, viewPanel = true">
                            <i class="mdi mdi-eye-outline"></i>
                          </a>
                        </li>
                        <!--<li class="list-inline-item px-1">
                          <a v-b-tooltip.hover title="Reports">
                            <i class="bx bx-line-chart"></i>
                          </a>
                        </li>-->
                        <li class="list-inline-item px-1">
                          <a v-b-tooltip.hover title="Delete" @click="deleteLocationEvent(location)">
                            <i class="bx bx-trash-alt"></i>
                          </a>
                        </li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="row">
              <div class="col-lg-12">
                 <b-pagination
              class="justify-content-center"
              pills
              v-model="currentPage"
              :total-rows="locations.total"
              :per-page="locations.per_page"
              aria-controls="my-table"
              @input="searchResource"
            ></b-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="view == 'grid'">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
            <div v-for="location in locations.data" :key="location.id" class="col-xl-3 col-sm-6">
        <div class="card text-center">
          <div class="card-body">
            <div class="avatar-sm mx-auto mb-4">
              <span
                class="avatar-title rounded-circle bg-soft-primary text-primary font-size-16"
              >{{(location.name).slice(0,1)}}</span>
            </div>
            <h5 class="font-size-15">
              <a href="javascript: void(0);" class="text-dark">{{location.name}}</a>
            </h5>
            <p class="text-muted">{{location.description}}</p>
            <div v-if="location.manager.user.avatar" class="btn btn-primary rounded-pill">
                        <img class="rounded-circle avatar-xs" :src="'/'+location.manager.user.avatar" alt />
                        <span>{{location.manager.user.name}} {{location.manager.user.lastname}} - {{location.manager.user.jobtitle}}</span>
                     </div>
            <div class="mt-3">
              <strong>Projects</strong>
             <h5 class="text-primary">{{location.projects_count}}</h5>
            </div>
          </div>
          <div class="card-footer bg-transparent border-top">
            <div class="contact-links d-flex font-size-20">
              <div class="flex-fill">
                <a v-b-tooltip.hover title="Edit">
                            <i class="bx bx-edit"></i>
                </a>
              </div>
              <div class="flex-fill">
                  <a v-b-tooltip.hover title="View">
                            <i class="mdi mdi-eye-outline"></i>
                  </a>
              </div>
              <div class="flex-fill">
                 <a v-b-tooltip.hover title="Staff">
                            <i class="bx bx-group"></i>
                  </a>
              </div>
              <div class="flex-fill">
                          <a v-b-tooltip.hover title="Reports">
                            <i class="bx bx-line-chart"></i>
                          </a>
              </div>
              <div class="flex-fill">
                          <a v-b-tooltip.hover title="Delete">
                            <i class="bx bx-trash-alt"></i>
                          </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
            <div class="row">
              <div class="col-lg-12">
                 <b-pagination
                    class="justify-content-center"
                    pills
                    v-model="currentPage"
                    :total-rows="locations.total"
                    :per-page="locations.per_page"
                    aria-controls="my-table"
                    @input="searchResource"
                  ></b-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <SideSlidePanel :editPanel="addPanel" :panelClass="'edit-sidepanel'" @close-panel="addPanel = false" >
      <b-row>
              <b-col md="12">
                <div class="m-4 px-2">
                <h4>Add New Location</h4>
                  <transition name="slide-error" mode="out-in">
                    <b-alert id="errors" variant="danger" :show="error_message"  dismissible @dismissed="error_message = !error_message">
                      <p class="font-size-14">Oops! Please correct the following errors in your submission</p>
                      <ul>
                        <li v-for="(error, key) in error_reponse">
                          {{error[0]}}
                        </li>
                      </ul>
                    </b-alert>
                    
                  </transition>
              <b-overlay
                :show="addFormSubmitted"
                variant="white"
                        opacity="0.85"
                        blur="1px"
                        rounded="md"
              >
              <b-row>
                <b-col md="12">
                  <b-form-group label="Location Name">
                    <b-form-input type="text" name="name" v-model="newLocation.name" :state="newLocation.name.length > 0"></b-form-input>
                  </b-form-group>
                  <b-form-group label="Address 1">
                  <b-form-input name="address1" v-model="newLocation.address1"></b-form-input>
                  </b-form-group>
                  <b-form-group label="Address 2">
                    <b-form-input name="address2" v-model="newLocation.address2"></b-form-input>
                  </b-form-group>
                  <b-form-group label="Address 3">
                    <b-form-input name="address3" v-model="newLocation.address3"></b-form-input>
                  </b-form-group>
                  <b-form-group label="Country">
                    <b-form-select name="country" v-model="newLocation.country_id" :options="countries"  value-field="id"  text-field="name" @input="getCounties(newLocation.country_id)"></b-form-select>
                  </b-form-group>
                  <b-form-group label="County/State/Region" v-if="(newLocation.country_id !== '') && (counties.length !== 0)">
                    <b-form-select name="county" v-model="newLocation.county_id"  value-field="id"  text-field="name" :options="counties"></b-form-select>
                  </b-form-group>
                  <b-form-group label="Postcode/Zip">
                    <b-form-input name="postcode" v-model="newLocation.postcode"></b-form-input>
                  </b-form-group>
                  <b-form-group label="Telephone">
                    <b-form-input name="telephone" v-model="newLocation.telephone"></b-form-input>
                  </b-form-group>
                  <a href="javascript:;" class="btn btn-success" @click="addNewLocation">Save</a>
                </b-col>
              </b-row>
            </b-overlay>
                </div>
              </b-col>
      </b-row>            
    </SideSlidePanel>
    <SideSlidePanel :editPanel="updatePanel" :panelClass="'edit-sidepanel'" @close-panel="updatePanel = false">
      <b-row>
              <b-col md="12">
                <div class="m-4 px-2" v-if="updatePanel == true">
                <h4>Edit Team</h4>
                <b-overlay
                :show="updateFormSubmitted"
                rounded
                opacity="0.6"
                spinner-small
                spinner-variant="primary"
                class=""
                @hidden="onHidden"
              >
              <b-row>
                <b-col md="12">
                  <b-form-group label="Location Name">
                    <b-form-input type="text" name="name" v-model="updateLocation.name" :state="updateLocation.name.length > 0"></b-form-input>
                  </b-form-group>
                  <b-form-group label="Address 1">
                  <b-form-input name="address1" v-model="updateLocation.address1"></b-form-input>
                  </b-form-group>
                  <b-form-group label="Address 2">
                    <b-form-input name="address2" v-model="updateLocation.address2"></b-form-input>
                  </b-form-group>
                  <b-form-group label="Address 3">
                    <b-form-input name="address3" v-model="updateLocation.address3"></b-form-input>
                  </b-form-group>
                  <b-form-group label="Country">
                    <b-form-select name="country" v-model="updateLocation.country_id" :options="countries"  value-field="id"  text-field="name" @input="getCounties(updateLocation.country_id)"></b-form-select>
                  </b-form-group>
                  <b-form-group label="County/State/Region" v-if="(updateLocation.country_id !== '') && (counties.length !== 0)">
                    <b-form-select name="county" v-model="updateLocation.county_id"  value-field="id"  text-field="name" :options="counties"></b-form-select>
                  </b-form-group>
                  <b-form-group label="Postcode/Zip">
                    <b-form-input name="postcode" v-model="updateLocation.postcode"></b-form-input>
                  </b-form-group>
                  <b-form-group label="Postcode/Zip">
                    <b-form-input name="telephone" v-model="updateLocation.telephone"></b-form-input>
                  </b-form-group>
                  <a href="javascript:;" class="btn btn-success" @click="updateExistingLocation()">Save</a>
                </b-col>
              </b-row>
            </b-overlay>
                </div>
              </b-col>
      </b-row>            
    </SideSlidePanel>

    <SideSlidePanel :editPanel="viewPanel" :panelClass="'edit-sidepanel'" @close-panel="viewPanel = false, updateManagerBtn = false">
      <b-row>
              <b-col md="12">
                <div class="m-4 px-2 row" v-if="viewPanel == true">

                  <b-col md="12">
                <h4>{{updateLocation.name}}</h4>
                </b-col>
                 <b-col md="12">
                  <b-card>
                    {{updateLocation.address1}}<br>
                    {{updateLocation.address2}}<br>
                    {{updateLocation.address3}}<br>
                    {{updateLocation.county.name}}<br>
                    {{updateLocation.country.name}}<br>
                    {{updateLocation.postcode}}<br><br>

                    <a :href="'mailto:'+updateLocation.email">{{updateLocation.email}}</a><br>
                    <a :href="'tel:'+updateLocation.telephone">{{updateLocation.telephone}}</a><br>
                  </b-card>
                  </b-col>
                </div>
              </b-col>
      </b-row>            
    </SideSlidePanel>
  </Layout>
  <Layout v-else :nickname="$attrs.nickname" :user="$attrs.user" >
    <PageHeader :title="title" :items="items" />
    <Subscription :company="this.$attrs.company" :user="this.$attrs.user" />
  </Layout>
</template>