<script type="text/javascript">
	export default{
		data: function(){
			return{
				storageurl: 'https://'+this.$attrs.company.nickname+'.s3.eu-west-2.amazonaws.com',
				transitioning: 0,
				hoverIndex: -1,
				linkprefix: '',
    			linksuffix: '',
			}
		},
		props: {
			autoplay: {
				type: Boolean,
				default: () => true,
			},
			items: {
				type: Array,
				default: () => [],
			},
			start: {
				type: Number,
				default: () => 0,
			},
			end: {
				type: Number,
				default: () => 2,
			},
			grid_limit: {
				type: Number,
				default: () => 2,
			},
			pages: {
				type: Number,
				default: () => 0,
			},
			layout_type: {
				type: String,
				default: () => 'horizontal',
			},
			truncate_titles: {
				type: Boolean,
				default: () => false,
			},
			use:{
				type: String,
				default: () => ''
			},
			customer: {
		  		type: Object,
		  		default: ''
		  	},
		},
		mounted(){
			if(this.use !== ''){
		   		if(this.use == 'public'){
		   			this.linkprefix = '/seller/';
		   			this.linksuffix = '/product';
		   		}else if(this.use == 'website'){
		   			this.linkprefix = '';
		   			this.linksuffix = '/product';
		   		}
		   	}
			this.pages = (this.items.length / this.grid_limit);
			this.transitioning = 1;
			var self = this;
			setTimeout(function(){
                 self.transitioning = 0;
                 //self.autoPlay();
                }, 1500);
			if(this.autoplay == true){
				setTimeout(function(){
                 //self.transitioning = 0;
                 self.autoPlay();
                }, 5000);
			}
		},
		methods: {
			addTax(product, price){
        		var tax_rule = product.tax_rule;
        		console.log(tax_rule);
		    	if((this.customer !== null && this.customer !== '') && (this.customer.default_delivery_address !== '' && this.customer.default_delivery_address !== null)){
		    		var country_id = this.customer.default_delivery_address.country_id;
		    	}else{
		    		var country_id = product.company.country_id;
		    	}
		    	if(tax_rule !== null && tax_rule.country_rules.length > 0){
		    		var selected_rule = null;
		    		var selected_ruleIndex = -1;
			    	for(var ti = 0; ti < tax_rule.country_rules.length; ti++){
			    		selected_ruleIndex = tax_rule.country_rules[ti].countries.findIndex(item => item == country_id);
			    		if(selected_ruleIndex !== -1){
			    			selected_rule = tax_rule.country_rules[ti];
			    			ti = tax_rule.country_rules.length;
			    		}
			    	}
			    	console.log(selected_rule);
			    	if(tax_rule.type == 1){
		        		if(selected_rule !== null){
		        			var percentage = (parseFloat(selected_rule.value) / 100) + 1;
		        			return price * percentage;
		         		}else{
		         			return (price * 1.2).toFixed(2);
		         		}
	         		}else{
	         			if(selected_rule !== null){
		        			return price + selected_rule.value;
		         		}else{
		         			return (price * 1.2).toFixed(2);
		         		}
	         		}
         		}else if(tax_rule !== null && tax_rule.country_rules.length > 0){
         			var percentage = (parseFloat(tax_rule.percentage) / 100) + 1;
		        	return price * percentage;
         		}else {
		        	return(price * 1.2).toFixed(2);
         		}
        	},
			autoPlay(){
				if((this.end < this.items.length) && (this.autoplay == true)){

                this.transitioning = 1;
                //setTimeout(5000);
                this.start = this.start + this.grid_limit;
                this.end = this.end + this.grid_limit;
                var self = this;
                setTimeout(function(){
                 self.transitioning = 0;
                 self.autoPlay();
                }, 1500);
               
                }

			},
			loadMore(){
                if(this.end < this.items.length){

                this.transitioning = 1;
                //setTimeout(5000);
                this.start = this.start + this.grid_limit;
                this.end = this.end + this.grid_limit;
                var self = this;
                setTimeout(function(){
                 self.transitioning = 0;
                }, 300);
               
                }
            },
            loadLess(){
                if(this.start >= 2){
                    this.transitioning = 1;
                this.start = this.start - this.grid_limit;
                this.end = this.end - this.grid_limit;
                var self = this;
                setTimeout(function(){
                 self.transitioning = 0;
                }, 300);
               
                }
            },
		}
	}
</script>

<template>
	<b-row>
		       <b-col md="12" class="mx-auto" :class="{'pb-3' : transitioning == 1}">
                     <transition name="slide-fadeleft">
                    <b-row v-if="transitioning == 0">
               		<b-col md="12" class="px-4">
               			<b-row class="px-3" v-if="layout_type == 'horizontal'">
		                <b-col :class="{'col-md-6': grid_limit == 2, 'col-md-4': grid_limit == 3, 'col-md-7': grid_limit == 4, 'col-md-12': grid_limit == 1}" class="mt-4 z-index-1" v-for="item in items.slice(start, end)">
		                     <b-card no-body class="overflow-hidden news-card shadow" >
		                    <b-row no-gutters>
		                      <b-col :class="{'col-md-4': grid_limit == 2, 'col-md-6': grid_limit == 3, 'col-md-3': grid_limit == 4}" >
		                        <b-card-img-lazy v-if="item.cover_image" :src="item.cover_image" alt="Image" class="rounded-0"></b-card-img-lazy>
		                        <b-card-img-lazy v-else :src="'/images/product-placeholder.png'" alt="Image" class="rounded-0"></b-card-img-lazy>
		                      </b-col>
		                      <b-col :class="{'col-md-8': grid_limit == 2, 'col-md-6': grid_limit == 3, 'col-md-5': grid_limit == 4}" >
		                        <b-card-body :title="item.name">
		                          <!--<b-card-text>
		                             <p v-html="item.short_description.substring(0,120)+'...'"></p>
		                           <a class="btn btn-primary mb-1 float-right">Link</a>
		                          </b-card-text>-->
		                        </b-card-body>
		                       
		                      </b-col>
		                    </b-row>
		                  </b-card>
		                </b-col>
		            </b-row>
		            <b-row class="px-3" v-if="layout_type == 'vertical'">
		                <b-col class="z-index-1" :class="{'col-md-6': grid_limit == 2, 'col-md-4': grid_limit == 3,  'col-md-3': grid_limit == 4, 'col-md-12': grid_limit == 1}" v-for="(item, index) in items.slice(start, end)" >
		                     <b-card no-body class="overflow-hidden news-card shadow-none border-radius-1em border" @mouseenter="hoverIndex = index" @mouseleave="hoverIndex = -1" >

		                    <b-row no-gutters>
		                     <transition name="fade-left">
		                    	<div class="col-md-12 position-absolute z-index-2" v-if="hoverIndex == index"> 
                              	<a id="tooltip-add"  @click="quickviewproduct = product" class="mt-3 ml-3 btn btn-primary font-size-16 btn-rounded ml-auto float-left"><i class="mdi mdi-eye-outline"></i></a>
                              </div>
                          </transition>
		                      <b-col lg="12" v-if="use == 'public'">
		                        <router-link v-if="typeof item.cover_image !== 'string' && item.cover_image !== null" :to="linkprefix+(item.company !== null && use == 'public' ? item.company.nickname : '' )+linksuffix+'/'+item.url_rewrite">
				            	<b-card-img-lazy v-if="'gallery' in item.cover_image" :src="storageurl+item.cover_image.gallery" :class="{'border-radius-1em' : roundedBorders}"></b-card-img-lazy>
				            	<b-card-img-lazy v-else-if="'thumb' in item.cover_image" :src="storageurl+item.cover_image.thumb" :class="{'border-radius-1em' : roundedBorders}"></b-card-img-lazy>
				            	<b-card-img-lazy v-else-if="'src' in item.cover_image" :src="storageurl+item.cover_image.src" :class="{'border-radius-1em' : roundedBorders}"></b-card-img-lazy>
				            	<b-card-img-lazy v-else :src="storageurl+item.cover_image" :class="{'border-radius-1em' : roundedBorders}"></b-card-img-lazy>
				            	</router-link>
				            	<router-link v-else :to="linkprefix+(item.company !== null && use == 'public' ? item.company.nickname : '' )+linksuffix+'/'+item.url_rewrite">
				            	<b-card-img-lazy src="/images/product-placeholder.png" :class="{'border-radius-1em' : roundedBorders}"></b-card-img-lazy>
				            	</router-link>
		                     <transition name="fade-right">
		                        <div class="mt-auto mb-3 mx-2 d-flex position-absolute bottom-0 right-0 left-0" v-if="hoverIndex == index">
                              	<a id="tooltip-add"  @click="selectedCategory = list, showStoneDetails = 1"class="mr-2 ml-auto btn btn-primary font-size-16 btn-rounded"><i class="mdi mdi-heart-outline"></i></a>
                              	<a id="tooltip-add"  @click="selectedCategory = list, showStoneDetails = 1"class="mr-3 ml-0 btn btn-primary font-size-16 btn-rounded"><i class="mdi mdi-shopping-outline"></i></a>
              					</div>
              				</transition>
		                      </b-col>
		                      <b-col lg="12" v-else >
		                        <a v-if="typeof item.cover_image !== 'string' && item.cover_image !== null" :href="linkprefix+(item.company !== null && use == 'public' ? item.company.nickname : '' )+linksuffix+'/'+item.url_rewrite">
				            	<b-card-img-lazy v-if="'gallery' in item.cover_image" :src="storageurl+item.cover_image.gallery" :class="{'border-radius-1em' : roundedBorders}"></b-card-img-lazy>
				            	<b-card-img-lazy v-else-if="'thumb' in item.cover_image" :src="storageurl+item.cover_image.thumb" :class="{'border-radius-1em' : roundedBorders}"></b-card-img-lazy>
				            	<b-card-img-lazy v-else-if="'src' in item.cover_image" :src="storageurl+item.cover_image.src" :class="{'border-radius-1em' : roundedBorders}"></b-card-img-lazy>
				            	<b-card-img-lazy v-else :src="storageurl+item.cover_image" :class="{'border-radius-1em' : roundedBorders}"></b-card-img-lazy>
				            	</a>
				            	<a v-else :href="linkprefix+(item.company !== null && use == 'public' ? item.company.nickname : '' )+linksuffix+'/'+item.url_rewrite">
				            	<b-card-img-lazy src="/images/product-placeholder.png" :class="{'border-radius-1em' : roundedBorders}"></b-card-img-lazy>
				            	</a>
		                     <transition name="fade-right">
		                        <div class="mt-auto mb-3 mx-2 d-flex position-absolute bottom-0 right-0 left-0" v-if="hoverIndex == index">
                              	<a id="tooltip-add"  @click="selectedCategory = list, showStoneDetails = 1"class="mr-2 ml-auto btn btn-primary font-size-16 btn-rounded"><i class="mdi mdi-heart-outline"></i></a>
                              	<a id="tooltip-add"  @click="selectedCategory = list, showStoneDetails = 1"class="mr-3 ml-0 btn btn-primary font-size-16 btn-rounded"><i class="mdi mdi-shopping-outline"></i></a>
              					</div>
              				</transition>
		                      </b-col>
		                      <b-col lg="12">
		                        <b-card-body>
		                        	<h4 class="card-title d-flex" :class="{'text-truncate font-size-14' : truncate_titles == true}">{{item.name}}</h4>
		                    
		                          
		                          <div class="d-flex flex-row">
		                         <span class="badge badge-light my-auto mr-auto bg-light p-2 font-size-12 border-radius-1em">
		                         	£ {{addTax(item, item.sales_price)}}
		                         </span>
		                         <transition name="fade-right">
		                         <p  v-if="hoverIndex == index" class="text-muted float-right ml-auto my-auto">
		                         	<b-form-rating style="width: 40%;" id="rating-inline" readonly variant="warning" class="bg-transparent pl-4 ml-auto" inline no-border size="sm" :value="item.reviews_avg_rating"></b-form-rating> 
			                
			                  </p>
			              			</transition>
			              		</div>
		                        </b-card-body>
		                      </b-col>
		                    </b-row>
		                  </b-card>
		                </b-col>
		            </b-row>
		        </b-col>
                <div class="d-flex position-absolute h-100 z-index-0 mx-0 px-0">
                	<a href="javascript:;" class="less-btn my-auto mr-auto" @click="loadLess()"><i class="bx bx-chevron-left font-size-42"></i></a>
                	<a href="javascript:;" class="more-btn my-auto ml-auto" @click="loadMore()"><i class="bx bx-chevron-right font-size-42"></i></a>
            	</div>
            </b-row>
            <b-row v-else class="loader-row border border-radius-1em" style="min-height: 400px;">
                <b-col md="12" class="d-flex mx-auto my-auto justify-content-center">
                 <b-spinner style="width: 5rem; height: 5rem;" variant="primary" label="Spinning"></b-spinner>
                </b-col>
            </b-row>
            </transition>
               
                </b-col>
	</b-row>
</template>