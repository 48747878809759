<script type="text/javascript">
	import CKEditor from "@ckeditor/ckeditor5-vue";
	import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
	export default {
		components: {
			ckeditor: CKEditor.component,
		},
		data(){
			return{
				editor: ClassicEditor,
				loadingForm: true,
			}
		},
		props: {
			user: {
				type: Object,
			},
			email: {
				type: Object,
				default: {
					id: '',
					notification_thread_id: '',
					email_to: [],
					email_cc: [],
					email_bcc: [],
					subject: '',
					message: '',
					replying_to: [],
				}
			}
		},
		mounted(){
			this.recaptcha();
			this.loadingForm = false;
		},
		methods: {
			async recaptcha() {
     			 // (optional) Wait until recaptcha has been loaded.
		      await this.$recaptchaLoaded()

		      // Execute reCAPTCHA with action "login".
		      const token = await this.$recaptcha('login');
		      document.getElementById('recaptcha').value = token;
		      // Do stuff with the received token.
		    
		    },
			sendEnquiry(){
				
				//var notspam = this.recaptcha();
				this.loadingForm = true;
				var postForm = new FormData(document.getElementById("contact-form"));
				axios.post('/api/company/communication/enquiry/send-mail', postForm, {headers: {
					'Authorization' : 'Bearer '+this.user.api_token
				}}).then(response => {
					this.enquiryResponse = response.data;
					let self = this
					setTimeout(function() {
						self.loadingForm = false;
					}, 1500);
				}).catch(error => {
					setTimeout(function() {
						self.loadingForm = false;
					}, 1500);
				});
			}
		}
	}
</script>

<template>
	<b-row>
		<b-col>

			<b-card class="border-radius-1em border shadow-none" v-if="email.replying_to.length > 0">
			<b-button v-b-toggle.collapse-1 variant="outline-light" class="w-100 text-left border-none px-0"><h5>You're Replying to the following message:</h5></b-button>
			
			<b-collapse id="collapse-1" class="mt-2" visible>
			
				
				<blockquote>
					{{email.replying_to[0].data.message}}
				</blockquote>
					<hr>
				<h5>
					From:
					{{email.replying_to[0].data.name}}
					{{email.replying_to[0].data.email}}
				</h5>
			
			</b-collapse>
			</b-card>
			<b-card class="border-radius-1em shadow-none">
				<b-overlay :show="loadingForm">
					<form id="contact-form">
						<input type="hidden" name="recaptcha" id="recaptcha">
						<b-form-group label="To">
							<b-form-tags id="to" v-model="email.email_to" placeholder="Enter Email Addresses"  type="email" name="email_to">
							</b-form-tags>
						</b-form-group>
						<b-form-group class="mt-3" label="Cc:">
							<b-form-tags id="cc" v-model="email.email_cc" placeholder="Enter Email Addresses"  type="email" name="email_cc">
							</b-form-tags>
						</b-form-group>
						<b-form-group class="mt-3" label="Bcc:">
							<b-form-tags id="bcc" v-model="email.email_bcc" placeholder="Enter Email Addresses"  type="email" name="email_bcc">
							</b-form-tags>
						</b-form-group>
						<b-form-group class="mt-3" label="Subject">
							<b-form-input v-model="email.subject" type="text" name="subject">
							</b-form-input>
						</b-form-group>
						<b-form-group class="mt-3 mb-3" label="Message">
							<ckeditor id="message" :editor="editor" v-model="email.message" >{{email.message}}</ckeditor>
							<input type="hidden" :value="email.message" name="message">
						</b-form-group>
						<b-button variant="primary" class="btn-rounded btn-block" @click="sendEnquiry()">
		          Send
		          <i class="fab fa-telegram-plane ms-1"></i>
		        </b-button>
					</form>
			</b-overlay>
		</b-card>
		</b-col>
	</b-row>
</template>