<script type="text/javascript">
	import QrcodeVue from 'qrcode.vue';
	import VueBarcode from '@chenfengyuan/vue-barcode';
	import VueSlideBar from "vue-slide-bar";
	import simplebar from "simplebar-vue";
//	import VueHtml2pdf from 'vue-html2pdf';
	export default{
		components: {QrcodeVue, VueBarcode, VueSlideBar, simplebar},
		props: {
			code_type: {
				type: String,
				default: 'barcode'
			},
			barcode_value: {
				type: Object,
				default: {
					EAN13: 234567891011,
					UPC: 134567891011,
					CODE128: 134567891011
				}
			},
			qr_value: {
				type: String,
				default: '1234567891011'
			},
			barcode_type: {
				type: String,
				default: 'CODE128'
			},
			background_color: {
				type: String,
				default: '#ffffff'
			},
			code_color:  {
				type: String,
				default: '#000000'
			},
			code_size: {
				type: Number,
				default: '1'
			},
			code_quality: {
				type: String,
				default: 'H'
			},
			display_code: {
				type: Number,
				default: true
			},
		},
		data(){
			return {
				mmconversion: 3.7795275591,
				label_index: 0,
				show_image: true,
				scale: 1,
				scale_qr: 1,
				scale_font: 1,
				scale_image: 1,
				font_size: 14,
				image_size: 80,
				code_types: ['barcode', 'QR', 'QR & Barcode'],
				label_size: '',
				label_margin: 0,
				label_type: 'login-card',
				label_types: [
					{text: 'Address', value: 'address'},
				],
				label_sizes: 
					{
						'login-card' : [
							{text: 'Standard Credit Card - 85.6mm x 53.98mm', value: {width: 85.6, height: 53.98}},
						]
					}

			}
		},
		methods: {
			print(){

			},
			generateReport () {
            this.$refs.html2Pdf.generatePdf()
	        },
	        printInvoice(){
	        	this.$bvModal.hide('invoice-modal');
	        	this.showInvoiceLayout = true;
	        	
	        	//var printContents = document.getElementById('invoiceArea').innerHTML;
				     //var originalContents = document.getElementById('print-window').innerHTML;

				     //document.getElementById('print-window').innerHTML = printContents;

				     window.print();

				     //document.getElementById('print-window').innerHTML = originalContents;
				     //this.showInvoiceLayout = false;
				     //this.$bvModal.hide('invoice-modal');
	        }
		}
	}

</script>
<template>
	<div>
		<b-row>
			<b-col md="9">
		<b-row class="mx-auto border-bottom h-100">
			<div class="border py-4 bg-light d-flex justify-content-center align-items-center">
				<div class="d-flex position-absolute top-0 right-0 left-0 m-2" v-if="$attrs.addresses.length > 0">
					<b-button variant="primary" class="btn btn-primary btn-rounded rounded-circle mr-auto" :disabled="label_index == 0" @click="label_index--, $attrs.address = $attrs.addresses[label_index]">
						<i class="bx bx-chevron-left"></i>
					</b-button>
					<b-button variant="primary" class="btn btn-primary btn-rounded rounded-circle ml-auto" :disabled="(label_index + 1) == $attrs.addresses.length" @click="label_index++, $attrs.address = 
					$attrs.addresses[label_index]">
						<i class="bx bx-chevron-right"></i>
					</b-button>

				</div>
				<div v-if="(label_size !== '') && (label_type == 'login-card')" class="d-flex justify-content-center align-items-center mx-auto my-4" >
					<div id="addressPrintArea" class="border border-radius-1em bg-white d-flex flex-column justify-content-center shadow-sm p-1 overflow-hidden" :style="{'width' : label_size.width * scale +'mm', 'height': label_size.height * scale +'mm', 'padding' : label_margin +'px !important'}" v-if="$attrs.address">
						<div class="d-flex" v-if="$attrs.address.area == 'front'">
							<img v-if="show_image == true" :src="$attrs.company.logo" class="border m-2" :width="image_size*scale*scale_image+'px'" :height="image_size*scale*scale_image+'px'">
							<img v-if="show_image == true" :src="$attrs.address.user.avatar" class="border ml-auto mr-2 mt-2" :width="image_size*scale*scale_image+'px'" :height="image_size*scale*scale_image+'px'">
						</div>
						
						<div class="d-flex mt-auto mb-2 mx-2">
							<div class="d-flex flex-column">
							<h6 v-if="$attrs.address.area == 'front'" class="mb-0 mt-auto" style="font-size-adjust: 0.5;" :style="{'font-size' : font_size*scale*scale_font+'px'}">
							{{$attrs.address.user.name}} {{$attrs.address.user.lastname}}
							</h6>
							<p v-if="$attrs.address.area == 'front'" class="mb-0" style="font-size-adjust: 0.5;" :style="{'font-size' : font_size*scale*scale_font+'px'}">
							{{$attrs.address.user.email}}
							</p>
							</div>
								<div class="text-right ml-auto my-auto float-right" v-if="$attrs.address.area == 'front'">
					          		<qrcode-vue :value="qr_value" :size="80" level="L" foreground="#000000" background="#ffffff" margin="0" class="" style="margin-bottom: -2px; margin-top: -2px;" />
					          		<small>
					          		{{qr_value["id"]}}
					          		</small>
					      	  </div>
					    </div>
						<div class="d-flex w-100 border-bottom py-3 bg-dark mt-4 mb-auto" v-if="$attrs.address.area == 'back'">

						</div>
						<div class="d-flex flex-column w-100 border-bottom py-2 mt-auto" v-if="$attrs.address.area == 'back'">
				          	 <div class="text-right mr-3 ml-auto my-auto ">
					          <qrcode-vue :value="qr_value" :size="image_size*scale*scale_image" level="L" foreground="#000000" background="#ffffff" margin="0" class="px-1" style="margin-bottom: -2px; margin-top: -2px;" />
					          <small>
					          {{qr_value["id"]}}
					          </small>
					      	  </div>
				          	  <div class="mt-auto ml-auto" style="min-width: 100%; max-width: 100%;">
				          	  	 <VueBarcode class="w-100" :value="barcode_value" :options="{ displayValue: true, format: 'CODE128', lineColor: '#000000', width: 1, height: 40, background: '#FFFFFF', fontSize: 10, marginTop: 0 }"></VueBarcode>
				          	  </div>
					          
				      		</div>
						
					</div>

				</div>
				<div v-if="label_size == ''" class="my-4 justify-content-center align-items-center">
					<div class="w-50 bg-white mx-auto p-4 text-center border-radius-1em shadow-sm">
						<h6 class="m-2">Select Label options and label template will show here.</h6>
					</div>
				</div>
				<span class="badge badge-primary bg-primary position-absolute left-0 bottom-0 m-3 font-size-12" v-if="$attrs.addresses.length > 0">You are previewing the {{$attrs.address.area}} of your user card</span>
				<!--<span class="badge badge-primary bg-primary position-absolute right-0 bottom-0 m-3 font-size-12" v-if="$attrs.addresses.length > 0">You are bulk printing {{$attrs.addresses.length}} tickets</span>-->
			</div>
		</b-row>
	</b-col>
	<b-col md="3">
		<b-card class="border-radius-1em border shadow-none mb-1" no-body>
			<div style="overflow-x: hidden;">
			<b-row class="p-3">
				<b-col md="12">
					<h5>Label Options</h5>
					<hr>
					<b-row>
						<b-col md="12">
							<!--<b-form-group class="mb-3" label="Code Type">
								<b-form-select class="form-control" :options="code_types" v-model="code_type">
								</b-form-select>
							</b-form-group>-->
							<b-form-group class="d-flex my-2" label="Show QR">
								<div
                              class="form-check form-switch form-switch-sm mb-0 mx-auto p-0 mt-auto d-flex float-right"
                              dir="ltr"
                            >
                            <input
                                class="form-check-input mx-auto my-auto" 
                                type="checkbox"
                                id="SwitchCheckSizesm"
                               
                                @click='checkAll()'
                                name="active"
                              />
                          		</div>
							</b-form-group>
							<b-form-group class="d-flex my-2" label="Show Barcode">
								<div
                              class="form-check form-switch form-switch-sm mb-0 mx-auto p-0 mt-auto d-flex float-right"
                              dir="ltr"
                            >
                            <input
                                class="form-check-input mx-auto my-auto" 
                                type="checkbox"
                                id="SwitchCheckSizesm"
                               
                                @click='checkAll()'
                                name="active"
                              />
                          		</div>
							</b-form-group>
							<b-form-group class="d-flex my-2" label="Show Image">
								<div
                              class="form-check form-switch form-switch-sm mb-0 mx-auto p-0 mt-auto d-flex float-right"
                              dir="ltr"
                            >
                            <input
                                class="form-check-input mx-auto my-auto" 
                                type="checkbox"
                                id="SwitchCheckSizesm"
                               
                                @click='checkAll()'
                                name="active"
                              />
                          		</div>
							</b-form-group>
							<b-form-group class="d-flex my-2" label="Show Name">
								<div
                              class="form-check form-switch form-switch-sm mb-0 mx-auto p-0 mt-auto d-flex float-right"
                              dir="ltr"
                            >
                            <input
                                class="form-check-input mx-auto my-auto" 
                                type="checkbox"
                                id="SwitchCheckSizesm"
                               
                                @click='checkAll()'
                                name="active"
                              />
                          		</div>
							</b-form-group>
							<!--<b-form-group class="mt-2" label="Label Types">
								<b-form-select class="form-control" :options="label_types" v-model="label_type">
								</b-form-select>
							</b-form-group>-->
						</b-col>
						<b-col md="12">
							<b-form-group class="" label="Label Size">
								<b-form-select class="form-control" :options="label_sizes[label_type]" v-model="label_size" @input="scale_qr = 1">
								</b-form-select>
							</b-form-group>
						</b-col>
						<b-col md="12">
							<h5 class="mt-4">Style Options</h5>
							<hr>
							<b-form-group label="Resize Label">
								<div class="d-flex">
							 	<b-form-input id="formControlRange" class="form-control-range form-range" type="range" v-model="scale" min="0.5" step="0.1" max="5"></b-form-input>
							 	<a class="btn btn-outline-light" @click="scale = 1">
							 		<i class="bx bx-reset"></i>
							 	</a></div>
							 </b-form-group>
							<!-- <b-form-group label="Resize QR Code">
							 	<div class="d-flex">
							 	<b-form-input id="formControlRange" class="form-control-range form-range" type="range" v-model="scale_qr" min="0.5" step="0.1" max="5"></b-form-input>
							 	<a class="btn btn-outline-light" @click="scale_qr = 1">
							 		<i class="bx bx-reset"></i>
							 	</a></div>
							 </b-form-group>-->
							 <b-form-group label="Resize Font">
							 	<div class="d-flex">
							 	<b-form-input id="formControlRange" class="form-control-range form-range" type="range" v-model="scale_font" min="0.5" step="0.1" max="5"></b-form-input>
							 	<a class="btn btn-outline-light" @click="scale_font = 1">
							 		<i class="bx bx-reset"></i>
							 	</a></div>
							 </b-form-group>

							 <b-form-group label="Label Margin">
							 	<div class="d-flex">
							 	<b-form-input id="formControlRange" class="form-control-range form-range" type="range" v-model="label_margin" min="0" step="1" max="100"></b-form-input>
							 	<a class="btn btn-outline-light" @click="scale_font = 1">
							 		<i class="bx bx-reset"></i>
							 	</a></div>
							 </b-form-group>

							 <!--<b-form-group>
							 	<a class="btn btn-light btn-rounded">
							 		Rotate Label
							 	</a>
							 </b-form-group>-->
						</b-col>
						
					</b-row>
				
				</b-col>
			</b-row>
			</div>
		</b-card>
		<a class="btn btn-success btn-block btn-rounded" @click="print()">
			<i class="bx bx-printer"></i> Print
		</a>
		<a class="btn btn-success btn-block btn-rounded mt-2" @click="generateReport()">
			<i class="bx bx-printer"></i> Generate PDF & Print
		</a>
	</b-col>
</b-row>
<b-row v-if="$attrs.address">
	<b-col>
				<vue-html2pdf
		 		id="invoiceArea"
        :show-layout="false"
        :float-layout="true"
        :enable-download="false"
        :preview-modal="true"
        v-if="$attrs.address !== ''"
        :filename="$attrs.address.name"
        :pdf-quality="2"
        :manual-pagination="false"
        :pdf-content-width="label_size.width"
        :paginate-elements-by-height="label_size.height"
        ref="html2Pdf"
    >
        <section slot="pdf-content"  >
        	<section class="pdf-item">
					<div id="addressPrintArea" class="border bg-white d-flex flex-column justify-content-center shadow-sm p-1 overflow-hidden" :style="{'width' : label_size.width * scale +'mm', 'height': label_size.height * scale +'mm', 'padding' : label_margin +'px !important'}" >
						
						<div class="d-flex flex-column" v-if="$attrs.addresses !== ''" v-for="addressitem in $attrs.addresses.slice(1, ($attrs.addresses.length - 1))">

						<p  style="font-size-adjust: 0.5;" :style="{'font-size' : font_size*scale*scale_font+'px'}">
							{{addressitem[0].user.name}} {{addressitem[0].user.lastname}}
						</p>
						<p  style="font-size-adjust: 0.5;" :style="{'font-size' : font_size*scale*scale_font+'px'}">
							{{addressitem[0].user.email}}
						</p>
						</div>
					</div>
				</section>
					<div class="html2pdf__page-break"/>
				</section>
			</vue-html2pdf>
	</b-col>
</b-row>
	</div>
</template>

<style scoped>
	.custom-control.custom-radio{
		padding-left: 0 !important;
	}
	.custom-control.custom-radio input{
		padding-right: 5px !important;
	}
</style>