<script type="text/javascript">
	import simplebar from 'simplebar-vue'
	export default {
		props: {
			elements: {
				type: Array,
				default: () => []
			},
			windowInnerHeight: {
				type: Number,
				default: () => 800
			}
		},
		data(){
			return {
				compiledStyle: '',
			}
		},
		components: {simplebar},
		methods:{
			closeCompiler(){
				this.$emit('close-compiler');
			},
			compileStyleElements(){
			var style = '';
	           for(var i = 0; i < this.elements.length; i++){
	           	var key1 = Object.keys(this.elements[i]);
	           	
	           
	           		var subkeys = Object.keys(this.elements[i][key1[0]]);
	           		for(var subki = 0; subki < subkeys.length; subki++){
	           			var key2 = [];
	           			key2.push(subkeys[subki]);
	           			style += ' #'+key1[0].replaceAll('_', '-')+'';
	           		if(key2[0]){
				 		 style += ' .'+key2[0].replaceAll('_', '-')+'';
				 	
				 	console.log( this.elements[i][key1[0]][key2[0]]);
				 		var subkey = '';
					 	for(var ei = 0; ei < this.elements[i][key1[0]][key2[0]].length; ei++ ){
					 		
					 		var key3 = Object.keys(this.elements[i][key1[0]][key2[0]][ei]);
					 		
					 		if(ei == 0){
					 			style += '{\n';
					 		}
					 		if((key3[0].includes('__')) && (this.elements[i][key1[0]][key2[0]][ei][key3[0]].value && this.elements[i][key1[0]][key2[0]][ei][key3[0]].value !== '')){
					 				if((subkey == '') || (subkey !== key3[0].split('__')[0])){
						 				style += '} \n';
						 				style += '#'+key1[0].replaceAll('_', '-')+' .'+key2[0].replaceAll('_', '-')+' .'+key3[0].split('__')[0].replaceAll('_', '-')+'{\n';
					 				}
					 				subkey = key3[0].split('__')[0];
					 		}
					 		if(!key3[0].includes('_label')){
					 		if(key3[0].includes('font_family')){
					 			style += ''+(key3[0]).replaceAll(''+subkey+'__', '').replaceAll('_', '-').replaceAll('colour', 'color')+': "'+this.elements[i][key1[0]][key2[0]][ei][key3[0]].value.family+'"';
					 			style += '; \n'

					 		}else if(key3[0] == 'border' || key3[0].split('_')[key3[0].split('_').length - 1] == 'border'){
					 			style += ''+(key3[0]).replaceAll(''+subkey+'__', '').replaceAll('_', '-').replaceAll('colour', 'color')+'-top: '+this.elements[i][key1[0]][key2[0]][ei][key3[0]].top+'px';
					 			style += '; \n'
					 			style += ''+(key3[0]).replaceAll(''+subkey+'__', '').replaceAll('_', '-').replaceAll('colour', 'color')+'-bottom: '+this.elements[i][key1[0]][key2[0]][ei][key3[0]].bottom+'px';
					 			style += '; \n'
					 			style += ''+(key3[0]).replaceAll(''+subkey+'__', '').replaceAll('_', '-').replaceAll('colour', 'color')+'-left: '+this.elements[i][key1[0]][key2[0]][ei][key3[0]].left+'px';
					 			style += '; \n'
					 			style += ''+(key3[0]).replaceAll(''+subkey+'__', '').replaceAll('_', '-').replaceAll('colour', 'color')+'-right: '+this.elements[i][key1[0]][key2[0]][ei][key3[0]].right+'px';
					 			style += '; \n'

					 		}else if(key3[0] == 'border_radius' || key3[0].split('_')[key3[0].split('_').length - 1] == 'border_radius'){
					 			style += ''+(key3[0]).replaceAll(''+subkey+'__', '').replaceAll('_', '-').replaceAll('colour', 'color')+'-top: '+this.elements[i][key1[0]][key2[0]][ei][key3[0]].top+'px';
					 			style += '; \n'
					 			style += ''+(key3[0]).replaceAll(''+subkey+'__', '').replaceAll('_', '-').replaceAll('colour', 'color')+'-bottom: '+this.elements[i][key1[0]][key2[0]][ei][key3[0]].bottom+'px';
					 			style += '; \n'
					 			style += ''+(key3[0]).replaceAll(''+subkey+'__', '').replaceAll('_', '-').replaceAll('colour', 'color')+'-left: '+this.elements[i][key1[0]][key2[0]][ei][key3[0]].left+'px';
					 			style += '; \n'
					 			style += ''+(key3[0]).replaceAll(''+subkey+'__', '').replaceAll('_', '-').replaceAll('colour', 'color')+'-right: '+this.elements[i][key1[0]][key2[0]][ei][key3[0]].right+'px';
					 			style += '; \n'

					 		}else if(this.elements[i][key1[0]][key2[0]][ei][key3[0]].value && this.elements[i][key1[0]][key2[0]][ei][key3[0]].value !== ''){
					 		 style += ''+(key3[0]).replaceAll(''+subkey+'__', '').replaceAll('_', '-').replaceAll('colour', 'color')+': '+this.elements[i][key1[0]][key2[0]][ei][key3[0]].value;
					 		 if(this.elements[i][key1[0]][key2[0]][ei][key3[0]].units){
					 		 	style += this.elements[i][key1[0]][key2[0]][ei][key3[0]].units;
					 		 }
					 		 style += '; \n'
					 		}
					 		}
					 		if((key3[0].includes('__')) && (this.elements[i][key1[0]][key2[0]][ei][key3[0]].value && this.elements[i][key1[0]][key2[0]][ei][key3[0]].value !== '')){
					 				
					 		}
					 		if(ei == (this.elements[i][key1[0]][key2[0]].length - 1)){
					 		style += '}\n';
					 		}
					 	}
					 	
				 	}
				 }
					//var key3 = Object.keys(this.elements[i][key1[0]][key2[0]]);
					//style += ' .'+key3[0]+'';
	          		/*for(var sei = 0; sei < this.elements[i][key1[0]][key2[0]].length; sei++){
	          			style += ' .test;'
				 		
				 		
				 	}*/
				 
				}
				this.compiledStyle = ''+style.replaceAll('.field', 'input').replaceAll('button', 'btn').replaceAll('\n', '')+'';
				return '<p>'+style.replaceAll('.field', 'input').replaceAll('button', 'btn')+'</p>';
			},
			filterStylesKey(styles, key){
	            for(var i = 0; i < styles.length; i++){
	               var keys = Object.keys(styles[i])
	               if(keys.includes(key)){
	                 return i;
	               }
	            }
	        },
	        renderComponent: function(el) {
			      return {
			        template: `<${el}><slot></${el}>`,
			     
			      }
			    },
		},
		computed: {
			renderComponent: function(el) {
			      return {
			        template: `<${el}><slot></${el}>`,
			     
			      }
			    }
		},

	}

</script>

<template>
	
	<simplebar class="position-absolute top-0 left-0 right-0 bottom-0 bg-white" style="height: 100%;">
		<b-row>
			<b-col md="12" class="mt-4 d-flex">
				<h4 class="my-auto px-3">Stylesheet Sample</h4>

				<a class="btn btn-outline-light btn-rounded rounded-circle ml-auto my-auto" @click="closeCompiler()">
					<i class="mdi mdi-close"></i>
				</a>
			</b-col>
			<b-col md="12">
				
				<pre class="bg-light m-3 p-3 border" v-html="compileStyleElements()"></pre>
			</b-col>
		</b-row>
		
	</simplebar>

</template>

