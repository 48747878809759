<template>
	<div class="p-3 mb-3">
		<b-overlay
		:show="loadingForm"
		variant="white"
                        opacity="0.85"
                        blur="1px"
                        rounded="md"
                        class="w-100 d-flex flex-column justify-content-center"
                        style="min-height: 50px"
	>
<b-form-group label="" v-if="'data' in all_collections && all_collections.data.length > 0">
                  <b-form-checkbox
                    v-for="collection in all_collections.data"
                   	class="category-checkbox mb-2"
                    :id="'collection-'+collection.id"
                    :name="'collection-'+collection.id"
                    :key="collection.id"
                    :value="collection.id"
                    stacked
                    @change="updateCollections(collection.id)"
                    
                  >
                  <img v-if="collection.image" :src="storageurl + '/' + collection.image" class="avatar-xs rounded-circle opacity-75 mr-2">
                    <img v-else src="/images/product-placeholder.png" class="avatar-xs rounded-circle opacity-75 mr-2">
                    {{ collection.name }}
                  </b-form-checkbox>
                  <a class="btn btn-outline-light" href="javascript: void(0);" @click="limit = parseFloat(limit) + 10, searchResource()" v-if="all_collections.total >= limit">Load More</a>
       			  <a class="btn btn-outline-light" href="javascript: void(0);" @click="limit = parseFloat(limit) - 10, searchResource()" v-if="limit > 10">Load Less</a>
              </b-form-group>
                <NothingHere v-else></NothingHere>
        
        </b-overlay>
    </div>
</template>
<script>
	export default{
		name: 'FilterProductCollections',
		data(){
			return{
				collections_error: '',
				selectedcollections: [],
				loadingForm: false,
				currentPage: 1,
				limit: 10,
				storageurl: 'https://'+this.$attrs.company.nickname+'.s3.eu-west-2.amazonaws.com',
			}
		},
		props: {
			all_collections: {
				type: Array,
				default: () => null
			}
		},
		methods:{
			updateCollections(value){
				if(this.selectedcollections.length > 0){
			      var filterList = this.selectedcollections.filter(item => item == value);
			      console.log(filterList);
			        if(filterList.length == 0){
			          this.selectedcollections.push(value);
			        }else{
			          var filterIndx =this.selectedcollections.findIndex(item => item == value);
			          console.log(filterIndx);
			          this.selectedcollections.splice(filterIndx, 1);
			        }
			      }else{
			        //this.selectedcollections = [];
			        this.selectedcollections.push(value);
			      }
				this.$emit('collections-filtered', this.selectedcollections);
			}, 
			searchResource(){
				this.loadingForm = true;
				if(this.all_collections == null){
						axios.get('/api/company/product/collections?page='+this.currentPage+'&limit='+this.limit+'&sortby=id&order=desc', {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.api_token
              }}).then(response => {
							this.all_collections = response.data;
							var self = this;
			                  setTimeout(function(){
			                  self.loadingForm = !self.loadingForm;
			                  }, 150);
						}).catch(error => {
							this.collections_error = error.data;
							var self = this;
			                  setTimeout(function(){
			                  self.loadingForm = !self.loadingForm;
			                  }, 150);
						});
			 }else{
			 	var self = this;
			                  setTimeout(function(){
			                  self.loadingForm = !self.loadingForm;
			                  }, 150);
			 }
			}
		},
		created(){
			this.searchResource();
		}
	}
</script>