<script>
//import Vertical from "./vertical";
import WebsiteWrapper from "./websitewrapper";
import { EventBus } from '../website-app'


export default {
  components: { WebsiteWrapper},
  data() {
    return {
      currencykey: process.env.MIX_CURRENCY_CONVERTER,
      recaptcha_token: '',
      selected_currency: '',
      selected_currency_symbol: '',
      exchange_rates: '',
      currencies: [],
    };
  },
  props: {
    user: {
      type: Object
    },
    domain: {
      type: Object
    }
  },
  mounted(){
   var storedcurrency = window.localStorage.getItem("currency");
    if(storedcurrency){
      var parsecurrency = JSON.parse(storedcurrency);
      console.log(parsecurrency["currency_code"]);
      this.convertCurrency(parsecurrency["currency_code"], null, parsecurrency["currency_symbol"]);
    }else if(this.selected_currency == ''){
      this.convertCurrency('GBP', null, '£');
    }else{
       this.convertCurrency(this.selected_currency, null, this.selected_currency_symbol);
    }
    let self = this;
    EventBus.$on('currency-updated', function (symbol, currency, rates) {
      self.selected_currency = currency;
      self.exchange_rates = rates.data;
      self.selected_currency_symbol = symbol; 
      //var currencyItems = document.getElementsByClassName('currency');
      /*for(var i = 0; i < currencyItems.length; i++){
          document.getElementsByClassName('currency')[i].innerHTML = symbol;
      }*/
      window.localStorage.setItem("currency", JSON.stringify({currency_code: currency, currency_symbol: symbol}))
    });
  },
  created(){
    this.getCurrencies();
    var storedcurrency = window.localStorage.getItem("currency");
    if(storedcurrency){
      var parsecurrency = JSON.parse(storedcurrency);
      console.log(parsecurrency["currency_code"]);
      this.convertCurrency(parsecurrency["currency_code"], null, parsecurrency["currency_symbol"]);
    }else if(this.selected_currency == ''){
      this.convertCurrency('GBP', null, '£');
    }
  },
  computed: {
    layoutType() {
        return this.$root.layout?.type;
    }
  },
  methods: {
    getCurrencies(){
        axios.get('/website-api/currencies').then(response => {
          this.currencies = response.data;
        }).catch(error => {

        });
      },
      convertCurrency(from, to, symbol){
        axios.get('https://api.freecurrencyapi.com/v1/latest?apikey='+this.currencykey+'&currencies=EUR,USD,GBP&base_currency='+from+'').then(response => {
          this.conversion_rates = response.data;
          EventBus.$emit('currency-updated', symbol, from, response.data);
        }).catch(error => {

        });
      }
  },
};
</script>

<template>
  <div>

    <WebsiteWrapper :domain="domain" :user="user" :exchange_rates="exchange_rates"  :currencies="currencies" :selected_currency="selected_currency" :selected_currency_symbol="selected_currency_symbol">
      <router-view :domain="domain" :user="user" :exchange_rates="exchange_rates"  :currencies="currencies" :selected_currency="selected_currency" :selected_currency_symbol="selected_currency_symbol" ></router-view>
    </WebsiteWrapper>
        
  </div>
</template>
