<script>
import VueSlideBar from "vue-slide-bar";
import simplebar from "simplebar-vue"
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
//import FilterListTree from "../../views/stock/product/category/_partials/FilterListTree";
import FilterListTree from "../../components/widgets/FilterListTree";
import FilterProductBrands from "../../components/widgets/FilterProductBrands";
import FilterProductCollections from "../../components/widgets/FilterProductCollections";
import SideSlidePanel from "../../components/widgets/SideSlidePanel";

/**
 * Products component
 */
export default {
  components: { VueSlideBar, Layout, PageHeader, FilterListTree, FilterProductBrands, FilterProductCollections, SideSlidePanel, simplebar },
  data() {
    return {
      pagesData: '',
      title: "Printers",
      items: [
        {
          text: "Sell",
          href: "/",
        },
        {
          text: "Printers",
          active: true,
        },
      ],
      search: '',
      storageurl: 'https://'+this.$attrs.company.nickname+'.s3.eu-west-2.amazonaws.com',
      showFilters: false,
      sliderPrice: 800,
      priceRange: [0,999999],
      currentPage: 1,
      limit: 25,
      selectedids: [],
      orderDesc: true,
      order: 'desc',
      sortby: 'id',
      trade_active: '',
      public_active: '',
      brands: '',
      collections: '',
      selectedids: [],
      closeHover: false,
      addNew: false,
      listingssearch: '',
      listings: '',
      printers: [],
      printer: {
        id: '',
        uid: '',
        reference: '',
        type: '',
        connection_type:'',
        name: '',
        model: '',
        description: '',
        company_listing_id: '',
        company_cash_register_id: '',
        mac: '',
        ip: '',
      },
      isCheckAll: false,
      active: '',
      view: 'grid',
      fields: [
        { key: "id", sortable: true },
        { key: "name", sortable: true },
        ],
      filter: null,
      filterOn: [],
      limits: [
        {value: 25, text: "25"},
        {value: 50, text: "50"},
        {value: 100, text: "100"},
      ],
      discountRates: [],
    };
  },
  methods: {

    getListings(){
      axios.get('/api/company/listings?page='+this.currentPage+'&limit=0&sortby=id&order=desc&search='+this.listingssearch+'&type_id=&active=&public_active=&trade_active=', {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
                this.listings = response.data;
          }).catch(error => {
                this.load_error = response.error;
          });
    },
    valuechange(value) {
      this.clothsData = clothsData.filter(function (product) {
        return product.newprice <= value.currentValue;
      });
    },

    searchFilter(e) {
      const searchStr = e.target.value;
      this.productQuery();
    },

    filterBrands(value){
      this.brands = value;
      this.productQuery();
    },

     filterCollections(value){
      this.collections = value;
      this.productQuery();
    },

    discountLessFilter(e, percentage) {
      if (e === "accepted" && this.discountRates.length === 0) {
        this.clothsData = clothsData.filter((product) => {
          return product.discount < percentage;
        });
      } else {
        this.clothsData = clothsData.filter((product) => {
          return product.discount >= Math.max.apply(null, this);
        }, this.discountRates);
      }
    },
    async connectToDeviceAndSubscribeToUpdates () {
          const device = await navigator.bluetooth.requestDevice({
       // filters: [...] <- Prefer filters to save energy & show relevant devices.
          acceptAllDevices: true,
          optionalServices: ['device_information']})
      .then(device => {
        console.log('Connecting to GATT Server...');
        return device.gatt.connect();
      })
      .then(server => {
        console.log('Getting Device Information Service...');
        return server.getPrimaryService('device_information');
      })
      .then(service => {
        console.log('Getting Device Information Characteristics...');
        return service.getCharacteristics();
      })
      .then(characteristics => {
        let queue = Promise.resolve();
        let decoder = new TextDecoder('utf-8');
        characteristics.forEach(characteristic => {
          switch (characteristic.uuid) {

            case BluetoothUUID.getCharacteristic('manufacturer_name_string'):
              queue = queue.then(_ => characteristic.readValue()).then(value => {
                console.log('> Manufacturer Name String: ' + decoder.decode(value));
              });
              break;

            case BluetoothUUID.getCharacteristic('model_number_string'):
              queue = queue.then(_ => characteristic.readValue()).then(value => {
                console.log('> Model Number String: ' + decoder.decode(value));
              });
              break;

            case BluetoothUUID.getCharacteristic('hardware_revision_string'):
              queue = queue.then(_ => characteristic.readValue()).then(value => {
                console.log('> Hardware Revision String: ' + decoder.decode(value));
              });
              break;

            case BluetoothUUID.getCharacteristic('firmware_revision_string'):
              queue = queue.then(_ => characteristic.readValue()).then(value => {
                console.log('> Firmware Revision String: ' + decoder.decode(value));
              });
              break;

            case BluetoothUUID.getCharacteristic('software_revision_string'):
              queue = queue.then(_ => characteristic.readValue()).then(value => {
                console.log('> Software Revision String: ' + decoder.decode(value));
              });
              break;

            case BluetoothUUID.getCharacteristic('system_id'):
              queue = queue.then(_ => characteristic.readValue()).then(value => {
                console.log('> System ID: ');
                console.log('  > Manufacturer Identifier: ' +
                    padHex(value.getUint8(4)) + padHex(value.getUint8(3)) +
                    padHex(value.getUint8(2)) + padHex(value.getUint8(1)) +
                    padHex(value.getUint8(0)));
                console.log('  > Organizationally Unique Identifier: ' +
                    padHex(value.getUint8(7)) + padHex(value.getUint8(6)) +
                    padHex(value.getUint8(5)));
              });
              break;

            case BluetoothUUID.getCharacteristic('ieee_11073-20601_regulatory_certification_data_list'):
              queue = queue.then(_ => characteristic.readValue()).then(value => {
                console.log('> IEEE 11073-20601 Regulatory Certification Data List: ' +
                    decoder.decode(value));
              });
              break;

            case BluetoothUUID.getCharacteristic('pnp_id'):
              queue = queue.then(_ => characteristic.readValue()).then(value => {
                console.log('> PnP ID:');
                console.log('  > Vendor ID Source: ' +
                    (value.getUint8(0) === 1 ? 'Bluetooth' : 'USB'));
                if (value.getUint8(0) === 1) {
                  console.log('  > Vendor ID: ' +
                      (value.getUint8(1) | value.getUint8(2) << 8));
                } else {
                  console.log('  > Vendor ID: ' +
                      getUsbVendorName(value.getUint8(1) | value.getUint8(2) << 8));
                }
                console.log('  > Product ID: ' +
                    (value.getUint8(3) | value.getUint8(4) << 8));
                console.log('  > Product Version: ' +
                    (value.getUint8(5) | value.getUint8(6) << 8));
              });
              break;

            default: console.log('> Unknown Characteristic: ' + characteristic.uuid);
          }
        });
        return queue;
      })
      .catch(error => {
        log('Argh! ' + error);
      });
    },
    async connectToDeviceAndSubscribeToUpdates () {
          const device = await navigator.bluetooth.requestDevice({ acceptAllDevices: true });
    },
    async connectToDeviceAndSubscribeToUSB () {
          return await navigator.usb.getDevices().then((devices) => {
            console.log(`Total devices: ${devices.length}`);
            devices.forEach((device) => {
              console.log(`Product name: ${device.productName}, serial number ${device.serialNumber}`);
            });
          });
    },
    discountMoreFilter(e, percentage) {
      if (e === "accepted") {
        this.discountRates.push(percentage);
      } else {
        this.discountRates.splice(this.discountRates.indexOf(percentage), 1);
      }
      this.clothsData = clothsData.filter((product) => {
        return product.discount >= Math.max.apply(null, this);
      }, this.discountRates);
    },
     productQuery(){
      if(this.pagesData != ''){
        if(this.limit > this.pagesData.total){
          this.currentPage = 1;
        }
      }
      if(this.orderDesc == true){
        this.order = 'desc';
      }else{
        this.order = 'asc';
      }
      axios.get('/api/company/sell/printers?page='+this.currentPage+'&limit='+this.limit+'&sortby='+this.sortby+'&order='+this.order+'&search='+this.search+'&active='+this.active, {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
        console.log(response);
        this.pagesData = response.data;
      }).catch(error => {
        this.error_reponse = error.response.error;
        this.error_message = true;
      });
    },
    createPrinter(){
      axios.post('/api/company/sell/printer/create', this.printer, {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
        console.log(response);
        this.pagesData = response.data;
      }).catch(error => {
        this.error_reponse = error.response.error;
        this.error_message = true;
      });
    },
    filterSelected(id){
        if(this.selectedids.length > 0){
          var exists = this.selectedids.filter(item => item == id);
          if(exists.length > 0){
            return true;
          }else{
            return false
          }
        }else{
          return false;
        }
      },
      checkAll: function(){ 
                 this.isCheckAll = !this.isCheckAll; 
                 this.selectedids = []; 
                 if(this.isCheckAll){ 
                     // Check all 
                     for (var key in this.pagesData.data) {          
                        this.selectedids.push(this.pagesData.data[key].id); 
                     } 
                 } 
              }, 
      updateCheckall: function(){ 
                 if(this.selectedids.length == this.pagesData.data.length){
                    this.isCheckAll = true; 
                 }else{ 
                    this.isCheckAll = false; 
                 } 
      },
  },
  beforeMount(){
      this.productQuery();
      this.getListings();
  },
  computed:{
   
  }
};
</script>

<template>
  <Layout  :nickname="$attrs.nickname" :user="$attrs.user" :menuItems="$attrs.menuItems" >
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div :class="{'col-lg-12': showFilters == true, 'col-lg-12': showFilters == false}">
        <div class="row mb-3">
          <div class="col-xl-1 col-sm-6">
            <div class="mt-2">
              <b-form-select class="form-control" v-model="limit" :options="limits" @input="productQuery()"></b-form-select>
            </div>
          </div>
         
          <div class="col-lg-2 col-sm-6 d-flex">
          <form class="my-auto">
            <small class="mx-auto my-2 text-center d-block">Results {{pagesData.from}} - {{pagesData.to}} of {{pagesData.total}}</small>
          </form>
        </div>
        <div class="col-lg-5 col-sm-6 d-flex">
            <div class="mt-sm-0 form-inline w-100" >
              <div class="search-box mr-2 ml-auto">
                <div class="position-relative">
                  <input
                    type="text"
                    class="form-control w-100 border-0"
                    placeholder="Search name, reference or description..."
                    v-model="search"
                    @keyup.enter="searchFilter($event)"
                  />
                  <i class="bx bx-search-alt search-icon"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-sm-6 d-flex">
            <div class="d-flex ml-auto">
              <ul class="nav nav-pills product-view-nav">
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': showFilters == true }" href="javascript: void(0);" @click="showFilters = !showFilters">
                    <i class="bx bx-filter-alt"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': view == 'grid' }" href="javascript: void(0);" @click="view = 'grid'">
                    <i class="bx bx-grid-alt"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': view == 'list' }" href="javascript: void(0);" @click="view = 'list'">
                    <i class="bx bx-list-ul"></i>
                  </a>
                </li>
                <li class="nav-item">
                 <b-dropdown text="Bulk Actions" variant="primary" class="rounded">
                  <b-dropdown-item href="javascript: void(0);">Action</b-dropdown-item>
                  <b-dropdown-item href="javascript: void(0);">Another action</b-dropdown-item>
                  <b-dropdown-item href="javascript: void(0);">Something else here</b-dropdown-item>
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-item href="javascript: void(0);">Separated link</b-dropdown-item>
                </b-dropdown>
                </li>
                <li class="nav-item">
                 <a
                    class="btn btn-success btn-rounded mb-2 mr-2 text-white"
                    @click="addNew = !addNew"
                  >
                    <i class="mdi mdi-plus mr-1"></i> New
                  </a>
                </li>
              </ul>
              <div class="text-sm-right">
                  
                </div>
          </div>
        </div>
      </div>
        <!--<div class="row">
          <div class="col-lg-12">
            <b-pagination
              v-if="pagesData.data.length > 0"
              class="justify-content-center mb-0"
              pills
              v-model="currentPage"
              :total-rows="pagesData.total"
              :per-page="pagesData.per_page"
              aria-controls="my-table"
              @input="productQuery"
            ></b-pagination>
             <small class="mx-auto my-2 text-center d-block">Records {{pagesData.from}} - {{pagesData.to}} of {{pagesData.total}} // Page {{pagesData.current_page}} of {{pagesData.last_page}}</small>
          </div>
        </div>-->
      <b-row>
      <div id="my-table" v-if="view == 'grid'" v-for="list in pagesData.data" :key="list.id" class="col-xl-4 col-sm-6">
        <div class="card overflow-hidden">
          <div class="bg-soft-primary min-h-100">
            <div class="row" style="height: 150px">
              
              <div class="col-12 align-items-center justify-content-center d-flex flex-column my-auto">
                <!--<div class="row">
                 <div class="col-md-12 position-absolute"> 
                
                <a id="tooltip-add"  :href="'/web/page/'+list.uid" class="mt-3 mr-3 btn btn-primary font-size-16 btn-rounded ml-auto float-right"><i class="mdi mdi-eye-outline"></i></a>
              </div>
              </div>-->
          
                <i class="bx bx-printer font-size-42 text-white border border-5 border-white rounded-circle p-2"></i>
              </div>
            </div>
          </div>
          <div class="card-body pt-0">
            <div class="row">
              <div class="col-sm-12 text-center px-4 mb-2">
               <!-- <div class="avatar-xl profile-user-wid mb-4">
                  <img class="img-thumbnail rounded-circle h-100" :src="`/public/storage/${list.image}`" alt />
                </div>-->
                <div class="avatar-sm profile-user-wid mx-auto">
                   <div class="text-center" v-if="list.image">
                      <b-form-checkbox class="select-row-btn" v-model="selectedids" :value="list.id" button-variant="primary" @input="updateSelected" button>
                        <i v-if="filterSelected(list.id)" class="bx bx-check"></i>
                        <img v-else :src="`${list.image}`" alt class="avatar-sm img-thumbnail rounded-circle h-100" />
                      </b-form-checkbox>
                    </div>
                    <div class="text-center" v-else >
                        <b-form-checkbox class="select-row-btn" v-model="selectedids" :value="list.id" button-variant="primary" @input="updateSelected" button>

                          <i v-if="filterSelected(list.id)" class="bx bx-check"></i><span class="avatar-title rounded-circle" v-else>{{(list.name).slice(0,1)}}</span></b-form-checkbox>
                    </div> 
                </div>
                <h4 class="font-size-18 text-truncate mt-3">{{list.name}}</h4>
                <p class="text-muted mb-0 text-truncate" v-html="list.description"></p>
              </div>
              <div class="col-sm-12 text-center pt-3 px-4 mb-2">
               <!-- <div class="avatar-xl profile-user-wid mb-4">
                  <img class="img-thumbnail rounded-circle h-100" :src="`/public/storage/${list.image}`" alt />
                </div>-->
                
              </div>

              <div class="col-sm-12 d-flex">
              <a class="my-auto btn bg-danger bg-soft text-danger btn-soft btn-sm btn-rounded ml-auto mr-1" v-if="list.status !== '200 OK'" @click="''">
                    <i class="bx bx-x-circle text-danger"></i>   Not Connected
                    </a>
                    <a v-else class="my-auto btn bg-success bg-soft text-success btn-soft btn-sm btn-rounded ml-auto mr-1">                 
                     <i class="bx bx-check-circle text-success"></i> Connected
                    </a>
                    <!--<a class="btn btn-danger btn-sm btn-rounded mr-auto ml-1" @click="destroyStripeLocation(location, index)">
                      Remove Reader Location
                    </a>-->
              </div>
            </div>
          </div>
        </div>
    </div>
  </b-row>
        
        <!-- end row -->

        <NothingHere v-if="'data' in pagesData && pagesData.data.length == 0" :addNew="true" @add-new=""></NothingHere> 
        <div class="card" v-if="view == 'list'" >
          <div class="card-body p-0">
            <div class="table-responsive">
              <table class="table table-centered table-hover">
                <thead class="thead-light">
                  <tr>
                   <th scope="col"  style="width: 100px" class="text-center">
                     <div
                        class="form-check form-switch form-switch-sm mb-0 mx-auto p-0 mt-auto d-flex"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input mx-auto my-auto" 
                          type="checkbox"
                          id="SwitchCheckSizesm"
                         
                          v-model="isCheckAll"
                          @click='checkAll()'
                          name="active"
                        />
                    </div>
                  </th>
                    <th scope="col" style="max-width: 300px"><a :class="{'text-dark': sortby != 'name'}" href="javascript: void(0);" @click="orderDesc = !orderDesc,  sortby = 'name', productQuery()">Name & Description <i v-if="orderDesc == true" class="bx bx-caret-up"></i><i v-if="orderDesc == false" class="bx bx-caret-down"></i></a></th>
                    
                    <th scope="col">Link</th>
                    <th scope="col">Parent Page</th>
                    
                    <th scope="col">Status</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="list in pagesData.data" :key="list.id" valign="middle">
                    <td>
                      <div class="text-center" v-if="list.cover_image">
                      <b-form-checkbox class="select-row-btn " v-model="selectedids" :value="list.id" button-variant="primary" @input="updateSelected" button>
                        <i v-if="filterSelected(list.id)" class="bx bx-check"></i>
                        <img v-else :src="`${storageurl+list.cover_image.src.replace('//', '/')}`" alt class="avatar-sm rounded-circle" />
                      </b-form-checkbox>
                    </div>
                    <div class="text-center" v-else >
                        <b-form-checkbox class="select-row-btn" v-model="selectedids" :value="list.id" button-variant="light" @input="updateSelected" button>

                          <i v-if="filterSelected(list.id)" class="bx bx-check"></i><span class="avatar-title rounded-circle" v-else>{{(list.name).slice(0,1)}}</span></b-form-checkbox>
                    </div> 
                    </td>
                    <td style="max-width: 550px">
                        <a :href="`/web/page/${list.uid}`" class="mb-0"><h6 class="mb-0">{{list.name}}</h6></a>
                        <p class="d-md-none d-block" v-html="list.short_description"></p>
                    </td>
                    <td><a 
                          href="javascript: void(0);"
                          class="badge badge-soft-light rounded-pill font-size-11 m-1"
                         v-if="list.brand != null"><img v-if="list.brand.logo" class="rounded-circle avatar-xs" :src="`${list.brand.logo}`" alt />  <img v-else  src="/images/banner-placeholder.png" alt="" class="rounded-circle avatar-xs"> <span class="text-dark">{{list.brand.name}}</span></a></td>
                    <td>
                      <div>

                        <a 
                          href="javascript: void(0);"
                          class="badge badge-soft-light rounded-pill font-size-11 m-1"
                         v-if="list.company_defaultcategory != null"><img class="rounded-circle avatar-xs" v-if="list.company_defaultcategory.image" :src="`${list.company_defaultcategory.image}`" alt /> <img v-else  src="/images/banner-placeholder.png" alt="" class="rounded-circle avatar-xs"> <span class="text-dark">{{list.company_defaultcategory.name}}</span></a>

                         <a 
                          href="javascript: void(0);"
                          class="badge badge-soft-light rounded-pill font-size-11 m-1"
                          v-for="category in list.company_categories"><img v-if="category.category.image" class="rounded-circle avatar-xs" :src="`${category.category.image}`" alt /> <img v-else src="/images/banner-placeholder.png" alt="" class="rounded-circle avatar-xs">  <span class="text-dark">{{category.category.name}}</span></a>

                        
                      </div>
                    </td>
                    
                    <td>
                      <div
                        class="form-check form-switch form-switch-sm mb-0 mt-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          id="SwitchCheckSizesm"
                          v-model="list.active"
                          value="1"
                          unchecked-value="0"
                          name="active"
                        />
                        <label class="form-check-label" for="SwitchCheckSizesm" 
                          >Active</label
                        >
                    </div>
                   
                    </td>
                    <td>
                      <ul class="list-inline font-size-20 contact-links mb-0">
                        <li class="list-inline-item px-2">
                          <a v-b-tooltip.hover title="Edit" placement="left">
                            <i class="bx bx-edit-alt"></i>
                          </a>
                        </li>
                        <li class="list-inline-item px-2">
                            <a v-b-tooltip.hover title="Preview" placement="left">
                            <i class="mdi mdi-eye-outline"></i>
                          </a>
                          </a>
                        </li>
                        <li class="list-inline-item px-2">
                          <a v-b-tooltip.hover title="Delete" placement="left">
                            <i class="bx bx-trash-alt text-danger"></i>
                          </a>
                        </li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            
            <div class="row">
              <div class="col-lg-12" v-if="pagesData !== ''">
                <b-pagination
              class="justify-content-center"
              pills
              v-model="currentPage"
              :total-rows="pagesData.total"
              :per-page="pagesData.per_page"
              aria-controls="my-table"
              @input="productQuery"
            ></b-pagination>
             <small class="mx-auto my-2 text-center d-block">Records {{pagesData.from}} - {{pagesData.to}} of {{pagesData.total}} // Page {{pagesData.current_page}} of {{pagesData.last_page}}</small>
              </div>
            </div>
          </div>
        </div>
      </div>
           <SideSlidePanel :editPanel="addNew" :panelClass="'edit-sidepanel'" @close-panel="addNew = !addNew">     
        <form id="add-update" method="post" enctype="mutlipart/form-data">
            <b-row>
              <b-col md="12">
                <h4>Add New Printer</h4>
              </b-col>
              <b-col md="2" class="my-3">
                          <h5 class="d-flex">Basic Info <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
                        </b-col>
              <b-col md="10" class="my-3">
              <b-card class="shadow-none border-radius-1em border">
              
                <b-form-group label="Name">
                  <b-form-input type="text" name="name" v-model="printer.name"></b-form-input>
                </b-form-group>
              
              </b-card>
              </b-col>
              <b-col md="2" class="my-3">
                          <h5 class="d-flex">Location <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
                        </b-col>
              <b-col md="10" class="my-3">
                <b-card class="shadow-none border-radius-1em border" body-class="p-0">
                <div v-if="printer.company_listing_id == ''">
                <simplebar
                              style="height: 300px"
                          >
                              <li class="list-group-item" v-for="listing in listings">
                                  <div class="media">
                                      <div class="avatar-sm me-3" v-if="listing.logo">
                                          <span
                                              class="avatar-title rounded-circle bg-light"
                                          >
                                              <!--<img
                                                  
                                                  :src="listing.logo"
                                                  alt=""
                                                  height="18"
                                              />-->
                                              
                                          </span>
                                        </div>
                                         <div class="avatar-sm me-3" v-else>
                                          <span class="avatar-title rounded-circle" >{{(listing.name).slice(0,1)}}</span>
                                        </div>
                                     
                                      <div class="media-body">
                                          <h6 class="my-1">
                                              {{listing.name}}
                                          </h6>
                                          <p class="text-muted mb-0">
                                              <span v-if="listing.address_1">{{listing.address_1}},</span>
                      <span v-if="listing.address_2">{{listing.address_2}},</span>
                      <span v-if="listing.address_3">{{listing.address_3}},</span>
                      <span v-if="listing.county">{{listing.county.name}},</span>
                      <span v-if="listing.country">{{listing.country.name}},</span>
                      <span v-if="listing.postcode">{{listing.postcode}}</span>
                                          </p>

                                         
                                         
                                      </div>
                                       <div class="float-end my-auto mr-2">
                                              <p class="text-muted my-2" v-if="listing.listing_type">
                                                  <i 
                                                      class="bx bx-buildings me-1"
                                                  ></i>
                                                  {{listing.listing_type.name}}
                                              </p>
                                          </div>
                                       <p class="text-muted my-auto">
                                              <a class="btn btn-primary btn-rounded" @click="printer['listing'] = listing, printer.company_listing_id = listing.id">Use Location</a>
                                          </p>
                                  </div>
                              </li>
                          </simplebar>
                      </div>
                      <div class="border p-4 text-center d-flex flex-column" v-else style="height: 350px">
                        <b-row class="my-auto">
                          <b-col md="12">
                              <div class="avatar-sm mx-auto mb-3" v-if="printer.listing.logo">
                                          <span
                                              class="avatar-title rounded-circle bg-light"
                                          >
                                              <!--<img
                                                  
                                                  :src="printer.listing.logo"
                                                  alt=""
                                                  height="18"
                                              />-->
                                              
                                          </span>
                                        </div>
                                         <div class="avatar-sm mx-auto mb-3" v-else @mouseenter="closeHover = true" @mouseleave="closeHover = false">
                                          <span class="avatar-title rounded-circle" v-if="closeHover !== true" >{{(printer.listing.name).slice(0,1)}}</span>
                                          <a v-else class="avatar-title rounded-circle" @click="printer.listing = '', printer.company_listing_id = '', closeHover = false">
                                            <i class="bx bx-x text-white font-size-20"></i>
                                          </a>
                                        </div>
                                        <h4>{{printer.listing.name}}</h4>

                                        <p class="text-muted">
                                      <span v-if="printer.listing.address_1">{{printer.listing.address_1}}<br></span>
                              <span v-if="printer.listing.address_2">{{printer.listing.address_2}}<br></span>
                              <span v-if="printer.listing.address_3">{{printer.listing.address_3}}<br></span>
                              <span v-if="printer.listing.county">{{printer.listing.county.name}}<br></span>
                              <span v-if="printer.listing.country">{{printer.listing.country.name}}<br></span>
                              <span v-if="printer.listing.postcode">{{printer.listing.postcode}}</span>
                                          </p>
                        
                                          
                              </b-col>
                           </b-row>
                      </div>
                    </b-card>
              </b-col>
              <b-col md="2" class="my-3">
                          <h5 class="d-flex">Printer Configuration <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
                        </b-col>
              <b-col md="10" class="my-3">
                <b-card class="shadow-none border-radius-1em border">
                  <b-form-group label="Format Type">
                    <b-form-select class="form-control" v-model="printer.type" :options="['Address Label', 'Product Label', 'Receipts']">
                    </b-form-select>
                  </b-form-group>
                  <b-form-group label="Connection Type">
                    <b-form-select class="form-control" v-model="printer.connection_type" :options="['WLAN/LAN']">
                    </b-form-select>
                  </b-form-group>

                  <div v-if="printer.connection_type == 'WLAN/LAN' && printer.type == 'Receipts'">

                    <b-form-group label="Printer Model" >
                        <b-form-select class="form-control" v-model="printer.model" :options="['Star Micronics mC-Print2', 'Star Micronics mC-Print3']">
                        </b-form-select>
                    </b-form-group>
                    <b-card class="bg-light">
                      <i class="bx bx-warning font-size-42 text-muted"></i>
                      <h3>Link Your Computer to Network</h3>
                      <p>You need to link your printer to your local network via LAN or WLAN</p>
                      <a class="btn btn-info" v-if="printer.model !== ''"  :href="(printer.model == 'Star Micronics mC-Print2' ? 'https://www.star-m.jp/products/s_print/mcprint2/manual/en/settings/settingsCloudPRNT.htm' : 'https://www.star-m.jp/products/s_print/mcprint3/manual/en/settings/settingsCloudPRNT.htm' )">
                        Printer Documentation
                      </a>
                    </b-card>
                    <b-form-group label="Mac Address">
                      <b-form-input v-model="printer.mac" type="text" placeholder="00:00:11:23:GB:10">

                      </b-form-input>
                    </b-form-group>
                  </div>

                  <a class="btn btn-primary mt-2 btn-rounded" @click="connectToDeviceAndSubscribeToUpdates()">
                    Find Bluetooth Printer
                  </a>
                  <a class="btn btn-primary mt-2 btn-rounded" @click="connectToDeviceAndSubscribeToUSB()">
                    Find USB Printer
                  </a>
                </b-card>
                <a class="btn btn-success btn-rounded btn-block" @click="createPrinter()">
                  Save <i class="bx bx-save"></i>
                </a>
              </b-col>
            </b-row>
            </form>
    </SideSlidePanel>
    </div>
    <!-- end row -->
  </Layout>
</template>
