<template>
	<transition name="slide-fadepanel">
	
			<div class="system-notification d-flex flex-column" v-if="showNotification == true">
				<transition-group name="slide-fadepanel">
				<div class="d-flex notification-item shadow-sm" v-for="(notification, key, index) in notification_stack" :key="key">
					
						<a :href="notification.notification_link" class="mr-auto">
						<div class="d-flex">
						<div class="d-flex flex-column justify-content-center">
							
						</div>
						<div class="d-flex flex-column justify-content-center">
							<h5>{{notification.notification_title}}</h5>
							<p class="mb-0" v-html="notification.notification_message">
						</p>
						</div>
						</div>
						</a>
						<a class="btn-close my-auto" @click="notification_stack.splice(index, 1)">
							
						</a>
					
				</div>
				</transition-group>
			</div>
		</a>
	</transition>
</template>

<script type="text/javascript">

	import Echo from 'laravel-echo';

			
	export default{
		data() {
			return {
				showNotification: false,
				notification_stack: [],
				notification_item_raw: {
					notification_title: 'Here\'s a notification',
					notification_message: 'A message with your notification',
					notification_link: '/link-to-notification',
				},
				notification_item: '',
				echoUserChannel: '',
				echoCompanyChannel: '',
				echoGlobalChannel: '',
			}
		},
		methods: {

		},
		mounted(){
			window.Pusher = require('pusher-js');
			window.Echo = new Echo({
			    broadcaster: 'pusher',
			    key: '7e97298bdae98a4308de',
			    wsPort: 6001,
			    forceTLS: false,
			    disableStats: true,
			    cluster: 'eu',
			   // authEndpoint: 'https://'+this.$attrs.user.companies[0].company.nickname+'.gemesys.co.uk/api/broadcasting/auth',
				  auth: {
				    headers: {
				    	 Accept: 'application/json',
				      	 Authorization: `Bearer ${this.$attrs.user.api_token}`
				    }
				  }
			});

			this.echoUserChannel = window.Echo.private('user.'+this.$attrs.user.id);
        	this.echoUserChannel.listen('.App\\Events\\UserBroadcast', (e) => {
							 this.notification_item = JSON.parse(JSON.stringify(this.notification_item_raw));
							this.notification_item.notification_title = e.title;
							this.notification_item.notification_message = e.message;
							this.notification_item.notification_link = e.url;
							this.notification_stack.push(this.notification_item);
							this.notification_item = '';
							this.showNotification = true;
								let self = this;
								setTimeout(function(){
									self.notification_stack.splice(0, 1);
								}, 8000);
				    		});
        	this.echoCompanyChannel = window.Echo.private('company.'+this.$attrs.user.companies[0].company.uid);
        	this.echoCompanyChannel.listen('.App\\Events\\CompanyBroadcast', (e) => {
							 this.notification_item = JSON.parse(JSON.stringify(this.notification_item_raw));
							this.notification_item.notification_title = e.title;
							this.notification_item.notification_message = e.message;
							this.notification_item.notification_link = e.url;
							this.notification_stack.push(this.notification_item);
							this.notification_item = '';
							this.showNotification = true;
								let self = this;
								setTimeout(function(){
									self.notification_stack.splice(0, 1);
								}, 8000);
				    		});
        	this.echoGlobalChannel = window.Echo.channel('global');
        	this.echoGlobalChannel.listen('.App\\Events\\GlobalBroadcast', (e) => {
							this.notification_item = JSON.parse(JSON.stringify(this.notification_item_raw));
							this.notification_item.notification_title = e.title;
							this.notification_item.notification_message = e.message;
							this.notification_item.notification_link = e.url;
							this.notification_stack.push(this.notification_item);
							this.notification_item = '';
							this.showNotification = true;
								let self = this;
								setTimeout(function(){
									self.notification_stack.splice(0, 1);
								}, 8000);
				    		});
		}
	}

</script>
<style scoped>
	.system-notification{
		right: 50px;
		bottom: 10px;

		max-width: 440px;
		min-width: 440px;
		position: fixed;
	
	}
	.notification-item{
		background: #fff;
		padding: 30px;
				margin-bottom: 15px;
	}

</style>