<script type="text/javascript">
	import VueGoodshareFacebook from "vue-goodshare/src/providers/Facebook.vue";
	import VueGoodshareTwitter from "vue-goodshare/src/providers/Twitter.vue";
	import VueGoodshareLinkedin from "vue-goodshare/src/providers/Linkedin.vue";
	import VueGoodshareReddit from "vue-goodshare/src/providers/Reddit.vue";
	import VueGoodsharePinterest from "vue-goodshare/src/providers/Pinterest.vue";
		import VueGoodshare from "vue-goodshare";
	import VueGoodshareWhatsApp from "vue-goodshare/src/providers/WhatsApp.vue";
	import VueGoodshareSMS from "vue-goodshare/src/providers/SMS.vue";

	export default{
		data(){
			return{
				share: false,
			}
		},
		components: {
			VueGoodshareFacebook,
			VueGoodshareTwitter,
			VueGoodshareLinkedin,
			VueGoodshareReddit,
			VueGoodsharePinterest,
			VueGoodshare,
			VueGoodshareSMS,
			VueGoodshareWhatsApp,
		},
		methods: {
			filterStylesKey(styles, key){
            for(var i = 0; i < styles.length; i++){
               var keys = Object.keys(styles[i])
               if(keys.includes(key)){
                 return i;
               }
            }
          },
		}
	}	
</script>

<template>
	<div  class="d-flex" :class="{'text-left' : $attrs.content[filterStylesKey($attrs.content, 'icon_group_position')].icon_group_position.value == 'left', 'text-right' : $attrs.content[filterStylesKey($attrs.content, 'icon_group_position')].icon_group_position.value == 'right', 'position-fixed left-0' : $attrs.content[filterStylesKey($attrs.content, 'icon_group_position')].icon_group_position.value == 'fixed-left', 'position-fixed right-0' : $attrs.content[filterStylesKey($attrs.content, 'icon_group_position')].icon_group_position.value == 'fixed-right', 'flex-column' : $attrs.content[filterStylesKey($attrs.content, 'icon_group_layout')].icon_group_layout == 'stacked'}">
				
				<!-- <vue-goodshare
				 button_design="outline"
				 has_icon
				 ></vue-goodshare>-->
				 <a v-if="$attrs.content[filterStylesKey($attrs.content, 'collapsed')].collapsed.value == false" class="btn btn-light text-primary" :class="{'ml-auto': share == false}" @click="share = !share">
					<i v-if="!share" class="bx bx-share-alt font-size-20"></i>
					<i v-else class="bx bx-x font-size-20"></i>
				</a>
				 <transition name="fade-right">
				 <div class="d-flex" :class="{'flex-column' : $attrs.content[filterStylesKey($attrs.content, 'icon_group_layout')].icon_group_layout == 'stacked'}" v-if="(share == true) || ($attrs.content[filterStylesKey($attrs.content, 'collapsed')].collapsed.value == true)">
				 <component  v-for="network in $attrs.content[filterStylesKey($attrs.content, 'list')].list" :is="'vue-goodshare-'+network[filterStylesKey(network, 'network')].network.value"
				 :button_design="network[filterStylesKey(network, 'button_style')].button_style.value"
				 :title_social="network[filterStylesKey(network, 'title')].title.value"
				 :has_counter="network[filterStylesKey(network, 'show_counter')].show_counter.value"
				 :has_icon="network[filterStylesKey(network, 'icon')].icon.value"
				 :has_square_edges="network[filterStylesKey(network, 'icon_type')].icon_type.value == 'square'"
				 class="font-size-16"
				 ></component>
				 <!--<vue-goodshare-pinterest
				 v-if="network[filterStylesKey(network, 'network')].network.value == 'pinterest'"
				 :button_design="network[filterStylesKey(network, 'button_style')].button_style.value"
				 :title_social="network[filterStylesKey(network, 'title')].title.value"
				 :has_counter="network[filterStylesKey(network, 'show_counter')].show_counter.value"
				 :has_icon="network[filterStylesKey(network, 'icon')].icon.value"
				 :has_square_edges="network[filterStylesKey(network, 'icon_type')].icon_type.value == 'square'"
				 class="font-size-16"
				 ></vue-goodshare-pinterest>
				 <vue-goodshare-twitter
				 v-if="network[filterStylesKey(network, 'network')].network.value == 'twitter'"
				 :button_design="network[filterStylesKey(network, 'button_style')].button_style.value"
				 :title_social="network[filterStylesKey(network, 'title')].title.value"
				 :has_counter="network[filterStylesKey(network, 'show_counter')].show_counter.value"
				 :has_icon="network[filterStylesKey(network, 'icon')].icon.value"
				 :has_square_edges="network[filterStylesKey(network, 'icon_type')].icon_type.value == 'square'"
				 class="font-size-16"
				 ></vue-goodshare-twitter>
				 <vue-goodshare-reddit
				 v-if="network[filterStylesKey(network, 'network')].network.value == 'reddit'"
				 :button_design="network[filterStylesKey(network, 'button_style')].button_style.value"
				 :title_social="network[filterStylesKey(network, 'title')].title.value"
				 :has_counter="network[filterStylesKey(network, 'show_counter')].show_counter.value"
				 :has_icon="network[filterStylesKey(network, 'icon')].icon.value"
				 :has_square_edges="network[filterStylesKey(network, 'icon_type')].icon_type.value == 'square'"
				 class="font-size-16"
				 ></vue-goodshare-reddit>
				<vue-goodshare-linkedin
				 v-if="network[filterStylesKey(network, 'network')].network.value == 'linkedin'"
				 :button_design="network[filterStylesKey(network, 'button_style')].button_style.value"
				 :title_social="network[filterStylesKey(network, 'title')].title.value"
				 :has_counter="network[filterStylesKey(network, 'show_counter')].show_counter.value"
				 :has_icon="network[filterStylesKey(network, 'icon')].icon.value"
				 :has_square_edges="network[filterStylesKey(network, 'icon_type')].icon_type.value == 'square'"
				 class="font-size-16"
				 ></vue-goodshare-linkedin>-->
				</div>
			</transition>
					
				</div>

</template>