<script type="text/javascript">
export default{
  data: function(){
      return{
        stoneDiameter: 100,
        tableSize: '',
        crownHeight: '',
        crownAngle: 0.00, 
        marginError: 0,
        diameterDisabled: true,
      }
    },
    methods:{
      calculateCrownAngle(){
        var step0 = (this.stoneDiameter / 100);
        var step1 = step0 - (this.tableSize / 100);
        var variable1 = 50 * step1;
        var variable2 = this.crownHeight / variable1;
        var angleRad = Math.atan(variable2);
        var angleDeg = angleRad * 180 / Math.PI;
        this.crownAngle = (angleDeg).toFixed(2);
      }
    }
  }
</script>
<template>
  <b-card>
    <div class="row">
      <div class="col-md-12">
        <div class="card-body">
          <h3 class="pb-3">Crown Angle Calculator</h3>
          <label>Table (mm)</label>
            <input v-model="tableSize" class="form-control"  placeholder="0.00" type="number" @keyup="calculateCrownAngle"></input>
          <label>Crown Height (mm)</label>
            <input v-model="crownHeight" placeholder="0.00" class="form-control" type="number" @keyup="calculateCrownAngle"></input>
          <label>Diameter (mm)</label>
            <input v-model="stoneDiameter" placeholder="0.00" class="form-control" type="number" @keyup="calculateCrownAngle"></input>
          <div class="row">
          <div class="col-md-12 mt-4 text-center">
            <label>Crown Angle</label><p class="result-value text-primary font-size-24">{{crownAngle}} &#xb0;</p>
      </div>
  </div>
  </div>
  </div>
</div></b-card>
</template>