<script>
	export default{
		mounted(){
			this.$refs[$attrs.modal_id].show();
		}
	}

</script>
<template>
	
</template>