<script type="text/javascript">
export default {
	props:{
		charts: Array,
    gemstones: Array,
    cuts: Array,
	},
	data: function(){
		return{
      showGemstoneCharts: false,
			scrolled: false,
      stickyHeader: true,
      fields: [{key: 'Size', sortable: true, label: 'Size (mm)'}, {key: 'Quartz_Beryl_Iolite', sortable: false, label: 'Quartz, Beryl, Iolite'}, {key: 'Tourmaline', sortable: false}, {key: 'Peridot_Tanzanite', sortable: false, label: 'Perdiot, Tanzanite'}, {key: 'Topaz', sortable: false}, {key: 'Spinel_Tsavorite', sortable: false, label: 'Spinel, Tsavourite'}, {key: 'Chrysoberyl_Rhodolite_Pyrope', sortable: false, label: 'Chrysoberyl, Rhodolite, Pyrope'}, , {key: 'Corundum_Zircon_Almandite', sortable: false, label: 'Corundum, Zircon, Almandite'}], 
       heartcut: [
          { Size: '4x4', 
            Quartz_Beryl_Iolite: '0.18',
            Tourmaline: '0.20',
            Peridot_Tanzanite: '0.23',
            Topaz: '0.24',
            Spinel_Tsavorite: '0.25',
            Chrysoberyl_Rhodolite_Pyrope: '0.26',
            Corundum_Zircon_Almandite: '0.27'
          },
          { Size: '5x5', Quartz_Beryl_Iolite: '0.40',
            Tourmaline: '0.44',
            Peridot_Tanzanite: '0.49',
            Topaz: '0.52',
            Spinel_Tsavorite: '0.53',
            Chrysoberyl_Rhodolite_Pyrope: '0.56',
            Corundum_Zircon_Almandite: '0.59'
          },
          { Size: '6x6',
            Quartz_Beryl_Iolite: '0.63',
            Tourmaline: '0.70',
            Peridot_Tanzanite: '0.77',
            Topaz: '0.82',
            Spinel_Tsavorite: '0.84',
            Chrysoberyl_Rhodolite_Pyrope: '0.89',
            Corundum_Zircon_Almandite: '0.94'
          },
          { Size: '7x7',
            Quartz_Beryl_Iolite: '0.95',
            Tourmaline: '1.06',
            Peridot_Tanzanite: '1.16',
            Topaz: '1.23',
            Spinel_Tsavorite: '1.27',
            Chrysoberyl_Rhodolite_Pyrope: '1.34',
            Corundum_Zircon_Almandite: '1.41'
          },
          { Size: '8x8',
            Quartz_Beryl_Iolite: '1.35',
            Tourmaline: '1.50',
            Peridot_Tanzanite: '1.65',
            Topaz: '1.75',
            Spinel_Tsavorite: '1.80',
            Chrysoberyl_Rhodolite_Pyrope: '1.90',
            Corundum_Zircon_Almandite: '2.00'
          },
        ],
      roundcut: [
          {Size: '2.0',
          Quartz_Beryl_Iolite: '0.03',
          Tourmaline: '0.03',
          Peridot_Tanzanite: '0.04',
          Topaz: '0.04',
          Spinel_Tsavorite: '0.04',
          Chrysoberyl_Rhodolite_Pyrope: '0.04',
          Corundum_Zircon_Almandite: '0.04'
        },
          {Size: '2.5',
          Quartz_Beryl_Iolite: '0.05',
          Tourmaline: '0.06',
          Peridot_Tanzanite: '0.07',
          Topaz: '0.07',
          Spinel_Tsavorite: '0.08',
          Chrysoberyl_Rhodolite_Pyrope: '0.08',
          Corundum_Zircon_Almandite: '0.08'
        },
          {Size: '3.0',
          Quartz_Beryl_Iolite: '0.09',
          Tourmaline: '0.11',
          Peridot_Tanzanite: '0.12',
          Topaz: '0.12',
          Spinel_Tsavorite: '0.13',
          Chrysoberyl_Rhodolite_Pyrope: '0.13',
          Corundum_Zircon_Almandite: '0.14'
        },
          {Size: '3.5',
          Quartz_Beryl_Iolite: '0.15',
          Tourmaline: '0.17',
          Peridot_Tanzanite: '0.19',
          Topaz: '0.20',
          Spinel_Tsavorite: '0.20',
          Chrysoberyl_Rhodolite_Pyrope: '0.21',
          Corundum_Zircon_Almandite: '0.22'
        },
          {Size: '4.0',
          Quartz_Beryl_Iolite: '0.22',
          Tourmaline: '0.26',
          Peridot_Tanzanite: '0.28',
          Topaz: '0.30',
          Spinel_Tsavorite: '0.30',
          Chrysoberyl_Rhodolite_Pyrope: '0.32',
          Corundum_Zircon_Almandite: '0.34'
        },
          {Size: '4.5',
          Quartz_Beryl_Iolite: '0.32',
          Tourmaline: '0.37',
          Peridot_Tanzanite: '0.40',
          Topaz: '0.42',
          Spinel_Tsavorite: '0.43',
          Chrysoberyl_Rhodolite_Pyrope: '0.45',
          Corundum_Zircon_Almandite: '0.48'
        },
          {Size: '5.0',
          Quartz_Beryl_Iolite: '0.44',
          Tourmaline: '0.50',
          Peridot_Tanzanite: '0.55',
          Topaz: '0.58',
          Spinel_Tsavorite: '0.59',
          Chrysoberyl_Rhodolite_Pyrope: '0.62',
          Corundum_Zircon_Almandite: '0.66'
        },
          {Size: '5.5',
          Quartz_Beryl_Iolite: '0.58',
          Tourmaline: '0.67',
          Peridot_Tanzanite: '0.73',
          Topaz: '0.77',
          Spinel_Tsavorite: '0.78',
          Chrysoberyl_Rhodolite_Pyrope: '0.82',
          Corundum_Zircon_Almandite: '0.87'
        },
          {Size: '6.0',
          Quartz_Beryl_Iolite: '0.75',
          Tourmaline: '0.87',
          Peridot_Tanzanite: '0.95',
          Topaz: '1.00',
          Spinel_Tsavorite: '1.02',
          Chrysoberyl_Rhodolite_Pyrope: '1.07',
          Corundum_Zircon_Almandite: '1.13'
        },
          {Size: '6.5',
          Quartz_Beryl_Iolite: '0.96',
          Tourmaline: '1.10',
          Peridot_Tanzanite: '1.20',
          Topaz: '1.27',
          Spinel_Tsavorite: '1.30',
          Chrysoberyl_Rhodolite_Pyrope: '1.36',
          Corundum_Zircon_Almandite: '1.44'
        },
          {Size: '7.0',
          Quartz_Beryl_Iolite: '1.20',
          Tourmaline: '1.38',
          Peridot_Tanzanite: '1.50',
          Topaz: '1.59',
          Spinel_Tsavorite: '1.62',
          Chrysoberyl_Rhodolite_Pyrope: '1.70',
          Corundum_Zircon_Almandite: '1.80'
        },
          {Size: '7.5',
          Quartz_Beryl_Iolite: '1.47',
          Tourmaline: '1.69',
          Peridot_Tanzanite: '1.85',
          Topaz: '1.95',
          Spinel_Tsavorite: '1.99',
          Chrysoberyl_Rhodolite_Pyrope: '2.09',
          Corundum_Zircon_Almandite: '2.21'
        },
          {Size: '8.0',
          Quartz_Beryl_Iolite: '1.78',
          Tourmaline: '2.05',
          Peridot_Tanzanite: '2.24',
          Topaz: '2.37',
          Spinel_Tsavorite: '2.42',
          Chrysoberyl_Rhodolite_Pyrope: '2.54',
          Corundum_Zircon_Almandite: '2.68'
        },
        ],
      ovalcut:[
          {
            Size: '5x3',
            Quartz_Beryl_Iolite: '0.19',
            Tourmaline: '0.21',
            Peridot_Tanzanite: '0.23',
            Topaz: '0.25',
            Spinel_Tsavorite: '0.25',
            Chrysoberyl_Rhodolite_Pyrope: '0.26',
            Corundum_Zircon_Almandite: '0.28'
          },
          {
            Size: '6x4',
            Quartz_Beryl_Iolite: '0.39',
            Tourmaline: '0.45',
            Peridot_Tanzanite: '0.49',
            Topaz: '0.51',
            Spinel_Tsavorite: '0.52',
            Chrysoberyl_Rhodolite_Pyrope: '0.55',
            Corundum_Zircon_Almandite: '0.58'
          },
          {
            Size: '7x5',
            Quartz_Beryl_Iolite: '0.70',
            Tourmaline: '0.80',
            Peridot_Tanzanite: '0.88',
            Topaz: '0.93',
            Spinel_Tsavorite: '0.94',
            Chrysoberyl_Rhodolite_Pyrope: '0.99',
            Corundum_Zircon_Almandite: '1.05'
          },
          {
            Size: '8x6',
            Quartz_Beryl_Iolite: '1.14',
            Tourmaline: '1.31',
            Peridot_Tanzanite: '1.43',
            Topaz: '1.51',
            Spinel_Tsavorite: '1.54',
            Chrysoberyl_Rhodolite_Pyrope: '1.62',
            Corundum_Zircon_Almandite: '1.71'
          },
          {
            Size: '9x7',
            Quartz_Beryl_Iolite: '1.74',
            Tourmaline: '2.00',
            Peridot_Tanzanite: '2.18',
            Topaz: '2.30',
            Spinel_Tsavorite: '2.35',
            Chrysoberyl_Rhodolite_Pyrope: '2.47',
            Corundum_Zircon_Almandite: '2.61'
          },
          {
            Size: '10x8',
            Quartz_Beryl_Iolite: '2.51',
            Tourmaline: '2.89',
            Peridot_Tanzanite: '3.15',
            Topaz: '3.33',
            Spinel_Tsavorite: '3.40',
            Chrysoberyl_Rhodolite_Pyrope: '3.57',
            Corundum_Zircon_Almandite: '3.77'
          },
      ],
      pearcut:[
         {Size: '5x3',
          Quartz_Beryl_Iolite: '0.17',
          Tourmaline: '0.18',
          Peridot_Tanzanite: '0.21',
          Topaz: '0.22',
          Spinel_Tsavorite: '0.22',
          Chrysoberyl_Rhodolite_Pyrope: '0.23',
          Corundum_Zircon_Almandite: '0.24'
          },
          {Size: '6x4',
          Quartz_Beryl_Iolite: '0.35',
          Tourmaline: '0.40',
          Peridot_Tanzanite: '0.43',
          Topaz: '0.47',
          Spinel_Tsavorite: '0.47',
          Chrysoberyl_Rhodolite_Pyrope: '0.49',
          Corundum_Zircon_Almandite: '0.52'
          },
          {Size: '7x5',
          Quartz_Beryl_Iolite: '0.64',
          Tourmaline: '0.73',
          Peridot_Tanzanite: '0.79',
          Topaz: '0.85',
          Spinel_Tsavorite: '0.86',
          Chrysoberyl_Rhodolite_Pyrope: '0.90',
          Corundum_Zircon_Almandite: '0.96'
          },
          {Size: '8x6',
          Quartz_Beryl_Iolite: '1.05',
          Tourmaline: '1.20',
          Peridot_Tanzanite: '1.30',
          Topaz: '1.39',
          Spinel_Tsavorite: '1.42',
          Chrysoberyl_Rhodolite_Pyrope: '1.48',
          Corundum_Zircon_Almandite: '1.57'
          },
          {Size: '9x7',
          Quartz_Beryl_Iolite: '1.63',
          Tourmaline: '1.83',
          Peridot_Tanzanite: '2.00',
          Topaz: '2.12',
          Spinel_Tsavorite: '2.17',
          Chrysoberyl_Rhodolite_Pyrope: '2.28',
          Corundum_Zircon_Almandite: '2.41'
          },
          {Size: '10x8',
          Quartz_Beryl_Iolite: '2.35',
          Tourmaline: '2.67',
          Peridot_Tanzanite: '2.90',
          Topaz: '3.08',
          Spinel_Tsavorite: '3.15',
          Chrysoberyl_Rhodolite_Pyrope: '3.30',
          Corundum_Zircon_Almandite: '3.50'
          },
      ],
      marquisecut:[

          {Size: '4x2',
          Quartz_Beryl_Iolite: '0.05',
          Tourmaline: '0.06',
          Peridot_Tanzanite: '0.07',
          Topaz: '0.07',
          Spinel_Tsavorite: '0.08',
          Chrysoberyl_Rhodolite_Pyrope: '0.08',
          Corundum_Zircon_Almandite: '0.08'
      },
          {Size: '5x2.5',
          Quartz_Beryl_Iolite: '0.10',
          Tourmaline: '0.11',
          Peridot_Tanzanite: '0.13',
          Topaz: '0.14',
          Spinel_Tsavorite: '0.14',
          Chrysoberyl_Rhodolite_Pyrope: '0.15',
          Corundum_Zircon_Almandite: '0.16'
      },
          {Size: '6x3',
          Quartz_Beryl_Iolite: '0.18',
          Tourmaline: '0.20',
          Peridot_Tanzanite: '0.22',
          Topaz: '0.23',
          Spinel_Tsavorite: '0.24',
          Chrysoberyl_Rhodolite_Pyrope: '0.26',
          Corundum_Zircon_Almandite: '0.27'
      },
          {Size: '7x3.5',
          Quartz_Beryl_Iolite: '0.29',
          Tourmaline: '0.33',
          Peridot_Tanzanite: '0.35',
          Topaz: '0.38',
          Spinel_Tsavorite: '0.39',
          Chrysoberyl_Rhodolite_Pyrope: '0.40',
          Corundum_Zircon_Almandite: '0.43'
      },
          {Size: '8x4',
          Quartz_Beryl_Iolite: '0.43',
          Tourmaline: '0.48',
          Peridot_Tanzanite: '0.53',
          Topaz: '0.56',
          Spinel_Tsavorite: '0.57',
          Chrysoberyl_Rhodolite_Pyrope: '0.60',
          Corundum_Zircon_Almandite: '0.64'
      },
          {Size: '9x4.5',
          Quartz_Beryl_Iolite: '0.61',
          Tourmaline: '0.69',
          Peridot_Tanzanite: '0.75',
          Topaz: '0.81',
          Spinel_Tsavorite: '0.82',
          Chrysoberyl_Rhodolite_Pyrope: '0.86',
          Corundum_Zircon_Almandite: '0.91'
},
 {Size: '10x5',
          Quartz_Beryl_Iolite: '0.85',
          Tourmaline: '0.95',
          Peridot_Tanzanite: '1.04',
          Topaz: '1.10',
          Spinel_Tsavorite: '1.12',
          Chrysoberyl_Rhodolite_Pyrope: '1.18',
          Corundum_Zircon_Almandite: '1.25'
},


      ],
      squarecut:[
         {Size: '4x4',
          Quartz_Beryl_Iolite: '0.18',
          Tourmaline: '0.20',
          Peridot_Tanzanite: '0.23',
          Topaz: '0.24',
          Spinel_Tsavorite: '0.25',
          Chrysoberyl_Rhodolite_Pyrope: '0.26',
          Corundum_Zircon_Almandite: '0.27'
      },
          {Size: '5x5',
          Quartz_Beryl_Iolite: '0.40',
          Tourmaline: '0.44',
          Peridot_Tanzanite: '0.49',
          Topaz: '0.52',
          Spinel_Tsavorite: '0.53',
          Chrysoberyl_Rhodolite_Pyrope: '0.56',
          Corundum_Zircon_Almandite: '0.59'
      },
          {Size: '6x6',
          Quartz_Beryl_Iolite: '0.63',
          Tourmaline: '0.70',
          Peridot_Tanzanite: '0.77',
          Topaz: '0.82',
          Spinel_Tsavorite: '0.84',
          Chrysoberyl_Rhodolite_Pyrope: '0.89',
          Corundum_Zircon_Almandite: '0.94'
      },
          {Size: '7x7',
          Quartz_Beryl_Iolite: '0.95',
          Tourmaline: '1.06',
          Peridot_Tanzanite: '1.16',
          Topaz: '1.23',
          Spinel_Tsavorite: '1.27',
          Chrysoberyl_Rhodolite_Pyrope: '1.34',
          Corundum_Zircon_Almandite: '1.41'
      },
          {Size: '8x8',
          Quartz_Beryl_Iolite: '1.35',
          Tourmaline: '1.50',
          Peridot_Tanzanite: '1.65',
          Topaz: '1.75',
          Spinel_Tsavorite: '1.80',
          Chrysoberyl_Rhodolite_Pyrope: '1.90',
          Corundum_Zircon_Almandite: '2.00'
      },
      ],
      selected_gemstone_id: null,
      selected_chart: [{}],
			resource_data:{
			"2.0mm": 0.03,
			"2.5mm": 0.05,
			"firstname": '',
			"lastname":  '',
			"iscompany": 0, 
			"company_name": '',
			"telephone": '',
			"external_id": '',
			"email": '',
			},
      filter: null,
      filterOn: [],
      sortBy: "size",
      sortDesc: false,
		}
	},
	methods:{
	  emailAddress(address){
        return 'mailto:'+address+'';
      },
       resourceEdit(id){
        return '../'+this.resource_type+'/'+id+'/edit';
      },
      resourceImage(filepath){
                var image = filepath.replace('\\', '/');
                return "http://localhost/jewellery-tools/public/storage/"+image;
      },
      openPhotoGallery(images){
        var image_array = [];
        image_array = images;
        console.log(image_array);
         EventBus.$emit('open-gallery', image_array);
      },
      selectedChart(cut, gemstone){
        this.selected_chart = this.charts.filter(item => (item.gemstone_id === gemstone) && (item.cut_id === cut));
        //var chart = this.charts.filter(item => item.cut_id === cut);
        console.log(selected_chart);
      },
      handleScroll () {
    	if(window.scrollY > 125){
    		this.scrolled = "fixed-sidebar mt-4";
    	}else{
    		this.scrolled = false;
    	}
  	  },
	},
	mounted(){
     let self = this;
      EventBus.$on('show-gemstone-chart', function(active){
        self.showGemstoneCharts = true;
      });
	},
	created () {
  		window.addEventListener('scroll', this.handleScroll);
	},
	destroyed () {
	  	window.removeEventListener('scroll', this.handleScroll);
	}
}
</script>
<template>

<div>
  <div class="row mt-4">
    <div class="col-md-12">
        <div class="row">
        <div class="col-md-12 mx-auto mb-4">
          <h3 class="text-center">Gemstone Weight Charts</h3>
        </div>
        <div class="col-md-12 mx-auto">
          <b-tabs pills :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn" justified content-class="mt-3">
                <b-tab title="Round Cuts" active>
                  <b-table striped hover :items="roundcut" :fields="fields" :sticky-header="stickyHeader"></b-table>
                </b-tab>
                <b-tab title="Oval Cuts">
                  <b-table striped hover :items="ovalcut" :fields="fields"></b-table>
                </b-tab>
                <b-tab title="Square Cut">
                  <b-table striped hover :items="squarecut" :fields="fields"></b-table>
                </b-tab>
                <b-tab title="Marquise Cut">
                  <b-table striped hover :items="marquisecut" :fields="fields"></b-table>
                </b-tab>
                <b-tab title="Pear Cut">
                  <b-table striped hover :items="pearcut" :fields="fields"></b-table>
                </b-tab>
                <b-tab title="Heart Cut">
                  <b-table class="mb-4 pb-4" striped hover :items="heartcut" :fields="fields"></b-table>
                </b-tab>
              </b-tabs>
            </div>
          </div>
      </div>
    </div>
  </div>
  </Layout>
</template>