<script type="text/javascript">
	export default{
		data: function(){
			return{

			}
		},
		methods: {
			printPreview(){
				var prtContent = document.getElementById("doc-preview");
				var WinPrint = window.open('http://app.gemesys.co.uk/', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
				WinPrint.document.write(prtContent.innerHTML);
				WinPrint.document.close();
				WinPrint.focus();
				WinPrint.print();
				WinPrint.close();
			}
		},
		props: {
			data: {
				type: Object,
				default: () => { rows: [] },
			},
			preview_type: {
				type: String,
				default: () => 'webpage',
			}
		}
	}

</script>
<template>
	<b-row>
		<b-col md="12" class="px-4">
			<b-row v-if="preview_type == 'webpage'">
			</b-row>
			<b-row  v-if="preview_type == 'document'" class="bg-light">
				<b-col md="12" class="d-flex my-4">

					<a class="btn btn-primary ml-auto my-auto btn-rounded" @click="$emit('close-preview')"><i class="mdi mdi-close font-size-14"></i></a>
                  	<a class="btn btn-info ml-3 my-auto btn-rounded" @click="printPreview()"><i class="bx bx-print font-size-14"></i></a>
				</b-col>
				<div id="doc-preview" class="document-preview mx-auto bg-white shadow-sm">
					<div class="p-4">
                 <b-col md="12">
                  <b-row v-for="(row, rindex) in data.rows" style="min-height: 100px;" class="position-relative">
                    <!--<span class="page-builder-tab text-white bg-primary row-tabs">Row</span>
                    <span class="page-builder-tools text-white bg-primary row-tabs d-flex"><a @click="deleteRow(rindex)" class="flex-grow-1" href="javascript:;"><i class="bx bx-x"></i></a><a class="flex-grow-1 handle" href="javascript:;"><i class="bx bx-move"></i></a></span>-->
                    <b-col
                       v-if="row.cols" v-for="(col, cindex) in row.cols" style="min-height: 100px;"
                      >
                      <!--<span class="page-builder-tab text-white bg-info">Column</span>
                      <span class="page-builder-tools text-white bg-info d-flex"><a @click="deleteColumn(rindex, cindex)" class="flex-grow-1" href="javascript:;"><i class="bx bx-x"></i></a><a class="flex-grow-1 handle2" href="javascript:;"><i class="bx bx-move"></i></a></span>-->
                      <div v-if="col.blocks" class="widget-block position-relative  border" v-for="(block, bindex) in col.blocks" :class="{'page-builder-element-outline': block.editable == 1, 'page-builder-element-outline': hoverElement == ''+rindex+''+cindex+''+bindex+'', 'border-white': hoverElement !== ''+rindex+''+cindex+''+bindex+''}" @mouseover="hoverElement = ''+rindex+''+cindex+''+bindex+''" @mouseout="hoverElement = ''">
                        <!--<span class="page-builder-tab text-white bg-secondary" :class="{'invisible': hoverElement !== ''+rindex+''+cindex+''+bindex+''}">{{block.widgetname}}</span><span class="page-builder-tools text-white bg-secondary d-flex" :class="{'invisible': hoverElement !== ''+rindex+''+cindex+''+bindex+''}"><a class="flex-grow-1" href="javascript:;" @click="deleteWidget(rindex, cindex, bindex)"><i class="bx bx-x"></i></a><a class="flex-grow-1" href="javascript:;" @click="editWidget(rindex, cindex, bindex)"><i class="bx bx-pencil"></i><a class="flex-grow-1 handle3" href="javascript:;"><i class="bx bx-move"></i></a></a></span>-->
                        
                        <div v-if="block.widgettype == 'text'" v-html="block.widgetcontent.text"></div>
                        <div v-if="block.widgettype == 'image'" :class="block.widgetcontent.alignment">
                          <img  :src="block.widgetcontent.url" :width="block.widgetcontent.width" :height="block.widgetcontent.height">
                        </div>
                        <div v-if="block.widgettype == 'address'">
                          [Store Address]
                        </div>
                        <div v-if="block.widgettype == 'customer-address'">
                          [Customer Address]
                        </div>
                        <div v-if="block.widgettype == 'spacer'" :style="{'height': block.widgetcontent.height+'px'}">
                        </div>
                        <hr v-if="block.widgettype == 'divider'" class="text-white" :style="{'border-style': block.widgetcontent.bordertype, 'border-width': block.widgetcontent.width+'px', 'border-color': block.widgetcontent.color}">
                        <div v-if="block.widgettype == 'signature'">
                          <img :src="storageurl+block.widgetcontent.image">
                          <p class="mb-0">{{block.widgetcontent.firstname}} {{block.widgetcontent.lastname}}</p>
                          <strong>{{block.widgetcontent.companyname}}</strong>
                        </div>
                        <img v-if="block.widgettype == 'stamp'" :src="block.widgetcontent.url">
                        <div v-if="block.widgettype == 'note'" v-html="block.widgetcontent.content">
                        </div>
                       
                      </div>
                      <!--<div class="position-relative">
                        <div class="w-100 d-flex h-100" style="min-height: 100px">
                        <a v-if="widgetBox !== ''+rindex + '' + cindex + ''" v-b-tooltip.hover title="Add Widget" class="btn btn-secondary btn-rounded m-auto" href="javascript:;" @click="widgetBox = ''+rindex + '' + cindex + ''"><i class="mdi mdi-plus"></i></a>
                        <a v-else class="btn btn-secondary btn-rounded m-auto" href="javascript:;" @click="widgetBox = '', widgetBoxEditMode = false, widgetEditBlockIndex = null, widgetBoxEditContent =''"><i class="mdi mdi-close noti-icon font-size-14"></i></a>
                        </div>
                       <div class="widget-box border shadow-sm bg-white " v-if="widgetBox === ''+rindex + '' + cindex + ''" :class="{'widget-box-lg': widgetBoxEditContent.widgettype == 'text', 'widget-box-lg': widgetBoxEditContent.widgettype == 'customer-address'}">
                        
                        <div class="d-flex w-100">
                          <strong class="w-75 d-block mb-2">Add Widget</strong>
                          
                        </div>
                   <b-button v-for="widget in widgets" v-if="widgetBoxEditMode == false"
                      class=" flex-grow-1 w-lg py-4 m-2"
                      value="0"
                      variant="light"
                      @click="widgetBoxEditContent = JSON.parse(JSON.stringify(widget)), widgetBoxEditMode = true"
                    ><i class="font-size-24 d-block text-primary my-2" :class="widget.widgeticon"></i> 
                     {{widget.widgetname}}
                    </b-button>
                    <div v-if="widgetBoxEditMode">
                        <div v-if="widgetBoxEditContent.widgettype == 'text'">
                          <b-form-group label="Text" >
                             <ckeditor id="issue" v-model="widgetBoxEditContent.widgetcontent.text" :editor="editor" rows="6" ></ckeditor>
                          </b-form-group>

                         </div>
                         <div v-if="widgetBoxEditContent.widgettype == 'divider'">
                          <b-form-group class="mb-2" label="Width">
                            <b-form-input class="w-100" type="range" v-model="widgetBoxEditContent.widgetcontent.width"></b-form-input>
                            <small>{{widgetBoxEditContent.widgetcontent.width}}px</small>
                          </b-form-group>
                          <b-form-group class="mb-2" label="Colour">
                            <b-form-input type="color" v-model="widgetBoxEditContent.widgetcontent.color"></b-form-input>
                          </b-form-group>
                          <b-form-group class="mb-2" label="Divider Style">
                             <b-form-select v-model="widgetBoxEditContent.widgetcontent.bordertype" :options="widgetBoxEditContent.widgetoptions.bordertypes"></b-form-select>
                          </b-form-group>
                          </div>
                          <div v-if="widgetBoxEditContent.widgettype == 'spacer'">
                          <b-form-group class="mb-2" label="Height">
                            <b-form-input type="range" class="w-100" v-model="widgetBoxEditContent.widgetcontent.height"></b-form-input>
                            <small>{{widgetBoxEditContent.widgetcontent.height}}px</small>
                          </b-form-group>
                          </div>
                         <div v-if="widgetBoxEditContent.widgettype == 'image'">
                          <div class="bg-light my-4 d-flex" style="min-height: 150px">
                          <a v-if="widgetBoxEditContent.widgetcontent.url == ''" class="btn btn-primary w-75 m-auto" href="javascript:;"  @click="fileResource = fileRowIndex = rindex, fileColIndex = cindex, showFileManager = !showFileManager">Select File</a>
                          <img v-else :src="widgetBoxEditContent.widgetcontent.url" class="w-75 m-auto">
                          </div>
                          <b-form-group class="mb-2" label="Width">
                            <b-form-input type="number" v-model="widgetBoxEditContent.widgetcontent.width" :disabled="widgetBoxEditContent.widgetcontent.fullwidth"></b-form-input>
                          </b-form-group>
                          <b-form-group class="mb-2" label="Height">
                            <b-form-input type="number" v-model="widgetBoxEditContent.widgetcontent.height" :disabled="widgetBoxEditContent.widgetcontent.fullwidth"></b-form-input>
                          </b-form-group> 
                          <b-form-group label="Alignment" class="mb-2">
                            <a class="btn btn-light" :class="{'btn-primary': widgetBoxEditContent.widgetcontent.alignment == 'text-left', 'btn-light': widgetBoxEditContent.widgetcontent.alignment !== 'text-left'}" @click="widgetBoxEditContent.widgetcontent.alignment = 'text-left'">
                              <i class="bx bx-align-left font-size-20"></i>
                            </a>
                            <a class="btn btn-light" :class="{'btn-primary': widgetBoxEditContent.widgetcontent.alignment == 'text-center', 'btn-light': widgetBoxEditContent.widgetcontent.alignment !== 'text-center'}" @click="widgetBoxEditContent.widgetcontent.alignment = 'text-center'">
                              <i class="bx bx-align-middle font-size-20"></i>
                            </a>
                            <a class="btn btn-light" :class="{'btn-primary': widgetBoxEditContent.widgetcontent.alignment == 'text-right', 'btn-light': widgetBoxEditContent.widgetcontent.alignment !== 'text-right'}" @click="widgetBoxEditContent.widgetcontent.alignment = 'text-right'">
                              <i class="bx bx-align-right font-size-20"></i>
                            </a>
                          </b-form-group>
                          <b-form-group class="mb-2" label="">
                            <b-form-checkbox v-model="widgetBoxEditContent.widgetcontent.fullwidth" @change="enforceFullwidthImage()" name="check-button" switch>
                                  Enforce Fullwith
                                </b-form-checkbox>
                          </b-form-group>                          
                         </div>
                         <div v-if="widgetBoxEditContent.widgettype == 'signature'">
                          <SelectValuationSignature :user="$attrs.user" :isDisabled="false" :id="''" @brand-selected="selectValue"></SelectValuationSignature>
                         </div>
                         <div v-if="widgetBoxEditContent.widgettype == 'stamp'">
                          <SelectValuationStamp :user="$attrs.user" :isDisabled="false" :id="''" @brand-selected="selectValue"></SelectValuationStamp>
                         </div>
                         <div v-if="widgetBoxEditContent.widgettype == 'note'">
                          <SelectValuationNote :user="$attrs.user" :isDisabled="false" :id="''" @brand-selected="selectValue"></SelectValuationNote>
                         </div>
                         <div v-if="widgetBoxEditContent.widgettype == 'customer-address'">
                          <b-form-group class="mb-2" label="Use Individual Customer" description="If you want to select a particular customer for this template, check this box. Otherwise customer address will be added from your appraisal customer.">
                            <b-form-checkbox v-model="widgetBoxEditContent.widgetcontent.selectcustomer"  name="check-button" switch>
                                  Select Customer
                                </b-form-checkbox>
                          </b-form-group>    
                          <b-form-group class="mt-2" label="Customer"  v-if="(widgetBoxEditContent.widgetcontent.selectcustomer == true)">
                            <CompanyCustomerSearch v-if="(widgetBoxEditContent.widgetcontent.selectcustomer == true) && (widgetBoxEditContent.widgetcontent.selectedcustomer == null)" :id="widgetBoxEditContent.widgetcontent.selectedcustomer"  :api_token="$attrs.user.api_token" @search-resource-selected="selectCustomer"></CompanyCustomerSearch>
                            <div class="d-flex w-100 h-100 border rounded-3 p-2 mt-3" v-if="widgetBoxEditContent.widgetcontent.selectedcustomer !== null">
                                <div class="avatar-sm mr-4 my-auto" v-if="widgetBoxEditContent.widgetcontent.selectedcustomer.user_details.avatar == null">
                                  <span class="avatar-title rounded-circle">{{widgetBoxEditContent.widgetcontent.selectedcustomer.firstname.slice(0, 1)}}</span>
                                </div>
                                 <img v-else :src="`${widgetBoxEditContent.widgetcontent.selectedcustomer.user_details.avatar}`" alt class="avatar-sm rounded-circle" />
                                <div class="option__desc d-flex flex-row w-75">
                                  <span class="option__title ml-2 my-auto w-100">{{widgetBoxEditContent.widgetcontent.selectedcustomer.firstname}} {{widgetBoxEditContent.widgetcontent.selectedcustomer.lastname}}<br><p class="m-0 lh-1 w-100 text-truncate"><small>{{widgetBoxEditContent.widgetcontent.selectedcustomer.user_details.email}}</small></p></span></div>
                                  <div class="d-flex flex-column flex-grow-1"><a class="btn btn-outline-light btn-rounded ml-auto my-auto mr-0"><i class="mdi mdi-close noti-icon font-size-16" href="javascript:;" @click="widgetBoxEditContent.widgetcontent.selectedcustomer = null" ></i></a></div></div>
                          </b-form-group>
                          <b-form-group class="mt-2" label="Address" v-if="(widgetBoxEditContent.widgetcontent.selectcustomer == true)">
                              <SearchCompanyCustomerAddress v-if="widgetBoxEditContent.widgetcontent.selectedcustomer !== null" :id="widgetBoxEditContent.widgetcontent.selectedaddress" :api_token="$attrs.user.api_token" :options="widgetBoxEditContent.widgetcontent.selectedcustomer.addresses" @status-selected="selectAddress" :data_provided="true" ></SearchCompanyCustomerAddress>
                              
                         </b-form-group>
                         </div>
                         <a class="btn btn-success my-2" v-if="widgetEditBlockIndex == null" @click="addNewWidget(widgetBoxEditContent, rindex, cindex), widgetBoxEditMode = false, widgetBoxEditContent = ''"> Save</a>
                          <a v-else class="btn btn-success my-2" @click="widgetBoxEditMode = false, widgetBoxEditContent = '', widgetBox = '', widgetEditBlockIndex = null">Update</a>
                    </div>

                </div>
                      </div>-->
                  </b-col>
                    <!--<b-col md="12" class="text-center d-flex"  v-if="row.cols.length == 0" style="min-height: 100px;">
                    <a v-b-tooltip.hover title="Add Columns" class="btn btn-info btn-rounded m-auto" href="javascript:;" @click="showColOptions = ''+rindex+''+cindex+''" v-if="showColOptions !== ''+rindex+''+cindex+''"><i class="mdi mdi-plus"></i></a>
                    <a v-b-tooltip.hover title="Close" class="btn btn-info btn-rounded m-auto" href="javascript:;" @click="showColOptions = ''" v-else><i class="mdi mdi-close noti-icon font-size-14"></i></a>
                    <b-row class="page-builder-column-select rounded-3">
                      <b-col md="6" class="text-center mx-auto bg-light" v-if="(row.cols.length == 0) && (showColOptions == ''+rindex+''+cindex+'')">
                      <a class="btn btn-info mx-auto my-3" @click="data.rows[rindex].cols.push({blocks: []})">Add 1 Column</a>
                      <a class="btn btn-info mx-auto my-3" @click="data.rows[rindex].cols.push({blocks: []}), data.rows[rindex].cols.push({blocks: []})">Add 2 Columns</a>
                      <a class="btn btn-info mx-auto my-3" @click="data.rows[rindex].cols.push({blocks: []}), data.rows[rindex].cols.push({blocks: []}), data.rows[rindex].cols.push({blocks: []})">Add 3 Columns</a>
                    </b-col>
                    </b-row>
                    </b-col>-->
                  </b-row>
                  <!--<b-row>
                    <b-col md="12" class="d-flex text-center" style="min-height: 75px;">
                  <a v-if="(data.rows.length < row_limit) || (row_limit == -1)" class="btn btn-primary btn-rounded m-auto" @click="data.rows.push({cols: []})"><i class="mdi mdi-plus"></i></a>
                  <a v-if="(data.rows.length >= row_limit) && (row_limit !== -1)" class="btn btn-primary btn-rounded m-auto" @click="limitReached('row', row_limit)"><i class="mdi mdi-plus"></i></a>
                  <a class="btn btn-primary ml-auto page-builder-preview-btn btn-rounded" v-if="allow_preview" @click="preview = !preview" href="javascript:;"><i v-if="preview == false" class="mdi mdi-eye font-size-14"></i><i v-else class="mdi mdi-close font-size-14"></i></a>
                    </b-col>
                  </b-row>-->
              </b-col>
                </div>
				</div>
			</b-row>
		</b-col>
	</b-row>
</template>