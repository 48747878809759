<template>
	<div>
	<div class="mt-sm-0 form-inline w-100" >
              <div class="app-search search-box pb-0">
                <div class="position-relative">
                  <input
                    type="text"
                    class="form-control w-100 border"
                    placeholder="Search name, reference or description..."
                    v-model="$attrs.filters.search.value"
                    @keyup.enter="getResource($attrs.filters.search_area.value)"
                  />
                  <span class="bx bx-search-alt search-icon"></span>
                </div>
              </div>
              <div v-if="($attrs.elements[filterStylesKey($attrs.elements, 'results')].results.value == 'Dropdown') && (isResults == true)" class="position-absolute w-100 shadow p-3 z-index-9 bg-white shadow">
              
              	<b-tabs v-if="isResults == true" pills content-class="pt-3">
              			<b-overlay :show="loadingResults">
          			<b-tab v-for="(item, resultskey) in search_results" :title="resultskey" v-if="$attrs.filters.search_area.value.includes(resultskey) == true"  @click="selectedKey = resultskey">
		             <b-list-group class="search-widget-results">
		             <b-list-group-item v-for="(result, index) in search_results[resultskey].data" class="d-flex">
		              <div v-if="resultskey == 'Products'">
								 			<b-img-lazy v-if="(result.cover_image !== null) && ('gallery' in result.cover_image)" class="rounded-3 avatar-lg mr-3" :src="storageurl+result.cover_image.gallery.replaceAll('//', '/')" ></b-img-lazy>
								   		<b-img-lazy v-else-if="(result.cover_image !== null) && ('thumb' in result.cover_image)" class="rounded-3 avatar-lg mr-3" :src="storageurl+result.cover_image.thumb.replaceAll('//', '/')" ></b-img-lazy>
								   		<b-img-lazy v-else-if="(result.cover_image !== null) && ('src' in result.cover_image)" class="rounded-3 avatar-lg mr-3" :src="storageurl+result.cover_image.src.replaceAll('//', '/')" ></b-img-lazy>
								</div>
								<div v-else-if="resultskey == 'Listings'">
							    		<b-img-lazy v-if="(result.banner !== '') && (result.banner !== null)" class="rounded-3 avatar-lg mr-3" :src="storageurl+result.banner.replaceAll('//', '/')" ></b-img-lazy>
							    	</div>
							    	<div v-else>
							    		<b-img-lazy v-if="(result.image !== '') && (result.image !== null)" class="rounded-3 avatar-lg mr-3" :src="result.image" ></b-img-lazy>
							    	</div>
											<div class="flex-inherit w-75">
												<h4>{{result.name}}</h4>
										      <p class="text-dark" v-if="resultskey == 'Products'" v-html="result.short_description">
										      </p>
							          	<p class="text-dark" v-else v-html="result.description">
							          	</p>
							        </div>
							        	
							        		<div class="flex-inherit d-flex ml-auto">
														<a class="border-bottom border-primary text-uppercase font-size-12 ml-auto text-dark my-auto py-2" :href="'javascript:;'">
															See Result <i class="bx bx-chevron-right"></i>
														</a>
													</div>
		              		</b-list-group-item>
		              	</b-list-group>
		              </b-tab>
		              </b-overlay>
		              <template #tabs-end>
          		 	<b-pagination id="dropdown-pagination"  class="ml-auto" v-model="$attrs.filters.page.value" :total-rows="search_results[selectedKey].total" :per-page="search_results[selectedKey].per_page" align="right"></b-pagination>
          		 </template>
		          </b-tabs>
		          <div v-else-if="(isResults == true) && ($attrs.filters.search.value.length < 3)">
			          <b-card class="shadow-none mb-0 pb-0 border border-radius-1em" body-class="d-flex flex-column justify-content-center align-items-center p-5">
			          	<h6>No Search Results</h6>
			          	<p class="text-muted">Please make sure your search query is over 3 characters long and try again.</p>
			          </b-card>
		        	</div>
		        	<div class="d-flex">
		        		<a class="ml-auto mt-3" @click="$attrs.filters.search.value = '', getResource($attrs.filters.search_area.value)" href="javascript:;">
		        			Clear Search
		        		</a>
		        	</div>
              </div>
            </div>

          <div v-if="$attrs.elements[filterStylesKey($attrs.elements, 'results')].results.value == 'Page'">
          	<div class="d-flex mt-3">
          	<h2 class="my-auto">Search Results</h2>
          	<a v-if="$attrs.filters.search.value.length > 3" class="ml-auto my-auto position-relative" @click="$attrs.filters.search.value = '', getResource($attrs.filters.search_area.value)" href="javascript:;">
		        			Clear Search
		        		</a>
		        </div>
          	<hr>
          	<b-tabs pills content-class="pt-3">
          		 	<b-overlay :show="loadingResults">
          		<b-tab title-link-class="position-relative" v-for="(item, resultskey) in search_results" :title="resultskey" v-if="$attrs.filters.search_area.value.includes(resultskey) == true" @click="selectedKey = resultskey">
		          	<b-card no-body v-for="result in search_results[resultskey].data" class="border-bottom shadow-none">
		          		<b-row no-gutters>
		          				<b-col md="3"  v-if="$attrs.configs[filterStylesKey($attrs.configs, 'show_image')].show_image.value == true" >
		          				<div v-if="resultskey == 'Products'">
							        	<b-img-lazy v-if="(result.cover_image !== null) && ('gallery' in result.cover_image)" class="rounded-3 avatar-lg mr-3" :src="storageurl+result.cover_image.gallery.replaceAll('//', '/')" ></b-img-lazy>
									   		<b-img-lazy v-else-if="(result.cover_image !== null) && ('thumb' in result.cover_image)" class="rounded-3 avatar-lg mr-3" :src="storageurl+result.cover_image.thumb.replaceAll('//', '/')" ></b-img-lazy>
									   		<b-img-lazy v-else-if="(result.cover_image !== null) && ('src' in result.cover_image)" class="rounded-3 avatar-lg mr-3" :src="storageurl+result.cover_image.src.replaceAll('//', '/')" ></b-img-lazy>
							    		</div>
							    	<div v-else-if="resultskey == 'Listings'">
							    		<b-img-lazy v-if="(result.banner !== '') && (result.banner !== null)" class="rounded-3 avatar-lg mr-3" :src="storageurl+result.banner.replaceAll('//', '/')" ></b-img-lazy>
							    	</div>
							    	<div v-else>
							    		<b-img-lazy v-if="(result.image !== '') && (result.image !== null)" class="rounded-3 avatar-lg mr-3" :src="result.image" ></b-img-lazy>
							    	</div>
							      </b-col>
							      <b-col :md="{9 : $attrs.configs[filterStylesKey($attrs.configs, 'show_image')].show_image.value == true, 12 : $attrs.elements[filterStylesKey($attrs.elements, 'show_image')].show_image.value !== ''}" class="d-flex flex-column justify-content-center">
							        <b-card-body class="flex-inherit">
							          <h4>{{result.name}}</h4>
										      <p class="text-dark" v-if="resultskey == 'Products'" v-html="result.short_description">
										      </p>
							          	<p class="text-dark" v-else v-html="result.description">
							          	</p>
							          <div class="flex-inherit d-flex ml-auto">
														<a class="border-bottom border-primary text-uppercase font-size-12 ml-auto text-dark my-auto py-2" :href="'javascript:;'">
															See Result <i class="bx bx-chevron-right"></i>
														</a>
													</div>
							        </b-card-body>
							      </b-col>
		          		</b-row>
		          	</b-card>
		          		<b-pagination id="page-footer-pagination"  class="mx-auto" v-model="$attrs.filters.page.value" :total-rows="search_results[selectedKey].total" :per-page="search_results[selectedKey].per_page" align="center"></b-pagination>
          		</b-tab>
          	</b-overlay>
          		<template #tabs-end>
          		 	<b-pagination id="page-tabs-pagination"  class="ml-auto" v-model="$attrs.filters.page.value" :total-rows="search_results[selectedKey].total" :per-page="search_results[selectedKey].per_page" align="right"></b-pagination>
          		 </template>
      		</b-tabs>

          </div>
     </div>
</template>

<script type="text/javascript">
	export default{
		data(){
			return{
				search_results: {Products: '', Collections: '', Categories: '', Listings: '', Services: ''},
				current_page: 1,
				query: '',
				hoverIndex: -1,
				storageurl: 'https://'+this.$attrs.company.nickname+'.s3.eu-west-2.amazonaws.com',
				isResults: false,
				selectedKey: '',
				loadingResults: false,
			}
		},
		watch:{
			'$attrs.filters.page.value': function (){
				this.getResource(this.$attrs.filters.search_area.value);
			}
		},
		created(){
			this.selectedKey = this.$attrs.filters.search_area.value[0];
		},
		methods: {
			filterStylesKey(styles, key){
            for(var i = 0; i < styles.length; i++){
               var keys = Object.keys(styles[i])
               if(keys.includes(key)){
                 return i;
               }
            }
       },
			getResource(search_areas){
				this.loadingResults = true;
				this.isResults = false;
				if(search_areas.length > 0){
					if(this.$attrs.filters.search.value.length > 3){
							this.createQueryString();
							if(search_areas.includes('Products') == true){
								this.searchProducts();
							}
							if(search_areas.includes('Categories') == true){
								this.search_results["Categories"] = this.searchCategories();
							}
							if(search_areas.includes('Collections')){
								this.search_results["Collections"] = this.searchCollections();
							}
							if(search_areas.includes('Listings')){
								this.search_results["Listings"] = this.searchListings();
							}
							this.isResults = true;
					}	
				}
				let self = this;
						          setTimeout(function(){
						            self.loadingResults = false;
						          }, 1000);
			},
			createQueryString(){
				this.query = '';
				for(var key in this.$attrs.filters){
					if(key !== 'search_area'){
						this.query += '&'+key+'='+this.$attrs.filters[key].value+'';
					}
				}
			},
			searchProducts(){
				if(this.$attrs.liveMode == true){
					axios.get('/website-api/widget/products?page='+this.current_page+this.query).then(response => {
							this.search_results["Products"] = response.data;
						
						}).catch(error => {
						
						});
				}else{
					axios.get('/api/company/web/page/widgets/products?page='+this.current_page+this.query, {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
						this.search_results["Products"] = response.data;
					}).catch(error => {
					});
				}
			},
			searchCategories(){
				if(this.$attrs.liveMode == true){
					axios.get('/website-api/widget/categories?page='+this.current_page+this.query).then(response => {
						this.search_results["Categories"] = response.data;
					}).catch(error => {
						return [];
						console.log(error);
					});
				}else if(this.$attrs.liveMode == false){
					axios.get('/api/company/web/page/widgets/categories?page='+this.current_page+this.query, {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
						this.search_results["Categories"] = response.data;
					}).catch(error => {
					});
				}
			},
			searchCollections(){
				if(this.$attrs.liveMode == true){
					axios.get('/website-api/widget/collections?page='+this.current_page+this.query).then(response => {
						this.search_results["Collections"] = response.data;
					}).catch(error => {
						return [];
						console.log(error);
					});
				}else if(this.$attrs.liveMode == false){
					axios.get('/api/company/web/page/widgets/collections?page='+this.current_page+this.query, {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
						this.search_results["Collections"] = response.data;
					}).catch(error => {
					});
				}
			},
			searchListings(){
				if(this.$attrs.liveMode == true){
					axios.get('/website-api/widget/listings?page='+this.current_page+this.query).then(response => {
						this.search_results["Listings"] = response.data;
					}).catch(error => {
						return [];
						console.log(error);
					});
				}else if(this.$attrs.liveMode == false){
					axios.get('/api/company/web/page/widgets/listings?page='+this.current_page+this.query, {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
						this.search_results["Listings"] = response.data;
					}).catch(error => {
					});
				}
			},
		}
	}

</script>