<script type="text/javascript">
	import simplebar from 'simplebar-vue'
	import SearchCompanyUser from '../../SearchCompanyUser'
	import SideSlidePanel from '../../SideSlidePanel'
	export default {
		components: { simplebar, SearchCompanyUser, SideSlidePanel },
		props: {
					reply_route: {
						type: String,
						default: () => '/api/company/communication/support-thread/send-reply'
					},
					userview: {
						type: String,
						default: () => 'company'
					},
					user: {
						type: Object
					},
					resource: {
		        type: String,
		        default: () => '',
		      },
		      resource_id: {
		        type: Number,
		        default: () => '',
		      },
		      api_token: {
		        type: String,
		        default: () => '',
		      },
		      resource_route: {
		        type: String,
		        default: () => '',
		      },
		      view: {
		        type: String,
		        default: () => 'list',
		      },
		      thread_list: {
		        type: Array,
		      },
		      thread_uid: {
		      		type: String,
		      		default: () => '',
		      },
		     	location: {
		      		type: String,
		      		default: () => '',
		      },
		      expanded:{
		      	type: Number,
		      	default: () => 0
		      },
		      order_source:{
		      	type: String,
		      	default: () => '',
		      },
		      company_order: {
		      	type: Array,
		      default: 	() => []
		      }
		  },
		  data(){
			return{
				msg_loading_progress: 5,
				source: '',
				selected_user: '',
				orderViewWindow: false,
				success_message: false,
				success_messagetext: 'Your action was successful',
				error_reponse: [],
				error_message: false,
				supportStatuses: [{text: "Awaiting User Assignment", value: 1},{text: "Awaiting Response", value: 2},{text: "In Progress", value: 3},{text: "In Dispute", value: 4},{text: "Resolved", value: 5},{text: "Archived", value: 6}],
				contactOptions: [
					{icon: 'bx bx-mail-send', text: "SMS", value: 'SMS'},
					{icon: 'bx bx-chat', text: 'Chat', value: 'chat'},
					{icon: 'bx bxl-whatsapp', text: "WhatsApp", value: 'whatsapp'}
				],
				loadingMessages: false,
		        quickviewproduct: '',
		        echoChannel: '',
		        cthread_uid: 0,
		        active_thread: '',
		        cthread_source: '',
		        data: {
		        	support_order_id: '',
							support_contact: '',
							status: 1,
		        	support_type: '',
							support_order: '',
							support_contact: '',
							name: '',
							email: '',
							telephone: '',
							mobile: '',
							subject: '',
							message: '',
							parent_id: '',
							customer_id: '',
							assigned_to_id: '',
		        },
		        thread_messages: [],
		        support_types: [
					{
						text: 'Order Enquiry', value: 'order'
					},
					{
						text: 'Order Cancellation', value: 'cancellation'
					},
					{
						text: 'Delivery Issue', value: 'delivery'
					},
					{
						text: 'Product Enquiry', value: 'product'
					},
					{
						text: 'Technical Issue', value: 'tech'
					},
					{
						text: 'Account Issue', value: 'account'
					},
					{
						text: 'Pre-Sales Question', value: 'presales'
					}
				],
								}
		  },
		  mounted () {
			    window.addEventListener(
			         'popstate', this.handleHistoryChange
			         )
					const searchParams = new URLSearchParams(window.location.search);
					const supportTypeValue = searchParams.get('support_type');
					if (supportTypeValue) {
					
					  this.cthread_source = supportTypeValue;
					}


					

		},
		destroyed () {
		     window.removeEventListener(
		            'popstate', this.handleHistoryChange
		        ) 
		   },
		  methods: {
		  	filterNotifications(source){
		      //var data = '{uid : []}';
		      //history.pushState(data, '', "/communicate/customer-service/");

		      this.$emit('filter', source);
		    },
		  	sendReply(originalmessage){
		  		//console.log('chat.support.'+this.getActiveThread.source+'.'+this.cthread_uid+'');
		  	


		  		this.loadingMessages = true;
		  				var customer = '';
		  				if(this.userview == 'customer'){
		  					customer = this.getActiveThread.company;
		  				}else{
		  					customer = this.getActiveThread.customer;
		  				}
		  				this.data.support_type = originalmessage.support_type;
							this.data.support_order = originalmessage.support_order_id;
							this.data.support_order_id = originalmessage.support_order_id;
							this.data.support_contact = originalmessage.support_contact;
							this.data.name = originalmessage.name;
							this.data.email = originalmessage.email;
							this.data.telephone = originalmessage.telephone;
							this.data.subject = originalmessage.subject;
							this.data.customer_id = this.getActiveThread.customer.id;
							if(this.getActiveThread.assigned_to_id == null){
								this.data.assigned_to_id = this.user.companies[0].id;
							}
							this.data.parent_id = this.getActiveThread.id;
							axios.post(this.reply_route, {data: this.data, customer: this.getActiveThread.customer, company: this.getActiveThread.company, type: this.getActiveThread.source} , {
								headers: {'Authorization' : 'Bearer '+this.user.api_token}
							}).then(response => {

								this.success_messagetext = "Your message has been sent";
								//this.getConversationThread(this.cthread_uid, this.cthread_source);
								//this.echoChannel.trigger('.NewMessage', {message: 'Message Sent'});
								
								 
								let self = this;
								setTimeout(function(){
									self.loadingMessages = false;
									self.success_message = true;
									/**var container = document.querySelector('#containerElement .simplebar-content-wrapper');
									var containerHeight = document.querySelector('#containerElement .simplebar-content-wrapper .simplebar-content');
									container.scrollTo({ top: (containerHeight.offsetHeight + 200), behavior: "smooth" });**/
								}, 150);
							}).catch(error => {
								console.log(error);
								this.error_reponse = error.response.error.errors;
							});
		  	},
		  	handleHistoryChange(){
		  		if(window.location.search == ''){
									this.active_thread = '';
								
								}
									this.cthread_uid = '';
		  	},
		  	uncollapseThread(thread){
		  		if(thread !== null){
		  		this.cthread_uid = thread.uid;
		  		this.cthread_source = thread.source;
		  		this.getConversationThread(this.cthread_uid, this.cthread_source);
		  	
		  		//this.loadList();
		  		if(thread.read_at == null){
		  			this.$emit('mark-read', [thread.uid, thread.source, true]);
		  		}
		  	
		  		var data = '{uid : ['+thread.thread_start+']}';
		  		if(this.userview == 'company'){
		  			if(this.order_source == ''){
		  			history.pushState(data, '', "/communicate/customer-service/"+thread.uid+'?support_type='+thread.source);
		  			}
		  		}else if(this.userview == 'customer'){
		  			history.pushState(data, '', "support?uid="+thread.uid);
		  		}

		  		this.echoChannel = window.Echo.channel('chat.support.'+this.cthread_source+'.'+this.cthread_uid+'');
        		this.echoChannel.listen('.App\\Events\\NewSupportMessage', (e) => {
							this.getConversationThread(this.cthread_uid, this.cthread_source);
				    		});
        	}
		  	},
		  	getSupportType(value){
		  		var support_item = this.support_types.filter(item => item.value = value);
		  		return support_item[0].text;
		  	},
		  	markArchived(thread){
		  		this.$emit('mark-archived', [thread.uid, thread.source, true]);
		  	},
		  	markInDispute(thread){
		  		this.$emit('mark-disputed', [thread.uid, thread.source, true]);
		  	},
		  	markComplete(thread){
		  		this.$emit('mark-complete', [thread.uid, thread.source, true]);
		  	},
		  	removeResource(){

		  	},
		  	priceIncVat(price, tax_percentage){
		      var tax = 1 + (tax_percentage / 100);
		      return price * tax;
		    },
		     loadList(){
		    	let self = this;
		    	setTimeout(function() {
		    		self.loadingMessages = false;
		    	}, 650);
		    },
		    getMessageFrom(index, thread){
		    	return thread[index]["from"];
		    },
		    getConversation(uid, support){
		    	this.msg_loading_progress = 10;
		    	this.loadingMessages = true;
		    	if(this.userview == 'customer'){
		    		var viewroute = '/api/company/trade/message/view/'+uid;
		    	}else{
		    		var viewroute = '/api/company/communication/support-thread/'+uid+'?support_type='+support;
		    	}
		    	this.msg_loading_progress = 30;
		  		axios.get(viewroute, {
								headers: {'Authorization' : 'Bearer '+this.user.api_token}
							}).then(response => {
								console.log(response.data);
		  			this.thread_messages = response.data;

		    	this.msg_loading_progress = 80;
		  			let self = this;
					setTimeout(function(){
		  					var container = document.querySelector('#containerElement .simplebar-content-wrapper');
							var containerHeight = document.querySelector('#containerElement .simplebar-content-wrapper .simplebar-content');
							container.scrollTo({ top: (containerHeight.offsetHeight), behavior: "smooth" });
							self.loadingMessages = false;
							self.msg_loading_progress = 100;
					}, 500);

		  		}).catch(error => {
		  			return error;
		  		});
		  	},
		  	updateTicketContactOptions(uid, support_type){
		  		axios.post('/api/company/communication/support-thread/'+uid+'/update-status', {contacts: this.getActiveThread.contactoptions, support_type: support_type}, {headers : {'Authorization' : 'Bearer '+this.user.api_token}}).then(response => {
		  			this.success_messagetext = "Ticket Status Updated";
		  			this.success_message = true;
		  		}).catch(error => {
		  			this.error_reponse = [{error: ["Ticket status could not be updated. Please try again. If this problem persists, please contact Gemesys for support support@gemesys.co.uk"]}];
		  			this.error_message = true;
		  		});
		  	},
		  	updateTicketStatus(uid, support_type){
		  		axios.post('/api/company/communication/support-thread/'+uid+'/update-status', {status: this.getActiveThread.status, support_type: support_type}, {headers : {'Authorization' : 'Bearer '+this.user.api_token}}).then(response => {
		  			this.success_messagetext = "Ticket Status Updated";
		  			this.success_message = true;
		  		}).catch(error => {
		  			this.error_reponse = [{error: ["Ticket status could not be updated. Please try again. If this problem persists, please contact Gemesys for support support@gemesys.co.uk"]}];
		  			this.error_message = true;
		  		});
		  	},
		  	assignUser(uid, support_type){
		  		axios.post('/api/company/communication/support-thread/'+uid+'/assign-user', {assigned_to_id: this.getActiveThread.assigned_to_id, support_type: support_type}, {headers : {'Authorization' : 'Bearer '+this.user.api_token}}).then(response => {

		  			this.success_messagetext = "Ticket Assigned to User";
		  			this.success_message = true;
		  			var userDetails = this.selected_user.companies[0];
		  			userDetails["user"] = this.selected_user;
		  			this.getActiveThread.assigned_to = userDetails;
		  			//this.getActiveThread.assigned_to["user"] = userDetails;
		  		}).catch(error => {
		  			this.error_reponse = [{error: ["Ticket status could not be updated. Please try again. If this problem persists, please contact Gemesys for support support@gemesys.co.uk"]}];
		  			this.error_message = true;
		  		});
		  	},
		  	userSelected(resource){
		  		this.getActiveThread.assigned_to_id = resource.companies[0].id;
		  		this.selected_user = resource;
		  	},
		  	async getConversationThread(uid, support){

		  		return await this.getConversation(uid, support);
		  	}
		  },
		  created(){
		  	this.cthread_uid = this.thread_uid;
		  	this.loadingMessages = true;
		  	var url = window.location.href;
    		if(url.search("support_type=") > 0){
              var support_source = url.substring(url.lastIndexOf("=") + 1);
              this.source = support_source;
              this.getConversationThread(this.cthread_uid, this.source);
              //console.log(cthread_uid);
              
        	}
        	console.log('chat.support.'+support_source+'.'+this.cthread_uid+'');
					
        	this.loadList();
        	//this.filterNotifications(this.cthread_source);
        	
        	this.echoChannel = window.Echo.channel('chat.support.'+support_source+'.'+this.cthread_uid+'');
        	this.echoChannel.listen('.App\\Events\\NewSupportMessage', (e) => {
							this.getConversationThread(this.cthread_uid, this.cthread_source);
				    		});
        	
        	
		  },
		  computed: {
		  		getActiveThread(cthread_uid){
		  			
		  			//this.active_thread = this.thread_list.filter(item => item.uid == this.cthread_uid)[0];
		  				var list = this.thread_list.filter(item => item.uid == this.cthread_uid);
		  				
		  				if(list.length > 0){
								return list[0];
							}else{
								return '';
							}
		  		
		  	},
		  },
		  watch: {
		  	$route() {
			  		this.$nextTick(() => {
								if(window.location.search == ''){
									this.active_thread = '';
								}

						});
		  	}
		  }
	}
</script>

<template>
	<div>
	<b-row>
		<b-col :md="location == 'home' ? 4 : 3" class="mt-4" v-if="order_source == ''">
			<b-card class="border-radius-1em border shadow-none">
		 <div class="chat-leftsidebar-nav">
				<b-tabs pills fill content-class="py-4">
            <b-tab title="Tab 1" @click="filterNotifications('')" :active="source == ''" >
              <template v-slot:title>
                <i class="bx bx-chat font-size-20 d-sm-none"></i>
                <span class="d-none d-sm-block">All</span>
              </template>
              <b-card-text>
              <b-card class="border-radius-1em border shadow-none" body-class="p-0">
                  <simplebar style="max-height: 610px" id="my-element">
                    <ul class="list-unstyled chat-list">
                      <li class="active" v-for="chat in thread_list" :style="chat.uid == getActiveThread.uid ? 'border-left: 5px solid;' : ''" :class="{'border-peach' : (chat.uid == getActiveThread.uid) && (chat.source == 'website'), 'border-primary' : (chat.uid == getActiveThread.uid) && (chat.source == 'public'), 'border-blue' : (chat.uid == getActiveThread.uid) && (chat.source == 'trade'), 'border-bottom' : chat.uid !== getActiveThread.uid}">
                        <a href="javascript:;"  @click="uncollapseThread(chat)" :class="{'bg-light unread' : chat.read_at == null, 'bg-light bg-soft' : chat.uid == getActiveThread.uid}">
                          <div class="media position-relative">
                            <div class="align-self-center mx-1">
                              <i v-if="chat.read_at == null" class="bx bx-chat font-size-10 position-absolute text-white font-size-12 bg-danger p-1 rounded-circle top-0 left-0 m-1"></i>
                            </div>

                            <div class="align-self-center me-3 avatar-sm my-3"  v-if="chat.customer.avatar == null">
                              <span class="avatar-title rounded-circle" :class="{'bg-blue' : chat.source == 'trade', 'bg-peach' : chat.source == 'website'}">{{chat.customer.name.slice(0,1)}}</span>
                            </div>
                            <div class="align-self-center me-3"  v-else>
                              <img
                                src="/images/users/avatar-2.jpg"
                                class="rounded-circle avatar-xs"
                                alt=""
                              />
                            </div>

                            <div class="media-body overflow-hidden my-auto">
                              <h6 class="text-truncate mb-0">
                                {{chat.customer.name}} {{chat.customer.lastname}}
                              </h6>
                              <p class="text-truncate mb-0">
                                {{chat.subject}}
                              </p>
                            </div>
                          <div class="font-size-11 position-absolute top-0 right-0">{{chat.updated_at | luxon({input: 'server', output: 'med'})}}</div>
                               <span v-if="chat.source !== ''" class="position-absolute bottom-0 right-0 mt-2 my-auto ml-2 badge font-size-11 text-capitalize" :class="{'bg-peach text-dark' : chat.source == 'website', 'bg-primary text-light' : chat.source == 'public', 'bg-blue' : chat.source == 'trade'}" v-b-tooltip.hover :title="chat.source+' enquiry'" >{{chat.source}}</span>
                      <span v-else-if="chat.source == ''" class="my-auto ml-2 badge badge-light bg-light my-auto font-size-11">
                        No Source
                    </span>
                          </div>
                        </a>
                      </li>
                    </ul>
              	  <div  v-if="thread_list.length == 0">
	            		<div class="bg-light justify-content-center align-items-center d-flex w-100 p-4 flex-column text-center border-radius-1em">
	           			 	<i class="mdi mdi-inbox-outline font-size-48 text-primary mt-3">
	            			</i>
	            			<h5 class="mb-3">No Enquiries to Show</h5>
	            		</div>
        					</div>
                  </simplebar>
                </b-card>

              </b-card-text>
            </b-tab>
            <b-tab title="Tab 2" @click="filterNotifications('website')"  :active="source == 'website'">
              <template v-slot:title>
                <i class="bx bx-chat font-size-20 d-sm-none"></i>
                <span class="d-none d-sm-block">Website</span>
              </template>

              <b-card class="border-radius-1em border shadow-none" body-class="p-0">
               <simplebar style="max-height: 610px" id="my-element">
                    <ul class="list-unstyled chat-list">
                      <li v-for="chat in thread_list" :style="(chat.uid == getActiveThread.uid ? 'border-left: 5px solid #5E3BB0;' : '')"  class="active border-bottom" :class="{'bg-light unread' : chat.read_at == null, 'bg-light bg-soft' : chat.uid == getActiveThread.uid}">
                        <a href="javascript:;"  @click="uncollapseThread(chat)">
                          <div class="media position-relative">
                            <div class="align-self-center mx-1">
                              <i v-if="chat.read_at == null" class="bx bx-chat font-size-10 position-absolute text-white font-size-12 bg-danger p-1 rounded-circle top-0 left-0 m-1"></i>
                            </div>

                            <div class="align-self-center me-3 avatar-sm my-3"  v-if="chat.customer.avatar == null">
                              <span class="avatar-title rounded-circle bg-peach">{{chat.customer.name.slice(0,1)}}</span>
                            </div>
                            <div class="align-self-center me-3"  v-else>
                              <img
                                src="/images/users/avatar-2.jpg"
                                class="rounded-circle avatar-xs"
                                alt=""
                              />
                            </div>

                            <div class="media-body overflow-hidden my-auto">
                              <h6 class="text-truncate mb-0">
                                {{chat.customer.name}} {{chat.customer.lastname}}
                              </h6>
                              <p class="text-truncate mb-0">
                                {{chat.subject}}
                              </p>
                            </div>
                            <div class="font-size-11 position-absolute top-0 right-0">{{chat.updated_at | luxon({input: 'server', output: 'med'})}}</div>
                               <span v-if="chat.source !== ''" class="position-absolute bottom-0 right-0 mt-2 my-auto ml-2 badge font-size-11 text-capitalize" :class="{'bg-peach text-dark' : chat.source == 'website', 'bg-primary text-light' : chat.source == 'public', 'bg-blue' : chat.source == 'trade'}" v-b-tooltip.hover :title="chat.source+' enquiry'" >{{chat.source}}</span>
                      <span v-else-if="chat.source == ''" class="my-auto ml-2 badge badge-light bg-light my-auto font-size-11">
                        No Source
                    </span>
                          </div>
                        </a>
                      </li>
                    </ul>
              	  <div  v-if="thread_list.length == 0">
	            		<div class="bg-light justify-content-center align-items-center d-flex w-100 p-4 flex-column text-center border-radius-1em">
	           			 	<i class="mdi mdi-inbox-outline font-size-48 text-primary mt-3">
	            			</i>
	            			<h5 class="mb-3">No Enquiries to Show</h5>
	            		</div>
        					</div>
                  </simplebar>
                </b-card>

            </b-tab>
            <b-tab title="Tab 3" @click="filterNotifications('public')"  :active="source == 'public'">
              <template v-slot:title>
                <i class="bx bx-chat font-size-20 d-sm-none"></i>
                <span class="d-none d-sm-block">Public</span>
              </template>

              <b-card class="border-radius-1em border shadow-none" body-class="p-0">
               <simplebar style="max-height: 610px" id="my-element">
                    <ul class="list-unstyled chat-list">
                      <li class="active border-bottom" v-for="chat in thread_list" :style="(chat.uid == getActiveThread.uid ? 'border-left: 5px solid #5E3BB0;' : '')">
                        <a href="javascript:;"  @click="uncollapseThread(chat)"  :class="{'bg-light unread' : chat.read_at == null, 'bg-light bg-soft' : chat.uid == getActiveThread.uid}">
                          <div class="media position-relative">
                            <div class="align-self-center mx-1">
                              <i v-if="chat.read_at == null" class="bx bx-chat font-size-10 position-absolute text-white font-size-12 bg-danger p-1 rounded-circle top-0 left-0 m-1"></i>
                            </div>

                            <div class="align-self-center me-3 avatar-sm my-3"  v-if="chat.customer.avatar == null">
                              <span class="avatar-title rounded-circle">{{chat.customer.name.slice(0,1)}}</span>
                            </div>
                            <div class="align-self-center me-3"  v-else>
                              <img
                                src="/images/users/avatar-2.jpg"
                                class="rounded-circle avatar-xs"
                                alt=""
                              />
                            </div>

                            <div class="media-body overflow-hidden my-auto">
                              <h6 class="text-truncate mb-0">
                                {{chat.customer.name}} {{chat.customer.lastname}}
                              </h6>
                              <p class="text-truncate mb-0">
                                {{chat.subject}}
                              </p>
                            </div>
                           <div class="font-size-11 position-absolute top-0 right-0">{{chat.updated_at | luxon({input: 'server', output: 'med'})}}</div>
                               <span v-if="chat.source !== ''" class="position-absolute bottom-0 right-0 mt-2 my-auto ml-2 badge font-size-11 text-capitalize" :class="{'bg-peach text-dark' : chat.source == 'website', 'bg-primary text-light' : chat.source == 'public', 'bg-blue' : chat.source == 'trade'}" v-b-tooltip.hover :title="chat.source+' enquiry'" >{{chat.source}}</span>
                      <span v-else-if="chat.source == ''" class="my-auto ml-2 badge badge-light bg-light my-auto font-size-11">
                        No Source
                    </span>
                          </div>
                        </a>
                      </li>
                    </ul>
              	  <div  v-if="thread_list.length == 0">
	            		<div class="bg-light justify-content-center align-items-center d-flex w-100 p-4 flex-column text-center border-radius-1em">
	           			 	<i class="mdi mdi-inbox-outline font-size-48 text-primary mt-3">
	            			</i>
	            			<h5 class="mb-3">No Enquiries to Show</h5>
	            		</div>
        					</div>
                  </simplebar>
                </b-card>

            </b-tab>
            <b-tab title="Tab 4" @click="filterNotifications('trade')"  :active="source == 'trade'">
              <template v-slot:title>
                <i class="bx bx-chat font-size-20 d-sm-none"></i>
                <span class="d-none d-sm-block">Trade</span>
              </template>

              <b-card class="border-radius-1em border shadow-none" body-class="p-0">
               <simplebar style="max-height: 610px" id="my-element">
                    <ul class="list-unstyled chat-list">
                      <li class="active border-bottom" v-for="chat in thread_list" :style="(chat.uid == getActiveThread.uid ? 'border-left: 5px solid #5E3BB0;' : '')" >
                        <a href="javascript:;"  @click="uncollapseThread(chat)" class="d-flex flex-column" :class="{'bg-light unread' : chat.read_at == null, 'bg-light bg-soft' : chat.uid == getActiveThread.uid}">
                          <div class="media position-relative">
                            <div class="align-self-center mx-1">
                              <i v-if="chat.responded == null" class="bx bx-chat font-size-10 position-absolute text-white font-size-12 bg-danger p-1 rounded-circle top-0 left-0 m-1"></i>
                            </div>

                            <div class="align-self-center me-3 avatar-sm my-3"  v-if="chat.customer.avatar == null">
                              <span class="avatar-title rounded-circle bg-blue">{{chat.customer.name.slice(0,1)}}</span>
                            </div>
                            <div class="align-self-center me-3"  v-else>
                              <img
                                src="/images/users/avatar-2.jpg"
                                class="rounded-circle avatar-xs"
                                alt=""
                              />
                            </div>

                            <div class="media-body overflow-hidden my-auto">
                              <h6 class="text-truncate mb-0">
                                {{chat.customer.name}} {{chat.customer.lastname}}
                              </h6>
                              <p class="text-truncate mb-0">
                                {{chat.subject}}
                              </p>
                            </div>
                             <div class="font-size-11 position-absolute top-0 right-0">{{chat.updated_at | luxon({input: 'server', output: 'med'})}}</div>
                               <span v-if="chat.source !== ''" class="position-absolute bottom-0 right-0 mt-2 my-auto ml-2 badge font-size-11 text-capitalize" :class="{'bg-peach text-dark' : chat.source == 'website', 'bg-primary text-light' : chat.source == 'public', 'bg-blue' : chat.source == 'trade'}" v-b-tooltip.hover :title="chat.source+' enquiry'" >{{chat.source}}</span>
                      <span v-else-if="chat.source == ''" class="my-auto ml-2 badge badge-light bg-light my-auto font-size-11">
                        No Source
                    </span>
                          </div>
                         

                        </a>
                      </li>
                    </ul>
              	  <div  v-if="thread_list.length == 0">
	            		<div class="bg-light justify-content-center align-items-center d-flex w-100 p-4 flex-column text-center border-radius-1em">
	           			 	<i class="mdi mdi-inbox-outline font-size-48 text-primary mt-3">
	            			</i>
	            			<h5 class="mb-3">No Enquiries to Show</h5>
	            		</div>
        					</div>
                  </simplebar>
                </b-card>

            </b-tab>
          </b-tabs>
        </div>
         
        <a class="btn btn-primary btn-block btn-rounded">
        	Start New Conversation
        </a>
    </b-card>
        <b-card v-if="getActiveThread !== ''" class="border-radius-1em border shadow-none">
                          	<b-row>
                          		<b-col md="12" class="mb-2">
                          		<div class=" ml-auto" v-if="getActiveThread.assigned_to == null">
                          		<label>Assign To User</label>
                          		<SearchCompanyUser :company="$attrs.company" :api_token="user.api_token" :id="getActiveThread.assigned_to_id" @user-selected="userSelected"></SearchCompanyUser>
                          		</div>
                          		<div class=" ml-auto" v-else>
                          		<label>Assign To User</label>
                          		<SearchCompanyUser :company="$attrs.company" :api_token="user.api_token" :id="getActiveThread.assigned_to.user" @user-selected="userSelected"></SearchCompanyUser>
                          		</div>
                          		<a  @click="assignUser(getActiveThread.uid, getActiveThread.source)" class="btn btn-light btn-rounded btn-block mt-2">
                          			Update
                          		</a>
                          	</b-col>
                          	<b-col md="12" class="mb-2">
                          		<div class="d-flex ml-auto w-100">
                          			<b-form-group label="Update Status" class="my-auto w-100">
                          			<b-form-select class="form-control" v-model="getActiveThread.status" :options="supportStatuses">
                          				
                          			</b-form-select>
                          			</b-form-group>
                          			
                          		</div>
                          		<a class="btn btn-light btn-rounded btn-block mt-2" @click="updateTicketStatus(getActiveThread.uid, getActiveThread.source)">
                          				Update
                          			</a>
                          </b-col>
                          <b-col md="12" class="mb-2">
                          		<div class="d-flex">
                          			<b-form-group label="Contact Options">
                          				<b-form-checkbox-group button-variant="light" class="bg-light border-radius-1em py-2">

                          					<b-form-checkbox v-for="item in contactOptions" :value="item.value">
                          					<i :class="item.icon"></i>
                          					{{item.text}}</b-form-checkbox>
                          				</b-form-checkbox-group>


                          			</b-form-group>
                          		</div>
                         </b-col>
                         <b-col md="3">
                         			
                          		</b-col>

                          	</b-row>
                          	</b-card>
    </b-col>
    <b-col md="12" v-else-if="getActiveThread == ''">
    			<b-card class="border-radius-1em border shadow-none">
		 <div class="chat-leftsidebar-nav">
              <b-card-text>
              <b-card class="border-radius-1em border shadow-none" body-class="p-0">
                  <simplebar style="max-height: 610px" id="my-element">
                    <ul class="list-unstyled chat-list">
                      <li class="active" v-for="chat in thread_list" :style="chat.uid == getActiveThread.uid ? 'border-left: 5px solid;' : ''" :class="{'border-peach' : (chat.uid == getActiveThread.uid) && (chat.source == 'website'), 'border-primary' : (chat.uid == getActiveThread.uid) && (chat.source == 'public'), 'border-blue' : (chat.uid == getActiveThread.uid) && (chat.source == 'trade'), 'border-bottom' : chat.uid !== getActiveThread.uid}">
                        <a href="javascript:;"  @click="uncollapseThread(chat)" :class="{'bg-light unread' : chat.read_at == null, 'bg-light bg-soft' : chat.uid == getActiveThread.uid}">
                          <div class="media position-relative">
                            <div class="align-self-center mx-1">
                              <i v-if="chat.read_at == null" class="bx bx-chat font-size-10 position-absolute text-white font-size-12 bg-danger p-1 rounded-circle top-0 left-0 m-1"></i>
                            </div>

                            <div class="align-self-center me-3 avatar-sm my-3"  v-if="chat.customer.avatar == null">
                              <span class="avatar-title rounded-circle" :class="{'bg-blue' : chat.source == 'trade', 'bg-peach' : chat.source == 'website'}">{{chat.customer.name.slice(0,1)}}</span>
                            </div>
                            <div class="align-self-center me-3"  v-else>
                              <img
                                src="/images/users/avatar-2.jpg"
                                class="rounded-circle avatar-xs"
                                alt=""
                              />
                            </div>

                            <div class="media-body overflow-hidden my-auto">
                              <h6 class="text-truncate mb-0">
                                {{chat.customer.name}} {{chat.customer.lastname}}
                              </h6>
                              <p class="text-truncate mb-0">
                                {{chat.subject}}
                              </p>
                            </div>
                          <div class="font-size-11 position-absolute top-0 right-0">{{chat.updated_at | luxon({input: 'server', output: 'med'})}}</div>
                               <span v-if="chat.source !== ''" class="position-absolute bottom-0 right-0 mt-2 my-auto ml-2 badge font-size-11 text-capitalize" :class="{'bg-peach text-dark' : chat.source == 'website', 'bg-primary text-light' : chat.source == 'public', 'bg-blue' : chat.source == 'trade'}" v-b-tooltip.hover :title="chat.source+' enquiry'" >{{chat.source}}</span>
                      <span v-else-if="chat.source == ''" class="my-auto ml-2 badge badge-light bg-light my-auto font-size-11">
                        No Source
                    </span>
                          </div>
                        </a>
                      </li>
                    </ul>
              	  <div  v-if="thread_list.length == 0">
	            		<div class="bg-light justify-content-center align-items-center d-flex w-100 p-4 flex-column text-center border-radius-1em">
	           			 	<i class="mdi mdi-inbox-outline font-size-48 text-primary mt-3">
	            			</i>
	            			<h5 class="mb-3">No Enquiries to Show</h5>
	            		</div>
        					</div>
                  </simplebar>
                </b-card>

              </b-card-text>
           </div>
         </b-card>
    </b-col>
		<b-col :md="location == 'home' ? 8 : 9" class="mt-4" v-if="order_source == ''">
				<b-list-group class="list-group product-list w-100 no-shadow" v-if="(thread_list.length !== 0) && (view == 'list')">
                            
                          <b-list-group-item v-if="getActiveThread !== ''">
                          	<b-overlay :show="loadingMessages">
                          		<template #overlay>
                          				<b-row>
			      			<b-col md="12" class="offset-md-2" >
				      			<b-card class="border-radius-1em border shadow-none mb-0">	
						            <div class="cube-wrapper mx-auto"><div class="cube-folding"><span class="leaf1"></span><span class="leaf2"></span><span class="leaf3"></span><span class="leaf4"></span></div></div>
						            <h6 class="text-center">Loading Message</h6>
						             <b-progress class="w-50 mx-auto" v-model="msg_loading_progress" variant="primary"></b-progress>
				         		</b-card>
			      			</b-col>
			      			</b-row>
                          		</template>
                          	<b-card class="border-radius-1em border shadow-none" >
                  
                          		<h5 class="mr-auto mb-3">Support Details</h5>
                          		<b-row>
                          			

                          			
                          			<b-col>
                          		<b-list-group flush>
                          			<b-list-group-item>
		                          		<div class="d-flex">
		                          			Ticket ID <span class="badge badge-light bg-light font-size-14 ml-auto text-capitalize my-auto">#{{getActiveThread.uid}}</span>
		                          		</div>
                          			</b-list-group-item>
                          			<b-list-group-item>
                          			<div class="d-flex">
                          				Ticket Assigned To 
                          				<a v-if="getActiveThread.assigned_to == null" class="badge badge-light bg-light font-size-14 ml-auto">-- No User Assigned --</a>
                          				<span v-else-if="(getActiveThread.assigned_to !== null)" class="badge badge-light bg-light font-size-14 ml-auto"><i class="bx bx-user text-primary"></i> {{getActiveThread.assigned_to.user.name}} {{getActiveThread.assigned_to.user.lastname}}</span>

                          				
                          			</div>
                          		</b-list-group-item>
                          		<b-list-group-item>
                          			<div class="d-flex">
                          				Customer Name <span class="badge badge-light bg-light font-size-14 ml-auto text-capitalize"><i class="bx bx-user text-primary"></i> {{getActiveThread.customer.name}} {{getActiveThread.customer.lastname}}</span>
                          			</div>
                          		</b-list-group-item>
                          		<b-list-group-item>
                          			<div class="d-flex">
                          				Customer Phone <span class="badge badge-light bg-light font-size-14 ml-auto text-capitalize">{{getActiveThread.phone}}</span>
                          			</div>
                          		</b-list-group-item>
                          		<b-list-group-item>
                          			<div class="d-flex">
                          				Customer Email <span v-if="getActiveThread.email == ''" class="badge badge-light bg-light font-size-14 ml-auto"><i class="bx bx-mail-send text-primary"></i> {{getActiveThread.customer.email}}</span>
                          				<span v-else class="badge badge-light bg-light font-size-14 ml-auto"><i class="bx bx-mail-send text-primary"></i> {{getActiveThread.email}}</span>
                          			</div>
                          		</b-list-group-item>

                          		

                          	</b-list-group>
                          </b-col>
                          <b-col>
                          	<b-list-group flush>
                          			<b-list-group-item>
		                          		<div class="d-flex">
		                          			Support Type <span class="badge badge-light bg-light font-size-14 ml-auto text-capitalize">{{getActiveThread.support_type}}</span>
		                          		</div>
                          			</b-list-group-item>
                          		<b-list-group-item>
                          			<div class="d-flex">
                          				Source <span class="badge badge-light bg-light font-size-14 ml-auto text-capitalize">{{getActiveThread.source}}</span>
                          			</div>
                          		</b-list-group-item>
                          		<b-list-group-item>
                          			<div class="d-flex">
                          				Status <span class="badge badge-light bg-light font-size-14 ml-auto text-capitalize" v-if="getActiveThread.status !== null">{{supportStatuses.filter(item => item.value == getActiveThread.status)[0].text}}</span>
                          			</div>
                          		</b-list-group-item>
                          		<b-list-group-item>
									<a v-if="getActiveThread.order !== null" :href="'/order/details/'+getActiveThread.order.company_order[0].company_order.uid" class="btn btn-light btn-block btn-rounded" target="_blank">
                          				Show Order <i class="bx bx-right-arrow-alt"></i>
                          			</a>
                          			<div class="d-flex mt-2">
                          		
                          			<a class="btn my-auto btn-light btn-rounded btn-block mr-2" @click="markComplete(getActiveThread)">
                          				Resolved <i class="bx bx-check-circle text-success"></i>
                          			</a>

                          			<a class="btn my-auto btn-light btn-rounded btn-block mr-2">
                          				C(losed <i class="bx bx-x-circle text-danger"></i>
                          			</a>
                          			 <b-dropdown variant="light" class="btn-light btn-rounded" v.b.v-b-tooltip.hover.left title="More Actions">
    																<template #button-content>
                          	
                          				<i class="bx bx-dots-vertical-rounded">
                          				</i>
                          	
                          		</template>
                          		<b-dropdown-item class="px-2" @click="markInDispute(getActiveThread)">
                          			Mark In Dispite
                          		</b-dropdown-item>
                          		<b-dropdown-item class="px-2" @click="markArchived(getActiveThread)">
                          			Mark Archived
                          		</b-dropdown-item>
                          		<b-dropdown-item class="px-2" @click="destroyTicket()">
                          			Delete Ticket
                          		</b-dropdown-item>
                          	</b-dropdown>
                          		</div>
                          		</b-list-group-item>
                          		
                          	</b-list-group>

                          	
                          </b-col>

                          </b-row>
                          	</b-card>
							
                          	<b-card class="border-radius-1em border shadow-none" body-class="p-0">	
                          		<b-row class="my-3">
                          			<hr style="margin-top: 25px; position: absolute; left: 70px; width: 85%;">
                          			<b-col v-for="status in supportStatuses" class="text-center" :class="{'active' : getActiveThread.status > status.value, 'current' : getActiveThread.status == status.value}">
                          				<div class="d-flex flex-column m-auto justify-content-center align-items-center border rounded-circle" style="width: 50px; height: 50px;" :class="{'bg-success text-white' : (getActiveThread.status == 6) || (getActiveThread.status > status.value), 'bg-primary text-white' : getActiveThread.status == status.value, 'bg-light' : getActiveThread.status < status.value}">
                          					<span class="font-size-20">{{status.value}}</span>
                          				</div>
                          				<p class="mb-0">{{status.text}}</p>
                          			</b-col>
                          		</b-row>
                          	</b-card>
                          	<b-card class="border-radius-1em border shadow-none" body-class="p-0">	
                          		<b-row class="p-3">
                          			<b-col md="8">
                          		<!--<span class="text-capitalize">From: {{getActiveThread.customer.name}} {{getActiveThread.customer.lastname}}</span>-->
                          		<span class="text-muted italic no-margin my-auto ml-auto mr-auto"><i class="bx bx-calendar text-primary font-size-14"></i> {{getActiveThread.created_at | luxon({input: 'server'})}}</span>
                          		<span>
                          			{{getSupportType(getActiveThread.support_type)}}
                          		</span>
                          		<h4 class="m-0">
                          			{{getActiveThread.subject}}
                          		</h4>

                          		<!--<div class="messages-content bg-light border-radius-1em p-3 mb-3" :class="{'text-right' : message.from == 'company'}" v-for="message in getActiveThread">
                          			<h6 class="text-capitalize">From: {{message.from}}</h6>
                          			{{message.notification.data.message}}
                          		</div>-->
                          	</b-col>
                          	<b-col md="4">
                          	</b-col>
                          </b-row>
                          		<div class="chat-conversation p-3">
									              <simplebar
									                style="max-height: 470px"
									                id="containerElement"
									                ref="current"
									              >
									                <ul class="list-unstyled">
									                  <li>
									                    <div class="chat-day-title">
									                      <span class="title">Conversation Started: {{getActiveThread.created_at | luxon({input: 'server'})}}</span>
									                    </div>
									                  </li>
									                  <!--<li  :class="{'right' : getActiveThread.from == 'customer' && userview == 'customer'}" >
									                    <div class="conversation-list">
									                     
									                      <div class="ctext-wrap">
									                        <div class="conversation-name">
									                        	<span v-if="getActiveThread.from == 'company'">
									                        		<span v-if="userview == 'company'">
									                        			You
									                        		</span>
									                        		<span v-if="userview == 'customer'">
									                        		Company
									                        		</span>
									                        	</span>
									                        	<span v-if="getActiveThread.from == 'customer'">
									                        		<span v-if="userview == 'company'">
									                        			Customer
									                        		</span>
									                        		<span v-if="userview == 'customer'">
									                        			You
									                        		</span>
									                        	</span>
									                        </div>
									                        <p>{{getActiveThread.message}}</p>
									                        <p class="chat-time mb-0">
									                          <i class="bx bx-time-five align-middle me-1"></i>
									                          {{getActiveThread.created_at | luxon({input: 'server', output: 'time'})}}
									                        </p>
									                      </div>
									                    </div>
									                  </li>-->
									                  <li v-for="message in thread_messages" :class="{'right' : message.attributes.from == userview}" >
									                    <div class="conversation-list">
									                     <!--<b-dropdown
									                        variant="white"
									                        menu-class="dropdown-menu-end"
									                      >
									                        <template v-slot:button-content>
									                          <i class="bx bx-dots-vertical-rounded"></i>
									                        </template>
									                        <b-dropdown-item>Copy</b-dropdown-item>
									                        <b-dropdown-item>Save</b-dropdown-item>
									                        <b-dropdown-item>Forward</b-dropdown-item>
									                        <b-dropdown-item>Delete</b-dropdown-item>
									                      </b-dropdown>-->
									                      <div class="ctext-wrap">
									                        <div class="conversation-name">
									                        	<span v-if="message.attributes.from == 'company'">
									                        		<span v-if="userview == 'company'">
									                        			You
									                        		</span>
									                        		<span v-if="userview == 'customer'">
									                        		Company
									                        		</span>
									                        	</span>
									                        	<span v-if="message.attributes.from == 'customer'">
									                        		<span v-if="userview == 'company'">
									                        			Customer
									                        		</span>
									                        		<span v-if="userview == 'customer'">
									                        			You
									                        		</span>
									                        	</span>
									                        </div>
									                        <p>{{message.body}}</p>
									                        <p class="chat-time mb-0">
									                          <i class="bx bx-time-five align-middle me-1"></i>
									                          {{message.created_at.date | luxon({output: 'time'})}}
									                        </p>
									                      </div>
									                    </div>
									                  </li>
									                </ul>
									              </simplebar>
									            </div>
									            <div class="p-3 chat-input-section">
									            	  <b-row class="position-absolute right-0 top-0 mr-2 mt-3">
									            	  	<transition name="fade-top">
											              	<b-col v-if="success_message == true">
											              		<b-alert variant="success" dismissible @dismissed="success_message = !success_message" :show="true">{{success_messagetext}}</b-alert>
											              	</b-col>
									              	</transition>
									              	<transition name="fade-top">
											              	<b-col v-if="error_reponse.length > 0">
											              		<b-alert variant="danger" dismissible @dismissed="success_message = !success_message" :show="true">

											              			<ul>
											                      <li v-for="error in error_reponse">{{error[0]}}</li>
											                    </ul>
											              		</b-alert>
											              	</b-col>
									              	</transition>
									              </b-row>
              <div class="row">
                <div class="col">
                  <div class="position-relative">
                    <!--<input
                      type="text"
                      class="form-control chat-input"
                      placeholder="Enter Message..."
                    />-->
                    <textarea
                      type="text"
                      class="form-control chat-input"
                      placeholder="Enter Message..."
                      v-model="data.message"
                    ></textarea>
                    <div class="chat-input-links">
                      <ul class="list-inline mb-0">
                        <li class="list-inline-item">
                          <a
                            href="#"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Emoji"
                            ><i class="mdi mdi-emoticon-happy-outline"></i
                          ></a>
                        </li>
                        <li class="list-inline-item">
                          <a
                            href="#"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Images"
                            ><i class="mdi mdi-file-image-outline"></i
                          ></a>
                        </li>
                        <li class="list-inline-item">
                          <a
                            href="#"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Add Files"
                            ><i class="mdi mdi-file-document-outline"></i
                          ></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="col-auto">
                  <button v-if="getActiveThread.status !== 'closed'"
                    type="submit"
                    class="btn btn-primary btn-rounded chat-send w-md waves-effect waves-light"
                    @click="sendReply(getActiveThread)"
                  >
                    <span class="d-none d-sm-inline-block me-2">Send</span>
                    <i class="mdi mdi-send"></i>
                  </button>
                </div>
              </div>
            </div>
                          	</b-card>
                          		</b-overlay>
                          </b-list-group-item>
                          <div v-else class="p-3">
	            <div class="bg-light justify-content-center align-items-center d-flex w-100 p-4 flex-column text-center border-radius-1em">
	            	<i class="mdi mdi-inbox-outline font-size-48 text-primary mt-3">
	            	</i>
	            	<h5 class="mb-3">Select a Message to Start</h5>
	            </div>
        	</div>
                              <!--<li class="list-group-item py-4 text-center" ><a class="btn btn-outline-light btn-rounded">Load More</a>
                              </li>-->
           </b-list-group>
           <div  v-if="thread_list.length == 0" class="p-3">
	            <div class="bg-light justify-content-center align-items-center d-flex w-100 p-4 flex-column text-center border-radius-1em">
	            	<i class="mdi mdi-inbox-outline font-size-48 text-primary mt-3">
	            	</i>
	            	<h5 class="mb-3">No Enquiries to Show</h5>
	            </div>
        	</div>
               
		</b-col>

		<b-col :md="12" class="mt-4" v-if="order_source !== ''">
				<b-list-group class="list-group product-list w-100 no-shadow" v-if="(thread_list.length !== 0) && (view == 'list')">
                            
                          <b-list-group-item v-if="getActiveThread !== ''">
                          	<b-overlay :show="loadingMessages">
                          		<template #overlay>
                          				<b-row>
			      			<b-col md="12" class="offset-md-2" >
				      			<b-card class="border-radius-1em border shadow-none mb-0">	
						            <div class="cube-wrapper mx-auto"><div class="cube-folding"><span class="leaf1"></span><span class="leaf2"></span><span class="leaf3"></span><span class="leaf4"></span></div></div>
						            <h6 class="text-center">Loading Message</h6>
						             <b-progress class="w-50 mx-auto" v-model="msg_loading_progress" variant="primary"></b-progress>
				         		</b-card>
			      			</b-col>
			      			</b-row>
                          		</template>
                          		<div class="d-flex mb-2">
                          			<a class="btn btn-outline-light btn-rounded" @click="cthread_uid = ''" style="width: 100px">
                          				<i class="bx bx-left-arrow-alt font-size-20 text-primary">
                          				</i>
                          			</a>
                          		</div>
                          	<b-card class="border-radius-1em border shadow-none" >
                  
                          		<h5 class="mr-auto mb-3">Support Details</h5>
                          		<b-row>
                          			

                          			
                          			<b-col>
                          		<b-list-group flush>
                          			<b-list-group-item>
		                          		<div class="d-flex">
		                          			Ticket ID <span class="badge badge-light bg-light font-size-14 ml-auto text-capitalize my-auto">#{{getActiveThread.uid}}</span>
		                          		</div>
                          			</b-list-group-item>
                          			<b-list-group-item>
                          			<div class="d-flex">
                          				Ticket Assigned To 
                          				<a v-if="getActiveThread.assigned_to == null" class="badge badge-light bg-light font-size-14 ml-auto">-- No User Assigned --</a>
                          				<span v-else-if="(getActiveThread.assigned_to !== null)" class="badge badge-light bg-light font-size-14 ml-auto"><i class="bx bx-user text-primary"></i> {{getActiveThread.assigned_to.user.name}} {{getActiveThread.assigned_to.user.lastname}}</span>

                          				
                          			</div>
                          		</b-list-group-item>
                          		<b-list-group-item>
                          			<div class="d-flex">
                          				Customer Name <span class="badge badge-light bg-light font-size-14 ml-auto text-capitalize"><i class="bx bx-user text-primary"></i> {{getActiveThread.customer.name}} {{getActiveThread.customer.lastname}}</span>
                          			</div>
                          		</b-list-group-item>
                          		<b-list-group-item>
                          			<div class="d-flex">
                          				Customer Phone <span class="badge badge-light bg-light font-size-14 ml-auto text-capitalize">{{getActiveThread.phone}}</span>
                          			</div>
                          		</b-list-group-item>
                          		<b-list-group-item>
                          			<div class="d-flex">
                          				Customer Email <span v-if="getActiveThread.email == ''" class="badge badge-light bg-light font-size-14 ml-auto"><i class="bx bx-mail-send text-primary"></i> {{getActiveThread.customer.email}}</span>
                          				<span v-else class="badge badge-light bg-light font-size-14 ml-auto"><i class="bx bx-mail-send text-primary"></i> {{getActiveThread.email}}</span>
                          			</div>
                          		</b-list-group-item>

                          		

                          	</b-list-group>
                          </b-col>
                          <b-col>
                          	<b-list-group flush>
                          			<b-list-group-item>
		                          		<div class="d-flex">
		                          			Support Type <span class="badge badge-light bg-light font-size-14 ml-auto text-capitalize">{{getActiveThread.support_type}}</span>
		                          		</div>
                          			</b-list-group-item>
                          		<b-list-group-item>
                          			<div class="d-flex">
                          				Source <span class="badge badge-light bg-light font-size-14 ml-auto text-capitalize">{{getActiveThread.source}}</span>
                          			</div>
                          		</b-list-group-item>
                          		<b-list-group-item>
                          			<div class="d-flex">
                          				Status <span class="badge badge-light bg-light font-size-14 ml-auto text-capitalize" v-if="getActiveThread.status !== null">{{supportStatuses.filter(item => item.value == getActiveThread.status)[0].text}}</span>
                          			</div>
                          		</b-list-group-item>
                          		<b-list-group-item>
									<a v-if="getActiveThread.order !== null" :href="'/order/details/'+company_order.uid" class="btn btn-light btn-block btn-rounded" target="_blank">
                          				Show Order <i class="bx bx-right-arrow-alt"></i>
                          			</a>
                          			<div class="d-flex mt-2">
                          		
                          			<a class="btn my-auto btn-light btn-rounded btn-block mr-2" @click="markComplete(getActiveThread)">
                          				Mark Resolved <i class="bx bx-check-circle text-success"></i>
                          			</a>

                          			<a class="btn my-auto btn-light btn-rounded btn-block mr-2">
                          				Mark Closed <i class="bx bx-x-circle text-danger"></i>
                          			</a>
                          			 <b-dropdown variant="light" class="btn-light btn-rounded" v.b.v-b-tooltip.hover.left title="More Actions">
    																<template #button-content>
                          	
                          				<i class="bx bx-dots-vertical-rounded">
                          				</i>
                          	
                          		</template>
                          		<b-dropdown-item class="px-2" @click="markInDispute(getActiveThread)">
                          			Mark In Dispite
                          		</b-dropdown-item>
                          		<b-dropdown-item class="px-2" @click="markArchived(getActiveThread)">
                          			Mark Archived
                          		</b-dropdown-item>
                          		<b-dropdown-item class="px-2" @click="destroyTicket()">
                          			Delete Ticket
                          		</b-dropdown-item>
                          	</b-dropdown>
                          		</div>
                          		</b-list-group-item>
                          		
                          	</b-list-group>

                          	
                          </b-col>

                          </b-row>
                          	</b-card>
							
                          	<b-card class="border-radius-1em border shadow-none" body-class="p-0">	
                          		<b-row class="my-3">
                          			<hr style="margin-top: 25px; position: absolute; left: 70px; width: 85%;">
                          			<b-col v-for="status in supportStatuses" class="text-center" :class="{'active' : getActiveThread.status > status.value, 'current' : getActiveThread.status == status.value}">
                          				<div class="d-flex flex-column m-auto justify-content-center align-items-center border rounded-circle" style="width: 50px; height: 50px;" :class="{'bg-success text-white' : (getActiveThread.status == 6) || (getActiveThread.status > status.value), 'bg-primary text-white' : getActiveThread.status == status.value, 'bg-light' : getActiveThread.status < status.value}">
                          					<span class="font-size-20">{{status.value}}</span>
                          				</div>
                          				<p class="mb-0">{{status.text}}</p>
                          			</b-col>
                          		</b-row>
                          	</b-card>
                          	<b-card class="border-radius-1em border shadow-none" body-class="p-0">	
                          		<b-row class="p-3">
                          			<b-col md="8">
                          		<!--<span class="text-capitalize">From: {{getActiveThread.customer.name}} {{getActiveThread.customer.lastname}}</span>-->
                          		<span class="text-muted italic no-margin my-auto ml-auto mr-auto"><i class="bx bx-calendar text-primary font-size-14"></i> {{getActiveThread.created_at | luxon({input: 'server'})}}</span>
                          		<span>
                          			{{getSupportType(getActiveThread.support_type)}}
                          		</span>
                          		<h4 class="m-0">
                          			{{getActiveThread.subject}}
                          		</h4>

                          		<!--<div class="messages-content bg-light border-radius-1em p-3 mb-3" :class="{'text-right' : message.from == 'company'}" v-for="message in getActiveThread">
                          			<h6 class="text-capitalize">From: {{message.from}}</h6>
                          			{{message.notification.data.message}}
                          		</div>-->
                          	</b-col>
                          	<b-col md="4">
                          	</b-col>
                          </b-row>
                          		<div class="chat-conversation p-3">
									              <simplebar
									                style="max-height: 470px"
									                id="containerElement"
									                ref="current"
									              >
									                <ul class="list-unstyled">
									                  <li>
									                    <div class="chat-day-title">
									                      <span class="title">Conversation Started: {{getActiveThread.created_at | luxon({input: 'server'})}}</span>
									                    </div>
									                  </li>
									                  <!--<li  :class="{'right' : getActiveThread.from == 'customer' && userview == 'customer'}" >
									                    <div class="conversation-list">
									                     
									                      <div class="ctext-wrap">
									                        <div class="conversation-name">
									                        	<span v-if="getActiveThread.from == 'company'">
									                        		<span v-if="userview == 'company'">
									                        			You
									                        		</span>
									                        		<span v-if="userview == 'customer'">
									                        		Company
									                        		</span>
									                        	</span>
									                        	<span v-if="getActiveThread.from == 'customer'">
									                        		<span v-if="userview == 'company'">
									                        			Customer
									                        		</span>
									                        		<span v-if="userview == 'customer'">
									                        			You
									                        		</span>
									                        	</span>
									                        </div>
									                        <p>{{getActiveThread.message}}</p>
									                        <p class="chat-time mb-0">
									                          <i class="bx bx-time-five align-middle me-1"></i>
									                          {{getActiveThread.created_at | luxon({input: 'server', output: 'time'})}}
									                        </p>
									                      </div>
									                    </div>
									                  </li>-->
									                  <li v-for="message in thread_messages" :class="{'right' : message.attributes.from == userview}" >
									                    <div class="conversation-list">
									                     <!--<b-dropdown
									                        variant="white"
									                        menu-class="dropdown-menu-end"
									                      >
									                        <template v-slot:button-content>
									                          <i class="bx bx-dots-vertical-rounded"></i>
									                        </template>
									                        <b-dropdown-item>Copy</b-dropdown-item>
									                        <b-dropdown-item>Save</b-dropdown-item>
									                        <b-dropdown-item>Forward</b-dropdown-item>
									                        <b-dropdown-item>Delete</b-dropdown-item>
									                      </b-dropdown>-->
									                      <div class="ctext-wrap">
									                        <div class="conversation-name">
									                        	<span v-if="message.attributes.from == 'company'">
									                        		<span v-if="userview == 'company'">
									                        			You
									                        		</span>
									                        		<span v-if="userview == 'customer'">
									                        		Company
									                        		</span>
									                        	</span>
									                        	<span v-if="message.attributes.from == 'customer'">
									                        		<span v-if="userview == 'company'">
									                        			Customer
									                        		</span>
									                        		<span v-if="userview == 'customer'">
									                        			You
									                        		</span>
									                        	</span>
									                        </div>
									                        <p>{{message.body}}</p>
									                        <p class="chat-time mb-0">
									                          <i class="bx bx-time-five align-middle me-1"></i>
									                          {{message.created_at.date | luxon({output: 'time'})}}
									                        </p>
									                      </div>
									                    </div>
									                  </li>
									                </ul>
									              </simplebar>
									            </div>
									            <div class="p-3 chat-input-section">
									            	  <b-row class="position-absolute right-0 top-0 mr-2 mt-3">
									            	  	<transition name="fade-top">
											              	<b-col v-if="success_message == true">
											              		<b-alert variant="success" dismissible @dismissed="success_message = !success_message" :show="true">{{success_messagetext}}</b-alert>
											              	</b-col>
									              	</transition>
									              	<transition name="fade-top">
											              	<b-col v-if="error_reponse.length > 0">
											              		<b-alert variant="danger" dismissible @dismissed="success_message = !success_message" :show="true">

											              			<ul>
											                      <li v-for="error in error_reponse">{{error[0]}}</li>
											                    </ul>
											              		</b-alert>
											              	</b-col>
									              	</transition>
									              </b-row>
              <div class="row">
                <div class="col">
                  <div class="position-relative">
                    <!--<input
                      type="text"
                      class="form-control chat-input"
                      placeholder="Enter Message..."
                    />-->
                    <textarea
                      type="text"
                      class="form-control chat-input"
                      placeholder="Enter Message..."
                      v-model="data.message"
                    ></textarea>
                    <div class="chat-input-links">
                      <ul class="list-inline mb-0">
                        <li class="list-inline-item">
                          <a
                            href="#"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Emoji"
                            ><i class="mdi mdi-emoticon-happy-outline"></i
                          ></a>
                        </li>
                        <li class="list-inline-item">
                          <a
                            href="#"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Images"
                            ><i class="mdi mdi-file-image-outline"></i
                          ></a>
                        </li>
                        <li class="list-inline-item">
                          <a
                            href="#"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Add Files"
                            ><i class="mdi mdi-file-document-outline"></i
                          ></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="col-auto">
                  <button v-if="getActiveThread.status !== 'closed'"
                    type="submit"
                    class="btn btn-primary btn-rounded chat-send w-md waves-effect waves-light"
                    @click="sendReply(getActiveThread)"
                  >
                    <span class="d-none d-sm-inline-block me-2">Send</span>
                    <i class="mdi mdi-send"></i>
                  </button>
                </div>
              </div>

            </div>
                          	</b-card>
                          	<b-row>
              	<b-col>
              		 <b-card v-if="order_source !== ''" class="border-radius-1em border shadow-none">
                          	<b-row>
                          		<b-col md="4" class="mb-2">
                          		<div class=" ml-auto" v-if="getActiveThread.assigned_to == null">
                          		<label>Assign To User</label>
                          		<SearchCompanyUser :company="$attrs.company" :api_token="user.api_token" :id="getActiveThread.assigned_to_id" @user-selected="userSelected"></SearchCompanyUser>
                          		</div>
                          		<div class=" ml-auto" v-else>
                          		<label>Assign To User</label>
                          		<SearchCompanyUser :company="$attrs.company" :api_token="user.api_token" :id="getActiveThread.assigned_to.user" @user-selected="userSelected"></SearchCompanyUser>
                          		</div>
                          		<a  @click="assignUser(getActiveThread.uid, getActiveThread.source)" class="btn btn-light btn-rounded btn-block mt-2">
                          			Update
                          		</a>
                          	</b-col>
                          	<b-col md="8" class="mb-2">
                          		<div class="d-flex ml-auto w-100 mb-2">
                          			<b-form-group label="Update Status" class="my-auto w-75">
                          			<b-form-select class="form-control" v-model="getActiveThread.status" :options="supportStatuses">
                          				
                          			</b-form-select>
                          			</b-form-group>
                          			<a class="btn btn-light btn-rounded mt-auto w-25 ml-2 w-25" @click="updateTicketStatus(getActiveThread.uid, getActiveThread.source)">
                          				Update
                          			</a>
                          		</div>
                          		
                          
                          		<div class="d-flex">
                          			<b-form-group label="Contact Options" class=" w-75">
                          				<b-form-checkbox-group button-variant="light" class="bg-light border-radius-1em py-2">

                          					<b-form-checkbox v-for="item in contactOptions" :value="item.value">
                          					<i :class="item.icon"></i>
                          					{{item.text}}</b-form-checkbox>
                          				</b-form-checkbox-group>


                          			</b-form-group>
                          			<a class="btn btn-light btn-rounded mt-auto w-25 ml-2 w-25" @click="updateTicketContactOptions(getActiveThread.uid, getActiveThread.source)">
                          				Update
                          			</a>
                          		</div>
                         </b-col>
                         <b-col md="3">
                         			
                          		</b-col>

                          	</b-row>
                          	</b-card>
              	</b-col>
              </b-row>
                          		</b-overlay>
                          </b-list-group-item>
                          <div v-else class="p-3">
	            <div class="bg-light justify-content-center align-items-center d-flex w-100 p-4 flex-column text-center border-radius-1em">
	            	<i class="mdi mdi-inbox-outline font-size-48 text-primary mt-3">
	            	</i>
	            	<h5 class="mb-3">Select a Message to Start</h5>
	            </div>
        	</div>
                              <!--<li class="list-group-item py-4 text-center" ><a class="btn btn-outline-light btn-rounded">Load More</a>
                              </li>-->
           </b-list-group>
           <div  v-if="thread_list.length == 0" class="p-3">
	            <div class="bg-light justify-content-center align-items-center d-flex w-100 p-4 flex-column text-center border-radius-1em">
	            	<i class="mdi mdi-inbox-outline font-size-48 text-primary mt-3">
	            	</i>
	            	<h5 class="mb-3">No Enquiries to Show</h5>
	            </div>
        	</div>
               
		</b-col>
	</b-row>
	<!--<SideSlidePanel :editPanel="orderViewWindow" :transitionName="'slide-fadepanelright'" :panelClass="'h-100 overflow-auto pos-frame-inner-left col-md-7 position-fixed z-index-5 bg-white'" @close-panel="orderViewWindow = !orderViewWindow">
						


					</SideSlidePanel>-->
	</div>
</template>
<style scoped>
	.chat-input-section .alert-success{
		min-width: 330px;
	}
</style>