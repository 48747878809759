<script type="text/javascript">
	export default{
		data: function(){
			return{
				storageurl: 'https://'+this.$attrs.company.nickname+'.s3.eu-west-2.amazonaws.com',
				transitioning: 0,
			}
		},
		props: {
			autoplay: {
				type: Boolean,
				default: () => true,
			},
			items: {
				type: Array,
				default: () => [],
			},
			start: {
				type: Number,
				default: () => 0,
			},
			end: {
				type: Number,
				default: () => 2,
			},
			grid_limit: {
				type: Number,
				default: () => 2,
			},
			pages: {
				type: Number,
				default: () => 0,
			},
			layout_type: {
				type: String,
				default: () => 'horizontal',
			}
		},
		mounted(){
			this.pages = (this.items.length / this.grid_limit);
			this.transitioning = 1;
			var self = this;
			setTimeout(function(){
                 self.transitioning = 0;
                 //self.autoPlay();
                }, 1000);
			if(this.autoplay == true){
				setTimeout(function(){
                 //self.transitioning = 0;
                 self.autoPlay();
                }, 5000);
			}
		},
		methods: {
			autoPlay(){
				if((this.end < this.items.length) && (this.autoplay == true)){

                this.transitioning = 1;
                //setTimeout(5000);
                this.start = this.start + this.grid_limit;
                this.end = this.end + this.grid_limit;
                var self = this;
                setTimeout(function(){
                 self.transitioning = 0;
                 self.autoPlay();
                }, 1500);
               
                }

			},
			loadMore(){
                if(this.end < this.items.length){

                this.transitioning = 1;
                //setTimeout(5000);
                this.start = this.start + this.grid_limit;
                this.end = this.end + this.grid_limit;
                var self = this;
                setTimeout(function(){
                 self.transitioning = 0;
                }, 300);
               
                }
            },
            loadLess(){
                if(this.start >= 2){
                    this.transitioning = 1;
                this.start = this.start - this.grid_limit;
                this.end = this.end - this.grid_limit;
                var self = this;
                setTimeout(function(){
                 self.transitioning = 0;
                }, 300);
               
                }
            },
		}
	}
</script>

<template>
	<b-row>
		       <b-col md="12" class="mx-auto">
                     <transition name="slide-fadeleft">
                    <b-row v-if="transitioning == 0">
               		<b-col md="12" class="px-4">
               			<b-row class="px-3" v-if="layout_type == 'horizontal'">
		                <b-col :class="{'col-md-6': grid_limit == 2, 'col-md-4': grid_limit == 3, 'col-md-7': grid_limit == 4, 'col-md-12': grid_limit == 1}" class="mt-4 z-index-1" v-for="item in items.slice(start, end)">
		                     <b-card no-body class="overflow-hidden news-card shadow" >
		                    <b-row no-gutters>
		                      <b-col :class="{'col-md-4': grid_limit == 2, 'col-md-6': grid_limit == 3, 'col-md-3': grid_limit == 4}" >
		                        <b-card-img v-if="item.cover_image" :src="item.cover_image" alt="Image" class="rounded-0"></b-card-img>
		                        <b-card-img v-else :src="'/images/product-placeholder.png'" alt="Image" class="rounded-0"></b-card-img>
		                      </b-col>
		                      <b-col :class="{'col-md-8': grid_limit == 2, 'col-md-6': grid_limit == 3, 'col-md-5': grid_limit == 4}" >
		                        <b-card-body :title="item.name">
		                          <b-card-text>
		                             <p v-html="item.short_description.substring(0,120)+'...'"></p>
		                           <a class="btn btn-primary mb-1 float-right">Link</a>
		                          </b-card-text>
		                        </b-card-body>
		                       
		                      </b-col>
		                    </b-row>
		                  </b-card>
		                </b-col>
		            </b-row>
		            <b-row class="px-3" v-if="layout_type == 'vertical'">
		                <b-col class="mt-4 z-index-1" :class="{'col-md-6': grid_limit == 2, 'col-md-4': grid_limit == 3, 'col-md-3': grid_limit == 4, 'col-md-12': grid_limit == 1}" v-for="item in items.slice(start, end)">
		                     <b-card no-body class="overflow-hidden news-card shadow border-radius-1em" >

		                    <b-row no-gutters>
		                    	<div class="col-md-12 position-absolute"> 
                              	<a id="tooltip-add"  @click="quickviewproduct = product" class="mt-3 ml-3 btn btn-primary font-size-16 btn-rounded ml-auto float-left"><i class="mdi mdi-eye-outline"></i></a>
                              </div>
		                      <b-col lg="12">
		                        <b-card-img v-if="item.cover_image" :src="item.cover_image" alt="Image" class="rounded-0"></b-card-img>
		                        <b-card-img v-else :src="'/images/product-placeholder.png'" alt="Image" class="rounded-0"></b-card-img>
		                      </b-col>
		                      <b-col lg="12">
		                        <b-card-body :title="item.name">
		                          <b-card-text>
		                             <p v-html="item.short_description.substring(0,120)+'...'"></p>
		                           

		                          </b-card-text>
		                          
		                          	
		                         
		                        </b-card-body>
		                        <div class="mt-auto mb-3 d-flex">
                              	<a id="tooltip-add"  @click="selectedCategory = list, showStoneDetails = 1"class="ml-3 mr-auto btn btn-primary font-size-16 btn-rounded"><i class="mdi mdi-eye-outline"></i></a>
                              	<a id="tooltip-add"  @click="selectedCategory = list, showStoneDetails = 1"class="mr-2 ml-auto btn btn-primary font-size-16 btn-rounded"><i class="mdi mdi-heart-outline"></i></a>
                              	<a id="tooltip-add"  @click="selectedCategory = list, showStoneDetails = 1"class="mr-3 ml-0 btn btn-primary font-size-16 btn-rounded"><i class="mdi mdi-shopping-outline"></i></a>
              					</div>
		                      </b-col>
		                    </b-row>
		                  </b-card>
		                </b-col>
		            </b-row>
		        </b-col>
                <div class="d-flex position-absolute h-100 z-index-0 mx-0 px-0">
                	<a href="javascript:;" class="less-btn my-auto mr-auto" @click="loadLess()"><i class="bx bx-chevron-left font-size-42"></i></a>
                	<a href="javascript:;" class="more-btn my-auto ml-auto" @click="loadMore()"><i class="bx bx-chevron-right font-size-42"></i></a>
            	</div>
            </b-row>
            <b-row v-else class="loader-row">
                <b-col md="12" class="d-flex mx-auto my-auto justify-content-center">
                 <b-spinner style="width: 5rem; height: 5rem;" variant="primary" label="Spinning"></b-spinner>
                </b-col>
            </b-row>
            </transition>
               
                </b-col>
	</b-row>
</template>