<script type="text/javascript">
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
//import 'swiper/css/swiper.css'
/**
 * Dashboard Component
 */
	export default {
		  components: {
		    Swiper,
		    SwiperSlide
		  },
		  directives: {
		    swiper: directive
		  },
		  data: function(){
			return{
				storageurl: '/public-image',
				transitioning: 0,
				hoverIndex: -1,
			}
		},
		props: {
			use: {
				type: String,
				default: () => 'public'
			},
			resource: {
				type: String,
				default: () => '',
			},
			autoplay: {
				type: Boolean,
				default: () => true,
			},
			items: {
				type: Array,
				default: () => [],
			},
			start: {
				type: Number,
				default: () => 0,
			},
			end: {
				type: Number,
				default: () => 2,
			},
			grid_limit: {
				type: Number,
				default: () => 2,
			},
			pages: {
				type: Number,
				default: () => 0,
			},
			layout_type: {
				type: String,
				default: () => 'horizontal',
			},
			showNavigation: {
				type: Boolean,
				default: () => false,
			},
			liveMode: {
				type: Boolean,
				default: () => false,
			},
			swiperOptions: {
				type: Object,
				default: () => {
		          // Some Swiper option/callback...
		        },
			},
			selected_currency_symbol: {
				type: String,
				default: '£'
			},
			selected_currency: {
				type: String,
				default: 'GBP'
			},
			exchange_rates: {
				type: Object, 
				default: {},
			}
		},
		mounted(){
			if(this.use == 'public'){
				this.storageurl = '.s3.eu-west-2.amazonaws.com/'
			}
			if(this.use == 'trade'){
				this.storageurl = '/trade-image'
			}
			this.pages = (this.items.length / this.grid_limit);
			this.transitioning = 0;
			var self = this;
			setTimeout(function(){
                // self.transitioning = 0;
                 //self.autoPlay();
                }, 1000);
			if(this.autoplay == true){
				setTimeout(function(){
                 //self.transitioning = 0;
                 self.autoPlay();
                }, 5000);
			}
		},
		methods: {
			autoPlay(){
				if((this.end < this.items.length) && (this.autoplay == true)){

               // this.transitioning = 1;
                //setTimeout(5000);
                this.start = this.start + this.grid_limit;
                this.end = this.end + this.grid_limit;
                var self = this;
                setTimeout(function(){
                 //self.transitioning = 0;
                 self.autoPlay();
                }, 1500);
               
                }

			},
			loadMore(){
                if(this.end < this.items.length){

               // this.transitioning = 1;
                //setTimeout(5000);
                this.start = this.start + this.grid_limit;
                this.end = this.end + this.grid_limit;
                var self = this;
                setTimeout(function(){
                 self.transitioning = 0;
                }, 300);
               
                }
            },
            loadLess(){
                if(this.start >= 2){
                   // this.transitioning = 1;
                this.start = this.start - this.grid_limit;
                this.end = this.end - this.grid_limit;
                var self = this;
                setTimeout(function(){
                 self.transitioning = 0;
                }, 300);
               
                }
            },
		}
	}
</script>

<template>
	<b-row>
		       <b-col md="12" class="mx-auto">
                     <transition name="slide-fadeleft">
                    <b-row v-if="(transitioning == 0)">
               		<b-col md="12" class="px-4">
               			<b-row class="px-3" v-if="layout_type == 'horizontal'">
               				<swiper ref="imageCarouselSwiper" :options="swiperOptions">
        						<swiper-slide v-for="(item, index) in items"  @mouseover="hoverIndex = index" @mouseout="hoverIndex = -1">
		                    	<div class="my-2 text-center"  v-if="liveMode == true" >
		                    	<a class="" @click="$emit('select-category', item)">

		                    		<b-img-lazy v-if="'cover_image' in item && item.cover_image !== null" :src="(resource == 'category' ? '' : 'https://'+(resource == 'collection' ? item.company.nickname : item.nickname)+storageurl+'')+item.cover_image+''" alt="Image" class="rounded-circle w-75 border" :class="{'border p-2' : hoverIndex == index}"></b-img-lazy>
		                    		<b-img-lazy v-else-if="'image' in item && item.image !== null" :src="(resource == 'category' ? '' : 'https://'+(resource == 'collection' ? item.company.nickname : item.nickname)+storageurl+'')+item.image+''" alt="Image" class="rounded-circle w-75 border" :class="{'border p-2' : hoverIndex == index}"></b-img-lazy>
		                    		<b-img-lazy v-else-if="'logo' in item && item.logo !== null" :src="resource == 'category' ? '' : 'https://'+(resource == 'collection' ? item.company.nickname : item.nickname)+(storageurl+''+item.logo).replaceAll('//', '/').replaceAll('https:/gemesys-dev.s3.eu-west-2.amazonaws.com/', '')+''" alt="Image" class="rounded-circle w-75 border" :class="{'border p-2' : hoverIndex == index}"></b-img-lazy>
		                        	<b-img-lazy v-else :src="(resource == 'category' ? '' : 'https://'+(resource == 'collection' ? item.company.nickname : item.nickname)+storageurl+'')+'/images/product-placeholder.png'" alt="Image" class="rounded-circle w-75 border" :class="{'border p2' : hoverIndex == index}"></b-img-lazy>

		                        <h3 class="font-size-14 text-center mt-3" :class="{'text-primary' : hoverIndex == index}">{{item.name}}</h3>
		                           </a>
		                           <router-link v-if="resource == 'collection' && item.company !== null" :to="'/seller/'+item.company.nickname+'/profile'">By 
		                           	{{item.company.name}}
		                           </router-link>
		                       </div>
		                       <div class="my-2" v-else>
		                       <a class=""  :href="((resource == 'collection' && liveMode == true ? '/seller/'+item.company.nickname+'/' : '')+resource+'/'+('link' in item ? item.link : '')+('slug' in item ? item.slug : '')).replaceAll('//', '')">
		                       	<b-img-lazy v-if="'cover_image' in item && item.cover_image !== null" :src="(resource == 'category' ? '' : 'https://'+(resource == 'collection' ? item.company.nickname : item.nickname)+storageurl+'')+item.cover_image+''" alt="Image" class="rounded-circle w-75 border" :class="{'border p-2' : hoverIndex == index}"></b-img-lazy>
		                    	<b-img-lazy v-else-if="'image' in item && item.image !== null" :src="(resource == 'category' ? '' : 'https://'+(resource == 'collection' ? item.company.nickname : item.nickname)+storageurl+'')+item.image+''" alt="Image" class="rounded-circle w-75 border" :class="{'border p-2' : hoverIndex == index}"></b-img-lazy>
		                        <b-img-lazy v-else :src="(resource == 'category' ? '' : 'https://'+(resource == 'collection' ? item.company.nickname : item.nickname)+storageurl+'')+'/images/product-placeholder.png'" alt="Image" class="rounded-circle w-75 border" :class="{'border p2' : hoverIndex == index}"></b-img-lazy>

		                        <h3 class="font-size-14 text-center mt-3" :class="{'text-primary' : hoverIndex == index}">{{item.name}}</h3>
		                           </a>

		                            <a v-if="resource == 'collection' && item.company !== null" :to="'/seller/'+item.company.nickname+'/profile'">By 
		                           	{{item.company.name}}
		                           </a>
		                       </div>
		                         
		                       
						</swiper-slide>
					</swiper>
		            </b-row>
		        </b-col>
                <div class="d-flex position-absolute h-100 z-index-0 mx-0 px-0" v-if="showNavigation">
                	<a href="javascript:;" class="less-btn my-auto mr-auto" @click="loadLess()"><i class="bx bx-chevron-left font-size-42"></i></a>
                	<a href="javascript:;" class="more-btn my-auto ml-auto" @click="loadMore()"><i class="bx bx-chevron-right font-size-42"></i></a>
            	</div>
            </b-row>
            <b-row v-else class="loader-row">
                <b-col md="12" class="d-flex mx-auto my-auto justify-content-center">
                 <b-spinner style="width: 5rem; height: 5rem;" variant="primary" label="Spinning"></b-spinner>
                </b-col>
            </b-row>
            </transition>
               
                </b-col>
	</b-row>
</template>