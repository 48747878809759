<script>
/**
 * Register component
 */
export default {
  data() {
    return {
      username: "",
      name: "",
      lastname: "",
      email: "",
      password: "",
      password_confirmation: "",
      isRegisterError: false,
      registerSuccess: false,
      regErrors: [],
    };
  },
  props: {
    submitUrl: {
      type: String,
      required: true,
    },
    regError: {
      type: String,
      required: false,
      default: () => null,
    },
    slimline: {
      type: Boolean,
      default: true
    }
  },
  mounted() {
    if(this.regError == '[]'){
      this.isRegisterError = false;
    }else{
      this.regErrors = JSON.parse(this.regError);
      this.isRegisterError = !!this.regError;
    }
  },
};
</script>

<template>
  <div class="row justify-content-center">
    <div class="col-md-12 col-lg-12 col-xl-12">
      <div class="card overflow-hidden border border-radius-1em shadow-none">
      
        <div class="card-body pt-0">
          <div v-if="slimline == false">
            <a href="/">
              <div class="avatar-lg profile-user-wid mb-0 position-relative mx-auto" style="margin-top: -45px;">
                <span class="avatar-title rounded-circle bg-light">
                  <!--<i class="bx bx-cube-alt text-dark" style="font-size: 48px"></i>-->
                   <div class="cube-wrapper m-auto"><div class="cube-folding"><span class="leaf1"></span><span class="leaf2"></span><span class="leaf3"></span><span class="leaf4"></span></div></div>
                </span>
              </div>
            </a>
          </div>

          <b-alert
            v-model="registerSuccess"
            class="mt-3"
            variant="success"
            dismissible
          >Registration successfull.</b-alert>

          <b-alert v-model="isRegisterError" class="mt-3" variant="danger" dismissible>
            <ul>
              <li v-for="error in regErrors">
                {{error[0]}}
              </li>
            </ul>
        </b-alert>
          <h4 class="text-center mt-5">Register an account</h4>
          <b-form class="p-2" :action="submitUrl" method="POST">
            <slot />
             <b-form-group id="username" label="Username" label-for="username" class="mb-3">
              <b-form-input
                id="username"
                v-model="username"
                name="username"
                type="text"
                placeholder="Enter username"
              ></b-form-input>
            </b-form-group>

            <b-form-group id="email-group" label="Name" label-for="name" class="mb-3">
              <b-form-input
                id="name"
                v-model="name"
                name="name"
                type="text"
                placeholder="Enter name"
              ></b-form-input>
            </b-form-group>

            <b-form-group id="email-group" label="Last Name" label-for="lastname" class="mb-3">
              <b-form-input
                id="lastname"
                v-model="lastname"
                name="lastname"
                type="text"
                placeholder="Enter lastname"
              ></b-form-input>
            </b-form-group>

            <b-form-group id="fullname-group" label="Email" label-for="email" class="mb-3">
              <b-form-input
                id="email"
                name="email"
                v-model="email"
                type="email"
                placeholder="Enter email"
              ></b-form-input>
            </b-form-group>

            <b-form-group id="password-group" label="Password" label-for="password" class="mb-3">
              <b-form-input
                id="password"
                v-model="password"
                name="password"
                type="password"
                placeholder="Enter password"
              ></b-form-input>
            </b-form-group>
            <b-form-group label="Confirm Password" label-for="password-confirm" class="mb-3">
              <b-form-input
                id="password-confirm"
                v-model="password_confirmation"
                name="password_confirmation"
                type="password"
                placeholder="Confirm password"
              ></b-form-input>
            </b-form-group>

            <div class="mt-4 d-grid">
              <b-button type="submit" variant="primary" class="btn-block btn-rounded">Register</b-button>
            </div>
            <!--<div class="mt-4 text-center">
              <h5 class="font-size-14 mb-3">Sign in with</h5>

              <ul class="list-inline">
                <li class="list-inline-item">
                  <a
                    href="javascript: void(0);"
                    class="social-list-item bg-primary text-white border-primary"
                  >
                    <i class="mdi mdi-facebook"></i>
                  </a>
                </li>
                <li class="list-inline-item">
                  <a
                    href="javascript: void(0);"
                    class="social-list-item bg-info text-white border-info"
                  >
                    <i class="mdi mdi-twitter"></i>
                  </a>
                </li>
                <li class="list-inline-item">
                  <a
                    href="javascript: void(0);"
                    class="social-list-item bg-danger text-white border-danger"
                  >
                    <i class="mdi mdi-google"></i>
                  </a>
                </li>
              </ul>
            </div>-->
            <div class="mt-4 text-center">
              <p class="mb-0">
                By registering you agree to the Gemesys
                <a
                  href="javascript: void(0);"
                  class="text-primary"
                >Terms of Use</a>
              </p>
            </div>
          </b-form>
        </div>
        <!-- end card-body -->
      </div>
      <!-- end card -->

      <div class="mt-5 text-center">
        <p>
          Already have an account ?
          <a @click="$emit('login')" class="fw-medium text-primary">Login</a>
        </p>
        <p>
            © {{ new Date().getFullYear() }} Gemesys by Gemesys Labs
        </p>
      </div>
    </div>
    <!-- end col -->
  </div>
</template>

<style lang="scss" module></style>
