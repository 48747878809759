<script type="text/javascript">
  export default{
    data(){
      return{
        name: '',
        description: '',
        selected_integration: '',
        integration: false,
        active: false,
        payment_integrations: [
          {name: 'Stripe', icon: 'fab fa-cc-stripe'},
          {name: 'Paypal', icon: 'fab fa-cc-paypal'}
        ]
      }
    },
    methods: {
      submitForm(){

      }
    }
  }
</script>     
<template>
        <b-form id="addpaymentterm" method="POST" enctype="multipart/form-data">
                    	 <b-form-group label="Name" label-for="name">
				            <b-form-input id="name" type="text" name="name" ></b-form-input>
				          </b-form-group>
				           <b-form-group class="mt-2" label="Description" label-for="description">
				            <b-textarea id="description" rows="3" name="description"></b-textarea>
				          </b-form-group>
                  <div
                        class="form-check form-switch form-switch-sm mb-0 mt-4"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          id="newterm-recurring"
                          value="true"
                          unchecked-value="false"
                          v-model="integration"
                        />
                        <label class="form-check-label" for="newterm-recurring" 
                          > Integration</label
                        >
                    </div>

                    <input type="hidden" name="integrations" v-model="integrations">
                    <b-row v-if="integration == true" class="mt-3">
                      <b-col md="6" v-for="integration in payment_integrations">
                        <b-card @click="selected_integration = integration.name" class="border" :class="{'bg-primary text-white' : selected_integration == integration.name, 'text-muted' : selected_integration !== integration.name}">
                          <i :class="integration.icon"></i> {{integration.name}}
                        </b-card>
                      </b-col>
                    </b-row>
                	<div
                        class="form-check form-switch form-switch-sm mb-0 mt-4"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          id="newterm-recurring"
                          value="true"
                          unchecked-value="false"
                          v-model="active"
                        />
                        <label class="form-check-label" for="newterm-recurring" 
                          > Active</label
                        >
                    </div>
                    <input type="hidden" name="active" v-model="active">
             		<b-button class="w-100 mt-4" variant="success" href="javascript:;">Save</b-button>
        </b-form>
    </template>