<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import SideSlidePanel from "../../components/widgets/SideSlidePanel";
import vue2Dropzone from 'vue2-dropzone';
import Multiselect from "vue-multiselect";
import AddressLabel from "../../components/widgets/admin/print/AddressLabel";
import CompanySupportForm from '../../components/widgets/forms/company/CompanySupportForm';
import CompanyCustomerSearch from '../../components/widgets/CompanyCustomerSearch';

export default{
	components: {
		Layout,
		PageHeader,
		SideSlidePanel,
		vue2Dropzone,
		Multiselect,
		AddressLabel,
		CompanySupportForm,
		CompanyCustomerSearch
	},
	data(){
		return{
			subscription_source: 'Manual Input',
			downloadListType: '',
			current_subscriber: '',
			addressLabel: '',
			printWindow: false,
			success_message: false,
			success_text: '',
			countries: [],
			counties: [],
			custom_field: {
        type: '',
        name: '',
        options: [],
        value: '',
        required: false,
        multiple: false,
        enable_editing: 1,
      },
      custom_fieldRaw: {
        type: '',
        name: '',
        options: [],
        value: '',
        required: false,
        multiple: false,
        enable_editing: 1,
      },
      form_fields: {
      	custom_fields: [{
        type: 'Text',
        name: 'Name',
        options: [],
        value: '',
        required: true,
        multiple: false,
        enable_editing: 0,
      },{
        type: 'Email',
        name: 'Email',
        options: [],
        value: '',
        required: true,
        multiple: false,
        enable_editing: 0,
      }],
      },
      custom_field_editing: -1,
      loadingCustomFields: false,
			listData: '',
			deleteAddressPanel: false,
			addNewPanel: false,
			loadingForm: false,
			error_message: false,
			error_reponse: '',
			addSupplierAddressPanel: false,
			deletesupplieraddress: false,
			contactsPanel: false,
		    addSupplierContactPanel: false,
		    deleteContactPanel: false,
			editsupplieraddress: '',
			add_subscription: '',
			add_subscription_customer: null,
			newsupplieraddress:{
		        id: null,
		        alias: '',
		        address_1: '',
		        address_2: '',
		        address_3: '',
		        county: '',
		        country: '',
		        city: '',
		        postcode: '',
		        active: 0,
		        invoice_default: 0,
		        delivery_default: 0,
		        type: '',
		      },
		      editsupplieraddress: {
		        id: null,
		        alias: '',
		        address_1: '',
		        address_2: '',
		        address_3: '',
		        county: '',
		        country: '',
		        city: '',
		        postcode: '',
		        active: 0,
		        invoice_default: 0,
		        delivery_default: 0,
		        type: '',
		      },
		      newsuppliercontact:{
		        id: null,
		        name: '',
		        position: '',
		        phone: '',
		        email: '',
		        mobile: '',
		        fax: '',
		        default: 0,
		      },
		      editsuppliercontact: {
		        id: null,
		        name: '',
		        position: '',
		        phone: '',
		        email: '',
		        mobile: '',
		        fax: '',
		        default: 0,
		      },
		}
	},
	props: {
		id: {
			type: String,
			default: () => ''
		},
		user: {
			type: Object,
			default: () => ''
		},
		company: {
			type: Object,
			default: () => ''
		}
	},
	created(){
		this.loadlistData();
		this.getCountries();
	},
	mounted(){
		
	},
	methods: {
		async prepareDownload(type){
			var subscribers = [];
			if(type == 'csv'){
				this.listData.subscribers.forEach(listsubscriber => {
					var subscriber = {};
					subscriber['name'] = listsubscriber.name;
					subscriber['email'] = listsubscriber.email;
					listsubscriber.form_data.forEach(formfield => {
						if((formfield.name !== 'name') && (formfield.name !== 'email')){
							subscriber[formfield.name] = formfield.value;
						}
					});
					subscribers.push(subscriber);
				});

				return this.createDownload(subscribers);
			}
		},
		async createDownload(list){
			axios.post('/api/company/customer/list/export', list, {headers: {'Authorization' : 'Bearer '+this.user.api_token}}).then(response => {
							if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        // Check if the response is an xlsx file
        const blob = response.blob(); // Get the response as a blob
        const url = window.URL.createObjectURL(blob); // Create a link to the blob

        const a = document.createElement('a'); // Create an anchor element
        a.style.display = 'none'; // Hide it
        a.href = url; // Set the href to the blob URL
        // Specify the desired filename (optional, can be anything)
        a.download = 'data_' + Date.now() + '.xlsx'; 
        document.body.appendChild(a); // Append anchor to the body
        a.click(); // Programmatically click the anchor to trigger the download
        window.URL.revokeObjectURL(url); // Clean up the URL object
			}).catch(error => {

			});
		},
		toggleMultiple(status){
			console.log(status);
			if(status !== true){
				this.custom_field['value'] = [];
			}else{
				this.custom_field['value'] = '';
			}
		},
		removeSubscriberFromList(){
			this.success_message = false;
			axios.post('/api/company/customer/list/'+this.listData.id+'/remove-subscriber/'+this.current_subscriber.uid, {}, {headers: {'Authorization' : 'Bearer '+this.user.api_token}}).then(response => {
					
					this.success_text = 'Subscriber removed from list';
					this.success_message = true;
					
					this.$bvModal.hide('removeSubscriber');
					this.current_subscriber = '';
					return this.loadlistData();
			}).catch(error => {
				this.error_reponse = error.response.data.errors;
        this.error_message = true;

        let self = this;
          setTimeout(function(){
            self.loadingForm = false;
          }, 500);
			});
		},
		addSubscriberToList(){
			this.success_message = false;
			axios.post('/api/company/customer/list/'+this.listData.id+'/subscribe-user', {list: this.listData, form: this.add_subscription.fields, name: this.add_subscription.fields[this.add_subscription.fields.findIndex(item => item.name == 'Name')].value, email: this.add_subscription.fields[this.add_subscription.fields.findIndex(item => item.name == 'Email')].value, company_customer_id: this.add_subscription_customer !== null ? this.add_subscription_customer['id'] : null, source: this.subscription_source}, {headers: {'Authorization' : 'Bearer '+this.user.api_token}}).then(response => {
					
					this.success_text = 'Subscriber added to list';
					this.success_message = true;
					
					this.$bvModal.hide('addSubscriber');
					this.add_subscription = '';
					this.add_subscription_customer = '';
					return this.loadlistData();
			}).catch(error => {
				this.error_reponse = error.response.data.errors;
        this.error_message = true;

        let self = this;
          setTimeout(function(){
            self.loadingForm = false;
          }, 500);
			});
		},
		updateList(){
      this.success_message = false;
      this.error_message = false;
      this.loadingForm = true;
      axios.post('/api/company/customer/list/'+this.listData.id+'/update', {list: this.listData, form: this.listData.form}, {headers: {'Authorization' : 'Bearer '+this.user.api_token}}).then(response => {
        console.log(response);
        this.success_text = 'Congratulations, your category has been updated.';
        this.success_message = true;
        //this.resetProjectData();
        //this.addPanel = false;
        let self = this;
          setTimeout(function(){
            self.loadingForm = false;
            self.editPanel = false;
            self.productQuery();
          }, 500);
      }).catch( error => {
        
        this.error_reponse = error.response.data.errors;
        this.error_message = true;
        //this.resetProjectData();
        let self = this;
          setTimeout(function(){
            self.loadingForm = false;
          }, 500);
      });

    },
		loadlistData(){
			axios.get('/api/company/customer/list/'+this.id, { headers : {
				'Authorization': 'Bearer ' + this.user.api_token
			} }).then(response => {
				this.listData = response.data;
				//return this.loadShopOrders(response.data.id);
			}).catch(error => {
				this.error_reponse = error;
			});
		},
		getCountries(){
        if(this.countries.length == 0){
          axios.get('/api/countries', {headers: {
                  'Authorization': 'Bearer ' + this.user.api_token
              }}).then(response => {
            this.countries = response.data;
          }).catch(error => {
            this.load_error = error.data;
          })
        }
      },
      getCounties(country){
        // get counties as country selected
        axios.get('/api/country/'+country.id+'/counties', {headers: {
                  'Authorization': 'Bearer ' + this.user.api_token
              }}).then(response => {
          this.counties = response.data;
        }).catch(error => {
          this.load_error = error.data;
        })
      },
		
	}
}


</script>

<template>
	<Layout  :nickname="$attrs.nickname" :user="user" :menuItems="$attrs.menuItems" >
		<PageHeader :title="title" :items="items" />
		<b-row>
			<b-col md="12">
                    <transition name="slide-success" mode="out-in">
                  <b-alert id="success-message" variant="success" :show="success_message"  dismissible @dismissed="success_message = !success_message">
                      <p class="font-size-14">{{success_text}}</p>
                    </b-alert>
                  </transition>
                  </b-col>
                  <b-col md="12">
                    <transition name="slide-error" mode="out-in">
                  <b-alert id="errors" variant="danger" :show="error_message"  dismissible @dismissed="error_message = !error_message">
                      <p class="font-size-14">Oops! Please correct the following errors in your submission</p>
                      <ul>
                        <li v-for="(error, key) in error_reponse">
                          {{error[0]}}
                        </li>
                      </ul>
                      <small>Please Try Again. If this error persists, please contact support.</small>
                    </b-alert>
                  </transition>
                  </b-col>
			<b-col md="3">
				<b-card class="card overflow-hidden border-radius-1em shadow-sm seller-profile-card" no-body >
					<div class="bg-light" style="height: 75px;">
			            <div class="row">
			            </div>
			        </div>
					<b-card-body class="card-body pt-0">
		            <div class="row">
		              <div class="col-sm-12 pb-4 text-center">
		                <div class="avatar-md profile-user-wid mb-2 mx-auto" style="margin-top: -45px !important;">
		                  <img
		                  	v-if="listData.avatar" :src="listData.avatar"
		                    alt
		                    class="img-thumbnail rounded-circle"
		                  />
		                   <img
		                  	v-else 
		                    src="/images/product-placeholder.png"
		                    alt
		                    class="img-thumbnail rounded-circle"
		                  />
		                </div>
		                <h5 class="font-size-15 text-truncate">{{listData.name}}</h5>
		                <a class="text-muted mb-0 text-truncate">{{listData.email}}</a>

		                <p v-html="listData.description"></p>
		              </div>
		              <!--<div class="col-md-12">
		              	<span class="badge badge-light border-radius-1em bg-light text-dark font-size-11 lh-16 my-auto p-2">
		              		<i class="bx bx-buildings text-primary"></i> 
		              	</span>
		              	

		              </div>-->
		              
		            

		            </div>
		            <a class="btn btn-primary btn-block btn-rounded mt-2" @click="add_subscription = listData.form, add_subscription_customer = null, $bvModal.show('addSubscriber')">
            	Add Subscriber <i class="bx bx-user-add"></i>
            </a>
             <a class="btn btn-info btn-block btn-rounded mt-2" @click="$bvModal.show('downloadList')">
            	Download List <i class="bx bx-user-add"></i>
            </a>
            <a class="btn btn-success btn-block btn-rounded mt-2" @click="updateList()">
            	Update List <i class="bx bx-user-add"></i>
            </a>
		          </b-card-body>
				</b-card>
							
							</b-col>
			<b-col md="9">
				<b-row>
					<b-col>
						  <b-card class="border-radius-1em shadow-sm">
				        	<div class="d-flex align-items-center mb-3"><div class="avatar-xs mr-3"><span class="avatar-title rounded-circle bg-soft-primary text-white font-size-18"><i class="bx bx-calendar"></i></span></div> <h5 class="font-size-14 mb-0">Date Created</h5></div> 
				        	<div class="text-muted mt-4">
				        		<h4 >{{listData.created_at | luxon({input: 'server'})}}</h4>

				        	<!--<div class="d-flex">
				        		<span class="badge badge-soft-success font-size-12"> 3 </span> <span class="ml-2 text-truncate">From previous period</span>
				        	</div>-->
				        	</div>
				        	
				        </b-card>
				    </b-col>
				    <b-col>
						  <b-card class="border-radius-1em shadow-sm">
				        	<div class="d-flex align-items-center mb-3"><div class="avatar-xs mr-3"><span class="avatar-title rounded-circle bg-soft-primary text-white font-size-18"><i class="bx bx-calendar"></i></span></div> <h5 class="font-size-14 mb-0">Date Updated</h5></div> 
				        	<div class="text-muted mt-4">
				        		<h4 >{{listData.updated_at | luxon({input: 'server'})}}</h4>

				        	<!--<div class="d-flex">
				        		<span class="badge badge-soft-success font-size-12"> 3 </span> <span class="ml-2 text-truncate">From previous period</span>
				        	</div>-->
				        	</div>
				        	
				        </b-card>
				    </b-col>
				    <b-col>
						  <b-card class="border-radius-1em shadow-sm">
				        	<div class="d-flex align-items-center mb-3"><div class="avatar-xs mr-3"><span class="avatar-title rounded-circle bg-soft-primary text-white font-size-18"><i class="bx bx-copy-alt"></i></span></div> <h5 class="font-size-14 mb-0">Subscribers</h5></div> 
				        	<div class="text-muted mt-4">
				        		<h4 v-if="listData !== ''"> {{listData.subscribers.length}} <i class="mdi mdi-chevron-up ml-1 text-success"></i></h4>
				        	
				        	
				        	</div>
				        	
				        </b-card>
				    </b-col>
				 </b-row>
				<b-tabs pills justified content-class="pt-3">
					<b-tab title="Subscribers" >
						<b-card class="border-radius-1em border-none shadow-none">

							<table class="table" v-if="(listData !== '') && (listData.form !== null)">
								<thead>
									<tr>
									<th v-for="field in listData.form.fields">
										{{field.name}}
									</th>
									<th>
										Source
									</th>
									<th>
										Actions
									</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="subscriber in listData.subscribers">
										<td v-for="fieldname in listData.form.fields">
											


											<p class="mb-2" v-if="Array.isArray(subscriber.form_data.findIndex(item => item.name == fieldname.name) > -1 ? subscriber.form_data.filter(item => item.name == fieldname.name)[0]['value'] : '')">
    					<span v-for="item in subscriber.form_data.findIndex(item => item.name == fieldname.name) > -1 ? subscriber.form_data.filter(item => item.name == fieldname.name)[0]['value'] : []" class="badge badge-light bg-light mr-2 font-size-12">
    							{{item}}
	   					</span>
    			</p>
    			<p class="mb-2" v-else>
    				<span class="badge badge-light bg-light mr-2 font-size-12">{{subscriber.form_data.findIndex(item => item.name == fieldname.name) > -1 ? subscriber.form_data.filter(item => item.name == fieldname.name)[0]["value"] : ''}}
    				</span></p>
										</td>
										<td>

											   <div v-if="subscriber.source == 'Trade'">
                       <span class="font-size-12 badge badge-light bg-light text-dark"  v-b-tooltip.hover title="Order from Trade Marketplace"><i class="bx bx-store text-primary"></i> Trade Marketplace</span>
                      </div>
                      <div v-if="subscriber.source == 'Public'">
                        <span class="font-size-12 badge bg-primary bg-soft text-dark"  v-b-tooltip.hover title="Order from Gemesys Public Marketplace"><i class="bx bxs-store text-dark"></i> Public Marketplace</span>
                      </div>
                      <div v-if="subscriber.source == 'Website'">
                        <span class="font-size-12 badge bg-peach-soft text-primary" v-b-tooltip.hover title="Order from Your Website"><i class="bx bx-globe text-primary"></i> Website</span>
                      </div>
                      <div v-if="subscriber.source == 'In Store'">
                        <span class="font-size-12 badge bg-pos text-primary" v-b-tooltip.hover title="Order from Your Point of Sale"><i class="bx bx-shopping-bag text-primary"></i> In Store</span>
                      </div>
                      <div v-if="subscriber.source == 'Manual Input'">
                        <span class="font-size-12 badge bg-secondary badge-secondary" v-b-tooltip.hover title="Order from Your Point of Sale"><i class="bx bx-shopping-bag text-white"></i> Manual Input</span>
                      </div>
                       <div v-if="subscriber.source == 'Imported'">
                        <span class="font-size-12 badge bg-info badge-info" v-b-tooltip.hover title="Order from Your Point of Sale"><i class="bx bx-shopping-bag text-white"></i> Imported</span>
                      </div>
										</td>
										<td>
											<a class="btn btn-light btn-sm" @click="current_subscriber = subscriber, $bvModal.show('subscriberDetails')">
												View Details
											</a>
										</td>
									</tr>
								</tbody>
							</table>
						</b-card>
   	
					</b-tab>

					<b-tab title="Form">
						<b-card class="shadow-none border-radius-1em border">
						<div class="d-flex">
            <b-form-group label="Field Type" class="w-50 pr-2">
              <b-form-select class="form-control" :options="['Email', 'Text', 'Long Text', 'Number', 'Dropdown', 'Radio', 'Checkbox', 'Date']" v-model="custom_field['type']">
              </b-form-select>
             </b-form-group>

            <b-form-group label="Name" class="w-50">
              <b-form-input v-model="custom_field['name']">
              </b-form-input>
            </b-form-group>

              <div
                        class="form-check form-switch form-switch-sm pt-4 mt-2 ml-2"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          :id="`SwitchCheckSize-formrequired`"
                          v-model="custom_field['required']"
                          :value="custom_field['required']"
                          name="active"
                          :disabled="(user.companies[0].userrole.name !== 'Owner') && !user.companies[0].permissions.edit.includes($attrs.active_menu.id)"
                   
                        />
                        <label class="form-check-label" :for="`SwitchCheckSize-formrequired`" 
                          >Required</label
                        >
                    </div>
                    <div
                        class="form-check form-switch form-switch-sm pt-4 mt-2 ml-2"
                        dir="ltr"
                        v-if="(custom_field['type'] == 'Checkbox') || (custom_field['type'] == 'Radio')"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          :id="`SwitchCheckSize-formrequired`"
                          v-model="custom_field['multiple']"
                          :value="custom_field['multiple']"
                          name="active"
                          :disabled="(user.companies[0].userrole.name !== 'Owner') && !user.companies[0].permissions.edit.includes($attrs.active_menu.id)"
                          @input="toggleMultiple(custom_field['multiple'])"
                   
                        />
                        <label class="form-check-label" :for="`SwitchCheckSize-formrequired`" 
                          >Multiple</label
                        >
                    </div>
            </div>
           
            <div v-if="custom_field['type'] == 'Dropdown' || custom_field['type'] == 'Radio' || custom_field['type'] == 'Checkbox'" class="d-flex flex-column" >
              <div class="d-flex" v-for="(option, index) in custom_field['options']">
              <b-form-group  label="Option" class="w-100">
                 <b-form-input v-model="custom_field['options'][index]" type="Value">
                </b-form-input>
              </b-form-group>

              <a class="btn btn-light mt-auto btn-rounded" @click="custom_field['options'].splice(index, 1)">
                <i class="bx bx-minus"></i>
              </a>
            </div>
            <b-button variant="light" class="btn-rounded mt-2 btn-block" @click="custom_field['options'].push('')">
              Add {{custom_field['type']}} Option
            </b-button>
            </div>

            <b-button variant="primary" class="btn-rounded mt-2 btn-block" v-if="custom_field_editing == -1" :disabled="(custom_field['name'] == '') || (custom_field['type'] == 'Dropdown' && custom_field['options'].length == 0) || (custom_field['type'] == 'Radio' && custom_field['options'].length == 0) || (custom_field['type'] == 'Checkbox' && custom_field['options'].length == 0)" @click="listData.form.fields.push(custom_field), custom_field = JSON.parse(JSON.stringify(custom_fieldRaw))">
              Add Field
            </b-button>
             <b-button variant="primary" class="btn-rounded mt-2 btn-block" v-else :disabled="(custom_field['name'] == '') || (custom_field['type'] == 'Dropdown' && custom_field['options'].length == 0) || (custom_field['type'] == 'Radio' && custom_field['options'].length == 0) || (custom_field['type'] == 'Checkbox' && custom_field['options'].length == 0)" @click="listData.form.fields[custom_field_editing] = (custom_field), custom_field = JSON.parse(JSON.stringify(custom_fieldRaw)), custom_field_editing = -1">
              Update Field
            </b-button>
           

            <div v-if="form_fields.custom_fields !== null" class="mt-2">
            <draggable v-model="listData.form.fields" handle=".handle">
            <b-card v-if="!loadingCustomFields" class=" border shadow-none mb-0" v-for="(field, fieldindex) in listData.form.fields" body-class="d-flex flex-column">
                <b-form-group class="mb-2 border-bottom" v-if="field['type'] == 'Text'" :label="field['name']" :class="field['required'] == true ? 'required' : ''">
                  <b-form-input type="text" v-model="field['value']" :required="field['required'] == true">

                  </b-form-input>
                  
                </b-form-group>
                 <b-form-group class="mb-2 border-bottom" v-if="field['type'] == 'Email'" :label="field['name']" :class="field['required'] == true ? 'required' : ''">
                  <b-form-input type="email" v-model="field['value']" :required="field['required'] == true">

                  </b-form-input>
                  
                </b-form-group>
                <b-form-group class="mb-2 border-bottom pb-4" v-else-if="field['type'] == 'Long Text'" :label="field['name']" :class="field['required'] == true ? 'required' : ''">
                  <b-form-textarea rows="4" max-rows="8" v-model="field['value']" :required="field['required'] == true">

                  </b-form-textarea>
                </b-form-group>
                <b-form-group class="mb-2 border-bottom" v-else-if="field['type'] == 'Number'" :label="field['name']" :class="field['required'] == true ? 'required' : ''">
                  <b-form-input type="number" v-model="field['value']" :required="field['required'] == true">

                  </b-form-input>
                </b-form-group>
                <b-form-group class="mb-2 border-bottom"  v-else-if="field['type'] == 'Dropdown'" :label="field['name']" :class="field['required'] == true ? 'required' : ''">
                 <b-form-select class="form-control" :label="field['name']" v-model="field['value']" :options="field['options']" :required="field['required'] == true">

                  </b-form-select>
                </b-form-group>
                 <b-form-group class="mb-2 border-bottom" v-else-if="field['type'] == 'Radio'" :label="field['name']" :class="field['required'] == true ? 'required' : ''" :multiple="field['multiple']">
                  
                           <b-form-radio-group class="w-100" v-model="field['value']" :options="field['options']" :required="field['required'] == true" :multiple="field['multiple']">

                            </b-form-radio-group>

                </b-form-group>
                 <b-form-group class="mb-2 border-bottom" v-else-if="field['type'] == 'Checkbox'" :label="field['name']" :class="field['required'] == true ? 'required' : ''">
                  
                           <b-form-checkbox-group class="w-100" v-model="field['value']" :options="field['options']" :required="field['required'] == true" :multiple="field['multiple']">

                            </b-form-checkbox-group>

                </b-form-group>
                <b-form-group class="mb-2 border-bottom d-flex" v-else-if="field['type'] == 'Date'" :label="field['name']" :class="field['required'] == true ? 'required' : ''">
                  
                           <b-form-input type="date" class="w-100" v-model="field['value']" :required="field['required'] == true">

                            </b-form-input>

                </b-form-group>
                <div class="d-flex align-items-end justify-content-end" v-if="field.enable_editing == true">
                	 <a class="btn ml-auto btn-light btn-rounded mb-auto ml-auto handle" href="javascript:;">
                    <i class="bx bx-move"></i>
                </a>
                <a class="btn btn-light btn-rounded mb-auto ml-2" @click="loadingCustomFields = true, custom_field_editing = fieldindex, custom_field = listData.form.fields[fieldindex], loadingCustomFields = false">
                    <i class="bx bx-edit-alt"></i>
                </a>
                <a class="btn btn-light btn-rounded mb-auto ml-2" @click="loadingCustomFields = true, listData.form.fields.splice(fieldindex, 1), loadingCustomFields = false">
                    <i class="bx bx-minus"></i>
                </a>

            	</div>
            </b-card>
         </draggable>
         <a class="btn btn-success btn-block btn-rounded mt-3" @click="updateList()">
            	Update Form
            </a>
          </div>
            </b-overlay>
			</b-card>

        </b-tab>
        <b-tab title="Settings">
        	<b-card class="shadow-none border-radius-1em border">

        		<b-form-group label="List Name" class="pb-3">
              		<b-form-input type="text" v-model="listData.name">
              		</b-form-input>
              	</b-form-group>
              	<b-form-group label="List Description">
              		<b-form-textarea type="text" v-model="listData.description">
              		</b-form-textarea>
              	</b-form-group>
            <b-form-group label="">
  						<div
                        class="form-check form-switch form-switch-sm mt-2 ml-2"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          :id="`SwitchCheckSize-emailuser`"
                          v-model="listData.email_user"
                          :value="listData.email_user"
                          name="active"
                          :disabled="(user.companies[0].userrole.name !== 'Owner') && !user.companies[0].permissions.edit.includes($attrs.active_menu.id)"
                   
                        />
                        <label class="form-check-label" :for="`SwitchCheckSize-emailuser`" 
                          >Email User when Subscribed</label
                        >
                    </div>
            </b-form-group>
            <b-form-group label="">
              <div
                        class="form-check form-switch form-switch-sm mt-2 ml-2"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          :id="`SwitchCheckSize-optin`"
                          v-model="listData.requires_opt_in"
                          :value="listData.requires_opt_in"
                          name="active"
                          :disabled="(user.companies[0].userrole.name !== 'Owner') && !user.companies[0].permissions.edit.includes($attrs.active_menu.id)"
                   
                        />
                        <label class="form-check-label" :for="`SwitchCheckSize-optin`" 
                          >Requires Opt-in</label
                        >
                    </div>	
            </b-form-group>
            <b-form-group label="">
              <div
                        class="form-check form-switch form-switch-sm mt-2 ml-2"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          :id="`SwitchCheckSize-emailoptin`"
                          v-model="listData.requires_email_opt_in"
                          :value="listData.requires_email_opt_in"
                          name="active"
                          :disabled="(user.companies[0].userrole.name !== 'Owner') && !user.companies[0].permissions.edit.includes($attrs.active_menu.id)"
                   
                        />
                        <label class="form-check-label" :for="`SwitchCheckSize-emailoptin`" 
                          >Requires Email Opt-in</label
                        >
                    </div>	
            </b-form-group>
            <a class="btn btn-success btn-block btn-rounded mt-3" @click="updateList()">
            	Update Settings
            </a>
						</b-card>
					</b-tab>
				</b-tabs>
			</b-col>
		</b-row>
		 <b-modal hide-footer size="xl" v-model="printWindow">
      <b-card no-body class="shadow-none border-none mb-0">
        <AddressLabel v-if="addressLabel !== ''" :address="addressLabel" :addresses="[]"></AddressLabel>
      </b-card>
    </b-modal>
    <b-modal hide-footer id="downloadList" centered size="lg">
    	 <b-card no-body class="shadow-none border-none mb-0">
    	 	<b-row>
    	 		<b-col md="12" class="mt-3 d-flex">
						<a class="btn btn-light border-radius-1em btn-md mx-2 btn-block w-50 d-flex flex-column justify-content-center" @click="downloadListType = 'csv'" >
							<i class="bx bx-credit-card font-size-36 text-primary d-block my-2"></i>
							Download CSV
						</a>
						
						<a class="btn btn-light border-radius-1em btn-md mx-2  btn-block w-50 d-flex flex-column justify-content-center"  @click="downloadListType = 'mailer'">
							<i class="mdi mdi-pen font-size-36 text-primary d-block"></i>
							Sync with Mailer
						</a>
					</b-col>
    	 	</b-row>
    	 	<b-row  v-if="downloadListType == 'csv'">

    	 		<b-col>
    	 			<h4>Export List to XLS</h4>
    	 			<p>To export your list click the prepare & download below:</p>
    	 			<a @click="prepareDownload('csv')" class="btn btn-success">
    	 				Prepare and Export
    	 			</a>
    	 		</b-col>
    	 	</b-row>
    	 	<b-row  v-if="downloadListType == 'mailer'">
    	 	</b-row>
    	 </b-card>
    </b-modal>
    <b-modal hide-footer id="addSubscriber" centered>
    	<b-card no-body class="shadow-none border-none mb-0">
    		   <b-alert variant="danger" :show="error_message"  dismissible @dismissed="error_message = !error_message">
                      <p class="font-size-14">Oops! Please correct the following errors in your submission</p>
                      <ul>
                        <li v-for="(error, key) in error_reponse">
                          {{error[0]}}
                        </li>
                      </ul>
                      <small>Please Try Again. If this error persists, please contact support.</small>
                    </b-alert>
    	<b-form id="form-addSubscriber" enctype="multipart/form-data">

    		<CompanyCustomerSearch class="mb-3" :api_token="user.api_token" @search-resource-selected="add_subscription_customer = $event, add_subscription.fields[add_subscription.fields.findIndex(item => item.name == 'Name')].value = '' + $event.name + ' '+$event.lastname + '', add_subscription.fields[add_subscription.fields.findIndex(item => item.name == 'Email')].value = $event.email">
    			
    		</CompanyCustomerSearch>

    		<b-card class="shadow-none border-radius-1em border border-radius-1em mb-3"  v-if="add_subscription_customer !== null" body-class="d-flex ">
    				<div>
    					<img class="avatar-md rounded-circle" v-if="add_subscription_customer.avatar !== null" :src="'https://'+company.nickname+ '.s3.eu-west-2.amazonaws.com/'+ add_subscription_customer.avatar">
    					<img class="avatar-md rounded-circle" v-else :src="'/images/product-placeholder.png'">
    				</div>
    				<div class="d-flex flex-column flex-grow-2 ml-3 position-relative">
    					<h4>{{add_subscription_customer.name}} {{add_subscription_customer.lastname}}</h4>
    					<a :href="'mailto:' + add_subscription_customer.email">{{add_subscription_customer.email}}</a>
    					<a class="btn btn-light btn-sm mr-auto mt-2" :href="'/customer/details/'+add_subscription_customer.uid">
    					View Profile <i class="bx bx-arrow-right"></i>
    				</a>
    				<a class="position-absolute top-0 right-0 btn" @click="add_subscription_customer = null, add_subscription.fields[add_subscription.fields.findIndex(item => item.name == 'Name')].value = '', add_subscription.fields[add_subscription.fields.findIndex(item => item.name == 'Email')].value = ''">
    					<i class="bx bx-x font-size-24"></i>
    				</a>
    				</div>
    				
    		</b-card>

    		<div  v-if="add_subscription !== ''"  v-for="(field, fieldindex) in add_subscription.fields">
    		<b-form-group class="mb-2" v-if="field['type'] == 'Text'" :label="field['name']" :class="field['required'] == true ? 'required' : ''">
                  <b-form-input type="text" v-model="field['value']" :required="field['required'] == true">

                  </b-form-input>
                  
                </b-form-group>
                 <b-form-group class="mb-2" v-if="field['type'] == 'Email'" :label="field['name']" :class="field['required'] == true ? 'required' : ''">
                  <b-form-input type="email" v-model="field['value']" :required="field['required'] == true">

                  </b-form-input>
                  
                </b-form-group>
                <b-form-group class="mb-2 pb-4" v-else-if="field['type'] == 'Long Text'" :label="field['name']" :class="field['required'] == true ? 'required' : ''">
                  <b-form-textarea rows="4" max-rows="8" v-model="field['value']" :required="field['required'] == true">

                  </b-form-textarea>
                </b-form-group>
                <b-form-group class="mb-2" v-else-if="field['type'] == 'Number'" :label="field['name']" :class="field['required'] == true ? 'required' : ''">
                  <b-form-input type="number" v-model="field['value']" :required="field['required'] == true">

                  </b-form-input>
                </b-form-group>
                <b-form-group class="mb-2"  v-else-if="field['type'] == 'Dropdown'" :label="field['name']" :class="field['required'] == true ? 'required' : ''">
                 <b-form-select class="form-control" :label="field['name']" v-model="field['value']" :options="field['options']" :required="field['required'] == true">

                  </b-form-select>
                </b-form-group>
                 <b-form-group class="mb-2 d-flex flex-column" v-else-if="field['type'] == 'Radio'" :label="field['name']" :class="field['required'] == true ? 'required' : ''">
                  
                           <b-form-radio-group class="w-100" v-model="field['value']" :options="field['options']" :required="field['required'] == true">

                            </b-form-radio-group>

                </b-form-group>
                 <b-form-group class="mb-2 d-flex flex-column" v-else-if="field['type'] == 'Checkbox'" :label="field['name']" :class="field['required'] == true ? 'required' : ''">
                  
                           <b-form-checkbox-group class="w-100" v-model="field['value']" :options="field['options']" :required="field['required'] == true">

                            </b-form-checkbox-group>

                </b-form-group>
                <b-form-group class="mb-2 d-flex flex-column" v-else-if="field['type'] == 'Date'" :label="field['name']" :class="field['required'] == true ? 'required' : ''">
                  
                           <b-form-input type="date" class="w-100" v-model="field['value']" :required="field['required'] == true">

                            </b-form-input>

                </b-form-group>
              </div>
             <div>
             		<b-form-group class="mb-2 d-flex flex-column" :label="'Source'" :class="'required'">
             			<b-form-select v-model="subscription_source" class="form-control" :options="['Manual Input', 'Public', 'Trade', 'Website', 'In Store']">

             			</b-form-select>

             		</b-form-group>
             </div>
    		<a class="btn btn-primary btn-rounded mt-3 btn-block" @click="addSubscriberToList()">
    			Add Subscriber
    		</a>
    	</b-form>
    	</b-card>
    </b-modal>
     <b-modal id="removeSubscriber" hide-footer centered>
     	<b-card class="shadow-none border-radius-1em border border-radius-1em mb-3"  v-if="(current_subscriber !== '')" >
     		<div class="border-bottom">
    			<label class="mt-2 mb-0">Subscriber ID</label>
    			<p class="mb-2">{{current_subscriber.uid}}</p>
    		</div>
    		<div class="border-bottom">
    			<label class="mt-2 mb-0">Name</label>
    			<p class="mb-2">{{current_subscriber.name}}</p>
    		</div>
    		<div class="border-bottom">
    			<label class="mt-2 mb-0">Email</label>
    			<p class="mb-2">{{current_subscriber.email}}</p>
    		</div>
    		<div class="d-flex border border-radius-1em p-3">
	    		<a class="btn btn-danger mx-1 btn-rounded flex-grow-1" @click="$bvModal.hide('removeSubscriber'), $bvModal.show('subscriberDetails')">
	    			Cancel
	    		</a>
	    		<a class="btn btn-success mx-1 btn-rounded flex-grow-1" @click="removeSubscriberFromList()">
	    			Confirm Deletion
	    		</a>
    		</div>
    	</b-card>
     </b-modal>
    <b-modal id="subscriberDetails" hide-footer centered>
    	<b-card class="shadow-none border-radius-1em border border-radius-1em mb-3"  v-if="(current_subscriber !== '') && (current_subscriber.customer !== null)" body-class="d-flex ">
    				<div>
    					<img class="avatar-md rounded-circle" v-if="current_subscriber.customer.avatar !== null" :src="'https://'+company.nickname+ '.s3.eu-west-2.amazonaws.com/'+ current_subscriber.customer.avatar">
    					<img class="avatar-md rounded-circle" v-else :src="'/images/product-placeholder.png'">
    				</div>
    				<div class="d-flex flex-column flex-grow-2 ml-3 position-relative">
    					<h4>{{current_subscriber.customer.name}} {{current_subscriber.customer.lastname}}</h4>
    					<a :href="'mailto:' + current_subscriber.customer.email">{{current_subscriber.customer.email}}</a>
    					<a class="btn btn-light btn-sm mr-auto mt-2" target="_blank" :href="'/customer/details/'+current_subscriber.customer.uid">
    					View Profile <i class="bx bx-arrow-right"></i>
    					</a>
    				</div>
    				
    		</b-card>
    	<b-card class="border-radius-1em border shadow-none">
    		<div class="border-bottom">
    			<label class="mt-2 mb-0">Subscriber ID</label>
    			<p class="mb-2">{{current_subscriber.uid}}</p>
    		</div>
    		<div v-for="field in current_subscriber.form_data" class="border-bottom">
    			<label class="mt-2 mb-0">
    				{{field.name}}
    			</label>
    			
    			<p class="mb-2" v-if="Array.isArray(field.value)">
    					<span v-for="item in field.value" class="badge badge-light bg-light mr-2 font-size-12">
    							{{item}}
	   					</span>
    			</p>
    			<p class="mb-2" v-else>{{field.value}}</p>
    		</div>
    		<div class="d-flex border-bottom mt-3 bg-light px-3 pt-3">
    			<h5>Actions</h5>
    		</div>
    		<div class="d-flex bg-light px-3 pb-3">
	    		<a class="btn btn-primary mx-1 btn-rounded flex-grow-1">
	    			Email Subscriber <i class="bx bx-mail-send"></i>
	    		</a>
	    		<a class="btn btn-dark mx-1 btn-rounded flex-grow-1" @click="$bvModal.show('removeSubscriber'), $bvModal.hide('subscriberDetails')">
	    			Remove from List <i class="bx bx-x text-danger"></i>
	    		</a>
    		</div>
    	</b-card>
    </b-modal>
	</Layout>
</template>
