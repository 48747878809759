<script type="text/javascript">
import SelectCompanyCategoryTreeChild from "./SelectCompanyCategoryTreeChild";
     export default{
        name: 'FilterListTree',
        components: {
            SelectCompanyCategoryTreeChild
        },
        props: {
            childs: Array,
            productcategories: Array,
            productid: Number,
            selectedid: Number,
            selectedcids: Array,
            category_data: {
                type: Array,
                default: () => [],
            }
        }, 
        beforeCreate(){
        
        //this.all_categories = this.$attrs.all_categories;
        },
        mounted(){
            if(this.category_data.length == 0){
            axios.get('/seller/product/categorytree').then(response => {
                     console.log(response);
                    this.all_categories = response.data;
                })
                this.clearCategories();
            }else{
                this.all_categories = this.category_data;
            }

            let self = this;
            //self.selectedids = self.selectedcids;
            this.$on('category-select', function(id){
                self.selectedid = id;
            });
            this.$on('categories-cleared', function(){
               self.selectedids = [];
            });
            
        },
        data: function() {
        return{
            storageurl: 'https://'+this.$attrs.company.nickname+'.s3.eu-west-2.amazonaws.com/',
            all_categories: [],
            //product_categories: this.productcategories,
            activeIds: [],
            testObject:[],
            selectedids: [],
            selectedchildids: [],
            childitems: '',
            success: '',
            product_id: this.productid,
            showFilters: false,
            selectallbtn: 1,
        }
        },
        methods:{
            addToCategory(id){
                /*if(this.selectedids.length == 0){
                    this.selectedids[0] = id;
                }else{
                this.selectedids.push(id);
                }*/
                this.$emit('category-added', id);
            },
            categorySelected(id){
                this.selectedid = id;
                this.$emit('category-select', id);
            },
             findIds(id){
                this.testObject = [];
                this.testObject = this.selectedids.filter(item => item === id);
                    if(this.testObject.length){
                        return "checked";
                    }else{
                        return false;
                    }

                },
            clearCategories(){
                this.$emit('categories-cleared');
            },
            filterRecords(){
                this.$emit('filter-records');
            },
            selectAll(){
                this.selectallbtn = 0;
                this.$emit('check-all-categories');
            }
        },
        computed: {
            parentCategories(){
                return this.all_categories.filter(item => item.parent_id == null)
            },
        checkAll: {
            get: function () {
                return this.all_categories ? this.selectedids.length == this.all_categories.length : false;
            },
            set: function (value) {
                var selected = [];
                if (value) {
                    this.all_categories.forEach(function (data) {
                        selected.push(data.id);
                        this.$emit('category-added', data.id);
                    });
                }else{
                   // this.$emit('categories-cleared');
                }
                this.selectedids = selected;
            }
        }
    },

    }
</script>

<template>
    <div>
    <div class="card shadow-none bg-transparent mb-0">
    <div class="card-body"><ul class="main-tree border-bottom">
        <li><div class="form-check d-flex"><input type="radio" v-bind:value="''" v-model="selectedid" class="form-check-input" @input="categorySelected('')" v-bind:id="0"><label class="custom-control-label" v-bind:for="0"><img class="avatar-xs rounded-circle" src="/images/product-placeholder.png"> <span>No Parent</span></label></div></li></ul>
        <ul class="main-tree mb-0 pl-0">
        <li v-for="category in all_categories" v-if="category.parent_id === null"><div class="form-check d-flex"><input type="radio" v-bind:value="category.id" v-model="selectedid" class="form-check-input" @input="categorySelected(category.id)" v-bind:id="category.id"><label class="custom-control-label" v-bind:for="category.id"><img v-if="category.image" :src="storageurl + category.image" class="avatar-xs rounded-circle"><img v-else class="avatar-xs rounded-circle" src="/images/product-placeholder.png"> <span>{{category.name}}</span></label></div><SelectCompanyCategoryTreeChild :childs="category.childs" :all_categories="all_categories" :productcategories="productcategories" :productid="product_id" :selectedcids="selectedid" @category-select="categorySelected" :company="$attrs.company"></SelectCompanyCategoryTreeChild></li></ul>

    <!--<div class="row"><div class="col-md-12"><h5>Active Categories</h5></div><div class="col-md-12"><ul class="list-group list-group-flush"><li class="list-group-item" v-for="selected in productcategories">{{selected.name}}</li></ul></div></div>-->
                <!--<b-form-checkbox v-model="checkAll" v-if="selectallbtn == 1" class="float-left" @click="selectAll()">Select All</b-form-checkbox>-->
             
</div>
</div>
 <!--<a href="javascript:;" class="float-right" @click="clearCategories()">Clear</a>-->
 </div>
</template>