<template>
	<b-tab>
		<template v-slot:title>
			<span class="d-inline-block d-sm-none">
				<i class="bx bx-notepad"></i>
			</span>
			<span class="d-none d-sm-inline-block">Access</span>
		</template>
		<div class=""  :class="{'widget-scrollbox widget-inner-scrollbox' : widgetbox == true}"  :style="[{'max-height' : sidePanelMaxHeight+'px', height: ( !widgetbox ? 'auto' : ''+sidePanelMaxHeight+'px')}]" >
				<b-card class="border shadow-none w-100 mb-0" body-class="p-0">
					<div class="d-flex flex-column" style="padding: 20px;
    border-radius: 0 10px 10px 0px;"><h5>Device Visibility</h5>
					
				<div @click="toggleViewSize('xl')" class="d-flex w-100 mb-2 bg-light border-radius-1em p-1"><a v-b-tooltip.hover.top title="Desktop" class="btn btn-primary mr-1 rounded-circle my-auto" @click="toggleViewSize('xl')" :class="[visibility.size.includes('xl') ?  'bg-success border-success' : 'bg-danger border-danger']">
					<i v-if="visibility.size.includes('xl')" class="bx bx-desktop text-white font-size-16 my-1"></i>
					<i v-else class="bx bx-hide text-white font-size-16 my-1"></i>
				</a>
				<a class="d-flex justify-content-center align-items-end" v-b-tooltip.hover.top title="Desktop"  @click="toggleViewSize('xl')">
					<span class="my-auto ml-3">Desktop</span>
				</a>
				</div>
					<div @click="toggleViewSize('lg')" class="d-flex w-100 mb-2  bg-light border-radius-1em p-1"><a v-b-tooltip.hover.top title="Laptop" class="btn btn-primary mr-1 rounded-circle my-auto" @click="toggleViewSize('lg')" :class="[visibility.size.includes('lg') ?  'bg-success border-success' : 'bg-danger border-danger']">
					<i v-if="visibility.size.includes('lg')" class="bx bx-laptop text-white font-size-16 my-1"></i>
					<i v-else class="bx bx-hide text-white font-size-16 my-1"></i>
				</a>
				<a class="d-flex justify-content-center align-items-end" v-b-tooltip.hover.top title="Desktop"  @click="toggleViewSize('lg')">
					<span class="my-auto ml-3">Laptop</span>
				</a>
				</div>
				<div @click="toggleViewSize('md')" class="d-flex w-100 mb-2  bg-light border-radius-1em p-1"><a v-b-tooltip.hover.top title="Tablet" class="btn btn-primary mr-1 rounded-circle my-auto" @click="toggleViewSize('md')" :class="[visibility.size.includes('md') ?  'bg-success border-success' : 'bg-danger border-danger']">
					<i v-if="visibility.size.includes('md')" class="bx bx bx-tab text-white font-size-16 my-1"></i>
					<i v-else class="bx bx-hide text-white font-size-16 my-1"></i>
				</a>
				<a class="d-flex justify-content-center align-items-end" v-b-tooltip.hover.top title="Desktop"  @click="toggleViewSize('md')">
					<span class="my-auto ml-3">Tablet</span>
				</a>
				</div>
				<div @click="toggleViewSize('sm')" class="d-flex w-100 mb-2  bg-light border-radius-1em p-1"><a v-b-tooltip.hover.top title="Large Mobile" class="btn btn-primary mr-1 rounded-circle my-auto" @click="toggleViewSize('sm')" :class="[visibility.size.includes('sm') ?  'bg-success border-success' : 'bg-danger border-danger']">
					<i v-if="visibility.size.includes('sm')" class="bx bx bx-mobile-alt text-white font-size-16 my-1"></i>
					<i v-else class="bx bx-hide text-white font-size-16 my-1"></i>
				</a>
				<a class="d-flex justify-content-center align-items-end" v-b-tooltip.hover.top title="Desktop"  @click="toggleViewSize('sm')">
					<span class="my-auto ml-3">Large Mobile</span>
				</a>
				</div>
				<div @click="toggleViewSize('xs')" class="d-flex w-100 mb-2  bg-light border-radius-1em p-1"><a v-b-tooltip.hover.top title="Small Mobile" class="btn btn-primary mr-1 rounded-circle my-auto" @click="toggleViewSize('xs')" :class="[visibility.size.includes('xs') ?  'bg-success border-success' : 'bg-danger border-danger']">
					<i v-if="visibility.size.includes('xs')" class="bx bx-mobile text-white font-size-16 my-1"></i>
					<i v-else class="bx bx-hide text-white font-size-16 my-1"></i>
				</a>
				<a class="d-flex justify-content-center align-items-end" v-b-tooltip.hover.top title="Desktop"  @click="toggleViewSize('xs')">
					<span class="my-auto ml-3">Small Mobile</span>
				</a>
				</div>
	</div>
				</b-card>
				<b-card class="border shadow-none w-100" body-class="p-0">
					<div class="d-flex flex-column" style="padding: 20px;
    border-radius: 0 10px 10px 0px;"><h5>User Logged-in</h5>
					<div @click="visibility.authenticated = !visibility.authenticated" class="d-flex w-100 mb-2 bg-light border-radius-1em p-1"><a v-b-tooltip.hover.top title="Desktop" class="btn btn-primary mr-1 rounded-circle my-auto" @click="visibility.authenticated = !visibility.authenticated" :class="[visibility.authenticated == true ?  'bg-warning border-warning' : 'bg-info border-info']">
					<i v-if="visibility.authenticated == true" class="bx bx-lock-alt text-white font-size-16 my-1"></i>
					<i v-else class="bx bx-group text-white font-size-16 my-1"></i>
				</a>
				<a class="d-flex justify-content-center align-items-end" v-b-tooltip.hover.top title="Desktop"  @click="visibility.authenticated = !visibility.authenticated">
					<span v-if="visibility.authenticated == false" class="my-auto ml-3">All Visitors</span>
					<span v-if="visibility.authenticated == true" class="my-auto ml-3">Authenticated Only</span>
				</a>
				</div>
			</div>
				</b-card>
			</div>
		</b-tab>
</template>

<script type="text/javascript">


	export default{
		name: 'visibilityTab',
		data() {
			return {
				sidePanelMaxHeight: 420,
				widgetbox: true,
			}
		},
		created(){
			window.addEventListener("resize", this.windowResize);
		},
		destroyed() {
            window.removeEventListener("resize", this.windowResize);
        },
		mounted(){
        	this.sidePanelMaxHeight = window.innerHeight - 150;
        },
		
		props: {
			visibility: {
				type: Object,
				default:() => ''
			}
		},
		watch: {
			computedStyles: {
		      handler(newVal, oldVal) {
		        //console.log('Styles changed:', newVal);
		        this.$emit('visibility-updated', newVal)
		        // Perform any desired actions when styles change
		      },
		      deep: true
		    }
		},
		computed:{
			activeVariant(){
				var active = this.variants.filter(item => item.element == this.element);
				return active[0];
			},
			computedStyles() {
		      return this.visibility;
		    }
		},
		methods:{
			windowResize(e) {
            console.log(window.innerHeight);
    
            this.sidePanelMaxHeight = window.innerHeight - 150;
   
           
          	},
			toggleViewSize(togglesize){
				var index = this.visibility.size.indexOf(togglesize);
				if(index > -1){
					this.visibility.size.splice(index, 1);
				}else{
					this.visibility.size.push(togglesize);
				}
			}
		}
	}

</script>