<script type="text/javascript">
  import PublicListSubscriptionWidget from '../components/widgets/ecommerce/public/PublicListSubscriptionWidget';
  export default {
    components: { PublicListSubscriptionWidget },
    data() {
      return {
        collapse_title: '',
        subscription: {
          name: '',
          lastname: '',
          email: '',
          phone: '',
          customer_id: '',
          list_id: 1,
        }
      }
    },
    created(){
        this.checkUser();
    },
    mounted(){
        this.checkUser();
    },
    watch: {
      $route() {
      this.$nextTick(() => {
        this.checkUser();
      })
      }
    },
    methods: {
      checkUser(){
        if(this.user !== ''){
          this.subscription.email = this.$attrs.user.email;
          this.subscription.id = this.$attrs.user.id;
        }
      }
    }

  }

</script>

<template>
<div>
   <section class="p-0 padded-section"  :class="{'bg-soft bg-light header-row' : $route.name !== 'About'}" v-if="$route.name !== 'NotFound'" >
  <div class="container-fullwidth" style="
    padding: 120px 0;" :style="{'backdrop-filter: saturate(0.6); background: linear-gradient(45deg, rgba(94, 59, 174,0.15), rgba(239, 242, 247, 0.25))' : $route.name !== 'About'}">
    <!--<PageHeader :title="title" :items="items" />-->
    <!--<b-row class="py-4 mt-4">
      <b-col md="10" class="mx-auto">
        <b-card class="shadow-none h-100 bg-transparent" body-class="d-flex flex-column justify-content-center align-items-start">
        <p>From the Blog</p>
        <h2>Fresh Off The Press</h2>
        <i class="bx bx-right-arrow-circle text-primary font-size-20"></i>
      </b-card>
      </b-col>
     <b-col md="10" class="mx-auto">
        <MiniCardCarousel :items="items2" :grid_limit="3" :end="3" :layout_type="'vertical'" :autoplay="false"></MiniCardCarousel>
      </b-col>
    </b-row>-->
    <b-row class="newsletter-row">
      <!--<b-col md="10" class="d-flex flex-column justify-content-center align-items-center text-center mx-auto">
        <p class="mb-1 text-muted section-info-preheader cursive-title">Join us...</p>
        <h2 class="section-info-header position-relative">Become a Gltrbox Seller</h2>
        <p class="text-left text-dark">
            Join an exclusive group of online jewellery retailers selling on Gltrbox from Gemesys. 
            Sign up today for a Gemesys account and start selling with us. 
          </p>
      </b-col>-->
      <b-col lg="6" class="mx-auto d-flex flex-column">
      <p class="mb-1 text-muted mx-auto text-center section-info-preheader cursive-title mt-2">Subscribe to Our Newsletter</p>
        <h2 class="section-info-header section-info-header position-relative mx-auto text-center">Get Exclusive News & Jewellery Drops</h2>
         <p class="text-dark font-size-16 text-left text-sm-center text-md-left" >
           <!--<b-form-group label="" class="mt-2">
           <b-form-input type="email" placeholder="Enter your email address..." v-model="subscription.email">
           </b-form-input>
          
          </b-form-group>-->

           <PublicListSubscriptionWidget :user="$attrs.user" :list_id="1" :recaptcha="$attrs.recaptcha_token" ></PublicListSubscriptionWidget>
          </p>
       
    </b-col>
    </b-row>
    <!-- end row -->
  </div>
</section>
<section class="bg-primary">
  <b-row >
      <b-col md="10" class="d-flex flex-column justify-content-center align-items-center mx-auto">
        <ul class="list-inline my-3">
          <li class="list-inline-item social-icon"><a href="#"><i class="bx bxl-facebook text-white font-size-16"></i></a></li>
          <li class="list-inline-item social-icon"><a href="#"><i class="bx bxl-twitter text-white font-size-16"></i></a></li>
          <li class="list-inline-item social-icon"><a href="#"><i class="bx bxl-instagram text-white font-size-16"></i></a></li>
          <li class="list-inline-item social-icon"><a href="#"><i class="bx bxl-pinterest text-white font-size-16"></i></a></li>
          <li class="list-inline-item social-icon"><a href="#"><i class="bx bxl-linkedin text-white font-size-16"></i></a></li>
        </ul>
      </b-col>
    </b-row>
</section>
  <section class="bg-white footer-links">
  <b-container fluid>
  <b-row style="padding: 50px 0 90px 0;">
    <b-col md="3" class="footer-links-block">
      <b-list-group flush class="text-left bg-transparent">
        <b-list-group-item class="border-none bg-transparent" @click="collapse_title = 'company'">
         <h5>Company Information</h5>
        </b-list-group-item>
        <div class="d-md-block" :class="{'d-block': collapse_title == 'company', 'd-none': collapse_title !== 'company'}">
        <b-list-group-item class="border-none bg-transparent" :to="'/about'">
          About us
        </b-list-group-item>
        
        <b-list-group-item class="border-none bg-transparent" :to="'/privacy-policy'">
          Privacy Policy
        </b-list-group-item>
        <b-list-group-item class="border-none bg-transparent" :to="'/terms-and-conditions'">
          Terms and Conditions
        </b-list-group-item>
        <b-list-group-item class="border-none bg-transparent" :to="'/contact'">
          Contact us
        </b-list-group-item>
      </div>
      </b-list-group>
    </b-col>
     <b-col md="3" class="footer-links-block">
      <b-list-group flush class="text-left bg-transparent">
        <b-list-group-item class="border-none bg-transparent" @click="collapse_title = 'shop'">
         <h5>Shop</h5>
        </b-list-group-item>
         <div class="d-md-block" :class="{'d-block': collapse_title == 'shop', 'd-none': collapse_title !== 'shop'}">
         <b-list-group-item class="border-none bg-transparent"  to="/category/jewellery">
          All Jewellery
        </b-list-group-item>
        <b-list-group-item class="border-none bg-transparent"  to="/categories">
          All Categories
        </b-list-group-item>
        <b-list-group-item class="border-none bg-transparent" to="/sellers">
          All Sellers
        </b-list-group-item>
        <b-list-group-item class="border-none bg-transparent"  to="/collections">
          Our Collections
        </b-list-group-item>
        <!--<b-list-group-item class="border-none bg-transparent" >
          News
        </b-list-group-item>-->
       </div>
      </b-list-group>
    </b-col>
     <b-col md="3" class="footer-links-block">
      <b-list-group flush class="text-left bg-transparent">
        <b-list-group-item class="border-none bg-transparent" to="/my-account" @click="collapse_title = 'account'">
         <h5>Account</h5>
        </b-list-group-item>
        <div class="d-md-block" :class="{'d-block': collapse_title == 'account', 'd-none': collapse_title !== 'account'}">
        <b-list-group-item class="border-none bg-transparent" to="/my-account">
          Login as Buyer
        </b-list-group-item>
        <b-list-group-item class="border-none bg-transparent" >
          Register as Buyer
        </b-list-group-item>
        <b-list-group-item class="border-none bg-transparent" href="https://app.gemesys.co.uk/login">
          Login as Seller
        </b-list-group-item>
        <b-list-group-item class="border-none bg-transparent" href="https://app.gemesys.co.uk/register">
           Create Seller Account
        </b-list-group-item>
      </div>
      </b-list-group>
    </b-col>
     <b-col md="3" class="text-left footer-links-block">
      <div class="px-3">
      <h5 class="mt-3 mb-4">Contact Information</h5>
      <address>
        Gemesys Ltd.<br>
        Unit 1c Eagle Industrial Estate<br>Church Green<br>Witney<br>Oxfordshire<br>United Kingdom<br>OX28 4YR
      </address>
      <a class="" >
          marketplace@gemesys.co.uk
        </a><br>
        <a class="">
          +44 (0)20 000 0000
        </a>
      <!--<ul class="list-inline my-2">
          <li class="list-inline-item social-icon border-primary"><a href="#"><i class="bx bxl-facebook text-primary font-size-16"></i></a></li>
          <li class="list-inline-item social-icon border-primary"><a href="#"><i class="bx bxl-twitter text-primary font-size-16"></i></a></li>
          <li class="list-inline-item social-icon border-primary"><a href="#"><i class="bx bxl-instagram text-primary font-size-16"></i></a></li>
          <li class="list-inline-item social-icon border-primary"><a href="#"><i class="bx bxl-pinterest text-primary font-size-16"></i></a></li>
          <li class="list-inline-item social-icon border-primary"><a href="#"><i class="bx bxl-linkedin text-primary font-size-16"></i></a></li>
        </ul>-->
        </div>
    </b-col>
  </b-row>
</b-container>
</section>
  <footer class="footer bg-white border">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-6">{{ new Date().getFullYear() }} © Gemesys.</div>
        <div class="col-sm-6">
          <div class="text-sm-end d-none d-sm-block">Design & Developed by Gemesys Labs</div>
        </div>
      </div>
    </div>
  </footer>
  <!-- end footer -->
</div>
</template>
