export const menuItems = [
           {
               id: 1,
               label: "menuitems.menu.text",
               isTitle: true
           },
           {
               id: 2,
               label: "menuitems.dashboards.text",
               icon: "bx-home-circle",
               link: "/"
           }
     ];