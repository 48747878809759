<script>
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
//import 'swiper/css/swiper.css'
/**
 * Dashboard Component
 */
export default {
  components: {
    Swiper,
    SwiperSlide
  },
  directives: {
    swiper: directive
  },
  data() {
    return {
    	hoverIndex: -1,
    	storageurl: '.s3.eu-west-2.amazonaws.com',
    	linkprefix: '',
    	linksuffix: '',
    	quickviewproduc: '',
    };
  },
  props: {
  	customer: {
  		type: Object,
  		default: ''
  	},
  	use: {
  		type: String,
  		default: '',
  	},
  	nickname: {
  		type: String,
  		default: '',
  	},
  	items: {
  		type: Array,
  		default: () => []
  	},
  	swiperOptions: {
				type: Object,
				default: () => {
          // Some Swiper option/callback...
        },
			},
		startSlide: {
			type: Number,
			default: () => 1
		},
		roundedBorders: {
			type: Boolean,
			default: () => true
		},
		selected_currency_symbol: {
			type: String,
			default: '£'
		},
		selected_currency: {
			type: String,
			default: 'GBP'
		},
		exchange_rates: {
			type: Object, 
			default: {},
		}
  },
  computed: {
      swiper() {
        return this.$refs.mySwiper.$swiper
      }
    },
    methods: {
    	addTax(product, price){
        		var tax_rule = product.tax_rule;
        		console.log(tax_rule);
		    	if((this.customer !== null && this.customer !== '') && (this.customer.default_delivery_address !== '' && this.customer.default_delivery_address !== null)){
		    		var country_id = this.customer.default_delivery_address.country_id;
		    	}else if(product.company !== null){
		    		var country_id = product.company.country_id;
		    	}
		    	if(tax_rule !== null && tax_rule.country_rules.length > 0){
		    		var selected_rule = null;
		    		var selected_ruleIndex = -1;
			    	for(var ti = 0; ti < tax_rule.country_rules.length; ti++){
			    		selected_ruleIndex = tax_rule.country_rules[ti].countries.findIndex(item => item == country_id);
			    		if(selected_ruleIndex !== -1){
			    			selected_rule = tax_rule.country_rules[ti];
			    			ti = tax_rule.country_rules.length;
			    		}
			    	}
			    	console.log(selected_rule);
			    	if(tax_rule.type == 1){
		        		if(selected_rule !== null){
		        			var percentage = (parseFloat(selected_rule.value) / 100) + 1;
		        			return price * percentage;
		         		}else{
		         			return (price * 1.2).toFixed(2);
		         		}
	         		}else{
	         			if(selected_rule !== null){
		        			return price + selected_rule.value;
		         		}else{
		         			return (price * 1.2).toFixed(2);
		         		}
	         		}
         		}else if(tax_rule !== null && tax_rule.country_rules.length > 0){
         			var percentage = (parseFloat(tax_rule.percentage) / 100) + 1;
		        	return price * percentage;
         		}else {
		        	return (price * 1.2).toFixed(2);
         		}
        	},
    },
   mounted() {
   	if(this.use !== ''){
   		if(this.use == 'public'){
   			this.linkprefix = '/seller/';
   			this.linksuffix = '/product';
   			this.storageurl = '.s3.eu-west-2.amazonaws.com';
   		}else if(this.use == 'trade'){
   			this.linkprefix = '/trade/profile/';
   			this.linksuffix = '';
   			//this.storageurl = '/trade-image';
   		}else if(this.use == 'website'){
   			this.linkprefix = '';
   			this.linksuffix = '/product';
   			//this.storageurl = '/public-image';
   		}
   	}
    console.log('Current Swiper instance object', this.swiper);
      this.swiper.slideTo(this.startSlide, 1000, false);
  },
};
</script>

<template>
	<swiper ref="mySwiper" :options="swiperOptions">
        <swiper-slide v-for="(item, index) in items">
            <b-card class="shadow-none border border-radius-1em" no-body  @mouseenter="hoverIndex = index" @mouseleave="hoverIndex = -1" v-if="use == 'public' || user == 'website'">
            	<transition name="fade-top">
	            	<div class="col-md-12 position-absolute z-index-2 d-flex" v-if="hoverIndex == index"> 
	                    <a id="tooltip-add"  @click="quickviewproduct = product" class="mt-3 ml-3 btn btn-primary font-size-16 btn-rounded ml-auto float-left"><i class="mdi mdi-eye-outline"></i></a>
	                    <a id="tooltip-add"  @click="$emit('add-to-wishlist')"class="mr-2 ml-auto btn btn-primary font-size-16 mt-auto btn-rounded"><i class="mdi mdi-heart-outline"></i></a>
	                    <!--<a id="tooltip-add"  @click="selectedCategory = list, showStoneDetails = 1"class="mr-3 ml-0 btn btn-primary font-size-16 mt-auto btn-rounded"><i class="mdi mdi-shopping-outline"></i></a>-->
	                 </div>
             	</transition>

             	<router-link v-if="item.cover_image !== null" :to="linkprefix+(item.company !== null && use == ('public') ? item.company.nickname : '' )+linksuffix+'/'+item.url_rewrite">
            	<b-card-img-lazy v-if="'gallery' in item.cover_image" :src="'https://'+item.company.nickname+storageurl+item.cover_image.gallery+(use == 'public' ? '?s='+item.company.nickname+'' : '')" :class="{'border-radius-1em' : roundedBorders}"></b-card-img-lazy>
            	<b-card-img-lazy v-else-if="'thumb' in item.cover_image" :src="'https://'+item.company.nickname+storageurl+item.cover_image.thumb+(use == 'public' ? '?s='+item.company.nickname+'' : '')" :class="{'border-radius-1em' : roundedBorders}"></b-card-img-lazy>
            	<b-card-img-lazy v-else-if="'src' in item.cover_image" :src="'https://'+item.company.nickname+storageurl+item.cover_image.src+(use == 'public' ? '?s='+item.company.nickname+'' : '')" :class="{'border-radius-1em' : roundedBorders}"></b-card-img-lazy>
            	<b-card-img-lazy v-else :src="'https://'+item.company.nickname+storageurl+item.cover_image" :class="{'border-radius-1em' : roundedBorders}"></b-card-img-lazy>
            	</router-link>
            	<router-link v-else :to="linkprefix+(item.company !== null && use == 'public' ? item.company.nickname : '' )+linksuffix+'/'+item.url_rewrite">
            	<b-card-img-lazy src="/images/product-placeholder.png" :class="{'border-radius-1em' : roundedBorders}"></b-card-img-lazy>
            	</router-link>
				<div class="mt-auto mb-3 mx-2 d-flex position-absolute bottom-0 right-0 left-0">
					<transition name="fade-left">
					<span class="badge badge-light my-auto mr-auto bg-light p-2 font-size-12 border-radius-1em ml-2"  v-if="hoverIndex == index">
		                         	<!--<span class="currency">£</span> {{(use == 'trade' ? addTax(item, item.trade_price).toFixed(2) : addTax(item, item.sales_price).toFixed(2))}}-->

		                         	<span class="currency">{{selected_currency_symbol}}</span> {{(addTax(item, (use == 'trade' ? item.trade_price : item.sales_price)) * (1 / exchange_rates[item.company.trading_currency_code])).toFixed(2)}}
		                         </span>
		                         </span>
		                     </transition>
                    <transition name="fade-right">
		                         <p  v-if="(hoverIndex == index) && ('reviews_avg_rating' in item)" class="text-muted float-right ml-auto my-auto mr-3">
		                         	<b-form-rating style="width: 40%;" id="rating-inline" readonly variant="warning" class="bg-transparent pl-4 ml-auto" inline no-border size="sm" :value="item.reviews_avg_rating"></b-form-rating> 
			                   
			                  </p>
			              			</transition>
              	</div>
            </b-card>
            <b-card class="shadow-none border border-radius-1em" no-body  @mouseenter="hoverIndex = index" @mouseleave="hoverIndex = -1" v-else-if="use == 'trade'">
            	<transition name="fade-top">
	            	<div class="col-md-12 position-absolute z-index-2 d-flex" v-if="hoverIndex == index"> 
	                    <a id="tooltip-add"  @click="quickviewproduct = product" class="mt-3 ml-3 btn btn-primary font-size-16 btn-rounded ml-auto float-left"><i class="mdi mdi-eye-outline"></i></a>
	                    <a id="tooltip-add"  @click="$emit('add-to-wishlist')"class="mr-2 ml-auto btn btn-primary font-size-16 mt-auto btn-rounded"><i class="mdi mdi-heart-outline"></i></a>
	                    <!--<a id="tooltip-add"  @click="selectedCategory = list, showStoneDetails = 1"class="mr-3 ml-0 btn btn-primary font-size-16 mt-auto btn-rounded"><i class="mdi mdi-shopping-outline"></i></a>-->
	                 </div>
             	</transition>

             	<a v-if="item.cover_image !== null" :href="linkprefix+(item.company !== null && use == ('trade') ? item.company.nickname : '' )+linksuffix+'/'+item.url_rewrite">
            	<b-card-img-lazy v-if="'gallery' in item.cover_image" :src="'https://'+item.company.nickname+storageurl+item.cover_image.gallery+(use == 'trade' ? '?s='+item.company.nickname+'' : '')" :class="{'border-radius-1em' : roundedBorders}"></b-card-img-lazy>
            	<b-card-img-lazy v-else-if="'thumb' in item.cover_image" :src="'https://'+item.company.nickname+storageurl+item.cover_image.thumb+(use == 'trade' ? '?s='+item.company.nickname+'' : '')" :class="{'border-radius-1em' : roundedBorders}"></b-card-img-lazy>
            	<b-card-img-lazy v-else-if="'src' in item.cover_image" :src="'https://'+item.company.nickname+storageurl+item.cover_image.src+(use == 'trade' ? '?s='+item.company.nickname+'' : '')" :class="{'border-radius-1em' : roundedBorders}"></b-card-img-lazy>
            	<b-card-img-lazy v-else :src="'https://'+item.company.nickname+storageurl+item.cover_image+(use == 'trade' ? '?s='+item.company.nickname+'' : '')" :class="{'border-radius-1em' : roundedBorders}"></b-card-img-lazy>
            	</a>
            	<a v-else :href="linkprefix+(item.company !== null && use == 'trade' ? item.company.nickname : '' )+linksuffix+'/'+item.url_rewrite">
            	<b-card-img-lazy src="/images/product-placeholder.png" :class="{'border-radius-1em' : roundedBorders}"></b-card-img-lazy>
            	</a>
				<div class="mt-auto mb-3 mx-2 d-flex position-absolute bottom-0 right-0 left-0">
					<transition name="fade-left">
					<span class="badge badge-light my-auto mr-auto bg-light p-2 font-size-12 border-radius-1em ml-2"  v-if="hoverIndex == index">
		                         	<span class="currency">{{selected_currency_symbol}}</span> {{(addTax(item, (use == 'trade' ? item.trade_price : item.sales_price)) * (1 / exchange_rates[item.company.trading_currency_code])).toFixed(2)}}
		                         </span>
		                     </transition>
                    <transition name="fade-right">
		                         <p  v-if="(hoverIndex == index) && ('reviews_avg_rating' in item)" class="text-muted float-right ml-auto my-auto mr-3">
		                         	<b-form-rating style="width: 40%;" id="rating-inline" readonly variant="warning" class="bg-transparent pl-4 ml-auto" inline no-border size="sm" :value="item.reviews_avg_rating"></b-form-rating> 
			                   
			                  </p>
			              			</transition>
              	</div>
            </b-card>
        </swiper-slide>
    	<div class="swiper-pagination" slot="pagination"></div>
  	</swiper>
</template>