<script>
import Layout from '../../layouts/main'
import PageHeader from '../../components/page-header'

import SideSlidePanel from "../../components/widgets/SideSlidePanel"
import SearchBar from "../../components/widgets/searchBar"
import EditModal from "../../components/widgets/editModal"
import SearchCompanySupplier from "../../components/widgets/SearchCompanySupplier"
import SearchCompanyLocation from "../../components/widgets/SearchCompanyLocation"
import SearchCompanyPaymentMethod from "../../components/widgets/SearchCompanyPaymentMethod"
import SearchCompanyPaymentTerm from "../../components/widgets/SearchCompanyPaymentTerm"
import FilterCompanySupplierAddresses from "../../components/widgets/FilterCompanySupplierAddresses"
/**
 * Shops component
 */
export default {
  components: { Layout, PageHeader, SideSlidePanel, SearchBar, EditModal, SearchCompanySupplier, SearchCompanyLocation, SearchCompanyPaymentMethod, SearchCompanyPaymentTerm, FilterCompanySupplierAddresses },
  data() {
    return {
      shopsData: '',
      statuses: [
          {text: 'Draft', value: 1},
          {text: 'Published', value: 2},
          {text: 'Sent to Merchant', value: 3},
          {text: 'Pending Payment', value: 4},
          {text: 'Paid', value: 5},
          {text: 'Goods Recieved', value: 6},
          {text: 'Goods Stocked', value: 7},
          {text: 'Complete', value: 8},
      ],
      supplierSearchBox: true,
      productsData: [],
      supplierSelected: true,
      supplierAddressLoading: false,
      selectedsupplieraddresses: [],
      showListingProducts: 0,
      addProductToListing: 0,
      selectedListing:[],
      searchproduct: '',
      error_reponse: [],
      error_message: false,
      success_message: false,
      success_text: '',
      loadingForm: false, 
      loadingList: false,
      shops_error: [],
      company_info: [],
      categoryProducts: [],
      productsLimit: '100',
      productsCurrentPage: 1,
      productListingSelected: [],
      newquantity: 0,
      listingquantity: 0,
      transferquantity: 0,
      currentPage: 1,
      orderDesc: false,
      priceRange: [0,999999],
      trade_productsactive: '',
      trade_active: '',
      public_active: '',
      public_productsactive: '',
      totalListingsQuantity: 0,
      editModal: 0,
      productsactive: '',
      brands: '',
      collections: '',
      active: '',
      view: 'list',
      search: '',
      limit: '25',
      sortby: 'id',
      order: 'desc',
      item_errors: [],
      isCheckAll: false,
      selectedids: [],
      addNewPanel: 0,
      tax_rules: [],
      isCustom: 0,
      editorderitem: null,
      newitems: [
          { name: '', 
            description: '',
            price_exc: 0.00,
            price_inc: 0.00, quantity: 1, tax_rule: null, editable: 1,
              line_item_total: 0.00},
        ],
      newpurchaseorder: {
        id: null,
        purchase_order_no: '',
        items: [
          {title: '',
          description: '',
           price_exc: 0.00, 
           price_inc: 0.00,
           quantity: 1, 
           tax_rule: null, 
           editable: 1,
            line_item_total: 0.00},
        ],
        order_notes: '',
        delivery_location_id: 0,
        company_supplier_id: 0,
        supplier: '',
        company_supplier_address_invoice_id: 0,
        total_inc: 0.00,
        total_exc: 0.00,
        total_taxes: 0.00,
        quantity: 1,
        tax_percentage: "0.00",
        tax_rule_id: 0,
        payment_method_id: 0, 
        payment_status_id: 0,
        payment_date: '',
        delivery_date: '',
        company_id: 0,
        order_status_id: 1,
        total_paid: 0.00,
        pdf: 0,
        email: 0
      },
      editpurchaseorder: {
        id: null,
        reference: '',
        items: [
          {name: '', description: '', price_exc: 0.00, price_inc: 0.00, quantity: 1, tax_rule_id: 0, tax_percentage: "0.00", editable: 1,
            line_item_total: 0.00},
        ],
        order_notes: '',
        delivery_location_id: 0,
        company_supplier_id: 0,
        company_supplier_address_invoice_id: 0,
        total_inc: 0.00,
        total_exc: 0.00,
        total_taxes: 0.00,
        quantity: 1,
        tax_percentage: "0.00",
        tax_rule_id: 0,
        payment_method_id: 0, 
        payment_status_id: 0,
        payment_date: '',
        company_id: 0,
        order_status_id: 1,
        total_paid: 0.00,
        pdf: 0,
        email: 0
      },
      fields: [{ id: 1 }],
      dropzoneOptions: {
            url: 'https://httpbin.org/post',
            thumbnailHeight: 300,
            thumbnailWidth: 300,
          },
          limits: [
            {value: 25, text: "25"},
            {value: 50, text: "50"},
            {value: 100, text: "100"},
          ],
          transferOptions: [
            {value: 0, text: "Company Stock"},
            {value: 1, text: "Another Shop or Listing"},
          ],
          transferOption: 0,
      title: 'Purchases',
      items: [
        {
          text: 'Stock',
          href: '/',
        },
        {
          text: 'Purchases',
          active: true,
        },
      ],
    }
  },
  methods: {
    saveItem(index){
      var errors = [];
      if(this.newpurchaseorder.id == null){
        var field = 'newpurchaseorder';
      }
      if(this.$data[field].items[index].name == ''){
        errors.push('Add item name');
      }
      if(this.$data[field].items[index].description == ''){
         errors.push('Add item description');
      }
      if(this.$data[field].items[index].quantity < 1){
         errors.push('Item quantity must be more than 1');
      }
      if(this.$data[field].items[index].price_exc < 0.00){
         errors.push('Price must be more than 1');
      }
      if(this.$data[field].items[index].price_inc < 0.00){
         errors.push('Price must be more than 0.00');
      }
      if(this.$data[field].items[index].line_item_total < 0.00){
         errors.push('Price must be more than 0.00');
      }
      if(this.$data[field].items[index].tax.tax_rule_id == 0){
         errors.push('Price must be more than 1');
      }
      if(errors.length == 0){
       this.$data[field].items[index].editable = 0;
      }else{
        this.item_errors = errors;
      }
    },
    AddformData() {
      this.newpurchaseorder.items.push(
          {title: '',
          description: '',
           price_exc: 0.00, 
           price_inc: 0.00,
           quantity: 1, 
           tax_rule: null, 
           editable: 1,
            line_item_total: 0.00},
        );
    },
    resetPurchaseData(){
      this.newpurchaseorder = {
        id: null,
        purchase_order_no: '',
        items: [
          {title: '',
          description: '',
           price_exc: 0.00, 
           price_inc: 0.00,
           quantity: 1, 
           tax_rule: null, 
           editable: 1,
            line_item_total: 0.00},
        ],
        order_notes: '',
        delivery_location_id: 0,
        company_supplier_id: 0,
        supplier: '',
        company_supplier_address_invoice_id: 0,
        total_inc: 0.00,
        total_exc: 0.00,
        total_taxes: 0.00,
        quantity: 1,
        tax_percentage: "0.00",
        tax_rule_id: 0,
        payment_method_id: 0, 
        payment_status_id: 0,
        payment_date: '',
        delivery_date: '',
        company_id: 0,
        order_status_id: 1,
        total_paid: 0.00,
        pdf: 0,
        email: 0
      };
    },
    addressAdded(){
      this.success_text = 'Congratulations, your new category has been added.';
        this.success_message = true;
      /*this.supplierSearchBox = false;
      this.selectSu(newpurchaseorder.supplier);
      let self = this;
              setTimeout(function(){
                self.supplierSearchBox = true;
              }, 500);*/
    },
    /**
     * Delete the row
     */
    deleteRow(index) {
      if (confirm("Are you sure you want to delete this element?"))
        this.newpurchaseorder.items.splice(index, 1);
    },
    getTaxRules(){
      axios.get('/api/company/order/tax-rules?limit=0&sortby='+this.sortby+'&order='+this.order, {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
        console.log(response);
        this.tax_rules = response.data;
      }).catch(error => {
        this.error_reponse = {error: ["Could Not Load Tax Rules"]};
        this.error_message = true;
      });
    },
    brandQuery(){
      axios.get('/api/company/product/purchases?page='+this.currentPage+'&limit='+this.limit+'&sortby='+this.sortby+'&order='+this.order+'&search='+this.search, {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
        this.shopsData = response.data;
      }).catch(error => {
        this.shops_error = error.data;
      });
    },
    /*validatePurchaseOrderItems(){
      this.loadingForm = true;
      this.error_reponse = {};
      for(var i = 0; i < this.newpurchaseorder.items.length; i++){
        if(this.newpurchaseorder.items[i].name == ''){
          this.error_reponse.name = ["Please enter item name for the "+i+" order item"];
        }
        if(this.newpurchaseorder.items[i].description == ''){
          this.error_reponse.description = ["Please enter item description for the "+i+" order item"];
        }
        if(this.newpurchaseorder.items[i].price_inc == ''){
          this.error_reponse.price_inc = ["Please enter item price (inc VAT) for the "+i+" order item"];
        }
        if(this.newpurchaseorder.items[i].price_exc == ''){
          this.error_reponse.price_exc = ["Please enter item price (exc VAT) for the "+i+" order item"];
        }
        if(this.error_reponse.length == 0){
          this.addPurchaseOrder();
        }else{
          this.error_message = true;
          let self = this;
          setTimeout(function(){
            self.loadingForm = false;
          }, 500);
        }
      }  
    },*/
    addPurchaseOrder(){
      //this.loadingForm = true;
      //var postForm = new FormData(document.getElementById("addpurchaseorderform"));
      axios.post('/api/company/product/purchase/create', this.newpurchaseorder, {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
        console.log(response);
        this.success_text = 'Congratulations, your purchase order has been added.';
        this.success_message = true;
        //this.resetProjectData();
        //this.addPanel = false;
        let self = this;
          setTimeout(function(){
            self.loadingForm = false;
            self.addNewPanel = false;
            self.productQuery();
          }, 1500);
      }).catch( error => {
        
        this.error_reponse = error.response.data.errors;
        this.error_message = true;
        //this.resetProjectData();
        let self = this;
          setTimeout(function(){
            self.loadingForm = false;
          }, 1500);
      });
    },
    updatePurchaseOrder(){
      this.loadingForm = true;
      //var postForm = new FormData(document.getElementById("updatepurchaseorderform"));
      axios.post('/api/company/product/purchase/'+this.newpurchaseorder.id+'/update', this.newpurchaseorder, {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
        console.log(response);
        this.success_text = 'Congratulations, your purchase order has been updated.';
        this.success_message = true;
        //this.resetProjectData();
        //this.addPanel = false;
        let self = this;
          setTimeout(function(){
            self.loadingForm = false;
            //self.addNewPanel = false;
            //self.productQuery();
          }, 1500);
      }).catch( error => {
        
        this.error_reponse = error.response.data.errors;
        this.error_message = true;
        //this.resetProjectData();
        let self = this;
          setTimeout(function(){
            self.loadingForm = false;
          }, 1500);
      });
    },
    getProducts(id){
      axios.get('/api/company/product/purchase/'+id+'/products').then(response => {
        this.categoryProducts = response.data;
        //this.showListingProducts = 1;
      }).catch(error => {
        this.products_error = error.data;
      });
    },
    getSellerProducts(search_query){
      if(this.orderDesc == true){
        this.order = 'desc';
      }else{
        this.order = 'asc';
      }
      axios.get('/api/company/product/purchases?page='+this.productsCurrentPage+'&limit='+this.productsLimit+'&sortby='+this.sortby+'&order='+this.order+'&search='+search_query+'&trade_active='+this.trade_productsactive+'&public_active='+this.public_productsactive+'&active='+this.productsactive+'&brands='+this.brands+'&collections='+this.collections+'&pricerange='+this.priceRange, {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
        console.log(response);
        this.productsData = response.data.data;
      });
    },
    closeEditModal(){
      this.$refs['edit-modal'].hide()
    },
    selectSupplier(supplier){
      this.supplierAddressLoading = true;
      this.newpurchaseorder.supplier = supplier;
      this.newpurchaseorder.company_supplier_id = supplier.id;
      this.selectedsupplieraddresses = JSON.parse(JSON.stringify(supplier.addresses));
      this.supplierSelected = false;
      this.supplierAddressLoading = false;
    },
    selectInvoiceAddress(address){
      console.log(address);
      this.newpurchaseorder.invoice_address_id = address.id;
    },
    selectDeliveryAddress(address){
      console.log(address);
      this.newpurchaseorder.delivery_address_id = address.id;
    },
    selectSupplierAddress(address){
      console.log(address);
      this.newpurchaseorder.company_supplier_address_id = address.id;
    },
    selectPaymentMethod(method){
      console.log(method);
      //this.newpurchaseorder.payment_method_id = method.id;
    },
    selectPaymentTerm(term){
      this.newpurchaseorder.payment_term_id = term.id;
    },
    addToListing(product){
      this.productListingSelected = product;
      this.selectedProductListingInfo = product.listings;
      if(this.productListingSelected.listings.length > 0){
        var listing_qty = this.productListingSelected.listings.filter(item => (item.listing_id == this.selectedListing.id) && (item.product_id == this.productListingSelected.id));
        if(listing_qty.length > 0){
          this.listingquantity = listing_qty[0].quantity;
        }else{
          this.listingquantity = 0;
        }
      }else{
         this.listingquantity = 0;
      }
      this.$refs['edit-modal'].show();
    },
    getListingData(listing_id){
        return this.shopsData.data.filter(item => item.id == listing_id);
    },
    priceIncVat(price, tax_percentage){
      var tax = 1 + (tax_percentage / 100);
      return price * tax;
    },
    addTax(price, trule){
            var tax_rule = trule;
            console.log(tax_rule);
            var country_id = this.$attrs.company.country_id;
       
          if(tax_rule !== null && tax_rule.country_rules.length > 0){
            var selected_rule = null;
            var selected_ruleIndex = -1;
            for(var ti = 0; ti < tax_rule.country_rules.length; ti++){
              selected_ruleIndex = tax_rule.country_rules[ti].countries.findIndex(item => item == country_id);
              if(selected_ruleIndex !== -1){
                selected_rule = tax_rule.country_rules[ti];
                ti = tax_rule.country_rules.length;
              }
            }
            console.log(selected_rule);
            if(tax_rule.type == 1){
                if(selected_rule !== null){
                  var percentage = (parseFloat(selected_rule.value) / 100) + 1;
                  this.active_tax_percentage = percentage;
                  return price * percentage;
                }else{
                  return price;
                }
              }else{
                if(selected_rule !== null){
                  this.active_tax_percentage = selected_rule.value;
                  return price + selected_rule.value;
                }else{
                  return price;
                }
              }
            }else if(tax_rule !== null && tax_rule.country_rules.length > 0){
              var percentage = (parseFloat(tax_rule.percentage) / 100) + 1;
              return price * percentage;
            }else {
              return price;
            }
          },
     productQuery(){
      if(this.shopsData != ''){
        if(this.limit > this.shopsData.total){
          this.currentPage = 1;
        }
      }
      if(this.orderDesc == true){
        this.order = 'desc';
      }else{
        this.order = 'asc';
      }
      axios.get('/api/company/product/purchases?page='+this.currentPage+'&limit='+this.limit+'&sortby='+this.sortby+'&order='+this.order+'&search='+this.search+'&trade_active='+this.trade_active+'&public_active='+this.public_active+'&active='+this.active+'&brands='+this.brands+'&collections='+this.collections+'&pricerange='+this.priceRange, {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
        console.log(response);
        this.shopsData = response.data;
      });
    },
    removeVatCalc(tax_percentage, index){
      console.log(tax_percentage);
            var setMultiplier = JSON.parse(tax_percentage) / 100;
            var multiplier = parseFloat(setMultiplier) + 1;
            var calculation = parseFloat(this.newpurchaseorder.items[index].price_inc) / parseFloat(multiplier);
            this.newpurchaseorder.items[index].price_exc = calculation.toFixed(2);
            this.newpurchaseorder.items[index].price_inc = this.addTax(this.newpurchaseorder.items[i].price_exc, tax_percentage);
    },
    

    addVatCalc(tax_percentage, i){
      console.log(tax_percentage);
      //this.newpurchaseorder.items[i].price_inc = (parseFloat(this.newpurchaseorder.items[i].price_exc) + ((parseFloat(this.newpurchaseorder.items[i].price_exc) / 100.00) * JSON.parse(tax_percentage))).toFixed(2);
      this.newpurchaseorder.items[i].price_inc = this.addTax(this.newpurchaseorder.items[i].price_exc, tax_percentage);
      this.newpurchaseorder.items[i].price_exc = parseFloat(this.newpurchaseorder.items[i].price_exc).toFixed(2);
    },
    filterSelected(id){
        if(this.selectedids.length > 0){
          var exists = this.selectedids.filter(item => item == id);
          if(exists.length > 0){
            return true;
          }else{
            return false
          }
        }else{
          return false;
        }
      },
      checkAll: function(){ 
                 this.isCheckAll = !this.isCheckAll; 
                 this.selectedids = []; 
                 if(this.isCheckAll){ 
                     // Check all 
                     for (var key in this.shopsData.data) {          
                        this.selectedids.push(this.shopsData.data[key].id); 
                     } 
                 } 
              }, 
      updateCheckall: function(){ 
                 if(this.selectedids.length == this.shopsData.data.length){
                    this.isCheckAll = true; 
                 }else{ 
                    this.isCheckAll = false; 
                 } 
      },
  },
  computed:{
    allProductListingQuantities: function(){
      if(this.productListingSelected.length !== 0){
          if(this.productListingSelected.listings.length > 0){
            this.totalListingsQuantity = 0;
            for(var i = 0; i < this.productListingSelected.listings.length; i++){
              //console.log(this.productListingSelected.listings[i].quantity);
              this.totalListingsQuantity = Number.parseFloat(this.totalListingsQuantity) + Number.parseFloat(this.productListingSelected.listings[i].quantity);
            }
            return this.totalListingsQuantity;
          }else{
            this.totalListingsQuantity = 0;
            return 0;
          }
      }else{
        return 0;
      }
    },
    purchaseItemsTotal: function(){
      //this.newpurchaseorder.total_inc = 0.00;
      //this.newpurchaseorder.total_exc = 0.00;
      var priceSum = 0.00;
      var priceSumInc = 0.00;
      for(var i = 0; i < this.newpurchaseorder.items.length; i++){
         //var currentTotal = this.newpurchaseorder.total_exc;
         var priceSum = parseFloat(priceSum) + parseFloat(this.newpurchaseorder.items[i].price_exc) * parseFloat(this.newpurchaseorder.items[i].quantity);
         var priceSumInc = parseFloat(priceSumInc) + parseFloat(this.newpurchaseorder.items[i].price_inc) * parseFloat(this.newpurchaseorder.items[i].quantity);
         this.newpurchaseorder.items[i].line_item_total = (parseFloat(this.newpurchaseorder.items[i].price_inc) * parseFloat(this.newpurchaseorder.items[i].quantity)).toFixed(2);
      }
        this.newpurchaseorder.total_taxes = ((priceSumInc).toFixed(2) - (priceSum).toFixed(2)).toFixed(2);
        this.newpurchaseorder.total_exc = (priceSum).toFixed(2);
        this.newpurchaseorder.total_inc = (priceSumInc).toFixed(2);
        return [(priceSum).toFixed(2), (priceSumInc).toFixed(2)];
      
    }
  },
  created(){
    //console.log(localStorage.getItem('default_company'));
    //this.company_info = JSON.parse(localStorage.getItem('default_company'));
  },
  mounted(){
     this.productQuery();
      this.getTaxRules();
  },
}
</script>

<template>
  <Layout  :nickname="$attrs.nickname" :user="$attrs.user" :menuItems="$attrs.menuItems">
    <PageHeader :title="title" :items="items" />
    <div class="row">
             <b-col md="12">
                    <transition name="slide-success" mode="out-in">
                  <b-alert id="success-message" variant="success" :show="success_message"  dismissible @dismissed="success_message = !success_message">
                      <p class="font-size-14">{{success_text}}</p>
                    </b-alert>
                  </transition>
                  </b-col>
      <div class="col-md-12">
        <div class="row mb-3">
          <div class="col-xl-1 col-sm-6">
            <div class="mt-2">
              <b-form-select class="form-control" v-model="limit" :options="limits" @input="brandQuery()"></b-form-select>
            </div>
          </div>
         
          <div class="col-lg-2 col-sm-6 d-flex">
          <form class="my-auto">
            <small class="mx-auto my-2 text-center d-block">Results {{shopsData.from}} - {{shopsData.to}} of {{shopsData.total}}</small>
          </form>
        </div>
        <div class="col-lg-3">
          <b-form-group class="my-auto ml-auto mr-3">
            <b-form-radio-group id="btn-radios-1" button-variant="outline-light"
        size="md" v-model="marketplace_brands" :options="quickfilter_options" buttons @input="brandQuery()"></b-form-radio-group>
      
          
          </b-form-group>
        </div>
        <div class="col-lg-3 col-sm-6 d-flex">
          
            <div class="mt-sm-0 form-inline w-100" >
              <div class="search-box mr-2 ml-auto">
                <div class="position-relative">
                  <input
                    type="text"
                    class="form-control w-100 border-0"
                    placeholder="Search name, reference or description..."
                    v-model="search"
                    @keyup.enter="brandQuery()"
                  />
                  <i class="bx bx-search-alt search-icon"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-sm-6 d-flex">
            <div class="d-flex ml-auto">
              <ul class="nav nav-pills product-view-nav">
                
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': view == 'grid' }" href="javascript: void(0);" @click="view = 'grid'">
                    <i class="bx bx-grid-alt"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': view == 'list' }" href="javascript: void(0);" @click="view = 'list'">
                    <i class="bx bx-list-ul"></i>
                  </a>
                </li>
                <li class="nav-item">
                 <b-dropdown text="Bulk Actions" variant="primary" class="rounded">
                  <b-dropdown-item href="javascript: void(0);">Action</b-dropdown-item>
                  <b-dropdown-item href="javascript: void(0);">Another action</b-dropdown-item>
                  <b-dropdown-item href="javascript: void(0);">Something else here</b-dropdown-item>
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-item href="javascript: void(0);">Separated link</b-dropdown-item>
                </b-dropdown>
                </li>
                <li class="nav-item">
                 <a
                    class="btn btn-success btn-rounded mb-2 mr-2 text-white"
                    href="javascript:;"
                    @click="addNewPanel = !addNewPanel"
                  >
                    <i class="mdi mdi-plus mr-1"></i> New
                  </a>
                </li>
              </ul>
              <div class="text-sm-right">
                  
                </div>
          </div>
        </div>
      </div>
        <div class="card" v-if="view == 'list'" >
          <div class="card-body p-0">
            <div class="table-responsive">
              <table class="table table-centered table-hover">

                <thead class="thead-light">
                  <tr>
                    <th scope="col"><div
                        class="form-check form-switch form-switch-sm mb-0 mx-auto p-0 mt-auto d-flex"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input mx-auto my-auto" 
                          type="checkbox"
                          id="SwitchCheckSizesm"
                         
                          v-model="isCheckAll"
                          @click='checkAll()'
                          name="active"
                        />
                    </div></th>
                    <th scope="col"><a :class="{'text-dark': sortby != 'name'}" href="javascript: void(0);" @click="orderDesc = !orderDesc,  sortby = 'name', brandQuery()">Purchase Order No. <i v-if="orderDesc == true" class="bx bx-caret-up"></i><i v-if="orderDesc == false" class="bx bx-caret-down"></i></a></th>
                    <th scope="col">Supplier</th>
                    <th scope="col">Total</th>
                    <th scope="col">Paid</th>
                    <th scope="col">Payment Method</th>
                    <th scope="col">Status</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
               
                <tbody>
                  
                  <tr v-for="list in shopsData.data" :key="list.id" valign="middle">
                    <td>
                      <div class="text-center" v-if="list.image">
                      <b-form-checkbox class="select-row-btn" v-model="selectedids" :value="list.id" button-variant="primary" @input="updateSelected" button>
                        <i v-if="filterSelected(list.id)" class="bx bx-check"></i>
                        <img v-else :src="`https://gemesys-dev.s3.eu-west-2.amazonaws.com/${list.image}`" alt class="avatar-sm rounded-circle" />
                      </b-form-checkbox>
                    </div>
                    <div class="text-center" v-else >
                        <b-form-checkbox class="select-row-btn" v-model="selectedids" :value="list.id" button-variant="primary" @input="updateSelected" button>

                          <i v-if="filterSelected(list.id)" class="bx bx-check"></i><span class="avatar-title bg-light text-primary rounded-circle" v-else>{{(list.purchase_order_no).slice(0,1)}}</span></b-form-checkbox>
                    </div> 
                    </td>
                    <td>
                      #{{list.purchase_order_no}}
                    </td>
                    <td>
                      <div class="d-flex">
                      <img :src="'/image/'+list.supplier.logo" alt class="avatar-xs rounded-circle my-auto" />
                      <p class="my-auto ml-2">{{list.supplier.name}}</p>
                      </div>
                    </td>
                   
                    <td>
                      <a href="javascript: void(0);"
                          class="m-1 d-block"
                        >{{list.total_inc}} inc. tax</a>
                        <a href="javascript: void(0);"
                          class="m-1 d-block"
                        >{{list.total_exc}} exc. tax</a>
                    </td>

                     <td>
                      <a href="javascript: void(0);"
                          class="m-1"
                        >{{list.amount_paid}} exc. tax</a>
                    </td>
                    <td>
                     <span v-if="list.payment_method"> <i :class="list.payment_method.icon"></i> {{list.payment_method.name}}
                     </span>
                    </td>
                    <td>
                      <div
                        class="form-check form-switch form-switch-sm mb-0 mt-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          id="SwitchCheckSizesm"
                          v-model="list.active"
                          value="1"
                          unchecked-value="0"
                          name="active"
                        />
                        <label class="form-check-label" for="SwitchCheckSizesm" 
                          >Published</label
                        >
                    </div>
                    
                    </td>
                    <td>
                      <ul class="list-inline font-size-20 contact-links mb-0">
                        <li class="list-inline-item"><a v-b-tooltip.hover title="Edit" placement="left" class="btn btn-outline-light btn-rounded" @click="newpurchaseorder = list, addNewPanel = true"><i class="bx bx-edit-alt"></i>
                          </a>
                        </li>
                      <li class="list-inline-item">
                        <a href="javascript:;" v-b-tooltip.hover title="View" placement="left" @click="selectedListing = list, getProducts(list.id), getProductsPanel = 1" class="btn btn-outline-light btn-rounded"><i class="mdi mdi-eye-outline"></i></a>
                      </li>
                        <li class="list-inline-item">
                           <b-dropdown v-b-tooltip.hover title="More Options" placement="left" dropleft class="more-options-dropdown btn btn-outline-light btn-rounded">
                        <template v-slot:button-content>
                           <i class="bx bx-dots-horizontal-rounded"></i>
                        </template>
                        <b-dropdown-item href="javascript: void(0);"  @click="selectedids = [], selectedids.push(list.id), bulkDuplicateResource()"><i class="bx bx-copy-alt"></i> Update Status</b-dropdown-item>
                        <b-dropdown-item href="javascript: void(0);"  @click="selectedids = [], selectedids.push(list.id), bulkDuplicateResource()"><i class="bx bx-copy-alt"></i> Duplicate</b-dropdown-item>
                        <b-dropdown-divider></b-dropdown-divider>
                        <b-dropdown-item href="javascript: void(0);"><i class="bx bx-copy-alt"></i> Mark Cancelled</b-dropdown-item>
                      </b-dropdown>
                        </li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <b-pagination
              v-if="shopsData.last_page > 1"
              class="justify-content-center"
              pills
              v-model="currentPage"
              :total-rows="shopsData.total"
              :per-page="shopsData.per_page"
              aria-controls="my-table"
              @input="brandQuery"
            ></b-pagination>
             <small class="mx-auto my-2 text-center d-block">Records {{shopsData.from}} - {{shopsData.to}} of {{shopsData.total}} // Page {{shopsData.current_page}} of {{shopsData.last_page}}</small>
              </div>
            </div>
          </div>
      </div>
    <div class="row" v-if="view == 'grid'" >
     <div class="col-xl-4"  v-for="list in shopsData.data" :key="list.id">
        <div class="card overflow-hidden">
          <div class="bg-soft-primary min-h-100">
            <div class="row">
              
              <div class="col-12 align-self-end">
                <img :src="'/public/storage/'+list.banner" alt class="img-fluid" />
              </div>
            </div>
          </div>
          <div class="card-body pt-0">
            <div class="row">
              <div class="col-sm-12 text-center">
               <div class="avatar-md profile-user-wid mb-4 mx-auto text-center">
                  <img class="img-thumbnail rounded-circle h-100 mx-auto" :src="`/public/storage/${list.logo}`" alt />
                </div>
                <h4 class="font-size-18 text-truncate mt-3">{{list.companyname}}</h4>
                <p class="text-muted mb-0 text-truncate" v-html="list.description"></p>
              </div>

              <div class="col-sm-12">
                <div class="pt-4">
                  <div class="mt-4 d-flex">
                    <a href="javascript:;" @click="selectedListing = list, getProducts(list.id)" class="btn btn-outline-light btn-rounded"><i class="bx bx bx-purchase-tag-alt my-1"></i></a>
                    <b-tooltip target="tooltip-add">Add Product</b-tooltip>
                    <a id="tooltip-add" class="btn btn-outline-light font-size-16 btn-rounded ml-2 my-auto"><i class="bx bx-pencil"></i></a><b-tooltip target="tooltip-add">Add Product</b-tooltip>
                    <a href="javascript:;" v-b-tooltip.hover title="View" placement="left" @click="selectedListing = list, getProducts(list.id), getProductsPanel = 1" class="btn btn-outline-light btn-rounded ml-2"><i class="mdi mdi-eye-outline"></i></a>
                     <b-dropdown v-b-tooltip.hover title="More Options" placement="left" dropleft class="more-options-dropdown btn btn-outline-light btn-rounded mr-auto ml-2">
                        <template v-slot:button-content>
                           <i class="bx bx-dots-horizontal-rounded"></i>
                        </template>
                        <b-dropdown-item>Action</b-dropdown-item>
                        <b-dropdown-item>Another Action</b-dropdown-item>
                        <b-dropdown-item>Something else here</b-dropdown-item>
                      </b-dropdown>
                    <b-form-checkbox switch size="lg" class="my-auto" v-model="list.active" value="1"
                      unchecked-value="0"><small>Active</small></b-form-checkbox>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>

              <div class="col-lg-12">
                <b-pagination
              v-if="shopsData.data.length > 0"
              class="justify-content-center"
              pills
              v-model="currentPage"
              :total-rows="shopsData.total"
              :per-page="shopsData.per_page"
              aria-controls="my-table"
              @input="brandQuery"
            ></b-pagination>
             <small class="mx-auto my-2 text-center d-block">Records {{shopsData.from}} - {{shopsData.to}} of {{shopsData.total}} // Page {{shopsData.current_page}} of {{shopsData.last_page}}</small>
              </div>
      </div>
    </div>
    </div>
    <!--  end row -->

    <div class="row">
      <!-- end col-->
    </div>
    <SideSlidePanel :editPanel="showListingProducts" :panelClass="'edit-sidepanel-large'" @close-panel="showListingProducts = 0">
      <div class="row">
        
      </div>
    </SideSlidePanel>

    <SideSlidePanel :editPanel="addNewPanel" :panelClass="'edit-sidepanel-fullwidth'" @close-panel="addNewPanel = !addNewPanel, resetPurchaseData()">
      <div class="row px-4">
        <form id="addpurchaseorderform" class="repeater" enctype="multipart/form-data">
           <b-row>
                     <b-col md="12">
                    <transition name="slide-error" mode="out-in">
                    <b-alert id="errors" variant="danger" :show="error_message"  dismissible @dismissed="error_message = !error_message">
                      <p class="font-size-14">Oops! Please correct the following errors in your submission</p>
                      <ul>
                        <li v-for="(error, key) in error_reponse">
                          {{error[0]}}
                        </li>
                      </ul>
                    </b-alert>
                    
                  </transition>
                  </b-col>
          </b-row>
          <div class="row px-4">
            <h4 class="mt-auto mb-4 mr-auto">Create New Purchase Order</h4>
            <hr>
          </div>
              <b-overlay
                            id="overlay-background"
                            :show="loadingForm"
                            variant="white"
                            opacity="0.85"
                            blur="1px"
                            rounded="md"
                          >
          <div class="row px-4">
              <div class="col-md-12">
                <h5 class="d-flex"><i class="font-size-16 mr-2 text-primary bx bx-info-circle"></i> Reference Info</h5>
                
              </div>
              <div class="col-md-12"> 
                <b-card class="shadow-none border-radius-1em border">
                  <b-row>
                  <div class="col-md-8">
                     <b-form-group class="mt-2" label="Reference">
                      <b-form-input type="text" name="reference" v-model="newpurchaseorder.purchase_order_no"></b-form-input>
                    </b-form-group>
                  </div>

                  <div class="col-md-4">
                     <b-form-group class="mt-2" label="Status">
                      <b-form-select class="form-control" :options="statuses" v-model="newpurchaseorder.order_status_id"></b-form-select>
                    </b-form-group>
                  </div>
                </b-row>
                </b-card>
              </div>
              <div class="col-md-12">
                <h5 class="d-flex"><i class="font-size-16 mr-2 text-primary bx bx-cube-alt"></i> Supplier Details</h5>
              </div>
              <div class="col-md-12">
                 <b-card class="shadow-none border-radius-1em border">
                  <b-row>
                    

                        <div class="col-md-6">
                          <b-form-group class="mt-2" label="Supplier">
                          <SearchCompanySupplier :id="newpurchaseorder.supplier" :api_token="$attrs.user.api_token" @supplier-selected="selectSupplier"></SearchCompanySupplier>
                        </b-form-group>
                        </div>
                        <div class="col-md-6">
                          <b-form-group class="mt-2" label="Supplier Address">
                          <FilterCompanySupplierAddresses v-if="supplierAddressLoading == false" :id="newpurchaseorder.supplier_address" :address_data="selectedsupplieraddresses" :supplier_id="newpurchaseorder.company_supplier_id"  :isDisabled="supplierSelected" :api_token="$attrs.user.api_token" @supplier-address-added="addressAdded" @supplier-address-selected="selectSupplierAddress" ></FilterCompanySupplierAddresses>
                        </b-form-group>
                        </div>
                  </b-row>
                </b-card>
              </div>
            
              <div class="col-md-12">
                <h5 class="d-flex"><i class="font-size-16 mr-2 text-primary bx bx-building-house"></i> Buyer Details</h5>
              </div>
              <div class="col-md-12">
                 <b-card class="shadow-none border-radius-1em border">
                <b-row>
                  
                  
                  <div class="col-md-6">
                    <b-form-group class="mt-2" label="Invoice Address">
                    <SearchCompanyLocation :id="newpurchaseorder.invoice_address" :api_token="$attrs.user.api_token" @listing-selected="selectInvoiceAddress"></SearchCompanyLocation>
                    <input type="hidden" name="invoice_address_id" v-model="newpurchaseorder.invoice_address_id">
                  </b-form-group>
                  </div>
                  <div class="col-md-6">
                    <b-form-group class="mt-2" label="Delivery Address">
                      <SearchCompanyLocation :id="newpurchaseorder.delivery_address" :api_token="$attrs.user.api_token" @listing-selected="selectDeliveryAddress"></SearchCompanyLocation>
                    <input type="hidden" name="invoice_address_id" v-model="newpurchaseorder.delivery_address_id">
                  </b-form-group>
                </div>
              </b-row>
            </b-card>
            </div>
            <div class="col-md-12">
                <h5 class="d-flex"><i class="font-size-16 mr-2 text-primary bx bxs-truck"></i> Delivery & Payment</h5>
              </div>
              <div class="col-md-12">
                <b-card class="shadow-none border-radius-1em border">
                  <b-row>
                  <div class="col-md-3">
                      <b-form-group class="mt-2" label="Delivery Date">
                      <b-form-input
                            id="action-duedate" class="mb-2" name="delivery_date" type="date" v-model="newpurchaseorder.delivery_date"
                          ></b-form-input>
                    </b-form-group>
                    </div>
                    <div class="col-md-3">
                      <b-form-group class="mt-2" label="Payment Term">
                      <SearchCompanyPaymentTerm :id="newpurchaseorder.payment_term" :api_token="$attrs.user.api_token" @payment-term-selected="selectPaymentTerm"></SearchCompanyPaymentTerm>

                      <input type="hidden" name="payment_term_id" v-model="newpurchaseorder.payment_term_id">
                    </b-form-group>
                    </div>
                    <div class="col-md-3">
                      <b-form-group class="mt-2" label="Payment Method">
                      <SearchCompanyPaymentMethod :id="newpurchaseorder.payment_method" :api_token="$attrs.user.api_token" @payment-method-selected="selectPaymentMethod"></SearchCompanyPaymentMethod>
                      <!--<input type="hidden" name="payment_method_id" v-model="newpurchaseorder.payment_method_id">-->
                    </b-form-group>
                    </div>
                    
                    <div class="col-md-3">
                      <b-form-group class="mt-2" label="Payment Date">
                      <b-form-input
                            id="example-datepicker" class="mb-2" name="payment_date" v-model="newpurchaseorder.payment_date" type="date"
                          ></b-form-input>
                    </b-form-group>
                    </div>
                  </b-row>
                </b-card>
          </div>
              <div class="col-md-12">
                
                 <h5 class="d-flex"><i class="font-size-16 mr-2 text-primary bx bx-receipt"></i> Order Items</h5>
                
            </div>
             <div class="col-md-12" v-if="item_errors.length > 0">
              
              <ul class="bg-danger bg-soft p-3 list-unstyled border-radius-1em mx-2 position-relative">
                <a class="position-absolute top-0 right-0 m-2 btn btn-soft font-size-20" @click="item_errors = []">
                <i class="mdi mdi-close"></i>
              </a>
                <li v-for="errors in item_errors" class="text-danger">
                  {{errors}}
                </li>
              </ul>
             </div>
            <div class="col-md-12">

                        <ul class="list-group product-list w-100 no-shadow px-2">
                              <li v-for="(item, index) in newpurchaseorder.items" class="list-group-item d-flex px-0 py-0 border product-list-item mb-2">
                                <div class="w-100" v-if="item.editable == 0">
                                <div class="d-flex product-list-item-container w-100">
                                  <div class="mr-auto ml-2 my-auto flex-grow-5 d-flex mr-3 py-3">
                                  <div class="mr-auto mb-auto ml-4 text-primary flex-grow-1 w-15">{{item.title}}<input type="hidden" name="item[name][]" :value="item.title"></div>
                                  <!--<span class="mx-4">{{address.address_type}}</span>-->
                                  <div class="text-muted italic no-margin d-inline w-50 flex-grow-3 px-3">{{item.description}}
                                    <input type="hidden" :name="'item['+index+'][description]'" :value="item.description">
                                  </div>
                                  <span class="flex-grow-3 mb-auto mx-auto text-center"><h5 class="text-primary d-inline">{{item.quantity}}</h5></span>
                                  <span class="flex-grow-3 mb-auto mx-auto text-center">£<h5 class="text-primary d-inline">{{item.price_exc}}</h5></span>
                                  <input type="hidden" name="item[price_exc][]" :value="item.price_exc">
                                  <span class="flex-grow-3 mb-auto mx-auto text-center"><h5 class="text-primary d-inline">{{item.tax.percentage}}</h5>%</span>
                                  <input type="hidden" name="item[tax_rule_id][]" :value="item.tax_rule_id">
                                  <span class="flex-grow-3 mb-auto mx-auto text-center">£<h5 class="text-primary d-inline">{{item.price_inc}}</h5></span>
                                  <span class="flex-grow-3 mb-auto mx-auto text-center">£<h5 class="text-primary d-inline">{{item.line_item_total}}</h5></span>
                                  <input type="hidden" name="item[price_inc][]" :value="item.price_inc">
                                </div>
                                <div class="ml-auto my-auto flex-grow-1 mr-3 d-flex">
                                  <a class="btn btn-outline-light btn-rounded ml-auto my-auto mr-2" href="javascript:;" @click="item.editable = 1"><i class="px-2 bx bx-pencil"></i></a>
                                <a class="btn btn-outline-light btn-rounded my-auto" href="javascript:;" @click="newpurchaseorder.items.splice(index, 1)"><i class="py-1 px-2 bx bx-trash-alt"></i></a>
                              </div>
              
                              </div>
                            </div>

                              <div class="w-100" v-else>
                                <div class="d-flex product-list-item-container w-100">
                                  <div class="mr-auto ml-2 my-auto mr-3 d-flex py-3 flex-column">
                                  <b-form-group class="mr-2 mb-2" label-class="font-size-10" label="Name">
                                    <b-form-input type="text" v-model="item.title"></b-form-input>
                                  </b-form-group>
                                  <!--<span class="mx-4">{{address.address_type}}</span>-->
                                  <b-form-group class="mr-2 mb-2" label-class="font-size-10" label="Description">
                                    <textarea class="form-control" rows="1" type="text" v-model="item.description"></textarea>
                                  </b-form-group>
                                  <div class="d-flex">
                                  <b-form-group class="mr-2" label-class="font-size-10" label="Quantity">
                                  <b-form-input type="number" min="1"  v-model="item.quantity"></b-form-input>

                                </b-form-group>
                                   <b-form-group class="mr-2" label-class="font-size-10" label="Price (exc. VAT)">
                                  <b-form-input type="number" step="0.50" v-model="item.price_exc" @keydown.enter="addVatCalc(item.tax_rule, index)" @focus="addVatCalc(item.tax_rule, index)" @blur="addVatCalc(item.tax_rule, index)"></b-form-input>
                                </b-form-group>
                                 <b-form-group class="mr-2" label-class="font-size-10" label="Tax (%)">
                                  <b-form-select class="form-control" v-model="item.tax_rule" @change="removeVatCalc(item.tax_rule, index)" >
                                    <b-form-select-option :value="null">
                                      No Tax
                                    </b-form-select-option>
                                    <b-form-select-option v-for="tax in tax_rules" :value="tax" >
                                      {{tax.name}} at {{tax.percentage}}%
                                    </b-form-select-option>
                                  </b-form-select>
                                </b-form-group>
                                 <b-form-group class="mr-2" label-class="font-size-10" label="Price (inc. VAT)">
                                  <b-form-input type="number"  v-model="item.price_inc" @keydown.enter="removeVatCalc(item.tax_rule, index)" @focus="removeVatCalc(item.tax_rule, index)" @blur="removeVatCalc(item.tax_rule, index)" ></b-form-input>
                                </b-form-group>
                                 <b-form-group class="mr-2" label-class="font-size-10" label="Item Total">
                                  <b-form-input type="number" v-model="item.line_item_total"></b-form-input>
                                </b-form-group>
                              </div>
                                </div>
                                <div class="ml-auto my-auto mx-auto d-flex">
                                  <a class="btn btn-light btn-rounded ml-auto my-auto mr-2" href="javascript:;" @click="saveItem(index)"><i class="font-size-16 px-2 bx bx-check text-success"></i></a>
                                  <a class="btn btn-light btn-rounded my-auto" href="javascript:;" @click="newpurchaseorder.items.splice(index, 1)"><i class="py-1 font-size-16 px-2 bx bx-trash-alt text-danger"></i></a>
                              </div>
              
                              </div>
                              </div>


                              </li>
                              <li class="list-group-item p-0">
                             <input
                type="button"
                class="btn btn-light mt-lg-0 px-3 btn-block"
                value="Add"
                @click="AddformData"

              />
            </li>
                            </ul>
                <!--<div v-for="(field, index) in fields" :key="field.id" class="row">
                  <div class="mb-3 col-lg-2">
                    <label for="name">Name</label>
                    <input
                      id="name"
                      v-model="field.name"
                      type="text"
                      name="untyped-input"
                      class="form-control"
                    />
                  </div>
                   <div class="mb-3 col-lg-4">
                    <label for="message">description</label>
                    <textarea id="message" v-model="field.message" class="form-control"></textarea>
                  </div>
                  <div class="mb-3 col-lg-2">
                    <label for="email">Price (exc VAT)</label>
                    <input id="email" v-model="field.email" type="email" class="form-control" />
                  </div>

                  <div class="mb-3 col-lg-1">
                    <label for="subject">Tax Rate (%)</label>
                    <input id="subject" v-model="field.subject" type="text" class="form-control" />
                  </div>
                  <div class="mb-3 col-lg-2">
                    <label for="subject">Price (inc VAT)</label>
                    <input id="subject" v-model="field.subject" type="text" class="form-control" />
                  </div>

                 

                  <div class="col-lg-1 align-self-center">
                     <div class="d-grid">
                    <input
                      type="button"
                      class="btn btn-danger btn-block"
                      value="Delete"
                      @click="deleteRow(index)"
                    />
                     </div>
                     <div class="d-grid">
                    <input
                      type="button"
                      class="btn btn-primary btn-block"
                      value="Save"
                      @click="deleteRow(index)"
                    />
                     </div>
                  </div>
                </div>-->
                 
              <ul class="list-group product-list w-100 no-shadow px-2 mt-2">
                              <li class="list-group-item d-flex px-0 py-0 border product-list-item mb-2">
                                <div class="w-100">
                                   <div class="d-flex product-list-item-container w-100">
                                  <div class="justify-content-end mx-2 my-auto flex-grow-1 d-flex mr-3 py-3">
                                  <span class="mx-3">Total exc: <h3 class="text-primary"><strong>{{purchaseItemsTotal[0]}}</strong></h3></span>
                                  <span class="mx-3">Taxes: <h3 class="text-primary"><strong v-if="(parseFloat(purchaseItemsTotal[1])) >= (parseFloat(purchaseItemsTotal[0]))"> {{ newpurchaseorder.total_taxes }}</strong><strong v-else>0.00</strong></h3></span>
                                  <span class="mx-3">Total inc: <h3 class="text-primary"><strong>{{purchaseItemsTotal[1]}}</strong></h3></span>
                                </div>
              
                              </div>
                                </div>
                              </li>
              </ul>
            </div>
              <div class="col-md-12 mt-2">
                
                 <h5 class="d-flex"><i class="font-size-16 mr-2 text-primary bx bx-notepad"></i> Notes</h5>
              </div>
              <div class="col-md-12 mt-2">
                <b-card class="shadow-none border-radius-1em border">
                 <b-form-group class="" label="Notes">
                  <b-textarea type="text" name="notes" rows="4"></b-textarea>
                </b-form-group>
              </b-card>
              </div>
              <div class="col-md-12 mb-3">
                 <h5 class="d-flex"><i class="font-size-16 mr-2 text-primary bx bx-mail-send"></i> PDF & Email</h5>
                </div> 
              <div class="col-md-12 mb-3"> 
              <b-card class="shadow-none border-radius-1em border">           
                <div
                        class="form-check form-switch form-switch-sm ml-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          :id="`SwitchCheckSize-createpdf`"
                          v-model="newpurchaseorder.pdf"
                          value="1"
                          unchecked-value="0"
                          name="active"
                        />
                        <label class="form-check-label" :for="`SwitchCheckSize-createpdf`" 
                          >Create PDF On Save</label
                        >
                    </div>

                    <div
                        class="form-check form-switch form-switch-sm my-auto ml-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          :id="`SwitchCheckSize-emailsupplier`"
                          v-model="newpurchaseorder.email"
                          value="1"
                          unchecked-value="0"
                          name="active"
                        />
                        <label class="form-check-label" :for="`SwitchCheckSize-emailsupplier`" 
                          >Email Supplier On Save</label
                        >
                    </div>
                </b-card>
                <b-row>
                  <div class="col-md-6 mb-3 mb-1">
                <a class="btn-rounded btn btn-success w-100" v-if="newpurchaseorder.id == null" @click="addPurchaseOrder()">Save</a>
                <a class="btn-rounded btn btn-success w-100" v-if="newpurchaseorder.id !== null" @click="updatePurchaseOrder()">Save</a>
              </div>
              <div class="col-md-6 mt-sm-1 mb-3">
                <a class="btn-rounded btn btn-danger w-100" @click="addNewPanel = false, resetPurchaseData()">Cancel</a>
              </div>
                </b-row>
              </div>
              

              </div>
            </b-overlay>
            </form>
        
      </div>


    </SideSlidePanel>




     
    <!-- end row -->
  </Layout>
</template>
