<script type="text/javascript">
	export default{
		name: 'ProductsListWidgetProductCards',
		data(){
			return {
				storageurl: 'https://'+this.$attrs.company.nickname+'.s3.eu-west-2.amazonaws.com',
				hoverIndex: -1,
				hoverLink: -1,
				categories: []
			}
		},
		props: {
			product: {
				type: Object
			},
			index: {
				type: Number
			}
		},
		computed: {
			headerComponent: function() {
			      return {
			        template: `<${this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'title')].title.subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'title')].title.subfields, 'heading_level')].heading_level.value}>${this.product.name}</${this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'title')].title.subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'title')].title.subfields, 'heading_level')].heading_level.value} >`,
			        methods: {
			          someAction() {
			            console.log("Action!");
			          }
			        }
			      }
			    }
		},
		methods: {
			filterStylesKey(styles, key){
				//console.log(styles);
	            for(var i = 0; i < styles.length; i++){
	               var keys = Object.keys(styles[i])
	               if(keys.includes(key)){
	                 return i;
	               }
	            }
	        },
			addTax(product, price){
        		var tax_rule = product.tax_rule;
        		console.log(tax_rule);
        		if(this.$attrs.liveMode == true){
			    	if((this.$attrs.user !== null && this.$attrs.user !== '') && (this.$attrs.user.default_delivery_address !== '' && this.$attrs.user.default_delivery_address !== null)){
			    		var country_id = this.$attrs.user.default_delivery_address.country_id;
			    	}else{
			    		var country_id = product.company.country_id;
			    	}
		    	}else if((this.$attrs.liveMode == false) && (this.$attrs.company.trading_country_id !== null)){
		    		var country_id = this.$attrs.company.trading_country_id;
		    	}else if(this.$attrs.liveMode == false){
		    		var country_id = this.$attrs.company.country_id;
		    	}
		    	if(tax_rule !== null && tax_rule.country_rules.length > 0){
		    		var selected_rule = null;
		    		var selected_ruleIndex = -1;
			    	for(var ti = 0; ti < tax_rule.country_rules.length; ti++){
			    		selected_ruleIndex = tax_rule.country_rules[ti].countries.findIndex(item => item == country_id);
			    		if(selected_ruleIndex !== -1){
			    			selected_rule = tax_rule.country_rules[ti];
			    			ti = tax_rule.country_rules.length;
			    		}
			    	}
			    	console.log(selected_rule);
			    	if(tax_rule.type == 1){
		        		if(selected_rule !== null){
		        			var percentage = (parseFloat(selected_rule.value) / 100) + 1;
		        			return price * percentage;
		         		}else{
		         			return price * 1.2;
		         		}
	         		}else{
	         			if(selected_rule !== null){
		        			return price + selected_rule.value;
		         		}else{
		         			return price * 1.2;
		         		}
	         		}
         		}else if(tax_rule !== null && tax_rule.country_rules.length > 0){
         			var percentage = (parseFloat(tax_rule.percentage) / 100) + 1;
		        	return price * percentage;
         		}else {
		        	return price * 1.2;
         		}
        	},
			loadDefaultClasses(classes){
				return classes;
			},
			compileElementStyles(fieldname, types){
			var style = '';
				if(types.includes('background') == true){
					if(fieldname !== 'column'){
				  		style += 'background-color:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_colour')][fieldname+'_colour'].value+' !important;'
				  	}else{
				  		style += 'background-color:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'background_colour')].background_colour.value+' !important;'
				  	}
				}
				if(types.includes('color') == true){
					if(fieldname !== 'column'){
						style += 'color:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_text_colour')][fieldname+'_text_colour'].value+' !important;'
					}else{
						style += 'color:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'text_colour')].text_colour.value+' !important;'
					}
				}
				if(types.includes('border') == true){
					if(fieldname !== 'column'){
						style +=  'border-color: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_border_colour')][fieldname+'_border_colour'].value+' !important; border-style:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_border_style')][fieldname+'_border_style'].value+' !important; border-radius:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_border_radius')][fieldname+'_border_radius'].top_left+' '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_border_radius')][fieldname+'_border_radius'].top_right+' '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_border_radius')][fieldname+'_border_radius'].bottom_right+' '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_border_radius')][fieldname+'_border_radius'].bottom_left+' !important; border-left:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_border')][fieldname+'_border'].left+'px !important; border-right: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_border')][fieldname+'_border'].right+'px !important; border-top: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_border')][fieldname+'_border'].top+'px !important; border-bottom: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_border')][fieldname+'_border'].bottom+'px !important;'
					}else{
						style +=  'border-color: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'border_colour')].border_colour.value+' !important;'

						style += 'border-style:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'border_style')].border_style.value+' !important;'

						style += 'border-radius:'+this.content[this.filterStylesKey(this.content, fieldname+'_border_radius')][fieldname+'_border_radius'].top_left+this.content[this.filterStylesKey(this.content, fieldname+'_border_radius')][fieldname+'_border_radius'].units +' '+this.content[this.filterStylesKey(this.content, fieldname+'_border_radius')][fieldname+'_border_radius'].top_right+this.content[this.filterStylesKey(this.content, fieldname+'_border_radius')][fieldname+'_border_radius'].units +' '+this.content[this.filterStylesKey(this.content, fieldname+'_border_radius')][fieldname+'_border_radius'].bottom_right+this.content[this.filterStylesKey(this.content, fieldname+'_border_radius')][fieldname+'_border_radius'].units +' '+this.content[this.filterStylesKey(this.content, fieldname+'_border_radius')][fieldname+'_border_radius'].bottom_left+this.content[this.filterStylesKey(this.content, fieldname+'_border_radius')][fieldname+'_border_radius'].units +' !important;'
						style += 'border-left:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'border')].border.left+'px !important; border-right: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'border')].border.right+'px !important; border-top: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'border')].border.top+'px !important; border-bottom: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'border')].border.bottom+'px !important;'
					}

				}
				if(types.includes('margin') == true){
					if(fieldname !== 'column'){
					style +=  'margin-left:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_margin')][fieldname+'_margin'].left+'px !important;';
					style +=  'margin-right: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_margin')][fieldname+'_margin'].right+'px !important;';
					style +=  'margin-top: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_margin')][fieldname+'_margin'].top+'px !important;';
					style +=  'margin-bottom: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_margin')][fieldname+'_margin'].bottom+'px !important;';
					}else{
					style +=  'margin-left:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'margin')]['margin'].left+'px !important;'
					style +=  'margin-right: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'margin')]['margin'].right+'px !important;'
					style +=  'margin-top: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'margin')]['margin'].top+'px !important;'
					style +=  'margin-bottom: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'margin')]['margin'].bottom+'px !important;'
					}
				}
				if(types.includes('padding') == true){
					if(fieldname !== 'column'){
					style +=  'padding-left:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_padding')][fieldname+'_padding'].left+'px !important;'
					style +=  'padding-right: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_padding')][fieldname+'_padding'].right+'px !important;'
					style +=  'padding-top: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_padding')][fieldname+'_padding'].top+'px !important;'
					style +=  'padding-bottom: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_padding')][fieldname+'_padding'].bottom+'px !important;'
					}else{
						style +=  'padding-left:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'padding')]['padding'].left+'px !important;'
					style +=  'padding-right: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'padding')]['padding'].right+'px !important;'
					style +=  'padding-top: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'padding')]['padding'].top+'px !important;'
					style +=  'padding-bottom: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'padding')]['padding'].bottom+'px !important;'
					}
				}
				if(types.includes('size') == true){
					if(fieldname !== 'column'){
					style +=  'height:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_height')][fieldname+'_height'].value+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_height')][fieldname+'_height'].units+' !important;';
					style +=  'max-height:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_max_height')][fieldname+'_max_height'].value+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_max_height')][fieldname+'_max_height'].units+' !important;';
					style +=  'width:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_width')][fieldname+'_width'].value+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_width')][fieldname+'_width'].units+' !important;'
					style +=  'max-width:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_max_width')][fieldname+'_max_width'].value+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_max_width')][fieldname+'_max_width'].units+' !important;'
					}else{
					style +=  'height:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'height')]['height'].value+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'height')]['height'].units+' !important;';
					style +=  'max-height:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'max_height')]['max_height'].value+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'max_height')]['max_height'].units+' !important;';
					style +=  'width:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'width')]['width'].value+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'width')]['width'].units+' !important;'
					style +=  'max-width:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'max_width')]['max_width'].value+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'max_width')]['max_width'].units+' !important;'
					} 
				}
				
				if(types.includes('text') == true){
				  style += 'font-family: \"'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'font_family')].font_family.value.family+'\", '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'font_family')].font_family.value.category+' !important; font-size:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'font_size')].font_size.value+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'font_size')].font_size.units+' !important; text-transform: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'text_transform')].text_transform.value+'!important;';
				}

				if(types.includes('alignment') == true){
					/*style += 'text-align: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_alignment')][fieldname+'_alignment'].value+''*/

					style += 'text-align: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_alignment')][fieldname+'_alignment'].value+'';
				}

				if(types.includes('text_alignment') == true){
					/*style += 'text-align: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_text_alignment')][fieldname+'_text_alignment'].value+''*/

					style += 'text-align: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, fieldname+'_text_alignment')][fieldname + '_text_alignment'].value+'';
				}

				return style;
			}
		},
	}
</script>
<template>
	<a v-if="$attrs.liveMode == false" :href="'javascript:;'">
					<b-card v-if="$attrs.content[filterStylesKey($attrs.content, 'card_layout')].card_layout.value == 'vertical'" no-body class="border shadow-none border-radius-1em" @mouseenter="hoverIndex = index" @mouseleave="hoverIndex = -1" >
						<!--<transition name="fade">
						<b-card-img v-if="product.cover_image" :src="storageurl+product.cover_image.src.replaceAll('//', '/')" />
						
						</transition>-->
						<transition name="fade">
						<b-card-img-lazy v-if="(product.cover_image) && ('gallery' in product.cover_image)"class="rounded-0" :src="storageurl+product.cover_image.gallery.replaceAll('//', '/')" :style="compileElementStyles('image', ['image', 'border', 'margin', 'padding', 'sizing'])" ></b-card-img-lazy>
                        <b-card-img-lazy v-else-if="(product.cover_image) && ('thumb' in product.cover_image)"class="rounded-0" :src="storageurl+product.cover_image.thumb.replaceAll('//', '/')" :style="compileElementStyles('image', ['image', 'border', 'margin', 'padding', 'sizing'])" ></b-card-img-lazy>
                         <b-card-img-lazy v-else-if="(product.cover_image) && ('src' in product.cover_image)"class="rounded-0" :src="storageurl+product.cover_image.src.replaceAll('//', '/')" :style="compileElementStyles('image', ['image', 'border', 'margin', 'padding', 'sizing'])" ></b-card-img-lazy>
		                  <b-card-img-lazy v-else :src="'/images/product-placeholder.png'" alt="Image" class="rounded-0" :style="compileElementStyles('image', ['image', 'border', 'margin', 'padding', 'sizing'])" ></b-card-img-lazy>
						</transition>
						<b-card-text class="p-4" :style="compileElementStyles('card_body', ['border', 'margin', 'padding'])">
							<component :is="headerComponent" :style="compileElementStyles('title', ['text', 'text_alignment'])" :class="{ 'paragraph-truncate-line2' : this.$attrs.elements[this.filterStylesKey(this.$attrs.elements, 'truncate_title')].truncate_title.value == true}" />
							<p v-if="$attrs.content[filterStylesKey($attrs.content, 'show_description')].show_description.value == true" class="text-dark mb-0" :class="{ 'paragraph-truncate-line2' : $attrs.elements[filterStylesKey($attrs.elements, 'truncate_description')].truncate_description.value == true}" :style="compileElementStyles('text', ['text'])" v-html="product.short_description.slice(0, (product.short_description.length > $attrs.content[filterStylesKey($attrs.content, 'description_length')].description_length.value ? $attrs.content[filterStylesKey($attrs.content, 'description_length')].description_length.value : product.short_description.length - 1))"></p>
							<div v-if="$attrs.content[filterStylesKey($attrs.content, 'show_price')].show_price.value == true" class="" :style="compileElementStyles('price', ['alignment'])">
							<span :style="compileElementStyles('price', ['background', 'color','alignment'])" >
								<span class="currency">{{$attrs.selected_currency_symbol}}</span>{{parseFloat(addTax(product, product.sales_price)).toFixed(2)}}
							</span>
							</div>
							<div v-if="$attrs.content[filterStylesKey($attrs.content, 'show_button')].show_button.value == true" :class="'text-'+$attrs.content[filterStylesKey($attrs.content, 'button_position')].button_position.value">
								<a v-if="$attrs.content[filterStylesKey($attrs.content, 'button_hover_effect')].button_hover_effect.value == false" :class="loadDefaultClasses('btn btn-peach')" :style="compileElementStyles('button', ['background', 'color', 'border'])" :href="'javascript:;'">
											More Details
										</a>
								<transition name="fade-right">
									<a v-if="(hoverIndex == index) && ($attrs.content[filterStylesKey($attrs.content, 'button_hover_effect')].button_hover_effect.value == true)" :class="loadDefaultClasses('btn btn-peach')" :style="compileElementStyles('button', ['background', 'color', 'border'])" :href="'javascript:;'">
											More Details
										</a>
								</transition>
								</div>
						</b-card-text>
					</b-card>
					<b-card v-if="$attrs.content[filterStylesKey($attrs.content, 'card_layout')].card_layout.value == 'horizontal'" no-body class="overflow-hidden" @mouseenter="hoverIndex = index" @mouseleave="hoverIndex = -1">
					    <b-row no-gutters>
					      <b-col md="3">
					        <b-card-img-lazy v-if="(product.cover_image) && ('gallery' in product.cover_image)"class="rounded-0" :src="storageurl+product.cover_image.gallery.replaceAll('//', '/')" :style="compileElementStyles('image', ['image', 'border', 'margin', 'padding', 'sizing'])"></b-card-img-lazy>
                        	<b-card-img-lazy v-else-if="(product.cover_image) && ('thumb' in product.cover_image)"class="rounded-0" :src="storageurl+product.cover_image.thumb.replaceAll('//', '/')" :style="compileElementStyles('image', ['image', 'border', 'margin', 'padding', 'sizing'])"></b-card-img-lazy>
                         	<b-card-img-lazy v-else-if="(product.cover_image) && ('src' in product.cover_image)"class="rounded-0" :src="storageurl+product.cover_image.src.replaceAll('//', '/')" :style="compileElementStyles('image', ['image', 'border', 'margin', 'padding', 'sizing'])"></b-card-img-lazy>
		                  	<b-card-img-lazy v-else :src="'/images/product-placeholder.png'" alt="Image" class="rounded-0" :style="compileElementStyles('image', ['image', 'border', 'margin', 'padding', 'sizing'])"></b-card-img-lazy>
					      </b-col>
					      <b-col md="9" class="d-flex flex-column justify-content-center">
					        <b-card-body class="flex-inherit" :style="compileElementStyles('card_body', ['border', 'margin', 'padding'])">
					          <component :is="headerComponent" :style="compileElementStyles('title', ['text'])" :class="{ 'paragraph-truncate-line2' : this.$attrs.elements[this.filterStylesKey(this.$attrs.elements, 'truncate_title')].truncate_title.value == true}"  />
								<p class="text-dark mb-0" v-html="product.short_description.slice(0, (product.short_description.length > $attrs.content[filterStylesKey($attrs.content, 'description_length')].description_length.value ? $attrs.content[filterStylesKey($attrs.content, 'description_length')].description_length.value : product.short_description.length - 1))" :style="compileElementStyles('text', ['text'])"></p>
					          	<div v-if="$attrs.content[filterStylesKey($attrs.content, 'show_price')].show_price == true" class="" :style="compileElementStyles('price', ['alignment'])">
									<span :style="compileElementStyles('price', ['background', 'color','alignment'])" >
										<span class="currency">{{$attrs.selected_currency_symbol}}</span>{{parseFloat(addTax(product, product.sales_price) * (1 / $attrs.exchange_rates[$attrs.company.trading_currency_code])).toFixed(2)}}
									</span>
								</div>
							<div v-if="$attrs.content[filterStylesKey($attrs.content, 'show_button')].show_button.value == true">
								<a v-if="$attrs.content[filterStylesKey($attrs.content, 'button_hover_effect')].button_hover_effect.value == false" :class="loadDefaultClasses('btn btn-primary')" :style="compileElementStyles('button', ['background', 'color', 'border'])" :href="'/products/'+product.url_rewrite">
											More Details
										</a>
								<transition name="fade-right">
									<a v-if="(hoverIndex == index) && ($attrs.content[filterStylesKey($attrs.content, 'button_hover_effect')].button_hover_effect.value == true)" :class="loadDefaultClasses('btn btn-primary')" :style="compileElementStyles('button', ['background', 'color', 'border'])" :href="'/products/'+product.url_rewrite">
											More Details
										</a>
								</transition>
								</div>
					        </b-card-body>
					      </b-col>
					    </b-row>
					  </b-card>
				</a>
				<router-link v-else :to="'/product/'+product.url_rewrite">
					<b-card v-if="$attrs.content[filterStylesKey($attrs.content, 'card_layout')].card_layout.value == 'vertical'" no-body class="border shadow-none border-radius-1em" @mouseenter="hoverIndex = index" @mouseleave="hoverIndex = -1" >
						<!--<transition name="fade">
						<b-card-img v-if="product.cover_image" :src="storageurl+product.cover_image.src.replaceAll('//', '/')" />
						
						</transition>-->
						<transition name="fade">

						<b-card-img-lazy v-if="(product.cover_image) && ('gallery' in product.cover_image)"class="rounded-0" :src="storageurl+product.cover_image.gallery.replaceAll('//', '/')" ></b-card-img-lazy>
                        <b-card-img-lazy v-else-if="(product.cover_image) && ('thumb' in product.cover_image)"class="rounded-0" :src="storageurl+product.cover_image.thumb.replaceAll('//', '/')" ></b-card-img-lazy>
                         <b-card-img-lazy v-else-if="(product.cover_image) && ('src' in product.cover_image)"class="rounded-0" :src="storageurl+product.cover_image.src.replaceAll('//', '/')" ></b-card-img-lazy>
		                  <b-card-img-lazy v-else :src="'/images/product-placeholder.png'" alt="Image" class="rounded-0"></b-card-img-lazy>
						</transition>
						<b-card-text class="p-4" :style="compileElementStyles('card_body', ['border', 'margin', 'padding'])">
							<component :is="headerComponent" :style="compileElementStyles('title', ['text', 'text_alignment'])" :class="{ 'paragraph-truncate-line2' : this.$attrs.elements[this.filterStylesKey(this.$attrs.elements, 'truncate_title')].truncate_title.value == true}"  />
							<p class="text-dark mb-0" :class="{ 'paragraph-truncate-line2' : $attrs.elements[filterStylesKey($attrs.elements, 'truncate_description')].truncate_description.value == true}" :style="compileElementStyles('text', ['text', 'text_alignment'])" v-html="product.short_description.slice(0, (product.short_description.length > $attrs.content[filterStylesKey($attrs.content, 'description_length')].description_length.value ? $attrs.content[filterStylesKey($attrs.content, 'description_length')].description_length.value : product.short_description.length - 1))"></p>
							<div v-if="$attrs.content[filterStylesKey($attrs.content, 'show_price')].show_price == true" class="" :style="compileElementStyles('price', ['alignment'])">
							<span :style="compileElementStyles('price', ['background', 'color','alignment', 'margin', 'padding'])" >
								<span class="currency">{{$attrs.selected_currency_symbol}}</span>{{parseFloat(addTax(product, product.sales_price) * (1 / $attrs.exchange_rates[product.company.trading_currency_code])).toFixed(2)}}
							</span>
						</div>
					<div v-if="$attrs.content[filterStylesKey($attrs.content, 'show_button')].show_button.value == true">
							<a v-if="$attrs.content[filterStylesKey($attrs.content, 'button_hover_effect')].button_hover_effect.value == false" :class="loadDefaultClasses('btn btn-primary')" :style="compileElementStyles('button', ['background', 'color', 'border'])" :href="'/products/'+product.url_rewrite">
											More Details
										</a>
						<transition name="fade-right">
							<router-link v-if="(hoverIndex == index) && ($attrs.content[filterStylesKey($attrs.content, 'button_hover_effect')].button_hover_effect.value == true)" class="border-bottom border-primary text-uppercase font-size-12 ml-auto text-dark my-auto py-2" :to="'/product/'+product.url_rewrite">
								More Details
							</router-link>
						</transition>
					</div>
						
						</b-card-text>
					</b-card>
					<b-card v-if="$attrs.content[filterStylesKey($attrs.content, 'card_layout')].card_layout.value == 'horizontal'" no-body class="overflow-hidden" @mouseenter="hoverIndex = index" @mouseleave="hoverIndex = -1">
					    <b-row no-gutters>
					      <b-col md="3">
					        <b-card-img-lazy v-if="(product.cover_image) && ('gallery' in product.cover_image)"class="rounded-0" :src="storageurl+product.cover_image.gallery.replaceAll('//', '/')" ></b-card-img-lazy>
                        <b-card-img-lazy v-else-if="(product.cover_image) && ('thumb' in product.cover_image)"class="rounded-0" :src="storageurl+product.cover_image.thumb.replaceAll('//', '/')" ></b-card-img-lazy>
                         <b-card-img-lazy v-else-if="(product.cover_image) && ('src' in product.cover_image)"class="rounded-0" :src="storageurl+product.cover_image.src.replaceAll('//', '/')" ></b-card-img-lazy>
		                  <b-card-img-lazy v-else :src="'/images/product-placeholder.png'" alt="Image" class="rounded-0"></b-card-img-lazy>
					      </b-col>
					      <b-col md="9" class="d-flex flex-column justify-content-center">
					        <b-card-body class="flex-inherit" :title="product.name">
					          <b-card-text class="text-dark" v-html="product.short_description.slice(0, (product.short_description.length > $attrs.content[filterStylesKey($attrs.content, 'description_length')].description_length.value ? $attrs.content[filterStylesKey($attrs.content, 'description_length')].description_length.value : product.short_description.length - 1))">
					          </b-card-text>
					          <div v-if="$attrs.content[filterStylesKey($attrs.content, 'show_price')].show_price.value == true" class="" :style="compileElementStyles('price', ['alignment'])" >
								<span :style="compileElementStyles('price', ['background', 'color','alignment'])" >
									<span class="currency">{{$attrs.selected_currency_symbol}}</span>{{parseFloat(addTax(product, product.sales_price) * (1 / $attrs.exchange_rates[product.company.trading_currency_code])).toFixed(2)}}
								</span>
								</div>
								<div v-if="$attrs.content[filterStylesKey($attrs.content, 'show_button')].show_button.value == true" :class="'text-'+$attrs.content[filterStylesKey($attrs.content, 'button_position')].button_position.value">
								<router-link v-if="$attrs.content[filterStylesKey($attrs.content, 'button_hover_effect')].button_hover_effect.value == false" :class="loadDefaultClasses('btn btn-primary')" :style="compileElementStyles('button', ['background', 'color', 'border'])" :href="'/products/'+product.url_rewrite">
											More Details
										</router-link>

								<transition name="fade-right">
									<router-link v-if="(hoverIndex == index) && ($attrs.content[filterStylesKey($attrs.content, 'button_hover_effect')].button_hover_effect.value == true)" class="border-bottom border-primary text-uppercase font-size-12 ml-auto text-dark my-auto py-2" :to="'/product/'+product.url_rewrite">
										More Details
									</router-link>
								</transition>
							</div>

					        </b-card-body>
					      </b-col>
					    </b-row>
					  </b-card>
				</router-link>
</template>