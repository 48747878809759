<template>
	
	<div>
		<div v-if="$attrs.type == 'spacer'"></div>
		<div v-else-if="$attrs.type == 'divider'">
			<hr>
		</div>
	</div>

</template>

<script type="text/javascript">
	export default {
		
	}

</script>