const valuationComponents = [

	{
		icon: 'bx bx-diamond',
		name: 'Diamond',
		config: {
				cuts: [
                      {html: "Round Brilliant", value: 0.0061},
                      {html: "Princess Cut", value: 0.0082},
                      {html: "Asscher Step Cut", value: 0.0080},
                      {html: "Cushion Brilliant", value: 0.00815},
                      {html: "Heart Brilliant", value: 0.0059},
                      {html: "Trillion Brilliant", value: 0.0057},
                      {html: "Marquise Brilliant", value: 0.0058},
                      {html: "Pear Brilliant", value: 0.0060},
                      {html: "Oval Brilliant", value: 0.00625},
                      {html: "Emerald Step Cut", value: 0.0092},
                      {html: "Radiant Brilliant", value: 0.0081},
                    ],
                    colors: [
                      {html: "D", value: "D"},
                      {html: "E", value: "E"},
                      {html: "F", value: "F"},
                      {html: "G", value: "G"},
                      {html: "H", value: "H"},
                      {html: "I", value: "I"},
                      {html: "J", value: "J"},
                      {html: "K", value: "K"},
                      {html: "L", value: "L"},
                      {html: "M", value: "M"},
                      {html: "N", value: "N"},
                      {html: "O", value: "O"},
                      {html: "P", value: "P"},
                      {html: "Q", value: "Q"},
                      {html: "R", value: "R"},
                      {html: "S", value: "S"},
                      {html: "T", value: "T"},
                      {html: "U", value: "U"},
                      {html: "V", value: "V"},
                      {html: "W", value: "W"},
                      {html: "X", value: "X"},
                      {html: "Y", value: "Y"},
                      {html: "Z", value: "Z"},
                      
                    ],
                    clarity:[
                        {html: "IF-FL", value: "FL"},
                        {html: "VVS1", value: "VVS1"},
                        {html: "VVS2", value: "VVS2"},
                        {html: "VS1", value: "VS1"},
                        {html: "VS2", value: "VS2"},
                        {html: "SL1", value: "SL1"},
                        {html: "SL2", value: "SL2"},
                        {html: "L1", value: "L1"},
                    ],
                    certTypes:[
                        {html: "GIA", value: "GIA"},
                        {html: "AGS", value: "AGS"},
                        {html: "EGL", value: "EGL"},
                    ],
                    avgDiameter: '',
                    diameter: '',
                    userLength: '',
                    userWidth: '',
                    userDepth: '',
                    selectedCutValue: 0,
                    estimatedCaratWeight: 0,
                    show: false,
                    highShouldersValue: 0,
                    wideFatValue: 0,
                    culetValue: 0,
                    wideCornersValue: 0,
                    pavilionBulgeValue: 0,
                    highShouldersActive: false,
                    wideFatActive: false,
                    culetActive: false,
                    wideCornersActive: false,
                    pavilionBulgeActive: false,
                    highShouldersEnabled: 1,
                    wideFatEnabled: 1,
                    wideCornersEnabled: 1,
                    pavilionBulgeEnabled: 1,
                    selectedColorValue: '',
                    selectedCertType: '',
                    selectedClarityValue: ''
		}
	},
	{
		icon: 'bx bx-diamond',
		name: 'Gemstone'
	},
	{
		icon: 'bx bx-diamond',
		name: 'Bezel'
	},
	{
		icon: 'bx bx-diamond',
		name: 'Setting'
	}

];

export {valuationComponents};