<script>
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import vue2Dropzone from 'vue2-dropzone'
import Multiselect from 'vue-multiselect'

import Layout from '../../layouts/main'
import PageHeader from '../../components/page-header'
import FilterListTree from "../../components/widgets/FilterListTree";
import FilterProductBrands from "../../components/widgets/FilterProductBrands";
/**
 * Add-product component
 */
export default {
  components: { vueDropzone: vue2Dropzone, Multiselect, Layout, PageHeader, ckeditor: CKEditor.component, FilterListTree, FilterProductBrands },
  data() {
    return {
      name: 'StockSettings',
      title: 'Settings',
      items: [
        {
          text: 'Stock',
          href: '/',
        },
        {
          text: 'Settings',
          active: true,
        },
      ],
      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        thumbnailHeight: 300,
        thumbnailWidth: 300,
      },
      editor: ClassicEditor,
      editorData:
        "<h3>Hello World!</h3><h5><b>This is an simple editable area.</b></h5>",
      content: "<h1>Some initial content</h1>",
      value: null,
      value1: null,
      all_brands: [],
      all_tax_rules: [],
      all_attributes: [],
      attribute_values: [],
      selected_customer_groups: [],
      selected_customer_type: '',
      product_brand_id: null,
      product_type_id: 0,
      cost_price: 0,
      trade_price: 0,
      sales_price: 0,
      inc_tax_price: 0,
      import_tax: 0,
      export_tax: 0,
      active_tax_rule: 0,
      carnet_price: 0,
      carnet_weight: 0,
      profit_margin: 0,
      calculated_cost_price: 0,
      calculated_tax_duties: 0,
      bulkquantity: 0,
      product_attributes: [],
      product_variants: [],
      product_bulk_pricing: [],
      product_condition: [],
      selected_attribute: '',
      active_tax_rule: '',
      searchbrand: '',
      searchresult: '',
      brandsearch_results: [],
      brandresults_start: 0,
      brandresults_end: 5,
      product_attribute_success: null,
      quantity_lang:{
            bulk:{
              qtytooltip: 'Add quantity where discount starts to apply. For example if you enter quantity of 10, discounts will apply on orders of 10 and above.'
            }
          },
      conditions: [
        'New',
        'Used',
        'Refurbished',
        'Custom',
      ],
      options: [
        'Alaska',
        'Hawaii',
        'California',
        'Nevada',
        'Oregon',
        'Washington',
        'Arizona',
        'Colorado',
        'Idaho',
        'Montana',
        'Nebraska',
        'New Mexico',
        'North Dakota',
        'Utah',
        'Wyoming',
        'Alabama',
        'Arkansas',
        'Illinois',
        'Iowa',
      ],
    }
  },
  methods:{

  },
  created(){


  },
  computed: {

  }
}
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-8">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Basic Information <b-button v-b-toggle.basicinfo class="m-1" variant="light" rounded><i class="bx bx-plus"></i></b-button></h4>
            <p class="card-title-desc">Fill all information below</p>
            <b-collapse id="basicinfo" visible class="mt-1">
            <form>
              <div class="row mt-3">
                <div class="col-sm-12">
                  <div class="row">
                    <div class="col-8">
                    <div class="form-group">
                      <label for="productname">Product Name</label>
                      <input id="productname" name="productname" type="text" class="form-control" />
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="form-group">
                      <label for="productname">Reference</label>
                      <input id="productname" name="productname" type="text" class="form-control" />
                    </div>
                  </div>
                  </div>
                </div>
                 <div class="col-sm-12">
          <b-tabs pills variant="light" content-class="px-0 pt-2 text-muted">
              <b-tab active>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-home"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">Summary</span>
                </template>
                <div class="form-group">
                    <textarea id="productdesc" class="form-control" rows="5"></textarea>
                  </div>
              </b-tab>
              <b-tab>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="far fa-user"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">Description</span>
                </template>
                  <ckeditor v-model="editorData" :editor="editor"></ckeditor>
              </b-tab>
              <b-tab>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="far fa-envelope"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">Trade Description</span>
                </template>
                <ckeditor v-model="editorData" :editor="editor"></ckeditor>
              </b-tab>
            </b-tabs>
                  
                </div>

                <div class="col-sm-4">
                 
                  
                </div>
              </div>

            </form>
          </b-collapse>
          </div>
        </div>
        


      
      </div>
      <div class="col-lg-4">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Status</h4>
            <div class="form-group text-center my-2">
                    <b-form-checkbox switch size="lg" class="my-auto" inline value="1"
                      unchecked-value="0"><small>Active</small></b-form-checkbox>
                      <b-form-checkbox switch size="lg" class="my-auto" inline value="1"
                      unchecked-value="0"><small>Public</small></b-form-checkbox>
                       <b-form-checkbox switch size="lg" class="my-auto" inline alue="1"
                      unchecked-value="0"><small>Trade</small></b-form-checkbox>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Product Type</h4>
            <div class="form-group mt-3">
              <b-form-radio
                      class="custom-radio custom-radio-outline custom-radio-primary mb-3"
                      value="0"
                      v-model="product_type_id"
                    >Standard Product
              </b-form-radio>
              <b-form-radio
                      class="custom-radio custom-radio-outline custom-radio-primary mb-3"
                      value="1"
                      v-model="product_type_id"
                    >Product with Variations
              </b-form-radio>
              <b-form-radio
                      class="custom-radio custom-radio-outline custom-radio-primary mb-3"
                      value="2"
                      v-model="product_type_id"
                    >Digital Download
              </b-form-radio>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Main Category</h4>
            <div class="form-group">
                    <multiselect v-model="value" :options="options"></multiselect>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Additional Categories <b-button v-b-toggle.additionalcats class="m-1" variant="light" rounded><i class="bx bx-plus"></i></b-button></h4>
              <b-collapse id="additionalcats" class="mt-1">
                <FilterListTree></FilterListTree>
              </b-collapse>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Collections <b-button v-b-toggle.collections class="m-1" variant="light" rounded><i class="bx bx-plus"></i></b-button></h4>
            <b-collapse id="collections" class="mt-1">
            <div class="form-group">
                    <multiselect v-model="value1" :options="options" :multiple="true" placeholder="Select existing tag..."></multiselect>
                    <b-form-tags placeholder="Add new tag..." input-id="tags-basic" v-model="value1" class="my-2 text-muted"></b-form-tags>
            </div>
          </b-collapse>
          </div>
        </div>
        <div class="card">
            <div class="card-body">
              <h4 class="card-title">Brand <b-button v-b-toggle.collapse class="m-1" variant="light" rounded><i class="bx bx-plus"></i></b-button></h4>
                      <div class="row">
                        <div class="col-md-12">
                          <b-collapse id="collapse" class="mt-1">
                            <div class="form-control-group">
                              <div class="input-group mb-3">
                                <div class="search-box w-75">
                                  <div class="position-relative">
                                    <input class="form-control" type="text" v-model="searchbrand" v-on:keyup="searchBrands" v-on:keydown="brandsDefault">
                                    <i class="bx bx-search-alt search-icon"></i>
                                    <a v-b-tooltip.hover title="Reset" placement="top" href="javascript:;" @click="[brandsearch_results = [], searchbrand = undefined]">
                                      <i class="bx bx-reset search-icon reset"></i>
                                    </a>
                                  </div>
                                </div>
                                <div class="w-25 pl-2">
                                    <a href="javascript:;" class="btn btn-rounded btn-primary btn-block" @click="brandsOnFocus">See All</a>
                                </div>
                             </div>
                            <ul class="list-group brands-searchlist" v-if="brandsearch_results.length">
                              <li v-for="searchresult in brandsearch_results.slice(brandresults_start, brandresults_end)" class="list-group-item d-flex">
                                <img class="avatar-md rounded-circle border" :src="'/public/storage/'+searchresult.logo">
                                <span class="mr-auto ml-2 my-auto">{{searchresult.name}}</span>
                                <a class="btn btn-outline-light btn-rounded ml-auto my-auto" href="javascript:;" @click="toggleBrand(false, searchresult.id)">Select Brand <i class="bx bx-plus"></i></a>
                              </li>
                              <li v-if="brandsearch_results.length && (brandresults_end <= brandsearch_results.length)" class="list-group-item py-4 text-center" v-on:click="brandresults_end += 3">Load More
                              </li>
                            </ul>
                          </div>
                        
                      <div class="col-md-12" v-if="product_brand_id != null">
                        <h5 class="mt-4 mb-4 pb-2 form-row-title" >Active Brand</h5>
                          <ul class="list-group">
                            <li v-for="searchresult in activeBrand(product_brand_id)" class="list-group-item d-flex">
                            <img class="avatar-md rounded-circle border" :src="'/public/storage/'+searchresult.logo">
                            <span class="my-auto ml-2">{{searchresult.name}}</span>
                              <a class="btn btn-remove ml-auto font-size-20 my-auto" href="javascript:;" @click="toggleBrand(true, searchresult.id)">
                                <i class="bx bx-trash-alt"></i>
                              </a>
                            </li>
                          </ul>
                      </div>
                    </b-collapse>
                  </div>
                </div>
        </div>
      </div>
       
      <div class="card">
          <div class="card-body">
            <h4 class="card-title">Tags <b-button v-b-toggle.tags class="m-1" variant="light" rounded><i class="bx bx-plus"></i></b-button></h4>
            <b-collapse id="tags" class="mt-1">
            <div class="form-group">
                    <multiselect v-model="value1" :options="options" :multiple="true" placeholder="Select existing tag..."></multiselect>
                    <b-form-tags placeholder="Add new tag..." input-id="tags-basic" v-model="value1" class="my-2 text-muted"></b-form-tags>
            </div>
          </b-collapse>
          </div>
        </div>

          <div class="card">
          <div class="card-body">
            <h4 class="card-title">Meta Data <b-button v-b-toggle.metadata class="m-1" variant="light" rounded><i class="bx bx-plus"></i></b-button></h4>
            <p class="card-title-desc">Fill all information below</p>
             <b-collapse id="metadata" class="mt-1">
            <form>
              <div class="row">
                <div class="col-sm-12">
                  <div class="form-group">
                    <label for="metatitle">Product URL</label>
                    <input id="metatitle" name="productname" type="text" class="form-control" />
                  </div>
                  <div class="form-group">
                    <label for="metatitle">Meta title</label>
                    <input id="metatitle" name="productname" type="text" class="form-control" />
                  </div>
                  <div class="form-group">
                    <label for="metakeywords">Meta Keywords</label>
                    <input
                      id="metakeywords"
                      name="manufacturername"
                      type="text"
                      class="form-control"
                    />
                  </div>
                </div>

                <div class="col-sm-12">
                  <div class="form-group">
                    <label for="metadescription">Meta Description</label>
                    <textarea id="metadescription" class="form-control" rows="5"></textarea>
                  </div>
                </div>
              </div>

              <button type="submit" class="btn btn-primary mr-1">Save Changes</button>
              <button type="submit" class="btn btn-secondary">Cancel</button>
            </form>
            </b-collapse>
          </div>
        </div>


         <div class="card">
          <div class="card-body">
            <h4 class="card-title">Condition <b-button v-b-toggle.condition class="m-1" variant="light" rounded><i class="bx bx-plus"></i></b-button></h4>
              <b-collapse id="condition" class="mt-1">
                <b-form-group>
                  <multiselect v-model="product_condition" :options="conditions"></multiselect>
                  <b-form-checkbox switch size="lg" class="my-auto" inline value="1" unchecked-value="0"></b-form-checkbox>
                </b-form-group>
              </b-collapse>
          </div>
        </div>

        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Reference Codes <b-button v-b-toggle.reference class="m-1" variant="light" rounded><i class="bx bx-plus"></i></b-button></h4>
              <b-collapse id="reference" class="mt-1">
                <b-form-group>
                 <label for="price">EAN-13 or JAN Barcode</label>
                    <input id="price" name="price" type="text" class="form-control" />
                    <label for="price">UPC Barcode</label>
                    <input id="price" name="price" type="text" class="form-control" />
                </b-form-group>
              </b-collapse>
          </div>
        </div>


    </div>
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Custom Tabs</h4>
            <p class="card-title-desc">Example of custom tabs</p>
            <b-tabs justified nav-class="nav-tabs-custom" content-class="p-3 text-muted">
              <b-tab active>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-home"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">Home</span>
                </template>
               
              </b-tab>
              <b-tab>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="far fa-user"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">Profile</span>
                </template>
                {{ content }}
              </b-tab>
              <b-tab>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="far fa-envelope"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">Messages</span>
                </template>
                {{ text }}
              </b-tab>
              <b-tab>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-cog"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">Settings</span>
                </template>
                {{ content }}
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>
