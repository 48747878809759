<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import Swal from "sweetalert2";
import SideSlidePanel from "../../components/widgets/SideSlidePanel";
import SearchCompanyUser from "../../components/widgets/SearchCompanyUser";
import DepartmentTreeRadios from "../../components/widgets/DepartmentTreeRadios";
import DepartmentTreeRadiosChild from "../../components/widgets/DepartmentTreeRadiosChild";
import UserList from "../../components/widgets/UserList";
import SearchCompanyUsers from "../../components/widgets/SearchCompanyUsers";
import Subscription from '../../components/widgets/subscription'

/**
 * Contacts-list component
 */
export default {
  components: { Layout, PageHeader, SideSlidePanel, SearchCompanyUser, UserList, SearchCompanyUsers, Subscription, DepartmentTreeRadios, DepartmentTreeRadiosChild },
  data() {
    return {
      title: "Departments",
      view: 'list',
      userAddToDepartment: false,
      departments: [],
      load_error: [],
      success_message: false,
      error_message: false,
      error_reponse: [],
      delete_confirm: false,
      delete_action: '',
      deleted_message: false,
      loadingForm: false,
      selectedids: [],
      currentPage: 1,
      limit: 25,
      selectedids: [],
      orderDesc: true,
      order: 'desc',
      sortby: 'id',
      search: '',
      timeout: null,
      formResponse: '',
      errorResponse: [],
      addFormSubmitted: false,
      updateManagerBtn: false,
      isCheckAll: false, 
      newDepartment:{
        id: '',
        name: '',
        description: '',
        manager_id: '',
        company_id: this.$attrs.company.id,
      },
      updateDepartment: {
        id: '',
        name: '',
        description: '',
        manager_id: '',
        company_id: this.$attrs.company.id,
      },
      updatePanel: false,
      addPanel: false,
      staffPanel: false,
      limits: [
        {value: 25, text: "25"},
        {value: 50, text: "50"},
        {value: 100, text: "100"},
      ],
      items: [
        {
          text: "Company",
          href: "/"
        },
        {
          text: "Departments",
          active: true
        }
      ]
    };
  },
  created(){
    this.requestheader = {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}};
    if(this.$attrs.validsubscription == 0){
      this.title = 'Subscription';
      this.items[1].text = 'Subscription';
    }
    this.searchResource();
  },
  methods: {
    checkAll: function(){ 
                 this.isCheckAll = !this.isCheckAll; 
                 this.selectedids = []; 
                 if(this.isCheckAll){ 
                     // Check all 
                     for (var key in this.departments.data) {          
                        this.selectedids.push(this.departments.data[key].id); 
                     } 
                 } 
              }, 
      updateCheckall: function(){ 
                 if(this.selectedids.length == this.departments.data.length){
                    this.isCheckAll = true; 
                 }else{ 
                    this.isCheckAll = false; 
                 } 
      },
      parentSelected(id){
                this.newDepartment.parent_id = id;
                this.updateDepartment.parent_id = id;
    }, 
    deleteDepartmentEvent(action){
      this.delete_action = action;
      this.delete_confirm = true;
    },
    /*deleteDepartment(action){
      axios.post('/api/company/department/'+value.id+'/destroy', {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response =>{
        this.deleted_message = true;
      }).catch(error => {
        this.error_message = true;
      });
    },*/
    searchResource(){
      axios.get('/api/company/departments?page='+this.currentPage+'&limit='+this.limit+'&sortby='+this.sortby+'&order='+this.order+'&search='+this.search+'', {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
            this.departments = response.data;
      }).catch(error => {
            this.load_error = response.error;
      });
    },
    searchFilter(e) {
      const searchStr = e.target.value;
      this.searchResource();
    },
    setTimeout(callback) {
        this.clearTimeout()
        this.timeout = setTimeout(() => {
          this.clearTimeout()
          callback()
        }, 3000)
    },
    clearTimeout() {
        if (this.timeout) {
          clearTimeout(this.timeout)
          this.timeout = null;
        }
    },
    validateNewDepartment(){
      this.addFormSubmitted = true;
      if(this.newDepartment.name.length < 2){
        this.errorResponse.push({error: "Please Enter Department Two Characters or Longer"});
      }
      if((this.newDepartment.description.length < 15) || (this.newDepartment.description.length > 200)){
        this.errorResponse.push({error: "Please Enter Department Description Between 15 - 200 Characters"});
      }
      if(this.newDepartment.manager_id == ''){
        this.errorResponse.push({error: "Please Select a Department Manager"});
      }
      if(this.errorResponse == ''){
        this.addNewDepartment();
      }else{
        this.setTimeout(() => {
          this.addFormSubmitted = false;
        });
      }
      
    },
    deleteDepartment(action){
      axios.post('/api/company/department/'+value.id+'/destroy', {}, this.requestheader).then(response =>{
        this.deleted_message = true;
      }).catch(error => {
        this.error_message = true;
      });
    },
 addNewDepartment(){
      this.loadingForm = true;
      axios.post('/api/company/department/store', this.newDepartment, this.requestheader).then(response => {
          this.formResponse = response.data;
          //this.addFormSubmitted = false;
          this.success_message = true;
          this.searchResource();
           var self = this;
                setTimeout(function(){
                self.loadingForm = 0;
                self.addPanel = false;
                self.searchResource();
                document.getElementById("html").className = "scroll";
                self.newDepartment = {
                            id: '',
                            name: '',
                            description: '',
                            manager_id: '',
                            company_id: this.$attrs.company.id,
                  };
                }, 1500);
      }).catch(error => {
            this.error_reponse = error.response.data.errors;
            this.error_message = true;
            let self = this;
              setTimeout(function(){
                self.loadingForm = false;
              }, 1500);
      });
    },
    updateExistingDepartment(){
      this.loadingForm = true;
        axios.post('/api/company/department/'+this.updateDepartment.id+'/update', this.updateDepartment, this.requestheader).then(response => {
          this.formResponse = response.data;
          var self = this;
                setTimeout(function(){
                self.searchResource();  
                self.loadingForm = false;
                }, 1500);
        }).catch(error => {
          this.error_reponse = error.response.data.errors;
          this.error_message = true;
          let self = this;
            setTimeout(function(){
              self.loadingForm = false;
            }, 1500);
          });
      },
      /*
    addNewDepartment(){
      this.loadingForm = true;
      axios.post('/api/company/department/store', this.newDepartment, {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
          this.formResponse = response.data;
          //this.addFormSubmitted = false;
          this.success_message = true;
          this.searchResource();
           var self = this;
                setTimeout(function(){
                self.loadingForm = 0;
                self.addPanel = false;
                self.newDepartment = {
                            id: '',
                            name: '',
                            description: '',
                            manager_id: '',
                            company_id: this.$attrs.company.id,
                  };
                }, 1500);
      }).catch(error => {
            this.error_reponse = error.response.data.errors;
            this.error_message = true;
            let self = this;
              setTimeout(function(){
                self.loadingForm = false;
              }, 1500);
      });
    },
    updateExistingDepartment(){
      this.loadingForm = true;
        axios.post('/api/company/department/'+this.updateDepartment.id+'/update', this.updateDepartment, {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
          this.formResponse = response.data;
          var self = this;
                setTimeout(function(){
                self.loadingForm = false;
                }, 1500);
        }).catch(error => {
          this.error_reponse = error.response.data.errors;
          this.error_message = true;
          let self = this;
            setTimeout(function(){
              self.loadingForm = false;
            }, 1500);
          });
      },*/
      getManager(selectedOption){
        this.newDepartment.manager_id = selectedOption.companies[0].id;
      },
      filterSelected(id){
        if(this.selectedids.length > 0){
          var exists = this.selectedids.filter(item => item == id);
          if(exists.length > 0){
            return true;
          }else{
            return false
          }
        }else{
          return false;
        }
      }
      

  }
};
</script>

<template>
  <Layout v-if="$attrs.validsubscription == 1"   :nickname="$attrs.nickname" :user="$attrs.user" :menuItems="$attrs.menuItems" >
    <PageHeader :title="title" :items="items" />

     <div class="row mb-3">

      <b-col md="12">
                    <transition name="slide-success" mode="out-in">
                  <b-alert id="success-message" variant="success" :show="success_message"  dismissible @dismissed="success_message = !success_message">
                      <p class="font-size-14">Congratulations, your action has been saved.</p>
                    </b-alert>
                  </transition>
                  </b-col>

           <div class="col-xl-1 col-sm-6">
            <div class="mt-2">
              <b-form-select class="form-control" v-model="limit" :options="limits" @input="searchResource()"></b-form-select>
            </div>
          </div>
         
          <div class="col-lg-2 col-sm-6 d-flex">
          <form class="my-auto">
            <small class="mx-auto my-2 text-center d-block">Results {{departments.from}} - {{departments.to}} of {{departments.total}}</small>
          </form>
        </div>
       
          <div class="col-lg-5 col-sm-3 d-flex">
            <div class="form-inline w-100 my-auto search-box" >
                <div class="position-relative">
                  <input
                    type="text"
                    class="form-control w-100 border-0"
                    placeholder="Search name, reference or description..."
                    v-model="search"
                    @keyup.enter="searchFilter($event)"
                  />
                  <i class="bx bx-search-alt search-icon"></i>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-sm-4 d-flex">
              <ul class="nav nav-pills product-view-nav pt-2 ml-auto">
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': view == 'tree' }" href="javascript: void(0);" @click="view = 'tree'">
                    <i class="mdi mdi-file-tree-outline"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': view == 'grid' }" href="javascript: void(0);" @click="view = 'grid', searchResource(), listview = 'grid'">
                    <i class="bx bx-grid-alt"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': view == 'list' }" href="javascript: void(0);" @click="view = 'list', searchResource(), listview = 'list'">
                    <i class="bx bx-list-ul"></i>
                  </a>
                </li>
                <li class="nav-item">
                 <b-dropdown text="Bulk Actions" variant="primary" class="rounded-pill px-0 mx-0">
                  <b-dropdown-item href="javascript: void(0);">Delete</b-dropdown-item>
                </b-dropdown>
                </li>
                <li class="nav-item">
                 <a
                    class="btn btn-success mb-2 mr-2 text-white"
                    @click="addPanel = !addPanel"
                  >
                    <i class="mdi mdi-plus mr-1"></i> New
                  </a>
                </li>
              </ul>
              <div class="text-sm-right">
                  
                </div>
          </div>       
        </div>
 <div class="row" v-if="view == 'list'">
      <div class="col-lg-12">
        <div class="card">
          <div class="">
            <div class="table-responsive">
              <table class="table table-centered table-wrap table-hover">
                <thead>
                  <tr>
                    <th scope="col" style="width: 100px; text-align: center;"><input type="checkbox" class="align-middle" @click='checkAll()' v-model="isCheckAll" /></th>
                    <th scope="col">Department</th>
                    <th scope="col" class="text-center">Manager</th>

                    <th scope="col" style="width: 250px">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="department in departments.data" :key="department.id" valign="middle">
                    <td>
                       <div class="text-center">
                        <b-form-checkbox class="select-row-btn" v-model="selectedids" :value="department.id" button-variant="primary" button>

                          <i v-if="filterSelected(department.id)" class="bx bx-check"></i><span class="avatar-title rounded-circle" v-else>{{(department.name).slice(0,1)}}</span></b-form-checkbox>
                      </div>   
                      
                    </td>
                    <td>
                      <h5 class="font-size-14 mb-0">
                        <a  :href="'/company/department/'+department.uid" class="text-dark">{{department.name}}</a>
                      </h5>
                    </td>
                    <td>
                      <div v-if="department.manager !== null">
                     <div  v-if="department.manager.user.avatar" class="team text-center">
                       <a class="team-member" :href="'/company/user/'+department.manager.uid" v-b-tooltip.hover :title="'Managed By '+department.manager.user.name">
                        <img class="rounded-circle avatar-xs" :src="department.manager.user.avatar" alt />
                        </a>
                     </div>
                      </div>
                      <div v-else class="text-center">
                        <span class="badge badge-light bg-light">
                          - No Manager Selected -
                        </span>
                      </div>

                     
                    </td>
                    <td>
                      <ul class="list-inline font-size-20 contact-links mb-0">
                        <li class="list-inline-item px-1" >
                          <a v-b-tooltip.hover title="Edit" @click="updateDepartment = JSON.parse(JSON.stringify(department)), updatePanel = true">
                            <i class="bx bx-edit"></i>
                          </a>
                        </li>
                        <li class="list-inline-item px-1">
                          <a v-b-tooltip.hover title="View" :href="'/company/department/'+department.uid">
                            <i class="mdi mdi-eye-outline"></i>
                          </a>
                        </li>
                        <li class="list-inline-item px-1">
                          <a v-b-tooltip.hover title="Staff" @click="updateDepartment = JSON.parse(JSON.stringify(department)), staffPanel = true">
                            <i class="bx bx-group"></i>
                          </a>
                        </li>
                        <!--<li class="list-inline-item px-1">
                          <a v-b-tooltip.hover title="Reports">
                            <i class="bx bx-line-chart"></i>
                          </a>
                        </li>-->
                        <li class="list-inline-item px-1" >
                          <a v-b-tooltip.hover title="Delete" @click="deleteDepartmentEvent(department)">
                            <i class="bx bx-trash-alt"></i>
                          </a>
                        </li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="row">
              <div class="col-lg-12">
                 <b-pagination
              class="justify-content-center"
              pills
              v-model="currentPage"
              :total-rows="departments.total"
              :per-page="departments.per_page"
              aria-controls="my-table"
              @input="searchResource"
            ></b-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="view == 'grid'">
      <div class="col-lg-12">
        <div class="card bg-transparent shadow-none">

          <div class="card-body">
            <div class="row">
            <div v-for="department in departments.data" :key="department.id" class="col-xl-4 col-sm-6 col-xs-12">
        <div class="card text-center overflow-hidden border-radius-1em">

          <div  class="bg-soft-primary bg-light border-radius-top-right-1em border-radius-top-left-1em" style="min-height: 250px;">
            <div class="row">
              
              <div class="col-12 align-self-end">
                <div class="row">
                 <div class="col-md-12 position-absolute"> 
                
                <a id="tooltip-add"  :href="'/company/departments/'+department.uid" class="mt-3 mr-3 btn btn-primary font-size-16 btn-rounded ml-auto float-right"><i class="mdi mdi-eye-outline"></i></a>
              </div>
              </div>
                
             
              </div>
            </div>
          </div>
          <div class="card-body pt-0">
             <div class="row">
              <div class="col-sm-12 text-center">
            <div class="avatar-sm profile-user-wid mx-auto mb-3">
              <span
                class="avatar-title rounded-circle bg-soft-primary text-primary font-size-16"
              >{{(department.name).slice(0,1)}}</span>
            </div>
            <h5 class="font-size-15">
              <a href="javascript: void(0);" class="text-dark">{{department.name}}</a>
            </h5>
            <p class="text-muted">{{department.description}}</p>
            <div v-if="department.manager !== null" class="btn btn-primary rounded-pill">
                        <img class="rounded-circle avatar-xs" v-if="department.manager.user.avatar" :src="department.manager.user.avatar" alt />
                        <span>{{department.manager.user.name}} {{department.manager.user.lastname}} - {{department.manager.user.jobtitle}}</span>
                 </div>    </div>
           </div>
          </div>
          <div class="card-footer bg-transparent border-top">
            <div class="contact-links d-flex font-size-20">
              <div class="flex-fill">
                <a v-b-tooltip.hover title="Edit">
                            <i class="bx bx-edit"></i>
                </a>
              </div>
              <div class="flex-fill">
                  <a v-b-tooltip.hover title="View">
                            <i class="mdi mdi-eye-outline"></i>
                  </a>
              </div>
              <div class="flex-fill">
                 <a v-b-tooltip.hover title="Staff">
                            <i class="bx bx-group"></i>
                  </a>
              </div>
              <div class="flex-fill">
                          <a v-b-tooltip.hover title="Delete">
                            <i class="bx bx-trash-alt"></i>
                          </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
            <div class="row">
              <div class="col-lg-12">
                 <b-pagination
                    class="justify-content-center"
                    pills
                    v-model="currentPage"
                    :total-rows="departments.total"
                    :per-page="departments.per_page"
                    aria-controls="my-table"
                    @input="searchResource"
                  ></b-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <SideSlidePanel :editPanel="addPanel" :panelClass="'edit-sidepanel'" @close-panel="addPanel = false" >
      <b-row>
              <b-col md="12">
                <div class="m-4 px-2">
                <h4>Add New Department</h4>
                 <transition name="slide-error" mode="out-in">
                    <b-alert id="errors" variant="danger" :show="error_message"  dismissible @dismissed="error_message = !error_message">
                      <p class="font-size-14">Oops! Please correct the following errors in your submission</p>
                      <ul>
                        <li v-for="(error, key) in error_reponse">
                          {{error[0]}}
                        </li>
                      </ul>
                    </b-alert>
                    
                  </transition>
             <b-overlay
                :show="loadingForm"
                variant="white"
                        opacity="0.85"
                        blur="1px"
                        rounded="md"
              >
              <b-row>
                <b-col md="12">
                  <b-form-group label="Department Name">
                    <b-form-input type="text" name="name" v-model="newDepartment.name"></b-form-input>
                  </b-form-group>
                  <b-form-group label="Description" :description="newDepartment.description.length+' of 200'">
                     <b-form-textarea name="name" v-model="newDepartment.description" rows="5" placeholder="Enter short company description..."></b-form-textarea>
                  </b-form-group>
                  <b-form-group label="Department Manager">
                    <SearchCompanyUser :api_token="this.$attrs.user.api_token" @user-selected="getManager" @input="getManager" />
                  </b-form-group>
                  <div class="border bg-outline-light p-4 mb-3" style="border-radius: 25px;">
                    <label>Parent Department</label>

                  <DepartmentTreeRadios v-if="'data' in departments && departments.data.length > 0" @category-select="parentSelected" :api_token="$attrs.user.api_token" :selectedid="newDepartment.parent_id" :category_data="JSON.parse(JSON.stringify(departments.data))"></DepartmentTreeRadios>
                  </div>
                  <a href="javascript:;" class="btn btn-success" @click="addNewDepartment">Save</a>
                </b-col>
              </b-row>
            </b-overlay>
                </div>
              </b-col>
      </b-row>            
    </SideSlidePanel>
    <SideSlidePanel :editPanel="updatePanel" :panelClass="'edit-sidepanel'" @close-panel="updatePanel = false">
      <b-row>
              <b-col md="12">
                <div class="m-4 px-2" v-if="updatePanel == true">
                <h4>Edit Department</h4>
                 <transition name="slide-error" mode="out-in">
                    <b-alert id="errors" variant="danger" :show="error_message"  dismissible @dismissed="error_message = !error_message">
                      <p class="font-size-14">Oops! Please correct the following errors in your submission</p>
                      <ul>
                        <li v-for="(error, key) in error_reponse">
                          {{error[0]}}
                        </li>
                      </ul>
                    </b-alert>
                    
                  </transition>
                  <b-overlay
                :show="loadingForm"
                variant="white"
                        opacity="0.85"
                        blur="1px"
                        rounded="md"
              >
               <b-row>
                <b-col md="12">
                  <b-form-group label="Department Name">
                    <b-form-input type="text" name="name" v-model="updateDepartment.name" :state="updateDepartment.name.length > 0"></b-form-input>
                  </b-form-group>
                  <b-form-group label="Description" :description="updateDepartment.description.length+' of 200'">
                     <b-form-textarea name="name" v-model="updateDepartment.description" rows="5" placeholder="Enter short company description..." :state="(updateDepartment.description.length <= 200) && (updateDepartment.description.length > 0)"></b-form-textarea>
                  </b-form-group>
                  <b-form-group label="Department Manager" v-if="updateDepartment.manager !== null">
                    <SearchCompanyUser :api_token="this.$attrs.user.api_token" :id="updateDepartment.manager.user" @user-selected="updateManager" @input="updateManager" />
                  </b-form-group>
                  <b-form-group label="Department Manager" v-else>
                    <SearchCompanyUser :api_token="this.$attrs.user.api_token" @user-selected="updateManager" @input="updateManager" />
                  </b-form-group>
                  <div class="border bg-outline-light p-4 mb-3" style="border-radius: 25px;">
                    <label>Parent Department</label>
                    
                  <DepartmentTreeRadios v-if="'data' in departments && departments.data.length > 0" @category-select="parentSelected" :api_token="$attrs.user.api_token" :selectedid="updateDepartment.parent_id" :currentid="updateDepartment.id" :category_data="JSON.parse(JSON.stringify(departments.data))"></DepartmentTreeRadios>
                  </div>
                  <a href="javascript:;" class="btn btn-success" @click="updateExistingDepartment">Save</a>
              </b-col>
            </b-row>
            </b-overlay>
                </div>
              </b-col>
      </b-row>            
    </SideSlidePanel>




    <SideSlidePanel :editPanel="staffPanel" :panelClass="'edit-sidepanel'" @close-panel="staffPanel = false, updateManagerBtn = false">
      <b-row>
              <b-col md="12">
                <div class="m-4 px-2 row" v-if="staffPanel == true">

                  <b-col md="12">
                <h4>Department Staff</h4>
                </b-col>
                 <b-col md="12">
                  <b-form-group label="Department Manager">
                    <SearchCompanyUser :api_token="this.$attrs.user.api_token" :id="updateDepartment.manager.user" @user-selected="updateManagerBtn = true" @input="getManager" />
                     <a href="javascript:;" v-if="updateManagerBtn == true" class="btn btn-light btn-block my-auto float-right" @click="updateExistingDepartment">
                  <i class="mdi mdi-reload d-block font-size-16"></i>
                Update Manager</a>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <div class="d-flex"><p class="mr-auto my-auto">Department Members</p>
                  <a class="btn btn-primary btn-rounded mb-2 mr-2 text-white ml-auto" @click="userAddToDepartment = !userAddToDepartment" v-b-tooltip.hover title="Add Staff Member">
                    <i class="mdi mdi-plus noti-icon font-size-20"></i>
                  </a>
                  </div>
                  <SearchCompanyUsers v-if="userAddToDepartment == true" :resource="'department'" :id="updateDepartment.id" :api_token="this.$attrs.user.api_token" />
                  <UserList :resource="'department'" :id="updateDepartment.id" :api_token="this.$attrs.user.api_token" />
                </b-col>
                </div>
              </b-col>
      </b-row>            
    </SideSlidePanel>
  </Layout>
  <Layout v-else :nickname="$attrs.nickname" :user="$attrs.user" >
    <PageHeader :title="title" :items="items" />
    <Subscription :company="this.$attrs.company" :user="this.$attrs.user" />
  </Layout>
</template>