<script type="text/javascript">

import Layout from '../../layouts/main'
import PageHeader from '../../components/page-header'
export default {
  components: {Layout, PageHeader},
  props: {
  	data: Array,
  	anchor_index: 1
  },
  data: function(){  
    return{
        selected_index: 1,
        selected_index_records: '',
        isLoading: false,
        searchquery: '',
        searchresults: [],
        isSearchResult: false,
        isLoading: false,
        title: 'Glossary',
      items: [
        {
          text: 'Educate',
          href: '/educate',
        },
        {
          text: 'Tools',
          active: true,
        },
        {
          text: 'Glossary',
          active: true,
        },
      ],
      }
    },
    created(){
      this.createIndex();
      
      
    },
    mounted(){
      this.isLoading = true;
       var url = window.location.href;
      if(url.search("index_id=") > 0){
              var uid = url.substring(url.lastIndexOf("=") + 1);
              //console.log(uid.split('#'));
              var id = uid.split('#');
              this.selected_index = id[0];
              //this.selected_index_records = index;
              this.isLoading = false;
            }
            this.$nextTick(function () {
                  var uid = url.substring(url.lastIndexOf("=") + 1);
              //console.log(uid.split('#'));
                  var id = uid.split('#');
                  console.log(this.$refs);
                  var references = this.$refs;
                  console.log(references['CARAT']);
                  document.getElementById(''+id[1]+'').scrollIntoView();
              })
    },
    methods:{
      createIndex(){
        axios.get('/api/company/library/glossary?api_token='+this.$attrs.user.api_token+'&index_id='+this.selected_index).then(response => {
                            //console.log(response);
                            this.data = response.data;
                            var index = response.data.filter(item => item.id == this.selected_index);
                            //console.log(index);
                            this.selected_index = this.anchor_index;
                            this.selected_index_records = index;
                            this.isLoading = false;
                            //this.isSearchResult = true;
                        });
      },
      copyLink(index_id, item_name){
        var copyText = '://'+this.$attrs.company.nickname+'gemesys.co.uk/educate/glossary?index_id='+index_id+'#'+item_name;
        //var copyText = document.getElementById(""+elementid+"");
        this.copy(copyText);
        /* Select the text field */
        copyText.select();
        copyText.setSelectionRange(0, 99999); /*For mobile devices*/

        /* Copy the text inside the text field */
        document.execCommand("copy");
      },
      async copy(s) {
      await navigator.clipboard.writeText(s);
      alert('Copied!');
      },
    	selectedIndex(index_id){
        this.isLoading = true;
    		var index = this.data.filter(item => item.id === index_id);
    		this.selected_index = index_id;
    		this.selected_index_records = index;
        this.isLoading = false;
        this.isSearchResult = false;
    	},
    	sectionAnchor(index_id, item_name){
    		return '/educate/glossary?index_id='+index_id+'#'+item_name;
    	},
    	sectionID(index_id, item_name){
    		return ''+item_name;
    	},
      searchIndex(searchquery){
        this.isLoading = true;
        this.searchresults = [];
        if(searchquery.length >= 3){
          axios.get('/api/company/library/glossary/search/'+searchquery+'/?api_token='+this.$attrs.user.api_token).then(response => {
                        //console.log(response);
                            this.searchresults = response.data;
                            this.isLoading = false;
                            //this.isSearchResult = true;
                        });

        }
        if(searchquery.length < 3){
          this.searchresults = [];
          this.isSearchResult = false;
          this.isLoading = false;
        }
      },
    },
}

</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div>
      <div class="row">
        <div class="col-md-8 mt-4 mx-auto">
          <!--<div class="input-group">
            <input class="form-control form-control-lg" type="text" v-model="searchquery" @keyup="searchIndex(searchquery)" @keyup.delete="searchIndex(searchquery)" @keydown="searchIndex(searchquery)">
            <div class="input-group-append">
              <span class="input-group-text">
                <i class="fa fa-search"></i>
              </span>

            </div>
          </div>-->
          <div class="input-group">
                                <div class="search-box" style="flex-grow: 3">
                                  <div class="position-relative">
                                    <input class="form-control" type="text" v-model="searchquery" @keyup="searchIndex(searchquery)" @keyup.delete="searchIndex(searchquery)" @keydown="searchIndex(searchquery)" style="border: 1px solid #eff2f7">
                                    <i class="bx bx-search-alt search-icon"></i>
                                    <a v-b-tooltip.hover title="Reset" placement="top" href="javascript:;" @click="searchresults = [], searchquery = ''">
                                      <i class="bx bx-reset search-icon reset"></i>
                                    </a>
                                  </div>
                                </div>
                                <div class="pl-2">
                                    <a href="javascript:;" class="btn btn-rounded btn-primary btn-block" @click="searchIndex(searchquery)">Search</a>
                                </div>
                             </div>
          <ul class="list-group searchbar-searchlist shadow" v-if="searchresults.length > 0">
            <li v-if="searchquery.length >= 3" v-for="result in searchresults" class="list-group-item d-flex">
              <div class="mr-auto ml-2 my-auto flex-grow-3 mr-3 pt-3 w-75">
                  
                    <a class="" :href="sectionAnchor(result.glossary_alpha_id, result.name)" >
                      <strong class="text-uppercase ml-2 my-3">{{result.name}}</strong>
                    </a>
                    <p class="ml-2">{{result.description}}</p>
              </div>
                  <div class="flex-grow-1 my-auto mx-auto text-center">
                    <a class="btn btn-outline-light" :href="sectionAnchor(result.glossary_alpha_id, result.name)">
                      <i class="fa fa-external-link"></i>
                    </a>
                    <a class="btn btn-outline-light" href="javascript:;" @click="copyLink(result.glossary_alpha_id, result.name)">
                      <i class="bx bx-copy"></i>
                    </a>
                  </div>
              </li>
            </ul>
          </div>
          <div class="col-md-12 mt-4 text-center mx-auto">
            <a class="btn btn-outline-light" v-for="index_item in data" @click="selectedIndex(index_item.id)" :class="{'active': index_item.id == selected_index}">{{index_item.letter}}</a>
          </div>
        </div>
        <div class="text-center mx-auto my-4"  v-if="isLoading">
          <b-spinner label="Loading..." :variant="primary"></b-spinner>
          <br><strong>Loading...</strong>
        </div>
        <div class="row px-4 py-4">
          <div class="col-md-12 card" v-for="glossary_section in selected_index_records">
            <h1 class="pt-4 pb-3 px-3" v-if="!isSearchResult">{{glossary_section.letter}}</h1><div class="row">
              <div class="col-md-12" v-for="glossary_item in glossary_section.childs">
                <div class="row  pt-3 pb-2 mx-2 border-bottom"><div class="col-md-11"><h4 class="" :ref="sectionID(glossary_section.id, glossary_item.name)"><a class="" :href="sectionAnchor(glossary_section.id, glossary_item.name)" >{{glossary_item.name}}</a></h4><p>{{glossary_item.description}}</p></div><div class="col-md-1 mx-auto d-flex my-auto"><input class="invisible" style="display: contents;" type="text" :value="sectionAnchor(glossary_section.id, glossary_item.name)" :id="sectionAnchor(glossary_section.id, glossary_item.name)"><a  href="javascript:;" class="float-right btn btn-light btn-quick-menu" @click="copyLink(glossary_section.id, glossary_item.name)" v-b-tooltip="{ trigger: 'hover', title: 'Copy Link', placement: 'top', variant: 'dark' }" tabindex="1"><i class="bx bx-copy"></i></a></div></div></div></div></div></div></div>
  </Layout>
</template>