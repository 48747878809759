<script type="text/javascript">
	import Layout from "../../layouts/main";
	import PageHeader from "../../components/page-header";
	import ReviewCard from "../../components/widgets/forms/ReviewCard";
	export default{
		components:{ Layout, PageHeader, ReviewCard },
		data(){
			return {
				showFilters: false,
				currentPage: 1,
				view: 'grid',
				limit: 25,
				reviews: [],
				title: "Reviews",
			      items: [
			        {
			          text: "Company",
			          href: "/",
			        },
			        {
			          text: "Reviews",
			          active: true,
			        },
			      ],
				limits: [
				        {value: 25, text: "25"},
				        {value: 50, text: "50"},
				        {value: 100, text: "100"},
				      ],
			}
		},
		mounted(){
			this.getReviews();
		},
		methods: {
			getReviews(){
				axios.get('/api/company/reviews', {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
					this.reviews = response.data;
				}).catch(error => {

				});
			}
		}
	}
</script>

<template>
	<Layout :nickname="$attrs.nickname" :user="$attrs.user" :menuItems="$attrs.menuItems" >
			<PageHeader :title="title" :items="items" />
			<div class="row">
			    <div :class="{'col-lg-12': showFilters == true, 'col-lg-12': showFilters == false}">
			       <div class="row mb-3">
			         <div class="col-xl-1 col-sm-6">
			           <div class="mt-2">
			              <b-form-select class="form-control" v-model="limit" :options="limits" @input="productQuery()"></b-form-select>
			            </div>
			          </div>
			         
			          <div class="col-lg-2 col-sm-6 d-flex">
			          <form class="my-auto">
			            <small class="mx-auto my-2 text-center d-block">Results {{reviews.from}} - {{reviews.to}} of {{reviews.total}}</small>
			          </form>
			        </div>
			        <div class="col-lg-5 col-sm-6 d-flex">
			            <div class="mt-sm-0 form-inline w-100" >
			              <div class="search-box mr-2 ml-auto">
			                <div class="position-relative">
			                  <input
			                    type="text"
			                    class="form-control w-100 border-0"
			                    placeholder="Search name, reference or description..."
			                    v-model="search"
			                    @keyup.enter="searchFilter($event)"
			                  />
			                  <i class="bx bx-search-alt search-icon"></i>
			                </div>
			              </div>
			            </div>
			          </div>
			          <div class="col-lg-4 col-sm-6 d-flex">
			            <div class="d-flex ml-auto">
			              <ul class="nav nav-pills product-view-nav">
			                <li class="nav-item">
			                  <a class="nav-link" :class="{'active': showFilters == true }" href="javascript: void(0);" @click="showFilters = !showFilters">
			                    <i class="bx bx-filter-alt"></i>
			                  </a>
			                </li>
			                <li class="nav-item">
			                  <a class="nav-link" :class="{'active': view == 'grid' }" href="javascript: void(0);" @click="view = 'grid'">
			                    <i class="bx bx-grid-alt"></i>
			                  </a>
			                </li>
			                <li class="nav-item">
			                  <a class="nav-link" :class="{'active': view == 'list' }" href="javascript: void(0);" @click="view = 'list'">
			                    <i class="bx bx-list-ul"></i>
			                  </a>
			                </li>
			                <!--<li class="nav-item">
			                 <b-dropdown text="Bulk Actions" variant="primary" class="rounded">
			                  <b-dropdown-item href="javascript: void(0);">Action</b-dropdown-item>
			                  <b-dropdown-item href="javascript: void(0);">Another action</b-dropdown-item>
			                  <b-dropdown-item href="javascript: void(0);">Something else here</b-dropdown-item>
			                  <b-dropdown-divider></b-dropdown-divider>
			                  <b-dropdown-item href="javascript: void(0);">Separated link</b-dropdown-item>
			                </b-dropdown>
			                </li>-->
			                
			              </ul>
			              <div class="text-sm-right">
			                  
			                </div>
			          </div>
			        </div>
			      </div>
			  </div>
			</div>

			<div class="row" v-if="reviews.data && reviews.data.length > 0">
				<b-col md="4" v-for="item in reviews.data">
					<ReviewCard :view="'admin'" :review="item"></ReviewCard>
				</b-col>
			</div>
			<div class="row">
              <div class="col-lg-12" v-if="reviews.data && reviews.data.length > 0">
                <b-pagination
              class="justify-content-center"
              pills
              v-model="currentPage"
              :total-rows="reviews.total"
              :per-page="reviews.per_page"
              aria-controls="my-table"
              @input="getReviews"
            ></b-pagination>
             <small class="mx-auto my-2 text-center d-block">Records {{reviews.from}} - {{reviews.to}} of {{reviews.total}} // Page {{reviews.current_page}} of {{reviews.last_page}}</small>
              </div>
            </div>
	</Layout>
</template>