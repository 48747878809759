<script>
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
  components: { ckeditor: CKEditor.component },
  data() {
    return {
      showModal: false,
      editor: ClassicEditor,
      editorData: "<p>Content of the editor.</p>",
      labels: [{ icon: 'mdi mdi-desktop-classic', source: 'website', label: "Website"} , { icon: 'bx bx-mail-send', source: 'public', label: "Public Market"}, { icon: 'bx bx-transfer', source: 'trade', label:"Trade Market"}],
    };
  },
  methods: {
    filterNotifications(source){
      var data = '{uid : []}';
      history.pushState(data, '', "/communicate/customer-service/");

      this.$emit('filter', source);
    },
    sendEmail(email){
      this.$emit('send-email');
    },
    startThread(type){
      
    }
  }
};
</script>

<template>
  <div class="p-3 card">
    <b-dropdown variant="primary" class="btn-rounded">
      <template class="btn-rounded" #button-content>
      Start New Conversation <i class="bx bx-pencil"></i>
      </template>
      <b-dropdown-item @click="startThread('support')">Support Message</b-dropdown-item>
      <b-dropdown-item disabled @click="startThread('chat')">Web Chat</b-dropdown-item>
      <b-dropdown-item disabled @click="startThread('sms')">SMS</b-dropdown-item>
      <b-dropdown-item disabled @click="startThread('whatsapp')">WhatsApp</b-dropdown-item>
    </b-dropdown>
    <div class="mail-list mt-4">
      <a @click="filterNotifications('')" class="active">
        <i class="mdi mdi-email-outline me-2"></i> All Requests
        <span class="ms-1 float-end" v-if="$attrs.unread">({{$attrs.unread}})</span>
        <span class="ms-1 float-end" v-else>(0)</span>
      </a>

      <a  v-for="label in labels" href="javascript:;" @click="filterNotifications(label.source)">
        <i class="me-2" :class="label.icon"></i>{{label.label}}
      </a>
      <!--<a href="/email/inbox">
        <i class="mdi mdi-diamond-stone me-2"></i>Important
      </a>
      <a href="/email/inbox">
        <i class="mdi mdi-file-outline me-2"></i>Draft
      </a>
      <a href="javascript:;">
        <i class="mdi mdi-email-check-outline me-2"></i>Sent Mail
      </a>
      <a href="javascript:;">
        <i class="mdi mdi-trash-can-outline me-2"></i>Trash
      </a>-->
    </div>

    <!--<h6 class="mt-4">Labels</h6>

    <div class="mail-list mt-1">
      <a href="javascript: void(0);">
        <span class="mdi mdi-arrow-right-drop-circle text-info float-end"></span>Tags
      </a>
    </div>-->

    <h6 class="mt-4">Customer Support</h6>
    <div class="d-flex flex-column justify-content-center align-items-center bg-light py-4 px-3 text-center">
      <h5 class="pt-4">Set-up Customer Support</h5>
      <p>
        Start supporting your customers through SMS, WhatsApp and Online Chat.
      </p>
      <a class="btn btn-primary btn-rounded mb-4">
        Get Started
      </a>
    </div>
    <!--<div class="mt-2">
      <a href="javascript: void(0);" class="media">
        <img
          class="d-flex me-3 rounded-circle"
          src="/images/users/avatar-2.jpg"
          alt="Generic placeholder image"
          height="36"
        />
        <div class="media-body chat-user-box">
          <p class="user-title m-0">Scott Median</p>
          <p class="text-muted">Hello</p>
        </div>
      </a>

      <a href="javascript: void(0);" class="media">
        <img
          class="d-flex me-3 rounded-circle"
          src="/images/users/avatar-3.jpg"
          alt="Generic placeholder image"
          height="36"
        />
        <div class="media-body chat-user-box">
          <p class="user-title m-0">Julian Rosa</p>
          <p class="text-muted">What about our next..</p>
        </div>
      </a>

      <a href="javascript: void(0);" class="media">
        <img
          class="d-flex me-3 rounded-circle"
          src="/images/users/avatar-4.jpg"
          alt="Generic placeholder image"
          height="36"
        />
        <div class="media-body chat-user-box">
          <p class="user-title m-0">David Medina</p>
          <p class="text-muted">Yeah everything is fine</p>
        </div>
      </a>

      <a href="javascript: void(0);" class="media">
        <img
          class="d-flex me-3 rounded-circle"
          src="/images/users/avatar-6.jpg"
          alt="Generic placeholder image"
          height="36"
        />
        <div class="media-body chat-user-box">
          <p class="user-title m-0">Jay Baker</p>
          <p class="text-muted">Wow that's great</p>
        </div>
      </a>
    </div>-->

    <b-modal v-model="showModal" title="New Message" centered>
      <form>
        <div class="mb-3">
          <input type="email" class="form-control" placeholder="To" />
        </div>

        <div class="mb-3">
          <input type="text" class="form-control" placeholder="Subject" />
        </div>
        <div class="mb-3">
          <ckeditor v-model="editorData" :editor="editor"></ckeditor>
        </div>
      </form>
      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="showModal = false">Close</b-button>
        <b-button variant="primary">
          Send
          <i class="fab fa-telegram-plane ms-1"></i>
        </b-button>
      </template>
    </b-modal>
  </div>
</template>
