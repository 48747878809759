<script>
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import vue2Dropzone from "vue2-dropzone";
import DatePicker from "vue2-datepicker";
import Layout from "../../layouts/main";
import Swal from "sweetalert2";
import PageHeader from "../../components/page-header";
import InvoicesList from "../../components/widgets/InvoicesList";
import SideSlidePanel from "../../components/widgets/SideSlidePanel";
import UserList from "../../components/widgets/UserList";
import ActionsList from "../../components/widgets/ActionsList";
import Subscription from '../../components/widgets/subscription';
import DateTime from 'luxon/src/datetime.js';
import AccessRestricted from "../../views/utility/access-restricted";

//import { projectData } from "./data-invoices";

/**
 * invoices-list component
 */
export default {
  components: { Layout, PageHeader, DatePicker, InvoicesList, SideSlidePanel, Subscription, vueDropzone: vue2Dropzone, ckeditor: CKEditor.component, DateTime, AccessRestricted  },
  data() {
    return {
      userListLoaded: false,
      date: '',
      upload_progress: 0,
      report_advanced_filter: false,
      report_error: false,
      reportGenerating: false,
      report_success_message: false,
      report: {
          actions: false,
          actions_complete: false,
          actions_incomplete: true,
          actions_require_review: true,
          reviewed_incomplete_actions: true,
          title: '',
          desc: '',
          filename: '',
          plogo: '',
          clogo: '',
          type: '',
          info: false,
          created_by: '',
          created_at: '',
          contents_page: true,
          cover_page: false,
          cover_color: '',
          report_data: '',
          report_invoices: [],
          status_history: false,
          teams: [],
          departments: [],
          assignedto: [],
          statuses: [],
          orderby: '',
          orientation: 'portrait'
        },
      report_options: false,
      showFilters: false,
      loadingForm: false,
      deleted_message: false,
      delete_confirm: false,
      delete_project: [],
      duedaterange: '',
      csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
      title: "Project Reports",
      view: 'list',
      listview: 'list',
      thisPage: 1,
      limit: 25,
      selectedids: [],
      orderDesc: true,
      order: 'desc',
      sortby: 'id',
      search: '',
      departments: [],
      teams: [],
      invoices: [],
      assignedto: '',
      error_reponse: [],
      collaborators: [],
      completed: false,
      archived: false,
      status_id: '',
      delete_form_load: false,
      load_error: [],
      addPanel: false,
      editPanel: false,
      collaboratorsPanel: false,
      addCollaboratorView: false,
      invoice_settings: '',
      invoice_no: 0,
      actionsPanel: false,
      newProject: {
        name: '',
        image: '',
        description: '',
        company_id: 0,
        parent_id: 0,
        created_by_id: 0,
        assigned_by_id: 0,
        assigned_to_id: 0,
        company_department_id: 0,
        company_team_id: 0,
        project_status_id: 0,
        reference: '',
        issue: '',
        action: '',
        due_date: 0,
        more_time_request: null, 
        more_time_approved: 0,
        more_time_approved_id: 0, 
        completed_date: 0,
        completed:0,
        archive: 0,
        reviewed: 0,
        collaborators: [],
        attachments: [],
        settings: {
          creation:{
            email_creator: true,
            email_assigned_to: true,
            email_collaborators: true,
            email_additional_emails: 0,
            additional_emails: []
          },
          update: {
            email_creator: true,
            email_assigned_to: true,
            email_collaborators: true,
            email_additional_emails: 0,
            additional_emails: []
          },
          delete: {
            email_creator: true,
            email_assigned_to: true,
            email_collaborators: true,
            email_additional_emails: 0,
            additional_emails: []
          },
          status: {
            email_creator: true,
            email_assigned_to: true,
            email_collaborators: true,
            email_additional_emails: 0,
            additional_emails: []
          },
          complete: {
            email_creator: true,
            email_assigned_to: true,
            email_collaborators: true,
            email_additional_emails: 0,
            additional_emails: []
          },
          due: {
            email_creator: 0,
            email_assigned_to: 0,
            email_collaborators: true,
            days_before: 0,
            email_additional_emails: 0,
            additional_emails: []
          },
          addcollaborator:{
            email_creator: 0,
            email_collaborator: 0,
          },
          removecollaborator:{
            email_creator: 0,
            email_collaborator: 0,
          },
          report:{
            email_creator: true,
            email_assigned_to: true,
            email_additional_emails: 0,
            additional_emails: [],
          },
        },
      },
      updateProject:{},
      limits: [
        {value: 25, text: "25"},
        {value: 50, text: "50"},
        {value: 100, text: "100"},
      ],
      statuses: [],
      loadingDetails: 1,
      items: [
        {
          text: "invoices",
          href: "/"
        },
        {
          text: "Reports",
          active: true
        }
      ],
      dropzoneOptions: {
                url: "https://httpbin.org/post",
                thumbnailWidth: 150,
                maxFilesize: 0.5,
                headers: {
                    "My-Awesome-Header": "header value"
                }
      },
      editor: ClassicEditor,
      editorData:
        "<h3>Hello World!</h3><h5><b>This is an simple editable area.</b></h5>",

    };
  },
  created(){
    this.date = DateTime.local().toSQL();
    if(this.$attrs.validsubscription == 0){
      this.title = 'Subscription';
      this.items[0].text = 'Company';
      this.items[1].text = 'Subscription';
    }
    if(this.invoices !== []){
    this.searchResource();
    }
    //this.getStatuses();
    let self = this;
          setTimeout(function(){
            self.loadingDetails = 0;
          }, 500);
  },
  mounted(){
    var url = window.location.href;
    if(url.search("#add-new") > 0){
      this.addPanel = true;
    }
    
  },
  methods:{
    searchResource(){
      this.loadingDetails = 1;
      //this.invoices = [];
      if(this.completed == false){
        this.completed = 0;
      }else{
        this.completed = 1;
      }
      if(this.archived == false){
        this.archived = 0;
      }else{
        this.archived = 1;
      }
      axios.get('/api/company/order/invoices?page='+this.thisPage+'&limit='+this.limit+'&sortby='+this.sortby+'&order='+this.order+'&search='+this.search+'&departments='+this.departments+'&teams='+this.teams+'&assingedto='+this.assignedto+'&collaborators='+this.collaborators+'&view='+this.view+'&completed='+this.completed+'&archived='+this.archived+'&status='+this.status_id+'', {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
        this.invoices = response.data;
        this.loadingDetails = 0;
      }).catch(error =>{
        this.load_error = error.response;
      })
    },
    
    createNewProject(){
      this.loadingForm = true;
      if(this.newProject.more_time_request == 0){
        this.newProject.more_time_request = null;
      }
      var postForm = new FormData(document.getElementById("addproject"));
      axios.post('/api/company/project/store', postForm, {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
              }}).then(response => {
        console.log(response);
        this.success_message = true;
        this.resetProjectData();
        this.addPanel = false;
        this.newProject.reference = 'PRO' + Math.floor((Math.random() * 1000000000) + 1) + '';
        let self = this;
          setTimeout(function(){
            self.loadingForm = false;
          }, 1500);
      }).catch( error => {
        //console.log(error);
        this.error_reponse = error.response.data.errors;
        this.error_message = true;
        //this.resetProjectData();
        let self = this;
          setTimeout(function(){
            self.loadingForm = false;
          }, 1500);
      });
    }, 
    editProject(){
      this.loadingForm = true;
       var postForm = new FormData(document.getElementById("updateproject"));
      axios.post('/api/company/project/'+this.updateProject.id+'/update', postForm, {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
              }}).then(response => {
        console.log(response);
        this.success_message = true;
        var self = this;
                  setTimeout(function(){
                   self.loadingForm = false;
                   self.searchResource();
                  }, 1500);
      }).catch( error => {
        this.error_reponse = error.response.data.errors;
        this.error_message = true;
       // this.resetProjectData();
       let self = this;
          setTimeout(function(){
            self.loadingForm = false;
          }, 1500);
      });
    },
    deleteProjectEvent(project){
      this.delete_project = project;
      this.delete_confirm = true;
    },
    deleteProject(project){
      this.delete_form_load = true;
       axios.post('/api/company/project/'+this.delete_project.id+'/report/delete', {url: this.delete_project.url, resourcetype: 'project_report', resource: this.delete_project}, {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
              }}).then(response => {
            if(response.data == true){
                var self = this;
                setTimeout(function(){
                 //Vue.swal("Avatar Updated");
                 self.delete_confirm = false;
                 self.deleted_message = true;
                 self.delete_form_load =false;
                 self.searchResource();
                }, 1500);
            }
          }).catch(error => {
            console.log(error);
          });
    },
    generateReportSelection(type){
      console.log(type);
      this.report.type = type; 
      this.report_options = true;

    },
    generateReport(type){
      this.report_error = false;
      this.reportGenerating = true;
      this.report_success_message = false;
      this.error_reponse = [];
      this.error_message = false;
      if(this.report.orderby == "current"){
        this.report.report_invoices = this.invoices.data; 
      }
        //his.report.report_data = this.userData;
        axios({url: '/api/company/project/report/generate/'+this.report.type+'',headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
              }, method: 'POST', responseType: 'json', data: this.report, 
        onUploadProgress: function( progressEvent ) {
          console.log(parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) )));
            this.upload_progress = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 50 ));
        }.bind(this)}).then(response => {
          //Vue.swal("Report Generated. Downloading!");
          this.report_success_message = true;
          this.upload_progress = 100;      
                setTimeout(function(){
                 //Vue.swal("Avatar Updated");
                 var fileURL = window.open(response.data);
                 fileLink.click();
                
                }, 1500);
                var self = this;
                setTimeout(function(){
                 //Vue.swal("Avatar Updated");
                 self.upload_progress = 0;
                 self.reportGenerating = false;
                }, 1500);
                 
        }).catch(error => {
          //Vue.swal("Oops, that didn't work. Try again.");
          this.report_error = true;
          this.upload_progress = 99;
          this.error_reponse = error.response.data.errors;
          this.error_message = true;
            var self = this;
                setTimeout(function(){
                 //Vue.swal("Avatar Updated");
                 self.reportGenerating = false;
                }, 1500);
        })
      }
  }
};
</script>

<template>
  <Layout v-if="$attrs.validsubscription == 0" :nickname="$attrs.nickname" :user="$attrs.user" >
    <PageHeader :title="title" :items="items" />
    <AccessRestricted v-if="($attrs.user.companies[0].userrole.name !== ('Owner' || 'Manager' ))" />
    <div v-else>
       <div class="row mb-3">
          <div class="col-xl-1 col-sm-6">
            <div class="mt-2">
              <b-form-select class="form-control" v-model="limit" :options="limits" @input="productQuery()"></b-form-select>
            </div>
          </div>
         
          <div class="col-lg-2 col-sm-6 d-flex">
          <form class="my-auto">
            <small class="mx-auto my-2 text-center d-block">Results {{invoices.from}} - {{invoices.to}} of {{invoices.total}}</small>
          </form>
        </div>
        <div class="col-lg-5 col-sm-6 d-flex">
            <div class="mt-sm-0 form-inline w-100" >
              <div class="search-box mr-2 ml-auto">
                <div class="position-relative">
                  <input
                    type="text"
                    class="form-control w-100 border-0"
                    placeholder="Search name, reference or description..."
                    v-model="search"
                    @keyup.enter="searchFilter($event)"
                  />
                  <i class="bx bx-search-alt search-icon"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-sm-6 d-flex">
            <div class="d-flex ml-auto">
              <ul class="nav nav-pills product-view-nav">
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': showFilters == true }" href="javascript: void(0);" @click="showFilters = !showFilters">
                    <i class="bx bx-filter-alt"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': view == 'grid' }" href="javascript: void(0);" @click="view = 'grid'">
                    <i class="bx bx-grid-alt"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': view == 'list' }" href="javascript: void(0);" @click="view = 'list'">
                    <i class="bx bx-list-ul"></i>
                  </a>
                </li>
                <li class="nav-item">
                 <b-dropdown text="Bulk Actions" variant="primary" class="rounded">
                  <b-dropdown-item href="javascript: void(0);">Action</b-dropdown-item>
                  <b-dropdown-item href="javascript: void(0);">Another action</b-dropdown-item>
                  <b-dropdown-item href="javascript: void(0);">Something else here</b-dropdown-item>
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-item href="javascript: void(0);">Separated link</b-dropdown-item>
                </b-dropdown>
                </li>
                <li class="nav-item">
                 <a
                    class="btn btn-success btn-rounded mb-2 mr-2 text-white"
                    href="product/add"
                  >
                    <i class="mdi mdi-plus mr-1"></i> New
                  </a>
                </li>
              </ul>
              <div class="text-sm-right">
                  
                </div>
          </div>
        </div>
      </div>
    <SideSlidePanel id="filter-project-panel" :editPanel="showFilters" :panelClass="'search-sidepanel mb-4'" @close-panel="showFilters = false">
      <b-row>
              <!--<b-col md="3" class="mb-0">
                
                  <b-form-group class="mb-0" label="Search">
                    <b-form-input type="text" placeholder="Search" /> 
                  </b-form-group>
                
              </b-col>-->
              <b-col md="3" class="mb-0">
                
                  <b-form-group class="mb-0" label="Department">
                    <SearchCompanyDepartments :api_token="this.$attrs.user.api_token" @department-selected="filterDepartment"  />
                  </b-form-group>
                
              </b-col>
              <b-col md="3" class="mb-0">
                
                  <b-form-group class="mb-0" label="Team">
                    <SearchCompanyTeams  :api_token="this.$attrs.user.api_token" @department-selected="filterTeam" />
                  </b-form-group>
                
              </b-col>
              <b-col md="3" class="mb-0">
                
                  <b-form-group class="mb-0" label="Due Date">
                     <date-picker v-model="duedaterange" range append-to-body lang="en" confirm></date-picker>
                  </b-form-group>
                
              </b-col>
              <b-col md="3" class="mb-1">
                
                  <b-form-group label="Assigned To">
                    <SearchCompanyUser  :api_token="this.$attrs.user.api_token" @user-selected="filterAssignedTo" />
                  </b-form-group>
                
              </b-col>
              <!--<b-col md="3" class="mb-1">
                
                  <b-form-group label="User">
                    <FilterCompanyUsers  :api_token="this.$attrs.user.api_token" @user-selected="filterCollaborators" />
                  </b-form-group>
                
              </b-col>
              <b-col md="3" class="mb-1">
                
                  <b-form-group label="User">
                    <FilterCompanyUsers  :api_token="this.$attrs.user.api_token" @user-selected="filterCollaborators" />
                  </b-form-group>
                
              </b-col>
              <b-col md="3" class="mb-1">
                
                  <b-form-group label="User">
                    <FilterCompanyUsers  :api_token="this.$attrs.user.api_token" @user-selected="filterCollaborators" />
                  </b-form-group>
                
              </b-col>-->
      </b-row>
    </SideSlidePanel>
    <SideSlidePanel id="filter-project-panel" :editPanel="delete_confirm || deleted_message" :panelClass="'search-sidepanel mb-4'" @close-panel="delete_confirm = false, deleted_message = false">
      <b-overlay
                        id="report-generating-overlay"
                        :show="delete_form_load"
                        variant="white"
                        opacity="0.85"
                        blur="1px"
                        rounded="md"
                        class="w-100"
                      >
          <div class="col-md-12 confirm-delete d-flex h-100" v-if="delete_confirm == true">
            <div class="card my-auto mx-auto pb-3 text-center">
              <div class="card-body">
              <h4>Are You Sure?</h4>
              <p>You Are About to Delete Report #{{delete_project.reference}}</p>
              <a class="btn btn-success float-left" @click="deleteProject(delete_project)">Confirm</a>
              <a class="btn btn-danger float-right" @click="delete_confirm = false, delete_project = []">Cancel!</a>
            </div>
            </div>
          </div>
          <div class="col-md-12 confirm-delete d-flex h-100" v-if="deleted_message == true">
            <div class="card my-auto mx-auto pb-3 text-center">
              <div class="card-body">
              <h4>Report Deleted</h4>
              <p>Your report has been deleted</p>
              <a class="btn btn-success mx-auto text-center" @click="delete_confirm = false, deleted_message = false">Close</a>
            </div>
            </div>
          </div>
        </b-overlay>
        </SideSlidePanel>
      <SideSlidePanel :editPanel="report_options" :panelClass="'edit-sidepanel'" @close-panel="report_options = false, limit = 25">
      <b-row class="align-items-center" v-if="report_options == true">
              <!--<b-col md="3" class="mb-0">
                
                  <b-form-group class="mb-0" label="Search">
                    <b-form-input type="text" placeholder="Search" /> 
                  </b-form-group>
                
              </b-col>-->

              <b-col md="12">
              <b-row class="p-4 mt-3" v-if="(report.type == 'pdf')">
               
              <b-col md="12">
                 <h4 class="float-left mt-2">PDF Report Options</h4>
                 
              </b-col>
              <b-overlay
                        id="report-generating-overlay"
                        :show="reportGenerating"
                        variant="white"
                        opacity="0.85"
                        blur="1px"
                        rounded="md"
                        class="w-100"
                      >
                    <b-row>
              <b-col md="12" class="mb-0 p-4 border-right">
                  <h5>Elements</h5>
                  <b-form-group label="Report Cover Page">
                      <b-form-checkbox switch v-model="report.cover_page" ></b-form-checkbox>
                    </b-form-group>
                  <div v-if="report.cover_page == true">
                    <b-form-group label="Report Title">
                      <b-form-input v-model="report.title" :disabled="report.cover_page !== true" :class="{'bg-light' : report.cover_page !== true}" ></b-form-input>
                    </b-form-group>
                    <b-form-group label="Report Description">
                      <b-form-textarea v-model="report.desc" :disabled="report.cover_page !== true" :class="{'bg-light' : report.cover_page !== true}"  ></b-form-textarea>
                    </b-form-group>
                  </div>
                  <b-form-group label="Report Contents">
                      <b-form-checkbox switch v-model="report.contents_page" ></b-form-checkbox>
                    </b-form-group>

                    <b-form-group label="Report Filename">
                      <b-form-input v-model="report.filename" ></b-form-input>
                    </b-form-group>
                    <b-form-group label="Include Company Logo">
                      <b-form-checkbox switch v-model="report.clogo" ></b-form-checkbox>
                    </b-form-group>
                    <h5>Format</h5>
                    <b-form-group label="">
                      <b-form-radio-group buttons button-variant="outline-primary">
                       <b-form-radio v-model="report.orientation" value="portrait">Portrait</b-form-radio>
                       <b-form-radio v-model="report.orientation" value="landscape">Landscape</b-form-radio>
                      </b-form-radio-group>
                    </b-form-group>
                    <b-row>
                    <b-col md="6">
                     <h5>Order By</h5>
                    <b-form-group label="">
                      <b-form-radio-group buttons button-variant="outline-primary">
                       <b-form-radio v-model="report.orderby" value="status">Status</b-form-radio>
                       <b-form-radio v-model="report.orderby" value="date">Date</b-form-radio>
                        <b-form-radio v-model="report.orderby" value="current">Current Selection</b-form-radio>
                      </b-form-radio-group>
                    </b-form-group>
                    <b-form-group v-if="report.orderby == 'current'" label="Results Limit">
                        <b-form-input type="number" v-model="limit" @input="searchResource()" ></b-form-input>
                      </b-form-group>
                   </b-col>
                  <b-col md="6"  v-if="(report.orderby !== 'current')">
                    <h5>Filter By</h5>
                      <b-form-group label="">
                        <b-form-radio-group buttons button-variant="outline-primary">
                         <b-form-radio v-model="report.complete" value="0">Incomplete</b-form-radio>
                         <b-form-radio v-model="report.complete" value="1">Completed</b-form-radio>
                         <b-form-radio v-model="report.complete" value="''">All</b-form-radio>
                        </b-form-radio-group>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-col md="12" class="d-flex flex-row px-0" v-if="(report.orderby == 'current')">
                  <h5 class="my-auto">Advanced Filters</h5><a class="btn btn-outline-light btn-rounded ml-auto" href="javascript:;" @click="report_advanced_filter = !report_advanced_filter"><i class="mdi mdi-plus text-dark font-size-20"></i></a>
                  </b-col>
                  <transition name="slide-panel" mode="out-in">
                  <b-row v-if="(report_advanced_filter == true) && (report.orderby == 'current')">
                    <b-col md="12">
                    <b-form-group class="mb-0" label="Department">
                    <SearchCompanyDepartments :api_token="this.$attrs.user.api_token" @department-selected="filterDepartment"  />
                      </b-form-group>
                        <b-form-group class="mb-0" label="Team">
                          <SearchCompanyTeams  :api_token="this.$attrs.user.api_token" @department-selected="filterTeam" />
                        </b-form-group>
                        <b-form-group class="mb-3" label="Due Date">
                           <date-picker v-model="duedaterange" range append-to-body lang="en" confirm></date-picker>
                        </b-form-group>
                        <b-form-group label="Assigned To">
                        <SearchCompanyUser  :api_token="this.$attrs.user.api_token" @user-selected="filterAssignedTo" />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </transition>
                <a class="btn btn-block btn-success mt-3" href="javascript:;" @click="generateReport()">Generate Report</a>
                  </b-col>
                </b-row>
                   <template #overlay>
                    <b-col md="12" v-if="reportGenerating == true">
                 <b-card>
                  <div  v-if="report_error == false" ><b-progress v-model="upload_progress" :max="100" animated show-value height="20px"  ></b-progress>
                    <h5  class="mt-2" v-if="(report_success_message == false) && (upload_progress < 100)"><i class="mdi mdi-upload"></i> We're Generating Your Report - Please Wait!</h5>
                    <h5  class="mt-2" v-else><i class="bx bx-check-circle text-success"></i> Report Generated Successfully. Opening Report...</h5>
                  </div>
                  <div v-else>
                    <b-progress v-model="upload_progress" :max="100" variant="danger"  animated show-value height="20px"  ></b-progress>
                    <h5 class="mt-2"><i class="bx bx-error-circle text-danger"></i> Error Generating Report File</h5>
                    <ul>
                      <li v-for="error in error_reponse">{{error[0]}}</li>
                    </ul>
                  </div>
                   </b-card>
                </b-col>
                   </template>
                </b-overlay>
                </b-row>
                <b-row  class="p-4" v-if="report.type == 'csv'">
                   <b-col md="12">
                  <h4>CSV Report Options</h4>
                 </b-col>
                 <b-overlay
                        id="report-generating-overlay"
                        :show="reportGenerating"
                        variant="white"
                        opacity="0.85"
                        blur="1px"
                        rounded="md"
                        class="w-100"
                      >
                   <b-col md="12">
                    <b-form-group label="Report Title">
                      <b-form-input v-model="report.title" ></b-form-input>
                    </b-form-group>
                    <b-form-group label="Report Description">
                      <b-form-textarea v-model="report.desc" ></b-form-textarea>
                    </b-form-group>
                    <b-form-group label="Report Filename">
                      <b-form-input v-model="report.filename" ></b-form-input>
                    </b-form-group>
                    <h5>Order By</h5>
                    <b-form-group label="">
                      <b-form-radio-group buttons button-variant="outline-primary">
                       <b-form-radio v-model="report.orderby" value="status">Status</b-form-radio>
                       <b-form-radio v-model="report.orderby" value="date">Date</b-form-radio>
                        <b-form-radio v-model="report.orderby" value="current">Current Selection</b-form-radio>
                      </b-form-radio-group>
                    </b-form-group>
                    <b-form-group v-if="report.orderby == 'current'" label="Results Limit">
                        <b-form-input type="number" v-model="limit" @input="searchResource()" ></b-form-input>
                      </b-form-group>
                    <a class="btn btn-block btn-success" href="javascript:;" @click="generateReport()">Generate Report</a>
                  </b-col>
                   <template #overlay>
                    <b-col md="12" v-if="reportGenerating == true">
                 <b-card>
                  <div  v-if="report_error == false" ><b-progress v-model="upload_progress" :max="100" animated show-value height="20px"  ></b-progress>
                    <h5  class="mt-2" v-if="(report_success_message == false) && (upload_progress < 100)"><i class="mdi mdi-upload"></i> We're Generating Your Report - Please Wait!</h5>
                    <h5  class="mt-2" v-else><i class="bx bx-check-circle text-success"></i> Report Generated Successfully. Opening Report...</h5>
                  </div>
                  <div v-else>
                    <b-progress v-model="upload_progress" :max="100" variant="danger"  animated show-value height="20px"  ></b-progress>
                    <h5 class="mt-2"><i class="bx bx-error-circle text-danger"></i> Error Generating Report File</h5>
                    <ul>
                      <li v-for="error in error_reponse">{{error[0]}}</li>
                    </ul>
                  </div>
                   </b-card>
                </b-col>
                   </template>
                </b-overlay>
                </b-row>
                <b-row  class="p-4" v-if="report.type == 'xls'">
                  <b-col md="12">
                  <h4>CSV Report Options</h4>
                 </b-col>
                 <b-overlay
                        id="report-generating-overlay"
                        :show="reportGenerating"
                        variant="white"
                        opacity="0.85"
                        blur="1px"
                        rounded="md"
                        class="w-100"
                      >
                   <b-col md="12">
                    <b-form-group label="Report Title">
                      <b-form-input v-model="report.title" ></b-form-input>
                    </b-form-group>
                    <b-form-group label="Report Description">
                      <b-form-textarea v-model="report.desc" ></b-form-textarea>
                    </b-form-group>
                    <b-form-group label="Report Filename">
                      <b-form-input v-model="report.filename" ></b-form-input>
                    </b-form-group>
                    <h5>Order By</h5>
                    <b-form-group label="">
                      <b-form-radio-group buttons button-variant="outline-primary">
                       <b-form-radio v-model="report.orderby" value="status">Status</b-form-radio>
                       <b-form-radio v-model="report.orderby" value="date">Date</b-form-radio>
                        <b-form-radio v-model="report.orderby" value="current">Current Selection</b-form-radio>
                      </b-form-radio-group>
                    </b-form-group>
                    <b-form-group v-if="report.orderby == 'current'" label="Results Limit">
                        <b-form-input type="number" v-model="limit" @input="searchResource()" ></b-form-input>
                      </b-form-group>
                    <a class="btn btn-block btn-success" href="javascript:;" @click="generateReport()">Generate Report</a>
                  </b-col>
                   <template #overlay>
                    <b-col md="12" v-if="reportGenerating == true">
                 <b-card>
                  <div  v-if="report_error == false" ><b-progress v-model="upload_progress" :max="100" animated show-value height="20px"  ></b-progress>
                    <h5  class="mt-2" v-if="(report_success_message == false) && (upload_progress < 100)"><i class="mdi mdi-upload"></i> We're Generating Your Report - Please Wait!</h5>
                    <h5  class="mt-2" v-else><i class="bx bx-check-circle text-success"></i> Report Generated Successfully. Opening Report...</h5>
                  </div>
                  <div v-else>
                    <b-progress v-model="upload_progress" :max="100" variant="danger"  animated show-value height="20px"  ></b-progress>
                    <h5 class="mt-2"><i class="bx bx-error-circle text-danger"></i> Error Generating Report File</h5>
                    <ul>
                      <li v-for="error in error_reponse">{{error[0]}}</li>
                    </ul>
                  </div>
                   </b-card>
                </b-col>
                   </template>
                </b-overlay>
                </b-row>
              </b-col>
          </b-row>
          </SideSlidePanel>
    <div class="row" v-if="view == 'list'">
      <div class="col-lg-12">
        <div>
          <b-overlay
                        id="overlay-background"
                        :show="loadingDetails == 1"
                        variant="white"
                        class="bg-white"
                        opacity="0.5"
                        blur="1px"
                        rounded="md"
                        
                      >
                          
          <InvoicesList :projectData="invoices.data" @edit-project="updateProjectEvent"  @show-collaborators="showCollaboratorsEvent" @show-actions="showProjectActionsEvent" @delete-project="deleteProjectEvent" :company="$attrs.company" :api_token="this.$attrs.user.api_token" />
          </b-overlay>
        </div>
      </div>
    </div>
    <div class="row" v-if="(listview == 'board') && (invoices !== [])">
      <div class="col-lg-12">
        <div>
          <BoardList :projectData="invoices.data" />
        </div>
      </div>
    </div>
     <div v-if="view == 'grid'">
         <ProjectGrid :projectData="invoices.data" />
    </div>
    <!-- end row -->
    <!-- start pagination row -->
    <div class="row">
              <div class="col-lg-12">
                 <b-pagination
                    class="justify-content-center"
                    pills
                    v-model="thisPage"
                    :total-rows="invoices.total"
                    :per-page="invoices.per_page"
                    aria-controls="my-table"
                    @input="searchResource"
                  ></b-pagination>
              </div>
            </div>
    <!-- end row -->
    <SideSlidePanel id="add-new-project-panel" :editPanel="addPanel" :panelClass="'edit-sidepanel-large'" @close-panel="addPanel = false">
          <b-row>
              <b-col md="12" class="mb-4">
                <div class="m-4 px-2" v-if="addPanel == true">
                <h4>Add New Project</h4>
                <b-overlay
                        id="overlay-background"
                        :show="loadingForm"
                        variant="white"
                        opacity="0.85"
                        blur="1px"
                        rounded="md"
                      >
                <b-form id="addproject" method="POST" enctype="multipart/form-data">
                   <input type="hidden" name="_token" :value="csrf">
                   <input type="hidden" v-model="newProject.more_time_approved" name="more_time_approved">
                   <input type="hidden" v-model="newProject.more_time_request" name="more_time_request">
                   <input type="hidden" v-model="newProject.more_time_approved_id" name="more_time_approved_id">
                   <input type="hidden" v-model="newProject.completed" name="completed">
                   <input type="hidden" v-model="newProject.archive" name="archive">
                    <input type="hidden" v-model="newProject.reviewed" name="reviewed">
                    <input type="hidden" v-model="newProject.description" name="description">
                <b-row>
                     <b-col md="12">
                    <transition name="slide-error" mode="out-in">
                    <b-alert id="errors" variant="danger" :show="error_message"  dismissible @dismissed="error_message = !error_message">
                      <p class="font-size-14">Oops! Please correct the following errors in your submission</p>
                      <ul>
                        <li v-for="(error, key) in error_reponse">
                          {{error[0]}}
                        </li>
                      </ul>
                    </b-alert>
                    
                  </transition>
                  </b-col>
                                   <b-col md="9">
                  <b-form-group label="Title">
                    <b-form-input type="text" v-model="newProject.name" name="name" />
                  </b-form-group>
                  </b-col>
                   <b-col md="3">
                    <b-form-group label="Reference">
                      <b-form-input type="text" v-model="newProject.reference" name="reference" />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>       
                  <b-col md="5">
                    <b-form-group label="Assign To User">
                      <input type="hidden" v-model="newProject.assigned_to_id" name="assigned_to_id">
                    <SearchCompanyUser :api_token="this.$attrs.user.api_token" @user-selected="assignProjectTo" @input="assignProjectTo" name="assigned_to_id" />
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group label="Status">
                       <input type="hidden" v-model="newProject.project_status_id" name="project_status_id">
                    <SearchCompanyStatus @status-selected="statusSelected" :id="newProject.project_status_id" :api_token="this.$attrs.user.api_token" :options="statuses" />
                    </b-form-group>
                  </b-col>
                   <b-col md="3">
                    <b-form-group label="Due Date">
                     <b-form-datepicker :min="date" id="duedate-datepicker" v-model="newProject.due_date" class="mb-2" name="due_date"></b-form-datepicker>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-form-group label="Description">
                   <ckeditor v-model="newProject.description" :editor="editor" ></ckeditor>
                </b-form-group>
               
                <b-row>       
                  <b-col md="6">
                    <b-form-group label="Assign To Department">
                      <input type="hidden" v-model="newProject.company_department_id" name="company_department_id">
                    <SearchCompanyDepartment :api_token="this.$attrs.user.api_token" @department-selected="selectDepartment" />
                    </b-form-group>
                  </b-col>
                   <b-col md="6">
                    <b-form-group label="Assign To Team">
                      <input type="hidden" v-model="newProject.company_team_id" name="company_team_id">
                    <SearchCompanyTeam :api_token="this.$attrs.user.api_token" @department-selected="selectTeam" />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="12">
                    <b-form-group label="Image">
                       <b-form-file
                      v-model="newProject.image"
                      placeholder="Choose a file or drop it here..."
                      drop-placeholder="Drop file here..."
                      name="image"
                    ></b-form-file>
                    </b-form-group>
                  </b-col>
                 <b-col md="12">
                    <b-form-group label="Attachments">
                       <b-form-file
                      v-model="newProject.attachments"
                      placeholder="Choose a file or drop it here..."
                      drop-placeholder="Drop file here..."
                      multiple
                      name="attachments[]"
                    ></b-form-file>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="12">
                    <h4 class="mt-4">Notifications</h4>
                  </b-col>
                </b-row>
                <b-row class="px-2 py-2">
                  <b-col md="4">
                    <b-form-group label="On Project Creation">
 <input type="hidden" v-model="newProject.settings.creation.email_assigned_to" name="settings[creation][email_assignee]" />
 <input type="hidden" v-model="newProject.settings.creation.email_collaborators"  name="settings[creation][email_collaborators]" />
 <input type="hidden" v-model="newProject.settings.creation.email_creator"  name="settings[creation][email_creator]" />
 <input type="hidden" v-model="newProject.settings.creation.email_additional_emails"  name="settings[creation][email_additional_emails]" />
 <input type="hidden" v-model="newProject.settings.creation.additional_emails" name="settings[creation][additional_emails]" />
 <input type="hidden" v-model="newProject.settings.update.email_assigned_to" name="settings[update][email_assignee]" />
 <input type="hidden" v-model="newProject.settings.update.email_collaborators" name="settings[update][email_collaborators]" />
 <input type="hidden" v-model="newProject.settings.update.email_creator" name="settings[update][email_creator]" />
 <input type="hidden" v-model="newProject.settings.update.email_additional_emails" name="settings[update][email_additional_emails]" />
 <input type="hidden" v-model="newProject.settings.update.additional_emails" name="settings[update][additional_emails]" />
 <input type="hidden" v-model="newProject.settings.status.email_assigned_to" name="settings[status][email_assignee]" />
 <input type="hidden" v-model="newProject.settings.status.email_collaborators" name="settings[status][email_collaborators]" />
 <input type="hidden" v-model="newProject.settings.status.email_creator" name="settings[status][email_creator]" />
 <input type="hidden" v-model="newProject.settings.status.email_additional_emails" name="settings[status][email_additional_emails]" />
 <input type="hidden" v-model="newProject.settings.status.additional_emails" name="settings[status][additional_emails]" />
 <input type="hidden" v-model="newProject.settings.complete.email_assigned_to" name="settings[complete][email_assignee]" />
 <input type="hidden" v-model="newProject.settings.complete.email_collaborators"  name="settings[complete][email_collaborators]" />
 <input type="hidden" v-model="newProject.settings.complete.email_creator"  name="settings[complete][email_creator]" />
 <input type="hidden" v-model="newProject.settings.complete.email_additional_emails"  name="settings[complete][email_additional_emails]" />
 <input type="hidden" v-model="newProject.settings.complete.additional_emails" name="settings[complete][additional_emails]" />
 <input type="hidden" v-model="newProject.settings.delete.email_assigned_to" name="settings[delete][email_assignee]" />
 <input type="hidden" v-model="newProject.settings.delete.email_creator" name="settings[delete][email_creator]" />
  <input type="hidden" v-model="newProject.settings.delete.email_collaborators" name="settings[delete][email_collaborators]" />
 <input type="hidden" v-model="newProject.settings.delete.email_additional_emails" name="settings[delete][email_additional_emails]" />
 <input type="hidden" v-model="newProject.settings.delete.additional_emails" name="settings[delete][additional_emails]" />
 <input type="hidden" v-model="newProject.settings.report.email_assigned_to" name="settings[report][email_assignee]" />
 <input type="hidden" v-model="newProject.settings.report.email_creator" name="settings[report][email_collaborators]" />
 <input type="hidden" v-model="newProject.settings.report.email_additional_emails" name="settings[report][email_additional_emails]" />
 <input type="hidden" v-model="newProject.settings.report.additional_emails" name="settings[report][additional_emails]" />
                             <b-form-checkbox v-model="newProject.settings.creation.email_assigned_to" name="settings[creation][email_assigned_to]" switch>
                               Email Assignee
                            </b-form-checkbox>
                            
                            <b-form-checkbox v-model="newProject.settings.creation.email_collaborators"  name="settings[creation][email_collaborators]"  switch>
                               Email Collaborators
                            </b-form-checkbox>
                            
                            <b-form-checkbox v-model="newProject.settings.creation.email_creator"  name="settings[creation][email_creator]"  switch>
                               Email Creator
                            </b-form-checkbox>
                            
                            <b-form-checkbox v-model="newProject.settings.creation.email_additional_emails"  name="settings[creation][email_additional_emails]"  switch>
                               Email Report to Additional E-mails
                            </b-form-checkbox>
                            <b-form-group label="Additional Emails" class="px-4 mt-2" v-if="newProject.settings.creation.email_additional_emails == 1">
                              <b-form-tags input-id="tags-basic" v-model="newProject.settings.additional_emails" name="settings[creation][additional_emails]" ></b-form-tags>
                            </b-form-group>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group label="On Project Update">
                            
                            <b-form-checkbox v-model="newProject.settings.update.email_assigned_to" name="settings[update][email_assigned_to]" switch>
                               Email Assignee
                            </b-form-checkbox>
                            
                            <b-form-checkbox v-model="newProject.settings.update.email_collaborators" name="settings[update][email_collaborators]" switch>
                               Email Collaborators
                            </b-form-checkbox>
                            
                            <b-form-checkbox v-model="newProject.settings.update.email_creator" name="settings[update][email_creator]" switch>
                               Email Creator
                            </b-form-checkbox>
                            
                            <b-form-checkbox v-model="newProject.settings.update.email_additional_emails" name="settings[update][email_additional_emails]"  switch>
                               Email Report to Additional E-mails
                            </b-form-checkbox>
                            <b-form-group label="Additional Emails" class="px-4 mt-2" v-if="newProject.settings.update.email_additional_emails == 1">
                              <b-form-tags input-id="tags-basic" name="settings[update][additional_emails]" v-model="newProject.settings.update.additional_emails"></b-form-tags>
                            </b-form-group>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group label="On Status Update">
                            
                            <b-form-checkbox v-model="newProject.settings.status.email_assigned_to" name="settings[status][email_assigned_to]" switch>
                               Email Assignee
                            </b-form-checkbox>
                            
                            <b-form-checkbox v-model="newProject.settings.status.email_collaborators" name="settings[status][email_collaborators]" switch>
                               Email Collaborators
                            </b-form-checkbox>
                            
                            <b-form-checkbox v-model="newProject.settings.status.email_creator" name="settings[status][email_creator]" switch>
                               Email Creator
                            </b-form-checkbox>
                            
                            <b-form-checkbox v-model="newProject.settings.status.email_additional_emails" name="settings[status][email_additional_emails]" switch>
                               Email Report to Additional E-mails
                            </b-form-checkbox>
                            <b-form-group label="Additional Emails" class="px-4 mt-2" v-if="newProject.settings.status.email_additional_emails == 1">
                              <b-form-tags input-id="tags-basic" v-model="newProject.settings.status.additional_emails" name="settings[update][additional_emails]"></b-form-tags>
                            </b-form-group>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group label="On Complete">
                            
                            <b-form-checkbox v-model="newProject.settings.complete.email_assigned_to"  name="settings[complete][email_assigned_to]" switch>
                               Email Assignee
                            </b-form-checkbox>
                            
                            <b-form-checkbox v-model="newProject.settings.complete.email_collaborators"  name="settings[complete][email_collaborators]" switch>
                               Email Collaborators
                            </b-form-checkbox>
                            
                            <b-form-checkbox v-model="newProject.settings.complete.email_creator"  name="settings[complete][email_creator]" switch>
                               Email Creator
                            </b-form-checkbox>
                            
                            <b-form-checkbox v-model="newProject.settings.complete.email_additional_emails"  name="settings[complete][email_additional_emails]" switch>
                               Email Report to Additional E-mails
                            </b-form-checkbox>
                            <b-form-group label="Additional Emails" class="px-4 mt-2" v-if="newProject.settings.complete.email_additional_emails == 1">
                              <b-form-tags input-id="tags-basic" v-model="newProject.settings.complete.additional_emails" name="settings[complete][additional_emails]"></b-form-tags>
                            </b-form-group>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group label="On Project Delete">
                             
                             <b-form-checkbox v-model="newProject.settings.delete.email_assigned_to" name="settings[delete][email_assigned_to]" switch>
                               Email Assigned To
                            </b-form-checkbox>
                            
                            <b-form-checkbox v-model="newProject.settings.delete.email_creator" name="settings[delete][email_collaborators]" switch>
                               Email Creator
                            </b-form-checkbox>
                            
                            <b-form-checkbox v-model="newProject.settings.delete.email_additional_emails" name="settings[delete][email_additional_emails]" switch>
                               Email Report to Additional E-mails
                            </b-form-checkbox>
                            <b-form-group label="Additional Emails" class="px-4 mt-2" v-if="newProject.settings.delete.email_additional_emails == 1">
                              <b-form-tags input-id="tags-basic" v-model="newProject.settings.delete.additional_emails" name="settings[delete][additional_emails]"></b-form-tags>
                            </b-form-group>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group label="On Report Creation">
                            
                            <b-form-checkbox v-model="newProject.settings.report.email_assigned_to" name="settings[report][email_assigned_to]" switch>
                               Email Assigned To
                            </b-form-checkbox>
                            
                            <b-form-checkbox v-model="newProject.settings.report.email_creator" name="settings[report][email_collaborators]" switch>
                               Email Creator
                            </b-form-checkbox>
                            
                            <b-form-checkbox v-model="newProject.settings.report.email_additional_emails" name="settings[report][email_additional_emails]" switch>
                               Email Report to Additional E-mails
                            </b-form-checkbox>
                            <b-form-group label="Additional Emails" class="px-4 mt-2" v-if="newProject.settings.report.email_additional_emails == 1">
                              <b-form-tags input-id="tags-basic" v-model="newProject.settings.report.additional_emails" name="settings[report][additional_emails]"></b-form-tags>
                            </b-form-group>
                  </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="12">
                    <b-button variant="success" @click="createNewProject()">Create New Project</b-button>
                  </b-col>
                </b-row>
              </b-form>
            </b-overlay>
                </div>
              </b-col>
              
          </b-row>
    </SideSlidePanel>
    <SideSlidePanel id="add-new-project-panel" :editPanel="editPanel" :panelClass="'edit-sidepanel-large'" @close-panel="editPanel = false">
          <b-row>
              <b-col md="12" class="mb-4">
                <div class="m-4 px-2" v-if="editPanel == true">
                <h4>Update Project</h4>
                <b-overlay
                        id="overlay-background"
                        :show="loadingForm"
                        variant="white"
                        opacity="0.85"
                        blur="1px"
                        rounded="md"
                      >
                <b-form id="updateproject" method="POST" enctype="multipart/form-data">
                <b-row>
                 <input type="hidden" name="_token" :value="csrf">
                   <input type="hidden" v-model="updateProject.company_team_id" name="company_team_id">
                   <input type="hidden" v-model="updateProject.company_department_id" name="company_department_id">
                   <input type="hidden" v-model="updateProject.assigned_by_id" name="assigned_by_id">
                   <input type="hidden" v-model="updateProject.assigned_to_id" name="assigned_to_id">
                   <input type="hidden" v-model="updateProject.more_time_approved" name="more_time_approved">
                   <input type="hidden" v-model="updateProject.more_time_request" name="more_time_request">
                   <input type="hidden" v-model="updateProject.more_time_approved_id" name="more_time_approved_id">
                   <input type="hidden" v-model="updateProject.completed" name="completed">
                   <input type="hidden" v-model="updateProject.archive" name="archive">
                    <input type="hidden" v-model="updateProject.reviewed" name="reviewed">
                   <input type="hidden" v-model="updateProject.project_status_id" name="project_status_id">
                   <input type="hidden" v-model="updateProject.description" name="description">
                   <b-col md="12">
                    <transition name="slide-error" mode="out-in">
                    <b-alert id="errors" variant="danger" :show="error_message"  dismissible @dismissed="error_message = !error_message">
                      <p class="font-size-14">Oops! Please correct the following errors in your submission</p>
                      <ul>
                        <li v-for="(error, key) in error_reponse">
                          {{error[0]}}
                        </li>
                      </ul>
                    </b-alert>
                    
                  </transition>
                  </b-col>
                  <b-col md="12">                  
                    <a v-if="updateProject.archive == 0" class="btn float-right btn-outline-warning ml-3 mb-3" @click="updateProject.archive = 1"><i class="bx bx-check"></i> Archive</a>
                    <a v-if="updateProject.archive == 1" class="btn float-right btn-warning ml-3 mb-3" @click="updateProject.archive = 0"><i class="bx bx-check"></i> Archived</a>
                    <a v-if="updateProject.completed == 0" class="btn float-right btn-outline-success" @click="updateProject.completed = 1"><i class="bx bx-check"></i> Mark Complete</a>
                    <a v-if="updateProject.completed == 1" class="btn float-right btn-success" @click="updateProject.completed = 0"><i class="bx bx-check"></i> Completed</a>
                  </b-col>
                  <b-col md="9">
                  <b-form-group label="Title">
                    <b-form-input type="text" v-model="updateProject.name" name="name" />
                  </b-form-group>
                  </b-col>
                   <b-col md="3">
                    <b-form-group label="Reference">
                      <b-form-input type="text" v-model="updateProject.reference" name="reference" />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>       
                  <b-col md="5">
                    <b-form-group label="Assign To User:">
                    <SearchCompanyUser :id="updateProject.assigned_to.user" :api_token="this.$attrs.user.api_token" @user-selected="assignProjectTo" @input="assignProjectTo" />
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group label="Status">
                    <SearchCompanyStatus @status-selected="statusSelected" :id="updateProject.status" :api_token="this.$attrs.user.api_token" :options="statuses" />
                    </b-form-group>
                  </b-col>
                   <b-col md="3">
                    <b-form-group label="Due Date:">
                     <b-form-datepicker :min="date" id="duedate-datepicker" v-model="updateProject.due_date" class="mb-2" name="due_date"></b-form-datepicker>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-form-group label="Description">
                   <ckeditor v-model="updateProject.description" :editor="editor" name="description"></ckeditor>
                </b-form-group>
               
                <b-row>       
                  <b-col md="6">
                    <b-form-group label="Assign To Department:">
                    <SearchCompanyDepartment :id="updateProject.department" :api_token="this.$attrs.user.api_token"  @department-selected="updateDepartment" />
                    </b-form-group>
                  </b-col>
                   <b-col md="6">
                    <b-form-group label="Assign To Team:">
                    <SearchCompanyTeam :id="updateProject.team" :api_token="this.$attrs.user.api_token"  @department-selected="updateTeam" />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="12">
                    <b-form-group label="Image">
                       <b-form-file
                      v-model="updateProject.image"
                      placeholder="Choose a file or drop it here..."
                      drop-placeholder="Drop file here..."
                      name="image"
                    ></b-form-file>
                    </b-form-group>
                  </b-col>
                  <!--<b-col md="12">
                    <b-form-group label="Attachments">
                       <b-form-file
                      v-model="updateProject.attachments"
                      placeholder="Choose a file or drop it here..."
                      drop-placeholder="Drop file here..."
                      multiple
                      name="attachments"
                    ></b-form-file>
                    </b-form-group>
                  </b-col>-->
                </b-row>
                <b-row>
                  <b-col md="12">
                    <h4>Notifications</h4>
                  </b-col>
                </b-row>
<b-row class="px-2 py-2">
                  <b-col md="4">
                    <input type="hidden" v-model="updateProject.settings.creation.email_assigned_to" name="settings[creation][email_assignee]" />
 <input type="hidden" v-model="updateProject.settings.creation.email_collaborators"  name="settings[creation][email_collaborators]" />
 <input type="hidden" v-model="updateProject.settings.creation.email_creator"  name="settings[creation][email_creator]" />
 <input type="hidden" v-model="updateProject.settings.creation.email_additional_emails"  name="settings[creation][email_additional_emails]" />
 <input type="hidden" v-model="updateProject.settings.creation.additional_emails" name="settings[creation][additional_emails]" />
 <input type="hidden" v-model="updateProject.settings.update.email_assigned_to" name="settings[update][email_assignee]" />
 <input type="hidden" v-model="updateProject.settings.update.email_collaborators" name="settings[update][email_collaborators]" />
 <input type="hidden" v-model="updateProject.settings.update.email_creator" name="settings[update][email_creator]" />
 <input type="hidden" v-model="updateProject.settings.update.email_additional_emails" name="settings[update][email_additional_emails]" />
 <input type="hidden" v-model="updateProject.settings.update.additional_emails" name="settings[update][additional_emails]" />
 <input type="hidden" v-model="updateProject.settings.status.email_assigned_to" name="settings[status][email_assignee]" />
 <input type="hidden" v-model="updateProject.settings.status.email_collaborators" name="settings[status][email_collaborators]" />
 <input type="hidden" v-model="updateProject.settings.status.email_creator" name="settings[status][email_creator]" />
 <input type="hidden" v-model="updateProject.settings.status.email_additional_emails" name="settings[status][email_additional_emails]" />
 <input type="hidden" v-model="updateProject.settings.status.additional_emails" name="settings[status][additional_emails]" />
 <input type="hidden" v-model="updateProject.settings.complete.email_assigned_to" name="settings[complete][email_assignee]" />
 <input type="hidden" v-model="updateProject.settings.complete.email_collaborators"  name="settings[complete][email_collaborators]" />
 <input type="hidden" v-model="updateProject.settings.complete.email_creator"  name="settings[complete][email_creator]" />
 <input type="hidden" v-model="updateProject.settings.complete.email_additional_emails"  name="settings[complete][email_additional_emails]" />
 <input type="hidden" v-model="updateProject.settings.complete.additional_emails" name="settings[complete][additional_emails]" />
 <input type="hidden" v-model="updateProject.settings.delete.email_assigned_to" name="settings[delete][email_assignee]" />
 <input type="hidden" v-model="updateProject.settings.delete.email_creator" name="settings[delete][email_creator]" />
  <input type="hidden" v-model="updateProject.settings.delete.email_collaborators" name="settings[delete][email_collaborators]" />
 <input type="hidden" v-model="updateProject.settings.delete.email_additional_emails" name="settings[delete][email_additional_emails]" />
 <input type="hidden" v-model="updateProject.settings.delete.additional_emails" name="settings[delete][additional_emails]" />
 <input type="hidden" v-model="updateProject.settings.report.email_assigned_to" name="settings[report][email_assignee]" />
 <input type="hidden" v-model="updateProject.settings.report.email_creator" name="settings[report][email_collaborators]" />
 <input type="hidden" v-model="updateProject.settings.report.email_additional_emails" name="settings[report][email_additional_emails]" />
 <input type="hidden" v-model="updateProject.settings.report.additional_emails" name="settings[report][additional_emails]" />
                    <b-form-group label="On Project Creation">
                             <b-form-checkbox v-model="updateProject.settings.creation.email_assigned_to" name="settings[creation][email_assigned_to]" switch>
                               Email Assignee
                            </b-form-checkbox>
                            <b-form-checkbox v-model="updateProject.settings.creation.email_collaborators"  name="settings[creation][email_collaborators]"  switch>
                               Email Collaborators
                            </b-form-checkbox>
                            <b-form-checkbox v-model="updateProject.settings.creation.email_creator"  name="settings[creation][email_creator]"  switch>
                               Email Creator
                            </b-form-checkbox>
                            <b-form-checkbox v-model="updateProject.settings.creation.email_additional_emails"  name="settings[creation][email_additional_emails]"  switch>
                               Email Report to Additional E-mails
                            </b-form-checkbox>
                            <b-form-group label="Additional Emails" class="px-4 mt-2" v-if="updateProject.settings.creation.email_additional_emails == 1">
                              <b-form-tags input-id="tags-basic" v-model="updateProject.settings.additional_emails" name="settings[creation][additional_emails]" ></b-form-tags>
                            </b-form-group>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group label="On Project Update">
                            <b-form-checkbox v-model="updateProject.settings.update.email_assigned_to" name="settings[update][email_assigned_to]" switch>
                               Email Assignee
                            </b-form-checkbox>
                            <b-form-checkbox v-model="updateProject.settings.update.email_collaborators" name="settings[update][email_collaborators]" switch>
                               Email Collaborators
                            </b-form-checkbox>
                            <b-form-checkbox v-model="updateProject.settings.update.email_creator" name="settings[update][email_creator]" switch>
                               Email Creator
                            </b-form-checkbox>
                            <b-form-checkbox v-model="updateProject.settings.update.email_additional_emails" name="settings[update][email_additional_emails]"  switch>
                               Email Report to Additional E-mails
                            </b-form-checkbox>
                            <b-form-group label="Additional Emails" class="px-4 mt-2" v-if="updateProject.settings.update.email_additional_emails == 1">
                              <b-form-tags input-id="tags-basic" name="settings[update][additional_emails]" v-model="updateProject.settings.update.additional_emails"></b-form-tags>
                            </b-form-group>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group label="On Status Update">
                            <b-form-checkbox v-model="updateProject.settings.status.email_assigned_to" name="settings[status][email_assigned_to]" switch>
                               Email Assignee
                            </b-form-checkbox>
                            <b-form-checkbox v-model="updateProject.settings.status.email_collaborators" name="settings[status][email_collaborators]" switch>
                               Email Collaborators
                            </b-form-checkbox>
                            <b-form-checkbox v-model="updateProject.settings.status.email_creator" name="settings[status][email_creator]" switch>
                               Email Creator
                            </b-form-checkbox>
                            <b-form-checkbox v-model="updateProject.settings.status.email_additional_emails" name="settings[status][email_additional_emails]" switch>
                               Email Report to Additional E-mails
                            </b-form-checkbox>
                            <b-form-group label="Additional Emails" class="px-4 mt-2" v-if="updateProject.settings.status.email_additional_emails == 1">
                              <b-form-tags input-id="tags-basic" v-model="updateProject.settings.status.additional_emails" name="settings[update][additional_emails]"></b-form-tags>
                            </b-form-group>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group label="On Complete">
                            <b-form-checkbox v-model="updateProject.settings.complete.email_assigned_to"  name="settings[complete][email_assigned_to]" switch>
                               Email Assignee
                            </b-form-checkbox>
                            <b-form-checkbox v-model="updateProject.settings.complete.email_collaborators"  name="settings[complete][email_collaborators]" switch>
                               Email Collaborators
                            </b-form-checkbox>
                            <b-form-checkbox v-model="updateProject.settings.complete.email_creator"  name="settings[complete][email_creator]" switch>
                               Email Creator
                            </b-form-checkbox>
                            <b-form-checkbox v-model="updateProject.settings.complete.email_additional_emails"  name="settings[complete][email_additional_emails]" switch>
                               Email Report to Additional E-mails
                            </b-form-checkbox>
                            <b-form-group label="Additional Emails" class="px-4 mt-2" v-if="updateProject.settings.complete.email_additional_emails == 1">
                              <b-form-tags input-id="tags-basic" v-model="updateProject.settings.complete.additional_emails" name="settings[complete][additional_emails]"></b-form-tags>
                            </b-form-group>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group label="On Project Delete">
                             <b-form-checkbox v-model="updateProject.settings.delete.email_assigned_to" name="settings[delete][email_assigned_to]" switch>
                               Email Assigned To
                            </b-form-checkbox>
                            <b-form-checkbox v-model="updateProject.settings.delete.email_creator" name="settings[delete][email_collaborators]" switch>
                               Email Creator
                            </b-form-checkbox>
                            <b-form-checkbox v-model="updateProject.settings.delete.email_additional_emails" name="settings[delete][email_additional_emails]" switch>
                               Email Report to Additional E-mails
                            </b-form-checkbox>
                            <b-form-group label="Additional Emails" class="px-4 mt-2" v-if="updateProject.settings.delete.email_additional_emails == 1">
                              <b-form-tags input-id="tags-basic" v-model="updateProject.settings.delete.additional_emails" name="settings[delete][additional_emails]"></b-form-tags>
                            </b-form-group>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group label="On Report Creation">
                            <b-form-checkbox v-model="updateProject.settings.report.email_assigned_to" name="settings[report][email_assigned_to]" switch>
                               Email Assigned To
                            </b-form-checkbox>
                            <b-form-checkbox v-model="updateProject.settings.report.email_creator" name="settings[report][email_collaborators]" switch>
                               Email Creator
                            </b-form-checkbox>
                            <b-form-checkbox v-model="updateProject.settings.report.email_additional_emails" name="settings[report][email_additional_emails]" switch>
                               Email Report to Additional E-mails
                            </b-form-checkbox>
                            <b-form-group label="Additional Emails" class="px-4 mt-2" v-if="updateProject.settings.report.email_additional_emails == 1">
                              <b-form-tags input-id="tags-basic" v-model="updateProject.settings.report.additional_emails" name="settings[report][additional_emails]"></b-form-tags>
                            </b-form-group>
                  </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="12">
                    <b-button variant="success" @click="editProject()">Update Project</b-button>
                  </b-col>
                </b-row>
              </b-form>
            </b-overlay>
                </div>
              </b-col>
              
          </b-row>
    </SideSlidePanel>

      <SideSlidePanel id="add-new-project-panel" :editPanel="collaboratorsPanel" :panelClass="'edit-sidepanel'" @close-panel="collaboratorsPanel = false">
          <b-row>
              <b-col md="12" class="mb-4">
                <div class="m-4 px-2" v-if="collaboratorsPanel == true">
                <b-row>
                 
                  <b-col md="12">
                    <h3>{{updateProject.reference}}: {{updateProject.name}}</h3>
                    <div v-html="updateProject.description"></div>

                  </b-col>
                </b-row>
                <b-row>       
                 </b-row>
                 
                 <h4 class="mt-4 mb-0">Collaborators</h4>

                 
                  <SearchCompanyUsers v-if="addCollaboratorView == true"  :api_token="this.$attrs.user.api_token" :resource="'project'" :id="updateProject.id" @user-added="newCollaboratorAdded" />
                  <a class="btn btn-outline-light float-left" :class="{'mt-2': addCollaboratorView == false}"  @click="addCollaboratorView = !addCollaboratorView"><i v-if="addCollaboratorView == false" class="mdi mdi-plus"></i><i v-if="addCollaboratorView == true" class="mdi mdi-close"></i></a>
                  <UserList v-if="userListLoaded == true" :api_token="this.$attrs.user.api_token" :resource="'project'" :id="updateProject.id" />
                </div>
              </b-col>
              
          </b-row>
    </SideSlidePanel>

    <SideSlidePanel  :editPanel="actionsPanel" :panelClass="'edit-sidepanel-large'" @close-panel="actionsPanel = false">
      <b-row class="mx-3">
        <b-col md="12" >
          <h4 class="mt-4 mb-2 pb-2">Upcoming Actions</h4>
          <ActionsList :project_id="updateProject.id"  :api_token="this.$attrs.user.api_token" />
        </b-col>
      </b-row>
    </SideSlidePanel>
    </div>
  </Layout>
      <Layout v-else :nickname="$attrs.nickname" :user="$attrs.user" >
    <PageHeader :title="title" :items="items" />
    <Subscription :company="this.$attrs.company" :user="this.$attrs.user" />
  </Layout>
</template>