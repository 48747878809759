<template>
	<div style="height: 350px">
	 <gmap-map :map-type-id="$attrs.elements.map_type.value" :center="{ lat: parseFloat($attrs.content.value.latitude.value), lng: parseFloat($attrs.content.value.longitude.value) }" :zoom="$attrs.elements.zoom_level.value" style="height: 350px" class="border-radius-1em" :options="options">
                  <gmap-marker
                    :position="{ lat: parseFloat($attrs.content.value.latitude.value), lng: parseFloat($attrs.content.value.longitude.value) }"
                    :clickable="true"
                    :draggable="true"
                    @click="center = { lat: parseFloat($attrs.content.value.latitude.value), lng: parseFloat($attrs.content.value.longitude.value) }"
                  ></gmap-marker>
                </gmap-map>
</div>
</template>

<script type="text/javascript">
	export default {
		data(){
			return {
				listings: [],
				center: [],
				options: {
				   zoomControl: this.$attrs.elements.allow_zoom.value,
				   mapTypeControl: this.$attrs.elements.map_type_switcher.value,
				   scaleControl: this.$attrs.elements.allow_scaling.value,
				   streetViewControl: this.$attrs.elements.allow_streetview.value,
				   rotateControl: this.$attrs.elements.allow_rotate.value,
				   fullscreenControl: this.$attrs.elements.allow_fullscreen.value,
				   disableDefaultUi: this.$attrs.elements.disable_default_ui.value,
				 }
			}
		},
		methods: {
			filterStylesKey(styles, key){
	            for(var i = 0; i < styles.length; i++){
	               var keys = Object.keys(styles[i])
	               if(keys.includes(key)){
	                 return i;
	               }
	            }
	        },
			loadDefaultClasses(classes){
				return classes;
			},
			
		}
	}

</script>

			