<script type="text/javascript">
	export default{
		props: {
			review: {
				type: Object
			},
			view: {
				type: String,
				default: 'user',
			},
			edit_route:{
				type: String
			},
			approve_route: {
				type: String
			},
			reply_route: {
				type: String
			}
		},
		data(){
			return{
				storageurl: 'https://'+this.$attrs.company.nickname+'.s3.eu-west-2.amazonaws.com',
			}
		}
	}
</script>

<template>
					<b-card class="border border-radius-1em shadow-none">
						
						<b-form-rating readonly v-model="review.rating">
						</b-form-rating>
						<div class="media my-3">
							<div class="avatar-sm me-2" v-if="review.customer !== null">
								<span v-if="review.customer.avatar == null" class="avatar-title rounded-circle">{{review.name.slice(0,1)}}</span>
								<img v-else class="avatar-sm rounded-circle" :src="storageurl+review.customer.avatar.replaceAll('//', '/')">
							</div>
							<div v-else class="avatar-sm me-2">
								<span class="avatar-title rounded-circle">{{review.name.slice(0,1)}}</span>
							</div>
							<div class="media-body my-auto">
								<h5 class="mb-0 mt-auto">
									<strong>{{review.name}} 
									{{review.lastname}}</strong>
									
								</h5>
								<small class="mb-auto mt-0">
										{{review.created_at | luxon({input: 'server'})}}
									</small>
						</div>
					</div>
						<p class="font-size-14" v-html="review.description"></p>
						
						<small>
							xx Found This Review Helpful. 
						</small>
						<small class="float-right" v-if="view !== 'admin'">
							 Did this help you? <a class="text-success" href="javascript"><i class="mdi mdi-thumb-up-outline"></i></a> 
						</small>
						<b-card class="border-radius-1em border shadow-none mb-0 mt-3" v-if="('product' in review && review['product'] !== null) && view == 'admin'" :href="'/stock/product/'+review.product.uid">
						<div class="media" v-if="'product' in review && review['product'] !== null">
							<div class="avatar-sm me-2 my-auto" >
								<span v-if="review.product.cover_image == null" class="avatar-title rounded-circle">{{review.name.slice(0,1)}}</span>
								<img v-else-if="'thumb' in review.product.cover_image" class="avatar-sm rounded-circle" :src="storageurl+review.product.cover_image.thumb.replaceAll('//', '/')">
							</div>
							
							<div class="media-body my-auto w-75">
								<h5 class="mb-0 mt-auto font-size-14">
									{{review.product.name}} 
									
									
								</h5>
								<p class="my-1 text-truncate">
										{{review.product.short_description}}
									</p>
									<a class="btn btn-light btn-rounded btn-sm" :href="'/stock/product/'+review.product.uid">
										View Product
									</a>
						</div>
						
					</div>
				</b-card>
						<b-card-footer v-if="view == 'admin'" class="bg-white border-top mt-2 px-0">
							Source: 
							<span class="badge badge-light bg-light font-size-14 text-capitalize" :class="{'badge-primary bg-primary': review.source == 'public', 'badge-peach bg-peach text-dark': review.source == 'website'}">
								{{review.source}} <span v-if="review.source !== 'website'">Marketplace</span>
							</span>
						</b-card-footer>
					</b-card>
</template>