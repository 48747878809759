export const menuItems = [
  {
    id: 2,
    icon: "",
    isTitle: 0,
    title: "Home",
    label: "Home",
    text: "Home",
    link: "/trade/marketplace",
    menu_category_id: 1,
    parent_menu_id: 0,
    component_name: "0",
    active: 1,
    created_at: null,
    updated_at: null,
    submenu: []
  },
  {
    id: 3,
    icon: "",
    isTitle: 0,
    title: "Jewellery",
    label: "Jewellery",
    text: "Jewellery",
    link: "/company",
    menu_category_id: 1,
    parent_menu_id: 0,
    component_name: "0",
    active: 1,
    created_at: null,
    updated_at: null,
    submenu: [
            {
                id: 8,
                label: 'Rings',
                link: '/trade/category/rings',
                parent_menu_id: 6,
                menu_category_id: 1,
                component_name: 'trade-product-category',
                active: 1,
                submenu: []
            },
            {
                id: 9,
                label: 'Earrings',
                link: '/trade/category/earrings',
                parent_menu_id: 6,
                menu_category_id: 1,
                component_name: 'trade-product-category',
                active: 1,
                submenu: []
            },
            {
                id: 10,
                label: 'Necklaces',
                link: '/trade/category/necklaces',
                parent_menu_id: 6,
                menu_category_id: 1,
                component_name: 'trade-product-category',
                active: 1,
                submenu: []
            },
            {
                id: 11,
                label: 'Bracelets',
                link: '/trade/category/bracelets',
                parent_menu_id: 6,
                menu_category_id: 1,
                component_name: 'trade-product-category',
                active: 1,
                submenu: []
            },
            {
                id: 12,
                label: 'Pendants',
                link: '/trade/category/pendants',
                parent_menu_id: 6,
                menu_category_id: 1,
                component_name: 'trade-product-category',
                active: 1,
                submenu: []
            },
            {
                id: 13,
                label: 'Chains',
                link: '/trade/category/chains',
                parent_menu_id: 6,
                menu_category_id: 1,
                component_name: 'trade-product-category',
                active: 1,
                submenu: []
            },
            {
                id: 14,
                label: 'Tiaras',
                link: '/trade/category/tiaras',
                parent_menu_id: 6,
                menu_category_id: 1,
                component_name: 'trade-product-category',
                active: 1,
                submenu: []
            },
            {
                id: 15,
                label: 'Bangles',
                link: '/trade/category/bangles',
                parent_menu_id: 6,
                menu_category_id: 1,
                component_name: 'trade-product-category',
                active: 1,
                submenu: []
            }
    ]
  },
  {
        id: 70,
        icon: "",
        isTitle: 0,
        title: "menuitems.dashboards.text",
        label: "menuitems.dashboards.text",
        text: "menuitems.dashboards.text",
        menu_category_id: 1,
        parent_menu_id: 0,
        component_name: "0",
        active: 1,
        created_at: null,
        updated_at: null,        
        label: 'menuitems.public.watches.text',
        link: '/trade/category/watches'
    },
    {
        id: 25,
        icon: "",
        isTitle: 0,
        title: "menuitems.dashboards.text",
        label: "menuitems.dashboards.text",
        text: "menuitems.dashboards.text",
        menu_category_id: 1,
        parent_menu_id: 0,
        component_name: "0",
        active: 1,
        created_at: null,
        updated_at: null,
        label: 'menuitems.public.antiques.text',
        link: '/trade/category/antiques'
    },
    
    {
        id: 97,
        icon: "",
        isTitle: 0,
        title: "menuitems.dashboards.text",
        label: "menuitems.dashboards.text",
        text: "menuitems.dashboards.text",
        menu_category_id: 1,
        parent_menu_id: 0,
        component_name: "0",
        active: 1,
        created_at: null,
        updated_at: null,
        label: 'menuitems.public.giftware.text',
        
    },
    {
        id: 97,
        icon: "",
        isTitle: 0,
        title: "menuitems.dashboards.text",
        text: "Equipment",
        menu_category_id: 1,
        parent_menu_id: 0,
        component_name: "0",
        active: 1,
        created_at: null,
        updated_at: null,
        label: 'Tools & Equipment',
        link: '/trade/category/equipment'
        
    },
    {
        id: 97,
        icon: "",
        isTitle: 0,
        title: "menuitems.dashboards.text",
        label: "menuitems.dashboards.text",
        text: "menuitems.dashboards.text",
        menu_category_id: 1,
        parent_menu_id: 0,
        component_name: "0",
        active: 1,
        created_at: null,
        updated_at: null,
        label: 'Directory',
        link: '/trade/directory'
        
    },
    /*{
        id: 97,
        icon: "",
        isTitle: 0,
        title: "menuitems.dashboards.text",
        label: "menuitems.dashboards.text",
        text: "menuitems.dashboards.text",
        menu_category_id: 1,
        parent_menu_id: 0,
        component_name: "0",
        active: 1,
        created_at: null,
        updated_at: null,
        label: 'menuitems.public.news.text',
        
    },*/
]