<script>
import Layout from '../../layouts/main'
import PageHeader from '../../components/page-header'
import DateTime from 'luxon/src/datetime.js';
import SideSlidePanel from "../../components/widgets/SideSlidePanel"
import SearchBar from "../../components/widgets/searchBar"
import EditModal from "../../components/widgets/editModal"
import CompanyProductSearch from '../../components/widgets/CompanyProductSearch';
import SelectCategoriesTabButtons from '../../components/widgets/SelectCategoriesTabButtons';
import SelectCollectionButtons from '../../components/widgets/SelectCollectionButtons';
import CompanyCustomerSearch from "../../components/widgets/CompanyCustomerSearch";
import CompanyCustomerList from "../../components/widgets/CompanyCustomerList";
import Swal from "sweetalert2";
import QrcodeVue from 'qrcode.vue';

/**
 * Shops component
 */
export default {
  components: { Layout, PageHeader, SideSlidePanel, SearchBar, EditModal, DateTime, CompanyProductSearch, SelectCategoriesTabButtons, SelectCollectionButtons, CompanyCustomerSearch, CompanyCustomerList, QrcodeVue },
  data() {
    return {
      publicurl: 'https://gemesys.co.uk',
      qrData: {
        type: 'coupon',
        reference: '',
        uid: '',
        id: '',
      },
      qrOnlineData: '',
      deleteMode: 'single',
      showActiveOn: -1,
      shopsData: '',
      productsData: [],
      showListingProducts: 0,
      addProductToListing: 0,
      selectedListing:[],
      searchproduct: '',
      shops_error: [],
      company_info: [],
      categoryProducts: [],
      productsLimit: '100',
      productsCurrentPage: 1,
      productListingSelected: [],
      newquantity: 0,
      listingquantity: 0,
      transferquantity: 0,
      currentPage: 1,
      orderDesc: false,
      priceRange: [0,999999],
      trade_productsactive: '',
      trade_active: '',
      public_active: '',
      public_productsactive: '',
      totalListingsQuantity: 0,
      editModal: 0,
      productsactive: '',
      brands: '',
      collections: '',
      active: '',
      view: 'list',
      search: '',
      limit: '25',
      sortby: 'id',
      order: 'desc',
      addPanel: false,
      updatePanel: false,
      viewPanel: false,
      viewType: '',
      addCoupon: {
          id:1,
          uid:"34g35456j57mmghress",
          code:"DISC123",
          discount_type: 1,
          discount_amount: 10, 
          start_date:"2021-02-01", 
          end_date:"2022-07-22",
          categories: {
            company_categories: [],
            trade_marketplace_categories: [],
            public_marketplace_categories: [],
          },
          products: [], 
          customers: [],
          emails: [],
          groups:[], 
          description:"My first discount token", 
          quantity:1, 
          use_per_customer:0, 
          starts_at_quantity:0, 
          active:0, 
          public:0, 
          trade:0
      },
      updateCoupon:{
          id:'',
          uid:"34g35456j57mmghress",
          company_id: 16,
          code:"DISC123",
          discount_type: 1,
          discount_amount: 10, 
          start_date:"2021-02-01", 
          end_date:"2022-07-22",
          categories: {
            company_categories: [],
            trade_marketplace_categories: [],
            public_marketplace_categories: [],
          },
          products: [], 
          customers: [],
          emails: [],
          groups:[], 
          description:"My first discount token", 
          quantity:1, 
          use_per_customer:0, 
          starts_at_quantity:0, 
          active:0, 
          public:0, 
          trade:0
      },
      selectedids: [],
      addNewPanel: 0,
      isCustom: 0,
      dropzoneOptions: {
            url: 'https://httpbin.org/post',
            thumbnailHeight: 300,
            thumbnailWidth: 300,
          },
          limits: [
            {value: 25, text: "25"},
            {value: 50, text: "50"},
            {value: 100, text: "100"},
          ],
          transferOptions: [
            {value: 0, text: "Company Stock"},
            {value: 1, text: "Another Shop or Listing"},
          ],
          transferOption: 0,
      title: 'Discount Coupons',
      items: [
        {
          text: 'Discount Coupons',
          active: true,
        },
      ],
    }
  },
  methods: {
    setActive(active, area){
            axios.post('/api/company/order/discount-coupon/set-active', {ids: this.selectedids, active: active, market: area}, {headers: {
              'Authorization' : 'Bearer '+this.$attrs.user.api_token
            }}).then(response => {
              
              this.success_text = 'Congratulations, your action has been saved';
              this.success_message = true;
              if(this.selectedids.length > 1){
                return this.productQuery();
              }else{
                return true;
              }
                
            }).catch(error => {

            });
    },
    bulkDestroyResource(){
            axios.post('/api/company/order/discount-coupon/bulk-destroy', {ids: this.selectedids}, {headers: {
              'Authorization' : 'Bearer '+this.$attrs.user.api_token
            }}).then(response => {
                this.$bvModal.hide('delete-modal');     
                this.selectedids = [];
                return this.productQuery();
            }).catch(error => {

            });
     },
     bulkDuplicateResource(){
      axios.post('/api/company/order/discount-coupon/duplicate', {ids: this.selectedids}, {headers : {
        'Authorization' : 'Bearer '+this.$attrs.user.api_token
      }}).then(response => {
        console.log(response);
        this.success_text = 'Congratulations, your collection(s) have been updated.';
        this.success_message = true;
        //this.resetProjectData();
        this.addPanel = false;
        let self = this;
          setTimeout(function(){
            self.loadingForm = false;
            self.productQuery();
            //self.$bvModal.hide('move-modal');
          }, 1500);
      }).catch( error => {
        
        this.error_reponse = error.response.data.errors;
        this.error_message = true;
        //this.resetProjectData();
        let self = this;
          setTimeout(function(){
            self.loadingForm = false;
          }, 1500);
      });
    },
     copyLink(elementid){
        var copyText = document.getElementById(""+elementid+"");
        console.log(copyText.value);
        this.copy(copyText.value);
        /* Select the text field */
        copyText.select();
        copyText.setSelectionRange(0, 99999); /*For mobile devices*/

        /* Copy the text inside the text field */
        document.execCommand("copy");
    },
    async copy(s) {
      await navigator.clipboard.writeText(s);
       Vue.swal({title: "Text Copied to Clipboard"});
      },
    addCustomerToRestriction(customer){
      var index = this.addCoupon.customers.findIndex(item => item.id == customer["id"]);
      if(index < 0){
        this.addCoupon.customers.push(customer);
      }else{
        Vue.swal("Customer Already Exists in List");
      }
    },
    removeCustomerFromRestriction(customer){
      var index = this.addCoupon.customers.findIndex(item => item.id == customer["id"]);
      this.addCoupon.customers.splice(index, 1);
    },
    brandQuery(){
      axios.get('/api/company/listings?page='+this.currentPage+'&limit='+this.limit+'&sortby='+this.sortby+'&order='+this.order+'&search='+this.search, {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
              }}).then(response => {
        this.shopsData = response.data;
      }).catch(error => {
        this.shops_error = error.data;
      });
    },
    addNewCoupon(){
      var couponCustomers = [];
      for(var i = 0; i > this.addCoupon.customers.length; i++){
        couponCustomers.push(this.addCoupon.customers[i].id);
      }
      this.addCoupon.customers = couponCustomers;
        axios.post('/api/company/order/discount-coupon/create', this.addCoupon, {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
          return this.addCoupon.push(response.data);
        }).catch(error => {

        });
    },
    updateExistingCoupon(){
      var couponCustomers = [];
      for(var i = 0; i > this.updateCoupon.customers.length; i++){
        couponCustomers.push(this.updateCoupon.customers[i].id);
      }
      this.updateCoupon.customers = couponCustomers;
        axios.post('/api/company/order/discount-coupon/'+this.updateCoupon.id+'/update', this.updateCoupon, {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
          return this.productQuery();
        }).catch(error => {

        });
    },
    getProducts(id){
      axios.get('/api/company/'+id+'/products').then(response => {
        this.categoryProducts = response.data;
        //this.showListingProducts = 1;
      }).catch(error => {
        this.products_error = error.data;
      });
    },
    getSellerProducts(search_query){
      if(this.orderDesc == true){
        this.order = 'desc';
      }else{
        this.order = 'asc';
      }
      axios.get('/api/company/order/discount-coupons?page='+this.productsCurrentPage+'&limit='+this.productsLimit+'&sortby='+this.sortby+'&order='+this.order+'&search='+search_query+'&trade_active='+this.trade_productsactive+'&public_active='+this.public_productsactive+'&active='+this.productsactive+'&brands='+this.brands+'&collections='+this.collections+'&pricerange='+this.priceRange, {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
              }}).then(response => {
        console.log(response);
        this.productsData = response.data;
      });
    },
    closeEditModal(){
      this.$refs['edit-modal'].hide()
    },
    addToListing(product){
      this.productListingSelected = product;
      this.selectedProductListingInfo = product.listings;
      if(this.productListingSelected.listings.length > 0){
        var listing_qty = this.productListingSelected.listings.filter(item => (item.listing_id == this.selectedListing.id) && (item.product_id == this.productListingSelected.id));
        if(listing_qty.length > 0){
          this.listingquantity = listing_qty[0].quantity;
        }else{
          this.listingquantity = 0;
        }
      }else{
         this.listingquantity = 0;
      }
      this.$refs['edit-modal'].show();
    },
    getListingData(listing_id){
        return this.shopsData.data.filter(item => item.id == listing_id);
    },
    priceIncVat(price, tax_percentage){
      var tax = 1 + (tax_percentage / 100);
      return price * tax;
    },
     productQuery(){
      if(this.productsData != ''){
        if(this.limit > this.productsData.total){
          this.currentPage = 1;
        }
      }
      if(this.orderDesc == true){
        this.order = 'desc';
      }else{
        this.order = 'asc';
      }
      axios.get('/api/company/order/discount-coupons?page='+this.currentPage+'&limit='+this.limit+'&sortby='+this.sortby+'&order='+this.order+'&search='+this.search+'&trade_active='+this.trade_active+'&public_active='+this.public_active+'&active='+this.active+'&brands='+this.brands+'&collections='+this.collections+'&pricerange='+this.priceRange, {headers : {
        'Authorization' : 'Bearer '+this.$attrs.user.api_token
      }}).then(response => {
        console.log(response);
        this.shopsData = response.data;
      });
    },
    getDate(date){
        var createdOn = DateTime.fromISO(date, { zone: 'utc'});
        console.log(createdOn);
        return createdOn.day+' '+createdOn.monthLong+' '+createdOn.weekYear+'';
      },
      filterSelected(id){
        if(this.selectedids.length > 0){
          var exists = this.selectedids.filter(item => item == id);
          if(exists.length > 0){
            return true;
          }else{
            return false
          }
        }else{
          return false;
        }
      },
      checkAll: function(){ 
                 this.isCheckAll = !this.isCheckAll; 
                 this.selectedids = []; 
                 if(this.isCheckAll){ 
                     // Check all 
                     for (var key in this.shopsData.data) {          
                        this.selectedids.push(this.shopsData.data[key].id); 
                     } 
                 } 
              }, 
      updateCheckall: function(){ 
                 if(this.selectedids.length == this.shopsData.data.length){
                    this.isCheckAll = true; 
                 }else{ 
                    this.isCheckAll = false; 
                 } 
      },
  },
  computed:{
    allProductListingQuantities: function(){
      if(this.productListingSelected.length !== 0){
          if(this.productListingSelected.listings.length > 0){
            this.totalListingsQuantity = 0;
            for(var i = 0; i < this.productListingSelected.listings.length; i++){
              //console.log(this.productListingSelected.listings[i].quantity);
              this.totalListingsQuantity = Number.parseFloat(this.totalListingsQuantity) + Number.parseFloat(this.productListingSelected.listings[i].quantity);
            }
            return this.totalListingsQuantity;
          }else{
            this.totalListingsQuantity = 0;
            return 0;
          }
      }else{
        return 0;
      }
    }
  },
  created(){
    //console.log(localStorage.getItem('default_company'));
    this.company_info = JSON.parse(localStorage.getItem('default_company'));
    this.productQuery();
  },
  mounted(){

  },
}
</script>

<template>
  <Layout  :nickname="$attrs.nickname" :user="$attrs.user" :menuItems="$attrs.menuItems" >
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-md-12">

        <div class="row mb-3">
          <div class="col-xl-1 col-sm-6">
            <div class="mt-2">
              <b-form-select class="form-control" v-model="limit" :options="limits" @input="productQuery()"></b-form-select>
            </div>
          </div>
         
          <div class="col-lg-2 col-sm-6 d-flex">
          <form class="my-auto">
            <small class="mx-auto my-2 text-center d-block">Results {{productsData.from}} - {{productsData.to}} of {{productsData.total}}</small>
          </form>
        </div>
        <div class="col-lg-5 col-sm-6 d-flex">
            <div class="mt-sm-0 form-inline w-100" >
              <div class="search-box mr-2 ml-auto">
                <div class="position-relative">
                  <input
                    type="text"
                    class="form-control w-100 border-0"
                    placeholder="Search name, reference or description..."
                    v-model="search"
                    @keyup.enter="productQuery()"
                  />
                  <i class="bx bx-search-alt search-icon"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-sm-6 d-flex">
            <div class="d-flex ml-auto">
              <ul class="nav nav-pills product-view-nav">
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': showFilters == true }" href="javascript: void(0);" @click="showFilters = !showFilters">
                    <i class="bx bx-filter-alt"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': view == 'grid' }" href="javascript: void(0);" @click="view = 'grid'">
                    <i class="bx bx-grid-alt"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': view == 'list' }" href="javascript: void(0);" @click="view = 'list'">
                    <i class="bx bx-list-ul"></i>
                  </a>
                </li>
                <li class="nav-item">
                 <b-dropdown text="Bulk Actions" variant="primary" class="rounded">
                  <b-dropdown-text class="list-group list-group-flush" text-class="list-group-item mb-0" @mouseenter="showActiveOn = 'active'" @mouseleave="showActiveOn = -1">

                      <i class="bx bx-check-circle font-size-14 text-success">
                        </i> Set Active On:
                   <transition name="fade-right">
                        <b-list-group v-if="showActiveOn == 'active'" flush class="pages-submenu hide" style="position: absolute; left: -100%; top: 0; width: 100%" >
                           <b-list-group-item href="javascript: void(0);" @click="setActive(true, 'active')">Shops</b-list-group-item>
                            <b-list-group-item href="javascript: void(0);" @click="setActive(true, 'public')">Public Market</b-list-group-item>
                            <b-list-group-item href="javascript: void(0);" @click="setActive(true, 'trade')">Trade Market</b-list-group-item>
                        </b-list-group>
                      </transition>
                    </b-dropdown-text>
                    <b-dropdown-text class="list-group list-group-flush" text-class="list-group-item mb-0" @mouseenter="showActiveOn = 'inactive'" @mouseleave="showActiveOn = -1">

                      <i class="bx bx-x-circle font-size-14 text-danger">
                        </i> Set Inactive On:
                   <transition name="fade-right">
                        <b-list-group v-if="showActiveOn == 'inactive'" flush class="pages-submenu hide" style="position: absolute; left: -100%; top: 0; width: 100%" >
                           <b-list-group-item href="javascript: void(0);" @click="setActive(false, 'active')">Shops</b-list-group-item>
                            <b-list-group-item href="javascript: void(0);"  @click="setActive(false, 'public')">Public Market</b-list-group-item>
                            <b-list-group-item href="javascript: void(0);"  @click="setActive(false, 'trade')">Trade Market</b-list-group-item>
                        </b-list-group>
                      </transition>
                    </b-dropdown-text>
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-item href="javascript: void(0);" @click="bulkDuplicateResource()"><i class="bx bx-copy-alt font-size-14"></i> Duplicate</b-dropdown-item>
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-item href="javascript: void(0);" v-b-modal.delete-modal @click="deleteMode = 'bulk'"><i class="bx bx-trash-alt font-size-14"></i> Delete</b-dropdown-item>
                </b-dropdown>
                </li>
                <li class="nav-item">
                 <a
                    class="btn btn-success btn-rounded mb-2 mr-2 text-white"
                    href="javascript:;"
                    @click="addPanel = !addPanel"
                  >
                    <i class="mdi mdi-plus mr-1"></i> New
                  </a>
                </li>
              </ul>
              <div class="text-sm-right">
                  
                </div>
          </div>
        </div>
      </div>
        <div class="card" v-if="view == 'list'" >
          <div class="card-body p-0">
            <div class="table-responsive" style="overflow-x: visible">
              <table class="table table-centered table-hover">

                <thead class="thead-light">
                  <tr>
                    <th scope="col">
                      <div
                        class="form-check form-switch form-switch-sm mb-0 mx-auto p-0 mt-auto d-flex"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input mx-auto my-auto" 
                          type="checkbox"
                          id="SwitchCheckSizesm"
                         
                          v-model="isCheckAll"
                          @click='checkAll()'
                          name="check-all"
                        />
                    </div>
                    </th>
                    <th scope="col"><a :class="{'text-dark': sortby != 'order_no'}" href="javascript: void(0);" @click="orderDesc = !orderDesc,  sortby = 'order_no', brandQuery()">Coupon Code <i v-if="orderDesc == true" class="bx bx-caret-up"></i><i v-if="orderDesc == false" class="bx bx-caret-down"></i></a></th>
                    <th scope="col">Discount Type</th>
                    <th scope="col">Discount Amount</th>
                    <th scope="col">Start Date</th>
                    <th scope="col">End Date</th>
                    <th scope="col">Quantity</th>
                    <th scope="col">Active</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
               
                <tbody>
                  
                  <tr v-for="list in shopsData.data" :key="list.id" valign="middle">
                    <td>
                                            <div class="text-center" >
                        <b-form-checkbox class="select-row-btn" v-model="selectedids" :value="list.id" button-variant="light" @input="updateSelected" button>

                          <i v-if="filterSelected(list.id)" class="bx bx-check"></i><span class="avatar-title rounded-circle bg-light text-primary avatar-sm" v-else>{{(list.code).slice(0,1)}}</span></b-form-checkbox>
                    </div>
                    </td>
                    <td>
                       <a :href="'javascript:;'" @click="updateCoupon = list, viewPanel = true"><strong>{{list.code}}</strong></a>
                    </td>
                    <td>
                      <div class="d-flex" v-if="list.discount_type == 1">
                        <i class="mdi mdi-percent-outline font-size-18 text-primary my-auto"></i> <span class="ml-2 my-auto">Percentage</span>
                      </div>
                       <div class="d-flex" v-if="list.discount_type == 2">
                        <i class="mdi mdi-currency-gbp font-size-18 text-primary my-auto"></i> <span class="ml-2 my-auto">Fixed Amount</span>
                      </div>
                    </td>
                    <td>
                      <div>
                        {{parseFloat(list.discount_amount).toFixed(2)}}
                      </div>
                    </td>
                     <td>
                      <i class="bx bx-calendar"></i> {{getDate(list.start_date)}}
                    </td>
                     <td>
                      <i class="bx bx-calendar"></i> {{getDate(list.start_date)}}
                    </td>
                    <td>
                      {{list.quantity}}
                    </td>
                     <td class="text-center">
                      <div
                        class="form-check form-switch form-switch-sm mb-0 mt-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          id="SwitchCheckSizesm"
                          v-model="list.active"
                          value="1"
                          unchecked-value="0"
                          name="active"
                        />
                        <label class="form-check-label" for="SwitchCheckSizesm" 
                          >Active</label
                        >
                    </div>
                    <div
                        class="form-check form-switch form-switch-sm mb-0"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          id="SwitchCheckSizesm"
                          v-model="list.trade"
                          value="1"
                          unchecked-value="0"
                          name="trade"
                        />
                        <label class="form-check-label" for="SwitchCheckSizesm" 
                          >Trade</label
                        >
                    </div>
                    <div
                        class="form-check form-switch form-switch-sm mb-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          id="SwitchCheckSizesm"
                          v-model="list.public"
                          value="1"
                          unchecked-value="0"
                          name="public"
                        />
                        <label class="form-check-label" for="SwitchCheckSizesm" 
                          >Public</label>
                    </div>
                    </td>
                    <td>
                      <ul class="list-inline font-size-20 contact-links mb-0">
                        <li class="list-inline-item"><a v-b-tooltip.hover title="Edit" placement="left" class="btn btn-outline-light btn-rounded" @click="updateCoupon = list, updatePanel = true"><i class="bx bx-edit-alt"></i>
                          </a>
                        </li>
                        <li class="list-inline-item">
                          <a href="javascript:;" v-b-tooltip.hover title="View" placement="left" @click="updateCoupon = list, viewPanel = true" class="btn btn-outline-light btn-rounded"><i class="mdi mdi-eye-outline"></i></a>
                        </li>
                        <li class="list-inline-item">
                           <b-dropdown v-b-tooltip.hover title="More Options" placement="left" class="more-options-dropdown" menu-class="dropdown-menu-right">
                        <template v-slot:button-content>
                           <i class="bx bx-dots-horizontal-rounded"></i>
                        </template>
                          <b-dropdown-text class="list-group list-group-flush" text-class="list-group-item mb-0" @mouseenter="showActiveOn = 'active'" @mouseleave="showActiveOn = -1">

                      <i class="bx bx-check-circle font-size-14 text-success">
                        </i> Set Active On:
                   <transition name="fade-right">
                        <b-list-group v-if="showActiveOn == 'active'" flush class="pages-submenu hide" style="position: absolute; left: -100%; top: 0; width: 100%" >
                           <b-list-group-item href="javascript: void(0);" @click="selectedids = [], selectedids.push(list.id), setActive(true, 'active')">Shops</b-list-group-item>
                            <b-list-group-item href="javascript: void(0);" @click="selectedids = [], selectedids.push(list.id), setActive(true, 'public')">Public Market</b-list-group-item>
                            <b-list-group-item href="javascript: void(0);" @click="selectedids = [], selectedids.push(list.id), setActive(true, 'trade')">Trade Market</b-list-group-item>
                        </b-list-group>
                      </transition>
                    </b-dropdown-text>
                    <b-dropdown-text class="list-group list-group-flush" text-class="list-group-item mb-0" @mouseenter="showActiveOn = 'inactive'" @mouseleave="showActiveOn = -1">

                      <i class="bx bx-x-circle font-size-14 text-danger">
                        </i> Set Inactive On:
                   <transition name="fade-right">
                        <b-list-group v-if="showActiveOn == 'inactive'" flush class="pages-submenu hide" style="position: absolute; left: -100%; top: 0; width: 100%" >
                           <b-list-group-item href="javascript: void(0);" @click="selectedids = [], selectedids.push(list.id), setActive(false, 'active')">Shops</b-list-group-item>
                            <b-list-group-item href="javascript: void(0);"  @click="selectedids = [], selectedids.push(list.id), setActive(false, 'public')">Public Market</b-list-group-item>
                            <b-list-group-item href="javascript: void(0);"  @click="selectedids = [], selectedids.push(list.id), setActive(false, 'trade')">Trade Market</b-list-group-item>
                        </b-list-group>
                      </transition>
                    </b-dropdown-text>
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-item href="javascript: void(0);" @click="selectedids = [], selectedids.push(list.id),bulkDuplicateResource()"><i class="bx bx-copy-alt font-size-14"></i> Duplicate</b-dropdown-item>
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-item href="javascript: void(0);" v-b-modal.delete-modal @click="selectedids = [], selectedids.push(list.id), deleteMode = 'single'"><i class="bx bx-trash-alt font-size-14"></i> Delete</b-dropdown-item>
                      </b-dropdown>
                        </li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <b-pagination
              v-if="shopsData.last_page > 1"
              class="justify-content-center"
              pills
              v-model="currentPage"
              :total-rows="shopsData.total"
              :per-page="shopsData.per_page"
              aria-controls="my-table"
              @input="brandQuery"
            ></b-pagination>
             <small class="mx-auto my-2 text-center d-block">Records {{shopsData.from}} - {{shopsData.to}} of {{shopsData.total}} // Page {{shopsData.current_page}} of {{shopsData.last_page}}</small>
              </div>
            </div>
          </div>
      </div>
    <div class="row" v-if="view == 'grid'" >
     <div class="col-xl-4"  v-for="list in shopsData.data" :key="list.id">
        <div class="card border-radius-1em">
          <div class="bg-soft-primary border-radius-top-left-1em border-radius-top-right-1em bg-light" style="min-height: 150px;">
            <div class="row">
              
              <div class="col-12 align-self-end">
                <div class="row">
                 <div class="col-md-12 position-absolute"> 
                
                <a id="tooltip-add" href="javascript:;"  @click="updateCoupon = list, viewPanel = true" class="mt-3 mr-3 btn btn-primary font-size-16 btn-rounded ml-auto float-right"><i class="mdi mdi-eye-outline"></i></a>
              </div>
              </div>
               
              </div>
            </div>
          </div>
          <div class="card-body pt-0">
            <div class="row">
              <div class="col-sm-12 text-center px-4 mb-2">
               <!-- <div class="avatar-xl profile-user-wid mb-4">
                  <img class="img-thumbnail rounded-circle h-100" :src="`/public/storage/${list.image}`" alt />
                </div>-->
                <div class="avatar-sm profile-user-wid mx-auto">
                   <div class="text-center" v-if="list.image">
                      <b-form-checkbox class="select-row-btn" v-model="selectedids" :value="list.id" button-variant="primary" @input="updateSelected" button>
                        <i v-if="filterSelected(list.id)" class="bx bx-check"></i>
                        <img v-else :src="`${list.image}`" alt class="avatar-sm img-thumbnail rounded-circle h-100" />
                      </b-form-checkbox>
                    </div>
                    <div class="text-center" v-else >
                        <b-form-checkbox class="select-row-btn" v-model="selectedids" :value="list.id" button-variant="primary" @input="updateSelected" button>

                          <i v-if="filterSelected(list.id)" class="bx bx-check"></i><span class="avatar-title rounded-circle" v-else>{{(list.code).slice(0,1)}}</span></b-form-checkbox>
                    </div> 
                </div>
                <h4 class="font-size-18 text-truncate mt-3">{{list.code}}</h4>
                <p class="text-muted mb-0 text-truncate" v-html="list.description"></p>
              </div>
              
            
            </div>
          </div>
          <div class="card-footer  bg-transparent border-top">
                  <div class="d-flex">
                    <a id="tooltip-add" @click="editcollection = list, editPanel = true" class="btn btn-outline-light font-size-16 btn-rounded my-auto"><i class="bx bx-pencil"></i></a>
                    <a class="btn btn-outline-light font-size-16 btn-rounded ml-2 my-auto" v-b-tooltip.hover title="Delete" placement="left"  @click="selectedids = [], selectedids.push(list.id), deleteMode = 'single'" v-b-modal.delete-modal><i class="bx bx-trash-alt"></i>
                          </a>
                    <b-dropdown  placement="left" variant="outline-light" dropup class="ml-2 more-options-dropdown btn-rounded" menu-class="dropdown-menu-left dropup">
                        <template v-slot:button-content>
                           <i class="bx bx-dots-horizontal-rounded"></i>
                        </template>
                           <b-dropdown-text class="list-group list-group-flush" text-class="list-group-item mb-0" @mouseenter="showActiveOn = 'active'" @mouseleave="showActiveOn = -1">

                      <i class="bx bx-check-circle font-size-14 text-success">
                        </i> Set Active On:
                   <transition name="fade-right">
                        <b-list-group v-if="showActiveOn == 'active'" flush class="pages-submenu hide" style="position: absolute; right: -100%; top: 0; width: 100%" >
                           <b-list-group-item href="javascript: void(0);" @click="selectedids = [], selectedids.push(list.id), setActive(true, 'active')">Shops</b-list-group-item>
                            <b-list-group-item href="javascript: void(0);" @click="selectedids = [], selectedids.push(list.id), setActive(true, 'public')">Public Market</b-list-group-item>
                            <b-list-group-item href="javascript: void(0);" @click="selectedids = [], selectedids.push(list.id), setActive(true, 'trade')">Trade Market</b-list-group-item>
                        </b-list-group>
                      </transition>
                    </b-dropdown-text>
                    <b-dropdown-text class="list-group list-group-flush" text-class="list-group-item mb-0" @mouseenter="showActiveOn = 'inactive'" @mouseleave="showActiveOn = -1">

                      <i class="bx bx-x-circle font-size-14 text-danger">
                        </i> Set Inactive On:
                   <transition name="fade-right">
                        <b-list-group v-if="showActiveOn == 'inactive'" flush class="pages-submenu hide" style="position: absolute; right: -100%; top: 0; width: 100%" >
                           <b-list-group-item href="javascript: void(0);" @click="selectedids = [], selectedids.push(list.id), setActive(false, 'active')">Shops</b-list-group-item>
                            <b-list-group-item href="javascript: void(0);"  @click="selectedids = [], selectedids.push(list.id), setActive(false, 'public')">Public Market</b-list-group-item>
                            <b-list-group-item href="javascript: void(0);"  @click="selectedids = [], selectedids.push(list.id), setActive(false, 'trade')">Trade Market</b-list-group-item>
                        </b-list-group>
                      </transition>
                    </b-dropdown-text>
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-item href="javascript: void(0);" @click="selectedids = [], selectedids.push(list.id),bulkDuplicateResource()"><i class="bx bx-copy-alt font-size-14"></i> Duplicate</b-dropdown-item>
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-item href="javascript: void(0);" v-b-modal.delete-modal @click="selectedids = [], selectedids.push(list.id), deleteMode = 'single'"><i class="bx bx-trash-alt font-size-14"></i> Delete</b-dropdown-item>
                      </b-dropdown>
                      <div
                        class="form-check form-switch form-switch-sm my-auto ml-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          :id="`SwitchCheckSizeact-${list.id}`"
                          v-model="list.active"
                          :value="list.active"
                          name="active"
                          @change="selectedids = [], selectedids.push(list.id), setActive('')"
                        />
                        <label class="form-check-label" :for="`SwitchCheckSizeact-${list.id}`" 
                          >Active</label
                        >
                    </div>
                     <div
                        class="form-check form-switch form-switch-sm my-auto ml-2"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          :id="`SwitchCheckSizepub-${list.id}`"
                          v-model="list.public"
                          :value="list.public"
                          name="public"
                          @change="selectedids = [], selectedids.push(list.id), setActive('')"
                        />
                        <label class="form-check-label" :for="`SwitchCheckSizepub-${list.id}`" 
                          >Public</label
                        >
                    </div>
                     <div
                        class="form-check form-switch form-switch-sm my-auto ml-2"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          :id="`SwitchCheckSizetrade-${list.id}`"
                          v-model="list.trade"
                          :value="list.trade"
                          name="active"
                          @change="selectedids = [], selectedids.push(list.id), setActive('')"
                        />
                        <label class="form-check-label" :for="`SwitchCheckSizetrade-${list.id}`" 
                          >Trade</label
                        >
                    </div>
                  </div>
          </div>
        </div>
    </div>

              <div class="col-lg-12">
                <b-pagination
              v-if="shopsData.data.length > 0"
              class="justify-content-center"
              pills
              v-model="currentPage"
              :total-rows="shopsData.total"
              :per-page="shopsData.per_page"
              aria-controls="my-table"
              @input="brandQuery"
            ></b-pagination>
             <small class="mx-auto my-2 text-center d-block">Records {{shopsData.from}} - {{shopsData.to}} of {{shopsData.total}} // Page {{shopsData.current_page}} of {{shopsData.last_page}}</small>
              </div>
      </div>
    </div>
    </div>
    <!--  end row -->

    <div class="row">
      <!-- end col-->
    </div>
    <b-modal id="delete-modal" size="md" hide-footer hide-header centered>
        <b-card class="border-radius-1em border shadow-none">
          <h4>Are you sure?</h4>
          <p v-if="deleteMode == 'bulk'">You are about to delete {{selectedids.length}} products. Are you sure you want to do this? This action is not reversable</p>
           <p v-if="deleteMode == 'single'">You are about to delete this category. Are you sure you want to do this? This action is not reversable</p>

           <div class="d-flex">
            <a class="btn btn-danger" v-if="deleteMode == 'bulk'" @click="bulkDestroyResource">
              Delete
            </a>
             <a class="btn btn-danger"  v-if="deleteMode == 'single'" @click="bulkDestroyResource">
              Delete
            </a>
            <a class="btn btn-success" @click="$bvModal.hide('delete-modal')">
              Cancel
            </a>
          </div>
        </b-card>
      </b-modal>
    <SideSlidePanel :editPanel="updatePanel" :panelClass="'edit-sidepanel'" @close-panel="updatePanel = false">
      <b-card class="shadow-none border-none" v-if="updateCoupon.id !== ''">
          <div class="row">
        <b-col md="12">
          <h4 class="mt-auto mb-4 mr-auto">Update Discount Coupon</h4>
            <hr>
        </b-col>
        <b-col md="12">
          <form>
            <b-form-group class="mb-2" label="Name">
              <b-form-input v-model="updateCoupon.name" type="text" name="name"></b-form-input>
            </b-form-group>
            <b-form-group class="mb-2" label="Description">
              <b-textarea type="text" name="description"></b-textarea>
            </b-form-group>
            <b-form-group class="mb-2" label="Code">
              <b-form-input v-model="updateCoupon.code" type="text" name="code"></b-form-input>
            </b-form-group>
            <b-form-group class="mb-2" label="Discount Type" >
              <b-form-radio-group v-model="updateCoupon.discount_type" button-variant="outline-primary" buttons :options="[{value: 1, text: 'Percentage'}, {value: 2, text: 'Fixed Amount'}]"></b-form-radio-group>
            </b-form-group>
            <b-form-group class="mb-2" label="Discount Amount">
              <b-form-input v-model="updateCoupon.discount_amount" type="number" name="code"></b-form-input>
            </b-form-group>
            <b-form-group class="mb-2" label="Coupons Quantity">
              <b-form-input v-model="updateCoupon.quantity" type="number" name="quantity"></b-form-input>
            </b-form-group>
            <b-form-group class="mb-2" label="Use Per Customer">
              <b-form-input v-model="updateCoupon.use_per_customer" type="number" name="user_per_customer"></b-form-input>
            </b-form-group>
            <b-row>
              <b-col>
                <b-form-group class="mb-2" label="Starts at">
                  <b-form-datepicker v-model="updateCoupon.start_date" type="number" name="user_per_customer"></b-form-datepicker>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group class="mb-2" label="Ends">
                  <b-form-datepicker v-model="updateCoupon.end_date" type="number" name="user_per_customer"></b-form-datepicker>
                </b-form-group>
              </b-col>
            </b-row>
          </form>
        </b-col>
        <b-col md="12">
          <h4 class="mt-4 mb-4 mr-auto">Restrictions</h4>
            <hr>
            <div class="d-flex mb-3">
            <b-button
                      class="flex-grow-1 my-0 py-3 mr-1 w-sm"
                      value="0"
                      :class="{'active' : updateCoupon.active == (true || 1 || '1')}"
                      variant="outline-light"
                      @click="updateCoupon.active = !updateCoupon.active"
                    ><i v-if="updateCoupon.active == (true || 1 || '1')" class="bx bx-check-circle font-size-24 d-block text-success my-2"></i> 
                    <i v-else class="bx bx-basket font-size-24 d-block text-primary my-2"></i> My Shops
              </b-button>
              <b-button
                      class=" flex-grow-1 my-0 py-3 mx-1 w-sm"
                      value="0"
                      :class="{'active' : updateCoupon.public == (true || 1 || '1')}"
                      variant="outline-light"
                      @click="updateCoupon.public = !updateCoupon.public"
                    ><i v-if="updateCoupon.public == (true || 1 ||  '1')" class="bx bx-check-circle font-size-24 d-block text-success my-2"></i> 
                    <i v-else class="bx bx-basket font-size-24 d-block text-primary my-2"></i> Public Market
              </b-button>
              <b-button
                      class=" flex-grow-1 my-0 py-3 ml-1 w-sm"
                      value="0"
                      :class="{'active' : updateCoupon.trade == (true || 1 || '1')}"
                      variant="outline-light"
                      @click="updateCoupon.trade = !updateCoupon.trade"
                    ><i v-if="updateCoupon.trade == (true || 1 || '1')" class="bx bx-check-circle font-size-24 d-block text-success my-2"></i> 
                    <i v-else class="bx bx-basket font-size-24 d-block text-primary my-2"></i> Trade Market
              </b-button>
            </div>
          <b-tabs pills>
          <b-tab title="Products">
          <b-form-group class="mt-3 mb-2" label="Allow only for selected products">
            <CompanyProductSearch class="mt-3 mb-4" :resource="'category'" :resource_id="''" :api_token="$attrs.user.api_token" :company="$attrs.company" :resource_route="''" @product-added="addProductOptions" ></CompanyProductSearch>
          </b-form-group>
        </b-tab>
        <b-tab title="Categories">
          <b-form-group class="mt-3 mb-2" label="Allow only for selected categories">
            <SelectCategoriesTabButtons :user="$attrs.user" :company_categories="updateCoupon['categories']['company_categories']" :trade_marketplace_categories="updateCoupon['categories']['trade_marketplace_categories']" :public_marketplace_categories="updateCoupon['categories']['public_marketplace_categories']" :active="updateCoupon.active" :public="updateCoupon.public" :trade="updateCoupon.trade"></SelectCategoriesTabButtons>
          </b-form-group>
        </b-tab>
        <b-tab title="Collections">
          <b-form-group class="mt-3 mb-2" label="Allow only for selected collections">
            <SelectCollectionButtons class="mt-3 mb-4" :resource="'collection'" :resource_id="''" :api_token="$attrs.user.api_token" :resource_route="''" ></SelectCollectionButtons>
          </b-form-group>
        </b-tab>
        <b-tab title="Customers">
          <b-form-group class="mt-3 mb-2" label="Allow only for selected customers">
            <CompanyCustomerSearch class="mt-3 mb-4" :resource="'customer'" :resource_id="''" :api_token="$attrs.user.api_token" :resource_route="''" @search-resource-selected="addCustomerToRestriction" ></CompanyCustomerSearch>
            <CompanyCustomerList :resource="'customer'" :resource_id="''" :api_token="$attrs.user.api_token" :resource_route="''" :customer_list="updateCoupon.customers" :view="'list'" @remove-resource="removeCustomerFromRestriction"></CompanyCustomerList>
          </b-form-group>
        </b-tab>
        <b-tab title="Emails">
          <b-form-group class="mt-3 mb-2" label="Allow only for selected emails" description="You can copy and paste a comma separated list of email addresses here">
            <b-form-tags separator=", " v-model="updateCoupon.emails"></b-form-tags>
          </b-form-group>
        </b-tab>
        
      </b-tabs>
        </b-col>
        <b-col md="12">
            <form>

            </form>
        </b-col>
        <b-col md="12">
          <a class="btn btn-success btn-block" @click="updateExistingCoupon()">
            Submit
          </a>
        </b-col>
      </div>
      </b-card>
    </SideSlidePanel>
    <b-modal v-model="viewPanel" :editPanel="viewPanel" hide-footer size="xl" :panelClass="'edit-sidepanel'" @close-panel="viewPanel = false">
      <b-card class="shadow-none border-none">
        <b-row>
          <b-col md="12">
            <h4 class="mt-auto mb-4 mr-auto">Coupon Details</h4>
            <hr>
            <b-card class="border-radius-1em border shadow-none">
            <b-list-group flush>
              <b-list-group-item>
                <h3 class="text-primary" v-html="updateCoupon.code"></h3>
                <h6 v-html="updateCoupon.name"></h6>
                <p v-html="updateCoupon.description"></p>
              </b-list-group-item>
              <b-list-group-item>
                <div class="d-flex mb-3">
                <b-button
                      class="flex-grow-1 my-0 py-3 mr-1 w-sm"
                      value="0"
                      :class="{'active' : updateCoupon.active == (true || 1 || '1')}"
                      variant="outline-light"
                    ><i v-if="updateCoupon.active == (true || 1 || '1')" class="bx bx-check-circle font-size-24 d-block text-success my-2"></i> 
                    <i v-else class="bx bx-basket font-size-24 d-block text-primary my-2"></i> My Shops
              </b-button>
              <b-button
                      class=" flex-grow-1 my-0 py-3 mx-1 w-sm"
                      value="0"
                      :class="{'active' : updateCoupon.public == (true || 1 || '1')}"
                      variant="outline-light"
                    ><i v-if="updateCoupon.public == (true || 1 ||  '1')" class="bx bx-check-circle font-size-24 d-block text-success my-2"></i> 
                    <i v-else class="bx bx-basket font-size-24 d-block text-primary my-2"></i> Public Market
              </b-button>
              <b-button
                      class=" flex-grow-1 my-0 py-3 ml-1 w-sm"
                      value="0"
                      :class="{'active' : updateCoupon.trade == (true || 1 || '1')}"
                      variant="outline-light"
                    ><i v-if="updateCoupon.trade == (true || 1 || '1')" class="bx bx-check-circle font-size-24 d-block text-success my-2"></i> 
                    <i v-else class="bx bx-basket font-size-24 d-block text-primary my-2"></i> Trade Market
              </b-button>
              </div>
            </div>
              </b-list-group-item>
              <b-list-group-item>
                <h6>Discount Type:</h6>
                       <div class="d-flex" v-if="updateCoupon.discount_type == 1">
                          <i class="mdi mdi-percent-outline font-size-18 text-primary my-auto"></i> <span class="ml-2 my-auto">Percentage</span>
                       </div>
                       <div class="d-flex" v-if="updateCoupon.discount_type == 2">
                        <i class="mdi mdi-currency-gbp font-size-18 text-primary my-auto"></i> <span class="ml-2 my-auto">Fixed Amount</span>
                      </div>
              </b-list-group-item>
              <b-list-group-item>
                <h6>Discount Amount:</h6>
                <p>{{parseFloat(updateCoupon.discount_amount).toFixed(2)}}</p>
              </b-list-group-item>
              <b-list-group-item>
                <h6>Quantity:</h6>
                <p>{{updateCoupon.quantity}}</p>
              </b-list-group-item>
              <b-list-group-item>
                <h6>Use Per Customer:</h6>
                <p>{{updateCoupon.use_per_customer}}</p>
              </b-list-group-item>
              <b-list-group-item>
                <b-row>
                  <b-col>
                    <h6>Start Date:</h6>
                    <p>{{updateCoupon.start_date | luxon}}</p>
                  </b-col>
                  <b-col>
                    <h6>End Date:</h6>
                    <p>{{updateCoupon.end_date | luxon}}</p>
                  </b-col>
                </b-row>
              </b-list-group-item>
              <b-list-group-item>
                <h6 v-b-toggle.restrictions-collapse class="d-flex" >
                  <span class="my-auto mr-auto">Restrictions</span>
                  <a class="my-auto btn btn-primary" href="javascript:;"><i class="mdi mdi-plus"></i></a>
                </h6>

                <b-collapse id="restrictions-collapse" class="mt-2">
                  <b-tabs pills>
                  <b-tab title="Products">
                  <b-form-group class="mt-3 mb-2" label="Allow only for selected products">
                    <b-list-group>
                      <b-list-group-item>

                      </b-list-group-item>
                    </b-list-group>
                  </b-form-group>
                </b-tab>
                <b-tab title="Categories">
                  <b-form-group class="mt-3 mb-2" label="Allow only for selected categories">
                    <SelectCategoriesTabButtons :user="$attrs.user" :company_categories="addCoupon['categories']['company_categories']" :trade_marketplace_categories="addCoupon['categories']['trade_marketplace_categories']" :public_marketplace_categories="addCoupon['categories']['public_marketplace_categories']" :active="updateCoupon.active" :public="updateCoupon.public" :trade="updateCoupon.trade"></SelectCategoriesTabButtons>
                  </b-form-group>
                </b-tab>
                <b-tab title="Collections">
                  <b-form-group class="mt-3 mb-2" label="Allow only for selected collections">
                    <SelectCollectionButtons class="mt-3 mb-4" :resource="'collection'" :resource_id="''" :api_token="$attrs.user.api_token" :resource_route="''" ></SelectCollectionButtons>
                  </b-form-group>
                </b-tab>
                <b-tab title="Customers">
                  <b-form-group class="mt-3 mb-2" label="Allow only for selected customers">
                    <CompanyCustomerList :resource="'customer'" :resource_id="''" :api_token="$attrs.user.api_token" :resource_route="''" :customer_list="addCoupon.customers" :view="'list'" @remove-resource="removeCustomerFromRestriction"></CompanyCustomerList>
                  </b-form-group>
                </b-tab>
                <b-tab title="Emails">
                  <b-list-group>
                    <b-list-group-item v-for="email in updateCoupon.emails">
                      {{email}}
                    </b-list-group-item>
                  </b-list-group>
                </b-tab>
                </b-tabs>
                </b-collapse>
              </b-list-group-item>
            </b-list-group>
            </b-card>
          </b-col>
          <b-col md="3">
            <b-button @click="viewType = 'code'"  variant="outline-light" class="border-radius-1em">
                <i class="bx bx-code text-primary font-size-42 mt-2"></i>
                <h5 class="card-title text-muted">Coupon Code</h5>
                <p class="text-muted">Provide a Code to Enter on Checkout</p>
            </b-button>
          </b-col>
          <b-col md="3">
            <b-button @click="viewType = 'url'"  variant="outline-light" class="border-radius-1em">
                <i class="bx bx-link text-primary font-size-42 mt-2"></i>
                <h5 class="card-title text-muted">URL</h5>
                <p class="text-muted">Provide a Code to Enter on Checkout</p>
            </b-button>
          </b-col>
          <b-col md="3">
            <b-button @click="viewType = 'qr'"  variant="outline-light" class="border-radius-1em">
                <i class="bx bx-barcode text-primary font-size-42 mt-2"></i>
                <h5 class="card-title text-muted">QR Code</h5>
                <p class="text-muted">Provide a Code to Enter on Checkout</p>
            </b-button>
          </b-col>
          <b-col md="3">
            <b-button @click="viewType = 'card'"  variant="outline-light" class="border-radius-1em">
                <i class="bx bx-credit-card text-primary font-size-42 mt-2"></i>
                <h5 class="card-title text-muted">Discount Card</h5>
                <p class="text-muted">Provide a Code to Enter on Checkout</p>
            </b-button>
          </b-col>
          <b-col md="12" v-if="viewType == 'code'">
            <b-card class="border-radius-1em border shadow-none mt-3 py-4">
               <h3 class="text-primary text-center" v-html="updateCoupon.code"></h3>
               <input id="basic-code" type="hidden" :value="updateCoupon.code"></input>
               <a class="btn btn-light float-right text-primary font-size-20" v-b-tooltip.hover title="Copy Link" @click="copyLink('basic-code')">
                      <i class="bx bx-copy-alt"></i>
                    </a>
            </b-card>
            <p>Use this code in communicaton, adverts, text or email with customer</p>
          </b-col>
          <b-col md="12" v-if="viewType == 'url'">
            <b-tabs pills class="mt-3" content-class="mt-3">
              <b-tab title="My Shops" v-if="updateCoupon.active == true">
                <b-card class="border-radius-1em border shadow-none">
                    <h3 class="text-primary text-center mt-4" v-html="'https://gemesys.co.uk/trade/products?apply_coupon=true&code='+updateCoupon.code"></h3>
                    <input id="shop-url" type="hidden" :value="'https://gemesys.co.uk/trade/products?apply_coupon=true&code='+updateCoupon.code"></input>
                    <a class="btn btn-light float-right text-primary font-size-20" v-b-tooltip.hover title="Copy Link" @click="copyLink('shop-url')">
                      <i class="bx bx-copy-alt"></i>
                    </a>
                </b-card>
                  <p>Use this code in communicaton, adverts, text or email with customer</p>
              </b-tab>
              <b-tab title="Public Market" v-if="updateCoupon.public == true">
                <b-card class="border-radius-1em border shadow-none">
                    <h3 class="text-primary text-center" v-html="'https://gemesys.co.uk/trade/products?apply_coupon=true&code='+updateCoupon.code"></h3>
                    <input id="public-url" type="hidden" :value="'https://gemesys.co.uk/trade/products?apply_coupon=true&code='+updateCoupon.code"></input>
                    <a class="btn btn-light float-right text-primary font-size-20" v-b-tooltip.hover title="Copy Link" @click="copyLink('public-url')">
                      <i class="bx bx-copy-alt"></i>
                    </a>
                </b-card>
                  <p>Use this code in communicaton, adverts, text or email with customer</p>
              </b-tab>
              <b-tab title="Trade Market" v-if="updateCoupon.trade == true">
                <b-card class="border-radius-1em border shadow-none">
                   <h3 class="text-primary text-center" v-html="'https://'+$attrs.company.nickname+'.gemesys.co.uk/trade/products?apply_coupon=true&code='+updateCoupon.code"></h3>
                    <input id="trade-url" type="hidden" :value="'https://gemesys.co.uk/trade/products?apply_coupon=true&code='+updateCoupon.code"></input>
                    <a class="btn btn-light float-right text-primary font-size-20" v-b-tooltip.hover title="Copy Link" @click="copyLink('trade-url')">
                      <i class="bx bx-copy-alt"></i>
                    </a>
                </b-card>
                  <p>Use this code in communicaton, adverts, text or email with customer</p>
              </b-tab>
            </b-tabs>
          </b-col>
          <b-col md="12" v-if="viewType == 'qr'">
             <b-tabs pills class="mt-3" content-class="mt-3">
              <b-tab title="My Shops" v-if="updateCoupon.active == true">
                <b-tabs pills vertical>
                  <b-tab title="Online Code">
                      <b-card class="border-radius-1em border shadow-none">
                         <qrcode-vue :value="publicurl+'/apply-coupon?code=1&type='+qrData.type+'&key='+qrData.id+'+'+qrData.uid+'+'+qrData.code" :size="250" level="L" foreground="#5E3BB0" background="#f7f7f7" margin="30" class="p-3" />
                      </b-card>
                  </b-tab>
                  <b-tab title="ePOS Code">
                      <b-card class="border-radius-1em border shadow-none">
                         <qrcode-vue :value="JSON.stringify(qrData)" :size="250" level="H" foreground="#5E3BB0" background="#f7f7f7" margin="30" class="p-3" />
                      </b-card>
                  </b-tab>
                </b-tabs>
              </b-tab>
              <b-tab title="Public Market" v-if="updateCoupon.public == true">
                <b-card class="border-radius-1em border shadow-none">
                   <b-tabs pills vertical>
                  <b-tab title="Online Code">
                      <b-card class="border-radius-1em border shadow-none">
                         <qrcode-vue :value="'https://gemesys.co.uk/apply-coupon?code=1&type='+qrData.type+'&key='+qrData.id+'+'+qrData.uid+'+'+qrData.code" :size="250" level="L" foreground="#5E3BB0" background="#f7f7f7" margin="30" class="p-3" />
                      </b-card>
                  </b-tab>
                  <b-tab title="ePOS Code">
                      <b-card class="border-radius-1em border shadow-none">
                         <qrcode-vue :value="JSON.stringify(qrData)" :size="250" level="H" foreground="#5E3BB0" background="#f7f7f7" margin="30" class="p-3" />
                      </b-card>
                  </b-tab>
                </b-tabs>
  
                </b-card>
              </b-tab>
              <b-tab title="Trade Market" v-if="updateCoupon.trade == true">
                <b-card class="border-radius-1em border shadow-none">
                  <b-tabs pills vertical>
                  <b-tab title="Online Code">
                      <b-card class="border-radius-1em border shadow-none">
                         <qrcode-vue :value="'https://app.gemesys.co.uk/trade/profile/'+$attrs.company.nickname+'#apply-coupon?code=1&type='+qrData.type+'&key='+qrData.id+'+'+qrData.uid+'+'+qrData.code" :size="250" level="L" foreground="#5E3BB0" background="#f7f7f7" margin="30" class="p-3" />
                      </b-card>
                  </b-tab>
                  <b-tab title="ePOS Code">
                      <b-card class="border-radius-1em border shadow-none">
                         <qrcode-vue :value="JSON.stringify(qrData)" :size="250" level="H" foreground="#5E3BB0" background="#f7f7f7" margin="30" class="p-3" />
                      </b-card>
                  </b-tab>
                </b-tabs>
              
                </b-card>
              </b-tab>
            </b-tabs> 
          </b-col>
          <b-col md="12" v-if="viewType == 'card'">
            <b-card class="border-radius-1em border shadow-none p-4 mt-3" body-class="d-flex flex-column">
              <a class="btn btn-primary btn-rounded mb-2 mx-auto">
                Connect to NFC Card Writer
              </a>
              <a class="btn btn-primary btn-rounded mb-2 mx-auto">
                Order Cards
              </a>
              <a class="btn btn-primary btn-rounded mx-auto">
                Order Cards Pre Programmed
              </a>
            </b-card>
          </b-col>
        </b-row>
      </b-card>
    </b-modal>
    <SideSlidePanel :editPanel="addPanel" :panelClass="'edit-sidepanel'" @close-panel="addPanel = false">
      <b-card class="shadow-none border-none">
      <div class="row">
        <b-col md="12">
          <h4 class="mt-auto mb-4 mr-auto">Create New Discount Coupon</h4>
            <hr>
        </b-col>
        <b-col md="12">
          <form>
            <b-form-group class="mb-2" label="Name">
              <b-form-input v-model="addCoupon.name" type="text" name="name"></b-form-input>
            </b-form-group>
            <b-form-group class="mb-2" label="Description">
              <b-textarea type="text" name="description"></b-textarea>
            </b-form-group>
            <b-form-group class="mb-2" label="Code">
              <b-form-input v-model="addCoupon.code" type="text" name="code"></b-form-input>
            </b-form-group>
            <b-form-group class="mb-2" label="Discount Type" >
              <b-form-radio-group v-model="addCoupon.discount_type" button-variant="outline-primary" buttons :options="[{value: 1, text: 'Percentage'}, {value: 2, text: 'Fixed Amount'}]"></b-form-radio-group>
            </b-form-group>
            <b-form-group class="mb-2" label="Discount Amount">
              <b-form-input v-model="addCoupon.discount_amount" type="number" name="code"></b-form-input>
            </b-form-group>
            <b-form-group class="mb-2" label="Coupons Quantity">
              <b-form-input v-model="addCoupon.quantity" type="number" name="quantity"></b-form-input>
            </b-form-group>
            <b-form-group class="mb-2" label="Use Per Customer">
              <b-form-input v-model="addCoupon.use_per_customer" type="number" name="user_per_customer"></b-form-input>
            </b-form-group>
            <b-row>
              <b-col>
                <b-form-group class="mb-2" label="Starts at">
                  <b-form-datepicker v-model="addCoupon.start_date" type="number" name="user_per_customer"></b-form-datepicker>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group class="mb-2" label="Ends">
                  <b-form-datepicker v-model="addCoupon.end_date" type="number" name="user_per_customer"></b-form-datepicker>
                </b-form-group>
              </b-col>
            </b-row>
          </form>
        </b-col>
        <b-col md="12">
          <h4 class="mt-4 mb-4 mr-auto">Restrictions</h4>
            <hr>
            <div class="d-flex mb-3">
            <b-button
                      class="flex-grow-1 my-0 py-3 mr-1 w-sm"
                      value="0"
                      :class="{'active' : addCoupon.active == (true || 1 || '1')}"
                      variant="outline-light"
                      @click="addCoupon.active = !addCoupon.active"
                    ><i v-if="addCoupon.active == (true || 1 || '1')" class="bx bx-check-circle font-size-24 d-block text-success my-2"></i> 
                    <i v-else class="bx bx-basket font-size-24 d-block text-primary my-2"></i> My Shops
              </b-button>
              <b-button
                      class=" flex-grow-1 my-0 py-3 mx-1 w-sm"
                      value="0"
                      :class="{'active' : addCoupon.public == (true || 1 || '1')}"
                      variant="outline-light"
                      @click="addCoupon.public = !addCoupon.public"
                    ><i v-if="addCoupon.public == (true || 1 ||  '1')" class="bx bx-check-circle font-size-24 d-block text-success my-2"></i> 
                    <i v-else class="bx bx-basket font-size-24 d-block text-primary my-2"></i> Public Market
              </b-button>
              <b-button
                      class=" flex-grow-1 my-0 py-3 ml-1 w-sm"
                      value="0"
                      :class="{'active' : addCoupon.trade == (true || 1 || '1')}"
                      variant="outline-light"
                      @click="addCoupon.trade = !addCoupon.trade"
                    ><i v-if="addCoupon.trade == (true || 1 || '1')" class="bx bx-check-circle font-size-24 d-block text-success my-2"></i> 
                    <i v-else class="bx bx-basket font-size-24 d-block text-primary my-2"></i> Trade Market
              </b-button>
            </div>
          <b-tabs pills>
          <b-tab title="Products">
          <b-form-group class="mt-3 mb-2" label="Allow only for selected products">
            <CompanyProductSearch class="mt-3 mb-4" :resource="'category'" :resource_id="''" :api_token="$attrs.user.api_token" :company="$attrs.company" :resource_route="''" @product-added="addProductOptions" ></CompanyProductSearch>
          </b-form-group>
        </b-tab>
        <b-tab title="Categories">
          <b-form-group class="mt-3 mb-2" label="Allow only for selected categories">
            <SelectCategoriesTabButtons :user="$attrs.user" :company_categories="addCoupon['categories']['company_categories']" :trade_marketplace_categories="addCoupon['categories']['trade_marketplace_categories']" :public_marketplace_categories="addCoupon['categories']['public_marketplace_categories']" :active="addCoupon.active" :public="addCoupon.public" :trade="addCoupon.trade"></SelectCategoriesTabButtons>
          </b-form-group>
        </b-tab>
        <b-tab title="Collections">
          <b-form-group class="mt-3 mb-2" label="Allow only for selected collections">
            <SelectCollectionButtons class="mt-3 mb-4" :resource="'collection'" :resource_id="''" :api_token="$attrs.user.api_token" :resource_route="''" ></SelectCollectionButtons>
          </b-form-group>
        </b-tab>
        <b-tab title="Customers">
          <b-form-group class="mt-3 mb-2" label="Allow only for selected customers">
            <CompanyCustomerSearch class="mt-3 mb-4" :resource="'customer'" :resource_id="''" :api_token="$attrs.user.api_token" :resource_route="''" @search-resource-selected="addCustomerToRestriction" ></CompanyCustomerSearch>
            <CompanyCustomerList :resource="'customer'" :resource_id="''" :api_token="$attrs.user.api_token" :resource_route="''" :customer_list="addCoupon.customers" :view="'list'" @remove-resource="removeCustomerFromRestriction"></CompanyCustomerList>
          </b-form-group>
        </b-tab>
        <b-tab title="Emails">
          <b-form-group class="mt-3 mb-2" label="Allow only for selected emails" description="You can copy and paste a comma separated list of email addresses here">
            <b-form-tags separator=", " v-model="addCoupon.emails"></b-form-tags>
          </b-form-group>
        </b-tab>
        
      </b-tabs>
        </b-col>
        <b-col md="12">
            <form>

            </form>
        </b-col>
        <b-col md="12">
          <a class="btn btn-success btn-block" @click="addNewCoupon()">
            Submit
          </a>
        </b-col>
      </div>
    </b-card>
    </SideSlidePanel>
    <!-- end row -->
  </Layout>
</template>
