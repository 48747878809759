
<script type="text/javascript">
import Layout from '../../layouts/main'
import PageHeader from '../../components/page-header'

import SideSlidePanel from "../../components/widgets/SideSlidePanel"

    export default {
      components: { Layout, PageHeader, SideSlidePanel},
        props: {
            companydata: Array,
            companysettings: Array,
            ownerdetails: Array,
        }, 
      data(){
        return{
          title: 'Orders',
            items: [
              {
                text: 'Orders',
                active: true,
              },
            ],
          order: '',
          order_behaviour: 0,
          all_counties: [],
          listView: false, 
          gridView: true,
          active: true,
          current: 0,
          active_address_tab: true,
          current_address_tab: 0,
          address_tabs: ['Delivery Address', 'Billing Address'],
          order_tabs: ['Products', 'Payments', 'Delivery', 'Status History', 'Messages', 'Downloads'],
          active_addons:[],
          all_plans: [],
          current_subscription: [],
          subscription_history: [],
          companyname: '',
          companytypes: [],
          searchbodies: '',
          professional_bodies: [],
          active_bodies: [],
          editorData: '',
          payment_history: [],
          delivery_history: [],
          status_history: [
          		{ isActive: true, age: 40, first_name: 'Dickerson', last_name: 'Macdonald' }
          ],
          editorConfig: {
            // The configuration of the editor.
        }
        }
        
      },
      beforeCreate(){
          axios.get('/seller/order/'+ this.$attrs.id + '/edit').then(response => {
                console.log(response);
                this.order = response.data[0];
            });
      		axios.get('/seller/order/'+ this.$attrs.id + '/payments').then(response => {
                console.log(response);
                this.payment_history = response.data;
            });
            axios.get('/seller/order/'+ this.$attrs.id + '/shipping').then(response => {
                console.log(response);
                this.delivery_history = response.data;
            });
      },
      methods: {
          onCancel(evt){

          },
          handleSubmit () {
                this.$refs.modal.hide()
          },
          onSubmit: function () {
              this.$refs.invisibleRecaptcha.execute()
          },
          onVerify: function (response) {
             console.log('Verify: ' + response);
             this.claim_listing_id = this.selected_modal.id;
             this.claim_step++;
          },
          onExpired: function () {
               console.log('Expired')
          },
          resetRecaptcha () {
            this.$refs.recaptcha.reset() // Direct call reset method
          },
          flipCard: function (index) {
            this.flip180 = true;
            this.filpIndex = index;
          },
          flipBack: function(index){
            this.flip180 = false;
            this.filpIndex = '';
          },
          submitSignUp: function(){
            axios.post('/register', {name: this.name, email: this.email, password: this.password},
                ).then(response => {
                 console.log(response);
            }).catch(function (error) {
                  console.log(error);         
                });
          },
          getLogo(avatar){
            return "/storage/"+avatar;
          },
          switchToList: function(){
            this.listView = true;
            this.gridView = false;
          },
          switchToGrid: function(){
            this.listView = false;
            this.gridView = true;
          },
          currentlyShowing: function(index){
            //this.current = index;
          },
          toggleActiveAddressTab: function(index){
            this.active_address_tab = true;
            this.current_address_tab = index;
          },
          toggleActive: function(index){
            this.active = true;
            this.current = index;
          },
          timeConvert(date){
            moment.locale('en-gb');  
            return moment(date).format('LLLL');
          },
          timeExpires(date){
            moment.locale('en-gb');  
            return moment(date).add(1, 'month').format('LLLL');
          }
      },
    }

</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
	<div class="container-fluid">

		<div class="col-md-12 form-container">
			<div class="row bg-white">
				<div class="card col-md-12 no-margin">
					<div class="row">
						<div class="col-md-12 margin-auto pb-4">
							<div class="row">
								
								<div class="col-md-12 no-padding">
								<div class="card-body">
									<div class="row">
										<div class="col-md-3">
											<div class="card status-card">
												<div class="card-body">
													<h3 class="listing-count">{{order.reference}}</h3>
													<span>Reference</span>
												</div>
											</div>
										</div>
										<div class="col-md-3">
											<div class="card status-card">
												<div class="card-body">
													<h3 class="listing-count">{{order.created_at | luxon }}</h3>
													<span>Date Created</span>
												</div>
											</div>
										</div>
										<div class="col-md-3">
											<div class="card status-card text-right" :style="'background:' + order.status.color">
												<div class="card-body" :style="'background:' + order.status.color">
													<h3 class="listing-count">{{order.status.name}}</h3>
													<span>Order Status</span>
												</div>
											</div>
										</div>
										<div class="col-md-3">
											<div class="card status-card text-right">
												<div class="card-body">
													<h3 class="listing-count">£{{order.total_order_inc}}</h3>
													<span>Order Total</span>
												</div>
											</div>
										</div>
									</div>
									<div class="row">
										<div class="col-md-12 my-3">
										<b-badge href="#" class="order-badges" variant="success"><b-badge variant="light"></b-badge> Order Paid</b-badge>
										<b-badge href="#" class="order-badges" variant="primary"><b-badge variant="light"></b-badge> Invoice Created</b-badge>
										<b-badge href="#" class="order-badges text-white" variant="info">
										<b-badge variant="light"></b-badge> Email Sent</b-badge>
										<b-badge href="#" class="order-badges" variant="primary"><b-badge variant="light"><font-awesome-icon icon="truck" color="#5E3BB0" /></b-badge> Delivery Note Created</b-badge>

										</div>
									</div>
									<div class="row">
										
										<div class="col-md-8">
									<div class="card mx-0 mb-0">
               <div class="p-4 d-flex card-body">
                <div class="w-100 my-auto d-flex">
            <img width="50" v-if="order.customer.avatar != ''" v-bind:alt="order.customer.name" class="card-img-profile logo-img img-thumbnail rounded-circle float-left mr-3" v-bind:src="getLogo(order.customer.avatar)">
              <a v-else class="user-circle my-auto mr-3 d-flex float-left" href="javascript:;">
                  <div class="user-circle-text m-auto">
                  {{(order.customer.firstname).slice(0,1)}}{{(order.customer.lastname).slice(0,1)}}
                  </div>
              </a>
              <div class="my-auto">
                <label><strong class="mb-0 text-capitalize">{{ order.customer.firstname }} {{ order.customer.lastname }}</strong></label>
                <p class="mb-0"><small>Company: {{order.company.name}}</small></p>
                <p class="mb-0"><small>Customer No: {{order.company.name}}</small></p>
              </div>
            </div>
           	<a href="javascript:;" class="btn close-btn cart-remove"><font-awesome-icon icon="times"  color="#fff"/></a>
          </div>
        </div>
		<nav class="nav nav-pills nav-fill">
			<a v-for="(tab, index) in address_tabs" class="nav-item nav-link" href="javascript:;" :class="{active: active_address_tab === true && current_address_tab === index}" v-on:click="toggleActiveAddressTab(index)" v-on:mouseover="currentlyShowing(index)">{{tab}}</a>
		</nav>
		<div class="address-cards">
		<transition name="slide-fadeleft">
		<div class="card mx-0 mb-0" v-if="current_address_tab == 0">
            <div class="p-4 card-body">
              		<span class="d-block" v-if="order.delivery_address.address_1">{{order.delivery_address.address_1}}</span>
              		<span class="d-block" v-if="order.delivery_address.address_2">{{order.delivery_address.address_2}}</span>
              		<span class="d-block" v-if="order.delivery_address.address_3">{{order.delivery_address.address_3}}</span>
              		<span class="d-block" v-if="order.delivery_address.city">{{order.delivery_address.city}}</span>
              		<span class="d-block" v-if="order.delivery_address.county.name">{{order.delivery_address.county.name}}</span>
              		<span class="d-block" v-if="order.delivery_address.postcode">{{order.delivery_address.postcode}}</span>
              		<span class="d-block" v-if="order.delivery_address.county.country.name">{{order.delivery_address.county.country.name}}</span>
           			<a href="javascript:;" class="btn btn-purple float-right text-white"><font-awesome-icon icon="refresh"  color="#fff"/> Change Address</a>
          	</div>
        </div>
    	</transition>
        <transition name="slide-faderight">
        <div class="card mx-0 mb-0" v-if="current_address_tab == 1">
               <div class="p-4 card-body">
              		<span class="d-block" v-if="order.invoice_address.address_1">{{order.invoice_address.address_1}}</span>
              		<span class="d-block" v-if="order.invoice_address.address_2">{{order.invoice_address.address_2}}</span>
              		<span class="d-block" v-if="order.invoice_address.address_3">{{order.invoice_address.address_3}}</span>
              		<span class="d-block" v-if="order.invoice_address.city">{{order.invoice_address.city}}</span>
              		<span class="d-block" v-if="order.invoice_address.county.name">{{order.invoice_address.county.name}}</span>
              		<span class="d-block" v-if="order.invoice_address.postcode">{{order.invoice_address.postcode}}</span>
              		<span class="d-block" v-if="order.invoice_address.county.country.name">{{order.delivery_address.county.country.name}}</span>
           			<a href="javascript:;" class="btn btn-purple float-right text-white"><font-awesome-icon icon="refresh"  color="#fff"/> Change Address</a>
          	</div>
        </div>
    	</transition>
    </div>
								</div>
								<div class="col-md-4">
										<div class="card">
											<a href="javascript:;" class="text-dark"><b-card no-body class="download-card mt-3 mx-4"><b-card-body class="d-flex flex-row"><font-awesome-icon icon="file-invoice" size="2x" class="mr-2 primary" color="#5E3BB0" /> <p class="my-auto">Invoice</p>
											<font-awesome-icon class="float-right my-auto ml-auto" icon="redo" color="#657786" />
											<font-awesome-icon class="float-right my-auto ml-4" icon="print" color="#657786" />
											<font-awesome-icon class="float-right my-auto ml-4" icon="download" color="#657786" /></b-card-body></b-card></a>
											<a href="javascript:;" class="text-dark"><b-card no-body class="download-card mt-3 mx-4"><b-card-body class="d-flex flex-row"><font-awesome-icon icon="sticky-note" size="2x" class="mr-2" color="#ffc107" /> <p class="my-auto">Delivery Note</p> <font-awesome-icon class="float-right my-auto ml-auto" icon="redo" color="#657786" />
											<font-awesome-icon class="float-right my-auto ml-4" icon="print" color="#657786" />
											<font-awesome-icon class="float-right my-auto ml-4" icon="download" color="#657786" /></b-card-body></b-card></a>
											<a href="javascript:;" class="text-dark"><b-card no-body class="download-card mt-3 mx-4"><b-card-body class="d-flex flex-row"><font-awesome-icon icon="truck" size="2x" class="mr-2" color="#6cb2eb" /> <p class="my-auto">Shipping Label</p> <font-awesome-icon class="float-right my-auto ml-auto" icon="redo" color="#657786" />
											<font-awesome-icon class="float-right my-auto ml-4" icon="print" color="#657786" />
											<font-awesome-icon class="float-right my-auto ml-4" icon="download" color="#657786" /></b-card-body></b-card></a>
											<a href="javascript:;" class="text-dark"><b-card no-body class="download-card mt-3 mx-4 mb-3"><b-card-body class="d-flex flex-row"><font-awesome-icon icon="eye-slash" size="2x" class="mr-2" /> <p class="my-auto">Hide Status from Customer</p> <font-awesome-icon class="float-right my-auto ml-auto" icon="redo" color="#657786" />
											<font-awesome-icon class="float-right my-auto ml-4" icon="print" color="#657786" />
											<font-awesome-icon class="float-right my-auto ml-4" icon="download" color="#657786" /></b-card-body></b-card></a>
										</div>
										</div>
		</div>
		<nav class="nav nav-pills nav-fill mt-4">
			<a v-for="(tab, index) in order_tabs" class="nav-item nav-link" href="javascript:;" :class="{active: active === true && current === index}" v-on:click="toggleActive(index)" v-on:mouseover="currentlyShowing(index)">{{tab}}</a>
		</nav>
		<div class="row">
								<div class="col-md-12" v-if="current === 0">
						          <div class="p-4 d-flex">
						            <div class="text-muted mb-0 list-group w-100">
						            <div class="list-group-item" v-for="(order_row, index) in order.order_rows" v-if="index < 3">
						            <div class="row">
						              <div class="col-md-1">
						              <img width="100%"  v-bind:alt="order_row.product_details.name" class="logo-img" v-bind:src="getLogo(order_row.product_details.cover_image)"><img width="100" v-if="order_row.product_details.cover_image.length === 0" v-bind:alt="order_row.product_details.name" v-bind:src="getDefaultLogo()" class="card-img-profile logo-img">
						            </div>
						            <div class="col-md-6 my-auto">             
						            <label><strong class="mb-0"><a href="javascript:;">{{ order_row.product_details.reference }}: {{ order_row.product_details.name }}</a></strong></label>
						            <small v-html="order_row.product_details.short_description"></small>
						            
						          </div>
						          <div class="col-md-2 my-auto text-center">             
						            <strong>{{ order_row.quantity }}</strong>
						            
						          </div>
						          <div class="col-md-3 my-auto d-flex pl-0 pr-4">
						           <div class="listingcount-div w-50 text-center my-auto pr-2"> <strong class="ml-auto pricing-counts"><small>£{{ order_row.product_details.sales_price }}</small></strong>
						            <h5 class="counts-title">Exc. VAT</h5></div>
						            <div class="listingcount-div w-50 text-center my-auto pr-2">
						            <strong class="ml-auto pricing-counts"><small>£{{ order_row.product_details.sales_price }}</small></strong>
						            <h5 class="counts-title">Inc. VAT</h5></div>
						          </div>
						          <a href="javascript:;" class="btn close-btn cart-remove"><font-awesome-icon icon="times"  color="#fff"/></a>
						            </div>
						            </div>
						             <div class="list-group-item d-flex flex-column" v-if="order.order_created != 1">
						              <transition name="slide-fadeabove">
						                <search-product class="cart-product-search" v-if="add_cart_product == 1"></search-product>
						              </transition>
						              <a class="btn btn-purple float-right" href="javascript:;" @click="add_cart_product = !add_cart_product">Add Product <font-awesome-icon icon="plus" color="#fff"/></a>
						             </div>
						          </div>
						        </div>
						        <div class="card mx-4 mb-4">
						               <div class="p-4 d-flex">
						                <div class="w-75 my-auto d-flex">
						              <div class="my-auto">
						                <h3 class="mb-0 text-capitalize">Products Total</h3>
						              </div>
						            </div>
						            <div class="w-25 my-auto d-flex">
						               <div class="listingcount-div w-50 text-center my-auto"><span class="pricing-counts">£{{order.total_exc_vat}}</span><h5 class="counts-title">Exc. VAT</h5></div>
						                <div class="listingcount-div w-50 text-center my-auto"><span class="pricing-counts">£{{order.total_inc_vat}}</span><h5 class="counts-title">Inc. VAT</h5></div>
						            </div>
						          </div>
						        </div>
								</div>
								<div class="col-md-12" v-if="current === 1">
						          <div class="p-4 d-flex">
						            <div class="text-muted mb-0 list-group w-100">
						            <div class="list-group-item" v-if="payment_history.length == 0">
						            	<div class="row">
						            		<div class="col-md-12 d-flex">
						            			<p class="my-auto py-4 w-100 bg-light text-center"> No Payments Found</p>
						            		</div>
						            	</div>
						            </div>
						            <div class="list-group-item" v-if="payment_history.length > 0" v-for="(history, index) in payment_history">
						            <div class="row">
							            <div class="col-md-2 text-center my-auto">            
							               {{history.payment_option.name}}
							          	</div>
						              	<div class="col-md-2 text-center">
						              	   <font-awesome-icon icon="hashtag" color="#657786" /> {{history.transaction_reference}}
						            	</div>
						            	<div class="col-md-2 text-center my-auto">            
						              		<font-awesome-icon icon="user" color="#657786" /> {{history.user.name}}
						          		</div>
						          		 <div class="col-md-3 text-center my-auto">
						           			<font-awesome-icon icon="clock" color="#657786" /> {{ history.created_at | luxon }}
						          		 </div>
								          <div class="col-md-2 my-auto d-flex pl-0 pr-4">
								           <div class="listingcount-div w-100 text-center my-auto pr-2">
								           	<strong class="ml-auto pricing-counts"><small>£{{history.amount}}</small></strong>
								           </div>
								          </div>
								          <div class="col-md-1 my-auto d-flex">
						           			 <a href="javascript" class="my-auto mx-auto">
						           			 	<font-awesome-icon icon="pencil-alt" color="#657786" />
						           			 </a>
						           			 <a href="javascript" class="my-auto mx-auto">
						           			  	<font-awesome-icon icon="times" color="#657786" />
						           			 </a>
						          		 </div>
						         
						            </div>
						            </div>
						             <div class="list-group-item d-flex flex-column" v-if="order.order_created != 1">
						              <transition name="slide-fadeabove">
						                <search-product class="cart-product-search" v-if="add_cart_product == 1"></search-product>
						              </transition>
						              <a class="btn btn-purple float-right" href="javascript:;" @click="add_cart_product = !add_cart_product">Add Payment <font-awesome-icon icon="plus" color="#fff"/></a>
						             </div>
						          </div>
						        </div>
						        <div class="card mx-4 mb-4">
						               <div class="p-4 d-flex">
						                <div class="w-75 my-auto d-flex">
						              <div class="my-auto">
						                <h3 class="mb-0 text-capitalize">Payments Total</h3>
						              </div>
						            </div>
						            <div class="w-25 my-auto d-flex">
						               <div class="listingcount-div w-50 text-center my-auto"><span class="pricing-counts">£{{order.total_exc_vat}}</span><h5 class="counts-title">Exc. VAT</h5></div>
						                <div class="listingcount-div w-50 text-center my-auto"><span class="pricing-counts">£{{order.total_inc_vat}}</span><h5 class="counts-title">Inc. VAT</h5></div>
						            </div>
						          </div>
						        </div>
							</div>
									
							<div class="col-md-12" v-if="current === 2">
						          <div class="p-4 d-flex">
						            <div class="text-muted mb-0 list-group w-100">
						            <div class="list-group-item" v-if="payment_history.length == 0">
						            	<div class="row">
						            		<div class="col-md-12 d-flex">
						            			<p class="my-auto py-4 w-100 bg-light text-center"> <font-awesome-icon icon="info-circle" color="#657786" /> No Payments Found</p>
						            		</div>
						            	</div>
						            </div>
						            <div class="list-group-item" v-if="delivery_history.length > 0" v-for="(history, index) in delivery_history">
						            <div class="row">
							            <div class="col-md-2 text-center my-auto">            
							               {{history.shipping_option.name}}
							          	</div>
						              	<div class="col-md-2 text-center">
						              	   <font-awesome-icon icon="hashtag" color="#657786" /> {{history.delivery_reference}}
						            	</div>
						            	<div class="col-md-2 text-center my-auto">            
						              		<font-awesome-icon icon="user" color="#657786" /> {{history.shipping_option.free_shipping}}
						          		</div>
						          		 <div class="col-md-3 text-center my-auto">
						           			<font-awesome-icon icon="clock" color="#657786" /> {{ history.created_at | luxon }}
						          		 </div>
								          <div class="col-md-2 my-auto d-flex pl-0 pr-4">
								           <div class="listingcount-div w-100 text-center my-auto pr-2">
								           	<strong class="ml-auto pricing-counts"><small>£{{history.amount}}</small></strong>
								           </div>
								          </div>
								          <div class="col-md-1 my-auto d-flex">
						           			 <a href="javascript" class="my-auto mx-auto">
						           			 	<font-awesome-icon icon="pencil-alt" color="#657786" />
						           			 </a>
						           			 <a href="javascript" class="my-auto mx-auto">
						           			  	<font-awesome-icon icon="times" color="#657786" />
						           			 </a>
						          		 </div>
						         
						            </div>
						            </div>
						             <div class="list-group-item d-flex flex-column" v-if="order.order_created != 1">
						              <transition name="slide-fadeabove">
						                <search-product class="cart-product-search" v-if="add_cart_product == 1"></search-product>
						              </transition>
						              <a class="btn btn-purple float-right" href="javascript:;" @click="add_cart_product = !add_cart_product">Add Payment <font-awesome-icon icon="plus" color="#fff"/></a>
						             </div>
						          </div>
						        </div>
						        <div class="card mx-4 mb-4">
						               <div class="p-4 d-flex">
						                <div class="w-75 my-auto d-flex">
						              <div class="my-auto">
						                <h3 class="mb-0 text-capitalize">Payments Total</h3>
						              </div>
						            </div>
						            <div class="w-25 my-auto d-flex">
						               <div class="listingcount-div w-50 text-center my-auto"><span class="pricing-counts">£{{order.total_exc_vat}}</span><h5 class="counts-title">Exc. VAT</h5></div>
						                <div class="listingcount-div w-50 text-center my-auto"><span class="pricing-counts">£{{order.total_inc_vat}}</span><h5 class="counts-title">Inc. VAT</h5></div>
						            </div>
						          </div>
						        </div>
							</div>	
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
</div>
</div>
</Layout>
</template>
