<script>
/**
 * Stat component -- specify the widget icon, title and value.
 */
export default {
  props: {
    title: {
      type: String,
      default: ""
    },
    value: {
      type: String,
      default: ""
    },
    icon: {
      type: String,
      default: ""
    },
    image: {
      type: String,
      default: "",
    },
    link: {
      type: String,
      default: "",
    },
    bgcolor: {
      type: String,
      default: "",
    }

  }
};
</script>

<template>
  <div class="card mini-stats-wid border-radius-1em">
    <div class="card-body">
      <div class="media">
        <div class="media-body">
          <p class="text-muted font-weight-medium mb-3" v-html="title"></p>
          <h5 class="mb-0" v-html="value"></h5>
        </div>

        <div v-if="icon !== ''" class="mini-stat-icon avatar-sm align-self-center rounded-circle" :class="{' bg-primary': bgcolor == ''}">
          <span class="avatar-title"  :style="{'background-color' : bgcolor }">
            <i :class="`${icon} font-size-24`"></i>
          </span>
        </div>
        <div v-else-if="image !== ''">
        <a v-if="link !== ''" :href="link">
        <img :src="`${image}`" height="55" width="55" class="mini-stat-icon avatar-sm align-self-center rounded-circle" />
        </a>
        <img v-else :src="`${image}`" height="55" width="55" class="mini-stat-icon avatar-sm align-self-center rounded-circle" />
        </div>
      </div>
    </div>
    <!-- end card-body -->
  </div>
  <!-- end card -->
</template>