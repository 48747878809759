
<script>
import { EventBus } from '../app'
import i18n from "../i18n";
import simplebar from "simplebar-vue";
import ModalWindow from "../components/widgets/ModalWindow";
import TradeCartSummary from "../components/widgets/ecommerce/TradeCartSummary";

/**
 * Nav-bar Component
 */
export default {
  data() {
    return {
      languages: [
        {
          flag: "/images/flags/us.jpg",
          language: "en",
          title: "English",
        },
        {
          flag: "/images/flags/french.jpg",
          language: "fr",
          title: "French",
        },
        {
          flag: "/images/flags/spain.jpg",
          language: "es",
          title: "Spanish",
        },
        {
          flag: "/images/flags/chaina.png",
          language: "zh",
          title: "Chinese",
        },
        {
          flag: "/images/flags/arabic.png",
          language: "ar",
          title: "Arabic",
        },
      ],
      cart: {
        products: [],
        uid: '',
        id: '',
      },
      cartLoading: false,
      initiateCheckout: false,
      cartPanelData: [],
      cartPanel: false,
      showCart: false, 
      lan: i18n.locale,
      text: null,
      flag: null,
      value: null,
      expanded: false,
    };
  },
  props: {
    user: {
      type: Object,
      default: () => {api_token: ''}
    },
    company: {
      type: Object,
      default: () => {}
    }
  },
  components: { simplebar, ModalWindow, TradeCartSummary },
  created() {
    var session_cart = window.sessionStorage.getItem("tradecart");
    session_cart = JSON.parse(session_cart);
    if(session_cart == null){
      this.checkAndGetCart();
    }else{
      this.cart = session_cart;
    }
  },
  mounted() {
    this.value = this.languages.find((x) => x.language === i18n.locale);
    this.text = this.value.title;
    this.flag = this.value.flag;
    //window.sessionStorage.setItem("test", JSON.stringify(this.cart));
    
    let self = this;
    EventBus.$on('add-to-cart', function(product, qty){
      console.log('Cart Event Triggered');
       self.addToCart(product, qty);
    });
    EventBus.$on('toggle-navbar', function(){
          self.expanded = !self.expanded;
        });
  },
  computed: {
    /*cartTotal: function(){
          var score = 0;
          if(this.cart.products.length > 1){
            for(var i = 0; i < this.cart.products.length; i++){
              var itemscore = (parseFloat(this.cart.products[i].price_exc) * parseFloat(this.cart.products[i].quantity));
              score = score + itemscore;
            }
          }else{
            score = this.cart.products[0].price_exc * this.cart.products[0].quantity;
          }
          return (score).toFixed(2);
        },*/
    },
  methods: {
    checkAndGetCart(){
      axios.get('/api/company/trade/cart/read?cart_uid='+this.cart.uid, {headers: {
                  'Authorization': 'Bearer ' + this.user.api_token
              }}).then(response => {
        console.log(response);
          if(response.data !== 0){
            this.cart = response.data;
            window.sessionStorage.setItem("tradecart", JSON.stringify(response.data));
          }else{
            window.sessionStorage.setItem("tradecart", JSON.stringify(this.cart))
          }
        
      }).catch(error => {
        this.error_reponse = error.response.data.errors;
      });
    },
    cartTotal(cart){
          var score = 0;
          if(cart.products.length > 1){
            for(var i = 0; i < cart.products.length; i++){
              var itemscore = (parseFloat(cart.products[i].price_exc) * parseFloat(cart.products[i].quantity));
              score = score + itemscore;
            }
          }else{
            score = cart.products[0].price_exc * cart.products[0].quantity;
          }
          return (score).toFixed(2);
        },
    addToCart(productdata, qty, variantsdata, combinationdata){
      this.cartLoading = true;
      if(this.cart.uid == ''){
        this.cart.products.push({product: productdata});
        this.createCart(productdata);
      }else{
        axios.post('/api/company/trade/cart/'+this.cart.uid+'/add-product?api_token='+this.user.api_token, {product: productdata, quantity: qty, variants: variantsdata, combination: combinationdata}, {headers: {
                  'Authorization': 'Bearer ' + this.user.api_token
              }}).then(response => {
        var carindex = this.cart.products.findIndex(item => item.product_id == productdata.id);
            if(carindex !== -1){
              this.cart.products[carindex] = response.data;
              this.cart.products[carindex]["product"] = productdata;
              window.sessionStorage.setItem("tradecart", JSON.stringify(this.cart));
              this.productAdded(productdata);
            }else if(carindex == -1){
              this.cart["products"].push(response.data);
              this.cart["products"][(this.cart.products.length - 1)]["product"] = productdata;
              window.sessionStorage.setItem("tradecart", JSON.stringify(this.cart));
              this.productAdded(productdata);
            }
      }).catch(error => {
        this.error_reponse = error.response.data.errors;
      });
      } 
    },
    removeProduct(id, index){
      axios.post('/api/company/trade/cart/product/'+id+'/delete', {headers: {
                  'Authorization': 'Bearer ' + this.user.api_token
              }}).then(response => {
          this.cart.products.splice(index, 1);
          window.sessionStorage.setItem("tradecart", JSON.stringify(this.cart));
        }).catch(error => {
          this.error_reponse = error.response.data.errors;
        });
    },
    productAdded(product){
      //this.cartPanelData = product;
      //this.cartPanel = true;
      this.cartLoading = false;
      this.$refs.cart.show(true);
    },
    createCart(product){
      axios.post('/api/company/trade/cart/create', product,  {headers: {
                  'Authorization': 'Bearer ' + this.user.api_token
              }}).then(response => {
        this.cart = response.data[0];
        this.cart["products"] = [];
        this.cart.products.push(response.data[1]);
        this.cart.products[0]["product"] = product;
        window.sessionStorage.setItem("tradecart", JSON.stringify(this.cart));
      }).catch(error => {

      });
    },
    toggleMenu() {
      this.$parent.toggleMenu();
    },
    toggleRightSidebar() {
      this.$parent.toggleRightSidebar();
    },
    initFullScreen() {
      document.body.classList.toggle("fullscreen-enable");
      if (
        !document.fullscreenElement &&
        /* alternative standard method */ !document.mozFullScreenElement &&
        !document.webkitFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(
            Element.ALLOW_KEYBOARD_INPUT
          );
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    },
    setLanguage(locale, country, flag) {
      this.lan = locale;
      this.text = country;
      this.flag = flag;
      i18n.locale = locale;
      localStorage.setItem("locale", locale);
    },
  },
};
</script>

<template>
  <header id="page-topbar"  v-if="expanded == true">
    <div class="navbar-header">
      <div class="d-flex">
        <!-- LOGO -->
        <div class="navbar-brand-box">
          <a href="/" class="logo logo-dark">
            <span class="logo-sm">
              <div class="cube-wrapper float-left"><div class="cube-folding"><span class="leaf1"></span><span class="leaf2"></span><span class="leaf3"></span><span class="leaf4"></span></div></div>
            </span>
            <span class="logo-lg">
              <div class="cube-wrapper float-left"><div class="cube-folding"><span class="leaf1"></span><span class="leaf2"></span><span class="leaf3"></span><span class="leaf4"></span></div></div>
              <div class="d-flex logo-text-holder"><span class="logo-text text-dark">Gemesys</span></div>
            </span>
          </a>

          <a href="/" class="logo logo-light">
            <span class="logo-sm">
              <div class="cube-wrapper float-left"><div class="cube-folding"><span class="leaf1"></span><span class="leaf2"></span><span class="leaf3"></span><span class="leaf4"></span></div></div>
            </span>
            <span class="logo-lg">
              <div class="cube-wrapper float-left"><div class="cube-folding"><span class="leaf1"></span><span class="leaf2"></span><span class="leaf3"></span><span class="leaf4"></span></div></div>
              <div class="d-flex logo-text-holder"><span class="logo-text text-white">Gemesys</span></div>
            </span>
          </a>
        </div>

        <button
          id="vertical-menu-btn"
          type="button"
          class="btn btn-sm px-3 font-size-16 header-item"
          @click="toggleMenu"
        >
          <i class="fa fa-fw fa-bars"></i>
        </button>

        <!-- App Search-->
        <form class="app-search d-none d-lg-block">
          <div class="position-relative">
            <input type="text" class="form-control" :placeholder="$t('navbar.search.text')" />
            <span class="bx bx-search-alt"></span>
          </div>
        </form>

      
      </div>

      <div class="d-flex">
        <b-dropdown
          class="d-inline-block d-lg-none ms-2"
          variant="black"
          menu-class="dropdown-menu-lg p-0"
          toggle-class="header-item noti-icon"
          right
        >
          <template v-slot:button-content>
            <i class="mdi mdi-magnify"></i>
          </template>

          <form class="p-3">
            <div class="form-group m-0">
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Search ..."
                  aria-label="Recipient's username"
                />
                <div class="input-group-append">
                  <button class="btn btn-primary" type="submit">
                    <i class="mdi mdi-magnify"></i>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </b-dropdown>



        <b-dropdown
          class="d-none d-lg-inline-block noti-icon"
          menu-class="dropdown-menu-lg calculators-menu dropdown-menu-end"
          right
          toggle-class="header-item"
          variant="black"
        >
          <template v-slot:button-content>
            <i class="bx bx-calculator"></i>
          </template>

          <div class="px-lg-2">
            <div class="row no-gutters">
              <div class="col">
                <a class="dropdown-icon-item" href="javascript: void(0);" @click="$emit('showCalculatorWindow', 0)">
                  <img src="/images/brands/github.png" alt="Github" />
                  <span>Gemstone</span>
                </a>
              </div>
              <div class="col">
                <a class="dropdown-icon-item" href="javascript: void(0);"  @click="$emit('showCalculatorWindow', 1)">
                  <img src="/images/brands/bitbucket.png" alt="bitbucket" />
                  <span>Diamond</span>
                </a>
              </div>
              <div class="col">
                <a class="dropdown-icon-item" href="javascript: void(0);"  @click="$emit('showCalculatorWindow', 2)">
                  <img src="/images/brands/dribbble.png" alt="dribbble" />
                  <span>Cabochon</span>
                </a>
              </div>
            </div>

            <div class="row no-gutters">
              <div class="col">
                <a class="dropdown-icon-item" href="javascript: void(0);"  @click="$emit('showCalculatorWindow', 3)">
                  <img src="/images/brands/dropbox.png" alt="dropbox" />
                  <span>Specific Gravity</span>
                </a>
              </div>
              <div class="col">
                <a class="dropdown-icon-item" href="javascript: void(0);"  @click="$emit('showCalculatorWindow', 4)">
                  <img src="/images/brands/mail_chimp.png" alt="mail_chimp" />
                  <span>Crown Angle</span>
                </a>
              </div>
              <div class="col">
                <a class="dropdown-icon-item" href="javascript: void(0);"  @click="$emit('showCalculatorWindow', 5)">
                  <img src="/images/brands/slack.png" alt="slack" />
                  <span>Pavillion Angle</span>
                </a>
              </div>
            </div>
          </div>
        </b-dropdown>


        <!--<b-dropdown
          class="d-none d-lg-inline-block noti-icon"
          menu-class="dropdown-menu-lg dropdown-menu-end"
          right
          toggle-class="header-item"
          variant="black"
        >
          <template v-slot:button-content>
            <i class="bx bx-customize"></i>
          </template>

          <div class="px-lg-2">
            <div class="row no-gutters">
              <div class="col">
                <a class="dropdown-icon-item" href="javascript: void(0);">
                  <img src="/images/brands/github.png" alt="Github" />
                  <span>{{ $t('navbar.dropdown.site.list.github') }}</span>
                </a>
              </div>
              <div class="col">
                <a class="dropdown-icon-item" href="javascript: void(0);">
                  <img src="/images/brands/bitbucket.png" alt="bitbucket" />
                  <span>{{ $t('navbar.dropdown.site.list.github') }}</span>
                </a>
              </div>
              <div class="col">
                <a class="dropdown-icon-item" href="javascript: void(0);">
                  <img src="/images/brands/dribbble.png" alt="dribbble" />
                  <span>{{ $t('navbar.dropdown.site.list.dribbble') }}</span>
                </a>
              </div>
            </div>

            <div class="row no-gutters">
              <div class="col">
                <a class="dropdown-icon-item" href="javascript: void(0);">
                  <img src="/images/brands/dropbox.png" alt="dropbox" />
                  <span>{{ $t('navbar.dropdown.site.list.dropbox') }}</span>
                </a>
              </div>
              <div class="col">
                <a class="dropdown-icon-item" href="javascript: void(0);">
                  <img src="/images/brands/mail_chimp.png" alt="mail_chimp" />
                  <span>{{ $t('navbar.dropdown.site.list.mailchimp') }}</span>
                </a>
              </div>
              <div class="col">
                <a class="dropdown-icon-item" href="javascript: void(0);">
                  <img src="/images/brands/slack.png" alt="slack" />
                  <span>{{ $t('navbar.dropdown.site.list.slack') }}</span>
                </a>
              </div>
            </div>
          </div>
        </b-dropdown>-->

        <div class="dropdown d-none d-lg-inline-block ms-1">
          <button type="button" class="btn header-item noti-icon" @click="initFullScreen">
            <i class="bx bx-fullscreen"></i>
          </button>
        </div>

       

        <b-dropdown right variant="black" toggle-class="header-item" menu-class="dropdown-menu-end">
          <template v-slot:button-content>
            <img
              class="rounded-circle header-profile-user"
              src="/images/users/avatar-1.jpg"
              alt="Header Avatar"
            />
            <span class="d-none d-xl-inline-block ms-1">{{ $t('navbar.dropdown.henry.text')}}</span>
            <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
          </template>
          <!-- item-->
      
            <b-dropdown-item href="/contacts/profile">
              <i class="bx bx-user font-size-16 align-middle me-1"></i>
              {{ $t('navbar.dropdown.henry.list.profile') }}
            </b-dropdown-item>
        
          <b-dropdown-item href="javascript: void(0);">
            <i class="bx bx-wallet font-size-16 align-middle me-1"></i>
            {{ $t('navbar.dropdown.henry.list.mywallet') }}
          </b-dropdown-item>
          <b-dropdown-item class="d-block" href="javascript: void(0);">
            <span class="badge bg-success float-end">11</span>
            <i class="bx bx-wrench font-size-16 align-middle me-1"></i>
            {{ $t('navbar.dropdown.henry.list.settings') }}
          </b-dropdown-item>
          <b-dropdown-item href="javascript: void(0);">
            <i class="bx bx-lock-open font-size-16 align-middle me-1"></i>
            {{ $t('navbar.dropdown.henry.list.lockscreen') }}
          </b-dropdown-item>
          <b-dropdown-divider></b-dropdown-divider>
          <a href="/logout" class="dropdown-item text-danger">
            <i class="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i>
            {{ $t('navbar.dropdown.henry.list.logout') }}
          </a>
        </b-dropdown>

        <div class="dropdown d-inline-block">
          <button
            type="button"
            class="btn header-item noti-icon right-bar-toggle toggle-right"
            @click="toggleRightSidebar"
          >
            <i class="bx bx-cog bx-spin toggle-right"></i>
          </button>
        </div>
      </div>
      <ModalWindow :editModal="cartPanel" :panelClass="'product-modal d-flex flex-column justify-content-center'" @close-modal="cartPanel = !cartPanel" :formReponse="''">
        <b-row>
          <b-col>
            <img v-if="cartPanelData.cover_image" :src="cartPanelData.cover_image" class="avatar-lg rounded-circle">
            <h2>cartPanelData.name</h2> 
          </b-col>
        </b-row>
      </ModalWindow>
    </div>
    
  </header>
</template>
