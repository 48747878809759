<script type="text/javascript">
	import OrderPayment from "../../components/widgets/ecommerce/OrderPayment";
	export default{
		components: { OrderPayment },
		data(){
			return {
				source: '',
				user: '',
				company: '',
				order: '',
				action: '',
			}
		},
		mounted(){
			document.body.classList.add('static-payment-form');
		},
		methods: {
			getUser(){
				if(this.source){

				}
			}
		},
		computed: {
			addCss(){
				return '.payment-form{min-height: unset !important;}';
			}
		}
	}

</script>
<template>
	<div class="container position-absolute top-0 left-0 bottom-0 right-0 d-flex">
		<b-row class="my-auto w-100">
			<b-col md="8" class="mx-auto text-center">
				<h4 class="text-primary">Pay for your order</h4>
				<p>Use the form below to process payment for your order</p>
			</b-col>
			<b-col md="8" class="mx-auto">
				<h6>Order Item(s)</h6>
				<b-card class="border border-radius-1em shadow-none">
				<b-list-group flush>
					<b-list-group-item >

					</b-list-group-item>
				</b-list-group>
				</b-card>
			</b-col>
			<b-col md="8" class="mx-auto text-center">
				<h6>Amount Payable</h6>
				<h4 class="text-primary">
					£ 50.00
				</h4>

				<b-button variant="primary" class="btn btn-primary btn-rounded" @click="action = 'make-payment'">
					Make Payment
				</b-button>
			</b-col>
		</b-row>
		<OrderPaymentForm v-if="(action == 'make-payment')" :user="$attrs.user" :company="$attrs.company" :order="order" :amount_payable="amount_payable" @close-window="action = ''" :id="id" :source="source" :selected_currency="currency.code" :selected_currency_symbol="currency.symbol" ></OrderPaymentForm>
	</div>	
</template>
<style type="text/css">
	
	.static-payment-form{
		min-height: 100% !important;
	}
</style>