<template>
	<b-row class="px-4">
		<b-col md="12">
			<b-row>
          <b-col sm="3">
            <b-card class="mini-stats-wid border border-radius-1em shadow-none" @click="loadingPrices = true, metal = 'GOLD', compileChartData(metal)" :class="{'border-primary': metal == 'GOLD'}">
             
                <div class="media">
                  <div class="me-3 align-self-center">
                    <i class="mdi mdi-gold h2 text-warning mb-0"></i>
                  </div>
                  <div class="media-body">
                    <p class="text-muted mb-2">Gold Spot Price</p>
                    <div class="d-flex w-100">
                    	
                	</div>
                    <h5 class="mb-0">
                      <b-spinner class="mx-auto" type="grow" variant="warning"  style="width: 1rem; height: 1rem;" v-if="loadingPrices == true"></b-spinner>
                      <span v-else class="font-size-14 text-muted">{{currency.icon}} {{spotprices.gold[4]}} / Ounce</span>
                    </h5>
                    <small>Prices via LBMA, updated on: <span v-if="loadingPrices == false">{{dailyprices.gold.dataset.data[0][0] | luxon}}</span></small>
                  </div>
                </div>

            </b-card>
          </b-col>
          <b-col sm="3">
            <b-card class="mini-stats-wid border border-radius-1em shadow-none" @click="loadingPrices = true, metal = 'SILVER', compileChartData(metal)" :class="{'border-primary': metal == 'SILVER'}">
                <div class="media">
                  <div class="me-3 align-self-center">
                    <i class="mdi mdi-gold text-secondary h2 text-primary mb-0"></i>
                  </div>
                  <div class="media-body">
                    <p class="text-muted mb-2">Silver Spot Price</p>
                    <h5 class="mb-0">
                      
                      <b-spinner class="mx-auto" type="grow" variant="warning"  style="width: 1rem; height: 1rem;" v-if="loadingPrices == true"></b-spinner>
                      <span v-else class="font-size-14 text-muted">{{currency.icon}} {{spotprices.silver[2]}} / Ounce</span>
                    </h5>
                    <small>Prices via LBMA, updated on: <span v-if="loadingPrices == false">{{dailyprices.silver.dataset.data[0][0] | luxon}}</span></small>
                  </div>
                </div>
    
            </b-card>
          </b-col>
          <b-col sm="3">
            <b-card class="mini-stats-wid border border-radius-1em shadow-none" :class="{'border-primary': metal == 'PLATINUM'}">
                <div class="media">
                  <div class="me-3 align-self-center">
                    <i class="mdi mdi-gold h2 text-info mb-0"></i>
                  </div>
                  <div class="media-body">
                    <p class="text-muted mb-2">Platinum</p>
                    <h5 class="mb-0">
                     	
                      <span class="font-size-14 text-muted">Coming Soon</span>
                    </h5>
                    <small>Currently assessing pricing methods </small>
                  </div>
                </div>
            </b-card>
          </b-col>
          <b-col sm="3">
            <b-card class="mini-stats-wid border border-radius-1em shadow-none"  :class="{'border-primary': metal == 'DIAMOND'}">
                <div class="media">
                  <div class="me-3 align-self-center">
                    <i class="mdi mdi-diamond-stone h2 text-info mb-0"></i>
                  </div>
                  <div class="media-body">
                    <p class="text-muted mb-2">Diamonds</p>
                    <h5 class="mb-0">
                     
                      <a class="btn btn-light btn-rounded btn-small">Add Your API Key</a>
                    </h5>
                  </div>
                </div>
            </b-card>
          </b-col>
        </b-row>
		</b-col>
		<b-col md="12">
			<b-card class="border border-radius-1em shadow-none">
			<h4>Conversions</h4>
			<div class="d-flex" v-if="metal == 'GOLD'">
			 <div class="media w-25 border border-radius-1em p-3 mr-1">
                  <div class="me-3 align-self-center">
                    <i class="mdi mdi-gold h2 text-warning mb-0"></i>
                  </div>
                  <div class="media-body">
                    <p class="text-muted mb-2">Gold Spot Price</p>
                    <div class="d-flex w-100">
                    	
                	</div>
                    <h5 class="mb-0">
                      <b-spinner class="mx-auto" type="grow" variant="warning"  style="width: 1rem; height: 1rem;" v-if="loadingPrices == true"></b-spinner>
                      <span v-else class="font-size-14 text-muted">{{currency.icon}} {{spotprices.gold[4]}} / Ounce</span> <h5 class="text-primary d-inline mb-0">=</h5>
                      <b-spinner class="mx-auto" type="grow" variant="warning"  style="width: 1rem; height: 1rem;" v-if="loadingPrices == true"></b-spinner>
                      <span v-else class="font-size-14 text-muted">{{currency.icon}} {{(parseFloat(spotprices.gold[4]) / parseFloat(28.34952)).toFixed(2)}} / Gram</span>
                    </h5>
                  </div>
                </div>
                <div class="media w-75 border border-radius-1em p-3 ml-1">
                </div>
            </div>
            <div class="d-flex" v-if="metal == 'SILVER'">
			 <div class="media w-25 border border-radius-1em p-3 mr-1">
                  <div class="me-3 align-self-center">
                    <i class="mdi mdi-gold h2 text-secondary mb-0"></i>
                  </div>
                  <div class="media-body">
                    <p class="text-muted mb-2">Silver Spot Price</p>
                    <div class="d-flex w-100">
                    	
                	</div>
                    <h5 class="mb-0">
                      <b-spinner class="mx-auto" type="grow" variant="warning"  style="width: 1rem; height: 1rem;" v-if="loadingPrices == true"></b-spinner>
                      <span v-else class="font-size-14 text-muted">{{currency.icon}} {{spotprices.silver[2]}} / Ounce</span> <h5 class="text-primary d-inline mb-0">=</h5>
                      <b-spinner class="mx-auto" type="grow" variant="warning"  style="width: 1rem; height: 1rem;" v-if="loadingPrices == true"></b-spinner>
                      <span v-else class="font-size-14 text-muted">{{currency.icon}} {{(parseFloat(spotprices.silver[2]) / parseFloat(28.34952)).toFixed(2)}} / Gram</span>
                    </h5>
                  </div>
                </div>
                <div class="media w-75 border border-radius-1em p-3 ml-1">
                </div>
            </div>
            </b-card>
		</b-col>
		<b-col md="8">
			<b-card class="border border-radius-1em shadow-none">
			<h4>{{datalimit}} Day Pricing Chart</h4>
			 <apexchart v-if="loadingPrices == false"
              class="apex-charts"
              height="540"
              type="line"
              dir="ltr"
              :series="linewithDataChart.series"
              :options="linewithDataChart.chartOptions"
            ></apexchart>
            <div v-else style="height: 540px;" class="d-flex w-100 justify-content-center text-center align-items-center">
            	<b-spinner variant="primary" type="grow"></b-spinner>
            </div>
        </b-card>
		</b-col>
		<b-col md="4">
			<b-card class="border border-radius-1em shadow-none">
			<h4>Last {{datalimit}} Trading Days</h4>
			<simplebar style="max-height: 540px;">
			<b-list-group v-if="loadingPrices == false">
				<b-list-group-item v-for="(price, index) in dailyprices[metal.toLowerCase()].dataset.data.slice(0,datalimit)" class="d-flex">
					<div>
						<div class="d-flex align-items-center h-100"><div class="avatar-xs mr-3"><span class="avatar-title rounded-circle bg-soft-primaryfont-size-18"><i class="bx bx-timer"></i></span></div> <h5 class="font-size-14 mb-0">{{price[0] | luxon}}</h5></div>

				</div>
						
					</div>
					<div class="d-flex  flex-column ml-auto" v-if="(metal == 'GOLD') && (currency.code == 'GBP')">
						
						<div class="ml-auto">
						 {{currency.icon}}{{(price[4]).toFixed(2)}} PM<br>
						 {{currency.icon}}{{(price[3]).toFixed(2)}} AM
						</div>
						<div>
							<span class="badge" :class="{'badge-soft-danger' : (parseFloat(price[4]) - parseFloat(dailyprices.gold.dataset.data[index + 1][4])) < 0, 'badge-soft-success' : (parseFloat(price[4]) - parseFloat(dailyprices.gold.dataset.data[index + 1][4])) > 0}" >

								<span v-if="(parseFloat(price[4]) - parseFloat(dailyprices.gold.dataset.data[index + 1][4])) > 0">+</span>{{(parseFloat(price[4]) - parseFloat(dailyprices.gold.dataset.data[index + 1][4])).toFixed(2) }}</span> 
							<small>Previous Day's Close Price</small>
						</div>
					</div>
					<div class="d-flex  flex-column ml-auto" v-else-if="(metal == 'SILVER') && (currency.code == 'GBP')">
						
						<div class="ml-auto">
						 {{currency.icon}}{{(price[2]).toFixed(2)}}
						</div>
						<div>
							<span class="badge" :class="{'badge-soft-danger' : (parseFloat(price[2]) - parseFloat(dailyprices.silver.dataset.data[index + 1][2])) < 0, 'badge-soft-success' : (parseFloat(price[2]) - parseFloat(dailyprices.silver.dataset.data[index + 1][2])) > 0}" >{{ (parseFloat(price[2]) - parseFloat(dailyprices.silver.dataset.data[index + 1][2])).toFixed(2) }}</span> 
							<small>Previous Day's Close Price</small>
						</div>
					</div>
				</b-list-group-item>
			</b-list-group>
		</simplebar>
		    </b-card>
		</b-col>
	</b-row>
</template>

<script type="text/javascript">
	import simplebar from "simplebar-vue";
	export default {
		components: {simplebar},
		data () {
			return {
				datalimit: 30,
				linewithDataChart: {
				    chartOptions: {
				        chart: {
				            height: 540,
				            type: 'line',
				            zoom: {
				                enabled: true
				            },
				            toolbar: {
				                show: true
				            }
				        },
				        colors: ['#556ee6', '#34c38f'],
				        dataLabels: {
				            enabled: false,
				        },
				        stroke: {
				            width: [3, 3],
				            curve: 'straight'
				        },
				        title: {
				            text: 'Average High & Low Temperature',
				            align: 'left',
				            style: {
				                fontWeight: '500',
				            },
				        },
				        grid: {
				            row: {
				                colors: ['transparent', 'transparent'], // takes an array which will be repeated on columns
				                opacity: 0.2
				            },
				            borderColor: '#f1f1f1'
				        },
				        markers: {
				            style: 'inverted',
				            size: 6
				        },
				        xaxis: {
				            categories: [],
				            title: {
				                text: 'Date'
				            }
				        },
				        yaxis: {
				            title: {
				                text: 'Price'
				            },
			
				        },
				        legend: {
				            position: 'top',
				            horizontalAlign: 'right',
				            floating: true,
				            offsetY: -25,
				            offsetX: -5
				        },
				        responsive: [{
				            breakpoint: 600,
				            options: {
				                chart: {
				                    toolbar: {
				                        show: false
				                    }
				                },
				                legend: {
				                    show: false
				                },
				            }
				        }],
				    },
				    series: [{
				        name: "High - 2018",
				        data: []
				    }
				    ],
				    animations: {
				        enabled: true,
				        easing: 'easeinout',
				        speed: 800,
				        animateGradually: {
				            enabled: true,
				            delay: 150
				        },
				        dynamicAnimation: {
				            enabled: true,
				            speed: 350
				        }
				    }
				},
				loadingPrices: true,
				spotprices: {
					gold: '',
					silver: '',
					platinum: ''
				},
				dailyprices: {
					gold: [],
					silver: [],
					platinum: []
				},
				currency: {
					icon: '£',
					code: 'GBP',
					ratetogbp: '1.00',
					ratetousd: '',
				},
				metal: 'GOLD',

			}
		},
		methods: {
			getHistoricalPrices(metal){
				axios.get('https://data.nasdaq.com/api/v3/datasets/LBMA/'+metal+'.json?api_key=5APczHT1kTC1FJanAs2b').then(response => {
					this.dailyprices[metal.toLowerCase()] = response.data;
					this.spotprices[metal.toLowerCase()] = response.data.dataset.data[0];
					this.compileChartData(metal);
					
				}).catch(error => {
					console.log(error);
				});
			},
			compileChartData(metal){
				this.linewithDataChart.chartOptions.xaxis.categories = [];
				this.linewithDataChart.series[0].data = [];
				for(var i = 0; i < this.datalimit; i++){
					var date = this.dailyprices[metal.toLowerCase()].dataset.data[i][0];
				
				    
				    if(metal == 'GOLD'){
				    	this.linewithDataChart.chartOptions.xaxis.categories.push(date);
				    	this.linewithDataChart.chartOptions.xaxis.categories.push(date);
				    	this.linewithDataChart.series[0].data.push(this.dailyprices[metal.toLowerCase()].dataset.data[i][3]);
				    	this.linewithDataChart.series[0].data.push(this.dailyprices[metal.toLowerCase()].dataset.data[i][4]);


				    }
				    if(metal == 'SILVER'){
				    	this.linewithDataChart.chartOptions.xaxis.categories.push(date);
				    	this.linewithDataChart.series[0].data.push(this.dailyprices[metal.toLowerCase()].dataset.data[i][2]);
				    }
				}
						this.linewithDataChart.series[0].data.reverse();
				    	this.linewithDataChart.chartOptions.xaxis.categories.reverse();
				let self = this;
				setTimeout(function(){
					self.loadingPrices = false;
				}, 500);
			}
		},
		/*computed:{
			filterChartData(metal){
				for(var i = 0; i < this.datalimit; i++){
					var date = this.dailyprices[metal.toLowerCase()].dataset.data[i][0];
					if(metal == 'GOLD'){
					 var amprice = this.dailyprices[metal.toLowerCase()].dataset.data[i][3];
					 var pmprice = this.dailyprices[metal.toLowerCase()].dataset.data[i][4];
				    }
				    this.linewithDataChart.chartOptions.xaxis.categories.push(date+ 'AM');
				    this.linewithDataChart.chartOptions.xaxis.categories.push(date+ 'PM');
				    this.linewithDataChart.series.data.push(amprice);
				    this.linewithDataChart.series.data.push(pmprice);
				}
			}
		},*/
		created(){
			this.getHistoricalPrices('SILVER');
			this.getHistoricalPrices('GOLD');
		}
	}

</script>

