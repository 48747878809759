<script type="text/javascript">
import GemstoneCalculator from './GemstoneCalculator';
import DiamondCalculator from './DiamondCalculator';
import CabochonCalculator from './CabochonCalculator';
import CrownAngleCalculator from './CrownAngleCalculator';
import SpecificGravityCalculator from './SpecificGravityCalculator';
import PavillionAngleCalculator from './PavillionAngleCalculator';
	export default{
		components: {GemstoneCalculator, DiamondCalculator, CabochonCalculator, CrownAngleCalculator, SpecificGravityCalculator, PavillionAngleCalculator},
		data: function(){
			return{

			}
		}
	}
</script>
<template>
	<div>
		<b-tabs pills  vertical>
			<b-tab title="Gemstone Calculator">
				<div class="mt-0">
				<GemstoneCalculator />
				</div>
			</b-tab>
			<b-tab title="Diamond Calculator">
				<div class="mt-0">
				<DiamondCalculator />
				</div>
			</b-tab>
			<b-tab title="Cabochon Calculator">
				<div class="mt-0">
				<CabochonCalculator />
				</div>
			</b-tab>
			<b-tab title="Specific Gravity Calculator">
				<div class="mt-0">
				<SpecificGravityCalculator />
				</div>
			</b-tab>
			<b-tab title="Crown Angle Calculator">
				<div class="mt-0">
				<CrownAngleCalculator />
				</div>
			</b-tab>
			<b-tab title="Pavillion Angle Calculator">
				<div class="mt-0">
				<PavillionAngleCalculator />
				</div>
			</b-tab>
		</b-tabs>
	</div>
</template>