<script type="text/javascript">
  import Layout from "../../layouts/main";
  import PageHeader from "../../components/page-header";
  import UserList from "../../components/widgets/UserList";
  import Stat from "../../components/widgets/stat";
  import OrderList from '../../components/widgets/admin/list/OrderList';
  import SearchCompanyUser from "../../components/widgets/SearchCompanyUser";
  import SideSlidePanel from "../../components/widgets/SideSlidePanel";
  //import { projectData } from "../data-projects";
  import vue2Dropzone from 'vue2-dropzone';
  import DateTime from 'luxon/src/datetime.js';
  import Interval from 'luxon/src/interval.js';
  import UserPermissions from '../../components/widgets/admin/permissions/UserPermissions'
  import LoginCard from "../../components/widgets/admin/print/LoginCard";
  import QrcodeVue from 'qrcode.vue';
  import VueBarcode from '@chenfengyuan/vue-barcode';


  export default{
    components:{ Layout, PageHeader, SearchCompanyUser, UserList, Stat, SideSlidePanel, OrderList, DateTime, Interval, UserPermissions, vueDropzone: vue2Dropzone, QrcodeVue, VueBarcode, LoginCard},
    data(){
      return {
        error_message: false,
        error_reponse: '',
        success_message: false,
        success_text: '',
        pos_code_type: 'design',
        loginQrData: {
              type: 'pos-login',
              reference: '',
              uid: '',
              id: '',
      },
      loginBarcodeData: '',
        revealPosId: false,
        avatarDropzoneOptions: {
            url: 'https://httpbin.org/post',
            thumbnailHeight: 150,
            thumbnailWidth: 150,
            autoProcessQueue: false,
            maxFiles: 1,
            acceptedFiles: '.jpg, .png, .webp',
          },
        imageOptions: '',
        logoUpload: false,
        permissions: [],
        mapcenter: { lat: 54, lng: 2 },
        hoverIndex: -1,
        storageurl: 'https://'+this.$attrs.company.nickname+'.s3.eu-west-2.amazonaws.com',
        orderDataLoading: true,
        chartDisplay: 'by-date',
        ordersstartdate: '',
        ordersenddate: '',
        latestorders: '',
        latestordersPage: 1,
        currencyObject: '<span class="currency">£</span>',
        title: 'department',
        projects: [],
        userData: [],
        statsOrders: [],
        error: '',
        order: 'desc',
        sortby: 'id',
        search: '',
        status_id: '',
        currentPage: 1,
        limit: 25,
        selectedids: [],
        orderDesc: true,
        departments: [],
        lineChart:{
            series: [
                {
                    name: "Earrnings",
                    type: 'line',
                    data: []
                },
                {
                    name: "OrderValue",
                    type: 'column',
                    data: []
                }
            ],
            chartOptions: {
                chart: {
                    height: 320,
                    type: "line",
                    toolbar: {
                        show: true
                    },
                    zoom: {
                        enabled: true
                    },
                    dropShadow: {
                        enabled: true,
                        color: "#000",
                        top: 18,
                        left: 7,
                        blur: 8,
                        opacity: 0.2
                    }
                },
                labels: [],
                dataLabels: {
                    enabled: false
                },
                colors: ["#5E3BB0", "rgba(52, 195, 143, 0.8)"],

                stroke: {
                    curve: "smooth",
                    width: 3
                },
                xaxis: {
                  type: "datetime",
                    labels: {
                        show: true
                    },
                    max: '',
                    min: '',
                    categories: [],
                },
                yaxis: [{
                    title: {
                        text: "Total Earnings",
                        style: {
                          color: "#00E396"
                        }
                      },
                },
                {
                    seriesName: "OrderValue",
                    opposite: true,
                    title: {
                        text: "Order Value",
                        style: {
                          color: "#00E396"
                        }
                      },
                }
                ]
            }
        },
        teams: [],
        projects: [],
        actionPanel: false,
        actionPanelClass: 'edit-sidepanel',
        assignedto: '',
        assignedby: '',
        collaborators: [],
        completed: false,
        archived: false,
        setPermissions: false,
        viewAction: {},
        viewActionTab: 'comments',
        newActionPanel: false,
newActionPanelClass: 'edit-sidepanel',
newAction: {
                name: '',
                image: '',
                description: '',
                company_id: 0,
                parent_id: 0,
                project_id: 0,
                created_by_id: 0,
                assigned_by_id: 0,
                assigned_to_id: 0,
                company_department_id: 0,
                company_team_id: 0,
                action_type_id: 0,
                reference: '',
                issue: '',
                action: '',
                due_date: null,
                more_time_request: null, 
                more_time_approved: 0,
                more_time_approved_id: 0, 
                completed_date: null,
                completed:0,
                archive: 0,
                reviewed: 0,
                review_requested: 0,
                management_review_minute_id: 0,
                collaborators: [],
                attachments: [],
                settings: {
                  creation:{
                    email_creator: 0,
                    email_assigned_to: 0,
                    email_collaborators: 0,
                    email_additional_emails: 0,
                    additional_emails: []
                  },
                  update: {
                    email_creator: 0,
                    email_assigned_to: 0,
                    email_collaborators: 0,
                    email_additional_emails: 0,
                    additional_emails: []
                  },
                  delete: {
                    email_creator: 0,
                    email_assigned_to: 0,
                    email_collaborators: 0,
                    email_additional_emails: 0,
                    additional_emails: []
                  },
                  status: {
                    email_creator: 0,
                    email_assigned_to: 0,
                    email_collaborators: 0,
                    email_additional_emails: 0,
                    additional_emails: []
                  },
                  complete: {
                    email_creator: 0,
                    email_assigned_to: 0,
                    email_collaborators: 0,
                    email_additional_emails: 0,
                    additional_emails: []
                  },
                  due: {
                    email_creator: 0,
                    email_assigned_to: 0,
                    email_collaborators: 0,
                    days_before: 0,
                    email_additional_emails: 0,
                    additional_emails: []
                  },
                  addcollaborator:{
                    email_creator: 0,
                    email_collaborator: 0,
                  },
                  removecollaborator:{
                    email_creator: 0,
                    email_collaborator: 0,
                  },
                  report:{
                    email_creator: 0,
                    email_attending: 0,
                    email_additional_emails: 0,
                    additional_emails: [],
                  },
                },
              },
        statData: [
              {
                icon: "bx bx-copy-alt",
                title: "Team Members",
                value: "1,235"
              },
              {
                icon: "bx bx-archive-in",
                title: "Projects",
                value: "$35, 723"
              },
              {
                icon: "bx bx-group",
                title: "Tasks",
                value: "$16.2"
              }
            ],
        items: [
              {
                text: "Company",
                href: "/dashboard"
              },
              {
                text: "Departments",
                href: "/company/departments"
              },
              {
                text: "Department",
                active: true
              }
            ]
      }
    },
    beforeMount(){
      this.ordersstartdate = DateTime.local().minus({months: 1}).toISODate();
      this.ordersenddate = DateTime.local().plus({days: 1}).toISODate();
      axios.get('/api/company/user/'+this.$attrs.id+'', {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
        this.userData = response.data;
        this.title = '';
        this.items[2].text = response.data.name;
        this.permissions[0] = response.data.permissions;
        this.updateMapCenter(response.data);
        return this.getUserSales(response.data.id);

      }).catch(error => {
        this.error = error.data;
      });
      
    },
    computed: {
      posModalSize(){
        if(this.pos_code_type == 'design')
        return  'xl' 
        else
        return 'lg'
      }
    },
    methods:{
      createEposLogin(){
        axios.post('/api/company/user/'+this.userData.id+'/create-pos-login', {uid: this.$attrs.id}, {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
          this.userData.pos_login = response.data;
        }).catch(error => {
          this.error_message = true;
          this.error_reponse = error.data;
        });
      },
      updateMapCenter(user){
        var userindex = user.listings.findIndex(element => element.listing.gplace_id !== null);
        //this.mapcenter.lat = parseFloat(user.listings[userindex].listing.latitude);
        //this.mapcenter.lng = parseFloat(user.listings[userindex].listing.longitude);
      },
      updateDates(minus_months){
      this.ordersstartdate = DateTime.local().minus({months: minus_months}).toISODate();
      this.ordersenddate = DateTime.local().plus({days: 1}).toISODate();
      //this.lineChart.chartOptions.xaxis.min = this.ordersstartdate;
      //this.lineChart.chartOptions.xaxis.max = this.ordersenddate;
      this.getUserSales(userData.companies[0].id);
      },
      compileChartByDate(){
        this.lineChart.chartOptions.xaxis.type = "datetime";
    this.orderDataLoading = true;
    this.lineChart.series[0].data = [];
    this.lineChart.series[1].data = [];
    this.lineChart.chartOptions.labels = [];
    this.lineChart.chartOptions.xaxis.labels.show = true;
    var keys = Object.keys(this.orderdatabydate);
    this.lineChart.chartOptions.labels = keys;
    this.lineChart.chartOptions.xaxis.categories = keys;
    console.log(keys);
    for(var date in this.orderdatabydate){
        console.log(date);
            if(this.lineChart.series[0].data.length == 0){
                this.lineChart.series[0].data[0] = parseFloat(this.orderdatabydate[''+date+'']["orders_total"]);
            }else{
                this.lineChart.series[0].data.push(parseFloat(this.orderdatabydate[date]["orders_total"]) + parseFloat(this.lineChart.series[0].data[this.lineChart.series[0].data.length -1]));
            }

            this.lineChart.series[1].data.push(this.orderdatabydate[date]["orders_total"]);
    }

    let self = this;
             setTimeout(() => {
     self.orderDataLoading = false;
    }, 1500);
  },
    compileOrderChart(){
      this.orderDataLoading = true;
      this.lineChart.chartOptions.xaxis.type = "category";
        this.lineChart.series[0].data = [];
        this.lineChart.series[1].data = [];
        this.lineChart.chartOptions.xaxis.categories = [];
        for(var i = 0; i < this.statsOrders[0].orders.length; i++){
           if(i == 0){
            this.lineChart.series[0].data.push(parseFloat(this.statsOrders[0].orders[i].total_order_inc));
            }else{
               this.lineChart.series[0].data.push((parseFloat(this.lineChart.series[0].data[i-1]) + parseFloat(this.statsOrders[0].orders[i].total_order_inc) ) ) ; 
            }
            /** 
            if(this.statsOrders[0].orders[i].trade_orders.length > 0){
                this.donutChart.series[4] += parseFloat(this.statsOrders[0].orders[i].total_order_inc);
            }
            if(this.statsOrders[0].orders[i].customer_orders.length > 0){
                this.donutChart.series[3] += parseFloat(this.statsOrders[0].orders[i].total_order_inc);
            }
            **/
            this.lineChart.series[1].data.push(parseFloat(this.statsOrders[0].orders[i].total_order_inc));
            this.lineChart.chartOptions.xaxis.categories.push(this.statsOrders[0].orders[i].reference);
        }
          let self = this;
             setTimeout(() => {
              self.orderDataLoading = false;
            }, 1500);
    },
      userProjects(){
      this.projects = [];
      if(this.completed == false){
        this.completed = 0;
      }else{
        this.completed = 1;
      }
      if(this.archived == false){
        this.archived = 0;
      }else{
        this.archived = 1;
      }
      axios.get('/api/company/projects?page='+this.currentPage+'&limit='+this.limit+'&sortby='+this.sortby+'&order='+this.order+'&search='+this.search+'&departments='+this.departments+'&teams='+this.teams+'&assingedto='+this.userData.id+'&assingedby='+this.assignedby+'&collaborators='+this.collaborators+'&view=list&completed='+this.completed+'&archived='+this.archived+'&status='+this.status_id+'', {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
              }}).then(response => {
        this.projects = response.data;
      }).catch(error =>{
        this.load_error = error.response;
      })
    },
    avatarFileAdded(file){
            this.userData.user.avatar = file;
        },
    getUserSales(id){
      axios.get('/api/company/user/'+id+'/sales?page='+this.latestordersPage+'&startdate='+this.ordersstartdate+'&enddate='+this.ordersenddate, {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
        this.latestorders = response.data;
        return this.getUserStats(id);
      }).catch(error => {
        this.error = error.data;
      });
    },
    getUserStats(id){
      axios.get('/api/company/user/'+id+'/stats?startdate='+this.ordersstartdate+'&enddate='+this.ordersenddate, {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
        this.statsOrders = response.data;
        this.orderDataLoading = false;
        this.orderdatabydate = response.data[2];
        if(this.chartDisplay == 'by-order'){
                return this.compileOrderChart();
              }else if(this.chartDisplay == 'by-date'){
                 return this.compileChartByDate();
              }
      }).catch(error => {
        this.error = error.data;
      });
    },
    getFactoryUserRolePermissions(id){
            axios.get('/api/company/user/permissions/default/'+id+'', {headers: {
                'Authorization' : 'Bearer '+this.$attrs.user.api_token
            }}).then(response => {
                if(response){
                    this.permissions[0].add = response.data.add;
                    this.permissions[0].view = response.data.view
                    this.permissions[0].edit = response.data.edit;
                    this.permissions[0].read = response.data.read;
                    this.permissions[0].delete = response.data.delete;
                }
                this.setPermissions = true;
          }).catch(error => {
            this.load_error = error.data;
          });
        },
        updateCompanyUserPermissions(id){
            axios.post('/api/company/user/'+id+'/permissions/update', this.userData.permissions, {headers: {
                'Authorization' : 'Bearer '+this.$attrs.user.api_token
            }}).then(response => {
               /* if(response){
                    this.permissions[0].add = response.data.add;
                    this.permissions[0].view = response.data.view;
                    this.permissions[0].edit = response.data.edit;
                    this.permissions[0].read = response.data.read;
                    this.permissions[0].delete = response.data.delete;
                }*/
                this.setPermissions = true;
                let self = this;
                setTimeout(function(){
                    self.success_text = "User Permissions Updated";
                    self.success_message = true;
                }, 500);
          }).catch(error => {
            this.error_reponse = error.data.errors;
          });
        },
      showActionEvent(project){
      this.actionPanel = false;
      this.viewAction = project;
      this.viewActionTab = 'comments';
      var self = this;
                setTimeout(function(){
                self.actionPanel = true;
                }, 500);
    },
    showCollaboratorsEvent(project){
      this.actionPanel = false;
      this.viewAction = project;
      this.viewActionTab = 'collaborators';
      var self = this;
                setTimeout(function(){
                self.actionPanel = true;
                }, 500);
    },
    addRelatedAction(){
          this.newActionPanel = true;
      },
    }
  }
</script>
<template>
  <Layout :nickname="$attrs.nickname" :user="$attrs.user" >
    <PageHeader :title="title" :items="items" />
    <div class="row" v-if="userData == ''">
      You don't have access to this department
    </div>
    <div class="row" v-else>
      
      <!-- end col -->
      <b-col md="12">
                    <transition name="slide-success" mode="out-in">
                  <b-alert id="success-message" variant="success" :show="success_message"  dismissible @dismissed="success_message = !success_message">
                      <p class="font-size-14">{{success_text}}</p>
                    </b-alert>
                  </transition>
                  </b-col>
      <div class="col-lg-3">
        <b-card class="card overflow-hidden border-radius-1em shadow-sm seller-profile-card" no-body >
          <div class="bg-light border-radius-top-right-1em border-radius-top-left-1em" style="height: 75px;">
            <div class="row">
                           
                       <div class="col-12">
              </div>
            </div>
          </div>
        <b-card-body class="card-body pt-0">
          <div class="avatar-md profile-user-wid mb-2 mx-auto" style="margin-top: -45px !important;"><img v-if="userData.user.avatar !== null" class="avatar-sm rounded-circle mr-4" :src="userData.user.avatar"><span v-else class="avatar-title rounded-circle">
                        {{(userData.user.name).slice(0,1)}}{{(userData.user.lastname).slice(0,1)}}
                      </span></div>
            <div class="media d-flex">
              
              <!--<div class="avatar-sm mr-4">
                <span class="avatar-title rounded-circle">{{(userData.user.name).slice(0,1)}}</span>
              </div>-->
              <div class="media-body overflow-hidden d-flex flex-column my-auto">
                <h4 class="text-truncate text-center mb-0 mt-auto">{{userData.user.name}} {{userData.user.lastname}}</h4>
                <a class="mx-auto" :href="'mailto:'+userData.user.email">{{userData.user.email}}</a>
               <!-- <p class="mb-0">{{userData.user.jobtitle}}</p>-->
              </div>
            </div>
            <b-list-group class="my-4" flush>
              <b-list-group-item class="d-flex"><strong class="mr-auto text-primary">Job Title</strong><p class="ml-auto my-auto badge badge-light bg-light font-size-12 d-flex align-items-center"><i class="bx bx-id-card text-primary font-size-14"></i> {{userData.user.jobtitle}}</p></b-list-group-item>
              <b-list-group-item class="d-flex"><strong class="mr-auto text-primary">User Role</strong><p class="ml-auto my-auto badge badge-light bg-light font-size-12 d-flex align-items-center"><i class="bx bx-user text-primary font-size-14"></i> {{userData.userrole.name}}</p></b-list-group-item>
              <b-list-group-item class="d-flex"><strong class="mr-auto text-primary">ePOS User</strong><p class="ml-auto my-auto badge badge-light bg-light font-size-12 d-flex align-items-center" v-if="userData.pos_login !== null"><i class="bx bx-check-circle text-success font-size-14"></i> POS User</p>
                <p class="ml-auto my-auto badge badge-light bg-light font-size-12 d-flex align-items-center" v-else><i class="bx bx-x-circle text-danger font-size-14"></i> Not POS User</p>
              </b-list-group-item>
               <b-list-group-item class="d-flex"><strong class="mr-auto text-primary">Date Created</strong><p class="ml-auto my-auto badge badge-light bg-light font-size-12 d-flex align-items-center"><i class="bx bx-calendar text-primary font-size-14"></i> {{userData.created_at | luxon({input: 'server'})}}</p>

              </b-list-group-item>
               <b-list-group-item class="d-flex"><strong class="mr-auto text-primary">Last Updated</strong><p class="ml-auto my-auto badge badge-light bg-light font-size-12 d-flex align-items-center"><i class="bx bx-calendar text-primary font-size-14"></i> {{userData.updated_at | luxon({input: 'server'})}}</p>

              </b-list-group-item>

            </b-list-group>
          
            <!--<p class="text-muted mb-auto">{{userData.description}}</p>-->

          </b-card-body>
        </b-card>
         <b-row>
              <b-col md="12" class="mb-3">
                <button class="btn btn-primary btn-soft btn-rounded btn-block" v-b-modal.modal-emailattendees>
                  <div class="d-flex m-2">
                 Email User <i class="bx bx-mail-send font-size-20 ml-auto icon-label-end"></i>
                  </div>
                </button>
              </b-col>
              
              <b-col md="12" class="mb-3">
                <button class="btn btn-primary btn-soft btn-rounded btn-block" v-b-modal.action-modal @click="action = 'arrange-delivery'">
                  <div class="d-flex m-2">
                 Message User  <i class="bx bx-message-alt font-size-20 ml-auto icon-label-end"></i>
                  </div>
                </button>
              </b-col>
              
              <b-col md="12" class="mb-3">
                <button class="btn btn-success btn-rounded btn-block" v-b-modal.invoice-modal>
                  <div class="d-flex m-2">
                   Update <i class="bx bxs-up-arrow-circle font-size-20 ml-auto icon-label-end"></i>
                  </div>
                </button>
              </b-col>
           </b-row>
          <b-modal id="modal-emailattendees" title="Email User" centered hide-footer title-class="font-18">
                    You are about to email {{userData.user.name}} {{userData.user.lastname}}.
                    <b-form-group class="mt-3" label="Custom Message">
                      <b-form-textarea placeholder="Write attendees a custom message" rows="4"></b-form-textarea>
                    </b-form-group>
                    <b-button variant="success" block class="mt-3">
                      <i class="bx bx-mail-send"></i> Send Email
                    </b-button>
                  </b-modal>
          
        </div>
        <div class="col-lg-9">
        <div class="row">
          <div class="col-lg-4">
                  <Stat :icon="'bx bx-store'" :title="'Shops'" :value="userData.listings.length" />
          </div>
          <!--<div class="col-lg-4">
                  <Stat :icon="'bx bx-group'" :title="'Departments'" :value="userData.departments.length" />
          </div>-->
          <div class="col-lg-4">
            <Stat :icon="'bx bx-purchase-tag-alt'" :title="'Sales'" :value="userData.sales_count"/>
          </div>
          <div class="col-lg-4">
            <Stat :icon="'bx bx-pound'" :title="'Total Sales'" :value="currencyObject + parseFloat(userData.sales_sum_total_order_inc).toFixed(2)" />
          </div>
        </div>
        <b-tabs pills justified content-class="py-3 text-muted">
          <b-tab active class="border-0">
                <template v-slot:title>
                  <span class="d-inline-block d-none">
                   <!--<i class="bx bx-history font-size-16"></i>-->
                  </span>
                  <span class="d-sm-inline-block"> Basic Info</span>
                </template>
                <b-card class="border-radius-1em shadow-sm">
                  <b-row>
                    <b-col md="4">
                       <h5 class="d-flex">Basic Details<i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
                    </b-col>
                    <b-col md="8">

                      <b-card class="border-radius-1em border shadow-none mb-0">
                      <b-list-group flush>
                        <b-list-group-item class="d-flex">
                          <span class="mr-auto">
                            Name:
                          </span>
                          <span>
                            {{userData.user.name}} {{userData.user.lastname}}
                          </span>
                        </b-list-group-item>
                        <b-list-group-item class="d-flex">
                          <span class="mr-auto">
                            Email:
                          </span>
                          <span>
                            {{userData.user.email}}
                          </span>
                        </b-list-group-item>
                        <b-list-group-item class="d-flex">
                          <span class="mr-auto">
                            Address:
                          </span>
                          <span>
                            
                          </span>
                        </b-list-group-item>
                        <b-list-group-item class="d-flex">
                          <span class="mr-auto">
                            Main Phone:
                          </span>
                          <span>
                            
                          </span>
                        </b-list-group-item>
                        <b-list-group-item class="d-flex">
                          <span class="mr-auto">
                            Mobile Phone:
                          </span>
                          <span>
                            
                          </span>
                        </b-list-group-item>
                      </b-list-group>
                    </b-card>
                    </b-col>
                  </b-row>
                </b-card>
                <b-card class="border-radius-1em shadow-sm">
                  <b-row>
                    <b-col md="8">
                       <h5 class="d-flex">User Avatar<i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
                    </b-col>
                    <b-col md="4" class="d-flex"  @mouseenter="imageOptions = true" @mouseleave="imageOptions = ''">
                      <b-card class="border-radius-1em border shadow-none ml-auto" v-if="logoUpload == false">
                <img  v-if="userData.user.avatar !== null && userData.user.avatar.search(storageurl) == -1" :src="storageurl + userData.user.avatar" class="mx-auto img-fluid border">
                <img  v-else-if="userData.user.avatar !== null && userData.user.avatar.search(storageurl) > -1" :src="userData.user.avatar" class="mx-auto img-fluid border">
                <img src="/images/product-placeholder.png" v-else class="mx-auto img-fluid border">
                <div class="left-0 right-0 top-0 h-100 w-100 opacity-75" :class="{'h-100 d-flex flex-column position-absolute bg-white bg-soft w-75 mx-auto': imageOptions == true, 'd-none': imageOptions == false}">
                              <a class="btn btn-primary btn-rounded mt-auto w-75 mx-auto mb-1" @click="$emit('file-selected', object), $emit('close-window')">Edit</a>
                              <a class="btn btn-primary btn-rounded mb-auto w-75 mx-auto mt-1" @click="logoUpload = !logoUpload" v-b-modal.uploadModal>Upload New</a>
                            </div>
                            </b-card>
                            <b-card v-else class="border-radius-1em border shadow-none">
                            <b-form id="upload-avatar">
                              <input type="hidden" name="logo" v-model="userData.user.avatar">
                            </b-form>
                             <vue-dropzone id="dropzone" ref="myVueDropzone" :use-custom-slot="true" :options="avatarDropzoneOptions" @vdropzone-file-added="avatarFileAdded"  @vdropzone-drop="avatarFileAdded">
                              <div class="dropzone-custom-content d-flex flex-column justify-content-center align-items-center">
                                  <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                                  <h4>Drop files here or click to upload.</h4>
                              </div>
                            </vue-dropzone>
                            <div class="d-flex">
                            <a class="btn btn-primary mb-auto w-50 mx-auto btn-rounded mt-1 mr-1" @click="uploadAvatar()" v-b-modal.uploadModal>Upload</a>
                            <a class="btn btn-danger mb-auto w-50 mx-auto btn-rounded mt-1 ml-1" @click="logoUpload = !logoUpload" v-b-modal.uploadModal>Cancel</a>
                            </div>
                        </b-card>
                    </b-col>
                  </b-row>
                </b-card>
             
          </b-tab>
           <b-tab class="border-0">
                <template v-slot:title>
                  <span class="d-inline-block d-none">
                   <!--<i class="bx bx-history font-size-16"></i>-->
                  </span>
                  <span class="d-sm-inline-block"> Security</span>
                </template>
                <b-row>
                  <b-col md="12">
                <b-card class="border-radius-1em shadow-sm">
                  <b-row>
                    <b-col md="4">
                       <h5 class="d-flex">Password & Security<i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
                    </b-col>
                    <b-col md="8">

                      <b-card class="border-radius-1em border shadow-none mb-0">
                      <b-list-group flush>
                        <b-list-group-item class="d-flex">
                          <span class="mr-auto">
                            Password:
                          </span>
                          
                          <b-button variant="light" class="btn btn-light mr-2 btn-rounded">
                            Email Password Reset Request
                          </b-button>
                          <b-button variant="light" class="btn btn-light btn-rounded">
                            Manual Password Reset
                          </b-button>
                        </b-list-group-item>
                        <b-list-group-item class="d-flex">
                          <span class="mr-auto">
                           2FA Authentication:
                          </span>
                          <span>
                            <div
                              class="form-check form-switch form-switch-sm mb-0 mx-auto p-0 mt-auto d-flex"
                              dir="ltr"
                            >
                            <input
                                class="form-check-input mx-auto my-auto" 
                                type="checkbox"
                                id="SwitchCheckSizesm"
                               
                                @click='checkAll()'
                                name="active"
                              />
                          </div>
                          </span>
                        </b-list-group-item>
                        <b-list-group-item class="d-flex">
                          <span class="mr-auto">
                            2FA Type:
                          </span>
                          <span class="badge badge-light">
                            --
                          </span>
                        </b-list-group-item>
                        

                      </b-list-group>
                      <b-list-group class="mt-3">
                        <b-list-group-item class="d-flex border-danger border-radius-1em">
                          <strong class="mr-auto">
                            Reset 2FA
                          </strong>
                          
                          <b-button variant="danger" class="btn-rounded">
                            Manual Password Reset
                          </b-button>
                        </b-list-group-item>
                      </b-list-group>
                    </b-card>
                    </b-col>
                  </b-row>
                </b-card>
                <b-card class="border-radius-1em shadow-sm" >
                  <b-row>
                    <b-col md="4">
                       <h5 class="d-flex">ePOS Login<i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
                    </b-col>
                    <b-col md="8">
                      <b-card class="border-radius-1em border shadow-none mb-0" v-if="userData.pos_login !== null">
                      <b-list-group flush>
                        <b-list-group-item class="d-flex">
                          <span class="mr-auto">
                            User ID:
                          </span>
                          <span v-if="revealPosId == false" class="badge badge-light bg-light d-flex my-auto font-size-14" v-b-tooltip.hover.top title="Click to Show">
                           *** - *** - ***
                          </span>
                          <span v-else class="badge badge-light bg-light d-flex my-auto font-size-14">
                           {{userData.pos_login.pos_id}}
                          </span>
                        </b-list-group-item>
                        <b-list-group-item class="d-flex">
                          <span class="mr-auto">
                            Pin:
                          </span>
                          <span class="badge badge-light bg-light d-flex my-auto font-size-14">
                            ******
                          </span>
                        </b-list-group-item>
                        <b-list-group-item class="d-flex">
                          <span class="mr-auto">
                            Configs
                          </span>
                          <span>
                            
                          </span>
                        </b-list-group-item>
                        <b-list-group-item class="d-flex">
                          <span class="mr-auto">
                            Last Logged In
                          </span>
                          <span class="badge badge-light bg-light d-flex my-auto font-size-12">
                            <i class="bx bx-calendar mr-2 font-size-16 text-primary"></i>
                            <span v-if="userData.pos_login.last_login !== null">{{userData.pos_login.last_login | luxon({input: 'server'})}}</span>
                            <span v-else>
                              Never
                            </span>
                          </span>
                        </b-list-group-item>
                        <b-list-group-item>
                          <b-dropdown id="dropdown-left" text="Generate Login Codes" variant="light" class="btn-block rounded-dropdown mb-3 d-sm-flex" button-class="btn-rounded" >
                             <template #button-content>
                                Generate Login Details <i class="bx bx-chevron-down"></i>
                               </template>
                               <b-dropdown-item v-b-modal.pos-login-modal @click="pos_code_type = 'design'">
                                 ID Card Design
                               </b-dropdown-item>
                               <b-dropdown-item v-b-modal.pos-login-modal @click="pos_code_type = 'QR'">
                                 Get QR Code
                               </b-dropdown-item>
                               <b-dropdown-item v-b-modal.pos-login-modal @click="pos_code_type = 'barcode'">
                                 Get Barcode
                               </b-dropdown-item>
                          </b-dropdown>
                          
                        </b-list-group-item>
                      </b-list-group>
                    </b-card>
                    <b-card class="border-radius-1em border shadow-none mb-0 bg-light" body-class="justify-content-center d-flex flex-column py-3 align-items-center" v-else>
                      <div class="w-75 my-4 text-center">
                      <h4>No ePOS Login Details</h4>
                      <p>Your user needs an ePOS login to be able to login to your ePOS and sell in-store. This is an additional security measure for your protection and enables to staff to switch between users quickly in-store to register their sales</p>
                      <b-button variant="warning" class="btn-rounded mt-2" @click="createEposLogin()">
                        Create an ePOS Login
                      </b-button>
                      </div>
                    </b-card>
                    </b-col>
                  </b-row>
                </b-card>
                <b-card class="border-radius-1em shadow-sm">
                  <b-row>
                    <b-col md="4">
                       <h5 class="d-flex text-danger">Danger Zone<i class="bx bx-right-arrow-circle text-danger font-size-20 ml-2"></i></h5>
                    </b-col>
                    <b-col md="8">
                      <b-card class="border-radius-1em border shadow-none mb-0  border border-danger">
                      <b-list-group flush>
                        <b-list-group-item class="d-flex">
                          <span class="mr-auto">
                            Reset User Data
                          </span>
                        
                          <b-button variant="danger" class="btn-rounded">
                            Clear User Stats & Data
                          </b-button>
                        </b-list-group-item>
                        <b-list-group-item class="d-flex">
                          <span class="mr-auto">
                            Delete User Account
                          </span>
                          <b-button variant="danger" class="btn-rounded">
                            Delete Account
                          </b-button>
                        </b-list-group-item>
                        
                      </b-list-group>
                    </b-card>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
            </b-row>
              </b-tab>
          <b-tab class="border-0">
                <template v-slot:title>
                  <span class="d-inline-block d-none">
                   <!--<i class="bx bx-history font-size-16"></i>-->
                  </span>
                  <span class="d-sm-inline-block"> Stats</span>
                </template>
                <b-card class="border-radius-1em shadow-none">
                <b-row>
                  <b-col md="12">
                    <h5 class="d-flex">User Sales Stats <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
                    <div class="row">
                        <div class="col-lg-7">
                            <div class="text-muted">
                                <div>
                                    <p>From {{ordersstartdate | luxon}} to {{ordersenddate | luxon}}</p>
                                </div>
                            </div>
                            <div class="">
                            <div class="input-group input-group mr-5">
                                <b-form-input type="date" v-model="ordersstartdate">
                                </b-form-input>
                
                                <b-form-input type="date" v-model="ordersenddate">
                                </b-form-input>
                                <a class="btn btn-light btn-rounded" @click="loadstatsOrders()">
                                    Update
                                </a>
                            </div>
                        </div>
                        <div class="row mt-2">
                          <div class="col-md-3">
                            <div class="input-group input-group mr-5">
                                <b-form-group label="Chart Display">
                                
                                        <a class="btn btn-light btn-sm" @click="chartDisplay = 'by-order', compileOrderChart()"> 
                                            By Order
                                        </a>
                                        <a class="btn btn-light btn-sm" @click="chartDisplay = 'by-date', compileChartByDate()"> 
                                            By Date
                                        </a>
                                
                                </b-form-group>
                                
                            </div>
                        </div>
                        <div class="col-md-9">
                            <div class="input-group input-group mr-5">
                                  <b-form-group label="Date Range">
                                    <a class="btn btn-light btn-sm" @click="updateDates(1)">
                                      This Month
                                    </a>
                                    <a class="btn btn-light btn-sm" @click="updateDates(1)">
                                      Last Month
                                    </a>
                                    <a class="btn btn-light btn-sm" @click="updateDates(3)">
                                      Last 3 Months
                                    </a>
                                    <a class="btn btn-light btn-sm" @click="updateDates(6)">
                                      Last 6 Months
                                    </a>
                                    <a class="btn btn-light btn-sm" @click="updateDates(12)">
                                      Last 12 Months
                                    </a>
                                  </b-form-group>
                                </div>
                            </div>
                        </div>
                        </div>
                                        <div class="col-lg-3 text-right">
                          <h4  v-if="(orderDataLoading == false)">£{{parseFloat(statsOrders[0].total).toFixed(2)}}</h4>
                                   
                                    <div v-if="orderDataLoading == false"><span class="badge font-size-12 mr-1" :class="{'badge-soft-success' : (((parseFloat(lineChart.series[0].data[lineChart.series[0].data.length - 1]) - parseFloat(statsOrders[1].total)) / parseFloat(statsOrders[1].total)) * parseFloat(100)).toFixed(2) > 0, 'badge-soft-danger': (((parseFloat(lineChart.series[0].data[lineChart.series[0].data.length - 1]) - parseFloat(statsOrders[1].total)) / parseFloat(statsOrders[1].total)) * parseFloat(100)).toFixed(2) < 0}"> 
                                    <span v-if="(((parseFloat(lineChart.series[0].data[lineChart.series[0].data.length - 1]) - parseFloat(statsOrders[1].total)) / parseFloat(statsOrders[1].total)) * parseFloat(100)).toFixed(2) > 0">+</span>{{(((parseFloat(statsOrders[0].total) - parseFloat(statsOrders[1].total)) / parseFloat(statsOrders[1].total)) * parseFloat(100)).toFixed(2) }} %</span> From previous period</div>
                                     <b-spinner v-else variant="primary" type="grow">
                                     </b-spinner>
                        </div>
                         <div class="col-lg-2 text-right">
                          <div >
                                    
                                    <h4 v-if="orderDataLoading == false">£{{statsOrders[1].total.toFixed(2)}}</h4>
                                    <b-spinner v-else variant="primary" type="grow">
                                     </b-spinner>
                                     <p class="mb-2">Previous Period</p>
                                </div>
                         </div>
                        <div class="col-lg-12">
                            <apexchart v-if="orderDataLoading == false" class="apex-charts" :options="lineChart.chartOptions" :series="lineChart.series" dir="ltr" height="460"/>
                            <b-spinner v-else variant="primary" type="grow">
                            </b-spinner>
                        </div>
                    </div>
                  </b-col>
                 
                </b-row>
              </b-card>
              </b-tab>
              <b-tab class="border-0">
                <template v-slot:title>
                  <span class="d-inline-block d-none">
                   <!--<i class="bx bx-history font-size-16"></i>-->
                  </span>
                  <span class="d-sm-inline-block"> Sales</span>
                </template>
                <b-card class="border-radius-1em shadow-sm">
             
          
    
             <b-row>
              <b-col md="2">
                <h5 class="d-flex">User Sales <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
                <h3 class="text-primary mt-4">{{statsOrders[0].order_count}}</h3>
                <small>Sales This Month</small>
                <hr>
                <h3 class="text-primary mt-4">{{statsOrders[1].order_count}}</h3>
                <small>Sales Last Month</small>
                <hr>
                <h3 class="text-primary mt-4">{{userData.sales.length}}</h3>
                <small>Total Sales</small>
                <hr>
                <!--
                <h3 class="text-primary mt-4" v-if="product.listings.length > 0">{{listingsTotalQuantities}}</h3>
                <small>Quantity Assigned to Shops</small>
                <hr>
                -->
              </b-col>
              <b-col md="10">
                <b-card class="border-radius-1em border shadow-none">
                    <OrderList v-if="latestorders !== ''" :orderData="latestorders">
                    </OrderList>
                    <b-pagination v-if="latestorders !== ''" v-model="latestordersPage"
                    :total-rows="latestorders.total" :per-page="latestorders.per_page" @input="getUserSales(userData.id)">
                    </b-pagination>
                </b-card>
              </b-col>
            </b-row>
          </b-card>
              </b-tab>
              <b-tab class="border-0">
                <template v-slot:title>
                  <span class="d-inline-block d-none">
                   <!--<i class="bx bx-history font-size-16"></i>-->
                  </span>
                  <span class="d-sm-inline-block"> Shops</span>
                </template>
                <div class="card border-radius-1em">
                  <div class="card-body">
                  <b-row>
                    <b-col md="2">
                      <h5 class="d-flex">User Shops <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
                      <h3 class="text-primary mt-4">{{userData.listings.length}}</h3>
                      <small>Shops where Staff</small>
                      <hr>
                      <h3 class="text-primary mt-4">{{userData.manages_listings.length}}</h3>
                      <small>Shops where Manager</small>
                      <hr>
                      
                    </b-col>
                    <b-col md="10">
                         <gmap-map v-if="userData.listings.length > 0" :center="mapcenter" :zoom="6" style="height: 450px" class="border-radius-1em">
                            <gmap-marker

                               v-for="locationMap in userData.listings"
                              :key="index"
                              :position="{ lat: parseFloat(locationMap.listing.latitude), lng: parseFloat(locationMap.listing.longitude) }"
                              :clickable="true"
                              :draggable="true"
                              @click="center = { lat: parseFloat(locationMap.listing.latitude), lng: parseFloat(locationMap.listing.longitude) }"
                            ></gmap-marker>
                          </gmap-map>

                          <b-row class="mt-3">
                            <div class="col-xl-4"  v-for="list in userData.listings" :key="list.id">
      <b-modal :id="'listing-info-'+list.listing.uid" hide-footer hide-header content-class="border-none border-radius-1em overflow-hidden" body-class="p-0" size="lg" centered>
    <a class="btn btn-light btn-rounded position-absolute z-index-2 top-0 right-0 m-3" @click="$bvModal.hide('listing-info-'+list.listing.uid)">
      <i class="mdi mdi-close"></i>
    </a>
    <gmap-map :center="{ lat: parseFloat(list.listing.latitude), lng: parseFloat(list.listing.longitude) }" :zoom="7" style="height: 350px" class="border-radius-1em">
                  <gmap-marker

                    :position="{ lat: parseFloat(list.listing.latitude), lng: parseFloat(list.listing.longitude) }"
                    :clickable="true"
                    :draggable="true"
                    @click="center = { lat: parseFloat(list.listing.latitude), lng: parseFloat(list.listing.longitude) }"
                  ></gmap-marker>
                </gmap-map>

       <b-row>
        <b-col>
          <b-card class="shadow-none border-none mb-0">
            <div class="d-flex">
                  <h4 class="card-title">{{list.listing.name}}</h4>

                  <a class="btn btn-light btn-rounded ml-auto" :href="'tel:'+list.listing.phone">
                   Call Now
                  </a>
                  <a class="btn btn-light btn-rounded ml-2" :href="'https://www.google.com/maps/dir/?api=1&query_place_id='+list.listing.gplace_id+'&zoom=12'">
                    Get Directions
                  </a>
            </div>
          <p class="text-muted my-1 d-flex">
                  <span class="my-auto mr-2"><i class="bx bx-home font-size-20 text-primary"></i></span>
                  <span class="my-auto mr-1" v-if="list.listing.address_1 !== null">{{list.listing.address_1}},</span>
                  <span class="my-auto mr-1" v-if="list.listing.address_2 !== null">{{list.listing.address_2}},</span>
                  <span class="my-auto mr-1" v-if="list.listing.address_3 !== null">{{list.listing.address_3}},</span>
                  <span class="my-auto mr-1" v-if="list.listing.city !== null">{{list.listing.city}},</span>
                  <span class="my-auto mr-1" v-if="list.listing.county !== null">{{list.listing.county.name}},</span>
                  <span class="my-auto mr-1" v-if="list.listing.postcode !== null">{{list.listing.postcode}}</span>
                  <span class="my-auto mr-1" v-if="list.listing.country !== null">{{list.listing.country.name}}</span>
                  
                </p>
                <p class="d-flex" >
                  <span class="my-auto"><i class="bx bx-phone text-primary font-size-20"></i></span>
                  <span class="my-auto ml-2 text-muted">{{list.listing.phone}}</span>

                </p>
                  <!--<p  class="text-muted ml-auto my-auto mt-3 mr-3">
                          <span class="bx bx-star font-size-14 text-warning"></span>
                          <span class="bx bx-star font-size-14 text-warning ml-1"></span>
                          <span class="bx bx-star font-size-14 text-warning ml-1"></span>
                          <span class="bx bx-star font-size-14 text-warning ml-1"></span>
                          <span class="bx bx-star font-size-14 ml-1"></span>
                        </p>
                 <h6>Location Reviews</h6>
                 <div v-if="listing.reviews.length > 0">
                     <simplebar style="max-height: 380px;">         
                      <b-card class="border-radius-1em border shadow-none">
                          <b-list-group>
                            <b-list-group-item v-for="review in listing.reviews">
                            </b-list-group-item>
                          </b-list-group>
                      </b-card>
                    </simplebar>
                  </div>
                  <div v-else>
                    <b-card class="border-radius-1em border shadow-none" body-class="text-center">
                          <i class="bx bx-star text-warning d-inline-block font-size-14"></i>
                          <i class="bx bx-star text-warning d-inline-block font-size-20"></i>
                          <i class="bx bx-star text-warning d-inline-block font-size-28"></i>
                          <i class="bx bx-star text-warning d-inline-block font-size-20"></i>
                          <i class="bx bx-star text-warning d-inline-block font-size-14"></i>
                          <h4 class="text-muted">
                            No Reviews
                          </h4>
                    </b-card>
                  </div>-->
           </b-card>
        </b-col>
       </b-row>
  </b-modal>
      <b-card class="card overflow-hidden border-radius-1em shadow-sm seller-profile-card" no-body @mouseenter="hoverIndex = index" @mouseleave="hoverIndex = -1">
          <div class="bg-soft bg-primary" style="min-height: 150px;">
            <div class="row">
            <div class="col-12">
              <a :href="'/company/shop/'+list.listing.uid">
                  <div v-if="list.listing.banner !== null">
                    <b-card-img-lazy :src="storageurl + list.listing.banner.src" alt class="img-fluid" /></b-card-img-lazy>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <b-card-body class="card-body pt-0">
            <div class="row">
              <div class="col-sm-12 pb-4 text-center">
                <div class="avatar-md profile-user-wid mb-2 mx-auto" style="margin-top: -45px !important;">
                  
                  <a href="javascript:;" class="avatar-title avatar-md bg-light text-primary rounded-circle" v-b-tooltip.hover title="View Map"  @click="$bvModal.show('listing-info-'+list.listing.uid)">
                    <i class="bx bx-map-pin font-size-20"></i>
                  </a>
                </div>
                <h5 class="font-size-15 text-truncate">{{list.listing.name}}</h5>
                <p class="text-muted mb-0 paragraph-truncate-line2">
                  <span v-if="list.listing.address_1 !== null">{{list.listing.address_1}},</span>
                  <span v-if="list.listing.address_2 !== null">{{list.listing.address_2}},</span>
                  <span v-if="list.listing.address_3 !== null">{{list.listing.address_3}},</span>
                  <span v-if="list.listing.city !== null">{{list.listing.city}},</span>
                  <span v-if="list.listing.county !== null">{{list.listing.county.name}},</span>
                  <span v-if="list.listing.postcode !== null">{{list.listing.postcode}}</span>
                  <span v-if="list.listing.country !== null">{{list.listing.country.name}}</span>
                </p>
              </div>
              <div class="col-md-12 d-flex">
                <span class="badge badge-light border-radius-1em bg-light text-dark font-size-11 lh-16 my-auto p-2 mx-auto" v-if="list.listing.listing_type !== null">
                  {{list.listing.listing_type.name}}
                </span>
              </div>
             
             
                            </div>
                          </b-card-body>
                        </b-card>
                      </div>
                 </b-row>
               </b-col>
             </b-row>
                </div>
                </div>
              </b-tab>
              <b-tab class="border-0">
                <template v-slot:title>
                  <span class="d-inline-block d-none">
                   <!--<i class="bx bx-history font-size-16"></i>-->
                  </span>
                  <span class="d-sm-inline-block"> Teams</span>
                </template>
                 <div class="card border-radius-1em shadow-sm">
                  <div class="card-body">
                    <b-row>
                      <b-col md="2">
                     <h5 class="d-flex">Teams <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
                      </b-col>
                      <b-col md="10">
                    <b-list-group v-if="(userData.teams.length > 0)">
                    <b-list-group-item v-for="team in userData.teams"  v-if="(team.team !== null)" :href="'/company/team/'+team.team.uid" v-b-tooltip.hover :title="team.team.name" ><div class="avatar-xs d-inline-block mr-3"><span class="avatar-title rounded-circle bg-warning">{{(team.team.name).slice(0,1)}}</span></div>{{team.team.name}}</b-list-group-item>
                    </b-list-group>
                    <!--<SearchCompanyUser :api_token="this.$attrs.user.api_token" :id="userData.manager.user" @user-selected="getManager" @input="getManager" />-->
                  </b-col>
                </b-row>
                    </div>
                  </div>
              </b-tab>
              <b-tab class="border-0">
                <template v-slot:title>
                  <span class="d-inline-block d-none">
                   <!--<i class="bx bx-history font-size-16"></i>-->
                  </span>
                  <span class="d-sm-inline-block"> Departments</span>
                </template>
                <div class="card border-radius-1em shadow-sm">
                  <div class="card-body">
                    <b-row>
                      <b-col md="2">
                     <h5 class="d-flex">Departments <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
                      </b-col>
                      <b-col md="10">
                        <b-list-group  v-if="userData.manages_departments !== null" >
                          <b-list-group-item v-for="department_manager in userData.manages_departments" :href="'/company/department/'+department_manager.uid" v-b-tooltip.hover :title="department_manager.name+' manager'" >
                                    <div class="avatar-xs d-inline-block mr-3"><span class="avatar-title rounded-circle bg-success">{{(department_manager.name).slice(0,1)}}</span></div>
                                    {{department_manager.name}}
                                  </b-list-group-item>
                        </b-list-group>

                        <b-list-group  v-if="userData.departments !== null">
                          <b-list-group-item v-for="item in userData.departments" v-if="item.department !== null" :href="'/company/department/'+item.department.uid" v-b-tooltip.hover :title="'Member of '+item.department.name" ><div class="avatar-xs d-inline-block mr-3"><span class="avatar-title rounded-circle">{{(item.department.name).slice(0,1)}}</span></div>
                            {{item.department.name}}
                          </b-list-group-item>
                        </b-list-group>
                       </b-col> 
                    </b-row>
                    
                    </div>
                  </div>
              </b-tab>
               <b-tab class="border-0">
                <template v-slot:title>
                  <span class="d-inline-block d-none">
                   <!--<i class="bx bx-history font-size-16"></i>-->
                  </span>
                  <span class="d-sm-inline-block"> Permissions</span>
                </template>
                <div class="card border-radius-1em shadow-sm">
          <div class="card-body">
                   <b-row>
                <b-col md="2">
                  <h5 class="d-flex">User Permissions <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
                      <h3 class="text-primary mt-4">{{userData.userrole.name}}</h3>
                      <small>User Role</small>
  
                     <a class="btn btn-success mt-2 my-auto w-100 btn-rounded" @click="updateCompanyUserPermissions(userData.id)">Update Permissions</a>
                     <a class="btn btn-info my-auto mt-2 w-100 btn-rounded" @click="getFactoryUserRolePermissions(userData.userrole.id)">Use Factory Settings</a>
                </b-col>
                <b-col md="10">
                  <UserPermissions :permission_resources="$root.menu" :permissions="permissions"></UserPermissions>
                </b-col>
              </b-row>
            </div>
          </div>
              </b-tab>
        </b-tabs>
        <b-modal id="pos-login-modal" hide-footer centered :size="posModalSize">

          <b-card class="card border-radius-1em border shadow-none" v-if="(userData.pos_login !== null) && (pos_code_type == 'QR')" >
            <b-row>
              <b-col md="6">
                <h4>Login QR Code</h4>
                <p>Your member of staff can use this QR code to login to the point of sale. This will need to be used in combination with their ePOS pin</p>

                <div class="d-flex">
                  <a class="btn btn-info">
                    Download QR Image
                  </a>
                </div>
              </b-col>
              <b-col md="6">
                <div id="qr-div">
                  <qrcode-vue :value="JSON.stringify({type: ''+$attrs.company.uid.slice(0, 5)+'-pos-login', reference: '', uid: '', id: $attrs.company.uid.slice(0, 5)+'--'+userData.pos_login.pos_id+'--'+userData.uid.slice(0,5)+'--'+userData.user.id})" :size="300" level="L" foreground="#000000" background="#ffffff" margin="0" class="px-1" style="margin-bottom: -2px; margin-top: -2px;" />
                </div>
              </b-col>
            </b-row>
          </b-card>
          <b-card class="card border-radius-1em border shadow-none" v-if="(userData.pos_login !== null) && (pos_code_type == 'barcode')" >
            <b-row>
                <b-col md="6">
                  <h4>Login Barcode</h4>
                <p>Your member of staff can use this barcode to login to the point of sale. This will need to be used in combination with their ePOS pin</p>
                 <div class="d-flex">
                  <a class="btn btn-info">
                    Download Barcode Image
                  </a>
                </div>
                </b-col>
              <b-col md="6">
                <VueBarcode  :value="$attrs.company.uid.slice(0, 5)+'--'+userData.pos_login.pos_id+'--'+userData.uid.slice(0,5)+'--'+userData.user.id" :options="{ displayValue: true, format: 'CODE128', lineColor: '#000000', width: 1, height: 120, background: '#FFFFFF', fontSize: 10, marginTop: 0 }"></VueBarcode>
            </b-col>
          </b-row>
          </b-card>
           <b-card class="card border-radius-1em border shadow-none" v-if="(userData.pos_login !== null) && (pos_code_type == 'design')" >
              <LoginCard :address="{area: 'front', pos_user: userData.pos_login, user: userData.user}" :addresses="[{area: 'front', pos_user: userData.pos_login, user: userData.user}, {area:'back', pos_user: userData.pos_login, user: userData.user}]" :qr_value="JSON.stringify({type: ''+$attrs.company.uid.slice(0, 5)+'-pos-login', reference: '', uid: userData.uid, id: $attrs.company.uid.slice(0, 5)+'--'+userData.pos_login.pos_id+'--'+userData.uid.slice(0,5)+'--'+userData.user.id})" :barcode_value="$attrs.company.uid.slice(0, 5)+'--'+userData.pos_login.pos_id+'--'+userData.uid.slice(0,5)+'--'+userData.user.id" :company="$attrs.company" ></LoginCard>
           </b-card>
        </b-modal>
      </div>
      </div>
      <!-- end col -->
    <!-- end row -->
    </div>
    <SideSlidePanel :expandable="1" :editPanel="actionPanel" :panelClass="actionPanelClass" @close-panel="actionPanel = false, actionPanelClass = 'edit-sidepanel'" @expand="actionPanelClass = 'edit-sidepanel-fullwidth'" @contract="actionPanelClass = 'edit-sidepanel'" >
      <ActionView :viewAction="viewAction" :api_token="this.$attrs.user.api_token" :activetab="viewActionTab" />
    </SideSlidePanel>
    <SideSlidePanel :editPanel="newActionPanel" :panelClass="newActionPanelClass" @close-panel="newActionPanel = false, newActionPanelClass = 'edit-sidepanel', resetActionData()" :expandable="1" @expand="newActionPanelClass = 'edit-sidepanel-fullwidth'" @contract="newActionPanelClass = 'edit-sidepanel'">
                <b-row>
              <b-col md="12" class="mb-4">
                <AddNewAction :api_token="this.$attrs.user.api_token" :newAction="newAction" :addPanel="newActionPanel" :statuses="statuses"  :view="'add'" :user="$attrs.user" :assignee="userData" /> 
              </b-col>
              
          </b-row>           
    </SideSlidePanel>
  </Layout>
</template>
