<script type="text/javascript">
export default{
		data(){
      return{
        csrf:  document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
      	newsupplier: {
        id: null,
        name: '',
        description: '',
        image: '',
        active: 0,
      },
      countries: [],
      counties: [],
      loadingForm: false,
      error_reponse: [],
      error_message: false,
      }
    },
    props: {
    	supplier_id: {
    		type: Number,
    		default: () => 0
    	},
    	api_token: {
    		type: String,
    		default: () => ''
    	},

    },
    methods: {

    	addSupplier(){
	      this.loadingForm = true;
	      var postForm = new FormData(document.getElementById("addsupplierform"));
	      axios.post('/api/company/product/supplier/create', postForm, {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
              }}).then(response => {
	        console.log(response);
	        this.$emit('supplier-added', response.data);
	        //this.success_text = 'Congratulations, your new category has been added.';
	        //this.success_message = true;
	        //this.resetProjectData();
	        //this.addPanel = false;
	        let self = this;
	          setTimeout(function(){
	            self.loadingForm = false;
	            self.addNewPanel = false;
	            self.productQuery();
	          }, 1500);
	      }).catch( error => {
	        
	        this.error_reponse = error.response.data.errors;
	        this.error_message = true;
	        //this.resetProjectData();
	        let self = this;
	          setTimeout(function(){
	            self.loadingForm = false;
	          }, 1500);
	      });
	    },
      sanitizeTitle: function(title) {
            var nickname = "";
            // Change to lower case
            var titleLower = title.toLowerCase();
            // Letter "e"
            nickname = titleLower.replace(/e|é|è|ẽ|ẻ|ẹ|ê|ế|ề|ễ|ể|ệ/gi, 'e');
            // Letter "a"
            nickname = nickname.replace(/a|á|à|ã|ả|ạ|ă|ắ|ằ|ẵ|ẳ|ặ|â|ấ|ầ|ẫ|ẩ|ậ/gi, 'a');
            // Letter "o"
            nickname = nickname.replace(/o|ó|ò|õ|ỏ|ọ|ô|ố|ồ|ỗ|ổ|ộ|ơ|ớ|ờ|ỡ|ở|ợ/gi, 'o');
            // Letter "u"
            nickname = nickname.replace(/u|ú|ù|ũ|ủ|ụ|ư|ứ|ừ|ữ|ử|ự/gi, 'u');
            // Letter "d"
            nickname = nickname.replace(/đ/gi, 'd');
            // Trim the last whitespace
            nickname = nickname.replace(/\s*$/g, '');
            // Change whitespace to "-"
            nickname = nickname.replace(/\s+/g, '-');
            // Remove quotations
            nickname = nickname.replace(/["']/g, "");
            // Remove symbols
            nickname = nickname.replace(/@|#|!/g, '');
            return nickname;
        }
    }
}
</script>

<template>
	 <b-overlay
                        id="overlay-background"
                        :show="loadingForm"
                        variant="white"
                        opacity="0.85"
                        blur="1px"
                        rounded="md"
                      >
                       <b-form id="addsupplierform" method="POST" enctype="multipart/form-data">
                   <input type="hidden" name="_token" :value="csrf">
           <b-row>
                     <b-col md="12">
                    <transition name="slide-error" mode="out-in">
                    <b-alert id="errors" variant="danger" :show="error_message"  dismissible @dismissed="error_message = !error_message">
                      <p class="font-size-14">Oops! Please correct the following errors in your submission</p>
                      <ul>
                        <li v-for="(error, key) in error_reponse">
                          {{error[0]}}
                        </li>
                      </ul>
                    </b-alert>
                    
                  </transition>
                  </b-col>
          </b-row>
               <b-form-group label="Name">
            <b-form-input type="text" name="name" v-model="newsupplier.name"></b-form-input>
            <input type="hidden" name="slug" :value="sanitizeTitle(newsupplier.name)">
          </b-form-group>
           <b-form-group class="mt-2" label="Description">
            <b-textarea rows="6" v-model="newsupplier.description" name="description"></b-textarea>
          </b-form-group>
          <b-form-group class="mt-2" label="Logo">
            <input class="form-control mb-2" name="logo" type="file">
          </b-form-group>
          <b-form-group class="my-3">
                     <div
                        class="form-check form-switch form-switch-sm mb-0 mt-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          id="newbrand-owner"
                          v-model="newsupplier.active" value="true"
                          unchecked-value="false"
                          name="active"
                        />
                        <label class="form-check-label" for="newbrand-owner" 
                          > Active</label
                        >
                    </div>
                    <input type="hidden" :value="newsupplier.active" name="active">
          </b-form-group>
          <div class="d-flex flex-row mt-3">
          <b-button variant="success" class="w-md w-100" @click="addSupplier()"> <i class=" bx bx-save"></i> Save</b-button>
          </div>
        </b-form>
     </b-overlay>
</template>