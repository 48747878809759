<script type="text/javascript">
	import ProductsListWidget from '../web/ProductsListWidget';

	export default{
		name: 'CategoryGrid',
		props: {
			id: '',
		},
		components: { ProductsListWidget },
		created(){
			if(this.widgetfilters.active_filters.categories.value.length == 0){
				this.widgetfilters.active_filters.categories.value.push(this.$attrs.product.id);
			}
		},
		mounted(){
			if(this.widgetfilters.active_filters.categories.value.length == 0){
				this.widgetfilters.active_filters.categories.value.push(this.$attrs.product.id);
			}
		},
		data() {
			return {
				widgetfilters: {
                    filter: {search: {active: 0, type: 'hidden', value: ''}, categories: {active: 0, type: 'customselect', value: ''}, collections: {active: 0, type: 'customselect', value: []}, price_range: {active: 0, type: 'multirange', value: [0,999999]}, page: {active: 0, type: 'number', value: 1}, limit: {active: 0, type: 'range', value: 16}, order: {active: 0, type: 'select', value: 'DESC', options: ['ASC','DESC']}, sortby: {active: 0, type: 'select', value: 'id', options: ['id', 'name', 'created_at', 'updated_at']}},
                    active_filters: {search: {active: 0, type: 'hidden', value: ''}, categories: {active: 0, type: 'customselect', value: []}, collections: {active: 0, type: 'customselect', value: []}, price_range: {active: 0, type: 'multirange', value: [0,999999]}, page: {active: 0, type: 'number', value: 1}, limit: {active: 0, type: 'range', value: 16}, order: {active: 0, type: 'select', value: 'DESC', options: ['ASC','DESC']}, sortby: {active: 0, type: 'select', value: 'id', options: ['id', 'name', 'created_at', 'updated_at']}},
              },
			}
		}
	}

</script>

<template>
	<div>	
		<ProductsListWidget v-if="$attrs.product !== ''" :id="id" :company="$attrs.company" :user="$attrs.user" :liveMode="$attrs.liveMode" :content="$attrs.content" :configs="$attrs.configs" :filters="widgetfilters.active_filters" :elements="$attrs.elements"  :exchange_rates="$attrs.exchange_rates"  :currencies="$attrs.currencies" :selected_currency="$attrs.selected_currency" :selected_currency_symbol="$attrs.selected_currency_symbol" ></ProductsListWidget>
	</div>
</template>