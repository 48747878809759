<template>
  <Layout :nickname="$attrs.nickname" :user="$attrs.user">
    <PageHeader :title="title" :items="items"  />
    <b-row>
       <b-col md="12">
      <transition name="slide-error" mode="out-in">
                    <b-alert id="errors" variant="danger" :show="error_message == true"  dismissible @dismissed="error_message = !error_message">
                      <p class="font-size-14">Oops! Please correct the following errors in your submission</p>
                      <ul>
                        <li v-for="(error, key) in error_reponse">
                          {{error[0]}}
                        </li>
                      </ul>
                      <small>Please Try Again. If this error persists, please contact support.</small>
                    </b-alert>
                    
                  </transition>
                </b-col>
                <b-col md="12">
                    <transition name="slide-success" mode="out-in">
                  <b-alert id="success-message" variant="success" :show="success_message"  dismissible @dismissed="success_message = !success_message">
                      <p class="font-size-14">{{success_messagetext}}</p>
                    </b-alert>
                  </transition>
                  </b-col>
      <b-col md="12">
        <b-card class="border-radius-1em border shadow-none">
          <div class="row mb-2">
          <div class="col-xl-1 col-sm-6">
            <div class="mt-2">
              <b-form-select class="form-control" v-model="limit" :options="limits" @input="getMenus()"></b-form-select>
            </div>
          </div>
         
          <div class="col-lg-2 col-sm-6 d-flex">
          <form class="my-auto mr-auto">
            <small class="mx-auto my-2 text-center d-block">Results {{menus.from}} - {{menus.to}} of {{menus.total}}</small>
          </form>
           <div  class="text-center my-auto ml-auto"><div
                        class="form-check form-switch form-switch-sm mb-0 mr-auto p-0 mt-auto d-flex"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input mx-auto my-auto" 
                          type="checkbox"
                          id="SwitchCheckSizeCheckall"
                         
                          v-model="isCheckAll"
                          @click='checkAll()'
                          name="active"
                        />
                        <label class="d-inline-block my-auto ml-2" for="SwitchCheckSizeCheckall">Select All</label>
                    </div></div>
        </div>
        <div class="col-lg-3 d-flex">
         
          <b-form-group class="my-auto ml-auto mr-3">
            <b-form-radio-group id="btn-radios-1" button-variant="outline-light"
        size="md" v-model="marketplace_brands" :options="quickfilter_options" buttons @input="getMenus()"></b-form-radio-group>
      
          
          </b-form-group>
        </div>
        <div class="col-lg-3 col-sm-6 d-flex">
          
            <div class="mt-sm-0 form-inline w-100" >
              <div class="search-box mr-2 ml-auto">
                <div class="position-relative">
                  <input
                    type="text"
                    class="form-control w-100 border-0"
                    placeholder="Search name, reference or description..."
                    v-model="search"
                    @keyup.enter="getMenus()"
                  />
                  <i class="bx bx-search-alt search-icon"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-sm-6 d-flex">
            <div class="d-flex ml-auto">
              <ul class="nav nav-pills product-view-nav">
                
               
                <li class="nav-item">
                 <b-dropdown text="Bulk Actions" variant="primary" class="rounded">
                   <b-dropdown-item href="javascript: void(0);" @click="setActive(true)"><i class="bx bx-check-circle font-size-14 text-success"></i> Set Active</b-dropdown-item>
                  <b-dropdown-item href="javascript: void(0);" @click="setActive(false)"><i class="bx bx-x-circle font-size-14 text-danger"></i> Set Inactive</b-dropdown-item>
                  <b-dropdown-item href="javascript: void(0);" @click="bulkDuplicateResource()"><i class="bx bx-copy-alt font-size-14"></i> Duplicate</b-dropdown-item>
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-item href="javascript: void(0);" @click="deleteMode = 'bulk', deleteModal = true"><i class="bx bx-trash-alt font-size-14"></i> Delete</b-dropdown-item>
                </b-dropdown>
                </li>
                <li class="nav-item">
                 <a
                    class="btn btn-success btn-rounded mb-2 mr-2 text-white"
                    href="javascript:;"
                    @click="addUpdateMenu = !addUpdateMenu"
                  >
                    <i class="mdi mdi-plus mr-1"></i> New
                  </a>
                </li>
              </ul>
              <div class="text-sm-right">
                  
                </div>
          </div>
        </div>
      </div>
          
          <b-modal id="add-menu" v-model="addUpdateMenu" hide-footer hide-header centered >
            <b-card class="border-radius-1em border shadow-none" v-if="addUpdateMenu == true">
              <h4>Add New Menu</h4>
              <b-form>
                <b-form-group label="Name">
                  <b-form-input type="text" v-model="menu.name"></b-form-input>
                </b-form-group>

                <b-form-group class="mt-2" label="Description">

                  <b-textarea :rows="5" type="description" v-model="menu.description"></b-textarea>
                </b-form-group>

                <!--<b-form-group class="mt-2" label="Menu Position">
                  <b-form-select class="form-control" v-model="menu.company_web_menu_position_id">
                    <b-form-select-option v-for="position in menupositions.data">
                      {{position.name}}
                    </b-form-select-option>
                  </b-form-select>
                </b-form-group>-->

                <a class="btn btn-success btn-rounded btn-block mt-3" @click="createMenu()">
                  Save
                </a>
              </b-form>
            </b-card>
          </b-modal>
          <b-list-group>
            <b-overlay :show="loadingMenus">
            <b-list-group-item v-for="menu in menus.data">
              <b-row>
                <b-col class="d-flex flex-0">
                  <div class="text-center"  >
                        <b-form-checkbox class="select-row-btn" v-model="selectedids" :value="menu.id" button-variant="light" @input="updateSelected" button>

                          <i v-if="filterSelected(menu.id)" class="bx bx-check"></i>
                          <!--<span class="avatar-title rounded-circle bg-light" v-b-tooltip.hover title="Homepage" v-else-if="menu.default == 1"><i class="bx bx-star text-warning font-size-18 my-auto mx-auto"></i></span>-->
                          <span class="avatar-title rounded-circle" v-else>{{(menu.name).slice(0,1)}}</span></b-form-checkbox>
                    </div> 
                </b-col>
                <b-col class="d-flex" style="max-width: 200px;">
                 <a :href="'/web/menu/'+menu.uid" class="my-auto">{{menu.name}}</a>
                </b-col>
                <b-col class="d-flex">
                 <p class="my-auto">{{menu.description}}</p>
                </b-col>
                <!--<b-col class="text-center">
                  <a class="btn btn-light rounded-circle">
                    <i class="bx bx-list-ul"></i>
                  </a>
                </b-col>-->
                <b-col class="d-flex">
                   <div
                        class="form-check form-switch form-switch-sm my-auto ml-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          :id="`SwitchCheckSize-${menu.id}`"
                          v-model="menu.active"
                          :value="menu.active"
                          name="active"
                          
                          @change="selectedids = [], selectedids.push(menu.id), setActive(menu.active)"
                        />
                        <label class="form-check-label" :for="`SwitchCheckSize-${menu.id}`" 
                          >Active</label
                        >
                    </div>
                </b-col>
                <b-col class="d-flex">

                  <a class="btn btn-primary rounded-circle ml-auto my-auto" :href="'/web/menu/'+menu.uid">
                    <i class="bx bx-pencil"></i>
                  </a>
                  <b-button variant="outline-light" :disabled="menu.default == 1" href="javascript:;" @click="selectedids = [], selectedids.push(menu.id), makeDefault(menu.active)" class="btn btn-outline-light btn-rounded ml-2 my-auto"><i class="bx bx-star my-1" :class="{'text-warning' : menu.default == 1}"></i></b-button>
                  <a class="btn btn-danger rounded-circle ml-2 my-auto" @click="selectedmenu = menu, deleteModal = true">
                    <i class="bx bx-trash"></i>
                  </a>
                  <a class="btn btn-outline-light rounded-circle ml-2 my-auto">
                    <i class="bx bx-dots-horizontal-rounded"></i>
                  </a>
                </b-col>
              </b-row>
            </b-list-group-item>
          </b-overlay>
          </b-list-group>
        </b-card>
      </b-col>
      <!--<b-col md="4">
        <b-card class="border-radius-1em border shadow-none">
          
          <div class="d-flex pb-2">
          <transition name="fade-top">
            <h6 class="w-75 my-auto" v-if="searchpositionbox == false">Menu Positions</h6>
          </transition>
          <transition name="fade-right">
            <div class="mt-sm-0 form-inline w-75" v-if="searchpositionbox == true">
              <div class="search-box mr-2 ml-auto my-auto">
                <div class="position-relative">
                  <input
                    type="text"
                    class="form-control w-100 border-0 bg-light"
                    placeholder="Search name, reference or description..."
                    v-model="searchmenu" @keydown.enter="getCompanyTradeListings()" 
                  />
                  <i class="bx bx-search-alt search-icon"></i>
                </div>
              </div>
            </div>
          </transition>

            <a class="btn btn-light btn-rounded ml-auto my-auto mr-2" @click="searchpositionbox = !searchpositionbox">
              <i class="bx bx-search-alt"></i>
            </a>
            <a class="btn btn-success btn-rounded my-auto" @click="addUpdateMenuPosition = !addUpdateMenuPosition">
              <i class="mdi mdi-plus"></i>
            </a>
          </div>
          <transition name="fade-top">
            <b-card class="border-radius-1em border shadow-none" v-if="addUpdateMenuPosition == true">
              <b-form>
                <b-form-group label="Name">
                  <b-form-input type="text" v-model="menuposition.name"></b-form-input>
                </b-form-group>

                <b-form-group class="mt-2" label="Order">
                  <b-form-input type="number" v-model="menuposition.order"></b-form-input>
                </b-form-group>


                <a class="btn btn-success btn-rounded btn-block mt-3" @click="createMenuPosition()">
                  Save
                </a>
              </b-form>
            </b-card>
          </transition>
          <b-list-group>
            <b-list-group-item v-for="position in menupositions.data">
              <b-col class="d-flex">
                 <p class="my-auto">{{position.name}}</p>
                </b-col>
                
                <b-col class="d-flex">
                  <a class="btn btn-primary rounded-circle ml-auto my-auto">
                    <i class="bx bx-pencil"></i>
                  </a>
                  <a class="btn btn-danger rounded-circle ml-2 my-auto">
                    <i class="bx bx-trash"></i>
                  </a>
                </b-col>
            </b-list-group-item>
          </b-list-group>
        </b-card>
      </b-col>-->
    </b-row>
    <b-modal v-model="deleteModal" hide-footer hide-header centered>
      <div class="d-flex mb-2">
        <a class="ml-auto btn btn-outline-light btn-rounded rounded-circle" @click="deleteModal = false">
          <i class="mdi mdi-close"></i>
        </a>
      </div>
      <b-card class="border-radius-1em border shadow-none mb-0 py-4"  body-class="justify-content-center align-items-center d-flex flex-column">
        <h4 class="text-center">Are you sure you want to delete?</h4>
        <p class="text-center" v-if="selectedmenu !== ''">You are about to delete the menu: {{selectedmenu.name}}. Are you sure you want to do this?</p>
        <p class="text-center" v-else-if="selectedmenu == '' && deleteMode == 'bulk'">You are about to delete {{selectedids.length}} menu(s). Are you sure you want to do this?</p>
        <div>
           <a class="btn btn-success btn-rounded" v-if="deleteMode == 'single'" @click="deleteMenu(selectedmenu), deleteModal = false">
            Yes, delete it!
          </a>
           <a class="btn btn-success btn-rounded" v-if="deleteMode == 'bulk'" @click="bulkDestroyResource()">
            Yes, delete it!
          </a>
          <a class="btn btn-danger btn-rounded" @click="deleteModal = false, selectedmenu = ''">
            No, keep it!
          </a>
        </div>
      </b-card>
    </b-modal>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import SideSlidePanel from "../../components/widgets/SideSlidePanel"


export default {
  components: {
    Layout, PageHeader, SideSlidePanel
  },
  data() {
    return {
      title: "Menus",
      items: [
            {
              text: "Website",
              href: "/"
            },
            {
              text: "Menus",
              active: true
            }
          ],
      deleteMode: 'single',
      deleteModal: false,
      selectedmenu: '',
      searchmenus: '',
      searchpositionbox: false,
      error_reponse: '',
      error_message: false,
      success_message: false,
      success_messagetext: '',
      loadingMenus: false,
      selectedids: [],
      isCheckAll: false,
      menus: '',
      menupositions: '',
      menuposition: {
        name: '',
        order: 0,
      },
      menu: {
        name: '',
        description: '',
        company_web_menu_position_id: 0,
      },
       limits: [
        {value: 25, text: "25"},
        {value: 50, text: "50"},
        {value: 100, text: "100"},
      ],
      currentPage: 1, 
      limit: 25,
      sortby: 'id',
      order: 'DESC',
      addUpdateMenu: false,
      addUpdateMenuPosition: false,
    }
  },
  mounted(){
    this.getMenus();
    this.getMenuPositions();
    var url = window.location.href;
    if(url.includes('?view=add')){
      this.addUpdateMenu = true;
    }
  },
  methods:{
    makeDefault(active){
            axios.post('/api/company/web/menu/make-default', {ids: this.selectedids, active: active}, {headers: {
              'Authorization' : 'Bearer '+this.$attrs.user.api_token
            }}).then(response => {
              
              this.success_messagetext = 'Congratulations, your default domain has been updated!';
              this.success_message = true;
              this.selectedids = [];
                return this.getMenus();
              
                
            }).catch(error => {

            });
    },
    setActive(active){
            axios.post('/api/company/web/menu/set-active', {ids: this.selectedids, active: active}, {headers: {
              'Authorization' : 'Bearer '+this.$attrs.user.api_token
            }}).then(response => {
              
              this.success_messagetext = 'Congratulations, your action has been saved';
              this.success_message = true;
              
              return this.getMenus();
             
                
            }).catch(error => {

            });
    },
     bulkDestroyResource(){
            axios.post('/api/company/web/menu/bulk-destroy', {ids: this.selectedids}, {headers: {
              'Authorization' : 'Bearer '+this.$attrs.user.api_token
            }}).then(response => {
                this.deleteModal = false;     
                this.selectedids = [];
                return this.getMenus();
            }).catch(error => {

            });
     },
     bulkDuplicateResource(){
      axios.post('/api/company/web/menu/duplicate', {ids: this.selectedids}, {headers : {
        'Authorization' : 'Bearer '+this.$attrs.user.api_token
      }}).then(response => {
        console.log(response);
        this.success_messagetext = 'Congratulations, your collection(s) have been updated.';
        this.success_message = true;
        //this.resetProjectData();
        this.addPanel = false;
        let self = this;
          setTimeout(function(){
            self.loadingForm = false;
            self.getMenus();
            //self.$bvModal.hide('move-modal');
          }, 1500);
      }).catch( error => {
        
        this.error_reponse = error.response.data.errors;
        this.error_message = true;
        //this.resetProjectData();
        let self = this;
          setTimeout(function(){
            self.loadingForm = false;
          }, 1500);
      });
    },
    getMenus(){
        axios.get('/api/company/web/menus?page='+this.currentPage+'&limit='+this.limit+'&sortby='+this.sortby+'&order='+this.order+'', {headers: { 'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
          this.menus = response.data;
          let self = this;
          setTimeout(function(){
            self.loadingMenus = false;
          }, 500);
        }).catch(error => {
          this.error_reponse = errors.response.data.errors;
          this.error_message = true;
        });
    },
    getMenuItems(){
        axios.get('/api/company/web/menu/{id}/items?page='+this.currentPage+'&limit='+this.limit+'&sortby='+this.sortby+'&order='+this.order+'', {headers: { 'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
          this.menus = response.data;
        }).catch(error => {
          this.error_reponse = errors.response.data.errors;
          this.error_message = true;
        });
    },
    getMenuPositions(){
        axios.get('/api/company/web/menu/positions?page='+this.currentPage+'&limit='+this.limit+'&sortby='+this.sortby+'&order='+this.order+'', {headers: { 'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
          this.menupositions = response.data;
        }).catch(error => {
          this.error_reponse = errors.response.data.errors;
          this.error_message = true;
        });
    },
    createMenuPosition(){
        axios.post('/api/company/web/menu/position/create', this.menuposition, {headers: { 'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
          this.menuposition.push(response.data);
        }).catch(error => {
          this.error_reponse = errors.response.data.errors;
          this.error_message = true;
        });
    },
    createMenu(){
      this.loadingMenus = true;
        axios.post('/api/company/web/menu/create', this.menu, { headers: { 'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
          this.$bvModal.hide('add-menu');
          this.menus.data.push(response.data);
          let self = this;
          setTimeout(function(){
            self.success_messagetext = "Menu Added";
            self.success_message = true;
            return self.getMenus();
          }, 500);
        }).catch(error => {
          this.error_reponse = errors.response.data.errors;
          this.error_message = true;
          this.loadingMenus = false;
        });
    },
    deleteMenu(menu){
      axios.post('/api/company/web/menu/'+menu.uid+'/delete', {}, { headers: { 'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
          return  this.getMenus();
        }).catch(error => {
            this.error_reponse = errors.response.data.errors;
            this.error_message = true;
        });
    },
    filterSelected(id){
        if(this.selectedids.length > 0){
          var exists = this.selectedids.filter(item => item == id);
          if(exists.length > 0){
            return true;
          }else{
            return false
          }
        }else{
          return false;
        }
      },
      checkAll: function(){ 
                 this.isCheckAll = !this.isCheckAll; 
                 this.selectedids = []; 
                 if(this.isCheckAll){ 
                     // Check all 
                     for (var key in this.menus.data) {          
                        this.selectedids.push(this.menus.data[key].id); 
                     } 
                 } 
              }, 
      updateCheckall: function(){ 
                 if(this.selectedids.length == this.menus.data.length){
                    this.isCheckAll = true; 
                 }else{ 
                    this.isCheckAll = false; 
                 } 
      },

  }
}

</script>