<script type="text/javascript">
	import simplebar from "simplebar-vue";
	import CartPayment from "../../../../components/widgets/ecommerce/public/CartPayment";
	import { EventBus } from '../../../../public-app'
	import { termsConditions } from '../../../../terms'
	export default{
		components: { simplebar, CartPayment },
		data: function(){
			return{
				processing: false,
				terms: termsConditions,
				customisationdropdown: -2,
				amount_payable: 0.00,
				order_details: null,
				companies: [],
				cartLoading: false,
		      	cartPanelData: [],
		      	cartPanel: false,
		      	cart: {
			        products: [],
			        uid: '',
			        id: '',
			     },
			     order: {
			     	products: [],
			        uid: '',
			        id: '',
			     },
			     customer: {
			     	customer_company_id: '',
			     	customer_id: '',
			     	customer_invoice_address_id: '',
			     	customer_delivery_address_id: '',
			     	customer_company_purchase_id: '',
			     },
			     cart_total: 0.00,
			     cart_totalinc: 0.00,
			     shipping_total: 0.00,
			     shipping_totalinc: 0.00,
			     discounts_total: 0.00,
			     discounts_totalinc: 0.00,
			     show_payment_form: false,
			     shipping_options: [],
			     orderCreated: false,
			     accpted_terms: 0,
			     invoiceaddrressstring: '',
			     deliveryaddressstring: '',
			     listingslimit: 25,
			     listingssortby: 'id',
			     listingsData: [],
				 listingsearch: '',
				 listingtrade_active: 1,
	      		 listingpublic_active: '',
	      		 listing_active: '',
	      		 purchaselimit: 25,
	      		 purchase_orders: '',
	      		 purchase: '',
	      		 purchasesearch: '',
	      		 couponsearch: '',
	      		 cart_companies: [],
	      		 cart_companies_raw: [],
	      		 haspurchaseorder: false,
	      		 purchaseOrderPanel: false,
	      		 order: 'desc',
	      		 type_id: '',
	      		 delivery_address: null,
	      		 invoice_address: null,
	      		 closeHover: false,
	      		 storageurl: '.s3.eu-west-2.amazonaws.com',
			}
		},
		  props: {
		    user: {
		      type: Object,
		      default: () => {api_token: ''}
		    },
		    company: {
		      type: Object,
		      default: () => {}
		    }
		  },
		created() {

			this.getListings();
			if(Object.keys(this.$attrs.cart).length > 0){
				this.cart = this.$attrs.cart;
			}
			/*
			this.getListings();
		    var session_cart = window.sessionStorage.getItem("publiccart");
		    session_cart = JSON.parse(session_cart);
		    if(session_cart == null){
		      this.checkAndGetCart();
		    }else{
		      this.cart = session_cart;
		    }
		    */
		  },
		mounted() {  
				
				var id = 0;
				var companies = [];
				var companyList = [];
				if(this.cart.products.length > 0){
					for(var i = 0; i < this.cart.products.length; i++){
						companies.push(this.cart.products[i].company_id);
					}
					this.companies = [...new Set(companies)];
					for(var ia = 0; ia < this.companies.length; ia++){
						var productsdata = this.cart.products.filter(item => item.product.company_id == this.companies[ia]);
						companyList.push({uid: productsdata[0].company.uid, id: productsdata[0].company.id, products: productsdata, shippingoptions: '', company_shipping_option_id: 0, shippingtotal: 0.00, shippingtotalinc: 0.00, productstotal: 0.00, combinationstotal: 0.00, combinationstotalinc: 0.00, variantstotal: 0.00, variantstotalinc: 0.00, productstotalinc: 0.00, coupon: '', couponvalueexc: '', couponvalueinc: '' });
						this.cart_companies.push({uid: productsdata[0].company.uid, id: productsdata[0].company.id, products: productsdata, shippingoptions: '', company_shipping_option_id: 0, shippingtotal: 0.00, shippingtotalinc: 0.00, productstotal: 0.00, combinationstotal: 0.00, combinationstotalinc: 0.00, variantstotal: 0.00, variantstotalinc: 0.00, productstotalinc: 0.00, coupon: '', couponvalueexc: '', couponvalueinc: '' });
						this.companiesTotal(ia);
						/*var self = this;
						axios.all([getShipping(companyList[ia].products[0].company.uid, self.user.api_token, ia)]).then(axios.spread(function (response) {
							//companyList[ia].push(response.data)
							console.log(response);
							self.cart_companies[response[1]].shippingoptions = response[0].data; 
						}));*/
					}
					self.cart_companies = companyList;
					//self.cart_companies_raw = JSON.parse(JSON.stringify(companyList));
				}
				
				/* async function getShipping(company_uid, api_token, index){
	             return [await axios.post('/customer-api/cart/shipping-options?api_token='+api_token, { uid: company_uid }), index];
	            }*/


	        if(this.user){
	        	this.customer.customer_id = this.user.id;
	        }
	        if(this.user.default_delivery_address !== null){
					this.delivery_address = this.user.default_delivery_address;
					this.customer.customer_delivery_address_id = this.user.default_delivery_address.id;
			  		this.getShippingOptions(this.user.default_delivery_address.country.country_zone_id, this.user.default_delivery_address.country.id);
			  		this.companiesTotalIncVat(this.user.default_delivery_address.country.country_zone_id, this.user.default_delivery_address.country.id, false);

			}
			if(this.user.default_invoice_address !== null){
					this.invoice_address = this.user.default_invoice_address;
					this.customer.customer_invoice_address_id = this.user.default_invoice_address.id;
			}
			this.cart_companies_raw = JSON.parse(JSON.stringify(this.cart_companies));
			
		  },
		computed: {
			/*cartCombinationTotals(){
				var combitotal = 0.00;
				var combitotalexc = 0.00;
				for(var i = 0; i < this.cart.products.length; i++){
					for(var ci = 0; ci < this.cart.products[i].combinations.length; ci++){
						combitotalexc += (parseFloat(this.cart.products[i].combinations[ci].price_difference) * (1 / this.$attrs.exchange_rates[this.cart.products[i].product.company.trading_currency_code])) * parseFloat(this.cart.products[i].combinations[ci].quantity);	
							combitotal += (parseFloat(this.addTax(this.cart.products[i].product, this.cart.products[i].combinations[ci].price_difference)) * (1 / this.$attrs.exchange_rates[this.cart.products[i].product.company.trading_currency_code])) * parseFloat(this.cart.products[i].combinations[ci].quantity) ;						
					
					}
				}
				return [combitotalexc.toFixed(2), combitotal.toFixed(2)];
			},
			cartVariationsTotals(){
				var variantstotal = 0.00;
				var variantstotalexc = 0.00;
				for(var i = 0; i < this.cart.products.length; i++){
					if(this.cart.products[i].variants !== null){
						for(var vi = 0; vi < this.cart.products[i].variants.length; vi++){
						variantstotalexc += (parseFloat(this.cart.products[i].variants[vi].price_difference) * (1 / this.$attrs.exchange_rates[this.cart.products[i].product.company.trading_currency_code])) * this.cart.products[i].variants[vi].quantity;
						variantstotal += parseFloat(parseFloat(this.addTax(this.cart.products[i].product, this.cart.products[i].variants[vi].price_difference) * (1 / this.$attrs.exchange_rates[this.cart.products[i].product.company.trading_currency_code])) * this.cart.products[i].variants[vi].quantity);
						}
					}
				}
				return [variantstotalexc.toFixed(2), variantstotal.toFixed(2)];
			},*/
			cartCombinationTotals(){
				var combitotal = 0.00;
				var combitotalexc = 0.00;
				for(var i = 0; i < this.cart.products.length; i++){
					for(var ci = 0; ci < this.cart.products[i].combinations.length; ci++){
						/*combitotalexc += parseFloat(this.cart.products[i].combinations[ci].price_difference) * parseFloat(this.cart.products[i].combinations[ci].quantity);	
							combitotal += parseFloat(parseFloat(this.addTax(this.cart.products[i].product, this.cart.products[i].combinations[ci].price_difference)) * parseFloat(this.cart.products[i].combinations[ci].quantity) );*/
						if(this.processing == true){
							combitotalexc += parseFloat(this.cart.products[i].combinations[ci].price_difference) * parseFloat(this.cart.products[i].combinations[ci].quantity);	
							combitotal += parseFloat(this.addTax(this.cart.products[i].product, this.cart.products[i].combinations[ci].price_difference)) * parseFloat(this.cart.products[i].combinations[ci].quantity);
						}else{
							combitotalexc += (parseFloat(this.cart.products[i].combinations[ci].price_difference) * (1 / this.$attrs.exchange_rates[this.cart.products[i].product.company.trading_currency_code])) * parseFloat(this.cart.products[i].combinations[ci].quantity);	
							combitotal += (parseFloat(this.addTax(this.cart.products[i].product, this.cart.products[i].combinations[ci].price_difference)) * (1 / this.$attrs.exchange_rates[this.cart.products[i].product.company.trading_currency_code])) * parseFloat(this.cart.products[i].combinations[ci].quantity);
						}				
					
					}
				}
				return [combitotalexc.toFixed(2), combitotal.toFixed(2)];
			},
			cartVariationsTotals(){
				var variantstotal = 0.00;
				var variantstotalexc = 0.00;
				for(var i = 0; i < this.cart.products.length; i++){
					if(this.cart.products[i].variants !== null){
						for(var vi = 0; vi < this.cart.products[i].variants.length; vi++){
						/*variantstotalexc += parseFloat(this.cart.products[i].variants[vi].price_difference * this.cart.products[i].variants[vi].quantity);
						variantstotal += parseFloat(this.addTax(this.cart.products[i].product, this.cart.products[i].variants[vi].price_difference)  * this.cart.products[i].variants[vi].quantity);*/
							if(this.processing == true){
								variantstotalexc += parseFloat(this.cart.products[i].variants[vi].price_difference) * this.cart.products[i].variants[vi].quantity;
								variantstotal += parseFloat(parseFloat(this.addTax(this.cart.products[i].product, this.cart.products[i].variants[vi].price_difference)) * this.cart.products[i].variants[vi].quantity);
							}else{
								variantstotalexc += (parseFloat(this.cart.products[i].variants[vi].price_difference) * (1 / this.$attrs.exchange_rates[this.cart.products[i].product.company.trading_currency_code])) * this.cart.products[i].variants[vi].quantity;
								variantstotal += parseFloat(parseFloat(this.addTax(this.cart.products[i].product, this.cart.products[i].variants[vi].price_difference) * (1 / this.$attrs.exchange_rates[this.cart.products[i].product.company.trading_currency_code])) * this.cart.products[i].variants[vi].quantity);
							}
						}
					}
				}
				return [variantstotalexc.toFixed(2), variantstotal.toFixed(2)];
			},
			cartCompanies(){
				var id = 0;
				var companies = [];
				var companyList = [];
				if(this.cart.products.length > 0){
					for(var i = 0; i < this.cart.products.length; i++){
						companies.push(this.cart.products[i].company_id);
					}
					this.companies = companies;
					for(var ia = 0; ia < companies.length; ia++){
						companyList.push(this.cart.products.filter(item => item.product.company_id == companies[ia]));
					}
					return companyList;
				}

			},
			shippingSelected(){
				var shippingIsSelected = [];
				for(var i = 0; i < this.cart_companies.length; i++){
					if(this.cart_companies[i].company_shipping_option_id == (null || '')){
						shippingIsSelected.push(false);
					}else{
						shippingIsSelected.push(true);
					}
				}
				var isSelected = shippingIsSelected.findIndex(item => item == false)
				if(isSelected == -1){
					return true;
				}else{
					return false;
				}
			},
		    /*cartTotal: function(){
		      var score = 0;
		      if(this.cart.products.length > 1){
		        for(var i = 0; i < this.cart.products.length; i++){
		          var itemscore = (parseFloat(this.cart.products[i].price_exc) * parseFloat(this.cart.products[i].quantity));
		          score = score + itemscore;
		        }
		      }else{
		      	score = this.cart.products[0].price_exc * this.cart.products[0].quantity;
		      }
		      return (score).toFixed(2);
		    }*/
		},
		methods: {
			updateProductQuantity(product, qty, variants, combination){
	        	EventBus.$emit('update-quantity', product, qty, variants, combination, false);
	    	},
			companyCartCombinationTotals(processing){
				var combitotal = 0.00;
				var combitotalexc = 0.00;
				
				for(var cci = 0; cci < this.cart_companies.length; cci++){
					for(var i = 0; i < this.cart_companies[cci].products.length; i++){
						for(var ci = 0; ci < this.cart_companies[cci].products[i].combinations.length; ci++){

								if(processing == 'reset'){
									this.cart_companies[cci].products[i].combinations[ci].price_difference = (parseFloat(this.cart_companies[cci].products[i].combinations[ci].price_difference) / (1 / this.$attrs.exchange_rates[this.cart_companies[cci].products[i].product.company.trading_currency_code]));
								}	
									
								combitotalexc += (parseFloat(this.cart_companies[cci].products[i].combinations[ci].price_difference) * (1 / this.$attrs.exchange_rates[this.cart_companies[cci].products[i].product.company.trading_currency_code])) * parseFloat(this.cart_companies[cci].products[i].combinations[ci].quantity);	
								combitotal += parseFloat((parseFloat(this.addTax(this.cart_companies[cci].products[i].product, this.cart_companies[cci].products[i].combinations[ci].price_difference)) * (1 / this.$attrs.exchange_rates[this.cart_companies[cci].products[i].product.company.trading_currency_code])) * parseFloat(this.cart_companies[cci].products[i].combinations[ci].quantity) );

								if(processing == true){
									
									this.cart_companies[cci].products[i].combinations[ci].price_difference_inc = (parseFloat(this.addTax(this.cart_companies[cci].products[i].product, this.cart_companies[cci].products[i].combinations[ci].price_difference)) * (1 / this.$attrs.exchange_rates[this.cart_companies[cci].products[i].product.company.trading_currency_code]));
									this.cart_companies[cci].products[i].combinations[ci].price_difference_total_exc = (parseFloat(this.cart_companies[cci].products[i].combinations[ci].price_difference) * (1 / this.$attrs.exchange_rates[this.cart_companies[cci].products[i].product.company.trading_currency_code])) * parseFloat(this.cart_companies[cci].products[i].combinations[ci].quantity);	
									this.cart_companies[cci].products[i].combinations[ci].price_difference_total_inc = parseFloat((parseFloat(this.addTax(this.cart_companies[cci].products[i].product, this.cart_companies[cci].products[i].combinations[ci].price_difference)) * (1 / this.$attrs.exchange_rates[this.cart_companies[cci].products[i].product.company.trading_currency_code])) * parseFloat(this.cart_companies[cci].products[i].combinations[ci].quantity) );

									this.cart_companies[cci].products[i].combinations[ci].price_difference = (parseFloat(this.cart_companies[cci].products[i].combinations[ci].price_difference) * (1 / this.$attrs.exchange_rates[this.cart_companies[cci].products[i].product.company.trading_currency_code]));
								}				
								
						}
					}
					this.cart_companies[cci].combinationstotalinc = combitotal.toFixed(2);
					this.cart_companies[cci].combinationstotal = combitotalexc.toFixed(2);
				}
				//return [combitotalexc.toFixed(2), combitotal.toFixed(2)];
				
			},
			companyCartVariationsTotals(processing){
				var variantstotal = 0.00;
				var variantstotalexc = 0.00;
				for(var cci = 0; cci < this.cart_companies.length; cci++){
					for(var i = 0; i < this.cart_companies[cci].products.length; i++){
						if(this.cart.products[i].variants !== null){
							for(var vi = 0; vi < this.cart.products[i].variants.length; vi++){
								if(processing == 'reset'){
									this.cart.products[i].variants[vi].price_difference = parseFloat(this.cart.products[i].variants[vi].price_difference * (1 / this.$attrs.exchange_rates[this.cart_companies[cci].products[i].product.company.trading_currency_code])) ;
									
								}
								variantstotalexc += (parseFloat(this.cart.products[i].variants[vi].price_difference * (1 / this.$attrs.exchange_rates[this.cart_companies[cci].products[i].product.company.trading_currency_code])) * this.cart.products[i].variants[vi].quantity) ;
								variantstotal += (parseFloat(this.addTax(this.cart.products[i].product, this.cart.products[i].variants[vi].price_difference) * (1 / this.$attrs.exchange_rates[this.cart_companies[cci].products[i].product.company.trading_currency_code])) * this.cart.products[i].variants[vi].quantity);

								if(processing == true){
								
									this.cart.products[i].variants[vi].price_difference_inc = parseFloat(this.addTax(this.cart.products[i].product, this.cart.products[i].variants[vi].price_difference) * (1 / this.$attrs.exchange_rates[this.cart_companies[cci].products[i].product.company.trading_currency_code]));
									this.cart.products[i].variants[vi].price_difference_total_exc = (parseFloat(this.cart.products[i].variants[vi].price_difference * (1 / this.$attrs.exchange_rates[this.cart_companies[cci].products[i].product.company.trading_currency_code])) * this.cart.products[i].variants[vi].quantity) ;
									this.cart.products[i].variants[vi].price_difference_total_inc = (parseFloat(this.addTax(this.cart.products[i].product, this.cart.products[i].variants[vi].price_difference) * (1 / this.$attrs.exchange_rates[this.cart_companies[cci].products[i].product.company.trading_currency_code])) * this.cart.products[i].variants[vi].quantity);

										this.cart.products[i].variants[vi].price_difference = parseFloat(this.cart.products[i].variants[vi].price_difference * (1 / this.$attrs.exchange_rates[this.cart_companies[cci].products[i].product.company.trading_currency_code])) ;
								}
							}
						}
					}	
					this.cart_companies[cci].variantstotalinc = variantstotal.toFixed(2);
					this.cart_companies[cci].variantstotal = variantstotalexc.toFixed(2);
				}
				
			},
			addTax(product, price){
        		var tax_rule = product.tax_rule;
        		console.log(tax_rule);
        		var country_id = null;
		    	if(this.delivery_address !== '' && this.delivery_address !== null){
		    	
		    		country_id = this.delivery_address.country_id;
		    	}
		    	if((country_id !== null) && (tax_rule !== null && tax_rule.country_rules.length > 0)){
		    		var selected_rule = null;
		    		var selected_ruleIndex = -1;
			    	for(var ti = 0; ti < tax_rule.country_rules.length; ti++){
			    		selected_ruleIndex = tax_rule.country_rules[ti].countries.findIndex(item => item == country_id);
			    		if(selected_ruleIndex !== -1){
			    			selected_rule = tax_rule.country_rules[ti];
			    			ti = tax_rule.country_rules.length;
			    		}
			    	}
			    	console.log(selected_rule);
			    	if(tax_rule.type == 1){
		        		if(selected_rule !== null){
		        			var percentage = (parseFloat(selected_rule.value) / 100) + 1;
		        			return price * percentage;
		         		}else{
		         			return (price * 1.2).toFixed(2);
		         		}
	         		}else{
	         			if(selected_rule !== null){
		        			return price + selected_rule.value;
		         		}else{
		         			return (price * 1.2).toFixed(2);
		         		}
	         		}
         		}else if(tax_rule !== null && tax_rule.country_rules.length > 0){
         			var percentage = (parseFloat(tax_rule.percentage) / 100) + 1;
		        	return price * percentage;
         		}else {
		        	return (price * 1.2).toFixed(2);
         		}
        	},
        	/*addTax(price, tax_rule){
		    	console.log(tax_rule);
		    	if(this.customer_address == ''){
		    		var country_id = this.company.country_id;
		    	}else{
		    		var country_id = this.customer_address.country_id;
		    	}
		    	if(tax_rule !== null && tax_rule.country_rules.length > 0){
		    		var selected_rule = null;
		    		var selected_ruleIndex = -1;
		    	for(var ti = 0; ti < tax_rule.country_rules.length; ti++){
		    		selected_ruleIndex = tax_rule.country_rules[ti].countries.findIndex(item => item == country_id);
		    		if(selected_ruleIndex !== -1){
		    			selected_rule = tax_rule.country_rules[ti];
		    			ti = tax_rule.country_rules.length;
		    		}
		    	}
		    	console.log(selected_rule);
		    	if(tax_rule.type == 1){
	        		if(selected_rule !== null){
	        			var percentage = (parseFloat(selected_rule.value) / 100) + 1;
	        			return price * percentage;
	         		}else{
	         			return price;
	         		}
         		}else{
         			if(selected_rule !== null){
	        			return price + selected_rule.value;
	         		}else{
	         			return price;
	         		}
         		}
         		}else{
         			return price;
         		}
        	},*/
			calculateShippingPrice(ranges, value, isfree){
				if(isfree == 1){
					return 0.00;
				}else{
					for(var i =0; i < ranges.length; i++){
						if((parseFloat(ranges[i].start_delimeter) <= parseFloat(value)) && (parseFloat(ranges[i].end_delimeter) >= parseFloat(value))){
								return ranges[i].price;
						}
					}
				}
			},
			calculateShippingPriceInc(shippingoption, value, isfree, zone_id, country_id){
				if(isfree == 1){
					return 0.00;
				}else{
					if(shippingoption.tax_rule !== null){
						var countryzone = shippingoption.tax_rule.country_rules.filter(item => item.country_zone_id == zone_id);
						var country = countryzone.countries.filter(item => item == country_id);
						var percentage = (parseFloat(country[0].value) / 100) + 1;
						for(var i =0; i < shippingoption.ranges.length; i++){
							if((parseFloat(shippingoption.ranges[i].start_delimeter) <= parseFloat(value)) && (parseFloat(shippingoption.ranges[i].end_delimeter) >= parseFloat(value))){
									return parseFloat(shippingoption.ranges[i].price) * parseFloat(percentage);
							}
						}
					}else{
						var percentage = (parseFloat(this.delivery_address.country.default_vat_rate) / 100) + 1;
						for(var i =0; i < shippingoption.ranges.length; i++){
							if((parseFloat(shippingoption.ranges[i].start_delimeter) <= parseFloat(value)) && (parseFloat(shippingoption.ranges[i].end_delimeter) >= parseFloat(value))){
									return parseFloat(shippingoption.ranges[i].price) * parseFloat(percentage);
							}
						}
					}
				}
			},
			removeShipping(){
				for(var i = 0; i < this.cart_companies.length; i++){
					this.cart_companies[i].company_shipping_option_id = '';
				}
			},
			reloadCart(){
				this.refetchCart();
				this.processToPayment();
			},
			processToPayment(){
				this.cartLoading = true;
				this.cart.total_exc_vat = this.cartTotal(this.cart);
				for(var i = 0; i < this.cart_companies.length; i++){
					this.companiesTotal(i);
				}
				this.companiesTotalIncVat(this.delivery_address.country.country_zone_id, this.delivery_address.country_id, true);
				this.shippingTotal();
				this.shippingTotalInc();
				this.couponDiscountsTotal();
				this.processing = true;
				this.companyCartCombinationTotals(this.processing);
				this.companyCartVariationsTotals(this.processing);
				var order_total = 0.00;
				var order_totalinc = 0.00;
				if(this.delivery_address !== ('' || null))
				{
					order_totalinc = parseFloat(parseFloat(this.cart_totalinc) + parseFloat(this.cartCombinationTotals[1]) + parseFloat(this.cartVariationsTotals[1]) + parseFloat(this.shipping_totalinc) - parseFloat(this.discounts_totalinc)).toFixed(2);
					order_total = (parseFloat(this.cart_total)+ parseFloat(this.cartCombinationTotals[0]) + parseFloat(this.cartVariationsTotals[0]) + parseFloat(this.shipping_total) - parseFloat(this.discounts_total)).toFixed(2);
				}else{
					order_total = (parseFloat(this.cart_total) + parseFloat(this.cartCombinationTotals[0]) + parseFloat(this.cartVariationsTotals[0]) + parseFloat(this.shipping_total) - parseFloat(this.discounts_total)).toFixed(2);
					order_totalinc = (parseFloat(this.cart_total) + parseFloat(this.cartCombinationTotals[1]) + parseFloat(this.cartVariationsTotals[1]) + parseFloat(this.shipping_totalinc) - parseFloat(this.discounts_total)).toFixed(2);
				}
				this.amount_payable = order_totalinc;
				let self = this;
				          setTimeout(function(){
				            self.cartLoading = false;
				            self.orderCreated = true;
							self.show_payment_form = true;
				            }, 1500);
			},
			processCart(){
				this.cartLoading = true;
				//this.cart.total_exc_vat = this.cartTotal(this.cart);
				/*for(var i = 0; i < this.cart_companies.length; i++){
					this.companiesTotal(i);
				}
				this.companiesTotalIncVat(this.delivery_address.country.country_zone_id, this.delivery_address.country_id);*/
				this.shippingTotal();
				this.shippingTotalInc();
				this.couponDiscountsTotal();
				var order_total = 0.00;
				var order_totalinc = 0.00;
				if(this.delivery_address !== ('' || null))
				{
					order_totalinc = parseFloat(parseFloat(this.cart_totalinc) + parseFloat(this.cartCombinationTotals[1]) + parseFloat(this.cartVariationsTotals[1]) + parseFloat(this.shipping_totalinc) - parseFloat(this.discounts_totalinc)).toFixed(2);
					order_total = (parseFloat(this.cart_total) + parseFloat(this.cartCombinationTotals[0]) + parseFloat(this.cartVariationsTotals[0]) + parseFloat(this.shipping_total) - parseFloat(this.discounts_total)).toFixed(2);
				}else{
					order_total = (parseFloat(this.cart_total) + parseFloat(this.cartCombinationTotals[0]) + parseFloat(this.cartVariationsTotals[0]) + parseFloat(this.shipping_total) - parseFloat(this.discounts_total)).toFixed(2);
					order_totalinc = (parseFloat(this.cart_total) + parseFloat(this.cartCombinationTotals[1]) + parseFloat(this.cartVariationsTotals[1]) + parseFloat(this.shipping_totalinc) - parseFloat(this.discounts_total)).toFixed(2);
				}
				/** axios({
			        'method':'GET',
			        'url':'/customer-api/order/create',
			        'headers': {
			            'Authorization': 'Bearer ' + this.user.api_token
			        },
			        'params': {
			            {cart_id: this.cart.id, cart_uid: this.cart.uid, customer: this.customer, cart_companies: this.cart_companies, total_inc: order_totalinc, total_exc: order_total
			        },
			    }).then(response => {
						this.order_details = response.data;
						  let self = this;
				          setTimeout(function(){
				            self.cartLoading = false;
				            self.orderCreated = true;
							self.show_payment_form = true;
				            }, 1500);
				}).catch(error => {
					console.log(error);
				});**/
				axios.post('/customer-api/order/create', {cart_id: this.cart.id, cart_uid: this.cart.uid, customer: this.customer, cart_companies: this.cart_companies, total_inc: order_totalinc, total_exc: order_total, currency_code: this.$attrs.selected_currency, currency_code_low: this.$attrs.selected_currency.toLowerCase(), exchange_rates: this.$attrs.exchange_rates}, {headers: {
			            'Authorization': 'Bearer ' + this.user.api_token
			        }}).then(response => {
						this.order_details = response.data;
						this.order_details["delivery_address"] = this.delivery_address;
						this.order_details["invoice_address"] = this.invoice_address;
						return this.processPayment(this.order_details["order"]["reference"], this.order_details["order"]["uid"] );
						  
				}).catch(error => {
					console.log(error);
				});
				//this.orderCreated = true;
				//this.$emit('update-cart', orderdetails);
				//this.show_payment_form = true;
			},
			processPayment(reference, uid){
				EventBus.$emit('process-payment', reference, uid);
			},
		    checkAndGetCart(){
		      axios.get('/customer-api/cart/read?cart_uid='+this.cart.uid, {headers: {
			            'Authorization': 'Bearer ' + this.user.api_token
			        }}).then(response => {
		        console.log(response);
		          if(response.data !== 0){
		            this.cart = response.data;
		            window.sessionStorage.setItem("publiccart", JSON.stringify(response.data));
		          }else{
		            window.sessionStorage.setItem("publiccart", JSON.stringify(this.cart))
		          }
		        
		      }).catch(error => {
		        this.error_reponse = error.response.data.errors;
		      });
		    },
		    couponDiscountsTotal(){
		    	this.discounts_totalinc = 0.00;
		    	this.discounts_total = 0.00;
		    	for(var i = 0; i < this.cart_companies.length; i++){
		    		if(this.cart_companies[i].coupon !== ''){
			    		if(this.cart_companies[i].coupon.discount_type == 1){
			    			var percentage = ((parseFloat(this.cart_companies[i].coupon.discount_amount) /100));
			    			if(this.delivery_address !== null){
			    				var priceincdiscountamount = (parseFloat(this.cart_companies[i].productstotalinc) + parseFloat(this.cart_companies[i].shippingtotal)) * parseFloat(percentage);
			    				this.cart_companies[i].couponvalueinc = priceincdiscountamount;
			    				this.discounts_totalinc += priceincdiscountamount;
			    			}
			    			
			    			var priceexcdiscountamount = (parseFloat(this.cart_companies[i].productstotal) + parseFloat(this.cart_companies[i].shippingtotal)) * parseFloat(percentage);
			    			this.cart_companies[i].couponvalueexc = priceexcdiscountamount;
			    			this.discounts_total += priceexcdiscountamount;
			    		    
			    		}else{
			    			if(this.delivery_address !== null){
			    				var priceincdiscountamount = parseFloat(this.cart_companies[i].coupon.discount_amount);
			    				this.cart_companies[i].couponvalueinc = parseFloat(this.cart_companies[i].coupon.discount_amount);
			    				this.discounts_totalinc += priceincdiscountamount;
			    			}
			    			var priceexcdiscountamount = parseFloat(this.cart_companies[i].coupon.discount_amount);
			    			this.cart_companies[i].couponvalueexc = parseFloat(this.cart_companies[i].coupon.discount_amount);
			    			this.discounts_total += priceexcdiscountamount;
			    		}
		    		}
		    		
		    	}
		    },
		    shippingTotal(){ 
		    	var total = 0.00;
		    	for(var i = 0; i < this.cart_companies.length; i++){
		    			total = (parseFloat(total) + (parseFloat(this.cart_companies[i].shippingtotal) * (1 / this.$attrs.exchange_rates[this.cart_companies[i].products[0].product.company.trading_currency_code]))).toFixed(2);
		    	}
		    	this.shipping_total = parseFloat(total).toFixed(2);
		    	return parseFloat(total).toFixed(2);
		    },
		    shippingTotalInc(){ 
		    	var total = 0.00;
		    	for(var i = 0; i < this.cart_companies.length; i++){
		    			total = (parseFloat(total) + (parseFloat(this.cart_companies[i].shippingtotalinc) * (1 / this.$attrs.exchange_rates[this.cart_companies[i].products[0].product.company.trading_currency_code]))).toFixed(2);
		    	}
		    	this.shipping_totalinc = parseFloat(total).toFixed(2);
		    	return parseFloat(total).toFixed(2);
		    },
		    cartTotal(cart){
	          var score = 0;
	          if(cart.products.length > 1){
	            for(var i = 0; i < cart.products.length; i++){
	              var itemscore = (parseFloat(cart.products[i].price_exc) * (1 / this.$attrs.exchange_rates[cart.products[i].product.company.trading_currency_code])) * parseFloat(cart.products[i].quantity);
	              score = parseFloat(score) + parseFloat(itemscore);
	            }
	          }else{
	            score = (parseFloat(cart.products[0].price_exc) * (1 / this.$attrs.exchange_rates[cart.products[0].product.company.trading_currency_code])) * parseFloat(cart.products[0].quantity);
	          }
	          this.cart_total = (score).toFixed(2);
	          return (score).toFixed(2);
	        },
	        /*companiesTotalIncVat(zone_id, country_id){
	        	this.cart_companies = JSON.parse(JSON.stringify(this.cart_companies_raw));
	       	this.cart_totalinc = 0.00;
	          for(var index = 0; index < this.cart_companies.length; index++){
	          	var score = 0;
	          if(this.cart_companies[index].products.length > 1){
	            for(var i = 0; i < this.cart_companies[index].products.length; i++){
	              var rule = this.cart_companies[index].products[i].product.tax_rule;
	               var tax_rule = '';
	           	   if(rule){
	           	  	tax_rule = rule.country_rules.filter(item => item.country_zone_id == zone_id);
	           	   }
	           	  if(tax_rule.length > 0){
		           	  var country_rule = tax_rule[0].countries.filter(item => item == country_id);
		           	  if(country_rule.length > 0){
		           	  	var tax_calc = ((parseFloat(tax_rule[0].value) + 100) / 100);
		           	  	var itempriceinc = parseFloat(this.cart_companies[index].products[i].price_exc) * parseFloat(tax_calc);
		           	  	var itemscore = (parseFloat(itempriceinc) * (1 / this.$attrs.exchange_rates[this.cart_companies[index].products[i].product.company.trading_currency_code]))  * parseFloat(this.cart_companies[index].products[i].quantity);
		           	  	this.cart_companies[index].products[i].price_exc = this.cart_companies[index].products[i].price_exc * (1 / this.$attrs.exchange_rates[this.cart_companies[index].products[i].product.company.trading_currency_code]);
		           	  	this.cart_companies[index].products[i].price_inc = parseFloat(itempriceinc) * (1 / this.$attrs.exchange_rates[this.cart_companies[index].products[i].product.company.trading_currency_code]);


		           	  }else{
		           	  	var percentage = (parseFloat(rule.percentage / 100) + 1);
		           	  	var itemscore = (((parseFloat(this.cart_companies[index].products[i].price_exc) * parseFloat(percentage)) * (1 / this.$attrs.exchange_rates[this.cart_companies[index].products[i].product.company.trading_currency_code])) * parseFloat(this.cart_companies[index].products[i].quantity));
		           	  	this.cart_companies[index].products[i].price_exc = this.cart_companies[index].products[i].price_exc * (1 / this.$attrs.exchange_rates[this.cart_companies[index].products[i].product.company.trading_currency_code]);
		           	  	this.cart_companies[index].products[i].price_inc = (parseFloat(this.cart_companies[index].products[i].price_exc) * parseFloat(percentage)) * (1 / this.$attrs.exchange_rates[this.cart_companies[index].products[i].product.company.trading_currency_code]);
		           	  }
	           	  }else if(rule){
	           	  	var percentage = (parseFloat(rule.percentage / 100) + 1);
		           	  var itemscore = (((parseFloat(this.cart_companies[index].products[i].price_exc) * parseFloat(percentage)) * (1 / this.$attrs.exchange_rates[this.cart_companies[index].products[i].product.company.trading_currency_code])) * parseFloat(this.cart_companies[index].products[i].quantity));
		           	  this.cart_companies[index].products[i].price_exc = this.cart_companies[index].products[i].price_exc * (1 / this.$attrs.exchange_rates[this.cart_companies[index].products[i].product.company.trading_currency_code]);
		           	  this.cart_companies[index].products[i].price_inc = (parseFloat(this.cart_companies[index].products[i].price_exc) * parseFloat(percentage))  * (1 / this.$attrs.exchange_rates[this.cart_companies[index].products[i].product.company.trading_currency_code]);
	           	  }else{
	           	  	itemscore = parseFloat(this.cart_companies[index].products[0].price_exc) * parseFloat(this.cart_companies[index].products[0].quantity) * 1.2;
	           	  	this.cart_companies[index].products[0].price_exc = this.cart_companies[index].products[0].price_exc * (1 / this.$attrs.exchange_rates[this.cart_companies[index].products[0].product.company.trading_currency_code]);
	           	  	this.cart_companies[index].products[0].price_inc = (parseFloat(this.cart_companies[index].products[0].price_exc) * 1.2)  * (1 / this.$attrs.exchange_rates[this.cart_companies[index].products[0].product.company.trading_currency_code]);
	           	  }
	              score = parseFloat(score) + parseFloat(itemscore);
	            }
	          }else{
	          	var rule = this.cart_companies[index].products[0].product.tax_rule;
	          	console.log(rule);
	          	if(rule !== null){
	           	  var tax_rule = rule.country_rules.filter(item => item.country_zone_id == zone_id);
	           	  console.log(tax_rule);
	           	  
	           	  if(tax_rule.length > 0){
		           	  var country_rule = tax_rule[0].countries.filter(item => item == country_id);
		           	  console.log(country_rule);
		           	  if(country_rule.length > 0){
		           	  	var tax_calc = ((parseFloat(tax_rule[0].value) + 100) / 100);
		           	  	var itempriceinc = parseFloat(this.cart_companies[index].products[0].price_exc) * parseFloat(tax_calc);
		           	  	var itemscore = (parseFloat(itempriceinc) * (1 / this.$attrs.exchange_rates[this.cart_companies[index].products[0].product.company.trading_currency_code])) * parseFloat(this.cart_companies[index].products[0].quantity);
		           	  	this.cart_companies[index].products[0].price_exc = this.cart_companies[index].products[0].price_exc * (1 / this.$attrs.exchange_rates[this.cart_companies[index].products[0].product.company.trading_currency_code]);
		           	  	this.cart_companies[index].products[0].price_inc = parseFloat(itempriceinc) * (1 / this.$attrs.exchange_rates[this.cart_companies[index].products[0].product.company.trading_currency_code]);
		           	  }else{
		           		var percentage = (parseFloat(rule.percentage / 100) + 1);
		           	  	var itemscore = (((parseFloat(this.cart_companies[index].products[0].price_exc) * parseFloat(percentage)) * (1 / this.$attrs.exchange_rates[this.cart_companies[index].products[0].product.company.trading_currency_code])) * parseFloat(this.cart_companies[index].products[0].quantity));
		           	  	this.cart_companies[index].products[0].price_exc = this.cart_companies[index].products[0].price_exc * (1 / this.$attrs.exchange_rates[this.cart_companies[index].products[0].product.company.trading_currency_code]);
		           	  	this.cart_companies[index].products[0].price_inc = (parseFloat(this.cart_companies[index].products[0].price_exc) * parseFloat(percentage)) * (1 / this.$attrs.exchange_rates[this.cart_companies[index].products[0].product.company.trading_currency_code]);
		           		}

		           	}else{
		           		var percentage = (parseFloat(rule.percentage / 100) + 1);
		           		itemscore = (((parseFloat(this.cart_companies[index].products[0].price_exc) * parseFloat(percentage)) * (1 / this.$attrs.exchange_rates[this.cart_companies[index].products[0].product.company.trading_currency_code])) * parseFloat(this.cart_companies[index].products[0].quantity));
		           		this.cart_companies[index].products[0].price_exc = this.cart_companies[index].products[0].price_exc * (1 / this.$attrs.exchange_rates[this.cart_companies[index].products[0].product.company.trading_currency_code]);
		           		this.cart_companies[index].products[0].price_inc = (parseFloat(this.cart_companies[index].products[0].price_exc) * parseFloat(percentage)) * (1 / this.$attrs.exchange_rates[this.cart_companies[index].products[0].product.company.trading_currency_code]);

		           	}
		           }else{
		           	itemscore = ((parseFloat(this.cart_companies[index].products[0].price_exc) * 1.2) * (1 / this.$attrs.exchange_rates[this.cart_companies[index].products[0].product.company.trading_currency_code])) * parseFloat(this.cart_companies[index].products[0].quantity);

		           	this.cart_companies[index].products[0].price_inc = (parseFloat(this.cart_companies[index].products[0].price_exc) * parseFloat(this.cart_companies[index].products[0].quantity) * 1.2) * (1 / this.$attrs.exchange_rates[this.cart_companies[index].products[0].product.company.trading_currency_code]);
		           }
		           	score = parseFloat(score) + parseFloat(itemscore);
		           	console.log(score);
	          }
	          this.cart_companies[index].productstotalinc = parseFloat(score).toFixed(2);
	          this.cart_totalinc = parseFloat(this.cart_totalinc) + parseFloat(score);
	      	 }
	          //return parseFloat(score).toFixed(2);
	        },*/
	         companiesTotalIncVat(zone_id, country_id, processing){
	        	//this.cart_companies = JSON.parse(JSON.stringify(this.cart_companies_raw));
	        	this.cart_totalinc = 0.00;
	        	for(var index = 0; index < this.cart_companies.length; index++){
	          	var score = 0;
	          		for(var i = 0; i < this.cart_companies[index].products.length; i++){
	          			if(processing == 'reset'){
	          			this.cart_companies[index].products[i].price_exc = this.cart_companies[index].products[i].price_exc / (1 / this.$attrs.exchange_rates[this.cart_companies[index].products[i].product.company.trading_currency_code]);
	          			this.cart_companies[index].products[i].price_inc = parseFloat(this.addTax(this.cart_companies[index].products[i].product, this.cart_companies[index].products[i].price_exc));
	          			}

	          			var itemscore = (parseFloat(this.addTax(this.cart_companies[index].products[i].product, this.cart_companies[index].products[i].price_exc)) * parseFloat(this.cart_companies[index].products[i].quantity)) * (1 / this.$attrs.exchange_rates[this.cart_companies[index].products[i].product.company.trading_currency_code]);
	          			score = parseFloat(score) + parseFloat(itemscore);

	          			
						if(processing == true){
	          			//this.cart_companies = JSON.parse(JSON.stringify(this.cart_companies_raw));
	          			this.cart_companies[index].products[i].price_exc = this.cart_companies[index].products[i].price_exc * (1 / this.$attrs.exchange_rates[this.cart_companies[index].products[i].product.company.trading_currency_code]);
	          			this.cart_companies[index].products[i].price_inc = parseFloat(this.addTax(this.cart_companies[index].products[i].product, this.cart_companies[index].products[i].price_exc));
	          			}
	          			
	          		}
	          		this.cart_companies[index].productstotalinc = parseFloat(score).toFixed(2);
	           this.cart_totalinc = parseFloat(this.cart_totalinc) + parseFloat(score);
	          	}
	          		
				
	        },
	        companiesTotal(index){
	         	//this.cart_companies = JSON.parse(JSON.stringify(this.cart_companies_raw));
	          var score = 0;
	         
	            for(var i = 0; i < this.cart_companies[index].products.length; i++){
	              var itemscore = (parseFloat(this.cart_companies[index].products[i].price_exc) * parseFloat(this.cart_companies[index].products[i].quantity)) * (1 / this.$attrs.exchange_rates[this.cart_companies[index].products[i].product.company.trading_currency_code]);
	              score = score + itemscore;
	            }
	         
	          this.cart_companies[index].productstotal = score;
	          return (score).toFixed(2);
	        },
	        getDiscountCoupon(cuid, index){
	        	axios.post('/customer-api/cart/coupon-search', {uid: cuid, search: this.couponsearch}, {headers: {
                  'Authorization': 'Bearer ' + this.user.api_token
              }}).then(response => {
	        		this.cart_companies[index].coupon = response.data;
	        		this.couponDiscountsTotal();
	        	}).catch(error => {
	        		this.error_reponse = error;
	        	});
	        },
		    addToCart(productdata, qty){
		      this.cartLoading = true;
		      if(this.cart.uid == ''){
		        this.cart.products.push({product: productdata});
		        this.createCart(productdata);
		      }else{
		        axios.post('/customer-api/cart/'+this.cart.uid+'/add-product', {product: productdata, quantity: qty}, {headers: {
                  'Authorization': 'Bearer ' + this.user.api_token
              }}).then(response => {
		        var carindex = this.cart.products.findIndex(item => item.product_id == productdata.id);
		            if(carindex !== -1){
		              this.cart.products[carindex] = response.data;
		              this.cart.products[carindex]["product"] = productdata;
		              window.sessionStorage.setItem("publiccart", JSON.stringify(this.cart));
		              this.productAdded(productdata);
		            }else if(carindex == -1){
		              this.cart["products"].push(response.data);
		              this.cart["products"][(this.cart.products.length - 1)]["product"] = productdata;
		              window.sessionStorage.setItem("publiccart", JSON.stringify(this.cart));
		              this.productAdded(productdata);
		            }
		      }).catch(error => {
		        this.error_reponse = error.response.data.errors;
		      });
		      } 
		    },
		    removeProduct(id, cindex, pindex){
		      axios.post('/customer-api/cart/product/'+id+'/delete', {}, {headers: {'Authorization' : 'Bearer '+this.user.api_token}}).then(response => {
		          this.cart_companies[cindex].products.splice(pindex, 1);
		          if(this.cart_companies[cindex].products.length == 0){
		          	this.cart_companies.splice(cindex, 1);
		          }
		          this.cart = response.data;
		          EventBus.$emit('update-cart', response.data);
		          window.sessionStorage.setItem("publiccart", JSON.stringify(response.data));
		        }).catch(error => {
		          this.error_reponse = error.response.data.errors;
		        });
		    },
		   
		    productAdded(product){
		      //this.cartPanelData = product;
		      //this.cartPanel = true;
		      this.cartLoading = false;
		      this.$refs.cart.show(true);
		    },
		    createCart(product){
		      axios.post('/customer-api/cart/create', product, {headers: {
                  'Authorization': 'Bearer ' + this.user.api_token
              }}).then(response => {
		        this.cart = response.data[0];
		        this.cart["products"] = [];
		        this.cart.products.push(response.data[1]);
		        this.cart.products[0]["product"] = product;
		        window.sessionStorage.setItem("publiccart", JSON.stringify(this.cart));
		      }).catch(error => {

		      });
		  },
		  getShippingOptions(zone_id, country_id){
		  	for(var i = 0; i < this.cart_companies.length; i++){
		  		var self = this;
						axios.all([getShipping(self.cart_companies[i].uid, self.user.api_token, i, zone_id, country_id)]).then(axios.spread(function (response) {
							//companyList[ia].push(response.data)
							//console.log(response);
							self.cart_companies[response[1]].shippingoptions = response[0].data; 
						}));
			  	
		  	}
		  	async function getShipping(company_uid, api_token, index, zone_id, country_id){
			  		return [await axios.post('/customer-api/cart/shipping-options', { uid: company_uid, zone: zone_id, country: country_id}, {headers: { 'Authorization' : 'Bearer '+api_token }}), index ];
			  	}
		  },
		  getPurchaseOrders(){
		  	axios.get('/api/company/product/purchases?api_token='+this.user.api_token+'&page='+this.currentPage+'&limit='+this.purchaselimit+'&sortby=id&order=desc&search='+this.purchasesearch).then(response => {
		        console.log(response);
		        this.purchase_orders = response.data;
		      });
		  },
		  getListings(){
	      axios.get('/customer-api/user/addresses?page='+this.currentPage+'&limit='+this.listingslimit+'&sortby='+this.listingssortby+'&order=desc&search='+this.listingsearch+'&type_id='+this.type_id+'&active='+this.listingactive+'&public='+this.listingpublic_active+'&trade='+this.listingtrade_active, {headers: {'Authorization' : 'Bearer '+this.user.api_token}}).then(response => {
	      this.listingsData = response.data;
	      }).catch(error => {
	        this.shops_error = error.data;
	      });
    	},
    },

	}

</script>

<template>
	<b-container fluid>
	<b-row>
		<b-col md="8" class="">
			<b-overlay
			:show="cartLoading"
	        variant="white"
	        opacity="0.85"
	        blur="1px"
	        rounded="xl"
			>
			<b-card no-body class="bg-transparent shadow-none mb-0">
			<b-row>
				<b-col md="6">
					<b-card class="border-radius-1em">
						<h4 class="card-title mb-3">Delivery Address</h4>
	        		<div class="mt-sm-0 form-inline w-100" v-if="delivery_address == null" >
              <div class="search-box mr-2 ml-auto my-3">
                <div class="position-relative">
                  <input
                    type="text"
                    class="form-control w-100 border-0 bg-light"
                    placeholder="Search name, reference or description..."
                    v-model="listingsearch" @keydown.enter="getListings()" 
                  />
                  <i class="bx bx-search-alt search-icon"></i>
                </div>
              </div>
            </div>
            <div v-if="delivery_address == null">
	        		<simplebar
                            style="height: 300px"
                        >
                            <li class="list-group-item" v-for="listing in listingsData">
                                <div class="media">
                                    
                                       <div class="avatar-sm me-3">
                                        <span class="avatar-title rounded-circle" >{{(listing.alias).slice(0,1)}}</span>
                                      </div>
                                   
                                    <div class="media-body">
                                        <h5 class="font-size-14">
                                            {{listing.alias}}
                                        </h5>
                                        <p class="text-muted">
                                            <span v-if="listing.address_1">{{listing.address_1}},</span>
				          	<span v-if="listing.address_2">{{listing.address_2}},</span>
				          	<span v-if="listing.address_3">{{listing.address_3}},</span>
				          	<span v-if="listing.county">{{listing.county.name}},</span>
				          	<span v-if="listing.country">{{listing.country.name}},</span>
				          	<span v-if="listing.postcode">{{listing.postcode}}</span>
                                        </p>

                                        <div class="float-end">
                                            <p class="text-muted my-2" v-if="listing.listing_type">
                                                <i 
                                                    class="bx bx-buildings me-1"
                                                ></i>
                                                {{listing.listing_type.name}}
                                            </p>
                                        </div>
                                        <p class="text-muted mb-0">
                                            <a class="btn btn-primary" @click="delivery_address = listing, customer.customer_delivery_address_id = listing.id, getShippingOptions(listing.country.country_zone_id, listing.country_id), couponDiscountsTotal(), companiesTotalIncVat(delivery_address.country == null ? null : delivery_address.country.country_zone_id, delivery_address.county == null ? null : delivery_address.country.id, false)">Use Location</a>
                                        </p>
                                    </div>
                                </div>
                            </li>
                            <li class="btn btn-block btn-outline-light" v-if="listinglimit < listingsData.total" @click="listingslimit += 25">Load More</a>
                            </li>
                        </simplebar>
                    </div>
                    <div class="border p-4 text-center d-flex flex-column" v-else style="height: 350px">
                    	<b-row class="my-auto">
                    		<b-col md="12">
                    				
                                       <div class="avatar-sm mx-auto mb-3" @mouseover="closeHover = 'delivery'" @mouseout="closeHover = false">
                                        <span class="avatar-title rounded-circle" v-if="closeHover !== 'delivery'" >{{(delivery_address.alias).slice(0,1)}}</span>
                                        <a v-else class="avatar-title rounded-circle" @click="delivery_address = null, removeShipping(), shipping_total = 0.00, closeHover = false">
                                        	<i class="bx bx-x text-white font-size-20"></i>
                                        </a>
                                      </div>
                                      <h4>{{delivery_address.alias}}</h4>

                                      <p class="text-muted">
				                            <span v-if="delivery_address.address_1">{{delivery_address.address_1}}<br></span>
								          	<span v-if="delivery_address.address_2">{{delivery_address.address_2}}<br></span>
								          	<span v-if="delivery_address.address_3">{{delivery_address.address_3}}<br></span>
								          	<span v-if="delivery_address.county">{{delivery_address.county.name}}<br></span>
								          	<span v-if="delivery_address.country">{{delivery_address.country.name}}<br></span>
								          	<span v-if="delivery_address.postcode">{{delivery_address.postcode}}</span>
                                        </p>
											
                                        
                            </b-col>
                         </b-row>
                    </div>
					</b-card>
				</b-col>
				<b-col md="6">
					<b-card class="border-radius-1em">
						<h4  class="card-title mb-3 d-flex">Invoice Address <a v-if="(invoice_address !== null) && (delivery_address !== null) && (invoice_address !== delivery_address)" class="text-muted font-size-12 text-weight-normal ml-auto" @click="invoice_address = delivery_address, customer.customer_invoice_address_id = delivery_address.id" href="javascript:;">Use Delivery Address</a></h4>
						<div class="p-4 border d-flex flex-column" v-if="invoice_address == null" style="height: 350px">
							<a class="btn btn-primary mb-1 mt-auto mx-auto" @click="invoice_address = delivery_address, customer.customer_invoice_address_id = delivery_address.id">Same as Delivery Address</a>
							<a class="btn btn-primary mt-1 mb-auto mx-auto" @click="invoice_address = ''">Select Address</a>
						</div>
	        		<div class="mt-sm-0 form-inline w-100" v-if="(invoice_address !== null) && (invoice_address == '') && (invoice_address !== delivery_address)" >
		              <div class="search-box mr-2 ml-auto my-3">
		                <div class="position-relative">
		                  <input
		                    type="text"
		                    class="form-control w-100 border-0 bg-light"
		                    placeholder="Search name, reference or description..."
		                    v-model="listingsearch" @keydown.enter="getListings()" 
		                  />
		                  <i class="bx bx-search-alt search-icon"></i>
		                </div>
		              </div>
		            </div>
		            <div v-if="(invoice_address == '') && (invoice_address !== delivery_address)">
	        		<simplebar
                            style="height: 300px" 
                        >
                            <li class="list-group-item" v-for="listing in listingsData">
                                <div class="media align-items-center">
                                   
                                       <div class="avatar-sm me-3">
                                        <span class="avatar-title rounded-circle" >{{(listing.alias).slice(0,1)}}</span>
                                      </div>
                                   
                                    <div class="media-body">
                                        <h5 class="font-size-14">
                                            {{listing.alias}}
                                        </h5>
                                        <p class="text-muted">
                                            <span v-if="listing.address_1">{{listing.address_1}},</span>
				          	<span v-if="listing.address_2">{{listing.address_2}},</span>
				          	<span v-if="listing.address_3">{{listing.address_3}},</span>
				          	<span v-if="listing.county">{{listing.county.name}},</span>
				          	<span v-if="listing.country">{{listing.country.name}},</span>
				          	<span v-if="listing.postcode">{{listing.postcode}}</span>
                                        </p>

                                        <div class="float-end">
                                            <p class="text-muted mb-0" v-if="listing.listing_type">
                                                <i 
                                                    class="bx bx-buildings me-1"
                                                ></i>
                                                {{listing.listing_type.name}}
                                            </p>
                                        </div>
                                        <p class="text-muted mb-0">
                                            <a class="btn btn-primary" @click="invoice_address = listing, customer.customer_invoice_address_id = listing.id">Use Location</a>
                                        </p>
                                    </div>
                                </div>
                            </li>
                             <li class="list-group-item">
                            	<a class="btn btn-block btn-outline-light" v-if="listinglimit < listingsData.total" @click="listingslimit += 25">Load More</a>
                            </li>
                        </simplebar>
                    </div>
                    <div class="border p-4 text-center d-flex flex-column" v-if="(invoice_address !== '') && (invoice_address !== null)" style="height: 350px">
                    	<b-row class="my-auto">
                    		<b-col md="12">
                    				<div class="avatar-sm mx-auto mb-3" v-if="invoice_address.logo">
                                        <span
                                            class="avatar-title rounded-circle bg-light"
                                        >
                                            <!--<img
                                            		
                                                :src="delivery_address.logo"
                                                alt=""
                                                height="18"
                                            />-->
                                            
                                        </span>
                                      </div>
                                       <div class="avatar-sm mx-auto mb-3" v-else @mouseover="closeHover = 'invoice'" @mouseout="closeHover = null">
                                        <span class="avatar-title rounded-circle" v-if="closeHover !== 'invoice'" >{{(invoice_address.alias).slice(0,1)}}</span>
                                        <a v-else class="avatar-title rounded-circle " @click="invoice_address = null, closeHover = false">
                                        	<i class="bx bx-x text-white font-size-20"></i>
                                        </a>
                                      </div>
                                      <h4>{{invoice_address.alias}}</h4>

                                      <p class="text-muted">
				                            <span v-if="invoice_address.address_1">{{invoice_address.address_1}}<br></span>
								          	<span v-if="invoice_address.address_2">{{invoice_address.address_2}}<br></span>
								          	<span v-if="invoice_address.address_3">{{invoice_address.address_3}}<br></span>
								          	<span v-if="invoice_address.county">{{invoice_address.county.name}}<br></span>
								          	<span v-if="invoice_address.country">{{invoice_address.country.name}}<br></span>
								          	<span v-if="invoice_address.postcode">{{invoice_address.postcode}}</span>
                                        </p>

                                        
                            </b-col>
                         </b-row>
                    </div>
					</b-card>
				</b-col>
			</b-row>
			<b-card class=" border-radius-1em">
				<h4 class="card-title mb-3">Cart Summary</h4>
				<b-list-group-item v-if="cart.products.length == 0">
	              <div class="p-5 text-center border">
	                <i class="bx bx-shopping-bag font-size-42 text-secondary mb-2"></i>
	                <h6>No Products in Cart</h6>

	                <router-link class="btn btn-primary mt-2 mx-auto" to="/category/jewellery">
	                	Start Shopping
	                </router-link>
	              </div>
	            </b-list-group-item>
			<b-list-group v-for="(company, cindex) in cart_companies">
			<div>
				<b-card class="shadow-none d-flex border border-radius-1em mb-2 mt-4"><h3>You're ordering {{company.length}} item(s) from:</h3><b-img-lazy v-if="company.products[0].company !== null && company.products[0].company.logo !== null" :src="(company.products[0].company.logo.includes(storageurl) == false ? ('https://' + company.products[0].company.nickname + storageurl + company.products[0].company.logo) : company.products[0].company.logo)" class="avatar-sm rounded-circle border border-light" />
			<span v-if="company.products[0].company !== null">{{company.products[0].company.name}}</span></b-card></div>
            	<b-list-group-item  v-for="(productitem, pindex) in company.products" href="javascript: void(0);" class="text-reset notification-item px-3 py-3 m-0">
	              <b-row>
	                <div class="col-md-1 d-flex">
	                  <b-img-lazy v-if="productitem.product.cover_image" :src="'https://'+company.products[0].company.nickname + storageurl + productitem.product.cover_image.src.replaceAll('//', '/')" class="rounded-circle avatar-sm border my-auto" />
	                  <div v-else class="avatar-sm">
	                  <span class="avatar-title bg-primary rounded-circle font-size-16" >
	                   <i class="bx bx-cart"></i>
	                  </span>
	              </div>
	                </div>
	                <div class="col-md-4 d-flex">
	                  <router-link class="my-auto text-truncate" :to="'seller/'+company.products[0].company.nickname+'/product/'+productitem.product.url_rewrite" target="_blank"><h6 class="my-auto text-truncate">{{productitem.product.name}}</h6></router-link>
	                  
	                </div>
	                <div class="col-md-7">
	                	<b-row>
	                		<b-col md="3" class="my-auto">
			                  <!--<i class="bx bx-chevron-up mb-auto mx-auto"></i>-->
			                  <b-form-input type="number"  v-model="productitem.quantity" @input="cart_companies_raw[cindex].products[pindex].quantity = productitem.quantity, companiesTotalIncVat(delivery_address.country == null ? null : delivery_address.country.country_zone_id, delivery_address.county == null ? null : delivery_address.country.id, false), cartTotal(cart), companiesTotal(cindex), updateProductQuantity(productitem, productitem.quantity, null, null)" :max="productitem.product.total_qty" class="visible w-100" >
			                  </b-form-input>
			                  <!--<span class="mr-auto my-auto"><strong class="text-secondary">{{productitem.product.total_qty}}</strong> available</span>
			                  <span class="mx-auto my-auto">{{productitem.quantity}}</span>
			                  <i class="bx bx-chevron-down mt-auto mx-auto"></i>-->
			                </b-col>
			                <b-col md="6">
			                	<b-row class="mb-2">
			                		<b-col md="6" class="my-auto text-left">
					                  <span class="text-muted">{{$attrs.selected_currency_symbol}}</span>{{parseFloat(productitem.price_exc * (1 / $attrs.exchange_rates[productitem.product.company.trading_currency_code])).toFixed(2)}}
					                  <small class="d-block text-muted">Item price exc.</small>
					                </b-col>
					                <b-col md="6" class="my-auto text-left">
					                  <span class="text-muted">{{$attrs.selected_currency_symbol}}</span>{{((productitem.price_exc * productitem.quantity) * (1 / $attrs.exchange_rates[productitem.product.company.trading_currency_code])).toFixed(2)}} <small class="d-block text-muted">Sub-total exc.</small>
					                </b-col>
			                	</b-row>
			                	<b-row>
			                		<b-col md="6" class="my-auto text-left">
					                  <span class="text-muted">{{$attrs.selected_currency_symbol}}</span>{{(addTax(productitem.product, productitem.price_exc) * (1 / $attrs.exchange_rates[productitem.product.company.trading_currency_code])).toFixed(2)}}
					                  <small class="d-block text-muted">Item price inc.</small>
					                </b-col>
					                <b-col md="6" class="my-auto text-left">
					                  <span class="text-muted">{{$attrs.selected_currency_symbol}}</span>{{((addTax(productitem.product, productitem.price_exc) * productitem.quantity) * (1 / $attrs.exchange_rates[productitem.product.company.trading_currency_code])).toFixed(2)}} <small class="d-block text-muted">Sub-total inc.</small>
					                </b-col>
			                	</b-row>
			                </b-col>
			                 
			                <b-col class="my-auto text-center px-0 d-flex" md="2">
			                 <a class="btn btn-danger btn-rounded btn-md mr-2" @click="removeProduct(productitem.id, cindex, pindex)"><i class="bx bx-trash-alt"></i></a>
			                
			                  <a v-b-tooltip.hover title="View Customisations" v-if="((productitem.combinations.length > 0) || (productitem.variants.length > 0)) && (customisationdropdown !== pindex)" class="btn btn-primary btn-rounded btn-md" @click="customisationdropdown = pindex"><i class="bx bx-chevron-down"></i></a>
			                 <a v-b-tooltip.hover title="View Customisations" v-else-if="((productitem.combinations.length > 0) || (productitem.variants.length > 0)) && (customisationdropdown == pindex)" class="btn btn-primary btn-rounded btn-md" @click="customisationdropdown = -2"><i class="bx bx-chevron-up"></i></a>
			                </b-col>
			               
	            		</b-row>
	              </div>
	          		</b-row>
	          	<b-row v-if="(customisationdropdown == pindex)">
	          		<b-col md="12">

	          		<b-row  v-if="(productitem.combinations.length == 0) && (productitem.variants.length > 0)" class="mt-4">
				<b-col md="12">
				<b-card class="border-radius-1em border shadow-none">
					<h3 class="font-size-18 text-center mb-4">Customisation Options</h3>
				<div class="w-100 mb-3" v-for="(variants, key) in productitem.grouped_variants">
				
					<h5 class="text-uppercase font-size-12 spaced-title my-2">{{variants[0].variant.attribute.name}}</h5>
				
				
					<b-row >
						<b-col md="12" v-for="value in variants">
						<b-overlay :show="loadingVariants">
							<a href="javascript:;">
							<b-card class="border-top border-bottom bg-transparent shadow-none text-dark mb-0" body-class="d-flex">
								<span class="my-auto" v-if="value.variant.value !== null"><b-img-lazy v-if="value.variant.value.image !== null" :src="storageurl + '/' + value.variant.value.image + '?s='+productitem.product.company.nickname" class="avatar-xs rounded-circle mr-2 border" /> 
								</span>
								<span class="my-auto mr-2" v-if="value.variant.value !== null">{{value.variant.value.name}}</span> <span class="my-auto">{{value.variant.measurement_value}}</span><span class="my-auto mr-2" v-if="value.variant.units !== null">{{value.variant.units.suffix}}</span>
								<span class="ml-auto my-auto">
									{{value.quantity}} X
								</span>
								<div class="d-flex flex-column">
									<span class="badge badge-light bg-light my-auto ml-3 font-size-14">
									<span class="ml-auto my-auto" v-if="value.variant.price_difference > 0">+</span><span class="ml-auto my-auto" v-else>-</span><span>{{$attrs.selected_currency_symbol}}</span>{{parseFloat(value.variant.price_difference * (1 / $attrs.exchange_rates[productitem.product.company.trading_currency_code])).toFixed(2)}}
									</span>
									<small class="ml-auto my-auto">Exc. VAT</small>
								</div>
								<div class="d-flex flex-column">
									<span class="badge badge-light bg-light my-auto ml-3 font-size-14">
									<span class="ml-auto my-auto" v-if="value.variant.price_difference > 0">+</span><span class="ml-auto my-auto" v-else>-</span><span>{{$attrs.selected_currency_symbol}}</span>{{parseFloat(addTax(productitem.product, value.variant.price_difference) * (1 / $attrs.exchange_rates[productitem.product.company.trading_currency_code])).toFixed(2)}}
									</span>
									<small class="ml-auto my-auto">Inc. VAT</small>
								</div>
							</b-card>
							</a>
						</b-overlay>
						</b-col>
					</b-row>
					</div>
				</b-card>
				</b-col>
				
		</b-row>
		<b-row v-else-if="productitem.combinations.length > 0">
			<b-col>
				<b-card class="border-radius-1em border shadow-none mt-3">
						<h3 class="font-size-18 text-center mb-4">Customisation Options</h3>
				<div class="w-100" v-for="(combination, index) in productitem.combinations">
					<a href="javascript:;" >
						<b-card  class="border-top border-bottom bg-transparent shadow-none text-dark mb-0">
					<b-row>
						<b-col md="3" v-for="value in combination.combination_variants">
							
							
								<span v-if="value.variant.attribute !== null">{{value.variant.attribute.name}}</span> 
								<span v-if="value.variant.value !== null">{{value.variant.value.name}}</span> {{value.variant.measurement_value}}<span v-if="value.variant.units !== null">{{value.variant.units.suffix}}</span>
								
								
							
							
						</b-col>
					</b-row>
					<b-row>
						<b-col md="2" class="d-flex offset-md-7">
							<span class="ml-auto my-auto">Quantity: {{combination.quantity}}</span>
						</b-col>
						<b-col md="3" class="d-flex">
							<div class="d-flex flex-column mr-2">
							<span class="badge badge-light bg-light ml-auto mt-2 font-size-14">
							<span v-if="combination.price_difference > 0">+</span><span v-else>-</span><span>{{$attrs.selected_currency_symbol}}</span>{{parseFloat(combination.price_difference * (1 / $attrs.exchange_rates[productitem.product.company.trading_currency_code])).toFixed(2)}}
								</span>
								<small class="ml-auto my-auto">Exc. VAT</small>
							</div>
							<div class="d-flex flex-column">
							<span class="badge badge-light bg-light ml-auto mt-2 font-size-14">
							<span v-if="combination.price_difference > 0">+</span><span v-else>-</span><span>{{$attrs.selected_currency_symbol}}</span>{{parseFloat(addTax(productitem.product, combination.price_difference) * (1 / $attrs.exchange_rates[productitem.product.company.trading_currency_code])).toFixed(2)}}
								</span>
								<small class="ml-auto my-auto">Inc. VAT</small>
							</div>
						</b-col>
					</b-row>
					</b-card>
					</a>
				</div>
				</b-card>
			</b-col>
	       </b-row>
	          	</b-col>
	          </b-row>
            	</b-list-group-item>
            	<b-list-group-item v-if="cart.products.length == 0">
	              <div class="p-5 text-center border">
	                <i class="bx bx-shopping-bag font-size-42 text-secondary mb-2"></i>
	                <h6>No Products in Cart</h6>
	              </div>
	            </b-list-group-item>
            	<b-card class="shadow-none d-flex border border-radius-1em mb-2 mt-2">
            		<h6 class="mb-4" v-if="company.products[0].company !== null">Shipping Options for {{company.products[0].company.name}}:</h6>
            		<b-row>
            			<b-col md="12" v-if="delivery_address == null">
            				<b-card class="shadow-none text-center border border-radius-1em">
            					<i class="bx bxs-truck font-size-24 text-primary"></i>
            					<h6>Please Select a Delivery Address To See Shipping Options</h6>
            				</b-card>
            			</b-col>
            			<b-col md="3"  v-else v-for="shippingoption in company.shippingoptions">
	            			<b-card class="shadow-sm text-center border border-radius-1em py-3" @click="cart_companies[cindex].company_shipping_option_id = shippingoption.id, cart_companies[cindex].shippingtotal = ( calculateShippingPrice(shippingoption.ranges, cart_companies[cindex].productstotal, shippingoption.free_shipping) * (1 / this.$attrs.exchange_rates[company.products[0].product.company.trading_currency_code])), cart_companies[cindex].shippingtotalinc = (calculateShippingPriceInc(shippingoption, cart_companies[cindex].productstotal, shippingoption.free_shipping, delivery_address.country_zone_id, delivery_address.country_id) * (1 / this.$attrs.exchange_rates[company.products[0].product.company.trading_currency_code])), shippingTotal(), shippingTotalInc(), couponDiscountsTotal()" :class="{'bg-primary text-white': cart_companies[cindex].company_shipping_option_id == shippingoption.id, 'bg-white text-dark': cart_companies[cindex].company_shipping_option_id !== shippingoption.id}">
	            				<b-img-lazy v-if="shippingoption.icon !== null && shippingoption.icon !== ''" :src="storageurl+shippingoption.icon"></b-img-lazy>
	            				<i v-else class="bx bxs-truck d-block font-size-24" :class="{'text-white': cart_companies[cindex].company_shipping_option_id == shippingoption.id, 'text-primary': cart_companies[cindex].company_shipping_option_id !== shippingoption.id}"></i>
	            				{{shippingoption.name}}
	            				<small v-if="shippingoption.free_shipping == 1" class="d-block shipping-price font-italic" :class="{'text-white': cart_companies[cindex].company_shipping_option_id == shippingoption.id, 'text-muted': cart_companies[cindex].company_shipping_option_id !== shippingoption.id}">
	            					+{{$attrs.selected_currency_symbol}}0.00
	            				</small>
	            				<small v-else class="d-block font-italic text-center" >
	            					+{{$attrs.selected_currency_symbol}}{{calculateShippingPrice(shippingoption.ranges, cart_companies[cindex].productstotal, shippingoption.free_shipping)}}
	            				</small>
	            			</b-card>
            			</b-col>
            		</b-row>
            	</b-card>
            	<b-card class="shadow-none d-flex border border-radius-1em mb-2 mt-2">
            		<b-row>
            			<b-col md="4">
            				<h6 class="mb-4" v-if="company.products[0].company !== null">Add Seller Discount Coupon</h6>
            			</b-col>
            			<b-col md="8">
            				 <div class="mt-sm-0 form-inline w-100" v-if="company.coupon == ''" >
				              <div class="search-box mr-2 ml-auto mt-3">
				                <div class="position-relative">
				                  <input
				                    type="text"
				                    class="form-control w-100 border-0 bg-light"
				                    placeholder="Enter coupon code"
				                    v-model="couponsearch" @keydown.enter="getDiscountCoupon(company.uid, cindex)" 
				                  />
				                  <i class="bx bx-search-alt search-icon"></i>
				                </div>
				              </div>
				            </div>
				            <div class="d-flex border border-radius-1em p-3" v-if="company.coupon !== ''">
				            	<div class="avatar-sm my-auto">
				            		<span class="avatar-title rounded-circle">
				            			{{company.coupon.code.slice(0,1)}}
				            		</span>
				            	</div>
				            	<div class="media-body my-auto ml-3">
				            		<h5 class="mb-0">{{company.coupon.code}}</h5>
				            		<p class="mb-0">{{company.coupon.description}}</p>
				            		
				            	</div>
				            	<div class="ml-auto my-auto">
				            		<h5 class="mb-0 text-primary font-size-24">
				            		<span v-if="company.coupon.discount_type == 2">{{$attrs.selected_currency_symbol}}</span>{{company.coupon.discount_amount}}<span v-if="company.coupon.discount_type == 1">%</span>
				            		</h5>
				            	</div>
				            	<div class="ml-3 my-auto" >
				            		<a href="javascript:;" class="btn btn-rounded btn-danger" @click="company.coupon = '', couponDiscountsTotal()">
				            			<i class="bx bx-trash-alt"></i>
				            		</a>
				            	</div>
				            </div>
            			</b-col>
            		</b-row>
            	</b-card>
          </b-list-group>
            </b-card>
        </b-card>
        <template #overlay>
            <div class="cube-wrapper"><div class="cube-folding"><span class="leaf1"></span><span class="leaf2"></span><span class="leaf3"></span><span class="leaf4"></span></div></div>
          </template>
        </b-overlay>
		</b-col>
		
		<b-col md="4" class="d-flex flex-column">
			
			<div>
			<b-overlay
			:show="cartLoading"
	        variant="white"
	        opacity="0.85"
	        blur="1px"
	        rounded="xl"
			>
			<b-card class="border-radius-1em ">
				<h4 class="card-title mb-3">Payment Summary</h4>

         
				<b-list-group flush>
					<b-list-group-item class="d-flex w-100">
						<h6 class="mt-auto">Cart Total <small>Exc. VAT</small></h6>
						<h4 class="my-auto ml-auto text-muted"><small>{{$attrs.selected_currency_symbol}}{{cart_total}}</small></h4>
					</b-list-group-item>
					
					<b-list-group-item class="d-flex w-100">
						<h6 class="mt-auto">Shipping Total <small>Exc. VAT</small></h6>
						<h4 class="my-auto ml-auto text-muted"><small>+{{$attrs.selected_currency_symbol}}</small><small v-if="shipping_total > 0">{{shipping_total}}</small><span v-else><small>0.00</small></span></h4>
					</b-list-group-item>
			
					<b-list-group-item class="d-flex w-100">
						<h6 class="mt-auto">Customisation Costs <small>Exc. VAT</small></h6>
						<h4 class="my-auto ml-auto text-muted"><small>+{{$attrs.selected_currency_symbol}}</small><small>{{(parseFloat(cartCombinationTotals[0]) + parseFloat(cartVariationsTotals[0])).toFixed(2)}}</small></h4>
					</b-list-group-item>
					<b-list-group-item class="d-flex w-100">
						<h6 class="mt-auto">Taxes</h6>
						<h4 class="my-auto ml-auto text-muted"><small>+{{$attrs.selected_currency_symbol}}</small><small v-if="delivery_address !== ('' || null)">{{(parseFloat(cart_totalinc - cart_total) + (parseFloat(shipping_totalinc) - parseFloat(shipping_total)) + ( parseFloat(cartVariationsTotals[1]) - parseFloat(cartVariationsTotals[0])) + ( parseFloat(cartCombinationTotals[1]) - parseFloat(cartCombinationTotals[0]))).toFixed(2)}}</small><span v-else><small>0.00</small></span></h4>
					</b-list-group-item>
					<b-list-group-item class="d-flex w-100">
						<h6 class="mt-auto">Discounts & Adjustments</h6>
						<h4 class="my-auto ml-auto text-muted">
							<small>-{{$attrs.selected_currency_symbol}}</small><small v-if="(discounts_totalinc > 0) && (delivery_address !== null)">{{parseFloat(discounts_totalinc).toFixed(2)}}</small><small v-if="(delivery_address == null) && (discounts_total > 0)">{{parseFloat(discounts_total).toFixed(2)}}</small><span v-if="(discounts_totalinc < 0.009) && (discounts_total < 0.009)"><small>0.00</small></span>
						</h4>
					</b-list-group-item>

					<b-list-group-item class="d-flex w-100">
						<h6 class="mt-auto">Amount Payable <small>Inc. VAT</small></h6>
						<h2 class="my-auto ml-auto "><small>{{$attrs.selected_currency_symbol}}</small><span class="text-primary" v-if="delivery_address !== ('' || null)">{{parseFloat(parseFloat(cart_totalinc) + parseFloat(cartCombinationTotals[1]) + parseFloat(cartVariationsTotals[1]) + parseFloat(shipping_totalinc) - parseFloat(discounts_totalinc)).toFixed(2)}}</span>
							<span class="text-primary" v-else>{{(parseFloat(cartTotal(cart)) + parseFloat(cartCombinationTotals[1]) + parseFloat(cartVariationsTotals[1]) + parseFloat(shipping_totalinc) - parseFloat(discounts_total)).toFixed(2)}}</span></h2>
					</b-list-group-item>
				</b-list-group>
			</b-card>
			<template #overlay>
            <div class="cube-wrapper"><div class="cube-folding"><span class="leaf1"></span><span class="leaf2"></span><span class="leaf3"></span><span class="leaf4"></span></div></div>
          </template>
        </b-overlay>
			<b-card class="shadow-none border-radius-1em border">
				<b-form-group>
					<b-form-checkbox value="1" v-model="accpted_terms">
						<span class="ml-3">I accept the <a href="javascript:;" v-b-modal.terms >terms and conditions</a> of sale</span>
					</b-form-checkbox>
				</b-form-group>
			</b-card>
			<b-button v-if="orderCreated == false" variant="success" :disabled="((accpted_terms == 0) || (delivery_address == ('' || null)) || (invoice_address == ('' || null)) || (this.shippingSelected == false))" class="btn btn-success btn-block" @click="processToPayment()">Create Order</b-button>
					<CartPayment v-if="(show_payment_form == true)" :user="user" :company="company" :order="order_details" :amount_payable="amount_payable" :selected_currency="$attrs.selected_currency" :selected_currency_symbol="$attrs.selected_currency_symbol" :exchange_rates="$attrs.exchange_rates" @close-window="show_payment_form = false, orderCreated = false, companiesTotalIncVat(delivery_address.country == null ? null : delivery_address.country.country_zone_id, delivery_address.county == null ? null : delivery_address.country.id, 'reset'), processing = false, companyCartCombinationTotals('reset'), companyCartVariationaTotals('reset')" ></CartPayment>
			</div>
		</b-col>
		
	</b-row>
	<b-modal id="terms" size="lg" scrollable @ok="accpted_terms = 1" @cancel="accpted_terms = 0" centered>
		<b-card class="border-radius-1em border shadow-none">
			<h4>Terms & Conditions of Sale</h4>
			<div v-html="terms.public.product">
			</div>
			
		</b-card>
		<template #modal-ok>
				Accept
			</template>
			<template #modal-cancel>
				Decline
			</template>
	</b-modal>
</b-container>
</template>