<script>
import VueSlideBar from "vue-slide-bar";

/**
 * Pricing component
 */
export default {
  components: { VueSlideBar },
  data() {
    return {
    	stripeAPIToken: process.env.MIX_STRIPE_KEY,
        payment_term: 'monthly',
        intentToken: '',
		cardholder_name: '',
		addPaymentStatus: 0,
    paymentResult: '',
    paymentFormLoading: false,
		addPaymentStatusError: '',
		show_payment_form: false,
		selected_plan: '',
    current_price: 0.00,
    current_flat_price: 0.00,
		stripe: '',
    user_quantity: 1,
    shop_quantity: 1,
		elements: '',
		plans: '',
     loader: null,
      loadingValue: 0,
      customStyle: 800,
      lineHeight: 10,
		card: '',
      	title: 'Pricing',
      	name: 'account-subscription',
      	items: [
        {
          text: 'Utility',
          href: '/',
        },
        {
          text: 'Pricing',
          active: true,
        },
      ],
    }
  },
  computed:{
   /* userSubscriptionPrice: function(){
     var index = this.plans.findIndex(item => (item.users[0] <= this.user_quantity) && (item.users[1] >= this.user_quantity));
        return ((parseFloat(this.plans[index].price_inc) * parseFloat(this.user_quantity)) + parseFloat(this.plans[index].flat_price_inc)).toFixed(2);
    },*/

    userSubscriptionPrice: function(){
      if(this.user_quantity < 6){
        this.current_price = parseFloat(8.99).toFixed(2);
        this.current_flat_price = parseFloat(50.00).toFixed(2);
        this.shop_quantity = 1;
      }else if((this.user_quantity > 5) && (this.user_quantity <= 50)){
        this.current_price = parseFloat(7.99);
        this.current_flat_price = parseFloat(100.00).toFixed(2);
        if(this.shop_quantity >= 10){
        this.shop_quantity = 2;
        }
      }else if((this.user_quantity > 50) && (this.user_quantity <= 200)){
        this.current_price = parseFloat(6.99);
        this.current_flat_price = parseFloat(200.00).toFixed(2);
        if(this.shop_quantity < 10){
        this.shop_quantity = 10;
        }
      }
      return parseFloat(parseFloat(this.user_quantity * this.current_price) + parseFloat(this.current_flat_price)).toFixed(2);
    },
    userYearlySubscriptionPrice: function(){
      return parseFloat(parseFloat(this.userSubscriptionPrice) * parseFloat(12)).toFixed(2);
    },
  },
  methods:{
            subscriptionConfirmation(){
              this.$emit('payment-success', this.paymentResult, this.selected_plan, this.user_quantity, this.current_price, this.current_flat_price);
            },
            loadPaymentForm(plan){
                    this.selected_plan = plan;
                axios.get('/api/company/subscription/setup-intent?uid='+this.$attrs.company.uid+'&price='+this.userSubscriptionPrice+'', {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
              }})
                .then( function( response ){
                    this.intentToken = response.data;
                    return this.includeStripe('js.stripe.com/v3/', function(){
                    this.configureStripe();

                  }.bind(this) );

                }.bind(this));
                      
            },
            flatPrice(plan){
            if(this.user_quantity < 6){
              this.current_price = parseFloat(8.99).toFixed(2);
              this.current_flat_price = parseFloat(50.00).toFixed(2);
              this.shop_quantity = 1;
            }else if((this.user_quantity > 5) && (this.user_quantity <= 50)){
              this.current_price = parseFloat(7.99);
              this.current_flat_price = parseFloat(100.00).toFixed(2);
              if(this.shop_quantity >= 10){
              this.shop_quantity = 2;
              }
            }else if((this.user_quantity > 50) && (this.user_quantity <= 200)){
                this.current_price = parseFloat(6.99);
                this.current_flat_price = parseFloat(200.00).toFixed(2);
                if(this.shop_quantity < 10){
                this.shop_quantity = 10;
                }
              }
            },
            includeStripe( URL, callback ){
                        let documentTag = document, tag = 'script',
                            object = documentTag.createElement(tag),
                            scriptTag = documentTag.getElementsByTagName(tag)[0];
                        object.src = '//' + URL;
                        if (callback) { object.addEventListener('load', function (e) { callback(null, e); }, false); }
                        scriptTag.parentNode.insertBefore(object, scriptTag);
            },
            configureStripe(){
                /*this.stripe = Stripe( this.stripeAPIToken );

                this.elements = this.stripe.elements();
                this.card = this.elements.create('card');

                this.card.mount('#card-element');
                */

                this.stripe = Stripe( this.stripeAPIToken );

                this.elements = this.stripe.elements({clientSecret: this.intentToken.client_secret});
                this.card = this.elements.create('payment', {clientSecret: this.intentToken.client_secret});
                this.show_payment_form = true;
                this.card.mount('#payment-element');
            },
            submitPaymentMethod(){
                    this.addPaymentStatus = 1;
                    this.paymentFormLoading = true;
                    this.stripe.confirmSetup({
                          elements: this.elements,
                          transfer_group: this.$attrs.company.uid,
                          confirmParams: {
                            return_url: 'http://'+this.$attrs.company.nickname+'.gemesys.co.uk/company/subscriptions?confirm='+this.intentToken.id,
                            payment_method_data: {
                              billing_details: {
                                                name: this.cardholder_name,
                                                email: this.$attrs.company.email,
                                  //address: {
                                   //line1: this.cardholder_line1,
                                 //line2: this.cardholder_line2,
                                 //line3: this.cardholder_line3,
                                   // city: this.cardholder_city,
                                  //}
                                            }
                            }
                          },
                          // Uncomment below if you only want redirect for redirect-based payments
                           redirect: 'if_required',

                        }
                    ).then(function(result) {
                      console.log(result);
                        if (result.error) {
                            this.addPaymentStatus = 3;
                            this.addPaymentStatusError = result.error.message;
                            let self = this;
                            setTimeout(function(){
                              self.paymentFormLoading = false;
                              //self.error_message = false;
                            }, 500);
                        } else {
                            this.savePaymentMethod( result.setupIntent.payment_method );
                            this.addPaymentStatus = 2;
                            this.card.clear();
                            this.cardholder_name = '';
                            this.$emit('payment-success', result, this.selected_plan, this.user_quantity, this.current_price, this.current_flat_price);
                               let self = this;
                              setTimeout(function(){
                                self.paymentFormLoading = false;
                                self.card.unmount('#payment-element');
                                //self.error_message = false;
                              }, 1000);
                              setTimeout(function(){
                              //window.location = ''+company.nickname+'.compliancecube.co.uk/login';
                               }, 3000);
                        }
                         let self = this;
                          setTimeout(function(){
                            self.paymentFormLoading = false;
                            //self.error_message = false;
                          }, 1000);
                    }.bind(this));
                },
            /* submitPaymentMethod(){
                    this.addPaymentStatus = 1;
                    this.paymentFormLoading = true;
                    this.stripe.confirmCardSetup(
                        this.intentToken.client_secret, {
                            payment_method: {
                                card: this.card,
                                billing_details: {
                                    name: this.cardholder_name
                                }
                            }
                        }
                    ).then(function(result) {
                        if (result.error) {
                            this.addPaymentStatus = 3;
                            this.addPaymentStatusError = result.error.message;
                        } else {
                            this.savePaymentMethod( result.setupIntent.payment_method );
                            this.paymentResult = result;
                            this.addPaymentStatus = 2;
                            this.card.clear();
                            this.cardholder_name = '';
                            this.$emit('payment-success', result, this.selected_plan, this.user_quantity, this.current_price);
                            /*setTimeout(function(){
                            	window.location.replace('/company/');
                            	 }, 5000);
                        */
              
                        /*
                               let self = this;
                              setTimeout(function(){
                                self.paymentFormLoading = false;
                                //self.error_message = false;
                              }, 1000);
                              setTimeout(function(){
                              //window.location = ''+company.nickname+'.compliancecube.co.uk/login';
                               }, 3000);
                        }
                         let self = this;
                          setTimeout(function(){
                            self.paymentFormLoading = false;
                            //self.error_message = false;
                          }, 1000);
                    }.bind(this));
                },*/
            savePaymentMethod( method ){
                    axios.post('/api/company/subscription/payment-method/add', {
                        pmid: method,
                    }, {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
              }}).then( function(){
                        //this.loadPaymentMethods();
                    }.bind(this));
                },

           
            getSubscriptionPlans(){
              axios.get('/api/subscription/plans?term='+this.payment_term, {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
              }}).then(response => {
                      this.plans = response.data;
                  });
            },
            startLoad() {
              this.loader = setInterval(() => {
                this.loadingValue++;
                if (this.loadingValue === 100) {
                  clearInterval(this.loader);
                }
              }, 100);
            }
        },

  mounted() {
            
            axios.get('/api/company/subscription/setup-intent?uid='+this.$attrs.company.uid+'&price=0.00', {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
              }})
                .then( function( response ){
                    this.intentToken = response.data;
                    return this.includeStripe('js.stripe.com/v3/', function(){
                    this.configureStripe();

                  }.bind(this) );

                }.bind(this));
        },
   created(){
   		this.getSubscriptionPlans();
   }
}
</script>

<template>
	<b-container>
    
      <transition name="fade">
            <div class="px-3" v-show="show_payment_form == true" :class="{'d-flex': show_payment_form == true}">
              <b-overlay
                        id="report-generating-overlay"
                        :show="paymentFormLoading"
                        variant="white"
                        opacity="0.85"
                        blur="1px"
                        rounded="md"
                        class="w-100"
                        
                      > 
                      <b-row>
            <div class="col-md-12 mx-auto my-auto p-4 bg-white border border-radius-1em mx-auto my-auto p-4 bg-white">
            <div v-if="addPaymentStatus == 2" class="py-4 my-4 text-center">
            	<h4>Hurrah! Your card has been added</h4>
            

            	<!--<a class="btn btn btn-block btn-rounded" @click="subscriptionConfirmation()">Go To Confirmation</a>-->
            </div>
            <div v-if="addPaymentStatus != 2">
            <h4 class="my-4 pb-4 card-title text-secondary" v-if="addPaymentStatus == 3">Oops! An Error Occurred</h4>
              <h4 class="my-2 pb-2 card-title text-center" v-else>Enter Your Payment Information</h4>
            <b-alert variant="danger" v-if="addPaymentStatus == 3">{{addPaymentStatusError}}</b-alert>
          
            <label>Card Holder Name</label>
                <input id="card-holder-name" type="text" v-model="cardholder_name" name="name" class="form-control border-0 mb-2" placeholder="Name on card">

  
                <div id="payment-element" class="form-control-group" :show="addPaymentStatus !== 2">

                </div>
                <div class="d-flex mt-4">
                <a class="btn btn-block btn-success mt-3 ml-auto mr-1" id="add-card-button" v-on:click="submitPaymentMethod()">
                    Add Card
                </a>
                <a class="btn btn-block btn-danger close-btn mt-3 mr-auto ml-1" href="javascript:;" @click="$emit('cancel-payment')">Cancel</a>
                </div>
            </div>
            </div>
          </b-row>
          </b-overlay>
            </div>
        </transition>
</b-container>
</template>

<style lang="scss" module></style>