const icons = {
	logos: ["bx bxl-adobe", "bx bxl-algolia",
"bx bxl-audible",
"bx bxl-figma",
"bx bxl-redbubble",
"bx bxl-etsy",
"bx bxl-gitlab",
"bx bxl-patreon",
"bx bxl-facebook-circle",
"bx bxl-imdb",
"bx bxl-jquery",
"bx bxl-pinterest-alt",
"bx bxl-500px",
"bx bxl-airbnb",
"bx bxl-amazon",
"bx bxl-android",
"bx bxl-angular",
"bx bxl-apple",
"bx bxl-baidu",
"bx bxl-behance",
"bx bxl-bing",
"bx bxl-bitcoin",
"bx bxl-blogger",
"bx bxl-bootstrap",
"bx bxl-chrome",
"bx bxl-codepen",
"bx bxl-creative-commons",
"bx bxl-css3",
"bx bxl-dailymotion",
"bx bxl-deviantart",
"bx bxl-digg",
"bx bxl-digitalocean",
"bx bxl-discord",
"bx bxl-discourse",
"bx bxl-dribbble",
"bx bxl-dropbox",
"bx bxl-drupal",
"bx bxl-ebay",
"bx bxl-edge",
"bx bxl-facebook",
"bx bxl-facebook-square",
"bx bxl-firefox",
"bx bxl-flickr",
"bx bxl-flickr-square",
"bx bxl-foursquare",
"bx bxl-git",
"bx bxl-github",
"bx bxl-google",
"bx bxl-google-plus",
"bx bxl-google-plus-circle",
"bx bxl-html5",
"bx bxl-instagram",
"bx bxl-instagram-alt",
"bx bxl-internet-explorer",
"bx bxl-invision",
"bx bxl-javascript",
"bx bxl-joomla",
"bx bxl-jsfiddle",
"bx bxl-kickstarter",
"bx bxl-less",
"bx bxl-linkedin",
"bx bxl-linkedin-square",
"bx bxl-magento",
"bx bxl-mailchimp",
"bx bxl-mastercard",
"bx bxl-medium",
"bx bxl-medium-old",
"bx bxl-medium-square",
"bx bxl-messenger",
"bx bxl-microsoft",
"bx bxl-nodejs",
"bx bxl-opera",
"bx bxl-paypal",
"bx bxl-periscope",
"bx bxl-pinterest",
"bx bxl-play-store",
"bx bxl-pocket",
"bx bxl-product-hunt",
"bx bxl-quora",
"bx bxl-react",
"bx bxl-reddit",
"bx bxl-redux",
"bx bxl-sass",
"bx bxl-shopify",
"bx bxl-skype",
"bx bxl-slack",
"bx bxl-slack-old",
"bx bxl-snapchat",
"bx bxl-soundcloud",
"bx bxl-spotify",
"bx bxl-squarespace",
"bx bxl-stack-overflow",
"bx bxl-stripe",
"bx bxl-telegram",
"bx bxl-trello",
"bx bxl-tumblr",
"bx bxl-twitch",
"bx bxl-twitter",
"bx bxl-unsplash",
"bx bxl-vimeo",
"bx bxl-visa",
"bx bxl-vk",
"bx bxl-vuejs",
"bx bxl-whatsapp",
"bx bxl-whatsapp-square",
"bx bxl-wikipedia",
"bx bxl-windows",
"bx bxl-wix",
"bx bxl-wordpress",
"bx bxl-yahoo",
"bx bxl-yelp",
"bx bxl-youtube"],
	outline: [
"bx bx-accessibility",
"bx bx-add-to-queue",
"bx bx-adjust",
"bx bx-alarm",
"bx bx-alarm-add",
"bx bx-alarm-off",
"bx bx-album",
"bx bx-align-justify",
"bx bx-align-left",
"bx bx-align-middle",
"bx bx-align-right",
"bx bx-analyse",
"bx bx-anchor",
"bx bx-angry",
"bx bx-aperture",
"bx bx-archive",
"bx bx-archive-in",
"bx bx-archive-out",
"bx bx-area",
"bx bx-arrow-back",
"bx bx-at",
"bx bx-award",
"bx bx-badge",
"bx bx-badge-check",
"bx bx-ball",
"bx bx-band-aid",
"bx bx-bar-chart",
"bx bx-bar-chart-alt",
"bx bx-bar-chart-alt-2",
"bx bx-bar-chart-square",
"bx bx-barcode",
"bx bx-basket",
"bx bx-basketball",
"bx bx-bath",
"bx bx-battery",
"bx bx-bed",
"bx bx-bell",
"bx bx-bell-minus",
"bx bx-bell-off",
"bx bx-bell-plus",
"bx bx-bitcoin",
"bx bx-block",
"bx bx-bluetooth",
"bx bx-body",
"bx bx-bold",
"bx bx-bolt-circle",
"bx bx-book",
"bx bx-book-bookmark",
"bx bx-book-content",
"bx bx-bookmark",
"bx bx-bookmark-minus",
"bx bx-bookmark-plus",
"bx bx-bookmarks",
"bx bx-book-open",
"bx bx-border-all",
"bx bx-border-bottom",
"bx bx-border-left",
"bx bx-border-radius",
"bx bx-border-right",
"bx bx-border-top",
"bx bx-bot",
"bx bx-bowling-ball",
"bx bx-box",
"bx bx-briefcase",
"bx bx-briefcase-alt",
"bx bx-briefcase-alt-2",
"bx bx-brightness",
"bx bx-brightness-half",
"bx bx-broadcast",
"bx bx-brush",
"bx bx-brush-alt",
"bx bx-bug",
"bx bx-bug-alt",
"bx bx-building",
"bx bx-building-house",
"bx bx-buildings",
"bx bx-bulb",
"bx bx-bullseye",
"bx bx-buoy",
"bx bx-bus",
"bx bx-cake",
"bx bx-calculator",
"bx bx-calendar",
"bx bx-calendar-alt",
"bx bx-calendar-check",
"bx bx-calendar-event",
"bx bx-calendar-minus",
"bx bx-calendar-plus",
"bx bx-calendar-x",
"bx bx-camera",
"bx bx-camera-off",
"bx bx-captions",
"bx bx-car",
"bx bx-card",
"bx bx-caret-down",
"bx bx-caret-left",
"bx bx-caret-right",
"bx bx-caret-up",
"bx bx-carousel",
"bx bx-cart",
"bx bx-cart-alt",
"bx bx-cast",
"bx bx-certification",
"bx bx-chalkboard",
"bx bx-chart",
"bx bx-chat",
"bx bx-check",
"bx bx-checkbox",
"bx bx-checkbox-checked",
"bx bx-checkbox-square",
"bx bx-check-circle",
"bx bx-check-double",
"bx bx-check-shield",
"bx bx-check-square",
"bx bx-chevron-down",
"bx bx-chevron-left",
"bx bx-chevron-right",
"bx bx-chevrons-down",
"bx bx-chevrons-left",
"bx bx-chevrons-right",
"bx bx-chevrons-up",
"bx bx-chevron-up",
"bx bx-chip",
"bx bx-circle",
"bx bx-clinic",
"bx bx-clipboard",
"bx bx-closet",
"bx bx-cloud",
"bx bx-cloud-download",
"bx bx-cloud-drizzle",
"bx bx-cloud-lightning",
"bx bx-cloud-light-rain",
"bx bx-cloud-rain",
"bx bx-cloud-snow",
"bx bx-cloud-upload",
"bx bx-code",
"bx bx-code-alt",
"bx bx-code-block",
"bx bx-code-curly",
"bx bx-coffee",
"bx bx-cog",
"bx bx-collapse",
"bx bx-collection",
"bx bx-columns",
"bx bx-command",
"bx bx-comment",
"bx bx-comment-dots",
"bx bx-compass",
"bx bx-confused",
"bx bx-conversation",
"bx bx-cool",
"bx bx-copy",
"bx bx-copy-alt",
"bx bx-copyright",
"bx bx-credit-card",
"bx bx-credit-card-alt",
"bx bx-crop",
"bx bx-crosshair",
"bx bx-crown",
"bx bx-cube",
"bx bx-cube-alt",
"bx bx-cuboid",
"bx bx-customize",
"bx bx-cut",
"bx bx-cycling",
"bx bx-cylinder",
"bx bx-data",
"bx bx-desktop",
"bx bx-detail",
"bx bx-devices",
"bx bx-dialpad",
"bx bx-dialpad-alt",
"bx bx-diamond",
"bx bx-directions",
"bx bx-disc",
"bx bx-dish",
"bx bx-dislike",
"bx bx-dizzy",
"bx bx-dna",
"bx bx-dock-bottom",
"bx bx-dock-left",
"bx bx-dock-right",
"bx bx-dock-top",
"bx bx-dollar",
"bx bx-dollar-circle",
"bx bx-dots-horizontal",
"bx bx-dots-horizontal-rounded",
"bx bx-dots-vertical",
"bx bx-dots-vertical-rounded",
"bx bx-doughnut-chart",
"bx bx-down-arrow",
"bx bx-down-arrow-alt",
"bx bx-down-arrow-circle",
"bx bx-download",
"bx bx-downvote",
"bx bx-droplet",
"bx bx-dumbbell",
"bx bx-duplicate",
"bx bx-edit",
"bx bx-edit-alt",
"bx bx-envelope",
"bx bx-equalizer",
"bx bx-error",
"bx bx-error-alt",
"bx bx-error-circle",
"bx bx-euro",
"bx bx-exit",
"bx bx-exit-fullscreen",
"bx bx-expand",
"bx bx-export",
"bx bx-extension",
"bx bx-face",
"bx bx-fast-forward",
"bx bx-fast-forward-circle",
"bx bx-female",
"bx bx-female-sign",
"bx bx-file",
"bx bx-file-blank",
"bx bx-file-find",
"bx bx-film",
"bx bx-filter",
"bx bx-filter-alt",
"bx bx-fingerprint",
"bx bx-first-aid",
"bx bx-first-page",
"bx bx-flag",
"bx bx-folder",
"bx bx-folder-minus",
"bx bx-folder-open",
"bx bx-folder-plus",
"bx bx-font",
"bx bx-font-color",
"bx bx-font-family",
"bx bx-font-size",
"bx bx-food-menu",
"bx bx-food-tag",
"bx bx-football",
"bx bx-fridge",
"bx bx-fullscreen",
"bx bx-gas-pump",
"bx bx-ghost",
"bx bx-gift",
"bx bx-git-branch",
"bx bx-git-commit",
"bx bx-git-compare",
"bx bx-git-merge",
"bx bx-git-pull-request",
"bx bx-git-repo-forked",
"bx bx-globe",
"bx bx-globe-alt",
"bx bx-grid",
"bx bx-grid-alt",
"bx bx-grid-horizontal",
"bx bx-grid-small",
"bx bx-grid-vertical",
"bx bx-group",
"bx bx-handicap",
"bx bx-happy",
"bx bx-happy-alt",
"bx bx-happy-beaming",
"bx bx-happy-heart-eyes",
"bx bx-hash",
"bx bx-hdd",
"bx bx-heading",
"bx bx-headphone",
"bx bx-health",
"bx bx-heart",
"bx bx-help-circle",
"bx bx-hide",
"bx bx-highlight",
"bx bx-history",
"bx bx-hive",
"bx bx-home",
"bx bx-home-alt",
"bx bx-home-circle",
"bx bx-horizontal-center",
"bx bx-hotel",
"bx bx-hourglass",
"bx bx-id-card",
"bx bx-image",
"bx bx-image-add",
"bx bx-image-alt",
"bx bx-images",
"bx bx-import",
"bx bx-infinite",
"bx bx-info-circle",
"bx bx-italic",
"bx bx-joystick",
"bx bx-joystick-alt",
"bx bx-joystick-button",
"bx bx-key",
"bx bx-label",
"bx bx-landscape",
"bx bx-laptop",
"bx bx-last-page",
"bx bx-laugh",
"bx bx-layer",
"bx bx-layout",
"bx bx-left-arrow",
"bx bx-left-arrow-alt",
"bx bx-left-arrow-circle",
"bx bx-left-down-arrow-circle",
"bx bx-left-indent",
"bx bx-left-top-arrow-circle",
"bx bx-like",
"bx bx-line-chart",
"bx bx-link",
"bx bx-link-alt",
"bx bx-link-external",
"bx bx-lira",
"bx bx-list-check",
"bx bx-list-minus",
"bx bx-list-ol",
"bx bx-list-plus",
"bx bx-list-ul",
"bx bx-loader",
"bx bx-loader-alt",
"bx bx-loader-circle",
"bx bx-lock",
"bx bx-lock-alt",
"bx bx-lock-open",
"bx bx-lock-open-alt",
"bx bx-log-in",
"bx bx-log-in-circle",
"bx bx-log-out",
"bx bx-log-out-circle",
"bx bx-magnet",
"bx bx-mail-send",
"bx bx-male",
"bx bx-male-sign",
"bx bx-map",
"bx bx-map-alt",
"bx bx-map-pin",
"bx bx-meh",
"bx bx-meh-alt",
"bx bx-meh-blank",
"bx bx-memory-card",
"bx bx-menu",
"bx bx-menu-alt-left",
"bx bx-menu-alt-right",
"bx bx-message",
"bx bx-message-alt",
"bx bx-message-alt-dots",
"bx bx-message-dots",
"bx bx-message-rounded",
"bx bx-message-rounded-dots",
"bx bx-message-square",
"bx bx-message-square-dots",
"bx bx-microphone",
"bx bx-microphone-off",
"bx bx-minus",
"bx bx-minus-circle",
"bx bx-mobile",
"bx bx-mobile-alt",
"bx bx-mobile-landscape",
"bx bx-mobile-vibration",
"bx bx-money",
"bx bx-moon",
"bx bx-mouse",
"bx bx-mouse-alt",
"bx bx-move",
"bx bx-move-horizontal",
"bx bx-move-vertical",
"bx bx-movie",
"bx bx-music",
"bx bx-navigation",
"bx bx-news",
"bx bx-no-entry",
"bx bx-note",
"bx bx-notepad",
"bx bx-notification",
"bx bx-notification-off",
"bx bx-package",
"bx bx-paint",
"bx bx-paint-roll",
"bx bx-palette",
"bx bx-paperclip",
"bx bx-paper-plane",
"bx bx-paragraph",
"bx bx-paste",
"bx bx-pause",
"bx bx-pause-circle",
"bx bx-pen",
"bx bx-pencil",
"bx bx-phone",
"bx bx-phone-call",
"bx bx-phone-incoming",
"bx bx-phone-outgoing",
"bx bx-photo-album",
"bx bx-pie-chart",
"bx bx-pie-chart-alt",
"bx bx-pie-chart-alt-2",
"bx bx-pin",
"bx bx-planet",
"bx bx-play",
"bx bx-play-circle",
"bx bx-plug",
"bx bx-plus",
"bx bx-plus-circle",
"bx bx-plus-medical",
"bx bx-poll",
"bx bx-polygon",
"bx bx-pound",
"bx bx-power-off",
"bx bx-printer",
"bx bx-pulse",
"bx bx-purchase-tag",
"bx bx-purchase-tag-alt",
"bx bx-pyramid",
"bx bx-question-mark",
"bx bx-radar",
"bx bx-radio",
"bx bx-radio-circle",
"bx bx-radio-circle-marked",
"bx bx-receipt",
"bx bx-rectangle",
"bx bx-redo",
"bx bx-rename",
"bx bx-repeat",
"bx bx-reply",
"bx bx-reply-all",
"bx bx-repost",
"bx bx-reset",
"bx bx-restaurant",
"bx bx-revision",
"bx bx-rewind",
"bx bx-rewind-circle",
"bx bx-right-arrow",
"bx bx-right-arrow-alt",
"bx bx-right-arrow-circle",
"bx bx-right-down-arrow-circle",
"bx bx-right-indent",
"bx bx-right-top-arrow-circle",
"bx bx-rocket",
"bx bx-rotate-left",
"bx bx-rotate-right",
"bx bx-rss",
"bx bx-ruble",
"bx bx-ruler",
"bx bx-run",
"bx bx-rupee",
"bx bx-sad",
"bx bx-save",
"bx bx-screenshot",
"bx bx-search",
"bx bx-search-alt",
"bx bx-search-alt-2",
"bx bx-selection",
"bx bx-select-multiple",
"bx bx-send",
"bx bx-server",
"bx bx-shape-circle",
"bx bx-shape-square",
"bx bx-shape-triangle",
"bx bx-share",
"bx bx-share-alt",
"bx bx-shekel",
"bx bx-shield",
"bx bx-shield-alt",
"bx bx-shield-alt-2",
"bx bx-shield-quarter",
"bx bx-shocked",
"bx bx-shopping-bag",
"bx bx-show",
"bx bx-show-alt",
"bx bx-shuffle",
"bx bx-sidebar",
"bx bx-sitemap",
"bx bx-skip-next",
"bx bx-skip-next-circle",
"bx bx-skip-previous",
"bx bx-skip-previous-circle",
"bx bx-sleepy",
"bx bx-slider",
"bx bx-slider-alt",
"bx bx-slideshow",
"bx bx-smile",
"bx bx-sort",
"bx bx-sort-a-z",
"bx bx-sort-down",
"bx bx-sort-up",
"bx bx-sort-z-a",
"bx bx-spa",
"bx bx-space-bar",
"bx bx-spreadsheet",
"bx bx-square",
"bx bx-square-rounded",
"bx bx-star",
"bx bx-station",
"bx bx-stats",
"bx bx-sticker",
"bx bx-stop",
"bx bx-stop-circle",
"bx bx-stopwatch",
"bx bx-store",
"bx bx-store-alt",
"bx bx-street-view",
"bx bx-strikethrough",
"bx bx-subdirectory-left",
"bx bx-subdirectory-right",
"bx bx-sun",
"bx bx-support",
"bx bx-swim",
"bx bx-sync",
"bx bx-tab",
"bx bx-table",
"bx bx-tag",
"bx bx-target-lock",
"bx bx-task",
"bx bx-taxi",
"bx bx-tennis-ball",
"bx bx-terminal",
"bx bx-test-tube",
"bx bx-text",
"bx bx-time",
"bx bx-time-five",
"bx bx-timer",
"bx bx-tired",
"bx bx-toggle-left",
"bx bx-toggle-right",
"bx bx-tone",
"bx bx-train",
"bx bx-transfer",
"bx bx-transfer-alt",
"bx bx-trash",
"bx bx-trash-alt",
"bx bx-trending-down",
"bx bx-trending-up",
"bx bx-trophy",
"bx bx-tv",
"bx bx-underline",
"bx bx-undo",
"bx bx-unlink",
"bx bx-up-arrow",
"bx bx-up-arrow-alt",
"bx bx-up-arrow-circle",
"bx bx-upload",
"bx bx-upside-down",
"bx bx-upvote",
"bx bx-usb",
"bx bx-user",
"bx bx-user-check",
"bx bx-user-circle",
"bx bx-user-minus",
"bx bx-user-pin",
"bx bx-user-plus",
"bx bx-user-voice",
"bx bx-user-x",
"bx bx-vertical-center",
"bx bx-video",
"bx bx-video-off",
"bx bx-video-plus",
"bx bx-video-recording",
"bx bx-voicemail",
"bx bx-volume",
"bx bx-volume-full",
"bx bx-volume-low",
"bx bx-volume-mute",
"bx bx-walk",
"bx bx-wallet",
"bx bx-wallet-alt",
"bx bx-water",
"bx bx-wifi",
"bx bx-wifi-off",
"bx bx-wind",
"bx bx-window",
"bx bx-window-close",
"bx bx-window-open",
"bx bx-windows",
"bx bx-wink-smile",
"bx bx-wink-tongue",
"bx bx-won",
"bx bx-world",
"bx bx-wrench",
"bx bx-x",
"bx bx-x-circle",
"bx bx-yen",
"bx bx-zoom-in",
"bx bx-zoom-out"],
	solid: [
"bx bxs-add-to-queue",
"bx bxs-adjust",
"bx bxs-adjust-alt",
"bx bxs-alarm",
"bx bxs-alarm-add",
"bx bxs-alarm-off",
"bx bxs-album",
"bx bxs-ambulance",
"bx bxs-analyse",
"bx bxs-angry",
"bx bxs-archive",
"bx bxs-archive-in",
"bx bxs-archive-out",
"bx bxs-area",
"bx bxs-award",
"bx bxs-baby-carriage",
"bx bxs-badge",
"bx bxs-badge-check",
"bx bxs-ball",
"bx bxs-band-aid",
"bx bxs-bank",
"bx bxs-bar-chart-alt-2",
"bx bxs-bar-chart-square",
"bx bxs-barcode",
"bx bxs-basket",
"bx bxs-bath",
"bx bxs-battery",
"bx bxs-battery-charging",
"bx bxs-battery-full",
"bx bxs-battery-low",
"bx bxs-bed",
"bx bxs-bell",
"bx bxs-bell-minus",
"bx bxs-bell-off",
"bx bxs-bell-plus",
"bx bxs-bell-ring",
"bx bxs-bolt",
"bx bxs-bolt-circle",
"bx bxs-book",
"bx bxs-book-bookmark",
"bx bxs-book-content",
"bx bxs-bookmark",
"bx bxs-bookmark-minus",
"bx bxs-bookmark-plus",
"bx bxs-bookmarks",
"bx bxs-bookmark-star",
"bx bxs-book-open",
"bx bxs-bot",
"bx bxs-bowling-ball",
"bx bxs-box",
"bx bxs-briefcase",
"bx bxs-briefcase-alt",
"bx bxs-briefcase-alt-2",
"bx bxs-brightness",
"bx bxs-brightness-half",
"bx bxs-brush",
"bx bxs-brush-alt",
"bx bxs-bug",
"bx bxs-bug-alt",
"bx bxs-building",
"bx bxs-building-house",
"bx bxs-buildings",
"bx bxs-bulb",
"bx bxs-buoy",
"bx bxs-bus",
"bx bxs-business",
"bx bxs-cake",
"bx bxs-calculator",
"bx bxs-calendar",
"bx bxs-calendar-alt",
"bx bxs-calendar-check",
"bx bxs-calendar-event",
"bx bxs-calendar-minus",
"bx bxs-calendar-plus",
"bx bxs-calendar-x",
"bx bxs-camera",
"bx bxs-camera-off",
"bx bxs-camera-plus",
"bx bxs-capsule",
"bx bxs-captions",
"bx bxs-car",
"bx bxs-card",
"bx bxs-caret-down-circle",
"bx bxs-caret-left-circle",
"bx bxs-caret-right-circle",
"bx bxs-caret-up-circle",
"bx bxs-carousel",
"bx bxs-cart",
"bx bxs-cart-alt",
"bx bxs-certification",
"bx bxs-chalkboard",
"bx bxs-chart",
"bx bxs-chat",
"bx bxs-checkbox",
"bx bxs-checkbox-checked",
"bx bxs-check-circle",
"bx bxs-check-shield",
"bx bxs-check-square",
"bx bxs-chip",
"bx bxs-circle",
"bx bxs-city",
"bx bxs-clinic",
"bx bxs-cloud",
"bx bxs-cloud-download",
"bx bxs-cloud-lightning",
"bx bxs-cloud-rain",
"bx bxs-cloud-upload",
"bx bxs-coffee",
"bx bxs-coffee-alt",
"bx bxs-cog",
"bx bxs-collection",
"bx bxs-color-fill",
"bx bxs-comment",
"bx bxs-comment-add",
"bx bxs-comment-detail",
"bx bxs-comment-dots",
"bx bxs-comment-error",
"bx bxs-compass",
"bx bxs-component",
"bx bxs-confused",
"bx bxs-contact",
"bx bxs-conversation",
"bx bxs-cool",
"bx bxs-copy",
"bx bxs-copy-alt",
"bx bxs-coupon",
"bx bxs-credit-card",
"bx bxs-credit-card-alt",
"bx bxs-crown",
"bx bxs-cube",
"bx bxs-cube-alt",
"bx bxs-cuboid",
"bx bxs-customize",
"bx bxs-cylinder",
"bx bxs-dashboard",
"bx bxs-data",
"bx bxs-detail",
"bx bxs-devices",
"bx bxs-direction-left",
"bx bxs-direction-right",
"bx bxs-directions",
"bx bxs-disc",
"bx bxs-discount",
"bx bxs-dish",
"bx bxs-dislike",
"bx bxs-dizzy",
"bx bxs-dock-bottom",
"bx bxs-dock-left",
"bx bxs-dock-right",
"bx bxs-dock-top",
"bx bxs-dollar-circle",
"bx bxs-doughnut-chart",
"bx bxs-down-arrow",
"bx bxs-down-arrow-circle",
"bx bxs-down-arrow-square",
"bx bxs-download",
"bx bxs-downvote",
"bx bxs-drink",
"bx bxs-droplet",
"bx bxs-droplet-half",
"bx bxs-duplicate",
"bx bxs-edit",
"bx bxs-edit-alt",
"bx bxs-eject",
"bx bxs-envelope",
"bx bxs-eraser",
"bx bxs-error",
"bx bxs-error-alt",
"bx bxs-error-circle",
"bx bxs-exit",
"bx bxs-extension",
"bx bxs-eyedropper",
"bx bxs-face",
"bx bxs-factory",
"bx bxs-fast-forward-circle",
"bx bxs-file",
"bx bxs-file-blank",
"bx bxs-file-css",
"bx bxs-file-doc",
"bx bxs-file-find",
"bx bxs-file-gif",
"bx bxs-file-html",
"bx bxs-file-image",
"bx bxs-file-jpg",
"bx bxs-file-js",
"bx bxs-file-json",
"bx bxs-file-md",
"bx bxs-file-pdf",
"bx bxs-file-plus",
"bx bxs-file-png",
"bx bxs-file-txt",
"bx bxs-film",
"bx bxs-filter-alt",
"bx bxs-first-aid",
"bx bxs-flag",
"bx bxs-flag-alt",
"bx bxs-flame",
"bx bxs-flask",
"bx bxs-folder",
"bx bxs-folder-minus",
"bx bxs-folder-open",
"bx bxs-folder-plus",
"bx bxs-food-menu",
"bx bxs-fridge",
"bx bxs-gas-pump",
"bx bxs-ghost",
"bx bxs-gift",
"bx bxs-graduation",
"bx bxs-grid",
"bx bxs-grid-alt",
"bx bxs-group",
"bx bxs-hand-down",
"bx bxs-hand-left",
"bx bxs-hand-right",
"bx bxs-hand-up",
"bx bxs-happy",
"bx bxs-happy-alt",
"bx bxs-happy-beaming",
"bx bxs-happy-heart-eyes",
"bx bxs-hdd",
"bx bxs-heart",
"bx bxs-help-circle",
"bx bxs-hide",
"bx bxs-home",
"bx bxs-home-circle",
"bx bxs-hot",
"bx bxs-hotel",
"bx bxs-hourglass",
"bx bxs-hourglass-bottom",
"bx bxs-hourglass-top",
"bx bxs-id-card",
"bx bxs-image",
"bx bxs-image-add",
"bx bxs-image-alt",
"bx bxs-inbox",
"bx bxs-info-circle",
"bx bxs-institution",
"bx bxs-joystick",
"bx bxs-joystick-alt",
"bx bxs-joystick-button",
"bx bxs-key",
"bx bxs-keyboard",
"bx bxs-label",
"bx bxs-landmark",
"bx bxs-landscape",
"bx bxs-laugh",
"bx bxs-layer",
"bx bxs-layout",
"bx bxs-left-arrow",
"bx bxs-left-arrow-circle",
"bx bxs-left-arrow-square",
"bx bxs-left-down-arrow-circle",
"bx bxs-left-top-arrow-circle",
"bx bxs-like",
"bx bxs-lock",
"bx bxs-lock-alt",
"bx bxs-lock-open",
"bx bxs-lock-open-alt",
"bx bxs-log-in",
"bx bxs-log-in-circle",
"bx bxs-log-out",
"bx bxs-log-out-circle",
"bx bxs-magic-wand",
"bx bxs-magnet",
"bx bxs-map",
"bx bxs-map-alt",
"bx bxs-map-pin",
"bx bxs-megaphone",
"bx bxs-meh",
"bx bxs-meh-alt",
"bx bxs-meh-blank",
"bx bxs-memory-card",
"bx bxs-message",
"bx bxs-message-alt",
"bx bxs-message-alt-dots",
"bx bxs-message-dots",
"bx bxs-message-rounded",
"bx bxs-message-rounded-dots",
"bx bxs-message-square",
"bx bxs-message-square-dots",
"bx bxs-microphone",
"bx bxs-microphone-alt",
"bx bxs-microphone-off",
"bx bxs-minus-circle",
"bx bxs-minus-square",
"bx bxs-mobile",
"bx bxs-mobile-vibration",
"bx bxs-moon",
"bx bxs-mouse",
"bx bxs-mouse-alt",
"bx bxs-movie",
"bx bxs-music",
"bx bxs-navigation",
"bx bxs-news",
"bx bxs-no-entry",
"bx bxs-note",
"bx bxs-notepad",
"bx bxs-notification",
"bx bxs-notification-off",
"bx bxs-offer",
"bx bxs-package",
"bx bxs-paint",
"bx bxs-paint-roll",
"bx bxs-palette",
"bx bxs-paper-plane",
"bx bxs-parking",
"bx bxs-paste",
"bx bxs-pen",
"bx bxs-pencil",
"bx bxs-phone",
"bx bxs-phone-call",
"bx bxs-phone-incoming",
"bx bxs-phone-outgoing",
"bx bxs-photo-album",
"bx bxs-pie-chart",
"bx bxs-pie-chart-alt",
"bx bxs-pie-chart-alt-2",
"bx bxs-pin",
"bx bxs-plane",
"bx bxs-plane-alt",
"bx bxs-plane-land",
"bx bxs-planet",
"bx bxs-plane-take-off",
"bx bxs-playlist",
"bx bxs-plug",
"bx bxs-plus-circle",
"bx bxs-plus-square",
"bx bxs-polygon",
"bx bxs-printer",
"bx bxs-purchase-tag",
"bx bxs-purchase-tag-alt",
"bx bxs-pyramid",
"bx bxs-quote-alt-left",
"bx bxs-quote-alt-right",
"bx bxs-quote-left",
"bx bxs-quote-right",
"bx bxs-quote-single-left",
"bx bxs-quote-single-right",
"bx bxs-radio",
"bx bxs-receipt",
"bx bxs-rectangle",
"bx bxs-rename",
"bx bxs-report",
"bx bxs-rewind-circle",
"bx bxs-right-arrow",
"bx bxs-right-arrow-circle",
"bx bxs-right-arrow-square",
"bx bxs-right-down-arrow-circle",
"bx bxs-right-top-arrow-circle",
"bx bxs-rocket",
"bx bxs-ruler",
"bx bxs-sad",
"bx bxs-save",
"bx bxs-school",
"bx bxs-search",
"bx bxs-search-alt-2",
"bx bxs-select-multiple",
"bx bxs-send",
"bx bxs-server",
"bx bxs-share",
"bx bxs-share-alt",
"bx bxs-shield",
"bx bxs-shield-alt-2",
"bx bxs-ship",
"bx bxs-shocked",
"bx bxs-shopping-bag",
"bx bxs-shopping-bag-alt",
"bx bxs-show",
"bx bxs-skip-next-circle",
"bx bxs-skip-previous-circle",
"bx bxs-skull",
"bx bxs-sleepy",
"bx bxs-slideshow",
"bx bxs-smile",
"bx bxs-sort-alt",
"bx bxs-spa",
"bx bxs-spreadsheet",
"bx bxs-square",
"bx bxs-square-rounded",
"bx bxs-star",
"bx bxs-star-half",
"bx bxs-stopwatch",
"bx bxs-store",
"bx bxs-store-alt",
"bx bxs-sun",
"bx bxs-tag",
"bx bxs-tag-x",
"bx bxs-taxi",
"bx bxs-tennis-ball",
"bx bxs-terminal",
"bx bxs-time",
"bx bxs-time-five",
"bx bxs-timer",
"bx bxs-tired",
"bx bxs-toggle-left",
"bx bxs-toggle-right",
"bx bxs-tone",
"bx bxs-torch",
"bx bxs-to-top",
"bx bxs-traffic",
"bx bxs-traffic-barrier",
"bx bxs-train",
"bx bxs-trash",
"bx bxs-trash-alt",
"bx bxs-tree",
"bx bxs-trophy",
"bx bxs-truck",
"bx bxs-t-shirt",
"bx bxs-up-arrow",
"bx bxs-up-arrow-circle",
"bx bxs-up-arrow-square",
"bx bxs-upside-down",
"bx bxs-upvote",
"bx bxs-user",
"bx bxs-user-badge",
"bx bxs-user-check",
"bx bxs-user-circle",
"bx bxs-user-detail",
"bx bxs-user-minus",
"bx bxs-user-pin",
"bx bxs-user-plus",
"bx bxs-user-rectangle",
"bx bxs-user-voice",
"bx bxs-user-x",
"bx bxs-vial",
"bx bxs-video",
"bx bxs-video-off",
"bx bxs-video-plus",
"bx bxs-video-recording",
"bx bxs-videos",
"bx bxs-volume",
"bx bxs-volume-full",
"bx bxs-volume-low",
"bx bxs-volume-mute",
"bx bxs-wallet",
"bx bxs-wallet-alt",
"bx bxs-watch",
"bx bxs-watch-alt",
"bx bxs-widget",
"bx bxs-wine",
"bx bxs-wink-smile",
"bx bxs-wink-tongue",
"bx bxs-wrench",
"bx bxs-x-circle",
"bx bxs-x-square",
"bx bxs-yin-yang",
"bx bxs-zap",
"bx bxs-zoom-in",
"bx bxs-zoom-out"]	
};

export { icons };