<script type="text/javascript">
import SelectCompanyCategoryTreeChild from './SelectCompanyCategoryTreeChild';
    
//const EventBus = new Vue();
  export default {
        component:{
            SelectCompanyCategoryTreeChild
        },
        name: 'SelectCompanyCategoryTreeChild',
        props: {
            childs: Array,
            all_categories: Array,
            productcategories: Array,
            productid: Number,
            selectedcids: Array,
        }, 
        template: '',
         beforeMount(){
        /*axios.get('http://localhost:7080/gemesislabs-framework/public/seller/product/'+this.productid+'/categories').then(response => {
                 console.log(response);
               this.productcategories = response.data;
            })*/
            this.selectedchildids = [];
        },
        mounted(){
            let self = this;
            //self.selectedchildids = self.selectedcids;
            self.$on('category-select', function(id){
                self.selectedcids = id;
            });
            /*EventBus.$on('categories-cleared', function(){
               self.selectedchildids = [];
            })
            EventBus.$on('check-all-categories', function(){
                self.checkAll;
            })*/
        },
        data: function() {
        return{
            storageurl: 'https://'+this.$attrs.company.nickname+'.s3.eu-west-2.amazonaws.com/',
            all_categories: [],
            childdata: this.childs,
            childitems: [],
            product_categories: this.productcategories,
            testObject: [],
            product_id: this.productid,
            showDropdown: 'show',
            selectedchildids: [],
        }
        },

        methods:{
            addToCategory(id){
                /*if(this.selectedchildids.length == 0){
                    this.selectedchildids[0] = id;
                }else{
                this.selectedchildids.push(id);
                }*/
                EventBus.$emit('category-added', id);     
            },
            categorySelected(id){
                this.$emit('category-select', id);
            },
             findIds(id){
                this.testObject = [];
                this.testObject = this.selectedcids.filter(item => item == id);
                    if(this.testObject.length){
                        return "checked";
                    }else{
                        return false;
                    }

                },
        },
         computed: {
        checkAll: {
            get: function () {
                return this.all_categories ? this.selectedchildids.length == this.all_categories.length : false;
            },
            set: function (value) {
                var selected = [];
                if (value) {
                    this.all_categories.forEach(function (data) {
                        selected.push(data.id);
                        EventBus.$emit('category-added', data.id);
                    });
                }
                this.selectedchildids = selected;
            }
        },
        checkBoxes: function(){
            this.selectedchildids = this.selectedcids;
        }
    }
}
</script>
<template>
    <ul class="tree-child" v-bind:class="showDropdown"><li v-for="child in childs"><div class="form-check"><input type="radio" v-bind:value="child.id" @input="categorySelected(child.id)" v-model="selectedcids" class="form-check-input" v-bind:id="child.id"><label class="custom-control-label" v-bind:for="child.id"><img v-if="child.image" :src="storageurl + child.image" class="avatar-xs rounded-circle"><img v-else class="avatar-xs rounded-circle" src="/images/product-placeholder.png"> <span>{{child.name}}</span></label></div><ul v-for="categories in childs" v-if="categories.id === child.id"><SelectCompanyCategoryTreeChild :childs="categories.childs" :productcategories="productcategories" :productid="product_id" :selectedcids="selectedcids"  @category-select="categorySelected" ></SelectCompanyCategoryTreeChild></ul></li></ul>
</template>