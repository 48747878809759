<template>
	<div class=" bottom-0 top-0 my-auto bg-white position-absolute d-flex" :style="$attrs.area == 'widget' ? 'left: 360px; height: 320px;   border-radius: 0 10px 10px 0px;' : 'height: 320px; right: 480px'">
	<div class="d-flex flex-column bottom-0 top-0 my-auto bg-white position-absolute" style=" padding: 20px; border-radius: 0px 10px 10px 0px;">
			<a :id="$attrs.area + '-fullscreen'" v-b-tooltip triggers="hover" :placement="$attrs.area == 'widget' ? 'left' : 'right'" title="Fullwidth" class="btn btn-primary mr-1 rounded-circle my-auto" @click="changeViewSize('fullwidth'), activateViewPortSettings = false" :class="[contentWidth == windowInnerWidth ? 'bg-info border-info' : '']">
					<i class="bx bx-fullscreen text-white font-size-16 my-1"></i>
				</a>
				<a :id="$attrs.area + '-desktop'" v-b-tooltip triggers="hover" :placement="$attrs.area == 'widget' ? 'left' : 'right'" title="Desktop" class="btn btn-primary mr-1 rounded-circle my-auto" @click="changeViewSize(1300), activateViewPortSettings = true" :class="[windowInnerWidth > 1200 ? 'bg-success border-success' : '', contentWidth > 1200 ? 'bg-info border-info' : '']">
					<i class="bx bx-desktop text-white font-size-16 my-1"></i>
				</a>
					<a :id="$attrs.area + '-laptop'" v-b-tooltip triggers="hover" :placement="$attrs.area == 'widget' ? 'left' : 'right'" title="Laptop" class="btn btn-primary mr-1 rounded-circle my-auto" @click="changeViewSize(1199), activateViewPortSettings = true" :class="[(windowInnerWidth > 991) && (windowInnerWidth < 1200) ? 'bg-success border-success' : '', (contentWidth > 991) && (contentWidth < 1200) ? 'bg-info border-info' : '']">
					<i class="bx bx-laptop text-white font-size-16 my-1"></i>
				</a>
				<a :id="$attrs.area + '-tablet'" v-b-tooltip triggers="hover" :placement="$attrs.area == 'widget' ? 'left' : 'right'" title="Tablet" class="btn btn-primary mr-1 rounded-circle my-auto" @click="changeViewSize(990), activateViewPortSettings = true" :class="[(windowInnerWidth > 769) && (windowInnerWidth < 991) ? 'bg-success border-success' : '', (contentWidth > 769) && (contentWidth < 991) ? 'bg-info border-info' : '']">
					<i class="bx bx bx-tab text-white font-size-16 my-1"></i>
				</a>
				<a :id="$attrs.area + '-large-mobile'" v-b-tooltip triggers="hover" :placement="$attrs.area == 'widget' ? 'left' : 'right'" title="Large Mobile" class="btn btn-primary mr-1 rounded-circle my-auto" @click="changeViewSize(768), activateViewPortSettings = true" :class="[(windowInnerWidth > 575) && (windowInnerWidth < 769) ? 'bg-success border-success' : '', (contentWidth > 575) && (contentWidth < 769) ? 'bg-info border-info' : '']">
					<i class="bx bx bx-mobile-alt text-white font-size-16 my-1"></i>
				</a>
				<a :id="$attrs.area + '-small-mobile'" v-b-tooltip triggers="hover" :placement="$attrs.area == 'widget' ? 'left' : 'right'" title="Small Mobile" class="btn btn-primary mr-1 rounded-circle my-auto" @click="changeViewSize(350), activateViewPortSettings = true" :class="[windowInnerWidth < 576 ? 'bg-success border-success' : '', contentWidth < 576 ? 'bg-info border-info' : '']">
					<i class="bx bx-mobile text-white font-size-16 my-1"></i>
				</a>
				<a :id="$attrs.area + '-copy'" v-b-tooltip triggers="hover" :placement="$attrs.area == 'widget' ? 'left' : 'right'" title="Copy Styles To:" class="btn btn-info mr-1 rounded-circle my-auto" @click="showCopyMenu = !showCopyMenu" :class="[windowInnerWidth < 576 ? 'bg-success border-success' : '', contentWidth < 576 ? 'bg-info border-info' : '']">
					<i v-if="showCopyMenu == false" class="bx bx-copy-alt text-white font-size-16 my-1"></i>
					<i v-else class="bx bx-chevron-right text-white font-size-16 my-1"></i>
				</a>
				<transition :name="$attrs.area == 'widget' ? 'fade-left' : 'fade-right'">
	<div class="d-flex bottom-0 position-absolute mt-auto mb-2"  v-if="showCopyMenu == true"  :style="$attrs.area !== 'widget' ? 'border-radius: 0 10px 10px 0px; right: 20px' : ''">
				<a :id="$attrs.area + '-copy'" v-b-tooltip triggers="hover" :placement="$attrs.area == 'widget' ? 'left' : 'right'" title="Copy Styles To:" class="btn btn-info mr-1 rounded-circle mt-auto" @click="showCopyMenu = !showCopyMenu" :class="[windowInnerWidth < 576 ? 'bg-success border-success' : '', contentWidth < 576 ? 'bg-info border-info' : '']">
					<i  class="bx bx-chevron-right text-white font-size-16 my-1"></i>
				</a>
				 <a class="btn btn-info mr-1 rounded-circle mt-auto" v-for="size in viewportSizes" @click="$emit('copy-to-viewport', size, viewPortSize)" :id="$attrs.area + '-copy-' + size" v-b-tooltip triggers="hover" :placement="$attrs.area == 'widget' ? 'left' : 'right'" :title="'Copy to: ' +size+''">
					 <i v-if="size == 'xl'" class="bx bx-desktop text-white font-size-16 my-1"></i>
					 <i v-if="size == 'lg'" class="bx bx-laptop text-white font-size-16 my-1"></i>
					 <i v-if="size == 'md'" class="bx bx-tab text-white font-size-16 my-1"></i>
					 <i v-if="size == 'sm'" class="bx bx-mobile-alt text-white font-size-16 my-1"></i>
					 <i v-if="size == 'xs'" class="bx bx-mobile text-white font-size-16 my-1"></i>
				</a>
				</div>
			</transition>
				
	</div>
	
</div>

</template>

<script type="text/javascript">
	
	import { EventBus } from '../../../app'

	export default{
		data(){
			return {
				contentWidth: 1201,
				viewportSizes: ['xl', 'lg', 'md', 'sm', 'xs'],
				showCopyMenu: false
			}
		},
		props: {
			viewPortSize: {
				type: String
			},
			windowWidth: {
				type: [String, Number]
			}
		},
		methods: {
			changeViewSize(size){
				EventBus.$emit('change-viewport-size', size);
				this.contentWidth = size;
			}
		},
		mounted(){
			this.contentWidth = this.windowWidth;
		},
		watch: {
			windowWidth(){
				this.contentWidth = this.windowWidth;
			}
		}
	}

</script>