<script>
import HorizontalTopbar from "../components/horizontal-topbar";
import HorizontalNav from "../components/horizontal-nav";
import RightBar from "../components/right-bar";
import Footer from "../components/footer";
import SideSlidePanel from "../components/widgets/SideSlidePanel";
import SidePanelCalculators from "../components/widgets/calculators/SidePanelCalculators";
import MetalsCharts from "../components/widgets/price-charts/metals";
import { EventBus } from '../app';
import SideBar from "../components/side-bar";


/**
 * Horizontal-layout
 */
export default {
  components: {
    HorizontalTopbar,
    HorizontalNav,
    Footer,
    RightBar,
    SideSlidePanel,
    SidePanelCalculators,
    MetalsCharts,
    SideBar,
  },
  data() {
    return {
      component: '',
      isMenuCondensed: false,
      showCalculators: false,
      showMetalsCharts: false,
      calculatorTab: 0,
      showSidebar: false,
      menu: [],
      
    };
  },
  props: {
    menu: Array
  },
  mounted(){
  /*let self = this;
    EventBus.$on('currency-updated', function (symbol, currency, rates) {
      self.selected_currency = currency;
      self.exchange_rates = rates.data;
      self.selected_currency_symbol = symbol; 
      var currencyItems = document.getElementsByClassName('currency');
      
      window.localStorage.setItem("currency", JSON.stringify({currency_code: currency, currency_symbol: symbol}))
    });*/
  },
  created: () => {
    document.body.setAttribute("data-layout", "horizontal");
    document.body.setAttribute("data-topbar", "dark");
    document.body.removeAttribute("data-sidebar", "dark");
    document.body.removeAttribute("data-layout-size", "boxed");
    

    
  },
  computed: {
      layout() {
        return this.$root.layout;
    },
     isTrade() {
      if (!document.body.getAttribute("data-trade")) {
         return false;
      }else{
          this.showSidebar = true;
          
         return document.body.getAttribute("data-trade");
      }
    },
  },
  methods: {
   
    toggleRightSidebar() {
      document.body.classList.toggle("right-bar-enabled");
    },
    hideRightSidebar() {
      document.body.classList.remove("right-bar-enabled");
    },
    showCalculator(payload){
      this.showCalculators =  true;
      this.calculatorTab = payload;
    },
    showSidebarEvent(){
      this.showSidebar = !this.showSidebar;
      if(this.showSidebar == false){
        document.body.classList.remove("vertical-collpsed");
      }else{
        document.body.classList.add("vertical-collpsed");
      }
    }
  },
};
</script>

<template>
  <div>
    <!-- Begin page -->
    <div id="layout-wrapper">
      <HorizontalTopbar  :user="$attrs.user"  @showCalculatorWindow="showCalculator" @showMetalPricing="showMetalsCharts = true" :isTrade="isTrade" @show-sidebar="showSidebarEvent" :selected_currency="$attrs.selected_currency" :selected_currency_symbol="$attrs.selected_currency_symbol" :exchange_rates="$attrs.exchange_rates" :currencies="$attrs.currencies" />
      <HorizontalNav :user="$attrs.user" :menuItems="menu" :active_menu="$attrs.active_menu" :isTrade="isTrade" @show-sidebar="showSidebarEvent"  :sidebarActive="showSidebar" />
      <transition name="fade-left">
        <SideBar v-if="(isTrade == 'true') && (showSidebar == true)" :is-condensed="isMenuCondensed" :user="$attrs.user" :menuItems="menu" :active_menu="$attrs.active_menu" />
      </transition>
      <!-- ============================================================== -->
      <!-- Start right Content here -->
      <!-- ============================================================== -->
      <div class="main-content">
        <div class="page-content">
          <div class="container-fluid content-container">
            <slot  @showStorageWindow="showFileManager = !showFileManager" :selected_currency="$attrs.selected_currency" :selected_currency_symbol="$attrs.selected_currency_symbol" :exchange_rates="$attrs.exchange_rates" :currencies="$attrs.currencies"  />
          </div>
          <!-- container-fluid -->
        </div>
          <SideSlidePanel :editPanel="showCalculators" :panelClass="'edit-sidepanel-large'" @close-panel="showCalculators = false">
        <b-row><SidePanelCalculators :activeTab="calculatorTab" /></b-row>
      </SideSlidePanel>
      <SideSlidePanel :editPanel="showMetalsCharts" :panelClass="'edit-sidepanel-large'" @close-panel="showMetalsCharts = false">
        <b-row><MetalsCharts ></MetalsCharts></b-row>
      </SideSlidePanel>
        <!-- End Page-content -->
        <Footer />
      </div>
      <!-- end main content-->
    </div>
    <!-- END layout-wrapper -->
    <RightBar />
  </div>
</template>
