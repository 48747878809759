<script type="text/javascript">
import DateTime from 'luxon/src/datetime.js';
  export default{
    components: {DateTime},
    props:{
      slimline: {
        type: Boolean,
        default: () => false
      },
    },
		data(){
			return{
          isCheckAll: false,
          selectedids: [],
          invoice_settings: '',
          invoice_no: 0,
          active_invoice: '',
			}
		},
    methods:{
      
      invoiceSettings(){
          axios.post('/api/company/settings', {type: 'invoice'}, {headers : { 'Authorization' : 'Bearer '+this.$attrs.api_token }}).then(response => {
              this.invoice_settings = response.data;
              //var stringlength = response.data.invoice_no_length - response.data.invoice_no.toString().length;
              //console.log(stringlength);
              this.invoice_no = response.data.invoice_no.toString().padStart(response.data.invoice_no_length, '0');
          }).catch(error => {

          });
      },
      updateProject(project){
        this.$emit('edit-project', project);
      },
      getCollaborators(project){
        this.$emit('show-collaborators', project);
      },
      deleteProject(project){
        this.$emit('delete-project', project);
      },
      getActions(project){
        this.$emit('show-actions', project);
      },
      updateSelected(payload){
        console.log(payload);
        this.$emit('update-selected-ids', payload);
      },
       filterSelected(id){
        if(this.selectedids.length > 0){
          var exists = this.selectedids.filter(item => item == id);
          if(exists.length > 0){
            return true;
          }else{
            return false
          }
        }else{
          return false;
        }
      },
      checkAll: function(){ 
                 this.isCheckAll = !this.isCheckAll; 
                 this.selectedids = []; 
                 if(this.isCheckAll){ 
                     // Check all 
                     for (var key in this.$attrs.projectData) {          
                        this.selectedids.push(this.$attrs.projectData[key].id); 
                     } 
                 } 
              }, 
      updateCheckall: function(){ 
                 if(this.selectedids.length == this.$attrs.projectData.length){
                    this.isCheckAll = true; 
                 }else{ 
                    this.isCheckAll = false; 
                 } 
      },
      openFile(file_path, filename){
         axios({url: '/api/company/storage/file/url?filename='+file_path+filename, method: 'GET', headers: {
                  'Authorization': 'Bearer ' + this.$attrs.api_token
              }}).then(response => {
	      console.log(response);
	      var fileURL = window.open(response.data);
	        fileLink.click();
	      }).catch(error => {
	        console.log(error);
	      });
	    },
	    downloadFile(file_path, filename){
	      axios({url: '/api/company/storage/file/download?filename='+file_path+'/'+filename, method: 'GET', responseType: 'blob', headers: {
                  'Authorization': 'Bearer ' + this.$attrs.api_token
              }}).then(response => {
	      console.log(new Blob([response.data]));
	      var fileURL = window.URL.createObjectURL(new Blob([response.data]));
	       var fileLink = document.createElement('a');
	       fileLink.href = fileURL;
	       fileLink.setAttribute('download', filename);
	       document.body.appendChild(fileLink);

      
	     fileLink.click();
	    }).catch(error => {
	      console.log(error);
	    });
	    },
      
	    deleteFile(file){
		      axios.post('/api/company/storage/file/download', {filename: current_path}, {headers: {
                  'Authorization': 'Bearer ' + this.api_token
              }}).then(response => {
		        if(response.data == true){
		          this.loadFiles();
		        }
		      }).catch(error => {
		        console.log(error);
		      });
		    }
    },
		created(){
			this.invoiceSettings();
		}

	}
</script>
<template>
		<div class="table-responsive bg-white">
            <table id="my-table" class="table table-wrap table-centered">
              <thead v-if="slimline == false">
                <tr>
                  <th scope="col" style="width: 100px" class="text-center">
                    <div
                        class="form-check form-switch form-switch-sm mb-0 mx-auto p-0 mt-auto d-flex"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input mx-auto my-auto" 
                          type="checkbox"
                          id="SwitchCheckSizesm"
                         
                          v-model="isCheckAll"
                          @click='checkAll()'
                          name="check-all"
                        />
                    </div>
                  </th>
                  <th scope="col">Order Ref.</th>
                  <th scope="col">Invoice No.</th>
                  <th scope="col">Filename</th>
                  <th scope="col">Created By</th>
                  <th scope="col">Created On</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="project in $attrs.projectData" :key="project.id" valign="middle">
                  <td>
                    <div class="text-center" >
                        <b-form-checkbox class="select-row-btn" v-model="selectedids" :value="project.id" button-variant="primary" @input="updateSelected" button>
                        	<a
                              href="javascript: void(0);"
                              class="text-dark font-weight-medium position-absolute"
                              >
                             
                            </a>
                          <i v-if="filterSelected(project.id)" class="bx bx-check"></i><span class="avatar-title bg-light rounded-circle" v-else><i
                                class="mdi font-size-16 align-middle mdi-file-pdf text-danger"
                              ></i></span></b-form-checkbox>
                    </div>  
                  </td>
                  
                  <td>
                    <p class="m-0 p-0 text-primary"><a :href="'/order/details/'+project.invoice_ref"><strong>#{{project.invoice_ref}}</strong></a></p>
                  </td>
                  <td>
                     #{{invoice_settings.invoice_prefix}}{{(project.invoice_no.toString().padStart(invoice_settings.invoice_no_length, '0'))}}
                  </td>
                  <td style="max-width: 400px;">
  					         {{project.filename}}
                  </td>
                  <td>
                   <a v-if="project.user !== null" class="d-flex" :href="'/company/user/'+project.user.uid" target="_blank">
                    <img v-if="project.user.user.avatar !== null" :src="project.user.user.avatar" class="avatar-xs rounded-circle my-auto">
                    <span v-else class="my-auto avatar-xs bg-primary rounded-circle text-white justify-content-center align-items-center d-flex">{{project.user.user.name.slice(0,1)}}</span>
                     <span class="my-auto ml-2">
                      {{project.user.user.name}} 
                      {{project.user.user.lastname}}
                     </span>
                   </a>
                  </td>
                   <td>
                   {{project.created_at | luxon({input: 'server'})}}
                  </td>
                  <td>
                    <ul class="list-inline font-size-20 contact-links mb-0">
                        <li class="list-inline-item pr-1"  v-if="slimline == false">
                          <a v-b-tooltip.hover title="Download" href="javascript:;" @click="downloadFile(project.filepath, project.filename)">
                            <i class="bx bx-download"></i>
                          </a>
                        </li>
                        <li class="list-inline-item pr-1">
                          <a v-b-tooltip.hover title="View" href="javascript:;" @click="openFile(project.filepath, project.filename)">
                            <i class="mdi mdi-eye-outline"></i>
                          </a>
                        </li>
                        <!--<li class="list-inline-item pr-1">
                          <a v-b-tooltip.hover title="Reports">
                            <i class="bx bx-line-chart"></i>
                          </a>
                        </li>-->
                       <!-- <li class="list-inline-item pr-1" v-if="slimline == false">
                          <a v-b-tooltip.hover title="Delete" @click="deleteProject(project)">
                            <i class="bx bx-trash-alt"></i>
                          </a>
                        </li>-->
                      </ul>
                    
                  </td>
                </tr>
              </tbody>
            </table>
          </div>


</template>