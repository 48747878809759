<script type="text/javascript">
	export default{
		data() {
			return{
				loadingForm: false,
				message_sent: null,
			}
		},
		props: {
			token: {
				type: Object
			}
		},
		methods: {
			sendEnquiry(){
				
				//var notspam = this.recaptcha();
				this.loadingForm = true;
				var postForm = new FormData(document.getElementById("contact-form"));
				axios.post(this.$attrs.route, postForm).then(response => {
					this.message_sent = true;
					let self = this
					setTimeout(function() {
						self.loadingForm = false;
					}, 500);
				}).catch(error => {
					this.message_sent = false;
					setTimeout(function() {
						self.loadingForm = false;
					}, 500);
				});
			},
		}
	}
</script>

<template>
	<b-overlay :show="loadingForm">
		<transition name="fade">
		  <b-form id="contact-form" v-if="message_sent == null">
		  	<input type="hidden" name="recaptcha" v-model="token">
	    	<b-form-group>
	    		<b-form-input name="name" class="underline-input my-2" placeholder="First Name" type="text"></b-form-input>
	    	</b-form-group>
	    	<b-form-group>
	    		<b-form-input name="lastname" class="underline-input my-2" placeholder="Last Name" type="text"></b-form-input>
	    	</b-form-group>
	    	<b-form-group>
	    		<b-form-input name="email" class="underline-input my-2" placeholder="Email" type="text"></b-form-input>
	    	</b-form-group>
	    	<b-form-group>
	    		<b-form-input name="telephone" class="underline-input my-2" placeholder="Telephone" type="text"></b-form-input>
	    	</b-form-group>
	    	<b-form-group>
	    		<b-form-input name="subject" class="underline-input my-2" placeholder="Message Subject" type="text"></b-form-input>
	    	</b-form-group>
	    	<b-form-group>
	    		<b-form-textarea
	    		class="underline-input my-2"
			      id="textarea"
			      placeholder="Enter something..."
			      rows="4"
			      name="message"
			    ></b-form-textarea>
	    	</b-form-group>
	    	<a class="btn btn-outline-light btn-rounded btn-block mt-3" @click="sendEnquiry()">Send Message</a>
	    </b-form>
	    <div v-else-if="message_sent == true" class="text-center">
	    	<svg class="checkmark checkmark-error" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
								  <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="red"/>
								  <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
								</svg>
								<h4>Message Sent to Seller</h4>
					<p>Your message has been sent directly to the seller.</p>
	    </div>
	    <div v-else-if="message_sent == false" class="text-center">
	    	<svg class="checkmark checkmark-error" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
								  <circle class="checkmark__circle__error" cx="26" cy="26" r="25" fill="#feb346"/>
								     <rect data-v-0b26b296="" x="45" y="10" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -26.5097 64)" width="8" height="6" class="st0 checkmark__pending" style="
									    transform: translate(-16px, 25px) rotateY(30deg);
									    fill: white;
									"></rect> <rect data-v-0b26b296="" x="98" y="63" transform="translate(-62px, -52px) rotateY(30deg)" width="8" height="20" class="st0 checkmark__cross" style="
									    transform: translate(-62px, -54px) rotateY(30deg);
									    fill: white;
									"></rect>
								</svg>
								<h4>Message Could Not be Sent</h4>
					<p>Your message could not be sent. Please retry.</p>
	    </div>

		</transition>
	</b-overlay>
</template>

<style>
		.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #7ac142;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}
	.checkmark__circle__error {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #F40101;
  fill: #F40101;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}
.checkmark {
  width: 128px;
  height: 128px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  margin: 5% auto;
  background: #7ac142;
  box-shadow: inset 0px 0px 0px #7ac142;
  animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
}
.checkmark.checkmark-error{
	margin-top: -30%;
}
.checkmark__cross {


  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}
.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}
@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%, 100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #7ac142;
  }
}
</style>