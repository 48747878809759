<script>
import Layout from '../../layouts/main';
import PageHeader from '../../components/page-header';

import SideSlidePanel from "../../components/widgets/SideSlidePanel";
import SearchBar from "../../components/widgets/searchBar";
import EditModal from "../../components/widgets/editModal";
import CompanyProductSearch from '../../components/widgets/CompanyProductSearch';
import CompanyProductList from '../../components/widgets/CompanyProductList';
import draggable from 'vuedraggable';

/**
 * Shops component
 */
export default {
  components: { Layout, PageHeader, SideSlidePanel, SearchBar, EditModal, CompanyProductSearch, CompanyProductList, draggable },
  data() {
    return {
      custom_field_editing: -1,
      loadingCustomFields: false,
      custom_field: {
        type: '',
        name: '',
        options: [],
        value: '',
        required: false,
        multiple: false,
        enable_editing: 1,
      },
      custom_fieldRaw: {
        type: '',
        name: '',
        options: [],
        value: '',
        required: false,
        multiple: false,
        enable_editing: 1,
      },
      form_fields: {
        custom_fields: [{
        type: 'Text',
        name: 'Name',
        options: [],
        value: '',
        required: true,
        multiple: false,
        enable_editing: 0,
      },{
        type: 'Email',
        name: 'Email',
        options: [],
        value: '',
        required: true,
        multiple: false,
        enable_editing: 0,
      }],
      },
      deleteMode: 'single',
      csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
      shopsData: '',
      viewSupplier: false,
      productsData: [],
      showListingProducts: 0,
      addProductToListing: 0,
      addSupplierAddressPanel: false,
      addSupplierContactPanel: false,
      addProductToggle: false,
      deleteAddressPanel: false,
      deleteContactPanel: false,
      deleteSupplierPanel: false,
      deleteContactIndex: 0,
      deleteAddressIndex: 0,
      selectedlist: null,
      deletesupplieraddress: '',
      deletesuppliercontact: '',
      searchproduct: '',
      shops_error: [],
      company_info: [],
      countries: [],
      counties: [],
      categoryProducts: [],
      supplierAddresses: [],
      supplierContacts: [],
      productsLimit: '100',
      productsCurrentPage: 1,
      productListingSelected: [],
      error_reponse: [],
      error_message: false,
      success_message: false,
      success_text: '',
      loadingForm: false, 
      loadingList: false,
      newquantity: 0,
      listingquantity: 0,
      transferquantity: 0,
      currentPage: 1,
      orderDesc: false,
      priceRange: [0,999999],
      trade_productsactive: '',
      trade_active: '',
      public_active: '',
      public_productsactive: '',
      totalListingsQuantity: 0,
      editModal: 0,
      productsactive: '',
      brands: '',
      collections: '',
      active: '',
      view: 'list',
      search: '',
      limit: '25',
      sortby: 'id',
      order: 'desc',
      isCheckAll: false,
      addNewPanel: false,
      editPanel: false,
      subscribersPanel: false,
      formPanel: false,
      selectedids: [],
      isCustom: 0,
      list: {
        id: null,
        name: '',
        description: '',
        active: 0,
      },
      form: {
        id: null,
        name: '',
        description: '',
        fields: ['email'],
        options: '',
        active: 0,
      },
      title: 'Customer Lists',
      items: [
        {
          text: 'Customer',
          href: '/',
        },
        {
          text: 'Lists',
          active: true,
        },
      ],
    }
  },
  methods: {
    toggleMultiple(status){
      console.log(status);
      if(status !== true){
        this.custom_field['value'] = [];
      }else{
        this.custom_field['value'] = '';
      }
    },
    closeEditModal(){
      this.$refs['edit-modal'].hide()
    },
    addList(){
      this.loadingForm = true;
      axios.post('/api/company/customer/list/create', {list: this.list, form: this.form_fields}, {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token, 'X-CSRF-TOKEN': this.csrf}}).then(response => {
        this.success_text = 'Congratulations, your new category has been added.';
        this.success_message = true;
        //this.resetProjectData();
        //this.addPanel = false;
        let self = this;
          setTimeout(function(){
            self.loadingForm = false;
            self.addNewPanel = false;
            self.productQuery();
          }, 1500);
      }).catch( error => {
        
        this.error_reponse = error.response.data.errors;
        this.error_message = true;
        //this.resetProjectData();
        let self = this;
          setTimeout(function(){
            self.loadingForm = false;
          }, 1500);
      });
    },
    updateList(){
      this.success_message = false;
      this.error_message = false;
      this.loadingForm = true;
      axios.post('/api/company/customer/list/'+this.selectedlist.id+'/update', {list: this.selectedlist, form: this.selectedlist.form}, {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
        console.log(response);
        this.success_text = 'Congratulations, your category has been updated.';
        this.success_message = true;
        //this.resetProjectData();
        //this.addPanel = false;
        let self = this;
          setTimeout(function(){
            self.loadingForm = false;
            self.editPanel = false;
            self.productQuery();
          }, 500);
      }).catch( error => {
        
        this.error_reponse = error.response.data.errors;
        this.error_message = true;
        //this.resetProjectData();
        let self = this;
          setTimeout(function(){
            self.loadingForm = false;
          }, 500);
      });

    },
    destroySupplier(id){
        axios.post('/api/company/customer/list/'+id+'/destroy', {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
            this.success_text = 'Congratulations, the supplier has been deleted.';
            this.success_message = true;
        }).catch(error => {
            this.error_reponse = {error: ["Error - could not delete. Please try again."]};
            this.error_message = true;
        });
    },
    setActive(active){
            axios.post('/api/company/customer/list/set-active', {ids: this.selectedids, active: active}, {headers: {
              'Authorization' : 'Bearer '+this.$attrs.user.api_token
            }}).then(response => {
              
              this.success_text = 'Congratulations, your action has been saved';
              this.success_message = true;
              if(this.selectedids.length > 1){
                return this.productQuery();
              }else{
                return true;
              }
                
            }).catch(error => {

            });
    },
    destroyResource(product_id){
            axios.post('/api/company/customer/list/'+this.selected_product_id+'/destroy', {}, {headers: {
              'Authorization' : 'Bearer '+this.$attrs.user.api_token
            }}).then(response => {
                this.$bvModal.hide('delete-modal');
                return this.productQuery();
            }).catch(error => {

            });
    },
     bulkDestroyResource(){
            axios.post('/api/company/customer/list/bulk-destroy', {ids: this.selectedids}, {headers: {
              'Authorization' : 'Bearer '+this.$attrs.user.api_token
            }}).then(response => {
                this.$bvModal.hide('delete-modal');
                this.selectedids = [];
                return this.productQuery();
            }).catch(error => {

            });
     },
     bulkDuplicateResource(){
      axios.post('/api/company/customer/list/duplicate', {ids: this.selectedids}, {headers : {
        'Authorization' : 'Bearer '+this.$attrs.user.api_token
      }}).then(response => {
        console.log(response);
        this.success_text = 'Congratulations, your collection(s) have been updated.';
        this.success_message = true;
        //this.resetProjectData();
        this.addPanel = false;
        let self = this;
          setTimeout(function(){
            self.loadingForm = false;
            self.productQuery();
            //self.$bvModal.hide('move-modal');
          }, 1500);
      }).catch( error => {
        
        this.error_reponse = error.response.data.errors;
        this.error_message = true;
        //this.resetProjectData();
        let self = this;
          setTimeout(function(){
            self.loadingForm = false;
          }, 1500);
      });
    },
    showStatusMessage(result){
      if(result == false){
        this.error_reponse = {error: ["Product Already Exists in Category"]};
        this.error_message = true;
      }else{
        this.success_message = true;
        this.success_text = 'Congratulations, your action has been saved.';
        this.getProducts(this.selectedlist.id)
      }
    },
    priceIncVat(price, tax_percentage){
      var tax = 1 + (tax_percentage / 100);
      return price * tax;
    },
     productQuery(){
     
      if(this.orderDesc == true){
        this.order = 'desc';
      }else{
        this.order = 'asc';
      }
      axios.get('/api/company/customer/lists?page='+this.currentPage+'&limit='+this.limit+'&sortby='+this.sortby+'&order='+this.order+'&search='+this.search+'&trade_active='+this.trade_active+'&public_active='+this.public_active+'&active='+this.active+'', {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
        console.log(response);
        this.shopsData = response.data;
      });
    },
    filterSelected(id){
        if(this.selectedids.length > 0){
          var exists = this.selectedids.filter(item => item == id);
          if(exists.length > 0){
            return true;
          }else{
            return false
          }
        }else{
          return false;
        }
      },
      checkAll: function(){ 
                 this.isCheckAll = !this.isCheckAll; 
                 this.selectedids = []; 
                 if(this.isCheckAll){ 
                     // Check all 
                     for (var key in this.shopsData.data) {          
                        this.selectedids.push(this.shopsData.data[key].id); 
                     } 
                 } 
              }, 
      updateCheckall: function(){ 
                 if(this.selectedids.length == this.shopsData.data.length){
                    this.isCheckAll = true; 
                 }else{ 
                    this.isCheckAll = false; 
                 } 
      },
      sanitizeTitle: function(title) {
            var nickname = "";
            // Change to lower case
            var titleLower = title.toLowerCase();
            // Letter "e"
            nickname = titleLower.replace(/e|é|è|ẽ|ẻ|ẹ|ê|ế|ề|ễ|ể|ệ/gi, 'e');
            // Letter "a"
            nickname = nickname.replace(/a|á|à|ã|ả|ạ|ă|ắ|ằ|ẵ|ẳ|ặ|â|ấ|ầ|ẫ|ẩ|ậ/gi, 'a');
            // Letter "o"
            nickname = nickname.replace(/o|ó|ò|õ|ỏ|ọ|ô|ố|ồ|ỗ|ổ|ộ|ơ|ớ|ờ|ỡ|ở|ợ/gi, 'o');
            // Letter "u"
            nickname = nickname.replace(/u|ú|ù|ũ|ủ|ụ|ư|ứ|ừ|ữ|ử|ự/gi, 'u');
            // Letter "d"
            nickname = nickname.replace(/đ/gi, 'd');
            // Trim the last whitespace
            nickname = nickname.replace(/\s*$/g, '');
            // Change whitespace to "-"
            nickname = nickname.replace(/\s+/g, '-');
            // Remove quotations
            nickname = nickname.replace(/["']/g, "");
            // Remove symbols
            nickname = nickname.replace(/@|#|!/g, '');
            return nickname;
        }
  },
  computed:{
    allProductListingQuantities: function(){
      if(this.productListingSelected.length !== 0){
          if(this.productListingSelected.listings.length > 0){
            this.totalListingsQuantity = 0;
            for(var i = 0; i < this.productListingSelected.listings.length; i++){
              //console.log(this.productListingSelected.listings[i].quantity);
              this.totalListingsQuantity = Number.parseFloat(this.totalListingsQuantity) + Number.parseFloat(this.productListingSelected.listings[i].quantity);
            }
            return this.totalListingsQuantity;
          }else{
            this.totalListingsQuantity = 0;
            return 0;
          }
      }else{
        return 0;
      }
    }
  },
  created(){
    this.productQuery();
  },
  mounted(){

  },
}
</script>

<template>
  <Layout :nickname="$attrs.nickname" :user="$attrs.user" :menuItems="$attrs.menuItems">
    <PageHeader :title="title" :items="items" />
    <div class="row">
       <SideSlidePanel :editPanel="deleteSupplierPanel" :panelClass="'edit-sidepanel'" @close-panel="deleteSupplierPanel = !deleteSupplierPanel">
         <div class="row">
          <div class="col-md-12" v-if="deleteSupplierPanel == true">
            <p>You are about to delete {{selectedlist.name}}. Are you sure?</p>
            <a class="btn btn-success" @click="destroySupplier(selectedlist.id)">Confirm</a>
            <a class="btn btn-danger" @click="deleteSupplierPanel = false">Cancel</a>
          </div>
        </div>
        </SideSlidePanel>
       <b-col md="12">
                    <transition name="slide-success" mode="out-in">
                  <b-alert id="success-message" variant="success" :show="success_message"  dismissible @dismissed="success_message = !success_message">
                      <p class="font-size-14">{{success_text}}</p>
                    </b-alert>
                  </transition>
                  </b-col>
                   <b-col md="12">
                    <transition name="slide-error" mode="out-in">
                  <b-alert id="error-message" variant="error" :show="error_message"  dismissible @dismissed="error_message = !error_message">
                      <p class="font-size-14">{{error_text}}</p>
                    </b-alert>
                  </transition>
                  </b-col>
      <div class="col-md-12">
        <div class="row mb-3">
          <div class="col-xl-1 col-sm-6">
            <div class="mt-2">
              <b-form-select class="form-control" v-model="limit" :options="limits" @input="productQuery()"></b-form-select>
            </div>
          </div>
         
          <div class="col-lg-2 col-sm-6 d-flex">
          <form class="my-auto mr-auto">
            <small class="mx-auto my-2 text-center d-block">Results {{shopsData.from}} - {{shopsData.to}} of {{shopsData.total}}</small>
          </form>
          <div v-if="view == 'grid'" class="text-center my-auto ml-auto"><div
                        class="form-check form-switch form-switch-sm mb-0 mr-auto p-0 mt-auto d-flex"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input mx-auto my-auto" 
                          type="checkbox"
                          id="SwitchCheckSizeCheckall"
                         
                          v-model="isCheckAll"
                          @click='checkAll()'
                          name="active"
                        />
                        <label class="d-inline-block my-auto ml-2" for="SwitchCheckSizeCheckall">Select All</label>
                    </div></div>
        </div>
        <div class="col-lg-3">
          <b-form-group class="my-auto ml-auto mr-3">
            <b-form-radio-group id="btn-radios-1" button-variant="outline-light"
        size="md" v-model="marketplace_brands" :options="quickfilter_options" buttons @input="productQuery()"></b-form-radio-group>
      
          
          </b-form-group>
        </div>
        <div class="col-lg-3 col-sm-6 d-flex">
          
            <div class="mt-sm-0 form-inline w-100" >
              <div class="search-box mr-2 ml-auto">
                <div class="position-relative">
                  <input
                    type="text"
                    class="form-control w-100 border-0"
                    placeholder="Search name, reference or description..."
                    v-model="search"
                    @keyup.enter="productQuery()"
                  />
                  <i class="bx bx-search-alt search-icon"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-sm-6 d-flex">
            <div class="d-flex ml-auto">
              <ul class="nav nav-pills product-view-nav">
                
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': view == 'grid' }" href="javascript: void(0);" @click="view = 'grid'">
                    <i class="bx bx-grid-alt"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': view == 'list' }" href="javascript: void(0);" @click="view = 'list'">
                    <i class="bx bx-list-ul"></i>
                  </a>
                </li>
                <li class="nav-item">
                 <b-dropdown text="Bulk Actions" variant="primary" class="rounded">
                  <b-dropdown-item :disabled="($attrs.user.companies[0].userrole.name !== 'Owner') && !$attrs.user.companies[0].permissions.edit.includes($attrs.active_menu.id)" href="javascript: void(0);" @click="setActive(true)">Set Active</b-dropdown-item>
                  <b-dropdown-item :disabled="($attrs.user.companies[0].userrole.name !== 'Owner') && !$attrs.user.companies[0].permissions.edit.includes($attrs.active_menu.id)" href="javascript: void(0);" @click="setActive(false)">Set Inactive</b-dropdown-item>
                  <b-dropdown-item :disabled="($attrs.user.companies[0].userrole.name !== 'Owner') && !$attrs.user.companies[0].permissions.edit.includes($attrs.active_menu.id)" href="javascript: void(0);" @click="bulkDuplicateResource()">Duplicate</b-dropdown-item>
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-item :disabled="($attrs.user.companies[0].userrole.name !== 'Owner') && !$attrs.user.companies[0].permissions.delete.includes($attrs.active_menu.id)" href="javascript: void(0);" v-b-modal.delete-modal @click="deleteMode = 'bulk'">Delete</b-dropdown-item>
                </b-dropdown>
                </li>
                <li class="nav-item">
                 <b-button
                    variant="success"
                    :disabled="($attrs.user.companies[0].userrole.name !== 'Owner') && !$attrs.user.companies[0].permissions.add.includes($attrs.active_menu.id)"
                    class="btn btn-success btn-rounded mb-2 mr-2 text-white"
                    href="javascript:;"
                    @click="addNewPanel = !addNewPanel"
                  >
                    <i class="mdi mdi-plus mr-1"></i> New
                  </b-button>
                </li>
              </ul>
              <div class="text-sm-right">
                  
                </div>
          </div>
        </div>
      </div>
       <div class="d-flex flex-column justify-content-center h-100 restricted-col" v-if="($attrs.user.companies[0].userrole.name !== 'Owner') && !$attrs.user.companies[0].permissions.read.includes($attrs.active_menu.id)" >
          <AccessRestricted :page="false"></AccessRestricted>
        </div>
        <div v-else>
        <div class="card" v-if="view == 'list'" >
          <div class="card-body p-0">
            <div class="table-responsive">
              <table class="table table-centered table-hover">

                <thead class="thead-light">
                  <tr>
                    <th scope="col"  style="width: 100px" class="text-center"><div
                        class="form-check form-switch form-switch-sm mb-0 mx-auto p-0 mt-auto d-flex"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input mx-auto my-auto" 
                          type="checkbox"
                          id="SwitchCheckAll"
                         
                          v-model="isCheckAll"
                          @click='checkAll()'
                          name="active"
                        />
                    </div></th>
                    <th scope="col"><a :class="{'text-dark': sortby != 'name'}" href="javascript: void(0);" @click="orderDesc = !orderDesc,  sortby = 'name', productQuery()">Name <i v-if="orderDesc == true" class="bx bx-caret-up"></i><i v-if="orderDesc == false" class="bx bx-caret-down"></i></a></th>
                    <th scope="col">Description</th>
                    <!--<th scope="col">Form</th>
                    <th scope="col">Subscribers</th>
                    <th scope="col">Status</th>-->
                    <th scope="col">Action</th>
                  </tr>
                </thead>
               
                <tbody>
                  
                  <tr v-for="list in shopsData.data" :key="list.id" valign="middle">
                    <td>
                       <div class="text-center" v-if="list.logo">
                      <b-form-checkbox class="select-row-btn" v-model="selectedids" :value="list.id" button-variant="primary" @input="updateSelected" button>
                        <i v-if="filterSelected(list.id)" class="bx bx-check"></i>
                        <img v-else :src="'https://'+$attrs.company.nickname+'.s3.eu-west-2.amazonaws.com/'+list.logo" alt class="avatar-sm rounded-circle" />
                      </b-form-checkbox>
                    </div>
                    <div class="text-center" v-else >
                        <b-form-checkbox class="select-row-btn" v-model="selectedids" :value="list.id" button-variant="primary" @input="updateSelected" button>

                          <i v-if="filterSelected(list.id)" class="bx bx-check"></i><span class="avatar-title rounded-circle" v-else>{{(list.name).slice(0,1)}}</span></b-form-checkbox>
                    </div> 
                    </td>
                    <td>
                        <h5 class="m-0">{{list.name}}</h5>
                    </td>
                    <td>
                      <small class="text-muted mb-0" v-if="list.description != null" v-html="(list.description).slice(0, 100)+'...'"></small>
                    </td>
                    <!-- <td>
                      <a href="javascript:;" v-b-tooltip.hover title="Form" placement="left" @click="selectedlist = list, supplierContacts = list.contacts, formPanel = true" class="btn btn-outline-light btn-rounded"><i class="bx bx bx-list-ul"></i></a>
                    </td>
                     <td>
                      <a href="javascript:;" v-b-tooltip.hover title="Subscribers" placement="left" @click="selectedlist = list, supplierAddresses = list.addresses, subscribersPanel = true" class="btn btn-outline-light btn-rounded"><i class="bx bx-user"></i></a>
                    </td>
              
                    <td>
                       <div
                        class="form-check form-switch form-switch-sm my-auto ml-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          :id="`SwitchCheckSize-${list.id}`"
                          v-model="list.active"
                          :value="list.active"
                          name="active"
                          :disabled="($attrs.user.companies[0].userrole.name !== 'Owner') && !$attrs.user.companies[0].permissions.edit.includes($attrs.active_menu.id)"
                          @change="selectedids = [], selectedids.push(list.id), setActive(list.active)"
                        />
                        <label class="form-check-label" :for="`SwitchCheckSize-${list.id}`" 
                          >Active</label
                        >
                    </div>
                    </td>-->
                    <td>
                      <ul class="list-inline font-size-20 contact-links mb-0">
                        <li class="list-inline-item" v-if="($attrs.user.companies[0].userrole.name == 'Owner') || $attrs.user.companies[0].permissions.edit.includes($attrs.active_menu.id)"><a  href="javascript:;" v-b-tooltip.hover title="Edit" placement="left" class="" @click="selectedlist = list, formPanel = true" ><i class="bx bx-edit-alt"></i>
                          </a>
                        </li>
                      <li class="list-inline-item" v-if="($attrs.user.companies[0].userrole.name == 'Owner') || $attrs.user.companies[0].permissions.view.includes($attrs.active_menu.id)">
                        <a :href="'/customer/subscription-list/'+list.uid" v-b-tooltip.hover title="View" placement="left" @click="selectedlist = list, supplierContacts = list.contacts, supplierAddresses = list.addresses, viewSupplier = true" class=""><i class="mdi mdi-eye-outline"></i></a>
                      </li>
                      <li class="list-inline-item" v-if="($attrs.user.companies[0].userrole.name == 'Owner') || $attrs.user.companies[0].permissions.delete.includes($attrs.active_menu.id)">
                        <a href="javascript:;" v-b-tooltip.hover title="Delete" placement="left" v-b-modal.delete-modal @click="selected_product_id = list.id, deleteMode = 'single'" class=""><i class="bx bx-trash-alt"></i></a>
                      </li>
                        <li class="list-inline-item">
                           <b-dropdown v-b-tooltip.hover title="More Options" placement="left" dropleft class="more-options-dropdown"  menu-class="dropdown-menu-right">
                        <template v-slot:button-content>
                           <i class="bx bx-dots-horizontal-rounded"></i>
                        </template>
                          <b-dropdown-item :disabled="($attrs.user.companies[0].userrole.name !== 'Owner') && !$attrs.user.companies[0].permissions.edit.includes($attrs.active_menu.id)" href="javascript: void(0);"  @click="selectedids = [], selectedids.push(list.id), bulkDuplicateResource()">Duplicate</b-dropdown-item>
                      </b-dropdown>
                        </li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <b-pagination
              v-if="shopsData.last_page > 1"
              class="justify-content-center"
              pills
              v-model="currentPage"
              :total-rows="shopsData.total"
              :per-page="shopsData.per_page"
              aria-controls="my-table"
              @input="brandQuery"
            ></b-pagination>
             <small class="mx-auto my-2 text-center d-block">Records {{shopsData.from}} - {{shopsData.to}} of {{shopsData.total}} // Page {{shopsData.current_page}} of {{shopsData.last_page}}</small>
              </div>
            </div>
          </div>
      </div>
    <div class="row" v-if="view == 'grid'" >
     <div class="col-xl-4"  v-for="list in shopsData.data" :key="list.id">
        <div class="card overflow-hidden">
          <div class="bg-soft-primary min-h-100">
            <div class="row">
              
              <div class="col-12 align-self-end">
                <img :src="'/public/storage/'+list.banner" alt class="img-fluid" />
              </div>
            </div>
          </div>
          <div class="card-body pt-0">
            <div class="row">
              <div class="col-sm-12 text-center">
               <div class="avatar-md mx-auto">
                   <div class="text-center" v-if="list.image">
                      <b-form-checkbox class="select-row-btn w-image" v-model="selectedids" :value="list.id" button-variant="primary" @input="updateSelected" button>
                        <i v-if="filterSelected(list.id)" class="bx bx-check"></i>
                        <img v-else :src="`${list.image}`" alt class="avatar-md img-thumbnail rounded-circle h-100" />
                      </b-form-checkbox>
                    </div>
                    <div class="text-center" v-else >
                        <b-form-checkbox class="select-row-btn w-image" v-model="selectedids" :value="list.id" button-variant="primary" @input="updateSelected" button>

                          <i v-if="filterSelected(list.id)" class="bx bx-check"></i><span class="avatar-title rounded-circle" v-else>{{(list.name).slice(0,1)}}</span></b-form-checkbox>
                    </div> 
                </div>
                <h4 class="font-size-18 text-truncate mt-3">{{list.name}}</h4>
                <p class="text-muted mb-0 text-truncate" v-html="list.description"></p>
              </div>

              <div class="col-sm-12">
                <div class="pt-4">
                  <div class="mt-4 d-flex">
                    <a href="javascript:;" v-b-tooltip.hover title="Contacts" placement="left" @click="selectedlist = list, supplierContacts = list.contacts, formPanel = true" class="btn btn-outline-light font-size-16 btn-rounded ml-2 my-auto"><i class="bx bx-list-ul"></i></a>
                    <a href="javascript:;" v-b-tooltip.hover title="Addresses" placement="left" @click="selectedlist = list, supplierAddresses = list.addresses, subscribersPanel = true" class="btn btn-outline-light font-size-16 btn-rounded ml-2 my-auto"><i class="bx bx-user"></i></a>
                    <a href="javascript:;" @click="selectedlist = list, getProducts(list.id), showListingProducts = 1" class="btn btn-outline-light btn-rounded ml-2"><i class="bx bx bx-purchase-tag-alt my-1"></i></a>
                    <b-tooltip target="tooltip-add">Add Product</b-tooltip>
                    <a id="tooltip-add" @click="selectedlist = list, formPanel = true" class="btn btn-outline-light font-size-16 btn-rounded ml-2 my-auto" v-if="($attrs.user.companies[0].userrole.name == 'Owner') || $attrs.user.companies[0].permissions.edit.includes($attrs.active_menu.id)"><i class="bx bx-pencil"></i></a>
                    <a href="javascript:;" v-if="($attrs.user.companies[0].userrole.name == 'Owner') || $attrs.user.companies[0].permissions.view.includes($attrs.active_menu.id)" v-b-tooltip.hover title="View" placement="left" @click="selectedlist = list, supplierContacts = list.contacts, supplierAddresses = list.addresses, viewSupplier = true" class="btn btn-outline-light btn-rounded ml-2"><i class="mdi mdi-eye-outline"></i></a>
                     <a href="javascript:;" v-if="($attrs.user.companies[0].userrole.name == 'Owner') || $attrs.user.companies[0].permissions.delete.includes($attrs.active_menu.id)" v-b-tooltip.hover title="Delete" placement="left" v-b-modal.delete-modal @click="selected_product_id = list.id, deleteMode = 'single'"  class="btn btn-outline-light btn-rounded ml-2"><i class="bx bx-trash-alt"></i></a>
                     <b-dropdown v-b-tooltip.hover title="More Options" placement="left" dropleft class="more-options-dropdown btn btn-outline-light btn-rounded mr-auto ml-2">
                        <template v-slot:button-content>
                           <i class="bx bx-dots-horizontal-rounded"></i>
                        </template>
                         <b-dropdown-item href="javascript: void(0);"  :disabled="($attrs.user.companies[0].userrole.name !== 'Owner') && !$attrs.user.companies[0].permissions.edit.includes($attrs.active_menu.id)" @click="selectedids = [], selectedids.push(list.id), bulkDuplicateResource()">Duplicate</b-dropdown-item>
                      </b-dropdown>
                     <div
                        class="form-check form-switch form-switch-sm my-auto ml-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          :id="`SwitchCheckSize-${list.id}`"
                          v-model="list.active"
                          :value="list.active"
                          name="active"
                          :disabled="($attrs.user.companies[0].userrole.name !== 'Owner') && !$attrs.user.companies[0].permissions.edit.includes($attrs.active_menu.id)"
                          @change="selectedids = [], selectedids.push(list.id), setActive(list.active)"
                        />
                        <label class="form-check-label" :for="`SwitchCheckSize-${list.id}`" 
                          >Active</label
                        >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>

              <div class="col-lg-12">
                <b-pagination
              v-if="shopsData.data.length > 0"
              class="justify-content-center"
              pills
              v-model="currentPage"
              :total-rows="shopsData.total"
              :per-page="shopsData.per_page"
              aria-controls="my-table"
              @input="brandQuery"
            ></b-pagination>
             <small class="mx-auto my-2 text-center d-block">Records {{shopsData.from}} - {{shopsData.to}} of {{shopsData.total}} // Page {{shopsData.current_page}} of {{shopsData.last_page}}</small>
              </div>
      </div>
    </div>
    </div>
    </div>
    <!--  end row -->

    <div class="row">
      <!-- end col-->
    </div>
     <b-modal id="delete-modal" size="md" hide-footer hide-header centered>
        <b-card class="border-radius-1em border shadow-none">
          <h4>Are you sure?</h4>
          <p v-if="deleteMode == 'bulk'">You are about to delete {{selectedids.length}} products. Are you sure you want to do this? This action is not reversable</p>
           <p v-if="deleteMode == 'single'">You are about to delete this category. Are you sure you want to do this? This action is not reversable</p>

           <div class="d-flex">
            <a class="btn btn-danger" v-if="deleteMode == 'bulk'" @click="bulkDestroyResource">
              Delete
            </a>
             <a class="btn btn-danger"  v-if="deleteMode == 'single'" @click="destroyResource">
              Delete
            </a>
            <a class="btn btn-success" @click="$bvModal.hide('delete-modal')">
              Cancel
            </a>
          </div>
        </b-card>
      </b-modal>
         <SideSlidePanel :editPanel="addNewPanel" :panelClass="'edit-sidepanel'" @close-panel="addNewPanel = 0">
           <div class="row px-3">
        
              <div class="col-md-12">
                <b-card class="border-radius-1em border shadow-none">
                  <h4 class="my-3">Add List</h4>

                  	<b-form-group label="List Name">
              		<b-form-input type="text" v-model="list.name">
              		</b-form-input>
              	</b-form-group>
              	<b-form-group label="List Description">
              		<b-form-textarea type="text" v-model="list.description">
              		</b-form-textarea>
              	</b-form-group>
                </b-card>
              
              </div>

     		<div class="col-md-12">
     			 <b-card class="border-radius-1em border shadow-none">
                  <h4 class="my-3">Form Settings</h4>
            <div class="d-flex">
            <b-form-group label="Field Type" class="w-50 pr-2">
              <b-form-select class="form-control" :options="['Email', 'Text', 'Long Text', 'Number', 'Dropdown', 'Radio', 'Checkbox', 'Date']" v-model="custom_field['type']">
              </b-form-select>
             </b-form-group>

            <b-form-group label="Name" class="w-50">
              <b-form-input v-model="custom_field['name']">
              </b-form-input>
            </b-form-group>

              <div
                        class="form-check form-switch form-switch-sm pt-4 mt-2 ml-2"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          :id="`SwitchCheckSize-formrequired`"
                          v-model="custom_field['required']"
                          :value="custom_field['required']"
                          name="active"
                          :disabled="($attrs.user.companies[0].userrole.name !== 'Owner') && !$attrs.user.companies[0].permissions.edit.includes($attrs.active_menu.id)"
                   
                        />
                        <label class="form-check-label" :for="`SwitchCheckSize-formrequired`" 
                          >Required</label
                        >
                    </div>
            </div>
           
            <div v-if="custom_field['type'] == 'Dropdown' || custom_field['type'] == 'Radio' || custom_field['type'] == 'Checkbox'" class="d-flex flex-column" >
              <div class="d-flex" v-for="(option, index) in custom_field['options']">
              <b-form-group  label="Option" class="w-100">
                 <b-form-input v-model="custom_field['options'][index]" type="Value">
                </b-form-input>
              </b-form-group>

              <a class="btn btn-light mt-auto btn-rounded" @click="custom_field['options'].splice(index, 1)">
                <i class="bx bx-minus"></i>
              </a>
            </div>
            <b-button variant="light" class="btn-rounded mt-2 btn-block" @click="custom_field['options'].push('')">
              Add {{custom_field['type']}} Option
            </b-button>
            </div>

            <b-button variant="primary" class="btn-rounded mt-2 btn-block" v-if="custom_field_editing = -1" :disabled="(custom_field['name'] == '') || (custom_field['type'] == 'Dropdown' && custom_field['options'].length == 0) || (custom_field['type'] == 'Radio' && custom_field['options'].length == 0) || (custom_field['type'] == 'Checkbox' && custom_field['options'].length == 0)" @click="form_fields['custom_fields'].push(custom_field), custom_field = JSON.parse(JSON.stringify(custom_fieldRaw))">
              Add Field
            </b-button>
             <b-button variant="primary" class="btn-rounded mt-2 btn-block" v-else :disabled="(custom_field['name'] == '') || (custom_field['type'] == 'Dropdown' && custom_field['options'].length == 0) || (custom_field['type'] == 'Radio' && custom_field['options'].length == 0) || (custom_field['type'] == 'Checkbox' && custom_field['options'].length == 0)" @click="form_fields['custom_fields'][custom_field_editing] = (custom_field), custom_field = JSON.parse(JSON.stringify(custom_fieldRaw)), custom_field_editing = -1">
              Update Field
            </b-button>
           

            <div v-if="form_fields.custom_fields !== null" class="mt-2">
            <draggable v-model="form_fields['custom_fields']" handle=".handle">
            <b-card v-if="!loadingCustomFields" class=" border shadow-none mb-0" v-for="(field, fieldindex) in form_fields['custom_fields']" body-class="d-flex flex-column">
                <b-form-group class="mb-2 border-bottom" v-if="field['type'] == 'Text'" :label="field['name']" :class="field['required'] == true ? 'required' : ''">
                  <b-form-input type="text" v-model="field['value']" :required="field['required'] == true">

                  </b-form-input>
                  
                </b-form-group>
                 <b-form-group class="mb-2 border-bottom" v-if="field['type'] == 'Email'" :label="field['name']" :class="field['required'] == true ? 'required' : ''">
                  <b-form-input type="email" v-model="field['value']" :required="field['required'] == true">

                  </b-form-input>
                  
                </b-form-group>
                <b-form-group class="mb-2 border-bottom pb-4" v-else-if="field['type'] == 'Long Text'" :label="field['name']" :class="field['required'] == true ? 'required' : ''">
                  <b-form-textarea rows="4" max-rows="8" v-model="field['value']" :required="field['required'] == true">

                  </b-form-textarea>
                </b-form-group>
                <b-form-group class="mb-2 border-bottom" v-else-if="field['type'] == 'Number'" :label="field['name']" :class="field['required'] == true ? 'required' : ''">
                  <b-form-input type="number" v-model="field['value']" :required="field['required'] == true">

                  </b-form-input>
                </b-form-group>
                <b-form-group class="mb-2 border-bottom"  v-else-if="field['type'] == 'Dropdown'" :label="field['name']" :class="field['required'] == true ? 'required' : ''">
                 <b-form-select class="form-control" :label="field['name']" v-model="field['value']" :options="field['options']" :required="field['required'] == true">

                  </b-form-select>
                </b-form-group>
                 <b-form-group class="mb-2 border-bottom d-flex" v-else-if="field['type'] == 'Radio'" :label="field['name']" :class="field['required'] == true ? 'required' : ''">
                  
                           <b-form-radio-group class="w-100" v-model="field['value']" :options="field['options']" :required="field['required'] == true" :multiple="field['multiple']">

                            </b-form-radio-group>

                </b-form-group>
                 <b-form-group class="mb-2 border-bottom d-flex" v-else-if="field['type'] == 'Checkbox'" :label="field['name']" :class="field['required'] == true ? 'required' : ''">
                  
                           <b-form-checkbox-group class="w-100" v-model="field['value']" :options="field['options']" :required="field['required'] == true" :multiple="field['multiple']">

                            </b-form-checkbox-group>

                </b-form-group>
                <b-form-group class="mb-2 border-bottom d-flex" v-else-if="field['type'] == 'Date'" :label="field['name']" :class="field['required'] == true ? 'required' : ''">
                  
                           <b-form-input type="date" class="w-100" v-model="field['value']" :required="field['required'] == true">

                            </b-form-input>

                </b-form-group>
                <div class="d-flex align-items-end justify-content-end" v-if="field.enable_editing == true">
                	 <a class="btn ml-auto btn-light btn-rounded mb-auto ml-auto handle" href="javascript:;">
                    <i class="bx bx-move"></i>
                </a>
                <a class="btn btn-light btn-rounded mb-auto ml-2" @click="loadingCustomFields = true, custom_field_editing = fieldindex, custom_field = form_fields['custom_fields'][fieldindex], loadingCustomFields = false">
                    <i class="bx bx-edit-alt"></i>
                </a>
                <a class="btn btn-light btn-rounded mb-auto ml-2" @click="loadingCustomFields = true, form_fields['custom_fields'].splice(fieldindex, 1), loadingCustomFields = false">
                    <i class="bx bx-minus"></i>
                </a>

            	</div>
            </b-card>
         </draggable>
          </div>
            </b-overlay>
			</b-card>
     
     		</div>

              <div class="col-md-12">
              	  <a class="btn btn-success btn-block btn-rounded" @click="addList()">
              		Save
              	</a>
              </div>
          </div>
    </SideSlidePanel>

        <SideSlidePanel :editPanel="formPanel" :panelClass="'edit-sidepanel'" @close-panel="formPanel = !formPanel">
        	<b-card class="border-radius-1em border shadow-none" v-if="selectedlist !== null">
            <h4 class="my-3">Edit List</h4>
                 <b-form-group label="List Name" class="pb-3">
                  <b-form-input type="text" v-model="selectedlist.name">
                  </b-form-input>
                </b-form-group>
                <b-form-group label="List Description">
                  <b-form-textarea type="text" v-model="selectedlist.description">
                  </b-form-textarea>
                </b-form-group>
        	</b-card>
        	 <b-card class="border-radius-1em border shadow-none" v-if="(selectedlist !== null) && (selectedlist.form !== null)">
                  <h4 class="my-3">Update Form</h4>
            <div class="d-flex">
            <b-form-group label="Field Type" class="w-50 pr-2">
              <b-form-select class="form-control" :options="['Email', 'Text', 'Long Text', 'Number', 'Dropdown', 'Radio', 'Checkbox', 'Date']" v-model="custom_field['type']">
              </b-form-select>
             </b-form-group>

            <b-form-group label="Name" class="w-50">
              <b-form-input v-model="custom_field['name']">
              </b-form-input>
            </b-form-group>

              <div
                        class="form-check form-switch form-switch-sm pt-4 mt-2 ml-2"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          :id="`SwitchCheckSize-formrequired`"
                          v-model="custom_field['required']"
                          :value="custom_field['required']"
                          name="active"
                          :disabled="($attrs.user.companies[0].userrole.name !== 'Owner') && !$attrs.user.companies[0].permissions.edit.includes($attrs.active_menu.id)"
                   
                        />
                        <label class="form-check-label" :for="`SwitchCheckSize-formrequired`" 
                          >Required</label
                        >
                    </div>
                    <div
                        class="form-check form-switch form-switch-sm pt-4 mt-2 ml-2"
                        dir="ltr"
                        v-if="(custom_field['type'] == 'Checkbox') || (custom_field['type'] == 'Radio')"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          :id="`SwitchCheckSize-formrequired`"
                          v-model="custom_field['multiple']"
                          :value="custom_field['multiple']"
                          name="active"
                          :disabled="($attrs.user.companies[0].userrole.name !== 'Owner') && !$attrs.user.companies[0].permissions.edit.includes($attrs.active_menu.id)"
                          @input="toggleMultiple(custom_field['multiple'])"
                   
                        />
                        <label class="form-check-label" :for="`SwitchCheckSize-formrequired`" 
                          >Multiple</label
                        >
                    </div>
            </div>
           
            <div v-if="custom_field['type'] == 'Dropdown' || custom_field['type'] == 'Radio' || custom_field['type'] == 'Checkbox'" class="d-flex flex-column" >
              <div class="d-flex" v-for="(option, index) in custom_field['options']">
              <b-form-group  label="Option" class="w-100">
                 <b-form-input v-model="custom_field['options'][index]" type="Value">
                </b-form-input>
              </b-form-group>

              <a class="btn btn-light mt-auto btn-rounded" @click="custom_field['options'].splice(index, 1)">
                <i class="bx bx-minus"></i>
              </a>
            </div>
            <b-button variant="light" class="btn-rounded mt-2 btn-block" @click="custom_field['options'].push('')">
              Add {{custom_field['type']}} Option
            </b-button>
            </div>

            <b-button variant="primary" class="btn-rounded mt-2 btn-block" v-if="custom_field_editing = -1" :disabled="(custom_field['name'] == '') || (custom_field['type'] == 'Dropdown' && custom_field['options'].length == 0) || (custom_field['type'] == 'Radio' && custom_field['options'].length == 0) || (custom_field['type'] == 'Checkbox' && custom_field['options'].length == 0)" @click="selectedlist.form.fields.push(custom_field), custom_field = JSON.parse(JSON.stringify(custom_fieldRaw))">
              Add Field
            </b-button>
             <b-button variant="primary" class="btn-rounded mt-2 btn-block" v-else :disabled="(custom_field['name'] == '') || (custom_field['type'] == 'Dropdown' && custom_field['options'].length == 0) || (custom_field['type'] == 'Radio' && custom_field['options'].length == 0) || (custom_field['type'] == 'Checkbox' && custom_field['options'].length == 0)" @click="selectedlist.form.fields[custom_field_editing] = (custom_field), custom_field = JSON.parse(JSON.stringify(custom_fieldRaw)), custom_field_editing = -1">
              Update Field
            </b-button>
           

            <div v-if="selectedlist.form.fields !== null" class="mt-2">
            <draggable v-model="selectedlist.form.fields" handle=".handle">
            <b-card v-if="!loadingCustomFields" class=" border shadow-none mb-0" v-for="(field, fieldindex) in selectedlist.form.fields" body-class="d-flex flex-column">
                <b-form-group class="mb-2 border-bottom" v-if="field['type'] == 'Text'" :label="field['name']" :class="field['required'] == true ? 'required' : ''">
                  <b-form-input type="text" v-model="field['value']" :required="field['required'] == true">

                  </b-form-input>
                  
                </b-form-group>
                 <b-form-group class="mb-2 border-bottom" v-if="field['type'] == 'Email'" :label="field['name']" :class="field['required'] == true ? 'required' : ''">
                  <b-form-input type="email" v-model="field['value']" :required="field['required'] == true">

                  </b-form-input>
                  
                </b-form-group>
                <b-form-group class="mb-2 border-bottom pb-4" v-else-if="field['type'] == 'Long Text'" :label="field['name']" :class="field['required'] == true ? 'required' : ''">
                  <b-form-textarea rows="4" max-rows="8" v-model="field['value']" :required="field['required'] == true">

                  </b-form-textarea>
                </b-form-group>
                <b-form-group class="mb-2 border-bottom" v-else-if="field['type'] == 'Number'" :label="field['name']" :class="field['required'] == true ? 'required' : ''">
                  <b-form-input type="number" v-model="field['value']" :required="field['required'] == true">

                  </b-form-input>
                </b-form-group>
                <b-form-group class="mb-2 border-bottom"  v-else-if="field['type'] == 'Dropdown'" :label="field['name']" :class="field['required'] == true ? 'required' : ''">
                 <b-form-select class="form-control" :label="field['name']" v-model="field['value']" :options="field['options']" :required="field['required'] == true">

                  </b-form-select>
                </b-form-group>
                 <b-form-group class="mb-2 border-bottom d-flex" v-else-if="field['type'] == 'Radio'" :label="field['name']" :class="field['required'] == true ? 'required' : ''">
                  
                           <b-form-radio-group class="w-100" v-model="field['value']" :options="field['options']" :required="field['required'] == true" :multiple="field['multiple']">

                            </b-form-radio-group>

                </b-form-group>
                 <b-form-group class="mb-2 border-bottom d-flex" v-else-if="field['type'] == 'Checkbox'" :label="field['name']" :class="field['required'] == true ? 'required' : ''">
                  
                           <b-form-checkbox-group class="w-100" v-model="field['value']" :options="field['options']" :required="field['required'] == true" :multiple="field['multiple']">

                            </b-form-checkbox-group>

                </b-form-group>
                <b-form-group class="mb-2 border-bottom d-flex" v-else-if="field['type'] == 'Date'" :label="field['name']" :class="field['required'] == true ? 'required' : ''">
                  
                           <b-form-input type="date" class="w-100" v-model="field['value']" :required="field['required'] == true">

                            </b-form-input>

                </b-form-group>
                <div class="d-flex align-items-end justify-content-end" v-if="field.enable_editing == true">
                	 <a class="btn ml-auto btn-light btn-rounded mb-auto ml-auto handle" href="javascript:;">
                    <i class="bx bx-move"></i>
                </a>
                <a class="btn btn-light btn-rounded mb-auto ml-2" @click="loadingCustomFields = true, custom_field_editing = fieldindex, custom_field = selectedlist.form.fields[fieldindex], loadingCustomFields = false">
                    <i class="bx bx-edit-alt"></i>
                </a>
                <a class="btn btn-light btn-rounded mb-auto ml-2" @click="loadingCustomFields = true, selectedlist.form.fields.splice(fieldindex, 1), loadingCustomFields = false">
                    <i class="bx bx-minus"></i>
                </a>

            	</div>
                
            </b-card>
         </draggable>
          </div>
            </b-overlay>
            <div class="col-md-12">
                  <a class="btn btn-success btn-block btn-rounded" @click="updateList()">
                  Update
                </a>
              </div>
			</b-card>
    </SideSlidePanel>
  </Layout>
</template>
