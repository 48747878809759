<script>
import NavBar from "../components/nav-bar";
import SideBar from "../components/side-bar";
import RightBar from "../components/right-bar";
import Footer from "../components/footer";
import SideSlidePanel from "../components/widgets/SideSlidePanel";
import SidePanelCalculators from "../components/widgets/calculators/SidePanelCalculators";
import MetalsCharts from "../components/widgets/price-charts/metals";
import { EventBus } from '../app'

export default {
  components: { NavBar, SideBar, RightBar, Footer, SideSlidePanel, SidePanelCalculators, MetalsCharts},
  data() {
    return {
      isMenuCondensed: false,
      showCalculators: false,
      showMetalsCharts: false,
      calculatorTab: 0,
      menu: [],
    };
  },
  props: {
    menu: Array
  },
  created: () => {
    document.body.removeAttribute("data-layout", "horizontal");
    document.body.removeAttribute("data-topbar", "light");
    document.body.removeAttribute("data-layout-size", "boxed");
    
  },
  mounted(){
    /*this.menu = JSON.parse(localStorage.getItem("menu")) || [];
        if (this.menu.length == 0) {
         axios.get('/api/menus').then(response => {
                this.menu = response.data[0].menu;
                 localStorage.setItem("menu", JSON.stringify(this.menu));
            }).catch(error => {
              console.log(error);
            });

        }*/
  },
  methods: {
    toggleMenu() {
      document.body.classList.toggle("sidebar-enable");

      if (window.screen.width >= 992) {
        // eslint-disable-next-line no-unused-vars
        document.body.classList.toggle("vertical-collpsed");
      } else {
        // eslint-disable-next-line no-unused-vars
        document.body.classList.remove("vertical-collpsed");
      }
      this.isMenuCondensed = !this.isMenuCondensed;
    },
    toggleRightSidebar() {
      document.body.classList.toggle("right-bar-enabled");
    },
    hideRightSidebar() {
      document.body.classList.remove("right-bar-enabled");
    },
    showCalculator(payload){
      this.showCalculators =  true;
      this.calculatorTab = payload;
    }

  }
};
</script>

<template>
  <div id="layout-wrapper">
    <NavBar :user="$attrs.user"  @showCalculatorWindow="showCalculator" @showMetalPricing="showMetalsCharts = true" />
    <SideBar :is-condensed="isMenuCondensed" :user="$attrs.user" :menuItems="menu" :active_menu="$attrs.active_menu" />
    <!-- ============================================================== -->
    <!-- Start Page Content here -->
    <!-- ============================================================== -->

    <div class="main-content">
      <div class="page-content">
        <!-- Start Content-->
        <div class="container-fluid">
          <slot  @showStorageWindow="showFileManager = !showFileManager"  />
        </div>
      </div>
      <SideSlidePanel :editPanel="showCalculators" :panelClass="'edit-sidepanel-large'" @close-panel="showCalculators = false">
        <b-row><SidePanelCalculators :activeTab="calculatorTab" /></b-row>
      </SideSlidePanel>
      <SideSlidePanel :editPanel="showMetalsCharts" :panelClass="'edit-sidepanel-large'" @close-panel="showMetalsCharts = false">
        <b-row><MetalsCharts ></MetalsCharts></b-row>
      </SideSlidePanel>

      <Footer />
    </div>
    <RightBar />
  </div>
</template>
