/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require("./bootstrap");
import Vue from "vue";
//import VueMeta from 'vue-meta'
window.Vue = Vue;

import { BootstrapVue } from "bootstrap-vue";
import { FormRatingPlugin } from 'bootstrap-vue'
//import vClickOutside from "v-click-outside";
//import VueMask from "v-mask";
//import Vuelidate from "vuelidate";
//import VueApexCharts from "vue-apexcharts";
import * as VueGoogleMaps from "vue2-google-maps";
import VueSweetalert2 from "vue-sweetalert2";
import i18n from "./websitei18n";
import VueLuxon from "vue-luxon";
import VueRouter from 'vue-router';
import VueAwesomeSwiper from 'vue-awesome-swiper'
 import Homepage from './public-views/homepage';
 import About from './public-views/about';
 import Terms from './public-views/terms-and-conditions';
 import Privacy from './public-views/privacy-policy';
 import Contact from './public-views/contact';
 import Sellers from './public-views/sellers';
 import Seller from './public-views/seller';
 import Sell from './public-views/sell-your-jewellery';
 import Services from './public-views/services';
 import Service from './public-views/service';
 import Product from './public-views/product';
 import CustomerAccount from './public-views/customer-account';
 import Category from './public-views/category';
 import Categories from './public-views/categories';
 import Collections from './public-views/collections';
 import Collection from './public-views/collection';
 import Order from './public-views/order';
 import NotFound from './public-views/utility/404';
 import Layout from './layouts/public';
 const routes = [
     {
     path: '/',
     name: 'layout',
     component: Layout,
     children: [
        { path: '/', component: Homepage, name: 'Home' , meta: {scrollToTop: true, showFooter: true, title: 'Gltrbox Jewellery Marketplace', description: 'Shop for modern, vintage and antique jewellery from an exclusive collection of high-street retail jewellers. Discover new jewellery collections and add a sparkle to your jewellery box.', image: 'https://gemesys.co.uk/images/category/vintage-jewellery.jpg', og: {title: 'Gltrbox Jewellery Marketplace', description: 'Shop for modern, vintage and antique jewellery from an exclusive collection of high-street retail jewellers. Discover new jewellery collections and add a sparkle to your jewellery box.', image: 'https://gemesys.co.uk/images/category/vintage-jewellery.jpg', type: 'website', locale: 'en_GB', robots: 'index,follow', author: ''}, twitter: {title: 'Gltrbox Jewellery Marketplace', image: 'https://gemesys.co.uk/images/category/vintage-jewellery.jpg', creator: '@gltrbox'}}},
        { path: '/home', redirect: { name: 'Home' } },
        { path: '/about', component: About, name: 'About' , meta: {scrollToTop: true, showFooter: false, title: 'About - Gltrbox Jewellery Marketplace', description: 'Discover a jewellery marketplace showcasing an exclusive selection of high-street retail jewellers in the UK, offering a range of modern and vintage jewellery to suit any budget.', image: 'https://gltrbox.co.uk/images/jewellery-marketplace-about-us.png', og: {title: 'About - Gltrbox Jewellery Marketplace', description: 'Discover a jewellery marketplace showcasing an exclusive selection of high-street retail jewellers in the UK, offering a range of modern and vintage jewellery to suit any budget.', image: 'https://gltrbox.co.uk/images/jewellery-marketplace-about-us.png', type: 'website', locale: 'en_GB', robots: 'index,follow', author: ''}, twitter: {title: '', image: 'https://gltrbox.co.uk/images/jewellery-marketplace-about-us.png', creator: '@gltrbox'}}},
        { path: '/contact', component: Contact, name: 'Contact' , meta: {scrollToTop: true, showFooter: true, title: 'Contact - Gltrbox Jewellery Marketplace', description: '', image: '', og: {title: '', description: '', image: '', type: 'website', locale: 'en_GB', robots: 'index,follow', author: ''}, twitter: {title: '', image: '', creator: '@gltrbox'} }},
        { path: '/categories', component: Categories, name: 'Categories' , meta: {scrollToTop: true, showFooter: true, title: 'Jewellery Categories - Gltrbox Jewellery Marketplace', description: '', image: '', og: {title: 'Jewellery Categories - Gltrbox Jewellery Marketplace', description: '', image: '', type: 'website', locale: 'en_GB', robots: 'index,follow', author: ''}, twitter: {title: '', image: '', creator: '@gltrbox'} }},
        { path: '/collections', component: Collections, name: 'Collections' , meta: {scrollToTop: true, showFooter: true, title: 'Jewellery Collections - Gltrbox Jewellery Marketplace', description: '', image: '', og: {title: 'Jewellery Collections - Gltrbox Jewellery Marketplace', description: '', image: '', type: 'website', locale: 'en_GB', robots: 'index,follow', author: ''}, twitter: {title: '', image: '', creator: '@gltrbox'} }},
        { path: '/category', redirect: '/categories' , meta: {scrollToTop: true, showFooter: true, title: 'Jewellery Categories - Gltrbox Jewellery Marketplace', description: '', image: '', og: {title: 'Jewellery Categories - Gltrbox Jewellery Marketplace', description: '', image: '', type: 'website', locale: 'en_GB', robots: 'index,follow', author: ''}, twitter: {title: '', image: '', creator: '@gltrbox'} }},
        { path: '/category/:id/:child?/:subchild?/:subsubchild?/:subsubsubchild?', component: Category, name: 'Category', meta: {scrollToTop: true, showFooter: true, title: '', description: '', image: '', og: {title: '', description: '', image: '', type: 'website', locale: 'en_GB', robots: 'index,follow', author: ''}, twitter: {title: '', image: '', creator: '@gltrbox'} }},
        { path: '/sellers', component: Sellers, name: 'Sellers', meta: {scrollToTop: true, showFooter: true, title: 'Jewellery Brands & Sellers - Gltrbox Jewellery Marketplace', description: '', image: '', og: {title: '', description: '', image: '', type: 'website', locale: 'en_GB', robots: 'index,follow', author: ''}, twitter: {title: '', image: '', creator: '@gltrbox'} }},
        { path: '/services', component: Services, name: 'Services', meta: {scrollToTop: true, showFooter: true, title: 'Jewellery Services - Gltrbox Jewellery Marketplace', description: '', image: '', og: {title: '', description: '', image: '', type: 'website', locale: 'en_GB', robots: 'index,follow', author: ''}, twitter: {title: '', image: '', creator: '@gltrbox'} }},
        { path: '/seller/:id', component: Seller, name: 'Seller', meta: {scrollToTop: true, showFooter: true, title: '', description: '', image: '', og: {title: '', description: '', image: '', type: 'website', locale: 'en_GB', robots: 'index,follow', author: ''}, twitter: {title: '', image: '', creator: '@gltrbox'} }},
        { path: '/seller/:id/profile', component: Seller, name: 'Seller', meta: {scrollToTop: true, showFooter: true, title: '', description: '', image: '', og: {title: '', description: '', image: '', type: 'website', locale: 'en_GB', robots: 'index,follow', author: ''}, twitter: {title: '', image: '', creator: '@gltrbox'} }},
        { path: '/seller/:id/product/:id', component: Product, name: 'Product', meta: {scrollToTop: true, showFooter: true, title: '', description: '', image: '', og: {title: '', description: '', image: '', type: 'website', locale: 'en_GB', robots: 'index,follow', author: ''}, twitter: {title: '', image: '', creator: '@gltrbox'} }},
        { path: '/seller/:id/collection/:id', component: Collection, name: 'Collection', meta: {scrollToTop: true, showFooter: true, title: '', description: '', image: '', og: {title: '', description: '', image: '', type: 'website', locale: 'en_GB', robots: 'index,follow', author: ''}, twitter: {title: '', image: '', creator: '@gltrbox'} }},
        { path: '/seller/:id/service/:id', component: Service, name: 'Service', meta: {scrollToTop: true, showFooter: true, title: '', description: '', image: '', og: {title: '', description: '', image: '', type: 'website', locale: 'en_GB', robots: 'index,follow', author: ''}, twitter: {title: '', image: '', creator: '@gltrbox'} }},
        { path: '/my-account/', component: CustomerAccount, name: 'CustomerAccount', meta: {scrollToTop: true, showFooter: true, title: '', description: '', image: '', og: {title: '', description: '', image: '', type: 'website', locale: 'en_GB', robots: 'index,follow', author: ''}, twitter: {title: '', image: '', creator: '@gltrbox'} }},
        { path: '/my-account/:section', component: CustomerAccount, name: 'CustomerAccount', meta: {scrollToTop: true, showFooter: true, title: '', description: '', image: '', og: {title: '', description: '', image: '', type: 'website', locale: 'en_GB', robots: 'index,follow', author: ''}, twitter: {title: '', image: '', creator: '@gltrbox'} }},
        { path: '/order', component: Order, name: 'Order', meta: {scrollToTop: true, showFooter: true, title: '', description: '', image: '', og: {title: '', description: '', image: '', type: 'website', locale: 'en_GB', robots: 'index,follow', author: ''}, twitter: {title: '', image: '', creator: '@gltrbox'} }},
        { path: '/order/:id', component: Order, name: 'Order', meta: {scrollToTop: true, showFooter: true, title: '', description: '', image: '', og: {title: '', description: '', image: '', type: 'website', locale: 'en_GB', robots: 'index,follow', author: ''}, twitter: {title: '', image: '', creator: '@gltrbox'} }},
        { path: '/terms-and-conditions', component: Terms, name: 'Terms', meta: {scrollToTop: true, showFooter: true, title: '', description: '', image: '', og: {title: '', description: '', image: '', type: 'website', locale: 'en_GB', robots: 'index,follow', author: ''}, twitter: {title: '', image: '', creator: '@gltrbox'} }},
        { path: '/privacy-policy', component: Privacy, name: 'Privacy', meta: {scrollToTop: true, showFooter: true, title: '', description: '', image: '', og: {title: '', description: '', image: '', type: 'website', locale: 'en_GB', robots: 'index,follow', author: ''}, twitter: {title: '', image: '', creator: '@gltrbox'} }},
        { path: '/sell-your-jewellery', component: Sell, name: 'Sell' , meta: {scrollToTop: true, showFooter: true, title: 'Categories - Gltrbox Jewellery Marketplace', description: '', image: '', og: {title: '', description: '', image: '', type: 'website', locale: 'en_GB', robots: 'index,follow', author: ''}, twitter: {title: '', image: '', creator: '@gltrbox'} }},
        { path: '*', component: NotFound, name: 'NotFound', meta: {scrollToTop: true, showFooter: true, title: '', description: '', image: '', og: {title: '', description: '', image: '', type: 'website', locale: 'en_GB', robots: 'index,follow', author: ''}, twitter: {title: '', image: '', creator: '@gltrbox'} }},
        ]
    }
];
 
Vue.use(VueRouter);
//Vue.use(VueMeta)
const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior(to, from, savedPosition) {
    
      return { top: 0, behavior: 'smooth' }
    
  },
});

/*function scrollToTop() {
  window.scrollTo(0, 0)
}
// Add a global beforeEach navigation guard to execute the scrollToTop function for all routes
router.beforeEach((to, from, next) => {
  if (from.meta.scrollToTop) {
    scrollToTop()
  }
  next()
})

// This navigation guard will trigger the scrollToTop function when navigating backwards
router.beforeRouteLeave((to, from, next) => {
  if (from.meta.scrollToTop) {
    scrollToTop()
  }
  next()
})*/

// import style
import 'swiper/swiper-bundle.min.css'

Vue.prototype.$isDev = process.env.MIX_APP_ENV !== "production";
Vue.config.devtools = Vue.prototype.$isDev;
Vue.config.debug = Vue.prototype.$isDev;
Vue.config.silent = !Vue.prototype.$isDev;
import tinymce from "vue-tinymce-editor";
export const EventBus = new Vue();
import { VueReCaptcha } from 'vue-recaptcha-v3';
Vue.use(VueReCaptcha, { siteKey: '6LeQy8IUAAAAABtOrMfUczpCLmxyZ-GSJ4xghvyA' });
Vue.use(VueLuxon, {
    input: {
        zone: "gmt",
        format: "sql"
    },
    output: {
        zone: "local",
        format: {
            year: "numeric",
            month: "long",
            day: "numeric"
        },
        locale: null,
        relative: {
            round: true,
            unit: null
        }
    }
});
Vue.use(BootstrapVue);
//Vue.use(vClickOutside);
//Vue.use(VueMask);
//Vue.use(Vuelidate);
//Vue.use(require("vue-chartist"));
Vue.use(VueSweetalert2);
Vue.use(VueGoogleMaps, {
    load: {
        key: "AIzaSyDYWzwTgxlRT37TtJkYNt2hlT4xpg1GweM",
        libraries: "places,geometry"
    },
    installComponents: true
});
//Vue.component("apexchart", VueApexCharts);
//Vue.component("tinymce", tinymce);
Vue.component(
    "public-component",
    require("./components/public-component").default
);

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */
/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

//import "./public-views";
import Layouts from "./mixins/publiclayouts.mixin";

const publicapp = new Vue({
    el: "#publicapp",
    mixins: [Layouts],
    router,
    i18n
});
