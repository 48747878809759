<script>
import Layout from "../layouts/main";
import ChartData from "./data-saas";
import DateTime from 'luxon/src/datetime.js';
import Interval from 'luxon/src/interval.js';
import simplebar from 'simplebar-vue'
import OrderList from '../components/widgets/admin/list/OrderList'
import EmailList from '../components/widgets/admin/email/EmailList'
import BookingCalendar from '../components/widgets/admin/BookingCalendar';
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import NotificationList from '../components/widgets/admin/notification/NotificationList'
import CompanyCustomerSupportTwilioThreads from "../components/widgets/admin/support/CompanyCustomerSupportTwilioThreads"
/**
 * Dashboard Component
 */
export default {
  components: { Layout, DateTime, Interval, simplebar, OrderList, BookingCalendar, EmailList, Swiper,
    SwiperSlide, NotificationList, CompanyCustomerSupportTwilioThreads },
  directives: {
    swiper: directive
  },
  data() {
    return {
        supportData: '',
        supportDataRaw: '',
        enquiryPage: 1,
        enquiryPages: 1,
        enquiryRows: 1,
        showEmailList: false,
        emailSource: '',
        showNotifications: 'company',
        notification_page: 1,
        unotification_page: 1,
        chartDisplay: 'by-date',
        company_notifications: [],
        notifications: [],
      swiperOptions: {
          slidesPerView: 4,
          spaceBetween: 15,
          autoplay: true,
          loop: false,
          loopFillGroupWithBlank: false,
          pagination: {
            el: '.swiper-pagination',
            clickable: true,
          },
          breakpoints: {
          0: {
            slidesPerView: 1,
            spaceBetween: 15
          },
          // when window width is >= 320px
          420: {
            slidesPerView: 1,
            spaceBetween: 15
          },
          // when window width is >= 480px
          567: {
            slidesPerView: 2,
            spaceBetween: 15
          },
          // when window width is >= 640px
          991: {
            slidesPerView: 3,
            spaceBetween: 15
          },
          1660: {
            slidesPerView: 4,
            spaceBetween: 15
          }
        },
          // Some Swiper option/callback...
        },
        reportswiperOptions: {
          slidesPerView: 3,
          spaceBetween: 20,
          autoplay: true,
          loop: true,
          loopFillGroupWithBlank: false,
          pagination: {
            el: '.swiper-pagination',
            clickable: true,
          },
          breakpoints: {
          0: {
            slidesPerView: 1,
            spaceBetween: 15
          },
          // when window width is >= 320px
          420: {
            slidesPerView: 1,
            spaceBetween: 15
          },
          // when window width is >= 480px
          567: {
            slidesPerView: 2,
            spaceBetween: 15
          },
          // when window width is >= 640px
          991: {
            slidesPerView: 3,
            spaceBetween: 20
          },
          1660: {
            slidesPerView: 3,
            spaceBetween: 20
          }
        },
          // Some Swiper option/callback...
        },
      storageurl: 'https://'+this.$attrs.company.nickname+'.s3.eu-west-2.amazonaws.com',
      title: "Dashboard",
      showModal: false,
      loadingCalendar: true,
      orderDataLoading: true,
      latestorders: '',
      topproductsdata: '',
      topsellersdata: '',
      toplistingsdata: '',
      dashdata: '',
      orderdata: '',
      orderdatabydate: '',
      ordersstartdate: '',
      ordersenddate: '',
      orderprevioustotal: 0,
      orderstrade_active: '',
      orderspublic_active: '',
      orderscurrentPage: 1,
      ordersactive: '',
      donutChart: {
            series: [0, 0, 0, 0, 0],
            chartOptions: {
                chart: {
                    type: "donut",
                    height: 240
                },
                labels: ["My Website", "Point of Sale", "Direct Input", "Public Market", "Trade Market"],
                colors: ["rgba(252, 184, 163, 0.4)", "#FEA100", "#f46a6a", "#5E3BB0", "#2a307d"],
                legend: {
                    position: 'bottom',
                    show:false
                },
                responsive: [{
                  breakpoint: 480,
                  options: {
                    chart: {
                      width: 200
                    },
                    legend: {
                      show: false
                    }
                  }
                }],
                plotOptions: {
                    pie: {
                        donut: {
                            size: "70%"
                        }
                    }
                }
            }
        },
      lineChart:{
            series: [
                {
                    name: "Earrnings",
                    type: 'area',
                    data: []
                },
                {
                    name: "OrderValue",
                    type: 'column',
                    data: []
                }
            ],
            chartOptions: {
                chart: {
                    height: 320,
                    type: "line",
                    toolbar: {
                        show: false
                    },
                    zoom: {
                        enabled: true
                    },
                    dropShadow: {
                        enabled: true,
                        color: "#000",
                        top: 18,
                        left: 7,
                        blur: 8,
                        opacity: 0.2
                    }
                },
                labels: [],
                dataLabels: {
                    enabled: false
                },
                colors: ["rgba(94, 59, 176, 0.25)", "rgba(94, 59, 176, 1)"],

                stroke: {
                    curve: "smooth",
                    width: 3
                },
                xaxis: {
                    type: "datetime",
                    max: "",
                    min: "",
                    labels: {
                        show: true
                    },
                    categories: [],
            
                },
                yaxis: [{
                    title: {
                        text: "Total Earnings",
                        style: {
                          color: "#00E396"
                        }
                      },
                      labels: {
                        formatter: function (value) {
                          return value.toFixed(2);
                        }
                      },
                },
                {
                    seriesName: "OrderValue",
                    opposite: true,
                    title: {
                        text: "Order Value",
                        style: {
                          color: "#00E396"
                        }
                      },
                    labels: {
                        formatter: function (value) {
                          return value.toFixed(2);
                        }
                      },
                }
                ]
            }
        },
      ChartData
    };
  },
  created(){
    window.Echo.channel('test-channel').listen('.NewMessage', (e) => {
        console.log(e);
    });
  },
  methods:{

    filterNotifications(params){
        this.active_filter = params;
      this.$attrs.id = '';
      this.getSupportTickets(params);
        /*if(params !== ''){
        this.emailData = this.emailDataRaw.filter(item => item.data.source == params);
        this.paginatedEmailData = this.emailData.slice(
                  0,
                  this.perPage
                );
        }else{
            this.emailData = this.emailDataRaw;
            this.onPageChange();
        }*/
    },
    getSupportTickets(enquirytype){
      //this.loadingMessages = true;
      ///this.emailData = '';
        axios.get('/api/company/communication/support-threads?type='+enquirytype, {headers : {
            'Authorization' : 'Bearer '+this.$attrs.user.api_token
        }}).then(response => {
            this.supportData = response.data[0];
            this.supportDataRaw = response.data[0];
       
        /*if(enquirytype == ''){
          this.emailData.reverse();
        }*/
        let self = this;
        setTimeout(function(){
          self.loadingMessages = false;
        }, 600);
          
        
        }).catch(error => {

        });
    },
    markAsRead(id, source, isread){
        axios.post('/api/company/communication/support-thread/'+id[0]+'/read', {from: id[1], read: id[2]}, {headers : {
            'Authorization' : 'Bearer '+this.$attrs.user.api_token
        }}).then(response => {
             this.getSupportTickets(this.active_filter);
        }).catch(error => {

        });
    },
    markInDispute(id, source, isread){
      axios.post('/api/company/communication/support-thread/'+id[0]+'/indispute', {from: id[1], read: id[2]}, {headers : {
        'Authorization' : 'Bearer '+this.$attrs.user.api_token
      }}).then(response => {
         this.getSupportTickets(this.active_filter);
      }).catch(error => {

      });
    },
    markComplete(id, source, isread){
      axios.post('/api/company/communication/support-thread/'+id[0]+'/complete', {from: id[1], read: id[2]}, {headers : {
        'Authorization' : 'Bearer '+this.$attrs.user.api_token
      }}).then(response => {
         this.getSupportTickets(this.active_filter);
      }).catch(error => {

      });
    },
    markArchived(id, source, isread){
      axios.post('/api/company/communication/support-thread/'+id[0]+'/archived', {from: id[1], read: id[2]}, {headers : {
        'Authorization' : 'Bearer '+this.$attrs.user.api_token
      }}).then(response => {
         this.getSupportTickets(this.active_filter);
      }).catch(error => {

      });
    },
    updateEnquiryPagination(data){
        this.enquiryPage = data[0];
        this.enquiryPages = data[2];
        this.enquiryRows = data[1];
    },
   
    getCompanyNotifications(){
        axios.get('/api/company/dashboard/company-notifications?page='+this.notification_page, {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
            this.company_notifications = response.data;
        }).catch(error => {

        });
    },
    getNotifications(){
        axios.get('/api/company/dashboard/user-notifications?page='+this.notification_page, {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
            this.notifications = response.data;
        }).catch(error => {

        });
    },
    getDashboardData(){
        axios.get('/api/company/dashboard', {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
            this.dashdata = response.data;
        }).catch(error => {

        });
    },
    getOrderData(){
        this.orderDataLoading = true;
        axios.get('/api/company/dashboard/orders?startdate='+this.ordersstartdate+'&enddate='+this.ordersenddate, {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
            this.orderdata = response.data[0];
            this.orderprevioustotal = response.data[1];
            this.orderdatabydate = response.data[2];
            if(this.chartDisplay == 'by-order'){
                this.compileOrderChart();
            }else if(this.chartDisplay == 'by-date'){
                this.compileChartByDate();
            }
          
        }).catch(error => {

        });
    },
    getTopProductsData(){
        this.orderDataLoading = true;
        axios.get('/api/company/dashboard/products', {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
            this.topproductsdata = response.data;
            //this.compileOrderChart();
          
        }).catch(error => {

        });
    },
    getTopSellersData(){
        this.orderDataLoading = true;
        axios.get('/api/company/dashboard/top-sellers', {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
            this.topsellersdata = response.data;
            //this.compileOrderChart();
          
        }).catch(error => {

        });
    },
    getTopListingsData(){
        this.orderDataLoading = true;
        axios.get('/api/company/dashboard/top-listings', {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
            this.toplistingsdata = response.data;
            //this.compileOrderChart();
          
        }).catch(error => {

        });
    },
    getLatestOrdersData(){
      axios.get('/api/company/orders?page='+this.orderscurrentPage+'&limit=10&sortby=created_at&order=desc&search=&trade_active='+this.orderstrade_active+'&public_active='+this.orderspublic_active+'&active='+this.ordersactive, {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
        console.log(response);
        this.latestorders = response.data;
      });
    },
    getBookingsData(){
        this.loadingCalendar = true;
        let self = this;
             setTimeout(() => {
              self.loadingCalendar = false;
            }, 500);
    },
    compileOrderChart(){
        this.orderDataLoading = true;
        this.lineChart.series[0].data = [];
        this.lineChart.series[1].data = [];
        this.lineChart.chartOptions.labels = [];
        this.lineChart.chartOptions.xaxis.type = "category";
        this.lineChart.chartOptions.xaxis.categories = [];
        this.donutChart.series[0] = 0.00;
        this.donutChart.series[1] = 0.00;
        this.donutChart.series[4] = 0.00;
        this.donutChart.series[3] = 0.00;
        for(var i = 0; i < this.orderdata.length; i++){
           if(i == 0){
            this.lineChart.series[0].data.push(parseFloat(this.orderdata[i].total_order_inc));
            }else{
               this.lineChart.series[0].data.push((parseFloat(this.lineChart.series[0].data[i-1]) + parseFloat(this.orderdata[i].total_order_inc) ) ) ; 
            }
            if(this.orderdata[i].website_orders.length > 0){
                this.donutChart.series[0] += parseFloat(this.orderdata[i].total_order_inc);
            }
            if(this.orderdata[i].pos_orders.length > 0){
                this.donutChart.series[1] += parseFloat(this.orderdata[i].total_order_inc);
            }
            if(this.orderdata[i].trade_orders.length > 0){
                this.donutChart.series[4] += parseFloat(this.orderdata[i].total_order_inc);
            }
            if(this.orderdata[i].customer_orders.length > 0){
                this.donutChart.series[3] += parseFloat(this.orderdata[i].total_order_inc);
            }

            this.lineChart.series[1].data.push(parseFloat(this.orderdata[i].total_order_inc));
            this.lineChart.chartOptions.xaxis.labels.show = false;
            this.lineChart.chartOptions.labels.push(this.orderdata[i].reference);
            //var date = {input: { format: "server"}};
            //this.lineChart.chartOptions.labels.push(this.$luxon(this.orderdata[i].created_at, date));
            //this.lineChart.chartOptions.xaxis.categories.push(this.$luxon(this.orderdata[i].created_at, date));
        }
          let self = this;
             setTimeout(() => {
              self.orderDataLoading = false;
            }, 1500);
    },
    compileChartByDate(){
    this.orderDataLoading = true;
    this.lineChart.series[0].data = [];
    this.lineChart.series[1].data = [];
    this.lineChart.chartOptions.xaxis.type = "datetime";
    this.lineChart.chartOptions.labels = [];
    this.lineChart.chartOptions.xaxis.categories = [];
    this.lineChart.chartOptions.xaxis.labels.show = true;
    this.lineChart.chartOptions.xaxis.min = this.ordersstartdate;
    this.lineChart.chartOptions.xaxis.max = this.ordersenddate;
    this.donutChart.series[0] = 0.00;
    this.donutChart.series[1] = 0.00;
    this.donutChart.series[4] = 0.00;
    this.donutChart.series[3] = 0.00;
    var keys = Object.keys(this.orderdatabydate);
    this.lineChart.chartOptions.labels = keys;
    //this.lineChart.chartOptions.xaxis.categories = keys;
    console.log(keys);
    for(var date in this.orderdatabydate){
        console.log(date);
            if(this.lineChart.series[0].data.length == 0){
                this.lineChart.series[0].data[0] = parseFloat(this.orderdatabydate[''+date+'']["orders_total"]);
            }else{
                this.lineChart.series[0].data.push(parseFloat(this.orderdatabydate[date]["orders_total"]) + parseFloat(this.lineChart.series[0].data[this.lineChart.series[0].data.length -1]));
            }
            
            if('website_orders_total' in this.orderdatabydate[date]){
                this.donutChart.series[0] += parseFloat(this.orderdatabydate[date]['website_orders_total']);
            }
            if('pos_orders_total' in this.orderdatabydate[date]){
                this.donutChart.series[1] += parseFloat(this.orderdatabydate[date]['pos_orders_total']);
            }
            if('trade_orders_total' in this.orderdatabydate[date]){
                this.donutChart.series[4] += parseFloat(this.orderdatabydate[date]['trade_orders_total']);
            }
            if('customer_orders_total' in this.orderdatabydate[date]){
                this.donutChart.series[3] += parseFloat(this.orderdatabydate[date]['customer_orders_total']);
            }

            this.lineChart.series[1].data.push(this.orderdatabydate[date]["orders_total"]);
    }

    let self = this;
             setTimeout(() => {
     self.orderDataLoading = false;
    }, 1500);
  }
  },
   mounted() {
    this.ordersstartdate = DateTime.local().minus({months: 1}).toISODate();
    this.ordersenddate = DateTime.local().toISODate();
    this.getDashboardData();
    this.getOrderData();
    this.getTopProductsData();
    this.getLatestOrdersData();
    this.getTopSellersData();
    this.getTopListingsData();
    this.getCompanyNotifications();
    setTimeout(() => {
      this.showModal = true;
    }, 1500);
  },
  computed: {
    swiper() {
        return this.$refs.reportSwiper.$swiper
      },
      companyCurrency(){
        if(this.$attrs.company.currency !== null){
            return this.$attrs.company.currency.symbol;
        }else{
            return '£';
        }
      }
  }
};
</script>

<template>
<Layout  :nickname="$attrs.nickname" :user="$attrs.user" :menuItems="$attrs.menuItems">
    <!--<PageHeader :title="title" :items="items" />-->
    <div class="row">
        <div class="col-xl-12">
            <div class="card border-radius-1em">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-6 d-flex">
                            <div class="media">
                                <div class="d-flex my-auto me-3">

                                    <img v-if="$attrs.user.avatar !== null" :src="$attrs.user.avatar.includes('.s3.eu-west-2.amazonaws.com') ? $attrs.user.avatar : 'https://' + $attrs.company.nickname + '.s3.eu-west-2.amazonaws.com' + $attrs.user.avatar" alt="" class="avatar-md rounded-circle img-thumbnail">
                                    <img v-else-if="$attrs.company.logo !== null" :src="$attrs.company.logo.includes('.s3.eu-west-2.amazonaws.com') ? $attrs.company.logo : 'https://' + $attrs.company.nickname + '.s3.eu-west-2.amazonaws.com' + $attrs.company.logo" alt="" class="avatar-md rounded-circle img-thumbnail">
                                    <span v-else class="border-radius-1em avatar-md bg-light p-3 px-4 mr-2 d-flex">
                                        <h4 class="my-auto mx-auto text-primary">{{$attrs.user.name.slice(0, 1)}}</h4>
                                    </span>
                                    
                                    
                                </div>
                                <div class="media-body align-self-center">
                                    <div class="text-muted">
                                        <p class="mb-1">Welcome to {{$attrs.company.name}}</p>
                                        <h5 class="mb-1">{{$attrs.user.name}} {{$attrs.user.lastname}}</h5>
                                        <p class="mb-0" v-if="$attrs.isowner == 0">{{$attrs.user.jobtitle}}</p>
                                        <p class="mb-0" v-else>Owner</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                       

                        <div class="col-md-6 d-lg-block">
                            <div class="d-flex mt-2"> 
                                <h6 class="text-muted text-uppercase mt-auto ml-auto mr-3 d-none d-lg-block">Quick Access</h6>
                                <a class="btn btn-light btn-lg d-flex avatar-md border-radius-1em p-0" href="https://gltrbox.co.uk" target="_blank">
                                    <img src="/images/logo-square-flat.png" alt="Public Market">
                                </a>
                                <a class="btn btn-light btn-lg d-flex avatar-md border-radius-1em ml-2 p-0" href="/trade/marketplace">
                                    <img src="/images/logo-trade-flat.png" alt="Trade Market">
                                </a>
                                <a class="btn btn-light btn-lg d-flex avatar-md border-radius-1em ml-2 p-0" href="/sell/pos">
                                    <img src="/images/logo-pos-flat.png" alt="ePOS">
                                </a>
                                <a class="btn btn-light btn-lg d-flex avatar-md border-radius-1em ml-2 p-0" href="/web/pages">
                                    <img src="/images/logo-web-flat.png" alt="Website">
                                </a>
                               
                                 
                            </div>
                            <!--<div class="clearfix my-3">
                            
                                    <button class="btn btn-light btn-rounded dropdown-toggle btn-block mb-2" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i class="bx bxs-cog align-middle mr-1"></i> View Profile
                                    </button>
                          
                                
                                  
                                    <b-dropdown id="dropdown-1" variant="primary" block text="More Actions" toggle-class="btn-primary btn-rounded">
                                        <b-dropdown-item>First Action</b-dropdown-item>
                                        <b-dropdown-item>Second Action</b-dropdown-item>
                                        <b-dropdown-item>Third Action</b-dropdown-item>
                                        <b-dropdown-divider></b-dropdown-divider>
                                        <b-dropdown-item active>Active action</b-dropdown-item>
                                        <b-dropdown-item disabled>Disabled action</b-dropdown-item>
                                      </b-dropdown>
                           
                            </div>-->
                        </div>
                    </div>
                    <!-- end row -->
                </div>
            </div>
        </div>
        <div class="col-lg-12 col-xl-3">
               <b-card class="border-radius-1em">
                            <h5 class="card-title">What do you want to do today?</h5>
                            <p>
                                Swipe to select a popular activity and get started!
                            </p>
                                <i class="bx bx-right-arrow-alt font-size-48 text-primary d-none d-xl-block">
                                </i>
                                <i class="bx bx-down-arrow-alt font-size-24 text-primary d-block d-xl-none">
                                </i>
                        </b-card>
            
        </div>
          <div class="col-lg-12 col-xl-9 overflow-hidden">
                <swiper ref="imgGallerySwiper" :options="swiperOptions" @click-slide="imageSelected" style="cursor: grab">
                    
                    <swiper-slide class="h-100" >
                        <b-card class="border-radius-1em">
                            <h5 class="card-title"><i class="bx bx-purchase-tag-alt text-primary"></i> Manage Your Products</h5>
                            <p>
                                Manage your stock and review your product stats
                            </p>
                            <b-button variant="primary" target="_blank" class="btn btn-primary btn-rounded btn-sm float-right mt-1" href="/stock/products">
                                    
                                    <span>
                                      Manage Products
                                    </span>
                                   
                                </b-button>
                        </b-card>
                    </swiper-slide>
                    <swiper-slide class="h-100" >
                        <b-card class="border-radius-1em">
                            <h5 class="card-title"><i class="bx bx-receipt text-primary"></i> View Your Orders</h5>
                            <p>
                                View your website, ePOS and marketplace orders
                            </p>
                            <b-button variant="primary" target="_blank" class="btn btn-primary btn-rounded btn-sm float-right mt-1"  href="/order/list">
                                    
                                    <span>
                                        View Orders
                                    </span>
                                   
                                </b-button>
                        </b-card>
                    </swiper-slide>
                    <swiper-slide class="h-100" >
                        <b-card class="border-radius-1em">
                            <h5 class="card-title"><i class="bx bx-mail-send text-primary"></i> Read Customer Enquiries</h5>
                            <p>
                                Read messages from potential new customers
                            </p>
                            <b-button variant="primary" target="_blank" class="btn btn-primary btn-rounded btn-sm float-right mt-1" href="/customer/list">
                                    
                                    <span>
                                      Make New Customers
                                    </span>
                                    
                                </b-button>
                        </b-card>
                    </swiper-slide>
                     <swiper-slide class="h-100" >
                        <b-card class="border-radius-1em">
                            <h5 class="card-title"><i class="bx bx bx-support text-primary"></i> Customer Support Center</h5>
                            <p>
                                Respond to customer support messages from existing customers
                            </p>
                            <b-button variant="primary" target="_blank" class="btn btn-primary btn-rounded btn-sm float-right mt-1" href="/communicate/customer-service">
                                    
                                    <span>
                                        Visit Support
                                    </span>
                                   
                                </b-button>
                        </b-card>
                    </swiper-slide>
                    <swiper-slide class="h-100" >
                        <b-card class="border-radius-1em">
                            <h5 class="card-title"><i class="bx bx bx-globe text-primary"></i> Build Your Website</h5>
                            <p>
                                Create your ecommerce store and promote your own branded shopping experience
                            </p>
                            <b-button variant="primary" target="_blank" class="btn btn-primary btn-rounded btn-sm float-right mt-1" href="/web/pages">
                                    
                                    <span>
                                        Go to Web Pages
                                    </span>
                                   
                                </b-button>
                        </b-card>
                    </swiper-slide>
                    <swiper-slide class="h-100" >
                        <b-card class="border-radius-1em">
                            <h5 class="card-title"><i class="bx bx bx-store text-primary"></i>  Sell In-Store</h5>
                            <p>
                                Sell to customers face-to-face
                            </p>
                            <b-button variant="primary" target="_blank" class="btn btn-primary btn-rounded btn-sm float-right mt-1" href="/sell/pos">
                                    
                                    <span>
                                        Sign-in to ePOS
                                    </span>
                                   
                                </b-button>
                        </b-card>
                    </swiper-slide>
                     <swiper-slide class="h-100" >
                        <b-card class="border-radius-1em">
                            <h5 class="card-title"><i class="bx bx bx-shopping-bag text-primary"></i> Buy New Stock</h5>
                            <p>
                                Buy and sell with the trade
                            </p>
                            <b-button variant="primary" target="_blank" class="btn btn-primary btn-rounded btn-sm float-right mt-1" href="/trade/marketplace">
                                    
                                    <span>
                                        Visit Trade Market
                                    </span>
                                   
                                </b-button>
                        </b-card>
                    </swiper-slide>
                    <swiper-slide class="h-100" >
                        <b-card class="border-radius-1em">
                            <h5 class="card-title"><i class="bx bx bx-shopping-bag text-primary"></i> View Public Profile</h5>
                            <p>
                                Sell to consumers via the public marketplace
                            </p>
                            <b-button variant="primary" target="_blank" class="btn btn-primary btn-rounded btn-sm float-right mt-1" href="https://gltrbox.co.uk">
                                    
                                    <span>
                                        Visit Public Marketplace
                                    </span>
                                   
                                </b-button>
                        </b-card>
                    </swiper-slide>
                </swiper>
        </div>
    </div>
    <!-- end row -->
    <div class="row">
        <div class="col-xl-12">
            <b-card class="border-radius-1em">
            <b-tabs pills vertical>
                <b-tab>
                   <template #title>
                     <i class="bx bx-bell bx-tada"></i> Notifications
                   </template>
                  
                        <b-tabs pills content-class="pt-3">
                            <b-tab @click="notification_page = 1, showNotifications = 'company', getCompanyNotifications()">
                                 <template #title>
                                 <i class="bx bx-buildings"></i> Company Notifications
                               </template>
                                <b-card class="border-radius-1em border shadow-none">
                                    <div v-if="'data' in company_notifications && company_notifications.data.length !== 0">
                                    <NotificationList  :user="$attrs.user" :company="$attrs.company" :notifications="company_notifications.data">
                                        
                                    </NotificationList>
                                    <b-pagination align="right"
                                          v-model="notification_page"
                                          :total-rows="company_notifications.total"
                                          :per-page="20"
                                          @input="getCompanyNotifications()"
                                            class="ml-auto mt-2"
                                        ></b-pagination>
                                    </div>
                                    <div class="mx-auto my-2" v-else>
                                     <NothingHere :addNew="false"></NothingHere> 
                                    </div>
                                </b-card>
                            </b-tab>
                            <b-tab @click="notification_page = 1, showNotifications = 'user', getNotifications()">
                                <template #title>
                                 <i class="bx bx-user"></i> Your Notifications
                               </template>
                               <b-card class="border-radius-1em border shadow-none">
                                <div v-if="'data' in notifications && notifications.data.length !== 0">
                                    <NotificationList  :user="$attrs.user" :company="$attrs.company" :notifications="notifications.data">
                                        
                                    </NotificationList>
                                    <b-pagination
                                        align="right"
                                          v-model="notification_page"
                                          :total-rows="notifications.total"
                                          :per-page="20"
                                          @input="getNotifications()"
                                                class="ml-auto mt-2"
                                        ></b-pagination>
                                    </div>
                                <div class="mx-auto my-2" v-else >
                                     <NothingHere :addNew="false"></NothingHere> 
                                    </div>
                               </b-card>
                                
                                    
                            </b-tab>
                            
                            <template #tabs-end class="ml-auto" >
                                <div class="ml-auto">
                              <b-pagination align="right" v-if="('data' in company_notifications && company_notifications.data.length !== 0) && showNotifications == 'company'"
                                          v-model="notification_page"
                                          :total-rows="company_notifications.total"
                                          :per-page="20"
                                          @input="getCompanyNotifications()"
                                        ></b-pagination>
                                        <b-pagination  v-if="('data' in notifications && notifications.data.length !== 0) && showNotifications == 'user'"
                                          v-model="notification_page"
                                          :total-rows="notifications.total"
                                          :per-page="20"
                                          @input="getCompanyNotifications()"
                                        ></b-pagination>
                                </div>
                            </template>
                        </b-tabs>
                   
                </b-tab>
                <b-tab>
                   <template #title>
                       <i class="bx bx-receipt"></i> Orders
                   </template>
                   <b-card class="border-radius-1em border shadow-none">
                    <div v-if="'data' in latestorders && latestorders.data.length > 0">
                    <OrderList :orderData="latestorders" :currencies="$attrs.currencies">
                    </OrderList>
                    <div class="d-flex">
                    <div class="mr-auto my-auto">
                        Page {{latestorders.current_page}} of {{latestorders.last_page}}
                    </div>
                    <b-pagination align="right" v-if="'data' in latestorders && latestorders.total > 10" v-model="orderscurrentPage"
                          :total-rows="latestorders.total"
                          :per-page="latestorders.per_page"
                          @input="getLatestOrdersData()"
                           >
                    </b-pagination>
                    </div>
                </div>

                    <div class="mx-auto my-2" v-else>
                                     <NothingHere :addNew="false"></NothingHere> 
                                    </div>
                   </b-card>
                </b-tab>
                <b-tab @click="getBookingsData()">
                    <template #title>
                       <i class="bx bx-calendar"></i> Bookings
                    </template>
                    <b-card class="border-radius-1em border shadow-none">
                        <b-row>
                            <b-col md="12">
                               <b-overlay v-if="loadingCalendar == true" :show="loadingCalendar" style="min-height: 500px;"

                               >
                                </b-overlay>
                               <BookingList>
                                </BookingList>

                                <BookingCalendar v-if="loadingCalendar == false" :initialView="'dayGridMonth'" :showControls="true"></BookingCalendar>
                            </b-col>
                        </b-row>
                   </b-card>
                </b-tab>
                <b-tab>
                    <template #title>
                    <i class="bx bx-mail-send"></i> Enquiries
                    </template>
                    <b-row>
                       


                        <b-col>
                               <b-overlay :show="showEmailList">
                               
                                    <EmailList @pagination-updated="updateEnquiryPagination" v-if="!showEmailList" ref="enquiries" :user="$attrs.user" :showSidebar="false" :showToolbar="false" :emailsource="emailSource" ></EmailList>
                            </b-overlay>
                        </b-col>
                    </b-row>
                    <!--<b-tabs pills content-class="pt-3">
                        <b-tab>
                        <template #title>
                                 <i class="bx bx-mail-send"></i> All Enquiries
                               </template>
                              
                        <b-card class="border-radius-1em border shadow-none">
                            <EmailList @pagination-updated="updateEnquiryPagination" ref="all-enquiries" :user="$attrs.user" :showSidebar="false" :showToolbar="false" :emailsource="emailSource" ></EmailList>
                       </b-card>
                        </b-tab>
                        <b-tab @click="reloadEnquiries('public')">
                        <template #title>
                                 <i class="bx bx-store"></i> Public
                               </template>
                            <b-overlay :show="showEmailList">
                                <b-card  class="border-radius-1em border shadow-none">
                                    <EmailList @pagination-updated="updateEnquiryPagination" v-if="!showEmailList" ref="public-enquiries" :user="$attrs.user" :showSidebar="false" :showToolbar="false" :emailsource="emailSource" ></EmailList>
                               </b-card>
                            </b-overlay>
                        </b-tab>
                        <b-tab @click="reloadEnquiries('trade')">
                        <template #title>
                                 <i class="bx bx-buildings"></i> Trade
                               </template>
                              <b-overlay :show="showEmailList">
                        <b-card class="border-radius-1em border shadow-none">
                            <EmailList @pagination-updated="updateEnquiryPagination" v-if="!showEmailList" ref="trade-enquiries" :user="$attrs.user" :showSidebar="false" :showToolbar="false" :emailsource="emailSource" ></EmailList>
                       </b-card>
                   </b-overlay>
                        </b-tab>
                        <b-tab @click="reloadEnquiries('website')">
                        <template #title>
                                 <i class="bx bx-globe"></i> Website

                               </template>
                               <b-overlay :show="showEmailList">
                        <b-card class="border-radius-1em border shadow-none">
                            <EmailList @pagination-updated="updateEnquiryPagination"  v-if="!showEmailList" ref="website-enquiries" :user="$attrs.user" :showSidebar="false" :showToolbar="false" :emailsource="emailSource" ></EmailList>
                       </b-card>
                   </b-overlay>
                        </b-tab>
                        <template #tabs-end>
                          
                        </template>
                    </b-tabs>-->
                </b-tab>
                <b-tab @click="getSupportTickets('')">
                    <template #title>
                    <i class="bx bx-mail-send"></i> Support Tickets
                    </template>
                    <b-card class="border-radius-1em border shadow-none" body-class="p-0">

                        <CompanyCustomerSupportTwilioThreads v-if="supportData !== ''" ref="threads" :thread_list="supportData" :expanded="isExpanded" :view="'list'" @mark-read="markAsRead" @mark-archived="markArchived" @mark-disputed="markInDispute" @mark-complete="markComplete" :user="$attrs.user" :thread_uid="''" :company="$attrs.company" @filter="filterNotifications" :location="'home'"></CompanyCustomerSupportTwilioThreads>

                    <div class="mx-auto my-2" v-else>
                                     <NothingHere :addNew="false"></NothingHere> 
                                    </div>
                    </b-card>
                </b-tab>
            </b-tabs>
             </b-card>
        </div>
    </div>
    
    <div class="row">
        <div class="col-xl-12">
            <swiper ref="reportSwiper" :options="reportswiperOptions" @click-slide="imageSelected" style="cursor: grab">
                <swiper-slide>
                      <div class="card border-radius-1em">
                        <div class="card-body">
                            <div class="d-flex align-items-center mb-3">
                                <div class="avatar-xs mr-3">
                                    <span class="avatar-title rounded-circle bg-soft-primary font-size-18">
                                        <i class="bx bx-copy-alt"></i>
                                    </span>
                                </div>
                                <h5 class="font-size-14 mb-0">Orders</h5>
                            </div>
                            <div class="text-muted mt-4">
                                <h4>{{orderdata.length}} <i class="mdi mdi-chevron-up ml-1 text-success"></i></h4>
                                <div class="d-flex">
                                    <span class="badge badge-soft-success font-size-12"> + 0.2% </span> <span class="ml-2 text-truncate">From previous period</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </swiper-slide>
                <swiper-slide>
                        <div class="card border-radius-1em">
                      <div class="card-body">
                            <div class="d-flex align-items-center mb-3">
                                <div class="avatar-xs mr-3">
                                    <span class="avatar-title rounded-circle bg-soft-primary font-size-18">
                                        <i class="bx bx-archive-in"></i>
                                    </span>
                                </div>
                                <h5 class="font-size-14 mb-0">Revenue</h5>
                            </div>
                            <div class="text-muted mt-4">
                                <h4  v-if="(orderDataLoading == false) && (lineChart.series[0].data.length > 0)">{{companyCurrency}}{{(lineChart.series[0].data[lineChart.series[0].data.length - 1]).toFixed(2).toString().replace(/B(?=(d{3})+(?!d))/g, ",")}}</h4>
                                    <h4 v-if="(orderDataLoading == false) && (lineChart.series[0].data.length == 0)">{{companyCurrency}}0.00</h4>
                                <div class="d-flex">
                                    <span class="badge badge-soft-success font-size-12"> + 0.2% </span> <span class="ml-2 text-truncate">From previous period</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </swiper-slide>
                <swiper-slide>
                        <div class="card border-radius-1em">
                      <div class="card-body">
                            <div class="d-flex align-items-center mb-3">
                                <div class="avatar-xs mr-3">
                                    <span class="avatar-title rounded-circle bg-soft-primary font-size-18">
                                        <i class="bx bx-archive-in"></i>
                                    </span>
                                </div>
                                <h5 class="font-size-14 mb-0">Revenue Last 12 Months</h5>
                            </div>
                            <div class="text-muted mt-4">
                                <h4  v-if="(orderDataLoading == false) && (lineChart.series[0].data.length > 0)">{{companyCurrency}}{{(lineChart.series[0].data[lineChart.series[0].data.length - 1]).toFixed(2).toString().replace(/B(?=(d{3})+(?!d))/g, ",")}}</h4>
                                    <h4 v-if="(orderDataLoading == false) && (lineChart.series[0].data.length == 0)">{{companyCurrency}}0.00</h4>
                                <div class="d-flex">
                                    <span class="badge badge-soft-success font-size-12"> + 0.2% </span> <span class="ml-2 text-truncate">From previous period</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </swiper-slide>
                <swiper-slide>
                      <div class="card border-radius-1em">
                        <div class="card-body">
                            <div class="d-flex align-items-center mb-3">
                                <div class="avatar-xs mr-3">
                                    <span class="avatar-title rounded-circle bg-soft-primary font-size-18">
                                        <i class="bx bx-purchase-tag-alt"></i>
                                    </span>
                                </div>
                                <h5 class="font-size-14 mb-0">Average Order Price</h5>
                            </div>
                            <div class="text-muted mt-4">
                                <h4  v-if="(orderDataLoading == false) && (lineChart.series[0].data.length > 0)">{{companyCurrency}}{{(parseFloat(lineChart.series[0].data[lineChart.series[0].data.length - 1]) / parseFloat(orderdata.length)).toFixed(2)}}</h4>
                                    <h4 v-if="(orderDataLoading == false) && (lineChart.series[0].data.length == 0)">{{companyCurrency}}0.00</h4>

                                <div class="d-flex">
                                    <span class="badge badge-soft-warning font-size-12"> 

                                    0% 

                                    </span> <span class="ml-2 text-truncate">From previous period</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </swiper-slide>
                <swiper-slide>
                        <div class="card border-radius-1em">
                      <div class="card-body">
                            <div class="d-flex align-items-center mb-3">
                                <div class="avatar-xs mr-3">
                                    <span class="avatar-title rounded-circle bg-soft-primary font-size-18">
                                        <i class="bx bx-archive-in"></i>
                                    </span>
                                </div>
                                <h5 class="font-size-14 mb-0">Revenue</h5>
                            </div>
                            <div class="text-muted mt-4">
                                <h4  v-if="(orderDataLoading == false) && (lineChart.series[0].data.length > 0)">{{companyCurrency}}{{(lineChart.series[0].data[lineChart.series[0].data.length - 1]).toFixed(2).toString().replace(/B(?=(d{3})+(?!d))/g, ",")}}</h4>
                                    <h4 v-if="(orderDataLoading == false) && (lineChart.series[0].data.length == 0)">{{companyCurrency}}0.00</h4>
                                <div class="d-flex">
                                    <span class="badge badge-soft-success font-size-12"> + 0.2% </span> <span class="ml-2 text-truncate">From previous period</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </swiper-slide>
            </swiper>
            <!--<div class="row">
                <div class="col-sm-4">
                    <div class="card border-radius-1em">
                        <div class="card-body">
                            <div class="d-flex align-items-center mb-3">
                                <div class="avatar-xs mr-3">
                                    <span class="avatar-title rounded-circle bg-soft-primary font-size-18">
                                        <i class="bx bx-copy-alt"></i>
                                    </span>
                                </div>
                                <h5 class="font-size-14 mb-0">Orders</h5>
                            </div>
                            <div class="text-muted mt-4">
                                <h4>{{orderdata.length}} <i class="mdi mdi-chevron-up ml-1 text-success"></i></h4>
                                <div class="d-flex">
                                    <span class="badge badge-soft-success font-size-12"> + 0.2% </span> <span class="ml-2 text-truncate">From previous period</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-sm-4">
                    <div class="card border-radius-1em">
                        <div class="card-body">
                            <div class="d-flex align-items-center mb-3">
                                <div class="avatar-xs mr-3">
                                    <span class="avatar-title rounded-circle bg-soft-primary font-size-18">
                                        <i class="bx bx-archive-in"></i>
                                    </span>
                                </div>
                                <h5 class="font-size-14 mb-0">Revenue</h5>
                            </div>
                            <div class="text-muted mt-4">
                                <h4  v-if="(orderDataLoading == false) && (lineChart.series[0].data.length > 0)">{{companyCurrency}}{{(lineChart.series[0].data[lineChart.series[0].data.length - 1]).toFixed(2).toString().replace(/B(?=(d{3})+(?!d))/g, ",")}}</h4>
                                    <h4 v-if="(orderDataLoading == false) && (lineChart.series[0].data.length == 0)">{{companyCurrency}}0.00</h4>
                                <div class="d-flex">
                                    <span class="badge badge-soft-success font-size-12"> + 0.2% </span> <span class="ml-2 text-truncate">From previous period</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-sm-4">
                    <div class="card border-radius-1em">
                        <div class="card-body">
                            <div class="d-flex align-items-center mb-3">
                                <div class="avatar-xs mr-3">
                                    <span class="avatar-title rounded-circle bg-soft-primary font-size-18">
                                        <i class="bx bx-purchase-tag-alt"></i>
                                    </span>
                                </div>
                                <h5 class="font-size-14 mb-0">Average Price</h5>
                            </div>
                            <div class="text-muted mt-4">
                                <h4  v-if="(orderDataLoading == false) && (lineChart.series[0].data.length > 0)">{{companyCurrency}}{{(parseFloat(lineChart.series[0].data[lineChart.series[0].data.length - 1]) / parseFloat(orderdata.length)).toFixed(2)}}</h4>
                                    <h4 v-if="(orderDataLoading == false) && (lineChart.series[0].data.length == 0)">{{companyCurrency}}0.00</h4>

                                <div class="d-flex">
                                    <span class="badge badge-soft-warning font-size-12"> 

                                    0% 

                                    </span> <span class="ml-2 text-truncate">From previous period</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>-->
            <!-- end row -->
        </div>
    </div>

    <div class="row">
        <div class="col-xl-8">
            <div class="row">
        <div class="col-md-12">
            <b-card class="border-radius-1em border shadow-none">
                <b-row>
                    <div class="col-lg-4">
                            
                             <div class="">

                                <h5>Earnings</h5>
                                    <p>From {{ordersstartdate | luxon}} to {{ordersenddate | luxon}}</p>
                                
                            <!--<div class="input-group input-group-md">
                                
                                <b-form-input type="date" v-model="ordersstartdate">
                                </b-form-input>
                
                                <b-form-input type="date" v-model="ordersenddate">
                                </b-form-input>
                                <a class="btn btn-light btn-rounded" @click="getOrderData()">
                                    Update
                                </a>
                            </div>-->
                            <!--<b-form-group label="Chart Display">
                                
                                        <a class="btn btn-primary" @click="chartDisplay = 'by-order', compileOrderChart()"> 
                                            By Order
                                        </a>
                                        <a class="btn btn-primary" @click="chartDisplay = 'by-date', compileChartByDate()"> 
                                            By Date
                                        </a>
                                
                                </b-form-group>-->
                        </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="text-muted">
                                <div class="mb-4">

                                    <h5>This Period</h5>
                                    <!--<p>From {{ordersstartdate | luxon}} to {{ordersenddate | luxon}}</p>-->
                                    <h4  v-if="(orderDataLoading == false) && (lineChart.series[0].data.length > 0)">{{companyCurrency}}{{(lineChart.series[0].data[lineChart.series[0].data.length - 1]).toFixed(2).toString().replace(/B(?=(d{3})+(?!d))/g, ",")}}</h4>
                                    <h4 v-if="(orderDataLoading == false) && (lineChart.series[0].data.length == 0)">{{companyCurrency}}0.00</h4>
                                    <div v-if="orderDataLoading == false"><span class="badge font-size-12 mr-1" :class="{'badge-soft-success' : (((parseFloat(lineChart.series[0].data[lineChart.series[0].data.length - 1]) - parseFloat(orderprevioustotal)) / parseFloat(orderprevioustotal)) * parseFloat(100)).toFixed(2) > 0, 'badge-soft-danger': (((parseFloat(lineChart.series[0].data[lineChart.series[0].data.length - 1]) - parseFloat(orderprevioustotal)) / parseFloat(orderprevioustotal)) * parseFloat(100)).toFixed(2) < 0}"> 
                                    <span v-if="(((parseFloat(lineChart.series[0].data[lineChart.series[0].data.length - 1]) - parseFloat(orderprevioustotal)) / parseFloat(orderprevioustotal)) * parseFloat(100)).toFixed(2) > 0">+</span>{{(((parseFloat(lineChart.series[0].data[lineChart.series[0].data.length - 1]) - parseFloat(orderprevioustotal)) / parseFloat(orderprevioustotal)) * parseFloat(100)).toFixed(2) }} %</span> From previous period</div>
                                     <b-spinner v-else variant="primary" type="grow">
                                     </b-spinner>
                                </div>

                                <!--<div>
                                    <a href="#" class="btn btn-primary waves-effect waves-light btn-sm">View Details <i class="mdi mdi-chevron-right ml-1"></i></a>
                                </div>

                                <div class="mt-4">
                                    <p class="mb-2">Previous Period</p>
                                    <h5 v-if="orderDataLoading == false">{{companyCurrency}}{{orderprevioustotal.toFixed(2)}}</h5>
                                    <b-spinner v-else variant="primary" type="grow">
                                     </b-spinner>
                                </div>-->

                            </div>
                            
                        </div>
                        <div class="col-lg-4">
                            <div class="text-muted">
                                <div class="mb-4">
                                    <h5>Previous Period</h5>
                                    <p> </p>
                                    <h4  v-if="(orderDataLoading == false) && (lineChart.series[0].data.length > 0)">{{companyCurrency}}{{orderprevioustotal.toFixed(2)}}</h4>
                                    <h4 v-if="(orderDataLoading == false) && (lineChart.series[0].data.length == 0)">{{companyCurrency}}0.00</h4>
                                    
                                     <b-spinner v-else-if="orderDataLoading == true" variant="primary" type="grow">
                                     </b-spinner>
                                </div>

                                <!--<div>
                                    <a href="#" class="btn btn-primary waves-effect waves-light btn-sm">View Details <i class="mdi mdi-chevron-right ml-1"></i></a>
                                </div>-->

                               

                            </div>
                          <!--  <div class="">
                                <label>Date Range</label>
                            <div class="input-group input-group-md">
                                
                                <b-form-input type="date" v-model="ordersstartdate">
                                </b-form-input>
                
                                <b-form-input type="date" v-model="ordersenddate">
                                </b-form-input>
                                <a class="btn btn-light btn-rounded" @click="getOrderData()">
                                    Update
                                </a>
                            </div>
                            <b-form-group label="Chart Display">
                                
                                        <a class="btn btn-primary" @click="chartDisplay = 'by-order', compileOrderChart()"> 
                                            By Order
                                        </a>
                                        <a class="btn btn-primary" @click="chartDisplay = 'by-date', compileChartByDate()"> 
                                            By Date
                                        </a>
                                
                                </b-form-group>
                        </div>-->
                        </div>
                </b-row>
            </b-card>
        </div>
    </div>
            <div class="card border-radius-1em">
                <div class="card-body">
                    <div class="row">
                       <!-- <div class="col-lg-4">
                            <div class="text-muted">
                                <div class="mb-4">

                                    <h5>This Period</h5>
                                    <p>From {{ordersstartdate | luxon}} to {{ordersenddate | luxon}}</p>
                                    <h4  v-if="(orderDataLoading == false) && (lineChart.series[0].data.length > 0)">{{companyCurrency}}{{(lineChart.series[0].data[lineChart.series[0].data.length - 1]).toFixed(2).toString().replace(/B(?=(d{3})+(?!d))/g, ",")}}</h4>
                                    <h4 v-if="(orderDataLoading == false) && (lineChart.series[0].data.length == 0)">{{companyCurrency}}0.00</h4>
                                    <div v-if="orderDataLoading == false"><span class="badge font-size-12 mr-1" :class="{'badge-soft-success' : (((parseFloat(lineChart.series[0].data[lineChart.series[0].data.length - 1]) - parseFloat(orderprevioustotal)) / parseFloat(orderprevioustotal)) * parseFloat(100)).toFixed(2) > 0, 'badge-soft-danger': (((parseFloat(lineChart.series[0].data[lineChart.series[0].data.length - 1]) - parseFloat(orderprevioustotal)) / parseFloat(orderprevioustotal)) * parseFloat(100)).toFixed(2) < 0}"> 
                                    <span v-if="(((parseFloat(lineChart.series[0].data[lineChart.series[0].data.length - 1]) - parseFloat(orderprevioustotal)) / parseFloat(orderprevioustotal)) * parseFloat(100)).toFixed(2) > 0">+</span>{{(((parseFloat(lineChart.series[0].data[lineChart.series[0].data.length - 1]) - parseFloat(orderprevioustotal)) / parseFloat(orderprevioustotal)) * parseFloat(100)).toFixed(2) }} %</span> From previous period</div>
                                     <b-spinner v-else variant="primary" type="grow">
                                     </b-spinner>
                                </div>

                                

                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="text-muted">
                                <div class="mb-4">
                                    <h5>Previous Period</h5>
                                    <p> </p>
                                    <h4  v-if="(orderDataLoading == false) && (lineChart.series[0].data.length > 0)">{{companyCurrency}}{{orderprevioustotal.toFixed(2)}}</h4>
                                    <h4 v-if="(orderDataLoading == false) && (lineChart.series[0].data.length == 0)">{{companyCurrency}}0.00</h4>
                                    
                                     <b-spinner v-else-if="orderDataLoading == true" variant="primary" type="grow">
                                     </b-spinner>
                                </div>

                              

                               

                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div>
                                <h5>Chart Options</h5>
                                
                            </div>
                            <div class="">
                                <label>Date Range</label>
                            <div class="input-group input-group-md">
                                
                                <b-form-input type="date" v-model="ordersstartdate">
                                </b-form-input>
                
                                <b-form-input type="date" v-model="ordersenddate">
                                </b-form-input>
                                <a class="btn btn-light btn-rounded" @click="getOrderData()">
                                    Update
                                </a>
                            </div>
                            <b-form-group label="Chart Display">
                                
                                        <a class="btn btn-primary" @click="chartDisplay = 'by-order', compileOrderChart()"> 
                                            By Order
                                        </a>
                                        <a class="btn btn-primary" @click="chartDisplay = 'by-date', compileChartByDate()"> 
                                            By Date
                                        </a>
                                
                                </b-form-group>
                        </div>
                        </div>-->

                        <div class="col-lg-6"> 
                            <div class="input-group input-group-md">
                                
                                <b-form-input type="date" v-model="ordersstartdate">
                                </b-form-input>
                
                                <b-form-input type="date" v-model="ordersenddate">
                                </b-form-input>
                                <a class="btn btn-light btn-rounded" @click="getOrderData()">
                                    Update
                                </a>
                            </div>
                        </div>
                        <div class="col-lg-6 d-flex">
                          
                                
                                        <a class="btn btn-primary ml-auto btn-rounded mr-2" @click="chartDisplay = 'by-order', compileOrderChart()"> 
                                            By Order
                                        </a>
                                        <a class="btn btn-primary btn-rounded" @click="chartDisplay = 'by-date', compileChartByDate()"> 
                                            By Date
                                        </a>
                             
                        </div>
                        <div class="col-lg-12 mt-2">
                            <apexchart v-if="orderDataLoading == false" class="apex-charts" :options="lineChart.chartOptions" :series="lineChart.series" dir="ltr" height="460"/>
                            <b-spinner v-else variant="primary" type="grow">
                            </b-spinner>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-xl-4">
            <div class="card border-radius-1em">
                <div class="card-body">
                    <h4 class="card-title mb-4">Channel Analytics</h4>

                    <div  :style="[orderDataLoading == false ? {'width' : '100%' } : {'width' : '98%' }]">
                        <apexchart v-if="orderDataLoading == false"  class="apex-charts" :options="donutChart.chartOptions" :series="donutChart.series" dir="ltr" height="460" />
                        <b-spinner v-else variant="primary" type="grow">
                            </b-spinner>
                    </div>

                    <div class="text-center text-muted">
                        <div class="row">
                            <div class="col" v-for="(label, index) in donutChart.chartOptions.labels">
                                <div class="mt-4">
                                    <p class="mb-2 text-truncate"><i class="mdi mdi-circle mr-1" :style="{'color': donutChart.chartOptions.colors[index] }"></i> {{label}}</p>
                                    <h5>{{companyCurrency}} {{(donutChart.series[index]).toFixed(2)}}</h5>
                                </div>
                            </div>
                            <!--<div class="col">
                                <div class="mt-4">
                                    <p class="mb-2 text-truncate"><i class="mdi mdi-circle text-success mr-1"></i> Product B</p>
                                    <h5>$ 1,763</h5>
                                </div>
                            </div>
                            <div class="col">
                                <div class="mt-4">
                                    <p class="mb-2 text-truncate"><i class="mdi mdi-circle text-danger mr-1"></i> Product C</p>
                                    <h5>$ 973</h5>
                                </div>
                            </div>-->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- end row -->

    <div class="row">
        <div class="col-xl-4">
            <div class="card border-radius-1em">
                <div class="card-body">
                    <div class="clearfix">
                        <!--<div class="float-right">
                            <div class="input-group input-group-sm">
                                <select class="custom-select custom-select-sm">
                                    <option selected>Jan</option>
                                    <option value="1">Dec</option>
                                    <option value="2">Nov</option>
                                    <option value="3">Oct</option>
                                </select>
                                <div class="input-group-append">
                                    <label class="input-group-text">Month</label>
                                </div>
                            </div>
                        </div>-->
                        <h4 class="card-title mb-4">Top Selling Products</h4>
                    </div>

                    <div v-if="topproductsdata.length > 0" class="text-muted text-center border border-radius-1em pb-4">
                        <div class="w-100 bg-light bg-soft" style="height: 100px">

                        </div>
                        <b-card-body class="card-body pt-0">
                            <div class="row">
                              <div class="col-sm-12 text-center">
                                <div class="avatar-md profile-user-wid mb-2 mx-auto" style="margin-top: -45px !important;">
                        <div v-if="topproductsdata[0].cover_image !== null" >
                        <img v-if="'thumb' in topproductsdata[0].cover_image"  :src="storageurl+topproductsdata[0].cover_image.thumb" class="avatar-md rounded-circle mx-auto">
                         <img v-else-if="'gallery' in topproductsdata[0].cover_image"  :src="storageurl+topproductsdata[0].cover_image.gallery" class="avatar-md rounded-circle mx-auto">
                          <img v-else-if="'src' in topproductsdata[0].cover_image"  :src="storageurl+topproductsdata[0].cover_image.src" class="avatar-md rounded-circle mx-auto">
                        </div>
                        <img v-else src="/images/product-placeholder.png" class="avatar-md rounded-circle mx-auto">
                    </div>
                </div>
            </div>
            <div class="row">
                              <div class="col-sm-12 text-center">
                        <span class="badge badge-primary bg-primary text-white">
                                            {{topproductsdata[0].reference}}
                                        </span>
                        <h5 class="mt-2 mb-0 ">{{topproductsdata[0].name}}</h5>
                        <p class="text-muted text-truncate paragraph-truncate-line2" v-html="topproductsdata[0].short_description"></p>

                        <b-row>
                            <b-col>
                                
                            <h4>{{companyCurrency}}{{(topproductsdata[0].orders_sum_price_inc).toFixed(2)}}</h4>
                            <p class="text-muted mb-1">Order Value</p>
                            </b-col>
                            <b-col>
                                
                                <h4 class="mb-0 mt-1">{{topproductsdata[0].orders_sum_quantity}}</h4>
                                <p class="text-muted mb-1">Sold</p>
                            </b-col>
                        </b-row>
                        
                        <p class="mt-4 mb-0">
                            <a class="btn-sm btn btn-rounded btn-primary" target="_blank" :href="'/stock/product/'+topproductsdata[0].uid">
                                Product Details <i class="bx bx-right-arrow-alt"></i>
                            </a>
                        </p>
                    </div>
                </div>
            </b-card-body>
                    </div>
                     <b-card class="border-radius-1em border shadow-none mb-0" v-else style="min-height: 536px;" body-class="d-flex flex-column justify-content-center align-items-center">
                        <i class="bx bx-user-circle font-size-42 text-muted d-block"></i>
                        <h5 class="text-muted">No Top Products</h5>
                        <p>Import your stock, or add it via your stock list to start selling!</p>

                    </b-card>
                    <simplebar v-if="topproductsdata.length > 0" style="height: 285px; max-height: 285px" class="border border-radius-1em mt-3" >
                    <div class="table-responsive mb-0">
                        <table class="table table-centered mb-0">
                            <thead style="visibility: hidden;">
                                <td>
                                    
                                </td>
                                <td class="w-25">
                                    
                                </td>
                                <td>
                                    
                                </td>
                                <td>
                                    
                                </td>
                            </thead>
                            <tbody>
                                <tr v-for="product in topproductsdata">
                                    <td colspan="1">
                                        <span class="badge badge-primary bg-primary text-white">
                                            {{product.reference}}
                                        </span>
                                        <h5 class="font-size-14 mb-1 paragraph-truncate-line2">{{product.name}}</h5>
                                        <p class="text-muted mb-0 text-truncate"></p>
                                    </td>

                                   
                                    <td colspan="2">
                                        <p class="text-muted mb-1">Sold</p>
                                        <b-progress :max="topproductsdata[0].orders_sum_quantity" :value="product.orders_sum_quantity" >
                                        </b-progress>
                                        <h5 class="mb-0 mt-1">{{product.orders_sum_quantity}}</h5>
                                    </td>
                                    <td colspan="1">
                                        <p class="text-muted mb-1">Sales Value</p>
                                        <h5 class="mb-0">{{companyCurrency}}{{(product.orders_sum_price_inc).toFixed(2)}}</h5>
                                    </td>
                                </tr>
                               
                            </tbody>
                        </table>
                    </div>
                </simplebar>
                </div>
            </div>
        </div>
        <div class="col-xl-4">
            <div class="card border-radius-1em">
                <div class="card-body">
                    <div class="clearfix">
                        <!--<div class="float-right">
                            <div class="input-group input-group-sm">
                                <select class="custom-select custom-select-sm">
                                    <option selected>Jan</option>
                                    <option value="1">Dec</option>
                                    <option value="2">Nov</option>
                                    <option value="3">Oct</option>
                                </select>
                                <div class="input-group-append">
                                    <label class="input-group-text">Month</label>
                                </div>
                            </div>
                        </div>-->
                        <h4 class="card-title mb-4">Top Salespeople</h4>
                    </div>

                    <div class="card text-muted text-center border border-radius-1em pb-4 shadow-none mb-2" v-if="topsellersdata.length > 0">
                        <div class="w-100 bg-light bg-soft" style="height: 100px">

                        </div>
                        <b-card-body class="card-body pt-0">
                            <div class="row">
                              <div class="col-sm-12 text-center">
                                <div class="avatar-md profile-user-wid mb-2 mx-auto" style="margin-top: -45px !important;">
                        <img v-if="topsellersdata[0].user.avatar !== null" :src="storageurl+topsellersdata[0].user.avatar" class="avatar-md rounded-circle mx-auto">
                        <img v-else src="/images/product-placeholder.png" class="avatar-md rounded-circle mx-auto">
                       
                                </div>
                            </div>
                            <div class="col-md-12 text-center">
                                <span class="badge badge-primary bg-light text-warning">
                                            #1 Top Seller <i class="bx bx-star"></i>
                                        </span>
                        <h5 class="mt-2 mb-0">{{topsellersdata[0].user.name}} {{topsellersdata[0].user.lastname}}</h5>
                        <p class="text-muted"> 
                            {{topsellersdata[0].user.email}}
                        </p>
                                 

                                  <b-row>
                            <b-col>
                                
                            <h4>{{companyCurrency}}{{(topsellersdata[0].sales_sum_total_order_inc).toFixed(2)}}</h4>
                            <p class="text-muted mb-1">Order Value</p>
                            </b-col>
                            <b-col>
                                
                                <h4 class="mb-0 mt-1">{{topsellersdata[0].sales_count}}</h4>
                                <p class="text-muted mb-1">Sold</p>
                            </b-col>
                        </b-row>
                        <p class="mt-4 mb-0"><a class="btn-sm btn btn-rounded btn-primary" target="_blank" :href="'/company/user/'+topsellersdata[0].uid">
                                User Profile <i class="bx bx-right-arrow-alt"></i>
                            </a></p>
                            </div>
                        </div>
                    </b-card-body>
                    </div>
                    <b-card class="border-radius-1em border shadow-none mb-0" v-else style="min-height: 536px;" body-class="d-flex flex-column justify-content-center align-items-center">
                        <i class="bx bx-user-circle font-size-42 text-muted d-block"></i>
                        <h5 class="text-muted">No Top Sellers</h5>
                        <p>Start selling directly or via your Point of Sale now!</p>
                    </b-card>
                     <simplebar  v-if="topsellersdata.length > 0" style="height: 285px; max-height: 285px" class="border border-radius-1em mt-3">
                    <div class="table-responsive mb-0">
                        <table class="table table-centered mb-0">
                            <thead style="visibility: hidden;">
                                <td>
                                    
                                </td>
                                <td class="w-25">
                                    
                                </td>
                                <td>
                                    
                                </td>
                                <td>
                                    
                                </td>
                            </thead>
                            <tbody>
                                <tr v-for="product in topsellersdata">
                                    <td valign="middle">
                                        <a :href="'/company/user/'+product.uid">
                                        <h5 class="font-size-14 mb-0">{{product.user.name}} {{product.user.lastname}}</h5>
                                        <p class="text-muted mb-0 text-truncate">{{product.user.email}}</p>
                                        </a>
                                    </td>

                                    <td colspan="2" valign="middle">
                                        <p class="text-muted mb-1">Sold</p>
                                        <b-progress :max="topsellersdata[0].sales_count" :value="product.sales_count" >
                                        </b-progress>
                                        <h5 class="mb-0 mt-1">{{product.sales_count}}</h5>
                                    </td>
                                    
                                    <td>
                                        <p class="text-muted mb-1">Sales Value</p>
                                        <h5 class="mb-0">{{companyCurrency}}{{(product.sales_sum_total_order_inc).toFixed(2)}}</h5>
                                    </td>
                                </tr>
                               
                            </tbody>
                        </table>
                    </div>
                </simplebar>
                </div>
            </div>
        </div>

        <div class="col-xl-4">
            <div class="card border-radius-1em">
                <div class="card-body">
                    <div class="clearfix">
                        <!--<div class="float-right">
                            <div class="input-group input-group-sm">
                                <select class="custom-select custom-select-sm">
                                    <option selected>Jan</option>
                                    <option value="1">Dec</option>
                                    <option value="2">Nov</option>
                                    <option value="3">Oct</option>
                                </select>
                                <div class="input-group-append">
                                    <label class="input-group-text">Month</label>
                                </div>
                            </div>
                        </div>-->
                        <h4 class="card-title mb-4">Top Selling Shops</h4>
                    </div>

                    <b-card class="text-muted text-center border border-radius-1em pb-4 pt-0 px-0 shadow-none"  v-if="toplistingsdata.length > 0" no-body>
                        <div class="">
                          <gmap-map v-if="toplistingsdata[0].gplace_id !== 0" :center="{ lat: parseFloat(toplistingsdata[0].latitude), lng: parseFloat(toplistingsdata[0].longitude) }" :zoom="6" style="height: 100px" class="border-radius-1em">
                          <gmap-marker
                            :position="{ lat: parseFloat(toplistingsdata[0].latitude), lng: parseFloat(toplistingsdata[0].longitude) }"
                            :clickable="true"
                            :draggable="true"
                            @click="center = { lat: parseFloat(toplistingsdata[0].latitude), lng: parseFloat(toplistingsdata[0].longitude) }"
                          ></gmap-marker>
                        </gmap-map>
                        </div>
                        <!--<div class="avatar-md mx-auto profile-user-wid rounded-circle">
                            <img v-if="toplistingsdata[0].logo !== null" :src="storageurl+toplistingsdata[0].logo" class="avatar-md rounded-circle mx-auto">
                            <span v-else class="avatar-md rounded-circle mx-auto"> 
                                <span class="avatar-title">
                                    {{toplistingsdata[0].name.slice(0,1)}}
                                </span>
                            </span>
                        </div>-->
                        <b-card-body class="card-body pt-0">
                            <div class="row">
                              <div class="col-sm-12 pb-1 text-center">
                                <div class="avatar-md profile-user-wid mb-2 mx-auto" style="margin-top: -45px !important;">
                                  
                                  <a href="javascript:;" class="avatar-title avatar-md bg-light text-warning rounded-circle" v-b-tooltip.hover>
                                    <i class="bx bx-star font-size-20"></i>
                                  </a>
                                </div>
                                <h5 class="mb-0 text-truncate">{{toplistingsdata[0].name}}</h5>
                                <p class="text-muted mb-0">
                                  <span v-if="toplistingsdata[0].address_1 !== null">{{toplistingsdata[0].address_1}},</span>
                                  <span v-if="toplistingsdata[0].city !== null">{{toplistingsdata[0].city}},</span>
                                  <span v-if="toplistingsdata[0].county !== null">{{toplistingsdata[0].county.name}},</span>
                                  <span v-if="toplistingsdata[0].postcode !== null">{{toplistingsdata[0].postcode}}</span>
                                </p>
                                <span class="badge badge-light border-radius-1em bg-light text-dark font-size-11 lh-16 my-2 mx-auto" v-if="toplistingsdata[0].listing_type !== null">
                                  {{toplistingsdata[0].listing_type.name}}
                                </span>
                              </div>
                              <div class="col-md-12">
                                <b-row>
                                    <b-col>
                                        
                                    <h4>{{companyCurrency}}{{(toplistingsdata[0].orders_sum_total_order_inc).toFixed(2)}}</h4>
                                    <p class="text-muted mb-1">Order Value</p>
                                    </b-col>
                                    <b-col>
                                        
                                        <h4 class="mb-0 mt-1">{{toplistingsdata[0].orders_count}}</h4>
                                        <p class="text-muted mb-1">Sold</p>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col>
                                 <p class="mt-4 mb-0"><a class="btn-sm btn btn-rounded btn-primary" target="_blank" :href="'/company/shop/'+toplistingsdata[0].uid">
                                Shop Profile <i class="bx bx-right-arrow-alt"></i>
                            </a></p>
                        </b-col>
                    </b-row>
                              </div>
                          </div>
                      </b-card-body>
                    </b-card>
                    <b-card class="border-radius-1em border shadow-none mb-0" v-else style="min-height: 536px;" body-class="d-flex flex-column justify-content-center align-items-center">
                        <i class="bx bx-store font-size-42 text-muted d-block"></i>
                        <h5 class="text-muted">No Top Shops</h5>
                        <p>Add a shop or stock room and start listing products.</p>
                    </b-card>
                     <simplebar  v-if="toplistingsdata.length > 0" style="height: 285px; max-height: 285px"  class="border border-radius-1em mt-3">
                    <div class="table-responsive mb-0">
                        <table class="table table-centered mb-0">
                            <thead style="visibility: hidden;">
                                <td>
                                    
                                </td>
                                <td class="w-25">
                                    
                                </td>
                                <td>
                                    
                                </td>
                                <td>
                                    
                                </td>
                            </thead>
                            <tbody>
                                <tr v-for="product in toplistingsdata">
                                    <td valign="middle" colspan="1">
                                        <h5 class="font-size-14 mb-0">{{product.name}}</h5>
                                        <p class="text-muted mb-0 paragraph-truncate-line2">
                                          <span v-if="product.address_1 !== null">{{product.address_1}},</span>
                                          <span v-if="product.city !== null">{{product.city}},</span>
                                          <span v-if="product.county !== null">{{product.county.name}},</span>
                                          <span v-if="product.postcode !== null">{{product.postcode}}</span>
                                        </p>
                                    </td>

                                    <td colspan="2" valign="middle">
                                        <p class="text-muted mb-1">Sold</p>
                                        <b-progress :max="toplistingsdata[0].orders_count" :value="product.orders_count" >
                                        </b-progress>
                                        <h5 class="mb-0 mt-1">{{product.orders_count}}</h5>
                                    </td>
                                    
                                    <td>
                                        <p class="text-muted mb-1">Sales Value</p>
                                        <h5 class="mb-0">{{companyCurrency}}{{(product.orders_sum_total_order_inc).toFixed(2)}}</h5>
                                    </td>
                                </tr>
                               
                            </tbody>
                        </table>
                    </div>
                </simplebar>
                </div>
            </div>
        </div>

        

    </div>
    <!-- end row -->
</Layout>
</template>

