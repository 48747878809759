<script type="text/javascript">
import FilterPublicProductCategoriesChild from './FilterPublicProductCategoriesChild';
 //import { EventBus } from '../../../../public-app'   
//const EventBus = new Vue();
  export default {
        component:{
            FilterPublicProductCategoriesChild
        },
        name: 'FilterPublicProductCategoriesChild',
        props: {
            childs: Array,
            all_categories: Array,
            productcategories: Array,
            productid: Number,
            selectedcids: Array,
        }, 
        template: '',
         beforeMount(){
        /*axios.get('http://localhost:7080/gemesislabs-framework/public/seller/product/'+this.productid+'/categories').then(response => {
                 console.log(response);
               this.productcategories = response.data;
            })*/
            this.selectedchildids = [];
        },
        mounted(){
            let self = this;
            //self.selectedchildids = self.selectedcids;
            
            EventBus.$on('categories-cleared', function(){
               self.categories = [];
            });
        },
        data: function() {
        return{
            all_categories: [],
            childdata: this.childs,
            childitems: [],
            product_categories: this.productcategories,
            testObject: [],
            product_id: this.productid,
            showDropdown: 'show',
            selectedchildids: [],
            categories: [],
        }
        },

        methods:{
            addToCategory(id){
                if(this.selectedchildids.length == 0){
                    this.selectedchildids[0] = id;
                }else{
                this.selectedchildids.push(id);
                }
                //EventBus.$emit('category-added', id);     
            },
            clearCategories(){
                this.categories = [];
                for(child in this.$refs['childchildcategories']){
                    child.clearCategories();
                }
                EventBus.$emit('categories-cleared');
            },
            categorySelected(id){
                EventBus.$emit('category-select', id);
                /*var exists = this.categories.findIndex(item => item == id);
                  console.log(exists);
                  if(exists == -1){
                    this.categories.push(id);
                  }else{
                                this.categories.splice(exists, 1);
                  }*/
            },
             findIds(id){
                this.testObject = [];
                this.testObject = this.selectedcids.filter(item => item == id);
                    if(this.testObject.length){
                        return "checked";
                    }else{
                        return false;
                    }

                },
        },
        computed: {
        /**checkAll: {
            get: function () {
                return this.all_categories ? this.selectedchildids.length == this.all_categories.length : false;
            },
            set: function (value) {
                var selected = [];
                if (value) {
                    this.all_categories.forEach(function (data) {
                        selected.push(data.id);
                        EventBus.$emit('category-added', data.id);
                    });
                }
                this.selectedchildids = selected;
            }
        },
        checkBoxes: function(){
            this.selectedchildids = this.selectedcids;
        }**/
    }
}
</script>
<template>
    <ul class="tree-child py-0" v-bind:class="showDropdown">
        <li v-for="child in childs">
             <div class="d-flex my-2">
                <b-form-checkbox-group v-model="categories"  @change="categorySelected(child.id)">
                <b-form-checkbox :name="'category-'+child.id"
                    :key="child.id"
                    :value="child.id"  type="checkbox" class="category-checkbox" v-bind:id="child.id" >
                        <img v-if="child.image" :src="child.image" class="avatar-xs rounded-circle opacity-75 mr-2">
                        <img v-else class="avatar-xs rounded-circle opacity-75 mr-2" src="/images/product-placeholder.png"> 
                                   <span class="my-auto"> {{child.name}}</span>
                </b-form-checkbox>
            </b-form-checkbox-group>
                  <a v-if="child.childs.length > 0" class="btn btn-outline-light btn-rounded ml-auto my-auto btn-sm" v-b-toggle="'childcategories-'+child.id" >
                        <i class="mdi mdi-plus hide-on-collapse text-primary"></i>
                        <i class="mdi mdi-minus show-on-collapse text-primary"></i>
                    </a>
                </div>
                 <b-collapse :id="'childcategories-'+child.id">
                    <FilterPublicProductCategoriesChild ref="childchildcategories" :childs="child.childs" :productcategories="productcategories" :productid="product_id"  ></FilterPublicProductCategoriesChild>
                </b-collapse>
               
        </li>
    </ul>
</template>