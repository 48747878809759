		<template>	
			<div class="overflow-hidden">				
				<a class="position-relative d-block" @mouseenter="previewImgBtns = true" @mouseleave="previewImgBtns = false" @click="setUpLightbox(), showLightbox = !showLightbox">
				<img v-if="(product.cover_image !== null) && (imgView == null)" :src="storageurl + product.cover_image.src.replaceAll('//', '/')" class="img-fluid w-100 border mb-3" >
				<img v-else-if="imgView !== null" :src="storageurl + imgView.gallery.replaceAll('//', '/')" class="img-fluid w-100 border mb-3"> 
				<img v-else src="/images/product-placeholder.png" class="img-fluid w-100 border mb-3">
				<transition name="fade-right">
				   <div class="position-absolute top-0 right-0 mt-3 mr-3 rounded" v-if="previewImgBtns == true">
	                          	<a class="btn btn-primary rounded-circle bg-peach-soft" href="javascript:;">
	                          		<i class="bx bx-search"></i>
	                          	</a>
	                </div>
	            </transition>
				</a>
				<ImageSwiper :swiperOptions="imgswiperOptions" :items="product.images.filter(item => item['hideonweb'] == false)" @image-selected="imageSelected" :imgClass="'border'" :company="$attrs.company" ></ImageSwiper>

				<vue-easy-lightbox
                :visible="showLightbox"
                :imgs="lightboxImages"
                :index="imgViewIndex"
                @hide="showLightbox = !showLightbox"
              ></vue-easy-lightbox>
			</div>
		</template>

		<script type="text/javascript">
				import VueEasyLightbox from "vue-easy-lightbox";
			export default {
				components: {VueEasyLightbox},
				props: {
					product: {
						type: Object
					}
				},
				data(){
					return{
						lightboxImages: [],
						storageurl: 'https://'+this.$attrs.company.nickname+'.s3.eu-west-2.amazonaws.com',
						previewImgBtns: false,
						showLightbox: false,
						imgView: null,
						imgViewIndex: 0,
						imgswiperOptions: {
				          slidesPerView: 5,
				          spaceBetween: 10,
				          pagination: {
				            el: '.swiper-pagination',
				            clickable: true
				          },
				          navigation: {
				            nextEl: '.swiper-button-next',
				            prevEl: '.swiper-button-prev'
				          }

				        },
					}
				},
				mounted(){
					this.setUpLightbox();
					this.product.cover_image["hideonweb"] = false;
					this.product.images.unshift(this.product.cover_image);
				},
				methods: {
					imageSelected(img){
				    	/*if(this.imgView == null){
				    		this.product.images.push(this.product.cover_image);
				    	}*/
				    	this.imgView = img;
			    	},
			    	setUpLightbox(){
		    	//this.product.images = JSON.parse(JSON.stringify(this.product.images).replaceAll('caption', 'title'));
		    		this.lightboxImages = [];
		    		/*var image = {};
		    		image["src"] = this.storageurl+''+this.product.cover_image.src.replaceAll('//', '/')+'?s='+this.product.company.nickname;
		    		image["title"] = this.product.cover_image.caption;
		    		this.lightboxImages.push(image);*/
		    	for(var i = 0; i < this.product.images.length; i++){
		    		if(this.product.images[i]["hideonweb"] == false){
			    		var image = {};
			    		image["src"] = this.storageurl+''+this.product.images[i].src.replaceAll('//', '/')+'?s='+this.product.company.nickname;
			    		image["title"] = this.product.images[i].caption;
			    		console.log(image);
			    		this.lightboxImages.push(image);
		    		}
		    		if(this.imgView == this.product.images[i]){
		    			this.imgViewIndex = i;
		    		}

		    	}
		    	

		    },
		    	}
			}

		</script>