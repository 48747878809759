<script>
//import VueSlideBar from "vue-slide-bar";
import VueSlider from 'vue-slider-component'
//import 'vue-slider-component/theme/default.css'
import { bus } from '../../app'
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
//import FilterListTree from "../../views/stock/product/category/_partials/FilterListTree";
import FilterListTree from "../../components/widgets/FilterListTree";
import FilterProductBrands from "../../components/widgets/FilterProductBrands";
import FilterProductCollections from "../../components/widgets/FilterProductCollections";
import MarketplaceProductList from "../../components/widgets/MarketplaceProductList";
import TradeMarketplaceProductList from "../../components/widgets/TradeMarketplaceProductList";
import FilterCompanyProductCategories from "../../components/widgets/FilterCompanyProductCategories";
//import { clothsData } from "./data-products";
import SideSlidePanel from "../../components/widgets/SideSlidePanel";

/**
 * Products component
 */
export default {
  components: { Layout, PageHeader, FilterListTree, FilterProductBrands, FilterProductCollections, SideSlidePanel, MarketplaceProductList, VueSlider, FilterCompanyProductCategories, TradeMarketplaceProductList },
  data() {
    return {
      productsData: '',
      title: "Trade Marketplace",
      items: [
        {
          text: "Trade",
          href: "/",
        },
        {
          text: "Trade Marketplace",
          active: true,
        },
      ],
      search: '',
      showFilters: false,
      sliderPrice: 800,
      priceRange: [0,250000],
      category_data: [],
      currentPage: 1,
      limit: 25,
      selectedids: [],
      orderDesc: true,
      order: 'desc',
      sortby: 'id',
      trade_active: '',
      public_active: '',
      productsLoading: false,
      brands: '',
      collections: '',
      categories: [],
      isCheckAll: false,
      active: '',
      view: 'grid',
      fields: [
        { key: "id", sortable: true },
        { key: "name", sortable: true },
        ],
      filter: null,
      filterOn: [],
      limits: [
        {value: 25, text: "25"},
        {value: 50, text: "50"},
        {value: 100, text: "100"},
      ],
      discountRates: [],
    };
  },
  methods: {
    valuechange(value) {
      this.clothsData = clothsData.filter(function (product) {
        return product.newprice <= value.currentValue;
      });
    },

    searchFilter(e) {
      const searchStr = e.target.value;
      this.productQuery();
    },

    filterBrands(value){
      this.brands = value;
      this.productQuery();
    },

     filterCollections(value){
      this.collections = value;
      this.productQuery();
    },

    discountLessFilter(e, percentage) {
      if (e === "accepted" && this.discountRates.length === 0) {
        this.clothsData = clothsData.filter((product) => {
          return product.discount < percentage;
        });
      } else {
        this.clothsData = clothsData.filter((product) => {
          return product.discount >= Math.max.apply(null, this);
        }, this.discountRates);
      }
    },

    discountMoreFilter(e, percentage) {
      if (e === "accepted") {
        this.discountRates.push(percentage);
      } else {
        this.discountRates.splice(this.discountRates.indexOf(percentage), 1);
      }
      this.clothsData = clothsData.filter((product) => {
        return product.discount >= Math.max.apply(null, this);
      }, this.discountRates);
    },
     productQuery(){
      this.productsLoading = true;
      if(this.productsData != ''){
        if(this.limit > this.productsData.total){
          this.currentPage = 1;
        }
      }
      if(this.orderDesc == true){
        this.order = 'desc';
      }else{
        this.order = 'asc';
      }
      axios.get('/api/company/trade/products?page='+this.currentPage+'&limit='+this.limit+'&sortby='+this.sortby+'&order='+this.order+'&search='+this.search+'&trade_active=1&public_active='+this.public_active+'&active='+this.active+'&brands='+this.brands+'&collections='+this.collections+'&pricerange='+this.priceRange, {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
              }}).then(response => {
        console.log(response);
        this.productsData = response.data;
      }).catch(error => {
        this.error_reponse = error;
      });
      let self = this;
          setTimeout(function(){
            self.productsLoading = false;
          }, 1500);
    },
    getCategories(){
      axios.get('/api/company/product/categories?page=1&limit=50&sortby=id&order=desc&search=&parent_id=null&marketplace_categories=trade', {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
              }}).then(response => {
                     console.log(response);
                    this.category_data = response.data.data;
                });
                //this.clearCategories();
    },
     filterSelected(id){
        if(this.selectedids.length > 0){
          var exists = this.selectedids.filter(item => item == id);
          if(exists.length > 0){
            return true;
          }else{
            return false
          }
        }else{
          return false;
        }
      },
      filterSelectedCategories(id){
        if(this.categories.length > 0){
          var exists = this.categories.filter(item => item == id);
          if(exists.length > 0){
            return true;
          }else{
            return false
          }
        }else{
          return false;
        }
      },
      checkAll: function(){ 
                 this.isCheckAll = !this.isCheckAll; 
                 this.selectedids = []; 
                 if(this.isCheckAll){ 
                     // Check all 
                     for (var key in this.productsData.data) {          
                        this.selectedids.push(this.productsData.data[key].id); 
                     } 
                 } 
              }, 
      updateCheckall: function(){ 
                 if(this.selectedids.length == this.productsData.data.length){
                    this.isCheckAll = true; 
                 }else{ 
                    this.isCheckAll = false; 
                 } 
      },
  },
  beforeMount(){
      this.productQuery();
  },
  computed:{
   
  }
};
</script>

<template>
  <Layout :nickname="$attrs.nickname" :user="$attrs.user" :company="$attrs.company">
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div :class="{'col-lg-12': showFilters == true, 'col-lg-12': showFilters == false}">
        <div class="row mb-3">
          <div class="col-xl-1 col-sm-6">
            <div class="mt-2">
              <b-form-select class="form-control" v-model="limit" :options="limits" @input="productQuery()"></b-form-select>
            </div>
          </div>
         
          <div class="col-lg-2 col-sm-6 d-flex">
          <form class="my-auto">
            <small class="mx-auto my-2 text-center d-block">Results {{productsData.from}} - {{productsData.to}} of {{productsData.total}}</small>
          </form>
        </div>
        <div class="col-lg-5 col-sm-6 d-flex">
            <div class="mt-sm-0 form-inline w-100" >
              <div class="search-box mr-2 ml-auto">
                <div class="position-relative">
                  <input
                    type="text"
                    class="form-control w-100 border-0"
                    placeholder="Search name, reference or description..."
                    v-model="search"
                    @keyup.enter="searchFilter($event)"
                  />
                  <i class="bx bx-search-alt search-icon"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-sm-6 d-flex">
            <div class="d-flex ml-auto">
              <ul class="nav nav-pills product-view-nav">
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': showFilters == true }" href="javascript: void(0);" @click="showFilters = !showFilters">
                    <i class="bx bx-filter-alt"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': view == 'grid' }" href="javascript: void(0);" @click="view = 'grid'">
                    <i class="bx bx-grid-alt"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': view == 'list' }" href="javascript: void(0);" @click="view = 'list'">
                    <i class="bx bx-list-ul"></i>
                  </a>
                </li>
              </ul>
              <div class="text-sm-right">
                  
                </div>
          </div>
        </div>
      </div>
        <!--<div class="row">
          <div class="col-lg-12">
            <b-pagination
              v-if="productsData.data.length > 0"
              class="justify-content-center mb-0"
              pills
              v-model="currentPage"
              :total-rows="productsData.total"
              :per-page="productsData.per_page"
              aria-controls="my-table"
              @input="productQuery"
            ></b-pagination>
             <small class="mx-auto my-2 text-center d-block">Records {{productsData.from}} - {{productsData.to}} of {{productsData.total}} // Page {{productsData.current_page}} of {{productsData.last_page}}</small>
          </div>
        </div>-->
        <b-row>
          <b-col md="3">
          <div class="col-lg-12">
        <div class="">
          <div class="card p-4 rounded-4">
            <!--<h4 class="card-title mb-4">Filter</h4>-->
            <div>
               <div class="my-3 pt-3">
              <h5 class="font-size-14">Price</h5>
              <vue-slider v-model="priceRange" :min="0" :max="100 + priceRange[1]"  @drag-end="productQuery()" ></vue-slider>
              <b-form-group label="Min Price" class="w-50 d-inline float-left mt-2 mb-3 px-2">
              <b-form-input
                type="number"
                v-model="priceRange[0]"
                @input="productQuery"></b-form-input>
              </b-form-group>
              <b-form-group label="Max Price" class="w-50 d-inline float-left mt-2 mb-3 px-2">
                 <b-form-input
                 label="Max Price"
                 type="number"
                 v-model="priceRange[1]"
                 @input="productQuery"></b-form-input>
              </b-form-group>
            </div>
                <h5 class="font-size-14 my-3">Categories</h5>
                <!--<FilterListTree></FilterListTree>-->
                <FilterCompanyProductCategories :api_token="$attrs.user.api_token" :selectedids="categories" :category_data="category_data" @category-select="filterSelectedCategories" :marketplace_categories="'trade'" ></FilterCompanyProductCategories>
                <h5 class="font-size-14 mb-3">Brands</h5>
               <FilterProductBrands v-on:brands-filtered="filterBrands" :api_token="$attrs.user.api_token"></FilterProductBrands>

               
            </div>
           

          <!-- <div class="mt-4 pt-3">
              <h5 class="font-size-14 mb-3">Customer Rating</h5>
              <div>
                <b-form-checkbox
                  id="checkbox-1"
                  name="checkbox-1"
                  value="accepted"
                  unchecked-value="not_accepted"
                >
                  4
                  <i class="bx bx-star text-warning"></i> & Above
                </b-form-checkbox>

                <b-form-checkbox
                  id="checkbox-2"
                  class="mt-2"
                  name="checkbox-2"
                  value="accepted"
                  unchecked-value="not_accepted"
                >
                  3
                  <i class="bx bx-star text-warning"></i> & Above
                </b-form-checkbox>

                <b-form-checkbox
                  id="checkbox-3"
                  class="mt-2"
                  name="checkbox-3"
                  value="accepted"
                  unchecked-value="not_accepted"
                >
                  2
                  <i class="bx bx-star text-warning"></i> & Above
                </b-form-checkbox>
                <b-form-checkbox
                  id="checkbox-4"
                  class="mt-2"
                  name="checkbox-4"
                  value="accepted"
                  unchecked-value="not_accepted"
                >
                  1
                  <i class="bx bx-star text-warning"></i>
                </b-form-checkbox>
              </div>
            </div>-->
          </div>
          <div class="card">
              <div class="card-body">
            Ad Space
              </div>
            </div>
        </div>
      </div>
          </b-col>
        <b-col md="9">
        <b-overlay
        :show="productsLoading"
        variant="white"
        opacity="0.85"
        blur="1px"
        rounded="xl">
          <!--<MarketplaceProductList :product_list="productsData.data" :expanded="0" :layouttoggle="false" :view="view" :marketplace="'trade'" ></MarketplaceProductList>-->
           <TradeMarketplaceProductList :product_list="productsData.data" :expanded="expanded" :layouttoggle="false" :view="'grid'" :marketplace="'trade'" ></TradeMarketplaceProductList>
          <b-pagination
              class="justify-content-center mt-auto pt-4"
              pills
              v-model="currentPage"
              :total-rows="productsData.total"
              :per-page="productsData.per_page"
              aria-controls="my-table"
              @input="productQuery"
            ></b-pagination>
             <small class="mx-auto my-2 text-center d-block">Records {{productsData.from}} - {{productsData.to}} of {{productsData.total}} // Page {{productsData.current_page}} of {{productsData.last_page}}</small>
          <template #overlay>
            <div class="cube-wrapper"><div class="cube-folding"><span class="leaf1"></span><span class="leaf2"></span><span class="leaf3"></span><span class="leaf4"></span></div></div>
          </template>
        </b-overlay>
      </b-col>
    </b-row>
        <div class="row">
          
          <div id="my-table" class="col-xl-4 col-sm-12">
            <div class="card">
              <div class="card-body">
              Ad space
              </div>
            </div>
          </div>
          <div id="my-table" class="col-xl-4 col-sm-12">
            <div class="card">
              <div class="card-body">
               Ad Space
              </div>
            </div>
          </div>
          <div id="my-table" class="col-xl-4 col-sm-12">
            <div class="card">
              <div class="card-body">
            Ad Space
              </div>
            </div>
          </div>
        </div>
        <!-- end row -->

        
        <div class="card" v-if="view == 'list'" >
          <div class="card-body p-0">
            <div class="table-responsive">
             
            </div>
            <div class="row">
              <div class="col-lg-12" v-if="productsData !== ''">
                <b-pagination
              class="justify-content-center"
              pills
              v-model="currentPage"
              :total-rows="productsData.total"
              :per-page="productsData.per_page"
              aria-controls="my-table"
              @input="productQuery"
            ></b-pagination>
             <small class="mx-auto my-2 text-center d-block">Records {{productsData.from}} - {{productsData.to}} of {{productsData.total}} // Page {{productsData.current_page}} of {{productsData.last_page}}</small>
              </div>
            </div>
          </div>
        </div>
      </div>
       <SideSlidePanel :editPanel="showFilters" :panelClass="'edit-sidepanel-slim'" @close-panel="showFilters = !showFilters">     
         <div class="col-lg-12" v-if="showFilters == true">
        <div class="">
          <div class="">
            <h4 class="card-title mb-4">Filter</h4>
              <div class="row">
                <div class="col-md-12 d-flex">
                      <b-form-checkbox v-b-tooltip.hover title="Toggle Active/Inactive" placement="left" switch size="lg" class="my-auto d-inline" value="1"
                      unchecked-value="0" v-model="active" @input="productQuery"><small class="mr-auto">Active</small></b-form-checkbox>
                      
                    <i v-b-tooltip.hover title="Clear Toggle" placement="left" class="font-size-20 bx bx-reset ml-auto" v-if="active != ''" @click="active = '', productQuery"></i>
                </div>
                 <div class="col-md-12 d-flex">
                      <b-form-checkbox switch size="lg" class="d-inline my-auto" value="1"
                      unchecked-value="0" v-model="public_active" @input="productQuery"><small class="mr-auto">Public</small></b-form-checkbox>
                      <i class="bx bx-reset font-size-20 ml-auto" v-if="public_active != ''" @click="public_active = '', productQuery"></i>
                </div>
                 <div class="col-md-12 d-flex">
                       <b-form-checkbox switch size="lg" class="my-auto d-inline" value="1"
                      unchecked-value="0" v-model="trade_active" indeterminate="null" @input="productQuery"><small class="mr-auto"  >Trade</small></b-form-checkbox>
                      <i class="bx bx-reset font-size-20 ml-auto" v-if="trade_active != ''" @click="trade_active = '', productQuery"></i>
                </div>
              </div>


            <div>
               <div class="my-3 pt-3">
              <h5 class="font-size-14">Price</h5>
              <b-form-group label="Min Price" class="w-50 d-inline float-left mt-2 mb-3 px-2">
              <b-form-input
                type="number"
                v-model="priceRange[0]"
                @input="productQuery"></b-form-input>
              </b-form-group>
              <b-form-group label="Max Price" class="w-50 d-inline float-left mt-2 mb-3 px-2">
                 <b-form-input
                 label="Max Price"
                 type="number"
                 v-model="priceRange[1]"
                 @input="productQuery"></b-form-input>
              </b-form-group>
            </div>
                <h5 class="font-size-14 my-3">Categories</h5>
                <FilterCompanyProductCategories :api_token="$attrs.user.api_token" :selectedids="categories" :category_data="[]" @category-select="filterSelectedCategories" :marketplace_categories="'trade'" ></FilterCompanyProductCategories>
                
                <h5 class="font-size-14 mb-3">Brands</h5>
               <FilterProductBrands v-on:brands-filtered="filterBrands" :api_token="$attrs.user.api_token"></FilterProductBrands>

               <h5 class="font-size-14 mb-3">Collections</h5>
               <FilterProductCollections v-on:collections-filtered="filterCollections" :companies="$attrs.companies" :default_company="$attrs.company.id" :api_token="$attrs.user.api_token"></FilterProductCollections>

            </div>
           

          <!-- <div class="mt-4 pt-3">
              <h5 class="font-size-14 mb-3">Customer Rating</h5>
              <div>
                <b-form-checkbox
                  id="checkbox-1"
                  name="checkbox-1"
                  value="accepted"
                  unchecked-value="not_accepted"
                >
                  4
                  <i class="bx bx-star text-warning"></i> & Above
                </b-form-checkbox>

                <b-form-checkbox
                  id="checkbox-2"
                  class="mt-2"
                  name="checkbox-2"
                  value="accepted"
                  unchecked-value="not_accepted"
                >
                  3
                  <i class="bx bx-star text-warning"></i> & Above
                </b-form-checkbox>

                <b-form-checkbox
                  id="checkbox-3"
                  class="mt-2"
                  name="checkbox-3"
                  value="accepted"
                  unchecked-value="not_accepted"
                >
                  2
                  <i class="bx bx-star text-warning"></i> & Above
                </b-form-checkbox>
                <b-form-checkbox
                  id="checkbox-4"
                  class="mt-2"
                  name="checkbox-4"
                  value="accepted"
                  unchecked-value="not_accepted"
                >
                  1
                  <i class="bx bx-star text-warning"></i>
                </b-form-checkbox>
              </div>
            </div>-->
          </div>
        </div>
      </div>
    </SideSlidePanel>
    </div>
    <!-- end row -->
  </Layout>
</template>
