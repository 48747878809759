<template>
	
	<div>
		<simplebar style="min-height: 90vh; max-height: 90vh; overflow-x: hidden;">	
		<b-list-group class="p-3">
			<draggable v-model="sections" handle=".shandle" v-for="(section, sindex) in sections" class="text-success border-success border-radius-1em border border-2 mb-2" :list="sections">
				<b-list-group-item>
				<div class="d-flex">
					<span class="my-auto mr-auto">Section {{sindex + 1}}</span>
				<a class="ml-auto mb-2 mt-1 float-right" @click="deleteSection(sindex)">
					<i class="bx bx-trash-alt text-success"></i>
				</a>
				<a class="ml-2 mb-2 mt-1 float-right" @click="duplicateSection(sindex, section)" >
				<i class="bx bx-copy-alt text-success"></i>
				</a>
				<a class="ml-2 mb-2 mt-1 float-right" @click="moveSection(sindex, 'up')" >
				<i class="bx bx-chevron-up text-success font-size-16"></i>
				</a>
				<a class="ml-2 mb-2 mt-1 float-right" @click="moveSection(sindex, 'down')" >
				<i class="bx bx-chevron-down text-success font-size-16"></i>
				</a>
				<span class="ml-2 mb-2 mt-1 float-right shandle">
					<i class="bx bx-move text-success"></i>
				</span></div>

				<b-list-group>
					<draggable tag="b-list-group-item" v-model="sections[sindex].rows" handle=".rhandle" v-for="(row, rindex) in section.rows" class="text-primary border-primary border-radius-1em border border-2 mb-2" :list="sections[sindex].rows">

						<div class="d-flex">
							<span class="my-auto mr-auto">Row {{rindex + 1}}</span>
						<a class="ml-auto mb-2 mt-1 float-right" @click="deleteRow(sindex, rindex)">
							<i class="bx bx-trash-alt text-primary"></i>
						</a>
						<a class="ml-2 mb-2 mt-1 float-right" @click="duplicateRow(sindex, rindex, row)" >
						<i class="bx bx-copy-alt text-primary"></i>
						</a>
						<span class="ml-2 mb-2 mt-1 float-right rhandle">
							<i class="bx bx-move text-primary"></i>
						</span></div>
						<b-list-group>
							<draggable tag="b-list-group-item" v-model="sections[sindex].rows[rindex].cols" v-for="(col, cindex) in row.cols" handle=".chandle" class="text-info border-info border-radius-1em border border-2 mb-2" :list="sections[sindex].rows[rindex].cols">
								<div class="d-flex">
									<span class="my-auto mr-auto">Column {{rindex + 1}}</span>
								<a class="ml-auto mb-2 mt-1 float-right" @click="deleteColumn(sindex, rindex, cindex)">
									<i class="bx bx-trash-alt text-info"></i>
								</a>
								<a class="ml-2 mb-2 mt-1 float-right" @click="duplicateColumn(sindex, rindex, cindex, col)">
								<i class="bx bx-copy-alt text-info"></i>
								</a>
								<span class="ml-2 mb-2 mt-1 float-right chandle">
									<i class="bx bx-move text-info"></i>
								</span></div>
								<b-list-group>
									<draggable tag="b-list-group-item" v-model="sections[sindex].rows[rindex].cols[cindex].blocks" v-for="(block, bindex) in col.blocks" handle=".bhandle" class="text-secondary border-secondary border-radius-1em border border-2 mb-2" :list="sections[sindex].rows[rindex].cols[cindex].blocks">
										<div class="d-flex">
											<span class="my-auto mr-auto">{{block.widgetname}}</span>
										<a class="ml-auto mb-2 mt-1 float-right">
											<i class="bx bx-trash-alt"></i>
										</a>
										<a class="ml-2 mb-2 mt-1 float-right">
										<i class="bx bx-copy-alt"></i>
										</a>
										<span class="ml-2 mb-2 mt-1 float-right bhandle">
											<i class="bx bx-move text-secondary"></i>
										</span></div>
									</draggable>
								</b-list-group>	
							</draggable>
						</b-list-group>	

					</draggable>
				</b-list-group>	
				</b-list-group-item>
			</draggable>
		</b-list-group>
		</simplebar>
	</div>
</template>

<script type="text/javascript">
	import Swal from "sweetalert2";
	import draggable from 'vuedraggable';
	import simplebar from "simplebar-vue";
	export default {
		data(){
			return {
				activeIndexes: '',
			}
		},
		components: {
			simplebar,
			draggable,
			Swal,
		},
		props: {
			sections: {
				type: Array,
				default: []
			}
		},
		watch: {
			computedStyles: {
		      handler(newVal, oldVal) {
		        //console.log('Styles changed:', newVal);
		        this.$emit('layers-updated', newVal)
		        // Perform any desired actions when styles change
		      },
		      deep: true
		    }
		},
		computed: {
			computedStyles(){
				return this.sections;
			}
		},
		methods: {
			moveSection(sindex, direction) {
			  if (direction === 'up') {
			    if (sindex > 0) {
			      let movedSection = this.sections.splice(sindex, 1);
			      this.sections.splice(sindex - 1, 0, movedSection[0]);
			    }
			  } else if (direction === 'down') {
			    if (sindex < this.sections.length - 1) {
			      let movedSection = this.sections.splice(sindex, 1);
			      this.sections.splice(sindex + 1, 0, movedSection[0]);
			    }
			  }
			},
			addParentResource(type, sindex, rindex, cindex, data){
    			if(type == 'row'){
    				var runQuery = {
		        	message: 'addRow',
		        	sindex: sindex,
		        	data: data
    				};
    				parent.postMessage(runQuery, '*');
    			}
    			if(type == 'delete-row'){
    				var runQuery = {
		        	message: 'addRow',
		        	sindex: sindex,
		        	data: data
    				};
    				parent.postMessage(runQuery, '*');
    			}
    			if(type == 'delete-section'){
    				var runQuery = {
		        	message: 'addRow',
		        	sindex: sindex,
		        	data: data
    				};
    				parent.postMessage(runQuery, '*');
    			}
    				if(type == 'duplicate-row'){
    				var runQuery = {
		        	message: 'duplicateRow',
		        	sindex: sindex,
		        	data: data
    				};
    				parent.postMessage(runQuery, '*');
    			}
    			if(type == 'col'){
    				var runQuery = {
		        	message: 'addCol',
		        	sindex: sindex,
		        	rindex: rindex,
		        	data: data
    				};
    				parent.postMessage(runQuery, '*');
    			}
    			if(type == 'duplicate-col'){
    				var runQuery = {
		        	message: 'addCol',
		        	sindex: sindex,
		        	rindex: rindex,
		        	cindex: cindex,
		        	data: data
    				};
    				parent.postMessage(runQuery, '*');
    			}
    			if(type == 'delete-col'){
    				var runQuery = {
		        	message: 'addCol',
		        	sindex: sindex,
		        	rindex: rindex,
		        	cindex: cindex,
		        	data: data
    				};
    				parent.postMessage(runQuery, '*');
    			}
    		},
			deleteColumn(sindex, rindex, cindex){
		      this.sections[sindex].rows[rindex].cols.splice(cindex, 1);
		      //this.addParentResource('delete-col', sindex, rindex, cindex, section);
		    },
		    deleteRow(sindex, rindex){
		      this.sections[sindex].rows.splice(rindex, 1);
		      //this.addParentResource('delete-row', sindex, rindex, null, null);
		    },
		    deleteSection(sindex){
		    	if((sindex == 0) && (this.sections.length == 1)){
					Swal.fire("You can't delete this", "Every page needs at least one section!", "error");
				}else{
		      		this.sections.splice(sindex, 1);
		  		}
		      //this.addParentResource('delete-section', sindex, null, null, null);
		    },
		    duplicateSection(sindex, section){
					this.sections.splice(sindex, 0, JSON.parse(JSON.stringify(section)));
					//this.addParentResource('duplicate-row', sindex, null, null, section);
			},
		    duplicateRow(sindex, rindex, section){
					this.sections[sindex].rows.splice(rindex, 0, JSON.parse(JSON.stringify(section)));
					//this.addParentResource('duplicate-row', sindex, rindex, null, section);
			},
			duplicateColumn(sindex, rindex, cindex, section){
					this.sections[sindex].rows[rindex].cols.splice(cindex, 0, JSON.parse(JSON.stringify(section)));
					//this.addParentResource('duplicate-col', sindex, rindex, cindex, section);
			},
		}
	}

</script>