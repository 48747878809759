<script type="text/javascript">
	export default{
		data(){
			return{
				users: '',
				search: '',
				currentPage: 1,
				order: 'desc',
      			sortby: 'id',
      			limit: 25,
				limits: [
		        {value: 25, text: "25"},
		        {value: 50, text: "50"},
		        {value: 100, text: "100"},
		      	],
			}
		},
		created(){
			//this.runSearchQuery();
		},
		methods: {
			runSearchQuery(){
				axios.get('/api/company/users?page='+this.currentPage+'&limit='+this.limit+'&sortby='+this.sortby+'&order='+this.order+'&search='+this.search+'', {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.api_token
              	}}).then(response => {
					this.users = response.data;
				}).catch(error => {
					this.load_error = error.data;
				});
			},
			addUserToResource(userid){
				axios.post('/api/company/'+this.$attrs.resource+'/'+this.$attrs.id+'/user/store', {user_id: userid}, {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.api_token
            	}}).then(response => {
					//this.users = response.data;
					this.$emit('user-added', response.data);
				}).catch(error => {
					//this.load_error = error.data;
					this.$emit('error', error.data);
				});
			}
		}
	}
</script>
<template>
	<div class="my-2">
		<b-form-group label="Search User">
			<b-input-group>
				<b-form-input type="text" v-model="search" @keydown.enter="runSearchQuery"></b-form-input>
				<b-input-group-append>
				 	<button  size="sm" class="btn btn-light" @click="users = ''">Reset</button>
			    </b-input-group-append>
				 <b-input-group-append>
				 	<button  size="sm" class="btn btn-dark" @click="search = '', runSearchQuery()">See All</button>
			    </b-input-group-append>
			</b-input-group>
			
		</b-form-group>
		<b-list-group class="user-list-results search-list" v-if="users !== ''">
			<b-list-group-item v-for="user in users.data" class="d-flex flex-row">
				<div class="my-auto">
				<img v-if="user.avatar !== null" :src="user.avatar" width="70" class="rounded-circle">
				<div v-else class="avatar-xs d-inline-block mr-1"><span class="avatar-title rounded-circle bg-primary">{{user.name.slice(0, 1)}}</span></div>
				</div>
				<div class="my-auto mx-4">
				{{user.name}} {{user.lastname}}<br>
				{{user.jobtitle}}
				</div>
				<div class="my-auto mx-auto">
				<a :href="'mailto:'+user.email" class="text-muted">{{user.email}}</a>
				</div>
				<div class="ml-auto my-auto">
					<a v-b-tooltip.hover.left class="btn btn-outline-light btn-rounded mr-2" title="Add" @click="addUserToResource(user.companies[0].id), users = ''">
                            <i class="mdi mdi-plus font-size-20"></i>
                  </a>
				</div>
			</b-list-group-item>

		</b-list-group>

	</div>
</template>