<template>
	<div>
	<div v-for="field in $attrs.form.fields">
				<b-form-group class="mb-2" v-if="field['type'] == 'Text'" :label="field['name']" :class="field['required'] == true ? 'required' : ''">
                  <b-form-input type="text" v-model="field['value']" :required="field['required'] == true">

                  </b-form-input>
                  
                </b-form-group>
                 <b-form-group class="mb-2" v-if="field['type'] == 'Email'" :label="field['name']" :class="field['required'] == true ? 'required' : ''">
                  <b-form-input type="email" v-model="field['value']" :required="field['required'] == true">

                  </b-form-input>
                  
                </b-form-group>
                <b-form-group class="mb-2 pb-4" v-else-if="field['type'] == 'Long Text'" :label="field['name']" :class="field['required'] == true ? 'required' : ''">
                  <b-form-textarea rows="4" max-rows="8" v-model="field['value']" :required="field['required'] == true">

                  </b-form-textarea>
                </b-form-group>
                <b-form-group class="mb-2" v-else-if="field['type'] == 'Number'" :label="field['name']" :class="field['required'] == true ? 'required' : ''">
                  <b-form-input type="number" v-model="field['value']" :required="field['required'] == true">

                  </b-form-input>
                </b-form-group>
                <b-form-group class="mb-2"  v-else-if="field['type'] == 'Dropdown'" :label="field['name']" :class="field['required'] == true ? 'required' : ''">
                 <b-form-select class="form-control" :label="field['name']" v-model="field['value']" :options="field['options']" :required="field['required'] == true">

                  </b-form-select>
                </b-form-group>
                 <b-form-group class="mb-2 d-flex" v-else-if="field['type'] == 'Radio'" :label="field['name']" :class="field['required'] == true ? 'required' : ''">
                  
                           <b-form-radio-group class="w-100" v-model="field['value']" :options="field['options']" :required="field['required'] == true">

                            </b-form-radio-group>

                </b-form-group>
                 <b-form-group class="mb-2" v-else-if="field['type'] == 'Checkbox'" :label="field['name']" :class="field['required'] == true ? 'required' : ''">
                  
                           <b-form-checkbox-group class="w-100" v-model="field['value']" :options="field['options']" :required="field['required'] == true">

                            </b-form-checkbox-group>

                </b-form-group>
                <b-form-group class="mb-2 d-flex" v-else-if="field['type'] == 'Date'" :label="field['name']" :class="field['required'] == true ? 'required' : ''">
                  
                           <b-form-input type="date" class="w-100" v-model="field['value']" :required="field['required'] == true">

                            </b-form-input>

                </b-form-group>
               
			</div>
		</div>
</template>

<script type="text/javascript">
	export default{
		methods: {
      
    }
	}

</script>