<script type="text/javascript">
	import Layout from "../../layouts/main";
	import PageHeader from "../../components/page-header";
	import { EventBus } from '../../app';
	import simplebar from "simplebar-vue";
	import TradeProfile from '../../components/widgets/ecommerce/trade/TradeProfile'
	import ProductContent from '../../components/widgets/ecommerce/trade/ProductContent'
	
	export default{
		components: { Layout, PageHeader, simplebar, TradeProfile, ProductContent },
		data(){
			return{
				viewtype: 'products',
				productNotFound: false,
				headers: '',
				title: '',
				items: [
					 {
			          text: 'Trade',
			          href: '/trade',
			        },
			        {
			          text: 'Directory',
			          href: '/trade/directory'
			        },
			         {
			          text: 'Seller',
			          active: true,
			        },
				],
				breadcrumbs: [],
				bread: {
					title: '',
					link: '',
					id: 1,
				},
				markers: [
        {
          position: { lat: 10.0, lng: 10.0 }
        },
        {
          position: { lat: 15.0, lng: 13.0 }
        }
      	],
				csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
				categorydata: '',
				showStores: false, 
				categoriesdata: '',
				collectionsdata: [],
				imageUpload: false,
				data: '',
				form: {
					action: '',
					name: '',
					lastname: '',
					email: '',
					customer_uid: '',
					share_details: '',
					review: '',
					message: '',
					review_score: 0,
				},
				mapcenter: { lat: 54, lng: 2 },
				mapzoom: 5,
				storageurl: 'https://'+this.$attrs.company.nickname+'.s3.eu-west-2.amazonaws.com',
				productsLoading: true,
				product: [],
				showFilters: false,
		        sliderPrice: 800,
		        priceRange: [0,250000],
		        brands: [],
		        collections: [],
		        categories: [],
		        sortby: 'created_at',
		        order: 'desc',
		        search: '',
		        view: 'grid',
		        public_active: 1,
		        trade_active: '',
		        active: '',
		        expanded: 1,
		        currentPage: 1,
		        query: '',
		        limit: 25,
		        listingslimit: 25,
					  listingssortby: 'id',
							      listingsData: [],
						listingsearch: '',
						listingtrade_active: 1,
							      listingpublic_active: '',
							      listing_active: '',
				limits: [
				        {value: 25, text: "25"},
				        {value: 50, text: "50"},
				        {value: 100, text: "100"},
				      ],
			}
		},
		props: {
			user: {
				type: Object
			}
		},
		mounted() {
			this.headers = {'Authorization' : 'Bearer '+this.user.api_token};
			//this.createQueryString();
			this.loadSellerInfo();
			//this.loadSellerCollections();
				//let self = this;
			if(this.$attrs.slug !== ''){
				this.loadProduct(this.$attrs.id, this.$attrs.slug);			
			}
		},
		created(){
			let self = this;
			EventBus.$on('category-select', function(id){
				self.filterSelectedCategories(id);
		    });
			EventBus.$on('categories-cleared', function(id){
				self.categories = [];
				self.loadSellerProducts();
		    });
		},
		methods: {
			loadProduct(seller, slug){
				axios.get('/api/company/trade/profile/'+seller+'/product/'+slug, {headers: this.headers}).then(response => {
					this.product = response.data;
					this.items.push({text: response.data.name, active: true});
					this.items[2].active = false;
					this.items[2]["href"] = '/trade/profile/'+this.$attrs.id;
					this.productNotFound = false;
					
				}).catch(error => {
					if(error){
						this.productNotFound = true;
					}
				});
			},
			loadSellerInfo(){
				if(this.$attrs.id !== ''){
					var id = this.$attrs.id;
				}else{
					var id = this.$attrs.company.nickname;
				}
				axios.get('/api/company/trade/profile/'+id, {headers: this.headers}).then(response => {
					this.categorydata = response.data;
					
						this.items[2].text = response.data.name;
					
				}).catch(error => {
					console.log(error);
				});
			},
			
		},
		watch: {
        $query() {
        	this.$nextTick(() => {
        		this.loadSellerProducts();
        		this.loadSellerInfo();
        		
        	 });
            //this.$nextTick();
            let self = this;
			
         }  
    	},
	}

</script>

<template>
	  <Layout  :nickname="$attrs.nickname" :user="user" :company="$attrs.company" :selected_currency="$attrs.selected_currency" :selected_currency_symbol="$attrs.selected_currency_symbol" :exchange_rates="$attrs.exchange_rates" :currencies="$attrs.currencies">
	  	<PageHeader :title="categorydata.name" :items="items" />
	  	<TradeProfile v-if="$attrs.slug == ''" :user="user" :active_menu="$attrs.active_menu" :company="$attrs.company" :id="$attrs.id" :isowner="$attrs.isowner" :nickname="$attrs.nickname" :subscription="$attrs.subscription" :validsubscription="$attrs.validsubscription" :userpermissions="$attrs.userpermissions" :categorydata="categorydata" :selected_currency="$attrs.selected_currency" :selected_currency_symbol="$attrs.selected_currency_symbol" :exchange_rates="$attrs.exchange_rates" :currencies="$attrs.currencies"></TradeProfile>
	  	<ProductContent ref="productContent" v-else-if="($attrs.id !== '') && (productNotFound == false)" :user="user" :active_menu="$attrs.active_menu" :company="$attrs.company" :id="$attrs.id" :isowner="$attrs.isowner" :nickname="$attrs.nickname" :subscription="$attrs.subscription" :validsubscription="$attrs.validsubscription" :userpermissions="$attrs.userpermissions" :product="product" :selected_currency="$attrs.selected_currency" :selected_currency_symbol="$attrs.selected_currency_symbol" :exchange_rates="$attrs.exchange_rates" :currencies="$attrs.currencies"></ProductContent>
	  	<NotFound v-else></NotFound>

</Layout>
</template>

<style scoped >
	
	.profile-container{
		    margin-top: -25px !important;
		    margin-left: -20px;
		    width: 103%;
		    max-width: 103% !important;
	}

	.page-content{
		padding: 0!important;
	}

	.page-content > .container-fluid{
		padding: 0!important;
	}

	.main-content{
		padding: 0!important;
	}

</style>