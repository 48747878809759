<script type="text/javascript">
	export default{
		props: {
			user: {
				type: Object,
				default: null,
			},
			use: {
				type: String,
				default: 'public',
			},
			index: {
				type: Number,
				default: -1,
			},
		},
		data(){
			return {
				storageurl: '',
				hoverIndex: -1,
				hoverLink: -1,
				categories: [],
				linkprefix: '',
				linksuffix: '',
			}
		},
		mounted(){
			if(this.use !== ''){
		   		if(this.use == 'public'){
		   			this.storageurl = '/public-image';
		   			this.linkprefix = '/seller/';
		   			this.linksuffix = '/product';
		   		}else if(this.use == 'website'){
		   			this.storageurl = '/website-image';
		   			this.linkprefix = '';
		   			this.linksuffix = '/product';
		   		}
		   	}
		},
		methods: {
			addTax(product, price){
        		var tax_rule = product.tax_rule;
        		console.log(tax_rule);
		    	if((this.user !== null && this.user !== '') && (this.user.default_delivery_address !== '' && this.user.default_delivery_address !== null)){
		    		var country_id = this.user.default_delivery_address.country_id;
		    	}else{
		    		var country_id = product.company.country_id;
		    	}
		    	if(tax_rule !== null && tax_rule.country_rules.length > 0){
		    		var selected_rule = null;
		    		var selected_ruleIndex = -1;
			    	for(var ti = 0; ti < tax_rule.country_rules.length; ti++){
			    		selected_ruleIndex = tax_rule.country_rules[ti].countries.findIndex(item => item == country_id);
			    		if(selected_ruleIndex !== -1){
			    			selected_rule = tax_rule.country_rules[ti];
			    			ti = tax_rule.country_rules.length;
			    		}
			    	}
			    	console.log(selected_rule);
			    	if(tax_rule.type == 1){
		        		if(selected_rule !== null){
		        			var percentage = (parseFloat(selected_rule.value) / 100) + 1;
		        			return price * percentage;
		         		}else{
		         			return (price * 1.2).toFixed(2);
		         		}
	         		}else{
	         			if(selected_rule !== null){
		        			return price + selected_rule.value;
		         		}else{
		         			return (price * 1.2).toFixed(2);
		         		}
	         		}
         		}else if(tax_rule !== null && tax_rule.country_rules.length > 0){
         			var percentage = (parseFloat(tax_rule.percentage) / 100) + 1;
		        	return price * percentage;
         		}else {
		        	return (price * 1.2).toFixed(2);
         		}
        	},
		}
	}
</script>
<template>
	<router-link :to="'/seller/'+$attrs.product.company.nickname+'/service/'+$attrs.product.slug">
					
				<b-card class="card overflow-hidden border-radius-1em shadow-sm seller-profile-card" no-body @mouseenter="hoverIndex = index" @mouseleave="hoverIndex = -1">
          <div class="bg-soft bg-light">
            <div class="row">
            	<transition name="fade-left">
		                    	<div class="col-md-12 position-absolute z-index-2" v-if="hoverIndex == index"> 
                              	<router-link :to="'/seller/'+$attrs.product.company.nickname+'/service/'+$attrs.product.slug" class="mt-3 ml-3 btn btn-primary font-size-16 btn-rounded ml-auto float-left" v-b-tooltip.hover.right title="View Company" ><i class="mdi mdi-eye-outline"></i></router-link>
                              </div>
                          </transition>
                           
                       <div class="col-12">
                       	<router-link :to="'/seller/'+$attrs.product.company.nickname+'/service/'+$attrs.product.slug">
                					<div v-if="$attrs.product.image !== null">	
                						<b-img-lazy :src="storageurl + $attrs.product.imagey+'?s='+$attrs.product.nickname" alt class="img-fluid" />	

                						<!--<b-img-lazy v-if="'gallery' in $attrs.product.cover_image" :src="storageurl + $attrs.product.cover_image.gallery+'?s='+$attrs.product.nickname" alt class="img-fluid" />
                						<b-img-lazy v-else-if="'thumb' in $attrs.product.cover_image" :src="storageurl + $attrs.product.cover_image.thumb+'?s='+$attrs.product.nickname" alt class="img-fluid" />
                						<b-img-lazy v-else-if="'src' in $attrs.product.cover_image" :src="storageurl + $attrs.product.cover_image.src+'?s='+$attrs.product.nickname" alt class="img-fluid" />-->
                						
                					</div>
                					<div v-else style="min-height: 240px;"></div>
                				</router-link>
                 <transition name="fade-right">
		                        <div class="mt-auto mb-3 mx-2 d-flex position-absolute bottom-0 right-0 left-0" v-if="hoverIndex == index">
                              	<b-form-rating style="width: 30%;" id="rating-inline" readonly variant="warning" class="bg-transparent pr-4 ml-auto" inline no-border size="sm" :value="$attrs.product.reviews_avg_rating"></b-form-rating> 
              					</div>
              				</transition>
              </div>
            </div>
          </div>
          <b-card-body class="card-body pt-0">
            <div class="row">
              <div class="col-sm-12 pb-4 text-center">
                <div class="avatar-md profile-user-wid mb-2 mx-auto" style="margin-top: -45px !important;">
                  <img
                  	v-if="$attrs.product.logo" :src="$attrs.product.logo.includes(storageurl) == false ? (storageurl + $attrs.product.logo+'?s='+$attrs.product.nickname) : $attrs.product.logo+'?s='+$attrs.product.nickname"
                    alt
                    class="img-thumbnail rounded-circle"
                  />
                   <img
                  	v-else 
                    src="/images/product-placeholder.png"
                    alt
                    class="img-thumbnail rounded-circle"
                  />
                </div>
                <h3 class="font-size-18 my-auto py-2">{{$attrs.product.name}}</h3>
							<p class="text-dark paragraph-truncate-line2 mb-0" v-html="$attrs.product.short_description"></p>
						     </div>
              <div class="col-md-6 d-flex">
              	<span class="badge badge-light border-radius-1em bg-light text-dark font-size-11 lh-16 my-auto p-2">
              		{{addTax($attrs.product, $attrs.product.price_exc)}}
              	</span>
              </div>
              <div class="col-md-6 d-flex">
              	<!--<span class="badge badge-light border-radius-1em bg-light text-dark font-size-11 lh-16 my-auto p-2 ml-auto">
              		100 <i class="bx bx-tag"></i>
              	</span>-->
              	<span class="badge badge-light border-radius-1em bg-light text-dark font-size-11 lh-16 my-auto p-2 ml-auto">
              		Offered at {{$attrs.product.listings.length}} Locations <i class="bx bx-buildings"></i>
              	</span>
              </div>
            </div>
        </div>
          </b-card-body>
        </b-card>

					<!--<b-card no-body class="border shadow-none border-radius-1em" @mouseenter="hoverIndex = index" @mouseleave="hoverIndex = -1" >
						
						<b-row no-gutters>
							<transition name="fade-left">
		                    	<div class="col-md-6 position-absolute z-index-2" v-if="hoverIndex == index"> 
                              	<router-link id="tooltip-add"  class="mt-3 ml-3 btn btn-primary font-size-16 btn-rounded ml-auto float-left" :to="'/seller/'+$attrs.product.company.nickname+'/service/'+$attrs.product.slug"><i class="mdi mdi-eye-outline"></i></router-link>
                              </div>
                          </transition>
                          <transition name="fade-right">
		                    	<div class="col-md-6 position-absolute z-index-2 right-0" v-if="hoverIndex == index"> 
                              	<router-link id="tooltip-add"  class="mt-3 mr-2 float-right" :to="'/seller/'+$attrs.product.company.nickname+'/profile'">
                              		<img
                                            		
                                                :src="storageurl + $attrs.product.company.logo.replaceAll('//', '/').replaceAll('https:/'+$attrs.product.company.nickname+'.s3.eu-west-2.amazonaws.com', '')+'?s='+$attrs.product.company.nickname+''"
                                                alt=""
                                                class="avatar-sm rounded-circle"
                                            />
                              	</router-link>
                              </div>
                          </transition>
						 <b-col lg="12" v-if="use == 'public'">
		                        <router-link v-if="typeof $attrs.product.cover_image !== 'string' && $attrs.product.cover_image !== null" :to="linkprefix+($attrs.product.company !== null && use == 'public' ? $attrs.product.company.nickname : '' )+linksuffix+'/'+$attrs.product.slug">
				            	<b-card-img-lazy v-if="'gallery' in $attrs.product.cover_image" :src="storageurl+$attrs.product.cover_image.gallery.replaceAll('//', '/')+'?s='+$attrs.product.company.nickname" :class="{'border-radius-1em' : roundedBorders}"></b-card-img-lazy>
				            	<b-card-img-lazy v-else-if="'thumb' in $attrs.product.cover_image" :src="storageurl+$attrs.product.cover_image.thumb.replaceAll('//', '/')+'?s='+$attrs.product.company.nickname" :class="{'border-radius-1em' : roundedBorders}"></b-card-img-lazy>
				            	<b-card-img-lazy v-else-if="'src' in $attrs.product.cover_image" :src="storageurl+$attrs.product.cover_image.src.replaceAll('//', '/')+'?s='+$attrs.product.company.nickname" :class="{'border-radius-1em' : roundedBorders}"></b-card-img-lazy>
				            	<b-card-img-lazy v-else :src="storageurl+$attrs.product.cover_image.replaceAll('//', '/')+'?s='+$attrs.product.company.nickname" :class="{'border-radius-1em' : roundedBorders}"></b-card-img-lazy>
				            	</router-link>
				            	<router-link v-else :to="linkprefix+($attrs.product.company !== null && use == 'public' ? $attrs.product.company.nickname : '' )+linksuffix+'/'+$attrs.product.slug">
				            	<b-card-img-lazy src="/images/product-placeholder.png" :class="{'border-radius-1em' : roundedBorders}"></b-card-img-lazy>
				            	</router-link>
		                   <transition name="fade-right">

		                         <p  v-if="hoverIndex == index" class="text-muted position-absolute bottom-0 right-0 mr-4 pr-2 mb-3">
		                         	<b-form-rating style="width: 40%;" id="rating-inline" readonly variant="warning" class="bg-transparent pl-4 ml-auto" inline no-border size="sm" :value="$attrs.product.reviews_avg_rating"></b-form-rating> 
			                
			                  </p>
			              			</transition>
		                      </b-col>
		                      <b-col lg="12" v-else >
		                        <a v-if="typeof $attrs.product.cover_image !== 'string' && $attrs.product.cover_image !== null" :href="linkprefix+($attrs.product.company !== null && use == 'public' ? $attrs.product.company.nickname : '' )+linksuffix+'/'+$attrs.product.slug">
				            	<b-card-img-lazy v-if="'gallery' in $attrs.product.cover_image" :src="storageurl+$attrs.product.cover_image.gallery" :class="{'border-radius-1em' : roundedBorders}"></b-card-img-lazy>
				            	<b-card-img-lazy v-else-if="'thumb' in $attrs.product.cover_image" :src="storageurl+$attrs.product.cover_image.thumb" :class="{'border-radius-1em' : roundedBorders}"></b-card-img-lazy>
				            	<b-card-img-lazy v-else-if="'src' in $attrs.product.cover_image" :src="storageurl+$attrs.product.cover_image.src" :class="{'border-radius-1em' : roundedBorders}"></b-card-img-lazy>
				            	<b-card-img-lazy v-else :src="storageurl+$attrs.product.cover_image" :class="{'border-radius-1em' : roundedBorders}"></b-card-img-lazy>
				            	</a>
				            	<a v-else :href="linkprefix+($attrs.product.company !== null && use == 'public' ? $attrs.product.company.nickname : '' )+linksuffix+'/'+$attrs.product.slug">
				            	<b-card-img-lazy src="/images/product-placeholder.png" :class="{'border-radius-1em' : roundedBorders}"></b-card-img-lazy>
				            	</a>
		                     <transition name="fade-right">

		                         <p  v-if="hoverIndex == index" class="text-muted float-right ml-auto my-auto">
		                         	<b-form-rating style="width: 40%;" id="rating-inline" readonly variant="warning" class="bg-transparent pl-4 ml-auto" inline no-border size="sm" :value="$attrs.product.reviews_avg_rating"></b-form-rating> 
			                
			                  </p>
			              			</transition>
		                      </b-col>
		                  </b-row>
						
						
              				
						<b-card-text class="p-4 peach-border-top-5 d-flex flex-column">
							<h3 class="font-size-18 my-auto py-2">{{$attrs.product.name}}</h3>
							<p class="text-dark paragraph-truncate-line2 mb-0" v-html="$attrs.product.short_description"></p>
							<div class="d-flex">
							<span class="badge badge-light text-dark bg-light font-size-14 my-2" v-if="'sales_price' in $attrs.product">
								{{addTax($attrs.product, $attrs.product.sales_price)}}
							</span>
							
						<transition name="fade-right">
							<router-link v-if="hoverIndex == index" class="border-bottom border-primary text-uppercase font-size-12 ml-auto text-dark my-auto py-2" :to="'/seller/'+$attrs.product.company.nickname+'/service/'+$attrs.product.slug">
								See Product <i class="bx bx-chevron-right"></i>
							</router-link>
						</transition>
							</div>
						</b-card-text>
					</b-card>-->

				</router-link>
</template>