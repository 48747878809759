<script type="text/javascript">
	//import Layout from "../layouts/public";
	import { termsConditions } from "../terms"
	export default{
		//components: { Layout },
		data(){
			return{
				terms: termsConditions
			}
		},
		created(){
			window.scrollTo(0,0);
		},
		mounted(){
			window.scrollTo(0,0);
		},
		watch: {
        $route() {
        	this.$nextTick(() => {
        		window.scrollTo(0,0);
        	 });
            //this.$nextTick();
          			
         },
     	}
	}

</script>

<template>
	<div>
			<section class="header-section">
  <div class="container-fullwidth">
    <!--<PageHeader :title="title" :items="items" />-->
    <b-row class="header-row bg-light bg-soft px-4 position-relative" style="min-height: 360px;">
      <b-col md="8" class="d-flex flex-column justify-content-end align-items-center mx-auto">
      	<p class="mb-1 text-muted mx-md-0 section-info-preheader cursive-title">Read our..</p>
        <h1 class="section-info-header section-info-header position-relative text-sm-center">
        	Privacy Policy
        </h1>
        
      </b-col>
      <b-col md="6" class="d-flex flex-column mx-auto">
          </b-col>
    </b-row>

    <!-- end row -->
  </div>
</section>
			<section>
  <div class="container" style="padding: 120px 0 !important;">
	  	<b-row>
		<div v-html="terms.public.privacy">
			
		</div>
		</b-row>
	</div>
</section>
</div>
</template>