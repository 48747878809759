<script type="text/javascript">
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import Stat from "../../components/widgets/stat";
import SubscriptionInvoice from "../../components/widgets/SubscriptionInvoice";
import UpdateSubscription from '../../components/widgets/Updatesubscription';
import AddStripePaymentCard from '../../components/widgets/AddStripePaymentCard';

export default{
	 components: { Layout, PageHeader, Stat, UpdateSubscription, SubscriptionInvoice, AddStripePaymentCard },
	 data() {
   		return {
   			success_message: false,
   			successmessagetext: 'Your action has been successful',
   			payment_methods: '',
   			selected_subscription: '',
   			title: "Settings",
   			settings: "",
   			isCheckAll: false,
   			update_subscription: false,
   			billingportal_url: '',
   			items: [
		        {
		          text: "Company",
		          href: "/"
		        },
		        {
		          text: "Subscriptions",
		          active: true
		        }
		      ]
   		}
   	},
   	methods:{
   		filterSelected(id){
        if(this.selectedids.length > 0){
          var exists = this.selectedids.filter(item => item == id);
          if(exists.length > 0){
            return true;
          }else{
            return false
          }
        }else{
          return false;
        }
      },
      checkAll: function(){ 
                 this.isCheckAll = !this.isCheckAll; 
                 this.selectedids = []; 
                 if(this.isCheckAll){ 
                     // Check all 
                     for (var key in this.users.data) {          
                        this.selectedids.push(this.users.data[key].id); 
                     } 
                 } 
              }, 
      updateCheckall: function(){ 
                   if(this.selectedids.length == this.users.data.length){
                      this.isCheckAll = true; 
                   }else{ 
                      this.isCheckAll = false; 
                   } 
      },
      getPaymentMethods(){
      	axios.get('/api/company/subscription/payment-methods', {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
              }}).then(response => {
      		this.payment_methods = response.data;
      	}).catch(error => {

      	});
      },
      updateDefaultPaymentMethod(method){
      	axios.post('/api/company/subscription/payment-method/default', {pmid: method}, {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
              }}).then(response => {
      		this.payment_methods = response.data;
      	}).catch(error => {

      	});
      },
      cancelSubscription(id, plan_id){
      	axios.post('/api/company/subscription/'+id+'/cancel-subscription', {subscriptionid: plan_id}, {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
        }}).then(response => {
      		if(response.data == 'success'){
      			this.successmessagetext = "Subscription successfully cancelled";
      			this.success_message = true;
      		}
      	}).catch(error => {

      	});
      },
      renewSubscription(id, plan_id){
      	axios.post('/api/company/subscription/'+id+'/renew-subscription', {subscriptionid: plan_id}, {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
        }}).then(response => {
      		if(response.data == 'success'){
      			this.successmessagetext = "Subscription successfully cancelled";
      			this.success_message = true;
      		}
      	}).catch(error => {

      	});
      }
   	},
   	created(){
              axios.get('/api/company/subscription/'+this.$attrs.company.uid+'/billing-portal', {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
              }}).then(response => {
                this.billingportal_url = response.data;
              }).catch(error => {
                this.error = error.data;
              });
   	}
}
</script>
<template>
	<Layout :nickname="$attrs.nickname" :user="$attrs.user"  >
		<PageHeader :title="title" :items="items"  />
			<div class="row">
				<div class="col-md-12">
					<b-alert variant="success" :show="success_message">
						{{successmessagetext}}
					</b-alert>
				</div>
			</div>
			<div class="row">
				<div class="col-xl-3 col-lg-4">
		      	<div class="card border-radius-1em">
		          <div class="card-body">
		            <div class="media d-flex my-1">
		              <div class="avatar-sm mr-4">
		                        <span class="avatar-title rounded-circle" :class="{'bg-danger': $attrs.validsubscription == 0, 'bg-success': $attrs.validsubscription == 1}"><i class="font-size-24 bx" :class="{'bx-error-circle': $attrs.validsubscription == 0, 'bx-check-circle': $attrs.validsubscription == 1}"></i></span>
		                      </div>

		              <div class="media-body overflow-hidden d-flex flex-column my-auto">
		                <span>Subscription Status: </span><h5 class="text-truncate font-size-15 mb-0 mt-auto text-danger" v-if="$attrs.validsubscription == 0">Inactive</h5>
		                <h5 class="text-truncate font-size-15 mb-0 mt-auto text-success" v-else>Active </h5>
		              </div>
		            </div>
		         </div>
		        </div>
		        <div class="card border-radius-1em">
		          <div class="card-body pb-0">
		          	<div class="media d-flex mb-2">
		          	    <div class="avatar-sm mr-4">
		                        <span class="avatar-title rounded-circle"><i class="font-size-24 bx bx-credit-card-alt"></i></span>
		                      </div>
		                  <div class="media-body overflow-hidden d-flex flex-column my-auto">
		             	 <h4 class="card-title my-auto">Payment Details</h4>
		             	</div>
		             </div>
		        	</div>
		        	<div v-if="$attrs.validsubscription == 1">
			           	<ul class="list-group list-group-flush">
			           		<li class="list-group-item">Customer ID: <span class="text-capitalize float-right">{{$attrs.company.stripe_id}}</span></li>
			           		<li class="list-group-item">Card Type: <span class="text-capitalize float-right">{{$attrs.company.card_brand}}</span></li>
			           		<li class="list-group-item">Card Number: <span class="text-capitalize float-right">XXXX XXXX XXXX {{$attrs.company.card_last_four}}</span></li>
			           	</ul>
		           	</div>
		           	<div v-else>
			           	<div class="card-body mt-0">
			           		<ul class="list-group list-group-flush">
				           		<li class="list-group-item bg-danger text-white">No Subscription Added</li>
				           	</ul>
				        </div>
		           	</div>
		            <!--<SearchCompanyUser :api_token="this.$attrs.user.api_token" :id="department.manager.user" @user-selected="getManager" @input="getManager" />-->
					<a class="btn btn-light m-4" v-b-modal.payment-methods @click="getPaymentMethods()">Update Payment Method</a>
		          </div>
		          <div class="card border-radius-1em">
		          	<div class="card-body">
		          	<div class="media d-flex mb-2">
		          	    <div class="avatar-sm mr-4">
		                        <span class="avatar-title bg-dark rounded-circle"><i class="font-size-24 bx bx-server"></i></span>
		                      </div>
		                  <div class="media-body overflow-hidden d-flex flex-column my-auto">
		             	 <h4 class="card-title my-auto">Storage</h4>
		             	</div>
		             </div>
		        	<div class="row p-4">
		        		<div class="col-6"><h5 class="font-size-15"> GB</h5><p class="text-muted mb-0">Used</p></div><div class="col-6"><h5 class="font-size-15">20 GB</h5><p class="text-muted mb-0">Total</p></div>
		        		<div class="col-md-12">
		        			<b-progress :value="25" :max="100" class="mt-4" variant="success"></b-progress>
		        		</div>

		        	</div>
		        	<a class="btn btn-light btn-block">Add Storage</a>
		        </div>
		    </div>
        </div>
			<div class="col-xl-9 col-lg-8">
				<div class="row">
		        	<div class="col-lg-4">
			          <Stat :icon="'bx bx-group'" :title="'Users'" :value="$attrs.subscriptiondetails.quantity" />
			        </div>
			        <div class="col-lg-4">
		        		<!--<Stat :icon="'bx bx-group'" :title="'Period'" :value="0" />-->
										<div class="card mini-stats-wid border-radius-1em">
				    <div class="card-body">
				      <div class="media">
				        <div class="media-body">
				          <p class="text-muted font-weight-medium mb-3" v-html="'Plan'"></p>
				          <h5 v-if="'items' in $attrs.subscriptiondetails && $attrs.subscriptiondetails.items.length" class="mb-0 d-inline" v-html="$attrs.subscriptiondetails.items[0].plan.name"></h5>
				          <span v-if="'items' in $attrs.subscriptiondetails && $attrs.subscriptiondetails.items.length" class="badge badge-light bg-light"> {{$attrs.subscriptiondetails.items[0].plan.payment_term}} </span>
				          <span v-if="$attrs.subscriptiondetails.stripe_status !== 'active'" class="badge badge-info bg-info">{{$attrs.subscriptiondetails.stripe_status}}</span>
				        </div>

				        <div class="mini-stat-icon avatar-sm align-self-center rounded-circle bg-primary">
				          <span class="avatar-title" >
				            <i :class="`bx bx-receipt font-size-24`"></i>
				          </span>
				        </div>
				     
				      </div>
				    </div>
    <!-- end card-body -->
 					 </div>
			        </div>
			        <div class="col-lg-4">
		        		<Stat v-if="$attrs.subscriptiondetails.ends_at == null" :icon="'bx bx-calendar-alt'" :title="'Valid Until'" :value="'Ongoing'" />
		        		<Stat v-else :icon="'bx bx-calendar-alt'" :title="'Cancels on'" :bgcolor="'bg-danger'" :value="$attrs.subscriptiondetails.ends_at | luxon({input: 'server'})" />
		        		
			        </div>
		        </div>
		        
				<div class="card border-radius-1em" v-if="update_subscription == false">
					<div class="card-body pb-0">
		          	<div class="media d-flex mb-2">
		          	    <div class="avatar-sm mr-4">
		                        <span class="avatar-title rounded-circle bg-secondary"><i class="font-size-24 bx bx-history"></i></span>
		                      </div>
		                  <div class="media-body overflow-hidden d-flex flex-row my-auto">
		             	 <h4 class="card-title my-auto">Current Subscriptions</h4>
		             	 <a class="ml-auto btn btn-light" @click="update_subscription = !update_subscription">Add/Remove Users</a>
		             	 <a class="ml-2 btn btn-light" :href="billingportal_url" target="_blank">Visit Billing Portal <i class="bx bx-link-external"></i></a>
		             	</div>
		             </div>
		        	</div>
					<div class="row p-4">
						<div class="col-md-12 p-4">
							
				            <div class="table-responsive">
				              <table class="table mb-0">
				                <thead>
				                  <tr>
				                    <th scope="col">#Subscription Details</th>
				                    <th>Unit Price</th>
				                    <th>Quantity</th>
				                    <th>User Total</th>
				                    <th>Flat Fee</th>
				                    <th>Actions</th>
				                  </tr>
				                </thead>
				                <tbody v-for="subscription in $attrs.company.subscriptions">
				                  <tr v-for="sub_item in subscription.items" valign="middle">
				                    <td scope="row">
				                    	<div class="d-flex flex-column">
						                    <strong>{{subscription.stripe_id}}</strong>
						                    <span class="mt-2">Plan Type: {{sub_item.plan.name}}</span>
						                    <span>Term: <span class="badge badge-primary bg-primary mr-auto text-capitalize">{{sub_item.plan.payment_term}}</span> <span class="badge badge-info bg-info mr-auto text-capitalize">{{subscription.stripe_status}}</span></span>
				                  		</div>
				                  </td>
				                    <td>£{{sub_item.plan.price_exc}} <small class="text-muted"><i>Price Exc.</i></small><hr class="my-2">£{{sub_item.plan.price_inc}} <small class="text-muted"><i>Price Inc.</i></small></td>
				                    <td><span class="m-auto">{{subscription.quantity}}</span></td>
				                    <td class="text-center">£{{(sub_item.plan.price_exc * subscription.quantity).toFixed(2)}}<small class="text-muted"><i>Price Exc.</i></small><hr class="my-2">£{{(sub_item.plan.price_inc * subscription.quantity).toFixed(2)}} <small class="text-muted"><i>Price Inc.</i></small></td>
				                    
				                    	<td class="text-center">£{{(sub_item.plan.flat_price_inc).toFixed(2)}} <small class="text-muted"><i>Price Inc.</i></small></td>
				                    <td>
				                    	 <ul class="list-inline font-size-20 contact-links mb-0">
					                        <li class="list-inline-item pr-1">
					                          <a v-b-tooltip.hover title="View" :href="'javascript:;'">
					                            <i class="mdi mdi-eye-outline"></i>
					                          </a>
					                        </li>
					                        <!--<li class="list-inline-item pr-1">
					                          <a v-b-tooltip.hover title="Reports">
					                            <i class="bx bx-line-chart"></i>
					                          </a>
					                        </li>-->

					                        <li class="list-inline-item pr-1" >
					                          <a v-b-tooltip.hover title="Preview Invoice" @click="selected_subscription = subscription, $bvModal.show('invoice-modal')">
					                            <i class="bx bx-download"></i>
					                          </a>
					                        </li>
					                        <li class="list-inline-item pr-1" >
					                          <a v-b-tooltip.hover v-b-modal.cancel-modal title="Cancel Subscription" @click="selected_subscription = subscription">
					                            <i class="bx bx-trash"></i>
					                          </a>
					                        </li>
					                      </ul>

				                    </td>
				                  </tr>
				                </tbody>
				              </table>
				            </div>
						</div>

					</div>
				</div>
				<div class="card" v-if="update_subscription == true">
					<div class="card-body pb-0">
		          	<div class="media d-flex mb-2">
		          	    <div class="avatar-sm mr-4">
		                        <span class="avatar-title rounded-circle bg-secondary"><i class="font-size-24 bx bx-user"></i></span>
		                      </div>
		                  <div class="media-body overflow-hidden d-flex flex-row my-auto">
		             	 <h4 class="card-title my-auto">Add/Remove Users</h4>
		             	 <a class="ml-auto btn btn-light" @click="update_subscription = !update_subscription">Subscription History <i class="bx bx-history"></i></a>
		             	</div>
		             </div>
		        	</div>
				<UpdateSubscription :company="this.$attrs.company" :user="this.$attrs.user" :payment_term="this.$attrs.subscriptiondetails.items[0].plan.payment_term" :user_quantity="this.$attrs.subscriptiondetails.quantity" />
				</div>
			</div>
		</div>
		<b-modal id="cancel-modal"  centered content-class="border-radius-1em" header-class="border-none" hide-footer>
			<b-card class="border-radius-1em border shadow-none">
				<h5 class="text-center">Cancel Subscription</h5>
				<p class="text-center">You are about to cancel the following subscription:</p>

				<b-card class="border-radius-1em border shadow-none">
					<b-list-group flush >
				                  <b-list-group-item v-if="selected_subscription.ends_at == null" v-for="sub_item in selected_subscription.items" valign="middle">
				                   
				                    	<div class="text-center">
						                    {{selected_subscription.stripe_id}}
						                  </div>
						                  <div class="text-center mb-3">
						                    <span class="mt-2">Plan Type: {{sub_item.plan.name}}</span>
						                    <span>Term: <span class="badge badge-primary bg-primary mr-auto text-capitalize">{{sub_item.plan.payment_term}}</span></span>
				                  		</div>
				               
				                   		<div class="text-center">£{{sub_item.plan.price_exc}} <small class="text-muted"><i>Price Exc.</i></small><hr class="my-2">£{{sub_item.plan.price_inc}} <small class="text-muted"><i>Price Inc.</i></small></div>
				                    <div class="text-center my-3"><span class="m-auto">for {{selected_subscription.quantity}} users</span></div>
				                    <div class="text-center">£{{(sub_item.plan.price_exc * selected_subscription.quantity).toFixed(2)}}<small class="text-muted"><i>Price Exc.</i></small><hr class="my-2">£{{(sub_item.plan.price_inc * selected_subscription.quantity).toFixed(2)}} <small class="text-muted"><i>Price Inc.</i></small></div>
				                    <div class="text-center"><hr class="my-2">£{{(sub_item.plan.flat_price_inc).toFixed(2)}} <small class="text-muted"><i>Flat Fee</i></small></div>
				                    </div>
				                    
				                  </b-list-group-item>
				                  <b-list-group-item v-else>
				                  	<p>Cancels On:</p>
				                  	<h5 class="text-primary text-center">
				                  	{{$attrs.subscriptiondetails.ends_at | luxon({input: 'server'})}}
				                  	</h5>
				                  </b-list-group-item>
				                </b-list-group>
				                

				</b-card>
				
				<div class="d-flex w-100" v-if="selected_subscription.ends_at == null">
					<a class="btn btn-success btn-block mr-1" @click="cancelSubscription(selected_subscription.id, selected_subscription.stripe_plan)">
						Cancel Subscription
					</a>
					<a class="btn btn-danger btn-block ml-1" @click="$bvModal.hide('cancel-modal')">
						Close
					</a>	
				</div>
				<div class="d-flex w-100" v-else>
					<a class="btn btn-success btn-block mr-1" @click="renewSubscription(selected_subscription.id, selected_subscription.stripe_plan)">
						Resume Subscription
					</a>
					<a class="btn btn-danger btn-block ml-1" @click="$bvModal.hide('cancel-modal')">
						Close
					</a>	
				</div>
			</b-card>
		</b-modal>
		<b-modal id="payment-methods"  centered content-class="border-radius-1em" header-class="border-none" hide-footer>
			<b-card class="border-radius-1em border shadow-none">
				<h5 class="text-center">Payment Methods</h5>

				<b-list-group >
					<b-list-group-item v-for="method in payment_methods">
						<div v-if="'card' in method" class="w-100 d-flex">
						<span class="my-auto"><i class="bx bx-credit-card text-primary"></i> {{method.card.brand}}</span>
						<span class="badge badge-light bg-light my-auto ml-auto">
							####-####-####-{{method.card.last4}}
						</span>
						<span class="badge badge-light bg-light my-auto ml-2">
						EXP: {{method.card.exp_month}} / {{method.card.exp_year}}
						</span>
						<a class="btn btn-light btn-sm ml-3 btn-rounded" @click="updateDefaultPaymentMethod(method.id)">
							Use Card
						</a>
						</div>
						
					</b-list-group-item>
				</b-list-group>
				<a class="btn btn-light btn-rounded btn-block mt-2" @click="$bvModal.show('add-payment'), $bvModal.hide('payment-methods')">
					Add New Payment Method
				</a>
			</b-card>
		</b-modal>
		<b-modal id="add-payment" centered content-class="border-radius-1em" header-class="border-none" hide-footer>
			<AddStripePaymentCard :user="$attrs.user" :company="$attrs.company" @cancel-payment="$bvModal.show('payment-methods'), $bvModal.hide('add-payment')"></AddStripePaymentCard>
		</b-modal>
		<b-modal id="invoice-modal" size="xl" centered content-class="border-radius-1em" header-class="border-none" hide-footer>
			<SubscriptionInvoice :printable="false" :user="$attrs.user" :company="$attrs.company" :subscription="selected_subscription"></SubscriptionInvoice>
		</b-modal>
	</Layout>
</template>