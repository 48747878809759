<script>
	export default{
		props:{
			expandable: Number
		},
		methods:{
			closePanel(){
				this.$emit('close-modal');
				
			},
			expandPanel(){
				this.$emit('expand');
				this.expanded = true;

			},
			contractPanel(){
				this.$emit('contract');
				this.expanded = false;
			},
		},
		data(){
			return{
				name: 'modal-window',
				expanded: false,
			}
		},
		mounted(){
			//document.getElementsById('#app').addClass('newClass');
			//document.getElementById("html").className = "no-scroll";
		},
	}
</script>
<template>
	      <div :class="$attrs.panelClass" v-if="$attrs.editModal == true" >
	      	<b-container fluid>
	      		
	      		<b-row>
	      			<div class=" d-flex flex-column px-2 product-grid-item mb-2 mx-auto col-md-8 col-sm-10">
                                <div class="border shadow-sm product-grid-item-container bg-white row">
                                  <b-row class="mx-0 px-0">
                                  	<b-col md="12">
					      				
					        				<a href="javascript:;" @click="closePanel" class="btn btn-rounded btn-outline-light float-right m-2"><i class="mdi mdi-close noti-icon font-size-20"></i></a>
					        		</b-col>
								      <b-col md="12">
				        			<div v-if="$attrs.formResponse != null">
				        				{{$attrs.formResponse}}
				        			</div>
				        		</b-col>
				        		<b-col md="12" class="mx-0 px-0">
				        			<slot />
				        		</b-col>
                                  </b-row>
                              
                                </div>
                    </div>
	      			
	        		
	        	</b-row>
	        </b-container>
	      </div>
</template>