<script type="text/javascript">
	//import DefaultContentProductCard from "./DefaultContent-ProductCard";
	//import ImageSwiper from "../../../../../components/widgets/gallery/ImageSwiper";
	import VueEasyLightbox from "vue-easy-lightbox";
	import { EventBus } from "../../../app";

	export default{
		data(){
			return {
				quantity: 1,
				previewImgBtns: false,
				showLightbox: false,
				imgView: null,
				imgViewIndex: 0,
				hoverIndex: -1,
				hoverLink: -1,
				lightboxImages: [],
				product: [],
				routearray: [],
				storageurl: 'https://'+this.$attrs.company.nickname+'.s3.eu-west-2.amazonaws.com',
				imgswiperOptions: {
		          slidesPerView: 5,
		          spaceBetween: 10,
		          pagination: {
		            el: '.swiper-pagination',
		            clickable: true
		          },
		          navigation: {
		            nextEl: '.swiper-button-next',
		            prevEl: '.swiper-button-prev'
		          }

		        },
			}
		},
		props: {
			company: {
				type: Object
			},
			resource: {
				type: String,
				default: () => 'product'
			},
			product: {
				type: Object
			}
		},
		components: {
			//ImageSwiper,
			VueEasyLightbox
		},
		created(){
			//this.getResource(this.resource);
		},
		methods: {
			addToCart(product, quantity){
		    	EventBus.$emit('add-to-cart', product, quantity);
		    },
			getResource(resource){
				this.routearray = this.$route.path.substring(1).split('/');
				axios.get('/website-api/widget/'+resource+'/'+this.routearray[this.routearray.length-1]).then(response => {
					this.product = response.data;
					
				}).catch(error => {
					this.product = [];
					console.log(error);
				});
			},
			imageSelected(img){
		    	if(this.imgView == null){
		    		this.product.images.unshift(this.product.cover_image);
		    	}
		    	this.imgView = img;
		    },
		    setUpLightbox(){
		    	//this.product.images = JSON.parse(JSON.stringify(this.product.images).replaceAll('caption', 'title'));
		    		var image = {};
		    		image["src"] = ''+this.storageurl+''+this.product.cover_image.src.replaceAll('//', '/')+'';
		    		image["title"] = this.product.cover_image.caption;
		    		this.lightboxImages.push(image);
		    	for(var i = 0; i < this.product.images.length; i++){
		    		var image = {};
		    		image["src"] = ''+this.storageurl+''+this.product.images[i].src.replaceAll('//', '/')+'';
		    		image["title"] = this.product.images[i].caption;
		    		console.log(image);
		    		this.lightboxImages.push(image);
		    		if(this.imgView == this.product.images[i]){
		    			this.imgViewIndex = (this.lightboxImages.length - 1);
		    		}
		    	}
		    	

		    },
		},
		  watch: {
        $route() {
        	this.$nextTick(() => {
        		 //this.loadingPage = true;
        		 this.getResource(this.resource);

        		});
        	}
        }
	}

</script>
<template>
	<b-container fluid class="py-4 my-4 default-content">
		<vue-easy-lightbox
                :visible="showLightbox"
                :imgs="lightboxImages"
                :index="imgViewIndex"
                @hide="showLightbox = !showLightbox"
              ></vue-easy-lightbox>
		<b-row class="mb-4">
			<b-col md="6" class="px-3 overflow-hidden">
				<a class="position-relative d-block" @mouseenter="previewImgBtns = true" @mouseleave="previewImgBtns = false" @click="setUpLightbox(), showLightbox = !showLightbox">
				<img v-if="(product.cover_image !== null) && (imgView == null)" :src="storageurl + product.cover_image.src.replaceAll('//', '/')" class="img-fluid w-100 border mb-3" >
				<img v-else-if="imgView !== null" :src="storageurl + imgView.src.replaceAll('//', '/')" class="img-fluid w-100 border mb-3"> 
				<img v-else src="/images/product-placeholder.png" class="img-fluid w-100 border mb-3">
				<transition name="fade-right">
				   <div class="position-absolute top-0 right-0 mt-3 mr-3 rounded" v-if="previewImgBtns == true">
	                          	<a class="btn btn-primary rounded-circle bg-peach-soft" href="javascript:;">
	                          		<i class="bx bx-search"></i>
	                          	</a>
	                </div>
	            </transition>
				</a>
				<ImageSwiper :swiperOptions="imgswiperOptions" :items="product.images" @image-selected="imageSelected" :imgClass="'border'" ></ImageSwiper>
			</b-col>
			<b-col md="6" class="px-3">
				<p class="mb-2 text-muted font-size-14">REFERENCE: {{product.reference}}</p>
				<h1 class="text-capitalize mb-3">{{product.name}}</h1>
				<div class="d-flex">
				<b-form-rating id="rating-inline" variant="warning" inline no-border size="lg" class="mb-2"></b-form-rating>
				<a class="">
					<i class="bx bx-share-alt font-size-20"></i>
				</a>
				</div>
				<p class="mt-3" v-html="product.short_description"></p>
				<h4 class="font-size-28">{{product.sales_price}}</h4>
				<b-list-group flush>
					<b-list-group-item >

					</b-list-group-item>
				</b-list-group>
			
				
				<div class="d-flex mb-2">
					 <b-button variant="-light" :disabled="quantity < 2" id="tooltip-add" @click="quantity--" class="btn btn-light font-size-16 btn-rounded w-25 mr-1 my-auto"><i class="mdi mdi-minus"></i></b-button>
					  <b-form-group label="" class="w-50 my-auto mx-1">
						<b-form-input type="number" v-model="quantity" size="lg" /></b-form-group>
					  <b-button variant="-light" :disabled="quantity >= product.total_qty " @click="quantity++" class="btn btn-light font-size-16 btn-rounded w-25 my-auto m1-1"><i class="mdi mdi-plus"></i></b-button>
				</div>
				<div class="d-flex">
					<b-button variant="primary" class="btn-rounded btn-block w-100 text-white btn-lg" @click="addToCart(product, quantity)">
					Add to Cart
					</b-button>	
					<!--<a class="btn btn-primary my-auto btn-rounded w-75"  >Add to Cart</a>-->
				</div>
			</b-col>
		</b-row>
		<b-row>
			<b-col md="12" class="d-flex mt-4">
				<h2 class="product-info-header">More About This Product</h2>
				<hr class="mb-4 flex-grow-1">
			</b-col>
			<b-col md="8" class="offset-md-4">
				<p class="mt-3" v-html="product.long_description"></p>
			</b-col>
			<b-col md="12" class="d-flex mt-4">
				<h2 class="product-info-header">Features</h2>
				<hr class="mb-4 flex-grow-1">
			</b-col>
			<b-col md="8" class="offset-md-4">
				<p class="mt-3" v-html="product.long_description"></p>
			</b-col>
			<b-col md="12" class="d-flex mt-4">
				<h2 class="product-info-header">Reviews</h2>
				<hr class="mb-4 flex-grow-1">
			</b-col>
			<b-col md="8" class="offset-md-4">
				<p class="mt-3" v-html="product.long_description"></p>
			</b-col>
		</b-row>
		<b-row>
			<b-col md="12" class="d-flex mt-4">
				<h2 class="product-info-header">Related Products</h2>
				<hr class="mb-4 flex-grow-1">
			</b-col>
			<b-col md="8" class="offset-md-4">
				<p class="mt-3" v-html="product.long_description"></p>
			</b-col>
		</b-row>
		<b-row class="mt-4 py-4 bg-peach-soft">
			<b-col md="12" class="mt-4">
				<h2 class="text-center">Related Products</h2>
			</b-col>
		</b-row>
	</b-container>
</template>


<style scoped >
#rating-inline{
	display: inline !important;
    padding: 0;
}
#rating-inline .b-rating-star{
	padding: 0 5px 0 0 !important;
}
</style>