<script>
import Layout from '../layouts/main'
import PageHeader from '../components/page-header'
import DateTime from 'luxon/src/datetime.js';
import SideSlidePanel from "../components/widgets/SideSlidePanel"
import SearchBar from "../components/widgets/searchBar"
import EditModal from "../components/widgets/editModal"
/**
 * Shops component
 */
export default {
  components: { Layout, PageHeader, SideSlidePanel, SearchBar, EditModal, DateTime },
  data() {
    return {
      shopsData: '',
      productsData: [],
      showListingProducts: 0,
      addProductToListing: 0,
      selectedListing:[],
      searchproduct: '',
      shops_error: [],
      company_info: [],
      categoryProducts: [],
      productsLimit: '100',
      productsCurrentPage: 1,
      productListingSelected: [],
      newquantity: 0,
      listingquantity: 0,
      transferquantity: 0,
      currentPage: 1,
      orderDesc: true,
      priceRange: [0,999999],
      trade_productsactive: '',
      trade_active: '',
      public_active: '',
      public_productsactive: '',
      totalListingsQuantity: 0,
      editModal: 0,
      productsactive: '',
      brands: '',
      collections: '',
      active: '',
      view: 'list',
      search: '',
      limit: '25',
      sortby: 'created_at',
      order: 'desc',
      isCheckAll: false,
      selectedids: [],
      addNewPanel: 0,
      isCustom: 0,
      dropzoneOptions: {
            url: 'https://httpbin.org/post',
            thumbnailHeight: 300,
            thumbnailWidth: 300,
          },
          limits: [
            {value: 25, text: "25"},
            {value: 50, text: "50"},
            {value: 100, text: "100"},
          ],
          transferOptions: [
            {value: 0, text: "Company Stock"},
            {value: 1, text: "Another Shop or Listing"},
          ],
          transferOption: 0,
      title: 'Orders',
      items: [
        {
          text: 'Orders',
          active: true,
        },
      ],
    }
  },
  methods: {
    brandQuery(){
      axios.get('/api/company/listings?page='+this.currentPage+'&limit='+this.limit+'&sortby='+this.sortby+'&order='+this.order+'&search='+this.search, {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
              }}).then(response => {
        this.shopsData = response.data;
      }).catch(error => {
        this.shops_error = error.data;
      });
    },
    getProducts(id){
      axios.get('/api/company/listing/'+id+'/products').then(response => {
        this.categoryProducts = response.data;
        //this.showListingProducts = 1;
      }).catch(error => {
        this.products_error = error.data;
      });
    },
    getSellerProducts(search_query){
      if(this.orderDesc == true){
        this.order = 'desc';
      }else{
        this.order = 'asc';
      }
      axios.get('/api/company/orders?page='+this.productsCurrentPage+'&limit='+this.productsLimit+'&sortby='+this.sortby+'&order='+this.order+'&search='+search_query+'&trade_active='+this.trade_productsactive+'&public_active='+this.public_productsactive+'&active='+this.productsactive+'&brands='+this.brands+'&collections='+this.collections+'&pricerange='+this.priceRange, {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
        console.log(response);
        this.productsData = response.data.data;
      });
    },
    closeEditModal(){
      this.$refs['edit-modal'].hide()
    },
    addToListing(product){
      this.productListingSelected = product;
      this.selectedProductListingInfo = product.listings;
      if(this.productListingSelected.listings.length > 0){
        var listing_qty = this.productListingSelected.listings.filter(item => (item.listing_id == this.selectedListing.id) && (item.product_id == this.productListingSelected.id));
        if(listing_qty.length > 0){
          this.listingquantity = listing_qty[0].quantity;
        }else{
          this.listingquantity = 0;
        }
      }else{
         this.listingquantity = 0;
      }
      this.$refs['edit-modal'].show();
    },
    getListingData(listing_id){
        return this.shopsData.data.filter(item => item.id == listing_id);
    },
    priceIncVat(price, tax_percentage){
      var tax = 1 + (tax_percentage / 100);
      return price * tax;
    },
     productQuery(){
      if(this.productsData != ''){
        if(this.limit > this.productsData.total){
          this.currentPage = 1;
        }
      }
      if(this.orderDesc == true){
        this.order = 'desc';
      }else{
        this.order = 'asc';
      }
      axios.get('/api/company/orders?page='+this.currentPage+'&limit='+this.limit+'&sortby='+this.sortby+'&order='+this.order+'&search='+this.search+'&trade_active='+this.trade_active+'&public_active='+this.public_active+'&active='+this.active+'&brands='+this.brands+'&collections='+this.collections+'&pricerange='+this.priceRange, {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
        console.log(response);
        this.shopsData = response.data;
      });
    },
    getDate(date){
        var createdOn = DateTime.fromISO(date, { zone: 'utc'});
        console.log(createdOn);
        return createdOn.day+' '+createdOn.monthLong+' '+createdOn.weekYear+'';
      },
      filterSelected(id){
        if(this.selectedids.length > 0){
          var exists = this.selectedids.filter(item => item == id);
          if(exists.length > 0){
            return true;
          }else{
            return false
          }
        }else{
          return false;
        }
      },
      checkAll: function(){ 
                 this.isCheckAll = !this.isCheckAll; 
                 this.selectedids = []; 
                 if(this.isCheckAll){ 
                     // Check all 
                     for (var key in this.shopsData.data) {          
                        this.selectedids.push(this.shopsData.data[key].id); 
                     } 
                 } 
              }, 
      updateCheckall: function(){ 
                 if(this.selectedids.length == this.shopsData.data.length){
                    this.isCheckAll = true; 
                 }else{ 
                    this.isCheckAll = false; 
                 } 
      },
  },
  computed:{
    allProductListingQuantities: function(){
      if(this.productListingSelected.length !== 0){
          if(this.productListingSelected.listings.length > 0){
            this.totalListingsQuantity = 0;
            for(var i = 0; i < this.productListingSelected.listings.length; i++){
              //console.log(this.productListingSelected.listings[i].quantity);
              this.totalListingsQuantity = Number.parseFloat(this.totalListingsQuantity) + Number.parseFloat(this.productListingSelected.listings[i].quantity);
            }
            return this.totalListingsQuantity;
          }else{
            this.totalListingsQuantity = 0;
            return 0;
          }
      }else{
        return 0;
      }
    },
  },
  created(){
    //console.log(localStorage.getItem('default_company'));
    this.company_info = JSON.parse(localStorage.getItem('default_company'));
    this.productQuery();
  },
  mounted(){

  },
}
</script>

<template>
  <Layout  :nickname="$attrs.nickname" :user="$attrs.user" :menuItems="$attrs.menuItems">
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-md-12">
        <div class="row mb-3">
          <div class="col-xl-1 col-sm-6">
            <div class="mt-2">
              <b-form-select class="form-control" v-model="limit" :options="limits" @input="productQuery()"></b-form-select>
            </div>
          </div>
         
          <div class="col-lg-2 col-sm-6 d-flex">
          <form class="my-auto">
            <small class="mx-auto my-2 text-center d-block">Results {{productsData.from}} - {{productsData.to}} of {{productsData.total}}</small>
          </form>
        </div>
        <div class="col-lg-5 col-sm-6 d-flex">
            <div class="mt-sm-0 form-inline w-100" >
              <div class="search-box mr-2 ml-auto">
                <div class="position-relative">
                  <input
                    type="text"
                    class="form-control w-100 border-0"
                    placeholder="Search name, reference or description..."
                    v-model="search"
                    @keyup.enter="searchFilter($event)"
                  />
                  <i class="bx bx-search-alt search-icon"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-sm-6 d-flex">
            <div class="d-flex ml-auto">
              <ul class="nav nav-pills product-view-nav">
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': showFilters == true }" href="javascript: void(0);" @click="showFilters = !showFilters">
                    <i class="bx bx-filter-alt"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': view == 'grid' }" href="javascript: void(0);" @click="view = 'grid'">
                    <i class="bx bx-grid-alt"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': view == 'list' }" href="javascript: void(0);" @click="view = 'list'">
                    <i class="bx bx-list-ul"></i>
                  </a>
                </li>
                <li class="nav-item">
                 <b-dropdown text="Bulk Actions" variant="primary" class="rounded">
                  <b-dropdown-item href="javascript: void(0);">Action</b-dropdown-item>
                  <b-dropdown-item href="javascript: void(0);">Another action</b-dropdown-item>
                  <b-dropdown-item href="javascript: void(0);">Something else here</b-dropdown-item>
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-item href="javascript: void(0);">Separated link</b-dropdown-item>
                </b-dropdown>
                </li>
                <li class="nav-item">
                 <a
                    class="btn btn-success btn-rounded mb-2 mr-2 text-white"
                    href="product/add"
                  >
                    <i class="mdi mdi-plus mr-1"></i> New
                  </a>
                </li>
              </ul>
              <div class="text-sm-right">
                  
                </div>
          </div>
        </div>
      </div>
        <div class="card" v-if="view == 'list'" >
          <div class="card-body p-0">
            <div class="table-responsive">
              <table class="table table-centered table-hover">

                <thead class="thead-light">
                  <tr>
                    <th scope="col">
                      <div
                        class="form-check form-switch form-switch-sm mb-0 mx-auto p-0 mt-auto d-flex"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input mx-auto my-auto" 
                          type="checkbox"
                          id="SwitchCheckSizesm"
                         
                          v-model="isCheckAll"
                          @click='checkAll()'
                          name="check-all"
                        />
                    </div>
                    </th>
                    <th scope="col"><a :class="{'text-dark': sortby != 'order_no'}" href="javascript: void(0);" @click="orderDesc = !orderDesc,  sortby = 'order_no', productQuery()">Order No. <i v-if="orderDesc == true" class="bx bx-caret-up"></i><i v-if="orderDesc == false" class="bx bx-caret-down"></i></a></th>
                    <th scope="col">Customer</th>
                    <th scope="col">Source</th>
                    <th scope="col">Products</th>
                    <th scope="col">Date</th>
                    <th scope="col">Total Exc.</th>
                    <th scope="col">Total Inc.</th>
                    <th scope="col">Paid</th>
                    <th scope="col">Payment Method</th>
                    <th scope="col">Status</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
               
                <tbody>
                  
                  <tr v-for="list in shopsData.data" :key="list.id" valign="middle">
                    <td>
                    <div class="text-center" >
                       <b-form-checkbox class="select-row-btn" v-model="selectedids" :value="list.id" button-variant="light" @input="updateSelected" button>

                          <i v-if="filterSelected(list.id)" class="bx bx-check"></i><span class="avatar-title rounded-circle bg-light text-primary avatar-sm" v-else>{{(list.reference).slice(0,1)}}</span></b-form-checkbox>
                    </div> 
                    </td>
                    <td>
                      <a :href="'/order/'+list.uid+''"><span>#{{list.reference}}</span></a>
                    </td>
                   
                    
                    <td>
                      <div v-if="list.trade_orders.length > 0">
                        <a :href="'/seller/profile/'+list.trade_orders[0].order.customer.nickname+''"><h6 class="mb-0">{{list.trade_orders[0].order.customer.name}}</h6>
                        {{list.trade_orders[0].order.customer.email}}</a>
                      </div>
                      <div v-if="list.customer_orders.length > 0">
                         <a :href="'/customer/details/'+list.customer_orders[0].order.customer.id+''"><h6 class="mb-0">{{list.customer_orders[0].order.customer.name}} {{list.customer_orders[0].order.customer.lastname}}</h6>
                        {{list.customer_orders[0].order.customer.email}}</a>
                      </div>
                      
                    </td>
                     <td>
                       <div v-if="list.trade_orders.length > 0">
                       <span class="font-size-12 badge badge-light bg-light text-dark"><i class="bx bx-transfer-alt text-primary"></i> Trade Marketplace</span>
                      </div>
                      <div v-if="list.customer_orders.length > 0">
                        <span class="font-size-12 badge border border-primary text-primary"><i class="bx bxs-user text-primary"></i> Public Marketplace</span>
                      </div>
                    </td>
                    <td>
                      <a href="javascript:;" v-b-tooltip.hover title="Stock" placement="left" @click="selectedListing = list, getProducts(list.id), showListingProducts = 1" class="btn btn-outline-light btn-rounded"><i class="bx bx bx-purchase-tag-alt"></i></a>
                    </td>
                     <td>
                     <i class="bx bx-calendar"></i> {{getDate(list.created_at)}}
                    </td>
                     <td>
                      {{list.total_products_exc}}
                    </td>
                    <td>
                      {{list.total_products_inc}}
                    </td>
                     <td class="text-center">
                      <i v-if="list.order_paid == 1" class="bx bx-check-circle text-success font-size-18 mx-auto"></i>
                      <i v-else class="bx bx-x-circle text-danger font-size-18 mx-auto"></i>
                    </td>
                    <td>
                       
                    </td>
                    <td>
                    </td>
                    <td>
                      <ul class="list-inline font-size-20 contact-links mb-0">
                        <li class="list-inline-item"><a v-b-tooltip.hover title="Edit" placement="left" class="btn btn-outline-light btn-rounded"><i class="bx bx-edit-alt"></i>
                          </a>
                        </li>
                      <li class="list-inline-item">
                        <a href="javascript:;" v-b-tooltip.hover title="View" placement="left" @click="selectedListing = list, getProducts(list.id), getProductsPanel = 1" class="btn btn-outline-light btn-rounded"><i class="mdi mdi-eye-outline"></i></a>
                      </li>
                        <li class="list-inline-item">
                           <b-dropdown v-b-tooltip.hover title="More Options" placement="left" dropleft class="more-options-dropdown btn btn-outline-light btn-rounded">
                        <template v-slot:button-content>
                           <i class="bx bx-dots-horizontal-rounded"></i>
                        </template>
                        <b-dropdown-item>Action</b-dropdown-item>
                        <b-dropdown-item>Another Action</b-dropdown-item>
                        <b-dropdown-item>Something else here</b-dropdown-item>
                      </b-dropdown>
                        </li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <b-pagination
              v-if="shopsData.last_page > 1"
              class="justify-content-center"
              pills
              v-model="currentPage"
              :total-rows="shopsData.total"
              :per-page="shopsData.per_page"
              aria-controls="my-table"
              @input="productQuery"
            ></b-pagination>
             <small class="mx-auto my-2 text-center d-block">Records {{shopsData.from}} - {{shopsData.to}} of {{shopsData.total}} // Page {{shopsData.current_page}} of {{shopsData.last_page}}</small>
              </div>
            </div>
          </div>
      </div>
    <div class="row" v-if="view == 'grid'" >
     <div class="col-xl-4"  v-for="list in shopsData.data" :key="list.id">
        <div class="card overflow-hidden">
          <div class="bg-soft-primary min-h-100">
            <div class="row">
              
              <div class="col-12 align-self-end">
                <img :src="'/public/storage/'+list.banner" alt class="img-fluid" />
              </div>
            </div>
          </div>
          <div class="card-body pt-0">
            <div class="row">
              <div class="col-sm-12 text-center">
               <div class="avatar-md profile-user-wid mb-4 mx-auto text-center">
                  <img class="img-thumbnail rounded-circle h-100 mx-auto" :src="`/public/storage/${list.logo}`" alt />
                </div>
                <h4 class="font-size-18 text-truncate mt-3">#{{list.reference}}</h4>
                <p class="text-muted mb-0 text-truncate">{{list.customer.name}}</p>
              </div>

              <div class="col-sm-12">
                <div class="pt-4">
                  <div class="mt-4 d-flex">
                    <a href="javascript:;" @click="selectedListing = list, getProducts(list.id)" class="btn btn-outline-light btn-rounded"><i class="bx bx bx-purchase-tag-alt my-1"></i></a>
                    <b-tooltip target="tooltip-add">Add Product</b-tooltip>
                    <a id="tooltip-add" class="btn btn-outline-light font-size-16 btn-rounded ml-2 my-auto"><i class="bx bx-pencil"></i></a><b-tooltip target="tooltip-add">Add Product</b-tooltip>
                    <a href="javascript:;" v-b-tooltip.hover title="View" placement="left" @click="selectedListing = list, getProducts(list.id), getProductsPanel = 1" class="btn btn-outline-light btn-rounded ml-2"><i class="mdi mdi-eye-outline"></i></a>
                     <b-dropdown v-b-tooltip.hover title="More Options" placement="left" dropleft class="more-options-dropdown btn btn-outline-light btn-rounded mr-auto ml-2">
                        <template v-slot:button-content>
                           <i class="bx bx-dots-horizontal-rounded"></i>
                        </template>
                        <b-dropdown-item>Action</b-dropdown-item>
                        <b-dropdown-item>Another Action</b-dropdown-item>
                        <b-dropdown-item>Something else here</b-dropdown-item>
                      </b-dropdown>
                    <b-form-checkbox switch size="lg" class="my-auto" v-model="list.active" value="1"
                      unchecked-value="0"><small>Active</small></b-form-checkbox>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>

              <div class="col-lg-12">
                <b-pagination
              v-if="shopsData.data.length > 0"
              class="justify-content-center"
              pills
              v-model="currentPage"
              :total-rows="shopsData.total"
              :per-page="shopsData.per_page"
              aria-controls="my-table"
              @input="productQuery"
            ></b-pagination>
             <small class="mx-auto my-2 text-center d-block">Records {{shopsData.from}} - {{shopsData.to}} of {{shopsData.total}} // Page {{shopsData.current_page}} of {{shopsData.last_page}}</small>
              </div>
      </div>
    </div>
    </div>
    <!--  end row -->

    <div class="row">
      <!-- end col-->
    </div>
    <SideSlidePanel :editPanel="showListingProducts" :panelClass="'edit-sidepanel-large'" @close-panel="showListingProducts = 0">
      <div class="row">
        
      </div>
    </SideSlidePanel>
    <!-- end row -->
  </Layout>
</template>
