<script type="text/javascript">
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import simplebar from "simplebar-vue";
import VueSlideBar from "vue-slide-bar";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import vue2Dropzone from 'vue2-dropzone';
//import ThemeBuilder from '../../components/widgets/page-builder/ThemeBuilder'
//import { menuItems } from "../../components/menu";
import SideSlidePanel from "../../components/widgets/SideSlidePanel"
import SearchResource from "../../components/widgets/SearchResource"
import SearchCompanyUsers from "../../components/widgets/SearchCompanyUsers"
import UserList from "../../components/widgets/UserList"
import { socialProfiles } from "../../components/widgets/page-builder/social-profiles"
import { headingFonts } from "../../components/widgets/page-builder/font-options"
import FontsMultiselect from "../../components/widgets/page-builder/widgets/FontsMultiselect"
import widgetContentFilters from "../../components/widgets/page-builder/widgets/widgetContentFilters"
import { themeStyles } from "../../components/widgets/page-builder/page-styles";
import widgetConfigsTab from "../../components/widgets/page-builder/widgets/widgetConfigsTab";
import Multiselect from "vue-multiselect";
import { EventBus } from '../../app';
import Swal from "sweetalert2";

export default{
	 components: { VueSlideBar, Layout, PageHeader, simplebar, VueGoogleAutocomplete, vueDropzone: vue2Dropzone, SideSlidePanel, FontsMultiselect, SearchResource, widgetContentFilters, widgetConfigsTab, Multiselect, Swal, SearchCompanyUsers, UserList },
	 data() {
   		return {
   			loadingCsvImport: false,
   			import_progress: 0,
   			success_count: 5,
   			selected_currency: '',
   			currencies: [],
   			supportUserList: false,
   			loadingUploadWindow: false,
   			loadingProfessionalBodies: false,
   			available_social_profiles: socialProfiles,
   			selected_social_profile: '',
   			newsocial: {
   				username: '',
   				url: '',
   			},
   			selected_social_action: 'create',
   			social_profiles: [],
   			invoice: {
   				invoice_prefix: 'INV',
   				invoice_no: 1,
   				invoice_no_length: 6,
   			},
   			load_error: '',
   			themeStyles: themeStyles,
   			addNewDomain: false,
   			addNewThemeModal: false,
   			domainVerificationLoading: false,
			verified_success: false,
			theme: {
		        id: '',
		        uid: '',
		        name: '',
		        description: '',
		        active: '',
		        styles: '',
		      },
		      theme_raw: {
		        id: '',
		        uid: '',
		        name: '',
		        description: '',
		        active: '',
		        styles: '',
		      },
			domains: [],
			      domain: {
			        id: '',
			        uid: '',
			        reference: '',
			        name: '',
			        description: '',
			        domain: '',
			        domain_key: '',
			        domain_config: {},
			        ssl_active: false,
			        active: false, 
			        verified: false,
			 },
   			selectedids: [],
   			loadingWebhookSettings: false,
   			show_webhook_errors: [],
   			webhooks_show: false,
   			channels_area_active: '',
   			selected_channel_webhooks: '',
   			integrationorderspage: 1,
   			channels_type: 'ecommerce',
   			linked_product_view: [],
   			syncOnlineQty: false,
			syncAllQty: false,
			addManualQty: false,
   			selected_integration_uid: '',
   			startIntegration:false,
   			feeds: [],
   			domains: [],
   			domainsearch: '',
   			domainslimit: 50,
   			customerslimit: 50,
   			active_feed_uid: '',
   			active_integration_uid: '',
   			linklisting: '',
   			stripelisting: '',
   			addStripeLocation: false,
   			addStripeReader: false,
   			loadingChannelsSetup: false,
   			loadingCompanySettings: false,
   			loadingFeeds: false,
   			loadingLinkListing: false,
   			loadingRegisteringLocation: false,
   			loadingStripeLocations: false,
   			loadingPaymentSettings: true,
   			loadingWebsiteSettings: false,
   			loadingEcommerceIntegration: false,
   			loadingShippingSettings: true,
   			loadingProducts: false,
   			loadingIntegrationProducts: false,
   			loadingIntegrationCategories: false,
   			loadingIntegrationCustomers: false,
   			loadingIntegrationOrders: false,
   			loadingCategories: false,
   			loadingTheme: false,
   			loadingThemeProgress: 0,
   			loadingMultipleImport: false,
   			multiItemsExists: 0,
   			multiItemsIndex: 0,
   			multiItemsUploadTotal: 0,
   			oauth_setup_progress: 0,
   			categories_loading_progress: 0,
   			products_loading_progress: 0,
   			products_loading_text: "Loading products..",
   			integration_loading_progress: 0,
   			integration_loading_text: "Loading integration products..",
   			productssearch: '',
   			productssortby: 'id',
   			productspage: 1,
   			productslimit: 100,
   			orderslimit: 100,
   			categorieslimit: 100,
   			productschanneltotal: 0,
   			productscurrentPage: 1,
   			categoriescurrentPage: 1,
   			shipping_progress: 0,
   			oauth_setup_text: '',
   			website_setup_text: '',
   			channels_setup_text: '',
   			channels_setup_progress: 0,
   			website_setup_progress: 0,
   			payments_setup_progress: 0,
   			company_settings_progress: 0,
   			ecommerce_website_url: '',
   			all_commerce_channels: [],
   			all_active_commerce_channels: [],
   			website_setup_started: false,
   			title: "Settings",
   			setPermissions: false,
   			settings: [],
   			storageurl: 'https://'+this.$attrs.company.nickname+'.s3.eu-west-2.amazonaws.com',
   			view: 'Company',
   			logoUpload: false,
   			bannerUpload: false,
   			directoryBannerUpload: false,
   			tradeDirectoryBannerUpload: false,
   			error_message:false,
   			error_reponse: [],
   			permission_role: [],
   			permission_resources: '',
   			permissions: [{
   				view: [],
   				add: [],
   				edit: [],
   				read: [],
   				delete: [],
   			}],
   			addreader: {
   				location: null,
   				name: '',
   				registration_code: '',
   				listing_id: 0,
   				configuration: {}
   			},
   			success_message: false,
   			success_text: "Congratulations, your action has been saved.",
   			counties: [],
   			gfontskey: 'AIzaSyCO-2c9bJAX3z7cP3WN2O6x7ndipzOvZsU',
   			gFonts: [],
   			loadingFonts: false,
   			countries: [],
   			registrationcountries: [],
   			registrationcounties: [],
   			user_roles: [],
   			company_types: [],
   			company_update: '',
   			company_registration_settings: {
			        	address1: '',
				        address2: '',
				        address3: '',
				        country_id: '',
				        country: '',
				        county: '',
				        county_id: '',
				        gplace_id: '',
				        longitude: 0.000000000,
				        latitude: 0.000000000,
				        postcode: '',
				        vat_no: '',
				        registration_no: '',
				        legal_status: '',
				        professional_bodies: [],
			},
   			import_type: '',
   			import_file: '',
   			import_api: '',
   			import_csv_file: '',
   			import_csv_rows: [],
   			import_header_rows: false,
   			start_carriers: 0,
   			end_carriers: 12,
   			start_marketplaces: 0,
   			end_marketplaces: 12,
   			carrier_search: '',
   			imported_rows: [],
   			import_step: 1,
   			importLoading: false,
   			loadingShippingIntegration: false,
   			api_url: 'http://moiranew.com',
   			api_token: '',
   			all_carrier_integrations: [],
   			active_carrier_integrations: [],
   			active_marketplace_integrations: [],
   			active_ecommerce_integrations: [],
   			selected_ecommerce_integration: '',
   			selected_ecommerce_integration_auth_rules: '',
   			selected_ecommerce_integration_auth_details: {},
   			selected_ecommerce_integration_index: -1,
   			selected_carrier_auth_rules: '',
   			selected_carrier_auth_details: {},
   			selected_carrier_integration: '',
   			selected_carrier_index: [],
   			selected_channel_products: '',
   			selected_channel_product: '',
   			selected_channel_categories: '',
   			selected_channel_customers: '',
   			selected_channel_company_customers: '',
   			selected_channel_orders: '',
   			selected_channel_order: '',
   			selected_integration: '',
   			selected_auth: '',
   			selected_product: '',
   			push_quantity: 0,
   			push_category: 0,
   			push_email_customer: false,
   			all_categories: '',
   			all_products: '',
   			all_product_ids: '',
   			courierapi_response: '',
   			ecommerce_channels_settings: '',
   			marketplce_channels_settings: '',
   			shipping_apikeys: '',
   			alllistings: [],
   			alllistingsearch: '',
   			tradelistingsearch: '',
   			publiclistingsearch: '',
   			onlinelistingsearch: '',
   			googleSearchAddress: '',
   			googleSearchRegisteredAddress: '',
   			googleSearchTradeAddress: '',
   			closeHover: false,
   			multiImportErrors: {
   				items: [],
   				imports: [],
   			},
   			app_integrations_settings: '',
   			integrationsSettingsModal: false,
   			integrations: [],
   			registered_webhooks: {},
   			all_channel_webhooks: [],
   			active_channel_webhooks: [],
   			channel_webhook_events: [
			  {name: 'orders.received', method: 'POST', url: '/orders-received'},
			  {name: 'orders.updated', method: 'POST', url: '/orders-updated'},
			  {name: 'confirmation.orders.received', method: 'POST', url: '/confirmation-orders-received'},
			  {name: 'products.updated', method: 'POST', url: '/products-updated'},
			  {name: 'channels.products.updated', method: 'POST', url: '/channels-products-updated'}
			],
			professional_bodies: [{text: 'NAJ', value: 'NAJ'}, {text: 'CMJ', value: 'CMJ'}, {text: 'Houlden Group', value: 'Houlden Group'}],
			professional_body_index: -1,
			professional_body: '',
			membership_no: '',
			show_linked_customer_details: '',
			channel_product: {
			sku:"",
			name:null,
			subName:null,
			description:null,
			currency:null,
			productType:null,
			quantity:null,
			price:null,
			
			//dimensions:{
			//	width:null,
			//	height:null,
			//	length:null,
			//	weight:null
			//},
			//units:{
			//	width:null,
			//	height:null,
			//	length:null,
			//	weight:null
			//},
			//tags:[],
			status:{
				//id:null,
				//visibility:"visible",
				active:"active",
				//status:"publish",
				//ecartapiId:"1",
				//ecartapi:"active",
				//hasOptions:null
			},
			//images:[
				//{
				// id:null, 
				// url:null,
				// variantIds:[],
				 //ecartapiUrl: null
				//}
			//],
			//imageUrl:null,
			//condition:null,
			//link:null,
			//dates:{},
			vendor:null,
			sellOutStock:true
			},
   			app_integrations: 
   			{social:
   				[
		   			{
		   				text: 'Meta & Instagram Shop',
		   				value: 'facebook',
		   				type: 'feed',
		   				icon: 'bx bxl-facebook',
		   				active:false,
		   				settings: {
		   					content: [
		   						{feed_name: {value: Date.now()+'-google-shopping-feed', units: ''},},
		   						{domain: {value: 'company', units: ''},},
		   						{marketplace: {value: 'company', units: ''},},
		   						{output_format: {value: 'xml', units: ''}},
		   					],
		   					configs: [
		   							
		   							{
		   								feed_name: {type: 'text', value: 'Slide 1'},
		   							},
		   							{
		   								marketplace: {
			   						 	active: 0,  type: 'radiogroup', isButton: true, value: 'company', options: ['company', 'public', 'trade']
			   						 	},
		   						 	},
		   						 	{
		   						 		output_format: {
			   						 	active: 0,  type: 'radiogroup', isButton: true, value: 'company', options: ['xml', 'csv']
			   						 	}
		   						 	},
		   					],
		   					filters: {
		   						filter: {
		   							search: {active: 0, type: 'hidden', value: ''}, categories: {active: 0, type: 'customselect', value: ''}, collections: {active: 0, type: 'customselect', value: []}, price_range: {active: 0, type: 'multirange', value: [0,999999]}, page: {active: 0, type: 'number', value: 1}, limit: {active: 0, type: 'range', value: 16}, order: {active: 0, type: 'select', value: 'DESC', options: ['ASC','DESC']}, sortby: {active: 0, type: 'select', value: 'id', options: ['id', 'name', 'created_at', 'updated_at']}
		   						},
                    			active_filters: {
                    				search: {active: 0, type: 'hidden', value: ''}, categories: {active: 0, type: 'customselect', value: []}, collections: {active: 0, type: 'customselect', value: []}, price_range: {active: 0, type: 'multirange', value: [0,999999]}, page: {active: 0, type: 'number', value: 1}, limit: {active: 0, type: 'range', value: 16}, order: {active: 0, type: 'select', value: 'DESC', options: ['ASC','DESC']}, sortby: {active: 0, type: 'select', value: 'id', options: ['id', 'name', 'created_at', 'updated_at']}
                    			},
		   					}
		   				}		
		   			},
		   			{
		   				text: 'Google Shopping',
		   				value: 'google-shopping',
		   				type: 'feed',
		   				icon: 'bx bxl-google',
						active:false,
		   				settings: {
		   					content: [
		   						{feed_name: {value: Date.now()+'-google-shopping-feed', units: ''},},
		   						{domain: {value: 'company', units: ''},},
		   						{marketplace: {value: 'company', units: ''},},
		   						{output_format: {value: 'xml', units: ''}},
		   					],
		   					configs: [
		   						{feed_name: {type: 'text', value: 'Slide 1'},
		   						},
		   						{
		   							marketplace: {
		   						 	active: 0,  type: 'radiogroup', isButton: true, value: 'company', options: ['company', 'public', 'trade']
		   						 	},
		   						 },
		   						 {
		   						 	output_format: {
		   						 	active: 0,  type: 'radiogroup', isButton: true, value: 'company', options: ['xml', 'csv']
		   						 	}
		   						 },
		   					],
		   					filters: {
		   						filter: {
		   							search: {active: 0, type: 'hidden', value: ''}, categories: {active: 0, type: 'customselect', value: ''}, collections: {active: 0, type: 'customselect', value: []}, price_range: {active: 0, type: 'multirange', value: [0,999999]}, page: {active: 0, type: 'number', value: 1}, limit: {active: 0, type: 'range', value: 16}, order: {active: 0, type: 'select', value: 'DESC', options: ['ASC','DESC']}, sortby: {active: 0, type: 'select', value: 'id', options: ['id', 'name', 'created_at', 'updated_at']}
		   						},
                    			active_filters: {
                    				search: {active: 0, type: 'hidden', value: ''}, categories: {active: 0, type: 'customselect', value: []}, collections: {active: 0, type: 'customselect', value: []}, price_range: {active: 0, type: 'multirange', value: [0,999999]}, page: {active: 0, type: 'number', value: 1}, limit: {active: 0, type: 'range', value: 16}, order: {active: 0, type: 'select', value: 'DESC', options: ['ASC','DESC']}, sortby: {active: 0, type: 'select', value: 'id', options: ['id', 'name', 'created_at', 'updated_at']}
                    			},
		   					}
		   				}				
		   			},
		   			{
		   				text: 'WhatsApp Business',
		   				value: 'whatsapp',
		   				type: 'integration',
		   				icon: 'bx bxl-whatsapp',
		   				active:false,
		   				settings: {
		   					configs: [
		   						{telephone: {type: 'text', value: 'Slide 1'},},
		   					],
		   					content: [
		   						{telephone: {value: '', units: ''},},
		   					]
		   				}
		   			}
   				],
   				email: [
		   			{
		   				text: 'Mailchimp',
		   				value: 'mailchimp',
		   				type: 'mail',
		   				icon: 'bx bxl-mailchimp',
		   				active:false,
		   				settings: {
		   					configs: [
		   						{username: {type: 'text', value: 'Username'},},
		   						{api_token: {type: 'text', value: 'API Token'},},
		   					],
		   					content: [
		   						{username: {type: 'text', value: 'Username'},},
		   						{api_token: {type: 'text', value: 'API Token'},},
		   					]
		   				}
		   			}
   				],
   				search: [
		   			{
		   				text: 'Google Search Console',
		   				value: 'google-search',
		   				type: 'search',
		   				icon: 'bx bxl-google',
		   				active:false,
		   				settings: {

		   				}
		   			},
		   			{
		   				text: 'Google Analytics',
		   				value: 'google-analytics',
		   				icon: 'bx bxl-google',
		   				type: 'integration',
		   				active:false,
		   				settings: {
		   					configs: [
		   						{tracking_code: {type: 'text', value: 'GA-'},},
		   					],
		   					content: [
		   						{tracking_code: {type: 'text', value: 'GA-'},},
		   					]
		   				}
		   			}
   				],
   				pricing: [
		   			{
		   				text: 'Rapaport Diamond Pricing',
		   				value: 'diamond',
		   				icon: 'bx bx-diamond',
		   				type: 'integration',
		   				active:false,
		   				settings: {
		   					api_token: '',
		   				}
		   			}
   				]
   			},
   			active_app_integrations: {
   				social: [],
   				email: [],
   				search: [],
   				pricing: [],
   			},
   			userKeys: [    
	            {text: 'import_from_id', required: false},
	            {text: 'name', required: true},
	            {text: 'lastname', required: true},
	            {text: 'email', required: true},
	            {text: 'password', required: false},
	            {text: 'user_role', required: false},
	        ],
   			productKeys: [    
	            {text: 'import_from_id', required: false},
	            {text: 'name', required: true},
	            {text: 'short_description', required: true},
	            {text: 'long_description', required: true},
	            {text: 'trade_description', required: true},
	            {text: 'url_rewrite', required: false},
	            {text: 'meta_title', required: false},
	            {text: 'meta_description', required: false},
	            {text: 'cover_image', required: false},
	            {text: 'images', required: false},
	            {text: 'feature_values_id', required: false},
	            {text: 'attribute_values_id', required: false},
	            {text: 'cost_price', required: true},
	            {text: 'trade_price', required: true},
	            {text: 'sales_price', required: true},
	            {text: 'taxrule_id', required: false},
	            {text: 'reference', required: true},
	            {text: 'default_category_id', required: false},
	            {text: 'pubmarket_category_id', required: false},
	            {text: 'trademarket_category_id', required: false},
	            {text: 'brand_id', required: false},
	            {text: 'company_supplier_id', required: false},
	            {text: 'marketplace_brand_id', required: false},
	            {text: 'bulk_price_id', required: false},
	            {text: 'carnet_price', required: false},
	            {text: 'carnet_weight', required: false},
	            {text: 'import_tax', required: false},
	            {text: 'export_tax', required: false},
	            {text: 'featured', required: false},
	            {text: 'public', required: true},
	            {text: 'trade', required: true},
	            {text: 'active', required: true},
	            {text: 'approved', required: false},
	            {text: 'product_type_id', required: true},
	            {text: 'total_qty', required: false},
	            {text: 'Ignore Column', required: false},
        	],
   			order: {
   				statuses: [],
   				default_status: '',
   				trade_listings: [],
   				default_trade_listing: '',
   				public_listings: [],
   				default_public_listing: '',
   				online_listings: '',
   				default_online_listing: '',
   			},
   			payments: {
   				stripe: 0,
   				paypal: 0,
   			},
   			stripeAccount: [],
   			enquiry: {
   				website: '',
   				trade: '',
   				public: '',
   			},
   			support: {
   				default_support_group_id: '',
   				default_support_group_users: [],
   				website: '',
   				trade: '',
   				public: '',
   			},
   			headingfonts: headingFonts,
   			dropzoneOptions: {
		        url: 'https://httpbin.org/post',
		        thumbnailHeight: 150,
		        thumbnailWidth: 150,
		        autoProcessQueue: false,
		        maxFiles: 1,
		        acceptedFiles: '.csv, .xlsx, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values',
		      },
		      avatarDropzoneOptions: {
		        url: 'https://httpbin.org/post',
		        thumbnailHeight: 150,
		        thumbnailWidth: 150,
		        autoProcessQueue: false,
		        maxFiles: 1,
		        acceptedFiles: '.jpg, .png, .webp',
		      },
		      heading_types: ['h1', 'h2', 'h3', 'h4', 'h5'],
		    website_resource: {
		    	themes: [],
		    	domains: [],
		    	templates: [],
		    	pages: [],
		    	menus: [],
		    },
		    website_default: {
		    	themes: '',
		    	domains: '',
		    	templates: '',
		    	pages: '',
		    	menus: '',
		    },
   			website: {
   				uid: '',
   				gemesys: null,
   				external: null,
   				externalservice: {
   					service: null,
   					url: null,
   					apikey: null,
   				},
   				homepage_banner: null,
   				colours: {
	   				primary_colour: null,
	   				secondary_colour: null,
   				},
   				fonts: {
	   				heading_font_weight: {h1: null, h2: null, h3: null, h4: null, h5: null},
	   				heading_font_size: {h1: 36, h2: 28, h3: 24, h4: 20, h5: 18},
	   				heading_font: {h1: null, h2: null, h3: null, h4: null, h5: null},
   				body_font: null,
   				body_font_size: 16,
   				},
   				active_defaultmenu: [],
   				defaultmenu: ['Home', 'Shop', 'Categories', 'Collections', 'Services', 'Stores', 'Contact']
   			},
   			externalservices: ['Prestashop', 'Shopify','WooCommerce'],
   			imageOptions: '',
   			directoryBannerOptions: '',
   			tradeDirectoryBannerOptions: '',
   			items: [
		        {
		          text: "Company",
		          href: "/"
		        },
		        {
		          text: "Settings",
		          active: true
		        }
		      ]
   		}
   	},
   	created(){
   		//this.getGFonts();
   		this.includeGoogle('maps.googleapis.com/maps/api/js?key=AIzaSyCO-2c9bJAX3z7cP3WN2O6x7ndipzOvZsU&libraries=places,geometry');
   		this.getCountries();
   		this.getRegistrationCountries();
   		this.getCommerceChannels();
   		this.getCompanyTypes();
   		this.getStripeAccount();
   		this.getCompanyDomains();

	   	/*this.enquiry.websiteemail = this.$attrs.company.email;
	   	this.enquiry.tradeemail = this.$attrs.company.email;
	   	this.enquiry.publicemail = this.$attrs.company.email;
	   	this.support.websiteemail = this.$attrs.company.email;
	   	this.support.tradeemail = this.$attrs.company.email;
	   	this.support.publicemail = this.$attrs.company.email;*/
	   	this.company_update = JSON.parse(JSON.stringify(this.$attrs.company));
	   	if(this.$attrs.company.business_settings !== null){
	   		this.company_registration_settings = JSON.parse(JSON.stringify(this.$attrs.company.business_settings));
	   		if(this.company_registration_settings.professional_bodies == null){
	   			this.company_registration_settings.professional_bodies = [];
	   		}
	   	}
	   	if(this.$attrs.company.profile_banners == null){
	   		var bannerInfo = {public: '', trade: ''};
	   		this.company_update.profile_banners = bannerInfo;
	   	}
	   	if(this.$attrs.company.profile_contact == null){
	   		var bannerInfo = {public: '', trade: {
	   			address1: '',
	   			address2: '',
	   			address3: '',
	   			postcode: '',
	   			gplace_id: '',
	   			country_id: '',
	   			county_id: '',
	   			latitude: 0.0000000,
	   			longitude: 0.0000000,
	   			name: '',
	   			email: '',
	   			website: '',
	   			phone: '',
	   		}};
	   		this.company_update.profile_contact = bannerInfo;
	   	}
	   	var urlhash = window.location.hash;
	   		if(urlhash.length > 0){
	   			urlhash = urlhash.split('?');
	   			this.view = urlhash[0].replace('#', '');
	   		}
	   		if((this.view == '') || (this.view == 'Company')){
	   			this.loadingCompanySettings = true;
	   			let self = this;
	   			setTimeout(	function(){
	   					self.loadingCompanySettings = false;
	   					self.loadingUploadWindow = false;
	   				}, 1000);
	   		}
	   		if(this.view == 'Order'){
	   			this.getOrderSettings();
	   		}
	   		if(this.view == 'Enquiries'){
		   				//this.company_update.settings = settings;
		   		this.getCompanySettings('enquiry');
		   	}
		   	if(this.view == 'Support'){
		   				//this.company_update.settings = settings;
		   		this.getCompanySettings('support');
		   	}
	   		if(this.view == 'User'){
	   			this.getUserRoles();
	   		}
	   		if(this.view == 'Shipping'){
	   			this.getCourierApiKey();
	   			//this.getShippingIntegrations();
	   		}
	   		if((this.view == 'Website') && (this.website.uid == '')){
	   			this.channels_type = 'ecommerce';
	   			this.fetchChannelsIntegrationsDetails('channels-api');
	   			this.getWebsiteSettings();

	   		}
	   		if((this.view == 'Import') && (this.import_type == 'website')){
	   			this.channels_type = 'ecommerce';
	   			this.fetchChannelsIntegrationsDetails('channels-api');
	   			this.getWebsiteSettings();

	   		}
	   		if(this.view == 'Marketplaces') {
	   			this.channels_type = 'marketplace';
	   			this.getAllCompanyListings('channels-api');
	   		}
			if(this.view == 'Payments') {
	   			this.getAllCompanyListings();
	   		}
	   		if(this.view == 'Socials'){
	   			this.getCompanySocialProfiles();
	   		}
	   		if(url.search("integration_success=") > 0){
	   			//console.log(url.search("integration_success="));
	   			this.view = 'Website';
	   			var message = url.substring(url.lastIndexOf("=") + 1);
              	//console.log(message.replace('#'+this.view+'', ''));
              	this.selected_integration_uid = message.replace('#'+this.view+'', '');
              	
	   			this.fetchChannelsIntegrationsDetails('channels-api');
	   			this.loadingWebsiteSettings = false;
	   			this.website.gemesys = false;
	   			this.website.external = true;
	   			this.$bvModal.show('commerce-products');
        		
        	}	
	   	
   	},
   	computed:{
   		countDownChanged(dismissCountDown) {
        	this.success_count = dismissCountDown;
      	},
   		invoiceNoLength(){
   			if(Object.keys(this.invoice).length > 0){
	   			if(Number.isInteger(this.invoice.invoice_no) ){
	   				return (this.invoice.invoice_no_length - this.invoice.invoice_no.toString().length);
	   			}else{
	   				return (this.invoice.invoice_no_length - this.invoice.invoice_no.length);
	   			}
   			}else{
   				return 6;
   			}
   		},
   		matchedProducts(){
   			if(('data' in this.all_products) && (this.all_products.data.length > 0)){
   				return this.all_products.data.filter(item => item.channel_product_id !== null);
   			}
   		},
   		menuItems(){
	      return this.$root.menu;
	    },
	   
	    filterShippingIntegrations(){
	    	if(this.carrier_search !== ''){
   				return this.all_carrier_integrations.filter(item => (item.name).toLowerCase().includes(this.carrier_search.toLowerCase()));
   			}else{
   				return this.all_carrier_integrations;
   			}
   		},
   		
   		/*customCss(){
   			return '.custom-color {background:' + this.website.primary_colour +'}';
   		},
   		loadFonts(){
   			var category = '';
   			var bodycategory = '';
   			var css = '';
   			if(this.website.fonts.heading_font !== null){
   				
   				for(var i = 0; i < this.heading_types.length; i++){
   					//console.log(this.heading_types[i]);
   					if(this.website.fonts.heading_font[this.heading_types[i]] !== null){
		   				if(this.website.fonts.heading_font[this.heading_types[i]].category == 'handwriting'){
		   					category = 'cursive';
			   			}else if(this.website.fonts.heading_font[this.heading_types[i]].category == 'display'){
			   				category = 'cursive';
			   			}else{
			   				category = this.website.fonts.heading_font[this.heading_types[i]].category;
			   			}
		   					css += '#sample '+this.heading_types[i]+' {font-family: \"' + this.website.fonts.heading_font[this.heading_types[i]].family +'\", '+category+'; font-size: '+this.website.fonts.heading_font_size[this.heading_types[i]]+'px; }\n '
	   				}
   				}
   			} 
   			if(this.website.fonts.body_font !== null){
   				if(this.website.fonts.body_font.category == 'handwriting'){
   					bodycategory = 'cursive';
	   			}else if(this.website.fonts.body_font.category == 'display'){
	   				bodycategory = 'cursive';
	   			}else{
	   				bodycategory = this.website.fonts.body_font.category;
	   			}
   					css += '#sample-body p {font-family: \"' + this.website.fonts.body_font.family +'\", '+bodycategory+' !important; font-size: '+ this.website.fonts.body_font_size +'px }\n ';
   			}
   			return css;
   		}*/
   	},
   	mounted(){
   		//this.createHead();
   		axios.defaults.headers.common = {
		  "Referrer" : 'https://'+this.$attrs.company.nickname+'.gemesys.co.uk/',
		  "Access-Control-Allow-Origin" : '"',
		};
		axios.defaults.headers.common['Access-Control-Allow-Methods'] = 'GET, POST, PUT, DELETE, OPTIONS';
		axios.defaults.headers.common['Access-Control-Allow-Headers'] = 'Origin, Accept, Content-Type, Authorization';
   		this.loadingUploadWindow = true;
   		this.getCommerceChannels();
   		this.getAvailableChannelWebhooks();
   		var url = window.location.href
   		if(url.search("strmessage=") > 0){
              var message = url.substring(url.lastIndexOf("=") + 1);
              //console.log(message);
              alert(message);
              //var action = resource.data.data.filter(item => item.uid == uid);
              
            }


   		var urlhash = window.location.hash;
	   		if(urlhash.length > 0){
	   			urlhash = urlhash.split('?');
	   			this.view = urlhash[0].replace('#', '');
	   		}
	   		if((this.view == '') || (this.view == 'Company')){
	   			this.loadingCompanySettings = true;
	   			let self = this;
	   			setTimeout(	function(){
	   					self.loadingCompanySettings = false;
	   					self.loadingUploadWindow = false;
	   				}, 1000);
	   		}
	   		if(this.view == 'Order'){
	   			this.getOrderSettings();
	   		}
	   		if(this.view == 'Enquiries'){
		   				//this.company_update.settings = settings;
		   		this.getCompanySettings('enquiry');
		   	}
		   	if(this.view == 'Support'){
		   				//this.company_update.settings = settings;
		   		this.getCompanySettings('support');
		   	}
	   		if(this.view == 'User'){
	   			this.getUserRoles();
	   		}
	   		if(this.view == 'Shipping'){
	   			this.getCourierApiKey();
	   			//this.getShippingIntegrations();
	   		}
	   		if((this.view == 'Website') && (this.website.uid == '')){
	   			this.channels_type = 'ecommerce';
	   			this.fetchChannelsIntegrationsDetails('channels-api');
	   			this.getWebsiteSettings();

	   		}
	   		if((this.view == 'Import') && (this.import_type == 'website')){
	   			this.channels_type = 'ecommerce';
	   			this.fetchChannelsIntegrationsDetails('channels-api');
	   			this.getWebsiteSettings();

	   		}
	   		if(this.view == 'Marketplaces') {
	   			this.channels_type = 'marketplace';
	   			this.getAllCompanyListings('channels-api');
	   		}
			if(this.view == 'Payments') {
	   			this.getAllCompanyListings();
	   		}
	   		if(this.view == 'Socials'){
	   			this.getCompanySocialProfiles();
	   		}
	   		if(url.search("integration_success=") > 0){
	   			//console.log(url.search("integration_success="));
	   			this.view = 'Website';
	   			var message = url.substring(url.lastIndexOf("=") + 1);
              	//console.log(message.replace('#'+this.view+'', ''));
              	this.selected_integration_uid = message.replace('#'+this.view+'', '');
              	
	   			this.fetchChannelsIntegrationsDetails('channels-api');
	   			this.loadingWebsiteSettings = false;
	   			this.website.gemesys = false;
	   			this.website.external = true;
	   			this.$bvModal.show('commerce-products');
        		
        	}		
	   		let self = this;
	   		window.addEventListener('hashchange', function() {
		      //console.log(window.location.hash);
		      //console.log(self.view);
		      self.view = window.location.hash.replace('#', '');

		      	  if((self.view == '') || (self.view == 'Company')){
	   					self.loadingCompanySettings = false;
	   		 			self.loadingUploadWindow = false;
	   				
	   			  }
			      if(self.view == 'Order'){
		   				self.getOrderSettings();
		   			}
		   			if((self.view == 'User') && (self.user_roles.length == 0)){
	   					self.getUserRoles();
			   		}
			   		if((self.view == 'Shipping') && ( self.all_carrier_integrations.length == 0) ){
			   			self.getCourierApiKey();
			   			//self.getShippingIntegrations();
			   		}
			   		if((self.view == 'Website') && (self.website.uid == '')){
			   			self.channels_type = 'ecommerce';
			   			self.fetchChannelsIntegrationsDetails('channels-api');
			   			self.getWebsiteSettings();
			   		}
			   		if((self.view == 'Import') && (self.import_type == 'website')){
			   			self.channels_type = 'ecommerce';
			   			self.fetchChannelsIntegrationsDetails('channels-api');
			   			self.getWebsiteSettings();
			   		}
			   		if(self.view == 'Marketplaces'){
			   			self.channels_type = 'marketplace';
			   			self.fetchChannelsIntegrationsDetails('channels-api');
			   			//self.getWebsiteSettings();
			   		}
			   		if(self.view == 'Payments') {
			   			self.getAllCompanyListings();
			   		}
			   		if(self.view == 'Enquiries'){
		   				//self.company_update.settings = settings;
		   				self.getCompanySettings('enquiry');
		   			}
		   			if(self.view == 'Support'){
		   				//self.company_update.settings = settings;
		   				self.getCompanySettings('support');
		   			}
		   			if(self.view == 'Socials'){
		   				self.getCompanySocialProfiles();
		   			}
		    }, false);


		    
	   	
   	},
   	methods: {
   		getCurrencies(){
   			if(this.currencies.length == 0){
	   			axios.get('/api/currencies', {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
	   				this.currencies = response.data;
	   				if(this.view == 'Orders'){
	   					return this.selectCurrency(response.data);
	   				}
	   			}).catch(error => {

	   			});
   			}
   		},
   		selectCurrency(data){
				this.selected_currency = data.filter(item => item.id == this.company_update.trading_currency_id)[0];
   		},
   		currencySelected(data){
   				this.company_update.trading_currency_code = data.code;
   				this.company_update.trading_currency_id = data.id;
   		},
   		supportUsersUpdated(){
   			this.$refs['support-group-list'].runQuery();
   		},
   		supportUserError(data){
   			this.error_reponse = data;
   			this.error_message = true;
   		},
   		searchVATNo(){
				if(this.newFormData.company_registration_settings.vat_no.length == (9 || 12)){
				axios.get('https://api.service.hmrc.gov.uk/organisations/vat/check-vat-number/lookup/'+this.newFormData.company_registration_settings.vat_no+'', {headers: {'Host' : 'api.service.hmrc.gov.uk'}}).then(response => {
					//console.log(response);
						this.vat_check_result = response.data;
						this.show_vat_result = true;

						return this.checkCompanyAgainstVAT(response.data);
					}).catch(error => {

					});
				}

			},
			checkCompanyAgainstVAT(){

			},
			searchCompaniesHouse(){
				axios.get('https://api.company-information.service.gov.uk/search/companies?q='+this.companies_house_search+'', {headers: {'Host' : 'api.company-information.service.gov.uk', 'Authorization' : 'Basic '+btoa('0258811b-736a-461f-84ac-8ccc2be9ba0b')+''}}).then(response => {
					//console.log(response);
						this.companies_search_results = response.data;
						this.show_search_results = true;
					}).catch(error => {

					});
			},
			selectedRegisteredCompany(item){
					  // Get a reference to the vue-google-autocomplete component
					  const addressAutocomplete = this.$refs['registeredaddressmapref'];

					  // Set the value of the component to the new address
					  addressAutocomplete.autocompleteText = item.title.toLowerCase()+' '+item.address_snippet;
					  // Trigger the place_changed event to search for the new address
					  this.$refs['registeredaddressmapref'].focus();
					  this.$refs['registeredaddressmapref'].$emit("place_changed");
					  					  addressAutocomplete.$emit("place_changed");
					 	this.$refs['registeredaddressmapref'].blur();
					  this.selected_registered_company = item;
					  this.newFormData.company_registration_settings.registration_no = item.company_number;
					  this.$refs['registeredaddressmapref'].focus();

			},
		updateProfessionalBody(){
			this.company_registration_settings.professional_bodies[this.professional_body_index].name = this.professional_body;
   			 this.company_registration_settings.professional_bodies[this.professional_body_index].membership_no = this.membership_no;
   			this.professional_body_index = -1;
   			this.professional_body = '';
	   		this.membership_no = '';
		},
   		editProfessionalBody(index){
   			this.professional_body = this.company_registration_settings.professional_bodies[index].name;
   			this.membership_no = this.company_registration_settings.professional_bodies[index].membership_no;
   			this.professional_body_index = index;
   		},
   		addProfessionalBody(){
   			this.loadingProfessionalBodies = true;
   			var body = {name: this.professional_body, membership_no: this.membership_no};
   			var indexExists = this.company_registration_settings.professional_bodies.findIndex(item => item.name == this.professional_body);

   			if(this.professional_body == ''){
   				Swal.fire("Insufficient Information", "You must add a membership body and number", "error");

   				let self = this;
	   			setTimeout(function(){
	   				self.loadingProfessionalBodies = false;
	   			}, 800);
   			}else if(this.membership_no == ''){
   				Swal.fire("Insufficient Information", "You must add a membership body and number", "error");

   				let self = this;
	   			setTimeout(function(){
	   				self.loadingProfessionalBodies = false;
	   			}, 800);
   			}else if(indexExists == -1 ) {
   				this.company_registration_settings.professional_bodies.push(body);
	   			this.professional_body = '';
	   			this.membership_no = '';
	   			//this.professional_body = '';
	   			let self = this;
	   			setTimeout(function(){
	   				self.loadingProfessionalBodies = false;
	   			}, 800);
   			}else{

   				Swal.fire("Body Already Exists", "You cannot add two membership numbers for the same organisation", "error");

   				let self = this;
	   			setTimeout(function(){
	   				self.loadingProfessionalBodies = false;
	   			}, 800);

   			}

   			
   		},
   		loadTheme(){
   			this.loadingTheme = true;
   			this.loadingThemeProgress = 75;
   			EventBus.$emit('loadTheme');
   			this.setShortTimeout(() => {
   				this.loadingThemeProgress = 100;
   				this.loadingTheme = false;
   			})
   		},
   		createTheme(){
   			this.loadingTheme = true;
   			this.loadingThemeProgress = 75;
   			if(this.website.default_theme !== null){
   				this.website.default_theme["styles"] = JSON.parse(JSON.stringify(this.themeStyles));
   			}else{
   				this.website.default_theme = JSON.parse(JSON.stringify(this.theme_raw));
   				this.website.default_theme["styles"] = JSON.parse(JSON.stringify(this.themeStyles));

   			}
   			this.setShortTimeout(() => {
   				this.loadingThemeProgress = 100;
   				this.loadingTheme = false;
   			});
   			this.setShortTimeout(() => {
   				EventBus.$emit('loadTheme');
   				this.$refs.themeBuilder.focus();
   				this.$refs.themeBuilder.load();
   			});
   			
   		},
   		filterWebhookActive(key, action){
   			if(this.selected_channel_webhooks["webhooks"] !== null){
	   			var webhookkeys = this.selected_channel_webhooks["webhooks"].findIndex(item => item.resource == key && item.action == action);
	   			if(webhookkeys > -1){
	   					return true;
	   				}else{
	   					return false;
	   				}
	   			
   			}else{
	   				return false;
	   			}
   		},
   		activateWebhook(integration, resource_data, action_data){
   			this.loadingWebhookSettings = true;
   			axios.post('/api/company/integrations/'+integration.uid+'/webhook/create', {resource: resource_data, action: action_data}, 
   				{
   					headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token }
   				}).then(response => {
   					let self = this;
		   				setTimeout(function(){
	   					self.loadingWebhookSettings = false;
	   					self.selected_channel_webhooks = '';
		   				return self.activeWebhooks();
	   				}, 300);
	   			}).catch(error => {

	   			});
   		},
   		deactivateWebhook(webhook_id){
   			this.loadingWebhookSettings = true;
   			axios.post('/api/company/integrations/channelsreq', {type: 'GET', url: 'api/v2/webhooks/'+webhook_id, auth: this.selected_integration.pass},  {headers: {
	    		'Authorization' : 'Bearer '+this.$attrs.user.api_token
	    		}}).then(response => {
		   				let self = this;
		   				setTimeout(function(){
		   							self.loadingWebhookSettings = false;
		   							self.success_text = "Webhook Removed";
   									self.success_message = true;
   									self.success_count = 5;
		   					return self.activeWebhooks();
		   				}, 300);
	   			}).catch(error => {

	   			});

   			/*axios.delete('https://api.ecartapi.com/api/v2/webhooks/'+webhook_id, {headers: { 
		   				'Authorization' : this.selected_integration.pass,
		   				'Content-Type': 'application/json'
		   			}}).then(response => {
		   				let self = this;
		   				setTimeout(function(){
		   							self.loadingWebhookSettings = false;
		   							self.success_text = "Webhook Removed";
   									self.success_message = true;
   									self.success_count = 5;
		   					return self.activeWebhooks();
		   				}, 300);
	   			}).catch(error => {

	   			});*/
   		},
   		goToLink(link){
   			window.location.href = link;
   		},
   		productExport(){

   		},
   		checkChannelLink(product){
   			if(product.product_channels.length > 0){
	   			var index = product.product_channels.findIndex(item => item.company_app_integration_id == this.selected_integration.id);
	   			if(index > -1){
	   				return true;
	   			}else{
	   				return false;
	   			}
   			}else{
   				return false;
   			}
   		},
   		checkGemesysLink(external_product){
   		
   			////console.log(external_product);
   			/*for(var i = 0; i < this.all_products.data.length; i++){
   				if(this.all_products.data[i].product_channels.length > 0){
   					index = this.all_products.data[i].product_channels.findIndex(item => (item.company_app_integration_id == this.selected_integration.id) && (item.channels_product_id == external_product.id) );
   						//console.log(index);
	   					if(index > -1){
			   				return true;
			   			}else{
			   				return false;
			   			}
   				}
   			}*/

   			var index = this.all_product_ids.filter(item => item["product_channels"].find(subitem => {
   					if(subitem.channels_product_id == external_product.id){
   						return true;
   					}else{
   						return false;
   					}
   			
   			}));

   			/*var index = this.all_products.data.filter(item => item["product_channels"].filter(subitem => subitem.channels_product_id == external_product.id));*/
   			//console.log(index);

   						if(index > -1){
			   				return true;
			   			}else{
			   				return false;
			   			}
   			
   			
   			
   		},
   		IsLinked(subitem){
   				if(subitem["channels_product_id"] == external_product["id"]){
			   				return true;
			   			}else{
			   				return false;
			   			}
   		},
   		updateStripeAccountTransactions(){
   			axios.get('/api/company/settings/payment/'+this.$attrs.company.uid+'/transactions?limit='+limit, {headers: {'Authorization' : 'Bearer ' +this.$attrs.user.api_token}}).then(response => {
   				this.stripeAccount[0].transactions = response.data;
   			}).catch(error => {

   			});
   		},
   		updateChannelSettings(){
   			axios.post('/api/company/integrations/'+integration.id+'/update', {url: this.ecommerce_website_url, type: this.channels_type, integration_name: this.selected_ecommerce_integration.name, webhooks: this.registered_webhooks}, {headers: {
   				'Authorization' : 'Bearer '+this.$attrs.user.api_token
   			}}).then(error => {

   			}).catch(error => {

   			});

   		},
   		getCustomerChannels(){
   			axios.get('/api/company/customer/channels/'+this.selected_integration.id, {headers: {
   				'Authorization' : 'Bearer '+this.$attrs.user.api_token
   			}}).then(response => {
   				this.selected_channel_company_customers = response.data;

   			}).catch(error => {

   			});
   		},
   		showChannelLinkedCustomer(id){
   			if(this.selected_channel_company_customers !== ''){
   				var index = this.selected_channel_company_customers.findIndex(item => item.channels_customer_id == id);
   				if(index > -1){
   					this.show_linked_customer_details = this.selected_channel_company_customers[index].customer;
   				}else{
   					return false;
   				}
   			}
   		},
   		checkChannelsCustomersLink(id){
   			if(this.selected_channel_company_customers !== ''){
   				var index = this.selected_channel_company_customers.findIndex(item => item.channels_customer_id == id);
   				if(index > -1){
   					return true;
   				}else{
   					return false;
   				}
   			}
   		},
   		async getChannelProductById(items, isMultiple){
   			if(isMultiple == false){
   				axios.post('/api/company/integrations/channelsreq', {type: 'GET',url: 'api/v2/products/'+items.id, auth: this.selected_integration.pass},  {headers: {
	    		'Authorization' : 'Bearer '+this.$attrs.user.api_token
	    		}}).then(response => {
	   				
	   				this.products_loading_progress = 100;
			        let self = this;
		    		setTimeout(
		    			function(){
		    				self.loadingIntegrationProducts = false;
		    				items.quantity = response.data.product.quantity;
							return self.runImportQuery('product', items, isMultiple);
		    			}, 300);
	   			}).catch(error => {
	   				this.multiImportErrors.items.push({id: items.id, err_msg: 'Fetch Product Error'});
	   			});

   			/*axios.get('https://api.ecartapi.com/api/v2/products/'+items.id, {headers: { 
	   				'Authorization' : this.selected_integration.pass,
	   				'Content-Type': 'application/json'
	   			}}).then(response => {
	   				
	   				this.products_loading_progress = 100;
			        let self = this;
		    		setTimeout(
		    			function(){
		    				self.loadingIntegrationProducts = false;
		    				items.quantity = response.data.product.quantity;
							return self.runImportQuery('product', items, isMultiple);
		    			}, 300);
	   			}).catch(error => {
	   				this.multiImportErrors.items.push({id: items.id, err_msg: error.data});
	   			});*/
	   		}else{
	   			for(var i = 0; i < items.length; i++){
	   				axios.post('/api/company/integrations/channelsreq', {type: 'GET',url: 'api/v2/products/'+items[i].id, auth: this.selected_integration.pass},  {headers: {
	    		'Authorization' : 'Bearer '+this.$attrs.user.api_token
	    		}}).then(response => {
		   				
		   				this.products_loading_progress = 100;
						items[i].quantity = response.data.product.quantity;
				        return this.runImportQuery('product', items, isMultiple);
		   			}).catch(error => {
		   				/*let self = this;
			    setTimeout(
			    	function(){
			    		self.loadingIntegrationProducts = false;
						
			    	}, 300);*/
		   				this.multiImportErrors.items.push({id: items.id, err_msg: 'Fetch Product Error'});
		   			});
	   			}
	   			
	   		}
   		},
   		runImportQuery(resource,items,isMultiple){
   			this.multiImportErrors.imports = [];
   			axios.post('/api/company/integrations/import', {multiple: isMultiple, import: items, type: resource, integration: this.selected_integration, emailCustomer: this.push_email_customer}, {headers: {
   				'Authorization' : 'Bearer '+this.$attrs.user.api_token
   			}}).then(response => {
   				this.integration_loading_progress = 95;
   				let self = this;
   					self.integration_loading_progress = 100;
		   			self.integration_loading_text = "Successfully Imported. Please check below for any errors:";
		   				setTimeout(function(){
		   							
   									if(resource == 'product'){
						   				if(isMultiple == false){
						   					self.loadingIntegrationProducts = false;
						   				}
						   			}else if(resource == 'category'){
						   				self.loadingIntegrationCategory = false;
						   			}else if(resource == 'customer'){
						   				self.loadingIntegrationCustomers = false;
						   				self.getCustomerChannels();
						   			}else if(resource == 'order'){
						   				self.loadingIntegrationOrder = false;
						   			}
		   					
		   				}, 300);
   			}).catch(error => {
   					this.multiImportErrors.imports.push({id: items.id, err_msg: 'Import Error'});
   			});
   		},
   		async channelResourceImport(resource, items, isMultiple ){
   			this.integration_loading_text = "Importing "+resource+". Please wait...";
   			this.integration_loading_progress = 20;
   			if(resource == 'product'){
   				if(this.loadingMultipleImport !== true){
   				this.loadingIntegrationProducts = true;
   				}
   			}else if(resource == 'category'){
   				this.loadingIntegrationCategory = true;
   			}else if(resource == 'customer'){
   				this.loadingIntegrationCustomers = true;
   			}else if(resource == 'order'){
   				this.loadingIntegrationOrder = true;
   			}
   			if((resource == 'product')){
   				return this.getChannelProductById(items, isMultiple);
   			}else{
   				return this.runImportQuery(resource, items, isMultiple);
   			}
   			
   		},
   		async channelResourceImportAll(){
   			const timer = ms => new Promise(res => setTimeout(res, ms));
   			this.loadingMultipleImport = true;
   			if(this.channels_area_active !== ''){
   				if(this.channels_area_active !== 'product'){
		   			var key = '';
		   			var area = '';
		   			if(this.channels_area_active == 'category'){
		   			 key = 'selected_channel_categories';
		   			 area = 'categories';
		   			}
		   			if(this.channels_area_active == 'order'){
		   			 key = 'selected_channel_orders';
		   			 area = 'orders';
		   			}
		   			if(this.channels_area_active == 'customer'){
		   			 key = 'selected_channel_customers';
		   			 area = 'customers';
		   			}
		   			this.multiItemsUploadTotal = this.$data[key][area].length;
		   			for(var i = 0; i < this.$data[key][area].length; i++){
		   				this.multiItemsIndex = i;
		   				await this.channelResourceImport(this.channels_area_active, this.$data[key][area][i], true);

	   					this.multiItemsIndex++;
		   				await timer(6000);
		   			}
	   			}else{
	   				this.multiItemsUploadTotal = this.selected_channel_products['products'].length;
	   				this.multiItemsExists = 0;
	   				for(var i = 0; i < this.selected_channel_products['products'].length; i++){
	   					this.multiItemsIndex = i;
	   					var index = this.all_product_ids.filter(item => item["product_channels"].find(subitem => {
		   					if(subitem.channels_product_id == this.selected_channel_products['products'][i]['id']){
		   						return true;
		   					}else{
		   						return false;
		   					}
   						}));
   						if(index == false){
			   				await this.channelResourceImport('product', this.selected_channel_products['products'][i], false);
		   					this.multiItemsIndex++;
		   					await timer(6000);
			   			}else{
		   					this.multiItemsIndex++;
		   					this.multiItemsExists ++;
		   					await timer(100);
			   			}
	   					
	   				}
	   			}	
   			}
   			let self = this;
		   				setTimeout(function(){
		   							self.loadingMultipleImport = false;
		   				}, 300);
   		},
   		async categoryExportPost(exportData, items, isMultiple){
   			var prodpost = {};
   			prodpost["category"] = exportData;
   			axios.post('/api/company/integrations/channelsreq', {type: 'POST',url: 'api/v2/categories', auth: this.selected_integration.pass, postdata: prodpost},  {headers: {
	    		'Authorization' : 'Bearer '+this.$attrs.user.api_token
	    		}}).then(response => {
		   				let self = this;
		   				setTimeout(function(){
		   							self.success_text = "Category Successfully Exported";
   									self.success_message = true;
   									self.success_count = 5;
		   					return self.categoryPostExport(response.data, items, isMultiple);
		   				}, 300);
	   			}).catch(error => {

	   			});

   			/*axios.post('https://api.ecartapi.com/api/v2/categories', {category: exportData}, {headers: { 
		   				'Authorization' : this.selected_integration.pass,
		   				'Content-Type': 'application/json'
		   			}}).then(response => {
		   				let self = this;
		   				setTimeout(function(){
		   							self.success_text = "Product Successfully Exported";
   									self.success_message = true;
   									self.success_count = 5;
		   					return self.categoryPostExport(response.data, items, isMultiple);
		   				}, 300);
	   			}).catch(error => {

	   			});*/
   		},
   		async productExportPost(exportData, items, isMultiple){
   			var prodpost = {};
   			prodpost["product"] = exportData;

   			axios.post('/api/company/integrations/channelsreq', {type: 'POST',url: 'api/v2/products', auth: this.selected_integration.pass, postdata: prodpost},  {headers: {
	    		'Authorization' : 'Bearer '+this.$attrs.user.api_token
	    		}}).then(response => {
		   				let self = this;
		   				setTimeout(function(){
		   								self.products_loading_text = "Product Successfully Exported. Linking Resources...";
		   							self.products_loading_progress = 80;
   									//self.loadingIntegrationProducts = false;
   									return self.productPostExport(response.data, items, isMultiple);
		   				}, 300);
		   				
	   			}).catch(error => {

	   			});

   			/*axios.post('https://api.ecartapi.com/api/v2/products', {product: exportData}, {headers: { 
		   				'Authorization' : this.selected_integration.pass,
		   				'Content-Type': 'application/json'
		   			}}).then(response => {
		   				let self = this;
		   				setTimeout(function(){
		   								self.products_loading_text = "Product Successfully Exported. Linking Resources...";
		   							self.products_loading_progress = 80;
   									//self.loadingIntegrationProducts = false;
   									return self.productPostExport(response.data, items, isMultiple);
		   				}, 300);
		   				
	   			}).catch(error => {

	   			});*/
   		},
   		async channelResourceExport(resource, items, isMultiple ){
   			var exportData = '';
   			if(isMultiple == true){
   				for(var i = 0; i < items.length; i++){
   					if(resource == 'product'){
   						this.loadingProducts = true;
   						exportData[i] = await this.compileProductExport(items[i]);
   					}
   				}
   			}else{
   				if(resource == 'product'){
   					//console.log(items);
   					this.loadingProducts = true;
   					exportData = await this.compileProductExport(items);
   					this.productExportPost(exportData, items, isMultiple);
   				}else if(resource == 'category'){
   					exportData = await this.compileCategoryExport(items);
   					return this.categoryExportPost(exportData, items, isMultiple);
   				}


   			}
   			if(resource == 'product'){
	   			
   			}
   		},
   		async toImageBlob(url) {
		    const response = await fetch(url);
		    const blob = await response.blob();
		    const reader = new FileReader();
		    await new Promise((resolve, reject) => {
		      reader.onload = resolve;
		      reader.onerror = reject;
		      reader.readAsDataURL(blob);
		    });
		    return reader.result.replace(/^data:.+;base64,/, '');
  		},
  		viewResourceLinkedProduct(product){
  			var channelproduct = product.product_channels.filter(item => item.company_app_integration_id == this.selected_integration.id);
  			this.linked_product_view = this.selected_channel_products["products"].filter(item => item.id == channelproduct[0].channels_product_id);
  			this.$bvModal.show('linked-product');  			
  		},
  		categoryPostExport(response, items, isMultiple){
  			this.categoryLink(response, items, isMultiple);
  		},
		productPostExport(response, items, isMultiple){
			this.productLink(response, items, isMultiple);
			if(isMultiple == false){
				if(items["cover_image"] !== null){
				this.productImagesExport(response, items, isMultiple);
				}
			}
			let self = this;
		   				setTimeout(function(){
		   							self.products_loading_text = "Product Exported";
		   							self.products_loading_progress = 100;
		   							self.loadingProducts = false;
   									self.all_products = '';
									return self.getProducts();
		   				}, 800);
			
		},
		categoryLink(response, items, isMultiple){
			if(isMultiple == false){
				axios.post('/api/company/integrations/category/'+items.id+'/link', {integration: this.selected_integration, integration_item: response}, {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {

					return this.getCategories();
				}).catch(error => {

				});
			}else{

			}
		},
		channelResourceLink(gemesys_product, external_product, isMultiple, resource){
   			if(resource == 'product'){
   				var extprod = [];
   				extprod["product"] = external_product;
   				this.productLink(extprod, gemesys_product, isMultiple);
   			}
   		},
		productLink(response, items, isMultiple){
			if(isMultiple == false){
				axios.post('/api/company/integrations/product/'+items.id+'/link', {integration: this.selected_integration, integration_product: response["product"]}, {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
					let self = this;
		   				setTimeout(function(){
		   							self.products_loading_text = "Product Images Exported";
		   							self.products_loading_progress = self.products_loading_progress + 5;
   									self.all_products = '';
									return self.getProducts();
		   				}, 300);
					
				}).catch(error => {

				});
			}else{

			}
		},
		/*manualProductLink(response, items){
				axios.post('/api/company/integrations/product/'+items.id+'/link', {integration: this.selected_integration, integration_product: response["product"]}, {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
					this.all_products = '';
					return this.getProducts();
				}).catch(error => {

				});
		},
		markLinked(){

		},*/
   		async productImagesExport(responsedata, items, isMultiple){
   			if(isMultiple == false){
	   			var id = responsedata["product"]["id"];
	   			if(items["cover_image"] !== null){
		   			var coverimgfname = (items["cover_image"]["gallery"]).replaceAll('//', '/');
		   			coverimgfname = coverimgfname.split("/");
		   			coverimgfname = coverimgfname[coverimgfname.length - 1];
		   			var imageUrl = this.storageurl+(items["cover_image"]["gallery"]).replaceAll('//', '/');
		   			var img = await this.toImageBlob(imageUrl);
	   			}
	   			var postprod = {};
	   			postprod["image"] = 
	   			axios.post('/api/company/integrations/channelsreq', {type: 'POST',url: 'api/v2/products/'+id+'/images', auth: this.selected_integration.pass, postdata: {image: {filename: coverimgfname, content: img}}},  {headers: {
	    		'Authorization' : 'Bearer '+this.$attrs.user.api_token
	    		}}).then(response => {
	   				
	   			}).catch(error => {

	   			});
				/*axios.post('https://api.ecartapi.com/api/v2/products/'+id+'/images', {image: {filename: coverimgfname, content: img}}, {headers : {
   				'Authorization' : this.selected_integration.pass,
   				'Content-Type': 'application/json'
	   			}}).then(response => {
	   				
	   			}).catch(error => {

	   			});*/
   			}else{
   				for(var i = 0; i < items.length; i++){
   					var id = responsedata["product"]["id"];
		   			if(items[i]["cover_image"] !== null){
			   			var coverimgfname = (items[i]["cover_image"]["gallery"]).replaceAll('//', '/');
			   			coverimgfname = coverimgfname.split("/");
			   			coverimgfname = coverimgfname[coverimgfname.length - 1];
			   			var imageUrl = this.storageurl+(items[i]["cover_image"]["gallery"]).replaceAll('//', '/');
			   			var img = await this.toImageBlob(imageUrl);
		   			}
		   			axios.post('/api/company/integrations/channelsreq', {type: 'POST',url: 'api/v2/products/'+id+'/images', auth: this.selected_integration.pass, postdata: {image: {filename: coverimgfname, content: img}}},  {headers: {
		    		'Authorization' : 'Bearer '+this.$attrs.user.api_token
		    		}}).then(response => {
		   				
		   			}).catch(error => {

		   			});
					/*axios.post('https://api.ecartapi.com/api/v2/products/'+id+'/images', {image: {filename: coverimgfname, content: img}}, {headers : {
	   				'Authorization' : this.selected_integration.pass,
	   				'Content-Type': 'application/json'
		   			}}).then(response => {
		   				
		   			}).catch(error => {

		   			});*/
	   				}
   			}
   			let self = this;
		   				setTimeout(function(){
		   								self.products_loading_text = "Product Images Exported";
		   							self.products_loading_progress = self.products_loading_progress + 5;
   								
		   				}, 300);
   		},
   		async compileCategoryExport(resource){
   			var category = {};
   			category["name"] = resource["name"];
	   			if(resource["description"] !== null){
	   				category["description"] = resource["description"];
	   			}else{
	   				category["description"] = 'Gemesys category with no description';
	   			}

	   			/*if(this.push_category !== 0){
	   				category["parent_id"] = this.push_category["id"];
	   			}else{
	   				if(this.selected_integration.integration_name !== 'Prestashop'){
	   					category["parent_id"] = '';
	   				}else{
	   					category["parent_id"] = '2';
	   				}
	   			}*/
   			return category;
   		},
   		async compileProductExport(resource){
   			var product = JSON.parse(JSON.stringify(this.channel_product));
   			//product["id"] = '';
			product["sku"] = resource["reference"];
			product["name"] = resource["name"];
			product["subName"] = resource["subName"];
			if(resource["barcode"] !== null){
				if(resource["barcode"]["UPC"] !== null){
					//product["upc"] = resource["barcode"]["UPC"];
				}
			}
			if(this.selected_integration.integration_name !== 'Prestashop'){
				product["description"] = resource["long_description"];
			}else{
				product["description"] =  resource["long_description"].replace(/<[^>]+>/g, '') ;
			}
			product["currency"] = resource["currency"];
			product["productType"] = resource["productType"];
			if(this.addManualQty == false){
				product["quantity"] = (resource["total_qty"]).toString();
			}else if(this.addManualQty == true){
				product["quantity"] = (this.push_quantity).toString();
			}
			//product["inventoryQuantity"] = (resource["total_qty"]).toString();
			product["price"] = resource["sales_price"];
			/*if(resource["cost_price"] > 0){
				product["cost"] = resource["cost_price"];
			}*/
			//product["dimensions"] = {};
			//product["units"] = {};
			/*
			if((this.selected_integration.integration_name !== 'Prestashop') && (this.push_category !== 0)){
				product["category"] = 
				{
				 id: this.push_category["id"], 
				 name: this.push_category["name"], 
				};
			}
			*/
			if(resource["search_keywords"] !== null){
				product["tags"] = resource["search_keywords"];
			}else{
				//product["tags"] = [];
			}
			product["status"] = {
				//id:'',
				//visibility:"visible",
				active:"true",
				//status:"publish",
			};
			//product["variants"] = [];
			//product["options"] = [];
			//product["associatedItems"] = [],
			/*product["logistic"] = {
				mode:null,
				type:null,
				free:"false",
				direction:null,
				dimensions:null
			};
			if(resource["images"] !== null){
				product["images"] = [];
				for(var imgind; imgind < (resource["images"].length - 1); imgind++){
					var imageItem = {
					 url: this.toImageBlob(this.storageurl + (resource["images"][imgind]["gallery"]).replaceAll('//', '/'), function(dataUrl) {
	  						return dataUrl;
						}),
					 variantIds:[],
					};
					product["images"].push(imageItem);
				}
			}else{
				product["images"] = [];
			}
			if(resource["cover_image"] !== null){
				if(this.selected_integration.integration_name !== 'Prestashop'){
				var imageUrl = this.storageurl + (resource["cover_image"]["gallery"]).replaceAll('//', '/');
				product["imageUrl"] = await this.toImageBlob(imageUrl);
				//console.log(product["imageUrl"]);
				}
			}*/
			if(resource["condition"] !== null){
				if(this.selected_integration.integration_name == 'Prestashop'){
					if(resource["condition"] == 'Antique'){
						product["condition"] = 'used';
					}else if(resource["condition"] == 'Bespoke'){
						product["condition"] = 'new';
					}
				}else{
					product["condition"] = resource["condition"];
				}
			}
			//product["link"] = null;
			//product["dates"] = {};
			product["vendorId"] = "1";
			product["vendor"] = this.$attrs.company.name;
			product["sellOutStock"] = "true";
			return product;
   		},
   		productMatch(channels_uid){
   			return this.all_products.data.filter(item => item.channel_product_id == channels_uid);
   		},
   		productMatchExists(channels_uid){
   			var index = this.all_products.data.findIndex(item => item.channel_product_id == channels_uid);
   			if(index > -1){
   				return true;
   			}else{
   				return false;
   			}
   		},
   		productMatchQuantity(price_type, channels_uid){
   			var item = ''; 
   			item = this.all_products.data.filter(item => item.channel_product_id == channels_uid);
   			if(item.length > 0){
   			 	return item[0][''+price_type+''];
   			}else{
   				return 0;
   			}
   		},
   		addChannelProducts(product, sync_quantity){
   			var productitem = {
				  'store_id': this.ecommerce_channels_settings["pass"],
				  'channel_id': this.selected_integration["id"],
				  'sku': product["reference"],
				  'quantity': sync_quantity,
				  'product_nominal_code': null,
				  'tax_type': null,
				  'channels': [{
						  'title' : product["name"],
						  'description' : product["description"],
						  //'image_url' : product["cover_image"]["src"],
						  'store_id': this.ecommerce_channels_settings["pass"],
				  		  'channel_id': this.selected_integration["id"],
						}]
				};
			axios.post('https://production.channelapi.co.uk/api/v1/products/create', productitem, {headers: { 
   				'Authorization' : 'Bearer 7rqBmd0TINWuRlVXilLZro4JpvJJitz',
   				'Content-Type': 'application/json'
   			}}).then(response => {
				return this.syncChannelProduct(response, product);
			}).catch(error => {

			});
   		},
   		syncChannelProduct(response, product){
   			
   		},
   		saveChannelProduct(response, product){
   			axios.post('/api/company/product/'+product.id+'/channel-sync', {channel_data: response.data, product_id: product.id}, {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {

   			}).catch(error => {

   			});
   		},
   		copyLink(elementid){
	        var copyText = document.getElementById(""+elementid+"");
	        //console.log(copyText.value);
	        this.copy(copyText.value);
	        /* Select the text field */
	        copyText.select();
	        copyText.setSelectionRange(0, 99999); /*For mobile devices*/

	        /* Copy the text inside the text field */
	        document.execCommand("copy");
    	},
	    async copy(s) {
	      await navigator.clipboard.writeText(s);
	       Vue.swal({title: "Text Copied to Clipboard"});
	      },
	     filterCompanyFeeds(service){

	     },
	     editCompanySocialProfile(action){
	     	if(action == 'update'){
	     		var query = '/'+this.selected_social_profile.id+'/update';
	     	}else if(action == 'create'){
	     		var query = '/create';
	     		this.selected_social_profile['username'] = this.newsocial.username;
	     		this.selected_social_profile['url'] = this.newsocial.url;
	     	}else if(action == 'destroy'){
	     		var query = '/destroy';
	     	}
	     	axios.post('/api/company/settings/social-profile'+query, this.selected_social_profile, {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
	     		this.getCompanySocialProfiles();
	     	let self = this;
	    		setTimeout(
	    			function(){
	    				self.loadingFeeds = false;
	    				self.$bvModal.hide('socialprofile');
	    			});
	    	}).catch(error => {
	    			
	    	});
	    },
	    getCompanySocialProfiles(){
	     	axios.get('/api/company/settings/social-profiles?limit=0', {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
	     		this.social_profiles = response.data;
	     	let self = this;
	    		setTimeout(
	    			function(){
	    				self.loadingFeeds = false;
	    			});
	    	}).catch(error => {

	    	});
	    },
	     getSocialImage(type_name){
	     	return this.available_social_profiles.filter(item => item.name == type_name)[0].image;
	     },
	    getCompanyFeeds(service){
	    	this.loadingFeeds = true;
	    	this.feeds = '';
	    	axios.get('/api/company/feeds?service='+service, {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
	    		this.feeds = response.data;

	    		let self = this;
	    		setTimeout(
	    			function(){
	    				self.loadingFeeds = false;
	    			});
	    	}).catch(error => {

	    	});
	    },
	    fetchChannelsIntegrationsDetails(service){
	    	axios.get('/api/company/integrations?service='+service, {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
	    		this.ecommerce_channels_settings = response.data;
	    		this.marketplace_channels_settings = response.data;
	    		this.all_active_commerce_channels = response.data.data;
   				this.active_ecommerce_integrations = this.filterActiveChannels(response.data.data, this.channels_type);
	    		let self = this;
	    		setTimeout(
	    			function(){
	    				self.loadingIntegrations = false;
	    				if(this.selected_integration_uid !== ''){
	    					return self.getActiveChannel(response.data.data);
	    				}
	    			}, 300);
	    	}).catch(error => {

	    	});
	    },
	    getActiveChannel(channels){
	    	
	    	this.getProducts();
	    	axios.get('/api/company/integrations/'+this.selected_integration_uid, {headers: {
	    		'Authorization' : 'Bearer '+this.$attrs.user.api_token
	    	}}).then(response => {
	    		this.selected_integration = response.data;

	    		return this.getChannelCategories();
	    	}).then(response => {

	    	});
	    	
	    },
	    getActiveChannelResources(){
	    	this.getChannelProducts();
	    	this.getChannelCategories();
	    	this.getChannelCustomers();
	    	this.getChannelOrders();
	    	this.activeWebhooks();
	    	this.selected_integration_uid = this.selected_integration.uid;
	    },
	    /*
	    getActiveChannels(store_id){
   			axios.get('https://production.channelapi.co.uk/api/v1/channels/list?store_id='+store_id, {headers: { 
   				'Authorization' : 'Bearer 7rqBmd0TINWuRlVXilLZro4JpvJJitz',
   				'Content-Type': 'application/json'
   			}}).then(response => {
   				this.all_active_commerce_channels = response.data.data;
   				this.active_ecommerce_integrations = this.filterActiveChannels(response.data, 'ecommerce');
   			}).catch(error => {

   			});
   		},
   		*/
	    getProducts(){
	    	if(this.all_products !== ''){
		        if(this.limit > this.all_products.total){
		          this.currentPage = 1;
		        }
		  		}else{
		  			this.getAllProductIds();
		  		}
		  	
		  		this.loadingProducts = true;
	    	this.products_loading_progress = 50;
		      axios.get('/api/company/products?page='+this.productscurrentPage+'&limit='+this.productslimit+'&sortby='+this.productssortby+'&order=DESC&search='+this.productssearch+'&trade_active=&public_active=&active=&brands=&collections=&pricerange=', {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
		        //console.log(response);
		        this.all_products = response.data;
		        this.products_loading_progress = 100;
		        let self = this;
	    		setTimeout(
	    			function(){
	    				self.loadingProducts = false;
	    			}, 800);
		      });
		  
	    },
	    getAllProductIds(){
		      axios.get('/api/company/products?page=1&limit=0&sortby='+this.productssortby+'&order=DESC&search='+this.productssearch+'&trade_active=&public_active=&active=&brands=&collections=&pricerange=', {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
		        //console.log(response);
		        this.all_product_ids = response.data;
		        this.products_loading_progress = 100;
		        let self = this;
	    		setTimeout(
	    			function(){
	    				self.loadingProducts = false;
	    			}, 800);
		      });
		  
	    },
	    getCategories(){
	    	this.loadingCategories = true;
	    	this.categories_loading_progress = 50;
	    	axios.get('/api/company/product/categories?page='+this.categoriescurrentPage+'&api_token='+this.$attrs.user.api_token+'&page=&limit=0&sortby=id&order=desc&search=&parent_id=&marketplace_categories=company', {
	    		headers: {
	    			'Authorization' : 'Bearer '+this.$attrs.user.api_token
	    		}
	    	}).then(response => {
				this.all_categories = response.data;
				this.categories_loading_progress = 100;
		        let self = this;
	    		setTimeout(
	    			function(){
	    				self.loadingCategories = false;
	    			});
			}).catch(error => {
				this.error_reponse = error.data;
				this.error_message = true;
			});
	    },
	    getCompanyIntegrations(service){
	    	this.loadingIntegrations = true;
	    	this.feeds = '';
	    	axios.get('/api/company/integrations?service='+service, {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
	    		this.integrations = response.data;

	    		let self = this;
	    		setTimeout(
	    			function(){
	    				self.loadingIntegrations = false;
	    			});
	    	}).catch(error => {

	    	});
	    },
	    filteredCommerceChannels(filter_by){
	    	return this.all_commerce_channels.filter(item => item.type == filter_by);
	    },
   		filterActiveIntegrations(area_key, integration){
   			var index = this.active_app_integrations[area_key].findIndex(item => (item.value == integration.value) && (item.active == true))
   			if(index > -1){
   				return true;
   			}else{
   				return false;
   			}
   		},
   		updateActiveIntegrations(area_key, integration){
   			/*var index = this.active_app_integrations[area_key].findIndex(item => item.value == integration.value)
   			if(index > -1){
   				// this.active_app_integrations[area_key].splice(index, 1);
   			}else{
   				 this.active_app_integrations[area_key].push(integration);
   				 
   			}*/
   			//this.app_integrations_settings = this.active_app_integrations[area_key][this.active_app_integrations[area_key].length - 1];
   			if(integration.type == 'feed'){
   				this.getCompanyFeeds(integration.value);
   			}
   			if((integration.type == 'integration') || (integration.type == 'mail')){
   				this.getCompanyIntegrations(integration.value);
   			}
   			this.app_integrations_settings = integration;
   			this.integrationsSettingsModal = true;
   		},
   		saveIntegrationSettings(){
   			this.app_integrations_settings.active = true;
   			this.integrationsSettingsModal = false;
   		},
   		removeIntegrationSettings(){
   			this.app_integrations_settings.active = false;
   			this.integrationsSettingsModal = false;
   		},
   		locationListingLink(location){
   			axios.post('/api/company/settings/payments/location/link', {location: location, listing: this.linklisting}, {}).then(response => {
   				var listindex = this.alllistings.findIndex(item => item.stripe_id == location.id);
   				this.alllistings[listindex] = response.data;
   			}).catch(error => {

   			});
   		},
   		readerLocationLink(reader_id, location_id){

   		},
   		getLocationStripeConnection(stripe_id){
   			var location = this.alllistings.filter(item => item.stripe_id == stripe_id);
   			if(location.length > 0){
   				return location[0];
   			}else{
   				return false;
   			}
   		},
   		getReaderLocationConnection(stripe_id){
   			var location = this.alllistings.filter(item => item.stripe_id == stripe_id);
   			if(location.length > 0){
   				return location[0];
   			}else{
   				return false;
   			}
   		},
   		activateCapability(type,value){
   			axios.post('/api/company/settings/payments/accounts/update-capability?'+type+'='+value, {headers: {
   				'Authorization' : 'Bearer '+this.$attrs.user.api_token
   			}}).then(response => {

   			}).catch(error => {

   			});
   		},
   		registerStripeLocation(location){
   			axios.post('/api/company/settings/payments/locations/create',  location, {
   				headers: {
   					'Authorization' : 'Bearer '+this.$attrs.user.api_token
   				}
   			}).then(response => {
   				this.stripeAccount[0].locations.data.push(response.data[0]);
   			}).catch(error => {

   			});
   		},
   		destroyChannel(integration, index){
   			/*var config = {
			  method: 'delete',
			  url: 'https://api.ecartapi.com/api/v2/services/access/delete',
			  headers: { 
			    'Authorization' : this.selected_integration.pass,
			  }
			}*/
   			axios.post('/api/company/integrations/channelsreq', {type: 'DELETE', url: 'api/v2/services/access/delete', auth: this.selected_integration.pass}, {headers: {
   				'Authorization' : 'Bearer '+this.$attrs.user.api_token
   			}}).then(response => {
   				return this.destroyIntegration(integration, index);
   			}).catch(error => {

   			});

   			/*var config = {
			  method: 'delete',
			  url: 'https://api.ecartapi.com/api/v2/services/access/delete',
			  headers: { 
			    'Authorization' : this.selected_integration.pass,
			  }
			};
   			axios(config).then(response => {
   				return this.destroyIntegration(integration, index);
   			}).catch(error => {

   			});*/
   		},
   		destroyIntegration(integration, index){
   			axios.post('/api/company/integrations/'+integration.id+'/destroy', {uid: integration.uid}, {headers: {
   				'Authorization' : 'Bearer '+this.$attrs.user.api_token
   			}}).then(response => {
   				this.active_ecommerce_integrations.slice(index, 0);
   			}).catch(error => {

   			});
   		},
   		destroyStripeLocation(location, index){
   			axios.post('/api/company/settings/payments/locations/delete',  location, {
   				headers: {
   					'Authorization' : 'Bearer '+this.$attrs.user.api_token
   				}
   			}).then(response => {
   				this.stripeAccount[0].locations.data.slice(index, 0);
   			}).catch(error => {

   			});
   		},
   		registerReader(){
   			axios.post('/api/company/settings/payments/terminal/create', this.addreader, {headers: {
   				'Authorization' : 'Bearer '+this.$attrs.user.api_token
   			}}).then(response => {

   			}).catch(error => {

   			});
   		},
   		updateSettings(view){
   			if(view == 'Website'){
   				this.saveWebsiteSettings();
   			}else if(view == 'Company'){
   				this.loadingCompanySettings = true;
   				this.company_settings_progress = 20;
   				this.updateCompanyDetails();
   			}else if(view == 'Directory'){
   				this.loadingCompanySettings = true;
   				this.company_settings_progress = 20;
   				this.updateCompanyDetails();
   			}else if(view == 'Enquiries'){
   				//this.company_update.settings = settings;
   				this.updateCompanySettings('enquiry', this.enquiry);
   			}else if(view == 'Support'){
   				//this.company_update.settings = settings;
   				this.updateCompanySettings('support', this.support);
   			}else if(view == 'Order'){
    			//this.company_update.settings = settings;
   				this.updateCompanySettings('invoice', this.invoice);
   			}else{
   				this.updateCompanyDetails();
   			}

   		},
   		setupOauthIntegration(){
   			this.loadingEcommerceIntegration = true;
   			this.oauth_setup_progress = 20;
   			axios.post('/api/company/integrations/setup-oauth', {url: this.ecommerce_website_url, type: this.channels_type, integration_name: this.selected_ecommerce_integration.name, webhooks: this.registered_webhooks}, {headers: {
   				'Authorization' : 'Bearer '+this.$attrs.user.api_token
   			}}).then(response => {
   				this.oauth_setup_text = 'Website Data Saved on Gemesys. Redirecting to Integration...';
   				this.oauth_setup_progress = 20;
   				let self = this;
				setTimeout(function(){
					if(self.selected_ecommerce_integration.name !== 'Prestashop'){
   					return self.goToLink('https://oauth.ecartapi.com/9nXKTcHi7IcNtnYlJr4CnANydiy0teRr?nobar=true&state='+self.$attrs.company.nickname+'.'+response.data.uid+'&ecommerce='+self.selected_ecommerce_integration.name+'&name='+self.$attrs.company.nickname);
   					}else{
   					return self.goToLink('https://oauth.ecartapi.com/9nXKTcHi7IcNtnYlJr4CnANydiy0teRr?nobar=true&state='+self.$attrs.company.nickname+'.'+response.data.uid+'&ecommerce='+self.selected_ecommerce_integration.name+'&name='+self.$attrs.company.nickname);

   					}
   				}, 500);
   			}).catch(error => {

   			});
   		},
   		completeOauthSetup(integration_name, integration_uid){

   			if(this.integration_name !== 'Prestashop'){
   					return this.goToLink('https://oauth.ecartapi.com/9nXKTcHi7IcNtnYlJr4CnANydiy0teRr?nobar=true&state='+this.$attrs.company.nickname+'--'+integration_uid+'&ecommerce='+this.integration_name+'&name='+this.$attrs.company.nickname+'&url='+this.ecommerce_website_url+'');
   					}else{
   					return this.goToLink('https://oauth.ecartapi.com/oauth/9nXKTcHi7IcNtnYlJr4CnANydiy0teRr/integration/prestashop?nobar=true&state='+this.$attrs.company.nickname+'--'+integration_uid+'&ecommerce='+this.integration_name+'&name='+this.$attrs.company.nickname+'&url='+this.ecommerce_website_url+'');

   					}
   			//return this.goToLink('https://oauth.ecartapi.com/9nXKTcHi7IcNtnYlJr4CnANydiy0teRr?nobar=true&state='+this.$attrs.company.nickname+'--'+integration_uid+'&ecommerce='+integration_name+'&name='+this.$attrs.company.nickname+'&url='+this.ecommerce_website_url+'');
   		},
   		setUpWebsite(){
   			this.website_setup_started = true;
   			this.loadingWebsiteSettings = true;
   			this.website_setup_progress = 10;
   			if(this.website.uid == ''){
	   			axios.post('/api/company/web/settings/create', this.website, {headers: {
	   				'Authorization' : 'Bearer '+this.$attrs.user.api_token
	   			}}).then(response => {
	   				this.website_setup_progress = 90;
	   				this.website = response.data;
	   				  let self = this;
					         setTimeout(function(){
					         		//self.getActiveShippingIntegrations();
					         		self.website_setup_progress = 100;
					         		self.loadingWebsiteSettings = false;
									  }, 500);
	   			}).catch(error => {
	   				this.error_reponse = error.data;
	   			});
   			}else{
   				this.saveWebsiteSettings();
   			}
   		},
   		loadWebsiteResource(type){
   			axios.get('/api/company/web/'+type+'?limit=0&sortby=id&order=desc', {headers : {'Authorization' : 'Bearer ' +this.$attrs.user.api_token}}).then(response => {
   				this.website_resource[type] = response.data;
   			}).catch(error => {
   				this.error_reponse = error.data;
   			});


   		},
   		getWebsiteSettings(){
   			this.website_setup_started = false;
   			this.loadingWebsiteSettings = true;
   			this.website_setup_progress = 5;
   			this.website_setup_progress = 30;
   			axios.get('/api/company/web/settings', {headers: {
   				'Authorization' : 'Bearer '+this.$attrs.user.api_token
   			}}).then(response => {
   				this.website_setup_progress = 75;
   				this.website = response.data;
   				let self = this;
				         setTimeout(function(){
				         		self.website_setup_progress = 100;
								  }, 500);
				         setTimeout(function(){
				         		self.loadingWebsiteSettings = false;
								  }, 1500);
   			}).catch(error => {
   				this.error_reponse = error.data;
   			});
   		},
   		saveWebsiteSettings(){
   			axios.post('/api/company/web/settings/update', this.website, {headers: {
   				'Authorization' : 'Bearer '+this.$attrs.user.api_token
   			}}).then(response => {
   				this.website = response.data;
   			}).catch(error => {
   				this.error_reponse = error.data;
   			});
   		},
   		loadFontWindow(){
   			this.loadingFonts = true;
   			let self = this;
			setTimeout(function(){
         		self.loadingFonts = false;
	     	}, 300);
   			
   		},
   		selectFont(type, level, payload){
   			if(type == "heading"){
   				this.website.fonts.heading_font[level] = payload;

   				var h = document.getElementsByTagName('head').item(0);
	   			var l = document.createElement("link");
	   			var firstchild = h.childNodes[0];
				l.rel = "stylesheet"; 
				l.id = "headingfontStylesheets-"+level;
				h.insertBefore(l, firstchild);
   				document.getElementById('headingfontStylesheets-'+level).href = 'https://fonts.googleapis.com/css2?family='+this.website.fonts.heading_font[level].family.replaceAll(' ','+')+'&display=swap';
   			}else if(type == "body"){
   				this.website.fonts.body_font = payload;
   				document.getElementById('bodyfontStylesheets').href = 'https://fonts.googleapis.com/css2?family='+this.website.fonts.body_font.family.replaceAll(' ','+')+'&display=swap';
   			}

   		},
   		getGFonts(){
   			axios.get('https://www.googleapis.com/webfonts/v1/webfonts?key='+this.gfontskey).then(response => {
   				this.gFonts = response.data;
   			}).catch(error => {

   			});
   		},
   		
   		/*refreshHead() {	
       		document.getElementById('customCss').innerHTML = this.customCss;
       		document.getElementById('loadFonts').innerHTML = this.loadFonts;
   		},*/
   		generatePassword(){
        var password = Array(14).fill('0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz').map(function(x) { return x[Math.floor(Math.random() * x.length)] }).join('');
        return password;
    	},
    	saveIntegration(integration_settings, feed_uid){
    		this.loadingFeeds = true;
    		axios.post('/api/company/integrations/generate', {integration: integration_settings, uid: feed_uid}, {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
    			this.feeds.push(response);
    			setTimeout(function(){
    				self.loadingFeeds = false;
    			});
    		}).catch(error => {

    		});
    	},
    	generateFeed(integration_settings, feed_uid){
    		this.loadingFeeds = true;
    		axios.post('/api/company/feeds/generate', {integration: integration_settings, uid: feed_uid}, {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
    			this.feeds.push(response);
    			setTimeout(function(){
    				self.loadingFeeds = false;
    			});
    		}).catch(error => {

    		})
    	},
    	destroyFeed(feed_uid){
    		this.loadingFeeds = true;
    		axios.post('/api/company/feed/delete', {uid: feed_uid}, {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
    			var feedIndex = this.feeds.findIndex(item => item.uid == feed_uid);
    			this.feeds.splice(feedIndex, 1);
    			setTimeout(function(){
    				self.loadingFeeds = false;
    			});
    		}).catch(error => {

    		})
    	},
   		getCourierApiKey(){
	   			if((this.shipping_apikeys == '') || (this.all_carrier_integrations.length == 0)){
		   			axios.get('/api/company/delivery-links', {headers: {
			    		'Authorization' : 'Bearer '+this.$attrs.user.api_token
			    	}}).then(response => {
			    							//console.log(response);
											this.shipping_apikeys = response.data;
												////console.log(this.shipping_apikeys.token);
												return this.getAllShippingIntegrations();
										}).catch(error => {
											//console.log(error);
											this.shipping_apikeys = '';
											this.shipping_progress = 100;
											this.loadingShippingSettings = false;
										});
					}
   		},
   		getAllShippingIntegrations(){
   			this.loadingShippingSettings = true;
   			this.getShippingIntegrations();
   			this.shipping_progress = 85;
   			this.getActiveShippingIntegrations();
   		},
   		setUpChannelsApiStore(){
   			this.channels_setup_progress = 10;
   			this.channels_setup_text = 'Setting up Channels Account...';
   			this.loadingChannelsSetup = true;
   			axios.post('https://production.channelapi.co.uk/api/v1/stores/create', {name: this.$attrs.company.nickname}, {headers: { 
   				'Authorization' : 'Bearer 7rqBmd0TINWuRlVXilLZro4JpvJJitz',
   				'Content-Type': 'application/json'
   			}}).then(response => {
   				this.channels_setup_progress = 30;
   				this.channels_setup_text = 'Account Set-up - Saving Details...';
   				var integrationData = {};
   				integrationData["value"] = 'channels-api';
   				integrationData["type"] = 'ecommerce';
   				integrationData["configs"] = response.data.data;
   				
   				return axios.post('/api/company/integrations/generate', {integration: integrationData}, {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
   						this.channels_setup_progress = 75;
   						this.channels_setup_text = 'Details Saved. Saving Webhooks. This may take a moment.';
   						//console.log(response.data);
   						this.ecommerce_channels_settings = response.data;
   						return this.setUpChannelsApiWebhooks(response.data);
   						}).catch(error => {

   						});
   			}).catch(error => {

   			});
   		},
   		setUpChannelsApiWebhooks(configs){
   			var header = {'Authorization' : 'Bearer '+this.$attrs.user.api_token};
   			for(var i = 0; i < this.channel_webhook_events.length; i++){
	   			var webhook = {
					  'store_id': ''+configs.pass+'',
					  'event': this.channel_webhook_events[i].name,
					  'method': this.channel_webhook_events[i].method,
					  'url': 'https://'+this.$attrs.company.nickname+'.gemesys.co.uk/api/company/channels-webhook'+this.channel_webhook_events[i].url,
					  'requires_auth': 'true',
					  'auth_header': 'Authorization',
					  'auth_key': 'Bearer '+this.$attrs.user.api_token,
					  'is_active': 'true',
					  'include_country_codes': '',
					  'exclude_country_codes': '',
					  'include_sku_codes': '',
					  'exclude_sku_codes': '' 
					};
	   			axios.post('https://production.channelapi.co.uk/api/v1/webhooks/create', webhook, {headers: { 
   				'Authorization' : 'Bearer 7rqBmd0TINWuRlVXilLZro4JpvJJitz',
   				'Content-Type': 'application/json'
   			}}).then(response => {
	   				this.registered_webhooks.push(response.data);
	   				this.channels_setup_progress = this.channels_setup_progress + 5;
   					this.channels_setup_text = 'Details Saved. Saving Webhooks '+ i + ' of ' +this.channel_webhook_events.length + 'created';
   					if(i == this.channel_webhook_events.length){
   						let self = this;
   						setTimeout(function(){
   							self.loadingChannelsSetup = false;
   						}, 1500);
   					}
	   			}).catch(error => {

	   			});
	   		}

   		},
   		setUpChannel(channel){
   			//var formData = new FormData(document.getElementById('ecommerce-integration-setup'));
   			for ( var key in this.selected_ecommerce_integration_auth_details){
   				if(this.selected_ecommerce_integration_auth_details[key] == ''){
   					this.selected_ecommerce_integration_auth_details[key] == null;
   				}
   			}
   			var formData = this.selected_ecommerce_integration_auth_details;
   			formData["store_id"] = this.ecommerce_channels_settings.pass;
   			formData["channel_type"] = channel.id;
   			var qs = require('qs');
   			var data = qs.stringify(formData);
   			
   			//console.log(data);

   				function replacer(key, value) {
				  if (typeof value === 'boolean') {
				    return value ? 'true' : 'false';
				  }
				  return value;
				}
   			//formData = JSON.stringify(formData);
   			axios.post('https://production.channelapi.co.uk/api/v1/channels/create/'+channel.id, data, {headers: { 
   				'Authorization' : 'Bearer 7rqBmd0TINWuRlVXilLZro4JpvJJitz',
   				'Accept': 'application/json', 
    			'Content-Type': 'application/x-www-form-urlencoded'
   			}}).then(response => {
	   				
	   			}).catch(error => {

	   			});
   		},
   		getAvailableChannelWebhooks(){
   			if(this.all_channel_webhooks.length == 0){
   				axios.post('/api/company/integrations/channelsreq', {type: 'OPTIONS',url: 'api/v2/webhooks', auth: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJOak01T1RFd01UZzRaakpsTkdJd01ERTJOVGsyWmpVMSIsImlhdCI6MTY3MTAyNTM4MzgwMX0.er8IVWgd7hhTdw3tBFHvMnEWb6BbJeDv1YceFLfvx1E'},  {headers: {
	    		'Authorization' : 'Bearer '+this.$attrs.user.api_token
	    		}}).then(response => {
	   				this.all_channel_webhooks = response.data["webhooks"];
	   			}).catch(error => {

	   			});	

	   			/*axios.options('https://api.ecartapi.com/api/v2/webhooks', {headers: {'Authorization' : 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJOak01T1RFd01UZzRaakpsTkdJd01ERTJOVGsyWmpVMSIsImlhdCI6MTY3MTAyNTM4MzgwMX0.er8IVWgd7hhTdw3tBFHvMnEWb6BbJeDv1YceFLfvx1E'}}).then(response => {
	   				this.all_channel_webhooks = response.data["webhooks"];
	   			}).catch(error => {

	   			});*/
   			}
   		},
   		activeWebhooks(integration){
   			//await this.getActiveChannel(integration);

   			axios.post('/api/company/integrations/channelsreq', {type: 'GET',url: 'api/v2/webhooks', auth: this.selected_integration.pass},  {headers: {
	    		'Authorization' : 'Bearer '+this.$attrs.user.api_token
	    		}}).then(response => {
	   				this.selected_channel_webhooks = response.data;
	   				this.products_loading_progress = 100;
			        let self = this;
		    		setTimeout(
		    			function(){
		    				self.loadingProducts = false;
		    			});
	   			}).catch(error => {

	   			});

   			/*axios.get('https://api.ecartapi.com/api/v2/webhooks', {maxBodyLength: Infinity, headers: { 
	   				'Authorization' : this.selected_integration.pass
	   			}}).then(response => {
	   				this.selected_channel_webhooks = response.data;
	   				this.products_loading_progress = 100;
			        let self = this;
		    		setTimeout(
		    			function(){
		    				self.loadingProducts = false;
		    			});
	   			}).catch(error => {

	   			});*/
   		},
   		createWebhook(){

   		},
   		filterWebhooks(channel){
   				this.active_channel_webhooks = this.all_channel_webhooks.filter(item => item.name == channel);
   				var keys = Object.keys(this.active_channel_webhooks[0]["webhooks"])
   				keys.forEach(element => this.registered_webhooks[element] = []);

   				if(this.selected_integration !== ''){
   					if(this.selected_integration.webhooks !== null){
   						this.registered_webhooks = this.selected_integration.webhooks;
   					}
   				}
   		},
   		getChannelProducts(){
   			this.channels_area_active = 'product';
	   			this.loadingIntegrationProducts = true;
		    	this.products_loading_progress = 50;
 				if((this.selected_channel_products == '') || (this.selected_channel_products['products'].length < this.productslimit)){
			    	axios.post('/api/company/integrations/channelsreq', {type: 'GET',url: 'api/v2/products?page='+this.productspage.toString()+'&limit='+this.productslimit+'', auth: this.selected_integration.pass},  {headers: {
		    		'Authorization' : 'Bearer '+this.$attrs.user.api_token
		    		}}).then(response => {
		    			if('error' in response.data){
		   				this.selected_channel_products = '';
		   				}else{
		   					this.selected_channel_products = response.data;
		   				}
		   				this.products_loading_progress = 100;
				        let self = this;
			    		setTimeout(
			    			function(){
			    				self.loadingIntegrationProducts = false;
								self.getChannelProductCount();
			    			}, 300);
		   			}).catch(error => {

		   			});
	   			}else{
	   				this.products_loading_progress = 100;
				        let self = this;
			    		setTimeout(
			    			function(){
			    				self.loadingIntegrationProducts = false;
								self.getChannelProductCount();
			    			}, 300);
	   			}
	   			/*axios.get('https://api.ecartapi.com/api/v2/products?page='+this.productscurrentPage+'&limit='+this.productslimit+'&sort=id&sortBy=desc', {headers: { 
	   				'Authorization' : this.selected_integration.pass,
	   				'Content-Type': 'application/json'
	   			}}).then(response => {
	   				this.selected_channel_products = response.data;
	   				this.products_loading_progress = 100;
			        let self = this;
		    		setTimeout(
		    			function(){
		    				self.loadingIntegrationProducts = false;
							self.getChannelProductCount();
		    			}, 300);
	   			}).catch(error => {

	   			});*/
   		},
   		getChannelProductCount(){

   			axios.post('/api/company/integrations/channelsreq', {type: 'GET',url: 'api/v2/products/count', auth: this.selected_integration.pass},  {headers: {
	    		'Authorization' : 'Bearer '+this.$attrs.user.api_token
	    		}}).then(response => {
	   				this.productschanneltotal = response.data.count;
	   				}).catch(error => {

	   			});
   			/*axios.get('https://api.ecartapi.com/api/v2/products/count', {maxBodyLength: Infinity,  headers: { 
	   				'Authorization' : this.selected_integration.pass,
	   			}}).then(response => {
	   				this.productschanneltotal = response.data.count;
	   				}).catch(error => {

	   			});*/
   		},
   		getChannelCategories(){
   			this.channels_area_active = 'category';
   			this.integration_loading_text = 'Loading integration categories...';
   			if(this.selected_channel_categories == ''){
   			this.loadingIntegrationCategories = true;
	    	this.products_loading_progress = 50;

	    	axios.post('/api/company/integrations/channelsreq', {type: 'GET',url: 'api/v2/categories?limit='+this.categorieslimit+'', auth: this.selected_integration.pass},  {headers: {
	    		'Authorization' : 'Bearer '+this.$attrs.user.api_token
	    		}}).then(response => {
	    			if('error' in response.data){
   						this.selected_channel_categories = '';
   					}else{
   						this.selected_channel_categories = response.data;
   					}
   				this.integration_loading_progress = 100;
		        let self = this;
	    		setTimeout(
	    			function(){
	    				self.loadingIntegrationCategories = false;
	    				
	    			});
   			}).catch(error => {

   			});
   			}else{
   				this.integration_loading_progress = 100;
		        let self = this;
	    		setTimeout(
	    			function(){
	    				self.loadingIntegrationCategories = false;
	    				
	    			});
   			}
   		},
   		getChannelCustomer(id){
   			//this.channels_area_active = 'customer';
   			this.integration_loading_text = 'Loading integration customers...';
   			
   			this.loadingIntegrationCustomers = true;
	    	this.products_loading_progress = 50;

	    	axios.post('/api/company/integrations/channelsreq', {type: 'GET',url: 'api/v2/customers/'+id+'', auth: this.selected_integration.pass},  {headers: {
	    		'Authorization' : 'Bearer '+this.$attrs.user.api_token
	    		}}).then(response => {
	    			if('error' in response.data){
   					var customerResponse = '';
   					this.loadingIntegrationCustomers = false;
   						}else{
   					var customerResponse = response.data;
   						}
   				this.products_loading_progress = 100;
		        let self = this;
	    		setTimeout(
	    			function(){
	    				self.loadingIntegrationCustomers = false;
	    				return self.channelResourceImport('customer', customerResponse["customer"], false )
	    			}, 300);
	    
   			}).catch(error => {

   			});

   			/*axios.get('https://api.ecartapi.com/api/v2/customers', {headers: { 
   				'Authorization' : this.selected_integration.pass,
   				'Content-Type': 'application/json'
   			}}).then(response => {
   				this.selected_channel_customers = response.data;
   				this.products_loading_progress = 100;
		        let self = this;
	    		setTimeout(
	    			function(){
	    				self.loadingIntegrationCustomers = false;
	    				
	    			});
   			}).catch(error => {

   			});*/
   		
   		},
   		getChannelCustomers(){
   			this.channels_area_active = 'customer';
   			this.integration_loading_text = 'Loading integration customers...';
   			if(this.selected_channel_customers == ''){
   			this.loadingIntegrationCustomers = true;
	    	this.integration_loading_progress = 50;
	    	axios.post('/api/company/integrations/channelsreq', {type: 'GET',url: 'api/v2/customers?limit='+this.customerslimit+'', auth: this.selected_integration.pass},  {headers: {
	    		'Authorization' : 'Bearer '+this.$attrs.user.api_token
	    		}}).then(response => {
	    			if('error' in response.data){
   						this.selected_channel_customers = '';
   					}else{
   						this.selected_channel_customers = response.data;
   					}
   				this.integration_loading_progress = 100;
		        let self = this;
	    		setTimeout(
	    			function(){
	    				self.loadingIntegrationCustomers = false;
	    				
	    			});
   			}).catch(error => {

   			});

   			/*axios.get('https://api.ecartapi.com/api/v2/customers', {headers: { 
   				'Authorization' : this.selected_integration.pass,
   				'Content-Type': 'application/json'
   			}}).then(response => {
   				this.selected_channel_customers = response.data;
   				this.products_loading_progress = 100;
		        let self = this;
	    		setTimeout(
	    			function(){
	    				self.loadingIntegrationCustomers = false;
	    				
	    			});
   			}).catch(error => {

   			});*/
   			}
   		},
   		getChannelOrders(){
   			this.channels_area_active = 'order';
   			this.integration_loading_text = 'Loading integration orders...';
   			if(this.selected_channel_orders == ''){
   			this.loadingIntegrationOrders = true;
	    	this.products_loading_progress = 50;

	    	axios.post('/api/company/integrations/channelsreq', {type: 'GET',url: 'api/v2/orders', auth: this.selected_integration.pass},  {headers: {
	    		'Authorization' : 'Bearer '+this.$attrs.user.api_token
	    		}}).then(response => {
	    			if('error' in response.data){
   						this.selected_channel_orders = '';
   					}else{
   						this.selected_channel_orders = response.data;
   					}
   				this.products_loading_progress = 100;
		        let self = this;
	    		setTimeout(
	    			function(){
	    				self.loadingIntegrationOrders = false;
	    				
	    			});
   			}).catch(error => {

   			});


   			/*axios.get('https://api.ecartapi.com/api/v2/orders?page='+this.integrationorderspage+'&limit=100', {headers: { 
   				'Authorization' : this.selected_integration.pass,
   				'Content-Type': 'application/json'
   			}}).then(response => {
   				this.selected_channel_orders = response.data;
   				this.products_loading_progress = 100;
		        let self = this;
	    		setTimeout(
	    			function(){
	    				self.loadingIntegrationOrders = false;
	    				
	    			});
   			}).catch(error => {

   			});*/
   			}
   		},
   		getChannelsImage(channel){
   			return this.all_commerce_channels.filter(item => item.name == channel)[0]["image"];
   		},
   		setUpCourierApiKey(){
   			var header = {'Authorization' : 'Bearer '+this.$attrs.user.api_token};
   			let self = this;
   			var pass = self.generatePassword();
   			axios.post('https://production.courierapi.co.uk/api/apiuser/create', { 
					    'name': this.$attrs.company.nickname, 
					    'key' : pass
	  			}, { headers: { 
					    'api-user': 'getCarriers', 
					    'api-token': 'griokptfhmzvbaqy', 
					    'Content-Type': 'application/json'
	  			}}).then(response => {
			    //this.setState({ courierapi_response: response.data });
			    this.courierapi_response = response.data;
			    return axios.post('/api/company/delivery-link-setup', response.data, {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}});
			  }).then(response => {
			  	//console.log(response);
			  	this.shipping_apikeys = response.data;
			  	return this.getShippingIntegrations();
			  }).catch(error => {
			  	//console.log(error);
			  });
		    	/**axios.all(createApiAccount(self.$attrs.company.nickname, pass)).then(axios.spread(function (response) {
								//companyList[ia].push(response.data)
								self.courierapi_response = response.data;
								//console.log(response.data);
								return axios.post('/api/company/order/delivery-options/integration/setup', response.data, {headers: {'Authorization' : 'Bearer '+self.$attrs.user.api_token}}).then(setupresponse => {
									self.shipping_apikeys = setupresponse.data;
								}).catch(setuperror => {
									//console.log(setuperror);
								});
							}));

		    	
	    	
   			async function createApiAccount(api_user, api_token){
			  		return await axios.post('https://production.courierapi.co.uk/api/apiuser/create', { 
					    'name': api_user, 
					    'key' : api_token
	  			}, { headers: { 
					    'api-user': 'getCarriers', 
					    'api-token': 'griokptfhmzvbaqy', 
					    'Content-Type': 'application/json'
	  			}});**/
			  	
   		},
   		setUpShippingAuth(event){
   			// event.preventDefault();

   			this.loadingShippingIntegration = true;
   			var formData = {};
   			formData["testing"] = true;
   			formData["auth_company"] = this.$attrs.company.nickname;
   			formData["auth"] = this.selected_carrier_auth_details;
   				axios.post('https://production.courierapi.co.uk/api/couriers/v1/'+this.selected_carrier_integration.key+'/register-auth', formData, { headers: { 
					    'api-user': this.$attrs.company.nickname, 
					    'api-token': this.shipping_apikeys.token,
					    'Content-Type': 'application/json'
	  			}}).then(response => {
	  				//console.log(response);
	  						this.active_carrier_integrations = [];
	  						this.getActiveShippingIntegrations();
		     			    let self = this;
				         setTimeout(function(){
				         		//self.getActiveShippingIntegrations();
				         		
				         		self.success_text = "Shipping Authorization Added";
   									self.success_message = true;
   									self.success_count = 5;
								    self.loadingShippingIntegration = false;
								  }, 500);
						//this.selected_carrier_integration = carrier;
	  			}).catch(error => {
	  				//console.log(error.data);
	  			});
   		},
   		deleteShippingAuth(auth){
   			// event.preventDefault();

   			this.loadingShippingIntegration = true;
   			var keyName = this.getShippingKey(auth.courier);
   			var formData = {};
   			formData["testing"] = true;
   			formData["auth_company"] = this.$attrs.company.nickname;
   			//formData["auth"] = this.selected_carrier_auth_details;
   				axios.post('https://production.courierapi.co.uk/api/couriers/v1/'+keyName+'/delete-auth', formData, { headers: { 
					    'api-user': this.$attrs.company.nickname, 
					    'api-token': this.shipping_apikeys.token,
					    'Content-Type': 'application/json'
	  			}}).then(response => {
	  				//console.log(response);
	  				this.active_carrier_integrations = [];
	  				this.getActiveShippingIntegrations();
		     			    let self = this;
				         setTimeout(function(){
				         		self.success_text = "Shipping Authorization Deleted";
   									self.success_message = true;
   									self.success_count = 5;
								    self.loadingShippingIntegration = false;
								  }, 500);
						
	  			}).catch(error => {
	  				//console.log(error.data);
	  			});
   		},
   		getCompanyDomains(){
   			axios.get('/api/company/web/domains?page=1&limit='+this.domainslimit+'&sortby=domain&order=desc&search='+this.domainsearch+'&active=', {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
		        //console.log(response);
		        this.domains = response.data;
		      }).catch(error => {
		        this.error_reponse = error.response.error;
		        this.error_message = true;
		      });
   		},
   		getOrderSettings(){
   			this.loadingCompanySettings = true;
   			this.getCurrencies();
   			if(this.order.statuses.length == 0){
   				this.getOrderStatuses();
   			}
   			if(this.order.trade_listings.length == 0){
	   			this.getCompanyTradeListings();
	   		}
	   		if(this.order.public_listings.length == 0){
	   			this.getCompanyPublicListings();
	   		}
	   		if(this.order.online_listings.length == 0){
	   			this.getCompanyShopListings();
	   		}
	   		this.getCompanySettings('invoice');
	   		
   		},
   		getCommerceChannels(){
   			if(this.all_commerce_channels.length == 0){

   				axios.post('/api/company/integrations/channelsreq', {type: 'GET',url: 'ecommerces', auth: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJOak01T1RFd01UZzRaakpsTkdJd01ERTJOVGsyWmpVMSIsImlhdCI6MTY3MTAyNTM4MzgwMX0.er8IVWgd7hhTdw3tBFHvMnEWb6BbJeDv1YceFLfvx1E'},  {headers: {
	    		'Authorization' : 'Bearer '+this.$attrs.user.api_token
	    		}}).then(response => {
	    			    //console.log(response);
	   				this.all_commerce_channels = response.data;
	   			}).catch(error => {

	   			});
	   			/*
	   			axios.get('https://api.ecartapi.com/ecommerces', {headers: { 
	   				'Authorization' : 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJOak01T1RFd01UZzRaakpsTkdJd01ERTJOVGsyWmpVMSIsImlhdCI6MTY3MTAyNTM4MzgwMX0.er8IVWgd7hhTdw3tBFHvMnEWb6BbJeDv1YceFLfvx1E'
	   			}}).then(response => {
	   				this.all_commerce_channels = response.data;
	   			}).catch(error => {

	   			});
	   			*/
   			}
   		},
   		
   		filterActiveChannels(list, integrationtype){
   			return list.filter(item => item.type == integrationtype);
   		},
   		getShippingIntegrations(){
   			if(this.all_carrier_integrations.length == 0){
	   			axios.get('https://production.courierapi.co.uk/api/couriers/v1/list-couriers', { headers: { 
					    'api-user': this.$attrs.company.nickname, 
					    'api-token': this.shipping_apikeys.token,
					    'Content-Type': 'application/json'
	  			}}).then(response => {
	  				//console.log(response.data);
	  				this.all_carrier_integrations = response.data.couriers;
	  				this.shipping_progress = 70;
	  			}).catch(error => {
	  				//console.log(error.data);
	  			});
  			}
   		},
   		getActiveShippingIntegrations(){
   			if(this.active_carrier_integrations.length == 0){
	   			axios.get('https://production.courierapi.co.uk/api/couriers/v1/list-registered-couriers', { headers: { 
					    'api-user': this.$attrs.company.nickname, 
					    'api-token': this.shipping_apikeys.token,
					    'Content-Type': 'application/json'
	  			}}).then(response => {
	  				//console.log(response.data);
	  				this.active_carrier_integrations = response.data;
	  				let self = this;
				         setTimeout(function(){
				         		//self.getActiveShippingIntegrations();
				         		self.shipping_progress = 100;
								  }, 500);
				         setTimeout(function(){
				         		//self.getActiveShippingIntegrations();
				         		self.loadingShippingSettings = false;
								  }, 1000);
	  				
	  			}).catch(error => {
	  				//console.log(error.data);
	  			});
  			}
   		},
   		getShippingAuthRules(carrier){
   			this.loadingShippingIntegration = true;
   			this.selected_carrier_auth_rules = '';
   			this.selected_carrier_auth_details = {};

   				axios.get('https://production.courierapi.co.uk/api/couriers/v1/'+carrier.key+'/register-auth-rules', { headers: { 
					    'api-user': this.$attrs.company.nickname, 
					    'api-token': this.shipping_apikeys.token,
					    'Content-Type': 'application/json'
	  			}}).then(response => {
	  				
	  				this.selected_carrier_auth_rules = response.data;
	  				Object.entries(response.data).forEach(([key, value]) => {
						    //console.log(key+value);
						    this.selected_carrier_auth_rules[key] = value.split("|");
						    this.selected_carrier_auth_details[key] = '';
						});
						let self = this;
				         setTimeout(function(){
								    self.loadingShippingIntegration = false;
								  }, 500);
						this.selected_carrier_integration = carrier;
	  			}).catch(error => {
	  				//console.log(error.data);
	  			});
   			
   		},
   		getChannelFormFields(channel){
   			axios.get('https://production.channelapi.co.uk/api/v1/channels/available/'+channel.id, {headers: { 
   				'Authorization' : 'Bearer 7rqBmd0TINWuRlVXilLZro4JpvJJitz',
   				'Content-Type': 'application/json'
   			}}).then(response => {
   				
   				return this.handleChannelForm(response.data, channel);

   			}).catch(error => {

   			});
   		},
   		handleChannelForm(channeldata, channel){
   			this.selected_ecommerce_integration_auth_rules = channeldata.data[0];
	  				Object.entries(channeldata.data[0]).forEach(([key, value]) => {
						    //console.log(key+value);
						    //this.selected_ecommerce_integration_auth_rules[key] = value.split("|");
						    this.selected_ecommerce_integration_auth_details[key] = '';
						});
   			this.selected_ecommerce_integration = channel;
						let self = this;
				         setTimeout(function(){
								    self.loadingEcommerceIntegration = false;
								  }, 500);
						
   		},
   		getUserRoles(){
	      axios.get('/api/user/roles', {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
              }}).then(response => {
	        this.user_roles = response.data;
	      }).catch(error => {
	        this.load_error = error.data;
	      });
	    },
	    getFactoryUserRolePermissions(id){
	    	axios.get('/api/company/user/permissions/default/'+id+'', {headers: {
	    		'Authorization' : 'Bearer '+this.$attrs.user.api_token
	    	}}).then(response => {
	    		if(response){
		        	this.permissions[0].add = response.data.add;
		        	this.permissions[0].view = response.data.view
	   				this.permissions[0].edit = response.data.edit;
	   				this.permissions[0].read = response.data.read;
	   				this.permissions[0].delete = response.data.delete;
   				}
   				this.setPermissions = true;
	      }).catch(error => {
	        this.load_error = error.data;
	      });
	    },
	    getUserRolePermissions(id){
	    	axios.get('/api/company/user/permissions/company-default/'+id+'', {headers: {
	    		'Authorization' : 'Bearer '+this.$attrs.user.api_token
	    	}}).then(response => {
	    		if(response){
		        	this.permissions[0].add = response.data.add;
		        	this.permissions[0].view = response.data.view
	   				this.permissions[0].edit = response.data.edit;
	   				this.permissions[0].read = response.data.read;
	   				this.permissions[0].delete = response.data.delete;
   				}
   				this.setPermissions = true;
	      }).catch(error => {
	        this.load_error = error.data;
	      });
	    },
	    updateCompanyUserRolePermissions(id){
	    	axios.post('/api/company/user/permissions/company-default/'+id+'/update', this.permissions[0], {headers: {
	    		'Authorization' : 'Bearer '+this.$attrs.user.api_token
	    	}}).then(response => {
	    		if(response){
		        	this.permissions[0].add = response.data.add;
		        	this.permissions[0].view = response.data.view;
	   				this.permissions[0].edit = response.data.edit;
	   				this.permissions[0].read = response.data.read;
	   				this.permissions[0].delete = response.data.delete;
   			    }
   				this.setPermissions = true;
   				let self = this;
		        setTimeout(function(){
   					self.success_text = "Default User Permissions Updated";
   					self.success_message = true;
   					self.success_count = 5;
				}, 500);
	      }).catch(error => {
	        this.load_error = error.data;
	      });
	    },
	   	updateHistory(hash){
	   		window.history.pushState({}, '', '#'+hash);
	   				if((hash == 'Website') && (this.website.uid == '')){
	   					this.channels_type = 'ecommerce';
	   					if(this.ecommerce_channels_settings == ''){
	   						
	   						this.fetchChannelsIntegrationsDetails('channels-api');
	   					}else{
	   						this.active_ecommerce_integrations = this.filterActiveChannels(this.all_active_commerce_channels, 'ecommerce');
	   					}

			   			this.getWebsiteSettings();
			   		}else if((hash == 'Website')){
			   			this.active_ecommerce_integrations = this.filterActiveChannels(this.all_active_commerce_channels, 'ecommerce');
			   		}
			   		if(hash == 'Marketplaces'){
			   			this.channels_type = 'marketplace';
			   			if(this.all_active_commerce_channels.length == 0){
			   				
	   						this.fetchChannelsIntegrationsDetails('channels-api');
	   					}else{
	   						this.active_ecommerce_integrations = this.filterActiveChannels(this.all_active_commerce_channels, 'marketplace');
	   					}
			   		}
			   		if(this.view == 'Enquiries'){
		   				//this.company_update.settings = settings;
		   				this.getCompanySettings('enquiry');
		   			}
		   			if(this.view == 'Support'){
		   				//this.company_update.settings = settings;
		   				this.getCompanySettings('support');
		   			}
		   		
	   	},
	   	setTimeout(callback) {
        	this.clearTimeout()
	        this.timeout = setTimeout(() => {
	          this.clearTimeout()
	          callback()
	        }, 1500)
	    },
	    setShortTimeout(callback) {
	        this.clearTimeout()
	        this.timeout = setTimeout(() => {
	          this.clearTimeout()
	          callback()
	        }, 500)
	    },
	     clearTimeout() {
		     if (this.timeout) {
          clearTimeout(this.timeout)
             this.timeout = null;
           }
        },
        updateDefaultStockListing(listing, order_type){
	    	axios.post('/api/company/order/listing/update-default', {listing_id: listing["id"], type: order_type}, {headers: {
	    		'Authorization' : 'Bearer '+this.$attrs.user.api_token
	    	}} ).then(response => {
	    		this.order['default_'+order_type+'_listing'] = response.data;
	    	}).catch(error => {

	    	});
	    },
	    updateDefaultOrderStatus(status, status_type){
	    	axios.post('/api/company/order/statuses/update-default', {status_id: status["id"], type: status_type}, {headers: {
	    		'Authorization' : 'Bearer '+this.$attrs.user.api_token
	    	}} ).then(response => {

	    	}).catch(error => {

	    	});
	    },
	    updateDefault(type, resource){
	    	this.selectedids = [];
	    	this.selectedids.push(resource.id);
	    	axios.post('/api/company/web/'+type+'/make-default', {ids: this.selectedids}, {headers: {
              'Authorization' : 'Bearer '+this.$attrs.user.api_token
            }}).then(response => {
              
              this.success_text = 'Congratulations, your default '+type+' has been updated!';
              this.success_message = true;
              this.success_count = 5;
              this.website['default_'+type] = resource;
              
                
            }).catch(error => {

            });
	    },
	    async processCSVImport(){
	    	this.import_progress = 0;
	    	this.loadingCsvImport = true;
	    	const timer = ms => new Promise(res => setTimeout(res, ms));
	    	var uploadLength = this.import_csv_rows[0].length;
	    	var pages = (uploadLength / 20);
	    	if(this.import_header_rows == true){
	    		var startlimit = 1;
	    		var endlimit = 21;
	        }else{
	        	var startlimit = 0;
	    		var endlimit = 20;
	        }

	    	for(var currentpage = 0; currentpage <= pages; currentpage++){
		    	let self = this;
		    	axios.all([chunkImport(self.import_csv_rows[0].slice(startlimit, endlimit), self.import_csv_rows[0][0], self.$attrs.user.api_token)]).then(axios.spread(function (response) {
								//companyList[ia].push(response.data)
								//console.log(response);
								this.imported_rows.push(response.data); 
							}));
		    	currentpage++;
		    	startlimit += 20;
		    	endlimit += 20;
		    	self.import_progress = (endlimit / uploadLength) * 100;
		    	await timer(2000);
	    	}
	    	this.loadingCsvImport = false;
			async function chunkImport(import_data, header_row, api_token){
			  		return await axios.post('/api/company/product/import/process-csv', { header_row: header_row , datarows: import_data }, {headers: { 'Authorization' : 'Bearer '+api_token }});
			  	}
	    },
	    setupCSVImport(){
	    	this.loadingCsvImport = true;
	    	var postForm = new FormData(document.getElementById("import-products"));
	    	postForm.append('import_file', this.import_csv_file);
	    	axios.post('/api/company/product/import/setup-csv', postForm, {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
	    		this.import_csv_rows = response.data;
	    		if(this.import_header_rows == false){
	    			this.import_csv_rows[0].unshift([]);
	    			this.import_csv_rows[0][0] = JSON.parse(JSON.stringify(this.import_csv_rows[0][1]));
	    		}
	    		let self = this;
		         setTimeout(function(){
		            self.loadingCsvImport = false;
		            self.import_step++;
		         }, 1500);
	    		
	    	}).catch(error => {
	    		//console.log(error);
	    	});
	    },
	    importFileAdded(file){
            this.import_csv_file = file;
        },
        avatarFileAdded(file){
            this.company_update.logo = file;
        },
        directoryFileAdded(file){
        	this.company_update.profile_banners['public'] = file;
        },
        tradeDirectoryFileAdded(file){
        	this.company_update.profile_banners['trade'] = file;
        },
        uploadDirectoryBanner(){
        	//this.company_update = [];
        	var postForm = new FormData(document.getElementById("upload-banner"));
        	postForm.append('banner', this.company_update.profile_banners['public']);
        	postForm.append('banner_type', 'public');
        	axios.post('/api/company/update-banner', postForm, { headers: {
        		'Authorization': 'Bearer '+this.$attrs.user.api_token
        	} }).then(response => {
        		this.company_update.profile_banners['public'] = response.data;
        		this.directoryBannerUpload = false;
        	}).catch(error => {
        		this.error_reponse = error;
        	});
        },
        uploadTradeDirectoryBanner(){
        	var postForm = new FormData(document.getElementById("upload-tradebanner"));
        	postForm.append('banner', this.company_update.profile_banners['trade']);
        	postForm.append('banner_type', 'trade');
        	axios.post('/api/company/update-banner', postForm, { headers: {
        		'Authorization': 'Bearer '+this.$attrs.user.api_token
        	} }).then(response => {
        		this.company_update.profile_banners['trade'] = response.data;
        		this.tradeDirectoryBannerUpload = false;
        	}).catch(error => {
        		this.error_reponse = error;
        	});
        },
        uploadAvatar(){
        	this.loadingUploadWindow = true;
        	var postForm = new FormData(document.getElementById("upload-avatar"));
        	postForm.append('logo', this.company_update.logo);
        	axios.post('/api/company/update-logo', postForm, { headers: {
        		'Authorization': 'Bearer '+this.$attrs.user.api_token
        	} }).then(response => {

        		this.success_text = 'Congratulations, your default logo has been updated!';
              	this.success_count = 5;
              	this.success_message = true;

              	let self = this;
        		setTimeout(function () {
        			self.loadingUploadWindow = false;
        		}, 300);

        		setTimeout(function () {
        			self.success_message = false;
        		}, 500);
        	}).catch(error => {
        		this.error_reponse = error;
        		this.error_message = true;
        	});
        },
        updateCompanyDetails(){
        	this.company_settings_progress = 40;
        	axios.post('/api/company/update-details', {company_update: this.company_update, registration_settings: this.company_registration_settings}, { headers: {
        		'Authorization': 'Bearer '+this.$attrs.user.api_token
        	} }).then(response => {
        		this.company_settings_progress = 100;
        		let self = this;
        		setTimeout(function () {
        			self.loadingCompanySettings = false;
        		}, 1000);
        	}).catch(error => {
        		this.error_reponse = error;
        	});
        },
        updateCompanySettings(update_type, settings){
        	this.company_settings_progress = 40;
        	axios.post('/api/company/settings/update', {type: update_type, data: settings, company_update: this.company_update}, { headers: {
        		'Authorization': 'Bearer '+this.$attrs.user.api_token
        	} }).then(response => {
        		this.company_settings_progress = 100;
        		let self = this;
        		setTimeout(function () {
        			self.loadingCompanySettings = false;
        		}, 1000);
        	}).catch(error => {
        		this.error_reponse = error;
        	});
        },
        getCompanySettings(get_type){
        	this.loadingCompanySettings = true;
	        	this.company_settings_progress = 40;
	        	axios.post('/api/company/settings/get-setting', {type: get_type}, { headers: {
	        		'Authorization': 'Bearer '+this.$attrs.user.api_token
	        	} }).then(response => {
	        		this.company_settings_progress = 100;
	        		if(response.data !== ''){
	        			this.$data[get_type] = response.data;
	        			if(get_type == 'support'){
	        				this.supportUserList = true;
	        				this.loadingSupportUserList = false;
	        			}
	        		}
	        		let self = this;
	        		setTimeout(function () {
	        			self.loadingCompanySettings = false;
	        		}, 1000);
	        	}).catch(error => {
	        		this.error_reponse = error;
	        	});
       
        },
	    setupAPIImport(){
	    	if(this.import_api == 'prestashop'){
	    	var url_extension = '/api/products';
	    	var authKey = btoa(this.api_token + ':');
	    	//console.log(authKey);
	    	//delete axios.defaults.headers.common['X-Requested-With'];
	    	axios.get(this.api_url+''+url_extension, {headers :{ 'Authorization' : 'Basic '+authKey, 'Output-Format' : 'JSON'}}).then(response => {
	    		//console.log(response);
	    		//axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
	    	}).catch(error => {
	    		//console.log(response);
	    		//axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
	    	});
	    	}
	    	
	    },
	    includeGoogle( URL, callback ){
                        let documentTag = document, tag = 'script',
                            object = documentTag.createElement(tag),
                            scriptTag = documentTag.getElementsByTagName(tag)[0];
                        object.src = '//' + URL;
                        if (callback) { object.addEventListener('load', function (e) { callback(null, e); }, false); }
                        scriptTag.parentNode.insertBefore(object, scriptTag);
            },
	    getTradeAddressData: function (addressData, placeResultData, id) {
        this.googleSearchTradeAddress = addressData;
        this.googleSearchTradeAddress["place"] = placeResultData;
        /*if(placeResultData.name){
         this.company_update.address1 = placeResultData.name;
         if(addressData.street_number){
              this.company_update.profile_contact['trade']['address2'] = addressData.street_number + ' ' +addressData.route; 
          }else{
              this.company_update.profile_contact['trade']['address2'] = addressData.route; 
          }
        }else{
         if(addressData.street_number){
              this.company_update.profile_contact['trade']['address1'] = addressData.street_number + ' ' +addressData.route; 
          }else{
              this.company_update.profile_contact['trade']['address1'] = addressData.route; 
          }
        }
        if(addressData.locality){
          this.company_update.profile_contact['trade']['address3'] = addressData.locality;
        }else{
        	this.company_update.profile_contact['trade']['address3'] = '';
        }*/


         for(var i = 0; i < this.googleSearchTradeAddress.place.address_components.length; i++){
		        			if(this.googleSearchTradeAddress.place.address_components[i].types.includes('street_number')){
		        					this.company_update.profile_contact['trade']['address1'] = this.googleSearchTradeAddress.street_number; 
		        				}
		        				if(this.googleSearchTradeAddress.place.address_components[i].types.includes('route')){
		        					this.company_update.profile_contact['trade']['address1'] += ' '+this.googleSearchTradeAddress.route; 
		        				}
		        				if(this.googleSearchTradeAddress.place.address_components[i].types.includes('neighborhood')){
		        					this.company_update.profile_contact['trade']['address2'] = this.googleSearchTradeAddress.place.address_components[i].long_name;
		        				}
		        				if(this.googleSearchTradeAddress.place.address_components[i].types.includes('postal_town')){
		        					
		        						this.company_update.profile_contact['trade']['address3'] = this.googleSearchTradeAddress.place.address_components[i].long_name; 
		        				}
		        				if(this.googleSearchTradeAddress.place.address_components[i].types.includes('locality')){
		        					this.company_update.profile_contact['trade']['address3'] = this.googleSearchTradeAddress.place.address_components[i].long_name;
		        				}
		        		}
		        /*if((!this.company_update.profile_contact['trade']['address1']) || this.company_update.profile_contact['trade']['address1'] == '') {
		        	this.company_update.profile_contact['trade']['address1'] = this.googleSearchTradeAddress.place.formatted_address.split(',', 1)[0];
		        }
		        if(this.company_update.profile_contact['trade']['address2'] == ''){
		        	this.company_update.profile_contact['trade']['address2'] = this.googleSearchTradeAddress.place.formatted_address.split(',', 2)[1];
		        }*/	

        this.company_update.profile_contact['trade']['postcode'] = addressData.postal_code;
        var country = this.countries.filter(item => item.name == addressData.country);
        this.company_update.profile_contact['trade']['country_id'] = country[0].id;
        this.selected_country_id = country[0].id;
        this.company_update.profile_contact['trade']['latitude'] = this.googleSearchTradeAddress.latitude;
        this.company_update.profile_contact['trade']['longitude'] = this.googleSearchTradeAddress.longitude;
        this.company_update.profile_contact['trade']['gplace_id'] = placeResultData.place_id;
        //this.googleSearchTradeAddress["place"] = placeResultData;
        if(country.length > 0){
            //this.getCounties(country[0].id);
            var self = this;
            axios.all([getCounties(country[0].id, self.$attrs.user.api_token, addressData.administrative_area_level_2)]).then(axios.spread(function (response) {
              //companyList[ia].push(response.data)
              //console.log(response);
              self.counties = response[0].data;
              var county = response[0].data.filter(item => item.name == response[1]);
              self.company_update.county_id = county[0].id;
              self.selected_county_id = county[0].id;
            }));
            
          }
        /**for(var i = 0; i < placesData.address_components.length; i++){
          var county = placesData.address_components[i].types.filter(item == 'route');
          var county = placesData.address_components[i].types.filter(item == 'street_number');
          
          var town = placesData.address_components[i].types.filter(item == 'locality');
          var city = placesData.address_components[i].types.filter(item == 'postal_town');
          var country = placesData.address_components[i].types.filter(item == 'country');

          this.newListing.address3 = town[0].long_name;

        
        }**/
        async function getCounties(country_id, api_token, county_name){
               return [await axios.get('/api/country/'+country_id+'/counties?api_token='+api_token), county_name];
              }

      },
	    getAddressData: function (addressData, placeResultData, id) {
        this.googleSearchAddress = addressData;
        if(placeResultData.name){
         this.company_update.address1 = placeResultData.name;
         if(addressData.street_number){
              this.company_update.address2 = addressData.street_number + ' ' +addressData.route; 
          }else{
              this.company_update.address2 = addressData.route; 
          }
        }else{
         if(addressData.street_number){
              this.company_update.address1 = addressData.street_number + ' ' +addressData.route; 
          }else{
              this.company_update.address1 = addressData.route; 
          }
        }
        if(addressData.locality){
          this.company_update.address3 = addressData.locality;
        }else{
        	this.company_update.address3 = '';
        }
        this.company_update.postcode = addressData.postal_code;
        var country = this.countries.filter(item => item.name == addressData.country);
        this.company_update.country_id = country[0].id;
        this.selected_country_id = country[0].id;
          
        this.company_update.latitude = this.googleSearchAddress.latitude;
        this.company_update.longitude = this.googleSearchAddress.longitude;
        this.company_update.gplace_id = placeResultData.place_id;
        this.googleSearchAddress["place"] = placeResultData;
        if(country.length > 0){
            //this.getCounties(country[0].id);
            var self = this;
            axios.all([getCounties(country[0].id, self.$attrs.user.api_token, addressData.administrative_area_level_2)]).then(axios.spread(function (response) {
              //companyList[ia].push(response.data)
              //console.log(response);
              self.counties = response[0].data;
              var county = response[0].data.filter(item => item.name == response[1]);
              self.company_update.county_id = county[0].id;
              self.selected_county_id = county[0].id;
            }));
            
          }
        /**for(var i = 0; i < placesData.address_components.length; i++){
          var county = placesData.address_components[i].types.filter(item == 'route');
          var county = placesData.address_components[i].types.filter(item == 'street_number');
          
          var town = placesData.address_components[i].types.filter(item == 'locality');
          var city = placesData.address_components[i].types.filter(item == 'postal_town');
          var country = placesData.address_components[i].types.filter(item == 'country');

          this.newListing.address3 = town[0].long_name;

        
        }**/
        async function getCounties(country_id, api_token, county_name){
               return [await axios.get('/api/country/'+country_id+'/counties?api_token='+api_token), county_name];
              }

      },
      getShippingKey(name){
      	var integrations = this.all_carrier_integrations.filter(item => item.key == name);
      	////console.log(integrations);
      	return integrations[0].key;
      },
      getShippingImage(name){
      	var integrations = this.all_carrier_integrations.filter(item => item.key == name);
      	////console.log(integrations);
      	return integrations[0].logo;
      },
      getAllCompanyListings(){
      		if(this.alllistings.length == 0){
	    	axios.get('/api/company/listings?page='+this.currentPage+'&limit=0&sortby=id&order=desc&search='+this.alllistingsearch+'&type_id=&active=&public_active=&trade_active=', {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
		            this.alllistings = response.data;
		            this.setShortTimeout(() => {
		            this.loadingDetails = 0;
		            });
		      }).catch(error => {
		            this.load_error = error.data;
		            this.setShortTimeout(() => {
		            this.loadingDetails = 0;
		            });
		      });
      		}
	    },
	    getCompanyTradeListings(){
	    	if(this.order.trade_listings.length == 0){
	    	axios.get('/api/company/listings?page='+this.currentPage+'&limit=0&sortby=id&order=desc&search='+this.tradelistingsearch+'&type_id=&active=&public_active=&trade_active=1', {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
		            this.order.trade_listings = response.data;
		            this.setShortTimeout(() => {
		            this.loadingDetails = 0;
		            });
		      }).catch(error => {
		            this.load_error = error.data;
		            this.setShortTimeout(() => {
		            this.loadingDetails = 0;
		            });
		      });
		  	}
	    },
	    getCompanyPublicListings(){
	    	if(this.order.public_listings.length == 0){
	    	axios.get('/api/company/listings?page='+this.currentPage+'&limit=0&sortby=id&order=desc&search='+this.publiclistingsearch+'&type_id=&active=&public_active=1&trade_active=', {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
		            this.order.public_listings = response.data;
		            this.setShortTimeout(() => {
		            this.loadingDetails = 0;
		            });
		      }).catch(error => {
		            this.load_error = error.data;
		            this.setShortTimeout(() => {
		            this.loadingDetails = 0;
		            });
		      });
		  	}
	    },
	    getCompanyShopListings(){
	    	if(this.order.online_listings.length == 0){
	    	axios.get('/api/company/listings?page='+this.currentPage+'&limit=0&sortby=id&order=desc&search='+this.onlinelistingsearch+'&type_id=&active=1&public_active=&trade_active=', {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
		            this.order.online_listings = response.data;
		            this.setShortTimeout(() => {
		            this.loadingDetails = 0;
		            });
		      }).catch(error => {
		            this.load_error = error.data;
		            this.setShortTimeout(() => {
		            this.loadingDetails = 0;
		            });
		      });
		  	}
	    },
	   	getOrderStatuses(){
		      this.loadingDetails = 1;
		      axios.get('/api/company/order/statuses?page='+this.currentPage+'&limit=0&sortby=id&order=asc&search=', {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
              }}).then(response => {
		            this.order.statuses = response.data;
		            this.setShortTimeout(() => {
		            this.loadingDetails = 0;
		            });
		      }).catch(error => {
		            this.load_error = error.data;
		            this.setShortTimeout(() => {
		            this.loadingDetails = 0;
		            });
		      });
    	},
    	getRegistrationCountries(){
	        if(this.countries.length == 0){
	          axios.get('/api/countries', {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
              }}).then(response => {
	            this.registrationcountries = response.data;
	          }).catch(error => {
	            this.load_error = error.data;
	          })
	        }
	      },
	      getRegistrationCounties(country){
	        // get counties as country selected
	        axios.get('/api/country/'+country.id+'/counties', {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
              }}).then(response => {
	          this.registrationcounties = response.data;
	        }).catch(error => {
	          this.load_error = error.data;
	        })
	      },
	   	getCountries(){
	        if(this.countries.length == 0){
	          axios.get('/api/countries', {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
              }}).then(response => {
	            this.countries = response.data;
	          }).catch(error => {
	            this.load_error = error.data;
	          })
	        }
	      },
	      getCounties(country_id){
	        // get counties as country selected
	        axios.get('/api/country/'+country_id+'/counties', {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
              }}).then(response => {
	          this.counties = response.data;
	        }).catch(error => {
	          this.load_error = error.data;
	        })
	      },
	      getCompanyTypes(){
	      	axios.get('/api/company/types', {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
	          this.company_types = response.data;
	        }).catch(error => {
	          this.load_error = error.data;
	        })
	      },
	      getStripeAccount(){
	      	this.getCurrencies();
	      	this.payments_setup_progress = 30;
	      	axios.get('/api/company/settings/payments/'+this.$attrs.company.uid+'/account', {headers: {
	      		'Authorization' : 'Bearer '+this.$attrs.user.api_token
	      	}}).then(response => {
	      		this.stripeAccount.push(response.data);
	      		this.payments_setup_progress = 60;
	      		let self = this;
	      		setTimeout(function(){
				         		//self.getActiveShippingIntegrations();
				         		self.payments_setup_progress = 99;
								  }, 500);
	      		setTimeout(function(){
				         		//self.getActiveShippingIntegrations();
				         		self.loadingPaymentSettings = false;
								  }, 1500);
	      	}).catch(error => {
	      		this.error_reponse = error;
	      		this.payments_setup_progress = 60;
	      		let self = this;
	      		setTimeout(function(){
				         		//self.getActiveShippingIntegrations();
				         		self.payments_setup_progress = 99;
								  }, 500);
	      		setTimeout(function(){
				         		//self.getActiveShippingIntegrations();
				         		self.loadingPaymentSettings = false;
								  }, 1500);
	      	});
	      },
	      connectStripeAccount(){
	      	axios.get('/api/company/settings/payments/'+this.company_update.uid+'/on-boarding', {headers: {
	      		'Authorization' : 'Bearer '+this.$attrs.user.api_token
	      	}}).then(response => {
	      		window.open(response.data, "_blank");
	      	}).catch(error => {
	      		this.error_reponse = error;
	      	});
	      },
	      disconnectStripeAccount(){
	      	axios.post('/api/company/settings/payments/'+this.stripeAccount[0].account_id+'/destroy', {headers: {
	      		'Authorization' : 'Bearer '+this.$attrs.user.api_token
	      	}}).then(response => {
	      		window.open(response.data, "_blank");
	      	}).catch(error => {
	      		this.error_reponse = error;
	      	});
	      }
   	}
}
</script>
<template>
	<Layout :nickname="$attrs.nickname" :user="$attrs.user">
		<PageHeader :title="title" :items="items"  />
			<b-row>
				 <b-col md="12">
                    <transition name="slide-success" mode="out-in">
                  <b-alert id="success-message" variant="success" :show="success_count" fade dismissible @dismissed="success_message = !success_message, success_count = 0" @dismiss-count-down="countDownChanged">
                      <p class="font-size-14">{{success_text}}</p>
                       <!--<b-progress
					        variant="success"
					        :max="5"
					        :value="success_count"
					        height="4px"
					      ></b-progress>-->
                    </b-alert>
                  </transition>
                  </b-col>
				<b-col md="2">
					<b-card no-body class="py-0">
						<b-list-group flush class="pl-3 py-0">
							<b-list-group-item 
							 href="javascript:;"
							 @click="view = 'Company', updateHistory(view)"
							 class="nav-tab"
							 :class="{'active' : view == 'Company'}">
								Company
							</b-list-group-item>
							
							<b-list-group-item 
							 href="javascript:;"
							 @click="getUserRoles(), view = 'User', updateHistory(view)"
							 class="nav-tab"
							 :class="{'active' : view == 'User'}">
								User
							</b-list-group-item>
							<b-list-group-item 
							 href="javascript:;"
							 @click="view = 'Directory', updateHistory(view)"
							 class="nav-tab"
							 :class="{'active' : view == 'Directory'}">
								Directory
							</b-list-group-item>
							<b-list-group-item 
							 href="javascript:;"
							 @click="view = 'Stock', updateHistory(view)"
							 class="nav-tab"
							 :class="{'active' : view == 'Stock'}">
								Import
							</b-list-group-item>
							<b-list-group-item 
							 href="javascript:;"
							 @click="getOrderSettings(), view = 'Order', updateHistory(view)"
							 class="nav-tab"
							 :class="{'active' : view == 'Order'}">
								Orders
							</b-list-group-item>
							<b-list-group-item 
							 href="javascript:;"
							 @click="view = 'Payments', updateHistory(view), getAllCompanyListings()"
							 class="nav-tab"
							 :class="{'active' : view == 'Payments'}">
								Payments
							</b-list-group-item>
							<b-list-group-item 
							 href="javascript:;"
							 @click="view = getCourierApiKey(), view = 'Shipping', updateHistory(view)"
							 class="nav-tab"
							 :class="{'active' : view == 'Shipping'}">
								Shipping
							</b-list-group-item>
							<b-list-group-item 
							 href="javascript:;"
							 @click="view = 'Website', updateHistory(view)"
							 class="nav-tab"
							 :class="{'active' : view == 'Website'}">
								Website & Ecommerce
							</b-list-group-item>
							<b-list-group-item 
							 href="javascript:;"
							 @click="view = 'Marketplaces', updateHistory(view)"
							 class="nav-tab"
							 :class="{'active' : view == 'Marketplaces'}">
								Marketplaces
							</b-list-group-item>
							<!--<b-list-group-item 
							 href="javascript:;"
							 @click="view = 'Mail', updateHistory(view)"
							 class="nav-tab"
							 :class="{'active' : view == 'Email & Newsletters'}">
								Marketplaces
							</b-list-group-item>-->
							<b-list-group-item 
							 href="javascript:;"
							 @click="view = 'Telephony', updateHistory(view)"
							 class="nav-tab"
							 :class="{'active' : view == 'Telephony'}">
								Telephony <span class="badge badge-info bg-info">Soon</span>
							</b-list-group-item>
							<b-list-group-item 
							 href="javascript:;"
							 @click="view = 'Video', updateHistory(view)"
							 class="nav-tab"
							 :class="{'active' : view == 'Video'}">
								Video & Broadcasting <span class="badge badge-info bg-info">Soon</span>
							</b-list-group-item>
							<b-list-group-item 
							 href="javascript:;"
							 @click="view = 'Enquiries', updateHistory(view)"
							 class="nav-tab"
							 :class="{'active' : view == 'Enquiries'}">
								Enquiries
							</b-list-group-item>
							<b-list-group-item 
							 href="javascript:;"
							 @click="view = 'Support', updateHistory(view)"
							 class="nav-tab"
							 :class="{'active' : view == 'Support'}">
								Support
							</b-list-group-item>
							<b-list-group-item 
							 href="javascript:;"
							 @click="getCompanySocialProfiles(), view = 'Socials', updateHistory(view)"
							 class="nav-tab"
							 :class="{'active' : view == 'Socials'}">
								Social Profiles 
							</b-list-group-item>
							<b-list-group-item 
							 href="javascript:;"
							 @click="view = 'Feeds', updateHistory(view)"
							 class="nav-tab"
							 :class="{'active' : view == 'Feeds'}">
								Feeds
							</b-list-group-item>
							<!--<b-list-group-item 
							 @click="view = 'Appraise', updateHistory(view)"
							 class="nav-tab"
							 :class="{'active' : view == 'Appraise'}">
								Appraise
							</b-list-group-item>
							<b-list-group-item 
							 @click="view = 'Repair', updateHistory(view)"
							 class="nav-tab"
							 :class="{'active' : view == 'Repair'}">
								Repair
							</b-list-group-item>
							<b-list-group-item>
								Appraise
							</b-list-group-item>-->
							<a class="btn btn-success" v-if="company_update !== company_update">
								Save Changes
							</a>
						</b-list-group>
						
					</b-card>
					<b-button @click="updateSettings(view)" variant="success" class="btn-rounded btn-block">
							Update
						</b-button>
				</b-col>
			<b-col md="10">
				<b-row v-if="view == 'Company'">
					<b-col md="12">
						<b-card class="p-3">
						<h4>{{view}} Settings</h4>
						<hr>
						<b-overlay :show="loadingCompanySettings"  variant="white" opacity="0.95">
						<template #overlay>
							<b-row>
			      			<b-col md="12" class="offset-md-2" >
			      			<b-card class="border-radius-1em border shadow-none mb-0">	
					            <div class="cube-wrapper mx-auto"><div class="cube-folding"><span class="leaf1"></span><span class="leaf2"></span><span class="leaf3"></span><span class="leaf4"></span></div></div>
					            <h6 class="text-center">Loading Your Company Details</h6>
					             <b-progress class="w-50 mx-auto" v-model="company_settings_progress" variant="primary"></b-progress>
			         		</b-card>
			      			</b-col>
			      			</b-row>
			      		</template>
						<b-row>
							<b-col md="4">
								<h5 class="d-flex">Logo <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
							</b-col>
							<b-col md="8" class="text-center"  @mouseenter="imageOptions = true" @mouseleave="imageOptions = ''">
								<b-overlay :show="loadingUploadWindow">
								<b-card class="border-radius-1em border shadow-none" v-if="logoUpload == false">
								<b-img-lazy  v-if="company_update.logo !== null && company_update.logo.search(storageurl) == -1" :src="storageurl + company_update.logo.replace('https://'+$attrs.company.nickname+'.s3.eu-west-2.amazonaws.com/', '')" class="mx-auto img-fluid border"/>
								<b-img-lazy  v-else-if="company_update.logo !== null && company_update.logo.search(storageurl) > -1" :src="company_update.logo" class="mx-auto img-fluid border" />
								<b-img-lazy src="/images/product-placeholder.png" v-else class="mx-auto img-fluid border" />
								<div class="left-0 right-0 top-0 h-100 w-100 opacity-75" :class="{'h-100 d-flex flex-column position-absolute bg-white bg-soft w-75 mx-auto': imageOptions == true, 'd-none': imageOptions == false}">
                              <!--<a class="btn btn-primary btn-rounded mt-auto w-75 mx-auto mb-1" @click="$emit('file-selected', object), $emit('close-window')">Edit</a>-->
                              <a class="btn btn-primary btn-rounded my-auto w-75 mx-auto" @click="logoUpload = !logoUpload" v-b-modal.uploadModal>Upload New</a>
                            </div>
                        		</b-card>
                        		<b-card v-else class="border-radius-1em border shadow-none">
                        		<b-form id="upload-avatar">
                        			<input type="hidden" name="logo" v-model="company_update.logo">
                        		</b-form>
                        		 <vue-dropzone id="dropzone" ref="myVueDropzone" :use-custom-slot="true" :options="avatarDropzoneOptions" @vdropzone-file-added="avatarFileAdded"  @vdropzone-drop="avatarFileAdded">
			                        <div class="dropzone-custom-content d-flex flex-column justify-content-center align-items-center" style="height: 500px;">
			                            <i class="display-4 text-muted bx bxs-cloud-upload"></i>
			                            <h4>Drop files here or click to upload.</h4>
			                        </div>
                        		</vue-dropzone>
                        		<div class="d-flex">
                        		<a class="btn btn-primary mb-auto w-50 mx-auto btn-rounded mt-1 mr-1" @click="uploadAvatar()" v-b-modal.uploadModal>Upload</a>
                        		<a class="btn btn-danger mb-auto w-50 mx-auto btn-rounded mt-1 ml-1" @click="logoUpload = !logoUpload" v-b-modal.uploadModal>Cancel</a>
                        		</div>
                    		</b-card>
                    	</b-overlay>
							</b-col>
							<b-col md="4">
								<h5 class="d-flex">Basic Info <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
							</b-col>
							<b-col md="8">
								<b-card class="border-radius-1em border shadow-none">
								<b-form-group label="Company Name" class="mb-2">
									<b-form-input type="text" v-model="company_update.name">
									</b-form-input>
								</b-form-group>
								<b-form-group label="Nickname" class="mb-2">
									<b-form-input type="text" v-model="company_update.nickname" disabled>
									</b-form-input>
								</b-form-group>
								<b-form-group label="Description" class="mb-2">
									<b-textarea v-model="company_update.description" rows="4"></b-textarea>
								</b-form-group>
								<b-form-group label="Main Business Activity">
									<b-form-select class="form-control border btn-rounded px-3 custom-select"  v-model="company_update.company_type_id">
										<b-form-select-option v-for="type in company_types" :value="type.id">
											{{type.name}}
										</b-form-select-option>
									</b-form-select>
								</b-form-group>

							</b-card>
							</b-col>
							<b-col md="4" class="mt-3">
								<h5 class="d-flex">Company Owner <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
							</b-col>
							<b-col md="8" class="mt-3">
								<b-card no-body class="d-flex flex-row p-3 shadow-none border border-radius-1em">
									<b-img-lazy :src="company_update.owner.avatar" v-if="company_update.owner.avatar !== null" class="avatar-md rounded-circle" ></b-img-lazy>
										<div class="avatar-sm me-3" v-else>
                                        <span class="avatar-title rounded-circle" >{{(company_update.owner.name).slice(0,1)}}</span>
                                      </div>
									<h6 class="my-auto">{{company_update.owner.name}} {{company_update.owner.lastname}}</h6>
									<a class="btn btn-primary ml-auto my-auto" v-if="$attrs.isowner == true">Change Owner</a>
								</b-card>
							</b-col>
							<b-col md="4">
								<h5 class="d-flex">Contact Information <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
							</b-col>
							<b-col md="8">
								 <b-card class="border-radius-1em border shadow-none">
                <b-form-group label="Search Address">
                  <vue-google-autocomplete id="map" :enableGeolocation="false" types="establishment" :fields="['address_components', 'adr_address', 'alt_id', 'formatted_address', 'geometry', 'icon', 'id', 'name', 'business_status', 'photo', 'place_id', 'scope', 'type', 'url', 'utc_offset', 'vicinity', ]" classname="form-control" placeholder="Start typing" v-on:placechanged="getAddressData">
                    </vue-google-autocomplete>
                </b-form-group>
                <gmap-map v-if="googleSearchAddress !== ''" :center="{ lat: googleSearchAddress.latitude, lng: googleSearchAddress.longitude }" :zoom="10" style="height: 350px" class="mt-2 border-radius-1em">
                  <gmap-marker
                    
                    :key="index"
                    :position="{ lat: googleSearchAddress.latitude, lng: googleSearchAddress.longitude }"
                    :clickable="true"
                    :draggable="true"
                    @click="center = { lat: googleSearchAddress.latitude, lng: googleSearchAddress.longitude }"
                  ></gmap-marker>
                </gmap-map>
              </b-card>
              <b-card class="border-radius-1em border shadow-none">
								<b-form-group  label="Address 1">
				                  <b-form-input type="text" name="address1" v-model="company_update.address1"></b-form-input>
				                </b-form-group>
				                <b-form-group class="mt-2" label="Address 2">
				                  <b-form-input type="text" name="address2" v-model="company_update.address2"></b-form-input>
				                </b-form-group>
				                <b-form-group class="mt-2" label="Address 3">
				                  <b-form-input type="text" name="address3" v-model="company_update.address3"></b-form-input>
				                </b-form-group>
				                
				                <b-form-group class="mt-2" label="Country">
				                  <b-form-select class="form-control" v-model="company_update.country_id" name="country_id"  @input="getCounties(company_update.country_id)">
				                    <b-form-select-option v-for="country in countries" :value="country.id">
				                      {{country.name}}
				                    </b-form-select-option>
				                  </b-form-select>
				                </b-form-group>
				                <b-form-group class="mt-2 mb-2" label="County/State">
				                  <b-form-select class="form-control" name="county_id" :disabled="(counties.length == 0)">
				                    <b-form-select-option v-for="county in counties" :value="county.id" >
				                      {{county.name}}
				                    </b-form-select-option>
				                  </b-form-select>
				                </b-form-group>
				                <b-form-group class="mb-2" label="City">
				                  <b-form-input v-model="company_update.city" type="text" name="city"></b-form-input>
				                </b-form-group>
				                <b-form-group class="mb-2" label="Postcode/Zip">
				                  <b-form-input v-model="company_update.postcode" type="text" name="postcode"></b-form-input>
				                </b-form-group>
				              </b-card>

				              <b-card class="border-radius-1em border shadow-none">
								<b-form-group label="Website" class="mb-2">
									<b-input-group>
										<b-form-input type="website" v-model="company_update.website">
										</b-form-input>
									</b-input-group>
								</b-form-group>
								<b-form-group label="Email" class="mb-2">
									<b-input-group prepend="@">
										<b-form-input type="email" v-model="company_update.email">
										</b-form-input>
									</b-input-group>
								</b-form-group>
								<b-form-group label="Telephone" class="mb-2">
									<b-input-group :prepend="'+'+$attrs.company['country']['call_prefix']">
										<b-form-input type="telephone" v-model="company_update.telephone">
										</b-form-input>
									</b-input-group>
								</b-form-group>
							</b-card>
						</b-col>
						<b-col md="4">
								<h5 class="d-flex">Registration Information <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
						</b-col>
						<b-col md="8">
						<b-card class="border-radius-1em border shadow-none">
			         	<h5 class="heading-label required">Business Legal Status</h5>
			          <div class="d-flex">
			          <b-button
			                      class=" flex-grow-1 my-0 w-sm border-radius-1em mr-1"
			                      value="0"
			                      :class="{'active' : company_registration_settings.legal_status == 'self-employed'}"
			                      variant="outline-light"
			                      @click="company_registration_settings.legal_status = 'self-employed'"
			                    ><i v-if="company_registration_settings.legal_status == 'self-employed'" class="bx bx-check-circle font-size-24 d-block text-success my-2"></i> 
			                    <i v-else class="bx bx-user font-size-24 d-block text-primary my-2"></i> Self-Employed
			              </b-button>
			              <b-button
			                      class=" flex-grow-1 my-0 w-sm border-radius-1em ml-1"
			                      value="0"
			                      :class="{'active' : company_registration_settings.legal_status == 'limited'}"
			                      variant="outline-light"
			                      @click="company_registration_settings.legal_status = 'limited'"
			                    ><i v-if="company_registration_settings.legal_status == 'limited'" class="bx bx-check-circle font-size-24 d-block text-success my-2"></i> 
			                    <i v-else class="bx bx-store font-size-24 d-block text-primary my-2"></i> Limited Company
			              </b-button>
			              <b-button
			                      class=" flex-grow-1 my-0 w-sm border-radius-1em ml-1"
			                      value="0"
			                      :class="{'active' : company_registration_settings.legal_status == 'partnership'}"
			                      variant="outline-light"
			                      @click="company_registration_settings.legal_status = 'partnership'"
			                    ><i v-if="company_registration_settings.legal_status == 'partnership'" class="bx bx-check-circle font-size-24 d-block text-success my-2"></i> 
			                    <i v-else class="mdi mdi-handshake-outline  font-size-24 d-block text-primary my-2"></i> Partnership
			              </b-button>
			            </div>
			          </b-card>
						<b-card  class="border-radius-1em border shadow-none border-primary" body-class="d-flex" v-if="googleSearchRegisteredAddress !== ''">
		                <div v-html="googleSearchRegisteredAddress.place.formatted_address"></div>
		              
		                <a class="btn btn-danger ml-auto" @click="googleSearchRegisteredAddress = '', company_registration_settings.gplace_id = '', company_registration_settings.longitude = 0, company_registration_settings.latitude = 0">Remove Listing</a>
		              </b-card>
		              <b-card class="border-radius-1em border shadow-none" v-if="company_registration_settings.legal_status !== 'self-employed'">
			          <b-form-group label="Address 1" class="required mt-2">
			            <b-form-input name="company_registration_settings_address1" v-model="company_registration_settings.address1"></b-form-input>
			          </b-form-group>
			          <b-form-group label="Address 2" class="mt-2">
			            <b-form-input name="company_registration_settings_address2" v-model="company_registration_settings.address2"></b-form-input>
			          </b-form-group>
			          <b-form-group label="Address 3" class="mt-2">
			            <b-form-input name="company_registration_settings_address3" v-model="company_registration_settings.address3"></b-form-input>
			          </b-form-group>
			          <b-form-group  class="my-2" label="Country">
				          <multiselect v-model="company_registration_settings.country" track-by="name" label="name" :options="registrationcountries" :option-height="104" @input="getRegistrationCounties" >
				                     <template slot="singleLabel" slot-scope="{ option }"><div class="d-flex w-100 h-100">
				                     <div class="avatar-xs mr-4 my-auto" v-if="company_registration_settings.country !== ''">
				                        <span class="avatar-title rounded-circle" >{{(option.iso_code).slice(0,2)}}</span>
				                       
				                      </div>
				                      <div class="option__desc d-flex flex-row flex-grow-1"><span v-if="company_registration_settings.country !== ''"  class="option__title ml-2 my-auto w-100">{{ option.name }}</span>
				                        <span v-else class="option__title my-auto w-100">Select Country</span>

				                      </div>
				                      <div class="avatar-xs my-auto" v-if="company_registration_settings.country == ''">
				                      <span class="avatar-title rounded-circle bg-light text-primary"><i class="bx bx-chevron-down"></i></span>
				                     </div>

				                      </div></template>
				                    <template slot="option" slot-scope="{ option }">
				                      <div class="d-flex"><div class="avatar-xs mr-4">
				                                        <span class="avatar-title rounded-circle">{{(option.iso_code).slice(0,2)}}</span>
				                                      </div>
				                      <div class="option__desc d-flex flex-row w-50"><span class="option__title ml-2 my-auto w-100">{{ option.name }}</span>
				                        
				                      </div>
				                      </div>
				                    </template>
				                  </multiselect>
     				</b-form-group>
      <input type="hidden" name="company_registration_settings_country_id" v-model="company_registration_settings.country_id">
      <b-form-group v-if="registrationcounties.length > 0" class="my-2"  label="County/State">
          <multiselect v-model="company_registration_settings.county" track-by="name" label="name" :options="registrationcounties" :option-height="104" class="" >
                     <!--<template slot="singleLabel" slot-scope="{ option }">{{ option.name }} - {{ option.jobtitle }}</template>-->
                     <template slot="singleLabel" slot-scope="{ option }">
                     <div class="d-flex w-100 h-100">
                     <div class="avatar-xs mr-4 my-auto" v-if="company_registration_settings.county.id !== ''">
                        <span class="avatar-title rounded-circle" >{{(option.name).slice(0,2)}}</span>
                       
                      </div>
                      <div class="option__desc d-flex flex-row flex-grow-1"><span v-if="company_registration_settings.county.id !== ''"  class="option__title ml-2 my-auto w-100">{{ option.name }}</span>
                        <span v-else class="option__title my-auto w-100">Select State/County</span>

                      </div>
                      <div class="avatar-xs my-auto" v-if="company_registration_settings.county.id == ''">
                      <span class="avatar-title rounded-circle bg-light text-primary"><i class="bx bx-chevron-down"></i></span>
                     </div>

                      </div></template>
                    <template slot="option" slot-scope="{ option }">
                      <div class="d-flex"><div class="avatar-xs mr-4">
                                        <span class="avatar-title rounded-circle">{{(option.name).slice(0,2)}}</span>
                                      </div>
                      <div class="option__desc d-flex flex-row w-50"><span class="option__title ml-2 my-auto w-100">{{ option.name }}</span>
                        
                      </div>
                      </div>
                    </template>
                  </multiselect>
      </b-form-group>
       <input type="hidden" name="company_registration_settings_county_id" v-model="company_registration_settings.county_id">
     
			          <!--<b-form-group label="Country" class="required mt-2">
			            <b-form-select name="country_id" v-model="company_registration_settings.country_id" @input="getCounties(company_registration_settings.country_id)">
			            	<b-form-select-option v-for="country in countries" :value="country.id">
			            		{{country.name}}
			            	</b-form-select-option>
			            </b-form-select>
			          </b-form-group>
			          <b-form-group label="County/State/Region" class="required mt-2">
			            <b-form-select name="county_id" v-model="company_registration_settings.county_id" :disabled="(company_registration_settings.country_id == '') && (counties.length == 0)">
			            	<b-form-select-option v-for="county in counties" :value="county.id">
			            		{{county.name}}
			            	</b-form-select-option>
			            </b-form-select>
			          </b-form-group>-->
			          <b-form-group label="Postcode/Zip" class="required mt-2">
			            <b-form-input name="company_registration_settings_postcode" v-model="company_registration_settings.postcode"></b-form-input>
			          </b-form-group>

			      	</b-card>
			      	<b-card class="border-radius-1em border shadow-none">
			      		<h5 class="heading-label required">VAT No.</h5>
			      		
			      		
			      		<b-form-group class="mb-2" label="VAT No.">
			      			<b-form-input type="input" name="company_registration_settings_vat_no" v-model="company_registration_settings.vat_no"  @keypress="searchVATNo()"></b-form-input>
			      		</b-form-group>
			      		<div v-if="vat_check_result !== ''  && show_vat_result !== false">
			      				<p class="p-3 rounded-4 bg-soft bg-success text-success" v-if="vat_company_match == true">
			      					Company Registration Address and VAT Match
			      				</p>
			      				<p class="p-3 rounded-4 bg-soft bg-danger text-danger" v-if="vat_company_match == false">
			      					Company Registration Address and VAT Address do not match
			      				</p>
			      		</div>
			      	</b-card>
			      	<b-card class="border-radius-1em border shadow-none">
			      		<h5 class="heading-label required">Professional Bodies Membership No(s).</h5>
			      		<b-form-group label="Professional Body">
			      			<b-form-select class="form-control"  v-model="professional_body">
			      				<b-form-select-option v-for="bodies in professional_bodies" :value="bodies.value">
			      					{{bodies.text}}
			      				</b-form-select-option>
			      			</b-form-select>
			      		</b-form-group>
			      		<b-form-group label="Membership No.">
			      			<b-form-input type="text" name="membership_no" v-model="membership_no"></b-form-input>
			      		</b-form-group>

			      		<a v-if="professional_body_index == -1" class="btn btn-primary btn-rounded mt-2 mb-3 btn-block" @click="addProfessionalBody()" >Add Professional Body</a>
			      		<a v-else class="btn btn-primary btn-rounded mt-2 mb-3 btn-block" @click="updateProfessionalBody()" >Update Professional Body</a>
			      		<b-overlay :show="loadingProfessionalBodies" >
			      		<b-list-group v-if="(company_registration_settings.professional_bodies !== null) && (company_registration_settings.professional_bodies.length > 0)">
			      			<b-list-group-item class="d-flex">
			      				<strong class="my-auto mr-2 w-25">Name</strong>
								<strong class="my-auto mr-auto w-50">Membership No.</strong>
			      			</b-list-group-item>
			      			<b-list-group-item v-if="company_registration_settings.professional_bodies.length > 0" v-for="(professionalbod, index) in company_registration_settings.professional_bodies" class="d-flex">
			      			
								<span class="my-auto mr-2 w-25">{{professionalbod.name}}</span>
								<span class="my-auto mr-auto w-50">{{professionalbod.membership_no}}</span>
								<a class="btn btn-outline-light btn-rounded my-auto ml-auto" @click="editProfessionalBody(index)">
									<i class="bx bx-pencil"></i>
								</a>
								<a class="btn btn-outline-light btn-rounded my-auto ml-2" @click="company_registration_settings.professional_bodies.splice(index, 1)">
									<i class="bx bx-trash-alt text-danger"></i>
								</a>
			      			</b-list-group-item>
			      		</b-list-group>
			      		<b-card v-else class="border-radius-1em border shadow-none py-4" body-class="d-flex flex-column  justify-content-center text-center">
			      			<i class="bx bx-sad mx-auto font-size-48 text-muted mb-2"></i>
			      			<h5 class="text-muted">No Professional Bodies Listed</h5>
			      		</b-card>
			      		</b-overlay>
			      	  </b-card>
						</b-col>
					</b-row>
				</b-overlay>
				</b-card>
			</b-col>
		</b-row>
			<b-row v-if="view == 'Directory'">
					<b-col md="12">
						<b-card class="p-3">
						<h4>{{view}} Settings</h4>
						<hr>
						<b-overlay :show="loadingCompanySettings"  variant="white" opacity="0.95">
						<template #overlay>
							<b-row>
			      			<b-col md="12" class="offset-md-2" >
			      			<b-card class="border-radius-1em border shadow-none mb-0">	
					            <div class="cube-wrapper mx-auto"><div class="cube-folding"><span class="leaf1"></span><span class="leaf2"></span><span class="leaf3"></span><span class="leaf4"></span></div></div>
					            <h6 class="text-center">Loading Your Company Details</h6>
					             <b-progress class="w-50 mx-auto" v-model="company_settings_progress" variant="primary"></b-progress>
			         		</b-card>
			      			</b-col>
			      			</b-row>
			      		</template>
						<b-row>
						<b-col md="4" class="mt-3">
								<h5 class="d-flex">Visibility <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
							</b-col>
						<b-col md="8" class="mt-3">
							<b-card class="border-radius-1em border shadow-none">
			      		<h5 class="heading-label required">Directory Listing</h5>
			      		<p>Display my company on the following directories:</p>
			      		 <div class="d-flex">
			       
			              <b-button
			                      class=" flex-grow-1 my-0 w-sm border-radius-1em mr-1"
			                      value="0"
			                      :class="{'active' : company_update.public == (true || 1 || '1')}"
			                      variant="outline-light"
			                      @click="company_update.public = !company_update.public"
			                    ><i v-if="company_update.public == (true || 1 ||  '1')" class="bx bx-check-circle font-size-24 d-block text-success my-2"></i> 
			                    <i v-else class="bx bx-basket font-size-24 d-block text-primary my-2"></i> Public Market
			              </b-button>
			              <b-button
			                      class=" flex-grow-1 my-0 w-sm border-radius-1em ml-1"
			                      value="0"
			                      :class="{'active' : company_update.trade == (true || 1 || '1')}"
			                      variant="outline-light"
			                      @click="company_update.trade = !company_update.trade"
			                    ><i v-if="company_update.trade == (true || 1 || '1')" class="bx bx-check-circle font-size-24 d-block text-success my-2"></i> 
			                    <i v-else class="bx bx-basket font-size-24 d-block text-primary my-2"></i> Trade Market
			              </b-button>
			              <input type="hidden" name="active" :value="company_update.active">
			              <input type="hidden" name="public_active" :value="company_update.public">
			              <input type="hidden" name="trade_active" :value="company_update.trade">
			            </div>
			            <small>You can change this setting later or assign an alternative address.</small>
			      	  </b-card>
			      	</b-col>
			      	<b-col md="4" class="mt-3" v-if="company_update.public == true || company_update.trade == true">
								<h5 class="d-flex">Profile Information <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
							</b-col>
						<b-col md="8" class="mt-3"  v-if="company_update.public == true || company_update.trade == true">
			      	  <b-tabs pills content-class="mt-3">
			      	  <b-tab title="Public" v-if="company_update.public == true">
			      	  <b-card class="border-radius-1em border shadow-none" v-if="(directoryBannerUpload == false)" @mouseover="directoryBannerOptions = true" @mouseout="directoryBannerOptions = false">
								<b-img-lazy v-if="('public' in company_update.profile_banners) && (company_update.profile_banners['public'] !== null)" :src="storageurl + '/' + company_update.profile_banners['public']"  class="mx-auto img-fluid border" />
								<b-img-lazy :src="'/images/banner-placeholder.png'" v-else class="mx-auto img-fluid border" />

								<div class="left-0 right-0 top-0 h-100 w-100 opacity-75" :class="{'h-100 d-flex flex-column position-absolute bg-white bg-soft w-75 mx-auto': directoryBannerOptions == true, 'd-none': directoryBannerOptions == false}">
                              <a class="btn btn-primary btn-rounded mt-auto w-75 mx-auto mb-1" @click="$emit('file-selected', object), $emit('close-window')">Edit</a>
                              <a class="btn btn-primary btn-rounded mb-auto w-75 mx-auto mt-1" @click="directoryBannerUpload = !directoryBannerUpload" v-b-modal.uploadModal>Upload New</a>
                            </div>
                        		</b-card>
                        		<b-card v-else class="border-radius-1em border shadow-none">
                        			<b-form id="upload-banner">
                        			<input type="hidden" name="banner" v-model="company_update.profile_banners['public']">
                        		</b-form>
                        		 <vue-dropzone id="dropzone" ref="myVueDropzone" :use-custom-slot="true" :options="avatarDropzoneOptions" @vdropzone-file-added="directoryFileAdded"  @vdropzone-drop="directoryFileAdded">
			                        <div class="dropzone-custom-content d-flex flex-column justify-content-center align-items-center" style="height: 500px;">
			                            <i class="display-4 text-muted bx bxs-cloud-upload"></i>
			                            <h4>Drop files here or click to upload.</h4>
			                        </div>
                        		</vue-dropzone>
                        		<div class="d-flex">
                        		<a class="btn btn-primary mb-auto w-50 mx-auto btn-rounded mt-1 mr-1" @click="uploadDirectoryBanner()" v-b-modal.uploadModal>Upload</a>
                        		<a class="btn btn-danger mb-auto w-50 mx-auto btn-rounded mt-1 ml-1" @click="directoryBannerUpload = !directoryBannerUpload" v-b-modal.uploadModal>Cancel</a>
                        		</div>
                    		</b-card>

                    		
                    	</b-tab>
                    	<b-tab title="Trade" v-if="company_update.trade == true">
			      	  <b-card class="border-radius-1em border shadow-none" v-if="(tradeDirectoryBannerUpload == false)" @mouseover="tradeDirectoryBannerOptions = true" @mouseout="tradeDirectoryBannerOptions = false">
								<b-img-lazy v-if="('trade' in company_update.profile_banners) && (company_update.profile_banners['trade'] !== null)" :src="storageurl + '/' + company_update.profile_banners['trade']"  class="mx-auto img-fluid border" />
								<b-img-lazy :src="'/images/banner-placeholder.png'" v-else class="mx-auto img-fluid border" />

								<div class="left-0 right-0 top-0 h-100 w-100 opacity-75" :class="{'h-100 d-flex flex-column position-absolute bg-white bg-soft w-75 mx-auto': tradeDirectoryBannerOptions == true, 'd-none': tradeDirectoryBannerOptions == false}">
                              <a class="btn btn-primary btn-rounded mt-auto w-75 mx-auto mb-1" @click="$emit('file-selected', object), $emit('close-window')">Edit</a>
                              <a class="btn btn-primary btn-rounded mb-auto w-75 mx-auto mt-1" @click="tradeDirectoryBannerUpload = !tradeDirectoryBannerUpload" v-b-modal.uploadModal>Upload New</a>
                            </div>
                        		</b-card>
                        		<b-card v-else class="border-radius-1em border shadow-none">

                        		<b-form id="upload-tradebanner">
                        		<input type="hidden" name="banner" v-model="company_update.profile_banners['trade']">
                        		</b-form>
                        		 <vue-dropzone id="dropzone" ref="myVueDropzone" :use-custom-slot="true" :options="avatarDropzoneOptions" @vdropzone-file-added="tradeDirectoryFileAdded"  @vdropzone-drop="tradeDirectoryFileAdded">
			                        <div class="dropzone-custom-content d-flex flex-column justify-content-center align-items-center" style="height: 500px;">
			                            <i class="display-4 text-muted bx bxs-cloud-upload"></i>
			                            <h4>Drop files here or click to upload.</h4>
			                        </div>
                        		</vue-dropzone>
                        		<div class="d-flex">
                        		<a class="btn btn-primary mb-auto w-50 mx-auto btn-rounded mt-1 mr-1" @click="uploadTradeDirectoryBanner()" v-b-modal.uploadModal>Upload</a>
                        		<a class="btn btn-danger mb-auto w-50 mx-auto btn-rounded mt-1 ml-1" @click="tradeDirectoryBannerUpload = !tradeDirectoryBannerUpload" v-b-modal.uploadModal>Cancel</a>
                        		</div>
                    		</b-card>

                    		<b-card class="border-radius-1em border shadow-none">
                    			<h5>Trade Contact Details</h5>
                    			<p>Trade contact details are the main details that show on your trade profile as the main point of contact for trade enquiries.</p>
                    				 <b-card class="border-radius-1em border shadow-none">
			                <b-form-group label="Search Address">
			                  <vue-google-autocomplete id="map" :enableGeolocation="false" types="establishment" :fields="['address_components', 'adr_address', 'alt_id', 'formatted_address', 'geometry', 'icon', 'id', 'name', 'business_status', 'photo', 'place_id', 'scope', 'type', 'url', 'utc_offset', 'vicinity', ]" classname="form-control" placeholder="Start typing" v-on:placechanged="getTradeAddressData">
			                    </vue-google-autocomplete>
			                </b-form-group>
			                <gmap-map v-if="googleSearchTradeAddress !== ''" :center="{ lat: googleSearchTradeAddress.latitude, lng: googleSearchTradeAddress.longitude }" :zoom="10" style="height: 350px" class="mt-2 border-radius-1em">
			                  <gmap-marker
			                    
			                    :key="index"
			                    :position="{ lat: googleSearchTradeAddress.latitude, lng: googleSearchTradeAddress.longitude }"
			                    :clickable="true"
			                    :draggable="true"
			                    @click="center = { lat: googleSearchTradeAddress.latitude, lng: googleSearchTradeAddress.longitude }"
			                  ></gmap-marker>
			                </gmap-map>
			              </b-card>
			              <b-card class="border-radius-1em border shadow-none">
								<b-form-group  label="Address 1">
				                  <b-form-input type="text" name="address1" v-model="company_update.profile_contact['trade']['address1']"></b-form-input>
				                </b-form-group>
				                <b-form-group class="mt-2" label="Address 2">
				                  <b-form-input type="text" name="address2" v-model="company_update.profile_contact['trade']['address2']"></b-form-input>
				                </b-form-group>
				                <b-form-group class="mt-2" label="Address 3">
				                  <b-form-input type="text" name="address3" v-model="company_update.profile_contact['trade']['address3']"></b-form-input>
				                </b-form-group>
				                
				                <b-form-group class="mt-2" label="Country">
				                  <b-form-select class="form-control" v-model="company_update.profile_contact['trade']['country_id']" name="country_id"  @input="getCounties(company_update.profile_contact['trade']['country_id'])">
				                    <b-form-select-option v-for="country in countries" :value="country.id">
				                      {{country.name}}
				                    </b-form-select-option>
				                  </b-form-select>
				                </b-form-group>
				                <b-form-group class="mt-2 mb-2" label="County/State">
				                  <b-form-select class="form-control" name="county_id" :disabled="(counties.length == 0)">
				                    <b-form-select-option v-for="county in counties" :value="county.id" >
				                      {{county.name}}
				                    </b-form-select-option>
				                  </b-form-select>
				                </b-form-group>
				                <b-form-group class="mb-2" label="City">
				                  <b-form-input v-model="company_update.profile_contact['trade']['city']" type="text" name="city"></b-form-input>
				                </b-form-group>
				                <b-form-group class="mb-2" label="Postcode/Zip">
				                  <b-form-input v-model="company_update.profile_contact['trade']['postcode']" type="text" name="postcode"></b-form-input>
				                </b-form-group>
				              </b-card>

				              <b-card class="border-radius-1em border shadow-none">
								<b-form-group label="Contact Name" class="mb-2">
									<b-input-group>
										<b-form-input type="text" v-model="company_update.profile_contact['trade']['name']">
										</b-form-input>
									</b-input-group>
								</b-form-group>
								<b-form-group label="Website" class="mb-2">
									<b-input-group>
										<b-form-input type="website" v-model="company_update.profile_contact['trade']['website']">
										</b-form-input>
									</b-input-group>
								</b-form-group>
								<b-form-group label="Email" class="mb-2">
									<b-input-group prepend="@">
										<b-form-input type="email" v-model="company_update.profile_contact['trade']['email']">
										</b-form-input>
									</b-input-group>
								</b-form-group>
								<b-form-group label="Telephone" class="mb-2">
									<b-input-group :prepend="'+'+$attrs.company['country']['call_prefix']">
										<b-form-input type="telephone" v-model="company_update.telephone">
										</b-form-input>
									</b-input-group>
								</b-form-group>
							</b-card>
                    			
                    			<b-button class="btn btn-success btn-block btn-rounded" @click="updateSettings(view)">
                    				Update Trade Contact
                    			</b-button>
                    		</b-card>
                    	</b-tab>
                    </b-tabs>
							</b-col>

							
						</b-row>
					</b-overlay>
						</b-card>
					</b-col>
				</b-row>
				<b-row v-if="view == 'User'">
					<b-col md="12">
						<b-card class="p-3">
						<h4>{{view}} Settings</h4>
						<hr>
						<b-row>
							<b-col md="2">
								<h5 class="d-flex">Users Permissions <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
							</b-col>
							<b-col md="10">
								<b-card class="border-radius-1em border shadow-none">
									<b-list-group>
										<b-list-group-item v-for="role in user_roles">
											<div class="d-flex w-100">
											<span class="my-auto">{{role.name}}</span> <a class="btn btn-outline-primary ml-auto" @click="getUserRolePermissions(role.id), permission_resources = menuItems, permission_role = role">Set Default Permissions <i class="bx bx-padlock"></i></a>
											</div>
										</b-list-group-item>
									</b-list-group>
								</b-card>
							</b-col>
						</b-row>
						<b-row>
							<b-col md="2">
								<h5 class="d-flex">Import Users <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
							</b-col>
							<b-col md="10">
									<b-overlay 
								:show="importLoading"
								>
								<b-form id="import-users" method="post" enctype="multipart/form-data">
								<b-card class="border-radius-1em border shadow-none">
				<div class="d-flex mb-3">
					<b-button
                      class=" flex-grow-1 my-0 w-xl border-radius-1em mr-1"
                      value="csv"
                      variant="outline-light"
                      v-model="import_type"
                      @click="import_type = 'csv'"
                    >
                    <i v-if="import_type == 'csv'" class="bx bx-check-circle text-success font-size-24 d-block py-4 my-2"></i>
                    	<i v-else class="bx bx-spreadsheet text-primary font-size-24 d-block py-4 my-2"></i> CSV Import
              		</b-button>
              		<b-button
                      class=" flex-grow-1 my-0 w-xl border-radius-1em ml-1"
                      value="website"
                      variant="outline-light"
                      v-model="import_type"
                     @click="import_type = 'website'"
                    >
                    	<i v-if="import_type == 'website'" class="bx bx-check-circle text-success font-size-24 d-block py-4 my-2"></i>
                    	<i v-else class="bx bx-globe text-primary font-size-24 d-block py-4 my-2"></i> Website Import
              		</b-button>
          	  </div>
              <div class="d-flex" v-if="import_type == 'website'"> 
              	
              </div>
              <div class="mt-3" v-if="(import_type == 'csv')">
              	<div class="bg-light border-radius-1em p-3 mb-2" v-if="(import_type == 'csv') && (import_step !== ( 3 || 'error'))">
              		<h6>How To Import from CSV:</h6>
              		<ol>
              			<li>Create a Compatible CSV or XLS sheet <small><a class="/csv-examples/products.csv">Click here to Download an Example</a></small></li>
              			<li>Upload Your Sheet and Match Your Columns</li>
              			<li>Process Your Upload to Add To Stock To Your Library</li>
              		</ol>
              	 </div>
              	 <div class="mt-3" v-if="(import_type == 'csv') && (import_step !== ( 3 || 'error'))">
			              	<div class="bg-info bg-soft border-radius-1em p-3">
			              		<h6>Following Fields are Required:</h6>
			              		<ul>
			              			<li v-for="key in userKeys" v-if="key.required == true" class="text-capitalize">{{(key.text).replaceAll('_', ' ')}}</li>
			              		</ul>
			              	 </div>
			              	</div>
			     <b-overlay :show="loadingCsvImport">
			     	<template #overlay>
										<b-row>
						      			<b-col md="12" class="offset-md-2" >
						      			<b-card class="border-radius-1em border shadow-none mb-0">	
								            <div class="cube-wrapper mx-auto"><div class="cube-folding"><span class="leaf1"></span><span class="leaf2"></span><span class="leaf3"></span><span class="leaf4"></span></div></div>
								            <h6 class="text-center" v-if="import_step == 1">Uploading File...</h6>
								            <h6 class="text-center" v-if="import_step == 2">Processing Import. Please wait.</h6>
								             <b-progress class="w-50 mx-auto" v-model="import_progress" variant="primary"></b-progress>
						         		</b-card>
						      			</b-col>
						      			</b-row>
						      		</template>
              	<div class="mt-3" v-if="import_step == 1">
	              	<!--<input class="form-control mb-2" name="import_file" type="file" />-->
	              	<vue-dropzone id="dropzone" ref="myVueDropzone" :use-custom-slot="true" :options="dropzoneOptions" @vdropzone-file-added="importFileAdded"  @vdropzone-drop="importFileAdded">
                        <div class="dropzone-custom-content">
                            <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                            <h4>Drop files here or click to upload.</h4>
                        </div>
                        </vue-dropzone>
	              	<div
                          class="form-check form-switch form-switch-sm d-inline-block mr-2 mt-2 mb-0 mt-auto"
                          dir="ltr"
                        >
                        <input
                            class="form-check-input"
                            type="checkbox"
                            id="csvrowheaders"
                          	 v-model="import_header_rows"
                            
                          />
                          <label class="form-check-label" for="csvrowheaders"
                            >File Includes Header Rows</label
                          >
                      </div>
                      <a class="btn btn-primary btn-rounded btn-block mt-3" @click="setupCSVImport">Set-up Import</a>
                  </div>
                  
                  <div v-if="import_step == 2">
                  
                  <b-card class="border-radius-1em border shadow-none">
                  	<simplebar style="height: 400px;">
                  	<div class="table-responsive import-products-table">
		              <table class="table mb-0">
		                <thead class="thead-dark">
		                  <tr v-for="(row, index) in import_csv_rows[0]" v-if="index == 0">
		                    <th  v-for="(col, cindex) in row" class="bg-light" style="min-width: 200px;">
		                    	<b-form-select  class="form-control border btn-rounded px-3 custom-select" :value="col" v-model="import_csv_rows[0][0][cindex]" > 
		                    		<b-form-select-option v-for="key in productKeys" :value="key.text">
		                    			{{key.text}} <span class="text-danger" v-if="key.required == true">*</span>
		                    		</b-form-select-option>
		                    	</b-form-select></th>
		                  </tr>
		                </thead>
		                <tbody>
		                  <tr  v-for="(row, index) in import_csv_rows[0].slice(1, import_csv_rows[0].length)">
		                    <th  scope="row"  v-for="(col, cindex) in row"><p>{{col}}</p></th>
		                  </tr>
		                </tbody>
		              </table>
		            </div>
		        </simplebar>
		            <a class="btn btn-primary btn-rounded btn-block mt-3" @click="processCSVImport">Process Import</a>
		        </b-card>
                  </div>
                  <div class="mt-3" v-if="import_step == 3">
                  	<b-card class="border-success border border-radius-1em shadow-none">
                  		<h4 class="text-success">Success</h4>
                  		<p>You successfully imported {{imported_rows.length}} into your stock. Check out your products list.</p>
                  		<a class="btn btn-success" target="_blank" href="/stock/products">Go to Product List</a>
                  	</b-card>
                  </div>
                  <div class="mt-3" v-if="import_step == 'error'">
                  	<b-card class="border-danger border border-radius-1em shadow-none">
                  		<h4 class="text-danger">Error Importing Products</h4>
                  		<p>There was an error importing your products. Please see the following error message for more information:</p>
                  		<div class="border border-white">
                  			Error Message
                  		</div>
                  	</b-card>
                  </div>
              </b-overlay>
              </div>
              

								</b-card>
							</b-form>
						</b-overlay>
							</b-col>

						</b-row>
						</b-card>

					</b-col>

				</b-row>
				<b-row v-if="view == 'Stock'">
					<b-col md="12">
						<b-card class="p-3">
						<h4>{{view}} Settings</h4>
						<hr>	
						<b-row>
							<b-col md="2">
							<h5 class="d-flex">Import <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>

							
							</b-col>
							<b-col md="10">
								<b-overlay 
								:show="importLoading"
								>
								<b-form id="import-products" method="post" enctype="multipart/form-data">
								<b-card class="border-radius-1em border shadow-none">
				<div class="d-flex mb-3">
					<b-button
                      class=" flex-grow-1 my-0 w-xl border-radius-1em mr-1"
                      value="csv"
                      variant="outline-light"
                      v-model="import_type"
                      @click="import_type = 'csv'"
                    >
                    <i v-if="import_type == 'csv'" class="bx bx-check-circle text-success font-size-24 d-block py-4 my-2"></i>
                    	<i v-else class="bx bx-spreadsheet text-primary font-size-24 d-block py-4 my-2"></i> CSV Import
              		</b-button>
              		<b-button
                      class=" flex-grow-1 my-0 w-xl border-radius-1em ml-1"
                      value="website"
                      variant="outline-light"
                      v-model="import_type"
                     @click="import_type = 'website', channels_type = 'ecommerce', fetchChannelsIntegrationsDetails('channels-api'),	getWebsiteSettings()"
                    >
                    	<i v-if="import_type == 'website'" class="bx bx-check-circle text-success font-size-24 d-block py-4 my-2"></i>
                    	<i v-else class="bx bx-globe text-primary font-size-24 d-block py-4 my-2"></i> Website Import
              		</b-button>
          	  </div>
              <div class="d-flex" v-if="import_type == 'website'">
              		<b-row v-if="(loadingWebsiteSettings == false)">
							<b-col md="12">
								<h5 class="d-flex">Active Integration <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
							</b-col>
							<b-col md="12">
								<b-row v-if="loadingChannelsSetup == true">
				      			<b-col md="12" >
				      			<b-card class="border-radius-1em border shadow-none mb-3">	
						            <div class="cube-wrapper mx-auto"><div class="cube-folding"><span class="leaf1"></span><span class="leaf2"></span><span class="leaf3"></span><span class="leaf4"></span></div></div>
						            <h6 class="text-center">{{channels_setup_text}}</h6>
						             <b-progress class="w-50 mx-auto" v-model="channels_setup_progress" variant="primary"></b-progress>
				         		</b-card>
				      			</b-col>
				      		</b-row>
								<b-card class="border-radius-1em border shadow-none "  body-class="d-flex justify-content-center align-items-center flex-column p-4" >
									<div class="m-3 p-3 text-center" v-if="active_ecommerce_integrations.length == 0">
										<i class="bx bx-shopping-bag font-size-24 text-primary"></i>
										<h5>No Ecommerce Integrations</h5>
										<a class="btn btn-primary btn-rounded mt-3" v-if="ecommerce_channels_settings == ''" @click="setUpChannelsApiStore()">Create Ecommerce Integrations</a>
									</div>
									<div v-else class="w-100">
										<b-overlay 
								:show="loadingEcommerceIntegration"
								>
										<b-list-group flush>
											<b-list-group-item v-for="(integration, index) in active_ecommerce_integrations" class="d-flex">
										<div class="d-flex flex-column w-100">
											<div class="d-flex w-100">
												<b-img-lazy :src="getChannelsImage(integration.integration_name)" class="my-auto mr-3" width="50px" />
												
												
												<div class="mr-auto d-flex flex-column">
													<h5 class="mt-auto mb-0 mr-2">
												{{integration.integration_name}}
												</h5>
												<h6 class="mb-auto">
												{{integration.type}}
												</h6>
													
												</div>
												<div class="ml-auto d-flex my-auto">
													Integration URL: 
													<a class="badge badge-light bg-light font-size-12" :href="integration.url" target="_blank">
														<i class="bx bx-link-external"></i>
														{{integration.url}}
													</a>
												</div>
											</div>
												<!--<div class="d-flex mr-auto flex-column">
														<span class="badge badge-light bg-light mx-auto mt-auto mb-1">
															Total Products: {{integration.channel_products.total}}
														</span>
														<span class="badge badge-light bg-light  mx-auto mb-auto mt-1">
															Matched Products: {{integration.channel_products.empty_matched_sku}}
														</span>
													</div>-->
											<div class="d-flex ml-auto">
												<a class="my-auto btn-rounded btn btn-light mr-1" v-b-modal.commerce-products @click="selected_channel_products = '', selected_channel_categories = '', selected_channel_customers = '', selected_channel_orders = '', selected_integration_uid = integration.uid, getProducts(), getActiveChannel(integration), selected_integration = integration">Import Products</a>
												<a class="my-auto btn-rounded btn btn-info mr-1" v-b-modal.auth-details @click="selected_integration = integration">Edit Configuration</a>
											
												<a class="my-auto btn-rounded btn btn-danger" @click="selected_integration_uid = integration.uid,selected_integration = integration, destroyChannel(integration, index)">Remove</a>
											</div>
										</div>
											</b-list-group-item>
										</b-list-group>
										</b-overlay>
									</div>
								</b-card>
							
							</b-col>
							<b-col md="12" v-if="ecommerce_channels_settings !== ''">
								<h5 class="d-flex">New Integration <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
							</b-col>
							<b-col md="12" v-if="ecommerce_channels_settings !== ''">
								<b-overlay 
								:show="loadingEcommerceIntegration"
								>
								<!--<b-card class="border-radius-1em border shadow-none" v-if="selected_ecommerce_integration !== ''">
									
									<div class="d-flex border-radius-1em border p-3">
							       <b-img-lazy :src="selected_ecommerce_integration.logo" :style="[{'width': '155px'}]" class="my-auto ml-2 p-2"><h5 class="ml-3 my-auto">Setup {{selected_ecommerce_integration.name}} Integration</h5>
							       <a class="btn btn-outline-light btn-rounded mb-auto ml-auto" @click="selected_ecommerce_integration = ''"><i class="mdi mdi-close"></i></a>
							       </div>
							      <b-form id="ecommerce-integration-setup" method="POST" enctype="application/x-www-form-urlencoded">
										<b-form-group v-for="(fields, key) in selected_ecommerce_integration_auth_rules" label-class="text-capitalize" :label="key.replaceAll('_', ' ').replace('id', 'ID')" class="mt-3">
											<div v-if="fields.type == 'text'">
											
										<b-form-input :name="key" :type="{'text' : fields.type == 'text'}" :required="'required' in fields" v-model="selected_ecommerce_integration_auth_details[key]"></b-form-input>
											
										</div>
										<div v-else-if="fields.type == 'checkbox'">
											<b-form-checkbox inline :name="key" :type="{'checkbox' : fields.type == 'checkbox'}" :required="'required' in fields" v-model="selected_ecommerce_integration_auth_details[key]"></b-form-checkbox>
										</div>
										<div v-else-if="fields.type == 'integer'">
											<b-form-input inline :name="key" :type="'number'" :required="'required' in fields" v-model="selected_ecommerce_integration_auth_details[key]"></b-form-input>
										</div>
										</b-form-group>
									<b-form-group label="name">
									<b-form-input type="text" name="name" v-model="selected_ecommerce_integration_auth_details.name" />
									</b-form-group>
									<b-form-group label="store_url">
									<b-form-input type="text" name="store_url" v-model="selected_ecommerce_integration_auth_details.store_url" />
									</b-form-group>
									<b-form-group label="consumer_key">
									<b-form-input type="text" name="consumer_key" v-model="selected_ecommerce_integration_auth_details.consumer_key" />
									</b-form-group>
									<b-form-group label="consumer_secret">
									<b-form-input type="text" name="consumer_secret" v-model="selected_ecommerce_integration_auth_details.consumer_secret" />
									</b-form-group>
									<b-form-group label="account_nominal_code">
									<b-form-input type="text" name="account_nominal_code" v-model="selected_ecommerce_integration_auth_details.account_nominal_code" />
									</b-form-group>
									<b-form-group label="timezone">
									<b-form-input type="text" name="timezone" v-model="selected_ecommerce_integration_auth_details.timezone" />
									</b-form-group>


									<b-form-group label="Active">
										<b-form-checkbox name="is_active" v-model="selected_ecommerce_integration_auth_details.is_active" /></b-form-group>

									<b-form-group>
										<b-form-checkbox type="text" name="use_default_get_orders_interval" v-model="selected_ecommerce_integration_auth_details.use_default_get_orders_interval" /></b-form-group>
									<b-form-input type="number" name="get_orders_interval" v-model="selected_ecommerce_integration_auth_details.get_orders_interval" />
									<b-form-group label="auto_sync_orders">
										<b-form-checkbox switch name="auto_sync_orders" v-model="selected_ecommerce_integration_auth_details.auto_sync_orders" /></b-form-group>
									<b-form-group label="manage_stock">
										<b-form-checkbox name="manage_stock" v-model="selected_ecommerce_integration_auth_details.manage_stock"  /></b-form-group>
									<b-form-group label="download_products">
										<b-form-checkbox name="download_products" v-model="selected_ecommerce_integration_auth_details.download_products" /></b-form-group>
									<b-form-group label="add_to_products">
										<b-form-checkbox name="add_to_products" v-model="selected_ecommerce_integration_auth_details.add_to_products" /></b-form-group>
									<b-form-group label="set_initial_stock_levels">
										<b-form-checkbox name="set_initial_stock_levels" v-model="selected_ecommerce_integration_auth_details.set_initial_stock_levels" /></b-form-group>
									<b-form-group label="auto_sync_stock">
										<b-form-checkbox name="auto_sync_stock" v-model="selected_ecommerce_integration_auth_details.auto_sync_stock" /></b-form-group>

									<b-form-group label="use_external_shipment_modules">
										<b-form-checkbox name="use_external_shipment_modules" v-model="selected_ecommerce_integration_auth_details.use_external_shipment_modules" /></b-form-group>

									<b-form-group label="download_product_images">
										<b-form-checkbox name="download_product_images" v-model="selected_ecommerce_integration_auth_details.download_product_images" /></b-form-group>
									<b-form-group label="download_product_dimensions">
										<b-form-checkbox name="download_product_dimensions" v-model="selected_ecommerce_integration_auth_details.download_product_dimensions" /></b-form-group>
									<b-form-group label="deduct_stock_via_orders">
										<b-form-checkbox name="deduct_stock_via_orders" v-model="selected_ecommerce_integration_auth_details.deduct_stock_via_orders" /></b-form-group>
									<b-form-group label="ignore_orders_before">
										<b-form-checkbox name="ignore_orders_before" v-model="selected_ecommerce_integration_auth_details.ignore_orders_before" /></b-form-group>
									<b-form-group label="ignore_orders_before_at">
									<b-form-datepicker name="ignore_orders_before_at" v-model="selected_ecommerce_integration_auth_details.ignore_orders_before_at" />
									</b-form-group>
							    	<b-button @click="setUpChannel(selected_ecommerce_integration)" variant="primary" class="mt-3 btn-block btn-rounded">Set-up Integration</b-button>
							    	</b-form>
							    </b-card>-->
							  </b-overlay>
								<b-card class="border-radius-1em border shadow-none" >
									
							      <div class="d-flex">
							      	
							      		<a v-if="(start_marketplaces !== 0)" class="btn btn-light btn-rounded mr-auto my-auto"  @click="start_marketplaces -= 12, end_marketplaces -= 12"><i class="bx bx-chevron-left"></i></a>
							      	
							      		<div class="search-box mx-2 w-100 my-3">
							                <div class="position-relative">
							                  <input
							                    type="text"
							                    class="form-control w-100 border-0 bg-light"
							                    placeholder="Search name, reference or description..."
							                    v-model="carrier_search"
							                    @keypress.enter="start_marketplaces = 0, end_marketplaces = 12"
							                  />
							                  <i class="bx bx-search-alt search-icon"></i>
							                </div>
							              </div>
							      		<a v-if="(start_marketplaces + 12) < all_carrier_integrations.length" class="btn btn-light btn-rounded ml-auto my-auto" @click="start_marketplaces += 12, end_marketplaces += 12"><i class="bx bx-chevron-right"></i></a>
							      	
							      </div>
							     
							      <div class="row" >
							          <div class="col-md-3" v-for="(carrier, index) in filteredCommerceChannels('Shopping Carts')">
							           <div class="card shadow-none border border-radius-1em mb-2" @click="startIntegration = true, selected_ecommerce_integration = carrier, selected_ecommerce_integration_index = index" :class="{'active': selected_ecommerce_integration_index == index}">
							            
							           <div class="card-body text-center d-flex flex-column position-relative">
							           	<i v-if="selected_ecommerce_integration_index == index" class="bx bx-check-circle text-success position-absolute top-0 right-0 mr-2 mt-2 font-size-24"></i>
							            <b-img-lazy :src="carrier.image" class="my-3 mx-auto" :style="[carrier.name == 'Shoplazza' ? 'padding' : '40px 0']"  width="120px" />
							            <p class="mx-auto my-auto pt-2">{{carrier.name}}</p>
							            </div>
							          </div>
							          </div>
							       </div>
							       <b-card no-body class="border border-radius-1em shadow-none" id="add-ecommerce-integration">
							       	<b-overlay :show="loadingEcommerceIntegration">
							       		<template #overlay>
										<b-row>
						      			<b-col md="12" class="offset-md-2" >
						      			<b-card class="border-radius-1em border shadow-none mb-0">	
								            <div class="cube-wrapper mx-auto"><div class="cube-folding"><span class="leaf1"></span><span class="leaf2"></span><span class="leaf3"></span><span class="leaf4"></span></div></div>
								            <h6 class="text-center">Setting up...</h6>
								             <b-progress class="w-50 mx-auto" v-model="oauth_setup_progress" variant="primary"></b-progress>
						         		</b-card>
						      			</b-col>
						      			</b-row>
						      		</template>
							        <div class="row mb-3" v-if="startIntegration == true">
							      		<div class="col-md-12 d-flex">
							      		<a class="btn btn-outline-light ml-auto my-auto rounded-circle mt-2 mr-2" @click="startIntegration = false">
							      			<i class="mdi mdi-close"></i>
							      		</a>
							      		</div>
							      		<div class="col-md-12 mx-auto d-flex flex-column justify-content-center" style="min-height: 300px;">
							      			<h4 class="text-center"><i class="bx bx-link"></i> Enter Website URL</h4>
							      				<b-form-group label="" class="w-75 mx-auto">
								      				<b-form-input type="text" v-model="ecommerce_website_url"></b-form-input>
							      				</b-form-group>
							      			<a class="btn btn-primary btn-rounded mt-3 mx-auto" @click="setupOauthIntegration()">
							      			Start Integration
							      			</a>
							      		</div>
							      	 </div>
							      	</b-overlay>
							  		</b-card>
							    </b-card>
							    
							</b-col>
					        </b-row>
              </div>
              <div class="mt-3" v-if="(import_type == 'csv')">
              	<div class="bg-light border-radius-1em p-3 mb-2" v-if="(import_type == 'csv') && (import_step !== ( 3 || 'error'))">
              		<h6>How To Import from CSV:</h6>
              		<ol>
              			<li>Create a Compatible CSV or XLS sheet <small><a class="/csv-examples/products.csv">Click here to Download an Example</a></small></li>
              			<li>Upload Your Sheet and Match Your Columns</li>
              			<li>Process Your Upload to Add To Stock To Your Library</li>
              		</ol>
              	 </div>
              	 <div class="mt-3" v-if="(import_type == 'csv') && (import_step !== ( 3 || 'error'))">
			              	<div class="bg-info bg-soft border-radius-1em p-3">
			              		<h6>Following Fields are Required:</h6>
			              		<ul>
			              			<li v-for="key in productKeys" v-if="key.required == true" class="text-capitalize">{{(key.text).replaceAll('_', ' ')}}</li>
			              		</ul>
			              	 </div>
			              	</div>
              	<div class="mt-3" v-if="import_step == 1">
	              	<!--<input class="form-control mb-2" name="import_file" type="file" />-->
	              	<vue-dropzone id="dropzone" ref="myVueDropzone" :use-custom-slot="true" :options="dropzoneOptions" @vdropzone-file-added="importFileAdded"  @vdropzone-drop="importFileAdded">
                        <div class="dropzone-custom-content">
                            <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                            <h4>Drop files here or click to upload.</h4>
                        </div>
                        </vue-dropzone>
	              	<div
                          class="form-check form-switch form-switch-sm d-inline-block mr-2 mt-2 mb-0 mt-auto"
                          dir="ltr"
                        >
                        <input
                            class="form-check-input"
                            type="checkbox"
                            id="csvrowheaders"
                          	 v-model="import_header_rows"
                            
                          />
                          <label class="form-check-label" for="csvrowheaders"
                            >File Includes Header Rows</label
                          >
                      </div>
                      <a class="btn btn-primary btn-rounded btn-block mt-3" @click="setupCSVImport">Set-up Import</a>
                  </div>
                  
                  <div v-if="import_step == 2">
                  
                  <b-card class="border-radius-1em border shadow-none">
                  	<simplebar style="height: 400px;">
                  	<div class="table-responsive import-products-table">
		              <table class="table mb-0">
		                <thead class="thead-dark">
		                  <tr v-for="(row, index) in import_csv_rows[0]" v-if="index == 0">
		                    <th  v-for="(col, cindex) in row" class="bg-light" style="min-width: 200px;">
		                    	<b-form-select v-if="!import_csv_rows[0][0][cindex].includes('custom_field__') || !import_csv_rows[0][0][cindex].includes('feature_name__')" class="form-control border btn-rounded px-3 custom-select" :value="col" v-model="import_csv_rows[0][0][cindex]" > 
		                    		<b-form-select-option v-for="key in productKeys" :value="key.text">
		                    			{{key.text}} <span class="text-danger" v-if="key.required == true">*</span>
		                    		</b-form-select-option>
		                    	</b-form-select>
		                    	<b-form-input type="text" v-else v-model="import_csv_rows[0][0][cindex]">

		                    	</b-form-input>
		                    	</th>
		                  </tr>
		                </thead>
		                <tbody>
		                  <tr  v-for="(row, index) in import_csv_rows[0].slice(1, import_csv_rows[0].length)">
		                    <th  scope="row"  v-for="(col, cindex) in row"><p>{{col}}</p></th>
		                  </tr>
		                </tbody>
		              </table>
		            </div>
		        </simplebar>
		            <a class="btn btn-primary btn-rounded btn-block mt-3" @click="processCSVImport">Process Import</a>
		        </b-card>
                  </div>
                  <div class="mt-3" v-if="import_step == 3">
                  	<b-card class="border-success border border-radius-1em shadow-none">
                  		<h4 class="text-success">Success</h4>
                  		<p>You successfully imported {{imported_rows.length}} into your stock. Check out your products list.</p>
                  		<a class="btn btn-success" target="_blank" href="/stock/products">Go to Product List</a>
                  	</b-card>
                  </div>
                  <div class="mt-3" v-if="import_step == 'error'">
                  	<b-card class="border-danger border border-radius-1em shadow-none">
                  		<h4 class="text-danger">Error Importing Products</h4>
                  		<p>There was an error importing your products. Please see the following error message for more information:</p>
                  		<div class="border border-white">
                  			Error Message
                  		</div>
                  	</b-card>
                  </div>
              </div>
           

								</b-card>
							</b-form>
						</b-overlay>
							</b-col>
						</b-row>
						</b-card>
					</b-col>
				</b-row>
				<b-row v-if="view == 'Order'">
					<b-col md="12">
						<b-card class="p-3">
						<h4>{{view}} Settings</h4>
						<hr>
						<b-overlay :show="loadingCompanySettings"  variant="white" opacity="0.95">
						<template #overlay>
							<b-row>
			      			<b-col md="12" class="offset-md-2" >
			      			<b-card class="border-radius-1em border shadow-none mb-0">	
					            <div class="cube-wrapper mx-auto"><div class="cube-folding"><span class="leaf1"></span><span class="leaf2"></span><span class="leaf3"></span><span class="leaf4"></span></div></div>
					            <h6 class="text-center">Loading Your Order Settings...</h6>
					             <b-progress class="w-50 mx-auto" v-model="company_settings_progress" variant="primary"></b-progress>
			         		</b-card>
			      			</b-col>
			      			</b-row>
			      		</template>	
						<b-row class="mt-4">
							<b-col md="4">
								<h5 class="d-flex">Country & Currency <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
							</b-col>
							<b-col md="8">
								<b-card class="shadow-none border border-radius-1em">	
									<b-row>
										<b-col md="12">
										<b-form-group class="mt-2" label="Country">
						                  <b-form-select class="form-control" v-model="company_update.trading_country_id" name="country_id">
						                    <b-form-select-option v-for="country in countries" :value="country.id">
						                      {{country.name}}
						                    </b-form-select-option>
						                  </b-form-select>
						                </b-form-group>

						                	<b-form-group class="mt-2" label="Currency">
						                  <b-form-select class="form-control" v-model="selected_currency" name="country_id" @input="currencySelected">
						                    <b-form-select-option v-for="currency in currencies" :value="currency">
						                      {{currency.code}}
						                    </b-form-select-option>
						                  </b-form-select>
						                </b-form-group>
						            </b-col>
						            <b-col md="12">
									   <b-button @click="updateSettings('')" variant="success" class="btn-rounded btn-block">
										Update
									</b-button>
						            </b-col>
									</b-row>
								</b-card>
							</b-col>
							<b-col md="4">
								<h5 class="d-flex">Invoices <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
							</b-col>
							<b-col md="8">
								<b-card class="shadow-none border border-radius-1em">	
							<b-row>
								<b-col md="6">
									<b-form-group label="Invoice Prefix" >
										<b-form-input type="text" v-model="invoice.invoice_prefix">
										</b-form-input>
									</b-form-group>
								</b-col>
								<b-col md="6">
									<b-form-group label="Invoice Number Length" description="The length of your invoice number, for example adding 6 here will start your invoicing at 000001">
										<b-form-input type="number" v-model="invoice.invoice_no_length">
										</b-form-input>
									</b-form-group>
								</b-col>
								<b-col md="6">
									<b-form-group label="Invoice Number">
										<b-form-input type="number" v-model="invoice.invoice_no">
										</b-form-input>
									</b-form-group>
								</b-col>
								<b-col md="6" class="d-flex">
									<a class="btn btn-success btn-rounded btn-block mt-auto" @click="updateSettings(view)">Update</a>
								</b-col>
								<b-col md="12">
									{{invoice.invoice_prefix}} <span v-for="index in invoiceNoLength">0</span>{{invoice.invoice_no}}
								</b-col>
							</b-row>
								</b-card>
							</b-col>
							<b-col md="4">
								<h5 class="d-flex">Order Quantity Settings <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
							</b-col>
							<b-col md="8">
								 <b-overlay variant="white" opacity="0.8" :show="!company_update.split_stock == 1" no-wrap class="border-radius-1em">
									 	<template #overlay>
									 		<div class="d-flex flex-column h-100 justify-content-center align-items-center">
									 			<div class="w-75 text-center">
									 			<i class="bx bx-no-entry font-size-42 text-muted"></i>
									 			<h5>This Feature is Still in Testing</h5>
									 			</div>
									 		</div>
									 	</template>
      								 </b-overlay>
							<b-form-group>
								<b-form-radio-group v-model="company_update.split_stock">
									<b-form-radio :value="0" description="Treat all your stock as one total quantity. Mainly for individual stores selling across multiple channels. This keeps your product stock in-line with your stock in all other locations, including a single store">
										Combine online and in-store - Single Store Mode
									</b-form-radio>
									<b-form-radio :value="1" description="Treat all your stock as online stock and in-store stock as separate. Mainly for multiple stores selling across multiple channels. This allows you to keep webstock separately from your in-store stock and assign a default listing as a central stock room for each source">
										Split online and in-store stock - Multi Store Mode
									</b-form-radio>
								</b-form-radio-group>	
							</b-form-group>

								<b-tabs pills class="mt-4 pt-3 position-relative" content-class="pt-3">
									
									<b-tab title="Website & Integrations">
										<b-card class="shadow-none border border-radius-1em">
						<h4 class="card-title mb-3">Default Website & Integration Stock Listing</h4>
						<p>When not using Click & Collect, Orders from your website, e-commerce or marketplace integrations will be deducted from your products overall quantity. To use one of your listings as the default stock room for your company - you can set it here:</p>
	        		<div class="mt-sm-0 form-inline w-100" v-if="order.default_online_listing == ''" >
              <div class="search-box mr-2 ml-auto my-3">
                <div class="position-relative">
                  <input
                    type="text"
                    class="form-control w-100 border-0 bg-light"
                    placeholder="Search name, reference or description..."
                    v-model="onlinelistingsearch" @keydown.enter="order.online_listings = '', getCompanyShopListings()" 
                  />
                  <i class="bx bx-search-alt search-icon"></i>
                </div>
              </div>
            </div>
            <div v-if="order.default_online_listing == ''">
	        		<simplebar
                            style="height: 300px"
                        >
                            <li class="list-group-item" v-for="listing in order.online_listings">
                                <div class="media">
                                    <div class="avatar-sm me-3" v-if="listing.logo">
                                        <span
                                            class="avatar-title rounded-circle bg-light"
                                        >
                                            <!--<b-img-lazy
                                            		
                                                :src="listing.logo"
                                                alt=""
                                                height="18"
                                            />-->
                                            
                                        </span>
                                      </div>
                                       <div class="avatar-sm me-3" v-else>
                                        <span class="avatar-title rounded-circle" >{{(listing.name).slice(0,1)}}</span>
                                      </div>
                                   
                                    <div class="media-body">
                                        <h5 class="font-size-14">
                                            {{listing.name}}
                                        </h5>
                                        <p class="text-muted">
                                            <span v-if="listing.address_1">{{listing.address_1}},</span>
				          	<span v-if="listing.address_2">{{listing.address_2}},</span>
				          	<span v-if="listing.address_3">{{listing.address_3}},</span>
				          	<span v-if="listing.county">{{listing.county.name}},</span>
				          	<span v-if="listing.country">{{listing.country.name}},</span>
				          	<span v-if="listing.postcode">{{listing.postcode}}</span>
                                        </p>

                                        <div class="float-end">
                                            <p class="text-muted my-2" v-if="listing.listing_type">
                                                <i 
                                                    class="bx bx-buildings me-1"
                                                ></i>
                                                {{listing.listing_type.name}}
                                            </p>
                                        </div>
                                        <p class="text-muted mb-0">
                                            <a class="btn btn-primary" @click="order.default_online_listing = listing, updateDefaultStockListing(listing, 'online')">Use Location</a>
                                        </p>
                                    </div>
                                </div>
                            </li>
                        </simplebar>
                    </div>
                    <div class="border p-4 text-center d-flex flex-column" v-else style="height: 350px">
                    	<b-row class="my-auto">
                    		<b-col md="12">
                    				<div class="avatar-sm mx-auto mb-3" v-if="order.default_online_listing.logo">
                                        <span
                                            class="avatar-title rounded-circle bg-light"
                                        >
                                            <!--<b-img-lazy
                                            		
                                                :src="order.default_online_listing.logo"
                                                alt=""
                                                height="18"
                                            />-->
                                            
                                        </span>
                                      </div>
                                       <div class="avatar-sm mx-auto mb-3" v-else @mouseenter="closeHover = 'delivery'" @mouseleave="closeHover = false">
                                        <span class="avatar-title rounded-circle" v-if="closeHover !== 'delivery'" >{{(order.default_online_listing.name).slice(0,1)}}</span>
                                        <a v-else class="avatar-title rounded-circle" @click="order.default_online_listing = '', closeHover = false">
                                        	<i class="bx bx-x text-white font-size-20"></i>
                                        </a>
                                      </div>
                                      <h4>{{order.default_online_listing.name}}</h4>

                                      <p class="text-muted">
				                            <span v-if="order.default_online_listing.address_1">{{order.default_online_listing.address_1}}<br></span>
								          	<span v-if="order.default_online_listing.address_2">{{order.default_online_listing.address_2}}<br></span>
								          	<span v-if="order.default_online_listing.address_3">{{order.default_online_listing.address_3}}<br></span>
								          	<span v-if="order.default_online_listing.county">{{order.default_online_listing.county.name}}<br></span>
								          	<span v-if="order.default_online_listing.country">{{order.default_online_listing.country.name}}<br></span>
								          	<span v-if="order.default_online_listing.postcode">{{order.default_online_listing.postcode}}</span>
                                        </p>
											
                                        
                            </b-col>
                         </b-row>
                    </div>
					</b-card>
									</b-tab>
									<b-tab title="Trade Market">
										<b-card class="shadow-none border border-radius-1em">
						<h4 class="card-title mb-3">Default Trade Stock Listing</h4>
						<p>When not using Click & Collect, Trade Marketplace Orders will be deducted from your products overall quantity. To use one of your listings as the default stock room for your company - you can set it here:</p>
	        		<div class="mt-sm-0 form-inline w-100" v-if="order.default_trade_listing == ''" >
              <div class="search-box mr-2 ml-auto my-3">
                <div class="position-relative">
                  <input
                    type="text"
                    class="form-control w-100 border-0 bg-light"
                    placeholder="Search name, reference or description..."
                    v-model="tradelistingsearch" @keydown.enter="order.trade_listings = '', getCompanyTradeListings()"
                  />
                  <i class="bx bx-search-alt search-icon"></i>
                </div>
              </div>
            </div>
            <div v-if="order.default_trade_listing == ''">
	        		<simplebar
                            style="height: 300px; overflow-y: scroll;"
                        >
                            <li class="list-group-item" v-for="listing in order.trade_listings">
                                <div class="media">
                                    <div class="avatar-sm me-3" v-if="listing.logo">
                                        <span
                                            class="avatar-title rounded-circle bg-light"
                                        >
                                            <!--<b-img-lazy
                                            		
                                                :src="listing.logo"
                                                alt=""
                                                height="18"
                                            />-->
                                            
                                        </span>
                                      </div>
                                       <div class="avatar-sm me-3" v-else>
                                        <span class="avatar-title rounded-circle" >{{(listing.name).slice(0,1)}}</span>
                                      </div>
                                   
                                    <div class="media-body">
                                        <h5 class="font-size-14">
                                            {{listing.name}}
                                        </h5>
                                        <p class="text-muted">
                                            <span v-if="listing.address_1">{{listing.address_1}},</span>
				          	<span v-if="listing.address_2">{{listing.address_2}},</span>
				          	<span v-if="listing.address_3">{{listing.address_3}},</span>
				          	<span v-if="listing.county">{{listing.county.name}},</span>
				          	<span v-if="listing.country">{{listing.country.name}},</span>
				          	<span v-if="listing.postcode">{{listing.postcode}}</span>
                                        </p>

                                        <div class="float-end">
                                            <p class="text-muted my-2" v-if="listing.listing_type">
                                                <i 
                                                    class="bx bx-buildings me-1"
                                                ></i>
                                                {{listing.listing_type.name}}
                                            </p>
                                        </div>
                                        <p class="text-muted mb-0">
                                            <a class="btn btn-primary" @click="order.default_trade_listing = listing, updateDefaultStockListing(listing, 'trade')">Use Location</a>
                                        </p>
                                    </div>
                                </div>
                            </li>
                        </simplebar>
                    </div>
                    <div class="border p-4 text-center d-flex flex-column" v-else style="height: 350px">
                    	<b-row class="my-auto">
                    		<b-col md="12">
                    				<div class="avatar-sm mx-auto mb-3" v-if="order.default_trade_listing.logo">
                                        <span
                                            class="avatar-title rounded-circle bg-light"
                                        >
                                            <!--<b-img-lazy
                                            		
                                                :src="order.default_trade_listing.logo"
                                                alt=""
                                                height="18"
                                            />-->
                                            
                                        </span>
                                      </div>
                                       <div class="avatar-sm mx-auto mb-3" v-else @mouseenter="closeHover = 'delivery'" @mouseleave="closeHover = false">
                                        <span class="avatar-title rounded-circle" v-if="closeHover !== 'delivery'" >{{(order.default_trade_listing.name).slice(0,1)}}</span>
                                        <a v-else class="avatar-title rounded-circle" @click="order.default_trade_listing = '', closeHover = false">
                                        	<i class="bx bx-x text-white font-size-20"></i>
                                        </a>
                                      </div>
                                      <h4>{{order.default_trade_listing.name}}</h4>

                                      <p class="text-muted">
				                            <span v-if="order.default_trade_listing.address_1">{{order.default_trade_listing.address_1}}<br></span>
								          	<span v-if="order.default_trade_listing.address_2">{{order.default_trade_listing.address_2}}<br></span>
								          	<span v-if="order.default_trade_listing.address_3">{{order.default_trade_listing.address_3}}<br></span>
								          	<span v-if="order.default_trade_listing.county">{{order.default_trade_listing.county.name}}<br></span>
								          	<span v-if="order.default_trade_listing.country">{{order.default_trade_listing.country.name}}<br></span>
								          	<span v-if="order.default_trade_listing.postcode">{{order.default_trade_listing.postcode}}</span>
                                        </p>
											
                                        
                            </b-col>
                         </b-row>
                    </div>
					</b-card>
									</b-tab>
									<b-tab title="Public Market">
										<b-card class="shadow-none border border-radius-1em">
						<h4 class="card-title mb-3">Default Public Stock Listing</h4>
						<p>When not using Click & Collect, Trade Marketplace Orders will be deducted from your products overall quantity. To use one of your listings as the default stock room for your company - you can set it here:</p>
	        		<div class="mt-sm-0 form-inline w-100" v-if="order.default_public_listing == ''" >
              <div class="search-box mr-2 ml-auto my-3">
                <div class="position-relative">
                  <input
                    type="text"
                    class="form-control w-100 border-0 bg-light"
                    placeholder="Search name, reference or description..."
                    v-model="publiclistingsearch" @keydown.enter="order.public_listings = '', getCompanyPublicListings()" 
                  />
                  <i class="bx bx-search-alt search-icon"></i>
                </div>
              </div>
            </div>
            <div v-if="order.default_public_listing == ''">
	        		<simplebar
                            style="height: 300px"
                        >
                            <li class="list-group-item" v-for="listing in order.public_listings">
                                <div class="media">
                                    <div class="avatar-sm me-3" v-if="listing.logo">
                                        <span
                                            class="avatar-title rounded-circle bg-light"
                                        >
                                            <!--<b-img-lazy
                                            		
                                                :src="listing.logo"
                                                alt=""
                                                height="18"
                                            />-->
                                            
                                        </span>
                                      </div>
                                       <div class="avatar-sm me-3" v-else>
                                        <span class="avatar-title rounded-circle" >{{(listing.name).slice(0,1)}}</span>
                                      </div>
                                   
                                    <div class="media-body">
                                        <h5 class="font-size-14">
                                            {{listing.name}}
                                        </h5>
                                        <p class="text-muted">
                                            <span v-if="listing.address_1">{{listing.address_1}},</span>
				          	<span v-if="listing.address_2">{{listing.address_2}},</span>
				          	<span v-if="listing.address_3">{{listing.address_3}},</span>
				          	<span v-if="listing.county">{{listing.county.name}},</span>
				          	<span v-if="listing.country">{{listing.country.name}},</span>
				          	<span v-if="listing.postcode">{{listing.postcode}}</span>
                                        </p>

                                        <div class="float-end">
                                            <p class="text-muted my-2" v-if="listing.listing_type">
                                                <i 
                                                    class="bx bx-buildings me-1"
                                                ></i>
                                                {{listing.listing_type.name}}
                                            </p>
                                        </div>
                                        <p class="text-muted mb-0">
                                            <a class="btn btn-primary" @click="order.default_publc_listing = listing, updateDefaultStockListing(listing, 'public')">Use Location</a>
                                        </p>
                                    </div>
                                </div>
                            </li>
                        </simplebar>
                    </div>
                    <div class="border p-4 text-center d-flex flex-column" v-else style="height: 350px">
                    	<b-row class="my-auto">
                    		<b-col md="12">
                    				<div class="avatar-sm mx-auto mb-3" v-if="order.default_publc_listing.logo">
                                        <span
                                            class="avatar-title rounded-circle bg-light"
                                        >
                                            <!--<b-img-lazy
                                            		
                                                :src="order.default_publc_listing.logo"
                                                alt=""
                                                height="18"
                                            />-->
                                            
                                        </span>
                                      </div>
                                       <div class="avatar-sm mx-auto mb-3" v-else @mouseenter="closeHover = 'delivery'" @mouseleave="closeHover = false">
                                        <span class="avatar-title rounded-circle" v-if="closeHover !== 'delivery'" >{{(order.default_publc_listing.name).slice(0,1)}}</span>
                                        <a v-else class="avatar-title rounded-circle" @click="order.default_publc_listing = '', closeHover = false">
                                        	<i class="bx bx-x text-white font-size-20"></i>
                                        </a>
                                      </div>
                                      <h4>{{order.default_publc_listing.name}}</h4>

                                      <p class="text-muted">
				                            <span v-if="order.default_publc_listing.address_1">{{order.default_publc_listing.address_1}}<br></span>
								          	<span v-if="order.default_publc_listing.address_2">{{order.default_publc_listing.address_2}}<br></span>
								          	<span v-if="order.default_publc_listing.address_3">{{order.default_publc_listing.address_3}}<br></span>
								          	<span v-if="order.default_publc_listing.county">{{order.default_publc_listing.county.name}}<br></span>
								          	<span v-if="order.default_publc_listing.country">{{order.default_publc_listing.country.name}}<br></span>
								          	<span v-if="order.default_publc_listing.postcode">{{order.default_publc_listing.postcode}}</span>
                                        </p>
											
                                        
                            </b-col>
                         </b-row>
                    </div>
					</b-card>
									</b-tab>
								</b-tabs>
					
				</b-col>
				
				<b-col md="4">
								<h5 class="d-flex">Status <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
							</b-col>
				<b-col md="8">
					<b-card class="shadow-none border border-radius-1em">
								<h6>Default Order Status</h6>
								<b-list-group flush>
									<b-list-group-item v-for="status in order.statuses" class="d-flex">
										<div class="avatar-xs">
											<span class="avatar-title rounded-circle" :style="{'background-color' : status.color}">
												{{status.name.slice(0,1)}}
											</span>
										</div>
										<span class="ml-3 my-auto">{{status.name}}</span>
										<span class="ml-auto my-auto">
											<a class="btn rounded-circle" :class="{'btn-success' : status.default == true, 'btn-light': status.default == false}" @click="updateDefaultOrderStatus(status, 'default')">
												<i class="bx" :class="{'bx-check text-white' : status.default == true, 'bx-plus text-primary': status.default == false}" ></i>
											</a>
										</span>
									</b-list-group-item>
								</b-list-group>
							</b-card>
							<b-card class="shadow-none border border-radius-1em">
								<h6>Default Order Completed Status</h6>
								<b-list-group flush>
									<b-list-group-item v-for="status in order.statuses" class="d-flex">
										<div class="avatar-xs">
											<span class="avatar-title rounded-circle" :style="{'background-color' : status.color}">
												{{status.name.slice(0,1)}}
											</span>
										</div>
										<span class="ml-3 my-auto">{{status.name}}</span>
										<span class="ml-auto my-auto">
											<a class="btn rounded-circle" :class="{'btn-success' : status.default == true, 'btn-light': status.default == false}" @click="updateDefaultOrderStatus(status, 'default_completed')">
												<i class="bx" :class="{'bx-check text-white' : status.default == true, 'bx-plus text-primary': status.default == false}" ></i>
											</a>
										</span>
									</b-list-group-item>
								</b-list-group>
							</b-card>
							</b-col>
						</b-row>
					</b-overlay>
						</b-card>
					</b-col>
				</b-row>
				<b-row v-if="view == 'Payments'">
					<b-col md="12">
						<b-card class="p-3">
						<h4>{{view}} Settings</h4>
						<hr>
						<b-overlay :show="loadingPaymentSettings"  variant="white" opacity="0.95">
						<template #overlay>
					<b-row>
	      			<b-col md="12" class="offset-md-2" >
	      			<b-card class="border-radius-1em border shadow-none mb-0">	
			            <div class="cube-wrapper mx-auto"><div class="cube-folding"><span class="leaf1"></span><span class="leaf2"></span><span class="leaf3"></span><span class="leaf4"></span></div></div>
			            <h6 class="text-center" v-if="website_setup_text == true">Setting Up Your Website</h6>
			            <h6 class="text-center" v-else>Loading Your Payments Settings</h6>
			             <b-progress class="w-50 mx-auto" v-model="payments_setup_progress" variant="primary"></b-progress>
	         		</b-card>
	      			</b-col>
	      			</b-row>
	      		</template>
						<p v-if="!stripeAccount.length">Please connect up your Stripe account to take marketplace payments</p>
					<b-button
                      class=" flex-grow-1 my-0 w-xl"
                      value="0"
                      :class="{'active' : payments.stripe == (true || 1 || '1')}"
                      variant="outline-light"
                      @click="connectStripeAccount(), payments.stripe = !payments.stripe"
                     v-if="stripeAccount.length == 0 || stripeAccount[0] == ''"
                    ><i v-if="payments.stripe !== (true || 1)" class="bx bxl-stripe font-size-24 d-block py-4 text-primary my-2"></i> 
                    <i v-else class="bx bx-check-circle font-size-24 d-block py-4 text-success my-2"></i> Stripe
              </b-button>
              
              <!--	<b-button
                      class=" flex-grow-1 my-0 w-xl"
                      value="0"
                      :class="{'active' : payments.paypal == (true || 1 || '1')}"
                      variant="outline-light"
                      @click="payments.paypal = !payments.paypal"
                    ><i v-if="payments.paypal !== (true || 1)" class="bx bxl-paypal font-size-24 d-block py-4 text-primary my-2"></i> 
                    <i v-else class="bx bx-check-circle font-size-24 d-block py-4 text-success my-2"></i> Paypal
              </b-button>-->
              <b-row v-if="stripeAccount.length > 0 && stripeAccount[0] !== ''">
              		<b-col md="4">
              			<b-card class="shadow-sm border-radius-1em">
							<div class="d-flex align-items-center mb-3"><div class="avatar-xs mr-3"><span class="avatar-title rounded-circle bg-soft-primary text-white font-size-18"><i class="bx bx-pound"></i></span></div> <h5 class="font-size-14 mb-0">Available Balance</h5></div> <div class="text-muted mt-4"><h4 v-for="available in stripeAccount[0].balance.available">{{parseFloat(available.amount / 100).toFixed(2)}} <small>{{available.currency}}</small></h4></div>
						</b-card>
              		</b-col>
              		<b-col md="4">
              			<b-card class="shadow-sm border-radius-1em">
							<div class="d-flex align-items-center mb-3"><div class="avatar-xs mr-3"><span class="avatar-title rounded-circle bg-soft-primary text-white font-size-18"><i class="bx bx-pound"></i></span></div> <h5 class="font-size-14 mb-0">Available for Instant Payout</h5></div> <div class="text-muted mt-4"><h4 v-for="available in stripeAccount[0].balance.instant_available">{{parseFloat(available.amount / 100).toFixed(2)}} <small>{{available.currency}}</small></h4></div>
						</b-card>
              		</b-col>
              		<b-col md="4">
              			<b-card class="shadow-sm border-radius-1em">
							<div class="d-flex align-items-center mb-3"><div class="avatar-xs mr-3"><span class="avatar-title rounded-circle bg-soft-primary text-white font-size-18"><i class="bx bx-pound"></i></span></div> <h5 class="font-size-14 mb-0">Pending Payout</h5></div> <div class="text-muted mt-4"><h4 v-for="available in stripeAccount[0].balance.pending">{{parseFloat(available.amount / 100).toFixed(2)}} <small>{{available.currency}}</small></h4></div>
						</b-card>
              		</b-col>

					<b-col md="12" class="pt-4">
						<b-tabs pills content-class="pt-3">
							<b-tab title="Transactions">
							<b-card class="border-radius-1em border shadow-none">
							<b-list-group class="b-list-group">

								<b-list-group-item>
								<b-row>
									<b-col md="4">
										ID
									</b-col>
									<b-col>
										Created
									</b-col>
									<b-col>
										Type
									</b-col>
									<b-col>
										Status
									</b-col>
									<b-col>
										Amount
									</b-col>
								</b-row>
								</b-list-group-item>
								<b-list-group-item v-for="transaction in stripeAccount[0].transactions['data']">
								<b-row>
									<b-col md="4">
										<span class="badge badge-light bg-light text-dark font-size-14">{{transaction.id}}</span>
									</b-col>
									<b-col>
										{{transaction.created}}
									</b-col>
									<b-col>
										<span class="badge badge-light text-dark font-size-14">
										{{transaction.type}}
										<i v-if="transaction.type == 'payout'" class="mdi mdi-bank-transfer-in"></i>
										<i v-if="transaction.type == 'payment'" class="mdi mdi-bank-plus"></i>
										</span>
									</b-col>
									<b-col>
										<span class="badge badge-light font-size-14" :class="{'bg-soft bg-warning text-dark' : transaction.status == 'pending', 'bg-soft bg-success text-success' : transaction.status == 'available'}">{{transaction.status}}
										</span>
									</b-col>
									<b-col>
										{{parseFloat(transaction.amount) / 100}} 

										
										<span >{{transaction.currency}} </span>
									</b-col>
								</b-row>
								</b-list-group-item>
								<b-list-group-item v-if="stripeAccount[0].has_more == true">
									<a class="btn btn-light btn-rounded" @click="$limit + 10,updateStripeAccountTransactions($limit)">
										Load More
									</a>
								</b-list-group-item>
							</b-list-group>
						</b-card>
					
						</b-tab>
						<b-tab title="Settings & Capabilities">
						<b-card class="border-radius-1em border shadow-none mt-3">					
						<h5>Enable Payment Capabilities</h5>
						<hr>
						<p>
							Enable your payment capabilities using the list below.
						</p>
						<b-list-group>
							<b-list-group-item v-for="capability in stripeAccount[0].capabilities.data">
						<div 
                        class="form-check form-switch form-switch-sm mb-0 mx-auto p-0 mt-auto d-flex"
                        dir="ltr"
                      >
                      <span class="my-auto text-capitalize">{{capability.id.replaceAll('_', ' ')}}</span>
                       <span v-if="capability.requested == true" class="badge badge-soft-success ml-auto">
                        	Capability Requested <i class="bx bx-thumbs-up"></i>
                        </span>
                        <a class="btn btn-sm btn-primary btn-rounded ml-auto" v-if="capability.requested == false" @click="activateCapability(capability.name, true)">
                        	Request Capability
                        </a>	
                      <input
                      v-if="capability.status == 'active'"
                          class="form-check-input mx-2 my-auto" 
                          type="checkbox"
                          :id="'SwitchCheckSizesm'+capability.id"
                          checked
                          value="true"
                          onclick="return false;"
                          name="active"
                        />
                        <input
                      v-else
                          class="form-check-input mx-2 my-auto" 
                          type="checkbox"
                          :id="'SwitchCheckSizesm'+capability.id"
                          value="false"
                          onclick="return false;"
                          name="active"
                        />
                       
                    </div>
                </b-list-group-item>
                </b-list-group>
                <b-card class="border-radius-1em border shadow-none mt-3">					
						<h5 class="text-danger">Action Actions</h5>
						<hr>
                 <b-row>
					<b-col md="12">
		              <b-button
		                      class=" flex-grow-1 my-0 w-xl"
		                      value="0"
		                      variant="outline-light"
		                      @click="disconnectStripeAccount()"
		                      v-if="stripeAccount.length > 0 && stripeAccount[0] !== ''"
		                    >
		                    <i class="bx bx-x-circle font-size-24 d-block py-4 text-danger my-2"></i> Disconnect Account
		              </b-button>
          		</b-col></b-row>
          		</b-card>

                </b-card>
						</b-tab>
						<b-tab title="Registered Card Reader Locations">
							<b-row v-if="'data' in stripeAccount[0].locations">
								<b-card class="border-radius-1em border shadow-none mt-3">
								<b-list-group>
									<b-list-group-item class="d-flex" v-for="(location, index) in stripeAccount[0].locations.data" >
										
										<span class="my-auto mr-2">{{location.display_name}}</span>
										<span class="my-auto mr-auto">{{location.address.line1}}, {{location.address.city}}, {{location.address.postal_code}}, {{location.address.country}}</span>
										<a class="my-auto btn bg-danger bg-soft text-danger btn-soft btn-sm btn-rounded" v-if="getLocationStripeConnection(location.id) == false" @click="loadingLinkListing = true">
											Not Linked to Gemesys Shop or Listing
										</a>
										<a v-else class="my-auto btn bg-success bg-soft text-success btn-soft btn-sm btn-rounded" :href="'/company/shop/'+getLocationStripeConnection(location.id)['uid']" target="_blank">									
											Linked to Shop {{getLocationStripeConnection(location.id)['name']}}
										</a>
										<a class="btn btn-danger btn-sm btn-rounded ml-3" @click="destroyStripeLocation(location, index)">
											Remove Reader Location
										</a>
									</b-list-group-item>
								</b-list-group>
								<a class="btn btn-primary mx-auto btn-rounded" @click="addStripeLocation = true">
										Register New Payment Location
									</a>
							</b-card>
							</b-row>
							<b-row v-else>
								<b-card class="border-radius-1em border shadow-none justify-content-center d-flex flex-column text-center mt-3">
									<i class="bx bx-buildings d-block mt-4 font-size-42 text-primary"></i>
									<p class="d-block my-2">No Locations Registered</p>
									<a class="btn btn-primary mx-auto btn-rounded" @click="addStripeLocation = true">
										Register Payment Location
									</a>
								</b-card>
							</b-row>
							<b-row v-if="loadingLinkListing == true">
								<b-form-group>
									<b-form-input >
									</b-form-input>
								</b-form-group>

								<a class="btn btn-success" @click="locationListingLink(location)">
									Link Card Reader Location to this Listing
								</a>	
							</b-row>
							<b-row v-if="addStripeLocation == true">
								<b-card class="border-radius-1em border shadow-none">
												<div class="mt-sm-0 form-inline w-100" v-if="order.default_trade_listing == ''" >
              <div class="search-box mr-2 ml-auto my-3">
                <div class="position-relative">
                  <input
                    type="text"
                    class="form-control w-100 border-0 bg-light"
                    placeholder="Search name, reference or description..."
                    v-model="alllistingsearch" @keydown.enter="getAllCompanyListings()" 
                  />
                  <i class="bx bx-search-alt search-icon"></i>
                </div>
              </div>
            </div>
            <div v-if="stripelisting == ''">
	        		<simplebar
                            style="height: 300px"
                        >
                            <li class="list-group-item" v-if="alllistings.length > 0" v-for="listing in alllistings">
                                <div class="media">
                                    <div class="avatar-sm me-3" v-if="listing.logo">
                                        <span
                                            class="avatar-title rounded-circle bg-light"
                                        >
                                            <!--<b-img-lazy
                                            		
                                                :src="listing.logo"
                                                alt=""
                                                height="18"
                                            />-->
                                            
                                        </span>
                                      </div>
                                       <div class="avatar-sm me-3" v-else>
                                        <span class="avatar-title rounded-circle" >{{(listing.name).slice(0,1)}}</span>
                                      </div>
                                   
                                    <div class="media-body">
                                        <h5 class="font-size-14">
                                            {{listing.name}}
                                        </h5>
                                        <p class="text-muted">
                                            <span v-if="listing.address_1">{{listing.address_1}},</span>
				          	<span v-if="listing.address_2">{{listing.address_2}},</span>
				          	<span v-if="listing.address_3">{{listing.address_3}},</span>
				          	<span v-if="listing.county">{{listing.county.name}},</span>
				          	<span v-if="listing.country">{{listing.country.name}},</span>
				          	<span v-if="listing.postcode">{{listing.postcode}}</span>
                                        </p>

                                        <div class="float-end">
                                            <p class="text-muted my-2" v-if="listing.listing_type">
                                                <i 
                                                    class="bx bx-buildings me-1"
                                                ></i>
                                                {{listing.listing_type.name}}
                                            </p>
                                        </div>
                                        <p class="text-muted mb-0">
                                            <a class="btn btn-primary" @click="stripelisting = listing">Use Location</a>
                                        </p>
                                    </div>
                                </div>
                            </li>
                        </simplebar>
                    </div>
                    <div class="border p-4 text-center d-flex flex-column" v-else style="height: 350px">
                    	<b-overlay :show="loadingRegisteringLocation">
                    	<b-row class="my-auto">
                    		<b-col md="12">
                    				<div class="avatar-sm mx-auto mb-3" v-if="stripelisting.logo !== null">
                                        <span
                                            class="avatar-title rounded-circle bg-light"
                                        >
                                            <!--<b-img-lazy
                                            		
                                                :src="stripelisting.logo"
                                                alt=""
                                                height="18"
                                            />-->
                                            
                                        </span>
                                      </div>
                                       <div class="avatar-sm mx-auto mb-3" v-else @mouseenter="closeHover = 'delivery'" @mouseleave="closeHover = false">
                                        <span class="avatar-title rounded-circle" v-if="closeHover !== 'delivery'" >{{(stripelisting.name).slice(0,1)}}</span>
                                        <a v-else class="avatar-title rounded-circle" @click="stripelisting = '', closeHover = false">
                                        	<i class="bx bx-x text-white font-size-20"></i>
                                        </a>
                                      </div>
                                      <h4>{{stripelisting.name}}</h4>

                                      <p class="text-muted">
				                            <span v-if="stripelisting.address_1">{{stripelisting.address_1}}<br></span>
								          	<span v-if="stripelisting.address_2">{{stripelisting.address_2}}<br></span>
								          	<span v-if="stripelisting.address_3">{{stripelisting.address_3}}<br></span>
								          	<span v-if="stripelisting.county">{{stripelisting.county.name}}<br></span>
								          	<span v-if="stripelisting.country">{{stripelisting.country.name}}<br></span>
								          	<span v-if="stripelisting.postcode">{{stripelisting.postcode}}</span>
                                        </p>
										<a class="btn btn-success" @click="registerStripeLocation(stripelisting)">
											Register This Location
										</a>
                                        
                            </b-col>
                         </b-row>
                     	</b-overlay>
                    </div>
								</b-card>
							</b-row>
						</b-tab>
						<b-tab title="Card Terminals / Readers">
							<b-row v-if="'data' in stripeAccount[0].readers && stripeAccount[0].readers.length > 0">
								<b-card class="border-radius-1em border shadow-none mt-3">
								<b-list-group flush>
									<b-list-group-item class="d-flex" v-for="(reader, index) in stripeAccount[0].readers.data" >
										<b-img-lazy v-if="reader.device_type == 'simulated_wisepos_e'" class="bx bx-card-reader d-block mr-4" width="50" src="https://stripe-images.s3.amazonaws.com/terminal/hardware_skus/WisePOS-E.png" />
                   						
										<span class="my-auto mr-auto text-capitalize">
										{{reader.device_type.replaceAll('_', ' ')}}
										</span>
										<span class="my-auto">Serial No.</span>
										<span class="badge badge-light bg-light rounded-3 my-auto mr-auto ml-2 font-size-14" v-b-tooltip.hover title="Click to Reveal Serial No.">{{reader.serial_number.slice(0, 4)}}xxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxxx</span>
										<a class="my-auto btn bg-danger bg-soft text-danger btn-soft btn-sm btn-rounded" v-if="getLocationStripeConnection(reader.location) == false">
											Not Linked to Shop
										</a>
										<a class="my-auto btn bg-success bg-soft text-success btn-soft btn-sm btn-rounded" v-else :href="'/company/shop/'+getLocationStripeConnection(reader.location)['uid']" target="_blank">
											<i class="bx bx-check-circle"></i>
											Linked to Shop {{getLocationStripeConnection(reader.location)['name']}}
										
										</a>
										<a class="btn btn-danger btn-sm btn-rounded ml-3 my-auto" @click="destroyStripeReader(reader, index)">
											Remove Reader
										</a>

									</b-list-group-item>
								</b-list-group>
							</b-card>
							</b-row>
							<b-row v-else>
								<b-card class="border-radius-1em border shadow-none justify-content-center d-flex flex-column text-center mt-3">
									<i class="mdi mdi-credit-card-multiple-outline d-block mt-4 font-size-42 text-primary"></i>
									<p class="d-block my-2">No Readers Registered</p>
									<a class="btn btn-primary mx-auto btn-rounded" @click="addStripeReader = true">
										Register Payment Location
									</a>
								</b-card>
							</b-row>
							<div v-if="addStripeReader == true">
								<b-card class="border-radius-1em border shadow-none">
									<label  v-if="addreader.location == null" >Select Registered Reader Location</label>
									<SearchResource  v-if="addreader.location == null" :options="stripeAccount[0].locations.data" :resource="'terminal_location'" @resource-selected="addreader.location = $event" ></SearchResource>
									<b-form-group v-if="addreader.location !== null" label="Reader Location">
										<b-card class="border-radius-1em border shadow-none" body-class="d-flex">
											<div class="avatar-sm mr-4 my-auto">
											<span class="avatar-title rounded-circle">{{(addreader.location.display_name).slice(0,1)}}</span>
											</div>
											<div class="my-auto mr-auto">
											{{addreader.location.display_name}}<br>
											{{addreader.location.address.line1}},
											{{addreader.location.address.city}},
											{{addreader.location.address.postal_code}},
											{{addreader.location.address.country}}
											</div>
											<div class="my-auto">
												<a class="btn btn-light rounded-circle" @click="addreader.location = null" v-b-tooltip.hover title="Remove Location">
													<i class="mdi mdi-close"></i>
												</a>
											</div>
										</b-card>
									</b-form-group>
									<b-form-group class="mt-2" label="Reader Name" description="Provide a memorable name for your reader and label it appropriately with the same name used here.">
										<b-form-input type="text" v-model="addreader.name">
										</b-form-input>
									</b-form-group>
									<b-form-group class="mt-2" label="Registration Code">
										<b-form-input type="text" v-model="addreader.registration_code">
										</b-form-input>
									</b-form-group>
									<b-form-group class="mt-2" label="Advanced Configuration">

									</b-form-group>
									<a class="btn btn-success btn-rounded" @click="registerReader()">
										Register Reader
									</a>
								</b-card>
							</div>
						</b-tab>
						
						
					</b-tabs>
					</b-col>
				</b-row>
				</b-overlay>
						</b-card>
					</b-col>
				
				</b-row>
				<b-row v-if="view == 'Marketplaces'">
					<b-col md="12">
						<b-card class="p-3">
						<h4>{{view}} Settings</h4>
						<hr>
				<b-row v-if="view == 'Marketplaces'">
							<b-col md="2">
								<h5 class="d-flex">Active Integration <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
							</b-col>
							<b-col md="10">
								<b-row v-if="loadingChannelsSetup == true">
				      			<b-col md="12" >
				      			<b-card class="border-radius-1em border shadow-none mb-3">	
						            <div class="cube-wrapper mx-auto"><div class="cube-folding"><span class="leaf1"></span><span class="leaf2"></span><span class="leaf3"></span><span class="leaf4"></span></div></div>
						            <h6 class="text-center">{{channels_setup_text}}</h6>
						             <b-progress class="w-50 mx-auto" v-model="channels_setup_progress" variant="primary"></b-progress>
				         		</b-card>
				      			</b-col>
				      		</b-row>
								<b-card class="border-radius-1em border shadow-none "  body-class="d-flex justify-content-center align-items-center flex-column p-4" >
									<div class="m-3 p-3 text-center" v-if="active_ecommerce_integrations.length == 0">
										<i class="bx bx-shopping-bag font-size-24 text-primary"></i>
										<h5>No Ecommerce Integrations</h5>
										<a class="btn btn-primary btn-rounded mt-3" v-if="marketplace_channels_settings == ''" @click="setUpChannelsApiStore()">Create Ecommerce Integrations</a>
									</div>
									<div v-else class="w-100">
										<b-overlay 
								:show="loadingEcommerceIntegration"
								>
										<b-list-group flush>
											<b-list-group-item v-for="(integration, index) in active_ecommerce_integrations" class="d-flex">
										<div class="d-flex flex-column w-100">
											<div class="d-flex w-100">
												<b-img-lazy :src="getChannelsImage(integration.integration_name)" class="my-auto mr-3" width="50px" />
												
												
												<div class="mr-auto d-flex flex-column">
													<h5 class="mt-auto mb-0 mr-2">
												{{integration.integration_name}}
												</h5>
												<h6 class="mb-auto">
												{{integration.type}}
												</h6>
													
												</div>
												<div class="ml-auto d-flex my-auto">
													Integration URL: 
													<a class="badge badge-light bg-light font-size-12" :href="integration.url" target="_blank">
														<i class="bx bx-link-external"></i>
														{{integration.url}}
													</a>
												</div>
											</div>
												<!--<div class="d-flex mr-auto flex-column">
														<span class="badge badge-light bg-light mx-auto mt-auto mb-1">
															Total Products: {{integration.channel_products.total}}
														</span>
														<span class="badge badge-light bg-light  mx-auto mb-auto mt-1">
															Matched Products: {{integration.channel_products.empty_matched_sku}}
														</span>
													</div>-->
											<div class="d-flex ml-auto">
												<a class="my-auto btn-rounded btn btn-light mr-1" v-b-modal.integration-details @click="selected_integration = integration">See Details</a>
												<a class="my-auto btn-rounded btn btn-light mr-1" v-b-modal.commerce-products @click="selected_channel_products = '', selected_channel_categories = '', selected_channel_customers = '', selected_channel_orders = '', selected_integration_uid = integration.uid, getProducts(), getActiveChannel(integration), selected_integration = integration">Import and Sync</a>
												
												<!--<b-dropdown variant="primary" class="btn-rounded mr-1">
													  <template #button-content class="btn-rounded">
													     Sync
													    </template>
													<b-dropdown-item>
														Product Quantities
													</b-dropdown-item>
													<b-dropdown-item>
														Orders
													</b-dropdown-item>
												</b-dropdown>-->
												<a class="my-auto btn-rounded btn btn-danger" @click="selected_integration_uid = integration.uid,selected_integration = integration, destroyChannel(integration, index)">Remove</a>
											</div>
										</div>
											</b-list-group-item>
										</b-list-group>
										</b-overlay>
									</div>
								</b-card>
							
							</b-col>
							<b-col md="2" v-if="ecommerce_channels_settings !== ''">
								<h5 class="d-flex">New Integration <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
							</b-col>
							<b-col md="10" v-if="ecommerce_channels_settings !== ''">
								<b-overlay 
								:show="loadingEcommerceIntegration"
								>
								<!--<b-card class="border-radius-1em border shadow-none" v-if="selected_ecommerce_integration !== ''">
									
									<div class="d-flex border-radius-1em border p-3">
							       <b-img-lazy :src="selected_ecommerce_integration.logo" :style="[{'width': '155px'}]" class="my-auto ml-2 p-2"><h5 class="ml-3 my-auto">Setup {{selected_ecommerce_integration.name}} Integration</h5>
							       <a class="btn btn-outline-light btn-rounded mb-auto ml-auto" @click="selected_ecommerce_integration = ''"><i class="mdi mdi-close"></i></a>
							       </div>
							      <b-form id="ecommerce-integration-setup" method="POST" enctype="application/x-www-form-urlencoded">
										<b-form-group v-for="(fields, key) in selected_ecommerce_integration_auth_rules" label-class="text-capitalize" :label="key.replaceAll('_', ' ').replace('id', 'ID')" class="mt-3">
											<div v-if="fields.type == 'text'">
											
										<b-form-input :name="key" :type="{'text' : fields.type == 'text'}" :required="'required' in fields" v-model="selected_ecommerce_integration_auth_details[key]"></b-form-input>
											
										</div>
										<div v-else-if="fields.type == 'checkbox'">
											<b-form-checkbox inline :name="key" :type="{'checkbox' : fields.type == 'checkbox'}" :required="'required' in fields" v-model="selected_ecommerce_integration_auth_details[key]"></b-form-checkbox>
										</div>
										<div v-else-if="fields.type == 'integer'">
											<b-form-input inline :name="key" :type="'number'" :required="'required' in fields" v-model="selected_ecommerce_integration_auth_details[key]"></b-form-input>
										</div>
										</b-form-group>
									<b-form-group label="name">
									<b-form-input type="text" name="name" v-model="selected_ecommerce_integration_auth_details.name" />
									</b-form-group>
									<b-form-group label="store_url">
									<b-form-input type="text" name="store_url" v-model="selected_ecommerce_integration_auth_details.store_url" />
									</b-form-group>
									<b-form-group label="consumer_key">
									<b-form-input type="text" name="consumer_key" v-model="selected_ecommerce_integration_auth_details.consumer_key" />
									</b-form-group>
									<b-form-group label="consumer_secret">
									<b-form-input type="text" name="consumer_secret" v-model="selected_ecommerce_integration_auth_details.consumer_secret" />
									</b-form-group>
									<b-form-group label="account_nominal_code">
									<b-form-input type="text" name="account_nominal_code" v-model="selected_ecommerce_integration_auth_details.account_nominal_code" />
									</b-form-group>
									<b-form-group label="timezone">
									<b-form-input type="text" name="timezone" v-model="selected_ecommerce_integration_auth_details.timezone" />
									</b-form-group>


									<b-form-group label="Active">
										<b-form-checkbox name="is_active" v-model="selected_ecommerce_integration_auth_details.is_active" /></b-form-group>

									<b-form-group>
										<b-form-checkbox type="text" name="use_default_get_orders_interval" v-model="selected_ecommerce_integration_auth_details.use_default_get_orders_interval" /></b-form-group>
									<b-form-input type="number" name="get_orders_interval" v-model="selected_ecommerce_integration_auth_details.get_orders_interval" />
									<b-form-group label="auto_sync_orders">
										<b-form-checkbox switch name="auto_sync_orders" v-model="selected_ecommerce_integration_auth_details.auto_sync_orders" /></b-form-group>
									<b-form-group label="manage_stock">
										<b-form-checkbox name="manage_stock" v-model="selected_ecommerce_integration_auth_details.manage_stock"  /></b-form-group>
									<b-form-group label="download_products">
										<b-form-checkbox name="download_products" v-model="selected_ecommerce_integration_auth_details.download_products" /></b-form-group>
									<b-form-group label="add_to_products">
										<b-form-checkbox name="add_to_products" v-model="selected_ecommerce_integration_auth_details.add_to_products" /></b-form-group>
									<b-form-group label="set_initial_stock_levels">
										<b-form-checkbox name="set_initial_stock_levels" v-model="selected_ecommerce_integration_auth_details.set_initial_stock_levels" /></b-form-group>
									<b-form-group label="auto_sync_stock">
										<b-form-checkbox name="auto_sync_stock" v-model="selected_ecommerce_integration_auth_details.auto_sync_stock" /></b-form-group>

									<b-form-group label="use_external_shipment_modules">
										<b-form-checkbox name="use_external_shipment_modules" v-model="selected_ecommerce_integration_auth_details.use_external_shipment_modules" /></b-form-group>

									<b-form-group label="download_product_images">
										<b-form-checkbox name="download_product_images" v-model="selected_ecommerce_integration_auth_details.download_product_images" /></b-form-group>
									<b-form-group label="download_product_dimensions">
										<b-form-checkbox name="download_product_dimensions" v-model="selected_ecommerce_integration_auth_details.download_product_dimensions" /></b-form-group>
									<b-form-group label="deduct_stock_via_orders">
										<b-form-checkbox name="deduct_stock_via_orders" v-model="selected_ecommerce_integration_auth_details.deduct_stock_via_orders" /></b-form-group>
									<b-form-group label="ignore_orders_before">
										<b-form-checkbox name="ignore_orders_before" v-model="selected_ecommerce_integration_auth_details.ignore_orders_before" /></b-form-group>
									<b-form-group label="ignore_orders_before_at">
									<b-form-datepicker name="ignore_orders_before_at" v-model="selected_ecommerce_integration_auth_details.ignore_orders_before_at" />
									</b-form-group>
							    	<b-button @click="setUpChannel(selected_ecommerce_integration)" variant="primary" class="mt-3 btn-block btn-rounded">Set-up Integration</b-button>
							    	</b-form>
							    </b-card>-->
							  </b-overlay>
								<b-card class="border-radius-1em border shadow-none" >
									
							      <div class="d-flex">
							      	
							      		<a v-if="(start_marketplaces !== 0)" class="btn btn-light btn-rounded mr-auto my-auto"  @click="start_marketplaces -= 12, end_marketplaces -= 12"><i class="bx bx-chevron-left"></i></a>
							      	
							      		<div class="search-box mx-2 w-100 my-3">
							                <div class="position-relative">
							                  <input
							                    type="text"
							                    class="form-control w-100 border-0 bg-light"
							                    placeholder="Search name, reference or description..."
							                    v-model="carrier_search"
							                    @keypress.enter="start_marketplaces = 0, end_marketplaces = 12"
							                  />
							                  <i class="bx bx-search-alt search-icon"></i>
							                </div>
							              </div>
							      		<a v-if="(start_marketplaces + 12) < all_carrier_integrations.length" class="btn btn-light btn-rounded ml-auto my-auto" @click="start_marketplaces += 12, end_marketplaces += 12"><i class="bx bx-chevron-right"></i></a>
							      	
							      </div>
							     
							      <div class="row" >
							          <div class="col-md-3" v-for="(carrier, index) in filteredCommerceChannels('Marketplaces')">
							           <div class="card shadow-none border border-radius-1em mb-2" @click="startIntegration = true, selected_ecommerce_integration = carrier, selected_ecommerce_integration_index = index" :class="{'active': selected_ecommerce_integration_index == index}">
							            
							           <div class="card-body text-center d-flex flex-column position-relative">
							           	<i v-if="selected_ecommerce_integration_index == index" class="bx bx-check-circle text-success position-absolute top-0 right-0 mr-2 mt-2 font-size-24"></i>
							            <b-img-lazy :src="carrier.image" class="my-3 mx-auto" :style="[carrier.name == 'Shoplazza' ? 'padding' : '40px 0']"  width="120px" />
							            <p class="mx-auto my-auto pt-2">{{carrier.name}}</p>
							            </div>
							          </div>
							          </div>
							       </div>
							       <b-card no-body class="border border-radius-1em shadow-none">
							       	<b-overlay :show="loadingEcommerceIntegration">
							       		<template #overlay>
										<b-row>
						      			<b-col md="12" class="offset-md-2" >
						      			<b-card class="border-radius-1em border shadow-none mb-0">	
								            <div class="cube-wrapper mx-auto"><div class="cube-folding"><span class="leaf1"></span><span class="leaf2"></span><span class="leaf3"></span><span class="leaf4"></span></div></div>
								            <h6 class="text-center">Setting up...</h6>
								             <b-progress class="w-50 mx-auto" v-model="oauth_setup_progress" variant="primary"></b-progress>
						         		</b-card>
						      			</b-col>
						      			</b-row>
						      		</template>
							        <div class="row mb-3" v-if="startIntegration == true">
							      		<div class="col-md-12 d-flex">
							      		<a class="btn btn-outline-light ml-auto my-auto rounded-circle mt-2 mr-2" @click="startIntegration = false">
							      			<i class="mdi mdi-close"></i>
							      		</a>
							      		</div>
							      		<div class="col-md-12 mx-auto d-flex flex-column justify-content-center" style="min-height: 300px;">
							      			<h4 class="text-center"><i class="bx bx-link"></i> Enter Marketplace Store Name</h4>
							      				<b-form-group label="" class="w-75 mx-auto">
								      				<b-form-input type="text" v-model="ecommerce_website_url"></b-form-input>
							      				</b-form-group>
							      			<a class="btn btn-primary btn-rounded mt-3 mx-auto" @click="setupOauthIntegration()">
							      			Start Integration
							      			</a>
							      		</div>
							      	 </div>
							      	</b-overlay>
							  		</b-card>
							    	</b-card>
								</b-col>
							</b-row>
						</b-card>
					</b-col>
				</b-row>
					
				<b-row v-if="view == 'Shipping'">
					<b-col md="12">
						<b-card class="p-3">
						<h4>{{view}} Settings</h4>
						<hr>
					<b-overlay :show="loadingShippingSettings" variant="white" opacity="0.95">
					<template #overlay>
						<b-card class="border-radius-1em shadow-none mb-0">	
		            <div class="cube-wrapper mx-auto"><div class="cube-folding"><span class="leaf1"></span><span class="leaf2"></span><span class="leaf3"></span><span class="leaf4"></span></div></div>
		               <h6 class="text-center">Loading Your Shipping Settings</h6>
		             <b-progress class="w-50 mx-auto" v-model="shipping_progress" variant="primary"></b-progress>
		         		</b-card>
		         		</template>	
						<b-row>
							<b-col md="2">
								<h5 class="d-flex">Active Integration <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
							</b-col>
							<b-col md="10">
								
								<b-card class="border-radius-1em border shadow-none "  body-class="d-flex justify-content-center align-items-center flex-column p-4" >
									<div class="m-3 p-3 text-center" v-if="active_carrier_integrations.length == 0">
										<i class="bx bxs-truck font-size-24 text-primary"></i>
										<h5>No Shipping Integrations</h5>
										<a class="btn btn-primary btn-rounded mt-3" v-if="shipping_apikeys == ''" @click="setUpCourierApiKey()">Create API Key</a>
									</div>
									<div v-else class="w-100">
										<b-overlay 
								:show="loadingShippingIntegration"
								>
										<b-list-group flush>
											<b-list-group-item v-for="courier in active_carrier_integrations" class="d-flex">
												<b-img-lazy :src="getShippingImage(courier.courier)" class="my-auto mr-3" width="75px" />
												<h6 class="my-auto mr-auto">
												Courier name: {{courier.courier}}
												</h6>
												<a class="my-auto w-25 btn-rounded btn btn-light mr-2" v-b-modal.auth-details @click="selected_auth = courier">See Auth Details</a>

												<a class="my-auto w-25 btn-rounded btn btn-primary" @click="deleteShippingAuth(courier)">Remove Authorisation</a>
											</b-list-group-item>
										</b-list-group>
										</b-overlay>
									</div>
								</b-card>
							
							</b-col>
							<b-col md="2" v-if="shipping_apikeys !== ''">
								<h5 class="d-flex">New Integration <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
							</b-col>
							<b-col md="10" v-if="shipping_apikeys !== ''">
								<b-overlay 
								:show="loadingShippingIntegration"
								>
								<b-card class="border-radius-1em border shadow-none" v-if="selected_carrier_integration !== ''">
									
									<div class="d-flex border-radius-1em border p-3">
							       <b-img-lazy :src="selected_carrier_integration.thumbnail" :style="[selected_carrier_integration.key == 'DeutschePost' ? {'width': '50px'} : {'width': '75px'}]" class="my-auto ml-2 border rounded-circle" /><h5 class="ml-3 my-auto">Setup {{selected_carrier_integration.name}} Integration</h5>
							       <a class="btn btn-outline-light btn-rounded mb-auto ml-auto" @click="selected_carrier_integration = ''"><i class="mdi mdi-close"></i></a>
							       </div>
							      <b-form id="carrier-integration-setup" method="POST" enctype="multipart/form-data" @submit.prevent="setUpShippingAuth()">
										<b-form-group v-for="(fields, key) in selected_carrier_auth_rules" label-class="text-capitalize" :label="key.replaceAll('_', ' ').replace('id', 'ID')" class="mt-3" :required="fields.includes('required')">
											<div v-if="fields.includes('sensitive')">
											<!--<b-form-input v-if="!fields.includes('required')" :name="key" type="text" v-model="selected_carrier_auth_details[key]"></b-form-input>-->
										<b-form-input :name="key" type="password" :required="fields.includes('required')" v-model="selected_carrier_auth_details[key]"></b-form-input>
											<!--<b-form-input v-if="fields.includes('required') && fields.includes('max')" required :name="key" type="text" :state="selected_carrier_auth_details[key].length > (fields[fields.indexOf('max:')].replace('max:', ''))" v-model="selected_carrier_auth_details[key]"></b-form-input>-->
										</div>
											<div v-else-if="fields.includes('string') && !fields.includes('sensitive')">
											<!--<b-form-input v-if="!fields.includes('required')" :name="key" type="text" v-model="selected_carrier_auth_details[key]"></b-form-input>-->
										<b-form-input :name="key" :type="{'text' : fields.includes('string')}" :required="fields.includes('required')" v-model="selected_carrier_auth_details[key]"></b-form-input>
											<!--<b-form-input v-if="fields.includes('required') && fields.includes('max')" required :name="key" type="text" :state="selected_carrier_auth_details[key].length > (fields[fields.indexOf('max:')].replace('max:', ''))" v-model="selected_carrier_auth_details[key]"></b-form-input>-->
										</div>
										
										</b-form-group>
							    	<b-button type="submit" variant="primary" class="mt-3 btn-block btn-rounded">Set-up Integration</b-button>
							    	</b-form>
							    </b-card>
							  </b-overlay>
								<b-card class="border-radius-1em border shadow-none" >
							      <div class="d-flex">
							      	
							      		<a v-if="(start_carriers !== 0)" class="btn btn-light btn-rounded mr-auto my-auto"  @click="start_carriers -= 12, end_carriers -= 12"><i class="bx bx-chevron-left"></i></a>
							      	
							      		<div class="search-box mx-2 w-100 my-3">
							                <div class="position-relative">
							                  <input
							                    type="text"
							                    class="form-control w-100 border-0 bg-light"
							                    placeholder="Search name, reference or description..."
							                    v-model="carrier_search"
							                    @keypress.enter="start_carriers = 0, end_carriers = 12"
							                  />
							                  <i class="bx bx-search-alt search-icon"></i>
							                </div>
							              </div>
							      		<a v-if="(start_carriers + 12) < all_carrier_integrations.length" class="btn btn-light btn-rounded ml-auto my-auto" @click="start_carriers += 12, end_carriers += 12"><i class="bx bx-chevron-right"></i></a>
							      	
							      </div>
							       <div class="row">
							          <div class="col-md-3" v-for="(carrier, index) in filterShippingIntegrations.slice(start_carriers, end_carriers)">
							           <div class="card shadow-none border border-radius-1em mb-2" @click="getShippingAuthRules(carrier), selected_carrier_index = index" :class="{'active': selected_carrier_index == index}">
							            
							           <div class="card-body text-center d-flex flex-column">
							            <b-img-lazy :src="carrier.thumbnail" :style="[carrier.key == 'DeutschePost' ? {'width': '50px'} : {'width': '75px'}]" class="my-auto mx-auto" />
							            <p class="mx-auto my-auto pt-2">{{carrier.name}}</p>
							            </div>
							          </div>
							          </div>
							       </div>
							    </b-card>
							    
							</b-col>
						</b-row>
					</b-overlay>
						</b-card>
					</b-col>
				</b-row>
				<b-row v-if="view == 'Enquiries'">
					<b-col md="12">
						<b-card class="p-3">
						<h4>{{view}} Settings</h4>
						<hr>
						<b-overlay :show="loadingCompanySettings"  variant="white" opacity="0.95">
						<template #overlay>
							<b-row>
			      			<b-col md="12" class="offset-md-2" >
			      			<b-card class="border-radius-1em border shadow-none mb-0">	
					            <div class="cube-wrapper mx-auto"><div class="cube-folding"><span class="leaf1"></span><span class="leaf2"></span><span class="leaf3"></span><span class="leaf4"></span></div></div>
					            <h6 class="text-center">Loading Your Company Details</h6>
					             <b-progress class="w-50 mx-auto" v-model="company_settings_progress" variant="primary"></b-progress>
			         		</b-card>
			      			</b-col>
			      			</b-row>
			      		</template>
						<b-row class="my-4">
							<b-col md="4">
								<h5 class="d-flex">Enquiry Details <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
							</b-col>
							<b-col md="8">
								<b-form-group class="mb-4" label="Website Enquiry Email">
									<b-input-group>
										<b-input-group-prepend is-text>
											<i class="mdi mdi-email-outline"></i>
										</b-input-group-prepend>
										<b-form-input type="email" v-model="enquiry.website">
										</b-form-input>
									</b-input-group>
						        </b-form-group>
						        <b-form-group class="mb-4" label="Trade Enquiry Email">
					              <b-input-group>
					              	<b-input-group-prepend is-text>
											<i class="mdi mdi-email-outline"></i>
										</b-input-group-prepend>
									<b-form-input type="email" v-model="enquiry.trade">
									</b-form-input>
								</b-input-group>
					        </b-form-group>
				    		<b-form-group class="mb-4" label="Public Enquiry Email">
				              <b-input-group>
				              	<b-input-group-prepend is-text>
											<i class="mdi mdi-email-outline"></i>
										</b-input-group-prepend>
								<b-form-input type="email" v-model="enquiry.public">
								</b-form-input>
							</b-input-group>
				          </b-form-group>
						    		<!--<b-form-group class="mb-2" label="Default Form">
										<b-form-select class="form-control" v-model="forms.default_form_id">
											<b-form-select-option value="0">-- Select Form --</b-form-select-option>
										</b-form-select>
						        </b-form-group>-->
				        	</b-col>
				        </b-row>
				    </b-overlay>
						</b-card>
					</b-col>
				</b-row>
				<b-row v-if="view == 'Support'">
					<b-col md="12">
						<b-card class="p-3">
						<h4>{{view}} Settings</h4>
						<hr>
						<b-overlay :show="loadingCompanySettings"  variant="white" opacity="0.95">
						<template #overlay>
							<b-row>
			      			<b-col md="12" class="offset-md-2" >
			      			<b-card class="border-radius-1em border shadow-none mb-0">	
					            <div class="cube-wrapper mx-auto"><div class="cube-folding"><span class="leaf1"></span><span class="leaf2"></span><span class="leaf3"></span><span class="leaf4"></span></div></div>
					            <h6 class="text-center">Loading Your Company Enquiry Details...</h6>
					             <b-progress class="w-50 mx-auto" v-model="company_settings_progress" variant="primary"></b-progress>
			         		</b-card>
			      			</b-col>
			      			</b-row>
			      		</template>
						<b-row class="my-4">
							<b-col md="4">
								<h5 class="d-flex">Default Support Group <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
							</b-col>
							<b-col md="8">

								<b-form-group label="">
									<SearchCompanyUsers :api_token="$attrs.user.api_token" :users="support.default_support_group" :resource="'settings/enquiry/group'" :id="support.default_support_group_id" @user-added="supportUsersUpdated" @error="supportUserError" ></SearchCompanyUsers>
								</b-form-group>

								<b-card class="border-radius-1em border shadow-none">
									<b-overlay :show="loadingSupportUserList">	
									<div  v-if="supportUserList == true">
									<UserList ref="support-group-list" :api_token="$attrs.user.api_token" :users="support.default_support_group_users" :resource="'settings/enquiry/group'" :id="support.default_support_group_id" @user-removed="supportUsersUpdated" ></UserList>
									</div>
										</b-overlay>
								</b-card>
							</b-col>
						</b-row>
						<b-row>
							<b-col md="4">
								<h5 class="d-flex">Support Details <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
							</b-col>
							<b-col md="8">
								<b-form-group class="mb-4" label="Website Support Email">
									<b-input-group>
										<b-input-group-prepend is-text>
											<i class="mdi mdi-email-outline"></i>
										</b-input-group-prepend>
										<b-form-input type="email" v-model="support.website">
										</b-form-input>
									</b-input-group>
						        </b-form-group>
						        <b-form-group class="mb-4" label="Trade Support Email">
					              <b-input-group>
					              	<b-input-group-prepend is-text>
											<i class="mdi mdi-email-outline"></i>
										</b-input-group-prepend>
									<b-form-input type="email" v-model="support.trade">
									</b-form-input>
								</b-input-group>
					        </b-form-group>
				    		<b-form-group class="mb-4" label="Public Support Email">
				              <b-input-group>
				              	<b-input-group-prepend is-text>
											<i class="mdi mdi-email-outline"></i>
										</b-input-group-prepend>
								<b-form-input type="email" v-model="support.public">
								</b-form-input>
							</b-input-group>
				          </b-form-group>
						    		<!--<b-form-group class="mb-2" label="Default Form">
										<b-form-select class="form-control" v-model="forms.default_form_id">
											<b-form-select-option value="0">-- Select Form --</b-form-select-option>
										</b-form-select>
						        </b-form-group>-->
				        	</b-col>
				        </b-row>
				    </b-overlay>
						</b-card>
					</b-col>
				</b-row>
				<b-row v-if="view == 'Socials'">
					<b-col md="12">
						<b-card class="p-3">
						<h4>{{view}} Settings</h4>
						<hr>
						<b-overlay :show="loadingCompanySettings"  variant="white" opacity="0.95">
						<template #overlay>
							<b-row>
			      			<b-col md="12" class="offset-md-2" >
			      			<b-card class="border-radius-1em border shadow-none mb-0">	
					            <div class="cube-wrapper mx-auto"><div class="cube-folding"><span class="leaf1"></span><span class="leaf2"></span><span class="leaf3"></span><span class="leaf4"></span></div></div>
					            <h6 class="text-center">Loading Your Company Enquiry Details...</h6>
					             <b-progress class="w-50 mx-auto" v-model="company_settings_progress" variant="primary"></b-progress>
			         		</b-card>
			      			</b-col>
			      			</b-row>
			      		</template>
						<b-row class="my-4">
							<b-col md="4">
								<h5 class="d-flex">Social Profiles <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
							</b-col>
							<b-col md="8">
								<b-row>
									<b-col md="12">
										<h5 class="d-flex">Add Social Profile <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
									</b-col>
								</b-row>
								<b-row>
									<b-col md="9">
										<SearchResource :options="available_social_profiles" @resource-selected="selected_social_profile = $event" ></SearchResource>
									</b-col>
									<b-col md="3" class="d-flex">
										<a class="btn btn-primary btn-rounded my-auto" v-b-modal.socialprofile @click="action = 'create'">
											Add Social Profile
										</a>
									</b-col>
								</b-row>
								<b-row class="mt-4">
									<b-col md="12">
										<h5 class="d-flex">Active Profiles <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
									</b-col>
								</b-row>
								<b-card class="border-radius-1em border shadow-none mb-0">
								<b-row v-if="social_profiles.length > 0">
									<b-col md="3" v-for="profile in social_profiles">
										<b-card class="shadow-none border border-radius-1em mb-0" v-b-modal.socialprofile @click="selected_social_profile = profile, selected_social_action = 'update'" body-class="d-flex flex-column">
											<b-img-lazy :src="getSocialImage(profile.name)" class="avatar-lg mx-auto" />
											<b-card-text class="text-center">
											<h5 class="mt-2 mb-0">{{profile.name}}</h5>
											<a class="mx-auto badge badge-light bg-light mb-0" :href="profile.url" target="_blank">{{profile.username}} <i class="bx bx-link-external"></i></a>
											</b-card-text>
										</b-card>
									</b-col>
								</b-row>
								<b-row v-else>
									<b-col md="12" class="d-flex flex-column justify-content-center align-items-center">
										<i class="bx bx-sad mx-auto font-size-48 text-muted mb-2"></i>
										<h4 class="text-muted">No Social Profiles</h4>
									</b-col>
								</b-row>
								</b-card>
				        	</b-col>
				        </b-row>
				    </b-overlay>
						</b-card>
					</b-col>
				</b-row>
				<b-row v-if="view == 'Website'">
					<b-col md="12">
						<b-card class="p-3">
						<h4>{{view}} Settings</h4>
						<hr>
						<b-row>
							<b-col md="2">
								<h5 class="d-flex">Options <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
							</b-col>
						 <b-col md="10">
						 	<b-row>
						 		<b-col md="6">
						<b-button
                      class=" flex-grow-1 my-0 py-4 w-100"
                      
                      :class="{'active' : website.gemesys == (true || 1 || '1')}"
                      variant="outline-light"
                      @click="website.external = false, website.gemesys = true, setUpWebsite()"
                    >
                    <div v-if="website.gemesys !== (true || 1)" class="cube-wrapper text-center w-100 m-0 my-2 px-0"><div class="cube-folding mx-auto"><span class="leaf1"></span><span class="leaf2"></span><span class="leaf3"></span><span class="leaf4"></span></div></div>
                    <i v-else class="bx bx-check-circle font-size-24 d-block py-4 text-success my-2"></i> Use Gemesys Website
              </b-button>
            </b-col>
            <b-col md="6">
              <b-button
                      class=" flex-grow-1 my-0 py-4 w-100"
                     @click="website.gemesys = false, website.external = true"
                      :class="{'active' : website.external == (true || 1 || '1')}"
                      variant="outline-light"
                     
                    >
                   
                    <i v-if="website.external !== (true || 1)" class="bx bx-globe font-size-24 d-block py-4 text-primary my-2"></i> 
                    <i v-else class="bx bx-check-circle font-size-24 d-block py-4 text-success my-2"></i> Connect to External Website
              </b-button>
            </b-col>
          </b-row>
      </b-col>
      <b-col md="12" class="mt-4">
      		<b-row v-if="loadingWebsiteSettings == true">
      			<b-col md="10" class="offset-md-2">
      			<b-card class="border-radius-1em border shadow-none mb-0">	
		            <div class="cube-wrapper mx-auto"><div class="cube-folding"><span class="leaf1"></span><span class="leaf2"></span><span class="leaf3"></span><span class="leaf4"></span></div></div>
		            <h6 class="text-center" v-if="website_setup_text == true">Setting Up Your Website</h6>
		            <h6 class="text-center" v-else>Loading Your Website Settings</h6>
		             <b-progress class="w-50 mx-auto" v-model="website_setup_progress" variant="primary"></b-progress>
         		</b-card>
      			</b-col>
      		</b-row>
      		
              <b-row v-if="(website.gemesys == true) && (loadingWebsiteSettings == false)">
              	<b-col md="2">
              		
              	</b-col>
              	<b-col md="10">
              		<b-card class="border-radius-1em border shadow-none" >
              		<h4 class="mb-3">Setting Up Your Gemesys Website</h4>
              		<p>
              			Your gemesys website is set-up by default for you, so all you need to do is add your domain via Web > Domains and point it to the custom URL provided on submission.
              		</p>
              		<p>
              			Once your domain is activated and pointing your website you will be able to access your website. All the basic design and functionality is set-up in our default template. You can adjust the style options below this message to update the styles on the default template, or you can create your own using our drag and drop builder.
              		</p>
              		<p>
              			To create your own templates and styles, simply create your own templates via Web > Templates or styles via Web > Styles. You can then overwrite the default website pages with your own content via Web > Pages and create your own navigation menus at Web > Menus. 
              		</p>
              		
              		</b-card>
              	</b-col>
              	<!--<b-col md="2" class="mt-4">
              		<h5 class="d-flex">Banner <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
              	</b-col>
              	<b-col md="10" class="text-center d-flex"  @mouseenter="imageOptions = true" @mouseleave="imageOptions = ''">
								<b-card class="border-radius-1em border w-50 mr-auto" v-if="bannerUpload == false">
								<b-img-lazy :src="website.homepage_banner" v-if="website.homepage_banner" class="mx-auto img-fluid border">
								<b-img-lazy :src="'/images/banner-placeholder.png'" v-else class="mx-auto img-fluid border">
								<div class="left-0 right-0 top-0 h-100 w-100 opacity-75" :class="{'h-100 d-flex flex-column position-absolute bg-white bg-soft w-75 mx-auto': imageOptions == true, 'd-none': imageOptions == false}">
                              <a class="btn btn-primary mt-auto w-75 mx-auto mb-1" @click="$emit('file-selected', object), $emit('close-window')">Edit</a>
                              <a class="btn btn-primary mb-auto w-75 mx-auto mt-1" @click="bannerUpload = !bannerUpload" v-b-modal.uploadModal>Upload New</a>
                            </div>
                        		</b-card>
                        		<b-card v-else class="border-radius-1em border w-50 mr-auto">
                        		 <vue-dropzone id="dropzone" ref="myVueDropzone" :use-custom-slot="true" :options="dropzoneOptions" @vdropzone-file-added="imageAdded"  @vdropzone-drop="imageAdded">
			                        <div class="dropzone-custom-content d-flex flex-column justify-content-center align-items-center" style="height: 500px;">
			                            <i class="display-4 text-muted bx bxs-cloud-upload"></i>
			                            <h4>Drop files here or click to upload.</h4>
			                        </div>
                        		</vue-dropzone>
                        		<div class="d-flex">
                        		<a class="btn btn-primary ml-auto mt-auto btn-rounded mt-1 mr-1" @click="uploadAvatar()" v-b-modal.uploadModal>Upload</a>
                        		<a class="btn btn-danger ml-2 mt-auto btn-rounded mt-1 ml-1" @click="bannerUpload = !bannerUpload" v-b-modal.uploadModal>Cancel</a>
                        		</div>
                    		</b-card>
                    		<div class="d-flex">
                    		<a class="btn btn-primary mt-auto w-75 mx-auto mb-1" @click="$emit('file-selected', object), $emit('close-window')">Edit</a>
                              <a class="btn btn-primary mb-auto w-75 mx-auto mt-1" @click="bannerUpload = !bannerUpload" v-b-modal.uploadModal>Upload New</a>
                          </div>
							</b-col>
				-->
				<b-col md="2" class="mt-4">
					<h5 class="d-flex">Website Settings <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
				</b-col>
				<b-col md="10" class="mt-4">
					<b-card class="border-radius-1em border shadow-none">
					<h5 class="border-bottom pb-2 mb-3">Domain</h5>
					
					<div class="d-flex w-100" v-if="website.default_domain == null">
						<SearchResource v-if="'domains' in website_resource && website_resource['domains'].length !== 0" class="w-50 mr-auto" :options="website_resource['domains']" :resource="'domains'" @resource-selected="website_default.domains = $event" ></SearchResource>
						<a v-else class="w-50 text-left mr-auto btn btn-light btn-rounded d-flex px-4 py-3" @click="loadWebsiteResource('domains')">
							<i class="bx bx-no-entry font-size-22 text-primary mr-2 my-auto"></i>
						<h6 class="my-auto mr-auto">No domain Selected</h6>
						<i class="mdi mdi-plus text-primary font-size-22 ml-auto">
						</i>
						</a>
						<a v-if="(website_default.domains !== '') && ('domains' in website_resource && website_resource['domains'].length !== 0)" class="btn btn-primary btn-rounded mr-2 my-auto" @click="updateDefault('domain', website_default.domains)">
							Use Selected Domain
						</a>	
						<b-button variant="light" class="btn my-auto btn-light btn-rounded">
							Add New Domain
						</b-button>
						<b-button variant="primary" class="btn my-auto btn-primary btn-rounded">
							Buy New Domain
						</b-button>
					</div>
					<div class="d-flex w-100" v-else>
						<a class="btn btn-light btn-rounded w-50 mr-auto" target="_blank" v-if="website.default_domain.ssl_active" :href="'https://'+website.default_domain.domain"><b-card  class="border-radius-1em shadow-none bg-light my-auto" body-class="d-flex p-2">
							<i class="bx bx-globe text-primary mr-2 font-size-20 my-auto"></i> <h6 class="my-auto">{{website.default_domain.domain}}</h6>

							<i class="bx bx-link-external text-primary ml-auto font-size-20 my-auto"></i> 
						</b-card></a>
						<a class="btn btn-light btn-rounded w-50 mr-auto" target="_blank" v-else :href="'http://'+website.default_domain.domain"><b-card  class="border-radius-1em shadow-none bg-light my-auto" body-class="d-flex p-2">
							<i class="bx bx-globe text-primary mr-2 font-size-20 my-auto"></i> <h6 class="my-auto">{{website.default_domain.domain}}</h6>

							<i class="bx bx-link-external text-primary ml-auto font-size-20 my-auto"></i> 
						</b-card></a>
						<a class="btn btn-primary my-auto btn-rounded" @click="website.default_domain = null">
							Update Default Domain
						</a>	
					</div>
					</b-card>

					<b-card class="border-radius-1em border shadow-none">
						<b-overlay :show="loadingTheme" variant="white" opacity="0.9">
         <template #overlay>
          <b-row>
                  <b-col md="12" class="offset-md-2" >
                  <b-card class="border-radius-1em border shadow-none mb-0">  
                      <div class="cube-wrapper mx-auto"><div class="cube-folding"><span class="leaf1"></span><span class="leaf2"></span><span class="leaf3"></span><span class="leaf4"></span></div></div>
                      <h6 class="text-center">Compiling Theme...</h6>
                       <b-progress class="w-50 mx-auto" v-model="loadingThemeProgress" variant="primary"></b-progress>
                  </b-card>
                  </b-col>
                  </b-row>
        </template>
				<h5 class="border-bottom pb-2 mb-3">Theme</h5>
					<div class="d-flex w-100" v-if="website.default_theme == null">
						<SearchResource v-if="'themes' in website_resource && website_resource['themes'].length !== 0" class="w-50 mr-auto" :options="website_resource['themes']" :resource="'domains'" @resource-selected="website_default.themes = $event" ></SearchResource>
						<a v-else class="w-50 text-left mr-auto btn btn-light btn-rounded d-flex px-4 py-3" @click="loadWebsiteResource('themes')">
							<i class="bx bx-no-entry font-size-22 text-primary mr-2 my-auto"></i>
						<h6 class="my-auto mr-auto">No Theme Selected</h6>
						<i class="mdi mdi-plus text-primary font-size-22 ml-auto">
						</i>
						</a>
						<a v-if="(website_default.themes !== '') && ('themes' in website_resource && website_resource['themes'].length !== 0)" class="btn btn-primary btn-rounded mr-2 my-auto" @click="updateDefault('theme', website_default.themes)">
							Use Selected Theme
						</a>	
						<b-button variant="light" class="btn my-auto btn-light btn-rounded" @click="createTheme()">
							Create New Theme
						</b-button>
					</div>
					<div class="d-flex w-100 mb-3" v-else>
						<div class="card border-radius-1em w-50 mb-0 mr-auto">
            
				          <div class="bg-soft-primary bg-light border-radius-top-right-1em border-radius-top-left-1em" style="min-height: 150px;">
				            <div class="row">
				              
				              <div class="col-12 align-self-end">
				                <b-img-lazy v-if="website.default_theme.thumbnail" :src="`${website.default_theme.thumbnail}`" alt class="img-fluid border-radius-top-right-1em border-radius-top-left-1em" />
				                
				              </div>
				            </div>
				          </div>
				          <div class="card-body pt-0">

			            <div class="row">
			              <div class="col-sm-12 text-center px-4 mb-2">
			               <!-- <div class="avatar-xl profile-user-wid mb-4">
			                  <b-img-lazy class="img-thumbnail rounded-circle h-100" :src="`/public/storage/${website.default_theme.image}`" alt />
			                </div>-->
			                <div class="avatar-sm profile-user-wid mx-auto">
			                   <div class="text-center" v-if="website.default_theme.image">
			                      <b-form-checkbox class="select-row-btn" v-model="selectedids" :value="website.default_theme.id" button-variant="primary"  button>
			                        
			                        <b-img-lazy :src="`${website.default_theme.image}`" alt class="avatar-sm img-thumbnail rounded-circle h-100" />
			                      </b-form-checkbox>
			                    </div>
			                    <div class="text-center" v-else >
			                        <b-form-checkbox class="select-row-btn" v-model="selectedids" :value="website.default_theme.id" button-variant="primary"  button>

			                          <span class="avatar-title rounded-circle">{{(website.default_theme.name).slice(0,1)}}</span></b-form-checkbox>
			                    </div> 
			                </div>
			                <h4 class="font-size-18 text-truncate mt-3">{{website.default_theme.name}}</h4>
			                <p class="text-muted mb-0 text-truncate" v-html="website.default_theme.description"></p>
			              </div>

			              <div class="col-sm-12">
			              
			              </div>
			            </div>
			          </div>
			          <div class="card-footer  bg-transparent border-top">
			                  <div class="d-flex">
			                    
			                    <a id="tooltip-add" @click="loadTheme()" class="btn btn-outline-light font-size-16 btn-rounded my-auto"><i class="bx bx-pencil"></i></a>
			                    <a href="javascript:;" @click="selectedCategory = website.default_theme, getProducts(website.default_theme.id), getProductsPanel = 1" class="btn btn-outline-light btn-rounded ml-2"><i class="mdi mdi-eye-outline my-1"></i></a>
			                   
			                    </div>
			                  </div>
			          </div>

			          <a class="btn btn-light mt-auto btn-rounded" @click="website.default_theme = null">
							Update Theme
						</a>
						<!--<a class="btn btn-light mt-auto btn-rounded" @click="website.default_theme = null">
							Create New
						</a>
  						<a class="btn btn-primary mt-auto btn-rounded" @click="website.default_theme = null">
							Make Default Theme <i class="bx bx-star text-warning"></i>
						</a>-->
			        </div>

			       
					</div>
					</b-overlay>
				 	<ThemeBuilder ref="themeBuilder" :hideDesign="false" :theme="website.default_theme" :user="$attrs.user" :company="$attrs.company" />
					</b-card>

					<b-card class="border-radius-1em border shadow-none">
					<h5 class="border-bottom pb-2 mb-3">Page</h5>
					<div class="d-flex w-100" v-if="website.default_page == null">
						<SearchResource v-if="'pages' in website_resource && website_resource['pages'].length !== 0" class="w-50 mr-auto" :options="website_resource['pages']" :resource="'domains'" @resource-selected="website_default.pages = $event" ></SearchResource>
						<a v-else class="w-50 text-left mr-auto btn btn-light btn-rounded d-flex px-4 py-3" @click="loadWebsiteResource('pages')">
							<i class="bx bx-no-entry font-size-22 text-primary mr-2 my-auto"></i>
						<h6 class="my-auto mr-auto">No page Selected</h6>
						<i class="mdi mdi-plus text-primary font-size-22 ml-auto">
						</i>
						</a>
						<a v-if="(website_default.pages !== '') && ('pages' in website_resource && website_resource['pages'].length !== 0)" class="btn btn-primary btn-rounded mr-2 my-auto" @click="updateDefault('page', website_default.pages)">
							Use Selected Page
						</a>	
						<b-button variant="light" class="btn my-auto btn-light btn-rounded" href="/web/page?view=add" target="_blank">
							Create New Page
						</b-button>
					</div>
					<div class="d-flex w-100 mb-3" v-else>
						<div class="card border-radius-1em w-50 mb-0 mr-auto">
            
				          <div class="bg-soft-primary bg-light border-radius-top-right-1em border-radius-top-left-1em" style="min-height: 150px;">
				            <div class="row">
				              
				              <div class="col-12 align-self-end">
				                <b-img-lazy v-if="website.default_page.thumbnail" :src="`${website.default_page.thumbnail}`" alt class="img-fluid border-radius-top-right-1em border-radius-top-left-1em" />
				                
				              </div>
				            </div>
				          </div>
				          <div class="card-body pt-0">

			            <div class="row">
			              <div class="col-sm-12 text-center px-4 mb-2">
			               <!-- <div class="avatar-xl profile-user-wid mb-4">
			                  <b-img-lazy class="img-thumbnail rounded-circle h-100" :src="`/public/storage/${website.default_page.image}`" alt />
			                </div>-->
			                <div class="avatar-sm profile-user-wid mx-auto">
			                   <div class="text-center" v-if="website.default_page.image">
			                      <b-form-checkbox class="select-row-btn" v-model="selectedids" :value="website.default_page.id" button-variant="primary" @input="updateSelected" button>
			                        
			                        <b-img-lazy :src="`${website.default_page.image}`" alt class="avatar-sm img-thumbnail rounded-circle h-100" />
			                      </b-form-checkbox>
			                    </div>
			                    <div class="text-center avatar-sm" v-else >
			                      


			                          <span class="avatar-title rounded-circle">{{(website.default_page.name).slice(0,1)}}</span>
			                    </div> 
			                </div>
			                <h4 class="font-size-18 text-truncate mt-3">{{website.default_page.name}}</h4>
			                <p class="text-muted mb-0 text-truncate" v-html="website.default_page.description"></p>
			              </div>

			              <div class="col-sm-12">
			              
			              </div>
			            </div>
			          </div>
			          <div class="card-footer  bg-transparent border-top">
			                  <div class="d-flex">
			                    
			                    <a id="tooltip-add" @click="loadpage()" class="btn btn-outline-light font-size-16 btn-rounded my-auto"><i class="bx bx-pencil"></i></a>
			                    <a href="javascript:;" @click="selectedCategory = website.default_page, getProducts(website.default_page.id), getProductsPanel = 1" class="btn btn-outline-light btn-rounded ml-2"><i class="mdi mdi-eye-outline my-1"></i></a>
			                   
			                    </div>
			                  </div>
			          </div>

			          <a class="btn btn-light mt-auto btn-rounded" @click="website.default_page = null">
							Update Page
						</a>
						<!--<a class="btn btn-light mt-auto btn-rounded" @click="website.default_theme = null">
							Create New
						</a>
  						<a class="btn btn-primary mt-auto btn-rounded" @click="website.default_theme = null">
							Make Default Theme <i class="bx bx-star text-warning"></i>
						</a>-->
			        </div>
			        
					</div>
					</b-card>

					<b-card class="border-radius-1em border shadow-none">
					<h5 class="border-bottom pb-2 mb-3">Template</h5>
					<div class="d-flex w-100" v-if="website.default_template == null">
						<SearchResource v-if="'templates' in website_resource && website_resource['templates'].length !== 0" class="w-50 mr-auto" :options="website_resource['templates']" :resource="'domains'" @resource-selected="website_default.templates = $event" ></SearchResource>
						<a v-else class="w-50 text-left mr-auto btn btn-light btn-rounded d-flex px-4 py-3" @click="loadWebsiteResource('templates')">
							<i class="bx bx-no-entry font-size-22 text-primary mr-2 my-auto"></i>
						<h6 class="my-auto mr-auto">No template Selected</h6>
						<i class="mdi mdi-plus text-primary font-size-22 ml-auto">
						</i>
						</a>
						<a v-if="(website_default.templates !== '') && ('templates' in website_resource && website_resource['templates'].length !== 0)" class="btn btn-primary btn-rounded mr-2 my-auto" @click="updateDefault('template', website_default.templates)">
							Use Selected template
						</a>	
						<b-button variant="light" class="btn my-auto btn-light btn-rounded" href="/web/templates?view=add" target="_blank">
							Create New template
						</b-button>
					</div>
					<div class="d-flex w-100 mb-3" v-else>
						<div class="card border-radius-1em w-50 mb-0 mr-auto">
            
				          <div class="bg-soft-primary bg-light border-radius-top-right-1em border-radius-top-left-1em" style="min-height: 150px;">
				            <div class="row">
				              
				              <div class="col-12 align-self-end">
				                <b-img-lazy v-if="website.default_template.thumbnail" :src="`${website.default_template.thumbnail}`" alt class="img-fluid border-radius-top-right-1em border-radius-top-left-1em" />
				                
				              </div>
				            </div>
				          </div>
				          <div class="card-body pt-0">

			            <div class="row">
			              <div class="col-sm-12 text-center px-4 mb-2">
			               <!-- <div class="avatar-xl profile-user-wid mb-4">
			                  <b-img-lazy class="img-thumbnail rounded-circle h-100" :src="`/public/storage/${website.default_template.image}`" alt />
			                </div>-->
			                <div class="avatar-sm profile-user-wid mx-auto">
			                   <div class="text-center" v-if="website.default_template.image">
			                      <b-form-checkbox class="select-row-btn" v-model="selectedids" :value="website.default_template.id" button-variant="primary" @input="updateSelected" button>
			                        
			                        <b-img-lazy :src="`${website.default_template.image}`" alt class="avatar-sm img-thumbnail rounded-circle h-100" />
			                      </b-form-checkbox>
			                    </div>
			                    <div class="text-center" v-else >
			                        <b-form-checkbox class="select-row-btn" v-model="selectedids" :value="website.default_template.id" button-variant="primary" @input="updateSelected" button>

			                          <span class="avatar-title rounded-circle">{{(website.default_template.name).slice(0,1)}}</span></b-form-checkbox>
			                    </div> 
			                </div>
			                <h4 class="font-size-18 text-truncate mt-3">{{website.default_template.name}}</h4>
			                <p class="text-muted mb-0 text-truncate" v-html="website.default_template.description"></p>
			              </div>

			              <div class="col-sm-12">
			              
			              </div>
			            </div>
			          </div>
			          <div class="card-footer  bg-transparent border-top">
			                  <div class="d-flex">
			                    
			                    <a id="tooltip-add" @click="loadtemplate()" class="btn btn-outline-light font-size-16 btn-rounded my-auto"><i class="bx bx-pencil"></i></a>
			                    <a href="javascript:;" @click="selectedCategory = website.default_template, getProducts(website.default_template.id), getProductsPanel = 1" class="btn btn-outline-light btn-rounded ml-2"><i class="mdi mdi-eye-outline my-1"></i></a>
			                   
			                    </div>
			                  </div>
			          </div>

			          <a class="btn btn-light mt-auto btn-rounded" @click="website.default_template = null">
							Update Template
						</a>
						<!--<a class="btn btn-light mt-auto btn-rounded" @click="website.default_theme = null">
							Create New
						</a>
  						<a class="btn btn-primary mt-auto btn-rounded" @click="website.default_theme = null">
							Make Default Theme <i class="bx bx-star text-warning"></i>
						</a>-->
			        </div>
			       
					</div>
					</b-card>

					<b-card class="border-radius-1em border shadow-none">
				<h5 class="border-bottom pb-2 mb-3">Menu</h5>
					<div class="d-flex w-100" v-if="website.default_menu == null">
						<SearchResource v-if="'menus' in website_resource && website_resource['menus'].length !== 0" class="w-50 mr-auto" :options="website_resource['menus']" :resource="'menus'" @resource-selected="website_default.menus = $event" ></SearchResource>
						<a v-else class="w-50 text-left mr-auto btn btn-light btn-rounded d-flex px-4 py-3" @click="loadWebsiteResource('menus')">
							<i class="bx bx-no-entry font-size-22 text-primary mr-2 my-auto"></i>
						<h6 class="my-auto mr-auto">No menu Selected</h6>
						<i class="mdi mdi-plus text-primary font-size-22 ml-auto">
						</i>
						</a>
						<a v-if="(website_default.menus !== '') && ('menus' in website_resource && website_resource['menus'].length !== 0)" class="btn btn-primary btn-rounded mr-2 my-auto" @click="updateDefault('menu', website_default.menus)">
							Use Selected menu
						</a>	
						<b-button variant="light" class="btn my-auto btn-light btn-rounded" href="/web/menus?view=add" target="_blank">
							Add New menu
						</b-button>
						<b-button variant="primary" class="btn my-auto btn-primary btn-rounded">
							Buy New menu
						</b-button>
					</div>
					<div class="d-flex w-100" v-else>
						<a class="btn btn-light btn-rounded" v-if="website.default_menu.ssl_active" :href="'/web/menu/'+website.default_menu.uid"><b-card  class="border-radius-1em shadow-none bg-light my-auto" body-class="d-flex p-2">
							<i class="bx bx-globe text-primary mr-2 font-size-20 my-auto"></i> <h6 class="my-auto">{{website.default_menu.name}}</h6>

							<i class="bx bx-link-external text-primary ml-auto font-size-20 my-auto"></i> 
						</b-card></a>
						<a class="btn btn-light btn-rounded" v-else :href="'/web/menu/'+website.default_menu.uid"><b-card  class="border-radius-1em shadow-none bg-light my-auto" body-class="d-flex p-2">
							<i class="bx bx-globe text-primary mr-2 font-size-20 my-auto"></i> <h6 class="my-auto">{{website.default_menu.name}}</h6>

							<i class="bx bx-link-external text-primary ml-auto font-size-20 my-auto"></i> 
						</b-card></a>
						<a class="btn btn-primary my-auto btn-rounded ml-auto" @click="website.default_menu = null">
							Update Default Menu
						</a>	
					</div>
				</b-card>
				</b-col>
              	<!--<b-col md="2" class="my-4">
              		<h5 class="d-flex">Colours <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
              	</b-col>
              	<b-col md="10" class="my-4">
              		<b-row>
              			<b-col md="6">
		              		<b-form-group class="w-100 float-left" label="Primary Colour">
		              			<b-form-input type="color" v-model="website.colours.primary_colour"></b-form-input>
		              		</b-form-group>
		              	</b-col>
              			<b-col md="6">
		              		<b-form-group class="w-100 float-left" label="Secondary Colour">
		              			<b-form-input type="color" v-model="website.colours.secondary_colour" ></b-form-input>
		              		</b-form-group>
		              	</b-col>
              		</b-row>
              	</b-col>
              	<b-col md="2" class="mt-4">
              		<h5 class="d-flex">Heading Font <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
              	</b-col>
              	<b-col md="10" class="mt-4">
              		<b-tabs pills content-class="mt-3" class="mb-3">
              			<b-tab v-for="type in heading_types" :title="type.toUpperCase()" @click="loadFontWindow()" >
              				<b-overlay :show="loadingFonts">
              			<b-card class="border-radius-1em border shadow-none mb-0" >
              			
              				<b-form-group class="mb-2" label="Font">
              			
		              			<FontsMultiselect :id="website.fonts.heading_font[type]" @font-selected="selectFont('heading', type, $event), refreshHead()"></FontsMultiselect>
		              		</b-form-group>

		              		<b-form-group label="Font Size">
		              			<b-card class="border-radius-1em border shadow-none mb-0" >           		
		              			 <vue-slide-bar v-if="loadingFonts == false" v-model="website.fonts.heading_font_size[type]" style="padding: 15px 0 !important" :label="website.fonts.heading_font_size[type]+'px'" :min="10" :max="120" @dragEnd="refreshHead()">
		              			 	 <template slot="tooltip" slot-scope="tooltip">
							            <span class="vue-slide-bar-tooltip">{{website.fonts.heading_font_size[type]}}px</span>
							          </template>

		              			 </vue-slide-bar>
		              			</b-card>
		              		</b-form-group>
		              
		              	</b-card>
		              		</b-overlay>
              			</b-tab>
              		</b-tabs>
              		<b-card class="border-radius-1em border mt-4 shadow-none mb-3">
              			

              			
              			<b-card-body id="sample" class="p-1">
	              			<h1 :style="{'font-family' : website.fonts.heading_font}">This is Heading 1 <span class="font-size-12 ml-3" v-if="website.fonts.heading_font.h1 !== null">{{website.fonts.heading_font.h1.family}}</span><span class="font-size-12 ml-3" v-else>Poppins</span></h1>
	              			<h2 :style="{'font-family' : website.fonts.heading_font}">This is Heading 2<span class="font-size-12 ml-3" v-if="website.fonts.heading_font.h2 !== null">{{website.fonts.heading_font.h2.family}}</span><span class="font-size-12 ml-3" v-else>Poppins</span></h2>
	              			<h3 :style="{'font-family' : website.fonts.heading_font}">This is Heading 3<span class="font-size-12 ml-3" v-if="website.fonts.heading_font.h3 !== null">{{website.fonts.heading_font.h3.family}}</span><span class="font-size-12 ml-3" v-else>Poppins</span></h3>
	              			<h4 :style="{'font-family' : website.fonts.heading_font}">This is Heading 3<span class="font-size-12 ml-3" v-if="website.fonts.heading_font.h4 !== null">{{website.fonts.heading_font.h4.family}}</span><span class="font-size-12 ml-3" v-else>Poppins</span></h4>
	              			<h5 :style="{'font-family' : website.fonts.heading_font}">This is Heading 3<span class="font-size-12 ml-3" v-if="website.fonts.heading_font.h5 !== null">{{website.fonts.heading_font.h5.family}}</span><span class="font-size-12 ml-3" v-else>Poppins</span></h5>
              			</b-card-body>
              		</b-card>
              	</b-col>
              	<b-col md="2" class="mt-4">
              		<h5 class="d-flex">Body Font <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
              	</b-col>
              	<b-col md="10" class="mt-4">
              		<b-card class="border-radius-1em border shadow-none mb-2" >

              		<b-form-group class="mb-2" label="Body Font">
              			
              			<FontsMultiselect :id="website.fonts.body_font" @font-selected="selectFont('body', 'p', $event), refreshHead()"></FontsMultiselect>
              		</b-form-group>
              		<b-form-group label="Body Font Size">
              			<b-card class="border-radius-1em border shadow-none mb-0">           		
              			 <vue-slide-bar v-model="website.fonts.body_font_size" style="padding: 15px 0 !important" :label="website.fonts.body_font_size+'px'" :min="10" :max="30" @dragEnd="refreshHead()">
              			 	 <template slot="tooltip" slot-scope="tooltip">
					            <span class="vue-slide-bar-tooltip">{{website.fonts.body_font_size}}px</span>
					          </template>

              			 </vue-slide-bar>
              			</b-card>
              		</b-form-group>
              		</b-card>
              		<b-card class="border-radius-1em border mt-4 shadow-none">
              		

              			
              			<b-card-body id="sample-body" class="p-1">
              		
              			<p :style="{'font-family' : website.fonts.body_font}">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
              			<a class="btn btn-primary custom-color" :style="{'background-color' : website.colours.primary_colour, 'border-color' : website.colours.primary_colour}">
              				Sample Button
              			</a>
              		</b-card-body>
              		</b-card>
              	</b-col>-->
              </b-row>
              <b-row v-if="(website.external == true) && (loadingWebsiteSettings == false)">
							<b-col md="2">
								<h5 class="d-flex">Active Integration <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
							</b-col>
							<b-col md="10">
								<b-row v-if="loadingChannelsSetup == true">
				      			<b-col md="12" >
				      			<b-card class="border-radius-1em border shadow-none mb-3">	
						            <div class="cube-wrapper mx-auto"><div class="cube-folding"><span class="leaf1"></span><span class="leaf2"></span><span class="leaf3"></span><span class="leaf4"></span></div></div>
						            <h6 class="text-center">{{channels_setup_text}}</h6>
						             <b-progress class="w-50 mx-auto" v-model="channels_setup_progress" variant="primary"></b-progress>
				         		</b-card>
				      			</b-col>
				      		</b-row>
								<b-card class="border-radius-1em border shadow-none "  body-class="d-flex justify-content-center align-items-center flex-column p-4" >
									<div class="m-3 p-3 text-center" v-if="active_ecommerce_integrations.length == 0">
										<i class="bx bx-shopping-bag font-size-24 text-primary"></i>
										<h5>No Ecommerce Integrations</h5>
										<a class="btn btn-primary btn-rounded mt-3" v-if="ecommerce_channels_settings == ''" @click="setUpChannelsApiStore()">Create Ecommerce Integrations</a>
									</div>
									<div v-else class="w-100">
										<b-overlay 
								:show="loadingEcommerceIntegration"
								>
										<b-list-group flush>
											<b-list-group-item v-for="(integration, index) in active_ecommerce_integrations" class="d-flex">
										<div class="d-flex flex-column w-100">
											<div class="d-flex w-100">
												<b-img-lazy :src="getChannelsImage(integration.integration_name)" class="my-auto mr-3" width="50px" />
												
												
												<div class="mr-auto d-flex flex-column">
													<h5 class="mt-auto mb-0 mr-2">
												{{integration.integration_name}}
												</h5>
												<h6 class="mb-auto">
												{{integration.type}}
												</h6>
													
												</div>
												<div class="ml-auto d-flex my-auto">
													Integration URL: 
													<a class="badge badge-light bg-light font-size-12" :href="integration.url" target="_blank">
														<i class="bx bx-link-external"></i>
														{{integration.url}}
													</a>
												</div>
											</div>
												<!--<div class="d-flex mr-auto flex-column">
														<span class="badge badge-light bg-light mx-auto mt-auto mb-1">
															Total Products: {{integration.channel_products.total}}
														</span>
														<span class="badge badge-light bg-light  mx-auto mb-auto mt-1">
															Matched Products: {{integration.channel_products.empty_matched_sku}}
														</span>
													</div>-->
											<div class="d-flex ml-auto">
												<a class="my-auto btn-rounded btn-info btn mr-1" v-if="integration.username == ''" @click="ecommerce_website_url = integration.url, completeOauthSetup(integration.integration_name, integration.uid)">
													Complete Setup
												</a>
												<a class="my-auto btn-rounded btn btn-light mr-1" v-b-modal.integration-details @click="selected_channel_products = '', selected_channel_categories = '', selected_channel_customers = '', selected_channel_orders = '', selected_integration_uid = integration.uid, getActiveChannel(integration), selected_integration = integration, filterWebhooks(integration.integration_name), webhooks_show = true">See Details</a>
												<a class="my-auto btn-rounded btn btn-light mr-1" v-b-modal.commerce-products @click="selected_channel_products = '', selected_channel_categories = '', selected_channel_customers = '', selected_channel_orders = '', selected_integration_uid = integration.uid, getProducts(), getActiveChannel(integration), selected_integration = integration">Import and Sync</a>
												<!--<a class="my-auto btn-rounded btn btn-info mr-1" v-b-modal.auth-details @click="selected_integration = integration">Edit Configuration</a>
												<b-dropdown variant="primary" class="btn-rounded mr-1">
													  <template #button-content class="btn-rounded">
													     Sync
													    </template>
													<b-dropdown-item>
														Product Quantities
													</b-dropdown-item>
													<b-dropdown-item>
														Orders
													</b-dropdown-item>
												</b-dropdown>-->
												<a class="my-auto btn-rounded btn btn-danger" @click="selected_integration_uid = integration.uid,selected_integration = integration, destroyChannel(integration, index)">Remove</a>
											</div>
										</div>
											</b-list-group-item>
										</b-list-group>
										</b-overlay>
									</div>
								</b-card>
							
							</b-col>
							<b-col md="2" v-if="ecommerce_channels_settings !== ''">
								<h5 class="d-flex">New Integration <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
							</b-col>
							<b-col md="10" v-if="ecommerce_channels_settings !== ''">
								<b-overlay 
								:show="loadingEcommerceIntegration"
								>
								<!--<b-card class="border-radius-1em border shadow-none" v-if="selected_ecommerce_integration !== ''">
									
									<div class="d-flex border-radius-1em border p-3">
							       <b-img-lazy :src="selected_ecommerce_integration.logo" :style="[{'width': '155px'}]" class="my-auto ml-2 p-2"><h5 class="ml-3 my-auto">Setup {{selected_ecommerce_integration.name}} Integration</h5>
							       <a class="btn btn-outline-light btn-rounded mb-auto ml-auto" @click="selected_ecommerce_integration = ''"><i class="mdi mdi-close"></i></a>
							       </div>
							      <b-form id="ecommerce-integration-setup" method="POST" enctype="application/x-www-form-urlencoded">
										<b-form-group v-for="(fields, key) in selected_ecommerce_integration_auth_rules" label-class="text-capitalize" :label="key.replaceAll('_', ' ').replace('id', 'ID')" class="mt-3">
											<div v-if="fields.type == 'text'">
											
										<b-form-input :name="key" :type="{'text' : fields.type == 'text'}" :required="'required' in fields" v-model="selected_ecommerce_integration_auth_details[key]"></b-form-input>
											
										</div>
										<div v-else-if="fields.type == 'checkbox'">
											<b-form-checkbox inline :name="key" :type="{'checkbox' : fields.type == 'checkbox'}" :required="'required' in fields" v-model="selected_ecommerce_integration_auth_details[key]"></b-form-checkbox>
										</div>
										<div v-else-if="fields.type == 'integer'">
											<b-form-input inline :name="key" :type="'number'" :required="'required' in fields" v-model="selected_ecommerce_integration_auth_details[key]"></b-form-input>
										</div>
										</b-form-group>
									<b-form-group label="name">
									<b-form-input type="text" name="name" v-model="selected_ecommerce_integration_auth_details.name" />
									</b-form-group>
									<b-form-group label="store_url">
									<b-form-input type="text" name="store_url" v-model="selected_ecommerce_integration_auth_details.store_url" />
									</b-form-group>
									<b-form-group label="consumer_key">
									<b-form-input type="text" name="consumer_key" v-model="selected_ecommerce_integration_auth_details.consumer_key" />
									</b-form-group>
									<b-form-group label="consumer_secret">
									<b-form-input type="text" name="consumer_secret" v-model="selected_ecommerce_integration_auth_details.consumer_secret" />
									</b-form-group>
									<b-form-group label="account_nominal_code">
									<b-form-input type="text" name="account_nominal_code" v-model="selected_ecommerce_integration_auth_details.account_nominal_code" />
									</b-form-group>
									<b-form-group label="timezone">
									<b-form-input type="text" name="timezone" v-model="selected_ecommerce_integration_auth_details.timezone" />
									</b-form-group>


									<b-form-group label="Active">
										<b-form-checkbox name="is_active" v-model="selected_ecommerce_integration_auth_details.is_active" /></b-form-group>

									<b-form-group>
										<b-form-checkbox type="text" name="use_default_get_orders_interval" v-model="selected_ecommerce_integration_auth_details.use_default_get_orders_interval" /></b-form-group>
									<b-form-input type="number" name="get_orders_interval" v-model="selected_ecommerce_integration_auth_details.get_orders_interval" />
									<b-form-group label="auto_sync_orders">
										<b-form-checkbox switch name="auto_sync_orders" v-model="selected_ecommerce_integration_auth_details.auto_sync_orders" /></b-form-group>
									<b-form-group label="manage_stock">
										<b-form-checkbox name="manage_stock" v-model="selected_ecommerce_integration_auth_details.manage_stock"  /></b-form-group>
									<b-form-group label="download_products">
										<b-form-checkbox name="download_products" v-model="selected_ecommerce_integration_auth_details.download_products" /></b-form-group>
									<b-form-group label="add_to_products">
										<b-form-checkbox name="add_to_products" v-model="selected_ecommerce_integration_auth_details.add_to_products" /></b-form-group>
									<b-form-group label="set_initial_stock_levels">
										<b-form-checkbox name="set_initial_stock_levels" v-model="selected_ecommerce_integration_auth_details.set_initial_stock_levels" /></b-form-group>
									<b-form-group label="auto_sync_stock">
										<b-form-checkbox name="auto_sync_stock" v-model="selected_ecommerce_integration_auth_details.auto_sync_stock" /></b-form-group>

									<b-form-group label="use_external_shipment_modules">
										<b-form-checkbox name="use_external_shipment_modules" v-model="selected_ecommerce_integration_auth_details.use_external_shipment_modules" /></b-form-group>

									<b-form-group label="download_product_images">
										<b-form-checkbox name="download_product_images" v-model="selected_ecommerce_integration_auth_details.download_product_images" /></b-form-group>
									<b-form-group label="download_product_dimensions">
										<b-form-checkbox name="download_product_dimensions" v-model="selected_ecommerce_integration_auth_details.download_product_dimensions" /></b-form-group>
									<b-form-group label="deduct_stock_via_orders">
										<b-form-checkbox name="deduct_stock_via_orders" v-model="selected_ecommerce_integration_auth_details.deduct_stock_via_orders" /></b-form-group>
									<b-form-group label="ignore_orders_before">
										<b-form-checkbox name="ignore_orders_before" v-model="selected_ecommerce_integration_auth_details.ignore_orders_before" /></b-form-group>
									<b-form-group label="ignore_orders_before_at">
									<b-form-datepicker name="ignore_orders_before_at" v-model="selected_ecommerce_integration_auth_details.ignore_orders_before_at" />
									</b-form-group>
							    	<b-button @click="setUpChannel(selected_ecommerce_integration)" variant="primary" class="mt-3 btn-block btn-rounded">Set-up Integration</b-button>
							    	</b-form>
							    </b-card>-->
							  </b-overlay>
								<b-card class="border-radius-1em border shadow-none" >
									
							      <div class="d-flex">
							      	
							      		<a v-if="(start_marketplaces !== 0)" class="btn btn-light btn-rounded mr-auto my-auto"  @click="start_marketplaces -= 12, end_marketplaces -= 12"><i class="bx bx-chevron-left"></i></a>
							      	
							      		<div class="search-box mx-2 w-100 my-3">
							                <div class="position-relative">
							                  <input
							                    type="text"
							                    class="form-control w-100 border-0 bg-light"
							                    placeholder="Search name, reference or description..."
							                    v-model="carrier_search"
							                    @keypress.enter="start_marketplaces = 0, end_marketplaces = 12"
							                  />
							                  <i class="bx bx-search-alt search-icon"></i>
							                </div>
							              </div>
							      		<a v-if="(start_marketplaces + 12) < all_carrier_integrations.length" class="btn btn-light btn-rounded ml-auto my-auto" @click="start_marketplaces += 12, end_marketplaces += 12"><i class="bx bx-chevron-right"></i></a>
							      	
							      </div>
							     
							      <div class="row" >
							          <div class="col-md-3" v-for="(carrier, index) in filteredCommerceChannels('Shopping Carts')">
							           <div class="card shadow-none border border-radius-1em mb-2" @click="startIntegration = true, selected_ecommerce_integration = carrier, selected_ecommerce_integration_index = index" :class="{'active': selected_ecommerce_integration_index == index}">
							            
							           <div class="card-body text-center d-flex flex-column position-relative">
							           	<i v-if="selected_ecommerce_integration_index == index" class="bx bx-check-circle text-success position-absolute top-0 right-0 mr-2 mt-2 font-size-24"></i>
							            <b-img-lazy :src="carrier.image" class="my-3 mx-auto" :style="[carrier.name == 'Shoplazza' ? 'padding' : '40px 0']"  width="120px" />
							            <p class="mx-auto my-auto pt-2">{{carrier.name}}</p>
							            </div>
							          </div>
							          </div>
							       </div>
							       <b-card no-body class="border border-radius-1em shadow-none">
							       	<b-overlay :show="loadingEcommerceIntegration">
							       		<template #overlay>
										<b-row>
						      			<b-col md="12" class="offset-md-2" >
						      			<b-card class="border-radius-1em border shadow-none mb-0">	
								            <div class="cube-wrapper mx-auto"><div class="cube-folding"><span class="leaf1"></span><span class="leaf2"></span><span class="leaf3"></span><span class="leaf4"></span></div></div>
								            <h6 class="text-center">Setting up...</h6>
								             <b-progress class="w-50 mx-auto" v-model="oauth_setup_progress" variant="primary"></b-progress>
						         		</b-card>
						      			</b-col>
						      			</b-row>
						      		</template>
							        <div class="row mb-3" v-if="startIntegration == true">
							      		<div class="col-md-12 d-flex">
							      		<a class="btn btn-outline-light ml-auto my-auto rounded-circle mt-2 mr-2" @click="startIntegration = false">
							      			<i class="mdi mdi-close"></i>
							      		</a>
							      		</div>
							      		<div class="col-md-12 mx-auto d-flex flex-column justify-content-center" style="min-height: 300px;">
							      			<h4 class="text-center"><i class="bx bx-link"></i> Enter Website URL</h4>
							      				<b-form-group label="" class="w-75 mx-auto">
								      				<b-form-input type="text" v-model="ecommerce_website_url"></b-form-input>
							      				</b-form-group>
							      				<!--<b-card class=" mt-3 w-75 mx-auto border border-radius-1em shadow-none">
							      					<h5>Auto Syncronise Actions</h5>
							      					<p>This will import changes made on your external channel to Gemesys via webhooks*. For example Orders > Create will create a new order on Gemesys when a new order is created on your e-commerce platform.</p>
								      				<b-form-group v-if="active_channel_webhooks.length > 0 && 'webhooks' in active_channel_webhooks[0]" v-for="(value, key, index) in active_channel_webhooks[0]['webhooks']" :label="key" class="text-capitalize mb-3">
								      					<b-form-checkbox-group type="checkbox" :options="active_channel_webhooks[0]['webhooks'][key]" v-model="registered_webhooks[key]" :value="value" multiple >
								      					</b-form-checkbox-group>
								      				</b-form-group>
								      				<small>* Certain e-commerce platforms require manual set-up of webhooks.</small>
							      				</b-card>-->
							      			<a class="btn btn-primary btn-rounded mt-3 mx-auto" @click="setupOauthIntegration()">
							      			Start Integration
							      			</a>
							      		</div>
							      	 </div>
							      	</b-overlay>
							  		</b-card>
							    </b-card>
							    
							</b-col>
					        </b-row>		
				</b-col>
              </b-row>
						</b-card>
					</b-col>
				</b-row>
				<b-row v-if="view == 'Feeds'">
					<b-card class="border-radius-1em border shadow-none">
						<b-row>
					      <b-col md="2">
								<h5 class="d-flex">Feeds <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
							</b-col>
							<b-col md="10">
								<b-tabs pills content-class="pt-3">
									<b-tab title="Social">
								<b-row>
									<b-col md="3" v-for="integration in app_integrations.social">
										<b-button variant="outline-light" class="w-100 p-4 border-radius-1em" @click="updateActiveIntegrations('social', integration)" >
											<i class="text-primary font-size-42 mt-2 d-block" :class="integration.icon"></i>
											<i v-if="filterActiveIntegrations('social', integration)" class="bx bx-check-circle text-success top-0 right-0 m-3"></i>
											<h6 class="text-muted">{{integration.text}}</h6>
										</b-button>
									</b-col>
								</b-row>
							</b-tab>
							<b-tab title="Search & Analytics">
								<b-row>
									<b-col md="3" v-for="integration in app_integrations.search">
										<b-button variant="outline-light" class="w-100 p-4 border-radius-1em" @click="updateActiveIntegrations('search', integration)" >
											<i class="text-primary font-size-42 mt-2 d-block" :class="integration.icon"></i>
											<i v-if="filterActiveIntegrations('search', integration)" class="bx bx-check-circle text-success top-0 right-0 m-3"></i>
											<h6 class="text-muted">{{integration.text}}</h6>
										</b-button>
									</b-col>
								</b-row>
							</b-tab>
							<b-tab title="Email & Newsletters">
								<b-row>
									<b-col md="3" v-for="integration in app_integrations.email">
										<b-button variant="outline-light" class="w-100 p-4 border-radius-1em" @click="updateActiveIntegrations('email', integration)" >
											<i class="text-primary font-size-42 mt-2 d-block" :class="integration.icon"></i>
											<i v-if="filterActiveIntegrations('email', integration)" class="bx bx-check-circle text-success top-0 right-0 m-3"></i>
											<h6 class="text-muted">{{integration.text}}</h6>
										</b-button>
									</b-col>
								</b-row>
							</b-tab>
							<!--<b-tab title="Payment">
							</b-tab>
							<b-tab title="Website">
								<b-row>
											 		<b-col md="6">
											<b-button
					                      class=" flex-grow-1 my-0 py-4 w-100"
					                      :disabled="website.external == (true || 1 || '1')"
					                      :class="{'active' : website.gemesys == (true || 1 || '1')}"
					                      variant="outline-light"
					                      @click="website.gemesys = true, website.external = false, setUpWebsite()"
					                    >
					                    <div v-if="website.gemesys !== (true || 1)" class="cube-wrapper text-center w-100 m-0 my-2 px-0"><div class="cube-folding mx-auto"><span class="leaf1"></span><span class="leaf2"></span><span class="leaf3"></span><span class="leaf4"></span></div></div>
					                    <i v-else class="bx bx-check-circle font-size-24 d-block py-4 text-success my-2"></i> 
					                    <h6 class="text-muted">Use Gemesys Website</h6>
					              </b-button>
					            </b-col>
					            <b-col md="6">
					              <b-button
					                      class=" flex-grow-1 my-0 py-4 w-100"
					                     
					                      :class="{'active' : website.external == (true || 1 || '1')}"
					                      variant="outline-light"
					                     
					                    >
					                    <span class="badge badge-info bg-success text-white float-end">Coming Soon</span>
					                    <i v-if="website.external !== (true || 1)" class="bx bx-globe font-size-24 d-block py-4 text-primary my-2"></i> 
					                    <i v-else class="bx bx-check-circle font-size-24 d-block py-4 text-success my-2"></i> 
					                    <h6 class="text-muted">Connect to External Website</h6>
					              </b-button>
					            </b-col>
					          </b-row>
							</b-tab>-->
							<b-tab title="Pricing">
									<b-row>
									<b-col md="3" v-for="integration in app_integrations.pricing">
										<b-button variant="outline-light" class="w-100 p-4 border-radius-1em" @click="updateActiveIntegrations('pricing', integration)" >
											<i class="text-primary font-size-42 mt-2 d-block" :class="integration.icon"></i>
											<i v-if="filterActiveIntegrations('pricing', integration)" class="bx bx-check-circle text-success top-0 right-0 m-3"></i>
											<h6 class="text-muted">{{integration.text}}</h6>
										</b-button>
									</b-col>
								</b-row>
							</b-tab>
						</b-tabs>
							</div>
							</b-col>
						</b-row>
					</b-card>
					<b-modal hide-footer v-model="integrationsSettingsModal" size="lg">
						<h5>{{app_integrations_settings.text}} Integration Setting</h5>
						<hr>
						<b-list-group v-if="(integrationsSettingsModal == true) && ((app_integrations_settings.value == 'google-shopping') || (app_integrations_settings.value == 'facebook') )">
						<b-list-group-item>
							<b-form-group>
								<label class="text-uppercase">Domain</label>
								<b-form-select class="form-control">
									<b-form-select-option v-for="domain in domains.data" :value="domain.domain">
										{{domain.domain}}
									</b-form-select-option>
								</b-form-select>
							</b-form-group>
						</b-list-group-item>
						</b-list-group>
						<widgetConfigsTab v-if="(integrationsSettingsModal == true) && ('configs' in app_integrations_settings.settings == true)"  :user="$attrs.user" :company="$attrs.company" :widgetbox="false" :configs="app_integrations_settings.settings.configs" :content="app_integrations_settings.settings.content" />
						
						<widgetContentFilters v-if="(integrationsSettingsModal == true) && ('filters' in app_integrations_settings.settings == true)" :widgetbox="false" :user="$attrs.user"  :company="$attrs.company" :filter="app_integrations_settings.settings.filters.filter" :active_filters="app_integrations_settings.settings.filters.active_filters" ></widgetContentFilters>

						

						<b-list-group v-if="(integrationsSettingsModal == true) && (app_integrations_settings.value == 'google-search')">
							<b-list-group-item v-for="domain in domains.data" class="d-flex flex-column">
								<div class="d-flex">	
								<div class="mr-auto">
									{{domain.domain}}
								</div>
								<div class="my-auto">
									<span class="badge badge-soft-danger text-danger" v-if="domain.sitemap == 0">
										Sitemap Not Generated
									</span>
									<span class="badge badge-soft-success text-success" v-else @click="$bvCollapse.show('sitemap-'+domain.domain)">
										Sitemap Generated
									</span>
								</div>
								<div class="ml-2">
									<a class="btn btn-primary">
										Generate Sitemap
									</a>
								</div>
							    </div>
								<b-collapse :id="'sitemap-'+domain.domain" class="w-100">
									<b-card class="border-radius-1em border">
										<a class="text-primary" :href="'http://'+domain.domain+'/'+domain.id+'-'+domain.uid+'-sitemap.xml'" target="_blank">
											https://{{domain.domain}}/{{domain.uid}}-{{domain.uid}}-sitemap.xml
										</a>
										<a :id="'sitemaplink-'+domain.domain" class="btn btn-light float-right text-primary font-size-20" v-b-tooltip.hover title="Copy Link" @click="copyLink('sitemaplink-'+domain.domain)">
					                      <i class="bx bx-copy-alt"></i>
					                    </a>

									</b-card>
								</b-collapse>
							</b-list-group-item>
						</b-list-group>

						

						<a class="btn btn-primary btn-rounded my-3 btn-block" v-if="(integrationsSettingsModal == true) && (app_integrations_settings.type == 'feed')" @click="generateFeed(app_integrations_settings, active_feed_uid)">
							Generate Feed
						</a>
						<!--<a class="btn btn-primary btn-rounded my-3 btn-block" v-if="(integrationsSettingsModal == true) && ((app_integrations_settings.type == 'integration')) " @click="generateFeed(app_integrations_settings, active_feed_uid)">
							Save Integration
						</a>-->
						<a class="btn btn-primary btn-rounded my-2 btn-block" v-if="(app_integrations_settings.type == 'mail') || (app_integrations_settings.type == 'integration')" @click="saveIntegration(app_integrations_settings, active_feed_uid)">
							Save Integration
						</a>
						<hr>
						<label v-if="(integrationsSettingsModal == true) && (app_integrations_settings.type == 'feed')" class="text-uppercase mt-3">Saved Feeds</label>
						<label v-if="(integrationsSettingsModal == true) && ((app_integrations_settings.type == 'integration') || (app_integrations_settings.type == 'mail') )" class="text-uppercase mt-3">Saved Integrations</label>
						<b-card v-if="(integrationsSettingsModal == true) && (app_integrations_settings.type == 'feed')" class="border-radius-1em border shadow-none">
							<b-overlay :show="loadingFeeds" no-wrap></b-overlay>
								<div class="widget-scrollbox" style="overflow-y: scroll; overflow-x: hidden; height: auto; max-height: 300px;">
									<b-list-group  v-if="'data' in feeds">
										<b-list-group-item class="d-flex" v-if="feeds.data.length > 0" v-for="feed in feeds.data">
										<div class="d-flex flex-column">
											<div>
												{{feed.name}}
											</div>
											<div class="d-flex">
												<a :href="feed.domain" class="text-capitalize">
													{{feed.domain}}
												</a>
												<div>
													Service: 
													<span class="badge badge-light bg-light">
													{{feed.service}}
													</span>
												</div>
											</div>
										</div>
											
											
												<a class="btn btn-light btn-rounded my-auto ml-auto mr-1" @click="active_feed_uid = feed.uid">
													Edit Feed
												</a>
											
												<a class="btn btn-primary btn-rounded my-auto mr-1" >
													Download Feed
												</a>
											
												<a class="btn btn-danger btn-rounded my-auto" @click="active_feed_uid = feed.uid">
													Delete Feed
												</a>
											
										</b-list-group-item>
										<b-list-group-item v-else class="d-flex flex-column justify-content-center align-items-center py-4 bg-light">
											<i class="bx bx-rss"></i>
											<h5>No Feeds Available</h5>

										</b-list-group-item>
									</b-list-group>
									</div>
								<b-pagination align="center" class="my-2" v-if="'data' in feeds && feeds.data.length > 0" :total-rows="feeds.total" :per-page="feeds.per_page">
								</b-pagination>
						</b-card>
						<b-card v-if="(integrationsSettingsModal == true) && ((app_integrations_settings.type == 'integration') || (app_integrations_settings.type == 'mail') ) " class="border-radius-1em border shadow-none">
							<b-overlay :show="loadingFeeds" no-wrap></b-overlay>
								<div class="widget-scrollbox" style="overflow-y: scroll; overflow-x: hidden; height: auto; max-height: 300px;">
									<b-list-group  v-if="'data' in integrations">
										<b-list-group-item class="d-flex" v-if="integrations.data.length > 0" v-for="integration in integrations.data">
											<div class="d-flex flex-column my-auto">
											<div class="text-capitalize">
												{{(integration.service).replaceAll('-', ' ')}}
											</div>
											<div class="d-flex">
												<div v-if="integration.username !== null">
													<span class="badge badge-light bg-light">
													 {{integration.username.slice(0,2)}}XX-XXXX-XXXX-XXXX
													</span>
												</div>
												<div v-if="integration.pass !== null">
													<span class="badge badge-light bg-light">
													 {{integration.pass.slice(0,2)}}XX-XXXX-XXXX-XXXX
													</span>
												</div>
											</div>
										</div>
										<div class="my-auto mx-auto" v-if="integration.configs !== null">
											<div v-for="(value, key) in integration.configs">
												<span class="badge badge-light bg-light" v-for="configkey in value" >
													{{configkey['value']}}
												</span>
											</div>
										</div>
											
										<a class="btn btn-light btn-rounded my-auto ml-auto mr-1" @click="active_integration_uid = integration.uid">
													Edit Integration
										</a>
										
										<a class="btn btn-danger btn-rounded my-auto" @click="active_integration_uid = integration.uid">
													Delete Integration
										</a>
										</b-list-group-item>
									</b-list-group>
								</div>
								<b-pagination align="center" class="my-2" v-if="'data' in integrations && integrations.data.length > 0" :total-rows="integrations.total" :per-page="integrations.per_page">
								</b-pagination>
							
						</b-card>

					</b-modal>
				</b-row>
				<b-row v-if="view == 'Appraise'">
					<b-col md="12">
						<b-card class="p-3">
						<h4>{{view}} Settings</h4>
						<hr>	
						</b-card>
					</b-col>
				</b-row>
				<b-row v-if="view == 'Repair'">
					<b-col md="12">
						<b-card class="p-3">
						<h4>{{view}} Settings</h4>
						<hr>	
						</b-card>
					</b-col>
				</b-row>
			</b-col>
			<SideSlidePanel :editPanel="setPermissions" :panelClass="'edit-sidepanel-large'" @close-panel="setPermissions = false">
				<b-row>
				<b-col md="2">
					<h5 class="d-flex">{{permission_role.name}} <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
					<a class="btn btn-success btn-block btn-rounded mt-3" @click="updateCompanyUserRolePermissions(permission_role.id)">Update Permissions</a>
					<a class="btn btn-block btn-info btn-rounded mt-3" @click="getFactoryUserRolePermissions(permission_role.id)">Reset to Factory Settings</a>
				</b-col>
				<b-col md="10">
				<div class="bg-white">
				<b-list-group flush>
					<b-list-group-item class=" shadow-none " v-for="resource in permission_resources" v-if="resource.isTitle == 0" no-body>
						<div v-if="resource.isTitle == 0">
						<b-button variant="white" v-b-toggle="'permissions-'+resource.id" class="btn-collapse-toggle btn btn-white m-0 px-4 py-0 w-100 btn-white text-left align-items-start justify-content-start d-flex" ><span class="my-auto">{{$t(resource.label)}}</span> <a class="btn btn-primary rounded-circle ml-auto my-auto"><i class="mdi mdi-plus"></i></a></b-button>
						<b-collapse :id="'permissions-'+resource.id" v-if="resource.submenu.length == 0">
						<b-card v-if="resource.submenu.length == 0" class="shadow-none mb-0">
							<b-row class="px-3">
											<b-col md="3" variant="white" class="">{{$t(resource.label)}}</b-col>
											<b-col md="9" :id="'permissions-'+resource.id">
											<b-form-group class="text-right">
												<b-form-checkbox v-for="(action, key) in permissions[0]" v-model="permissions[0][key]" :value="resource.id" :name="'resource-'+resource.id+''+key+''" inline> {{key}} </b-form-checkbox>
											</b-form-group>
										</b-col>
									</b-row>
										</b-card>
									</b-collapse>
						</div>
						<b-collapse :id="'permissions-'+resource.id" v-if="resource.submenu.length > 0">
							<b-card class="shadow-none">
								<!--<h4>{{ $t(resource.label) }}</h4>-->
								<b-list-group flush>
									<b-list-group-item v-if="resource.submenu" v-for="subsubmenu in resource.submenu">
										<b-row>
											<b-col md="3" variant="white" class=" d-flex align-items-center">{{$t(subsubmenu.label)}}</b-col>
											<b-col md="9" :id="'permissions-'+subsubmenu.id">
												<b-card class="shadow-none mb-0" no-body>
													<b-form-group class="text-right">
														<b-form-checkbox v-for="(action, key) in permissions[0]" v-model="permissions[0][key]" :value="subsubmenu.id" inline> {{key}} </b-form-checkbox>
													</b-form-group>
												</b-card>
											</b-col>
										</b-row>
									</b-list-group-item>
								</b-list-group>
							</b-card>
						</b-collapse>
					</b-list-group-item>
				</b-list-group>
				</div>
			</b-col>
		</b-row>
			</SideSlidePanel>
			<b-modal id="commerce-products" centered size="xl" hide-footer header-class="py-4">
				
					<b-row v-if="selected_integration_uid && selected_integration_uid !== ''">
					<b-col  >
						<div class="col-sm-12 pb-4 text-center mb-4">
							<b-card class="border-radius-1em border shadow-none">
							<b-img-lazy  :src="getChannelsImage(selected_integration.integration_name)" class="mx-auto mb-3" width="70px" />
            		<h3>{{selected_integration.integration_name}} Integration Resources</h3>
            	</b-card>
            	</div>
			 <b-overlay :show="loadingMultipleImport">
              				<template #overlay>
							<b-row>
			      			<b-col md="12" class="offset-md-2" >
			      			<b-card class="border-radius-1em border shadow-none mb-0">	
					            <div class="cube-wrapper mx-auto"><div class="cube-folding"><span class="leaf1"></span><span class="leaf2"></span><span class="leaf3"></span><span class="leaf4"></span></div></div>
					            <h6 class="text-center">Importing {{channels_area_active}}<br> {{multiItemsIndex}} of {{multiItemsUploadTotal}}</h6>
					             <b-progress class="w-50 mx-auto" v-model="multiItemsIndex" :max="multiItemsUploadTotal" variant="primary"></b-progress>

					              <h6 class="text-center mt-3">Skipped {{multiItemsExists}} <br> duplicate {{channels_area_active}}(s)</h6>


					              <h6>Could not upload the following items</h6>
					              <ul>
					              	<li v-for="error in multiImportErrors.items">Error with product {{error.id}}: {{error.err_msg}}</li>
					              </ul>
			         		</b-card>
			      			</b-col>
			      			</b-row>
			      		</template>
              <b-tabs pills content-class="pt-3" align="center" >
              		<b-tab :title="'Export & Sync Gemesys Resources'">
              			<b-overlay :show="loadingProducts">
              				<template #overlay>
							<b-row>
			      			<b-col md="12" class="offset-md-2" >
			      			<b-card class="border-radius-1em border shadow-none mb-0">	
					            <div class="cube-wrapper mx-auto"><div class="cube-folding"><span class="leaf1"></span><span class="leaf2"></span><span class="leaf3"></span><span class="leaf4"></span></div></div>
					            <h6 class="text-center">{{products_loading_text}}</h6>
					             <b-progress class="w-50 mx-auto" v-model="products_loading_progress" variant="primary"></b-progress>
			         		</b-card>
			      			</b-col>
			      			</b-row>
			      		</template>
			      		<b-tabs pills content-class="pt-3">
			      			<template #tabs-end>
						      <a class="btn btn-primary text-white ml-auto btn-rounded">
						      	Export All
						      </a>
						    </template>
              		<b-tab :title="'Products'">
              			<b-card class="border-radius-1em border shadow-none" body-class="p-0" v-if="all_products !== ''">
              			<b-list-group flush v-if="'data' in all_products && all_products.data.length > 0" class="widget-scrollbox">
							<b-list-group-item v-for="(product, key) in all_products.data" class="d-flex">
								<div class="my-auto" v-if="product.cover_image !== null">
									<b-img-lazy v-if="'thumb' in product.cover_image" :src="storageurl+(product.cover_image.thumb).replaceAll('//', '/')" class="avatar-sm rounded-circle mr-2" />
									<b-img-lazy v-else-if="'gallery' in product.cover_image" :src="storageurl+(product.cover_image.gallery).replaceAll('//', '/')" class="avatar-sm rounded-circle mr-2" />
									<b-img-lazy v-else-if="'src' in product.cover_image" :src="storageurl+(product.cover_image.src).replaceAll('//', '/')" class="avatar-sm rounded-circle mr-2" />
									<span v-else class="avatar-title avatar-md">
										{{product.name.slice(0, 1)}}
									</span>
								</div>
								<div class="d-flex flex-column my-auto">
									<span>SKU: {{product.reference}}</span>
									<h5 class="mb-0">{{product.name}}</h5>
								</div>
								<div class="d-flex my-auto ml-auto">
										<div class="d-flex flex-column mr-3">
											<label>Online Quantity</label>
											<span>{{product.online_qty}}</span>
										</div>
										<div class="d-flex flex-column mr-3">
											<label>Total Quantity</label>
											<span>{{product.total_qty}}</span>
										</div>
								</div>
								<h5 class="my-auto ml-2">{{product.sales_price}}</h5>
							<div class="d-flex flex-column w-25">
								<a class="btn btn-primary mt-auto ml-3 btn-rounded mb-1 text-capitalize" v-b-modal.quantity  v-if="checkChannelLink(product) == false" @click="selected_product = product">
									Push to {{selected_integration.integration_name}}
								</a>
								<a class="btn btn-info mt-auto ml-3 btn-rounded mb-1 text-capitalize" v-b-modal.manual-link  v-if="checkChannelLink(product) == false" @click="selected_product = product">
									Link to Existing {{selected_integration.integration_name}} Item
								</a>
								<div v-else class="d-flex flex-column w-100">
								<a class="btn btn-light btn-rounded mt-auto mb-1 ml-3" @click="viewResourceLinkedProduct(product)">
									<i class="bx bx-check-circle text-success font-size-20"></i> Linked with {{selected_integration.integration_name}}
								</a>
								<!--<a class="btn btn-primary mb-auto ml-3 btn-rounded mt-1">
									Push Updates to {{selected_integration.integration_name}}
								</a>-->
								<a class="btn btn-danger btn-rounded mb-auto mt-1 ml-3">
									<i class="bx bx-x-circle text-white font-size-20"></i> Unlink Product
								</a>
								</div>
							</div>
							</b-list-group-item>
						</b-list-group>
					</b-card>
					<b-pagination v-model="productscurrentPage" v-if="'data' in all_products && all_products.data.length > 0" 
						:total-rows="all_products.total" :per-page="all_products.per_page" @input="getProducts()">
					</b-pagination>
				</b-tab>
				<b-tab title="Categories" @click="getCategories()">
					<b-card class="border-radius-1em border shadow-none" body-class="p-0" v-if="all_categories !== ''">
              			<b-list-group flush v-if="all_categories.length > 0" class="widget-scrollbox">
							<b-list-group-item v-for="(product, key) in all_categories" class="d-flex">
								<div v-if="product.image !== null" class="my-auto">
									<b-img-lazy :src="product.image" class="avatar-sm rounded-circle mr-2 my-auto border" />
									
								</div>
								<div class="d-flex flex-column my-auto mr-auto w-50">
									<h5 class="mb-0">{{product.name}}</h5>
									<p class="mb-0" v-html="product.description">
									</p>
								</div>

							<div class="d-flex flex-column ml-auto">
								<a class="btn btn-primary mt-auto ml-3 btn-rounded mb-1 text-capitalize" @click="selected_category = product, channelResourceExport('category', product, false)">
									Push to {{selected_integration.integration_name}}
								</a>
								<a class="btn btn-light btn-rounded">
									Category Exists on {{selected_integration.integration_name}}
								</a>
								<a class="btn btn-primary mb-auto ml-3 btn-rounded mt-1">
									Sync with {{selected_integration.integration_name}} Item
								</a>
							</div>
							</b-list-group-item>
						</b-list-group>
					</b-card>
					<b-pagination v-model="categoriescurrentPage" v-if="all_categories.length > 0" 
						:total-rows="all_categories.total" :per-page="all_categories.per_page" >
					</b-pagination>
				</b-tab>
			</b-tabs>
			</b-overlay>
          </b-tab>
          <b-tab :title="'Import & Sync '+selected_integration.integration_name+' Resources'">
        
          	<b-tabs pills content-class="pt-3">
          		<template #tabs-end >
          			<div class="d-inline-flex ml-auto" v-if="channels_area_active == 'product'">

          				<b-form-select :options="[20, 50, 100, 500, 1000]"  v-model="productslimit" @change="getChannelProducts()">
          				</b-form-select>

          				<div v-if="(selected_channel_products !== '') && 'products' in selected_channel_products" class="d-flex ml-auto">
							<a v-if="productspage > 1" class="btn btn-light btn-rounded w-100" @click="selected_channel_products = '', productspage -= 1, getChannelProducts()">
								<i class="bx bx-chevron-left"></i>
							</a>
							<a class="btn btn-light btn-rounded w-100" @click="selected_channel_products = '', productspage += 1, getChannelProducts()">
								<i class="bx bx-chevron-right"></i>
							</a>
						</div>
						      <a v-if="(selected_channel_products !== '') && 'products' in selected_channel_products" class="btn btn-primary text-white ml-auto btn-rounded" :class="{'ml-2' : (selected_channel_products.products.length == productslimit) && (selected_channel_products !== '')}" @click="channelResourceImportAll('')">
						      	Import All
						      </a>
						  </div>
						 
						    <div class="d-inline-flex ml-auto" v-if="channels_area_active == 'category'">
          				<!--<div v-if="(selected_channel_categories !== '') && 'categories' in selected_channel_categories" class="d-flex ml-auto">
							<a v-if="categorieslimit > 51" class="btn btn-light btn-rounded w-100" @click="categorieslimit -= 50, getChannelCategories()">
								<i class="bx bx-chevron-left"></i>
							</a>
							<a v-if="selected_channel_categories.categories.length == categorieslimit" class="btn btn-light btn-rounded w-100" @click="categorieslimit += 50, getChannelCategories()">
								<i class="bx bx-chevron-right"></i>
							</a>
						</div>-->
						      <a v-if="(selected_channel_categories !== '') && 'categories' in selected_channel_categories" class="btn btn-primary text-white ml-auto btn-rounded" :class="{'ml-2' : (selected_channel_categories.categories.length == categorieslimit) && (selected_channel_categories !== '')}" @click="channelResourceImportAll('')">
						      	Import All
						      </a>
						    </div>
						    <div class="d-inline-flex ml-auto" v-if="channels_area_active == 'customer'">
          				<!--<div v-if="(selected_channel_customers !== '') && 'customers' in selected_channel_customers" class="d-flex ml-auto">
							<a v-if="customerslimit > 51" class="btn btn-light btn-rounded w-100" @click="customerslimit -= 50, getChannelCustomers()">
								<i class="bx bx-chevron-left"></i>
							</a>
							<a v-if="selected_channel_customers.customers.length == customerslimit" class="btn btn-light btn-rounded w-100" @click="customerslimit += 50, selected_channel_customers = '', getChannelCustomers()">
								<i class="bx bx-chevron-right"></i>
							</a>
						</div>-->
						      <a v-if="(selected_channel_customers !== '') && 'customers' in selected_channel_customers" class="btn btn-primary text-white ml-auto btn-rounded" :class="{'ml-2' : (selected_channel_customers.customers.length == customerslimit) && (selected_channel_customers !== '')}" @click="channelResourceImportAll('')">
						      	Import All
						      </a>
						  </div>
						    <div class="d-inline-flex ml-auto" v-if="channels_area_active == 'order'">
          				<!--<div v-if="(selected_channel_orders !== '') && 'orders' in selected_channel_orders" class="d-flex ml-auto">
							<a v-if="orderslimit > 51" class="btn btn-light btn-rounded w-100" @click="orderslimit -= 50, getChannelOrders()">
								<i class="bx bx-chevron-left"></i>
							</a>
							<a v-if="selected_channel_orders.orders.length == orderslimit" class="btn btn-light btn-rounded w-100" @click="orderslimit += 50, getChannelOrders()">
								<i class="bx bx-chevron-right"></i>
							</a>
						</div>-->
						      <a v-if="(selected_channel_orders !== '') && 'orders' in selected_channel_orders" class="btn btn-primary text-white ml-auto btn-rounded" :class="{'ml-2' : (selected_channel_orders.orders.length == orderslimit) && (selected_channel_orders !== '')}" @click="channelResourceImportAll('')">
						      	Import All
						      </a>
						  </div>
						    </template>
              		<b-tab :title="selected_integration.integration_name + ' Categories'" title-link-class="text-capitalize" @click="getChannelCategories()">
              			<b-overlay :show="loadingIntegrationCategories && loadingMultipleImport == false">
              				<template #overlay>
							<b-row>
			      			<b-col md="12" class="offset-md-2" >
			      			<b-card class="border-radius-1em border shadow-none mb-0">	
					            <div class="cube-wrapper mx-auto"><div class="cube-folding"><span class="leaf1"></span><span class="leaf2"></span><span class="leaf3"></span><span class="leaf4"></span></div></div>
					            <h6 class="text-center">{{integration_loading_text}}</h6>
					             <b-progress class="w-50 mx-auto" v-model="integration_loading_progress" variant="primary"></b-progress>
			         		</b-card>
			      			</b-col>
			      			</b-row>
			      		</template>
              			<b-card class="border-radius-1em border shadow-none" body-class="p-0" v-if="selected_channel_categories !== ''">
              				<b-list-group flush v-if="'categories' in selected_channel_categories && selected_channel_categories.categories.length > 0" class="widget-scrollbox">
								<b-list-group-item v-for="(category, key) in selected_channel_categories.categories" class="d-flex">
									<div class="d-flex flex-column my-auto">
									<h5 class="mb-0">{{category.name}}</h5>
		
									</div>
									<a class="btn btn-primary my-auto btn-rounded ml-auto"  @click="channelResourceImport('category', category, false)">
										Import to Gemesys
									</a>	
								</b-list-group-item>
							</b-list-group>
							<div class="d-inline-flex ml-auto" v-if="channels_area_active == 'category'">
          				<div v-if="(selected_channel_categories !== '') && 'categories' in selected_channel_categories" class="d-flex w-100">
							
							<a v-if="selected_channel_categories.categories.length == categorieslimit" class="btn btn-light btn-rounded w-100" @click="categorieslimit += 100, getChannelCategories()">
								Load More <i class="bx bx-chevron-right"></i>
							</a>
						</div>
						    
						    </div>
              			</b-card>
              		</b-overlay>
              		</b-tab>
              		<b-tab :title="selected_integration.integration_name + ' Products'" title-link-class="text-capitalize" @click="getChannelProducts()">
              			<b-overlay :show="loadingIntegrationProducts && loadingMultipleImport == false">
              				<template #overlay>
							<b-row>
			      			<b-col md="12" class="offset-md-2" >
			      			<b-card class="border-radius-1em border shadow-none mb-0">	
					            <div class="cube-wrapper mx-auto"><div class="cube-folding"><span class="leaf1"></span><span class="leaf2"></span><span class="leaf3"></span><span class="leaf4"></span></div></div>
					            <h6 class="text-center">{{integration_loading_text}}</h6>
					             <b-progress class="w-50 mx-auto" v-model="integration_loading_progress" variant="primary"></b-progress>
			         		</b-card>
			      			</b-col>
			      			</b-row>
			      		</template>
              <b-card class="border-radius-1em border shadow-none" body-class="p-0" v-if="selected_channel_products !== ''">
              	
						<b-list-group flush v-if="!loadingIntegrationProducts && ('products' in selected_channel_products && selected_channel_products.products.length > 0)" class="widget-scrollbox">
							<b-list-group-item v-for="(product, key) in selected_channel_products.products" class="d-flex">
								<b-img-lazy v-if="product.imageUrl !== null" :src="product.imageUrl" class="avatar-sm rounded-circle mr-2 my-auto" />
								
								<div class="d-flex flex-column my-auto w-50 mx-3">
									<div class="d-flex">
									<div class="d-flex flex-column my-auto">
									<span>SKU: {{product.sku}}</span>
									<h5 class="mb-0">{{product.name}}</h5>
									<p class="mb-0 v-html-p paragraph-truncate-line2" v-if="product.description !== null" v-html="product.description"></p>
									</div>	
									
									</div>
									<div class="d-flex mt-2">
										<div class="d-flex flex-column mr-3">
											<label>Online Quantity</label>
											<span>{{productMatchQuantity('online_qty',product.id)}}</span>
										</div>
										<div class="d-flex flex-column mr-3">
											<label>Total Quantity</label>
											<span>{{productMatchQuantity('total_qty',product.id)}}</span>
										</div>
										<div class="d-flex flex-column mr-3">
											<label class="text-capitalize">{{selected_integration.integration_name}} Quantity</label>
											<span>{{product.quantity}}</span>
										</div>
									</div>
								</div>
								<h5 class="my-auto ml-auto mr-3">{{product.price}}</h5>
								<div class="d-flex">
									<b-button variant="light" class="btn btn-light btn-rounded my-auto" @click="product.quantity--" :disabled="product.quantity < 1">
										<i class="bx bx-minus"></i>
									</b-button>

									<b-form-input type="number" v-model="product.quantity" class="my-auto mx-2" style="width: 80px;">
									</b-form-input>

									<b-button variant="light" class="btn btn-light btn-rounded my-auto" @click="product.quantity++" >
										<i class="bx bx-plus"></i>
									</b-button>
								</div>

								
								<div class="d-flex flex-column w-25 justify-content-center">
									<a class="btn btn-primary my-auto ml-3 btn-rounded" v-if="checkGemesysLink(product) == true" @click="channelResourceImport('product', product, false)">
										Import to Stock
									</a>

									<div v-else class="d-flex flex-column w-100 " >
										
										<a class="btn btn-primary my-1 ml-3 btn-rounded">
											<i class="bx bx-check-circle text-success"></i> Linked to Gemesys Product
											</a>	
										<a class="btn btn-primary my-1 ml-3 btn-rounded">
										Update Quantity
										</a>
										<a class="btn btn-primary my-1 ml-3 btn-rounded">
											Sync with Stock
										</a>
									</div>
								</div>
							</b-list-group-item>
						</b-list-group>

						<div class="d-flex w-100">
						<!--<b-pagination v-model="productscurrentPage" v-if="productschanneltotal !== 0" :total-rows="productschanneltotal" :per-page="50" >

						</b-pagination>-->
						<div v-if="selected_channel_products !== ''" class="d-flex w-100 mt-3">
							<!--<a v-if="productslimit > 51" class="btn btn-light btn-rounded w-100" @click="productslimit -= 50, getChannelProducts()">
								<i class="bx bx-chevron-left"></i>
							</a>-->
							<b-button variant="light" :disabled="productspage == 1" class="btn btn-light btn-rounded ml-auto" @click="productspage--, getChannelProducts()">
								<i class="bx bx-chevron-left"></i> 
							</b-button>
							<b-form-input :class="'mx-3 w-25'" type="number" min="1" v-model="productspage" @change="getChannelProducts()">
							</b-form-input>
							<b-button variant="light" class="btn btn-light btn-rounded mr-auto" @click="productspage++, getChannelProducts()">
								 <i class="bx bx-chevron-right"></i>
							</b-button>
						</div>
					</div>
					
			</b-card>
		</b-overlay>
			</b-tab>
			<b-tab :title="selected_integration.integration_name + ' Customers'" title-link-class="text-capitalize" @click="getChannelCustomers(), getCustomerChannels()">
				<b-overlay :show="(loadingIntegrationCustomers == true) && (loadingMultipleImport == false)">
              				<template #overlay>
							<b-row>
			      			<b-col md="12" class="offset-md-2" >
			      			<b-card class="border-radius-1em border shadow-none mb-0">	
					            <div class="cube-wrapper mx-auto"><div class="cube-folding"><span class="leaf1"></span><span class="leaf2"></span><span class="leaf3"></span><span class="leaf4"></span></div></div>
					            <h6 class="text-center">{{integration_loading_text}}</h6>
					             <b-progress class="w-50 mx-auto" v-model="integration_loading_progress" variant="primary"></b-progress>
			         		</b-card>
			      			</b-col>
			      			</b-row>
			      		</template>
				<b-card class="border-radius-1em border shadow-none" body-class="p-0" v-if="selected_channel_customers !== ''">
              			<b-list-group flush v-if="'customers' in selected_channel_customers && selected_channel_customers.customers.length > 0" class="widget-scrollbox">

              				<b-list-group-item v-for="customer in selected_channel_customers['customers']">
              					<div class="w-100 d-flex my-auto">
              					<div class="d-flex flex-column my-auto">
              						<h5 class="mb-0 mt-auto">{{customer.firstName}} {{customer.lastName}}</h5>
              						<a class="mt-0 mb-auto" :href="'mailto:'+customer.email">{{customer.email}}</a>
              					</div>
              					<span class="badge badge-light bg-light ml-auto  my-auto" v-if="customer.addresses.length > 0">Has {{customer.addresses.length}} Addresses Registered</span>


              					<div class="my-auto ml-auto" v-if="!checkChannelsCustomersLink(customer.id)">
										<a class="btn btn-primary my-auto ml-3 btn-rounded" @click="channelResourceImport('customer', customer, false)">
										Import Customer Only
										</a>
										<a class="btn btn-primary my-auto ml-3 btn-rounded" @click="getChannelCustomer(customer.id)">
										Import Customer & Addresses
										</a>
									</div>
									<div v-else class="my-auto ml-auto">
										<a href="javascript:;" class="btn btn-light btn-rounded" v-b-modal.customer-details @click="showChannelLinkedCustomer(customer.id)">
											Customer Linked <i class="bx bx-check-circle text-success"></i>
										</a>
									</div>
              					</div>
              				</b-list-group-item>

              			</b-list-group>

						    <div class="d-inline-flex ml-auto w-100" v-if="channels_area_active == 'customer'">
          				<div v-if="(selected_channel_customers !== '') && 'customers' in selected_channel_customers" class="d-flex w-100">
							
							<a v-if="selected_channel_customers.customers.length == customerslimit" class="btn btn-light btn-rounded w-100" @click="customerslimit += 50, selected_channel_customers = '', getChannelCustomers()">
								Load More <i class="bx bx-chevron-right"></i>
							</a>
						</div>
						     
						  </div>
						    
              			<b-modal id="customer-details" hide-footer hide-header centered>
              				<b-card class="border-radius-1em border shadow-none" v-if="show_linked_customer_details !== ''">
              					<div class="d-flex">
              						<div class="avatar-md rounded-circle mr-3">
              							<img v-if="show_linked_customer_details.avatar !== null" :src="storageurl+show_linked_customer_details.avatar">
              							<span class="avatar-title avatar-md rounded-circle" v-else>
              								{{show_linked_customer_details.name.slice(0, 1)}}
              							</span>
              						</div>
              						<div class="d-flex flex-column my-auto">
              							<p class="mb-0">{{show_linked_customer_details.name}} {{show_linked_customer_details.lastname}}</p>
              							<a :href="'mailto:'+show_linked_customer_details.email">
              								<h3 class="mb-0">{{show_linked_customer_details.email}}</h3>
              							</a>
              							<a class="mt-3 btn btn-light btn-md btn-rounded" :href="'/customer/details/'+show_linked_customer_details.uid">
              								View Profile
              							</a>
              						</div>
              					</div>
              				</b-card>
              			</b-modal>
              		</b-card>
              	</b-overlay>
			</b-tab>
			<b-tab :title="selected_integration.integration_name + ' Orders'" title-link-class="text-capitalize" @click="getChannelOrders()">
				<b-overlay :show="loadingIntegrationOrders && loadingMultipleImport == false">
              				<template #overlay>
							<b-row>
			      			<b-col md="12" class="offset-md-2" >
			      			<b-card class="border-radius-1em border shadow-none mb-0">	
					            <div class="cube-wrapper mx-auto"><div class="cube-folding"><span class="leaf1"></span><span class="leaf2"></span><span class="leaf3"></span><span class="leaf4"></span></div></div>
					            <h6 class="text-center">{{integration_loading_text}}</h6>
					             <b-progress class="w-50 mx-auto" v-model="integration_loading_progress" variant="primary"></b-progress>
			         		</b-card>
			      			</b-col>
			      			</b-row>
			      		</template>
				<b-card class="border-radius-1em border shadow-none" body-class="p-0" v-if="selected_channel_orders !== ''">
              			<b-list-group flush v-if="'orders' in selected_channel_orders && selected_channel_orders.orders.length > 0" class="widget-scrollbox">

              				<b-list-group-item v-for="order in selected_channel_orders['orders']" class="d-flex">
              					<div class="d-flex flex-column my-auto mr-4">
              						<h6 class="mb-0">#{{order.id}}</h6>
              					</div>
              					<div class="d-flex flex-column my-auto mr-4 w-25">
              						<span class="badge badge-light bg-light mr-auto mb-2 font-size-12">#{{order.reference}}</span>
              						<h6 class="mb-0">{{order.customer.firstName}} {{order.customer.lastName}}</h6>
              						<a href="">{{order.customer.email}}</a>
              					</div>
              					<div class="d-flex my-auto mr-2 w-25" v-if="order.items.length > 0">
              						<div class="d-flex flex-column mr-auto">
              						<span class="badge badge-light bg-light mr-auto mb-2 font-size-12">#{{order.items[0].sku}}</span>
              						<p>{{order.items[0].name}}</p>
              						</div>
									<div class="d-flex flex-column mr-auto">
              						<span class="text-muted">QTY</span>	
              						{{order.items[0].quantity}}
              						</div>
              					</div>
              					<div class="d-flex flex-column my-auto mr-4 ml-auto">
              						<a class="btn btn-light btn-rounded mr-2" href="javascript:;" @click="selected_channel_order = order" v-b-modal.order-items>Order Items</a>
              						<a class="btn btn-light btn-rounded" href="javascript:;" @click="channelResourceImport('order', order, false)" >Import Order</a>
              						
              					</div>
              				</b-list-group-item>

              			</b-list-group>
              			
						<div class="d-inline-flex w-100" v-if="channels_area_active == 'order'">
          				<div v-if="(selected_channel_orders !== '') && 'orders' in selected_channel_orders" class="d-flex w-100">
						
							<a v-if="selected_channel_orders.orders.length == orderslimit" class="btn btn-light btn-rounded w-100" @click="orderslimit += 1000, getChannelOrders()">
								Load More <i class="bx bx-chevron-right"></i>
							</a>
						</div>
						    
						  </div>
              			<!--<b-pagination v-model="integrationorderspage">
              			</b-pagination>-->
              		</b-card>
              	</b-overlay>
			</b-tab>
					<!--<b-tab :title="'Matched Products'">
						  <b-card class="border-radius-1em border shadow-none" body-class="p-0">
						  	<b-list-group flush v-if="'data' in selected_channel_products && selected_channel_products.data.length > 0" class="widget-scrollbox">
						  		<b-list-group-item v-for="(product, key) in matchedProducts" class="d-flex">
						  			<div v-if="product.cover_image !== null">
									<b-img-lazy v-if="'thumb' in product.cover_image" :src="storageurl+(product.cover_image.thumb).replaceAll('//', '/')" class="avatar-sm rounded-circle mr-2" />
									<b-img-lazy v-else-if="'gallery' in product.cover_image" :src="storageurl+(product.cover_image.gallery).replaceAll('//', '/')" class="avatar-sm rounded-circle mr-2" />
									<b-img-lazy v-else-if="'src' in product.cover_image" :src="storageurl+(product.cover_image.src).replaceAll('//', '/')" class="avatar-sm rounded-circle mr-2" />
									<span v-else class="avatar-title avatar-md">
										{{product.name.slice(0, 1)}}
									</span>
								</div>
								<div class="d-flex flex-column my-auto">
									<span>SKU: {{product.reference}}</span>
									<h5 class="mb-0">{{product.name}}</h5>
								</div>
								<h5 class="my-auto ml-auto">{{product.price}}</h5>
								
								<a class="btn btn-danger my-auto ml-3 btn-rounded text-capitalize">
									Remove From {{selected_integration.channel_type}}
								</a>
								


						  		</b-list-group-item>
						  	</b-list-group>
						  </b-card>
              		</b-tab>-->
              	</b-tabs>
              </b-tab>
				</b-tabs>
			</b-overlay>
          </b-col>
      </b-row>
      	<b-modal id="manual-link" size="md" hide-footer hide-header centered>
      		<b-card class="border-radius-1em border shadow-none">
      			<h5>Link Product:</h5>
      			<b-card class="border-radius-1em border shadow-none">
      			<div class="d-flex" v-if="selected_product !== ''">
      				<div class="my-auto" v-if="selected_product.cover_image !== null">
						<b-img-lazy v-if="'thumb' in selected_product.cover_image" :src="storageurl+(selected_product.cover_image.thumb).replaceAll('//', '/')" class="avatar-sm rounded-circle mr-2" />
						<b-img-lazy v-else-if="'gallery' in selected_product.cover_image" :src="storageurl+(selected_product.cover_image.gallery).replaceAll('//', '/')" class="avatar-sm rounded-circle mr-2" />
						<b-img-lazy v-else-if="'src' in selected_product.cover_image" :src="storageurl+(selected_product.cover_image.src).replaceAll('//', '/')" class="avatar-sm rounded-circle mr-2" />
						<span v-else class="avatar-title avatar-md">
							{{selected_product.name.slice(0, 1)}}
						</span>
					</div>
					<div class="d-flex flex-column my-auto">
									<span>SKU: {{selected_product.reference}}</span>
									<h5 class="mb-0">{{selected_product.name}}</h5>
								</div>
				</div>
				</b-card>
      			<h5>Link to {{selected_integration.name}} Product</h5>

      			<b-card class="border-radius-1em border shadow-none" body-class="p-0" v-if="selected_channel_products !== ''">
              	
						<b-list-group flush v-if="'products' in selected_channel_products && selected_channel_products.products.length > 0" class="widget-scrollbox">
							<b-list-group-item v-for="(product, key) in selected_channel_products.products" class="d-flex">
								<b-img-lazy v-if="product.imageUrl !== null" :src="product.imageUrl" class="avatar-sm rounded-circle mr-2 my-auto" />
								
								<div class="d-flex flex-column my-auto">
									<div class="d-flex">
									<div class="d-flex flex-column my-auto">
									<span>SKU: {{product.sku}}</span>
									<h5 class="mb-0">{{product.name}}</h5>
									
									</div>	
									
									</div>
									
								</div>

								
								<div class="d-flex flex-column ml-auto">
									<a class="btn btn-primary my-1 ml-3 btn-rounded" v-if="checkGemesysLink(product) == true" @click="channelResourceLink(selected_product, product, false, 'product')">
										Link
									</a>
									<a class="btn btn-light my-1 ml-3 btn-rounded" v-else>
									<i class="bx bx-check-circle text-success"></i>
									Linked
									</a>
								</div>
							</b-list-group-item>
						</b-list-group>
					
			</b-card>
			<b-card v-else class="border-radius-1em border shadow-none">
				<p>No Products Loaded.</p>
				<a class="">Reload Integration Products</a>
			</b-card>
      		</b-card>
      	</b-modal>
      	<b-modal id="linked-product" size="md" hide-footer hide-header centered>
      		<b-card class="border-radius-1em border shadow-none">
      			<h4>Linked To:</h4>
      			<div class="d-flex flex-column" v-if="linked_product_view.length > 0">
      				<!--<div class="my-auto" v-if="linked_product_view[0].cover_image !== null">
						<b-img-lazy v-if="'thumb' in linked_product_view[0].cover_image" :src="storageurl+(linked_product_view[0].cover_image.thumb).replaceAll('//', '/')" class="avatar-sm rounded-circle mr-2" />
						<b-img-lazy v-else-if="'gallery' in linked_product_view[0].cover_image" :src="storageurl+(linked_product_view[0].cover_image.gallery).replaceAll('//', '/')" class="avatar-sm rounded-circle mr-2" />
						<b-img-lazy v-else-if="'src' in linked_product_view[0].cover_image" :src="storageurl+(linked_product_view[0].cover_image.src).replaceAll('//', '/')" class="avatar-sm rounded-circle mr-2" />
						<span v-else class="avatar-title avatar-md">
							{{linked_product_view[0].name.slice(0, 1)}}
						</span>
					</div>-->
					<b-card class="border-radius-1em border shadow-sm text-left mb-0 mt-3">
					<div class="mx-auto text-center">
						<b-img-lazy  v-if="linked_product_view[0].imageUrl !== null" :src="linked_product_view[0].imageUrl" class="avatar-md rounded-circle mt-3 mb-2" />
						<span v-else class="avatar-title avatar-md">
							{{linked_product_view[0].name.slice(0, 1)}}
						</span>
					</div>
					<div class="d-flex flex-column my-auto text-center">
									<span>SKU: {{linked_product_view[0].sku}}</span>
									<h5 class="mb-2">{{linked_product_view[0].name}}</h5>
									<p class="mb-2" v-html="linked_product_view[0].description"></p>
								</div>
								<div class="d-flex flex-column my-auto">
									<h6 class="spaced-title text-uppercase">Quantity</h6>
									<p class="mb-2" v-html="linked_product_view[0].quantity"></p>
									<h6 class="spaced-title text-uppercase">Price</h6>
									<p class="mb-0">{{parseFloat(linked_product_view[0].price).toFixed(2)}}</p>

									
								</div>
							</b-card>

				<b-card class="border-radius-1em border shadow-sm text-left mb-0 mt-3" body-class="d-flex">
										<div>
										<h5 class="text-capitalize">{{selected_integration.integration_name}} ID:</h5>
										<p class="mb-0">{{linked_product_view[0].id}}</p>
										</div>
										<a class="btn btn-primary ml-auto my-auto btn-rounded" :href="selected_integration.url" target="_blank">Go to {{selected_integration.integration_name}} <i class="bx bx-link-external"></i></a>
									</b-card>
				</div>
      		</b-card>
      	</b-modal>
      	<b-modal id="quantity" size="md" hide-footer hide-header centered>

      						<b-card class="border-radius-1em border shadow-none">
      							<h4 class="text-capitalize text-center">Add to {{selected_integration.integration_name}}</h4>
      							<div class="d-flex flex-column pt-3">
      								<div
				                        class="form-check form-switch form-switch-sm my-1 mx-auto"
				                        dir="ltr"
				                      >
				                      <input
				                          class="form-check-input"
				                          type="checkbox"
				                          :id="`SwitchCheckSize-synconline`"
				                          v-model="syncOnlineQty"
				                          value="1"
				                          unchecked-value="0"
				                          name="iscompany"
				                        />
				                        <label class="form-check-label" :for="`SwitchCheckSize-synconline`" 
				                          > Sync All Online Stock</label
				                        >
				                    </div>
				                    <div
				                        class="form-check form-switch form-switch-sm my-1 mx-auto"
				                        dir="ltr"
				                      >
				                      <input
				                          class="form-check-input"
				                          type="checkbox"
				                          :id="`SwitchCheckSize-syncall`"
				                          v-model="syncAllQty"
				                          value="1"
				                          unchecked-value="0"
				                          name="iscompany"
				                        />
				                        <label class="form-check-label" :for="`SwitchCheckSize-syncall`" 
				                          > Sync All Stock</label
				                        >
				                    </div>
				                    <div
				                        class="form-check form-switch form-switch-sm my-1 mx-auto"
				                        dir="ltr"
				                      >
				                      <input
				                          class="form-check-input"
				                          type="checkbox"
				                          :id="`SwitchCheckSize-addmanual`"
				                          v-model="addManualQty"
				                          value="1"
				                          unchecked-value="0"
				                          name="iscompany"
				                        />
				                        <label class="form-check-label" :for="`SwitchCheckSize-addmanual`" 
				                          > Add Quantity Manually</label
				                        >
				                    </div>
      							</div>
      							<div class="d-flex pt-3" v-if="addManualQty == true">
									<b-button variant="light" class="btn btn-light btn-rounded my-auto" @click="push_quantity--" :disabled="push_quantity < 1">
										<i class="bx bx-minus"></i>
									</b-button>

									<b-form-input type="number" v-model="push_quantity" class="my-auto mx-2">
									</b-form-input>

									<b-button variant="light" class="btn btn-light btn-rounded my-auto" @click="push_quantity++" >
										<i class="bx bx-plus"></i>
									</b-button>
								</div>
								<div class="d-flex flex-column pt-3">

									<b-form-group label="Select Category">
										<b-form-select class="form-control"  v-model="push_category">
											<b-form-select-option v-for="cat in selected_channel_categories.categories" :value="cat">
												{{cat.name}}
											</b-form-select-option>
										</b-form-select>
									</b-form-group>
								</div>
								<a class="btn btn-primary btn-rounded btn-block mt-3" @click="channelResourceExport('product', selected_product, false )">
									Push to {{selected_integration.channel_type}}
								</a>
							</b-card>
      </b-modal>
			</b-modal>
		<b-modal id="order-items" centered size="md" hide-footer header-class="py-4">
			<b-row v-if="selected_channel_order !== ''">
				<b-list-group flush v-if="'items' in selected_channel_order">
					<b-list-group-item v-for="items in selected_channel_order.items" class="d-flex">

              						<div class="d-flex flex-column mr-auto">
              						<span class="badge badge-light bg-light mr-auto mb-2 font-size-12">#{{items.sku}}</span>
              						<p>{{items.name}}</p>
              						</div>
									<div class="d-flex flex-column mr-auto">
              						<span class="text-muted">QTY</span>	
              						{{items.quantity}}
              						</div>
              				
					</b-list-group-item>
				</b-list-group>
			</b-row>
		</b-modal>
			
			<b-modal id="auth-details" centered size="md" hide-footer header-class="py-4">
				<b-row>
					<b-col  v-if="selected_auth !== ''">
						<div class="col-sm-12 pb-4 text-center mb-4">
            		<h3>Your {{selected_auth.courier}} Integration Details</h3>
            	</div>
						<div class="col-sm-12 pb-4 text-center border border-radius-1em">
                <div class="avatar-md profile-user-wid mb-2 mx-auto">
                  
                   <b-img-lazy 
                    :src="getShippingImage(selected_auth.courier)"
                    alt
                    class="img-thumbnail rounded-circle"
                  />
                </div>
              </div>
						<b-card class="border-radius-1em border shadow-none">

						<b-list-group flush>
							<b-list-group-item v-for="(data, key) in selected_auth.auth_details" class="d-flex">
								<h6 class="mr-auto">{{key}}:</h6> <span class="mr-auto">{{(data).slice(0,3)}} &bull;&bull;&bull;&bull;&bull;</span>
							</b-list-group-item>
						</b-list-group>
					</b-card>
					</b-col>
				</b-row>
			</b-modal>
			<b-modal id="integration-details" centered size="xl" hide-footer header-class="py-4">
				<b-row >
					<b-col md="4">
						<b-card class="border-radius-1em border shadow-none"  v-if="selected_integration_uid && selected_integration_uid !== ''">
							<b-img-lazy  :src="getChannelsImage(selected_integration.integration_name)" class="mx-auto mb-3" width="70px" />
            			<h3>{{selected_integration.integration_name}}</h3>
            			<a :href="selected_integration.url"><span class="badge badge-light bg-light font-size-12">
            				{{selected_integration.url}}
            			</span></a>
            			</b-card>
            		</b-col>
            			<!--<b-card v-if="selected_integration_uid !== ''">
            				<b-row>
            					<b-col> 
            						<h5 v-if="selected_integration.products !== null" >{{selected_integration.products.length}}</h5>
            						<p class="text-muted">Products Linked</p>
            					</b-col>

            					<b-col>
            						<h5 v-if="selected_integration.categories !== null" >{{selected_integration.categories.length}}</h5>
            						<p class="text-muted">Categories Linked</p>
            					</b-col>

            					<b-col>
            						<h5 v-if="selected_integration.products !== null" >{{selected_integration.products.length}}</h5>
            						<p class="text-muted">Customers Linked</p>
            					</b-col>
            				</b-row>

            			</b-card>-->
            				<b-col md="8">
            					<b-overlay :show="loadingWebhookSettings"  variant="white" opacity="0.95">
						<template #overlay>
							<b-row>
			      			<b-col md="12" class="offset-md-2" >
			      			<b-card class="border-radius-1em border shadow-none mb-0">	
					            <div class="cube-wrapper mx-auto"><div class="cube-folding"><span class="leaf1"></span><span class="leaf2"></span><span class="leaf3"></span><span class="leaf4"></span></div></div>
					            <h6 class="text-center">Loading Webhooks</h6>
					             <b-progress class="w-50 mx-auto" v-model="webhooks_progress" variant="primary"></b-progress>
			         		</b-card>
			      			</b-col>
			      			</b-row>
			      		</template>
            					<b-card class=" mt-3 mx-auto border border-radius-1em shadow-none"  v-if="webhooks_show == true && selected_integration_uid && selected_integration_uid !== ''">
							      					<h5>Active Resource Syncing</h5>
							      					<p>These settings manage changes made on your external channel on Gemesys via webhooks*. For example when Orders > Create is active your external system will create a new order on Gemesys when a new order is created on your e-commerce platform.</p>
								      				<b-form-group v-if="(active_channel_webhooks.length > 0 && 'webhooks' in active_channel_webhooks[0]) && (selected_channel_webhooks !== '' && 'webhooks' in selected_channel_webhooks)" v-for="(value, key, index) in active_channel_webhooks[0]['webhooks']" :label="key" class="text-capitalize mb-3">
								      					<div class="d-inline-block" v-for="subvalue in value">
								      					<a class="btn btn-light btn-rounded mr-2"  type="checkbox" @click="selected_integration.webhooks[key].push(subvalue)" v-if="filterWebhookActive(key, subvalue)" >
								      						
								      						{{subvalue}}

								      						
								      						<i class="bx bx-check-circle text-success" ></i>
								      						
								      					</a>

								      					<a class="btn btn-light btn-rounded mr-2"  type="checkbox" @click="activateWebhook(selected_integration, key, subvalue), selected_integration.webhooks[key].push(subvalue)" v-else >
								      						
								      						{{subvalue}}

								      						
								      						<i class="mdi mdi-plus" ></i>
								      						
								      					</a>
								      					</div>
								      				</b-form-group>
								      				<small>*certain e-commerce platforms require manual set-up of webhooks.</small>
							      				</b-card>
							      			
							      		
							      <b-card class=" mt-3 mx-auto border border-radius-1em shadow-none"  v-if="selected_channel_webhooks !== '' && show_webhook_errors.length == 0">
							      	<b-list-group v-if="'webhooks' in selected_channel_webhooks">
							      		<b-list-group-item v-for="hook in selected_channel_webhooks['webhooks']" class="d-flex">

							      			<span class="mr-auto">{{hook.resource}}</span>
							      			<span class="mr-auto">{{hook.action}}</span>

							      			<a class="btn btn-light mr-2 btn-rounded" title="Click to view errors" v-b-tooltip.hover-top @click="show_webhook_errors = hook.errors">Sync Errors: 
							      				<span class="badge badge-danger bg-danger">{{hook.errors.length}}</span>
							      			</a>
							      			<a class="btn btn-danger btn-rounded" @click="deactivateWebhook(hook.id)">
							      				Remove Sync <i class="bx bx-x"></i>
							      			</a>
							      		</b-list-group-item>
							      	</b-list-group>
							      </b-card>
							      <b-card v-else-if="show_webhook_errors.length > 0">
							      	<b-list-group-item v-for="hook in show_webhook_errors" class="d-flex">
							      	</b-list-group-item>
							      </b-card>
							      	<!--<a class="btn btn-primary btn-rounded w-100" @click="updateChannelSettings()" >
							      		Update Settings
							      	</a>-->
							      </b-overlay>
					</b-col>
				</b-row>

			</b-modal>
		</b-row>
		  <SideSlidePanel :editPanel="addNewDomain" :panelClass="'edit-sidepanel'" @close-panel="addNewDomain = !addNewDomain">     
        <form id="add-update" method="post" enctype="mutlipart/form-data">
            <b-row>
              <b-col md="2" class="my-3">
                          <h5 class="d-flex">Configure Domain <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
                        </b-col>
              <b-col md="10" class="my-3">
              <b-card class="shadow-none border-radius-1em border">
                <b-alert variant="warning" :show="(domain.domain_key !== '') && (domain.verified == 0)">
                      Your domain has not been verified yet. 
                    </b-alert>
                <b-form-group class="mb-2" label="Reference">
                  <b-form-input type="text" name="reference" v-model="domain.reference"></b-form-input>
                </b-form-group>
                <b-form-group class="mb-2" label="Name">
                  <b-form-input type="text" name="name" v-model="domain.name"></b-form-input>
                </b-form-group>
                <b-form-group class="mb-2" label="Description">
                  <b-form-textarea :rows="5" type="text" name="description" v-model="domain.description"></b-form-textarea>
                </b-form-group>
                <b-form-group class="mb-2" label="URL/Domain Name">
                  <b-form-input type="text" name="domain" v-model="domain.domain"></b-form-input>
                </b-form-group>
                 <div
                        class="form-check form-switch form-switch-sm mb-0 mx-auto p-0 mt-auto d-flex"
                        dir="ltr"
                      >
                      Default
                      <input
                          class="form-check-input mx-auto my-auto" 
                          type="checkbox"
                          id="SwitchCheckDefault"
                          v-model="domain.default"
                      
                          name="ssl"
                        />
                    </div>
                <div
                        class="form-check form-switch form-switch-sm mb-0 mx-auto p-0 mt-auto d-flex"
                        dir="ltr"
                      >
                      Enable SSL
                      <input
                          class="form-check-input mx-auto my-auto" 
                          type="checkbox"
                          id="SwitchCheckSsl"
                          v-model="domain.ssl_active"
                      
                          name="ssl"
                        />
                    </div>
              </b-card>

                  <b-card class="shadow-none bg-warning bg-soft border-radius-1em p-3 mt-2" v-if="(domain.domain_key !== '') && (domain.verified == 0)">
                    <h5>Domain Not Verified</h5>
                    

                    To verify your domain you need to add the following as an CNAME record to your domain DNS settings: 

                    <p class="well p-2">
                    <b-row class="mt-2">
                      <b-col md=3>
                        CNAME
                      </b-col>
                      <b-col md="9">
                         website.{{domain.domain_key}}.gemesys.co.uk
                       </b-col>
                    </b-row>
                    <b-row class="pb-0 my-2">
                      <b-col>
                        <a class="btn btn-primary btn-block btn-rounded" @click="domainLookup(domain.domain)">
                            Check Domain Verification
                        </a>
                      </b-col>
                    </b-row>
                    </p>

                  </b-card>
                <a class="btn btn-success btn-rounded btn-block" @click="addDomain()">
                  Start Setup
                </a>
              </b-col>
          
               <b-col>

              </b-col>
            </b-row>
            </form>
    </SideSlidePanel>
    <b-modal id="socialprofile" size="md" hide-footer hide-header centered>
    	<h4 class="mb-4 text-center"><span v-if="selected_social_action == 'create'">Add</span><span v-else>Update</span> Social Profile</h4>
      <b-row>
       <b-col md="12">
       		<b-card class="border-radius-1em border shadow-none mb-2" body-class="d-flex" v-if="selected_social_profile !== ''">
       		
       			<b-img-lazy :src="getSocialImage(selected_social_profile.name)" class="avatar-md" />
       			<div class="w-75 d-flex">
       				<h4 class="my-auto ml-3">{{selected_social_profile.name}}</h4>
       			</div>
       		</b-card>
       		<b-card  class="border-radius-1em border shadow-none" v-if="selected_social_action == 'create'">
       			<b-form-group class="mb-2" label="Profile / Page Url">
       				<b-form-input type="input" v-model="newsocial.url" >
       				</b-form-input>
       			</b-form-group>
       			<b-form-group class="mb-2" label="Username">
       				<b-form-input type="input" v-model="newsocial.username" >
       				</b-form-input>
       			</b-form-group>
       			<b-button variant="success" class="btn-rounded mt-2 btn-block" @click="editCompanySocialProfile(selected_social_action)">
       				Add Social Profile
       			</b-button>
       		</b-card>
       		<b-card  class="border-radius-1em border shadow-none" v-else-if="selected_social_action == 'update'">
       			<b-form-group class="mb-2" label="Profile / Page Url">
       				<b-form-input type="input" v-model="selected_social_profile.url" >
       				</b-form-input>
       			</b-form-group>
       			<b-form-group class="mb-2" label="Username">
       				<b-form-input type="input" v-model="selected_social_profile.username" >
       				</b-form-input>
       			</b-form-group>
       			<div
				                        class="form-check form-switch form-switch-sm my-1 mx-auto"
				                        dir="ltr"
				                      >
				                      <input
				                          class="form-check-input"
				                          type="checkbox"
				                          :id="`SwitchCheckSize-socactive`"
				                          v-model="selected_social_profile.active"
				                          value="1"
				                          unchecked-value="0"
				                          name="iscompany"
				                        />
				                        <label class="form-check-label" :for="`SwitchCheckSize-socactive`" 
				                          > Active</label
				                        >
				                    </div>
				                    <div
				                        class="form-check form-switch form-switch-sm my-1 mx-auto"
				                        dir="ltr"
				                      >
				                      <input
				                          class="form-check-input"
				                          type="checkbox"
				                          :id="`SwitchCheckSize-socpub`"
				                          v-model="selected_social_profile.public"
				                          value="1"
				                          unchecked-value="0"
				                          name="iscompany"
				                        />
				                        <label class="form-check-label" :for="`SwitchCheckSize-socpub`" 
				                          > Public</label
				                        >
				                    </div>
				                    <div
				                        class="form-check form-switch form-switch-sm my-1 mx-auto"
				                        dir="ltr"
				                      >
				                      <input
				                          class="form-check-input"
				                          type="checkbox"
				                          :id="`SwitchCheckSize-soctrade`"
				                          v-model="selected_social_profile.trade"
				                          value="1"
				                          unchecked-value="0"
				                          name="iscompany"
				                        />
				                        <label class="form-check-label" :for="`SwitchCheckSize-soctrade`" 
				                          > Trade</label
				                        >
				                    </div>
       			<b-button variant="success" class="btn-rounded mt-2 btn-block" @click="editCompanySocialProfile(selected_social_action)">
       				Update Social Profile
       			</b-button>
       		</b-card>
       </b-col>
   	   </b-row>
    </b-modal>
    <b-modal v-model="addNewThemeModal" centered content-class="border-radius-1em" header-class="border-none" hide-footer >
      <h4 class="mb-4 text-center">Add New Theme</h4>
      <b-row>
       <b-col md="12">
             <b-card class="border border-radius-1em shadow-none">
            <b-form-group label="Theme Name" class="mt-2">
                <b-form-input type="text" v-model="theme.name"></b-form-input>
              </b-form-group>
              <b-form-group label="Theme Description" class="mt-2">
                <b-form-textarea type="text" v-model="theme.description"></b-form-textarea>
              </b-form-group>
             <!-- <b-form-group label="theme Thumbnail" class="mt-2">
                <vue-dropzone id="dropzone" ref="myVueDropzone" :use-custom-slot="true" :options="dropzoneOptions" @vdropzone-file-added="imageAdded"  @vdropzone-drop="imageAdded">
                              <div class="dropzone-custom-content">
                                  <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                                  <h4>Drop files here or click to upload.</h4>
                              </div>
                              </vue-dropzone>
              </b-form-group>
              <input type="hidden" name="thumbnail" v-model="theme.thumbnail" />-->
              <b-form-group label="Active" class="mt-2">
                         <div
                                class="form-check form-switch form-switch-lg w-75 d-flex"
                                dir="ltr"
                              >
                         
                              <input
                                  class="form-check-input ml-auto my-auto" 
                                  type="checkbox"
                                  id="SwitchCheckSizesm"
                                 
                                  v-model="theme.active"
                                
                                  name="active"
                                />
                            </div>
              </b-form-group>
              </b-card>
            </b-col>
            <b-col md="12"  @click="createTheme()">
            <a class="btn btn-success btn-block btn-rounded">
              Add New Theme
            </a>
          </b-col>
          </b-row>
    </b-modal>
	</Layout>
</template>
<!--<style type="text/css" v-model="loadFonts"></style>-->
<style type="text/css">
	.v-html-p p{
		margin: 0px !important;
	}
</style>