<script>
import DateTime from 'luxon/src/datetime.js';
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import Swal from "sweetalert2";
import SideSlidePanel from "../../components/widgets/SideSlidePanel";
import SearchCompanyUser from "../../components/widgets/SearchCompanyUser";
import UserList from "../../components/widgets/UserList";
import SearchCompanyUsers from "../../components/widgets/SearchCompanyUsers";
import SearchCompanyDepartment from "../../components/widgets/SearchCompanyDepartment";
import Subscription from '../../components/widgets/subscription'
import vue2Dropzone from 'vue2-dropzone';
import Multiselect from "vue-multiselect";
import Emailer from "../../components/widgets/admin/email/Emailer"
import AddressLabel from "../../components/widgets/admin/print/AddressLabel";
import SearchCompanyCustomerAddress from "../../components/widgets/SearchCompanyCustomerAddress"

/**
 * Contacts-list component
 */
export default {
  components: { vueDropzone: vue2Dropzone, Layout, PageHeader, SideSlidePanel, SearchCompanyUser, UserList, SearchCompanyUsers, SearchCompanyDepartment, Subscription, DateTime, Multiselect, Emailer, AddressLabel, SearchCompanyCustomerAddress },
  data() {
    return {
      honorifics: [],
      deleteMode: 'single',
      addSupplierAddressPanel: false,
      editIndex: -1,
      printWindow: false,
      addressBulk: false,
      addressBulkOptions: false,
      addressLabel: '',
      addressLabelsList: [],
      action: 'add',
      title: "Users",
      view: 'list',
      userAddToDepartment: false,
      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        thumbnailHeight: 150,
        thumbnailWidth: 150,
        autoProcessQueue: false,
      },
      send_email: {
        id: '',
        notification_thread_id: '',
        email_to: [],
        email_cc: [],
        email_bcc: [],
        subject: '',
        message: '',
        replying_to: [],
      }, 
      users: [],
      load_error: [],
      currentPage: 1,
      limit: 25,
      selectedids: [],
      orderDesc: true,
      order: 'desc',
      sortby: 'id',
      search: '',
       delete_action: [],
      delete_confirm: false,
      deleted_message: false, 
      success_message: false,
      success_text: 'Your action has been successful.',
      delete_form_load: false, 
      error_message: false, 
      error_reponse: '',
      permissions_updating: false,
      timeout: null,
      formResponse: '',
      errorResponse: [],
      addFormSubmitted: false,
      updateFormSubmitted: false,
      loadingResource: true,
      addressesPanel: false,
      contactsPanel: false,
      addSupplierContactPanel: false,
      deleteContactPanel: false,
      deleteAddressPanel: false,
      updateManagerBtn: false,
      isCheckAll: false,
      newUser:{
        id: '',
        honorific_id: '',
        name: '',
        lastname: '',
        iscompany: 0,
        companyname: '',
        email: '',
        password: '',
        role_id: '',
        company_id: this.$attrs.company.id,
        active: 0,
        send_email: 0,
      },
      updateUser: {
        id: '',
        honorific_id: '',
        name: '',
        lastname: '',
        iscompany: 0,
        companyname: '',
        email: '',
        password: '',
        role_id: '',
        company_id: '',
        active: 0,
        send_email: 0,
      },
      customerAddresses: [],
      customerContacts: [],
      newsupplieraddress:{
        id: null,
        alias: '',
        address_1: '',
        address_2: '',
        address_3: '',
        county: '',
        country: '',
        city: '',
        postcode: '',
        active: 0,
        invoice_default: 0,
        delivery_default: 0,
        type: '',
      },
      editsupplieraddress: {
        id: null,
        alias: '',
        address_1: '',
        address_2: '',
        address_3: '',
        county: '',
        country: '',
        city: '',
        postcode: '',
        active: 0,
        invoice_default: 0,
        delivery_default: 0,
        type: '',
      },
      newsuppliercontact:{
        id: null,
        name: '',
        position: '',
        phone: '',
        email: '',
        mobile: '',
        fax: '',
        default: 0,
      },
      editsuppliercontact: {
        id: null,
        name: '',
        position: '',
        phone: '',
        email: '',
        mobile: '',
        fax: '',
        default: 0,
      },
      selectedListing: '',
      counties: [],
      countries: [],
      updatePanel: false,
      addPanel: false,
      viewPanel: false,
      show_subtooltip: false,
      limits: [
        {value: 25, text: "25"},
        {value: 50, text: "50"},
        {value: 100, text: "100"},
      ],
      items: [
        {
          text: "Company",
          href: "/dashboard"
        },
        {
          text: "Users",
          active: true
        }
      ]
    };
  },
  created(){
    if(this.$attrs.validsubscription == 0){
      this.title = 'Subscription';
      this.items[1].text = 'Subscription';
    }
    this.searchResource();
   // this.userRoles();
    this.getCountries();
    this.getHonorifics();
  },
  methods: {
    getHonorifics(){
      axios.get('/api/honorifics', {headers: {
              'Authorization' : 'Bearer '+this.$attrs.user.api_token
            }}).then(response => {
              this.honorifics = response.data;
            }).catch(error => {

            });
    },
    getCustomerAddresses(type){ 
      this.addressLabelsList = [];
        for(var i = 0; i < this.selectedids.length; i++){
          var uindex = this.users.data.findIndex(item => item.id == this.selectedids[i]);
          if(this.users.data[uindex]['default_'+type+'_address'] !== null){
            this.addressLabelsList.push(this.users.data[uindex]['default_'+type+'_address']);
          }
        }
    },
     imageAdded(file){
          if(this.action = 'add'){
              this.newUser.avatar = file;
           }else{
             this.updateUser.avatar = file;
           }
        },
    deleteUserEvent(action){
      this.delete_action = action;
      this.delete_confirm = true;
    },
    getCountries(){
        if(this.countries.length == 0){
          axios.get('/api/countries', {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
              }}).then(response => {
            this.countries = response.data;
          }).catch(error => {
            this.load_error = error.data;
          })
        }
      },
      getCounties(country){
        // get counties as country selected
        axios.get('/api/country/'+country.id+'/counties', {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
              }}).then(response => {
          this.counties = response.data;
        }).catch(error => {
          this.load_error = error.data;
        })
      },
    deleteUser(action){
      this.delete_form_load = true;
      axios.post('/api/company/customer/'+this.selectedids[0]+'/destroy', {}, {headers: {
              'Authorization' : 'Bearer '+this.$attrs.user.api_token
            }}).then(response => {
         
                var self = this;
                setTimeout(function(){
                 //Vue.swal("Avatar Updated");
                 self.delete_confirm = false;
                 self.deleted_message = true;
                 self.delete_form_load =false;
                 return self.searchResource();
                }, 500);
          }).catch(error => {
        this.error_message = true;
      });
    },
    deleteDepartment(value) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ml-2"
        },
        buttonsStyling: false
      });

      swalWithBootstrapButtons
        .fire({
          title: "Are you sure you want to delete the user: "+value.name+" "+value.lastname+"?",
          text: "You won't be able to revert this!",
          icon: "warning",
          confirmButtonText: "Yes, delete it!",
          cancelButtonText: "No, cancel!",
          showCancelButton: true
        })
        .then(result => {
          if (result.value) {
            axios.post('/api/company/customer/'+value.id+'/destroy', {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
              }}).then(response =>{
              if(response.data == true){
                    swalWithBootstrapButtons.fire(
                  "Deleted!",
                  "Your "+value.name+" has been deleted.",
                  "success"
                );
                  }else{
                    swalWithBootstrapButtons.fire(
                  "Could Not Delete Item",
                  "Pleae Try Again. If this problem persists, contact our tech department",
                  "success"
                );
              }
            })
            
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
            swalWithBootstrapButtons.fire(
              "Cancelled",
              "You have cancelled this operation",
              "error"
            );
          }
        });
    },
    setActive(active){
            axios.post('/api/company/customer/set-active', {ids: this.selectedids, active: active}, {headers: {
              'Authorization' : 'Bearer '+this.$attrs.user.api_token
            }}).then(response => {
              
              this.success_text = 'Congratulations, your action has been saved';
              this.success_message = true;
              if(this.selectedids.length > 1){
                return this.searchResource();
              }else{
                return true;
              }
                
            }).catch(error => {

            });
    },
    setAddressActive(active){
            axios.post('/api/company/customer/address/set-active', {ids: this.selectedids, customer_id: this.selectedListing.id, active: active}, {headers: {
              'Authorization' : 'Bearer '+this.$attrs.user.api_token
            }}).then(response => {
              
              this.success_text = 'Congratulations, your action has been saved';
              this.success_message = true;
              if(this.selectedids.length > 1){
                return this.searchResource();
              }else{
                return true;
              }
                
            }).catch(error => {

            });
    },
    bulkDestroyResource(){
            axios.post('/api/company/customer/bulk-destroy', {ids: this.selectedids}, {headers: {
              'Authorization' : 'Bearer '+this.$attrs.user.api_token
            }}).then(response => {
                this.$bvModal.hide('delete-modal');     
                this.selectedids = [];
                return this.searchResource();
            }).catch(error => {

            });
     },
     bulkDuplicateResource(){
      axios.post('/api/company/customer/duplicate', {ids: this.selectedids}, {headers : {
        'Authorization' : 'Bearer '+this.$attrs.user.api_token
      }}).then(response => {
        console.log(response);
        this.success_text = 'Congratulations, your collection(s) have been updated.';
        this.success_message = true;
        //this.resetProjectData();
        this.addPanel = false;
        let self = this;
          setTimeout(function(){
            self.loadingForm = false;
            self.searchResource();
            //self.$bvModal.hide('move-modal');
          }, 1500);
      }).catch( error => {
        
        this.error_reponse = error.response.data.errors;
        this.error_message = true;
        //this.resetProjectData();
        let self = this;
          setTimeout(function(){
            self.loadingForm = false;
          }, 1500);
      });
    },
    userRoles(){
      axios.get('/api/user/roles', {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
              }}).then(response => {
        this.user_roles = response.data;
      }).catch(error => {
        this.load_error = error.data;
      });
    },
    searchResource(){
      axios.get('/api/company/customers?page='+this.currentPage+'&limit='+this.limit+'&sortby='+this.sortby+'&order='+this.order+'&search='+this.search+'&list=full&showp=true', {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
              }}).then(response => {
            this.users = response.data;
            this.setTimeoutShort(() => {
              this.loadingResource = false;
            });
      }).catch(error => {
            this.load_error = error.data;
            this.setTimeoutShort(() => {
              this.loadingResource = false;
            });
      });
    },
    addSupplierAddress(){
        this.loadingForm = true;
      var postForm = new FormData(document.getElementById("addsupplieraddressform"));
      postForm.append('country_id', this.newsupplieraddress.country.id);
      if(this.newsupplieraddress.county !== ''){
        postForm.append('county_id', this.newsupplieraddress.county.id);
      }
      axios.post('/api/company/customer/'+this.selectedListing.id+'/address/create', postForm, {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
              }}).then(response => {
        console.log(response);
        this.success_text = 'Congratulations, the customer address has been added.';
        this.success_message = true;
        this.customerAddresses.push(response.data);
        let self = this;
          setTimeout(function(){
            self.loadingForm = false;
            //self.addPanel = false;
            self.addSupplierAddressPanel = false;
            return self.searchResource();
          }, 1500);
      }).catch( error => {
        
        this.error_reponse = error.response.data.errors;
        this.error_message = true;
        let self = this;
          setTimeout(function(){
            self.loadingForm = false;
          }, 1500);
      });
    },
    updateSupplierAddress(){
      this.loadingForm = true;
      var postForm = new FormData(document.getElementById("updatesupplieraddressform"));
      postForm.append('country_id', this.editsupplieraddress.country.id);
        if( (this.editsupplieraddress.county !== null)) {
          postForm.append('county_id', this.editsupplieraddress.county.id);
        }
      
      axios.post('/api/company/customer/address/'+this.editsupplieraddress.id+'/update', postForm, {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
              }}).then(response => {
        console.log(response);
        this.success_text = 'Congratulations, the customer address has been updated.';
        this.success_message = true;
        let self = this;
          setTimeout(function(){
            self.loadingForm = false;
            self.searchResource();
            self.resetAddressData();
          }, 1500);
      }).catch( error => {
        
        this.error_reponse = error.response.data.errors;
        this.error_message = true;
        //this.resetProjectData();
        let self = this;
          setTimeout(function(){
            self.loadingForm = false;
          }, 1500);
      });
    },
    resetAddressData(){
        this.editsupplieraddress = {
        id: null,
        alias: '',
        address1: '',
        address2: '',
        address3: '',
        county: '',
        country: '',
        city: '',
        postcode: '',
        default: 0,
        invoice: 0,
        delivery: 0,
        type: '',
      };
    },
    destroySupplierAddress(id, index){
        axios.post('/api/company/customer/address/'+id+'/destroy', {}, {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
              }}).then(response => {
            this.success_text = 'Congratulations, the customer address has been deleted.';
            this.success_message = true;
            this.deleteAddressPanel = false;
            return this.searchResource();
        }).catch(error => {
            this.error_reponse = {error: ["Error - could not delete. Please try again."]};
            this.error_message = true;
        });
    },
    addSupplierContact(){
        this.loadingForm = true;
      var postForm = new FormData(document.getElementById("addsuppliercontactform"));
      axios.post('/api/company/customer/'+this.selectedListing.id+'/contact/create', postForm, {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
              }}).then(response => {
        console.log(response);
        this.success_text = 'Congratulations, the supplier contact has been added.';
        this.success_message = true;
        this.customerContacts.push(response.data);
        let self = this;
          setTimeout(function(){
            self.loadingForm = false;
            self.addSupplierContactPanel = false;
            self.searchResource();
          }, 1500);
      }).catch( error => {
        
        this.error_reponse = error.response.data.errors;
        this.error_message = true;
        //this.resetProjectData();
        let self = this;
          setTimeout(function(){
            self.loadingForm = false;
          }, 1500);
      });
    },
    updateSupplierContact(){
           this.loadingForm = true;
      var postForm = new FormData(document.getElementById("updatesupplieraddressform"));
      axios.post('/api/company/customer/contact/'+this.editsuppliercontact.id+'/update', postForm, {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
              }}).then(response => {
        console.log(response);
        this.success_text = 'Congratulations, the supplier contact has been updated.';
        this.success_message = true;
        
        //this.addPanel = false;
        let self = this;
          setTimeout(function(){
            self.loadingForm = false;
            self.addPanel = false;
            self.searchResource();
            self.resetAddressData();
          }, 1500);
      }).catch( error => {
        
        this.error_reponse = error.response.data.errors;
        this.error_message = true;
        //this.resetProjectData();
        let self = this;
          setTimeout(function(){
            self.loadingForm = false;
          }, 1500);
      });
    },
    destroySupplierContact(id, index){
         axios.post('/api/company/customer/contact/'+id+'/destroy', {}, {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
              }}).then(response => {
            this.success_text = 'Congratulations, the supplier contact has been deleted.';
            this.success_message = true;
            this.deleteContactPanel = false;
            return this.searchResource();
        }).catch(error => {
            this.error_reponse = {error: ["Error - could not delete. Please try again."]};
            this.error_message = true;
        });
    },
    searchFilter(e) {
      const searchStr = e.target.value;
      this.searchResource();
    },
    setTimeout(callback) {
        this.clearTimeout()
        this.timeout = setTimeout(() => {
          this.clearTimeout()
          callback()
        }, 1500)
    },
    setTimeoutShort(callback) {
        this.clearTimeout()
        this.timeout = setTimeout(() => {
          this.clearTimeout()
          callback()
        }, 500)
    },
    clearTimeout() {
        if (this.timeout) {
          clearTimeout(this.timeout)
          this.timeout = null;
        }
    },
    generatePassword(type){
      if(type == 'update'){
        this.updateUser.password = Array(14).fill('0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz').map(function(x) { return x[Math.floor(Math.random() * x.length)] }).join('');
      }else if(type == 'new'){
        this.newUser.password = Array(14).fill('0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz').map(function(x) { return x[Math.floor(Math.random() * x.length)] }).join('');
      }
    },
    addNewUser(){
      this.addFormSubmitted = true;
      axios.post('/api/company/customer/create', this.newUser, {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
              }}).then(response => {
            this.formResponse = response.data;
             this.setTimeout(() => {
          this.addFormSubmitted = false;
          this.success_message = true;
          this.newUser = {
              id: '',
              name: '',
              lastname: '',
              iscompany: 0,
              companyname: '',
              email: '',
              password: '',
              role_id: '',
              company_id: this.$attrs.company.id,
              active: 0,
            };
        });  
            let self = this;
          setTimeout(function(){
            self.loadingForm = false;
            self.addPanel = false;
            return self.searchResource();
          }, 500);
      }).catch(error => {
            this.error_reponse = error.response.data.errors;
            this.error_message = true;
            this.setTimeout(() => {
            this.addFormSubmitted = false;
          });
      });
    },
    updateExistingUser(){
      this.loadingForm = true;
        axios.post('/api/company/customer/'+this.updateUser.id+'/update', {user: this.updateUser}, {headers: {
              'Authorization' : 'Bearer '+this.$attrs.user.api_token
            }}).then(response => {
          this.formResponse = response.data;
        
         let self = this;
          setTimeout(function(){
            self.loadingForm = false;
            self.updatePanel = false;
            return self.searchResource();
          }, 500);
        }).catch(error => {
          this.errorResponse = error.data; 
        });
      },
      editUser(user){
        this.updateUser = user;
        this.updatePanel = true;
      },
      getManager(selectedOption){
        this.newLocation.manager_id = selectedOption.companies[0].id;
        this.updateLocation.manager_id = selectedOption.companies[0].id;
      },
      getDepartment(selectedOption){
        this.newLocation.company_department_id = selectedOption.id;
        this.updateLocation.company_department_id = selectedOption.id;
      },
      updatePermissions(userrole){
        console.log(userrole);
        if(this.addPanel == true){
          var role = this.user_roles.filter(item => item.id == userrole);
          this.newUser.permissions = role[0].default_permissions;
        }else if(this.updatePanel == true){
          var role = this.user_roles.filter(item => item.id == userrole);
          this.updateUser.permissions = role[0].default_permissions;
        }
      },
      permissionsUpdated(userrole){
          var role = this.user_roles.filter(item => item.id == userrole);
          this.updateUser.permissions = role[0].default_permissions;
      },
      filterSelected(id){
        if(this.selectedids.length > 0){
          var exists = this.selectedids.filter(item => item == id);
          if(exists.length > 0){
            return true;
          }else{
            return false
          }
        }else{
          return false;
        }
      },
      getDate(date){
        var createdOn = DateTime.fromISO(date, { zone: 'utc'});
        console.log(createdOn);
        return createdOn.day+' '+createdOn.monthLong+' '+createdOn.weekYear+'';
      },
      checkAll: function(){ 
                 this.isCheckAll = !this.isCheckAll; 
                 this.selectedids = []; 
                 if(this.isCheckAll){ 
                     // Check all 
                     for (var key in this.users.data) {          
                        this.selectedids.push(this.users.data[key].id); 
                     } 
                 } 
              }, 
      updateCheckall: function(){ 
                   if(this.selectedids.length == this.users.data.length){
                      this.isCheckAll = true; 
                   }else{ 
                      this.isCheckAll = false; 
                   } 
      },

  }
};
</script>

<template>
  <Layout  :nickname="$attrs.nickname" :user="$attrs.user" :menuItems="$attrs.menuItems" >
    <PageHeader :title="title" :items="items" />
    <div class="row mb-3">
       <b-col md="12">
                    <transition name="slide-success" mode="out-in">
                  <b-alert id="success-message" variant="success" :show="success_message"  dismissible @dismissed="success_message = !success_message">
                      <p class="font-size-14">{{success_text}}</p>
                    </b-alert>
                  </transition>
                  </b-col>
          <div class="col-xl-1 col-sm-6">
            <div class="mt-2">
              <b-form-select class="form-control" v-model="limit" :options="limits" @input="searchResource()"></b-form-select>
            </div>
          </div>
         
          <div class="col-lg-2 col-sm-6 d-flex">
          <form class="my-auto mr-auto">
            <small class="mx-auto my-2 text-center d-block">Results {{users.from}} - {{users.to}} of {{users.total}}</small>
          </form>
          <div v-if="view == 'grid'" class="text-center my-auto ml-auto"><div
                        class="form-check form-switch form-switch-sm mb-0 mr-auto p-0 mt-auto d-flex"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input mx-auto my-auto" 
                          type="checkbox"
                          id="SwitchCheckSizeCheckall"
                         
                          v-model="isCheckAll"
                          @click='checkAll()'
                          name="active"
                        />
                        <label class="d-inline-block my-auto ml-2" for="SwitchCheckSizeCheckall">Select All</label>
                    </div></div>
        </div>
        <div class="col-lg-5 col-sm-6 d-flex">
            <div class="mt-sm-0 form-inline w-100" >
              <div class="search-box mr-2 ml-auto">
                <div class="position-relative">
                  <input
                    type="text"
                    class="form-control w-100 border-0"
                    placeholder="Search name, reference or description..."
                    v-model="search"
                    @keyup.enter="searchFilter($event)"
                  />
                  <i class="bx bx-search-alt search-icon"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-sm-6 d-flex">
            <div class="d-flex ml-auto">
              <ul class="nav nav-pills product-view-nav">
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': showFilters == true }" href="javascript: void(0);" @click="showFilters = !showFilters">
                    <i class="bx bx-filter-alt"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': view == 'grid' }" href="javascript: void(0);" @click="view = 'grid'">
                    <i class="bx bx-grid-alt"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': view == 'list' }" href="javascript: void(0);" @click="view = 'list'">
                    <i class="bx bx-list-ul"></i>
                  </a>
                </li>
                <li class="nav-item">
                 <b-dropdown text="Bulk Actions" variant="primary" class="rounded">
                    <b-dropdown-item href="javascript: void(0);" @click="setActive(true)"><i class="bx bx-check-circle font-size-14 text-success"></i> Set Active</b-dropdown-item>
                  <b-dropdown-item href="javascript: void(0);" @click="setActive(false)"><i class="bx bx-x-circle font-size-14 text-danger"></i> Set Inactive</b-dropdown-item>
                  <!--<b-dropdown-item href="javascript: void(0);" @click="bulkDuplicateResource()"><i class="bx bx-copy-alt font-size-14"></i> Duplicate</b-dropdown-item>-->
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-item href="javascript: void(0);" @click="addressLabelsList = [], addressBulk = true, addressBulkOptions = true, printWindow = !printWindow"><i class="bx bx-printer font-size-14"></i> Print Address Labels</b-dropdown-item>
                  <b-dropdown-item href="javascript: void(0);" ><i class="bx bx-mail-send font-size-14"></i> Send Email</b-dropdown-item>
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-item href="javascript: void(0);" v-b-modal.delete-modal @click="deleteMode = 'bulk'"><i class="bx bx-trash-alt font-size-14"></i> Delete</b-dropdown-item>
                </b-dropdown>
                </li>
                <li class="nav-item">
                 <b-button
                    variant="success"
                    class="btn btn-success btn-rounded mb-2 mr-2 text-white"
                    href="javascript:;"
                    :disabled="!$attrs.user.companies[0].permissions.add.includes($attrs.active_menu.id)"
                    @click="addPanel = !addPanel, action = 'add'"
                  >
                    <i class="mdi mdi-plus mr-1"></i> New
                  </b-button>
                </li>
              </ul>
              <div class="text-sm-right">
                  
                </div>
          </div>
        </div>
      </div>
          <div class="row">
       <div class="col-md-12">
        <b-modal id="delete-modal" v-model="delete_confirm"  size="md" hide-footer hide-header centered>
        <b-card class="border-radius-1em border shadow-none">
          <h4>Are you sure?</h4>
          <p v-if="deleteMode == 'bulk'">You are about to delete {{selectedids.length}} customers. Are you sure you want to do this? This action is not reversable</p>
           <p v-if="deleteMode == 'single'">You are about to delete this customer. Are you sure you want to do this? This action is not reversable</p>

           <div class="d-flex">
            <a class="btn btn-danger" v-if="deleteMode == 'bulk'" @click="bulkDestroyResource">
              Delete
            </a>
             <a class="btn btn-danger"  v-if="deleteMode == 'single'" @click="deleteUser(delete_action)">
              Delete
            </a>
            <a class="btn btn-success" @click="$bvModal.hide('delete-modal')">
              Cancel
            </a>
          </div>
        </b-card>
      </b-modal>
          <!--<b-modal id="delete-modal" v-model="delete_confirm" hide-footer hide-header centered>
             <b-overlay
                        id="report-generating-overlay"
                        :show="delete_form_load"
                        variant="white"
                        opacity="0.85"
                        blur="1px"
                        rounded="md"
                        class="w-100"
                      >
          <div class="col-md-12 confirm-delete d-flex h-100" v-if="delete_confirm == true">
            <div class="card shadow-none my-auto mx-auto pb-3 text-center">
              <div class="card-body">
              <h4>Are You Sure?</h4>
              <p>You Are About to Delete User <strong>{{delete_action.name}}</strong>.</p>

            
              <a class="btn btn-success float-left" @click="deleteUser(delete_action)">Confirm</a>
              <a class="btn btn-danger float-right" @click="delete_confirm = false, delete_action = []">Cancel!</a>
            </div>
            </div>
          </div>
          <div class="col-md-12 confirm-delete d-flex h-100" v-if="deleted_message == true">
            <div class="card my-auto mx-auto pb-3 text-center">
              <div class="card-body">
              <h4>User Deleted</h4>
              <p>Your user has been deleted.</p>
              <a class="btn btn-success mx-auto text-center" @click="delete_confirm = false, deleted_message = false">Close</a>
            </div>
            </div>
          </div>
        </b-overlay>
        </b-modal>-->
      </div>
    </div>
    <div class="d-flex flex-column justify-content-center h-100 restricted-col" v-if="($attrs.user.companies[0].userrole.name !== 'Owner') && !$attrs.user.companies[0].permissions.read.includes($attrs.active_menu.id)" >
          <AccessRestricted :page="false"></AccessRestricted>
        </div>
        <div v-else>
    <div class="row" v-if="view == 'list'">
      <div class="col-lg-12">
        <div class="card">
          <div class="">
            <b-overlay
                        id="overlay"
                        :show="loadingResource"
                        variant="white"
                        opacity="0.85"
                        blur="1px"
                        rounded="md"
                        class="w-100"
                      >
            <div class="table-responsive">
              <table class="table table-centered table-nowrap table-hover">
                <thead>
                  <tr>
                    <th scope="col" style="width: 100px" class="text-center">
                     <div
                        class="form-check form-switch form-switch-sm mb-0 mx-auto p-0 mt-auto d-flex"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input mx-auto my-auto" 
                          type="checkbox"
                          id="SwitchCheckSizesm"
                         
                          v-model="isCheckAll"
                          @click='checkAll()'
                          name="active"
                        />
                    </div></th>
                    <th scope="col">Firstname</th>
                    <th scope="col">Lastname</th>
                    <th scope="col">Email</th>
                    <!--<th scope="col">Contacts</th>-->
                    <th scope="col">Addresses</th>
                    <th scope="col">Contacts</th>
                    <th scope="col">Orders</th>
                    <!--<th scope="col">Subcriber</th>-->
                    <th scope="col">Registration Date</th>
                    <th scope="col">Active</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="user in users.data" :key="user.id" valign="middle">
                    <td>

                      <div class="text-center" v-if="user.avatar !== null">
                        <b-form-checkbox class="select-row-btn" v-model="selectedids" :value="user.id" button-variant="primary" button>

                          <i v-if="filterSelected(user.id)" class="bx bx-check"></i><img v-else :src="user.avatar" class="rounded-circle avatar-xs" style="width: 40px; height: 40px"></b-form-checkbox>
                      </div>
                       <div class="text-center" v-else >
                        <b-form-checkbox class="select-row-btn" v-model="selectedids" :value="user.id" button-variant="light" @input="updateSelected" button>

                          <i v-if="filterSelected(user.id)" class="bx bx-check"></i><span class="avatar-title rounded-circle" v-else>{{(user.name).slice(0,1)}}</span></b-form-checkbox>
                    </div> 
                    </td>
                    <td>
                      <h5 class="font-size-14 m-0 p-0">
                        <a href="#" class="text-dark m-0 p-0" :href="'/customer/details/'+user.uid">{{user.name}}</a>
                      </h5>
                    </td>
                    <td>
                      <h5 class="font-size-14 m-0 p-0">
                        <a href="#" class="text-dark m-0 p-0" :href="'/customer/details/'+user.uid">{{user.lastname}}</a>
                      </h5>
                    </td>
                    <td>
                     {{user.email}}
                     </td>
                    <!--<td><a href="javascript:;" class="btn btn-outline-light btn-rounded"><i class="bx bx-phone"></i></a></td> -->
                    <td>
                      <a href="javascript:;" v-b-tooltip.hover title="Addresses" placement="left" @click="selectedListing = user, customerAddresses = user.addresses, addressesPanel = true" class="btn btn-outline-light btn-rounded"><i class="bx bx-home-alt"></i></a>

                    </td>
                    <td>
                      <a href="javascript:;" v-b-tooltip.hover title="Contacts" placement="left" @click="selectedListing = user, customerContacts = user.contacts, contactsPanel = true" class="btn btn-outline-light btn-rounded"><i class="bx bx-phone"></i></a>

                    </td>
                    <td><a href="javascript:;" class="btn btn-outline-light btn-rounded"><i class="bx bx-purchase-tag-alt"></i></a></td>
                    <!--<td>
                    <div
                        class="form-check form-switch form-switch-sm my-auto ml-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          :id="`SwitchCheckSize-${user.id}`"
                       
                          value="1"
                          unchecked-value="0"
                          name="active"
                        />
                        <label class="form-check-label" :for="`SwitchCheckSize-${user.id}`" 
                          ></label
                        >
                    </div>
                    </td>-->
                    <td>
                      
                      {{getDate(user.created_at) }}
                    </td>
                    <td>
                      <div
                        class="form-check form-switch form-switch-sm my-auto ml-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          :id="`SwitchCheckSize-${user.id}`"
                          v-model="user.active"
                          :value="user.active"
                          name="active"
                          @click="selectedids = [], selectedids.push(user.id), setActive(!user.active)"
                        />
                        <label class="form-check-label" :for="`SwitchCheckSize-${user.id}`" 
                          ></label
                        >
                    </div>

                    </td>
                    
                    <td>
                      <ul class="list-inline font-size-20 contact-links mb-0">
                        <li class="list-inline-item px-1">
                          <a v-b-tooltip.hover title="Edit" href="javascript:;" @click="action = 'update', editUser(user)">
                            <i class="bx bx-edit"></i>
                          </a>
                        </li>
                        <li class="list-inline-item px-1">
                          <a v-b-tooltip.hover title="View" :href="'/customer/details/'+user.uid">
                            <i class="mdi mdi-eye-outline"></i>
                          </a>
                        </li>
                        <!--<li class="list-inline-item px-1">
                          <a v-b-tooltip.hover title="Reports">
                            <i class="bx bx-line-chart"></i>
                          </a>
                        </li>-->
                        <li class="list-inline-item px-1">
                          <a v-b-tooltip.hover title="Delete" @click="selectedids = [], selectedids.push(user.id), deleteUserEvent(user)">
                            <i class="bx bx-trash-alt"></i>
                          </a>
                        </li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </b-overlay>
            <div class="row">
              <div class="col-lg-12">
                 <b-pagination
              class="justify-content-center"
              pills
              v-model="currentPage"
              :total-rows="users.total"
              :per-page="users.per_page"
              aria-controls="my-table"
              @input="searchResource"
            ></b-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="view == 'grid'">
      <div class="col-lg-12">
        <div class="card bg-transparent shadow-none">
          <div class="card-body p-0">
            <div class="row">
            <div v-for="(location, index) in users.data" :key="location.id" class="col-xl-4 col-sm-6">
        <div class="card text-center" @mouseenter="editIndex = index" @mouseleave="editIndex = -1">
           <div class="bg-soft-primary bg-light border-radius-top-right-1em border-radius-top-left-1em" style="min-height: 150px;">
            <div class="row">
              
              <div class="col-12 align-self-end">
                <div class="row">
                 <div class="col-md-12 position-absolute z-index-5">
                <transition name="fade-top">
                  <div v-if="editIndex == index">
                    <a  :href="'/customer/details/'+location.uid" class="mt-3 ml-3 btn btn-primary font-size-16 btn-rounded ml-auto float-left"><i class="mdi mdi-eye"></i></a>
                  </div>
              </transition>   
                <transition name="fade-top">
                  <div v-if="editIndex == index">
                    <a href="javascript:;" @click="action = 'update', editUser(location)" class="mt-3 mr-3 btn btn-primary font-size-16 btn-rounded ml-auto float-right"><i class="bx bx-pencil"></i></a>
                  </div>
              </transition>
              </div>
              </div>
                
              </div>
            </div>
          </div>


          <div class="card-body pt-0">
             <div class="row">
              <div class="col-sm-12 text-center px-4 mb-2">
          <div class="avatar-sm profile-user-wid mx-auto">
                   <div class="text-center" v-if="location.avatar">
                      <b-form-checkbox class="select-row-btn" v-model="selectedids" :value="location.id" button-variant="primary" @input="updateSelected" button>
                        <i v-if="filterSelected(location.id)" class="bx bx-check"></i>
                        <img v-else :src="`${location.avatar}`" alt class="avatar-sm img-thumbnail rounded-circle h-100" />
                      </b-form-checkbox>
                    </div>
                    <div class="text-center" v-else >
                        <b-form-checkbox class="select-row-btn" v-model="selectedids" :value="location.id" button-variant="primary" @input="updateSelected" button>

                          <i v-if="filterSelected(location.id)" class="bx bx-check"></i><span class="avatar-title rounded-circle" v-else>{{(location.name).slice(0,1)}}</span></b-form-checkbox>
                    </div> 
                </div>
              </div>
            </div>
            <h4 class="font-size-18 text-truncate mt-3">{{location.name}} {{location.lastname}}</h4>
                <a class="text-muted mb-0 text-truncate" v-html="location.email"></a>
           
          </div>
          <div class="card-footer bg-transparent border-top">
           <div class="d-flex">
                   
                  <a href="javascript:;" v-b-tooltip.hover title="Addresses" placement="left" @click="selectedListing = location, customerAddresses = location.addresses, addressesPanel = true" class="btn btn-outline-light btn-rounded"><i class="bx bx-home-alt"></i></a>
                  <a href="javascript:;" v-b-tooltip.hover title="Contacts" placement="left" @click="selectedListing = location, customerContacts = location.contacts, contactsPanel = true" class="btn btn-outline-light btn-rounded ml-2"><i class="bx bx-phone"></i></a>
                        <a href="javascript:;" class="btn btn-outline-light btn-rounded ml-2"><i class="bx bx-purchase-tag-alt"></i></a>
                    <b-dropdown  placement="left" variant="outline-light" dropup class="more-options-dropdown dropup btn-rounded ml-2" menu-class="dropdown-menu-left  ml-2">
                        <template v-slot:button-content>
                           <i class="bx bx-dots-horizontal-rounded"></i>
                        </template>
                       
                          <b-dropdown-item href="javascript: void(0);" @click="addressLabelsList = [], selectedListing = location, addressBulk = false, addressBulkOptions = false, printWindow = !printWindow"><i class="bx bx-printer font-size-14"></i> Print Address Label</b-dropdown-item>
                          <b-dropdown-item href="javascript: void(0);" v-b-modal.send-email @click="send_email.email_to.push(location.email)"><i class="bx bx-mail-send font-size-14"></i> Send Email</b-dropdown-item>

                           <b-dropdown-divider></b-dropdown-divider>
                          <!--<b-dropdown-item href="javascript: void(0);"  @click="selectedids = [], selectedids.push(location.id), bulkDuplicateResource()"><i class="bx bx-copy-alt font-size-14"></i>  Duplicate</b-dropdown-item>-->

                          <b-dropdown-item href="javascript: void(0);"  @click="selectedids = [], selectedids.push(location.id), deleteMode = 'single'" v-b-modal.delete-modal ><i class="bx bx-trash-alt font-size-14"></i>  Delete</b-dropdown-item>
                      </b-dropdown>
                      <div
                        class="form-check form-switch form-switch-sm my-auto ml-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          :id="`SwitchCheckSize-${location.id}`"
                          v-model="location.active"
                          :value="location.active"
                          name="active"
                          @change="selectedids = [], selectedids.push(location.id), setActive(location.active)"
                        />
                        <label class="form-check-label" :for="`SwitchCheckSize-${location.id}`" 
                          >Active</label
                        >
                    </div>
                  </div>
          </div>
        </div>
      </div>
    </div>
            <div class="row">
              <div class="col-lg-12">
                 <b-pagination
                    class="justify-content-center"
                    pills
                    v-model="currentPage"
                    :total-rows="users.total"
                    :per-page="users.per_page"
                    aria-controls="my-table"
                    @input="searchResource"
                  ></b-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
    <SideSlidePanel :editPanel="addPanel" :panelClass="'edit-sidepanel'" @close-panel="addPanel = false" >
      <b-row>
              <b-col md="12">
                <div class="m-4 px-2">
                <h4>Add New Customer</h4>
                 <transition name="slide-error" mode="out-in">
                    <b-alert id="errors" variant="danger" :show="error_message"  dismissible @dismissed="error_message = !error_message">
                      <p class="font-size-14">Oops! Please correct the following errors in your submission</p>
                      <ul>
                        <li v-for="(error, key) in error_reponse">
                          {{error[0]}}
                        </li>
                      </ul>
                    </b-alert>
                    
                  </transition>
              <b-overlay
                :show="addFormSubmitted"
                variant="white"
                        opacity="0.85"
                        blur="1px"
                        rounded="md"
              >
              <b-row>
                <b-col md="12">
                  <b-form-group label="Title">
                    <b-form-select class="form-control" v-model="newUser.honorific_id" name="honorific_id">
                      <b-form-select-option v-for="honorific in honorifics" :value="honorific.id">
                        {{honorific.value}}
                      </b-form-select-option>
                    </b-form-select>
                  </b-form-group>
                  <b-form-group class="mt-2" label="Name">
                    <b-form-input type="text" name="name" v-model="newUser.name"></b-form-input>
                  </b-form-group>
                  <b-form-group class="mt-2" label="Last Name">
                    <b-form-input type="text" name="name" v-model="newUser.lastname"></b-form-input>
                  </b-form-group>
                  <b-form-group class="my-2" label="Avatar">
                    <vue-dropzone id="dropzone" ref="myVueDropzone" :use-custom-slot="true" :options="dropzoneOptions" @vdropzone-file-added="imageAdded"  @vdropzone-drop="imageAdded">
                        <div class="dropzone-custom-content">
                            <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                            <h4>Drop files here or click to upload.</h4>
                        </div>
                        </vue-dropzone>
                  </b-form-group>
                  <div
                        class="form-check form-switch form-switch-sm my-3 ml-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          :id="`SwitchCheckSize-iscompanynew`"
                          v-model="newUser.iscompany"
                          value="1"
                          unchecked-value="0"
                          name="iscompany"
                        />
                        <label class="form-check-label" :for="`SwitchCheckSize-iscompanynew`" 
                          > Customer is a Company</label
                        >
                    </div>
                  <b-form-group class="mt-2" label="Company Name" v-if="newUser.iscompany == 1">
                    <b-form-input type="text" name="name" v-model="newUser.companyname"></b-form-input>
                  </b-form-group>
                  <b-form-group class="mt-2" label="Email">
                    <b-form-input type="text" name="name" v-model="newUser.email"></b-form-input>
                  </b-form-group>
                  <b-form-group class="mt-2" label="Password">
                    <b-input-group >
                      <b-form-input type="password" name="name" v-model="newUser.password"></b-form-input>
                       <b-input-group-append>
                      <b-button variant="primary" class="border-radius-top-right-1em border-radius-bottom-right-1em" @click="generatePassword('new')">Generate Password</b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                 <div
                        class="form-check form-switch form-switch-sm mt-3 ml-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          :id="`SwitchCheckSize-newuser`"
                          v-model="newUser.active"
                          :value="newUser.active"
                          name="active"
                
                        />
                        <label class="form-check-label" :for="`SwitchCheckSize-newuser`" 
                          ><i class="bx bx-x-circle text-danger" v-if="newUser.active == false"></i><i class="bx bx-check-circle text-success"></i> Active</label
                        >
                    </div>
                    <div
                        class="form-check form-switch form-switch-sm mt-3 ml-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          :id="`SwitchCheckSize-newuseremail`"
                          v-model="newUser.send_email"
                          :value="newUser.send_email"
                          name="send_email"
                
                        />
                        <label class="form-check-label" :for="`SwitchCheckSize-newuseremail`" 
                          >Send Email</label
                        >
                    </div>
                  <a href="javascript:;" class="btn btn-success btn-block btn-rounded mt-3" @click="addNewUser">Save</a>
                </b-col>
              </b-row>
            </b-overlay>
                </div>
              </b-col>
      </b-row>            
    </SideSlidePanel>
    <SideSlidePanel :editPanel="updatePanel" :panelClass="'edit-sidepanel'" @close-panel="updatePanel = false">
      <b-row>
              <b-col md="12">
                <div class="m-4 px-2" v-if="updatePanel == true">
                <h4>Update Customer</h4>
                <transition name="slide-error" mode="out-in">
                    <b-alert id="errors" variant="danger" :show="error_message"  dismissible @dismissed="error_message = !error_message">
                      <p class="font-size-14">Oops! Please correct the following errors in your submission</p>
                      <ul>
                        <li v-for="(error, key) in error_reponse">
                          {{error[0]}}
                        </li>
                      </ul>
                    </b-alert>
                    
                  </transition>
                <b-overlay
                :show="updateFormSubmitted"
                variant="white"
                        opacity="0.85"
                        blur="1px"
                        rounded="md"
              >
              <b-row>
                <b-col md="12">
                  <b-form-group label="Title">
                    <b-form-select class="form-control" v-model="updateUser.honorific_id" name="honorific_id">
                      <b-form-select-option v-for="honorific in honorifics" :value="honorific.id">
                        {{honorific.value}}
                      </b-form-select-option>
                    </b-form-select>
                  </b-form-group>
                  <b-form-group class="mt-2" label="Name">
                    <b-form-input type="text" name="name" v-model="updateUser.name"></b-form-input>
                  </b-form-group>
                  <b-form-group class="mt-2" label="Last Name">
                    <b-form-input type="text" name="name" v-model="updateUser.lastname"></b-form-input>
                  </b-form-group>
                  <b-form-group class="my-2" label="Avatar">
                  <vue-dropzone id="dropzone" ref="myVueDropzone" :use-custom-slot="true" :options="dropzoneOptions" @vdropzone-file-added="imageAdded"  @vdropzone-drop="imageAdded">
                        <div class="dropzone-custom-content">
                            <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                            <h4>Drop files here or click to upload.</h4>
                        </div>
                        </vue-dropzone>
                      </b-form-group>
                    <div
                        class="form-check form-switch form-switch-sm my-3 ml-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          :id="`SwitchCheckSize-iscompanynew`"
                          v-model="updateUser.iscompany"
                          value="1"
                          unchecked-value="0"
                          name="iscompany"
                        />
                        <label class="form-check-label" :for="`SwitchCheckSize-iscompanynew`" 
                          > Customer is a Company</label
                        >
                    </div>
                   <b-form-group class="mt-2" label="Company Name" v-if="updateUser.iscompany == 1">
                    <b-form-input type="text" name="name" v-model="updateUser.companyname"></b-form-input>
                  </b-form-group>
                  <b-form-group class="mt-2" label="Email">
                    <b-form-input type="text" name="name" v-model="updateUser.email"></b-form-input>
                  </b-form-group>
                  <b-form-group class="mt-2" label="Password">
                    <b-input-group >
                      <b-form-input type="password" name="name" v-model="updateUser.password"></b-form-input>
                       <b-input-group-append>
                      <b-button variant="primary" class="border-radius-top-right-1em border-radius-bottom-right-1em" @click="generatePassword('update')">Generate Password</b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                <div
                        class="form-check form-switch form-switch-sm mt-3 ml-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          :id="`SwitchCheckSize-updateuser`"
                          v-model="updateUser.active"
                          :value="updateUser.active"
                          name="active"
                        
                        />
                        <label class="form-check-label" :for="`SwitchCheckSize-updateuser`" 
                          >Active</label
                        >
                    </div>
                     <div
                        class="form-check form-switch form-switch-sm mt-3 ml-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          :id="`SwitchCheckSize-updateemail`"
                          v-model="updateUser.send_email"
                          :value="updateUser.send_email"
                          name="send_email"
                
                        />
                        <label class="form-check-label" :for="`SwitchCheckSize-updateemail`" 
                          >Send Email</label
                        >
                    </div>
                  <a href="javascript:;" class="btn btn-success mt-3 btn-block btn-rounded" @click="updateExistingUser">Save</a>
                </b-col>
              </b-row>
            </b-overlay>
                </div>
              </b-col>
      </b-row>            
    </SideSlidePanel>

    <SideSlidePanel :editPanel="viewPanel" :panelClass="'edit-sidepanel'" @close-panel="viewPanel = false, updateManagerBtn = false">
      <b-row>
              <b-col md="12">
                <div class="m-4 px-2 row" v-if="viewPanel == true">

                  <b-col md="12">
                <h4>{{updateLocation.name}}</h4>
                </b-col>
                 <b-col md="12">
                  <b-card>
                    {{updateLocation.address_1}}<br>
                    {{updateLocation.address_2}}<br>
                    {{updateLocation.address_3}}<br>
                    {{updateLocation.county.name}}<br>
                    {{updateLocation.country.name}}<br>
                    {{updateLocation.postcode}}<br><br>

                    <a :href="'mailto:'+updateLocation.email">{{updateLocation.email}}</a><br>
                    <a :href="'tel:'+updateLocation.telephone">{{updateLocation.telephone}}</a><br>
                  </b-card>
                  </b-col>
                </div>
              </b-col>
      </b-row>            
    </SideSlidePanel>

      <SideSlidePanel :editPanel="addressesPanel" :panelClass="'edit-sidepanel'" @close-panel="addressesPanel = !addressesPanel">
       <div class="row px-2">
        <div class="col-md-12 px-4 mb-3">
          <h4>Addresses for <span class="text-primary">{{selectedListing.name}} {{selectedListing.lastname}}</span></h4>
        </div>
        <transition name="slide-fadepanel">
          <div class="col-md-12 px-4" v-if="deleteAddressPanel == true">
            <p>You are about to delete {{deletesupplieraddress.address_alias}}. Are you sure?</p>
            <a class="btn btn-success" @click="destroySupplierAddress(deletesupplieraddress.id, deleteAddressIndex)">Confirm</a>
            <a class="btn btn-danger" @click="deleteAddressPanel = false, resetFeatureValue()">Cancel</a>
          </div>
        </transition>
        <div class="col-md-12">
            <ul class="list-group product-list w-100 no-shadow" v-if="(customerAddresses.length !== 0) && (deleteAddressPanel == false)">
                              <li v-for="(address, index) in customerAddresses" class="list-group-item d-flex px-0 py-0 border product-list-item mb-3 shadow-none">
                                <div class="row w-100 py-3">
                                  <div class="col-md-3 d-flex">
                                  <strong class="mr-auto my-auto ml-4 text-primary">{{address.alias}}</strong>
                                </div>
                                  <div class="col-md-6 d-flex">
                        <p class="text-muted italic no-margin d-inline my-auto text-truncate">{{address.address_1}}, {{address.address_2}}, {{address.address_3}} <span v-if="address.county">{{address.county.name}}</span><span v-if="address.country">{{address.country.name}}</span> {{address.postcode}}</p>
                                </div>
                                <div class="col-md-3 d-flex">
                                  <div
                        class="form-check form-switch form-switch-sm my-2 ml-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          :id="`SwitchCheckSize-${address.id}`"
                          v-model="address.active"
                          :value="address.active"
                          name="active"
                          @click="selectedids = [], selectedids.push(address.id), setAddressActive(!address.active)"
                        />
                        <label class="form-check-label" :for="`SwitchCheckSize-${address.id}`"
                          >Active</label
                        >
                    </div>
                                  <a class="btn btn-outline-light btn-rounded ml-auto my-auto mr-2" href="javascript:;" @click="addressBulk = false, addressBulkOptions = false, addressLabelsList = [], addressLabel = address, addressLabel['name'] = selectedListing.name+' '+selectedListing.lastname, printWindow = true"><i class="px-2 bx bx-printer"></i></a>
                                  <a class="btn btn-outline-light btn-rounded my-auto mr-2" href="javascript:;" @click="getCounties(address.country), addSupplierAddressPanel = false, editsupplieraddress = address"><i class="px-2 bx bx-pencil"></i></a>
                                <a class="btn btn-outline-light btn-rounded my-auto" href="javascript:;" @click="deletesupplieraddress = address, deleteAddressIndex = index, deleteAddressPanel = true, addSupplierAddressPanel = false"><i class="py-1 px-2 bx bx-trash-alt"></i></a>
                              </div>
              
                              </div>
                              </li>
                              

                              <!--<li class="list-group-item py-4 text-center" ><a class="btn btn-outline-light btn-rounded">Load More</a>
                              </li>-->
                            </ul>
                <ul class="list-group product-list w-100 no-shadow" v-if="(customerAddresses.length == 0) && (deleteAddressPanel == false)">
                    <li class="list-group-item d-flex">
                      <i class="bx bx-warning"></i> No Addresses Assigned to this customer
                    </li> 
                </ul>
                 <a v-if="deleteAddressPanel == false" class="btn btn-outline-light w-100 btn-rounded" @click="editsupplieraddress = {id: null}, addSupplierAddressPanel = true"><i class="bx bx-home-alt"></i> Add New Address <i class="mdi mdi-plus"></i></a>
        </div>
        <div class="col-md-12">
      <transition name="slide-fadepanel">
       <b-card class="shadow-none border-radius-1em border mt-3" v-if="(addSupplierAddressPanel == true)  && (deleteAddressPanel == false)">
          <h4 class="my-3">Add New Address</h4>
          <b-overlay
                        id="overlay-background"
                        :show="loadingForm"
                        variant="white"
                        opacity="0.85"
                        blur="1px"
                        rounded="md"
                      >
         <b-form id="addsupplieraddressform" method="POST" enctype="multipart/form-data">
                   <input type="hidden" name="_token" :value="csrf">
           <b-row>
                     <b-col md="12">
                    <transition name="slide-error" mode="out-in">
                    <b-alert id="errors" variant="danger" :show="error_message"  dismissible @dismissed="error_message = !error_message">
                      <p class="font-size-14">Oops! Please correct the following errors in your submission</p>
                      <ul>
                        <li v-for="(error, key) in error_reponse">
                          {{error[0]}}
                        </li>
                      </ul>
                    </b-alert>
                    
                  </transition>
                  </b-col>
          </b-row>
       
           <b-col md="12">
                <b-form-group  label="Address Alias/Name">
                  <b-form-input type="text" class="underline-input" name="alias" v-model="newsupplieraddress.alias" ></b-form-input>
                </b-form-group>
                <b-form-group class="mt-2" label="Address 1">
                  <b-form-input type="text" class="underline-input" name="address_1" v-model="newsupplieraddress.address_1" ></b-form-input>
                </b-form-group>
                <b-form-group class="mt-2" label="Address 2">
                  <b-form-input type="text" class="underline-input" name="address_2" v-model="newsupplieraddress.address_2" ></b-form-input>
                </b-form-group>
                <b-form-group class="mt-2" label="Address 3">
                  <b-form-input type="text" class="underline-input" name="address_3" v-model="newsupplieraddress.address_3" ></b-form-input>
                </b-form-group>
                      <b-form-group v-if="countries.length > 0" class="my-2" label="Country">
          <multiselect v-model="newsupplieraddress.country" track-by="name" label="name" :options="countries" :option-height="104" class="underline-select" @input="getCounties">
                     <!--<template slot="singleLabel" slot-scope="{ option }">{{ option.name }} - {{ option.jobtitle }}</template>-->
                     <template slot="singleLabel" slot-scope="{ option }"><div class="d-flex w-100 h-100">
                     <div class="avatar-xs mr-4 my-auto" v-if="newsupplieraddress.country.id !== ''">
                        <span class="avatar-title rounded-circle" >{{(option.iso_code).slice(0,2)}}</span>
                       
                      </div>
                      <div class="option__desc d-flex flex-row flex-grow-1"><span v-if="newsupplieraddress.country.id !== ''"  class="option__title ml-2 my-auto w-100">{{ option.name }}</span>
                        <span v-else class="option__title my-auto w-100">Select Country</span>

                      </div>
                      <div class="avatar-xs my-auto" v-if="newsupplieraddress.country.id == ''">
                      <span class="avatar-title rounded-circle bg-light text-primary"><i class="bx bx-chevron-down"></i></span>
                     </div>

                      </div></template>
                    <template slot="option" slot-scope="{ option }">
                      <div class="d-flex"><div class="avatar-xs mr-4">
                                        <span class="avatar-title rounded-circle">{{(option.iso_code).slice(0,2)}}</span>
                                      </div>
                      <div class="option__desc d-flex flex-row w-50"><span class="option__title ml-2 my-auto w-100">{{ option.name }}</span>
                        
                      </div>
                      </div>
                    </template>
                  </multiselect>
      </b-form-group>
      <b-form-group v-if="counties.length > 0" class="my-2"  label="County/State">
          <multiselect v-model="newsupplieraddress.county" track-by="name" label="name" :options="counties" :option-height="104" class="underline-select" >
                     <!--<template slot="singleLabel" slot-scope="{ option }">{{ option.name }} - {{ option.jobtitle }}</template>-->
                     <template slot="singleLabel" slot-scope="{ option }">
                     <div class="d-flex w-100 h-100">
                     <div class="avatar-xs mr-4 my-auto" v-if="newsupplieraddress.county.id !== ''">
                        <span class="avatar-title rounded-circle" >{{(option.name).slice(0,2)}}</span>
                       
                      </div>
                      <div class="option__desc d-flex flex-row flex-grow-1"><span v-if="newsupplieraddress.county.id !== ''"  class="option__title ml-2 my-auto w-100">{{ option.name }}</span>
                        <span v-else class="option__title my-auto w-100">Select State/County</span>

                      </div>
                      <div class="avatar-xs my-auto" v-if="newsupplieraddress.county.id == ''">
                      <span class="avatar-title rounded-circle bg-light text-primary"><i class="bx bx-chevron-down"></i></span>
                     </div>

                      </div></template>
                    <template slot="option" slot-scope="{ option }">
                      <div class="d-flex"><div class="avatar-xs mr-4">
                                        <span class="avatar-title rounded-circle">{{(option.name).slice(0,2)}}</span>
                                      </div>
                      <div class="option__desc d-flex flex-row w-50"><span class="option__title ml-2 my-auto w-100">{{ option.name }}</span>
                        
                      </div>
                      </div>
                    </template>
                  </multiselect>
      </b-form-group>
                <!--<b-form-group class="mt-2" label="Country">
                  <b-form-select class="form-control" v-model="newsupplieraddress.country_id" name="country_id"  @input="getCounties(newsupplieraddress.country_id)">
                    <b-form-select-option v-for="country in countries" :value="country.id">
                      {{country.name}}
                    </b-form-select-option>
                  </b-form-select>
                </b-form-group>
                <b-form-group class="mt-2" label="County/State">
                  <b-form-select class="form-control" name="county_id"  v-model="newsupplieraddress.county_id"  :disabled="(counties.length == 0)">
                    <b-form-select-option v-for="county in counties" :value="county.id" >
                      {{county.name}}
                    </b-form-select-option>
                  </b-form-select>
                </b-form-group>-->
                <b-form-group class="mt-2" label="City">
                  <b-form-input type="text" class="underline-input" v-model="newsupplieraddress.city" name="city"></b-form-input>
                </b-form-group>
                <b-form-group class="mt-2" label="Postcode/Zip">
                  <b-form-input type="text" class="underline-input" v-model="newsupplieraddress.postcode" name="postcode"></b-form-input>
                </b-form-group>
                  <div
                        class="form-check form-switch form-switch-sm mt-3 mb-2 ml-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          :id="`SwitchCheckBilling`"
                          v-model="newsupplieraddress.invoice_default"
                          value="1"
                          unchecked-value="0"
                          name="active"
                        />
                        <label class="form-check-label" :for="`SwitchCheckBilling`" 
                          >Billing Address</label
                        >
                    </div>
                    <div
                        class="form-check form-switch form-switch-sm my-2 ml-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          :id="`SwitchCheckDelivery`"
                          v-model="newsupplieraddress.delivery_default"
                          value="1"
                          unchecked-value="0"
                          name="active"
                        />
                        <label class="form-check-label" :for="`SwitchCheckDelivery`" 
                          >Delivery Address</label
                        >
                    </div>
                    <div
                        class="form-check form-switch form-switch-sm my-2 ml-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          :id="`SwitchCheckDefault`"
                          v-model="newsupplieraddress.active"
                          value="1"
                          unchecked-value="0"
                          name="active"
                        />
                        <label class="form-check-label" :for="`SwitchCheckDefault`" 
                          >Active</label
                        >
                    </div>
               
              </b-col>
          <div class="d-flex flex-row mt-3">
          <b-button variant="success" class="w-md w-50 d-inline" @click="addSupplierAddress()"> <i class=" bx bx-save"></i> Save</b-button>
          <b-button variant="danger" class="w-md w-50 d-inline" @click="addSupplierAddressPanel = false"> <i class=" bx bx-save"></i> Cancel</b-button>
          </div>
        </b-form>
      </b-overlay>
        </b-card>
      </transition>
        </div>
        <div class="col-md-12">
      <transition name="slide-fadepanel">
       <b-card class="shadow-none border-radius-1em border mt-3" v-if="(editsupplieraddress.id !== null)  && (deleteAddressPanel == false)">
          <h4 class="my-3">Edit Address</h4>
          <b-overlay
                        id="overlay-background"
                        :show="loadingForm"
                        variant="white"
                        opacity="0.85"
                        blur="1px"
                        rounded="md"
                      >
         <b-form id="updatesupplieraddressform" method="POST" enctype="multipart/form-data">
                   <input type="hidden" name="_token" :value="csrf">
           <b-row>
                     <b-col md="12">
                    <transition name="slide-error" mode="out-in">
                    <b-alert id="errors" variant="danger" :show="error_message"  dismissible @dismissed="error_message = !error_message">
                      <p class="font-size-14">Oops! Please correct the following errors in your submission</p>
                      <ul>
                        <li v-for="(error, key) in error_reponse">
                          {{error[0]}}
                        </li>
                      </ul>
                    </b-alert>
                    
                  </transition>
                  </b-col>
          </b-row>
       
           <b-col md="12">
                <b-form-group  label="Address Alias/Name">
                  <b-form-input type="text" class="underline-input" name="alias" v-model="editsupplieraddress.alias" ></b-form-input>
                </b-form-group>
                <b-form-group  label="Address 1">
                  <b-form-input type="text" class="underline-input" name="address_1" v-model="editsupplieraddress.address_1" ></b-form-input>
                </b-form-group>
                <b-form-group class="mt-2" label="Address 2">
                  <b-form-input type="text" class="underline-input" name="address_2" v-model="editsupplieraddress.address_2" ></b-form-input>
                </b-form-group>
                <b-form-group class="mt-2" label="Address 3">
                  <b-form-input type="text" class="underline-input" name="address_3" v-model="editsupplieraddress.address_3" ></b-form-input>
                </b-form-group>
                            <b-form-group v-if="countries.length > 0" class="my-2" label="Country">
          <multiselect v-model="editsupplieraddress.country" track-by="name" label="name" :options="countries" :option-height="104" class="underline-select" @input="getCounties">
                     <!--<template slot="singleLabel" slot-scope="{ option }">{{ option.name }} - {{ option.jobtitle }}</template>-->
                     <template slot="singleLabel" slot-scope="{ option }"><div class="d-flex w-100 h-100">
                     <div class="avatar-xs mr-4 my-auto" v-if="editsupplieraddress.country.id !== ''">
                        <span class="avatar-title rounded-circle" >{{(option.iso_code).slice(0,2)}}</span>
                       
                      </div>
                      <div class="option__desc d-flex flex-row flex-grow-1"><span v-if="editsupplieraddress.country.id !== ''"  class="option__title ml-2 my-auto w-100">{{ option.name }}</span>
                        <span v-else class="option__title my-auto w-100">Select Country</span>

                      </div>
                      <div class="avatar-xs my-auto" v-if="editsupplieraddress.country.id == ''">
                      <span class="avatar-title rounded-circle bg-light text-primary"><i class="bx bx-chevron-down"></i></span>
                     </div>

                      </div></template>
                    <template slot="option" slot-scope="{ option }">
                      <div class="d-flex"><div class="avatar-xs mr-4">
                                        <span class="avatar-title rounded-circle">{{(option.iso_code).slice(0,2)}}</span>
                                      </div>
                      <div class="option__desc d-flex flex-row w-50"><span class="option__title ml-2 my-auto w-100">{{ option.name }}</span>
                        
                      </div>
                      </div>
                    </template>
                  </multiselect>
      </b-form-group>
      <b-form-group v-if="counties.length > 0" class="my-2"  label="County/State">
          <multiselect v-model="editsupplieraddress.county" track-by="name" label="name" :options="counties" :option-height="104" class="underline-select" >
                     <!--<template slot="singleLabel" slot-scope="{ option }">{{ option.name }} - {{ option.jobtitle }}</template>-->
                     <template slot="singleLabel" slot-scope="{ option }">
                     <div class="d-flex w-100 h-100">
                     <div class="avatar-xs mr-4 my-auto" v-if="editsupplieraddress.county.id !== ''">
                        <span class="avatar-title rounded-circle" >{{(option.name).slice(0,2)}}</span>
                       
                      </div>
                      <div class="option__desc d-flex flex-row flex-grow-1"><span v-if="editsupplieraddress.county.id !== ''"  class="option__title ml-2 my-auto w-100">{{ option.name }}</span>
                        <span v-else class="option__title my-auto w-100">Select State/County</span>

                      </div>
                      <div class="avatar-xs my-auto" v-if="editsupplieraddress.county.id == ''">
                      <span class="avatar-title rounded-circle bg-light text-primary"><i class="bx bx-chevron-down"></i></span>
                     </div>

                      </div></template>
                    <template slot="option" slot-scope="{ option }">
                      <div class="d-flex"><div class="avatar-xs mr-4">
                                        <span class="avatar-title rounded-circle">{{(option.name).slice(0,2)}}</span>
                                      </div>
                      <div class="option__desc d-flex flex-row w-50"><span class="option__title ml-2 my-auto w-100">{{ option.name }}</span>
                        
                      </div>
                      </div>
                    </template>
                  </multiselect>
      </b-form-group>
      <!--
                <b-form-group class="mt-2" label="Country">
                  <b-form-select class="form-control" v-model="editsupplieraddress.country_id" name="country_id"  @input="getCounties(editsupplieraddress.country_id)">
                    <b-form-select-option v-for="country in countries" :value="country.id">
                      {{country.name}}
                    </b-form-select-option>
                  </b-form-select>
                </b-form-group>
                <b-form-group class="mt-2" label="County/State">
                  <b-form-select class="form-control" name="county_id"  v-model="editsupplieraddress.county_id"  :disabled="(counties.length == 0)">
                    <b-form-select-option v-for="county in counties" :value="county.id" >
                      {{county.name}}
                    </b-form-select-option>
                  </b-form-select>
                </b-form-group>-->
                <b-form-group class="mt-2" label="City">
                  <b-form-input type="text" class="underline-input" v-model="editsupplieraddress.city" name="city"></b-form-input>
                </b-form-group>
                <b-form-group class="mt-2" label="Postcode/Zip">
                  <b-form-input type="text" class="underline-input" v-model="editsupplieraddress.postcode" name="postcode"></b-form-input>
                </b-form-group>
                    <div
                         class="form-check form-switch form-switch-sm mt-3 mb-2 ml-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          :id="`SwitchCheckBilling`"
                          v-model="editsupplieraddress.invoice_default"
                          value="1"
                          unchecked-value="0"
                          name="active"
                        />
                        <label class="form-check-label" :for="`SwitchCheckBilling`" 
                          >Billing Address</label
                        >
                    </div>
                    <div
                        class="form-check form-switch form-switch-sm my-2 ml-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          :id="`SwitchCheckDelivery`"
                          v-model="editsupplieraddress.delivery_default"
                          value="1"
                          unchecked-value="0"
                          name="active"
                        />
                        <label class="form-check-label" :for="`SwitchCheckDelivery`" 
                          >Delivery Address</label
                        >
                    </div>
                    <div
                        class="form-check form-switch form-switch-sm my-2 ml-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          :id="`SwitchCheckDefault`"
                          v-model="editsupplieraddress.active"
                          value="1"
                          unchecked-value="0"
                          name="active"
                        />
                        <label class="form-check-label" :for="`SwitchCheckDefault`" 
                          >Active</label
                        >
                    </div>
               
              </b-col>
          <div class="d-flex flex-row mt-3">
          <b-button variant="success" class="w-md w-50 d-inline" @click="updateSupplierAddress()"> <i class=" bx bx-save"></i> Save</b-button>
          <b-button variant="danger" class="w-md w-50 d-inline" @click="editsupplieraddress = {id: null}"> <i class=" bx bx-save"></i> Cancel</b-button>
          </div>
        </b-form>
      </b-overlay>
        </b-card>
      </transition>
        </div>
      </div>
    </SideSlidePanel>

    <SideSlidePanel :editPanel="contactsPanel" :panelClass="'edit-sidepanel'" @close-panel="contactsPanel = !contactsPanel">
       <div class="row px-2">
        <div class="col-md-12 px-4 mb-3">
          <h4>Contacts for customer: <span class="text-primary">{{selectedListing.name}} {{selectedListing.lastname}}</span></h4>
        </div>
         <transition name="slide-fadepanel">
          <div class="col-md-12 px-4" v-if="deleteContactPanel == true">
            <p>You are about to delete {{deletesuppliercontact.name}}. Are you sure?</p>
            <a class="btn btn-success" @click="destroySupplierContact(deletesuppliercontact.id, deleteContactIndex)">Confirm</a>
            <a class="btn btn-danger" @click="deleteContactPanel = false">Cancel</a>
          </div>
        </transition>
        <div class="col-md-12">
            <ul class="list-group product-list w-100 no-shadow" v-if="(customerContacts.length !== 0) && (deleteContactPanel == false)">
                              <li v-for="(contact, index) in customerContacts" class="list-group-item d-flex px-0 py-0 border product-list-item mb-2 shadow-sm">
                                <div class="d-flex product-list-item-container w-100">
                                  <div class="mr-auto ml-2 my-auto flex-grow-1 mr-3 py-3">
                                  <strong class="mr-auto my-auto ml-4 text-primary">{{contact.name}}</strong>
                                  <span class="mx-4">{{contact.email}}</span>
                                  <span class="mx-4">{{contact.phone}}</span>
                                  <span class="mx-4">{{contact.mobile}}</span>
                                  <span class="mx-4">{{contact.fax}}</span>
                                </div>
                                <div class="ml-auto my-auto flex-grow-2 mr-3 d-flex">
                                  <a class="btn btn-outline-light btn-rounded ml-auto my-auto flex-grow-1 mr-2" href="javascript:;" @click="addSupplierContactPanel = false, editsuppliercontact = contact"><i class="px-2 bx bx-pencil"></i></a>
                                <a class="btn btn-outline-light btn-rounded my-auto" href="javascript:;" ><i class="py-1 px-2 bx bx-trash-alt" @click="deletesuppliercontact = contact, deleteContactIndex = index, deleteContactPanel = true"></i></a>
                              </div>
              
                              </div>
                              </li>
                              

                              <!--<li class="list-group-item py-4 text-center" ><a class="btn btn-outline-light btn-rounded">Load More</a>
                              </li>-->
                            </ul>
                <ul class="list-group product-list w-100 no-shadow" v-if="(customerContacts.length == 0) && (deleteContactPanel == false)">
                    <li class="list-group-item d-flex">
                      <i class="bx bx-warning"></i> No Addresses Assigned to this customer
                    </li> 
                </ul>
                 <a class="btn btn-outline-light w-100 btn-rounded" v-if="(addSupplierContactPanel == false) && (deleteContactPanel == false)" @click="editsuppliercontact.id =  null, addSupplierContactPanel = true"><i class="bx bx-home-alt"></i> Add New Contact <i class="mdi mdi-plus"></i></a>
        </div>
        <div class="col-md-12">
      <transition name="slide-fadepanel">
       <b-card class="shadow-none" v-if="(addSupplierContactPanel == true) && (deleteContactPanel == false)">
          <h4 class="my-3">Add New Contact</h4>
          <b-overlay
                        id="overlay-background"
                        :show="loadingForm"
                        variant="white"
                        opacity="0.85"
                        blur="1px"
                        rounded="md"
                      >
         <b-form id="addsuppliercontactform" method="POST" enctype="multipart/form-data">
                   <input type="hidden" name="_token" :value="csrf">
           <b-row>
                     <b-col md="12">
                    <transition name="slide-error" mode="out-in">
                    <b-alert id="errors" variant="danger" :show="error_message"  dismissible @dismissed="error_message = !error_message">
                      <p class="font-size-14">Oops! Please correct the following errors in your submission</p>
                      <ul>
                        <li v-for="(error, key) in error_reponse">
                          {{error[0]}}
                        </li>
                      </ul>
                    </b-alert>
                    
                  </transition>
                  </b-col>
          </b-row>
       
           <b-col md="12">
                <b-form-group  label="Name">
                  <b-form-input type="text" name="name" v-model="newsuppliercontact.name" ></b-form-input>
                </b-form-group>
                <b-form-group  label="Position">
                  <b-form-input type="text" name="position" v-model="newsuppliercontact.position" ></b-form-input>
                </b-form-group>
                <b-form-group class="mt-2" label="Email">
                  <b-form-input type="text" name="email" v-model="newsuppliercontact.email" ></b-form-input>
                </b-form-group>
                <b-form-group class="mt-2" label="Phone">
                  <b-form-input type="text" name="phone" v-model="newsuppliercontact.phone" ></b-form-input>
                </b-form-group>
                <b-form-group class="mt-2" label="Mobile">
                  <b-form-input type="text" name="mobile" v-model="newsuppliercontact.mobile" ></b-form-input>
                </b-form-group>
                <b-form-group class="mt-2" label="Fax">
                  <b-form-input type="text" name="fax" v-model="newsuppliercontact.fax" ></b-form-input>
                </b-form-group>

               
              </b-col>
          <div class="d-flex flex-row mt-3">
          <b-button variant="success" class="w-md w-50 d-inline" @click="addSupplierContact()"> <i class=" bx bx-save"></i> Save</b-button>
          <b-button variant="danger" class="w-md w-50 d-inline" @click="addSupplierContactPanel = false"> <i class=" bx bx-save"></i> Cancel</b-button>
          </div>
        </b-form>
      </b-overlay>
        </b-card>
      </transition>
        </div>
        <div class="col-md-12">
      <transition name="slide-fadepanel">
       <b-card class="shadow-none" v-if="(editsuppliercontact.id !== null)  && (deleteContactPanel == false)">
          <h4 class="my-3">Edit Address</h4>
          <b-overlay
                        id="overlay-background"
                        :show="loadingForm"
                        variant="white"
                        opacity="0.85"
                        blur="1px"
                        rounded="md"
                      >
         <b-form id="updatesuppliercontactform" method="POST" enctype="multipart/form-data">
                   <input type="hidden" name="_token" :value="csrf">
           <b-row>
                     <b-col md="12">
                    <transition name="slide-error" mode="out-in">
                    <b-alert id="errors" variant="danger" :show="error_message"  dismissible @dismissed="error_message = !error_message">
                      <p class="font-size-14">Oops! Please correct the following errors in your submission</p>
                      <ul>
                        <li v-for="(error, key) in error_reponse">
                          {{error[0]}}
                        </li>
                      </ul>
                    </b-alert>
                    
                  </transition>
                  </b-col>
          </b-row>
       
           <b-col md="12">
                <b-form-group  label="Name">
                  <b-form-input type="text" name="name" v-model="editsuppliercontact.name" ></b-form-input>
                </b-form-group>
                <b-form-group  label="Position">
                  <b-form-input type="text" name="position" v-model="editsuppliercontact.position" ></b-form-input>
                </b-form-group>
                <b-form-group class="mt-2" label="Email">
                  <b-form-input type="text" name="email" v-model="editsuppliercontact.email" ></b-form-input>
                </b-form-group>
                <b-form-group class="mt-2" label="Phone">
                  <b-form-input type="text" name="phone" v-model="editsuppliercontact.phone" ></b-form-input>
                </b-form-group>
                <b-form-group class="mt-2" label="Mobile">
                  <b-form-input type="text" name="mobile" v-model="editsuppliercontact.mobile" ></b-form-input>
                </b-form-group>
                <b-form-group class="mt-2" label="Fax">
                  <b-form-input type="text" name="fax" v-model="editsuppliercontact.fax" ></b-form-input>
                </b-form-group>

               
              </b-col>
          <div class="d-flex flex-row mt-3">
          <b-button variant="success" class="w-md w-50 d-inline" @click="updateSupplierContact()"> <i class=" bx bx-save"></i> Save</b-button>
          <b-button variant="danger" class="w-md w-50 d-inline" @click="editsuppliercontact = {id: null}"> <i class=" bx bx-save"></i> Cancel</b-button>
          </div>
        </b-form>
      </b-overlay>
        </b-card>
      </transition>
        </div>
      </div>
    </SideSlidePanel>
     <SideSlidePanel :editPanel="contactsPanel" :panelClass="'edit-sidepanel'" @close-panel="contactsPanel = !contactsPanel">
       <div class="row px-2" v-if="selectedListing !== ''">
        <div class="col-md-12 px-4 mb-3">
          <h4>Contacts for customer: <span class="text-primary">{{selectedListing.name}}</span></h4>
        </div>
         <transition name="slide-fadepanel">
          <div class="col-md-12 px-4" v-if="deleteContactPanel == true">
            <p>You are about to delete {{deletesuppliercontact.name}}. Are you sure?</p>
            <a class="btn btn-success" @click="destroySupplierContact(deletesuppliercontact.id, deleteContactIndex)">Confirm</a>
            <a class="btn btn-danger" @click="deleteContactPanel = false">Cancel</a>
          </div>
        </transition>
        <div class="col-md-12">
            <ul class="list-group product-list w-100 no-shadow" v-if="(customerContacts.length !== 0) && (deleteContactPanel == false)">
                              <li v-for="(contact, index) in customerContacts" class="list-group-item d-flex px-0 py-0 border product-list-item mb-2 shadow-sm">
                                <div class="d-flex product-list-item-container w-100">
                                  <div class="mr-auto ml-2 my-auto flex-grow-1 mr-3 py-3">
                                  <strong class="mr-auto my-auto ml-4 text-primary">{{contact.name}}</strong>
                                  <span class="mx-4">{{contact.email}}</span>
                                  <span class="mx-4">{{contact.phone}}</span>
                                  <span class="mx-4">{{contact.mobile}}</span>
                                  <span class="mx-4">{{contact.fax}}</span>
                                </div>
                                <div class="ml-auto my-auto flex-grow-2 mr-3 d-flex">
                                  <a class="btn btn-outline-light btn-rounded ml-auto my-auto flex-grow-1 mr-2" href="javascript:;" @click="addSupplierContactPanel = false, editsuppliercontact = contact"><i class="px-2 bx bx-pencil"></i></a>
                                <a class="btn btn-outline-light btn-rounded my-auto" href="javascript:;" ><i class="py-1 px-2 bx bx-trash-alt" @click="deletesuppliercontact = contact, deleteContactIndex = index, deleteContactPanel = true"></i></a>
                              </div>
              
                              </div>
                              </li>
                              

                              <!--<li class="list-group-item py-4 text-center" ><a class="btn btn-outline-light btn-rounded">Load More</a>
                              </li>-->
                            </ul>
                <ul class="list-group product-list w-100 no-shadow" v-if="(customerContacts.length == 0) && (deleteContactPanel == false)">
                    <li class="list-group-item d-flex">
                      <i class="bx bx-warning"></i> No Addresses Assigned to this customer
                    </li> 
                </ul>
                 <a class="btn btn-outline-light w-100 btn-rounded" v-if="(addSupplierContactPanel == false) && (deleteContactPanel == false)" @click="editsuppliercontact.id =  null, addSupplierContactPanel = true"><i class="bx bx-home-alt"></i> Add New Contact <i class="mdi mdi-plus"></i></a>
        </div>
        <div class="col-md-12">
      <transition name="slide-fadepanel">
       <b-card class="shadow-none" v-if="(addSupplierContactPanel == true) && (deleteContactPanel == false)">
          <h4 class="my-3">Add New Contact</h4>
          <b-overlay
                        id="overlay-background"
                        :show="loadingForm"
                        variant="white"
                        opacity="0.85"
                        blur="1px"
                        rounded="md"
                      >
         <b-form id="addsuppliercontactform" method="POST" enctype="multipart/form-data">
                   <input type="hidden" name="_token" :value="csrf">
           <b-row>
                     <b-col md="12">
                    <transition name="slide-error" mode="out-in">
                    <b-alert id="errors" variant="danger" :show="error_message"  dismissible @dismissed="error_message = !error_message">
                      <p class="font-size-14">Oops! Please correct the following errors in your submission</p>
                      <ul>
                        <li v-for="(error, key) in error_reponse">
                          {{error[0]}}
                        </li>
                      </ul>
                    </b-alert>
                    
                  </transition>
                  </b-col>
          </b-row>
       
           <b-col md="12">
                <b-form-group  label="Name">
                  <b-form-input type="text" name="name" v-model="newsuppliercontact.name" ></b-form-input>
                </b-form-group>
                <b-form-group  label="Position">
                  <b-form-input type="text" name="position" v-model="newsuppliercontact.position" ></b-form-input>
                </b-form-group>
                <b-form-group class="mt-2" label="Email">
                  <b-form-input type="text" name="email" v-model="newsuppliercontact.email" ></b-form-input>
                </b-form-group>
                <b-form-group class="mt-2" label="Phone">
                  <b-form-input type="text" name="phone" v-model="newsuppliercontact.phone" ></b-form-input>
                </b-form-group>
                <b-form-group class="mt-2" label="Mobile">
                  <b-form-input type="text" name="mobile" v-model="newsuppliercontact.mobile" ></b-form-input>
                </b-form-group>
                <b-form-group class="mt-2" label="Fax">
                  <b-form-input type="text" name="fax" v-model="newsuppliercontact.fax" ></b-form-input>
                </b-form-group>

               
              </b-col>
          <div class="d-flex flex-row mt-3">
          <b-button variant="success" class="w-md w-50 d-inline" @click="addSupplierContact()"> <i class=" bx bx-save"></i> Save</b-button>
          <b-button variant="danger" class="w-md w-50 d-inline" @click="addSupplierContactPanel = false"> <i class=" bx bx-save"></i> Cancel</b-button>
          </div>
        </b-form>
      </b-overlay>
        </b-card>
      </transition>
        </div>
        <div class="col-md-12">
      <transition name="slide-fadepanel">
       <b-card class="shadow-none" v-if="(editsuppliercontact.id !== null)  && (deleteContactPanel == false)">
          <h4 class="my-3">Edit Address</h4>
          <b-overlay
                        id="overlay-background"
                        :show="loadingForm"
                        variant="white"
                        opacity="0.85"
                        blur="1px"
                        rounded="md"
                      >
         <b-form id="updatesuppliercontactform" method="POST" enctype="multipart/form-data">
                   <input type="hidden" name="_token" :value="csrf">
           <b-row>
                     <b-col md="12">
                    <transition name="slide-error" mode="out-in">
                    <b-alert id="errors" variant="danger" :show="error_message"  dismissible @dismissed="error_message = !error_message">
                      <p class="font-size-14">Oops! Please correct the following errors in your submission</p>
                      <ul>
                        <li v-for="(error, key) in error_reponse">
                          {{error[0]}}
                        </li>
                      </ul>
                    </b-alert>
                    
                  </transition>
                  </b-col>
          </b-row>
       
           <b-col md="12">
                <b-form-group  label="Name">
                  <b-form-input type="text" name="name" v-model="editsuppliercontact.name" ></b-form-input>
                </b-form-group>
                <b-form-group  label="Position">
                  <b-form-input type="text" name="position" v-model="editsuppliercontact.position" ></b-form-input>
                </b-form-group>
                <b-form-group class="mt-2" label="Email">
                  <b-form-input type="text" name="email" v-model="editsuppliercontact.email" ></b-form-input>
                </b-form-group>
                <b-form-group class="mt-2" label="Phone">
                  <b-form-input type="text" name="phone" v-model="editsuppliercontact.phone" ></b-form-input>
                </b-form-group>
                <b-form-group class="mt-2" label="Mobile">
                  <b-form-input type="text" name="mobile" v-model="editsuppliercontact.mobile" ></b-form-input>
                </b-form-group>
                <b-form-group class="mt-2" label="Fax">
                  <b-form-input type="text" name="fax" v-model="editsuppliercontact.fax" ></b-form-input>
                </b-form-group>

               
              </b-col>
          <div class="d-flex flex-row mt-3">
          <b-button variant="success" class="w-md w-50 d-inline" @click="updateSupplierContact()"> <i class=" bx bx-save"></i> Save</b-button>
          <b-button variant="danger" class="w-md w-50 d-inline" @click="editsuppliercontact = {id: null}"> <i class=" bx bx-save"></i> Cancel</b-button>
          </div>
        </b-form>
      </b-overlay>
        </b-card>
      </transition>
        </div>
      </div>
    </SideSlidePanel>
    <b-modal hide-footer size="xl" v-model="printWindow" centered>
      <b-card no-body class="shadow-none border-none mb-0" v-if="(addressBulk == true) && (addressBulkOptions == true)" >
        <b-row>
          <b-col>
            <b-card class="border-radius-1em border shadow-none" body-class="d-flex flex-column align-items-center justify-content-center"  @click="getCustomerAddresses('delivery')">
              <i class="bx bxs-truck font-size-42 text-primary"></i>
              <p class="text-muted">Delivery Addresses</p>
            </b-card>
          </b-col>
          <b-col>
            <b-card class="border-radius-1em border shadow-none" body-class="d-flex flex-column align-items-center justify-content-center" @click="getCustomerAddresses('invoice')">
              <i class="bx bx-receipt font-size-42 text-primary"></i>
              <p class="text-muted">Invoice Addresses</p>
            </b-card>
          </b-col>
        </b-row>
        <b-row>
          <b-col>

          </b-col>
        </b-row>
      </b-card>
      <b-card no-body class="shadow-none border-none mb-0" v-if="addressBulk == false">
        <SearchCompanyCustomerAddress class="mb-3" v-if="selectedListing !== ''" :api_token="$attrs.user.api_token" :options="selectedListing.addresses" @listing-selected="addressLabel = $event" :data_provided="true" ></SearchCompanyCustomerAddress>
        <AddressLabel :address="addressLabel" :addresses="addressLabelsList"></AddressLabel>
      </b-card>
      <b-card no-body class="shadow-none border-none mb-0" v-if="(addressBulk == true) && (addressLabelsList.length > 0)">
        <AddressLabel v-if="addressLabelsList.length > 0" :address="addressLabelsList[0]" :addresses="addressLabelsList"></AddressLabel>
      </b-card>
    </b-modal>
    <b-modal id="send-email" size="lg" centered hide-footer content-class="border-radius-1em">
          <Emailer :email="send_email" :user="$attrs.user"></Emailer>
        </b-modal>
  </Layout>

</template>