<template>
	<b-row v-if="content[filterStylesKey(content, 'view')].view.value == 'grid'">
	  <b-col :class="'col-xl-'+content[filterStylesKey(content, 'columns')].columns.value.xl+' col-lg-'+content[filterStylesKey(content, 'columns')].columns.value.lg+' col-md-'+content[filterStylesKey(content, 'columns')].columns.value.md+' col-sm-'+content[filterStylesKey(content, 'columns')].columns.value.sm+' col-xs-'+content[filterStylesKey(content, 'columns')].columns.value.xs" v-for="image in content[filterStylesKey(content, 'list')].list" >
	       	<b-img-lazy :src="image.src" class="img-fluid" ></b-img-lazy>
	  </b-col>
	</b-row>
	<b-row v-else-if="content[filterStylesKey(content, 'view')].view.value == 'list'">
		 <b-col v-for="image in content[filterStylesKey(content, 'list')].list" md="12">
	       	<b-img-lazy :src="image.src" class="img-fluid" ></b-img-lazy>
	  	</b-col>
	</b-row>
	<b-row v-else-if="content[filterStylesKey(content, 'view')].view.value == 'masonry'">
		 <b-col v-for="image in content[filterStylesKey(content, 'list')].list">
	       	<b-img-lazy :src="image.src" class="img-fluid" ></b-img-lazy>
	  	</b-col>
	</b-row>
	<swiper v-else-if="content[filterStylesKey(content, 'view')].view.value == 'carousel'" ref="imgGalleryCarousel" :options="swiperOptions" >
        <swiper-slide v-for="(image, index) in content[filterStylesKey(content, 'list')].list" class="h-100" >
              <b-img @click="selected_index = index" :src="image.src" class="border-radius-1em border img-fluid" :class="{'border border-primary' : index == selected_index}"></b-img>
        </swiper-slide>
  	</swiper>
</template>

<script type="text/javascript">
	import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
	export default {
		components: {
		    Swiper,
		    SwiperSlide
		  },
		  directives: {
		    swiper: directive
		  },
		data() {
	      return {
	      	hoverIndex: -1,
    		selected_index: -1,
      		storageurl: 'https://'+this.$attrs.company.nickname+'.s3.eu-west-2.amazonaws.com',
      		swiperOptions: {
	           	direction: 'horizontal',
		          slidesPerView: 3,
		          spaceBetween: 10,
		          pagination: {
		            el: '.swiper-pagination',
		            clickable: true
		          },
		          navigation: {
		            nextEl: '.swiper-button-next',
		            prevEl: '.swiper-button-prev'
		          }
	        },
	      }
	    },
		props: {
			content: {
				type: Object
			}
		},
		computed: {
      swiper() {
        return this.$refs.imgGalleryCarousel.$swiper
      }
    },
    mounted() {
	    console.log('Current Swiper instance object', this.swiper);
	      this.swiper.slideTo(this.startSlide, 1000, false);
	  },
	  methods: {
		    filterStylesKey(styles, key){
	            for(var i = 0; i < styles.length; i++){
	               var keys = Object.keys(styles[i])
	               if(keys.includes(key)){
	                 return i;
	               }
	            }
	        },
		}
	}

</script>