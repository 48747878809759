<script type="text/javascript">
	import Layout from "../../layouts/main";
	import PageHeader from "../../components/page-header";
	import MarketplaceProductList from "../../components/widgets/MarketplaceProductList";
	import CardCarousel from "../../components/widgets/carousel/CardCarousel";
	import simplebar from "simplebar-vue";
	import FilterProductBrands from "../../components/widgets/FilterProductBrands";
	import FilterCompanyProductCategories from "../../components/widgets/FilterCompanyProductCategories";

	export default{
		components: {Layout, PageHeader, MarketplaceProductList, CardCarousel, simplebar, FilterProductBrands, FilterCompanyProductCategories},
		data: function(){
			return{
				productsLoading: false,
				productsData: [],
				priceRange: [0,250000],
				currentPage: 1,
	      limit: 25,
	      listingslimit: 25,
	      listingssortby: 'id',
	      listingsData: [],
	      type_id: '',
	      selectedids: [],
	      orderDesc: true,
	      order: 'desc',
	      sortby: 'id',
	      search: '',
	      listingsearch: '',
	      active: '',
	      trade_active: '',
	      listingtrade_active: 1,
	      listingpublic_active: '',
	      listing_active: '',
	      public_active: '',
				brands: '',
	      collections: '',
	      categories: [],
	      category_data: [],
				limits: [
	        {value: 25, text: "25"},
	        {value: 50, text: "50"},
	        {value: 100, text: "100"},
      	],
				  storageurl: 'https://'+this.$attrs.company.nickname+'.s3.eu-west-2.amazonaws.com',
				  title: "Profile",
			      items: [
			        {
			          text: "Trade",
			          href: "/",
			        },
			        {
			          text: "Profile",
			          active: true,
			        },
			      ],
			}
		},
		created(){
			this.productQuery();
			this.getCategories();
			this.getListings();
		},
		methods:{
				productQuery(){
	      this.productsLoading = true;
	      if(this.productsData != ''){
	        if(this.limit > this.productsData.total){
	          this.currentPage = 1;
	        }
	      }
	      if(this.orderDesc == true){
	        this.order = 'desc';
	      }else{
	        this.order = 'asc';
	      }
	      axios.get('/api/company/trade/products?api_token='+this.$attrs.user.api_token+'&page='+this.currentPage+'&limit='+this.limit+'&sortby='+this.sortby+'&order='+this.order+'&search='+this.search+'&trade_active=1&public_active='+this.public_active+'&active='+this.active+'&brands='+this.brands+'&collections='+this.collections+'&pricerange='+this.priceRange).then(response => {
	        console.log(response);
	        this.productsData = response.data;
	      }).catch(error => {
	        this.error_reponse = error;
	      });
	      let self = this;
	          setTimeout(function(){
	            self.productsLoading = false;
	          }, 1500);
	    },
	    getCategories(){
	      axios.get('/api/company/product/categories?api_token='+this.$attrs.user.api_token+'&page=1&limit=50&sortby=id&order=desc&search=&parent_id=null&marketplace_categories=trade').then(response => {
	                     console.log(response);
	                    this.category_data = response.data.data;
	                });
	                //this.clearCategories();
	    },
	    getListings(){
	      axios.get('/api/company/listings?page='+this.currentPage+'&limit='+this.listingslimit+'&sortby='+this.listingssortby+'&order='+this.order+'&search='+this.listingsearch+'&type_id='+this.type_id+'&active='+this.listingactive+'&public='+this.listingpublic_active+'&trade='+this.listingtrade_active, {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
	      this.listingsData = response.data;
	      }).catch(error => {
	        this.shops_error = error.data;
	      });
    	},
	     filterBrands(value){
		      this.brands = value;
		      this.productQuery();
   	 },
		 filterSelectedCategories(id){
		        if(this.categories.length > 0){
		          var exists = this.categories.filter(item => item == id);
		          if(exists.length > 0){
		            return true;
		          }else{
		            return false
		          }
		        }else{
		          return false;
		        }
      },
		}
	}

</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <b-row>
    	
    	<b-col md="8">
    		  <div class="card overflow-hidden">
				    <div class="bg-soft bg-primary">
				      <div class="row" style="max-height: 560px">
				        <div class="col-12">
				          <img v-if="$attrs.company.cover_image" :src="storageurl+$attrs.company.cover_image"  src="/images/profile-img.png" alt class="img-fluid" />
				          <img v-else  src="/images/banner-placeholder.png" alt class="img-fluid w-100" />
				        </div>
				      </div>
				    </div>
				    <div class="card-body pt-0 bg-white z-index-2">
				      <div class="row">
				        <div class="col-sm-12 text-center">
				          <div class="avatar-lg profile-user-wid mb-4 mx-auto">
				            <img v-if="$attrs.company.logo" :src="storageurl+$attrs.company.logo" alt class="img-thumbnail rounded-circle" />
				          	<img v-else src="/images/product-placeholder.png" alt class="img-thumbnail rounded-circle" />
				          
				          </div>
				          <h2 class="text-truncate">{{$attrs.company.name}}</h2>
				          <p class="text-muted px-2">
				          	<span v-if="$attrs.company.address1">{{$attrs.company.address1}},</span>
				          	<span v-if="$attrs.company.address2">{{$attrs.company.address2}},</span>
				          	<span v-if="$attrs.company.address3">{{$attrs.company.address3}},</span>
				          	<span v-if="$attrs.company.county">{{$attrs.company.county.name}},</span>
				          	<span v-if="$attrs.company.country">{{$attrs.company.country.name}},</span>
				          	<span v-if="$attrs.company.postcode">{{$attrs.company.postcode}}</span>
				          </p>
				          <p v-html="$attrs.company.description"></p>
				        </div>

				        <div class="col-sm-12">
				          <div class="pt-4">
				              <a href class="btn btn-primary btn-rounded">
				                <i class="bx bx-mail-send font-size-18"></i>
				              </a>
					          </div>
				        </div>
				      </div>
				    </div>
    <!-- end card-body -->
  			</div>
        </b-col>
        <b-col md="4">
        	<div class="card">
          <div class="card-body">
            <h4>Company Information</h4>

            <p
              class="text-muted mb-4"
            >Hi I'm Cynthia Price,has been the industry's standard dummy text To an English person, it will seem like simplified English, as a skeptical Cambridge.</p>
            <div class="table-responsive mb-0">
              <table class="table">
                <tbody>
                  <tr>
                    <th scope="row">Full Name :</th>
                    <td>Cynthia Price</td>
                  </tr>
                  <tr>
                    <th scope="row">Mobile :</th>
                    <td>(123) 123 1234</td>
                  </tr>
                  <tr>
                    <th scope="row">E-mail :</th>
                    <td>cynthiaskote@gmail.com</td>
                  </tr>
                  <tr>
                    <th scope="row">Location :</th>
                    <td>California, United States</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        
        	
        	<b-row>
        		<b-col md="7">
	        		
						</b-col>
        		<b-col md="12">
	        	
	        	<b-card>
	        		<h4>Stores & Locations</h4>
	        		<div class="mt-sm-0 form-inline w-100" >
              <div class="search-box mr-2 ml-auto my-3">
                <div class="position-relative">
                  <input
                    type="text"
                    class="form-control w-100 border-0 bg-light"
                    placeholder="Search name, reference or description..."
                    v-model="listingsearch" @keydown.enter="getListings()" 
                  />
                  <i class="bx bx-search-alt search-icon"></i>
                </div>
              </div>
            </div>
	        		<simplebar
                            style="max-height: 365px"
                        >
                            <li class="list-group-item" v-for="listing in listingsData.data">
                                <div class="media">
                                    <div class="avatar-sm me-3" v-if="listing.logo">
                                        <span
                                            class="avatar-title rounded-circle bg-light"
                                        >
                                            <!--<img
                                            		
                                                :src="listing.logo"
                                                alt=""
                                                height="18"
                                            />-->
                                            
                                        </span>
                                      </div>
                                       <div class="avatar-sm me-3" v-else>
                                        <span class="avatar-title rounded-circle" >{{(listing.name).slice(0,1)}}</span>
                                      </div>
                                   
                                    <div class="media-body">
                                        <h5 class="font-size-14">
                                            {{listing.name}}
                                        </h5>
                                        <p class="text-muted">
                                            <span v-if="listing.address_1">{{listing.address_1}},</span>
				          	<span v-if="listing.address_2">{{listing.address_2}},</span>
				          	<span v-if="listing.address_3">{{listing.address_3}},</span>
				          	<span v-if="listing.county">{{listing.county.name}},</span>
				          	<span v-if="listing.country">{{listing.country.name}},</span>
				          	<span v-if="listing.postcode">{{listing.postcode}}</span>
                                        </p>

                                        <div class="float-end">
                                            <p class="text-muted mb-0" v-if="listing.listing_type">
                                                <i 
                                                    class="bx bx-buildings me-1"
                                                ></i>
                                                {{listing.listing_type.name}}
                                            </p>
                                        </div>
                                        <p class="text-muted mb-0">
                                            <a class="text-primary" >See Location</a>
                                        </p>
                                    </div>
                                </div>
                            </li>
                        </simplebar>
	        	</b-card>
	        </b-col>
	        <!--<b-col md="12">
        	<h4>Latest News</h4>
        	<CardCarousel 
    		:items="productsData.data"
    		:layout_type="'horizontal'"
    		:grid_limit="2"
    		:end="2"
    		></CardCarousel>
        </b-col>-->
        	</b-row>
        </b-col>
        
      </b-row>
      <b-row>
      	<b-col md="3">
          <div class="card p-4 rounded-4">
            <h4 class="card-title mb-4">Filter</h4>
            <div>
               <div class="my-3 pt-3">
              <h5 class="font-size-14">Price</h5>
              <vue-slider v-model="priceRange" :min="0" :max="100 + priceRange[1]"  @drag-end="productQuery()" ></vue-slider>
              <b-form-group label="Min Price" class="w-50 d-inline float-left mt-2 mb-3 px-2">
              <b-form-input
                type="number"
                v-model="priceRange[0]"
                @input="productQuery"></b-form-input>
              </b-form-group>
              <b-form-group label="Max Price" class="w-50 d-inline float-left mt-2 mb-3 px-2">
                 <b-form-input
                 label="Max Price"
                 type="number"
                 v-model="priceRange[1]"
                 @input="productQuery"></b-form-input>
              </b-form-group>
            </div>
                <h5 class="font-size-14 my-3">Categories</h5>
                <!--<FilterListTree></FilterListTree>-->
                <FilterCompanyProductCategories :api_token="$attrs.user.api_token" :selectedids="categories" :category_data="category_data" @category-select="filterSelectedCategories" :marketplace_categories="'trade'" ></FilterCompanyProductCategories>
                <h5 class="font-size-14 mb-3">Brands</h5>
               <FilterProductBrands v-on:brands-filtered="filterBrands" :api_token="$attrs.user.api_token"></FilterProductBrands>

               
            </div>
          </div>
          	<MarketplaceProductList :product_list="productsData.data" :expanded="0" :layouttoggle="false" :view="'list'" :marketplace="'trade'" :showtools="false" :showcategories="false" :minilist="true"></MarketplaceProductList>
          	<b-pagination
              class="justify-content-center mt-auto pt-4"
              pills
              v-model="currentPage"
              :total-rows="productsData.total"
              :per-page="productsData.per_page"
              aria-controls="my-table"
              @input="productQuery"
            ></b-pagination>
    	</b-col>
    	<b-col md="9">
    		<div v-if="search.length < 3">
    		<h4>Featured</h4>
    		<hr>
    		<CardCarousel 
    		:items="productsData.data"
    		:layout_type="'vertical'"
    		:grid_limit="3"
    		:end="3"
    		></CardCarousel>
    		<h4>Popular</h4>
    		<hr>
    		<CardCarousel 
    		:items="productsData.data"
    		:layout_type="'vertical'"
    		:grid_limit="3"
    		:end="3"
    		></CardCarousel>

    		<h4>Discounted</h4>
    		<hr>
    		<CardCarousel 
    		:items="productsData.data"
    		:layout_type="'vertical'"
    		:grid_limit="3"
    		:end="3"
    		></CardCarousel>
    		<h4>All Products</h4>
    		<hr>
    		</div>
    		<div v-else>
    		<h4>Search Results For: <span class="text-primary">{{search}}</span></h4>
    		<hr>
    		</div>
    		<b-overlay
        :show="productsLoading"
        variant="white"
        opacity="0.85"
        blur="1px"
        rounded="xl">
        <div class="px-2">
          <MarketplaceProductList :product_list="productsData.data" :expanded="0" :layouttoggle="true" :view="'grid'" :marketplace="'trade'" ></MarketplaceProductList>
         </div>
          <b-pagination
              class="justify-content-center mt-auto pt-4"
              pills
              v-model="currentPage"
              :total-rows="productsData.total"
              :per-page="productsData.per_page"
              aria-controls="my-table"
              @input="productQuery"
            ></b-pagination>
             <small class="mx-auto my-2 text-center d-block">Records {{productsData.from}} - {{productsData.to}} of {{productsData.total}} // Page {{productsData.current_page}} of {{productsData.last_page}}</small>
          <template #overlay>
            <div class="cube-wrapper"><div class="cube-folding"><span class="leaf1"></span><span class="leaf2"></span><span class="leaf3"></span><span class="leaf4"></span></div></div>
          </template>
        </b-overlay>
    	</b-col>
    </b-row>
  </Layout>	
</template>