
<template>
<b-card class="p-3 border-radius-1em shadow-none">
					
					<b-overlay :show="loadingShippingSettings" variant="white" opacity="0.95">
					<template #overlay>
						<b-card class="border-radius-1em shadow-none mb-0">	
		            <div class="cube-wrapper mx-auto"><div class="cube-folding"><span class="leaf1"></span><span class="leaf2"></span><span class="leaf3"></span><span class="leaf4"></span></div></div>
		               <h6 class="text-center">Loading Your Shipping Settings</h6>
		             <b-progress class="w-50 mx-auto" v-model="shipping_progress" variant="primary"></b-progress>
		         		</b-card>
		         		</template>	
						<b-row>
							<b-col md="2">
								<h5 class="d-flex">Active Integration <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
							</b-col>
							<b-col md="10">
								
								<b-card class="border-radius-1em border shadow-none "  body-class="d-flex justify-content-center align-items-center flex-column p-4" >
									<div class="m-3 p-3 text-center" v-if="active_carrier_integrations.length == 0">
										<i class="bx bxs-truck font-size-24 text-primary"></i>
										<h5>No Shipping Integrations</h5>
										<a class="btn btn-primary btn-rounded mt-3" v-if="shipping_apikeys == ''" @click="setUpCourierApiKey()">Create API Key</a>
									</div>
									<div v-else class="w-100">
										<b-overlay 
								:show="loadingEcommerceIntegration"
								>
										<b-list-group flush v-if="!loadingShippingSettings">
											<b-list-group-item v-for="courier in active_carrier_integrations" class="d-flex">
												<img :src="getShippingImage(courier.courier)" class="my-auto mr-3" width="75px">
												<h6 class="my-auto mr-auto">
												Courier name: {{courier.courier}}
												</h6>
												

												<a class="my-auto w-25 btn-rounded btn btn-primary" v-if="$attrs.active_courier.key !== courier.courier" @click="selectCourier(courier)">Set as Default</a>
												<a class="my-auto w-25 btn-rounded btn btn-light" v-b-tooltip.hover title="Click to Remove" v-if="$attrs.active_courier.key == courier.courier" @click="$emit('courier-selected', {})">
													<i class="bx bx-check-circle text-success"></i> Courier Selected
												</a>
											</b-list-group-item>
										</b-list-group>
										</b-overlay>
									</div>
								</b-card>
							
							</b-col>
							
						</b-row>
					</b-overlay>
						</b-card>
</template>
					<script type="text/javascript">
						export default {
							data: function() {
								return {
									all_carrier_integrations: [],
									loadingShippingSettings: true,
									loadingEcommerceIntegration: false,
									shipping_progress: 0,
									active_carrier_integrations: [],
									active_marketplace_integrations: [],
									shipping_apikeys: '',
									courierapi_response: '',
									shipping_progress: 0,
								}
							},
							mounted(){
								this.getCourierApiKey();
							},
							methods: {
								selectCourier(courier){
									this.loadingShippingSettings = true;
									this.$emit('courier-selected', courier);
									let self = this;
									setTimeout(
										function() {
											self.loadingShippingSettings = false;

										}, 600);
								},
								setUpCourierApiKey(){
							   			var header = {'Authorization' : 'Bearer '+this.$attrs.user.api_token};
							   			let self = this;
							   			var pass = self.generatePassword();
							   			axios.post('https://production.courierapi.co.uk/api/apiuser/create', { 
												    'name': this.$attrs.company.nickname, 
												    'key' : pass
								  			}, { headers: { 
												    'api-user': 'getCarriers', 
												    'api-token': 'griokptfhmzvbaqy', 
												    'Content-Type': 'application/json'
								  			}}).then(response => {
										    //this.setState({ courierapi_response: response.data });
										    this.courierapi_response = response.data;
										    return axios.post('/api/company/order/delivery-options/integration/setup', response.data, {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}});
										  }).then(response => {
										  	console.log(response);
										  	this.shipping_apikeys = response.data;
										  	return this.getShippingIntegrations();
										  }).catch(error => {
										  	console.log(error);
										  });
										  	
							   		},
							   		getCourierApiKey(){
								   			if((this.shipping_apikeys == '') || (this.all_carrier_integrations.length == 0)){
									   			axios.get('/api/company/order/delivery-options/integration', {headers: {
										    		'Authorization' : 'Bearer '+this.$attrs.user.api_token
										    	}}).then(response => {
																		this.shipping_apikeys = response.data;
																			//console.log(this.shipping_apikeys.token);
																			return this.getAllShippingIntegrations();
																	}).then(error => {
																		console.log(error);
																	});
												}
							   		},
							   		getAllShippingIntegrations(){
							   			this.loadingShippingSettings = true;
							   			this.getShippingIntegrations();
							   			this.shipping_progress = 85;
							   			this.getActiveShippingIntegrations();
							   		},
							   		getShippingIntegrations(){
							   			if(this.all_carrier_integrations.length == 0){
								   			axios.get('https://production.courierapi.co.uk/api/couriers/v1/list-couriers', { headers: { 
												    'api-user': this.$attrs.company.nickname, 
												    'api-token': this.shipping_apikeys.token,
												    'Content-Type': 'application/json'
								  			}}).then(response => {
								  				console.log(response.data);
								  				this.all_carrier_integrations = response.data.couriers;
								  				this.shipping_progress = 70;
								  			}).catch(error => {
								  				console.log(error.data);
								  			});
							  			}
							   		},
									getActiveShippingIntegrations(){
										this.loadingShippingSettings = true;
										this.shipping_progress = 20;
								   			if(this.all_carrier_integrations.length == 0){
									   			axios.get('https://production.courierapi.co.uk/api/couriers/v1/list-registered-couriers', { headers: { 
													    'api-user': this.$attrs.company.nickname, 
													    'api-token': this.shipping_apikeys.token,
													    'Content-Type': 'application/json'
									  			}}).then(response => {
									  				console.log(response.data);
									  				this.active_carrier_integrations = response.data;
									  				let self = this;
												         setTimeout(function(){
												         		//self.getActiveShippingIntegrations();
												         		self.shipping_progress = 100;
																  }, 500);
												         setTimeout(function(){
												         		//self.getActiveShippingIntegrations();
												         		self.loadingShippingSettings = false;
																  }, 1000);
									  				
									  			}).catch(error => {
									  				console.log(error.data);
									  			});
								  			}
								   		},
								   		getShippingKey(name){
									      	var integrations = this.all_carrier_integrations.filter(item => item.key == name);
									      	//console.log(integrations);
									      	return integrations[0].key;
									      },
									      getShippingImage(name){
									      	var integrations = this.all_carrier_integrations.filter(item => item.key == name);
									      	//console.log(integrations);
									      	return integrations[0].logo;
									      },
									  }
						}


					</script>