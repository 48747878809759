<script>
import DateTime from 'luxon/src/datetime.js';
  import Interval from 'luxon/src/interval.js';

export default {
  components: {DateTime, Interval},
  props: {
    company: {
      type: Object
    },
    order: {
      type: Object
    },
    user: {
      type: Object
    },
    printable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      storageurl: 'https://'+this.company.nickname+'.s3.eu-west-2.amazonaws.com',
      title: "Invoice",
      invoice_settings: '',
      invoice_no: 0, 
      items: [
        {
          text: "Invoices",
          href: "/"
        },
        {
          text: "Detail",
          active: true
        }
      ]
    };
  },
  created(){

    axios.get(this.storageurl + this.company.logo.replaceAll(this.storageurl, '').replaceAll('//', '/'),  {headers : { 'Authorization' : 'Bearer '+this.user.api_token }} ).then(response => {
      this.parseImage(response.data);
    }).catch(error => {

    });
    this.invoiceSettings();
  },
  methods: {
        invoiceSettings(){
          axios.post('/api/company/settings/get-setting', {type: 'invoice'}, {headers : { 'Authorization' : 'Bearer '+this.user.api_token }}).then(response => {
              this.invoice_settings = response.data;
              //var stringlength = response.data.invoice_no_length - response.data.invoice_no.toString().length;
              //console.log(stringlength);
              this.invoice_no = response.data.invoice_no.toString().padStart(response.data.invoice_no_length, '0');
          }).catch(error => {

          });
      },
    parseDate(date){
        this.timestamp = DateTime.local().toISO();
        console.log(DateTime.fromISO(date));
        var formattedDate = DateTime.fromISO(date);
        return formattedDate.monthLong+' '+formattedDate.day+', '+formattedDate.weekYear+'';
      },
    parseImage(file){
     // var file = file
  
        var reader = new FileReader();
        console.log("next");
          
        reader.onload = function () {
            base64String = reader.result.replace("data:", "")
                .replace(/^.+,/, "");
      
            imageBase64Stringsep = base64String;
      
            // alert(imageBase64Stringsep);
            console.log(base64String);
        }
        reader.readAsDataURL(file);
        }
  }
};
</script>

<template>

    <div class="row printable" id="invoice" :class="{'p-1 m-1' : printable == true}">
      <div class="col-lg-12">
        <div class="card shadow-none">
          <div class="card-body">
            <div class="invoice-title">
               <div class="row">
                <div class="col-2">
              <div class="mb-1">
                <img v-if="company.logo !== null" :src="storageurl + company.logo.replaceAll('//', '/')" alt="logo" class="rounded-circle border avatar-lg" />
              </div>
            </div>
              <div class="col-10 d-flex flex-column">
                <h2  class="float-end ml-auto mt-auto text-primary mb-0"><strong>INVOICE</strong></h2>
              <h4  class="float-end font-size-16 ml-auto mt-auto" :class="{'font-size-14' : printable == true}">Invoice No # <span v-if="order.invoice_created && order.order_invoice !== null" >{{order.order_invoice.invoice_no.toString().padStart(invoice_settings.invoice_no_length, '0')}}</span><span v-else><i>Invoice Not Generated</i></span></h4>
              <h4  class="float-end font-size-16 ml-auto mt-auto" :class="{'font-size-14' : printable == true}">Order # {{order.reference}}</h4>
              <p class="float-end ml-auto mb-0 text-right" :class="{'font-size-11' : printable == true}">{{company.name}} {{company.address1}} {{company.address2}} {{company.address3}} <span v-if="company.county !== null">{{company.county.name}} </span><span v-if="company.country !== null">{{company.country.name}}</span></p>
            </div>
            
          </div>
            </div>
            <hr />
            <div class="row">
              <div class="col-6">
                <address>
                  <strong>Billed To:</strong>
                  <div v-if="order.trade_orders.length !== 0">
                <p class="text-muted mb-0" v-if="order.trade_orders[0].order.customer_invoice_address !== null">
                     <span v-if="order.trade_orders[0].order.customer_invoice_address.address_1">{{order.trade_orders[0].order.customer_invoice_address.address_1}}</span>
                      <span v-if="order.trade_orders[0].order.customer_invoice_address.address_2"><br />{{order.trade_orders[0].order.customer_invoice_address.address_2}}</span>
                      <span v-if="order.trade_orders[0].order.customer_invoice_address.address_3"><br />{{order.trade_orders[0].order.customer_invoice_address.address_3}}</span>
                      <span v-if="order.trade_orders[0].order.customer_invoice_address.county"><br />{{order.trade_orders[0].order.customer_invoice_address.county.name}}</span>
                      <span v-if="order.trade_orders[0].order.customer_invoice_address.country"><br />{{order.trade_orders[0].order.customer_invoice_address.country.name}}</span>
                      <span v-if="order.trade_orders[0].order.customer_invoice_address.postcode"><br />{{order.trade_orders[0].order.customer_invoice_address.postcode}}</span>
                                          </p>
                     <p class="text-muted mb-0" v-else>
                      -- No Address Added --
                     </p>
                  </div>
                  <div v-if="order.customer_orders.length !== 0">
                <p class="text-muted mb-0" v-if="order.customer_orders[0].order.customer_invoice_address !== null">
                     <span v-if="order.customer_orders[0].order.customer_invoice_address.address_1">{{order.customer_orders[0].order.customer_invoice_address.address_1}}</span>
                      <span v-if="order.customer_orders[0].order.customer_invoice_address.address_2"><br />{{order.customer_orders[0].order.customer_invoice_address.address_2}}</span>
                      <span v-if="order.customer_orders[0].order.customer_invoice_address.address_3"><br />{{order.customer_orders[0].order.customer_invoice_address.address_3}}</span>
                      <span v-if="order.customer_orders[0].order.customer_invoice_address.county"><br />{{order.customer_orders[0].order.customer_invoice_address.county.name}}</span>
                      <span v-if="order.customer_orders[0].order.customer_invoice_address.country"><br />{{order.customer_orders[0].order.customer_invoice_address.country.name}}</span>
                      <span v-if="order.customer_orders[0].order.customer_invoice_address.postcode"><br />{{order.customer_orders[0].order.customer_invoice_address.postcode}}</span>
                                          </p>
                     <p class="text-muted mb-0" v-else>
                      -- No Address Added --
                     </p>
                  </div>
                </address>
              </div>
              <div class="col-6 text-end">
                <address>
                  <strong>Shipped To:</strong>
                    <div v-if="order.trade_orders.length !== 0">
                <p class="text-muted mb-0" v-if="order.trade_orders[0].order.customer_delivery_address !== null">
                     <span v-if="order.trade_orders[0].order.customer_delivery_address.address_1">{{order.trade_orders[0].order.customer_delivery_address.address_1}}</span>
                      <span v-if="order.trade_orders[0].order.customer_delivery_address.address_2"><br />{{order.trade_orders[0].order.customer_delivery_address.address_2}}</span>
                      <span v-if="order.trade_orders[0].order.customer_delivery_address.address_3"><br />{{order.trade_orders[0].order.customer_delivery_address.address_3}}</span>
                      <span v-if="order.trade_orders[0].order.customer_delivery_address.county"><br />{{order.trade_orders[0].order.customer_delivery_address.county.name}}</span>
                      <span v-if="order.trade_orders[0].order.customer_delivery_address.country"><br />{{order.trade_orders[0].order.customer_delivery_address.country.name}}</span>
                      <span v-if="order.trade_orders[0].order.customer_delivery_address.postcode"><br />{{order.trade_orders[0].order.customer_delivery_address.postcode}}</span>
                                          </p>
                     <p class="text-muted mb-0" v-else>
                      -- No Address Added --
                     </p>
                  </div>
                    <div v-if="order.customer_orders.length !== 0">
                <p class="text-muted mb-0" v-if="order.customer_orders[0].order.customer_delivery_address !== null">
                     <span v-if="order.customer_orders[0].order.customer_delivery_address.address_1">{{order.customer_orders[0].order.customer_delivery_address.address_1}}</span>
                      <span v-if="order.customer_orders[0].order.customer_delivery_address.address_2"><br />{{order.customer_orders[0].order.customer_delivery_address.address_2}}</span>
                      <span v-if="order.customer_orders[0].order.customer_delivery_address.address_3"><br />{{order.customer_orders[0].order.customer_delivery_address.address_3}}</span>
                      <span v-if="order.customer_orders[0].order.customer_delivery_address.county"><br />{{order.customer_orders[0].order.customer_delivery_address.county.name}}</span>
                      <span v-if="order.customer_orders[0].order.customer_delivery_address.country"><br />{{order.customer_orders[0].order.customer_delivery_address.country.name}}</span>
                      <span v-if="order.customer_orders[0].order.customer_delivery_address.postcode"><br />{{order.customer_orders[0].order.customer_delivery_address.postcode}}</span>
                                          </p>
                     <p class="text-muted mb-0" v-else>
                      -- No Address Added --
                     </p>
                  </div>
                </address>
              </div>
            </div>
            <div class="row">
              <div class="col-6 mt-3">
                <address>
                  <strong>Payment Method:</strong>
                   <div v-if="order.trade_orders.length !== 0">
                    Debit / Credit Card via Stripe
                    <br />Card ending ****
                   </div>
                   <div v-if="order.customer_orders.length !== 0">
                    Debit / Credit Card via Stripe
                    <br />Card ending ****
                   </div>

                  <br /> <div v-if="order.trade_orders.length > 0">
                        <a :href="'/seller/profile/'+order.trade_orders[0].order.customer.nickname+''"><h6 class="mb-0">{{order.trade_orders[0].order.customer.name}}</h6>
                        {{order.trade_orders[0].order.customer.email}}</a>
                      </div>
                      <div v-if="order.customer_orders.length > 0">
                         <a :href="'/customer/details/'+order.customer_orders[0].order.customer.id+''"><h6 class="mb-0">{{order.customer_orders[0].order.customer.name}} {{order.customer_orders[0].order.customer.lastname}}</h6>
                        {{order.customer_orders[0].order.customer.email}}</a>
                      </div>
                </address>
              </div>
              <div class="col-6 mt-3 text-end">
                <address>
                  <strong>Order Date:</strong>
                  <br />{{parseDate(order.created_at)}}
                  <br />
                  <br />
                </address>
              </div>
            </div>
            <div class="p-2 mt-3">
              <h3 class="font-size-16">Order summary</h3>
            </div>
            <div class="table-responsive">
              <table class="table" :class="{'font-size-12' : printable == true}">
                <thead>
                  <tr>
                    <th style="width: 70px;">REF.</th>
                    <th>Product Name</th>
                    <th class="text-end">Price</th>
                    <th class="text-end">Quantity</th>
                    <th class="text-end">Subtotal Exc VAT</th>
                    <th class="text-end">Subtotal Inc VAT</th>
                  </tr>
                </thead>
                <tbody>
                  
                  <tr v-for="product in order.products">
                    <td>{{product.product.reference}}</td>
                    <td>{{product.product.name}}</td>
                    <td class="text-end" >{{parseFloat(product.price_inc).toFixed(2)}}</td>
                    <td class="text-end">{{product.quantity}}</td>
                    <td class="text-end">{{(parseFloat(product.price_exc) * parseFloat(product.quantity)).toFixed(2)}}</td>
                    <td class="text-end">{{(parseFloat(product.price_inc) * parseFloat(product.quantity)).toFixed(2)}}</td>
                  </tr>

                  
                  <tr>
                    <td colspan="4" class="text-end">Sub Total</td>
                    <td class="text-end">£{{parseFloat(order.total_products_exc).toFixed(2)}}</td>
                    <td class="text-end">£{{parseFloat(order.total_products_inc).toFixed(2)}}</td>
                  </tr>
                  <tr>
                    <td colspan="4" class="border-0 text-end">
                      <strong>Customisations</strong>
                    </td>
                    <td class="border-0 text-end">£{{(parseFloat(order.total_combinations_exc == null ? 0.00 : order.total_combinations_exc) + parseFloat(order.total_variants_exc == null ? 0.00 : order.total_variants_exc)).toFixed(2)}}</td>
                    <td class="border-0 text-end">£{{(parseFloat(order.total_combinations_inc == null ? 0.00 : order.total_combinations_inc) + parseFloat(order.total_variants_inc == null ? 0.00 : order.total_variants_inc)).toFixed(2)}}</td>
                  </tr>
                  <tr>
                    <td colspan="4" class="border-0 text-end">
                      <strong>Shipping</strong>
                    </td>
                    <td class="border-0 text-end">£{{parseFloat(order.total_shipping_exc).toFixed(2)}}</td>
                    <td class="border-0 text-end">£{{parseFloat(order.total_shipping_inc).toFixed(2)}}</td>
                  </tr>
                  <tr>
                    <td colspan="4" class="border-0 text-end">
                      <strong>Total</strong>
                    </td>
                    <td class="border-0 text-end">
                      <h4 class="m-0">£{{parseFloat(order.total_order_exc).toFixed(2)}}</h4>
                    </td>
                    <td class="border-0 text-end">
                      <h4 class="m-0">£{{parseFloat(order.total_order_inc).toFixed(2)}}</h4>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="d-print-none" v-if="printable == false">
              <div class="float-end">
                <a @click="$emit('print-pdf')" class="btn-rounded btn btn-success">
                 Print <i class="fa fa-print"></i>
                </a>
                <a @click="$emit('process-pdf')" class="btn-rounded btn btn-primary w-md ml-1" v-if="order.invoice_created == 0">Process Invoice <i class="bx bxs-file-pdf"></i></a>
                <a @click="$emit('send-pdf')" class="btn-rounded btn btn-primary w-md ml-1" v-else>Send Invoice <i class="bx bx-mail-send"></i></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>