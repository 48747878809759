<script>
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import vue2Dropzone from 'vue2-dropzone'
import Multiselect from 'vue-multiselect'

import Layout from '../../layouts/main'
import PageHeader from '../../components/page-header'
import FilterListTree from "../../components/widgets/FilterListTree";
import FilterProductBrands from "../../components/widgets/FilterProductBrands";
/**
 * Add-product component
 */
export default {
  components: { vueDropzone: vue2Dropzone, Multiselect, Layout, PageHeader, ckeditor: CKEditor.component, FilterListTree, FilterProductBrands },
  data() {
    return {
      name: 'AddProduct',
      title: 'Add Product',
      items: [
        {
          text: 'Ecommerce',
          href: '/',
        },
        {
          text: 'Add Product',
          active: true,
        },
      ],
      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        thumbnailHeight: 300,
        thumbnailWidth: 300,
      },
      editor: ClassicEditor,
      editorData:
        "<h3>Hello World!</h3><h5><b>This is an simple editable area.</b></h5>",
      content: "<h1>Some initial content</h1>",
      value: null,
      value1: null,
      all_brands: [],
      all_tax_rules: [],
      all_attributes: [],
      attribute_values: [],
      selected_customer_groups: [],
      selected_customer_type: '',
      product_brand_id: null,
      product_type_id: 0,
      cost_price: 0,
      trade_price: 0,
      sales_price: 0,
      inc_tax_price: 0,
      import_tax: 0,
      export_tax: 0,
      active_tax_rule: 0,
      carnet_price: 0,
      carnet_weight: 0,
      profit_margin: 0,
      calculated_cost_price: 0,
      calculated_tax_duties: 0,
      bulkquantity: 0,
      product_attributes: [],
      product_variants: [],
      product_bulk_pricing: [],
      product_condition: [],
      selected_attribute: '',
      active_tax_rule: '',
      searchbrand: '',
      searchresult: '',
      brandsearch_results: [],
      brandresults_start: 0,
      brandresults_end: 5,
      product_attribute_success: null,
      quantity_lang:{
            bulk:{
              qtytooltip: 'Add quantity where discount starts to apply. For example if you enter quantity of 10, discounts will apply on orders of 10 and above.'
            }
          },
      conditions: [
        'New',
        'Used',
        'Refurbished',
        'Custom',
      ],
      options: [
        'Alaska',
        'Hawaii',
        'California',
        'Nevada',
        'Oregon',
        'Washington',
        'Arizona',
        'Colorado',
        'Idaho',
        'Montana',
        'Nebraska',
        'New Mexico',
        'North Dakota',
        'Utah',
        'Wyoming',
        'Alabama',
        'Arkansas',
        'Illinois',
        'Iowa',
      ],
    }
  },
  methods:{
       searchBrands(){
            if(this.searchbrand.length > 2){
                this.brandsearch_results = this.all_brands.filter(item => item.name.toLowerCase().match(this.searchbrand));
                    if(!this.brandsearch_results.length){
                            this.brandsearch_results = this.all_brands.filter(item => item.name.match(this.searchbrand));
                    }
            }
          },
          activeBrand(id){
             return this.all_brands.filter(item => item.id === id);
          },
          brandsDefault(){
            if(this.searchbrand.length < 2 || this.searchbrand === undefined){
            this.brandsearch_results = this.all_brands;
            }
          },
          brandsOnBlur(){
            if(this.searchbrand.length < 1){
                //this.brandsearch_results = [];
            }
            this.brandresults_end = 5;
            this.brandresults_start = 0;
          },
          brandsOnFocus(){
             axios.get('/seller/allbrands').then(response => {
                 console.log(response);
                this.all_brands = response.data;
                this.brandsearch_results = this.all_brands;
            })
            
          },
          toggleBrand(isdelete, selectedbrandid){
            if(isdelete === false){
            this.product_brand_id = selectedbrandid;
            }
            if(isdelete === true){
            this.product_brand_id = null;
            }
            /*axios.post('http://localhost:7080/gemesislabs-framework/public/seller/product/'+this.productdata[0].id+'/togglebrand', {brandid: selectedbrandid, delete: isdelete}).then(response => {
                 console.log(response);
                //this.product_attribute_success = response.data;
            })*/
          },
          toggleBulkPricing(isdelete, bulk_price_id){
            if(isdelete === false){
                if( (this.discount_type != '') && (this.discount_amount != 0) && (this.bulkquantity != 0) && (this.selected_customer_type != '') && (this.selected_customer_group != '')){
                    axios.post('http://localhost:7080/gemesislabs-framework/public/seller/product/'+this.productdata[0].id+'/togglebulkprice', {discounttype: this.discount_type, discountamount: this.discount_amount, quantity: this.bulkquantity, customertype: this.selected_customer_type, customergroup: this.selected_customer_group, delete: isdelete, bulkpricing_id: bulk_price_id}).then(response => {
                         console.log(response);
                        //this.product_attribute_success = response.data;
                    })
                }
            }
            if(isdelete === true){
                 axios.post('http://localhost:7080/gemesislabs-framework/public/seller/product/'+this.productdata[0].id+'/togglebulkprice', {discounttype: this.discount_type, discountamount: this.discount_amount, quantity: this.bulkquantity, customertype: this.selected_customer_type, customergroup: this.selected_customer_group, delete: isdelete, bulkpricing_id: bulk_price_id}).then(response => {
                     console.log(response);
                    //this.product_attribute_success = response.data;
                }) 
            }
            this.$emit('bulkprices-added');
          }, 
          calcCostPrice(){
            var tax_duties = JSON.parse(this.import_tax) + JSON.parse(this.export_tax) + JSON.parse(this.carnet_price);
            this.calculated_tax_duties = tax_duties.toFixed(2);
            var cost_price = JSON.parse(this.cost_price) + JSON.parse(this.calculated_tax_duties);
            this.calculated_cost_price = cost_price.toFixed(2);
            this.calcProfitMargin();
          }, 
          updateTaxCalc(){
            var setMultiplier = this.activeTaxPercentage / 100;
            var multiplier = setMultiplier + 1;
            var calculation = JSON.parse(this.sales_price) * multiplier;
            this.inc_tax_price = calculation.toFixed(2);
            this.sales_price = this.sales_price.toFixed(2);
            this.calcProfitMargin();
          },
          removeVatCalc(){
            var setMultiplier = this.activeTaxPercentage / 100;
            var multiplier = setMultiplier + 1;
            var calculation = JSON.parse(this.inc_tax_price) / multiplier;
            this.sales_price = calculation.toFixed(2);
            this.calcProfitMargin();
          },
           activeTaxRule(id){
            var activeObject = this.all_tax_rules.filter(item => item.id === id);
            console.log(activeObject);
            this.active_tax_percentage = activeObject[0].percentage;
            return activeObject[0].percentage;
          },
          calcProfitMargin(){
            var salesSubCost = JSON.parse(this.sales_price).toFixed(2) - JSON.parse(this.calculated_cost_price).toFixed(2);
            var multiplier = salesSubCost / JSON.parse(this.calculated_cost_price).toFixed(2);
            var calculation = multiplier * 100;
            this.profit_margin = calculation.toFixed(2);
          },
          formatCurrency: function(price){
            return price.toFixed(2);
          },
          getAttributeValues(){
            this.product_attribute_success = null;
            this.selected_value = '';
            this.attribute_values = [];
            axios.get('/seller/product/attribute/'+this.selected_attribute+'/values').then(response => {
                 console.log(response);
                this.attribute_values = response.data;
            })
          },
          getAttributeName(id){
            var attributes = this.all_attributes;
            var name = attributes.filter(item => item.id == id);
            return name[0].name;
          },
          getAttributeValueName(attributes, id){
            var filteredResult = attributes.filter(item => item.id == id);
            return filteredResult[0].name;
          },
          getAttributeVariantValues(index){
            this.product_attribute_success = null;
            //this.selected_value = '';
            //this.attribute_values = [];
            axios.get('/seller/product/attribute/'+this.product_variants[index].attribute_id+'/values').then(response => {
                 console.log(response);
                this.product_variants[index].attribute_values = response.data;
            })
          },
          addProductAttribute(){
           if(this.selected_value != '' && this.selected_attribute != ''){
            axios.post('http://localhost:7080/gemesislabs-framework/public/seller/product/attribute/create', {product_id: this.productdata[0].id, attribute_id: this.selected_attribute, value_id: this.selected_value, measurement_unit_id: this.selected_measurement_unit, measurement_value: this.measurement_unit_value}).then(response => {
                 console.log(response);
                this.product_attribute_success = response.data;
              })
            EventBus.$emit('attribute-added');
            this.selected_value = '';
            this.selected_attribute = '';
            this.selected_measurement_unit = '';
            this.measurement_unit_value = '';
            this.attribute_values = [];
            this.showAlert();
            }
          },
          destroyProductAttribute(id){
            axios.post('http://localhost:7080/gemesislabs-framework/public/seller/product/attribute/'+id+'/destroy').then(response => {
                 console.log(response);
                //this.product_attribute_success = response.data;
            })
            axios.get('http://localhost:7080/gemesislabs-framework/public/seller/product/'+this.productdata[0].id+'/attributes').then(response => {
                 console.log(response);
                this.product_attributes = response.data;
            })
          },
          addVariant(){
            this.product_variants.push({
                    "product_id": '',
                    "id": '',
                    "attribute_id": '',
                    "attribute_value_id": '',
                    "measurement_unit_value": '',
                    "measurement_unit_id": '',
                    "price_difference": '',
                    "quantity": '',
                    "is_custom": 0,
                    "custom_value":  '',
                    "custom_text": '', 
                    "active": 1,
                    "default": 1,
                    attribute_values:[]
                });
          },
          addCustomVariant(){
            this.product_variants.push({
                    "product_id": '',
                    "id": '',
                    "attribute_id": '',
                    "attribute_value_id": '',
                    "measurement_unit_value": '',
                    "measurement_unit_id": '',
                    "price_difference": '',
                    "quantity": '',
                    "is_custom": 1,
                    "custom_value":  '',
                    "custom_text": '', 
                    "active": 1,
                    "default": 1,
                    attribute_values:[]
                });
          },
          removeVariant(index){
            this.product_variants.splice(index, 1);
          },
  },
  created(){
        axios.get('/seller/product/taxrules').then(response => {
                 console.log(response);
                this.all_tax_rules = response.data;
                this.active_tax_rule = response.data[0].id;
                //this.brandsearch_results = this.all_brands;
            });
        axios.get('/seller/product/allattributes').then(response => {
                 console.log(response);
                this.all_attributes = response.data;
            })
        axios.get('/seller/product/attribute/measurement-units').then(response => {
                 console.log(response);
                this.measurement_units = response.data;
            });

  },
  computed: {
            activeTaxPercentage: function(){
                var activeObject = this.all_tax_rules.filter(item => item.id === this.active_tax_rule);
                this.active_tax_percentage = activeObject[0].percentage;
                var setMultiplier = activeObject[0].percentage / 100;
                var multiplier = setMultiplier + 1;
                var calculation = this.sales_price * multiplier;
                return activeObject[0].percentage;
            },
            calculateIncVat: function(){
                var activeObject = this.all_tax_rules.filter(item => item.id === this.active_tax_rule);
                this.active_tax_percentage = activeObject[0].percentage;
                var setMultiplier = activeObject[0].percentage / 100;
                var multiplier = setMultiplier + 1;
                var calculation = this.sales_price * multiplier;
                this.inc_tax_price = calculation.toFixed(2);
                this.calcCostPrice();
                this.calcProfitMargin();
                return calculation.toFixed(2);
          },
  }
}
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-8">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Basic Information <b-button v-b-toggle.basicinfo class="m-1" variant="light" rounded><i class="bx bx-plus"></i></b-button></h4>
            <p class="card-title-desc">Fill all information below</p>
            <b-collapse id="basicinfo" visible class="mt-1">
            <form>
              <div class="row mt-3">
                <div class="col-sm-12">
                  <div class="row">
                    <div class="col-8">
                    <div class="form-group">
                      <label for="productname">Product Name</label>
                      <input id="productname" name="productname" type="text" class="form-control" />
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="form-group">
                      <label for="productname">Reference</label>
                      <input id="productname" name="productname" type="text" class="form-control" />
                    </div>
                  </div>
                  </div>
                </div>
                 <div class="col-sm-12">
          <b-tabs pills variant="light" content-class="px-0 pt-2 text-muted">
              <b-tab active>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-home"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">Summary</span>
                </template>
                <div class="form-group">
                    <textarea id="productdesc" class="form-control" rows="5"></textarea>
                  </div>
              </b-tab>
              <b-tab>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="far fa-user"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">Description</span>
                </template>
                  <ckeditor v-model="editorData" :editor="editor"></ckeditor>
              </b-tab>
              <b-tab>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="far fa-envelope"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">Trade Description</span>
                </template>
                <ckeditor v-model="editorData" :editor="editor"></ckeditor>
              </b-tab>
            </b-tabs>
                  
                </div>

                <div class="col-sm-4">
                 
                  
                </div>
              </div>

            </form>
          </b-collapse>
          </div>
        </div>
         <div class="card">
          <div class="card-body">
            <h4 class="card-title">Images <b-button v-b-toggle.basicinfo class="m-1" variant="light" rounded><i class="bx bx-plus"></i></b-button></h4>
            <p class="card-title-desc">Fill all information below</p>
            <b-collapse id="basicinfo" visible class="mt-1">
            <vue-dropzone
              id="dropzone"
              ref="myVueDropzone"
              :use-custom-slot="true"
              :options="dropzoneOptions"
            >
              <div class="dropzone-custom-content">
                <div class="mb-1">
                  <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                </div>
                <h4>Drop files here or click to upload.</h4>
              </div>
            </vue-dropzone>
          </b-collapse>
          </div>
        </div>

        
        <!-- end card-->

         <div class="card">
          <div class="card-body">
            <h4 class="card-title">Pricing <b-button v-b-toggle.pricing class="m-1" variant="light" rounded><i class="bx bx-plus"></i></b-button></h4>
              <b-collapse id="pricing" class="mt-1">
                <div class="row">
                    <div class="col-md-12">
                    <div class="row">
                    <div class="col-md-12">
                    <h4 class="mt-4 mb-1 pt-2 card-title">
                  Cost & Trade Pricing</h4></div><div class="col-md-3">
                    <div class="form-control-group">
                    <label class="mt-4">
                  Cost Price</label><div class="input-group">
                    <div class="input-group-prepend">
                    <span class="input-group-text">
                  £</span></div><input  min="0.00" v-on:change="calcCostPrice" v-model.number="cost_price" data-number-to-fixed="2" type="number" step="0.01" class="form-control">
                </div></div></div><div class="col-md-3">
                    <div class="form-control-group">
                    <label class="mt-4">
                  Trade Price</a></span></label><div class="input-group">
                    <div class="input-group-prepend">
                    <span class="input-group-text">
                  £</span></div><input v-model.number="trade_price" type="number" placeholder="0.00" step="0.01" class="form-control">
                </div></div></div><div class="col-md-3">
                    <div class="form-control-group">
                    <label class="mt-4">
                    Carnet Price <small>Exc VAT</small></label><div class="input-group">
                    <div class="input-group-prepend">
                    <span class="input-group-text">
                  £</span></div><input v-model.number="carnet_price" type="number" placeholder="0.00" step="0.01" class="form-control"  v-on:change="calcCostPrice">
                </div></div></div><div class="col-md-3">
                    <div class="form-control-group">
                    <label class="mt-4">
                  Carnet Weight </label><div class="input-group">
                    <input v-model.number="carnet_weight" type="number" placeholder="0.00" step="0.01" class="form-control">
                  <div class="input-group-append">
                    <span class="input-group-text">
                  .g</span></div></div></div></div><div class="col-md-3">
                    <div class="form-control-group">
                    <label class="mt-4">
                    Import Tax <small>Exc VAT</small></label><div class="input-group">
                    <div class="input-group-prepend">
                    <span class="input-group-text">
                  £</span></div><input type="number" v-model.number="import_tax" v-on:change="calcCostPrice" placeholder="0.00" step="0.01" class="form-control">
                </div></div></div><div class="col-md-3">
                    <div class="form-control-group">
                    <label class="mt-4">
                    Export Tax <small>Inc VAT</small></label><div class="input-group">
                    <div class="input-group-prepend">
                    <span class="input-group-text">
                  £</span></div><input v-on:change="calcCostPrice" type="number" v-model.number="export_tax" placeholder="0.00" step="0.01" class="form-control">
                </div></div></div></div></div><div class="col-md-12">
                    <h4 class="mt-4 mb-1 pt-2 card-title">
                  Consumer Pricing</h4></div><div class="col-md-3">
                    <div class="form-control-group">
                    <label class="mt-4">
                    Consumer Price <small>Exc VAT</small></label><div class="input-group">
                    <div class="input-group-prepend">
                    <span class="input-group-text">
                  £</span></div><input v-on:change="updateTaxCalc" type="number" v-model.number="sales_price" placeholder="0.00" step="0.01" class="form-control">
                </div></div></div><div class="col-md-6">
                    <div class="form-control-group">
                    <label class="mt-4">
                  Tax Rule</label><select class="form-control" v-model.number="active_tax_rule"  v-on:change="activeTaxRule(active_tax_rule)">
                    <option v-for="rule in all_tax_rules" v-bind:value="rule.id">
                  {{rule.code}}: {{rule.percentage}}%</option></select></div></div><div class="col-md-3">
                    <div class="form-control-group">
                    <label class="mt-4">
                    <small>Inc VAT</small> <span class="float-right">
                    <small>More options</small><a href="javascript:;" @click="current = 4">
                    <i class="fa fa-external-link">
                  </i></a></span></label><div class="input-group">
                    <div class="input-group-prepend">
                    <span class="input-group-text">
                  £</span></div><input  v-model="inc_tax_price" v-on:change="removeVatCalc" type="number" placeholder="0.00" step="0.01" class="form-control">
                </div></div></div>
                <div class="col-md-12 mt-4">
                    <div class="card border border-primary">
                      <div class="card-header bg-transparent border-primary">
                        <h5 class="my-0 text-primary"><i class="mdi mdi-bullseye-arrow mr-3"></i> Pricing Calculations </h5>
                      </div>
                      <div class="card-body">
                        <div class="row">
                          <div class="col-md-2 count-div d-flex flex-column">
                            <h5 class="text-primary font-size-24 mt-auto">£{{cost_price}}</h5>
                            <label class="counts-title mb-auto">Cost Price</label>
                          </div>
                          <div class="col-md-2 count-div d-flex flex-column">
                            <h5 class="text-primary font-size-24 mt-auto">£{{calculated_tax_duties}}</h5>
                            <label class="counts-title mb-auto">Taxes & Duties</label>
                          </div>
                          <div class="col-md-2 count-div d-flex flex-column">
                            <h5 class="text-primary font-size-24 mt-auto">£{{calculated_cost_price}}</h5>
                            <label class="counts-title mb-auto">Total Cost</label>
                          </div>
                          <div class="col-md-2 count-div d-flex flex-column">
                            <h5 class="text-primary font-size-24 mt-auto">£{{trade_price}}</h5>
                            <label class="counts-title mb-auto">Trade Price</label>
                          </div>
                          <div class="col-md-2 count-div d-flex flex-column">
                            <h5 class="text-primary font-size-24 mt-auto">£{{sales_price}} </h5><small>Exc. VAT</small><br>
                            <h5 class="text-primary font-size-24 mt-auto">£{{calculateIncVat}} </h5><small>Inc. VAT</small>
                            <label class="counts-title mb-auto">Consumer Price</label>
                          </div>
                          <div class="col-md-2 count-div d-flex flex-column">
                            <h5 class="text-primary font-size-24 mt-auto">{{profit_margin}}%</h5>
                            <label class="counts-title mb-auto">Profit Margin</label>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <h5 class="mt-4 mt-4 mb-1 pt-2 form-row-title">Custom Pricing <b-button v-b-toggle.custompricing class="m-1" variant="light" rounded><i class="bx bx-plus"></i></b-button></h5>
                </div>
                  <b-collapse id="custompricing" class="mt-1">
                  <div class="col-md-12">
                    <div class="row">
                    <div class="col-md-12">
                    <div class="row">
                    <div class="col-md-3">
                    <div class="form-control-group">
                    <label class="mt-4">
                    Customer Type <i class="ti-help info-tooltip" v-b-tooltip.hover :title="quantity_lang.bulk.qtytooltip">
                  </i></label><div class="input-group">
                    <select type="select" class="form-control" v-model.number="selected_customer_type" @change="getCustomerGroups(selected_customer_type)">
                    <option value="" placeholder="" selected>-- Select Customer Type --</option><option v-for="customertype in all_customer_types" v-bind:value="customertype.id" >{{customertype.name}}</option></select></div></div></div><div class="col-md-3">
                    <div class="form-control-group">
                    <label class="mt-4">
                    Customer Group <i class="ti-help info-tooltip" v-b-tooltip.hover :title="quantity_lang.bulk.qtytooltip">
                  </i></label><div class="input-group">
                    <select type="select" class="form-control" v-model.number="selected_customer_group">
                    <option value="" placeholder="" selected>-- Select Customer Group --</option><option v-for="customergroup in selected_customer_groups" v-bind:value="customergroup.id">
                  {{customergroup.name}}</option></select></div></div></div><div class="col-md-2">
                    <div class="form-control-group">
                    <label class="mt-4">
                    Bulk Quantity <i class="ti-help info-tooltip" v-b-tooltip.hover :title="quantity_lang.bulk.qtytooltip">
                  </i></label><div class="input-group">
                    <input type="number" placeholder="0.00" step="0.01" class="form-control" v-model.number="bulkquantity">
                </div></div></div><div class="col-md-2">
                    <div class="form-control-group">
                    <label class="mt-4">
                  Discount Type / Amount</a></span></label><div class="input-group">
                    <div class="input-group-prepend">
                    <select class="input-group-text" v-model="discount_type">
                    <option value="currency">
                  £</option><option value="percentage">
                  %</option><i class="fa fa-caret">
                  </i></select></div><input type="number" placeholder="0.00" step="0.01" class="form-control" v-model="discount_amount">
                </div></div></div><div class="col-md-2 center-flex mt-4">
                    <a class="btn btn-outline-light btn-rounded mt-4" @click="toggleBulkPricing(false, null)">
                    Add Discount Rule <i class="bx bx-plus">
                  </i></a></div><div class="single-table col-md-12 mt-4">
                    <div class="table-responsive">
                    <table class="table text-center" v-if="product_bulk_pricing.length > 0">
                    <thead class="text-uppercase">
                    <tr><th scope="col">
                  Discount Type</th><th scope="col">
                  Discount</th><th scope="col">
                  QTY Starts at:</th><th scope="col">
                  Customer Type</th><th scope="col">
                  Customer Group</th><th scope="col">
                  Actions</th></tr></thead><tbody><tr v-for="bulk in product_bulk_pricing">
                    <th scope="row">
                  {{bulk.discount_type}}</th><td>{{bulk.price}}</td><td>{{bulk.quantity}}</td><td>{{filterCustomerType(bulk.customer_type_id)}}</td><td>{{bulk.customer_group_id}}</td><td><a href="javascript:;" @click="toggleBulkPricing(true, bulk.id)">
                    <i class="ti-trash">
                  </i></a></td></tr></tbody></table>
                </div></div></div></div></div></div></b-collapse>
              </div>
              </b-collapse>
          </div>
        </div>

         <div class="card">
          <div class="card-body">
            <h4 class="card-title">Quantity <b-button v-b-toggle.quantity class="m-1" variant="light" rounded><i class="bx bx-plus"></i></b-button></h4>
              <b-collapse id="quantity" class="mt-1">
                <div class="row">
                    <div class="col-md-3">
                      <div class="form-control-group">
                      <label>Total Quantity</label><input type="number" class="form-control">
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-control-group">
                      <label>Minimum Sale Quantity</label><input type="number" class="form-control">
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-control-group">
                      <label>Maximium Sale Quantity</label><input type="number" class="form-control">
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-control-group">
                      <label>Low Stock Level</label><input type="number" class="form-control">
                  </div></div>
                  <div class="col-md-3">
                      <div class="form-control-group">
                      </div>
                  </div>
                </div>
              </b-collapse>
          </div>
        </div>

        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Features <b-button v-b-toggle.features class="m-1" variant="light" rounded><i class="bx bx-plus"></i></b-button></h4>
              <b-collapse id="features" class="mt-1">
                <div class="row">
                <div class="col-md-6">
                <div class="row">
                <div class="col-md-12">
                <h5 class="mt-4 mb-4 pb-2 form-row-title">
              Add New</h5></div><div class="col-md-12">
                <div class="form-control-group mb-3">
                <select type="select" class="form-control" v-model="selected_attribute" v-on:change="getAttributeValues">
                <option value="" selected>-- Add New Attribute --</option><option v-for="attribute in all_attributes" v-bind:value="attribute.id">
              {{attribute.name}}</option></select></div></div>
              <div class="col-md-12">
                <div class="form-control-group mb-3">
                <select type="select" class="form-control" v-model="selected_value">
                <option value="" selected>-- Add New Value --</option><option v-for="values in attribute_values" v-bind:value="values.id">
              {{values.name}}</option></select>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-control-group mb-3">
                  <input type="number" step="0.01" v-model="measurement_unit_value" class="form-control" placeholder="Add size, weight, length unit value">
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-control-group mb-3">
                <select type="select" class="form-control" v-model="selected_measurement_unit">
                <option value="" selected>-- Select Units --</option><option v-for="values in measurement_units" v-bind:value="values.id">
              {{values.name}}</option></select>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-control-group">
                <a class="btn btn-outline-light btn-rounded btn-block" href="javascript:;"  v-on:click="addProductAttribute">
              Add New Attribute & Value Pair</a></div>
              </div>
              <div class="col-md-12 mt-3">
                <transition name="fade">
                <div v-if="product_attribute_success === true">
                <b-alert :show="dismissCountDown" dismissible variant="success" @dismissed="dismissCountDown=0" @dismiss-count-down="countDownChanged">
                Attribute added!<small class="float-right">
              This message will self destruct in: {{ dismissCountDown }} seconds...</small><b-progress variant="success" :max="dismissSecs"   :value="dismissCountDown" height="4px">
              </b-progress></b-alert></div></transition><transition name="fade" mode="out-in" enter-active-class="fade" leave-active-class="fade" :duration="{ enter: 500, leave: 800 }">
                <div v-if="product_attribute_success === false">
                <b-alert :show="dismissCountDown" dismissible variant="danger" @dismissed="dismissCountDown=0" @dismiss-count-down="countDownChanged">
                <strong>Uh-oh!</strong> Attribute set already exists! <small class="float-right">
              This message will self destruct in: {{ dismissCountDown }} seconds...</small><b-progress variant="danger" :max="dismissSecs" :value="dismissCountDown" height="4px">
              </b-progress></b-alert></div></transition></div></div></div>
              <div class="col-md-6">
                <div class="row">
                <div class="col-md-12">
                <h5 class="mt-4 mb-4 pb-2 form-row-title">
              Active Attributes</h5></div><div class="col-md-12">
                <div class="form-control-group">
                <ul class="list-group">
                <li v-for="active_attributes in product_attributes" class="list-group-item list-group-item p-0 d-flex">
                <span class="my-auto pl-4 w-100">{{active_attributes.attribute_name}}: {{active_attributes.measurement_value}}{{active_attributes.measurement_suffix}} {{active_attributes.value_name}}</span><a class="btn btn-remove float-right border-0" href="javascript:;" v-on:click="destroyProductAttribute(active_attributes.id)">
                <font-awesome-icon icon="times" color="#5E3BB0" /></a></li></ul></div></div></div></div>

            </div>
              </b-collapse>
          </div>
        </div>

        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Variations <b-button v-b-toggle.variations class="m-1" variant="light" rounded><i class="bx bx-plus"></i></b-button></h4>
              <b-collapse id="variations" class="mt-1">
                  <div class="row">
    <div class="col-md-12">
      <h5 class="mt-4 pb-2 form-row-title">Product Variations</h5>
      
<div class="row mr-2 ml-0" v-if="product_variants.length == 0">
  <div class="col-md-12 bg-outline-muted p-3 text-center">
    <font-awesome-icon icon="info-circle" size="lg" /> No Variants Added
  </div>
</div>  
<div class="list-group">
<div class="list-group-item" v-for="(variant, index) in product_variants">
  <div class="row">
  <div class="col-md-2">
    <div class="form-control-group mb-3" v-if="product_variants[index].id == ''">
      <label class="mb-0">Variant Attribute</label>
      <select type="select" class="form-control" v-model="product_variants[index].attribute_id" v-on:change="getAttributeVariantValues(index)">
        <option value="" selected>-- Add New Attribute --</option>
        <option  v-for="attribute in all_attributes" :value="attribute.id">
          {{attribute.name}}
        </option>
      </select>
    </div>
  </div>
  <div class="col-md-2">
    <div class="form-control-group mb-3" v-if="product_variants[index].is_custom == 0"> 
    <label class="mb-0">Variant Attribute Type</label>
    <select type="select" class="form-control" v-model="product_variants[index].attribute_value_id">
    <option value="" selected>-- Add New Value --</option><option v-for="values in product_variants[index].attribute_values" v-bind:value="values.id">
  {{values.name}}</option></select>
    </div>
    <div v-else class="form-control-group mb-3" v-if="product_variants[index].is_custom == 1"> 
    <label class="mb-0">Custom Variant Type</label>
    <input v-model="product_variants[index].custom_text" type="text" class="form-control">
    </div>

  </div>
  <div class="col-md-1">
    <div class="form-control-group mb-3">
      <label class="mb-0">Value</label>
      <input type="number" step="0.01" v-model="product_variants[index].measurement_unit_value" class="form-control" placeholder="Add size, weight, length unit value">
    </div>
  </div>
  <div class="col-md-2">
    <div class="form-control-group mb-3">
    <label class="mb-0">Value Units</label>
    <select type="select" class="form-control" v-model="product_variants[index].measurement_unit_id">
    <option value="" selected>-- Select Units --</option><option v-for="values in measurement_units" v-bind:value="values.id">
  {{values.name}}</option></select>
    </div>
  </div>
  <div class="col-md-1">
    <div class="form-control-group mb-3">
      <label class="mb-0">Price Impact</label>
      <input type="number" step="0.01" v-model="product_variants[index].price_difference" class="form-control" placeholder="Price Difference">
    </div>
  </div>
  <div class="col-md-1">
    <div class="form-control-group mb-3">
      <label class="mb-0">Quantity</label>
      <input type="number" step="0.01" v-model="product_variants[index].quantity" class="form-control" placeholder="Quantity">
    </div>
  </div>
  <div class="col-md-2">
    <label class="mb-0">Cover image</label>
    <input type="file" step="0.01" class="form-control" placeholder="Quantity">
  </div>
  <div class="col-md-1 d-flex pt-1">
    <a class="my-auto mx-auto" href="javascript:;"  v-on:click="addProductAttribute"><font-awesome-icon size="lg" icon="save" color="#5E3BB0" /></a>
    <a class="my-auto mx-auto" href="javascript:;"  v-on:click="addProductAttribute" @click="removeVariant(index)"><font-awesome-icon size="lg" icon="times" color="#5E3BB0" /></a>
  </div>
    <div class="col-md-12">
    </div>
  </div>
</div>
</div>
  <div class="row">
    <div class="col-md-12 pt-2">
      <a class="btn btn-purple" href="javascript:;" @click="addVariant()">Add Attribute Variation <font-awesome-icon icon="plus" color="#fff" /></a>
      <a class="btn btn-purple" href="javascript:;" @click="addCustomVariant()">Add Custom Variation <font-awesome-icon icon="plus" color="#fff" /></a>
    </div>
  </div>
</div>
</div>
              </b-collapse>
          </div>
        </div>
      <div class="row">
          <div class="col-md-6">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Customisations <b-button v-b-toggle.customisations class="m-1" variant="light" rounded><i class="bx bx-plus"></i></b-button></h4>
              <b-collapse id="customisations" class="mt-1">
                <FilterListTree></FilterListTree>
              </b-collapse>
          </div>
        </div>
      </div>
       <div class="col-md-6">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Order Fields <b-button v-b-toggle.orderfields class="m-1" variant="light" rounded><i class="bx bx-plus"></i></b-button></h4>
              <b-collapse id="orderfields" class="mt-1">
                <FilterListTree></FilterListTree>
              </b-collapse>
          </div>
        </div>
      </div>
    </div>

      
      </div>
      <div class="col-lg-4">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Status</h4>
            <div class="form-group text-center my-2">
                    <b-form-checkbox switch size="lg" class="my-auto" inline value="1"
                      unchecked-value="0"><small>Active</small></b-form-checkbox>
                      <b-form-checkbox switch size="lg" class="my-auto" inline value="1"
                      unchecked-value="0"><small>Public</small></b-form-checkbox>
                       <b-form-checkbox switch size="lg" class="my-auto" inline alue="1"
                      unchecked-value="0"><small>Trade</small></b-form-checkbox>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Product Type</h4>
            <div class="form-group mt-3">
              <b-form-radio
                      class="custom-radio custom-radio-outline custom-radio-primary mb-3"
                      value="0"
                      v-model="product_type_id"
                    >Standard Product
              </b-form-radio>
              <b-form-radio
                      class="custom-radio custom-radio-outline custom-radio-primary mb-3"
                      value="1"
                      v-model="product_type_id"
                    >Product with Variations
              </b-form-radio>
              <b-form-radio
                      class="custom-radio custom-radio-outline custom-radio-primary mb-3"
                      value="2"
                      v-model="product_type_id"
                    >Digital Download
              </b-form-radio>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Main Category</h4>
            <div class="form-group">
                    <multiselect v-model="value" :options="options"></multiselect>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Additional Categories <b-button v-b-toggle.additionalcats class="m-1" variant="light" rounded><i class="bx bx-plus"></i></b-button></h4>
              <b-collapse id="additionalcats" class="mt-1">
                <FilterListTree></FilterListTree>
              </b-collapse>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Collections <b-button v-b-toggle.collections class="m-1" variant="light" rounded><i class="bx bx-plus"></i></b-button></h4>
            <b-collapse id="collections" class="mt-1">
            <div class="form-group">
                    <multiselect v-model="value1" :options="options" :multiple="true" placeholder="Select existing tag..."></multiselect>
                    <b-form-tags placeholder="Add new tag..." input-id="tags-basic" v-model="value1" class="my-2 text-muted"></b-form-tags>
            </div>
          </b-collapse>
          </div>
        </div>
        <div class="card">
            <div class="card-body">
              <h4 class="card-title">Brand <b-button v-b-toggle.collapse class="m-1" variant="light" rounded><i class="bx bx-plus"></i></b-button></h4>
                      <div class="row">
                        <div class="col-md-12">
                          <b-collapse id="collapse" class="mt-1">
                            <div class="form-control-group">
                              <div class="input-group mb-3">
                                <div class="search-box w-75">
                                  <div class="position-relative">
                                    <input class="form-control" type="text" v-model="searchbrand" v-on:keyup="searchBrands" v-on:keydown="brandsDefault">
                                    <i class="bx bx-search-alt search-icon"></i>
                                    <a v-b-tooltip.hover title="Reset" placement="top" href="javascript:;" @click="[brandsearch_results = [], searchbrand = undefined]">
                                      <i class="bx bx-reset search-icon reset"></i>
                                    </a>
                                  </div>
                                </div>
                                <div class="w-25 pl-2">
                                    <a href="javascript:;" class="btn btn-rounded btn-primary btn-block" @click="brandsOnFocus">See All</a>
                                </div>
                             </div>
                            <ul class="list-group brands-searchlist" v-if="brandsearch_results.length">
                              <li v-for="searchresult in brandsearch_results.slice(brandresults_start, brandresults_end)" class="list-group-item d-flex">
                                <img class="avatar-md rounded-circle border" :src="'/public/storage/'+searchresult.logo">
                                <span class="mr-auto ml-2 my-auto">{{searchresult.name}}</span>
                                <a class="btn btn-outline-light btn-rounded ml-auto my-auto" href="javascript:;" @click="toggleBrand(false, searchresult.id)">Select Brand <i class="bx bx-plus"></i></a>
                              </li>
                              <li v-if="brandsearch_results.length && (brandresults_end <= brandsearch_results.length)" class="list-group-item py-4 text-center" v-on:click="brandresults_end += 3">Load More
                              </li>
                            </ul>
                          </div>
                        
                      <div class="col-md-12" v-if="product_brand_id != null">
                        <h5 class="mt-4 mb-4 pb-2 form-row-title" >Active Brand</h5>
                          <ul class="list-group">
                            <li v-for="searchresult in activeBrand(product_brand_id)" class="list-group-item d-flex">
                            <img class="avatar-md rounded-circle border" :src="'/public/storage/'+searchresult.logo">
                            <span class="my-auto ml-2">{{searchresult.name}}</span>
                              <a class="btn btn-remove ml-auto font-size-20 my-auto" href="javascript:;" @click="toggleBrand(true, searchresult.id)">
                                <i class="bx bx-trash-alt"></i>
                              </a>
                            </li>
                          </ul>
                      </div>
                    </b-collapse>
                  </div>
                </div>
        </div>
      </div>
       
      <div class="card">
          <div class="card-body">
            <h4 class="card-title">Tags <b-button v-b-toggle.tags class="m-1" variant="light" rounded><i class="bx bx-plus"></i></b-button></h4>
            <b-collapse id="tags" class="mt-1">
            <div class="form-group">
                    <multiselect v-model="value1" :options="options" :multiple="true" placeholder="Select existing tag..."></multiselect>
                    <b-form-tags placeholder="Add new tag..." input-id="tags-basic" v-model="value1" class="my-2 text-muted"></b-form-tags>
            </div>
          </b-collapse>
          </div>
        </div>

          <div class="card">
          <div class="card-body">
            <h4 class="card-title">Meta Data <b-button v-b-toggle.metadata class="m-1" variant="light" rounded><i class="bx bx-plus"></i></b-button></h4>
            <p class="card-title-desc">Fill all information below</p>
             <b-collapse id="metadata" class="mt-1">
            <form>
              <div class="row">
                <div class="col-sm-12">
                  <div class="form-group">
                    <label for="metatitle">Product URL</label>
                    <input id="metatitle" name="productname" type="text" class="form-control" />
                  </div>
                  <div class="form-group">
                    <label for="metatitle">Meta title</label>
                    <input id="metatitle" name="productname" type="text" class="form-control" />
                  </div>
                  <div class="form-group">
                    <label for="metakeywords">Meta Keywords</label>
                    <input
                      id="metakeywords"
                      name="manufacturername"
                      type="text"
                      class="form-control"
                    />
                  </div>
                </div>

                <div class="col-sm-12">
                  <div class="form-group">
                    <label for="metadescription">Meta Description</label>
                    <textarea id="metadescription" class="form-control" rows="5"></textarea>
                  </div>
                </div>
              </div>

              <button type="submit" class="btn btn-primary mr-1">Save Changes</button>
              <button type="submit" class="btn btn-secondary">Cancel</button>
            </form>
            </b-collapse>
          </div>
        </div>


         <div class="card">
          <div class="card-body">
            <h4 class="card-title">Condition <b-button v-b-toggle.condition class="m-1" variant="light" rounded><i class="bx bx-plus"></i></b-button></h4>
              <b-collapse id="condition" class="mt-1">
                <b-form-group>
                  <multiselect v-model="product_condition" :options="conditions"></multiselect>
                  <b-form-checkbox switch size="lg" class="my-auto" inline value="1" unchecked-value="0"></b-form-checkbox>
                </b-form-group>
              </b-collapse>
          </div>
        </div>

        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Reference Codes <b-button v-b-toggle.reference class="m-1" variant="light" rounded><i class="bx bx-plus"></i></b-button></h4>
              <b-collapse id="reference" class="mt-1">
                <b-form-group>
                 <label for="price">EAN-13 or JAN Barcode</label>
                    <input id="price" name="price" type="text" class="form-control" />
                    <label for="price">UPC Barcode</label>
                    <input id="price" name="price" type="text" class="form-control" />
                </b-form-group>
              </b-collapse>
          </div>
        </div>


    </div>
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Custom Tabs</h4>
            <p class="card-title-desc">Example of custom tabs</p>
            <b-tabs justified nav-class="nav-tabs-custom" content-class="p-3 text-muted">
              <b-tab active>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-home"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">Home</span>
                </template>
               
              </b-tab>
              <b-tab>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="far fa-user"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">Profile</span>
                </template>
                {{ content }}
              </b-tab>
              <b-tab>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="far fa-envelope"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">Messages</span>
                </template>
                {{ text }}
              </b-tab>
              <b-tab>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-cog"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">Settings</span>
                </template>
                {{ content }}
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>
