<template>
    <b-container class="mt-4">
        <b-row class="mt-4 justify-content-center" align-v="center">
            <b-col md="8" class="mt-4">
                <h3 class="mt-4 text-center">Login to One of Your Companies</h3>
                <b-card class="mt-4 border-radius-1em">
                    <b-list-group style="max-height: 540px; overflow-y: auto">
                        <b-list-group-item v-for="company in filteredCompanies(user.companies, 1, null)" >
                            <h4 class="text-primary">{{company.company.name}}</h4>
                            <a class="float-right font-weight-bold text-dark" :href="'//'+company.company.nickname+'.gemesys.co.uk/'" target="_blank">{{company.company.nickname}}.gemesys.co.uk <i class="bx bx-link-external"></i></a>

                        </b-list-group-item>
                    </b-list-group>


                </b-card>
            </b-col>
             <b-col md="8" class="mt-3 text-center">
                <a class="btn btn-primary btn-rounded" href="/company/create">
                    <i class="mdi mdi-plus"></i> Create New Company
                </a>
            </b-col>
             <b-col md="8" class="mt-4">
                <h3 class="mt-4 text-center">Company Link Requests</h3>
                <b-card class="mt-4 border-radius-1em">
                    <b-list-group style="max-height: 540px; overflow-y: auto">
                        <b-list-group-item v-for="company in filteredCompanies(user.companies, 0, null)" >
                            <h4 class="text-primary">{{company.company.name}}</h4>
                            <a class="float-right font-weight-bold text-dark" :href="'//'+company.company.nickname+'.gemesys.co.uk/'" target="_blank">{{company.company.nickname}}.gemesys.co.uk <i class="bx bx-link-external"></i></a>

                        </b-list-group-item>
                    </b-list-group>

                    
                </b-card>
            </b-col>

            <b-col md="8" class="mt-4 text-center">
                <a class="btn btn-danger btn-rounded" href="/logout">
                    <i class="bx bx-power-off"></i> Logout
                </a>
            </b-col>

        </b-row>
    </b-container>
</template>
<script>
export default {
    props: {
        companies:{
            type: String,
            required: false,
            default: () => ""
        },
    },
    data(){
        return{
            user: [],
        }
    },
    created(){
        this.user = JSON.parse(this.companies);
        if(this.user.companies.length == 0){
           // window.location.replace('http://app.gemesys.co.uk/company/create');
        }
    },
    methods: {
        filteredCompanies(companies, accepted, registered){
            return companies.filter(item => item.user_accepted == accepted && item.company !== registered);
        }
    },
    computed: {
        component_name() {
            return !!this.$root.$options.components[this.component] ? this.component : "pages-404";
        },

    }
};
</script>
