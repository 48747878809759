<script type="text/javascript">
  export default{
        data: function() {
        return{
           diamondOrGem: [
              { text: 'Diamond', value: 'diamond' },
              { text: 'Gemstone', value: 'gemstone' },
            ],
            diamondGemValue: 'diamond',
            diamondConstants:[
                  {name: "Round Brilliant", value: 0.0061},
                  {name: "Princess Cut", value: 0.0082},
                  {name: "Asscher Step Cut", value: 0.0080},
                  {name: "Cushion Brilliant", value: 0.00815},
                  {name: "Heart Brilliant", value: 0.0059},
                  {name: "Trillion Brilliant", value: 0.0057},
                  {name: "Marquise Brilliant", value: 0.0058},
                  {name: "Pear Brilliant", value: 0.0060},
                  {name: "Oval Brilliant", value: 0.00625},
                  {name: "Emerald Step Cut", value: 0.0092},
                  {name: "Radiant Brilliant", value: 0.0081},
            ],
            avgDiameter: '',
            diameter: '',
            userLength: '',
            userWidth: '',
            userDepth: '',
            selectedCutValue: 0,
            selectedGemTypeValue: 0,
            selectedGemCutValue: 0,
            estimatedCaratWeight: 0,
            show: false,
            highShouldersValue: 0,
            wideFatValue: 0,
            culetValue: 0,
            wideCornersValue: 0,
            pavilionBulgeValue: 0,
            highShouldersActive: false,
            wideFatActive: false,
            culetActive: false,
            wideCornersActive: false,
            pavilionBulgeActive: false,
            highShouldersEnabled: 1,
            wideFatEnabled: 1,
            wideCornersEnabled: 1,
            pavilionBulgeEnabled: 1,
        }
        },
        methods:{
          calculateCaratWeight(){
                if(this.selectedCutValue === 0.0060 || this.selectedCutValue === 0.00625){
                  this.highShouldersEnabled = 0;
                }else{
                  this.highShouldersEnabled = 1;
                }
                if(this.selectedCutValue === 0.0060 || this.selectedCutValue === 0.0058){
                  this.wideFatEnabled = 0;
                }else{
                  this.wideFatEnabled = 1;
                }

                if(this.selectedCutValue === 0.0092 || this.selectedCutValue === 0.0081){
                  this.wideCornersEnabled = 0;
                }else{
                  this.wideCornersEnabled = 1;
                }

                if(this.selectedCutValue === 0.0092 || this.selectedCutValue === 0.0081 || this.selectedCutValue === 0.0057){
                  this.pavilionBulgeEnabled = 0;
                }else{
                  this.pavilionBulgeEnabled = 1;
                }

                if(this.selectedCutValue === 0.0061){
                  if(this.culetActive === "true"){
                      var calculation = this.avgDiameter * this.diameter * this.userDepth * this.selectedCutValue;
                      var culetAdjustment = calculation * (this.culetValue/100);
                      calculation = calculation + culetAdjustment;
                      this.estimatedCaratWeight = calculation.toFixed(6);
                  }else{
                    var calculation = this.avgDiameter * this.diameter * this.userDepth * this.selectedCutValue;
                    this.estimatedCaratWeight = calculation.toFixed(6);
                  }
                }
                else{
                  var calculation = this.userLength * this.userWidth * this.userDepth * this.selectedCutValue;
                  if(this.highShouldersActive === "true"){
                         var calculation =  this.userLength * this.userWidth * this.userDepth * this.selectedCutValue;
                         var adjustmentValue = calculation * (this.highShouldersValue/100);
                         calculation = calculation + adjustmentValue;
                  }
                  if((this.selectedCutValue === 0.0060 || this.selectedCutValue === 0.0058) && this.wideFatActive === "true"){
                          var calculation =  this.userLength * this.userWidth * this.userDepth * this.selectedCutValue;
                          var adjustmentValue = calculation * (this.wideFatValue/100);
                          calculation = calculation + adjustmentValue;
                  }
                  if(((this.selectedCutValue === 0.0060 || this.selectedCutValue === 0.0058) && this.wideFatActive === "true") && ((this.selectedCutValue === 0.0060 || this.selectedCutValue === 0.00625) && this.highShouldersActive === "true")){
                          var calculation =  this.userLength * this.userWidth * this.userDepth * this.selectedCutValue;
                          var adjustmentValue1 = calculation * (this.highShouldersValue/100);
                          var adjustmentValue2 = calculation * (this.wideFatValue/100);
                          calculation = calculation + adjustmentValue1 + adjustmentValue2;
                  }
                  if((this.selectedCutValue === 0.0092 || this.selectedCutValue === 0.0081) && this.wideCornersActive === "true"){
                          var calculation =  this.userLength * this.userWidth * this.userDepth * this.selectedCutValue;
                          var adjustmentValue = calculation * (this.wideCornersValue/100);
                          calculation = calculation + adjustmentValue;
                  }
                  if(((this.selectedCutValue === 0.0092 || this.selectedCutValue === 0.0081) && this.wideCornersActive === "true")){
                          var calculation =  this.userLength * this.userWidth * this.userDepth * this.selectedCutValue;
                          var adjustmentValue1 = calculation * (this.pavilionBulgeValue/100);
                          var adjustmentValue2 = calculation * (this.wideCornersValue/100);
                          calculation = calculation + adjustmentValue1 + adjustmentValue2;
                  }
                  if((this.selectedCutValue === 0.0092 || this.selectedCutValue === 0.0081 || this.selectedCutValue === 0.0057) && this.pavilionBulgeActive === "true"){
                            var adjustmentValue1 = calculation * (this.pavilionBulgeValue/100);
                          calculation = calculation + adjustmentValue1;
                  }
                  if(this.culetActive === "true"){
                        var calculation =  this.userLength * this.userWidth * this.userDepth * this.selectedCutValue;
                        var culetAdjustment = calculation * (this.culetValue/100);
                        calculation = calculation + culetAdjustment;
                        if((this.selectedCutValue === 0.0092 || this.selectedCutValue === 0.0081 || this.selectedCutValue === 0.0057) && this.pavilionBulgeActive === "true"){
                            var adjustmentValue1 = calculation * (this.pavilionBulgeValue/100);
                          calculation = calculation + adjustmentValue1;
                        }
                       if((this.selectedCutValue === 0.0060 || this.selectedCutValue === 0.00625) && this.highShouldersActive === "true"){
                          var calculation =  this.userLength * this.userWidth * this.userDepth * this.selectedCutValue;
                          var culetAdjustment = calculation * (this.culetValue/100);
                          var adjustmentValue = calculation * (this.highShouldersValue/100);
                          calculation = calculation + culetAdjustment + adjustmentValue;
                       }
                       if((this.selectedCutValue === 0.0060 || this.selectedCutValue === 0.0058) && this.wideFatActive === "true"){
                          var calculation =  this.userLength * this.userWidth * this.userDepth * this.selectedCutValue;
                          var culetAdjustment = calculation * (this.culetValue/100);
                          var adjustmentValue = calculation * (this.wideFatValue/100);
                          calculation = calculation + culetAdjustment + adjustmentValue;
                       }
                        if((this.selectedCutValue === 0.0092 || this.selectedCutValue === 0.0081) && this.wideCornersActive === "true"){
                          var calculation =  this.userLength * this.userWidth * this.userDepth * this.selectedCutValue;
                          var culetAdjustment = calculation * (this.culetValue/100);
                          var adjustmentValue = calculation * (this.wideCornersValue/100);
                          calculation = calculation + culetAdjustment + adjustmentValue;
                       }
                       if(((this.selectedCutValue === 0.0060 || this.selectedCutValue === 0.0058) && this.wideFatActive === "true") && ((this.selectedCutValue === 0.0060 || this.selectedCutValue === 0.00625) && this.highShouldersActive === "true")){
                          var calculation =  this.userLength * this.userWidth * this.userDepth * this.selectedCutValue;
                          var culetAdjustment = calculation * (this.culetValue/100);
                          var adjustmentValue1 = calculation * (this.highShouldersValue/100);
                          var adjustmentValue2 = calculation * (this.wideFatValue/100);
                          calculation = calculation + culetAdjustment + adjustmentValue1 + adjustmentValue2;
                       }
                       if(((this.selectedCutValue === 0.0092 || this.selectedCutValue === 0.0081) && this.wideCornersActive === "true") && ((this.selectedCutValue === 0.0092 || this.selectedCutValue === 0.0081 || this.selectedCutValue === 0.0057) && this.pavilionBulgeActive === "true")){
                          var calculation =  this.userLength * this.userWidth * this.userDepth * this.selectedCutValue;
                          var culetAdjustment = calculation * (this.culetValue/100);
                          var adjustmentValue1 = calculation * (this.pavilionBulgeValue/100);
                          var adjustmentValue2 = calculation * (this.wideCornersValue/100);
                          calculation = calculation + culetAdjustment + adjustmentValue1 + adjustmentValue2;
                       }        
                      this.estimatedCaratWeight = calculation.toFixed(6);
                  } 
                  this.estimatedCaratWeight = calculation.toFixed(6);
                }
          },
          resetCalculator(){
             Object.assign(this.$data, this.$options.data());
          }
        },
        mounted(){
  
            this.calculateCaratWeight();
          }
    }
  </script>
  <template>
    <b-card>
      <div class="row">
        <div class="col-md-8 p-4">
          <h3 class="pb-3">Diamond Carat Weight Calculator</h3>
            <div class="row">
            <div class="col-md-6">
              
              <label>Diamond Cut</label>
              <select class="form-control form-control-md mb-3" v-model="selectedCutValue" v-on:change="calculateCaratWeight">
                <option value="0" selected>-- Select Cut --</option>
                <option v-for="cut in diamondConstants" v-bind:value="cut.value">{{cut.name}}</option>
              </select>
            </div>
          <div class="col-md-6" v-if="selectedCutValue === 0.0061">        
            <label>Diameter</label>
            <div class="input-group mb-3">
              <input class="form-control form-control-md" v-model="diameter" type="number" placeholder="0.00" v-on:keyup="calculateCaratWeight">
            <div class="input-group-append">
              <span class="input-group-text">mm</span></div></div></div>
          <div class="col-md-6" v-if="selectedCutValue === 0.0061">        
            <label>Average Diameter</label>
            <div class="input-group mb-3">
              <input type="number" class="form-control form-control-md" v-model="avgDiameter" placeholder="0.00" v-on:keyup="calculateCaratWeight">
            <div class="input-group-append">
              <span class="input-group-text">mm</span></div></div></div>
          <div class="col-md-6"  v-if="selectedCutValue != 0.0061">        
            <label>Length</label>
            <div class="input-group mb-3">
              <input type="number" class="form-control form-control-md"  v-model="userLength" placeholder="0.00" v-on:keyup="calculateCaratWeight">
            <div class="input-group-append">
              <span class="input-group-text">mm</span></div></div></div>
          <div class="col-md-6" v-if="selectedCutValue != 0.0061">        
            <label>Width</label>
            <div class="input-group mb-3">
              <input type="number" class="form-control form-control-md"  v-model="userWidth" placeholder="0.00" v-on:keyup="calculateCaratWeight">
            <div class="input-group-append">
              <span class="input-group-text">mm</span></div></div></div>
          <div class="col-md-6">        
            <label>Depth</label>
            <div class="input-group mb-3">
              <input type="number" class="form-control form-control-md"  v-model="userDepth" placeholder="0.00" v-on:keyup="calculateCaratWeight">
            <div class="input-group-append">
              <span class="input-group-text">mm</span>
            </div>
          </div>
        </div>
      </div>
          <div class="row my-3">
            <div class="col-md-12 text-center py-4">
              <h4>Estimated Carat Weight</h4>
              <span class="result-value  font-size-24 text-primary mb-4">{{estimatedCaratWeight}}</span>
            </div>
          <div class="col-md-12 text-center">
            <a class="btn btn-primary py-4 w-25 ml-auto pr-1" href="javascript:;" @click="resetCalculator">Reset</a>
            <a class="btn btn-dark py-4 w-25 mr-auto pl-1" href="javascript:;" @click="calculateCaratWeight">Recalculate</a>
          </div>
        </div>
    </div>
          <div class="col-md-4 px-4 py-4">
            <div class="px-4 py-2">
              <h3 class="sidepanel-title pb-3">Weight Adjustments</h3>
              <label for="range-2">Squarish or High Shoulders</label><b-form-checkbox class="float-right" :disabled="highShouldersEnabled == 1" id="checkbox-1" v-model="highShouldersActive" name="checkbox-1" value="true" unchecked-value="false" v-on:click.self="calculateCaratWeight" @change="calculateCaratWeight">Active</b-form-checkbox><b-form-input id="range-2" :disabled="highShouldersEnabled == 1" v-model="highShouldersValue" type="range" class="w-100" min="0" max="10" step="0.1" v-on:change="calculateCaratWeight"></b-form-input>
            <div class="mb-2 text-center adjustment-value-text">+{{ highShouldersValue }}
              <span class="percent">%</span></div>
              <label for="range-2">Wide or Fat Wings</label>
              <b-form-checkbox class="float-right" :disabled="wideFatEnabled == 1" id="checkbox-2" v-model="wideFatActive"  name="checkbox-2" value="true" unchecked-value="false">Active</b-form-checkbox><b-form-input :disabled="wideFatEnabled == 1" id="range-2" v-model="wideFatValue" type="range" class="w-100" min="0" max="5" step="0.1" v-on:change="calculateCaratWeight"></b-form-input>
          <div class="mb-2 text-center adjustment-value-text">+{{ wideFatValue }}
            <span class="percent">%</span></div>
            <label for="range-2">Large Culet</label>
            <b-form-checkbox class="float-right" id="checkbox-3" v-model="culetActive"  name="checkbox-3" value="true" unchecked-value="false">Active</b-form-checkbox><b-form-input id="range-2" v-model="culetValue" type="range" class="w-100" min="0" max="2" step="0.1" v-on:change="calculateCaratWeight"></b-form-input>
          <div class="mb-2 text-center adjustment-value-text">+{{ culetValue }}
            <span class="percent">%</span></div>
            <label for="range-2">Wide Corners</label>
            <b-form-checkbox class="float-right" :disabled="wideCornersEnabled == 1" id="checkbox-4" v-model="wideCornersActive"  name="checkbox-4" value="true" unchecked-value="false">Active</b-form-checkbox><b-form-input :disabled="wideCornersEnabled == 1" id="range-2" v-model="wideCornersValue" type="range" class="w-100" min="-5" max="-1" step="0.1" v-on:change="calculateCaratWeight"></b-form-input>
          <div class="mb-2 text-center adjustment-value-text">{{ wideCornersValue }}
            <span class="percent">%</span></div>
            <label for="range-2">Pavilion Bulge</label>
            <b-form-checkbox class="float-right" :disabled="pavilionBulgeEnabled == 1" id="checkbox-5" v-model="pavilionBulgeActive"  name="checkbox-5" value="true" unchecked-value="false">Active</b-form-checkbox><b-form-input :disabled="pavilionBulgeEnabled == 1"  id="range-2" v-model="pavilionBulgeValue" type="range" class="w-100" min="0" max="15" step="0.1" v-on:change="calculateCaratWeight"></b-form-input>
          <div class="mb-2 text-center adjustment-value-text">+{{ pavilionBulgeValue }}
            <span class="percent">%</span></div></div></div></div>
  </b-card>
  </template>