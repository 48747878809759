<script type="text/javascript">
	export default{
		props: {
					resource: {
		        type: String,
		        default: () => '',
		      },
		      default_resource_key: {
		        type: String,
		        default: () => 'uid',
		      },
		      resource_id: {
		        type: Number,
		        default: () => '',
		      },
		      api_token: {
		        type: String,
		        default: () => '',
		      },
		      resource_route: {
		        type: String,
		        default: () => '',
		      },
		      view: {
		        type: String,
		        default: () => 'grid',
		      },
		      product_list: {
		        type: Array,
		        default: () => '',
		      },
		      expanded:{
		      	type: Number,
		      	default: () => 0
		      }
		  },
		  data(){
			return{
						quickviewproduct: '',
		        quantity: 1,
		        showQuantitySelector: null,
		        hoverIndex: -1,
		        storageurl: 'https://'+this.$attrs.company.nickname+'.s3.eu-west-2.amazonaws.com',
		        showQVGallery: false,
		        viewImage: null,
			}
		  },
		  methods: {
		  	removeResource(payload){
		  		this.$emit('remove-product', payload);
		  	},
		  	priceIncVat(price, tax_percentage){
		      var tax = 1 + (tax_percentage / 100);
		      return price * tax;
		    }
		  }
	}
</script>
<template>
	 <div class="row">
          <div class="col-md-12">
          	<ul class="nav nav-pills product-view-nav mb-2 float-right">
          	<li class="nav-item">
                  <a class="nav-link" :class="{'active': view == 'grid' }" href="javascript: void(0);" @click="view = 'grid'">
                    <i class="bx bx-grid-alt"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': view == 'list' }" href="javascript: void(0);" @click="view = 'list'">
                    <i class="bx bx-list-ul"></i>
                  </a>
                </li>
               </ul>
          	<ul class="list-group product-list w-100 no-shadow" v-if="(product_list.length !== 0) && (view == 'list')">
                              <li v-for="product in product_list" class="list-group-item d-flex px-0 py-0 border product-list-item mb-2 shadow-sm">
                              	<div class="d-flex product-list-item-container">
                              	<img v-if="product.product.cover_image !== null" class="avatar-lg flex-grow-1 mr-3 product-list-img" :src="storageurl + product.product.cover_image.src.replaceAll('//', '/')">
                              	<img v-else class="avatar-lg flex-grow-1 mr-3 product-list-img" src="/images/product-placeholder.png">
                                <div class="mr-auto ml-2 my-auto flex-grow-3 mr-3">
                                	<strong class="mr-auto my-auto text-primary">{{product.product.name}}</strong>
                                	<span class="badge badge-primary position-absolute product-list-reference-badge">{{product.product.reference}}</span>
						            <small class="text-muted italic no-margin" v-html="product.product.short_description"></small>
						            <strong class="text-muted mb-auto">£{{product.product.sales_price}} <small class="mr-3">exc. VAT</small>   <span v-if="product.product.tax_rule !== null">£{{priceIncVat(product.product.sales_price, product.product.tax_rule.percentage)}} <small class="mr-3">inc. VAT</small></span> £{{product.product.trade_price}} <small class="mr-3">Trade Price</small></strong>
                                </div>
                                <div class="mr-auto ml-2 my-auto flex-grow-1 mr-3 d-flex">
                                	<a class="btn btn-outline-light btn-rounded ml-auto my-auto flex-grow-3 mr-2" href="javascript:;" @click="quickviewproduct = product"><i class="py-3 px-2 mdi mdi-eye-outline"></i></a>
                                <a class="btn btn-outline-light btn-rounded ml-auto my-auto" href="javascript:;" @click="removeResource(product)"><i class="py-1 px-2 bx bx-trash-alt"></i></a>
                            	</div>
              
                              </div>
                              </li>
                              

                              <!--<li class="list-group-item py-4 text-center" ><a class="btn btn-outline-light btn-rounded">Load More</a>
                              </li>-->
                            </ul>
                <ul class="list-group product-list w-100 no-shadow" v-if="(product_list.length == 0) && (view == 'list')">
                	  <li class="list-group-item d-flex">
                	  	<i class="bx bx-warning"></i> No Products <span v-if="resource !== ''">assigned to this {{resource}}</span>
                	  </li>
                </ul>
          </div>
          <div class="col-md-12">
              <div id="my-table" class="row product-grid px-2 row-eq-height" v-if="(product_list.length !== 0) && (view == 'grid')">
                 <div class="d-flex flex-column product-grid-item mb-2" :class="{'col-md-4' : expanded == 0,'col-md-3' : expanded == 1}" v-for="(product, index) in product_list">
                 	<b-card no-body class="overflow-hidden news-card shadow-none border-radius-1em border" @mouseenter="hoverIndex = index" @mouseleave="hoverIndex = -1" >

		                    <b-row no-gutters>
		                    	<b-col md="12" class="position-absolute z-index-2">
		                    		<b-row>
				                     <transition name="fade-left">
				                    	<div class="col-md-4" v-if="hoverIndex == index"> 
		                              	<a id="tooltip-add" v-b-modal.product-info @click="quickviewproduct = product" class="mt-2 ml-3 btn btn-primary font-size-14 btn-rounded ml-auto float-left"><i class="mdi mdi-eye-outline"></i></a>
		                              </div>
		                          </transition>
		                          <transition name="fade-right">
		                           <div class="col-md-8 my-auto"  v-if="hoverIndex == index">
				                         <p  class="text-muted float-right ml-auto mt-3">
								                    <span class="bx bx-star font-size-14 text-warning"></span>
								                    <span class="bx bx-star font-size-14 text-warning ml-1"></span>
								                    <span class="bx bx-star font-size-14 text-warning ml-1"></span>
								                    <span class="bx bx-star font-size-14 text-warning ml-1"></span>
								                    <span class="bx bx-star font-size-14 ml-1"></span>
					                        </p>
					                      </div>
					              			</transition>
			              			</b-row>
			              		</b-col> 
		                      <b-col lg="12">
		                        <b-card-img-lazy v-if="product.product.cover_image" :src="storageurl+product.product.cover_image.src.replace('//', '/')" alt="Image" class="rounded-0"></b-card-img-lazy>
		                        <b-card-img-lazy v-else :src="'/images/product-placeholder.png'" alt="Image" class="rounded-0"></b-card-img-lazy>
		                     <transition name="fade-right">
		                        <div class="mt-auto mb-3 mx-2 d-flex position-absolute bottom-0 right-0 left-0" v-if="hoverIndex == index">
                              	<a @click="removeResource(product)" class="mr-3 ml-auto btn btn-primary font-size-16 btn-rounded"><i class="bx bx-trash-alt"></i></a>
              					</div>
              				</transition>
		                      </b-col>
		                      <b-col lg="12"> 
		                        <b-card-body>
		                        	<span v-if="resource_id == product[default_resource_key]" class="text-capitalize"><i class="bx bx-star"></i> Default {{resource}}</span>
		                        	<a class="" :href="'/stock/product/'+product.product.uid"><h4 class="card-title d-flex" :class="{'text-truncate font-size-14' : truncate_titles == true}">{{product.product.name}}</h4></a>
		                          <b-card-text>
		                             <p v-html="product.product.short_description.substring(0,90)+'...'"></p>
		                           

		                          </b-card-text>
		                          <div class="d-flex flex-row">
		                          	<span class="badge badge-light my-auto mr-2 bg-light p-2 font-size-12 border-radius-1em">
		                         	£ {{product.product.sales_price}}
		                         </span>
		                         <span class="badge badge-light my-auto mr-auto bg-light p-2 font-size-12 border-radius-1em">
		                         	£ {{product.product.trade_price}}
		                         </span>
		                       		</div>
		                        </b-card-body>
		                      </b-col>
		                    </b-row>
		                  </b-card>
                 
                              </div>
                              

                              <!--<li class="list-group-item py-4 text-center" ><a class="btn btn-outline-light btn-rounded">Load More</a>
                              </li>-->
                            </div>         
                <ul class="list-group product-list w-100 no-shadow"  v-if="(product_list.length == 0) && (view == 'grid')">
                	  <li class="list-group-item d-flex">
                	  	<i class="bx bx-warning"></i> No Products <span v-if="resource !== ''">assigned to this {{resource}}</span>
                	  </li>
                </ul>
          </div>
          <div class="product-modal d-flex flex-column justify-content-center" v-if="quickviewproduct !== ''">
          	<div class=" d-flex flex-column px-2 product-grid-item mb-2 mx-auto col-md-8 col-sm-10">
                              	<div class="border shadow-sm product-grid-item-container bg-white row">
                              		<div class="col-md-6 m-0 p-0">
                              	<img v-if="quickviewproduct.product.cover_image !== null" class="flex-grow-3 w-100 quickviewproduct-grid-img" :src="storageurl + quickviewproduct.product.cover_image.src.replaceAll('//','/')">
                              	<img v-else class="flex-grow-3 w-100 product-list-img" src="/images/product-placeholder.png"></div>
                              	<div class="col-md-6 d-flex flex-column justify-content-center">
                              		<a href="javascript:;" @click="quickviewproduct = ''" class="btn btn-rounded btn-outline-light float-right mr-2 mt-2 position-absolute close-modal"><i class="mdi mdi-close noti-icon font-size-20"></i></a>
                              	                                <div class="my-3 flex-grow-3 px-3">
                                	<h2 class="mr-auto my-auto text-primary">{{quickviewproduct.product.name}}</h2>
                                	<span class="badge badge-primary">{{quickviewproduct.product.reference}}</span>
						            <p class="text-muted italic no-margin" v-html="quickviewproduct.product.short_description"></p>
                                </div>
                                <div class="my-1 flex-grow-3 px-3">
                                	<strong class="text-muted mb-auto">£{{quickviewproduct.product.sales_price}} <small class="mr-3">exc. VAT</small></strong>
                                </div>
                                <div class="my-1 flex-grow-3 px-3">
                                	<strong class="text-muted mb-auto"> £{{priceIncVat(quickviewproduct.product.sales_price, quickviewproduct.product.tax_rule.percentage)}} <small class="mr-3">inc. VAT</small></strong>
                                </div>
                                <div class="my-1 flex-grow-3 px-3">
                                	<strong class="text-muted mb-auto">£{{quickviewproduct.product.trade_price}} <small class="mr-3">Trade Price</small></strong>
                                </div>
                                <div class="my-3 flex-grow-3 px-3">
                                <a class="btn btn-outline-light btn-rounded ml-auto my-auto flex-grow-3" href="javascript:;" @click="removeResource(quickviewproduct)"><i class="bx bx-trash-alt"></i></a>
              					</div>
              				</div>
                              	</div>
                              </div>
          </div>
     </div>
</template>