<script>
	import Multiselect from "vue-multiselect";
	import { gFonts } from "./gFonts";
	export default{
		components: { Multiselect },
		data(){
			return{
				options: gFonts,
				gfontskey: 'AIzaSyDYWzwTgxlRT37TtJkYNt2hlT4xpg1GweM',
   				gFonts: gFonts,
				search: '',
				currentPage: 1,
				order: 'desc',
      			sortby: 'id',
      			limit: 0,
				limits: [
		        {value: 25, text: "25"},
		        {value: 50, text: "50"},
		        {value: 100, text: "100"},
		    	],
				response_error: []
			}
		},
		props: {
			id: {
				type: Object
			},
			isDisabled: {
				type: Boolean,
				default: () => false,
				},
			marketplace_categories: {
				type: String,
				default: () => 'false'
			}
		},
		created(){
			if(this.gFonts.length == 0){
				var localFonts = localStorage.getItem("gFonts");
				//console.log(localFonts);
				if(localFonts){
					axios.get('https://www.googleapis.com/webfonts/v1/webfonts?key='+this.gfontskey).then(response => {
		   				this.options = response.data["items"];
		   				localStorage.setItem("gFonts", JSON.stringify(response.data["items"]));
		   			}).catch(error => {

		   			});
	   			}else{
	   				this.options = JSON.parse(localFonts);
	   			}
   			}else{
   				this.options = JSON.parse(this.gFonts);
   			}
			
				
		},
		methods:{
			updateFont: function(selectedOption, id){
				this.$emit('font-selected', selectedOption);
			},
			removeFont: function(selectedOption, id){
				this.$emit('font-removed', selectedOption);
			},
			customLabel ({ title, desc }) {
		      return `${title} – ${desc}`
		    }
		}
	}
</script>
<template>
	<div>
	<multiselect v-model="id" track-by="family" label="family" :options="options" @select="updateFont" @remove="removeFont" :option-height="104" :disabled="isDisabled" :optionsLimit="20000" >
                     <!--<template slot="singleLabel" slot-scope="{ option }">{{ option.name }} - {{ option.jobtitle }}</template>-->
                     <template slot="singleLabel" slot-scope="{ option }"><div class="d-flex w-100 h-100"><div class="avatar-sm mr-4 my-auto">
                   
                        <span class="avatar-title rounded-circle">{{option.family.slice(0,1)}}</span>
                      </div>
      <div class="option__desc d-flex flex-row w-75"><span class="option__title ml-2 my-auto w-100">{{ option.family }}<br></span>
      </div>
  		</div></template>
    <template slot="option" slot-scope="{ option }">
    	<div class="d-flex"><div class="avatar-sm mr-4">
                        <span class="avatar-title rounded-circle">{{option.family.slice(0,1)}}</span>
                      </div>
      <div class="option__desc d-flex flex-row w-50"><span class="option__title ml-2 my-auto w-100">{{ option.family }}<br></span>
      </div>
  		</div>
    </template>
                  </multiselect>
                 
   </div>
</template>