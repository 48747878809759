<script type="text/javascript">
import GemstoneCalculator from './GemstoneCalculator';
import DiamondCalculator from './DiamondCalculator';
import CabochonCalculator from './CabochonCalculator';
import CrownAngleCalculator from './CrownAngleCalculator';
import SpecificGravityCalculator from './SpecificGravityCalculator';
import PavillionAngleCalculator from './PavillionAngleCalculator';
	export default{
		components: {GemstoneCalculator, DiamondCalculator, CabochonCalculator, CrownAngleCalculator, SpecificGravityCalculator, PavillionAngleCalculator},
		data: function(){
			return{
				
			}
		},
		props: {
			activeTab: {
				type: Number,
				default: () => false,
			},
		}
	}
</script>
<template>
	<div>
		<b-tabs pills justified v-model="activeTab">
			<b-tab title="Gemstone Calculator">
				<div class="mt-4">
				<GemstoneCalculator />
				</div>
			</b-tab>
			<b-tab title="Diamond Calculator">
				<div class="mt-4">
				<DiamondCalculator />
				</div>
			</b-tab>
			<b-tab title="Cabochon Calculator">
				<div class="mt-4">
				<CabochonCalculator />
				</div>
			</b-tab>
			<b-tab title="Specific Gravity Calculator">
				<div class="mt-4">
				<SpecificGravityCalculator />
				</div>
			</b-tab>
			<b-tab title="Crown Angle Calculator">
				<div class="mt-4">
				<CrownAngleCalculator />
				</div>
			</b-tab>
			<b-tab title="Pavillion Angle Calculator">
				<div class="mt-4">
				<PavillionAngleCalculator />
				</div>
			</b-tab>
		</b-tabs>
	</div>
</template>