<script type="text/javascript">

	import VueSlider from 'vue-slider-component'
	import ImageBlocks from "../../../../components/widgets/blocks/ImageBlocks";
	import MiniCardCarousel from "../../../../components/widgets/carousel/MiniCardCarousel";
	import Swiper from "../../../../components/widgets/carousel/Swiper";
	import TradeMarketplaceProductList from "../../../../components/widgets/TradeMarketplaceProductList";
	import FilterProductBrands from "../../../../components/widgets/FilterProductBrands";
	import FilterCompanyProductCategories from "../../../../components/widgets/FilterCompanyProductCategories";
	import FilterProductCollections from "../../../../components/widgets/FilterProductCollections";
	import { EventBus } from '../../../../app';
	import simplebar from "simplebar-vue";
	import SideSlidePanel from "../../../../components/widgets/SideSlidePanel";
	import ReviewForm from "../../../../components/widgets/forms/ReviewForm";
	import ReviewCard from "../../../../components/widgets/forms/ReviewCard"
	import ReviewCarousel from "../../../../components/widgets/forms/ReviewCarousel"
	import TradeSellerEnquiry from "../../../../components/widgets/forms/company/TradeSellerEnquiry"
	export default{
		components: { Swiper, ImageBlocks, MiniCardCarousel, TradeMarketplaceProductList, VueSlider, FilterProductBrands, FilterCompanyProductCategories, FilterProductCollections, simplebar, SideSlidePanel, ReviewForm, ReviewCard, ReviewCarousel, TradeSellerEnquiry },
		data(){
			return{
				viewtype: 'products',
				headers: '',
				action_error: '',
				loadingActionModal: false,
				breadcrumbs: [],
				bread: {
					title: '',
					link: '',
					id: 1,
				},
				markers: [
        {
          position: { lat: 10.0, lng: 10.0 }
        },
        {
          position: { lat: 15.0, lng: 13.0 }
        }
      	],
				csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
				showStores: false, 
				categoriesdata: '',
				reviewOptions: {
		          slidesPerView: 3,
		          spaceBetween: 30,
		          autoplay: {
				      delay: 2500,
				      disableOnInteraction: false
				    },
		          loop: false,
		          loopFillGroupWithBlank: false,
		          pagination: {
		            el: '.swiper-pagination',
		            clickable: true,
		          },
		          // Some Swiper option/callback...
		        },
				infomodalaction: 'reviews',
				collectionsdata: [],
				imageUpload: false,
				data: '',
				form: {
					action: '',
					name: '',
					lastname: '',
					email: '',
					customer_uid: '',
					share_details: '',
					review: '',
					message: '',
					review_score: 0,
				},
				mapcenter: { lat: 54, lng: 2 },
				mapzoom: 5,
				storageurl: '.s3.eu-west-2.amazonaws.com/',
				productsLoading: true,
				showFilters: false,
		        sliderPrice: 800,
		        priceRange: [0,250000],
		        brands: [],
		        collections: [],
		        categories: [],
		        sortby: 'created_at',
		        order: 'desc',
		        search: '',
		        view: 'grid',
		        public_active: 1,
		        trade_active: '',
		        active: '',
		        expanded: 1,
		        currentPage: 1,
		        query: '',
		        limit: 25,
		        listingslimit: 25,
					  listingssortby: 'id',
							      listingsData: [],
						listingsearch: '',
						listingtrade_active: 1,
							      listingpublic_active: '',
							      listing_active: '',
				limits: [
				        {value: 25, text: "25"},
				        {value: 50, text: "50"},
				        {value: 100, text: "100"},
				      ],
			}
		},
		props: {
			user: {
				type: Object
			},
			categorydata: {
				type: Object
			}
		},
		mounted() {
			this.headers = {'Authorization' : 'Bearer '+this.user.api_token};
			//this.createQueryString();
			//this.loadSellerInfo();
			this.loadSellerProducts();
			this.loadSellerCollections();
			this.loadSellerListings();
			this.checkUserLoggedIn();
			//this.loadSellerCollections();
				//let self = this;
			
		},
		created(){
			let self = this;
			EventBus.$on('category-select', function(id){
				self.filterSelectedCategories(id);
		    });
			EventBus.$on('categories-cleared', function(id){
				self.categories = [];
				self.loadSellerProducts();
		    });
		},
		methods: {
			addToFavourites(id){
				axios.post('/customer-api/user/favourites/add-remove', {seller_id: id}, this.headers).then(response => {
					this.customer.favourites.push(id);
				}).catch(error => {
					this.action_error = error.data.errors;
				});
			},
			checkUserLoggedIn(){
				axios.get('/customer-api/user').then(response => {
					console.log(response);
				}).catch( error => {
					console.log(error);
				})
			},
			loadSellerProducts(){
				this.createQueryString();
				this.productsLoading = true;
				if(this.$attrs.id !== ''){
					var id = this.$attrs.id;
				}else{
					var id = this.$attrs.company.nickname;
				}
				//console.log(this.$route.path);
				axios.get('/api/company/trade/profile/'+id+'/products'+this.query,  {headers: this.headers}).then(response => {
					this.data = response.data;
				}).catch(error => {
					console.log(error);
				});
				var self = this;
                setTimeout(function(){
	                self.productsLoading = false;                
                }, 500);
			},
			loadSellerCollections(){
				if(this.$attrs.id !== ''){
					var id = this.$attrs.id;
				}else{
					var id = this.$attrs.company.nickname;
				}
				axios.get('/api/company/trade/profile/'+id+'/collections', {headers: this.headers}).then(response => {
					this.collectionsdata = response;
				}).catch(error => {
					console.log(error);
				});
			},
			loadTradeCategories(){
	      axios.get('/api/company/product/categories?page='+this.currentPage+'&limit='+this.limit+'&sortby='+this.sortby+'&order='+this.order+'&search='+this.search+'&parent_id='+this.selected_parent.id+'&marketplace_categories=trade', {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
              }}).then(response => {
	        this.categoriesdata = response.data;
	      }).catch(error => {
	        this.brands_error = error.data;
	      });
			},
			loadSellerListings(){
				if(this.$attrs.id !== ''){
					var id = this.$attrs.id;
				}else{
					var id = this.$attrs.company.nickname;
				}
	      axios.get('/api/company/trade/profile/'+id+'/listings?page='+this.currentPage+'&limit='+this.listingslimit+'&sortby='+this.listingssortby+'&order='+this.order+'&search='+this.listingsearch+'&type_id='+this.type_id+'&active='+this.listingactive+'&public='+this.listingpublic_active+'&trade='+this.listingtrade_active, {headers: this.headers}).then(response => {
	      this.listingsData = response.data;
	      }).catch(error => {
	        this.shops_error = error.data;
	      });
    	},
			filterBrands(value){
		      this.brands = value;
		      //this.createQueryString();
		      this.loadSellerProducts();
		    },
		    filterCollections(id){
		        
		          var exists = this.collections.findIndex(item => item == id);
		          console.log(exists);
		          if(exists == -1){
		            this.collections.push(id);
		          }else{
								this.collections.splice(exists, 1);
		          }
		          //this.createQueryString();
		      		this.loadSellerProducts();
		      },
			createQueryString(){
				this.query = '?page='+this.currentPage+'&limit='+this.limit+'&sortby='+this.sortby+'&order='+this.order+'&search='+this.search+'&public_active='+this.public_active+'&active='+this.active+'&brands='+this.brands+'&collections='+this.collections+'&categories='+this.categories+'&pricerange='+this.priceRange+'';
			},
			filterSelectedCategories(id){
		        
		          var exists = this.categories.findIndex(item => item == id);
		          console.log(exists);
		          if(exists == -1){
		            this.categories.push(id);
		          }else{
								this.categories.splice(exists, 1);
		          }
		          //this.createQueryString();
		      		this.loadSellerProducts();
		      },
		},
		watch: {
        $query() {
        	this.$nextTick(() => {
        		this.loadSellerProducts();
        		//this.loadSellerInfo();
        		
        	 });
            //this.$nextTick();
            let self = this;
			
         }  
    	},
	}

</script>
<template>
<div>
		<section >
		  <div class="container container-fullwidth profile-container" style="background: #fff; margin-top: -25px">
		    <!--<PageHeader :title="title" :items="items" />-->
		    
		    <b-row v-if="('trade' in categorydata.profile_banners) && (categorydata.profile_banners.trade !== null)" class="mb-4 bg-primary bg-extra-soft" :style="'background-image: url(https://' + categorydata.nickname  + storageurl + categorydata.profile_banners.trade +'); min-height: 300px; background-color: #f7f7f7; background-size: cover; background-position: center center; background-repeat: no-repeat'">

		      <b-col md="10" class="d-flex flex-column justify-content-center align-items-start mx-auto">
		      	
		      	
		      </b-col>
		    </b-row>
		    <b-row v-else class="mb-4 bg-primary bg-extra-soft" style="min-height: 300px; background-color: #f7f7f7; background-position:  right bottom; background-size: 50%; background-repeat: no-repeat">

		      <b-col md="10" class="d-flex flex-column justify-content-center align-items-start mx-auto">
		      	
		      	
		      </b-col>
		    </b-row>
		    <b-row class="seller-top-row mb-4 pb-4">
		    	<b-row class="pb-3">
		    		<b-col md="7" class="ml-auto">
		    			<div class="avatar-xl profile-user-wid mb-2 mr-auto position-relative" @mouseenter="imageUpload = true" @mouseleave="imageUpload = false">
                  <img
                  	v-if="categorydata.logo" :src="'https://'+ ($attrs.id + storageurl + categorydata.logo).replaceAll('//', '/').replaceAll('https:/'+$attrs.id+'.s3.eu-west-2.amazonaws.com', '')"
                    alt
                    class="avatar-xl rounded-circle bg-white shadow-sm"
                  />
                   <img
                  	v-else 
                    src="/images/product-placeholder.png"
                    alt
                    class="avatar-xl rounded-circle bg-white shadow-sm"
                  />
                  
                  <a class="position-absolute top-0 bottom-0 right-0 left-0 d-flex rounded-circle opacity-0" :class="{'bg-primary bg-soft opacity-1' : imageUpload == true}" v-if="$attrs.company.id == categorydata.id" v-b-modal.upload-modal>
                  	<transition name="fade-top">
                  		<div v-if="imageUpload == true" class="my-auto mx-auto text-white text-uppercase text-center">
                  			<i class="bx bxs-cloud-upload text-white font-size-36 d-block text-center"></i>
                  	Change Logo
                  		</div>
                  	</transition>
                  </a>
                 	
                </div>
		    		<b-row class="">
				 	<b-col md="12" class="d-flex flex-row align-items-start mx-auto">
				 	<div  v-for="(bread, index) in breadcrumbs" class="d-flex flex-row">
		    		<span class="text-muted font-size-12 text-capitalize my-auto"><i class="bx bx-home my-auto font-size-10 mr-1" v-if="index == 0"></i>{{bread}}</span>
		    		 <i class="bx bx-chevron-right my-auto" v-if="index !== (breadcrumbs.length -1)"></i>
		    		</div>

				    	</b-col>
				    </b-row>
		        <h1 class="font-size-32 text-primary my-1 text-capitalize">{{categorydata.name}}</h1>
		        <p class="w-100 mb-0" v-if="categorydata.description !== (null || '')">
		            {{categorydata.description}}
		          </p>
		          <div class="w-50 d-flex mb-3 mt-4">
		          	<a class="btn btn-rounded mr-2" :class="{'btn-primary' : viewtype == 'products', 'btn-light' : viewtype !== 'products'}" href="javascript:;" @click="viewtype = 'products'">Products <i class="bx bx-purchase-tag-alt"></i></a>
		          	<a class="btn btn-rounded" :class="{'btn-primary' : viewtype == 'services', 'btn-light' : viewtype !== 'services'}" href="javascript:;" @click="viewtype = 'services'">Services <i class="bx bx-wrench"></i></a>
		          </div>
		        </b-col>
		        <b-col md="3" class="mr-auto">
		        	<div class="avatar-xl profile-user-wid mb-2 mr-auto w-100">
		        		<b-card class="border-radius-1em">
		        			<h5 class="text-center">Seller Rating</h5>
		        			    <p  class="text-muted mb-3 text-center mx-4 px-3" @click="infomodalaction = 'review'" v-b-modal.info-modal>
			                    <b-form-rating id="rating-inline" variant="warning" class="text-center m-auto trade-rating" no-border size="md" v-model="categorydata.reviews_avg_rating" style="max-width: 200px;" readonly></b-form-rating>
			                    <small class="d-block mt-2">
			             	{{categorydata.reviews_avg_rating !== null ? categorydata.reviews_avg_rating : 0}} / 5 (1,000 reviews)
			             </small>
			                  </p>
			            <a class="btn btn-outline-light btn-rounded btn-block mb-2"  v-b-modal.company-info @click="form.action = 'review'"> Contact Information <i class="bx bx-info-circle text-info"></i></a>
		        			<a class="btn btn-outline-light btn-rounded btn-block mb-2" v-b-modal.modal-1 @click="form.action = 'message'">Message Contact <i class="bx bx-mail-send text-primary"></i></a>
		        			<a class="btn btn-outline-light btn-rounded btn-block mb-2" @click="form.action = 'favourites', addToFavourites(product.id)">Add To Favourites <i class="mdi mdi-heart-outline text-danger"></i></a>
		        			<a class="btn btn-outline-light btn-rounded btn-block mb-2"  v-b-modal.modal-1 @click="form.action = 'review'">Submit Review <i class="bx bx-star text-warning"></i></a>
		        			<a class="btn btn-outline-light btn-rounded btn-block mb-2"  v-b-modal.listings-modal @click="showStores = !showStores"> Stores <i class="bx bxs-store text-primary"></i></a>
		        			
		        			<!--<h6 v-if="categorydata.county !== null">{{categorydata.county.name}}</h6>
		        			<h6 v-if="categorydata.country !== null"><i class="bx bx-globe text-primary font-size-12"></i> {{categorydata.country.name}}</h6>-->
		        		</b-card>
		        	</div>
		        </b-col>
		    	</b-row>
		  	</b-row>
		    <!-- end row -->
		  </div>
		</section>
		<section class="profile-products-section" >
			<b-container fluid>
				
				 <b-row class="py-4">
				 	<!--<b-col md="12" class="d-flex flex-row align-items-start mx-auto">
				 	<div  v-for="(bread, index) in breadcrumbs" class="d-flex flex-row">
		    		<span class="badge badge-light text-dark font-size-12 text-capitalize my-auto">{{bread}}</span>
		    		 <i class="bx bx-chevron-right my-auto" v-if="index !== (breadcrumbs.length -1)"></i>
		    		</div>

		    	</b-col>-->
		    <transition name="fade-filtersleft">
		     	<b-col md="3" v-if="showFilters">
          <div class="col-lg-12 pt-1 mt-4">
        <div>
          <div class="card p-4 rounded-4 shadow-sm border-radius-1em mt-4">
            <!--<h4 class="card-title mb-4">Filter</h4>-->
            <div>
               <div class="mb-3">
              <h6 class="mb-3">Price</h6>
              <vue-slider v-model="priceRange" :min="0" :max="100 + priceRange[1]"  @drag-end="loadSellerProducts()" ></vue-slider>
              <b-form-group label="Min Price" class="w-50 d-inline float-left mt-2 mb-3 px-2">
              <b-form-input
                type="number"
                v-model="priceRange[0]"
                @input="loadSellerProducts"></b-form-input>
              </b-form-group>
              <b-form-group label="Max Price" class="w-50 d-inline float-left mt-2 mb-3 px-2">
                 <b-form-input
                 label="Max Price"
                 type="number"
                 v-model="priceRange[1]"
                 @input="loadSellerProducts"></b-form-input>
              </b-form-group>
            </div>
            		<h6 class="my-3">Collections</h6>
            		<FilterProductCollections v-on:collections-filtered="filterCollections" :all_collections="collectionsdata"></FilterProductCollections>
                <h6 class="my-3">Categories</h6>
                <!--<FilterListTree></FilterListTree>-->
                <FilterCompanyProductCategories :api_token="user.api_token"  :category_data="categoriesdata"></FilterCompanyProductCategories>
                <h5 class="font-size-14 mb-3">Brands</h5>
               <FilterProductBrands v-on:brands-filtered="filterBrands" :api_token="user.api_token"></FilterProductBrands>
            
            </div>
           

          <!-- <div class="mt-4 pt-3">
              <h5 class="font-size-14 mb-3">Customer Rating</h5>
              <div>
                <b-form-checkbox
                  id="checkbox-1"
                  name="checkbox-1"
                  value="accepted"
                  unchecked-value="not_accepted"
                >
                  4
                  <i class="bx bx-star text-warning"></i> & Above
                </b-form-checkbox>

                <b-form-checkbox
                  id="checkbox-2"
                  class="mt-2"
                  name="checkbox-2"
                  value="accepted"
                  unchecked-value="not_accepted"
                >
                  3
                  <i class="bx bx-star text-warning"></i> & Above
                </b-form-checkbox>

                <b-form-checkbox
                  id="checkbox-3"
                  class="mt-2"
                  name="checkbox-3"
                  value="accepted"
                  unchecked-value="not_accepted"
                >
                  2
                  <i class="bx bx-star text-warning"></i> & Above
                </b-form-checkbox>
                <b-form-checkbox
                  id="checkbox-4"
                  class="mt-2"
                  name="checkbox-4"
                  value="accepted"
                  unchecked-value="not_accepted"
                >
                  1
                  <i class="bx bx-star text-warning"></i>
                </b-form-checkbox>
              </div>
            </div>-->
          </div>
          <h6>Advertisement</h6>
          <div class="card shadow-sm border-radius-1em">
              <img src="/images/banner-placeholder.png" class="w-100">
            </div>
        </div>
      </div>
          </b-col>
      </transition>
      <transition name="fade-right">
					<b-col class="" :class="{'col-md-9 fade-right-in' : showFilters == true, 'col-md-12 fade-right-exit' : showFilters == false, }">
						<div class="row mb-3">
          
        <div class="col-lg-7 col-sm-6 d-flex">
            <div class="mt-sm-0 form-inline w-100" >
              <div class="search-box mr-2 ml-auto">
                <div class="position-relative">
                  <input
                    type="text"
                    class="form-control w-100 border"
                    placeholder="Search name, reference or description..."
                    v-model="search"
                    @keyup.enter="loadSellerProducts()"
                  />
                  <i class="bx bx-search-alt search-icon"></i>
                </div>
              </div>
            </div>
          </div>

         
          <div class="col-lg-2 col-sm-6 d-flex">
          <form class="my-auto">
            <small class="mx-auto my-2 text-center d-block">Results {{data.from}} - {{data.to}} of {{data.total}}</small>
          </form>
        </div>
       
          <div class="col-lg-2 col-sm-6 d-flex">
            <div class="d-flex ml-auto">
              <ul class="nav nav-pills product-view-nav">
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': showFilters == true }" href="javascript: void(0);" @click="loadSellerCollections(), showFilters = !showFilters, expanded = !expanded">
                    <i class="bx bx-filter-alt"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': view == 'grid' }" href="javascript: void(0);" @click="view = 'grid'">
                    <i class="bx bx-grid-alt"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': view == 'list' }" href="javascript: void(0);" @click="view = 'list'">
                    <i class="bx bx-list-ul"></i>
                  </a>
                </li>
              </ul>
              <div class="text-sm-right">
                  
                </div>
          </div>
        </div>
         <div class="col-xl-1 col-sm-6">
            <div class="my-auto">
              <b-form-select class="form-control border btn-rounded" v-model="limit" :options="limits" @input="loadSellerProducts()"></b-form-select>
            </div>
          </div>
      </div>
						<b-overlay
			        :show="productsLoading"
			        variant="white"
			        opacity="0.85"
			        blur="1px"
			        rounded="xl">
			          <TradeMarketplaceProductList v-if="viewtype == 'products'" :product_list="data.data" :expanded="expanded" :layouttoggle="false" :view="'grid'" :marketplace="'trade'" :exchange_rates="$attrs.exchange_rates" :selected_currency="$attrs.selected_currency" :selected_currency_symbol="$attrs.selected_currency_symbol" :company="$attrs.company" ></TradeMarketplaceProductList>

		             <b-pagination
		              class="justify-content-center"
		              pills
		              v-model="currentPage"
		              :total-rows="data.total"
		              :per-page="data.per_page"
		              aria-controls="my-table"
		              @input="loadSellerProducts"
		            ></b-pagination>
		             <small class="mx-auto my-2 text-center d-block">Records {{data.from}} - {{data.to}} of {{data.total}} // Page {{data.current_page}} of {{data.last_page}}</small>
			          <div v-if="viewtype == 'services'">Services</div>
			      </b-overlay>
					</b-col>
				</transition>
				
			</b-row>
			<b-row>
				<b-col md="12" class="d-flex mt-4">
				<h2 class="product-info-header">Reviews</h2>
				<hr class="mb-4 flex-grow-1">
			</b-col>
			<b-col md="12">
				<b-row v-if="categorydata.reviews.length > 0"  >
					<b-col>
						<ReviewCarousel :items="categorydata.reviews" :swiperOptions="reviewOptions"></ReviewCarousel>
					</b-col>
					<b-col md="12" class="d-flex">
						<a class="ml-auto btn btn-light" href="javascript:;" v-b-modal.modal-1 @click="form.action = 'review'">Write Review</a>
					</b-col>
				</b-row>
			</b-col>
			</b-row>
			</b-container>
		</section>
		<SideSlidePanel :editPanel="showStores" :panelClass="'edit-sidepanel'" @close-panel="showStores = false">
			<b-row>
				<b-col md="12" class="px-0">
					<div class="bg-light w-100" style="height: 350px"> <gmap-map :center="mapcenter" :zoom="mapzoom" style="height: 350px">
              <gmap-marker
                v-for="(shopslisting, index) in listingsData"
                :key="index"
                v-if="shopslisting.gplace_id !== null"
                :position="{lat: parseFloat(shopslisting.latitude), lng: parseFloat(shopslisting.longitude)}"
                :clickable="true"
                :draggable="true"
                @click="mapcenter = {lat: parseFloat(shopslisting.latitude), lng: parseFloat(shopslisting.longitude)}, mapzoom = 11"
              ></gmap-marker>
            </gmap-map> </div>
				</b-col>
				<b-col md="12">
			<b-card class="shadow-none">
	        		<h4>Stores & Locations</h4>
	        		<div class="mt-sm-0 form-inline w-100" >
              <div class="search-box mr-2 ml-auto my-3">
                <div class="position-relative">
                  <input
                    type="text"
                    class="form-control w-100 border-0 bg-light"
                    placeholder="Search name, reference or description..."
                    v-model="listingsearch" @keydown.enter="loadSellerListings()" 
                  />
                  <i class="bx bx-search-alt search-icon"></i>
                </div>
              </div>
            </div>
	               <ul class="list-group">
                            <li class="list-group-item" v-if="listingsData.length > 0" v-for="shopslisting in listingsData">
                                <div class="media">
                                    <div class="avatar-sm me-3" v-if="shopslisting.logo">
                                        <span
                                            class="avatar-title rounded-circle bg-light"
                                        >
                                            <!--<img
                                            		
                                                :src="shopslisting.logo"
                                                alt=""
                                                height="18"
                                            />-->
                                            
                                        </span>
                                      </div>
                                       <div class="avatar-sm me-3" v-else>
                                        <span class="avatar-title rounded-circle" >{{(shopslisting.name).slice(0,1)}}</span>
                                      </div>
                                   
                                    <div class="media-body">
                                        <h5 class="font-size-14">
                                            {{shopslisting.name}}
                                        </h5>
                                        <p class="text-muted">
                                            <span v-if="shopslisting.address_1">{{shopslisting.address_1}},</span>
				          	<span v-if="shopslisting.address_2">{{shopslisting.address_2}},</span>
				          	<span v-if="shopslisting.address_3">{{shopslisting.address_3}},</span>
				          	<span v-if="shopslisting.county">{{shopslisting.county.name}},</span>
				          	<span v-if="shopslisting.country">{{shopslisting.country.name}},</span>
				          	<span v-if="shopslisting.postcode">{{shopslisting.postcode}}</span>
                                        </p>

                                        <div class="float-end">
                                            <p class="text-muted mb-0" v-if="shopslisting.listing_type">
                                                <i 
                                                    class="bx bx-buildings me-1"
                                                ></i>
                                                {{shopslisting.listing_type.name}}
                                            </p>
                                        </div>
                                        <p class="text-muted mb-0">
                                            <a class="text-primary" >See Location</a>
                                        </p>
                                    </div>
                                </div>
                            </li>
                          </ul>
	        	</b-card>
	        </b-col>
	       </b-row>
		</SideSlidePanel>
		<b-modal id="company-info" centered content-class="border-radius-1em" header-class="border-none" hide-footer size="lg">
			<div class="card shadow-none border border-radius-1em">
				<b-card-body class="card-body pt-0">
            <div class="row">
            	<div class="col-sm-12 pb-4 text-center mb-3 mt-4">
            		<h3>Company Information</h3>
            	</div>
              <div class="col-sm-12 pb-4 text-center border border-radius-1em">
                <div class="avatar-md profile-user-wid mb-2 mx-auto">
                  <img
                  	v-if="categorydata.logo" :src="'https://'+ ($attrs.id + storageurl + categorydata.logo).replaceAll('//', '/').replaceAll('https:/'+$attrs.id+'.s3.eu-west-2.amazonaws.com', '')"
                    alt
                    class="img-thumbnail rounded-circle"
                  />
                   <img
                  	v-else 
                    src="/images/product-placeholder.png"
                    alt
                    class="img-thumbnail rounded-circle"
                  />
                </div>
                <h5 class="font-size-15 text-truncate">{{categorydata.name}}</h5>

            <p
              class="text-muted mb-4 text-center"
            v-html="categorydata.description"></p>
              </div>
           

            </div>
          </b-card-body>
          <div class="card-body">

            <div class="table-responsive mb-0">
              <table class="table">
                <tbody>
                  <tr v-if="('trade' in categorydata.profile_contact)">
                    <th scope="row">Main Contact:</th>
                    <td v-if="categorydata.profile_contact.trade.name !== ('' || null)">{{categorydata.profile_contact.trade.name}}</td>
                    <td v-else>{{categorydata.profile_contact.trade.name}}</td>
                  </tr>
                  <tr >
                    <th scope="row">Phone:</th>
                    <td v-if="categorydata.profile_contact.trade.phone !== ('' || null)">{{categorydata.profile_contact.trade.phone}}</td>
                    <td v-else>{{categorydata.phone}}</td>
                  </tr>
                  <tr>
                    <th scope="row">E-mail:</th>
                     <td v-if="categorydata.profile_contact.trade.email !== ('' || null)">{{categorydata.profile_contact.trade.email}}</td>
                    <td v-else>{{categorydata.email}}</td>
                  </tr>
                  <tr>
                    <th scope="row">Location :</th>
                    <td>
                    	<span v-if="categorydata.profile_contact.trade.address1 !== ('' || null)">
                    		{{categorydata.profile_contact.trade.address1}}
                    	</span>
                    	<span v-if="categorydata.profile_contact.trade.address2 !== ('' || null)">
                    		{{categorydata.profile_contact.trade.address2}}
                    	</span>
                    	<span v-if="categorydata.profile_contact.trade.address3 !== ('' || null)">
                    		{{categorydata.profile_contact.trade.address3}}
                    	</span>
                    	<span v-if="categorydata.profile_contact.trade.postcode !== ('' || null)">
                    		{{categorydata.profile_contact.trade.postcode}}
                    	</span>
                    	<span v-if="categorydata.profile_contact.trade.address1 == ('' || null)">
                    	{{categorydata.city}} <span v-if="categorydata.county !== null">{{categorydata.county.name}}, </span><span v-if="categorydata.country !== null">{{categorydata.country.name}}</span>
                    	</span>
                    </td>
                  </tr>
                </tbody>
              </table>
              <b-card class="border-radius-1em border shadow-none" body-class="p-0">
              	<gmap-map v-if="categorydata.profile_contact.trade.gplace_id !== ''" :center="{ lat: categorydata.profile_contact.trade.latitude, lng: categorydata.profile_contact.trade.longitude }" :zoom="10" style="height: 350px" class="border-radius-1em">
			                  <gmap-marker
			                    
			                    :key="index"
			                    :position="{ lat: categorydata.profile_contact.trade.latitude, lng: categorydata.profile_contact.trade.longitude }"
			                    :clickable="true"
			                    :draggable="true"
			                    @click="center = { lat: categorydata.profile_contact.trade.latitude, lng: categorydata.profile_contact.trade.longitude }"
			                  ></gmap-marker>
			                </gmap-map>
              </b-card>
            </div>
          </div>
        </div>
		</b-modal>
		<b-modal id="upload-modal" centered content-class="border-radius-1em" header-class="border-none" hide-footer>
			<vue-dropzone>
			</vue-dropzone>
		</b-modal>
		<b-modal id="info-modal" centered content-class="border-radius-1em" header-class="border-none" size="lg" hide-footer>
			<b-card-body class="card-body pt-0">
				 <div class="row">
            	<div class="col-sm-12 pb-4 text-center mb-4">
            		<h3 class="text-capitalize">{{infomodalaction}}s for:</h3>
            	</div>
              <div class="col-sm-12 pb-4 text-center border border-radius-1em">
                <div class="avatar-md profile-user-wid mb-2 mx-auto">
                  <img
                  	v-if="categorydata.logo" :src="'https://'+ ($attrs.id + storageurl + categorydata.logo).replaceAll('//', '/').replaceAll('https:/'+$attrs.id+'.s3.eu-west-2.amazonaws.com', '')"
                    alt
                    class="img-thumbnail rounded-circle"
                  />
                   <img
                  	v-else 
                    src="/images/product-placeholder.png"
                    alt
                    class="img-thumbnail rounded-circle"
                  />
                </div>
                <h5 class="font-size-15 text-truncate">{{categorydata.name}}</h5>
              </div>
           

            </div>
             <div class="row">
		       <b-card class="border-radius-1em border shadow-none mt-3 widget-scrollbox" body-class="pt-4 px-2 pb-1">
							<b-row v-if="infomodalaction == 'reviews'" >
								<b-col v-for="item in categorydata.reviews" md="6">
									<ReviewCard :review="item"></ReviewCard>
								</b-col>
									<b-col v-if="categorydata.reviews.length == 0" md="12">
										<div>
											No Trade Reviews Submitted
										</div>
									</b-col>
							</b-row>
					</b-card>
			</div>
			<a class="btn btn-outline-light btn-rounded btn-block mb-2"  v-b-modal.modal-1 @click="form.action = 'review'">Submit Review <i class="bx bx-star text-warning"></i></a>
			</b-card-body>
		</b-modal>
		<b-modal id="modal-1" centered content-class="border-radius-1em" header-class="border-none" hide-footer>
			<b-card-body class="card-body pt-0">
            <div class="row">
            	<div class="col-sm-12 pb-4 text-center mb-4">
            		<h3>You are about to {{form.action}}</h3>
            	</div>
              <div class="col-sm-12 pb-4 text-center border border-radius-1em">
                <div class="avatar-md profile-user-wid mb-2 mx-auto">
                  <img
                  	v-if="categorydata.logo" :src="'https://'+ ($attrs.id + storageurl + categorydata.logo).replaceAll('//', '/').replaceAll('https:/'+$attrs.id+'.s3.eu-west-2.amazonaws.com', '')"
                    alt
                    class="img-thumbnail rounded-circle"
                  />
                   <img
                  	v-else 
                    src="/images/product-placeholder.png"
                    alt
                    class="img-thumbnail rounded-circle"
                  />
                </div>
                <h5 class="font-size-15 text-truncate">{{categorydata.name}}</h5>
              </div>
           

            </div>
          </b-card-body>
    <div v-if="form.action == 'message'">
    	<TradeSellerEnquiry :route="'/api/company/communication/'+$attrs.id+'/trade-enquiry'" :api_token="user.api_token"></TradeSellerEnquiry>
   	</div>

    <ReviewForm v-if="(form.action == 'review')  && (user)" class="px-2" :company_id="categorydata.id" :product_id="''" :service_id="''" :source="'trade'" :api_token="user.api_token" :user="user" :review_type="'company'">
    </ReviewForm>
    <b-form v-if="(form.action == 'favourites')  && (user !== null)" class="px-2">
    	<b-overlay v-show="loadingAactionModal == true">

    	</b-overlay>
    </b-form>

    <b-form v-if="((form.action == 'favourites') || (form.action == 'review')) && (!user)" class="px-2">
    	<!--<a class="btn btn-outline-light btn-rounded btn-block mb-2" target="_blank">Sign In</a>-->
    	<Login submit-url="http://gemesys.co.uk/my-account/login" auth-error="">
             <input type="hidden" name="_token" :value="csrf">
        </Login>
        
    </b-form>
  </b-modal>
</div>
</template>