<script type="text/javascript">
	import Multiselect from "vue-multiselect";
	export default{
		components: {Multiselect},
		data(){
			return{
				address: {
					id: '',
					customer_id: '',
					address_1: '',
					address_2: '',
					address_3: '',
					city: '',
					county: {
						id: '',
					},
					country: {
						id: '',
					},
					alias: '',
					postcode: '',
					delivery_default: 0,
					invoice_default: 0,
					active: 1,

				},
				counties: [],
				resourceLoading: false,
				error_response: [],
				error_message: false,
				
			}
		},
		props: {
			user: {
				type: Object,
				default: () => {},
			},
			address: {
				type: Object,
				default: {
					id: '',
					customer_id: '',
					address_1: '',
					address_2: '',
					address_3: '',
					city: '',
					country_id: '',
					county: {
						id: '',
					},
					country: {
						id: '',
					},
					county_id: '',
					alias: '',
					postcode: '',
					delivery_default: 0,
					invoice_default: 0,
					active: 1,

				},
				
			},
			action: {
				type: String,
				default: () => 'create',
			},
			countries: {
				type: Array,
				default: () => [],
			},
			customer: {
				type: Array,
				default: () => [],
			},

		},
		methods: {
			loadCountries(){
				axios.get('/api/countries', {headers: {
			            'Authorization': 'Bearer ' + this.user.api_token
			        } }).then(response => {
					this.countries = response.data;
				}).catch(error => {
					alert("Could not load countries");
				});
			},
			loadCounties(country){
				this.address.country_id = country.id;
				axios.get('/api/country/'+country.id+'/counties', {headers: {
			            'Authorization': 'Bearer ' + this.user.api_token
			        } }).then(response => {
					this.counties = response.data;
				}).catch(error => {
					alert("Could not load countries");
				});
			},
			selectCounty(county){
				this.address.county_id = country.id;
			},
			updateResource(){
				this.resourceLoading = true;
				if(this.action == 'create'){
				axios.post('/api/company/customer/'+this.customer.id+'/address/'+this.action, this.address, {headers: {
			            'Authorization': 'Bearer ' + this.user.api_token
			        } }).then(response => {
					this.address = response.data;
					this.success_message = true;
					let self = this;
					setTimeout(function(){
						self.emit('address-data', response.data);
					}, 2000);
				}).catch(error => {
					this.error_response = error.response.data.errors;
					let self = this;
					setTimeout(function(){
					self.error_message = true;
					}, 1000);
				});
				}

				if(this.action == 'update'){
				axios.post('/api/company/customer/address/'+this.address.id+'/'+this.action, this.address, {headers: {
			            'Authorization': 'Bearer ' + this.user.api_token
			        } }).then(response => {
					this.address = response.data;
					this.success_message = true;
					let self = this;
					setTimeout(function(){
						self.emit('address-data', response.data);
					}, 2000);

				}).catch(error => {
					this.error_response = error.response.data.errors;
					let self = this;
					setTimeout(function(){
					self.error_message = true;
					}, 1000);
				});
				}
				let self = this;
				setTimeout(function(){
		            self.resourceLoading = false;
		          }, 1500);
			}
		},
		created(){
			this.resourceLoading = true;
			if(this.countries.length == 0){
				this.loadCountries();
			}
			if(this.address.country.id !== ''){
				this.loadCounties(this.address.country);
			}
			let self = this;
			setTimeout(function(){
		            self.resourceLoading = false;
		          }, 1000);
		}
	}
</script>
<template>
	<b-form class="p-3">
		
		<b-overlay
		:show="resourceLoading"
		variant="white"
        opacity="0.85"
        blur="1px"
        rounded="xl">
		<h5 class="text-center">Add New Address</h5>
    	<transition name="slide-error" mode="out-in">
                    <b-alert  variant="danger" :show="error_message"  dismissible @dismissed="error_message = !error_message">
                      <p class="font-size-14">Oops! Please correct the following errors in your submission</p>
                      <ul>
                        <li v-for="(error, key) in error_response">
                          {{error[0]}}
                        </li>
                      </ul>
                    </b-alert>
                    
                  </transition>

                  <transition name="slide-success" mode="out-in">
                    <b-alert  variant="success" :show="success_message"  dismissible @dismissed="success_message = !success_message">
                      <p class="font-size-14">Address successfully saved!</p>
                    </b-alert>
                    
                  </transition>
    	<b-form-group>
    		<b-form-input class="underline-input my-2" v-model="address.address_1" placeholder="Address Line 1" type="text"></b-form-input>
    	</b-form-group>
    	<b-form-group>
    		<b-form-input class="underline-input my-2" v-model="address.address_2" placeholder="Address Line 2" type="text"></b-form-input>
    	</b-form-group>
    	<b-form-group>
    		<b-form-input class="underline-input my-2" v-model="address.address_3" placeholder="Address Line 3" type="text" autocomplete="off"></b-form-input>
    	</b-form-group>
    	<b-form-group>
    		<b-form-input class="underline-input my-2" v-model="address.city" placeholder="City" type="text" autocomplete="off"></b-form-input>
    	</b-form-group>
    	
    	<b-form-group v-if="countries.length > 0" class="my-2">
      		<multiselect v-model="address.country" track-by="name" label="name" :options="countries" :option-height="104" class="underline-select" @input="loadCounties">
                     <!--<template slot="singleLabel" slot-scope="{ option }">{{ option.name }} - {{ option.jobtitle }}</template>-->
                     <template slot="singleLabel" slot-scope="{ option }"><div class="d-flex w-100 h-100">
                     <div class="avatar-xs mr-4 my-auto" v-if="address.country.id !== ''">
                        <span class="avatar-title rounded-circle" >{{(option.iso_code).slice(0,2)}}</span>
                       
                      </div>
                      <div class="option__desc d-flex flex-row flex-grow-1"><span v-if="address.country.id !== ''"  class="option__title ml-2 my-auto w-100">{{ option.name }}</span>
                      	<span v-else class="option__title my-auto w-100">Select Country</span>

                      </div>
                      <div class="avatar-xs my-auto" v-if="address.country.id == ''">
                      <span class="avatar-title rounded-circle bg-light text-primary"><i class="bx bx-chevron-down"></i></span>
                  	 </div>

                      </div></template>
                    <template slot="option" slot-scope="{ option }">
                      <div class="d-flex"><div class="avatar-xs mr-4">
                                        <span class="avatar-title rounded-circle">{{(option.iso_code).slice(0,2)}}</span>
                                      </div>
                      <div class="option__desc d-flex flex-row w-50"><span class="option__title ml-2 my-auto w-100">{{ option.name }}</span>
                      	
                      </div>
                      </div>
                    </template>
                  </multiselect>
    	</b-form-group>
    	<b-form-group v-if="counties.length > 0" class="my-2">
      		<multiselect v-model="address.county" track-by="name" label="name" :options="counties" :option-height="104" class="underline-select" @input="selectCounty" >
                     <!--<template slot="singleLabel" slot-scope="{ option }">{{ option.name }} - {{ option.jobtitle }}</template>-->
                     <template slot="singleLabel" slot-scope="{ option }">
                     <div class="d-flex w-100 h-100">
                     <div class="avatar-xs mr-4 my-auto" v-if="address.county.id !== ''">
                        <span class="avatar-title rounded-circle" >{{(option.name).slice(0,2)}}</span>
                       
                      </div>
                      <div class="option__desc d-flex flex-row flex-grow-1"><span v-if="address.county.id !== ''"  class="option__title ml-2 my-auto w-100">{{ option.name }}</span>
                      	<span v-else class="option__title my-auto w-100">Select State/County</span>

                      </div>
                      <div class="avatar-xs my-auto" v-if="address.county.id == ''">
                      <span class="avatar-title rounded-circle bg-light text-primary"><i class="bx bx-chevron-down"></i></span>
                  	 </div>

                      </div></template>
                    <template slot="option" slot-scope="{ option }">
                      <div class="d-flex"><div class="avatar-xs mr-4">
                                        <span class="avatar-title rounded-circle">{{(option.name).slice(0,2)}}</span>
                                      </div>
                      <div class="option__desc d-flex flex-row w-50"><span class="option__title ml-2 my-auto w-100">{{ option.name }}</span>
                      	
                      </div>
                      </div>
                    </template>
                  </multiselect>

    	</b-form-group>
    	<b-form-group>
    		<b-form-input class="underline-input my-2" v-model="address.postcode" placeholder="Postcode or ZIP" type="text"></b-form-input>
    	</b-form-group>
    	<b-form-group>
    		<b-form-input class="underline-input my-2" v-model="address.alias" placeholder="Address Name" type="text"></b-form-input>
    	</b-form-group>
    	<div class="my-3 px-1">
    		<div dir="ltr" class="form-check form-switch form-switch-sm my-auto mr-auto"><label for="SwitchCheckDefaultSize-1" class="form-check-label">Delivery Default</label> <input type="checkbox" id="SwitchCheckDefaultSize-1" v-model="address.delivery_default" value="1" unchecked-value="0" name="default" class="form-check-input"></div>
    	
    		<div dir="ltr" class="form-check form-switch form-switch-sm my-auto mr-auto"><label for="SwitchCheckDefaultSize-2" class="form-check-label">Billing Default</label> <input type="checkbox" id="SwitchCheckDefaultSize-2" v-model="address.invoice_default" value="1" unchecked-value="0" name="default" class="form-check-input"></div>
    	</div>
    	<a class="btn btn-success btn-rounded btn-block" @click="updateResource()">Save</a>
    	</b-overlay>
        </b-form>
</template>