<script>
	export default{
    props: {
      resource: {
        type: String,
        default: () => '',
      },
      resource_id: {
        type: Number,
        default: () => '',
      },
      api_token: {
        type: String,
        default: () => '',
      },
      resource_route: {
        type: String,
        default: () => '',
      },
      input_classes: {
        type: String
      }
    },
		data(){
			return{
        skip: 0,
        take: 25,
        sortby: 'id',
        order: 'desc',
        sortby: 'id',
        trade_active: '',
        public_active: '',
        brands: '',
        collections: '',
        priceRange: [0,999999],
        currentPage: 1,
        selectedids: [],
        loadingForm: false, 
        isCheckAll: false,
        active: '',
				searchquery: '',
				search_results: [],
				all_brands: '',
				results_start: 0,
      			results_end: 5,
			}
		},
		methods:{
          searchQuery(){
            this.loadingForm = true;
              axios.get('/api/company/customer/search?page='+this.currentPage+'&skip='+this.skip+'&take='+this.take+'&sortby='+this.sortby+'&order='+this.order+'&search='+this.searchquery+'&trade_active='+this.trade_active+'&public_active='+this.public_active+'&active='+this.active+'&brands='+this.brands+'&collections='+this.collections+'&pricerange='+this.priceRange, {headers: {
                  'Authorization': 'Bearer ' + this.api_token
              }}).then(response => {
                console.log(response);
                this.search_results = response.data;
                let self = this;
                setTimeout(function(){
                  self.loadingForm = false;
                }, 500);
                //this.results_end = response.data.total;
              });
          },
          selectResource(resource){
          	this.$emit('search-resource-selected', resource);
          },
          addToResource(resource){
            if(this.resource_route !== ''){
              axios.post(this.resource_route+'product_id='+resource.id+'', {product_id: resource.id}, {headers: {
                  'Authorization': 'Bearer ' + this.api_token
              }}).then(response => {
                this.$emit('product-added', response.data);
              }).catch(error => {

              });
            }else{
               this.$emit('search-resource-selected', resource);
            }
          },
          priceIncVat(price, tax_percentage){
		      var tax = 1 + (tax_percentage / 100);
		      return price * tax;
		      }
		}
	}
</script>
<template>
  <div class="row">
          <div class="col-md-12">
                            <div class="form-control-group">
                              <div class="input-group">
                                <div class="search-box" style="flex-grow: 3">
                                  <div class="position-relative">
                                    <input id="search" ref="customersearch" class="form-control" type="text" :class="input_classes" v-model="searchquery" v-on:keyup.enter="searchQuery" v-on:keydown="searchQuery" style="border: 1px solid #eff2f7">
                                    <i class="bx bx-search-alt search-icon"></i>
                                    <a v-b-tooltip.hover title="Reset" placement="top" href="javascript:;" @click="search_results = [], searchquery = ''">
                                      <i class="bx bx-reset search-icon reset"></i>
                                    </a>
                                  </div>
                                </div>
                                <div class="pl-2">
                                    <a href="javascript:;" class="btn btn-rounded btn-primary btn-block" @click="searchQuery">Search</a>
                                </div>
                             </div>
                             
                            <ul class="list-group searchbar-searchlist shadow" v-if="search_results.length !== 0">
                              <b-overlay
                            id="overlay-background"
                            :show="loadingForm"
                            variant="white"
                            opacity="0.85"
                            blur="1px"
                            rounded="md"
                          >
                              <li v-for="searchresult in search_results" class="list-group-item d-flex">
                              	<div v-if="searchresult.customer !== null"><img v-if="searchresult.customer.avatar" class="avatar-md border mr-3 rounded-circle" :src="searchresult.customer.avatar">
                                <img v-else class="avatar-md border mr-3 rounded-circle" src="/images/product-placeholder.png">
                              </div>
                              <img v-else class="avatar-md border mr-3" src="/images/product-placeholder.png">
                                <div class="mr-auto ml-2 my-auto flex-grow-3 mr-3">
                                	<h6 class="mr-auto my-auto">{{searchresult.firstname}} {{searchresult.lastname}}</h6>
                                	<span class="badge badge-light">{{searchresult.reference}}</span>
						            <a class="text-muted italic no-margin" href="javascript:;">{{searchresult.email}}</a>
                                </div>
                                
                                <a class="btn btn-outline-light btn-rounded ml-auto my-auto flex-grow-1" href="javascript:;" @click="addToResource(searchresult), search_results = [], searchquery = undefined "><i class="bx bx-plus"></i></a>
              
                              
                              </li>
                              <!--<li class="list-group-item py-4 text-center" ><a class="btn btn-outline-light btn-rounded">Load More</a>
                              </li>-->
                               </b-overlay>
                            </ul>
                                                   </div>
                        
                  </div>
                </div>
</template>