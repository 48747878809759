<script type="text/javascript">
	export default{
		data(){
			return{
				users: '',
				limit: 10,
		      	orderDesc: true,
		      	order: 'desc',
		      	sortby: 'id',
		      	search: '',
		      	currentPage: 1,
		      	limits: [
		      	{value: 10, text: "10"},
		        {value: 25, text: "25"},
		        {value: 50, text: "50"},
		        {value: 100, text: "100"},
		    	],
		    	showFilters: false,
			}
		},
		created(){
			this.runQuery();
		},
		methods: {
			runQuery(){
			axios.get('/api/company/'+this.$attrs.resource+'/'+this.$attrs.id+'/users?page='+this.currentPage+'&limit='+this.limit+'&sortby='+this.sortby+'&order='+this.order+'&search='+this.search+'', {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.api_token
              }}).then(response => {
				this.users = response.data;
			}).catch(error => {
				this.load_error = error.data;
			});
			},
			removeUser(userid){
				axios.post('/api/company/'+this.$attrs.resource+'/'+this.$attrs.id+'/user/destroy', {user_id: userid}, {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.api_token
              }}).then(response => {
					this.$emit('user-removed');
					Vue.swal("User Removed");
				}).catch(error => {
					Vue.swal("Error, please try again");
				})
			},
		}
	}
</script>
<template>
	<div class="my-2">
		<div class="row mb-2" v-if="showFilters == false">
			<b-col md="12">
			<a class="btn btn-outline-light float-right" @click="showFilters = !showFilters"><i class="bx bx-filter"></i>
			</a>
			</b-col>
		</div>
		<div class="row mb-2" v-if="showFilters == true">
			<b-col md="2">
			<b-form-select class="form-control" v-model="limit" :options="limits" @input="runQuery()"/>
			</b-col>
			<b-col md="8">
			<b-form-input type="text" v-model="search" @keydown.enter="runQuery" placeholder="Enter name or email and press enter..." />
			</b-col>
			<b-col md="2">
			<a class="btn btn-outline-light float-right" @click="showFilters = !showFilters, search = '', runQuery()"><i class="mdi mdi-close"></i>
			</a>
			</b-col>
		</div>	
		<!--<b-list-group class="user-list-results"  v-if="$attrs.resource !== 'project'">
			<b-list-group-item v-for="user in users.data" class="d-flex flex-row">
				<div class="my-auto">
				<img :src="user.users.user.avatar" class="avatar-xs rounded-circle">
				</div>
				<div class="my-auto mx-4">
				<p class="m-0">{{user.users.user.name}} {{user.users.user.lastname}}<br>
				{{user.users.user.jobtitle}}</p>
				</div>
				<div class="my-auto mx-auto">
				<a :href="'mailto:'+user.users.user.email" class="text-muted">{{user.users.user.email}}</a>
				</div>
				<div class="ml-auto my-auto">
					<a v-b-tooltip.hover class="btn btn-outline-light btn-rounded mr-2" title="View">
                            <i class="mdi mdi-eye-outline font-size-15"></i>
                  </a>
					<a href="javascript" class="btn btn-outline-light btn-rounded mr-2" v-b-tooltip.hover title="Message">
						 <i class="bx bx-message-alt-dots font-size-15"></i>
					</a>
					<a href="javascript" class="btn btn-outline-light btn-rounded" v-b-tooltip.hover title="Remove">
						 <i class="bx bx-trash-alt font-size-15"></i>
					</a>
				</div>
			</b-list-group-item>
		</b-list-group>-->
		<b-list-group class="user-list-results" flush>
			<b-list-group-item v-for="user in users.data" class="d-flex flex-row">
				<div class="my-auto">
				<img v-if="user.users.user.avatar !== null" :src="user.users.user.avatar" class="avatar-xs rounded-circle">
				<div v-else class="avatar-xs d-inline-block mr-1"><span class="avatar-title rounded-circle bg-primary">{{user.users.user.name.slice(0, 1)}}</span></div>
				</div>
				<div class="my-auto mx-4">
				<p class="m-0">{{user.users.user.name}} {{user.users.user.lastname}}<br>
				{{user.users.user.jobtitle}}</p>
				</div>
				<div class="my-auto mx-auto">
				<a :href="'mailto:'+user.users.user.email" class="text-muted">{{user.users.user.email}}</a>
				</div>
				<div class="ml-auto my-auto">
					<a v-b-tooltip.hover.left class="btn btn-outline-light btn-rounded mr-2" title="View">
                            <i class="mdi mdi-eye-outline font-size-15"></i>
                  </a>
					<!--<a href="javascript" class="btn btn-outline-light btn-rounded mr-2" v-b-tooltip.hover.left title="Message">
						 <i class="bx bx-message-alt-dots font-size-15"></i>
					</a>-->
					<a href="javascript:;" @click="removeUser(user.users.id)" class="btn btn-outline-light btn-rounded" v-b-tooltip.hover.left :title="'Remove '+user.users.user.name+' from '+$attrs.resource" >
						 <i class="bx bx-trash-alt font-size-15"></i>
					</a>
				</div>
			</b-list-group-item>
		</b-list-group>
		 <b-pagination
                    class="justify-content-center mt-4"
                    pills
                    v-if="users.last_page > 1"
                    v-model="users.current_page"
                    :total-rows="users.total"
                    :per-page="users.per_page"
                    aria-controls="my-table"
                  ></b-pagination>
	</div>
</template>