<script type="text/javascript">
	import DefaultContentProductCard from "./DefaultContent-ProductCard";

	export default{
		data(){
			return {
				hoverIndex: -1,
				hoverLink: -1,
				categories: [],
				routearray: [],
				search: '',
				currentPage: '',
				limit: 16,
				limits: [16,32,64,96,128],
				layouttoggle: true,
				loadingResource: false,
			}
		},
		props: {
			company: {
				type: Object
			},
			resource: {
				type: String,
				default: () => 'category'
			}
		},
		components: {
			DefaultContentProductCard
		},
		created(){
			this.getResource(this.resource, 1);
		},
		methods: {
			getResource(resource, page){
				this.loadingResource = true;
				this.routearray = this.$route.path.substring(1).split('/');
				axios.get('/website-api/widget/'+resource+'/'+this.routearray[this.routearray.length-1] + (page > 1 ? '?page='+page : '')).then(response => {
					this.categories = response.data;
					let self = this;
					setTimeout(function(){
						self.loadingResource = false;
					}, 400);
				}).catch(error => {
					this.categories = [];
					console.log(error);
				});
			}
		},
		  watch: {
        $route() {
        	this.$nextTick(() => {
        		 //this.loadingPage = true;
        		
        		 this.getResource(this.resource, this.currentPage);

        		});
        	}
        }
	}

</script>
<template>
	<b-container fluid class="py-4 mb-4 default-content category-collection">
		<b-row>
			<b-col md="12" class="mb-3">
				<h1 class="text-capitalize">{{categories.name}}</h1>
					<p v-html="categories.description"></p>
			</b-col>
			<b-col class="col-md-12">
						<div class="row mb-3">
        
          <div class="col-lg-3 col-sm-6 d-flex">
          <form class="my-auto">
            <small class="mx-auto my-2 text-left d-block" v-if="categories.products !== null">Results {{categories.products.from}} - {{categories.products.to}} of {{categories.products.total}}</small>
          </form>
        </div>  
        <div class="col-lg-6 col-sm-6 d-flex">
            <div class="mt-sm-0 form-inline w-100" >
              <div class="app-search search-box mr-2 ml-auto">
                <div class="position-relative">
                  <input
                    type="text"
                    class="form-control w-100 border"
                    placeholder="Search name, reference or description..."
                    v-model="search"
                    @keyup.enter="getResource(resource)"
                  />
                  <span class="bx bx-search-alt search-icon top-0" style="width: 10px;"></span>
                </div>
              </div>
            </div>
          </div>

         
			
			<b-col md="3" class="d-flex">
				<ul class="nav nav-pills product-view-nav my-auto ml-auto d-flex" v-if="layouttoggle == true">
					<li class="nav-item  ml-auto mr-2">
                  <a class="nav-link" :class="{'active': showFilters == true }" href="javascript: void(0);" @click="showFilters = !showFilters, expanded = !expanded">
                    <i class="bx bx-filter-alt"></i>
                  </a>
                </li>
          		<!--<li class="nav-item px-1">
                  <a class="nav-link" :class="{'active': view == 'grid' }" href="javascript: void(0);" @click="view = 'grid'">
                    <i class="bx bx-grid-alt"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': view == 'list' }" href="javascript: void(0);" @click="view = 'list'">
                    <i class="bx bx-list-ul"></i>
                  </a>
                </li>-->
               </ul>
          	 <b-form-select class="form-control my-auto w-25 pl-1" v-model="limit" :options="limits" @input="getResource(resource)"></b-form-select>
          </b-col>
		</div>
	</b-col>
	<b-overlay :show="loadingResource" no-wrap></b-overlay>
			<b-col md="3" v-for="(category, index) in categories.products.data">
				<DefaultContentProductCard :product="category" :company="company" :exchange_rates="$attrs.exchange_rates"  :currencies="$attrs.currencies" :selected_currency="$attrs.selected_currency" :selected_currency_symbol="$attrs.selected_currency_symbol"></DefaultContentProductCard>
				<!--<router-link :to="'/'+($route.path.substring(1).split('/')[0]).replace('ies', 'y')+'/'+category.slug">
					<b-card no-body class="border shadow-none border-radius-1em" @mouseenter="hoverIndex = index" @mouseleave="hoverIndex = -1" >
						<b-card-img v-if="category.image" :src="category.image" />
						<b-card-img v-else :src="'/images/product-placeholder.png'" />
						<b-card-text class="p-4 peach-border-top-5 d-flex">
							<h3 class="font-size-18 my-auto py-2">{{category.name}}</h3>
						<transition name="fade-right">
							<router-link v-if="hoverIndex == index" class="border-bottom border-primary text-uppercase font-size-12 ml-auto text-dark my-auto py-2" :to="'/'+($route.path.substring(1).split('/')[0]).replace('ies', 'y')+'/'+category.slug">
								See Products <i class="bx bx-chevron-right"></i>
							</router-link>
						</transition>
						</b-card-text>
					</b-card>
				</router-link>-->
			</b-col>
		
			<b-col md="12">

				<b-pagination v-if="categories.products.data.length > 0" v-model="currentPage" :total-rows="categories.products.total" :per-page="categories.products.per_page" align="right" @click="getResource(resource, currentPage)"></b-pagination>
			</b-col>
		</b-row>
	</b-container>
</template>


