<script type="text/javascript">
	import PageHeader from "../components/widgets/ecommerce/public/PageHeader";
	import vue2Dropzone from 'vue2-dropzone';
	import { cutClarityData } from "../clarity-cuts";
	import Multiselect from "vue-multiselect";

	export default {
		mounted(){
			this.loadCountries();
		},
		methods: {
			addType(jewellerytype, value){
				this.sales_data.jewellery[jewellerytype].push(value);
			},
			loadCountries(){
				axios.get('/customer-api/countries').then(response => {
					this.countries = response.data;
				}).catch(error => {
					alert("Could not load countries");
				});
			},
			loadCounties(country){
				axios.get('/customer-api/country/'+country.id+'/counties').then(response => {
					this.counties = response.data;
				}).catch(error => {
					alert("Could not load countries");
				});
			},
		},
		components: {PageHeader, vueDropzone: vue2Dropzone, Multiselect},
		data(){
			return {
				addMetalDetails: false,
				showForm: false,
				countries: [],
				counties: [],
				title: 'Sell Your Jewellery',
				breadcrumbs: ["Home", "Sell Your Jewellery"],
				jewellery_types: [
					{name: 'Ring'},
					{name: 'Earrings'},
					{name: 'Brooch'},
					{name: 'Necklace'},
					{name: 'Bracelet'},
					{name: 'Bangle'},
					{name: 'Chain'},
					{name: 'Cufflinks'},
					{name: 'Pendant'},
					{name: 'Tiara'},
					{name: 'Crown'},
					{name: 'Headband'},
					{name: 'Hairclip'},
					{name: 'Armlet'},
					{name: 'Charm bracelet'},
					{name: 'Chatelaine'},
					{name: 'Anklet'},
					{name: 'Amulet'},
					{name: 'Membership pin'},
					{name: 'Cameo'},
					{name: 'Locket'},
					{name: 'Medallion'},
				],
				metals: [{name: 'Gold'}, {name: 'Rose Gold'}, {name: 'White Gold'}, {name: 'Silver'}, {name: 'Platinum'}, {name: 'Palladium'},{name: 'Stainless Steel'}, {name: 'Tungsten'}, {name: 'Meteorite'}, {name: 'Titanium'}],
				gemstone_types:[
		              {name: "Achorite", value: 3.14},
		              {name: "Agate", value: 2.65},
		              {name: "Alexandrite", value: 3.73},
		              {name: "Almandine", value: 3.90},
		              {name: "Almandine Spinel", value: 3.82},
		              {name: "Almandine-Spessartine", value: 3.90},
		              {name: "Amazonite", value: 2.65},
		              {name: "Amethyst", value: 2.65},
		              {name: "Andradite", value: 3.90},
		              {name: "Aquamarine", value: 2.74},
		              {name: "Balas Ruby", value: 3.82},
		              {name: "Black Opal", value: 2.11},
		              {name: "Bloodstone", value: 2.65},
		              {name: "Brazilian Emerald", value: 3.14},
		              {name: "Cairngorm", value: 2.65},
		              {name: "Carnelian", value: 2.65},
		              {name: "Cat's Eye", value: 3.73},
		              {name: "Chalcedony", value: 2.65},
		              {name: "Chlorspinel", value: 3.82},
		              {name: "Chrome Pyrope", value: 3.90},
		              {name: "Chrysoberyl", value: 3.73},
		              {name: "Chrysoprase", value: 2.65},
		              {name: "Citrine", value: 2.65},
		              {name: "Color-Change Garnet", value: 3.90},
		              {name: "Demantoid", value: 3.90},
		              {name: "Diamond", value: 3.51},
		              {name: "Dravite", value: 3.14},
		              {name: "Emerald", value: 2.77},
		              {name: "Feldspar", value: 2.65},
		              {name: "Fire Opal", value: 2.11},
		              {name: "Garnet", value: 3.90},
		              {name: "Ghanospinel", value: 3.82},
		              {name: "Goshenite", value: 2.77},
		              {name: "Green Quartz", value: 2.65},
		              {name: "Grossular", value: 3.90},
		              {name: "Heliodore", value: 2.77},
		              {name: "Heliotrope", value: 2.65},
		              {name: "Hessonite", value: 3.90},
		              {name: "Hyacinth", value: 4.65},
		              {name: "Indicolite</option>  ", value: 3.14},
		              {name: "Jade", value: 3.00},
		              {name: "Jadeite", value: 3.00},
		              {name: "Jargon", value: 4.65},
		              {name: "Jasper", value: 2.65},
		              {name: "Labradorite", value: 2.65},
		              {name: "Lapis Lazuli", value: 2.80},
		              {name: "Malaia", value: 3.90},
		              {name: "Matura Diamond", value: 4.65},
		              {name: "Moonstone", value: 2.65},
		              {name: "Morganite", value: 2.77},
		              {name: "Morion", value: 2.65},
		              {name: "Nephrite", value: 3.00},
		              {name: "Onyx", value: 2.65},
		              {name: "Opal", value: 2.11},
		              {name: "Orthoclase", value: 2.65},
		              {name: "Peridot", value: 3.33},
		              {name: "Peristerite", value: 2.65},
		              {name: "Praziolite", value: 2.65},
		              {name: "Pyrope", value: 3.90},
		              {name: "Pyrope-Almandine", value: 3.90},
		              {name: "Pyrope-Spessartine", value: 3.90},
		              {name: "Quartz", value: 2.65},
		              {name: "Red Beryl", value: 2.77},
		              {name: "Rhodolite", value: 3.90},
		              {name: "Rock Crystal", value: 2.65},
		              {name: "Rose Quartz", value: 2.65},
		              {name: "Rubellite", value: 3.14},
		              {name: "Rubicelle", value: 3.82},
		              {name: "Ruby", value: 4.00},
		              {name: "Sapphire", value: 4.00},
		              {name: "Sapphire Spinel", value: 3.82},
		              {name: "Siberite", value: 3.14},
		              {name: "Smoky Quartz", value: 2.65},
		              {name: "Spessartine", value: 3.90},
		              {name: "Spinel", value: 3.82},
		              {name: "Sunstone", value: 2.65},
		              {name: "Topaz", value: 3.55},
		              {name: "Topazolite", value: 3.90},
		              {name: "Tourmaline", value: 3.14},
		              {name: "Tsavorite", value: 3.90},
		              {name: "Turquoise", value: 2.70},
		              {name: "Uvarovite", value: 3.90},
		              {name: "Verdilite", value: 3.14},
		              {name: "Water Opal", value: 2.11},
		              {name: "White Opal", value: 2.11},
		              {name: "Zircon", value: 4.65},
	            ],
	            gemstone_cuts:[ 
	              {name: "Cabochon Cut", value: .0027},
	              {name: "Emerald Cut Faceted", value: .0025},
	              {name: "Marquise Cut Faceted", value: .0016},
	              {name: "Oval Faceted", value: .0020},
	              {name: "Pear Cut Faceted", value: .0018},
	              {name: "Rectangular Cushions", value: .0022},
	              {name: "Rectangular Faceted", value: .0026},
	              {name: "Rectangular Step Cut", value: .0025},
	              {name: "Round Faceted", value: .0018},
	              {name: "Square Cushions", value: .0022},
	              {name: "Square Cut Faceted", value: .0023},
	              {name: "Square Step Cut", value: .0023},
	            ],
	            diamond_cuts:[
	                  {name: "Round Brilliant", value: 0.0061},
	                  {name: "Princess Cut", value: 0.0082},
	                  {name: "Asscher Step Cut", value: 0.0080},
	                  {name: "Cushion Brilliant", value: 0.00815},
	                  {name: "Heart Brilliant", value: 0.0059},
	                  {name: "Trillion Brilliant", value: 0.0057},
	                  {name: "Marquise Brilliant", value: 0.0058},
	                  {name: "Pear Brilliant", value: 0.0060},
	                  {name: "Oval Brilliant", value: 0.00625},
	                  {name: "Emerald Step Cut", value: 0.0092},
	                  {name: "Radiant Brilliant", value: 0.0081},
	            ],
	            diamondOptions: cutClarityData,
				sales_data: {
					customer: {
						name: '',
						lastname: '',
						email: '',
						address1: '',
						address2: '',
						address3: '',
						county: {id: ''},
						county_id: '',
						country: {id: ''},
						country_id: '',
						postcode: '',
					},
					jewellery: {
						type: '',
						type_styles: '',
						metals: [],
						primary_gemstone: '',
						primary_gemstone_carat: '',
						primary_gemstone_cut: '',
						other_gemstones: [],
						hallmarked: '',
						certificated: '',
						valued: '',
						purchase_price: 0.00,
						valued_price: 0.00,
					}

				}

			}
		}
	}
</script>

<template>
	<div>
		<PageHeader :data="{name: title, description: ''}" />
		<section class="pt-0">
  <b-container class="py-4 padded-section-2">
   <b-row class="py-4 my-4">
   	<b-col lg="4" md="12" class="mx-auto px-4 text-center d-flex flex-column justify-content-center">
   			<img src="/images/jewellery-marketplace-about-us.png" alt="Image" class="rounded-circle w-100 border w-md-75 mx-auto">
      </b-col>
      <b-col lg="8" md="12">
        <b-card class="shadow-none h-100 mb-0" body-class="p-0 d-flex flex-column justify-content-center align-items-start align-items-sm-center align-items-md-start text-left text-left text-sm-center text-md-left">
        <p class="mb-1 text-muted mx-md-0 section-info-preheader cursive-title mt-2">Sell Your...</p>
        <h2 class="section-info-header section-info-header position-relative mx-md-0">Jewellery for Cash</h2>
         <p class="text-dark font-size-16 text-left text-left text-sm-center text-md-left">
           Many high street jewellers will buy your second-hand jewellery for resale, remodelling or for scrap. Discover how much you could get for your second-hand jewellery by filling in a few details about your jewellery to receive quotes from buyers across the UK.
          </p>
          <router-link class="border-bottom border-primary text-dark mt-3 py-2 d-inline-flex" to="/category/jewellery">
          <h4>Find Out More</h4> <i class="bx bx-chevron-right text-primary font-size-20 ml-2"></i>
        </router-link>
      </b-card>
      </b-col>
     
      <b-col lg="12" class="text-left">
        
      </b-col> 
    </b-row>
  </b-container>
</section>
		
			<section >
			<b-container class="padded-section-2">

				<b-row>
					<b-col md="12" class="mx-auto mb-4">
						<h2 class="position-relative mx-auto product-info-header text-center">How does it work?</h2>
					</b-col>
					<b-col md="4" class="mx-auto mb-4">
						<img src="/images/jewellery-marketplace-about-us.png" alt="Image" class="rounded-circle w-100 border w-md-75 mx-auto">
						<h2 class="position-relative mx-auto product-info-header text-center">1. Tell us about your jewellery</h2>
						<p class="text-center">Fill in the form about your jewellery.</p>
					</b-col>
					<b-col md="4" class="mx-auto mb-4">
						<img src="/images/jewellery-marketplace-about-us.png" alt="Image" class="rounded-circle w-100 border w-md-75 mx-auto">
						<h2 class="position-relative mx-auto product-info-header text-center">2. Jewellers submit quotes</h2>
						<p class="text-center">Submit your details and wait for the bids to come flooding in.</p>
					</b-col>
					<b-col md="4" class="mx-auto mb-4">
						<img src="/images/jewellery-marketplace-about-us.png" alt="Image" class="rounded-circle w-100 border w-md-75 mx-auto">
						<h2 class="position-relative mx-auto product-info-header text-center">3. Select a bid and send</h2>
						<p class="text-center">Select your wining bid and send your jewellery.</p>
					</b-col>
					<b-col md="12" class="mx-auto mb-4">
						<a class="btn btn-primary text-center btn-rounded" @click="showForm = true" href="javascript:;"> 
							Start Now
						</a>
					</b-col>
				</b-row>
			</b-container>
		</section>
		<section v-if="showForm == true">
			<b-container fluid>
				<b-row>
					<b-col md="9">
				 <b-row class="pb-4 mt-4 pt-4">
					<b-col md="12" class="mx-auto d-flex mb-4">
						<h2 class="position-relative mr-auto product-info-header">Personal Details</h2>
						<hr class="mb-4 ml-3 flex-grow-1">
					</b-col>
					<b-col md="12" class="mx-auto">
						<b-form-group label="Name">
							<b-form-input type="text" v-model="sales_data.customer.name"></b-form-input>
						</b-form-group>
						<b-form-group label="Last Name">
							<b-form-input type="text" v-model="sales_data.customer.lastname"></b-form-input>
						</b-form-group>
						<b-form-group label="Email Address">
							<b-form-input type="email" v-model="sales_data.customer.email"></b-form-input>
						</b-form-group>
						<b-form-group label="Address 1">
							<b-form-input type="text" v-model="sales_data.customer.address1"></b-form-input>
						</b-form-group>
						<b-form-group label="Address 2">
							<b-form-input type="text" v-model="sales_data.customer.address2"></b-form-input>
						</b-form-group>
						<b-form-group label="Address 3">
							<b-form-input type="text" v-model="sales_data.customer.address3"></b-form-input>
						</b-form-group>
						<b-form-group v-if="counties.length > 0" class="my-2" label="County">
      		<multiselect v-model="sales_data.customer.county" track-by="name" label="name" :options="counties" :option-height="104"  class="border border-radius-1em">
                     <!--<template slot="singleLabel" slot-scope="{ option }">{{ option.name }} - {{ option.jobtitle }}</template>-->
                     <template slot="singleLabel" slot-scope="{ option }">
                     <div class="d-flex w-100 h-100">
                     <div class="avatar-xs mr-4 my-auto" v-if="sales_data.customer.county.id !== ''">
                        <span class="avatar-title rounded-circle" >{{(option.name).slice(0,2)}}</span>
                       
                      </div>
                      <div class="option__desc d-flex flex-row flex-grow-1"><span v-if="sales_data.customer.county.id !== ''"  class="option__title ml-2 my-auto w-100">{{ option.name }}</span>
                      	<span v-else class="option__title my-auto w-100">Select State/County</span>

                      </div>
                      <div class="avatar-xs my-auto" v-if="sales_data.customer.county.id == ''">
                      <span class="avatar-title rounded-circle bg-light text-primary"><i class="bx bx-chevron-down"></i></span>
                  	 </div>

                      </div></template>
                    <template slot="option" slot-scope="{ option }">
                      <div class="d-flex"><div class="avatar-xs mr-4">
                                        <span class="avatar-title rounded-circle">{{(option.name).slice(0,2)}}</span>
                                      </div>
                      <div class="option__desc d-flex flex-row w-50"><span class="option__title ml-2 my-auto w-100">{{ option.name }}</span>
                      	
                      </div>
                      </div>
                    </template>
                  </multiselect>
    	</b-form-group>
						 	<b-form-group v-if="countries.length > 0" class="my-2" label="Country">
      		<multiselect v-model="sales_data.customer.country" track-by="name" label="name" :options="countries" :option-height="104" @input="loadCounties" class="border border-radius-1em">
                     <!--<template slot="singleLabel" slot-scope="{ option }">{{ option.name }} - {{ option.jobtitle }}</template>-->
                     <template slot="singleLabel" slot-scope="{ option }"><div class="d-flex w-100 h-100">
                     <div class="avatar-xs mr-4 my-auto" v-if="sales_data.customer.country.id !== ''">
                        <span class="avatar-title rounded-circle" >{{(option.iso_code).slice(0,2)}}</span>
                       
                      </div>
                      <div class="option__desc d-flex flex-row flex-grow-1"><span v-if="sales_data.customer.country.id !== ''"  class="option__title ml-2 my-auto w-100">{{ option.name }}</span>
                      	<span v-else class="option__title my-auto w-100">Select Country</span>

                      </div>
                      <div class="avatar-xs my-auto" v-if="sales_data.customer.country.id == ''">
                      <span class="avatar-title rounded-circle bg-light text-primary"><i class="bx bx-chevron-down"></i></span>
                  	 </div>

                      </div></template>
                    <template slot="option" slot-scope="{ option }">
                      <div class="d-flex"><div class="avatar-xs mr-4">
                                        <span class="avatar-title rounded-circle">{{(option.iso_code).slice(0,2)}}</span>
                                      </div>
                      <div class="option__desc d-flex flex-row w-50"><span class="option__title ml-2 my-auto w-100">{{ option.name }}</span>
                      	
                      </div>
                      </div>
                    </template>
                  </multiselect>
    	</b-form-group>
    	
						<b-form-group label="Postcode">
							<b-form-input type="text" v-model="sales_data.customer.postcode"></b-form-input>
						</b-form-group>
					</b-col>

					<b-col md="12" class="mx-auto d-flex pt-4 mt-4 mb-4">
						<h2 class="position-relative mr-auto product-info-header">Jewellery Type</h2>
						<hr class="mb-4 ml-3 flex-grow-1">
					</b-col>


					<b-col md="12" class="mx-auto">
						<b-row>
							<b-col md="3"  v-for="type in jewellery_types">
								<b-card class="border-radius-1em border shadow-none" @click="sales_data.jewellery.type = type.name" :class="{'border-success' : sales_data.jewellery.type == type.name}">
									{{type.name}}
									<i class="bx bx-check-circle text-success" v-if="sales_data.jewellery.type == type.name"></i>
								</b-card>
							</b-col>
						</b-row>
					</b-col>
					<b-col md="12" class="mx-auto d-flex pt-4 mt-4 mb-4">
						<h2 class="position-relative mr-auto product-info-header">Metal(s)</h2>
						<hr class="mb-4 ml-3 flex-grow-1">
					</b-col>
					<b-col md="12" class="mx-auto">
						<b-row>
							<b-col md="3"  v-for="type in metals" v-if="addMetalDetails == false">
								<b-card class="border-radius-1em border shadow-none" @click="addType('metals', type), addMetalDetails = true" :class="{'border-success' : sales_data.jewellery.primary_metal == type.name}">
									{{type.name}}
									<i class="bx bx-check-circle text-success" v-if="sales_data.jewellery.metals.includes(type)"></i>
								</b-card>
							</b-col>
							<b-col md="12" v-if="addMetalDetails == true">
								<b-form-group label="Karat (ct)" description="Please provide estimated values if you know">
								<b-form-input type="range">

									</b-form-input>
								</b-form-group>
								<b-form-group label="Weight (g)" description="Please provide estimated values if you know">
								<b-form-input type="range">

									</b-form-input>
								</b-form-group>
							</b-col>
						</b-row>
					</b-col>
				
					<b-col md="12" class="mx-auto d-flex pt-4 mt-4 mb-4">
						<h2 class="position-relative mr-auto product-info-header">Gemstone(s)</h2>
						<hr class="mb-4 ml-3 flex-grow-1">
					</b-col>
					<b-col md="12" class="mx-auto">
						<b-row>
							<b-col md="3"  v-for="type in gemstone_types">
								<b-card class="border-radius-1em border shadow-none" @click="sales_data.jewellery.primary_gemstone = type.name" :class="{'border-success' : sales_data.jewellery.primary_gemstone == type.name}">
									{{type.name}}
									<i class="bx bx-check-circle text-success" v-if="sales_data.jewellery.primary_gemstone == type.name"></i>
								</b-card>
							</b-col>
						</b-row>
					</b-col>
					
					<b-col md="12" class="mx-auto d-flex pt-4 mt-4 mb-4">
						<h2 class="position-relative mr-auto product-info-header">Images</h2>
						<hr class="mb-4 ml-3 flex-grow-1">
					</b-col>
					<b-col md="12" class="mx-auto">
						 <vue-dropzone id="dropzone" ref="myVueDropzone" :use-custom-slot="true" :options="avatarDropzoneOptions" @vdropzone-file-added="avatarFileAdded"  @vdropzone-drop="avatarFileAdded">
			                        <div class="dropzone-custom-content d-flex flex-column justify-content-center align-items-center" style="height: 500px;">
			                            <i class="display-4 text-muted bx bxs-cloud-upload"></i>
			                            <h4>Drop files here or click to upload.</h4>
			                        </div>
                        		</vue-dropzone>
					</b-col>

				 </b-row>
				</b-col>
				<b-col md="3">
					<b-row class="position-sticky">
						<b-col md="12" class="mx-auto d-flex pt-4 mt-4 mb-4">
							<h3 class="position-relative mr-auto product-info-header">Summary</h3>
							<hr class="mb-4 ml-3 flex-grow-1">
						</b-col>
						<b-col md="12">
							<label>Your Details</label>
							<hr>
							<b-card class="border-radius-1em border shadow-none">
								<p>{{sales_data.customer.name}} {{sales_data.customer.lastname}}</p>
								<p>{{sales_data.customer.address1}}<br>{{sales_data.customer.address2}}<br>{{sales_data.customer.address3}}<br>{{sales_data.customer.county}}<br>{{sales_data.customer.postcode}}<br>
								</p>
							</b-card>
						</b-col>
						<b-col md="12">
							<label>Your Item</label>
							<hr>
							<b-list-group class="border-radius-1em">
								<b-list-group-item v-if="sales_data.jewellery.type !== ''">
									<p><strong>Jewellery Type:</strong> {{sales_data.jewellery.type}}</p>
								</b-list-group-item>
								<b-list-group-item v-if="sales_data.jewellery.primary_metal !== ''">
									<p><strong>Metal(s):</strong> <span v-for="metal in sales_data.jewellery.metals" class="mr-1">{{metal}}</span></p>
								</b-list-group-item>
							</b-list-group>
							
						</b-col>
					</b-row>
				</b-col>
			</b-row>

			</b-container>

		</section>

	</div>

</template>