<script>
import PublicTopbar from "../components/public-topbar";
import PublicNav from "../components/public-nav";
import RightBar from "../components/right-bar";
import Footer from "../components/public-footer";
import Breadcrumbs from "../components/widgets/ecommerce/public/Breadcrumbs";

/**
 * Public-layout
 */
export default {
  components: {
    Breadcrumbs,
    PublicTopbar,
    PublicNav,
    Footer,
    RightBar
  },
  props: {
    user: {
      type: Object
    }
  },
  data() {
    return {
    };
  },
  created: () => {
    
    document.body.setAttribute("data-layout", "horizontal");
    document.body.setAttribute("data-topbar", "light");
    document.body.removeAttribute("data-sidebar", "light");
    document.body.removeAttribute("data-layout-size", "fluid");
    
  },
  mounted(){
    this.checkLaravelSessionCookie();
  },
  methods: {
    checkLaravelSessionCookie() {
      
    },
    toggleRightSidebar() {
      document.body.classList.toggle("right-bar-enabled");
    },
    hideRightSidebar() {
      document.body.classList.remove("right-bar-enabled");
    }
  }
};
</script>

<template>
  <div>
    <!-- Begin page -->
    <div id="layout-wrapper">
      <PublicTopbar :user="user" :regError="$attrs.regError" :seller_sessions="$attrs.seller_sessions" :currencies="$attrs.currencies" :exchange_rates="$attrs.exchange_rates" :selected_currency="$attrs.selected_currency" :selected_currency_symbol="$attrs.selected_currency_symbol" />
      <PublicNav />
        <Breadcrumbs v-if="$route.path !== '/'"></Breadcrumbs>
      <!-- ============================================================== -->
      <!-- Start right Content here -->
      <!-- ============================================================== -->
      <div class="main-content">
        <div class="page-content bg-white p-0">
          
            <slot  />
     
          <!-- container-fluid -->
        </div>
        <!-- End Page-content -->
        <Footer :user="user" :recaptcha_token="$attrs.recaptcha_token" />
      </div>
      <!-- end main content-->
    </div>
    <!-- END layout-wrapper -->
    <RightBar />
  </div>
</template>
