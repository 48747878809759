<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import Swal from "sweetalert2";
import SideSlidePanel from "../../components/widgets/SideSlidePanel";
import SearchCompanyUser from "../../components/widgets/SearchCompanyUser";
import UserList from "../../components/widgets/UserList";
import SearchCompanyUsers from "../../components/widgets/SearchCompanyUsers";
import SearchCompanyDepartment from "../../components/widgets/SearchCompanyDepartment";
import Subscription from '../../components/widgets/subscription'

/**
 * Contacts-list component
 */
export default {
  components: { Layout, PageHeader, SideSlidePanel, SearchCompanyUser, UserList, SearchCompanyUsers, SearchCompanyDepartment, Subscription },
  data() {
    return {
      title: "Teams",
      view: 'list',
      delete_action: [],
      delete_confirm: false,
      deleted_message: false, 
      success_message: false,
      delete_form_load: false, 
      error_message: false, 
      loadingForm: false,
      error_reponse: '',
      userAddToDepartment: false,
      teams: [],
      load_error: [],
      currentPage: 1,
      limit: 25,
      selectedids: [],
      orderDesc: true,
      order: 'desc',
      sortby: 'id',
      search: '',
      timeout: null,
      formResponse: '',
      errorResponse: [],
      addFormSubmitted: false,
      updateManagerBtn: false,
      isCheckAll: false,
      selectedids: [],
      newTeam:{
        id: '',
        name: '',
        description: '',
        manager_id: '',
        company_department_id: '',
        company_id: this.$attrs.company.id,
      },
      updateTeam: {
        id: '',
        name: '',
        description: '',
        manager_id: '',
        company_department_id: '',
        company_id: this.$attrs.company.id,
      },
      updatePanel: false,
      addPanel: false,
      staffPanel: false,
      limits: [
        {value: 25, text: "25"},
        {value: 50, text: "50"},
        {value: 100, text: "100"},
      ],
      items: [
        {
          text: "Company",
          href: "/"
        },
        {
          text: "Teams",
          active: true
        }
      ]
    };
  },
  created(){
    if(this.$attrs.validsubscription == 0){
      this.title = 'Subscription';
      this.items[1].text = 'Subscription';
    }
    this.searchResource();
  },
  methods: {
    deleteTeamEvent(action){
      this.delete_action = action;
      this.delete_confirm = true;
    },
    deleteTeam(action){
      this.delete_form_load = true;
      axios.post('/api/company/team/'+action.id+'/destroy', {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
              }}).then(response => {
         if(response.data == true){
                var self = this;
                setTimeout(function(){
                 //Vue.swal("Avatar Updated");
                 self.delete_confirm = false;
                 self.deleted_message = true;
                 self.delete_form_load =false;
                 self.searchResource();
                }, 1500);
            }
          }).catch(error => {
        this.error_message = true;
      });
    },
    searchResource(){
      axios.get('/api/company/teams?page='+this.currentPage+'&limit='+this.limit+'&sortby='+this.sortby+'&order='+this.order+'&search='+this.search+'', {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
              }}).then(response => {
            this.teams = response.data;
      }).catch(error => {
            this.load_error = response.error;
      });
    },
    searchFilter(e) {
      const searchStr = e.target.value;
      this.searchResource();
    },
    setTimeout(callback) {
        this.clearTimeout()
        this.timeout = setTimeout(() => {
          this.clearTimeout()
          callback()
        }, 3000)
    },
    clearTimeout() {
        if (this.timeout) {
          clearTimeout(this.timeout)
          this.timeout = null;
        }
    },
    addNewTeam(){
      this.loadingForm = true;
      axios.post('/api/company/team/store', this.newTeam, {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
              }}).then(response => {
            this.formResponse = response.data;
          this.addFormSubmitted = false;
          this.success_message = true;
          this.searchResource();
           var self = this;
                setTimeout(function(){
                self.loadingForm = 0;
                self.addPanel = false;
                self.newTeam = {
                    id: '',
                    name: '',
                    description: '',
                    manager_id: '',
                    company_department_id: '',
                    company_id: this.$attrs.company.id,
                  };
                }, 1500);
      }).catch(error => {
            this.error_reponse = error.response.data.errors;
            this.error_message = true;
            let self = this;
              setTimeout(function(){
                self.loadingForm = false;
              }, 1500);
      });
    },
    updateExistingTeam(){
      this.loadingForm = true;
        axios.post('/api/company/team/'+this.updateTeam.id+'/update', this.updateTeam, {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
              }}).then(response => {
          this.formResponse = response.data;
          this.success_message = true;
          var self = this;
                setTimeout(function(){
                self.loadingForm = false;
                }, 1500);
        }).catch(error => {
          this.error_reponse = error.response.data.errors;
          this.error_message = true;
          let self = this;
            setTimeout(function(){
              self.loadingForm = false;
            }, 1500);
          });
      },
      getManager(selectedOption){
        this.newTeam.manager_id = selectedOption.companies[0].id;
        this.updateTeam.manager_id = selectedOption.companies[0].id;
      },
      getDepartment(selectedOption){
        this.newTeam.company_department_id = selectedOption.id;
        this.updateTeam.company_department_id = selectedOption.id;
      },
      filterSelected(id){
        if(this.selectedids.length > 0){
          var exists = this.selectedids.filter(item => item == id);
          if(exists.length > 0){
            return true;
          }else{
            return false
          }
        }else{
          return false;
        }
      },
      checkAll: function(){ 
                 this.isCheckAll = !this.isCheckAll; 
                 this.selectedids = []; 
                 if(this.isCheckAll){ 
                     // Check all 
                     for (var key in this.teams.data) {          
                        this.selectedids.push(this.teams.data[key].id); 
                     } 
                 } 
              }, 
      updateCheckall: function(){ 
                 if(this.selectedids.length == this.teams.data.length){
                    this.isCheckAll = true; 
                 }else{ 
                    this.isCheckAll = false; 
                 } 
      }, 

  }
};
</script>

<template>
  <Layout v-if="$attrs.validsubscription == 1" :nickname="$attrs.nickname" :user="$attrs.user" >
    <PageHeader :title="title" :items="items" />
    <div class="row mb-3">
      <b-col md="12">
                    <transition name="slide-success" mode="out-in">
                  <b-alert id="success-message" variant="success" :show="success_message"  dismissible @dismissed="success_message = !success_message">
                      <p class="font-size-14">Congratulations, your action has been saved.</p>
                    </b-alert>
                  </transition>
                  </b-col>
          <div class="col-xl-1 col-sm-6">
            <div class="mt-2">
              <b-form-select class="form-control" v-model="limit" :options="limits" @input="searchResource()"></b-form-select>
            </div>
          </div>
         
          <div class="col-lg-2 col-sm-6 d-flex">
          <form class="my-auto">
            <small class="mx-auto my-2 text-center d-block">Results {{teams.from}} - {{teams.to}} of {{teams.total}}</small>
          </form>
        </div>
       
          <div class="col-lg-5 col-sm-3 d-flex">
            <div class="form-inline w-100 my-auto search-box" >
                <div class="position-relative">
                  <input
                    type="text"
                    class="form-control w-100 border-0"
                    placeholder="Search name, reference or description..."
                    v-model="search"
                    @keyup.enter="searchFilter($event)"
                  />
                  <i class="bx bx-search-alt search-icon"></i>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-sm-4 d-flex">
              <ul class="nav nav-pills product-view-nav pt-2 ml-auto">
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': showFilters == true }" href="javascript: void(0);" @click="showFilters = !showFilters">
                    <i class="bx bx-filter-alt"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': view == 'grid' }" href="javascript: void(0);" @click="view = 'grid', searchResource(), listview = 'grid'">
                    <i class="bx bx-grid-alt"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': view == 'list' }" href="javascript: void(0);" @click="view = 'list', searchResource(), listview = 'list'">
                    <i class="bx bx-list-ul"></i>
                  </a>
                </li>
                <li class="nav-item">
                 <b-dropdown text="Bulk Actions" variant="primary" class="rounded-pill px-0 mx-0">
                  <b-dropdown-item href="javascript: void(0);">Toggle Active</b-dropdown-item>
                  <b-dropdown-item href="javascript: void(0);">Toggle Trade</b-dropdown-item>
                  <b-dropdown-item href="javascript: void(0);">Toggle Public</b-dropdown-item>
                  <b-dropdown-item href="javascript: void(0);">Delete</b-dropdown-item>
                </b-dropdown>
                </li>
                <li class="nav-item">
                 <a
                    class="btn btn-success mb-2 mr-2 text-white"
                    @click="addPanel = !addPanel"
                  >
                    <i class="mdi mdi-plus mr-1"></i> New
                  </a>
                </li>
              </ul>
              <div class="text-sm-right">
                  
                </div>
          </div>
        </div>
    <div class="row">
       <div class="col-md-12">
          <SideSlidePanel id="filter-project-panel" :editPanel="delete_confirm || deleted_message" :panelClass="'search-sidepanel mb-4'" @close-panel="delete_confirm = false">
             <b-overlay
                        id="report-generating-overlay"
                        :show="delete_form_load"
                        variant="white"
                        opacity="0.85"
                        blur="1px"
                        rounded="md"
                        class="w-100"
                      >
          <div class="col-md-12 confirm-delete d-flex h-100" v-if="delete_confirm == true">
            <div class="card my-auto mx-auto pb-3 text-center">
              <div class="card-body">
              <h4>Are You Sure?</h4>
              <p>You Are About to Delete Team <strong>{{delete_action.name}}</strong>.<br>All projects, tasks and reviews under this team will not be effected.</p>
             <!--<b-row>
              <b-col md="4">
              <b-form-group title="Delete Projects" description="Delete all projects assigned to this team">
                <b-form-checkbox v-model="delete_assprojects" switch></b-form-checkbox>
              </b-form-group>
            </b-col>
             <b-col md="4">
              <b-form-group title="Delete All Actions" description="Delete all actions assigned to this team">
                <b-form-checkbox v-model="delete_assactions" switch></b-form-checkbox>
              </b-form-group>
            </b-col>
             <b-col md="4">
              <b-form-group title="Delete All Reviews" description="Delete all reviews assigned to this team">
                <b-form-checkbox v-model="delete_assreviews" switch></b-form-checkbox>
              </b-form-group>
            </b-col>
            </b-row>-->
              <a class="btn btn-success float-left" @click="deleteTeam(delete_action)">Confirm</a>
              <a class="btn btn-danger float-right" @click="delete_confirm = false, delete_action = []">Cancel!</a>
            </div>
            </div>
          </div>
          <div class="col-md-12 confirm-delete d-flex h-100" v-if="deleted_message == true">
            <div class="card my-auto mx-auto pb-3 text-center">
              <div class="card-body">
              <h4>Team Deleted</h4>
              <p>Your team has been deleted.</p>
              <a class="btn btn-success mx-auto text-center" @click="delete_confirm = false, deleted_message = false">Close</a>
            </div>
            </div>
          </div>
        </b-overlay>
        </SideSlidePanel>
      </div>
    </div>
    <div class="row" v-if="view == 'list'">
      
      <div class="col-lg-12">
        <div class="card">
          <div class="">
            <div class="table-responsive">
              <table class="table table-centered table-wrap table-hover">
                <thead>
                  <tr>
                    <th scope="col" style="width: 100px; text-align: center;"><input type="checkbox" class="align-middle" @click='checkAll()' v-model="isCheckAll" /></th>
                    <th scope="col" style="width: 200px">Team</th>
                    <th scope="col" class="text-center">Manager</th>
                    <th scope="col" class="text-center">Projects</th>
                    <th scope="col" style="width: 250px">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="team in teams.data" :key="team.id">
                    <td>
                      <div class="text-center">
                        <b-form-checkbox class="select-row-btn" v-model="selectedids" :value="team.id" button-variant="primary" button>

                          <i v-if="filterSelected(team.id)" class="bx bx-check"></i><span class="avatar-title rounded-circle" v-else>{{(team.name).slice(0,1)}}</span></b-form-checkbox>
                      </div>
                      <!--<div  class="avatar-xs">
                        <span class="avatar-title rounded-circle">{{(team.name).slice(0,1)}}</span>
                      </div>-->
                      
                    </td>
                    <td>
                      <h5 class="font-size-14 mb-1">
                        <a :href="'/company/team/'+team.uid" class="text-dark">{{team.name}}</a>
                      </h5>
                    </td>
                    <td>
                     <div v-if="team.manager.length !== ''" class="team text-center">
                      <a class="team-member" :href="'/company/user/'+team.manager.uid" v-b-tooltip.hover :title="'Managed By '+team.manager.user.name">
                        <img class="rounded-circle avatar-xs" :src="team.manager.user.avatar" alt />
                      </a>
                     </div>
                     
                    </td>
                    <td>
                      <div class="avatar-xs mx-auto">
                    <a href="javascript:;" @click="getActions(team)">
                         <span class="avatar-title rounded-circle"  v-b-tooltip.hover title="Show Related Projects">{{team.projects_count}}</span>
                        </a>
                    </div>

                    </td>
                    <td>
                      <ul class="list-inline font-size-20 contact-links mb-0">
                        <li class="list-inline-item px-1">
                          <a v-b-tooltip.hover title="Edit" @click="updateTeam = team, updatePanel = true">
                            <i class="bx bx-edit"></i>
                          </a>
                        </li>
                        <li class="list-inline-item px-1">
                          <a v-b-tooltip.hover title="View" :href="'/company/team/'+team.uid">
                            <i class="mdi mdi-eye-outline"></i>
                          </a>
                        </li>
                        <li class="list-inline-item px-1">
                          <a v-b-tooltip.hover title="Staff" @click="updateTeam = team, staffPanel = true">
                            <i class="bx bx-group"></i>
                          </a>
                        </li>
                        <!--<li class="list-inline-item px-1">
                          <a v-b-tooltip.hover title="Reports">
                            <i class="bx bx-line-chart"></i>
                          </a>
                        </li>-->
                        <li class="list-inline-item px-1">
                          <a v-b-tooltip.hover title="Delete" @click="deleteTeamEvent(team)">
                            <i class="bx bx-trash-alt"></i>
                          </a>
                        </li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="row">
              <div class="col-lg-12">
                 <b-pagination
              class="justify-content-center"
              pills
              v-model="currentPage"
              :total-rows="teams.total"
              :per-page="teams.per_page"
              aria-controls="my-table"
              @input="searchResource"
            ></b-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="view == 'grid'">
      <div class="col-lg-12">
        <div class="bg-transparent">
          <div class="">
            <div class="row">
            <div v-for="team in teams.data" :key="team.id" class="col-xl-3 col-sm-6">
        <div class="card text-center">
          <div class="card-body">
            <div class="avatar-sm mx-auto mb-4">
              <span
                class="avatar-title rounded-circle bg-soft-primary text-primary font-size-16"
              >{{(team.name).slice(0,1)}}</span>
            </div>
            <h5 class="font-size-15">
              <a href="javascript: void(0);" class="text-dark">{{team.name}}</a>
            </h5>
            <p class="text-muted mb-2">{{team.description}}</p>
            <b-row>
              <b-col md="6">
           
            <div v-if="team.manager.length !== ''" class="team text-center">
                      <a class="team-member" :href="'/company/user/'+team.manager.uid" v-b-tooltip.hover :title="'Managed By '+team.manager.user.name">
                        <img class="rounded-circle avatar-xs" :src="team.manager.user.avatar" alt />
                      </a>
                     </div>
                      <strong>Manager</strong>
                    </b-col>

              <b-col md="6">
            <div class="">
             
             <div class="avatar-xs mx-auto">
                    <a href="javascript:;" @click="getActions(team)">
                         <span class="avatar-title rounded-circle"  v-b-tooltip.hover title="Show Related Projects">{{team.projects_count}}</span>
                        </a>
                    </div>
                     <strong>Projects</strong>
            </div>
          </b-col>
          </b-row>
          </div>
          <div class="card-footer bg-transparent border-top">
            <div class="contact-links d-flex font-size-20">
                        <div class="flex-fill">
                          <a v-b-tooltip.hover title="Edit" @click="updateTeam = team, updatePanel = true">
                            <i class="bx bx-edit"></i>
                          </a>
                        </div>
                        <div class="flex-fill">
                          <a v-b-tooltip.hover title="View" :href="'/company/team/'+team.uid">
                            <i class="mdi mdi-eye-outline"></i>
                          </a>
                        </div>
                        <div class="flex-fill">
                          <a v-b-tooltip.hover title="Staff" @click="updateTeam = team, staffPanel = true">
                            <i class="bx bx-group"></i>
                          </a>
                        </div>
                        <!--<div class="flex-fill">
                          <a v-b-tooltip.hover title="Reports">
                            <i class="bx bx-line-chart"></i>
                          </a>
                        </div>-->
                        <div class="flex-fill">
                          <a v-b-tooltip.hover title="Delete" @click="deleteTeamEvent(team)">
                            <i class="bx bx-trash-alt"></i>
                          </a>
                        </div>
                      </div>
          </div>
        </div>
      </div>
    </div>
            <div class="row">
              <div class="col-lg-12">
                 <b-pagination
                    class="justify-content-center"
                    pills
                    v-model="currentPage"
                    :total-rows="teams.total"
                    :per-page="teams.per_page"
                    aria-controls="my-table"
                    @input="searchResource"
                  ></b-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <SideSlidePanel :editPanel="addPanel" :panelClass="'edit-sidepanel'" @close-panel="addPanel = false" >
      <b-row>
              <b-col md="12">
                <div class="m-4 px-2">
                <h4>Add New Team</h4>
                    <transition name="slide-error" mode="out-in">
                    <b-alert id="errors" variant="danger" :show="error_message"  dismissible @dismissed="error_message = !error_message">
                      <p class="font-size-14">Oops! Please correct the following errors in your submission</p>
                      <ul>
                        <li v-for="(error, key) in error_reponse">
                          {{error[0]}}
                        </li>
                      </ul>
                    </b-alert>
                    
                  </transition>
              <b-overlay
                :show="loadingForm"
                variant="white"
                        opacity="0.85"
                        blur="1px"
                        rounded="md"
              >
              <b-row>
                <b-col md="12">
                  <b-form-group label="Team Name">
                    <b-form-input type="text" name="name" v-model="newTeam.name" :state="newTeam.name.length > 0" required></b-form-input>
                  </b-form-group>
                  <b-form-group label="Description" :description="newTeam.description.length+' of 200'" required >
                     <b-form-textarea name="name" v-model="newTeam.description" rows="5" placeholder="Enter short company description..." :state="(newTeam.description.length <= 200) && (newTeam.description.length > 0)"></b-form-textarea>
                  </b-form-group>
                  <b-form-group label="Team Manager">
                    <SearchCompanyUser :api_token="this.$attrs.user.api_token" @user-selected="getManager" @input="getManager" />
                  </b-form-group>
                  <label>Team Department</label>
                    <SearchCompanyDepartment :api_token="this.$attrs.user.api_token" @department-selected="getDepartment" @input="getDepartment" />
                  <a href="javascript:;" class="btn btn-success" @click="addNewTeam">Save</a>
                </b-col>
              </b-row>
            </b-overlay>
                </div>
              </b-col>
      </b-row>            
    </SideSlidePanel>
    <SideSlidePanel :editPanel="updatePanel" :panelClass="'edit-sidepanel'" @close-panel="updatePanel = false">
      <b-row>
              <b-col md="12">
                <div class="m-4 px-2" v-if="updatePanel == true">
                <h4>Edit Team</h4>
                 <b-overlay
                :show="loadingForm"
                variant="white"
                        opacity="0.85"
                        blur="1px"
                        rounded="md"
              >
                  <b-form-group label="Team Name">
                    <b-form-input type="text" name="name" v-model="updateTeam.name" :state="updateTeam.name.length > 0"></b-form-input>
                  </b-form-group>
                  <b-form-group label="Description" :description="updateTeam.description.length+' of 200'">
                     <b-form-textarea name="name" v-model="updateTeam.description" rows="5" placeholder="Enter short company description..." :state="(updateTeam.description.length <= 200) && (updateTeam.description.length > 0)"></b-form-textarea>
                  </b-form-group>
                  <b-form-group label="Team Manager">
                    <SearchCompanyUser :api_token="this.$attrs.user.api_token" :id="updateTeam.manager.user" @user-selected="getManager" @input="getManager" />
                    <SearchCompanyDepartment :api_token="this.$attrs.user.api_token" :id="updateTeam.department" @department-selected="getDepartment" @input="getDepartment" />
                  </b-form-group>
                  <a href="javascript:;" class="btn btn-success" @click="updateExistingTeam">Save</a>
                </b-overlay>
                </div>
              </b-col>
      </b-row>            
    </SideSlidePanel>

    <SideSlidePanel :editPanel="staffPanel" :panelClass="'edit-sidepanel'" @close-panel="staffPanel = false, updateManagerBtn = false">
      <b-row>
              <b-col md="12">
                <div class="m-4 px-2 row" v-if="staffPanel == true">

                  <b-col md="12">
                <h4>Department Staff</h4>
                </b-col>
                 <b-col md="12">
                  <b-form-group label="Department Manager">
                    <SearchCompanyUser :api_token="this.$attrs.user.api_token" :id="updateTeam.manager.user" @user-selected="updateManagerBtn = true" @input="getManager" />
                     <a href="javascript:;" v-if="updateManagerBtn == true" class="btn btn-light btn-block my-auto float-right" @click="updateExistingDepartment">
                  <i class="mdi mdi-reload d-block font-size-16"></i>
                Update Manager</a>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <div class="d-flex"><p class="mr-auto my-auto">Department Members</p>
                  <a class="btn btn-primary btn-rounded mb-2 mr-2 text-white ml-auto" @click="userAddToDepartment = !userAddToDepartment" v-b-tooltip.hover title="Add Staff Member">
                    <i class="mdi mdi-plus noti-icon font-size-20"></i>
                  </a>
                  </div>
                  <SearchCompanyUsers v-if="userAddToDepartment == true" :resource="'team'" :id="updateTeam.id" :api_token="this.$attrs.user.api_token" />
                  <UserList :resource="'team'" :id="updateTeam.id" :api_token="this.$attrs.user.api_token" />
                </b-col>
                </div>
              </b-col>
      </b-row>            
    </SideSlidePanel>
  </Layout>
  <Layout v-else :nickname="$attrs.nickname" :user="$attrs.user" >
    <PageHeader :title="title" :items="items" />
    <Subscription :company="this.$attrs.company" :user="this.$attrs.user" />
  </Layout>
</template>