export const menuItems = [
    {
        id: 1,
        label: 'menuitems.website.about.text',
        link: 'about'
    },
    {
        id: 6,
        label: 'menuitems.website.jewellery.text',
        isUiElement: true,
        link: 'shop',
       /* subItems: [
            {
                id: 7,
                label: 'All Jewellery',
                link: '/category/jewellery',
                parentId: 6
            },
            {
                id: 8,
                label: 'Rings',
                link: '/category/rings',
                parentId: 6
            },
            {
                id: 9,
                label: 'Earrings',
                link: '/category/earrings',
                parentId: 6
            },
            {
                id: 10,
                label: 'Necklaces',
                link: '/category/necklaces',
                parentId: 6
            },
            {
                id: 11,
                label: 'Bracelets',
                link: '/category/bracelets',
                parentId: 6
            },
            {
                id: 12,
                label: 'Pendants',
                link: '/category/pendants',
                parentId: 6
            },
            {
                id: 13,
                label: 'Chains',
                link: '/category/chains',
                parentId: 6
            },
            {
                id: 14,
                label: 'Tiaras',
                link: '/category/tiaras',
                parentId: 6
            },
            {
                id: 15,
                label: 'Bangles',
                link: '/category/bangles',
                parentId: 6
            }
        ]*/
    },
    {
        id: 97,
        label: 'menuitems.website.categories.text',
        link: 'categories',
    }, 
    {
        id: 97,
        label: 'menuitems.website.collections.text',
        link: 'collections',
    },   
    {
        id: 97,
        label: 'menuitems.website.services.text',
        link: 'services',
    },
    {
        id: 97,
        label: 'menuitems.website.contact.text',
        link: '/contact'
    }
];

