<script type="text/javascript">
	import Layout from "../../layouts/main";
	import PageHeader from "../../components/page-header";
	import simplebar from "simplebar-vue";
	import CompanyProductSearch from '../../components/widgets/CompanyProductSearch';
	import CompanyProductList from '../../components/widgets/CompanyProductList';
	import { dragscroll } from 'vue-dragscroll';
	import DateTime from 'luxon/src/datetime.js';
	export default {
	components: {Layout, PageHeader, simplebar, CompanyProductSearch, CompanyProductList, DateTime },
	directives: {
    dragscroll
  },
	data(){
		return {
			success_message: false,
			success_text: 'Your action was successful',
			loadingForm: false,
			listingsUpdated: false,
			limits: [
            {value: 25, text: "25"},
            {value: 50, text: "50"},
            {value: 100, text: "100"},
          ],
			shopslimit: 25,
	      shopscurrentPage: 1,
	      shopsearch: '',
	      shop_type_id: '',
	      shopsortby: 'id',
	      shoporder: 'desc',
				view: 'basic-info',
				toplistingsdata: [],
				topsellersdata: [],
				product_orders: '',
			  newStaffMember: false,
			  readMore: false,
			  shopData: '',
				productslimit: '',
				productsortby: '',
				productsorder: '',
				productsearch: '',
			  shopProducts: '',
			  productsCurrentPage: 1,
			  storageurl: 'https://'+this.company.nickname+'.s3.eu-west-2.amazonaws.com',
			  hoverIndex: -1,
			  title: "Shop",
		      items: [
		        {
		          text: "Company Shops",
		          href: "/",
		        },
		        {
		          text: "Shop",
		          active: true,
		        },
		      ],
		    donutChart: {
            series: [0, 0, 0, 0, 0],
            chartOptions: {
                chart: {
                    type: "donut",
                    height: 240
                },
                labels: ["My Website", "Point of Sale", "Direct Input", "Public Market", "Trade Market"],
                colors: ["#556ee6", "#34c38f", "#f46a6a", "#f46a6a", "#f46a6a"],
                legend: {
                    position: 'bottom',
                    show:false
                },
                responsive: [{
                  breakpoint: 480,
                  options: {
                    chart: {
                      width: 200
                    },
                    legend: {
                      show: false
                    }
                  }
                }],
                plotOptions: {
                    pie: {
                        donut: {
                            size: "70%"
                        }
                    }
                }
            }
        },
      lineChart:{
            series: [
                {
                    name: "Earrnings",
                    type: 'line',
                    data: []
                },
                {
                    name: "OrderValue",
                    type: 'column',
                    data: []
                }
            ],
            chartOptions: {
                chart: {
                    height: 320,
                    type: "line",
                    toolbar: "false",
                    dropShadow: {
                        enabled: true,
                        color: "#000",
                        top: 18,
                        left: 7,
                        blur: 8,
                        opacity: 0.2
                    }
                },
                dataLabels: {
                    enabled: false
                },
                colors: ["#5E3BB0", "rgba(52, 195, 143, 0.8)"],

                stroke: {
                    curve: "smooth",
                    width: 3
                },
                xaxis: {
                    labels: {
                        show: false
                    },
                    categories: [],
                },
                yaxis: [{
                    title: {
                        text: "Total Earnings",
                        style: {
                          color: "#00E396"
                        }
                      },
                },
                {
                    seriesName: "OrderValue",
                    opposite: true,
                    title: {
                        text: "Order Value",
                        style: {
                          color: "#00E396"
                        }
                      },
                }
                ]
            }
        },
		     
		}
	},
	props: {
		id: {
			type: String,
			default: () => ''
		},
		user: {
			type: Object,
			default: () => ''
		},
		company: {
			type: Object,
			default: () => ''
		},
		product: {
			type: Object,
			default: () => ''
		},
		shopsData: {
			type: Object,
		},
		all_tax_rules: {
			type: Array
		},
		listing_types: {
			type: Array,
			default: () => []
		},
		active_tax_rule: {
			type: Number
		}

	},
	mounted() {
		//this.loadShopData();
		//this.loadShopProducts();
		this.getServiceBookings(this.product.id);
		
	},

	methods: {
		updateListings(){
			this.loadingForm = true;
		      	var postForm = new FormData(document.getElementById("updatelistingsform"));
		        var query = '/api/company/service/'+this.product.uid+'/update-listings';
		      
		      postForm.append('listings', JSON.stringify(this.product.listings));
		      axios.post(query, postForm, {headers : { 'Authorization' : 'Bearer '+this.user.api_token }}).then(response => {
		        console.log(response);
		        this.success_message = true;
		        this.success_text = 'Shop services updated';
		        //this.resetProjectData();
		     
		        
		        let self = this;
		          setTimeout(function(){
		            self.loadingForm = false;
		            self.image = '';
		            self.banner_image = '';
		          }, 500);
		      }).catch( error => {
		        
		        this.error_reponse = error.response.data.errors;
		        this.error_message = true;
		        //this.resetProjectData();
		        let self = this;
		          setTimeout(function(){
		            self.loadingForm = false;
		          }, 500);
		      });
		},
		getShops(key, data){
			this.$emit('update-listings', key, data);
		},
		
		calculateIncVat(){
                var activeObject = this.all_tax_rules.filter(item => item.id === this.active_tax_rule);
                
                if(this.company !== null){
                	return this.addTax(activeObject[0], this.product.price_exc);
            	}else{
            		return this.product.price_exc;
            	}
                //this.calcCostPrice();
                //this.calcProfitMargin();
                //return calculation.toFixed(2);
          },
		addTax(rule, price){
	            var tax_rule = rule;
	            console.log(tax_rule);
	            var country_id = this.company.country_id;
	       
	          if(tax_rule !== null && tax_rule.country_rules.length > 0){
	            var selected_rule = null;
	            var selected_ruleIndex = -1;
	            for(var ti = 0; ti < tax_rule.country_rules.length; ti++){
	              selected_ruleIndex = tax_rule.country_rules[ti].countries.findIndex(item => item == country_id);
	              if(selected_ruleIndex !== -1){
	                selected_rule = tax_rule.country_rules[ti];
	                ti = tax_rule.country_rules.length;
	              }
	            }
	            console.log(selected_rule);
	            if(tax_rule.type == 1){
	                if(selected_rule !== null){
	                  var percentage = (parseFloat(selected_rule.value) / 100) + 1;
	                  this.active_tax_percentage = percentage;
	                  return price * percentage;
	                }else{
	                  return price;
	                }
	              }else{
	                if(selected_rule !== null){
	                  this.active_tax_percentage = selected_rule.value;
	                  return price + selected_rule.value;
	                }else{
	                  return price;
	                }
	              }
	            }else if(tax_rule !== null && tax_rule.country_rules.length > 0){
	              var percentage = (parseFloat(tax_rule.percentage) / 100) + 1;
	              return price * percentage;
	            }else {
	              return price;
	            }
	    },
		listingsTotalQuantities(){
            var score = 0;
            for(var i = 0; i < this.product.listings.length; i++){
              score = parseFloat(score) + parseFloat(this.product.listings[i].quantity);
            }
            return score;
          },
		 updateHistory(hash){
            window.history.pushState({}, '', '#'+hash);
          },
          removeFromListing(id){
          	var index = this.product.listings.findIndex(item => item.listing_id == id);
           		if(index !== -1){
            		this.product.listings.splice(index, 1);
           		}
          },
		quantityInListing(id){
           var index = this.product.listings.findIndex(item => item.listing_id == id);
           if(index !== -1){
            return true;
           }else{
            return false;
           }
          },
          listingQuantity(value){
            return this.product.listings.findIndex(item => item.listing_id == value);
            //return items[0];
          },
          getServiceBookings(id){
          axios.get('/api/company/service/'+id+'/bookings?page='+this.productsCurrentPage+'&limit='+this.productslimit+'&sortby='+this.productsortby+'&order='+this.productsorder+'&search='+this.productsearch, { headers : {
				'Authorization': 'Bearer ' + this.user.api_token
			} }).then( response => {
            this.product_orders = response.data;
          }).catch(error => {
            //this.error_message = true;
          });
        },
		loadShopData(){
			axios.get('/api/company/listing/'+this.id, { headers : {
				'Authorization': 'Bearer ' + this.user.api_token
			} }).then(response => {
				this.shopData = response.data;
			}).catch(error => {
				this.error_reponse = error;
			});
		},
		loadShopProducts(){
			axios.get('/api/company/listing/'+this.id+'/products', { headers : {
				'Authorization': 'Bearer ' + this.user.api_token
			} }).then(response => {
				this.shopProducts = response.data;
			}).catch(error => {
				this.error_reponse = error;
			});
		},
		getDate(date){
        var createdOn = DateTime.fromISO(date, { zone: 'utc'});
        console.log(createdOn);
        return createdOn.day+' '+createdOn.monthLong+' '+createdOn.weekYear+'';
      },
	}
}
</script>

<template>
	<div>
		<b-row>
			<b-col md="12">
                    <transition name="slide-success" mode="out-in">
                  <b-alert id="success-message" variant="success" :show="success_message"  dismissible @dismissed="success_message = !success_message">
                      <p class="font-size-14">{{success_text}}</p>
                    </b-alert>
                  </transition>
            </b-col>
			 <b-col md="12">
                    <transition name="slide-error" mode="out-in">
                    <b-alert id="errors" variant="danger" :show="error_message"  dismissible @dismissed="error_message = !error_message">
                      <p class="font-size-14">Oops! Please correct the following errors in your submission</p>
                      <ul>
                        <li v-for="(error, key) in error_reponse">
                          {{error[0]}}
                        </li>
                      </ul>
                    </b-alert>
                    
                  </transition>
            </b-col>
			<b-col md="2">
				
		        
        <div class="product-nav">
        <b-card no-body class="py-2">
            <b-list-group flush class="pl-3">
              <b-list-group-item 
               @click="view = 'basic-info', updateHistory(view)"
               class="nav-tab d-flex"
               :class="{'active' : view == ('basic-info' || '')}">
                <i :class="{'text-primary' : view !== 'basic-info', 'text-white' : view == 'basic-info'}" class="my-auto font-size-16 mr-1 bx bx-info-circle"></i> Basic info
              </b-list-group-item>
            
              <b-list-group-item 
               @click="view = 'listings', updateHistory(view)"
               class="nav-tab d-flex"
               :class="{'active' : view == 'listings'}">
               <i :class="{'text-primary' : view !== 'listings', 'text-white' : view == 'listings'}" class="my-auto font-size-16 mr-1 bx bxs-store"></i> Locations
              </b-list-group-item>
             
              <b-list-group-item 
               @click="view = 'bookings', updateHistory(view)"
               class="nav-tab d-flex"
               :class="{'active' : view == 'bookings'}">
               <i :class="{'text-primary' : view !== 'bookings', 'text-white' : view == 'bookings'}" class="my-auto font-size-16 mr-1 bx bx-list-plus"></i> Bookings
              </b-list-group-item>
              <!--<b-list-group-item 
               @click="view = 'shipping', updateHistory(view)"
               class="nav-tab d-flex"
               :class="{'active' : view == 'shipping'}">
               <i :class="{'text-primary' : view !== 'shipping', 'text-white' : view == 'shipping'}" class="my-auto font-size-16 mr-1 bx bxs-truck"></i>  Shipping
              </b-list-group-item>
              -->
              <b-list-group-item 
               @click="view = 'reviews', updateHistory(view)"
               class="nav-tab d-flex"
               :class="{'active' : view == 'reviews'}">
                <i :class="{'text-primary' : view !== 'reviews', 'text-white' : view == 'reviews'}" class="my-auto font-size-16 mr-1 bx bx-globe"></i> Reviews
              </b-list-group-item>
              <b-list-group-item 
               @click="view = 'sales-stats', updateHistory(view)"
               class="nav-tab d-flex"
               :class="{'active' : view == 'sales-stats'}">
               <i :class="{'text-primary' : view !== 'sales-stats', 'text-white' : view == 'sales-stats'}" class="my-auto font-size-16 mr-1 bx bx-wrench"></i> Sales Stats
              </b-list-group-item>
              
              <!--<b-list-group-item>
                Appraise
              </b-list-group-item>-->
            </b-list-group>
           
          </b-card>
          
             <b-button variant="light"  @click="$emit('close-details')" class="btn btn-block btn-rounded btn-success mt-2">
              <i class="bx bx-pencil"></i> Edit Service

            </b-button>
           
          </div>
      </b-col>

    <b-col md="10">
				
					<b-row v-if="view == 'basic-info'">
                    	<b-col md="12">
                    		<b-row>
                    		<b-col md="3">
                    			 		
		    </b-col>
		    <b-col md="12">
		    	<b-row>
		    		<b-col md="4">
<b-card class="card overflow-hidden border-radius-1em shadow-sm seller-profile-card" no-body >
          <div class="bg-white">
            <div class="row">
            	<transition name="fade-left">
		                    	<div class="col-md-12 position-absolute z-index-2"> 
                              	<a id="tooltip-add"  :href="'/seller/'+company.nickname+'/profile'" class="mt-3 ml-3 btn btn-primary font-size-16 btn-rounded ml-auto float-left"><i class="mdi mdi-eye-outline"></i></a>
                              </div>
                          </transition>
                           
                       <div class="col-12">

               <img
		                  	v-if="product.image !== null" :src="storageurl + product.image.replaceAll('//', '/')"
		                    alt
		                    class="img-fluid"
		                  />
		                   <img
		                  	v-else
		                    src="/images/product-placeholder.png"
		                    alt
		                    class="img-fluid"
		                  />
                 <transition name="fade-right">
		                        <div class="mt-auto mb-3 mx-2 d-flex position-absolute bottom-0 right-0 left-0">
                              	<p  class="text-muted ml-auto my-auto mr-3">
			                    <span class="bx bx-star font-size-14 text-warning"></span>
			                    <span class="bx bx-star font-size-14 text-warning ml-1"></span>
			                    <span class="bx bx-star font-size-14 text-warning ml-1"></span>
			                    <span class="bx bx-star font-size-14 text-warning ml-1"></span>
			                    <span class="bx bx-star font-size-14 ml-1"></span>
			                  </p>
              					</div>
              				</transition>
              </div>
            </div>
          </div>
				<!--<b-card-body class="card-body pt-0">
		            <div class="row pt-2">
		              
		              <div class="col-md-6 d-flex">
		              	<span class="badge badge-light border-radius-1em bg-light text-dark font-size-11 lh-16 my-auto p-2">
		              	<i class="bx bx-hash"></i>{{product.reference}} 
		              	</span>
		              	
		              	
		              
		              </div>
		              

		            </div>
		          </b-card-body>-->
		        </b-card>
		    		</b-col>
		    		<b-col md="8">
					<b-card class="border-radius-1em shadow-sm">

						<div class="d-flex p-3 flex-column">
							
							<div>
							<h2 class="w-100"><span class="text-primary">#</span>{{product.reference}}: {{product.name}}</h2>
							<hr>
							<h6>Summary</h6>
							<p>{{product.short_description}}</p>

							<h6>Description</h6>
							<p v-html="product.long_description"></p>
							</div>
							
						</div>
					</b-card>
				</b-col>
				</b-row>
					<b-row>
				<b-col md="12">
					<b-card class="border-radius-1em shadow-sm">
						 <h5 class="d-flex">Service Category <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>

						 <b-card class="border-radius-1em border shadow-none" body-class="d-flex" v-if="product.main_category !== null">
						 	<div>
						 		<img v-if="product.main_category.cover_image !== null" class="avatar-sm rounded-circle" :src="storageurl + product.main_category.cover_image">
						 		<img v-else class="avatar-sm rounded-circle" :src="'/images/product-placeholder.png'">
						 	</div>

						 	<div class="d-flex flex-column">
						 		<h6 class="my-auto ml-2">{{product.main_category.name}}</h6>
						 	</div>
						 </b-card>
					</b-card>
				</b-col>
				<b-col md="12">
				
					 <b-card class="border-radius-1em shadow-sm">
		        	<b-row>
		        		 <div class="col-md-6 d-flex flex-column">
		               	 <h5 class="d-flex">Sold On <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
		               	<div class="d-flex pt-3 pb-1">
		              		<div v-if="product.trade_active == 1">
                       <span class="lh-16 my-auto mr-2 p-2 font-size-11 badge badge-light bg-light text-dark"><i class="bx bx-transfer-alt text-primary"></i> Trade</span>
                      </div>
                      <div v-if="product.public_active == 1">
                        <span class="lh-16 my-auto mr-2 p-2 font-size-11  badge badge-light bg-light text-dark"><i class="bx bxs-user text-primary"></i> Public</span>
                      </div>
                       <div v-if="product.active == 1">
                        <span class="lh-16 my-auto mr-2 p-2 font-size-11  badge badge-light bg-light text-dark"><i class="bx bxs-store text-dark"></i> My Shops</span>
                      </div>
		              </div>
		          </div>
		              <div class="col-md-6 d-flex flex-column">
		              	 <h5 class="d-flex">Service Type <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
		               	<div class="d-flex pt-3 pb-1">
		              	<span v-if="product.allow_bookings == 1" class="badge badge-light border-radius-1em bg-light text-dark font-size-11 lh-16 my-auto p-2 mr-2">
		              		Bookings Enabled <i class="bx bx-calendar"></i>
		              	</span>
		              	<span v-if="product.set_duration == 1" class="badge badge-light border-radius-1em bg-light text-dark font-size-11 lh-16 my-auto p-2 mr-auto">
		              		Has Set Duration <i class="bx bx-list-plus"></i>
		              	</span>
		              </div>
		          	</div>
		        	</b-row>
		        </b-card>
		    </b-col>
		    
		   <b-col md="12">
          		<b-card class="border border-radius-1em shadow-none" v-if="product.set_duration == (true || 1)">
						<h5 class="d-flex mb-2 mt-3 w-100">Set Duration <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>

						<p>Each service booking lasts <span class="badge badge-light bg-light text-dark font-size-12 lh-16 my-auto border-radius-1em p-2"><i class="bx bx-timer text-primary"></i> {{product.duration}} {{product.duration_units}}</span></p>
					</b-card>
		</b-col>
          <b-col md="12">
          		<b-card class="border border-radius-1em shadow-none" v-if="product.allow_bookings == (true || 1)">
						<h5 class="d-flex mb-2 mt-3">Booking Days and Times <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
          	<b-form-group v-for="day, index in product.booking_days">

          		<b-row class="mb-3">
          			<b-col md="2" class="d-flex align-items-center">
          				<h6 class="mb-0">{{day}}</h6> <i class="ml-auto bx bx-right-arrow-circle text-primary font-size-20"></i>
          			</b-col>
          			<b-col md="4">
          				<b-form-group
		                  
		                    id="example-time"
		                    label-cols-sm="2"
		                    label-cols-lg="2"
		                    label="Start"
		                    label-for="start"
		                    
		                  >
		                    <b-form-input
		                      id="time"
		                      value="09:00:00"
		                      type="time"
		                      disabled
		                      v-if="product.booking_days.includes(day)"
		                      v-model="product.booking_times[product.booking_days.findIndex(item => item == day)][0]"
		                    ></b-form-input>
		                     <b-form-input
		                      id="time"
		                      value="09:00:00"
		                      type="time"
		                      v-else
		                      disabled
		                    ></b-form-input>
		                  </b-form-group>
          			</b-col>
          			<b-col md="4">
          				<b-form-group
		                  
		                    id="example-time"
		                    label-cols-sm="2"
		                    label-cols-lg="2"
		                    label="End"
		                    label-for="start"
		                    
		                  >
		                    <b-form-input
		                      id="time"
		                      value="17:00:00"
		                      type="time"
		                      disabled
		                      v-if="product.booking_days.includes(day)"
		                      v-model="product.booking_times[product.booking_days.findIndex(item => item == day)][1]"
		                    ></b-form-input>
		                     <b-form-input
		                      id="time"
		                      value="09:00:00"
		                      type="time"
		                      v-else
		                      disabled
		                    ></b-form-input>
		                  </b-form-group>
          			</b-col>
          			<b-col md="2">
          				
                      <a class="btn btn-light btn-rounded" 
                        
                          v-if="!product.booking_days.includes(day)"
                          @click="setDayActive(day, 1, index)"
                   			
                        >
                         Make Active
                   		</a>
                   		<a class="btn btn-light btn-rounded" 
                        
                          v-else
                       
                   		v-b-tooltip.left
                   		
                        >
                         Active <i class="bx bx-check-circle text-success"></i>
                   		</a>	
          			</b-col>
          		</b-row>
          	</b-form-group>
      	  </b-card>
          </b-col>
          <b-col md="12">
		        <b-card class="border-radius-1em shadow-sm">
		        	<b-row>
	              <b-col md="12">
	                <h5 class="d-flex">Pricing <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
	              </b-col>
	              <b-col md="6">
	              	
	              	<h3 class="text-primary mb-0 d-inline-block"><span>£</span>{{calculateIncVat().toFixed(2) }} <small>Inc.</small></h3>
	              	<p class="text-muted mb-1 d-inline-block font-size-14"><span>£</span>{{parseFloat(product.price_exc).toFixed(2)}} <small>Exc.</small></p>
	                <small class="d-block">Public Price</small>
	                
	                
	               
	             
	              </b-col>
	               <b-col md="6">
	               	 <h3 class="text-primary mt-auto mb-1"><span>£</span>{{product.trade_price}}</h3>
	                <small>Trade Price <small>Exc.</small></small>
	               </b-col>
            	</b-row>
          	</b-card>
          </b-col>
      </b-row>
				</b-col>
			</b-row>
			

					
					<!--<b-card class="border-radius-1em shadow-sm mt-3">
						<div v-dragscroll class="w-100 d-flex img-scrollbox">
								<img v-if="product.image !== null" :src="storageurl + product.image.src.replaceAll('//', '/')" class="avatar-lg">
								<img v-for="image in product.images" :src="storageurl + image.src.replaceAll('//', '/')" class="avatar-lg">
							</div>
					</b-card>-->
			
				</b-col>
					
				
				</b-row>
					<b-row v-if="view == 'listings'">
					<b-col md="12">
					<b-card class="border-radius-1em shadow-sm">
						<b-row>
              <b-col md="2">
                <h5 class="d-flex">Shop Services <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
                <h3 class="text-primary mt-4">{{shopsData.data.length}}</h3>
                <small>Total Listings</small>
                <hr>
                <h3 class="text-primary mt-4">
                	<span v-if="product.listings.length > 0">{{product.listings.length}}</span>
                	<span v-else>
                		0
                	</span>
            	</h3>
                <small>Shop(s) Offer this Service</small>
                <hr>
                <b-button v-if="listingsUpdated == true" variant="success" class="btn-rounded" @click="updateListings()">
                	Update Listings <i class="bx bx-save"></i>
                </b-button>
              </b-col>
              <b-col md="10">
                <b-card class="border shadow-none border-radius-1em">
                  <div class="mt-sm-0 form-inline w-100" >
                  	 <b-form id="updatelistingsform" method="POST" enctype="multipart/form-data">
                   		<input type="hidden" name="_token" :value="csrf">
               		</b-form>
                  <div class="search-box mr-2 ml-auto">
                    <label>Search Shops</label>
                    <div class="position-relative d-flex">
                      <input
                        type="text"
                        class="form-control w-75 border-0 bg-light"
                        placeholder="Search name, location or description..."
                        v-model="shopsearch" @keydown.enter="getShops('shopsearch', shopsearch)" 
                      />
                      <i class="bx bx-search-alt search-icon"></i>
                      
                      <b-form-select class="form-control border btn-rounded w-unset ml-2 bg-light px-3 text-uppercase" v-model="shop_type_id" @input="getShops('shop_type_id', shop_type_id)">
                        <b-form-select-option value="" >-- Listing Type --</b-form-select-option>
                        <b-form-select-option v-for="(listing_type, key) in listing_types" :value="listing_type.id" class="text-capitalize">{{listing_type.name}}</b-form-select-option>
                      </b-form-select>

                      <b-form-select class="form-control border btn-rounded w-unset ml-2 bg-light px-3 text-uppercase" v-model="shopsortby" @input="getShops()">
                        <b-form-select-option value="id" >-- Sort By --</b-form-select-option>
                        <b-form-select-option v-if="shopsData.data.length > 0" v-for="(listing, key) in shopsData.data[0]" :value="key" class="text-uppercase">{{(key).replace('_', ' ')}}</b-form-select-option>
                      </b-form-select>
                      
                      <b-form-select class="form-control border btn-rounded w-unset ml-2 bg-light px-3" v-model="shopslimit" :options="limits" @input="getShops()"></b-form-select>
                    </div>
                  </div>
                </div>
                </b-card>
                <b-overlay :show="loadingForm">
                <div v-dragscroll class="widget-scrollbox" 
                            style="height: auto; max-height: 800px;"
                            v-if="'listings' in product"
                        >
                            <li class="list-group-item" v-for="(listing, index) in shopsData.data">
                                <div class="media">
                                	<div v-if="quantityInListing(listing.id)">
                                	<div class="avatar-sm me-3">
                                        <span class="avatar-title bg-success rounded-circle" ><i class="text-white bx bx-check font-size-20"></i></span>
                                      </div>
                                	</div>
                                    <div class="avatar-sm me-3" v-else-if="listing.logo">
                                        <span
                                            class="avatar-title rounded-circle bg-light"
                                        >
                                            <!--<img
                                                
                                                :src="listing.logo"
                                                alt=""
                                                height="18"
                                            />-->
                                            
                                        </span>
                                      </div>
                                       <div class="avatar-sm me-3" v-else>
                                        <span class="avatar-title rounded-circle" >{{(listing.name).slice(0,1)}}</span>
                                      </div>
                                   
                                    <div class="media-body my-auto">
                                        <a :href="'/company/shop/'+listing.uid" target="_blank"><h5 class="font-size-14 mb-0 mt-auto">
                                            {{listing.name}}
                                        </h5></a>
                                        <p class="text-muted mb-auto mt-0">
                                            <span v-if="listing.address_1">{{listing.address_1}},</span>
                    <span v-if="listing.address_2">{{listing.address_2}},</span>
                    <span v-if="listing.address_3">{{listing.address_3}},</span>
                    <span v-if="listing.county">{{listing.county.name}},</span>
                    <span v-if="listing.country">{{listing.country.name}},</span>
                    <span v-if="listing.postcode">{{listing.postcode}}</span>
                                        </p>

                                        
                                    </div>
                                    <div class="my-auto text-center">
                                            <p class="text-muted my-2" v-if="listing.listing_type">
                                                <i 
                                                    class="bx bx-buildings me-1"
                                                ></i>
                                                {{listing.listing_type.name}}
                                            </p>
                                        </div>
                                        <div class="my-auto px-4 w-25">
                                          <a class="btn btn-light btn-rounded btn-block" v-if="!quantityInListing(listing.id)" @click="product.listings.push({listing_id: listing.id, quantity: 1, variant_id: null}), listingsUpdated = true" >
                                            Add To This Shop
                                          </a>
                                          <div label="Quantity" v-if="quantityInListing(listing.id)" class="d-flex">
                                             <a class="btn btn-light btn-rounded" @click="removeFromListing(listing.id), listingsUpdated = true">
                                             	Remove Service
                                            	<i class="bx bx-x-circle text-danger"></i>
                                            </a>
                                          </div>
                                        </div>
                                </div>
                            </li>
                        </div>
                    </b-overlay>
                        <b-pagination
                          class="justify-content-center"
                          pills
                          v-model="currentPage"
                          :total-rows="shopsData.total"
                          :per-page="shopsData.per_page"
                          aria-controls="my-table"
                          @input="getShops()"
                        >

                        </b-pagination>
              </b-col>
            </b-row>
					</b-card>
				</b-col>
					</b-row>
					  
					<b-row v-if="view == 'bookings'">
						<b-col md="12">
                          <b-card class="border-radius-1em shadow-sm">
             
          
		
             <b-row>
             	<b-col md="2">
                <h5 class="d-flex">Service Bookings <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
                <h3 class="text-primary mt-4">{{product_orders.total}}</h3>
                <small>Total Bookings</small>
                <hr>
                <!--
                <h3 class="text-primary mt-4" v-if="product.listings.length > 0">{{listingsTotalQuantities}}</h3>
                <small>Quantity Assigned to Shops</small>
                <hr>
              	-->
              </b-col>
              <b-col md="10">
             <b-card class="shadow-none border-radius-1em border" v-if="product_orders.data.length == 0">
             	<div style="height: 300px;" class="w-100 d-flex justify-content-center align-items-center">
             		<div class="mx-auto my-auto text-center">
             			<i class="bx bx-shopping-bag font-size-42 text-muted d-block mx-auto pb-2"></i>
             			<span class="text-muted">
             				No Orders Listed
             			</span>
             		</div>
             	</div>
             </b-card>
             	<b-card class="shadow-none border-radius-1em border" v-else>
             <div class="table-responsive">
              <table class="table table-centered table-hover">

                <!--<thead class="thead-light">
                  <tr>
                    
                    <th scope="col"><a :class="{'text-dark': sortby != 'order_no'}" href="javascript: void(0);" @click="orderDesc = !orderDesc,  sortby = 'order_no', getServiceBookings(product.id)">Order No. <i v-if="orderDesc == true" class="bx bx-caret-up"></i><i v-if="orderDesc == false" class="bx bx-caret-down"></i></a></th>
                    <th scope="col">Customer</th>
                    <th scope="col">Source</th>
                    <th scope="col">Date</th>
                    <th scope="col">Total Exc.</th>
                    <th scope="col">Total Inc.</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>-->
               
                <tbody class="widget-scrollbox" v-dragscroll  
                            style="max-height: 600px; display: table-caption; height: auto;">
                  
                  <tr v-for="list in product_orders.data" :key="list.id" valign="middle">
                    
                    <td>
                      <a :href="'/order/'+list.uid+''"><span>#{{list.reference}}</span></a>
                    </td>
                   
                    
                    <td>
                      <div v-if="list.trade_orders.length > 0">
                        <a :href="'/seller/profile/'+list.trade_orders[0].order.customer.nickname+''"><h6 class="mb-0">{{list.trade_orders[0].order.customer.name}}</h6>
                        {{list.trade_orders[0].order.customer.email}}</a>
                      </div>
                      <div v-if="list.customer_orders.length > 0">
                         <a :href="'/customer/details/'+list.customer_orders[0].order.customer.id+''"><h6 class="mb-0">{{list.customer_orders[0].order.customer.name}} {{list.customer_orders[0].order.customer.lastname}}</h6>
                        {{list.customer_orders[0].order.customer.email}}</a>
                      </div>
                      
                    </td>
                     <td>
                       <div v-if="list.trade_orders.length > 0">
                       <span class="font-size-12 badge badge-light bg-light text-dark"><i class="bx bx-transfer-alt text-primary"></i> Trade Marketplace</span>
                      </div>
                      <div v-if="list.customer_orders.length > 0">
                        <span class="font-size-12 badge border border-primary text-primary"><i class="bx bxs-user text-primary"></i> Public Marketplace</span>
                      </div>
                    </td>
                     <td>
                     <i class="bx bx-calendar"></i> {{getDate(list.created_at)}}
                    </td>
                     <td>
                      {{list.total_products_exc}}
                    </td>
                    <td>
                      {{list.total_products_inc}}
                    </td>
                    
                    <!--<td>
                      <ul class="list-inline font-size-20 contact-links mb-0">
                        <li class="list-inline-item"><a v-b-tooltip.hover title="Edit" placement="left" class="btn btn-outline-light btn-rounded"><i class="bx bx-edit-alt"></i>
                          </a>
                        </li>
                      <li class="list-inline-item">
                        <a href="javascript:;" v-b-tooltip.hover title="View" placement="left" @click="selectedListing = list, getProducts(list.id), getProductsPanel = 1" class="btn btn-outline-light btn-rounded"><i class="mdi mdi-eye-outline"></i></a>
                      </li>
                        <li class="list-inline-item">
                           <b-dropdown v-b-tooltip.hover title="More Options" placement="left" dropleft class="more-options-dropdown btn btn-outline-light btn-rounded">
                        <template v-slot:button-content>
                           <i class="bx bx-dots-horizontal-rounded"></i>
                        </template>
                        <b-dropdown-item>Action</b-dropdown-item>
                        <b-dropdown-item>Another Action</b-dropdown-item>
                        <b-dropdown-item>Something else here</b-dropdown-item>
                      </b-dropdown>
                        </li>
                      </ul>
                    </td>-->
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <b-pagination
              v-if="product_orders.last_page > 1"
              class="justify-content-center"
              pills
              v-model="productsCurrentPage"
              :total-rows="product_orders.total"
              :per-page="product_orders.per_page"
              aria-controls="my-table"
              @input="getServiceBookings(product.id)"
            ></b-pagination>
             <small class="mx-auto my-2 text-center d-block">Records {{product_orders.from}} - {{product_orders.to}} of {{product_orders.total}} // Page {{product_orders.current_page}} of {{product_orders.last_page}}</small>
              </div>
            </div>
          </b-card>
          </b-col>
        </b-row>
                          </b-card>
                        </b-col>
					</b-row>
					<b-row v-if="view == 'reviews'">
						<b-col md="12">
                            <b-card class="border-radius-1em shadow-sm">
                            	<b-row>
              <b-col md="2">
                <h5 class="d-flex">Service Reviews <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
                <h3 class="text-primary mt-4">0.0 /	<small class="font-size-15 text-dark">5</small></h3>
                <small>Avg. Service Review</small>
                <hr>
              
              </b-col>
              <b-col md="10">
              	 <b-card class="shadow-none border-radius-1em border" v-if="'reviews' in product && product.reviews.length == 0">
             	<div style="height: 300px;" class="w-100 d-flex justify-content-center align-items-center">
             		<div class="mx-auto my-auto text-center">
             			<i class="bx bx-shopping-bag font-size-42 text-muted d-block mx-auto pb-2"></i>
             			<span class="text-muted">
             				No Orders Listed
             			</span>
             		</div>
             	</div>
             </b-card>
             <b-card class="shadow-none border-radius-1em border" v-else>

             </b-card>
              </b-col>
            </b-row>
                          </b-card>
                        </b-col>
					</b-row>
					 <b-row v-if="view == 'sales-stats'" class="mb-3">
					<b-col>
						  <b-card class="border-radius-1em shadow-sm mb-0">
				        	<div class="d-flex align-items-center mb-3"><div class="avatar-xs mr-3"><span class="avatar-title rounded-circle bg-soft-primary text-white font-size-18"><i class="bx bx-copy-alt"></i></span></div> <h5 class="font-size-14 mb-0">Bookings</h5></div> <div class="text-muted mt-4"><h4>{{product_orders.total}} <i class="mdi mdi-chevron-up ml-1 text-success"></i></h4> <div class="d-flex"><span class="badge badge-soft-success font-size-12"> + 0.2% </span> <span class="ml-2 text-truncate">From previous period</span></div></div>
				        	
				        </b-card>
				    </b-col>
				    <b-col>
						  <b-card class="border-radius-1em shadow-sm mb-0">
				        	<div class="d-flex align-items-center mb-3"><div class="avatar-xs mr-3"><span class="avatar-title rounded-circle bg-soft-primary text-white font-size-18"><i class="bx bx-pound"></i></span></div> <h5 class="font-size-14 mb-0">Revenue</h5></div> <div class="text-muted mt-4"><h4>1,452 <i class="mdi mdi-chevron-up ml-1 text-success"></i></h4> <div class="d-flex"><span class="badge badge-soft-success font-size-12"> + 0.2% </span> <span class="ml-2 text-truncate">From previous period</span></div></div>
				        </b-card>
				    </b-col>
				    <b-col>
						  <b-card class="border-radius-1em shadow-sm mb-0">
				        	<div class="d-flex align-items-center mb-3"><div class="avatar-xs mr-3"><span class="avatar-title rounded-circle bg-soft-primary text-white font-size-18"><i class="bx bx-purchase-tag-alt"></i></span></div> <h5 class="font-size-14 mb-0">Avg. Order Price</h5></div> <div class="text-muted mt-4"><h4>1,452 <i class="mdi mdi-chevron-up ml-1 text-success"></i></h4> <div class="d-flex"><span class="badge badge-soft-success font-size-12"> + 0.2% </span> <span class="ml-2 text-truncate">From previous period</span></div></div>
				        </b-card>
				    </b-col>
				</b-row>
					<b-row v-if="view == 'sales-stats'">
						<b-col md="12">
							<b-row>
							<b-col md="8">
							<div class="card border-radius-1em">
                <div class="card-body">
                    <div class="clearfix">
                        <div class="float-right">
                            <div class="input-group input-group-sm">
                                <b-form-input type="date" v-model="ordersstartdate">
                                </b-form-input>
                
                                <b-form-input type="date" v-model="ordersenddate">
                                </b-form-input>
                                <a class="btn btn-light btn-rounded" @click="getOrderData()">
                                    Update
                                </a>
                            </div>
                        </div>
                        <h4 class="card-title mb-4">Earning</h4>
                    </div>

                    <div class="row">
                        <div class="col-lg-4">
                            <div class="text-muted">
                                <div class="mb-4">
                                    <p>From {{ordersstartdate | luxon}} to {{ordersenddate | luxon}}</p>
                                    <h4  v-if="(orderDataLoading == false) && (lineChart.series[0].data.length > 0)">£{{(lineChart.series[0].data[lineChart.series[0].data.length - 1]).toFixed(2).toString().replace(/B(?=(d{3})+(?!d))/g, ",")}}</h4>
                                    <h4 v-if="(orderDataLoading == false) && (lineChart.series[0].data.length == 0)">£0.00</h4>
                                    <div v-if="orderDataLoading == false"><span class="badge font-size-12 mr-1" :class="{'badge-soft-success' : (((parseFloat(lineChart.series[0].data[lineChart.series[0].data.length - 1]) - parseFloat(orderprevioustotal)) / parseFloat(orderprevioustotal)) * parseFloat(100)).toFixed(2) > 0, 'badge-soft-danger': (((parseFloat(lineChart.series[0].data[lineChart.series[0].data.length - 1]) - parseFloat(orderprevioustotal)) / parseFloat(orderprevioustotal)) * parseFloat(100)).toFixed(2) < 0}"> 
                                    <span v-if="(((parseFloat(lineChart.series[0].data[lineChart.series[0].data.length - 1]) - parseFloat(orderprevioustotal)) / parseFloat(orderprevioustotal)) * parseFloat(100)).toFixed(2) > 0">+</span>{{(((parseFloat(lineChart.series[0].data[lineChart.series[0].data.length - 1]) - parseFloat(orderprevioustotal)) / parseFloat(orderprevioustotal)) * parseFloat(100)).toFixed(2) }} %</span> From previous period</div>
                                     <b-spinner v-else variant="primary" type="grow">
                                     </b-spinner>
                                </div>

                                

                                <div class="mt-4">
                                    <p class="mb-2">Previous Period</p>
                                    <h5 v-if="orderDataLoading == false">£{{orderprevioustotal.toFixed(2)}}</h5>
                                    <b-spinner v-else variant="primary" type="grow">
                                     </b-spinner>
                                </div>

                            </div>
                        </div>

                        <div class="col-lg-8">
                            <apexchart v-if="orderDataLoading == false" class="apex-charts" :options="lineChart.chartOptions" :series="lineChart.series" dir="ltr" height="460"/>
                            <b-spinner v-else variant="primary" type="grow">
                            </b-spinner>
                        </div>
                    </div>
                </div>
            </div>
          </b-col>
            <div class="col-xl-4">
            <div class="card border-radius-1em">
                <div class="card-body">
                    <h4 class="card-title mb-4">Sales Analytics</h4>

                    <div>
                        <apexchart class="apex-charts" :options="donutChart.chartOptions" :series="donutChart.series" dir="ltr" height="300" />
                    </div>

                    <div class="text-center text-muted">
                        <div class="row">
                            <div class="col" v-for="(label, index) in donutChart.chartOptions.labels">
                                <div class="mt-4">
                                    <p class="mb-2 text-truncate"><i class="mdi mdi-circle mr-1" :style="{'color': donutChart.chartOptions.colors[index] }"></i> {{label}}</p>
                                    <h5>£ {{(donutChart.series[index]).toFixed(2)}}</h5>
                                </div>
                            </div>
                            <!--<div class="col">
                                <div class="mt-4">
                                    <p class="mb-2 text-truncate"><i class="mdi mdi-circle text-success mr-1"></i> Product B</p>
                                    <h5>$ 1,763</h5>
                                </div>
                            </div>
                            <div class="col">
                                <div class="mt-4">
                                    <p class="mb-2 text-truncate"><i class="mdi mdi-circle text-danger mr-1"></i> Product C</p>
                                    <h5>$ 973</h5>
                                </div>
                            </div>-->
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </b-row>
              <b-row>
              	
        <div class="col-xl-6">
            <div class="card border-radius-1em">
                <div class="card-body">
                    <div class="clearfix">
                        <div class="float-right">
                            <div class="input-group input-group-sm">
                                <select class="custom-select custom-select-sm">
                                    <option selected>Jan</option>
                                    <option value="1">Dec</option>
                                    <option value="2">Nov</option>
                                    <option value="3">Oct</option>
                                </select>
                                <div class="input-group-append">
                                    <label class="input-group-text">Month</label>
                                </div>
                            </div>
                        </div>
                        <h4 class="card-title mb-4">Top Salespeople</h4>
                    </div>

                    <div class="text-muted text-center" v-if="topsellersdata.length > 0">
                        <img v-if="topsellersdata[0].user.avatar !== null" :src="storageurl+topsellersdata[0].user.avatar" class="avatar-md rounded-circle mx-auto">
                        <img v-else src="/images/product-placeholder.png" class="avatar-md rounded-circle mx-auto">
                        <p class="mb-2">{{topsellersdata[0].user.name}} {{topsellersdata[0].user.lastname}}</p>
                        <h4>£{{(topsellersdata[0].sales_sum_total_order_inc).toFixed(2)}}</h4>
                        <p class="mt-4 mb-0"><span class="badge badge-soft-success font-size-11 mr-2"> 0.6% <i class="mdi mdi-arrow-up"></i> </span> From previous period</p>
                    </div>
                    <b-card class="border-radius-1em border shadow-none mb-0" v-else style="min-height: 450px;" body-class="d-flex flex-column justify-content-center align-items-center">
                        <i class="bx bx-user-circle font-size-42 text-muted d-block"></i>
                        <h5 class="text-muted">No Top Sellers</h5>
                        <p>Start selling directly or via your Point of Sale now!</p>
                    </b-card>
                     <simplebar  v-if="topsellersdata.length > 0" style="height: 285px; max-height: 285px">
                    <div class="table-responsive mt-4 mb-0">
                        <table class="table table-centered mb-0">
                            <tbody>
                                <tr v-for="product in topsellersdata">
                                    <td>
                                        <h5 class="font-size-14 mb-1">{{product.user.name}} {{product.user.lastname}}</h5>
                                        <p class="text-muted mb-0 text-truncate"></p>
                                    </td>

                                    <td>
                                        <apexchart class="apex-charts" :options="ChartData.RadialChart1.chartOptions" :height="60" :width="60" :series="ChartData.RadialChart1.series" dir="ltr" />
                                    </td>
                                    <td>
                                        <p class="text-muted mb-1">Sold</p>
                                        <h5 class="mb-0">{{product.orders_sum_quantity}}</h5>
                                    </td>
                                    <td>
                                        <p class="text-muted mb-1">Sales Value</p>
                                        <h5 class="mb-0">£{{(product.sales_sum_total_order_inc).toFixed(2)}}</h5>
                                    </td>
                                </tr>
                               
                            </tbody>
                        </table>
                    </div>
                </simplebar>
                </div>
            </div>
        </div>

        <div class="col-xl-6">
            <div class="card border-radius-1em">
                <div class="card-body">
                    <div class="clearfix">
                        <div class="float-right">
                            <div class="input-group input-group-sm">
                                <select class="custom-select custom-select-sm">
                                    <option selected>Jan</option>
                                    <option value="1">Dec</option>
                                    <option value="2">Nov</option>
                                    <option value="3">Oct</option>
                                </select>
                                <div class="input-group-append">
                                    <label class="input-group-text">Month</label>
                                </div>
                            </div>
                        </div>
                        <h4 class="card-title mb-4">Top Selling Shops</h4>
                    </div>

                    <div class="text-muted text-center" v-if="toplistingsdata.length > 0">
                        <img v-if="toplistingsdata[0].user.avatar !== null" :src="storageurl+toplistingsdata[0].user.avatar" class="avatar-md rounded-circle mx-auto">
                        <img v-else src="/images/product-placeholder.png" class="avatar-md rounded-circle mx-auto">
                        <p class="mb-2">{{toplistingsdata[0].user.name}} {{toplistingsdata[0].user.lastname}}</p>
                        <h4>£{{(toplistingsdata[0].sales_sum_total_order_inc).toFixed(2)}}</h4>
                        <p class="mt-4 mb-0"><span class="badge badge-soft-success font-size-11 mr-2"> 0.6% <i class="mdi mdi-arrow-up"></i> </span> From previous period</p>
                    </div>
                    <b-card class="border-radius-1em border shadow-none mb-0" v-else style="min-height: 450px;" body-class="d-flex flex-column justify-content-center align-items-center">
                        <i class="bx bx-store font-size-42 text-muted d-block"></i>
                        <h5 class="text-muted">No Top Shops</h5>
                        <p>Add a shop or stock room and start listing products.</p>
                    </b-card>
                     <simplebar  v-if="toplistingsdata.length > 0" style="height: 285px; max-height: 285px">
                    <div class="table-responsive mt-4 mb-0">
                        <table class="table table-centered mb-0">
                            <tbody>
                                <tr v-for="product in toplistingsdata">
                                    <td>
                                        <h5 class="font-size-14 mb-1">{{product.user.name}} {{product.user.lastname}}</h5>
                                        <p class="text-muted mb-0 text-truncate"></p>
                                    </td>

                                    <td>
                                        <apexchart class="apex-charts" :options="ChartData.RadialChart1.chartOptions" :height="60" :width="60" :series="ChartData.RadialChart1.series" dir="ltr" />
                                    </td>
                                    <td>
                                        <p class="text-muted mb-1">Sold</p>
                                        <h5 class="mb-0">{{product.orders_sum_quantity}}</h5>
                                    </td>
                                    <td>
                                        <p class="text-muted mb-1">Sales Value</p>
                                        <h5 class="mb-0">£{{(product.sales_sum_total_order_inc).toFixed(2)}}</h5>
                                    </td>
                                </tr>
                               
                            </tbody>
                        </table>
                    </div>
                </simplebar>
                </div>
            </div>
        </div>
              </b-row>
            </b-col>
					</b-row>
					
			

			</b-col>
</b-row>
	
			
		</b-row>
	</div>
</template>