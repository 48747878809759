<script type="text/javascript">

import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import AllCalculators from "../../components/widgets/calculators/AllCalculators";


export default{
	components:{Layout, PageHeader, AllCalculators},
	data: function () {
		return{
			title: "Calculators",
		      items: [
		        {
		          text: "Educate",
		          href: "javascript:;",
		        },
		        {
		          text: "Calculators",
		          active: true,
		        },
		      ],
		}
	}
}
</script>
<template>
	<Layout>
		<PageHeader :title="title" :items="items" />
		<b-row>
			<b-col md="12">
				<AllCalculators />

			</b-col>
		</b-row>
	</Layout>
</template>