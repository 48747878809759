<script>
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import QrcodeVue from 'qrcode.vue';
import vue2Dropzone from 'vue2-dropzone'
import Multiselect from 'vue-multiselect'
import SideSlidePanel from "../../components/widgets/SideSlidePanel";
import Layout from '../../layouts/main'
import PageHeader from '../../components/page-header'
//import FilterListTree from "../../components/widgets/FilterListTree";
import ServiceDetails from "./ServiceDetails";
import ServiceAddEdit from "./ServiceAddEdit";
import ProductPreview from "../../components/widgets/ecommerce/ProductPreview";
import FilterProductBrands from "../../components/widgets/FilterProductBrands";
import SelectCompanyProductCategory from "../../components/widgets/SelectCompanyProductCategory"
import SelectCompanyProductBrand from "../../components/widgets/SelectCompanyProductBrand"
import SelectCompanyProductCategories from "../../components/widgets/SelectCompanyProductCategories"
import SelectCompanyProductAttribute from "../../components/widgets/SelectCompanyProductAttribute"
import SelectCompanyProductAttributeValue from "../../components/widgets/SelectCompanyProductAttributeValue"
import ImageCropper from "../../components/widgets/ImageCropper";
import ImageSwiper from "../../components/widgets/gallery/ImageSwiper";
import ProductLabel from "../../components/widgets/admin/print/ProductLabel";
import simplebar from "simplebar-vue";
import VueBarcode from '@chenfengyuan/vue-barcode';
/**
 * Add-product component
 */
export default {
  components: { vueDropzone: vue2Dropzone, Multiselect, Layout, PageHeader, ckeditor: CKEditor.component,FilterProductBrands, SelectCompanyProductCategory, SelectCompanyProductBrand, SelectCompanyProductCategories, SelectCompanyProductAttribute, SelectCompanyProductAttributeValue, ImageCropper, ImageSwiper, SideSlidePanel, simplebar, ServiceDetails, QrcodeVue, VueBarcode, ProductLabel, ProductPreview, ServiceAddEdit },
  data() {
    return {
      showDetails: true,
      loadingForm: false,
      loadingImgForm: false,
      refs: 'Service',
      name: 'Service',
      title: 'Service Details',
      items: [
        {
          text: 'Service',
          href: '/',
        },
        {
          text: 'Details',
          href: '/stock/products',
        },
      ],
      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        thumbnailHeight: 150,
        thumbnailWidth: 150,
        autoProcessQueue: false,
      },
      updateDropzoneOptions: {
        url: '',
        timeout: 3000,
        thumbnailHeight: 150,
        thumbnailWidth: 150,
        autoProcessQueue: true,
        parallelUploads: 1,
        headers: null,
        retryChunks: true,
      },
      showPurchaseResults: false,
      storageurl: 'https://'+this.$attrs.company.nickname+'.s3.eu-west-2.amazonaws.com',
      editImageUrl: '',
      editImage: false,
      editable: true,
      editcaption: -1,
      editcaptionrawtext: '',
      success_message: false,
      success_messagetext: 'Congratulations, your action was successful',
      editor: ClassicEditor,
      editorData:
        "<h3>Hello World!</h3><h5><b>This is an simple editable area.</b></h5>",
      content: "<h1>Some initial content</h1>",
      value: null,
      value1: null,
      view: 'basic-info',
      isFixed: false,
      newFeature: false,
      windowTop: '',
      windowInnerHeight: '',
      purchaselimit: 25,
      shopslimit: 25,
      shopscurrentPage: 1,
      shopsearch: '',
      shop_type_id: '',
      shopsortby: 'id',
      shoporder: 'desc',
      error_reponse: '',
      error_message: false,
      purchase: null,
      measurement_unit_value: '',
      shippingoptions: [],
      all_brands: [],
      all_tax_rules: [],
      all_attributes: [],
      attribute_values: [],
      active_collections: [],
      active_categories: [],
      active_public_categories: [],
      active_trade_categories: [],
      categories: [],
      public_categories: [],
      trade_categories: [],
      selected_customer_groups: [],
      selected_customer_type: '',
      product_brand_id: null,
      product_type_id: 0,
      cost_price: 0,
      trade_price: 0,
      sales_price: 0,
      inc_tax_price: 0,
      import_tax: 0,
      export_tax: 0,
      active_tax_rule: 0,
      carnet_price: 0,
      shopsData: [],
      carnet_weight: 0,
      profit_margin: 0,
      calculated_cost_price: 0,
      calculated_tax_duties: 0,
      bulkquantity: 0,
      active_submenu: 'product-status',
      product_attributes: [],
      product_variants: [],
      product_variantForm: '',
      product_combinationsForm: {
        variants: [],
        quantity: 0,
        price_difference: 0,
        product_id: 0,
      },
      product_bulk_pricing: [],
      product_condition: [],
      product_shipping_options: [],
      selected_attribute: {id: 0, name: 'Select Attribute', image: ''},
      selected_value: {id: 0, name: 'Select Value', image: ''},
      selected_measurement_unit: '',
      selected_measurement_value: '',
      active_tax_rule: '',
      searchbrand: '',
      purchasesearch:'',
      purchase_orders: [],
      searchresult: '',
      qrData: {
        type: 'product',
        reference: '',
        uid: '',
        id: '',
      },
      productData: {
          listings: [],
                uid: '',
                reference: '',
                name: '',
                slug: '',
                short_description: '',
                image: '',
                banner_image: '',
                company_service_category_id: 0,
                company_service_type_id: 1,
                isowner: 0,
                tax_rule: {id: 0},
                tax_rule_id: null,
                price_exc: parseFloat(0.00).toFixed(2),
                price_inc: parseFloat(0.00).toFixed(2),
                meta_description: '',
                meta_title: '',
                active: false,
                trade_active: false,
                public_active: false,
                allow_bookings: false,
                booking_times: [],
                booking_days: [],
                set_duration: false,
                duration: 1, 
                duration_units: '',
                max_units: 0,
                service_deposit: 0.00,
                ownercontact: {
                  name: '',
                  email: '',
                  phone: '',
                  address1: '',
                  address2: '',
                  address3: '',
                  country_id: '',
                  county_id: '',
                  postcode: '',

                }
      },
      brandsearch_results: [],
      collections: [],
      col_limit: 25,
      col_search: '',
      listing_types: [],
      colcurrentPage: 1,
      brandresults_start: 0,
      brandresults_end: 5,
      product_attribute_success: null,
      limits: [
            {value: 25, text: "25"},
            {value: 50, text: "50"},
            {value: 100, text: "100"},
          ],
      quantity_lang:{
            bulk:{
              qtytooltip: 'Add quantity where discount starts to apply. For example if you enter quantity of 10, discounts will apply on orders of 10 and above.'
            }
          },
      conditions: [
        'New',
        'Used',
        'Refurbished',
        'Custom',
      ],
      options: [
        'Alaska',
        'Hawaii',
        'California',
        'Nevada',
        'Oregon',
        'Washington',
        'Arizona',
        'Colorado',
        'Idaho',
        'Montana',
        'Nebraska',
        'New Mexico',
        'North Dakota',
        'Utah',
        'Wyoming',
        'Alabama',
        'Arkansas',
        'Illinois',
        'Iowa',
      ],
    }
  },
  mounted(){
    var url = window.location.href;
    var hash = window.location.hash;
    if(url == 'http://'+this.$attrs.company.nickname+'.gemesys.co.uk/stock/product'){
      window.location.href = 'http://'+this.$attrs.company.nickname+'.gemesys.co.uk/stock/product/add#basic-info'
    }
    if(this.$attrs.id !== ('' || 'add')){
      this.getProduct(this.$attrs.id);
    }else{
      this.showDetails = false;
    }
    
    if(hash !== '#'){
    this.view = hash.replace('#','');
    }
    
    
    this.getListingTypes();
    //this.getShippingOptions();
    this.getShops();
   
    let self = this;
    window.addEventListener('hashchange', function() {
      console.log(window.location.hash);
      console.log(self.view);
      self.view = window.location.hash.replace('#', '');

    }, false);

    //window.addEventListener("scroll", this.onScroll);
  },

  methods:{

    addToCategoryCollection(category_id, index, marketplace, type){
      if(this.productData.id !== null){
        axios.post('/api/company/service/'+type+'/'+category_id+'/add-product', {marketplace_categories: 'marketplace', product_id: this.productData.id}, {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
              }}).then(response => {
          this.success_messagetext = "Product Added to "+type;
          this.success_message = true;

        }).catch(error => {
          this.error_message = true;
          this.error_reponse = errors.response.data.errors;
        });
      }
    },
    removeFromCategory(category_id, index, marketplace){
      if(this.productData.id !== null){
        var defaultcat = '';
            if(marketplace == 'company'){
              if(category_id == this.productData.default_category_id){
                defaultcat = 1;
              }else{
                defaultcat = 0;
              }
            }
            if(marketplace == 'trade'){
              if(category_id == this.productData.trademarket_category_id){
                defaultcat = 1;
              }else{
                defaultcat = 0;
              }
            }
            if(marketplace == 'public'){
              if(category_id == this.productData.pubmarket_category_id){
                defaultcat = 1;
              }else{
                defaultcat = 0;
              }
            }
            axios.post('/api/company/service/category/'+category_id+'/remove-product', {marketplace_categories: marketplace, product_id: this.productData.uid, is_default: defaultcat}, {
              headers: {
                'Authorization' : 'Bearer '+this.$attrs.user.api_token
              }
            }).then(response => {
              this.$emit('product-removed', response.data);
              this.success_messagetext = 'Product Removed from Category';
              this.success_message = true;
              let self = this;
              setTimeout(function(){
                self.success_message = false;
              }, 300);
            }).catch(error => {
              //this.$emit('resource-error', error.data);
            });
      }
    },

    getCategories(type){
      axios.get('/api/company/service/categories?page='+this.currentPage+'&limit='+this.limit+'&sortby='+this.sortby+'&order='+this.order+'&search='+this.search+'&marketplace_categories='+type, {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
              }}).then(response => {
        if(type == 'company'){
          this.categories = response.data;
        }
        if(type == 'public'){
          this.public_categories = response.data;
        }
        if(type == 'trade'){
          this.trade_categories = response.data;
        }
        //return response.data;

      }).catch(error => {
        this.response_error = error.data;
      });
    },
      handleImage(e) {
      const selectedImage = e.target.files[0]; // get first file
      this.createBase64Image(selectedImage);

    },
    createBase64Image(fileObject) {
      const reader = new FileReader();

      reader.onload = (e) => {
        this.editImageUrl = e.target.result;
        //this.uploadImage();
        this.editImage = true;
      };
      reader.readAsDataURL(fileObject);
    },
        categorySelected(payload, params){
          console.log([payload, params]);

            if(params == 'company'){
              this.productData.default_category_id = payload.id;
             this.productData.company_defaultcategory = payload;
            }
            if(params == 'trade'){
              this.productData.trademarket_category_id = payload.id;
             this.productData.trade_marketplace_defaultcategory = payload;
            }
            if(params == 'public'){
              this.productData.pubmarket_category_id = payload.id;
             this.productData.public_marketplace_defaultcategory = payload;
            }
        },
        getProduct(id){
          axios.get('/api/company/service/'+id+'', {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
              }}).then( response => {
            this.productData = response.data;
            this.items[2] = this.productData.name;
            this.updateDropzoneOptions.url = '/api/company/service/'+this.productData.uid+'/upload-image';
            this.updateDropzoneOptions.headers = {'Authorization' : 'Bearer '+this.$attrs.user.api_token};
            this.qrData.reference = this.productData.reference;
                this.qrData.uid = this.productData.uid;
                this.qrData.id = this.productData.id;
          }).catch(error => {
            //this.error_message = true;
          });
        },
        onScroll(e) {
          this.windowTop = window.top.scrollY;
          
          console.log(window.top.scrollY);
          if (window.top.scrollY >= 67) {
            this.isFixed = true;
          } else {
            this.isFixed = false;
          }
        },
        refetchListings: function(key, data){
          console.log(key);
          this.$data[key] = data;
          this.getShops();
        },
        refetchService() {
          this.getProduct(this.$attrs.id);
        },
          getShops(){
            axios.get('/api/company/listings?page='+this.shopscurrentPage+'&limit='+this.shopslimit+'&sortby='+this.shopsortby+'&order='+this.shoporder+'&search='+this.shopsearch+'&type_id='+this.shop_type_id, {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
            this.shopsData = response.data;
            }).catch(error => {
              this.shops_error = error.data;
            });
          },
          toggleBrand(isdelete, selectedbrandid){
            if(isdelete === false){
            this.product_brand_id = selectedbrandid;
            }
            if(isdelete === true){
            this.product_brand_id = null;
            }
            /*axios.post('http://localhost:7080/gemesislabs-framework/public/seller/product/'+this.productdata[0].id+'/togglebrand', {brandid: selectedbrandid, delete: isdelete}).then(response => {
                 console.log(response);
                //this.product_attribute_success = response.data;
            })*/
          },
          toggleBulkPricing(isdelete, bulk_price_id){
            if(isdelete === false){
                if( (this.discount_type != '') && (this.discount_amount != 0) && (this.bulkquantity != 0) && (this.selected_customer_type != '') && (this.selected_customer_group != '')){
                    axios.post('http://localhost:7080/gemesislabs-framework/public/seller/product/'+this.productdata[0].id+'/togglebulkprice', {discounttype: this.discount_type, discountamount: this.discount_amount, quantity: this.bulkquantity, customertype: this.selected_customer_type, customergroup: this.selected_customer_group, delete: isdelete, bulkpricing_id: bulk_price_id}).then(response => {
                         console.log(response);
                        //this.product_attribute_success = response.data;
                    })
                }
            }
            if(isdelete === true){
                 axios.post('http://localhost:7080/gemesislabs-framework/public/seller/product/'+this.productdata[0].id+'/togglebulkprice', {discounttype: this.discount_type, discountamount: this.discount_amount, quantity: this.bulkquantity, customertype: this.selected_customer_type, customergroup: this.selected_customer_group, delete: isdelete, bulkpricing_id: bulk_price_id}).then(response => {
                     console.log(response);
                    //this.product_attribute_success = response.data;
                }) 
            }
            this.$emit('bulkprices-added');
          }, 
          calcCostPrice(){
            var tax_duties = JSON.parse(this.import_tax) + JSON.parse(this.export_tax) + JSON.parse(this.carnet_price);
            this.calculated_tax_duties = tax_duties.toFixed(2);
            var cost_price = JSON.parse(this.productData.cost_price) + JSON.parse(this.calculated_tax_duties);
            this.calculated_cost_price = cost_price.toFixed(2);
            this.calcProfitMargin();
          }, 
          updateTaxCalc(){
            var setMultiplier = this.activeTaxPercentage / 100;
            var multiplier = setMultiplier + 1;
            var calculation = JSON.parse(this.productData.sales_price) * multiplier;
            this.inc_tax_price = calculation.toFixed(2);
            this.productData.sales_price = this.productData.sales_price.toFixed(2);
            this.calcProfitMargin();
          },
          removeVatCalc(){
            var setMultiplier = this.activeTaxPercentage / 100;
            var multiplier = setMultiplier + 1;
            var calculation = JSON.parse(this.inc_tax_price) / multiplier;
            this.productData.sales_price = calculation.toFixed(2);
            this.calcProfitMargin();
          },
           activeTaxRule(id){
            var activeObject = this.all_tax_rules.filter(item => item.id === id);
            console.log(activeObject);
            this.active_tax_percentage = activeObject[0].percentage;
            return activeObject[0].percentage;
          },
          calcProfitMargin(){
            var salesSubCost = JSON.parse(this.productData.sales_price).toFixed(2) - JSON.parse(this.calculated_cost_price).toFixed(2);
            var multiplier = salesSubCost / JSON.parse(this.calculated_cost_price).toFixed(2);
            var calculation = multiplier * 100;
            this.profit_margin = calculation.toFixed(2);
          },
          formatCurrency: function(price){
            return price.toFixed(2);
          },
          quantityInListing(id){
           var index = this.productData.listings.findIndex(item => item.company_listing_id == id);
           if(index !== -1){
            return true;
           }else{
            return false;
           }
          },
         
          listingQuantity(value){
            return this.productData.listings.findIndex(item => item.company_listing_id == value);
            //return items[0];
          },
          

          updateActiveCategories(category, type){
            if(type == 'company'){
              var activeCategories = this.productData.company_categories.findIndex(item => item.id == category.id);
              if(activeCategories < 0){
                this.addToCategoryCollection(category["id"], 0, type, 'category');
                category["category"] = category;
                this.productData.company_categories.push(category);
              }else{
                this.removeFromCategory(category["id"], activeCategories, type);
                this.productData.company_categories.splice(activeCategories, 1);
              }
            }
            if(type == 'public'){
             var activeCategories = this.productData.public_marketplace_categories.findIndex(item => item.id == category.id);
              if(activeCategories < 0){
                this.addToCategoryCollection(category["id"], 0, type, 'category');
                category["category"] = category;
                this.productData.public_marketplace_categories.push(category);
              }else{
                this.removeFromCategory(category["id"], activeCategories, type);
                this.productData.public_marketplace_categories.splice(activeCategories, 1);
              }


            }
            if(type == 'trade'){
              var activeCategories = this.productData.trade_marketplace_categories.findIndex(item => item.id == category.id);
              if(activeCategories < 0){
                this.addToCategoryCollection(category["id"], 0, type, 'category');
                category["category"] = category;
                this.productData.trade_marketplace_categories.push(category);
              }else{
                this.removeFromCategory(category["id"], activeCategories, type);
                this.productData.trade_marketplace_categories.splice(activeCategories, 1);
              }
            }
          },
            updateHistory(hash){
            window.history.pushState({}, '', '#'+hash);
          },
          activeInCategories(category, type){
            if(type == 'company'){
              if(this.productData.company_categories.length > 0){
                var activeCategories = this.productData.company_categories.filter(item => item.category.id == category.id);
                if(activeCategories.length > 0){
                  return true;
                }else{
                  return false;
                }
              }
            }
            if(type == 'public'){
              if(this.productData.public_marketplace_categories.length > 0){
                var activePubCategories = this.productData.public_marketplace_categories.filter(item => item.category.id == category.id);
                if(activePubCategories.length > 0){
                  return true;
                }else{
                  return false;
                }
              }
            }
            if(type == 'trade'){
              if(this.productData.trade_marketplace_categories.length > 0){
                var activeTradCategories = this.productData.trade_marketplace_categories.filter(item => item.category.id == category.id);
                if(activeTradCategories.length > 0){
                  return true;
                }else{
                  return false;
                }
              }
            }

          },
          getPurchaseOrders(){
            axios.get('/api/company/service/purchases?page='+this.currentPage+'&limit='+this.purchaselimit+'&sortby=id&order=desc&search='+this.purchasesearch, {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
              }}).then(response => {
                console.log(response);
                this.purchase_orders = response.data;
                this.showPurchaseResults = true;
              });
          },
        getListingTypes(){
          axios.get('/api/company/listing/types', {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
              }}).then(response => {
          this.listing_types = response.data;
          }).catch(error => {
            this.shops_error = error.data;
          });
        },
        urlRewrite(name){
          if(this.$attrs.id == "add"){
            this.productData.url_rewrite = this.sanitizeTitle(name);
            this.productData.meta_title = name;
          }
        },
         getTaxRules(){
          axios.get('/api/company/order/tax-rules?limit=0&sortby=id&order=desc', {headers: {
            'Authorization' : 'Bearer '+this.$attrs.user.api_token
          }}).then(response => {
            console.log(response);
            this.all_tax_rules = response.data;
            this.active_tax_rule = response.data[0].id;
          }).catch(error => {
            this.error_reponse = {error: ["Could Not Load Tax Rules"]};
            this.error_message = true;
          });
        },
  },
  created(){
        this.getTaxRules();
        //this.getShops();
        //this.getListingTypes();
        var url = window.location.href;
        if(url.search("edit=true") > 0){
          this.editable = true;
          this.showDetails = false;
        }
        if(url.search("edit=false") > 0){
          this.editable = false;
          this.showDetails = false;
        }
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll)
  },
  computed: {
          variantFormFilled(){
            if(
              ((this.product_variantForm.is_custom == 0) || (this.product_variantForm.is_custom == false))
              &&
              ((this.product_variantForm.attribute !== null) && (this.product_variantForm.value !== null))

              ){
              return false;
            }else if (
              ((this.product_variantForm.is_custom == 1) || (this.product_variantForm.is_custom == true))
              &&
              ((this.product_variantForm.attribute !== null) && (this.product_variantForm.custom_text !== ''))

              ){
              return false;
            }else{
              return true;
            }
          },
          listingsTotalQuantities(){
            var score = 0;
            for(var i = 0; i < this.productData.listings.length; i++){
              score = parseFloat(score) + parseFloat(this.productData.listings[i].quantity);
            }
            return score;
          },
            activeTaxPercentage: function(){
                var activeObject = this.all_tax_rules.filter(item => item.id === this.active_tax_rule);
                this.active_tax_percentage = activeObject[0].percentage;
                var setMultiplier = activeObject[0].percentage / 100;
                var multiplier = setMultiplier + 1;
                var calculation = this.productData.sales_price * multiplier;
                return activeObject[0].percentage;
            },
            calculateIncVat: function(){
                var activeObject = this.all_tax_rules.filter(item => item.id === this.active_tax_rule);
                this.active_tax_percentage = activeObject[0].percentage;
                var setMultiplier = activeObject[0].percentage / 100;
                var multiplier = setMultiplier + 1;
                var calculation = this.productData.sales_price * multiplier;
                this.inc_tax_price = calculation.toFixed(2);
                this.calcCostPrice();
                this.calcProfitMargin();
                return calculation.toFixed(2);
          },
          panelHeight(){
            this.windowInnerHeight = window.innerHeight;
            return window.innerHeight;
          }
  }
}
</script>
<!--<style>
html, body {margin: 0; height: 100%; overflow: hidden}
</style>-->
<template>
  <Layout :user="$attrs.user" >
    <PageHeader :title="title" :items="items" />
    <b-row v-if="showDetails == true && (($attrs.user.companies[0].userrole.name == 'Owner') || ($attrs.user.companies[0].permissions.view.includes($attrs.active_menu.id)))">
      <ServiceDetails :user="$attrs.user" :company="$attrs.company" :product="productData" :shopsData="shopsData" @close-details="showDetails = false, editable = false, view = 'basic-info'" :all_tax_rules="all_tax_rules" :active_tax_rule="active_tax_rule" @update-listings="refetchListings" :listing_types="listing_types" :url_view="view"></ServiceDetails>
      
    </b-row>
    <b-row v-else-if="($attrs.user.companies[0].userrole.name == 'Owner') || $attrs.user.companies[0].permissions.add.includes($attrs.active_menu.id) || $attrs.user.companies[0].permissions.edit.includes($attrs.active_menu.id)">
      <ServiceAddEdit :id="$attrs.id" :user="$attrs.user" :company="$attrs.company" :product="productData" :shopsData="shopsData" @close-editor="showDetails = true, editable = false, view = 'basic-info', refetchService()" :all_tax_rules="all_tax_rules" :active_tax_rule="active_tax_rule" @update-listings="refetchListings" :listing_types="listing_types" :url_view="view" :active_menu="$attrs.active_menu" ></ServiceAddEdit>
     
    </b-row>
    <b-row v-else>
      <b-col md="12" class="d-flex flex-column justify-content-center restricted-col">
        <AccessRestricted :page="false"></AccessRestricted>
        <b-button variant="primary" class="btn-rounded mx-auto" @click="showDetails = false, editable = false">Back to Details</b-button>
      </b-col>
    </b-row>
      <SideSlidePanel :panelClass="'edit-sidepanel-fullwidth bg-white'" :editPanel="editImage"  @close-panel="editImage = false" >
                  <ImageCropper :imageUrl="editImageUrl" @close-editor="editImage = false, view = 'basic-info', updateHistory(view)" :checkCrossOrigin="false"></ImageCropper>
      </SideSlidePanel>
       <b-modal id="print-label" size="xl" hide-footer centered>
            <ProductLabel :code_type="'barcode'"  :qr_value="qrData"></ProductLabel>
          </b-modal>
  </Layout>
</template>
