<script>
import Vertical from "./vertical";
import Horizontal from "./horizontal";
import BroadcastToast from "../components/widgets/admin/notification/BroadcastToast";
import { EventBus } from '../app'


export default {
  components: { Vertical, Horizontal, BroadcastToast},
  data() {
    return {
      menu: [],
      user: '',
      currencies: [],
      currencykey: process.env.MIX_CURRENCY_CONVERTER,
      selected_currency: '',
      selected_currency_symbol: '',
      exchange_rates: '',
    };
  },
  computed: {
    layoutType() {
        return this.$root.layout?.type;
    },
    menuItems(){
      return this.$root.menu;
    },
    activeMenuItem(){
      return this.$root.active_menu;
    }
  },
  updated(){
    this.menu = this.$root.menu;
    this.user = this.$root.user;
  },
};
</script>

<template>
  <div>
    <vertical v-if="layoutType === 'vertical'" :user="$attrs.user" :menu="menuItems" :active_menu="activeMenuItem" :selected_currency="$attrs.selected_currency" :selected_currency_symbol="$attrs.selected_currency_symbol" :exchange_rates="$attrs.exchange_rates" :currencies="$attrs.currencies">
      <slot :menu="menu" :selected_currency="$attrs.selected_currency" :selected_currency_symbol="$attrs.selected_currency_symbol" :exchange_rates="$attrs.exchange_rates" :currencies="$attrs.currencies" />
    </vertical>

    <Horizontal v-if="layoutType === 'horizontal'"  :user="$attrs.user" :menu="menuItems" :active_menu="activeMenuItem" :selected_currency="$attrs.selected_currency" :selected_currency_symbol="$attrs.selected_currency_symbol" :exchange_rates="$attrs.exchange_rates" :currencies="$attrs.currencies">
      <slot :menu="menu" :user="user" :selected_currency="$attrs.selected_currency" :selected_currency_symbol="$attrs.selected_currency_symbol" :exchange_rates="$attrs.exchange_rates" :currencies="$attrs.currencies"/>
    </Horizontal>
        
    <BroadcastToast :user="$attrs.user"></BroadcastToast>
  </div>
</template>
