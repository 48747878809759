<script type="text/javascript">
	import { EventBus } from '../../public-app'
	import ImageSwiper from "../../components/widgets/gallery/ImageSwiper";
	import ProductCard from "../../components/widgets/ecommerce/public/ProductCard";
	import ProductCardHorizontal from "../../components/widgets/ecommerce/public/ProductCardHorizontal";
	export default{
		components: {
			ImageSwiper,
			ProductCard,
			ProductCardHorizontal
		},
		props: {
			resource: {
		        type: String,
		        default: () => '',
		      },
		      resource_id: {
		        type: Number,
		        default: () => '',
		      },
		      api_token: {
		        type: String,
		        default: () => '',
		      },
		      resource_route: {
		        type: String,
		        default: () => '',
		      },
		      marketplace: {
		        type: String,
		        default: () => '',
		      },
		      product_list: {
		        type: Array,
		        default: () => '',
		      },
		      expanded:{
		      	type: Number,
		      	default: () => 0
		      },
		      view: {
		      	type: String,
		      	default: () => 'grid'
		      },
		      layouttoggle: {
		      	type: Boolean,
		      	default: () => true
		      },
		      showtools: {
		      	type: Boolean,
		      	default: () => false
		      },
		      showcategories: {
		      	type: Boolean,
		      	default: () => false
		      },
		      minilist: {
		      	type: Boolean,
		      	default: () => false
		      }
		  },
		  data(){
			return{
	
		        quickviewproduct: '',
		        quantity: 1,
		        showQuantitySelector: null,
		        hoverIndex: -1,
		        storageurl: '.s3.eu-west-2.amazonaws.com',
		        showQVGallery: false,
		        viewImage: null,
		        imgswiperOptions: {
		          direction: 'vertical',
		          slidesPerView: 3,
		          spaceBetween: 10,
		          pagination: {
		            el: '.swiper-pagination',
		            clickable: true
		          },
		          navigation: {
		            nextEl: '.swiper-button-next',
		            prevEl: '.swiper-button-prev'
		          }

		        },
			}
		  },
		  methods: {
		  	removeResource(){

		  	},
		  	priceIncVat(price, tax_rule){
		  		if(tax_rule !== null){
			      var tax = 1 + (tax_rule.percentage / 100);
			      return (parseFloat(price) * parseFloat(tax)).toFixed(2);
		    	}else{
		    		return parseFloat(price).toFixed(2);
		    	}
		    },
		    addToCart(product, quantity){
		    	EventBus.$emit('add-to-cart', product, quantity)
		    },
		    imageSelected(img){
		    	if(this.viewImage == null){
		    		this.quickviewproduct.images.unshift(this.quickviewproduct.cover_image);
		    	}
		    	this.viewImage = img.src;

		    },
		    qvModalClosed(){
		    	this.viewImage = null;
		    }
		  }
	}
</script>
<template>
	 <div class="row">
          <div class="col-md-12">
          	<ul class="nav nav-pills product-view-nav mb-2 float-right" v-if="layouttoggle == true">
          	<li class="nav-item">
                  <a class="nav-link" :class="{'active': view == 'grid' }" href="javascript: void(0);" @click="view = 'grid'">
                    <i class="bx bx-grid-alt"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': view == 'list' }" href="javascript: void(0);" @click="view = 'list'">
                    <i class="bx bx-list-ul"></i>
                  </a>
                </li>
               </ul>
          	<div id="my-table" class="no-shadow row" v-if="(product_list.length !== 0) && (view == 'list')">
          										
          										<div class="d-flex flex-column product-grid-item mb-2" :class="{'col-md-12' : expanded == 0,'col-md-12' : expanded == 1}" v-for="(product, index) in product_list">
                 				<ProductCardHorizontal :product="product" :index="index" :company="product.company" :exchange_rates="$attrs.exchange_rates" :selected_currency="$attrs.selected_currency" :selected_currency_symbol="$attrs.selected_currency_symbol" ></ProductCardHorizontal>
     								</div>
                    
                              

                              <!--<li class="list-group-item py-4 text-center" ><a class="btn btn-outline-light btn-rounded">Load More</a>
                              </li>-->
                            </div>
                <ul class="list-group product-list w-100 no-shadow" v-if="(product_list.length == 0) && (view == 'list')">
                	  <li class="list-group-item d-flex">
                	  	<i class="bx bx-warning"></i> No Products <span v-if="resource !== ''">assigned to this {{resource}}</span>
                	  </li>
                </ul>
          </div>
          <div class="col-md-12">
          	<div  id="my-table" class="row product-grid px-2 row-eq-height" v-if="(product_list.length !== 0) && (view == 'grid')">
                 <div class="d-flex flex-column product-grid-item mb-2" :class="{'col-md-4 col-sm-6' : expanded == 0,'col-md-3 col-sm-6' : expanded == 1}" v-for="(product, index) in product_list">
                 				<ProductCard :product="product" :index="index" :company="product.company" :exchange_rates="$attrs.exchange_rates" :selected_currency="$attrs.selected_currency" :selected_currency_symbol="$attrs.selected_currency_symbol" ></ProductCard>
     								</div>
                              

                              <!--<li class="list-group-item py-4 text-center" ><a class="btn btn-outline-light btn-rounded">Load More</a>
                              </li>-->
                            </div>
                <ul class="list-group product-list w-100 no-shadow"  v-if="(product_list.length == 0) && (view == 'grid')">
                	  <li class="list-group-item d-flex">
                	  	<i class="bx bx-warning"></i> No Products <span v-if="resource !== ''">assigned to this {{resource}}</span>
                	  </li>
                </ul>
          </div>
           <b-modal id="product-info" centered size="xl" body-class="p-0 my-0 overflow-hidden" content-class="border-radius-1em p-0 border-none" header-class="border-none position-absolute right-0" hide-footer @close="qvModalClosed">
          	<!--<div v-if="quickviewproduct.cover_image" style="height: 600px; background-repeat: no-repeat;
    background-position: center; background-size: contain;" :style="'background-image: url(' + storageurl + quickviewproduct.cover_image.src.replace('//','/') + ')'"></div>
    <div v-else style="height: 600px; background-repeat: no-repeat;
    background-position: center; background-size: contain;" :style="'background-image: url(/images/product-placeholder.png)'"></div>-->
    <b-card v-if="quickviewproduct !== ''" no-body class="mb-0">
    	<b-row no-gutters>
      <b-col lg="6" @mouseenter="showQVGallery = true" @mouseleave="showQVGallery = false" class="p-0">
      	<transition name="fade-left">
		                    	<div class="col-md-12 position-absolute z-index-2" v-if="showQVGallery == true"> 
                              	<!--<a id="tooltip-add" v-b-modal.product-info @click="quickviewproduct = product" class="mt-3 ml-3 btn btn-primary font-size-16 btn-rounded ml-auto float-left"><i class="mdi mdi-eye-outline"></i></a>-->
                              	<div class="d-block overflow-hidden px-1 position-relative ml-2" style="max-height: 626px; width: 10%;" v-if="quickviewproduct.images !== null">
														    	<ImageSwiper :swiperOptions="imgswiperOptions" :items="quickviewproduct.images" @image-selected="imageSelected" ></ImageSwiper>
														    	</div>
                              </div>
                          </transition>
                          <div v-if="viewImage == null">
          											<b-card-img v-if="quickviewproduct.cover_image" class="w-100 quickviewproduct-grid-img" :src="'https://'+quickviewproduct.product.nickname + storageurl + quickviewproduct.cover_image.src.replace('//','/')"></b-card-img>
                              	<b-card-img v-else class="w-100 quickviewproduct-grid-img" src="/images/product-placeholder.png"></b-card-img>
                              </div>
                              <div v-else>
                              	<b-card-img class="w-100 quickviewproduct-grid-img" :src="'https://'+quickviewproduct.product.nickname + storageurl + viewImage.replace('//', '/')"></b-card-img>
                              </div>
                              </b-col>
               <b-col lg="6" class="d-flex align-items-center">
                              	<b-card-body>
                              		<span class="badge badge-primary bg-primary font-size-12">{{quickviewproduct.reference}}</span>
                   <h2 class="mr-auto my-auto text-primary">{{quickviewproduct.name}}</h2>
                                	
						            <p class="text-muted italic no-margin" v-html="quickviewproduct.short_description"></p>
                          
                                <div class="my-1 px-3"  v-if="marketplace == ('company' || 'public')">
                                	<strong class="text-muted mb-auto">£{{quickviewproduct.sales_price}} <small class="mr-3">exc. VAT</small></strong>
                                </div>
                                <div class="my-1 px-3"  v-if="marketplace == ('company' || 'public')">
                                	<strong class="text-muted mb-auto"> £{{priceIncVat(quickviewproduct.sales_price, quickviewproduct.tax_rule.percentage)}} <small class="mr-3">inc. VAT</small></strong>
                                </div>
                                <div class="my-1 px-3" v-if="marketplace == 'trade'">
                                	<strong class="text-muted mb-auto">£{{quickviewproduct.trade_price}} <small class="mr-3">Trade Price</small></strong>
                                </div>
                                <div class="d-flex grab-row">
											            <a 
					                          href="javascript: void(0);"
					                          class="badge bg-light bg-soft rounded-pill font-size-11 m-1"
					                         v-if="quickviewproduct.public_marketplace_defaultcategory !== null"><img class="rounded-circle avatar-xs" v-if="quickviewproduct.public_marketplace_defaultcategory.image" :src="`${quickviewproduct.public_marketplace_defaultcategory.image}`" alt /> <img v-else  src="/images/banner-placeholder.png" alt="" class="rounded-circle avatar-xs"> <span class="text-dark">{{quickviewproduct.public_marketplace_defaultcategory.name}}</span></a>

					                         <a 
					                         v-if="quickviewproduct.public_marketplace_categories.length >0"
					                          href="javascript: void(0);"
					                          class="badge bg-light bg-soft rounded-pill font-size-11 m-1"
					                          v-for="category in quickviewproduct.public_marketplace_categories"><img v-if="category.category.image" class="rounded-circle avatar-xs" :src="`${category.category.image}`" alt /> <img v-else src="/images/banner-placeholder.png" alt="" class="rounded-circle avatar-xs">  <span class="text-dark">{{category.category.name}}</span></a>
					                        </div>
					                       
					                        <div class="d-flex mb-2">
					                        	<a id="tooltip-add" @click="quantity--" class="btn btn-outline-light font-size-16 btn-rounded mr-3 my-auto"><i class="mdi mdi-minus"></i></a>
					                        	<b-form-group label="" class="mx-auto">
													              <b-form-input type="number" v-model="quantity" /></b-form-group>
													           
													            
													            <a id="tooltip-add" @click="quantity++" class="btn btn-outline-light font-size-16 btn-rounded my-auto mr-2"><i class="mdi mdi-plus"></i></a>
					                        	<a class="btn btn-primary my-auto btn-rounded w-25" @click="addToCart(quickviewproduct, quantity)" >Add to Cart</a>
					                        </div>
					                         <div class="w-100">
					                        	<a class="btn btn-primary btn-block btn-rounded">Product Details</a>
					                        </div>
					                      </b-card-body>
					                    </b-col>
					                  </b-row>
					                      </b-card>
          </b-modal>
     </div>
</template>