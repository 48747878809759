<script>
//import Vertical from "./vertical";
import PublicWrapper from "./publicwrapper";
import { EventBus } from "../public-app"


export default {
  components: { PublicWrapper},
  data() {
    return {
      currencykey: process.env.MIX_CURRENCY_CONVERTER,
      recaptcha_token: '',
      selected_currency: '',
      selected_currency_symbol: '',
      exchange_rates: '',
      currencies: [],
      metaInfo: {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: 'Default Title',
      // all titles will be injected into this template
      titleTemplate: '%s | My Awesome Webapp'
    }
    };
  },
  props: {
    user: {
      type: Object
    }
  },
  computed: {
    layoutType() {
        return this.$root.layout?.type;
    },
    getAdminCookies() {
      // Get all cookies and split them into an array
      var cookiesArray = document.cookie.split(';');

      // Loop through the array and get cookies with name 'admin'
      var adminCookies = [];
      for (var i = 0; i < cookiesArray.length; i++) {
        // Split the cookie into its name and value parts
        var cookie = cookiesArray[i].trim();
        var equalsIndex = cookie.indexOf('=');
        var name = cookie.substring(0, equalsIndex);
        var value = cookie.substring(equalsIndex + 1);

        // If the cookie name matches 'admin', add it to the array
        if (name === 'admin_logged') {
          adminCookies.push({ name: name, value: atob(value) });
        }
      }

      // Return the array of admin cookies
      return adminCookies;
    }

  },
  created(){
    if(this.$route.name !== 'Product'){
      this.injectMeta(this.$route.name, this.$route.meta);
    }
    this.getCurrencies();
    var storedcurrency = window.localStorage.getItem("currency");
    if(storedcurrency){
      var parsecurrency = JSON.parse(storedcurrency);
      console.log(parsecurrency["currency_code"]);
      this.convertCurrency(parsecurrency["currency_code"], null, parsecurrency["currency_symbol"]);
    }else if(this.selected_currency == ''){
      this.convertCurrency('GBP', null, '£');
    }
  },
  methods: {
    injectMeta(route_name, route_meta){

      var keys = Object.keys(route_meta);
      for(var i = 0; i < keys.length; i++){
            // Create a new meta tag element
            if((keys[i] !== 'scrollToTop') && (keys[i] !== 'og') && (keys[i] !== 'twitter')){
              if(keys[i] !== 'title'){ 

                // Find the existing meta tag with name="description"
                var existingMeta = document.querySelector('meta[name="'+keys[i]+'"]');

                // If the meta tag with name="description" doesn't exist, create a new one
                if (!existingMeta) {
                  var metaTag = document.createElement('meta');

                  // Set the attributes for the meta tag (example attributes like name and content)
                  metaTag.setAttribute('name', keys[i]);
                  metaTag.setAttribute('content', route_meta[keys[i]]);

                  // Get the head element of the document
                  var head = document.getElementsByTagName('head')[0];

                  // Append the meta tag to the head element
                  head.appendChild(metaTag);
                  
                }else{
                  existingMeta.setAttribute('content', route_meta[keys[i]]);
                }
              }else{
                var titleTag = document.querySelector('title');

                // Set the attributes for the meta tag (example attributes like name and content)
                titleTag.innerText = route_meta[keys[i]];

                // Get the head element of the document
                //var head = document.getElementsByTagName('head')[0];

                // Append the meta tag to the head element
                //head.appendChild(titleTag);
              }
            }else if(keys[i] == 'og'){
              var ogkeys = Object.keys(route_meta["og"]);
              for(var ogi = 0; ogi < ogkeys.length; ogi++ ){

                var existingMeta = document.querySelector('meta[property="og:'+ogkeys[ogi]+'"]');
                if(!existingMeta){
                  var metaTag = document.createElement('meta');

                  // Set the attributes for the meta tag (example attributes like name and content)
                  metaTag.setAttribute('property', 'og:'+ogkeys[ogi]);
                  metaTag.setAttribute('content', route_meta[keys[i]][ogkeys[ogi]]);

                  // Get the head element of the document
                  var head = document.getElementsByTagName('head')[0];

                  // Append the meta tag to the head element
                  head.appendChild(metaTag);
                }else{
                  existingMeta.setAttribute('content', route_meta[keys[i]][ogkeys[ogi]]);
                }
              }
            }else if(keys[i] == 'twitter'){
              var twitterkeys = Object.keys(route_meta["twitter"]);
              for(var tti = 0; tti < twitterkeys.length; tti++ ){

                var existingMeta = document.querySelector('meta[name="twitter:'+twitterkeys[tti]+'"]');
                if(!existingMeta){
                  var metaTag = document.createElement('meta');

                  // Set the attributes for the meta tag (example attributes like name and content)
                  metaTag.setAttribute('name', 'twitter:'+twitterkeys[tti]);
                  metaTag.setAttribute('content', route_meta[keys[i]][twitterkeys[tti]]);

                  // Get the head element of the document
                  var head = document.getElementsByTagName('head')[0];

                  // Append the meta tag to the head element
                  head.appendChild(metaTag);
                }else{
                  existingMeta.setAttribute('content', route_meta[keys[i]][twitterkeys[tti]]);
                }
              }
            }

      }
                var existingMeta = document.querySelector('meta[property="og:url"]');

                // If the meta tag with name="description" doesn't exist, create a new one
                if (!existingMeta) {
                  var metaTag = document.createElement('meta');

                  // Set the attributes for the meta tag (example attributes like name and content)
                  metaTag.setAttribute('property', 'og:url');
                  metaTag.setAttribute('content', 'https://gltrbox.co.uk'+this.$route.path);

                  // Get the head element of the document
                  var head = document.getElementsByTagName('head')[0];

                  // Append the meta tag to the head element
                  head.appendChild(metaTag);
                }else{
                   existingMeta.setAttribute('content', 'https://gltrbox.co.uk'+this.$route.path);
                }
    },
    async recaptcha() {
           // (optional) Wait until recaptcha has been loaded.
          await this.$recaptchaLoaded()

          // Execute reCAPTCHA with action "login".
          const token = await this.$recaptcha('login');
          this.recaptcha_token = token;
          // Do stuff with the received token.
        
        },
      getCurrencies(){
        axios.get('/customer-api/currencies').then(response => {
          this.currencies = response.data;
        }).catch(error => {

        });
      },
      convertCurrency(from, to, symbol){
        axios.get('https://api.freecurrencyapi.com/v1/latest?apikey='+this.currencykey+'&currencies=EUR,USD,GBP&base_currency='+from+'').then(response => {
          this.conversion_rates = response.data;
          EventBus.$emit('currency-updated', symbol, from, response.data);
        }).catch(error => {

        });
    },
  },
  mounted() {
    this.recaptcha();
    /*var currencyItems = document.getElementsByClassName('currency');
      for(var i = 0; i < currencyItems.length; i++){
          document.getElementsByClassName('currency')[i].innerHTML = this.selected_currency_symbol;
      }*/
    var storedcurrency = window.localStorage.getItem("currency");
    if(storedcurrency){
      var parsecurrency = JSON.parse(storedcurrency);
      console.log(parsecurrency["currency_code"]);
      this.convertCurrency(parsecurrency["currency_code"], null, parsecurrency["currency_symbol"]);
    }else if(this.selected_currency == ''){
      this.convertCurrency('GBP', null, '£');
    }else{
       this.convertCurrency(this.selected_currency, null, this.selected_currency_symbol);
    }
    let self = this;
    EventBus.$on('currency-updated', function (symbol, currency, rates) {
      self.selected_currency = currency;
      self.exchange_rates = rates.data;
      self.selected_currency_symbol = symbol; 
      var currencyItems = document.getElementsByClassName('currency');
      /*for(var i = 0; i < currencyItems.length; i++){
          document.getElementsByClassName('currency')[i].innerHTML = symbol;
      }*/
      window.localStorage.setItem("currency", JSON.stringify({currency_code: currency, currency_symbol: symbol}))
    });
    EventBus.$on('inject-meta', function(route, meta) {
      self.injectMeta(route.name, meta);
    });
  },
  watch: {
    $route(){
      this.$nextTick(() => {
        this.recaptcha();
        if(this.$route.name !== 'Product'){
          this.injectMeta(this.$route.name, this.$route.meta);
        }
       /* var currencyItems = document.getElementsByClassName('currency');
        for(var i = 0; i < currencyItems.length; i++){
            document.getElementsByClassName('currency')[i].innerHTML = this.selected_currency_symbol;
        }*/
      });
    }
  }
};
</script>

<template>
  <div>

    <PublicWrapper :user="user" :recaptcha_token="recaptcha_token" :regError="$attrs.regerror" :seller_sessions="getAdminCookies"  :selected_currency="selected_currency" :selected_currency_symbol="selected_currency_symbol" :exchange_rates="exchange_rates" :currencies="currencies">
      <router-view :user="user" :recaptcha_token="recaptcha_token" :seller_sessions="getAdminCookies" :selected_currency="selected_currency" :selected_currency_symbol="selected_currency_symbol" :exchange_rates="exchange_rates" :currencies="currencies"></router-view>
    </PublicWrapper>
        
  </div>
</template>
