<script type="text/javascript">
	import Layout from '../../layouts/main';
	import PageHeader from '../../components/page-header';
	import TradeMarketplaceSellerList from "../../components/widgets/TradeMarketplaceSellerList";

	export default{
		components: {Layout, PageHeader, TradeMarketplaceSellerList},
		data(){
			return {
				  title: 'Trade Directory',
			      items: [
			        {
			          text: 'Trade',
			          href: '/',
			        },
			        {
			          text: 'Directory',
			          active: true,
			        },
			      ],
			      alphabet: ['a','b','c','d','e','f','g','h','i','j','k','l','m','n','o','p','q','r','s','t','u','v','w','x','y','z'],
			      selectedalpha: '',
			      expanded: 1,
			      data: '',
			      mapcenter: { lat: 54, lng: 2 },
				mapzoom: 5,
				company_types: [],
				search_area: {

				},
				location_search_type: 'Location',
				countries: [],
				counties: [],
				selected_county: '',
				selected_county_id: '',
				selected_country: '',
				selected_country_id: '',
					productsLoading: true,
					business_type: '',
					showFilters: false,
			        sliderPrice: 800,
			        priceRange: [0,250000],
			        brands: [],
			        collections: [],
			        categories: [],
			        sortby: 'created_at',
			        order: 'desc',
			        search: '',
			        view: 'grid',
			        public_active: 1,
			        trade_active: '',
			        active: '',
			        expanded: 1,
			        currentPage: 1,
			        listing_types: [],
			        query: '',
			        limit: 25,
					limits: [
					        {value: 25, text: "25"},
					        {value: 50, text: "50"},
					        {value: 100, text: "100"},
					      ],
			}
		},
		
		mounted(){
			//this.getListingTypes();
			this.loadSellers();
			this.getCompanyTypes();
			this.getCountries();
			var url = window.location.href;
    	if(url.search("type=") > 0){
              var uid = url.substring(url.lastIndexOf("=") + 1);
              this.business_type = uid;
            }
		},
		methods: {
			selectCounty(event){
				this.selected_county = event;
				this.selected_county_id = event.id;
			},
			getCountries(){
        if(this.countries.length == 0){
          axios.get('/api/countries', {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
              }}).then(response => {
            this.countries = response.data;
          }).catch(error => {
            this.load_error = error.data;
          })
        }
      },
      getCounties(country){
      	if(country){
      		this.selected_country_id = country.id;
      	}else{
      		this.selected_country_id = '';
      	}
        // get counties as country selected
        axios.get('/api/country/'+country.id+'/counties', {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
              }}).then(response => {
          this.counties = response.data;
        }).catch(error => {
          this.load_error = error.data;
        })
      },
			getCompanyTypes(){
	      	axios.get('/api/company/types', {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
	          this.company_types = response.data;
	        }).catch(error => {
	          this.load_error = error.data;
	        })
	      },
			loadSellers(){
				this.createQueryString();
				this.productsLoading = true;
				//console.log(this.$route.path);
				axios.get('/api/company/trade/directory'+this.query, {headers: {
					'Authorization' : 'Bearer '+this.$attrs.user.api_token 
				}}).then(response => {
					this.data = response.data;
				}).catch(error => {
					console.log(error);
				});
				var self = this;
                setTimeout(function(){
	                self.productsLoading = false;                
                }, 500);
			},
			getListingTypes(){
      axios.get('/api/company/listing/types', {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
              }}).then(response => {
      this.listing_types = response.data;
      }).catch(error => {
        this.shops_error = error.data;
      });
    },
			createQueryString(){
				this.query = '?page='+this.currentPage+'&limit='+this.limit+'&sortby='+this.sortby+'&order='+this.order+'&business_type='+this.business_type+'&country_id='+this.selected_country_id+'&county_id='+this.selected_county_id+'&alphabet='+this.selectedalpha+'&search='+this.search+'&brands='+this.brands+'&collections='+this.collections+'&categories='+this.categories+'&pricerange='+this.priceRange+'';
			},
		}
	}

</script>

<template>
	<Layout :nickname="$attrs.nickname" :user="$attrs.user" :company="$attrs.company"  :selected_currency="$attrs.selected_currency" :selected_currency_symbol="$attrs.selected_currency_symbol" :exchange_rates="$attrs.exchange_rates">
		  <PageHeader :title="title" :items="items" />
		  <div class="bg-light w-100 directory-map"> <gmap-map :center="mapcenter" :zoom="mapzoom" style="height: 560px">
             <gmap-marker
                v-for="(shopslisting, index) in data.data"
                :key="index"
                v-if="shopslisting.gplace_id !== null"
                :position="{lat: parseFloat(shopslisting.latitude), lng: parseFloat(shopslisting.longitude)}"
                :clickable="true"
                :draggable="true"
                @click="mapcenter = {lat: parseFloat(shopslisting.latitude), lng: parseFloat(shopslisting.longitude)}, mapzoom = 11"
              ></gmap-marker>
            </gmap-map> </div>
		  <b-container fluid class="directory-content">
		  	<b-row>
		  		<b-col md="12" class="px-0">
					
				</b-col>
		  		<b-col md="8" class="mx-auto mt-4 directory-search">
		  			 <div class="input-group">
                                <div class="search-box" style="flex-grow: 3">
                                  <div class="position-relative">
                                    <input class="form-control" type="text" v-model="search" @keydown.enter="loadSellers()" style="border: 1px solid #eff2f7">
                                    <i class="bx bx-search-alt search-icon"></i>
                                    <a v-b-tooltip.hover title="Reset" placement="top" href="javascript:;" @click="search = loadSellers()">
                                      <i class="bx bx-reset search-icon reset"></i>
                                    </a>
                                  </div>
                                </div>
                                <div class="pl-2">
                                    <a href="javascript:;" class="btn btn-rounded btn-primary btn-block" @click="loadSellers()">Search</a>
                                </div>
                             </div>

                     <b-row class="mt-3">
                     	<b-col>
                     		<b-input-group prepend="Business Type" class="border-curve-left">
					     		<b-form-select class="form-control border btn-rounded px-3 text-uppercase" v-model="business_type" @input="loadSellers()">
					     						<b-form-select-option value="">
					     							-- None Selected --
					     						</b-form-select-option>
                       		<b-form-select-option v-for="type in company_types" :value="type.name">
                       			{{type.name}}
                       		</b-form-select-option>	
                      		</b-form-select>
                      		</b-input-group>
                     	</b-col>
                     		<b-col>
                     		<b-input-group prepend="Country" class="border-curve-left">
					     		<b-form-select class="form-control border btn-rounded px-3 text-uppercase" v-model="selected_country" @input="getCounties($event), loadSellers()">
					     						<b-form-select-option value="">
					     							-- None Selected --
					     						</b-form-select-option>
                       		<b-form-select-option v-for="country in countries" :value="country">
                       			{{country.name}}
                       		</b-form-select-option>	
                      		</b-form-select>
                      		</b-input-group>
                     	</b-col>
                     	<b-col>
                     		<b-input-group prepend="State/County" class="border-curve-left" >
					     		<b-form-select class="form-control border btn-rounded px-3 text-uppercase" v-model="selected_county" @input="selectCounty($event), loadSellers()">
					     						<b-form-select-option value="">
					     							-- None Selected --
					     						</b-form-select-option>
                       		<b-form-select-option v-if="counties.length > 0" v-for="county in counties" :value="county">
                       			{{county.name}}
                       		</b-form-select-option>	
                      		</b-form-select>
                      		</b-input-group>
                     	</b-col>
                     	<!--<b-col>
                     		<b-input-group>
                     			<template #prepend>
							      <b-dropdown :text="location_search_type" variant="light">
							        <b-dropdown-item @click="location_search_type = 'Country'">Country</b-dropdown-item>
			
							        <b-dropdown-item @click="location_search_type = 'Postcode'">Postcode/Zip</b-dropdown-item>
							      </b-dropdown>
							  </template>
                     		<b-form-select class="form-control border btn-rounded px-3 text-uppercase" :options="[]" @input="">
                       
                      		</b-form-select>
                      		</b-input-group>
                     	</b-col>-->
                     	
                     </b-row>
		  		</b-col>
		  		<b-col md="12" class="mt-4 text-center mx-auto">
            	
            		<a class="btn btn-outline-light text-uppercase" v-for="alpha in alphabet" @click="selectedalpha = alpha, loadSellers()" :class="{'active': selectedalpha == alpha}">{{alpha}}</a>
            			<a class="btn btn-outline-light text-uppercase" @click="selectedalpha = '', loadSellers()" :class="{'active': selectedalpha == alpha}">Reset</a>
          		</b-col>
		  	</b-row>
		  	<b-row>
		  		<b-col md="12" class="my-4">
		  			<TradeMarketplaceSellerList :product_list="data.data" :expanded="expanded" :layouttoggle="false" :view="'grid'" :marketplace="'public'" ></TradeMarketplaceSellerList>
		  		</b-col>
		  	</b-row>
		  </b-container>

	</Layout>
</template>