const socialProfiles = [
		{name: 'Facebook', image: 'https://www.facebookbrand.com/wp-content/uploads/2019/04/f_logo_RGB-Hex-Blue_512.png'},
		{name: 'Instagram', image: 'https://www.instagram.com/static/images/ico/favicon-192.png/68d99ba29cc8.png'},
		{name: 'Twitter', image: 'https://cdn.icon-icons.com/icons2/836/PNG/512/Twitter_icon-icons.com_66803.png'},
		{name: 'YouTube', image: '/images/social/yt_logo_rgb_light.png'},
		{name: 'TikTok', image: 'https://www.tiktok.com/favicon.ico'},
		{name: 'LinkedIn', image: '/images/social/Linkedin-sq.png'},
		{name: 'Pinterest', image: '/images/social/pinterest.jpg'},
		//{name: 'Snapchat', image: 'https://www.snapchat.com/favicon.ico'},
		//{name: 'Reddit', image: 'https://www.redditinc.com/assets/images/site/reddit-logo.png'},
		{name: 'WhatsApp', image: '/images/social/whatsapp.png'},
	];

export { socialProfiles };