<script>
import simplebar from "simplebar-vue";
import i18n from "../i18n";
import { EventBus } from '../app'

export default {
  components: {
    simplebar,
  },
  data() {
    return {
      currencykey: process.env.MIX_CURRENCY_CONVERTER,
      languages: [
        {
          flag: "/images/flags/uk.jpg",
          language: "en",
          title: "English",
        },
        {
          flag: "/images/flags/french.jpg",
          language: "fr",
          title: "French",
        },
        {
          flag: "/images/flags/spain.jpg",
          language: "es",
          title: "Spanish",
        },
        {
          flag: "/images/flags/chaina.png",
          language: "zh",
          title: "Chinese",
        },
        {
          flag: "/images/flags/arabic.png",
          language: "ar",
          title: "Arabic",
        },
      ],
      cart: {
        products: [],
        uid: '',
        id: '',
      },
      cartLoading: false,
      initiateCheckout: false,
      cartPanelData: [],
      cartPanel: false,
      showCart: false,
      storageurl: '/trade-image',
      currency: '',
      currencyicon: 'bx bx-pound',
      currencytext: 'GBP',
      cartLoading: false,
      showSearch: false,
      lan: i18n.locale,
      text: null,
      flag: null,
      value: null,
    };
  },
  props: {
    user: {
      type: Object,
      default: () => {api_token: ''}
    },
    company: {
      type: Object,
      default: () => {}
    },
    isTrade: {
      type: Boolean,
      default: false,
    },
    currencies: {
      type: Array,
      default: [],
    }
  },
  created() {

     var session_cart = window.sessionStorage.getItem("tradecart");
    session_cart = JSON.parse(session_cart);
    if(session_cart == null){
      this.checkAndGetCart();
    }else{
      this.cart = session_cart;
    }
    var currency = window.localStorage.getItem("currency");
    if(currency){
      var parsecurrency = JSON.parse(currency);
      
      this.currencycode = parsecurrency["currency_code"];
      this.currencytext = parsecurrency["currency_code"];
      if(parsecurrency["currency_code"] == 'USD'){
        this.currencyicon = 'bx bx-dollar';
      }
      if(parsecurrency["currency_code"] == 'EUR'){
        this.currencyicon = 'bx bx-euro';
      }
    }
  },
  mounted() {
    this.value = this.languages.find((x) => x.language === i18n.locale);
    this.text = this.value.title;
    this.flag = this.value.flag;

    let self = this;
     EventBus.$on('add-to-cart', function(product, qty, variants, combination){
      console.log('Cart Event Triggered');
       self.addToCart(product, qty, variants, combination);
    });
    EventBus.$on('cart-cleared', function(result){
      self.cart = { products: [], uid: '', id: ''};
    });
    EventBus.$on('update-cart', function(cartdata){
       self.cart = cartdata;
    });
  },
  methods: {
    convertCurrency(from, to, symbol){
        axios.get('https://api.freecurrencyapi.com/v1/latest?apikey='+this.currencykey+'&currencies=EUR,USD,GBP&base_currency='+from+'').then(response => {
          this.conversion_rates = response.data;
          EventBus.$emit('currency-updated', symbol, from, response.data);
        }).catch(error => {

        });
    },
    updateCurrency(symbol, old_currency, new_currency){
      //EventBus.$emit('currency-updated', old_currency, new_currency);
      this.convertCurrency(new_currency, null, symbol);
    },
    destroyCart(uid){
            axios.post('/api/company/trade/cart/destroy', {cartuid: uid }, {
              headers: {
                'Authorization' : 'Bearer '+this.user.api_token
              }
            }).then(response => {
              if(response.data == true){
                this.cart =  { products: [], uid: '', id: ''};
                window.sessionStorage.removeItem("tradecart");
              }
            }).catch(error => {
              
            });
    },
    checkAndGetCart(){
      axios.get('/api/company/trade/cart/read?cart_uid='+this.cart.uid, {headers: {
                  'Authorization': 'Bearer ' + this.user.api_token
              }}).then(response => {
        console.log(response);
          if(response.data !== 0){
            this.cart = response.data;
            window.sessionStorage.setItem("tradecart", JSON.stringify(response.data));
          }else{
            window.sessionStorage.setItem("tradecart", JSON.stringify(this.cart))
          }
        
      }).catch(error => {
        this.error_reponse = error.response.data.errors;
      });
    },
    cartTotal(cart){
          var score = 0;
          if(cart.products.length > 1){
            for(var i = 0; i < cart.products.length; i++){
              var itemscore = (parseFloat(cart.products[i].price_exc) * parseFloat(cart.products[i].quantity)) * (1 / this.$attrs.exchange_rates[cart.products[i].company.trading_currency_code]);
              score = score + itemscore;
            }
          }else{
            score = (cart.products[0].price_exc * cart.products[0].quantity)  * (1 / this.$attrs.exchange_rates[cart.products[0].company.trading_currency_code]);
          }
          return (score).toFixed(2);
        },
    addToCart(productdata, qty, variantsdata, combinationdata){
      this.cartLoading = true;
      if(this.cart.uid == ''){
        this.cart.products.push({product: productdata});
        this.createCart(productdata, qty, variantsdata, combinationdata);
      }else{
        axios.post('/api/company/trade/cart/'+this.cart.uid+'/add-product', {product: productdata, quantity: qty, variants: variantsdata, combination: combinationdata}, {headers: {
                  'Authorization': 'Bearer ' + this.user.api_token
              }}).then(response => {
        var carindex = this.cart.products.findIndex(item => item.product_id == productdata.id);
            if(carindex !== -1){
              this.cart.products[carindex] = response.data;
              this.cart.products[carindex]["product"] = productdata;
              this.cart.products[carindex]["company"] = productdata["company"];
              window.sessionStorage.setItem("tradecart", JSON.stringify(this.cart));
              this.productAdded(productdata);
            }else if(carindex == -1){
              this.cart["products"].push(response.data);
              this.cart["products"][(this.cart.products.length - 1)]["product"] = productdata;
               this.cart.products[(this.cart.products.length - 1)]["company"] = productdata["company"];
              window.sessionStorage.setItem("tradecart", JSON.stringify(this.cart));
              this.productAdded(productdata);
            }
      }).catch(error => {
        this.error_reponse = error.response.data.errors;
      });
      } 
    },
    removeProduct(id, index){
      axios.post('/api/company/trade/cart/product/'+id+'/delete', {}, {headers: {
                  'Authorization': 'Bearer ' + this.user.api_token
              }}).then(response => {
          this.cart.products.splice(index, 1);
          window.sessionStorage.setItem("tradecart", JSON.stringify(this.cart));
        }).catch(error => {
          this.error_reponse = error.response.data.errors;
        });
    },
    productAdded(product){
      //this.cartPanelData = product;
      //this.cartPanel = true;
      this.cartLoading = false;
      this.$refs.cart.show(true);
    },
    createCart(productdata, qty, variantsdata, combinationdata){
      axios.post('/api/company/trade/cart/create', {product: productdata, quantity: qty, variants: variantsdata, combination: combinationdata}, {headers: {
                  'Authorization': 'Bearer ' + this.user.api_token
              }}).then(response => {
        this.cart = response.data[0];
        this.cart["products"] = [];
        this.cart.products.push(response.data[1]);
        this.cart.products[0]["product"] = productdata;
        this.cart.products[0]["company"] = productdata.company;
        window.sessionStorage.setItem("tradecart", JSON.stringify(this.cart));
        this.productAdded(null);
      }).catch(error => {

      });
    },
    toggleRightSidebar() {
      this.$parent.toggleRightSidebar();
    },
    toggleMenu() {
      let element = document.getElementById("topnav-menu-content");
      element.classList.toggle("show");
    },
    initFullScreen() {
      document.body.classList.toggle("fullscreen-enable");
      if (
        !document.fullscreenElement &&
        /* alternative standard method */ !document.mozFullScreenElement &&
        !document.webkitFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(
            Element.ALLOW_KEYBOARD_INPUT
          );
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    },
    setLanguage(locale, country, flag) {
      this.lan = locale;
      this.text = country;
      this.flag = flag;
      i18n.locale = locale;
      localStorage.setItem("locale", locale);
    },
    setCurrency(code, title, currencyicon, symbol) {
      this.updateCurrency(symbol, this.currencycode, code);
      this.currencycode = code;
      this.currencytext = code;
      this.currencyicon = currencyicon;
      //i18n.locale = locale;

      //localStorage.setItem("currency", locale);
    },
  },
};
</script>

<template>
  <header id="page-topbar">
    <div class="navbar-header">
      <div class="d-flex">
        <!-- LOGO -->
        <div class="navbar-brand-box">
          <a href="/" class="logo logo-dark">
             <span class="logo-sm">
              <div class="cube-wrapper float-left"><div class="cube-folding"><span class="leaf1"></span><span class="leaf2"></span><span class="leaf3"></span><span class="leaf4"></span></div></div>
            </span>
            <span class="logo-lg">
              <div class="cube-wrapper float-left"><div class="cube-folding"><span class="leaf1"></span><span class="leaf2"></span><span class="leaf3"></span><span class="leaf4"></span></div></div>
              <div class="d-flex logo-text-holder"><span class="logo-text text-dark">Gemesys <small  v-if="isTrade == 'true'" class="extra-small d-block mt-1">TRADE</small></span></div>
            </span>
          </a>

          <a href="/" class="logo logo-light">
            <span class="logo-sm">
              <div class="cube-wrapper float-left"><div class="cube-folding"><span class="leaf1"></span><span class="leaf2"></span><span class="leaf3"></span><span class="leaf4"></span></div></div>
            </span>
            <span class="logo-lg">
              <div class="cube-wrapper float-left"><div class="cube-folding"><span class="leaf1"></span><span class="leaf2"></span><span class="leaf3"></span><span class="leaf4"></span></div></div>
              <div class="d-flex logo-text-holder"><span class="logo-text text-white">Gemesys <small v-if="isTrade == 'true'" class="extra-small d-block mt-1">TRADE</small></span></div>
            </span>
          </a>
        </div>

        <button
          id="toggle"
          type="button"
          class="btn btn-sm me-2 font-size-16 d-lg-none header-item"
          @click="toggleMenu"
        >
          <i class="fa fa-fw fa-bars"></i>
        </button>

        <!-- App Search
        <form class="app-search d-none d-lg-block">
          <div class="position-relative">
            <input
              type="text"
              class="form-control"
              :placeholder="$t('navbar.search.text')"
            />
            <span class="bx bx-search-alt"></span>
          </div>
        </form>-->
        <!--
        <b-dropdown
          variant="black"
          class="dropdown-mega d-none d-lg-block ml-2"
          toggle-class="header-item"
          menu-class="dropdown-megamenu dropdown-menu-end"
        >
          <template v-slot:button-content>
            {{ $t("navbar.dropdown.megamenu.text") }}
            <i class="mdi mdi-chevron-down"></i>
          </template>

          <div class="row">
            <div class="col-sm-8">
              <div class="row">
                <div class="col-md-4">
                  <h5 class="font-size-14 mt-0">
                    {{ $t("navbar.dropdown.megamenu.uicontent.title") }}
                  </h5>
                  <ul class="list-unstyled megamenu-list">
                    <li>
                      <a href="javascript:void(0);">{{
                        $t("navbar.dropdown.megamenu.uicontent.list.lightbox")
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        $t(
                          "navbar.dropdown.megamenu.uicontent.list.rangeslider"
                        )
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        $t("navbar.dropdown.megamenu.uicontent.list.sweetalert")
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        $t("navbar.dropdown.megamenu.uicontent.list.rating")
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        $t("navbar.dropdown.megamenu.uicontent.list.forms")
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        $t("navbar.dropdown.megamenu.uicontent.list.tables")
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        $t("navbar.dropdown.megamenu.uicontent.list.charts")
                      }}</a>
                    </li>
                  </ul>
                </div>

                <div class="col-md-4">
                  <h5 class="font-size-14 mt-0">
                    {{ $t("navbar.dropdown.megamenu.application.title") }}
                  </h5>
                  <ul class="list-unstyled megamenu-list">
                    <li>
                      <a href="javascript:void(0);">{{
                        $t(
                          "navbar.dropdown.megamenu.application.list.ecommerce"
                        )
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        $t("navbar.dropdown.megamenu.application.list.calendar")
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        $t("navbar.dropdown.megamenu.application.list.email")
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        $t("navbar.dropdown.megamenu.application.list.projects")
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        $t("navbar.dropdown.megamenu.application.list.tasks")
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        $t("navbar.dropdown.megamenu.application.list.contacts")
                      }}</a>
                    </li>
                  </ul>
                </div>

                <div class="col-md-4">
                  <h5 class="font-size-14 mt-0">
                    {{ $t("navbar.dropdown.megamenu.extrapages.title") }}
                  </h5>
                  <ul class="list-unstyled megamenu-list">
                    <li>
                      <a href="javascript:void(0);">{{
                        $t(
                          "navbar.dropdown.megamenu.extrapages.list.lightsidebar"
                        )
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        $t(
                          "navbar.dropdown.megamenu.extrapages.list.compactsidebar"
                        )
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        $t(
                          "navbar.dropdown.megamenu.extrapages.list.horizontallayout"
                        )
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        $t(
                          "navbar.dropdown.megamenu.extrapages.list.maintenance"
                        )
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        $t(
                          "navbar.dropdown.megamenu.extrapages.list.comingsoon"
                        )
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        $t("navbar.dropdown.megamenu.extrapages.list.timeline")
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        $t("navbar.dropdown.megamenu.extrapages.list.faqs")
                      }}</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="row">
                <div class="col-sm-6">
                  <h5 class="font-size-14 mt-0">
                    {{ $t("navbar.dropdown.megamenu.uicontent.title") }}
                  </h5>
                  <ul class="list-unstyled megamenu-list">
                    <li>
                      <a href="javascript:void(0);">{{
                        $t("navbar.dropdown.megamenu.uicontent.list.lightbox")
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        $t(
                          "navbar.dropdown.megamenu.uicontent.list.rangeslider"
                        )
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        $t("navbar.dropdown.megamenu.uicontent.list.sweetalert")
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        $t("navbar.dropdown.megamenu.uicontent.list.rating")
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        $t("navbar.dropdown.megamenu.uicontent.list.forms")
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        $t("navbar.dropdown.megamenu.uicontent.list.tables")
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        $t("navbar.dropdown.megamenu.uicontent.list.charts")
                      }}</a>
                    </li>
                  </ul>
                </div>

                <div class="col-sm-5">
                  <div>
                    <img
                      src="/images/megamenu-img.png"
                      alt
                      class="img-fluid mx-auto d-block"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-dropdown>
      -->
      </div>

      <div class="d-flex">
        <b-dropdown
          class="d-inline-block d-lg-none ml-2"
          variant="black"
          menu-class="dropdown-menu-lg p-0 dropdown-menu-end"
          toggle-class="header-item noti-icon"
          right
        >
          <template v-slot:button-content>
            <i class="mdi mdi-magnify"></i>
          </template>

          <form class="p-3">
            <div class="form-group m-0">
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Search ..."
                  aria-label="Recipient's username"
                />
                <div class="input-group-append">
                  <button class="btn btn-primary" type="submit">
                    <i class="mdi mdi-magnify"></i>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </b-dropdown>

        <!--<b-dropdown variant="white" right toggle-class="header-item">
          <template v-slot:button-content>
            <img class :src="flag" alt="Header Language" height="16" />
            {{ text }}
          </template>
          <b-dropdown-item
            class="notify-item"
            v-for="(entry, i) in languages"
            :key="`Lang${i}`"
            :value="entry"
            @click="setLanguage(entry.language, entry.title, entry.flag)"
            :class="{ active: lan === entry.language }"
          >
            <img
              :src="`${entry.flag}`"
              alt="user-image"
              class="me-1"
              height="12"
            />
            <span class="align-middle">{{ entry.title }}</span>
          </b-dropdown-item>
        </b-dropdown>-->
        <transition name="fade-right">
         <form class="app-search d-none d-lg-block ml-auto w-50" v-if="showSearch && isTrade == 'true'" >
          <div class="position-relative">
            <input
              type="text"
              class="form-control"
              :placeholder="$t('navbar.search.text')"
            />
            <span class="bx bx-search-alt"></span>
          </div>
        </form>
        </transition>
        <b-dropdown
         v-if="isTrade == false"
          class="d-none d-lg-inline-block noti-icon"
          menu-class="dropdown-menu-lg calculators-menu dropdown-menu-end"
          right
          toggle-class="header-item"
          variant="black"
        >
          <template v-slot:button-content>
            <i class="bx bx-calculator"></i>
          </template>

          <div class="px-lg-2">
            <div class="row no-gutters">
              <div class="col">
                <a class="dropdown-icon-item" href="javascript: void(0);" @click="$emit('showCalculatorWindow', 0)">
                  
                  <object type="image/svg+xml" data="/images/diamonds/Diamonds_Princess%20Outlined.svg" class="logo">
                     <!-- fallback image in CSS -->
                    </object>
                  <span>Gemstone</span>
                </a>
              </div>
              <div class="col">
                <a class="dropdown-icon-item" href="javascript: void(0);"  @click="$emit('showCalculatorWindow', 1)">
                  <img src="/images/brands/bitbucket.png" alt="bitbucket" />
                  <span>Diamond</span>
                </a>
              </div>
              <div class="col">
                <a class="dropdown-icon-item" href="javascript: void(0);"  @click="$emit('showCalculatorWindow', 2)">
                  <img src="/images/brands/dribbble.png" alt="dribbble" />
                  <span>Cabochon</span>
                </a>
              </div>
            </div>

            <div class="row no-gutters">
              <div class="col">
                <a class="dropdown-icon-item" href="javascript: void(0);"  @click="$emit('showCalculatorWindow', 3)">
                  <img src="/images/brands/dropbox.png" alt="dropbox" />
                  <span>Specific Gravity</span>
                </a>
              </div>
              <div class="col">
                <a class="dropdown-icon-item" href="javascript: void(0);"  @click="$emit('showCalculatorWindow', 4)">
                  <img src="/images/brands/mail_chimp.png" alt="mail_chimp" />
                  <span>Crown Angle</span>
                </a>
              </div>
              <div class="col">
                <a class="dropdown-icon-item" href="javascript: void(0);"  @click="$emit('showCalculatorWindow', 5)">
                  <img src="/images/brands/slack.png" alt="slack" />
                  <span>Pavillion Angle</span>
                </a>
              </div>
            </div>
          </div>
        </b-dropdown>
        <b-dropdown  v-if="isTrade == false"
          class="d-none d-lg-inline-block noti-icon"
          menu-class="dropdown-menu-lg dropdown-menu-end"
          right
          toggle-class="header-item"
          variant="black"
        >
          <template v-slot:button-content>
            <i class="bx bx-customize"></i>
          </template>

          <div class="px-lg-2">
            <div class="row no-gutters">
              <div class="col">
                <a class="dropdown-icon-item" href="javascript: void(0);">
                  <img src="/images/brands/github.png" alt="Github" />
                  <span>{{ $t("navbar.dropdown.site.list.github") }}</span>
                </a>
              </div>
              <div class="col">
                <a class="dropdown-icon-item" href="javascript: void(0);">
                  <img src="/images/brands/bitbucket.png" alt="bitbucket" />
                  <span>{{ $t("navbar.dropdown.site.list.github") }}</span>
                </a>
              </div>
              <div class="col">
                <a class="dropdown-icon-item" href="javascript: void(0);">
                  <img src="/images/brands/dribbble.png" alt="dribbble" />
                  <span>{{ $t("navbar.dropdown.site.list.dribbble") }}</span>
                </a>
              </div>
            </div>

            <div class="row no-gutters">
              <div class="col">
                <a class="dropdown-icon-item" href="javascript: void(0);">
                  <img src="/images/brands/dropbox.png" alt="dropbox" />
                  <span>{{ $t("navbar.dropdown.site.list.dropbox") }}</span>
                </a>
              </div>
              <div class="col">
                <a class="dropdown-icon-item" href="javascript: void(0);">
                  <img src="/images/brands/mail_chimp.png" alt="mail_chimp" />
                  <span>{{ $t("navbar.dropdown.site.list.mailchimp") }}</span>
                </a>
              </div>
              <div class="col">
                <a class="dropdown-icon-item" href="javascript: void(0);">
                  <img src="/images/brands/slack.png" alt="slack" />
                  <span>{{ $t("navbar.dropdown.site.list.slack") }}</span>
                </a>
              </div>
            </div>
          </div>
        </b-dropdown>

        <div v-if="isTrade == false" class="dropdown d-none d-lg-inline-block ml-1">
          <button
            type="button"
            class="btn header-item noti-icon"
            @click="initFullScreen"
          >
            <i class="bx bx-fullscreen"></i>
          </button>
        </div>
        <div  v-if="isTrade == false" class="dropdown d-none d-lg-inline-block ms-1">
          <button type="button" class="btn header-item noti-icon" @click="$emit('showMetalPricing')">
            <i class="bx bx-line-chart"></i>
          </button>
        </div>


         <div class="dropdown d-none d-lg-inline-block ml-1" v-if="isTrade == 'true'" >
          <button
            type="button"
            class="btn header-item noti-icon"
            @click="showSearch = !showSearch"
          >
            <i class="bx bx-search"></i>
          </button>
        </div>
        <b-dropdown
          variant="white" right toggle-class="header-item noti-icon"
           v-if="isTrade == 'true'"
        >
          <template v-slot:button-content>
            <i :class="`${currencyicon}`" :alt="'Pay in ' + currency" ></i>
          </template>

          <b-dropdown-item
            class="notify-item"
            v-for="(entry, i) in currencies"
            :key="`Lang${i}`"
            :value="entry"
            @click="setCurrency(entry.code, entry.name, entry.icon, entry.symbol)"
            :class=" {'active' : lan === entry.language}"
          >
            <i :class="`${entry.icon}`" :alt="'Pay in ' + entry.name" class="me-1" ></i>
            <span class="align-middle">{{ entry.name }}</span>
          </b-dropdown-item>
        </b-dropdown>

        <b-dropdown

          right
          menu-class="dropdown-menu-lg p-0 dropdown-menu-end cart-dropdown"
          toggle-class="header-item noti-icon"
          variant="black"
          ref="cart"
        >
          <template v-slot:button-content>
            <i class="bx bx-shopping-bag"></i>
            <span
              class="badge bg-danger rounded-pill"
            >{{cart.products.length}}</span>
          </template>

          <div class="p-3">
            <div class="row align-items-center">
              <div class="col">
                <h6 class="m-0">Shopping Cart</h6>
              </div>
              <div class="col-auto">
                <a v-if="cart.products.length > 0" v-b-modal.delete-cart class="small">Delete Cart</a>
              </div>
            </div>
          </div>
          <simplebar style="max-height: 530px;">
          <b-list-group v-if="cartLoading == false">
            <b-list-group-item  v-for="(productitem, index) in cart.products" href="javascript: void(0);" class="text-reset notification-item px-0 py-3 m-0">
              <div class="media">
                <div class="avatar-sm me-3" v-if="productitem.product.cover_image !== null">
                  <b-img-lazy v-if="'thumb'  in productitem.product.cover_image && productitem.product.cover_image.thumb !== null" :src="storageurl + productitem.product.cover_image.thumb + '?s=' + productitem.company.nickname" class="avatar-title rounded-circle" />
                  <b-img-lazy v-else-if="'gallery'  in productitem.product.cover_image && productitem.product.cover_image.gallery !== null" :src="storageurl + productitem.product.cover_image.gallery + '?s=' + productitem.company.nickname" class="avatar-title rounded-circle" />
                </div>
                  <div class="avatar-sm me-3" v-else>
                  <span  class="avatar-title bg-primary rounded-circle font-size-16" >
                    <i class="bx bx-cart"></i>
                  </span>
                </div>
                <div class="media-body my-auto flex-grow-10">
                  <h6 class="mt-0 mb-1">{{productitem.product.name}}</h6>
                </div>
                <div class="my-auto flex-grow-2 text-right d-flex flex-column">
                  <i class="bx bx-chevron-up mb-auto mx-auto"></i>
                  <b-form-input type="number"  v-model="productitem.quantity" class="visible mx-auto my-auto" style="width: 25%;">
                  </b-form-input>
                  <span class="mx-auto my-auto">{{productitem.quantity}}</span>
                  <i class="bx bx-chevron-down mt-auto mx-auto"></i>
                </div>
                <div class="my-auto flex-grow-2 text-center">
                  <strong class="text-primary mr-2">{{$attrs.selected_currency_symbol}}</strong>{{(productitem.price_exc * (1 / $attrs.exchange_rates[productitem.company.trading_currency_code])).toFixed(2)}}
                </div>
                <div class="my-auto flex-grow-2 text-center">
                  <strong class="text-primary mr-2">{{$attrs.selected_currency_symbol}}</strong>{{((productitem.price_exc * productitem.quantity) * (1 / $attrs.exchange_rates[productitem.company.trading_currency_code])).toFixed(2)}}
                </div>
                <div class="my-auto flex-grow-1 text-center">
                 <a class="btn btn-outline-light btn-rounded" @click="removeProduct(productitem.id, index)"><i class="bx bx-trash-alt"></i></a>
                </div>
              </div>
            </b-list-group-item>
            <b-list-group-item v-if="cart.products.length == 0">
              <div class="p-5 text-center border">
                <i class="bx bx-shopping-bag font-size-42 text-secondary mb-2"></i>
                <h6>No Products in Cart</h6>
              </div>
            </b-list-group-item>
          </b-list-group>
            

          </simplebar>
          <b-overlay
            :show="cartLoading">
              <div class="p-4 border-top d-flex bg-light" v-if="(cart.products.length !== 0) && (cartLoading == false)">
                <strong class="mr-auto flex-grow-2"> Total: </strong>
                  <span class="mx-auto flex-grow-1 text-center"><strong class="text-primary mr-2">£</strong> {{cartTotal(cart)}}</span>
              </div>
          </b-overlay>
         <div class="p-2 border-top d-grid" v-if="cart.products.length !== 0">
            <a class="btn btn-sm btn-link font-size-14 text-center btn-primary text-white" href="/trade/order" >
              <i class=" me-1"></i>
              <span key="t-view-more"> Checkout Now </span>
            </a>
          </div>
        </b-dropdown>
        <b-dropdown
          v-if="isTrade == false"
          right
          menu-class="dropdown-menu-lg p-0 dropdown-menu-end"
          toggle-class="header-item noti-icon"
          variant="black"
        >
          <template v-slot:button-content>
            <i class="bx bx-bell bx-tada"></i>
            <span class="badge bg-danger rounded-pill">0</span>
          </template>

          <div class="p-3">
            <div class="row align-items-center">
              <div class="col">
                <h6 class="m-0">
                  {{ $t("navbar.dropdown.notification.text") }}
                </h6>
              </div>
              <div class="col-auto">
                <a href="#" class="small">{{
                  $t("navbar.dropdown.notification.subtext")
                }}</a>
              </div>
            </div>
          </div>
          <!--<simplebar style="max-height: 230px">
            <a href="javascript: void(0);" class="text-reset notification-item">
              <div class="media">
                <div class="avatar-xs me-3">
                  <span
                    class="avatar-title bg-primary rounded-circle font-size-16"
                  >
                    <i class="bx bx-cart"></i>
                  </span>
                </div>
                <div class="media-body">
                  <h6 class="mt-0 mb-1">
                    {{ $t("navbar.dropdown.notification.order.title") }}
                  </h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">
                      {{ $t("navbar.dropdown.notification.order.text") }}
                    </p>
                    <p class="mb-0">
                      <i class="mdi mdi-clock-outline"></i>
                      {{ $t("navbar.dropdown.notification.order.time") }}
                    </p>
                  </div>
                </div>
              </div>
            </a>
          </simplebar>-->
          <div class="p-2 border-top d-grid">
            <a
              class="btn btn-sm btn-link font-size-14 text-center"
              href="javascript:void(0)"
            >
              <i class="mdi mdi-arrow-down-circle me-1"></i>
              <span key="t-view-more">
                {{ $t("navbar.dropdown.notification.button") }}
              </span>
            </a>
          </div>
        </b-dropdown>

        <b-dropdown
          right
          variant="black"
          toggle-class="header-item"
          menu-class="dropdown-menu-end"
        >
          <template v-slot:button-content >
            <img v-if="user.avatar !== null"
              class="rounded-circle header-profile-user"
              src="/images/users/avatar-1.jpg"
              alt="Header Avatar"
            />
            <img v-else
              class="rounded-circle header-profile-user"
              src="/images/avatar-placeholder.jpg"
              alt="Header Avatar"
            />
            <span class="d-none d-xl-inline-block ml-1" v-if="user.name !== null">{{user.name}} {{user.lastname}}</span>
            <span class="d-none d-xl-inline-block ml-1" v-else>My Account</span>
            <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
          </template>
          <!-- item-->


          <b-dropdown-item href="/trade/account">
              <i class="bx bx-user font-size-16 align-middle me-1"></i>
              Account
            </b-dropdown-item>
             <b-dropdown-item href="/trade/account/orders">
              <i class="bx bx-receipt font-size-16 align-middle me-1"></i>
              Orders
            </b-dropdown-item>
             <b-dropdown-item href="/trade/account/support">
              <i class="bx bx-support font-size-16 align-middle me-1"></i>
              Support
            </b-dropdown-item>
             <b-dropdown-item href="/trade/account/wishlist">
              <i class="bx bx-heart font-size-16 align-middle me-1"></i>
              Wishlist
            </b-dropdown-item>
             <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-text class="px-3 mb-2 border-bottom text-uppercase">
            Gemesys
          </b-dropdown-text>
            <b-dropdown-item href="/account/profile">
              <i class="bx bx-user font-size-16 align-middle me-1"></i>
              {{ $t('navbar.dropdown.henry.list.profile') }}
            </b-dropdown-item>
            <b-dropdown-item class="d-block" href="/company/settings">
            <i class="bx bx-wrench font-size-16 align-middle me-1"></i>
            {{ $t('navbar.dropdown.henry.list.settings') }}
          </b-dropdown-item>
          <b-dropdown-item href="javascript: void(0);" @click="$emit('show-lockscreen')">
            <i class="bx bx-lock-open font-size-16 align-middle me-1"></i>
            {{ $t('navbar.dropdown.henry.list.lockscreen') }}
          </b-dropdown-item>
         
          <b-dropdown-divider></b-dropdown-divider>
          <a href="/logout" class="dropdown-item text-danger">
            <i
              class="bx bx-power-off font-size-16 align-middle me-1 text-danger"
            ></i>
            {{ $t("navbar.dropdown.henry.list.logout") }}
          </a>
        </b-dropdown>

        <div class="dropdown d-inline-block">
          <button
            type="button"
            class="btn header-item noti-icon right-bar-toggle toggle-right"
            @click="toggleRightSidebar"
          >
            <i class="bx bx-cog bx-spin toggle-right"></i>
          </button>
        </div>
      </div>
    </div>
    <b-modal id="delete-cart"  centered content-class="border-radius-1em" header-class="border-none" hide-footer>
        <b-row>
          <b-col md="12">
            <h4 class="text-center">Are You Sure?</h4>
            <p  class="text-center">You are about to delete all items from your cart. Are you sure you want to do this?</p>

            <b-row class="mt-3">
              <b-col>
                <a class="btn-rounded btn-block btn btn-danger" @click="$bvModal.hide('delete-cart')">
                  No!
                </a>
              </b-col>
              <b-col>
                <a class="btn-rounded btn-block btn btn-success" @click="destroyCart(cart.uid)">
                  Yes, delete all!
                </a>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
    </b-modal>
  </header>
</template>
