<script type="text/javascript">
	export default{
		props: {
			resource: {
		        type: String,
		        default: () => '',
		      },
		      resource_id: {
		        type: Number,
		        default: () => '',
		      },
		      api_token: {
		        type: String,
		        default: () => '',
		      },
		      resource_route: {
		        type: String,
		        default: () => '',
		      },
		      view: {
		        type: String,
		        default: () => '',
		      },
		      product_list: {
		        type: Array,
		        default: () => '',
		      },
		      expanded:{
		      	type: Number,
		      	default: () => 0
		      },
		      show_filters: {
		      	type: Boolean,
		      	default: () => true
		      }
		  },
		  data(){
			return{
		        quickviewproduct: '',
								}
		  },
		  methods: {
		  	getCurrencySymbol(currency_id){
		  		var symbol = this.$attrs.currencies.filter(item => item.id == currency_id);
		  		if(symbol.length !== 0){
		  			return symbol[0].symbol;
		  		}
		  	},
		  	removeResource(){

		  	},
		  	priceIncVat(price, tax_percentage){
		      var tax = 1 + (tax_percentage / 100);
		      return price * tax;
		    }
		  }
	}
</script>
<template>
	 <div class="row">
          <div class="col-md-12">
          	<ul class="nav nav-pills product-view-nav mb-2 float-right" v-if="show_filters == true">
          	<li class="nav-item">
                  <a class="nav-link" :class="{'active': view == 'grid' }" href="javascript: void(0);" @click="view = 'grid'">
                    <i class="bx bx-grid-alt"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': view == 'list' }" href="javascript: void(0);" @click="view = 'list'">
                    <i class="bx bx-list-ul"></i>
                  </a>
                </li>
               </ul>
          	<b-list-group class="list-group product-list w-100 no-shadow" v-if="(product_list.length !== 0) && (view == 'list')">
                              <b-list-group-item v-for="product in product_list.data" class="list-group-item d-flex px-4 py-3 border product-list-item mb-2 w-100"
                              :to="'/order/'+product.uid"
                              >
                              	<div class="d-flex product-list-item-container w-100">
                              	
                                <div class="mr-auto ml-2 my-auto mr-3">
                                	#{{product.reference}}
						            
                                </div>
                                <span class="text-muted italic no-margin my-auto ml-auto mr-auto">{{product.created_at | luxon({ input: "server" }) }} <i class="bx bx-calendar text-primary font-size-14"></i></span>
						            <span class="text-muted ml-auto my-auto mr-2">{{getCurrencySymbol(product.payment_currency_id)}}{{product.total_order_exc}}</span>
						            <strong class="text-muted my-auto">{{getCurrencySymbol(product.payment_currency_id)}}{{product.total_order_inc}}</strong>
                                <div class=" ml-2 my-auto mr-3 d-flex">
                                	<router-link class="btn btn-outline-light btn-rounded ml-auto my-auto flex-grow-3 mr-2" :to="'/order/'+product.uid"><i class="py-3 px-2 mdi mdi-eye-outline"></i></router-link>
                                <!--<a class="btn btn-outline-light btn-rounded ml-auto my-auto" href="javascript:;" @click="removeResource(product)"><i class="py-1 px-2 bx bx-trash-alt"></i></a>-->
                            	</div>
              
                              </div>
                              </b-list-group-item>
                              

                              <!--<li class="list-group-item py-4 text-center" ><a class="btn btn-outline-light btn-rounded">Load More</a>
                              </li>-->
                            </b-list-group>
                <ul class="list-group product-list w-100 no-shadow" v-if="(product_list.length == 0) && (view == 'list')">
                	  <li class="list-group-item d-flex">
                	  	<i class="bx bx-warning"></i> No Products <span v-if="resource !== ''">assigned to this {{resource}}</span>
                	  </li>
                </ul>
          </div>
          <div class="col-md-12">
          	<!--<div class="row product-grid px-2 row-eq-height" v-if="(product_list.length !== 0) && (view == 'grid')">
                              <div class=" d-flex flex-column px-2 product-grid-item mb-2" :class="{'col-md-4' : expanded == 0,'col-md-3' : expanded == 1}" v-for="product in product_list">
                              	<div class="border shadow-sm product-grid-item-container">
                              	<img v-if="product.cover_image" class="flex-grow-3 w-100 product-grid-img" :src="product.cover_image">
                              	<img v-else class="flex-grow-3 w-100 product-grid-img" src="/images/product-placeholder.png">
                                <div class="my-3 flex-grow-3 px-3">
                                	<strong class="mr-auto my-auto text-primary">{{product.name}}</strong>
                                	<span class="badge badge-primary position-absolute product-grid-reference-badge">{{product.reference}}</span>
						            <small class="text-muted italic no-margin" v-html="product.short_description"></small>
                                </div>
                                <div class="my-1 flex-grow-3 px-3">
                                	<strong class="text-muted mb-auto">{{getCurrencySymbol(product.payment_currency_id)}}{{product.sales_price}} <small class="mr-3">exc. VAT</small></strong>
                                </div>
                                <div class="my-1 flex-grow-3 px-3">
                                	<strong class="text-muted mb-auto"> {{getCurrencySymbol(product.payment_currency_id)}}{{priceIncVat(product.sales_price, product.tax_rule.percentage)}} <small class="mr-3">inc. VAT</small></strong>
                                </div>
                                <div class="my-1 flex-grow-3 px-3">
                                	<strong class="text-muted mb-auto">{{getCurrencySymbol(product.payment_currency_id)}}{{product.trade_price}} <small class="mr-3">Trade Price</small></strong>
                                </div>
                                <div class="my-3 flex-grow-3 px-3">
                                	<a class="btn btn-outline-light btn-rounded ml-auto my-auto flex-grow-3" href="javascript:;" @click="quickviewproduct = product"><i class="mdi mdi-eye-outline"></i></a>
                                	<a class="btn btn-outline-light btn-rounded ml-auto my-auto flex-grow-3" href="javascript:;"><i class="bx bx-pencil"></i></a>
                                <a class="btn btn-outline-light btn-rounded ml-auto my-auto flex-grow-3" href="javascript:;" @click="removeResource(product)"><i class="bx bx-trash-alt"></i></a>
              					</div>
                              	</div>
                              </div>
                              

                              <li class="list-group-item py-4 text-center" ><a class="btn btn-outline-light btn-rounded">Load More</a>
                              </li>
                            </div>
                <ul class="list-group product-list w-100 no-shadow"  v-if="(product_list.length == 0) && (view == 'grid')">
                	  <li class="list-group-item d-flex">
                	  	<i class="bx bx-warning"></i> No Products <span v-if="resource !== ''">assigned to this {{resource}}</span>
                	  </li>
                </ul>-->
          </div>
     </div>
</template>