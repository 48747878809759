<script type="text/javascript">
  import Layout from "../../layouts/main";
  import PageHeader from "../../components/page-header";
  import UserList from "../../components/widgets/UserList";
  import Stat from "../../components/widgets/stat";
  import SearchCompanyUser from "../../components/widgets/SearchCompanyUser";
  import SearchCompanyUsers from "../../components/widgets/SearchCompanyUsers";
  import SideSlidePanel from "../../components/widgets/SideSlidePanel";
  export default{
    components:{ Layout, PageHeader, SearchCompanyUser, SearchCompanyUsers, UserList, Stat, SideSlidePanel},
    data(){
      return {
        title: 'department',
        projectData: projectData,
        department: [],
        departments: [],
        teams: [],
        projects: [],
        viewAction: {},
        actionPanel: false, 
        newActionPanel: false,
        actionPanelClass: 'edit-sidepanel',
        newActionPanelClass: 'edit-sidepanel',
        viewActionTab: 'comments',
        addCollaboratorView: false,
        userListLoaded: true,
        limit: 0,
        order: 'desc',
        sortby: 'id',
        search: '',
        status_id: '',
        currentPage: 1,
        limit: 25,
        view: 'list',
        assignedto: '',
        assignedby: '',
        collaborators: [],
        completed: false,
        archived: false,
        error: '',
        newAction: {
                name: '',
                image: '',
                description: '',
                company_id: 0,
                parent_id: 0,
                project_id: 0,
                created_by_id: 0,
                assigned_by_id: 0,
                assigned_to_id: 0,
                company_department_id: 0,
                company_team_id: 0,
                action_type_id: 0,
                reference: '',
                issue: '',
                action: '',
                due_date: null,
                more_time_request: null, 
                more_time_approved: 0,
                more_time_approved_id: 0, 
                completed_date: null,
                completed:0,
                archive: 0,
                reviewed: 0,
                review_requested: 0,
                management_review_minute_id: 0,
                collaborators: [],
                attachments: [],
                settings: {
                  creation:{
                    email_creator: 0,
                    email_assigned_to: 0,
                    email_collaborators: 0,
                    email_additional_emails: 0,
                    additional_emails: []
                  },
                  update: {
                    email_creator: 0,
                    email_assigned_to: 0,
                    email_collaborators: 0,
                    email_additional_emails: 0,
                    additional_emails: []
                  },
                  delete: {
                    email_creator: 0,
                    email_assigned_to: 0,
                    email_collaborators: 0,
                    email_additional_emails: 0,
                    additional_emails: []
                  },
                  status: {
                    email_creator: 0,
                    email_assigned_to: 0,
                    email_collaborators: 0,
                    email_additional_emails: 0,
                    additional_emails: []
                  },
                  complete: {
                    email_creator: 0,
                    email_assigned_to: 0,
                    email_collaborators: 0,
                    email_additional_emails: 0,
                    additional_emails: []
                  },
                  due: {
                    email_creator: 0,
                    email_assigned_to: 0,
                    email_collaborators: 0,
                    days_before: 0,
                    email_additional_emails: 0,
                    additional_emails: []
                  },
                  addcollaborator:{
                    email_creator: 0,
                    email_collaborator: 0,
                  },
                  removecollaborator:{
                    email_creator: 0,
                    email_collaborator: 0,
                  },
                  report:{
                    email_creator: 0,
                    email_attending: 0,
                    email_additional_emails: 0,
                    additional_emails: [],
                  },
                },
              },
        statData: [
              {
                icon: "bx bx-copy-alt",
                title: "Team Members",
                value: "1,235"
              },
              {
                icon: "bx bx-archive-in",
                title: "Projects",
                value: "$35, 723"
              },
              {
                icon: "bx bx-group",
                title: "Tasks",
                value: "$16.2"
              }
            ],
        items: [
              {
                text: "Company",
                href: "/dashboard"
              },
              {
                text: "User",
                href: "/company/users"
              },
              {
                text: "Department",
                active: true
              }
            ]
      }
    },
    beforeMount(){
      axios.get('/api/company/team/'+this.$attrs.id+'', {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
              }}).then(response => {
        this.department = response.data;
        this.teams.push(response.data.id);
        this.title = '';
        this.items[2].text = response.data.name;
        axios.get('/api/company/projects?page='+this.currentPage+'&limit='+this.limit+'&sortby='+this.sortby+'&order='+this.order+'&search='+this.search+'&departments='+this.departments+'&teams='+this.teams+'&assingedto='+this.assignedto+'&collaborators='+this.collaborators+'&view='+this.view+'&completed='+this.completed+'&archived='+this.archived+'&status='+this.status_id+'', {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
              }}).then(response => {
            this.projects = response.data;
        }).catch(error =>{
          this.load_error = error.response;
        })
      }).catch(error => {
        this.error = error.data;
      });
    },
    methods:{
        showActionEvent(project){
      this.actionPanel = false;
      this.viewAction = project;
      this.viewActionTab = 'comments';
      var self = this;
                setTimeout(function(){
                self.actionPanel = true;
                }, 500);
      },
      showCollaboratorsEvent(project){
        this.actionPanel = false;
        this.viewAction = project;
        this.viewActionTab = 'collaborators';
        var self = this;
                  setTimeout(function(){
                  self.actionPanel = true;
                  }, 500);
      },
      addRelatedAction(){
          this.newActionPanel = true;
      },
    }
  }
</script>
<template>
  <Layout :nickname="$attrs.nickname" :user="$attrs.user" >
    <PageHeader :title="title" :items="items" />
    <div class="row" v-if="department == []">
      You don't have access to this team
    </div>
    <div class="row" v-else>
      
      <!-- end col -->

      <div class="col-lg-4">
        <div class="card">
          <div class="card-body">
            <div class="media d-flex">
              <div class="avatar-sm mr-4">
                        <span class="avatar-title rounded-circle">{{(department.name).slice(0,1)}}</span>
                      </div>

              <div class="media-body overflow-hidden d-flex flex-column my-auto">
                <h5 class="text-truncate font-size-15 mb-0 mt-auto">{{department.name}} Team</h5>
              </div>
            </div>

            <h5 class="font-size-15 mt-4">Team Description</h5>

            <p class="text-muted mb-auto">{{department.description}}</p>

          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Manager</h4>
            <SearchCompanyUser :api_token="this.$attrs.user.api_token" :id="department.manager.user" @user-selected="getManager" @input="getManager" />
            </div>
          </div>
        </div>
        <div class="col-lg-8">
        <div class="row">
          <div class="col-lg-4">
                  <Stat :icon="'bx bx-group'" :title="'Team Members'" :value="department.users_count" />
          </div>
          <div class="col-lg-4">
            <Stat :icon="'bx bx-group'" :title="'Active Projects'" :value="department.projects_count" />
          </div>
          <div class="col-lg-4">
            <Stat :icon="'bx bx-group'" :title="'Upcoming Actions'" :value="department.users_count" />
          </div>
        </div>
        <b-tabs pills justified content-class="py-3 text-muted">
              <b-tab active class="border-0">
                <template v-slot:title>
                  <span class="d-inline-block d-none">
                   <!--<i class="bx bx-history font-size-16"></i>-->
                  </span>
                  <span class="d-sm-inline-block"> Projects</span>
                </template>
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title mb-4">Upcoming Projects</h4>
                    <div v-if="projects.data.length == 0">
                    <div class="card border border-info"><div class="card-header bg-transparent border-info"><h5 class="my-0 text-info"><i class="mdi mdi-alert-circle-outline mr-3"></i> No Projects To Show </h5></div><div class="card-body"><p class="card-text"> You haven't added any actions here yet. </p><a class="btn btn-primary btn-block" href="/projects#add-new">Add New Project</a></div></div>
                  </div>
                    <ProjectList :projectData="projects.data" :slimline="true" />
                    </div>
                </div>
              </b-tab>
              <b-tab class="border-0">
                <template v-slot:title>
                  <span class="d-inline-block d-none">
                   <!--<i class="bx bx-history font-size-16"></i>-->
                  </span>
                  <span class="d-sm-inline-block"> Actions</span>
                </template>
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title mb-4">Actions</h4>

                    <ActionsList :user_id="$attrs.user.companies[0].id" :api_token="$attrs.user.api_token" :project_id="''" @show-action="showActionEvent" @show-collaborators="showCollaboratorsEvent" @add-new-action="addRelatedAction" :department_id="department.id" />
                    </div>
                </div>
              </b-tab>
              <b-tab class="border-0">
                <template v-slot:title>
                  <span class="d-inline-block d-none">
                   <!--<i class="bx bx-history font-size-16"></i>-->
                  </span>
                  <span class="d-sm-inline-block"> Team Members</span>
                </template>
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title mb-4">Team Members</h4>

                    <SearchCompanyUsers v-if="addCollaboratorView == true"  :api_token="this.$attrs.user.api_token" :resource="'team'" :id="department.id" @user-added="newCollaboratorAdded" />
                    <a class="btn btn-outline-light float-left" :class="{'mt-2': addCollaboratorView == false}"  @click="addCollaboratorView = !addCollaboratorView"><i v-if="addCollaboratorView == false" class="mdi mdi-plus"></i><i v-if="addCollaboratorView == true" class="mdi mdi-close"></i></a>
                    <UserList v-if="userListLoaded == true" :resource="'team'" :id="department.id" :api_token="this.$attrs.user.api_token" />
                    </div>
                </div>
              </b-tab>
        </b-tabs>
        
        
      </div>
      </div>
      <!-- end col -->
    <!-- end row -->

    </div>
        <SideSlidePanel :expandable="1" :editPanel="actionPanel" :panelClass="actionPanelClass" @close-panel="actionPanel = false, actionPanelClass = 'edit-sidepanel'" @expand="actionPanelClass = 'edit-sidepanel-fullwidth'" @contract="actionPanelClass = 'edit-sidepanel'" >
      <ActionView :viewAction="viewAction" :api_token="this.$attrs.user.api_token" :activetab="viewActionTab" />
    </SideSlidePanel>

    <SideSlidePanel :editPanel="newActionPanel" :panelClass="newActionPanelClass" @close-panel="newActionPanel = false, newActionPanelClass = 'edit-sidepanel', resetActionData()" :expandable="1" @expand="newActionPanelClass = 'edit-sidepanel-fullwidth'" @contract="newActionPanelClass = 'edit-sidepanel'">
                <b-row>
              <b-col md="12" class="mb-4">
                <AddNewAction :api_token="this.$attrs.user.api_token" :newAction="newAction" :addPanel="newActionPanel" :statuses="statuses"  :view="'add'" :team="department" /> 
              </b-col>
              
          </b-row>           
    </SideSlidePanel>
  </Layout>
</template>