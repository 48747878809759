<script type="text/javascript">
  export default{
    props: {
      company: {
        type: Object
      },
      footerlinks: {
        type: Array
      },
      user: {
        type: [String, Object],
      }
    }
  }

</script>

<template>
<div>
  <footer class="footer bg-white border-top pb-0 left-0 right-0 top-0">
  <section class="bg-white">
  <b-container fluid>
  <b-row style="padding: 50px 0 90px 0;">
    <b-col>
      <b-list-group flush class="text-left bg-transparent">
        <b-list-group-item class="border-0 bg-transparent px-0" >
         <h6>About</h6>
        </b-list-group-item>
        <b-list-group-item class="border-0 bg-transparent px-0 font-size-14" to="/about">
          About us
        </b-list-group-item>
        <b-list-group-item class="border-0 bg-transparent px-0 font-size-14" to="/shop">
          Shop
        </b-list-group-item>
        <b-list-group-item class="border-0 bg-transparent px-0 font-size-14" to="/categories">
          Categories
        </b-list-group-item>
        <b-list-group-item class="border-0 bg-transparent px-0 font-size-14" to="/collections">
          Collections
        </b-list-group-item>
      </b-list-group>
    </b-col>
     <b-col>
      <b-list-group flush class="text-left bg-transparent">
        <b-list-group-item class="border-0 bg-transparent px-0">
         <h6>Company</h6>
        </b-list-group-item>
        <b-list-group-item class="border-0 bg-transparent px-0 font-size-14" to="/stores">
          Stores
        </b-list-group-item>
        <b-list-group-item class="border-0 bg-transparent px-0 font-size-14" to="/contact">
          Contact
        </b-list-group-item>
        <b-list-group-item class="border-0 bg-transparent px-0 font-size-14" to="/privacy-policy">
          Privacy Policy
        </b-list-group-item>
        <b-list-group-item class="border-0 bg-transparent px-0 font-size-14" to="/terms-and-conditions">
          Terms of Use
        </b-list-group-item>
      </b-list-group>
    </b-col>
     <b-col>
      <b-list-group flush class="text-left bg-transparent" v-if="!user">
        <b-list-group-item class="border-0 bg-transparent px-0">
         <h6>Account</h6>
        </b-list-group-item>
        <b-list-group-item v-if="!user" class="border-0 bg-transparent px-0 font-size-14" to="/account/">
          Login
        </b-list-group-item>
        <b-list-group-item class="border-0 bg-transparent px-0 font-size-14">
          Register
        </b-list-group-item>
        <b-list-group-item class="border-0 bg-transparent px-0 font-size-14">
          Cart
        </b-list-group-item>
        <b-list-group-item class="border-0 bg-transparent px-0 font-size-14">
          Checkout
        </b-list-group-item>
      </b-list-group>
      <b-list-group flush class="text-left bg-transparent" v-else>
        <b-list-group-item class="border-0 bg-transparent px-0">
         <h6>Account</h6>
        </b-list-group-item>
        <b-list-group-item class="border-0 bg-transparent px-0 font-size-14" to="/account">
          Dashboard
        </b-list-group-item>
        <b-list-group-item class="border-0 bg-transparent px-0 font-size-14" to="/account/addresses">
          Addresses
        </b-list-group-item>
        <b-list-group-item class="border-0 bg-transparent px-0 font-size-14"  to="/account/orders">
          Orders
        </b-list-group-item>
        <b-list-group-item class="border-0 bg-transparent px-0 font-size-14" to="/account/messages">
          Support
        </b-list-group-item>
      </b-list-group>
    </b-col>
     <b-col>
      
    </b-col>
  </b-row>
</b-container>
</section>

    <div class="container-fluid container-fullwidth bg-white border-top">
      <div class="row">
        <div class="container-fluid">
      <div class="row py-3">
        <div class="col-sm-6">{{ new Date().getFullYear() }} © {{company.name}}.</div>
        <div class="col-sm-6">
          <div class="text-sm-end d-none d-sm-block">Jewellery E-Commerce by Gemesys</div>
        </div>
      </div>
    </div>
  </div>
    </div>
  </footer>
  <!-- end footer -->
</div>
</template>
