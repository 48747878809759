<script type="text/javascript">
	export default{
		props: {
					company: {
						type: Object,
					},
					resource: {
		        type: String,
		        default: () => '',
		      },
		      default_resource_key: {
		        type: String,
		        default: () => 'uid',
		      },
		      resource_id: {
		        type: Number,
		        default: () => '',
		      },
		      api_token: {
		        type: String,
		        default: () => '',
		      },
		      resource_route: {
		        type: String,
		        default: () => '',
		      },
		      view: {
		        type: String,
		        default: () => 'grid',
		      },
		      product_list: {
		        type: Array,
		        default: () => '',
		      },
		      expanded:{
		      	type: Number,
		      	default: () => 0
		      },
		      slimline: {
		      	type: Boolean,
		      	default: () => false
		      },
		      pricing: {
		      	type: String,
		      	default: () => 'all'
		      },
		      pricing_title: {
		      	type: Boolean,
		      	default: () => true
		      },
		      listing_id: {
		      	type: Number,
		      	default: () => 0
		      },
		  },
		  data(){
			return{
						quickviewproduct: '',
		        quantity: 1,
		        showQuantitySelector: null,
		        hoverIndex: -1,
		        storageurl: 'https://'+this.company.nickname+'.s3.eu-west-2.amazonaws.com',
		        showQVGallery: false,
		        viewImage: null,
			}
		  },
		  computed: {
		  		companyCurrency(){
        if(this.company.currency !== null){
            return this.company.currency.symbol;
        }else{
            return '£';
        }
		  	}
		  },
		  methods: {
		  	copyTextToClipboard(text) {
				    // Create a temporary textarea element to hold the text
				    const textarea = document.createElement('textarea');
				    textarea.value = text;
				    
				    // Set the textarea's position off-screen
				    textarea.style.position = 'fixed'; 
				    textarea.style.opacity = '0';

				    // Append to the body
				    document.body.appendChild(textarea);
				    
				    // Select the text
				    textarea.select();
				    
				    // Execute the copy command
				    document.execCommand('copy');

				    // Remove the textarea from the body
				    document.body.removeChild(textarea);

				    //alert('Copied to clipboard: ', text);
				},
		  	removeResource(payload){
		  		this.$emit('remove-product', payload);
		  	},
		  	 addTax(price, tax_rule){
		   		var country_id = this.company.country_id;
		     	if(tax_rule !== null && tax_rule.country_rules.length > 0){
		    		var selected_rule = null;
		    		var selected_ruleIndex = -1;
		    	for(var ti = 0; ti < tax_rule.country_rules.length; ti++){
		    		selected_ruleIndex = tax_rule.country_rules[ti].countries.findIndex(item => item == country_id);
		    		if(selected_ruleIndex !== -1){
		    			selected_rule = tax_rule.country_rules[ti];
		    			ti = tax_rule.country_rules.length;
		    		}
		    	}
		    	console.log(selected_rule);
		    	if(tax_rule.type == 1){
	        		if(selected_rule !== null){
	        			var percentage = (parseFloat(selected_rule.value) / 100) + 1;
	        			return price * percentage;
	         		}else{
	         			return price;
	         		}
         		}else{
         			if(selected_rule !== null){
	        			return price + selected_rule.value;
	         		}else{
	         			return price;
	         		}
         		}
         		}else{
         			return price;
         		}
        	},
		  	priceIncVat(price, tax_rule){
		  		if(tax_rule !== null){
			      var tax = 1 + (parseFloat(tax_rule.percentage) / 100);
			      return (parseFloat(price) * parseFloat(tax)).toFixed(2);
		    	}else{
		    		return parseFloat(price).toFixed(2);
		    	}
		    },
		    listingQuantity(product, listing_id){
		    	if(product.listings.length > 0){
			    	var listings = product.listings;
			    	var productlisting = listings.filter(item => item.company_listing_id == listing_id);
			    	return productlisting[0].quantity;
		    	}else{
		    		return 0;
		    	}
		    }
		  }
	}
</script>
<template>
	 <div class="row">
          <div class="col-md-12">
          	<ul class="nav nav-pills product-view-nav mb-2 float-right" v-if="slimline == false">
          	<li class="nav-item">
                  <a class="nav-link" :class="{'active': view == 'grid' }" href="javascript: void(0);" @click="view = 'grid'">
                    <i class="bx bx-grid-alt"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': view == 'list' }" href="javascript: void(0);" @click="view = 'list'">
                    <i class="bx bx-list-ul"></i>
                  </a>
                </li>
               </ul>
          	<ul class="list-group product-list w-100 no-shadow" v-if="(product_list.length !== 0) && (view == 'list')">
                              <li v-for="product in product_list" class="list-group-item d-flex px-0 py-0 border product-list-item mb-2 shadow-sm">
                              	<div class="d-flex product-list-item-container w-100">

                              	<img v-if="(product.cover_image !== null) && ('thumb' in product.cover_image)" class="avatar-xl mr-3 product-list-img" :src="storageurl + product.cover_image.thumb.replaceAll('//', '/')">	
                              	<img v-else-if="product.cover_image !== null" class="avatar-xl mr-3 product-list-img" :src="storageurl + product.cover_image.src.replaceAll('//', '/')">
                              	<img v-else class="avatar-xl mr-3 product-list-img" src="/images/product-placeholder.png">
                                <div class="mr-auto ml-2 my-auto flex-grow-10 mr-3">
                                	<h4 class="mr-auto my-auto card-title">{{product.name}}</h4>
                                	<span class="badge badge-light bg-light position-absolute product-list-reference-badge top-0 m-2 font-size-12 " @click="copyTextToClipboard(product.reference)">{{product.reference}}</span>
						            <p class="paragraph-truncate-line2 mb-1" v-html="product.short_description" ></p>
						            <span class="mt-1 badge badge-light bg-light p-2 font-size-12 border-radius-1em">{{companyCurrency}}{{product.sales_price}} exc. VAT</span>
						            
						            <span v-if="product.tax_rule !== null" class="mt-1 badge badge-light bg-light p-2 font-size-12 border-radius-1em">{{companyCurrency}}{{addTax(product.sales_price, product.tax_rule).toFixed(2)}} <small>inc. VAT</small></span> <span class="mt-1 badge badge-light bg-light p-2 font-size-12 border-radius-1em">{{companyCurrency}}{{product.trade_price}} <small class="mr-3">Trade Price</small></span>
                                </div>
                                <div class="mr-auto ml-2 my-auto mr-3 d-flex align-items-center h-100">
                                	<a class="mr-2" href="javascript:;" v-b-modal.product-info @click="quickviewproduct = product"><i class="mdi mdi-eye-outline font-size-18"></i></a>
                                <a class="text-danger" href="javascript:;" @click="removeResource(product)"><i class="bx bx-trash-alt font-size-20"></i></a>
                            	</div>
              
                              </div>
                              </li>
                              

                              <!--<li class="list-group-item py-4 text-center" ><a class="btn btn-outline-light btn-rounded">Load More</a>
                              </li>-->
                            </ul>
                <ul class="list-group product-list w-100 no-shadow" v-if="(product_list.length == 0) && (view == 'list')">
                	  <li class="list-group-item d-flex">
                	  	<i class="bx bx-warning"></i> No Products <span v-if="resource !== ''">assigned to this {{resource}}</span>
                	  </li>
                </ul>
          </div>
          <div class="col-md-12">
              <div id="my-table" class="row product-grid px-2 row-eq-height" v-if="(product_list.length !== 0) && (view == 'grid')">
                 <div class="d-flex flex-column product-grid-item mb-2" :class="{'col-md-4' : expanded == 0,'col-md-3' : expanded == 1}" v-for="(product, index) in product_list">
                 	<b-card no-body class="overflow-hidden news-card shadow-none border-radius-1em border" @mouseenter="hoverIndex = index" @mouseleave="hoverIndex = -1" >

		                    <b-row no-gutters>
		                    	<b-col md="12" class="position-absolute z-index-2">
		                    		<b-row>
				                     <transition name="fade-left">
				                    	<div class="col-md-4" v-if="hoverIndex == index"> 
		                              	<a id="tooltip-add" v-b-modal.product-info @click="quickviewproduct = product" class="mt-2 ml-3 btn btn-primary font-size-14 btn-rounded ml-auto float-left"><i class="mdi mdi-eye-outline"></i></a>
		                              </div>
		                          </transition>
		                          <transition name="fade-right">
		                           <div class="col-md-8 my-auto"  v-if="hoverIndex == index">
				                         <p  class="text-muted float-right ml-auto mt-3">
								                    <span class="bx bx-star font-size-14 text-warning"></span>
								                    <span class="bx bx-star font-size-14 text-warning ml-1"></span>
								                    <span class="bx bx-star font-size-14 text-warning ml-1"></span>
								                    <span class="bx bx-star font-size-14 text-warning ml-1"></span>
								                    <span class="bx bx-star font-size-14 ml-1"></span>
					                        </p>
					                      </div>
					              			</transition>
			              			</b-row>
			              		</b-col> 
		                      <b-col lg="12">
		                        


		                        <b-card-img-lazy v-if="(product.cover_image) && ('gallery' in product.cover_image)"class="rounded-0" :src="storageurl+product.cover_image.gallery.replaceAll('//', '/')" ></b-card-img-lazy>
                              	<b-card-img-lazy v-else-if="(product.cover_image) && ('thumb' in product.cover_image)"class="rounded-0" :src="storageurl+product.cover_image.thumb.replaceAll('//', '/')" ></b-card-img-lazy>
                              	<b-card-img-lazy v-else-if="(product.cover_image) && ('src' in product.cover_image)"class="rounded-0" :src="storageurl+product.cover_image.src.replaceAll('//', '/')" ></b-card-img-lazy>
		                        <b-card-img-lazy v-else :src="'/images/product-placeholder.png'" alt="Image" class="rounded-0"></b-card-img-lazy>
		                        <div class="mt-auto mb-3 mx-2 d-flex position-absolute bottom-0 right-0 left-0">
		                        	<span class="badge badge-light my-auto ml-3 bg-light p-2 font-size-12 border-radius-1em" @click="copyTextToClipboard(product.reference)">
		                        		#{{product.reference}}
		                        	</span>
		                        </div>
		                     <transition name="fade-right">
		                        <div class="mt-auto mb-3 mx-2 d-flex position-absolute bottom-0 right-0 left-0" v-if="hoverIndex == index">
                              	<a @click="removeResource(product)" class="mr-3 ml-auto btn btn-primary font-size-16 btn-rounded"><i class="bx bx-trash-alt"></i></a>
              					</div>
              				</transition>
		                      </b-col>
		                      <b-col lg="12"> 
		                        <b-card-body>
		                        	<span v-if="resource_id == product[default_resource_key]" class="text-capitalize"><i class="bx bx-star"></i> Default {{resource}}</span>
		                        	<a class="" :href="'/stock/product/'+product.uid"><h4 class="card-title d-flex" :class="{'text-truncate font-size-14' : truncate_titles == true}">{{product.name}}</h4></a>
		                          <b-card-text>
		                             <p class="paragraph-truncate-line2" v-html="product.short_description+'...'"></p>
		                           

		                          </b-card-text>
		                         <div v-if="(pricing == 'public') || (pricing == 'all')">
		                          <h5 class="text-uppercase font-size-12 text-underline spaced-title" v-if="pricing_title == true">Consumer Pricing</h5>
		                          <div class="d-flex flex-row">
		                          	<span class="badge badge-light my-auto mr-2 bg-light p-2 font-size-12 border-radius-1em">
		                         			{{companyCurrency}} {{addTax(product.sales_price, product.tax_rule)}} inc. VAT
		                         			</span>
		                         			<span class="badge badge-light my-auto mr-auto bg-light p-2 font-size-12 border-radius-1em">
		                         			{{companyCurrency}} {{product.sales_price}} exc. VAT
		                         			</span>
		                       		</div>
		                       	</div>
		                       	<div v-if="(pricing == 'trade') || (pricing == 'all')">
		                       		<h5 class="text-uppercase font-size-12 text-underline spaced-title mt-3" v-if="pricing_title == true">Trade Pricing</h5>
		                       		<div class="d-flex flex-row">
		                       				<span class="badge badge-light my-auto mr-2 bg-light p-2 font-size-12 border-radius-1em">
		                         			{{companyCurrency}} {{addTax(product.trade_price, product.tax_rule)}} inc. VAT
		                         		</span>
		                         			<span class="badge badge-light my-auto mr-auto bg-light p-2 font-size-12 border-radius-1em">
		                         			{{companyCurrency}} {{product.trade_price}} exc. VAT
		                         		</span>

		                       		</div>
		                       	</div>
		                       	<div v-if="listing_id > 0" class="mt-3">

		                       		<div class="d-flex flex-row">
		                       			<h5 class="text-uppercase font-size-12 text-underline spaced-title my-auto">Quantity at this Shop:</h5>
		                       				<span class="badge badge-light my-auto mr-2 ml-auto bg-light p-2 font-size-12 border-radius-1em">
		                         			{{listingQuantity(product, listing_id)}}
		                         		</span>
		                         	</div>
		                       	</div>
		                        </b-card-body>
		                      </b-col>
		                    </b-row>
		                  </b-card>
                 
                              </div>
                              

                              <!--<li class="list-group-item py-4 text-center" ><a class="btn btn-outline-light btn-rounded">Load More</a>
                              </li>-->
                            </div>         
                <ul class="list-group product-list w-100 no-shadow"  v-if="(product_list.length == 0) && (view == 'grid')">
                	  <li class="list-group-item d-flex">
                	  	<i class="bx bx-warning"></i> No Products <span v-if="resource !== ''"> assigned to this {{resource}}</span>
                	  </li>
                </ul>
          </div>
          <b-modal id="product-info" centered size="xl" body-class="p-0 my-0 overflow-hidden" content-class="border-radius-1em p-0 border-none" header-class="border-none position-absolute right-0" hide-footer @close="qvModalClosed">
					    <b-card v-if="quickviewproduct !== ''" no-body class="mb-0">
					    	<b-row no-gutters>
					      <b-col lg="6" @mouseenter="showQVGallery = true" @mouseleave="showQVGallery = false" class="p-0">
					      	<transition name="fade-left">
		                <div class="col-md-12 position-absolute z-index-2" v-if="showQVGallery == true"> 
                             
                              	<div class="d-block overflow-hidden px-1 position-relative ml-2" style="max-height: 626px; width: 10%;" v-if="quickviewproduct.images !== null">
														    	<ImageSwiper :swiperOptions="imgswiperOptions" :items="quickviewproduct.images" @image-selected="imageSelected" ></ImageSwiper>
														    	</div>
                              </div>
                   </transition>
                 <div style="height: 100%;" v-if="viewImage == null">
          					<b-card-img style="height: 100%; object-fit: cover;" v-if="quickviewproduct.cover_image" class="w-100 quickviewproduct-grid-img" :src="storageurl + quickviewproduct.cover_image.gallery.replace('//','/')"></b-card-img>
                   	<b-card-img style="height: 100%; object-fit: cover;" v-else class="w-100 quickviewproduct-grid-img" src="/images/product-placeholder.png"></b-card-img>
                </div>
                <div style="height: 100%;" v-else>
                   	<b-card-img style="height: 100%; object-fit: cover;" class="w-100 quickviewproduct-grid-img" :src="storageurl + viewImage.replace('//', '/')"></b-card-img>
                </div>
               </b-col>
               <b-col lg="6" class="d-flex align-items-center">
                              	<b-card-body>
                              		<span class="badge badge-primary bg-primary font-size-12 mb-1" @click="copyTextToClipboard(quickviewproduct.reference)">{{quickviewproduct.reference}}</span>
                   <h2 class="mr-auto my-auto text-primary">{{quickviewproduct.name}}</h2>
                                	
						            <p class="text-muted italic no-margin mb-3 mt-2" v-html="quickviewproduct.short_description"></p>
                             <h5 class="text-uppercase font-size-12 text-underline spaced-title">Consumer Pricing</h5>
                                <div class="mt-1 mb-1">
                                	<span class="badge badge-light font-size-14 bg-light text-muted mb-auto">{{companyCurrency}}{{quickviewproduct.sales_price}} <small>exc. VAT</small></span>
                                	<span class="badge badge-light font-size-14 bg-light text-muted mb-auto"> {{companyCurrency}}{{addTax(quickviewproduct.sales_price, quickviewproduct.tax_rule).toFixed(2)}} <small>inc. VAT</small></span>
                                </div>
                                <h5 class="text-uppercase font-size-12 text-underline spaced-title mt-3">Trade Pricing</h5>
                                <div class="mt-1 mb-1">
                                	<span class="badge badge-light font-size-14 bg-light text-muted mb-auto">{{companyCurrency}}{{quickviewproduct.trade_price}} <small>Exc. VAT</small></span>
                                	<span class="badge badge-light font-size-14 bg-light text-muted mb-auto">{{companyCurrency}}{{addTax(quickviewproduct.trade_price, quickviewproduct.tax_rule)}} <small>inc. VAT</small></span>
                                </div>
                               <div v-if="listing_id > 0" class="mt-2">

		                       		<div class="d-flex flex-row">
		                       			<h5 class="text-uppercase font-size-12 text-underline spaced-title my-auto">Quantity at this Shop:</h5>
		                       				<span class="badge badge-light my-auto mr-2 ml-auto bg-light p-2 font-size-12 border-radius-1em">
		                         			{{listingQuantity(quickviewproduct, listing_id)}}
		                         		</span>
		                         	</div>
		                       	</div>
					                       
					                        <!--<div class="d-flex mb-2">
					                        	<a id="tooltip-add" @click="quantity--" class="btn btn-outline-light font-size-16 btn-rounded mr-3 my-auto"><i class="mdi mdi-minus"></i></a>
					                        	<b-form-group label="" class="mx-auto">
													              <b-form-input type="number" v-model="quantity" /></b-form-group>
													           
													            
													            <a id="tooltip-add" @click="quantity++" class="btn btn-outline-light font-size-16 btn-rounded my-auto mr-2"><i class="mdi mdi-plus"></i></a>
					                        	<a class="btn btn-primary my-auto btn-rounded w-25" @click="addToCart(quickviewproduct, quantity)" >Add to Cart</a>
					                        </div>-->
					                         <div class="w-100 mt-4">
					                        	<a class="btn btn-primary btn-block btn-rounded" :href="'/stock/product/'+quickviewproduct.uid">Product Details</a>
					                        </div>
					                        <div class="w-100 mt-2">
					                        	<a class="btn btn-light btn-block btn-rounded"  :href="'/stock/product/'+quickviewproduct.uid+'#sales-stats'">Product Stock & Stats</a>
					                        </div>
					                      </b-card-body>
					                    </b-col>
					                  </b-row>
					                      </b-card>
          </b-modal>
     </div>
</template>