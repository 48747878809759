<script>
	import Multiselect from "vue-multiselect";
	export default{
		components: { Multiselect },
		data(){
			return{
				id: '',
				options: [
					{id: 1, name: 'text'},
					{id: 2, name: 'text2'}
				],
				search: '',
				currentPage: 1,
				order: 'desc',
      			sortby: 'id',
      			limit: 0,
				limits: [
		        {value: 25, text: "25"},
		        {value: 50, text: "50"},
		        {value: 100, text: "100"},
		    	],
				response_error: []
			}
		},
		props: {
			isDisabled: {
				type: Boolean,
				default: () => false,
				}
		},
		created(){
			axios.get('/api/company/users?page='+this.currentPage+'&limit='+this.limit+'&sortby='+this.sortby+'&order='+this.order+'&search='+this.search+'&list=full', {headers: {'Authorization' : 'Bearer '+this.$attrs.api_token}}).then(response => {
				this.options = response.data.data;
			}).catch(error => {
				this.response_error = error.data;
			});
			this.id = this.$attrs.id;
			
		},
		methods:{
			updateUser: function(selectedOption, id){
				this.$emit('user-selected', selectedOption);
			},
			removeUser: function(selectedOption, id){
				this.$emit('user-removed', selectedOption);
			},
			customLabel ({ title, desc }) {
		      return `${title} – ${desc}`
		    }
		}
	}
</script>
<template>
	<div>
	<multiselect v-model="id" track-by="email" label="name" :options="options" @select="updateUser" @remove="removeUser" :option-height="104" :disabled="isDisabled" >
                     <!--<template slot="singleLabel" slot-scope="{ option }">{{ option.name }} - {{ option.jobtitle }}</template>-->
                     <template slot="singleLabel" slot-scope="{ option }"><div class="d-flex w-100 my-auto"><img class="option__image rounded-circle" :src="option.avatar" v-if="option.avatar" width="50" alt="No Man’s Sky">
                     	<img v-else src="/images/product-placeholder.png" class="option__image rounded-circle" width="50">
      <div class="option__desc d-flex flex-row w-100"><span class="option__title ml-2 my-auto">{{ option.name }}<br><small>{{ option.jobtitle }}</small></span><span class="option__small ml-auto my-auto">{{ option.email }}</span>
      </div>
  		</div></template>
    <template slot="option" slot-scope="{ option }">
    	<div class="d-flex w-100 my-auto">
    		<img class="option__image rounded-circle" :src="option.avatar" v-if="option.avatar" width="50" alt="No Man’s Sky">
    		<img v-else src="/images/product-placeholder.png" class="option__image rounded-circle" width="50">
      <div class="option__desc d-flex flex-row w-50"><span class="option__title ml-2 my-auto">{{ option.name }}<br><small>{{ option.jobtitle }}</small></span><span class="option__small ml-auto my-auto">{{ option.email }}</span>
      </div>
  		</div>
    </template>
                  </multiselect>
                  <!--<pre class="language-json"><code>{{ value  }}</code></pre>-->
   </div>
</template>