<script>
import VueSlideBar from "vue-slide-bar";
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";

import SideSlidePanel from "../../components/widgets/SideSlidePanel";

import SelectCompanyProductAttribute from "../../components/widgets/SelectCompanyProductAttribute"

export default{
  components:{VueSlideBar, Layout, PageHeader, SideSlidePanel, SelectCompanyProductAttribute},
  data() {
      return {
          storageurl: 'https://'+this.$attrs.company.nickname+'.s3.eu-west-2.amazonaws.com',
          deleteMode: 'single',
          moveMode: 'single',
          csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
          brandsData: [],
          all_features: '',
          categoryProducts: [],
          selectedCategory: [],
          brands_error: [],
          products_error: [],   
          view: 'list',
          search: '',
          limit: '25',
          sortby: 'id',
          order: 'desc',
          selectedids: [],
          addNewPanel: false,
          editPanel: false,
          addValuePanel: false,
          editValuePanel: false,
          deleteValuePanel: false,
          loadingUpdateForm: false,
          customValues: 1,
          getProductsPanel: 0,
          error_reponse: [],
          error_message: false,
          success_message: false,
          success_text: '',
          loadingForm: false,
          brandsValueData: [],
          addvalue_parent: '',
          addPanelClass: 'edit-sidepanel',
          editPanelClass: 'edit-sidepanel',
          addValuePanelClass: 'edit-sidepanel',
          editValuePanelClass: 'edit-sidepanel',
          newfeature: {
            name: '',
            description: '',
            image: '',
            cover_image: '',
            order: '',
            slug: '',
            display_type: '',
            allow_cutclarity: null,
            active: 0,
          },
          newfeaturevalue: {
            name: '',
            description: '',
            image: '',
            cover_image: '',
            order: '',
            slug: '',
            display_type: '',
            active: 0,
          },
          editfeaturevalue: {
            name: '',
            description: '',
            image: '',
            cover_image: '',
            order: '',
            slug: '',
            display_type: '',
            active: 0,
          },
          editfeature: {
            name: '',
            description: '',
            image: '',
            cover_image: '',
            order: '',
            slug: '',
            display_type: '',
            allow_cutclarity: null,
            active: 0,
          },
          selected_parent: {id: null},
          customValues: 0,
          customValuesOptions:[
            { text: 'Marketplace', value: 0 },
            { text: 'Custom', value: 1 },
          ],
          limits: [
            {value: 25, text: "25"},
            {value: 50, text: "50"},
            {value: 100, text: "100"},
          ],
          title: "Components Attributes",
          items: [
            {
              text: "Stock",
              href: "javascript:;",
            },
            {
              text: "Components Attributes",
              active: true,
            },
          ],
          } 
  },
  methods:{
    editParentCategory(){

    },
    setActive(active, values){
            if(values == true){
              var posturl = '/api/company/product/component/attribute/set-active';
            }else{
              var posturl = '/api/company/product/component/attribute/set-active';
            }
            if(this.selected_parent !== ''){
               var posturl = '/api/company/product/component/attribute/'+this.selected_parent.id+'/value/set-active';
            }
            axios.post(posturl, {ids: this.selectedids, active: active}, {headers: {
              'Authorization' : 'Bearer '+this.$attrs.user.api_token
            }}).then(response => {
              
              this.success_text = 'Congratulations, your action has been saved';
              this.success_message = true;
              if(this.selectedids.length > 1){
                return this.brandQuery();
              }else{
                return true;
              }
                
            }).catch(error => {

            });
    },
  destroyResource(product_id){
            axios.post('/api/company/product/component/attribute/'+this.selectedids[0]+'/destroy', {}, {headers: {
              'Authorization' : 'Bearer '+this.$attrs.user.api_token
            }}).then(response => {
                this.$bvModal.hide('delete-modal');
                this.selectedids = [];
                return this.brandQuery();
            }).catch(error => {

            });
    },
     bulkDestroyResource(){
            axios.post('/api/company/product/component/attribute/bulk-destroy', {ids: this.selectedids}, {headers: {
              'Authorization' : 'Bearer '+this.$attrs.user.api_token
            }}).then(response => {
                this.$bvModal.hide('delete-modal');
                this.selectedids = [];
                return this.brandQuery();
            }).catch(error => {

            });
     },
     bulkDuplicateResource(attrs){
      axios.post('/api/company/product/component/attribute/duplicate', {ids: this.selectedids, withAttributes: attrs}, {headers : {
        'Authorization' : 'Bearer '+this.$attrs.user.api_token
      }}).then(response => {
        console.log(response);
        this.success_text = 'Congratulations, your category has been updated.';
        this.success_message = true;
        //this.resetProjectData();
        this.addPanel = false;
        let self = this;
          setTimeout(function(){
            self.loadingForm = false;
            self.brandQuery();
            //self.$bvModal.hide('move-modal');
          }, 1500);
      }).catch( error => {
        
        this.error_reponse = error.response.data.errors;
        this.error_message = true;
        //this.resetProjectData();
        let self = this;
          setTimeout(function(){
            self.loadingForm = false;
          }, 1500);
      });
    },
    brandQuery(){
      axios.get('/api/company/product/component/attributes?page='+this.currentPage+'&limit='+this.limit+'&sortby='+this.sortby+'&order='+this.order+'&search='+this.search, {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
        this.brandsData = response.data;
      }).catch(error => {
        this.brands_error = error.data;
      });
    },
    featuresQuery(){
      if(this.all_features == ''){
        axios.get('/api/company/product/component/attributes?page='+this.currentPage+'&limit='+this.limit+'&sortby='+this.sortby+'&order='+this.order+'&search='+this.search, {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
          this.all_features = response.data;
        }).catch(error => {
          this.brands_error = error.data;
        });
      }
    },
    getAttributeValues(){
      axios.get('/api/company/product/component/attribute/'+this.selected_parent.id+'/values', {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
              }}).then(response => {
        this.brandsData = response;
      }).catch(error => {
        this.brands_error = error;
      });
    },
    getAddAttributeValues(){
      axios.get('/api/company/product/component/attribute/'+this.addvalue_parent.id+'/values', {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
              }}).then(response => {
        this.brandsValueData = response;
      }).catch(error => {
        this.brands_error = error;
      });
    },
    addNewFeature(save_draft){
      this.loadingForm = true;
      var postForm = new FormData(document.getElementById("addfeatureform"));
      axios.post('/api/company/product/component/attribute/create', postForm, {headers: {
              'Authorization' : 'Bearer '+this.$attrs.user.api_token
            }}).then(response => {
        console.log(response);
        this.success_text = 'Your Component Attribute has been added successfully';
        this.success_message = true;
        //this.resetProjectData();
        let self = this;
          setTimeout(function(){
            self.loadingForm = false;
            self.addNewPanel = save_draft;
            self.brandQuery();
            self.selected_parent = {id: null};
          }, 500);
      }).catch( error => {
        
        this.error_reponse = error.response.data.errors;
        this.error_message = true;
        //this.resetProjectData();
        let self = this;
          setTimeout(function(){
            self.loadingForm = false;
          }, 500);
      });
    },
    resetFeatureValue(){
      this.editfeaturevalue = {
                name: '',
                description: '',
                image: '',
                cover_image: '',
                order: '',
                slug: '',
                active: 0,
              };
    },
    resetFeature(){
      this.editfeature = {
                name: '',
                description: '',
                image: '',
                cover_image: '',
                order: '',
                slug: '',
                display_type: '',
                active: 0,
              };
    },
    updateFeature(){
      this.loadingForm = true;
      var postForm = new FormData(document.getElementById("updatefeatureform"));
      axios.post('/api/company/product/component/attribute/'+this.editfeature.id+'/update', postForm, {headers: {
              'Authorization' : 'Bearer '+this.$attrs.user.api_token
            }}).then(response => {
        console.log(response);
        this.success_text = 'Your Component Attributehas been updated successfully';
        this.success_message = true;
        //this.resetProjectData();
        let self = this;
          setTimeout(function(){
            self.loadingForm = false;
            self.brandQuery();
            self.selected_parent = {id: null};
          }, 500);
      }).catch( error => {
        
        this.error_reponse = error.response.data.errors;
        this.error_message = true;
        //this.resetProjectData();
        let self = this;
          setTimeout(function(){
            self.loadingForm = false;
          }, 500);
      });
    },
    addNewFeatureValue(save_draft){
         this.loadingForm = true;
      var postForm = new FormData(document.getElementById("addfeaturevalueform"));
      axios.post('/api/company/product/component/attribute/'+this.addvalue_parent.id+'/value/create', postForm, {headers: {
              'Authorization' : 'Bearer '+this.$attrs.user.api_token
            }}).then(response => {
        console.log(response);
        this.success_text = 'Your value has been added successfully';
        this.success_message = true;
        //this.resetProjectData();
        //this.addPanel = false;
        this.selected_parent = this.addvalue_parent;
        this.getAttributeValues();
        this.getAddAttributeValues();
        let self = this;
          setTimeout(function(){
            self.loadingForm = false;
            self.addValuePanel = save_draft;
            self.newfeaturevalue ={
                name: '',
                description: '',
                image: '',
                cover_image: '',
                order: '',
                slug: '',
                active: 0,
              };
          }, 500);
      }).catch( error => {
        
        this.error_reponse = error.response.data.errors;
        this.error_message = true;
        //this.resetProjectData();
        let self = this;
          setTimeout(function(){
            self.loadingForm = false;
          }, 500);
      });
    },
    updateFeatureValue(){
      this.loadingUpdateForm = true;
      var postForm = new FormData(document.getElementById("updatefeaturevalueform"));
      axios.post('/api/company/product/component/attribute/value/'+this.editfeaturevalue.id+'/update', postForm, {headers: {
              'Authorization' : 'Bearer '+this.$attrs.user.api_token
            }}).then(response => {
        console.log(response);
        this.success_text = 'Your value has been updated successfully';
        this.success_message = true;
        //this.resetProjectData();
        //this.addPanel = false;
        this.editfeaturevalue.image = response.data.image;
        let self = this;
          setTimeout(function(){
            self.loadingUpdateForm = false;
          }, 500);
      }).catch( error => {
        
        this.error_reponse = error.response.data.errors;
        this.error_message = true;
        //this.resetProjectData();
        let self = this;
          setTimeout(function(){
            self.loadingForm = false;
          }, 500);
      });
    },
    deleteFeatureValue(){
      this.loadingUpdateForm = true;
      axios.post('/api/company/product/component/attribute/value/'+this.editfeaturevalue.id+'/destroy', {}, {headers: {
              'Authorization' : 'Bearer '+this.$attrs.user.api_token
            }}).then(response => {
        console.log(response);
        this.success_text = 'Your Component Attributehas been deleted successfully';
        this.success_message = true;
        //this.resetProjectData();
        //this.addPanel = false;
        //this.selected_parent = this.addvalue_parent;
        this.resetFeatureValue();
        this.getAttributeValues();
        this.getAddAttributeValues();
        let self = this;
          setTimeout(function(){
            self.loadingUpdateForm = false;
            self.deleteValuePanel = false;
            self.addValuePanel = false;
          }, 500);
      }).catch( error => {
        
        this.error_reponse = error.response.data.errors;
        this.error_message = true;
        //this.resetProjectData();
        let self = this;
          setTimeout(function(){
            self.loadingUpdateForm = false;
          }, 500);
      });
    },
    priceIncVat(price, tax_percentage){
      var tax = 1 + (tax_percentage / 100);
      return price * tax;
    },
    filterSelected(id){
        if(this.selectedids.length > 0){
          var exists = this.selectedids.filter(item => item == id);
          if(exists.length > 0){
            return true;
          }else{
            return false
          }
        }else{
          return false;
        }
      },
      checkAll: function(){ 
                 this.isCheckAll = !this.isCheckAll; 
                 this.selectedids = []; 
                 if(this.isCheckAll){ 
                     // Check all 
                     for (var key in this.brandsData.data) {          
                        this.selectedids.push(this.brandsData.data[key].id); 
                     } 
                 } 
              }, 
      updateCheckall: function(){ 
                 if(this.selectedids.length == this.brandsData.data.length){
                    this.isCheckAll = true; 
                 }else{ 
                    this.isCheckAll = false; 
                 } 
      },
      sanitizeTitle: function(title) {
        if(title !== ''){
            var nickname = "";
            // Change to lower case
            var titleLower = title.toLowerCase();
            // Letter "e"
            nickname = titleLower.replace(/e|é|è|ẽ|ẻ|ẹ|ê|ế|ề|ễ|ể|ệ/gi, 'e');
            // Letter "a"
            nickname = nickname.replace(/a|á|à|ã|ả|ạ|ă|ắ|ằ|ẵ|ẳ|ặ|â|ấ|ầ|ẫ|ẩ|ậ/gi, 'a');
            // Letter "o"
            nickname = nickname.replace(/o|ó|ò|õ|ỏ|ọ|ô|ố|ồ|ỗ|ổ|ộ|ơ|ớ|ờ|ỡ|ở|ợ/gi, 'o');
            // Letter "u"
            nickname = nickname.replace(/u|ú|ù|ũ|ủ|ụ|ư|ứ|ừ|ữ|ử|ự/gi, 'u');
            // Letter "d"
            nickname = nickname.replace(/đ/gi, 'd');
            // Trim the last whitespace
            nickname = nickname.replace(/\s*$/g, '');
            // Change whitespace to "-"
            nickname = nickname.replace(/\s+/g, '-');
            // Remove quotations
            nickname = nickname.replace(/["']/g, "");
            // Remove symbols
            nickname = nickname.replace(/@|#|!/g, '');
            return nickname;
          }
        }
  },
  created(){
    this.brandQuery();
  }
}
</script>

<template>
  <Layout  :nickname="$attrs.nickname" :user="$attrs.user" :menuItems="$attrs.menuItems">
    <PageHeader :title="title" :items="items" />
        <div class="row">
          <b-col md="12">
                    <transition name="slide-success" mode="out-in">
                  <b-alert id="success-message" variant="success" :show="success_message"  dismissible @dismissed="success_message = !success_message">
                      <p class="font-size-14">{{success_text}}</p>
                    </b-alert>
                  </transition>
                  </b-col>
      <div class="col-md-12">
            <div class="row mb-3">
          <div class="col-xl-1 col-sm-6">
            <div class="mt-2">
              <b-form-select class="form-control" v-model="limit" :options="limits" @input="productQuery()"></b-form-select>
            </div>
          </div>
         
          <div class="col-lg-2 col-sm-6 d-flex">
          <form class="my-auto">
            <small class="mx-auto my-2 text-center d-block">Results {{brandsData.from}} - {{brandsData.to}} of {{brandsData.total}}</small>
          </form>
        </div>
        <div class="col-lg-5 col-sm-6 d-flex">
            <div class="mt-sm-0 form-inline w-100" >
              <div class="search-box mr-2 ml-auto">
                <div class="position-relative">
                  <input
                    type="text"
                    class="form-control w-100 border-0"
                    placeholder="Search name, reference or description..."
                    v-model="search"
                    @keyup.enter="searchFilter($event)"
                  />
                  <i class="bx bx-search-alt search-icon"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-sm-6 d-flex">
            <div class="d-flex ml-auto">
              <ul class="nav nav-pills product-view-nav">
                
                <!--<li class="nav-item">
                  <a class="nav-link" :class="{'active': view == 'grid' }" href="javascript: void(0);" @click="view = 'grid'">
                    <i class="bx bx-grid-alt"></i>
                  </a>
                </li>-->
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': view == 'list' }" href="javascript: void(0);" @click="view = 'list'">
                    <i class="bx bx-list-ul"></i>
                  </a>
                </li>
                <li class="nav-item">
                 <b-dropdown text="Bulk Actions" variant="primary" class="rounded">
                  <b-dropdown-item href="javascript: void(0);" :disabled="($attrs.user.companies[0].userrole.name !== 'Owner') && !$attrs.user.companies[0].permissions.edit.includes($attrs.active_menu.id)" @click="setActive(true, false)"><i class="bx bx-check-circle font-size-14 text-success"></i> Set Active</b-dropdown-item>
                  <b-dropdown-item :disabled="($attrs.user.companies[0].userrole.name !== 'Owner') && !$attrs.user.companies[0].permissions.edit.includes($attrs.active_menu.id)" href="javascript: void(0);" @click="setActive(false, false)"><i class="bx bx-x-circle font-size-14 text-danger"></i> Set Inactive</b-dropdown-item>
                  <b-dropdown-item :disabled="($attrs.user.companies[0].userrole.name !== 'Owner') && !$attrs.user.companies[0].permissions.edit.includes($attrs.active_menu.id)" href="javascript: void(0);" @click="bulkDuplicateResource(false)"><i class="bx bx-copy-alt font-size-14"></i> Duplicate</b-dropdown-item>
                  <b-dropdown-item :disabled="($attrs.user.companies[0].userrole.name !== 'Owner') && !$attrs.user.companies[0].permissions.edit.includes($attrs.active_menu.id)" href="javascript: void(0);" @click="bulkDuplicateResource(true)"><i class="bx bx-copy-alt font-size-14"></i> Duplicate with Values</b-dropdown-item>
                  <b-dropdown-item :disabled="($attrs.user.companies[0].userrole.name !== 'Owner') && !$attrs.user.companies[0].permissions.edit.includes($attrs.active_menu.id)" v-if="(selected_parent.id !== null)" v-b-modal.move-modal @click="featuresQuery(), moveMode = 'bulk'">
                    <i class="bx bx-move"></i> Move Value
                  </b-dropdown-item>
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-item :disabled="($attrs.user.companies[0].userrole.name !== 'Owner') && !$attrs.user.companies[0].permissions.delete.includes($attrs.active_menu.id)" href="javascript: void(0);" v-b-modal.delete-modal @click="deleteMode = 'bulk'"><i class="bx bx-trash-alt font-size-14"></i> Delete</b-dropdown-item>
                </b-dropdown>
                </li>
                <li class="nav-item">
                 <b-button
                    variant="success"
                   :disabled="($attrs.user.companies[0].userrole.name !== 'Owner') && !$attrs.user.companies[0].permissions.add.includes($attrs.active_menu.id)"
                    class="btn btn-success btn-rounded mb-2 text-white"
                    href="javascript:;"
                    @click="addNewPanel = !addNewPanel"
                  >
                    <i class="mdi mdi-plus mr-1"></i> New Component Attribute
                  </b-button>
                  <b-button
                  variant="success"
                    v-if="(selected_parent.id !== null) && (selected_parent.name !== null)"
                    class="btn btn-success btn-rounded mb-2 text-white"
                    href="javascript:;"
                    :disabled="($attrs.user.companies[0].userrole.name !== 'Owner') && (!$attrs.user.companies[0].permissions.add.includes($attrs.active_menu.id) || !$attrs.user.companies[0].permissions.edit.includes($attrs.active_menu.id))"
                    @click="addvalue_parent = selected_parent, getAddAttributeValues(), addValuePanel = true"
                  >
                    <i class="mdi mdi-plus mr-1"></i> New Component Value
                  </b-button>
                </li>
              </ul>
              <div class="text-sm-right">
                  
                </div>
          </div>
        </div>
      </div>

      <div class="d-flex flex-column justify-content-center h-100" v-if="($attrs.user.companies[0].userrole.name !== 'Owner') && !$attrs.user.companies[0].permissions.read.includes($attrs.active_menu.id)" >
          <AccessRestricted :page="false"></AccessRestricted>
        </div>
        <div v-else>
        <div class="card" v-if="view == 'list'" >
          <div class="card-body p-0">
            <div class="col-md-12 bg-light">
                 <tr class="d-flex w-100 bg-light" v-if="(selected_parent.name != '') && (selected_parent.name != null)">
                    <a v-if="selected_parent.parent != null" class="btn btn-primary btn-soft btn-rounded mx-4 my-3" @click="selected_parent = selected_parent.parent, brandQuery()"><i class="bx bx-chevron-left float-left font-size-16  px-1 py-2"></i></a>
                    <a v-if="selected_parent.parent == null" class="btn btn-primary btn-soft btn-rounded mx-4 my-3" @click="selected_parent.id = selected_parent.parent_id, selected_parent.name = null, brandQuery()"><i class="bx bx-chevron-left float-left font-size-16  px-1 py-2"></i></a>
                    <h5 class="float-right mx-2 my-auto p-1"><span class="text-primary">{{selected_parent.name}}</span> Values</h5> 
                  </tr>
                    <tr v-else  class="d-flex w-100 bg-light">
                      <a class="btn btn-primary btn-soft btn-rounded mx-4 my-3"><i class="bx bx-home float-left font-size-16  px-1 py-2"></i></a> 
                      <h5 class="float-right mx-2 my-auto p-1">Root</h5> 
                    </tr>

                </div>
            <div class="table-responsive">
              <table class="table table-centered table-hover table-wrap">

                <thead class="thead-light">
                  <tr>
                     <th scope="col"  style="width: 100px" class="text-center"><div
                        class="form-check form-switch form-switch-sm mb-0 mx-auto p-0 mt-auto d-flex"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input mx-auto my-auto" 
                          type="checkbox"
                          id="SwitchCheckSizesm"
                         
                          v-model="isCheckAll"
                          @click='checkAll()'
                          name="active"
                        />
                    </div></th>
                    <th scope="col"><a :class="{'text-dark': sortby != 'name'}" href="javascript: void(0);" @click="orderDesc = !orderDesc,  sortby = 'name', brandQuery()">Component Attributes <i v-if="orderDesc == true" class="bx bx-caret-up"></i><i v-if="orderDesc == false" class="bx bx-caret-down"></i></a></th>
                    <th scope="col" style="width: 150px"><span v-if="selected_parent.id == null">Values</span></th>
                    <th scope="col" style="width: 150px"><span v-if="selected_parent.id == null">Add Value</span></th>
                    <th scope="col">Active</th>
                    <th scope="col">Action</th>
                    
                  </tr>
                </thead>
               
                <tbody>
                  
                  <tr v-for="list in brandsData.data" :key="list.id" valign="middle">
                    <td>
                       <div class="text-center" v-if="list.image">
                      <b-form-checkbox class="select-row-btn" v-model="selectedids" :value="list.id" button-variant="primary" @input="updateSelected" button>
                        <i v-if="filterSelected(list.id)" class="bx bx-check"></i>
                        <img v-else :src="storageurl +'/'+ list.image.replaceAll('https://', '').replaceAll('//', '/')" alt class="avatar-sm rounded-circle" />
                      </b-form-checkbox>
                    </div>
                    <div class="text-center" v-else >
                        <b-form-checkbox class="select-row-btn" v-model="selectedids" :value="list.id" button-variant="primary" @input="updateSelected" button>

                          <i v-if="filterSelected(list.id)" class="bx bx-check"></i><span class="avatar-title rounded-circle" v-else>{{(list.name).slice(0,1)}}</span></b-form-checkbox>
                    </div>
                    </td>
                    <td>
                        <h5 class="m-0">{{list.name}}</h5>
                     <small class="text-muted mb-0" v-if="list.short_description != null" v-html="(list.short_description).slice(0, 200)+'...'"></small>
                    </td>
                    <td style="width: 150px;">
                      <a href="javascript:;"  v-if="selected_parent.id == null"  @click="selected_parent = list, getAttributeValues()" class="btn btn-outline-light btn-rounded"><i class="bx bx-list-ul"></i></a>
                    </td>
                    <td style="width: 150px;">
                      <a href="javascript:;"  v-if="(selected_parent.id == null) && ($attrs.user.companies[0].permissions.edit.includes($attrs.active_menu.id) || ($attrs.user.companies[0].userrole.name == 'Owner'))" @click="addvalue_parent = list, getAddAttributeValues(), addValuePanel = true" class="btn btn-outline-light btn-rounded"><i class="mdi mdi-plus"></i></a>
                    </td>
                    <td>
                      <div
                        class="form-check form-switch form-switch-sm mb-0 mt-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          v-model="list.active"
                          :id="'SwitchCheckSizesm'+list.id"
                          :value="list.active"
                          name="active"
                          :disabled="($attrs.user.companies[0].userrole.name !== 'Owner') && !$attrs.user.companies[0].permissions.edit.includes($attrs.active_menu.id)"
                          @change="selectedids = [], selectedids.push(list.id), setActive(list.active, false)"
                        />
                        <label class="form-check-label" :for="'SwitchCheckSizesm'+list.id"
                          >Active</label
                        >
                    </div>
                    </td>
                    <td>
                      <ul class="list-inline font-size-20 contact-links mb-0"  v-if="selected_parent.id == null" >
                        <li class="list-inline-item px-2"><a v-if="($attrs.user.companies[0].userrole.name == 'Owner') || $attrs.user.companies[0].permissions.edit.includes($attrs.active_menu.id)" v-b-tooltip.hover title="Edit" placement="left" @click="editfeature = list, editPanel = true"><i class="bx bx-edit-alt"></i>
                          </a>
                        </li>
                        <li class="list-inline-item px-2"><a v-if="($attrs.user.companies[0].userrole.name == 'Owner') || $attrs.user.companies[0].permissions.delete.includes($attrs.active_menu.id)" v-b-tooltip.hover title="Delete" placement="left" v-b-modal.delete-modal @click="selectedids = [], selectedids.push(list.id), deleteMode = 'single'"><i class="bx bx-trash-alt"></i>
                          </a>
                        </li>
                        <li class="list-inline-item px-2">
                           <b-dropdown v-b-tooltip.hover title="More Options" placement="left"dropleft class="more-options-dropdown"  menu-class="dropdown-menu-right">
                        <template v-slot:button-content>
                           <i class="bx bx-dots-horizontal-rounded"></i>
                        </template>
                        <b-dropdown-item href="javascript: void(0);"  @click="selectedids = [], selectedids.push(list.id), bulkDuplicateResource()"><i class="bx bx-copy-alt"></i> Duplicate</b-dropdown-item>
                      </b-dropdown>
                        </li>
                      </ul>

                      <ul class="list-inline font-size-20 contact-links mb-0"  v-else >
                        <li class="list-inline-item px-2"><a v-b-tooltip.hover v-if="($attrs.user.companies[0].userrole.name == 'Owner') || $attrs.user.companies[0].permissions.edit.includes($attrs.active_menu.id)" title="Edit" placement="left" @click="addvalue_parent = list, getAddAttributeValues(), addValuePanel = true, editfeaturevalue = list, editValuePanel = true"><i class="bx bx-edit-alt"></i>
                          </a>
                        </li>
                        <li class="list-inline-item px-2"><a v-b-tooltip.hover v-if="($attrs.user.companies[0].userrole.name == 'Owner') || $attrs.user.companies[0].permissions.delete.includes($attrs.active_menu.id)" title="Delete" placement="left" @click="addvalue_parent = list, getAddAttributeValues(), addValuePanel = true, editfeaturevalue = list, deleteValuePanel = true"><i class="bx bx-trash-alt"></i>
                          </a>
                        </li>
                        <li class="list-inline-item px-2">
                           <b-dropdown v-b-tooltip.hover title="More Options" placement="left"dropleft class="more-options-dropdown"  menu-class="dropdown-menu-right">
                        <template v-slot:button-content>
                           <i class="bx bx-dots-horizontal-rounded"></i>
                        </template>
                        <b-dropdown-item :disabled="($attrs.user.companies[0].userrole.name !== 'Owner') && !$attrs.user.companies[0].permissions.edit.includes($attrs.active_menu.id)" href="javascript: void(0);"  @click="selectedids = [], selectedids.push(list.id), bulkDuplicateResource()"><i class="bx bx-copy-alt"></i> Duplicate</b-dropdown-item>
                        <b-dropdown-item :disabled="($attrs.user.companies[0].userrole.name !== 'Owner') && !$attrs.user.companies[0].permissions.edit.includes($attrs.active_menu.id)" href="javascript: void(0);" v-b-modal.move-modal @click="featuresQuery(), moveMode = 'single'"><i class="bx bx-move"></i> Move Value</b-dropdown-item>
                      </b-dropdown>
                        </li>
                      </ul>
                    </td>
                    
                  </tr>
                  
                </tbody>
              </table>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <b-pagination
              v-if="brandsData !== ''"
              class="justify-content-center"
              pills
              v-model="currentPage"
              :total-rows="brandsData.total"
              :per-page="brandsData.per_page"
              aria-controls="my-table"
              @input="brandQuery"
            ></b-pagination>
             <small class="mx-auto my-2 text-center d-block">Records {{brandsData.from}} - {{brandsData.to}} of {{brandsData.total}} // Page {{brandsData.current_page}} of {{brandsData.last_page}}</small>
              </div>
            </div>
          </div>
      </div>
    <div class="row" v-if="view == 'grid'" >
      <div class="col-md-12">
        <div class="card">
                  <tr v-if="(selected_parent.name != '') && (selected_parent.name != null)">
                    <a v-if="selected_parent.parent != null" class="btn btn-outline-light btn-rounded m-3" @click="selected_parent = selected_parent.parent, brandQuery()"><i class="bx bxs-caret-left-circle float-left"></i></a>
                    <a v-if="selected_parent.parent == null" class="btn btn-outline-light btn-rounded m-3" @click="selected_parent.id = selected_parent.parent_id, selected_parent.name = null, brandQuery()"><i class="bx bxs-caret-left-circle float-left"></i></a> <h5 class="float-right m-3 p-1">{{selected_parent.name}}</h5> 
                  </tr>
                    <tr v-else>
                      <a class="btn btn-outline-light btn-rounded m-3"><i class="bx bxs-home float-left"></i></a> <h5 class="float-right m-3 p-1">Root</h5> 
                    </tr>
                  </div>
                </div>
      <div class="col-xl-4"  v-for="list in brandsData.data" :key="list.id">
        <div class="card overflow-hidden">
          <div class="bg-soft-primary min-h-100">
            <div class="row">
              <div class="col-7">
                <div class="text-primary p-3">
                  
                </div>
              </div>
              <div class="col-5 align-self-end">
                <img src="/images/profile-img.png" alt class="img-fluid" />
              </div>
            </div>
          </div>
          <div class="card-body pt-0">
            <div class="row">
              <div class="col-sm-4">
                <div class="avatar-md profile-user-wid mb-4">
                  <img class="img-thumbnail rounded-circle h-100" :src="`/public/storage/${list.logo}`" alt />
                </div>
                <h5 class="font-size-15 text-truncate">Cynthia Price</h5>
                <p class="text-muted mb-0 text-truncate">UI/UX Designer</p>
              </div>

              <div class="col-sm-8">
                <div class="pt-4">
                  <div class="row">
                    <div class="col-6">
                      <h5 class="font-size-15">125</h5>
                      <p class="text-muted mb-0">Products</p>
                    </div>
                    <div class="col-6">
                      <h5 class="font-size-15">$1245</h5>
                      <p class="text-muted mb-0">Revenue</p>
                    </div>
                  </div>
                  <div class="mt-4">
                    <a href class="btn btn-primary btn-sm">
                      View Brand Profile
                      <i class="mdi mdi-arrow-right ml-1"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
      </div>
    </div>
    </div>
</div>
<b-modal id="move-modal" size="md" hide-footer hide-header centered>
        <b-card class="border-radius-1em border shadow-none">
          <h4>Move Value</h4>
           <p class="mt-3">New Parent Feature</p>
          <!--<FilterListTree :category_data="brandsData.data"></FilterListTree>-->
          <!--<input type="hidden" name="parent_id" :value="update_parent_id">-->
           <b-overlay :show="all_features == ''">
          <div v-if="all_features !== ''" class="border border-radius-1em">
           
             <SelectCompanyProductAttribute :id="''" :categories_data="all_features.data" :user="$attrs.user" :isDisabled="false" :marketplace_categories="'company'" @attribute-selected="editParentCategory"></SelectCompanyProductAttribute>
            
          </div>
          </b-overlay>
          <a class="btn btn-primary btn-rounded btn-block mt-3 w-100" v-if="moveMode == 'single'" @click="moveCategory()">
            Move Category
          </a>
          <a class="btn btn-primary btn-rounded btn-block mt-3 w-100" v-if="moveMode == 'bulk'" @click="bulkMoveCategory()">
            Move Category
          </a>
        </b-card>
      </b-modal>
<b-modal id="delete-modal" size="md" hide-footer hide-header centered>
        <b-card class="border-radius-1em border shadow-none">
          <h4>Are you sure?</h4>
          <p v-if="deleteMode == 'bulk'">You are about to delete {{selectedids.length}} products. Are you sure you want to do this? This action is not reversable</p>
           <p v-if="deleteMode == 'single'">You are about to delete this category. Are you sure you want to do this? This action is not reversable</p>

           <div class="d-flex">
            <a class="btn btn-danger" v-if="deleteMode == 'bulk'" @click="bulkDestroyResource">
              Delete
            </a>
             <a class="btn btn-danger"  v-if="deleteMode == 'single'" @click="destroyResource">
              Delete
            </a>
            <a class="btn btn-success" @click="$bvModal.hide('delete-modal')">
              Cancel
            </a>
          </div>
        </b-card>
      </b-modal>
  <SideSlidePanel :editPanel="getProductsPanel" :panelClass="'edit-sidepanel'" @close-panel="getProductsPanel = 0">
    <div class="row">
        <div class="d-flex flex-column justify-content-center h-100" v-if="($attrs.user.companies[0].userrole.name !== 'Owner') && !$attrs.user.companies[0].permissions.view.includes($attrs.active_menu.id)" >
          <AccessRestricted :page="false"></AccessRestricted>
        </div>
        <div class="col-md-12" v-else>
        <b-card>
          <h4 class="my-3 pb-2">Products in <strong class="text-primary">{{selectedCategory.name}}</strong> <a id="tooltip-add" class="btn btn-outline-light font-size-16 btn-rounded float-right"><i class="mdi mdi-plus"></i></a><b-tooltip target="tooltip-add">Add Product</b-tooltip></h4>
          <b-list-group>
            <b-list-group-item href="#some-link" v-for="product in categoryProducts.data">
              <img :src="'/public/storage/'+product.cover_image" width="85" class="float-left mr-4"> 
            <strong class="text-muted">{{product.name}}</strong><a id="tooltip-remove" class="btn btn-rounded btn-outline-light  float-right font-size-16 my-4 mx-1"><i class="bx bx-trash"></i></a>
            <b-tooltip target="tooltip-remove">Remove from Category</b-tooltip>
            <a id="tooltip-edit" class="btn btn-rounded btn-outline-light  float-right font-size-16 my-4 mx-1"><i class="bx bx-pencil"></i></a>
            <b-tooltip target="tooltip-edit">Edit Product</b-tooltip>
            <small class="text-muted italic no-margin" v-html="product.short_description"></small>
            <strong class="text-muted">£{{product.sales_price}} <small class="mr-3">exc. VAT</small>   £{{priceIncVat(product.sales_price, product.tax_rule.percentage)}} <small class="mr-3">inc. VAT</small> £{{product.trade_price}} <small class="mr-3">Trade Price</small></strong>

            </b-list-group-item>
          </b-list-group>
        </b-card>
        </div>
    </div>
  </SideSlidePanel>
  <SideSlidePanel :editPanel="addNewPanel" :panelClass="addPanelClass" @close-panel="addNewPanel = !addNewPanel, resetFeature()" :expandable="1"  @expand="addPanelClass = 'edit-sidepanel-large'" @contract="addPanelClass = 'edit-sidepanel'">
       <b-row v-if="addNewPanel == true">
                     <b-col md="12">
            
                    <transition name="slide-error" mode="out-in">
                    <b-alert id="errors" variant="danger" :show="error_message"  dismissible @dismissed="error_message = !error_message">
                      <p class="font-size-14">Oops! Please correct the following errors in your submission</p>
                      <ul>
                        <li v-for="(error, key) in error_reponse">
                          {{error[0]}}
                        </li>
                      </ul>
                    </b-alert>
                    
                  </transition>
                  </b-col>
                  </b-row>
                  <div class="d-flex flex-column justify-content-center h-100" v-if="($attrs.user.companies[0].userrole.name !== 'Owner') && !$attrs.user.companies[0].permissions.add.includes($attrs.active_menu.id)" >
          <AccessRestricted :page="false"></AccessRestricted>
        </div>
                    <b-row v-else>
                    <b-card class="shadow-none">
                    <b-col md="12">
                      <h5 class="text-primary">Add New Component Attribute</h5>
                      <hr>
                      <b-overlay
                        id="overlay-background"
                        :show="loadingForm"
                        variant="white"
                        opacity="0.85"
                        blur="1px"
                        rounded="md"
                      >
         <b-form id="addfeatureform" method="POST" enctype="multipart/form-data">
                   <input type="hidden" name="_token" :value="csrf">
                    <b-row>
                      <b-col md="12">
                    <b-form-group label="Component Attribute Name">
                      <b-form-input name="name" type="text" v-model="newfeature.name"></b-form-input>
                      <input name="slug" type="hidden" :value="sanitizeTitle(newfeature.name)">
                    </b-form-group>
                  </b-col>
                  <b-col md="12" class="mt-2">
                    <b-form-group label="Component Attribute Description">
                      <b-form-textarea name="description" type="text"></b-form-textarea>
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                     <b-form-group class="mt-2" label="Allow Clarity, Cut and Color Assignment">
                      <b-form-radio-group v-model="newfeature.allow_cutclarity" name="allow_cutclarity" :options="[{text: 'True', value: 1 }, {text: 'False', value: null }]">
                      </b-form-radio-group>
                     </b-form-group>
                   </b-col>
                 <!-- <b-col md="12">
                     <b-form-group class="mt-2" label="Display Type">
                      <b-form-select class="form-control" :options="[
                        {value: 'dropdown', text: 'Dropdown'},
                        {value: 'buttons', text: 'Buttons'},
                        {value: 'image', text: 'Image/Icons'},
                      ]" name="display_type" type="text" v-model="newfeature.display_type"></b-form-select>
                    </b-form-group>
                  </b-col>-->
                  <b-col md="8">
                    <b-form-group class="mt-2" label="Image or Icon">
                      <input class="form-control mb-2" name="image" type="file">
                    </b-form-group>
                  </b-col>
                  <b-col md="4" class="d-flex">
                    <b-form-group class=" my-auto pt-3">
                     <div
                        class="form-check form-switch form-switch-sm mb-0 mt-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          id="newbrand-owner"
                          v-model="newfeature.active" value="true"
                          unchecked-value="false"
                          name="active"
                        />
                        <label class="form-check-label" for="newbrand-owner" 
                          > Active</label
                        >
                      </div>
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-button variant="success" @click="addNewFeature(false)"><i class="bx bx-save"></i> Save</b-button>
                    <b-button variant="success" @click="addNewFeature(true)"><i class="bx bx-save"></i> Save and Add New</b-button>
                  </b-col>
                  </b-row>
                  </b-form>
                </b-overlay>
                </b-col>
              </b-card>
              </b-row>
  </SideSlidePanel>

    <SideSlidePanel :editPanel="editPanel" :panelClass="editPanelClass" @close-panel="editPanel = !editPanel" :expandable="1"  @expand="editPanelClass = 'edit-sidepanel-large'" @contract="editPanelClass = 'edit-sidepanel'">
       <b-row v-if="editPanel == true">
                     <b-col md="12">
            
                    <transition name="slide-error" mode="out-in">
                    <b-alert id="errors" variant="danger" :show="error_message"  dismissible @dismissed="error_message = !error_message">
                      <p class="font-size-14">Oops! Please correct the following errors in your submission</p>
                      <ul>
                        <li v-for="(error, key) in error_reponse">
                          {{error[0]}}
                        </li>
                      </ul>
                    </b-alert>
                    
                  </transition>
                  </b-col>
                  </b-row>
                   <div class="d-flex flex-column justify-content-center h-100" v-if="($attrs.user.companies[0].userrole.name !== 'Owner') && !$attrs.user.companies[0].permissions.edit.includes($attrs.active_menu.id)" >
          <AccessRestricted :page="false"></AccessRestricted>
        </div>
                    <b-row v-else>
                    <b-card class="shadow-none">
                    <b-col md="12">
                      <h5>Edit Feature: <span class="text-primary">{{editfeature.name}}</span></h5>
                      <hr>
                      <b-overlay
                        id="overlay-background"
                        :show="loadingForm"
                        variant="white"
                        opacity="0.85"
                        blur="1px"
                        rounded="md"
                      >
         <b-form id="updatefeatureform" method="POST" enctype="multipart/form-data">
                   <input type="hidden" name="_token" :value="csrf">
                    <b-row>
                      <b-col md="12">
                    <b-form-group label="Feature Name">
                      <b-form-input v-model="editfeature.name" name="name" type="text"></b-form-input>
                      <input v-if="(editfeature.name !== '') && (editfeature.name !== null)" type="hidden" name="slug" :value="sanitizeTitle(editfeature.name)">
                    </b-form-group>
                  </b-col>
                  <b-col md="12" class="mt-2">
                    <b-form-group label="Feature Description">
                      <b-form-textarea v-model="editfeature.description" name="description" type="text"></b-form-textarea>
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                     <b-form-group class="mt-2" label="Allow Clarity, Cut and Color Assignment">
                      <b-form-radio-group v-model="editfeature.allow_cutclarity" name="allow_cutclarity" :options="[{text: 'True', value: 1 }, {text: 'False', value: null }]">
                      </b-form-radio-group>

                      
                     </b-form-group>
                   </b-col>
                  <!--<b-col md="12">
                     <b-form-group class="mt-2" label="Display Type">
                      <b-form-select class="form-control" :options="[
                        {value: 'dropdown', text: 'Dropdown'},
                        {value: 'buttons', text: 'Buttons'},
                        {value: 'image', text: 'Image/Icons'},
                      ]" name="display_type" type="text" v-model="editfeature.display_type"></b-form-select>
                    </b-form-group>
                  </b-col>-->
                  <b-col md="8">
                    <b-form-group class="mt-2" label="Image or Icon">
                      <input class="form-control mb-2" name="image" type="file">
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group class=" my-4 pt-3">
                     <div
                        class="form-check form-switch form-switch-sm mb-0 mt-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          id="newbrand-owner"
                          v-model="editfeature.active" value="true"
                          unchecked-value="false"
                          name="active"
                        />
                        <label class="form-check-label" for="newbrand-owner" 
                          > Active</label
                        >
                      </div>
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-button variant="success" @click="updateFeature()"><i class="bx bx-save"></i> Save</b-button>
                  </b-col>
                  </b-row>
                  </b-form>
                </b-overlay>
                </b-col>
              </b-card>
              </b-row>
  </SideSlidePanel>

   <SideSlidePanel :editPanel="addValuePanel" :panelClass="addValuePanelClass" @close-panel="addValuePanel = !addValuePanel" :expandable="1"  @expand="addValuePanelClass = 'edit-sidepanel-large'" @contract="addValuePanelClass = 'edit-sidepanel'">
       <b-row v-if="addValuePanel == true">
                     <b-col md="12">
            
                    <transition name="slide-error" mode="out-in">
                    <b-alert id="errors" variant="danger" :show="error_message"  dismissible @dismissed="error_message = !error_message">
                      <p class="font-size-14">Oops! Please correct the following errors in your submission</p>
                      <ul>
                        <li v-for="(error, key) in error_reponse">
                          {{error[0]}}
                        </li>
                      </ul>
                    </b-alert>
                    
                  </transition>
                  </b-col>
                  </b-row>
                    <b-row>
                    <b-card class="shadow-none">
                    <b-col md="12">
                      <h5 >Add/Update Values for Component Attribute: <span class="text-primary">{{addvalue_parent.name}}</span></h5>
                      <hr>
                      <div class="table mb-4">
              <table class="table table-centered table-hover table-wrap">

                <thead class="thead-light">
                  <tr class="d-none">
                     <th scope="col"  style="width: 100px" class="text-center"></th>
                    <th scope="col"><a :class="{'text-dark': sortby != 'name'}" href="javascript: void(0);" @click="orderDesc = !orderDesc,  sortby = 'name', brandQuery()"> <i v-if="orderDesc == true" class="bx bx-caret-up"></i><i v-if="orderDesc == false" class="bx bx-caret-down"></i></a></th>
                    <th scope="col"><span v-if="addvalue_parent.id == null">Values</span></th>
                    <th scope="col"> </th>
                    <th scope="col"> </th>
                    <th scope="col" v-if="addvalue_parent.id == null" >Add Value</th>
                  </tr>
                </thead>
               
                <tbody>
                  
                  <tr v-for="list in brandsValueData.data" :key="list.id" valign="middle" v-if="editfeaturevalue.name == ''">
                    <td>
                       <div class="text-center" v-if="list.image">
                      <b-form-checkbox class="select-row-btn" v-model="selectedids" :value="list.id" button-variant="primary" @input="updateSelected" button>
                        <i v-if="filterSelected(list.id)" class="bx bx-check"></i>
                        <img v-else  :src="`/image/${list.image.replaceAll('https://', '').replaceAll('//', '')}`"  alt class="avatar-sm rounded-circle" />
                      </b-form-checkbox>
                    </div>
                    <div class="text-center" v-else >
                        <b-form-checkbox class="select-row-btn" v-model="selectedids" :value="list.id" button-variant="primary" @input="updateSelected" button>

                          <i v-if="filterSelected(list.id)" class="bx bx-check"></i><span class="avatar-title rounded-circle" v-else>{{(list.name).slice(0,1)}}</span></b-form-checkbox>
                    </div>
                    </td>
                    <td>
                        <h5 class="m-0">{{list.name}}</h5>
                     <small class="text-muted mb-0" v-if="list.short_description != null" v-html="(list.short_description).slice(0, 200)+'...'"></small>
                    </td>
                    <td>
                      <a href="javascript:;"  v-if="addvalue_parent.id == null"  @click="addvalue_parent = list, getAttributeValues()" class="btn btn-outline-light btn-rounded"><i class="bx bx-list-ul"></i></a>
                    </td>
                    <td>
                     
                    </td>
                    <td>
                      <ul class="list-inline font-size-20 contact-links mb-0">
                        <li class="list-inline-item px-2"><a v-b-tooltip.hover title="Edit" placement="left" @click="editfeaturevalue = list, editValuePanel = true"><i class="bx bx-edit-alt"></i>
                          </a>
                        </li>
                        <li class="list-inline-item px-2"><a v-b-tooltip.hover title="Delete" placement="left" @click="editfeaturevalue = list, deleteValuePanel = true"><i class="bx bx-trash-alt"></i>
                          </a>
                        </li>
                      </ul>
                    </td>
                    <td>
                      <a href="javascript:;"  v-if="(addvalue_parent.id == null)" @click="addvalue_parent = list, getAttributeValues(),addValuePanel = true" class="btn btn-outline-light btn-rounded"><i class="mdi mdi-plus"></i></a>
                    </td>
                  </tr>
                  <transition name="slide-fadepanel">
                    <tr v-if="deleteValuePanel == true">
                      <td colspan="6">
                        <p>You are about to delete {{editfeaturevalue.name}}. Are you sure?</p>
                        <a class="btn btn-success" @click="deleteFeatureValue()">Confirm</a>
                        <a class="btn btn-danger" @click="deleteValuePanel = false, resetFeatureValue()">Cancel</a>
                      </td>
                    </tr>
                  </transition>
                  <transition name="slide-fadepanel">
                  <tr v-if="(editfeaturevalue.name !== '') && (deleteValuePanel == false)">
                    <td colspan="6">
                  <b-overlay
                        id="overlay-background"
                        :show="loadingUpdateForm"
                        variant="white"
                        opacity="0.85"
                        blur="1px"
                        rounded="md"
                      >
         <b-form id="updatefeaturevalueform" method="POST" enctype="multipart/form-data" v-if="(editfeaturevalue.name !== '') && (deleteValuePanel == false)">

                   <input type="hidden" name="_token" :value="csrf">
                   <input type="hidden" name="attributeuid" :value="addvalue_parent.uid">
                    <b-row>
                      <b-col md="10">
                      <h4>Edit Value</h4>
                    <b-form-group label="Value Name">
                      <b-form-input name="name" v-model="editfeaturevalue.name" type="text"></b-form-input>
                    </b-form-group>
                     <b-form-group class="mt-2" label="Image or Icon">
                      <input class="form-control mb-2" name="image" type="file">
                    </b-form-group>
                    <b-form-group class=" my-4 pt-3">
                     <div
                        class="form-check form-switch form-switch-sm mb-0 mt-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          id="updatevalue-active"
                          v-model="editfeaturevalue.active" value="true"
                          unchecked-value="false"
                          name="active"
                        />
                        <label class="form-check-label" for="updatevalue-active" 
                          > Active</label
                        >
                      </div>
                    </b-form-group>
                    <b-button variant="success" @click="updateFeatureValue(false)"><i class="bx bx-save"></i> Update</b-button>
                   
                  </b-col>
                  <b-col md="2" v-if="editfeaturevalue.image !== ('' || null)" :src="editfeaturevalue.image" class="pl-4  d-flex flex-column justify-content-center">
                    <img  class="mt-2 w-100 rounded-circle" v-if="editfeaturevalue.image !== ('' || null)" :src="`https://${editfeaturevalue.image.replaceAll('https://', '').replaceAll('//', '')}`" >
                    <img v-else class="mt-2 w-100 rounded-circle" src="/images/product-placeholder.png">
                  </b-col>
                  </b-row>
                  </b-form>
                </b-overlay>
              </td>
              </tr>
            </transition>

                  <transition name="slide-fadepanel">
                  <tr v-if="(editfeaturevalue.name !== '') && (deleteValuePanel == false)">
                    <td colspan="6">
                    <a  class="btn btn-outline-light btn-rounded w-100" @click="resetFeatureValue()"><i class="bx bx-arrow-left"></i> Back to Add New</a>
                    </td>
                  </tr>
                  </transition>
                </tbody>
              </table>
            </div>
                      <b-overlay
                        id="overlay-background"
                        :show="loadingForm"
                        variant="white"
                        opacity="0.85"
                        blur="1px"
                        rounded="md"
                      >
         <b-form id="addfeaturevalueform" method="POST" enctype="multipart/form-data" v-if="editfeaturevalue.name == ''">
                   <input type="hidden" name="_token" :value="csrf">
                   <input type="hidden" name="attributeuid" :value="addvalue_parent.uid">
                    <b-row>
                      <b-col md="12">
                        <h4>Add Feature</h4>
                    <b-form-group label="Value Name">
                      <b-form-input name="name" type="text"></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="8">
                    <b-form-group class="mt-2" label="Image or Icon">
                      <input class="form-control mb-2" name="image" type="file">
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group class=" my-4 pt-3">
                     <div
                        class="form-check form-switch form-switch-sm mb-0 mt-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          id="newbrand-owner"
                          v-model="newfeaturevalue.active" value="true"
                          unchecked-value="false"
                          name="active"
                        />
                        <label class="form-check-label" for="newbrand-owner" 
                          > Active</label
                        >
                      </div>
                    </b-form-group>
                  </b-col>
                  <b-col md="12" class="mt-3">
                    <b-button variant="success" @click="addNewFeatureValue(false)"><i class="bx bx-save"></i> Save</b-button>
                    <b-button variant="info" @click="addNewFeatureValue(true)"><i class="mdi mdi-plus"></i> Save and Add New</b-button>
                  </b-col>
                  </b-row>
                  </b-form>
                </b-overlay>
                
                </b-col>
              </b-card>
              </b-row>
  </SideSlidePanel>

</Layout>
</template>