<script type="text/javascript">
	export default{
		props: {
			resource: {
		        type: String,
		        default: () => '',
		      },
		      resource_id: {
		        type: Number,
		        default: () => '',
		      },
		      api_token: {
		        type: String,
		        default: () => '',
		      },
		      resource_route: {
		        type: String,
		        default: () => '',
		      },
		      service_list: {
		        type: Array,
		        default: () => '',
		      },
		      expanded:{
		      	type: Number,
		      	default: () => 0
		      },
		      view:{
		      	type: String,
		      	default: () => 'grid'
		      },
		      show_view: {
		      	type: Boolean,
		      	default: () => true,
		      }
		  },
		  data(){
			return{
		        quickviewproduct: '',
			}
		  },
		  methods: {
		  	removeResource(service, index){
		  		this.$emit('remove-resource', service, index);
		  	},
		  	priceIncVat(price, tax_percentage){
		      var tax = 1 + (tax_percentage / 100);
		      return price * tax;
		    }
		  }
	}
</script>
<template>
	 <div class="row">
          <div class="col-md-12">
          	<ul class="nav nav-pills product-view-nav mb-2 float-right" v-if="show_view">
          	<li class="nav-item">
                  <a class="nav-link" :class="{'active': view == 'grid' }" href="javascript: void(0);" @click="view = 'grid'">
                    <i class="bx bx-grid-alt"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': view == 'list' }" href="javascript: void(0);" @click="view = 'list'">
                    <i class="bx bx-list-ul"></i>
                  </a>
                </li>
               </ul>
          	<ul class="list-group product-list w-100 no-shadow" v-if="(service_list.length !== 0) && (view == 'list')">
                              <li v-for="(product, index) in service_list" class="list-group-item d-flex px-0 py-0 border product-list-item mb-2 shadow-sm">
                              	<div class="d-flex product-list-item-container py-3">
                              	<div class="position-relative">
                              	<img v-if="product.cover_image" class="avatar-md rounded-circle mx-3 my-auto" :src="product.cover_image">
                              	<img v-else class="avatar-md rounded-circle mx-3 my-auto" src="/images/product-placeholder.png">
                              	</div>
                                <div class="mr-auto ml-2 my-auto">
                                	
                                	<p class="mr-auto my-auto text-primary"><span class="badge badge-light bg-light mr-2">#{{product.reference}}</span> {{product.name}}</p>
                                	
						            <p class="text-muted italic no-margin paragraph-truncate-line2 mb-0 font-size-11" v-html="product.short_description"></p>
						            <strong class="text-muted mb-auto">£{{product.base_price}} <small class="mr-3">exc. VAT</small>   <span v-if="product.tax_rule">£{{priceIncVat(product.base_price, product.tax_rule.percentage)}}</span><span v-else>£{{product.base_price}}</span> <small class="mr-3">inc. VAT</small></strong>
                                </div>
                                <div class="mr-auto ml-2 my-auto flex-grow-1 mr-3 d-flex">
                                	<a class="btn btn-outline-light btn-rounded ml-auto my-auto flex-grow-3 mr-2" href="javascript:;" @click="quickviewproduct = product"><i class="py-3 px-2 mdi mdi-eye-outline"></i></a>
                                <a class="btn btn-outline-light btn-rounded ml-auto my-auto" href="javascript:;" @click="removeResource(product, index)"><i class="py-1 px-2 bx bx-trash-alt"></i></a>
                            	</div>
              
                              </div>
                              </li>
                              

                              <!--<li class="list-group-item py-4 text-center" ><a class="btn btn-outline-light btn-rounded">Load More</a>
                              </li>-->
                            </ul>
                <ul class="list-group product-list w-100 no-shadow" v-if="(service_list.length == 0) && (view == 'list')">
                	  <li class="list-group-item d-flex">
                	  	<i class="bx bx-warning"></i> No Service(s) Selected <span v-if="resource !== ''">assigned to this {{resource}}</span>
                	  </li>
                </ul>
          </div>
          <div class="col-md-12">
          	<div class="row product-grid px-2 row-eq-height" v-if="(service_list.length !== 0) && (view == 'grid')">
                              <div class=" d-flex flex-column px-2 product-grid-item mb-2" :class="{'col-md-4' : expanded == 0,'col-md-3' : expanded == 1}" v-for="(product, index) in service_list">
                              	<div class="border shadow-sm product-grid-item-container">
                              	<img v-if="product.cover_image" class="flex-grow-3 w-100 product-grid-img" :src="product.cover_image">
                              	<img v-else class="flex-grow-3 w-100 product-grid-img" src="/images/product-placeholder.png">
                                <div class="my-3 flex-grow-3 px-3">
                                	<strong class="mr-auto my-auto text-primary">{{product.name}}</strong>
                                	<span class="badge badge-primary position-absolute product-grid-reference-badge">{{product.reference}}</span>
						            <small class="text-muted italic no-margin" v-html="product.short_description"></small>
                                </div>
                                <div class="my-1 flex-grow-3 px-3">
                                	<strong class="text-muted mb-auto">£{{product.base_price}} <small class="mr-3">exc. VAT</small></strong>
                                </div>
                                <div class="my-1 flex-grow-3 px-3">
                                	<strong class="text-muted mb-auto" v-if="product.tax_rule"> £{{priceIncVat(product.base_price, product.tax_rule.percentage)}} <small class="mr-3">inc. VAT</small></strong>
                                	<strong class="text-muted mb-auto" v-else> £{{product.base_price}} <small class="mr-3">inc. VAT</small></strong>
                                </div>
                                <div class="my-3 flex-grow-3 px-3">
                                	<a class="btn btn-outline-light btn-rounded ml-auto my-auto flex-grow-3" href="javascript:;" @click="quickviewproduct = product"><i class="mdi mdi-eye-outline"></i></a>
                                	<a class="btn btn-outline-light btn-rounded ml-auto my-auto flex-grow-3" href="javascript:;"><i class="bx bx-pencil"></i></a>
                                <a class="btn btn-outline-light btn-rounded ml-auto my-auto flex-grow-3" href="javascript:;" @click="removeResource(product, index)"><i class="bx bx-trash-alt"></i></a>
              					</div>
                              	</div>
                              </div>
                              

                              <!--<li class="list-group-item py-4 text-center" ><a class="btn btn-outline-light btn-rounded">Load More</a>
                              </li>-->
                            </div>
                <ul class="list-group product-list w-100 no-shadow"  v-if="(service_list.length == 0) && (view == 'grid')">
                	  <li class="list-group-item d-flex">
                	  	<i class="bx bx-warning"></i> No Service(s) Selected <span v-if="resource !== ''">assigned to this {{resource}}</span>
                	  </li>
                </ul>
          </div>
          <div class="product-modal d-flex flex-column justify-content-center" v-if="quickviewproduct !== ''">
          	<div class=" d-flex flex-column px-2 product-grid-item mb-2 mx-auto col-md-8 col-sm-10">
                              	<div class="border shadow-sm product-grid-item-container bg-white row">
                              		<div class="col-md-6 m-0 p-0">
                              	<img v-if="quickviewproduct.cover_image" class="flex-grow-3 w-100 quickviewproduct-grid-img" :src="quickviewproduct.cover_image">
                              	<img v-else class="flex-grow-3 w-100 product-list-img" src="/images/product-placeholder.png"></div>
                              	<div class="col-md-6 d-flex flex-column justify-content-center">
                              		<a href="javascript:;" @click="quickviewproduct = ''" class="btn btn-rounded btn-outline-light float-right mr-2 mt-2 position-absolute close-modal"><i class="mdi mdi-close noti-icon font-size-20"></i></a>
                              	                                <div class="my-3 flex-grow-3 px-3">
                                	<h2 class="mr-auto my-auto text-primary">{{quickviewproduct.name}}</h2>
                                	<span class="badge badge-primary">{{quickviewproduct.reference}}</span>
						            <p class="text-muted italic no-margin" v-html="quickviewproduct.short_description"></p>
                                </div>
                                <div class="my-1 flex-grow-3 px-3">
                                	<strong class="text-muted mb-auto">£{{quickviewproduct.sales_price}} <small class="mr-3">exc. VAT</small></strong>
                                </div>
                                <div class="my-1 flex-grow-3 px-3">
                                	<strong class="text-muted mb-auto"> £{{priceIncVat(quickviewproduct.sales_price, quickviewproduct.tax_rule.percentage)}} <small class="mr-3">inc. VAT</small></strong>
                                </div>
                                <div class="my-1 flex-grow-3 px-3">
                                	<strong class="text-muted mb-auto">£{{quickviewproduct.trade_price}} <small class="mr-3">Trade Price</small></strong>
                                </div>
                                <div class="my-3 flex-grow-3 px-3">
                                	<a class="btn btn-outline-light btn-rounded ml-auto my-auto flex-grow-3" href="javascript:;"><i class="bx bx-pencil"></i></a>
                                <a class="btn btn-outline-light btn-rounded ml-auto my-auto flex-grow-3" href="javascript:;" @click="removeResource(quickviewproduct)"><i class="bx bx-trash-alt"></i></a>
              					</div>
              				</div>
                              	</div>
                              </div>
          </div>
     </div>
</template>