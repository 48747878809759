<template>
	<div class="w-100 position-relative" >
	<div class="mt-sm-0 form-inline w-100" style="z-index: 9999 !important;" >
              <div class="search-box mr-2 ml-auto d-flex w-100">
                <div class="position-relative w-100 mr-2">
                  <input
                    type="text"
                    class="form-control w-100 border"
                    placeholder="Search for a product, jewellery type or seller name..."
                    v-model="search_value"
                    @keyup.enter="getResource(search_area)"
                  />
                  <span class="bx bx-search-alt search-icon"></span>
                </div>
              </div>
              <div v-if="(isResults == true)" class="position-absolute widget-scrollbox w-100 p-3 z-index-9 bg-white border-radius-1em mt-3  right-0 left-0" style="z-index: 9999 !important;" >
              
              	<b-tabs v-if="isResults == true" pills content-class="pt-3">
              			<b-overlay :show="loadingResults">
          			<b-tab v-for="(item, resultskey) in search_results" :title="resultskey" v-if="search_area.includes(resultskey) == true"  @click="selectedKey = resultskey">
		             <b-list-group class="search-widget-results">
		             <b-list-group-item v-for="(result, index) in search_results[resultskey].data" class="d-flex">
		              <div v-if="resultskey == 'Products'">
								 			<b-img-lazy v-if="(result.cover_image !== null) && ('gallery' in result.cover_image)" class="rounded-3 avatar-md rounded-circle mr-3" :src="'https://'+result.company.nickname+storageurl+result.cover_image.gallery.replaceAll('//', '/')" ></b-img-lazy>
								   		<b-img-lazy v-else-if="(result.cover_image !== null) && ('thumb' in result.cover_image)" class="rounded-3 avatar-md rounded-circle mr-3" :src="'https://'+result.company.nickname+storageurl+result.cover_image.thumb.replaceAll('//', '/')" ></b-img-lazy>
								   		<b-img-lazy v-else-if="(result.cover_image !== null) && ('src' in result.cover_image)" class="rounded-3 avatar-md rounded-circle mr-3" :src="'https://'+result.company.nickname+storageurl+result.cover_image.src.replaceAll('//', '/')" ></b-img-lazy>
								</div>
								<div v-else-if="resultskey == 'Listings'">
							    		<b-img-lazy v-if="(result.banner !== '') && (result.banner !== null)" class="rounded-3 avatar-md rounded-circle mr-3" :src="'https://'+result.company.nickname+storageurl+result.banner.replaceAll('//', '/')" ></b-img-lazy>
							    	</div>
							    	<div v-else-if="resultskey == 'Sellers'">
							    		<b-img-lazy class="rounded-3 avatar-md rounded-circle mr-3" :src="'https://'+result.nickname+storageurl+result.logo.replaceAll('//', '/')" ></b-img-lazy>
							    	</div>
							    	<div v-else>
							    		<b-img-lazy v-if="(result.image !== '') && (result.image !== null)" class="rounded-3 avatar-md rounded-circle mr-3" :src="'https://'+result.company.nickname+storageurl+result.image" ></b-img-lazy>
							    	</div>
											<div class="flex-inherit w-75 my-auto">
												<h4 class="mb-0">{{result.name}}</h4>
										      <p class="text-dark mb-0 paragraph-truncate-line2" v-if="resultskey == 'Products'" v-html="result.short_description">
										      </p>
							          	<p class="text-dark mb-0 paragraph-truncate-line2" v-else v-html="result.description">
							          	</p>
							          	<div class="d-flex"  v-if="resultskey == 'Sellers'">
							          	<span class="my-auto text-muted"><i>Rated</i></span>
							          	<b-form-rating id="rating-inline" variant="warning" class="text-left px-0 mx-2" no-border size="sm" v-model="result.reviews_avg_rating" style="max-width: 100px; line-height: 0.8px;" readonly></b-form-rating>
							          		<span class="my-auto mr-auto text-muted">{{(result.reviews_avg_rating !== null ? parseFloat(result.reviews_avg_rating).toFixed(0) : 0)}} / 5 </span>
							          	</div>
							        </div>
							        	
							        		<div class="flex-inherit d-lg-flex d-xl-flex d-md-flex d-none ml-auto">
														<router-link class="border-bottom border-primary text-uppercase font-size-12 ml-auto text-dark my-auto py-2" v-if="resultskey == 'Products'" :to="'/seller/'+result.company.nickname+'/product/'+result.url_rewrite">
															See Result <i class="bx bx-chevron-right"></i>
														</router-link>
														<router-link class="border-bottom border-primary text-uppercase font-size-12 ml-auto text-dark my-auto py-2" v-if="resultskey == 'Category'" :to="'/category/'+result.url_rewrite">
															See Result <i class="bx bx-chevron-right"></i>
														</router-link>
														<router-link class="border-bottom border-primary text-uppercase font-size-12 ml-auto text-dark my-auto py-2" v-if="resultskey == 'Collections'" :to="'/seller/'+result.company.nickname+'/collection/'+result.url_rewrite">
															See Result <i class="bx bx-chevron-right"></i>
														</router-link>
														<router-link class="border-bottom border-primary text-uppercase font-size-12 ml-auto text-dark my-auto py-2" v-if="resultskey == 'Sellers'" :to="'/seller/'+result.nickname">
															See Result <i class="bx bx-chevron-right"></i>
														</router-link>
													</div>
													<router-link class="position-absolute top-0 left-0 right-0 bottom-0 z-index-2" v-if="resultskey == 'Products'" :to="'/seller/'+result.company.nickname+'/product/'+result.url_rewrite">
															
														</router-link>
														<router-link class="position-absolute top-0 left-0 right-0 bottom-0 z-index-2" v-if="resultskey == 'Category'" :to="'/category/'+result.url_rewrite">
															
														</router-link>
														<router-link class="position-absolute top-0 left-0 right-0 bottom-0 z-index-2" v-if="resultskey == 'Collections'" :to="'/seller/'+result.company.nickname+'/collection/'+result.url_rewrite">
															
														</router-link>
														<router-link class="position-absolute top-0 left-0 right-0 bottom-0 z-index-2" v-if="resultskey == 'Sellers'" :to="'/seller/'+result.nickname">
															
														</router-link>
		              		</b-list-group-item>
		              	</b-list-group>
		              	  <div v-if="('data' in search_results[resultskey]) && (search_results[resultskey].data.length == 0)">
				          <b-card class="shadow-none mb-0 pb-0 border border-radius-1em" body-class="d-flex flex-column justify-content-center align-items-center p-5">
				          	<h6>No Search Results</h6>
				          	<p class="text-muted">Please try another search term.</p>
				          </b-card>
			        	</div>
		              </b-tab>
		              </b-overlay>
		              <template #tabs-end>
          		 	<b-pagination id="dropdown-pagination"  class="ml-auto d-none d-lg-flex d-xl-flex" v-model="current_page" :total-rows="search_results[selectedKey].total" :per-page="search_results[selectedKey].per_page" align="right"></b-pagination>
          		 </template>
          		 <b-pagination id="dropdown-pagination"  class="ml-auto d-lg-none d-flex d-xl-none mt-2" v-model="current_page" :total-rows="search_results[selectedKey].total" :per-page="search_results[selectedKey].per_page" align="center"></b-pagination>
		          </b-tabs>
		          <div v-else-if="(isResults == true) && (search_value.length < 3)">
			          <b-card class="shadow-none mb-0 pb-0 border border-radius-1em" body-class="d-flex flex-column justify-content-center align-items-center p-5">
			          	<h6>No Search Results</h6>
			          	<p class="text-muted">Please make sure your search query is over 3 characters long and try again.</p>
			          </b-card>
		        	</div>
		        	<div class="d-flex">
		        		<a class="ml-auto mt-3" @click="search_value = '', getResource(search_area)" href="javascript:;">
		        			Clear Search
		        		</a>
		        	</div>
              </div>
            </div>
     </div>
</template>

<script type="text/javascript">
	export default{
		props: {
				isHeader: {
					type: Boolean,
					default: false
				}
		},
		data(){
			return{
				search_results: {Products: '', Categories: '', Sellers: ''},
				current_page: 1,
				query: '',
				hoverIndex: -1,
				storageurl: '.s3.eu-west-2.amazonaws.com',
				isResults: false,
				selectedKey: '',
				loadingResults: false,
				search_value: '',
				search_area: ['Products', 'Categories', 'Sellers'],
			}
		},
		created(){
			this.selectedKey = 'Products';
		},
		methods: {
			filterStylesKey(styles, key){
            for(var i = 0; i < styles.length; i++){
               var keys = Object.keys(styles[i])
               if(keys.includes(key)){
                 return i;
               }
            }
       },
			getResource(search_areas){
				this.loadingResults = true;
				this.isResults = false;
				if(this.search_area.length > 0){
					if(this.search_value.length > 3){
							//this.createQueryString();
							if(this.search_area.includes('Products') == true){
								this.searchProducts();
							}
							if(this.search_area.includes('Categories') == true){
								this.search_results["Categories"] = this.searchCategories();
							}
							if(this.search_area.includes('Collections')){
								this.search_results["Collections"] = this.searchCollections();
							}
							if(this.search_area.includes('Listings')){
								this.search_results["Listings"] = this.searchListings();
							}
							if(this.search_area.includes('Sellers')){
								this.search_results["Sellers"] = this.searchSellers();
							}
							this.isResults = true;
							this.$emit('search-results-shown');
					}else{
						this.isResults = false;
					  this.$emit('search-results-hidden');
					}
				}else{
					this.isResults = false;
					this.$emit('search-results-hidden');
				}
				let self = this;
						          setTimeout(function(){
						            self.loadingResults = false;
						          }, 1000);
			},
			createQueryString(){
				this.query = '';
				for(var key in this.$attrs.filters){
					if(key !== 'search_area'){
						this.query += '&'+key+'='+this.$attrs.filters[key].value+'';
					}
				}
			},
			searchProducts(){
					axios.get('/customer-api/product/search?page='+this.current_page+'&search='+this.search_value+this.query).then(response => {
							this.search_results["Products"] = response.data;
						
						}).catch(error => {
						
						});
			},
			searchCategories(){
					axios.get('/customer-api/product/category/search?page='+this.current_page+'&search='+this.search_value+this.query).then(response => {
						this.search_results["Categories"] = response.data;
					}).catch(error => {
						return [];
						console.log(error);
					});
			},
			searchCollections(){
					axios.get('/customer-api/collections/search?page='+this.current_page+'&search='+this.search_value+this.query).then(response => {
						this.search_results["Collections"] = response.data;
					}).catch(error => {
						return [];
						console.log(error);
					});
			},
			searchListings(){
					axios.get('/customer-api/widget/listings?page='+this.current_page+'&search='+this.search_value+this.query).then(response => {
						this.search_results["Listings"] = response.data;
					}).catch(error => {
						return [];
						console.log(error);
					});
			},
			searchSellers(){
					axios.get('/customer-api/seller/search?page='+this.current_page+'&search='+this.search_value+this.query).then(response => {
						this.search_results["Sellers"] = response.data;
					}).catch(error => {
						return [];
						console.log(error);
					});
			},
		}
	}

</script>