<template>
<section >
		  <!--<div class="container-fullwidth" style="background-image: url('/images/gemesys-header-banner.webp'), linear-gradient(196deg, rgb(108, 46, 185) 29%, rgba(0, 0, 0, 0.9) 100%) !important; background-position:  right bottom; background-size: cover; background-repeat: no-repeat; background-blend-mode: screen;">
		    -->
		    <div class="container-fullwidth bg-light bg-soft" style="background: linear-gradient(45deg, rgba(94, 59, 174,0.15), rgba(239, 242, 247, 0.25))">
		    <b-row class="mb-4" style="min-height: 250px; backdrop-filter: saturate(0.6);">

		      <b-col md="10" class="d-flex flex-column justify-content-center align-items-sm-center align-items-center text-center mx-auto offset-md-2">
		      	
		      	<div class="d-flex flex-row">
		      	<div class="d-flex flex-column my-auto">
		        <h1 class="section-info-header position-relative mx-auto text-capitalize">{{data.name}}</h1>
		        <p class="w-100 text-center mb-0" v-if="data.description !== (null || '')">
		            {{data.description}}
		          </p>
		      	</div>
		      </div>
		      </b-col>
		    </b-row>
		  </div>
		</section>
</template>

<script type="text/javascript">
	
	export default{
		props: {
			data: {
				type: Object,

			}
		}
	}
</script>