<script>
	import Multiselect from "vue-multiselect";
	export default{
		components: { Multiselect },
		data(){
			return{
				id: '',
				options: [
					{id: 1, name: 'text'},
					{id: 2, name: 'text2'}
				],
				search: '',
				currentPage: 1,
				order: 'desc',
      			sortby: 'id',
      			limit: 0,
				limits: [
		        {value: 25, text: "25"},
		        {value: 50, text: "50"},
		        {value: 100, text: "100"},
		    	],
				response_error: []
			}
		},
		props: {
			isDisabled: {
				type: Boolean,
				default: () => false,
				},
		},
		created(){
			console.log('category select created');
			axios.get('/api/company/appraisal/templates/stamps?page='+this.currentPage+'&limit='+this.limit+'&sortby='+this.sortby+'&order='+this.order+'&search='+this.search, {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
              }}).then(response => {
	       	this.options = response.data.data;
			}).catch(error => {
				this.response_error = error.data;
			});
			this.id = this.$attrs.id;
				
		},
		methods:{
			updateDepartment: function(selectedOption, id){
				this.$emit('brand-selected', selectedOption);
			},
			customLabel ({ title, desc }) {
		      return `${title} – ${desc}`
		    }
		}
	}
</script>
<template>
	<div>
	<multiselect v-model="id" track-by="name" label="name" :options="options" @select="updateDepartment" :option-height="104" :disabled="isDisabled" >
                     <!--<template slot="singleLabel" slot-scope="{ option }">{{ option.name }} - {{ option.jobtitle }}</template>-->
                     <template slot="singleLabel" slot-scope="{ option }"><div class="d-flex w-100 h-100"><div class="avatar-sm mr-4 my-auto">
                        <img class="avatar-title rounded-circle" v-if="option.logo" :src="option.logo">
                        <img class="avatar-title rounded-circle" v-else src="/images/product-placeholder.png">
                      </div>
      <div class="option__desc d-flex flex-row w-75"><span class="option__title ml-2 my-auto w-100">{{ option.name }}<br></span>
      </div>
  		</div></template>
    <template slot="option" slot-scope="{ option }">
    	<div class="d-flex"><div class="avatar-sm mr-4">
                        <img class="avatar-title rounded-circle" v-if="option.logo" :src="option.logo">
                        <img class="avatar-title rounded-circle" v-else src="/images/product-placeholder.png">
                      </div>
      <div class="option__desc d-flex flex-row w-50"><span class="option__title ml-2 my-auto w-100">{{ option.name }}<br></span>
      </div>
  		</div>
    </template>
                  </multiselect>
                 
   </div>
</template>