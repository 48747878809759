<script>
  import Multiselect from "vue-multiselect";
	export default{
    components: {Multiselect},
    props: {
      resource: {
        type: String,
        default: () => '',
      },
      resource_id: {
        type: Number,
        default: () => '',
      },
      api_token: {
        type: String,
        default: () => '',
      },
      resource_route: {
        type: String,
        default: () => '',
      },
      input_classes: {
        type: String
      }
    },
		data(){
			return{
        skip: 0,
        take: 25,
        sortby: 'id',
        order: 'desc',
        sortby: 'id',
        trade_active: '',
        public_active: '',
        brands: '',
        collections: '',
        priceRange: [0,999999],
        currentPage: 1,
        selectedids: [],
        loadingForm: false, 
        isCheckAll: false,
        active: '',
				searchquery: '',
				search_results: [],
				all_brands: '',
        customer: '',
				results_start: 0,
      			results_end: 5,
			}
		},
    mounted(){
      this.searchQuery();
    },
		methods:{
          searchQuery(){
            this.loadingForm = true;
              axios.get('/api/company/customer/search?page='+this.currentPage+'&skip='+this.skip+'&take='+this.take+'&sortby='+this.sortby+'&order='+this.order+'&search='+this.searchquery+'&trade_active='+this.trade_active+'&public_active='+this.public_active+'&active='+this.active+'&brands='+this.brands+'&collections='+this.collections+'&pricerange='+this.priceRange, {headers: {
                  'Authorization': 'Bearer ' + this.api_token
              }}).then(response => {
                console.log(response);
                this.search_results = response.data;
                let self = this;
                setTimeout(function(){
                  self.loadingForm = false;
                }, 500);
                //this.results_end = response.data.total;
              });
          },
          selectResource(resource){
          	this.$emit('search-resource-selected', resource);
          },
          addToResource(resource){
            if(this.resource_route !== ''){
              axios.post(this.resource_route+'product_id='+resource.id+'', {product_id: resource.id}, {headers: {
                  'Authorization': 'Bearer ' + this.api_token
              }}).then(response => {
                this.$emit('product-added', response.data);
              }).catch(error => {

              });
            }else{
               this.$emit('search-resource-selected', resource);
            }
          },
          priceIncVat(price, tax_percentage){
		      var tax = 1 + (tax_percentage / 100);
		      return price * tax;
		      }
		}
	}
</script>
<template>
  <div class="row">
          <div class="col-md-12">
                            
                             <multiselect v-model="customer" track-by="email" label="email" :options="search_results" @select="selectResource" :option-height="104" :disabled="isDisabled" >
                           
                              <!--<b-form-select-option v-for="searchresult in search_results" class="list-group-item d-flex">
                              	<div v-if="searchresult.customer !== null"><img v-if="searchresult.customer.avatar" class="avatar-md border mr-3" :src="searchresult.customer.avatar">
                                <img v-else class="avatar-md border mr-3" src="/images/product-placeholder.png">
                              </div>
                              <img v-else class="avatar-md border mr-3" src="/images/product-placeholder.png">
                                <div class="mr-auto ml-2 my-auto flex-grow-3 mr-3">
                                	<h6 class="mr-auto my-auto">{{searchresult.firstname}} {{searchresult.lastname}}</h6>
                                	<span class="badge badge-light">{{searchresult.reference}}</span>
						                    <a class="text-muted italic no-margin" href="javascript:;">{{searchresult.email}}</a>
                                </div>
                                
        
              
                              
                              </b-form-select-option>-->

                               <template slot="singleLabel" slot-scope="{ option }"><div class="d-flex w-100 h-100"><div class="avatar-sm mr-4 my-auto">
                        <span class="avatar-title rounded-circle">{{(option.name).slice(0,1)}}</span>
                      </div>
                    <div class="option__desc d-flex flex-row w-75"><span class="option__title ml-2 my-auto w-100">{{ option.name }} {{ option.lastname }}<br><p class="m-0 lh-1 w-100 text-truncate"><small>{{option.email}}</small></p></span>
                    </div>
                    </div></template>
                  <template slot="option" slot-scope="{ option }">
                    <div class="d-flex"><div class="avatar-sm mr-4">
                                      <span class="avatar-title rounded-circle">{{(option.name).slice(0,1)}}</span>
                                    </div>
                    <div class="option__desc d-flex flex-row w-50"><span class="option__title ml-2 my-auto w-100">{{ option.name }} {{ option.lastname }}<br><p class="m-0 lh-1 w-100 text-truncate"><small>{{option.email}}</small></p></span>
                    </div>
                    </div>
                  </template>
                            
                          </multiselect>
                  </div>
                </div>
</template>