<template>
<b-card class="bg-transparent shadow-none border-radius-1em border border-5 border-dashed" style="min-height: 300px; border-style: dashed !important;" body-class="text-center justify-content-center d-flex flex-column">
           <i class="bx bx-sad mx-auto font-size-48 text-muted mb-2"></i>
           <h5 class="text-muted">Nothing Here Yet</h5>
           <a class="btn btn-primary mt-2 btn-rounded mx-auto" @click="$emit('add-new')" v-if="addNew == true">
             Add New <i class="mdi mdi-plus"></i>
           </a>
          </b-card>
</template>

<script>
  export default {
    props: {
      addNew: {
        type: Boolean,
        default: true,
      }
    }
  }
</script>