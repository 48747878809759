<template>
	<b-card class="border-radius-1em border shadow-none mb-0">
<b-row>
	<b-col md="12">
		 	<b-form-group v-for="(fieldvalue, wkey) in $attrs.filter" >
		 			<!--<b-form-checkbox  v-model="$attrs.filter[wkey].active"></b-form-checkbox>-->
				<div class="form-check form-switch form-switch-sm mb-0 mx-auto px-0 py-2 mt-auto d-flex text-capitalize"dir="ltr"  v-if="$attrs.filter[wkey].type !== 'hidden'">
					<span class="mr-auto">{{wkey.replace(/_/g, ' ')}}</span>
					<input  class="form-check-input ml-auto my-auto"   type="checkbox"  :id="$attrs.filter[wkey]"   v-model="$attrs.filter[wkey].active"  name="active" :class="{'d-none' : 'switchHidden' in $attrs.filter[wkey] }" />
												                    </div>
		 			<div v-if="$attrs.filter[wkey].active == 1">
			 				
		 			<b-form-select v-if="$attrs.filter[wkey].type == 'select'" :options="$attrs.filter[wkey].options" class="w-100 form-control"></b-form-select>
		 			<b-form-group  v-else-if="$attrs.filter[wkey].type == 'customselect'">
		 					<SelectCompanyProductCategories v-if="(wkey == 'categories')  && ($attrs.active_filters[wkey].value.length == 0)" :id="''" :user="$attrs.user" :isDisabled="false" :marketplace_categories="'company'" @company-category-selected="$attrs.active_filters[wkey].value.push($event)" ></SelectCompanyProductCategories>
			 				<SelectCompanyProductCategories v-else-if="(wkey == 'categories') && ($attrs.active_filters[wkey].value.length > 0)" :id="$attrs.active_filters[wkey].value" :user="$attrs.user" :isDisabled="false" :marketplace_categories="'company'" @company-category-selected="$attrs.active_filters[wkey].value.push($event)" ></SelectCompanyProductCategories>
			 				<SelectCompanyProductAttributes v-else-if="(wkey == 'attributes') && ($attrs.active_filters[wkey].value.length > 0)" :id="selected_attribute" :user="$attrs.user" :isDisabled="false" :marketplace_categories="'company'" @attribute-selected="$attrs.active_filters[wkey].value.push($event)"></SelectCompanyProductAttributes>
			 				<SelectCompanyProductCollections v-else-if="(wkey == 'collections')  && ($attrs.active_filters[wkey].value.length == 0)" :id="''" :user="$attrs.user" :isDisabled="false" :marketplace_categories="'company'" @company-category-selected="$attrs.active_filters[wkey].value.push($event)" ></SelectCompanyProductCollections>
			 				<SelectCompanyProductCollections v-else-if="(wkey == 'collections') && ($attrs.active_filters[wkey].value.length > 0)" :id="$attrs.active_filters[wkey].value" :user="$attrs.user" :isDisabled="false" :marketplace_categories="'company'" @company-category-selected="$attrs.active_filters[wkey].value.push($event)" ></SelectCompanyProductCollections>
			 				<SelectCompanyLocations v-else-if="(wkey == 'listings')  && ($attrs.active_filters[wkey].value.length == 0)" :id="''" :user="$attrs.user" :isDisabled="false" :marketplace_categories="'company'" @location-selected="$attrs.active_filters[wkey].value.push($event)" ></SelectCompanyLocations>
			 				<SelectCompanyLocations v-else-if="(wkey == 'listings') && ($attrs.active_filters[wkey].value.length > 0)" :id="$attrs.active_filters[wkey].value" :user="$attrs.user" :isDisabled="false" :marketplace_categories="'company'" @location-selected="$attrs.active_filters[wkey].value.push($event)" ></SelectCompanyLocations>
			 				<SelectCompanyDomains v-else-if="(wkey == 'domains')  && ($attrs.active_filters[wkey].value.length == 0)" :id="''" :user="$attrs.user" :isDisabled="false" :marketplace_categories="'company'" @location-selected="$attrs.active_filters[wkey].value.push($event)" ></SelectCompanyDomains>
			 				<SelectCompanyDomains v-else-if="(wkey == 'domains') && ($attrs.active_filters[wkey].value.length > 0)" :id="$attrs.active_filters[wkey].value" :user="$attrs.user" :isDisabled="false" :marketplace_categories="'company'" @location-selected="$attrs.active_filters[wkey].value.push($event)" ></SelectCompanyDomains>
			 				<CompanyProductSearch v-else-if="(wkey == 'products')" :resource="''" :resource_id="''" :api_token="$attrs.user.api_token" :company="$attrs.company" :resource_route="''" @product-added="$attrs.active_filters[wkey].value.push($event)" @location-selected="$attrs.active_filters[wkey].value.push($event)" ></CompanyProductSearch>
			 				<CompanyProductList v-else-if="(wkey == 'products') && ($attrs.active_filters[wkey].value.length > 0)" :product_list="$attrs.active_filters[wkey].value" :resource_id="null" :default_resource_key="default_resource_key" :expanded="isExpanded" @remove-product="removeFromResource" :company="$attrs.company" ></CompanyProductList>
							<b-form-select v-else class="form-control w-100" v-model="$attrs.active_filters[wkey].value">
								<b-form-select-option v-for="option in $attrs.filter[wkey].options" :value="option.uid" class="w-100 p-0">
									{{option.name}}
								</b-form-select-option>
							</b-form-select>
					</b-form-group>
		 			<b-form-checkbox-group v-else-if="$attrs.filter[wkey].type == 'custommultiselect'"  v-model="$attrs.active_filters[wkey].value">
		 					<b-form-checkbox v-for="option in $attrs.filter[wkey].options" :value="option.id" class="w-100 p-0">
		 						{{option.name}}
		 					</b-form-checkbox>
		 				</b-form-checkbox-group>

		 				<b-form-checkbox-group v-else-if="$attrs.filter[wkey].type == 'multiselect'" :stacked="'isStacked' in $attrs.filter[wkey]" v-model="$attrs.active_filters[wkey].value" :options="$attrs.filter[wkey].options">
		 					
		 				</b-form-checkbox-group>
		 		<div v-else-if="$attrs.filter[wkey].type == 'multirange'">
		 					<div class="d-flex">
		 						<b-form-group label="Min">
		 						<b-form-input v-model="$attrs.filter[wkey].value[0]" type="number"></b-form-input>
		 					</b-form-group>
		 						<b-form-group label="Max">
		 						<b-form-input v-model="$attrs.filter[wkey].value[1]" type="number"></b-form-input>
		 					</b-form-group>
		 					</div>
		 				<vue-slider v-model="$attrs.filter[wkey].value"	:min="0" :max="$attrs.filter[wkey].value[1] + 100" 															                    />
				</div>
				<div v-else-if="$attrs.filter[wkey].type == 'range'">
		 				<input type="range"  v-if="$attrs.filter[wkey].type == 'range'" v-model="$attrs.active_filters[wkey].value" :min="0" :max="200" class="w-100" />
				</div>
				<div v-else-if="$attrs.filter[wkey].type == 'tags'">
				<b-form-tags input-id="tags-basic" v-model="$attrs.active_filters[wkey].value"></b-form-tags>
				</div>
				<b-form-input v-else-if="$attrs.filter[wkey].type !== 'hidden'" :type="$attrs.filter[wkey].type" v-model="$attrs.active_filters[wkey].value"></b-form-input>
				
			</div>
</b-form-group>
</b-col>
</b-row>
</b-card>

</template>

<script type="text/javascript">
	import VueSlideBar from 'vue-slide-bar';
	import VueSlider from 'vue-slider-component';
	import SelectCompanyProductCategories from "../../../../components/widgets/SelectCompanyProductCategories"
	import SelectCompanyProductCollections from "../../../../components/widgets/SelectCompanyProductCollections"
	import SelectCompanyLocations from "../../../../components/widgets/SelectCompanyLocations"
	import SelectCompanyDomains from "../../../../components/widgets/SelectCompanyDomains"
	import CompanyProductSearch from '../../../../components/widgets/CompanyProductSearch';
	import CompanyProductList from '../../../../components/widgets/CompanyProductList';
	import SelectCompanyProductAttributes from "../../../../components/widgets/SelectCompanyProductAttribute"
	import SelectCompanyProductAttributeValue from "../../../../components/widgets/SelectCompanyProductAttributeValue"
	export default {
		name: 'widgetContentFilters',
		components: 
		{
			SelectCompanyProductCategories,
			SelectCompanyProductCollections,
			SelectCompanyLocations,
			SelectCompanyDomains,
			VueSlideBar,
			VueSlider,
			CompanyProductSearch,
			CompanyProductList,
			SelectCompanyProductAttributes,
			SelectCompanyProductAttributeValue,
		},
	}

</script>