<script type="text/javascript">
	export default{
		props: {
			resource: {
		        type: String,
		        default: () => '',
		      },
		      resource_id: {
		        type: Number,
		        default: () => '',
		      },
		      api_token: {
		        type: String,
		        default: () => '',
		      },
		      resource_route: {
		        type: String,
		        default: () => '',
		      },
		      customer_list: {
		        type: Array,
		        default: () => '',
		      },
		      expanded:{
		      	type: Number,
		      	default: () => 0
		      },
		      view:{
		      	type: String,
		      	default: () => 'grid'
		      },
		      show_view: {
		      	type: Boolean,
		      	default: () => true,
		      }
		  },
		  data(){
			return{
		        quickviewproduct: '',
			}
		  },
		  methods: {
		  	getList(){
		  		if(this.resource_route == ''){
		  		axios.get('/api/company/customers?customers='+this.customer_list+'&limit=0', {headers: {'Authorization' : 'Bearer '+this.api_token}}).then(response => {
		  			this.customer_list = response.data;
		  		}).catch(error => {
		  			this.customer_error = true;
		  		});
		  		}
		  	},
		  	removeResource(resource){
						this.$emit('remove-resource', resource);
		  	},
		  	priceIncVat(price, tax_percentage){
		      var tax = 1 + (tax_percentage / 100);
		      return price * tax;
		    }
		  }
	}
</script>
<template>
	 <div class="row">
          <div class="col-md-12">
          	<ul class="nav nav-pills product-view-nav mb-2 float-right" v-if="show_view">
          	<li class="nav-item">
                  <a class="nav-link" :class="{'active': view == 'grid' }" href="javascript: void(0);" @click="view = 'grid'">
                    <i class="bx bx-grid-alt"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': view == 'list' }" href="javascript: void(0);" @click="view = 'list'">
                    <i class="bx bx-list-ul"></i>
                  </a>
                </li>
               </ul>
          	<ul class="list-group product-list w-100 no-shadow" v-if="(customer_list.length !== 0) && (view == 'list')">
                              <li v-for="product in customer_list" class="list-group-item d-flex px-0 py-0 border product-list-item mb-2 shadow-sm">
                              	<div class="d-flex product-list-item-container w-100">
                              <div v-if="product.customer !== null">
                              	<img v-if="product.customer.avatar" class="avatar-lg mr-3 product-list-img" :src="product.user_details.avatar">
                              	<img v-else class="avatar-lg mr-3 product-list-img" src="/images/product-placeholder.png">
                              </div>
                              <img v-else class="avatar-lg mr-3 product-list-img" src="/images/product-placeholder.png">
                                <div class="mr-auto ml-2 my-auto flex-grow-3 mr-3">
                                	<strong class="mr-auto my-auto text-primary">{{product.firstname}} {{product.name}} {{product.lastname}}</strong>
                                	
						            <a class="text-muted italic no-margin" href="javascript:;">{{product.email}}</a>
						           
                                </div>
                                <div class="mr-auto ml-2 my-auto flex-grow-1 mr-3 ml-auto d-flex">
                                	<a class="btn btn-outline-light btn-rounded ml-auto my-auto mr-2" href="javascript:;" @click="quickviewproduct = product"><i class="py-3 px-2 mdi mdi-eye-outline"></i></a>
                                <a class="btn btn-outline-light btn-rounded my-auto" href="javascript:;" @click="removeResource(product)"><i class="py-1 px-2 bx bx-trash-alt"></i></a>
                            	</div>
              
                              </div>
                              </li>
                              

                              <!--<li class="list-group-item py-4 text-center" ><a class="btn btn-outline-light btn-rounded">Load More</a>
                              </li>-->
                            </ul>
                <ul class="list-group product-list w-100 no-shadow" v-if="(customer_list.length == 0) && (view == 'list')">
                	  <li class="list-group-item d-flex">
                	  	<i class="bx bx-warning"></i> No Customer(s) Selected <span v-if="resource !== ''">assigned to this {{resource}}</span>
                	  </li>
                </ul>
          </div>
          <div class="col-md-12">
          	<div class="row product-grid px-2 row-eq-height" v-if="(customer_list.length !== 0) && (view == 'grid')">
                              <div class=" d-flex flex-column px-2 product-grid-item mb-2" :class="{'col-md-4' : expanded == 0,'col-md-3' : expanded == 1}" v-for="product in customer_list">
                              	<div class="border shadow-sm product-grid-item-container">
                              	<img v-if="product.user_details.avatar" class="flex-grow-3 w-100 product-grid-img" :src="product.user_details.avatar">
                              	<img v-else class="flex-grow-3 w-100 product-grid-img" src="/images/product-placeholder.png">
                                <div class="my-3 flex-grow-3 px-3">
                                	<strong class="mr-auto my-auto text-primary">{{product.firstname}} {{product.lastname}}</strong>
						           					<a class="text-muted italic no-margin" href="javascript:;">{{product.user_details.email}}</a>
                                </div>
                                <div class="my-3 flex-grow-3 px-3">
                                	<a class="btn btn-outline-light btn-rounded ml-auto my-auto flex-grow-3" href="javascript:;" @click="quickviewproduct = product"><i class="mdi mdi-eye-outline"></i></a>
                                	<a class="btn btn-outline-light btn-rounded ml-auto my-auto flex-grow-3" href="javascript:;"><i class="bx bx-pencil"></i></a>
                                <a class="btn btn-outline-light btn-rounded ml-auto my-auto flex-grow-3" href="javascript:;" @click="removeResource(product)"><i class="bx bx-trash-alt"></i></a>
              					</div>
                              	</div>
                              </div>
                              

                              <!--<li class="list-group-item py-4 text-center" ><a class="btn btn-outline-light btn-rounded">Load More</a>
                              </li>-->
                            </div>
                <ul class="list-group product-list w-100 no-shadow"  v-if="(customer_list.length == 0) && (view == 'grid')">
                	  <li class="list-group-item d-flex">
                	  	<i class="bx bx-warning"></i> No Customer(s) Selected <span v-if="resource !== ''">assigned to this {{resource}}</span>
                	  </li>
                </ul>
          </div>
          <div class="product-modal d-flex flex-column justify-content-center" v-if="quickviewproduct !== ''">
          	<div class=" d-flex flex-column px-2 product-grid-item mb-2 mx-auto col-md-8 col-sm-10">
                              	<div class="border shadow-sm product-grid-item-container bg-white row">
                              		<div class="col-md-6 m-0 p-0">
                              	<img v-if="quickviewproduct.user_details.avatar" class="flex-grow-3 w-100 quickviewproduct-grid-img" :src="quickviewproduct.user_details.avatar">
                              	<img v-else class="flex-grow-3 w-100 product-list-img" src="/images/product-placeholder.png"></div>
                              	<div class="col-md-6 d-flex flex-column justify-content-center">
                              		<a href="javascript:;" @click="quickviewproduct = ''" class="btn btn-rounded btn-outline-light float-right mr-2 mt-2 position-absolute close-modal"><i class="mdi mdi-close noti-icon font-size-20"></i></a>
                              	                                <div class="my-3 flex-grow-3 px-3">
                                	<h2 class="mr-auto my-auto text-primary">{{quickviewproduct.firstname}} ewproduct.lastname}}</h2>
                                	
 																<a class="text-muted italic no-margin" href="javascript:;">{{product.user_details.email}}</a>
                                </div>
                                <div class="my-3 flex-grow-3 px-3">
                                	<a class="btn btn-outline-light btn-rounded ml-auto my-auto flex-grow-3" href="javascript:;"><i class="bx bx-pencil"></i></a>
                                <a class="btn btn-outline-light btn-rounded ml-auto my-auto flex-grow-3" href="javascript:;" @click="removeResource(quickviewproduct)"><i class="bx bx-trash-alt"></i></a>
              					</div>
              				</div>
                              	</div>
                              </div>
          </div>
     </div>
</template>