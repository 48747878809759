<script>
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import VueSlideBar from "vue-slide-bar";
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import CompanyProductSearch from '../../components/widgets/CompanyProductSearch';
import CompanyProductList from '../../components/widgets/CompanyProductList';
import SideSlidePanel from "../../components/widgets/SideSlidePanel";

export default{
	components:{VueSlideBar, Layout, PageHeader, SideSlidePanel, ckeditor: CKEditor.component, CompanyProductSearch, CompanyProductList},
	data() {
    	return {
          deleteMode: '', 
          csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
          editor: ClassicEditor,
    		  brandsData: [],
    		  categoryProducts: [],
    		  selectedCategory: [],
    		  brands_error: [],
    		  products_error: [],
          addProductToggle: false,
          error_reponse: [],
          error_message: false,
          success_message: false,
          success_text: '',
          loadingForm: false, 
          loadingList: false,
    		  view: 'grid',
    		  search: '',
    		  limit: '25',
    		  sortby: 'id',
    		  order: 'desc',
          currentPage: 1,
    		  selectedids: [],
          newcollection: {
            name: '',
            description: '',
            image: '',
            cover_image: '',
            order: '',
            slug: '',
            active: 0,
            public: 0,
            trade: 0,
          },
          editcollection: {
            name: '',
            description: '',
            image: '',
            cover_image: '',
            order: '',
            slug: '',
            active: 0,
            public: 0,
            trade: 0,
          },
          isCheckAll: false,
          addNewPanel: false,
          editPanel: false,
          addPanelClass: 'edit-sidepanel',
          expandable: 0,
    		  getProductsPanel: 0,
    		  selected_parent: {id: null},
    		  limits: [
		        {value: 25, text: "25"},
		        {value: 50, text: "50"},
		        {value: 100, text: "100"},
		      ],
		      title: "Collections",
		      items: [
		        {
		          text: "Stock",
		          href: "javascript:;",
		        },
		        {
		          text: "Collections",
		          active: true,
		        },
		      ],
  				}	
	},
	methods:{
    removeFromResource(product){
            axios.post('/api/company/product/collection/'+this.selectedCategory.id+'/remove-product', {product_id: product.uid}, {
              headers: {
                'Authorization' : 'Bearer '+this.$attrs.user.api_token
              }
            }).then(response => {
              this.$emit('product-removed', response.data);
              this.success_text = 'Product Removed from Collection';
              this.success_message = true;
              this.getProducts(this.selectedCategory.id);
              let self = this;
              setTimeout(function(){
                self.success_message = false;
              }, 1000);
            }).catch(error => {
              this.$emit('resource-error', error.data);
            });
    },
		brandQuery(){
			axios.get('/api/company/product/collections?page='+this.currentPage+'&limit='+this.limit+'&sortby='+this.sortby+'&order='+this.order+'&search='+this.search+'&parent_id='+this.selected_parent.id+'&active=&public_active=&trade_active=', {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
              }}).then(response => {
				this.brandsData = response.data;
			}).catch(error => {
				this.brands_error = error.data;
			});
		},
		getProducts(category_id){
      this.categoryProducts = [];
      this.loadingForm = false;
			axios.get('/api/company/product/collection/'+category_id+'/products', {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
              }}).then(response => {
        this.categoryProducts = response.data;
         let self = this;
          setTimeout(function(){
            self.loadingForm = false;
          }, 500);
      }).catch(error => {
        this.products_error = error.data;
      });
		},
    setActive(active, area){
            axios.post('/api/company/product/collection/set-active', {ids: this.selectedids, active: active, market: area}, {headers: {
              'Authorization' : 'Bearer '+this.$attrs.user.api_token
            }}).then(response => {
              
              this.success_text = 'Congratulations, your action has been saved';
              this.success_message = true;
              if(this.selectedids.length > 1){
                return this.brandQuery();
              }else{
                return true;
              }
                
            }).catch(error => {

            });
    },
    destroyResource(product_id){
            axios.post('/api/company/product/collection/'+this.selected_product_id+'/destroy', {}, {headers: {
              'Authorization' : 'Bearer '+this.$attrs.user.api_token
            }}).then(response => {
                this.$bvModal.hide('delete-modal');
                return this.brandQuery();
            }).catch(error => {

            });
    },
     bulkDestroyResource(){
            axios.post('/api/company/product/collection/bulk-destroy', {ids: this.selectedids}, {headers: {
              'Authorization' : 'Bearer '+this.$attrs.user.api_token
            }}).then(response => {
                this.$bvModal.hide('delete-modal');     
                this.selectedids = [];
                return this.brandQuery();
            }).catch(error => {

            });
     },
     bulkDuplicateResource(){
      axios.post('/api/company/product/collection/duplicate', {ids: this.selectedids}, {headers : {
        'Authorization' : 'Bearer '+this.$attrs.user.api_token
      }}).then(response => {
        console.log(response);
        this.success_text = 'Congratulations, your collection(s) have been updated.';
        this.success_message = true;
        //this.resetProjectData();
        this.addPanel = false;
        let self = this;
          setTimeout(function(){
            self.loadingForm = false;
            self.brandQuery();
            //self.$bvModal.hide('move-modal');
          }, 1500);
      }).catch( error => {
        
        this.error_reponse = error.response.data.errors;
        this.error_message = true;
        //this.resetProjectData();
        let self = this;
          setTimeout(function(){
            self.loadingForm = false;
          }, 1500);
      });
    },
    addCollection(){
      this.loadingForm = true;
      var postForm = new FormData(document.getElementById("addcollectionform"));
      axios.post('/api/company/product/collection/create', postForm, {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
        console.log(response);
        this.success_text = 'Congratulations, your new collection has been added.';
        this.success_message = true;
        //this.resetProjectData();
        //this.addPanel = false;
        let self = this;
          setTimeout(function(){
            self.loadingForm = false;
            self.addNewPanel = false;
            self.brandQuery();
          }, 1500);
      }).catch( error => {
        
        this.error_reponse = error.response.data.errors;
        this.error_message = true;
        //this.resetProjectData();
        let self = this;
          setTimeout(function(){
            self.loadingForm = false;
          }, 1500);
      });
    },
    updateCollection(){
      this.success_message = false;
      this.error_message = false;
      this.loadingForm = true;
      var postForm = new FormData(document.getElementById("updatecollectionform"));
      axios.post('/api/company/product/collection/'+this.editcollection.id+'/update', postForm, {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
        console.log(response);
        this.success_text = 'Congratulations, your collection has been updated.';
        this.success_message = true;
        //this.resetProjectData();
        this.addPanel = false;
        let self = this;
          setTimeout(function(){
            self.loadingForm = false;
            self.editPanel = false;
            self.brandQuery();
          }, 500);
      }).catch( error => {
        
        this.error_reponse = error.response.data.errors;
        this.error_message = true;
        //this.resetProjectData();
        let self = this;
          setTimeout(function(){
            self.loadingForm = false;
          }, 500);
      });

    },
    showStatusMessage(result){
      if(result == false){
        this.error_reponse = {error: ["Product Already Exists in Category"]};
        this.error_message = true;
      }else{
        this.success_message = true;
        this.success_text = 'Congratulations, your action has been saved.';
        this.getProducts(this.selectedCategory.id)
      }
    },
		priceIncVat(price, tax_percentage){
			var tax = 1 + (tax_percentage / 100);
			return price * tax;
		},
    filterSelected(id){
        if(this.selectedids.length > 0){
          var exists = this.selectedids.filter(item => item == id);
          if(exists.length > 0){
            return true;
          }else{
            return false
          }
        }else{
          return false;
        }
      },
      checkAll: function(){ 
                 this.isCheckAll = !this.isCheckAll; 
                 this.selectedids = []; 
                 if(this.isCheckAll){ 
                     // Check all 
                     for (var key in this.brandsData.data) {          
                        this.selectedids.push(this.brandsData.data[key].id); 
                     } 
                 } 
              }, 
      updateCheckall: function(){ 
                 if(this.selectedids.length == this.brandsData.data.length){
                    this.isCheckAll = true; 
                 }else{ 
                    this.isCheckAll = false; 
                 } 
      },
      sanitizeTitle: function(title) {
            var nickname = "";
            // Change to lower case
            var titleLower = title.toLowerCase();
            // Letter "e"
            nickname = titleLower.replace(/e|é|è|ẽ|ẻ|ẹ|ê|ế|ề|ễ|ể|ệ/gi, 'e');
            // Letter "a"
            nickname = nickname.replace(/a|á|à|ã|ả|ạ|ă|ắ|ằ|ẵ|ẳ|ặ|â|ấ|ầ|ẫ|ẩ|ậ/gi, 'a');
            // Letter "o"
            nickname = nickname.replace(/o|ó|ò|õ|ỏ|ọ|ô|ố|ồ|ỗ|ổ|ộ|ơ|ớ|ờ|ỡ|ở|ợ/gi, 'o');
            // Letter "u"
            nickname = nickname.replace(/u|ú|ù|ũ|ủ|ụ|ư|ứ|ừ|ữ|ử|ự/gi, 'u');
            // Letter "d"
            nickname = nickname.replace(/đ/gi, 'd');
            // Trim the last whitespace
            nickname = nickname.replace(/\s*$/g, '');
            // Change whitespace to "-"
            nickname = nickname.replace(/\s+/g, '-');
            // Remove quotations
            nickname = nickname.replace(/["']/g, "");
            // Remove symbols
            nickname = nickname.replace(/@|#|!/g, '');
            return nickname;
        }
	},
	created(){
		this.brandQuery();
  }
}
</script>

<template>
	<Layout  :nickname="$attrs.nickname" :user="$attrs.user" :menuItems="$attrs.menuItems">
		<PageHeader :title="title" :items="items" />
		    <div class="row">
          <b-col md="12">
                    <transition name="slide-success" mode="out-in">
                  <b-alert id="success-message" variant="success" :show="success_message"  dismissible @dismissed="success_message = !success_message">
                      <p class="font-size-14">{{success_text}}</p>
                    </b-alert>
                  </transition>
                  </b-col>
      <div class="col-md-12">
 <div class="row mb-3">
          <div class="col-xl-1 col-sm-6">
            <div class="mt-2">
              <b-form-select class="form-control" v-model="limit" :options="limits" @input="brandQuery()"></b-form-select>
            </div>
          </div>
         
          <div class="col-lg-2 col-sm-6 d-flex">
          <form class="my-auto mr-auto">
            <small class="mx-auto my-2 text-center d-block">Results {{brandsData.from}} - {{brandsData.to}} of {{brandsData.total}}</small>
          </form>
           <div v-if="view == 'grid'" class="text-center my-auto ml-auto"><div
                        class="form-check form-switch form-switch-sm mb-0 mr-auto p-0 mt-auto d-flex"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input mx-auto my-auto" 
                          type="checkbox"
                          id="SwitchCheckSizeCheckall"
                         
                          v-model="isCheckAll"
                          @click='checkAll()'
                          name="active"
                        />
                        <label class="d-inline-block my-auto ml-2" for="SwitchCheckSizeCheckall">Select All</label>
                    </div></div>
        </div>
        <div class="col-lg-3 d-flex">
         
          <b-form-group class="my-auto ml-auto mr-3">
            <b-form-radio-group id="btn-radios-1" button-variant="outline-light"
        size="md" v-model="marketplace_brands" :options="quickfilter_options" buttons @input="brandQuery()"></b-form-radio-group>
      
          
          </b-form-group>
        </div>
        <div class="col-lg-3 col-sm-6 d-flex">
          
            <div class="mt-sm-0 form-inline w-100" >
              <div class="search-box mr-2 ml-auto">
                <div class="position-relative">
                  <input
                    type="text"
                    class="form-control w-100 border-0"
                    placeholder="Search name, reference or description..."
                    v-model="search"
                    @keyup.enter="brandQuery()"
                  />
                  <i class="bx bx-search-alt search-icon"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-sm-6 d-flex">
            <div class="d-flex ml-auto">
              <ul class="nav nav-pills product-view-nav">
                
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': view == 'grid' }" href="javascript: void(0);" @click="view = 'grid'">
                    <i class="bx bx-grid-alt"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': view == 'list' }" href="javascript: void(0);" @click="view = 'list'">
                    <i class="bx bx-list-ul"></i>
                  </a>
                </li>
                <li class="nav-item">
                 <b-dropdown text="Bulk Actions" variant="primary" class="rounded">
                    <b-dropdown-item :disabled="($attrs.user.companies[0].userrole.name !== 'Owner') && !$attrs.user.companies[0].permissions.edit.includes($attrs.active_menu.id)" href="javascript: void(0);" @click="setActive(true, 'active')">Set Active</b-dropdown-item>
                  <b-dropdown-item :disabled="($attrs.user.companies[0].userrole.name !== 'Owner') && !$attrs.user.companies[0].permissions.edit.includes($attrs.active_menu.id)" href="javascript: void(0);" @click="setActive(false, 'active')">Set Inactive</b-dropdown-item>
                  <b-dropdown-item :disabled="($attrs.user.companies[0].userrole.name !== 'Owner') && !$attrs.user.companies[0].permissions.edit.includes($attrs.active_menu.id)" href="javascript: void(0);" @click="bulkDuplicateResource()">Duplicate</b-dropdown-item>
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-item :disabled="($attrs.user.companies[0].userrole.name !== 'Owner') && !$attrs.user.companies[0].permissions.delete.includes($attrs.active_menu.id)" href="javascript: void(0);" v-b-modal.delete-modal @click="deleteMode = 'bulk'">Delete</b-dropdown-item>
                </b-dropdown>
                </li>
                <li class="nav-item">
                  
                 <b-button variant="success"
                  :disabled="($attrs.user.companies[0].userrole.name !== 'Owner') && !$attrs.user.companies[0].permissions.add.includes($attrs.active_menu.id)"
                    class="btn btn-success btn-rounded mb-2 mr-2 text-white"
                    href="javascript:;"
                    @click="addNewPanel = !addNewPanel"
                  >
                    <i class="mdi mdi-plus mr-1"></i> New
                  </b-button>
                </li>
              </ul>
              <div class="text-sm-right">
                  
                </div>
          </div>
        </div>
      </div>
      <div class="d-flex flex-column justify-content-center h-100" v-if="($attrs.user.companies[0].userrole.name !== 'Owner') && !$attrs.user.companies[0].permissions.read.includes($attrs.active_menu.id)" >
          <AccessRestricted :page="false"></AccessRestricted>
        </div>
        <div v-else>
        <div class="card" v-if="view == 'list'" >
          <div class="card-body p-0">
            <div class="table-responsive">
              <table class="table table-centered table-hover">

                <thead class="thead-light">
                  <tr>
                    <th scope="col"  style="width: 100px" class="text-center"><div
                        class="form-check form-switch form-switch-sm mb-0 mx-auto p-0 mt-auto d-flex"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input mx-auto my-auto" 
                          type="checkbox"
                          id="SwitchCheckSizesm"
                         
                          v-model="isCheckAll"
                          @click='checkAll()'
                          name="active"
                        />
                    </div></th>
                    <th scope="col"></th>
                    <th scope="col"><a :class="{'text-dark': sortby != 'name'}" href="javascript: void(0);" @click="orderDesc = !orderDesc,  sortby = 'name', brandQuery()">Name & Description <i v-if="orderDesc == true" class="bx bx-caret-up"></i><i v-if="orderDesc == false" class="bx bx-caret-down"></i></a></th>
                    
                    <th scope="col">Products</th>
                    <th scope="col">Status</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
               
                <tbody>
                  
                  <tr v-for="list in brandsData.data" :key="list.id" valign="middle">
                    <td>
                       <div class="text-center" v-if="list.image">
                      <b-form-checkbox class="select-row-btn" v-model="selectedids" :value="list.id" button-variant="primary" @input="updateSelected" button>
                        <i v-if="filterSelected(list.id)" class="bx bx-check"></i>
                        <img v-else :src="'/image/'+list.image" alt class="avatar-sm rounded-circle" />
                      </b-form-checkbox>
                    </div>
                    <div class="text-center" v-else >
                        <b-form-checkbox class="select-row-btn" v-model="selectedids" :value="list.id" button-variant="primary" @input="updateSelected" button>

                          <i v-if="filterSelected(list.id)" class="bx bx-check"></i><span class="avatar-title rounded-circle" v-else>{{(list.name).slice(0,1)}}</span></b-form-checkbox>
                    </div> 
                    </td>
                    <td>
                      <div v-if="list.font" class="avatar-xs">
                        <span class="avatar-title rounded-circle">{{list.font}}</span>
                      </div>
                      <div v-if="list.logo">
                        <img class="rounded-circle avatar-lg" :src="`/public/storage/${list.logo}`" alt />
                      </div>
                    </td>
                    <td>
                        <h5 class="m-0">{{list.name}}</h5>
                     <small class="text-muted mb-0" v-if="list.description != null" v-html="(list.description).slice(0, 200)+'...'"></small>
                    </td>
                    <td><a href="javascript:;" @click="selectedCategory = list, getProducts(list.id), getProductsPanel = 1" class="btn btn-outline-light btn-rounded"><i class="bx bx bx-purchase-tag-alt"></i></a>
                    </td>
                    <td>
                      <div
                        class="form-check form-switch form-switch-sm my-auto ml-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          :id="`SwitchCheckSize-${list.id}`"
                          v-model="list.active"
                          :value="list.active"
                          :disabled="($attrs.user.companies[0].userrole.name !== 'Owner') && !$attrs.user.companies[0].permissions.edit.includes($attrs.active_menu.id)"
                          @change="selectedids = [], selectedids.push(list.id), setActive(list.active, 'active')"
                          name="active"
                        />
                        <label class="form-check-label" :for="`SwitchCheckSize-${list.id}`" 
                          >Active</label
                        >
                    </div>
                    <div
                        class="form-check form-switch form-switch-sm my-auto ml-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          :id="`SwitchCheckSizepub-${list.id}`"
                          v-model="list.public"
                          :value="list.public"
                          @change="selectedids = [], selectedids.push(list.id), setActive(list.public, 'public')"
                          name="public"
                          :disabled="($attrs.user.companies[0].userrole.name !== 'Owner') && !$attrs.user.companies[0].permissions.edit.includes($attrs.active_menu.id)"
                        />
                        <label class="form-check-label" :for="`SwitchCheckSizepub-${list.id}`" 
                          >Public</label
                        >
                    </div>
                    <div
                        class="form-check form-switch form-switch-sm my-auto ml-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          :id="`SwitchCheckSizetrade-${list.id}`"
                          v-model="list.trade"
                          :value="list.trade"
                          @change="selectedids = [], selectedids.push(list.id), setActive(list.trade, 'trade')"
                          name="trade"
                          :disabled="($attrs.user.companies[0].userrole.name !== 'Owner') && !$attrs.user.companies[0].permissions.edit.includes($attrs.active_menu.id)"
                        />
                        <label class="form-check-label" :for="`SwitchCheckSizetrade-${list.id}`" 
                          >Trade</label
                        >
                    </div>
                    </td>
                    <td>
                      <ul class="list-inline font-size-20 contact-links mb-0">
                        <li class="list-inline-item px-2"><a v-if="($attrs.user.companies[0].userrole.name == 'Owner') || $attrs.user.companies[0].permissions.edit.includes($attrs.active_menu.id)" @click="editcollection = list, editPanel = true" v-b-tooltip.hover title="Edit" placement="left"><i class="bx bx-edit-alt"></i>
                          </a>
                        </li>
                       
                        <li class="list-inline-item px-2"><a v-b-tooltip.hover title="Delete" placement="left" v-if="($attrs.user.companies[0].userrole.name == 'Owner') || $attrs.user.companies[0].permissions.delete.includes($attrs.active_menu.id)" @click="selected_product_id = list.id, deleteMode = 'single'" v-b-modal.delete-modal><i class="bx bx-trash-alt"></i>
                          </a>
                        </li>
                        <li class="list-inline-item px-2">
                           <b-dropdown v-b-tooltip.hover title="More Options" placement="left" dropleft class="more-options-dropdown" menu-class="dropdown-menu-right">
			                  <template v-slot:button-content>
			                     <i class="bx bx-dots-horizontal-rounded"></i>
			                  </template>
			                    <b-dropdown-item :disabled="($attrs.user.companies[0].userrole.name !== 'Owner') && !$attrs.user.companies[0].permissions.edit.includes($attrs.active_menu.id)" href="javascript: void(0);"  @click="selectedids = [], selectedids.push(list.id), bulkDuplicateResource()">Duplicate</b-dropdown-item>
			                </b-dropdown>
                        </li>
                      </ul>
                    </td>
                  </tr>
              	</tbody>
              </table>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <b-pagination
              v-if="brandsData.data.length > 0"
              class="justify-content-center"
              pills
              v-model="currentPage"
              :total-rows="brandsData.total"
              :per-page="brandsData.per_page"
              aria-controls="my-table"
              @input="brandQuery"
            ></b-pagination>
             <small class="mx-auto my-2 text-center d-block">Records {{brandsData.from}} - {{brandsData.to}} of {{brandsData.total}} // Page {{brandsData.current_page}} of {{brandsData.last_page}}</small>
              </div>
            </div>
          </div>
      </div>
    <div class="row" v-if="view == 'grid'" >
      <div class="col-xl-4"  v-for="list in brandsData.data" :key="list.id">
        <div class="card">
          <div class="bg-soft-primary bg-light border-radius-top-right-1em border-radius-top-left-1em" style="min-height: 250px;">
            <div class="row">
              
              <div class="col-12 align-self-end" style="overflow: hidden; max-height: 250px;" >
                <img v-if="list.cover_image" :src="'/image/'+list.cover_image" alt class="img-fluid  border-radius-top-right-1em border-radius-top-left-1em" style="object-fit: cover; min-height: 250px; " />
             
              </div>
            </div>
          </div>
          <div class="card-body pt-0">
            <div class="row">
              <div class="col-sm-12 text-center">
               <!--<div class="avatar-md mx-auto profile-user-wid mb-4">
                  <img v-if="list.image !== null" class="img-thumbnail rounded-circle h-100" :src="`${list.image}`" alt />
                  <span v-else class="avatar-title rounded-circle">
                    {{list.name.slice(0,1)}}
                  </span>
                </div>-->
                <div class="avatar-md profile-user-wid mx-auto">
                   <div class="text-center" v-if="list.image">
                      <b-form-checkbox class="select-row-btn w-image" v-model="selectedids" :value="list.id" button-variant="primary" @input="updateSelected" button>
                        <i v-if="filterSelected(list.id)" class="bx bx-check"></i>
                        <img v-else :src="'/image/'+list.image" alt class="avatar-md img-thumbnail rounded-circle h-100" />
                      </b-form-checkbox>
                    </div>
                    <div class="text-center" v-else >
                        <b-form-checkbox class="select-row-btn w-image" v-model="selectedids" :value="list.id" button-variant="primary" @input="updateSelected" button>

                          <i v-if="filterSelected(list.id)" class="bx bx-check"></i><span class="avatar-title rounded-circle" v-else>{{(list.name).slice(0,1)}}</span></b-form-checkbox>
                    </div> 
                </div>
                <h4 class="font-size-18 text-truncate mt-3">{{list.name}}</h4>
                <p class="text-muted mb-0 text-truncate" v-html="list.description"></p>
              </div>

              <div class="col-sm-12">
               
              </div>
            </div>
          </div>
          <div class="card-footer  bg-transparent border-top">
                  <div class="d-flex">
                    <a href="javascript:;" @click="selectedCategory = list, getProducts(list.id), getProductsPanel = 1" class="btn btn-outline-light btn-rounded"><i class="bx bx bx-purchase-tag-alt my-1"></i></a>
                    <b-tooltip target="tooltip-add">Add Product</b-tooltip>
                    <a id="tooltip-add" @click="editcollection = list, editPanel = true" v-if="$attrs.user.companies[0].permissions.edit.includes($attrs.active_menu.id)" class="btn btn-outline-light font-size-16 btn-rounded ml-2 my-auto"><i class="bx bx-pencil"></i></a><b-tooltip target="tooltip-add">Add Product</b-tooltip>
                    <a class="btn btn-outline-light font-size-16 btn-rounded ml-2 my-auto" v-b-tooltip.hover title="Delete" placement="left" v-if="$attrs.user.companies[0].permissions.delete.includes($attrs.active_menu.id)" @click="selected_product_id = list.id, deleteMode = 'single'" v-b-modal.delete-modal><i class="bx bx-trash-alt"></i>
                          </a>
                    <b-dropdown v-b-tooltip.hover title="More Options" placement="left" variant="outline-light" dropup class="more-options-dropdown btn-rounded dropup ml-2" menu-class="dropdown-menu-right">
                        <template v-slot:button-content>
                           <i class="bx bx-dots-horizontal-rounded"></i>
                        </template>
                          <b-dropdown-item :disabled="($attrs.user.companies[0].userrole.name !== 'Owner') && !$attrs.user.companies[0].permissions.edit.includes($attrs.active_menu.id)" href="javascript: void(0);"  @click="selectedids = [], selectedids.push(list.id), bulkDuplicateResource()">Duplicate</b-dropdown-item>
                      </b-dropdown>
                      <div
                        class="form-check form-switch form-switch-sm my-auto ml-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          :id="`SwitchCheckSize-${list.id}`"
                          v-model="list.active"
                          :value="list.active"
                          name="active"
                          :disabled="($attrs.user.companies[0].userrole.name !== 'Owner') && !$attrs.user.companies[0].permissions.edit.includes($attrs.active_menu.id)"
                          @change="selectedids = [], selectedids.push(list.id), setActive(list.active)"
                        />
                        <label class="form-check-label" :for="`SwitchCheckSize-${list.id}`" 
                          >Active</label
                        >
                    </div>
                     <div
                        class="form-check form-switch form-switch-sm my-auto ml-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          :id="`SwitchCheckSizepub-${list.id}`"
                          v-model="list.public"
                          :value="list.public"
                          name="public"
                          :disabled="($attrs.user.companies[0].userrole.name !== 'Owner') && !$attrs.user.companies[0].permissions.edit.includes($attrs.active_menu.id)"
                          @change="selectedids = [], selectedids.push(list.id), setActive(list.public, 'public')"
                        />
                        <label class="form-check-label" :for="`SwitchCheckSizepub-${list.id}`" 
                          >Public</label
                        >
                    </div>
                     <div
                        class="form-check form-switch form-switch-sm my-auto ml-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          :id="`SwitchCheckSizetrade-${list.id}`"
                          v-model="list.trade"
                          :value="list.trade"
                          name="trade"
                          :disabled="($attrs.user.companies[0].userrole.name !== 'Owner') && !$attrs.user.companies[0].permissions.edit.includes($attrs.active_menu.id)"
                          @change="selectedids = [], selectedids.push(list.id), setActive(list.trade, 'trade')"
                        />
                        <label class="form-check-label" :for="`SwitchCheckSizetrade-${list.id}`" 
                          >Trade</label
                        >
                    </div>
                  </div>
          </div>
        </div>
    </div>
    	</div>
    </div>
    </div>

</div>
 <b-modal id="delete-modal" size="md" hide-footer hide-header centered>
        <b-card class="border-radius-1em border shadow-none">
          <h4>Are you sure?</h4>
          <p v-if="deleteMode == 'bulk'">You are about to delete {{selectedids.length}} products. Are you sure you want to do this? This action is not reversable</p>
           <p v-if="deleteMode == 'single'">You are about to delete this category. Are you sure you want to do this? This action is not reversable</p>

           <div class="d-flex">
            <a class="btn btn-danger" v-if="deleteMode == 'bulk'" @click="bulkDestroyResource">
              Delete
            </a>
             <a class="btn btn-danger"  v-if="deleteMode == 'single'" @click="destroyResource">
              Delete
            </a>
            <a class="btn btn-success" @click="$bvModal.hide('delete-modal')">
              Cancel
            </a>
          </div>
        </b-card>
      </b-modal>
	<SideSlidePanel :editPanel="getProductsPanel" :panelClass="'edit-sidepanel'" @close-panel="getProductsPanel = 0">
		<div class="row">
				 <div class="d-flex flex-column justify-content-center h-100" v-if="($attrs.user.companies[0].userrole.name !== 'Owner') && !$attrs.user.companies[0].permissions.view.includes($attrs.active_menu.id)" >
          <AccessRestricted :page="false"></AccessRestricted>
        </div>
				<div class="col-md-12" v-else>
				<b-card>
        <div class="d-flex mb-3">
          <img v-if="selectedCategory.image" :src="'https://'+ $attrs.nickname + '.s3.eu-west-2.amazonaws.com/' + selectedCategory.image" class="rounded-circle avatar-md mr-3">
          <img v-else src="/images/product-placeholder.png" class="rounded-circle avatar-md mr-3">
					<h4 class="my-auto mr-auto ml-3">Products in <strong class="text-primary">{{selectedCategory.name}}</strong></h4>
           <a id="tooltip-add" v-if="($attrs.user.companies[0].userrole.name == 'Owner') || $attrs.user.companies[0].permissions.edit.includes($attrs.active_menu.id)" class="btn btn-outline-light font-size-16 btn-rounded ml-auto mr-3 my-auto" @click="addProductToggle = !addProductToggle"><i class="mdi mdi-plus"></i></a><b-tooltip target="tooltip-add">Add Product</b-tooltip>
        </div>
					<CompanyProductSearch v-if="addProductToggle" class="mt-1 mb-4" :resource="'collection'" :resource_id="selectedCategory.id" :api_token="$attrs.user.api_token" :resource_route="'/api/company/product/collection/'+selectedCategory.id+'/add-product?'" @product-added="showStatusMessage" :company="$attrs.company"></CompanyProductSearch>
            <b-overlay
                            id="overlay-background"
                            :show="loadingForm"
                            variant="white"
                            opacity="0.85"
                            blur="1px"
                            rounded="md"
                          >
              <CompanyProductList :resource="'collection'" :product_list="categoryProducts" :expanded="isExpanded" @remove-product="removeFromResource" :company="$attrs.company"></CompanyProductList>

              <b-pagination v-if="categoryProducts.length > 0" v-model="categoryProductsPage" :total-rows="categoryProducts.total" :per-page="categoryProducts.per_page">
                
              </b-pagination>
            </b-overlay>
				</b-card>
				</div>
		</div>
	</SideSlidePanel>
   <SideSlidePanel :editPanel="addNewPanel" :panelClass="addPanelClass" @close-panel="addNewPanel = !addNewPanel" :expandable="1"  @expand="addPanelClass = 'edit-sidepanel-large'" @contract="addPanelClass = 'edit-sidepanel'">
      <div class="row">
         <div class="d-flex flex-column justify-content-center h-100" v-if="($attrs.user.companies[0].userrole.name !== 'Owner') && !$attrs.user.companies[0].permissions.add.includes($attrs.active_menu.id)" >
          <AccessRestricted :page="false"></AccessRestricted>
        </div>
        <div class="col-md-12" v-else>
        <b-card class="shadow-none">
          <h4 class="my-3">Add New Collection</h4>
          <b-overlay
                        id="overlay-background"
                        :show="loadingForm"
                        variant="white"
                        opacity="0.85"
                        blur="1px"
                        rounded="md"
                      >
         <b-form id="addcollectionform" method="POST" enctype="multipart/form-data">
                   <input type="hidden" name="_token" :value="csrf">
           <b-row>
                     <b-col md="12">
                    <transition name="slide-error" mode="out-in">
                    <b-alert id="errors" variant="danger" :show="error_message"  dismissible @dismissed="error_message = !error_message">
                      <p class="font-size-14">Oops! Please correct the following errors in your submission</p>
                      <ul>
                        <li v-for="(error, key) in error_reponse">
                          {{error[0]}}
                        </li>
                      </ul>
                    </b-alert>
                    
                  </transition>
                  </b-col>
          </b-row>
               <b-form-group label="Name">
            <b-form-input type="text" name="name" v-model="newcollection.name"></b-form-input>
            <input type="hidden" name="slug" :value="sanitizeTitle(newcollection.name)">
          </b-form-group>
           <b-form-group class="mt-2" label="Description">
            <b-textarea rows="6" v-model="newcollection.description" name="description"></b-textarea>
          </b-form-group>
          <b-form-group class="mt-2" label="Image">
            <input class="form-control mb-2" name="image" type="file">
          </b-form-group>
          <b-form-group class="mt-2" label="Cover Image">
            <input class="form-control mb-2" name="cover_image" type="file">
          </b-form-group>
             <b-form-group class="my-3">
                     <div
                        class="form-check form-switch form-switch-sm mb-0 mt-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          id="newbrand-owner"
                          v-model="newcollection.active" value="true"
                          unchecked-value="false"
                          name="active"
                        />
                        <label class="form-check-label" for="newbrand-owner" 
                          > Active</label
                        >
                    </div>
                    <div
                        class="form-check form-switch form-switch-sm my-auto ml-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          :id="`SwitchCheckSizepub-${newcollection.id}`"
                          v-model="newcollection.public"
                          
                          name="public"
                          
                         
                        />
                        <label class="form-check-label" :for="`SwitchCheckSizepub-${newcollection.id}`" 
                          >Public</label
                        >
                    </div>
                     <div
                        class="form-check form-switch form-switch-sm my-auto ml-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          :id="`SwitchCheckSizetrade-${newcollection.id}`"
                          v-model="newcollection.trade"
                     
                          name="trade"
                      
                        />
                        <label class="form-check-label" :for="`SwitchCheckSizetrade-${newcollection.id}`" 
                          >Trade</label
                        >
                    </div>
                    <input type="hidden" :value="newcollection.active" name="active">
                    <input type="hidden" :value="newcollection.public" name="public">
                    <input type="hidden" :value="newcollection.trade" name="trade">
          </b-form-group>
          <div class="d-flex flex-row mt-3">
          <b-button variant="success" class="w-md w-100" @click="addCollection()"> <i class=" bx bx-save"></i> Save</b-button>
          </div>
        </b-form>
      </b-overlay>
        </b-card>
        </div>
      </div>
    </SideSlidePanel> 
      <SideSlidePanel :editPanel="editPanel" :panelClass="addPanelClass" @close-panel="editPanel = !editPanel"  :expandable="1"  @expand="addPanelClass = 'edit-sidepanel-large'" @contract="addPanelClass = 'edit-sidepanel'">
      <div class="row">
        <div class="d-flex flex-column justify-content-center h-100" v-if="($attrs.user.companies[0].userrole.name !== 'Owner') && !$attrs.user.companies[0].permissions.edit.includes($attrs.active_menu.id)" >
          <AccessRestricted :page="false"></AccessRestricted>
        </div>
        <div class="col-md-12" v-else>
        <b-card class="shadow-none">
          <h4 class="my-3">Edit Collection: {{editcollection.name}}</h4>
           <b-overlay
                        id="overlay-background"
                        :show="loadingForm"
                        variant="white"
                        opacity="0.85"
                        blur="1px"
                        rounded="md"
                      >
         <b-form id="updatecollectionform" method="POST" enctype="multipart/form-data">
                   <input type="hidden" name="_token" :value="csrf">
           <b-row>
                     <b-col md="12">
                    <transition name="slide-error" mode="out-in">
                    <b-alert id="errors" variant="danger" :show="error_message"  dismissible @dismissed="error_message = !error_message">
                      <p class="font-size-14">Oops! Please correct the following errors in your submission</p>
                      <ul>
                        <li v-for="(error, key) in error_reponse">
                          {{error[0]}}
                        </li>
                      </ul>
                    </b-alert>
                    
                  </transition>
                  </b-col>
          </b-row>
         <b-form-group label="Name">
            <b-form-input type="text" name="name" v-model="editcollection.name"></b-form-input>
             <input type="hidden" name="slug" :value="sanitizeTitle(editcollection.name)">
          </b-form-group>
           <b-form-group class="mt-2" label="Description">
            <b-textarea rows="6" name="description" v-model="editcollection.description" :editor="editor"></b-textarea>
          </b-form-group>
          <b-form-group class="mt-2" label="Image">
            <input class="form-control mb-2" name="image" type="file">
          </b-form-group>
          <b-form-group class="mt-2" label="Cover Image">
            <input class="form-control mb-2" name="cover_image" type="file">
          </b-form-group>
          <b-form-group class="my-3">
                     <div
                        class="form-check form-switch form-switch-sm mb-0 mt-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          id="newbrand-owner"
                          v-model="editcollection.active" value="true"
                          unchecked-value="false"
                          name="active"
                        />
                        <label class="form-check-label" for="newbrand-owner" 
                          > Active</label
                        >
                    </div>
                    <div
                        class="form-check form-switch form-switch-sm my-auto ml-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          :id="`SwitchCheckSizepub-${editcollection.id}`"
                          v-model="editcollection.public"
                          
                          name="public"
                          
                         
                        />
                        <label class="form-check-label" :for="`SwitchCheckSizepub-${editcollection.id}`" 
                          >Public</label
                        >
                    </div>
                     <div
                        class="form-check form-switch form-switch-sm my-auto ml-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          :id="`SwitchCheckSizetrade-${editcollection.id}`"
                          v-model="editcollection.trade"
                     
                          name="trade"
                      
                        />
                        <label class="form-check-label" :for="`SwitchCheckSizetrade-${editcollection.id}`" 
                          >Trade</label
                        >
                    </div>
                    <input type="hidden" :value="editcollection.active" name="active">
                    <input type="hidden" :value="editcollection.public" name="public">
                    <input type="hidden" :value="editcollection.trade" name="trade">
          </b-form-group>

         
                     
          <div class="d-flex flex-row mt-3">
          <b-button variant="success" class="w-md w-100" @click="updateCollection()"> <i class=" bx bx-save"></i> Save</b-button>
          </div>
        </b-form>
      </b-overlay>
        </b-card>
        </div>
      </div>
    </SideSlidePanel> 

</Layout>
</template>