<script type="text/javascript">
import FilterWebsiteProductCategoriesChilds from "./FilterWebsiteProductCategoriesChilds";
import { EventBus } from '../../../../app'
     export default{
        name: 'FilterWebsiteProductCategories',
        components: {
            FilterWebsiteProductCategoriesChilds
        },
        props: {
            childs: Array,
            productcategories: Array,
            productid: Number,
            selectedcids: Array,
            category_data: {
                type: Array,
                default: () => [],
            },
            api_token: {
                type: String,
                default: () => '',
            },
            marketplace_categories: {
                type: String,
                default: () => 'company',
            }
        }, 
        beforeCreate(){
        
        //this.all_categories = this.$attrs.all_categories;
        },
        created(){


            if(this.category_data.length == 0){
                if(this.$attrs.liveMode == true){
                     axios.get('/website-api/product/categories&page='+this.currentPage+'&limit='+this.limit+'&sortby='+this.sortby+'&order='+this.order+'&search='+this.search+'&parent_id=null&marketplace_categories='+this.marketplace_categories).then(response => {
                         console.log(response);
                        this.all_categories = response.data;
                    });
                   
                    
                }else{
                    axios.get('/api/company/product/categories?limit=0&sortby=name&order=DESC&search=&parent_id=null&marketplace_categories='+this.marketplace_categories, {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
                    }}).then(response => {
                         console.log(response);
                        this.all_categories = response.data;
                    });
                }
                //this.clearCategories();
            }else{
                this.all_categories = this.category_data;
            }
        },
        mounted(){
            
               
        },
        data: function() {
        return{
            all_categories: [],
            //product_categories: this.productcategories,
            activeIds: [],
            testObject:[],
            selectedchildids: [],
            selectedids: [],
            childitems: '',
            success: '',
            product_id: this.productid,
            showFilters: false,
            selectallbtn: 1,
            search: '',
            currentPage: 1,
            order: 'desc',
            sortby: 'id',
            categories: [],
            isCheckAll: false,
        }
        },
        methods:{
            addToCategory(id){
                /*if(this.selectedids.length == 0){
                    this.selectedids[0] = id;
                }else{
                this.selectedids.push(id);
                }*/
                this.$emit('category-added', id);
            },
            categorySelected(id){
                //this.selectedids = id;

                EventBus.$emit('category-select', id);
                /*var exists = this.categories.findIndex(item => item == id);
                  console.log(exists);
                  if(exists == -1){
                    this.categories.push(id);
                  }else{
                    this.categories.splice(exists, 1);
                  }*/
            },
             findIds(id){
                this.testObject = [];
                this.testObject = this.selectedids.filter(item => item === id);
                    if(this.testObject.length){
                        return "checked";
                    }else{
                        return false;
                    }

                },

            clearCategories(){
                this.categories = [];
                //this.$refs['childcategories'].clearCategories();
                EventBus.$emit('categories-cleared');
            },
            filterRecords(){
                this.$emit('filter-records');
            },
            selectAll(){
                this.selectallbtn = 0;
                this.$emit('check-all-categories');
            }
        },
        computed: {
           
        /** checkAll: {
            get: function () {
                return this.all_categories ? this.selectedids.length == this.all_categories.length : false;
            },
            set: function (value) {
                var selected = [];
                if (value) {
                    this.all_categories.forEach(function (data) {
                        selected.push(data.id);
                        this.$emit('category-added', data.id);
                    });
                }else{
                   // this.$emit('categories-cleared');
                }
                this.selectedids = selected;
            }
        }**/
    },

    }
</script>

<template>
    <div>
    <div>
        <ul class="categories-tree mb-0 pl-0 py-0">
            <li v-for="category in all_categories" v-if="category.parent_id == null">
                <div class="d-flex my-2">
                <b-form-checkbox-group v-model="categories"  @change="categorySelected(category.id)">
                    <b-form-checkbox :name="'category-'+category.id"
                    :key="category.id"
                    :value="category.id"   class="category-checkbox" :id="category.id">
                        <img v-if="category.image" :src="category.image" class="avatar-xs rounded-circle opacity-75 mr-2">
                        <img v-else class="avatar-xs rounded-circle opacity-75 mr-2" src="/images/product-placeholder.png">
                        <span class="my-auto">{{category.name}}</span>
                    </b-form-checkbox>
                </b-form-checkbox-group>
                    <a v-if="category.childs.length > 0" class="btn btn-outline-light btn-rounded ml-auto my-auto btn-sm" v-b-toggle="'subcategories-'+category.id" >
                        <i class="mdi mdi-plus hide-on-collapse"></i>
                        <i class="mdi mdi-minus show-on-collapse"></i>
                    </a>
                </div>
                 <b-collapse :id="'subcategories-'+category.id">
                    <FilterWebsiteProductCategoriesChilds ref="childcategories" v-if="category.childs.length > 0" :childs="category.childs" ></FilterWebsiteProductCategoriesChilds>
                </b-collapse>
            </li>
        </ul>

    <!--<div class="row"><div class="col-md-12"><h5>Active Categories</h5></div><div class="col-md-12"><ul class="list-group list-group-flush"><li class="list-group-item" v-for="selected in productcategories">{{selected.name}}</li></ul></div></div>-->
                <!--<b-form-checkbox v-model="checkAll" v-if="selectallbtn == 1" class="float-left" @click="selectAll()">Select All</b-form-checkbox>-->
         
</div>
 <!--<a href="javascript:;" class="float-right" @click="clearCategories()">Clear</a>-->
 </div>
</template>