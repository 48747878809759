<script type="text/javascript">
	export default{
		data(){
			return{

			}
		},
		props: {
			company: {
				type: Object
			}
		}
	}
</script>
<template>
	<b-container class="py-4">
		<b-row class="d-flex flex-column h-100 my-4 py-4">
			<b-col>
				<b-card class="border-radius-1em shadow-none border py-4 text-center">
					<img src="/images/404.png" class="img-fluid w-50">
					<h1>404 - Page Does Not Exist</h1>
					<p>Sorry the page you're looking for doesn't exist</p>
				</b-card>
			</b-col>
		</b-row>
	</b-container>
</template>