<template>
<b-card class="border-radius-1em border shadow-none" body-class="p-0">
          <h5 class="m-3 text-center">Add Menu Item</h5>
          <b-list-group flush>
            <b-list-group-item  >
              <div class="d-flex w-100" v-b-toggle.accordion-pages><i class="bx bx-notepad mr-2 my-auto"></i><span class="my-auto">Pages</span> <i class="bx bx-chevron-down ml-auto my-auto"></i></div>
               <b-collapse id="accordion-pages" accordion="my-accordion" role="tabpanel">

                <b-card class="p-0 shadow-none mt-2 pt-2 mb-2" body-class="p-0 border border-radius-1em">
                <simplebar style="max-height: 200px;">
                  <b-list-group flush>
                    <draggable tag="b-list-group-item" v-for="page in pages.data" class="d-flex" handle=".phandle" @end="addResource('page', page)">
                      <a href="javascript:;" class="phandle my-auto mr-3">
                      <i class="bx bx-move font-size-20"></i>
                    </a>
                      <span class="my-auto mr-auto">{{page.name}}</span>
                      <a class="my-auto btn btn-light rounded-circle btn-rounded" @click="addResource('page', page)">
                        <i class="mdi mdi-plus"></i>
                      </a>
                    </draggable>
                   
                  </b-list-group>
                </simplebar>

                      <a href="/web/page?view=add" class="btn btn-light btn-block" target="_blank">
                        Add New Page <i class="mdi mdi-plus"></i>
                      </a>
                  
                </b-card>
               </b-collapse>
            </b-list-group-item>
            <b-list-group-item>
              <div class="d-flex w-100" v-b-toggle.accordion-categories><i class="bx bx bx-grid-small mr-2 my-auto"></i><span class="my-auto">Categories</span> <i class="bx bx-chevron-down ml-auto my-auto float-right"></i></div>
              <b-collapse id="accordion-categories" accordion="my-accordion" role="tabpanel">
                <b-card class="p-0 shadow-none mt-2 pt-2 mb-2" body-class="p-2 border border-radius-1em">
                  <!--<b-form-checkbox-group>
                    <b-form-checkbox id="all">Category Grid/List</b-form-checkbox>
                    <b-form-checbox v-for="category in categoriesdata" :id="category.id">
                      {{category.name}}
                    </b-form-checbox>
                  </b-form-checkbox-group>-->
                   <simplebar style="max-height: 200px;">
                  <b-list-group flush>
                    <draggable tag="b-list-group-item" v-for="category in categoriesdata" class="d-flex" handle=".chandle" @end="addResource('category', category)">
                    <a href="javascript:;" class="chandle my-auto mr-3">
                      <i class="bx bx-move font-size-20"></i>
                    </a>
                      <span class="my-auto mr-auto">{{category.name}}</span>
                      <a class="my-auto btn btn-light rounded-circle btn-rounded" @click="addResource('category', category)">
                        <i class="mdi mdi-plus"></i>
                      </a>
                    </draggable>
                     <draggable tag="b-list-group-item" class="d-flex" handle=".chandle" @end="addResource('categories', {name: 'Categories', id: 0 })">
                    <a href="javascript:;" class="chandle my-auto mr-3">
                      <i class="bx bx-move font-size-20"></i>
                    </a>
                      <span class="my-auto mr-auto">Category List</span>
                      <a class="my-auto btn btn-light rounded-circle btn-rounded" @click="addResource('categories', {name: 'Categories', id: 0 })">
                        <i class="mdi mdi-plus"></i>
                      </a>
                    </draggable>
                  </b-list-group>
                </simplebar>
                </b-card>
               </b-collapse>
            </b-list-group-item>
            <b-list-group-item >
               <div class="d-flex w-100" v-b-toggle.accordion-collections><i class="bx bx bx-grid-small mr-2 my-auto"></i><span class="my-auto">Collections</span> <i class="bx bx-chevron-down ml-auto my-auto float-right"></i></div>
              <b-collapse id="accordion-collections" accordion="my-accordion" role="tabpanel">
                <b-card class="p-0 shadow-none mt-2 pt-2 mb-2" body-class="p-2 border border-radius-1em">
                  <!--<b-form-checkbox-group>
                    <b-form-checbox v-for="collection in collections" :id="collection.id">
                    </b-form-checbox>
                  </b-form-checkbox-group>-->

                  <simplebar style="max-height: 200px;">
                  <b-list-group flush>
                    <draggable tag="b-list-group-item" v-for="collection in collections" class="d-flex" handle=".collectionhandle" @end="addResource('collection', collection)">
                    <a href="javascript:;" class="collectionhandle my-auto mr-3">
                      <i class="bx bx-move font-size-20 chandle"></i>
                    </a>
                      <span class="my-auto mr-auto">{{collection.name}}</span>
                      <a class="my-auto btn btn-light rounded-circle btn-rounded" @click="addResource('collection', collection)">
                        <i class="mdi mdi-plus"></i>
                      </a>
                    </draggable>
                    <draggable tag="b-list-group-item" class="d-flex" handle=".chandle" @end="addResource('collections', {name: 'Collections', id: 0 })">
                    <a href="javascript:;" class="collectionhandle my-auto mr-3" >
                      <i class="bx bx-move font-size-20"></i>
                    </a>
                      <span class="my-auto mr-auto">Collections List</span>
                      <a class="my-auto btn btn-light rounded-circle btn-rounded" @click="addResource('collections', {name: 'Collections', id: 0 })">
                        <i class="mdi mdi-plus"></i>
                      </a>
                    </draggable>
                  </b-list-group>
                </simplebar>
                </b-card>
               </b-collapse>
            </b-list-group-item>
            <b-list-group-item>
              <div class="d-flex w-100" v-b-toggle.accordion-custom><i class="bx bx-link mr-2 my-auto"></i><span class="my-auto">Custom Link</span> <i class="bx bx-chevron-down ml-auto my-auto"></i></div>
              <b-collapse id="accordion-custom" accordion="my-accordion" role="tabpanel">
                <b-card class="p-0 shadow-none mt-2 pt-2 mb-2" body-class="p-2 border border-radius-1em">
                  <b-form-group label="Name" class="mt-2">
                    <b-form-input v-model="menulink.name">
                    </b-form-input>
                  </b-form-group>
                  <b-form-group label="Label" class="mt-2">
                    <b-form-input v-model="menulink.label">
                    </b-form-input>
                  </b-form-group>
                  <b-form-group label="Link" class="mt-2">
                    <b-form-input v-model="menulink.link">
                    </b-form-input>
                  </b-form-group>
                  <a class="my-3 btn btn-primary btn-rounded btn-block" @click="addToMenu('custom')">
                    Add to Menu
                  </a>
                </b-card>
               </b-collapse>
            </b-list-group-item>
          </b-list-group> 
        </b-card>
      </template>
      <script type="text/javascript">
        import draggable from 'vuedraggable';
        import simplebar from "simplebar-vue";
         export default{
          components: {
            simplebar,
            draggable
          },
          data(){
            return {
              headers: '',
              pages: '',
              pagessearch: '',
              pagescurrentPage: 1,
              pageslimit: 25,
              pagesorderDesc: true,
              pagesorder: 'desc',
              pagessortby: 'id',
              collections: [],
              categoriesdata: [],
              menulinkdata: {
                company_web_menu_id: '',
                parent_id: '',
                name: '',
                label: '',
                resource: '',
                menuitems: [],
                company_web_page_id: 0,
                resource_id: '',
                link: '',
              },
              type: '',
            }
          },
          props: {
            action: {
              type: String,
              default: 'add',
            },
            menulink: {
              type: Object,
              default: {
                company_web_menu_id: '',
                parent_id: '',
                name: '',
                label: '',
                resource: '',
                menuitems: [],
                company_web_page_id: 0,
                resource_id: '',
                link: '',
              },
            },
            activeDropdown: {
              type: String,
              default: () => 'pages'
            },
            user: {
              type: Object,
            }
          },
          created(){
            this.headers = {headers : { 'Authorization' : 'Bearer ' +this.user.api_token}};
            this.getCategories();
            this.getCollections();
            if(this.pages == ''){
              this.getPages();
            }
          },
          mounted(){
            this.$root.$emit('bv::toggle::collapse', 'accordion-'+this.activeDropdown);
          },
          methods: {
              addToMenu(type){
                this.menulink.resource = type;
                this.$emit('add-menu-item', this.action, type, this.menulink);
              },
              addResource(type, data){
                this.menulink.resource = type;
                this.menulink.resource_id = data.id;
                this.menulink.name = data.name;
                this.menulink.label = data.name;
                  if(type == 'page'){
                    this.menulink.company_web_page_id = data.id;
                    this.menulink["isHomepage"] = data.id;
                    this.menulink.link = data.url_rewrite;
                  }
                  if(type == 'category'){
                    this.menulink.company_web_page_id = 0;
                    this.menulink["isHomepage"] = 0;
                    this.menulink.link = data.slug;
                  }
                  if(type == 'categories'){
                    this.menulink.company_web_page_id = 0;
                    this.menulink["isHomepage"] = 0;
                    this.menulink.link = 'categories';
                  }
                  if(type == 'collection'){
                    this.menulink.company_web_page_id = 0;
                    this.menulink["isHomepage"] = 0;
                    this.menulink.link = data.slug;
                  }
                  if(type == 'collections'){
                    this.menulink.company_web_page_id = 0;
                    this.menulink["isHomepage"] = 0;
                    this.menulink.link = 'collections';
                  }
                  this.$emit('add-menu-item', this.action, type, this.menulink);
              },
              getCategories(){
                axios.get('/api/company/product/categories?marketplace_categories=company', this.headers).then(response => {
                  this.categoriesdata = response.data;
                }).catch(error => {
                  this.error_reponse = error.response.error[0];
                });
              },
              getCollections(){
                axios.get('/api/company/product/collections?limit=0', this.headers).then(response => {
                  this.collections = response.data;
                }).catch(error => {
                  this.error_reponse = error.response.error[0];
                });
              },
              getPages(){
                axios.get('/api/company/web/pages?page='+this.pagescurrentPage+'&limit='+this.pageslimit+'&sortby='+this.pagessortby+'&order='+this.pagesorder+'&search='+this.pagessearch+'', this.headers).then(response => {
                  this.pages = response.data;
                }).catch(error => {
                  this.error_reponse = error.response.error[0];
                });
              },
            }

         }
      </script>