<script>
	import Multiselect from "vue-multiselect";
	import SideSlidePanel from "../../components/widgets/SideSlidePanel"
	export default{
		components: { Multiselect, SideSlidePanel },
		data(){
			return{
				id: '',
				options: [
					{id: 1, name: '-- No payment terms created! --'},
				],
				search: '',
				currentPage: 1,
				order: 'desc',
      			sortby: 'id',
      			recurring: false,
      			noresults: false, 
      			limit: 0,
				limits: [
		        {value: 25, text: "25"},
		        {value: 50, text: "50"},
		        {value: 100, text: "100"},
		    	],
				response_error: [],
				addNewPanel: false,
			}
		},
		props: {
			isDisabled: {
				type: Boolean,
				default: () => false,
				}
		},
		created(){
			axios.get('/api/company/order/payment-terms?page='+this.currentPage+'&limit='+this.limit+'&sortby='+this.sortby+'&order='+this.order+'&search='+this.search+'', {headers: {
                  'Authorization': 'Bearer ' + this.api_token
              }}).then(response => {
				this.options = response.data.data;
				if(response.data.data.length == 0){
					this.noresults = true;
				}
			}).catch(error => {
				this.response_error = error.data;
			});
			this.id = this.$attrs.id;
			
		},
		methods:{
			updateDepartment: function(selectedOption, id){
				this.$emit('payment-term-selected', selectedOption);
			},
			customLabel ({ title, desc }) {
		      return `${title} – ${desc}`
		    }

		}
	}
</script>
<template>
	<div>
		<div class="d-flex">
	<multiselect v-model="id" track-by="name" label="name" :options="options" @select="updateDepartment" :option-height="104" :disabled="isDisabled" class="flex-grow-5">
                     <!--<template slot="singleLabel" slot-scope="{ option }">{{ option.name }} - {{ option.jobtitle }}</template>-->
                     <template slot="singleLabel" slot-scope="{ option }"><div class="d-flex w-100 h-100"><div class="avatar-xs mr-4 my-auto">
                        <span class="avatar-title rounded-circle"><i :class="option.icon"></i></span>
                      </div>
      <div class="option__desc d-flex flex-row w-75"><span class="option__title ml-2 my-auto w-100">{{ option.name }}</span>
      </div>
  		</div></template>
    <template slot="option" slot-scope="{ option }">
    	<div class="d-flex"><div class="avatar-xs mr-4">
                        <span class="avatar-title rounded-circle"><i :class="option.icon"></i></span>
                      </div>
      <div class="option__desc d-flex flex-row w-50"><span class="option__title ml-2 my-auto w-100">{{ option.name }}</span>
      </div>
  		</div>
    </template>
                  </multiselect>
                  <a variant="light" @click="addNewPanel = !addNewPanel" class="my-auto btn btn-outline-light ml-2 flex-grow-1 h-100" href="javascript:;"><i class="mdi mdi-plus"></i></a>
              </div>
                  <SideSlidePanel :editPanel="addNewPanel" :panelClass="'edit-micropanel'" @close-panel="addNewPanel = !addNewPanel">
                  	<b-row class="px-4">
                  		<b-col>
                  			<h4>Add New Payment Method</h4>
			                    <b-form id="addpaymentterm" method="POST" enctype="multipart/form-data">
			                    	 <b-form-group label="Name"  label-cols-lg="3" label-for="name">
							            <b-form-input id="name" type="text" name="name" ></b-form-input>
							          </b-form-group>
							           <b-form-group class="mt-2" label="Description" label-cols-lg="3" label-for="description">
							            <b-textarea id="description" rows="3" name="description"></b-textarea>
							          </b-form-group>
							    <b-form-group class="mt-3" label="Term Length" label-cols-lg="3" label-for="length">
			          				<b-form-input id="length" type="number" name="length" class="w-50 d-inline-block float-left"></b-form-input>
			          				<b-form-select id="period" name="period" class="w-50 d-inline-block form-control">
			                    	<b-form-select-option value="Day" >
			                    		Day(s)
			                    	</b-form-select-option>
			                    	<b-form-select-option value="Week" >
			                    		Week(s)
			                    	</b-form-select-option>
			                    	<b-form-select-option value="Month" >
			                    		Month(s)
			                    	</b-form-select-option>
			                    	<b-form-select-option value="Quarter" >
			                    		Quarter(s)
			                    	</b-form-select-option>
			                    	<b-form-select-option value="Year" >
			                    		Year(s)
			                    	</b-form-select-option>
			                    </b-form-select>
			                	</b-form-group>
			                	<div
			                        class="form-check form-switch form-switch-sm mb-0 mt-4 ml-4 float-right"
			                        dir="ltr"
			                      >
			                      <input
			                          class="form-check-input"
			                          type="checkbox"
			                          id="newterm-recurring"
			                          value="true"
			                          unchecked-value="false"
			                          v-model="recurring"
			                        />
			                        <label class="form-check-label" for="newterm-recurring" 
			                          > Recurring</label
			                        >
			                    </div>
			                    <input type="hidden" name="recurring" v-model="recurring">
			                	<b-form-group class="mt-3" label="Payments" v-if="recurring"  label-cols-lg="4" label-for="recurringpayments">
			          				<b-form-input id="recurringpayments" type="number" name="name"></b-form-input>
			                	</b-form-group>
			             		<b-button class="w-100 mt-4" variant="success" href="javascript:;">Save</b-button>
			                    </b-form>
			                  </b-col>
			                 </b-row>
                </SideSlidePanel>
   </div>
</template>
