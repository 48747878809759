<script type="text/javascript">

	import Layout from "../../layouts/main";
	import PageHeader from "../../components/page-header";
	
	export default{
		components: {Layout, PageHeader},
		props: {
			page: {
				type: Boolean,
				default: ()=> true, 
			}
		}
	}

</script>

<template>
		        <div class="col-lg-12">
		        	<div class="row justify-content-center">
			        <div class="col-md-8 col-xl-6">
			          <div>
			            <img src="/images/access-denied.png" alt class="img-fluid" />
			          </div>
			        </div>
			      </div>
		          <div class="text-center mb-5">
		            <h3 class="display-4 font-weight-medium">
		              Access <i class="bx bx-lock bx-tada text-primary display-4"></i> Restricted
		            </h3>
		            <h4 class="text-uppercase">Sorry, you don't have permissions to view this <span v-if="page == true">page</span><span v-else>resource</span></h4>
		            <div class="mt-5 text-center" v-if="page == true">
		              <a class="btn btn-primary" href="/">Back to Dashboard</a>
		            </div>
		          </div>
		          
		        </div>

</template>