<script>
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import VueSlideBar from "vue-slide-bar";
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import CompanyProductSearch from '../../components/widgets/CompanyProductSearch';
import CompanyProductList from '../../components/widgets/CompanyProductList';
import SideSlidePanel from "../../components/widgets/SideSlidePanel";

export default{
	components:{VueSlideBar, Layout, PageHeader, SideSlidePanel, ckeditor: CKEditor.component, CompanyProductSearch, CompanyProductList},
	data() {
    	return {
          csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
          editor: ClassicEditor,
    		  brandsData: [],
    		  categoryProducts: [],
    		  selectedCategory: [],
    		  brands_error: [],
    		  products_error: [],
          addProductToggle: false,
          error_reponse: [],
          error_message: false,
          success_message: false,
          success_text: '',
          loadingForm: false, 
          loadingList: false,
    		  view: 'grid',
    		  search: '',
    		  limit: '25',
    		  sortby: 'id',
    		  order: 'desc',
    		  selectedids: [],
          newcollection: {
            name: '',
            description: '',
            image: '',
            cover_image: '',
            order: '',
            slug: '',
            active: 0,
          },
          editcollection: {
            name: '',
            description: '',
            image: '',
            cover_image: '',
            order: '',
            slug: '',
            active: 0,
          },
          isCheckAll: false,
          addNewPanel: false,
          editPanel: false,
          addPanelClass: 'edit-sidepanel',
          expandable: 0,
    		  showStoneDetails: 0,
    		  selected_parent: {id: null},
    		  limits: [
		        {value: 25, text: "25"},
		        {value: 50, text: "50"},
		        {value: 100, text: "100"},
		      ],
		      title: "Gemstones",
		      items: [
		        {
		          text: "Educate",
		          href: "javascript:;",
		        },
		        {
		          text: "Gemstones",
		          active: true,
		        },
		      ],
  				}	
	},
	methods:{
		brandQuery(){
			axios.get('/api/company/library/books?api_token='+this.$attrs.user.api_token+'&page='+this.currentPage+'&limit='+this.limit+'&sortby='+this.sortby+'&order='+this.order+'&search='+this.search+'&parent_id='+this.selected_parent.id).then(response => {
				this.brandsData = response.data;
			}).catch(error => {
				this.brands_error = error.data;
			});
		},
		getProducts(category_id){
      this.categoryProducts = [];
      this.loadingForm = false;
			axios.get('/api/company/product/collection/'+category_id+'/products?api_token='+this.$attrs.user.api_token).then(response => {
        this.categoryProducts = response.data;
         let self = this;
          setTimeout(function(){
            self.loadingForm = false;
          }, 500);
      }).catch(error => {
        this.products_error = error.data;
      });
		},
    addCollection(){
      this.loadingForm = true;
      var postForm = new FormData(document.getElementById("addcollectionform"));
      axios.post('/api/company/product/collection/create?api_token='+this.$attrs.user.api_token+'', postForm).then(response => {
        console.log(response);
        this.success_text = 'Congratulations, your new category has been added.';
        this.success_message = true;
        //this.resetProjectData();
        //this.addPanel = false;
        let self = this;
          setTimeout(function(){
            self.loadingForm = false;
            self.addNewPanel = false;
            self.brandQuery();
          }, 1500);
      }).catch( error => {
        
        this.error_reponse = error.response.data.errors;
        this.error_message = true;
        //this.resetProjectData();
        let self = this;
          setTimeout(function(){
            self.loadingForm = false;
          }, 1500);
      });
    },
    updateCollection(){
      this.success_message = false;
      this.error_message = false;
      this.loadingForm = true;
      var postForm = new FormData(document.getElementById("updatecollectionform"));
      axios.post('/api/company/product/collection/'+this.editcollection.id+'/update?api_token='+this.$attrs.user.api_token+'', postForm).then(response => {
        console.log(response);
        this.success_text = 'Congratulations, your category has been updated.';
        this.success_message = true;
        //this.resetProjectData();
        this.addPanel = false;
        let self = this;
          setTimeout(function(){
            self.loadingForm = false;
            self.editPanel = false;
            self.brandQuery();
          }, 500);
      }).catch( error => {
        
        this.error_reponse = error.response.data.errors;
        this.error_message = true;
        //this.resetProjectData();
        let self = this;
          setTimeout(function(){
            self.loadingForm = false;
          }, 500);
      });

    },
    showStatusMessage(result){
      if(result == false){
        this.error_reponse = {error: ["Product Already Exists in Category"]};
        this.error_message = true;
      }else{
        this.success_message = true;
        this.success_text = 'Congratulations, your action has been saved.';
        this.getProducts(this.selectedCategory.id)
      }
    },
		priceIncVat(price, tax_percentage){
			var tax = 1 + (tax_percentage / 100);
			return price * tax;
		},
    filterSelected(id){
        if(this.selectedids.length > 0){
          var exists = this.selectedids.filter(item => item == id);
          if(exists.length > 0){
            return true;
          }else{
            return false
          }
        }else{
          return false;
        }
      },
      checkAll: function(){ 
                 this.isCheckAll = !this.isCheckAll; 
                 this.selectedids = []; 
                 if(this.isCheckAll){ 
                     // Check all 
                     for (var key in this.brandsData.data) {          
                        this.selectedids.push(this.brandsData.data[key].id); 
                     } 
                 } 
              }, 
      updateCheckall: function(){ 
                 if(this.selectedids.length == this.brandsData.data.length){
                    this.isCheckAll = true; 
                 }else{ 
                    this.isCheckAll = false; 
                 } 
      },
      sanitizeTitle: function(title) {
            var nickname = "";
            // Change to lower case
            var titleLower = title.toLowerCase();
            // Letter "e"
            nickname = titleLower.replace(/e|é|è|ẽ|ẻ|ẹ|ê|ế|ề|ễ|ể|ệ/gi, 'e');
            // Letter "a"
            nickname = nickname.replace(/a|á|à|ã|ả|ạ|ă|ắ|ằ|ẵ|ẳ|ặ|â|ấ|ầ|ẫ|ẩ|ậ/gi, 'a');
            // Letter "o"
            nickname = nickname.replace(/o|ó|ò|õ|ỏ|ọ|ô|ố|ồ|ỗ|ổ|ộ|ơ|ớ|ờ|ỡ|ở|ợ/gi, 'o');
            // Letter "u"
            nickname = nickname.replace(/u|ú|ù|ũ|ủ|ụ|ư|ứ|ừ|ữ|ử|ự/gi, 'u');
            // Letter "d"
            nickname = nickname.replace(/đ/gi, 'd');
            // Trim the last whitespace
            nickname = nickname.replace(/\s*$/g, '');
            // Change whitespace to "-"
            nickname = nickname.replace(/\s+/g, '-');
            // Remove quotations
            nickname = nickname.replace(/["']/g, "");
            // Remove symbols
            nickname = nickname.replace(/@|#|!/g, '');
            return nickname;
        }
	},
	created(){
		this.brandQuery();
  }
}
</script>

<template>
	<Layout>
		<PageHeader :title="title" :items="items" />
		    <div class="row">
          <b-col md="12">
                    <transition name="slide-success" mode="out-in">
                  <b-alert id="success-message" variant="success" :show="success_message"  dismissible @dismissed="success_message = !success_message">
                      <p class="font-size-14">{{success_text}}</p>
                    </b-alert>
                  </transition>
                  </b-col>
      <div class="col-md-12">
 <div class="row mb-3">
          <div class="col-xl-1 col-sm-6">
            <div class="mt-2">
              <b-form-select class="form-control" v-model="limit" :options="limits" @input="brandQuery()"></b-form-select>
            </div>
          </div>
         
          <div class="col-lg-2 col-sm-6 d-flex">
          <form class="my-auto">
            <small class="mx-auto my-2 text-center d-block">Results {{brandsData.from}} - {{brandsData.to}} of {{brandsData.total}}</small>
          </form>
        </div>
        <div class="col-lg-3">
          <b-form-group class="my-auto ml-auto mr-3">
            <b-form-radio-group id="btn-radios-1" button-variant="outline-light"
        size="md" v-model="marketplace_brands" :options="quickfilter_options" buttons @input="brandQuery()"></b-form-radio-group>
      
          
          </b-form-group>
        </div>
        <div class="col-lg-3 col-sm-6 d-flex">
          
            <div class="mt-sm-0 form-inline w-100" >
              <div class="search-box mr-2 ml-auto">
                <div class="position-relative">
                  <input
                    type="text"
                    class="form-control w-100 border-0"
                    placeholder="Search name, reference or description..."
                    v-model="search"
                    @keyup.enter="brandQuery()"
                  />
                  <i class="bx bx-search-alt search-icon"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-sm-6 d-flex">
            <div class="d-flex ml-auto">
              <ul class="nav nav-pills product-view-nav">
                
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': view == 'grid' }" href="javascript: void(0);" @click="view = 'grid'">
                    <i class="bx bx-grid-alt"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': view == 'list' }" href="javascript: void(0);" @click="view = 'list'">
                    <i class="bx bx-list-ul"></i>
                  </a>
                </li>
                <li class="nav-item">
                 <b-dropdown text="Bulk Actions" variant="primary" class="rounded">
                  <b-dropdown-item href="javascript: void(0);">Action</b-dropdown-item>
                  <b-dropdown-item href="javascript: void(0);">Another action</b-dropdown-item>
                  <b-dropdown-item href="javascript: void(0);">Something else here</b-dropdown-item>
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-item href="javascript: void(0);">Separated link</b-dropdown-item>
                </b-dropdown>
                </li>
                <li class="nav-item">
                 <a
                    class="btn btn-success btn-rounded mb-2 mr-2 text-white"
                    href="javascript:;"
                    @click="addNewPanel = !addNewPanel"
                  >
                    <i class="mdi mdi-plus mr-1"></i> New
                  </a>
                </li>
              </ul>
              <div class="text-sm-right">
                  
                </div>
          </div>
        </div>
      </div>
        <div class="card" v-if="view == 'list'" >
          <div class="card-body p-0">
            <div class="table-responsive">
              <table class="table table-centered table-hover">

                <thead class="thead-light">
                  <tr>
                    <th scope="col"  style="width: 100px" class="text-center"><div
                        class="form-check form-switch form-switch-sm mb-0 mx-auto p-0 mt-auto d-flex"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input mx-auto my-auto" 
                          type="checkbox"
                          id="SwitchCheckSizesm"
                         
                          v-model="isCheckAll"
                          @click='checkAll()'
                          name="active"
                        />
                    </div></th>
                    <th scope="col"></th>
                    <th scope="col"><a :class="{'text-dark': sortby != 'name'}" href="javascript: void(0);" @click="orderDesc = !orderDesc,  sortby = 'name', brandQuery()">Name & Description <i v-if="orderDesc == true" class="bx bx-caret-up"></i><i v-if="orderDesc == false" class="bx bx-caret-down"></i></a></th>
                    
                    <th scope="col">Products</th>
                    <th scope="col">Status</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
               
                <tbody>
                  
                  <tr v-for="list in brandsData.data" :key="list.id" valign="middle">
                    <td>
                       <div class="text-center" v-if="list.image">
                      <b-form-checkbox class="select-row-btn" v-model="selectedids" :value="list.id" button-variant="primary" @input="updateSelected" button>
                        <i v-if="filterSelected(list.id)" class="bx bx-check"></i>
                        <img v-else :src="`${list.image}`" alt class="avatar-sm rounded-circle" />
                      </b-form-checkbox>
                    </div>
                    <div class="text-center" v-else >
                        <b-form-checkbox class="select-row-btn" v-model="selectedids" :value="list.id" button-variant="primary" @input="updateSelected" button>

                          <i v-if="filterSelected(list.id)" class="bx bx-check"></i><span class="avatar-title rounded-circle" v-else>{{(list.name).slice(0,1)}}</span></b-form-checkbox>
                    </div> 
                    </td>
                    <td>
                      <div v-if="list.font" class="avatar-xs">
                        <span class="avatar-title rounded-circle">{{list.font}}</span>
                      </div>
                      <div v-if="list.logo">
                        <img class="rounded-circle avatar-lg" :src="`/public/storage/${list.logo}`" alt />
                      </div>
                    </td>
                    <td>
                        <h5 class="m-0">{{list.name}}</h5>
                     <small class="text-muted mb-0" v-if="list.description != null" v-html="(list.description).slice(0, 200)+'...'"></small>
                    </td>
                    <td><a href="javascript:;" @click="selectedCategory = list, getProducts(list.id), showStoneDetails = 1" class="btn btn-outline-light btn-rounded"><i class="bx bx bx-purchase-tag-alt"></i></a>
                    </td>
                    <td>
                      <div
                        class="form-check form-switch form-switch-sm my-auto ml-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          :id="`SwitchCheckSize-${list.id}`"
                          v-model="list.active"
                          value="1"
                          unchecked-value="0"
                          name="active"
                        />
                        <label class="form-check-label" :for="`SwitchCheckSize-${list.id}`" 
                          >Active</label
                        >
                    </div>
                    </td>
                    <td>
                      <ul class="list-inline font-size-20 contact-links mb-0">
                        <li class="list-inline-item px-2"><a  @click="editcollection = list, editPanel = true" v-b-tooltip.hover title="Edit" placement="left"><i class="bx bx-edit-alt"></i>
                          </a>
                        </li>
                        <li class="list-inline-item px-2">
                           <b-dropdown v-b-tooltip.hover title="More Options" placement="left"dropleft class="more-options-dropdown">
			                  <template v-slot:button-content>
			                     <i class="bx bx-dots-horizontal-rounded"></i>
			                  </template>
			                  <b-dropdown-item>Action</b-dropdown-item>
			                  <b-dropdown-item>Another Action</b-dropdown-item>
			                  <b-dropdown-item>Something else here</b-dropdown-item>
			                </b-dropdown>
                        </li>
                      </ul>
                    </td>
                  </tr>
              	</tbody>
              </table>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <b-pagination
              v-if="brandsData.data.length > 0"
              class="justify-content-center"
              pills
              v-model="currentPage"
              :total-rows="brandsData.total"
              :per-page="brandsData.per_page"
              aria-controls="my-table"
              @input="brandQuery"
            ></b-pagination>
             <small class="mx-auto my-2 text-center d-block">Records {{brandsData.from}} - {{brandsData.to}} of {{brandsData.total}} // Page {{brandsData.current_page}} of {{brandsData.last_page}}</small>
              </div>
            </div>
          </div>
      </div>
    <div class="row" v-if="view == 'grid'" >
      <div class="col-xl-4"  v-for="list in brandsData.data" :key="list.id">
        <div class="card overflow-hidden">
          <div class="bg-soft-primary min-h-100">
            <div class="row">
              
              <div class="col-12 align-self-end">
                <div class="row">
                 <div class="col-md-12 position-absolute"> 
                
                <a id="tooltip-add"  @click="selectedCategory = list, showStoneDetails = 1"class="mt-3 mr-3 btn btn-primary font-size-16 btn-rounded ml-auto float-right"><i class="mdi mdi-eye-outline"></i></a>
              </div>
              </div>
                <img v-if="list.cover_image" :src="`${list.cover_image}`" alt class="img-fluid" />
                <img v-else src="/images/banner-placeholder.png" alt class="img-fluid" />
              </div>
            </div>
          </div>
          <div class="card-body pt-0">
            <div class="row">
              <div class="col-sm-12 text-center pt-3 px-4 mb-2">
               <!-- <div class="avatar-xl profile-user-wid mb-4">
                  <img class="img-thumbnail rounded-circle h-100" :src="`/public/storage/${list.image}`" alt />
                </div>-->
                <h4 class="font-size-18 text-truncate mt-3">{{list.name}}</h4>
                <p class="text-muted mb-0 text-truncate" v-html="list.description"></p>
              </div>

              <div class="col-sm-12">
              
              </div>
            </div>
          </div>
           <b-list-group flush>
                <b-list-group-item class="d-flex">
                  <i class="bx bx-user my-auto mr-2 text-primary bg-light rounded-circle p-2 font-size-14"></i> <span class="my-auto">Author</span> <span class="ml-auto" v-if="list.authors" v-for="item in list.authors">{{item.author.name}}</span>
                </b-list-group-item>
                <b-list-group-item  class="d-flex">
                 <i class="bx bx-book-open my-auto mr-2 text-primary bg-light rounded-circle p-2 font-size-14"></i> <span class="my-auto">Publisher</span>
                </b-list-group-item>
                <b-list-group-item  class="d-flex">
                  <i class="bx bx-calendar-alt my-auto mr-2 text-primary bg-light rounded-circle p-2 font-size-14"></i> <span class="my-auto">Year</span>
                </b-list-group-item>
                <b-list-group-item  class="d-flex">
                 <i class="bx bx-copy my-auto mr-2 text-primary bg-light rounded-circle p-2 font-size-14"></i> <span class="my-auto">Pages</span> <span class="text-muted ml-auto">{{list.formula}}</span>
                </b-list-group-item>
                <b-list-group-item  class="d-flex">
                 <i class="bx bx-barcode my-auto mr-2 text-primary bg-light rounded-circle p-2 font-size-14"></i> <span class="my-auto">ISBN</span> <span class="text-muted ml-auto">{{list.formula}}</span>
                </b-list-group-item>
                </b-list-group>
        </div>
    </div>
    	</div>
    </div>
</div>
	<SideSlidePanel :editPanel="showStoneDetails" :panelClass="'edit-sidepanel'" @close-panel="showStoneDetails = 0">
		<div class="row">
				<div class="col-md-12" v-if="selectedCategory.cover_image" :style="{'background-image': selectedCategory.cover_image}" style="background-size: 75%; min-height: 500px; background-position: center;" >
        </div>
        <div class="col-md-12" v-else style="background: url('/images/product-placeholder.png'); background-size: 75%; min-height: 500px; background-position: center;" >
        </div>
				<div class="col-md-12 pt-3">
				<b-card class="shadow-none">
        <div class="d-flex flex-column mb-3 mx-3">
					<h3 class="my-auto mr-auto">{{selectedCategory.name}}</h3>
          <b-list-group flush class="mt-3">
                <b-list-group-item class="d-flex px-0">
                  <i class="bx bx-user my-auto mr-2 text-primary bg-light rounded-circle p-2 font-size-14"></i> <span class="my-auto">Author(s)</span> <span class="ml-auto" v-if="selectedCategory.authors" v-for="item in selectedCategory.authors">{{item.author.name}}</span>
                </b-list-group-item>
                <b-list-group-item class="d-flex flex-column px-0">
                  <div class="d-flex">
                  <i class="bx bx-user my-auto mr-2 text-primary bg-light rounded-circle p-2 font-size-14"></i> <span class="my-auto">Description</span>
                  </div> <p class="pt-3 mb-0" v-html="selectedCategory.description"></p>
                </b-list-group-item>
                <b-list-group-item  class="d-flex px-0">
                 <i class="bx bx-book-open my-auto mr-2 text-primary bg-light rounded-circle p-2 font-size-14"></i> <span class="my-auto">Publisher</span>
                </b-list-group-item>
                <b-list-group-item  class="d-flex px-0">
                  <i class="bx bx-calendar-alt my-auto mr-2 text-primary bg-light rounded-circle p-2 font-size-14"></i> <span class="my-auto">Year</span>
                </b-list-group-item>
                <b-list-group-item  class="d-flex px-0">
                 <i class="bx bx-copy my-auto mr-2 text-primary bg-light rounded-circle p-2 font-size-14"></i> <span class="my-auto">Pages</span> <span class="text-muted ml-auto">{{selectedCategory.formula}}</span>
                </b-list-group-item>
                <b-list-group-item  class="d-flex px-0">
                 <i class="bx bx-barcode my-auto mr-2 text-primary bg-light rounded-circle p-2 font-size-14"></i> <span class="my-auto">ISBN</span> <span class="text-muted ml-auto">{{selectedCategory.formula}}</span>
                </b-list-group-item>
                </b-list-group>

        </div>
				
				</b-card>
				</div>
		</div>
	</SideSlidePanel>
   <SideSlidePanel :editPanel="addNewPanel" :panelClass="addPanelClass" @close-panel="addNewPanel = !addNewPanel" :expandable="1"  @expand="addPanelClass = 'edit-sidepanel-large'" @contract="addPanelClass = 'edit-sidepanel'">
      <div class="row">
        
        <div class="col-md-12">
        <b-card class="shadow-none">
          <h4 class="my-3">Add New Collection</h4>
          <b-overlay
                        id="overlay-background"
                        :show="loadingForm"
                        variant="white"
                        opacity="0.85"
                        blur="1px"
                        rounded="md"
                      >
         <b-form id="addcollectionform" method="POST" enctype="multipart/form-data">
                   <input type="hidden" name="_token" :value="csrf">
           <b-row>
                     <b-col md="12">
                    <transition name="slide-error" mode="out-in">
                    <b-alert id="errors" variant="danger" :show="error_message"  dismissible @dismissed="error_message = !error_message">
                      <p class="font-size-14">Oops! Please correct the following errors in your submission</p>
                      <ul>
                        <li v-for="(error, key) in error_reponse">
                          {{error[0]}}
                        </li>
                      </ul>
                    </b-alert>
                    
                  </transition>
                  </b-col>
          </b-row>
               <b-form-group label="Name">
            <b-form-input type="text" name="name" v-model="newcollection.name"></b-form-input>
            <input type="hidden" name="slug" :value="sanitizeTitle(newcollection.name)">
          </b-form-group>
           <b-form-group class="mt-2" label="Description">
            <b-textarea rows="6" v-model="newcollection.description" name="description"></b-textarea>
          </b-form-group>
          <b-form-group class="mt-2" label="Image">
            <input class="form-control mb-2" name="image" type="file">
          </b-form-group>
          <b-form-group class="mt-2" label="Cover Image">
            <input class="form-control mb-2" name="cover_image" type="file">
          </b-form-group>
          <b-form-group class="my-3">
                     <div
                        class="form-check form-switch form-switch-sm mb-0 mt-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          id="newbrand-owner"
                          v-model="newcollection.active" value="true"
                          unchecked-value="false"
                          name="active"
                        />
                        <label class="form-check-label" for="newbrand-owner" 
                          > Active</label
                        >
                    </div>
                    <input type="hidden" :value="newcollection.active" name="active">
          </b-form-group>
          <div class="d-flex flex-row mt-3">
          <b-button variant="success" class="w-md w-100" @click="addCollection()"> <i class=" bx bx-save"></i> Save</b-button>
          </div>
        </b-form>
      </b-overlay>
        </b-card>
        </div>
      </div>
    </SideSlidePanel> 
      <SideSlidePanel :editPanel="editPanel" :panelClass="addPanelClass" @close-panel="editPanel = !editPanel"  :expandable="1"  @expand="addPanelClass = 'edit-sidepanel-large'" @contract="addPanelClass = 'edit-sidepanel'">
      <div class="row">
        
        <div class="col-md-12">
        <b-card class="shadow-none">
          <h4 class="my-3">Edit Collection: {{editcollection.name}}</h4>
           <b-overlay
                        id="overlay-background"
                        :show="loadingForm"
                        variant="white"
                        opacity="0.85"
                        blur="1px"
                        rounded="md"
                      >
         <b-form id="updatecollectionform" method="POST" enctype="multipart/form-data">
                   <input type="hidden" name="_token" :value="csrf">
           <b-row>
                     <b-col md="12">
                    <transition name="slide-error" mode="out-in">
                    <b-alert id="errors" variant="danger" :show="error_message"  dismissible @dismissed="error_message = !error_message">
                      <p class="font-size-14">Oops! Please correct the following errors in your submission</p>
                      <ul>
                        <li v-for="(error, key) in error_reponse">
                          {{error[0]}}
                        </li>
                      </ul>
                    </b-alert>
                    
                  </transition>
                  </b-col>
          </b-row>
         <b-form-group label="Name">
            <b-form-input type="text" name="name" v-model="editcollection.name"></b-form-input>
             <input type="hidden" name="slug" :value="sanitizeTitle(editcollection.name)">
          </b-form-group>
           <b-form-group class="mt-2" label="Description">
            <b-textarea rows="6" name="description" v-model="editcollection.description" :editor="editor"></b-textarea>
          </b-form-group>
          <b-form-group class="mt-2" label="Image">
            <input class="form-control mb-2" name="image" type="file">
          </b-form-group>
          <b-form-group class="mt-2" label="Cover Image">
            <input class="form-control mb-2" name="cover_image" type="file">
          </b-form-group>
          <b-form-group class="my-3">
                     <div
                        class="form-check form-switch form-switch-sm mb-0 mt-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          id="newbrand-owner"
                          v-model="editcollection.active" value="true"
                          unchecked-value="false"
                          name="active"
                        />
                        <label class="form-check-label" for="newbrand-owner" 
                          > Active</label
                        >
                    </div>
                    <input type="hidden" :value="editcollection.active" name="active">
          </b-form-group>
          <div class="d-flex flex-row mt-3">
          <b-button variant="success" class="w-md w-100" @click="updateCollection()"> <i class=" bx bx-save"></i> Save</b-button>
          </div>
        </b-form>
      </b-overlay>
        </b-card>
        </div>
      </div>
    </SideSlidePanel> 

</Layout>
</template>