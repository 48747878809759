<script type="text/javascript">
	export default{
		props: {
			id: '',
		},
		data(){
			return {

			}
		},
		methods:{
			filterStylesKey(styles, key){
	            for(var i = 0; i < styles.length; i++){
	               var keys = Object.keys(styles[i])
	               if(keys.includes(key)){
	                 return i;
	               }
	            }
	        },
			compileElementStyles(fieldname, types){
			var style = '';
				if(types.includes('background') == true){
					if(fieldname !== 'column'){
				  		style += 'background-color:'+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname+'_colour')][fieldname+'_colour'].value+' !important;'
				  	}else{
				  		style += 'background-color:'+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields, 'background_colour')].background_colour.value+' !important;'
				  	}
				}
				if(types.includes('color') == true){
					if(fieldname !== 'column'){
						style += 'color:'+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname+'_text_colour')][fieldname+'_text_colour'].value+' !important;'
					}else{
						style += 'color:'+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields, 'text_colour')].text_colour.value+' !important;'
					}
				}
				if(types.includes('border') == true){
					if(fieldname !== 'column'){
						style +=  'border-color: '+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname+'_border_colour')][fieldname+'_border_colour'].value+' !important; border-style:'+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname+'_border_style')][fieldname+'_border_style'].value+' !important;';
						style += ' border-radius: '+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname+'_border_radius')][fieldname+'_border_radius'].top_left+' '+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname+'_border_radius')][fieldname+'_border_radius'].top_right+' '+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname+'_border_radius')][fieldname+'_border_radius'].bottom_right+' '+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname+'_border_radius')][fieldname+'_border_radius'].bottom_left+' !important;';
						style += ' border-left: '+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname+'_border')][fieldname+'_border'].left+'px !important; border-right: '+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname+'_border')][fieldname+'_border'].right+'px !important; border-top: '+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname+'_border')][fieldname+'_border'].top+'px !important; border-bottom: '+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname+'_border')][fieldname+'_border'].bottom+'px !important;'
					}else{
						style +=  'border-color: '+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields, 'border_colour')].border_colour.value+' !important;'

						style += 'border-style:'+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields, 'border_style')].border_style.value+' !important;'

						style += 'border-radius:'+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields, 'border_radius')].border_radius.top_left+' '+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields, 'border_radius')].border_radius.top_right+' '+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields, 'border_radius')].border_radius.bottom_right+' '+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields, 'border_radius')].border_radius.bottom_left+' !important;'
						style += 'border-left:'+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields, 'border')].border.left+'px !important; border-right: '+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields, 'border')].border.right+'px !important; border-top: '+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields, 'border')].border.top+'px !important; border-bottom: '+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields, 'border')].border.bottom+'px !important;'
					}

				}
				if(types.includes('margin') == true){
					if(fieldname !== 'column'){
					style +=  'margin-left:'+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname+'_margin')][fieldname+'_margin'].left !== '' ? this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname+'_margin')][fieldname+'_margin'].left +'px !important;' : 'auto !important';
					style +=  'margin-right: '+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname+'_margin')][fieldname+'_margin'].right !== '' ? this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname+'_margin')][fieldname+'_margin'].right+'px !important;' : 'auto !important';
					style +=  'margin-top: '+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname+'_margin')][fieldname+'_margin'].top !== '' ? this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname+'_margin')][fieldname+'_margin'].top+'px !important;' : 'auto !important';
					style +=  'margin-bottom: '+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname+'_margin')][fieldname+'_margin'].bottom !== '' ? this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname+'_margin')][fieldname+'_margin'].bottom+'px !important;' : 'auto !important';
					}else{
					style +=  'margin-left:'+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields, 'margin')]['margin'].left+'px !important;'
					style +=  'margin-right: '+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields, 'margin')]['margin'].right+'px !important;'
					style +=  'margin-top: '+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields, 'margin')]['margin'].top+'px !important;'
					style +=  'margin-bottom: '+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields, 'margin')]['margin'].bottom+'px !important;'
					}
				}
				if(types.includes('padding') == true){
					if(fieldname !== 'column'){
					style +=  'padding-left:'+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname+'_padding')][fieldname+'_padding'].left+'px !important;'
					style +=  'padding-right: '+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname+'_padding')][fieldname+'_padding'].right+'px !important;'
					style +=  'padding-top: '+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname+'_padding')][fieldname+'_padding'].top+'px !important;'
					style +=  'padding-bottom: '+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname+'_padding')][fieldname+'_padding'].bottom+'px !important;'
					}else{
						style +=  'padding-left:'+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields, 'padding')]['padding'].left+'px !important;'
					style +=  'padding-right: '+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields, 'padding')]['padding'].right+'px !important;'
					style +=  'padding-top: '+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields, 'padding')]['padding'].top+'px !important;'
					style +=  'padding-bottom: '+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields, 'padding')]['padding'].bottom+'px !important;'
					}
				}
				if(types.includes('size') == true){
					if(fieldname !== 'column'){
					style +=  'height:'+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname+'_height')][fieldname+'_height'].value+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname+'_height')][fieldname+'_height'].units+' !important;';
					style +=  'max-height:'+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname+'_max_height')][fieldname+'_max_height'].value+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname+'_max_height')][fieldname+'_max_height'].units+' !important;';
					style +=  'width:'+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname+'_width')][fieldname+'_width'].value+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname+'_width')][fieldname+'_width'].units+' !important;'
					style +=  'max-width:'+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname+'_max_width')][fieldname+'_max_width'].value+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname+'_max_width')][fieldname+'_max_width'].units+' !important;'
					}else{
					style +=  'height:'+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields, 'height')]['height'].value+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields, 'height')]['height'].units+' !important;';
					style +=  'max-height:'+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields, 'max_height')]['max_height'].value+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields, 'max_height')]['max_height'].units+' !important;';
					style +=  'width:'+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields, 'width')]['width'].value+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields, 'width')]['width'].units+' !important;'
					style +=  'max-width:'+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields, 'max_width')]['max_width'].value+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields, 'max_width')]['max_width'].units+' !important;'
					} 
				}
				
				if(types.includes('text') == true){
				  style += 'font-family: \"'+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields, 'font_family')].font_family.value.family+'\", '+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields, 'font_family')].font_family.value.category+' !important; font-size:'+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields, 'font_size')].font_size.value+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields, 'font_size')].font_size.units+' !important; text-transform: '+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields, 'text_transform')].text_transform.value+'!important;';
				}

				if(types.includes('text_alignment') == true){
					style += 'text-align: '+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname+'_text_align')][fieldname+'_text_align'].value+''
				}

				return style;
			}
		}
	}

</script>

<template>
	
	<div>
		<img src="/images/product-placeholder.png" :style="compileElementStyles('image', ['title', 'text_alignment', 'border', 'margin', 'padding', 'size'])">
	</div>
</template>