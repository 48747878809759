<script type="text/javascript">
  export default{
    data: function(){
      return{
        pavilionDepth: '',
        pavilionAngle: 0.00, 
        diameterDisabled: true,
      }
    },
    methods:{
      calculatePavilionAngle(){
        var variable = (this.pavilionDepth / 50);
        var angleRad = Math.atan(variable);
        var angleDeg = angleRad * 180 / Math.PI;
        this.pavilionAngle = (angleDeg).toFixed(2);
      }
    }
  }
</script>
<template>
  <b-card>
    <div class="row">
      <div class="col-md-12 p-4">
          <h3 class="pb-3">Pavilion Angle Calculator</h3>
          <label>Pavilion Depth (mm)</label>
            <input v-model="pavilionDepth" placeholder="0.00" class="form-control" type="number" @keyup="calculatePavilionAngle"></input>
          <div class="row">
            <div class="col-md-12 py-4 text-center">
              <label>Pavilion Angle</label><p class="result-value font-size-24 text-primary">{{pavilionAngle}} &#xb0;</p>
            </div>
          </div>
        </div>
      </div>
  </b-card>
</template>