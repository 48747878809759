import Vue from "vue";
// Use it to import all Vue file containing this folder as Components
// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

Vue.component("home", require("./home").default);
Vue.component("/", require("./home").default);

Vue.component('pages-404', require('./utility/404').default);

Vue.component("login", require("./account/login").default);
Vue.component("customer-login", require("./account/login").default);
Vue.component("register", require("./account/register").default);
Vue.component("account-profile", require("./account/profile").default);
Vue.component("forgot-password", require("./account/forgot-password").default);
Vue.component("reset-password", require("./account/reset-password").default);

Vue.component("company-create", require("./company/create").default);
Vue.component("company-departments", require("./company/departments").default);
Vue.component("company-department", require("./company/department").default);
Vue.component("company-locations", require("./company/locations").default);
Vue.component("company-teams", require("./company/teams").default);
Vue.component("company-team", require("./company/team").default);
Vue.component("company-users", require("./company/users").default);
Vue.component("company-user", require("./company/user").default);
Vue.component("company-storage", require("./company/storage").default);
Vue.component("company-settings", require("./company/settings").default);
Vue.component("company-subscriptions", require("./company/subscriptions").default);
Vue.component("company-shops", require("./company/shops").default);
Vue.component("company-shop", require("./company/shop").default);
Vue.component("company-reviews", require("./company/reviews").default);

Vue.component("stock-products", require("./stock/products").default);
Vue.component("stock-add", require("./stock/add").default);
Vue.component("stock-product", require("./stock/product").default);
Vue.component("stock-brands", require("./stock/brands").default);
Vue.component("stock-categories", require("./stock/categories").default);
Vue.component("stock-collections", require("./stock/collections").default);
Vue.component("stock-suppliers", require("./stock/suppliers").default);
Vue.component("stock-transfers", require("./stock/transfers").default);

Vue.component("stock-purchases", require("./stock/purchases").default);
Vue.component("stock-reviews", require("./stock/reviews").default);
Vue.component("stock-settings", require("./stock/settings").default);
Vue.component("stock-feature-attributes", require("./stock/feature-attributes").default);
Vue.component("inventory-component", require("./inventory/component").default);
Vue.component("inventory-components", require("./inventory/components").default);
Vue.component("inventory-component-attributes", require("./inventory/component-attributes").default);
Vue.component("inventory-component-types", require("./inventory/component-types").default);
//Vue.component("stock-product-add", require("./stock/product/add").default);
//Vue.component("stock-product-details", require("./stock/product/details").default);

Vue.component("sell-pos", require("./sell/pos").default);
Vue.component("sell-cash-registers", require("./sell/cash-registers").default);
Vue.component("sell-terminals", require("./sell/terminals").default);
Vue.component("sell-printers", require("./sell/printers").default);
Vue.component("sell-displays", require("./sell/displays").default);
Vue.component("sell-display", require("./sell/display").default);


Vue.component("customer-list", require("./customer/list").default);
Vue.component("customer-subscription-lists", require("./customer/subscription-lists").default);
Vue.component("customer-subscription-list", require("./customer/subscription-list").default);
Vue.component("customer-details", require("./customer/details").default);
Vue.component("customer-groups", require("./customer/groups").default);
Vue.component("customer-relationships", require("./customer/relationships").default);

Vue.component("orders", require("./orders").default);
//Vue.component("order", require("./order").default);
Vue.component("order-details", require("./order/details").default);
Vue.component("order-payment", require("./order/payment").default);
Vue.component("order-list", require("./order/list").default);
Vue.component("order-edit", require("./order/edit").default);
Vue.component("order-invoices", require("./order/invoices").default);
Vue.component("order-statuses", require("./order/statuses").default);
Vue.component("order-carts", require("./order/carts").default);
Vue.component("order-tax-rules", require("./order/tax-rules").default);
Vue.component("order-discount-coupons", require("./order/discount-coupons").default);
Vue.component("order-delivery-options", require("./order/delivery-options").default);
Vue.component("order-delivery-methods-create", require("./order/delivery-methods/create").default);
Vue.component("order-payment-methods", require("./order/payment-methods").default);
Vue.component("order-deliveries", require("./order/deliveries").default);

Vue.component("service-list", require("./service/list").default);
//Vue.component("service-add", require("./service/add").default);
Vue.component("service-details", require("./service/details").default);
Vue.component("service-categories", require("./service/categories").default);
Vue.component("service-bookings", require("./service/bookings").default);
Vue.component("service-calendar", require("./service/calendar").default);
Vue.component("service-statuses", require("./service/statuses").default);
Vue.component("service-settings", require("./service/settings").default);


Vue.component("communicate-enquiries", require("./communicate/enquiries").default);
Vue.component("communicate-customer-service", require("./communicate/customer-service").default);

Vue.component("trade-marketplace", require("./trade/marketplace").default);
Vue.component("trade-products", require("./trade/products").default);
Vue.component("trade-category", require("./trade/category").default);
Vue.component("trade-profile", require("./trade/profile").default);
Vue.component("trade-profile1", require("./trade/profile1").default);
Vue.component("trade-order", require("./trade/order").default);
Vue.component("trade-directory", require("./trade/directory").default);
Vue.component("trade-account", require("./trade/account").default);
//Vue.component("trade-orders", require("./trade/orders").default);
//Vue.component("trade-support", require("./trade/support").default);

Vue.component("web-menu", require("./web/menu").default);
Vue.component("web-menus", require("./web/menus").default);
Vue.component("web-page", require("./web/page").default);
Vue.component("web-layout", require("./web/layout").default);
Vue.component("web-layouts", require("./web/layouts").default);
//Vue.component("web-page2", require("./web/page2").default);
Vue.component("web-pages", require("./web/pages").default);
Vue.component("web-themes", require("./web/themes").default);
Vue.component("web-template", require("./web/template").default);
//Vue.component("web-template2", require("./web/template2").default);
Vue.component("web-template-inner", require("./web/template-inner").default);
Vue.component("web-templates-", require("./web/templates").default);
Vue.component("web-domains", require("./web/domains").default);

Vue.component("appraise-appraisal", require("./appraise/appraisal").default);
Vue.component("appraise-appraisals", require("./appraise/appraisals").default);
Vue.component("appraise-stamps", require("./appraise/stamps").default);
Vue.component("appraise-letters", require("./appraise/letters").default);
Vue.component("appraise-notes", require("./appraise/notes").default);
Vue.component("appraise-signatures", require("./appraise/signatures").default);
Vue.component("appraise-report-templates", require("./appraise/report-templates").default);


Vue.component("educate-glossary", require("./educate/glossary").default);
Vue.component("educate-gemstones", require("./educate/gemstones").default);
Vue.component("educate-books", require("./educate/books").default);
Vue.component("educate-journals", require("./educate/journals").default);
Vue.component("educate-charts", require("./educate/charts").default);
Vue.component("educate-calculators", require("./educate/calculators").default);