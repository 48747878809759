<script>
import simplebar from "simplebar-vue";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import DateTime from 'luxon/src/datetime.js';
import Interval from 'luxon/src/interval.js';
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import SideSlidePanel from "../../components/widgets/SideSlidePanel"
import Toolbar from "../../components/widgets/admin/email/EmailToolbar";
import Sidepanel from "../../components/widgets/admin/email/EmailSidepanel";
import EmailViewer from "../../components/widgets/admin/email/EmailViewer";
import Emailer from "../../components/widgets/admin/email/Emailer";

//import { emailData } from "./data-inbox";

/**
 * Email-inbox component
 */
export default {
  components: {
    Layout,
    PageHeader,
    Toolbar,
    Sidepanel,
    EmailViewer,
    SideSlidePanel,
    Emailer,
    DateTime,
    Interval,
    simplebar
  },
  data() {
    return {
      unreadEmails: 0,
      showReply: false,
      emailLoaded: false,
      emailsource: '',
      selectedids: [],
   	  success_message: false,
   	  success_messagetext: '',
      loadEmailViewer: false,
      emailViewerPanel:  false,
      emailViewerData: true,
      emailData: '',
      emailDataRaw: '',
      active_filter: '',
      send_email: {
      	id: '',
        notification_thread_id: '',
        email_to: [],
    		email_cc: [],
    		email_bcc: [],
    		subject: '',
    		message: '',
        replying_to: [],
      },
      send_email_raw: {
        id: '',
        notification_thread_id: '',
        email_to: [],
    		email_cc: [],
    		email_bcc: [],
    		subject: '',
    		message: '',
        replying_to: [],
      },
      paginatedEmailData: '',
      title: "Customer Enquiries",
      items: [
        {
          text: "Customer",
          href: "/",
        },
        {
          text: "Enquiries",
          active: true,
        },
      ],
      // page number
      currentPage: 1,
      // default page size
      perPage: 15,
      emailIds: [],
      // start and end index
      startIndex: 1,
      endIndex: 15,
    };
  },
  computed: {
    rows() {
      	return this.emailData.length;
    },
    unread(){
    	if(this.emailDataRaw.length > 0){
    		return this.emailDataRaw.filter(item => item.read_at == null).length;
    	}else{
    		return 0;
    	}
    }
  },
  created() {
  	this.getEnquiries();
    this.startIndex = 0;
    this.endIndex = this.perPage;

    
  },
  methods: {
    reloadEnquiries(source){
        //this.showEmailList = true;
        this.emailsource = source;
        this.getEnquiries();
        let self = this;
        setTimeout(function(){
          //  self.showEmailList = false;
        }, 1000);
    },
    filterSelected(id){
        if(this.selectedids.length > 0){
          var exists = this.selectedids.filter(item => item == id);
          if(exists.length > 0){
            return true;
          }else{
            return false
          }
        }else{
          return false;
        }
      },
  	getDate(date){
  		return DateTime.fromISO(date).toHTTP();
  	},
  	sendEmail(email){
  		if(email !== this.send_email_raw){
  			this.send_email = email;
  		}else{
  			this.send_email = this.send_email_raw;
  		}
  		this.showReply = true;
   	},
  	filterNotifications(params){
  		this.active_filter = params;
  		if(params !== ''){
  		this.emailData = this.emailDataRaw.filter(item => item.data.source == params);
  		this.paginatedEmailData = this.emailData.slice(
			      0,
			      this.perPage
			    );
  		}else{
  			this.emailData = this.emailDataRaw;
  			this.onPageChange();
  		}
  	},
  	readEmail(email){
  		this.loadEmailViewer = true;
  		this.emailViewerPanel = true;
  		this.emailViewerData = email;
      if(email.read_at == null){
  		this.markAsRead(email.id, true);
      }
  		 var self = this;
                setTimeout(function(){
                 //Vue.swal("Avatar Updated");
                 self.loadEmailViewer = false;
                 self.emailLoaded = true;
                }, 500);
  	},
  	getEnquiries(){
  		axios.get('/api/company/communication/enquiries?page=1&source='+this.emailsource, {headers : {
  			'Authorization' : 'Bearer '+this.$attrs.user.api_token
  		}}).then(response => {
  			this.emailData = JSON.parse(JSON.stringify(response.data[0]));
        this.unreadEmails = response.data[1];
  			this.emailDataRaw = response.data[0];
  			/*this.paginatedEmailData = this.emailData.slice(
			      this.startIndex,
			      this.endIndex
			    );*/
  		}).catch(error => {

  		});
  	},
  	markAsRead(id, isread){
  		axios.post('/api/company/communication/enquiry/'+id+'/read', {read: isread}, {headers : {
  			'Authorization' : 'Bearer '+this.$attrs.user.api_token
  		}}).then(response => {
  			this.unreadEmails--;
  		}).catch(error => {

  		});
  	},
    onPageChange() {
    	if(this.active_filter !== ''){
	      this.startIndex = (this.currentPage - 1) * this.perPage;
	      this.endIndex = (this.currentPage - 1) * this.perPage + this.perPage;

	      this.paginatedEmailData = this.emailData.slice(
	        this.startIndex,
	        this.endIndex
	      );
  		}else{
  			this.startIndex = (this.currentPage - 1) * this.perPage;
	      this.endIndex = (this.currentPage - 1) * this.perPage + this.perPage;

	      this.paginatedEmailData = this.emailDataRaw.slice(
	        this.startIndex,
	        this.endIndex
	      );
  		}
    },
  },
};
</script>

<template>
  <Layout  :nickname="$attrs.nickname" :user="$attrs.user" :menuItems="$attrs.menuItems">
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <!-- Right Sidebar -->
      <b-col md="12">
                    <transition name="slide-success" mode="out-in">
                  <b-alert id="success-message" variant="success" :show="success_message"  dismissible @dismissed="success_message = !success_message">
                      <p class="font-size-14">{{success_messagetext}}</p>
                    </b-alert>
                  </transition>
                  </b-col>
                  <div class="d-flex flex-column justify-content-center h-100 restricted-col" v-if="($attrs.user.companies[0].userrole.name !== 'Owner') && !$attrs.user.companies[0].permissions.read.includes($attrs.active_menu.id)" >
          <AccessRestricted :page="false"></AccessRestricted>
        </div>
        <div  v-else>
           <b-row>
          <b-col>
            <b-card class="shadow-sm border-radius-1em">
              <div class="d-flex align-items-center mb-3"><div class="avatar-xs mr-3"><span class="avatar-title rounded-circle bg-soft-primary text-white font-size-18"><i class="bx bx-mail-send"></i></span></div> <h5 class="font-size-14 mb-0">Outstanding Messages</h5></div> <div class="text-muted mt-4"><h4>{{unreadEmails}}</h4> </div>
            </b-card>
          </b-col>
           <b-col>
            <b-card class="shadow-sm border-radius-1em">
              <div class="d-flex align-items-center mb-3"><div class="avatar-xs mr-3"><span class="avatar-title rounded-circle bg-soft-warning text-white font-size-18"><i class="bx bx-timer"></i></span></div> <h5 class="font-size-14 mb-0">Average Response Time</h5></div> <div class="text-muted mt-4"><h4>12 mins</h4> </div>
            </b-card>
          </b-col>
           <b-col>
            <b-card class="shadow-sm border-radius-1em">
              <div class="d-flex align-items-center mb-3"><div class="avatar-xs mr-3"><span class="avatar-title rounded-circle bg-soft-primary text-white font-size-18"><i class="bx bx-user"></i></span></div> <h5 class="font-size-14 mb-0">Converted to Customer</h5></div> <div class="text-muted mt-4"><h4>1</h4> </div>
            </b-card>
          </b-col>
          </b-row>
        <div class="card border-radius-1em border p-3">
          <b-row>
      <div class="col-md-3">
        <div class="card border-radius-1em border shadow-none p-3">
        <div class="d-flex mb-2 w-100">
                            <a class="my-auto btn btn-rounded btn-block" :class="{'btn-primary' : emailsource == ''}" @click="reloadEnquiries('')">
                                 All
                            </a>

                            <a class="my-auto btn btn-rounded btn-block" :class="{'btn-primary' : emailsource == 'public'}" @click="reloadEnquiries('public')">
                                 Public
                            </a>

                            <a class="my-auto btn btn-rounded btn-block" :class="{'btn-primary' : emailsource == 'trade'}" @click="reloadEnquiries('trade')">
                                 Trade
                            </a>

                            <a class="my-auto btn btn-rounded btn-block" :class="{'btn-primary' : emailsource == 'website'}" @click="reloadEnquiries('website')">
                                 Website
                            </a>

                              
                        </div>
          <b-card class="border-radius-1em border shadow-none" body-class="p-0">
     <div class="chat-leftsidebar-nav">
        <simplebar style="max-height: 660px">
                    <ul class="list-unstyled chat-list">
                      <li class="active border-bottom" v-if="'data' in emailData" v-for="email in emailData.data">
                        <a href="javascript:;"  @click="readEmail(email), email.read_at = true" :class="{'bg-light unread' : email.read_at == null}">
                          <div class="media position-relative">
                            <div class="align-self-center me-3">
                              <i v-if="email.read_at == null" class="bx bx-mail-send font-size-10 position-absolute text-white font-size-12 bg-danger p-1 rounded-circle top-0 left-0 m-2"></i>
                            </div>
                             <b-form-checkbox class="select-row-btn my-auto" v-model="selectedids" :value="index" button-variant="light" @input="updateSelected" button>

                          <i v-if="filterSelected(index)" class="bx bx-check"></i><span class="avatar-title rounded-circle text-primary avatar-sm border border-white border-2"  :class="{'bg-peach text-dark' : email.data.source == 'website', 'bg-primary text-light' : email.data.source == 'public', 'bg-blue text-light' : email.data.source == 'trade'}" v-else>
                            <i class="font-size-20 bx bx-globe" v-if="email.data.source == 'website'"></i>
                             <i class="font-size-20 bx bx-store" v-if="email.data.source == 'public'"></i>
                              <i class="font-size-20 bx bx-buildings" v-if="email.data.source == 'trade'"></i>
                              <i class="font-size-20 bx bx-mail-send" v-else-if="(email.data.source == '') || (email.data.source == null)"></i>
                          </span></b-form-checkbox>
                            

                            <div class="media-body overflow-hidden position-relative">
                               <a href="javascript:;" @click="readEmail(email), email.read_at = true" class="title ml-2 my-auto text-muted my-auto position-relative"  :class="{'bg-light unread' : email.read_at == null}"><h6 class="mb-0">{{email.data.name}}</h6> <p class="my-auto text-truncate paragraph-truncate-line2 text-muted mb-0">{{email.data.message}}</p>
                                <span v-if="email.data.source !== ''" class="position-absolute bottom-0 right-0 mt-2 my-auto ml-2 badge font-size-11 text-capitalize" :class="{'bg-peach text-dark' : email.data.source == 'website', 'bg-primary text-light' : email.data.source == 'public', 'bg-blue' : email.data.source == 'trade'}" v-b-tooltip.hover :title="email.data.source+' enquiry'" >{{email.data.source}}</span>
                      <span v-else-if="email.data.source == ''" class="my-auto ml-2 badge badge-light bg-light my-auto font-size-11">
                        No Source
                    </span>
                            
                            </a>
                            <div class="font-size-11 position-absolute top-0 right-0">{{email.created_at | luxon({input: 'server', output: 'med'})}}</div>
                            </div>
                           
                          </div>
                        </a>
                      </li>
                    </ul>
                  </simplebar>
                </div>
              </b-card>
            </div>
      </div>
      <div class="col-md-9">
        <b-overlay :show="loadEmailViewer">
            <div class="d-flex flex-column justify-content-center h-100 restricted-col" v-if="($attrs.user.companies[0].userrole.name !== 'Owner') && !$attrs.user.companies[0].permissions.view.includes($attrs.active_menu.id)" >
          <AccessRestricted :page="false"></AccessRestricted>
        </div>
        <div v-else>



          <EmailViewer v-if="emailLoaded" :email="emailViewerData" @send-email="sendEmail" @close-email-viewer="emailViewerData = true, emailLoaded = false"></EmailViewer>
           <div v-else class="card shadow-none border-radius-1em border">
            <div class="bg-light justify-content-center align-items-center d-flex w-100 p-4 flex-column text-center border-radius-1em">
                <i class="mdi mdi-inbox-outline font-size-48 text-primary mt-3">
                </i>
                <h5 class="mb-3">Select a Message to Start</h5>
              </div>
          </div>
        </div>
         <div class="d-flex flex-column justify-content-center h-100 restricted-col" v-if="( ($attrs.user.companies[0].userrole.name !== 'Owner') && !$attrs.user.companies[0].permissions.edit.includes($attrs.active_menu.id) ) || (($attrs.user.companies[0].userrole.name !== 'Owner') && !$attrs.user.companies[0].permissions.edd.includes($attrs.active_menu.id))" >
          <AccessRestricted :page="false"></AccessRestricted>
        </div>
        <div v-else>

          <transition name="fade-top">
            <Emailer v-if="showReply" :email="send_email" :user="$attrs.user"></Emailer>
          </transition>
        </div>
        </b-overlay>
        <!--<Sidepanel @filter="filterNotifications" @send-email="sendEmail" :unread="unread" />
        <div class="email-rightbar mb-3">
          <div class="card">
            <div class="btn-toolbar p-3">
              <Toolbar />
            </div>
            <div class="mt-3" v-if="rows !== 0">
              <b-list-group class="message-list">
                <th class="d-flex list-group-item">

                   <div class="col-mail col-mail-1 w-25 my-auto d-flex">
                    <div class="select-row-btn mx-3">
                      #
                    </div>
                    <div class="ml-3">
                      Source
                    </div>
                    <div class="ml-2">
                      From
                    </div>
                    </div>
                     <div class="col-mail col-mail-2 w-75 my-auto d-flex">
                      <div class="w-75">
                      Message
                      </div>
                      <div class="w-25">
                      Date Received
                      </div>
                    </div>
                </th>
                <b-list-group-item
                  class="py-0"
                  :class="{ 'unread bg-light': email.read_at == null }"
                  v-for="(email,index) in paginatedEmailData"
                  :key="index"
                >
                  <a class="d-flex w-100">
                    <div class="col-mail col-mail-1 w-25 py-2 d-flex">
                          <b-form-checkbox class="select-row-btn" v-model="selectedids" :value="index" button-variant="light" @input="updateSelected" button>

                          <i v-if="filterSelected(index)" class="bx bx-check"></i><span class="avatar-title rounded-circle avatar-sm"  :class="{'bg-peach text-dark' : email.data.source == 'website', 'bg-primary text-light' : email.data.source == 'public', 'bg-blue text-light' : email.data.source == 'trade'}" v-else>
                            <i class="font-size-20 bx bx-globe" v-if="email.data.source == 'website'"></i>
                             <i class="font-size-20 bx bx-store" v-if="email.data.source == 'public'"></i>
                              <i class="font-size-20 bx bx-buildings" v-if="email.data.source == 'trade'"></i>
                          </span></b-form-checkbox>
                    
                         <a href="javascript:;" @click="readEmail(email), email.read_at = true" class="title ml-2 my-auto text-muted my-auto"><h6 class="mb-0">{{email.data.name}}</h6> <p class="my-auto text-truncate paragraph-truncate-line2 text-muted mb-0">{{email.data.message}}</p></a>

                      
                      <span v-if="email.data.source !== ''" class="my-auto ml-2 badge font-size-11 text-capitalize" :class="{'bg-peach text-dark' : email.data.source == 'website', 'bg-primary text-light' : email.data.source == 'public', 'bg-blue' : email.data.source == 'trade'}" v-b-tooltip.hover :title="email.data.source+' enquiry'" >{{email.data.source}}</span>
                      <span v-else-if="email.data.source == ''" class="my-auto ml-2 badge badge-light bg-light my-auto font-size-11">
                        No Source
                    </span>
                      <a href="javascript:;" @click="readEmail(email), email.read_at = true" class="title ml-2 my-auto text-muted">{{email.data.name}}</a>
                    </div>
                    <div class="col-mail col-mail-2 w-75 d-flex">
                      <a href="javascript:;" @click="readEmail(email), email.read_at = true" class="subject text-truncate w-75 mr-4 d-flex text-muted"><span class="my-auto text-truncate">{{email.data.message}}</span>
                        <a class="btn btn-sm btn-light ml-auto my-auto mr-2">
                          Read
                          </a>
                      </a>
                      <div class="date px-0 my-auto w-25 text-muted">{{getDate(email.created_at)}}</div>
                    </div>
                  </a>
                </b-list-group-item>
              </b-list-group>
            </div>
            <div v-else class="p-3">
	            <div class="bg-light justify-content-center align-items-center d-flex w-100 p-4 flex-column text-center border-radius-1em">
	            	<i class="mdi mdi-inbox-outline font-size-48 text-primary mt-3">
	            	</i>
	            	<h5 class="mb-3">No Enquiries to Show</h5>
	            </div>
        	</div>
          </div>
          <div class="row justify-content-md-between align-items-md-center">
            <div class="col-xl-7">Showing {{startIndex}} - {{endIndex}} of {{rows}}</div>
    
            <div class="col-xl-5">
              <div class="text-md-end float-xl-end mt-2 pagination-rounded">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                  @input="onPageChange"
                ></b-pagination>
              </div>
            </div>
           
          </div>
        </div>-->
      </div>
    </b-row>
  </div>
    </div>
        <!--<SideSlidePanel :editPanel="emailViewerPanel" :panelClass="'edit-sidepanel'" @close-panel="emailViewerPanel = false">
        	<b-overlay :show="loadEmailViewer">
            <div class="d-flex flex-column justify-content-center h-100 restricted-col" v-if="($attrs.user.companies[0].userrole.name !== 'Owner') && !$attrs.user.companies[0].permissions.view.includes($attrs.active_menu.id)" >
          <AccessRestricted :page="false"></AccessRestricted>
        </div>
        <div v-else>
    			<EmailViewer :email="emailViewerData" @send-email="sendEmail"></EmailViewer>
        </div>
    		</b-overlay>
	    </SideSlidePanel>
	    <b-modal id="send-email" size="lg" centered hide-footer content-class="border-radius-1em">
        <div class="d-flex flex-column justify-content-center h-100 restricted-col" v-if="( ($attrs.user.companies[0].userrole.name !== 'Owner') && !$attrs.user.companies[0].permissions.edit.includes($attrs.active_menu.id) ) || (($attrs.user.companies[0].userrole.name !== 'Owner') && !$attrs.user.companies[0].permissions.edd.includes($attrs.active_menu.id))" >
          <AccessRestricted :page="false"></AccessRestricted>
        </div>
        <div v-else>
        	<Emailer :email="send_email" :user="$attrs.user"></Emailer>
        </div>
        </b-modal>-->
    </div>

  </Layout>
</template>
<style type="text/css" scoped>
  .bg-blue{
        background-color: #2A307D !important;
  }
</style>