<template>
	<div class="card shadow-none border-none"  id="categories">
       
            <div class="row px-3">
                <b-button v-for="collection in collections"
                      class="custom-radio flex-grow-0 w-25 custom-radio-outline position-relative custom-radio-primary my-0 py-4 w-sm col-md-4 border-radius-1em inset-border border-radius-1em"
                      value="0"
                      :class="{'active' : activeInCollections(collection)}"
                      variant="outline-light"
                      @click="updateActiveCollection(collection)"
                    >
                    <img class="avatar d-block avatar-md rounded-circle mx-auto" v-if="collection.image !== null" :src="'/image/'+collection.image">
                    <img v-else class="avatar d-block avatar-md rounded-circle mx-auto" src="/images/product-placeholder.png">
                    <i v-if="activeInCollections(collection)" class="bx bx-check-circle font-size-24 d-block text-success my-2 position-absolute top-1"></i> {{collection.name}}
             	</b-button>
            </div>

    </div>
</template>

<script type="text/javascript">
	export default{
		data(){
			return {
				active_collections: [],
				collections: [],
				search: '',
				limit: 0,
				currentPage: 1,
			}
		},
		created(){
			this.getCollections();
		},
		methods: {
			getCollections(){
		      axios.get('/api/company/product/collections?page='+this.currentPage+'&limit='+this.limit+'&sortby=name&order=asc&search='+this.search, {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.api_token
              }}).then(response => {
		        this.collections = response.data;
		      }).catch(error => {
		        this.brands_error = error.data;
		      });
		    },
		    updateActiveCollection(collection){
            var activeCollections = this.active_collections.findIndex(item => item.id == collection.id);
            if(activeCollections < 0){
              this.active_collections.push(collection);
            }else{
              this.active_collections.splice(activeCollections, 1);
            }
          },
          activeInCollections(collection){
            if(this.active_collections.length > 0){
            var activeCollections = this.active_collections.filter(item => item.id == collection.id);
              if(activeCollections.length > 0){
                return true;
              }else{
                return false;
              }
            }

          },
		}
	}


</script>