<script type="text/javascript">
	import Swal from "sweetalert2";
	import PageBuilder from "../../components/widgets/page-builder/PageBuilder";
	import PageBuilderPreview from "../../components/widgets/page-builder/PageBuilderPreview";
	import vue2Dropzone from 'vue2-dropzone';
	import { webPageWidgets } from "../web/page-widgets"
	import stylesTab from "../../components/widgets/page-builder/widgets/stylesTab";
	import { containerStyles, widgetStyles } from "../../components/widgets/page-builder/page-styles";
	import draggable from 'vuedraggable';
	import simplebar from "simplebar-vue";
	import { EventBus } from "../../app";
	import CodeEditor from 'simple-code-editor';
			
	export default{
		components: {CodeEditor, PageBuilder, PageBuilderPreview, vueDropzone: vue2Dropzone, draggable, simplebar, stylesTab},
		data() {
		    return {
		    	activeSection: false,
		    	showSectionTools: -1,
		      containerStyles: containerStyles,
		      title: "Display",
		      showModal: false,
		      sidePanelMaxHeight: 420,
		      sectionHover: -1,
		      sectionSettings: -1,
		      sectionStyles_raw: '',
		      pageSettings: false,
		      pageStylesheets: [],
		      pageTemplates: [
		      	{name: 'Template 1', thumbnail: '', description: ''},
		       	{name: 'Template 2', thumbnail: '', description: ''},
		      ],
		      page: {
		      	name: '',
		      	url_rewrite: '',
		      	description: '',
		      	thumbnail: '',
		      	company_web_page_template_id: '',
		      	company_web_page_theme_id: '',
		      	header: '',
		      	footer: '',
		      	active: 0, 
		      	custom_classes: '',
		      	custom_css: '',
		      	custom_js: '',
		      	content: {
		 		sections: [
		 			
		 		],	     	
		     	 }
		  	 },
		  	 showPageTemplates: false,
		      error_reponse: '',
		      error_message: false,
		      webPageWidgets: webPageWidgets,
		      dropzoneOptions: {
		        url: 'https://httpbin.org/post',
		        thumbnailHeight: 150,
		        thumbnailWidth: 150,
		        autoProcessQueue: false,
		      },
		    };
		  },
		created(){
			//window.resizeTo(500, 500);
			var meta = document.createElement('meta');
			meta.name = "viewport";
			meta.content = "width=device-width, initial-scale=1.0";
			document.getElementsByTagName('head')[0].appendChild(meta);
			document.documentElement.classList.add("bg-white");
			document.body.classList.add("bg-white");
			if(this.$attrs.id !== ''){
				this.getPage();
			}else{
				this.addSection();
			}
			window.addEventListener("resize", this.windowResize);	
		},
		destroyed() {
            window.removeEventListener("resize", this.windowResize);
        },
        mounted(){
        	this.sidePanelMaxHeight = window.innerHeight - 150;
        },
		methods: {
			addSection(){
				this.page.content.sections.push({styles: JSON.parse(JSON.stringify(this.containerStyles)), rows: []})
			},
			duplicateSection(index, section){
				this.page.content.sections.splice(index, 0, JSON.parse(JSON.stringify(section)));
			},
			deleteSection(index){
				if((index == 0) && (this.page.content.sections.length == 1)){
					Swal.fire("You can't delete this", "Every page needs at least one section!", "error");
				}else{
					this.page.content.sections.splice(index, 1);
					/*Swal.fire({
				        title: "Are you sure?",
				        text: "You won't be able to recover this section!",
				        icon: "warning",
				        allowOutsideClick: false,
				        showCancelButton: true,
				        confirmButtonColor: "#34c38f",
				        cancelButtonColor: "#f46a6a",
				        confirmButtonText: "Leave this section",
				        cancelButtonText: "Delete this section"
				      }).then(result => {
				        if (result.value) {
				          Swal.fire("Saved", "Your page has been saved.", "success");

				          //await async function()
				        }else{
				        	let timerInterval;
				        	Swal.fire({
						        title: "Section deleted",
						        html: "Your section has been deleted",
						        timer: 1000,
						        timerProgressBar: true,
						        onBeforeOpen: () => {
						          //Swal.showLoading();
						          timerInterval = setInterval(() => {
						            Swal.getContent().querySelector(
						              "b"
						            ).textContent = Swal.getTimerLeft();
						          }, 100);
						        },
						        onClose: () => {
						          this.page.content.sections.splice(index, 1);
						        }
		      				})
				        }
				      });*/
					
				}
			},
			selectPageTemplate(){
				this.getPageTemplates();
				this.showPageTemplates = true;
			},
			getPageTemplates(){

			},
			getPage(){
				axios.get('/api/company/sell/display/'+this.$attrs.id, {headers: {
				'Authorization' : 'Bearer '+this.$attrs.user.api_token
				}}).then(response => {
					this.page = response.data;
				}).catch(error => {
					this.error_reponse = error.response.error;
					this.error_message = true;
				});
			},
			savePage(){
				//var postForm = new FormData(document.getElementById("page-form"));
				//this.page.append('thumbnail', this.page.thumbnail);
				//postForm.append('header', JSON.stringify(this.page.header));
				//postForm.append('footer', JSON.stringify(this.page.footer));
				//postForm.append('content', JSON.stringify(this.page.content));
				axios.post('/api/company/sell/display/create', this.page, {headers: {
					'Authorization' : 'Bearer '+this.$attrs.user.api_token
				}}).then(response => {
					this.page = response.data;
					var self = this;
	                setTimeout(function(){
	                 //Vue.swal("Avatar Updated");
	                 	Swal.fire("Saved", "Your page has been saved.", "success");
	                 	//this.success_text = "Your page has been saved";
						//this.success_message = true;
	                }, 500);
				}).catch(error => {

				});
			},
			updatePage(){
				axios.post('/api/company/sell/display/'+this.page.uid+'/update', postForm, {headers: {
					'Authorization' : 'Bearer '+this.$attrs.user.api_token
				}}).then(response => {
					this.page = response.data;
					var self = this;
	                setTimeout(function(){
	                 //Vue.swal("Avatar Updated");
	                 	Swal.fire("Saved", "Your page has been saved.", "success");
	                 	//this.success_text = "Your page has been saved";
						//this.success_message = true;
	                }, 500);
				}).catch(error => {

				});
			},
			changeView(){
				document.body.classList.add("bg-white");
			},
			triggerEvent(event){
				EventBus.$emit(event, true);
			},
			deletePage(){

			},
			imageAdded(file){
	            this.page.thumbnail = file;
	        },
	        areYouSure(){
	        	Swal.fire({
		        title: "Are you sure?",
		        text: "Make sure you have saved your work",
		        icon: "warning",
		        allowOutsideClick: false,
		        showCancelButton: true,
		        confirmButtonColor: "#34c38f",
		        cancelButtonColor: "#f46a6a",
		        confirmButtonText: "Save and Leave",
		        cancelButtonText: "Leave without Saving"
		      }).then(result => {
		        if (result.value) {
		          Swal.fire("Saved", "Your page has been saved.", "success");

		          //await async function()
		        }else{
		        	let timerInterval;
		        	Swal.fire({
				        title: "Redirecting you now",
				        html: "Your page has not been saved.",
				        timer: 500,
				        timerProgressBar: true,
				        onBeforeOpen: () => {
				          //Swal.showLoading();
				          timerInterval = setInterval(() => {
				            Swal.getContent().querySelector(
				              "b"
				            ).textContent = Swal.getTimerLeft();
				          }, 100);
				        },
				        onClose: () => {
				          window.location.replace('https://'+this.$attrs.company.nickname+'.gemesys.co.uk/web/pages')
				        }
      				})
		        }
		      });
	        },
	        compiledStyles(styles){
	        	var style = 'will-change: auto; ';
	        	for(var i = 0; i < (styles.length - 1); i++ ){
	        		for(var key in styles[i] ){
	        			
	        		if(styles[i][key].active == true){
		        		if(key.includes('_label') == false){

		        			if(key == 'background'){
		        				//var stylename = key.replace('_', '-');
		        				if(styles[i][key].value[0] == 'background-image'){
		        					style += ''+styles[i][key].value[0]+': url('+styles[i][key].value[1]+'); '
		        				}else{
		        					style += ''+styles[i][key].value[0]+': '+styles[i][key].value[1]+'; '
		        				}
		        				style += 'background-size: '+styles[i][key].size+'; '
		        				style += 'background-position: '+styles[i][key].position+'; '
		        				style += 'background-repeat: '+styles[i][key].repeat+'; '
		        			}
		        			else if(styles[i][key].type == 'numbergroup'){
		        				//var stylename = key.replace('_', '-');
		        				for(var numgroupkey in styles[i][key]){
		        					if((numgroupkey !== 'parentlabel') || (numgroupkey !== 'type')){
		        						if((key.includes('_radius') == false) && (key.includes('border') == true)){

		        							style += ''+key+'-'+numgroupkey.replace('_','-')+': '+styles[i][key][numgroupkey]+'px '+styles[this.filterStylesKey(styles, 'border_style')]["border_style"].value+' '+styles[this.filterStylesKey(styles, 'border_colour')]["border_colour"].value+' !important; '

		        						}else	if(key.includes('_radius') == false){

		        							style += ''+key+'-'+numgroupkey.replace('_','-')+': '+styles[i][key][numgroupkey]+'px !important; '
		        						
		        						}else{
		        						
		        							style += ''+key.replace('_radius', '')+'-'+numgroupkey.replace('_','-')+'-radius: '+styles[i][key][numgroupkey]+'px !important; '
		        						
		        						}
		        					}
		        				}
		        			}
		        			else if(styles[i][key].type == 'range'){
		        					if('units' in styles[i][key]){
		        						//console.log(styles[i][key].value);
		        						if(styles[i][key].value !== null){
			        						var stylename = key.replace('_', '-');
					        				style += ''+stylename+': '+parseFloat(styles[i][key].value)+''+styles[i][key].units+'; '; 
				        					}
		        					 }else{
		        					 		style += ''+stylename+': '+styles[i][key].value+'; '
		        					 }

		        			}else{
		        				var stylename = key.replace('_', '-');
		        				stylename = stylename.replace('colour', 'color');
		        				if(styles[i][key].value.length !== 0){
		        					style += ''+stylename+': '+styles[i][key].value+' !important; '
		        				}else{
		        					style += ''+stylename+': '+styles[i][key].value+' !important; '
		        				}
		        			}
		        		}
	        		}
	        	}
	        }
	        	return style;
	        },
	         windowResize(e) {
            console.log(window.innerHeight);
    
            this.sidePanelMaxHeight = window.innerHeight - 150;
   
           
          },
          filterStylesKey(styles, key){
       		for(var i = 0; i < styles.length; i++){
       			 var keys = Object.keys(styles[i])
       			 if(keys.includes(key)){
       			 	 return i;
       			 }
       		}
       },
          sanitizeTitle: function(title) {
            var nickname = "";
            // Change to lower case
            var titleLower = title.toLowerCase();
            // Letter "e"
            nickname = titleLower.replace(/e|é|è|ẽ|ẻ|ẹ|ê|ế|ề|ễ|ể|ệ/gi, 'e');
            // Letter "a"
            nickname = nickname.replace(/a|á|à|ã|ả|ạ|ă|ắ|ằ|ẵ|ẳ|ặ|â|ấ|ầ|ẫ|ẩ|ậ/gi, 'a');
            // Letter "o"
            nickname = nickname.replace(/o|ó|ò|õ|ỏ|ọ|ô|ố|ồ|ỗ|ổ|ộ|ơ|ớ|ờ|ỡ|ở|ợ/gi, 'o');
            // Letter "u"
            nickname = nickname.replace(/u|ú|ù|ũ|ủ|ụ|ư|ứ|ừ|ữ|ử|ự/gi, 'u');
            // Letter "d"
            nickname = nickname.replace(/đ/gi, 'd');
            // Trim the last whitespace
            nickname = nickname.replace(/\s*$/g, '');
            // Change whitespace to "-"
            nickname = nickname.replace(/\s+/g, '-');
            // Remove quotations
            nickname = nickname.replace(/["']/g, "");
            
            return nickname;
        },
		}
	}
</script>

<template>
	<form id="page-form" method="post" enctype="multipart/form-data" @submit.prevent="">
	<draggable handle=".sechandle"
				tag="div"
				class="page-builder-element-outline position-relative"
  				:list="page.content.sections"
                      ghost-class="ghost" v-for="(section, sindex) in page.content.sections" :class="section.styles[1].containerStyle.value" :style="compiledStyles(section.styles)">
                      <transition name="fade-top">
                      <span v-if="showSectionTools == sindex" class="d-flex flex-row sechandle z-index-7 position-absolute top-0 w-100 right-0 left-0">
                      	<span class="text-white bg-success top-0 mx-auto section-tab d-flex">
                      <span class="text-uppercase my-auto d-flex">
                      	<span class="my-auto mr-3">Section {{sindex + 1}} </span>
                      	<i class="mdi mdi-cursor-move my-auto"></i>
                      	</span>
                      <a v-b-tooltip.hover.bottom title="Duplicate Section" @click="duplicateSection(sindex, section)" :disabled="page.content.sections.length < 2" class="mr-auto text-white" href="javascript:;"><i class="font-size-14 ml-1 mdi mdi-content-duplicate my-auto"></i></a>
                      <a v-b-tooltip.hover.bottom title="Delete Section" @click="deleteSection(sindex)" :disabled="page.content.sections.length < 2" class="mr-auto text-white" href="javascript:;"><i class="font-size-14 ml-1 mdi mdi-close my-auto"></i></a>
                    	<a v-b-tooltip.hover.bottom title="Section Settings" @click="triggerEvent('close-subpanel'), sectionSettings = sindex, sectionStyles_raw = JSON.parse(JSON.stringify(section.styles))" class="mx-auto text-white" href="javascript:;"><i class="font-size-14 ml-1 mdi mdi-cog my-auto"></i></a>
                    		</span>
                    	</span>
                    </transition>
                    <transition name="fade-right">
                     <b-card class="page-builder-section-settings border-none shadow-none position-fixed right-0 top-0 bottom-0 px-0 mb-0 shadow-none" body-class="px-0 pt-0" v-if="sectionSettings == sindex">
                    	<simplebar style="overflow-x: hidden;" :style="{'max-height' : sidePanelMaxHeight+'px'}">
                    		<b-row>
                    			<b-col md="12" class="d-flex py-3 bg-success">
                    				<h5 class="text-white my-auto ml-4">Section Options</h5>
                    				<a class="btn btn-success btn-rounded ml-auto mr-3"  @click="sectionSettings = -1, section.styles = JSON.parse(JSON.stringify(sectionStyles_raw))"><i class="mdi mdi-close"></i></a>
                    			</b-col>
                    			<b-col md="12">
	                    				<b-tabs justified nav-class="nav-tabs-custom success" content-class="pb-3 pt-0 text-muted">
	                         			<!--<b-tab >
	                         			 <template v-slot:title>
										                  <span class="d-inline-block d-sm-none">
										                    <i class="bx bx-notepad"></i>
										                  </span>
										                  <span class="d-none d-sm-inline-block">Design</span>
										                </template>
										                <b-card class="page-builder-section-settings border-radius-1em w-100" body-class="px-0 pt-0">
										                	<b-list-group>
											               <b-list-group-item v-for="(value, key) in section.styles" :id="'settings-'+key" :key="key">
											               	<b-form-group class="text-uppercase" :label="key.replace(/_/g, ' ')">
											               
    																		 
    																	<div class="d-flex" v-if="section.styles[key].type == 'range'">
											               		<b-form-input type="number"  v-model="section.styles[key].value" :max="2400"></b-form-input>
											               		 <b-form-radio-group v-model="section.styles[key].units" buttons button-variant="outline-primary" size="sm" >
    																		  	<b-form-radio value="px">px</b-form-radio>
    																		  	<b-form-radio value="%">%</b-form-radio>
    																		  </b-form-radio-group>
    																	</div>
    																	<div class="d-flex" v-else-if="section.styles[key].type == 'numbergroup'">
    																		<b-form-group label="" description="LEFT" class="mr-1">
											               			<b-form-input type="number"  v-model="section.styles[key].left" :min="0"></b-form-input>
											               		</b-form-group>
											               		<b-form-group description="RIGHT" class="mx-1">
																				<b-form-input type="number"  v-model="section.styles[key].right" :min="0"></b-form-input>
																				</b-form-group>
																				<b-form-group description="TOP" class="mx-1">
																				<b-form-input type="number"  v-model="section.styles[key].top" :min="0"></b-form-input>
																			</b-form-group>
																			<b-form-group description="BOTTOM" class="ml-1">
																				<b-form-input type="number"  v-model="section.styles[key].bottom" :min="0"></b-form-input>
																				</b-form-group>								               		
    																	</div>
    																	<div class="d-flex" v-else-if="section.styles[key].type == 'select'">
    																		<b-form-group class="w-100">
    																			<b-form-select class="form-control text-capitalize" v-model="section.styles[key].value" :options="section.styles[key].options">
    																			</b-form-select>
    																		</b-form-group>
    																	</div>

											               		<b-form-input v-else class="w-100 mt-2"  :type="section.styles[key].type" v-model="section.styles[key].value"></b-form-input>
											               	</b-form-group>
											               	
											               </b-list-group-item>
										             	 </b-list-group>
										                </b-card>
										               </b-tab>-->
										               <stylesTab :user="$attrs.user" :company="$attrs.company" :styles="section.styles" :widgetbox="false" @showFileManager="" :element="'section'" />
										            </b-tabs>
                    			</b-col>
                    		</b-row>
                    	</simplebar>
                    	<b-row class="row position-absolute bottom-0 right-0 left-0 bg-white py-3 z-index-1">
                    			<b-col md="6">
                    				<a class="btn btn-danger btn-rounded btn-block" @click="sectionSettings = -1, section.styles = JSON.parse(JSON.stringify(sectionStyles_raw))">
                    					Cancel
                    				</a>
                    			</b-col>
                    			<b-col md="6">
                    				<a class="btn btn-success btn-rounded btn-block" @click="sectionSettings = -1">
                    					Save
                    				</a>
                    			</b-col>
                    		</b-row>

                    </b-card>
                  </transition>
	 <!--<PageBuilder :user="$attrs.user" :data="section" :widgets="webPageWidgets" :row_limit="50" :col_limit="50" :preview="false" :allow_preview="false" :company="$attrs.company" @add-section="addSection" :sidePanelMaxHeight="sidePanelMaxHeight" @show-section-tools="showSectionTools = sindex" @hide-section-tools="showSectionTools = -1"  @sidepanel-opened="sectionSettings = -1" :sindex="sindex"  ></PageBuilder>
	-->
	 <PageBuilder :type="'page'" :user="$attrs.user" :data="section" :widgets="webPageWidgets" :template_areas="[]" :row_limit="50" :col_limit="50" :preview="false" :allow_preview="false" :company="$attrs.company" @add-section="addSection" :sidePanelMaxHeight="sidePanelMaxHeight" @show-section-tools="showSectionTools = sindex" @hide-section-tools="showSectionTools = -1"  @sidepanel-opened="sectionSettings = -1" :sindex="sindex"  ></PageBuilder>

	 
	</draggable>
	<b-container fluid class="position-fixed bottom-0 left-0 right-0 mb-3">
		<b-row>
			<b-col class="d-flex"> 
				<a class="mr-auto my-auto btn btn-primary btn-rounded" @click="pageSettings = !pageSettings">
					<i class="bx bx-cog">
					</i>
					<span>
						Page Settings
					</span>
				</a>
			</b-col>
			<b-col>
				
			</b-col>
			<b-col class="d-flex">
				<a class="ml-auto mr-2 my-auto btn btn-danger btn-rounded" @click="areYouSure()">
					<i class="bx bx-chevron-left">
					</i>
					<span>
						Back to Pages
					</span>
					
				</a>
				<a class="mr-2 my-auto btn btn-info btn-rounded" @click="activatePreviewMode()">
					<i class="mdi mdi-eye-outline">
					</i>
					<span>
						Preview
					</span>
					
				</a>
				<a class="my-auto btn btn-success btn-rounded" v-if="$attrs.id == ''" @click="savePage()">	
					<span>
						Save
					</span>
					<i class="bx bx-save">
					</i>
				</a>
				<a class="my-auto btn btn-success btn-rounded" v-else @click="updatePage()">
					
					<span>
						Update
					</span>
					<i class="bx bx-save">
					</i>
				</a>
			</b-col>
		</b-row>
	</b-container>
	<transition name="fade-left">
		<div v-if="pageSettings == true" class="z-index-2 page-options position-fixed top-0 left-0 bottom-0 bg-white shadow" style="width: 460px;">
			<!--<div class="w-100 top-0 d-flex mt-2 mb-4">
			<a class="mr-3 ml-auto my-auto btn btn-outline-light btn-rounded float-right" @click="pageSettings = !pageSettings">
					<i class="mdi mdi-close">
					</i>
					
				</a>
			</div>-->
			<b-row class="bg-white">
			<b-col md="12" class="d-flex py-3 bg-primary">
                    				<h5 class="text-white my-auto ml-4">Page Options</h5>
                    				<a class="btn btn-primary btn-rounded ml-auto mr-3"  @click="pageSettings = !pageSettings"><i class="mdi mdi-close"></i></a>
                    			</b-col>
                   
        <b-col md="12" class="pt-2">
				<b-tabs justified content-class="px-3" nav-class="nav-tabs-custom">
					<b-tab title="General">
						<b-card class="border-radius-1em border shadow-none h-100 mt-3">
							<b-form-group label="Name" class="mt-2">
								<b-form-input type="text" v-model="page.name"></b-form-input>
							</b-form-group>
							<b-form-group label="Url" class="mt-2">
								<b-form-input id="metatitle" name="url_rewrite" type="text" class="form-control" v-model="page.url_rewrite" :value="sanitizeTitle(page.name)" ></b-form-input></b-form-group>
							</b-form-group>
							<b-form-group label="Description" class="mt-2">
								<b-form-textarea type="text" v-model="page.description"></b-form-textarea>
							</b-form-group>
							<b-form-group label="Page Thumbnail" class="mt-2">
								<vue-dropzone id="dropzone" ref="myVueDropzone" :use-custom-slot="true" :options="dropzoneOptions" @vdropzone-file-added="imageAdded"  @vdropzone-drop="imageAdded">
			                        <div class="dropzone-custom-content">
			                            <i class="display-4 text-muted bx bxs-cloud-upload"></i>
			                            <h4>Drop files here or click to upload.</h4>
			                        </div>
			                        </vue-dropzone>
							</b-form-group>
							<input type="hidden" name="thumbnail" v-model="page.thumbnail" />
							<b-form-group label="Active" class="mt-2">
												 <div
				                        class="form-check form-switch form-switch-lg w-75 d-flex"
				                        dir="ltr"
				                      >
				                 
				                      <input
				                          class="form-check-input ml-auto my-auto" 
				                          type="checkbox"
				                          id="SwitchCheckSizesm"
				                         
				                          v-model="page.active"
				                        
				                          name="active"
				                        />
				                    </div>
							</b-form-group>
							
						</b-card>
					</b-tab>
					
					<b-tab title="Template">
						<b-card class="border-radius-1em border shadow-none h-100 mt-3">
							<b-row>
								<b-col>
									<h6>Current Template</h6>
									<b-card v-if="page.company_web_page_template_id !== ''" class="border-radius-1em border shadow-none">
									</b-card>
									<b-card v-else class="border-radius-1em border shadow-none" body-class="d-flex">
										<div>
										<h6 class="mb-0">No Template Selected</h6>
										<span class="text-muted font-italic">Using Default Template</span>
										</div>
										<div class="d-flex ml-auto">
											<a class="btn btn-primary ml-auto btn-rounded my-auto" @click="selectPageTemplate()">Select New <i class="bx bx-chevron-down"></i></a>
										</div>
									</b-card>
								</b-col>
							</b-row>
							<transition name="fade-top">
								<b-row v-if="showPageTemplates == true">
									<b-col md="12">
										<h6>Select New Template</h6>
									</b-col>
									<b-col md="6" v-for="template in pageTemplates">
										<b-card class="border-radius-1em border shadow-none" body-class="p-0">
										<b-card-img v-if="template.thumbnail" :src="template.thumbnail"></b-card-img>
										<b-card-img v-else :src="'/images/product-placeholder.png'"></b-card-img>
										<b-card-title class="p-2">{{template.name}}</b-card-title>
										<b-card-text class="p-2">{{template.description}}</b-card-text>
										</b-card>
									</b-col>
								</b-row>
							</transition>
						</b-card>
					</b-tab>
				
					<b-tab title="Advanced">
						<b-card class="border-radius-1em border shadow-none h-100 mt-3">
							<b-form-group label="Custom CSS Classes" >
								<b-form-input type="text" ></b-form-input>
							</b-form-group>
							<label class="mt-2 text-muted">Custom CSS</label>
								<CodeEditor selector_height="200px" min_height="200px" max_height="400px"  :language_selector="false" :languages="[['css', 'CSS']]" theme="light" class="light bg-white w-100"></CodeEditor>
						
							
						</b-card>
					</b-tab>
				</b-tabs>
				</b-col>
       </b-row>
		</div>
	</transition>
	</form>
</template>
