<script type="text/javascript">
	import TradeProductCard from './ecommerce/trade/TradeProductCard'
	import TradeProductListCard from './ecommerce/trade/TradeProductListCard'
	import { EventBus } from '../../app'
	export default{
		components: {TradeProductCard, TradeProductListCard},
		props: {
			resource: {
		        type: String,
		        default: () => '',
		      },
		      resource_id: {
		        type: Number,
		        default: () => '',
		      },
		      api_token: {
		        type: String,
		        default: () => '',
		      },
		      resource_route: {
		        type: String,
		        default: () => '',
		      },
		      marketplace: {
		        type: String,
		        default: () => '',
		      },
		      product_list: {
		        type: Array,
		        default: () => '',
		      },
		      expanded:{
		      	type: Number,
		      	default: () => 0
		      },
		      view: {
		      	type: String,
		      	default: () => 'grid'
		      },
		      layouttoggle: {
		      	type: Boolean,
		      	default: () => true
		      },
		      showtools: {
		      	type: Boolean,
		      	default: () => false
		      },
		      showcategories: {
		      	type: Boolean,
		      	default: () => true
		      },
		      minilist: {
		      	type: Boolean,
		      	default: () => false
		      }
		  },
		  data(){
			return{
		        quickviewproduct: '',
		        quantity: 1,
		        showQuantitySelector: null,
		        hoverIndex: -1,
		        storageurl: '.s3.eu-west-2.amazonaws.com',
		        showQVGallery: false,
		        viewImage: null,
		        imgswiperOptions: {
		          direction: 'vertical',
		          slidesPerView: 3,
		          spaceBetween: 10,
		          pagination: {
		            el: '.swiper-pagination',
		            clickable: true
		          },
		          navigation: {
		            nextEl: '.swiper-button-next',
		            prevEl: '.swiper-button-prev'
		          }

		        },
			}
		  },
		  methods: {
		  	copyLink(ref){
        var copyText = ref;
        //var copyText = document.getElementById(""+elementid+"");
        this.copy(copyText);
        /* Select the text field */
        copyText.select();
        copyText.setSelectionRange(0, 99999); /*For mobile devices*/

        /* Copy the text inside the text field */
        document.execCommand("copy");
      },
		  	removeResource(){

		  	},
		  	priceIncVat(price, tax_rule){
		  		if(tax_rule !== null){
			      var tax = 1 + (tax_rule.percentage / 100);
			      return (parseFloat(price) * parseFloat(tax)).toFixed(2);
		    	}else{
		    		return parseFloat(price).toFixed(2);
		    	}
		    },
		    addToCart(product, quantity){
		    	EventBus.$emit('add-to-cart', product, quantity)
		    },
		    imageSelected(img){
		    	if(this.viewImage == null){
		    		this.quickviewproduct.images.unshift(this.quickviewproduct.cover_image);
		    	}
		    	this.viewImage = img.src;

		    },
		    qvModalClosed(){
		    	this.viewImage = null;
		    }
		  }
	}
</script>
<template>
	 <div class="row">
          <div class="col-md-12">
          	<ul class="nav nav-pills product-view-nav mb-2 float-right" v-if="layouttoggle == true">
          	<li class="nav-item">
                  <a class="nav-link" :class="{'active': view == 'grid' }" href="javascript: void(0);" @click="view = 'grid'">
                    <i class="bx bx-grid-alt"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': view == 'list' }" href="javascript: void(0);" @click="view = 'list'">
                    <i class="bx bx-list-ul"></i>
                  </a>
                </li>
               </ul>
          	<ul id="my-table" class="list-group product-list w-100 no-shadow" v-if="(product_list.length !== 0) && (view == 'list')">
                              <li v-for="product in product_list" class="list-group-item d-flex px-0 py-0 border product-list-item mb-3 shadow-sm">
                              	<div class="d-flex product-list-item-container position-relative">
                              		<span class="badge bg-primary position-absolute mt-2 ml-2 product-list-reference-badge" v-if="minilist == false">{{product.reference}}</span>
                              	<img v-if="product.cover_image" class="avatar-lg mr-3  product-list-img" :src="'https://'+product.company.nickname+storageurl+product.cover_image.src.replace('//', '/')" :class="{'w-15 h-100': minilist == false}">
                              	<img v-else class="avatar-lg mr-3 product-list-img" :class="{'w-15 h-100': minilist == false}" src="/images/product-placeholder.png">
                                <div class="mr-auto ml-2 my-auto flex-grow-3 mr-3">
                                	<span class="badge bg-primary mt-2 product-list-reference-badge" v-if="minilist == true">{{product.reference}}</span>
                                	<strong class="mr-auto my-auto text-primary" :class="{'d-block': minilist == false}">{{product.name}}</strong>
                                	
						            <small v-if="minilist == false" class="text-muted italic no-margin" v-html="product.short_description"></small>
						            <strong class="text-muted mb-auto">£{{product.trade_price}} <small class="mr-3">Trade Price</small></strong>
						            <div class="d-flex">
						            <a 
                          href="javascript: void(0);"
                          class="badge bg-light bg-soft rounded-pill font-size-11 m-1"
                         v-if="(product.trade_marketplace_defaultcategory !== null) && (showcategories == true)"><img class="rounded-circle avatar-xs" v-if="product.trade_marketplace_defaultcategory.image" :src="`${product.trade_marketplace_defaultcategory.image}`" alt /> <img v-else  src="/images/banner-placeholder.png" alt="" class="rounded-circle avatar-xs"> <span class="text-dark">{{product.trade_marketplace_defaultcategory.name}}</span></a>

                         <a 
                         v-if="(product.trade_marketplace_categories.length >0) && (showcategories == true)"
                          href="javascript: void(0);"
                          class="badge bg-light bg-soft rounded-pill font-size-11 m-1"
                          v-for="category in product.trade_marketplace_categories"><img v-if="category.category.image" class="rounded-circle avatar-xs" :src="`${category.category.image}`" alt /> <img v-else src="/images/banner-placeholder.png" alt="" class="rounded-circle avatar-xs">  <span class="text-dark">{{category.category.name}}</span></a>
                        </div>
						            
                                </div>
                                <div class="mr-auto ml-2 my-auto flex-grow-1 mr-3 d-flex" v-if="showtools == true">
                                	<a class="btn btn-outline-light btn-rounded ml-auto my-auto flex-grow-3 mr-2" href="javascript:;" @click="quickviewproduct = product"><i class="py-3 px-2 mdi mdi-eye-outline"></i></a>
                                <a class="btn btn-outline-light btn-rounded ml-auto my-auto" href="javascript:;" @click="removeResource(product)"><i class="py-1 px-2 bx bx-trash-alt"></i></a>
                            	</div>
              
                              </div>
                              </li>
                              

                              <!--<li class="list-group-item py-4 text-center" ><a class="btn btn-outline-light btn-rounded">Load More</a>
                              </li>-->
                            </ul>
                <ul class="list-group product-list w-100 no-shadow" v-if="(product_list.length == 0) && (view == 'list')">
                	  <li class="list-group-item d-flex">
                	  	<i class="bx bx-warning"></i> No Products <span v-if="resource !== ''">assigned to this {{resource}}</span>
                	  </li>
                </ul>
          </div>
          <div class="col-md-12">
          	<div  id="my-table" class="row product-grid px-2 row-eq-height" v-if="(product_list.length !== 0) && (view == 'grid')">

                 <div class="d-flex flex-column product-grid-item mb-2" :class="{'col-md-4' : expanded == 0,'col-md-3' : expanded == 1}" v-for="(product, index) in product_list">

          		
              <TradeProductListCard :product="product" :index="index" :company="product.company" :user_company="$attrs.company" :selected_currency="$attrs.selected_currency" :selected_currency_symbol="$attrs.selected_currency_symbol" :exchange_rates="$attrs.exchange_rates" :currencies="$attrs.currencies"></TradeProductListCard>   	
                 	<!--<b-card no-body class="overflow-hidden news-card shadow-none border-radius-1em border" @mouseenter="hoverIndex = index" @mouseleave="hoverIndex = -1" >

		                    <b-row no-gutters>
		                     <transition name="fade-left">
		                    	<div class="col-md-6 position-absolute z-index-2" v-if="hoverIndex == index"> 
                              	<a id="tooltip-add" v-b-modal.product-info @click="quickviewproduct = product" class="mt-3 ml-3 btn btn-primary font-size-16 btn-rounded ml-auto float-left"><i class="mdi mdi-eye-outline"></i></a>
                              </div>
                          </transition>
                           <transition name="fade-right">
		                    	<div class="col-md-6 position-absolute z-index-2 right-0 d-flex" v-if="hoverIndex == index"> 
                              	<span class="badge badge-primary bg-primary font-size-12  ml-auto my-4 mr-1" v-b-tooltip.hover.left title=""><a @click="copyLink(product.reference)" href="javascript:;" class="text-white">{{product.reference}}</a></span>
                              </div>
                          </transition>

		                      <b-col lg="12">
		                        <a class="" :href="'/trade/profile/'+product.company.nickname+'/'+product.url_rewrite"> 
		                        	<b-card-img-lazy v-if="(product.cover_image) && ('gallery' in product.cover_image)"class="rounded-0" :src="'https://'+product.company.nickname+storageurl+product.cover_image.gallery.replaceAll('//', '/')+'?s='+product.company.nickname" ></b-card-img-lazy>
                              	<b-card-img-lazy v-else-if="(product.cover_image) && ('thumb' in product.cover_image)"class="rounded-0" :src="'https://'+product.company.nickname+storageurl+product.cover_image.thumb.replaceAll('//', '/')+'?s='+product.company.nickname" ></b-card-img-lazy>
                              	<b-card-img-lazy v-else-if="(product.cover_image) && ('src' in product.cover_image)"class="rounded-0" :src="'https://'+product.company.nickname+storageurl+product.cover_image.src.replaceAll('//', '/')+'?s='+product.company.nickname" ></b-card-img-lazy>
		                        		<b-card-img-lazy v-else :src="'/images/product-placeholder.png'" alt="Image" class="rounded-0"></b-card-img-lazy>
		                      </a>
		                     <transition name="fade-right">
		                        <div class="mt-auto mb-3 mx-2 d-flex position-absolute bottom-0 right-0 left-0" v-if="hoverIndex == index">
                              	<a id="tooltip-add"  @click="selectedCategory = list, showStoneDetails = 1"class="mr-2 ml-auto btn btn-primary font-size-16 btn-rounded"><i class="mdi mdi-heart-outline"></i></a>
                              	<a id="tooltip-add"   @click="addToCart(product, 1)" class="mr-3 ml-0 btn btn-primary font-size-16 btn-rounded"><i class="mdi mdi-shopping-outline"></i></a>
              					</div>
              				</transition>
		                      </b-col>
		                      <b-col lg="12">
		                      	<a class="" style="color: unset;" :href="'/trade/profile/'+product.company.nickname+'/'+product.url_rewrite"> 
		                        <b-card-body>
		                        	<h4 class="card-title d-flex" :class="{'text-truncate font-size-14' : truncate_titles == true}">{{product.name}}</h4>
		                          <b-card-text>
		                             <p class="text-muted paragraph-truncate-line2" v-html="product.short_description"></p>
		                           

		                          </b-card-text>
		                          
		                          <div class="d-flex flex-row">
		                        <span class="badge badge-light my-auto mr-auto bg-light p-2 font-size-12 border-radius-1em" v-if="product.tax_rule == null">
		                         	£ {{product.trade_price}}
		                         </span>
		                         <span class="badge badge-light my-auto mr-auto bg-light p-2 font-size-12 border-radius-1em" v-else>
		                         	£ {{priceIncVat(product.trade_price, product.tax_rule)}}
		                         </span>
		                         <transition name="fade-right">
		                         <p  v-if="hoverIndex == index" class="text-muted float-right ml-auto my-auto">
			                    <span class="bx bx-star font-size-14 text-warning"></span>
			                    <span class="bx bx-star font-size-14 text-warning ml-1"></span>
			                    <span class="bx bx-star font-size-14 text-warning ml-1"></span>
			                    <span class="bx bx-star font-size-14 text-warning ml-1"></span>
			                    <span class="bx bx-star font-size-14 ml-1"></span>
			                  </p>
			              			</transition>
			              		</div>
		                        </b-card-body>
		                      </a>
		                      </b-col>
		                    </b-row>
		                  </b-card>-->
                    	<!--<div class="marketplace-pg border shadow-sm product-grid-item-container bg-white flex-column d-flex">
                         <div class="row">
                 						<div class="col-md-12 position-absolute"> 
                              	<a id="tooltip-add"  @click="quickviewproduct = product" class="mt-3 ml-3 btn btn-primary font-size-16 btn-rounded ml-auto float-left"><i class="mdi mdi-eye-outline"></i></a>
                              </div>
                            </div>
                           	<img v-if="product.cover_image" class="w-100 product-grid-img" :src="product.cover_image">
                           	<img v-else class="w-100 product-grid-img" src="/images/product-placeholder.png">
                          <div class="mt-3 px-3">
                           	<strong class="mr-auto my-auto text-primary">{{product.name}}</strong>
                           	<span class="badge mt-3 bg-primary position-absolute product-grid-reference-badge">{{product.reference}}</span>
								            <small class="text-muted italic no-margin" v-html="(product.short_description).slice(0,150)+'...'"></small>
                    			</div>
                                <div class="my-1 px-3">
                                	<strong class="text-muted mb-auto">£{{product.trade_price}} <small class="mr-3">Trade Price</small></strong>
                                </div>
                        <b-row class="px-1 mt-1">
                        <b-col md="4" v-if="product.trade_marketplace_defaultcategory !== null">  
	                         <a 
	                          href="javascript: void(0);"
	                          class="badge bg-light bg-soft rounded-pill font-size-11 m-1"
	                         v-if="product.trade_marketplace_defaultcategory !== null"><img class="rounded-circle avatar-xs" v-if="product.trade_marketplace_defaultcategory.image" :src="`${product.trade_marketplace_defaultcategory.image}`" alt /> <img v-else  src="/images/banner-placeholder.png" alt="" class="rounded-circle avatar-xs"> <span class="text-dark">{{product.trade_marketplace_defaultcategory.name}}</span></a>
                       </b-col>

                           <b-col md="4" v-if="product.brand !== null">
	                         	<a 
	                          href="javascript: void(0);"
	                          class="badge bg-light bg-soft rounded-pill font-size-11 m-1"
	                         v-if="product.brand != null"><img v-if="product.brand.logo" class="rounded-circle avatar-xs" :src="`${product.brand.logo}`" alt />  <img v-else  src="/images/banner-placeholder.png" alt="" class="rounded-circle avatar-xs"> <span class="text-dark">{{product.brand.name}}</span></a>
                         </b-col>
                       </b-row>

                        <div class="mt-auto mb-3 d-flex">
                              	<a id="tooltip-add"  @click="selectedCategory = list, showStoneDetails = 1"class="ml-3 mr-auto btn btn-primary font-size-16 btn-rounded"><i class="mdi mdi-eye-outline"></i></a>
                              	<a id="tooltip-add"  @click="selectedCategory = list, showStoneDetails = 1"class="mr-2 ml-auto btn btn-primary font-size-16 btn-rounded"><i class="mdi mdi-heart-outline"></i></a>
                              	<a id="tooltip-add"  @click="showQuantitySelector = index"class="mr-3 ml-0 btn btn-primary font-size-16 btn-rounded"><i class="mdi mdi-shopping-outline"></i></a>
                              	<b-card class="quantity-card bg-light" v-if="showQuantitySelector == index">
                              		<b-row>
                              			<b-col md="12">
                              		<a class="float-right btn btn-outline-light btn-rounded" @click="showQuantitySelector = null" ><i class="bx bx-x font-size-14"></i></a>
                              			</b-col>
                              		</b-row>
                              		<b-row>
                              			<b-col md="12">
		                              		<b-form-group label="Add Quantity">
		                              			<b-form-input type="number" v-model="quantity">
		                              			</b-form-input>
		                              			<a lass="mr-3 ml-0 btn btn-primary mt-2 btn-rounded ml-auto btn-block">Add to Cart <i class="mdi mdi-shopping-outline"></i></a>
		                              		</b-form-group>
		                              	</b-col>
		                              </b-row>
                              		
                              	</b-card>
              					</div>
                              	</div>-->
                              </div>
                              

                              <!--<li class="list-group-item py-4 text-center" ><a class="btn btn-outline-light btn-rounded">Load More</a>
                              </li>-->
                            </div>
                <ul class="list-group product-list w-100 no-shadow"  v-if="(product_list.length == 0) && (view == 'grid')">
                	  <li class="list-group-item d-flex">
                	  	<i class="bx bx-warning"></i> No Products <span v-if="resource !== ''">assigned to this {{resource}}</span>
                	  </li>
                </ul>
          </div>
          <!--<div class="product-modal d-flex flex-column justify-content-center" v-if="quickviewproduct !== ''">
          	<div class=" d-flex flex-column px-2 product-grid-item mb-2 mx-auto col-md-8 col-sm-10">
                              	<div class="border shadow-sm product-grid-item-container bg-white row">
                              		<div class="col-md-6 m-0 p-0">
                              	<img v-if="quickviewproduct.cover_image" class="w-100 quickviewproduct-grid-img" :src="quickviewproduct.cover_image">
                              	<img v-else class="w-100 product-list-img" src="/images/product-placeholder.png"></div>
                              	<div class="col-md-6 d-flex flex-column justify-content-center">
                              		<a href="javascript:;" @click="quickviewproduct = ''" class="btn btn-rounded btn-outline-light float-right mr-2 mt-2 position-absolute close-modal"><i class="mdi mdi-close noti-icon font-size-20"></i></a>
                              	                                <div class="my-3 px-3">
                                	<h2 class="mr-auto my-auto text-primary">{{quickviewproduct.name}}</h2>
                                	<span class="badge badge-primary">{{quickviewproduct.reference}}</span>
						            <p class="text-muted italic no-margin" v-html="quickviewproduct.short_description"></p>
                                </div>
                                <div class="my-1 px-3"  v-if="marketplace == ('company' || 'public')">
                                	<strong class="text-muted mb-auto">£{{quickviewproduct.sales_price}} <small class="mr-3">exc. VAT</small></strong>
                                </div>
                                <div class="my-1 px-3"  v-if="marketplace == ('company' || 'public')">
                                	<strong class="text-muted mb-auto"> £{{priceIncVat(quickviewproduct.sales_price, quickviewproduct.tax_rule.percentage)}} <small class="mr-3">inc. VAT</small></strong>
                                </div>
                                <div class="my-1 px-3" v-if="marketplace == 'trade'">
                                	<strong class="text-muted mb-auto">£{{quickviewproduct.trade_price}} <small class="mr-3">Trade Price</small></strong>
                                </div>
                                <div class="d-flex grab-row">
											            <a 
					                          href="javascript: void(0);"
					                          class="badge bg-light bg-soft rounded-pill font-size-11 m-1"
					                         v-if="quickviewproduct.trade_marketplace_defaultcategory !== null"><img class="rounded-circle avatar-xs" v-if="quickviewproduct.trade_marketplace_defaultcategory.image" :src="`${quickviewproduct.trade_marketplace_defaultcategory.image}`" alt /> <img v-else  src="/images/banner-placeholder.png" alt="" class="rounded-circle avatar-xs"> <span class="text-dark">{{quickviewproduct.trade_marketplace_defaultcategory.name}}</span></a>

					                         <a 
					                         v-if="quickviewproduct.trade_marketplace_categories.length >0"
					                          href="javascript: void(0);"
					                          class="badge bg-light bg-soft rounded-pill font-size-11 m-1"
					                          v-for="category in quickviewproduct.trade_marketplace_categories"><img v-if="category.category.image" class="rounded-circle avatar-xs" :src="`${category.category.image}`" alt /> <img v-else src="/images/banner-placeholder.png" alt="" class="rounded-circle avatar-xs">  <span class="text-dark">{{category.category.name}}</span></a>
					                        </div>
                                <div class="my-3 px-3">
                                	<a class="btn btn-outline-light btn-rounded ml-auto my-auto flex-grow-3" href="javascript:;"><i class="bx bx-pencil"></i></a>
                                <a class="btn btn-outline-light btn-rounded ml-auto my-auto flex-grow-3" href="javascript:;" @click="removeResource(quickviewproduct)"><i class="bx bx-trash-alt"></i></a>
              					</div>
              				</div>
                              	</div>
                              </div>
          </div>-->
          <b-modal id="product-info" centered size="xl" body-class="p-0 my-0 overflow-hidden" content-class="border-radius-1em p-0 border-none" header-class="border-none position-absolute right-0" hide-footer @close="qvModalClosed">
          	<!--<div v-if="quickviewproduct.cover_image" style="height: 600px; background-repeat: no-repeat;
    background-position: center; background-size: contain;" :style="'background-image: url(' + storageurl + quickviewproduct.cover_image.src.replace('//','/') + ')'"></div>
    <div v-else style="height: 600px; background-repeat: no-repeat;
    background-position: center; background-size: contain;" :style="'background-image: url(/images/product-placeholder.png)'"></div>-->
    <b-card v-if="quickviewproduct !== ''" no-body class="mb-0">
    	<b-row no-gutters>
      <b-col lg="6" @mouseenter="showQVGallery = true" @mouseleave="showQVGallery = false" class="p-0">
      	<transition name="fade-left">
		                    	<div class="col-md-12 position-absolute z-index-2" v-if="showQVGallery == true"> 
                              	<!--<a id="tooltip-add" v-b-modal.product-info @click="quickviewproduct = product" class="mt-3 ml-3 btn btn-primary font-size-16 btn-rounded ml-auto float-left"><i class="mdi mdi-eye-outline"></i></a>-->
                              	<div class="d-block overflow-hidden px-1 position-relative ml-2" style="max-height: 626px; width: 10%;" v-if="quickviewproduct.images !== null">
														    	<ImageSwiper :swiperOptions="imgswiperOptions" :items="quickviewproduct.images" @image-selected="imageSelected" ></ImageSwiper>
														    	</div>
                              </div>
                          </transition>
                          <div v-if="viewImage == null">
          											<b-card-img v-if="quickviewproduct.cover_image" class="w-100 quickviewproduct-grid-img" :src="'https://'+product.company.nickname+storageurl + quickviewproduct.cover_image.src.replace('//','/')"></b-card-img>
                              	<b-card-img v-else class="w-100 quickviewproduct-grid-img" src="/images/product-placeholder.png"></b-card-img>
                              </div>
                              <div v-else>
                              	<b-card-img class="w-100 quickviewproduct-grid-img" :src="'https://'+product.company.nickname+storageurl + viewImage.replace('//', '/')"></b-card-img>
                              </div>
                              </b-col>
               <b-col lg="6" class="d-flex align-items-center">
                              	<b-card-body>
                              		<span class="badge badge-primary bg-primary font-size-12">{{quickviewproduct.reference}}</span>
                   <h2 class="mr-auto my-auto text-primary">{{quickviewproduct.name}}</h2>
                                	
						            <p class="text-muted italic no-margin" v-html="quickviewproduct.short_description"></p>
                          
                                <div class="my-1 px-3"  v-if="marketplace == ('company' || 'public')">
                                	<strong class="text-muted mb-auto">£{{quickviewproduct.sales_price}} <small class="mr-3">exc. VAT</small></strong>
                                </div>
                                <div class="my-1 px-3"  v-if="marketplace == ('company' || 'public')">
                                	<strong class="text-muted mb-auto"> £{{priceIncVat(quickviewproduct.sales_price, quickviewproduct.tax_rule.percentage)}} <small class="mr-3">inc. VAT</small></strong>
                                </div>
                                <div class="my-1 px-3" v-if="marketplace == 'trade'">
                                	<strong class="text-muted mb-auto">£{{quickviewproduct.trade_price}} <small class="mr-3">Trade Price</small></strong>
                                </div>
                                <div class="d-flex grab-row">
											            <a 
					                          href="javascript: void(0);"
					                          class="badge bg-light bg-soft rounded-pill font-size-11 m-1"
					                         v-if="quickviewproduct.trade_marketplace_defaultcategory !== null"><img class="rounded-circle avatar-xs" v-if="quickviewproduct.trade_marketplace_defaultcategory.image" :src="`${quickviewproduct.trade_marketplace_defaultcategory.image}`" alt /> <img v-else  src="/images/banner-placeholder.png" alt="" class="rounded-circle avatar-xs"> <span class="text-dark">{{quickviewproduct.trade_marketplace_defaultcategory.name}}</span></a>

					                         <a 
					                         v-if="quickviewproduct.trade_marketplace_categories.length >0"
					                          href="javascript: void(0);"
					                          class="badge bg-light bg-soft rounded-pill font-size-11 m-1"
					                          v-for="category in quickviewproduct.trade_marketplace_categories"><img v-if="category.category.image" class="rounded-circle avatar-xs" :src="`${category.category.image}`" alt /> <img v-else src="/images/banner-placeholder.png" alt="" class="rounded-circle avatar-xs">  <span class="text-dark">{{category.category.name}}</span></a>
					                        </div>
					                       
					                        <div class="d-flex mb-2">
					                        	<a id="tooltip-add" @click="quantity--" class="btn btn-outline-light font-size-16 btn-rounded mr-3 my-auto"><i class="mdi mdi-minus"></i></a>
					                        	<b-form-group label="" class="mx-auto">
													              <b-form-input type="number" v-model="quantity" /></b-form-group>
													           
													            
													            <a id="tooltip-add" @click="quantity++" class="btn btn-outline-light font-size-16 btn-rounded my-auto mr-2"><i class="mdi mdi-plus"></i></a>
					                        	<a class="btn btn-primary my-auto btn-rounded w-25" @click="addToCart(quickviewproduct, quantity)" >Add to Cart</a>
					                        </div>
					                         <div class="w-100">
					                        	<a class="btn btn-primary btn-block btn-rounded">Product Details</a>
					                        </div>
					                      </b-card-body>
					                    </b-col>
					                  </b-row>
					                      </b-card>
          </b-modal>
     </div>
</template>