<template>
  <div>
    <b-card class="card overflow-hidden border-radius-1em shadow-sm seller-profile-card" no-body @mouseenter="hoverIndex = index" @mouseleave="hoverIndex = -1">
          <div v-if="product.profile_banners !== null" class="bg-soft bg-light" :style="'background-size: cover; background-position: center center; background-image: url(' + ('https://' + product.nickname + '.s3.eu-west-2.amazonaws.com/' + product.profile_banners.public +'?s='+product.nickname) + ')'">
            <div class="row">
              <transition name="fade-left">
                          <div class="col-md-12 position-absolute z-index-2" v-if="hoverIndex == index"> 
                                <router-link :to="'/seller/'+product.nickname+'/profile'" class="mt-3 ml-3 btn btn-primary font-size-16 btn-rounded ml-auto float-left" v-b-tooltip.hover.right title="View Company" ><i class="mdi mdi-eye-outline"></i></router-link>
                              </div>
                          </transition>
                           
                       <div class="col-12">
                        <router-link :to="'/seller/'+product.nickname+'/profile'">
                          <div  style="min-height: 240px;"></div>
                        </router-link>
                 <transition name="fade-right">
                            <div class="mt-auto mb-3 mx-2 d-flex position-absolute bottom-0 right-0 left-0" v-if="hoverIndex == index">
                                <b-form-rating style="width: 30%;" id="rating-inline" readonly variant="warning" class="bg-transparent pr-4 ml-auto" inline no-border size="sm" :value="product.reviews_avg_rating"></b-form-rating> 
                        </div>
                      </transition>
              </div>
            </div>
          </div>
           <div v-else class="bg-soft bg-light">
            <div class="row">
              <transition name="fade-left">
                          <div class="col-md-12 position-absolute z-index-2" v-if="hoverIndex == index"> 
                                <router-link :to="'/seller/'+product.nickname+'/profile'" class="mt-3 ml-3 btn btn-primary font-size-16 btn-rounded ml-auto float-left" v-b-tooltip.hover.right title="View Company" ><i class="mdi mdi-eye-outline"></i></router-link>
                              </div>
                          </transition>
                           
                       <div class="col-12">
                        <router-link :to="'/seller/'+product.nickname+'/profile'">
                          <div  style="min-height: 240px;"></div>
                        </router-link>
                 <transition name="fade-right">
                            <div class="mt-auto mb-3 mx-2 d-flex position-absolute bottom-0 right-0 left-0" v-if="hoverIndex == index">
                                <b-form-rating style="width: 30%;" id="rating-inline" readonly variant="warning" class="bg-transparent pr-4 ml-auto" inline no-border size="sm" :value="product.reviews_avg_rating"></b-form-rating> 
                        </div>
                      </transition>
              </div>
            </div>
          </div>
          <b-card-body class="card-body pt-0">
            <div class="row">
              <div class="col-sm-12 pb-4 text-center">
                <div class="avatar-md profile-user-wid mb-2 mx-auto" style="margin-top: -45px !important;">
                  <img
                    v-if="product.logo" :src="product.logo.includes(storageurl) == false ? ('https://' + product.nickname + '.s3.eu-west-2.amazonaws.com' + product.logo+'?s='+product.nickname) : product.logo+'?s='+product.nickname"
                    alt
                    class="img-thumbnail rounded-circle"
                  />
                   <img
                    v-else 
                    src="/images/product-placeholder.png"
                    alt
                    class="img-thumbnail rounded-circle"
                  />
                </div>
                <h4 class="mb-0 text-truncate">{{product.name}}</h4>
                <p class="text-muted mb-0 text-truncate"><span v-if="product.county !== null">{{product.county.name}},</span> {{product.country.name}}</p>
              </div>
              <div class="col-md-6 d-flex">
                <span class="badge badge-light border-radius-1em bg-light text-dark font-size-11 lh-16 my-auto p-2">
                  {{product.type.name}}
                </span>
              </div>
              <div class="col-md-6 d-flex">
                <!--<span class="badge badge-light border-radius-1em bg-light text-dark font-size-11 lh-16 my-auto p-2 ml-auto">
                  100 <i class="bx bx-tag"></i>
                </span>-->
                <span class="badge badge-light border-radius-1em bg-light text-dark font-size-11 lh-16 my-auto p-2 ml-auto">
                  {{product.listings.length}} Stores and Locations <i class="bx bx-buildings"></i>
                </span>
              </div>

            </div>
          </b-card-body>
        </b-card>
  </div>
</template>

<script type="text/javascript">
  export default{
    data(){
      return{
        hoverIndex: -1,
      }
    },
    props: {
      product: {
        type: Object
      }
    }
  }

</script>