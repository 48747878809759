<template>
    <div class="table-responsive">
<table class="table table-centered table-hover">

                <thead class="thead-light">
                  <tr>
                    <th scope="col" class="text-center"><a href="javascript: void(0);">#</a></th>
                    <th scope="col"><a href="javascript: void(0);">Order No.</a></th>
                    <th scope="col">Customer</th>
                    <th scope="col">Source</th>
                    <th scope="col">Date</th>
                    <th scope="col">Total Exc.</th>
                    <th scope="col">Total Inc.</th>
                    <th scope="col">Paid</th>
                    <th scope="col">Status</th>
                  </tr>
                </thead>
               
                <tbody>
                  
                  <tr v-for="list in orderData.data" :key="list.id" valign="middle">
                     <td>
                    <div class="text-center" >
                       <b-form-checkbox class="select-row-btn" v-model="selectedids" :value="list.id" button-variant="light" button>

                          <span class="avatar-title rounded-circle bg-light text-primary avatar-sm">{{(list.reference).slice(0,1)}}</span></b-form-checkbox>
                    </div> 
                    </td>
                   
                    <td>
                      <a :href="'/order/details/'+list.uid+''"><span>#{{list.reference}}</span></a>
                    </td>
                   
                    
                    <td>
                      <div v-if="list.trade_orders.length > 0">
                        <a :href="'/seller/profile/'+list.trade_orders[0].order.customer.nickname+''"><h6 class="mb-0">{{list.trade_orders[0].order.customer.name}}</h6>
                        {{list.trade_orders[0].order.customer.email}}</a>
                      </div>
                      <div v-if="list.customer_orders.length > 0">
                         <a :href="'/customer/details/'+list.customer_orders[0].order.customer.id+''"><h6 class="mb-0">{{list.customer_orders[0].order.customer.name}} {{list.customer_orders[0].order.customer.lastname}}</h6>
                        {{list.customer_orders[0].order.customer.email}}</a>
                      </div>
                      <div v-if="list.website_orders.length > 0">
                         <a :href="'/customer/details/'+list.website_orders[0].order.customer.id+''"><h6 class="mb-0">{{list.website_orders[0].order.customer.name}} {{list.website_orders[0].order.customer.lastname}}</h6>
                        {{list.website_orders[0].order.customer.email}}</a>
                      </div>
                      <div v-if="list.pos_orders.length > 0">
                         <a v-if="list.pos_orders[0].order.customer !== null" :href="'/customer/details/'+list.pos_orders[0].order.customer.id+''"><h6 class="mb-0">{{list.pos_orders[0].order.customer.name}} {{list.pos_orders[0].order.customer.lastname}}</h6>
                        {{list.pos_orders[0].order.customer.email}}</a>
                        <a v-else-if="list.pos_orders[0].order.customer == null" 
                        href="javascript:;"><h6 class="mb-0">No Customer Data Collected</h6></a>
                      </div>
                      
                    </td>
                     <td>
                      <div v-if="list.trade_orders.length > 0">
                       <span class="font-size-12 badge badge-light bg-light text-dark"  v-b-tooltip.hover title="Order from Trade Marketplace"><i class="bx bx-transfer-alt text-primary"></i> Trade Marketplace</span>
                      </div>
                      <div v-if="list.customer_orders.length > 0">
                        <span class="font-size-12 badge bg-primary bg-soft text-dark"  v-b-tooltip.hover title="Order from Gemesys Public Marketplace"><i class="bx bxs-user text-dark"></i> Public Marketplace</span>
                      </div>
                      <div v-if="list.website_orders.length > 0">
                        <span class="font-size-12 badge bg-peach-soft text-primary" v-b-tooltip.hover title="Order from Your Website"><i class="bx bx-globe text-primary"></i> Website</span>
                      </div>
                      <div v-if="list.pos_orders.length > 0">
                        <span class="font-size-12 badge bg-pos text-primary" v-b-tooltip.hover title="Order from Your Point of Sale"><i class="bx bx-shopping-bag text-primary"></i> In Store</span>
                      </div>
                    </td>
                     <td>
                     <i class="bx bx-calendar"></i> {{getDate(list.created_at)}}
                    </td>
                    <td>
                      
                      {{getCurrencySymbol(list.payment_currency_id)}}{{parseFloat(list.total_order_exc).toFixed(2)}}
                    </td>
                    <td>
                      {{getCurrencySymbol(list.payment_currency_id)}}{{parseFloat(list.total_order_inc).toFixed(2)}}
                     
                    </td>
                     <td class="text-center">
                      <i v-if="list.order_paid == 1" class="bx bx-check-circle text-success font-size-18 mx-auto"></i>
                      <i v-else class="bx bx-x-circle text-danger font-size-18 mx-auto"></i>
                    </td>
                    <td>
                       <div v-if="list.order_cancelled == true">
                        <span class="badge bg-soft font-size-12 bg-danger" > Order Cancelled</span>
                       </div>
                       <div v-else-if="list.status !== null">
                        <span class="badge bg-soft font-size-12" :style="{'background-color': list.status.color}" > {{list.status.name}}</span>
                       </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
      
</template>
<script type="text/javascript">

  import DateTime from 'luxon/src/datetime.js';
import Interval from 'luxon/src/interval.js';
  export default{
      components: {DateTime, Interval},
      props: {
        orderData: {
          type: Object
        }
      },
      data(){
        return {
          selectedids: [],
        }
      },
      methods: {
        getCurrencySymbol(currency_id){
          var symbol = this.$attrs.currencies.filter(item => item.id == currency_id);
          if(symbol.length !== 0){
            return symbol[0].symbol;
          }
     },
        getDate(date){
        var createdOn = DateTime.fromISO(date, { zone: 'utc'});
        console.log(createdOn);
        return createdOn.day+' '+createdOn.monthLong+' '+createdOn.weekYear+'';
        },
         filterSelected(id){
        if(this.selectedids.length > 0){
          var exists = this.selectedids.filter(item => item == id);
          if(exists.length > 0){
            return true;
          }else{
            return false
          }
        }else{
          return false;
        }
      },
      }
  }
</script>