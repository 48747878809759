export const menuItems = [
     {
        id: 6,
        label: 'Jewellery',
        isUiElement: true,
        subItems: [
            {
                id: 6,
                label: 'Modern',
                image: '/images/category/modern-jewellery.jpg',
                isUiElement: true,
                link: '/category/modern-jewellery',
                subItems: [
                    {
                        id: 8,
                        label: 'Rings',
                        link: '/category/rings',
                        parentId: 6
                    },
                    {
                        id: 9,
                        label: 'Earrings',
                        link: '/category/earrings',
                        parentId: 6
                    },
                    {
                        id: 10,
                        label: 'Necklaces',
                        link: '/category/necklaces',
                        parentId: 6
                    },
                    {
                        id: 11,
                        label: 'Bracelets',
                        link: '/category/bracelets',
                        parentId: 6
                    },
                    {
                        id: 12,
                        label: 'Pendants',
                        link: '/category/pendants',
                        parentId: 6
                    },
                    {
                        id: 13,
                        label: 'Chains',
                        link: '/category/chains',
                        parentId: 6
                    },
                    {
                        id: 14,
                        label: 'Tiaras',
                        link: '/category/tiaras',
                        parentId: 6
                    },
                    {
                        id: 15,
                        label: 'Bangles',
                        link: '/category/bangles',
                        parentId: 6
                    },
                    {
                        id: 15,
                        label: 'All Categories',
                        link: '/categories',
                        parentId: 6
                    }
                ]
            },
            {
                id: 6,
                label: 'Handmade',
                image: '/images/category/handmade-jewellery.jpg',
                link: '/category/handmade-jewellery',
                isUiElement: true,
                subItems: [
                    {
                        id: 8,
                        label: 'Rings',
                        link: '/category/handmade-rings',
                        parentId: 6
                    },
                    {
                        id: 9,
                        label: 'Earrings',
                        link: '/category/handmade-earrings',
                        parentId: 6
                    },
                    {
                        id: 10,
                        label: 'Necklaces',
                        link: '/category/handmade-necklaces',
                        parentId: 6
                    },
                    {
                        id: 11,
                        label: 'Bracelets',
                        link: '/category/handmade-bracelets',
                        parentId: 6
                    },
                    {
                        id: 12,
                        label: 'Pendants',
                        link: '/category/handmade-pendants',
                        parentId: 6
                    },
                    {
                        id: 13,
                        label: 'Chains',
                        link: '/category/handmade-chains',
                        parentId: 6
                    },
                    {
                        id: 14,
                        label: 'Tiaras',
                        link: '/category/handmade-tiaras',
                        parentId: 6
                    },
                    {
                        id: 15,
                        label: 'Bangles',
                        link: '/category/handmade-bangles',
                        parentId: 6
                    }
                ]
            },
            {
                id: 6,
                label: 'Vintage',
                image: '/images/category/vintage-jewellery.jpg',
                link: '/category/vintage-jewellery',
                isUiElement: true,
                subItems: [
                    {
                        id: 8,
                        label: 'Rings',
                        link: '/category/vintage-rings',
                        parentId: 6
                    },
                    {
                        id: 9,
                        label: 'Earrings',
                        link: '/category/vintage-earrings',
                        parentId: 6
                    },
                    {
                        id: 10,
                        label: 'Necklaces',
                        link: '/category/vintage-necklaces',
                        parentId: 6
                    },
                    {
                        id: 11,
                        label: 'Bracelets',
                        link: '/category/vintage-bracelets',
                        parentId: 6
                    },
                    {
                        id: 12,
                        label: 'Pendants',
                        link: '/category/vintage-pendants',
                        parentId: 6
                    },
                    {
                        id: 13,
                        label: 'Chains',
                        link: '/category/vintage-chains',
                        parentId: 6
                    },
                    {
                        id: 14,
                        label: 'Tiaras',
                        link: '/category/vintage-tiaras',
                        parentId: 6
                    },
                    {
                        id: 15,
                        label: 'Bangles',
                        link: '/category/vintage-bangles',
                        parentId: 6
                    }
                ]
            },
            {
                id: 6,
                label: 'Antique',
                image: '/images/category/antique-jewellery.jpg',
                isUiElement: true,
                link: '/category/antique-jewellery',
                subItems: [
                    {
                        id: 8,
                        label: 'Rings',
                        link: '/category/antique-rings',
                        parentId: 6
                    },
                    {
                        id: 9,
                        label: 'Earrings',
                        link: '/category/antique-earrings',
                        parentId: 6
                    },
                    {
                        id: 10,
                        label: 'Necklaces',
                        link: '/category/antique-necklaces',
                        parentId: 6
                    },
                    {
                        id: 11,
                        label: 'Bracelets',
                        link: '/category/antique-bracelets',
                        parentId: 6
                    },
                    {
                        id: 12,
                        label: 'Pendants',
                        link: '/category/antique-pendants',
                        parentId: 6
                    },
                    {
                        id: 13,
                        label: 'Chains',
                        link: '/category/antique-chains',
                        parentId: 6
                    },
                    {
                        id: 14,
                        label: 'Tiaras',
                        link: '/category/antique-tiaras',
                        parentId: 6
                    },
                    {
                        id: 15,
                        label: 'Bangles',
                        link: '/category/antique-bangles',
                        parentId: 6
                    }
                ]
            },
        ]
    },
    {
        id: 70,
        
        label: 'menuitems.public.watches.text',
        link: '/category/watches'
    },
    {
        id: 25,
        label: 'menuitems.public.antiques.text',
        link: '/category/antiques'
    },
    
    {
        id: 97,
        label: 'menuitems.public.giftware.text',
        
    },
    {
        id: 97,
        label: 'Sellers',
        link: '/sellers'
    },
    {
        id: 97,
        label: 'menuitems.public.news.text',
        
    },
    {
        id: 97,
        label: 'menuitems.public.contact.text',
        link: '/contact'
    }
];

