<script type="text/javascript">
	import Multiselect from 'vue-multiselect'
	export default{
		data() {
			return{
				storageurl: 'http://gemesys-dev.s3.eu-west-2.amazonaws.com/',
				success_message: false,
      			success_messagetext: 'Congratulations, your action was successful',
      			error_reponse: '',
      			error_message: false,
				loadingForm: false,
				status: 'seller-response',
				products: [],
				productsearch: '',
				support_type: '',
				support_order: '',
				support_order_company_id: '',
				support_company_order: '',
				support_company_order_product: '',
				support_product: '',
				support_proudct_company: '',
				support_contact: '',
				support_listings: [],
				support_listing: '',
				support_types: [
					{
						text: 'Order Enquiry', value: 'order'
					},
					{
						text: 'Order Cancellation', value: 'cancellation'
					},
					{
						text: 'Delivery Issue', value: 'delivery'
					},
					{
						text: 'Product Enquiry', value: 'product'
					},
				],
				support_contact_options: [
					{
						text: 'Email', value: 'email', disabled: false,
					},
					{
						text: 'Phone', value: 'phone', disabled: false,
					},
					{
						text: 'Chat', value: 'Chat', disabled: true,
					},
					{
						text: 'SMS', value: 'SMS', disabled: true,
					},
					{
						text: 'WhatsApp', value: 'WhatsApp', disabled: true,
					},
				]
			}
		},
		components: { Multiselect },
		props: {
			company: {
				type: Object
			},
			token: {
				type: Object
			},
			user: {
				type: Object
			},
			from: {
				type: Object,
				default: 'customer'
			},
			orders: {
				type: Object,
			}
		},
		mounted(){
			this.recaptcha();
		},
		methods: {
			orderSellers(){
				axios.get('/api/company/trade/order/'+this.support_order.id+'/sellers', {headers: {
					'Authorization' : 'Bearer '+this.user.api_token
				}}).then(response => {
					this.support_listings = response.data;
				}).catch(error => {

				});
			},
			loadSupportOptions(){
				this.$emit('load-orders');
			},
			async recaptcha() {
     			 // (optional) Wait until recaptcha has been loaded.
		      await this.$recaptchaLoaded()

		      // Execute reCAPTCHA with action "login".
		      const token = await this.$recaptcha('login');
		      document.getElementById('recaptcha').value = token;
		      // Do stuff with the received token.
		    
		    },
			sendEnquiry(){
				
				//var notspam = this.recaptcha();
				this.loadingForm = true;
				var postForm = new FormData(document.getElementById("contact-form"));
				postForm.append('support_type', this.support_type);
				if(this.support_order !== ''){
					postForm.append('support_order', this.support_order.id);
				}
				if(this.support_company_order !== ''){
					postForm.append('support_company_order', this.support_company_order.id);
					postForm.append('support_order_company_id', this.support_company_order.company_id);
				}
				if(this.support_company_order_product !== ''){
					postForm.append('support_company_order_product', this.support_company_order_product.id);
				}
				if(this.support_product !== ''){
					postForm.append('support_product', this.support_product.id);
				}
				if(this.support_product_order !== ''){
					postForm.append('support_proudct_company', this.support_proudct_company.company_id);
				}
				postForm.append('status', this.status);
				postForm.append('support_contact', this.support_contact);
				postForm.append('support_listing', JSON.stringify(this.support_listing));
				axios.post('/api/company/trade/message/create', postForm, {
					headers: {
						'Authorization' : 'Bearer '+this.user.api_token
					}
				}).then(response => {
					this.success_message = true; 
					this.success_messagetext = 'Your support message has been sent';
					let self = this
					setTimeout(function() {
						self.loadingForm = false;
					}, 300);
				}).catch(error => {
                	this.error_reponse = error.response.data.errors;
					this.error_message = true;
					let self = this
					setTimeout(function() {
						self.loadingForm = false;
					}, 300);
				});
			},
		}
	}
</script>

<template>
	<b-overlay :show="loadingForm">
		<b-card class="border-radius-1em border shadow-none">
			<transition name="slide-success" mode="out-in">
                  <b-alert id="success-message" variant="success" :show="success_message"  dismissible @dismissed="success_message = !success_message">
                      <p class="font-size-14">{{success_messagetext}}</p>
                    </b-alert>
                  </transition>

      <transition name="slide-error" mode="out-in">
                    <b-alert id="errors" variant="danger" :show="error_message == true"  dismissible @dismissed="error_message = !error_message">
                      <p class="font-size-14">Oops! Please correct the following errors in your submission</p>
                      <ul>
                        <li v-for="(error, key) in error_reponse">
                          {{error[0]}}
                        </li>
                      </ul>
                    </b-alert>
                    
                  </transition>
		  <b-form id="contact-form" >
		  	<input type="hidden" name="recaptcha" id="recaptcha">
		  	<input type="hidden" name="notification_thread_id" id="notification_thread_id">
		  	<input type="hidden" name="customer_id" id="customer_id" v-model="company.id">
		  	<input type="hidden" name="from" id="from" v-model="from">
		  	<b-row>
		  		<b-col md="12">
		  			<h3>Contact Information</h3>
		  		</b-col>
		  		<b-col>
			    	<b-form-group label="Name">
			    		<b-form-input name="name" class="my-2" placeholder="First Name" type="text" disabled v-model="user.name"></b-form-input>
			    		<input type="hidden" name="name" v-model="user.name">
			    	</b-form-group>
		    	</b-col>
			    <b-col>
			    	<b-form-group label="Last name">
			    		<b-form-input name="lastname" class="my-2" placeholder="Last Name" type="text" disabled v-model="user.lastname"></b-form-input>
			    		<input type="hidden" name="lastname" v-model="user.lastname">
			    	</b-form-group>
			    </b-col>
	    	</b-row>
	    	<b-row>
				<b-col md="12">
			    	<b-form-group label="Email Address">
			    		<b-form-input name="email" class="my-2" placeholder="Email" type="text" disabled v-model="company.email"></b-form-input>
			    		<input type="hidden" name="email" v-model="company.email">
			    	</b-form-group>
			    </b-col>
			    <b-col>
			    	<b-form-group label="Telephone">
			    		<b-form-input name="telephone" class="my-2" placeholder="Telephone" type="text"></b-form-input>
			    	</b-form-group>
			    </b-col>
			    <b-col>
			    	<b-form-group label="Mobile Phone">
			    		<b-form-input name="mobile" class="my-2" placeholder="Mobile Phone" type="text"></b-form-input>
			    	</b-form-group>
			    </b-col>
			</b-row>
			<!--<b-card class="border-radius-1em border shadow-none">
				<b-form-group label="Contact Preferences" >
      				<b-form-checkbox-group 
      				class="d-inline-flex w-100 justify-content-between"
      				v-model="support_contact"
      				:options="support_contact_options"
      			
      				>
      				</b-form-checkbox-group>
      			</b-form-group>
			</b-card>-->
			<h3 class="mt-4">Enquiry Details</h3>
	    	<b-form-group label="Query Type">
	    	<b-form-select :options="support_types" name="query_type" class="form-control" v-model="support_type" @input="loadSupportOptions()">
	    	</b-form-select>
	    	</b-form-group>
	    	<b-form-group v-if="(support_type == 'order' || support_type == 'cancellation' || support_type == 'delivery') && orders.length > 0" label="Select Order:" class="mt-2">
	    		<multiselect v-if="(support_type == 'order' || support_type == 'cancellation' || support == 'delivery') && orders.length > 0" v-model="support_order" track-by="reference" label="reference" :options="orders" :option-height="104" @input="orderSellers()">
                     <!--<template slot="singleLabel" slot-scope="{ option }">{{ option.name }} - {{ option.jobtitle }}</template>
                     <template slot="singleLabel" slot-scope="{ option }"><div class="d-flex w-100 h-100">
                     <div class="avatar-xs mr-4 my-auto" v-if="(support == 'order' || support == 'cancellation') && orders.length > 0" >
                        <span class="avatar-title rounded-circle" >{{(option.reference).slice(0,2)}}</span>
                       
                      </div>
                      <div class="option__desc d-flex flex-row flex-grow-1"><span class="option__title ml-2 my-auto w-100">{{ option.reference }}</span>
                      	<span v-else class="option__title my-auto w-100">Select Order</span>

                      </div>
                      <div class="avatar-xs my-auto" v-if="support_order == ''">
                      <span class="avatar-title rounded-circle bg-light text-primary"><i class="bx bx-chevron-down"></i></span>
                  	 </div>

                      </div></template>-->
                      <template slot="singleLabel" slot-scope="{ option }">
                      <div class="d-flex"><div class="avatar-xs mr-4">
                                        <span class="avatar-title rounded-circle">{{(option.reference).slice(0,2)}}</span>
                                      </div>
                      <div class="option__desc d-flex flex-row"><span class="option__title ml-2 my-auto w-100 px-2">{{ option.reference }}</span>
                      	
                      </div>
                      </div>
                    </template>
                    <template slot="option" slot-scope="{ option }">
                      <div class="d-flex"><div class="avatar-xs mr-4">
                                        <span class="avatar-title rounded-circle">{{(option.reference).slice(0,2)}}</span>
                                      </div>
                      <div class="option__desc d-flex flex-row w-50"><span class="option__title ml-2 my-auto w-100">{{ option.reference }}</span>
                      	
                      </div>
                      </div>
                    </template>
                  </multiselect>
	    	</b-form-group>
	    	<b-form-group v-if="(support_type == 'order' || support_type == 'cancellation' || support_type == 'delivery') && orders.length > 0" label="Select Seller to Message:" class="mt-2">
	    		<multiselect v-if="(support_type == 'order' || support_type == 'cancellation' || support == 'delivery') && support_listings.length > 0" v-model="support_listing" track-by="reference" label="reference" :options="support_listings" :option-height="104" >
                     <!--<template slot="singleLabel" slot-scope="{ option }">{{ option.name }} - {{ option.jobtitle }}</template>
                     <template slot="singleLabel" slot-scope="{ option }"><div class="d-flex w-100 h-100">
                     <div class="avatar-xs mr-4 my-auto" v-if="(support == 'order' || support == 'cancellation') && orders.length > 0" >
                        <span class="avatar-title rounded-circle" >{{(option.reference).slice(0,2)}}</span>
                       
                      </div>
                      <div class="option__desc d-flex flex-row flex-grow-1"><span class="option__title ml-2 my-auto w-100">{{ option.reference }}</span>
                      	<span v-else class="option__title my-auto w-100">Select Order</span>

                      </div>
                      <div class="avatar-xs my-auto" v-if="support_order == ''">
                      <span class="avatar-title rounded-circle bg-light text-primary"><i class="bx bx-chevron-down"></i></span>
                  	 </div>

                      </div></template>-->
                      <template slot="singleLabel" slot-scope="{ option }">
                      <div class="d-flex">
                      								<img v-if="option.company.logo" class="avatar-xs mr-4 rounded-circle border" :src="storageurl + option.company.logo" >
                      								<div v-else class="avatar-xs mr-4">
                                        <span class="avatar-title rounded-circle">{{(option.company.name).slice(0,2)}}</span>
                                      </div>
                      <div class="option__desc d-flex flex-row"><span class="option__title ml-2 my-auto w-100 px-2">{{ option.company.name }}</span>
                      	
                      </div>
                      </div>
                    </template>
                    <template slot="option" slot-scope="{ option }">
                      <div class="d-flex">
																		<img v-if="option.company.logo" class="avatar-xs mr-4 rounded-circle border" :src="storageurl + option.company.logo" >
                      								<div v-else class="avatar-xs mr-4">
                                        <span class="avatar-title rounded-circle">{{(option.company.name).slice(0,2)}}</span>
                                      </div>
                      <div class="option__desc d-flex flex-row w-50"><span class="option__title ml-2 my-auto w-100">{{ option.company.name }}</span>
                      	
                      </div>
                      </div>
                    </template>
                  </multiselect>
	    	</b-form-group>
	    	<b-form-group v-if="(support_type == 'order') && support_company_order !== ''" label="Select Item:" class="mt-3">
	    	<multiselect v-if="(support_type == 'order') && support_company_order !== ''" v-model="support_company_order_product" track-by="reference" label="reference" :options="support_order.company_order.products" :option-height="104" class="underline-select" >
                     <!--<template slot="singleLabel" slot-scope="{ option }">{{ option.name }} - {{ option.jobtitle }}</template>
                     <template slot="singleLabel" slot-scope="{ option }"><div class="d-flex w-100 h-100">
                     <div class="avatar-xs mr-1 my-auto" v-if="(support == 'order' || support == 'cancellation') && orders.length > 0" >
                        <span class="avatar-title rounded-circle" >{{(option.reference).slice(0,2)}}</span>
                       
                      </div>
                      <div class="option__desc d-flex flex-row flex-grow-1"><span class="option__title ml-2 my-auto w-100">{{ option.reference }}</span>
                      	<span v-else class="option__title my-auto w-100">Select Order</span>

                      </div>
                      <div class="avatar-xs my-auto" v-if="support_order == ''">
                      <span class="avatar-title rounded-circle bg-light text-primary"><i class="bx bx-chevron-down"></i></span>
                  	 </div>

                      </div></template>-->
                      <template slot="singleLabel" slot-scope="{ option }">
                      <div class="d-flex"><div class="avatar-xs mr-1" v-if="option.product.cover_image == null">
                                        <span class="avatar-title rounded-circle">{{(option.product.name).slice(0,2)}}</span>
                                      </div>
                                       <img v-else-if="'thumb' in option.product.cover_image" :src="storageurl+option.product.cover_image.thumb" class="avatar-xs mr-1 border rounded-circle">
                                      <img v-else-if="'gallery' in option.product.cover_image" :src="storageurl+option.product.cover_image.gallery" class="avatar-xs mr-1 border rounded-circle">
                                      <img v-else-if="'src' in option.product.cover_image" :src="storageurl+option.product.cover_image.src" class="avatar-xs mr-1 border rounded-circle">
                      <div class="option__desc d-flex flex-row"><span class="option__title ml-2 my-auto w-100 px-2">	<span class="badge badge-primary bg-primary mr-3">{{option.product.reference}}</span> {{ option.product.name }}</span>
                      	
                      </div>
                      </div>
                    </template>
                    <template slot="option" slot-scope="{ option }">
                      <div class="d-flex"><div class="avatar-xs mr-1" v-if="option.product.cover_image == null">
                                        <span class="avatar-title rounded-circle">{{(option.product.name).slice(0,2)}}</span>
                                      </div>
                                      <img v-else-if="'thumb' in option.product.cover_image" :src="storageurl+option.product.cover_image.thumb" class="avatar-xs mr-1 border rounded-circle">
                                      <img v-else-if="'gallery' in option.product.cover_image" :src="storageurl+option.product.cover_image.gallery" class="avatar-xs mr-1 border rounded-circle">
                                      <img v-else-if="'src' in option.product.cover_image" :src="storageurl+option.product.cover_image.src" class="avatar-xs mr-1 border rounded-circle">
                      <div class="option__desc d-flex flex-row"><span class="option__title ml-2 my-auto w-100 px-2">	<span class="badge badge-primary bg-primary mr-3">{{option.product.reference}}</span> {{ option.product.name }}</span>
                      	
                      </div>
                      </div>
                    </template>
                    <!--<template slot="afterList" slot-scope="{ option }">
                    	<a class="btn btn-light btn-block">
                    		Load More Items
                    	</a>
                    </template>-->
                  </multiselect>
	    	</b-form-group>
	    	<b-form-group label="Subject" class="mt-2">
	    		<b-form-input name="subject" class="my-2" placeholder="Subject" type="text"></b-form-input>
	    	</b-form-group>
	    	<b-form-group label="Message">
	    		<b-form-textarea
	    		class="my-2"
			      id="textarea"
			      placeholder="Enter something..."
			      rows="4"
			      name="message"
			    ></b-form-textarea>
	    	</b-form-group>
	    	<a class="btn btn-outline-light btn-rounded btn-block mt-3" @click="sendEnquiry()">Send Message</a>
	    </b-form>
	</b-card>
	</b-overlay>
</template>