<script type="text/javascript">
export default{
  data: function(){
    return{
      showDiamondCharts: true,
      scrolled: false,
      stickyHeader: true,
      fields: [
        {key: 'carats', sortable: true, label: 'Carats'},
        {key: 'round', sortable: false, label: 'Round'},
        {key: 'marquise', sortable: false, label: 'Marquise'},
        {key: 'pear', sortable: false, label: 'Pear'},
        {key: 'oval', sortable: false, label: 'Oval'},
        {key: 'princess', sortable: false, label: 'Princess'},
        {key: 'heart', sortable: false, label: 'Heart'},
        ], 
       items: [
          {
            carats: '.005',
            round: '1.0',
            marquise: '',
            pear: '',
            oval: '',
            princess: '',
            heart: '',
          },
          {
            carats: '.01',
            round: '1.3',
            marquise: '',
            pear: '',
            oval: '',
            princess: '',
            heart: '',
          },
          {
            carats: '.015',
            round: '1.5',
            marquise: '',
            pear: '',
            oval: '',
            princess: '',
            heart: '',
          },
          {
            carats: '.02',
            round: '1.7',
            marquise: '2.5x1.25',
            pear: '',
            oval: '',
            princess: '',
            heart: '',
          },
          {
            carats: '.025',
            round: '1.8',
            marquise: '',
            pear: '',
            oval: '',
            princess: '',
            heart: '',
          },
          {
            carats: '.03',
            round: '2.0',
            marquise: '3x1.5',
            pear: '',
            oval: '',
            princess: '',
            heart: '',
          },
          {
            carats: '.04',
            round: '2.2',
            marquise: '3.5x1.75',
            pear: '',
            oval: '',
            princess: '2x2',
            heart: '',
          },
          {
            carats: '.05',
            round: '2.4',
            marquise: '',
            pear: '',
            oval: '',
            princess: '',
            heart: '',
          },
          {
            carats: '.06',
            round: '2.5',
            marquise: '',
            pear: '4x2',
            oval: '',
            princess: '',
            heart: '',
          },
          {
            carats: '.07',
            round: '2.7',
            marquise: '4x2',
            pear: '',
            oval: '',
            princess: '',
            heart: '',
          },
          {
            carats: '.08',
            round: '2.8',
            marquise: '4.25x2.25',
            pear: '',
            oval: '',
            princess: '',
            heart: '',
          },
          {
            carats: '.09',
            round: '2.9',
            marquise: '',
            pear: '',
            oval: '',
            princess: '',
            heart: '',
          },
          {
            carats: '.10',
            round: '3.0',
            marquise: '4.5x2.5',
            pear: '',
            oval: '',
            princess: '',
            heart: '',
          },
          {
            carats: '.11',
            round: '3.1',
            marquise: '',
            pear: '',
            oval: '',
            princess: '',
            heart: '',
          },
          {
            carats: '.12',
            round: '3.2',
            marquise: '5x2.5',
            pear: '',
            oval: '',
            princess: '',
            heart: '',
          },
          {
            carats: '.14',
            round: '3.3',
            marquise: '',
            pear: '',
            oval: '',
            princess: '3x3',
            heart: '',
          },
          {
            carats: '.15',
            round: '3.4',
            marquise: '',
            pear: '',
            oval: '',
            princess: '',
            heart: '',
          },
          {
            carats: '.16',
            round: '3.5',
            marquise: '',
            pear: '5x3',
            oval: '',
            princess: '',
            heart: '',
          },
          {
            carats: '.17',
            round: '3.6',
            marquise: '5x3',
            pear: '',
            oval: '',
            princess: '',
            heart: '',
          },
          {
            carats: '.18',
            round: '3.7',
            marquise: '',
            pear: '',
            oval: '5x3',
            princess: '',
            heart: '',
          },
          {
            carats: '.20',
            round: '3.8',
            marquise: '6x3',
            pear: '',
            oval: '',
            princess: '',
            heart: '',
          },
          {
            carats: '.22',
            round: '3.9',
            marquise: '',
            pear: '',
            oval: '',
            princess: '3.5x3.5',
            heart: '',
          },
          {
            carats: '.23',
            round: '4.0',
            marquise: '',
            pear: '',
            oval: '',
            princess: '',
            heart: '4x4',
          },
          {
            carats: '.25',
            round: '4.1',
            marquise: '',
            pear: '',
            oval: '',
            princess: '',
            heart: '',
          },
          {
            carats: '.30',
            round: '4.2',
            marquise: '',
            pear: '',
            oval: '',
            princess: '',
            heart: '',
          },
          {
            carats: '.32',
            round: '4.3',
            marquise: '7x3.5',
            pear: '',
            oval: '',
            princess: '',
            heart: '',
          },
          {
            carats: '.33',
            round: '4.4',
            marquise: '',
            pear: '',
            oval: '',
            princess: '4x4',
            heart: '',
          },
          {
            carats: '.35',
            round: '4.5',
            marquise: '',
            pear: '6x4',
            oval: '',
            princess: '',
            heart: '',
          },
          {
            carats: '.38',
            round: '4.6',
            marquise: '',
            pear: '',
            oval: '6x4',
            princess: '',
            heart: '',
          },
          {
            carats: '.40',
            round: '4.8',
            marquise: '',
            pear: '',
            oval: '',
            princess: '',
            heart: '',
          },
          {
            carats: '.45',
            round: '4.9',
            marquise: '',
            pear: '',
            oval: '',
            princess: '',
            heart: '',
          },
          {
            carats: '.47',
            round: '5.0',
            marquise: '',
            pear: '',
            oval: '',
            princess: '',
            heart: '5x5',
          },
          {
            carats: '.48',
            round: '5.1',
            marquise: '8 x 4',
            pear: '',
            oval: '',
            princess: '',
            heart: '',
          },
          {
            carats: '.50',
            round: '5.2',
            marquise: '',
            pear: '',
            oval: '',
            princess: '4.5x4.5',
            heart: '',
          },
          {
            carats: '.55',
            round: '5.3',
            marquise: '',
            pear: '',
            oval: '',
            princess: '',
            heart: '',
          },
          {
            carats: '.60',
            round: '5.4',
            marquise: '',
            pear: '',
            oval: '',
            princess: '',
            heart: '5.5x5.5',
          },
          {
            carats: '.65',
            round: '5.6',
            marquise: '',
            pear: '7x5',
            oval: '',
            princess: '5x5',
            heart: '',
          },
          {
            carats: '.70',
            round: '5.7',
            marquise: '9x4.5',
            pear: '8x5',
            oval: '7x5',
            princess: '',
            heart: '',
          },
          {
            carats: '.75',
            round: '5.8',
            marquise: '',
            pear: '',
            oval: '',
            princess: '',
            heart: '',
          },
          {
            carats: '.80',
            round: '6.0',
            marquise: '',
            pear: '',
            oval: '',
            princess: '5.5x5.5',
            heart: '6x6',
          },
          {
            carats: '.90',
            round: '6.2',
            marquise: '',
            pear: '',
            oval: '7.5x5.5',
            princess: '',
            heart: '',
          },
          {
            carats: '.95',
            round: '6.4',
            marquise: '10x5',
            pear: '',
            oval: '',
            princess: '',
            heart: '',
          },
          {
            carats: '1.00',
            round: '6.5',
            marquise: '',
            pear: '',
            oval: '',
            princess: '',
            heart: '6.5x6.5',
          },
          {
            carats: '1.10',
            round: '6.6',
            marquise: '',
            pear: '',
            oval: '',
            princess: '6x6',
            heart: '',
          },
          {
            carats: '1.17',
            round: '6.8',
            marquise: '',
            pear: '9x6',
            oval: '8x6',
            princess: '',
            heart: '',
          },
          {
            carats: '1.25',
            round: '7.0',
            marquise: '11x5.5',
            pear: '',
            oval: '',
            princess: '',
            heart: '7x7',
          },
          {
            carats: '1.33',
            round: '7.2',
            marquise: '',
            pear: '',
            oval: '',
            princess: '',
            heart: '',
          },
          {
            carats: '1.40',
            round: '7.3',
            marquise: '',
            pear: '',
            oval: '8.5x6.5',
            princess: '6.5x6.5',
            heart: '',
          },
          {
            carats: '1.50',
            round: '7.4',
            marquise: '',
            pear: '',
            oval: '',
            princess: '',
            heart: '',
          },
          {
            carats: '1.55',
            round: '7.5',
            marquise: '',
            pear: '',
            oval: '',
            princess: '',
            heart: '7.5x7.5',
          },
          {
            carats: '1.60',
            round: '7.6',
            marquise: '',
            pear: '',
            oval: '',
            princess: '',
            heart: '',
          },
          {
            carats: '1.75',
            round: '7.8',
            marquise: '',
            pear: '10x7',
            oval: '9x7',
            princess: '7x7',
            heart: '',
          },
          {
            carats: '1.90',
            round: '8.0',
            marquise: '13x6.5',
            pear: '',
            oval: '',
            princess: '',
            heart: '8x8',
          },
          {
            carats: '2.00',
            round: '8.2',
            marquise: '',
            pear: '12x7',
            oval: '10x7',
            princess: '',
            heart: '',
          },
          {
            carats: '2.15',
            round: '8.4',
            marquise: '',
            pear: '',
            oval: '',
            princess: '7.5x7.5',
            heart: '',
          },
          {
            carats: '2.25',
            round: '8.6',
            marquise: '',
            pear: '',
            oval: '',
            princess: '',
            heart: '8.5x8.5',
          },
          {
            carats: '2.35',
            round: '8.7',
            marquise: '',
            pear: '',
            oval: '',
            princess: '',
            heart: '',
          },
          {
            carats: '2.50',
            round: '8.8',
            marquise: '14x7',
            pear: '',
            oval: '10x8',
            princess: '',
            heart: '',
          },
          {
            carats: '2.60',
            round: '9.0',
            marquise: '',
            pear: '',
            oval: '',
            princess: '8x8',
            heart: '9x9',
          },
          {
            carats: '2.75',
            round: '9.1',
            marquise: '',
            pear: '12x8',
            oval: '10x8.5',
            princess: '',
            heart: '',
          },
          {
            carats: '2.85',
            round: '9.2',
            marquise: '',
            pear: '',
            oval: '',
            princess: '',
            heart: '',
          },
          {
            carats: '3.00',
            round: '9.4',
            marquise: '15x7.5',
            pear: '13x8',
            oval: '',
            princess: '',
            heart: '',
          },
          {
            carats: '3.15',
            round: '9.6',
            marquise: '',
            pear: '',
            oval: '',
            princess: '8.5x8.5',
            heart: '',
          },
          {
            carats: '3.25',
            round: '9.7',
            marquise: '',
            pear: '14x8',
            oval: '',
            princess: '',
            heart: '',
          },
          {
            carats: '3.35',
            round: '9.8',
            marquise: '',
            pear: '',
            oval: '11x9',
            princess: '',
            heart: '',
          },
          {
            carats: '3.50',
            round: '10.0',
            marquise: '16x8',
            pear: '',
            oval: '',
            princess: '',
            heart: '10x10',
          },
          {
            carats: '3.75',
            round: '10.2',
            marquise: '',
            pear: '',
            oval: '11x9.5',
            princess: '9x9',
            heart: '',
          },
          {
            carats: '4.00',
            round: '10.4',
            marquise: '',
            pear: '',
            oval: '',
            princess: '',
            heart: '',
          },
          {
            carats: '4.25',
            round: '10.6',
            marquise: '',
            pear: '14.5x9',
            oval: '',
            princess: '',
            heart: '',
          },
          {
            carats: '4.35',
            round: '10.7',
            marquise: '',
            pear: '15x9',
            oval: '',
            princess: '9.5x9.5',
            heart: '',
          },
          {
            carats: '4.50',
            round: '10.8',
            marquise: '',
            pear: '',
            oval: '12x10',
            princess: '',
            heart: '',
          },
          {
            carats: '4.75',
            round: '11.0',
            marquise: '',
            pear: '',
            oval: '',
            princess: '',
            heart: '',
          },
          {
            carats: '5.00',
            round: '11.1',
            marquise: '',
            pear: '',
            oval: '',
            princess: '10x10',
            heart: '',
          },
          {
            carats: '5.75',
            round: '11.6',
            marquise: '',
            pear: '',
            oval: '',
            princess: '',
            heart: '',
          }
        ],
      selected_gemstone_id: null,
      selected_chart: [{}],
      resource_data:{
      "2.0mm": 0.03,
      "2.5mm": 0.05,
      "firstname": '',
      "lastname":  '',
      "iscompany": 0, 
      "company_name": '',
      "telephone": '',
      "external_id": '',
      "email": '',
      },
    }
  },
    watch: {
      showDiamondCharts: function(){
        if(this.showDiamondCharts == true){
         //window.location.hash = '#gem-search';
         $('#diamond-weights').focus();
        }else{
          $('#app').focus();
        }

      }
    },
  methods:{
    emailAddress(address){
        return 'mailto:'+address+'';
      },
       resourceEdit(id){
        return '../'+this.resource_type+'/'+id+'/edit';
      },
      resourceImage(filepath){
                var image = filepath.replace('\\', '/');
                return "http://localhost/jewellery-tools/public/storage/"+image;
      },
      openPhotoGallery(images){
        var image_array = [];
        image_array = images;
        console.log(image_array);
         EventBus.$emit('open-gallery', image_array);
      },
      selectedChart(cut, gemstone){
        this.selected_chart = this.charts.filter(item => (item.gemstone_id === gemstone) && (item.cut_id === cut));
        //var chart = this.charts.filter(item => item.cut_id === cut);
        console.log(selected_chart);
      },
      handleScroll () {
      if(window.scrollY > 125){
        this.scrolled = "fixed-sidebar mt-4";
      }else{
        this.scrolled = false;
      }
      },
  }
}
</script>
<template>
  <div class="row mt-4">
    <div class="col-md-12 mx-auto mb-4"><h3 class="text-center">Diamond Weight Charts</h3></div><div class="col-md-12 mx-auto"><b-table striped hover :items="items" :fields="fields" :sticky-header="stickyHeader"></b-table></div></div>
</template>