<script>
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
//import 'swiper/css/swiper.css'
/**
 * Dashboard Component
 */
export default {
  components: {
    Swiper,
    SwiperSlide
  },
  directives: {
    swiper: directive
  },
  data() {
    return {
    	hoverIndex: -1,
    	storageurl: '.s3.eu-west-2.amazonaws.com',
    	favouriteTooltip: 'Add to favourites',
    };
  },
  props: {
  	items: {
  		type: Array,
  		default: () => []
  	},
  	favourites: {
  		type: Array,
  		default: () => []
  	},
  	swiperOptions: {
				type: Object,
				default: () => {
          // Some Swiper option/callback...
        },
			},
	startSlide: {
		type: Number,
		default: () => 1
	},
	roundedBorders: {
		type: Boolean,
		default: () => true
	},
  },
  computed: {
      swiper() {
        return this.$refs.mySwiper.$swiper
      }
    },
    methods: {
    	isFavouriteText(seller_id){
    		var filterIndex = this.favourites.findIndex(item => item == seller_id);
    		if(filterIndex == -1){
    			return "Add to favourites";
    		}else{
    			return "Remove from favourites";
    		}
    	},
    },
   mounted() {
    console.log('Current Swiper instance object', this.swiper);
      this.swiper.slideTo(this.startSlide, 1000, false);
  },
};
</script>

<template>
	<swiper ref="mySwiper" :options="swiperOptions">
        <swiper-slide v-for="(item, index) in items">
            <b-card class="shadow-none" no-body  @mouseenter="hoverIndex = index" @mouseleave="hoverIndex = -1">
            	<transition name="fade-top">
	            	<div class="col-md-12 position-absolute z-index-2 d-flex" v-if="hoverIndex == index"> 
	                    <a :href="'/seller/'+item.nickname+'/profile/'" class="mt-3 ml-3 btn btn-primary font-size-16 btn-rounded ml-auto float-left"><i class="mdi mdi-eye-outline"></i></a>
	                    <a v-b-tooltip.hover :title="isFavouriteText(item.id)"  @click="selectedCategory = list, showStoneDetails = 1"class="mr-2 ml-auto btn btn-primary font-size-16 mt-auto btn-rounded" :class="{'btn-warning' : isFavouriteText(item.id) == 'Remove from favourites'}"><i class="mdi mdi-heart-outline"></i></a>
	                 </div>
             	</transition>
            	<b-card-img-lazy  v-if="item.cover_image" :src="'https://'+item.nickname+item.cover_image" :class="{'border-radius-1em' : roundedBorders}"></b-card-img-lazy>
            	<b-card-img-lazy  v-else src="/images/product-placeholder.png" :class="{'border-radius-1em' : roundedBorders}"></b-card-img-lazy>
				<div class="mt-auto mb-3 mx-2 d-flex position-absolute bottom-0 right-0 left-0">
					<transition name="fade-left">
						<span class="my-auto mr-auto p-2 font-size-12 border-radius-1em ml-2">
			                         <b-img :src="'https://'+item.nickname+storageurl + item.logo" class="avatar-sm rounded-circle bg-white p-1" /></b-img>
			                         </span>
		                     </transition>
                    <transition name="fade-right">
		                         <p  v-if="hoverIndex == index" class="text-muted float-right ml-auto my-auto mr-3">
			                    <span class="bx bx-star font-size-14 text-warning"></span>
			                    <span class="bx bx-star font-size-14 text-warning ml-1"></span>
			                    <span class="bx bx-star font-size-14 text-warning ml-1"></span>
			                    <span class="bx bx-star font-size-14 text-warning ml-1"></span>
			                    <span class="bx bx-star font-size-14 ml-1"></span>
			                  </p>
			              			</transition>
              	</div>
            </b-card>
        </swiper-slide>
    	<div class="swiper-pagination" slot="pagination"></div>
  	</swiper>
</template>