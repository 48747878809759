<script>
import { menuItems } from "./trade-menu";

export default {
  props: {
    isTrade: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      menuItems: menuItems,
      active_parent_id: '',
      active_id: '',
    };
  },
  computed: {
    activeParentId(){
      //this.active_parent_id = this.$attrs.active_menu.parent_menu_id;
        return this.$attrs.active_menu.parent_menu_id;
    },
    activeId(){
      //this.active_id = this.$attrs.active_menu.id;
        return this.$attrs.active_menu.id;
    },
    activeMenuItems(){
      if(this.isTrade == "true"){
        return this.menuItems.filter(item => item.active == 1);
      }
      return this.$attrs.menuItems.filter(item => item.active == 1);
    }
  },
  mounted() {
    /**var links = document.getElementsByClassName("side-nav-link-ref");
    var matchingMenuItem = null;
    for (var i = 0; i < links.length; i++) {
      if (window.location.pathname === links[i].pathname) {
        matchingMenuItem = links[i];
        break;
      }
    }

    if (matchingMenuItem) {
      matchingMenuItem.classList.add("active");
      var parent = matchingMenuItem.parentElement;

  
      if (parent) {
        parent.classList.add("active");
        const parent2 = parent.parentElement;
        if (parent2) {
          parent2.classList.add("active");
        }
        const parent3 = parent2.parentElement;
        if (parent3) {
          parent3.classList.add("active");
          var childAnchor = parent3.querySelector(".has-dropdown");
          if (childAnchor) childAnchor.classList.add("active");
        }

        const parent4 = parent3.parentElement;
        if (parent4) parent4.classList.add("active");
        const parent5 = parent4.parentElement;
        if (parent5) parent5.classList.add("active");
      }
    }**/
  },
  methods: {
    activeSubmenuItems(menuitems){
      return menuitems.filter(item => item.active == 1);
     },
    hasItems(item) {
      return item.submenu !== undefined ? item.submenu.length > 0 : false;
    },
    collapseOnHover(id){
      var bodyclasses = document.body.classList;
      console.log(bodyclasses.value)
      if(bodyclasses.value.includes('vertical-collpsed')){
        this.$root.$emit('bv::toggle::collapse', 'menu-'+id);
      }
    },
   
    topbarLight() {
      document.body.setAttribute("data-topbar", "light");
      document.body.removeAttribute("data-layout-size", "boxed");
    },
    boxedWidth() {
      document.body.setAttribute("data-layout-size", "boxed");
      document.body.removeAttribute("data-topbar", "light");
      document.body.setAttribute("data-topbar", "dark");
    },
    coloredHeader() {
      document.body.setAttribute("data-topbar", "colored");
      document.body.removeAttribute("data-layout-size", "boxed");
    },
  },
};
</script>
<!--<template>
  <div class="topnav">
    <div class="container-fluid">
      <nav class="navbar navbar-light navbar-expand-lg topnav-menu">
        <div id="topnav-menu-content" class="collapse navbar-collapse">
          <ul class="navbar-nav">
           

            <template v-for="(item, index) of menuItems">
              <li class="nav-item dropdown" :key="index">
                <a
                  class="nav-link dropdown-toggle arrow-none"
                  v-if="!item.subItems"
                  id="topnav-components"
                  :href="item.link"
                  role="button"
                >
                  <i :class="`bx ${item.icon} mr-2`"></i>{{ $t(item.label) }}
                  <div class="arrow-down" v-if="hasItems(item)"></div>
                </a>

                <a
                  v-if="item.subItems"
                  class="nav-link dropdown-toggle arrow-none"
                  @click="onMenuClick($event)"
                  href="javascript: void(0);"
                  id="topnav-components"
                  role="button"
                >
                  <i :class="`bx ${item.icon} mr-1`"></i>
                  {{ $t(item.label) }}
                  <div class="arrow-down"></div>
                </a>

                <div
                  class="dropdown-menu"
                  aria-labelledby="topnav-dashboard"
                  v-if="hasItems(item)"
                >
                  <template v-for="(subitem, index) of item.subItems">
                    <a
                      class="col dropdown-item side-nav-link-ref"
                      :key="index"
                      v-if="!hasItems(subitem)"
                      :href="subitem.link"
                      >{{ $t(subitem.label) }}</a
                    >

                    <div class="dropdown" v-if="hasItems(subitem)" :key="index">
                      <a
                        class="dropdown-item dropdown-toggle"
                        href="javascript: void(0);"
                        @click="onMenuClick($event)"
                        >{{ $t(subitem.label) }}
                        <div class="arrow-down"></div>
                      </a>
                      <div class="dropdown-menu">
                        <template
                          v-for="(subSubitem, index) of subitem.subItems"
                        >
                          <a
                            class="dropdown-item side-nav-link-ref"
                            :key="index"
                            v-if="!hasItems(subSubitem)"
                            :href="subSubitem.link"
                            >{{ $t(subSubitem.label) }}</a
                          >
                          <div
                            class="dropdown"
                            v-if="hasItems(subSubitem)"
                            :key="index"
                          >
                            <a
                              class="dropdown-item dropdown-toggle"
                              href="javascript: void(0);"
                              @click="onMenuClick($event)"
                              >{{ $t(subSubitem.label) }}
                              <div class="arrow-down"></div>
                            </a>
                            <div class="dropdown-menu">
                              <template
                                v-for="(
                                  subSubSubitem, index
                                ) of subSubitem.subItems"
                              >
                                <a
                                  class="dropdown-item side-nav-link-ref"
                                  :key="index"
                                  :href="subSubSubitem.link"
                                  routerLinkActive="active"
                                  >{{ $t(subSubSubitem.label) }}</a
                                >
                              </template>
                            </div>
                          </div>
                        </template>
                      </div>
                    </div>
                  </template>
                </div>
              </li>
            </template>
          </ul>
        </div>
      </nav>
    </div>
  </div>
</template>-->


<template>
  <!-- ========== Left Sidebar Start ========== -->

  <!--- Sidemenu -->
    <div class="topnav">
    <div class="container-fluid">
      <nav class="navbar navbar-light navbar-expand-lg topnav-menu">
        <div id="topnav-menu-content" class="collapse navbar-collapse">
          <ul class="navbar-nav">
    <!-- Left Menu Start -->
          <li class="">
            <a class="nav-link dropdown-toggle arrow-none" href="javascript:;" @click="$emit('show-sidebar')">
              <i class="bx bx-menu-alt-left font-size-20" v-if="$attrs.sidebarActive == false"></i>
              <i class="bx bx-x font-size-20" v-else></i>
            </a>
          </li>
      <template v-for="item in activeMenuItems">
        <!--<li class="nav-item" v-if="item.isTitle" :key="item.id"><a class="nav-link dropdown-toggle arrow-none" href="javascript:;">{{ $t(item.label) }}</a></li>-->
        <li v-if="!item.isTitle && !item.isLayout" :key="item.id" @mouseenter="collapseOnHover(item.id)" @mouseleave="collapseOnHover(item.id)"  :class="{ 'mm-active': activeId == item.id }">
          <!--<a
            v-if="hasItems(item)"
            href="javascript:void(0);"
            class="is-parent nav-link dropdown-toggle arrow-none"
            :class="{'has-arrow': !item.badge, 'has-dropdown': item.badge}"
            v-b-toggle="'menu-'+item.id"

          >
            <i :class="`bx ${item.icon}`" v-if="item.icon"></i>
            <span>{{ $t(item.label) }}</span>
            <span
              :class="`badge rounded-pill bg-${item.badge.variant} float-end`"
              v-if="item.badge"
            >{{ $t(item.badge.text)}}</span>
          </a>-->

          <a :href="item.link" v-if="!hasItems(item)" class="nav-link dropdown-toggle arrow-none">
            <i :class="`bx ${item.icon}`" v-if="item.icon"></i>
            <span>{{ $t(item.label) }}</span>
            <span
              :class="`badge rounded-pill bg-${item.badge.variant} float-end`"
              v-if="item.badge"
            >{{$t(item.badge.text)}}</span>
          </a>

          <b-dropdown :text="$t(item.label)" :id="'menu-'+item.id" v-if="hasItems(item)" toggle-class="btn-none bg-transparent border-none p-0" class="sub-menu menu-dropdown" aria-expanded="false" v-model="activeParentId == item.id" no-caret>
            <template #button-content>
              <a
                v-if="hasItems(item)"
                href="javascript:void(0);"
                class="is-parent nav-link dropdown-toggle arrow-none"
                :class="{'has-arrow': !item.badge, 'has-dropdown': item.badge}"
                v-b-toggle="'menu-'+item.id"

              >
                <i :class="`bx ${item.icon}`" v-if="item.icon"></i>
                <span>{{ $t(item.label) }}</span>
                <span
                  :class="`badge rounded-pill bg-${item.badge.variant} float-end`"
                  v-if="item.badge"
                >{{ $t(item.badge.text)}}</span>
              </a>
            </template>
            <b-dropdown-item v-for="(subitem, index) of activeSubmenuItems(item.submenu)" :key="index" :class="{ 'active': activeId == subitem.id }" :href="subitem.link">
              <span
                :href="subitem.link"
                v-if="!hasItems(subitem)"
                class="side-nav-link-ref"
                :class="{ 'active': activeId == subitem.id }"
              >{{ $t(subitem.label) }}</span>
              <span
                v-if="hasItems(subitem)"
                class="side-nav-link-a-ref has-arrow"
                href="javascript:void(0);"
                :class="{ 'active': activeId == subitem.id }"
              >{{ $t(subitem.label) }}</span>
              <b-dropdown v-if="hasItems(subitem)" toggle-class="btn-none bg-transparent border-none p-0" class="sub-menu menu-dropdown" aria-expanded="false">
                <b-dropdown-item v-for="(subSubitem, index) of subitem.submenu" :key="index" :class="{'active': activeId == subSubitem.id}" :href="subSubitem.link" >
                   {{ $t(subSubitem.label) }}
                </b-dropdown-item>
              </b-dropdown>
            </b-dropdown-item>
          </b-dropdown>
        </li>
      </template>
    </ul>
  </div>
</nav>
</div>
  </div>
  <!-- Sidebar -->
</template>
