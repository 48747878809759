<script>
import VueSlideBar from "vue-slide-bar";
import simplebar from "simplebar-vue"
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
//import FilterListTree from "../../views/stock/product/category/_partials/FilterListTree";
import FilterListTree from "../../components/widgets/FilterListTree";
import FilterProductBrands from "../../components/widgets/FilterProductBrands";
import FilterProductCollections from "../../components/widgets/FilterProductCollections";
import SideSlidePanel from "../../components/widgets/SideSlidePanel";

/**
 * Products component
 */
export default {
  components: { VueSlideBar, Layout, PageHeader, FilterListTree, FilterProductBrands, FilterProductCollections, SideSlidePanel, simplebar },
  data() {
    return {
      pagesData: '',
      title: "Terminals",
      items: [
        {
          text: "Sell",
          href: "/",
        },
        {
          text: "Terminals",
          active: true,
        },
      ],
      stripeAccount: [],
      loadingTerminalForm: false,
      search: '',
      storageurl: 'https://'+this.$attrs.company.nickname+'.s3.eu-west-2.amazonaws.com',
      showFilters: false,
      sliderPrice: 800,
      priceRange: [0,999999],
      currentPage: 1,
      limit: 25,
      selectedids: [],
      orderDesc: true,
      order: 'desc',
      sortby: 'id',
      trade_active: '',
      public_active: '',
      brands: '',
      collections: '',
      selectedids: [],
      closeHover: false,
      addNew: false,
      listingssearch: '',
      listings: '',
      terminals: [],
      terminal: {
        id: '',
        uid: '',
        reference: '',
        name: '',
        description: '',
        company_listing_id: '',
        company_register_id: '',
        registration_code: '',
        reader_type: '',
        terminal_config: {},
      },
      isCheckAll: false,
      active: '',
      view: 'grid',
      fields: [
        { key: "id", sortable: true },
        { key: "name", sortable: true },
        ],
      filter: null,
      filterOn: [],
      limits: [
        {value: 25, text: "25"},
        {value: 50, text: "50"},
        {value: 100, text: "100"},
      ],
      discountRates: [],
    };
  },
  methods: {
    setUpTerminal(){
      this.createTerminalRecord();
    },
    createTerminalRecord(){
      this.loadingTerminalForm = true;
      axios.post('/api/company/sell/pos/terminal/create', this.terminal, {headers: {
        'Authorization' : 'Bearer '+this.$attrs.user.api_token
      }}).then(response => {
        this.loadingTerminalForm = false;
      }).catch(error => {
        this.loadingTerminalForm = false;
      });
    },
     getStripeAccount(){
          
          axios.get('/api/company/settings/payments/'+this.$attrs.company.uid+'/account', {headers: {
            'Authorization' : 'Bearer '+this.$attrs.user.api_token
          }}).then(response => {
            this.stripeAccount.push(response.data);
          }).catch(error => {
            this.error_reponse = error;
          });
        },
    getListings(){
      axios.get('/api/company/listings?page='+this.currentPage+'&limit=0&sortby=id&order=desc&search='+this.listingssearch+'&type_id=&active=&public_active=&trade_active=', {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
                this.listings = response.data;
          }).catch(error => {
                this.load_error = response.error;
          });
    },
    valuechange(value) {
      this.clothsData = clothsData.filter(function (product) {
        return product.newprice <= value.currentValue;
      });
    },

    searchFilter(e) {
      const searchStr = e.target.value;
      this.productQuery();
    },

    filterStripeReaders(){

    },

    filterBrands(value){
      this.brands = value;
      this.productQuery();
    },

     filterCollections(value){
      this.collections = value;
      this.productQuery();
    },

    discountLessFilter(e, percentage) {
      if (e === "accepted" && this.discountRates.length === 0) {
        this.clothsData = clothsData.filter((product) => {
          return product.discount < percentage;
        });
      } else {
        this.clothsData = clothsData.filter((product) => {
          return product.discount >= Math.max.apply(null, this);
        }, this.discountRates);
      }
    },

    discountMoreFilter(e, percentage) {
      if (e === "accepted") {
        this.discountRates.push(percentage);
      } else {
        this.discountRates.splice(this.discountRates.indexOf(percentage), 1);
      }
      this.clothsData = clothsData.filter((product) => {
        return product.discount >= Math.max.apply(null, this);
      }, this.discountRates);
    },
     productQuery(){
      if(this.pagesData != ''){
        if(this.limit > this.pagesData.total){
          this.currentPage = 1;
        }
      }
      if(this.orderDesc == true){
        this.order = 'desc';
      }else{
        this.order = 'asc';
      }
      axios.get('/api/company/sell/terminals?page='+this.currentPage+'&limit='+this.limit+'&sortby='+this.sortby+'&order='+this.order+'&search='+this.search+'&active='+this.active, {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
        console.log(response);
        this.pagesData = response.data;
      }).catch(error => {
        this.error_reponse = error.response.error;
        this.error_message = true;
      });
    },
     filterSelected(id){
        if(this.selectedids.length > 0){
          var exists = this.selectedids.filter(item => item == id);
          if(exists.length > 0){
            return true;
          }else{
            return false
          }
        }else{
          return false;
        }
      },
      checkAll: function(){ 
                 this.isCheckAll = !this.isCheckAll; 
                 this.selectedids = []; 
                 if(this.isCheckAll){ 
                     // Check all 
                     for (var key in this.pagesData.data) {          
                        this.selectedids.push(this.pagesData.data[key].id); 
                     } 
                 } 
              }, 
      updateCheckall: function(){ 
                 if(this.selectedids.length == this.pagesData.data.length){
                    this.isCheckAll = true; 
                 }else{ 
                    this.isCheckAll = false; 
                 } 
      },
  },
  beforeMount(){
      this.productQuery();
      this.getListings();
      this.getStripeAccount();
  },
  computed:{
   
  }
};
</script>

<template>
  <Layout  :nickname="$attrs.nickname" :user="$attrs.user" :menuItems="$attrs.menuItems" >
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div :class="{'col-lg-12': showFilters == true, 'col-lg-12': showFilters == false}">
        <div class="row mb-3">
          <div class="col-xl-1 col-sm-6">
            <div class="mt-2">
              <b-form-select class="form-control" v-model="limit" :options="limits" @input="productQuery()"></b-form-select>
            </div>
          </div>
         
          <div class="col-lg-2 col-sm-6 d-flex">
          <form class="my-auto">
            <small class="mx-auto my-2 text-center d-block">Results {{pagesData.from}} - {{pagesData.to}} of {{pagesData.total}}</small>
          </form>
        </div>
        <div class="col-lg-5 col-sm-6 d-flex">
            <div class="mt-sm-0 form-inline w-100" >
              <div class="search-box mr-2 ml-auto">
                <div class="position-relative">
                  <input
                    type="text"
                    class="form-control w-100 border-0"
                    placeholder="Search name, reference or description..."
                    v-model="search"
                    @keyup.enter="searchFilter($event)"
                  />
                  <i class="bx bx-search-alt search-icon"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-sm-6 d-flex">
            <div class="d-flex ml-auto">
              <ul class="nav nav-pills product-view-nav">
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': showFilters == true }" href="javascript: void(0);" @click="showFilters = !showFilters">
                    <i class="bx bx-filter-alt"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': view == 'grid' }" href="javascript: void(0);" @click="view = 'grid'">
                    <i class="bx bx-grid-alt"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': view == 'list' }" href="javascript: void(0);" @click="view = 'list'">
                    <i class="bx bx-list-ul"></i>
                  </a>
                </li>
                <li class="nav-item">
                 <b-dropdown text="Bulk Actions" variant="primary" class="rounded">
                  <b-dropdown-item href="javascript: void(0);">Action</b-dropdown-item>
                  <b-dropdown-item href="javascript: void(0);">Another action</b-dropdown-item>
                  <b-dropdown-item href="javascript: void(0);">Something else here</b-dropdown-item>
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-item href="javascript: void(0);">Separated link</b-dropdown-item>
                </b-dropdown>
                </li>
                <li class="nav-item">
                 <a
                    class="btn btn-success btn-rounded mb-2 mr-2 text-white"
                    @click="addNew = !addNew"
                  >
                    <i class="mdi mdi-plus mr-1"></i> New
                  </a>
                </li>
              </ul>
              <div class="text-sm-right">
                  
                </div>
          </div>
        </div>
      </div>
        <!--<div class="row">
          <div class="col-lg-12">
            <b-pagination
              v-if="pagesData.data.length > 0"
              class="justify-content-center mb-0"
              pills
              v-model="currentPage"
              :total-rows="pagesData.total"
              :per-page="pagesData.per_page"
              aria-controls="my-table"
              @input="productQuery"
            ></b-pagination>
             <small class="mx-auto my-2 text-center d-block">Records {{pagesData.from}} - {{pagesData.to}} of {{pagesData.total}} // Page {{pagesData.current_page}} of {{pagesData.last_page}}</small>
          </div>
        </div>-->
      <b-row>
      <div id="my-table" v-if="view == 'grid'" v-for="list in pagesData.data" :key="list.id" class="col-xl-4 col-sm-6">
        <div class="card overflow-hidden">
          <div class="bg-soft-primary border-top-left-1em border-top-right-1em bg-light" style="min-height: 150px;">
            <div class="row">
              
              <div class="col-12 align-self-end">
                <div class="row">
                 <div class="col-md-12 position-absolute"> 
                
                <a id="tooltip-add"  :href="'/web/page/'+list.uid" class="mt-3 mr-3 btn btn-primary font-size-16 btn-rounded ml-auto float-right"><i class="mdi mdi-eye-outline"></i></a>
              </div>
              </div>
               
              </div>
            </div>
          </div>
          <div class="card-body pt-0">
            <div class="row">
              <div class="col-sm-12 text-center px-4 mb-2">
               <!-- <div class="avatar-xl profile-user-wid mb-4">
                  <img class="img-thumbnail rounded-circle h-100" :src="`/public/storage/${list.image}`" alt />
                </div>-->
                <div class="avatar-sm profile-user-wid mx-auto">
                   <div class="text-center" v-if="list.image">
                      <b-form-checkbox class="select-row-btn" v-model="selectedids" :value="list.id" button-variant="primary" @input="updateSelected" button>
                        <i v-if="filterSelected(list.id)" class="bx bx-check"></i>
                        <img v-else :src="`${list.image}`" alt class="avatar-sm img-thumbnail rounded-circle h-100" />
                      </b-form-checkbox>
                    </div>
                    <div class="text-center" v-else >
                        <b-form-checkbox class="select-row-btn" v-model="selectedids" :value="list.id" button-variant="primary" @input="updateSelected" button>

                          <i v-if="filterSelected(list.id)" class="bx bx-check"></i><span class="avatar-title rounded-circle" v-else>{{(list.name).slice(0,1)}}</span></b-form-checkbox>
                    </div> 
                </div>
                <h4 class="font-size-18 text-truncate mt-3">{{list.name}}</h4>
                <p class="text-muted mb-0 text-truncate" v-html="list.description"></p>
              </div>
              <div class="col-sm-12  d-flex flex-column">
                <span class="my-auto mx-auto">Serial No.</span>
                                                <span class="badge badge-light bg-light rounded-3 mb-3 mx-auto font-size-14" v-b-tooltip.hover title="Click to Reveal Serial No.">xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxxx</span>
              </div>
              <div class="col-sm-12 d-flex">
              <a class="my-auto btn bg-danger bg-soft text-danger btn-soft btn-sm btn-rounded ml-auto mr-1" v-if="!list.listing" @click="loadingLinkListing = true">
                    <i class="bx bx-x-circle text-danger"></i>   Not Linked to Shop
                    </a>
                    <a v-else class="my-auto btn bg-success bg-soft text-success btn-soft btn-sm btn-rounded ml-auto mr-1" :href="'/company/shop/'+list.listing.uid" target="_blank">                 
                     <i class="bx bx-check-circle text-success"></i> Linked to Shop
                    </a>
                    <a class="btn btn-danger btn-sm btn-rounded mr-auto ml-1" @click="destroyStripeLocation(location, index)">
                      Remove Reader Location
                    </a>
              </div>
            </div>
          </div>
          <div class="card-footer  bg-transparent border-top">
                  <div class="d-flex">
                    <a  href="/company/settings#Payments" target="_blank" class="btn btn-outline-light btn-rounded"><i class="bx bx bx-cog my-1"></i></a>
                    <a id="tooltip-add" @click="editcollection = list, editPanel = true" class="btn btn-outline-light font-size-16 btn-rounded ml-2 my-auto"><i class="bx bx-pencil"></i></a><b-tooltip target="tooltip-add">Add Product</b-tooltip>
                    <a class="btn btn-outline-light font-size-16 btn-rounded ml-2 my-auto" v-b-tooltip.hover title="Delete" placement="left"  @click="selected_product_id = list.id, deleteMode = 'single'" v-b-modal.delete-modal><i class="bx bx-trash-alt"></i>
                          </a>
                    <b-dropdown  placement="left" variant="outline-light" dropleft class="more-options-dropdown btn-rounded" menu-class="dropdown-menu-right  ml-2">
                        <template v-slot:button-content>
                           <i class="bx bx-dots-horizontal-rounded"></i>
                        </template>
                          <b-dropdown-item href="javascript: void(0);"  @click="selectedids = [], selectedids.push(list.id), bulkDuplicateResource()">Duplicate</b-dropdown-item>
                      </b-dropdown>
                      <div
                        class="form-check form-switch form-switch-sm my-auto ml-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          :id="`SwitchCheckSize-${list.id}`"
                          v-model="list.active"
                          :value="list.active"
                          name="active"
                          @change="selectedids = [], selectedids.push(list.id), setActive(list.active)"
                        />
                        <label class="form-check-label" :for="`SwitchCheckSize-${list.id}`" 
                          >Active</label
                        >
                    </div>
                  </div>
          </div>
        </div>
    </div>
  </b-row>
        
        <!-- end row -->

        
        <div class="card" v-if="view == 'list'" >
          <div class="card-body p-0">
            <div class="table-responsive">
              <table class="table table-centered table-hover">
                <thead class="thead-light">
                  <tr>
                   <th scope="col"  style="width: 100px" class="text-center">
                     <div
                        class="form-check form-switch form-switch-sm mb-0 mx-auto p-0 mt-auto d-flex"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input mx-auto my-auto" 
                          type="checkbox"
                          id="SwitchCheckSizesm"
                         
                          v-model="isCheckAll"
                          @click='checkAll()'
                          name="active"
                        />
                    </div>
                  </th>
                    <th scope="col" style="max-width: 300px"><a :class="{'text-dark': sortby != 'name'}" href="javascript: void(0);" @click="orderDesc = !orderDesc,  sortby = 'name', productQuery()">Name & Description <i v-if="orderDesc == true" class="bx bx-caret-up"></i><i v-if="orderDesc == false" class="bx bx-caret-down"></i></a></th>
                    
                    <th scope="col">Link</th>
                    <th scope="col">Parent Page</th>
                    
                    <th scope="col">Status</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="list in pagesData.data" :key="list.id" valign="middle">
                    <td>
                      <div class="text-center" v-if="list.cover_image">
                      <b-form-checkbox class="select-row-btn " v-model="selectedids" :value="list.id" button-variant="primary" @input="updateSelected" button>
                        <i v-if="filterSelected(list.id)" class="bx bx-check"></i>
                        <img v-else :src="`${storageurl+list.cover_image.src.replace('//', '/')}`" alt class="avatar-sm rounded-circle" />
                      </b-form-checkbox>
                    </div>
                    <div class="text-center" v-else >
                        <b-form-checkbox class="select-row-btn" v-model="selectedids" :value="list.id" button-variant="light" @input="updateSelected" button>

                          <i v-if="filterSelected(list.id)" class="bx bx-check"></i><span class="avatar-title rounded-circle" v-else>{{(list.name).slice(0,1)}}</span></b-form-checkbox>
                    </div> 
                    </td>
                    <td style="max-width: 550px">
                        <a :href="`/web/page/${list.uid}`" class="mb-0"><h6 class="mb-0">{{list.name}}</h6></a>
                        <p class="d-md-none d-block" v-html="list.short_description"></p>
                    </td>
                    <td><a 
                          href="javascript: void(0);"
                          class="badge badge-soft-light rounded-pill font-size-11 m-1"
                         v-if="list.brand != null"><img v-if="list.brand.logo" class="rounded-circle avatar-xs" :src="`${list.brand.logo}`" alt />  <img v-else  src="/images/banner-placeholder.png" alt="" class="rounded-circle avatar-xs"> <span class="text-dark">{{list.brand.name}}</span></a></td>
                    <td>
                      <div>

                        <a 
                          href="javascript: void(0);"
                          class="badge badge-soft-light rounded-pill font-size-11 m-1"
                         v-if="list.company_defaultcategory != null"><img class="rounded-circle avatar-xs" v-if="list.company_defaultcategory.image" :src="`${list.company_defaultcategory.image}`" alt /> <img v-else  src="/images/banner-placeholder.png" alt="" class="rounded-circle avatar-xs"> <span class="text-dark">{{list.company_defaultcategory.name}}</span></a>

                         <a 
                          href="javascript: void(0);"
                          class="badge badge-soft-light rounded-pill font-size-11 m-1"
                          v-for="category in list.company_categories"><img v-if="category.category.image" class="rounded-circle avatar-xs" :src="`${category.category.image}`" alt /> <img v-else src="/images/banner-placeholder.png" alt="" class="rounded-circle avatar-xs">  <span class="text-dark">{{category.category.name}}</span></a>

                        
                      </div>
                    </td>
                    
                    <td>
                      <div
                        class="form-check form-switch form-switch-sm mb-0 mt-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          id="SwitchCheckSizesm"
                          v-model="list.active"
                          value="1"
                          unchecked-value="0"
                          name="active"
                        />
                        <label class="form-check-label" for="SwitchCheckSizesm" 
                          >Active</label
                        >
                    </div>
                   
                    </td>
                    <td>
                      <ul class="list-inline font-size-20 contact-links mb-0">
                        <li class="list-inline-item px-2">
                          <a v-b-tooltip.hover title="Edit" placement="left">
                            <i class="bx bx-edit-alt"></i>
                          </a>
                        </li>
                        <li class="list-inline-item px-2">
                            <a v-b-tooltip.hover title="Preview" placement="left">
                            <i class="mdi mdi-eye-outline"></i>
                          </a>
                          </a>
                        </li>
                        <li class="list-inline-item px-2">
                          <a v-b-tooltip.hover title="Delete" placement="left">
                            <i class="bx bx-trash-alt text-danger"></i>
                          </a>
                        </li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            
            <div class="row">
              <div class="col-lg-12" v-if="pagesData !== ''">
                <b-pagination
              class="justify-content-center"
              pills
              v-model="currentPage"
              :total-rows="pagesData.total"
              :per-page="pagesData.per_page"
              aria-controls="my-table"
              @input="productQuery"
            ></b-pagination>
             <small class="mx-auto my-2 text-center d-block">Records {{pagesData.from}} - {{pagesData.to}} of {{pagesData.total}} // Page {{pagesData.current_page}} of {{pagesData.last_page}}</small>
              </div>
            </div>
          </div>
        </div>
      </div>
           <SideSlidePanel :editPanel="addNew" :panelClass="'edit-sidepanel'" @close-panel="addNew = !addNew">     
        <form id="add-update" method="post" enctype="mutlipart/form-data">
          <b-overlay :show="loadingTerminalForm">
            <b-row>
              <b-col md="2" class="my-3">
                          <h5 class="d-flex">Basic Info <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
                        </b-col>
              <b-col md="10" class="my-3">
              <b-card class="shadow-none border-radius-1em border">
                <b-form-group label="Reference">
                  <b-form-input type="text" name="reference" v-model="terminal.reference"></b-form-input>
                </b-form-group>
                <b-form-group label="Name">
                  <b-form-input type="text" name="name" v-model="terminal.name"></b-form-input>
                </b-form-group>
                <b-form-group label="Description">
                  <b-form-textarea :rows="5" type="text" name="description" v-model="terminal.description"></b-form-textarea>
                </b-form-group>
              </b-card>
              </b-col>
              <b-col md="2" class="my-3">
                          <h5 class="d-flex">Location <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
                        </b-col>
              <b-col md="10" class="my-3">
                <b-card class="shadow-none border-radius-1em border" body-class="p-0">
                <div v-if="terminal.company_listing_id == ''">
                <simplebar
                              style="height: 300px"
                          >
                              <li class="list-group-item" v-for="listing in listings">
                                  <div class="media">
                                      <div class="avatar-sm me-3" v-if="listing.logo">
                                          <span
                                              class="avatar-title rounded-circle bg-light"
                                          >
                                              <!--<img
                                                  
                                                  :src="listing.logo"
                                                  alt=""
                                                  height="18"
                                              />-->
                                              
                                          </span>
                                        </div>
                                         <div class="avatar-sm me-3" v-else>
                                          <span class="avatar-title rounded-circle" >{{(listing.name).slice(0,1)}}</span>
                                        </div>
                                     
                                      <div class="media-body">
                                          <h6 class="my-1">
                                              {{listing.name}}
                                          </h6>
                                          <p class="text-muted mb-0">
                                              <span v-if="listing.address_1">{{listing.address_1}},</span>
                      <span v-if="listing.address_2">{{listing.address_2}},</span>
                      <span v-if="listing.address_3">{{listing.address_3}},</span>
                      <span v-if="listing.county">{{listing.county.name}},</span>
                      <span v-if="listing.country">{{listing.country.name}},</span>
                      <span v-if="listing.postcode">{{listing.postcode}}</span>
                                          </p>

                                         
                                         
                                      </div>
                                       <div class="float-end my-auto mr-2">
                                              <p class="text-muted my-2" v-if="listing.listing_type">
                                                  <i 
                                                      class="bx bx-buildings me-1"
                                                  ></i>
                                                  {{listing.listing_type.name}}
                                              </p>
                                          </div>
                                       <p class="text-muted my-auto">
                                              <a class="btn btn-primary btn-rounded" @click="terminal['listing'] = listing, terminal.company_listing_id = listing.id">Use Location</a>
                                          </p>
                                  </div>
                              </li>
                          </simplebar>
                      </div>
                      <div class="border p-4 text-center d-flex flex-column" v-else style="height: 350px">
                        <b-row class="my-auto">
                          <b-col md="12">
                              <div class="avatar-sm mx-auto mb-3" v-if="terminal.listing.logo">
                                          <span
                                              class="avatar-title rounded-circle bg-light"
                                          >
                                              <!--<img
                                                  
                                                  :src="terminal.listing.logo"
                                                  alt=""
                                                  height="18"
                                              />-->
                                              
                                          </span>
                                        </div>
                                         <div class="avatar-sm mx-auto mb-3" v-else @mouseenter="closeHover = true" @mouseleave="closeHover = false">
                                          <span class="avatar-title rounded-circle" v-if="closeHover !== true" >{{(terminal.listing.name).slice(0,1)}}</span>
                                          <a v-else class="avatar-title rounded-circle" @click="terminal.listing = '', terminal.company_listing_id = '', closeHover = false">
                                            <i class="bx bx-x text-white font-size-20"></i>
                                          </a>
                                        </div>
                                        <h4>{{terminal.listing.name}}</h4>

                                        <p class="text-muted">
                                      <span v-if="terminal.listing.address_1">{{terminal.listing.address_1}}<br></span>
                              <span v-if="terminal.listing.address_2">{{terminal.listing.address_2}}<br></span>
                              <span v-if="terminal.listing.address_3">{{terminal.listing.address_3}}<br></span>
                              <span v-if="terminal.listing.county">{{terminal.listing.county.name}}<br></span>
                              <span v-if="terminal.listing.country">{{terminal.listing.country.name}}<br></span>
                              <span v-if="terminal.listing.postcode">{{terminal.listing.postcode}}</span>
                                          </p>
                        
                                          
                              </b-col>
                           </b-row>
                      </div>
                    </b-card>
              </b-col>
              <b-col md="2" class="my-3">
                          <h5 class="d-flex">Terminal Configuration <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
                        </b-col>
              <b-col md="10" class="my-3">
                <b-card class="shadow-none border-radius-1em border">
                 
                <b-form-group label="Terminal Label" class="mb-3">
                  <b-form-input type="text" name="reference" v-model="terminal.reference"></b-form-input>
                </b-form-group>
                <b-row>
                  <b-col md="12">
                    <label>Select Terminal Configuration</label>
                  </b-col>
                  <b-col md="4">
                  <b-card class="border-radius-1em border shadow-none"  @click="terminal.reader_type = 'bbpos_wisepos_e'" :class="{'active border-primary' : terminal.reader_type == 'bbpos_wisepos_e'}">
                    <img class="bx bx-card-reader d-block w-100" src="https://stripe-images.s3.amazonaws.com/terminal/hardware_skus/WisePOS-E.png">
                   <p class="text-center mb-0">Gemesys BBPOS WisePOS™ E</p>
                  </b-card>
                  </b-col>
                  <b-col md="4">
                  <b-card class="border-radius-1em border shadow-none" @click="terminal.reader_type = 'simulated-wpe'" :class="{'active border-primary' : terminal.reader_type == 'simulated-wpe'}">
                    <img class="bx bx-card-reader d-block w-100" src="https://stripe-images.s3.amazonaws.com/terminal/hardware_skus/WisePOS-E.png">
                   <p class="text-center mb-0">Simulated Reader / Test Reader</p>
                  </b-card>
                  </b-col>
                </b-row>
                  <b-form-group label="Registration Code">
                    <b-form-input type="text" name="registration_code" v-model="terminal.registration_code"></b-form-input>
                  </b-form-group>
                </b-card>
                <a class="btn btn-success btn-rounded btn-block" @click="setUpTerminal()">
                  Set-up Terminal <i class="bx bx-save"></i>
                </a>
              </b-col>
            </b-row>
          </b-overlay>
            </form>
    </SideSlidePanel>
    </div>
    <!-- end row -->
  </Layout>
</template>
