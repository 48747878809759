<template>
<b-tabs pills content-class="pt-2" variant="outline-light">
                      <b-tab active v-if="$attrs.active == true">
                        <template v-slot:title>
                          <span class="d-inline-block d-sm-none">
                        <i class="far fa-user"></i>
                      </span>
                      <span class="d-none d-sm-inline-block">My Shops</span>
                            </template>
                          <div class="form-group mt-2">
                                   <div class="row px-2" v-if="loadingActive == false">
                             <b-button v-for="collection in categories"
                        class="custom-radio flex-grow-0 border-radius-1em inset-border py-4 custom-radio-outline position-relative custom-radio-primary my-0 w-25 w-sm col-md-4"
                        value="0"
                        :class="{'active' : activeInCategories(collection, 'company')}"
                        variant="outline-light"
                         @click="updateActiveCategories(collection, 'company')"
                      >
                      <img class="avatar d-block avatar-md rounded-circle mx-auto" v-if="collection.image !== null" :src="'/image/'+collection.image">
                      <img v-else class="avatar d-block avatar-md rounded-circle mx-auto" src="/images/product-placeholder.png">
                      <i v-if="activeInCategories(collection, 'company') == true" class="bx bx-check-circle font-size-24 d-block text-success my-2 position-absolute top-1"></i> {{collection.name}}
                </b-button>
              </div>
              <b-overlay :show="loadingActive"></b-overlay>
                          </div>
                        </b-tab>
                        <b-tab v-if="$attrs.trade == true">
                        <template v-slot:title>
                          <span class="d-inline-block d-sm-none">
                        <i class="far fa-user"></i>
                      </span>
                      <span class="d-none d-sm-inline-block">Trade Market</span>
                            </template>
                          <div class="form-group mt-2">
                                   <div class="row px-2" v-if="loadingActive == false">
                             <b-button v-for="(collection, key) in trade_categories"
                        class="custom-radio flex-grow-0 border-radius-1em inset-border py-4 custom-radio-outline position-relative custom-radio-primary my-0 w-25 w-sm col-md-4"
                        value="0"
                        :class="{'active' : activeInCategories(collection, 'trade')}"
                        variant="outline-light"
                         @click="updateActiveCategories(collection, 'trade')"
                         :key="key"
                         :id="collection.id"
                      >
                      <img class="avatar d-block avatar-md rounded-circle mx-auto" v-if="collection.image !== null" :src="'/image/'+collection.image">
                      <img v-else class="avatar d-block avatar-md rounded-circle mx-auto" src="/images/product-placeholder.png">
                      <i v-if="activeInCategories(collection, 'trade') == true" class="bx bx-check-circle font-size-24 d-block text-success my-2 position-absolute top-1"></i> {{collection.name}}
                </b-button>
              </div>
              <b-overlay :show="loadingActive"></b-overlay>
                          </div>
                        </b-tab>
                        <b-tab v-if="$attrs.public == true">
                        <template v-slot:title>
                          <span class="d-inline-block d-sm-none">
                        <i class="far fa-user"></i>
                      </span>
                      <span class="d-none d-sm-inline-block">Public Market</span>
                            </template>
                          <div class="form-group mt-2">
                                   <div class="row px-2" v-if="loadingActive == false">
                             <b-button v-for="collection in public_categories"
                        class="custom-radio flex-grow-0 border-radius-1em inset-border py-4 custom-radio-outline position-relative custom-radio-primary my-0 py-3 w-25 w-sm col-md-4"
                        value="0"
                        :class="{'active' : activeInCategories(collection, 'public')}"
                        variant="outline-light"
                         @click="updateActiveCategories(collection, 'public')"
                      >
                      <img class="avatar d-block avatar-md rounded-circle mx-auto" v-if="collection.image !== null" :src="'/image/'+collection.image">
                      <img v-else class="avatar d-block avatar-md rounded-circle mx-auto" src="/images/product-placeholder.png">
                      <i v-if="activeInCategories(collection, 'public') == true" class="bx bx-check-circle font-size-24 d-block text-success my-2 position-absolute top-1"></i> {{collection.name}}
                </b-button>
              </div>
              <b-overlay :show="loadingActive"></b-overlay>
                          </div>
                        </b-tab>
                        
                      </b-tabs>
</template>

<script type="text/javascript">
  export default {
    data(){
      return {
        loadingActive: false,
        active_collections: [],
        active_categories: [],
        active_public_categories: [],
        active_trade_categories: [],
        categories: [],
        public_categories: [],
        trade_categories: [],
      }
    },
    mounted(){
      if(this.$attrs.active == true){
        this.getCategories('company');
      }
      if(this.$attrs.public == true){
        this.getCategories('public');
      }
      if(this.$attrs.trade == true){
        this.getCategories('trade');
      }
    },
    watch:{
      '$attrs.active'(){
        if(this.$attrs.active == true){
          this.getCategories('company');
          }
        },
        '$attrs.public'(){
        if(this.$attrs.public == true){
          this.getCategories('public');
          }
        },
        '$attrs.trade'(){
        if(this.$attrs.trade == true){
          this.getCategories('trade');
          }
        }
    },
      methods: {
        getCategories(type){
          axios.get('/api/company/product/categories?page='+this.currentPage+'&limit='+this.limit+'&sortby='+this.sortby+'&order='+this.order+'&search='+this.search+'&marketplace_categories='+type, {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
              }}).then(response => {
            if(type == 'company'){
              this.categories = response.data;
            }
            if(type == 'public'){
              this.public_categories = response.data;
            }
            if(type == 'trade'){
              this.trade_categories = response.data;
            }
            //return response.data;

          }).catch(error => {
            this.response_error = error.data;
          });
        },
        updateActiveCategories(category, type){
          this.loadingActive = true;
            if(type == 'company'){
              var activeCategories = this.$attrs.company_categories.findIndex(item => item == category.id);
              if(activeCategories < 0){
                this.$attrs.company_categories.push(category.id);
              }else{
                this.$attrs.company_categories.splice(activeCategories, 1);
              }
            }
            if(type == 'public'){
             var activeCategories = this.$attrs.public_marketplace_categories.findIndex(item => item == category.id);
              if(activeCategories < 0){
                this.$attrs.public_marketplace_categories.push(category.id);
              }else{
                this.$attrs.public_marketplace_categories.splice(activeCategories, 1);
              }


            }
            if(type == 'trade'){
              var activeCategories = this.$attrs.trade_marketplace_categories.findIndex(item => item == category.id);
              if(activeCategories < 0){
                this.$attrs.trade_marketplace_categories.push(category.id);
              }else{
                this.$attrs.trade_marketplace_categories.splice(activeCategories, 1);
              }
            }
            this.loadingActive = false;
          },
          activeInCategories(category, type){
                      if(type == 'company'){
                        if(this.$attrs.company_categories.length > 0){
                          var activeCategories = this.$attrs.company_categories.findIndex(item => item == category["id"]);
                          if(activeCategories > -1){
                            console.log(true);
                            return true;
                          }else{
                            return false;
                          }
                        }
                      }
                      if(type == 'public'){
                        if(this.$attrs.public_marketplace_categories.length > 0){
                          var activePubCategories = this.$attrs.public_marketplace_categories.findIndex(item => item == category["id"]);
                          if(activePubCategories > -1){
                            return true;
                          }else{
                            return false;
                          }
                        }
                      }
                      if(type == 'trade'){
                        if(this.$attrs.trade_marketplace_categories.length > 0){
                          var activeTradCategories = this.$attrs.trade_marketplace_categories.findIndex(item => item == category["id"]);
                          if(activeTradCategories > -1){
                            return true;
                          }else{
                            return false;
                          }
                        }
                      }

          },
      }
  }


</script>