<script>
  import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import VueSlideBar from "vue-slide-bar";
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import SideSlidePanel from "../../components/widgets/SideSlidePanel";
import vue2Dropzone from 'vue2-dropzone';
import CompanyProductSearch from '../../components/widgets/CompanyProductSearch';
import CompanyProductList from '../../components/widgets/CompanyProductList';
import AccessRestricted from '../../views/utility/access-restricted'

export default{
	components:{vueDropzone: vue2Dropzone, VueSlideBar, Layout, PageHeader, SideSlidePanel, ckeditor: CKEditor.component, CompanyProductSearch, CompanyProductList, AccessRestricted},
	data() {
    	return {
          deleteMode: 'single',
    		  brandsData: [],
    		  brands_error: [],
          thisPage: 1,
          productsPanel: false, 
          productPanelClass: 'edit-sidepanel',
    		  newbrand: {
    		  	name: '',
    		  	short_description: '',
    		  	logo: '',
    		  	banner_image: '',
    		  	isowner: 0,
    		  	allowlisting: 0,
    		  	usecompanyinfo: 0,
    		  	ownercontact: {
    		  		name: '',
    		  		email: '',
    		  		phone: '',
    		  		address1: '',
    		  		address2: '',
    		  		address3: '',
    		  		country_id: '',
    		  		county_id: '',
    		  		postcode: '',

    		  	}
    		  },
          editor: ClassicEditor,
          editBrandPanel: false,
          products: [],
          editBrand: {
            name: '',
            short_description: '',
            logo: '',
            banner_image: '',
            isowner: 0,
            allowlisting: 0,
            usecompanyinfo: 0,
            ownercontact: {
              name: '',
              email: '',
              phone: '',
              address1: '',
              address2: '',
              address3: '',
              country_id: '',
              county_id: '',
              postcode: '',

            }
          },
          quickfilter_options: [
              {text: 'Your Brands', value: false},
              {text: 'Marketplace Brands', value: true},
          ],
          marketplace_brands: false,
    		  view: 'list',
    		  search: '',
    		  limit: '25',
    		  sortby: 'id',
    		  order: 'desc',
    		  selectedids: [],
          error_reponse: [],
          error_message: false,
          success_message: false,
          loadingForm: false, 
          loadingList: false,
          isCheckAll: false,
    		  addNewPanel: false,
          addPanelClass: 'edit-sidepanel',
    		  isCustom: 0,
    		  dropzoneOptions: {
		        url: 'https://httpbin.org/post',
		        thumbnailHeight: 300,
		        thumbnailWidth: 300,
		      },
    		  limits: [
		        {value: 25, text: "25"},
		        {value: 50, text: "50"},
		        {value: 100, text: "100"},
		      ],
		      title: "Brands",
		      items: [
		        {
		          text: "Stock",
		          href: "javascript:;",
		        },
		        {
		          text: "Brands",
		          active: true,
		        },
		      ],
  				}	
	},
	methods:{
    removeFromResource(product){
            axios.post('/api/company/product/brand/'+this.editBrand.id+'/remove-product', {marketplace_brands: this.marketplace_brands, product_id: product.uid}, {
              headers: {
                'Authorization' : 'Bearer '+this.$attrs.user.api_token
              }
            }).then(response => {
              this.$emit('product-removed', response.data);
              this.getBrandProducts();
            }).catch(error => {
              this.$emit('resource-error', error.data);
            });
    },
		brandQuery(){
      this.loadingList = true;
			axios.get('/api/company/product/brands?page='+this.thisPage+'&limit='+this.limit+'&sortby='+this.sortby+'&order='+this.order+'&search='+this.search+'&marketplace_brands='+this.marketplace_brands, {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
				this.brandsData = response.data;
        let self = this;
          setTimeout(function(){
            self.loadingList = false;
          }, 500);
			}).catch(error => {
				this.brands_error = error.data;
        let self = this;
          setTimeout(function(){
            self.loadingList = false;
          }, 500);
			});
		},
    getBrandProducts(){
      this.loadingForm = true;
      axios.get('/api/company/product/brand/'+this.editBrand.id+'/products?marketplace_brands='+this.marketplace_brands, {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
              }}).then(response => {
        this.products = response.data;
        let self = this;
          setTimeout(function(){
            self.loadingForm = false;
          }, 500);
      }).catch(error => {
        this.error_response = error.response.data.errors;
        this.error_message = true;
      });
    },
    setActive(active){
            axios.post('/api/company/product/brand/set-active', {ids: this.selectedids, active: active}, {headers: {
              'Authorization' : 'Bearer '+this.$attrs.user.api_token
            }}).then(response => {
              
              this.success_text = 'Congratulations, your action has been saved';
              this.success_message = true;
              if(this.selectedids.length > 1){
                return this.brandQuery();
              }else{
                return true;
              }
                
            }).catch(error => {

            });
    },
    destroyResource(product_id){
            axios.post('/api/company/product/brand/'+this.selectedids[0]+'/destroy', {}, {headers: {
              'Authorization' : 'Bearer '+this.$attrs.user.api_token
            }}).then(response => {
                this.$bvModal.hide('delete-modal');
                this.selectedids = [];
                return this.brandQuery();
            }).catch(error => {

            });
    },
     bulkDestroyResource(){
            axios.post('/api/company/product/brand/bulk-destroy', {ids: this.selectedids}, {headers: {
              'Authorization' : 'Bearer '+this.$attrs.user.api_token
            }}).then(response => {
                this.$bvModal.hide('delete-modal');
                this.selectedids = [];
                return this.brandQuery();
            }).catch(error => {

            });
     },
    bulkDuplicateResource(){
      axios.post('/api/company/product/brand/duplicate', {ids: this.selectedids}, {headers : {
        'Authorization' : 'Bearer '+this.$attrs.user.api_token
      }}).then(response => {
        console.log(response);
        this.success_text = 'Congratulations, your brand has been duplicated.';
        this.success_message = true;
        //this.resetProjectData();
        this.addPanel = false;
        let self = this;
          setTimeout(function(){
            self.loadingForm = false;
            self.brandQuery();
            //self.$bvModal.hide('move-modal');
          }, 1500);
      }).catch( error => {
        
        this.error_reponse = error.response.data.errors;
        this.error_message = true;
        //this.resetProjectData();
        let self = this;
          setTimeout(function(){
            self.loadingForm = false;
          }, 1500);
      });
    },
    duplicateResource(){
      if(this.newbrand.description.length > 50){
        let self = this;
        setTimeout(function() {
          self.addBrand();
        }, 500);
      }
    },
    addBrand(){
      this.loadingForm = true;
      var postForm = new FormData(document.getElementById("addbrandform"));
      axios.post('/api/company/product/brand/create', postForm, {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
        console.log(response);
        this.success_message = true;
        //this.resetProjectData();
        this.brandQuery();
        this.addNewPanel = false;
        let self = this;
          setTimeout(function(){
            self.loadingForm = false;
          }, 500);
      }).catch( error => {
        
        this.error_reponse = error.response.data.errors;
        this.error_message = true;
        //this.resetProjectData();
        let self = this;
          setTimeout(function(){
            self.loadingForm = false;
          }, 500);
      });
    },
    updateBrand(){
      this.loadingForm = true;
      var postForm = new FormData(document.getElementById("updatebrandform"));
      axios.post('/api/company/product/brand/'+this.editBrand.id+'/update', postForm, {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
        console.log(response);
        this.success_message = true;
        //this.resetProjectData();
        this.brandQuery();
        this.editBrandPanel = false;
        let self = this;
          setTimeout(function(){
            self.loadingForm = false;
          }, 1500);
      }).catch( error => {
        
        this.error_reponse = error.response.data.errors;
        this.error_message = true;
        //this.resetProjectData();
        let self = this;
          setTimeout(function(){
            self.loadingForm = false;
          }, 1500);
      });

    },
     filterSelected(id){
        if(this.selectedids.length > 0){
          var exists = this.selectedids.filter(item => item == id);
          if(exists.length > 0){
            return true;
          }else{
            return false
          }
        }else{
          return false;
        }
      },
      checkAll: function(){ 
                 this.isCheckAll = !this.isCheckAll; 
                 this.selectedids = []; 
                 if(this.isCheckAll){ 
                     // Check all 
                     for (var key in this.brandsData.data) {          
                        this.selectedids.push(this.brandsData.data[key].id); 
                     } 
                 } 
              }, 
      updateCheckall: function(){ 
                 if(this.selectedids.length == this.brandsData.data.length){
                    this.isCheckAll = true; 
                 }else{ 
                    this.isCheckAll = false; 
                 } 
      },
	},
	mounted(){
		this.brandQuery();
	}
}
</script>
<template>
	<Layout :nickname="$attrs.nickname" :user="$attrs.user" :menuItems="$attrs.menuItems">
    <PageHeader :title="title" :items="items" />
    <div class="row">
          <b-col md="12">
                    <transition name="slide-success" mode="out-in">
                  <b-alert id="success-message" variant="success" :show="success_message"  dismissible @dismissed="success_message = !success_message">
                      <p class="font-size-14">Congratulations, your action has been saved.</p>
                    </b-alert>
                  </transition>
                  </b-col>
      <div class="col-md-12">
        <div class="row mb-3">
          <div class="col-xl-1 col-sm-6">
            <div class="mt-2">
              <b-form-select class="form-control" v-model="limit" :options="limits" @input="brandQuery()"></b-form-select>
            </div>
          </div>
         
          <div class="col-lg-2 col-sm-6 d-flex">
          <form class="my-auto">
            <small class="mx-auto my-2 text-center d-block">Results {{brandsData.from}} - {{brandsData.to}} of {{brandsData.total}}</small>
          </form>
        </div>
        <div class="col-lg-3">
          <b-form-group class="my-auto ml-auto mr-3">
            <b-form-radio-group id="btn-radios-1" button-variant="outline-light"
        size="md" v-model="marketplace_brands" :options="quickfilter_options" buttons @input="brandQuery()"></b-form-radio-group>
      
          
          </b-form-group>
        </div>
        <div class="col-lg-3 col-sm-6 d-flex">
          
            <div class="mt-sm-0 form-inline w-100" >
              <div class="search-box mr-2 ml-auto">
                <div class="position-relative">
                  <input
                    type="text"
                    class="form-control w-100 border-0"
                    placeholder="Search name, reference or description..."
                    v-model="search"
                    @keyup.enter="brandQuery()"
                  />
                  <i class="bx bx-search-alt search-icon"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-sm-6 d-flex">
            <div class="d-flex ml-auto">
              <ul class="nav nav-pills product-view-nav">
                
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': view == 'grid' }" href="javascript: void(0);" @click="view = 'grid'">
                    <i class="bx bx-grid-alt"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': view == 'list' }" href="javascript: void(0);" @click="view = 'list'">
                    <i class="bx bx-list-ul"></i>
                  </a>
                </li>
                <li class="nav-item">
                 <b-dropdown text="Bulk Actions" variant="primary" class="rounded">
                  <b-dropdown-item :disabled="($attrs.user.companies[0].userrole.name !== 'Owner') && !$attrs.user.companies[0].permissions.edit.includes($attrs.active_menu.id)" href="javascript: void(0);" @click="setActive(true)">Set Active</b-dropdown-item>
                  <b-dropdown-item :disabled="($attrs.user.companies[0].userrole.name !== 'Owner') && !$attrs.user.companies[0].permissions.edit.includes($attrs.active_menu.id)" href="javascript: void(0);" @click="setActive(false)">Set Inactive</b-dropdown-item>
                  <b-dropdown-item :disabled="($attrs.user.companies[0].userrole.name !== 'Owner') && !$attrs.user.companies[0].permissions.edit.includes($attrs.active_menu.id)" href="javascript: void(0);" @click="bulkDuplicateResource()">Duplicate</b-dropdown-item>
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-item :disabled="($attrs.user.companies[0].userrole.name !== 'Owner') && !$attrs.user.companies[0].permissions.delete.includes($attrs.active_menu.id)" href="javascript: void(0);" v-b-modal.delete-modal @click="deleteMode = 'bulk'">Delete</b-dropdown-item>
                </b-dropdown>
                </li>
                <li class="nav-item">
                 <b-button
                    :disabled="($attrs.user.companies[0].userrole.name !== 'Owner') && !$attrs.user.companies[0].permissions.add.includes($attrs.active_menu.id)"
                    class="btn btn-success btn-rounded mb-2 mr-2 text-white"
                    href="javascript:;"
                    @click="addNewPanel = !addNewPanel"
                  >
                    <i class="mdi mdi-plus mr-1"></i> New
                  </b-button>
                </li>
              </ul>
              <div class="text-sm-right">
                  
                </div>
          </div>
        </div>
      </div>
      <div class="d-flex flex-column justify-content-center h-100 restricted-col" v-if="($attrs.user.companies[0].userrole.name !== 'Owner') && !$attrs.user.companies[0].permissions.read.includes($attrs.active_menu.id)">
        <AccessRestricted></AccessRestricted>
      </div>
      <div v-else>
        <div class="card" v-if="view == 'list'" >
          <div class="card-body p-0">
            <div class="table-responsive">
              <b-overlay
                        id="overlay-background"
                        :show="loadingList"
                        variant="white"
                        opacity="0.85"
                        blur="1px"
                        rounded="md"
                      >
              <table class="table table-centered table-hover table-wrap">
                <thead class="thead-light">
                  <tr>
                     <th scope="col"  style="width: 100px" class="text-center"><div
                        class="form-check form-switch form-switch-sm mb-0 mx-auto p-0 mt-auto d-flex"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input mx-auto my-auto" 
                          type="checkbox"
                          id="SwitchCheckSizesm"
                         
                          v-model="isCheckAll"
                          @click='checkAll()'
                          name="active"
                        />
                    </div></th>
                    <th scope="col"><a :class="{'text-dark': sortby != 'name'}" href="javascript: void(0);" @click="orderDesc = !orderDesc,  sortby = 'name', brandQuery()">Name <i v-if="orderDesc == true" class="bx bx-caret-up"></i><i v-if="orderDesc == false" class="bx bx-caret-down"></i></a></th>
                    <th scope="col">Products</th>
                    <th scope="col" v-if="marketplace_brands == false">Status</th>
                    <th scope="col" v-if="marketplace_brands == false">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="list in brandsData.data" :key="list.id" valign="middle">
                    <td>
                      <div class="text-center" v-if="list.logo">
                      <b-form-checkbox class="select-row-btn" v-model="selectedids" :value="list.id" button-variant="primary" @input="updateSelected" button>
                        <i v-if="filterSelected(list.id)" class="bx bx-check"></i>
                        <b-img-lazy v-else :src="'/image/'+list.logo" alt class="avatar-sm rounded-circle" />
                      </b-form-checkbox>
                    </div>
                    <div class="text-center" v-else >
                        <b-form-checkbox class="select-row-btn" v-model="selectedids" :value="list.id" button-variant="primary" @input="updateSelected" button>

                          <i v-if="filterSelected(list.id)" class="bx bx-check"></i><span class="avatar-title rounded-circle" v-else>{{(list.name).slice(0,1)}}</span></b-form-checkbox>
                    </div> 
                    </td>
                    <td>
                        <h5 class="m-0">{{list.name}}</h5>
                        <p class="text-truncate m-0" style="max-width: 400px;"><small class="text-muted mb-0" v-if="list.short_description != null" v-html="(list.short_description)+'...'"></small></p>
                    </td>

                    <td>
                      <a href="javascript: void(0);" class="btn btn-outline-light btn-rounded" @click="editBrand = list, getBrandProducts(), productsPanel = true"
                        ><i class="bx bx-tag"></i></a></td>
                    <td  v-if="marketplace_brands == false">
                      <div
                        class="form-check form-switch form-switch-sm mb-0 mt-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          :id="'SwitchCheckSizesm'+list.id"
                          v-model="list.active"
                          :value="list.active"
                          name="active"
                          :disabled="($attrs.user.companies[0].userrole.name !== 'Owner') && !$attrs.user.companies[0].permissions.edit.includes($attrs.active_menu.id)" 
                          @change="selectedids = [], selectedids.push(list.id), setActive(list.active)"
                        />
                        <label class="form-check-label" :for="'SwitchCheckSizesm'+list.id" 
                          >Active</label
                        >
                    </div>
                    </td>
                    <td  v-if="marketplace_brands == false">
                      <ul class="list-inline font-size-20 contact-links mb-0">
                        <li  v-if="($attrs.user.companies[0].userrole.name == 'Owner') || $attrs.user.companies[0].permissions.edit.includes($attrs.active_menu.id)" class="list-inline-item px-2"><a v-b-tooltip.hover title="Edit" placement="left" @click="editBrand = list, editBrandPanel = !editBrandPanel"><i class="bx bx-edit-alt"></i>
                          </a>
                        </li>
                        <li class="list-inline-item px-2"  v-if="($attrs.user.companies[0].userrole.name == 'Owner') || $attrs.user.companies[0].permissions.delete.includes($attrs.active_menu.id)"><a v-b-tooltip.hover title="Delete" placement="left"  @click="selectedids = [], selectedids.push(list.id), deleteMode = 'single'" v-b-modal.delete-modal><i class="bx bx-trash-alt"></i>
                          </a>
                        </li>
                        <li class="list-inline-item px-2">
                           <b-dropdown v-b-tooltip.hover title="More Options" placement="left"dropleft class="more-options-dropdown">
			                  <template v-slot:button-content>
			                     <i class="bx bx-dots-horizontal-rounded"></i>
			                  </template>
			                  <b-dropdown-item :disabled="($attrs.user.companies[0].userrole.name !== 'Owner') || !$attrs.user.companies[0].permissions.edit.includes($attrs.active_menu.id)" href="javascript: void(0);" @click="selectedids = [], selectedids.push(list.id), bulkDuplicateResource()">Duplicate</b-dropdown-item>
			                </b-dropdown>
                        </li>
                      </ul>
                    </td>
                  </tr>
              	</tbody>
              </table>
            </b-overlay>
            </div>
             <div class="row">
              <div class="col-lg-12">
                 <b-pagination
                    class="justify-content-center"
                    pills
                    v-model="thisPage"
                    :total-rows="brandsData.total"
                    :per-page="brandsData.per_page"
                    aria-controls="my-table"
                    @input="brandQuery"
                  ></b-pagination>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12">
             <small class="mx-auto my-2 text-center d-block">Records {{brandsData.from}} - {{brandsData.to}} of {{brandsData.total}} // Page {{brandsData.current_page}} of {{brandsData.last_page}}</small>
              </div>
            </div>
          </div>
      </div>
    <div class="row" v-if="view == 'grid'" >
        <div class="col-xl-4"  v-for="list in brandsData.data" :key="list.id">
        <div class="card">
          <div class="bg-soft-primary bg-light border-radius-top-right-1em border-radius-top-left-1em" style="min-height: 250px;">
            <div class="row">
              
              <div class="col-12 align-self-end" style="overflow: hidden; max-height: 250px;" >
                <b-img-lazy v-if="list.banner_image" :src="'/image/'+list.banner_image" alt class="img-fluid  border-radius-top-right-1em border-radius-top-left-1em" style="object-fit: cover; min-height: 250px; " />
             
              </div>
            </div>
          </div>
          <div class="card-body pt-0">
            <div class="row">
              <div class="col-sm-12 text-center">
               <!--<div class="avatar-md mx-auto profile-user-wid mb-4">
                  <img v-if="list.image !== null" class="img-thumbnail rounded-circle h-100" :src="`${list.image}`" alt />
                  <span v-else class="avatar-title rounded-circle">
                    {{list.name.slice(0,1)}}
                  </span>
                </div>-->
                <div class="avatar-md profile-user-wid mx-auto">
                   <div class="text-center" v-if="list.image">
                      <b-form-checkbox class="select-row-btn w-image" v-model="selectedids" :value="list.id" button-variant="primary" @input="updateSelected" button>
                        <i v-if="filterSelected(list.id)" class="bx bx-check"></i>
                        <b-img-lazy v-else :src="'/image/'+list.image" alt class="avatar-md img-thumbnail rounded-circle h-100" />
                      </b-form-checkbox>
                    </div>
                    <div class="text-center" v-else >
                        <b-form-checkbox class="select-row-btn w-image" v-model="selectedids" :value="list.id" button-variant="primary" @input="updateSelected" button>

                          <i v-if="filterSelected(list.id)" class="bx bx-check"></i><span class="avatar-title rounded-circle" v-else>{{(list.name).slice(0,1)}}</span></b-form-checkbox>
                    </div> 
                </div>
                <h4 class="font-size-18 text-truncate mt-3">{{list.name}}</h4>
                <p class="text-muted mb-0 text-truncate" v-html="list.description"></p>
              </div>

              <div class="col-sm-12">
               
              </div>
            </div>
          </div>
          <div class="card-footer  bg-transparent border-top">
                  <div class="d-flex">
                    <a href="javascript:;" @click="selectedCategory = list, getProducts(list.id), getProductsPanel = 1" class="btn btn-outline-light btn-rounded"><i class="bx bx bx-purchase-tag-alt my-1"></i></a>
                    <b-tooltip target="tooltip-add">Add Product</b-tooltip>
                    <a id="tooltip-add" @click="editcollection = list, editPanel = true" class="btn btn-outline-light font-size-16 btn-rounded ml-2 my-auto"><i class="bx bx-pencil"></i></a><b-tooltip target="tooltip-add">Add Product</b-tooltip>
                    <a v-if="($attrs.user.companies[0].userrole.name == 'Owner') || $attrs.user.companies[0].permissions.delete.includes($attrs.active_menu.id)"  class="btn btn-outline-light font-size-16 btn-rounded ml-2 my-auto" v-b-tooltip.hover title="Delete" placement="left"  @click="selected_product_id = list.id, deleteMode = 'single'" v-b-modal.delete-modal><i class="bx bx-trash-alt"></i>
                          </a>
                    <b-dropdown v-b-tooltip.hover title="More Options" placement="left" variant="outline-light" dropup class="more-options-dropdown btn-rounded dropup ml-2" menu-class="dropdown-menu-right">
                        <template v-slot:button-content>
                           <i class="bx bx-dots-horizontal-rounded"></i>
                        </template>
                          <b-dropdown-item :disabled="($attrs.user.companies[0].userrole.name !== 'Owner') && !$attrs.user.companies[0].permissions.edit.includes($attrs.active_menu.id)"  href="javascript: void(0);"  @click="selectedids = [], selectedids.push(list.id), bulkDuplicateResource()">Duplicate</b-dropdown-item>
                      </b-dropdown>
                      <div
                        class="form-check form-switch form-switch-sm my-auto ml-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          :id="`SwitchCheckSize-${list.id}`"
                          v-model="list.active"
                          :value="list.active"
                          name="active"
                          :disabled="($attrs.user.companies[0].userrole.name !== 'Owner') && !$attrs.user.companies[0].permissions.edit.includes($attrs.active_menu.id)" 
                          @change="selectedids = [], selectedids.push(list.id), setActive(list.active)"
                        />
                        <label class="form-check-label" :for="`SwitchCheckSize-${list.id}`" 
                          >Active</label
                        >
                    </div>
                  </div>
          </div>
        </div>
    </div>
    	</div>
    </div>
    </div>
</div>
 <b-modal id="delete-modal" size="md" hide-footer hide-header centered>
        <b-card class="border-radius-1em border shadow-none">
          <h4>Are you sure?</h4>
          <p v-if="deleteMode == 'bulk'">You are about to delete {{selectedids.length}} products. Are you sure you want to do this? This action is not reversable</p>
           <p v-if="deleteMode == 'single'">You are about to delete this category. Are you sure you want to do this? This action is not reversable</p>

           <div class="d-flex">
            <a class="btn btn-danger" v-if="deleteMode == 'bulk'" @click="bulkDestroyResource">
              Delete
            </a>
             <a class="btn btn-danger"  v-if="deleteMode == 'single'" @click="destroyResource">
              Delete
            </a>
            <a class="btn btn-success" @click="$bvModal.hide('delete-modal')">
              Cancel
            </a>
          </div>
        </b-card>
      </b-modal>
    <SideSlidePanel :editPanel="productsPanel" :panelClass="addPanelClass" @close-panel="productsPanel = !productsPanel" :expandable="1"  @expand="productPanelClass = 'edit-sidepanel-large'" @contract="productPanelClass = 'edit-sidepanel'">
      <div class="row px-4">
        
        <div class="col-md-12" v-if="editBrand.length !== 0">
        <div class="d-flex mb-3">
          <img v-if="(editBrand.logo !== '') && (editBrand.logo !== null)" :src="'/image/'+editBrand.logo" class="rounded-circle avatar-lg border mr-4">
          <div class="avatar-md mr-4"  v-else>
          <span class="avatar-title rounded-circle">{{(editBrand.name).slice(0,1)}}</span>
          </div>
          <div class="my-auto mr-auto ml-3"><h4 class="mb-0" >Products from <strong class="text-primary">{{editBrand.name}}</strong></h4>
        
          </div>
           <a id="tooltip-add" @click="addProductToBrand = !addProductToBrand" class="btn btn-outline-light font-size-16 btn-rounded ml-auto mr-3 my-auto"><i class="mdi mdi-plus"></i></a><b-tooltip target="tooltip-add">Add Product</b-tooltip>

        </div>
        <CompanyProductSearch class="mt-1 mb-4" :resource="'brand'" :resource_id="editBrand.id" :api_token="$attrs.user.api_token" :company="$attrs.company" :resource_route="'/api/company/product/brand/'+editBrand.id+'/add-product?marketplace_brands='+marketplace_brands+'&'" @product-added="getBrandProducts"></CompanyProductSearch>
        <b-overlay
                        id="overlay-background"
                        :show="loadingForm"
                        variant="white"
                        opacity="0.85"
                        blur="1px"
                        rounded="md"
                      >
          <CompanyProductList :product_list="products" :company="$attrs.company" @remove-product="removeFromResource"></CompanyProductList>
        </b-overlay>
      </div>
    </div>
     
    </SideSlidePanel>

		<SideSlidePanel :editPanel="addNewPanel" :panelClass="addPanelClass" @close-panel="addNewPanel = !addNewPanel" :expandable="1"  @expand="addPanelClass = 'edit-sidepanel-large'" @contract="addPanelClass = 'edit-sidepanel'">
			<div class="row px-4">
				<div  class="col-md-12" v-if="($attrs.user.companies[0].userrole.name !== 'Owner') && !$attrs.user.companies[0].permissions.add.includes($attrs.active_menu.id)">
          <AccessRestricted :page="false"></AccessRestricted>
        </div>
				<div v-else class="col-md-12">
				<b-card class="shadow-none">
					<h4 class="my-3">Add New Brand</h4>
          <b-overlay
                        id="overlay-background"
                        :show="loadingForm"
                        variant="white"
                        opacity="0.85"
                        blur="1px"
                        rounded="md"
                      >
         <b-form id="addbrandform" method="POST" enctype="multipart/form-data">
                   <input type="hidden" name="_token" :value="csrf">
           <b-row>
                     <b-col md="12">
                    <transition name="slide-error" mode="out-in">
                    <b-alert id="errors" variant="danger" :show="error_message"  dismissible @dismissed="error_message = !error_message">
                      <p class="font-size-14">Oops! Please correct the following errors in your submission</p>
                      <ul>
                        <li v-for="(error, key) in error_reponse">
                          {{error[0]}}
                        </li>
                      </ul>
                    </b-alert>
                    
                  </transition>
                  </b-col>
          </b-row>
					<b-form-group label="Name">
						<b-form-input type="text" name="name" v-model="newbrand.name"></b-form-input>
					</b-form-group>
					<b-form-group class="mt-2" label="Description">
						<b-form-textarea
							placeholder="Enter description"
						    rows="3"
						    max-rows="6"
                v-model="newbrand.short_description"
                name="short_description"
						></b-form-textarea>
					</b-form-group>
           <b-form-group class="mt-2" label="Long Description" name="long_description">
            <input type="hidden" name="long_description" v-model="newbrand.long_description">
            <ckeditor v-model="newbrand.long_description" :editor="editor"></ckeditor>
          </b-form-group>
					<b-form-group class="mt-2" label="Logo">
						<input class="form-control mb-2" name="logo" type="file">
					</b-form-group>
					<b-form-group class="mt-2" label="Cover Image">
						<input class="form-control mb-2" name="banner_image" type="file">
					</b-form-group>
					<b-form-group class="mt-2" label="Website">
						<b-form-input v-model="newbrand.website" type="text" name="website"></b-form-input>
					</b-form-group>
          <b-form-group class="my-3">
                     <div
                        class="form-check form-switch form-switch-sm mb-0 mt-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          id="newbrand-active"
                          v-model="newbrand.active" value="true"
                          unchecked-value="false"
                          name="active"
                        />
                        <label class="form-check-label" for="newbrand-active" 
                          > Active</label
                        >
                    </div>
          </b-form-group>
					<b-form-group class="my-3">
                     <div
                        class="form-check form-switch form-switch-sm mb-0 mt-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          id="newbrand-owner"
                          v-model="newbrand.isowner" value="true"
                          unchecked-value="false"
                          name="isowner"
                          disabled
                        />
                        <label class="form-check-label" for="newbrand-owner" 
                          > I own this brand (coming soon)</label
                        >
                    </div>
					</b-form-group>
          <b-form-group class="my-3" v-if="newbrand.isowner == true">
                     <div
                        class="form-check form-switch form-switch-sm mb-0 mt-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          id="newbrand-allowlisting"
                          v-model="newbrand.allowlisting" value="true"
                          unchecked-value="false"
                          name="allowlisting"
                        />
                        <label class="form-check-label" for="newbrand-allowlisting" 
                          > Allow others to list products under this brand</label
                        >
                    </div>
          </b-form-group>
					<div v-if="newbrand.isowner == true">
					<h5 class="my-3">Add Brand Contact Info</h5>
					<b-form-group>
            <div
                        class="form-check form-switch form-switch-sm mb-0 mt-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          id="newbrand-usecompanyinfo"
                          v-model="newbrand.usecompanyinfo" value="true"
                          unchecked-value="false"
                          name="usecompanyinfo"
                        />
                        <label class="form-check-label" for="newbrand-usecompanyinfo" 
                          > Use Company Contact Info</label
                        >
                    </div>
					</b-form-group>
					</div>
					<div v-if="(newbrand.isowner == true) && (newbrand.usecompanyinfo == false)">

           <b-form-group class="mt-2"  label="Name">
            <b-form-input type="text" v-model="newbrand.ownercontact.name" name="ownercontact[name]"></b-form-input>
          </b-form-group>
          <b-form-group class="mt-2" label="E-mail">
            <b-form-input  type="email" v-model="newbrand.ownercontact.email" name="ownercontact[email]" description="You will need access to this email to verify that you are the domain owner"></b-form-input>
          </b-form-group>
          <b-form-group class="mt-2" label="Telephone">
            <b-form-input  type="text" v-model="newbrand.ownercontact.phone" name="ownercontact[phone]"></b-form-input>
          </b-form-group>
          <b-form-group class="mt-2" label="Address 1">
            <b-form-input  type="text" v-model="newbrand.ownercontact.address1" name="ownercontact[address1]"></b-form-input>
          </b-form-group>
          <b-form-group class="mt-2" label="Address 2">
            <b-form-input type="text" v-model="newbrand.ownercontact.address2" name="ownercontact[address2]"></b-form-input>
          </b-form-group>
          <b-form-group class="mt-2" label="Address 3">
            <b-form-input type="text" v-model="newbrand.ownercontact.address3" name="ownercontact[address3]"></b-form-input>
          </b-form-group>
          <b-form-group class="mt-2" label="Country">
            <b-form-input type="text" v-model="newbrand.ownercontact.country_id" name="ownercontact[country_id]"></b-form-input>
          </b-form-group>
          <b-form-group class="mt-2" label="County/State/Region">
            <b-form-input type="text" v-model="newbrand.ownercontact.county_id" name="ownercontact[county_id]"></b-form-input>
          </b-form-group>
          <b-form-group class="mt-2" label="Postcode/Zip">
            <b-form-input type="text" v-model="newbrand.ownercontact.postcode" name="ownercontact[postcode]"></b-form-input>
          </b-form-group>
					</div>
          <div class="d-flex flex-row mt-3">
          <b-button variant="success" class="w-md w-100" @click="addBrand()"> <i class=" bx bx-save"></i> Save</b-button>
          </div>
        </b-form>
      </b-overlay>
				</b-card>
				</div>
			</div>
		</SideSlidePanel>	
      <SideSlidePanel :editPanel="editBrandPanel" :panelClass="'edit-sidepanel'" @close-panel="editBrandPanel = !editBrandPanel">
      <div class="row px-4">
        <div  class="col-md-12" v-if="($attrs.user.companies[0].userrole.name !== 'Owner') && !$attrs.user.companies[0].permissions.edit.includes($attrs.active_menu.id)">
          <AccessRestricted :page="false"></AccessRestricted>
        </div>
        <div v-else class="col-md-12">
        <b-card>
          <h4 class="my-3">Edit Brand: {{editBrand.name}}</h4>
           <b-overlay
                        id="overlay-background"
                        :show="loadingForm"
                        variant="white"
                        opacity="0.85"
                        blur="1px"
                        rounded="md"
                      >
         <b-form id="updatebrandform" method="POST" enctype="multipart/form-data">
                   <input type="hidden" name="_token" :value="csrf">
           <b-row>
                     <b-col md="12">
                    <transition name="slide-error" mode="out-in">
                    <b-alert id="errors" variant="danger" :show="error_message"  dismissible @dismissed="error_message = !error_message">
                      <p class="font-size-14">Oops! Please correct the following errors in your submission</p>
                      <ul>
                        <li v-for="(error, key) in error_reponse">
                          {{error[0]}}
                        </li>
                      </ul>
                    </b-alert>
                    
                  </transition>
                  </b-col>
          </b-row>
         <b-form-group label="Name">
            <b-form-input type="text" name="name" v-model="editBrand.name"></b-form-input>
          </b-form-group>
          <b-form-group class="mt-2" label="Description">
            <b-form-textarea
              placeholder="Enter description"
                rows="3"
                max-rows="6"
                v-model="editBrand.short_description"
                name="short_description"
            ></b-form-textarea>
          </b-form-group>
           <b-form-group class="mt-2" label="Long Description" name="long_description">
            <input type="hidden" name="long_description" v-model="editBrand.long_description">
            <ckeditor v-model="editBrand.long_description" :editor="editor"></ckeditor>
          </b-form-group>
          <b-form-group class="mt-2" label="Logo">
            <input class="form-control mb-2" name="logo" type="file">
          </b-form-group>
          <b-form-group class="mt-2" label="Cover Image">
            <input class="form-control mb-2" name="banner_image" type="file">
          </b-form-group>
          <b-form-group class="mt-2" label="Website">
            <b-form-input v-model="editBrand.website" type="text" name="website"></b-form-input>
          </b-form-group>
           <b-form-group class="my-3">
                     <div
                        class="form-check form-switch form-switch-sm mb-0 mt-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          id="newbrand-active"
                          v-model="editBrand.active" value="true"
                          unchecked-value="false"
                          name="active"
                        />
                        <label class="form-check-label" for="newbrand-active" 
                          > Active</label
                        >
                    </div>
          </b-form-group>
          <b-form-group class="my-3">
                     <div
                        class="form-check form-switch form-switch-sm mb-0 mt-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          id="newbrand-owner"
                          v-model="editBrand.isowner" value="true"
                          unchecked-value="false"
                          name="isowner"
                          disabled
                        />
                        <label class="form-check-label" for="newbrand-owner" 
                          > I own this brand (coming soon)</label
                        >
                    </div>
          </b-form-group>
          <b-form-group class="my-3" v-if="editBrand.isowner == true">
                     <div
                        class="form-check form-switch form-switch-sm mb-0 mt-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          id="newbrand-allowlisting"
                          v-model="editBrand.allowlisting" value="true"
                          unchecked-value="false"
                          name="allowlisting"
                        />
                        <label class="form-check-label" for="newbrand-allowlisting" 
                          > Allow others to list products under this brand</label
                        >
                    </div>
          </b-form-group>
          <div v-if="editBrand.isowner == 1">
          <h5 class="my-3">Add Brand Contact Info</h5>
          <b-form-group>
            <div
                        class="form-check form-switch form-switch-sm mb-0 mt-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          id="newbrand-usecompanyinfo"
                          v-model="editBrand.usecompanyinfo" value="true"
                          unchecked-value="false"
                          name="usecompanyinfo"
                        />
                        <label class="form-check-label" for="newbrand-usecompanyinfo" 
                          > Use Company Contact Info</label
                        >
                    </div>
          </b-form-group>
          </div>
          <div v-if="(editBrand.isowner == true) && (editBrand.usecompanyinfo == false)">

           <b-form-group class="mt-2"  label="Name">
            <b-form-input type="text" v-model="editBrand.ownercontact.name" name="ownercontact[name]"></b-form-input>
          </b-form-group>
          <b-form-group class="mt-2" label="E-mail">
            <b-form-input  type="email" v-model="editBrand.ownercontact.email" name="ownercontact[email]" description="You will need access to this email to verify that you are the domain owner"></b-form-input>
          </b-form-group>
          <b-form-group class="mt-2" label="Telephone">
            <b-form-input  type="text" v-model="editBrand.ownercontact.phone" name="ownercontact[phone]"></b-form-input>
          </b-form-group>
          <b-form-group class="mt-2" label="Address 1">
            <b-form-input  type="text" v-model="editBrand.ownercontact.address1" name="ownercontact[address1]"></b-form-input>
          </b-form-group>
          <b-form-group class="mt-2" label="Address 2">
            <b-form-input type="text" v-model="editBrand.ownercontact.address2" name="ownercontact[address2]"></b-form-input>
          </b-form-group>
          <b-form-group class="mt-2" label="Address 3">
            <b-form-input type="text" v-model="editBrand.ownercontact.address3" name="ownercontact[address3]"></b-form-input>
          </b-form-group>
          <b-form-group class="mt-2" label="Country">
            <b-form-input type="text" v-model="editBrand.ownercontact.country_id" name="ownercontact[country_id]"></b-form-input>
          </b-form-group>
          <b-form-group class="mt-2" label="County/State/Region">
            <b-form-input type="text" v-model="editBrand.ownercontact.county_id" name="ownercontact[county_id]"></b-form-input>
          </b-form-group>
          <b-form-group class="mt-2" label="Postcode/Zip">
            <b-form-input type="text" v-model="editBrand.ownercontact.postcode" name="ownercontact[postcode]"></b-form-input>
          </b-form-group>
          </div>
          <div class="d-flex flex-row mt-3">
          <b-button variant="success" class="w-md w-100" @click="updateBrand()"> <i class=" bx bx-save"></i> Save</b-button>
          </div>
        </b-form>
      </b-overlay>
        </b-card>
        </div>
      </div>
    </SideSlidePanel> 
	</Layout>
</template>