<template>
	<div class="container-fluid">
    <!--<nav class="nav nav-pills nav-fill mb-3">
      <a v-for="(tab, index) in shipping_tabs" class="nav-item nav-link" href="javascript:;" :class="{active: active === true && current === index}" v-on:click="toggleActive(index)" v-on:mouseover="currentlyShowing(index)">{{tab}}</a>
    </nav>-->
    <b-form>
    <b-row>
    <div class="col-md-12 form-container">
      <b-alert ref="steperrorsalert" variant="danger" v-model="steperrormessage" dismissible @dismissed="steperrormessage = false, steperrors = []">
        <p v-for="error in steperrors" class="text-danger mb-0">{{error}}</p>
      </b-alert>
      <b-form id="shipping">
      <div class="row bg-white">

 <transition name="fade">
       <div class="card shadow-none border border-radius-1em p-3 card-profile col-md-12 no-margin" v-show="current == 0">
        <b-row>
      <b-col md="12">
        <h3 class="mt-4">Shipping Details</h3>
            <hr>
    <b-card class="shadow-none border border-radius-1em p-3">
      <b-form-group
        label="Name"
        label-for="input-1"
      >
        <b-form-input
          v-model="shipping.name"
          type="email"
          required
          placeholder="Enter shipping name"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        label="Transit Time"
        label-for="input-1"
        description="E.g - 3 - 5 working days"
      >
        <b-form-input
          v-model="shipping.transit_time"
          type="email"
          required
          placeholder="Enter transit time"
        ></b-form-input>
      </b-form-group>

      <b-form-group  label="Description" label-for="input-2">
        <b-form-textarea
          id="textarea"
          v-model="shipping.description"
          placeholder="Enter something..."
          rows="3"
          max-rows="6"
        ></b-form-textarea>
      </b-form-group>

      <b-form-group  label="Icon" label-for="input-2">
        <b-form-file
          v-model="shipping.icon"
          required
          placeholder="Add shipping icon"
        ></b-form-file>
      </b-form-group>
    </b-card>
      </b-col>
    </b-row>
  </div>
</transition>
      <transition name="fade">
       <div class="card shadow-none border border-radius-1em p-3 card-profile col-md-12 no-margin" v-show="current == 1">
         <b-row>
      <b-col md="12">
        <h3 class="mt-4">Rate Card</h3>
            <hr>
        <div class="card shadow-none border border-radius-1em p-3 card-profile col-md-12 no-margin" >
          <div class="row">
            <div class="col-md-12 margin-auto pb-4">
      <b-form-group  label="Free Shipping" label-for="radio-group-2" class="mb-3">
        <b-form-radio-group id="radio-group-2" v-model="shipping.free_shipping" name="radio-free-shipping">
           <b-form-radio value="1">Yes</b-form-radio>
           <b-form-radio value="0">No</b-form-radio>
        </b-form-radio-group>
      </b-form-group>

      <b-form-group  label="Flat Rate Shipping" label-for="radio-group-flatrate" class="mb-3">
        <b-form-radio-group id="radio-group-flatrate" v-model="shipping.flat_rate" name="radio-flat-shipping">
           <b-form-radio value="1">Yes</b-form-radio>
           <b-form-radio value="0">No</b-form-radio>
        </b-form-radio-group>
      </b-form-group>
      <b-row v-if="shipping.flat_rate" class="my-3">
        <b-col md="4">
          <b-form-group label="Flat Rate Shipping Exc. VAT">
            <b-input-group prepend="£">
                <b-form-input
                  type="number"
                  required
                  prepend="£"
                  class="shipping-range-value"
                  v-model="shipping.flat_rate_price_exc"
                  placeholder="0.00"
                ></b-form-input>
              </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="4">
          <div class="form-control-group">
                          <label class="">Tax Rule</label>
                        <select class="form-control" v-model.number="active_tax_rule" name="taxrule_id"  v-on:change="activeTaxRule(active_tax_rule)">
                          <option v-for="rule in all_tax_rules" v-bind:value="rule.id">
                        {{rule.code}}: {{rule.percentage}}%</option>
                          </select>
                      </div>
        </b-col>
        <b-col md="4">
          <b-form-group label="Flat Rate Shipping Inc. VAT">
            <b-input-group prepend="£">
                <b-form-input
                  type="number"
                  required
                  prepend="£"
                  class="shipping-range-value"
                  v-model="shipping.flat_rate_price_inc"
                  placeholder="0.00"
                ></b-form-input>
              </b-input-group>
          </b-form-group>
        </b-col>
    </b-row>
      <b-form-group  label="Shipping Type" label-for="radio-group-3" v-if="(shipping.free_shipping == 0) && (shipping.flat_rate == 0)" class="mb-3">
        <b-form-radio-group id="radio-group-3" v-model="shipping.type" name="radio-shipping-type">
          <b-form-radio value="0">Shipping by Weight</b-form-radio>
          <b-form-radio value="1">Shipping by Order Total</b-form-radio>
        </b-form-radio-group>
      </b-form-group>
      <!--<div class="row" v-if="shipping.free_shipping == 0">
         <div class="col-md-2 pr-0">
            <label>Zone</label>
            <div class="card shadow-none border border-radius-1em p-3 d-flex flex-column shipping-range-inputs bg-light h-100">
            </div>
         </div>
          <div class="col-md-1 p-4" v-for="(range, index) in range_values">
            <label>Range</label>
        
        </div>
      </div>-->
      <div class="row" style="overflow-x: auto; transform: rotateX(180deg);"  v-if="shipping.free_shipping == 0">
        <b-overlay :show="loadingRateCard">
      <div class="row mt-4 d-flex flex-nowrap "style="transform: rotateX(180deg);">
        <b-col md="2">
          <div class="card shadow-none border border-radius-1em p-4 d-flex flex-column shipping-range-inputs bg-light" style="height: 105px;" v-if="!'edit_shipping' in $attrs && $attrs.edit_shipping == ''"  >

            <a href="javascript:;" class="btn btn-success my-auto" @click="addNewRange()">Add New Range</a>
          </div>
           <div class="card shadow-none border border-radius-1em p-4 d-flex flex-column shipping-range-inputs bg-light" style="height: 105px;" v-if="'edit_shipping' in $attrs && $attrs.edit_shipping !== ''">

            <a href="javascript:;" class="btn btn-success my-auto" @click="addNewEditRange()">Add New Range</a>
          </div>
          <b-card v-if="!'edit_shipping' in $attrs && $attrs.edit_shipping == ''" v-for="(zone, index) in all_zones.data" class="shadow-none border border-bottom" style="height: 75px;" @click="activateZone(zone)" :class="{'border-success': activeZone(zone) == true}">
            <!--<b-form-checkbox v-model="selected_zones" :value="zone.id" @click="activateZone(zone)">
            </b-form-checkbox>-->
            {{zone.name}} <i class="bx bx-check-circle text-success" v-if="activeZone(zone) == true"></i>
            <small v-else class="text-muted text-italic d-block">Click to Activate</small>
            
          </b-card>
          <b-card v-if="'edit_shipping' in $attrs && $attrs.edit_shipping !== ''" v-for="(zone, index) in first_range" class="shadow-none border border-bottom" style="height: 75px;" @click="activateZoneById(zone.zone_id)" :class="{'border-success': activeZoneById(zone.zone_id) == true}">
            <!--<b-form-checkbox v-model="selected_zones" :value="zone.id" @click="activateZoneById(zone)">
            </b-form-checkbox>-->
            <span v-if="'zone' in zone">{{zone.zone.name}}</span><span v-else>{{zone.zone_name}}</span> <i class="bx bx-check-circle text-success" v-if="activeZoneById(zone.zone_id) == true"></i>
            <small v-else class="text-muted text-italic d-block">Click to Activate</small>
            
          </b-card>
        </b-col>
        <b-col md="2" v-if="!'edit_shipping' in $attrs || $attrs.edit_shipping == ''" v-for="(range, range_index) in range_values">
          <div class="card shadow-none border border-radius-1em p-3 d-flex flex-column shipping-range-inputs" style="height: 105px;" >
          <b-form-input
              type="number"
              required
              v-model="range.start_delimeter"
              placeholder="Enter transit time"
            ></b-form-input>
            <b-form-input
              type="number"
              required
               v-model="range.end_delimeter"
              placeholder="Enter transit time"
            ></b-form-input>
          </div>
          <div class="card shadow-none border border-radius-1em p-3 d-flex flex-row shipping-range-inputs p-0" v-for="(zone, index) in all_zones.data" style="height: 75px;">
             <b-input-group prepend="£">
            <b-form-input
              type="number"
              required
              prepend="£"
              class="shipping-range-value"
              :disabled="checkZoneEnabled(zone.id, range_index)"
              v-model="range.zone_options[index].value"
              placeholder="0.00"
            ></b-form-input>
          </b-input-group>
          </div>
           <div class="card shadow-none border border-radius-1em p-3 d-flex flex-column shipping-range-inputs">
          <button href="javascript:;" class="btn btn-danger" :disabled="range_index < 1" @click="removeRange(range_index)"><i class="bx bx-trash-alt text-white"></i></button>
        </div>
        </b-col>
        <b-col md="2" v-if="'edit_shipping' in $attrs && $attrs.edit_shipping !== ''" v-for="(range, key, range_index) in shipping.grouped_ranges">
          <div class="card shadow-none border border-radius-1em p-3 d-flex flex-column shipping-range-inputs" style="height: 105px;" >
          <b-form-input
              type="number"
              required
              v-model="range[0].start_delimeter"
              placeholder="Start Delimeter"
            ></b-form-input>
            <b-form-input
              type="number"
              required
               v-model="range[0].end_delimeter"
              placeholder="End Delimeter"
            ></b-form-input>
          </div>
          <div class="card shadow-none border border-radius-1em p-3 d-flex flex-row shipping-range-inputs p-0" v-for="(zone, index) in range" style="height: 75px;">
             <b-input-group prepend="£">
            <b-form-input
              type="number"
              required
              prepend="£"
              class="shipping-range-value"
              :disabled="checkZoneEnabled(zone.zone_id, range_index)"
              v-model="range[index].price"
              placeholder="0.00"
            ></b-form-input>
          </b-input-group>
             
          </div>
           <div class="card shadow-none border border-radius-1em p-3 d-flex flex-column shipping-range-inputs">
          <button href="javascript:;" class="btn btn-danger" :disabled="range_index < 1" @click="removeEditRange(key)"><i class="bx bx-trash-alt text-white"></i></button>
        </div>
        </b-col>
      </div>
    </b-overlay>
    </div>
    </div>
   </div>
  </div>
      </b-col>
    </b-row>
      </div>
</transition>
 <transition name="fade">
      <div class="card shadow-none border border-radius-1em p-3 card-profile col-md-12 no-margin" v-show="current == 2">
          <div class="row">
            <div class="col-md-12 margin-auto pb-4">
            <h3 class="mt-4">Shipping Restrictions</h3>
            <hr>
        
            <div class="d-flex mb-3">
            <b-button
                      class="flex-grow-1 my-0 py-3 mr-1 w-sm"
                      value="0"
                      :class="{'active' : shipping.active == (true || 1 || '1')}"
                      variant="outline-light"
                      @click="shipping.active = !shipping.active"
                    ><i v-if="shipping.active == (true || 1 || '1')" class="bx bx-check-circle font-size-24 d-block text-success my-2"></i> 
                    <i v-else class="bx bx-basket font-size-24 d-block text-primary my-2"></i> My Shops
              </b-button>
              <b-button
                      class=" flex-grow-1 my-0 py-3 mx-1 w-sm"
                      value="0"
                      :class="{'active' : shipping.public == (true || 1 || '1')}"
                      variant="outline-light"
                      @click="shipping.public = !shipping.public"
                    ><i v-if="shipping.public == (true || 1 ||  '1')" class="bx bx-check-circle font-size-24 d-block text-success my-2"></i> 
                    <i v-else class="bx bx-basket font-size-24 d-block text-primary my-2"></i> Public Market
              </b-button>
              <b-button
                      class=" flex-grow-1 my-0 py-3 ml-1 w-sm"
                      value="0"
                      :class="{'active' : shipping.trade == (true || 1 || '1')}"
                      variant="outline-light"
                      @click="shipping.trade = !shipping.trade"
                    ><i v-if="shipping.trade == (true || 1 || '1')" class="bx bx-check-circle font-size-24 d-block text-success my-2"></i> 
                    <i v-else class="bx bx-basket font-size-24 d-block text-primary my-2"></i> Trade Market
              </b-button>
            </div>
          <b-tabs class="mt-3" pills>
           <b-tab title="Size, Weight & Quantity">
            <div class="row mt-3">
              <div class="col-md-6 pb-3">
                 <label>Cart Min Quantity</label>
                <b-input-group append ="cm">
                  <b-form-input
                    type="number"
                    required
                    append  ="£"
                    placeholder="0.00"

                  ></b-form-input>
                </b-input-group>
              </div>
               <div class="col-md-6 pb-3">
                 <label>Cart Max Quantity</label>
                <b-input-group append ="cm">
                  <b-form-input
                    type="number"
                    required
                    append  ="£"
                    placeholder="0.00"
                  ></b-form-input>
                </b-input-group>
              </div>
              <div class="col-md-3 pb-4">
                <label>Max width</label>
                <b-input-group append ="cm">
                  <b-form-input
                    type="number"
                    required
                    append  ="£"
                    placeholder="0.00"
                  ></b-form-input>
                </b-input-group>
              </div>
               <div class="col-md-3 pb-4">
                <label>Max length</label>
                <b-input-group append ="cm">
                  <b-form-input
                    type="number"
                    required
                    append  ="£"
                    placeholder="0.00"
                  ></b-form-input>
                </b-input-group>
              </div>
               <div class="col-md-3 pb-4">
                <label>Max depth</label>
                <b-input-group append ="cm">
                  <b-form-input
                    type="number"
                    required
                    append  ="£"
                    placeholder="0.00"
                  ></b-form-input>
                </b-input-group>
              </div>
              <div class="col-md-3 pb-4">
                <label>Max weight</label>
                <b-input-group append ="kg">
                  <b-form-input
                    type="number"
                    required
                    prepend="£"
                    placeholder="0.00"
                  ></b-form-input>
                </b-input-group>
              </div>
            </div>
           </b-tab>
          <b-tab title="Products">
          <b-form-group class="mt-3 mb-2" label="Allow only for selected products">
            <CompanyProductSearch class="mt-3 mb-4" :resource="'category'" :resource_id="''" :api_token="user.api_token" :resource_route="''" @product-added="addProductOptions" ></CompanyProductSearch>
          </b-form-group>
        </b-tab>
        <b-tab title="Categories">
          <b-form-group class="mt-3 mb-2" label="Allow only for selected categories">
            <SelectCategoriesTabButtons :user="user" :company_categories="shipping['categories']['company_categories']" :trade_marketplace_categories="shipping['categories']['trade_marketplace_categories']" :public_marketplace_categories="shipping['categories']['public_marketplace_categories']" :active="shipping.active" :public="shipping.public" :trade="shipping.trade"></SelectCategoriesTabButtons>
          </b-form-group>
        </b-tab>
        <b-tab title="Collections">
          <b-form-group class="mt-3 mb-2" label="Allow only for selected collections">
            <SelectCollectionButtons class="mt-3 mb-4" :resource="'collection'" :resource_id="''" :api_token="user.api_token" :resource_route="''" ></SelectCollectionButtons>
          </b-form-group>
        </b-tab>
       <!-- <b-tab title="Customers">
          <b-form-group class="mt-3 mb-2" label="Allow only for selected customers">
            <CompanyCustomerSearch class="mt-3 mb-4" :resource="'customer'" :resource_id="''" :api_token="user.api_token" :resource_route="''" @search-resource-selected="addCustomerToRestriction" ></CompanyCustomerSearch>
            <CompanyCustomerList :resource="'customer'" :resource_id="''" :api_token="user.api_token" :resource_route="''" :customer_list="shipping.customers" :view="'list'" @remove-resource="removeCustomerFromRestriction"></CompanyCustomerList>
          </b-form-group>
        </b-tab>
        <b-tab title="Emails">
          <b-form-group class="mt-3 mb-2" label="Allow only for selected emails" description="You can copy and paste a comma separated list of email addresses here">
            <b-form-tags separator=", " v-model="shipping.emails"></b-form-tags>
          </b-form-group>
        </b-tab>-->
        
      </b-tabs>

           

    </div>
   </div>
  </div>
</transition>
 <transition name="fade">
      <div class="card shadow-none border border-radius-1em p-3 card-profile col-md-12 no-margin" v-show="current == 3">
          <div class="row">
            <div class="col-md-12 margin-auto pb-4">
            <h3 class="mt-4">Shipping Integration</h3>
            <hr>
            <p>Associate this rate card with a certain provider for shipping requests. Any orders placed using this shipping option will be assigned this courier. Shipping and labels are booked with this courier by default when you process an order and print a shipping label.</p>
            <b-form-radio-group v-model="shipping.use_carrier_integration">
              <b-form-radio value="0">
                Use Manual Shipping Labels 
              </b-form-radio>
              <b-form-radio  value="1">
                Integrate with Courier Service
              </b-form-radio>
            </b-form-radio-group>


    </div>
    <div class="col-md-12 margin-auto pb-4" v-if="shipping.use_carrier_integration == 1">
       <h3>Select Courier</h3>
       <!--<div class="row">
          <div class="col-md-2" v-for="(carrier, index) in all_carriers">
           <div class="card shadow-none border border-radius-1em p-3 mt-4" @click="selectCarrier(carrier), selected_carrier_index = index" :class="{'active': selected_carrier_index == index}">
            
           <div class="card-body text-center d-flex">
            <img :src="carrier.thumbnail" :style="[carrier.key == 'DeutschePost' ? {'width': '50px'} : {'width': '75px'}]" class="my-auto mx-auto">
            <p class="p-2 mx-auto my-auto">{{carrier.name}}</p>
            </div>
          </div>
          </div>
       </div>-->

       <b-row>
          <b-col md="12">

            <ShippingIntegrationsList v-if="loadingCourierList" :user="user" :company="$attrs.company" :active_courier="shipping.integration_courier" @courier-selected="selectCourier($event)"></ShippingIntegrationsList>
          </b-col>
        </b-row>
    </div>
    <div class="col-md-12 margin-auto pb-4" v-if="selected_carrier_integration != ''">
       <h3>{{selected_carrier_integration.name}} Integration Details</h3>
       
       <b-input-group class="mt-3" prepend="Account Email"> 
          <b-form-input type="text"></b-form-input>
       </b-input-group>
       <b-input-group class="mt-3" prepend="Account ID"> 
          <b-form-input type="text"></b-form-input>
       </b-input-group>
       <b-input-group class="mt-3" prepend="API Key"> 
          <b-form-input type="text"></b-form-input>
       </b-input-group>
     <a class="btn btn-purple float-right mt-3">Use Defaults</a>
    </div>
   </div>
  </div>
</transition>
 <transition name="fade">
      <div class="card shadow-none border border-radius-1em p-3 card-profile col-md-12 no-margin" v-show="current == 4">
          <div class="row">
            <div class="col-md-12 margin-auto pb-4">
            <h3 class="mt-4">Review Settings</h3>
            <hr>
            <b-card class="border-radius-1em border shadow-none">
            <h4>Delivery Method Details</h4>
            <strong v-html="shipping.name"></strong>
            <p v-html="shipping.description"></p>
            <strong>Transit Time: {{shipping.transit_time}}</strong>
            </b-card>
            <b-card class="border-radius-1em border shadow-none" >
            <h4>Delivery Rates</h4>
            <div v-if="shipping.free_shipping ">
              <p>Free Worldwide Shipping</p>
            </div>
            <div v-if="(shipping.free_shipping == 0) && (selected_zones.length !== 0)">
              <table class="table table-striped" v-if="!'edit_shipping' in $attrs">
                <thead>
                  <tr class="bg-primary text-white">
                    <th>Range</th>
                    <th :colspan="selected_zones.length">Country Zones</th>
                  </tr>
                  <tr class="bg-light">
                    <th class="text-right"></th>
                    <th v-for="zones in range_values[0].zone_options" v-if="selected_zones.includes(parseFloat(zones.zone_id))">{{zones.zone_name}}</th>
                  </tr>
                </thead>
                <tbody >
                  <tr v-for="delimeters in range_values">
                  <th scope="col" class="text-right" >{{delimeters.start_delimeter}} - {{delimeters.end_delimeter}} </th>
                  <td v-for="(values, rindex) in delimeters.zone_options"  v-if="selected_zones.includes(parseFloat(values.zone_id))">
                  <span class="currency">£</span><span v-if="values.value !== ''">{{parseFloat(values.value).toFixed(2)}}</span>

                </td>
             
                </tr>
                </tbody>
              </table>
              <table class="table table-striped" v-else-if="'edit_shipping' in $attrs">
                <thead>
                  <tr class="bg-primary text-white">
                    <th>Range</th>
                    <th :colspan="selected_zones.length">Country Zones</th>
                  </tr>
                  <tr class="bg-light">
                    <th class="text-right"></th>
                    <th v-for="zones in first_range" v-if="selected_zones.includes(parseFloat(zones.zone_id))">
                      <span v-if="'zone' in zones">{{zones.zone.name}}</span>
                      <span v-else>{{zones.zone_name}}</span>
                      </th>
                  </tr>
                </thead>
                <tbody >
                  <tr v-for="(delimeters, key, index) in shipping.grouped_ranges">
                  <th scope="col" class="text-right" >{{delimeters[0].start_delimeter}} - {{delimeters[0].end_delimeter}} </th>
                  <td v-for="(values, rindex) in delimeters"  v-if="selected_zones.includes(parseFloat(values.zone_id))">
                  <span class="currency">£</span><span v-if="values.price !== ''">{{parseFloat(values.price).toFixed(2)}}</span>

                </td>
             
                </tr>
                </tbody>
              </table>
            </div>
          </b-card>
          <b-card class="border-radius-1em border shadow-none">
            <hr>
            <h4>Delivery Restrictions</h4>
          </b-card>


    </div>
   </div>
  </div>
</transition>
  <div class="col-md-12 pb-3 d-flex">
    <a v-if="current > 0" @click="current--" class="btn btn-primary btn-rounded mr-auto">Previous</a>
    <a v-if="current < (shipping_tabs.length - 1)" @click="validateStep()" class="btn btn-primary btn-rounded ml-auto">Next</a>
   
    <a v-if="current >= (shipping_tabs.length - 1) && 'edit_shipping' in $attrs" @click="updateShippingMethod()" class="btn btn-success btn-rounded ml-auto">Update</a>
     <a v-else-if="current >= (shipping_tabs.length - 1)"  @click="createShippingMethod()" class="btn btn-success btn-rounded ml-auto">Create</a>
  </div>
</div>
</b-form>
      </div>
    </b-row>
</b-form>

		</div>
	</div>
</template>
<script>
  import FilterCompanyProductCategories from "../../../components/widgets/FilterCompanyProductCategories";
  import CompanyProductSearch from "../../../components/widgets/CompanyProductSearch";
  import ShippingIntegrationsList from "../../../components/widgets/integrations/ShippingIntegrationsList";
  import SelectCategoriesTabButtons from '../../../components/widgets/SelectCategoriesTabButtons';
  import SelectCollectionButtons from '../../../components/widgets/SelectCollectionButtons';
  import CompanyCustomerSearch from "../../../components/widgets/CompanyCustomerSearch";
  import CompanyCustomerList from "../../../components/widgets/CompanyCustomerList";
export default {
        components:{ FilterCompanyProductCategories, CompanyProductSearch, ShippingIntegrationsList, SelectCategoriesTabButtons, SelectCollectionButtons, CompanyCustomerSearch, CompanyCustomerList },
        props: {
            companydata: Array,
            user: {
               type: Object,
               default: () => {},
            }
        }, 
      data: function (){
        return{
          loadingRateCard: false,
          loadingCourierList: true,
          steperrors: [],
          steperrormessage: false,
          flip180: this.flipstate,
          all_counties: [],
          selected_carrier_integration: '',
          selected_carrier_index: undefined,
          all_carriers: [ {
            "key": "APC",
            "name": "APC",
            "logo": "https://production.courierapi.co.uk/courier-service-logos/apc.png",
            "status": "beta"
        },
        {
            "key": "AmazonShipping",
            "name": "Amazon Shipping",
            "logo": "https://production.courierapi.co.uk/courier-service-logos/amazon-shipping.jpg",
            "status": "beta",
            "login_supported": true
        },
        {
            "key": "DHL",
            "name": "DHL",
            "logo": "https://production.courierapi.co.uk/courier-service-logos/DHL.png",
            "status": "beta"
        },
        {
            "key": "DPD",
            "name": "DPD",
            "logo": "https://production.courierapi.co.uk/courier-service-logos/DPD.png",
            "status": "beta"
        },
        {
            "key": "DPDLocal",
            "name": "DPD Local",
            "logo": "https://production.courierapi.co.uk/courier-service-logos/DPDlocal.png",
            "status": "beta"
        },
        {
            "key": "DeutschePost",
            "name": "Deutsche Post",
            "logo": "https://production.courierapi.co.uk/courier-service-logos/Deutsche_Post.png",
            "status": "beta"
        },
        {
            "key": "Fedex",
            "name": "Fedex",
            "logo": "https://production.courierapi.co.uk/courier-service-logos/fedex.png",
            "status": "beta"
        },
        {
            "key": "Hermes",
            "name": "Hermes",
            "logo": "https://production.courierapi.co.uk/courier-service-logos/hermes.png",
            "status": "beta"
        },
        {
            "key": "HermesCorporate",
            "name": "Hermes Corporate",
            "logo": "https://production.courierapi.co.uk/courier-service-logos/hermes.png",
            "status": "beta"
        },
        {
            "key": "LowCostParcels",
            "name": "LowCostParcels",
            "logo": "https://production.courierapi.co.uk/courier-service-logos/lowcostparcels.png",
            "status": "beta"
        },
        {
            "key": "Parcelforce",
            "name": "Parcelforce",
            "logo": "https://production.courierapi.co.uk/courier-service-logos/parcelforce.png",
            "status": "beta"
        },
        {
            "key": "RoyalMail",
            "name": "Royal Mail",
            "logo": "https://production.courierapi.co.uk/courier-service-logos/royal-mail.png",
            "status": "beta"
        },
        {
            "key": "SecuredMail",
            "name": "Secured Mail",
            "logo": "https://production.courierapi.co.uk/courier-service-logos/securedmail.png",
            "status": "beta"
        },
        {
            "key": "TNT",
            "name": "TNT",
            "logo": "https://production.courierapi.co.uk/courier-service-logos/tnt.png",
            "status": "beta"
        },
        {
            "key": "UKMail",
            "name": "UK Mail",
            "logo": "https://production.courierapi.co.uk/courier-service-logos/ukmail.png",
            "status": "beta"
        },
        {
            "key": "Whistl",
            "name": "Whistl",
            "logo": "https://production.courierapi.co.uk/courier-service-logos/whistl.png",
            "status": "beta"
        }],
          listView: true, 
          gridView: false,
          first_range: '',
          current: 0,
          all_tax_rules: [],
          active_tax_rule: '',
          active_tax_percentage: '',
          all_zones: [],
          selected_zones: [],
          range_values:[
            {
              company_shipping_option_id:'',
              start_delimeter:'',
              end_delimeter: '',
              price: 0.00,
              zone_options:[],
              zone_id: '',
              active: 0,
              created_at: '',
              updated_at: '',
            }
          ],
          shipping_tabs: ['Shipping Option Information', 'Costs', 'Restrictions', 'Integrations', 'Settings'],
          shipping: '',
          shippingRaw:{
            name: '',
            icon: '',
            categories: [],
            free_shipping: 0,
            flat_rate:0,
            handling_fee:0,
            flat_rate_price_exc:0,
            flat_rate_price_inc:0,
            tax_rule_id:0,
            type: 0,
            active: false,
            public: false,
            trade: false,
            categories: {
              company_categories: [],
              trade_marketplace_categories: [],
              public_marketplace_categories: [],
            },
            products: [], 
            customers: [],
            emails: [],
            groups:[],
            use_carrier_integration: 0,
            integration_courier: {},
            created_at: '',
            updated_at: '',
          }
        }
      },
      created(){
        
        this.getTaxRules();
        this.getCountryZones();
        this.getAllCouriers();
        if('edit_shipping' in this.$attrs){
          this.shipping = this.$attrs.edit_shipping;
          this.first_range = this.shipping.grouped_ranges[Object.keys(this.shipping.grouped_ranges)[0]];
          if(this.shipping.categories == null) {
            this.shipping.categories = JSON.parse(JSON.stringify(this.shippingRaw.categories));
          }
          //this.buildRangeValues();
        }else{
          this.shipping = JSON.parse(JSON.stringify(this.shippingRaw));
        }
      },
      methods: {
        getAllCouriers(){
          axios.get(
              'https://production.courierapi.co.uk/api/couriers/v1/list-couriers', 
              {
                headers: 
                {
                  'Content-Type': 'application/json',
                  'api-user': 'getCarriers',
                  'api-token':  'griokptfhmzvbaqy'
                }
              }).then(response => {
                //console.log(response);
                if('edit_shipping' in this.$attrs){
                  this.all_carriers = response.data.couriers;
                  return this.selectCourier('');
                }else{
                  this.all_carriers = response.data.couriers;
                  }
              }).catch(error => {
                  console.log(error.errors);
              });
        },
        selectCourier(id){
          this.loadingCourierList = false;
          if(id == ''){
            var courier = this.all_carriers.filter(item => item.key == this.shipping.company_shipping_integration);
            if(courier){
              this.shipping["integration_courier"] = courier[0];
            }
          }else{
            var courier = this.all_carriers.filter(item => item.key == id.courier);
            if(courier){
              this.shipping["integration_courier"] = courier[0];
            }
          }
          this.loadingCourierList = true;
        },
        activeTaxRule(id){
            var activeObject = this.all_tax_rules.filter(item => item.id === id);
            console.log(activeObject);
            this.active_tax_percentage = activeObject[0].percentage;
            return activeObject[0].percentage;
          },
          buildRangeValues(zoneData){
            var zones = zoneData;
            Object.keys(this.shipping.grouped_ranges).forEach(key => {
              console.log('range key '+key);
                //var i = 0;
                for(var i = 0; i <= (zones.length - 1); i++){
                  if(this.shipping.grouped_ranges[key].findIndex(item => item.zone_id == zones[i].id) == -1){
                  this.shipping.grouped_ranges[key].push({"countries": zones[i].countries, "zone_name": ''+zones[i].name+'', "zone_id": ''+zones[i].id+'', "value": '', "active": 0, "price": 0.00});
                  }else{
                     if(this.selected_zones.findIndex(item => item == zones[i].id) == -1){
                      this.selected_zones.push(zones[i].id);
                    }
                  }
                }
            });
          },
          getCountryZones(){
            axios.get('/api/country/zones', {headers: {
                  'Authorization': 'Bearer ' + this.user.api_token
              }}).then(response =>{
            this.all_zones = response;
            if('edit_shipping' in this.$attrs){
                return this.buildRangeValues(response.data);
            }else{
              for(var i = 0; i <= (JSON.parse(this.all_zones.data.length) - 1); i++ ){
                this.range_values[0].zone_options.push({"countries": this.all_zones.data[i].countries, "zone_name": ''+this.all_zones.data[i].name+'', "zone_id": ''+this.all_zones.data[i].id+'', "value": '', "active": 0, "price": 0.00})
                }
            }
            });

        },
        getTaxRules(){
          axios.get('/api/company/product/taxrules', {headers: {
                  'Authorization': 'Bearer ' + this.user.api_token
              }}).then(response => {
                 console.log(response);
                this.all_tax_rules = response.data;
                this.active_tax_rule = response.data[0].id;
                //this.brandsearch_results = this.all_brands;
            });
        },
        validateStep(){
          this.steperrors = [];
          var errors = [];
          if(this.current == 0){
            if((this.shipping.name == null) || (this.shipping.name.length == 0)){
              errors.push('Please enter a shipping name');
            }
            if((this.shipping.transit_time == null) || (this.shipping.transit_time.length == 0)){
              errors.push('Please enter a shipping name');
            }
            if((this.shipping.description == null) || (this.shipping.description.length == 0)){
              errors.push('Please enter a shipping name');
            }
           
          }else if(this.current == 1){
            if( (this.shipping.free_shipping == 0) ){
              if(this.selected_zones.length == 0){
                errors.push('Please activate a shipping zone or set free shipping');
              }
            }
          }
           if(errors.length == 0){
              return this.current++;
            }else{
              this.steperrors = errors; 
              this.steperrormessage = true;
              const el = this.$refs.steperrorsalert;

                  if (el) {
                    // Use el.scrollIntoView() to instantly scroll to the element
                    el.scrollIntoView({behavior: 'smooth'});
                  }
            }
        },
        filterSelectedCategories(payload){

        },
        filterSelectedProduct(payload){

        },
        getCarriersList(){
                    },
          selectCarrier(carrier){
            this.selected_carrier_integration = carrier;
          },
          addNewRange(){
            var current_index = this.range_values.length;
            this.range_values.push(
            {
              company_shipping_option_id:'',
              start_delimeter:'',
              end_delimeter: '',
              price: 0.00,
              zone_options:[],
              zone_id: '',
              active: 0,
              created_at: '',
              updated_at: '',
            }
          );
             for(var i = 0; i <= (JSON.parse(this.all_zones.data.length) - 1); i++ ){
              this.range_values[current_index].zone_options.push({"countries": this.all_zones.data[i].countries, "zone_name": ''+this.all_zones.data[i].name+'', "zone_id": ''+this.all_zones.data[i].id+'', "value": '', "active": 0, "price": 0.00})
              }
          },
          removeRange(index){
            this.range_values.splice(index, 1);
          },
          addNewEditRange(){
            this.loadingRateCard = true;
            var current_index = this.shipping.grouped_ranges.length;
            var key = Object.keys(this.shipping.grouped_ranges);
            var keyInt = parseInt(key[key.length - 1]);
            keyInt = keyInt + 1;
            this.shipping.grouped_ranges[keyInt] = [];
            /*this.shipping.grouped_ranges[keyInt].push(
            {
              company_shipping_option_id:'',
              start_delimeter:'',
              end_delimeter: '',
              price: 0.00,
              zone:[],
              zone_id: '',
              active: 0,
              created_at: '',
              updated_at: '',
            }
          );*/
             for(var i = 0; i <= (JSON.parse(this.first_range.length) - 1); i++ ){
                if(!'zone' in this.first_range[i]){
                  this.shipping.grouped_ranges[keyInt].push({"countries": this.first_range[i].countries, "zone_name": ''+this.first_range[i].zone_name+'', "zone_id": ''+this.first_range[i].zone_id+'', "value": '', "active": this.first_range[i].active, "price" : this.first_range[i].price});
                }else{
                  this.shipping.grouped_ranges[keyInt].push({"countries": this.first_range[i].countries, "zone_name": ''+this.first_range[i].zone_name+'', "zone_id": ''+this.first_range[i].zone_id+'', "value": '', "active": this.first_range[i].active, "price" : this.first_range[i].price});
                }
              }
              let self = this;
              setTimeout(function(){
                self.loadingRateCard = false;
              });
          },
          removeEditRange(key){
            this.loadingRateCard = false;
            delete this.shipping.grouped_ranges[key];
            let self = this;
              setTimeout(function(){
                self.loadingRateCard = false;
              });
          },
          checkZoneEnabled(id, range_index){
            var return_value = '';
            var active_zone = this.selected_zones.filter(item => item == id);

            if(active_zone.length == 0){
              return true;
            }
            if(active_zone.length > 0){
              return false;
            }
          },
          activateZone(zone){
            var active_zone = this.selected_zones.findIndex(item => item == parseInt(zone.id));
            if(active_zone > -1){
              this.selected_zones.splice(active_zone, 1);
            }else{
              this.selected_zones.push(parseInt(zone.id));
            }
          },
          activeZone(zone){
            var active_zone = this.selected_zones.findIndex(item => item == parseInt(zone.id));
            if(active_zone > -1){
              return true;
            }else{
              return false;
            }
          },
          activateZoneById(zone_id){
            var active_zone = this.selected_zones.findIndex(item => item == parseInt(zone_id));
            if(active_zone > -1){
              this.selected_zones.splice(active_zone, 1);
            }else{
              this.selected_zones.push(parseInt(zone_id));
            }
          },
          activeZoneById(zone_id){
            var active_zone = this.selected_zones.findIndex(item => item == parseInt(zone_id));
            if(active_zone > -1){
              return true;
            }else{
              return false;
            }
          },
          onCancel(evt){

          },
          handleSubmit () {
                this.$refs.modal.hide()
          },
          onSubmit: function () {
              this.$refs.invisibleRecaptcha.execute()
          },
          onVerify: function (response) {
             console.log('Verify: ' + response);
             this.claim_listing_id = this.selected_modal.id;
             this.claim_step++;
          },
          currentlyShowing: function(index){
            //this.current = index;
          },
          toggleActive: function(index){
            this.active = true;
            this.current = index;
          },
          onExpired: function () {
               console.log('Expired')
          },
          resetRecaptcha () {
            this.$refs.recaptcha.reset() // Direct call reset method
          },
          getLogo(avatar){
            return "http://localhost:7080/gemesislabs-framework/public/storage/"+avatar;
          },
          createShippingMethod(){
            axios.post('/api/company/order/delivery-option/create', {shipping_option: this.shipping, range_values: this.range_values, selected_zones: this.selected_zones}, {headers: {
              'Authorization' : 'Bearer '+this.user.api_token
            }}).then(response => {
              return this.$emit('delivery-option-created', response.data);
            }).catch(error => {

            });
          },

          updateShippingMethod(){
            axios.post('/api/company/order/delivery-option/'+this.shipping.id+'/update', {shipping_option: this.shipping, selected_zones: this.selected_zones}, {headers: {
              'Authorization' : 'Bearer '+this.user.api_token
            }}).then(response => {
              return this.$emit('delivery-option-created', response.data);
            }).catch(error => {

            });
          }

      },
    }
</script>