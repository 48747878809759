<script type="text/javascript">
import Layout from '../../layouts/main'
import PageHeader from '../../components/page-header'
import GemstoneWeights from '../../components/widgets/weight-charts/GemstoneWeights';
import DiamondWeights from '../../components/widgets/weight-charts/DiamondWeights';	
	export default{
		components: {Layout, PageHeader, GemstoneWeights, DiamondWeights},
		data: function(){  
    return{
        isLoading: false,
        searchquery: '',
        searchresults: [],
        isSearchResult: false,
        isLoading: false,
        title: 'Charts',
      items: [
        {
          text: 'Educate',
          href: '/educate',
        },
        {
          text: 'Tools',
          active: true,
        },
        {
          text: 'Charts',
          active: true,
        },
      ],
      }
    },
	}
</script>
<template>
	<Layout>
		<PageHeader :title="title" :items="items" />
		<div class="checkout-tabs">
		<b-tabs pills vertical  nav-wrapper-class="col-lg-2">
			<b-tab active>
				<template v-slot:title>
                    <!--<i class="bx bxs-truck d-block check-nav-icon mt-4 mb-2"></i>-->
                    <p class="font-weight-bold my-4">Gemstone Weights</p>
                </template>
                <b-card>
					<GemstoneWeights />
				</b-card>
			</b-tab>
			<b-tab>
				<template v-slot:title>
                    <!--<i class="bx bxs-truck d-block check-nav-icon mt-4 mb-2"></i>-->
                    <p class="font-weight-bold my-4">Diamond Weights</p>
                </template>
                <b-card>
					<DiamondWeights />
				</b-card>
			</b-tab>
			<b-tab>
				<template v-slot:title>
                    <!--<i class="bx bxs-truck d-block check-nav-icon mt-4 mb-2"></i>-->
                    <p class="font-weight-bold my-4">Baguette Weights</p>
                </template>
                <b-card>
					<GemstoneWeights />
				</b-card>
			</b-tab>
		</b-tabs>
		</div>
	</Layout>
</template>