<template>
    <div>
           
            <component :is="component_name" :id="id" />
    </div>
</template>
<script>
export default {
    props: {
        component: {
            type: String,
            required: true
        },
        id: {
            type: String,
            required: false,
            default: () => ""
        },
        
    },
    data(){
        return {
            hasstripe_id: false,
        }
    },
    created(){
        console.log(this.$root.$options);

    },
    computed: {
        component_name() {
            return !!this.$root.$options.components[this.component] ? this.component : "pages-404";
        }
    }
};
</script>
