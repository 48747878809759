<script type="text/javascript">
	import VueSlideBar from "vue-slide-bar";
	import SelectCompanyCategoryTree from "../../components/widgets/SelectCompanyCategoryTree";
	import CKEditor from "@ckeditor/ckeditor5-vue";
	import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
	import vue2Dropzone from 'vue2-dropzone';
	export default{
		props: {
			id: {
				type: String,
				default: null
			},
			shopsData: {
				type: Object,
			},
			listing_types: {
				type: Array,
				default: () => []
			},
		},
		components: { VueSlideBar, SelectCompanyCategoryTree, vueDropzone: vue2Dropzone, ckeditor: CKEditor.component },
		data(){
			return {
				editor: ClassicEditor,
				loadAllCategories: true,
				limits: [
	            {value: 25, text: "25"},
	            {value: 50, text: "50"},
	            {value: 100, text: "100"},
	          ],
				shopslimit: 25,
		      shopscurrentPage: 1,
		      shopsearch: '',
		      shop_type_id: '',
		      shopsortby: 'id',
		      shoporder: 'desc',
				service_types: [],
				floatImage: false,
				addNewPanel: false,
				loadingForm: false,
				image: '',
				cover_image: '',
				view: 'basic-info',
				service_categories: [],
				tax_rules: '',
				success_message: false,
				success_text: '',
				error_reponse: '',
				error_message: false,
				available_days: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday','Saturday','Sunday'],
				booking_units: [[0,0]],
				booking_days: [],
				booking_times: [],
				periods: ['Hour(s)','Day(s)','Week(s)','Month(s)','Year(s)'],
      			numbers: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30],
				dropzoneOptions: {
		        		url: 'https://httpbin.org/post',
		        		thumbnailHeight: 150,
		        		thumbnailWidth: 150,
		        		autoProcessQueue: false,
		      	},
				newservice: {
					listings: [],
		            uid: '',
		            reference: '',
		            name: '',
		            slug: '',
		            short_description: '',
		            image: '',
		            banner_image: '',
		            company_service_category_id: 0,
		            company_service_type_id: 1,
		            isowner: 0,
		            tax_rule: {id: 0},
		            tax_rule_id: null,
		            price_exc: parseFloat(0.00).toFixed(2),
		            price_inc: parseFloat(0.00).toFixed(2),
		            meta_description: '',
		            meta_title: '',
		            active: false,
		            trade_active: false,
		            public_active: false,
		            allow_bookings: false,
		            booking_times: [],
		            booking_days: [],
		            set_duration: false,
		            duration: 1, 
		            duration_units: '',
		            max_units: 0,
		            service_deposit: 0.00,
		            ownercontact: {
		              name: '',
		              email: '',
		              phone: '',
		              address1: '',
		              address2: '',
		              address3: '',
		              country_id: '',
		              county_id: '',
		              postcode: '',

		            }
		          },
			}
		},
		created(){
			this.view = this.$attrs.url_view;
			this.newservice = this.$attrs.product;
			if(this.$attrs.product.booking_days == null){
				this.newservice.booking_days = [];
			}
			if(this.$attrs.product.booking_times == null){
				this.newservice.booking_times = [];
			}
			if(this.id == 'add'){
				this.view = 'basic-info';
				this.updateHistory(this.view);
			}
		},
		beforeMount(){
			this.getAllCategories();
			this.getTaxRules();
			this.getAllTypes();
      this.getAllFrequencies();
			
		},
		methods: {
			redirect(path){
				
				window.history.pushState({}, 'Services', path);
				window.location.pathname = path;
			},
			getShops(key, data){
				this.$emit('update-listings', key, data);
			},
			removeFromListing(id){
          	var index = this.newservice.listings.findIndex(item => item.listing_id == id);
           		if(index !== -1){
            		this.newservice.listings.splice(index, 1);
           		}
          },
			quantityInListing(id){
	           var index = this.newservice.listings.findIndex(item => item.listing_id == id);
	           if(index !== -1){
	            return true;
	           }else{
	            return false;
	           }
	          },
	          listingQuantity(value){
	            return this.newservice.listings.findIndex(item => item.listing_id == value);
	            //return items[0];
	          },
			setDayActive(day, event, index){
				console.log(event);
				if(event == 1){
					this.newservice.booking_days.splice(index, 0, day);
					this.newservice.booking_times.splice(index, 0, ['09:00', '17:00']);
				}else{
					this.newservice.booking_days.splice(this.newservice.booking_days.findIndex(item => item == day), 1);
					this.newservice.booking_times.splice(this.newservice.booking_days.findIndex(item => item == day), 1);
				}
			},
			coverImageAdded(file){
		      this.banner_image = file;
		    },
		    imageAdded(file){
		      this.image = file;
		    },

			addService(addnew){
		      this.loadingForm = true;
		      	var postForm = new FormData(document.getElementById("addserviceform"));
		      	var query = '/api/company/service/create';
		      if(this.newservice.uid !== ''){
		        query = '/api/company/service/'+this.newservice.uid+'/update';
		      }
		      if(this.image !== ''){
		      postForm.append('image', this.image);
		      }
		      if(this.cover_image !== ''){
		      postForm.append('cover_image', this.cover_image);
		      }
		      postForm.append('booking_times', JSON.stringify(this.newservice.booking_times));
		      postForm.append('booking_days', JSON.stringify(this.newservice.booking_days));
		      postForm.append('listings', JSON.stringify(this.newservice.listings));
		      axios.post(query, postForm, {headers : { 'Authorization' : 'Bearer '+this.$attrs.user.api_token }}).then(response => {
		        console.log(response);
		        this.success_message = true;
		        //this.resetProjectData();
		        
		        if(addnew == true){
		        	//window.history.pushState({}, 'Services', '/service/details/add#basic-info');
		        	window.location.hash = 'basic-info';
		        	window.location.pathname = '/service/details/add';
		        }else{
		        let self = this;
		          setTimeout(function(){
		            self.loadingForm = false;
		            self.image = '';
		            self.banner_image = '';
		          }, 500);
		         }
		      }).catch( error => {
		        
		        this.error_reponse = error.response.data.errors;
		        this.error_message = true;
		        //this.resetProjectData();
		        let self = this;
		          setTimeout(function(){
		            self.loadingForm = false;
		          }, 500);
		      });
		    },
		    addCategory(id){
      this.newservice.company_service_category_id = id;
    	},
			removeTax(price, rule){
	            var tax_rule = rule;
	            console.log(tax_rule);
	            var country_id = this.$attrs.company.country_id;
	       
	          if(tax_rule !== null && tax_rule.country_rules.length > 0){
	            var selected_rule = null;
	            var selected_ruleIndex = -1;
	            for(var ti = 0; ti < tax_rule.country_rules.length; ti++){
	              selected_ruleIndex = tax_rule.country_rules[ti].countries.findIndex(item => item == country_id);
	              if(selected_ruleIndex !== -1){
	                selected_rule = tax_rule.country_rules[ti];
	                ti = tax_rule.country_rules.length;
	              }
	            }
	            console.log(selected_rule);
	            if(tax_rule.type == 1){
	                if(selected_rule !== null){
	                  var percentage = (parseFloat(selected_rule.value) / 100) + 1;
	                  this.active_tax_percentage = percentage;
	                  return price / percentage;
	                }else{
	                  return price;
	                }
	              }else{
	                if(selected_rule !== null){
	                  this.active_tax_percentage = selected_rule.value;
	                  return price - selected_rule.value;
	                }else{
	                  return price;
	                }
	              }
	            }else if(tax_rule !== null && tax_rule.country_rules.length > 0){
	              var percentage = (parseFloat(tax_rule.percentage) / 100) + 1;
	              return price / percentage;
	            }else {
	              return price;
	            }
	        },
	    	addTax(price, rule){
	            var tax_rule = rule;
	            console.log(tax_rule);
	            var country_id = this.$attrs.company.country_id;
	       
	          if(tax_rule !== null && tax_rule.country_rules.length > 0){
	            var selected_rule = null;
	            var selected_ruleIndex = -1;
	            for(var ti = 0; ti < tax_rule.country_rules.length; ti++){
	              selected_ruleIndex = tax_rule.country_rules[ti].countries.findIndex(item => item == country_id);
	              if(selected_ruleIndex !== -1){
	                selected_rule = tax_rule.country_rules[ti];
	                ti = tax_rule.country_rules.length;
	              }
	            }
	            console.log(selected_rule);
	            if(tax_rule.type == 1){
	                if(selected_rule !== null){
	                  var percentage = (parseFloat(selected_rule.value) / 100) + 1;
	                  this.active_tax_percentage = percentage;
	                  return price * percentage;
	                }else{
	                  return price;
	                }
	              }else{
	                if(selected_rule !== null){
	                  this.active_tax_percentage = selected_rule.value;
	                  return price + selected_rule.value;
	                }else{
	                  return price;
	                }
	              }
	            }else if(tax_rule !== null && tax_rule.country_rules.length > 0){
	              var percentage = (parseFloat(tax_rule.percentage) / 100) + 1;
	              return price * percentage;
	            }else {
	              return price;
	            }
	        },
          	getAllTypes(){
		      axios.get('/api/company/service/types', {headers: {
		                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
		              }}).then(response => {
		        this.service_types = response.data;
		      }).catch(error => {
		        this.brands_error = error.data;
		      });
		    },
		    getAllFrequencies(){
		      axios.get('/api/company/service/frequencies', {headers: {
		                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
		              }}).then(response => {
		        this.service_frequencies = response.data;
		      }).catch(error => {
		        this.brands_error = error.data;
		      });
		    },
	        getTaxRules(){
		      axios.get('/api/company/order/tax-rules?limit=0&sortby='+this.sortby+'&order='+this.order, {headers: {
		        'Authorization' : 'Bearer '+this.$attrs.user.api_token
		      }}).then(response => {
		        console.log(response);
		        this.tax_rules = response.data;
		      }).catch(error => {
		        this.error_reponse = {error: ["Could Not Load Tax Rules"]};
		        this.error_message = true;
		      });
		    },
		    getAllCategories(){
		    	this.loadAllCategories = true;
		      axios.get('/api/company/service/categories?page='+this.currentPage+'&limit='+this.limit+'&sortby='+this.sortby+'&order='+this.order+'&search='+this.search, {headers: {
		                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
		              }}).then(response => {
		        this.service_categories = response.data;
		        this.loadAllCategories = false;
		      }).catch(error => {
		        this.brands_error = error.data;
		      });
		    },
		     updateHistory(hash){
            	window.history.pushState({}, '', '#'+hash);
          	},
		}
	}
</script>

<template>
	<b-row>
		<b-col md="3" lg="2">
			 <div class="product-nav">
        <b-card no-body class="py-2">
            <b-list-group flush class="pl-3">
              <b-list-group-item 
               @click="view = 'basic-info', updateHistory(view)"
               class="nav-tab d-flex"
               :class="{'active' : view == ('basic-info' || '')}">
                <i :class="{'text-primary' : view !== 'basic-info', 'text-white' : view == 'basic-info'}" class="my-auto font-size-16 mr-1 bx bx-info-circle"></i> Basic info
              </b-list-group-item>
            <b-list-group-item 
               @click="view = 'bookings', updateHistory(view)"
               class="nav-tab d-flex"
               :class="{'active' : view == 'bookings'}">
               <i :class="{'text-primary' : view !== 'bookings', 'text-white' : view == 'bookings'}" class="my-auto font-size-16 mr-1 bx bx-list-plus"></i> Bookings
              </b-list-group-item>
               <b-list-group-item 
               @click="view = 'pricing', updateHistory(view)"
               class="nav-tab d-flex"
               :class="{'active' : view == 'pricing'}">
               <i :class="{'text-primary' : view !== 'pricing', 'text-white' : view == 'pricing'}" class="my-auto font-size-16 mr-1 bx bx-pound"></i> Pricing
              </b-list-group-item>
              <b-list-group-item 
               @click="view = 'listings', updateHistory(view)"
               class="nav-tab d-flex"
               :class="{'active' : view == 'listings'}">
               <i :class="{'text-primary' : view !== 'listings', 'text-white' : view == 'listings'}" class="my-auto font-size-16 mr-1 bx bxs-store"></i> Locations
              </b-list-group-item>
             
              
              <!--<b-list-group-item 
               @click="view = 'shipping', updateHistory(view)"
               class="nav-tab d-flex"
               :class="{'active' : view == 'shipping'}">
               <i :class="{'text-primary' : view !== 'shipping', 'text-white' : view == 'shipping'}" class="my-auto font-size-16 mr-1 bx bxs-truck"></i>  Shipping
              </b-list-group-item>
              -->
              <b-list-group-item 
               @click="view = 'meta', updateHistory(view)"
               class="nav-tab d-flex"
               :class="{'active' : view == 'meta'}">
                <i :class="{'text-primary' : view !== 'meta', 'text-white' : view == 'meta'}" class="my-auto font-size-16 mr-1 bx bx-globe"></i> Meta
              </b-list-group-item>
              <!--<b-list-group-item 
               @click="view = 'sales-stats', updateHistory(view)"
               class="nav-tab d-flex"
               :class="{'active' : view == 'sales-stats'}">
               <i :class="{'text-primary' : view !== 'sales-stats', 'text-white' : view == 'sales-stats'}" class="my-auto font-size-16 mr-1 bx bx-wrench"></i> Sales Stats
              </b-list-group-item>
              
              <b-list-group-item>
                Appraise
              </b-list-group-item>-->
            </b-list-group>
           
          </b-card>

         <!-- <b-card class="border border-radius-1em shadow-none">
          	<h5>Active On</h5>
                <div
                        class="form-check form-switch form-switch-sm mb-0 mt-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          id="myWebsiteSwitch"
                          v-model="newservice.active"
                          value="1"
                          unchecked-value="0"
                          name="active"
                        />
                        <label class="form-check-label" for="myWebsiteSwitch" 
                          >My Website</label
                        >
                    </div>
                    <div
                        class="form-check form-switch form-switch-sm mb-0 mt-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          id="publicActiveSwitch"
                          v-model="newservice.public_active"
                          value="1"
                          unchecked-value="0"
                          name="public_active"
                        />
                        <label class="form-check-label" for="publicActiveSwitch" 
                          >Public Market</label
                        >
                    </div>
                    <div
                        class="form-check form-switch form-switch-sm mb-0 mt-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          id="tradeActiveSwitch"
                          v-model="newservice.trade_active"
                          value="1"
                          unchecked-value="0"
                          name="trade_active"
                        />
                        <label class="form-check-label" for="tradeActiveSwitch" 
                          >Trade Market</label
                        >
                    </div>
                  </b-card>-->

          	
            
            <b-button variant="success"  @click="addService(false)" class="btn btn-block btn-rounded btn-success mt-2" :disabled="(!$attrs.user.companies[0].userrole.name == 'Owner') && ((!$attrs.user.companies[0].permissions.add.includes($attrs.active_menu.id) && (id == 'add')) || (!$attrs.user.companies[0].permissions.edit.includes($attrs.active_menu.id) && (id !== 'add')))">
              <i class="bx bx-save"></i> Save

            </b-button>
            <b-button variant="success"  @click="addService(true)" class="btn btn-block btn-rounded btn-success mt-2" :disabled="(!$attrs.user.companies[0].userrole.name == 'Owner') && ((!$attrs.user.companies[0].permissions.add.includes($attrs.active_menu.id) && (id == 'add')) || (!$attrs.user.companies[0].permissions.edit.includes($attrs.active_menu.id) && (id !== 'add')))">
              <i class="bx bx-save"></i> Save and Add New

            </b-button>
             <b-button v-if="id !== 'add'" variant="danger"  @click="$emit('close-editor')" class="btn btn-block btn-rounded btn-danger mt-2">
              <i class="bx bx-x-circle"></i> Cancel

            </b-button>
             <b-button v-else variant="danger"  @click="redirect('/service/list')" class="btn btn-block btn-rounded btn-danger mt-2">
              <i class="bx bx-x-circle"></i> Cancel

            </b-button>
           
          </div>
		</b-col>
		<b-col md="10">
			<b-row v-if="((!$attrs.user.companies[0].userrole.name == 'Owner') && (!$attrs.user.companies[0].permissions.add.includes($attrs.active_menu.id))) && (id !== 'add')">
				<AccessRestricted></AccessRestricted>
			</b-row>
			<b-row v-else-if="((!$attrs.user.companies[0].userrole.name == 'Owner') && (!$attrs.user.companies[0].permissions.edit.includes($attrs.active_menu.id))) && (id == 'add')">
				<AccessRestricted></AccessRestricted>
			</b-row>
			 <div class="row" v-else>
        
        <div class="col-md-12">
        <b-card class="shadow-none">
          <h4 class="my-3"><span v-if="id == 'add'">Add New</span><span v-else>Edit</span> Service</h4>
          <hr>
          <b-overlay
                        id="overlay-background"
                        :show="loadingForm"
                        variant="white"
                        opacity="0.85"
                        blur="1px"
                        rounded="md"
                      >
         <b-form id="addserviceform" method="POST" enctype="multipart/form-data">
                   <input type="hidden" name="_token" :value="csrf">
           <b-row>
                     <b-col md="12">
                    <transition name="slide-error" mode="out-in">
                    <b-alert id="errors" variant="danger" :show="error_message"  dismissible @dismissed="error_message = !error_message">
                      <p class="font-size-14">Oops! Please correct the following errors in your submission</p>
                      <ul>
                        <li v-for="(error, key) in error_reponse">
                          {{error[0]}}
                        </li>
                      </ul>
                    </b-alert>
                    
                  </transition>
                  </b-col>
          </b-row>
           <b-row class="mt-2" v-show="view == 'basic-info'">
            <b-col md="3">
              <h5><i class="font-size-16 text-primary bx bx-info-circle"></i> Basic Info</h5>
            </b-col>
            <b-col md="9">
              <b-card class="border border-radius-1em shadow-none">

              	<!--<b-row>
              		<b-col md="3">
              			<a  @click="newservice.type = 0">
              			<b-card class="border border-radius-1em shadow-sm" @click="newservice.type = 0">
              				General
              			</b-card>
              		</a>
              		</b-col>
              		<b-col md="3">
              			<a  @click="newservice.type = 1">
              			<b-card class="border border-radius-1em shadow-sm">
              				Repair
              			</b-card>
              			</a>
              		</b-col>

              		<b-col md="3">
              			<a  @click="newservice.type = 2">
              			<b-card class="border border-radius-1em shadow-sm" @click="newservice.type = 0">
              				Valuation
              			</b-card>
              		</a>
              		</b-col>
              	</b-row>-->
               <b-form-group label="Reference">
            <b-form-input type="text" name="reference" v-model="newservice.reference"></b-form-input>
          </b-form-group>
          <b-form-group label="Name">
            <b-form-input type="text" name="name" v-model="newservice.name" @input="newservice.slug = sanitizeTitle(newservice.name)"></b-form-input>
          </b-form-group>
           <b-form-group class="mt-2" label="Description">
            <b-form-textarea
              placeholder="Enter description"
                rows="3"
                max-rows="6"
                v-model="newservice.short_description"
                name="short_description"
            ></b-form-textarea>
          </b-form-group>
           <b-form-group class="mt-2" label="Long Description" name="long_description">
            <input type="hidden" name="long_description" v-model="newservice.long_description">
            <ckeditor v-model="newservice.long_description" :editor="editor"></ckeditor>
          </b-form-group>
        </b-card>
        </b-col>
      </b-row>
        
      <b-row class="mt-1" v-show="view == 'basic-info'">       
        <b-col md="3">
              <h5><i class="font-size-16 text-primary bx bx-info-circle"></i> Category</h5>

            </b-col>
        <b-col md="9">
         
          <input type="hidden" name="company_service_category_id" :value="newservice.company_service_category_id">
          <div class="border border-radius-1em">
          	<b-overlay :show="loadAllCategories">
            <SelectCompanyCategoryTree v-if="'data' in service_categories" :selectedid="newservice.company_service_category_id" :category_data="service_categories.data" @category-select="addCategory"></SelectCompanyCategoryTree>
          	</b-overlay>
          </div>
        </b-col>
      </b-row>
      <b-row class="mt-4" v-show="view == 'basic-info'">
          <b-col md="3">
              <h5><i class="text-primary bx bx-image-add"></i> Images</h5>
            </b-col>
          <b-col md="9">
            <b-card class="border border-radius-1em shadow-none">
              <b-row>
                <b-col md="6">
              <b-form-group class="mt-2" label="Image">
               <vue-dropzone v-if="(newservice.image == '') || (newservice.image == null)"  id="dropzone" ref="myVueDropzone" :use-custom-slot="true" :options="dropzoneOptions" @vdropzone-file-added="imageAdded"  @vdropzone-drop="imageAdded">
                        <div class="dropzone-custom-content">
                            <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                            <h4>Drop files here or click to upload.</h4>
                        </div>
                        </vue-dropzone>
                <div v-else class="position-relative" @mouseenter="floatImage = 'image'" @mouseleave="floatImage = ''">
                  <img class="img-fluid" :src="newservice.image">
                  <div v-if="floatImage == 'image'" class="h-100 d-flex justify-content-center align-items-center position-absolute top-0 bottom-0 left-0 right-0 bg-white border border-radius-1em">
                   <a class="btn btn-primary btn-rounded mx-auto"  @click="newservice.image = ''">
                    Remove / Upload New Image
                    </a>
                  </div>
                </div>
                </b-form-group>
              </b-col>
               <b-col md="6">
              <b-form-group class="mt-2" label="Banner Image">

             <vue-dropzone v-if="(newservice.banner_image == '') || (newservice.banner_image == null)" id="dropzone" ref="myVueDropzone" :use-custom-slot="true" :options="dropzoneOptions" @vdropzone-file-added="coverImageAdded"  @vdropzone-drop="coverImageAdded">
                        <div class="dropzone-custom-content">
                            <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                            <h4>Drop files here or click to upload.</h4>
                        </div>
                        </vue-dropzone>

                <div v-else class="position-relative" @mouseenter="floatImage = 'cover'" @mouseleave="floatImage = ''">
                  <img class="img-fluid" :src="newservice.banner_image">
                  <div v-if="floatImage == 'cover'" class="h-100 d-flex justify-content-center align-items-center position-absolute top-0 bottom-0 left-0 right-0 bg-white border border-radius-1em">
                   <a class="btn btn-primary btn-rounded mx-auto"  @click="newservice.image = ''">
                    Remove / Upload New Image
                    </a>
                  </div>
                </div>
                </b-form-group>
              </b-col>
            </b-row> 
        </b-card>
        </b-col>
      </b-row>
      <b-row class="mt-1" v-show="view == 'bookings'">
        <b-col md="3">
        <h5><i class="text-primary bx bx-receipt"></i> Service Type & Frequency</h5>
        </b-col>
        <b-col md="9">
          <b-card class="border border-radius-1em shadow-none">
          	    <input type="hidden" name="company_service_type_id" :value="newservice.company_service_type_id">
          <b-form-group label="Type" v-slot="{ ariaDescribedby }" inline class="plain-radio-label">
            <b-form-radio v-for="type in service_types" v-model="newservice.company_service_type_id" :aria-describedby="ariaDescribedby" name="company_service_type_id" :value="type.id" :disabled="type.name == 'Subscription'">{{type.name}} <span v-if="type.name == 'Subscription'">(Coming Soon)</span></b-form-radio>
          </b-form-group>

          <b-form-group label="Frequency"  class="plain-radio-label" v-if="newservice.company_service_type_id > 1" v-slot="{ ariaDescribedby }" inline>
            <b-form-radio v-for="freq in service_frequencies" v-model="newservice.company_service_frequency_id" :aria-describedby="ariaDescribedby" name="company_service_frequency_id" :value="freq.id">{{freq.name}}</b-form-radio>
          </b-form-group>
        </b-card>
        </b-col>
      </b-row>
      <b-row class="mt-1"  v-show="view == 'bookings'">
        <b-col md="3">
        <h5><i class="text-primary bx bx-receipt"></i> Bookings</h5>
        </b-col>
        <b-col md="9">
          <b-card class="border border-radius-1em shadow-none">
          <b-form-group label="Allow Calendar Bookings" v-slot="{ ariaDescribedby }" inline class="plain-radio-label">
            <b-form-radio v-model="newservice.allow_bookings"  name="allow_bookings" :value="1">Yes</b-form-radio>
            <b-form-radio v-model="newservice.allow_bookings"  name="allow_bookings" :value="0">No</b-form-radio>
          </b-form-group>
          <b-card class="border border-radius-1em shadow-none" v-if="newservice.allow_bookings == (true || 1)">
          	<b-form-group v-for="day, index in available_days">
          		<b-row>
          			<b-col md="2" class="d-flex align-items-center">
          				<h6 class="mb-0">{{day}}</h6> <i class="ml-auto bx bx-right-arrow-circle text-primary font-size-20"></i>
          			</b-col>
          			<b-col md="4">
          				<b-form-group
		                    class="mb-3"
		                    id="example-time"
		                    label-cols-sm="2"
		                    label-cols-lg="2"
		                    label="Start"
		                    label-for="start"
		                    
		                  >
		                    <b-form-input
		                      id="time"
		                      value="09:00:00"
		                      type="time"
		                      v-if="newservice.booking_days.includes(day)"
		                      v-model="newservice.booking_times[newservice.booking_days.findIndex(item => item == day)][0]"
		                    ></b-form-input>
		                     <b-form-input
		                      id="time"
		                      value="09:00:00"
		                      type="time"
		                      v-else
		                      disabled
		                    ></b-form-input>
		                  </b-form-group>
          			</b-col>
          			<b-col md="4">
          				<b-form-group
		                    class="mb-3"
		                    id="example-time"
		                    label-cols-sm="2"
		                    label-cols-lg="2"
		                    label="End"
		                    label-for="start"
		                    
		                  >
		                    <b-form-input
		                      id="time"
		                      value="17:00:00"
		                      type="time"
		                      v-if="newservice.booking_days.includes(day)"
		                      v-model="newservice.booking_times[newservice.booking_days.findIndex(item => item == day)][1]"
		                    ></b-form-input>
		                     <b-form-input
		                      id="time"
		                      value="09:00:00"
		                      type="time"
		                      v-else
		                      disabled
		                    ></b-form-input>
		                  </b-form-group>
          			</b-col>
          			<b-col md="2">
          				
                      <a class="btn btn-light btn-rounded" 
                        
                          v-if="!newservice.booking_days.includes(day)"
                          @click="setDayActive(day, 1, index)"
                   			
                        >
                         Make Active
                   		</a>
                   		<a class="btn btn-light btn-rounded" 
                        
                          v-else
                          @click="setDayActive(day, 0, index)"
                   		v-b-tooltip.left
                   		title="Click to Deactivate"	
                        >
                         Active <i class="bx bx-check-circle text-success"></i>
                   		</a>	
          			</b-col>
          		</b-row>
          	</b-form-group>
      	  </b-card>
          <b-form-group v-if="newservice.allow_bookings == true" label="Service Has Set Duration" v-slot="{ ariaDescribedby }" inline class="plain-radio-label">
            <b-form-radio v-model="newservice.set_duration"  name="set_duration" :value="1">Yes</b-form-radio>
            <b-form-radio v-model="newservice.set_duration"  name="set_duration" :value="0">No</b-form-radio>
          </b-form-group>


          <b-form-group v-if="(newservice.allow_bookings == (true || 1 )) && (newservice.set_duration == (true || 1 ))" label="Duration" inline class="w-50 float-left mr-3">
            <!--<b-form-select class="form-control w-50 float-left">
              <b-form-select-option v-for="number in numbers">
                {{number}}
              </b-form-select-option>
            </b-form-select>-->
            <div class="d-flex">
            <input type="hidden" name="duration" :value="newservice.duration">
            <b-form-input type="number" v-model="newservice.duration" class="mr-3" >
            </b-form-input>
             <b-form-select class="form-control" v-model="newservice.duration_units">
              <b-form-select-option v-for="period in periods" :value="period">
                {{period}}
              </b-form-select-option>
            </b-form-select>
            <input type="hidden" name="duration_units" :value="newservice.duration_units">
            </div>
          </b-form-group>
          <!--<b-form-group v-if="(newservice.allow_bookings == (true || 1 )) && (newservice.set_duration == (true || 1 ))" label="Maximum Units Per Booking" class="w-25 float-left">
            <b-form-input v-model="newservice.max_units" type="number"></b-form-input>
          </b-form-group>-->
        </b-card>
        </b-col>
      </b-row>
       <b-row class="mt-1"  v-show="view == 'pricing'">
        <b-col md="3">
        <h5><i class="font-size-16 text-primary bx bx-receipt"></i> Price</h5>
              </b-col>
          <b-col md="9">
            <b-card class="border border-radius-1em shadow-none">
            <b-row>
                     <b-col md="3">
          <b-form-group label="Price (exc. VAT)">
            <b-form-input v-model="newservice.price_exc" type="number" step="0.01" name="price_exc" @input="newservice.price_inc = addTax(newservice.price_exc, newservice.tax_rule)"></b-form-input>
          </b-form-group>
        </b-col>
                     <b-col md="3">
          <label for="floatingSelect">Tax Rate</label>
          <b-form-select class="form-select" label="Tax Rate"  v-model="newservice.tax_rule" @change="newservice.tax_rule_id = newservice['tax_rule']['id']" >
            <b-form-select-option v-for="rule in tax_rules" :value="rule" >{{rule.name}}</b-form-select-option>
          </b-form-select>
          <input v-if="(newservice.tax_rule !== null) && (newservice.tax_rule !== '')" type="hidden" name="tax_rule_id" :value="newservice['tax_rule']['id']">
        </b-col>
        <b-col md="3">
          <b-form-group label="Price (inc. VAT)">
            <b-form-input v-model="newservice.price_inc" type="number" name="price_inc"  @input="newservice.price_exc = removeTax(newservice.price_inc, newservice.tax_rule)"></b-form-input>
          </b-form-group>
          </b-col>
          <b-col md="3">
          <b-form-group label="Deposit">
            <b-form-input v-model="newservice.service_deposit" type="number" step="0.01" name="service_deposit" ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12" class="mt-2">
         <b-form-group label="Trade Price (exc. VAT)">
          <b-form-input v-model="newservice.trade_price" type="number" step="0.01" name="trade_price"></b-form-input>
          </b-form-group>
     </b-col>
      </b-row>
    </b-card>
    </b-col>
  </b-row>
  <b-row v-if="view == 'listings'">
					<b-col md="12">
					<b-card class="border-radius-1em shadow-sm">
						<b-row>
              <b-col md="2">
                <h5 class="d-flex">Shop Services <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
                <h3 class="text-primary mt-4">{{shopsData.data.length}}</h3>
                <small>Total Listings</small>
                <hr>
                <h3 class="text-primary mt-4">
                	<span v-if="newservice.listings.length > 0">{{newservice.listings.length}}</span>
                	<span v-else>
                		0
                	</span>
            	</h3>
                <small>Shop(s) Offer this Service</small>
                <hr>

                
              </b-col>
              <b-col md="10">
                <b-card class="border shadow-none border-radius-1em">
                  <div class="mt-sm-0 form-inline w-100" >
                  <div class="search-box mr-2 ml-auto">
                    <label>Search Shops</label>
                    <div class="position-relative d-flex">
                      <input
                        type="text"
                        class="form-control w-75 border-0 bg-light"
                        placeholder="Search name, location or description..."
                        v-model="shopsearch" @keydown.enter="getShops('shopsearch', shopsearch)" 
                      />
                      <i class="bx bx-search-alt search-icon"></i>
                      
                      <b-form-select class="form-control border btn-rounded w-unset ml-2 bg-light px-3 text-uppercase" v-model="shop_type_id" @input="getShops('shop_type_id', shop_type_id)">
                        <b-form-select-option value="" >-- Listing Type --</b-form-select-option>
                        <b-form-select-option v-for="(listing_type, key) in listing_types" :value="listing_type.id" class="text-capitalize">{{listing_type.name}}</b-form-select-option>
                      </b-form-select>

                      <b-form-select class="form-control border btn-rounded w-unset ml-2 bg-light px-3 text-uppercase" v-model="shopsortby" @input="getShops()">
                        <b-form-select-option value="id" >-- Sort By --</b-form-select-option>
                        <b-form-select-option v-if="shopsData.data.length > 0" v-for="(listing, key) in shopsData.data[0]" :value="key" class="text-uppercase">{{(key).replace('_', ' ')}}</b-form-select-option>
                      </b-form-select>
                      
                      <b-form-select class="form-control border btn-rounded w-unset ml-2 bg-light px-3" v-model="shopslimit" :options="limits" @input="getShops()"></b-form-select>
                    </div>
                  </div>
                </div>
                </b-card>
                <div v-dragscroll class="widget-scrollbox" 
                            style="height: auto; max-height: 800px;"
                            v-if="'listings' in newservice"
                        >
                            <li class="list-group-item" v-for="(listing, index) in shopsData.data">
                                <div class="media">
                                	<div v-if="quantityInListing(listing.id)">
                                	<div class="avatar-sm me-3">
                                        <span class="avatar-title bg-success rounded-circle" ><i class="text-white bx bx-check font-size-20"></i></span>
                                      </div>
                                	</div>
                                    <div class="avatar-sm me-3" v-else-if="listing.logo">
                                        <span
                                            class="avatar-title rounded-circle bg-light"
                                        >
                                            <!--<img
                                                
                                                :src="listing.logo"
                                                alt=""
                                                height="18"
                                            />-->
                                            
                                        </span>
                                      </div>
                                       <div class="avatar-sm me-3" v-else>
                                        <span class="avatar-title rounded-circle" >{{(listing.name).slice(0,1)}}</span>
                                      </div>
                                   
                                    <div class="media-body my-auto">
                                        <a :href="'/company/shop/'+listing.uid" target="_blank"><h5 class="font-size-14 mb-0 mt-auto">
                                            {{listing.name}}
                                        </h5></a>
                                        <p class="text-muted mb-auto mt-0">
                                            <span v-if="listing.address_1">{{listing.address_1}},</span>
                    <span v-if="listing.address_2">{{listing.address_2}},</span>
                    <span v-if="listing.address_3">{{listing.address_3}},</span>
                    <span v-if="listing.county">{{listing.county.name}},</span>
                    <span v-if="listing.country">{{listing.country.name}},</span>
                    <span v-if="listing.postcode">{{listing.postcode}}</span>
                                        </p>

                                        
                                    </div>
                                    <div class="my-auto text-center">
                                            <p class="text-muted my-2" v-if="listing.listing_type">
                                                <i 
                                                    class="bx bx-buildings me-1"
                                                ></i>
                                                {{listing.listing_type.name}}
                                            </p>
                                        </div>
                                        <div class="my-auto px-4 w-25">
                                          <a class="btn btn-light btn-rounded btn-block" v-if="!quantityInListing(listing.id)" @click="newservice.listings.push({listing_id: listing.id, quantity: 1, variant_id: null})" >
                                            Add To This Shop
                                          </a>
                                          <div label="Quantity" v-if="quantityInListing(listing.id)" class="d-flex">
                                             <a class="btn btn-light btn-rounded" @click="removeFromListing(listing.id)">
                                             	Remove Service
                                            	<i class="bx bx-x-circle text-danger"></i>
                                            </a>
                                          </div>
                                        </div>
                                </div>
                            </li>
                        </div>
                        <b-pagination
                          class="justify-content-center"
                          pills
                          v-model="currentPage"
                          :total-rows="shopsData.total"
                          :per-page="shopsData.per_page"
                          aria-controls="my-table"
                          @input="getShops()"
                        >

                        </b-pagination>
              </b-col>
            </b-row>
					</b-card>
				</b-col>
					</b-row>
  <b-row class="mt-1"  v-show="view == 'meta'">
        <b-col md="3">
              <h5><i class="font-size-16 text-primary bx bx-info-circle"></i> Meta Info</h5>
            </b-col>

        <b-col md="9">
          <b-card class="border border-radius-1em shadow-none">
          <b-form-group label="Meta Title">
            <b-form-input type="text" name="meta_title" v-model="newservice.meta_title"></b-form-input>
          </b-form-group>
          <b-form-group class="mt-2" label="Meta Description">
            <b-form-textarea
              placeholder="Enter description"
                rows="3"
                max-rows="6"
                v-model="newservice.meta_description"
                name="meta_description"
            ></b-form-textarea>
          </b-form-group>
          <b-form-group label="Marketplace URL" class="mt-2">
            <b-input-group>
            <b-input-group-prepend is-text>
              /seller/{{$attrs.company.nickname}}/service/
            </b-input-group-prepend>
              <b-form-input type="text" name="slug" v-model="newservice.slug"></b-form-input>
            </b-input-group>
          </b-form-group>
        </b-card>
        </b-col>
      </b-row>
      <b-row class="mt-1" v-if="view == 'basic-info'">
         <b-col md="3">
              <h5><i class="font-size-16 text-primary bx bx-info-circle"></i> Active On</h5>
          </b-col>
          <b-col md="9">
            <b-card class="border border-radius-1em shadow-none">
                <div
                        class="form-check form-switch form-switch-sm mb-0 mt-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          id="myWebsiteSwitch"
                          v-model="newservice.active"
                          :value="newservice.active"
                          name="active"
                        />
                        <label class="form-check-label" for="myWebsiteSwitch" 
                          >My Website</label
                        >
                    </div>
                    <div
                        class="form-check form-switch form-switch-sm mb-0 mt-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          id="publicActiveSwitch"
                          v-model="newservice.public_active"
                          :value="newservice.public_active"
                          name="public_active"
                        />
                        <label class="form-check-label" for="publicActiveSwitch" 
                          >Public Market</label
                        >
                    </div>
                    <div
                        class="form-check form-switch form-switch-sm mb-0 mt-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          id="tradeActiveSwitch"
                          v-model="newservice.trade_active"
                          :value="newservice.trade_active"
                          
                          name="trade_active"
                        />
                        <label class="form-check-label" for="tradeActiveSwitch" 
                          >Trade Market</label
                        >
                    </div>
                  </b-card>
                   <div class="d-flex flex-row mt-3">
                  <b-button variant="success" class="w-md w-100 btn-rounded btn-block" @click="addService(false)"> <i class=" bx bx-save"></i>

                  <span v-if="newservice.uid == ''"> Save</span> <span v-else>Update</span></b-button>
                  </div>
            </b-col>
        
        </b-row>
         
        </b-form>
      </b-overlay>
        </b-card>
        </div>
    </div>
		</b-col>

	</b-row>

</template>