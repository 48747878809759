<script>
import VueSlideBar from "vue-slide-bar";
import simplebar from "simplebar-vue"
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
//import FilterListTree from "../../views/stock/product/category/_partials/FilterListTree";
import FilterListTree from "../../components/widgets/FilterListTree";
import FilterProductBrands from "../../components/widgets/FilterProductBrands";
import FilterProductCollections from "../../components/widgets/FilterProductCollections";
import SideSlidePanel from "../../components/widgets/SideSlidePanel";
import doh from 'dohjs';
/**
 * Products component
 */
export default {
  components: { VueSlideBar, Layout, PageHeader, FilterListTree, FilterProductBrands, FilterProductCollections, SideSlidePanel, simplebar },
  data() {
    return {
      pagesData: '',
      title: "Domains",
      items: [
        {
          text: "Sell",
          href: "/",
        },
        {
          text: "Domains",
          active: true,
        },
      ],
      editIndex: -1,
      search: '',
      storageurl: 'https://'+this.$attrs.company.nickname+'.s3.eu-west-2.amazonaws.com',
      showFilters: false,
      sliderPrice: 800,
      priceRange: [0,999999],
      currentPage: 1,
      limit: 25,
      selectedids: [],
      orderDesc: true,
      domainVerificationLoading: false,
      order: 'desc',
      sortby: 'id',
      trade_active: '',
      public_active: '',
      brands: '',
      collections: '',
      selectedids: [],
      closeHover: false,
      addNew: false,
      listingssearch: '',
      listings: '',
      verified_success: false,
      success_message: false,
      success_messagetext: '',
      error_reponse: '',
      error_message: false,
      action: 'add',
      domains: [],
      domain: {
        id: '',
        uid: '',
        reference: '',
        name: '',
        description: '',
        domain: '',
        domain_key: '',
        domain_config: {},
        ssl_active: false,
        active: false, 
        verified: false,
      },
      isCheckAll: false,
      active: '',
      view: 'grid',
      fields: [
        { key: "id", sortable: true },
        { key: "name", sortable: true },
        ],
      filter: null,
      filterOn: [],
      limits: [
        {value: 25, text: "25"},
        {value: 50, text: "50"},
        {value: 100, text: "100"},
      ],
      discountRates: [],
    };
  },
  methods: {
    setActive(active){
            axios.post('/api/company/web/domain/set-active', {ids: this.selectedids, active: active}, {headers: {
              'Authorization' : 'Bearer '+this.$attrs.user.api_token
            }}).then(response => {
              
              this.success_messagetext = 'Congratulations, your action has been saved';
              this.success_message = true;
              if(this.selectedids.length > 1){
                return this.resourceQuery();
              }else{
                return true;
              }
                
            }).catch(error => {

            });
    },
    makeDefault(active){
            axios.post('/api/company/web/domain/make-default', {ids: this.selectedids, active: active}, {headers: {
              'Authorization' : 'Bearer '+this.$attrs.user.api_token
            }}).then(response => {
              
              this.success_messagetext = 'Congratulations, your default domain has been updated!';
              this.success_message = true;
              this.selectedids = [];
                return this.resourceQuery();
              
                
            }).catch(error => {

            });
    },
    destroyResource(product_id){
            axios.post('/api/company/web/domain/'+this.selectedids[0]+'/destroy', {}, {headers: {
              'Authorization' : 'Bearer '+this.$attrs.user.api_token
            }}).then(response => {
                this.$bvModal.hide('delete-modal');
                return this.resourceQuery();
            }).catch(error => {

            });
    },
     bulkDestroyResource(){
            axios.post('/api/company/web/domain/bulk-destroy', {ids: this.selectedids}, {headers: {
              'Authorization' : 'Bearer '+this.$attrs.user.api_token
            }}).then(response => {
                this.$bvModal.hide('delete-modal');     
                this.selectedids = [];
                return this.resourceQuery();
            }).catch(error => {

            });
     },
     bulkDuplicateResource(){
      axios.post('/api/company/web/domain/duplicate', {ids: this.selectedids}, {headers : {
        'Authorization' : 'Bearer '+this.$attrs.user.api_token
      }}).then(response => {
        console.log(response);
        this.success_messagetext = 'Congratulations, your collection(s) have been updated.';
        this.success_message = true;
        //this.resetProjectData();
        this.selectedids = [];
        let self = this;
          setTimeout(function(){
            //self.loadingForm = false;
            self.resourceQuery();
            //self.$bvModal.hide('move-modal');
          }, 1500);
      }).catch( error => {
        
        this.error_reponse = error.response.data.errors;
        this.error_message = true;
        //this.resetProjectData();
        let self = this;
          setTimeout(function(){
            self.loadingForm = false;
          }, 1500);
      });
    },
    domainLookup(domain){
      // create your stub resolver
      console.log('method fired');
        const resolver = new doh.DohResolver('https://1.1.1.1/dns-query');

        // lookup the A records for example.com and log the IP addresses to the console
        resolver.query(''+domain+'', 'CNAME')
          .then(response => {
            response.answers.forEach(ans => this.checkDNSRecords(ans));
          })
          .catch(err => console.error(err));
            },
    checkDNSRecords(answer){
        //console.log(answer);
        if(answer.data == ''+this.domain.domain_key+'.website.gemesys.co.uk'){
          this.domainVerificationLoading = true;
          this.verifyDomain(this.domain.uid);
        }else{
          console.log(answer);
        }
    },
    deleteDomain(domain){
      axios.post('/api/company/web/domain/'+domain.uid+'/delete', this.domain, {headers : { 'Authorization' : 'Bearer '+this.$attrs.user.api_token }}).then(response => {
        this.domain_response = response.data;
        let self = this;
          setTimeout(function(){
            self.success_messagetext = "Your Domain Has Been Deleted";
            self.success_message = true;
            
            self.domainVerificationLoading = true;
            self.resourceQuery();
          }, 500); 
        
      }).catch(error => {
        this.error_reponse = error.response.data.errors;
          let self = this;
          setTimeout(function(){
            self.error_message = true;
            
            self.resourceQuery();
          }, 500); 
      });
    },
    addDomain(){
      axios.post('/api/company/web/domain/create', this.domain, {headers : { 'Authorization' : 'Bearer '+this.$attrs.user.api_token }}).then(response => {
        //this.domain = response.data;
        let self = this;
          setTimeout(function(){
            self.success_messagetext = "Your Domain Has Been Added";
            self.success_message = true;
            
            self.resourceQuery();
          }, 500); 
        
      }).catch(error => {
        this.error_reponse = error.response.data.errors;
          let self = this;
          setTimeout(function(){
            self.error_message = true;
            
            self.resourceQuery();
          }, 500); 
      });
    },
    verifyDomain(uid){
      axios.post('/api/company/web/domain/'+uid+'/verify', this.domain, {headers : { 'Authorization' : 'Bearer '+this.$attrs.user.api_token }}).then(response => {
        this.domain_response = response.data;
        let self = this;
          setTimeout(function(){
            self.success_messagetext = "Your Domain Has Been Verified and Activated";
            self.success_message = true;
            self.domainVerificationLoading = true;
            self.resourceQuery();
          }, 500); 
        
      }).catch(error => {
        this.error_reponse = error.response.data.errors;
          let self = this;
          setTimeout(function(){
            self.error_message = true;
            
            self.resourceQuery();
          }, 500); 
      });
    },
    getListings(){
      axios.get('/api/company/listings?page='+this.currentPage+'&limit=0&sortby=id&order=desc&search='+this.listingssearch+'&type_id=&active=&public_active=&trade_active=', {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
                this.listings = response.data;
          }).catch(error => {
                this.load_error = response.error;
          });
    },
    valuechange(value) {
      this.clothsData = clothsData.filter(function (product) {
        return product.newprice <= value.currentValue;
      });
    },

    searchFilter(e) {
      const searchStr = e.target.value;
      this.resourceQuery();
    },

    filterBrands(value){
      this.brands = value;
      this.resourceQuery();
    },

     filterCollections(value){
      this.collections = value;
      this.resourceQuery();
    },

    discountLessFilter(e, percentage) {
      if (e === "accepted" && this.discountRates.length === 0) {
        this.clothsData = clothsData.filter((product) => {
          return product.discount < percentage;
        });
      } else {
        this.clothsData = clothsData.filter((product) => {
          return product.discount >= Math.max.apply(null, this);
        }, this.discountRates);
      }
    },

    discountMoreFilter(e, percentage) {
      if (e === "accepted") {
        this.discountRates.push(percentage);
      } else {
        this.discountRates.splice(this.discountRates.indexOf(percentage), 1);
      }
      this.clothsData = clothsData.filter((product) => {
        return product.discount >= Math.max.apply(null, this);
      }, this.discountRates);
    },
     resourceQuery(){
      if(this.pagesData != ''){
        if(this.limit > this.pagesData.total){
          this.currentPage = 1;
        }
      }
      if(this.orderDesc == true){
        this.order = 'desc';
      }else{
        this.order = 'asc';
      }
      axios.get('/api/company/web/domains?page='+this.currentPage+'&limit='+this.limit+'&sortby='+this.sortby+'&order='+this.order+'&search='+this.search+'&active='+this.active, {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
        console.log(response);
        this.pagesData = response.data;
      }).catch(error => {
        this.error_reponse = error.response.error;
        this.error_message = true;
      });
    },
     filterSelected(id){
        if(this.selectedids.length > 0){
          var exists = this.selectedids.filter(item => item == id);
          if(exists.length > 0){
            return true;
          }else{
            return false
          }
        }else{
          return false;
        }
      },
      checkAll: function(){ 
                 this.isCheckAll = !this.isCheckAll; 
                 this.selectedids = []; 
                 if(this.isCheckAll){ 
                     // Check all 
                     for (var key in this.pagesData.data) {          
                        this.selectedids.push(this.pagesData.data[key].id); 
                     } 
                 } 
              }, 
      updateCheckall: function(){ 
                 if(this.selectedids.length == this.pagesData.data.length){
                    this.isCheckAll = true; 
                 }else{ 
                    this.isCheckAll = false; 
                 } 
      },
  },
  beforeMount(){
      this.resourceQuery();
      this.domainLookup(1);
      //this.getListings();
      
  },
  computed:{
   
  }
};
</script>

<template>
  <Layout  :nickname="$attrs.nickname" :user="$attrs.user" :menuItems="$attrs.menuItems" >
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <b-col md="12">
      <transition name="slide-error" mode="out-in">
                    <b-alert id="errors" variant="danger" :show="error_message == true"  dismissible @dismissed="error_message = !error_message">
                      <p class="font-size-14">Oops! Please correct the following errors in your submission</p>
                      <ul>
                        <li v-for="(error, key) in error_reponse">
                          {{error[0]}}
                        </li>
                      </ul>
                    </b-alert>
                    
                  </transition>
                </b-col>
                 <b-col md="12">
                    <transition name="slide-success" mode="out-in">
                  <b-alert id="success-message" variant="success" :show="success_message"  dismissible @dismissed="success_message = !success_message">
                      <p class="font-size-14">{{success_messagetext}}</p>
                    </b-alert>
                  </transition>
                  </b-col>
      <div :class="{'col-lg-12': showFilters == true, 'col-lg-12': showFilters == false}">
        <div class="row mb-3">
          <div class="col-xl-1 col-sm-6">
            <div class="mt-2">
              <b-form-select class="form-control" v-model="limit" :options="limits" @input="resourceQuery()"></b-form-select>
            </div>
          </div>
         
          <div class="col-lg-2 col-sm-6 d-flex">
          <form class="my-auto mr-auto">
            <small class="mx-auto my-2 text-center d-block">Results {{pagesData.from}} - {{pagesData.to}} of {{pagesData.total}}</small>
          </form>
          <div v-if="view == 'grid'" class="text-center my-auto ml-auto"><div
                        class="form-check form-switch form-switch-sm mb-0 mr-auto p-0 mt-auto d-flex"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input mx-auto my-auto" 
                          type="checkbox"
                          id="SwitchCheckSizeCheckall"
                         
                          v-model="isCheckAll"
                          @click='checkAll()'
                          name="active"
                        />
                        <label class="d-inline-block my-auto ml-2" for="SwitchCheckSizeCheckall">Select All</label>
                    </div></div>
        </div>
        <div class="col-lg-5 col-sm-6 d-flex">
            <div class="mt-sm-0 form-inline w-100" >
              <div class="search-box mr-2 ml-auto">
                <div class="position-relative">
                  <input
                    type="text"
                    class="form-control w-100 border-0"
                    placeholder="Search name, reference or description..."
                    v-model="search"
                    @keyup.enter="searchFilter($event)"
                  />
                  <i class="bx bx-search-alt search-icon"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-sm-6 d-flex">
            <div class="d-flex ml-auto">
              <ul class="nav nav-pills product-view-nav">
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': showFilters == true }" href="javascript: void(0);" @click="showFilters = !showFilters">
                    <i class="bx bx-filter-alt"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': view == 'grid' }" href="javascript: void(0);" @click="view = 'grid'">
                    <i class="bx bx-grid-alt"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': view == 'list' }" href="javascript: void(0);" @click="view = 'list'">
                    <i class="bx bx-list-ul"></i>
                  </a>
                </li>
                <li class="nav-item">
                 <b-dropdown text="Bulk Actions" variant="primary" class="rounded">
                  <b-dropdown-item href="javascript: void(0);" @click="setActive(true)"><i class="bx bx-check-circle font-size-14 text-success"></i> Set Active</b-dropdown-item>
                  <b-dropdown-item href="javascript: void(0);" @click="setActive(false)"><i class="bx bx-x-circle font-size-14 text-danger"></i> Set Inactive</b-dropdown-item>
                  <b-dropdown-item href="javascript: void(0);" @click="bulkDuplicateResource()"><i class="bx bx-copy-alt font-size-14"></i> Duplicate</b-dropdown-item>
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-item href="javascript: void(0);" v-b-modal.delete-modal @click="deleteMode = 'bulk'"><i class="bx bx-trash-alt font-size-14"></i> Delete</b-dropdown-item>
                </b-dropdown>
                </li>
                <li class="nav-item">
                 <a
                    class="btn btn-success btn-rounded mb-2 mr-2 text-white"
                    @click="addNew = !addNew"
                  >
                    <i class="mdi mdi-plus mr-1"></i> New
                  </a>
                </li>
              </ul>
              <div class="text-sm-right">
                  
                </div>
          </div>
        </div>
      </div>
        <!--<div class="row">
          <div class="col-lg-12">
            <b-pagination
              v-if="pagesData.data.length > 0"
              class="justify-content-center mb-0"
              pills
              v-model="currentPage"
              :total-rows="pagesData.total"
              :per-page="pagesData.per_page"
              aria-controls="my-table"
              @input="resourceQuery"
            ></b-pagination>
             <small class="mx-auto my-2 text-center d-block">Records {{pagesData.from}} - {{pagesData.to}} of {{pagesData.total}} // Page {{pagesData.current_page}} of {{pagesData.last_page}}</small>
          </div>
        </div>-->
      <b-row>
      <div id="my-table" v-if="view == 'grid'" v-for="(list, index) in pagesData.data" :key="list.id" class="col-xl-4 col-sm-6">
        <div class="card overflow-hidden border-radius-1em" @mouseenter="editIndex = index" @mouseleave="editIndex = -1">
          <div class="bg-soft-primary bg-light border-radius-top-right-1em border-radius-top-left-1em" style="min-height: 150px;">
            <div class="row">
              
              <div class="col-12 align-self-end">
                <div class="row">
                 <div class="col-md-12 position-absolute z-index-5">
                <transition name="fade-top">
                  <div v-if="editIndex == index">
                    <a  :href="'https://'+list.domain ? 'http://'+list.domain : list.ssl" target="_blank" class="mt-3 ml-3 btn btn-primary font-size-16 btn-rounded ml-auto float-left"><i class="mdi mdi-eye"></i></a>
                  </div>
              </transition>   
                <transition name="fade-top">
                  <div v-if="editIndex == index">
                    <a @click="domain = list, action = 'edit', addNew = !addNew" class="mt-3 mr-3 btn btn-primary font-size-16 btn-rounded ml-auto float-right"><i class="bx bx-pencil"></i></a>
                  </div>
              </transition>
              </div>
              </div>
              <!--  <img v-if="list.thumbnail" :src="`${list.thumbnail}`" alt class="img-fluid" />
                <img v-else src="/images/banner-placeholder.png" alt class="img-fluid" />-->
              </div>
            </div>
          </div>

          <div class="card-body pt-0">
            <div class="row">
              <div class="col-sm-12 text-center pt-3 px-4 mb-2">
               <!-- <div class="avatar-xl profile-user-wid mb-4">
                  <img class="img-thumbnail rounded-circle h-100" :src="`/public/storage/${list.image}`" alt />
                </div>-->
                <div class="avatar-sm profile-user-wid mx-auto">
                   <div class="text-center" v-if="list.image">
                      <b-form-checkbox class="select-row-btn" v-model="selectedids" :value="list.id" button-variant="primary" @input="updateSelected" button>
                        <i v-if="filterSelected(list.id)" class="bx bx-check"></i>
                        <span class="avatar-title rounded-circle bg-light" v-b-tooltip.hover title="Default" v-else-if="list.default == 1"><i class="bx bx-star text-warning font-size-18 my-auto mx-auto"></i></span>
                        <img v-else :src="`${list.image}`" alt class="avatar-sm img-thumbnail rounded-circle h-100" />
                      </b-form-checkbox>
                    </div>
                    <div class="text-center" v-else >
                        <b-form-checkbox class="select-row-btn" v-model="selectedids" :value="list.id" button-variant="primary" @input="updateSelected" button>

                          <i v-if="filterSelected(list.id)" class="bx bx-check"></i><span class="avatar-title rounded-circle bg-light" v-b-tooltip.hover title="Default" v-else-if="list.default == 1"><i class="bx bx-star text-warning font-size-18 my-auto mx-auto"></i></span><span class="avatar-title rounded-circle" v-else>{{(list.name).slice(0,1)}}</span></b-form-checkbox>
                          
                    </div> 
                </div>
                <h4 class="font-size-18 text-truncate mt-3">{{list.name}}</h4>
                <p class="text-muted mb-0 text-truncate" v-if="list.description !== null" v-html="list.description"></p>
                <p v-else class="text-muted mb-0 text-truncate font-italic">- No Description Added -</p>
              </div>
              <div class="col-md-12 mb-3 text-center">

               <a v-if="list.ssl_active == 1" :href="'https://'+list.domain_key+'.website.gemesys.co.uk'" target="_blank">
                <i class="bx bx-globe"></i>
               https://{{list.domain_key}}.website.gemesys.co.uk
               </a>
               <a v-else :href="'http://'+list.domain_key+'.website.gemesys.co.uk'" target="_blank">
                <i class="bx bx-globe"></i>
               http://{{list.domain_key}}.website.gemesys.co.uk
               </a>
              </div>
              <div class="col-sm-3">
                <span class="font-size-11 badge badge-warning bg-warning" v-if="list.verified == 0">
                  <i class="bx bx-x"></i> Not Yet Verified
                </span>
                <span class="font-size-11 badge badge-soft-success" v-if="list.verified == 1">
                 <i class="bx bx-check-circle text-success"></i> Verified
                </span>
              </div>
              <div class="col-sm-3">
                <span class="font-size-11 badge badge-warning bg-warning" v-if="list.default == 0">
                  <i class="bx bx-x"></i> Not Default
                </span>
                <span class="font-size-11 badge badge-soft-success" v-if="list.default == 1">
                 <i class="bx bx-check-circle text-success"></i> Default
                </span>
              </div>
              <div class="col-sm-3 d-flex">
                <span class="mx-auto font-size-11 badge badge-danger bg-danger" v-if="list.ssl_active == 0">
                  <i class="bx bx-lock-open-alt"></i> SSL OFF
                </span>
                <span class="mx-auto font-size-11 badge badge-soft-success" v-if="list.ssl_active == 1">
                 <i class="bx bx-lock-alt"></i> SSL Active
                </span>
              </div>
              <div class="col-sm-3 d-flex">
              <span class="ml-auto font-size-11 badge badge-danger bg-danger " v-if="list.active == 0">
                 Inactive <i class="bx bx-x"></i>
                </span>
                <span class="ml-auto font-size-11 badge badge-soft-success" v-if="list.active == 1">
                 <i class="bx bx-check-circle text-success"></i> Active
                </span>
              </div>
            </div>
          </div>
            <div class="card-footer  bg-transparent border-top">
                  <div class="d-flex">
                  
                    <a class="btn btn-outline-light font-size-16 btn-rounded ml-2 my-auto" v-b-tooltip.hover title="Delete" placement="left"  @click="selectedids = [], selectedids.push(list.id), deleteMode = 'single'" v-b-modal.delete-modal><i class="bx bx-trash-alt"></i>
                          </a>
                    <b-button variant="outline-light" :disabled="list.default == 1" href="javascript:;" @click="selectedids = [], selectedids.push(list.id), makeDefault(list.active)" class="btn btn-outline-light btn-rounded ml-2"><i class="bx bx-star my-1" :class="{'text-warning' : list.default == 1}"></i></b-button>
                    <b-dropdown  placement="left" variant="outline-light" dropup class="more-options-dropdown dropup btn-rounded ml-2 font-size-16" menu-class="dropdown-menu-left ml-2">
                        <template v-slot:button-content>
                           <i class="bx bx-dots-horizontal-rounded"></i>
                        </template>
                          <b-dropdown-item href="javascript: void(0);"  @click="selectedids = [], selectedids.push(list.id), bulkDuplicateResource()">Duplicate</b-dropdown-item>
                      </b-dropdown>
                      <div
                        class="form-check form-switch form-switch-sm my-auto ml-auto d-flex mr-3"
                        dir="ltr"
                        v-if="list.default == 1"
                      >
                      <i class="bx bx-star font-size-18 text-warning"></i> <label class="form-check-label">Default</label>
                    </div>
                      <div :class="{'ml-auto': list.default == 0}"
                        class="form-check form-switch form-switch-sm my-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          :id="`SwitchCheckSize-${list.id}`"
                          v-model="list.active"
                          :value="list.active"
                          name="active"
                         
                          @change="selectedids = [], selectedids.push(list.id), setActive(list.active)"
                        />
                        <label class="form-check-label" :for="`SwitchCheckSize-${list.id}`" 
                          >Active</label
                        >
                    </div>
                  </div>
          </div>
        </div>
    </div>
  </b-row>
        
        <!-- end row -->

        
        <div class="card" v-if="view == 'list'" >
          <div class="card-body p-0">
            <div class="table-responsive">
              <table class="table table-centered table-hover">
                <thead class="thead-light">
                  <tr>
                   <th scope="col"  style="width: 100px" class="text-center">
                     <div
                        class="form-check form-switch form-switch-sm mb-0 mx-auto p-0 mt-auto d-flex"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input mx-auto my-auto" 
                          type="checkbox"
                          id="SwitchCheckSizesm"
                         
                          v-model="isCheckAll"
                          @click='checkAll()'
                          name="active"
                        />
                    </div>
                  </th>
                    <th scope="col" style="max-width: 300px"><a :class="{'text-dark': sortby != 'name'}" href="javascript: void(0);" @click="orderDesc = !orderDesc,  sortby = 'name', resourceQuery()">Name & Description <i v-if="orderDesc == true" class="bx bx-caret-up"></i><i v-if="orderDesc == false" class="bx bx-caret-down"></i></a></th>
                    <th scope="col">Domain</th>
                    <th scope="col">Permalink</th>
                    
                    <th scope="col" style="width: 100px">Status</th>
                    <th scope="col">Active</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="list in pagesData.data" :key="list.id" valign="middle">
                    <td>
                      <div class="text-center" v-if="list.cover_image">
                      <b-form-checkbox class="select-row-btn " v-model="selectedids" :value="list.id" button-variant="primary" @input="updateSelected" button>
                        <i v-if="filterSelected(list.id)" class="bx bx-check"></i>
                        <span class="avatar-title rounded-circle bg-light" v-b-tooltip.hover title="Default" v-else-if="list.default == 1"><i class="bx bx-star text-warning font-size-18 my-auto mx-auto"></i></span>
                        <img v-else :src="`${storageurl+list.cover_image.src.replace('//', '/')}`" alt class="avatar-sm rounded-circle" />
                      </b-form-checkbox>
                    </div>
                    <div class="text-center" v-else >
                        <b-form-checkbox class="select-row-btn" v-model="selectedids" :value="list.id" button-variant="light" @input="updateSelected" button>

                          <i v-if="filterSelected(list.id)" class="bx bx-check"></i>
                          <span class="avatar-title rounded-circle bg-light" v-b-tooltip.hover title="Default" v-else-if="list.default == 1"><i class="bx bx-star text-warning font-size-18 my-auto mx-auto"></i></span>
                          <span class="avatar-title rounded-circle" v-else>{{(list.name).slice(0,1)}}</span></b-form-checkbox>
                    </div> 
                    </td>
                    <td style="max-width: 550px">
                        <a href="javascript:;" class="mb-0"><h6 class="mb-0">{{list.name}}</h6></a>
                        <p class="d-md-none d-block" v-html="list.description"></p>
                    </td>
                    <td><a v-if="list.ssl_active == 1" :href="'https://'+list.domain+''" target="_blank">
                <i class="bx bx-globe"></i>
               https://{{list.domain}}
               </a>
               <a v-else :href="'http://'+list.domain+''" target="_blank">
                <i class="bx bx-globe"></i>
               http://{{list.domain}}
               </a></td>
                    <td><a v-if="list.ssl_active == 1" :href="'https://'+list.domain_key+'.website.gemesys.co.uk'" target="_blank">
                <i class="bx bx-globe"></i>
               https://{{list.domain_key}}.website.gemesys.co.uk
               </a>
               <a v-else :href="'http://'+list.domain_key+'.website.gemesys.co.uk'" target="_blank">
                <i class="bx bx-globe"></i>
               http://{{list.domain_key}}.website.gemesys.co.uk
               </a></td>
                    
                    
                    <td  style="width: 100px">
                      <span class="font-size-11 badge badge-warning bg-warning" v-if="list.verified == 0">
                        <i class="bx bx-x"></i> Not Yet Verified
                      </span>
                      <span class="font-size-11 badge badge-soft-success" v-if="list.verified == 1">
                       <i class="bx bx-check-circle text-success"></i> Verified
                      </span>
                      <span class="mx-auto font-size-11 badge badge-danger bg-danger" v-if="list.ssl_active == 0">
                        <i class="bx bx-lock-open-alt"></i> SSL OFF
                      </span>
                      <span class="mx-auto font-size-11 badge badge-soft-success" v-if="list.ssl_active == 1">
                       <i class="bx bx-lock-alt"></i> SSL Active
                      </span>
                      <span class="ml-auto font-size-11 badge badge-danger bg-danger " v-if="list.active == 0">
                       Inactive <i class="bx bx-x"></i>
                      </span>
                      <span class="ml-auto font-size-11 badge badge-soft-success" v-if="list.active == 1">
                       <i class="bx bx-check-circle text-success"></i> Active
                      </span>
                      <span class="font-size-11 badge badge-soft-success" v-if="list.default == 1">
                       <i class="bx bx-check-circle text-success"></i> Default
                      </span>
             
                    </td>
                    <td>
                       <div
                        class="form-check form-switch form-switch-sm my-auto ml-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          :id="`SwitchCheckSize-${list.id}`"
                          v-model="list.active"
                          :value="list.active"
                          name="active"
                         
                          @change="selectedids = [], selectedids.push(list.id), setActive(list.active)"
                        />
                        <label class="form-check-label" :for="`SwitchCheckSize-${list.id}`" 
                          >Active</label
                        >
                    </div>
                    </td>
                    <td>
                      <ul class="list-inline font-size-20 contact-links mb-0">
                        <li class="list-inline-item px-1">
                          <a v-b-tooltip.hover title="Edit" placement="left" @click="domain = list, action = 'edit', addNew = !addNew" >
                            <i class="bx bx-edit-alt"></i>
                          </a>
                        </li>
                        <li class="list-inline-item px-1">
                            <a v-b-tooltip.hover  title="Preview" placement="left" :href="'https://'+list.domain ? 'http://'+list.domain : list.ssl" target="_blank">
                            <i class="mdi mdi-eye-outline"></i>
                          </a>
                          </a>
                        </li>
                        <li class="list-inline-item px-1">
                          <b-button variant="white" :disabled="list.default == 1" href="javascript:;" @click="selectedids = [], selectedids.push(list.id), makeDefault(list.active)" class="p-0 font-size-20"><i class="bx bx-star my-1" :class="{'text-warning' : list.default == 1}"></i></b-button>
                        </li>
                        <li class="list-inline-item px-1">
                          <a v-b-tooltip.hover title="Delete" placement="left" @click="selectedids = [], selectedids.push(list.id), deleteMode = 'single'" v-b-modal.delete-modal>
                            <i class="bx bx-trash-alt"></i>
                          </a>
                        </li>
                        <li class="list-inline-item px-1">
                           <b-dropdown v-b-tooltip.hover title="More Options" placement="left" dropleft class="more-options-dropdown" menu-class="dropdown-menu-right">
                        <template v-slot:button-content>
                           <i class="bx bx-dots-horizontal-rounded"></i>
                        </template>
                          <b-dropdown-item href="javascript: void(0);"  @click="selectedids = [], selectedids.push(list.id), bulkDuplicateResource()"><i class="bx bx-copy-alt"></i> Duplicate</b-dropdown-item>
                      </b-dropdown>
                        </li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            
            <div class="row">
              <div class="col-lg-12" v-if="pagesData !== ''">
                <b-pagination
              class="justify-content-center"
              pills
              v-model="currentPage"
              :total-rows="pagesData.total"
              :per-page="pagesData.per_page"
              aria-controls="my-table"
              @input="resourceQuery"
            ></b-pagination>
             <small class="mx-auto my-2 text-center d-block">Records {{pagesData.from}} - {{pagesData.to}} of {{pagesData.total}} // Page {{pagesData.current_page}} of {{pagesData.last_page}}</small>
              </div>
            </div>
          </div>
        </div>
      </div>
       <b-modal id="delete-modal" size="md" hide-footer hide-header centered>
        <b-card class="border-radius-1em border shadow-none">
          <h4>Are you sure?</h4>
          <p v-if="deleteMode == 'bulk'">You are about to delete {{selectedids.length}} products. Are you sure you want to do this? This action is not reversable</p>
           <p v-if="deleteMode == 'single'">You are about to delete this category. Are you sure you want to do this? This action is not reversable</p>

           <div class="d-flex">
            <a class="btn btn-danger" v-if="deleteMode == 'bulk'" @click="bulkDestroyResource">
              Delete
            </a>
             <a class="btn btn-danger"  v-if="deleteMode == 'single'" @click="bulkDestroyResource">
              Delete
            </a>
            <a class="btn btn-success" @click="$bvModal.hide('delete-modal')">
              Cancel
            </a>
          </div>
        </b-card>
      </b-modal>
           <SideSlidePanel :editPanel="addNew" :panelClass="'edit-sidepanel'" @close-panel="addNew = !addNew">     
        <form id="add-update" method="post" enctype="mutlipart/form-data">
            <b-row>
              <b-col md="2" class="my-3">
                          <h5 class="d-flex">Configure Domain <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
                        </b-col>
              <b-col md="10" class="my-3">
              <b-card class="shadow-none border-radius-1em border">
                <b-alert variant="warning" :show="(domain.domain_key !== '') && (domain.verified == 0)">
                      Your domain has not been verified yet. 
                    </b-alert>
                <b-form-group class="mb-2" label="Reference">
                  <b-form-input type="text" name="reference" v-model="domain.reference"></b-form-input>
                </b-form-group>
                <b-form-group class="mb-2" label="Name">
                  <b-form-input type="text" name="name" v-model="domain.name"></b-form-input>
                </b-form-group>
                <b-form-group class="mb-2" label="Description">
                  <b-form-textarea :rows="5" type="text" name="description" v-model="domain.description"></b-form-textarea>
                </b-form-group>
                <b-form-group class="mb-2" label="URL/Domain Name">
                  <b-form-input type="text" name="domain" v-model="domain.domain"></b-form-input>
                </b-form-group>
                 <div
                        class="form-check form-switch form-switch-sm mb-0 mx-auto p-0 mt-auto d-flex"
                        dir="ltr"
                      >
                      Default
                      <input
                          class="form-check-input mx-auto my-auto" 
                          type="checkbox"
                          id="SwitchCheckDefault"
                          v-model="domain.default"
                      
                          name="ssl"
                        />
                    </div>
                <div
                        class="form-check form-switch form-switch-sm mb-0 mx-auto p-0 mt-auto d-flex"
                        dir="ltr"
                      >
                      Enable SSL
                      <input
                          class="form-check-input mx-auto my-auto" 
                          type="checkbox"
                          id="SwitchCheckSsl"
                          v-model="domain.ssl_active"
                      
                          name="ssl"
                        />
                    </div>
              </b-card>

                  <b-card class="shadow-none bg-warning bg-soft border-radius-1em p-3 mt-2" v-if="(domain.domain_key !== '') && (domain.verified == 0)">
                    <h5>Domain Not Verified</h5>
                    

                    To verify your domain you need to add the following as an CNAME record to your domain DNS settings: 

                    <p class="well p-2">
                    <b-row class="mt-2">
                      <b-col md=3>
                        CNAME
                      </b-col>
                      <b-col md="9">
                         {{domain.domain_key}}.website.gemesys.co.uk
                       </b-col>
                    </b-row>
                    <b-row class="pb-0 my-2">
                      <b-col>
                        <a class="btn btn-primary btn-block btn-rounded" @click="domainLookup(domain.domain)">
                            Check Domain Verification
                        </a>
                      </b-col>
                    </b-row>
                    </p>

                  </b-card>
                <a class="btn btn-success btn-rounded btn-block" @click="addDomain()">
                  Start Setup
                </a>
              </b-col>
          
               <b-col>

              </b-col>
            </b-row>
            </form>
    </SideSlidePanel>
    </div>
    <!-- end row -->
  </Layout>
</template>
