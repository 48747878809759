<script>
import VueSlideBar from "vue-slide-bar";

/**
 * Pricing component
 */
export default {
  components: { VueSlideBar },
  props: {
    payment_term: {
      type: String,
      default: () => 'monthly'
    },
    user_quantity: {
      type: Number,
      default: () => 1,
    },
    shop_quantity: {
      type: Number,
      default: () => 1,
    },
  },
  data() {
    return {
    	stripeAPIToken: 'pk_test_51I0zSvGhRp3KgkKHNozJ2bzjzNzEazSpEkolNZedmluKPikp7MQpcsEk8dxWb4FhSbSykO8A1WblCWhWHcFlchUY00csWs53a0',
        intentToken: '',
		cardholder_name: '',
		addPaymentStatus: 0,
		addPaymentStatusError: '',
		show_payment_form: false,
		selected_plan: '',
    original_user_quantity: 0,
    current_price: 0.00,
    original_price: 0.00,
    current_flat_price: 0.00,
    original_flat_price: 0.00,
		stripe: '',
		elements: '',
		plans: '',
		card: '',
      	title: 'Pricing',
      	name: 'account-subscription',
      	items: [
        {
          text: 'Utility',
          href: '/',
        },
        {
          text: 'Pricing',
          active: true,
        },
      ],
    }
  },
  computed:{
    originalSubscriptionPrice: function(){
      if(this.original_user_quantity < 6){
        this.original_price = parseFloat(8.99).toFixed(2);
        this.original_flat_price = parseFloat(50.00).toFixed(2);
        this.shop_quantity = 1;
      }else if((this.original_user_quantity > 5) && (this.original_user_quantity <= 50)){
        this.original_price = parseFloat(7.99);
        this.original_flat_price = parseFloat(100.00).toFixed(2);
        if(this.shop_quantity >= 10){
        this.shop_quantity = 2;
        }
      }else if((this.original_user_quantity > 50) && (this.original_user_quantity <= 200)){
        this.original_price = parseFloat(6.99);
        this.original_flat_price = parseFloat(200.00).toFixed(2);
        if(this.shop_quantity < 10){
        this.shop_quantity = 10;
        }
      }
      return parseFloat(parseFloat(this.original_user_quantity * this.original_price) + parseFloat(this.original_flat_price)).toFixed(2);
    },
    originalYearlySubscriptionPrice: function(){
      return parseFloat(parseFloat(this.userSubscriptionPrice) * parseFloat(12)).toFixed(2);
    },
    userSubscriptionPrice: function(){
      if(this.user_quantity < 6){
        this.current_price = parseFloat(8.99).toFixed(2);
        this.current_flat_price = parseFloat(50.00).toFixed(2);
        this.shop_quantity = 1;
      }else if((this.user_quantity > 5) && (this.user_quantity <= 50)){
        this.current_price = parseFloat(7.99);
        this.current_flat_price = parseFloat(100.00).toFixed(2);
        if(this.shop_quantity >= 10){
        this.shop_quantity = 2;
        }
      }else if((this.user_quantity > 50) && (this.user_quantity <= 200)){
        this.current_price = parseFloat(6.99);
        this.current_flat_price = parseFloat(200.00).toFixed(2);
        if(this.shop_quantity < 10){
        this.shop_quantity = 10;
        }
      }
      return parseFloat(parseFloat(this.user_quantity * this.current_price) + parseFloat(this.current_flat_price)).toFixed(2);
    },
    userYearlySubscriptionPrice: function(){
      return parseFloat(parseFloat(this.userSubscriptionPrice) * parseFloat(12)).toFixed(2);
    },
  },
  methods:{
            loadPaymentForm(plan){
                    this.selected_plan = plan;
                    this.show_payment_form = true;
                 
            },

            includeStripe( URL, callback ){
                        let documentTag = document, tag = 'script',
                            object = documentTag.createElement(tag),
                            scriptTag = documentTag.getElementsByTagName(tag)[0];
                        object.src = '//' + URL;
                        if (callback) { object.addEventListener('load', function (e) { callback(null, e); }, false); }
                        scriptTag.parentNode.insertBefore(object, scriptTag);
            },
            configureStripe(){
                this.stripe = Stripe( this.stripeAPIToken );

                this.elements = this.stripe.elements({clientSecret: this.intentToken.client_secret});
                this.card = this.elements.create('payment', {clientSecret: this.intentToken.client_secret});
                this.show_payment_form = true;
                this.card.mount('#payment-element');
            },
            updateSubscriptionQuantity(){
              axios.post('/api/company/subscription/users/update', {
                        companyuid: this.$attrs.company.uid,
                        quantity: this.user_quantity,
                        plan_name: this.selected_plan.stripe_plan,
                    }, {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
              }}).then(response => {

                      this.show_payment_form = false;

                    }).catch(error =>  {
                      

                      this.show_payment_form = true;
                    })
            },
            submitPaymentMethod(){
                    this.addPaymentStatus = 1;

                    this.stripe.confirmCardSetup(
                        this.intentToken.client_secret, {
                            payment_method: {
                                card: this.card,
                                billing_details: {
                                    name: this.cardholder_name
                                }
                            }
                        }
                    ).then(function(result) {
                        if (result.error) {
                            this.addPaymentStatus = 3;
                            this.addPaymentStatusError = result.error.message;
                        } else {
                            this.savePaymentMethod( result.setupIntent.payment_method );
                            this.addPaymentStatus = 2;
                            this.card.clear();
                            this.cardholder_name = '';
                            /*setTimeout(function(){
                            	window.location.replace('/company/');
                            	 }, 5000);*/
                        }
                    }.bind(this));
                },
            savePaymentMethod( method ){
                    axios.post('/api/company/subscription/payments', {
                        payment_method: method,
                        plan_name: this.selected_plan.stripe_plan,
                        companyuid: this.$attrs.company.uid,
                        quantity: this.user_quantity,
                    }, {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
              }}).then( function(){
                        //this.loadPaymentMethods();
                    }.bind(this));
                },

            getSavedPaymentMethods(){

            },
            getSubscriptionPlans(){
              axios.get('/api/subscription/plans?term='+this.payment_term, {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
              }}).then(response => {
                      this.plans = response.data;
                  });
            }
        },
  mounted() {
            this.includeStripe('js.stripe.com/v3/', function(){
                this.configureStripe();
            }.bind(this) );
            axios.get('/api/company/subscription/setup-intent?uid='+this.$attrs.company.uid+'', {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
              }})
                .then( function( response ){
                    this.intentToken = response.data;
                    

                }.bind(this));
            //this.loadPaymentForm();
        },
   created(){
   		this.getSubscriptionPlans();
      this.original_user_quantity = this.user_quantity;
   }
}
</script>

<template>
	<b-container>
	 <div class="row justify-content-center mt-0 pt-4">
      <div class="col-lg-12">
        <div class="mb-5">
        </div>
        <!--<div class="btn-group btn-group-example mb-3 mx-auto text-center" role="group">
                      <b-button variant="outline-primary" class="w-sm" :class="{'active' : payment_term == 'monthly'}" @click="payment_term = 'monthly', getSubscriptionPlans()">Monthly</b-button>
                      <b-button variant="outline-primary" class="w-sm" :class="{'active' : payment_term == 'yearly'}" @click="payment_term = 'yearly', getSubscriptionPlans()">Yearly</b-button>
                    </div>-->
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        
        <h5>No. of Users</h5>
        <div class="px-3 d-flex py-4">
           
            <vue-slide-bar class="w-75 mr-4 pb-4" v-model="user_quantity" :min="1"  :max="200" />

             <div class="w-25 my-auto">
            <b-form-input type="number" v-model="user_quantity" :max="200"></b-form-input>
          </div>
        </div>

        <h5>No. of Shops</h5>
        <div class="px-3 d-flex py-4">
           
            <vue-slide-bar class="w-75 mr-4 pb-4" v-model="shop_quantity" :min="1" :max="20"  />

             <div class="w-25 my-auto">
            <b-form-input type="number" v-model="shop_quantity" :max="20"></b-form-input>
          </div>
        </div>
      </div>
      <div class="col-md-3 py-4">
        <h3 class="text-center text-primary">{{user_quantity}}<small> users</small></h3>
      </div>
    <div class="col-md-1 py-4">
      <h3 class="font-size-20 text-center">X</h3>
    </div>
    <div class="col-md-3 py-4">
        <h3 class="text-center text-primary">£{{current_price}}</h3>
      </div>
       <div class="col-md-1 py-4">
      <h3 class="font-size-20 text-center">=</h3>
    </div>
      <div class="col-md-4 py-4" v-if="payment_term == 'monthly'">
        <h3 class="text-center text-primary">£{{userSubscriptionPrice}}</h3>
        <p class="text-center">per month (tax exc.)</p>
      </div>
      <div class="col-md-4 py-4" v-else-if="payment_term == 'yearly'">
        <h3 class="text-center text-primary">£{{userYearlySubscriptionPrice}}</h3>
        <p class="text-center">per year (tax exc.)</p>
      </div>
    </div>
    <div class="row justify-content-center">
      <div v-if="plans.length > 0" v-for="plan in plans" :key="plan.id" class="col-xl-3 col-md-6">
        <div class="card plan-box" :class="{'card border border-primary': plan.price_inc == current_price}">
          <div class="card-body p-4">
            <div class="media">
              <div class="media-body">
				<h5>{{plan.name}}</h5>
				<p class="text-muted">{{plan.description}}</p>
              </div>
              <div class="ml-3">
				<i :class="`bx ${plan.icon} h1 text-primary`"></i>
              </div>
            </div>
            <div class="py-4">
              <h2>
				<sup>
                  <small>£</small>
                </sup>
                {{plan.price_inc}}
                <span class="font-size-13" v-if="plan.payment_term == 'monthly'">Per user/month</span>
                <span class="font-size-13" v-else>Per user/year</span>
              </h2>
            </div>
            <div class="text-center plan-btn">
              <a v-if="plan.price_inc == current_price"
                href="javascript: void(0);"
                class="btn btn-primary btn-sm position-relative"
               @click="loadPaymentForm(plan)">Update Subscription</a>
            </div>

            <div class="plan-features mt-5">
              <p v-for="item in JSON.parse(plan.features)">
                <i class="bx bx-checkbox-square text-primary mr-2"></i> {{item.text}}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
           <transition name="fade">
            <div class="payment-modal px-3" v-show="show_card_form == true" :class="{'d-flex': show_card_form == true}">
              <div id="payment-element"></div>
            </div>
          </transition>
      <transition name="fade">
            <div class="payment-modal px-3" v-show="show_payment_form == true" :class="{'d-flex': show_payment_form == true}">
            <div class="payment-modal-inner col-md-6 mx-auto my-auto p-4 bg-white shadow">
            <div class="payment-modal-header">
            	<a href="javascript:;" @click="show_payment_form = false" class="btn btn-light btn-rounded float-right"><i class="mdi mdi-close noti-icon font-size-16"></i></a>
            </div>
             <h4 class="my-4 pb-4 card-title text-secondary">Update Subscription</h4>
            <div v-if="addPaymentStatus == 2">
            	<h4>Hurrah! Your payment has succeed</h4>
            	<p>To get started with your account, click on the button below to visit your company dashboard.</p>

            	<a class="btn btn-secondary rounded-0" :href="'https://'+$attrs.company.nickname+'/'">Set-Up-My Company</a>
            </div>
            <div v-if="addPaymentStatus == 3">
            <h4 class="my-4 pb-4 card-title text-secondary">Oops! An Error Occurred</h4>
            <b-alert variant="danger" v-if="addPaymentStatus == 3">{{addPaymentStatusError}}</b-alert>
            </div>
            <!--<h5 class="text-center">{{selected_plan.name}}</h5>-->
            <p class="text-center">You are updating your subscription <strong>from</strong></p>
            <h3 class="text-center text-primary strikethrough" v-if="payment_term == 'monthly'">{{original_user_quantity}} <small>users</small> £{{originalSubscriptionPrice}}</h3>
            <p class="text-center"><strong>To</strong></p>
            <h3 class="text-center text-primary strike-through" v-if="payment_term == 'yearly'">£{{originalYearlySubscriptionPrice}}</h3>
            <h3 class="text-center text-primary" v-if="payment_term == 'monthly'">{{user_quantity}} <small>users</small> £{{userSubscriptionPrice}}</h3>
            <h3 class="text-center text-primary" v-if="payment_term == 'yearly'">£{{userYearlySubscriptionPrice}}</h3>
            <p class="text-center" v-if="payment_term == 'monthly'">per month</p>
            <p class="text-center" v-if="payment_term == 'yearly'">per year</p>
                <div class="d-flex mt-4">
                <a class="btn btn-block btn-success mt-3 ml-auto mr-1" id="add-card-button" v-on:click="updateSubscriptionQuantity()">
                    Confirm
                </a>
                <a class="btn btn-block btn-danger close-btn mt-3 mr-auto ml-1" href="javascript:;" @click="show_payment_form = false, selected_plan = ''">Cancel</a>
                </div>
            </div>
            </div>
            </div>
        </transition>
</b-container>
</template>

<style lang="scss" module></style>