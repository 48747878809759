<script>
	import Multiselect from "vue-multiselect";
	import AddNewSupplierForm from '../../components/widgets/AddNewSupplierForm';
	import SideSlidePanel from "../../components/widgets/SideSlidePanel"
	export default{
		components: { Multiselect, AddNewSupplierForm, SideSlidePanel },
		data(){
			return{
				id: '',
				options: [
					{id: 1, name: 'text'},
					{id: 2, name: 'text2'}
				],
				search: '',
				addNewPanel: false,
				currentPage: 1,
				order: 'desc',
      			sortby: 'id',
      			limit: 0,
				limits: [
		        {value: 25, text: "25"},
		        {value: 50, text: "50"},
		        {value: 100, text: "100"},
		    	],
				response_error: []
			}
		},
		props: {
			isDisabled: {
				type: Boolean,
				default: () => false,
				}
		},
		created(){
			axios.get('/api/company/product/suppliers?limit=0&sortby='+this.sortby+'&order='+this.order+'&search='+this.search+'', {headers: {'Authorization' : 'Bearer '+this.$attrs.api_token}}).then(response => {
				this.options = response.data;
			}).catch(error => {
				this.response_error = error.data;
			});
			this.id = this.$attrs.id;
			
		},
		methods:{
			updateDepartment: function(selectedOption, id){
				this.$emit('supplier-selected', selectedOption);
			},
			customLabel ({ title, desc }) {
		      return `${title} – ${desc}`
		    },
		    supplierAdded(supplier){
		    	this.options.push(supplier);
		    }
		}
	}
</script>
<template>
	<div>
		<div class="d-flex"> 
	<multiselect v-model="id" track-by="name" label="name" :options="options" @select="updateDepartment" :option-height="104" :disabled="isDisabled" class="flex-grow-5">
                     <!--<template slot="singleLabel" slot-scope="{ option }">{{ option.name }} - {{ option.jobtitle }}</template>-->
                     <template slot="singleLabel" slot-scope="{ option }"><div class="d-flex w-100 h-100"><div class="avatar-sm mr-4 my-auto">
                        <span class="avatar-title rounded-circle">{{(option.name).slice(0,1)}}</span>
                      </div>
      <div class="option__desc d-flex flex-row w-75"><span class="option__title ml-2 my-auto w-100">{{ option.name }}<br><p class="m-0 lh-1 w-100 text-truncate"><small>{{ option.description }}...</small></p></span>
      </div>
  		</div></template>
    <template slot="option" slot-scope="{ option }">
    	<div class="d-flex"><div class="avatar-sm mr-4">
                        <span class="avatar-title rounded-circle">{{(option.name).slice(0,1)}}</span>
                      </div>
      <div class="option__desc d-flex flex-row w-50"><span class="option__title ml-2 my-auto w-100">{{ option.name }}<br><p class="m-0 lh-1 w-100 text-truncate"><small>{{ option.description }}...</small></p></span>
      </div>
  		</div>
    </template>
                  </multiselect>
                  <a v-if="supplier_id !== 0" variant="light" class="my-auto btn btn-outline-light ml-2 flex-grow-1 h-100"  @click="addNewPanel = !addNewPanel" ><i class="mdi mdi-plus"></i></a>
               </div>


                   <SideSlidePanel :editPanel="addNewPanel" :panelClass="'edit-micropanel'" @close-panel="addNewPanel = !addNewPanel">
                   	<b-row class="px-4">
                  		<b-col>
                  			<h4>Add New Supplier</h4>
                    		<AddNewSupplierForm :api_token="$attrs.api_token" @close-modal="$bvModal.hide('modal-addnewsupplier')" @supplier-added="supplierAdded" ></AddNewSupplierForm>
                    	</b-col>
                    </b-row>
                  </SideSlidePanel>
   </div>
</template>