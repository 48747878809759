<template>
	<b-row>
				     <div class="col-md-12" v-if="product.attributes.length == 0">
                    <b-card class="shadow-none text-center border mb-2 py-4">
                      <i class="bx bxs-cube font-size-24 text-muted"></i>
                      <h4>No Active Features</h4>
                  </b-card>
                  </div>
                 <div class="col-md-12 mb-2" v-if="product.attributes.length !== 0">
                <div class="row">
               <div class="col-md-12">
                <div class="form-control-group">
                <b-list-group class="list-group" flush>
                	<div v-for="attribute_group in product.grouped_attributes">
                	   <li v-for="(active_attributes, index) in attribute_group" class="list-group-item p-0 d-flex py-3">
		                <span class="my-auto pl-4 w-25">
		                  <!--<img v-if="active_attributes.attribute.image" :src="active_attributes.attribute.image" class="avatar-xs rounded-circle mr-2">
		                  <img v-else src="/images/product-placeholder.png" class="avatar-xs rounded-circle mr-2">-->
		               <div v-if="index == 0">{{attribute_group[0].attribute.name}}</div>
		              </span>
		              <span class="w-25">
		                <img v-if="active_attributes.value.image" :src="active_attributes.value.image" class="avatar-xs rounded-circle mr-3 border">
		                 {{active_attributes.value.name}}
		              </span>
		              <span class="my-auto pl-4 w-50 d-flex">
		              	<span class="ml-auto">{{active_attributes.measurement_value}}{{active_attributes.units.suffix}}
		              	</span>
		              </span>
		              
					          </li>
			        </div>
			        </b-list-group>
			      </div>
			    </div>
			  </div>
			</div> 
			</b-row>
</template>

<script type="text/javascript">
	export default {
				props: {
					product: {
						type: Object
					}
				},
			}

</script>