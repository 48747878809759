<script type="text/javascript">
  import DateTime from 'luxon/src/datetime.js';
	export default{
    components: {DateTime},
    props:{
      user_id: {
        type: Number,
        default: () => ''
      },
      assigned_type: {
        type: String,
        default: () => '',
      },
      department_id: {
        type: String,
        default: () => '',
      },
      team_id:{
        type: String,
        default: () => ''
      },
      assignedto: {
        type: String,
        default: () => '',
      },
      searchlimit: {
        type: Number,
        default: () => 25,
      },
      pageno: {
        type: Number,
        default: () => 1,
      },
      typeid: {
        type: String,
        default: () => '',
      }
    },
		data(){
			return{
				id: '',
				actions: [],
				search: '',
        isCheckAll: false,
        selectedids: [],
				project_id: '',
				currentPage: 1,
        assigned_to: '',
        assigned_by: '',
        types: [],
				order: 'asc',
      			sortby: 'due_date',
      			limit: 25,
				limits: [
		        {value: 25, text: "25"},
		        {value: 50, text: "50"},
		        {value: 100, text: "100"},
		    	],
				response_error: []
			}
		},
		methods:{
			searchResource(){
        
				axios.get('/api/company/actions?api_token='+this.$attrs.api_token+'&page='+this.currentPage+'&limit='+this.limit+'&sortby='+this.sortby+'&order='+this.order+'&search='+this.search+'&project_id='+this.project_id+'&departments='+this.department_id+'&assingedto='+this.assigned_to+'&assingedby='+this.assigned_by+'&types='+this.types+'').then(response => {
					this.actions = response.data;
          if(this.currentPage == 1){
            this.$emit('last-page', response.data.last_page);
          }
				}).catch(error => {
					this.response_error = error.data;
				});
				
			},
      updateProject(project){
        this.$emit('edit-project', project);
      },
      getCollaborators(project){
        this.$emit('show-collaborators', project);
      },
      deleteProject(project){
        this.$emit('delete-project', project);
      },
      getAction(project){
        this.$emit('show-action', project);
      },
      checkAll: function(){ 
                 this.isCheckAll = !this.isCheckAll; 
                 this.selectedids = []; 
                 if(this.isCheckAll){ 
                     // Check all 
                     for (var key in this.actions.data) {          
                        this.selectedids.push(this.actions.data[key].id); 
                     } 
                 } 
              }, 
      updateCheckall: function(){ 
                   if(this.selectedids.length == this.actions.data.length){
                      this.isCheckAll = true; 
                   }else{ 
                      this.isCheckAll = false; 
                   } 
      },
     filterSelected(id){
        if(this.selectedids.length > 0){
          var exists = this.selectedids.filter(item => item == id);
          if(exists.length > 0){
            return true;
          }else{
            return false
          }
        }else{
          return false;
        }
      },
      addNew(){
        this.$emit('add-new-action');
      },
      daysOverdue(date){
        //console.log(DateTime.local().toISO());
        //const testdate = DateTime.fromISO("2020-09-06T14:00");
        const due = DateTime.fromSQL(date);
        const today = DateTime.fromISO(DateTime.local().toISO());
        const diff = today.diff(due, ["days", "hours"]);
        console.log(diff.toObject());
        if(diff.days > 0){
          return diff.days;
        }else{
          return '';
        }
        
      },
      isOverdue(date){
        //console.log(DateTime.local().toISO());
        //const testdate = DateTime.fromISO("2020-09-06T14:00");
        const due = DateTime.fromSQL(date);
        const today = DateTime.fromISO(DateTime.local().toISO());
        const diff = today.diff(due, ["days", "hours"]);
        console.log(diff.toObject());
        if(diff.days > 0){
          return true;
        }else{
          return false;
        }
      },
		},
		created(){
      //this.daysOverdue();
			this.id = this.$attrs.id;
			this.project_id = this.$attrs.project_id;
      if(this.assigned_type == ''){
        
      }else if(this.assigned_type == 'assingedto'){
        this.assigned_to = this.user_id;
      }else if(this.assigned_type == 'assignedby'){
        this.assigned_by = this.user_id;
      }
      this.limit = this.searchlimit;
      if(this.pageno > 1){
          this.currentPage = this.pageno;
        }
      if(this.typeid !== ''){
          this.types = this.typeid;
        }
			this.searchResource();
		  }
	}

</script>

<template>
	<div class="table-responsive mb-0">
              <table class="table table-nowrap table-centered">
                <tbody>
                  <tr  v-if="actions.data.length > 0" v-for="(action, index) in actions.data">
                    <td style="width: 60px;">
                      <div class="text-center">
                        <b-form-checkbox class="select-row-btn" v-model="selectedids" :value="action.id" button-variant="primary" button>

                          <i v-if="filterSelected(action.id)" class="bx bx-check"></i><span class="avatar-title rounded-circle" v-else>{{(action.name).slice(0,1)}}</span></b-form-checkbox>
                      </div>
                    </td>
                    <td>
                      <h5 class="text-truncate font-size-14 m-0">
                        <a href="javascript: void(0);" class="text-dark">{{action.name}}</a>
                      </h5>
                    </td>
                    <td>
                      <i class="bx bx-calendar"></i>
                      <span :class="{'text-linethrough' : action.more_time_approved == 1}">{{action.due_date | luxon}}</span>
                      <span v-if="(daysOverdue(action.due_date) > 0) && (action.more_time_approved == 0)"
                                        class="badge badge-danger"
                                        style="display: table-caption;" 
                                      >{{daysOverdue(action.due_date)}} days overdue</span>
                      <span class="text-danger"></span>
                      <span v-if="action.more_time_approved == 1">{{action.more_time_request | luxon}}</span>
                    </td>
                    <td >
                    <div class="team" v-if="action.collaborators.length > 0" >
                    <a class="" :href="'/company/user/'+action.collaborators[0].users.uid" v-b-tooltip.hover :title="action.collaborators[0].users.user.name">
                        <img class="avatar-xs rounded-circle mr-1" :src="action.collaborators[0].users.user.avatar">
                      </a>
                      <a class="" v-if="action.collaborators.length > 1" :href="'/company/user/'+action.collaborators[1].users.uid" v-b-tooltip.hover :title="action.collaborators[1].users.user.name">
                        <img class="avatar-xs rounded-circle mr-1" :src="action.collaborators[1].users.user.avatar">
                      </a>
                        <div class="avatar-xs d-inline-block">
                        <a  v-if="action.collaborators.length > 2" href="javascript:;" v-b-tooltip.hover :title="(action.collaborators.length - 2) + ' More Collaborators'" @click="getCollaborators(action)" >
                         <span class="avatar-title rounded-circle bg-light text-primary"> + {{(action.collaborators.length - 2)}}</span>
                        </a>
                        <a v-if="action.collaborators.length < 3" href="javascript:;" v-b-tooltip.hover title="Add Collaborators"  @click="getCollaborators(action)"><span class="avatar-title rounded-circle bg-light text-primary">+</span>
                        </a>
                      </div>
                    </div>
                    </td>
                    <td  :class="{'bg-soft-warning' : (action.review_requested == 0) && (action.completed == 0), 'bg-soft-success' : action.completed == 1, 'bg-soft-info' : (action.review_requested == 1) && (action.completed == 0), 'bg-soft-danger' : daysOverdue(action.due_date) > 0 }">
                      <ul class="list-inline font-size-20 contact-links mb-0">
                          <li class="list-inline-item pr-1">
                            <div  v-if="action.more_time_request !== null">
                              <a class="font-size-20" v-b-tooltip.hover title="More Time Requested" v-if="action.more_approved == 0"><i class="bx bx-time-five text-success"></i></a>
                              <a class="font-size-20" v-b-tooltip.hover title="More Time Approved" v-if="action.more_approved == 1"><i class="bx bx-time-five text-warning"></i></a>
                            </div>
                          </li>
                          <li class="list-inline-item pr-1">
                            <a v-if="action.completed == 1" class="font-size-20" v-b-tooltip.hover title="Completed" @click="action.completed = 0"><i class="bx bx-check-circle text-success"></i></a>
                            <a v-if="action.completed == 0" class="font-size-20" v-b-tooltip.hover title="Incomplete" @click="action.completed = 1"><i class="bx bx-check-circle text-danger"></i></a>
                          </li>
                          <li class="list-inline-item pr-1">
                            <a class="" v-if="action.reviewed == 1" v-b-tooltip.hover title="Approved"><i class="bx bx-like text-success"></i></a>
                            <a class="" v-b-tooltip.hover v-if="(action.reviewed == 0) && (action.review_requested)" title="Awaiting Approval"><i class="bx bx-dislike text-danger"></i></a>
                            <a class="" v-b-tooltip.hover v-if="action.review_requested == 0" title="Request Approval"><i class="bx bxs-megaphone text-info"></i></a>
                          </li>
                      </ul>
                    </td>
                     <td>
                      <ul class="list-inline font-size-20 contact-links mb-0">
                        <!--<li class="list-inline-item pr-1">
                          <a v-b-tooltip.hover title="Edit" @click="updateProject(action)">
                            <i class="bx bx-edit"></i>
                          </a>
                        </li>-->
                        <li class="list-inline-item pr-1">
                          <a v-b-tooltip.hover title="View" @click="getAction(action)">
                            <i class="mdi mdi-eye-outline"></i>
                          </a>
                        </li>
                        <!--<li class="list-inline-item pr-1">
                          <a v-b-tooltip.hover title="Reports">
                            <i class="bx bx-line-chart"></i>
                          </a>
                        </li>-->
                        <!--<li class="list-inline-item pr-1">
                          <a v-b-tooltip.hover title="Delete" @click="deleteProject(action)">
                            <i class="bx bx-trash-alt"></i>
                          </a>
                        </li>-->
                        <b-dropdown class="card-drop" variant="white" right toggle-class="p-0">
                      <template v-slot:button-content>
                        <i class="mdi mdi-dots-horizontal font-size-18"></i>
                      </template>
                      <b-dropdown-item href="javascript: void(0);">Action</b-dropdown-item>
                      <b-dropdown-item href="javascript: void(0);">Another action</b-dropdown-item>
                      <b-dropdown-item href="javascript: void(0);">Something else here</b-dropdown-item>
                    </b-dropdown>
                      </ul>
                    </td>
                  </tr>
                  <tr v-if="actions.data.length == 0">
                    <div class="card border border-info"><div class="card-header bg-transparent border-info"><h5 class="my-0 text-info"><i class="mdi mdi-alert-circle-outline mr-3"></i> No Actions To Show </h5></div><div class="card-body"><p class="card-text"> You haven't added any actions here yet. </p><a class="btn btn-primary btn-block" @click="addNew()">Add New Action</a></div></div>
                  </tr>
                </tbody>
              </table>
              <a v-if="actions.data.length > 0" class="btn btn-light btn-block mt-4 px-3" @click="addNew()">Add New Action <i class="mdi mdi-plus"></i></a>
            </div>
</template>