<script type="text/javascript">
	import VueGoodshare from "vue-goodshare";
	import VueGoodshareFacebook from "vue-goodshare/src/providers/Facebook.vue";
	import VueGoodshareTwitter from "vue-goodshare/src/providers/Twitter.vue";
	import VueGoodshareLinkedin from "vue-goodshare/src/providers/Linkedin.vue";
	import VueGoodshareReddit from "vue-goodshare/src/providers/Reddit.vue";
	import VueGoodsharePinterest from "vue-goodshare/src/providers/Pinterest.vue";
	import VueGoodshareWhatsApp from "vue-goodshare/src/providers/WhatsApp.vue";
	import VueGoodshareSMS from "vue-goodshare/src/providers/SMS.vue";

	export default{
		data(){
			return{
				share: false,
			}
		},
		components: {
			VueGoodshare,
			VueGoodshareFacebook,
			VueGoodshareTwitter,
			VueGoodshareLinkedin,
			VueGoodshareReddit,
			VueGoodsharePinterest,
			VueGoodshareWhatsApp,
			VueGoodshareSMS,
		}
	}	
</script>

<template>
	<div  class="d-flex flex-column position-fixed right-0 z-index-3">
				
				<!-- <vue-goodshare
				 button_design="outline"
				 has_icon
				 ></vue-goodshare>-->
				 <a class="btn btn-light text-primary" :class="{'ml-auto': share == false}" @click="share = !share">
					<i v-if="!share" class="bx bx-share-alt font-size-20"></i>
					<i v-else class="bx bx-x font-size-20"></i>
				</a>
				 <transition name="fade-right">
				 <div class="ml-auto d-flex flex-column" v-if="share == true">
				 <vue-goodshare-facebook
				 button_design="gradient"
				 has_icon
				 has_square_edges
				 class="font-size-16"
				 ></vue-goodshare-facebook>
				 <vue-goodshare-pinterest
				 button_design="gradient"
				 has_icon
				 has_square_edges
				 class="font-size-16"
				 ></vue-goodshare-pinterest>
				  <vue-goodshare-twitter
				 button_design="gradient"
				 has_icon
				 has_square_edges
				 class="font-size-16"
				 ></vue-goodshare-twitter>
				 <vue-goodshare-reddit
				 button_design="gradient"
				 has_icon
				 has_square_edges
				 class="font-size-16"
				 ></vue-goodshare-reddit>
				 <vue-goodshare-linkedin
				 button_design="gradient"
				 has_icon
				 has_square_edges
				 class="font-size-16"
				 ></vue-goodshare-linkedin>
				  <vue-goodshare-whatsapp
				 button_design="gradient"
				 has_icon
				 has_square_edges
				 class="font-size-16"
				 ></vue-goodshare-whatsapp>
				  <vue-goodshare-sms
				 button_design="gradient"
				 has_icon
				 has_square_edges
				 class="font-size-16"
				 ></vue-goodshare-sms>
				</div>
			</transition>
					
				</div>

</template>