<template>
	<b-card class="card overflow-hidden border-radius-1em shadow-sm seller-profile-card" no-body @mouseenter="hoverIndex = $attrs.index" @mouseleave="hoverIndex = -1">
          <div class="bg-soft bg-light">
            <div class="row">
            	<transition name="fade-left">
		                    	<div class="col-md-12 position-absolute z-index-2" v-if="hoverIndex == $attrs.index"> 
                              	<router-link :to="'/seller/'+$attrs.product.nickname+'/profile'" class="mt-3 ml-3 btn btn-primary font-size-16 btn-rounded ml-auto float-left" v-b-tooltip.hover.right title="View Company" ><i class="mdi mdi-eye-outline"></i></router-link>
                              </div>
                          </transition>
                           
                       <div class="col-12">
                       	<router-link :to="'/seller/'+$attrs.product.nickname+'/profile'">
                					<div v-if="$attrs.product.cover_image !== null">	
                						<img v-if="'gallery' in $attrs.product.cover_image" :src="storageurl + $attrs.product.cover_image.gallery" alt class="img-fluid" />
                						<img v-else-if="'thumb' in $attrs.product.cover_image" :src="storageurl + $attrs.product.cover_image.thumb" alt class="img-fluid" />
                						<img v-else-if="'src' in $attrs.product.cover_image" :src="storageurl + $attrs.product.cover_image.src" alt class="img-fluid" />
                						<div v-else style="min-height: 240px;"></div>
                					</div>
                					<div v-else style="min-height: 240px;"></div>
                				</router-link>
                 <transition name="fade-right">
		                        <div class="mt-auto mb-3 mx-2 d-flex position-absolute bottom-0 right-0 left-0" v-if="hoverIndex == $attrs.index">
                              	<b-form-rating style="width: 30%;" id="rating-inline" readonly variant="warning" class="bg-transparent pr-4 ml-auto" inline no-border size="sm" :value="$attrs.product.reviews_avg_rating"></b-form-rating> 
              					</div>
              				</transition>
              </div>
            </div>
          </div>
          <b-card-body class="card-body pt-0">
            <div class="row">
              <div class="col-sm-12 pb-4 text-center">
                <div class="avatar-md profile-user-wid mb-2 mx-auto" style="margin-top: -45px !important;">
                  <img
                  	v-if="$attrs.product.logo" :src="$attrs.product.logo.includes(storageurl) == false ? (storageurl + $attrs.product.logo) : $attrs.product.logo"
                    alt
                    class="img-thumbnail rounded-circle"
                  />
                   <img
                  	v-else 
                    src="/images/product-placeholder.png"
                    alt
                    class="img-thumbnail rounded-circle"
                  />
                </div>
                <h5 class="font-size-15 text-truncate">{{$attrs.product.name}}</h5>
                <p class="text-muted mb-0 text-truncate"><span v-if="$attrs.product.county !== null">{{$attrs.product.county.name}},</span> {{$attrs.product.country.name}}</p>
              </div>
              <div class="col-md-6 d-flex">
              	<span class="badge badge-light border-radius-1em bg-light text-dark font-size-11 lh-16 my-auto p-2">
              		{{$attrs.product.type.name}}
              	</span>
              </div>
              <div class="col-md-6 d-flex">
              	<!--<span class="badge badge-light border-radius-1em bg-light text-dark font-size-11 lh-16 my-auto p-2 ml-auto">
              		100 <i class="bx bx-tag"></i>
              	</span>-->
              	<span class="badge badge-light border-radius-1em bg-light text-dark font-size-11 lh-16 my-auto p-2 ml-auto">
              		{{$attrs.product.listings.length}} Stores and Locations <i class="bx bx-buildings"></i>
              	</span>
              </div>

            </div>
          </b-card-body>
        </b-card>
</template>

<script type="text/javascript">

export default{
	data (){
			return {
				hoverIndex: -1,
			}
		},
}


</script>