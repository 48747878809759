<script>
	import Multiselect from "vue-multiselect";
	export default{
		components: { Multiselect },
		data(){
			return{
				search: '',
				currentPage: 1,
				order: 'desc',
      			sortby: 'id',
      			limit: 0,
				limits: [
		        {value: 25, text: "25"},
		        {value: 50, text: "50"},
		        {value: 100, text: "100"},
		    	],
				response_error: []
			}
		},
		props: {
			id: {
				type: Object,
					default: '',
			},
			isDisabled: {
				type: Boolean,
				default: () => false,
				},
			options: {
				type: Array,
				default: () => [
					{id: null, name: 'No Options'}
				],
			},
			resource: {
				type: String
			}
		},
		mounted(){
	
			
		},
		methods:{
			updateDepartment: function(selectedOption, id){
				this.$emit('resource-selected', selectedOption);
			},
			customLabel ({ title, desc }) {
		      return `${title} – ${desc}`
		    }
		}
	}
</script>
<template>
	<div>
	<multiselect v-model="id" track-by="name" label="name" :options="options" @select="updateDepartment" :option-height="104" :disabled="isDisabled" >
                     <!--<template slot="singleLabel" slot-scope="{ option }">{{ option.name }} - {{ option.jobtitle }}</template>-->
                     <template slot="singleLabel" slot-scope="{ option }"><div class="d-flex w-100 h-100"><div class="avatar-sm mr-4 my-auto">
                        <img v-if="'image' in option" :src="option.image" class="avatar-sm rounded-circle">
                        <span v-else-if="'name' in option" class="avatar-title rounded-circle">{{(option.name).slice(0,1)}}</span>
                        <span v-else-if="'display_name' in option" class="avatar-title rounded-circle">{{(option.display_name).slice(0,1)}}</span>
                        <span v-else class="avatar-title rounded-circle">#</span>
                      </div>
      <div class="option__desc d-flex flex-row w-75"><span class="option__title ml-2 my-auto w-100"><span v-if="'name' in option">{{ option.name }}</span><span v-else-if="'display_name' in option">{{option.display_name}}</span><span v-else-if="'id' in option">{{option.id}}</span><br><p class="m-0 lh-1 w-100 text-truncate"><small v-if="resource == 'address'">{{ option.address_1 }} {{ option.city }} {{ option.postcode }}</small><small v-else-if="resource == 'terminal_location'">{{ option.address.line1 }} {{ option.address.city }} {{option.address.postal_code}} {{ option.address.country }}</small><small v-else>{{ option.description }}</small></p></span>
      </div>
  		</div></template>
    <template slot="option" slot-scope="{ option }">
    	<div class="d-flex"><div class="avatar-sm mr-4">
                       <img v-if="'image' in option" :src="option.image" class="avatar-sm rounded-circle">
                        <span v-else-if="'name'  in option" class="avatar-title rounded-circle">{{(option.name).slice(0,1)}}</span>
                        <span v-else-if="'display_name' in option" class="avatar-title rounded-circle">{{(option.display_name).slice(0,1)}}</span>
                        <span v-else class="avatar-title rounded-circle">#</span>
                      </div>
      <div class="option__desc d-flex flex-row w-50"><span class="option__title ml-2 my-auto w-100"><span v-if="'name' in option">{{ option.name }}</span><span v-else-if="'display_name' in option">{{option.display_name}}</span><span v-else-if="'id' in option">{{option.id}}</span><br><p class="m-0 lh-1 w-100 text-truncate"><small v-if="resource == 'address'">{{ option.address_1 }} {{ option.city }} {{ option.postcode }}</small><small v-else-if="resource == 'terminal_location'">{{ option.address.line1 }} {{ option.address.city }} {{option.address.postal_code}} {{ option.address.country }}</small><small v-else>{{ option.description }}</small></p></span>
      </div>
  		</div>
    </template>
                  </multiselect>
   </div>
</template>