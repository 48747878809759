<template>
	<div>
		<!--<h4 class="font-size-28 mb-4" v-if="product.combinations.length == 0">£{{(parseFloat(priceIncVat) + parseFloat(selectedvariantstotal)).toFixed(2)}}</h4>
				<h4 v-else class="font-size-28 mb-4">£{{(parseFloat(priceIncVat) + parseFloat(combinationPrice)).toFixed(2)}}</h4>-->

				<h4 class="font-size-28" v-if="product.combinations.length == 0">{{$attrs.selected_currency_symbol}}{{((parseFloat(priceIncVat) + parseFloat(selectedvariantstotal)) * (1 / $attrs.exchange_rates[company.trading_currency_code])).toFixed(2) }}</h4>
				<h4 v-else class="font-size-28">{{$attrs.selected_currency_symbol}}{{((parseFloat(priceIncVat) + parseFloat(addTax(product, combinationPrice)))  * (1 / $attrs.exchange_rates[company.trading_currency_code])).toFixed(2)}}</h4>
				<small class="mb-4">{{$attrs.selected_currency_symbol}}{{((parseFloat(product.sales_price) + parseFloat(combinationPrice)) * (1 / $attrs.exchange_rates[company.trading_currency_code])).toFixed(2)}} Exc Vat</small>
	</div>
</template>

<script type="text/javascript">
	export default{
		data(){
			return {
				
			}
		},
		props: {
			company: {
				type: Object,
			},
			product: {
				type: Object,
			},
			selectedvariants: {
				type: Array,
			},
			selectedcombination: {
				type: Object,
			},
			selectedvariantstotal: {
				type: Number,
			}
		},
		methods: {
			calculateVariantsTotal(){
        		var total = 0.00;
        		for(let key in this.selectedvariants){
        			console.log(key);
        			total += parseFloat(this.selectedvariants[key].price_difference);
        		}
        		this.selectedvariantstotal = this.addTax(total);
        	},
        	addTax(product, price){
        		var tax_rule = this.product.tax_rule;
        		console.log(tax_rule);
		    		var country_id = this.company.country_id;
		    	if(tax_rule !== null && tax_rule.country_rules.length > 0){
		    		var selected_rule = null;
		    		var selected_ruleIndex = -1;
			    	for(var ti = 0; ti < tax_rule.country_rules.length; ti++){
			    		selected_ruleIndex = tax_rule.country_rules[ti].countries.findIndex(item => item == country_id);
			    		if(selected_ruleIndex !== -1){
			    			selected_rule = tax_rule.country_rules[ti];
			    			ti = tax_rule.country_rules.length;
			    		}
			    	}
			    	console.log(selected_rule);
			    	if(tax_rule.type == 1){
		        		if(selected_rule !== null){
		        			var percentage = (parseFloat(selected_rule.value) / 100) + 1;
		        			return price * percentage;
		         		}else{
		         			return price;
		         		}
	         		}else{
	         			if(selected_rule !== null){
		        			return price + selected_rule.value;
		         		}else{
		         			return price;
		         		}
	         		}
         		}else if(tax_rule !== null && tax_rule.country_rules.length > 0){
         			var percentage = (parseFloat(tax_rule.percentage) / 100) + 1;
		        	return price * percentage;
         		}else {
		        	return price;
         		}
        	},
		},
		computed: {
			variantsTotal(){
        		var total = 0.00;
        		for(let key in this.selectedvariants){
        			console.log(key);
        			total =+ parseFloat(this.selectedvariants[key].price_difference);
        		}
        		return total;
        	},
        	priceIncVat(){
        		if(this.product.tax_rule !== null){
        			return this.addTax(this.product, this.product.sales_price);
         		}else{
         			return this.product.sales_price;
         		}
        	},
        	
        	combinationPrice(){
        		if(this.selectedcombination !== null){
        		return parseFloat(this.selectedcombination.price_difference);
        		}else{
        			return parseFloat(0.00);
        		}
        	}
		}
	}

</script>