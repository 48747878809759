<template>
	<Layout  :nickname="$attrs.nickname" :user="$attrs.user" :menuItems="$attrs.menuItems">
    	<PageHeader />
			<b-row>
				<b-col md="3">
					<b-card class="shadow-sm border-radius-1em">
						<h6>Basic Info</h6>
						
						<b-form-group class="mb-2" label="Reference">
							<b-form-input type="text"></b-form-input>
						</b-form-group>
						<b-form-group class="mb-2" label="Display Name">
							<b-form-input type="text"></b-form-input>
						</b-form-group>
						<b-form-group class="mb-2" label="Description">
							<b-form-textarea>
							</b-form-textarea>
						</b-form-group>
						<b-form-group label="Status">
							<b-form-select class="form-control" :options="['Draft', 'In Progress', 'Send to Customer', 'Complete']" ></b-form-select>
						</b-form-group>
						<b-form-group class="mb-2" label="">
							

						</b-form-group>
					</b-card>
					
					<b-card class="shadow-sm border-radius-1em">
						<h6>Report Template</h6>
					</b-card>

					<b-card class="shadow-sm border-radius-1em">
						<h6>Total Value</h6>
					</b-card>
					<b-card class="shadow-sm border-radius-1em">
						<h6>Fees</h6>
					</b-card>
				</b-col>

				<b-col md="9">
					<simplebar style="overflow-x: hidden;" :style="{'max-height' : this.innerPanelHeight+'px', 'height' : this.innerPanelHeight+'px'}">
					<b-row>
						<b-col md="12">
							<b-card class="shadow-sm border-radius-1em">
								<h6>Customer</h6>
							<div class=" border border-radius-1em mx-auto my-auto text-center p-3" v-if="valuation.customer == ''">
		             			<i class="bx bx-user-circle font-size-42 text-muted d-block mx-auto pb-2 pt-4"></i>
		             			<p class="text-muted pb-2">
		             				No Customer Selected
		             			</p>

		             			<div class="d-flex pb-2">
								<a class="btn btn-primary btn-rounded mr-1 ml-auto" @click="customerSearch = !customerSearch">
									<span v-if="customerSearch == false">Select Customer</span>
									<span v-else><i class="bx bx-x"></i> Cancel</span>
								</a>
								<a class="btn btn-light btn-rounded ml-1 mr-auto" v-b-modal.action-modal>
									Add Customer
								</a>
								</div>
		             		</div>
							<transition name="fade-top">
								<b-card  class="shadow-none border border-radius-1em mt-2" v-if="customerSearch == true">
								 <CompanyCustomerSearch v-if="valuation.customer == ''" ref="selectcustomer"  :api_token="user.api_token" @search-resource-selected="selectCustomer" :input_classes="'bg-light'"></CompanyCustomerSearch>
								 <div v-if="valuation.customer !== ''" class="d-flex border border-radius-1em px-3 py-2 my-2">
								<div v-if="valuation.customer.customer !== null"><img v-if="valuation.customer.valuation.customer.avatar" class="avatar-sm border mr-3 rounded-circle" :src="valuation.customer.valuation.customer.avatar">
                                <img v-else class="avatar-sm border mr-3 rounded-circle" src="/images/product-placeholder.png">
	                              </div>
	                              <img v-else class="avatar-sm border mr-3 rounded-circle" src="/images/product-placeholder.png">
	                                <div class="mr-auto ml-2 my-auto flex-grow-3 mr-3">
	                                	<h6 class="mr-auto my-auto">{{valuation.customer.firstname}} {{valuation.customer.lastname}}</h6>

	                                </div>
	                                <a class="btn btn-light my-auto rounded-circle" v-b-tooltip.hover title="Remove Customer" @click="valuation.customer = ''"><i class="mdi mdi-close font-size-18"></i></a>

								</div>
							</b-card>
							</transition>
							</b-card>
							
						</b-col>
						<b-col md="6">
							<b-card class="shadow-sm border-radius-1em">
								<div class="d-flex w-100"><h6>Valuation Address</h6> <a class="ml-auto my-auto" @click="valuation.valuation_address = ''" href="javascript:;">Clear</a></div>

								<div class=" border border-radius-1em mx-auto my-auto text-center p-3" v-if="valuation.customer == ''">
		             			<i class="bx bx-home font-size-42 text-muted d-block mx-auto pb-2 pt-4"></i>
		             			<p class="text-muted">
		             				Please Select a Customer to Choose an Address
		             			</p>
		             			<div class="d-flex position-relative"  v-if="valuation.valuation_address == ''">
								<h4 class="z-index-2 mx-auto text-primary font-size-20 py-2 position-relative"> OR</h4>
								<span class=" divider-text "></span>
								</div>
		             			<div class="d-flex" v-if="valuation.valuation_address == ''">
								<a class="btn btn-light btn-rounded btn-block mt-2">
									Manually Enter Address
								</a>
								</div>
		             		</div>
		             		<div v-if="valuation.customer !== ''"  class="border border-radius-1em mx-auto my-auto text-center p-3">
								<SearchCompanyCustomerAddress :id="customer_address" :api_token="user.api_token" :options="valuation.customer.addresses" @listing-selected="selectValuationAddress" :data_provided="true" ></SearchCompanyCustomerAddress>
								<div class="d-flex position-relative"  v-if="valuation.valuation_address == ''">
								<h4 class="z-index-2 mx-auto text-primary font-size-20 py-2 position-relative"> OR</h4>
								<span class=" divider-text "></span>
								</div>
			             			<div class="d-flex" v-if="valuation.valuation_address == ''">
								<a class="btn btn-light btn-rounded btn-block mt-2">
									Manually Enter Address
								</a>
								</div>
							</div>
							</b-card>
						</b-col>
						<b-col md="6">
							<b-card class="shadow-sm border-radius-1em">
								<div class="d-flex w-100"><h6>Billing Address</h6> <a class="ml-auto my-auto" @click="valuation.billing_address = ''" href="javascript:;">Clear</a></div>
								<div class=" border border-radius-1em mx-auto my-auto text-center p-3" v-if="valuation.customer == ''">
			             			<i class="bx bx-home font-size-42 text-muted d-block mx-auto pb-2 pt-4"></i>
			             			<p class="text-muted">
			             				Please Select a Customer to Choose an Address
			             			</p>
			             			<div class="d-flex position-relative"  v-if="valuation.billing_address == ''">
								<h4 class="z-index-2 mx-auto text-primary font-size-20 py-2 position-relative"> OR</h4>
								<span class=" divider-text "></span>
								</div>
			             			<div class="d-flex"  v-if="valuation.billing_address == ''">
									<a class="btn btn-light btn-rounded btn-block mt-2" v-b-modal.modal-address>
										Manually Enter Address
									</a>
									</div>
		             			</div>
		             			<div v-if="valuation.customer !== ''"  class="border border-radius-1em mx-auto my-auto text-center p-3">
								<SearchCompanyCustomerAddress v-if="valuation.customer !== ''" :id="customer_address" :api_token="user.api_token" :options="valuation.customer.addresses" @listing-selected="selectBillingAddress" :data_provided="true" ></SearchCompanyCustomerAddress>
								<div class="d-flex position-relative"  v-if="valuation.billing_address == ''">
								<h4 class="z-index-2 mx-auto text-primary font-size-20 py-2 position-relative"> OR</h4>
								<span class=" divider-text "></span>
								</div>
			             		<div class="d-flex"  v-if="valuation.billing_address == ''">
									<a class="btn btn-light btn-rounded btn-block mt-2" v-b-modal.modal-address>
										Manually Enter Address
									</a>
								</div>
							</div>
							</b-card>
						</b-col>
					</b-row>
					<b-row>
						<b-col>
							<b-card class="shadow-sm border-radius-1em">
								<h6>Cover Statement</h6>
								<b-card class="shadow-none border border-radius-1em text-center">
									<i class="bx bx-notepad font-size-42 text-muted d-block mx-auto pb-2 pt-4"></i>
			             			<p class="text-muted">
			             				No Cover Letter Added
			             			</p>
			             			<div class="d-flex pb-2">
										<a class="btn btn-primary btn-rounded mr-1 ml-auto" @click="customerSearch = !customerSearch">
											<span v-if="customerSearch == false">Select Cover Letter</span>
											<span v-else><i class="bx bx-x"></i> Cancel</span>
										</a>
										<a class="btn btn-light btn-rounded ml-1 mr-auto" v-b-modal.action-modal>
											Add Cover Letter
										</a>
									</div>
								</b-card>
								<!--<div class="d-flex">
								<a class="btn btn-primary btn-rounded mx-auto mb-2">
									<i class="mdi mdi-plus"></i>
								</a>
								</div>-->
							</b-card>
						</b-col>
					</b-row>
					<b-row>
						<b-col>
							<b-card class="shadow-sm border-radius-1em">
								<h6>Valuation Items</h6>
								<b-card v-if="valuation.notes.length == 0" class="shadow-none border border-radius-1em text-center">
									<i class="mdi mdi-diamond-stone font-size-42 text-muted d-block mx-auto pb-2 pt-4"></i>
			             			<p class="text-muted">
			             				No Items Added
			             			</p>
			             			<div class="d-flex pb-2">
										<a class="btn btn-primary btn-rounded mx-auto" @click="addItem = !addItem">
											Add Item <i class="mdi mdi-plus"></i>
										</a>
									</div>
								</b-card>
								<div class="d-flex" v-if="valuation.notes.length > 0">
								<a class="btn btn-primary btn-rounded mx-auto mb-2">
									<i class="mdi mdi-plus"></i>
								</a>
								</div>			
							</b-card>
						</b-col>
					</b-row>
					<b-row>
						<b-col>
							<b-card class="shadow-sm border-radius-1em">
								<h6>Valuation Notes</h6>
								<b-card v-if="valuation.notes.length == 0" class="shadow-none border border-radius-1em text-center">
									<i class="bx bx-note font-size-42 text-muted d-block mx-auto pb-2 pt-4"></i>
			             			<p class="text-muted">
			             				No Notes Added
			             			</p>
			             			<div class="d-flex pb-2">
										<a class="btn btn-primary btn-rounded mr-1 ml-auto" @click="customerSearch = !customerSearch">
											<span v-if="customerSearch == false">Select Cover Letter</span>
											<span v-else><i class="bx bx-x"></i> Cancel</span>
										</a>
										<a class="btn btn-light btn-rounded ml-1 mr-auto" v-b-modal.action-modal>
											Add Cover Letter
										</a>
									</div>
								</b-card>
								<div class="d-flex" v-if="valuation.notes.length > 0">
								<a class="btn btn-primary btn-rounded mx-auto mb-2">
									<i class="mdi mdi-plus"></i>
								</a>
								</div>
							</b-card>
						</b-col>
					</b-row>
					<b-row>
						<b-col md="6">
							<b-card class="shadow-sm border-radius-1em">
								<h6>Valuers' Stamp</h6>


								<div class="d-flex">
								<a class="btn btn-primary btn-rounded mx-auto mb-2">
									<i class="mdi mdi-plus"></i>
								</a>
								</div>
							</b-card>
						</b-col>
						<b-col md="6">
							<b-card class="shadow-sm border-radius-1em">
								<h6>Valuer's Signature</h6>

								<div class="d-flex">
								<a class="btn btn-primary btn-rounded mx-auto mb-2">
									<i class="mdi mdi-plus"></i>
								</a>
								</div>
							</b-card>
						</b-col>
					</b-row>
				</simplebar>
				</b-col>
			</b-row>
			<b-modal id="action-modal">
				<b-row>
					<b-col>
					</b-col>
				</b-row>
			</b-modal>
			
			<b-modal ref="modal-address" id="modal-address" centered content-class="border-radius-1em" header-class="border-none" hide-footer>
				<div class="p-3">	
					<CompanyCustomerAddressForm :address="address" :action="action" :user="user"></CompanyCustomerAddressForm>
			    </div>
			</b-modal>
		<!--<b-modal  hide-footer cancel-disabled="true" id="item-modal" size="xl" scrollable >
				<template slot="modal-title">Add / Edit Product
					<b-nav pills>
						<b-nav-item @click="productTabNo = 0" :active="productTabNo == 0">Description</b-nav-item>
						<b-nav-item @click="productTabNo = 1" :active="productTabNo == 1">Images</b-nav-item>
						<b-nav-item @click="productTabNo = 2" :active="productTabNo == 3">Components</b-nav-item>
					</b-nav>
				</template>
				<div class="row">
					<div class="col-md-12" v-if="productTabNo == 0">
						<div class="row">
							<div class="form-control-group col-md-12">
								<label>Item Name</label>
								<input class="form-control" v-model="selected_modal.name" :value="selected_modal.name"></input>
							</div>
						<div class="form-control-group col-md-12">
							<label>Item Description</label>
							<ckeditor class="description_editor" id="description_editor" rows="10" :editor="editor" v-model="selected_modal.description" :config="editorConfig"></ckeditor>
						</div>
						<div class="col-md-12">
							<div class="row">
							<div class="form-control-group col-md-4 pt-2" v-for="(attribtype, index) in attribute_type">
								<label>@{{attribtype.name}}</label>
								<select class="form-control" v-model="selected_modal.attributes[index]">
									<option value="">--Select Item Type</option>
									<option v-for="attrib in attributeTypeValues(attribtype.id)" :value="{valuation_item_attribute_value_id: 0, value_id: attrib.id, name: attribtype.name, type_id: attribtype.id}">@{{attrib.name}}</option></select>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-md-12" v-if="productTabNo == 1">
				<div class="logo-input form-control-group col-md-12">
					<label for="companylogo">Cover Image <small>(Max-size for logo file is 300x300 px.)</small></label>

					<file-pond :allow-multiple="false" :allowDrop="true" :files="files" v-model="cover_image" :instantUpload="false" server="/"></file-pond>
				</div>
				<div class="form-control-group col-md-12 text-center"><label>Original Stock Image</label>
					<img v-if="selected_modal !== undefined" class="img-responsive original-stock-img" width="250" height="auto" :src="productImage(selected_modal.external_item_id, selected_modal.id_default_image)">
				<b-form-checkbox id="checkbox-1" v-model="status" name="checkbox-1" value="accepted" unchecked-value="not_accepted">Use as main</b-form-checkbox></div>
				<div class="form-control-group col-md-12">
					<label>Additional Images</label>
				<file-pond :allow-multiple="true" :allowDrop="true" :files="files" v-model="additional_files" :instantUpload="false" server="http://localhost:7080/jewellery-valuations/public/storage/public"></file-pond>
					<div class="row">
						<div v-if="selected_modal !== undefined" class="col-md-2" v-for="gallery_image in gallery_images">
							<img class="img-responsive gallery-thumbnail-img float-left"  :src="productImage(selected_modal.external_item_id, gallery_image.id)" rounded>
						</div>
					</div>
				</div>
			</div>
			<div class="col-md-12" v-if="productTabNo == 2">
				<div class="row">
					<div class="form-control-group col-md-3 my-4">
						<label>Component Name</label>
						<b-form-select v-model="selectedcomponents" :options="componentName"></b-form-select>
					</div>
				</div>
				<diamond-component v-if="selectedcomponents == 2"></diamond-component>
			</div>
		</b-modal>-->
		<SideSlidePanel :editPanel="addItem" :panelClass="'edit-sidepanel'" @close-panel="addItem = !addItem">
			<b-row class="px-4">
				<b-col md="12">
					<h4>Add Item</h4>
					<hr>
				</b-col>
			</b-row>
			<b-row class="pt-2 px-4">
				<b-col md="2">
					<h5 class="d-flex">Basic Info <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
				</b-col>
				<b-col md="10">
					<b-card class="border-radius-1em border shadow-none">
						<b-form-group label="Name">
							<b-form-input>
							</b-form-input>
						</b-form-group>
						<b-form-group label="Description">
							<b-form-textarea>
							</b-form-textarea>
						</b-form-group>
						<b-form-group label="Valuation Notes">
							<b-form-textarea>
							</b-form-textarea>
						</b-form-group>
					</b-card>
				</b-col>
				<b-col md="2">
					<h5 class="d-flex">Images <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
				</b-col>
				<b-col md="10">

					<b-card class="border-radius-1em border shadow-none">
					<b-form-group label="Images">
					 <vue-dropzone id="dropzone" ref="myVueDropzone" :use-custom-slot="true" :options="dropzoneOptions" @vdropzone-file-added="imageAdded"  @vdropzone-drop="imageAdded">
                        <div class="dropzone-custom-content">
                            <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                            <h4>Drop files here or click to upload.</h4>
                        </div>
                        </vue-dropzone>
                    </b-form-group>
                	</b-card>
				</b-col>
				<b-col md="2">
					<h5 class="d-flex">Components <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
				</b-col>
				<b-col md="10">
					<a class="btn btn-primary waves-effect waves-light btn-block btn-rounded" @click="showComponents = !showComponents">
						<i class="mdi mdi-plus font-size-16"></i>
						Add Item Component
					</a>
					<b-card class="border-radius-1em border shadow-none mt-2" v-if="showComponents == true">

					
					<a class="btn btn-light waves-effect waves-light w-lg p-3 mr-2" v-for="component in valuationComponents" @click="selectedComponent = component, componentEditor = true">
						<i class="d-block font-size-16" :class="component.icon"></i>
						{{component.name}}
					</a>
					</b-card>
				<transition name="fade-right">
					<b-card class="shadow-none border border-radius-1em"  v-if="componentEditor == true" >
						<h6>Component Configuration</h6>
						<componentsLoader :component="selectedComponent.name" :componentData="selectedComponent" ></componentsLoader>
					</b-card>
				</transition>
				</b-col>
				<b-col md="2">
					<h5 class="d-flex">Configuration <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
				</b-col>
				<b-coi md="10">
				</b-coi>
			</b-row>
		</SideSlidePanel>
	</Layout>
</template>

<script type="text/javascript">
	import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

	import Layout from "../../layouts/main";
	import PageHeader from "../../components/page-header";
	import simplebar from "simplebar-vue"
	import CompanyCustomerSearch from "../../components/widgets/CompanyCustomerSearch"
	import SearchCompanyCustomerAddress from "../../components/widgets/SearchCompanyCustomerAddress"
	import CompanyCustomerAddressForm from "../../components/widgets/forms/company/CompanyCustomerAddressForm";
	import vue2Dropzone from 'vue2-dropzone';
	import SideSlidePanel from "../../components/widgets/SideSlidePanel";
	import { valuationComponents } from './valuation-items';
	import componentsLoader from './componentsLoader';

	export default {
		components: {Layout, PageHeader, CompanyCustomerSearch, SearchCompanyCustomerAddress, CompanyCustomerAddressForm, simplebar, vueDropzone: vue2Dropzone, SideSlidePanel, ckeditor: CKEditor.component, componentsLoader},
		data() {
			return {
				showComponents: false,
				componentEditor: false, 
				selectedComponent: '',
				addItem: false,
				valuation: {
					customer: '',
					valuation_address: '',
					billing_address: '',
					cover_letter: [],
					items: [],
					stamp: [],
					signature: [],
					notes: [],
				},
				address: {
					id: '',
					customer_id: '',
					address_1: '',
					address_2: '',
					address_3: '',
					city: '',
					county: null,
					country: null,
					alias: '',
					postcode: '',
					delivery_default: 0,
					invoice_default: 0,
					active: 1,

				},
				valuationComponents: valuationComponents,
				action: 'create',
				customerSearch: false,
				innerPanelHeight: 800,
				dropzoneOptions: {
				        url: 'https://httpbin.org/post',
				        thumbnailHeight: 150,
				        thumbnailWidth: 150,
				        autoProcessQueue: false,
				      },
			}
		},
		props: {
			user: {
				type: Object
			},
			company: {
				type: Object
			}
		},
		methods: {
			selectCustomer(customer){
				this.valuation.customer = customer;
			},
			selectValuationAddress(address){
				this.valuation.valuation_address = address;
			},
			selectBillingAddress(address){
				this.valuation.billing_address = address;
			},
			windowResize(){
				this.innerPanelHeight = window.innerHeight - 100;
			},
			getValuationTemplates(){
				
			}

		},
		created() {
            window.addEventListener("resize", this.windowResize);
        },
        destroyed() {
            window.removeEventListener("resize", this.windowResize);
        },
	}

</script>