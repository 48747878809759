<script>
import NavBar from "../components/pos-nav-bar";
import SideBar from "../components/pos-side-bar";
import RightBar from "../components/right-bar";
import Footer from "../components/footer";
import SideSlidePanel from "../components/widgets/SideSlidePanel";
import SidePanelCalculators from "../components/widgets/calculators/SidePanelCalculators";


export default {
  components: { NavBar, SideBar, RightBar, Footer, SideSlidePanel, SidePanelCalculators},
  data() {
    return {
      isMenuCondensed: false,
      showCalculators: false,
      calculatorTab: 0,
    };
  },
  created: () => {
    document.body.removeAttribute("data-layout", "horizontal");
    document.body.removeAttribute("data-topbar", "light");
    document.body.removeAttribute("data-layout-size", "boxed");
    document.body.classList.add("pos-wrapper");
    document.body.setAttribute("data-pos", "true");
  },
  methods: {
    
    toggleMenu() {
      document.body.classList.toggle("sidebar-enable");

      if (window.screen.width >= 992) {
        // eslint-disable-next-line no-unused-vars
        document.body.classList.toggle("vertical-collpsed");
      } else {
        // eslint-disable-next-line no-unused-vars
        document.body.classList.remove("vertical-collpsed");
      }
      this.isMenuCondensed = !this.isMenuCondensed;
    },
    toggleRightSidebar() {
      document.body.classList.toggle("right-bar-enabled");
    },
    hideRightSidebar() {
      document.body.classList.remove("right-bar-enabled");
    },
    showCalculator(payload){
      this.showCalculators =  true;
      this.calculatorTab = payload;
    }

  }
};
</script>

<template>
  <div id="layout-wrapper">
    <NavBar :user="$attrs.user"  @showCalculatorWindow="showCalculator" />
    <!--<SideBar :is-condensed="isMenuCondensed" />-->
    <!-- ============================================================== -->
    <!-- Start Page Content here -->
    <!-- ============================================================== -->

    <div class="main-content">
      <div class="page-content">
        <!-- Start Content-->
        <div class="container-fluid">
          <slot  @showStorageWindow="showFileManager = !showFileManager"  />
        </div>
      </div>
      <SideSlidePanel :editPanel="showCalculators" :panelClass="'edit-sidepanel-large'" @close-panel="showCalculators = false">
        <b-row><SidePanelCalculators :activeTab="calculatorTab" /></b-row>
      </SideSlidePanel>

      <!--<Footer />-->
    </div>
    <RightBar />
  </div>
</template>
