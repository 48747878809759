<script>
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import DateTime from 'luxon/src/datetime.js';
import Interval from 'luxon/src/interval.js';
import SideSlidePanel from "../../../../components/widgets/SideSlidePanel"
import Toolbar from "../../../../components/widgets/admin/email/EmailToolbar";
import Sidepanel from "../../../../components/widgets/admin/email/EmailSidepanel";
import EmailViewer from "../../../../components/widgets/admin/email/EmailViewer";
import Emailer from "../../../../components/widgets/admin/email/Emailer"
//import { emailData } from "./data-inbox";

/**
 * Email-inbox component
 */
export default {
  components: {
    Toolbar,
    Sidepanel,
    EmailViewer,
    ckeditor: CKEditor.component,
    SideSlidePanel,
    Emailer,
    DateTime,
    Interval
  },
  data() {
    return {
      selectedids: [],
      success_message: false,
      success_messagetext: '',
      loadEmailViewer: false,
      emailViewerPanel:  false,
      emailViewerData: true,
      emailData: '',
      emailDataRaw: '',
      active_filter: '',
      send_email: {
        id: '',
        notification_thread_id: '',
        email_to: [],
        email_cc: [],
        email_bcc: [],
        subject: '',
        message: '',
        replying_to: [],
      },
      send_email_raw: {
        id: '',
        notification_thread_id: '',
        email_to: [],
        email_cc: [],
        email_bcc: [],
        subject: '',
        message: '',
        replying_to: [],
      },
      paginatedEmailData: '',
      title: "Customer Enquiries",
      items: [
        {
          text: "Customer",
          href: "/",
        },
        {
          text: "Enquiries",
          active: true,
        },
      ],
      // page number
      currentPage: 1,
      // default page size
      perPage: 10,
      emailIds: [],
      // start and end index
      startIndex: 1,
      endIndex: 10,
    };
  },
  computed: {
    rows() {
        return this.emailData.length;
    },
    unread(){
      if(this.emailDataRaw.length > 0){
        return this.emailDataRaw.filter(item => item.read_at == null).length;
      }else{
        return 0;
      }
    }
  },
  created() {
    this.getEnquiries();
    this.startIndex = 0;
    this.endIndex = this.perPage;

    
  },
  props: {
    showToolbar: {
      type: Boolean,
      default: () => false,
    },
    emailsource: {
      type: String,
      default: () => '',
    }
  },
  methods: {
     reloadEnquiries(source){
        //this.showEmailList = true;
        this.emailsource = source;
        this.getEnquiries();
        let self = this;
        setTimeout(function(){
          //  self.showEmailList = false;
        }, 1000);
    },
    filterSelected(id){
        if(this.selectedids.length > 0){
          var exists = this.selectedids.filter(item => item == id);
          if(exists.length > 0){
            return true;
          }else{
            return false
          }
        }else{
          return false;
        }
      },
    getDate(date){
      return DateTime.fromISO(date).toHTTP();
    },
    sendEmail(email){
      if(email !== this.send_email_raw){
        this.send_email = email;
      }else{
        this.send_email = this.send_email_raw;
      }
      this.$bvModal.show('send-email'+this.emailsource);
    },
    filterNotifications(params){
      this.active_filter = params;
      if(params !== ''){
      this.emailData = this.emailDataRaw.filter(item => item.data.source == params);
      this.paginatedEmailData = this.emailData.slice(
            0,
            this.perPage
          );
      }else{
        this.emailData = this.emailDataRaw;
        this.onPageChange();
      }
    },
    readEmail(email){
      this.loadEmailViewer = true;
      this.emailViewerPanel = true;
      this.emailViewerData = email;
      this.markAsRead(email.id, true);
       var self = this;
                setTimeout(function(){
                 //Vue.swal("Avatar Updated");
                 self.loadEmailViewer = false;
                }, 500);
    },
    getEnquiries(){

      axios.get('/api/company/communication/enquiries?page='+this.currentPage+'&source='+this.emailsource, {headers : {
        'Authorization' : 'Bearer '+this.$attrs.user.api_token
      }}).then(response => {
        this.emailData = response.data[0];
        this.emailDataRaw = response.data[0];
        this.$emit('pagination-updated', [this.currentPage, this.emailData.total, this.emailData.per_page])
       /* this.paginatedEmailData = this.emailData.slice(
            this.startIndex,
            this.endIndex
          );*/
      }).catch(error => {

      });
    },
    markAsRead(id, isread){
      axios.post('/api/company/communication/enquiry/'+id+'/read', {read: isread}, {headers : {
        'Authorization' : 'Bearer '+this.$attrs.user.api_token
      }}).then(response => {
        
      }).catch(error => {

      });
    },
    onPageChange() {
      if(this.active_filter !== ''){
        this.startIndex = (this.currentPage - 1) * this.perPage;
        this.endIndex = (this.currentPage - 1) * this.perPage + this.perPage;

        this.paginatedEmailData = this.emailData.slice(
          this.startIndex,
          this.endIndex
        );
      }else{
        this.startIndex = (this.currentPage - 1) * this.perPage;
        this.endIndex = (this.currentPage - 1) * this.perPage + this.perPage;

        this.paginatedEmailData = this.emailDataRaw.slice(
          this.startIndex,
          this.endIndex
        );
      }
    },
  },
};
</script>

<template>
    <div class="row">
      <!-- Right Sidebar -->
      <b-col md="12">
                    <transition name="slide-success" mode="out-in">
                  <b-alert id="success-message" variant="success" :show="success_message"  dismissible @dismissed="success_message = !success_message">
                      <p class="font-size-14">{{success_messagetext}}</p>
                    </b-alert>
                  </transition>
                  </b-col>
                   <b-col md="12" class="d-flex mb-2">
                            <a class="my-auto btn btn-rounded" :class="{'btn-primary' : emailsource == ''}" @click="reloadEnquiries('')">
                               <i class="bx bx-mail-send"></i>  All Enquiries
                            </a>

                            <a class="my-auto btn btn-rounded" :class="{'btn-primary' : emailsource == 'public'}" @click="reloadEnquiries('public')">
                               <i class="bx bx-store"></i>  Public
                            </a>

                            <a class="my-auto btn btn-rounded" :class="{'btn-primary' : emailsource == 'trade'}" @click="reloadEnquiries('trade')">
                               <i class="bx bx-buildings"></i>  Trade
                            </a>

                            <a class="my-auto btn btn-rounded" :class="{'btn-primary' : emailsource == 'website'}" @click="reloadEnquiries('website')">
                               <i class="bx bx-globe"></i>  Website
                            </a>

                              <div class="ml-auto">
                                     <b-pagination
                            v-if="'data' in emailData"
                            v-model="currentPage"
                            :total-rows="emailData.total"
                            :per-page="emailData.per_page"
                            @input="getEnquiries()"
                          ></b-pagination>
                        </div>
                        </b-col>
      <div class="col-12">
        <Sidepanel @filter="filterNotifications" v-if="$attrs.showSidebar" @send-email="sendEmail" :unread="unread" />
        <div class="mb-3" :class="{'email-rightbar ' : $attrs.showSidebar == true}">
          <div class="card shadow-none border p-3">
            <div class="btn-toolbar p-3" v-if="showToolbar == true">
              <Toolbar />
            </div>
            <div :class="{'mt-3' : showToolbar == true, 'widget-scrollbox' : showToolbar == false}" v-if="rows !== 0">
              
              <b-list-group class="message-list" flush>
                <!--<th class="d-flex list-group-item">

                   <div class="col-mail col-mail-1 w-25 my-auto d-flex">
                    <div class="select-row-btn mx-4">
                      #
                    </div>
                    <div class="ml-2">
                      From
                    </div>
                    </div>
                     <div class="col-mail col-mail-2 w-75 my-auto d-flex">
                      <div class="w-75">
                      
                      </div>
                      <div class="w-25">
                      Date Received
                      </div>
                    </div>
                </th>-->
                <b-list-group-item
                  class="py-0"
                  
                  v-for="(email,index) in emailData.data"
                  :class="{ 'unread bg-light': email.read_at == null }"
                  :key="index"
                >
                  <a class="d-flex w-100">
                    
                    <div class="col-mail col-mail-1 w-25 py-2 d-flex">
                       <i v-if="email.read_at == null" class="bx bx-mail-send font-size-10 position-absolute text-white font-size-12 bg-danger p-1 rounded-circle top-0 left-0 mx-3 my-2"></i>
                          <b-form-checkbox class="select-row-btn mr-2" v-model="selectedids" :value="index" button-variant="light" @input="updateSelected" button>

                          <i v-if="filterSelected(index)" class="bx bx-check"></i><span class="avatar-title rounded-circle text-primary avatar-sm border border-white border-2"  :class="{'bg-peach text-dark' : email.data.source == 'website', 'bg-primary text-light' : email.data.source == 'public', 'bg-blue text-light' : email.data.source == 'trade'}" v-else>
                            <i class="font-size-20 bx bx-globe" v-if="email.data.source == 'website'"></i>
                             <i class="font-size-20 bx bx-store" v-if="email.data.source == 'public'"></i>
                              <i class="font-size-20 bx bx-buildings" v-if="email.data.source == 'trade'"></i>
                              <i class="font-size-20 bx bx-mail-send" v-else-if="(email.data.source == '') || (email.data.source == null)"></i>
                          </span></b-form-checkbox>
                    
                        

                      <!--<span :class="`star-toggle far fa-star text-${email.text}`"></span>-->
                      
                      <a href="javascript:;" @click="readEmail(email), email.read_at = true" class="title ml-2 my-auto text-muted my-auto"><h6 class="mb-0">{{email.data.name}}</h6> <p class="my-auto text-truncate paragraph-truncate-line2 text-muted mb-0">{{email.data.message}}</p></a>
                    </div>
                    <div class="col-mail col-mail-2 w-75 d-flex">

                      <a href="javascript:;" @click="readEmail(email), email.read_at = true" class="subject text-truncate w-75 mr-4 d-flex text-muted ml-auto">                             <span v-if="email.data.source !== ''" class="my-auto ml-auto badge font-size-11" :class="{'bg-peach text-dark' : email.data.source == 'website', 'bg-primary text-white' : email.data.source == 'public', 'bg-blue' : email.data.source == 'trade'}" v-b-tooltip.hover :title="email.data.source+' enquiry'" >{{email.data.source}}</span>
                      <span v-else-if="email.data.source == ''" class="my-auto ml-2 badge badge-light bg-light my-auto font-size-11">
                        No Source
                    </span>
                    <div class="date px-0 my-auto w-25 text-muted ml-2">{{getDate(email.created_at)}}</div>
                        <a class="btn btn-sm btn-light ml-2 my-auto mr-2">
                          Read
                          </a>
                      </a>
                      
                      <!--<li v-if="email.read_at == null" class="position-absolute right-0 top-0 bottom-0 unread-bullet m-4 btn-danger"></li>-->
                    </div>
                  </a>
                </b-list-group-item>
              </b-list-group>
            </div>
            <div v-else class="p-3">
              <!--<div class="bg-light justify-content-center align-items-center d-flex w-100 p-4 flex-column text-center border-radius-1em">
                <i class="mdi mdi-inbox-outline font-size-48 text-primary mt-3">
                </i>
                <h5 class="mb-3">No Enquiries to Show</h5>
              </div>-->
              <NothingHere :addNew="false"></NothingHere>
          </div>
          </div>
          <div v-if="rows !== 0" class="row justify-content-md-between align-items-md-center">
            <div class="col-xl-7">Showing {{startIndex}} - {{endIndex}} of {{rows}}</div>
            <!-- end col-->
            <div class="col-xl-5">
              <div class="text-md-end float-xl-end mt-2">
                <b-pagination
                  v-if="'data' in emailData"
                  v-model="currentPage"
                  :total-rows="emailData.total"
                  :per-page="emailData.per_page"
                  @input="getEnquiries()"
                ></b-pagination>
              </div>
            </div>
            <!-- end col-->
          </div>
        </div>
      </div>
        <SideSlidePanel :editPanel="emailViewerPanel" :panelClass="'edit-sidepanel'" @close-panel="emailViewerPanel = false">
          <b-overlay :show="loadEmailViewer">
          <EmailViewer :email="emailViewerData" @send-email="sendEmail"></EmailViewer>
        </b-overlay>
      </SideSlidePanel>
      <b-modal :id="'send-email'+emailsource" size="lg" centered hide-footer content-class="border-radius-1em">
          <Emailer :email="send_email" :user="$attrs.user"></Emailer>
        </b-modal>
    </div>

</template>
