<template>
	   
		<div class="" :class="{'widget-scrollbox widget-inner-scrollbox' : widgetbox == true}"  :style="[{'max-height' :  widgetbox == false ? 'auto' : sidePanelMaxHeight+'px', height: ( widgetbox == false ? 'auto' : ''+sidePanelMaxHeight+'px')}]">

				<b-card class="border shadow-none w-100" body-class="p-0"  v-if="('content' in $attrs) && ('configs' in $attrs)">
					
					<b-list-group flush class="" v-for="(configsval, sindex) in $attrs.configs">
						<b-list-group-item v-for="(value, key) in $attrs.configs[sindex]" :id="'settings-'+key" :key="key" :class="[{'bg-light label' : ($attrs.configs[sindex][key].type !== 'hidden') && ($attrs.configs[sindex][key].type == 'label')}, {'show' : (activeIndexRange[0] <= sindex) && (activeIndexRange[1] >= sindex)}, {'hide' : (activeIndexRange[0] > sindex) || (activeIndexRange[1] < sindex)}, {'px-0' : ('subfields' in $attrs.configs[sindex][key]) }, {'px-0 pt-0' : ( $attrs.configs[sindex][key].type == 'subtab') && ('subfields' in $attrs.configs[sindex][key]) }]" v-if="($attrs.configs[sindex][key].type !== 'hidden') && (checkDependency($attrs.configs[sindex][key], key))">

							
							<a href="javascript:;" v-if="($attrs.configs[sindex][key].type !== 'hidden') && (($attrs.configs[sindex][key].type == 'label'))" class="mb-0 label-link" @click="setActiveCollapse(sindex, key)" >
								<h5 class="mb-0 text-dark">
							<i class="bx bx-chevron-down text-primary" v-if="(activeIndexRange[0] <= sindex) && (activeIndexRange[1] >= sindex)"></i>
							<i class="bx bx-chevron-right text-primary" v-else></i> {{$attrs.configs[sindex][key].text}} </h5></a>
							<label v-else-if="($attrs.configs[sindex][key].type !== 'hidden') && ($attrs.configs[sindex][key].type !== 'subtab')" class="text-uppercase"  :class="{'px-3' : ('subfields' in $attrs.configs[sindex][key]) }">
							<span v-if="$attrs.configs[sindex][key].type == 'cols'">Column</span>
							{{key.replace(/_/g, ' ').replace('text_text_alignment', 'text_alignment')}}
							</label>
							<b-form-group class="text-capitalize" :class="[{'px-3' : ('subfields' in $attrs.configs[sindex][key]) }]" >
								
								<div class="d-flex" v-if="$attrs.configs[sindex][key].type == 'number'">
									<b-input-group>
									<b-form-input type="number"  v-model="$attrs.content[filterStylesKey($attrs.content, key)][key].value" :max="2400"></b-form-input>
										<!--<template #append>
											<b-form-radio-group v-model="$attrs.content[filterStylesKey($attrs.content, key)][key].units" buttons button-variant="outline-primary" >
		    								<b-form-radio class="font-size-12 text-uppercase" value="px">px</b-form-radio>
		    								<b-form-radio class="font-size-12 text-uppercase" value="%">%</b-form-radio>
		    								</b-form-radio-group>

		    								<b-dropdown text="Dropdown" variant="success">
										        <b-dropdown-item @click="$attrs.content[this.filterStylesKey($attrs.content, key)][key].units = 'px'">px</b-dropdown-item>
										        <b-dropdown-item @click="$attrs.content[this.filterStylesKey($attrs.content, key)][key].units = '%'">%</b-dropdown-item>
										      </b-dropdown>
    									</template>-->
    								</b-input-group>
    							</div>
    							<div class="d-flex w-100" v-else-if="$attrs.configs[sindex][key].type == 'cols'">
    								<b-form-group class="w-100" >

    								<label class="d-flex w-100">
    									<span class="my-auto">X-Large Screens</span>
    									<i class="mdi mdi-television  ml-auto font-size-18 text-primary"></i>
    								</label>
    								<input type="range" v-model="$attrs.configs[sindex][key].xl" class="mt-1 py-4 w-100" style="min-height: unset;" :min="1" :max="12" :data="colrange" :range="collabels" />
    								<label class="d-flex w-100">
    									<span class="my-auto">Large Screens</span>
    									<i class="mdi mdi-monitor  ml-auto font-size-18 text-primary"></i>
    								</label>
    								<input type="range" v-model="$attrs.configs[sindex][key].lg" class="mt-1 py-4 w-100" style="min-height: unset;" :min="1" :max="12" :data="colrange" :range="collabels" />
    								<label class="d-flex w-100">
    									<span class="my-auto">Medium Screens</span>
    									<i class="bx bx-laptop  ml-auto font-size-18 text-primary"></i>
    								</label>
    								<input type="range" v-model="$attrs.configs[sindex][key].md" class="mt-1 py-4 w-100" style="min-height: unset;" :min="1" :max="12" :data="colrange" :range="collabels" />
    								<label class="d-flex w-100">
    									<span class="my-auto">Small Screens</span>
    									<i class="mdi mdi-tablet-android  ml-auto font-size-18 text-primary"></i>
    								</label>
    								<input type="range" v-model="$attrs.configs[sindex][key].sm" class="mt-1 py-4 w-100" style="min-height: unset;" :min="1" :max="12" :data="colrange" :range="collabels" />
    								<label class="d-flex w-100">
    									X-Small Screens <i class="bx bx-mobile  ml-auto font-size-18 text-primary"></i>
    								</label>
    								<input type="range" v-model="$attrs.configs[sindex][key].xs" class="mt-1 py-4 w-100" style="min-height: unset;" :min="1" :max="12" :data="colrange" :range="collabels" />
    								
    							</b-form-group>
    						</div>
    						 <div class="d-flex flex-column" v-else-if="$attrs.configs[sindex][key].type == 'range'">
       								
    								<input type="range" v-if="$attrs.content[filterStylesKey($attrs.content, key)][key].units == '%'" class="w-100 pt-3 pb-3" v-model="$attrs.content[filterStylesKey($attrs.content, key)][key].value" :max="500" @input="$attrs.configs[sindex][key].active = true" />
    								<input type="range" v-else class="w-100 pt-3 pb-3" v-model="$attrs.content[filterStylesKey($attrs.content, key)][key].value" :min="$attrs.configs[sindex][key].min" :max="$attrs.configs[sindex][key].max"  @input="$attrs.configs[sindex][key].active = true" />
									<b-input-group>
									<b-form-input type="number"  v-model="$attrs.content[filterStylesKey($attrs.content, key)][key].value" :min="$attrs.configs[sindex][key].min" :max="$attrs.configs[sindex][key].max" @input="$attrs.configs[sindex][key].active = true"></b-form-input>
										<template #append v-if="'unit_options' in $attrs.configs[sindex][key]">
											<b-form-radio-group v-model="$attrs.content[filterStylesKey($attrs.content, key)][key].units" buttons button-variant="outline-primary" v-b-tooltip.hover title="Units" >
		    								
											
		    								<b-form-radio v-for="unit in $attrs.configs[sindex][key].unit_options" class="font-size-12 text-uppercase" :value="unit">{{unit}}</b-form-radio>
		    								</b-form-radio-group>
		    								<b-button variant="primary" v-b-tooltip.hover title="Reset" @click="$attrs.content[this.filterStylesKey($attrs.content, key)][key].value = ''"><i class="bx bx-reset"></i></b-button>
		    								<!--<b-dropdown text="Dropdown" variant="success">
										        <b-dropdown-item @click="$attrs.content[this.filterStylesKey($attrs.content, key)][key].units = 'px'">px</b-dropdown-item>
										        <b-dropdown-item @click="$attrs.content[this.filterStylesKey($attrs.content, key)][key].units = '%'">%</b-dropdown-item>
										      </b-dropdown>-->
    									</template>

    								</b-input-group>
    							</div>
								
    						<div class="d-flex" v-else-if="$attrs.configs[sindex][key].type == 'numbergroup'">
    							<div class="d-flex" v-if="key.includes('radius') == false">
    								<b-form-group label="" description="LEFT" class="mr-1" >
									<b-form-input @input="$attrs.configs[sindex][key].active = true" type="number"  v-model="$attrs.content[filterStylesKey($attrs.content, key)][key].left"></b-form-input>
									</b-form-group>
									<b-form-group description="RIGHT" class="mx-1">
									<b-form-input @input="$attrs.configs[sindex][key].active = true" type="number"  v-model="$attrs.content[filterStylesKey($attrs.content, key)][key].right"></b-form-input>
									</b-form-group>
									<b-form-group description="TOP" class="mx-1">
									<b-form-input @input="$attrs.configs[sindex][key].active = true" type="number"  v-model="$attrs.content[filterStylesKey($attrs.content, key)][key].top"></b-form-input>
									</b-form-group>
									<b-form-group description="BOTTOM" class="ml-1">
									<b-form-input @input="$attrs.configs[sindex][key].active = true" type="number"  v-model="$attrs.content[filterStylesKey($attrs.content, key)][key].bottom"></b-form-input>
									</b-form-group>
								</div>
								<div class="d-flex flex-column" v-else-if="key.includes('radius') == true">
									<div class="d-flex">
    								<b-form-group label="" description="TOP LEFT" class="mr-1" >
									<b-form-input @input="$attrs.configs[sindex][key].active = true" type="number"  v-model="$attrs.content[filterStylesKey($attrs.content, key)][key].top_left" :min="0"></b-form-input>
									</b-form-group>
									<b-form-group description="TOP RIGHT" class="mx-1">
									<b-form-input @input="$attrs.configs[sindex][key].active = true" type="number"  v-model="$attrs.content[filterStylesKey($attrs.content, key)][key].top_right" :min="0"></b-form-input>
									</b-form-group>
									<b-form-group description="BOTTOM LEFT" class="mx-1">
									<b-form-input @input="$attrs.configs[sindex][key].active = true" type="number"  v-model="$attrs.content[filterStylesKey($attrs.content, key)][key].bottom_left" :min="0"></b-form-input>
									</b-form-group>
									<b-form-group description="BOTTOM RIGHT" class="ml-1">
									<b-form-input @input="$attrs.configs[sindex][key].active = true" type="number"  v-model="$attrs.content[filterStylesKey($attrs.content, key)][key].bottom_right" :min="0"></b-form-input>
									</b-form-group>
									</div>
									<div class="d-flex">
									<b-form-radio-group v-if="'units' in $attrs.content[filterStylesKey($attrs.content, key)][key]" v-model="$attrs.content[filterStylesKey($attrs.content, key)][key].units" buttons button-variant="outline-primary" v-b-tooltip.hover title="Units" :options="['px', '%']">
		    								</b-form-radio-group>
		    							</div>
								</div>

								</div>
								<div class="d-flex flex-column" v-else-if="$attrs.configs[sindex][key].type == 'iconselect'">
									<div class="card shadow-none border-radius-1em border p-3">
										<span class="mr-auto my-auto">
											Selected Icon
										</span>
										<i :class="'font-size-36 my-auto ml-auto text-primary border rounded-5 p-1 ' +$attrs.content[filterStylesKey($attrs.content, key)][key].value"></i>
									</div>
									<div class="d-flex">
										<b-tabs pills class="w-100" fill>
											<b-tab v-for="(iconoption, iconkey) in iconOptions" :title="iconkey.replaceAll('_', ' ')" title-link-class="text-capitalize" content-class="pt-2">
												 <simplebar style="width: 100%; min-height: 200px; max-height: 200px; overflow-x: hidden;" :style="{'max-height' : (sidePanelMaxHeight ) +'px'}">	
												<a v-for="icon in iconOptions[iconkey]" @click="$attrs.content[filterStylesKey($attrs.content, key)][key].value = icon"
												class="btn m-2" :class="$attrs.content[filterStylesKey($attrs.content, key)][key].value == icon ? 'btn-primary' : 'btn-light'">
													<i :class="['font-size-20 ' + icon, $attrs.content[filterStylesKey($attrs.content, key)][key].value == icon ? 'text-white' : 'text-primary']"></i>
												</a>
												</simplebar>
											</b-tab>
										</b-tabs>
									</div>

    							</div>
								<div class="d-flex" v-else-if="$attrs.configs[sindex][key].type == 'select'">
									<b-form-group class="w-100">
    									<b-form-select v-model="$attrs.content[filterStylesKey($attrs.content, key)][key].value"  class="form-control text-capitalize" :options="$attrs.configs[sindex][key].options" @input="$attrs.configs[sindex][key].active = true">
    									</b-form-select>
    								</b-form-group>

    							</div>
    							<div class="d-flex" v-else-if="$attrs.configs[sindex][key].type == 'color'">
    							<b-form-group id="color"
					                   class="w-100"
					                    >
					                      <div class="bg-light mt-2 mb-3 d-flex flex-column justify-content-center p-4" style="min-height: 150px">
					                     <label>Select Color:</label>
                    					<b-form-input id="color" type="color" name="color" v-model="$attrs.content[filterStylesKey($attrs.content, key)][key].value" @input="$attrs.configs[sindex][key].active = true"></b-form-input>
                    					<label>Default Swatches:</label>
                    					<b-form-checkbox-group id="default-colors" class="d-inline" buttons v-model="$attrs.content[filterStylesKey($attrs.content, key)][key].value"  button-variant="white" @input="recentlyUsedSwatch($event), $attrs.configs[sindex][key].active = true">
                    						<b-form-checkbox :value="'rgba(0,0,0,0)'" :style="{'background': 'rgba(0,0,0,0)'}" style="; width: 25px; height: 25px" class="border border-dark" >
                    							
                    						</b-form-checkbox>
                    						<b-form-checkbox v-for="color in colors.default" :value="color" :style="{'background': color}" style="; width: 25px; height: 25px" class="border border-dark" >
                    							
                    						</b-form-checkbox>
                    					</b-form-checkbox-group>
                    					<label>Recently Used:</label>
                    					<b-form-checkbox-group id="recent-colors" class="d-inline" buttons v-model="$attrs.content[filterStylesKey($attrs.content, key)][key].value"  button-variant="white" @input="$attrs.configs[sindex][key].active = true"  >
                    						<template #first>
                    							<b-button variant="white" v-if="colors.recent.length > 11" style="; width: 25px; height: 25px" class="border border-dark" @click="recent_colors_end = 11, $attrs.configs[sindex][key].active = true">
                    							<i class="mdi mdi-minus"></i>
                    							</b-button>
                    						</template>
                    						<b-form-checkbox v-for="color in colors.recent.slice(0, recent_colors_end)" :value="color" :style="{'background': color}" style="; width: 25px; height: 25px" class="border border-dark" @input="$attrs.configs[sindex][key].active = true" >
                    							
                    						</b-form-checkbox>
                    					
                    							<b-button variant="white" v-if="colors.recent.length > 11" style="; width: 25px; height: 25px" class="border border-dark" @click="recent_colors_end = (colors.recent.length - 1)">
                    							<i class="mdi mdi-plus"></i>
                    							</b-button>
                    						
                    					</b-form-checkbox-group>
                    					<label>Saved Swatches:</label>
                    					<b-form-checkbox-group id="saved-colors" class="d-inline" buttons v-model="$attrs.content[filterStylesKey($attrs.content, key)][key].value"  button-variant="white" >
                    						<b-form-checkbox style="; width: 25px; height: 25px" class="border border-dark">
                    							<i class="mdi mdi-plus"></i>
                    						</b-form-checkbox>
                    						<b-form-checkbox v-for="color in colors.saved" :value="color" :style="{'background': color}" style="; width: 25px; height: 25px" class="border border-dark" @input="$attrs.configs[sindex][key].active = true" >
                    							
                    						</b-form-checkbox>
                    					</b-form-checkbox-group>
                    				</div>
    								</b-form-group>
    							</div>
    							<div class="" v-else-if="$attrs.configs[sindex][key].type == 'radiogroup'" >
    								<b-form-radio-group button-variant="outline-primary" v-if="$attrs.configs[sindex][key].isButton == true" buttons :stacked="'isStacked' in $attrs.configs[sindex][key]" v-model="$attrs.content[filterStylesKey($attrs.content, key)][key].value" :options="$attrs.configs[sindex][key].options" @input="$attrs.configs[sindex][key].active = true" :class="{'w-100' : 'isStacked' in $attrs.configs[sindex][key]}" >
    								</b-form-radio-group>
    								<b-form-radio-group button-variant="outline-primary" v-if="$attrs.configs[sindex][key].isButton == false" v-model="$attrs.content[filterStylesKey($attrs.content, key)][key].value" :stacked="'isStacked' in $attrs.configs[sindex][key]" :options="$attrs.configs[sindex][key].options" @input="$attrs.configs[sindex][key].active = true"  :class="{'w-100' : 'isStacked' in $attrs.configs[sindex][key]}" >
    								</b-form-radio-group>
    							</div>
    							<div class="" v-else-if="$attrs.configs[sindex][key].type == 'image'">
    								<b-form-group >
    									  <div class="bg-light mt-2 d-flex position-relative" style="min-height: 150px"  @mouseenter="hoverElement = key" @mouseleave="hoverElement = ''">
    									  <a v-if="$attrs.content[filterStylesKey($attrs.content, key)][key].value == ''" class="btn btn-primary w-75 m-auto" href="javascript:;"  @click="fileResourceKey = key, fileResourceIndex = filterStylesKey($attrs.content, key), showFileManager = !showFileManager, $emit('storage-toggled', showFileManager)">Select File</a>
                         				  <img v-else :src="$attrs.content[filterStylesKey($attrs.content, key)][key].value" class="w-75 m-auto">
                         				  <div v-if="(hoverElement == key) && ($attrs.content[filterStylesKey($attrs.content, key)][key].value !== '')" class=" bg-secondary bg-soft mx-auto w-75 position-absolute top-0 right-0 left-0 bottom-0 d-flex flex-column justify-content-center align-items-center">
                         				  	<a class="btn btn-primary w-75 m-auto mb-1" href="javascript:;"  @click="fileResourceKey = key, fileResourceIndex = filterStylesKey($attrs.content, key), showFileManager = !showFileManager, $emit('storage-toggled', showFileManager)">Select File</a>
                         				  	<a class="btn btn-danger w-75 m-auto mt-1" href="javascript:;"  @click="$attrs.content[filterStylesKey($attrs.content, key)][key].value = '', hoverElement = ''">Remove File</a>

                         				  </div>
                         				</div>
    								</b-form-group>
    								
    							</div>
    							<div class="" v-else-if="$attrs.configs[sindex][key].type == 'file'">
    								<b-form-group >
    									  <div class="bg-light mt-2 d-flex position-relative" style="min-height: 150px"  @mouseenter="hoverElement = key" @mouseleave="hoverElement = ''">
    									  <a v-if="$attrs.content[filterStylesKey($attrs.content, key)][key].value == ''" class="btn btn-primary w-75 m-auto" href="javascript:;"  @click="fileResourceKey = key, fileResourceIndex = filterStylesKey($attrs.content, key), showFileManager = !showFileManager, $emit('storage-toggled', showFileManager)">Select File</a>
                         				  <img v-else :src="'/image/product-placeholder.png'" class="w-75 m-auto">
                         				  <div v-if="(hoverElement == key) && ($attrs.content[filterStylesKey($attrs.content, key)][key].value !== '')" class=" bg-secondary bg-soft mx-auto w-75 position-absolute top-0 right-0 left-0 bottom-0 d-flex flex-column justify-content-center align-items-center">
                         				  	<a class="btn btn-primary w-75 m-auto mb-1" href="javascript:;"  @click="fileResourceKey = key, fileResourceIndex = filterStylesKey($attrs.content, key), showFileManager = !showFileManager, $emit('storage-toggled', showFileManager)">Select File</a>
                         				  	<a class="btn btn-danger w-75 m-auto mt-1" href="javascript:;"  @click="$attrs.content[filterStylesKey($attrs.content, key)][key].value = '', hoverElement = ''">Remove File</a>

                         				  </div>
                         				</div>
    								</b-form-group>
    								
    							</div>
    							<div class="" v-else-if="$attrs.configs[sindex][key].type == 'textarea'" >
    								<textarea class="w-100" rows="5" v-model="$attrs.content[filterStylesKey($attrs.content, key)][key].value"></textarea>
    							</div>
    							<div class="" v-else-if="$attrs.configs[sindex][key].type == 'fontselect'" >
    								<FontsMultiselect :id="$attrs.content[filterStylesKey($attrs.content, key)][key].value"  @font-selected="selectFont($event), $attrs.content[filterStylesKey($attrs.content, key)][key].value = $event, $attrs.configs['font_style']['options'] = $event.variants"></FontsMultiselect>
    							</div>
    							<div class="" v-else-if="$attrs.configs[sindex][key].type == 'background'">
    								<b-form-radio-group button-variant="outline-primary" buttons v-model="$attrs.content[filterStylesKey($attrs.content, key)][key].value" :options="$attrs.configs[sindex][key].options" >
    								</b-form-radio-group>

    								<b-form-group v-if="$attrs.content[filterStylesKey($attrs.content, key)][key].value[0] == 'background-image'">
    									  <div class="bg-light mt-2 d-flex position-relative" style="min-height: 150px"  @mouseenter="hoverElement = $attrs.content[filterStylesKey($attrs.content, key)][key].value[0]" @mouseleave="hoverElement = ''">
    									  <a v-if="$attrs.content[filterStylesKey($attrs.content, key)][key].value[1] == ''" class="btn btn-primary w-75 m-auto" href="javascript:;"  @click="fileResourceKey = key, fileResourceIndex = filterStylesKey($attrs.content, key), showFileManager = !showFileManager, $emit('storage-toggled', showFileManager)">Select File</a>
                         				  <img v-else :src="$attrs.content[filterStylesKey($attrs.content, key)][key].value[1]" class="w-75 m-auto">
                         				  <div v-if="(hoverElement == 'background-image') && ($attrs.content[filterStylesKey($attrs.content, key)][key].value[1] !== '')" class=" bg-secondary bg-soft mx-auto w-75 position-absolute top-0 right-0 left-0 bottom-0 d-flex flex-column justify-content-center align-items-center">
                         				  	<a class="btn btn-primary w-75 m-auto mb-1" href="javascript:;"  @click="fileResourceKey = key, fileResourceIndex = filterStylesKey($attrs.content, key), showFileManager = !showFileManager, $emit('storage-toggled', showFileManager)">Select File</a>
                         				  	<a class="btn btn-danger w-75 m-auto mt-1" href="javascript:;"  @click="$attrs.content[filterStylesKey($attrs.content, key)][key].value[1] = '', hoverElement = ''">Remove File</a>

                         				  </div>
                         				</div>
    								</b-form-group>
    								 <div v-if="$attrs.content[filterStylesKey($attrs.content, key)][key].value[0] == 'background-image'" class="bg-light mb-3 d-flex flex-column px-4 pb-4" style="min-height: 150px">
    								<b-form-group class="w-100 mb-2" v-if="$attrs.content[filterStylesKey($attrs.content, key)][key].value[0] == 'background-image'" label="Size">
    									<b-form-select class="form-control text-capitalize" v-model="$attrs.configs[sindex][key].size" :options="$attrs.configs[sindex][key].image_size_options">
    									</b-form-select>
    								</b-form-group>

    								<b-form-group class="w-100 mb-2" v-if="$attrs.content[filterStylesKey($attrs.content, key)][key].value[0] == 'background-image'" label="Position">
    									<b-form-select class="form-control text-capitalize" v-model="$attrs.configs[sindex][key].position" :options="$attrs.configs[sindex][key].image_position_options">
    									</b-form-select>
    								</b-form-group>

    								<b-form-group class="w-100 mb-2" v-if="$attrs.content[filterStylesKey($attrs.content, key)][key].value[0] == 'background-image'" label="Repeat">
    									<b-form-select class="form-control text-capitalize" v-model="$attrs.configs[sindex][key].repeat" :options="$attrs.configs[sindex][key].image_repeat_options">
    									</b-form-select>
    								</b-form-group>
    								<b-form-group class="w-100" v-if="($attrs.content[filterStylesKey($attrs.content, key)][key].value[0] == 'background-image') && ($attrs.configs[sindex][key].size == 'custom')" label="Custom Size">
    								<input type="range" v-model="$attrs.configs[sindex][key].custom_size" class="w-100 mt-1 pt-4" style="min-height: unset;" :min="0" :max="500" />
    								</b-form-group>

    								</div>
    								<b-form-group v-if="$attrs.content[filterStylesKey($attrs.content, key)][key].value[0] == 'background-color'" id="color"
					                   
					                    >
					                      <div class="bg-light mt-2 mb-3 d-flex flex-column justify-content-center p-4" style="min-height: 150px">
					                     <label>Select Color:</label>
                    					<b-form-input id="color" type="color" name="color" v-model="$attrs.content[filterStylesKey($attrs.content, key)][key].value[1]"></b-form-input>
                    					<label>Default Swatches:</label>
                    					<b-form-checkbox-group id="default-colors" class="d-inline" buttons v-model="$attrs.content[filterStylesKey($attrs.content, key)][key].value[1]"  button-variant="white" @input="recentlyUsedSwatch($event)">
                    						<b-form-checkbox v-for="color in colors.default" :value="color" :style="{'background': color}" style="; width: 25px; height: 25px" class="border border-dark" >
                    							
                    						</b-form-checkbox>
                    					</b-form-checkbox-group>
                    					<label>Recently Used:</label>
                    					<b-form-checkbox-group id="recent-colors" class="d-inline" buttons v-model="$attrs.content[filterStylesKey($attrs.content, key)][key].value[1]"  button-variant="white" >
                    						<b-form-checkbox v-for="color in colors.recent.slice(0, recent_colors_end)" :value="color" :style="{'background': color}" style="; width: 25px; height: 25px" class="border border-dark">
                    							
                    						</b-form-checkbox>
                    					
                    							<b-button variant="white" v-if="colors.recent.length > 11" style="; width: 25px; height: 25px" class="border border-dark" @click="recent_colors_end = (colors.recent.length - 1)">
                    							<i class="mdi mdi-plus"></i>
                    							</b-button>
                    						
                    					</b-form-checkbox-group>
                    					<label>Saved Swatches:</label>
                    					<b-form-checkbox-group id="saved-colors" class="d-inline" buttons v-model="$attrs.content[filterStylesKey($attrs.content, key)][key].value[1]"  button-variant="white" >
                    						<b-form-checkbox style="; width: 25px; height: 25px" class="border border-dark">
                    							<i class="mdi mdi-plus"></i>
                    						</b-form-checkbox>
                    						<b-form-checkbox v-for="color in colors.saved" :value="color" :style="{'background': color}" style="; width: 25px; height: 25px" class="border border-dark">
                    							
                    						</b-form-checkbox>
                    					</b-form-checkbox-group>
                    				</div>
    								</b-form-group>
    							</div>
    							
								<b-form-input v-else-if="($attrs.configs[sindex][key].type !== 'hidden') && ($attrs.configs[sindex][key].type !== 'label') && ($attrs.configs[sindex][key].type !== 'subtab')" class="w-100 mt-2"  :type="$attrs.configs[sindex][key].type" v-model="$attrs.content[filterStylesKey($attrs.content, key)][key].value"></b-form-input>
								<div v-else></div>
								</b-form-group>
								<div class="" v-if="'subfields' in $attrs.configs[sindex][key]">
    								<widgetConfigsTab ref="subfields" :user="$attrs.user" :company="$attrs.company" :widgetbox="false" :configs="$attrs.configs[sindex][key].subfields" :content="$attrs.content[filterStylesKey($attrs.content, key)][key].subfields" />
    							</div>
								
    							<div class="pt-3" v-if="'subtabs' in $attrs.configs[sindex][key]">
    								<b-tabs pills justified content-class="pt-3">
    									<b-tab v-for="(tabvalue, tabkey) in $attrs.configs[sindex][key].subtabs">
    										 <template v-slot:title>
									                  <span class="d-inline-block d-sm-none">
									                    <i class="bx bx-notepad"></i>
									                  </span>
									                  <span class="d-none d-sm-inline-block">{{Object.entries($attrs.configs[sindex][key].subtabs[tabkey])[0][0]}}</span>
									                </template>
    										<widgetConfigsTab ref="subtabs" :user="$attrs.user" :company="$attrs.company" :widgetbox="false" :configs="$attrs.configs[sindex][key].subtabs[tabkey][Object.entries($attrs.configs[sindex][key].subtabs[tabkey])[0][0]]" :content="$attrs.content[filterStylesKey($attrs.content, key)][key].subtabs[tabkey][Object.entries($attrs.configs[sindex][key].subtabs[tabkey])[0][0]]" />
    									</b-tab>
    								</b-tabs>
    							</div>

						</b-list-group-item>
					</b-list-group>
				
				</b-card>
				<div v-if="widgetbox == true" class="spacer" style="height: 90px; display: block;"></div>
				<ModalWindow :editModal="showFileManager" :panelClass="'product-modal d-flex flex-column justify-content-center'" @close-modal="showFileManager = !showFileManager, $emit('storage-toggled', showFileManager)" :formReponse="''">
			        <StorageWindow :user="$attrs.user" :company="$attrs.company" @close-window="showFileManager = false, $emit('storage-closed')" @file-selected="fileSelected" :selectedFiles="selectedFiles" ></StorageWindow>
			      </ModalWindow>
			</div>
		
</template>

<script type="text/javascript">
	import FontsMultiselect from "../../../../components/widgets/page-builder/widgets/FontsMultiselect"
	import { icons } from "../../../../components/widgets/page-builder/page-icons";
	import widgetConfigsTab from "./widgetConfigsTab";
	import VueSlideBar from 'vue-slide-bar'
	import ModalWindow from "../../../../components/widgets/ModalWindow";
	import StorageWindow from "../../../../components/widgets/file-manager/StorageWindow";
	import simplebar from "simplebar-vue";
	import { EventBus } from "../../../../app"
	export default{
		name: 'widgetConfigsTab',
		components: { VueSlideBar, ModalWindow, StorageWindow, widgetConfigsTab, FontsMultiselect, simplebar },
		data() {
			return {
				activeIndexRange: [],
				labelIndexData: [],
				labelKeys: [],
				iconOptions: icons,
				activeFonts: [],
				hoverElement: '',
				storageurl: 'https://'+this.$attrs.company.nickname+'.s3.eu-west-2.amazonaws.com/',
				variants: [
					{element: 'column', variant: 'info'},
					{element: 'section', variant: 'success'},
					{element: 'row', variant: 'primary'},
				],
				fileResourceKey: '',
				fileResourceIndex: '',
				recent_colors_end: 11,
				colors: {
					default: ['#4b2f8d', '#c35555', '#34c38f', '#f1b44c', '#ffffff', '#000000'],
					recent: [],
					saved: [],
				},
				sidePanelMaxHeight: 420,
				showFileManager: false,
				selectedFiles: [],
				colrange: [1,2,3,4,5,6,7,8,9,10,11,12],
				collabels: [
		          {
		            label: "1"
		          },
		          {
		            label: "2"
		          },
		          {
		            label: "3"
		          },
		          {
		            label: "4"
		          },
		          {
		            label: "5"
		          },
		          {
		            label: "6"
		          },
		          {
		            label: "7"
		          },
		          {
		            label: "8"
		          },
		          {
		            label: "9"
		          },
		          {
		            label: "10"
		          },
		          {
		            label: "11"
		          },
		          {
		            label: "12"
		          },
		        ]
			}
		},
		props: {
			subFields: {
				types: Boolean,
				default: false,
			},
			widgetbox: {
				type: Boolean,
				default: true,
			}
		},
		computed:{
			activeVariant(){
				var active = this.variants.filter(item => item.element = this.$attrs.element);
				return active[0].variant;
			},
			
		},
		created(){
			window.addEventListener("resize", this.windowResize);
		},
		destroyed() {
            window.removeEventListener("resize", this.windowResize);
        },
        mounted(){
        	this.sidePanelMaxHeight = window.innerHeight - 80;
        	this.getLabelKeys();
        	this.labelIndexes();
        },
		methods: {
			setActiveCollapse(sindex, key){
				this.sidePanelMaxHeight = this.sidePanelMaxHeight + 1;
				this.activeIndexRange[0] = this.filterStylesKey(this.$attrs.configs, key);
				var nextLabelIndex = this.labelIndexData.findIndex(item => item == this.activeIndexRange[0])
				if((nextLabelIndex > -1) && (nextLabelIndex < (this.labelIndexData.length - 1))){
					var endIndex = nextLabelIndex + 1;
					this.activeIndexRange[1] = this.labelIndexData[endIndex];
				}else{
					var endIndex = this.$attrs.configs.length - 1;
					this.activeIndexRange[1] = endIndex;
				}
				this.sidePanelMaxHeight = this.sidePanelMaxHeight - 1;
				
			},
			labelIndexes() {
		      this.labelIndexData = this.$attrs.configs
		        .map((item, index) => {
		          const key = Object.keys(item)[0];
		          const obj = item[key];
		          return obj && obj.type === 'label' ? index : -1; // Only return the index if it's a label
		        })
		        .filter(index => index !== -1); // Remove invalid indexes
		        this.activeIndexRange.push(0);
		        this.activeIndexRange.push(this.labelIndexData[1]);
		    },
		    getLabelKeys() {
		      this.labelKeys = this.$attrs.configs
		        .map(item => {
		          const key = Object.keys(item)[0];
		          const obj = item[key];
		          return obj && obj.type === 'label' ? key : null; // Return key if type is 'label'
		        })
		        .filter(key => key !== null); // Filter out null values
		    },
			windowResize(e) {
            console.log(window.innerHeight);
    
            this.sidePanelMaxHeight = window.innerHeight - 80;
   
           
          	},
			filterStylesKey(styles, key){
       		for(var i = 0; i < styles.length; i++){
	       			 var keys = Object.keys(styles[i])
	       			 if(keys[0] == key){
	       			 	 return i;
	       			 }
	       		}
	       },
			createFontLoader(){
	   			var h = document.getElementsByTagName('head').item(0);
				var f = document.createElement("style");
				f.type = "text/css"; 
				f.id = "loadFonts";
				h.appendChild(f);
	   		},
			selectFont(payload){
				var index = this.activeFonts.findIndex(item => item == payload);
	   			if(index == -1){
	   				var h = document.getElementsByTagName('head').item(0);
		   			var l = document.createElement("link");
		   			var firstchild = h.childNodes[0];
					l.rel = "stylesheet"; 
					l.id = "loadedFonts-"+this.activeFonts.length;
					h.insertBefore(l, firstchild);
	   				document.getElementById('loadedFonts-'+this.activeFonts.length).href = 'https://fonts.googleapis.com/css2?family='+payload.family.replaceAll(' ','+')+'&display=swap';
	   				this.activeFonts.push(payload);
	   				EventBus.$emit('add-font', payload);
	   			}

   			},
   			removeFont(payload){
   				var index = this.activeFonts.findIndex(item => item == payload);
   				var element = document.getElementById('loadedFonts-'+index);
   				element?.remove();
   				this.activeFonts[index].splice(index, 1);
   				EventBus.$emit('remove-font', payload);
   			},
			checkDependency(configs, key){
				var keys = Object.keys(configs);
				if(keys.includes('dependentOn')){
					console.log(configs);
					if(this.$attrs.content[this.filterStylesKey(this.$attrs.content, configs.dependentOn.field)][configs.dependentOn.field].value == configs.dependentOn.value){
						return true;
					}else{
						if(key == 'view'){
							this.$attrs.content[this.filterStylesKey(this.$attrs.content, key)].value = '';
						}
						return false;
					}
				}else{
					return true;
				}
			},
			fileSelected(payload){
				if(this.fileResourceKey == 'background'){
					this.$attrs.content[this.fileResourceIndex][this.fileResourceKey].value[1] = ''+this.storageurl+''+payload.file+'';
				}else{
					this.$attrs.content[this.fileResourceIndex][this.fileResourceKey].value = ''+this.storageurl+''+payload.file+'';
				}
				this.$emit('storage-closed');
				this.showFileManager = !this.showFileManager;
				
			},
			recentlyUsedSwatch(color){
				var index = this.colors.recent.findIndex(item => item == color);
				if(index == -1){
					this.colors.recent.unshift(color);
					localStorage.setItem('recentColors', JSON.stringify(this.colors.recent));
				}else{
					this.colors.recent.splice(index, 1);
					this.colors.recent.unshift(color);
					localStorage.setItem('recentColors', JSON.stringify(this.colors.recent));
				}
			},

   
		}
	}

</script>

<style scoped>
	
	.list-group-item.show{
		display: block;
	}
	.list-group-item.label.hide{
		display: block;
	}
	.list-group-item.hide{
		display: none;
	}
</style>