<template>
<Layout>
  <PageHeader :title="title" :items="items" />
  <div class="container-fluid">
    <nav class="nav nav-pills nav-fill">
      <a v-for="(tab, index) in shipping_tabs" class="nav-item nav-link" href="javascript:;" :class="{active: active === true && current === index}" v-on:click="toggleActive(index)" v-on:mouseover="currentlyShowing(index)">{{tab}}</a>
    </nav>
    <div class="col-md-12 form-container">
      <b-form>
      <div class="row bg-white">
      <transition name="slide-fadestepform">
        <div class="card card-profile col-md-12 no-margin" v-if="current == 0">
          <div class="row">
            <div class="col-md-12 margin-auto pb-4">
            <h3 class="mt-4">Shipping Information</h3>
            <hr>
    
      <b-form-group
        label="Name"
        label-for="input-1"
      >
        <b-form-input
          v-model="shipping.name"
          type="email"
          required
          placeholder="Enter shipping name"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        label="Transit Time"
        label-for="input-1"
        description="E.g - 3 - 5 working days"
      >
        <b-form-input
          v-model="shipping.name"
          type="email"
          required
          placeholder="Enter transit time"
        ></b-form-input>
      </b-form-group>

      <b-form-group  label="Description" label-for="input-2">
        <b-form-textarea
          id="textarea"
          v-model="shipping.description"
          placeholder="Enter something..."
          rows="3"
          max-rows="6"
        ></b-form-textarea>
      </b-form-group>

      <b-form-group  label="Icon" label-for="input-2">
        <b-form-file
          v-model="shipping.icon"
          required
          placeholder="Add shipping icon"
        ></b-form-file>
      </b-form-group>
        </div>
      </div>
    </div>
    </transition>
      <transition name="slide-fadestepform">
      <div class="card card-profile col-md-12 no-margin" v-if="current == 1">
          <div class="row">
            <div class="col-md-12 margin-auto pb-4">
            <h3 class="mt-4">Cost Information</h3>
            <hr>
      <b-form-group  label="Free Shipping" label-for="radio-group-2">
        <b-form-radio-group id="radio-group-2" v-model="shipping.free" name="radio-free-shipping">
           <b-form-radio value="1">Yes</b-form-radio>
           <b-form-radio value="0">No</b-form-radio>
        </b-form-radio-group>
      </b-form-group>

      <b-form-group  label="Shipping Type" label-for="radio-group-3" v-if="shipping.free == 0">
        <b-form-radio-group id="radio-group-3" v-model="shipping.type" name="radio-shipping-type">
          <b-form-radio value="0">Shipping by Weight</b-form-radio>
          <b-form-radio value="1">Shipping by Order Total</b-form-radio>
        </b-form-radio-group>
      </b-form-group>
      <!--<div class="row" v-if="shipping.free == 0">
         <div class="col-md-2 pr-0">
            <label>Zone</label>
            <div class="card d-flex flex-column shipping-range-inputs bg-light h-100">
            </div>
         </div>
          <div class="col-md-1 p-4" v-for="(range, index) in range_values">
            <label>Range</label>
        
        </div>
      </div>-->
      <div class="row" v-if="shipping.free == 0">
        <div class="col-md-2 pr-0">
          <div class="card d-flex flex-column shipping-range-inputs bg-light">
          <b-form-input
              type="number"
              required
              class="invisible"
              placeholder="Enter transit time"
            ></b-form-input>
            <b-form-input
              type="number"
              required
              class="invisible"
              placeholder="Enter transit time"
            ></b-form-input>
          </div>
          <b-card v-for="zone in all_zones.data">
            <b-form-checkbox v-model="selected_zones" :value="zone.id" @click="activateZone(zone)">
               {{zone.name}}
            </b-form-checkbox>


          </b-card>
        </div>
        <div class="col-md-1 px-0" v-for="(range, range_index) in range_values">
          <div class="card d-flex flex-column shipping-range-inputs">
          <b-form-input
              type="number"
              required
              v-model="range.start_delimeter"
              placeholder="Enter transit time"
            ></b-form-input>
            <b-form-input
              type="number"
              required
               v-model="range.end_delimeter"
              placeholder="Enter transit time"
            ></b-form-input>
          </div>
          <div class="card d-flex flex-row shipping-range-inputs p-0" v-for="(zone, index) in all_zones.data">
             <b-input-group prepend="£">
            <b-form-input
              type="number"
              required
              prepend="£"
              class="shipping-range-value"
              :disabled="checkZoneEnabled(zone.id, range_index)"
              v-model="range.zone_options[index].value"
              placeholder="0.00"
            ></b-form-input>
          </b-input-group>
          </div>
           <div class="card d-flex flex-column shipping-range-inputs">
          <button href="javascript:;" class="btn btn-danger" :disabled="range_index < 1" @click="removeRange(range_index)"><font-awesome-icon icon="trash" color="#fff" /></button>
        </div>
        </div>
        <div class="col-md-2 d-flex">
          <a href="javascript:;" class="btn btn-success my-auto" @click="addNewRange()">Add New Range <font-awesome-icon icon="plus" color="#fff" /></a>
        </div>
      </div>
    </div>
   </div>
  </div>
</transition>
 <transition name="slide-fadestepform">
      <div class="card card-profile col-md-12 no-margin" v-if="current == 2">
          <div class="row">
            <div class="col-md-12 margin-auto pb-4">
            <h3 class="mt-4">Shipping Restrictions</h3>
            <hr>
            <h5>Size & Weight Restrictions</h5>
            <div class="row">
              <div class="col-md-3 pb-4">
                <label>Max width</label>
                <b-input-group append ="cm">
                  <b-form-input
                    type="number"
                    required
                    append  ="£"
                    placeholder="0.00"
                  ></b-form-input>
                </b-input-group>
              </div>
               <div class="col-md-3 pb-4">
                <label>Max length</label>
                <b-input-group append ="cm">
                  <b-form-input
                    type="number"
                    required
                    append  ="£"
                    placeholder="0.00"
                  ></b-form-input>
                </b-input-group>
              </div>
               <div class="col-md-3 pb-4">
                <label>Max depth</label>
                <b-input-group append ="cm">
                  <b-form-input
                    type="number"
                    required
                    append  ="£"
                    placeholder="0.00"
                  ></b-form-input>
                </b-input-group>
              </div>
              <div class="col-md-3 pb-4">
                <label>Max weight</label>
                <b-input-group append ="kg">
                  <b-form-input
                    type="number"
                    required
                    prepend="£"
                    placeholder="0.00"
                  ></b-form-input>
                </b-input-group>
              </div>
            </div>
            <h5>Restrict by Category</h5>
            <category-select-list-tree></category-select-list-tree>
            <h5 class="pt-4">Restrict by Product</h5>
            <search-product></search-product>
            <h5>Restrict by Customer Group</h5>

           

    </div>
   </div>
  </div>
</transition>
 <transition name="slide-fadestepform">
      <div class="card card-profile col-md-12 no-margin" v-if="current == 3">
          <div class="row">
            <div class="col-md-12 margin-auto pb-4">
            <h3 class="mt-4">Shipping Integration</h3>
            <hr>
            <p>Associate this rate card with a certain provider for shipping requests. Any orders placed using this shipping option will be assigned this courier. Shipping and labels are booked with this courier by default when you process an order and print a shipping label.</p>
            <b-form-radio-group>
              <b-form-radio v-model="use_carrier_integration" value="0">
                Use Manual Shipping Labels 
              </b-form-radio>
              <b-form-radio  v-model="use_carrier_integration" value="1">
                Integrate with Courier Service
              </b-form-radio>
            </b-form-radio-group>


    </div>
    <div class="col-md-12 margin-auto pb-4" v-if="use_carrier_integration == 1">
       <h3>Select Courier</h3>
       <div class="row">
          <div class="col-md-2" v-for="(carrier, index) in all_carriers">
           <div class="card mt-4" @click="selectCarrier(carrier), selected_carrier_index = index" :class="{'active': selected_carrier_index == index}">
            
           <div class="card-body text-center d-flex">
            <img :src="carrier.logo" :style="[carrier.key == 'DeutschePost' ? {'width': '50px'} : {'width': '75px'}]" class="my-auto mx-auto">
            <p class="p-2 mx-auto my-auto">{{carrier.name}}</p>
            </div>
          </div>
          </div>
       </div>
    </div>
    <div class="col-md-12 margin-auto pb-4" v-if="selected_carrier_integration != ''">
       <h3>{{selected_carrier_integration.name}} Integration Details</h3>
       
       <b-input-group class="mt-3" prepend="Account Email"> 
          <b-form-input type="text"></b-form-input>
       </b-input-group>
       <b-input-group class="mt-3" prepend="Account ID"> 
          <b-form-input type="text"></b-form-input>
       </b-input-group>
       <b-input-group class="mt-3" prepend="API Key"> 
          <b-form-input type="text"></b-form-input>
       </b-input-group>
     <a class="btn btn-purple float-right mt-3">Use Defaults</a>
    </div>
   </div>
  </div>
</transition>
 <transition name="slide-fadestepform">
      <div class="card card-profile col-md-12 no-margin" v-if="current == 4">
          <div class="row">
            <div class="col-md-12 margin-auto pb-4">
            <h3 class="mt-4">Settings</h3>
            <hr>

    </div>
   </div>
  </div>
</transition>
  <div class="col-md-12 pb-3 px-4">
    <a v-if="current > 0" @click="current--" class="btn btn-purple float-left">Previous</a>
    <a v-if="current < (shipping_tabs.length - 1)" @click="current++" class="btn btn-purple float-right">Next</a>
  </div>
</div>
</b-form>
      </div>
    </div>
  </div>
</Layout>
</template>
<script>

import Layout from '../../../layouts/main'
import PageHeader from '../../../components/page-header'

import SideSlidePanel from "../../../components/widgets/SideSlidePanel"

export default {
        components: { Layout, PageHeader, SideSlidePanel },
        props: {
            companydata: Array,
        }, 
      data: function (){
        return{
          flip180: this.flipstate,
          title: 'Delivery Methods',
          items: [
            {
              text: 'Orders',
              active: '/orders/list',
            },
            {
              text: 'Delivery Methods',
              href: '/orders/delivery-methods/list',
            },
            {
              text: 'Create',
              active: true,
            },
          ],
          all_counties: [],
          use_carrier_integration: 0,
          selected_carrier_integration: '',
          selected_carrier_index: undefined,
          all_carriers: [ {
            "key": "APC",
            "name": "APC",
            "logo": "https://production.courierapi.co.uk/courier-service-logos/apc.png",
            "status": "beta"
        },
        {
            "key": "AmazonShipping",
            "name": "Amazon Shipping",
            "logo": "https://production.courierapi.co.uk/courier-service-logos/amazon-shipping.jpg",
            "status": "beta",
            "login_supported": true
        },
        {
            "key": "DHL",
            "name": "DHL",
            "logo": "https://production.courierapi.co.uk/courier-service-logos/DHL.png",
            "status": "beta"
        },
        {
            "key": "DPD",
            "name": "DPD",
            "logo": "https://production.courierapi.co.uk/courier-service-logos/DPD.png",
            "status": "beta"
        },
        {
            "key": "DPDLocal",
            "name": "DPD Local",
            "logo": "https://production.courierapi.co.uk/courier-service-logos/DPDlocal.png",
            "status": "beta"
        },
        {
            "key": "DeutschePost",
            "name": "Deutsche Post",
            "logo": "https://production.courierapi.co.uk/courier-service-logos/Deutsche_Post.png",
            "status": "beta"
        },
        {
            "key": "Fedex",
            "name": "Fedex",
            "logo": "https://production.courierapi.co.uk/courier-service-logos/fedex.png",
            "status": "beta"
        },
        {
            "key": "Hermes",
            "name": "Hermes",
            "logo": "https://production.courierapi.co.uk/courier-service-logos/hermes.png",
            "status": "beta"
        },
        {
            "key": "HermesCorporate",
            "name": "Hermes Corporate",
            "logo": "https://production.courierapi.co.uk/courier-service-logos/hermes.png",
            "status": "beta"
        },
        {
            "key": "LowCostParcels",
            "name": "LowCostParcels",
            "logo": "https://production.courierapi.co.uk/courier-service-logos/lowcostparcels.png",
            "status": "beta"
        },
        {
            "key": "Parcelforce",
            "name": "Parcelforce",
            "logo": "https://production.courierapi.co.uk/courier-service-logos/parcelforce.png",
            "status": "beta"
        },
        {
            "key": "RoyalMail",
            "name": "Royal Mail",
            "logo": "https://production.courierapi.co.uk/courier-service-logos/royal-mail.png",
            "status": "beta"
        },
        {
            "key": "SecuredMail",
            "name": "Secured Mail",
            "logo": "https://production.courierapi.co.uk/courier-service-logos/securedmail.png",
            "status": "beta"
        },
        {
            "key": "TNT",
            "name": "TNT",
            "logo": "https://production.courierapi.co.uk/courier-service-logos/tnt.png",
            "status": "beta"
        },
        {
            "key": "UKMail",
            "name": "UK Mail",
            "logo": "https://production.courierapi.co.uk/courier-service-logos/ukmail.png",
            "status": "beta"
        },
        {
            "key": "Whistl",
            "name": "Whistl",
            "logo": "https://production.courierapi.co.uk/courier-service-logos/whistl.png",
            "status": "beta"
        }],
          listView: true, 
          gridView: false,
          current: 0,
          all_zones: [],
          selected_zones: [],
          range_values:[
            {
              company_shipping_option_id:'',
              start_delimeter:'',
              end_delimeter: '',
              price: 0.00,
              zone_options:[],
              zone_id: '',
              active: 0,
              created_at: '',
              updated_at: '',
            }
          ],
          shipping_tabs: ['Shipping Option Information', 'Costs', 'Restrictions', 'Integrations', 'Settings'],
          shipping:{
            name: '',
            icon: '',
            free: 0,
            type: 0,
            created_at: '',
            updated_at: '',
          }
        }
        
      },
      beforeMount(){
        axios.get('/country-zones').then(response =>{
            this.all_zones = response;
            for(var i = 0; i <= (JSON.parse(this.all_zones.data.length) - 1); i++ ){
              this.range_values[0].zone_options.push({"zone_id": ''+this.all_zones.data[i].id+'', "value": '', "active": 0})
              }
        });
            axios.get(
              'https://production.courierapi.co.uk/api/couriers/v1/list-couriers', 
              {
                headers: 
                {
                  'Content-Type': 'application/json',
                  'api-user': 'getCarriers',
                  'api-token':  'griokptfhmzvbaqy'
                }
              }).then(response => {
                  this.all_carriers = response.couriers;
              }).catch(error => {
                  console.log(error.errors);
              });

      },
      methods: {
        getCarriersList(){
                    },
          selectCarrier(carrier){
            this.selected_carrier_integration = carrier;
          },
          addNewRange(){
            var current_index = this.range_values.length;
            this.range_values.push(
            {
              company_shipping_option_id:'',
              start_delimeter:'',
              end_delimeter: '',
              price: 0.00,
              zone_options:[],
              zone_id: '',
              active: 0,
              created_at: '',
              updated_at: '',
            }
          );
             for(var i = 0; i <= (JSON.parse(this.all_zones.data.length) - 1); i++ ){
              this.range_values[current_index].zone_options.push({"zone_id": ''+this.all_zones.data[i].id+'', "value": '', "active": 0})
              }
          },
          removeRange(index){
            this.range_values.splice(index, 1);
          },
          checkZoneEnabled(id, range_index){
            var return_value = '';
            var active_zone = this.selected_zones.filter(item => item == id);

            if(active_zone.length == 0){
              return true;
            }
            if(active_zone.length > 0){
              return false;
            }
          },
          activateZone(){

          },
          onCancel(evt){

          },
          handleSubmit () {
                this.$refs.modal.hide()
          },
          onSubmit: function () {
              this.$refs.invisibleRecaptcha.execute()
          },
          onVerify: function (response) {
             console.log('Verify: ' + response);
             this.claim_listing_id = this.selected_modal.id;
             this.claim_step++;
          },
          currentlyShowing: function(index){
            //this.current = index;
          },
          toggleActive: function(index){
            this.active = true;
            this.current = index;
          },
          onExpired: function () {
               console.log('Expired')
          },
          resetRecaptcha () {
            this.$refs.recaptcha.reset() // Direct call reset method
          },
          getLogo(avatar){
            return "/storage/"+avatar;
          },

      },
    }
</script>