<script type="text/javascript">
	import ImageBlocks from "../components/widgets/blocks/ImageBlocks";
	import ImageCarouselSwiperSubCategories from "../components/widgets/carousel/ImageCarouselSwiperSubCategories";
	import MiniCardCarousel from "../components/widgets/carousel/MiniCardCarousel";
	import Swiper from "../components/widgets/carousel/Swiper";
	export default{
		components: { Swiper, ImageBlocks, MiniCardCarousel, ImageCarouselSwiperSubCategories},
		data(){
			return{
				title: '',
				breadcrumbs: [],
				data: '',
				selectedCategory: {childs: []},
				categorySwiper: {
          slidesPerView: 5,
          spaceBetween: 20,
          loop: false,
          loopFillGroupWithBlank: false,
          pagination: {
            el: '.swiper-pagination',
            clickable: true,
          },
          breakpoints: {
          0: {
            slidesPerView: 1,
            spaceBetween: 20
          },
          // when window width is >= 320px
          420: {
            slidesPerView: 2,
            spaceBetween: 20
          },
          // when window width is >= 480px
          567: {
            slidesPerView: 3,
            spaceBetween: 30
          },
          // when window width is >= 640px
          767: {
            slidesPerView: 4,
            spaceBetween: 40
          },
          990: {
            slidesPerView: 5,
            spaceBetween: 40
          }
        }
          // Some Swiper option/callback...
        },
			}
		},
		created() {
			window.scrollTo(0,0);
			axios.get('/customer-api/product'+this.$route.path+'?view=childs').then(response => {
				this.data = response.data;
			}).catch(error => {
			});
			
			this.breadcrumbs = this.$route.path.split('/');
			if(this.breadcrumbs.length > 0){
			this.title = this.breadcrumbs[this.breadcrumbs.length - 1]
			}
		},
		methods: {
			selectCategory(event) {
				this.selectedCategory = JSON.parse(JSON.stringify(event));
				this.selectedCategory.childs.push(JSON.parse(JSON.stringify(event)));
			}
		},
		watch: {
			$route(){
				this.$nextTick(() => {
					window.scrollTo(0,0);
				});
			}
		}
	}

</script>

<template>

		<section class="header-section pb-4">
		  <div class="container-fullwidth">
		    <!--<PageHeader :title="title" :items="items" />-->
		    <b-row style=" min-height: 350px; background: linear-gradient(45deg, rgba(94, 59, 174,0.15), rgba(239, 242, 247, 0.25))" class="bg-light bg-soft">
		      <b-col md="2" class="d-flex flex-column justify-content-end align-items-start ml-auto" >
		         
		      </b-col>
		      <b-col md="5" class="d-flex flex-column justify-content-center align-items-center">

		        <h1 class="section-info-header position-relative">Select from Jewellery Types</h1>
		        <p class="text-dark text-center">
		            Choose one of the categories below to filter by the type
		            of jewellery you are searching for.
		          </p>
		      </b-col>
		      <b-col md="2" class="d-flex flex-column justify-content-end align-items-start mr-auto" >
		         
		      </b-col>
		    </b-row>
		     <b-row class="categories-top-row mb-4 pb-4">
				<b-col lg="10" class="mx-auto" xl="8">
					 <ImageCarouselSwiperSubCategories :items="data.filter(item => item.parent_id == null)" :grid_limit="5" :end="5"  :resource="'category'" :liveMode="true" :swiperOptions="categorySwiper"  @select-category="selectCategory"></ImageCarouselSwiperSubCategories>
					</b-col>
				</b-row>
					<b-row class="categories-top-row mb-4 pb-4" v-if="selectedCategory.childs.length > 0" >
						<b-col lg="10" class="mx-auto" xl="8">
					<h3 class="text-center pt-5 my-4">Select from Jewellery Pieces</h3>
					<ImageBlocks v-if="selectedCategory.childs.length > 0" :items="selectedCategory.childs" :grid_limit="24" :end="24" :autoplay="false" :headingtype="'h4'"></ImageBlocks>
				</b-col>
			</b-row>
		    <!-- end row -->
		  </div>
		</section>

</template>