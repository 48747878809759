 <script type="text/javascript">
 	import Toolbar from "./EmailToolbar";
 	import Emailer from "./Emailer";
 	import DateTime from 'luxon/src/datetime.js';
	import Interval from 'luxon/src/interval.js';
 
 	export default {
 		components: {
 			Toolbar,
 			Emailer,
 			DateTime,
    		Interval
 		},
 		props: {
 			email: Object
 		},
 		data(){
 			return{
 				send_email: {
          id: '',
          notification_thread_id: '',
 					email_to: [],
					email_cc: [],
					email_bcc: [],
					subject: '',
					message: '',
          replying_to: [],
 				}
 			}
 		},
 		methods: {
 			sendEmail(email){
 				this.send_email.email_to[0] = email.data.email;
        this.send_email.id = email.id;
        this.send_email.replying_to.push(email);
 				this.$emit('send-email', this.send_email);
 			},
      closeViewer(){
        this.$emit('close-email-viewer');
      },
 			getDate(date){
		  		return DateTime.fromISO(date).toHTTP();
		  	},
 		}
 	}

 </script>

 <template>
 	<div class="row">
      <div class="col-12">
        <!-- Left sidebar -->
        <!-- End Left sidebar -->

        <!-- Right Sidebar -->
        <div class="mb-3">
          <div class="card shadow-none border-radius-1em border" v-if="email !== '' && email !== null">
            <div class="card-body">
              <div class="media mb-4 border border-radius-1em p-3">
              	<div class="avatar-sm mr-3"> 
                	<span class="avatar-title rounded-circle" >{{(email.data.name).slice(0,1)}}</span>
            	</div>
                <div class="media-body my-auto">
                  <h5 class="font-size-14 my-0">{{email.data.name}} {{email.data.lastname}}</h5>
                  <p class="m-0 p-0"><small class="text-muted"><a :href="'mailto:'+email.data.email">{{email.data.email}}</a></small></p>
                  <p class="m-0 p-0"><small class="text-muted">{{getDate(email.created_at)}}</small></p>
                </div>
                <div class="media-body my-auto d-flex">
                	<a class="btn btn-primary ml-auto btn-rounded">
                		Make Into Customer
                	</a>
                	<a class="btn btn-danger btn-rounded ml-1" v-b-tooltip.hover title="Delete">
                		<i class="bx bx-trash-alt"></i>
                	</a>
                	
                	<a class="btn btn-primary btn-rounded ml-1" v-b-tooltip.hover :title="'Reply to '+email.data.email" @click="sendEmail(email)" >
                		<i class="mdi mdi-reply"></i>
                	</a>

                  <a class="btn btn-light btn-rounded ml-1 px-3" v-b-tooltip.hover :title="'Close'" @click="closeViewer()" >
                   <i class="btn-close"></i>
                  </a>
                </div>
              </div>
              <b-card class="shadow-none border-radius-1em border">
              <h4 class="mt-0 font-size-16 mb-3">Subject: {{email.data.subject}}</h4>
              <hr>
              <p>{{email.data.message}}</p>
              <hr />
              Sent by: {{email.data.name}} {{email.data.lastname}} <a @click="sendEmail(email.data.email)" href="javascript:;">({{email.data.email}})</a><br>
              Sent from {{email.data.source}} <span v-if="email.data.source_url">page</span> <a v-if="email.data.source_url" :href="email.data.source_url" target="_blank">{{email.data.source_url}} <i class="bx bx-link-external"></i></a>
              <span v-else class="text-muted">data could not be retrieved</span>
              <!--<div class="row">
                <div class="col-xl-2 col-6">
                  <div class="card">
                    <img
                      class="card-img-top img-fluid"
                      src="/images/small/img-3.jpg"
                      alt="Card image cap"
                    />
                    <div class="py-2 text-center">
                      <a href class="fw-medium">Download</a>
                    </div>
                  </div>
                </div>
                <div class="col-xl-2 col-6">
                  <div class="card">
                    <img
                      class="card-img-top img-fluid"
                      src="/images/small/img-4.jpg"
                      alt="Card image cap"
                    />
                    <div class="py-2 text-center">
                      <a href class="fw-medium">Download</a>
                    </div>
                  </div>
                </div>
              </div>-->
          	  </b-card>
            </div>
          </div>

        </div>
        <!-- card -->
        
      </div>
      <!-- end Col-9 -->
    </div>

 </template>