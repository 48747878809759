<script>
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";

import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";

/**
 * Cropper component
 */
export default {
  components: { VueCropper, Layout, PageHeader },
  data() {
    return {
      title: "Image Cropper",
      items: [
        {
          text: "UI Elements",
          href: "/"
        },
        {
          text: "Image Cropper",
          active: true
        }
      ],
    };
  },
  methods: {
    zoom(percent) {
      this.$refs.cropper.relativeZoom(percent);
    },
    rotate(deg) {
      this.$refs.cropper.rotate(deg);
    },
    move(offsetX, offsetY) {
      this.$refs.cropper.move(offsetX, offsetY);
    },
    flipX() {
      const dom = this.$refs.flipX;
      let scale = dom.getAttribute("data-scale");
      scale = scale ? -scale : -1;
      this.$refs.cropper.scaleX(scale);
      dom.setAttribute("data-scale", scale);
    },
    flipY() {
      const dom = this.$refs.flipY;
      let scale = dom.getAttribute("data-scale");
      scale = scale ? -scale : -1;
      this.$refs.cropper.scaleY(scale);
      dom.setAttribute("data-scale", scale);
    },
    reset() {
      this.$refs.cropper.reset();
    }
  }
};
</script>

<template>


    <div class="row px-4">
      <div class="col-xl-9">
        <div class="card">
            <vue-cropper ref="cropper" :src="$attrs.imageUrl" alt="Source Image" preview=".preview-lg" />
            <div class="mt-2 position-absolute image-cropper-toolbar w-100 text-center mx-auto">
              <a href="javascript:;" class="btn btn-primary btn-rounded" @click.prevent="zoom(0.2)" v-b-tooltip.hover title="Zoom in"><i class="bx bx-zoom-in"></i></a>
              <a href="javascript:;" class="btn btn-primary btn-rounded ml-1" @click.prevent="zoom(-0.2)" v-b-tooltip.hover title="Zoom Out"> <i class="bx bx-zoom-out"></i></a>
              <a href="javascript:;" class="btn btn-primary btn-rounded ml-1" @click.prevent="rotate(90)" v-b-tooltip.hover title="Rotate Right"> <i class="bx bx-rotate-right"></i></a>
              <a href="javascript:;" class="btn btn-primary btn-rounded ml-1" @click.prevent="rotate(-90)" v-b-tooltip.hover title="Rotate Left"> <i class="bx bx-rotate-left"></i></a>
              <a href="javascript:;" class="btn btn-primary btn-rounded ml-1" @click.prevent="move(-10, 0)" v-b-tooltip.hover title="Move Left"> <i class="bx bx-left-arrow-alt"></i></a>
              <a href="javascript:;" class="btn btn-primary btn-rounded ml-1" @click.prevent="move(10, 0)" v-b-tooltip.hover title="Move Right"> <i class="bx bx-right-arrow-alt"></i></a>
              <a href="javascript:;" class="btn btn-primary btn-rounded ml-1" @click.prevent="move(0, -10)" v-b-tooltip.hover title="Move Up"><i class="bx bx-up-arrow-alt"></i></a>
              <a href="javascript:;" class="btn btn-primary btn-rounded ml-1" @click.prevent="move(0, 10)" v-b-tooltip.hover title="Move Down"> <i class="bx bx-down-arrow-alt"></i></a>
              <a ref="flipX" href="javascript:;" class="btn btn-primary btn-rounded ml-1" @click.prevent="flipX" v-b-tooltip.hover title="Flip Horizontally"> <i class="mdi mdi-flip-horizontal"></i></a>
              <a ref="flipY" href="javascript:;" class="btn btn-primary btn-rounded ml-1" @click.prevent="flipY" v-b-tooltip.hover title="Flip Vertically"> <i class="mdi mdi-flip-vertical"></i></a>
              <a href="javascript:;" class="btn btn-primary btn-rounded ml-1" @click.prevent="reset" v-b-tooltip.hover title="Reset"> <i class="bx bx-reset"></i> </a>
            </div>
            <a href="javascript:;" class="btn btn-success mt-2">Save Image</a>
        </div>
      </div>
      <div class="col-xl-3">
        <div class="preview-lg"></div>
      </div>
    </div>
</template>


<style scoped>
.preview-lg {
  width: 100%;
  height: calc(322px * (9 / 16));
  overflow: hidden;
}
</style>
