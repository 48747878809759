<template>
	<div>
	<a class="#" v-if="$attrs.liveMode == false">
			<b-card class="card overflow-hidden border-radius-1em shadow-sm seller-profile-card" no-body @mouseenter="hoverIndex = index" @mouseleave="hoverIndex = -1">
          <div class="bg-soft bg-primary">
            <div class="row">
            	<transition name="fade-left">
		                    	<div class="col-md-12 position-absolute z-index-2" v-if="hoverIndex == index"> 
                              	<a @click="$bvModal.show('listing-info-'+listing.uid)" class="mt-3 ml-3 btn btn-primary font-size-16 btn-rounded ml-auto float-left"><i class="mdi mdi-eye-outline"></i></a>
                              </div>
                          </transition>
                           
                       <div class="col-12">

                <a :href="'javascript:;'">
                					<div v-if="listing.banner !== null">	

                						<b-card-img-lazy :src="storageurl + listing.banner.src" alt class="img-fluid" /></b-card-img-lazy>

                						<!--<b-card-img-lazy v-if="'gallery' in listing.banner" :src="storageurl + listing.banner.gallery" alt class="img-fluid" /></b-card-img-lazy>
                						<b-card-img-lazy v-else-if="'thumb' in listing.banner" :src="storageurl + listing.banner.thumb" alt class="img-fluid" /></b-card-img-lazy>
                						<b-card-img-lazy v-else-if="'src' in listing.banner" :src="storageurl + listing.banner.src" alt class="img-fluid" /></b-card-img-lazy>
                						<b-card-img-lazy v-else src="/images/banner-placeholder.png" alt class="img-fluid" /></b-card-img-lazy>-->
                					</div>
                					<b-card-img-lazy v-else src="/images/banner-placeholder.png" alt class="img-fluid" /></b-card-img-lazy>
                				</a>
                 <!--<transition name="fade-right">
		                        <div class="mt-auto mb-3 mx-2 d-flex position-absolute bottom-0 right-0 left-0" v-if="hoverIndex == index">
                              	<p  class="text-muted ml-auto my-auto mr-3">
			                    <span class="bx bx-star font-size-14 text-warning"></span>
			                    <span class="bx bx-star font-size-14 text-warning ml-1"></span>
			                    <span class="bx bx-star font-size-14 text-warning ml-1"></span>
			                    <span class="bx bx-star font-size-14 text-warning ml-1"></span>
			                    <span class="bx bx-star font-size-14 ml-1"></span>
			                  </p>
              					</div>
              				</transition>-->
              </div>
            </div>
          </div>
          <b-card-body class="card-body pt-0">
            <div class="row">
              <div class="col-sm-12 pb-4 text-center">
                <div class="avatar-md profile-user-wid mb-2 mx-auto" style="margin-top: -45px !important;">
                  
                  <span class="avatar-title avatar-md bg-light text-peach rounded-circle">
                  	<i class="bx bx-map-pin font-size-20"></i>
                  </span>
                </div>
                <h5 class="font-size-15 text-truncate">{{listing.name}}</h5>
                <p class="text-muted mb-0">
                	<span v-if="listing.address_1 !== null">{{listing.address_1}},</span>
                	<span v-if="listing.address_2 !== null">{{listing.address_2}},</span>
                	<span v-if="listing.address_3 !== null">{{listing.address_3}},</span>
                	<span v-if="listing.city !== null">{{listing.city}},</span>
                	<span v-if="listing.county !== null">{{listing.county.name}},</span>
									<span v-if="listing.postcode !== null">{{listing.postcode}}</span>
                	<span v-if="listing.country !== null">{{listing.country.name}}</span>
                </p>
              </div>
              <div class="col-md-12 d-flex">
              	<span class="badge badge-light border-radius-1em bg-light text-dark font-size-11 lh-16 my-auto p-2 mx-auto" v-if="listing.listing_type !== null">
              		{{listing.listing_type.name}}
              	</span>
              </div>
             

            </div>
          </b-card-body>
        </b-card>
	</a>

	<a class="" v-else-if="$attrs.liveMode == true" @click="$bvModal.show('listing-info-'+listing.uid)">
		<b-card class="card overflow-hidden border-radius-1em shadow-sm seller-profile-card" no-body @mouseenter="hoverIndex = index" @mouseleave="hoverIndex = -1">
          <div class="bg-soft bg-primary">
            <div class="row">
            	<transition name="fade-left">
		                    	<div class="col-md-12 position-absolute z-index-5" v-if="hoverIndex == index"> 
                              	<a id="tooltip-add" @click="$bvModal.show('listing-info-'+listing.uid)" href="javascript:;'" class="mt-3 ml-3 btn btn-primary font-size-16 btn-rounded float-left"><i class="mdi mdi-eye-outline"></i></a>
                              </div>
                          </transition>
                           
                       <div class="col-12">

                <a href="javascript:;" @click="$bvModal.show('listing-info-'+listing.uid)">
                					<div v-if="listing.banner !== null">	

                						<b-card-img-lazy :src="storageurl + listing.banner.src" alt class="img-fluid" /></b-card-img-lazy>

                						<!--<b-card-img-lazy v-if="'gallery' in listing.banner" :src="storageurl + listing.banner.gallery" alt class="img-fluid" /></b-card-img-lazy>
                						<b-card-img-lazy v-else-if="'thumb' in listing.banner" :src="storageurl + listing.banner.thumb" alt class="img-fluid" /></b-card-img-lazy>
                						<b-card-img-lazy v-else-if="'src' in listing.banner" :src="storageurl + listing.banner.src" alt class="img-fluid" /></b-card-img-lazy>
                						<b-card-img-lazy v-else src="/images/banner-placeholder.png" alt class="img-fluid" /></b-card-img-lazy>-->
                					</div>
                					<b-card-img-lazy v-else src="/images/banner-placeholder.png" alt class="img-fluid" /></b-card-img-lazy>
                				</a>
                 <transition name="fade-right">
		                        <div class="mt-auto mb-3 mx-2 d-flex position-absolute bottom-0 right-0 left-0" v-if="hoverIndex == index">
                              	<p  class="text-muted ml-auto my-auto mr-3">
			                    <span class="bx bx-star font-size-14 text-warning"></span>
			                    <span class="bx bx-star font-size-14 text-warning ml-1"></span>
			                    <span class="bx bx-star font-size-14 text-warning ml-1"></span>
			                    <span class="bx bx-star font-size-14 text-warning ml-1"></span>
			                    <span class="bx bx-star font-size-14 ml-1"></span>
			                  </p>
              					</div>
              				</transition>
              </div>
            </div>
          </div>
          <b-card-body class="card-body pt-0">
            <div class="row">
              <div class="col-sm-12 pb-4 text-center">
                <!--<div class="avatar-md profile-user-wid mb-2 mx-auto" style="margin-top: -45px !important;">
                  <img
                  	v-if="listing.logo" :src="storageurl + listing.logo"
                    alt
                    class="img-thumbnail rounded-circle"
                  />
                   <img
                  	v-else 
                    src="/images/product-placeholder.png"
                    alt
                    class="img-thumbnail rounded-circle"
                  />
                </div>-->

                <div class="avatar-md profile-user-wid mb-2 mx-auto" style="margin-top: -45px !important;">
                  
                  <span class="avatar-title avatar-md bg-light text-primary rounded-circle">
                  	<i class="bx bx-map-pin font-size-20"></i>
                  </span>
                </div>
                <h5 class="font-size-15 text-truncate">{{listing.name}}</h5>
                <p class="text-muted mb-0 text-truncate"><span v-if="listing.county !== null">{{listing.county.name}},</span> {{listing.country.name}}</p>
              </div>
              <div class="col-md-6 d-flex">
              	<span class="badge badge-light border-radius-1em bg-light text-dark font-size-11 lh-16 my-auto p-2" v-if="listing.listing_type !== null">
              		{{listing.listing_type.name}}
              	</span>
              </div>
              <div class="col-md-6 d-flex">
              	<!--<span class="badge badge-light border-radius-1em bg-light text-dark font-size-11 lh-16 my-auto p-2 ml-auto">
              		100 <i class="bx bx-tag"></i>
              	</span>-->
              	
              </div>

            </div>
          </b-card-body>
        </b-card>
	</a>
	<b-modal :id="'listing-info-'+listing.uid" hide-footer hide-header content-class="border-none border-radius-1em overflow-hidden" body-class="p-0" size="lg" centered>
		<a class="btn btn-light btn-rounded position-absolute z-index-2 top-0 right-0 m-3" @click="$bvModal.hide('listing-info-'+listing.uid)">
			<i class="mdi mdi-close"></i>
		</a>
		<gmap-map :center="{ lat: parseFloat(listing.latitude), lng: parseFloat(listing.longitude) }" :zoom="7" style="height: 350px" class="border-radius-1em">
                  <gmap-marker

                    :position="{ lat: parseFloat(listing.latitude), lng: parseFloat(listing.longitude) }"
                    :clickable="true"
                    :draggable="true"
                    @click="center = { lat: parseFloat(listing.latitude), lng: parseFloat(listing.longitude) }"
                  ></gmap-marker>
                </gmap-map>
       <b-row>
       	<b-col>
       		<b-card class="shadow-none border-none mb-0">
       			<div class="d-flex">
       						<h4 class="card-title">{{listing.name}}</h4>

       						<a class="btn btn-light btn-rounded ml-auto" :href="'tel:'+listing.phone">
                	 Call Now
                	</a>
       						<a class="btn btn-light btn-rounded ml-2 mb-auto" :href="'https://www.google.com/maps/dir/?api=1&destination='+listing.address_1+' '+listing.address_2+''+listing.postcode+'&destination_place_id='+listing.gplace_id+'&zoom=12'" target="_blank">
                		Get Directions
                	</a>
       			</div>
       		<p class="text-muted my-1 d-flex">
       						<span class="my-auto mr-2"><i class="bx bx-home font-size-20 text-primary"></i></span>
                	<span class="my-auto mr-1" v-if="listing.address_1 !== null">{{listing.address_1}},</span>
                	<span class="my-auto mr-1" v-if="listing.address_2 !== null">{{listing.address_2}},</span>
                	<span class="my-auto mr-1" v-if="listing.address_3 !== null">{{listing.address_3}},</span>
                	<span class="my-auto mr-1" v-if="listing.city !== null">{{listing.city}},</span>
                	<span class="my-auto mr-1" v-if="listing.county !== null">{{listing.county.name}},</span>
									<span class="my-auto mr-1" v-if="listing.postcode !== null">{{listing.postcode}}</span>
                	<span class="my-auto mr-1" v-if="listing.country !== null">{{listing.country.name}}</span>
                	
                </p>
                <p class="d-flex" >
                	<span class="my-auto"><i class="bx bx-phone text-primary font-size-20"></i></span>
                	<span class="my-auto ml-2 text-muted">{{listing.phone}}</span>

                </p>
                	<!--<p  class="text-muted ml-auto my-auto mt-3 mr-3">
			                    <span class="bx bx-star font-size-14 text-warning"></span>
			                    <span class="bx bx-star font-size-14 text-warning ml-1"></span>
			                    <span class="bx bx-star font-size-14 text-warning ml-1"></span>
			                    <span class="bx bx-star font-size-14 text-warning ml-1"></span>
			                    <span class="bx bx-star font-size-14 ml-1"></span>
			                  </p>
			           <h6>Location Reviews</h6>
			           <div v-if="listing.reviews.length > 0">
					           <simplebar style="max-height: 380px;">       	
					            <b-card class="border-radius-1em border shadow-none">
					            		<b-list-group>
					            			<b-list-group-item v-for="review in listing.reviews">
					            			</b-list-group-item>
					            		</b-list-group>
					            </b-card>
					          </simplebar>
			        		</div>
			        		<div v-else>
			        			<b-card class="border-radius-1em border shadow-none" body-class="text-center">
			        						<i class="bx bx-star text-warning d-inline-block font-size-14"></i>
													<i class="bx bx-star text-warning d-inline-block font-size-20"></i>
													<i class="bx bx-star text-warning d-inline-block font-size-28"></i>
													<i class="bx bx-star text-warning d-inline-block font-size-20"></i>
													<i class="bx bx-star text-warning d-inline-block font-size-14"></i>
													<h4 class="text-muted">
														No Reviews
													</h4>
			        			</b-card>
			        		</div>-->
           </b-card>
       	</b-col>
       </b-row>
	</b-modal>
</div>
</template>

<script type="text/javascript">
	export default{
		props: {
			listing: {
				type: Object,
			},
			index: {
				type: Number
			}
		},
		data (){
			return {
				hoverIndex: -1,
			}
		},
		methods: {
			loadDefaultClasses(classes){
				return classes;
			},
			filterStylesKey(styles, key){
                for(var i = 0; i < styles.length; i++){
                   var keys = Object.keys(styles[i])
                   if(keys.includes(key)){
                     return i;
                   }
                }
            },
			compileElementStyles(fieldname, types){
				var style = '';
				if(types.includes('background') == true){
				  style += 'background-color:'+this.$attrs.content[fieldname+'_colour'].value+' !important;'
				}
				if(types.includes('color') == true){
					style += 'color:'+this.$attrs.content[fieldname+'_text_colour'].value+' !important;'
				}
				if(types.includes('border') == true){
					/*style +=  'border-color: '+this.$attrs.content[fieldname+'_border_colour'].value+' !important; border-style:'+this.$attrs.content[fieldname+'_border_style'].value+' !important; border-radius:'+this.$attrs.content[fieldname+'_border_radius'].top_left+' '+this.$attrs.content[fieldname+'_border_radius'].top_right+' '+this.$attrs.content[fieldname+'_border_radius'].bottom_right+' '+this.$attrs.content[fieldname+'_border_radius'].bottom_left+' !important; border-left:'+this.$attrs.content[fieldname+'_border'].left+'px !important; border-right: '+this.$attrs.content[fieldname+'_border'].right+'px !important; border-top: '+this.$attrs.content[fieldname+'_border'].top+'px !important; border-bottom: '+this.$attrs.content[fieldname+'_border'].bottom+'px !important;'*/

					style += 'border-radius:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_border_radius')][fieldname+'_border_radius'].top_left+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_border_radius')][fieldname+'_border_radius'].units +' '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_border_radius')][fieldname+'_border_radius'].top_right+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_border_radius')][fieldname+'_border_radius'].units +' '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_border_radius')][fieldname+'_border_radius'].bottom_right+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_border_radius')][fieldname+'_border_radius'].units +' '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_border_radius')][fieldname+'_border_radius'].bottom_left+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_border_radius')][fieldname+'_border_radius'].units +' !important;'
						style += ' border-left:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_border')][fieldname+'_border'].left+'px !important; border-right: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_border')][fieldname+'_border'].right+'px !important; border-top: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_border')][fieldname+'_border'].top+'px !important; border-bottom: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_border')][fieldname+'_border'].bottom+'px !important;'

						style += 'border-style: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_border_style')][fieldname+'_border_style'].value+' !important; ';
						style +=  ' border-color: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_border_colour')][fieldname+'_border_colour'].value+' !important;' ;
				}
				if(types.includes('margin') == true){
					if(fieldname !== 'column'){
					style +=  'margin-left:'+this.$attrs.content[fieldname+'_margin'].left+'px !important;'
					style +=  'margin-right: '+this.$attrs.content[fieldname+'_margin'].right+'px !important;'
					style +=  'margin-top: '+this.$attrs.content[fieldname+'_margin'].top+'px !important;'
					style +=  'margin-bottom: '+this.$attrs.content[fieldname+'_margin'].bottom+'px !important;'
					}else{
					style +=  'margin-left:'+this.$attrs.content[fieldname]['margin'].left+'px !important;'
					style +=  'margin-right: '+this.$attrs.content[fieldname]['margin'].right+'px !important;'
					style +=  'margin-top: '+this.$attrs.content[fieldname]['margin'].top+'px !important;'
					style +=  'margin-bottom: '+this.$attrs.content[fieldname]['margin'].bottom+'px !important;'
					}
				}
				if(types.includes('padding') == true){
					if(fieldname !== 'column'){
					style +=  'padding-left:'+this.$attrs.content[fieldname+'_padding'].left+'px !important;'
					style +=  'padding-right: '+this.$attrs.content[fieldname+'_padding'].right+'px !important;'
					style +=  'padding-top: '+this.$attrs.content[fieldname+'_padding'].top+'px !important;'
					style +=  'padding-bottom: '+this.$attrs.content[fieldname+'_padding'].bottom+'px !important;'
					}else{
						style +=  'padding-left:'+this.$attrs.content[fieldname]['padding'].left+'px !important;'
					style +=  'padding-right: '+this.$attrs.content[fieldname]['padding'].right+'px !important;'
					style +=  'padding-top: '+this.$attrs.content[fieldname]['padding'].top+'px !important;'
					style +=  'padding-bottom: '+this.$attrs.content[fieldname]['padding'].bottom+'px !important;'
					}
				}
				if(types.includes('size') == true){
					style +=  'height:'+this.$attrs.content[fieldname+'_height'].value+this.$attrs.content[fieldname+'_height'].units+' !important;';
					style +=  'max-height:'+this.$attrs.content[fieldname+'_max_height'].value+this.$attrs.content[fieldname+'_height'].units+' !important;';
					style +=  'width:'+this.$attrs.content[fieldname+'_width'].value+this.$attrs.content[fieldname+'_height'].units+' !important;'
					style +=  'max-width:'+this.$attrs.content[fieldname+'_max_width'].value+this.$attrs.content[fieldname+'_height'].units+' !important;'
				}
				
				if(types.includes('text') == true){
				  style += 'font-family: \"'+this.$attrs.content[fieldname].font_family.value.family+'\", '+this.$attrs.content[fieldname].font_family.value.category+' !important; font-size:'+this.$attrs.content[fieldname].font_size.value+this.$attrs.content[fieldname].font_size.units+' !important; text-transform: '+this.$attrs.content[fieldname].text_transform.value+'!important;';
				  style += 'color:'+this.$attrs.content[fieldname].text_colour.value+' !important;'
				}

				return style;
			}
		},

	}

</script>

