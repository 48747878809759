<script type="text/javascript">

	import VueSlider from 'vue-slider-component'
	import ImageBlocks from "../components/widgets/blocks/ImageBlocks";
	import MiniCardCarousel from "../components/widgets/carousel/MiniCardCarousel";
	//import Swiper from "../components/widgets/carousel/Swiper";
	import PublicMarketplaceProductList from "../components/widgets/PublicMarketplaceProductList";
	import FilterPublicProductBrands from "../components/widgets/filters/public/FilterPublicProductBrands";
	import FilterPublicProductCategories from "../components/widgets/filters/public/FilterPublicProductCategories";
	import FilterPublicProductCollections from "../components/widgets/filters/public/FilterPublicProductCollections";
		import CustomerSellerEnquiry from "../components/widgets/forms/public/CustomerSellerEnquiry";
	import Login from "../public-views/account/onpageLogin";
	import { EventBus } from '../public-app';
	//import Breadcrumbs from "../components/widgets/ecommerce/public/Breadcrumbs";
	import SocialShareBlock from "../components/widgets/page-builder/widgets/web/SocialShareBlock"
	import ReviewForm from "../components/widgets/forms/ReviewForm"
	import ReviewCard from "../components/widgets/forms/ReviewCard"
	import ReviewCarousel from "../components/widgets/forms/ReviewCarousel"
	import SideSlidePanel from "../components/widgets/SideSlidePanel"
	import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper';
import SwiperClass, { Pagination } from 'swiper'
  import 'swiper/swiper.min.css';

	export default{
		components: { ImageBlocks, MiniCardCarousel, PublicMarketplaceProductList, VueSlider, FilterPublicProductBrands, FilterPublicProductCategories, FilterPublicProductCollections, Login, CustomerSellerEnquiry, SocialShareBlock, ReviewForm, ReviewCard, ReviewCarousel, SideSlidePanel, Swiper, SwiperSlide },
		 directives: {
        swiper: directive
   },
		data(){
			return{
				productConditions: [
		        'New',
		        'Bespoke',
		        'Used',
		        'Antique',
		        'Refurbished',
		        'Custom',
      	],
      	productMetals: [
      			'Gold',
      			'Rose Gold',
      			'White Gold',
      			'Silver',
      			'Platinum',
      			'Palladium',
      	],
      	productCarats: [
      			'9',
      			'18',
      			'24',
      	],
      	productGemstones: [
      			'Aquamarine',
      			'Diamond',
      			'Emerald',
      			'Garnet',
      			'Opal',
      			'Pearl',
      			'Ruby',
      			'Sapphire',
      			'Tsavorite',
      			'Topaz',
      			'Tanzonite',
      	],
      	condition: '',
      	attributes: {
      		gemstones: [],
      		metals: [], 
      	},
      	loadingFilters: false,
      	keywordslist: [],
				hoverIndex: -1,
				listingsearch: '',
				action_error: [],
				loadingActionModal: true,
				favourites_status: false,
				mapcenter: { lat: 54, lng: 2 },
				mapzoom: 5,
				showStores: false,
				listingsData: '',
				title: '',
				listing: '',
				breadcrumbs: [],
				bread: {
					title: '',
					link: '',
					id: 1,
				},
				meta: {
					title: '',
					description: '',
					image: '', 
					og: {
						title: '',
						description: '',
						image: '',
						url: '',
					}
				},
				csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
				categorydata: '',
				categoriesdata: '',
				data: '',
				form: {
					action: '',
					name: '',
					lastname: '',
					email: '',
					customer_uid: '',
					share_details: '',
					review: '',
					message: '',
					review_score: 0,
				},
				swiperOptions2: {
          slidesPerView: 3,
          spaceBetween: 20,
          loop: false,
          autoplay: {
            delay: 2500,
            disableOnInteraction: false
          },
          loopFillGroupWithBlank: false,
          pagination: {
            el: '.swiper-pagination',
            clickable: true,
          },
          breakpoints: {
          0: {
            slidesPerView: 1,
            spaceBetween: 20
          },
          // when window width is >= 320px
          320: {
            slidesPerView: 1,
            spaceBetween: 20
          },
          // when window width is >= 480px
          567: {
            slidesPerView: 2,
            spaceBetween: 30
          },
          // when window width is >= 640px
          767: {
            slidesPerView: 3,
            spaceBetween: 40
          },
          990: {
            slidesPerView: 3,
            spaceBetween: 40
          }
        }
          // Some Swiper option/callback...
        },
				reviewOptions: {
		          slidesPerView: 3,
		          spaceBetween: 30,
		          autoplay: {
				      delay: 2500,
				      disableOnInteraction: false
				    },
		          loop: false,
		          loopFillGroupWithBlank: false,
		          pagination: {
		            el: '.swiper-pagination',
		            clickable: true,
		          },
		          breakpoints: {
			          0: {
			            slidesPerView: 1,
			            spaceBetween: 20
			          },
			          // when window width is >= 320px
			          420: {
			            slidesPerView: 1,
			            spaceBetween: 20
			          },
			          // when window width is >= 480px
			          567: {
			            slidesPerView: 2,
			            spaceBetween: 30
			          },
			          // when window width is >= 640px
			          767: {
			            slidesPerView: 2,
			            spaceBetween: 40
			          },
			          990: {
			            slidesPerView: 2,
			            spaceBetween: 40
			          },
			          1240: {
			            slidesPerView: 3,
			            spaceBetween: 40
			          }

			        }

		          // Some Swiper option/callback...
		        },
				infomodalaction: 'reviews',
				storageurl: '.s3.eu-west-2.amazonaws.com/',
				productsLoading: true,
				showFilters: false,
				showFiltersSm: false,
		        sliderPrice: 800,
		        priceRange: [0,250000],
		        brands: [],
		        collections: [],
		        searchcollections: [],
		        categories: [],
		        sortby: 'created_at',
		        order: 'desc',
		        search: '',
		        view: 'grid',
		        public_active: 1,
		        trade_active: '',
		        active: '',
		        expanded: 1,
		        currentPage: 1,
		        query: '',
		        listingssortby: 'created_at',
		        listingsorder: 'desc',
						listingspublic_active: 1,
						listingstrade_active: '',
						listingscurrentPage: 1,
						listingslimit: 25,
						limit: 24,
						limits: [
				        {value: 24, text: "24"},
				        {value: 48, text: "48"},
				        {value: 96, text: "96"},
				      ],
			}
		},
		props: {
			user: {
				type: Object
			}
		},
		mounted() {

			this.createQueryString();
			this.loadSellerInfo();
			this.loadSellerProducts();
			this.loadSellerCollections();
			this.checkUserLoggedIn();
			//this.loadSellerCollections();
	
		},
		created(){
			window.scrollTo(0,0);

			this.loadAllCategories();
			let self = this;
			EventBus.$on('category-select', function(id){
				self.filterSelectedCategories(id);
		    });
			EventBus.$on('categories-cleared', function(id){
				self.categories = [];
				//self.loadCategory();
		    });
		},
		methods: {
			injectMeta(product){
									// Create a script element for JSON-LD schema
				//var existingSchemaTag = document.getElementById('productSchema');
					this.meta['title'] = product.name;
					this.meta['og']['title'] = product.name;
					if(product.description !== null){
						this.meta['description'] = product.description.replaceAll("<[^>]*>", "");
						this.meta['og']['description'] = product.description.replaceAll("<[^>]*>", "");
					}
					this.meta['og']['url'] = 'https://gltrbox.co.uk'+this.$route.path;
					this.meta['image'] = 'https://gltrbox.co.uk/public-image'+product.logo+'?s='+this.$route.params.id;
					this.meta['og']['image'] = 'https://gltrbox.co.uk/public-image'+product.logo+'?s='+this.$route.params.id;

					
					EventBus.$emit('inject-meta', this.$route, this.meta)
			},
			existsInFavourites(id){
				this.injectMeta(this.categorydata);
				var findIndex = this.user.favourites.findIndex(item => item == id);
				if(findIndex > -1){
					this.favourites_status = true;
				}else{
					this.favourites_status = false;
				}

			},
			addToFavourites(id){
				this.loadingActionModal = true;
				axios.post('/customer-api/user/favourites/add-remove', {seller_id: id}, {headers: {'Authorization' : 'Bearer '+ this.user.api_token}}).then(response => {
					if(response.data[0] == 'add'){
						this.favourites_status = true;
						this.user.favourites = response.data[1];
					
						//this.user.favourites.push(id);

						
					}else if(response.data[0] == 'delete'){
						this.favourites_status = false;
						this.user.favourites = response.data[1];
					}
					var self = this;
                setTimeout(function(){
	                self.loadingActionModal = false;                
                }, 300);
				}).catch(error => {
					this.action_error = error.data;
				});
			},
			handleFavouritesResponse(){

			},
			checkUserLoggedIn(){
				axios.get('/customer-api/user').then(response => {
					console.log(response);
				}).catch( error => {
					console.log(error);
				})
			},
			loadAllCategories(){
				axios.get('/customer-api/product/categories?view=childs').then(response => {
					this.categoriesdata = response.data;
				}).catch(error => {
					console.log(error);
				});
			},
			reloadFilters: function(){
		    	this.loadingFilters = true; 
		    	var self = this;
                setTimeout(function(){
	                self.loadingFilters = false;                
                }, 500);
		  },
			loadSellerProducts(){
				this.createQueryString();
				this.productsLoading = true;
				//console.log(this.$route.path);
				var routepath = this.$route.path;
				if(routepath.includes('/profile')){
					var path = routepath.replace('/profile', '');
					console.log(path);
				}else{
					var path = routepath;
				}
				axios.get('/customer-api'+path+'/products'+this.query).then(response => {
					this.data = response.data;

				}).catch(error => {
					console.log(error);
				});
				var self = this;
                setTimeout(function(){
	                self.productsLoading = false;                
                }, 500);
			},
			loadSellerInfo(){
				axios.get('/customer-api'+this.$route.path+(this.$route.path.includes('/profile') ? '' : '/profile')).then(response => {
					this.categorydata = response.data;
					
					return this.existsInFavourites(response.data.id);
				}).catch(error => {
					console.log(error);
				});
			},
			loadSellerCollections(){
				axios.get('/customer-api/seller/'+this.$route.params.id+'/collections').then(response => {
					this.collections = response.data;
				}).catch(error => {
					console.log(error);
				});
			},
			loadSellerListings(){
				if(this.$attrs.id !== ''){
					var id = this.$attrs.id;
				}else{
					var id = this.$attrs.company.nickname;
				}
				if(this.$route.path.includes('/profile')){
					var path = this.$route.path.replace('/profile', '');
					console.log(path);
				}else{
					var path = this.$route.path;
				}
	      axios.get('/customer-api'+path+'/listings?page='+this.currentPage+'&limit='+this.listingslimit+'&sortby='+this.listingssortby+'&order='+this.order+'&search='+this.listingsearch+'&type_id='+this.type_id+'&active=true&public=&trade=').then(response => {
	      this.listingsData = response.data;
	      }).catch(error => {
	        this.shops_error = error.data;
	      });
    	},
			filterBrands(value){
		      this.brands = value;
		      //this.createQueryString();
		      this.loadSellerProducts();
		    },
		    filterCollections(value){
		        	this.searchcollections = value;
		          
		          //this.createQueryString();
		      		this.loadSellerProducts();
		  },
			createQueryString(){
				this.query = '?page='+this.currentPage+'&limit='+this.limit+'&sortby='+this.sortby+'&order='+this.order+'&search='+this.search+'&public_active='+this.public_active+'&active='+this.active+'&brands='+this.brands+'&collections='+this.searchcollections+'&categories='+this.categories+'&pricerange='+this.priceRange+'';
			},
			filterSelectedCategories(id){
		        
		          var exists = this.categories.findIndex(item => item == id);
		          console.log(exists);
		          if(exists == -1){
		            this.categories.push(id);
		          }else{
								this.categories.splice(exists, 1);
		          }
		          //this.createQueryString();
		      		this.loadSellerProducts();
		      },
		},
		watch: {
        $route() {
        	this.$nextTick(() => {
        		window.scrollTo(0,0);
        		this.loadSellerProducts();
        		this.loadSellerInfo();

        		
        	 });
            //this.$nextTick();
            let self = this;
			
         }  
    	},
	}

</script>

<template>
<div>
		<section >
		 <SocialShareBlock class="mt-4 pt-4" />
		  <div class="container-fullwidth" style="background: #fff">
		    <!--<PageHeader :title="title" :items="items" />-->

		    <b-row v-if="(categorydata.profile_banners !== null) && ('public' in categorydata.profile_banners)" class="mb-4 bg-primary bg-extra-soft" :style="'background-image: url(https://' + $route.params.id  + storageurl + categorydata.profile_banners.public +'); min-height: 400px; background-size: cover; background-position: center center; background-repeat: no-repeat'">

		      <b-col md="10" class="d-flex flex-column justify-content-center align-items-start mx-auto">
		      	
		      	<!--<div class="d-flex flex-row mt-4 pt-4">
		      	<img class="avatar-xl bg-white border p-2 rounded-circle my-auto mr-3" v-if="categorydata.cover_image == null" src="/images/product-placeholder.png">
		      	<img class="avatar-xl bg-white border p-2 rounded-circle my-auto mr-3" v-else :src="categorydata.cover_image">
		      	<div class="d-flex flex-column my-auto">
		      		<b-row class="">
				 	<b-col md="12" class="d-flex flex-row align-items-start mx-auto">
				 	<div  v-for="(bread, index) in breadcrumbs" class="d-flex flex-row">
		    		<span class="text-muted font-size-12 text-capitalize my-auto"><i class="bx bx-home my-auto font-size-10 mr-1" v-if="index == 0"></i>{{bread}}</span>
		    		 <i class="bx bx-chevron-right my-auto" v-if="index !== (breadcrumbs.length -1)"></i>
		    		</div>

				    	</b-col>
				    </b-row>
		        <h1 class="font-size-36 text-primary  text-capitalize">{{categorydata.name}}</h1>
		        <p class="w-75 mb-0" v-if="categorydata.description !== (null || '')">
		            {{categorydata.description}}
		          </p>
		      	</div>
		      </div>-->

		      </b-col>
		    </b-row>
		    <b-row v-else class="mb-4 bg-primary bg-extra-soft" style="min-height: 400px; background-position:  right bottom; background-size: 50%; background-repeat: no-repeat">

		      <b-col md="10" class="d-flex flex-column justify-content-center align-items-start mx-auto">
		      	
		      	<!--<div class="d-flex flex-row mt-4 pt-4">
		      	<img class="avatar-xl bg-white border p-2 rounded-circle my-auto mr-3" v-if="categorydata.cover_image == null" src="/images/product-placeholder.png">
		      	<img class="avatar-xl bg-white border p-2 rounded-circle my-auto mr-3" v-else :src="categorydata.cover_image">
		      	<div class="d-flex flex-column my-auto">
		      		<b-row class="">
				 	<b-col md="12" class="d-flex flex-row align-items-start mx-auto">
				 	<div  v-for="(bread, index) in breadcrumbs" class="d-flex flex-row">
		    		<span class="text-muted font-size-12 text-capitalize my-auto"><i class="bx bx-home my-auto font-size-10 mr-1" v-if="index == 0"></i>{{bread}}</span>
		    		 <i class="bx bx-chevron-right my-auto" v-if="index !== (breadcrumbs.length -1)"></i>
		    		</div>

				    	</b-col>
				    </b-row>
		        <h1 class="font-size-36 text-primary  text-capitalize">{{categorydata.name}}</h1>
		        <p class="w-75 mb-0" v-if="categorydata.description !== (null || '')">
		            {{categorydata.description}}
		          </p>
		      	</div>
		      </div>-->

		      </b-col>
		    </b-row>
		    <b-row class="seller-top-row mb-4 pb-4 pr-0">
		    	<b-row class="border-bottom pb-5 pr-0">
		    		<b-col lg="7" md="10" sm="10" class="ml-auto d-flex flex-column seller-avatar-block pr-0">
		    			<div class="avatar-xl profile-user-wid mb-2 mr-auto">
                  <img
                  	v-if="categorydata.logo" :src="'https://'+ ($route.params.id + storageurl + categorydata.logo).replaceAll('//', '/').replaceAll('https:/'+$route.params.id+'.s3.eu-west-2.amazonaws.com', '')+'?s='+$route.params.id+''"
                    alt
                    class="img-thumbnail rounded-circle avatar-xl"
                  />
                   <img
                  	v-else 
                    src="/images/product-placeholder.png"
                    alt
                    class="img-thumbnail rounded-circle avatar-xl"
                  />
                </div>
		    		
		        <h1 class="section-info-header position-relative mr-auto">{{categorydata.name}}</h1>
		        <p class="w-100 mb-0 px-lg-0 px-md-4 px-4" v-if="categorydata.description !== (null || '')">
		            {{categorydata.description}}
		          </p>
		        </b-col>
		        <b-col lg="3" md="10" sm="10" class="mr-auto seller-info-block pr-0">

		        	<div class="profile-user-wid mb-2 mr-auto w-100 px-3">
		        		<b-card class="border-radius-1em">
		        			<h5 class="text-center mb-1">Seller Rating</h5>
		        			<!--<p  class="text-muted my-3 text-center" @click="infomodalaction = 'reviews'" v-b-modal.info-modal>
			                    <span class="bx bx-star font-size-18 text-warning"></span>
			                    <span class="bx bx-star font-size-18 text-warning ml-1"></span>
			                    <span class="bx bx-star font-size-18 text-warning ml-1"></span>
			                    <span class="bx bx-star font-size-18 text-warning ml-1"></span>
			                    <span class="bx bx-star font-size-18 ml-1"></span>
			                    <br><small>
			             	 / 5 (1,000 reviews)
			             </small>
			                  </p>-->

			                  <p  class="text-muted mb-3 text-center mx-4 px-3" @click="infomodalaction = 'reviews'" v-b-modal.info-modal>
			                    <b-form-rating id="rating-inline" variant="warning" class="text-center m-auto" no-border size="md" v-model="categorydata.reviews_avg_rating" style="max-width: 200px;" readonly></b-form-rating>
			                    <small class="d-block mt-2">
			             	Rated {{categorydata.reviews_avg_rating !== null ? parseFloat(categorydata.reviews_avg_rating).toFixed(2) : 0}} / 5 from {{categorydata.reviews.length}} review(s)
			             </small>
			                  </p>
			            
		        			<a class="btn btn-outline-light btn-rounded btn-block mb-2" v-b-modal.modal-1 @click="form.action = 'message'">Message Contact <i class="bx bx-mail-send text-primary"></i></a>
		        			<a class="btn btn-outline-light btn-rounded btn-block mb-2" v-b-modal.modal-1 @click="form.action = 'favourites', addToFavourites(categorydata.id)">

		        				<span v-if="favourites_status == true">Remove From</span><span v-else-if="favourites_status == false">Add to</span> Favourites <i class="mdi mdi-heart-outline text-danger"></i></a>
		        			<a class="btn btn-outline-light btn-rounded btn-block mb-2"  v-b-modal.modal-1 @click="form.action = 'review'">Submit Review <i class="bx bx-star text-warning"></i></a>
		        			<a class="btn btn-outline-light btn-rounded btn-block mb-2"  v-b-modal.listings-modal @click="loadSellerListings(), showStores = !showStores"> Stores <i class="bx bxs-store text-primary"></i></a>
		        	
		        		</b-card>
		        	</div>
		        </b-col>
		    	</b-row>
		  	</b-row>
		    <!-- end row -->
		  </div>
		</section>
		<section >
			<b-container fluid>
				<b-row v-if="collections.length > 0" >
				<b-col md="12" class="d-flex my-4 ">
				<h2 class="product-info-header">Collections</h2>
				<hr class="mb-4 ml-3 flex-grow-1">
			</b-col>
		</b-row>
				<b-row v-if="collections.length > 0">
					<b-col class="overflow-hidden">
						<swiper ref="collectionCarouselSwiper" :options="swiperOptions2">
                    <swiper-slide v-for="(item, index) in collections" >
                       
                             


                             <b-card class="card overflow-hidden border-radius-1em shadow-sm seller-profile-card" no-body >
          <div v-if="item.cover_image !== null" class="bg-soft bg-light" :style="'background-size: cover; background-position: center center; background-image: url(' + ('https://' + item.company.nickname + '.s3.eu-west-2.amazonaws.com/' + item.cover_image +'?s='+item.company.nickname) + ')'">
            <div class="row">
              <transition name="fade-left">
                          <div class="col-md-12 position-absolute z-index-2"> 
                                <router-link :to="'/seller/'+item.company.nickname+'/profile'" class="mt-3 ml-3 btn btn-primary font-size-16 btn-rounded ml-auto float-left" v-b-tooltip.hover.right title="View Company" ><i class="mdi mdi-eye-outline"></i></router-link>
                              </div>
                          </transition>
                           
                       <div class="col-12">
                        <router-link :to="'/seller/'+item.company.nickname+'/collection/'+item.slug">
                          <div  style="min-height: 240px;"></div>
                        </router-link>
                 <transition name="fade-right">
                            <div class="mt-auto mb-3 mx-2 d-flex position-absolute bottom-0 right-0 left-0" v-if="hoverIndex == index">
                                <b-form-rating style="width: 30%;" id="rating-inline" readonly variant="warning" class="bg-transparent pr-4 ml-auto" inline no-border size="sm" :value="item.reviews_avg_rating"></b-form-rating> 
                        </div>
                      </transition>
              </div>
            </div>
          </div>
           <div v-else class="bg-soft bg-light">
            <div class="row">
              <transition name="fade-left">
                          <div class="col-md-12 position-absolute z-index-2" v-if="hoverIndex == index"> 
                                <router-link :to="'/seller/'+item.company.nickname+'/profile'" class="mt-3 ml-3 btn btn-primary font-size-16 btn-rounded ml-auto float-left" v-b-tooltip.hover.right title="View Company" ><i class="mdi mdi-eye-outline"></i></router-link>
                              </div>
                          </transition>
                           
                       <div class="col-12">
                        <router-link :to="'/seller/'+item.company.nickname+'/profile'">
                          <div  style="min-height: 240px;"></div>
                        </router-link>
                
              </div>
            </div>
          </div>
          <b-card-body class="card-body pt-0">
            <div class="row">
              <div class="col-sm-12 pb-4 text-center">
                <div class="avatar-md profile-user-wid mb-2 mx-auto" style="margin-top: -45px !important;">
                  <img
                    v-if="item.image" :src="item.image.includes(storageurl) == false ? ('https://' + item.company.nickname + '.s3.eu-west-2.amazonaws.com/' + item.image+'?s='+item.company.nickname) : item.image+'?s='+item.company.nickname"
                    alt
                    class="img-thumbnail rounded-circle"
                  />
                   <img
                    v-else 
                    src="/images/product-placeholder.png"
                    alt
                    class="img-thumbnail rounded-circle"
                  />
                </div>
                <h4 class="mb-0 text-truncate">{{item.name}}</h4>
                

                <h6 class="text-primary cursive-title mt-4 mb-0 font-size-20">Collection By</h6>
                <p class="mb-0"><router-link class="mb-0 text-muted" :to="'/seller/'+item.company.nickname+'/profile'">
                  {{item.company.name}}
                </router-link></p>
              </div>
              

            </div>
          </b-card-body>
        </b-card>
                           
            </swiper-slide>
          </swiper>
					</b-col>
				</b-row>
			</b-container>
			<b-container fluid>
					<b-row >
				<b-col md="12" class="d-flex my-4 ">
				<h2 class="product-info-header">Products</h2>
				<hr class="mb-4 ml-3 flex-grow-1">
			</b-col>
		</b-row>
				 <b-row class="pb-4">
				 	<!--<b-col md="12" class="d-flex flex-row align-items-start mx-auto">
				 	<div  v-for="(bread, index) in breadcrumbs" class="d-flex flex-row">
		    		<span class="badge badge-light text-dark font-size-12 text-capitalize my-auto">{{bread}}</span>
		    		 <i class="bx bx-chevron-right my-auto" v-if="index !== (breadcrumbs.length -1)"></i>
		    		</div>

		    	</b-col>-->
		    <transition name="fade-filtersleft">
		     	<b-col md="3" v-if="showFilters">
          <div class="col-lg-12 pt-1 mt-4">
        <div>
          <div class="card p-4 rounded-4 shadow-sm border-radius-1em mt-4">
            <!--<h4 class="card-title mb-4">Filter</h4>-->
            <div>
               <div class="mb-3">
              <h5 class="mb-3">Price</h5>
              <vue-slider v-model="priceRange" :min="0" :max="100 + priceRange[1]"  @drag-end="loadSellerProducts()" ></vue-slider>
              <b-form-group label="Min Price" class="w-50 d-inline float-left mt-2 mb-3 px-2">
              <b-form-input
                type="number"
                v-model="priceRange[0]"
                @input="loadSellerProducts"></b-form-input>
              </b-form-group>
              <b-form-group label="Max Price" class="w-50 d-inline float-left mt-2 mb-3 px-2">
                 <b-form-input
                 label="Max Price"
                 type="number"
                 v-model="priceRange[1]"
                 @input="loadSellerProducts"></b-form-input>
              </b-form-group>
            </div>
            		<div v-if="collections.length > 0">
            		<h5 class="my-3">Collections</h5>
            			<FilterPublicProductCollections :brands="collections" v-on:brands-filtered="filterCollections"></FilterPublicProductCollections>
            		</div>
                <h5 class="my-3">Categories</h5>
                    <b-overlay :show="loadingFilters">
                <FilterPublicProductCategories  v-if="loadingFilters == false" :category_data="categoriesdata" ref="categoryfilter"></FilterPublicProductCategories>
              </b-overlay>
                <!--<FilterListTree></FilterListTree>-->
                <!--<FilterPublicProductCategories  :category_data="categoriesdata"></FilterPublicProductCategories>
                <h5 class="font-size-14 mb-3">Brands</h5>
               <FilterPublicProductBrands v-on:brands-filtered="filterBrands"></FilterPublicProductBrands>
               -->

                 <h5 class="mb-3">Brands</h5>
               <FilterPublicProductBrands v-on:brands-filtered="filterBrands"></FilterPublicProductBrands>
                <h5 class="my-3">Gemstone</h5>
                <b-form-group>
                	<b-form-checkbox-group v-model="keywordslist" @change="loadCategory()" :options="productGemstones"></b-form-checkbox-group>
                </b-form-group>
                <h5 class="my-3">Metal</h5>
                <b-form-group>
                	<b-form-checkbox-group v-model="keywordslist"  @change="loadCategory()" :options="productMetals"></b-form-checkbox-group>
                </b-form-group>
                <h5 class="my-3">Condition</h5>
                <b-form-group>
                	<b-form-checkbox-group v-model="condition"  @change="loadCategory()" :options="productConditions"></b-form-checkbox-group>
                </b-form-group>
            </div>
           

          <!-- <div class="mt-4 pt-3">
              <h5 class="font-size-14 mb-3">Customer Rating</h5>
              <div>
                <b-form-checkbox
                  id="checkbox-1"
                  name="checkbox-1"
                  value="accepted"
                  unchecked-value="not_accepted"
                >
                  4
                  <i class="bx bx-star text-warning"></i> & Above
                </b-form-checkbox>

                <b-form-checkbox
                  id="checkbox-2"
                  class="mt-2"
                  name="checkbox-2"
                  value="accepted"
                  unchecked-value="not_accepted"
                >
                  3
                  <i class="bx bx-star text-warning"></i> & Above
                </b-form-checkbox>

                <b-form-checkbox
                  id="checkbox-3"
                  class="mt-2"
                  name="checkbox-3"
                  value="accepted"
                  unchecked-value="not_accepted"
                >
                  2
                  <i class="bx bx-star text-warning"></i> & Above
                </b-form-checkbox>
                <b-form-checkbox
                  id="checkbox-4"
                  class="mt-2"
                  name="checkbox-4"
                  value="accepted"
                  unchecked-value="not_accepted"
                >
                  1
                  <i class="bx bx-star text-warning"></i>
                </b-form-checkbox>
              </div>
            </div>-->
          </div>
          <h6>Advertisement</h6>
          <div class="card shadow-sm border-radius-1em">
              <img src="/images/banner-placeholder.png" class="w-100">
            </div>
        </div>
      </div>
          </b-col>
      </transition>
      <transition name="fade-right">
					<b-col class="" :class="{'col-md-9 fade-right-in' : showFilters == true, 'col-md-12 fade-right-exit' : showFilters == false, }">
						<div class="row mb-3">
          
        <div class="col-lg-7 col-sm-9 order-lg-1 order-md-1 order-sm-1 d-flex">
            <div class="mt-sm-0 form-inline w-100" >
              <div class="search-box mr-2 ml-auto">
                <div class="position-relative">
                  <input
                    type="text"
                    class="form-control w-100 border"
                    placeholder="Search name, reference or description..."
                    v-model="search"
                    @keyup.enter="loadSellerProducts()"
                  />
                  <i class="bx bx-search-alt search-icon"></i>
                </div>
              </div>
            </div>
          </div>

         
           <div class="col-lg-2 col-sm-12 order-lg-2 order-md-5 order-sm-5 d-flex">
          <form class="my-auto mx-auto">
            <small class="mx-auto my-2 text-center d-block">Results {{data.from}} - {{data.to}} of {{data.total}}</small>
          </form>
        </div>
       
         <div class="col-lg-2 col-sm-3 order-lg-3 order-md-2 order-sm-2 d-flex">
            <div class="d-flex ml-auto">
              <ul class="nav nav-pills product-view-nav">
                <li class="nav-item">
                  <a class="nav-link d-none d-lg-block" :class="{'active': showFilters == true }" href="javascript: void(0);" @click="loadSellerCollections(), showFilters = !showFilters, expanded = !expanded">
                    <i class="bx bx-filter-alt"></i>
                  </a>
                  <a class="nav-link d-lg-none d-md-block" :class="{'active': showFiltersSm == true }" href="javascript: void(0);" @click="loadSellerCollections(), showFiltersSm = !showFiltersSm">
                    <i class="bx bx-filter-alt"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': view == 'grid' }" href="javascript: void(0);" @click="view = 'grid'">
                    <i class="bx bx-grid-alt"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': view == 'list' }" href="javascript: void(0);" @click="view = 'list'">
                    <i class="bx bx-list-ul"></i>
                  </a>
                </li>
              </ul>
              <div class="text-sm-right">
                  
                </div>
          </div>
        </div>
        <div class="col-xl-1 col-lg-1 col-md-12 order-lg-4 order-md-4 order-sm-4">
            <div class="my-auto">
              <b-form-select class="form-control border btn-rounded" v-model="limit" :options="limits" @input="loadSellerProducts()"></b-form-select>
            </div>
          </div>
           <b-col md="12" v-if="showFiltersSm" class="d-lg-none order-lg-5 order-md-3 order-sm-3">
          <div class="col-lg-12 pt-1 mt-4">
        <div class="widget-scrollbox border-radius-1em border" style="overflow-x: hidden; min-height: 300px;">
          <div class="card p-4 rounded-4 shadow-none  mt-4">
            <!--<h4 class="card-title mb-4">Filter</h4>-->
            <div>
               <div class="mb-3">
              <h5 class="mb-3">Price</h5>
              <vue-slider v-model="priceRange" :min="0" :max="100 + priceRange[1]"  @drag-end="loadCategory()" ></vue-slider>
              <b-form-group label="Min Price" class="w-50 d-inline float-left mt-2 mb-3 px-2">
              <b-form-input
                type="number"
                v-model="priceRange[0]"
                @input="loadCategory"></b-form-input>
              </b-form-group>
              <b-form-group label="Max Price" class="w-50 d-inline float-left mt-2 mb-3 px-2">
                 <b-form-input
                 label="Max Price"
                 type="number"
                 v-model="priceRange[1]"
                 @input="loadCategory"></b-form-input>
              </b-form-group>
            </div>
                <h5 class="my-3">Jewellery Type</h5>
                <!--<FilterListTree></FilterListTree>-->
                <b-overlay :show="loadingFilters">
                <FilterPublicProductCategories  v-if="loadingFilters == false" :category_data="categoriesdata" ref="categoryfiltersm"></FilterPublicProductCategories>
              </b-overlay>
                <h5 class="font-size-14 mb-3">Brands</h5>
               <FilterPublicProductBrands v-on:brands-filtered="filterBrands"></FilterPublicProductBrands>
                <h5 class="my-3">Gemstone</h5>
                <b-form-group>
                	<b-form-checkbox-group v-model="keywordslist" @change="loadCategory()" :options="productGemstones"></b-form-checkbox-group>
                </b-form-group>
                <h5 class="my-3">Metal</h5>
                <b-form-group>
                	<b-form-checkbox-group v-model="keywordslist"  @change="loadCategory()" :options="productMetals"></b-form-checkbox-group>
                </b-form-group>
                <h5 class="my-3">Condition</h5>
                <b-form-group>
                	<b-form-checkbox-group v-model="condition"  @change="loadCategory()" :options="productConditions"></b-form-checkbox-group>
                </b-form-group>
            </div>
          
          </div>
          
        </div>
      </div>
          </b-col>
      </div>
						<b-overlay
			        :show="productsLoading"
			        variant="white"
			        opacity="0.85"
			        blur="1px"
			        rounded="xl">
			          <PublicMarketplaceProductList :product_list="data.data" :expanded="expanded" :layouttoggle="false" :view="view" :marketplace="'public'" :selected_currency="$attrs.selected_currency" :selected_currency_symbol="$attrs.selected_currency_symbol" :exchange_rates="$attrs.exchange_rates" ></PublicMarketplaceProductList>
			      </b-overlay>
					</b-col>
				</transition>
							<b-col md="12">
					<b-pagination v-if="(data.data !== null)" v-model="currentPage" :total-rows="data.total" :per-page="data.per_page" align="center"  @input="loadSellerProducts()"></b-pagination>
					</b-col>
			</b-row>
			<b-row >
				<b-col md="12" class="d-flex my-4 ">
				<h2 class="product-info-header">Reviews</h2>
				<hr class="ml-3 mb-4 flex-grow-1">
			</b-col>
			<b-col md="12" v-if="categorydata !== ''">
				<b-row class="mb-4" v-if="categorydata.reviews.length > 0"  >
					<b-col>
						<ReviewCarousel :items="categorydata.reviews" :swiperOptions="reviewOptions"></ReviewCarousel>
					</b-col>
					<b-col md="12" class="d-flex">
						<a class="ml-auto btn btn-light btn-rounded" href="javascript:;" v-b-modal.modal-1 @click="form.action = 'review'">Write Review</a>
					</b-col>
				</b-row>
				<b-row class="mb-4" v-else>
					<b-col md="12" class="text-center py-4">
						<i class="bx bx-star text-warning d-inline-block font-size-14"></i>
						<i class="bx bx-star text-warning d-inline-block font-size-20"></i>
						<i class="bx bx-star text-warning d-inline-block font-size-28"></i>
						<i class="bx bx-star text-warning d-inline-block font-size-20"></i>
						<i class="bx bx-star text-warning d-inline-block font-size-14"></i>
						<h4 class="text-muted">
							No Reviews
						</h4>
						<!--<a class="text-primary" href="javascript:;">
							Be the first to review this product
						</a>-->
						<a class="btn btn-light btn-rounded mb-2"  v-b-modal.modal-1 @click="form.action = 'review'">Be the first to review this seller <i class="bx bx-star text-warning"></i></a>
					</b-col>
				</b-row>
			</b-col>
			</b-row>
			</b-container>
		</section>
		<b-modal id="info-modal" centered content-class="border-radius-1em" header-class="border-none" size="lg" hide-footer>
			<b-card-body class="card-body pt-0">
				 <div class="row">
            	<div class="col-sm-12 pb-4 text-center mb-4">
            		<h3 class="text-capitalize">{{infomodalaction}} for:</h3>
            	</div>
              <div class="col-sm-12 pb-4 text-center border border-radius-1em">
                <div class="avatar-md profile-user-wid mb-2 mx-auto">
                  <img
                  	v-if="categorydata.logo" :src="'https://' + ( $route.params.id + storageurl + categorydata.logo).replaceAll('//', '/').replaceAll('https:/'+$route.params.id+'.s3.eu-west-2.amazonaws.com', '')+'?s='+$route.params.id+''"
                    alt
                    class="img-thumbnail rounded-circle"
                  />
                   <img
                  	v-else 
                    src="/images/product-placeholder.png"
                    alt
                    class="img-thumbnail rounded-circle"
                  />
                </div>
                <h5 class="font-size-15 text-truncate">{{categorydata.name}}</h5>
              </div>
           

            </div>
             <div class="row">
		       <b-card class="border-radius-1em border shadow-none mt-3 widget-scrollbox" body-class="pt-4 px-2 pb-1">
							<b-row v-if="infomodalaction == 'reviews'" >
								<b-col v-for="item in categorydata.reviews" md="6">
									<ReviewCard :review="item"></ReviewCard>
								</b-col>
							</b-row>
					</b-card>
			</div>
			<a class="btn btn-outline-light btn-rounded btn-block mb-2"  v-b-modal.modal-1 @click="form.action = 'review'">Submit Review <i class="bx bx-star text-warning"></i></a>
			</b-card-body>
		</b-modal>
		<SideSlidePanel :editPanel="showStores" :panelClass="'edit-sidepanel position-fixed'" style="top: 70px" @close-panel="showStores = false">
			<b-row>
				<b-col md="12" class="px-0">
					<div class="bg-light w-100" style="height: 350px;"> <gmap-map :center="mapcenter" :zoom="mapzoom" style="height: 350px">
              <gmap-marker
                v-for="(shopslisting, index) in listingsData"
                :key="index"
                v-if="shopslisting.gplace_id !== null && shopslisting.gplace_id !== ''"
                :position="{lat: parseFloat(shopslisting.latitude), lng: parseFloat(shopslisting.longitude)}"
                :clickable="true"
                :draggable="true"
                @click="mapcenter = {lat: parseFloat(shopslisting.latitude), lng: parseFloat(shopslisting.longitude)}, mapzoom = 11"
              ></gmap-marker>
            </gmap-map> </div>
				</b-col>
				<b-col md="12">
			<b-card class="shadow-none">
	        		<h4>Stores & Locations</h4>
	        		<div class="mt-sm-0 form-inline w-100" >
              <div class="search-box mr-2 ml-auto my-3">
                <div class="position-relative">
                  <input
                    type="text"
                    class="form-control w-100 border-0 bg-light"
                    placeholder="Search name, reference or description..."
                    v-model="listingsearch" @keydown.enter="loadSellerListings()" 
                  />
                  <i class="bx bx-search-alt search-icon"></i>
                </div>
              </div>
            </div>
	               <ul class="list-group">
                            <li class="list-group-item" v-if="listingsData.length > 0" v-for="shopslisting in listingsData">
                                <div class="media">
                                    <div class="avatar-sm me-3" v-if="shopslisting.logo">
                                        <span
                                            class="avatar-title rounded-circle bg-light"
                                        >
                                            <img
                                            		
                                                :src="shoplisting.logo.includes(storageurl) == false ? (storageurl + shoplisting.logo) : shoplisting.logo"
                                                alt=""
                                                height="18"
                                            />
                                            
                                        </span>
                                      </div>
                                       <div class="avatar-sm me-3" v-else>
                                        <span class="avatar-title rounded-circle" >{{(shopslisting.name).slice(0,1)}}</span>
                                      </div>
                                   
                                    <div class="media-body">
                                        <h5 class="mb-0">
                                            {{shopslisting.name}}
                                        </h5>
                                        <p class="text-muted my-2">
                                            <span v-if="shopslisting.address_1">{{shopslisting.address_1}},</span>
				          	<span v-if="shopslisting.address_2">{{shopslisting.address_2}},</span>
				          	<span v-if="shopslisting.address_3">{{shopslisting.address_3}},</span>
				          	<span v-if="shopslisting.county">{{shopslisting.county.name}},</span>
				          	<span v-if="shopslisting.country">{{shopslisting.country.name}},</span>
				          	<span v-if="shopslisting.postcode">{{shopslisting.postcode}}</span>
                                        </p>

                                        <div class="float-end">
                                            <p class="text-muted mb-0" v-if="shopslisting.listing_type">
                                                <i 
                                                    class="bx bx-buildings me-1"
                                                ></i>
                                                {{shopslisting.listing_type.name}}
                                            </p>
                                        </div>
                                        <p class="text-muted mb-0">
                                            <a class="btn btn-light btn-rounded" href="javascript:;" @click="listing = shopslisting, $bvModal.show('listing-info')" >See Location</a>
                                        </p>
                                    </div>
                                </div>
                            </li>
                          </ul>
	        	</b-card>
	        </b-col>
	       </b-row>
		</SideSlidePanel>
		<b-modal id="modal-1" centered content-class="border-radius-1em" header-class="border-none" hide-footer>
			<b-card-body class="card-body pt-0">
            <div class="row">
            	<div class="col-sm-12 pb-4 text-center mb-4">
            		<h3>You are about to {{form.action}}</h3>
            	</div>
              <div class="col-sm-12 pb-4 text-center border border-radius-1em">
                <div class="avatar-md profile-user-wid mb-2 mx-auto">
                  <img
                  	v-if="categorydata.logo" :src="'https://' + ( $route.params.id + storageurl + categorydata.logo).replaceAll('//', '/').replaceAll('https:/'+$route.params.id+'.s3.eu-west-2.amazonaws.com', '')+'?s='+$route.params.id+''"
                    alt
                    class="img-thumbnail rounded-circle"
                  />
                   <img
                  	v-else 
                    src="/images/product-placeholder.png"
                    alt
                    class="img-thumbnail rounded-circle"
                  />
                </div>
                <h5 class="font-size-15 text-truncate">{{categorydata.name}}</h5>
              </div>
           

            </div>
          </b-card-body>


    <div v-if="form.action == 'message'">
    	<CustomerSellerEnquiry :token="$attrs.recaptcha_token" :route="'/customer-api/seller/'+this.$route.params.id+'/enquiry'"></CustomerSellerEnquiry>
    </div>

    <b-form v-if="(form.action == 'review')  && (user)" class="px-2">
    	<ReviewForm v-if="(form.action == 'review')  && (user)" class="px-2" :company_id="categorydata.id" :product_id="''" :service_id="''" :source="'public'" :api_token="user.api_token" :user="user" :review_type="'company'">
    </ReviewForm>
    
    	
    </b-form>
   <b-form v-if="(form.action == 'favourites')  && (user !== '')" class="px-2">
    	<b-overlay :show="loadingActionModal">
    		<b-card class="border-radius-1em shadow-none border" body-class="d-flex flex-column justify-content-center align-items-center py-3" v-if="favourites_status == true">
    			<i class="bx bx-check-circle font-size-48 text-success rounded-circle my-3"></i>
    			<h5>Shop Added to Favourites</h5>

    		</b-card>
    		<b-card class="border-radius-1em shadow-none border" body-class="d-flex flex-column justify-content-center align-items-center py-3" v-else-if="favourites_status == false">
    			<i class="bx bx-x-circle font-size-48 text-danger rounded-circle my-3"></i>
    			<h5>Shop Removed from Favourites</h5>

    		</b-card>
    	</b-overlay>
    </b-form>

    <b-form v-if="((form.action == 'favourites') || (form.action == 'review')) && (!user)" class="px-2">
    	<!--<a class="btn btn-outline-light btn-rounded btn-block mb-2" target="_blank">Sign In</a>-->
    	<Login submit-url="https://gemesys.co.uk/my-account/login" auth-error="">
             <input type="hidden" name="_token" :value="csrf">
        </Login>
        
    </b-form>
  </b-modal>
  	<b-modal :id="'listing-info'" hide-footer hide-header content-class="border-none border-radius-1em overflow-hidden" body-class="p-0" size="lg" centered>
  		<b-overlay :show="listing == ''"> 
  			<div v-if="listing !== ''">
		<a class="btn btn-light btn-rounded position-absolute z-index-2 top-0 right-0 m-3" @click="$bvModal.hide('listing-info')">
			<i class="mdi mdi-close"></i>
		</a>
		<gmap-map id="listing-map" :center="{ lat: parseFloat(listing.latitude), lng: parseFloat(listing.longitude) }" :zoom="7" style="height: 350px" class="border-radius-1em">
                  <gmap-marker

                    :position="{ lat: parseFloat(listing.latitude), lng: parseFloat(listing.longitude) }"
                    :clickable="true"
                    :draggable="true"
                    @click="center = { lat: parseFloat(listing.latitude), lng: parseFloat(listing.longitude) }"
                  ></gmap-marker>
                </gmap-map>
       <b-row>
       	<b-col>
       		<b-card class="shadow-none border-none mb-0 p-4">
       			<div class="d-flex">
       						<h4 class="section-info-header position-relative mr-auto font-size-36">{{listing.name}}</h4>
       						
       			</div>
       			<div class="d-flex">
       		<p class="text-muted my-1 d-flex flex-column">
       						<span class="my-auto mr-2"><i class="bx bx-home font-size-20 text-primary"></i></span>
                	<span class="my-auto mr-1" v-if="listing.address_1 !== null">{{listing.address_1}},</span>
                	<span class="my-auto mr-1" v-if="listing.address_2 !== null">{{listing.address_2}},</span>
                	<span class="my-auto mr-1" v-if="listing.address_3 !== null">{{listing.address_3}},</span>
                	<span class="my-auto mr-1" v-if="listing.city !== null">{{listing.city}},</span>
                	<span class="my-auto mr-1" v-if="listing.county !== null">{{listing.county.name}},</span>
									<span class="my-auto mr-1" v-if="listing.postcode !== null">{{listing.postcode}}</span>
                	<span class="my-auto mr-1" v-if="listing.country !== null">{{listing.country.name}}</span>
                	
                </p>
                <a class="btn btn-light btn-rounded ml-auto mb-auto" :href="'https://www.google.com/maps/dir/?api=1&destination='+listing.address_1+' '+listing.address_2+''+listing.postcode+'&destination_place_id='+listing.gplace_id+'&zoom=12'" target="_blank">
                		Get Directions
                	</a>
              </div>
                <div class="d-flex mt-3" >
                	<div class="d-flex flex-column">
                	<span class="my-auto"><i class="bx bx-phone text-primary font-size-20"></i></span>
                	<span class="my-auto ml-2 text-muted">{{listing.phone}}</span>
                	</div>
                	<a class="btn btn-light btn-rounded ml-auto mb-auto" v-if="listing.phone !== '' && listing.phone !== null" :href="'tel:'+listing.phone">
                	 Call Now
                	</a>
                </div>
                	<!--<p  class="text-muted ml-auto my-auto mt-3 mr-3">
			                    <span class="bx bx-star font-size-14 text-warning"></span>
			                    <span class="bx bx-star font-size-14 text-warning ml-1"></span>
			                    <span class="bx bx-star font-size-14 text-warning ml-1"></span>
			                    <span class="bx bx-star font-size-14 text-warning ml-1"></span>
			                    <span class="bx bx-star font-size-14 ml-1"></span>
			                  </p>
			           <h6>Location Reviews</h6>
			           <div v-if="listing.reviews.length > 0">
					           <simplebar style="max-height: 380px;">       	
					            <b-card class="border-radius-1em border shadow-none">
					            		<b-list-group>
					            			<b-list-group-item v-for="review in listing.reviews">
					            			</b-list-group-item>
					            		</b-list-group>
					            </b-card>
					          </simplebar>
			        		</div>
			        		<div v-else>
			        			<b-card class="border-radius-1em border shadow-none" body-class="text-center">
			        						<i class="bx bx-star text-warning d-inline-block font-size-14"></i>
													<i class="bx bx-star text-warning d-inline-block font-size-20"></i>
													<i class="bx bx-star text-warning d-inline-block font-size-28"></i>
													<i class="bx bx-star text-warning d-inline-block font-size-20"></i>
													<i class="bx bx-star text-warning d-inline-block font-size-14"></i>
													<h4 class="text-muted">
														No Reviews
													</h4>
			        			</b-card>
			        		</div>-->
           </b-card>
       	</b-col>
       </b-row>
     </div>
   </b-overlay>
	</b-modal>
</div>
</template>