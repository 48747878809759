<template>
<b-card no-body class="overflow-hidden news-card shadow-none border-radius-1em border" @mouseenter="hoverIndex = $attrs.index" @mouseleave="hoverIndex = -1" >

		                    <b-row no-gutters>
		                     <transition name="fade-left">
		                    	<div class="col-md-6 position-absolute z-index-2" v-if="hoverIndex == $attrs.index"> 
                              	<a id="tooltip-add" v-b-modal.product-info @click="quickviewproduct = product" class="mt-3 ml-3 btn btn-primary font-size-16 btn-rounded ml-auto float-left"><i class="mdi mdi-eye-outline"></i></a>
                              </div>
                          </transition>
                           <transition name="fade-right">
		                    	<div class="col-md-6 position-absolute z-index-2 right-0 d-flex" v-if="hoverIndex == $attrs.index"> 
                              	<span class="badge badge-primary bg-primary font-size-12  ml-auto my-4 mr-1" v-b-tooltip.hover.left title=""><a @click="copyLink(product.reference)" href="javascript:;" class="text-white">{{product.reference}}</a></span>
                              </div>
                          </transition>

		                      <b-col lg="12">
		                        <a class="" :href="'/trade/profile/'+product.company.nickname+'/'+product.url_rewrite"> 
		                        	<b-card-img-lazy v-if="(product.cover_image) && ('gallery' in product.cover_image)"class="rounded-0" :src="'https://'+product.company.nickname+storageurl+product.cover_image.gallery.replaceAll('//', '/')" ></b-card-img-lazy>
                              	<b-card-img-lazy v-else-if="(product.cover_image) && ('thumb' in product.cover_image)"class="rounded-0" :src="'https://'+product.company.nickname+storageurl+product.cover_image.thumb.replaceAll('//', '/')" ></b-card-img-lazy>
                              	<b-card-img-lazy v-else-if="(product.cover_image) && ('src' in product.cover_image)"class="rounded-0" :src="'https://'+product.company.nickname+storageurl+product.cover_image.src.replaceAll('//', '/')" ></b-card-img-lazy>
		                        		<b-card-img-lazy v-else :src="'/images/product-placeholder.png'" alt="Image" class="rounded-0"></b-card-img-lazy>
		                      </a>
		                     <transition name="fade-right">
		                        <div class="mt-auto mb-3 mx-2 d-flex position-absolute bottom-0 right-0 left-0" v-if="hoverIndex == $attrs.index">
                              	<a id="tooltip-add"  @click="selectedCategory = list, showStoneDetails = 1"class="mr-2 ml-auto btn btn-primary font-size-16 btn-rounded"><i class="mdi mdi-heart-outline"></i></a>
                              	<a id="tooltip-add"   @click="addToCart(product, 1)" class="mr-3 ml-0 btn btn-primary font-size-16 btn-rounded"><i class="mdi mdi-shopping-outline"></i></a>
              					</div>
              				</transition>
		                      </b-col>
		                      <b-col lg="12">
		                      	<a class="" style="color: unset;" :href="'/trade/profile/'+product.company.nickname+'/'+product.url_rewrite"> 
		                        <b-card-body>
		                        	<h4 class="card-title d-flex" :class="{'text-truncate font-size-14' : truncate_titles == true}">{{product.name}}</h4>
		                          <b-card-text>
		                             <p class="text-muted paragraph-truncate-line2" v-html="product.short_description"></p>
		                           

		                          </b-card-text>
		                          
		                          <div class="d-flex flex-row">
		                        <span class="badge badge-light my-auto mr-auto bg-light p-2 font-size-12 border-radius-1em" v-if="product.tax_rule == null">
		                         	{{$attrs.selected_currency_symbol}} {{(product.trade_price  * (1 / $attrs.exchange_rates[product.company.trading_currency_code])).toFixed(2)}}
		                         </span>
		                         <span class="badge badge-light my-auto mr-auto bg-light p-2 font-size-12 border-radius-1em" v-else>
		                         	{{$attrs.selected_currency_symbol}} {{(addTax(product, product.trade_price) * (1 / $attrs.exchange_rates[product.company.trading_currency_code])).toFixed(2)}}
		                         </span>
		                         <transition name="fade-right">
		                         <p  v-if="hoverIndex == $attrs.index" class="text-muted float-right ml-auto my-auto">
			                    <span class="bx bx-star font-size-14 text-warning"></span>
			                    <span class="bx bx-star font-size-14 text-warning ml-1"></span>
			                    <span class="bx bx-star font-size-14 text-warning ml-1"></span>
			                    <span class="bx bx-star font-size-14 text-warning ml-1"></span>
			                    <span class="bx bx-star font-size-14 ml-1"></span>
			                  </p>
			              			</transition>
			              		</div>
		                        </b-card-body>
		                      </a>
		                      </b-col>
		                    </b-row>
		                  </b-card>
		                 </template>

<script type="text/javascript">
	export default{
		props: {
			company: {
				type: Object,
				default: null
			},
			product: {
				type: Object,
				default: null,
			},
			truncate_titles: {
				type: Boolean,
			default: false,
			}
		},
		data(){
			return {
				storageurl: '.s3.eu-west-2.amazonaws.com',
				storagereq: '',
				hoverIndex: -1,
				hoverLink: -1,
				categories: []
			}
		},
		methods: {
			addTax(product, price){
        		var tax_rule = product.tax_rule;
        		console.log(tax_rule);
		    	if(this.$attrs.user_company !== null && this.$attrs.user_company !== ''){
		    		var country_id = this.$attrs.user_company.country_id;
		    	}else{
		    		var country_id = product.company.country_id;
		    	}
		    	if(tax_rule !== null && tax_rule.country_rules.length > 0){
		    		var selected_rule = null;
		    		var selected_ruleIndex = -1;
			    	for(var ti = 0; ti < tax_rule.country_rules.length; ti++){
			    		selected_ruleIndex = tax_rule.country_rules[ti].countries.findIndex(item => item == country_id);
			    		if(selected_ruleIndex !== -1){
			    			selected_rule = tax_rule.country_rules[ti];
			    			ti = tax_rule.country_rules.length;
			    		}
			    	}
			    	console.log(selected_rule);
			    	if(tax_rule.type == 1){
		        		if(selected_rule !== null){
		        			var percentage = (parseFloat(selected_rule.value) / 100) + 1;
		        			return price * percentage;
		         		}else{
		         			return (price * 1.2).toFixed(2);
		         		}
	         		}else{
	         			if(selected_rule !== null){
		        			return price + selected_rule.value;
		         		}else{
		         			return (price * 1.2).toFixed(2);
		         		}
	         		}
         		}else if(tax_rule !== null && tax_rule.country_rules.length > 0){
         			var percentage = (parseFloat(tax_rule.percentage) / 100) + 1;
		        	return price * percentage;
         		}else {
		        	return (price * 1.2).toFixed(2);
         		}
        	},
		}
	}
</script>