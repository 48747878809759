<script type="text/javascript">
	import TextWidget from '../web/TextWidget';
	export default{
		props: {
			id: '',
			description: '',
		},
		components: {TextWidget},
		data() {
			return {
				widgetcontent: '',
				contentLoaded: false,
			}
		},
		mounted(){
			var h = document.getElementsByTagName('head').item(0);
			var f = document.createElement("style");
			f.type = "text/css"; 
			f.id = "customCss";
			h.appendChild(f);
			this.compileFontStyles;
		},
		methods: {
			filterStylesKey(styles, key){
	            for(var i = 0; i < styles.length; i++){
	               var keys = Object.keys(styles[i])
	               if(keys.includes(key)){
	                 return i;
	               }
	            }
	        },
	        compiledStyles(styles){
	        	var style = 'will-change: auto; ';
	        	for(var i = 0; i < (styles.length - 1); i++ ){
	        		for(var key in styles[i] ){
	        			
	        		if(styles[i][key].active == true){
		        		if(key.includes('_label') == false){

		        			if(key == 'background'){
		        				//var stylename = key.replace('_', '-');
		        				if(styles[i][key].value[0] == 'background-image'){
		        					style += ''+styles[i][key].value[0]+': url('+styles[i][key].value[1]+'); '
		        				}else{
		        					style += ''+styles[i][key].value[0]+': '+styles[i][key].value[1]+'; '
		        				}
		        				style += 'background-size: '+styles[i][key].size+'; '
		        				style += 'background-position: '+styles[i][key].position+'; '
		        				style += 'background-repeat: '+styles[i][key].repeat+'; '
		        			}
		        			else if(styles[i][key].type == 'numbergroup'){
		        				//var stylename = key.replace('_', '-');
		        				for(var numgroupkey in styles[i][key]){
		        					if((numgroupkey !== 'parentlabel') || (numgroupkey !== 'type')){
		        						if((key.includes('_radius') == false) && (key.includes('border') == true)){

		        							style += ''+key+'-'+numgroupkey.replace('_','-')+': '+styles[i][key][numgroupkey]+'px '+styles[this.filterStylesKey(styles, 'border_style')]["border_style"].value+' '+styles[this.filterStylesKey(styles, 'border_colour')]["border_colour"].value+' !important; '

		        						}else if(key.includes('_radius') == false){

		        							style += ''+key+'-'+numgroupkey.replace('_','-')+': '+styles[i][key][numgroupkey] ? styles[i][key][numgroupkey]+'px !important; ' : 'auto !important; '
		        						
		        						}else{
		        						
		        							style += ''+key.replace('_radius', '')+'-'+numgroupkey.replace('_','-')+'-radius: '+styles[i][key][numgroupkey]+'px !important; '
		        						
		        						}
		        					}
		        				}
		        			}
		        			else if(styles[i][key].type == 'range'){
		        					if('units' in styles[i][key]){
		        						//console.log(styles[i][key].value);
		        						if(styles[i][key].value !== null){
			        						var stylename = key.replace('_', '-');
					        				style += ''+stylename+': '+parseFloat(styles[i][key].value)+''+styles[i][key].units+'; '; 
				        					}
		        					 }else{
		        					 		style += ''+stylename+': '+styles[i][key].value+'; '
		        					 }

		        			}else{
		        				var stylename = key.replace('_', '-');
		        				stylename = stylename.replace('colour', 'color');
		        				if(styles[i][key].value.length !== 0){
		        					style += ''+stylename+': '+styles[i][key].value+' !important; '
		        				}else{
		        					style += ''+stylename+': '+styles[i][key].value+' !important; '
		        				}
		        			}
		        		}
	        		}
	        	}
	        }
	        	return style;
	     },
	        compileElementStyles(fieldname, types){
			var style = '';
				if(types.includes('background') == true){
					if(fieldname !== 'column'){
				  		style += 'background-color:'+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname+'_colour')][fieldname+'_colour'].value+' !important;'
				  	}else{
				  		style += 'background-color:'+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields, 'background_colour')].background_colour.value+' !important;'
				  	}
				}
				if(types.includes('color') == true){
					if(fieldname !== 'column'){
						style += 'color:'+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname+'_text_colour')][fieldname+'_text_colour'].value+' !important;'
					}else{
						style += 'color:'+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields, 'text_colour')].text_colour.value+' !important;'
					}
				}
				if(types.includes('border') == true){
					if(fieldname !== 'column'){
						style +=  'border-color: '+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname+'_border_colour')][fieldname+'_border_colour'].value+' !important; border-style:'+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname+'_border_style')][fieldname+'_border_style'].value+' !important; border-radius:'+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname+'_border_radius')][fieldname+'_border_radius'].top_left+' '+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname+'_border_radius')][fieldname+'_border_radius'].top_right+' '+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname+'_border_radius')][fieldname+'_border_radius'].bottom_right+' '+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname+'_border_radius')][fieldname+'_border_radius'].bottom_left+' !important; border-left:'+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname+'_border')][fieldname+'_border'].left+'px !important; border-right: '+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname+'_border')][fieldname+'_border'].right+'px !important; border-top: '+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname+'_border')][fieldname+'_border'].top+'px !important; border-bottom: '+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname+'_border')][fieldname+'_border'].bottom+'px !important;'
					}else{
						style +=  'border-color: '+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields, 'border_colour')].border_colour.value+' !important;'

						style += 'border-style:'+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields, 'border_style')].border_style.value+' !important;'

						style += 'border-radius:'+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields, 'border_radius')].border_radius.top_left+' '+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields, 'border_radius')].border_radius.top_right+' '+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields, 'border_radius')].border_radius.bottom_right+' '+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields, 'border_radius')].border_radius.bottom_left+' !important;'
						style += 'border-left:'+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields, 'border')].border.left+'px !important; border-right: '+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields, 'border')].border.right+'px !important; border-top: '+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields, 'border')].border.top+'px !important; border-bottom: '+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields, 'border')].border.bottom+'px !important;'
					}

				}
				if(types.includes('margin') == true){
					if(fieldname !== 'column'){
					style +=  'margin-left:'+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname+'_margin')][fieldname+'_margin'].left !== '' ? this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname+'_margin')][fieldname+'_margin'].left +'px !important;' : 'auto !important';
					style +=  'margin-right: '+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname+'_margin')][fieldname+'_margin'].right !== '' ? this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname+'_margin')][fieldname+'_margin'].right+'px !important;' : 'auto !important';
					style +=  'margin-top: '+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname+'_margin')][fieldname+'_margin'].top !== '' ? this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname+'_margin')][fieldname+'_margin'].top+'px !important;' : 'auto !important';
					style +=  'margin-bottom: '+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname+'_margin')][fieldname+'_margin'].bottom !== '' ? this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname+'_margin')][fieldname+'_margin'].bottom+'px !important;' : 'auto !important';
					}else{
					style +=  'margin-left:'+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields, 'margin')]['margin'].left+'px !important;'
					style +=  'margin-right: '+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields, 'margin')]['margin'].right+'px !important;'
					style +=  'margin-top: '+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields, 'margin')]['margin'].top+'px !important;'
					style +=  'margin-bottom: '+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields, 'margin')]['margin'].bottom+'px !important;'
					}
				}
				if(types.includes('padding') == true){
					if(fieldname !== 'column'){
					style +=  'padding-left:'+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname+'_padding')][fieldname+'_padding'].left+'px !important;'
					style +=  'padding-right: '+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname+'_padding')][fieldname+'_padding'].right+'px !important;'
					style +=  'padding-top: '+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname+'_padding')][fieldname+'_padding'].top+'px !important;'
					style +=  'padding-bottom: '+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname+'_padding')][fieldname+'_padding'].bottom+'px !important;'
					}else{
						style +=  'padding-left:'+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields, 'padding')]['padding'].left+'px !important;'
					style +=  'padding-right: '+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields, 'padding')]['padding'].right+'px !important;'
					style +=  'padding-top: '+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields, 'padding')]['padding'].top+'px !important;'
					style +=  'padding-bottom: '+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields, 'padding')]['padding'].bottom+'px !important;'
					}
				}
				if(types.includes('size') == true){
					if(fieldname !== 'column'){
					style +=  'height:'+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname+'_height')][fieldname+'_height'].value+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname+'_height')][fieldname+'_height'].units+' !important;';
					style +=  'max-height:'+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname+'_max_height')][fieldname+'_max_height'].value+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname+'_max_height')][fieldname+'_max_height'].units+' !important;';
					style +=  'width:'+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname+'_width')][fieldname+'_width'].value+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname+'_width')][fieldname+'_width'].units+' !important;'
					style +=  'max-width:'+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname+'_max_width')][fieldname+'_max_width'].value+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname+'_max_width')][fieldname+'_max_width'].units+' !important;'
					}else{
					style +=  'height:'+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields, 'height')]['height'].value+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields, 'height')]['height'].units+' !important;';
					style +=  'max-height:'+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields, 'max_height')]['max_height'].value+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields, 'max_height')]['max_height'].units+' !important;';
					style +=  'width:'+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields, 'width')]['width'].value+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields, 'width')]['width'].units+' !important;'
					style +=  'max-width:'+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields, 'max_width')]['max_width'].value+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields, 'max_width')]['max_width'].units+' !important;'
					} 
				}
				
				if(types.includes('text') == true){
				  style += 'font-family: \"'+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields, 'font_family')].font_family.value.family+'\", '+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields, 'font_family')].font_family.value.category+' !important; font-size:'+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields, 'font_size')].font_size.value+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields, 'font_size')].font_size.units+' !important; text-transform: '+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname)][fieldname].subfields, 'text_transform')].text_transform.value+'!important;';
				}

				if(types.includes('text_alignment') == true){
					style += 'text-align: '+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, fieldname+'_text_alignment')][fieldname+'_text_alignment'].value+''
				}

				return style;
			}
		},
		computed: {
			headerComponent: function() {
			      return {
			        template: `<${this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, 'title')].title.subfields[this.filterStylesKey(this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, 'title')].title.subfields, 'heading_level')].heading_level.value} style="${this.compileElementStyles('title', ['title'])}">${this.$attrs.product.name}</${this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, 'title')].title.subfields[this.filterStylesKey(this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, 'title')].title.subfields, 'heading_level')].heading_level.value} >`,
			        methods: {
			          someAction() {
			            console.log("Action!");
			          }
			        }
			      }
			 },
			compileFontStyles(){
				var style = '';
				for(var key in this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, 'text')].text.subtabs){
					
						
						for(var skey in this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, 'text')].text.subtabs[key]){
								style += '#'+this.id+'-text '+skey+'{';
								for(var subskey in this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, 'text')].text.subtabs[key][skey]){
									for(var subsubskey in this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, 'text')].text.subtabs[key][skey][subskey]){
									if(this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, 'text')].text.subtabs[key][skey][subskey].value !== ''){
										if(subsubskey !== 'font_family'){
										style += ''+subsubskey.replaceAll('_', '-')+': '+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, 'text')].text.subtabs[key][skey][subskey][subsubskey].value+''+this.$attrs.widget.widgetcontent[this.filterStylesKey(this.$attrs.widget.widgetcontent, 'text')].text.subtabs[key][skey][subskey][subsubskey].units+' !important; '
										}
									}
								}
							}
						}
						style += '}\n';
				}
				document.getElementById('customCss').innerHTML = style;
				
				return style;
			}
		}
	}
</script>

<template>
	<div :id="id" :style="compiledStyles($attrs.widget.widgetstyles)">
		<div :id="id+'-text'" v-if="description == 'product-short-desc'" v-html="$attrs.product.short_description" :style="compileElementStyles('text', ['text_alignment'])"></div>
		<div :id="id+'-text'" v-if="description == 'product-desc'" v-html="$attrs.product.long_description" :style="compileElementStyles('text', ['text_alignment'])"></div>
	</div>
</template>

