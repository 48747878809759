<script type="text/javascript">
	export default{
		data(){
			return{

			}
		},
		props: {
			company: {
				type: Object
			}
		}
	}
</script>
<template>
	<b-container class="py-4">
		<b-row class="d-flex flex-column h-100 my-4 py-4">
			<b-col>
				<b-card class="border-radius-1em shadow-none border py-4 text-center">
					<img src="/images/Management.png" class="img-fluid w-50">
					<h1>We're Working On It!</h1>
					<p>We're working on getting this feature live for release. Come back soon and you'll be able to book all your jewellery servicing here!</p>
				</b-card>
			</b-col>
		</b-row>
	</b-container>
</template>