<script type="text/javascript">

	import CartSummary from "../../../../../components/widgets/ecommerce/website/CartSummary";
	import OrderConfirmation from "../../../../../components/widgets/ecommerce/website/OrderConfirmation";
	import OrderSummary from "../../../../../components/widgets/ecommerce/website/OrderSummary";
	import Login from "../../../../../website-views/account/onpageLogin";
	import { EventBus } from '../../../../../website-app'
	export default{
		components: { CartSummary, OrderConfirmation, OrderSummary, Login},
		data() {
		    return {
		    	csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
		      	  cartuid: '',
		      	  cart: '',
		      	  order: '',
		      	  order_paid: false,
		      	  show_order_summary: false,
		      	  show_payment_form: false,
		      	  order_created: false, 
		      	  paymentResult: '',
		      	  breadcrumbs: [],
					bread: {
						title: '',
						link: '',
						id: 1,
					},
			      title: "Order",
			      items: [
			        {
			          text: "Trade",
			          href: "/",
			        },
			        {
			          text: "Order",
			          active: true,
			        },
			      ],
			  }
	  	   },
	  	   props: {
	  	   	 company: {
	  	   	 	type: Object,
	  	   	 },
	  	   	 domain: {
	  	   	 	type: Object,
	  	   	 },
	  	   	 user: {
	  	   	 	type: Object,
	  	   	 },
	  	   	 id: {
	  	   	 	type: Number,
	  	   	 	default: () => '',
	  	   	 }
	  	   },
	  	   created(){
	  	   		this.checkAndGetCart();
	  	   },
	  	   mounted(){
	  	   	this.breadcrumbs = this.$route.path.split('/');
				if(this.breadcrumbs.length > 0){
				
				this.breadcrumbs[0] = "Home";
				}
			if(this.breadcrumbs.length == 3){
					this.id = this.breadcrumbs[this.breadcrumbs.length - 1];
					this.title = "Order Summary";
				}
	  	   	let self = this;
				EventBus.$on('cart-updated', function(){
					self.refetchCart();
				});
				EventBus.$on('currency-updated', function(symbol, currency, rates){
					self.refetchCart();
	        if(self.user.default_delivery_address !== null){			  		
			  		self.$refs['summary'].companiesTotalIncVat(self.user.default_delivery_address.country.country_zone_id, self.user.default_delivery_address.country.id);
					}
					if(self.user.default_invoice_address !== null){
							//this.invoice_address = this.user.default_invoice_address;
							//this.customer.customer_invoice_address_id = this.user.default_invoice_address.id;
						self.$refs['summary'].companiesTotalIncVat(self.user.default_invoice_address.country.country_zone_id, self.user.default_invoice_address.country.id);
					}
				});
	  	   	EventBus.$on('process-cart', function(){
		      self.$refs['summary'].processCart();
		    });
		    EventBus.$on('cancel-payment', function(product, qty){
		      self.show_payment_form = false;
		    });
		    EventBus.$on('order-paid', function(result){
		      self.paymentResult = result;
		      if(result.status == "succeeded"){
		    	  self.order_paid = true;
		  		}else{
		  		  self.order_paid = false;
		  		}
		    });
		    EventBus.$on('order-updated', function(order, paid){
		    	self.show_payment_form = false;
		     	self.order = order;
		     	self.orderconfirmuid = order["order"]["uid"];
		     	self.order_created = true;
		     	self.title = "Order Confirmation";
		     	self.destroyCart();
		    });
	  	   },
	  	   methods: {
	  	   	refetchCart(){
	  	   		this.cart = '';
	  	   		var session_cart = window.sessionStorage.getItem("websitecart");
				    session_cart = JSON.parse(session_cart);
				    console.log(session_cart);
				    if(session_cart == null){
				    	session_cart = {};
				    }
		    		//var cartparams = window.sessionStorage.getItem("cartparams");
		    		//cartparams = JSON.parse(cartparams);
	  	 		if(Object.keys(this.$route.params).length > 0){
	  	 			this.cartuid = this.$route.params.cart.uid;
		  	 			axios.get('/website-api/cart/read?cart_uid='+this.cartuid, {headers: {
				            'Authorization': 'Bearer ' + this.user.api_token
				        }}).then(response => {
				        console.log(response);
				          if(response.data !== 0){
				            this.cart = response.data;
				            window.sessionStorage.setItem("publiccart", JSON.stringify(response.data));
				          }else{
				            window.sessionStorage.setItem("publiccart", JSON.stringify(this.cart))
				          }
				        
				      }).catch(error => {
				        this.error_reponse = error.response.data.errors;
				      });
	  	 		}else if(Object.keys(session_cart).length > 0){
	  	 			this.cartuid = session_cart['uid'];
	  	 			axios.get('/website-api/cart/read?cart_uid='+this.cartuid, {headers: {
				            'Authorization': 'Bearer ' + this.user.api_token
				        }}).then(response => {
				        console.log(response);
				          if(response.data !== 0){
				            this.cart = response.data;
				            window.sessionStorage.setItem("publiccart", JSON.stringify(response.data));
				          }else{
				            window.sessionStorage.setItem("publiccart", JSON.stringify(this.cart));
				          }
				        
				      }).catch(error => {
				        this.error_reponse = error.response.data.errors;
				      });
	  	 		}else{
	  	 			axios.get('/website-api/cart/read?cart_uid='+this.cartuid, {headers: {
				            'Authorization': 'Bearer ' + this.user.api_token
				        }}).then(response => {
				        console.log(response);
				          if(response.data !== 0){
				            this.cart = response.data;
				            window.sessionStorage.setItem("publiccart", JSON.stringify(response.data));
				          }else{
				            window.sessionStorage.setItem("publiccart", JSON.stringify(this.cart))
				          }
				        
				      }).catch(error => {
				        this.error_reponse = error.response.data.errors;
				      });
	  	 		}
	  	   	},
	  	   	checkAndGetCart(){
	  	   			var session_cart = window.sessionStorage.getItem("websitecart");
		    session_cart = JSON.parse(session_cart);
	  	 		if(Object.keys(this.$route.params).length > 0){
	  	 			this.cartuid = this.$route.params.cart.uid;
		  	 			axios.get('/website-api/cart/read?cart_uid='+this.cartuid, {headers: {
				            'Authorization': 'Bearer ' + this.user.api_token
				        }}).then(response => {
				        console.log(response);
				          if(response.data !== 0){
				            this.cart = response.data;
				            window.sessionStorage.setItem("websitecart", JSON.stringify(response.data));
				          }else{
				            window.sessionStorage.setItem("websitecart", JSON.stringify(this.cart))
				          }
				        
				      }).catch(error => {
				        this.error_reponse = error.response.data.errors;
				      });
	  	 		}else if(Object.keys(session_cart).length > 0){
	  	 			this.cartuid = session_cart['uid'];
	  	 			axios.get('/website-api/cart/read?cart_uid='+this.cartuid, {headers: {
				            'Authorization': 'Bearer ' + this.user.api_token
				        }}).then(response => {
				        console.log(response);
				          if(response.data !== 0){
				            this.cart = response.data;
				            window.sessionStorage.setItem("websitecart", JSON.stringify(response.data));
				          }else{
				            window.sessionStorage.setItem("websitecart", JSON.stringify(this.cart))
				          }
				        
				      }).catch(error => {
				        this.error_reponse = error.response.data.errors;
				      });
	  	 		}else{
	  	 			axios.get('/website-api/cart/read?cart_uid='+this.cartuid, {headers: {
				            'Authorization': 'Bearer ' + this.user.api_token
				        }}).then(response => {
				        console.log(response);
				          if(response.data !== 0){
				            this.cart = response.data;
				            window.sessionStorage.setItem("websitecart", JSON.stringify(response.data));
				          }else{
				            window.sessionStorage.setItem("websitecart", JSON.stringify(this.cart))
				          }
				        
				      }).catch(error => {
				        this.error_reponse = error.response.data.errors;
				      });
	  	 		}
	  	   	},
	  	   	updateCart(cart){
	  	   		this.cart = cart[2];
	  	   		this.show_payment_form = true;
	  	   	},
	  	   	destroyCart(){
	  	   		axios.post('/website-api/cart/order-created', {cartuid: uid }, {
              headers: {
                'Authorization' : 'Bearer '+this.user.api_token
              }
            }).then(response => {
	  	   			this.cartDeleted = true;
	  	   			EventBus.$emit('clear-cart', this.cart.uid);
	  	   		}).catch(error => {
	  	   			this.cartDeleted = false;
	  	   		});
	  	   	}
	  	   },
	  	   watch: {
        	$route() {
        			this.breadcrumbs = this.$route.path.split('/');
						if(this.breadcrumbs.length == 1){
						this.id = '';
						this.title = 'Order';
						this.breadcrumbs[0] = "Home";
						}
					if(this.breadcrumbs.length == 3){
							this.id = this.breadcrumbs[this.breadcrumbs.length - 1];
							this.title = "Order Summary";
						}

	  	   		this.checkAndGetCart();
        	}
      	}
	}

</script>

<template>
	<section>
		<b-container fluid>
		<b-row class="my-4 py-4"  v-if="user == ''">
				    <b-col md="12">
				    	<b-row class="pb-5">
					    		<b-col md="6" class="mx-auto">
						    		<Login :submit-url="'https://'+domain.domain_key+'.website.gemesys.co.uk/account/login'" auth-error="">
				             <input type="hidden" name="_token" :value="csrf">
				        		</Login>
			        		</b-col>
				    	</b-row>
				    </b-col>
				</b-row>
    	<b-row v-else class="order-row">
    		<b-col md="12" class="profile-user-wid my-4 py-4">
				<CartSummary ref="summary" v-if="(cart !== '') && (id == '') && (order_created == false)" :user="user" :company="company" @update-cart="updateCart" :cart="cart" :exchange_rates="$attrs.exchange_rates"  :currencies="$attrs.currencies" :selected_currency="$attrs.selected_currency" :selected_currency_symbol="$attrs.selected_currency_symbol" ></CartSummary>
				<!--<OrderConfirmation v-if="(id !== '') && (order !== '')"
				:user="user" :company="''" :order="order" :paid="order_paid" 
				></OrderConfirmation>-->
				<OrderSummary v-if="(id !== '') && (order_created == false)"
				:user="user" :company="company" :paid="order_paid" :uid="id"
				:exchange_rates="$attrs.exchange_rates"  :currencies="$attrs.currencies" :selected_currency="$attrs.selected_currency" :selected_currency_symbol="$attrs.selected_currency_symbol" ></OrderSummary>
					<OrderSummary v-if="(orderconfirmid !== '') && (order_created == true)"
				:user="user" :company="company" :paid="order_paid" :uid="orderconfirmuid" :exchange_rates="$attrs.exchange_rates"  :currencies="$attrs.currencies" :selected_currency="$attrs.selected_currency" :selected_currency_symbol="$attrs.selected_currency_symbol"
				></OrderSummary>
				<!--<TradeOrderDetails v-if="id !== ''" ></TradeOrderDetails>-->
				<!--<a class="btn btn-success" v-if="step == 0, title = 'Payment', items[1].text = 'Payment'" @click="step++">Process Order</a>-->
			</b-col>
				
		</b-row>
		</b-container>
	</section>
</template>