<script type="text/javascript">
	import Layout from "../layouts/public";
	import ImageCarouselSwiper from "../components/widgets/carousel/ImageCarouselSwiper";
	import PublicListSubscriptionWidget from '../components/widgets/ecommerce/public/PublicListSubscriptionWidget';

	export default{
		components: { Layout, PublicListSubscriptionWidget },
		data(){
			return{

			}
		},
		created(){
			window.scrollTo(0,0);
		},
		mounted(){
			window.scrollTo(0,0);
		},
		watch: {
        $route() {
        	this.$nextTick(() => {
        		window.scrollTo(0,0);
        	 });
            //this.$nextTick();
          			
         },
     	}

	}

</script>

<template>
	<div>
		<section class="header-section">
  <div class="container-fullwidth">
    <!--<PageHeader :title="title" :items="items" />-->
    <b-row class="header-row bg-light bg-soft px-4 position-relative" style="min-height: 550px;" :style="showSearchResults == true ? 'z-index: 2' : ''">
      <b-col lg="8" class="d-flex flex-column justify-content-end align-items-center mx-auto">
      	<p class="mb-1 text-muted mx-md-0 section-info-preheader cursive-title mt-2">Welcome to...</p>
        <h1 class="section-info-header section-info-header position-relative text-sm-center">
        	Gltrbox Jewellery Marketplace
        </h1>
        <p class="text-dark font-size-16 text-center">
           Gltrbox is the UK's one-stop jewellery marketplace for all things sparkling and stylish.
          </p>
      </b-col>
      <b-col lg="6" class="d-flex flex-column mx-auto">
          </b-col>
    </b-row>

    <!-- end row -->
  </div>
</section>
<section class="pt-0">
  <b-container class="py-4 padded-section-2">
   <b-row class="py-4 my-4">
   	<b-col lg="4" md="12" class="mx-auto px-4 text-center d-flex flex-column justify-content-center">
   			<img src="/images/jewellery-marketplace-about-us.png" alt="Image" class="rounded-circle w-100 border w-md-75 mx-auto">
      </b-col>
      <b-col lg="8" md="12">
        <b-card class="shadow-none h-100 mb-0" body-class="p-0 d-flex flex-column justify-content-center align-items-start align-items-sm-center align-items-md-start text-left text-left text-sm-center text-md-left">
        <p class="mb-1 text-muted mx-md-0 section-info-preheader cursive-title mt-2">More Than Just...</p>
        <h2 class="section-info-header section-info-header position-relative mx-md-0">A Jewellery Marketplace</h2>
         <p class="text-dark font-size-16 text-left text-left text-sm-center text-md-left">
           Gltrbox is the UK's one-stop jewellery marketplace for all things sparkling and stylish. Here, you will find a curated selection of high-street retail jewellers, offering a wide range of designs to suit every taste and budget. From classic antique diamond engagement rings to trendy modern statement necklaces, this marketplace has a sparkle for all occassions.
          </p>
          <router-link class="border-bottom border-primary text-dark mt-3 py-2 d-inline-flex" to="/category/jewellery">
          <h4>Find Out More</h4> <i class="bx bx-chevron-right text-primary font-size-20 ml-2"></i>
        </router-link>
      </b-card>
      </b-col>
     
      <b-col lg="12" class="text-left">
        
      </b-col> 
    </b-row>
  </b-container>
</section>
 <section class="bg-light bg-soft header-row h-100">
  <b-container class="padded-section-2">
    <b-row>
    	 <b-col lg="4" md="12" class="px-4 overflow-hidden text-center d-flex flex-column justify-content-center">
  			<img src="/images/gltrbox-jewellery-experts.png" alt="Image" class="rounded-circle w-100 border w-md-75 mx-auto">
      </b-col>
       <b-col lg="8" md="12" class="pb-3">
        <b-card class="shadow-none h-100 bg-transparent" body-class="p-0 d-flex flex-column justify-content-center align-items-start text-left align-items-sm-center align-items-md-start text-left text-sm-center text-md-left bg-transparent">
        <p class="mb-1 text-muted section-info-preheader cursive-title mt-2">Exclusive Jewellery</p>
        <h2  class="section-info-header section-info-header position-relative">Curated by Jewellery Experts</h2>
         <p class="text-dark font-size-16 text-left text-left text-sm-center text-md-left"  >
          Gltrbox jewellery marketplace is a haven for those who appreciate timeless elegance and modern trends. We handpick the finest pieces from top jewellers, ensuring a curated selection that is guaranteed to dazzle. Whether you're searching for a stunning engagement ring or a bold statement piece, our marketplace has something for every taste and budget. Discover the perfect sparkly accessory for any occasion, meticulously selected by jewellery experts who know what truly shines.
          </p>
          <router-link disabled class="border-bottom border-primary text-dark mt-3 py-2 d-inline-flex" to="/category/jewellery">
          <h4>Discover Our Collections</h4> <i class="bx bx-chevron-right text-primary font-size-20 ml-2"></i>
        </router-link>
      </b-card>
      </b-col>
     
    </b-row>
  </b-container>
</section>

<section class="pt-0">
  <b-container class="py-4 padded-section-2">
   <b-row class="py-4 my-4">
   	<b-col lg="4" md="12" class="text-center px-4 d-flex flex-column justify-content-center">
   			<img src="/images/gltrbox-high-street-jewellers.png" alt="Image" class="rounded-circle w-100 border w-md-75 mx-auto">
      </b-col>
      <b-col lg="8" md="12">
        <b-card class="shadow-none h-100 mb-0" body-class="p-0 d-flex flex-column justify-content-center align-items-start text-left align-items-sm-center align-items-md-start text-left text-sm-center text-md-left">
        <p class="mb-1 text-muted mx-md-0 section-info-preheader cursive-title mt-2">Celebrating & Supporting</p>
        <h2 class="section-info-header section-info-header position-relative mx-md-0">High-Street Jewellery Stores</h2>
         <p class="text-dark font-size-16 text-left text-sm-center text-md-left" >
           Gltrbox jewellery marketplace is a hub for showcasing and championing high-street jewellery stores. We have carefully curated a collection of exquisite pieces from local retailers, celebrating their craftsmanship and unique designs. By shopping with us, you not only support these independent businesses but also discover a wide range of stunning jewellery options that cater to all styles and preferences. From delicate everyday pieces to luxurious statement items, our marketplace is a reflection of the creativity and talent found in high-street jewellery stores. Shop with us and join in celebrating and supporting these gems of the jewellery industry.
          </p>
          <router-link class="border-bottom border-primary text-dark mt-3 py-2 d-inline-flex" to="/sellers">
          <h4>See Our Sellers</h4> <i class="bx bx-chevron-right text-primary font-size-20 ml-2"></i>
        </router-link>
      </b-card>
      </b-col>
     	
      <b-col lg="12" class="text-left">
        
      </b-col> 
    </b-row>
  </b-container>
</section>

<section class="pt-0 bg-light bg-soft header-row h-100">
  <b-container class="py-4 padded-section-2" >
   <b-row class="py-4 my-4">
   	<b-col lg="4" md="12" class="text-center px-4 d-flex flex-column justify-content-center">
   			<img src="/images/gltrbox-jewellery-tech-experts.webp" alt="Image" class="rounded-circle w-100 border w-md-75 mx-auto">
      </b-col>
      <b-col lg="8" md="12">
        <b-card class="shadow-none h-100 mb-0 bg-transparent" body-class="p-0 d-flex flex-column justify-content-center align-items-start text-left align-items-sm-center align-items-md-start text-left text-sm-center text-md-left">
        <p class="mb-1 text-muted mx-md-0 section-info-preheader cursive-title mt-2">Created by Tech Experts</p>
        <h2 class="section-info-header section-info-header position-relative mx-md-0">Dedicated to Jewellery Retailers</h2>
         <p class="text-dark font-size-16 text-left text-sm-center text-md-left" >
          Gltrbox is a cutting-edge jewellery marketplace created by tech experts with a passion for supporting jewellery retailers. Our platform offers a seamless shopping experience for customers looking to explore unique and handcrafted pieces from a variety of local stores. With advanced technology and a user-friendly interface, Gltrbox connects consumers with independent jewellery retailers, allowing them to discover exquisite designs that showcase the skill and creativity of each brand. By shopping on Gltrbox, customers not only access a diverse range of jewellery options but also contribute to the growth of small businesses in the industry. Join us in celebrating the artistry and craftsmanship of jewellery retailers by shopping on Gltrbox today.
          </p>
          <router-link class="border-bottom border-primary text-dark mt-3 py-2 d-inline-flex" to="/sellers">
          <h4>See Our Sellers</h4> <i class="bx bx-chevron-right text-primary font-size-20 ml-2"></i>
        </router-link>
      </b-card>
      </b-col>
     	
      <b-col lg="12" class="text-left">
        
      </b-col> 
    </b-row>
  </b-container>
</section>
<!--<section class="pt-0">
  <b-container class="py-4 padded-section-2">
   <b-row class="py-4 my-4">
   	<b-col lg="10" class="mx-auto d-flex flex-column">
   		<p class="mb-1 text-muted mx-auto text-center section-info-preheader cursive-title mt-2">Subscribe to Our Newsletter</p>
        <h2 class="section-info-header section-info-header position-relative mx-auto text-center">Get Exclusive News & Jewellery Drops</h2>
         <p class="text-dark font-size-16 text-left text-sm-center text-md-left" >
       		<PublicListSubscriptionWidget :user="$attrs.user" :list_id="1" :recaptcha="$attrs.recaptcha_token" :styleClasses="'underline-input border-top-0 border-left-0 border-right-0 rounded-0'" ></PublicListSubscriptionWidget>
          </p>
   	</b-col>

   <b-col lg="4" class="text-center px-4 d-flex flex-column justify-content-center">
   			<img src="/images/gltrbox-high-street-jewellers.png" alt="Image" class="rounded-circle w-100 border w-md-75 mx-auto">
      </b-col>
      <b-col lg="8" md="12">
        <b-card class="shadow-none h-100 mb-0 bg-transparent" body-class="p-0 d-flex flex-column justify-content-center align-items-start">
        <p class="mb-1 text-muted mx-md-0 section-info-preheader cursive-title mt-2">Celebrating & Supporting</p>
        <h2 class="section-info-header section-info-header position-relative mx-md-0">High-Street Jewellery Stores</h2>
         <p class="text-dark font-size-16 text-left text-sm-center text-md-left" >
           Gltrbox jewellery marketplace is a hub for showcasing and championing high-street jewellery stores. We have carefully curated a collection of exquisite pieces from local retailers, celebrating their craftsmanship and unique designs. By shopping with us, you not only support these independent businesses but also discover a wide range of stunning jewellery options that cater to all styles and preferences. From delicate everyday pieces to luxurious statement items, our marketplace is a reflection of the creativity and talent found in high-street jewellery stores. Shop with us and join in celebrating and supporting these gems of the jewellery industry.
          </p>
          <router-link class="border-bottom border-primary text-dark mt-3 py-2 d-inline-flex" to="/sellers">
          <h4>See Our Sellers</h4> <i class="bx bx-chevron-right text-primary font-size-20 ml-2"></i>
        </router-link>
      </b-card>
      </b-col>
     	
      <b-col lg="12" class="text-left">
        
      </b-col> 
    </b-row>
  </b-container>
</section>-->
	</div>

</template>