/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require("./bootstrap");
import Vue from "vue";
window.Vue = Vue;

import { BootstrapVue } from "bootstrap-vue";
//import vClickOutside from "v-click-outside";
//import VueMask from "v-mask";
//import Vuelidate from "vuelidate";
import VueApexCharts from "vue-apexcharts";
import * as VueGoogleMaps from "vue2-google-maps";
Vue.use(VueGoogleMaps, {
    load: {
        key: "AIzaSyDYWzwTgxlRT37TtJkYNt2hlT4xpg1GweM",
        libraries: "places,geometry"
    },
    installComponents: true
});
import VueSweetalert2 from "vue-sweetalert2";
import i18n from "./i18n";
import VueLuxon from "vue-luxon";

Vue.prototype.$isDev = process.env.MIX_APP_ENV !== "production";
Vue.config.devtools = Vue.prototype.$isDev;
Vue.config.debug = Vue.prototype.$isDev;
Vue.config.silent = !Vue.prototype.$isDev;
//import tinymce from "vue-tinymce-editor";
export const EventBus = new Vue();
import { VueReCaptcha } from 'vue-recaptcha-v3';
Vue.use(VueReCaptcha, { siteKey: '6LeQy8IUAAAAABtOrMfUczpCLmxyZ-GSJ4xghvyA' });
const prod = process.env.NODE_ENV === 'production';
const swLocation = '../serviceWorker.js';

if (
  prod &&
  ('https:' === location.protocol || location.host.match(/(localhost|127.0.0.1)/)) &&
  navigator.serviceWorker
) {
  navigator.serviceWorker.register(swLocation).catch( error => console.log)
  console.log('sw register');
}

Vue.use(VueLuxon, {
    input: {
        zone: "gmt",
        format: "sql"
    },
    output: {
        zone: "local",
        format: {
            year: "numeric",
            month: "long",
            day: "numeric"
        },
        locale: null,
        relative: {
            round: true,
            unit: null
        }
    }
});

Vue.use(BootstrapVue);
//Vue.use(vClickOutside);
//Vue.use(VueMask);
//Vue.use(Vuelidate);
Vue.use(require("vue-chartist"));
Vue.use(VueSweetalert2);

Vue.component("apexchart", VueApexCharts);
//Vue.component("tinymce", tinymce);
Vue.component(
    "public-component",
    require("./components/public-component").default
);
Vue.component(
    "dynamic-component",
    require("./components/dynamic-component").default
);

Vue.component(
    "user-companies",
    require("./components/user-companies").default
);
/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */
/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

import "./views/index.js";
import Layouts from "./mixins/layouts.mixin";

//Pages


//Internal Widgets
import ThemeBuilder from './components/widgets/page-builder/ThemeBuilder';
import ImageSwiper from "./components/widgets/gallery/ImageSwiper";
import NothingHere from "./components/widgets/NothingHere";
import AccessRestricted from "./views/utility/access-restricted";

//External Widgets
import QrcodeVue from 'qrcode.vue';
import VueHtml2pdf from 'vue-html2pdf';


//Internal Widgets
Vue.component("ThemeBuilder", ThemeBuilder);
Vue.component("ImageSwiper", ImageSwiper);
Vue.component("NothingHere", NothingHere);
Vue.component("AccessRestricted", AccessRestricted);

//External Widgets
Vue.component("qrcode-vue", QrcodeVue);
Vue.component("vue-html2pdf", VueHtml2pdf);

const app = new Vue({
    el: "#app",
    mixins: [Layouts],
    i18n,
    data() {
    return {
      menu: [],
      active_menu: [],
    };
    },
    created(){
        const menu = JSON.parse(localStorage.getItem("menu")) || null;
        if (!menu) {
         axios.get('/api/menus').then(response => {
                this.menu = response.data[0].menu;
                 localStorage.setItem("menu", JSON.stringify(this.menu));
            }).catch(error => {
              console.log(error);
            });

        }else{
            this.menu = menu;
        }
        var pathname = window.location.pathname;
        if(pathname.split('/')[2] == 'storage'){
            pathname = '/company/storage'
        }
        /*axios.get('/api/menu-route?url='+pathname+'').then(response => {
              this.active_menu = response.data;
            }).catch(error => {

            });*/
    }
    
});
