<script type="text/javascript">
		import Layout from "../../layouts/main";
	import PageHeader from "../../components/page-header";
	import EventBus from '../../app'
	import AccountOverview from './overview'
	//import Login from "../../../../../website-views/account/onpageLogin";
	export default{
		components: {AccountOverview, Layout, PageHeader},
		data(){
			return{
				csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
				updateAvatar: false,
				breadcrumbs: [],
				cardExpanded: '',
				storageurl: 'https://'+this.$attrs.company.nickname+'.s3.eu-west-2.amazonaws.com',
				orders: '',
				title: "Account",
				items: [
				        {
				          text: "Account",
				          href: "/trade/account"
				        },
				        {
				          text: this.$attrs.id,
				          active: true
				        }
				      ],
				bread: {
					title: '',
					link: '',
					id: 1,
				},
				account_tabs: [
					{
						title: 'Overview',
						link: '/trade/account',
						icon: '',
						active: true,
						id: 1,
					},
					/**{
						title: 'Addresses',
						link: '/trade/account/addresses',
						icon: '',
						active: false,
						id: 2,
					},**/
					{
						title: 'Orders',
						link: '/trade/account/orders',
						icon: '',
						active: false,
						id: 3,
					},
					/**{
						title: 'Payment Details',
						link: '',
						icon: '',
						active: false,
						id: 3,
					},**/
					{
						title: 'Support',
						link: '/trade/account/support',
						icon: '',
						active: false,
						id: 3,
					},
					{
						title: 'Wishlist',
						link: '/trade/account/wishlist',
						icon: '',
						active: false,
						id: 3,
					}
				]
			}
		},
		props: {
			user: {
				type: Object
			}
		},
		mounted() {
			this.cardExpanded = this.$attrs.id;
			if(this.$attrs.id == ''){
				this.items[1].text = 'Overview';
			}
			this.breadcrumbs = this.$route.path.split('/');
			this.breadcrumbs[1] = this.breadcrumbs[1].replaceAll('-',' ');
				if(this.breadcrumbs.length > 0){
				this.title = this.breadcrumbs[this.breadcrumbs.length - 1];
				this.breadcrumbs[0] = "Home";
				}
				let self = this;
				EventBus.$on('remove-hash', function(){
					self.removeHash();
				})
			
		},
		methods: {
			removeHash () { 
			    var scrollV, scrollH, loc = window.location;
			    if ("pushState" in history)
			        history.pushState("", document.title, loc.pathname + loc.search);
			    else {
			        // Prevent scrolling by storing the page's current scroll offset
			        scrollV = document.body.scrollTop;
			        scrollH = document.body.scrollLeft;

			        loc.hash = "";

			        // Restore the scroll offset, should be flicker free
			        document.body.scrollTop = scrollV;
			        document.body.scrollLeft = scrollH;
			    }
			}
		},
		watch: {
        $route() {
        	this.$nextTick(() => {
        		 this.cardExpanded = (location.hash).replace('#', '');

        		});
        	}
        }
		
	}

</script>
<template>
	<div>
				<Layout  :selected_currency="$attrs.selected_currency" :selected_currency_symbol="$attrs.selected_currency_symbol" :exchange_rates="$attrs.exchange_rates"  :nickname="$attrs.nickname" :user="user" :menuItems="$attrs.menuItems" :currencies="$attrs.currencies">
    <PageHeader :title="title" :items="items" />
		<section >
		  <div class="container-fluid" style="margin-top: 150px;">
		    <!--<PageHeader :title="title" :items="items" />-->
		   
		    <b-row class="my-4 py-4 seller-top-row" >
		    	<b-col md="12">
		    	<b-row class="pb-5" v-if="user == ''">
			    		<b-col md="6" class="mx-auto">
				    		<Login :submit-url="'http://website.'+domain.domain_key+'.gemesys.co.uk/account/login'" auth-error="">
				             <input type="hidden" name="_token" :value="csrf">
				        		</Login>
	        		</b-col>
		    	</b-row>
		    	<b-row class="pb-5" v-else>
		    		<b-col md="9" class="profile-user-wid">
		      	<AccountOverview :user="user" :company="$attrs.company" :cardExpanded="cardExpanded" :id="$attrs.id" :currencies="$attrs.currencies" :exchange_rates="$attrs.exchange_rates" :selected_currency_symbol="$attrs.selected_currency_symbol" :selected_currency="$attrs.selected_currency"></AccountOverview>
		        </b-col>
		        <b-col md="3" style="min-height: 500px;">
		        	<div class="avatar-xl profile-user-wid mb-2 mr-auto w-100">
		        		<b-card class="border-radius-1em">
		        			<div class="avatar-xl profile-user-wid mb-2 mx-auto position-relative">
                  <img
                  	v-if="$attrs.company.logo !== null" :src="storageurl + $attrs.company.logo.replaceAll('//', '/')"
                    alt
                    class="img-thumbnail rounded-circle"
                  />
                   <img
                  	v-else 
                    src="/images/product-placeholder.png"
                    alt
                    class="img-thumbnail rounded-circle"
                  />
                <div class="avatar-xl bg-soft avatar-title top-0 mb-2 mx-auto position-absolute rounded-circle w-100 opacity-75 text-uppercase font-size-10" v-if="updateAvatar == true">
                	
                	Update Avatar
                </div>
                </div>

		        			<h5 class="text-center" v-if="user">{{$attrs.company.name}}</h5>
		        			<p  class="text-muted mb-4 text-center">
			                    <a href="javascript:;">{{$attrs.company.email}}</a>
			                  </p>
			            
		        			<a v-for="tab in account_tabs" class="btn btn-outline-light btn-rounded btn-block mb-2" :href="tab.link" >{{tab.title}}<i :class="tab.icon"></i></a>

		        			<!--<a class="btn btn-danger btn-rounded btn-block mt-3 mb-2" href="/my-account/logout" >Logout<i class="bx bx-power-off"></i></a>
		        			-->
		        		</b-card>
		        	</div>
		        </b-col>
		    	</b-row>
		    	</b-col>
		  	</b-row>
		    <!-- end row -->
		  </div>
		</section>
	</Layout>
</div>
</template>