<script type="text/javascript">
	import QrcodeVue from 'qrcode.vue';
	import VueBarcode from '@chenfengyuan/vue-barcode';
	import VueSlideBar from "vue-slide-bar";
	import simplebar from "simplebar-vue";
//	import VueHtml2pdf from 'vue-html2pdf';
	export default{
		components: {QrcodeVue, VueBarcode, VueSlideBar, simplebar},
		props: {
			isMultiple: {
				type: Boolean,
				default: false
			},
			item_count: {
				type: Number,
				default: 1
			},
			items:{
				type: Array,
				default: []
			},
			code_type: {
				type: String,
				default: 'barcode'
			},
			barcode_value: {
				type: Object,
				default: {
					EAN13: 234567891011,
					UPC: 234567891012,
					CODE128: 134567891011
				}
			},
			qr_value: {
				type: String,
				default: '1234567891011'
			},
			barcode_type: {
				type: String,
				default: 'CODE128'
			},
			background_color: {
				type: String,
				default: '#ffffff'
			},
			code_color:  {
				type: String,
				default: '#000000'
			},
			code_size: {
				type: Number,
				default: '1'
			},
			code_quality: {
				type: String,
				default: 'H'
			},
			display_code: {
				type: Number,
				default: true
			},
			product: {
				type: Object
			}
		},
		methods:{
			print(){

			},
			generateReport () {
            this.$refs.html2Pdf.generatePdf()
	        },
		},
		data(){
			return {
				mmconversion: 3.7795275591,
				scale: 1,
				scale_qr: 1,
				scale_font: 1,
				font_size: 9,
				code_types: ['barcode', 'QR'],
				barcode_types: ['CODE128', 'UPC', 'EAN13'],
				label_size: '',
				label_type: 'rattail',
				label_types: [
					{text: 'Ring Wrap / Rattail', value: 'rattail'},
					{text: 'Dumbell', value: 'dumbell'},
				],
				label_sizes: 
					{rattail: 
						[
							{text: '88 x 15', value: {width: 88, height: 15, tagportion: 25, tailportion: 50}},
							{text: '68 x 10 (GTAGS REF: 1020)', value: {width: 68, height: 10, tagportion: 29.411764705882355, tailportion: 41.176470588}},
							{text: '72 x 10 (GTAGS REF: 1021)', value: {width: 72, height: 10, tagportion: 29.166666666666668, tailportion: 41.666666667}},
							{text: '72 x 10 (GTAGS REF: 1022)', value: {width: 72, height: 10, tagportion: 30.555555555555557, tailportion: 38.888888889}},
							{text: '88 x 11 (GTAGS REF: 1122T)', value: {width: 88, height: 11, tagportion: 25, tailportion: 50}},
							{text: '88 x 11 (GTAGS REF: 1122)', value: {width: 88, height: 11, tagportion: 25, tailportion: 50}},
							{text: '80 x 11 (GTAGS REF: 1125)', value: {width: 80, height: 11, tagportion: 31.25, tailportion: 37.5}},
							{text: '86 x 12 (GTAGS REF: 1228)', value: {width: 86, height: 12, tagportion: 32.558139534883722, tailportion: 34.88372093}},
							{text: '100 x 13 (GTAGS REF: 2713)', value: {width: 100, height: 13, tagportion: 27, tailportion: 46}},
							{text: '76 x 13 (GTAGS REF: 2313)', value: {width: 76, height: 13, tagportion: 30.263157894736842, tailportion: 39.473684211}},
							{text: '88 x 15 (GTAGS REF: 1522T)', value: {width: 88, height: 15, tagportion: 25, tailportion: 50}},
							{text: '59 x 7 (GTAGS REF: 0712)', value: {width: 59, height: 7, tagportion: 21.1864406779661, tailportion: 57.627118644}},
							{text: '70 x 8 (GTAGS REF: 0820)', value: {width: 70, height: 8, tagportion: 28.571428571428569, tailportion: 42.857142857}},
							{text: '69 x 8 (GTAGS REF: 0818)', value: {width: 69, height: 8, tagportion: 25.362318840579711, tailportion: 49.275362319}},
							{text: '72 x 8 (GTAGS REF: 0821)', value: {width: 72, height: 8, tagportion: 29.166666666666668, tailportion: 41.666666667}},


						],
						dumbell: [
							{text: 'Direct Thermal Dumbell Labels – 6315', value: {width: 63, height: 15, tagportion: 31.746031746, tailportion: 36.507936508}},
							{text: 'Dumbell Labels – 7911 DT', value: {width: 81, height: 11, tagportion: 39.50617284, tailportion: 20.987654321}},
							{text: 'Direct Thermal Dumbell Labels – 1913 DT', value: {width: 56, height: 13, tagportion: 33.928571429, tailportion: 32.142857143}},
							{text: 'Dumbell Labels – 7510 DT', value: {width: 75, height: 10, tagportion: 40, tailportion: 20}},

						]
					}

			}
		}
	}

</script>
<template>
	<div>
		<b-row>
			<b-col md="9">
		<b-row class="mx-auto border-bottom h-100">
			<div class="border py-4 bg-light d-flex justify-content-center align-items-center">
				<div v-if="(label_size !== '') && (label_type == 'rattail')" class="d-flex justify-content-center align-items-center mx-auto my-4" :style="{'width' : label_size.width * scale +'mm', 'height': label_size.height * scale +'mm'}">
					<div class="border bg-white d-flex justify-content-center align-items-center shadow-sm p-1" style="height: 100%;" :style="{'width' : label_size.tagportion+'%'}">
						<VueBarcode v-if="code_type == 'barcode'" :value="barcode_value[barcode_type]" class="w-100 h-100" :options="{ displayValue: display_code, format: barcode_type, lineColor: code_color, background: background_color }"></VueBarcode>
						<!--<qrcode-vue v-if="code_type == 'QR'" :value="JSON.stringify(qrData)" :size="code_size" :level="code_quality" :foreground="code_color" :background="background_color" margin="0" class="p-3" />-->
						 <qrcode-vue v-if="code_type == 'QR'" :value="JSON.stringify(qr_value)" :size="(((label_size.width / 100) * label_size.tagportion) * scale) * scale_qr" level="H" :foreground="code_color" :background="background_color" margin="0" class="h-100 my-auto mx-auto"  style="display: contents;" />
					</div>
					<div class="border bg-white justify-content-center px-2 d-flex flex-column  shadow-sm" style="height: 100%;" :style="{'width' : label_size.tagportion+'%'}">
						<small style="font-size-adjust: 0.5;" :style="{'font-size' : font_size*scale*scale_font+'px'}">REFERENCE001</small>
						<small style="font-size-adjust: 0.5;" :style="{'font-size' : font_size*scale*scale_font+'px'}">£15000.00</small>
					</div>
					<div class="border bg-white  shadow-sm" style="height: 25%;" :style="{'width' : label_size.tailportion+'%'}">
						
					</div>
				</div>
				<div v-if="(label_size !== '') && (label_type == 'dumbell')" class="d-flex justify-content-center align-items-center mx-auto my-4" :style="{'width' : label_size.width * scale +'mm', 'height': label_size.height * scale +'mm'}">
					<div class="border bg-white d-flex justify-content-center align-items-center shadow-sm p-1" style="height: 100%;" :style="{'width' : label_size.tagportion+'%'}">
						<VueBarcode v-if="code_type == 'barcode'" :value="barcode_value[barcode_type]" class="w-100 h-100" :options="{ displayValue: display_code, format: barcode_type, lineColor: code_color, background: background_color }"></VueBarcode>
						<qrcode-vue v-if="code_type == 'QR'" :value="JSON.stringify(qr_value)" :size="code_size" :level="code_quality" :foreground="code_color" :background="background_color" margin="0" class="p-3" style="display: contents;" />
					</div>
					<div class="border bg-white  shadow-sm" style="height: 25%;" :style="{'width' : label_size.tailportion+'%'}">
						
					</div>
					<div class="border bg-white justify-content-center px-2 d-flex flex-column  shadow-sm" style="height: 100%;" :style="{'width' : label_size.tagportion+'%'}">
						<small style="font-size-adjust: 0.5;" :style="{'font-size' : font_size*scale*scale_font+'px'}">REFERENCE001</small>
						<small style="font-size-adjust: 0.5;" :style="{'font-size' : font_size*scale*scale_font+'px'}">£15000.00</small>
					</div>
					
				</div>
				<div v-if="label_size == ''" class="my-4 justify-content-center align-items-center">
					<div class="w-50 bg-white mx-auto p-4 text-center border-radius-1em shadow-sm">
						<h6 class="m-2">Select Label options and label template will show here.</h6>
					</div>
				</div>
			</div>
		</b-row>
	</b-col>
	<b-col md="3">
		<b-card class="border-radius-1em border shadow-none" no-body>
			<div class="widget-scrollbox" style="overflow-x: hidden;">
			<b-row class="p-3">
				<b-col md="12">
					<h5>Label Options</h5>
					<hr>
					<b-row>
						<b-col md="12">
							<b-form-group label="Code Type">
								<b-form-select class="form-control" :options="code_types" v-model="code_type">
								</b-form-select>
							</b-form-group>
						
							<b-form-group label="Barcode Format">
								<b-form-select class="form-control" :options="barcode_types" v-model="barcode_type">
								</b-form-select>
							</b-form-group>

							<b-form-group class="mt-2" label="Label Types">
								<b-form-select class="form-control" :options="label_types" v-model="label_type">
								</b-form-select>
							</b-form-group>
						</b-col>
						<b-col md="12">
							<b-form-group class="mt-2" label="Label Size">
								<b-form-select class="form-control" :options="label_sizes[label_type]" v-model="label_size" @input="scale_qr = 1">
								</b-form-select>
							</b-form-group>
						</b-col>
						<b-col md="12">
							<h5 class="mt-4">Style Options</h5>
							<hr>
							<b-form-group label="Resize Label">
								<div class="d-flex">
							 	<b-form-input id="formControlRange" class="form-control-range form-range" type="range" v-model="scale" min="0.5" step="0.1" max="5"></b-form-input>
							 	<a class="btn btn-outline-light" @click="scale = 1">
							 		<i class="bx bx-reset"></i>
							 	</a></div>
							 </b-form-group>
							 <b-form-group label="Resize QR Code">
							 	<div class="d-flex">
							 	<b-form-input id="formControlRange" class="form-control-range form-range" type="range" v-model="scale_qr" min="0.5" step="0.1" max="5"></b-form-input>
							 	<a class="btn btn-outline-light" @click="scale_qr = 1">
							 		<i class="bx bx-reset"></i>
							 	</a></div>
							 </b-form-group>
							 <b-form-group label="Resize Font">
							 	<div class="d-flex">
							 	<b-form-input id="formControlRange" class="form-control-range form-range" type="range" v-model="scale_font" min="0.5" step="0.1" max="5"></b-form-input>
							 	<a class="btn btn-outline-light" @click="scale_font = 1">
							 		<i class="bx bx-reset"></i>
							 	</a></div>
							 </b-form-group>
						</b-col>
						
					</b-row>
				
				</b-col>
			</b-row>
			</div>
		</b-card>
		<a class="btn btn-success btn-block btn-rounded" @click="print()">
			<i class="bx bx-printer"></i> Print
		</a>
		<a class="btn btn-success btn-block btn-rounded mt-2" @click="generateReport()">
			<i class="bx bx-printer"></i> Generate PDF & Print
		</a>
	</b-col>
	<b-col v-if="isMultiple == true" md="12">
		<b-row class="pt-3">
			<hr>
			<h5 class="font-size-16">
				You are Printing {{item_count}} labels.
			</h5>
			<b-col v-for="item in items.slice(0, 3)" >
				<b-card class="border-radius-1em border shadow-sm p-4 mb-0" body-class="d-flex flex-column justify-content-center">
					<h6 class="mb-0">#{{item}}</h6>
				</b-card>
			</b-col>
			<b-col v-if="items.length > 3" >
				<b-card class="border-radius-1em border shadow-sm p-4 mb-0" body-class="d-flex flex-column justify-content-center">
					<h6 class="mb-0">+ {{items.length - 3}} More Item(s)</h6>
				</b-card>
			</b-col>
		</b-row>
	</b-col>
</b-row>
	</div>
</template>

<style scoped>
	.custom-control.custom-radio{
		padding-left: 0 !important;
	}
	.custom-control.custom-radio input{
		padding-right: 5px !important;
	}
</style>