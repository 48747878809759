<script>
import DateTime from 'luxon/src/datetime.js';
  import Interval from 'luxon/src/interval.js';

export default {
  components: {DateTime, Interval},
  props: {
    company: {
      type: Object
    },
    order: {
      type: Object
    },
    printable: {
      type: Boolean,
      default: false
    },
    marketplace: {
      type: Boolean,
      default: 'trade',
    }
  },
  data() {
    return {
      storageurl: 'https://'+this.company.nickname+'.s3.eu-west-2.amazonaws.com',
      title: "Invoice",
      items: [
        {
          text: "Invoices",
          href: "/"
        },
        {
          text: "Detail",
          active: true
        }
      ]
    };
  },
  mounted(){
   // this.parseImage('http://app.gemesys.co.uk/images/logo-square.png');
  },
  methods: {
    parseDate(date){
        this.timestamp = DateTime.local().toISO();
        console.log(DateTime.fromISO(date));
        var formattedDate = DateTime.fromISO(date);
        return formattedDate.monthLong+' '+formattedDate.day+', '+formattedDate.weekYear+'';
      },
    parseImage(file){
     // var file = file
  
        var reader = new FileReader();
        console.log("next");
          
        reader.onload = function () {
            base64String = reader.result.replace("data:", "")
                .replace(/^.+,/, "");
      
            imageBase64Stringsep = base64String;
      
            // alert(imageBase64Stringsep);
            console.log(base64String);
        }
        reader.readAsDataURL(file);
        }
  }
};
</script>

<template>

    <div class="row printable" id="invoice" :class="{'p-1 m-1' : printable == true}">
      <div class="col-lg-12">
        <div class="card shadow-none">
          <div class="card-body">
            <div class="invoice-title">
               <div class="row">
                <div class="col-2">
              <div class="mb-1">
                <img class="img-thumbnail avatar-lg rounded-circle" src="http://app.gemesys.co.uk/images/logo-square-flat.png" />
              </div>
            </div>
              <div class="col-10 d-flex flex-column">
                <h2  class="float-end ml-auto mt-auto text-primary mb-0"><strong>RECEIPT</strong></h2>
              <h4  class="float-end font-size-16 ml-auto mt-auto" :class="{'font-size-14' : printable == true}">Order # {{order.reference}}</h4>
              <p class="float-end ml-auto mb-0 text-right" :class="{'font-size-11' : printable == true}">Gemesys Ltd. Unit 1c Eagle Industrial, Estate, Church Green, Witney, Oxfordshire, OX28 4YR. Registered Company No. 14180401</span></p>
            </div>
            
          </div>
            </div>
            <hr />
            <div class="row">
              <div class="col-6">
                <address>
                  <strong>Billed To:</strong>
                  <div>
                <p class="text-muted mb-0" v-if="company_invoice_address !== null">
                     <span v-if="company_invoice_address.address_1">{{company_invoice_address.address_1}}</span>
                      <span v-if="company_invoice_address.address_2"><br />{{company_invoice_address.address_2}}</span>
                      <span v-if="company_invoice_address.address_3"><br />{{company_invoice_address.address_3}}</span>
                      <span v-if="company_invoice_address.county"><br />{{company_invoice_address.county.name}}</span>
                      <span v-if="company_invoice_address.country"><br />{{company_invoice_address.country.name}}</span>
                      <span v-if="company_invoice_address.postcode"><br />{{company_invoice_address.postcode}}</span>
                                          </p>
                     <p class="text-muted mb-0" v-else>
                      -- No Address Added --
                     </p>
                  </div>
                  
                </address>
              </div>
              <div class="col-6 text-end">
                <!--<address>
                  <strong>Shipped To:</strong>
                    <div>
                <p class="text-muted mb-0" v-if="company_delivery_address !== null">
                     <span v-if="company_delivery_address.address_1">{{company_delivery_address.address_1}}</span>
                      <span v-if="company_delivery_address.address_2"><br />{{company_delivery_address.address_2}}</span>
                      <span v-if="company_delivery_address.address_3"><br />{{company_delivery_address.address_3}}</span>
                      <span v-if="company_delivery_address.county"><br />{{company_delivery_address.county.name}}</span>
                      <span v-if="company_delivery_address.country"><br />{{company_delivery_address.country.name}}</span>
                      <span v-if="company_delivery_address.postcode"><br />{{company_delivery_address.postcode}}</span>
                                          </p>
                     <p class="text-muted mb-0" v-else>
                      -- No Address Added --
                     </p>
                  </div>
                    
                </address>-->
              </div>
            </div>
            <div class="row">
              <div class="col-6 mt-3">
                <address>
                  <strong>Payment Method:</strong>
                   <div>
                    Debit / Credit Card via Stripe
                    <br />Card ending ****
                   </div>
                   

                  <br />
                      <div>
                         <a :href="'mailto:'+company.email"><h6 class="mb-0">{{company.name}} {{company.lastname}}</h6>
                        {{company.email}}</a>
                      </div>
                </address>
              </div>
              <div class="col-6 mt-3 text-end">
                <address>
                  <strong>Order Date:</strong>
                  <br />{{parseDate(order.created_at)}}
                  <br />
                  <br />
                </address>
              </div>
            </div>
            <div class="p-2 mt-3 bg-primary">
              <h3 class="font-size-16 text-white my-1">Order summary</h3>
            </div>
            <div class="table-responsive">
              <table class="table mb-0" :class="{'font-size-12' : printable == true}">
                <thead>
                  <tr class="bg-light">
                    <td colspan="6" class="text-start">
                      <h5 class="my-1">Subscription Items for {{company.name}}</h5>
                    </td>
                  </tr>
                  <tr>
                    <th style="width: 100px;">REF.</th>
                    <th style="width: 350px;">Product Name</th>
                    <th class="text-end" style="width: 130px;">Price</th>
                    <th class="text-end" style="width: 130px;">Quantity</th>
                    <th class="text-end">Subtotal Exc VAT</th>
                    <th class="text-end">Subtotal Inc VAT</th>
                  </tr>
                </thead>
                <tbody>
                  
                  <tr v-for="item in subscription.items">
                    <td style="width: 100px; max-width: 100px;">{{item.reference}}</td>
                    <td>{{item.name}}</td>
                    <td class="text-end">{{item.price_exc}}</td>
                    <td class="text-end">{{item.quantity}}</td>
                    <td class="text-end">{{(parseFloat(item.price_exc) * parseFloat(item.quantity)).toFixed(2)}}</td>
                    <td class="text-end">{{(parseFloat(item.price_inc) * parseFloat(item.quantity)).toFixed(2)}}</td>
                  </tr>

                  
                  <tr>
                    <td colspan="4" class="text-end">Sub Total</td>
                    <td class="text-end">£0.00</td>
                    <td class="text-end">£0.00</td>
                  </tr>
                  <tr>
                    <td colspan="4" class="border-0 text-end">
                      <strong>Customisations</strong>
                    </td>
                    <td class="border-0 text-end">£0.00</td>
                    <td class="border-0 text-end">£0.00</td>
                  </tr>
                  <tr>
                    <td colspan="4" class="border-0 text-end">
                      <strong>Shipping</strong>
                    </td>
                    <td class="border-0 text-end">£0.00</td>
                    <td class="border-0 text-end">£0.00</td>
                  </tr>
                  <tr>
                    <td colspan="4" class="border-0 text-end">
                      <strong>Total</strong>
                    </td>
                    <td class="border-0 text-end">
                      <h4 class="m-0">£0.00</h4>
                    </td>
                    <td class="border-0 text-end">
                      <h4 class="m-0">£0.00</h4>
                    </td>
                  </tr>
                </tbody>
               
              </table>
            
            </div>
            <div class="d-print-none mt-3" v-if="printable == false">
              <div class="float-end">
                <a @click="$emit('enable-download', false), $emit('process-pdf')" class="btn-rounded btn btn-info mr-2">
                 Print <i class="fa fa-print"></i>
                </a>
                <a @click="$emit('enable-download', true), $emit('process-pdf')" class="btn-rounded btn btn-success">
                 Save As <i class="fa fa-save"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>