<script>
import Swal from "sweetalert2";
import VueSlideBar from "vue-slide-bar";
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
//import FilterListTree from "../../views/stock/product/category/_partials/FilterListTree";
import FilterListTree from "../../components/widgets/FilterListTree";
import FilterProductBrands from "../../components/widgets/FilterProductBrands";
import FilterProductCollections from "../../components/widgets/FilterProductCollections";
import SideSlidePanel from "../../components/widgets/SideSlidePanel";
import { themeStyles } from "../../components/widgets/page-builder/page-styles";
import stylesBlock from "../../components/widgets/page-builder/widgets/stylesBlock";
import widgetConfigsTab from "../../components/widgets/page-builder/widgets/widgetConfigsTab";
import simplebar from "simplebar-vue";
import DefaultFooter from "../../components/widgets/page-builder/templates/actinolite/DefaultFooter";
import { EventBus } from '../../app'

/**
 * Products component
 */
export default {
  components: { VueSlideBar, Layout, PageHeader, FilterListTree, FilterProductBrands, FilterProductCollections, SideSlidePanel, stylesBlock, simplebar, Swal, DefaultFooter, widgetConfigsTab },
  data() {
    return {
      hideDesign: false,
      loadingTheme: false,
      loadingThemeProgress: 0,
      loadingForm: false,
      deleteMode: 'single',
      producthoverIndex: -1,
      showPageTools: -1,
      showTemplateTools: -1,
      pages: '',
      templates: '',
      pagesData: '',
      title: "Themes",
      items: [
        {
          text: "Web",
          href: "/",
        },
        {
          text: "Themes",
          active: true,
        },
      ],
      search: '',
      pageslimit: 25,
      pagessearch: '',
      storageurl: 'https://'+this.$attrs.company.nickname+'.s3.eu-west-2.amazonaws.com',
      showFilters: false,
      addNew: false,
      addNewModal: false,
      sliderPrice: 800,
      priceRange: [0,999999],
      currentPage: 1,
      limit: 25,
      selectedids: [],
      sidePanelMaxHeight: 420,
      orderDesc: true,
      order: 'desc',
      sortby: 'id',
      error_reponse: '',
      error_message: false,
      success_message: false, 
      success_text: 'Your action was successful',
      trade_active: '',
      public_active: '',
      brands: '',
      collections: '',
      selectedids: [],
      isCheckAll: false,
      theme: {
        id: '',
        uid: '',
        name: '',
        description: '',
        active: '',
        styles: '',
      },
      theme_raw: {
        id: '',
        uid: '',
        name: '',
        description: '',
        active: '',
        styles: '',
      },
      active: '',
      themeStyles: themeStyles,
      view: 'grid',
      fields: [
        { key: "id", sortable: true },
        { key: "name", sortable: true },
        ],
      filter: null,
      filterOn: [],
      limits: [
        {value: 25, text: "25"},
        {value: 50, text: "50"},
        {value: 100, text: "100"},
      ],
      discountRates: [],
    };
  },
  created(){
      window.addEventListener("resize", this.windowResize);
    },
    destroyed() {
            window.removeEventListener("resize", this.windowResize);
        },
    mounted(){
          this.sidePanelMaxHeight = window.innerHeight - 120;
        },
    methods: {
      loadTheme(item){
        this.loadingTheme = true;
        this.loadingThemeProgress = 49;
        let self = this;
          setTimeout(function(){
            self.theme = item;
            self.addNew = true;
            EventBus.$emit('loadTheme');
            self.loadingThemeProgress = 100;
            self.loadingTheme = false;
          }, 2000);
      },
      addPageToTemplate(template_uid, template_id, page_id){
      axios.post('/api/company/web/template/'+template_uid+'/add-page', {temp_id: template_id, pag_id: page_id}, {headers: {
          'Authorization' : 'Bearer '+this.$attrs.user.api_token
        }}).then(response => {
        var index = this.pagesData.data.findIndex(item => item.uid == template_uid);
        this.pagesData.data[index].pages = response.data;
      }).catch(error => {

      });
    },
    removePageFromTemplate(template_uid, template_id, page_id){
       axios.post('/api/company/web/template/'+template_uid+'/remove-page', {temp_id: template_id, pag_id: page_id}, {headers: {
          'Authorization' : 'Bearer '+this.$attrs.user.api_token
        }}).then(response => {
        var index = this.pagesData.data.findIndex(item => item.uid == template_uid);
        this.pagesData.data.splice(index, 1);
      }).catch(error => {

      });
    },
    templateOnPage(pages, page_uid){
      var index = pages.findIndex(item => item.uid == page_uid);
      if(index > -1){
        return true;
      }else{
        return false;
      }
    },
    setActive(active){
            axios.post('/api/company/web/theme/set-active', {ids: this.selectedids, active: active}, {headers: {
              'Authorization' : 'Bearer '+this.$attrs.user.api_token
            }}).then(response => {
              
              this.success_text = 'Congratulations, your action has been saved';
              this.success_message = true;
              if(this.selectedids.length > 1){
                return this.productQuery();
              }else{
                return true;
              }
                
            }).catch(error => {

            });
    },
    makeDefault(active){
            axios.post('/api/company/web/theme/make-default', {ids: this.selectedids, active: active}, {headers: {
              'Authorization' : 'Bearer '+this.$attrs.user.api_token
            }}).then(response => {
              
              this.success_text = 'Congratulations, your default theme has been updated!';
              this.success_message = true;
                return this.productQuery();
              
                
            }).catch(error => {

            });
    },
   destroyTheme(){
      axios.post('/api/company/web/theme/'+this.selectedids[0]+'/delete', {}, {headers: {
        'Authorization' : 'Bearer '+this.$attrs.user.api_token
      }}).then(response => {
        //var index = this.pagesData.data.findIndex(item => item.uid == uid);
        //this.pagesData.data.splice(index, 1);
        this.$bvModal.hide('delete-modal');     
                this.selectedids = [];
        return this.productQuery();
      }).catch(error => {

      });
    },
    bulkDestroyResource(){
            axios.post('/api/company/web/theme/bulk-destroy', {ids: this.selectedids}, {headers: {
              'Authorization' : 'Bearer '+this.$attrs.user.api_token
            }}).then(response => {
                this.$bvModal.hide('delete-modal');     
                this.selectedids = [];
                return this.productQuery();
            }).catch(error => {

            });
     },
     bulkDuplicateResource(){
      axios.post('/api/company/web/theme/duplicate', {ids: this.selectedids}, {headers : {
        'Authorization' : 'Bearer '+this.$attrs.user.api_token
      }}).then(response => {
        console.log(response);
        this.success_text = 'Congratulations, your collection(s) have been updated.';
        this.success_message = true;
        //this.resetProjectData();
        this.addPanel = false;
        let self = this;
          setTimeout(function(){
            self.loadingForm = false;
            self.productQuery();
            //self.$bvModal.hide('move-modal');
          }, 1500);
      }).catch( error => {
        
        this.error_reponse = error.response.data.errors;
        this.error_message = true;
        //this.resetProjectData();
        let self = this;
          setTimeout(function(){
            self.loadingForm = false;
          }, 1500);
      });
    },
    pagesQuery(){
          axios.get('/api/company/web/pages?page=1&limit='+this.pageslimit+'&sortby=id&order=DESC&search='+this.pagessearch+'&active=1', {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
            console.log(response);
            this.pages = response.data;
          }).catch(error => {
            this.error_reponse = error.response.error;
            this.error_message = true;
          });
    },
    templatesQuery(){
      axios.get('/api/company/web/templates?limit='+this.pageslimit+'&sortby=id&order=DESC&search='+this.pagessearch+'&active=1', {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
        console.log(response);
        this.templates = response.data;
      }).catch(error => {
        this.error_reponse = error.response.error;
        this.error_message = true;
      });
    },
      windowResize(e) {
            console.log(window.innerHeight);
    
            this.sidePanelMaxHeight = window.innerHeight - 120;
   
           
            },
    valuechange(value) {
      this.clothsData = clothsData.filter(function (product) {
        return product.newprice <= value.currentValue;
      });
    },

    searchFilter(e) {
      const searchStr = e.target.value;
      this.productQuery();
    },

    filterBrands(value){
      this.brands = value;
      this.productQuery();
    },

     filterCollections(value){
      this.collections = value;
      this.productQuery();
    },

    discountLessFilter(e, percentage) {
      if (e === "accepted" && this.discountRates.length === 0) {
        this.clothsData = clothsData.filter((product) => {
          return product.discount < percentage;
        });
      } else {
        this.clothsData = clothsData.filter((product) => {
          return product.discount >= Math.max.apply(null, this);
        }, this.discountRates);
      }
    },

    discountMoreFilter(e, percentage) {
      if (e === "accepted") {
        this.discountRates.push(percentage);
      } else {
        this.discountRates.splice(this.discountRates.indexOf(percentage), 1);
      }
      this.clothsData = clothsData.filter((product) => {
        return product.discount >= Math.max.apply(null, this);
      }, this.discountRates);
    },
    createTheme(){
      //this.theme.styles = this.themeStyles;
      this.theme.styles = {elements: [], themeconfigs: [], themecontent: [], custom_classes: []};
      axios.post('/api/company/web/theme/create', {data: this.theme, raw_css: '', raw_json: ''}, {headers: {
        'Authorization' : 'Bearer '+this.$attrs.user.api_token
      }}).then(response => {
        this.theme = response.data;
        this.theme["pages"] = [];
        this.theme["templates"] = [];
        this.theme["active"] = 1;
        this.templateModal = false;
        this.addNew = true;
        EventBus.$emit('loadTheme');
        this.addNewModal = false;
        Swal.fire("Saved", "Your template has been updated.", "success");
      }).catch(error => {

      });
    },
     productQuery(){
      if(this.pagesData != ''){
        if(this.limit > this.pagesData.total){
          this.currentPage = 1;
        }
      }
      if(this.orderDesc == true){
        this.order = 'desc';
      }else{
        this.order = 'asc';
      }
      axios.get('/api/company/web/themes?page='+this.currentPage+'&limit='+this.limit+'&sortby='+this.sortby+'&order='+this.order+'&search='+this.search+'&active='+this.active, {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
        console.log(response);
        this.pagesData = response.data;
      }).catch(error => {
        this.error_reponse = error.response.error;
        this.error_message = true;
      });
    },
     filterSelected(id){
        if(this.selectedids.length > 0){
          var exists = this.selectedids.filter(item => item == id);
          if(exists.length > 0){
            return true;
          }else{
            return false
          }
        }else{
          return false;
        }
      },
      checkAll: function(){ 
                 this.isCheckAll = !this.isCheckAll; 
                 this.selectedids = []; 
                 if(this.isCheckAll){ 
                     // Check all 
                     for (var key in this.pagesData.data) {          
                        this.selectedids.push(this.pagesData.data[key].id); 
                     } 
                 } 
              }, 
      updateCheckall: function(){ 
                 if(this.selectedids.length == this.pagesData.data.length){
                    this.isCheckAll = true; 
                 }else{ 
                    this.isCheckAll = false; 
                 } 
      },
  },
  beforeMount(){
      this.productQuery();
      this.pagesQuery();
      this.templatesQuery();
  },
  computed:{
   
  }
};
</script>

<template>
  <Layout  :nickname="$attrs.nickname" :user="$attrs.user" :menuItems="$attrs.menuItems" >
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <!--<transition name="slide-fadepanel">
        <div v-if="addNew == true" class="bg-white position-fixed top-0 bottom-0 right-0 left-0 edit-sidepanel-fullwidth" style="z-index: 1006">
         
          <b-row class="justify-content-start h-100">
          <b-col md="3" class="bg-light position-relative pb-0 px-0" v-if="addNew == true" >
            <h5 class="pt-4 pb-3 px-3 bg-primary text-white d-flex">
              <div class="my-auto mr-auto d-flex flex-column justify-content-center">
                <span>Theme Options</span>
                <small>Theme: {{theme.name}}</small>
              </div>
               <a class="btn btn-primary btn-rounded  mr-2 my-auto" @click="addNew = !addNew">
            <i class="mdi mdi-close"></i>
          </a>
            </h5>
            <simplebar style="overflow-y: auto;" :style="{'max-height' : sidePanelMaxHeight - 35 +'px'}">
                    <b-card no-body class="mb-0">
                <b-card-header header-tag="header" class="p-0" role="tab">
                  <b-button block v-b-toggle="'accordion-configs'" variant="light" class="text-left text-capitalize">
                  <h5 class="mb-0"><i class="bx bx-chevron-right text-primary"></i>
                  Images</h5></b-button>
                </b-card-header>
                <b-collapse :id="'accordion-configs'" visible accordion="my-accordion" role="tabpanel">
                <widgetConfigsTab :user="$attrs.user" :company="$attrs.company" :widgetbox="false" :configs="theme.styles.themeconfigs" :content="theme.styles.themecontent" @storage-closed="hideDesign = true" @storage-toggled="hideDesign = $event" />
              </b-collapse>
            </b-card>
            <div class="accordion" role="tablist" v-for="(element, index, key) in theme.styles.elements">
              <b-card no-body class="mb-0" v-for="fields, fieldkey in element" >
                <b-card-header header-tag="header" class="p-0" role="tab">
                  <b-button block v-b-toggle="'accordion-'+fieldkey" variant="light" class="text-left text-capitalize">
                  <h5 class="mb-0"><i class="bx bx-chevron-right text-primary"></i>
                  {{fieldkey.replaceAll('_', ' ')}}</h5></b-button>
                </b-card-header>
                <b-collapse :id="'accordion-'+fieldkey" :visible="index == 0" accordion="my-accordion" role="tabpanel">
                  <b-card no-body class="mb-0" v-for="(type, typek) in element[fieldkey]">
                <b-card-header header-tag="header" class="p-0" role="tab">
                  <b-button block v-b-toggle="'subaccordion-'+typek" variant="outline-light" class="text-left text-capitalize">
                    <h5 class="mb-0"> <i class="bx bx-chevron-right text-primary"></i>
                  {{(typek).replaceAll('_', ' ')}}</h5></b-button>
                </b-card-header>
                  <b-collapse :id="'subaccordion-'+typek" :visible="index == 0" accordion="sub-accordion" role="tabpanel">
                    <stylesBlock :styles="type" :user="$attrs.user" :company="$attrs.company" 
                          :widgetbox="false" @showFileManager="" :element="'row'"></stylesBlock>
                      </b-collapse>
                  </b-card>
                </b-collapse>
              </b-card>
            </div>
          </simplebar>
          <div class="position-absolute bottom-0 right-0 left-0 d-flex bg-white p-3">
            <a class="btn btn-danger btn-rounded w-50 mr-1" @click="addNew = !addNew">
              Cancel
            </a>
            <a class="btn btn-success btn-rounded w-50 ml-1" @click="updateTheme()">
              Save
            </a>
          </div>
          </b-col>
          <b-col md="9" class="p-0" v-if="!hideDesign">
            <simplebar style="overflow-y: auto; overflow-x: hidden;" :style="{'max-height' : (sidePanelMaxHeight + 120 ) +'px'}">
            <b-row>
              <b-col md="6">
                <img src="/images/product-placeholder.png" class="avatar-md">
              </b-col>
              <b-col md="6" class="d-flex">
                <div class="dropdown d-none d-lg-inline-block ml-auto">
                <button  
                  type="button"
                  class="btn header-item noti-icon"
                               >
                  <i class="bx bx-search"></i>
                </button>
                <button  
                  type="button"
                  class="btn header-item noti-icon"
                               >
                  <i class="bx bx-pound"></i>
                </button>
                <button  
                  type="button"
                  class="btn header-item noti-icon"
                               >
                  <i class="bx bx-shopping-bag"></i>
                </button>
                <b-dropdown
           
                right
                variant="black"
                toggle-class="header-item"
                menu-class="dropdown-menu-end"
               
              >
                <template v-slot:button-content>
                 
                  <div>
                    <img
                    class="rounded-circle header-profile-user"
                    src="/images/product-placeholder.png"
                    alt="Sign in to Gemesys"
                  />
                  <span class="d-none d-xl-inline-block ml-1">
                    My Account
                  </span>
                  <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
                  </div>

                  
                </template>
              </b-dropdown>
              </div>
              </b-col>
              <b-col md="12" class="py-3 border-bottom border-top">
                <b-nav align="center">
                  <b-nav-item active>Active</b-nav-item>
                  <b-nav-item>Link</b-nav-item>
                  <b-nav-item>Link with a long name </b-nav-item>
                  <b-nav-item disabled>Disabled</b-nav-item>
                </b-nav>
              </b-col>
            </b-row>
            <b-row class="bg-primary bg-soft d-flex flex-column justify-content-center" style="height: 500px;">
              <b-col md="12" class="text-center">
                <h1>Heading 1 Text</h1>
                <p>This is an example of some paragraph text</p>
              </b-col>
            </b-row>
            <b-row class="px-5 pt-5">
            <b-col md="8">
              <span class="font-size-20 mb-3 border-bottom d-block">Fonts</span>
              <b-card class="border">
                 <span class="font-size-20 mb-3 border-bottom d-block">Headers</span>
              <h1>Heading 1</h1>
              <h2>Heading 2</h2>
              <h3>Heading 3</h3>
              <h4>Heading 4</h4>
              <h5>Heading 5</h5>
              <span class="font-size-20 mb-3 mt-4  border-bottom d-block">Paragraph</span>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum
              </p>
              <span class="font-size-20 mb-3 mt-4  border-bottom d-block">Links</span>
              <a>
               Link Text
              </a>
              <span class="font-size-20 mb-3 mt-4  border-bottom d-block">Blockquote</span>
              <blockquote>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum
              </blockquote>
            </b-card>
            <span class="font-size-20 mb-3 border-bottom d-block">Horizontal Card</span>
             <b-card class="border" no-body>
               <b-row no-gutters>
              <b-col md="4">
                <b-card-img src="https://picsum.photos/400/400/?image=20" alt="Image" class="rounded-0"></b-card-img>
              </b-col>
              <b-col md="8">
                 <b-card-body title="Sample Card Styles">
                    <b-card-text>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    </b-card-text>
                    <a class="btn btn-primary mb-2">
                      Primary Button
                    </a>
                    <a class="btn btn-secondary mb-2">
                      Secondary Button
                    </a>
                  </b-card-body>
                </b-col>
              </b-row>
              </b-card>
            </b-col>
            <b-col md="4">
              <span class="font-size-20 mb-3 border-bottom d-block">Buttons</span>
              <b-card class="border">
              <a class="btn btn-primary btn-block mb-2">
                Primary Button
              </a>
              <a class="btn btn-secondary btn-block mb-2">
                Secondary Button
              </a>
            </b-card>
            <span class="font-size-20 mb-3 border-bottom d-block">Vertical Card</span>
             <b-card class="border" title="Sample Card Styles" img-src="https://picsum.photos/300/225.jpg" img-top>
                <b-card-text>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                </b-card-text>
              <a class="btn btn-primary mb-2">
                Primary Button
              </a>
              <a class="btn btn-secondary mb-2">
                Secondary Button
              </a>
              </b-card>
              <span class="font-size-20 mb-3 border-bottom d-block">Pagination</span>
              <b-pagination :total-rows="10" :per-page="5"  ></b-pagination>
              <span class="font-size-20 mb-3 border-bottom d-block">Filters</span>
              <b-pagination :total-rows="10" :per-page="5"  ></b-pagination>
               <span class="font-size-20 mb-3 border-bottom d-block">Default Searchbar</span>
              <div class="mt-sm-0 form-inline w-100"><div class="app-search search-box mr-2 ml-auto"><div class="position-relative"><input type="text" placeholder="Search name, reference or description..." class="form-control w-100 border"> <span class="bx bx-search-alt search-icon"></span></div></div></div>
            </b-col>
          </b-row>
           <b-row class="px-5 pt-3">
            <span class="font-size-20 mb-3 border-bottom d-block">Card Variations</span>
            <b-col md="4">
             <b-card id="product-card" no-body class="border shadow-none border-radius-1em" @mouseenter="producthoverIndex = 1" @mouseleave="producthoverIndex = -1" >
            
           
            <b-card-img :src="'/images/banner-placeholder.png'" />
           
            <b-card-text class="p-4 peach-border-top-5 d-flex flex-column">
              <h3 class="font-size-18 my-auto py-2">Product Name</h3>
              <p class="text-dark text-truncate mb-0" v-html="'Here is a product description'"></p>
              <div class="d-flex">
              <span class="badge badge-light text-dark bg-light font-size-14 my-2">
                £1,000
              </span>
            <transition name="fade-right">
              <a v-if="producthoverIndex == 1" class="border-bottom border-primary text-uppercase font-size-12 ml-auto text-dark my-auto py-2" href="javascript:;">
                See Product <i class="bx bx-chevron-right"></i>
              </a>
            </transition>
              </div>
            </b-card-text>
          </b-card>
            </b-col>
            <b-col md="4">
             <b-card id="category-card" no-body @mouseenter="hoverIndex = index" @mouseleave="hoverIndex = -1" >
                
                <b-card-img  :src="'/images/banner-placeholder.png'" />
                <b-card-body>
                <b-card-text>
                  <h2 class="font-size-18 my-auto py-2">Category / Collection Name</h2>
                  <p class="text-muted">Here is a product description</p>
                  <div>
                    <a href="javascript:;">
                          See Products <i class="bx bx-chevron-right"></i>
                        </a>
                    <transition name="fade-right">
                      <a v-if="(hoverIndex == index)" href="javascript:;">
                          See Products <i class="bx bx-chevron-right"></i>
                        </a>
                    </transition>
                  </div>
                  </b-card-text>
                </b-card-body>
                </b-card>
            </b-col>
            <b-col md="4">
            <b-card class="card overflow-hidden border-radius-1em shadow-sm seller-profile-card" no-body @mouseenter="hoverIndex = index" @mouseleave="hoverIndex = -1">
          <div class="bg-soft bg-primary">
            <div class="row">
              <transition name="fade-left">
                          <div class="col-md-12 position-absolute z-index-2" v-if="hoverIndex == index"> 
                                <a class="mt-3 ml-3 btn btn-primary font-size-16 btn-rounded ml-auto float-left"><i class="mdi mdi-eye-outline"></i></a>
                              </div>
                          </transition>
                           
                       <div class="col-12">

                <a :href="'javascript:;'">
                        <b-card-img-lazy src="/images/banner-placeholder.png" alt class="img-fluid" /></b-card-img-lazy>
                        </a>
              
              </div>
            </div>
          </div>
          <b-card-body class="card-body pt-0">
            <div class="row">
              <div class="col-sm-12 pb-4 text-center">
                <div class="avatar-md profile-user-wid mb-2 mx-auto" style="margin-top: -45px !important;">
                  
                  <span class="avatar-title avatar-md bg-light text-primary rounded-circle">
                    <i class="bx bx-map-pin font-size-20"></i>
                  </span>
                </div>
                <h5 class="font-size-15 text-truncate">Listing Name</h5>
                <p class="text-muted mb-0">
                  <span>50 Kings Street</span>
                  <span>Aldgate</span>
                  <span>Oxford</span>
                  <span>Oxfordshire</span>
                  <span>OX12 122</span>
                </p>
              </div>
              <div class="col-md-12 d-flex">
                <span class="badge badge-light border-radius-1em bg-light text-dark font-size-11 lh-16 my-auto p-2 mx-auto">
                  Listing Type
                </span>
              </div>
             

            </div>
          </b-card-body>
        </b-card>
            </b-col>
           </b-row>
           <b-row>
           <DefaultFooter :company="$attrs.company" :user="$attrs.user" />
         </b-row>
        </simplebar>
          </b-col>
          </b-row>
          
        </div>
      </transition>-->
      <ThemeBuilder ref="themeBuilder" :hideDesign="false" :theme="theme" :user="$attrs.user" :company="$attrs.company" :availableStyles="themeStyles" :availableStylesRaw="themeStyles" />
      <b-col md="12">
                    <transition name="slide-success" mode="out-in">
                  <b-alert id="success-message" variant="success" :show="success_message"  dismissible @dismissed="success_message = !success_message">
                      <p class="font-size-14">{{success_text}}</p>
                    </b-alert>
                  </transition>
                  </b-col>
      <div :class="{'col-lg-12': showFilters == true, 'col-lg-12': showFilters == false}">
        <div class="row mb-3">
          <div class="col-xl-1 col-sm-6">
            <div class="mt-2">
              <b-form-select class="form-control" v-model="limit" :options="limits" @input="productQuery()"></b-form-select>
            </div>
          </div>
         
          <div class="col-lg-2 col-sm-6 d-flex">
          <form class="my-auto mr-auto">
            <small class="mx-auto my-2 text-center d-block">Results {{pagesData.from}} - {{pagesData.to}} of {{pagesData.total}}</small>
          </form>
          <div v-if="view == 'grid'" class="text-center my-auto ml-auto"><div
                        class="form-check form-switch form-switch-sm mb-0 mr-auto p-0 mt-auto d-flex"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input mx-auto my-auto" 
                          type="checkbox"
                          id="SwitchCheckSizeCheckall"
                         
                          v-model="isCheckAll"
                          @click='checkAll()'
                          name="active"
                        />
                        <label class="d-inline-block my-auto ml-2" for="SwitchCheckSizeCheckall">Select All</label>
                    </div></div>
        </div>
        <div class="col-lg-5 col-sm-6 d-flex">
            <div class="mt-sm-0 form-inline w-100" >
              <div class="search-box mr-2 ml-auto">
                <div class="position-relative">
                  <input
                    type="text"
                    class="form-control w-100 border-0"
                    placeholder="Search name, reference or description..."
                    v-model="search"
                    @keyup.enter="searchFilter($event)"
                  />
                  <i class="bx bx-search-alt search-icon"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-sm-6 d-flex">
            <div class="d-flex ml-auto">
              <ul class="nav nav-pills product-view-nav">
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': showFilters == true }" href="javascript: void(0);" @click="showFilters = !showFilters">
                    <i class="bx bx-filter-alt"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': view == 'grid' }" href="javascript: void(0);" @click="view = 'grid'">
                    <i class="bx bx-grid-alt"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': view == 'list' }" href="javascript: void(0);" @click="view = 'list'">
                    <i class="bx bx-list-ul"></i>
                  </a>
                </li>
                <li class="nav-item">
                 <b-dropdown text="Bulk Actions" variant="primary" class="rounded">
                    <b-dropdown-item href="javascript: void(0);" @click="setActive(true)"><i class="bx bx-check-circle font-size-14 text-success"></i> Set Active</b-dropdown-item>
                  <b-dropdown-item href="javascript: void(0);" @click="setActive(false)"><i class="bx bx-x-circle font-size-14 text-danger"></i> Set Inactive</b-dropdown-item>
                  <b-dropdown-item href="javascript: void(0);" @click="bulkDuplicateResource()"><i class="bx bx-copy-alt font-size-14"></i> Duplicate</b-dropdown-item>
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-item href="javascript: void(0);" v-b-modal.delete-modal @click="deleteMode = 'bulk'"><i class="bx bx-trash-alt font-size-14"></i> Delete</b-dropdown-item>
                </b-dropdown>
                </li>
                <li class="nav-item">
                 <a
                    class="btn btn-success btn-rounded mb-2 mr-2 text-white"
                    @click="addNewModal = !addNewModal"
                  >
                    <i class="mdi mdi-plus mr-1"></i> New
                  </a>
                </li>
              </ul>
              <div class="text-sm-right">
                  
                </div>
          </div>
        </div>
      </div>
        <!--<div class="row">
          <div class="col-lg-12">
            <b-pagination
              v-if="pagesData.data.length > 0"
              class="justify-content-center mb-0"
              pills
              v-model="currentPage"
              :total-rows="pagesData.total"
              :per-page="pagesData.per_page"
              aria-controls="my-table"
              @input="productQuery"
            ></b-pagination>
             <small class="mx-auto my-2 text-center d-block">Records {{pagesData.from}} - {{pagesData.to}} of {{pagesData.total}} // Page {{pagesData.current_page}} of {{pagesData.last_page}}</small>
          </div>
        </div>-->
    <b-overlay :show="loadingTheme" variant="white" opacity="0.9">
         <template #overlay>
          <b-row>
                  <b-col md="12" class="offset-md-2" >
                  <b-card class="border-radius-1em border shadow-none mb-0">  
                      <div class="cube-wrapper mx-auto"><div class="cube-folding"><span class="leaf1"></span><span class="leaf2"></span><span class="leaf3"></span><span class="leaf4"></span></div></div>
                      <h6 class="text-center">Compiling Theme...</h6>
                       <b-progress class="w-50 mx-auto" v-model="loadingThemeProgress" variant="primary"></b-progress>
                  </b-card>
                  </b-col>
                  </b-row>
        </template>
      <b-row  v-if="(view == 'grid') && ('data' in pagesData && pagesData.data.length > 0)" >
       
      <div id="my-table" v-for="list in pagesData.data" :key="list.id" class="col-xl-4 col-sm-6">
        <div class="card border-radius-1em">
            <!-- <div class="d-flex w-100">
                  <a class="text-muted ml-auto my-auto" href="javascript:;" @click="theme = list, addNew = true">
                    <i class="bx bx-edit-alt"></i>
                  </a>
                  <b-dropdown id="template-menu" variant="white" class="rounded" right>
                   <template #button-content>
                        <i class="bx bx-dots-vertical font-size-18"></i>
                      </template>
                      <b-dropdown-text class="mb-0 px-3 py-2 bg-light mt-1" text-class="mb-0">
                        Apply To: 
                      </b-dropdown-text>

                      <b-dropdown-text class="list-group list-group-flush mb-0" text-class="list-group-item mb-0" @mouseenter="showPageTools = index" @mouseleave="showPageTools = -1">

                      <i class="mdi mdi-plus">
                        </i>  Pages
                        <transition name="fade-right">
                        <b-list-group v-if="showPageTools == index" flush class="pages-submenu hide" style="position: absolute; left: -150%; top: 0; width: 150%" >
                        <b-list-group-item v-for="page in pages.data">
                          <span class="d-flex" v-if="templateOnPage(list.pages, page.uid) == true">
                            <span class="my-auto mr-auto">{{page.name}}</span>
                            <a href="javascript:;" class="btn btn-light btn-rounded" title="Remove From Template" @click="removePageFromTemplate(list.uid, list.id, page.id)">
                            <i class="bx bx-check-circle text-success"></i>
                            </a>
                          </span>
                          <span class="d-flex" v-else>
                            <span class="my-auto mr-auto">{{page.name}}</span>
                            <a href="javascript:;" class="btn btn-light btn-rounded" title="Add to Template" @click="addPageToTemplate(list.uid, list.id, page.id)">
                            <i class="mdi mdi-plus"></i>
                          </a>
                          </span>
                        </b-list-group-item>
                        </b-list-group>
                      </transition>
                      </b-dropdown-text>
                      <b-dropdown-text class="list-group list-group-flush mb-0" text-class="list-group-item mb-0" @mouseenter="showTemplateTools = index" @mouseleave="showTemplateTools = -1">

                      <i class="mdi mdi-plus">
                        </i> Templates
                        <transition name="fade-right">
                        <b-list-group v-if="showTemplateTools == index" flush class="pages-submenu hide" style="position: absolute; left: -150%; top: 0; width: 150%" >
                        <b-list-group-item v-for="page in templates.data">
                          <span class="d-flex" v-if="templateOnPage(list.templates, page.uid) == true">
                            <span class="my-auto mr-auto">{{page.name}}</span>
                            <a href="javascript:;" class="btn btn-light btn-rounded" title="Remove From Template" @click="removePageFromTemplate(list.uid, list.id, page.id)">
                            <i class="bx bx-check-circle text-success"></i>
                            </a>
                          </span>
                          <span class="d-flex" v-else>
                            <span class="my-auto mr-auto">{{page.name}}</span>
                            <a href="javascript:;" class="btn btn-light btn-rounded" title="Add to Template" @click="addPageToTemplate(list.uid, list.id, page.id)">
                            <i class="mdi mdi-plus"></i>
                          </a>
                          </span>
                        </b-list-group-item>
                        </b-list-group>
                      </transition>
                      </b-dropdown-text>
                    
                      <b-dropdown-item href="javascript:;" @click="duplicateTemplate(list)">
                        Duplicate
                      </b-dropdown-item>
                      <b-dropdown-item href="javascript:;" @click="destroyTheme(list.uid)">
                        Delete
                      </b-dropdown-item>
                    </b-dropdown>
                    </div>-->
          <div class="bg-soft-primary bg-light border-radius-top-right-1em border-radius-top-left-1em" style="min-height: 150px;">
            <div class="row">
              
              <div class="col-12 align-self-end">
                <img v-if="list.thumbnail" :src="`${list.thumbnail}`" alt class="img-fluid border-radius-top-right-1em border-radius-top-left-1em" />
                
              </div>
            </div>
          </div>
          <div class="card-body pt-0">

            <div class="row">
              <div class="col-sm-12 text-center px-4 mb-2">
               <!-- <div class="avatar-xl profile-user-wid mb-4">
                  <img class="img-thumbnail rounded-circle h-100" :src="`/public/storage/${list.image}`" alt />
                </div>-->
                <div class="avatar-sm profile-user-wid mx-auto">
                   <div class="text-center" v-if="list.image">
                      <b-form-checkbox class="select-row-btn" v-model="selectedids" :value="list.id" button-variant="primary" @input="updateSelected" button>
                        <i v-if="filterSelected(list.id)" class="bx bx-check"></i>
                        <img v-else :src="`${list.image}`" alt class="avatar-sm img-thumbnail rounded-circle h-100" />
                      </b-form-checkbox>
                    </div>
                    <div class="text-center" v-else >
                        <b-form-checkbox class="select-row-btn" v-model="selectedids" :value="list.id" button-variant="primary" @input="updateSelected" button>

                          <i v-if="filterSelected(list.id)" class="bx bx-check"></i><span class="avatar-title rounded-circle" v-else>{{(list.name).slice(0,1)}}</span></b-form-checkbox>
                    </div> 
                </div>
                <h4 class="font-size-18 text-truncate mt-3">{{list.name}}</h4>
                <p class="text-muted mb-0 text-truncate" v-html="list.description"></p>
              </div>

              <div class="col-sm-12">
              
              </div>
            </div>
          </div>
          <div class="card-footer  bg-transparent border-top">
                  <div class="d-flex">
                    
                    <a id="tooltip-add" @click="loadTheme(list)" class="btn btn-outline-light font-size-16 btn-rounded my-auto"><i class="bx bx-pencil"></i></a>
                    <a href="javascript:;" @click="selectedCategory = list, getProducts(list.id), getProductsPanel = 1" class="btn btn-outline-light btn-rounded ml-2"><i class="mdi mdi-eye-outline my-1"></i></a>
                    <b-button variant="outline-light" :disabled="list.default == 1" href="javascript:;" @click="selectedids = [], selectedids.push(list.id), makeDefault(list.active)" class="btn btn-outline-light btn-rounded ml-2"><i class="bx bx-star my-1" :class="{'text-warning' : list.default == 1}"></i></b-button>
                    <a class="btn btn-outline-light font-size-16 btn-rounded ml-2 my-auto" v-b-tooltip.hover title="Delete" placement="left"  @click="selectedids = [], selectedids.push(list.id), deleteMode = 'single'" v-b-modal.delete-modal><i class="bx bx-trash-alt"></i>
                          </a>
                         <b-dropdown v-b-tooltip.hover title="More Options" placement="left"  variant="outline-light"  dropup class="more-options-dropdown btn-rounded dropup ml-2" menu-class="dropdown-menu-right">
                   <template #button-content>
                        <i class="bx bx-dots-horizontal-rounded font-size-18"></i>
                      </template>
                      <b-dropdown-text class="mb-0 px-3 py-2 bg-light mt-1" text-class="mb-0">
                        Apply To: 
                      </b-dropdown-text>

                      <b-dropdown-text class="list-group list-group-flush mb-0" text-class="list-group-item mb-0" @mouseenter="showPageTools = index" @mouseleave="showPageTools = -1">

                      <i class="mdi mdi-plus">
                        </i>  Pages
                        <transition name="fade-right">
                        <b-list-group v-if="showPageTools == index" flush class="pages-submenu hide" style="position: absolute; right: -150%; top: 0; width: 150%" >
                        <b-list-group-item v-for="page in pages.data">
                          <span class="d-flex" v-if="templateOnPage(list.pages, page.uid) == true">
                            <span class="my-auto mr-auto">{{page.name}}</span>
                            <a href="javascript:;" class="btn btn-light btn-rounded" title="Remove From Template" @click="removePageFromTemplate(list.uid, list.id, page.id)">
                            <i class="bx bx-check-circle text-success"></i>
                            </a>
                          </span>
                          <span class="d-flex" v-else>
                            <span class="my-auto mr-auto">{{page.name}}</span>
                            <a href="javascript:;" class="btn btn-light btn-rounded" title="Add to Template" @click="addPageToTemplate(list.uid, list.id, page.id)">
                            <i class="mdi mdi-plus"></i>
                          </a>
                          </span>
                        </b-list-group-item>
                        </b-list-group>
                      </transition>
                      </b-dropdown-text>
                      <b-dropdown-text class="list-group list-group-flush mb-0" text-class="list-group-item mb-0" @mouseenter="showTemplateTools = index" @mouseleave="showTemplateTools = -1">

                      <i class="mdi mdi-plus">
                        </i> Templates
                        <transition name="fade-right">
                        <b-list-group v-if="showTemplateTools == index" flush class="pages-submenu hide" style="position: absolute; right: -150%; top: 0; width: 150%" >
                        <b-list-group-item v-for="page in templates.data">
                          <span class="d-flex" v-if="templateOnPage(list.templates, page.uid) == true">
                            <span class="my-auto mr-auto">{{page.name}}</span>
                            <a href="javascript:;" class="btn btn-light btn-rounded" title="Remove From Template" @click="removePageFromTemplate(list.uid, list.id, page.id)">
                            <i class="bx bx-check-circle text-success"></i>
                            </a>
                          </span>
                          <span class="d-flex" v-else>
                            <span class="my-auto mr-auto">{{page.name}}</span>
                            <a href="javascript:;" class="btn btn-light btn-rounded" title="Add to Template" @click="addPageToTemplate(list.uid, list.id, page.id)">
                            <i class="mdi mdi-plus"></i>
                          </a>
                          </span>
                        </b-list-group-item>
                        </b-list-group>
                      </transition>
                      </b-dropdown-text>
                      <b-dropdown-item href="javascript: void(0);"  @click="selectedids = [], selectedids.push(list.id), bulkDuplicateResource()">Duplicate</b-dropdown-item>
                     
                      <b-dropdown-item href="javascript:;"  v-b-modal.delete-modal @click="selectedids = [], selectedids.push(list.id), deleteMode = 'single'">
                        Delete
                      </b-dropdown-item>
                    </b-dropdown>
                     <div
                        class="form-check form-switch form-switch-sm my-auto ml-auto d-flex mr-2"
                        dir="ltr"
                        v-if="list.default == 1"
                      >
                      <i class="bx bx-star font-size-18 text-warning"></i> <label class="form-check-label">Default</label>
                    </div>
                      <div :class="{'ml-auto': list.default == 0}"
                        class="form-check form-switch form-switch-sm my-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          :id="`SwitchCheckSize-${list.id}`"
                          v-model="list.active"
                          :value="list.active"
                          name="active"
                          
                          @change="selectedids = [], selectedids.push(list.id), setActive(list.active)"
                        />
                        <label class="form-check-label" :for="`SwitchCheckSize-${list.id}`" 
                          >Active</label
                        >
                    </div>
                  </div>
          </div>
        </div>
    </div>
 
  </b-row>
   
        
        <!-- end row -->

        
        <div class="card" v-else-if="(view == 'list') && ('data' in pagesData && pagesData.data.length > 0)" >
          <div class="card-body p-0">
            <div class="table-responsive">
              <table class="table table-centered table-hover">
                <thead class="thead-light">
                  <tr>
                   <th scope="col"  style="width: 100px" class="text-center">
                     <div
                        class="form-check form-switch form-switch-sm mb-0 mx-auto p-0 mt-auto d-flex"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input mx-auto my-auto" 
                          type="checkbox"
                          id="SwitchCheckSizesm"
                         
                          v-model="isCheckAll"
                          @click='checkAll()'
                          name="active"
                        />
                    </div>
                  </th>
                    <th scope="col" style="max-width: 300px"><a :class="{'text-dark': sortby != 'name'}" href="javascript: void(0);" @click="orderDesc = !orderDesc,  sortby = 'name', productQuery()">Name & Description <i v-if="orderDesc == true" class="bx bx-caret-up"></i><i v-if="orderDesc == false" class="bx bx-caret-down"></i></a></th>
                    
                    <th scope="col">Link</th>
                    <th scope="col">Parent Page</th>
                    
                    <th scope="col">Status</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="list in pagesData.data" :key="list.id" valign="middle">
                    <td>
                      <div class="text-center" v-if="list.cover_image">
                      <b-form-checkbox class="select-row-btn " v-model="selectedids" :value="list.id" button-variant="primary" @input="updateSelected" button>
                        <i v-if="filterSelected(list.id)" class="bx bx-check"></i>
                        <img v-else :src="`${storageurl+list.cover_image.src.replace('//', '/')}`" alt class="avatar-sm rounded-circle" />
                      </b-form-checkbox>
                    </div>
                    <div class="text-center" v-else >
                        <b-form-checkbox class="select-row-btn" v-model="selectedids" :value="list.id" button-variant="light" @input="updateSelected" button>

                          <i v-if="filterSelected(list.id)" class="bx bx-check"></i><span class="avatar-title rounded-circle" v-else>{{(list.name).slice(0,1)}}</span></b-form-checkbox>
                    </div> 
                    </td>
                    <td style="max-width: 550px">
                        <a :href="`/web/page/${list.uid}`" class="mb-0"><h6 class="mb-0">{{list.name}}</h6></a>
                        <p class="d-md-none d-block" v-html="list.short_description"></p>
                    </td>
                    <td><a 
                          href="javascript: void(0);"
                          class="badge badge-soft-light rounded-pill font-size-11 m-1"
                         v-if="list.brand != null"><img v-if="list.brand.logo" class="rounded-circle avatar-xs" :src="`${list.brand.logo}`" alt />  <img v-else  src="/images/banner-placeholder.png" alt="" class="rounded-circle avatar-xs"> <span class="text-dark">{{list.brand.name}}</span></a></td>
                    <td>
                      <div>

                        <a 
                          href="javascript: void(0);"
                          class="badge badge-soft-light rounded-pill font-size-11 m-1"
                         v-if="list.company_defaultcategory != null"><img class="rounded-circle avatar-xs" v-if="list.company_defaultcategory.image" :src="`${list.company_defaultcategory.image}`" alt /> <img v-else  src="/images/banner-placeholder.png" alt="" class="rounded-circle avatar-xs"> <span class="text-dark">{{list.company_defaultcategory.name}}</span></a>

                         <a 
                          href="javascript: void(0);"
                          class="badge badge-soft-light rounded-pill font-size-11 m-1"
                          v-for="category in list.company_categories"><img v-if="category.category.image" class="rounded-circle avatar-xs" :src="`${category.category.image}`" alt /> <img v-else src="/images/banner-placeholder.png" alt="" class="rounded-circle avatar-xs">  <span class="text-dark">{{category.category.name}}</span></a>

                        
                      </div>
                    </td>
                    
                    <td>
                      <div
                        class="form-check form-switch form-switch-sm mb-0 mt-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          id="SwitchCheckSizesm"
                          v-model="list.active"
                          value="1"
                          unchecked-value="0"
                          name="active"
                        />
                        <label class="form-check-label" for="SwitchCheckSizesm" 
                          >Active</label
                        >
                    </div>
                   
                    </td>
                    <td>
                       <ul class="list-inline font-size-20 contact-links mb-0">
                        <li class="list-inline-item px-2">
                          <a v-b-tooltip.hover title="Edit" placement="left"  :href="`/web/page/${list.uid}`">
                            <i class="bx bx-edit-alt"></i>
                          </a>
                        </li>
                        <li class="list-inline-item px-2">
                            <a v-b-tooltip.hover title="Preview" placement="left">
                            <i class="mdi mdi-eye-outline"></i>
                          </a>
                          </a>
                        </li>
                        <li class="list-inline-item px-2">
                          <a v-b-tooltip.hover title="Delete" placement="left"  v-b-modal.delete-modal @click="selectedids = [], selectedids.push(list.id), deleteMode = 'single'" >
                            <i class="bx bx-trash-alt"></i>
                          </a>
                        </li>
                        <li class="list-inline-item px-2">
                           <b-dropdown v-b-tooltip.hover title="More Options" placement="left" dropleft class="more-options-dropdown" menu-class="dropdown-menu-right">
                        <template v-slot:button-content>
                           <i class="bx bx-dots-horizontal-rounded"></i>
                        </template>
                          <b-dropdown-item href="javascript: void(0);"  @click="selectedids = [], selectedids.push(list.id), bulkDuplicateResource()"><i class="bx bx-copy-alt"></i> Duplicate</b-dropdown-item>
                      </b-dropdown>
                        </li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            
            <div class="row">
              <div class="col-lg-12" v-if="pagesData !== ''">
                <b-pagination
              class="justify-content-center"
              pills
              v-model="currentPage"
              :total-rows="pagesData.total"
              :per-page="pagesData.per_page"
              aria-controls="my-table"
              @input="productQuery"
            ></b-pagination>
             <small class="mx-auto my-2 text-center d-block">Records {{pagesData.from}} - {{pagesData.to}} of {{pagesData.total}} // Page {{pagesData.current_page}} of {{pagesData.last_page}}</small>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <NothingHere @add-new="addNewModal = !addNewModal"></NothingHere>
        </div>
        </b-overlay>
      </div>
       
    </div>
    <!-- end row -->
     <b-modal id="delete-modal" size="md" hide-footer hide-header centered>
        <b-card class="border-radius-1em border shadow-none">
          <h4>Are you sure?</h4>
          <p v-if="deleteMode == 'bulk'">You are about to delete {{selectedids.length}} products. Are you sure you want to do this? This action is not reversable</p>
           <p v-if="deleteMode == 'single'">You are about to delete this category. Are you sure you want to do this? This action is not reversable</p>

           <div class="d-flex">
            <a class="btn btn-danger" v-if="deleteMode == 'bulk'" @click="bulkDestroyResource">
              Delete
            </a>
             <a class="btn btn-danger"  v-if="deleteMode == 'single'" @click="destroyTheme">
              Delete
            </a>
            <a class="btn btn-success" @click="$bvModal.hide('delete-modal')">
              Cancel
            </a>
          </div>
        </b-card>
      </b-modal>
    <b-modal v-model="addNewModal" centered content-class="border-radius-1em" header-class="border-none" hide-footer >
      <h4 class="mb-4 text-center">Add New Theme</h4>
      <b-row>
       <b-col md="12">
             <b-card class="border border-radius-1em shadow-none">
            <b-form-group label="Theme Name" class="mt-2">
                <b-form-input type="text" v-model="theme.name"></b-form-input>
              </b-form-group>
              <b-form-group label="Theme Description" class="mt-2">
                <b-form-textarea type="text" v-model="theme.description"></b-form-textarea>
              </b-form-group>
             <!-- <b-form-group label="theme Thumbnail" class="mt-2">
                <vue-dropzone id="dropzone" ref="myVueDropzone" :use-custom-slot="true" :options="dropzoneOptions" @vdropzone-file-added="imageAdded"  @vdropzone-drop="imageAdded">
                              <div class="dropzone-custom-content">
                                  <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                                  <h4>Drop files here or click to upload.</h4>
                              </div>
                              </vue-dropzone>
              </b-form-group>
              <input type="hidden" name="thumbnail" v-model="theme.thumbnail" />-->
              <b-form-group label="Active" class="mt-2">
                         <div
                                class="form-check form-switch form-switch-lg w-75 d-flex"
                                dir="ltr"
                              >
                         
                              <input
                                  class="form-check-input ml-auto my-auto" 
                                  type="checkbox"
                                  id="SwitchCheckSizesm"
                                 
                                  v-model="theme.active"
                                
                                  name="active"
                                />
                            </div>
              </b-form-group>
              </b-card>
            </b-col>
            <b-col md="12"  @click="createTheme()">
            <a class="btn btn-success btn-block btn-rounded">
              Add New Theme
            </a>
          </b-col>
          </b-row>
    </b-modal>
  </Layout>
</template>
