<script type="text/javascript">
	export default{
		data(){ return {
				// calculation:'15*98',
				// tempResult:'1470',
				calculation:'',
				tempResult:'',
			}
		},
		mounted() {
		let btns = document.querySelectorAll('.btn')
		for (btn of btns) {
			btn.addEventListener('click',function() {
				this.classList.add('animate')
				this.classList.add('resetappearanim')
			})
			btn.addEventListener('animationend',function() {
				this.classList.remove('animate')
			})
		}
	},
		methods: {
			append(value) {
				this.calculation += value.toString()
			},
			clear() {
				this.calculation = ''
				this.tempResult = ''
			},
			getResult() {
				if(this.tempResult != ''){
					this.calculation = this.tempResult
					//this.tempResult = ''
				}
			},
			backspace() {
				this.calculation = this.calculation.slice(0,-1)
			}
		},
		watch: {
			calculation() {
				if(this.calculation !== '' && !isNaN(this.calculation.slice(-1)) && this.calculation != this.result ){
					this.tempResult = this.result.toString()
				}
			}
		},
		computed: {
			result() {
				if(!isNaN(this.calculation.slice(-1)))
					return eval(this.calculation)
				else
					return eval(this.calculation.slice(0, -1))
			},
			fontSize() {
				return this.fontSize = 50-(this.tempResult.length*1.25)
			}
		},
		filters: {
		  hugeNumber: (value) => {
			 let parts = value.toString().split(".");
			 parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
			 return parts.join(".");
		  },
			number: (value) => {
				return value.replaceAll('*','x')
			},
			calculation: (value) => {
				return value.replaceAll('x',' x ').replaceAll('/',' / ').replaceAll('+',' + ').replaceAll('-',' - ')
			}
		}
	}


</script>

<template>
<div id="standard-calculator"  @keyup.55="append('7')">
	<div id="calculator">
		<div class="screen-container">
			<div class="result">
				<transition name="slide-fade">
				<div class="number" :style="{fontSize : fontSize + 'px'}" v-if="tempResult !== ''"  v-cloak>{{ tempResult | number | hugeNumber }}</div>
				</transition>
			</div>
			<div class="calculation">
				<div class="number" v-cloak>{{ calculation | number | calculation}}</div>
			</div>
		</div>
		<div class="btn-container">
			<div class="oper-container">
					<div class="btn" id="clear" @click="clear()">
						<div class="number">AC</div>
					</div>
					<div class="btn operators divide" @click="append('/')">
						<div class="number">÷</div>
					</div>
					<div class="btn operators multiplication" @click="append('*')">
						<div class="number">×</div>
					</div>
					<div class="btn operators plus" @click="append('+')">
						<div class="number">+</div>
					</div>
					<div class="btn operators less" @click="append('-')">
						<div class="number">-</div>
					</div>
			</div>
					
			<div class="d-flex h-25">
				<div class="btn number-btn text-white" @click="append('7')">
					<div class="number">7</div>
				</div>
				<div class="btn number-btn text-white" @click="append('8')">
					<div class="number">8</div>
				</div>
				<div class="btn number-btn text-white" @click="append('9')">
					<div class="number">9</div>
				</div>
<!-- 				<div class="btn number-btn text-white" @click="append('/')">÷</div> -->
			</div>
			<div class="d-flex h-25">
				<div class="btn number-btn text-white" @click="append('4')">
					<div class="number">4</div>
				</div>
				<div class="btn number-btn text-white" @click="append('5')">
					<div class="number">5</div>
				</div>
				<div class="btn number-btn text-white" @click="append('6')">
					<div class="number">6</div>
				</div>
<!-- 				<div class="btn number-btn text-white" @click="append('*')">×</div> -->
				
			</div>
			<div class="d-flex h-25">
				<div class="btn number-btn text-white" @click="append('1')">
					<div class="number">1</div>
				</div>
				<div class="btn number-btn text-white" @click="append('2')">
					<div class="number">2</div>
				</div>
				<div class="btn number-btn text-white" @click="append('3')">
					<div class="number">3</div>
				</div>
<!-- 				<div class="btn number-btn text-white" @click="append('-')">-</div> -->
			</div>
			<div class="row ml-1">
				<div class="btn number-btn result-row text-white" id="dot" @click="append('.')">
					<div class="number">.</div>
				</div>
				<div class="btn number-btn result-row text-white" @click="append('0')">
					<div class="number">0</div>
				</div>
				<div class="btn number-btn text-white" id="result" @click="getResult()">
					<div class="number">=</div>
				</div>
<!-- 				<div class="btn" @click="append('+')">+</div> -->
			</div>
		</div>
	</div>
</div>
</template>

<style scoped>
	.number-btn.result-row{
		width: 33% !important;
		height: 100% !important;
	}
	#result.number-btn{
		width: calc(33% * 1.25) !important;
		height: calc(100% * 1.25) !important;
		background: #FEA100 !important;
	}
	#standard-calculator {
  width: 100%;
  height: 100%;
  display: flex;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-size: 24px;
}
	#standard-calculator #calculator {
  opacity: 0;
  -webkit-animation: 1s bounceIn;
          animation: 1s bounceIn;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  margin: 10px;
  border-radius: 8px;
  color: #ffffff;
  box-shadow: 10px 10px 50px rgba(0, 0, 0, 0.5);
  margin: auto;
  height: 540px;
  width: 300px;
  background: #191b1d;
}
#standard-calculator #calculator .screen-container {
  width: 100%;
  height: 38%;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  text-align: right;
}
#standard-calculator #calculator .screen-container .calculation,
#standard-calculator #calculator .screen-container .result {
  width: 100%;
  height: 50%;
  padding-right: 15px;
}
#standard-calculator #calculator .screen-container .result {
  display: flex;
  align-items: flex-end;
  color: #FEA100 !important;
  justify-content: flex-end;
}
#standard-calculator #calculator .screen-container .result .number {
  font-size: 50px;
  font-weight: 800;
}
#standard-calculator #calculator .btn-container {
  width: 100%;
  height: 62%;
  display: flex;
  flex-direction: column;
  position: relative;
}
#standard-calculator #calculator .btn-container .oper-container {
  z-index: 10;
  opacity: 0;
  -webkit-animation: 1s bounceIn;
          animation: 1s bounceIn;
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  position: absolute;
  width: 50px;
  left: -25px;
  box-shadow: 10px 10px 50px rgba(0, 0, 0, 0.5);
  top: -100px;
}
#standard-calculator #calculator .btn-container .oper-container .btn {
  position: static;
  width: 50px;
  height: 50px;
  
  color: #FEA100 !important;
  background: #ffffff;
  display: flex;
  cursor: pointer;
  font-size: 20px;
}
#standard-calculator #calculator .btn-container .oper-container .btn .number {
  margin: auto;
}
#standard-calculator #calculator .btn-container .oper-container .btn#clear.animate {
  -webkit-animation: fade 0.12s ease-in-out;
          animation: fade 0.12s ease-in-out;
  animation-direction: reverse;
}
#standard-calculator #calculator .btn-container .oper-container .btn#clear {
  cursor: pointer;
  height: 50px;
  width: 50px;
  display: flex;
  background: #1e2022;
  border-radius: 8px 0px 0 0;
  font-size: 16px;
  box-shadow: 10px 10px 50px rgba(0, 0, 0, 0.5);
}
#standard-calculator #calculator .btn-container .oper-container .btn#clear .number {
  margin: auto;
  color: #ffffff;
}
#standard-calculator #calculator .btn-container .row {
  position: relative !important;
  display: flex !important;
  flex-direction: row !important;
  width: 100% !important;
  height: 25% !important;
}
.number-btn{
	    cursor: pointer !important;
    width: 100% !important;
    height: 100% !important;
    text-align: center !important;
    display: flex !important;
    border: 0.5px solid #191b1d !important;
    background: #1e2022 !important;
    justify-content: center;
    align-items: center;
    font-size: 24px;
}
#standard-calculator #calculator .btn-container .row .btn .number {
  opacity: 0;
  -webkit-animation: 1s fadeIn;
          animation: 1s fadeIn;
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  margin: auto;
}
#standard-calculator #calculator .btn-container .row .btn:nth-child(1) {
  border-left: none;
}
#standard-calculator #calculator .btn-container .row .btn:nth-child(3) {
  border-right: none;
}
#standard-calculator #calculator .btn-container .row .btn.animate {
  -webkit-animation: fade 0.12s ease-in-out;
          animation: fade 0.12s ease-in-out;
}
#standard-calculator #calculator .btn-container .row .btn#result {
  position: absolute;
  right: -12px;
  bottom: -12px;
  width: calc(33% * 1.25);
  height: calc(100% * 1.25);
  background: #FEA100 !important;
  border-bottom-right-radius: 8px;
  border: 0.5px rgba(0, 0, 0, 0.0001);
}
#standard-calculator #calculator .btn-container .row .btn#result:not(.resetappearanim) {
  opacity: 0;
  -webkit-animation: 1s bounceIn;
          animation: 1s bounceIn;
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}
#standard-calculator #calculator .btn-container .row .btn#result.animate {
  -webkit-animation: fade 0.12s ease-in-out;
          animation: fade 0.12s ease-in-out;
  animation-direction: reverse;
}
#standard-calculator #calculator .btn-container .row .btn#dot {
  border-bottom-left-radius: 8px;
}
#standard-calculator #calculator .btn-container .row:last-child .btn {
  border-bottom: none;
}
#standard-calculator #calculator .btn-container .btn.operators.animate {
  -webkit-animation: fadeThird 0.12s ease-in-out;
          animation: fadeThird 0.12s ease-in-out;
  animation-direction: reverse;
}
#standard-calculator #calculator .btn-container .btn.operators.divide {
  left: calc(33% - 25px);
  top: calc(25% - 25px);
}
#standard-calculator #calculator .btn-container .btn.operators.multiplication {
  left: calc(66% - 25px);
  top: calc(25% - 25px);
}
#standard-calculator #calculator .btn-container .btn.operators.plus {
  left: calc(66% - 25px);
  top: calc(50% - 25px);
}
#standard-calculator #calculator .btn-container .btn.operators.less {
  left: calc(33% - 25px);
  top: calc(50% - 25px);
  border-radius: 0 0 8px 0;
}

@-webkit-keyframes fade {
  from {
    background: #1e2022;
  }
  to {
    background: #FEA100 !important;
  }
}

@keyframes fade {
  from {
    background: #1e2022;
  }
  to {
    background: #FEA100 !important;
  }
}
@-webkit-keyframes fadeThird {
  from {
    background: #ffffff;
  }
  to {
    background: #1e2022;
  }
}
@keyframes fadeThird {
  from {
    background: #ffffff;
  }
  to {
    background: #1e2022;
  }
}
@-webkit-keyframes bounceIn {
  from, 20%, 40%, 60%, 80%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }
  40% {
    transform: scale3d(0.9, 0.9, 0.9);
  }
  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03);
  }
  80% {
    transform: scale3d(0.97, 0.97, 0.97);
  }
  to {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}
@keyframes bounceIn {
  from, 20%, 40%, 60%, 80%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }
  40% {
    transform: scale3d(0.9, 0.9, 0.9);
  }
  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03);
  }
  80% {
    transform: scale3d(0.97, 0.97, 0.97);
  }
  to {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}
@-webkit-keyframes bounce {
  from, 20%, 40%, 60%, 80%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }
  40% {
    transform: scale3d(0.9, 0.9, 0.9);
  }
  60% {
    transform: scale3d(1.03, 1.03, 1.03);
  }
  80% {
    transform: scale3d(0.97, 0.97, 0.97);
  }
  to {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}
@keyframes bounce {
  from, 20%, 40%, 60%, 80%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }
  40% {
    transform: scale3d(0.9, 0.9, 0.9);
  }
  60% {
    transform: scale3d(1.03, 1.03, 1.03);
  }
  80% {
    transform: scale3d(0.97, 0.97, 0.97);
  }
  to {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.slide-fade-enter-active {
  transition: all 0.3s ease;
}

.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}

*:focus {
  outline: none;
}

@media screen and (max-width: 500px) {
  #standard-calculator {
    transform: scale(0.8) translateY(-50px);
  }
}
*::-moz-selection {
  background: #FEA100 !important;
  color: #ffffff;
}
*::selection {
  background: #FEA100 !important;
  color: #ffffff;
}

[v-cloak] {
  display: none;
}

</style>