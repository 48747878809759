<script type="text/javascript">
	import QrcodeVue from 'qrcode.vue';
	import VueBarcode from '@chenfengyuan/vue-barcode';
	import VueSlideBar from "vue-slide-bar";
	import simplebar from "simplebar-vue";
	//import VueHtml2pdf from 'vue-html2pdf';
	export default{
		components: {QrcodeVue, VueBarcode, VueSlideBar, simplebar},
		props: {
			code_type: {
				type: String,
				default: 'barcode'
			},
			barcode_value: {
				type: Object,
				default: {
					EAN13: 234567891011,
					UPC: 134567891011,
					CODE128: 134567891011
				}
			},
			qr_value: {
				type: String,
				default: '1234567891011'
			},
			barcode_type: {
				type: String,
				default: 'CODE128'
			},
			background_color: {
				type: String,
				default: '#ffffff'
			},
			code_color:  {
				type: String,
				default: '#000000'
			},
			code_size: {
				type: Number,
				default: '1'
			},
			code_quality: {
				type: String,
				default: 'H'
			},
			display_code: {
				type: Number,
				default: true
			},
		},
		data(){
			return {
				mmconversion: 3.7795275591,
				label_index: 0,
				scale: 1,
				scale_qr: 1,
				scale_font: 1,
				font_size: 9,
				code_types: ['barcode', 'QR'],
				label_size: '',
				label_margin: 0,
				label_type: 'address',
				label_types: [
					{text: 'Address', value: 'address'},
				],
				label_sizes: 
					{
						address: [
							{text: 'Extra Small - 50mm x 25mm', value: {width: 50, height: 25}},
							{text: 'Small - 50mm x 38mm', value: {width: 50, height: 38}},
							{text: 'Medium - 76mm x 51mm', value: {width: 76, height: 51}},
							{text: 'Large - 101mm x 73mm', value: {width: 101, height: 73}},
							{text: 'Extra Extra - 101mm x 148mm', value: {width: 101, height: 148}},
						]
					}

			}
		},
		methods: {
			print(){

			},
			generateReport () {
            this.$refs.html2Pdf.generatePdf()
	        },
	        printInvoice(){
	        	this.$bvModal.hide('invoice-modal');
	        	this.showInvoiceLayout = true;
	        	
	        	//var printContents = document.getElementById('invoiceArea').innerHTML;
				     //var originalContents = document.getElementById('print-window').innerHTML;

				     //document.getElementById('print-window').innerHTML = printContents;

				     window.print();

				     //document.getElementById('print-window').innerHTML = originalContents;
				     //this.showInvoiceLayout = false;
				     //this.$bvModal.hide('invoice-modal');
	        }
		}
	}

</script>
<template>
	<div>
		<b-row>
			<b-col md="9">
		<b-row class="mx-auto border-bottom h-100">
			<div class="border py-4 bg-light d-flex justify-content-center align-items-center">
				<div class="d-flex position-absolute top-0 right-0 left-0 m-2" v-if="$attrs.addresses.length > 0">
					<b-button variant="primary" class="btn btn-primary btn-rounded rounded-circle mr-auto" :disabled="label_index == 0" @click="label_index--, $attrs.address = addresses[index]">
						<i class="bx bx-chevron-left"></i>
					</b-button>
					<b-button variant="primary" class="btn btn-primary btn-rounded rounded-circle ml-auto" :disabled="(label_index + 1) == $attrs.addresses.length" @click="label_index++, $attrs.address = addresses[index]">
						<i class="bx bx-chevron-right"></i>
					</b-button>

				</div>
				<div v-if="(label_size !== '') && (label_type == 'address')" class="d-flex justify-content-center align-items-center mx-auto my-4" >
					<div id="addressPrintArea" class="border bg-white d-flex flex-column justify-content-center shadow-sm p-1 overflow-hidden" :style="{'width' : label_size.width * scale +'mm', 'height': label_size.height * scale +'mm', 'padding' : label_margin +'px !important'}" >
						<div class="d-flex flex-column" v-if="$attrs.address !== ''">

						<small style="font-size-adjust: 0.5;" :style="{'font-size' : font_size*scale*scale_font+'px'}">
							<span v-if="'salutation' in $attrs.address">{{$attrs.address.salutation}}</span>
							<span v-if="'name' in $attrs.address">{{$attrs.address.name}}</span>
						</small>
						<small v-if="'address1' in $attrs.address" style="font-size-adjust: 0.5;" :style="{'font-size' : font_size*scale*scale_font+'px'}">{{$attrs.address.address1}}</small>
						<small v-if="'address2' in $attrs.address" style="font-size-adjust: 0.5;" :style="{'font-size' : font_size*scale*scale_font+'px'}">{{$attrs.address.address2}}</small>
						<small v-if="'address3' in $attrs.address" style="font-size-adjust: 0.5;" :style="{'font-size' : font_size*scale*scale_font+'px'}">{{$attrs.address.address3}}</small>
						<small v-if="'address_1' in $attrs.address" style="font-size-adjust: 0.5;" :style="{'font-size' : font_size*scale*scale_font+'px'}">{{$attrs.address.address_1}}</small>
						<small v-if="'address_2' in $attrs.address" style="font-size-adjust: 0.5;" :style="{'font-size' : font_size*scale*scale_font+'px'}">{{$attrs.address.address_2}}</small>
						<small v-if="'address_3' in $attrs.address" style="font-size-adjust: 0.5;" :style="{'font-size' : font_size*scale*scale_font+'px'}">{{$attrs.address.address_3}}</small>
						<small v-if="'city' in $attrs.address" style="font-size-adjust: 0.5;" :style="{'font-size' : font_size*scale*scale_font+'px'}">{{$attrs.address.city}}</small>
						<small v-if="'county' in $attrs.address" style="font-size-adjust: 0.5;" :style="{'font-size' : font_size*scale*scale_font+'px'}"><span v-if="$attrs.address.county !== null">{{$attrs.address.county.name}}</span></small>
						<small v-if="'country' in $attrs.address" style="font-size-adjust: 0.5;" :style="{'font-size' : font_size*scale*scale_font+'px'}"><span v-if="$attrs.address.country !== null">{{$attrs.address.country.name}}</span></small>
						<small v-if="'postcode' in $attrs.address" style="font-size-adjust: 0.5;" :style="{'font-size' : font_size*scale*scale_font+'px'}">{{$attrs.address.postcode}}</small>
						</div>
					</div>

				</div>
				<div v-if="label_size == ''" class="my-4 justify-content-center align-items-center">
					<div class="w-50 bg-white mx-auto p-4 text-center border-radius-1em shadow-sm">
						<h6 class="m-2">Select Label options and label template will show here.</h6>
					</div>
				</div>
				<span class="badge badge-primary bg-primary position-absolute left-0 bottom-0 m-3 font-size-12" v-if="$attrs.addresses.length > 0">You are previewing ticket {{label_index + 1}} of {{$attrs.addresses.length}} ticket(s)</span>
				<span class="badge badge-primary bg-primary position-absolute right-0 bottom-0 m-3 font-size-12" v-if="$attrs.addresses.length > 0">You are bulk printing {{$attrs.addresses.length}} tickets</span>
			</div>
		</b-row>
	</b-col>
	<b-col md="3">
		<b-card class="border-radius-1em border shadow-none mb-1" no-body>
			<div style="overflow-x: hidden;">
			<b-row class="p-3">
				<b-col md="12">
					<h5>Label Options</h5>
					<hr>
					<b-row>
						<b-col md="12">
							<!--<b-form-group label="Code Type">
								<b-form-select class="form-control" :options="code_types" v-model="code_type">
								</b-form-select>
							</b-form-group>-->
						
							<b-form-group class="mt-2" label="Label Types">
								<b-form-select class="form-control" :options="label_types" v-model="label_type">
								</b-form-select>
							</b-form-group>
						</b-col>
						<b-col md="12">
							<b-form-group class="mt-2" label="Label Size">
								<b-form-select class="form-control" :options="label_sizes[label_type]" v-model="label_size" @input="scale_qr = 1">
								</b-form-select>
							</b-form-group>
						</b-col>
						<b-col md="12">
							<h5 class="mt-4">Style Options</h5>
							<hr>
							<b-form-group label="Resize Label">
								<div class="d-flex">
							 	<b-form-input id="formControlRange" class="form-control-range form-range" type="range" v-model="scale" min="0.5" step="0.1" max="5"></b-form-input>
							 	<a class="btn btn-outline-light" @click="scale = 1">
							 		<i class="bx bx-reset"></i>
							 	</a></div>
							 </b-form-group>
							<!-- <b-form-group label="Resize QR Code">
							 	<div class="d-flex">
							 	<b-form-input id="formControlRange" class="form-control-range form-range" type="range" v-model="scale_qr" min="0.5" step="0.1" max="5"></b-form-input>
							 	<a class="btn btn-outline-light" @click="scale_qr = 1">
							 		<i class="bx bx-reset"></i>
							 	</a></div>
							 </b-form-group>-->
							 <b-form-group label="Resize Font">
							 	<div class="d-flex">
							 	<b-form-input id="formControlRange" class="form-control-range form-range" type="range" v-model="scale_font" min="0.5" step="0.1" max="5"></b-form-input>
							 	<a class="btn btn-outline-light" @click="scale_font = 1">
							 		<i class="bx bx-reset"></i>
							 	</a></div>
							 </b-form-group>

							 <b-form-group label="Label Margin">
							 	<div class="d-flex">
							 	<b-form-input id="formControlRange" class="form-control-range form-range" type="range" v-model="label_margin" min="0" step="1" max="100"></b-form-input>
							 	<a class="btn btn-outline-light" @click="scale_font = 1">
							 		<i class="bx bx-reset"></i>
							 	</a></div>
							 </b-form-group>

							 <!--<b-form-group>
							 	<a class="btn btn-light btn-rounded">
							 		Rotate Label
							 	</a>
							 </b-form-group>-->
						</b-col>
						
					</b-row>
				
				</b-col>
			</b-row>
			</div>
		</b-card>
		<a class="btn btn-success btn-block btn-rounded" @click="print()">
			<i class="bx bx-printer"></i> Print
		</a>
		<a class="btn btn-success btn-block btn-rounded mt-2" @click="generateReport()">
			<i class="bx bx-printer"></i> Generate PDF & Print
		</a>
	</b-col>
</b-row>
<b-row>
	<b-col>
				<vue-html2pdf
		 		id="invoiceArea"
        :show-layout="false"
        :float-layout="true"
        :enable-download="false"
        :preview-modal="true"
        v-if="$attrs.address !== ''"
        :filename="$attrs.address.alias"
        :pdf-quality="2"
        :manual-pagination="false"
        :pdf-content-width="label_size.width"
        :paginate-elements-by-height="label_size.height"
        ref="html2Pdf"
    >
        <section slot="pdf-content"  >
        	<section class="pdf-item">
					<div id="addressPrintArea" class="border bg-white d-flex flex-column justify-content-center shadow-sm p-1 overflow-hidden" :style="{'width' : label_size.width * scale +'mm', 'height': label_size.height * scale +'mm', 'padding' : label_margin +'px !important'}" >
						<div class="d-flex flex-column" v-if="$attrs.address !== ''">

						<small style="font-size-adjust: 0.5;" :style="{'font-size' : font_size*scale*scale_font+'px'}">
							<span v-if="'salutation' in $attrs.address">{{$attrs.address.salutation}}</span>
							<span v-if="'name' in $attrs.address">{{$attrs.address.name}}</span>
						</small>
						<small v-if="'address1' in $attrs.address" style="font-size-adjust: 0.5;" :style="{'font-size' : font_size*scale*scale_font+'px'}">{{$attrs.address.address1}}</small>
						<small v-if="'address2' in $attrs.address" style="font-size-adjust: 0.5;" :style="{'font-size' : font_size*scale*scale_font+'px'}">{{$attrs.address.address2}}</small>
						<small v-if="'address3' in $attrs.address" style="font-size-adjust: 0.5;" :style="{'font-size' : font_size*scale*scale_font+'px'}">{{$attrs.address.address3}}</small>
						<small v-if="'address_1' in $attrs.address" style="font-size-adjust: 0.5;" :style="{'font-size' : font_size*scale*scale_font+'px'}">{{$attrs.address.address_1}}</small>
						<small v-if="'address_2' in $attrs.address" style="font-size-adjust: 0.5;" :style="{'font-size' : font_size*scale*scale_font+'px'}">{{$attrs.address.address_2}}</small>
						<small v-if="'address_3' in $attrs.address" style="font-size-adjust: 0.5;" :style="{'font-size' : font_size*scale*scale_font+'px'}">{{$attrs.address.address_3}}</small>
						<small v-if="'city' in $attrs.address" style="font-size-adjust: 0.5;" :style="{'font-size' : font_size*scale*scale_font+'px'}">{{$attrs.address.city}}</small>
						<small v-if="'county' in $attrs.address" style="font-size-adjust: 0.5;" :style="{'font-size' : font_size*scale*scale_font+'px'}"><span v-if="$attrs.address.county !== null">{{$attrs.address.county.name}}</span></small>
						<small v-if="'country' in $attrs.address" style="font-size-adjust: 0.5;" :style="{'font-size' : font_size*scale*scale_font+'px'}"><span v-if="$attrs.address.country !== null">{{$attrs.address.country.name}}</span></small>
						<small v-if="'postcode' in $attrs.address" style="font-size-adjust: 0.5;" :style="{'font-size' : font_size*scale*scale_font+'px'}">{{$attrs.address.postcode}}</small>
						</div>

						<div class="d-flex flex-column" v-if="$attrs.addresses !== ''" v-for="addressitem in $attrs.addresses.slice(1, ($attrs.addresses.length - 1))">

						<small style="font-size-adjust: 0.5;" :style="{'font-size' : font_size*scale*scale_font+'px'}">
							<span v-if="'salutation' in addressitem">{{addressitem.salutation}}</span>
							<span v-if="'name' in addressitem">{{addressitem.name}}</span>
						</small>
						<small v-if="'address1' in addressitem" style="font-size-adjust: 0.5;" :style="{'font-size' : font_size*scale*scale_font+'px'}">{{addressitem.address1}}</small>
						<small v-if="'address2' in addressitem" style="font-size-adjust: 0.5;" :style="{'font-size' : font_size*scale*scale_font+'px'}">{{addressitem.address2}}</small>
						<small v-if="'address3' in addressitem" style="font-size-adjust: 0.5;" :style="{'font-size' : font_size*scale*scale_font+'px'}">{{addressitem.address3}}</small>
						<small v-if="'address_1' in addressitem" style="font-size-adjust: 0.5;" :style="{'font-size' : font_size*scale*scale_font+'px'}">{{addressitem.address_1}}</small>
						<small v-if="'address_2' in addressitem" style="font-size-adjust: 0.5;" :style="{'font-size' : font_size*scale*scale_font+'px'}">{{addressitem.address_2}}</small>
						<small v-if="'address_3' in addressitem" style="font-size-adjust: 0.5;" :style="{'font-size' : font_size*scale*scale_font+'px'}">{{addressitem.address_3}}</small>
						<small v-if="'city' in addressitem" style="font-size-adjust: 0.5;" :style="{'font-size' : font_size*scale*scale_font+'px'}">{{addressitem.city}}</small>
						<small v-if="'county' in addressitem" style="font-size-adjust: 0.5;" :style="{'font-size' : font_size*scale*scale_font+'px'}"><span v-if="addressitem.county !== null">{{addressitem.county.name}}</span></small>
						<small v-if="'country' in addressitem" style="font-size-adjust: 0.5;" :style="{'font-size' : font_size*scale*scale_font+'px'}"><span v-if="addressitem.country !== null">{{addressitem.country.name}}</span></small>
						<small v-if="'postcode' in addressitem" style="font-size-adjust: 0.5;" :style="{'font-size' : font_size*scale*scale_font+'px'}">{{addressitem.postcode}}</small>
						</div>
					</div>
				</section>
					<div class="html2pdf__page-break"/>
				</section>
			</vue-html2pdf>
	</b-col>
</b-row>
	</div>
</template>

<style scoped>
	.custom-control.custom-radio{
		padding-left: 0 !important;
	}
	.custom-control.custom-radio input{
		padding-right: 5px !important;
	}
</style>