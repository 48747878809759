
<script>
import Layout from '../../layouts/main'
import PageHeader from '../../components/page-header'
import vue2Dropzone from 'vue2-dropzone'
//import { shopsData } from './data-shops'
import SearchCompanyUser from "../../components/widgets/SearchCompanyUser"
import SideSlidePanel from "../../components/widgets/SideSlidePanel"
import SearchBar from "../../components/widgets/searchBar"
import EditModal from "../../components/widgets/editModal"
import VueGoogleAutocomplete from "vue-google-autocomplete";
/**
 * Shops component
 */
export default {
  components: { Layout, PageHeader, SideSlidePanel, SearchBar, EditModal, SearchCompanyUser, vueDropzone: vue2Dropzone, VueGoogleAutocomplete},
  data() {
    return {
      showActiveOn: false,
      shopsData: '',
      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        thumbnailHeight: 50,
        thumbnailWidth: 50,
        autoProcessQueue: false,
      },
      logo: '',
      cover_image: '',
      type_id: '',
      shopsactive: false,
      public: false,
      trade: false,
      productsData: [],
      showListingProducts: false,
      addProductToListing: 0,
      googleSearchAddress: '',
      addNewListing: false,
      updateListing: false,
      selectedListing:[],
      searchproduct: '',
      shops_error: [],
      error_reponse: '',
      success: '',
      success_message: false, 
      error_message: false,
      loadingForm: false,
      listing_types: [],
      company_info: [],
      categoryProducts: '',
      productsLimit: '100',
      productsCurrentPage: 1,
      productListingSelected: '',
      newquantity: 0,
      listingquantity: 0,
      transferquantity: 0,
      currentPage: 1,
      orderDesc: false,
      priceRange: [0,999999],
      trade_productsactive: '',
      trade_active: '',
      public_active: '',
      public_productsactive: '',
      totalListingsQuantity: 0,
      editModal: 0,
      isCheckAll: false,
      productsactive: '',
      countries: [],
      counties: [],
      brands: '',
      collections: '',
      active: '',
      view: 'list',
      search: '',
      selected_country_id: 0,
      selected_county_id: 0,
      limit: '25',
      sortby: 'id',
      order: 'desc',
      selectedids: [],
      newListing: {
        address1: '',
        address2: '',
        address3: '',
        postcode: '',
        city: '',
        longitude: 0,
        latitude: 0,
        gplace_id: '',
        country_id: 0,
        county_id: 0,
        manager_id: 0,
        manager: null,
      },
      mapCenter: {
        lat: 54.5509597,
        lng: -5.9974262,
      },
      addNewPanel: 0,
      isCustom: 0,
      dropzoneOptions: {
            url: 'https://httpbin.org/post',
            thumbnailHeight: 300,
            thumbnailWidth: 300,
          },
          limits: [
            {value: 25, text: "25"},
            {value: 50, text: "50"},
            {value: 100, text: "100"},
          ],
          transferOptions: [
            {value: 0, text: "Company Stock"},
            {value: 1, text: "Another Shop or Listing"},
          ],
          transferOption: 0,
      title: 'Shops & Locations',
      items: [
        {
          text: 'Company',
          href: '/',
        },
        {
          text: 'Shops & Locations',
          active: true,
        },
      ],
    }
  },
  methods: {
    setActive(active, area){
            axios.post('/api/company/listing/set-active', {ids: this.selectedids, active: active, market: area}, {headers: {
              'Authorization' : 'Bearer '+this.$attrs.user.api_token
            }}).then(response => {
              
              this.success_text = 'Congratulations, your action has been saved';
              this.success_message = true;
              if(this.selectedids.length > 1){
                return this.productQuery();
              }else{
                return true;
              }
                
            }).catch(error => {

            });
    },
    bulkDestroyResource(){
            axios.post('/api/company/listing/bulk-destroy', {ids: this.selectedids}, {headers: {
              'Authorization' : 'Bearer '+this.$attrs.user.api_token
            }}).then(response => {
                this.$bvModal.hide('delete-modal');     
                this.selectedids = [];
                return this.productQuery();
            }).catch(error => {

            });
     },
     bulkDuplicateResource(){
      axios.post('/api/company/listing/duplicate', {ids: this.selectedids}, {headers : {
        'Authorization' : 'Bearer '+this.$attrs.user.api_token
      }}).then(response => {
        console.log(response);
        this.success_text = 'Congratulations, your collection(s) have been updated.';
        this.success_message = true;
        //this.resetProjectData();
        this.addPanel = false;
        let self = this;
          setTimeout(function(){
            self.loadingForm = false;
            self.productQuery();
            //self.$bvModal.hide('move-modal');
          }, 1500);
      }).catch( error => {
        
        this.error_reponse = error.response.data.errors;
        this.error_message = true;
        //this.resetProjectData();
        let self = this;
          setTimeout(function(){
            self.loadingForm = false;
          }, 1500);
      });
    },
    getCountries(){
        if(this.countries.length == 0){
          axios.get('/api/countries', {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
              }}).then(response => {
            this.countries = response.data;
          }).catch(error => {
            this.load_error = error.data;
          })
        }
      },
       includeGoogle( URL, callback ){
                        let documentTag = document, tag = 'script',
                            object = documentTag.createElement(tag),
                            scriptTag = documentTag.getElementsByTagName(tag)[0];
                        object.src = '//' + URL;
                        if (callback) { object.addEventListener('load', function (e) { callback(null, e); }, false); }
                        scriptTag.parentNode.insertBefore(object, scriptTag);
            },
      getCounties(country_id){
        // get counties as country selected
        axios.get('/api/country/'+country_id+'/counties', {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
              }}).then(response => {
          this.counties = response.data;
        }).catch(error => {
          this.load_error = error.data;
        })
      },
      
    searchResource(){
      axios.get('/api/company/listings?page='+this.currentPage+'&limit='+this.limit+'&sortby='+this.sortby+'&order='+this.order+'&search='+this.search+'&type_id='+this.type_id+'&active='+this.active+'&public='+this.public_active+'&trade='+this.trade_active, {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
      this.shopsData = response.data;
      }).catch(error => {
        this.shops_error = error.data;
      });
    },
    getListingTypes(){
      axios.get('/api/company/listing/types', {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
      this.listing_types = response.data;
      }).catch(error => {
        this.shops_error = error.data;
      });
    },
    getProducts(id){
      axios.get('/api/company/listing/'+id+'/products', { headers : {
        'Authorization': 'Bearer ' + this.$attrs.user.api_token
      } }).then(response => {
        this.categoryProducts = response.data;
        //this.showListingProducts = 1;
      }).catch(error => {
        this.products_error = error.data;
      });
    },
    getSellerProducts(search_query){
      if(this.orderDesc == true){
        this.order = 'desc';
      }else{
        this.order = 'asc';
      }
      axios.get('/api/company/products?page='+this.productsCurrentPage+'&limit='+this.productsLimit+'&sortby='+this.sortby+'&order='+this.order+'&search='+search_query+'&trade_active='+this.trade_productsactive+'&public_active='+this.public_productsactive+'&active='+this.productsactive+'&brands='+this.brands+'&collections='+this.collections+'&pricerange='+this.priceRange, { headers : {
        'Authorization': 'Bearer ' + this.$attrs.user.api_token
      } }).then(response => {
        console.log(response);
        this.productsData = response.data.data;
      });
    },
    submitListing(){
       this.loadingForm = true;
        var postForm = new FormData(document.getElementById("addnewlisting"));
        postForm.append('banner', this.cover_image);
        postForm.append('logo', this.logo);
        postForm.append('longitude', this.newListing.longitude);
        postForm.append('latitude', this.newListing.latitude);
        postForm.append('gplace_id', this.newListing.gplace_id);
        postForm.append('manager_id', this.newListing.manager_id);
        postForm.append('active', this.shopsactive);
        postForm.append('trade_active', this.trade);
        postForm.append('public_active', this.public);
        axios.post('/api/company/listing/create', postForm, {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token }}).then(response => {
          this.success = response.data;
          this.success_message = true;
          //this.$emit('action-created');
          //this.newAction = [];
          let self = this;
          setTimeout(function(){
            self.loadingForm = false;
            self.addNewListing = false;
            self.searchResource();
          }, 1500);    
        }).catch(error => {
          this.error_reponse = error.response.data.errors;
          this.error_message = true;
          let self = this;
          setTimeout(function(){
            self.loadingForm = false;
          }, 1500);
          
        });
    },
    imageAdded(file){
          this.cover_image = file;
        },
        logoAdded(file){
          this.logo = file;
        },
        imageUpdated(file){
          this.selectedListing.cover_image = file;
        },
        logoUpdated(file){
          this.selectedListing.logo = file;
        },
    submitUpdateListing(){
       this.loadingForm = true;
        var postForm = new FormData(document.getElementById("updatelisting"));
        postForm.append('banner', this.cover_image);
        postForm.append('logo', this.logo);
        if(this.selectedListing.longitude !== null){
          postForm.append('longitude', this.selectedListing.longitude);
        }
        if(this.selectedListing.latitude !== null){
          postForm.append('latitude', this.selectedListing.latitude);
        }
        postForm.append('gplace_id', this.selectedListing.gplace_id);
        postForm.append('manager_id', this.selectedListing.manager_id);
        postForm.append('active', this.selectedListing.active);
        postForm.append('trade_active', this.selectedListing.trade);
        postForm.append('public_active', this.selectedListing.public);
        axios.post('/api/company/listing/'+this.selectedListing.id+'/update', postForm, {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token }}).then(response => {
          this.success = response.data;
          this.success_message = true;
          //this.$emit('action-created');
          //this.newAction = [];
          let self = this;
          setTimeout(function(){
            self.loadingForm = false;
            self.searchResource();
          }, 1500);    
        }).catch(error => {
          this.error_reponse = error.response.data.errors;
          this.error_message = true;
          let self = this;
          setTimeout(function(){
            self.loadingForm = false;
          }, 1500);
          
        });
    },
    selectManager(manager){
      this.newListing.manager_id = manager.companies[0].id;
    },
    updateManager(manager){
      this.selectedListing.manager_id = manager.companies[0].id;
      this.selectedListing["manager"] = manager;
    },
    closeEditModal(){
      this.$refs['edit-modal'].hide()
    },
    addToListing(product){
      this.productListingSelected = product;
      this.selectedProductListingInfo = product.listings;
      if(this.productListingSelected.listings.length > 0){
        var listing_qty = this.productListingSelected.listings.filter(item => (item.listing_id == this.selectedListing.id) && (item.product_id == this.productListingSelected.id));
        if(listing_qty.length > 0){
          this.listingquantity = listing_qty[0].quantity;
        }else{
          this.listingquantity = 0;
        }
      }else{
         this.listingquantity = 0;
      }
      this.$refs['edit-modal'].show();
    },
    getListingData(listing_id){
        return this.shopsData.data.filter(item => item.id == listing_id);
    },
    priceIncVat(price, tax_percentage){
      var tax = 1 + (tax_percentage / 100);
      return price * tax;
    },
     filterSelected(id){
        if(this.selectedids.length > 0){
          var exists = this.selectedids.filter(item => item == id);
          if(exists.length > 0){
            return true;
          }else{
            return false
          }
        }else{
          return false;
        }
      },
       getAddressData: function (addressData, placeResultData, id) {
        this.googleSearchAddress = addressData;
        if(placeResultData.name){
         this.newListing.address1 = placeResultData.name;
         if(addressData.street_number){
              this.newListing.address2 = addressData.street_number + ' ' +addressData.route; 
          }else{
              this.newListing.address2 = addressData.route; 
          }
        }else{
         if(addressData.street_number){
              this.newListing.address1 = addressData.street_number + ' ' +addressData.route; 
          }else{
              this.newListing.address1 = addressData.route; 
          }
        }
        if(addressData.locality){
          this.newListing.address3 = addressData.locality;
        }
        this.newListing.postcode = addressData.postal_code;
        var country = this.countries.filter(item => item.name == addressData.country);
        this.newListing.country_id = country[0].id;
        this.selected_country_id = country[0].id;
          
        this.newListing.latitude = this.googleSearchAddress.latitude;
        this.newListing.longitude = this.googleSearchAddress.longitude;
        this.newListing.gplace_id = placeResultData.place_id;
        this.googleSearchAddress["place"] = placeResultData;
        if(country.length > 0){
            //this.getCounties(country[0].id);
            var self = this;
            axios.all([getCounties(country[0].id, self.$attrs.user.api_token, addressData.administrative_area_level_2)]).then(axios.spread(function (response) {
              //companyList[ia].push(response.data)
              console.log(response);
              self.counties = response[0].data;
              var county = response[0].data.filter(item => item.name == response[1]);
              self.newListing.county_id = county[0].id;
              self.selected_county_id = county[0].id;
            }));
            
          }
        /**for(var i = 0; i < placesData.address_components.length; i++){
          var county = placesData.address_components[i].types.filter(item == 'route');
          var county = placesData.address_components[i].types.filter(item == 'street_number');
          
          var town = placesData.address_components[i].types.filter(item == 'locality');
          var city = placesData.address_components[i].types.filter(item == 'postal_town');
          var country = placesData.address_components[i].types.filter(item == 'country');

          this.newListing.address3 = town[0].long_name;

        
        }**/
        async function getCounties(country_id, api_token, county_name){
               return [await axios.get('/api/country/'+country_id+'/counties', {headers: {
                  'Authorization': 'Bearer ' + api_token
              }}), county_name];
              }

      },
      checkAll: function(){ 
                 this.isCheckAll = !this.isCheckAll; 
                 this.selectedids = []; 
                 if(this.isCheckAll){ 
                     // Check all 
                     for (var key in this.shopsData.data) {          
                        this.selectedids.push(this.shopsData.data[key].id); 
                     } 
                 } 
              }, 
      updateCheckall: function(){ 
                 if(this.selectedids.length == this.shopsData.data.length){
                    this.isCheckAll = true; 
                 }else{ 
                    this.isCheckAll = false; 
                 } 
      },
  },
  computed:{
    allProductListingQuantities: function(){
      if(this.productListingSelected.length !== 0){
          if(this.productListingSelected.listings.length > 0){
            this.totalListingsQuantity = 0;
            for(var i = 0; i < this.productListingSelected.listings.length; i++){
              //console.log(this.productListingSelected.listings[i].quantity);
              this.totalListingsQuantity = Number.parseFloat(this.totalListingsQuantity) + Number.parseFloat(this.productListingSelected.listings[i].quantity);
            }
            return this.totalListingsQuantity;
          }else{
            this.totalListingsQuantity = 0;
            return 0;
          }
      }else{
        return 0;
      }
    }
  },
  created(){
    //console.log(localStorage.getItem('default_company'));
    //this.company_info = JSON.parse(localStorage.getItem('default_company'));
    this.searchResource();
    this.getListingTypes();
    this.getCountries();
    
  },
  mounted(){
    this.includeGoogle('maps.googleapis.com/maps/api/js?key=AIzaSyCO-2c9bJAX3z7cP3WN2O6x7ndipzOvZsU&libraries=places,geometry');
  },
}
</script>

<template>
  <Layout :user="$attrs.user" :company="$attrs.company" >
    <PageHeader :title="title" :items="items" />
        <div class="row mb-3">
      
                   <b-col md="12">
                    <transition name="slide-success" mode="out-in">
                  <b-alert id="success-message" variant="success" :show="success_message"  dismissible @dismissed="success_message = !success_message">
                      <p class="font-size-14">Congratulations, your action has been saved.</p>
                    </b-alert>
                  </transition>
                  </b-col>

          <div class="col-xl-1 col-sm-6">
            <div class="mt-2">
              <b-form-select class="form-control" v-model="limit" :options="limits" @input="searchResource()"></b-form-select>
            </div>
          </div>
         
          <div class="col-lg-1 col-sm-6 d-flex">
          <form class="my-auto">
            <small class="mx-auto my-2 text-center d-block">Results {{shopsData.from}} - {{shopsData.to}} of {{shopsData.total}}</small>
          </form>
        </div>
        <div class="col-lg-3 col-sm-6 d-flex">
          <form class="my-auto ml-auto">
            <div role="group" class="btn-group">
            
            </div>
            
            <b-dropdown text="Type" variant="light" class="rounded-pill mx-0 px-0">
                  <b-dropdown-item v-for="type in listing_types" href="javascript: void(0);" @click="type_id = type.id, searchResource()">{{type.name}}</b-dropdown-item>
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-item href="javascript: void(0);" @click="type_id = '', searchResource()">Clear</b-dropdown-item>
                </b-dropdown>
                 <a class="btn btn-light w-md"  @click="archived = '', completed = '', assignedto = '', status_id = '', departments = '', teams = '', searchResource()">Clear All</a>
          </form>
          </div>
          <div class="col-lg-3 col-sm-3 d-flex">
            <div class="form-inline w-100 my-auto search-box" >
                <div class="position-relative">
                  <input
                    type="text"
                    class="form-control w-100 border-0"
                    placeholder="Search name, reference or description..."
                    v-model="search"
                    @keyup.enter="searchFilter($event)"
                  />
                  <i class="bx bx-search-alt search-icon"></i>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-sm-4 d-flex">
              <ul class="nav nav-pills product-view-nav pt-2 ml-auto">
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': showFilters == true }" href="javascript: void(0);" @click="showFilters = !showFilters">
                    <i class="bx bx-filter-alt"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': view == 'grid' }" href="javascript: void(0);" @click="view = 'grid', searchResource(), listview = 'grid'">
                    <i class="bx bx-grid-alt"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': view == 'list' }" href="javascript: void(0);" @click="view = 'list', searchResource(), listview = 'list'">
                    <i class="bx bx-list-ul"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <b-dropdown text="Bulk Actions" variant="primary" class="rounded">
                   <b-dropdown-text class="list-group list-group-flush" text-class="list-group-item mb-0" @mouseenter="showActiveOn = 'active'" @mouseleave="showActiveOn = -1">

                      <i class="bx bx-check-circle font-size-14 text-success">
                        </i> Set Active On:
                   <transition name="fade-right">
                        <b-list-group v-if="showActiveOn == 'active'" flush class="pages-submenu hide" style="position: absolute; left: -100%; top: 0; width: 100%" >
                           <b-list-group-item href="javascript: void(0);" @click="setActive(true, 'active')">Shops</b-list-group-item>
                            <b-list-group-item href="javascript: void(0);" @click="setActive(true, 'public')">Public Market</b-list-group-item>
                            <b-list-group-item href="javascript: void(0);" @click="setActive(true, 'trade')">Trade Market</b-list-group-item>
                        </b-list-group>
                      </transition>
                    </b-dropdown-text>
                    <b-dropdown-text class="list-group list-group-flush" text-class="list-group-item mb-0" @mouseenter="showActiveOn = 'inactive'" @mouseleave="showActiveOn = -1">

                      <i class="bx bx-x-circle font-size-14 text-danger">
                        </i> Set Inactive On:
                   <transition name="fade-right">
                        <b-list-group v-if="showActiveOn == 'inactive'" flush class="pages-submenu hide" style="position: absolute; left: -100%; top: 0; width: 100%" >
                           <b-list-group-item href="javascript: void(0);" @click="setActive(false, 'active')">Shops</b-list-group-item>
                            <b-list-group-item href="javascript: void(0);"  @click="setActive(false, 'public')">Public Market</b-list-group-item>
                            <b-list-group-item href="javascript: void(0);"  @click="setActive(false, 'trade')">Trade Market</b-list-group-item>
                        </b-list-group>
                      </transition>
                    </b-dropdown-text>
                  <b-dropdown-divider></b-dropdown-divider>
              
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-item href="javascript: void(0);" v-b-modal.delete-modal @click="deleteMode = 'bulk'"><i class="bx bx-trash-alt font-size-14"></i> Delete</b-dropdown-item>
                </b-dropdown>
                </li>
                <li class="nav-item">
                 <a
                    class="btn btn-success mb-2 mr-2 text-white"
                    @click="addNewListing = !addNewListing"
                  >
                    <i class="mdi mdi-plus mr-1"></i> New
                  </a>
                </li>
              </ul>
              <div class="text-sm-right">
                  
                </div>
          </div>
        </div>
        <div class="card" v-if="view == 'list'" >
          <gmap-map v-if="'data' in shopsData && shopsData.data.length > 0" :center="mapCenter" :zoom="6" style="height: 450px" class="border-radius-1em">
                  <gmap-marker

                     v-for="locationMap in shopsData.data"
                    :key="index"
                    :position="{ lat: parseFloat(locationMap.latitude), lng: parseFloat(locationMap.longitude) }"
                    :clickable="true"
                    :draggable="true"
                    @click="center = { lat: parseFloat(locationMap.latitude), lng: parseFloat(locationMap.longitude) }"
                  ></gmap-marker>
                </gmap-map>
          <div class="card-body p-0">
            <div class="table">
              <table class="table table-centered table-hover">

                <thead class="thead-light">
                  <tr>
                    <th scope="col"  style="width: 100px" class="text-center"><div
                        class="form-check form-switch form-switch-sm mb-0 mx-auto p-0 mt-auto d-flex"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input mx-auto my-auto" 
                          type="checkbox"
                          id="SwitchCheckSizesm"
                         
                          v-model="isCheckAll"
                          @click='checkAll()'
                        
                        />
                    </div></th>
                    <th scope="col" style="max-width: 300px"><a :class="{'text-dark': sortby != 'name'}" href="javascript: void(0);" @click="orderDesc = !orderDesc,  sortby = 'name', searchResource()()">Name & Description <i v-if="orderDesc == true" class="bx bx-caret-up"></i><i v-if="orderDesc == false" class="bx bx-caret-down"></i></a></th>
                    <th scope="col">Listing Type</th>
                    <th scope="col">Address</th>
                    <th scope="col">County</th>
                    <th scope="col">Postcode</th>
                    <th scope="col">Country</th>
                    <th scope="col">Products</th>
                    <th scope="col">Status</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
               
                <tbody>
                  
                  <tr v-for="list in shopsData.data" :key="list.id" valign="middle">
                    <td>
                      <div class="text-center" v-if="list.logo">
                      <b-form-checkbox class="select-row-btn" v-model="selectedids" :value="list.id" button-variant="primary" @input="updateSelected" button>
                        <i v-if="filterSelected(list.id)" class="bx bx-check"></i>
                        <img v-else :src="`${list.logo}`" alt class="avatar-sm rounded-circle" />
                      </b-form-checkbox>
                    </div>
                    <div class="text-center" v-else >
                        <b-form-checkbox class="select-row-btn" v-model="selectedids" :value="list.id" button-variant="primary" @input="updateSelected" button>

                          <i v-if="filterSelected(list.id)" class="bx bx-check"></i><span class="avatar-title rounded-circle" v-else>{{(list.name).slice(0,1)}}</span></b-form-checkbox>
                    </div> 
                    </td>
                    <td style="max-width: 300px">
                    <a :href="'/company/shop/'+list.uid"><h5 class="my-0">{{list.name}}</h5>
                     <div class="col-sm-12 text-muted text-truncate mb-0" v-if="list.description != null" v-html="(list.description)"></div></a>
                    </td>
                    <td valign="align-middle">
                      <span class="badge badge-light text-dark font-size-12" v-if="list.listing_type !== null">{{list.listing_type.name}}</span>
                    </td>
                    <td>
                     {{list.address_1}}<br>
                     {{list.address_2}}<br>
                     {{list.address_3}}
                     </td>
                     
                    <td><span v-if="list.county !== null">{{list.county.name}}</span></td>
                    <td>{{list.postcode}}</td>
                    <td><span v-if="list.country !== null">{{list.country.name}}</span></td>
                    <td>
                      <a href="javascript:;" v-b-tooltip.hover title="Stock" placement="left" @click="selectedListing = list, getProducts(list.uid), showListingProducts = !showListingProducts" class="btn btn-outline-light btn-rounded"><i class="bx bx bx-purchase-tag-alt"></i></a>
                    </td>
                    <td>
                    <div
                        class="form-check form-switch form-switch-sm mb-0 mt-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          @click="selectedids = [], selectedids.push(list.id), setActive(!list.active, 'active')"
                          type="checkbox"
                          id="SwitchCheckSizesm"
                          v-model="list.active"
                          value="1"
                          unchecked-value="0"
                        
                        />
                        <label class="form-check-label" for="SwitchCheckSizesm" 
                          >Active</label
                        >
                    </div>
                    <div
                        class="form-check form-switch form-switch-sm mb-0"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          @click="selectedids = [], selectedids.push(list.id), setActive(!list.trade, 'trade')"
                          type="checkbox"
                          id="SwitchCheckSizesm"
                          v-model="list.trade"
                          value="1"
                          unchecked-value="0"
                        />
                        <label class="form-check-label" for="SwitchCheckSizesm" 
                          >Trade</label
                        >
                    </div>
                    <div
                        class="form-check form-switch form-switch-sm mb-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          @click="selectedids = [], selectedids.push(list.id), setActive(!list.public, 'public')"
                          type="checkbox"
                          id="SwitchCheckSizesm"
                          v-model="list.public"
                          value="1"
                          unchecked-value="0"
                        />
                        <label class="form-check-label" for="SwitchCheckSizesm" 
                          >Public</label
                        >
                    </div>
                    </td>
                    <td>
                      <ul class="list-inline font-size-20 contact-links mb-0">
                        <li class="list-inline-item">
                          <a @click="selectedListing = list, getCounties(selectedListing.country_id), updateListing = !updateListing" v-b-tooltip.hover title="Edit" placement="left" class="btn btn-outline-light btn-rounded"><i class="bx bx-edit-alt"></i>
                          </a>
                        </li>
                      <li class="list-inline-item">
                        <a href="javascript:;" v-b-tooltip.hover title="View" placement="left" class="btn btn-outline-light btn-rounded"><i class="mdi mdi-eye-outline"></i></a>
                      </li>
                        <li class="list-inline-item">
                           <b-dropdown v-b-tooltip.hover title="More Options" placement="left" dropleft class="more-options-dropdown btn btn-outline-light btn-rounded">
                        <template v-slot:button-content>
                           <i class="bx bx-dots-horizontal-rounded"></i>
                        </template>
                        <b-dropdown-item>Action</b-dropdown-item>
                        <b-dropdown-item>Another Action</b-dropdown-item>
                        <b-dropdown-item>Something else here</b-dropdown-item>
                      </b-dropdown>
                        </li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
           
          </div>
      </div>
    <div class="row" v-if="view == 'grid'" >
      <b-col md="12" class="mb-4">
      <gmap-map v-if="shopsData.data.length > 0" :center="mapCenter" :zoom="6" style="height: 450px" class="border-radius-1em">
                  <gmap-marker

                     v-for="locationMap in shopsData.data"
                    :key="index"
                    :position="{ lat: parseFloat(locationMap.latitude), lng: parseFloat(locationMap.longitude) }"
                    :clickable="true"
                    :draggable="true"
                    @click="center = { lat: parseFloat(locationMap.latitude), lng: parseFloat(locationMap.longitude) }"
                  ></gmap-marker>
                </gmap-map>
        </b-col>
     <div class="col-xl-4"  v-for="list in shopsData.data" :key="list.id">
      <b-modal :id="'listing-info-'+list.uid" hide-footer hide-header content-class="border-none border-radius-1em overflow-hidden" body-class="p-0" size="lg" centered>
    <a class="btn btn-light btn-rounded position-absolute z-index-2 top-0 right-0 m-3" @click="$bvModal.hide('listing-info-'+list.uid)">
      <i class="mdi mdi-close"></i>
    </a>
    <gmap-map :center="{ lat: parseFloat(list.latitude), lng: parseFloat(list.longitude) }" :zoom="7" style="height: 350px" class="border-radius-1em">
                  <gmap-marker

                    :position="{ lat: parseFloat(list.latitude), lng: parseFloat(list.longitude) }"
                    :clickable="true"
                    :draggable="true"
                    @click="center = { lat: parseFloat(list.latitude), lng: parseFloat(list.longitude) }"
                  ></gmap-marker>
                </gmap-map>
       <b-row>
        <b-col>
          <b-card class="shadow-none border-none mb-0">
            <div class="d-flex">
                  <h4 class="card-title">{{list.name}}</h4>

                  <a class="btn btn-light btn-rounded ml-auto" :href="'tel:'+list.phone">
                   Call Now
                  </a>
                  <a class="btn btn-light btn-rounded ml-2" :href="'https://www.google.com/maps/dir/?api=1&query_place_id='+list.gplace_id+'&zoom=12'">
                    Get Directions
                  </a>
            </div>
          <p class="text-muted my-1 d-flex">
                  <span class="my-auto mr-2"><i class="bx bx-home font-size-20 text-primary"></i></span>
                  <span class="my-auto mr-1" v-if="list.address_1 !== null">{{list.address_1}},</span>
                  <span class="my-auto mr-1" v-if="list.address_2 !== null">{{list.address_2}},</span>
                  <span class="my-auto mr-1" v-if="list.address_3 !== null">{{list.address_3}},</span>
                  <span class="my-auto mr-1" v-if="list.city !== null">{{list.city}},</span>
                  <span class="my-auto mr-1" v-if="list.county !== null">{{list.county.name}},</span>
                  <span class="my-auto mr-1" v-if="list.postcode !== null">{{list.postcode}}</span>
                  <span class="my-auto mr-1" v-if="list.country !== null">{{list.country.name}}</span>
                  
                </p>
                <p class="d-flex" >
                  <span class="my-auto"><i class="bx bx-phone text-primary font-size-20"></i></span>
                  <span class="my-auto ml-2 text-muted">{{list.phone}}</span>

                </p>
                  <!--<p  class="text-muted ml-auto my-auto mt-3 mr-3">
                          <span class="bx bx-star font-size-14 text-warning"></span>
                          <span class="bx bx-star font-size-14 text-warning ml-1"></span>
                          <span class="bx bx-star font-size-14 text-warning ml-1"></span>
                          <span class="bx bx-star font-size-14 text-warning ml-1"></span>
                          <span class="bx bx-star font-size-14 ml-1"></span>
                        </p>
                 <h6>Location Reviews</h6>
                 <div v-if="listing.reviews.length > 0">
                     <simplebar style="max-height: 380px;">         
                      <b-card class="border-radius-1em border shadow-none">
                          <b-list-group>
                            <b-list-group-item v-for="review in listing.reviews">
                            </b-list-group-item>
                          </b-list-group>
                      </b-card>
                    </simplebar>
                  </div>
                  <div v-else>
                    <b-card class="border-radius-1em border shadow-none" body-class="text-center">
                          <i class="bx bx-star text-warning d-inline-block font-size-14"></i>
                          <i class="bx bx-star text-warning d-inline-block font-size-20"></i>
                          <i class="bx bx-star text-warning d-inline-block font-size-28"></i>
                          <i class="bx bx-star text-warning d-inline-block font-size-20"></i>
                          <i class="bx bx-star text-warning d-inline-block font-size-14"></i>
                          <h4 class="text-muted">
                            No Reviews
                          </h4>
                    </b-card>
                  </div>-->
           </b-card>
        </b-col>
       </b-row>
  </b-modal>
      <b-card class="card overflow-hidden border-radius-1em shadow-sm seller-profile-card" no-body @mouseenter="hoverIndex = index" @mouseleave="hoverIndex = -1">
          <div class="bg-soft bg-primary">
            <div class="row">
              <transition name="fade-left">
                          <div class="col-md-12 position-absolute z-index-2" v-if="hoverIndex == index"> 
                                <!--<a :href="'/company/shop/'+list.uid" class="mt-3 ml-3 btn btn-primary font-size-16 btn-rounded ml-auto float-left"><i class="mdi mdi-eye-outline"></i></a>-->
                              </div>
                          </transition>
                           
                       <div class="col-12">

                <a  :href="'/company/shop/'+list.uid">
                          <div v-if="list.banner !== null">  

                            <b-card-img-lazy :src="storageurl + list.banner.src" alt class="img-fluid" /></b-card-img-lazy>

                            <!--<b-card-img-lazy v-if="'gallery' in list.banner" :src="storageurl + list.banner.gallery" alt class="img-fluid" /></b-card-img-lazy>
                            <b-card-img-lazy v-else-if="'thumb' in list.banner" :src="storageurl + list.banner.thumb" alt class="img-fluid" /></b-card-img-lazy>
                            <b-card-img-lazy v-else-if="'src' in list.banner" :src="storageurl + list.banner.src" alt class="img-fluid" /></b-card-img-lazy>
                            <b-card-img-lazy v-else src="/images/banner-placeholder.png" alt class="img-fluid" /></b-card-img-lazy>-->
                          </div>
                          <b-card-img-lazy v-else src="/images/banner-placeholder.png" alt class="img-fluid" /></b-card-img-lazy>
                        </a>
                 <!--<transition name="fade-right">
                            <div class="mt-auto mb-3 mx-2 d-flex position-absolute bottom-0 right-0 left-0" v-if="hoverIndex == index">
                                <p  class="text-muted ml-auto my-auto mr-3">
                          <span class="bx bx-star font-size-14 text-warning"></span>
                          <span class="bx bx-star font-size-14 text-warning ml-1"></span>
                          <span class="bx bx-star font-size-14 text-warning ml-1"></span>
                          <span class="bx bx-star font-size-14 text-warning ml-1"></span>
                          <span class="bx bx-star font-size-14 ml-1"></span>
                        </p>
                        </div>
                      </transition>-->
              </div>
            </div>
          </div>
          <b-card-body class="card-body pt-0">
            <div class="row">
              <div class="col-sm-12 pb-4 text-center">
                <div class="avatar-md profile-user-wid mb-2 mx-auto" style="margin-top: -45px !important;">
                  
                  <a href="javascript:;" class="avatar-title avatar-md bg-light text-primary rounded-circle" v-b-tooltip.hover title="View Map"  @click="$bvModal.show('listing-info-'+list.uid)">
                    <i class="bx bx-map-pin font-size-20"></i>
                  </a>
                </div>
                <h5 class="font-size-15 text-truncate">{{list.name}}</h5>
                <p class="text-muted mb-0">
                  <span v-if="list.address_1 !== null">{{list.address_1}},</span>
                  <span v-if="list.address_2 !== null">{{list.address_2}},</span>
                  <span v-if="list.address_3 !== null">{{list.address_3}},</span>
                  <span v-if="list.city !== null">{{list.city}},</span>
                  <span v-if="list.county !== null">{{list.county.name}},</span>
                  <span v-if="list.postcode !== null">{{list.postcode}}</span>
                  <span v-if="list.country !== null">{{list.country.name}}</span>
                </p>
              </div>
              <div class="col-md-12 d-flex">
                <span class="badge badge-light border-radius-1em bg-light text-dark font-size-11 lh-16 my-auto p-2 mx-auto" v-if="list.listing_type !== null">
                  {{list.listing_type.name}}
                </span>
              </div>
             
              <div class="col-sm-12">
                <div class="pt-4">
                  <div class="mt-4 d-flex">
                    <!--<a href="javascript:;" @click="selectedListing = list, getProducts(list.uid)" class="btn btn-outline-light btn-rounded"><i class="bx bx bx-purchase-tag-alt my-1"></i></a>-->
                     <a v-b-tooltip.hover title="View" placement="left" :href="'/company/shop/'+list.uid" class="btn btn-outline-light btn-rounded ml-2"><i class="mdi mdi-eye-outline"></i></a>
                    <a @click="selectedListing = list, getCounties(selectedListing.country_id), updateListing = !updateListing" v-b-tooltip.hover title="Edit Listing" class="btn btn-outline-light font-size-16 btn-rounded ml-2 my-auto"><i class="bx bx-pencil"></i></a>
                   
                     <b-dropdown v-b-tooltip.hover title="More Options" placement="left" dropleft class="more-options-dropdown btn btn-outline-light btn-rounded mr-auto ml-2">
                        <template v-slot:button-content>
                           <i class="bx bx-dots-horizontal-rounded"></i>
                        </template>
                        <b-dropdown-item>Action</b-dropdown-item>
                        <b-dropdown-item>Another Action</b-dropdown-item>
                        <b-dropdown-item>Something else here</b-dropdown-item>
                      </b-dropdown>
                     <div
                        class="form-check form-switch form-switch-sm my-auto mr-3"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          id="SwitchCheckSizesm"
                          v-model="list.active"
                          value="1"
                          unchecked-value="0"
                        
                        />
                        <label class="form-check-label" for="SwitchCheckSizesm" 
                          >Active</label
                        >
                    </div>
                    <div
                        class="form-check form-switch form-switch-sm my-auto mr-3"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          id="SwitchCheckSizesm"
                          v-model="list.trade"
                          value="1"
                          unchecked-value="0"
                        />
                        <label class="form-check-label" for="SwitchCheckSizesm" 
                          >Trade</label
                        >
                    </div>
                    <div
                        class="form-check form-switch form-switch-sm my-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          id="SwitchCheckSizesm"
                          v-model="list.public"
                          value="1"
                          unchecked-value="0"
                        />
                        <label class="form-check-label" for="SwitchCheckSizesm" 
                          >Public</label
                        >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-card-body>
        </b-card>
    </div>
      </div>
       <div class="row">
              <div class="col-lg-12" v-if="shopsData !== ''">
                <b-pagination
              
              class="justify-content-center"
              pills
              v-model="currentPage"
              :total-rows="shopsData.total"
              :per-page="shopsData.per_page"
              aria-controls="my-table"
              @input="searchResource()"
            ></b-pagination>
             <small class="mx-auto my-2 text-center d-block">Records {{shopsData.from}} - {{shopsData.to}} of {{shopsData.total}} // Page {{shopsData.current_page}} of {{shopsData.last_page}}</small>
              </div>
            </div>
  
    <!--  end row -->

    <!-- Add New Panel -->
    <SideSlidePanel :editPanel="addNewListing" :panelClass="'edit-sidepanel-large'" @close-panel="addNewListing = 0">
      <b-row class="mx-4 px-2 pb-4">
        <b-col md="12">
            <transition name="slide-error" mode="out-in">
               <b-alert id="errors" variant="danger" :show="error_message"  dismissible @dismissed="error_message = !error_message">
                  <p class="font-size-14">Oops! Please correct the following errors in your submission</p>
                     <ul>
                       <li v-for="(error, key) in error_reponse">
                          {{error[0]}}
                        </li>
                      </ul>
                </b-alert>           
             </transition>
        </b-col>
        <b-form id="addnewlisting" method="POST" enctype="multipart/form-data">
        <b-col md="12">
          <h4>Add New Listing</h4>
          <hr>
           <b-overlay
                        id="overlay-background"
                        :show="loadingForm"
                        variant="white"
                        opacity="0.85"
                        blur="1px"
                        rounded="md"
                      >
          <b-row>
            <b-col md="2">
              <h5 class="d-flex">Baisc Info <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
            </b-col>
            <b-col md="10">
            <b-row>
         
            
              <b-col md="12">
              <b-card class="border-radius-1em border shadow-none">
                <b-form-group label="Listing Type">
                    <b-form-radio-group
                      id="radio-group-1"
                      name="listing_type_id"
                    >
                      <b-form-radio v-for="type in listing_types" :value="type.id" class="d-inline mr-3 radio-inline">
                        {{type.name}}
                      </b-form-radio>
                    </b-form-radio-group>
                  </b-form-group>
                <b-form-group class="mt-2" label="Listing Name">
                  <b-form-input type="text" name="name"></b-form-input>
                </b-form-group>
                <b-form-group class="mt-2" label="Listing Description">
                  <b-form-textarea name="description" rows="5"></b-form-textarea>
                </b-form-group>
              </b-card>
            </b-col>
          </b-row>
        </b-col>
          <b-col md="2">
            <h5 class="d-flex">Images <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
            </b-col>
            <b-col md="10">
            <b-row>
            <b-col md="6">
              <b-card class="border-radius-1em border shadow-none">
                 <b-form-group label="Alternative Logo">

                  <vue-dropzone id="dropzone" ref="myVueDropzone" :use-custom-slot="true" :options="dropzoneOptions" @vdropzone-file-added="logoAdded"  @vdropzone-drop="logoAdded">
                        <div class="dropzone-custom-content">
                            <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                            <h4>Drop files here or click to upload.</h4>
                        </div>
                        </vue-dropzone>
                 <!--<input class="form-control mb-2" name="logo" type="file">-->
                </b-form-group>
              </b-card>
             </b-col>
             <b-col md="6">
              <b-card class="border-radius-1em border shadow-none">
                <b-form-group label="Banner Image">
                  <vue-dropzone id="dropzone2" ref="myVueDropzone" :use-custom-slot="true" :options="dropzoneOptions" @vdropzone-file-added="imageAdded"  @vdropzone-drop="imageAdded">
                        <div class="dropzone-custom-content">
                            <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                            <h4>Drop files here or click to upload.</h4>
                        </div>
                        </vue-dropzone>
                  <!--<input class="form-control mb-2" name="banner_image" type="file">-->
                </b-form-group>
              </b-card>
            </b-col>
          </b-row>
        </b-col>
        <b-col md="2" class="my-3">
            <h5 class="d-flex">Listing Manager <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
            </b-col>
            <b-col md="10" class="my-3">
            <b-row>
            <b-col md="12">
              <b-card class="border-radius-1em border shadow-none pt-2">
                <SearchCompanyUser :api_token="$attrs.user.api_token" @user-selected="selectManager" ></SearchCompanyUser>
              </b-card>
              </b-col>
            </b-row>
          </b-col>
          <b-col md="2">
            <h5 class="d-flex">Location Details <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
            </b-col>
            <b-col md="10">
            <b-row>
              <b-col md="12">
                <b-card class="border-radius-1em border shadow-none">
                <b-form-group label="Search Address">
                  <vue-google-autocomplete id="map" :enableGeolocation="true" types="establishment" :fields="['address_components', 'adr_address', 'alt_id', 'formatted_address', 'geometry', 'icon', 'id', 'name', 'photo', 'place_id', 'scope', 'type', 'url', 'vicinity', ]" classname="form-control" placeholder="Start typing" v-on:placechanged="getAddressData">
                    </vue-google-autocomplete>
                </b-form-group>
                <gmap-map v-if="googleSearchAddress !== ''" :center="{ lat: googleSearchAddress.latitude, lng: googleSearchAddress.longitude }" :zoom="10" style="height: 350px" class="mt-2 border-radius-1em">
                  <gmap-marker
                    
                    :key="index"
                    :position="{ lat: googleSearchAddress.latitude, lng: googleSearchAddress.longitude }"
                    :clickable="true"
                    :draggable="true"
                    @click="center = { lat: googleSearchAddress.latitude, lng: googleSearchAddress.longitude }"
                  ></gmap-marker>
                </gmap-map>
              </b-card>
              <b-card class="border-radius-1em border shadow-none border-primary" body-class="d-flex" v-if="googleSearchAddress !== ''">
                <div v-html="googleSearchAddress.place.formatted_address"></div>
              
                <a class="btn btn-danger ml-auto" @click="googleSearchAddress = '', newListing.gplace_id = '', newListing.longitude = 0, newListing.latitude = 0">Remove Listing</a>
              </b-card>
              <b-card class="border-radius-1em border shadow-none">
                <b-form-group class="mt-2"  label="Address 1">
                  <b-form-input type="text" name="address1" v-model="newListing.address1"></b-form-input>
                </b-form-group>
                <b-form-group class="mt-2" label="Address 2">
                  <b-form-input type="text" name="address2" v-model="newListing.address2"></b-form-input>
                </b-form-group>
                <b-form-group class="mt-2" label="Address 3">
                  <b-form-input type="text" name="address3" v-model="newListing.address3"></b-form-input>
                </b-form-group>
                
                <b-form-group class="mt-2" label="Country">
                  <b-form-select class="form-control" v-model="selected_country_id" name="country_id"  @input="getCounties(selected_country_id)">
                    <b-form-select-option v-for="country in countries" :value="country.id">
                      {{country.name}}
                    </b-form-select-option>
                  </b-form-select>
                </b-form-group>
                <b-form-group class="mt-2" label="County/State">
                  <b-form-select class="form-control" name="county_id" v-model="selected_county_id" :disabled="(counties.length == 0)">
                    <b-form-select-option v-for="county in counties" :value="county.id" >
                      {{county.name}}
                    </b-form-select-option>
                  </b-form-select>
                </b-form-group>
                <b-form-group class="mt-2" label="City">
                  <b-form-input type="text" name="city" v-model="newListing.city"></b-form-input>
                </b-form-group>
                <b-form-group class="mt-2" label="Postcode/Zip">
                  <b-form-input type="text" name="postcode" v-model="newListing.postcode"></b-form-input>
                </b-form-group>
              </b-card>
              </b-col>
            </b-row>
          </b-col>
          <b-col md="2">
            <h5 class="d-flex">Location Details <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
            </b-col>
            <b-col md="10">
             <b-row>
              <b-col>
                <b-card class="border-radius-1em border shadow-none">
                <b-form-group  class="mt-2" label="Email">
                  <b-form-input type="text" name="email"></b-form-input>
                </b-form-group>
                <b-form-group class="mt-2" label="Phone">
                  <b-form-input type="text" name="phone"></b-form-input>
                </b-form-group>
                <b-form-group class="mt-2" label="Website">
                  <b-form-input type="text" name="website"></b-form-input>
                </b-form-group>
               </b-card>
              </b-col>
              </b-row>
            </b-col>
            <b-col md="2">
            <h5 class="d-flex">Visibility <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
            </b-col>
            <b-col md="10">
              <b-card class="border-radius-1em border shadow-none">
              <b-row>
              <b-col md="12">
                <div class="d-flex">
            <b-button
                      class="flex-grow-1 my-0 w-sm"
                      :class="{'active' : shopsactive == (true || 1 || '1')}"
                      variant="outline-light"
                      @click="shopsactive = !shopsactive"
                    ><i v-if="shopsactive == (true || 1 || '1')" class="bx bx-check-circle font-size-24 d-block text-success my-2"></i> 
                    <i v-else class="bx bx-basket font-size-24 d-block text-primary my-2"></i> My Shops
              </b-button>
              <b-button
                      class=" flex-grow-1 my-0 w-sm"
                      :class="{'active' : public == (true || 1 || '1')}"
                      variant="outline-light"
                      @click="public = !public"
                    ><i v-if="public == (true || 1 ||  '1')" class="bx bx-check-circle font-size-24 d-block text-success my-2"></i> 
                    <i v-else class="bx bx-basket font-size-24 d-block text-primary my-2"></i> Public Market
              </b-button>
              <b-button
                      class=" flex-grow-1 my-0 w-sm"
                      :class="{'active' : trade == (true || 1 || '1')}"
                      variant="outline-light"
                      @click="trade = !trade"
                    ><i v-if="trade == (true || 1 || '1')" class="bx bx-check-circle font-size-24 d-block text-success my-2"></i> 
                    <i v-else class="bx bx-basket font-size-24 d-block text-primary my-2"></i> Trade Market
              </b-button>
            
            </div>
                <!--<div
                          class="form-check form-switch form-switch-sm d-inline-block mr-2 mb-0 mt-auto"
                          dir="ltr"
                        >
                        <input
                            class="form-check-input"
                            type="checkbox"
                            id="SwitchCheckSizesm"
                          
                            value="1"
                            unchecked-value="0"
                            name="active"
                          />
                          <label class="form-check-label" for="SwitchCheckSizesm" 
                            >Active</label
                          >
                      </div>
                      <div
                          class="form-check form-switch form-switch-sm d-inline-block mr-2 mb-0"
                          dir="ltr"
                        >
                        <input
                            class="form-check-input"
                            type="checkbox"
                            id="SwitchCheckSizesm"
                         
                            value="1"
                            unchecked-value="0"
                            name="trade"
                          />
                          <label class="form-check-label" for="SwitchCheckSizesm" 
                            >Trade</label
                          >
                      </div>
                      <div
                          class="form-check form-switch form-switch-sm d-inline-block mr-2 mb-auto"
                          dir="ltr"
                        >
                        <input
                            class="form-check-input"
                            type="checkbox"
                            id="SwitchCheckSizesm"
                           
                            value="1"
                            unchecked-value="0"
                            name="public"
                          />
                          <label class="form-check-label" for="SwitchCheckSizesm" 
                            >Public</label
                          >
                      </div>-->
                    </b-col>
                  </b-row>
                </b-card>
                  <input type="hidden" name="featured" value="0">
              </b-col>
               <b-col md="2">
               </b-col>
              <b-col md="10">
                <b-button variant="success" class="w-100 mt-3" @click="submitListing()">Save</b-button>
              </b-col>
        </b-row>
     
    </b-overlay>
        </b-col>
        </b-form>
      </b-row>
    </SideSlidePanel>
    <!-- Add New Panel -->
    <SideSlidePanel :editPanel="updateListing" :panelClass="'edit-sidepanel-large'" @close-panel="updateListing = !updateListing">
      <b-row class="mx-4 px-2 pb-4">
        <b-col md="12">
            <transition name="slide-error" mode="out-in">
               <b-alert id="errors" variant="danger" :show="error_message"  dismissible @dismissed="error_message = !error_message">
                  <p class="font-size-14">Oops! Please correct the following errors in your submission</p>
                     <ul>
                       <li v-for="(error, key) in error_reponse">
                          {{error[0]}}
                        </li>
                      </ul>
                </b-alert>           
             </transition>
        </b-col>
       <b-form id="updatelisting" method="POST" enctype="multipart/form-data">
        <b-col md="12">
          <h4>Update Listing</h4>
          <hr>
           <b-overlay
                        id="overlay-background"
                        :show="loadingForm"
                        variant="white"
                        opacity="0.85"
                        blur="1px"
                        rounded="md"
                      >
          <b-row>
            <b-col md="2">
            <h5 class="d-flex">Basic Info <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
            </b-col>
            <b-col md="10">
              <b-card class="border-radius-1em border shadow-none">
              <b-form-group label="Listing Type">
                  <b-form-radio-group
                    id="radio-group-1"
                    v-model="selectedListing.listing_type_id"
                    name="listing_type_id"
                  >
                    <b-form-radio v-for="type in listing_types" :value="type.id" class="d-inline mr-3 radio-inline">
                      {{type.name}}
                    </b-form-radio>
                  </b-form-radio-group>
                </b-form-group>
              <b-form-group class="mt-2" label="Listing Name">
                <b-form-input type="text" v-model="selectedListing.name" name="name"></b-form-input>
              </b-form-group>
              <b-form-group class="mt-2" label="Listing Description">
                <b-form-textarea v-model="selectedListing.description" name="description" rows="5"></b-form-textarea>
              </b-form-group>
              <!-- <b-form-group label="Logo">
               <input class="form-control mb-2" name="logo" type="file">
              </b-form-group>
              <b-form-group label="Banner Image">
                <input class="form-control mb-2" name="banner_image" type="file">
              </b-form-group>-->
              </b-card>
            </b-col>
             <b-col md="2" class="my-3">
            <h5 class="d-flex">Images <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
            </b-col>
            <b-col md="10" class="my-3">
             <b-row>
            <b-col md="6">
                 <b-col md="6" v-if="updateListing.logo">
              <b-form-group class="mt-2" label="Image">
              <img :src="updateListing.logo" class="mx-auto img-fluid border" @mouseover="imageOptions = 'cover'">
                <div class="left-0 right-0 top-0" style="max-height: 500px;" :class="{'h-100 d-flex flex-column position-absolute bg-white bg-soft': imageOptions == 'cover', 'd-none': imageOptions !== 'cover'}"  @mouseout="imageOptions = ''">
                              <a class="btn btn-primary mt-auto w-75 mx-auto mb-1" @click="$emit('file-selected', object), $emit('close-window')">Edit</a>
                              <a class="btn btn-primary mb-auto w-75 mx-auto mt-1" @click="previewFile = object, previewMode = true">Upload New</a>
                            </div>
                </b-form-group>
              </b-col>
              <b-card v-else class="border-radius-1em border shadow-none">
                 <b-form-group label="Alternative Logo">

                  <vue-dropzone id="dropzone" ref="myVueDropzone" :use-custom-slot="true" :options="dropzoneOptions" @vdropzone-file-added="logoUpdated"  @vdropzone-drop="logoUpdated">
                        <div class="dropzone-custom-content">
                            <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                            <h4>Drop files here or click to upload.</h4>
                        </div>
                        </vue-dropzone>
                 <!--<input class="form-control mb-2" name="logo" type="file">-->
                </b-form-group>
              </b-card>

             </b-col>
             <b-col md="6" v-if="updateListing.banner">
              <b-form-group class="mt-2" label="Banner Image">
              <img :src="updateListing.banner" class="mx-auto img-fluid border" @mouseover="imageOptions = 'banner'">
                <div class="left-0 right-0 top-0" style="max-height: 500px;" :class="{'h-100 d-flex flex-column position-absolute bg-white bg-soft': imageOptions == 'banner', 'd-none': imageOptions !== 'banner'}"  @mouseout="imageOptions = ''">
                              <a class="btn btn-primary mt-auto w-75 mx-auto mb-1" @click="$emit('file-selected', object), $emit('close-window')">Edit</a>
                              <a class="btn btn-primary mb-auto w-75 mx-auto mt-1" @click="previewFile = object, previewMode = true">Upload New</a>
                            </div>
                </b-form-group>
              </b-col>
             <b-col md="6" v-else>
              <b-card class="border-radius-1em border shadow-none">
                <b-form-group label="Banner Image">
                  <vue-dropzone id="dropzone2" ref="myVueDropzone" :use-custom-slot="true" :options="dropzoneOptions" @vdropzone-file-added="imageUpdated"  @vdropzone-drop="imageUpdated">
                        <div class="dropzone-custom-content">
                            <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                            <h4>Drop files here or click to upload.</h4>
                        </div>
                        </vue-dropzone>
                  <!--<input class="form-control mb-2" name="banner_image" type="file">-->
                </b-form-group>
              </b-card>
            </b-col>

          </b-row><b-row>
             
               
            </b-row>
          </b-col>
          <b-col md="2" class="my-3">
            <h5 class="d-flex">Listing Manager <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
          </b-col>
          <b-col md="10" class="my-3">
            <b-row>
            <b-col md="12">
              <b-card class="border-radius-1em border shadow-none pt-2">
                <SearchCompanyUser :api_token="$attrs.user.api_token" @user-selected="updateManager" :id="selectedListing.manager" ></SearchCompanyUser>
              </b-card>
              </b-col>
            </b-row>
          </b-col>
          <b-col md="2" class="my-3">
            <h5 class="d-flex">Location Details <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
          </b-col>
            <b-col md="10">
              <b-card class="border-radius-1em border shadow-none">
                <b-form-group label="Search Address">
                  <vue-google-autocomplete  id="map" :enableGeolocation="true" types="establishment" :fields="['address_components', 'adr_address', 'alt_id', 'formatted_address', 'geometry', 'icon', 'id', 'name', 'business_status', 'photo', 'place_id', 'scope', 'type', 'url', 'utc_offset', 'vicinity', ]" classname="form-control" placeholder="Start typing" v-on:placechanged="getAddressData">
                    </vue-google-autocomplete>
                </b-form-group>
                <gmap-map v-if="selectedListing !== ''" :center="{ lat: parseFloat(selectedListing.latitude), lng: parseFloat(selectedListing.longitude) }" :zoom="10" style="height: 350px" class="mt-2 border-radius-1em">
                  <gmap-marker
                    
                    
                    :position="{ lat: parseFloat(selectedListing.latitude), lng: parseFloat(selectedListing.longitude) }"
                    :clickable="true"
                    :draggable="true"
                    @click="center = { lat: parseFloat(selectedListing.latitude), lng: parseFloat(selectedListing.longitude) }"
                  ></gmap-marker>
                </gmap-map>
              </b-card>
              <b-card class="border-radius-1em border shadow-none border-primary" body-class="d-flex" v-if="selectedListing !== ''">
                <!--<div v-html="selectedListing.place.formatted_address"></div>-->
                <h6 class="my-auto">Google Place Active</h6>
                <a class="btn btn-danger ml-auto" @click="selectedListing = '', selectedListing.gplace_id = '', selectedListing.longitude = 0, selectedListing.latitude = 0">Remove Google Place Listing</a>
              </b-card>
              <b-card class="border-radius-1em border shadow-none">
              <b-form-group  label="Address 1">
                <b-form-input v-model="selectedListing.address_1" type="text" name="address1"></b-form-input>
              </b-form-group>
              <b-form-group class="mt-2" label="Address 2">
                <b-form-input v-model="selectedListing.address_2" type="text" name="address2"></b-form-input>
              </b-form-group>
              <b-form-group class="mt-2" label="Address 3">
                <b-form-input v-model="selectedListing.address_3" type="text" name="address3"></b-form-input>
              </b-form-group>
              
              <b-form-group class="mt-2" label="Country">
                <b-form-select v-model="selectedListing.country_id" class="form-control" name="country_id" @input="getCounties(selectedListing.country_id)">
                  <b-form-select-option v-for="country in countries" :value="country.id">
                    {{country.name}}
                  </b-form-select-option>
                </b-form-select>
              </b-form-group>
              <b-form-group class="mt-2" label="County/State" v-if="(selectedListing.length !== 0)">
                <b-form-select v-model="selectedListing.county_id" class="form-control" name="county_id" :disabled="(selectedListing.length == 0)">
                  <b-form-select-option v-for="county in counties"  :value="county.id">
                    {{county.name}}
                  </b-form-select-option>
                </b-form-select>
              </b-form-group>
              <b-form-group class="mt-2" label="City">
                <b-form-input v-model="selectedListing.city" type="text" name="city"></b-form-input>
              </b-form-group>
              <b-form-group class="mt-2" label="Postcode/Zip">
                <b-form-input v-model="selectedListing.postcode" type="text" name="postcode"></b-form-input>
              </b-form-group>
              <b-form-group  class="mt-2" label="Email">
                <b-form-input v-model="selectedListing.email" type="text" name="email"></b-form-input>
              </b-form-group>
              <b-form-group class="mt-2" label="Phone">
                <b-form-input v-model="selectedListing.phone" type="text" name="phone"></b-form-input>
              </b-form-group>
              <b-form-group class="mt-2" label="Website">
                <b-form-input v-model="selectedListing.website" type="text" name="website"></b-form-input>
              </b-form-group>
              </b-card>
            </b-col>
            <b-col md="2">
            <h5 class="d-flex">Visibility <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
            </b-col>
            <b-col md="10">
              <b-card class="border-radius-1em border shadow-none">
              <b-row>
              <b-col md="12">
                <div class="d-flex">
            <b-button
                      class="flex-grow-1 my-0 w-sm"
                      :class="{'active' : selectedListing.active == (true || 1 || '1')}"
                      variant="outline-light"
                      @click="selectedListing.active = !selectedListing.active"
                    ><i v-if="selectedListing.active == (true || 1 || '1')" class="bx bx-check-circle font-size-24 d-block text-success my-2"></i> 
                    <i v-else class="bx bx-basket font-size-24 d-block text-primary my-2"></i> My Shops
              </b-button>
              <b-button
                      class=" flex-grow-1 my-0 w-sm"
                      :class="{'active' : selectedListing.public == (true || 1 || '1')}"
                      variant="outline-light"
                      @click="selectedListing.public = !selectedListing.public"
                    ><i v-if="selectedListing.public == (true || 1 ||  '1')" class="bx bx-check-circle font-size-24 d-block text-success my-2"></i> 
                    <i v-else class="bx bx-basket font-size-24 d-block text-primary my-2"></i> Public Market
              </b-button>
              <b-button
                      class=" flex-grow-1 my-0 w-sm"
                      :class="{'active' : selectedListing.trade == (true || 1 || '1')}"
                      variant="outline-light"
                      @click="selectedListing.trade = !selectedListing.trade"
                    ><i v-if="selectedListing.trade == (true || 1 || '1')" class="bx bx-check-circle font-size-24 d-block text-success my-2"></i> 
                    <i v-else class="bx bx-basket font-size-24 d-block text-primary my-2"></i> Trade Market
              </b-button>
              
            </div>
                <!--<div
                          class="form-check form-switch form-switch-sm d-inline-block mr-2 mb-0 mt-auto"
                          dir="ltr"
                        >
                        <input
                            class="form-check-input"
                            type="checkbox"
                            id="SwitchCheckSizesm"
                          
                            value="1"
                            unchecked-value="0"
                            name="active"
                          />
                          <label class="form-check-label" for="SwitchCheckSizesm" 
                            >Active</label
                          >
                      </div>
                      <div
                          class="form-check form-switch form-switch-sm d-inline-block mr-2 mb-0"
                          dir="ltr"
                        >
                        <input
                            class="form-check-input"
                            type="checkbox"
                            id="SwitchCheckSizesm"
                         
                            value="1"
                            unchecked-value="0"
                            name="trade"
                          />
                          <label class="form-check-label" for="SwitchCheckSizesm" 
                            >Trade</label
                          >
                      </div>
                      <div
                          class="form-check form-switch form-switch-sm d-inline-block mr-2 mb-auto"
                          dir="ltr"
                        >
                        <input
                            class="form-check-input"
                            type="checkbox"
                            id="SwitchCheckSizesm"
                           
                            value="1"
                            unchecked-value="0"
                            name="public"
                          />
                          <label class="form-check-label" for="SwitchCheckSizesm" 
                            >Public</label
                          >
                      </div>-->
                    </b-col>
                  </b-row>
                </b-card>
                  <input type="hidden" name="featured" value="0">
              </b-col>
            <b-col md="2">
            </b-col>
          <b-col md="10">
              <b-button variant="success" class="w-100 mt-3" @click="submitUpdateListing()">Update</b-button>
            </b-col>
          </b-row>
      </b-overlay>
        </b-col>
      </b-form>
      </b-row>
    </SideSlidePanel>
    <!-- Products Panel -->
    <SideSlidePanel :editPanel="showListingProducts" :panelClass="'edit-sidepanel-large'" @close-panel="showListingProducts = !showListingProducts">
      <div class="row">
        
        <div class="col-md-12" v-if="selectedListing.length !== 0">
        <div class="d-flex mb-3">
          <img v-if="(selectedListing.logo !== '') && (selectedListing.logo !== null)" :src="'https://gemesys-dev.s3.eu-west-2.amazonaws.com/'+selectedListing.logo" class="rounded-circle avatar-lg border mr-2">
          <div class="avatar-md mr-3" v-else>
          <span class="avatar-title rounded-circle" >{{(selectedListing.name).slice(0,1)}}</span>
          </div>
          <div class="my-auto mr-auto ml-3"><h4 class="mb-0" >Products in <strong class="text-primary">{{selectedListing.companyname}}</strong></h4>
          <p class="mb-0">{{selectedListing.address_1}} {{selectedListing.address_2}} {{selectedListing.address_3}}</p>
          </div>
           <a id="tooltip-add" @click="addProductToListing = !addProductToListing" class="btn btn-outline-light font-size-16 btn-rounded ml-auto mr-3 my-auto"><i class="mdi mdi-plus"></i></a><b-tooltip target="tooltip-add">Add Product</b-tooltip>

        </div>
      </div>
    </div>
     <SearchBar class="mt-1 mb-4" v-if="(addProductToListing == 1)" :search_results="productsData" :search_resource="'products'" :product_brand_id="null"  @search-query="getSellerProducts" @search-resource-selected="addToListing"></SearchBar>
      <b-modal hide-header hide-footer no-close-on-backdrop centered ref="edit-modal" size="lg" title-class="mx-auto" content-class="border-radius-1em" header-class="border-none" >
          <b-tabs pills content-class="py-3 text-muted mx-auto " align="center">
              <b-tab active>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-home"></i>
                  </span>
                  <span class="d-none d-sm-inline-block"><i class="bx bx-star"></i> New Stock</span>
                </template>
              <div class="jumbotron p-3 border border-radius-1em" v-if="productListingSelected !== ''">
                <div class="row">
                  <div class="col-md-3">
                <img v-if="productListingSelected.cover_image !== null" :src="'https://gemesys-dev.s3.eu-west-2.amazonaws.com/'+productListingSelected.cover_image.src.replace('//', '/')" class="float-left img-fluid"> 
                <img v-else :src="'/images/product-placeholder.png'" class="float-left img-fluid"> 
                </div>
                <div class="col-md-9">
              <small class="text-dark">{{productListingSelected.reference}}</small>
               <h5 class="text-primary">{{productListingSelected.name}}</h5>
               <p v-html="productListingSelected.short_description"></p>
               <div class="row">
                <div class="col-md-6">
                 <p class="mb-1">Total Quantity</p>
                 <strong class="text-primary mb-auto font-size-20">{{Number.parseFloat(productListingSelected.total_qty)+Number.parseFloat(newquantity)}}</strong>
               </div>
                <div class="col-md-6">
                 <p class="mb-1">Quantity at this shop</p>
                 <strong class="text-primary mb-auto font-size-20">{{Number.parseFloat(listingquantity)+Number.parseFloat(newquantity)}}</strong>
               </div>
                </div>
              </div>
            </div>
          </div>
    <b-card class="border-radius-1em border shadow-none mt-3">
      <div class="col-md-12">
        <div class="d-flex my-4 pb-2">
          <div class="my-auto mr-4"><h4 class="m-0">Add to <strong class="text-primary">{{selectedListing.companyname}}</strong></h4>
          <p class="m-0">{{selectedListing.address_1}} {{selectedListing.address_2}} {{selectedListing.address_3}}</p>
          </div>
            <b-form-group label="Quantity" class="mx-auto">
              <b-form-input type="number" v-model="newquantity" /></b-form-group>
            </b-form-group>
            <a id="tooltip-add" @click="newquantity--" class="btn btn-outline-light font-size-16 btn-rounded mr-3 my-auto"><i class="mdi mdi-minus"></i></a>
            <a id="tooltip-add" @click="newquantity++" class="btn btn-outline-light font-size-16 btn-rounded my-auto"><i class="mdi mdi-plus"></i></a>
        </div>
        
      </div>
    </b-card>
      <div class="col-md-12 text-center">
        <b-button variant="primary" @click="closeEditModal()">Save</b-button>
        <b-button @click="closeEditModal(), newquantity = 0">Quit</b-button>
      </div>
          
              </b-tab>
              <b-tab>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="far fa-user"></i>
                  </span>
                  <span class="d-none d-sm-inline-block"><i class="bx bx-transfer-alt"></i> Transfer Stock</span>
                </template>
                <div class="jumbotron p-3 border border-radius-1em mb-3">
                <div class="row" v-if="productListingSelected !== ''">
                  <div class="col-md-3">
                <img v-if="productListingSelected.cover_image !== null" :src="'https://gemesys-dev.s3.eu-west-2.amazonaws.com/'+productListingSelected.cover_image.src.replace('//', '/')" class="float-left img-fluid"> 
                <img v-else :src="'/images/product-placeholder.png'" class="float-left img-fluid"> 
                </div>
                <div class="col-md-9">
              <small class="text-dark">{{productListingSelected.reference}}</small>
               <h5 class="text-primary">{{productListingSelected.name}}</h5>
               <p v-html="productListingSelected.short_description"></p>
               <div class="row">
                <div class="col-md-6">
                 <p class="mb-1">Total Quantity</p>
                 <strong class="text-primary mb-auto font-size-20">{{Number.parseFloat(productListingSelected.total_qty)}}</strong>
               </div>
                <div class="col-md-6">
                 <p class="mb-1">Quantity at this shop</p>
                 <strong class="text-primary mb-auto font-size-20">{{Number.parseFloat(listingquantity)}}</strong>
               </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-12">
            <b-card class="border-radius-1em border shadow-none mt-3">
            <h4 class="mb-2">Transfer From:</h4>
              <b-form-group>
                <b-form-radio-group :options="transferOptions" v-model="transferOption">
                </b-form-radio-group>
              </b-form-group>
               <div class="d-flex my-4" v-if="transferOption == 0">
          <div class="my-auto mr-4"><h4 class="m-0">Add to <strong class="text-primary">{{selectedListing.companyname}}</strong></h4>
          <p class="m-0">{{selectedListing.address_1}} {{selectedListing.address_2}} {{selectedListing.address_3}}</p>
          </div>
            <b-form-group v-if="" label="Quantity" class="mx-auto">
              <b-form-input type="number" v-model="transferquantity" :max="Number.parseFloat(productListingSelected.total_qty)-allProductListingQuantities" />
            </b-form-group>
            <a id="tooltip-add" @click="transferquantity--" class="btn btn-outline-light font-size-16 btn-rounded mr-3 my-auto"><i class="mdi mdi-minus"></i></a>
            <button id="tooltip-add" @click="transferquantity++" class="btn btn-outline-light font-size-16 btn-rounded my-auto"  :disabled="transferquantity >= (Number.parseFloat(productListingSelected.total_qty)-allProductListingQuantities)"><i class="mdi mdi-plus"></i></button>
        </div>
      </b-card>
           <b-list-group class="results-list-mini" v-if="transferOption == 0">
              <b-list-group-item class="d-flex flex-column">
                <strong class="text-primary mb-auto font-size-20">{{Number.parseFloat(listingquantity)}} <strong class="text-success font-size-20">+({{transferquantity}})</strong>
                  </strong>
                    <span class="align-middle my-auto">Already at <strong>{{selectedListing.companyname}}</strong></span>
                   <strong class="text-primary mb-auto font-size-20">{{allProductListingQuantities-Number.parseFloat(listingquantity)}}
                  </strong>
                    <span class="align-middle my-auto">Allocated to other Shops or Locations</span>
                  <strong class="text-primary mb-auto font-size-20">{{allProductListingQuantities+Number.parseFloat(transferquantity)}}</strong>
                  <span class="align-middle my-auto">Total Allocated to Shop or Location</span>
                   <strong class="text-primary mb-auto font-size-20">{{Number.parseFloat(productListingSelected.total_qty)}}</strong>
                   <span class="align-middle my-auto">Owned by your company</span>
                </b-list-group-item>
              </b-list-group>
              <b-list-group class="results-list-mini" v-if="transferOption == 1">
                <b-list-group-item v-if="productListingSelected == []">
                         Not Listed in Any Shops or Locations
                </b-list-group-item>
                <b-list-group-item class="d-flex" v-else v-for="listing in selectedProductListingInfo" :class="{'border-primary': selectedListing.id == listing.listing_id}">
                        <div class="w-50">
                         {{getListingData(listing.listing_id)[0].companyname}}
                         <p class="m-0">{{getListingData(listing.listing_id)[0].address_1}} {{getListingData(listing.listing_id)[0].address_2}} {{getListingData(listing.listing_id)[0].address_3}}</p>
                        </div>
                         <strong class="text-primary mb-auto font-size-20 ml-auto mr-3 my-auto">{{listing.quantity}}</strong>
                         <i v-if="selectedListing.id == listing.listing_id" class="bx bx-star text-primary font-size-20"></i>
                          <b-form-input type="number" class="w-25 my-auto" v-model="listing.quantity"></b-form-input>
                </b-list-group-item>
                <b-list-group-item>
                  <strong class="text-primary mb-auto font-size-20">{{allProductListingQuantities}}</strong> of
                  <strong class="text-primary mb-auto font-size-20">{{Number.parseFloat(productListingSelected.total_qty)}}</strong>
                  <span>Allocated to Listings</span>
                </b-list-group-item>
              </b-list-group>
          </div>
        <div class="col-md-12 text-center">
          <b-button variant="primary" @click="closeEditModal()">Save</b-button>
          <b-button @click="closeEditModal(), newquantity = 0">Quit</b-button>
        </div>
              </b-tab>
            </b-tabs>
      </b-modal>
    <div class="row">
    <div class="col-md-12">
          <b-list-group v-if="categoryProducts !== ''">
            <b-list-group-item v-for="product in categoryProducts.data" v-if="categoryProducts.data.length > 0">
            <div class="row">
            <div class="col-md-1">
              <img v-if="product.cover_image !== null" :src="'https://gemesys-dev.s3.eu-west-2.amazonaws.com/'+product.cover_image.src" width="85" class="float-left mr-4 img-fluid"> 
               <img v-else class="float-left mr-4 img-fluid" src="/images/product-placeholder.png">
            </div>
            <div class="col-md-5 d-flex flex-column">
              <strong class="text-muted mt-auto">{{product.name}}</strong>
              <small class="text-muted italic no-margin" v-html="product.short_description"></small>
            <strong class="text-muted mb-auto">£{{product.sales_price}} <small class="mr-3">exc. VAT</small>   £{{priceIncVat(product.sales_price, product.tax_rule.percentage)}} <small class="mr-3">inc. VAT</small> £{{product.trade_price}} <small class="mr-3">Trade Price</small></strong>
            </div>
            <div class="col-md-3 d-flex flex-column">
              <div class="mt-auto" v-if="product.product_variant_id == 0">
                <small>Quantity Stocked</small>
                  <h5 class="float-right text-primary font-weight-bold m-0">{{product.quantity}}</h5>
                </div>
              <div class="mb-auto" v-if="product.product_variant_id == 0">
                <small>Total Quantity Stocked</small>
                  <h5 class="float-right text-primary font-weight-bold m-0">{{product.total_qty}}</h5>
              </div>
            </div>
            <div class="col-md-3">
              <a id="tooltip-transfer" class="btn btn-rounded btn-outline-light  float-left font-size-16 my-4 mx-1"><i class="bx bx-transfer-alt"></i></a>
              <b-tooltip target="tooltip-transfer">Transfer Stock</b-tooltip>
              <a id="tooltip-remove" class="btn btn-rounded btn-outline-light  float-right font-size-16 my-4 mx-1"><i class="bx bx-trash"></i></a>
              <b-tooltip target="tooltip-remove">Remove from Collection</b-tooltip>
              <a id="tooltip-edit" class="btn btn-rounded btn-outline-light  float-right font-size-16 my-4 mx-1"><i class="bx bx-pencil"></i></a>
              <b-tooltip target="tooltip-edit">Edit Product</b-tooltip>
            </div>
            
          </div>
            </b-list-group-item>
          </b-list-group>
        </div>
    </div>
    </SideSlidePanel>

    <b-modal id="delete-modal" size="md" hide-footer hide-header centered>
        <b-card class="border-radius-1em border shadow-none">
          <h4>Are you sure?</h4>
          <p v-if="deleteMode == 'bulk'">You are about to delete {{selectedids.length}} products. Are you sure you want to do this? This action is not reversable</p>
           <p v-if="deleteMode == 'single'">You are about to delete this category. Are you sure you want to do this? This action is not reversable</p>

           <div class="d-flex">
            <a class="btn btn-danger" v-if="deleteMode == 'bulk'" @click="bulkDestroyResource">
              Delete
            </a>
             <a class="btn btn-danger"  v-if="deleteMode == 'single'"  @click="bulkDestroyResource">
              Delete
            </a>
            <a class="btn btn-success" @click="$bvModal.hide('delete-modal')">
              Cancel
            </a>
          </div>
        </b-card>
      </b-modal>
    <!-- end row -->
  </Layout>
</template>
