<template>
	<div>
	<div v-for="collection in categories.filter(item => item.parent_id == parent_id)" :class="parent_id == null ? '' : 'ml-4'">
				<div class="d-flex">
		 		<b-button 
                        class="custom-radio flex-grow-1 border-radius-1em inset-border py-4 custom-radio-outline position-relative custom-radio-primary my-0 py-3 d-flex "
                        value="0"
                        :class="{'active' : activeInCategories(collection, resource)}"
                        variant="outline-light"
                         @click="updateActiveCategories(collection, resource)"
                      >
                      <span v-if="parent_id !== null" class="my-auto ml-2">
                      	<i class="bx bx-minus my-auto font-size-18 text-muted">
                      	</i>
                      </span>
                      <img class="avatar d-block avatar-sm rounded-circle mr-2 my-auto border" v-if="collection.image !== null" :src="storageurl == '' ? collection.image : storageurl + '/' + collection.image">
                      <img v-else class="avatar d-block avatar-sm rounded-circle mr-2 my-auto border" src="/images/product-placeholder.png">
                      <i v-if="activeInCategories(collection, resource)" class="bx bx-check-circle font-size-24 d-block text-success my-2 py-1 position-absolute right-0 mr-4"></i> 

                      <span class="my-auto">{{collection.name}}</span>

                      
                </b-button>
                <a class="custom-radio custom-radio-outline rounded-circle mr-2 custom-radio flex-grow-0 border-radius-1em inset-border py-4 custom-radio-outline position-relative custom-radio-primary my-0 py-3 d-flex w-sm flex-grow-0 justify-content-center align-items-center" v-if="(collection.childs.length > 0) && (showCategory !== collection.id)" @click="showCategory = collection.id">
                      	<i class="bx bx-chevron-down font-size-18 text-muted"></i>
                      </a>
                      <a class="custom-radio custom-radio-outline rounded-circle mr-2 custom-radio flex-grow-0 border-radius-1em inset-border py-4 custom-radio-outline position-relative custom-radio-primary my-0 py-3 d-flex w-sm flex-grow-0 justify-content-center align-items-center" v-if="showCategory == collection.id" @click="showCategory = null">
                      	<i class="bx bx-x font-size-18 text-muted"></i>
                      </a>
                  </div>
               <CategorySelectButtons v-if="showCategory == collection.id" :categories="collection.childs" :productData="productData" :parent_id="collection.id" :resource="resource" :storageurl="storageurl"></CategorySelectButtons>
	</div>
	</div>
</template>

<script type="text/javascript">
	//import CategorySelectButtons from './category-select-buttons'; 
	import { EventBus } from '../../app';
	export default {
		name: 'CategorySelectButtons',
		data(){
			return {
				childs: [],
				showCategory: null,
			}
		},
		props: {
			parent_id: {
				type: Number,
				default: null,
			},
			categories: {
				type: Array,
				default: [],
			},
			productData: {
				type: Object,
			},
			resource: {
				type: String,
			},
			storageurl: {
				type: String,
				default: '',
			}
		},
		methods: {
			updateActiveCategories(category, resource){
				EventBus.$emit('update-category', [category, resource]);
			},
			activeInCategories(category, type){
            if(type == 'company'){
              if(this.productData.company_categories.length > 0){
                var activeCategories = this.productData.company_categories.filter(item => item.category.id == category.id);
                if(activeCategories.length > 0){
                  return true;
                }else{
                  return false;
                }
              }
            }
            if(type == 'public'){
              if(this.productData.public_marketplace_categories.length > 0){
                var activePubCategories = this.productData.public_marketplace_categories.filter(item => item.category.id == category.id);
                if(activePubCategories.length > 0){
                  return true;
                }else{
                  return false;
                }
              }
            }
            if(type == 'trade'){
              if(this.productData.trade_marketplace_categories.length > 0){
                var activeTradCategories = this.productData.trade_marketplace_categories.filter(item => item.category.id == category.id);
                if(activeTradCategories.length > 0){
                  return true;
                }else{
                  return false;
                }
              }
            }

          },
		}

	}

</script>