<script type="text/javascript">
	import simplebar from "simplebar-vue";
	import VueSlider from 'vue-slider-component'
	import DefaultContentProductCard from './DefaultContent-ProductCard';
	import FilterCompanyProductCategories from '../../../FilterCompanyProductCategories';
	import FilterProductBrands from '../../../FilterProductBrands';
	import { EventBus } from '../../../../../website-app'
	export default{
		components:{
			VueSlider,
			DefaultContentProductCard,
			FilterCompanyProductCategories,
			FilterProductBrands,
			simplebar
		},
		data(){
			return{
				priceRange: [0,250000],
				category_data: '',
				brands_data: '',
				brands: [],
				categories: [],
				productConditions: [
		        'New',
		        'Bespoke',
		        'Used',
		        'Antique',
		        'Refurbished',
		        'Custom',
      	],
      	productMetals: [
      			'Gold',
      			'Rose Gold',
      			'White Gold',
      			'Silver',
      			'Platinum',
      			'Palladium',
      	],
      	productCarats: [
      			'9',
      			'18',
      			'24',
      	],
      	productGemstones: [
      			'Aquamarine',
      			'Diamond',
      			'Emerald',
      			'Garnet',
      			'Opal',
      			'Pearl',
      			'Ruby',
      			'Sapphire',
      			'Tsavorite',
      			'Topaz',
      			'Tanzonite',
      	],
      	condition: '',
      	attributes: {
      		gemstones: [],
      		metals: [], 
      	},
      	keywordslist: [],
				loadingProducts: true,
				products: null,
				expanded: true,
				query: '',
				view: 'grid',
				layouttoggle: true,
				search: '',
				showFilters: false,
				current_page: 1,
				limit: 16,
				limits: 
				[ 
					{value: 16, text: "16"},
		        	{value: 32, text: "32"},
		        	{value: 64, text: "64"}
		        ]
			}
		},
		created(){
			this.getProducts();
			this.getProductCategories();
			this.getProductBrands();

				EventBus.$on('category-select', function(id){
					console.log(id);
				self.filterSelectedCategories(id);
		    });
			EventBus.$on('categories-cleared', function(id){
				self.categories = [];
				//self.loadCategory();
		    });
	
		},
		methods: {
			filterSelectedCategories(id){
		        
		          var exists = this.categories.findIndex(item => item == id);
		          console.log(exists);
		          if(exists == -1){
		            this.categories.push(id);
		          }else{
								this.categories.splice(exists, 1);
		          }
		          //this.createQueryString();
		      		this.getProducts();
		      },
			filterBrands(value){
		      this.brands = value;
		      //this.createQueryString();
		      this.getProducts();
		    },
			getProductBrands(){
				axios.get('/website-api/widget/brands?page='+this.current_page+'&limit=5').then(response => {
					this.brands_data = response.data;
					let self = this;
				          setTimeout(function(){
				            self.loadingProducts = false;
				          }, 500);
				}).catch(error => {
					let self = this;
				          setTimeout(function(){
				            self.loadingProducts = false;
				          }, 500);
				});
			},
			getProductCategories(){
				axios.get('/website-api/widget/categories?page='+this.current_page+'&limit=0').then(response => {
					this.category_data = response.data;
					let self = this;
				          setTimeout(function(){
				            self.loadingProducts = false;
				          }, 500);
				}).catch(error => {
					let self = this;
				          setTimeout(function(){
				            self.loadingProducts = false;
				          }, 500);
				});
			},
			getProducts(){
				this.createQueryString();
				this.loadingProducts = true;
				axios.get('/website-api/widget/products?page='+this.current_page+this.query).then(response => {
					this.products = response.data;
					let self = this;
				          setTimeout(function(){
				            self.loadingProducts = false;
				          }, 500);
				}).catch(error => {
					let self = this;
				          setTimeout(function(){
				            self.loadingProducts = false;
				          }, 500);
				});
			},
			createQueryString(){
				this.query = '&limit='+this.limit+'&search='+this.search+'&categories='+this.categories+'&brands='+this.brands+'&keywords='+this.keywordslist;
			}
		}
	}

</script>

<template>
		<b-container fluid class="py-4 mb-4 default-content products">
		<b-row>
			<b-col md="12">
				<h1 class="text-capitalize">{{$attrs.resource}}</h1>
					<p>Browse the full collection from {{$attrs.company.name}}.</p>
			</b-col>
			<b-col class="col-md-12">
						<div class="row mb-3">
        
          <div class="col-lg-3 col-sm-6 d-flex">
          <form class="my-auto">
            <small class="mx-auto my-2 text-left d-block" v-if="products !== null">Results {{products.from}} - {{products.to}} of {{products.total}}</small>
          </form>
        </div>  
        <div class="col-lg-6 col-sm-6 d-flex">
            <div class="mt-sm-0 form-inline w-100" >
              <div class="app-search search-box mr-2 ml-auto">
                <div class="position-relative">
                  <input
                    type="text"
                    class="form-control w-100 border"
                    placeholder="Search name, reference or description..."
                    v-model="search"
                    @keyup.enter="getProducts()"
                  />
                  <span class="bx bx-search-alt search-icon"></span>
                </div>
              </div>
            </div>
          </div>

         
			
			<b-col md="3" class="d-flex">
				<ul class="nav nav-pills product-view-nav my-auto ml-auto d-flex" v-if="layouttoggle == true">
					<li class="nav-item  ml-auto mr-2">
                  <a class="nav-link" :class="{'active': showFilters == true }" href="javascript: void(0);" @click="showFilters = !showFilters, expanded = !expanded">
                    <i class="bx bx-filter-alt"></i>
                  </a>
                </li>
          		<!--<li class="nav-item px-1">
                  <a class="nav-link" :class="{'active': view == 'grid' }" href="javascript: void(0);" @click="view = 'grid'">
                    <i class="bx bx-grid-alt"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': view == 'list' }" href="javascript: void(0);" @click="view = 'list'">
                    <i class="bx bx-list-ul"></i>
                  </a>
                </li>-->
               </ul>
          	 <b-form-select class="form-control my-auto w-25 pl-1" v-model="limit" :options="limits" @input="getProducts"></b-form-select>
          </b-col>
		</div>
	</b-col>
			<b-col md="12" v-if="products !== null">
				<b-overlay :show="loadingProducts">
				<b-row>
					<b-col md="3" v-for="(product, index) in products.data">
						<DefaultContentProductCard :product="product" :company="$attrs.company" :index="index"  :exchange_rates="$attrs.exchange_rates"  :currencies="$attrs.currencies" :selected_currency="$attrs.selected_currency" :selected_currency_symbol="$attrs.selected_currency_symbol"></DefaultContentProductCard>
					</b-col>
					<b-col md="12" v-if="products.data.length == 0">
						<b-card class="border-radius-1em shadow-none border py-4 text-center">
							<img src="/images/Management.png" class="img-fluid" style="max-width: 500px">
							<h1>We're Working On It!</h1>
							<p>There's no products here yet, but we're adding some - just watch this space!</p>
						</b-card>
					</b-col>
				</b-row>
				</b-overlay>
			</b-col>
			<b-col v-else>
				<b-card class="border-radius-1em shadow-none border py-4 text-center">
					<img src="/images/Management.png" class="img-fluid" style="max-width: 500px">
					<h1>We're Working On It!</h1>
					<p>There's no products here yet, but we're adding some - just watch this space!</p>
				</b-card>
			</b-col>
			<b-col md="12">
				<b-pagination v-if="products !== null" v-model="current_page" :total-rows="products.total" :per-page="products.per_page" align="right" @input="getProducts()"></b-pagination>
			</b-col>
		</b-row>
		<transition name="fade-left">
		<div v-if="showFilters == true" style="" class="filter-sidebar position-fixed w-100 bg-white top-0 left-0 bottom-0 shadow-lg">
			<simplebar style="overflow-x: hidden; height: 100%;">
			<b-row>
				<b-col md="12" class="px-4 pt-3 pb-3 d-flex position-sticky top-0 border-bottom bg-white" style="z-index: 999999;">
					<a @click="showFilters = !showFilters" class="btn btn-outline-light btn-rounded ml-auto my-auto mr-3">
						<i class="mdi mdi-close font-size-20"></i>
					</a>
				</b-col>
				<b-col md="12" class="p-4">
					<div class="px-3">
					<div>
               <div class="my-3 pt-3">
              <h5  v-b-toggle.collapse-price class="pb-3 border-bottom">Price <i class="mdi mdi-plus ml-auto hide-on-collapse float-right"></i><i class="mdi mdi-minus ml-auto show-on-collapse float-right"></i></h5>
              <b-collapse id="collapse-price" class="mt-2 w-100">

              <vue-slider v-model="priceRange" :min="0" :max="100 + priceRange[1]"   ></vue-slider>
              <b-row>
              	<b-col>
		              <b-form-group label="Min Price" class="w-50 d-inline float-left mt-2 mb-3 px-2">
		              <b-form-input
		                type="number"
		                v-model="priceRange[0]"
		                @input="getProducts()"></b-form-input>
		              </b-form-group>
            		</b-col>
            		<b-col>
		              <b-form-group label="Max Price" class="w-50 d-inline float-left mt-2 mb-3 px-2">
		                 <b-form-input
		                 label="Max Price"
		                 type="number"
		                 v-model="priceRange[1]"
		                 @input="getProducts()"></b-form-input>
		              </b-form-group>
            		</b-col>
            	</b-row>
            	</b-collapse>
            </div>
                <h5 v-b-toggle.collapse-categories class="pb-3 border-bottom">Categories  <i class="mdi mdi-plus ml-auto hide-on-collapse float-right"></i><i class="mdi mdi-minus ml-auto show-on-collapse float-right"></i></h5>
                <!--<FilterListTree></FilterListTree>-->
               
                
                	<b-collapse id="collapse-categories" class="mt-2 pt-3">
                		<b-overlay :show="loadingFilters">
                  <FilterCompanyProductCategories  :selectedids="categories" :category_data="category_data"  :marketplace_categories="'company'" :company="$attrs.company" @category-select="filterSelectedCategories" @categories-clear="categories = []" ></FilterCompanyProductCategories>
                  </b-overlay>
                </b-collapse>
              
                <h5 v-b-toggle.collapse-brands  class="py-3 border-bottom">Brands <i class="mdi mdi-plus ml-auto hide-on-collapse float-right"></i><i class="mdi mdi-minus ml-auto show-on-collapse float-right"></i></h5>
                 <b-collapse id="collapse-brands" class="mt-2">
                 	<b-overlay :show="loadingBrands">
               <FilterProductBrands v-on:brands-filtered="filterBrands" :brands_data="brands_data"  :company="$attrs.company" :marketplace_brands="'false'"  ></FilterProductBrands>
               	</b-overlay>
             </b-collapse>
               <h5 v-b-toggle.collapse-gemstones  class="py-3 border-bottom">Gemstone <i class="mdi mdi-plus ml-auto hide-on-collapse float-right"></i><i class="mdi mdi-minus ml-auto show-on-collapse float-right"></i></h5>
               <b-collapse id="collapse-gemstones" class="mt-2">
                <b-form-group>
                  <b-form-checkbox-group stacked v-model="keywordslist" @change="getProducts()" :options="productGemstones"></b-form-checkbox-group>
                </b-form-group>
              </b-collapse>
                <h5 v-b-toggle.collapse-metal class="py-3 border-bottom">Metal <i class="mdi mdi-plus ml-auto hide-on-collapse float-right"></i><i class="mdi mdi-minus ml-auto show-on-collapse float-right"></i></h5>
                <b-collapse id="collapse-metal" class="mt-2">
                <b-form-group>
                  <b-form-checkbox-group stacked v-model="keywordslist"  @change="getProducts()" :options="productMetals"></b-form-checkbox-group>
                </b-form-group>
              </b-collapse>
                <h5 v-b-toggle.collapse-condition class="py-3 border-bottom">Condition <i class="mdi mdi-plus ml-auto hide-on-collapse float-right"></i><i class="mdi mdi-minus ml-auto show-on-collapse float-right"></i></h5>
                <b-collapse id="collapse-condition" class="mt-2">
                <b-form-group>
                  <b-form-checkbox-group stacked v-model="condition"  @change="getProducts()" :options="productConditions"></b-form-checkbox-group>
                </b-form-group>
              </b-collapse>
               
            </div>
          </div>
				</b-col>
				<b-col md="12" class="d-flex">
					<a class="btn btn-outline-light mx-4 w-100 btn-rounded" @click="categories = [], brands = [], condition = '', keywordslist = []">
						Reset Filters <i class="bx bx-filter-alt"></i>

					</a>

				</b-col>
			</b-row>
		</simplebar>
		</div>
	</transition>
	</b-container>
</template>