<script>
//import Vertical from "./vertical";
import PosWrapper from "./poswrapper";



export default {
  components: { PosWrapper},
  data() {
    return {

    };
  },
  computed: {
    layoutType() {
        return this.$root.layout?.type;
    }
  },
  methods: {},
};
</script>

<template>
  <div>
    <PosWrapper :user.sync="$attrs.user" >
      <slot />
    </PosWrapper>
        
  </div>
</template>
