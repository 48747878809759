<script>
import simplebar from "simplebar-vue";
import i18n from "../i18n";
import Login from "../public-views/account/onpageLogin";
import Register from "../public-views/account/onpageRegistration";
import { EventBus } from '../public-app'
import PublicSearchWidget from '../components/widgets/ecommerce/public/PublicSearchWidget';

export default {
  components: {
    simplebar,
    Login,
    Register,
    PublicSearchWidget
  },
  data() {
    return {
      currencykey: process.env.MIX_CURRENCY_CONVERTER,
      conversion_rates: '',
      csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
      languages: [
        {
          flag: "/images/flags/uk.jpg",
          language: "en",
          title: "English",
        },
        {
          flag: "/images/flags/french.jpg",
          language: "fr",
          title: "French",
        },
        {
          flag: "/images/flags/spain.jpg",
          language: "es",
          title: "Spanish",
        },
        {
          flag: "/images/flags/chaina.png",
          language: "zh",
          title: "Chinese",
        },
        {
          flag: "/images/flags/arabic.png",
          language: "ar",
          title: "Arabic",
        },
      ],
      cart: {
        products: [],
        uid: '',
        id: '',
      },
      register: false,
      storageurl: '.s3.eu-west-2.amazonaws.com',
      currency: '',
      currencyicon: 'bx bx-pound',
      currencytext: 'GBP',
      currencycode: 'GBP',
      cartLoading: false,
      initiateCheckout: false,
      cartPanelData: [],
      cartPanel: false,
      showCart: false, 
      showSearch: false,
      lan: i18n.locale,
      text: null,
      flag: null,
      value: null,
      showSellerBar: true,
    };
  },
  props: {
    user: {
      type: Object
    },
    seller_sessions: {
      type: Array,
    default: [],
    },
    currencies: {
      type: Array,
      default: [],
    }
  },
  computed: {
    isEmailVerified(){
      if(this.$route.query['email_verified'] == "true"){
        this.removeWindowQueryString();
        return true;
    }else{
      return false;
    }
  },
},
  created() {

    var session_cart = window.sessionStorage.getItem("publiccart");
    session_cart = JSON.parse(session_cart);
    if(session_cart == null){
      this.checkAndGetCart();
    }else{
      this.cart = session_cart;
    }
    var sellerbar = window.sessionStorage.getItem("showSellerBar");
    sellerbar = JSON.parse(sellerbar);
    if(sellerbar == null){
      this.showSellerBar = true;
    }else{
      this.showSellerBar = sellerbar;
    }
    var currency = window.localStorage.getItem("currency");
    if(currency){
      var parsecurrency = JSON.parse(currency);
      
      this.currencycode = parsecurrency["currency_code"];
      this.currencytext = parsecurrency["currency_code"];
      if(parsecurrency["currency_code"] == 'USD'){
        this.currencyicon = 'bx bx-dollar';
      }
      if(parsecurrency["currency_code"] == 'EUR'){
        this.currencyicon = 'bx bx-euro';
      }
    }
  },
  mounted() {
     if(this.$attrs.regError !== '[]'){
      this.$bvModal.show('register');
    }
    this.value = this.languages.find((x) => x.language === i18n.locale);
   // this.text = this.value.title;
    //this.flag = this.value.flag;
    let self = this;
    EventBus.$on('add-to-cart', function(product, qty, variants, combination){
      console.log('Cart Event Triggered');
       self.addToCart(product, qty, variants, combination);
    });
    EventBus.$on('update-quantity', function(product, qty, variant, combination, reloadCart){
      console.log('Cart Event Triggered');
       self.updateQuantity(product, qty, variant, combination, reloadCart);
    });
     EventBus.$on('cart-cleared', function(result){
      self.cart = { products: [], uid: '', id: ''};
    });
      EventBus.$on('update-cart', function(cartdata){
       self.cart = cartdata;
    });
      EventBus.$on('register', function(){
        self.$bvModal.show('register');
      });
  },
  methods: {
    convertCurrency(from, to, symbol){
        axios.get('https://api.freecurrencyapi.com/v1/latest?apikey='+this.currencykey+'&currencies=EUR,USD,GBP&base_currency='+from+'').then(response => {
          this.conversion_rates = response.data;
          EventBus.$emit('currency-updated', symbol, from, response.data);
        }).catch(error => {

        });
    },
    addTax(product, price){
            var tax_rule = product.tax_rule;
            console.log(tax_rule);
          if((this.user !== null && this.user !== '') && (this.user.default_delivery_address !== '' && this.user.default_delivery_address !== null)){
            var country_id = this.user.default_delivery_address.country_id;
          }else{
            var country_id = product.company.country_id;
          }
          if(tax_rule !== null && tax_rule.country_rules.length > 0){
            var selected_rule = null;
            var selected_ruleIndex = -1;
            for(var ti = 0; ti < tax_rule.country_rules.length; ti++){
              selected_ruleIndex = tax_rule.country_rules[ti].countries.findIndex(item => item == country_id);
              if(selected_ruleIndex !== -1){
                selected_rule = tax_rule.country_rules[ti];
                ti = tax_rule.country_rules.length;
              }
            }
            console.log(selected_rule);
            if(tax_rule.type == 1){
                if(selected_rule !== null){
                  var percentage = (parseFloat(selected_rule.value) / 100) + 1;
                  return price * percentage;
                }else{
                  return price * 1.2;
                }
              }else{
                if(selected_rule !== null){
                  return price + selected_rule.value;
                }else{
                  return price * 1.2;
                }
              }
            }else if(tax_rule !== null && tax_rule.country_rules.length > 0){
              var percentage = (parseFloat(tax_rule.percentage) / 100) + 1;
              return price * percentage;
            }else {
              return price * 1.2;
            }
          },
    updateCurrency(symbol, old_currency, new_currency){
      //EventBus.$emit('currency-updated', old_currency, new_currency);
      this.convertCurrency(new_currency, null, symbol);
    },
    destroyCart(uid){
            axios.post('/customer-api/cart/destroy', {cartuid: uid }, {
              headers: {
                'Authorization' : 'Bearer '+this.user.api_token
              }
            }).then(response => {
              if(response.data == true){
                this.cart =  { products: [], uid: '', id: ''};
                window.sessionStorage.removeItem("publiccart");
              }
            }).catch(error => {
              
            });
    },
    checkAndGetCart(){
      axios.get('/customer-api/cart/read?cart_uid='+this.cart.uid, {headers: {
                  'Authorization': 'Bearer ' + this.user.api_token
              }}).then(response => {
        console.log(response);
          if(response.data !== 0){
            this.cart = response.data;
            window.sessionStorage.setItem("publiccart", JSON.stringify(response.data));
          }else{
            window.sessionStorage.setItem("publiccart", JSON.stringify(this.cart))
          }
        
      }).catch(error => {
        this.error_reponse = error.response.data.errors;
      });
    },
    cartTotal(cart){
          var score = 0;
          if(cart.products.length > 1){
            for(var i = 0; i < cart.products.length; i++){
              var itemscore = ((parseFloat(this.addTax(cart.products[i].product, cart.products[i].price_exc)) * parseFloat(cart.products[i].quantity))) * (1 / this.$attrs.exchange_rates[cart.products[i].company.trading_currency_code]);
              score = score + itemscore;
            }
          }else{
            score = (this.addTax(cart.products[0].product, cart.products[0].price_exc) * (1 / this.$attrs.exchange_rates[cart.products[0].company.trading_currency_code])) * cart.products[0].quantity;
          }
          return (score).toFixed(2);
        },
    addToCart(productdata, qty, variantsdata, combinationdata){
      this.cartLoading = true;
      if(this.cart.uid == ''){
        this.cart.products.push({product: productdata, company: productdata.company});
        this.createCart(productdata, qty, variantsdata, combinationdata);
      }else{
        axios.post('/customer-api/cart/'+this.cart.uid+'/add-product', {product: productdata, quantity: qty, variants: variantsdata, combination: combinationdata}, {headers: {
                  'Authorization': 'Bearer ' + this.user.api_token
              }}).then(response => {
        var carindex = this.cart.products.findIndex(item => item.product_id == productdata.id);
            if(carindex !== -1){
              this.cart.products[carindex] = response.data;
              this.cart.products[carindex]["product"] = productdata;
              this.cart.products[carindex]["company"] = productdata.company;
              window.sessionStorage.setItem("publiccart", JSON.stringify(this.cart));
              window.localStorage.setItem("publiccart", JSON.stringify(this.cart));
              this.productAdded(productdata);
            }else if(carindex == -1){
              this.cart["products"].push(response.data);
              this.cart["products"][(this.cart.products.length - 1)]["product"] = productdata;
              this.cart["products"][(this.cart.products.length - 1)]["company"] = productdata.company;
              window.sessionStorage.setItem("publiccart", JSON.stringify(this.cart));
              window.localStorage.setItem("publiccart", JSON.stringify(this.cart));
              this.productAdded(productdata);
            }
            
      }).catch(error => {
        this.error_reponse = error.response.data.errors;
      });
      } 
    },
    removeProduct(id, index){
      this.cartLoading = true;
      axios.post('/customer-api/cart/product/'+id+'/delete', {}, {headers: {
                  'Authorization': 'Bearer ' + this.user.api_token
              }}).then(response => {
          this.cart.products.splice(index, 1);
          window.sessionStorage.setItem("publiccart", JSON.stringify(this.cart));
          window.localStorage.setItem("publiccart", JSON.stringify(this.cart));
           let self = this
            setTimeout(function() {
              self.cartLoading = false;
            }, 400);
        }).catch(error => {
          this.error_reponse = error.response.data.errors;
           let self = this
            setTimeout(function() {
              self.cartLoading = false;
            }, 400);
        });
    },
    productAdded(product){
      //this.cartPanelData = product;
      //this.cartPanel = true;
      this.cartLoading = false;
      this.$refs.cart.show(true);
    },
    updateQuantity(product, qty, variant, combination, reloadCart){
              axios.post('/customer-api/cart/product/'+product.id+'/update-quantity', {quantity: qty},  {headers: {
                  'Authorization': 'Bearer ' + this.user.api_token
              }}).then(response => {

                  if(reloadCart == true){
                   EventBus.$emit('cart-updated');
                  }
                  return this.checkAndGetCart();
              }).catch(error => {

              });
    },
    createCart(productdata, qty, variantsdata, combinationdata){
      axios.post('/customer-api/cart/create', {product: productdata, quantity: qty, variants: variantsdata, combination: combinationdata}, {headers: {
                  'Authorization': 'Bearer ' + this.user.api_token
              }}).then(response => {
        this.cart = response.data[0];
        this.cart["products"] = [];
        this.cart.products.push(response.data[1]);
        this.cart.products[0]["product"] = productdata;
        this.cart.products[0]["company"] = productdata.company;
        window.sessionStorage.setItem("publiccart", JSON.stringify(this.cart));
        window.localStorage.setItem("publiccart", JSON.stringify(this.cart));
        this.productAdded(productdata);
      }).catch(error => {

      });
    },
    toggleRightSidebar() {
      this.$parent.toggleRightSidebar();
    },
    toggleMenu() {
      let element = document.getElementById("topnav-menu-content");
      element.classList.toggle("show");
    },
    initFullScreen() {
      document.body.classList.toggle("fullscreen-enable");
      if (
        !document.fullscreenElement &&
        /* alternative standard method */ !document.mozFullScreenElement &&
        !document.webkitFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(
            Element.ALLOW_KEYBOARD_INPUT
          );
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    },
    setCurrency(code, title, currencyicon, symbol) {
      this.updateCurrency(symbol, this.currencycode, code);
      this.currencycode = code;
      this.currencytext = code;
      this.currencyicon = currencyicon;
      //i18n.locale = locale;

      localStorage.setItem("currency", locale);
    },
    setLanguage(locale, country, flag) {
      this.lan = locale;
      this.text = country;
      this.flag = flag;
      i18n.locale = locale;
      localStorage.setItem("locale", locale);
    },
    goToCheckout(){
      localStorage.setItem("cartparams", JSON.stringify(this.cart));
      this.$router.push({ name: 'Order', path: '/order', params:  { cart: this.cart} });
      if(this.$route.path == ('order' || '/order')){
        this.$router.go(0);
      }
    },
    removeWindowQueryString(){
      console.log(window.location.href);
      let self = this;
              setTimeout(function(){
               self.$router.replace({ query: ''});
              }, 5000);
    },
    resendVerificationEmail(){
      axios.get('/customer-api/email/verification-notification/'+this.user.id, {
        headers: {
          'Authorization' : 'Bearer '+this.user.api_token
        }
      }).then(response => {

      }).catch(error => {

      });
    }
  },
};
</script>

<template>
  <header id="page-topbar" class="shadow-none">
    <transition name="fade-top">
    <div v-if="user !== ''" class="position-absolute bg-primary w-100 justify-content-center align-items-center d-flex z-index-3" :class="{'bg-success' : isEmailVerified == true}">
      <div v-if="user.email_verified_at == null">
        <p class="my-3 text-white">Your Email Address Has Not Been Verified Yet. <a class="btn btn-dark btn-rounded ml-3"  @click="resendVerificationEmail()">Resend Email</a></p>
      </div>
     
    </div>
  </transition>

    <div v-if="user !== ''" class="position-absolute bg-primary w-100 justify-content-center align-items-center d-flex z-index-3 bg-success">
      <transition name="fade-top">
       <div v-if="(user.email_verified_at !== null) && (isEmailVerified == true)">
        <p class="my-3 text-white">Your Email Address Has Been Verified<a class="btn btn-dark btn-rounded ml-3"  @click="removeWindowQueryString()">Close Message</a></p>
      </div>
      </transition>
    </div>
    <div class="navbar-header">
      <div class="d-flex w-50">
        <!-- LOGO -->
        <div class="navbar-brand-box pr-0">
          <router-link to="/" class="logo logo-dark">
            <span class="logo-sm">
              <!--<div class="cube-wrapper float-left"><div class="cube-folding"><span class="leaf1"></span><span class="leaf2"></span><span class="leaf3"></span><span class="leaf4"></span></div></div>-->
              <img src="/images/gltrbox-logo.png" width="100px">
            </span>
            <span class="logo-lg">
              <img src="/images/gltrbox-logo.png" width="100px">
              <!--<div class="cube-wrapper float-left"><div class="cube-folding"><span class="leaf1"></span><span class="leaf2"></span><span class="leaf3"></span><span class="leaf4"></span></div></div>
              <div class="d-flex logo-text-holder"><span class="logo-text text-dark"><small class="extra-small d-block"></small> Jewellery <small class="extra-small">QUARTER</small></span></div>-->
            </span>
          </router-link>

          <router-link to="/" class="logo logo-light">
            <span class="logo-sm">
              <div class="cube-wrapper float-left"><div class="cube-folding"><span class="leaf1"></span><span class="leaf2"></span><span class="leaf3"></span><span class="leaf4"></span></div></div>
            </span>
            <span class="logo-lg">
              <img src="/images/gltrbox-logo.png" width="100px">
              <!--<div class="cube-wrapper float-left"><div class="cube-folding"><span class="leaf1"></span><span class="leaf2"></span><span class="leaf3"></span><span class="leaf4"></span></div></div>
              <div class="d-flex logo-text-holder"><span class="logo-text text-white"><small class="extra-small d-block"></small> Jewellery <small class="extra-small">QUARTER</small></span></div>-->
            </span>
          </router-link>
        </div>

       

        <!-- App Search-->
       

        <!--<b-dropdown
          variant="black"
          class="dropdown-mega d-none d-lg-block ml-2 mr-auto"
          toggle-class="header-item"
          menu-class="dropdown-megamenu dropdown-menu-end"
        >
          <template v-slot:button-content>
            {{ $t("navbar.dropdown.megamenu.text") }}
            <i class="mdi mdi-chevron-down"></i>
          </template>

          <div class="row">
            <div class="col-sm-8">
              <div class="row">
                <div class="col-md-4">
                  <h5 class="font-size-14 mt-0">
                    {{ $t("navbar.dropdown.megamenu.uicontent.title") }}
                  </h5>
                  <ul class="list-unstyled megamenu-list">
                    <li>
                      <a href="javascript:void(0);">{{
                        $t("navbar.dropdown.megamenu.uicontent.list.lightbox")
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        $t(
                          "navbar.dropdown.megamenu.uicontent.list.rangeslider"
                        )
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        $t("navbar.dropdown.megamenu.uicontent.list.sweetalert")
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        $t("navbar.dropdown.megamenu.uicontent.list.rating")
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        $t("navbar.dropdown.megamenu.uicontent.list.forms")
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        $t("navbar.dropdown.megamenu.uicontent.list.tables")
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        $t("navbar.dropdown.megamenu.uicontent.list.charts")
                      }}</a>
                    </li>
                  </ul>
                </div>

                <div class="col-md-4">
                  <h5 class="font-size-14 mt-0">
                    {{ $t("navbar.dropdown.megamenu.application.title") }}
                  </h5>
                  <ul class="list-unstyled megamenu-list">
                    <li>
                      <a href="javascript:void(0);">{{
                        $t(
                          "navbar.dropdown.megamenu.application.list.ecommerce"
                        )
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        $t("navbar.dropdown.megamenu.application.list.calendar")
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        $t("navbar.dropdown.megamenu.application.list.email")
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        $t("navbar.dropdown.megamenu.application.list.projects")
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        $t("navbar.dropdown.megamenu.application.list.tasks")
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        $t("navbar.dropdown.megamenu.application.list.contacts")
                      }}</a>
                    </li>
                  </ul>
                </div>

                <div class="col-md-4">
                  <h5 class="font-size-14 mt-0">
                    {{ $t("navbar.dropdown.megamenu.extrapages.title") }}
                  </h5>
                  <ul class="list-unstyled megamenu-list">
                    <li>
                      <a href="javascript:void(0);">{{
                        $t(
                          "navbar.dropdown.megamenu.extrapages.list.lightsidebar"
                        )
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        $t(
                          "navbar.dropdown.megamenu.extrapages.list.compactsidebar"
                        )
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        $t(
                          "navbar.dropdown.megamenu.extrapages.list.horizontallayout"
                        )
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        $t(
                          "navbar.dropdown.megamenu.extrapages.list.maintenance"
                        )
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        $t(
                          "navbar.dropdown.megamenu.extrapages.list.comingsoon"
                        )
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        $t("navbar.dropdown.megamenu.extrapages.list.timeline")
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        $t("navbar.dropdown.megamenu.extrapages.list.faqs")
                      }}</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="row">
                <div class="col-sm-6">
                  <h5 class="font-size-14 mt-0">
                    {{ $t("navbar.dropdown.megamenu.uicontent.title") }}
                  </h5>
                  <ul class="list-unstyled megamenu-list">
                    <li>
                      <a href="javascript:void(0);">{{
                        $t("navbar.dropdown.megamenu.uicontent.list.lightbox")
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        $t(
                          "navbar.dropdown.megamenu.uicontent.list.rangeslider"
                        )
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        $t("navbar.dropdown.megamenu.uicontent.list.sweetalert")
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        $t("navbar.dropdown.megamenu.uicontent.list.rating")
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        $t("navbar.dropdown.megamenu.uicontent.list.forms")
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        $t("navbar.dropdown.megamenu.uicontent.list.tables")
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        $t("navbar.dropdown.megamenu.uicontent.list.charts")
                      }}</a>
                    </li>
                  </ul>
                </div>

                <div class="col-sm-5">
                  <div>
                    <img
                      src="/images/megamenu-img.png"
                      alt
                      class="img-fluid mx-auto d-block"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-dropdown>-->
        <transition name="fade-right">
         <!--<form class="app-search d-none d-lg-block ml-auto w-50" v-if="showSearch" >
          <div class="position-relative">
            <input
              type="text"
              class="form-control"
              :placeholder="$t('navbar.search.text')"
            />
            <span class="bx bx-search-alt"></span>
          </div>
        </form>-->
        <PublicSearchWidget v-if="showSearch"></PublicSearchWidget>
        </transition>
      </div>

      <div class="d-flex ml-auto">
        <b-dropdown
          class="d-inline-block d-lg-none ml-2"
          variant="black"
          menu-class="dropdown-menu-lg p-0 dropdown-menu-end"
          toggle-class="header-item noti-icon"
          right
        >
          <template v-slot:button-content>
            <i class="mdi mdi-magnify"></i>
          </template>

          <!--<form class="p-3">
            <div class="form-group m-0">
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Search ..."
                  aria-label="Recipient's username"
                />
                <div class="input-group-append">
                  <button class="btn btn-primary" type="submit">
                    <i class="mdi mdi-magnify"></i>
                  </button>
                </div>
              </div>
            </div>
          </form>-->
          <PublicSearchWidget></PublicSearchWidget>
        </b-dropdown>

        <!--<b-dropdown variant="white" right toggle-class="header-item">
          <template v-slot:button-content>
            <img class :src="flag" alt="Header Language" height="16" />
            {{ text }}
          </template>
          <b-dropdown-item
            class="notify-item"
            v-for="(entry, i) in languages"
            :key="`Lang${i}`"
            :value="entry"
            @click="setLanguage(entry.language, entry.title, entry.flag)"
            :class="{ active: lan === entry.language }"
          >
            <img
              :src="`${entry.flag}`"
              alt="user-image"
              class="me-1"
              height="12"
            />
            <span class="align-middle">{{ entry.title }}</span>
          </b-dropdown-item>
        </b-dropdown>-->



        <!--<div class="dropdown d-none d-lg-inline-block ml-1">
          <button
            type="button"
            class="btn header-item noti-icon"
            @click="initFullScreen"
          >
            <i class="bx bx-fullscreen"></i>
          </button>
        </div>-->
        <div class="dropdown d-none d-lg-inline-block ml-1">
          <button
            type="button"
            class="btn header-item noti-icon"
            @click="showSearch = !showSearch"
          >
            <i class="bx bx-search"></i>
          </button>
        </div>
        <b-dropdown
          variant="white" right toggle-class="header-item noti-icon"
        >
          <template v-slot:button-content>
            <i :class="`${currencyicon}`" :alt="'Pay in ' + currency" ></i>
          </template>

          <b-dropdown-item
            class="notify-item"
            v-for="(entry, i) in currencies"
            :key="`Lang${i}`"
            :value="entry"
            @click="setCurrency(entry.code, entry.name, entry.icon, entry.symbol)"
            :class=" {'active' : lan === entry.language}"
          >
            <i :class="`${entry.icon}`" :alt="'Pay in ' + entry.name" class="me-1" ></i>
            <span class="align-middle">{{ entry.name }}</span>
          </b-dropdown-item>
        </b-dropdown>
        <b-dropdown
          right
          menu-class="dropdown-menu-lg p-0 dropdown-menu-end cart-dropdown border-radius-1em"
          toggle-class="header-item noti-icon"
          variant="black"
          ref="cart"
        >
          <template v-slot:button-content>
            <i class="bx bx-shopping-bag"></i>
            <span
              class="badge bg-danger rounded-pill"
            >{{cart.products.length}}</span>
          </template>

          <div class="p-3">
            <div class="row align-items-center">
              <div class="col">
                <h6 class="m-0">Shopping Cart</h6>
              </div>
              <div class="col-auto" >
                <a v-if="cart.products.length > 0" v-b-modal.delete-cart class="small">Delete Cart</a>
              </div>
              
            </div>
          </div>
          <simplebar style="max-height: 530px;">
          <b-list-group v-if="cartLoading == false">
            <b-list-group-item  v-for="(productitem, index) in cart.products" href="javascript: void(0);" class="text-reset notification-item px-0 py-3 m-0">
              <div class="media">
                <div class="avatar-sm me-3">
                  <span v-if="productitem.product.cover_image == null" class="avatar-title bg-primary rounded-circle font-size-16" >
                    <i class="bx bx-cart"></i>
                  </span>
                  <img v-else-if="'thumb' in productitem.product.cover_image" :src="'https://'+productitem.company.nickname +storageurl + productitem.product.cover_image.thumb.replaceAll('//', '/')+''" class="avatar-title rounded-circle">
                  <img v-else-if="'gallery' in productitem.product.cover_image" :src="'https://'+productitem.company.nickname +storageurl + productitem.product.cover_image.gallery.replaceAll('//', '/')+''" class="avatar-title rounded-circle">
                  <img v-else-if="'src' in productitem.product.cover_image" :src="'https://'+productitem.company.nickname +storageurl + productitem.product.cover_image.src.replaceAll('//', '/')+''" class="avatar-title rounded-circle">
                  
                </div>
                <div class="media-body my-auto flex-grow-10">
                  <h6 class="mt-0 mb-1">{{productitem.product.name}}</h6>
                  <!--<span v-if="'combinations' in productitem">
                    <span v-if="productitem.combinations.length > 0">
                    with Customisations
                    </span>
                  </span>
                  <span v-if="'variants' in productitem">
                    <span v-if="productitem.variants.length > 0">
                    with Customisations
                    </span>

                  </span>-->
                </div>
                <div class="my-auto flex-grow-2 text-right d-flex flex-column">
                  <a href="javascript:;" class="text-center" @click="productitem.quantity++, updateQuantity(productitem, productitem.quantity, null, null, true)"><i class="bx bx-chevron-up mb-auto mx-auto"></i></a>
                  <b-form-input type="number"  v-model="productitem.quantity" class="visible mx-auto my-auto d-none" style="width: 25%;" >
                  </b-form-input>
                  <span class="mx-auto my-auto">{{productitem.quantity}}</span>
                  <a v-if="productitem.quantity > 1" href="javascript:;" class="text-center" @click="productitem.quantity--, updateQuantity(productitem, productitem.quantity, null, null, true)"><i class="bx bx-chevron-down mt-auto mx-auto"></i></a>
                  <a v-else href="javascript:;" class="text-center" @click="removeProduct(productitem.id, index)"><i class="bx bx-chevron-down mt-auto mx-auto"></i></a>
                </div>
                <div class="my-auto flex-grow-2 text-center d-flex flex-column">
                  <p class="mb-0"><strong class="text-primary mr-2">{{$attrs.selected_currency_symbol}}</strong>{{(addTax(productitem.product, productitem.price_exc) * (1 / $attrs.exchange_rates[productitem.company.trading_currency_code])).toFixed(2)}}</p>
                  <small>Item Price</small>
                </div>
                <div class="my-auto flex-grow-2 text-center d-flex flex-column">
                  <p class="mb-0"><strong class="text-primary mr-2">{{$attrs.selected_currency_symbol}}</strong>{{((addTax(productitem.product, productitem.price_exc) * productitem.quantity) * (1 / $attrs.exchange_rates[productitem.company.trading_currency_code])).toFixed(2)}}</p>
                  <small>Total Price</small>
                </div>
                <div class="my-auto flex-grow-1 text-center">
                 <a class="btn btn-outline-light btn-rounded" @click="removeProduct(productitem.id, index)"><i class="bx bx-trash-alt"></i></a>
                </div>
              </div>
            </b-list-group-item>
            <b-list-group-item v-if="cart.products.length == 0">
              <div class="p-5 text-center border">
                <i class="bx bx-shopping-bag font-size-42 text-secondary mb-2"></i>
                <h6>No Products in Cart</h6>
              </div>
            </b-list-group-item>
          </b-list-group>
            

          </simplebar>
          <b-overlay
            :show="cartLoading">
              <div class="p-4 border-top d-flex bg-light" v-if="(cart.products.length !== 0) && (cartLoading == false)">
                <strong class="mr-auto flex-grow-2"> Total: </strong>
                  <span class="mx-auto flex-grow-1 text-center"><strong class="text-primary mr-2">{{$attrs.selected_currency_symbol}}</strong> {{cartTotal(cart) }}</span>
              </div>
          </b-overlay>
         <div class="p-2 border-top d-grid" v-if="cart.products.length !== 0">
            <a class="btn btn-sm btn-link font-size-14 text-center btn-primary text-white btn-rounded" @click="goToCheckout()" >
              <i class=" me-1"></i>
              <span key="t-view-more"> Checkout Now </span>
            </a>
          </div>
        </b-dropdown>
        <b-dropdown
          id="account-dropdown"
          right
          variant="black"
          toggle-class="header-item"
          menu-class="dropdown-menu-end"
          :menu-class="{'border-radius-1em p-0 login-dropdown dropdown-menu-end' : !user}"
        >
          <template v-slot:button-content>
            <div v-if="user">
            <img
              v-if="user.avatar"
              class="rounded-circle header-profile-user"
              :src="user.avatar"
              alt="Header Avatar"
            />
            <img v-else
              class="rounded-circle header-profile-user"
              src="/images/product-placeholder.png"
            > 
            <span class="d-none d-xl-inline-block ml-1">{{user.name}} {{user.lastname}}</span>
            <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
            </div>
            <div v-else>
              <img
              class="rounded-circle header-profile-user"
              src="/images/product-placeholder.png"
              alt="Sign in to Gemesys"
            />
            <span class="d-none d-xl-inline-block ml-1">
              My Account
            </span>
            <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
            </div>

            
          </template>
          <!-- item-->
           <div v-if="user">
          <b-dropdown-item to="/my-account">
            <i class="bx bx-user font-size-16 align-middle me-1"></i>
            My Account
          </b-dropdown-item>
             <b-dropdown-item to="/my-account/orders">
            <i class="bx bx-home-alt font-size-16 align-middle me-1"></i>
            Addresses
          </b-dropdown-item> 
          <b-dropdown-item to="/my-account/orders">
            <i class="bx bx-tag font-size-16 align-middle me-1"></i>
            Orders
          </b-dropdown-item>
          <b-dropdown-item class="d-block" to="/my-account/messages">
            <span class="badge bg-success float-end">{{user.notifications_count}}</span>
            <i class="bx bx-chat font-size-16 align-middle me-1"></i>
            Messages
          </b-dropdown-item>
          <b-dropdown-item to="/my-account/favourites">
            <i class="bx bx-star font-size-16 align-middle me-1"></i>
            Favourites
          </b-dropdown-item>
          <b-dropdown-item to="/my-account/Wishlist">
            <i class="mdi mdi-heart-outline font-size-16 align-middle me-1"></i>
            Wishlist
          </b-dropdown-item>

          <b-dropdown-divider></b-dropdown-divider>
          <a href="/my-account/logout" class="dropdown-item text-danger">
            <i
              class="bx bx-power-off font-size-16 align-middle me-1 text-danger"
            ></i>
            {{ $t("navbar.dropdown.henry.list.logout") }}
          </a>
          </div>
          <div v-else class="p-0" style="min-height: 300px;">
            <Login submit-url="/my-account/login" auth-error="" @register="$bvModal.show('register')">
                 <input type="hidden" name="_token" :value="csrf">
            </Login>
           
          </div>
        </b-dropdown>
      
        <!--<div class="dropdown d-inline-block">
          <button
            type="button"
            class="btn header-item noti-icon right-bar-toggle toggle-right"
            @click="toggleRightSidebar"
          >
            <i class="bx bx-cog bx-spin toggle-right"></i>
          </button>
        </div>-->
         <button
          id="toggle"
          type="button"
          class="btn btn-sm me-2 font-size-16 d-lg-none header-item"
          @click="toggleMenu"
        >
          <i class="fa fa-fw fa-bars"></i>
        </button>
      </div>
    </div>
    <b-button variant="light" v-if="(seller_sessions.length > 0) && (showSellerBar == false)" class="rounded-0 position-fixed bottom-0 left-0" @click="showSellerBar = true">
      <i class="bx bx-menu text-primary font-size-24"></i>
    </b-button>
    <div v-else-if="(seller_sessions.length > 0) && (showSellerBar == true)" class="position-fixed bottom-0 left-0 right-0 bg-light" >
      <div class="d-flex w-100">
        <b-button variant="light" @click="showSellerBar = false" class="rounded-0">
          <i class="bx bx-hide text-primary font-size-24"></i>
        </b-button>
        <b-button
          id="account-dropdown"
          dropup
          variant="white"
          v-b-modal.seller-companies
        >
        
            <div >
           
            
            <span class="d-none d-xl-inline-block ml-1 text-dark">Logged in as {{seller_sessions.length}} <span v-html="seller_sessions.length == 1 ? 'Company' : 'Companies'"></span> <i class="bx bx-link-external text-primary"></i></span>
            </div>
                        
        
          <!-- item-->
        
        </b-button>
      </div>
    </div>
    <b-modal id="seller-companies" content-class="border-radius-1em" header-class="border-none" hide-footer size="lg">
      
        <b-row class="justify-content-center" align-v="center">
            <b-col md="12" >
                <h3 class="mt-4 text-center">Login to One of Your Companies</h3>
                <b-card class="mt-4 border-radius-1em shadow-none border">
                    <b-list-group style="max-height: 540px; overflow-y: auto">
                        <b-list-group-item v-for="active_session in seller_sessions" :href="'//'+active_session.value+'.gemesys.co.uk/'" target="_blank" >
                            <h4 class="text-primary">{{active_session.value.replaceAll('-', ' ')}}</h4>
                            <a class="float-right font-weight-bold text-dark" :href="'//'+active_session.value+'.gemesys.co.uk/'" target="_blank">{{active_session.value}}.gemesys.co.uk <i class="bx bx-link-external"></i></a>

                        </b-list-group-item>
                    </b-list-group>


                </b-card>
            </b-col>
          </b-row>
    

    </b-modal>
    <b-modal id="delete-cart"  centered content-class="border-radius-1em" header-class="border-none" hide-footer>
        <b-row>
          <b-col md="12">
            <h4 class="text-center">Are You Sure?</h4>
            <p  class="text-center">You are about to delete all items from your cart. Are you sure you want to do this?</p>

            <b-row class="mt-3">
              <b-col>
                <a class="btn-rounded btn-block btn btn-danger" @click="$bvModal.hide('delete-cart')">
                  No!
                </a>
              </b-col>
              <b-col>
                <a class="btn-rounded btn-block btn btn-success" @click="destroyCart(cart.uid)">
                  Yes, delete all!
                </a>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
    </b-modal>
    <b-modal id="register" centered content-class="border-radius-1em" header-class="border-none" hide-footer>
       <Register @login="$bvDropdown.show('account-dropdown')" submit-url="/my-account/register" :regError="$attrs.regError">
              <input type="hidden" name="_token" :value="csrf">
            </Register>
    </b-modal>
  </header>
</template>
