<script type="text/javascript">
	export default{
		data: function(){
			return{
				//storageurl: 'https://'+this.$attrs.company.nickname+'.s3.eu-west-2.amazonaws.com',
				transitioning: 0,
				hoverIndex: -1,
			}
		},
		props: {
			headingtype: {
				type: String,
			default: 'h2',
			},
			autoplay: {
				type: Boolean,
				default: () => true,
			},
			items: {
				type: Array,
				default: () => [],
			},
			start: {
				type: Number,
				default: () => 0,
			},
			end: {
				type: Number,
				default: () => 2,
			},
			grid_limit: {
				type: Number,
				default: () => 2,
			},
			pages: {
				type: Number,
				default: () => 0,
			},
			layout_type: {
				type: String,
				default: () => 'horizontal',
			},
			resource: {
				type: String,
				default: () => 'category',
			},
			showNavigation: {
				type: Boolean,
				default: () => false,
			},
		},
		mounted(){
			this.pages = (this.items.length / this.grid_limit);
			this.transitioning = 0;
			var self = this;
			setTimeout(function(){
                 self.transitioning = 0;
                 //self.autoPlay();
                }, 1000);
			if(this.autoplay == true){
				setTimeout(function(){
                 //self.transitioning = 0;
                 self.autoPlay();
                }, 5000);
			}
		},
		methods: {
			autoPlay(){
				if((this.end < this.items.length) && (this.autoplay == true)){

                this.transitioning = 1;
                //setTimeout(5000);
                this.start = this.start + this.grid_limit;
                this.end = this.end + this.grid_limit;
                var self = this;
                setTimeout(function(){
                 self.transitioning = 0;
                 self.autoPlay();
                }, 1500);
               
                }

			},
			loadMore(){
                if(this.end < this.items.length){

                this.transitioning = 1;
                //setTimeout(5000);
                this.start = this.start + this.grid_limit;
                this.end = this.end + this.grid_limit;
                var self = this;
                setTimeout(function(){
                 self.transitioning = 0;
                }, 300);
               
                }
            },
            loadLess(){
                if(this.start >= 2){
                    this.transitioning = 1;
                this.start = this.start - this.grid_limit;
                this.end = this.end - this.grid_limit;
                var self = this;
                setTimeout(function(){
                 self.transitioning = 0;
                }, 300);
               
                }
            },
		}
	}
</script>

<template>
	<b-row>
		       <b-col md="12" class="mx-auto">
                     <transition name="slide-fadeleft">
                    <b-row v-if="(transitioning == 0)">
               		<b-col md="12" class="px-4">
               			<b-row class="px-3" v-if="layout_type == 'horizontal'">
		                <b-col class="mt-4 z-index-1 text-center" v-for="(item, index) in items.slice(start, end)" :class="{'col-lg-3 col-md-6 col-sm-6 col-12': grid_limit > 4}" @mouseenter="hoverIndex = index" @mouseleave="hoverIndex = -1">
		                    	
		                       <router-link class="" :to="'/'+resource+'/'+item.slug"><img v-if="item.image" :src="item.image" alt="Image" class="rounded-circle w-75 border" :class="{'border p-2' : hoverIndex == index}"></img>
		                        <img v-else :src="'/images/product-placeholder.png'" alt="Image" class="rounded-circle w-75 border" :class="{'border p2' : hoverIndex == index}"></img>

		                        <component :is="headingtype" class="text-center my-3" :class="{'text-primary' : hoverIndex == index}">{{item.name}}</component>
		                        <p>{{item.description}}</p>
		                           </router-link>
		                         
		                       
	
		                </b-col>
		            </b-row>
		        </b-col>
                <div class="d-flex position-absolute h-100 z-index-0 mx-0 px-0" v-if="showNavigation">
                	<a href="javascript:;" class="less-btn my-auto mr-auto" @click="loadLess()"><i class="bx bx-chevron-left font-size-42"></i></a>
                	<a href="javascript:;" class="more-btn my-auto ml-auto" @click="loadMore()"><i class="bx bx-chevron-right font-size-42"></i></a>
            	</div>
            </b-row>
            <b-row v-else class="loader-row">
                <b-col md="12" class="d-flex mx-auto my-auto justify-content-center">
                 <b-spinner style="width: 5rem; height: 5rem;" variant="primary" label="Spinning"></b-spinner>
                </b-col>
            </b-row>
            </transition>
               
                </b-col>
	</b-row>
</template>