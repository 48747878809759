
const cutClarityData = {
	cuts: 
		   [
		   	  {name: "Round Brilliant", value: "Round Brilliant"},
              {name: "Princess Cut", value: "Princess Cut"},
              {name: "Asscher Step Cut", value: "Asscher Step Cut"},
              {name: "Cushion Brilliant", value:  "Cushion Brilliant"},
              {name: "Heart Brilliant", value: "Heart Brilliant"},
              {name: "Trillion Brilliant", value: "Trillion Brilliant"},
              {name: "Marquise Brilliant", value:  "Marquise Brilliant"},
              {name: "Pear Brilliant", value:  "Pear Brilliant"},
              {name: "Oval Brilliant", value:  "Oval Brilliant"},
              {name: "Emerald Step Cut", value: "Emerald Step Cut"},
              {name: "Radiant Brilliant", value:  "Radiant Brilliant"}
           ],
	clarity: [
		 	  {name: "Flawless", value: "FL"},
              {name: "Internally Flawless", value: "IF"},
              {name: "Very Very Slightly Included 1", value: "VVS1"},
              {name: "Very Very Slightly Included 2", value:  "VVS2"},
              {name: "Very Slightly Included 1", value: "VS1"},
              {name: "Very Slightly Included 2", value: "VS2"},
              {name: "Slightly Included 1", value:  "SI1"},
              {name: "Slightly Included 2", value:  "SI2"},
              {name: "Included 1", value:  "I1"},
              {name: "Included 2", value: "I2"},
              {name: "Included 3", value:  "I3"}
	],
	colour: [
			  {name: "D", value: "D"},
              {name: "E", value: "E"},
              {name: "F", value: "F"},
              {name: "G", value: "G"},
              {name: "H", value: "H"},
              {name: "I", value: "I"},
              {name: "J", value: "J"},
              {name: "K", value: "K"},
              {name: "L", value: "L"},
              {name: "M", value: "M"},
              {name: "N", value: "N"},
              {name: "O", value: "O"},
              {name: "P", value: "P"},
              {name: "Q", value: "Q"},
              {name: "R", value: "R"},
              {name: "S", value: "S"},
              {name: "T", value: "T"},
              {name: "U", value: "U"},
              {name: "V", value: "V"},
              {name: "W", value: "W"},
              {name: "X", value: "X"},
              {name: "Y", value: "Y"},
              {name: "Z", value: "Z"}

	]

	

};


export { cutClarityData };