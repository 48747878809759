<script>
	import Multiselect from "vue-multiselect";
	import SideSlidePanel from "../../components/widgets/SideSlidePanel"
	export default{
		components: { Multiselect, SideSlidePanel },
		data(){
			return{
				id: '',
				options: [
					{id: 1, name: '-- No order statuses created! --'},
				],
				search: '',
				currentPage: 1,
				order: 'desc',
      			sortby: 'id',
      			recurring: false,
      			noresults: false, 
      			limit: 0,
				limits: [
		        {value: 25, text: "25"},
		        {value: 50, text: "50"},
		        {value: 100, text: "100"},
		    	],
				response_error: [],
				addNewPanel: false,
			}
		},
		props: {
			isDisabled: {
				type: Boolean,
				default: () => false,
				},
				allowAdd: {
				type: Boolean,
				default: () => false,
				},
				selectdata: {
					type: Array,
					default: () => [],
				}
		},
		created(){
			if(this.selectdata.length == 0){
			axios.get('/api/company/order/statuses?page='+this.currentPage+'&limit='+this.limit+'&sortby='+this.sortby+'&order='+this.order+'&search='+this.search+'', {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.api_token
              }}).then(response => {
				this.options = response.data.data;
				if(response.data.data.length == 0){
					this.noresults = true;
				}
			}).catch(error => {
				this.response_error = error.data;
			});
		}else{
			this.options = this.selectdata;
		}

			this.id = this.$attrs.id;
			
		},
		methods:{
			updateDepartment: function(selectedOption, id){
				this.$emit('order-status-selected', selectedOption);
			},
			customLabel ({ title, desc }) {
		      return `${title} – ${desc}`
		    }

		}
	}
</script>
<template>
	<div>
		<div class="d-flex">
	<multiselect v-model="id" track-by="name" label="name" :options="options" @select="updateDepartment" :option-height="104" :disabled="isDisabled" class="flex-grow-5" >
                     <!--<template slot="singleLabel" slot-scope="{ option }">{{ option.name }} - {{ option.jobtitle }}</template>-->
                     <template slot="singleLabel" slot-scope="{ option }"><div class="d-flex w-100 h-100"><div class="avatar-xs mr-4 my-auto">
                        <span class="avatar-title rounded-circle" :style="{'background-color' : option.color}"><i :class="option.icon"></i></span>
                      </div>
      <div class="option__desc d-flex flex-row w-75"><span class="option__title ml-2 my-auto w-100">{{ option.name }}</span>
      </div>
  		</div></template>
    <template slot="option" slot-scope="{ option }">
    	<div class="d-flex"><div class="avatar-xs mr-4">
                        <span class="avatar-title rounded-circle" :style="{'background-color' : option.color}"><i :class="option.icon"></i></span>
                      </div>
      <div class="option__desc d-flex flex-row w-50"><span class="option__title ml-2 my-auto w-100">{{ option.name }}</span>
      </div>
  		</div>
    </template>
                  </multiselect>
                  <a v-if="allowAdd == true" variant="light" @click="addNewPanel = !addNewPanel" class="my-auto btn btn-outline-light ml-2 flex-grow-1 h-100" href="javascript:;"><i class="mdi mdi-plus"></i></a>
              </div>
                  <SideSlidePanel :editPanel="addNewPanel" :panelClass="'edit-micropanel'" @close-panel="addNewPanel = !addNewPanel">
                  	<b-row class="px-4">
                  		<b-col>
                  			
			                  </b-col>
			                 </b-row>
                </SideSlidePanel>
   </div>
</template>
