<template>
  <Layout>
    <PageHeader />
    <b-row>
       <b-col md="12">
      <transition name="slide-error" mode="out-in">
                    <b-alert id="errors" variant="danger" :show="error_message == true"  dismissible @dismissed="error_message = !error_message">
                      <p class="font-size-14">{{error_reponse_message !== '' ? error_reponse_message : 'Oops! Please correct the following errors in your submission'}}</p>
                      <ul>
                        <li v-for="(error, key) in error_reponse">
                          {{error[0]}}
                        </li>
                      </ul>
                      <small>Please Try Again. If this error persists, please contact support.</small>
                    </b-alert>
                    
                  </transition>
                </b-col>
                <b-col md="12">
                    <transition name="slide-success" mode="out-in">
                  <b-alert id="success-message" variant="success" :show="success_message"  dismissible @dismissed="success_message = !success_message">
                      <p class="font-size-14">{{success_messagetext}}</p>
                    </b-alert>
                  </transition>
                  </b-col>
      <b-col md="8">
         <b-overlay :show="loadingMenus">
        <b-card class="border-radius-1em border shadow-none">
          <div class="d-flex">
          <h3 class="mt-1" v-if="editable.title == false"><a class="" @click="editable.title = true"><i class="bx bx-edit-alt"></i></a> {{menu.name}} </h3>
          <a class="" v-if="editable.title == true" @click="editable.title = false"><i class="bx bx-x font-size-24"></i></a>
          <b-form-input v-if="editable.title == true" type="text" name="name" v-model="menu.name" class="ml-2 underline-input font-size-20 mb-auto w-auto p-0"></b-form-input>
          
          <!--<a class="btn btn-primary ml-auto mb-3 mr-2 btn-rounded float-right" @click="menulink = JSON.parse(JSON.stringify(menulinkdata)), itemEditorAction = 'add', addPanel = true"> Add Menu Item </a>-->
          <b-col class="d-flex">
                   <div
                        class="form-check form-switch form-switch-sm my-2 ml-auto mr-4"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          :id="`SwitchCheckSizeactive`"
                          v-model="menu.active"
                          :value="menu.active"
                          name="active"
                          
                        />
                        <label class="form-check-label" :for="`SwitchCheckSizeactive`" 
                          >Active</label
                        >
                    </div>
                </b-col>
          <a class="btn btn-success ml-auto btn-rounded mb-3" @click="saveMenu()">
            <i class="bx bx-save">
            </i> 

            Save Menu
          </a>
        </div>
          <nested-draggable :parentlink="''" :domain="default_domain" :menuitems="menu.menuitems" @add-menu-item="addToMenu" @edit-menu-item="editMenuItem" @delete-menu-item="deleteMenuItem" :layouts="layouts" />

        </b-card>
         </b-overlay>
      </b-col>
   
      <b-col md="4">
        <MenuItemEditor :menulink="menulink" @add-menu-item="addToMenu"  :action="itemEditorAction" :activeDropdown="dropdownResource" :user="$attrs.user"></MenuItemEditor>
      </b-col>
      <!--<rawDisplayer class="col-3" :value="list" title="List" />-->
       <SideSlidePanel :editPanel="addPanel" :panelClass="'edit-sidepanel-slim position-fixed'" @close-panel="addPanel = false">
    <MenuItemEditor :menulink="menulink" @add-menu-item="addToMenu"  :action="itemEditorAction" :activeDropdown="dropdownResource" :user="$attrs.user"></MenuItemEditor>
  </SideSlidePanel>
   <SideSlidePanel :editPanel="megaMenuPanel" :panelClass="'edit-sidepanel-fullwidth position-fixed'" @close-panel="megaMenuPanel = false" style="z-index: 99999; left: 0; top: 0; overflow: hidden; padding: 0" :panelBodyClass="'container-fullwidth'"> 
    <megamenu ref="megamenu" 
:active_menu="$attrs.active_menu"
:company="$attrs.company"
:currencies="$attrs.currencies"
:exchange_rates="$attrs.exchange_rates"
:id="megamenu_id"
:isowner="$attrs.isowner"
:nickname="$attrs.nickname"
:selected_currency="$attrs.selected_currency"
:selected_currency_symbol="$attrs.selected_currency_symbol"
:slug="$attrs.slug"
:subscriptiondetails="$attrs.subscriptiondetails"
:user="$attrs.user"
:userpermissions="$attrs.userpermissions"
:validsubscription="$attrs.validsubscription"
:title="megamenu_name"
:type="'megamenu'"
 />
  </SideSlidePanel>
    </b-row>
    <b-modal v-model="showLayouts" hide-footer>
      <b-card class="border-radius-1em border shadow-none">
        <h4>Select Existing Megamenu Layout</h4>
        <b-form-group>
          <b-form-select v-model="selected_layout" class="form-control">
            <b-form-select-option v-for="layout in layouts" :value="layout">
              {{layout.id}}: {{layout.name}}
            </b-form-select-option>
          </b-form-select>
        </b-form-group>
        <b-button :disabled="selected_layout == ''" variant="light" class="btn-block mt-3 btn btn-light btn-rounded" @click="menu.menuitems[menuindex].menuitems[0].resource_id = selected_layout.uid, menu.menuitems[menuindex].menuitems[0].menuitems = selected_layout.content, saveMenu(), showLayouts = false, selected_layout = ''">
          Use this layout
        </b-button>
      </b-card>
    </b-modal>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import nestedDraggable from "../../components/widgets/nestedDraggable";
import MenuItemEditor from "../../components/widgets/page-builder/MenuItemEditor";
import SideSlidePanel from "../../components/widgets/SideSlidePanel";
import { EventBus }  from '../../app';
import megamenu from './layout';
export default {
  name: "nested-example",
  display: "Nested",
  order: 15,
  components: {
    Layout, PageHeader, nestedDraggable, MenuItemEditor, SideSlidePanel, megamenu
  },
  data() {
    return {
      showLayouts: false,
      list: [
        {
          name: "task 1",
          menuitems: [
            {
              name: "task 2",
              menuitems: []
            }
          ]
        },
        {
          name: "task 3",
          menuitems: [
            {
              name: "task 4",
              menuitems: []
            }
          ]
        },
        {
          name: "task 5",
          menuitems: []
        }
      ],
      menu: '',
      menu_raw: '',
      editable: {
        title: false,
        description: false,
      },
      layouts: '',
      selected_layout: '',
      default_domain: '',
      error_message: 'Could not update menu',
      error_reponse_message: '',
      menuindex: -1,
      error_reponse: [],
      loadingMenus: false,
      success_message: false,
      success_messagetext: 'Menu updated',
      categories: [],
      collections: [],
      pages: [],
      megamenu_id: '',
      megamenu_content: [],
      megamenu_name: '',
      addPanel: false,
      megaMenuPanel: false,
      itemEditorAction: 'add',
      dropdownResource: 'pages',
      menulink: {
        company_web_menu_id: '',
        name: '',
        description: '',
        label: '',
        resource: '',
        megamenu_content: null,
        menuitems: [],
        company_web_page_id: 0,
        resource_id: '',
        link: '',
      },
      menulinkdata: {
        company_web_menu_id: '',
        name: '',
        description: '',
        label: '',
        resource: '',
        megamenu_content: null,
        menuitems: [],
        company_web_page_id: 0,
        resource_id: '',
        link: '',
      }
    };
  },
  created(){
    this.getMenu();
    this.getDefaultDomain();
    this.getLayouts();
  },
  mounted(){
    let self = this;
    EventBus.$on('add-dropdown', function (payload) {
      console.log(payload);
      self.menu.menuitems = payload;
    });
    EventBus.$on('add-megamenu', function (payload, data, parentindex) {
      self.megamenu_id = data.resource_id;
      //self.menulink = data;
      self.menuindex = parentindex;
      self.megamenu_name = ''+ self.menu.menuitems[parentindex].label + ' Megamenu ';
      self.menu.menuitems[parentindex].menuitems[0].label = self.megamenu_name;
      self.megaMenuPanel = true;
      
    });
    EventBus.$on('add-megamenu-layout', function (payload, data, parentindex) {
      self.megamenu_id = data.resource_id;
      //self.menulink = data;
      self.menuindex = parentindex;
      self.showLayouts = true;
    });
    EventBus.$on('move-error', function(message) {
      self.error_message = true;
      self.error_reponse_message = message;
    });
    EventBus.$on('layout-saved', function(data){
      //alert('layout saved');
      console.log('layoutdata: '+ data);
      self.menu.menuitems[self.menuindex].menuitems[0].menuitems = data.content;
      self.menu.menuitems[self.menuindex].menuitems[0].resource_id = data.uid;
      self.megamenu_id = data.uid;
      self.saveMenu();
    });
    EventBus.$on('update-selected-layout', function(data){
      self.selected_layout = data;
    });
  },
  methods: {
    async getLayout(uid){
      if(uid == null){
        var layout = [];
        layout["content"] = {"xl": [], "lg": [], "md": [], "sm": [], "xs": []};
        return  layout;
      }else if(Object.keys(this.loadedLayouts).includes(uid) == false){
      await axios.get('/website-api/layouts/'+uid, {headers: {
                  'Authorization': 'Bearer ' + this.user.api_token
              }}).then(response => {
                this.loadedLayouts[uid] = response.data;
                return response.data;
              }).catch(error => {
                return 'could not load menu';
              });
      }else{
        return this.loadedLayouts[uid];
      }
    },
    getLayouts(){
       axios.get('/api/company/web/layouts?page=1&limit=0&sortby=created_at&order=ASC&search=&active=1', {headers : {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
        this.layouts = response.data;
      }).catch(error => {
        console.log(error);
      });
    },
    getDefaultDomain(){
      axios.get('/api/company/web/domain/default', {headers : {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
        this.default_domain = response.data;
      }).catch(error => {
        console.log(error);
      });
    },
    getMenu(){
      axios.get('/api/company/web/menu/'+this.$attrs.id, {headers : {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
        this.menu = response.data;
      }).catch(error => {
        console.log(error);
      });
    },
    saveMenu(){
      this.loadingMenus = true;
      axios.post('/api/company/web/menu/'+this.$attrs.id+'/update', this.menu, {headers : {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
          let self = this;
            setTimeout(function(){
              self.loadingMenus = false;
              self.success_message = true;
              self.success_messagetext = "Menu updated";
            }, 500);

            setTimeout(function(){
              self.success_message = false;
            }, 1500);
        }).catch(error => {
          this.error_reponse = errors.response.data.errors;
          this.error_message = true;
        });
    },
    deleteMenuItem(){

    },
    openAddPanel(){
      //this.addPanel = true;
    },
    addToMenu(action, type, item){
      console.log(item);
        if(this.itemEditorAction == 'add'){
            if(type == 'custom'){
              this.menu.menuitems.push(JSON.parse(JSON.stringify(item)));
              this.menulink = JSON.parse(JSON.stringify(this.menulinkdata));
           }else if(type == 'megamenu'){
              this.menu.menuitems = [JSON.parse(JSON.stringify(item))];
              this.menulink = JSON.parse(JSON.stringify(this.menulinkdata));
           }else if(type == 'page'){
              this.menu.menuitems.push(JSON.parse(JSON.stringify(item)));
              this.menulink = JSON.parse(JSON.stringify(this.menulinkdata));
            }else if(type == 'category'){
              item.link = type + '/' + item.link;
              this.menu.menuitems.push(JSON.parse(JSON.stringify(item)));
              this.menulink = JSON.parse(JSON.stringify(this.menulinkdata));
            }else if(type == 'collection'){
              item.link = type + '/' + item.link;
              this.menu.menuitems.push(JSON.parse(JSON.stringify(item)));
              this.menulink = JSON.parse(JSON.stringify(this.menulinkdata));
            }else{
              this.menu.menuitems.push(JSON.parse(JSON.stringify(item)));
              this.menulink = JSON.parse(JSON.stringify(this.menulinkdata));
            }
        }
        if(this.itemEditorAction == 'edit'){
          this.menulink = JSON.parse(JSON.stringify(this.menulinkdata));
        }
        this.menulink = JSON.parse(JSON.stringify(this.menulinkdata));
        this.itemEditorAction = 'add';
       //this.addPanel = false;
    },
    addDropdown(){

    },
    editMenuItem (payload){
      //console.log(payload);
      this.dropdownResource = payload.resource;
      this.itemEditorAction = 'edit';
      this.menulink = payload;
      //this.addPanel = true;
    }


  },
  watch: {
    menu: function (menudata){
      console.log(menudata);
    }
  }
};
</script>
<style scoped></style>