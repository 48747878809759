<script type="text/javascript">
	import DateTime from 'luxon/src/datetime.js';
	import Interval from 'luxon/src/interval.js';
	import ProductCard from "./ProductCard";
	import ImageSwiper from "../../../../components/widgets/gallery/ImageSwiper";
	import VueEasyLightbox from "vue-easy-lightbox";
	import { EventBus } from "../../../../public-app";
	import RelatedProducts from "./RelatedProducts";
	import RecentlyViewed from "./RecentlyViewed";
	import Login from "../../../../public-views/account/onpageLogin";
	import SocialShareBlock from "../../../../components/widgets/page-builder/widgets/web/SocialShareBlock";
	import ReviewForm from "../../../../components/widgets/forms/ReviewForm";
	import ReviewCarousel from "../../../../components/widgets/forms/ReviewCarousel";
	export default{
		data(){
			return {
				booking: {
					start_date: '',
					start_time: '',
					end_date: '',
					end_time: '',
					company_service_id: null,
					company_id: '',
					deposit_enabled: null,
					pay_upfront: true,
				},
				date: '',
				listingsearch: '',
				action_error: [],
				loadingActionModal: true,
				favourites_status: false,
				share: false,
				quantity: 1,
				previewImgBtns: false,
				showLightbox: false,
				tryOnLoading: true,
				imgView: null,
				imgViewIndex: 0,
				hoverIndex: -1,
				hoverLink: -1,
				loadingVariants: false,
				lightboxImages: [],
				selectedvariantstotal: 0.00,
				selectedvariants: {},
				selectedcombination: null,
				recentlyviewedobject: {
					id: null,
					uid: null,
					name: null,
					image: null,
					short_description: null,
					company: {
						nickname: null,
						logo: '',
					},
					url_rewrite: null,
					trade_price: null,
					tax_rule: null,
				},
				recentlyviewed: [],
				product: [],
				routearray: [],
				storageurl: '/public-image',
				form: {
					action: '',
					name: '',
					lastname: '',
					email: '',
					customer_uid: '',
					share_details: '',
					review: '',
					message: '',
					review_score: 0,
				},
				imgswiperOptions: {
		          slidesPerView: 5,
		          spaceBetween: 10,
		          pagination: {
		            el: '.swiper-pagination',
		            clickable: true
		          },
		          navigation: {
		            nextEl: '.swiper-button-next',
		            prevEl: '.swiper-button-prev'
		          }

		        },
		        swiperOptions: {
		          slidesPerView: 4,
		          spaceBetween: 30,
		          autoplay: {
				      delay: 2500,
				      disableOnInteraction: false
				    },
		          loop: false,
		          loopFillGroupWithBlank: false,
		          pagination: {
		            el: '.swiper-pagination',
		            clickable: true,
		          },
		          // Some Swiper option/callback...
		        },
		        reviewOptions: {
		          slidesPerView: 3,
		          spaceBetween: 30,
		          autoplay: {
				      delay: 2500,
				      disableOnInteraction: false
				    },
		          loop: false,
		          loopFillGroupWithBlank: false,
		          pagination: {
		            el: '.swiper-pagination',
		            clickable: true,
		          },
		          // Some Swiper option/callback...
		        },
		        reviewOptions1: {
		          slidesPerView: 1,
		          spaceBetween: 0,
		          autoplay: {
				      delay: 2500,
				      disableOnInteraction: false
				    },
		          loop: false,
		          loopFillGroupWithBlank: false,
		          pagination: {
		            el: '.swiper-pagination',
		            clickable: true,
		          },
		          // Some Swiper option/callback...
		        },
			}
		},
		props: {
			company: {
				type: Object
			},
			resource: {
				type: String,
				default: () => 'product'
			},
			product: {
				type: Object
			},
			user: {
				type: Object
			}
		},
		components: {
			SocialShareBlock,
			ProductCard,
			ImageSwiper,
			VueEasyLightbox,
			Login,
			RelatedProducts,
			RecentlyViewed,
			ReviewForm,
			ReviewCarousel,
			DateTime,
			Interval
		},
		created(){
			this.month = LocalDateTime.now();
			window.scrollTo(0,0);
		
			//this.getResource(this.resource);
		},
		mounted(){
				this.isZeroRated(this.product);
				this.tryOnLoading = true;
		},
		methods: {
			getTryOnScript(service){
				if(service == 'PicUp Media'){
				
				   const script = document.createElement('script');
				    script.src = 'https://static.cloud.picupmedia.com/static/embed-btn-script.js';
				    script.async = true;
				    script.defer = true;
				    this.$refs.picupelement.appendChild(script);
				    let self = this;
				    setTimeout(function() {
				    	self.tryOnLoading = false;
				    }, 500);
				    
			
				}
			},
			existsInFavourites(id){
				var findIndex = this.user.wishlist.findIndex(item => item == id);
				if(findIndex > -1){
					this.favourites_status = true;
				}else{
					this.favourites_status = false;
				}
			},
			addToFavourites(id){
				this.loadingActionModal = true;
				axios.post('/customer-api/user/wishlist/add-remove', {product_id: id}, {headers: {'Authorization' : 'Bearer '+ this.user.api_token}}).then(response => {
					if(response.data[0] == 'add'){
						this.favourites_status = true;
						this.user.wishlist = response.data[1];
					
						//this.user.wishlist.push(id);

						
					}else if(response.data[0] == 'delete'){
						this.favourites_status = false;
						this.user.wishlist = response.data[1];
					}
					var self = this;
                setTimeout(function(){
	                self.loadingActionModal = false;                
                }, 300);
				}).catch(error => {
					this.action_error = error.data;
				});
			},
			isZeroRated(product){
				var tax_rule = product.tax_rule;
				console.log('zero: '+product);
				var country_id = product.company.country_id;
					if(tax_rule !== null && tax_rule.country_rules.length > 0){
		    		var selected_rule = null;
		    		var selected_ruleIndex = -1;
			    	for(var ti = 0; ti < tax_rule.country_rules.length; ti++){
			    		selected_ruleIndex = tax_rule.country_rules[ti].countries.findIndex(item => item == country_id);
			    		if(selected_ruleIndex !== -1){
			    			selected_rule = tax_rule.country_rules[ti];
			    			ti = tax_rule.country_rules.length;
			    		}
			    	}
			    		  if(selected_rule !== null && selected_rule.value > 0){
		        			this.product["VAT_zero_rated"] = 0;
		         		}else{
		         			this.product["VAT_zero_rated"] = 1;
		         		}
		    	}else if(tax_rule !== null && tax_rule.country_rules.length == 0){
		    		if(tax_rule.percentage > 0){
		    			this.product["VAT_zero_rated"] = 1;
		    		}else{
		    			this.product["VAT_zero_rated"] = 0;
		    		}
		    		
		    	}else if(tax_rule == null){
		    		this.product["VAT_zero_rated"] = 0;
		    	}
			},
			hasReviewed(user_id){
				return this.product.reviews.findIndex(item => item.customer_id == user_id);
				
			},
			submitReview(uid){
				axios.post('/customer-api/product/'+uid+'/submit-review', this.form, {headers: {'Authorization' : 'Bearer '+this.user.api_token}}).then(response => {
					this.product.reviews.push(response.data);
					let self = this;
					setTimeout(function(){
						self.success_message = true;
						self.success_message_text = 'Your review has been submitted';
						self.loadReviews = false;
					}, 500);
				}).catch({

				});
			},
			isKeyValueActive(key, value){
				if(this.selectedvariants[key] == value){
					return true;
				}else{
					return false;
				}
			},
			addToCart(product, quantity, variant, combination){
		    	EventBus.$emit('add-to-cart', product, quantity, variant, combination);
		    },
		    checkDate(date){
		    	var formattedDate = this.$luxon(date , "date_huge");
		    	var day = formattedDate.split(",")[0];
		    	var day_allowed = this.product.booking_days.findIndex(item => item == day);
		    	if(day_allowed > -1){
		    		return day_allowed;
		    	}else{
		    		return false;
		    	}
		    },
		    checkTime(event, date, range){
		    	console.log(event);
		    	var index = this.checkDate(date);
		    	if(index !== false){
		    		if(event > this.product.booking_times[index][range]){
		    			if(range == 0){
		    			this.booking.start_time = event;
		    			}
		    		}
		    		if(event < this.product.booking_times[index][range]){
		    			if(range == 1){
		    			this.booking.end_time = event;
		    			}
		    		}
		    		
		    	}
		    },
		    parseBookingDate(date, time){
		    	return this.$luxon(''+date+' '+time+'', {
					    input: { format: "yyyy-MM-dd HH:mm" },
					    output: "med",
					});
		    },
			/**getResource(resource){
				this.routearray = this.$route.path.substring(1).split('/');
				axios.get('/website-api/widget/'+resource+'/'+this.routearray[this.routearray.length-1]).then(response => {
					this.product = response.data;
					
				}).catch(error => {
					this.product = [];
					console.log(error);
				});
			},**/
			imageSelected(img){
		    	if(this.imgView == null){
		    		this.product.images.unshift(this.product.image);
		    	}
		    	this.imgView = img;
		    },
		    calculateVariantsTotal(){
        		var total = 0.00;
        		for(let key in this.selectedvariants){
        			console.log(key);
        			total += parseFloat(this.selectedvariants[key].price_difference);
        		}
        		this.selectedvariantstotal = this.addTax(this.product, total);
        	},

        	addTax(product, price){
        		var tax_rule = product.tax_rule;
        		console.log(tax_rule);
		    	if((this.user !== null && this.user !== '') && (this.user.default_delivery_address !== '' && this.user.default_delivery_address !== null)){
		    		var country_id = this.user.default_delivery_address.country_id;
		    	}else{
		    		var country_id = product.company.country_id;
		    	}
		    	if(tax_rule !== null && tax_rule.country_rules.length > 0){
		    		var selected_rule = null;
		    		var selected_ruleIndex = -1;
			    	for(var ti = 0; ti < tax_rule.country_rules.length; ti++){
			    		selected_ruleIndex = tax_rule.country_rules[ti].countries.findIndex(item => item == country_id);
			    		if(selected_ruleIndex !== -1){
			    			selected_rule = tax_rule.country_rules[ti];
			    			ti = tax_rule.country_rules.length;
			    		}
			    	}
			    	console.log(selected_rule);
			    	if(tax_rule.type == 1){
		        		if(selected_rule !== null){
		        			var percentage = (parseFloat(selected_rule.value) / 100) + 1;
		        			return price * percentage;
		         		}else{
		         			return (price * 1.2).toFixed(2);
		         		}
	         		}else{
	         			if(selected_rule !== null){
		        			return price + selected_rule.value;
		         		}else{
		         			return (price * 1.2).toFixed(2);
		         		}
	         		}
         		}else if(tax_rule !== null && tax_rule.country_rules.length > 0){
         			var percentage = (parseFloat(tax_rule.percentage) / 100) + 1;
		        	return price * percentage;
         		}else {
		        	return (price * 1.2).toFixed(2);
         		}
        	},
        	/*addTax(price){
        		if(this.product.tax_rule !== null){
        			var percentage = (parseFloat(this.product.tax_rule.percentage) / 100) + 1;
        			return price * percentage;
         		}else{
         			return price;
         		}
        	},*/
		    setUpLightbox(){
		    	//this.product.images = JSON.parse(JSON.stringify(this.product.images).replaceAll('caption', 'title'));
		    		var image = {};
		    		image["src"] = ''+this.storageurl+''+this.product.image.replaceAll('//', '/')+'?s='+this.product.company.nickname;
		    		image["title"] = '';
		    		this.lightboxImages.push(image);
		    	for(var i = 0; i < this.product.images.length; i++){
		    		var image = {};
		    		image["src"] = ''+this.storageurl+''+this.product.images[i].src.replaceAll('//', '/')+'?s='+this.product.company.nickname;
		    		image["title"] = this.product.images[i].caption;
		    		console.log(image);
		    		this.lightboxImages.push(image);
		    		if(this.imgView == this.product.images[i]){
		    			this.imgViewIndex = (this.lightboxImages.length - 1);
		    		}
		    	}
		    	

		    },
		},
		  watch: {
        $route() {

        	this.$nextTick(() => {
        		 //this.loadingPage = true;
        		window.scrollTo(0,0);
        		this.tryOnLoading = true;
        		if(this.product.tryon !== null){
					if(this.product.tryon.url !== ''){
						this.getTryOnScript(this.product.tryon.service);
					}
				}
        		//this.loadTryOnScript;
        		 //this.getResource(this.resource);
        		 this.existsInFavourites(this.resource.id);

        		});
        	}
        },
        computed: {
        	/*loadTryOnScript(){
        		 if(this.product.tryon !== null){
					if(this.product.tryon.url !== ''){
						this.getTryOnScript(this.product.tryon.service);
					}
				}
        	},*/
        	recentlyViewed(){
        		if(this.product !== ''){
							this.recentlyviewedobject.id = this.product.id;
							this.recentlyviewedobject.uid = this.product.uid;
							this.recentlyviewedobject.name = this.product.name;
							this.recentlyviewedobject.image = this.product.image;
							this.recentlyviewedobject.short_description = this.product.short_description;
							this.recentlyviewedobject.company["nickname"] = this.product.company.nickname;
							this.recentlyviewedobject.company["logo"] = this.product.company.logo;
							this.recentlyviewedobject.url_rewrite = this.product.url_rewrite;
							this.recentlyviewedobject.trade_price = this.product.trade_price;
							this.recentlyviewedobject.tax_rule = this.product.tax_rule;
						}
						var viewed = window.sessionStorage.getItem("public_rv");
						
						if(!viewed){
							console.log('no viewed');
							//var vieweditems = JSON.parse(viewed);
							if(this.product !== ''){
								this.recentlyviewed.push(this.recentlyviewedobject);
							}
							window.sessionStorage.setItem("public_rv", JSON.stringify(this.recentlyviewed));
						}else{
							var vieweditems = JSON.parse(viewed);
								if(this.product !== ''){
										if(vieweditems.findIndex(item => item.id == this.product.id) == -1){
											vieweditems.push(this.recentlyviewedobject);
										}
								window.sessionStorage.setItem("public_rv", JSON.stringify(vieweditems));
								return vieweditems.reverse();
							}
							
						}
        	},
        	recentlyViewedFiltered(){
        		return this.recentlyViewed.filter(item => item.id !== this.product.id);
        	},
        	variantsTotal(){
        		var total = 0.00;
        		for(let key in this.selectedvariants){
        			console.log(key);
        			total =+ parseFloat(this.selectedvariants[key].price_difference);
        		}
        		return total;
        	},
        	priceIncVat(){
        		if(this.product.tax_rule !== null){
        			//var percentage = (parseFloat(this.product.tax_rule.percentage) / 100) + 1;
        			return this.addTax(this.product, this.product.sales_price);
         		}else{
         			return this.product.sales_price;
         		}
        	},
        	combinationPrice(){
        		if(this.selectedcombination !== null){
        		return parseFloat(this.addTax(this.product, this.selectedcombination.price_difference));
        		}else{
        			return parseFloat(0.00);
        		}
        	}
        }
	}

</script>
<template>
	<b-container fluid class="py-4 my-4 default-content">
		<vue-easy-lightbox
                :visible="showLightbox"
                :imgs="lightboxImages"
                :index="imgViewIndex"
                @hide="showLightbox = !showLightbox"
              ></vue-easy-lightbox>
		<b-row class="mb-4">
			<b-col md="4" class="px-3 overflow-hidden">
				<a class="position-relative d-block" @mouseenter="previewImgBtns = true" @mouseleave="previewImgBtns = false" @click="setUpLightbox(), showLightbox = !showLightbox">
				<img v-if="(product.image !== null) && (imgView == null)" :src="storageurl + product.image.replaceAll('//', '/')+'?s='+product.company.nickname+''" class="img-fluid w-100 border mb-3" >
				<img v-else-if="imgView !== null" :src="storageurl + imgView.replaceAll('//', '/')+'?s='+product.company.nickname+''" class="img-fluid w-100 border mb-3"> 
				<img v-else src="/images/product-placeholder.png" class="img-fluid w-100 border mb-3">
				<transition name="fade-right">
				   <div class="position-absolute top-0 right-0 mt-3 mr-3 rounded" v-if="previewImgBtns == true">
	                          	<a class="btn btn-primary rounded-circle bg-peach-soft" href="javascript:;">
	                          		<i class="bx bx-search"></i>
	                          	</a>
	                </div>
	            </transition>
				</a>
				<ImageSwiper :swiperOptions="imgswiperOptions" :items="product.images" @image-selected="imageSelected" :imgClass="'border'" :isPublic="true" :s="product.company.nickname" ></ImageSwiper>
			</b-col>
			<b-col md="8" class="px-3 d-flex flex-column">
				<SocialShareBlock />
				
				<p class="mb-2 text-muted font-size-14">REFERENCE: {{product.reference}}</p>
				<h1 class="text-capitalize mb-2">{{product.name}}</h1>
				<div class="d-flex mb-2">
					<b-form-rating id="rating-inline" variant="warning" v-b-modal.modal-1 @click="form.action = 'review'"  inline no-border size="lg" class="bg-transparent" :readonly="true" v-model="product.reviews_avg_rating"></b-form-rating><span class="my-auto ml-2" v-if="product.reviews.length > 0"><small >{{parseFloat(product.reviews_avg_rating).toFixed(2)}} / 5 from {{product.reviews.length}} reviews</small></span>
					<a class="my-auto"  href="javascript:;" v-b-modal.modal-1  @click="form.action = 'review'">
						<i class="bx bx-pencil-alt"></i>
						<small>Write Review</small>
					</a>
					
			
				</div>
				<p class="mb-3" v-html="product.short_description"></p>
				<h4 class="font-size-28 mb-4" >£{{parseFloat(priceIncVat)}}</h4>

				<!--<h5 class="text-uppercase font-size-12 text-underline spaced-title mt-2 pt-3">Product Features</h5>
				<b-row>
				     <div class="col-md-12" v-if="product.attributes.length == 0">
                    <b-card class="shadow-none text-center border mb-2 py-4">
                      <i class="bx bxs-cube font-size-24 text-muted"></i>
                      <h4>No Active Features</h4>
                  </b-card>
                  </div>
                 <div class="col-md-12 mb-2" v-if="product.attributes.length !== 0">
                <div class="row">
               <div class="col-md-12">
                <div class="form-control-group">
                <b-list-group class="list-group" flush>
                <li v-for="(active_attributes, index) in product.attributes" class="list-group-item p-0 d-flex py-3">
                <span class="my-auto pl-4 w-25">
                  <img v-if="active_attributes.attribute.image" :src="active_attributes.attribute.image" class="avatar-xs rounded-circle mr-2">
                  <img v-else src="/images/product-placeholder.png" class="avatar-xs rounded-circle mr-2">
                {{active_attributes.attribute.name}}
              </span>
              <span class="w-25">
                <img v-if="active_attributes.value.image" :src="active_attributes.value.image" class="avatar-xs rounded-circle mr-3">
                <img v-else src="/images/product-placeholder.png" class="avatar-xs rounded-circle ml-2 mr-2"> {{active_attributes.value.name}}
              </span>
              <span class="my-auto pl-4 w-50 d-flex">
              	<span class="ml-auto">{{active_attributes.measurement_value}}{{active_attributes.units.suffix}}
              	</span>
              </span>
              
			          </li>
			        </b-list-group>
			      </div>
			    </div>
			  </div>
			</div> 
			</b-row>-->
			

			


				<!--<div class="d-flex mb-2">
					 <b-button variant="-light" :disabled="quantity < 2" id="tooltip-add" @click="quantity--" class="btn btn-light font-size-16 btn-rounded w-25 mr-1 my-auto"><i class="mdi mdi-minus"></i></b-button>
					  <b-form-group label="" class="w-50 my-auto mx-1">
						<b-form-input type="number" v-model="quantity" size="lg" /></b-form-group>
					  <b-button variant="-light" :disabled="quantity >= product.total_qty " @click="quantity++" class="btn btn-light font-size-16 btn-rounded w-25 my-auto m1-1"><i class="mdi mdi-plus"></i></b-button>
				</div>-->
				<div class="d-flex w-100">
					<div class="d-flex flex-column w-50 pr-2">
					<b-list-group v-if="product.booking_days.length > 0">
						<b-list-group-item v-for="day in product.booking_days" class="d-flex py-3">
							<h5 class="mr-auto mb-0">
								{{day}}
							</h5>
							<div class="mr-3">
								Available Times:
							</div>
							<div class="mr-2">
								{{product.booking_times[product.booking_days.findIndex(item => item == day)][0]}}
							-
								{{product.booking_times[product.booking_days.findIndex(item => item == day)][1]}}
							</div>
						
						</b-list-group-item>
					</b-list-group>
				</div>
				<div class="d-flex flex-column w-50 pl-2">
				<b-form-group label="Booking Day">
					<b-form-datepicker type="datetime-local" v-model="booking.start_date" class="d-flex border-none p0">
						</b-form-datepicker>
					</b-form-group>
				<div class="d-flex mb-3">
				<b-form-group class="w-50 pr-2" label="Start Time">
					<b-form-input
						@input="checkTime($event, booking.start_date, 0)"
						type="time"
						id="time"
						min="09:00"
						:value="booking.start_time"
						>
					</b-form-input>
				</b-form-group>
				<b-form-group class="w-50 pl-2" label="End Time">
					<b-form-input
						@input="checkTime($event, booking.start_date, 1)"
						type="time"
						id="time"
						min="09:00"
						:value="booking.end_time"
						>
					</b-form-input>
				</b-form-group>
			
					</div>
				<div class="d-flex flex-column w-100">
					<div class="mb-3">
						<div  v-if="booking.start_time">
							<h3>From:</h3>
							{{parseBookingDate(booking.start_date, booking.start_time)}}
						</div>
					</div>
					<div class="mb-3">
						<div v-if="booking.end_time">
							<h3>To:</h3>
							{{parseBookingDate(booking.start_date, booking.end_time)}}
						</div>
					</div>
				</div>
					<b-button variant="primary" class="btn-rounded text-white btn-lg mt-auto" @click="addToCart(product, quantity, selectedvariants, selectedcombination)">
					Book Now
					</b-button>	
					
					<!--<a class="btn btn-primary my-auto btn-rounded w-75"  >Add to Cart</a>-->
				</div>
			</div>
				<div class="d-flex mt-auto pt-4 w-100">
					<b-card class="border-radius-1em border shadow-none w-100">
						 <h4>Sold By</h4>
						 <hr>
						 <div class="d-flex">
						 		<div class="" v-if="product.company.logo !== null">
						 			<router-link :to="'/seller/'+product.company.nickname+'/profile'">
						 				<b-img-lazy :src="storageurl + product.company.logo.replaceAll('//', '/').replaceAll('https:/'+product.company.nickname+'.s3.eu-west-2.amazonaws.com', '')+'?s='+product.company.nickname+''" class="avatar-md rounded-circle border mr-4" />
						 			</router-link>
						 		</div>
						 		<div class="d-flex flex-column">
						 			<h5 class="mb-1 mt-auto">{{product.company.name}}</h5>
						 			<p>{{product.company.description.slice(0, 175)}}...</p>
						 			<router-link class="btn btn-light my-auto btn-rounded" :to="'/seller/'+product.company.nickname+'/profile'">
						 				See Products <i class="bx bx-store"></i>
						 			</router-link>
						 		</div>
						 		
						 </div>
					</b-card>
				</div>
			</b-col>
		</b-row>
		<b-row>
			<b-col md="12" class="d-flex mt-4" v-if="product.long_description !== null || product.long_description !== ''">
				<h2 class="product-info-header">More Information</h2>
				<hr class="mb-4 flex-grow-1">
			</b-col>
			<b-col md="8" class="offset-md-4" v-if="product.long_description !== null || product.long_description !== ''">
				<p class="mt-3 px-3" v-html="product.long_description"></p>
			</b-col>
			<b-col md="12" class="d-flex mt-4">
				<h2 class="product-info-header">Reviews</h2>
				<hr class="mb-4 flex-grow-1">
			</b-col>
			<b-col md="12">
				<b-row v-if="product.reviews.length > 0"  >
					<b-col>
						<ReviewCarousel :items="product.reviews" :swiperOptions="reviewOptions" ></ReviewCarousel>
					</b-col>
					<b-col md="12" class="d-flex">
						<a class="ml-auto btn btn-light" href="javascript:;" v-b-modal.modal-1 @click="form.action = 'review'">Write Review</a>
					</b-col>
				</b-row>
				<b-row v-else >
					<b-col md="12" class="text-center py-4">
						<i class="bx bx-star text-warning d-inline-block font-size-14"></i>
						<i class="bx bx-star text-warning d-inline-block font-size-20"></i>
						<i class="bx bx-star text-warning d-inline-block font-size-28"></i>
						<i class="bx bx-star text-warning d-inline-block font-size-20"></i>
						<i class="bx bx-star text-warning d-inline-block font-size-14"></i>
						<h4 class="text-muted">
							No Reviews
						</h4>
						<!--<a class="text-primary" href="javascript:;">
							Be the first to review this product
						</a>-->
						<a class="btn btn-light btn-rounded mb-2"  v-b-modal.modal-1 @click="form.action = 'review'">Be the first to review this product <i class="bx bx-star text-warning"></i></a>
					</b-col>
				</b-row>
			</b-col>
		</b-row>
		<!--<b-row>
			<b-col md="12" class="d-flex mt-4">
				<h2 class="product-info-header">Related Products</h2>
				<hr class="mb-4 flex-grow-1">
			</b-col>
			<b-col md="8" class="offset-md-4 overflow-hidden">
				<RelatedProducts :swiperOptions="swiperOptions" :items="product.related_products">
				</RelatedProducts>

			</b-col>
		</b-row>-->
		<b-row class="mt-4 py-4" v-if="product.related_products.length !== 0">
			<b-col md="12" class="mt-4 overflow-hidden">
				<h2 class="product-info-header">Related Products</h2>
				<hr class="mb-4 flex-grow-1">
				<div class="pt-5">
				<RelatedProducts :swiperOptions="swiperOptions" :items="product.related_products" :startSlide="0">
				</RelatedProducts>
				</div>
			</b-col>
		</b-row>
		<b-row class=""  v-if="recentlyViewed && recentlyViewed.length > 0" >
			<b-col md="12" class="mt-4 overflow-hidden">
				<h2 class="product-info-header">You Recently Viewed</h2>
				<hr class="mb-4 flex-grow-1">
				<div class="pt-5">
				<RecentlyViewed v-if="recentlyViewed && recentlyViewed.length > 0"  :swiperOptions="swiperOptions" :items="recentlyViewedFiltered" :startSlide="0">
				</RecentlyViewed>
				</div>
			</b-col>
		</b-row>
		<b-modal id="modal-1" centered content-class="border-radius-1em" header-class="border-none" hide-footer>
			<b-card-body class="card-body pt-0">
            <div class="row">
            	<div class="col-sm-12 pb-4 text-center mb-4 d-flex">
            		<h3 class="section-info-header position-relative mx-auto">You are about to {{form.action}}</h3>
            	</div>
              <div class="col-sm-12 pb-4 text-center border border-radius-1em">
                <div class="avatar-md profile-user-wid mb-2 mx-auto" v-if="product.image !== null" >
                  <img
                  v-if="'thumb' in product.image" 
                  	 :src="storageurl + product.image.thumb+'?s='+product.company.nickname"
                    alt
                    class="img-thumbnail rounded-circle"
                  />
                  <img
                  v-else-if="'gallery' in product.image" 
                  	 :src="storageurl + product.image.gallery+'?s='+product.company.nickname"
                    alt
                    class="img-thumbnail rounded-circle"
                  />
                  <img
                  v-else-if="'src' in product.image" 
                  	 :src="storageurl + product.image.src+'?s='+product.company.nickname"
                    alt
                    class="img-thumbnail rounded-circle"
                  />
                   <span v-else class="avatar-title rounded-circle font-size-16 bg-light text-primary">{{product.name.slice(0,1)}}</span>
                </div>
                <div v-else class="avatar-md profile-user-wid mb-2 mx-auto">
                	<span class="avatar-title rounded-circle font-size-16 bg-light text-primary">{{product.name.slice(0,1)}}</span>
                </div>
                <h5 class="font-size-15 text-truncate">{{product.name}}</h5>
                <p class="paragraph-truncate-line2 font-size-12 mb-0 px-3" v-html="product.short_description"></p>
              </div>
           

            </div>
          </b-card-body>
    <b-form v-if="form.action == 'message'">
    	<b-form-group>
    		<b-form-input class="underline-input my-2" placeholder="First Name" type="text"></b-form-input>
    	</b-form-group>
    	<b-form-group>
    		<b-form-input class="underline-input my-2" placeholder="Last Name" type="text"></b-form-input>
    	</b-form-group>
    	<b-form-group>
    		<b-form-input class="underline-input my-2" placeholder="Email" type="text"></b-form-input>
    	</b-form-group>
    	<b-form-group>
    		<b-form-input class="underline-input my-2" placeholder="Telephone" type="text"></b-form-input>
    	</b-form-group>
    	<b-form-group>
    		<b-form-textarea
    		class="underline-input my-2"
		      id="textarea"
		      placeholder="Enter something..."
		      rows="4"
		    ></b-form-textarea>
    	</b-form-group>
    	<a class="btn btn-outline-light btn-rounded btn-block mt-3" href="javascript:;">Send Message</a>
    </b-form>

    <b-form v-if="((form.action == 'review') && (user)) && (hasReviewed(user.id) == -1)" class="px-2">
    	<b-form-group class="text-center">
    	<label class="mt-3 text-center section-info-header position-relative h4">Your Details</label>
    </b-form-group>
    <ReviewForm :source="'public'" :api_token="user.api_token" :product_id="product.id" :company_id="product.company_id" :user="user" :review_type="'product'"></ReviewForm>
    </b-form>
    <b-form v-if="(form.action == 'favourites')  && (user !== '')" class="px-2">
    	<b-overlay :show="loadingActionModal">
    		<b-card class="border-radius-1em shadow-none border" body-class="d-flex flex-column justify-content-center align-items-center py-3" v-if="favourites_status == true">
    			<i class="bx bx-check-circle font-size-48 text-success rounded-circle my-3"></i>
    			<h5>Product Added to Wishlist</h5>

    		</b-card>
    		<b-card class="border-radius-1em shadow-none border" body-class="d-flex flex-column justify-content-center align-items-center py-3" v-else-if="favourites_status == false">
    			<i class="bx bx-x-circle font-size-48 text-danger rounded-circle my-3"></i>
    			<h5>Product Removed from Wishlist</h5>

    		</b-card>
    	</b-overlay>
    	
    </b-form> 
    <b-form v-if="((form.action == 'favourites') || (form.action == 'review')) && (!user)" class="px-2">
    	<!--<a class="btn btn-outline-light btn-rounded btn-block mb-2" target="_blank">Sign In</a>-->
    	<Login submit-url="http://gemesys.co.uk/my-account/login" auth-error="">
             <input type="hidden" name="_token" :value="csrf">
        </Login>
        
    </b-form>
    <b-form v-if="((form.action == 'review') && (user)) && (hasReviewed(user.id) > -1)" class="px-2">
    	<h4 class="text-center">You have already reviewed this product</h4>
    	<p class="text-center">See your review below:</p>

    	<ReviewCarousel :items="[product.reviews[hasReviewed(user.id)]]" :swiperOptions="reviewOptions1"></ReviewCarousel>
    </b-form>
  </b-modal>
 <!-- <transition name="slide-fadepanel">
  <div class="edit-sidepanel" >
  		<iframe :src="product.tryon.link" allow="camera *; microphone *; fullscreen;" sandbox="allow-downloads allow-scripts allow-same-origin" style="width: 480px; height: 480px; max-width: 480px; max-height: 480px; border: none; overflow: hidden;" allowfullscreen></iframe>
  </div>
</transition>-->
	</b-container>
</template>


<style scoped >
#rating-inline{
	display: inline !important;
    padding: 0;
    width: auto !important;
}
#rating-inline .b-rating-star{
	padding: 0 5px 0 0 !important;
}
.spaced-title{
	letter-spacing: 0.9px;
}
</style>