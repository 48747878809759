<script type="text/javascript">
	import Layout from "../layouts/public";
	import ImageCarousel from "../components/widgets/carousel/ImageCarousel";
	import SellerSwiper from "../components/widgets/carousel/SellerSwiper";
	import PublicMarketplaceSellerList from "../components/widgets/PublicMarketplaceSellerList";
	import PageHeader from "../components/widgets/ecommerce/public/PageHeader";

	export default{
		components: { Layout, ImageCarousel, SellerSwiper, PublicMarketplaceSellerList, PageHeader },
		data(){
			return{
				title: 'Sellers',
				breadcrumbs: ["Home", "Sellers"],
				bread: {
					title: '',
					link: '',
					id: 1,
				},
				meta: {
					title: '',
					description: '',
					image: '', 
					og: {
						title: '',
						description: '',
						image: '',
						url: '',
					}
				},
				categorydata: '',
				categoriesdata: '',
				data: '',
				productsLoading: true,
				showFilters: false,
		        sliderPrice: 800,
		        priceRange: [0,250000],
		        brands: [],
		        collections: [],
		        categories: [],
		        sortby: 'created_at',
		        order: 'desc',
		        search: '',
		        view: 'grid',
		        public_active: 1,
		        trade_active: '',
		        active: '',
		        expanded: 1,
		        currentPage: 1,
		        query: '',
		        limit: 25,
				limits: [
				        {value: 25, text: "25"},
				        {value: 50, text: "50"},
				        {value: 100, text: "100"},
				      ],
				swiperOptions: {
		          slidesPerView: 4,
		          spaceBetween: 5,
		          loop: false,
		          loopFillGroupWithBlank: false,
		          pagination: {
		            el: '.swiper-pagination',
		            clickable: true,
		          },
		          // Some Swiper option/callback...
		        },
				contact_methods: [
					{
						name: 'Phone',
						icon: 'bx bx-phone',
						link: '/',
						hours: '',
						response_time: 'Coming Soon',
						status: 'Coming Soon',
					}, 
					{
						name: 'Email Support',
						icon: 'bx bx-mail-send',
						link: '/',
						hours: '',
						response_time: '24 - 48 hours',
						status: '24/7',
					},
					{
						name: 'Online Chat',
						icon: 'bx bx-chat',
						link: '/',
						hours: '',
						response_time: '15mins',
						status: '9am - 5pm (GMT)',
					}
				],
				items: [
			        {
			          cover_image: '/images/product-placeholder.png',
			          name: 'Necklaces',
			          link: '/category/jewellery',
			        },
			        {
			          cover_image: '/images/product-placeholder.png',
			          name: 'Rings',
			          link: '/category/jewellery',
			        },
			        {
			          cover_image: '/images/product-placeholder.png',
			          name: 'Earrings',
			          link: '/category/jewellery',
			        },
			        {
			          cover_image: '/images/product-placeholder.png',
			          name: 'Earrings',
			          link: '/category/jewellery',
			        },
			        {
			          cover_image: '/images/product-placeholder.png',
			          name: 'Earrings',
			          link: '/category/jewellery',
			        },
			      ],
			    items2: [
			        {
			          cover_image: '/images/product-placeholder.png',
			          name: 'Moira Fine Jewellery',
			          logo: '/images/product-placeholder.png',
			          link: '/category/jewellery',
			          price: '2000.00',
			        },
			        {
			          cover_image: '/images/product-placeholder.png',
			          logo: '/images/product-placeholder.png',
			          name: 'Robert Bicknell Fine Jewellery',
			          link: '/category/jewellery',
			          price: '2000.00',
			        },
			        {
			          cover_image: '/images/product-placeholder.png',
			          name: 'Edwardian Sapphire And Diamond Bracelet, Circa 1910',
			          logo: '/images/product-placeholder.png',
			          link: '/category/jewellery',
			          price: '2000.00',
			        },
			        {
			          cover_image: '/images/product-placeholder.png',
			          name: 'David Webb Rock Crystal Diamond Gold Earrings',
			          logo: '/images/product-placeholder.png',
			          link: '/category/jewellery',
			          price: '2000.00',
			        },
			        {
			          cover_image: '/images/product-placeholder.png',
			          name: 'Earrings',
			          logo: '/images/product-placeholder.png',
			          link: '/category/jewellery',
			          price: '2000.00',
			        },
			        {
			          cover_image: '/images/product-placeholder.png',
			          name: 'Earrings',
			          logo: '/images/product-placeholder.png',
			          link: '/category/jewellery',
			          price: '2000.00',
			        },
			        {
			          cover_image: '/images/product-placeholder.png',
			          name: 'Earrings',
			          logo: '/images/product-placeholder.png',
			          link: '/category/jewellery',
			          price: '2000.00',
			        },
			        {
			          cover_image: '/images/product-placeholder.png',
			          name: 'Earrings',
			          logo: '/images/product-placeholder.png',
			          link: '/category/jewellery',
			          price: '2000.00',
			        },
			        {
			          cover_image: '/images/product-placeholder.png',
			          name: 'Earrings',
			          logo: '/images/product-placeholder.png',
			          link: '/category/jewellery',
			          price: '2000.00',
			        },
			        {
			          cover_image: '/images/product-placeholder.png',
			          name: 'Earrings',
			          logo: '/images/product-placeholder.png',
			          link: '/category/jewellery',
			          price: '2000.00',
			        },
			        {
			          cover_image: '/images/product-placeholder.png',
			          name: 'Earrings',
			          logo: '/images/product-placeholder.png',
			          link: '/category/jewellery',
			          price: '2000.00',
			        },
			        {
			          cover_image: '/images/product-placeholder.png',
			          name: 'Earrings',
			          logo: '/images/product-placeholder.png',
			          link: '/category/jewellery',
			          price: '2000.00',
			        },
			      ]
			}
		},
		mounted(){
			window.scrollTo(0,0);
			this.loadSellers();
		},
		methods: {
			loadSellers(){
				//this.createQueryString();
				this.productsLoading = true;
				//console.log(this.$route.path);
				axios.get('/customer-api'+this.$route.path+''+this.query).then(response => {
					this.data = response.data;
				}).catch(error => {
					console.log(error);
				});
				var self = this;
                setTimeout(function(){
	                self.productsLoading = false;                
                }, 500);
			},
			createQueryString(){
				this.query = '?page='+this.currentPage+'&limit='+this.limit+'&sortby='+this.sortby+'&order='+this.order+'&search='+this.search+'&public_active='+this.public_active+'&active='+this.active+'&brands='+this.brands+'&collections='+this.collections+'&categories='+this.categories+'&pricerange='+this.priceRange+'';
			},
		},
		watch: {
        $route() {
        	this.$nextTick(() => {
        		window.scrollTo(0,0);
        	 });
         }  
    	},
	}

</script>

<template>
	<div>
		<PageHeader :data="{name: title, description: ''}" />
		
		<section>
		<!--<b-container>
			<b-row>
				<b-col md="12" class="mt-4 pt-4">
					<h2 class=" text-center text-primary">Select From Our Range of Sellers</h2>
					<p class="w-75 mb-0 mx-auto text-center">
		           Gemesys' community of jewellery retailers, antique dealers and watch sellers sell a wide range of items, from everyday jewellery, to premium luxury watches and branded jewellery. You can search through our range of sellers to find sellers with products made just for you.
		          </p>
				</b-col>
				<b-col md="10" class="mx-auto mb-4 pb-4" >
		        	<ImageCarousel :items="items" :grid_limit="3" :end="3" :autoplay="false"></ImageCarousel>
		      	</b-col>
			</b-row>
		</b-container>

		  <b-container class="py-4" fluid>
		   <b-row>
		      <b-col md="12">
		        <b-card class="shadow-none h-100" body-class="d-flex flex-column justify-content-center align-items-start">
		        <p>Editors Picks</p>
		        <h2 >Our Featured Sellers</h2>
		        <i class="bx bx-right-arrow-circle text-primary font-size-20"></i>
		      </b-card>
		      </b-col>
		     <b-col md="12" class="mx-auto">
		        <SellerSwiper :items="items2" :swiperOptions="swiperOptions" :startSlide="5"></SellerSwiper>
		      </b-col>
		    </b-row>
		  </b-container>-->

		</section>
				<section >
			<b-container fluid>
				
				 <b-row class="pb-4">
				 	<!--<b-col md="12" class="d-flex flex-row align-items-start mx-auto">
				 	<div  v-for="(bread, index) in breadcrumbs" class="d-flex flex-row">
		    		<span class="badge badge-light text-dark font-size-12 text-capitalize my-auto">{{bread}}</span>
		    		 <i class="bx bx-chevron-right my-auto" v-if="index !== (breadcrumbs.length -1)"></i>
		    		</div>

		    	</b-col>-->
		    <transition name="fade-filtersleft">
		     	<b-col md="3" v-if="showFilters">
          <div class="col-lg-12 pt-1 mt-4">
        <div>
          <div class="card p-4 rounded-4 shadow-sm border-radius-1em mt-4">
            <!--<h4 class="card-title mb-4">Filter</h4>-->
            <div>
               <div class="mb-3">
              <h6 class="mb-3">Price</h6>
              <vue-slider v-model="priceRange" :min="0" :max="100 + priceRange[1]"  @drag-end="loadCategory()" ></vue-slider>
              <b-form-group label="Min Price" class="w-50 d-inline float-left mt-2 mb-3 px-2">
              <b-form-input
                type="number"
                v-model="priceRange[0]"
                @input="loadCategory"></b-form-input>
              </b-form-group>
              <b-form-group label="Max Price" class="w-50 d-inline float-left mt-2 mb-3 px-2">
                 <b-form-input
                 label="Max Price"
                 type="number"
                 v-model="priceRange[1]"
                 @input="loadCategory"></b-form-input>
              </b-form-group>
            </div>
                <h6 class="my-3">Categories</h6>
                <!--<FilterListTree></FilterListTree>-->
                <FilterPublicProductCategories  :category_data="categoriesdata"></FilterPublicProductCategories>
                <h5 class="font-size-14 mb-3">Brands</h5>
               <FilterPublicProductBrands v-on:brands-filtered="filterBrands"></FilterPublicProductBrands>
               
            </div>
           

          <!-- <div class="mt-4 pt-3">
              <h5 class="font-size-14 mb-3">Customer Rating</h5>
              <div>
                <b-form-checkbox
                  id="checkbox-1"
                  name="checkbox-1"
                  value="accepted"
                  unchecked-value="not_accepted"
                >
                  4
                  <i class="bx bx-star text-warning"></i> & Above
                </b-form-checkbox>

                <b-form-checkbox
                  id="checkbox-2"
                  class="mt-2"
                  name="checkbox-2"
                  value="accepted"
                  unchecked-value="not_accepted"
                >
                  3
                  <i class="bx bx-star text-warning"></i> & Above
                </b-form-checkbox>

                <b-form-checkbox
                  id="checkbox-3"
                  class="mt-2"
                  name="checkbox-3"
                  value="accepted"
                  unchecked-value="not_accepted"
                >
                  2
                  <i class="bx bx-star text-warning"></i> & Above
                </b-form-checkbox>
                <b-form-checkbox
                  id="checkbox-4"
                  class="mt-2"
                  name="checkbox-4"
                  value="accepted"
                  unchecked-value="not_accepted"
                >
                  1
                  <i class="bx bx-star text-warning"></i>
                </b-form-checkbox>
              </div>
            </div>-->
          </div>
          <h6>Advertisement</h6>
          <div class="card shadow-sm border-radius-1em">
              <img src="/images/banner-placeholder.png" class="w-100">
            </div>
        </div>
      </div>
          </b-col>
      </transition>
      <transition name="fade-right">
					<b-col class="" :class="{'col-md-9 fade-right-in' : showFilters == true, 'col-md-12 fade-right-exit' : showFilters == false, }">
						<div class="row mb-3">
          
        <div class="col-lg-7 col-sm-6 d-flex">
            <div class="mt-sm-0 form-inline w-100" >
              <div class="search-box mr-2 ml-auto">
                <div class="position-relative">
                  <input
                    type="text"
                    class="form-control w-100 border"
                    placeholder="Search name, reference or description..."
                    v-model="search"
                    @keyup.enter="searchFilter($event)"
                  />
                  <i class="bx bx-search-alt search-icon"></i>
                </div>
              </div>
            </div>
          </div>

         
          <div class="col-lg-2 col-sm-6 d-flex">
          <form class="my-auto">
            <small class="mx-auto my-2 text-center d-block">Results {{data.from}} - {{data.to}} of {{data.total}}</small>
          </form>
        </div>
       
          <div class="col-lg-2 col-sm-6 d-flex">
            <div class="d-flex ml-auto">
              <ul class="nav nav-pills product-view-nav">
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': showFilters == true }" href="javascript: void(0);" @click="loadAllCategories(), showFilters = !showFilters, expanded = !expanded">
                    <i class="bx bx-filter-alt"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': view == 'grid' }" href="javascript: void(0);" @click="view = 'grid'">
                    <i class="bx bx-grid-alt"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': view == 'list' }" href="javascript: void(0);" @click="view = 'list'">
                    <i class="bx bx-list-ul"></i>
                  </a>
                </li>
              </ul>
              <div class="text-sm-right">
                  
                </div>
          </div>
        </div>
         <div class="col-xl-1 col-sm-6">
            <div class="my-auto">
              <b-form-select class="form-control border btn-rounded" v-model="limit" :options="limits" @input="loadCategory()"></b-form-select>
            </div>
          </div>
      </div>
						<b-overlay
			        :show="productsLoading"
			        variant="white"
			        opacity="0.85"
			        blur="1px"
			        rounded="xl">
			          <PublicMarketplaceSellerList :product_list="data.data" :expanded="expanded" :layouttoggle="false" :view="'grid'" :marketplace="'public'" ></PublicMarketplaceSellerList>
			      </b-overlay>
					</b-col>
				</transition>
				
			</b-row>
			</b-container>
		</section>
	</div>

</template>