<script type="text/javascript">
	import CompanyCustomerSearch from "../../../../components/widgets/CompanyCustomerSearch"
	import SearchCompanyCustomerAddress from "../../../../components/widgets/SearchCompanyCustomerAddress";
	import CompanyCustomerAddressForm from "../../../../components/widgets/forms/company/CompanyCustomerAddressForm";
import SwiperClass, { Pagination, Autoplay } from 'Swiper'
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper';

	export default{
		components: { CompanyCustomerSearch, SearchCompanyCustomerAddress, CompanyCustomerAddressForm, Swiper, SwiperSlide 
		},
		directives: {
    	swiper: directive
  	},
		props: {
			user: {
				type: Object
			},
			company: {
				type: Object
			}
		},
		data(){
			return {
				componentIndex: -1,
				enterManually: -1,
				componentsPanel: true,
				componentscurrentPage: 1,
      	componentslimit: 24,
				componentsorderDesc: true,
      	componentsorder: 'desc',
      	componentssortby: 'id',
	      selectedcomponentcategories: [],
      	componentsactive: '',
				categorysearch: '',
				component_categories: [],
				components: [],
				customer: '',
				swiperOptions: {
                  direction: 'horizontal',
                  loop: true,
                  slidesPerView: 3,
                  spaceBetween: 5,
                  pagination: {
                    el: '.swiper-pagination',
                    dynamicBullets: true,
                    clickable: true
                  },
                  navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                  },
                  breakpoints: {
                  0: {
                    slidesPerView: 1,
                    spaceBetween: 10
                  },
                  // when window width is >= 320px
                  350: {
                    slidesPerView: 1,
                    spaceBetween: 10
                  },
                  // when window width is >= 640px
                  768: {
                    slidesPerView: 2,
                    spaceBetween: 10
                  },
                  991: {
                    slidesPerView: 3,
                    spaceBetween: 10
                  },
                  1200: {
                    slidesPerView: 3,
                    spaceBetween: 10
                  },
                  1440: {
                    slidesPerView: 5,
                    spaceBetween: 10
                  }
                }
            },
				job: {
					type: '',
					jewellery_type: '',
					company_customer_id: '',
					name: '',
					description: '',
					images: '',
					status: '',
					labour_time: '',
					lead_time: '',
					completed: 0,
					returned_to_customer: 0,
					date_return: null,
					date_complete: null, 
					labour_price: 0.00, 
					items_price: 0.00,
					components_price: 0.00,
					deposit_price: 0.00
				},
				job_items: [],
				job_item: {
					id: '',
					uid: '',
					company_job_id: '',
					reference: '',
					name: '',
					area: '',
					company_component_id: '',
					component: null,
					description: '',
					quantity: 0,
					price_exc: 0.00,
					price_inc: 0.00,
					editable: 0,
				},
				job_statuses: ['Pending', 'In Progress', 'Awaiting', 'Complete - Awaiting Collection', 'Complete - Collected'],
				jewellery_types: [
					{text: 'Ring', icon: '', value: 'ring', subitems: []},
					{text: 'Necklace', icon: '', value: 'necklace', subitems: []},
					{text: 'Earrings', icon: '', value: 'earrings', subitems: []},
					{text: 'Bracelet', icon: '', value: 'bracelet', subitems: []},
					{text: 'Bangle', icon: '', value: 'bangle', subitems: []},
					{text: 'Cufflinks', icon: '', value: 'cufflinks', subitems: []},
					{text: 'Brooch', icon: '', value: 'brooch', subitems: []},
					{text: 'Other', icon: '', value: 'other', subitems: []},
				],
				componentcategoriessearch: '',
				componentssearch: '',
			}
		},
		mounted(){
			this.getAllCategories();
			this.getComponents();
			if(this.$attrs.editPanel == true){
				let self = this;
				setTimeout(function(){
					self.componentsPanel = true;
				}, 1000);
			}
		},
		methods: {
			addTax(price, tax_rule){
		    	console.log(tax_rule);
		    	if(this.$attrs.cart.listing == ''){
		    		var country_id = this.company.country_id;
		    	}else{
		    		var country_id = this.$attrs.cart.listing.country_id;
		    	}
		    	if(tax_rule !== null && tax_rule.country_rules.length > 0){
		    		var selected_rule = null;
		    		var selected_ruleIndex = -1;
		    	for(var ti = 0; ti < tax_rule.country_rules.length; ti++){
		    		selected_ruleIndex = tax_rule.country_rules[ti].countries.findIndex(item => item == country_id);
		    		if(selected_ruleIndex !== -1){
		    			selected_rule = tax_rule.country_rules[ti];
		    			ti = tax_rule.country_rules.length;
		    		}
		    	}
		    	console.log(selected_rule);
		    	if(tax_rule.type == 1){
	        		if(selected_rule !== null){
	        			var percentage = (parseFloat(selected_rule.value) / 100) + 1;
	        			return price * percentage;
	         		}else{
	         			return price;
	         		}
         		}else{
         			if(selected_rule !== null){
	        			return price + selected_rule.value;
	         		}else{
	         			return price;
	         		}
         		}
         		}else{
         			return price;
         		}
        	},
			selectCustomer(event){
				this.customer = event;
				this.job.company_customer_id = event.id; 
			},
			getAllCategories(){
	      axios.get('/api/company/product/component/categories?page=1&limit=0&sortby=id&order=ASC&search='+this.categorysearch+'&parent_id=null&marketplace_categories=company', {headers: {
	                  'Authorization': 'Bearer ' + this.user.api_token
	              }}).then(response => {
	        this.component_categories = response.data;
	      }).catch(error => {
	       // this.brands_error = error.data;
	      });
	    },
	    getComponents(){
	      if(this.components != ''){
	        if(this.componentslimit > this.components.total){
	          this.componentscurrentPage = 1;
	        }
	      }
	      if(this.componentsorderDesc == true){
	        this.componentsorder = 'desc';
	      }else{
	        this.componentsorder = 'asc';
	      }
	      axios.get('/api/company/product/components?page='+this.componentscurrentPage+'&limit='+this.componentslimit+'&sortby='+this.componentssortby+'&order='+this.componentsorder+'&search='+this.componentssearch+'&active=1&categories='+this.selectedcomponentcategories+'&pricerange=null', {headers: {'Authorization' : 'Bearer '+this.user.api_token}}).then(response => {
	        //console.log(response);
	        this.components = response.data;
	      });
	    },
		},
		computed: {
			itemsPrice(){
				var price = [0.00, 0.00, 0.00];
				for(var i = 1; i < this.job_items.length; i++){
					price[0] += this.job_items[i].price_exc;
					price[1] += this.job_items[i].price_inc;
					price[2] += this.job_items[i].price_inc - this.job_items[i].price_exc;
				}

				this.job.items_price = price[0];
				return price;
			},
      swiper() {
        return this.$refs.mySwiper.$swiper
      }
		}

	}
</script>

<template>
	<div> 
		<b-card class="border-radius-1em border shadow-none">
		<b-row>
			
			<b-col md="12">
		   	<h5>Search Customer</h5>
		   <CompanyCustomerSearch ref="selectcustomer" v-if="customer == ''" :api_token="user.api_token" @search-resource-selected="selectCustomer" :input_classes="'bg-light'"></CompanyCustomerSearch>

		    <div v-if="customer !== ''" class="d-flex border border-radius-1em px-3 py-2 my-2">
								<div v-if="customer.customer !== null"><img v-if="customer.customer.avatar" class="avatar-sm border mr-3 rounded-circle" :src="customer.customer.avatar">
                                <img v-else class="avatar-sm border mr-3 rounded-circle" src="/images/product-placeholder.png">
                              </div>
                              <img v-else class="avatar-sm border mr-3 rounded-circle" src="/images/product-placeholder.png">
                                <div class="mr-auto ml-2 my-auto flex-grow-3 mr-3">
                                	<h6 class="mr-auto my-auto">{{customer.firstname}} {{customer.lastname}}</h6>

                                </div>
                                <a class="btn btn-light my-auto rounded-circle" v-b-tooltip.hover title="Remove Customer" @click="customer = ''"><i class="mdi mdi-close font-size-18"></i></a>

							</div>
		   <SearchCompanyCustomerAddress v-if="customer !== ''" :id="customer_address" :api_token="user.api_token" :options="customer.addresses" @listing-selected="selectAddress" :data_provided="true" @add-address="$bvModal.show('add-address')" ></SearchCompanyCustomerAddress>

		   <a class="btn btn-success btn-rounded btn-block mt-2" v-if="customer !== ''" @click="customerSelected = true, existingCustomerWindow = false">Add Customer to Order</a>
		  

			</b-col>
			</b-row>
		</b-card>
			<b-card class="border-radius-1em border shadow-none">
		<b-row>

			<b-col md="12" class="mb-3">
				<h5>Job Type</h5>

				<div class="d-flex">
					<a class="btn btn-outline-light w-50 mr-1 d-block py-3 border-radius-1em" @click="job.type = 'bespoke'" :class="{'border-primary bg-light' : job.type == 'bespoke'}">
						<i class="bx bx-diamond d-block font-size-36 text-primary"></i>
						Bespoke Commission
					</a>
					<a class="btn btn-outline-light w-50 ml-1 d-block py-3 border-radius-1em" @click="job.type = 'repair'" :class="{'border-primary bg-light' : job.type == 'repair'}">
						<i class="bx bx-wrench d-block font-size-36 text-primary"></i>
						Repair
					</a>
				</div>
			</b-col>
			<b-col md="12" class="mb-3">
				<h5>Job Details</h5>

				<b-form-group label="Name" class="mb-3">
					<b-form-input class="form-control" type="text" v-model="job.name">
					</b-form-input>
				</b-form-group>

				<b-form-group label="Description">
					<textarea class="form-control" v-model="job.description">
					</textarea>
				</b-form-group>
			</b-col>
			</b-row>
		</b-card>
			<b-card class="border-radius-1em border shadow-none">
		<b-row>
		
			<b-col md="12">
				<h5>Jewellery Type</h5>
				<b-row >
					<b-col md="3" v-for="type in jewellery_types" class="mb-2">
					<a class="btn btn-outline-light w-100 d-block py-3 border-radius-1em" @click="job.jewellery_type = type.value, custom_type = (type == 'other' ? true : false)" :class="{'border-primary bg-light' : job.jewellery_type == type.value}">
						<!--<i class="bx bx-diamond d-block font-size-36 text-primary"></i>-->
						{{type.text}}
					</a>
					</b-col>
				</b-row>
				<b-row v-if="custom_type == true">
					<b-col md="12">
						<b-form-group label="Jewellery Type">
							<b-form-input type="text" v-model="job.jewellery_type">

							</b-form-input>
						</b-form-group>
					</b-col>
				</b-row>
			</b-col>
		</b-row>
	</b-card>
	<b-card class="border-radius-1em border shadow-none mb-5">
		<b-row>
			<h5>Items and Components</h5>
			<b-col md="12">
				
				<div  v-for="(item, itemindex) in job_items">
					<b-card class="border-radius-1em border shadow-none">
					<b-row>
						
						<b-col md="12">
							<div class="media">
								<div class="avatar-sm rounded-circle bg-primary text-white font-size-20 d-flex justify-content-center align-items-center">
									{{itemindex + 1}}
								</div>
							</div>
							<h5>Item {{itemindex + 1}}</h5>
							<div class="d-flex position-absolute top-0 right-0 m-2">
							<a v-if="item.editable == 0" class="btn btn-light btn-rounded mr-2" @click="job_items.splice(itemindex, 1)">
									<i class="bx bx-trash-alt text-danger"></i>
							</a>
							<a v-if="item.editable == 0" class="btn btn-light btn-rounded" @click="job_items[itemindex].editable = 1">
									<i class="bx bx-pencil text-primary"></i>
							</a>
							</div>
						</b-col>
						<b-col md="2" class="mb-2">
							<strong class="text-muted">Reference</strong>
							<span>{{job_items[itemindex].reference}}</span>
						</b-col>
						<b-col md="5" class="mb-2">
							<strong class="text-muted">Name</strong>
							<span>{{job_items[itemindex].name}}</span>
						</b-col>
						<b-col md="5" class="mb-2">
							<strong class="text-muted">Area</strong>
							<span v-if="job_items[itemindex].area !== ''">{{job_items[itemindex].area}}</span>
							<span class="font-size-12 badge bg-light" v-else>-- Not Set -- </span>
						</b-col>
						<b-col md="12" class="mb-2">
							<strong class="text-muted d-block">Description</strong>
							<span>{{job_items[itemindex].description}}</span>
						</b-col>
								<b-col md="4">
									
								</b-col>
								<b-col md="2" class="d-flex">
									<strong class="text-muted mt-auto mb-1">Quantity</strong>
										<h5 class="font-size-24 text-primary text-right ml-auto mt-auto mb-0">{{job_items[itemindex].quantity}}</h5>
								</b-col>
								<b-col md="3" class="d-flex">
									<strong class="text-muted mt-auto mb-1">Price Exc.</strong>
										<h5 class="font-size-24 text-primary text-right ml-auto mt-auto mb-0">{{$attrs.companyCurrency}}{{parseFloat(job_items[itemindex].price_exc).toFixed(2)}}</h5>
								</b-col>
								<b-col md="3" class="d-flex">
									<strong class="text-muted mt-auto mb-1">Price Inc.</strong>
										<h5 class="font-size-24 text-primary text-right ml-auto mt-auto mb-0">{{$attrs.companyCurrency}}{{parseFloat(job_items[itemindex].price_inc).toFixed(2)}}</h5>
								</b-col>
							<b-col md="12" v-if="(item.component !== null)" class="mt-2">
								<ul class="list-group product-list w-100 no-shadow">
                              <li class="list-group-item d-flex px-0 py-0 border product-list-item mb-2 shadow-sm">
                              	<div class="d-flex product-list-item-container w-100">

                              	<img v-if="(item.component.cover_image !== null) && ('thumb' in item.component.cover_image)" class="avatar-xl mr-3 product-list-img" :src="$attrs.storageurl + item.component.cover_image.thumb.replaceAll('//', '/')">	
                              	<img v-else-if="item.component.cover_image !== null" class="avatar-xl mr-3 product-list-img" :src="$attrs.storageurl + item.component.cover_image.src.replaceAll('//', '/')">
                              	<img v-else class="avatar-xl mr-3 product-list-img" src="/images/product-placeholder.png">
                                <div class="mr-auto ml-2 my-auto flex-grow-10 mr-3">
                                	<h4 class="mr-auto my-auto card-title">{{item.component.name}}</h4>
                                	<span class="badge badge-light bg-light position-absolute product-list-reference-badge top-0 m-2 font-size-12 " @click="copyTextToClipboard(item.component.reference)">{{item.component.reference}}</span>
						            <p class="paragraph-truncate-line2 mb-1" v-html="item.component.short_description" ></p>
						            <span class="mt-1 badge badge-light bg-light p-2 font-size-12 border-radius-1em">{{companyCurrency}}{{item.component.sales_price}} exc. VAT</span>
						            
						            <span v-if="item.component.tax_rule !== null" class="mt-1 badge badge-light bg-light p-2 font-size-12 border-radius-1em">{{companyCurrency}}{{addTax(item.component.sales_price, item.component.tax_rule).toFixed(2)}} <small>inc. VAT</small></span> <span class="mt-1 badge badge-light bg-light p-2 font-size-12 border-radius-1em">{{companyCurrency}}{{item.component.trade_price}} <small class="mr-3">Trade Price</small></span>
                                </div>
                                <div class="mr-auto ml-2 my-auto mr-3 d-flex align-items-center h-100">
                                <a href="javascript:;" b-v-tooltip.hover.top title="Change Component" @click="componentIndex = itemindex"><i class="bx bx-refresh font-size-20"></i></a>
                            	</div>
              
                              </div>
                              </li>
                              

                              <!--<li class="list-group-item py-4 text-center" ><a class="btn btn-outline-light btn-rounded">Load More</a>
                              </li>-->
                            </ul>

						</b-col>
						
									
							
				</b-row>
			<b-card class="border-radius-1em border shadow-none mt-3" v-if="item.editable == 1">
					<b-row>
						<!--<b-col md="12">
							<h5>Item {{itemindex + 1}} Details</h5>
						</b-col>-->
						<b-col md="12" v-if="item.component == null">
							<a class="btn-rounded py-3 btn-block mx-auto btn btn-primary mb-2" @click="componentIndex = itemindex">Use Component</a>
						</b-col>
						<b-col md="12">
									<a class="btn-rounded py-3 btn-block mx-auto btn btn-light" @click="enterManually = itemindex">
										Edit Manually
									</a>
						</b-col>
						<b-col md="12" class="mt-3" v-if="enterManually == itemindex">
							<b-row >
								<b-col md="2" class="mb-2">
									<b-form-group label="Reference">
										<b-form-input type="text" v-model="job_items[itemindex].reference"></b-form-input>
									</b-form-group>
								</b-col>
								<b-col md="5" class="mb-2">
									<b-form-group label="Name">
										<b-form-input type="text" v-model="job_items[itemindex].name"></b-form-input>
									</b-form-group>
								</b-col>
								<b-col md="5" class="mb-2">
									<b-form-group label="Area">
										<b-form-input type="text" v-model="job_items[itemindex].area"></b-form-input>
									</b-form-group>
								</b-col>
								<b-col md="12" class="mb-2">
									<b-form-group label="Description">
										<textarea class="form-control" rows="4" v-model="job_items[itemindex].description"></textarea>
									</b-form-group>
								</b-col>
								<b-col md="4">
									<b-form-group label="Quantity">
										<b-form-input type="number" v-model="job_items[itemindex].quantity"></b-form-input>
									</b-form-group>
								</b-col>
								<b-col md="4">
									<b-form-group label="Price Exc.">
										<b-form-input type="text" v-model="job_items[itemindex].price_exc"></b-form-input>
									</b-form-group>
								</b-col>
								<b-col md="4">
									<b-form-group label="Price Inc.">
										<b-form-input type="text" v-model="job_items[itemindex].price_inc"></b-form-input>
									</b-form-group>
								</b-col>
								
								
							</b-row>
						</b-col>
						<!--<b-col md="12" v-if="item.company_component_id" class="mt-4">
								<ul class="list-group product-list w-100 no-shadow">
                              <li class="list-group-item d-flex px-0 py-0 border product-list-item mb-2 shadow-sm">
                              	<div class="d-flex product-list-item-container w-100">

                              	<img v-if="(item.component.cover_image !== null) && ('thumb' in item.component.cover_image)" class="avatar-xl mr-3 product-list-img" :src="$attrs.storageurl + item.component.cover_image.thumb.replaceAll('//', '/')">	
                              	<img v-else-if="item.component.cover_image !== null" class="avatar-xl mr-3 product-list-img" :src="$attrs.storageurl + item.component.cover_image.src.replaceAll('//', '/')">
                              	<img v-else class="avatar-xl mr-3 product-list-img" src="/images/product-placeholder.png">
                                <div class="mr-auto ml-2 my-auto flex-grow-10 mr-3">
                                	<h4 class="mr-auto my-auto card-title">{{item.component.name}}</h4>
                                	<span class="badge badge-light bg-light position-absolute product-list-reference-badge top-0 m-2 font-size-12 " @click="copyTextToClipboard(item.component.reference)">{{item.component.reference}}</span>
						            <p class="paragraph-truncate-line2 mb-1" v-html="item.component.short_description" ></p>
						            <span class="mt-1 badge badge-light bg-light p-2 font-size-12 border-radius-1em">{{companyCurrency}}{{item.component.sales_price}} exc. VAT</span>
						            
						            <span v-if="item.component.tax_rule !== null" class="mt-1 badge badge-light bg-light p-2 font-size-12 border-radius-1em">{{companyCurrency}}{{addTax(item.component.sales_price, item.component.tax_rule).toFixed(2)}} <small>inc. VAT</small></span> <span class="mt-1 badge badge-light bg-light p-2 font-size-12 border-radius-1em">{{companyCurrency}}{{item.component.trade_price}} <small class="mr-3">Trade Price</small></span>
                                </div>
                                <div class="mr-auto ml-2 my-auto mr-3 d-flex align-items-center h-100">
                                <a href="javascript:;" @click="componentIndex = itemindex"><i class="bx bx-refresh font-size-20"></i></a>
                            	</div>
              
                              </div>
                              </li>
                              

                            </ul>

						</b-col>-->
						<b-col md="12" class="d-flex flex-column">
									<a class="btn btn-success btn-block btn-rounded py-3 mt-2 h-100 m-auto align-items-center justify-content-center" @click="job_items[itemindex].editable = 0">
										Save
									</a>
								</b-col>
					</b-row>
				</b-card>
			</b-card>
			
				</div>
			</b-col>
		</b-row>
		<b-row>
			<b-col md="12">
				<a class="btn btn-light btn-rounded btn-block py-4" @click="job_items.push(JSON.parse(JSON.stringify(job_item))), job_items[job_items.length - 1].editable = 1, job_items[job_items.length - 1].reference = '000'+ job_items.length+'', job_items[job_items.length - 1].name = 'Item 000'+ job_items.length+''">
					Add New Item
				</a>
			</b-col>
		</b-row>
	</b-card>
	<b-card class="border-radius-1em border shadow-none mb-3">
		<b-row>
			<b-col md="4">
				<h5>Component Totals:</h5>
			</b-col>
			<b-col md="2">
			</b-col>
			<b-col md="3" class="d-flex">
				<strong class="text-muted mb-1 mt-auto">Items Total Exc.</strong>
				<h5 class="font-size-24 text-primary text-right ml-auto mb-0 mt-auto">{{$attrs.companyCurrency}}{{job.items_price.toFixed(2)}}</h5>
			</b-col>
			<b-col md="3" class="d-flex">
				<strong class="text-muted mb-1 mt-auto">Items Total Inc.</strong>
				<h5 class="font-size-24 text-primary text-right ml-auto mb-0 mt-auto">{{$attrs.companyCurrency}}{{job.items_price.toFixed(2)}}</h5>
			</b-col>
		</b-row>
	</b-card>

	<b-card class="border-radius-1em border shadow-none mb-3">
		<b-row>
			<b-col md="12">
				<h5>Collection Time & Dates</h5>
			</b-col>

			<b-col md="6">
				<strong class="text-muted mb-1 mt-auto">Estimated Return Date</strong>
				<b-form-input id="pos-date" type="date"></b-form-input>
			</b-col>

			<b-col md="6">
				<strong class="text-muted mb-1 mt-auto">Desired Return Date</strong>
				<b-form-input id="pos-date" type="date"></b-form-input>
			</b-col>
		</b-row>
	</b-card>

	<b-card class="border-radius-1em border shadow-none mb-3">
		<b-row>
			<b-col md="12">
				<h5>Estimated Labour Cost</h5>
			</b-col>


			<b-col md="6">
				<b-form-group label=" ">
					<b-form-input type="number" v-model="job.labour_price"></b-form-input>
				</b-form-group>
			</b-col>
		
			<b-col md="3" class="d-flex">
				<strong class="text-muted mb-1 mt-auto">Labour Total Inc.</strong>
				<h5 class="font-size-24 text-primary text-right ml-auto mb-0 mt-auto">{{$attrs.companyCurrency}}{{job.labour_price}}</h5>
			</b-col>
			<b-col md="3" class="d-flex">
				<strong class="text-muted mb-1 mt-auto">Labour Total Inc.</strong>
				<h5 class="font-size-24 text-primary text-right ml-auto mb-0 mt-auto">{{$attrs.companyCurrency}}{{job.labour_price}}</h5>
			</b-col>
		</b-row>
	</b-card>

	<b-card class="border-radius-1em border shadow-none mb-3">
		<b-row>
			
			<b-col md="12">
				<h5>Deposit</h5>
			</b-col>

			<b-col md="6">
				<b-form-group label=" ">
					<b-form-input type="number" v-model="job.deposit_paid"></b-form-input>
				</b-form-group>
			</b-col>
		
			<b-col md="3" class="d-flex">
				<strong class="text-muted mb-1 mt-auto">Labour Total Inc.</strong>
				<h5 class="font-size-24 text-primary text-right ml-auto mb-0 mt-auto">{{$attrs.companyCurrency}}{{job.deposit_paid}}</h5>
			</b-col>
			<b-col md="3" class="d-flex">
				<strong class="text-muted mb-1 mt-auto">Labour Total Inc.</strong>
				<h5 class="font-size-24 text-primary text-right ml-auto mb-0 mt-auto">{{$attrs.companyCurrency}}{{job.deposit_paid}}</h5>
			</b-col>
		</b-row>
	</b-card>

	<b-card class="border-radius-1em border shadow-none mb-3">
		<b-row>
			<h5>Total</h5>
			<b-col md="12">
			</b-col>

			<b-col md="6">
			
			</b-col>
		
			<b-col md="3" class="d-flex">
				<strong class="text-muted mb-1 mt-auto">Labour Total Inc.</strong>
				<h5 class="font-size-24 text-primary text-right ml-auto mb-0 mt-auto">{{$attrs.companyCurrency}}{{parseFloat(job.deposit_paid) + parseFloat(job.labour_price) + parseFloat(job.items_price)}}</h5>
			</b-col>
			<b-col md="3" class="d-flex">
				<strong class="text-muted mb-1 mt-auto">Labour Total Inc.</strong>
				<h5 class="font-size-24 text-primary text-right ml-auto mb-0 mt-auto">{{$attrs.companyCurrency}}{{parseFloat(job.deposit_paid) + parseFloat(job.labour_price) + parseFloat(job.items_price)}}</h5>
			</b-col>
		</b-row>
	</b-card>
	<transition name="slide-fadepanel">
		<div v-if="componentsPanel" class="h-100 pos-frame-inner-left col-md-5 position-fixed z-index-5 bg-white top-0 right-0 p-4 d-flex flex-column">
			<b-row>
				<b-col md="12">
					<b-card class="border-radius-1em border shadow-none">
						<h3>Components</h3>
						<b-row>
							<b-col md="7" class="overflow-hidden mb-2">
								<b-form-group label="Search Components">
									<b-form-input type="text" v-model="search">
									</b-form-input>
									
								</b-form-group>
							</b-col>
							<b-col md="5" class="overflow-hidden mb-2 d-flex">
								<a class="btn btn-primary btn-rounded mt-auto">
										Search
									</a>
								<a class="btn btn-light btn-rounded mt-auto">
										Types
									</a>
									<a class="btn btn-light btn-rounded mt-auto">
										Filters
									</a>
							</b-col>
						</b-row>
						<b-row>
							<b-col md="12" class="overflow-hidden">
								<swiper :options="swiperOptions" @click-slide="imageSelected">
				
		    
		        					<swiper-slide v-for="(item, index) in component_categories" class="h-100" >
		        						<a @click="">
		        							<b-card class="border-radius-1em border shadow-none text-center" body-class="text-center">
		        							<img :src="(item.image !== null ? $attrs.storageurl + '/' + item.image: '/images/product-placeholder.png')"
												    img-top
												    style="max-width: 20rem;"
												    class="mb-2 rounded-circle avatar-sm"
											  	>
											  	<p class="mb-0 text-center text-truncate">{{item.name}}</p>
											  	</b-card>
												</a>
		        					</swiper-slide>
		        			</swiper>
								</b-col>
						</b-row>
						<b-row>
							<b-col md="12" class="overflow-auto widget-scrollbox" style="height: 60vh;">
							
									<ul class="list-group product-list w-100 no-shadow" v-if="(components.length !== 0)">
                              <li v-for="product in components.data" class="list-group-item d-flex px-0 py-0 border product-list-item mb-2 shadow-sm">
                              	<div class="d-flex product-list-item-container w-100">

                              	<img v-if="(product.cover_image !== null) && ('thumb' in product.cover_image)" class="avatar-xl mr-3 product-list-img" :src="$attrs.storageurl + product.cover_image.thumb.replaceAll('//', '/')">	
                              	<img v-else-if="product.cover_image !== null" class="avatar-xl mr-3 product-list-img" :src="$attrs.storageurl + product.cover_image.src.replaceAll('//', '/')">
                              	<img v-else class="avatar-xl mr-3 product-list-img" src="/images/product-placeholder.png">
                                <div class="mr-auto ml-2 my-auto flex-grow-10 mr-3">
                                	<h4 class="mr-auto my-auto card-title">{{product.name}}</h4>
                                	<span class="badge badge-light bg-light position-absolute product-list-reference-badge top-0 m-2 font-size-12 " @click="copyTextToClipboard(product.reference)">{{product.reference}}</span>
						            <p class="paragraph-truncate-line2 mb-1" v-html="product.short_description" ></p>
						            <span class="mt-1 badge badge-light bg-light p-2 font-size-12 border-radius-1em">{{companyCurrency}}{{product.sales_price}} exc. VAT</span>
						            
						            <span v-if="product.tax_rule !== null" class="mt-1 badge badge-light bg-light p-2 font-size-12 border-radius-1em">{{companyCurrency}}{{addTax(product.sales_price, product.tax_rule).toFixed(2)}} <small>inc. VAT</small></span> <span class="mt-1 badge badge-light bg-light p-2 font-size-12 border-radius-1em">{{companyCurrency}}{{product.trade_price}} <small class="mr-3">Trade Price</small></span>
                                </div>
                                <div class="mr-auto ml-2 my-auto mr-3 d-flex align-items-center h-100">
                                <a href="javascript:;" @click="job_items[componentIndex].name = product.name, job_items[componentIndex].refrence = product.reference, job_items[componentIndex].price_exc = product.sales_price, job_items[componentIndex].price_inc = addTax(product.sales_price, product.tax_rule), job_items[componentIndex].quantity = 1, job_items[componentIndex].component = product, job_items[componentIndex].company_component_id = product.id"><i class="bx bx-plus font-size-20"></i></a>
                            	</div>
              
                              </div>
                              </li>
                              

                              <!--<li class="list-group-item py-4 text-center" ><a class="btn btn-outline-light btn-rounded">Load More</a>
                              </li>-->
                            </ul>
                <ul class="list-group product-list w-100 no-shadow" v-if="(components.length == 0)">
                	  <li class="list-group-item d-flex flex-column">
                	  	<i class="bx bx-warning"></i> <span>No Product components to show</span>
                	  </li>
                </ul>
				
							</b-col>
						</b-row>
					</b-card>
				</b-col>
				
			</b-row>
			<b-row>
			<b-col md="12">
			<b-card class="border-radius-1em border shadow-none">
				<h3>Summary</h3>
			</b-card>
			</b-col>
		</b-row>
		<b-row class="mt-auto">
				<b-col md="12">
					<a class="btn btn-light btn-rounded btn-block py-3 mb-2">
						Save Draft
					</a>
					<a class="btn btn-primary btn-rounded btn-block py-3">
						Process Order
					</a>
				</b-col>
			</b-row>
		</div>
	</transition>	
	</div>

</template>

<style scoped>
#pos-date::-webkit-datetime-edit{

}
#pos-date::-webkit-datetime-edit-fields-wrapper{

}
#pos-date::-webkit-datetime-edit-text{

}

#pos-date::-webkit-datetime-edit-year-field{

}
#pos-date::-webkit-datetime-edit-month-field{

}
#pos-date::-webkit-datetime-edit-week-field{

}
#pos-date::-webkit-datetime-edit-day-field{

}
#pos-date::-webkit-datetime-edit-hour-field{

}
#pos-date::-webkit-datetime-edit-minute-field{

}
#pos-date::-webkit-datetime-edit-second-field{

}
#pos-date::-webkit-datetime-edit-millisecond-field{

}
#pos-date::-webkit-datetime-edit-ampm-field{

}

#pos-date::-webkit-inner-spin-button{

}
#pos-date::-webkit-calendar-picker-indicator{

}
</style>