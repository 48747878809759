<script type="text/javascript">
	import ProductsListWidgetProductCards from './ProductsListWidget-ProductCards';
	import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper';
//	import SwiperClass, { Pagination } from 'swiper'
  import 'swiper/swiper.min.css'
  import { EventBus } from "../../../../../app";
  import VueSlider from 'vue-slider-component'
  import ProductFilters from "./ProductFilters"
  import simplebar from 'simplebar-vue';
	//import FilterWebsiteProductCategories from '../../../../../components/widgets/FilterWebsiteProductCategories';
	export default{
		props: {
			category_id: {
				type: Number,
				default: 0
			},
			viewPortSize: {
				type: String
			}
		},
		components:{
			ProductsListWidgetProductCards,
			Swiper,
		  SwiperSlide,
		  VueSlider,
		  ProductFilters,
		  simplebar,
		},
		directives: {
		    swiper: directive
		  },
		data(){
			return{
				swiperOptions: {
	           	direction: 'horizontal',
		          slidesPerView: 3,
		          spaceBetween: 10,
		          pagination: {
		            el: '.swiper-pagination',
		            clickable: true
		          },
		          navigation: {
		            nextEl: '.swiper-button-next',
		            prevEl: '.swiper-button-prev'
		          },
		          breakpoints: {
		          0: {
		            slidesPerView: 1,
		            spaceBetween: 20
		          },
		          // when window width is >= 320px
		          350: {
		            slidesPerView: 1,
		            spaceBetween: 20
		          },
		          567: {
		            slidesPerView: 1,
		            spaceBetween: 20
		          },
		          // when window width is >= 640px
		          769: {
		            slidesPerView: 2,
		            spaceBetween: 40
		          },
		          991: {
		            slidesPerView: 3,
		            spaceBetween: 40
		          },
		          1200: {
		            slidesPerView: 3,
		            spaceBetween: 40
		          },
		          1440: {
		            slidesPerView: 4,
		            spaceBetween: 40
		          }
        		}
	        },
	      contentReloading: false,
				loadingProducts: true,
				products: null,
				expanded: true,
				query: '',
				view: 'grid',
				layouttoggle: true,
				search: '',
				showFilters: false,
				current_page: 1,
				sliderPrice: 800,
		        priceRange: [0,250000],
		        brands: [],
		        collections: [],
		        categories: [],
		        keywordslist: [],
		        productConditions: [
				        'New',
				        'Bespoke',
				        'Used',
				        'Antique',
				        'Refurbished',
				        'Custom',
		      	],
		      	productMetals: [
		      			'Gold',
		      			'Rose Gold',
		      			'White Gold',
		      			'Silver',
		      			'Platinum',
		      			'Palladium',
		      	],
		      	productCarats: [
		      			'9',
		      			'18',
		      			'24',
		      	],
		      	productGemstones: [
		      			'Aquamarine',
		      			'Diamond',
		      			'Emerald',
		      			'Garnet',
		      			'Opal',
		      			'Pearl',
		      			'Ruby',
		      			'Sapphire',
		      			'Tsavorite',
		      			'Topaz',
		      			'Tanzonite',
		      	],
		        sortby: 'created_at',
		        order: 'desc',
		        search: '',
		        view: 'grid',
		        public_active: 1,
		        trade_active: '',
		        active: '',
		        expanded: 0,
		        query: '',
		        limit: 25,
				limit: this.$attrs.filters.limit.value,
				limits: 
						[ 
							{value: this.$attrs.filters.limit.value, text: "16"},
		        	{value: this.$attrs.filters.limit.value * 2, text: "32"},
		        	{value: ((this.$attrs.filters.limit.value * 2) * 2), text: "64"}
		        ]
			}
		},
		created(){

			this.getProducts();
			this.compileSlides();
			if(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields, 'show')].show.value !== ''){
                    this.swiperOptions.slidesPerView = parseInt(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields, 'show')].show.value);
                }else{
                    this.swiperOptions.slidesPerView = 3;
                }


		            	
                if(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields, 'loop')].loop.value !== ''){
                    this.swiperOptions.loop = this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields, 'loop')].loop.value;
                }else{
                    this.swiperOptions.loop = false;
                }
                if(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields, 'margin')].margin.value !== ''){
                    this.swiperOptions.spaceBetween = this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields, 'margin')].margin.value;
                }else{
                    this.swiperOptions.spaceBetween = 10;
                }
                if(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields, 'margin')].margin.value !== ''){
                    this.swiperOptions.direction = this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields, 'margin')].margin.value;
                }else{
                    this.swiperOptions.direction = 'horizontal';
                }
		},
		updated(){
			//this.getProducts();
		},
		 watch: {
		 	$attrs(){
		 		this.$nextTick(() => {
			 		if(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'image_carousel')].image_carousel.subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'image_carousel')].image_carousel.subfields, 'show')].show.value !== ''){
	                    this.swiperOptions.slidesPerView = this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'image_carousel')].image_carousel.subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'image_carousel')].image_carousel.subfields, 'show')].show.value;
	                }else{
	                    this.swiperOptions.slidesPerView = 3;
	                }
            	});
		 	},
		 	$route(){
		 		this.$nextTick(() => {
			 		if(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'image_carousel')].image_carousel.subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'image_carousel')].image_carousel.subfields, 'show')].show.value !== ''){
	                    this.swiperOptions.slidesPerView = this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'image_carousel')].image_carousel.subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'image_carousel')].image_carousel.subfields, 'show')].show.value;
	                }else{
	                    this.swiperOptions.slidesPerView = 3;
	                }
            	});
		 	},
		 	viewPortSize(){
		 		console.log('change portsize');
			 		if(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'image_carousel')].image_carousel.subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'image_carousel')].image_carousel.subfields, 'show')].show.value !== ''){
	                    this.swiperOptions.slidesPerView = this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'image_carousel')].image_carousel.subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'image_carousel')].image_carousel.subfields, 'show')].show.value;
	                }else{
	                    this.swiperOptions.slidesPerView = 3;
	                }
	                 if(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel[this.filterStylesKey(this.$attrs.content, 'show')].show.value !== ''){
                    this.swiperOptions.slidesPerView = parseFloat(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields, 'show')].show.value).toFixed(0);
                    this.swiper.loopedSlide = parseFloat(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields, 'show')].show.value).toFixed(0);
                }else{
                    this.swiperOptions.slidesPerView = 3;
                }
                
              

		           
                this.swiper.update();
		 	},
      '$attrs.content[*].carousel.show.value'(){
                if(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel[this.filterStylesKey(this.$attrs.content, 'show')].show.value !== ''){
                    this.swiperOptions.slidesPerView = parseFloat(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields, 'show')].show.value).toFixed(0);
                    this.swiper.loopedSlide = parseFloat(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel.subfields, 'show')].show.value).toFixed(0);
                }else{
                    this.swiperOptions.slidesPerView = 3;
                }
              
            },
            '$attrs.content[*].image_carousel.show.value'(){
                if(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'image_carousel')].image_carousel[this.filterStylesKey(this.$attrs.content, 'show')].show.value !== ''){
                    this.swiperOptions.slidesPerView = parseFloat(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'image_carousel')].image_carousel.subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'image_carousel')].image_carousel.subfields, 'show')].show.value).toFixed(0);
                    this.swiper.loopedSlide = parseFloat(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'image_carousel')].image_carousel.subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'image_carousel')].image_carousel.subfields, 'show')].show.value).toFixed(0);
                }else{
                    this.swiperOptions.slidesPerView = 3;
                }
              
            },
            '$attrs.content[*].carousel.margin.value'(){
                if(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel[this.filterStylesKey(this.$attrs.content, 'margin')].margin.value !== ''){
                    this.swiperOptions.loop = this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel[this.filterStylesKey(this.$attrs.content, 'margin')].margin.value;
                }else{
                    this.swiperOptions.loop = 10;
                }
                
            },
            '$attrs.content[*].carousel.loop.value'(){
                if(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel[this.filterStylesKey(this.$attrs.content, 'loop')].loop.value !== ''){
                    this.swiperOptions.loop = this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel[this.filterStylesKey(this.$attrs.content, 'loop')].loop.value;
                }else{
                    this.swiperOptions.loop = false;
                }
               
            },
            '$attrs.content[*].carousel.direction.value'(){
                if(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel[this.filterStylesKey(this.$attrs.content, 'direction')].direction.value !== ''){
                    this.swiperOptions.direction = this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'carousel')].carousel[this.filterStylesKey(this.$attrs.content, 'direction')].direction.value;
                }else{
                    this.swiperOptions.direction = 'horizontal';
                }
                
            }
    },
		mounted(){
			this.compileSlides();
			
				 let self = this;
             EventBus.$on('update-widget', function(){

                    self.compileSlides();
                    self.contentReloading = true;
                    setTimeout(function(){
                        self.contentReloading = false;
                    }, 1000);
             });
		},
		computed: {
      swiper() {
        return this.$refs.productListWidgetCarousel.$swiper
      }
    },
		methods: {
			compileSlides(){
				var sizes = Object.keys(this.$attrs.page);
                for(var i = 0; i < sizes.length; i++){
                	console.log('Slide '+i+ ':'+sizes[i]);
                	var slide = sizes[i];

                					if(slide == 'xs'){
										this.swiperOptions.breakpoints[0].slidesPerView = parseFloat(this.$attrs.page[slide].sections[this.$attrs.block_array[0]].rows[this.$attrs.block_array[1]].cols[this.$attrs.block_array[2]].blocks[this.$attrs.block_array[3]].widgetcontent[this.filterStylesKey(this.$attrs.page[slide].sections[this.$attrs.block_array[0]].rows[this.$attrs.block_array[1]].cols[this.$attrs.block_array[2]].blocks[this.$attrs.block_array[3]].widgetcontent, this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'view')].view.value.replaceAll(' ', '_'))].carousel.subfields[this.filterStylesKey(this.$attrs.page[slide].sections[this.$attrs.block_array[0]].rows[this.$attrs.block_array[1]].cols[this.$attrs.block_array[2]].blocks[this.$attrs.block_array[3]].widgetcontent[this.filterStylesKey(this.$attrs.page[slide].sections[this.$attrs.block_array[0]].rows[this.$attrs.block_array[1]].cols[this.$attrs.block_array[2]].blocks[this.$attrs.block_array[3]].widgetcontent, this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'view')].view.value.replaceAll(' ', '_'))].carousel.subfields, 'show')].show.value).toFixed(0);
									}else if(slide == 'sm'){
										this.swiperOptions.breakpoints[350].slidesPerView = parseFloat(this.$attrs.page[slide].sections[this.$attrs.block_array[0]].rows[this.$attrs.block_array[1]].cols[this.$attrs.block_array[2]].blocks[this.$attrs.block_array[3]].widgetcontent[this.filterStylesKey(this.$attrs.page[slide].sections[this.$attrs.block_array[0]].rows[this.$attrs.block_array[1]].cols[this.$attrs.block_array[2]].blocks[this.$attrs.block_array[3]].widgetcontent, this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'view')].view.value.replaceAll(' ', '_'))].carousel.subfields[this.filterStylesKey(this.$attrs.page[slide].sections[this.$attrs.block_array[0]].rows[this.$attrs.block_array[1]].cols[this.$attrs.block_array[2]].blocks[this.$attrs.block_array[3]].widgetcontent[this.filterStylesKey(this.$attrs.page[slide].sections[this.$attrs.block_array[0]].rows[this.$attrs.block_array[1]].cols[this.$attrs.block_array[2]].blocks[this.$attrs.block_array[3]].widgetcontent, this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'view')].view.value.replaceAll(' ', '_'))].carousel.subfields, 'show')].show.value).toFixed(0);
									}else if(slide == 'md'){
										this.swiperOptions.breakpoints[768].slidesPerView = parseFloat(this.$attrs.page[slide].sections[this.$attrs.block_array[0]].rows[this.$attrs.block_array[1]].cols[this.$attrs.block_array[2]].blocks[this.$attrs.block_array[3]].widgetcontent[this.filterStylesKey(this.$attrs.page[slide].sections[this.$attrs.block_array[0]].rows[this.$attrs.block_array[1]].cols[this.$attrs.block_array[2]].blocks[this.$attrs.block_array[3]].widgetcontent, this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'view')].view.value.replaceAll(' ', '_'))].carousel.subfields[this.filterStylesKey(this.$attrs.page[slide].sections[this.$attrs.block_array[0]].rows[this.$attrs.block_array[1]].cols[this.$attrs.block_array[2]].blocks[this.$attrs.block_array[3]].widgetcontent[this.filterStylesKey(this.$attrs.page[slide].sections[this.$attrs.block_array[0]].rows[this.$attrs.block_array[1]].cols[this.$attrs.block_array[2]].blocks[this.$attrs.block_array[3]].widgetcontent, this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'view')].view.value.replaceAll(' ', '_'))].carousel.subfields, 'show')].show.value).toFixed(0);
									}else if(slide == 'lg'){
										this.swiperOptions.breakpoints[991].slidesPerView = parseFloat(this.$attrs.page[slide].sections[this.$attrs.block_array[0]].rows[this.$attrs.block_array[1]].cols[this.$attrs.block_array[2]].blocks[this.$attrs.block_array[3]].widgetcontent[this.filterStylesKey(this.$attrs.page[slide].sections[this.$attrs.block_array[0]].rows[this.$attrs.block_array[1]].cols[this.$attrs.block_array[2]].blocks[this.$attrs.block_array[3]].widgetcontent, this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'view')].view.value.replaceAll(' ', '_'))].carousel.subfields[this.filterStylesKey(this.$attrs.page[slide].sections[this.$attrs.block_array[0]].rows[this.$attrs.block_array[1]].cols[this.$attrs.block_array[2]].blocks[this.$attrs.block_array[3]].widgetcontent[this.filterStylesKey(this.$attrs.page[slide].sections[this.$attrs.block_array[0]].rows[this.$attrs.block_array[1]].cols[this.$attrs.block_array[2]].blocks[this.$attrs.block_array[3]].widgetcontent, this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'view')].view.value.replaceAll(' ', '_'))].carousel.subfields, 'show')].show.value).toFixed(0);				
									}else if(slide =  'xl'){
										this.swiperOptions.breakpoints[1200].slidesPerView = parseFloat(this.$attrs.page[slide].sections[this.$attrs.block_array[0]].rows[this.$attrs.block_array[1]].cols[this.$attrs.block_array[2]].blocks[this.$attrs.block_array[3]].widgetcontent[this.filterStylesKey(this.$attrs.page[slide].sections[this.$attrs.block_array[0]].rows[this.$attrs.block_array[1]].cols[this.$attrs.block_array[2]].blocks[this.$attrs.block_array[3]].widgetcontent, this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'view')].view.value.replaceAll(' ', '_'))].carousel.subfields[this.filterStylesKey(this.$attrs.page[slide].sections[this.$attrs.block_array[0]].rows[this.$attrs.block_array[1]].cols[this.$attrs.block_array[2]].blocks[this.$attrs.block_array[3]].widgetcontent[this.filterStylesKey(this.$attrs.page[slide].sections[this.$attrs.block_array[0]].rows[this.$attrs.block_array[1]].cols[this.$attrs.block_array[2]].blocks[this.$attrs.block_array[3]].widgetcontent, this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'view')].view.value.replaceAll(' ', '_'))].carousel.subfields, 'show')].show.value).toFixed(0);
										this.swiperOptions.breakpoints[1440].slidesPerView = parseFloat(this.$attrs.page[slide].sections[this.$attrs.block_array[0]].rows[this.$attrs.block_array[1]].cols[this.$attrs.block_array[2]].blocks[this.$attrs.block_array[3]].widgetcontent[this.filterStylesKey(this.$attrs.page[slide].sections[this.$attrs.block_array[0]].rows[this.$attrs.block_array[1]].cols[this.$attrs.block_array[2]].blocks[this.$attrs.block_array[3]].widgetcontent, this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'view')].view.value.replaceAll(' ', '_'))].carousel.subfields[this.filterStylesKey(this.$attrs.page[slide].sections[this.$attrs.block_array[0]].rows[this.$attrs.block_array[1]].cols[this.$attrs.block_array[2]].blocks[this.$attrs.block_array[3]].widgetcontent[this.filterStylesKey(this.$attrs.page[slide].sections[this.$attrs.block_array[0]].rows[this.$attrs.block_array[1]].cols[this.$attrs.block_array[2]].blocks[this.$attrs.block_array[3]].widgetcontent, this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'view')].view.value.replaceAll(' ', '_'))].carousel.subfields, 'show')].show.value).toFixed(0);	
										this.swiperOptions.slidesPerView = parseFloat(this.$attrs.page[slide].sections[this.$attrs.block_array[0]].rows[this.$attrs.block_array[1]].cols[this.$attrs.block_array[2]].blocks[this.$attrs.block_array[3]].widgetcontent[this.filterStylesKey(this.$attrs.page[slide].sections[this.$attrs.block_array[0]].rows[this.$attrs.block_array[1]].cols[this.$attrs.block_array[2]].blocks[this.$attrs.block_array[3]].widgetcontent, this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'view')].view.value.replaceAll(' ', '_'))].carousel.subfields[this.filterStylesKey(this.$attrs.page[slide].sections[this.$attrs.block_array[0]].rows[this.$attrs.block_array[1]].cols[this.$attrs.block_array[2]].blocks[this.$attrs.block_array[3]].widgetcontent[this.filterStylesKey(this.$attrs.page[slide].sections[this.$attrs.block_array[0]].rows[this.$attrs.block_array[1]].cols[this.$attrs.block_array[2]].blocks[this.$attrs.block_array[3]].widgetcontent, this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'view')].view.value.replaceAll(' ', '_'))].carousel.subfields, 'show')].show.value).toFixed(0);
									}
                }
			},
			updateSwiper(){
				this.swiperOptions.slidesPerView = 1;
				
			},
			filterStylesKey(styles, key){
	            for(var i = 0; i < styles.length; i++){
	               var keys = Object.keys(styles[i])
	               if(keys.includes(key)){
	                 return i;
	               }
	            }
	        },
			getProducts(){
				this.createQueryString();
				this.loadingProducts = true;
				if(this.$attrs.liveMode == true){
					axios.get('/website-api/widget/products?page='+this.current_page+this.query).then(response => {
							this.products = response.data;
							let self = this;
						          setTimeout(function(){
						            self.loadingProducts = false;
						          }, 500);
						}).catch(error => {
							let self = this;
						          setTimeout(function(){
						            self.loadingProducts = false;
						          }, 500);
						});
				}else{
					axios.get('/api/company/web/page/widgets/products?page='+this.current_page+this.query, {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
						this.products = response.data;
						let self = this;
					          setTimeout(function(){
					            self.loadingProducts = false;
					          }, 500);
					}).catch(error => {
						let self = this;
					          setTimeout(function(){
					            self.loadingProducts = false;
					          }, 500);
					});
				}
			},
			createQueryString(){
				this.query = '';
				for(var key in this.$attrs.filters){
					if((key !== 'page') && (key !== 'search')){
						this.query += '&'+key+'='+this.$attrs.filters[key].value+'';
					}
					if(key == 'search'){
						if(this.$attrs.filters.search.value == null){
							this.query += '&'+key+'=';
						}else{
							this.query += '&'+key+'='+this.$attrs.filters[key].value+'';
						}
					}
					if(key == 'categories'){
						if(this.category_id !== 0){
							var categories = [];
							categories.push(this.category_id);
							this.query += '&'+key+'='+categories;
						}else{
							this.query += '&'+key+'='+this.$attrs.filters[key].value+'';
						}
					}
					if(key == 'limit'){
						this.limits[0].value = this.$attrs.filters.limit.value;
						this.limits[0].text = this.$attrs.filters.limit.value;
						this.limits[1].value = this.$attrs.filters.limit.value * 2;
						this.limits[1].text = this.$attrs.filters.limit.value * 2;
						this.limits[2].value = (this.$attrs.filters.limit.value * 2) * 2;
						this.limits[2].text = (this.$attrs.filters.limit.value * 2) * 2;
					}
				}
			}
		}
	}

</script>

<template>
	<div>
		<div class="py-4 mb-4 default-content products" v-if="contentReloading == false">
		<b-row v-if="(($attrs.content[filterStylesKey($attrs.content, 'view')].view.value == 'grid') || ($attrs.content[filterStylesKey($attrs.content, 'view')].view.value == 'list'))  && (contentReloading == false)">
			<b-col class="col-md-12">
					<div class="row mb-3">
        
          <div class="col-lg-3 col-sm-6 d-flex">
          <form class="my-auto">
            <small class="mx-auto my-2 text-left d-block" v-if="products !== null">Results {{products.from}} - {{products.to}} of {{products.total}}</small>
          </form>
        </div>  
        <div class="col-lg-6 col-sm-6 d-flex">
            <div class="mt-sm-0 form-inline w-100" v-if="$attrs.elements[filterStylesKey($attrs.elements, 'show_search')].show_search.value == true" >
              <div class="app-search search-box mr-2 ml-auto">
                <div class="position-relative">
                  <input
                    type="text"
                    class="form-control w-100 border"
                    placeholder="Search name, reference or description..."
                    v-model="$attrs.filters.search.value"
                    @keyup.enter="getProducts()"
                  />
                  <span class="bx bx-search-alt search-icon"></span>
                </div>
              </div>
            </div>
          </div>

         
			
			<b-col md="3" class="d-flex">
				<ul class="nav nav-pills product-view-nav my-auto ml-auto d-flex" v-if="layouttoggle == true">
					<li class="nav-item  ml-auto mr-2" v-if="$attrs.elements[filterStylesKey($attrs.elements, 'allow_filters')].allow_filters.value == true">
                  <a class="nav-link" :class="{'active': showFilters == true }" href="javascript: void(0);" @click="showFilters = !showFilters, expanded = !expanded">
                    <i class="bx bx-filter-alt"></i>
                  </a>
                </li>
          		<!--<li class="nav-item px-1">
                  <a class="nav-link" :class="{'active': view == 'grid' }" href="javascript: void(0);" @click="view = 'grid'">
                    <i class="bx bx-grid-alt"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': view == 'list' }" href="javascript: void(0);" @click="view = 'list'">
                    <i class="bx bx-list-ul"></i>
                  </a>
                </li>-->
               </ul>
          	 <b-form-select v-if="$attrs.elements[filterStylesKey($attrs.elements, 'show_limits')].show_limits.value == true" class="form-control my-auto w-25 pl-1" v-model="$attrs.filters.limit.value" :options="limits" @input="getProducts"></b-form-select>
          </b-col>
		</div>
	</b-col>
			<b-col md="12" v-if="products !== null">
				<b-overlay :show="loadingProducts" no-wrap></b-overlay>
				<b-row v-if="($attrs.content[filterStylesKey($attrs.content, 'view')].view.value == 'grid')  && (contentReloading == false)">
					<b-col :class="'col-xl-'+$attrs.content[filterStylesKey($attrs.content, 'columns')].columns.value.xl+' col-lg-'+$attrs.content[filterStylesKey($attrs.content, 'columns')].columns.value.lg+' col-md-'+$attrs.content[filterStylesKey($attrs.content, 'columns')].columns.value.md+' col-sm-'+$attrs.content[filterStylesKey($attrs.content, 'columns')].columns.value.sm+' col-xs-'+$attrs.content[filterStylesKey($attrs.content, 'columns')].columns.value.xs" v-for="(product, index) in products.data">
						<ProductsListWidgetProductCards :user="$attrs.user" :exchange_rates="$attrs.exchange_rates"  :currencies="$attrs.currencies" :selected_currency="$attrs.selected_currency" :selected_currency_symbol="$attrs.selected_currency_symbol" :company="$attrs.company" :content="$attrs.content" :elements="$attrs.elements" :liveMode="$attrs.liveMode" :product="product" :index="index" :cardtype="'image'"></ProductsListWidgetProductCards>
					</b-col>
					<b-col md="12" v-if="products.data.length == 0">
						<b-card class="border-radius-1em shadow-none border py-4 text-center">
							<img src="/images/Management.png" class="img-fluid" style="max-width: 500px">
							<h1>We're Working On It!</h1>
							<p>There's no products here yet, but we're adding some - just watch this space!</p>
						</b-card>
					</b-col>
				</b-row>
				<b-row v-if="($attrs.content[filterStylesKey($attrs.content, 'view')].view.value == 'list')  && (contentReloading == false)">
					<b-col md="12" v-for="(product, index) in products.data">
						<ProductsListWidgetProductCards :user="$attrs.user" :exchange_rates="$attrs.exchange_rates"  :currencies="$attrs.currencies" :selected_currency="$attrs.selected_currency" :selected_currency_symbol="$attrs.selected_currency_symbol" :company="$attrs.company" :content="$attrs.content" :elements="$attrs.elements" :liveMode="$attrs.liveMode" :product="product" :index="index" :cardtype="'horizontal'"></ProductsListWidgetProductCards>
					</b-col>
				</b-row>
				
			</b-col>
			<b-col v-else>
				<b-card class="border-radius-1em shadow-none border py-4 text-center">
					<img src="/images/Management.png" class="img-fluid" style="max-width: 500px">
					<h1>We're Working On It!</h1>
					<p>There's no products here yet, but we're adding some - just watch this space!</p>
				</b-card>
			</b-col>
			<b-col md="12">
				<b-pagination v-if="(products !== null) && ($attrs.elements[filterStylesKey($attrs.elements, 'pagination')].pagination.value == true)" v-model="current_page" :total-rows="products.total" :per-page="products.per_page" :align="$attrs.elements[filterStylesKey($attrs.elements, 'pagination_alignment')].pagination_alignment.value" :class="$attrs.elements[filterStylesKey($attrs.elements, 'pagination_shape')].pagination_shape.value" @input="getProducts()"></b-pagination>
			</b-col>
		</b-row>
		<swiper v-else-if="($attrs.content[filterStylesKey($attrs.content, 'view')].view.value == 'carousel')  && (contentReloading == false)" ref="productListWidgetCarousel" :options="swiperOptions" class="overflow-hidden" >
        <swiper-slide v-for="(product, index) in products.data" class="h-100" >
              <ProductsListWidgetProductCards :user="$attrs.user" :exchange_rates="$attrs.exchange_rates"  :currencies="$attrs.currencies" :selected_currency="$attrs.selected_currency" :selected_currency_symbol="$attrs.selected_currency_symbol" :company="$attrs.company" :content="$attrs.content" :elements="$attrs.elements" :liveMode="$attrs.liveMode" :product="product" :index="index" :cardtype="'image'"></ProductsListWidgetProductCards>
        </swiper-slide>
  	</swiper>
		<transition name="fade-left">
		<div v-if="showFilters == true" style="" class="filter-sidebar position-fixed w-100 bg-white top-0 left-0 bottom-0 shadow-lg">
			<simplebar style="max-height: 100vh; height: 100vh; overflow: hidden scroll;">
			<ProductFilters :priceRange="priceRange" :keywordslist="keywordslist" :productGemstones="productGemstones" :productMetals="productMetals" :productConditions="productConditions" :showFilters="showFilters" :loadingFilters="loadingFilters" :categoriesdata="categoriesdata" @get-products="getProducts()" :filters="$attrs.filters" ></ProductFilters>
		</simplebar>
		</div>
	</transition>
	</div>
		<b-overlay :show="contentReloading" v-else-if="contentReloading == true" no-wrap></b-overlay>
	</div>
</template>