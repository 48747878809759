<script>
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import TradeProductCard from './TradeProductCard'
//import 'swiper/css/swiper.css'
/**
 * Dashboard Component
 */
export default {
  components: {
    Swiper,
    SwiperSlide,
    TradeProductCard
  },
  directives: {
    swiper: directive
  },
  data() {
    return {
    	hoverIndex: -1,
    	storageurl: 'https://'+this.$attrs.company.nickname+'.s3.eu-west-2.amazonaws.com',
    };
  },
  props: {
  	items: {
  		type: Array,
  		default: () => []
  	},
  	swiperOptions: {
				type: Object,
				default: () => {
          // Some Swiper option/callback...
        },
			},
	startSlide: {
		type: Number,
		default: () => 1
	},
	roundedBorders: {
		type: Boolean,
		default: () => true
	},
  },
  computed: {
      swiper() {
        return this.$refs.relatedTradeProductsSwiper.$swiper
      }
    },
   mounted() {
    console.log('Current Swiper instance object', this.swiper);
      this.swiper.slideTo(this.startSlide, 1000, false);
  },
};
</script>

<template>
	<swiper ref="relatedTradeProductsSwiper" :options="swiperOptions">
        <swiper-slide v-for="(product, index) in items">
            <TradeProductCard :selected_currency="$attrs.selected_currency" :selected_currency_symbol="$attrs.selected_currency_symbol" :exchange_rates="$attrs.exchange_rates" :currencies="$attrs.currencies" :index="index" :product="product.related_product_details"></TradeProductCard>
        </swiper-slide>
    	<div class="swiper-pagination" slot="pagination"></div>
  	</swiper>
</template>