<template>
	 <gmap-map v-if="$attrs.listings.data.length > 0" :center="{ lat: parseFloat($attrs.listings.data[0].latitude), lng: parseFloat($attrs.listings.data[0].longitude) }" style="height: 350px" class="border-radius-1em" :map-type-id="$attrs.elements[filterStylesKey($attrs.elements, 'map_type')].map_type.value" :zoom="$attrs.elements[filterStylesKey($attrs.elements, 'zoom_level')].zoom_level.value" :options="options">
                  <gmap-marker
                     v-for="locationMap in $attrs.listings.data"
                     v-if="(locationMap.latitude !== null) && (locationMap.longitude !== null)"
                    :key="index"
                    :position="{ lat: parseFloat(locationMap.latitude), lng: parseFloat(locationMap.longitude) }"
                    :clickable="true"
                    :draggable="true"
                    @click="center = { lat: parseFloat(locationMap.latitude), lng: parseFloat(locationMap.longitude) }"
                  ></gmap-marker>
                </gmap-map>

</template>

<script type="text/javascript">
	export default {
		data(){
			return {
				center: [],
				options: {
				   zoomControl: this.$attrs.elements[this.filterStylesKey(this.$attrs.elements, 'allow_zoom')].allow_zoom.value,
				   mapTypeControl: this.$attrs.elements[this.filterStylesKey(this.$attrs.elements, 'map_type_switcher')].map_type_switcher.value,
				   scaleControl: this.$attrs.elements[this.filterStylesKey(this.$attrs.elements, 'allow_scaling')].allow_scaling.value,
				   streetViewControl: this.$attrs.elements[this.filterStylesKey(this.$attrs.elements, 'allow_streetview')].allow_streetview.value,
				   rotateControl: this.$attrs.elements[this.filterStylesKey(this.$attrs.elements, 'allow_rotate')].allow_rotate.value,
				   fullscreenControl: this.$attrs.elements[this.filterStylesKey(this.$attrs.elements, 'allow_fullscreen')].allow_fullscreen.value,
				   disableDefaultUi: this.$attrs.elements[this.filterStylesKey(this.$attrs.elements, 'disable_default_ui')].disable_default_ui.value,
				 }
			}
		},
		methods: {
			filterStylesKey(styles, key){
            for(var i = 0; i < styles.length; i++){
               var keys = Object.keys(styles[i])
               if(keys.includes(key)){
                 return i;
               }
            }
          },
		}
	}

</script>