const shippingStatuses = [{text: 'Booked', id: 1},
{text: 'Collected', id: 2},
{text: 'AtHub', id: 3},
{text: 'InTransit', id: 4},
{text: 'OutForDelivery', id: 5},
{text: 'FailedAttempt', id: 6},
{text: 'Delivered', id: 7},
{text: 'OnHold', id: 8},
{text: 'AddressIssue', id: 9},
{text: 'ReturnedToSender', id: 10},
{text: 'TrackingExpired', id: 11},
{text: 'Cancelled', id: 12},
{text: 'AwaitingCustomerCollection', id: 13}];

export { shippingStatuses };