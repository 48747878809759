<script type="text/javascript">
	export default{
		props: {
			link_route: {
				type: String,
				default: '',
			}
		},
		data(){
			return {
				storageurl: 'https://'+this.$attrs.company.nickname+'.s3.eu-west-2.amazonaws.com/',
				hoverIndex: -1,
				hoverLink: -1,
				categories: []
			}
		},
	}
</script>
<template>
	<router-link v-if="link_route == ''" :to="'/'+($route.path.substring(1).split('/')[0]).replace('ies', 'y').replace('collections', 'collection')+'/'+$attrs.category.slug">
					<b-card id="category-collection" no-body class="border shadow-none border-radius-1em" @mouseenter="hoverIndex = index" @mouseleave="hoverIndex = -1" >
						<b-card-img v-if="$attrs.category.image" :src="storageurl + $attrs.category.image" />
						<b-card-img v-else :src="'/images/product-placeholder.png'" />
						<b-card-text class="p-4 peach-border-top-5 d-flex">
							<h2 class="font-size-18 my-auto py-2">{{$attrs.category.name}}</h2>
						<transition name="fade-right">
							<router-link v-if="hoverIndex == index" class="border-bottom border-primary text-uppercase font-size-12 ml-auto text-dark my-auto py-2" :to="'/'+($route.path.substring(1).split('/')[0]).replace('ies', 'y').replace('collections', 'collection')+'/'+$attrs.category.slug">
								See Products <i class="bx bx-chevron-right"></i>
							</router-link>
						</transition>
						</b-card-text>
					</b-card>
				</router-link>
				<router-link v-else-if="link_route !== ''" :to="link_route+$attrs.category.slug">
					<b-card id="category-collection" no-body class="border shadow-none border-radius-1em" @mouseenter="hoverIndex = index" @mouseleave="hoverIndex = -1" >
						<b-card-img v-if="$attrs.category.image" :src="storageurl + $attrs.category.image" />
						<b-card-img v-else :src="'/images/product-placeholder.png'" />
						<b-card-text class="p-4 peach-border-top-5 d-flex">
							<h2 class="font-size-18 my-auto py-2">{{$attrs.category.name}}</h2>
						<transition name="fade-right">
							<router-link v-if="hoverIndex == index" class="border-bottom border-primary text-uppercase font-size-12 ml-auto text-dark my-auto py-2" :to="link_route+$attrs.category.slug">
								See Products <i class="bx bx-chevron-right"></i>
							</router-link>
						</transition>
						</b-card-text>
					</b-card>
				</router-link>
</template>