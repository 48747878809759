<template>
	<div>
		<b-row>
				<b-col md="12" class="px-4 pt-4 pb-1 d-flex">
					<a class="btn btn-outline-light btn-rounded ml-auto my-auto mr-3" @click="closeFilters()">
						<i class="mdi mdi-close font-size-20"></i>
					</a>
				</b-col>
				<b-col md="12" class="p-4">
					<div class="px-3">
					<div class="mt-sm-0 form-inline w-100" >
              <div class="app-search search-box mr-2 ml-auto">
                <div class="position-relative">
                  <input
                    type="text"
                    class="form-control w-100 border"
                    placeholder="Search name, reference or description..."
                    v-model="$attrs.filters.search.value"
                    @keyup.enter="loadCategory()"
                  />
                  <span class="bx bx-search-alt search-icon"></span>
                </div>
              </div>
            </div>
           <div class="card p-4 rounded-4 shadow-sm border-radius-1em mt-4">
            <!--<h4 class="card-title mb-4">Filter</h4>-->
            <div>
               <div class="mb-3">
              <h5 class="mb-3">Price</h5>
              <vue-slider v-model="$attrs.priceRange" :min="0" :max="100 + $attrs.priceRange[1]"  @drag-end="loadCategory()" ></vue-slider>
              <b-form-group label="Min Price" class="w-50 d-inline float-left mt-2 mb-3 px-2">
              <b-form-input
                type="number"
                v-model="$attrs.priceRange[0]"
                @input="loadCategory"></b-form-input>
              </b-form-group>
              <b-form-group label="Max Price" class="w-50 d-inline float-left mt-2 mb-3 px-2">
                 <b-form-input
                 label="Max Price"
                 type="number"
                 v-model="$attrs.priceRange[1]"
                 @input="loadCategory"></b-form-input>
              </b-form-group>
            </div>
                <h5 class="my-3">Jewellery Type</h5>
                <!--<FilterListTree></FilterListTree>-->
                <b-overlay :show="$attrs.loadingFilters">
                <FilterPublicProductCategories  v-if="$attrs.loadingFilters == false" :category_data="$attrs.categoriesdata" ref="categoryfilter"></FilterPublicProductCategories>
              </b-overlay>
                <h5 class="my-3">Brands</h5>
               <ProductFiltersBrands v-on:brands-filtered="filterBrands"></ProductFiltersBrands>
                <h5 class="my-3">Gemstone</h5>
                <b-form-group>
                	<b-form-checkbox-group v-model="$attrs.productGemstones" @change="loadCategory()" :options="$attrs.productGemstones"></b-form-checkbox-group>
                </b-form-group>
                <h5 class="my-3">Metal</h5>
                <b-form-group>
                	<b-form-checkbox-group v-model="$attrs.productMetals"  @change="loadCategory()" :options="$attrs.productMetals"></b-form-checkbox-group>
                </b-form-group>
                <h5 class="my-3">Condition</h5>
                <b-form-group>
                	<b-form-checkbox-group v-model="$attrs.productConditions"  @change="loadCategory()" :options="$attrs.productConditions"></b-form-checkbox-group>
                </b-form-group>
            </div>
           

          <!-- <div class="mt-4 pt-3">
              <h5 class="font-size-14 mb-3">Customer Rating</h5>
              <div>
                <b-form-checkbox
                  id="checkbox-1"
                  name="checkbox-1"
                  value="accepted"
                  unchecked-value="not_accepted"
                >
                  4
                  <i class="bx bx-star text-warning"></i> & Above
                </b-form-checkbox>

                <b-form-checkbox
                  id="checkbox-2"
                  class="mt-2"
                  name="checkbox-2"
                  value="accepted"
                  unchecked-value="not_accepted"
                >
                  3
                  <i class="bx bx-star text-warning"></i> & Above
                </b-form-checkbox>

                <b-form-checkbox
                  id="checkbox-3"
                  class="mt-2"
                  name="checkbox-3"
                  value="accepted"
                  unchecked-value="not_accepted"
                >
                  2
                  <i class="bx bx-star text-warning"></i> & Above
                </b-form-checkbox>
                <b-form-checkbox
                  id="checkbox-4"
                  class="mt-2"
                  name="checkbox-4"
                  value="accepted"
                  unchecked-value="not_accepted"
                >
                  1
                  <i class="bx bx-star text-warning"></i>
                </b-form-checkbox>
              </div>
            </div>-->
          </div>
          </div>
				</b-col>
			</b-row>
	</div>
</template>

<script type="text/javascript">
  import ProductFiltersBrands from "./ProductFilters-Brands"
	
  export default{
    props: {
      showFilters: {
        type: Boolean,
        default: false,
      }
    },
		data(){
			return{
        priceRange: [0,250000],
            brands: [],
            collections: [],
            categories: [],
            keywordslist: [],
            productConditions: [
                'New',
                'Bespoke',
                'Used',
                'Antique',
                'Refurbished',
                'Custom',
            ],
            productMetals: [
                'Gold',
                'Rose Gold',
                'White Gold',
                'Silver',
                'Platinum',
                'Palladium',
            ],
            productCarats: [
                '9',
                '18',
                '24',
            ],
            productGemstones: [
                'Aquamarine',
                'Diamond',
                'Emerald',
                'Garnet',
                'Opal',
                'Pearl',
                'Ruby',
                'Sapphire',
                'Tsavorite',
                'Topaz',
                'Tanzonite',
            ],
            sortby: 'created_at',
            order: 'desc',
            search: '',
			}
		},
		methods: {
      closeFilters(){
        this.$emit('closeFilters')
      },
			loadCategory(){
				this.emit('get-products');
			},
      filterBrands(value){
          this.brands = value;
          //this.createQueryString();
          this.loadCategory();
        },
      filterSelectedCategories(id){
            
              var exists = this.categories.findIndex(item => item == id);
              console.log(exists);
              if(exists == -1){
                this.categories.push(id);
              }else{
                this.categories.splice(exists, 1);
              }
              //this.createQueryString();
              this.loadCategory();
          },
		}
	}

</script>