<script type="text/javascript">
	export default{
		data(){
			return{
				loadingForm: false,
				enquiryResponse: '',
				error_reponse: '',
			}
		},
		props: {
			company: {
				type: Object
			}
		},
		mounted(){
			this.recaptcha();
		},
		methods: {
			async recaptcha() {
     			 // (optional) Wait until recaptcha has been loaded.
		      await this.$recaptchaLoaded()

		      // Execute reCAPTCHA with action "login".
		      const token = await this.$recaptcha('login');
		      document.getElementById('recaptcha').value = token;
		      // Do stuff with the received token.
		    
		    },
			sendEnquiry(){
				
				//var notspam = this.recaptcha();
				this.loadingForm = true;
				var postForm = new FormData(document.getElementById("contact-form"));
				axios.post('/website-api/widget/contact', postForm).then(response => {
					this.enquiryResponse = response.data;
					let self = this
					setTimeout(function() {
						self.loadingForm = false;
					}, 1500);
				}).catch(error => {
					this.error_reponse = error.response.data.errors;
					this.error_message = true;
					let self = this
					setTimeout(function() {
						self.loadingForm = false;
					}, 1500);
				});
			},
			/*
			processEnquiry(){
				let self = this;
				axios.all([self.recaptcha()]).then(axios.spread(function (response) {
					self.sendEnquiry();
					})
				)
			}*/
		}
	}
</script>
<template>
	<b-container fluid class="py-4 default-content contact">
		<b-row class="my-4 py-4">
			<transition name="slide-error" mode="out-in">
                    <b-alert id="errors" variant="danger" :show="error_message == true"  dismissible @dismissed="error_message = !error_message">
                      <p class="font-size-14">Oops! Please correct the following errors in your submission</p>
                      <ul>
                        <li v-for="(error, key) in error_reponse">
                          {{error[0]}}
                        </li>
                      </ul>
                      <small>Please Try Again. If this error persists, please contact support.</small>
                    </b-alert>
                </transition>
			<b-col md="12">
				<h1>Contact Us</h1>
					<p>Have questions? Get in touch with the team at {{company.name}}.</p>
			</b-col>
			<b-col>	
			<h2>Contact Information</h2>	
				<b-list-group flush>
					<b-list-group-item>
						Address:
					</b-list-group-item>
					<b-list-group-item>
						Telephone:
					</b-list-group-item>
					<b-list-group-item>
						Email:
					</b-list-group-item>
				</b-list-group>

				<h2>Visit Our Store</h2>
				
			</b-col>
			<b-col>
				<b-card class="border-radius-1em shadow-none">
					<b-overlay
					:show="loadingForm"
					>
					<form id="contact-form" method="POST" enctype="multipart/form-data">
						<input id="recaptcha" type="hidden" name="recaptcha" />
						<b-form-group class="mt-2" label="Name">
							<b-form-input name="name" type="text"></b-form-input>
						</b-form-group>
						<b-form-group class="mt-2" label="Email">
							<b-form-input name="email" type="text"></b-form-input>
						</b-form-group>
						<b-form-group class="mt-2" label="Message">
							<b-form-textarea type="text" name="message" rows="6"></b-form-textarea>
						</b-form-group>
						<a class="btn btn-peach w-100 font-size-18 btn-rounded mt-3" @click="sendEnquiry()">
							Submit
						</a>
					</form>
					</b-overlay>
				</b-card>
			</b-col>
		</b-row>
	</b-container>
</template>