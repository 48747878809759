<script>
import VueSlideBar from "vue-slide-bar";
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
//import FilterListTree from "../../views/stock/product/category/_partials/FilterListTree";
import SelectCompanyCategoryTree from "../../components/widgets/SelectCompanyCategoryTree";
import FilterListTree from "../../components/widgets/FilterListTree";
import FilterProductBrands from "../../components/widgets/FilterProductBrands";
import FilterProductCollections from "../../components/widgets/FilterProductCollections";
import SideSlidePanel from "../../components/widgets/SideSlidePanel";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import vue2Dropzone from 'vue2-dropzone';
/**
 * Products component
 */
export default {
  components: { vueDropzone: vue2Dropzone, VueSlideBar, Layout, PageHeader, FilterListTree, FilterProductBrands, FilterProductCollections, SideSlidePanel, ckeditor: CKEditor.component, SelectCompanyCategoryTree },
  data() {
    return {
      storageurl: 'https://'+this.$attrs.company.nickname+'.s3.eu-west-2.amazonaws.com',
      productsData: '',
      title: "Services",
      items: [
        {
          text: "Ecommerce",
          href: "/",
        },
        {
          text: "Services",
          active: true,
        },
      ],
      floatImage: '',
      imageOptions: '',
      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        thumbnailHeight: 150,
        thumbnailWidth: 150,
        autoProcessQueue: false,
      },
      search: '',
      periods: ['Hour(s)','Day(s)','Week(s)','Month(s)','Year(s)'],
      numbers: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30],
      showFilters: false,
      editor: ClassicEditor,
      sliderPrice: 800,
      error_message: false,
      error_reponse: '',
      loadingForm: false,
      priceRange: [0,999999],
      currentPage: 1,
      limit: 25,
      selectedids: [],
      success_text: "Your action has been successful",
      success_message: false,
      orderDesc: true,
      addNewPanel: false,
      addPanelClass: 'edit-sidepanel-large',
      order: 'desc',
      sortby: 'id',
      trade_active: '',
      public_active: '',
      brands: '',
      collections: '',
      selectedids: [],
      tax_rules: [],
      banner_image: '',
      image: '',
      newservice: {
            uid: '',
            reference: '',
            name: '',
            slug: '',
            short_description: '',
            image: '',
            banner_image: '',
            company_service_category_id: 0,
            company_service_type_id: 1,
            isowner: 0,
            tax_rule: '',
            price_exc: parseFloat(0.00).toFixed(2),
            price_inc: parseFloat(0.00).toFixed(2),
            meta_description: '',
            meta_title: '',
            active: false,
            trade_active: false,
            public_active: false,
            allow_bookings: false,
            set_duration: false,
            duration: 1, 
            duration_units: '',
            max_units: 0,
            service_deposit: 0.00,
            ownercontact: {
              name: '',
              email: '',
              phone: '',
              address1: '',
              address2: '',
              address3: '',
              country_id: '',
              county_id: '',
              postcode: '',

            }
          },
      isCheckAll: false,
      active: '',
      view: 'list',
      fields: [
        { key: "id", sortable: true },
        { key: "name", sortable: true },
        ],
      filter: null,
      filterOn: [],
      service_categories: [],
      service_type: [],
      service_frequencies: [],
      limits: [
        {value: 25, text: "25"},
        {value: 50, text: "50"},
        {value: 100, text: "100"},
      ],
      discountRates: [],
    };
  },
  methods: {
    coverImageAdded(file){
      this.banner_image = file;
    },
    imageAdded(file){
      this.image = file;
    },
    valuechange(value) {
      this.clothsData = clothsData.filter(function (product) {
        return product.newprice <= value.currentValue;
      });
    },
    getAllTypes(){
      axios.get('/api/company/service/types', {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
              }}).then(response => {
        this.service_types = response.data;
      }).catch(error => {
        this.brands_error = error.data;
      });
    },
    getAllFrequencies(){
      axios.get('/api/company/service/frequencies', {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
              }}).then(response => {
        this.service_frequencies = response.data;
      }).catch(error => {
        this.brands_error = error.data;
      });
    },
    getAllCategories(){
      axios.get('/api/company/service/categories?page='+this.currentPage+'&limit='+this.limit+'&sortby='+this.sortby+'&order='+this.order+'&search='+this.search, {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
              }}).then(response => {
        this.service_categories = response.data;
      }).catch(error => {
        this.brands_error = error.data;
      });
    },
    searchFilter(e) {
      const searchStr = e.target.value;
      this.productQuery();
    },
    addCategory(id){
      this.newservice.company_service_category_id = id;

    },
    filterBrands(value){
      this.brands = value;
      this.productQuery();
    },

     filterCollections(value){
      this.collections = value;
      this.productQuery();
    },

    discountLessFilter(e, percentage) {
      if (e === "accepted" && this.discountRates.length === 0) {
        this.clothsData = clothsData.filter((product) => {
          return product.discount < percentage;
        });
      } else {
        this.clothsData = clothsData.filter((product) => {
          return product.discount >= Math.max.apply(null, this);
        }, this.discountRates);
      }
    },

    discountMoreFilter(e, percentage) {
      if (e === "accepted") {
        this.discountRates.push(percentage);
      } else {
        this.discountRates.splice(this.discountRates.indexOf(percentage), 1);
      }
      this.clothsData = clothsData.filter((product) => {
        return product.discount >= Math.max.apply(null, this);
      }, this.discountRates);
    },
     productQuery(){
      if(this.productsData != ''){
        if(this.limit > this.productsData.total){
          this.currentPage = 1;
        }
      }
      if(this.orderDesc == true){
        this.order = 'desc';
      }else{
        this.order = 'asc';
      }
      axios.get('/api/company/services?page='+this.currentPage+'&limit='+this.limit+'&sortby='+this.sortby+'&order='+this.order+'&search='+this.search+'&trade_active='+this.trade_active+'&public_active='+this.public_active+'&active='+this.active+'&brands='+this.brands+'&collections='+this.collections+'&pricerange='+this.priceRange, {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
        console.log(response);
        this.productsData = response.data;
      });
    },
    addService(){
      this.loadingForm = true;
      var postForm = new FormData(document.getElementById("addserviceform"));
      var query = '/api/company/service/create';
      if(this.newservice.uid !== ''){
        query = '/api/company/service/'+this.newservice.uid+'/update';
      }
      if(this.image !== ''){
      postForm.append('image', this.image);
      }
      if(this.cover_image !== ''){
      postForm.append('cover_image', this.cover_image);
      }
      axios.post(query, postForm, {headers : { 'Authorization' : 'Bearer '+this.$attrs.user.api_token }}).then(response => {
        console.log(response);
        this.success_message = true;
        //this.resetProjectData();
        this.addNewPanel = false;
        let self = this;
          setTimeout(function(){
            self.loadingForm = false;
            self.image = '';
            self.banner_image = '';
          }, 500);
      }).catch( error => {
        
        this.error_reponse = error.response.data.errors;
        this.error_message = true;
        //this.resetProjectData();
        let self = this;
          setTimeout(function(){
            self.loadingForm = false;
          }, 500);
      });
    },
     filterSelected(id){
        if(this.selectedids.length > 0){
          var exists = this.selectedids.filter(item => item == id);
          if(exists.length > 0){
            return true;
          }else{
            return false
          }
        }else{
          return false;
        }
      },
      checkAll: function(){ 
                 this.isCheckAll = !this.isCheckAll; 
                 this.selectedids = []; 
                 if(this.isCheckAll){ 
                     // Check all 
                     for (var key in this.productsData.data) {          
                        this.selectedids.push(this.productsData.data[key].id); 
                     } 
                 } 
              }, 
      updateCheckall: function(){ 
                 if(this.selectedids.length == this.productsData.data.length){
                    this.isCheckAll = true; 
                 }else{ 
                    this.isCheckAll = false; 
                 } 
      },
      getDate(date){
        var createdOn = DateTime.fromISO(date, { zone: 'utc'});
        console.log(createdOn);
        return createdOn.day+' '+createdOn.monthLong+' '+createdOn.weekYear+'';
      },
      filterSelected(id){
        if(this.selectedids.length > 0){
          var exists = this.selectedids.filter(item => item == id);
          if(exists.length > 0){
            return true;
          }else{
            return false
          }
        }else{
          return false;
        }
      },
    getTaxRules(){
      axios.get('/api/company/order/tax-rules?limit=0&sortby='+this.sortby+'&order='+this.order, {headers: {
        'Authorization' : 'Bearer '+this.$attrs.user.api_token
      }}).then(response => {
        console.log(response);
        this.tax_rules = response.data;
      }).catch(error => {
        this.error_reponse = {error: ["Could Not Load Tax Rules"]};
        this.error_message = true;
      });
    },
    removeVatCalc(tax_percentage){
      console.log(tax_percentage);
            var setMultiplier = JSON.parse(tax_percentage) / 100;
            var multiplier = parseFloat(setMultiplier) + 1;
            var calculation = parseFloat(this.newservice.price_inc) / parseFloat(multiplier);
            this.newservice.price_exc = calculation.toFixed(2);
            this.newservice.price_inc = parseFloat(this.newservice.price_inc).toFloat(2);
    },
    removeTax(price, rule){
            var tax_rule = rule;
            console.log(tax_rule);
            var country_id = this.$attrs.company.country_id;
       
          if(tax_rule !== null && tax_rule.country_rules.length > 0){
            var selected_rule = null;
            var selected_ruleIndex = -1;
            for(var ti = 0; ti < tax_rule.country_rules.length; ti++){
              selected_ruleIndex = tax_rule.country_rules[ti].countries.findIndex(item => item == country_id);
              if(selected_ruleIndex !== -1){
                selected_rule = tax_rule.country_rules[ti];
                ti = tax_rule.country_rules.length;
              }
            }
            console.log(selected_rule);
            if(tax_rule.type == 1){
                if(selected_rule !== null){
                  var percentage = (parseFloat(selected_rule.value) / 100) + 1;
                  this.active_tax_percentage = percentage;
                  return price / percentage;
                }else{
                  return price;
                }
              }else{
                if(selected_rule !== null){
                  this.active_tax_percentage = selected_rule.value;
                  return price - selected_rule.value;
                }else{
                  return price;
                }
              }
            }else if(tax_rule !== null && tax_rule.country_rules.length > 0){
              var percentage = (parseFloat(tax_rule.percentage) / 100) + 1;
              return price / percentage;
            }else {
              return price;
            }
          },
    addTax(price, rule){
            var tax_rule = rule;
            console.log(tax_rule);
            var country_id = this.$attrs.company.country_id;
       
          if(tax_rule !== null && tax_rule.country_rules.length > 0){
            var selected_rule = null;
            var selected_ruleIndex = -1;
            for(var ti = 0; ti < tax_rule.country_rules.length; ti++){
              selected_ruleIndex = tax_rule.country_rules[ti].countries.findIndex(item => item == country_id);
              if(selected_ruleIndex !== -1){
                selected_rule = tax_rule.country_rules[ti];
                ti = tax_rule.country_rules.length;
              }
            }
            console.log(selected_rule);
            if(tax_rule.type == 1){
                if(selected_rule !== null){
                  var percentage = (parseFloat(selected_rule.value) / 100) + 1;
                  this.active_tax_percentage = percentage;
                  return price * percentage;
                }else{
                  return price;
                }
              }else{
                if(selected_rule !== null){
                  this.active_tax_percentage = selected_rule.value;
                  return price + selected_rule.value;
                }else{
                  return price;
                }
              }
            }else if(tax_rule !== null && tax_rule.country_rules.length > 0){
              var percentage = (parseFloat(tax_rule.percentage) / 100) + 1;
              return price * percentage;
            }else {
              return price;
            }
          },

    addVatCalc(tax_percentage){
      console.log(tax_percentage);
      this.newservice.price_inc = (parseFloat(this.newservice.price_exc) + ((parseFloat(this.newservice.price_exc) / 100.00) * JSON.parse(tax_percentage))).toFixed(2);
      this.newservice.price_exc = parseFloat(this.newservice.price_exc).toFixed(2);
    },
      checkAll: function(){ 
                 this.isCheckAll = !this.isCheckAll; 
                 this.selectedids = []; 
                 if(this.isCheckAll){ 
                     // Check all 
                     for (var key in this.shopsData.data) {          
                        this.selectedids.push(this.shopsData.data[key].id); 
                     } 
                 } 
              }, 
      updateCheckall: function(){ 
                 if(this.selectedids.length == this.shopsData.data.length){
                    this.isCheckAll = true; 
                 }else{ 
                    this.isCheckAll = false; 
                 } 
      },
       setActive(active, area){
            axios.post('/api/company/service/set-active', {ids: this.selectedids, active: active, market: area}, {headers: {
              'Authorization' : 'Bearer '+this.$attrs.user.api_token
            }}).then(response => {
              
              this.success_text = 'Congratulations, your action has been saved';
              this.success_message = true;
              if(this.selectedids.length > 1){
                return this.productQuery();
              }else{
                return true;
              }
                
            }).catch(error => {

            });
    },
      sanitizeTitle: function(title) {
            var nickname = "";
            // Change to lower case
            var titleLower = title.toLowerCase();
            // Letter "e"
            nickname = titleLower.replace(/e|é|è|ẽ|ẻ|ẹ|ê|ế|ề|ễ|ể|ệ/gi, 'e');

            nickname = nickname.replace(/'|'/gi, '');
            // Letter "a"
            nickname = nickname.replace(/a|á|à|ã|ả|ạ|ă|ắ|ằ|ẵ|ẳ|ặ|â|ấ|ầ|ẫ|ẩ|ậ/gi, 'a');
            // Letter "o"
            nickname = nickname.replace(/o|ó|ò|õ|ỏ|ọ|ô|ố|ồ|ỗ|ổ|ộ|ơ|ớ|ờ|ỡ|ở|ợ/gi, 'o');
            // Letter "u"
            nickname = nickname.replace(/u|ú|ù|ũ|ủ|ụ|ư|ứ|ừ|ữ|ử|ự/gi, 'u');
            // Letter "d"
            nickname = nickname.replace(/đ/gi, 'd');
            // Trim the last whitespace
            nickname = nickname.replace(/\s*$/g, '');
            // Change whitespace to "-"
            nickname = nickname.replace(/\s+/g, '-');
            
            return nickname;
        }
  },
  beforeMount(){
      this.productQuery();
      this.getAllCategories();
      this.getTaxRules();
      this.getAllTypes();
      this.getAllFrequencies();
  },
  computed:{
   
  }
};
</script>

<template>
  <Layout :nickname="$attrs.nickname" :user="$attrs.user" :menuItems="$attrs.menuItems">
    <PageHeader :title="title" :items="items" />
    <div class="row">
       <b-col md="12">
                    <transition name="slide-success" mode="out-in">
                  <b-alert id="success-message" variant="success" :show="success_message"  dismissible @dismissed="success_message = !success_message">
                      <p class="font-size-14">{{success_text}}</p>
                    </b-alert>
                  </transition>
                  </b-col>
      <div :class="{'col-lg-12': showFilters == true, 'col-lg-12': showFilters == false}">
        <div class="row mb-3">
          <div class="col-xl-1 col-sm-6">
            <div class="mt-2">
              <b-form-select class="form-control" v-model="limit" :options="limits" @input="productQuery()"></b-form-select>
            </div>
          </div>
         
          <div class="col-lg-2 col-sm-6 d-flex">
          <form class="my-auto">
            <small class="mx-auto my-2 text-center d-block">Results {{productsData.from}} - {{productsData.to}} of {{productsData.total}}</small>
          </form>
        </div>
        <div class="col-lg-5 col-sm-6 d-flex">
            <div class="mt-sm-0 form-inline w-100" >
              <div class="search-box mr-2 ml-auto">
                <div class="position-relative">
                  <input
                    type="text"
                    class="form-control w-100 border-0"
                    placeholder="Search name, reference or description..."
                    v-model="search"
                    @keyup.enter="searchFilter($event)"
                  />
                  <i class="bx bx-search-alt search-icon"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-sm-6 d-flex">
            <div class="d-flex ml-auto">
              <ul class="nav nav-pills product-view-nav">
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': showFilters == true }" href="javascript: void(0);" @click="showFilters = !showFilters">
                    <i class="bx bx-filter-alt"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': view == 'grid' }" href="javascript: void(0);" @click="view = 'grid'">
                    <i class="bx bx-grid-alt"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': view == 'list' }" href="javascript: void(0);" @click="view = 'list'">
                    <i class="bx bx-list-ul"></i>
                  </a>
                </li>
                <li class="nav-item">
                 <b-dropdown text="Bulk Actions" variant="primary" class="rounded">
                   <b-dropdown-text class="list-group list-group-flush" text-class="list-group-item mb-0" @mouseenter="showActiveOn = 'active'" @mouseleave="showActiveOn = -1">

                      <i class="bx bx-check-circle font-size-14 text-success">
                        </i> Set Active On:
                   <transition name="fade-right">
                        <b-list-group v-if="showActiveOn == 'active'" flush class="pages-submenu hide" style="position: absolute; left: -100%; top: 0; width: 100%" >
                           <b-list-group-item :disabled="($attrs.user.companies[0].userrole.name !== ('Owner' || 'Manager')) && !$attrs.user.companies[0].permissions.edit.includes($attrs.active_menu.id)" href="javascript: void(0);" @click="setActive(true, 'active')">Shops</b-list-group-item>
                            <b-list-group-item :disabled="($attrs.user.companies[0].userrole.name !== ('Owner' || 'Manager')) && !$attrs.user.companies[0].permissions.edit.includes($attrs.active_menu.id)" href="javascript: void(0);" @click="setActive(true, 'public')">Public Market</b-list-group-item>
                            <b-list-group-item :disabled="($attrs.user.companies[0].userrole.name !== ('Owner' || 'Manager')) && !$attrs.user.companies[0].permissions.edit.includes($attrs.active_menu.id)" href="javascript: void(0);" @click="setActive(true, 'trade')">Trade Market</b-list-group-item>
                        </b-list-group>
                      </transition>
                    </b-dropdown-text>
                    <b-dropdown-text class="list-group list-group-flush" text-class="list-group-item mb-0" @mouseenter="showActiveOn = 'inactive'" @mouseleave="showActiveOn = -1">

                      <i class="bx bx-x-circle font-size-14 text-danger">
                        </i> Set Inactive On:
                   <transition name="fade-right">
                        <b-list-group v-if="showActiveOn == 'inactive'" flush class="pages-submenu hide" style="position: absolute; left: -100%; top: 0; width: 100%" >
                           <b-list-group-item :disabled="($attrs.user.companies[0].userrole.name !== ('Owner' || 'Manager')) && !$attrs.user.companies[0].permissions.edit.includes($attrs.active_menu.id)" href="javascript: void(0);" @click="setActive(false, 'active')">Shops</b-list-group-item>
                            <b-list-group-item :disabled="($attrs.user.companies[0].userrole.name !== ('Owner' || 'Manager')) && !$attrs.user.companies[0].permissions.edit.includes($attrs.active_menu.id)" href="javascript: void(0);"  @click="setActive(false, 'public')">Public Market</b-list-group-item>
                            <b-list-group-item :disabled="($attrs.user.companies[0].userrole.name !== ('Owner' || 'Manager')) && !$attrs.user.companies[0].permissions.edit.includes($attrs.active_menu.id)" href="javascript: void(0);"  @click="setActive(false, 'trade')">Trade Market</b-list-group-item>
                        </b-list-group>
                      </transition>
                    </b-dropdown-text>
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-item href="javascript: void(0);" disabled><i class="bx bx-copy-alt font-size-14"></i> Duplicate</b-dropdown-item>
                  <b-dropdown-item :disabled="($attrs.user.companies[0].userrole.name !== ('Owner' || 'Manager')) && !$attrs.user.companies[0].permissions.edit.includes($attrs.active_menu.id)" href="javascript: void(0);" v-b-modal.move-modal @click="all_categories = '', categories_type = 'company', getAllCategories(), moveMode = 'bulk'"><i class="bx bx-move"></i> Move Category</b-dropdown-item>
                  <b-dropdown-item href="javascript: void(0);" v-b-modal.print-label @click="setPrintMode()"><i class="bx bx-printer font-size-14"></i> Print Labels</b-dropdown-item>
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-item href="javascript: void(0);" v-b-modal.delete-modal @click="deleteMode = 'bulk'" :disabled="($attrs.user.companies[0].userrole.name !== ('Owner' || 'Manager')) && !$attrs.user.companies[0].permissions.delete.includes($attrs.active_menu.id)"><i class="bx bx-trash-alt font-size-14"></i> Delete</b-dropdown-item>
                </b-dropdown>
                </li>
                <li class="nav-item">
                 <b-button variant="success"
                    class="btn btn-success btn-rounded mb-2 mr-2 text-white"
                    href="/service/details/add"
                    :disabled="($attrs.user.companies[0].userrole.name !== 'Owner') && !$attrs.user.companies[0].permissions.add.includes($attrs.active_menu.id)"
                  >
                    <i class="mdi mdi-plus mr-1"></i> New
                  </b-button>
                </li>
              </ul>
              <div class="text-sm-right">
                  
                </div>
          </div>
        </div>
      </div>
        <!--<div class="row">
          <div class="col-lg-12">
            <b-pagination
              v-if="productsData.data.length > 0"
              class="justify-content-center mb-0"
              pills
              v-model="currentPage"
              :total-rows="productsData.total"
              :per-page="productsData.per_page"
              aria-controls="my-table"
              @input="productQuery"
            ></b-pagination>
             <small class="mx-auto my-2 text-center d-block">Records {{productsData.from}} - {{productsData.to}} of {{productsData.total}} // Page {{productsData.current_page}} of {{productsData.last_page}}</small>
          </div>
        </div>-->
         <div  class="d-flex flex-column justify-content-center h-100 restricted-col" v-if="($attrs.user.companies[0].userrole.name !== 'Owner') && (!$attrs.user.companies[0].permissions.read.includes($attrs.active_menu.id))">
          <AccessRestricted></AccessRestricted>
        </div>
        <div v-else>
        <div class="row">
          <div id="my-table" v-if="view == 'grid'" v-for="data in productsData.data" :key="data.id" class="col-xl-4 col-sm-6">
            <div class="card">
              <div class="card-body">
                <div class="product-img position-relative">
                  <div v-if="data.discount" class="avatar-sm product-ribbon">
                    <span class="avatar-title rounded-circle bg-primary">-{{data.discount}}%</span>
                  </div>
                  <a :href="`/shop/product/detail/${data.id}`">
                    <img :src="`/public/storage/${data.banner_image}`" alt class="img-fluid mx-auto d-block" />
                  </a>
                </div>
                <div class="mt-4 text-center">
                  <h5 class="mb-3 text-truncate">
                    <a
                      class="text-dark"
                      :href="`/ecommerce/product-detail/${data.id}`"
                    >{{data.name}}</a>
                  </h5>
                  <p class="text-muted">
                    <i class="bx bx-star text-warning"></i>
                    <i class="bx bx-star text-warning"></i>
                    <i class="bx bx-star text-warning"></i>
                    <i class="bx bx-star text-warning"></i>
                    <i class="bx bx-star text-warning"></i>
                  </p>
                  <h5 class="my-0">
                    <span class="text-muted mr-2">
                      <del>${{data.oldprice}}</del>
                    </span>
                    <b>${{data.price}}</b>
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end row -->

        
        <div class="card" v-if="view == 'list'" >
          <div class="card-body p-0">
            <div class="table-responsive">
              <table class="table table-centered table-hover">
                <thead class="thead-light">
                  <tr>
                   <th scope="col"  style="width: 100px" class="text-center">
                     <div
                        class="form-check form-switch form-switch-sm mb-0 mx-auto p-0 mt-auto d-flex"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input mx-auto my-auto" 
                          type="checkbox"
                          id="SwitchCheckSizesm"
                         
                          v-model="isCheckAll"
                          @click='checkAll()'
                          name="active"
                        />
                    </div>
                  </th>
                    <th scope="col"><a :class="{'text-dark': sortby != 'reference'}" href="javascript: void(0);" @click="orderDesc = !orderDesc, sortby = 'reference', productQuery()">SKU <i v-if="orderDesc == true" class="bx bx-caret-up"></i><i v-if="orderDesc == false" class="bx bx-caret-down"></i></a></th>
                    <th scope="col" style="max-width: 300px"><a :class="{'text-dark': sortby != 'name'}" href="javascript: void(0);" @click="orderDesc = !orderDesc,  sortby = 'name', productQuery()">Name & Description <i v-if="orderDesc == true" class="bx bx-caret-up"></i><i v-if="orderDesc == false" class="bx bx-caret-down"></i></a></th>
                    
                    <th scope="col">Categories</th>
                      <th scope="col"><a :class="{'text-dark': sortby != 'sales_price'}" href="javascript: void(0);" @click="orderDesc = !orderDesc,  sortby = 'sales_price', productQuery()">Price <i v-if="orderDesc == true" class="bx bx-caret-up"></i><i v-if="orderDesc == false" class="bx bx-caret-down"></i></a></th>
                   
                    <th scope="col">Status</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="list in productsData.data" :key="list.id" valign="middle">
                    <td>
                      <div class="text-center" v-if="list.cover_iamge">
                      <b-form-checkbox class="select-row-btn" v-model="selectedids" :value="list.id" button-variant="primary" @input="updateSelected" button>
                        <i v-if="filterSelected(list.id)" class="bx bx-check"></i>
                        <img v-else :src="`https://gemesys-dev.s3.eu-west-2.amazonaws.com/${list.cover_iamge}`" alt class="avatar-md rounded-circle" />
                      </b-form-checkbox>
                    </div>
                    <div class="text-center" v-else >
                        <b-form-checkbox class="select-row-btn" v-model="selectedids" :value="list.id" button-variant="light" @input="updateSelected" button>

                          <i v-if="filterSelected(list.id)" class="bx bx-check"></i><span class="avatar-title rounded-circle" v-else>{{(list.name).slice(0,1)}}</span></b-form-checkbox>
                    </div> 

                    </td>
                    <td>
                     <a :href="`/service/details/${list.uid}`" class="mb-0 text-primary">#{{list.reference}}</a>
                    </td>
                    <td style="max-width: 300px">
                        <a :href="`/service/details/${list.uid}`" class="mb-0"><p class="mb-0">{{list.name}}</p></a>
                        <p class="d-md-none d-lg-block mb-0 paragraph-truncate-line2 with-v-html" v-html="list.short_description"></p>
                    </td>
                    <td>
                      <div>
                        <a
                          v-if="list.main_category !== null"
                          href="javascript: void(0);"
                          class="badge bg-light p-2 border-radius-1em font-size-11 m-1"
                        >{{list.main_category.name}}</a>
                        <a v-for="category in list.categories"
                          href="javascript: void(0);"
                          class="badge bg-light p-2 border-radius-1em font-size-11 m-1"
                        >{{category.category.name}}</a>
                      </div>
                    </td>
                    <td> <a href="javascript: void(0);"
                          class="badge bg-light p-2 border-radius-1em font-size-11 m-1" v-if="list.price_exc !== null"
                        >{{parseFloat(list.price_exc).toFixed(2)}} exc. tax</a><br><a href="javascript: void(0);"
                          class="badge bg-light p-2 border-radius-1em font-size-11 m-1" v-if="list.price_inc !== null"
                        >{{parseFloat(list.price_inc).toFixed(2)}} inc. tax</a>
                    </td>
                    <td>
                      <div
                        class="form-check form-switch form-switch-sm mb-0 mt-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          id="SwitchCheckSizesm"
                          v-model="list.active"
                          value="1"
                          unchecked-value="0"
                          name="active"
                        />
                        <label class="form-check-label" for="SwitchCheckSizesm" 
                          >Active</label
                        >
                    </div>
                    <div
                        class="form-check form-switch form-switch-sm mb-0"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          id="SwitchCheckSizesm"
                          v-model="list.trade_active"
                          value="1"
                          unchecked-value="0"
                          name="trade"
                        />
                        <label class="form-check-label" for="SwitchCheckSizesm" 
                          >Trade</label
                        >
                    </div>
                    <div
                        class="form-check form-switch form-switch-sm mb-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          id="SwitchCheckSizesm"
                          v-model="list.public_active"
                          value="1"
                          unchecked-value="0"
                          name="public"
                        />
                        <label class="form-check-label" for="SwitchCheckSizesm" 
                          >Public</label
                        >
                    </div>
                    </td>
                    <td>
                      <ul class="list-inline font-size-20 contact-links mb-0">
                        <li class="list-inline-item px-2"  v-if="($attrs.user.companies[0].userrole.name == 'Owner') || $attrs.user.companies[0].permissions.view.includes($attrs.active_menu.id)">
                          <a v-b-tooltip.hover title="View" placement="left" :href="'/service/details/'+list.uid">
                            <i class="mdi mdi-eye-outline"></i>
                          </a>
                        </li>
                        <li class="list-inline-item px-2" v-if="($attrs.user.companies[0].userrole.name == 'Owner') || $attrs.user.companies[0].permissions.edit.includes($attrs.active_menu.id)">
                          <a v-b-tooltip.hover title="Quick Edit" placement="left" :href="'/service/details/'+list.uid+'#basic-info'">
                            <i class="bx bx-edit-alt"></i>
                          </a>
                        </li>

                        <li class="list-inline-item px-2">
                           
                            <b-dropdown v-b-tooltip.hover title="More Options" 
                            placement="left"
                            dropleft class="more-options-dropdown">
                  <template v-slot:button-content>
                     <i class="bx bx-dots-horizontal-rounded"></i>
                  </template>
                  <b-dropdown-item>Action</b-dropdown-item>
                  <b-dropdown-item>Another Action</b-dropdown-item>
                  <b-dropdown-item>Something else here</b-dropdown-item>
                </b-dropdown>
                      
                        </li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="row">
              <div class="col-lg-12" v-if="productsData !== ''">
                <b-pagination
              class="justify-content-center"
              pills
              v-model="currentPage"
              :total-rows="productsData.total"
              :per-page="productsData.per_page"
              aria-controls="my-table"
              @input="productQuery"
            ></b-pagination>
             <small class="mx-auto my-2 text-center d-block">Records {{productsData.from}} - {{productsData.to}} of {{productsData.total}} // Page {{productsData.current_page}} of {{productsData.last_page}}</small>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
      <SideSlidePanel :editPanel="addNewPanel" :panelClass="'edit-sidepanel-fullwidth'" @close-panel="addNewPanel = !addNewPanel" :expandable="0"  @expand="addPanelClass = 'edit-sidepanel-fullwidth'" @contract="addPanelClass = 'edit-sidepanel'">
      <div class="row">
        
        <div class="col-md-12">
        <b-card class="shadow-none">
          <h4 class="my-3">Add New Service</h4>
          <hr>
          <b-overlay
                        id="overlay-background"
                        :show="loadingForm"
                        variant="white"
                        opacity="0.85"
                        blur="1px"
                        rounded="md"
                      >
         <b-form id="addserviceform" method="POST" enctype="multipart/form-data">
                   <input type="hidden" name="_token" :value="csrf">
           <b-row>
                     <b-col md="12">
                    <transition name="slide-error" mode="out-in">
                    <b-alert id="errors" variant="danger" :show="error_message"  dismissible @dismissed="error_message = !error_message">
                      <p class="font-size-14">Oops! Please correct the following errors in your submission</p>
                      <ul>
                        <li v-for="(error, key) in error_reponse">
                          {{error[0]}}
                        </li>
                      </ul>
                    </b-alert>
                    
                  </transition>
                  </b-col>
          </b-row>
           <b-row class="mt-2">
            <b-col md="3">
              <h5><i class="font-size-16 text-primary bx bx-info-circle"></i> Basic Info</h5>
            </b-col>
            <b-col md="9">
              <b-card class="border border-radius-1em shadow-none">
               <b-form-group label="Reference">
            <b-form-input type="text" name="reference" v-model="newservice.reference"></b-form-input>
          </b-form-group>
          <b-form-group label="Name">
            <b-form-input type="text" name="name" v-model="newservice.name" @input="newservice.slug = sanitizeTitle(newservice.name)"></b-form-input>
          </b-form-group>
           <b-form-group class="mt-2" label="Description">
            <b-form-textarea
              placeholder="Enter description"
                rows="3"
                max-rows="6"
                v-model="newservice.short_description"
                name="short_description"
            ></b-form-textarea>
          </b-form-group>
           <b-form-group class="mt-2" label="Long Description" name="long_description">
            <input type="hidden" name="long_description" v-model="newservice.long_description">
            <ckeditor v-model="newservice.long_description" :editor="editor"></ckeditor>
          </b-form-group>
        </b-card>
        </b-col>
      </b-row>
        
      <b-row class="mt-1">       
        <b-col md="3">
              <h5><i class="font-size-16 text-primary bx bx-info-circle"></i> Category</h5>

            </b-col>
        <b-col md="9">
          <!--<FilterListTree :category_data="brandsData.data"></FilterListTree>-->
          <input type="hidden" name="company_service_category_id" :value="newservice.company_service_category_id">
          <div class="border border-radius-1em">
            <SelectCompanyCategoryTree :selectedid="newservice.company_service_category_id" :category_data="service_categories.data" @category-select="addCategory"></SelectCompanyCategoryTree>
          </div>
        </b-col>
      </b-row>
      <b-row class="mt-4">
          <b-col md="3">
              <h5><i class="text-primary bx bx-image-add"></i> Images</h5>
            </b-col>
          <b-col md="9">
            <b-card class="border border-radius-1em shadow-none">
              <b-row>
                <b-col md="6">
              <b-form-group class="mt-2" label="Image">
               <vue-dropzone v-if="(newservice.image == '') || (newservice.image == null)"  id="dropzone" ref="myVueDropzone" :use-custom-slot="true" :options="dropzoneOptions" @vdropzone-file-added="imageAdded"  @vdropzone-drop="imageAdded">
                        <div class="dropzone-custom-content">
                            <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                            <h4>Drop files here or click to upload.</h4>
                        </div>
                        </vue-dropzone>
                <div v-else class="position-relative" @mouseenter="floatImage = 'image'" @mouseleave="floatImage = ''">
                  <img class="img-fluid" :src="newservice.image">
                  <div v-if="floatImage == 'image'" class="h-100 d-flex justify-content-center align-items-center position-absolute top-0 bottom-0 left-0 right-0 bg-white border border-radius-1em">
                   <a class="btn btn-primary btn-rounded mx-auto"  @click="newservice.image = ''">
                    Remove / Upload New Image
                    </a>
                  </div>
                </div>
                </b-form-group>
              </b-col>
               <b-col md="6">
              <b-form-group class="mt-2" label="Banner Image">

             <vue-dropzone v-if="(newservice.banner_image == '') || (newservice.banner_image == null)" id="dropzone" ref="myVueDropzone" :use-custom-slot="true" :options="dropzoneOptions" @vdropzone-file-added="coverImageAdded"  @vdropzone-drop="coverImageAdded">
                        <div class="dropzone-custom-content">
                            <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                            <h4>Drop files here or click to upload.</h4>
                        </div>
                        </vue-dropzone>

                <div v-else class="position-relative" @mouseenter="floatImage = 'cover'" @mouseleave="floatImage = ''">
                  <img class="img-fluid" :src="newservice.banner_image">
                  <div v-if="floatImage == 'cover'" class="h-100 d-flex justify-content-center align-items-center position-absolute top-0 bottom-0 left-0 right-0 bg-white border border-radius-1em">
                   <a class="btn btn-primary btn-rounded mx-auto"  @click="newservice.image = ''">
                    Remove / Upload New Image
                    </a>
                  </div>
                </div>
                </b-form-group>
              </b-col>
            </b-row> 
        </b-card>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col md="3">
        <h5><i class="text-primary bx bx-receipt"></i> Service Type & Frequency</h5>
        </b-col>
        <b-col md="9">
          <b-card class="border border-radius-1em shadow-none">
          <b-form-group label="Type" v-slot="{ ariaDescribedby }" inline class="plain-radio-label">
            <b-form-radio v-for="type in service_types" v-model="newservice.company_service_type_id" :aria-describedby="ariaDescribedby" name="company_service_type_id" :value="type.id" :disabled="type.name == 'Subscription'">{{type.name}} <span v-if="type.name == 'Subscription'">(Coming Soon)</span></b-form-radio>
          </b-form-group>

          <b-form-group label="Frequency"  class="plain-radio-label" v-if="newservice.company_service_type_id > 1" v-slot="{ ariaDescribedby }" inline>
            <b-form-radio v-for="freq in service_frequencies" v-model="newservice.company_service_frequency_id" :aria-describedby="ariaDescribedby" name="company_service_frequency_id" :value="freq.id">{{freq.name}}</b-form-radio>
          </b-form-group>
        </b-card>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col md="3">
        <h5><i class="text-primary bx bx-receipt"></i> Bookings</h5>
        </b-col>
        <b-col md="9">
          <b-card class="border border-radius-1em shadow-none">
          <b-form-group label="Allow Calendar Bookings" v-slot="{ ariaDescribedby }" inline class="plain-radio-label">
            <b-form-radio v-model="newservice.allow_bookings"  name="allow_bookings" :value="true">Yes</b-form-radio>
            <b-form-radio v-model="newservice.allow_bookings"  name="allow_bookings" :value="false">No</b-form-radio>
          </b-form-group>

          <b-form-group v-if="newservice.allow_bookings == true" label="Service Has Set Duration" v-slot="{ ariaDescribedby }" inline class="plain-radio-label">
            <b-form-radio v-model="newservice.set_duration"  name="set_duration" :value="true">Yes</b-form-radio>
            <b-form-radio v-model="newservice.set_duration"  name="set_duration" :value="false">No</b-form-radio>
          </b-form-group>

          <b-form-group v-if="(newservice.allow_bookings == true) && (newservice.set_duration == true)" label="Duration" inline class="w-50 float-left mr-3">
            <!--<b-form-select class="form-control w-50 float-left">
              <b-form-select-option v-for="number in numbers">
                {{number}}
              </b-form-select-option>
            </b-form-select>-->
            <div class="d-flex">
            <b-form-input type="number" v-model="newservice.duration" class="mr-3" >
            </b-form-input>
             <b-form-select class="form-control" v-model="newservice.duration_units">
              <b-form-select-option v-for="period in periods">
                {{period}}
              </b-form-select-option>
            </b-form-select>
            </div>
          </b-form-group>
          <b-form-group v-if="(newservice.allow_bookings == true) && (newservice.set_duration == true)" label="Maximum Units Per Booking" class="w-25 float-left">
            <b-form-input v-model="newservice.max_units" type="number"></b-form-input>
          </b-form-group>
        </b-card>
        </b-col>
      </b-row>
       <b-row class="mt-1">
        <b-col md="3">
        <h5><i class="font-size-16 text-primary bx bx-receipt"></i> Price</h5>
              </b-col>
          <b-col md="9">
            <b-card class="border border-radius-1em shadow-none">
            <b-row>
                     <b-col md="3">
          <b-form-group label="Price (exc. VAT)">
            <b-form-input v-model="newservice.price_exc" type="number" step="0.01" name="price_exc" @input="newservice.price_inc = addTax(newservice.price_exc, newservice.tax_rule)"></b-form-input>
          </b-form-group>
        </b-col>
                     <b-col md="3">
          <label for="floatingSelect">Tax Rate</label>
          <select class="form-select" label="Tax Rate"  v-model="newservice.tax_rule" @change="newservice.tax_rule_id = newservice.tax_rule.id">
            <option :value="''">-No Tax-</option>
            <option v-for="rule in tax_rules" :value="rule" >{{rule.name}}</option>
          </select>
          <input v-if="(newservice.tax_rule !== null) && (newservice.tax_rule !== '')" type="hidden" name="tax_rule_id" :value="newservice.tax_rule.id">
        </b-col>
        <b-col md="3">
          <b-form-group label="Price (inc. VAT)">
            <b-form-input v-model="newservice.price_inc" type="number" name="price_inc"  @input="newservice.price_exc = removeTax(newservice.price_inc, newservice.tax_rule)"></b-form-input>
          </b-form-group>
          </b-col>
          <b-col md="3">
          <b-form-group label="Deposit">
            <b-form-input v-model="newservice.service_deposit" type="number" step="0.01" name="service_deposit" ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12" class="mt-2">
         <b-form-group label="Trade Price (exc. VAT)">
          <b-form-input v-model="newservice.trade_price" type="number" step="0.01" name="trade_price"></b-form-input>
          </b-form-group>
     </b-col>
      </b-row>
    </b-card>
    </b-col>
  </b-row>
  <b-row class="mt-1">
        <b-col md="3">
              <h5><i class="font-size-16 text-primary bx bx-info-circle"></i> Meta Info</h5>
            </b-col>

        <b-col md="9">
          <b-card class="border border-radius-1em shadow-none">
          <b-form-group label="Meta Title">
            <b-form-input type="text" name="meta_title" v-model="newservice.meta_title"></b-form-input>
          </b-form-group>
          <b-form-group class="mt-2" label="Meta Description">
            <b-form-textarea
              placeholder="Enter description"
                rows="3"
                max-rows="6"
                v-model="newservice.meta_description"
                name="meta_description"
            ></b-form-textarea>
          </b-form-group>
          <b-form-group label="Marketplace URL" class="mt-2">
            <b-input-group>
            <b-input-group-prepend is-text>
              /seller/{{$attrs.company.nickname}}/service/
            </b-input-group-prepend>
              <b-form-input type="text" name="slug" v-model="newservice.slug"></b-form-input>
            </b-input-group>
          </b-form-group>
        </b-card>
        </b-col>
      </b-row>
      <b-row class="mt-1">
         <b-col md="3">
              <h5><i class="font-size-16 text-primary bx bx-info-circle"></i> Active On</h5>
          </b-col>
          <b-col md="9">
            <b-card class="border border-radius-1em shadow-none">
                <div
                        class="form-check form-switch form-switch-sm mb-0 mt-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          id="myWebsiteSwitch"
                          v-model="newservice.active"
                          value="1"
                          unchecked-value="0"
                          name="active"
                        />
                        <label class="form-check-label" for="myWebsiteSwitch" 
                          >My Website</label
                        >
                    </div>
                    <div
                        class="form-check form-switch form-switch-sm mb-0 mt-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          id="publicActiveSwitch"
                          v-model="newservice.public_active"
                          value="1"
                          unchecked-value="0"
                          name="public_active"
                        />
                        <label class="form-check-label" for="publicActiveSwitch" 
                          >Public Market</label
                        >
                    </div>
                    <div
                        class="form-check form-switch form-switch-sm mb-0 mt-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          id="tradeActiveSwitch"
                          v-model="newservice.trade_active"
                          value="1"
                          unchecked-value="0"
                          name="trade_active"
                        />
                        <label class="form-check-label" for="tradeActiveSwitch" 
                          >Trade Market</label
                        >
                    </div>
                  </b-card>
                   <div class="d-flex flex-row mt-3">
                  <b-button variant="success" class="w-md w-100 btn-rounded btn-block" @click="addService()"> <i class=" bx bx-save"></i>

                  <span v-if="newservice.uid == ''"> Save</span> <span v-else>Update</span></b-button>
                  </div>
            </b-col>
        
        </b-row>
         
        </b-form>
      </b-overlay>
        </b-card>
        </div>
      </div>
    </SideSlidePanel> 
       <SideSlidePanel :editPanel="showFilters" :panelClass="'edit-sidepanel-slim'" @close-panel="showFilters = !showFilters">     
         <div class="col-lg-12" v-if="showFilters == true">
        <div class="">
          <div class="">
            <h4 class="card-title mb-4">Filter</h4>
              <div class="row">
                <div class="col-md-12 d-flex">
                      <b-form-checkbox v-b-tooltip.hover title="Toggle Active/Inactive" placement="left" switch size="lg" class="my-auto d-inline" value="1"
                      unchecked-value="0" v-model="active" @input="productQuery"><small class="mr-auto">Active</small></b-form-checkbox>
                      
                    <i v-b-tooltip.hover title="Clear Toggle" placement="left" class="font-size-20 bx bx-reset ml-auto" v-if="active != ''" @click="active = '', productQuery"></i>
                </div>
                 <div class="col-md-12 d-flex">
                      <b-form-checkbox switch size="lg" class="d-inline my-auto" value="1"
                      unchecked-value="0" v-model="public_active" @input="productQuery"><small class="mr-auto">Public</small></b-form-checkbox>
                      <i class="bx bx-reset font-size-20 ml-auto" v-if="public_active != ''" @click="public_active = '', productQuery"></i>
                </div>
                 <div class="col-md-12 d-flex">
                       <b-form-checkbox switch size="lg" class="my-auto d-inline" value="1"
                      unchecked-value="0" v-model="trade_active" indeterminate="null" @input="productQuery"><small class="mr-auto"  >Trade</small></b-form-checkbox>
                      <i class="bx bx-reset font-size-20 ml-auto" v-if="trade_active != ''" @click="trade_active = '', productQuery"></i>
                </div>
              </div>


            <div>
               <div class="my-3 pt-3">
              <h5 class="font-size-14">Price</h5>
              <b-form-group label="Min Price" class="w-50 d-inline float-left mt-2 mb-3 px-2">
              <b-form-input
                type="number"
                v-model="priceRange[0]"
                @input="productQuery"></b-form-input>
              </b-form-group>
              <b-form-group label="Max Price" class="w-50 d-inline float-left mt-2 mb-3 px-2">
                 <b-form-input
                 label="Max Price"
                 type="number"
                 v-model="priceRange[1]"
                 @input="productQuery"></b-form-input>
              </b-form-group>
            </div>
                <h5 class="font-size-14 my-3">Categories</h5>
                <FilterListTree></FilterListTree>
                
                <h5 class="font-size-14 mb-3">Brands</h5>
               <FilterProductBrands v-on:brands-filtered="filterBrands" :api_token="$attrs.user.api_token"></FilterProductBrands>

               <h5 class="font-size-14 mb-3">Collections</h5>
               <FilterProductCollections v-on:collections-filtered="filterCollections" :companies="$attrs.companies" :default_company="$attrs.company.id" :api_token="$attrs.user.api_token"></FilterProductCollections>

            </div>
           

          <!-- <div class="mt-4 pt-3">
              <h5 class="font-size-14 mb-3">Customer Rating</h5>
              <div>
                <b-form-checkbox
                  id="checkbox-1"
                  name="checkbox-1"
                  value="accepted"
                  unchecked-value="not_accepted"
                >
                  4
                  <i class="bx bx-star text-warning"></i> & Above
                </b-form-checkbox>

                <b-form-checkbox
                  id="checkbox-2"
                  class="mt-2"
                  name="checkbox-2"
                  value="accepted"
                  unchecked-value="not_accepted"
                >
                  3
                  <i class="bx bx-star text-warning"></i> & Above
                </b-form-checkbox>

                <b-form-checkbox
                  id="checkbox-3"
                  class="mt-2"
                  name="checkbox-3"
                  value="accepted"
                  unchecked-value="not_accepted"
                >
                  2
                  <i class="bx bx-star text-warning"></i> & Above
                </b-form-checkbox>
                <b-form-checkbox
                  id="checkbox-4"
                  class="mt-2"
                  name="checkbox-4"
                  value="accepted"
                  unchecked-value="not_accepted"
                >
                  1
                  <i class="bx bx-star text-warning"></i>
                </b-form-checkbox>
              </div>
            </div>-->
          </div>
        </div>
      </div>
    </SideSlidePanel>
    </div>
    <!-- end row -->
  </Layout>
</template>
