<script>
	export default{
    props: {
      resource: {
        type: String,
        default: () => '',
      },
      resource_id: {
        type: Number,
        default: () => '',
      },
      api_token: {
        type: String,
        default: () => '',
      },
      resource_route: {
        type: String,
        default: () => '',
      },
    },
		data(){
			return{
        storageurl: 'https://'+this.$attrs.company.nickname+'.s3.eu-west-2.amazonaws.com',
        skip: 0,
        take: 25,
        sortby: 'id',
        order: 'desc',
        sortby: 'id',
        trade_active: '',
        public_active: '',
        brands: '',
        collections: '',
        priceRange: [0,999999],
        currentPage: 1,
        selectedids: [],
        loadingForm: false, 
        isCheckAll: false,
        active: '',
				search_query: '',
				search_results: [],
				all_brands: '',
				results_start: 0,
      			results_end: 5,
			}
		},
		methods:{
          searchQuery(){
            this.loadingForm = true;
              axios.get('/api/company/product/component/search?page='+this.currentPage+'&skip='+this.skip+'&take='+this.take+'&sortby='+this.sortby+'&order='+this.order+'&search='+this.search_query+'&trade_active='+this.trade_active+'&public_active='+this.public_active+'&active='+this.active+'&brands='+this.brands+'&collections='+this.collections+'&pricerange='+this.priceRange, {headers: {
                  'Authorization': 'Bearer ' + this.api_token
              }}).then(response => {
                console.log(response);
                this.search_results = response.data;
                let self = this;
                setTimeout(function(){
                  self.loadingForm = false;
                }, 500);
                //this.results_end = response.data.total;
              });
          },
          selectResource(resource){
            this.loadingForm = true;
          	this.$emit('search-resource-selected', resource);
            this.search_results = [];
            this.search_query = '';
            this.loadingForm = false;
          },
          addToResource(resource){
            this.loadingForm = true;
            if(this.resource_route !== ''){
              axios.post(this.resource_route+'api_token='+this.api_token+'&product_id='+resource.id+'', {product_id: resource.id}, {
                headers: {'Authorization' : 'Bearer '+this.api_token}
              }).then(response => {
                this.$emit('product-added', response.data);
                this.search_results = [];
                this.search_query = '';
                this.loadingForm = false;
              }).catch(error => {
                this.$emit('resource-error', error.data);
                this.search_results = [];
                this.search_query = '';
                this.loadingForm = false;
              });
            }else{  
              this.$emit('product-added', resource);
              this.search_results = [];
              this.search_query = '';
              this.loadingForm = false;
            }
          },
          priceIncVat(price, tax_percentage){
		      var tax = 1 + (tax_percentage / 100);
		      return price * tax;
		      }
		}
	}
</script>
<template>
  <div class="row">
          <div class="col-md-12">
                            <div class="form-control-group">
                              <div class="input-group">
                                <div class="search-box" style="flex-grow: 3">
                                  <div class="position-relative">
                                    <input class="form-control" type="text" v-model="search_query" v-on:keyup.enter="searchQuery" v-on:keydown="searchQuery" style="border: 1px solid #eff2f7">
                                    <i class="bx bx-search-alt search-icon"></i>
                                    <a v-b-tooltip.hover title="Reset" placement="top" href="javascript:;" @click="search_results = [], search_query = ''">
                                      <i class="bx bx-reset search-icon reset"></i>
                                    </a>
                                  </div>
                                </div>
                                <div class="pl-2">
                                    <a href="javascript:;" class="btn btn-rounded btn-primary btn-block" @click="searchQuery">Search</a>
                                </div>
                             </div>
                             
                            <ul class="list-group searchbar-searchlist shadow" v-if="search_results.length !== 0">
                              <b-overlay
                            id="overlay-background"
                            :show="loadingForm"
                            variant="white"
                            opacity="0.85"
                            blur="1px"
                            rounded="md"
                          >
                              <li v-for="searchresult in search_results" class="list-group-item d-flex w-100">
                              	<img v-if="searchresult.cover_image !== null" class="avatar-md border rounded-circle mr-3" :src="storageurl + searchresult.cover_image.src.replaceAll('//', '/')">
                                <img v-else class="avatar-md border rounded-circle mr-3" src="/images/product-placeholder.png">
                                <div class="mr-auto ml-2 my-auto mr-3 w-75">
                                	<h6 class="mr-auto my-auto">{{searchresult.name}} <span class="badge badge-light bg-primary">{{searchresult.reference}}</span></h6>
						            <p class="text-muted italic no-margin text-truncate paragraph-truncate-line2 mb-0" style="max-height: 56px;" v-html="searchresult.short_description"></p>
						            <strong class="text-muted mb-auto">£{{searchresult.sales_price}} <small class="mr-3">exc. VAT</small>   <span v-if="searchresult.tax_rule !== null">£{{priceIncVat(searchresult.sales_price, searchresult.tax_rule.percentage)}} <small class="mr-3">inc. VAT</small></span> £{{searchresult.trade_price}} <small class="mr-3">Trade Price</small></strong>
                                </div>
                                
                                <a class="btn btn-outline-light btn-rounded ml-auto my-auto" href="javascript:;" @click="addToResource(searchresult), search_results = [], searchquery = '' "><i class="bx bx-plus"></i></a>
              
                              
                              </li>
                              <!--<li class="list-group-item py-4 text-center" ><a class="btn btn-outline-light btn-rounded">Load More</a>
                              </li>-->
                               </b-overlay>
                            </ul>
                                                   </div>
                        
                  </div>
                </div>
</template>