<template>
	<div>
		<ListingMapWidget :id="$attrs.id" v-if="(listings !== null) && (resource == 'listingsmap')" :company="$attrs.company" :user="$attrs.user" :liveMode="$attrs.liveMode" :content="$attrs.content" :configs="$attrs.configs" :filters="$attrs.active_filters" :elements="$attrs.elements" :listings="listings"  ></ListingMapWidget>
		<ListingListWidget :id="$attrs.id" v-if="listings !== null" :company="$attrs.company" :user="$attrs.user" :liveMode="$attrs.liveMode" :content="$attrs.content" :configs="$attrs.configs" :filters="$attrs.active_filters" :elements="$attrs.elements" :listings="listings" ></ListingListWidget>
		<div v-if="listings == null">
			No Listings to Show
		</div>
	</div>
</template>

<script type="text/javascript">
	import ListingListWidget from './ListingListWidget'
	import ListingMapWidget from './ListingMapWidget'

	export default {
		props: {
			resource: {
				type: String
			}
		},
		components: {
			ListingListWidget,
			ListingMapWidget
		},
		data() {
			return {
				loadingListings: false,
				current_page: 1,
				query: '',
				listings: null,
				loadingListings: true,
			}
		},
		created(){
			this.getResource();
		},
		methods: {

			getResource(){
				this.createQueryString();
				this.loadingListings = false;
				if(this.$attrs.liveMode == false){
					axios.get('/api/company/web/page/widgets/listings?page='+this.current_page+this.query, {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
							this.listings = response.data;
							let self = this;
						          setTimeout(function(){
						            self.loadingListings = false;
						          }, 500);
						}).catch(error => {
							let self = this;
						          setTimeout(function(){
						            self.loadingListings = false;
						          }, 500);
						});
					}else if(this.$attrs.liveMode == true){
						axios.get('/website-api/widget/listings?page='+this.current_page+this.query, {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
							this.listings = response.data;
							let self = this;
						          setTimeout(function(){
						            self.loadingListings = false;
						          }, 500);
						}).catch(error => {
							let self = this;
						          setTimeout(function(){
						            self.loadingListings = false;
						          }, 500);
						});
					}
			},
			createQueryString(){
				this.query = '';
				for(var key in this.$attrs.filters){
					if(key !== 'page'){
						this.query += '&'+key+'='+this.$attrs.filters[key].value+'';
					}
					
				}
			}
		}
	}

</script>